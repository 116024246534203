import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import {
  addShiftFail,
  addShiftPayLineItemFail,
  addShiftPayLineItemSuccess,
  addShiftSuccess,
  checkOpenedShiftFail,
  checkOpenedShiftSuccess,
  closeShiftFail,
  closeShiftSuccess,
  deleteShiftPayLineItemFail,
  deleteShiftPayLineItemSuccess,
  getShiftError,
  getShiftPayLineItemFail,
  getShiftPayLineItemSuccess,
  getShiftSuccess,
  listShiftPayLineItemFail,
  listShiftPayLineItemSuccess,
  listShiftsError,
  listShiftsSuccess,
  moreShiftPayLineItemFail,
  moreShiftPayLineItemSuccess,
  updateShiftFail,
  updateShiftPayLineItemFail,
  updateShiftPayLineItemSuccess,
  updateShiftSuccess,
} from "./action";
import {
  ADD_NEW_SHIFT_PAY_LINE_ITEM,
  ADD_SHIFT,
  CHECK_OPENED_SHIFT,
  CLOSE_SHIFT,
  DELETE_SHIFT_PAY_LINE_ITEM,
  GET_SHIFT,
  GET_SHIFT_PAY_LINE_ITEM,
  LIST_SHIFTS,
  LIST_SHIFT_PAY_LINE_ITEM,
  MORE_SHIFT_PAY_LINE_ITEM,
  UPDATE_SHIFT,
  UPDATE_SHIFT_PAY_LINE_ITEM,
} from "./actionType";
import { locationsAPI, shiftPayLineItemsAPI, shiftsAPI } from "../../../../helpers/resource_helper";
import { toast } from "react-toastify";
import { getDefaultPrintShop } from "../../../actions";

function* listShifts({ payload: { ...params } }) {
  try {
    const response = yield call(shiftsAPI.list, { ...params });
    yield put(listShiftsSuccess(response));
  } catch (error) {
    yield put(listShiftsError(error));
  }
}

function* getShift({ payload: id }) {
  try {
    const response = yield call(shiftsAPI.get, { id });
    yield put(getShiftSuccess(response));
  } catch (error) {
    yield put(getShiftError(error));
  }
}

function* addShift({ payload: { data, handleAfterSubmit } }) {
  try {
    const response = yield call(shiftsAPI.create, data);
    yield put(addShiftSuccess(response));
    yield call(handleAfterSubmit);
  } catch (error) {
    yield put(addShiftFail(error));
  }
}

function* updateShift({ payload }) {
  try {
    const response = yield call(shiftsAPI.put, payload);
    yield put(updateShiftSuccess(response));
    if (payload.is_default) {
      put(getDefaultPrintShop());
    }
    toast.success(`Cập nhật thành công!`);
  } catch (error) {
    toast.error(error.toString());
    yield put(updateShiftFail(error));
  }
}

function* checkOpenedShift({ payload: { data, handleAfterSubmit } }) {
  try {
    const response = yield call(shiftsAPI.createCustomPath, "check_opened_shift", data);
    if (!response?.location_id) {
      yield put(checkOpenedShiftSuccess({}));
    } else {
      const location = yield call(locationsAPI.get, { id: response.location_id });
      const locationData = { label: location.name, value: location.id };
      yield call(handleAfterSubmit, locationData);
      yield put(checkOpenedShiftSuccess(response));
    }
  } catch (error) {
    toast.error(error.toString());
    yield put(checkOpenedShiftFail(error));
  }
}

function* closeShift({ payload: { data, handleAfterSubmit } }) {
  try {
    const { id, ...rest } = data;
    const response = yield call(shiftsAPI.createCustomPath, `${id}/close_shift`, rest);
    yield call(handleAfterSubmit);
    yield put(closeShiftSuccess(response));
  } catch (error) {
    toast.error(error.toString());
    yield put(closeShiftFail(error));
  }
}

function* listShiftPayLineItem({ payload: { ...rest } }) {
  try {
    const response = yield call(shiftPayLineItemsAPI.list, { ...rest });
    yield put(listShiftPayLineItemSuccess(response));
  } catch (error) {
    yield put(listShiftPayLineItemFail(error));
  }
}
function* moreShiftPayLineItem({ payload: { ...rest } }) {
  try {
    const response = yield call(shiftPayLineItemsAPI.list, { ...rest });
    yield put(moreShiftPayLineItemSuccess(response));
  } catch (error) {
    yield put(moreShiftPayLineItemFail(error));
  }
}

function* getShiftPayLineItem({ payload: id }) {
  try {
    const response = yield call(shiftPayLineItemsAPI.get, { id });
    yield put(getShiftPayLineItemSuccess(response));
  } catch (error) {
    yield put(getShiftPayLineItemFail(error));
  }
}

function* deleteShiftPayLineItems({ payload: { shiftPayLineItem, shiftId } }) {
  try {
    const response = yield call(shiftPayLineItemsAPI.customDelete, `${shiftId}/${shiftPayLineItem.id}`);
    yield put(deleteShiftPayLineItemSuccess({ shift: response, shiftPayLineItem }));
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteShiftPayLineItemFail(error));
    toast.error(error.toString(), { autoClose: 3000 });
  }
}

function* onAddNewShiftPayLineItem({ payload: { shiftPayLineItem, shiftId, handleAfterSubmit } }) {
  try {
    const response = yield call(shiftPayLineItemsAPI.customCreate, `${shiftId}`, shiftPayLineItem);
    yield put(addShiftPayLineItemSuccess(response));
    yield call(handleAfterSubmit);
    toast.success("ShiftPayLineItem Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addShiftPayLineItemFail(error));
    toast.error(error.toString(), { autoClose: 3000 });
  }
}

function* onUpdateShiftPayLineItem({ payload: { shiftPayLineItem, shiftId } }) {
  try {
    const response = yield call(shiftPayLineItemsAPI.put, `${shiftPayLineItem.id}/${shiftId}`, shiftPayLineItem);
    yield put(updateShiftPayLineItemSuccess(response));
    toast.success("ShiftPayLineItem Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateShiftPayLineItemFail(error));
    toast.error(error.toString(), { autoClose: 3000 });
  }
}

export function* watchListShifts() {
  yield takeEvery(LIST_SHIFTS, listShifts);
}

export function* watchGetShift() {
  yield takeEvery(GET_SHIFT, getShift);
}

export function* watchAddShift() {
  yield takeEvery(ADD_SHIFT, addShift);
}

export function* watchUpdateShift() {
  yield takeEvery(UPDATE_SHIFT, updateShift);
}

export function* watchCheckOpenedShift() {
  yield takeEvery(CHECK_OPENED_SHIFT, checkOpenedShift);
}

export function* watchCloseShift() {
  yield takeEvery(CLOSE_SHIFT, closeShift);
}

export function* watchGetShiftPayLineItems() {
  yield takeEvery(LIST_SHIFT_PAY_LINE_ITEM, listShiftPayLineItem);
}

export function* watchMoreShiftPayLineItems() {
  yield takeEvery(MORE_SHIFT_PAY_LINE_ITEM, moreShiftPayLineItem);
}

export function* watchDeleteShiftPayLineItems() {
  yield takeEvery(DELETE_SHIFT_PAY_LINE_ITEM, deleteShiftPayLineItems);
}

export function* watchGetShiftPayLineItem() {
  yield takeEvery(GET_SHIFT_PAY_LINE_ITEM, getShiftPayLineItem);
}

export function* watchUpdateShiftPayLineItem() {
  yield takeEvery(UPDATE_SHIFT_PAY_LINE_ITEM, onUpdateShiftPayLineItem);
}

export function* watchAddNewShiftPayLineItem() {
  yield takeEvery(ADD_NEW_SHIFT_PAY_LINE_ITEM, onAddNewShiftPayLineItem);
}

function* ShiftsSaga() {
  yield all([
    fork(watchListShifts),
    fork(watchGetShift),
    fork(watchAddShift),
    fork(watchUpdateShift),
    fork(watchCheckOpenedShift),
    fork(watchCloseShift),
    fork(watchGetShiftPayLineItem),
    fork(watchGetShiftPayLineItems),
    fork(watchMoreShiftPayLineItems),
    fork(watchUpdateShiftPayLineItem),
    fork(watchAddNewShiftPayLineItem),
    fork(watchDeleteShiftPayLineItems),
  ]);
}

export default ShiftsSaga;
