import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

// Formik
import * as Yup from "yup";
import { Form, Formik, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Col, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

import { useProfile } from "../../../../Components/Hooks/useProfile";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { Actions } from "../../../../Components/constants/common";

import { addNewLocation, updateLocation } from "../../../../store/actions";
import ImageInput from "../../../../Components/Common/ImageInput";
import InputTheme from "../../../../Components/Common/InputTheme";
import ScrollToError from "../../../../Components/Common/ScrollToError";

const EditAddLocationModal = ({ show, toggle, location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { userProfile } = useProfile();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const { locationsLoading, locationsAction, locationsSuccess, locationsError } = useMemoizedSelector((state) => ({
    locationsLoading: state.Locations.loading,
    locationsAction: state.Locations.action,
    locationsSuccess: state.Locations.success,
    locationsError: state.Locations.error,
  }));

  const formRef = useRef();

  // validation
  const validation = {
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: location?.name || "",
      open_time: location?.open_time || "",
      description: location?.description || "",
      images: location?.images || [],
      city: location?.city || "",
      province: location?.province || "",
      province_code: location?.province_code || "",
      country: location?.country || "",
      country_code: location?.country_code || "",
      phone: location?.phone || "",
      address1: location?.address1 || "",
      address2: location?.address2 || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("enter_location_name")),
      // open_time: Yup.string().required(t("enter_open_time")),
      // description: Yup.string().required(t("enter_description")),
      // images: Yup.array().of(
      //   Yup.object().shape({
      //     url: Yup.string().required(t("provide_image_url")),
      //     alt: Yup.string().required(t("provide_image_description")),
      //   })
      // ),
      // city: Yup.string().required(t("enter_city")),
      // province: Yup.string().required(t("enter_province")),
      // province_code: Yup.string().required(t("enter_province_code")),
      // country: Yup.string().required(t("enter_country")),
      // country_code: Yup.string().required(t("enter_country_code")),
      // phone: Yup.string().matches(phoneRegExp, "Phone number is not valid").required("enter-phone-number"),
      // address1: Yup.string().required(t("enter_address1")),
      // address2: Yup.string(),
    }),
    onSubmit: (values) => {
      if (location) {
        const { terminal_count, ...res } = location;
        const payload = { ...res, ...values };
        dispatch(updateLocation(payload));
      } else {
        dispatch(addNewLocation(values));
      }
    },
  };

  const handleClose = () => {
    if (!locationsLoading) {
      toggle();
      formRef.current.resetForm();
    }
  };

  // toggle after loading
  useEffect(() => {
    !locationsLoading && locationsSuccess && show && handleClose();
  }, [locationsLoading, locationsSuccess]);

  return (
    <Modal fade={true} isOpen={show} toggle={handleClose} centered={true} scrollable>
      <ModalHeader className="bg-light p-3">{t(location ? t("Edit location") : t("New location"))}</ModalHeader>

      <ModalBody>
        <Formik
          enableReinitialize={true}
          initialValues={validation.initialValues}
          validationSchema={validation.validationSchema}
          onSubmit={validation.onSubmit}
          className="needs-validation"
          innerRef={formRef}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <ScrollToError />
              <Row>
                <Col md={12} className="mb-3">
                  <Label htmlFor="images-field" className="form-label">
                    {t("Image")}
                  </Label>
                  <ImageInput
                    images={values.images}
                    onChangeImages={(data) => {
                      const newData = data.map((item) => {
                        if (item.id) {
                          return item;
                        } else {
                          return {
                            name: item.path,
                            image: item.content,
                            preview: item.preview,
                          };
                        }
                      });
                      setFieldValue("images", newData);
                    }}
                  />
                </Col>
                <Col md={12} className="mb-3">
                  <InputTheme name="name" label="Location Name" placeholder="Enter Location Name" />
                </Col>
                <Col md={12} className="mb-3">
                  <InputTheme name="description" label="Description" placeholder="Enter Location Description" />
                </Col>

                <Col md={12} className="mb-3">
                  <InputTheme
                    name="open_time"
                    label="Opening-Closing time"
                    placeholder="Enter Open Time - Close Time..."
                  />
                </Col>

                <Col md={12} className="mb-3">
                  <InputTheme name="city" label="City" placeholder="Enter City" />
                </Col>
                <Col md={6} className="mb-3">
                  <InputTheme name="province" label="Province" placeholder="Enter Province" />
                </Col>
                <Col md={6} className="mb-3">
                  <InputTheme name="province_code" label="Province code" placeholder="Enter Province Code" />
                </Col>
                <Col md={6} className="mb-3">
                  <InputTheme name="country" label="Country" placeholder="Enter Country" />
                </Col>
                <Col md={6} className="mb-3">
                  <InputTheme name="country_code" label="Country Code" placeholder="Enter Country Code" />
                </Col>
                <Col md={12} className="mb-3">
                  <InputTheme name="phone" label="Phone" placeholder="Enter Phone" />
                </Col>
                <Col md={12} className="mb-3">
                  <InputTheme name="address1" label={<span>{t("Address")} 1</span>} placeholder="Enter First Address" />
                </Col>
                <Col md={12} className="mb-3">
                  <InputTheme
                    name="address2"
                    label={<span>{t("Address")} 2</span>}
                    placeholder="Enter Second Address"
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>
        <div className="hstack gap-2 justify-content-end">
          <ButtonTheme type="button" className="btn btn-light w-sm" onClick={handleClose}>
            {t("Close")}
          </ButtonTheme>
          <ButtonTheme
            onClick={() => formRef.current.handleSubmit()}
            className="btn btn-primary w-sm"
            loading={locationsLoading && (locationsAction === Actions.UPDATE || locationsAction === Actions.CREATE)}
            disabled={locationsLoading && (locationsAction === Actions.UPDATE || locationsAction === Actions.CREATE)}
          >
            {t(location ? t("Update") : t("Add"))}
          </ButtonTheme>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default EditAddLocationModal;
