import {
  LIST_TRANSACTION,
  LIST_TRANSACTION_FAIL,
  LIST_TRANSACTION_SUCCESS,
  ADD_NEW_TRANSACTION,
  ADD_TRANSACTION_SUCCESS,
  ADD_TRANSACTION_FAIL,
  CANCEL_TRANSACTION,
  CANCEL_TRANSACTION_SUCCESS,
  CANCEL_TRANSACTION_FAIL,
  GET_TRANSACTION,
  GET_TRANSACTION_FAIL,
  GET_TRANSACTION_SUCCESS,
} from "./actionType";

export const listTransaction = (options) => ({
  type: LIST_TRANSACTION,
  payload: options,
});

export const listTransactionFail = (error) => ({
  type: LIST_TRANSACTION_FAIL,
  payload: error,
});

export const listTransactionSuccess = (transactions) => ({
  type: LIST_TRANSACTION_SUCCESS,
  payload: transactions,
});

export const getTransaction = (transactionID) => ({
  type: GET_TRANSACTION,
  payload: transactionID,
});

export const getTransactionFail = (error) => ({
  type: GET_TRANSACTION_FAIL,
  payload: error,
});

export const getTransactionSuccess = (transaction) => ({
  type: GET_TRANSACTION_SUCCESS,
  payload: transaction,
});

export const cancelTransaction = (transactionId) => ({
  type: CANCEL_TRANSACTION,
  payload: transactionId,
});

export const cancelTransactionSuccess = (transactionId) => ({
  type: CANCEL_TRANSACTION_SUCCESS,
  payload: transactionId,
});

export const cancelTransactionFail = (error) => ({
  type: CANCEL_TRANSACTION_FAIL,
  payload: error,
});

export const addNewTransaction = (transaction, handleAfterSubmit = () => {}) => ({
  type: ADD_NEW_TRANSACTION,
  payload: { transaction, handleAfterSubmit },
});

export const addTransactionSuccess = (transaction) => ({
  type: ADD_TRANSACTION_SUCCESS,
  payload: transaction,
});

export const addTransactionFail = (error) => ({
  type: ADD_TRANSACTION_FAIL,
  payload: error,
});
