import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Redux States
import { GET_DRAFT_ORDER, UPDATE_DRAFT_ORDER } from "./actionType";

import { getDraftOrderSuccess, getDraftOrderFail, updateDraftOrderSuccess, updateDraftOrderFail } from "./action";

import { draftOrdersAPI } from "../../../../helpers/resource_helper";

function* getDraftOrder({ payload: { handleAfterSubmit } }) {
  try {
    const response = yield call(draftOrdersAPI.list);
    yield put(getDraftOrderSuccess(response));
    yield call(handleAfterSubmit, response);
  } catch (error) {
    yield put(getDraftOrderFail(error));
  }
}

function* onUpdateDraftOrder({ payload: { draftOrder, handleAfterSubmit } }) {
  try {
    const response = yield call(draftOrdersAPI.put, draftOrder);
    yield put(updateDraftOrderSuccess(response));
    yield call(handleAfterSubmit, response);
  } catch (error) {
    yield put(updateDraftOrderFail(error));
  }
}

export function* watchGetDraftOrder() {
  yield takeEvery(GET_DRAFT_ORDER, getDraftOrder);
}

export function* watchUpdateDraftOrder() {
  yield takeEvery(UPDATE_DRAFT_ORDER, onUpdateDraftOrder);
}

function* draftOrdersSaga() {
  yield all([fork(watchUpdateDraftOrder), fork(watchGetDraftOrder)]);
}

export default draftOrdersSaga;
