import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Card, CardBody, Col, Container, Input, Label, Row, FormFeedback, Alert } from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { Form, Formik } from "formik";

import withRouter from "../../Components/Common/withRouter";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import ButtonTheme from "../../Components/Common/ButtonTheme";
import noliLogo from "../../assets/images/noliwms-logo.webp";

// actions
import { loginUser, resetLoginFlag } from "../../store/actions";
import { Fragment } from "react";
import InputTheme from "../../Components/Common/InputTheme";
import { getSapoQRCodeDetails } from "../../helpers/service_helper";
import ScrollToError from "../../Components/Common/ScrollToError";

const QRCodePage = (props) => {
  const {
    router: { t, location, navigate, params },
  } = props;

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const getQRCode = async (id) => {
    try {
      setLoading(true);
      const res = await getSapoQRCodeDetails(id);
      if (res) {
        navigate(`/qr/${id}`);
      }
    } catch (e) {
      setError(String(e));
    } finally {
      setLoading(false);
    }
  };

  const validation = {
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      order_number: "",
    },
    validationSchema: Yup.object({
      order_number: Yup.string().required(t("Please Enter Order Number")),
    }),
    onSubmit: (values) => {
      getQRCode(values.order_number);
    },
  };

  document.title = `${t("QR Code")} | OptiWarehouse`;
  return (
    <Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={noliLogo} alt="" height="100" />
                    </Link>
                  </div>
                  <h1
                    className="display-5 mt-2"
                    style={{
                      fontWeight: 600,
                      textTransform: "uppercase",
                      color: "#fff",
                      textShadow: "3px 4px #f7b84b",
                    }}
                  >
                    OptiWarehouse
                  </h1>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">{t("Welcome!")}</h5>
                      {/* <p className="text-muted">{t("Sign in to continue to OptiWarehouse.")}</p> */}
                    </div>
                    {error && error ? <Alert color="danger"> {t("Can not find order")} </Alert> : null}
                    <div className="p-2 mt-4">
                      <Formik
                        enableReinitialize={true}
                        initialValues={validation.initialValues}
                        validationSchema={validation.validationSchema}
                        onSubmit={validation.onSubmit}
                      >
                        {({ values, setFieldValue }) => (
                          <Form>
                            <ScrollToError />
                            <div className="mb-3">
                              <InputTheme
                                name="order_number"
                                disabled={loading}
                                placeholder={t("Enter order number")}
                              />
                            </div>

                            <div className="mt-4">
                              <ButtonTheme
                                id="sign-in-button"
                                className="btn btn-primary w-100"
                                type="submit"
                                loading={loading}
                                disabled={loading}
                              >
                                {t("Search")}
                              </ButtonTheme>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </Fragment>
  );
};

export default withRouter(QRCodePage);
