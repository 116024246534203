import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";

// Redux States
import {
  LIST_STOCK_ADJUSTMENT,
  DELETE_STOCK_ADJUSTMENT,
  ADD_NEW_STOCK_ADJUSTMENT,
  UPDATE_STOCK_ADJUSTMENT,
  GET_STOCK_ADJUSTMENT,
  BALANCE_STOCK_ADJUSTMENT,
} from "./actionType";

import {
  deleteStockAdjustmentSuccess,
  deleteStockAdjustmentFail,
  addStockAdjustmentSuccess,
  addStockAdjustmentFail,
  updateStockAdjustmentSuccess,
  updateStockAdjustmentFail,
  listStockAdjustmentSuccess,
  listStockAdjustmentFail,
  getStockAdjustmentSuccess,
  getStockAdjustmentFail,
  balanceStockAdjustmentSuccess,
  balanceStockAdjustmentFail,
} from "./action";

import { stockAdjustmentsAPI } from "../../../../helpers/resource_helper";

function* listStockAdjustment({ payload: { ...res } }) {
  try {
    const response = yield call(stockAdjustmentsAPI.list, { ...res });
    yield put(listStockAdjustmentSuccess(response));
  } catch (error) {
    yield put(listStockAdjustmentFail(error));
  }
}

function* getStockAdjustment({ payload: id }) {
  try {
    const response = yield call(stockAdjustmentsAPI.get, { id });
    yield put(getStockAdjustmentSuccess(response));
  } catch (error) {
    yield put(getStockAdjustmentFail(error));
  }
}

function* deleteStockAdjustments({ payload: { stockAdjustment, handleAfterSubmit } }) {
  try {
    const response = yield call(stockAdjustmentsAPI.customCreate, `${stockAdjustment.id}/delete`, {});
    yield put(deleteStockAdjustmentSuccess(response));
    toast.success(response?.message, { autoClose: 3000 });
    yield call(handleAfterSubmit, response);
  } catch (error) {
    yield put(deleteStockAdjustmentFail(error));
    toast.error("Stock Adjustment Delete Failed", { autoClose: 3000 });
  }
}

function* balanceStockAdjustments({ payload: { stockAdjustment, handleAfterSubmit } }) {
  try {
    const response = yield call(stockAdjustmentsAPI.customCreate, `${stockAdjustment.id}/balance`, {});
    yield put(balanceStockAdjustmentSuccess(response));
    toast.success(response?.message, { autoClose: 3000 });
    yield call(handleAfterSubmit, response);
  } catch (error) {
    yield put(balanceStockAdjustmentFail(error));
    toast.error("Stock Adjustment Balance Failed", { autoClose: 3000 });
  }
}

function* onAddNewStockAdjustment({ payload: { stockAdjustment, handleAfterSubmit } }) {
  try {
    const response = yield call(stockAdjustmentsAPI.create, stockAdjustment);
    yield put(addStockAdjustmentSuccess(response));
    toast.success("Stock Adjustment Added Successfully", { autoClose: 3000 });
    yield call(handleAfterSubmit, response);
  } catch (error) {
    yield put(addStockAdjustmentFail(error));
    toast.error("Stock Adjustment Added Failed", { autoClose: 3000 });
  }
}

function* onUpdateStockAdjustment({ payload: stockAdjustment }) {
  try {
    const response = yield call(stockAdjustmentsAPI.put, stockAdjustment);
    yield put(updateStockAdjustmentSuccess(response));
    toast.success("Stock Adjustment Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateStockAdjustmentFail(error));
    toast.error("Stock Adjustment Updated Failed", { autoClose: 3000 });
  }
}

export function* watchGetStockAdjustments() {
  yield takeEvery(LIST_STOCK_ADJUSTMENT, listStockAdjustment);
}

export function* watchDeleteStockAdjustments() {
  yield takeEvery(DELETE_STOCK_ADJUSTMENT, deleteStockAdjustments);
}

export function* watchBalanceStockAdjustments() {
  yield takeEvery(BALANCE_STOCK_ADJUSTMENT, balanceStockAdjustments);
}

export function* watchGetStockAdjustment() {
  yield takeEvery(GET_STOCK_ADJUSTMENT, getStockAdjustment);
}

export function* watchUpdateStockAdjustment() {
  yield takeEvery(UPDATE_STOCK_ADJUSTMENT, onUpdateStockAdjustment);
}

export function* watchAddNewStockAdjustment() {
  yield takeEvery(ADD_NEW_STOCK_ADJUSTMENT, onAddNewStockAdjustment);
}

function* stockAdjustmentsSaga() {
  yield all([
    fork(watchGetStockAdjustment),
    fork(watchGetStockAdjustments),
    fork(watchUpdateStockAdjustment),
    fork(watchAddNewStockAdjustment),
    fork(watchDeleteStockAdjustments),
    fork(watchBalanceStockAdjustments),
  ]);
}

export default stockAdjustmentsSaga;
