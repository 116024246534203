import { Form, Formik } from "formik";
import * as Yup from "yup";
import withRouter from "../../../../../Components/Common/withRouter";
import useMemoizedSelector from "../../../../../Components/Hooks/useMemoizedSelector";
import { toast } from "react-toastify";
import { deepCopy, formatDateTime } from "../../../../../helpers/format_helper";
import { useDispatch } from "react-redux";
import {
  addNewStockAdjustment,
  balanceStockAdjustment,
  deleteStockAdjustment,
  getStockAdjustment,
} from "../../../../../store/actions";
import { Fragment, useEffect, useRef, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import BreadCrumbCustom from "../../../../../Components/Common/BreadCrumbCustom";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import SelectTheme from "../../../../../Components/Common/SelectTheme";
import InputTheme from "../../../../../Components/Common/InputTheme";
import { StockAdjustmentStatuses } from "../../../../../Components/constants/common";
import StockItemsCard from "./Components/StockItemsCard";
import { getListUserAPI } from "../../../../../helpers/service_helper";
import BadgeCustom from "../../../../../Components/Common/BadgeCustom";
import ScrollToError from "../../../../../Components/Common/ScrollToError";
import { useSearchParams } from "react-router-dom";

const StockAdjustmentDetails = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  const { id } = params;
  document.title = `${t(!id ? "Create" : "View")} ${t("Stock Adjustment Details")} | OptiWarehouse`;
  const dispatch = useDispatch();
  const formRef = useRef();
  const [searchParams] = useSearchParams();
  const clone_stock_adjustment_id = searchParams.get("clone_stock_adjustment_id");
  const {
    stockAdjustment,
    stockAdjustmentsLoading,
    stockAdjustmentsBalanceLoading,
    stockAdjustmentsDeleteLoading,
    stockAdjustmentsSuccess,
    stockAdjustmentsError,
  } = useMemoizedSelector((state) => ({
    stockAdjustment: state.StockAdjustments.stockAdjustment,
    stockAdjustmentsLoading: state.StockAdjustments.loading,
    stockAdjustmentsBalanceLoading: state.StockAdjustments.balanceLoading,
    stockAdjustmentsDeleteLoading: state.StockAdjustments.deleteLoading,
    stockAdjustmentsSuccess: state.StockAdjustments.success,
    stockAdjustmentsError: state.StockAdjustments.error,
  }));

  const validation = {
    enableReinitialize: true,

    initialValues: {
      location: stockAdjustment?.location || null,
      checking_staff: stockAdjustment?.checking_staff || null,
      stock_line_items: stockAdjustment?.stock_line_items || [],
      note: stockAdjustment?.note || null,
      tags: stockAdjustment?.tags || null,
    },
    validationSchema: Yup.object({
      location: Yup.object().required(t("Please select location")),
      checking_staff: Yup.object().required(t("Please enter Checking staff")),
    }),
    onSubmit: (values) => {
      let payload = deepCopy(values);
      const location = { id: payload["location"]["id"], name: payload["location"]["name"] };
      const checking_staff = {
        id: payload["checking_staff"]?.["value"] || payload["checking_staff"]?.["id"],
        name: payload["checking_staff"]["label"] || payload["checking_staff"]["name"],
      };
      payload["location"] = location;
      payload["checking_staff"] = checking_staff;
      if (payload.stock_line_items.length === 0) {
        toast.error(t("Please add product"));
        return;
      }
      if (!id) {
        dispatch(
          addNewStockAdjustment(payload, (data) => {
            navigate(`/stock-adjustments/${data.id}`);
          }),
        );
      }
    },
  };

  const [users, setUsers] = useState([]);
  const getListUser = async () => {
    try {
      const company_id = JSON.parse(localStorage.getItem("auth_user"))?.UserAttributes["custom:company_id"];
      const res = await getListUserAPI({ company_id });
      const mapRes = res.Users.map((item) => {
        return {
          id: item["Attributes"].find((u) => u["Name"] === "sub")["Value"],
          name: item["Username"],
        };
      });
      setUsers(mapRes);
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangeStockAdjustmentLines = (data) => {
    formRef.current.setFieldValue("stock_line_items", data);
  };

  const handleSetValues = (data) => {
    formRef.current.setValues(data);
  };

  useEffect(() => {
    if (id) {
      dispatch(getStockAdjustment(id));
    } else {
      if (clone_stock_adjustment_id) {
        dispatch(getStockAdjustment(clone_stock_adjustment_id));
      }
      getListUser();
    }
  }, [dispatch, id, clone_stock_adjustment_id]);

  return (
    <div className="page-content" tabIndex="0">
      <Container fluid>
        <BreadCrumbCustom navigateTo="/stock-adjustments" text={"Back to stock adjustment list"}>
          {!id ? (
            <div className="hstack gap-2">
              <ButtonTheme
                type="button"
                className="btn btn-light w-md"
                onClick={() => {
                  navigate("/stock-adjustments");
                }}
                disabled={stockAdjustmentsLoading}
              >
                {t("Close")}
              </ButtonTheme>
              <ButtonTheme
                type="button"
                className="btn btn-outline-primary w-md"
                onClick={() => formRef.current.handleSubmit()}
                loading={stockAdjustmentsLoading}
                disabled={stockAdjustmentsLoading}
              >
                {t("Create")}
              </ButtonTheme>
            </div>
          ) : (
            <div className="hstack gap-2">
              <ButtonTheme
                type="button"
                className="btn btn-outline-primary w-md"
                onClick={() => {
                  navigate(`/stock-adjustments/new?clone_stock_adjustment_id=${id}`);
                }}
                loading={stockAdjustmentsDeleteLoading}
                disabled={stockAdjustmentsLoading || stockAdjustmentsDeleteLoading || stockAdjustmentsBalanceLoading}
              >
                <i class="ri-file-copy-line me-1"></i>
                {t("Copy")}
              </ButtonTheme>
              {stockAdjustment?.status === StockAdjustmentStatuses.AWAIT_CHECKING && (
                <Fragment>
                  <ButtonTheme
                    type="button"
                    className="btn btn-outline-danger w-md"
                    onClick={() => {
                      dispatch(deleteStockAdjustment(stockAdjustment, handleSetValues));
                    }}
                    loading={stockAdjustmentsDeleteLoading}
                    disabled={
                      stockAdjustmentsLoading || stockAdjustmentsDeleteLoading || stockAdjustmentsBalanceLoading
                    }
                  >
                    {t("Delete")}
                  </ButtonTheme>
                  <ButtonTheme
                    type="button"
                    className="btn btn-outline-primary w-md"
                    onClick={() => {
                      dispatch(balanceStockAdjustment(stockAdjustment, handleSetValues));
                    }}
                    loading={stockAdjustmentsBalanceLoading}
                    disabled={
                      stockAdjustmentsLoading || stockAdjustmentsDeleteLoading || stockAdjustmentsBalanceLoading
                    }
                  >
                    {t("Balance")}
                  </ButtonTheme>
                </Fragment>
              )}
            </div>
          )}
        </BreadCrumbCustom>
        <Formik
          enableReinitialize={true}
          initialValues={validation.initialValues}
          validationSchema={validation.validationSchema}
          onSubmit={validation.onSubmit}
          innerRef={formRef}
        >
          {({ values, setFieldValue, errors, touched }) => {
            return (
              <Form>
                <ScrollToError />
                <Row>
                  <Col className="col-8">
                    <Card>
                      <CardHeader>
                        {!id ? (
                          <div className="d-flex align-items-center">
                            <h5 className="card-title flex-grow-1 mb-0">{t("Voucher info")}</h5>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center">
                            <h5 className="card-title flex-grow-1 mb-0">
                              {t("Voucher info")} #{stockAdjustment?.code}
                            </h5>
                            <h5 className="flex-shrink-0 mb-0">
                              <BadgeCustom status={stockAdjustment?.status} />
                            </h5>
                          </div>
                        )}
                      </CardHeader>
                      <CardBody>
                        {!id ? (
                          <Row>
                            <Col className="col-6">
                              <div className="mb-3">
                                <SelectTheme
                                  label={"Checked branch"}
                                  name="location"
                                  placeholder={"Select A Branch"}
                                  remote={true}
                                  isObjectValueSelect={true}
                                  selectDataOrigin={true}
                                  path="inventory/locations"
                                />
                              </div>
                            </Col>
                            <Col className="col-6">
                              <div className="mb-3">
                                <SelectTheme
                                  label={"Checking staff"}
                                  name="checking_staff"
                                  placeholder={"Select A Staff"}
                                  options={users}
                                  selectDataOrigin={true}
                                  isObjectValueSelect={true}
                                />
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col lg={6}>
                              <Row>
                                <Col className="hstack justify-content-between col-4">
                                  <span>{t("Checked branch")}</span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">
                                  <span className="text-truncate">{stockAdjustment.location?.name || "---"}</span>
                                </Col>
                                <Col className="hstack justify-content-between col-4">
                                  <span>{t("Creating staff")}</span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">
                                  <span className="text-truncate">{stockAdjustment.creating_staff?.name}</span>
                                </Col>
                                <Col className="hstack justify-content-between col-4">
                                  <span>{t("Checking staff")}</span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">
                                  <span className="text-truncate">{stockAdjustment.checking_staff?.name || "---"}</span>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={6}>
                              <Row>
                                <Col className="hstack justify-content-between col-4">
                                  <span>{t("Balancing staff")}</span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">
                                  <span className="text-truncate">{stockAdjustment.balancing_staff?.name || "---"}</span>
                                </Col>
                                <Col className="hstack justify-content-between col-4">
                                  <span>{t("Created at")}</span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">
                                  {formatDateTime(stockAdjustment?.created_at)}
                                </Col>
                                <Col className="hstack justify-content-between col-4">
                                  <span>{t("Balanced at")}</span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">
                                  {stockAdjustment?.balanced_at
                                    ? formatDateTime(stockAdjustment?.balanced_at)
                                    : "---"}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="col-4">
                    <Card>
                      <CardHeader>
                        <div className="d-flex align-items-center">
                          <h5 className="card-title flex-grow-1 mb-0">{t("Additional info")}</h5>
                        </div>
                      </CardHeader>
                      <CardBody>
                        {!id ? (
                          <Row>
                            <Col className="col-12">
                              <div className="mb-3">
                                <InputTheme
                                  name="note"
                                  label="Note"
                                  disabled={stockAdjustmentsLoading}
                                  placeholder="Enter note"
                                />
                              </div>
                            </Col>
                            <Col className="col-12">
                              <div className="mb-3">
                                <InputTheme
                                  name={"tags"}
                                  label={"Tags"}
                                  type="tags"
                                  placeHolder={"Enter tags and press enter"}
                                  disabled={stockAdjustmentsLoading}
                                />
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col lg={12}>
                              <div className="mb-3">
                                <div className="fw-bolder">{t("Note")}</div>
                                <div>{values.note || "---"}</div>
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="mb-3">
                                <div className="fw-bolder">{t("Tags")}</div>
                                <div>{values.tags || "---"}</div>
                              </div>
                            </Col>
                          </Row>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="col-12">
                    <StockItemsCard
                      stockLineItems={values.stock_line_items}
                      onChangeStockAdjustment={handleChangeStockAdjustmentLines}
                      isEdit={!id}
                    />
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </div>
  );
};

export default withRouter(StockAdjustmentDetails);
