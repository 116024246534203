import {
  LIST_BLOG_CATEGORY,
  LIST_BLOG_CATEGORY_FAIL,
  LIST_BLOG_CATEGORY_SUCCESS,
  DELETE_BLOG_CATEGORY,
  DELETE_BLOG_CATEGORY_SUCCESS,
  DELETE_BLOG_CATEGORY_FAIL,
  ADD_NEW_BLOG_CATEGORY,
  ADD_BLOG_CATEGORY_SUCCESS,
  ADD_BLOG_CATEGORY_FAIL,
  UPDATE_BLOG_CATEGORY,
  UPDATE_BLOG_CATEGORY_SUCCESS,
  UPDATE_BLOG_CATEGORY_FAIL,
  GET_BLOG_CATEGORY,
  GET_BLOG_CATEGORY_FAIL,
  GET_BLOG_CATEGORY_SUCCESS,
} from "./actionType";

export const listBlogCategory = (options) => ({
  type: LIST_BLOG_CATEGORY,
  payload: options,
});

export const listBlogCategoryFail = (error) => ({
  type: LIST_BLOG_CATEGORY_FAIL,
  payload: error,
});

export const listBlogCategorySuccess = (categories) => ({
  type: LIST_BLOG_CATEGORY_SUCCESS,
  payload: categories,
});

export const getBlogCategory = (BlogcategoryID) => ({
  type: GET_BLOG_CATEGORY,
  payload: BlogcategoryID,
});

export const getBlogCategoryFail = (error) => ({
  type: GET_BLOG_CATEGORY_FAIL,
  payload: error,
});

export const getBlogCategorySuccess = (Blogcategory) => ({
  type: GET_BLOG_CATEGORY_SUCCESS,
  payload: Blogcategory,
});

export const deleteBlogCategory = (Blogcategory) => ({
  type: DELETE_BLOG_CATEGORY,
  payload: Blogcategory,
});

export const deleteBlogCategorySuccess = (Blogcategory) => ({
  type: DELETE_BLOG_CATEGORY_SUCCESS,
  payload: Blogcategory,
});

export const deleteBlogCategoryFail = (error) => ({
  type: DELETE_BLOG_CATEGORY_FAIL,
  payload: error,
});

export const updateBlogCategory = (Blogcategory, handleAfterSubmit = () => {}) => ({
  type: UPDATE_BLOG_CATEGORY,
  payload: { Blogcategory, handleAfterSubmit },
});

export const updateBlogCategorySuccess = (Blogcategory) => ({
  type: UPDATE_BLOG_CATEGORY_SUCCESS,
  payload: Blogcategory,
});

export const updateBlogCategoryFail = (error) => ({
  type: UPDATE_BLOG_CATEGORY_FAIL,
  payload: error,
});

export const addNewBlogCategory = (Blogcategory, handleAfterSubmit = () => {}) => ({
  type: ADD_NEW_BLOG_CATEGORY,
  payload: { Blogcategory, handleAfterSubmit },
});

export const addBlogCategorySuccess = (Blogcategory) => ({
  type: ADD_BLOG_CATEGORY_SUCCESS,
  payload: Blogcategory,
});

export const addBlogCategoryFail = (error) => ({
  type: ADD_BLOG_CATEGORY_FAIL,
  payload: error,
});
