import React, { Fragment, useState } from "react";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import TransactionModal from "../../../Ecommerce/Finance/Transactions/TransactionModal";
import { useNavigate } from "react-router-dom";

const AllActionModal = ({
  show,
  onCloseClick,
  addProductToCart,
  refShortcutButton,
  refShortcutButtonRightMenu,
  refShortcutButtonOrder,
  refShortcutButtonHeader,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [transactionModal, setTranSactionModal] = useState(false);
  const Menus = [
    {
      icon: "ri-exchange-dollar-line",
      label: "Change price group",
      description: "Information about policies applicable to each order",
      onClick: () => {
        refShortcutButton.current.onTogglePriceGroup();
        onCloseClick();
      },
    },
    {
      icon: "mdi mdi-brightness-percent",
      label: "Promotion (F8)",
      description: "Apply promotions to orders",
      onClick: () => {
        refShortcutButton.current.onTogglePromotion();
        onCloseClick();
      },
    },
    {
      icon: "ri-add-fill",
      label: "Add service (F9)",
      description: "Services added to orders will not be recorded in inventory",
      onClick: () => {
        refShortcutButton.current.onAddService();
        onCloseClick();
      },
    },
    {
      icon: "mdi mdi-label-percent",
      label: "Discount order (F6)",
      description: "Add discount to order",
      onClick: () => {
        refShortcutButtonRightMenu.current.toggleDiscount();
        onCloseClick();
      },
    },
    {
      icon: "mdi mdi-printer",
      label: "Print latest order (Alt+1)",
      description: "Print the most recently created order",
    },
    {
      icon: "mdi mdi-account-group",
      label: "Customer info",
      description: "View general customer information in the application",
      onClick: () => {
        refShortcutButton.current.onToggleCustomerInfo();
        onCloseClick();
      },
    },
    {
      icon: "ri-article-line",
      label: "View order list",
      description: "Visit the store's management page",
      onClick: () => {
        window.open("/orders", "_blank");
      },
    },
    {
      icon: "ri-settings-4-line",
      label: "General setting",
      description: "General setup for POS orders",
    },
    // {
    //   icon: "ri-map-pin-line",
    //   label: "Change location",
    //   description: "Switch to selling at another location",
    //   onClick: () => {
    //     refShortcutButtonHeader.current.onToggleOnLocation();
    //     onCloseClick();
    //   },
    // },
    {
      icon: "bx bx-money",
      label: "Create receipts/payments",
      description: "Create receipts/payments to record for the store",
      onClick: () => {
        setTranSactionModal(true);
        onCloseClick();
      },
    },
    {
      icon: "mdi mdi-arrow-left-top-bold",
      label: "Exchange and return goods",
      description: "Create return and exchange orders for other products for customers",
      onClick: () => {
        refShortcutButton.current.onToggleReturnModal();
        onCloseClick();
      },
    },
    {
      icon: "mdi mdi-trash-can",
      label: "Delete all products",
      description: "Delete all products on the order",
      onClick: () => {
        refShortcutButton.current.onRemoveAllProducts();
        onCloseClick();
      },
    },
    {
      icon: "bx bxs-report",
      label: "View report",
      description: "Summary of information about sales situation",
      onClick: () => {
        refShortcutButton.current.onToggleReport();
        onCloseClick();
      },
    },
    {
      icon: "bx bxs-report",
      label: "View shift",
      description: "Summary of information about this shift",
      onClick: () => {
        refShortcutButton.current.onToggleShift();
        onCloseClick();
      },
    },
    {
      icon: "bx bx-devices",
      label: "Connect the secondary display",
      description: "Connect to secondary displays for single information display",
      onClick: () => {
        refShortcutButtonHeader.current.onToggleRemote();
        onCloseClick();
      },
    },
    {
      icon: "ri-survey-line",
      label: "Select automatic lot (Alt+5)",
      description: "Select lots automatically",
    },
    {
      icon: "bx bx-wifi",
      label: "Offline Sales",
      description: "Convert Online sales version to Offline",
    },
    {
      icon: "ri-settings-4-line",
      label: "Customize function buttons",
      description: "Select the function buttons displayed on the sales screen",
    },
    {
      icon: "ri-play-circle-fill",
      label: "See the instructions",
      description: "See operating instructions on the sales screen at the counter",
    },
    {
      icon: "ri-scales-2-line",
      label: "Connect the electronic scale",
      description: "Connect the electronic scale",
    },
  ];
  return (
    <Fragment>
      <TransactionModal
        isOpen={transactionModal}
        toggle={() => {
          setTranSactionModal(false);
        }}
      />
      <Modal fade={true} isOpen={show} toggle={onCloseClick} centered={true} size="xl" scrollable>
        <ModalHeader toggle={onCloseClick}>{t("See more operations")}</ModalHeader>
        <ModalBody className="py-3 px-5">
          <Row>
            {Menus.map((item, index) => {
              return (
                <Col key={index} lg={4} className="mb-2">
                  <Button
                    className="btn btn-ghost-primary w-100 h-100"
                    onClick={item?.onClick ? item?.onClick : () => {}}
                    disabled={!item?.onClick}
                  >
                    <div className="hstack text-start gap-3">
                      <div className="flex-shink-0">
                        <i className={`${item.icon} fs-1`}></i>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="fw-bolder">{t(item.label)}</h5>
                        <p className="text-muted">{t(item.description)}</p>
                      </div>
                    </div>
                  </Button>
                </Col>
              );
            })}
          </Row>
        </ModalBody>
        <ModalFooter>
          <ButtonTheme type="button" className="btn w-sm btn-light" onClick={onCloseClick}>
            {t("Close")}
          </ButtonTheme>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default AllActionModal;
