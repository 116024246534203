import React, { useEffect, useState, useImperativeHandle, useRef } from "react";
import { withTranslation } from "react-i18next";
import { Dropdown, DropdownItem, DropdownMenu } from "reactstrap";

import { customersAPI } from "../../../../helpers/resource_helper";

import Loader from "../../../../Components/Common/Loader";
import { useListData } from "../../../../Components/Hooks/useListData";
import InfiniteScroll from "react-infinite-scroll-component";
import LottieIcon from "../../../../Components/Common/LottieIcon";
import FullNameRender from "../../../../Components/Common/FullnameRender";

const CustomersSearchDropdown = ({
  onSelectCustomer,
  width = "100%",
  toggleOnCustomerModal,
  t,
  customerRef,
  className = "form-control form-control-icon rounded-1",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { page, loading, hasMore, items, query, debouncedQuery, onChangeQuery, onSearch, listData } = useListData({
    resource: customersAPI,
    isScroll: true,
  });

  const dropdownRef = useRef(null);

  useImperativeHandle(customerRef, () => ({
    onFocusCustomerSearch: () => {
      toggleOn();
      document.getElementById("search-customers").focus();
    },
  }));

  const toggleOn = () => {
    setIsOpen(true);
  };
  const toggleOff = () => {
    setIsOpen(false);
    onChangeQuery("");
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      toggleOff();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    isOpen && onSearch();
  }, [debouncedQuery, isOpen]);

  return (
    <div ref={dropdownRef}>
      <div className="d-flex form-icon mb-1">
        <input
          type="text"
          value={query}
          onClick={toggleOn}
          autoComplete="off"
          onChange={(e) => {
            onChangeQuery(e.target.value);
          }}
          className={className}
          id="search-customers"
          placeholder={t("Add customer to this order (F4)")}
        />
        <i className="mdi mdi-magnify search-widget-icon"></i>
        {/* <div className="position-absolute end-0">
          <ButtonTheme
            type="button"
            className="btn btn-icon btn-ghost-primary rounded-circle hstack"
            onClick={toggleOnCustomerModal}
          >
            <i className="bx bx-plus text-muted fs-22"></i>
          </ButtonTheme>
        </div> */}
      </div>
      <Dropdown isOpen={isOpen} toggle={toggleOff}>
        <DropdownMenu className="p-0 mt-1" style={{ width }}>
          <DropdownItem className="border-bottom position-sticky top-0" onClick={toggleOnCustomerModal}>
            <div className="d-flex">
              <span className="me-3 text-center d-flex align-items-center" style={{ width: "2rem" }}>
                <i className="bx bx-plus fs-22 text-primary"></i>
              </span>
              <h5 className="flex-grow-1 fw-semibold mb-0 d-flex align-items-center text-primary">
                {t("Add new customer")}
              </h5>
            </div>
          </DropdownItem>
          {loading ? (
            <div className="p-3">
              <Loader />
            </div>
          ) : (
            <div id="customer-search-drop-down" style={{ maxHeight: "400px", overflow: "auto" }}>
              <InfiniteScroll
                dataLength={items.length}
                next={() => {
                  listData(query, page + 1);
                }}
                hasMore={hasMore}
                loader={<Loader />}
                scrollableTarget="customer-search-drop-down"
              >
                {items.length > 0 ? (
                  items.map((item, index) => (
                    <DropdownItem key={index} onClick={() => onSelectCustomer(item)} className="border-bottom">
                      <h5 className="fw-semibold">
                        <FullNameRender first_name={item.first_name} last_name={item.last_name} />
                      </h5>
                      <div className="fs-13 text-muted">
                        <p className="mb-1">{item.phone}</p>
                      </div>
                    </DropdownItem>
                  ))
                ) : (
                  <div className="py-4 text-center">
                    <LottieIcon
                      src="https://cdn.lordicon.com/msoeawqm.json"
                      style={{ width: "72px", height: "72px" }}
                    />
                    <div className="mt-4">
                      <h5>{t("Sorry! No Result Found")}</h5>
                    </div>
                  </div>
                )}
              </InfiniteScroll>
            </div>
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default withTranslation()(CustomersSearchDropdown);
