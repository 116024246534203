import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions";

//Include Both Helper File with needed methods
import { forgotPasswordAPI } from "../../../helpers/AuthAPI";

function* forgetUser({ payload: { user, history } }) {
  try {
    const response = yield call(forgotPasswordAPI, user);
    if (response) {
      yield put(userForgetPasswordSuccess("Reset link are sended to your mailbox, check there first"));
    }
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
