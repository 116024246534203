import { useTranslation } from "react-i18next";
import { Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import ImageCustom from "./ImageCustom";
import { Tooltip } from "antd";
import InputStep from "./InputStep";
import { deepCopy, formatVNDCurrency } from "../../helpers/format_helper";
import { DiscountTypes } from "../constants/common";
import ButtonTheme from "./ButtonTheme";
import { Fragment, useEffect, useState } from "react";
import ServiceNameInput from "./Input/ServiceNameInput";
import { Link } from "react-router-dom";
import InputNoteField from "./Input/NoteInput";
import { toast } from "react-toastify";
import SimpleBar from "simplebar-react";
import InputTheme from "./InputTheme";
import SelectTheme from "./SelectTheme";

const OrderLineItemsTable = ({
  maxHeight = "600px",
  isEdit,
  discount_id,
  products,
  divClass,
  tableClass,
  theadClass,
  trClass,
  discount,
  discount_details,
  removeProductFromCart,
  onChangeQuantity,
  onChangeOrderLineItem = () => {},
  onChangeOrderLineItemUnit = () => {},
  noDataElement,
  selectedLocation,
  selectedPriceGroup,
}) => {
  const { t } = useTranslation();
  const ViewConditionsCase = [DiscountTypes.BY_PRODUCT, DiscountTypes.BY_QUANTITY];

  return (
    <div className={divClass} style={{ maxHeight }}>
      <Table hover className={tableClass}>
        <thead className={theadClass}>
          <tr className={trClass}>
            <th>{t("Items No.")}</th>
            <th>{t("Image")}</th>
            <th>{t("Name")}</th>
            <th className="text-center">{t("Quantity")}</th>
            <th className="text-end">{t("Available")}</th>
            {isEdit && <th className="text-end">{t("Unit")}</th>}
            {isEdit && <th className="text-end">{t("Price")}</th>}
            <th className="text-end">{t("Discount")}</th>
            <th className="text-end">{t("Total Amount")}</th>
            {isEdit && <th></th>}
          </tr>
        </thead>

        <tbody>
          {products.length === 0 ? (
            <tr>
              <td colSpan={1000}> {noDataElement}</td>
            </tr>
          ) : (
            products.map((product, index) => {
              const units = (product?.variants_with_same_original_sku ?? []).map((item) =>
                !item?.unit ? { id: "Default", name: "Default", data: null } : { ...item.unit, data: item },
              );
              let discountValue = product.unit_price - product.sale_price;
              if (ViewConditionsCase.includes(discount?.type)) {
                const effectiveItem = discount_details.find((item) => item.sku === product.sku);
                if (effectiveItem) {
                  discountValue = effectiveItem.unit_price - effectiveItem.sale_price;
                }
              }
              let total = product.quantity * product.sale_price;
              if (discount_id && ViewConditionsCase.includes(discount?.type)) {
                const effectiveItem = discount_details.find((item) => item.sku === product.sku);
                if (effectiveItem) {
                  total = effectiveItem.quantity * effectiveItem.sale_price;
                }
              }
              return (
                <tr
                  style={{ verticalAlign: "middle" }}
                  key={index}
                  onClick={() => {
                    // onRowClick(row);
                  }}
                >
                  <td>{products.length - index}</td>
                  {!product?.custom ? (
                    <Fragment>
                      <td>
                        <ImageCustom image={product.image_url} name={product.name} avatarSize={"avatar-sm"} />
                      </td>
                      <td>
                        <div className="d-flex flex-column justify-content-center">
                          <h5 className="fs-15">
                            <Tooltip title={product.name}>
                              <Link
                                id={`order-items-product-name-${index}`}
                                to={{ pathname: `/products/${product.product_id}`, state: { sku: product.sku } }}
                                className="d-inline-block text-truncate"
                                style={{ maxWidth: "400px" }}
                                target="_blank"
                              >
                                {product.name}
                              </Link>
                            </Tooltip>
                          </h5>
                          <p className="text-muted fw-medium mb-1">Sku: {product.sku}</p>
                          <p
                            className="text-muted fw-medium mb-1 d-inline-block text-truncate"
                            style={{ maxWidth: "400px" }}
                          >
                            {t("Location")}: {product?.location?.name}
                          </p>
                          <div className="text-muted fw-medium">
                            <InputNoteField
                              onChangeOrderLineItem={onChangeOrderLineItem}
                              product={product}
                              i={index}
                              isEdit={isEdit}
                            />
                          </div>
                        </div>
                      </td>
                    </Fragment>
                  ) : (
                    <td colSpan={2}>
                      <ServiceNameInput
                        className={"form-control border-0 border-bottom"}
                        placeholder={"Service name"}
                        onChangeItem={onChangeOrderLineItem}
                        index={index}
                        product={product}
                        isEdit={isEdit}
                      />
                    </td>
                  )}
                  <td className="text-center">
                    {isEdit ? (
                      <InputStep index={index} onChangeQuantity={onChangeQuantity} quantity={product?.quantity ?? 0} />
                    ) : (
                      product?.quantity ?? 0
                    )}
                  </td>
                  {isEdit && (
                    <td>
                      <div className="fw-medium text-center">{product?.available || 0}</div>
                    </td>
                  )}
                  {isEdit && (
                    <td>
                      {units.length > 1 ? (
                        <UnitSelect
                          index={index}
                          product={product}
                          onChangeItem={onChangeOrderLineItemUnit}
                          unitOptions={units}
                          selectedLocation={selectedLocation}
                          selectedPriceGroup={selectedPriceGroup}
                        />
                      ) : (
                        <div className="text-center">{t("Default")}</div>
                      )}
                    </td>
                  )}
                  <td>
                    {isEdit ? (
                      <PriceInput
                        index={index}
                        product={product}
                        onChangeItem={onChangeOrderLineItem}
                        placeholder={"Enter price"}
                        className={"border-0 border-bottom text-end"}
                      />
                    ) : (
                      formatVNDCurrency(product.unit_price)
                    )}
                  </td>
                  <td>
                    <div className="fw-medium">
                      {isEdit && !discount_id ? (
                        <DiscountInput onChangeOrderLineItem={onChangeOrderLineItem} product={product} index={index} />
                      ) : (
                        <div className="text-end">{formatVNDCurrency(discountValue)}</div>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="fw-medium text-end">{formatVNDCurrency(total)}</div>
                  </td>
                  {isEdit && (
                    <td>
                      <ButtonTheme
                        type="button"
                        className="btn btn-outline-danger btn-icon waves-effect waves-light"
                        onClick={() => removeProductFromCart(index)}
                      >
                        <i className="ri-delete-bin-5-line"></i>
                      </ButtonTheme>
                    </td>
                  )}
                </tr>
              );
            })
          )}
          {discount_details &&
            [
              DiscountTypes.GIFT_BY_ORDER_TOTAL,
              DiscountTypes.GIFT_BY_PURCHASE_PRODUCT,
              DiscountTypes.BY_PURCHASE_PRODUCT,
              DiscountTypes.PRODUCT_BY_ORDER_TOTAL,
            ].includes(discount?.type) &&
            discount_details.map((product, index) => {
              let discountValue = product.unit_price - product.sale_price;
              let total = product.quantity * product.sale_price;
              return (
                <tr key={index} onClick={() => {}} className="bg-soft-primary" style={{ verticalAlign: "middle" }}>
                  <td>
                    <ImageCustom image={product.image_url} name={product.name} avatarSize={"avatar-sm"} />
                  </td>
                  <td>
                    <div className="d-flex flex-column justify-content-center">
                      <h5 className="fs-15">
                        <Tooltip title={product.name}>
                          <Link
                            id={`order-items-product-name-${index}`}
                            to={{ pathname: `/products/${product.product_id}`, state: { sku: product.sku } }}
                            className="d-inline-block text-truncate"
                            style={{ maxWidth: "400px" }}
                            target="_blank"
                          >
                            {product.name}
                          </Link>
                        </Tooltip>
                      </h5>
                      <p className="text-muted fw-medium mb-1">Sku: {product.sku}</p>
                      <p
                        className="text-muted fw-medium mb-1 d-inline-block text-truncate"
                        style={{ maxWidth: "400px" }}
                      >
                        {t("Location")}: {product?.location?.name}
                      </p>
                    </div>
                  </td>
                  <td className="text-center">{product?.quantity || 0}</td>
                  <td>
                    <div className="text-end">{formatVNDCurrency(product?.unit_price)}</div>
                  </td>
                  <td>
                    <div className="fw-medium">
                      <div className="text-end">{formatVNDCurrency(discountValue)}</div>
                    </div>
                  </td>
                  <td>
                    <div className="fw-medium text-end">{formatVNDCurrency(total)}</div>
                  </td>
                  <td>
                    <i className="mdi mdi-brightness-percent text-primary fs-2"></i>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

const DiscountInput = ({ onChangeOrderLineItem = () => {}, product, index }) => {
  const { t } = useTranslation();

  const [discountPercent, setDiscountPercent] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);
  const [type, setType] = useState("value");

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (isOpen) {
      setDiscountValue(product?.unit_price - product?.sale_price);
      setDiscountPercent(0);
      setType("value");
    }
  }, [isOpen]);

  const toggle = () => {
    setIsOpen(false);
  };

  const handleApply = () => {
    if (type === "value" && product.unit_price < discountValue) {
      toast.error(t("Discount price is more than total price!!!"));
      return;
    }
    const discount = type === "value" ? discountValue : (product.unit_price * discountPercent) / 100;
    const sale_price = product.unit_price - discount;

    const newProduct = deepCopy(product);
    onChangeOrderLineItem(index, { ...newProduct, sale_price, discount });
    toggle();
  };
  return (
    <Fragment>
      <ButtonTheme
        style={{ color: "var(--vz-body-color)" }}
        className="btn btn-outline-light border-0 border-bottom w-100 p-0 py-1 text-end"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {formatVNDCurrency(product?.unit_price - product?.sale_price)}
      </ButtonTheme>
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          {!product?.custom ? `${t("Product details")}: ${product.sku}` : t("Service details")}
        </ModalHeader>
        <ModalBody>
          <Row className="mb-3">
            <Col className="hstack" lg={12}>
              <Label className="me-3">{t("Discount")}</Label>
              <ButtonTheme
                className={`btn w-md waves-effect waves-light rounded-0 rounded-start ${
                  type === "value" ? "btn-primary" : "btn-outline-primary"
                }`}
                onClick={() => {
                  setType("value");
                }}
              >
                {t("Value")}
              </ButtonTheme>
              <ButtonTheme
                className={`btn w-md waves-effect waves-light rounded-0 rounded-end me-3 ${
                  type === "percent" ? "btn-primary" : "btn-outline-primary"
                }`}
                onClick={() => {
                  setType("percent");
                }}
              >
                %
              </ButtonTheme>
              {type === "value" ? (
                <InputTheme
                  isForm={false}
                  format="price"
                  min={0}
                  className="form-control border-0 border-bottom text-end w-md"
                  onChange={(data) => {
                    if (data < 0 || data === "") {
                      setDiscountValue(0);
                    } else {
                      setDiscountValue(data);
                    }
                  }}
                  value={discountValue}
                />
              ) : (
                <InputTheme
                  isForm={false}
                  format="percent"
                  min={0}
                  max={100}
                  className="form-control border-0 border-bottom text-end w-md"
                  onChange={(data) => {
                    if (data < 0 || data === "") {
                      setDiscountPercent(0);
                    } else if (data > 100) {
                      setDiscountPercent(100);
                    } else {
                      setDiscountPercent(data);
                    }
                  }}
                  value={discountPercent}
                />
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <ButtonTheme className="btn btn-light w-sm" onClick={toggle}>
            {t("Close")}
          </ButtonTheme>
          <ButtonTheme className="btn btn-primary w-sm" onClick={handleApply}>
            {t("Apply")}
          </ButtonTheme>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

const PriceInput = ({ onChangeItem = () => {}, product, index, className, placeholder }) => {
  const { t } = useTranslation();
  const [price, setPrice] = useState(product.unit_price);
  useEffect(() => {
    setPrice(product.unit_price);
  }, [product]);
  return (
    <InputTheme
      isForm={false}
      formNoMargin={true}
      format="price"
      className={className}
      value={price}
      placeholder={t(placeholder)}
      onChange={(data) => {
        setPrice(data);
      }}
      onBlur={(e) => {
        const data = Number(price);
        const newProduct = deepCopy(product);
        newProduct.unit_price = data;
        newProduct.sale_price = data;
        onChangeItem(index, newProduct);
      }}
    />
  );
};

const UnitSelect = ({
  product,
  onChangeItem = () => {},
  index,
  unitOptions = [],
  selectedLocation,
  selectedPriceGroup,
}) => {
  const [unit, setUnit] = useState(null);
  useEffect(() => {
    const selectedProduct = product.variants_with_same_original_sku.find((item) => {
      return item.sku === product.sku;
    });
    setUnit(selectedProduct?.unit?.id ?? "Default");
  }, [product?.unit]);

  const handleSelectOption = (data) => {
    const selectedProduct = product.variants_with_same_original_sku.find((item) => {
      if (data.value === "Default") {
        return !item?.unit;
      } else {
        return item?.unit?.id === data.value;
      }
    });
    const available =
      (selectedProduct?.inventories || []).find(
        (item) => item.location_id === (selectedLocation?.value || selectedLocation?.id),
      )?.available || 0;
    const price = (selectedProduct?.prices || []).find(
      (priceItem) => priceItem.price_group.id === (selectedPriceGroup?.value || selectedPriceGroup?.id),
    )?.price;
    const newItem = {
      prices: selectedProduct.prices ?? [],
      unit_price: price || selectedProduct?.prices?.[0]?.price || 0,
      sale_price: price || selectedProduct?.prices?.[0]?.price || 0,
      sku: selectedProduct?.sku ?? "",
      name: selectedProduct?.name ?? "",
      variant_name: selectedProduct?.name ?? "",
      image_url: selectedProduct.images?.[0]?.url ?? "",
      location: selectedLocation?.value
        ? { id: selectedLocation?.value, name: selectedLocation?.label }
        : selectedLocation,
      category: selectedProduct?.category
        ? { id: selectedProduct?.category?.id, name: selectedProduct?.category?.name }
        : null,
      brand: selectedProduct?.brand ? { id: selectedProduct?.brand?.id, name: selectedProduct?.brand?.name } : null,
      quantity: 1,
      unit: data?.data,
      variants_with_same_original_sku: product?.variants_with_same_original_sku ?? null,
      available,
    };
    onChangeItem(index, newItem);
  };
  return (
    <SelectTheme
      placeholder="Select unit"
      isForm={false}
      options={unitOptions}
      value={unit}
      onChange={(data) => {
        handleSelectOption(data);
      }}
      isClearable={false}
    />
  );
};

export default OrderLineItemsTable;
