// Actions
export const LIST_TERMINAL = "LIST_TERMINAL";
export const LIST_TERMINAL_SUCCESS = "LIST_TERMINAL_SUCCESS";
export const LIST_TERMINAL_FAIL = "LIST_TERMINAL_FAIL";

export const GET_TERMINAL = "GET_TERMINAL";
export const GET_TERMINAL_SUCCESS = "GET_TERMINAL_SUCCESS";
export const GET_TERMINAL_FAIL = "GET_TERMINAL_FAIL";

export const DELETE_TERMINAL = "DELETE_TERMINAL";
export const DELETE_TERMINAL_SUCCESS = "DELETE_TERMINAL_SUCCESS";
export const DELETE_TERMINAL_FAIL = "DELETE_TERMINAL_FAIL";

export const ADD_NEW_TERMINAL = "ADD_NEW_TERMINAL";
export const ADD_TERMINAL_SUCCESS = "ADD_TERMINAL_SUCCESS";
export const ADD_TERMINAL_FAIL = "ADD_TERMINAL_FAIL";

export const UPDATE_TERMINAL = "UPDATE_TERMINAL";
export const UPDATE_TERMINAL_SUCCESS = "UPDATE_TERMINAL_SUCCESS";
export const UPDATE_TERMINAL_FAIL = "UPDATE_TERMINAL_FAIL";
