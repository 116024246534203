import { QRCode, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useImperativeHandle, useState } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import ButtonTheme from "./ButtonTheme";
import ImageCustom from "./ImageCustom";

import step1 from "../../assets/images/pos/remote-screen/step1.svg";
import step2 from "../../assets/images/pos/remote-screen/step2.svg";
import step3 from "../../assets/images/pos/remote-screen/step3.svg";
import { Link } from "react-router-dom";
import CopyableTextTooltip from "./CopyableTextTooltip";
import { useProfile } from "../Hooks/useProfile";

const RemoteScreen = ({ refBtn }) => {
  const { userProfile } = useProfile();
  const { t } = useTranslation();
  const socket_link = `${window.location.href}/pair-device?user_id=${userProfile?.UserAttributes?.sub}`;

  const [show, setShow] = useState(false);
  const toggle = () => {
    setShow(false);
  };

  useImperativeHandle(refBtn, () => ({
    onToggleRemote: () => setShow(true),
  }));

  return (
    <div className="ms-1 header-item d-sm-flex">
      <Tooltip title={t("Remote screen")}>
        <ButtonTheme
          type="button"
          className="btn btn-icon rounded-circle light-dark-mode"
          onClick={() => {
            setShow(true);
          }}
        >
          <i className="ri-airplay-line fs-22"></i>
        </ButtonTheme>
      </Tooltip>
      <Modal isOpen={show} toggle={toggle} size="xl">
        <ModalHeader toggle={toggle}>{t("Connect the secondary display")}</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={4} className="p-3 d-flex flex-column gap-3">
              {t(
                "Tính năng cho phép bạn kết nối các thiết bị khác (smartphone, tablet, máy tính) để hiển thị thông tin đơn hàng cho người mua hàng.",
              )}
              <div className="hstack justify-content-center p-3">
                <QRCode value={socket_link} size={250} errorLevel="H" />
              </div>
            </Col>
            <Col lg={8} className="bg-soft-primary p-3 rounded">
              <h6>Hướng dẫn kết nối</h6>
              <p>
                Cách 1: Truy cập đường{" "}
                <Link to={socket_link} target="_blank">
                  link này
                </Link>{" "}
                hoặc{" "}
                <CopyableTextTooltip text={socket_link} isTooltip={true}>
                  sao chép link
                </CopyableTextTooltip>{" "}
                vào trình duyệt mới
              </p>
              <p>Cách 2: Sử dụng thiết bị làm màn hình phụ để quét mã QR Kết nối</p>
              <Row className="text-center">
                <Col className="col-4">
                  <ImageCustom image={step1} isPreview={false} />
                  <p>1. Mở camera trên thiết bị hoặc ứng dụng hỗ trợ quét mã QR</p>
                </Col>
                <Col className="col-4">
                  <ImageCustom image={step2} isPreview={false} />
                  <p>2. Quét mã QR Kết nối và nhấp vào thông báo truy cập link</p>
                </Col>
                <Col className="col-4">
                  <ImageCustom image={step3} isPreview={false} />
                  <p>3. Màn hình hiển thị thông tin đơn hàng cho khách hàng</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default RemoteScreen;
