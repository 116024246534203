export const LOCATION = {
  _list: "/locations",
};
export const INVENTORY = {
  _list: "/inventory-items",
};
export const STOCK_ADJUSTMENT = {
  _list: "/stock-adjustments",
};
export const STOCK_RELOCATE = {
  _list: "/stock-relocates",
};