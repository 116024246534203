import { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import OfflineMode from "../../../../Components/Common/OfflineMode";
import RemoteScreen from "../../../../Components/Common/RemoteScreen";
import AdminHomepage from "../../../../Components/Common/AdminHomepage";
import StaffSelectForm from "./Components/StaffSelectForm";
import LightDark from "../../../../Components/Common/LightDark";
import LocationSelectForm from "./Components/LocationSelectForm";
import FullScreenDropdown from "../../../../Components/Common/FullScreenDropdown";
import SearchDropdown from "../../../../Components/Common/ProductsSearchDropDown";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import DeleteOrderModal from "../Modal/DeleteOrderModal";
import HorizontalScrollingMenu from "../../../../Components/Common/ScrollMenu/HorizontalScrollingMenu";
import LanguageDropdown from "../../../../Components/Common/LanguageDropdown";

import { changeLayoutMode } from "../../../../store/actions";
import ShortcutGuide from "../../../../Components/Common/ShortcutGuide";

const POSHeader = ({
  selectedPriceGroup,
  selectedLocation,
  currentOrders,
  handleChangeLocation,
  handleChangeStaff,
  selectedStaff,
  selectedOrder,
  handleChangeSelectedOrder,
  deleteOrder,
  addNewCurrentOrders,
  addProductToCart,
  showDropDownProductMenu,
  toggleOffDropDownProductMenu,
  toggleOnDropDownProductMenu,
  scrollContainerRef,
  refShortcutButtonHeader,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const refFullScreenBtn = useRef(null);
  const refLocationBtn = useRef(null);
  const refRemoteBtn = useRef(null);
  const { layoutModeType } = useMemoizedSelector((state) => ({
    layoutModeType: state.Layout.layoutModeType,
  }));
  const onChangeLayoutMode = (value) => {
    if (changeLayoutMode) {
      localStorage.setItem("local_layout_mode_type", value);
      dispatch(changeLayoutMode(value));
    }
  };

  useImperativeHandle(refShortcutButtonHeader, () => ({
    onFullScreen: () => refFullScreenBtn.current.onFullScreen(),
    onToggleOnLocation: () => refLocationBtn.current.onToggleOnLocation(),
    onToggleRemote: () => refRemoteBtn.current.onToggleRemote(),
  }));

  useEffect(() => {
    const localLayoutModeType = localStorage.getItem("local_layout_mode_type");
    if (changeLayoutMode) {
      dispatch(changeLayoutMode(localLayoutModeType));
    }
  }, []);

  return (
    <header className="pos-header hstack w-100 bg-primary" style={{ height: "50px" }}>
      <div className="h-100" style={{ width: "calc(100% - 452px)" }}>
        <div className="d-flex align-items-center w-100 h-100">
          <div className="px-3 flex-shrink-0">
            <SearchDropdown
              priceGroup={selectedPriceGroup}
              onClickItem={addProductToCart}
              isOpen={showDropDownProductMenu}
              toggleOff={toggleOffDropDownProductMenu}
              toggleOn={toggleOnDropDownProductMenu}
              width="700px"
              location={{ id: selectedLocation.value, name: selectedLocation.label }}
            />
          </div>
          <div className="flex-shrink-0">
            <ButtonTheme type="button" className="btn border px-1 me-3">
              <span className="d-flex align-items-center">
                <i className="bx bx-barcode-reader fs-22 me-1"></i>
                {t("Barcode")}
              </span>
            </ButtonTheme>
          </div>
          <div className="flex-grow-1 h-100" style={{ width: "200px" }}>
            <OrderHeader
              selectedOrder={selectedOrder}
              currentOrders={currentOrders}
              deleteOrder={deleteOrder}
              handleChangeSelectedOrder={handleChangeSelectedOrder}
              t={t}
              scrollContainerRef={scrollContainerRef}
            />
          </div>
          <div className="flex-shrink-0">
            <ButtonTheme type="button" className="btn btn-icon mx-2 border" onClick={addNewCurrentOrders}>
              <i className="bx bx-plus fs-22"></i>
            </ButtonTheme>
          </div>
        </div>
      </div>
      <div className="pe-3 h-100" style={{ width: "452px" }}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="ms-1 d-flex flex-column">
            <LocationSelectForm
              selectedLocation={selectedLocation}
              handleChangeLocation={handleChangeLocation}
              refBtn={refLocationBtn}
            />
            <StaffSelectForm handleChangeStaff={handleChangeStaff} selectedStaff={selectedStaff} />
          </div>
          <div className="hstack" style={{ height: "50px" }}>
            <LanguageDropdown />
            {/* <LightDark layoutMode={layoutModeType} onChangeLayoutMode={onChangeLayoutMode} /> */}
            <RemoteScreen refBtn={refRemoteBtn} />
            <FullScreenDropdown refBtn={refFullScreenBtn} />
            <OfflineMode />
            <AdminHomepage />
            <ShortcutGuide />
          </div>
        </div>
      </div>
    </header>
  );
};

const OrderHeader = ({
  currentOrders,
  deleteOrder,
  handleChangeSelectedOrder,
  selectedOrder,
  t,
  scrollContainerRef,
}) => {
  const onDelete = (e, index) => {
    e.stopPropagation();
    setSelectedIndex(index);
    setSubmitModal(true);
  };
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [submitModal, setSubmitModal] = useState(false);
  const toggle = () => {
    setSubmitModal(false);
    setSelectedIndex(null);
  };
  return (
    <div className="w-100 h-100">
      <DeleteOrderModal
        show={submitModal}
        onDeleteClick={() => {
          deleteOrder(selectedIndex);
          toggle();
        }}
        onCloseClick={toggle}
        index={selectedIndex}
      />
      <HorizontalScrollingMenu className={"h-100"} scrollContainerRef={scrollContainerRef}>
        {currentOrders.map((item, index) => (
          <div
            key={index}
            tabIndex={0}
            className={`h-100 hstack`}
            style={{ backgroundColor: selectedOrder === index && "var(--vz-topbar-search-bg)" }}
          >
            <ButtonTheme
              className={`btn w-lg p-0 ${selectedOrder === index ? "" : "btn-light"} ${
                selectedOrder === index ? "text-dark" : ""
              }`}
              onClick={() => {
                handleChangeSelectedOrder(index);
              }}
            >
              <span className="hstack gap-2">
                {t("Order")} {item?.order_number || ""}
                <i
                  className="ri-close-fill fs-22"
                  onClick={(e) => {
                    onDelete(e, index);
                  }}
                ></i>
              </span>
            </ButtonTheme>
          </div>
        ))}
      </HorizontalScrollingMenu>
    </div>
  );
};

export default POSHeader;
