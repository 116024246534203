import { useTranslation } from "react-i18next";

const BadgeCustom = ({ status = "Unknown" }) => {
  const { t } = useTranslation();

  const primaryCases = ["Returned", "Returns", "Imported"];
  const secondaryCases = ["Inprogress", "Processing", "Pending escrow", "Partial completed"];
  const successCases = ["Completed", "Delivered", "Done", "Insert", "Success", "Running", "Active", "Paid", "Balanced"];
  const warningCases = ["Not received", "Pending"];
  const infoCases = ["Ready", "Pickups", "Received", "Partial paid"];
  const dangerCases = ["Cancelled", "Canceled", "Failed", "Remove", "Error", "Deleted"];
  const darkCases = ["Paused", "Unpaid"];
  const lightCases = [];
  if (status) {
    const options = [
      { bg: "primary", value: primaryCases },
      { bg: "secondary", value: secondaryCases },
      { bg: "success", value: successCases },
      { bg: "warning", value: warningCases },
      { bg: "info", value: infoCases },
      { bg: "danger", value: dangerCases },
      { bg: "dark", value: darkCases },
      { bg: "light", value: lightCases },
    ];

    const formatString = (str) => {
      let parts = str.toLowerCase().split("_");
      let formattedString = parts.join(" ");
      return formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
    };

    const label = formatString(status);
    const checkStatus = options.find((item) => item.value.includes(label));
    if (checkStatus) {
      return <span className={`badge badge-soft-${checkStatus.bg}`}> {t(label)} </span>;
    } else {
      return <span className="badge badge-soft-dark"> {t(label)} </span>;
    }
  }
};

export default BadgeCustom;
