import React, { useState, useEffect } from "react";

const CountdownTimer = ({ checkActive, setIsSpinLoading }) => {
  const [time, setTime] = useState(60);

  useEffect(() => {
    let interval = null;

    if (checkActive && time > 0) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (time === 0 || !checkActive) {
      setIsSpinLoading(false);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [checkActive, time]);

  useEffect(() => {
    if (!checkActive) {
      setTime(60); // Reset time when checkActive is false
    }
  }, [checkActive]);

  const formatTime = (time) => {
    const minutes = String(Math.floor(time / 60)).padStart(2, "0");
    const seconds = String(time % 60).padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  return (
    <div className={"tw-inline-block"}>
      <span className={"tw-text-[#44425D] tw-text-[14px] tw-font-extrabold"}>{formatTime(time)}</span>
    </div>
  );
};

export default CountdownTimer;
