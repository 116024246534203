import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import {
  addPrintTemplateFail,
  addPrintTemplateSuccess,
  getTemplatePrintError,
  getTemplatePrintSuccess,
  updatePrintTemplateFail,
  updatePrintTemplateSuccess,
} from "./action";
import { ADD_TEMPLATE_PRINT, GET_TEMPLATE_PRINT, UPDATE_TEMPLATE_PRINT } from "./actionType";
import { printTemplatesAPI } from "../../helpers/resource_helper";
import { toast } from "react-toastify";
import { getDefaultPrintShop } from "../actions";

function* getTemplatePrint() {
  try {
    const response = yield call(printTemplatesAPI.list, {});
    yield put(getTemplatePrintSuccess(response));
  } catch (error) {
    yield put(getTemplatePrintError(error));
  }
}

function* addTemplatePrint({ payload }) {
  try {
    const response = yield call(printTemplatesAPI.create, payload);
    yield put(addPrintTemplateSuccess(response));
    toast.success(`Thêm thành công!`);
    if (payload.is_default) {
      put(getDefaultPrintShop());
    }
  } catch (error) {
    toast.error(error.toString());
    yield put(addPrintTemplateFail(error));
  }
}

function* updateTemplatePrint({ payload }) {
  try {
    const response = yield call(printTemplatesAPI.put, payload);
    yield put(updatePrintTemplateSuccess(response));
    if (payload.is_default) {
      put(getDefaultPrintShop());
    }
    toast.success(`Cập nhật thành công!`);
  } catch (error) {
    toast.error(error.toString());
    yield put(updatePrintTemplateFail(error));
  }
}

export function* watchGetTemplatePrint() {
  yield takeEvery(GET_TEMPLATE_PRINT, getTemplatePrint);
}

export function* watchAddTemplatePrint() {
  yield takeEvery(ADD_TEMPLATE_PRINT, addTemplatePrint);
}

export function* watchUpdateTemplatePrint() {
  yield takeEvery(UPDATE_TEMPLATE_PRINT, updateTemplatePrint);
}

function* TemplatePrintSaga() {
  yield all([fork(watchGetTemplatePrint), fork(watchAddTemplatePrint), fork(watchUpdateTemplatePrint)]);
}

export default TemplatePrintSaga;
