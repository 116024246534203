import { useEffect, useState, Fragment, useRef } from "react";
import { useDispatch } from "react-redux";

import { Card, CardBody, CardHeader, Col, Dropdown, DropdownItem, DropdownMenu, Input, Row } from "reactstrap";
import SimpleBar from "simplebar-react";
import { useTranslation } from "react-i18next";

import Loader from "./Loader";
import ButtonTheme from "./ButtonTheme";
import FullNameRender from "./FullnameRender";
import useMemoizedSelector from "../Hooks/useMemoizedSelector";
import AddEditSupplierModal from "../../pages/Ecommerce/PurchaseOrders/Suppliers/AddEditSupplierModal";
import { useDebounce } from "../Hooks/UseDebounce";

import { getSupplier, listSupplier } from "../../store/actions";
import { useListData } from "../Hooks/useListData";
import { suppliersAPI } from "../../helpers/resource_helper";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import LottieIcon from "./LottieIcon";

const SupplierInfo = ({
  selectedSupplier = null,
  onSelectSupplier = () => {},
  addresses = [],
  onChangeSupplierAddress,
  isEdit,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { page, loading, hasMore, items, query, debouncedQuery, onChangeQuery, onSearch, listData } = useListData({
    resource: suppliersAPI,
    isScroll: true,
  });

  const [showDeleteSearchValue, setShowDeleteSearchValue] = useState(false);

  const [showDropDownMenu, setShowDropDownMenu] = useState(false);
  const [showDropDownMenuBill, setShowDropDownMenuBill] = useState(false);
  const [showSupplierModal, setShowSupplierModal] = useState(false);

  const dropdownRef = useRef(null);

  const toggleOnSupplierModal = () => {
    setShowSupplierModal(true);
  };

  const handleClickName = () => {
    !!isEdit && setShowSupplierModal(true);
  };

  const toggleOffSupplierModal = () => {
    setShowSupplierModal(false);
  };

  useEffect(() => {
    setShowDeleteSearchValue(!!debouncedQuery);
    showDropDownMenu && onSearch();
  }, [debouncedQuery, showDropDownMenu]);

  const toggleDropDownMenuBill = () => {
    setShowDropDownMenuBill(!showDropDownMenuBill);
  };
  const handleClickDeleteSearchValue = () => {
    setShowDeleteSearchValue(false);
    onChangeQuery("");
  };

  const handleClickItem = async (item) => {
    if (item?.id) {
      try {
        const res = await suppliersAPI.get({ id: item.id });
        onSelectSupplier(res);
      } catch (e) {
        toast.error(e);
      }
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropDownMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Fragment>
      <AddEditSupplierModal
        isOpen={showSupplierModal}
        toggle={toggleOffSupplierModal}
        supplierId={selectedSupplier?.id}
        onAddUpdateSupplier={onSelectSupplier}
      />
      <Card>
        <CardHeader>
          <div className="d-flex">
            <h5 className="card-title mb-0">{t("Supplier Details")}</h5>
          </div>
        </CardHeader>
        {selectedSupplier ? (
          <CardBody>
            <div className="mb-3">
              <div className="fw-medium fs-14 text-uppercase">
                {t("Supplier")}
                {isEdit && (
                  <ButtonTheme
                    type="button"
                    onClick={() => onSelectSupplier(null)}
                    className="btn btn-icon btn-ghost-danger waves-effect waves-light rounded-circle light-dark-mode ms-3"
                  >
                    <i className="ri-close-line fs-22"></i>
                  </ButtonTheme>
                )}
              </div>
              <Row className="align-items-center">
                <Col md={4}>
                  <h6 className={`fs-14 mb-0 text-primary ${isEdit && "cursor-pointer"}`} onClick={handleClickName}>
                    <FullNameRender first_name={selectedSupplier?.first_name} last_name={selectedSupplier?.last_name} />
                  </h6>
                </Col>
                <Col md={4}>
                  <p className="mb-0">
                    <i className="ri-phone-line me-2 align-middle text-muted fs-16"></i>
                    {selectedSupplier?.phone}
                  </p>
                </Col>
                <Col md={4}>
                  <p className="mb-0">
                    <i className="ri-mail-line me-2 align-middle text-muted fs-16"></i>
                    {selectedSupplier?.email}
                  </p>
                </Col>
              </Row>
            </div>
            <div>
              <div className="fs-14 hstack">
                <i className="ri-map-pin-line align-middle text-muted me-1"></i>
                <span className="fw-medium text-uppercase me-3">{t("Billing Address")}</span>
                {isEdit && (
                  <div>
                    <ButtonTheme
                      type="button"
                      className="btn btn-ghost-primary text-primary waves-effect"
                      onClick={toggleDropDownMenuBill}
                    >
                      {t("Change")}
                    </ButtonTheme>
                    <DropDownAddress
                      isOpen={showDropDownMenuBill}
                      toggle={toggleDropDownMenuBill}
                      addresses={addresses}
                      toggleSupplierModal={toggleOnSupplierModal}
                      onChangeSupplierAddress={onChangeSupplierAddress}
                    />
                  </div>
                )}
              </div>
              <p className="mb-0">
                <FullNameRender
                  first_name={selectedSupplier.billing_address?.first_name}
                  last_name={selectedSupplier.billing_address?.last_name}
                />
              </p>
              <p className="mb-0">{selectedSupplier.billing_address?.address1 || "---"}</p>
            </div>
          </CardBody>
        ) : (
          <CardBody>
            <div ref={dropdownRef}>
              <form className="app-search d-md-block">
                <div className="position-relative">
                  <Input
                    type="text"
                    className="form-control border"
                    placeholder={t("Search...")}
                    value={query}
                    onChange={(e) => {
                      onChangeQuery(e.target.value);
                    }}
                    onClick={() => {
                      setShowDropDownMenu(true);
                    }}
                  />
                  <span className="mdi mdi-magnify search-widget-icon"></span>
                  {showDeleteSearchValue && (
                    <span
                      className="mdi mdi-close-circle search-widget-icon search-widget-icon-close cursor-pointer"
                      onClick={handleClickDeleteSearchValue}
                    ></span>
                  )}
                </div>
                <Dropdown
                  isOpen={showDropDownMenu}
                  toggle={() => {
                    setShowDropDownMenu(false);
                  }}
                  className="w-100"
                >
                  <DropdownMenu className="p-0 mt-1 w-100">
                    <DropdownItem className="border-bottom position-sticky top-0" onClick={toggleOnSupplierModal}>
                      <div className="d-flex">
                        <span className="me-3 text-center d-flex align-items-center" style={{ width: "2rem" }}>
                          <i className="bx bx-plus fs-22"></i>
                        </span>
                        <h5 className="flex-grow-1 fw-semibold mb-0 d-flex align-items-center">Add new supplier</h5>
                      </div>
                    </DropdownItem>
                    {loading ? (
                      <Loader />
                    ) : (
                      <div
                        id="supplier-search-drop-down"
                        className="w-100"
                        style={{ maxHeight: "400px", overflow: "auto" }}
                      >
                        <InfiniteScroll
                          dataLength={items.length}
                          next={() => {
                            listData(query, page + 1);
                          }}
                          hasMore={hasMore}
                          loader={<Loader />}
                          scrollableTarget="supplier-search-drop-down"
                        >
                          {items.length === 0 ? (
                            <div className="text-center">
                              <LottieIcon
                                src="https://cdn.lordicon.com/msoeawqm.json"
                                style={{ width: "42px", height: "42px" }}
                              />
                              <div>
                                <p>{t("Sorry! No Result Found")}</p>
                              </div>
                            </div>
                          ) : (
                            items.map((item, index) => (
                              <DropdownItem key={index} className="hstack" onClick={() => handleClickItem(item)}>
                                <div className="me-3 avatar-xs">
                                  <div className="avatar-title rounded-circle bg-light text-primary">
                                    {String(item.first_name).charAt(0).toUpperCase()}
                                  </div>
                                </div>
                                <h5 className="flex-grow-1 fw-semibold mb-0 d-flex align-items-center">
                                  <FullNameRender first_name={item.first_name} last_name={item.last_name} />
                                </h5>
                              </DropdownItem>
                            ))
                          )}
                        </InfiniteScroll>
                      </div>
                    )}
                  </DropdownMenu>
                </Dropdown>
              </form>
            </div>
            <div
              className="d-flex flex-column justify-content-center text-center text-muted "
              style={{ height: "200px" }}
            >
              {loading && !showDropDownMenu ? (
                <Loader />
              ) : (
                <Fragment>
                  <i className="bx bxs-user-detail fs-48"></i>
                  <span>{t("No supplier info")}</span>
                </Fragment>
              )}
            </div>
          </CardBody>
        )}
      </Card>
    </Fragment>
  );
};

const DropDownAddress = ({ isOpen, toggle, onChangeSupplierAddress, addresses, toggleSupplierModal }) => {
  const { t } = useTranslation();
  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownMenu className="p-0" style={{ width: "600px", maxHeight: "600px" }}>
        <SimpleBar className="h-100">
          <div className="border-bottom p-3">
            <span className="fw-medium">{t("Change address")}</span>
          </div>

          {addresses.length > 0 ? (
            addresses.map((item, index) => (
              <DropdownItem key={index} onClick={() => onChangeSupplierAddress(item)}>
                <p className="mb-0 fw-medium">
                  <FullNameRender first_name={item.first_name} last_name={item.last_name} />
                </p>
                <div className="mb-0 text-wrap">{item?.address1 || "---"}</div>
              </DropdownItem>
            ))
          ) : (
            <div className="hstack p-3">
              <span className="me-auto">{t("This supplier doesn't have any addresses!")}</span>
              <span className="text-primary text-decoration-underline cursor-pointer" onClick={toggleSupplierModal}>
                {t("Add address")}
              </span>
            </div>
          )}
        </SimpleBar>
      </DropdownMenu>
    </Dropdown>
  );
};
export default SupplierInfo;
