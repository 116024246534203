import { useState } from "react";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

import PackageComponent from "./PackageComponent";
import useMemoizedSelector from "../Hooks/useMemoizedSelector";
import { useEffect } from "react";
import { toast } from "react-toastify";
import ButtonTheme from "./ButtonTheme";
import { useTranslation } from "react-i18next";

const PackagesModal = ({ isOpen, toggle, items, onPacking, loading, packages }) => {
  const { locations, locationsLoading, locationsSuccess, locationsError } = useMemoizedSelector((state) => ({
    locations: state.Locations.locations,
    locationsLoading: state.Locations.loading,
    locationsSuccess: state.Locations.success,
    locationsError: state.Locations.error,
  }));
  const { t } = useTranslation();

  const [selectedLocation, setSelectedLocation] = useState("");
  const [packagePayload, setPackagePayload] = useState([]);

  const handleChangePackagePayload = (data) => {
    setPackagePayload(data);
  };

  const handlePack = () => {
    if (packagePayload.length > 0) {
      let totalItems = items.reduce((total, item) => total + Number(item.quantity), 0);
      let totalPackItems = packagePayload.reduce(
        (total, item) =>
          total + item.order_line_items.reduce((subTotal, subItem) => subTotal + Number(subItem.quantity), 0),
        0,
      );
      let totalPackedItems = packages.reduce(
        (total, item) =>
          total + item.order_line_items.reduce((subTotal, subItem) => subTotal + Number(subItem.quantity), 0),
        0,
      );
      if (totalPackItems + totalPackedItems < totalItems) {
        if (window.confirm(t("Total items in package less than total items in order. Are you sure?"))) {
          onPacking({ packages: packagePayload });
        }
      } else {
        onPacking({ packages: packagePayload });
      }
    } else {
      toast.error(t("Required at least 1 item!"));
    }
  };

  const handleToggle = () => {
    !loading && toggle();
  };

  useEffect(() => {
    setSelectedLocation(locations?.[0]?.id);
  }, [locationsLoading, locations]);

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} size={"xl"} scrollable>
      <ModalHeader toggle={handleToggle}>{t("Packages order")}</ModalHeader>
      <ModalBody>
        <Nav tabs className="nav nav-tabs nav-border-top nav-border-top-primary mb-3">
          {locations.map((item, index) => (
            <NavItem key={index}>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: selectedLocation === item.id })}
                onClick={() => {
                  setSelectedLocation(item.id);
                }}
              >
                {t(item.name)}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={selectedLocation} className="text-muted">
          {locations.map((item, index) => (
            <TabPane key={index} tabId={item.id} id="nav-border-top-home">
              <PackageComponent
                orderItems={items}
                packages={packages}
                selectedLocation={selectedLocation}
                packagePayload={packagePayload}
                onChangePackagePayload={handleChangePackagePayload}
              />
            </TabPane>
          ))}
        </TabContent>
      </ModalBody>
      <ModalFooter>
        <ButtonTheme className="btn btn-light w-md" onClick={handleToggle} disabled={loading}>
          {t("Close")}
        </ButtonTheme>
        <ButtonTheme className="btn btn-warning w-md" onClick={handlePack} loading={loading} disabled={loading}>
          {t("Packing")}
        </ButtonTheme>
      </ModalFooter>
    </Modal>
  );
};
export default PackagesModal;
