import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";


// Redux States
import { LIST_IMPORT } from "./actionType";

import { listImportSuccess, listImportFail, getImportDetailsSuccess, getImportDetailsFail } from "./action";

import { listImportsAPI } from "../../../../helpers/service_helper";

function* listImport({ payload: { ...res } }) {
  try {
    const response = yield call(listImportsAPI, { ...res });
    yield put(listImportSuccess(response));
  } catch (error) {
    yield put(listImportFail(error));
  }
}

export function* watchGetImports() {
  yield takeEvery(LIST_IMPORT, listImport);
}

function* importsSaga() {
  yield all([fork(watchGetImports)]);
}

export default importsSaga;
