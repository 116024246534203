import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

import { convertValidDate, convertValidTime } from "../../../../helpers/format_helper";

import withRouter from "../../../../Components/Common/withRouter";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import { Actions } from "../../../../Components/constants/common";
import ImageCustom from "../../../../Components/Common/ImageCustom";
import EditAddBrandModal from "../Components/EditAddBrandModal";
import ActionDropdown from "../../../../Components/Common/ActionDropdown";

import { deleteBrand, listBrand } from "../../../../store/actions";

const BrandsPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Brand List")} | OptiWarehouse`;

  const dispatch = useDispatch();

  const { brands, brandsLoading, brandsAction, brandsSuccess, brandsError, brandsLimit, brandsTotal, brandsPage } =
    useMemoizedSelector((state) => ({
      brands: state.Brands.brands,
      brandsLoading: state.Brands.loading,
      brandsSuccess: state.Brands.success,
      brandsAction: state.Brands.action,
      brandsError: state.Brands.error,
      brandsLimit: state.Brands.limit,
      brandsTotal: state.Brands.total,
      brandsPage: state.Brands.page,
    }));

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [showEditAddModal, setShowEditAddModal] = useState(false);

  const handleAdd = () => {
    setShowEditAddModal(true);
  };

  const handleEdit = (data) => {
    setShowEditAddModal(true);
    setSelectedBrand(data);
  };

  const handleCloseEditAddModal = () => {
    if (!brandsLoading) {
      setSelectedBrand(null);
      setShowEditAddModal(false);
    }
  };

  const handleDelete = () => {
    dispatch(deleteBrand(selectedBrand));
  };

  const handleCloseDeleteModal = () => {
    if (!brandsLoading) {
      setShowDeleteModal(false);
      setSelectedBrand(null);
    }
  };

  const headers = ["name", "image", "updated_at", "created_at", "action"];

  // toggle after loading
  useEffect(() => {
    if (!brandsLoading && brandsSuccess && showDeleteModal) {
      setShowDeleteModal(false);
      setSelectedBrand(null);
    }
  }, [brandsLoading, brandsSuccess]);

  return (
    <div className="page-content">
      <Container fluid>
        <DeleteModal
          show={showDeleteModal}
          onCloseClick={handleCloseDeleteModal}
          onDeleteClick={handleDelete}
          loading={brandsLoading && brandsAction === Actions.DELETE}
        />
        <EditAddBrandModal show={showEditAddModal} toggle={handleCloseEditAddModal} brand={selectedBrand} />
        <Row>
          <Col lg={12}>
            <Card id="brand-list">
              <CardHeader className="border-0">
                <Row className="g-4 align-items-center">
                  <div className="col-sm">
                    <div>
                      <h5 className="card-title mb-0">{t("Brand List")}</h5>
                    </div>
                  </div>
                  <div className="col-sm-auto">
                    <div>
                      <ButtonTheme
                        type="button"
                        className="btn btn-primary add-btn"
                        id="create-btn"
                        onClick={handleAdd}
                        icon="ri-add-line text-white"
                      >
                        {t("Add Brand")}
                      </ButtonTheme>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <TableContainerCustom
                    filterType={"brands"}
                    searchPlaceHolder="Search brand..."
                    listFilter={[]}
                    headers={headers}
                    contents={brands}
                    limitPage={Number(brandsLimit)}
                    currentPage={Number(brandsPage) + 1}
                    totalItems={brandsTotal}
                    loading={brandsLoading && brandsAction === Actions.LIST}
                    showPagination={true}
                    scrollHeight="50vh"
                    onCallData={(dataPayload) => {
                      dispatch(listBrand(dataPayload));
                    }}
                    isSettings={true}
                    listHeader={[
                      {
                        title: t("Brand details"),
                        key: "detail-variant",
                        children: [
                          {
                            title: t("Name"),
                            dataIndex: "name",
                            key: "name",
                            sorter: true,
                          },
                          {
                            title: t("Image"),
                            dataIndex: "image",
                            key: "image",
                            width: "90px",
                            render: (value, row, index) => {
                              return <ImageCustom image={row?.image?.url} name={row.name} avatarSize={"avatar-xs"} />;
                            },
                          },

                          {
                            title: t("Updated at"),
                            dataIndex: "updated_at",
                            key: "updated_at",
                            sorter: true,
                          },
                          {
                            title: t("Created at"),
                            dataIndex: "created_at",
                            key: "created_at",
                            defaultSortOrder: "desc",
                            sorter: true,
                          },
                          {
                            title: t("Action"),
                            dataIndex: "action",
                            key: "action",
                            render: (value, row, index) => {
                              return (
                                <ActionDropdown
                                  index={index}
                                  actions={[
                                    {
                                      type: "edit",
                                      onClick: () => {
                                        handleEdit(row);
                                      },
                                    },
                                    {
                                      type: "delete",
                                      onClick: () => {
                                        setSelectedBrand(row);
                                        setShowDeleteModal(true);
                                      },
                                    },
                                  ]}
                                />
                              );
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default withRouter(BrandsPage);
