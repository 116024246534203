import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";


// Redux States
import { LIST_UNIT, DELETE_UNIT, ADD_NEW_UNIT, UPDATE_UNIT, GET_UNIT } from "./actionType";

import {
  deleteUnitSuccess,
  deleteUnitFail,
  addUnitSuccess,
  addUnitFail,
  updateUnitSuccess,
  updateUnitFail,
  listUnitSuccess,
  listUnitFail,
  getUnitSuccess,
  getUnitFail,
} from "./action";

import { unitsAPI } from "../../../../helpers/resource_helper";

function* listUnit({ payload: { ...res } }) {
  try {
    const response = yield call(unitsAPI.list, { ...res });
    yield put(listUnitSuccess(response));
  } catch (error) {
    yield put(listUnitFail(error));
  }
}

function* getUnit({ payload: id }) {
  try {
    const response = yield call(unitsAPI.get, { id });
    yield put(getUnitSuccess(response));
  } catch (error) {
    yield put(getUnitFail(error));
  }
}

function* deleteUnits({ payload: unit }) {
  try {
    const response = yield call(unitsAPI.delete, unit);
    yield put(deleteUnitSuccess(unit));
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteUnitFail(error));
    toast.error("Unit Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewUnit({ payload: { unit, handleAfterSubmit } }) {
  try {
    const response = yield call(unitsAPI.create, unit);
    yield put(addUnitSuccess(response));
    yield call(handleAfterSubmit, response);
    toast.success("Unit Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addUnitFail(error));
    toast.error("Unit Added Failed", { autoClose: 3000 });
  }
}

function* onUpdateUnit({ payload: { unit, handleAfterSubmit } }) {
  try {
    const response = yield call(unitsAPI.put, unit);
    yield put(updateUnitSuccess(response));
    yield call(handleAfterSubmit);
    toast.success("Unit Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateUnitFail(error));
    toast.error("Unit Updated Failed", { autoClose: 3000 });
  }
}

export function* watchGetUnits() {
  yield takeEvery(LIST_UNIT, listUnit);
}

export function* watchDeleteUnits() {
  yield takeEvery(DELETE_UNIT, deleteUnits);
}

export function* watchGetUnit() {
  yield takeEvery(GET_UNIT, getUnit);
}

export function* watchUpdateUnit() {
  yield takeEvery(UPDATE_UNIT, onUpdateUnit);
}

export function* watchAddNewUnit() {
  yield takeEvery(ADD_NEW_UNIT, onAddNewUnit);
}

function* unitsSaga() {
  yield all([
    fork(watchGetUnit),
    fork(watchGetUnits),
    fork(watchUpdateUnit),
    fork(watchAddNewUnit),
    fork(watchDeleteUnits),
  ]);
}

export default unitsSaga;
