import {
  LIST_SUPPLIER,
  LIST_SUPPLIER_FAIL,
  LIST_SUPPLIER_SUCCESS,
  DELETE_SUPPLIER,
  DELETE_SUPPLIER_SUCCESS,
  DELETE_SUPPLIER_FAIL,
  ADD_NEW_SUPPLIER,
  ADD_SUPPLIER_SUCCESS,
  ADD_SUPPLIER_FAIL,
  UPDATE_SUPPLIER,
  UPDATE_SUPPLIER_SUCCESS,
  UPDATE_SUPPLIER_FAIL,
  GET_SUPPLIER,
  GET_SUPPLIER_FAIL,
  GET_SUPPLIER_SUCCESS,
} from "./actionType";

export const listSupplier = (options) => ({
  type: LIST_SUPPLIER,
  payload: options,
});

export const listSupplerFail = (error) => ({
  type: LIST_SUPPLIER_FAIL,
  payload: error,
});

export const listSupplerSuccess = (suppliers) => ({
  type: LIST_SUPPLIER_SUCCESS,
  payload: suppliers,
});

export const getSupplier = (supplierID) => ({
  type: GET_SUPPLIER,
  payload: supplierID,
});

export const getSupplierFail = (error) => ({
  type: GET_SUPPLIER_FAIL,
  payload: error,
});

export const getSupplierSuccess = (supplier) => ({
  type: GET_SUPPLIER_SUCCESS,
  payload: supplier,
});

export const deleteSupplier = (supplier) => ({
  type: DELETE_SUPPLIER,
  payload: supplier,
});

export const deleteSupplierSuccess = (supplier) => ({
  type: DELETE_SUPPLIER_SUCCESS,
  payload: supplier,
});

export const deleteSupplierFail = (error) => ({
  type: DELETE_SUPPLIER_FAIL,
  payload: error,
});

export const updateSupplier = (supplier, handleAfterSubmit = () => {}) => ({
  type: UPDATE_SUPPLIER,
  payload: { supplier, handleAfterSubmit },
});

export const updateSupplierSuccess = (supplier) => ({
  type: UPDATE_SUPPLIER_SUCCESS,
  payload: supplier,
});

export const updateSupplierFail = (error) => ({
  type: UPDATE_SUPPLIER_FAIL,
  payload: error,
});

export const addNewSupplier = (supplier, handleAfterSubmit = () => {}) => ({
  type: ADD_NEW_SUPPLIER,
  payload: { supplier, handleAfterSubmit },
});

export const addSupplierSuccess = (supplier) => ({
  type: ADD_SUPPLIER_SUCCESS,
  payload: supplier,
});

export const addSupplierFail = (error) => ({
  type: ADD_SUPPLIER_FAIL,
  payload: error,
});
