import { Fragment, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, CardFooter, CardHeader, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { Form, Formik } from "formik";

import { useTranslation } from "react-i18next";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import BoxApply from "../Component/BoxApply";
import { appsAPI, locationsAPI, variantsAPI, vouchersAPI } from "../../../../helpers/resource_helper";
import InputTheme from "../../../../Components/Common/InputTheme";
import SelectTheme from "../../../../Components/Common/SelectTheme";
import SearchDropdown from "../../../../Components/Common/ProductsSearchDropDown";
import { Table } from "antd";
import BreadCrumbCustom from "../../../../Components/Common/BreadCrumbCustom";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../../Components/Common/Loader";
import { toast } from "react-toastify";
import ScrollToError from "../../../../Components/Common/ScrollToError";

const CreateVoucher = ({ voucher = null, isOpen, toggle }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();

  document.title = `${t(id ? "Edit Voucher" : "Create Voucher")} | OptiWarehouse`;

  const formRef = useRef();
  const [loadingSave, setLoadingSave] = useState(false);
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [detailVoucher, setDetailVoucher] = useState({});
  const [loading, setLoading] = useState(false);
  const clickTypeRef = useRef();
  const loadingSaveRef = useRef();
  const loadingSaveHandleRef = useRef();
  const loadingSaveDetailRef = useRef();
  const form = {
    initialValues: {
      locations: detailVoucher?.locations || [{ id: "ALL" }],
      customers: detailVoucher?.customers || [{ id: "ALL" }],
      sources: detailVoucher?.customers || [{ id: "ALL" }],
      start_at: detailVoucher?.start_at ? detailVoucher?.start_at : new Date().toISOString(),
      expire_at: detailVoucher?.expire_at ? detailVoucher?.expire_at : null,
      check_expire_at: isEdit ? !detailVoucher?.expire_at : false,
      description: detailVoucher?.description || "",
      value_coupon: {
        type: isEdit ? detailVoucher?.value_coupon?.type : "PERCENT",
        amount: isEdit ? detailVoucher?.value_coupon?.amount : "",
      },
      max_value_coupon: detailVoucher?.max_value_coupon || null,
      use_voucher_oder: detailVoucher?.use_voucher_oder || null,
      use_voucher_customer: detailVoucher?.use_voucher_customer || null,
      apply_with_other_promotion: isEdit ? detailVoucher?.apply_with_other_promotion : false,
      condition_order: detailVoucher?.condition_order || "",
      check_use_voucher_customer: isEdit ? !detailVoucher?.use_voucher_oder : false,
      check_use_voucher_oder: isEdit ? !detailVoucher?.use_voucher_customer : false,
      name: detailVoucher?.name || "",
      code: detailVoucher?.code || "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("Required voucher name")),
      code: Yup.string().required(t("Required voucher code")),
      start_at: Yup.string().required(t("Required effective start date")),
      expire_at: Yup.string()
        .nullable()
        .when("check_expire_at", ([check_expire_at], schema) => {
          if (!check_expire_at) {
            return Yup.string().required(t("Field is required"));
          }
          return schema;
        }),
      value_coupon: Yup.object().shape({
        amount: Yup.string().required(t("Required value")), // 'Required value' có thể thay thế bằng t('Required value')
      }),
      max_value_coupon: Yup.string()
        .nullable()
        .when("value_coupon", ([value_coupon], schema) => {
          if (value_coupon.type === "PERCENT") {
            return Yup.string().required(t("Required value"));
          }
          return schema;
        }),
      use_voucher_oder: Yup.string()
        .nullable()
        .when("check_use_voucher_oder", ([check_use_voucher_oder], schema) => {
          if (!check_use_voucher_oder) {
            return Yup.string().required(t("Field is required"));
          }
          return schema;
        }),
      use_voucher_customer: Yup.string()
        .nullable()
        .when("check_use_voucher_customer", ([check_use_voucher_customer], schema) => {
          if (!check_use_voucher_customer) {
            return Yup.string().required(t("Field is required"));
          }
          return schema;
        }),
      condition_order: Yup.string().required(t("Required value")),
    }),
    onSubmit: async (values) => {
      if (clickTypeRef.current === "save") {
        loadingSaveRef.current.showLoading();
      } else if (clickTypeRef.current === "saveHandle") {
        loadingSaveHandleRef.current.showLoading();
      } else {
        loadingSaveDetailRef.current.showLoading();
      }
      try {
        const { check_expire_at, check_use_voucher_customer, check_use_voucher_oder, ...res } = values;
        if (detailVoucher.id) {
          await vouchersAPI.put({ ...detailVoucher, ...res });
          toast.success(t("Update successfully!"));
        } else {
          const response = await vouchersAPI.create({ ...res, condition_contains_order: tableRef.current });
          if (clickTypeRef.current === "save") {
            navigate("/vouchers");
          } else {
            navigate(`/voucher-code/${response.id}`, { state: { name: response.name } });
          }
        }
      } catch (error) {
        toast.error(error.toString());
      }
      if (clickTypeRef.current === "save") {
        loadingSaveRef.current.hideLoading();
      } else if (clickTypeRef.current === "saveHandle") {
        loadingSaveHandleRef.current.hideLoading();
      } else {
        loadingSaveDetailRef.current.hideLoading();
      }
    },
  };
  const tableRef = useRef();

  useEffect(() => {
    const getDetailVoucher = async () => {
      setLoading(true);
      const res = await vouchersAPI.get({ id: id });
      setDetailVoucher(res);
      setLoading(false);
    };
    if (id) {
      setIsEdit(true);
      getDetailVoucher();
    } else {
      setIsEdit(false);
      setDetailVoucher({});
    }
  }, [id]);

  const [actionSave, setActionSave] = useState("");

  return (
    <div className="page-content">
      <Container fluid>
        {loading ? (
          <Loader />
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={form.initialValues}
            validationSchema={form.validationSchema}
            onSubmit={form.onSubmit}
            innerRef={formRef}
          >
            {({ values, setFieldValue }) => (
              <>
                <Form>
                  <ScrollToError />
                  <BreadCrumbCustom text="Back to voucher list" navigateTo="/vouchers">
                    <>
                      {isEdit ? (
                        <ButtonTheme
                          type="submit"
                          className="btn btn-primary w-sm mx-2"
                          loadShowText={true}
                          refBtn={loadingSaveDetailRef}
                          onClick={async () => {
                            clickTypeRef.current = "saveDetail";
                          }}
                        >
                          {t("Save")}
                        </ButtonTheme>
                      ) : (
                        <>
                          <ButtonTheme
                            type="submit"
                            className="btn btn-light w-sm"
                            loadShowText={true}
                            refBtn={loadingSaveRef}
                            onClick={async () => {
                              clickTypeRef.current = "save";
                            }}
                          >
                            {t("Save")}
                          </ButtonTheme>
                          <ButtonTheme
                            type="submit"
                            className="btn btn-primary w-sm mx-2"
                            loadShowText={true}
                            refBtn={loadingSaveHandleRef}
                            onClick={() => {
                              clickTypeRef.current = "saveHandle";
                            }}
                          >
                            {t("Save and add new voucher code")}
                          </ButtonTheme>
                        </>
                      )}
                    </>
                  </BreadCrumbCustom>
                  <Row>
                    <Col lg={9}>
                      <Card>
                        <CardBody>
                          <h5>{t("General info")}</h5>
                          <Row>
                            <Col lg={6}>
                              <InputTheme label={"Name"} name={"name"} placeholder="Enter name" />
                            </Col>
                            <Col lg={6}>
                              <InputTheme
                                label={"Voucher code"}
                                name={"code"}
                                subLabel={"If value is emptied, the code will automatically be generated"}
                                disabled={isEdit}
                                placeholder="Ex: NOLI26122023"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Label>{t("Start at")}</Label>
                            <div className="d-flex align-items-center">
                              <InputTheme type="timeDate" name={"start_at"} disabled={isEdit} />
                              <span className="mb-3 mx-3">{t("To")}</span>
                              <InputTheme type="timeDate" name={"expire_at"} disabled={values.check_expire_at} />
                              <InputTheme
                                type="checkbox"
                                label={"No expired date"}
                                name={"check_expire_at"}
                                classWrapper="mb-2 mx-3"
                                onTrigger={() => {
                                  setFieldValue("expire_at", null);
                                }}
                              />
                            </div>
                          </Row>
                          <Row>
                            <Col lg={12}>
                              <InputTheme type="textarea" label={"Description"} name={"description"} />
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <h5>{t("Voucher details")}</h5>
                          <Row>
                            <Col lg={6}>
                              <InputTheme
                                name={"value_coupon.amount"}
                                label={"Discount"}
                                classWrapper="w-75"
                                disabled={isEdit}
                                format="price"
                                customRender={
                                  <>
                                    <div className="flex-shrink-0">
                                      <Input
                                        type="radio"
                                        className="btn-check"
                                        name={`value_coupon.type`}
                                        onChange={(e) => {
                                          setFieldValue("value_coupon.type", "PERCENT");
                                        }}
                                        disabled={isEdit}
                                        id="value_coupon-PERCENT"
                                        checked={values.value_coupon.type === "PERCENT"}
                                      />
                                      <Label className="btn btn-outline-primary m-0 w-sm" for={`value_coupon-PERCENT`}>
                                        %
                                      </Label>
                                      <Input
                                        type="radio"
                                        className="btn-check"
                                        name={`value_coupon.type`}
                                        onChange={(e) => {
                                          setFieldValue("value_coupon.type", "VALUE");
                                          setFieldValue("max_value_coupon", null);
                                        }}
                                        id="value_coupon-VALUE"
                                        disabled={isEdit}
                                        checked={values.value_coupon.type === "VALUE"}
                                      />
                                      <Label className="btn btn-outline-primary m-0  w-sm" for={`value_coupon-VALUE`}>
                                        {t("Amount")}
                                      </Label>
                                    </div>
                                  </>
                                }
                              />
                            </Col>
                            <Col lg={6}>
                              <InputTheme
                                name={"max_value_coupon"}
                                label={"Max amount"}
                                classWrapper="w-50"
                                disabled={isEdit || values.value_coupon.type === "VALUE"}
                                format="price"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <div className="d-flex align-items-baseline mt-2">
                              <span style={{ width: "350px" }}>{t("Max using times")} *</span>
                              <div style={{ width: "100px" }}>
                                <InputTheme
                                  name={"use_voucher_oder"}
                                  disabled={values.check_use_voucher_oder || isEdit}
                                  format="price"
                                  placeholder="Enter times"
                                />
                              </div>
                              <InputTheme
                                name={"check_use_voucher_oder"}
                                type="checkbox"
                                label={"Limitless"}
                                classWrapper="mx-3"
                                onTrigger={() => {
                                  setFieldValue("use_voucher_oder", null);
                                }}
                                disabled={isEdit}
                              />
                            </div>
                            <div className="d-flex align-items-baseline mt-2">
                              <span style={{ width: "350px" }}>{t("Each customer uses the maximum")}</span>
                              <div style={{ width: "100px" }}>
                                <InputTheme
                                  name={"use_voucher_customer"}
                                  disabled={values?.check_use_voucher_customer || isEdit}
                                  format="price"
                                  placeholder="Enter times"
                                />
                              </div>
                              <InputTheme
                                name={"check_use_voucher_customer"}
                                type="checkbox"
                                label={"Limitless"}
                                classWrapper="mx-3"
                                onTrigger={() => {
                                  setFieldValue("use_voucher_customer", null);
                                }}
                                disabled={isEdit}
                              />
                            </div>
                          </Row>
                        </CardBody>
                        <CardFooter>
                          <InputTheme
                            name={"apply_with_other_promotion"}
                            type="checkbox"
                            label={"Stack with other promotions"}
                            subLabel={
                              "Including customer discounts, points discounts, self-entering discounts for orders and promotional programs"
                            }
                            disabled={isEdit}
                          />
                        </CardFooter>
                      </Card>
                      <Card>
                        <CardBody className="mb-3">
                          <h5>{t("Condition")}</h5>
                          <div className="d-flex align-items-baseline mt-2 mb-2">
                            <span style={{ width: "220px" }}>{t("Order total from")}</span>
                            <div style={{ width: "250px" }}>
                              <InputTheme name={"condition_order"} disabled={isEdit} format="price" />
                            </div>
                          </div>

                          <TableConditionOrder
                            tableRef={tableRef}
                            disabled={isEdit}
                            data={detailVoucher?.condition_contains_order}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={3}>
                      <BoxApply
                        title={"Effective locations"}
                        titleRadioAll={"All locations"}
                        onChange={(data) => {
                          setFieldValue("locations", data);
                        }}
                        text={"locations"}
                        data={values?.locations}
                        callApi={locationsAPI}
                        disabled={isEdit}
                      />
                      <BoxApply
                        title={"Effective sources"}
                        titleRadioAll={"All sources"}
                        onChange={(data) => {
                          setFieldValue("sources", data);
                        }}
                        text={"sources"}
                        data={values?.sources}
                        callApi={appsAPI}
                        disabled={isEdit}
                        conditionFilter={{ status: "ACTIVE" }}
                      />
                      <BoxApply
                        title={"Effective customers"}
                        titleRadioAll={"All customers"}
                        onChange={(data) => {
                          setFieldValue("customers", data);
                        }}
                        text={"customers"}
                        data={values?.customers}
                        callApi={locationsAPI}
                        disabled={isEdit}
                      />
                    </Col>
                  </Row>
                </Form>
              </>
            )}
          </Formik>
        )}
      </Container>
    </div>
  );
};

const TableConditionOrder = ({ tableRef, disabled, data = {} }) => {
  const { t } = useTranslation();
  const [dataTable, setDataTable] = useState([]);
  const [showDropDown, setShowDropDown] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [typeTable, setTypeTable] = useState("condition");

  const columns = useMemo(() => {
    const newColumns = [
      {
        title: t(typeTable === "products" ? "Product name" : typeTable === "category" ? "Category name" : "Brand name"),
        key: "name",
        dataIndex: "name",
      },
      {
        title: t("Min product quantity in order"),
        key: "number_order",
        dataIndex: "number_order",
        render: (_, row) => {
          return (
            <div className="w-25" key={row.id}>
              <Input
                type="number"
                disabled={disabled}
                value={row.min_quantity}
                onChange={(e) => {
                  const newArr = dataTable.map((item) => {
                    if (item.id === row.id) {
                      return {
                        ...item,
                        quantity: e.target.value,
                      };
                    }
                    return item;
                  });
                  setDataTable(newArr);
                }}
              />
            </div>
          );
        },
      },
      {
        dataIndex: "action",
        key: "action",
        title: "",
        render: (_, row) => {
          return (
            <div className="fw-medium text-end">
              <ButtonTheme
                type="button"
                className="btn btn-outline-danger btn-icon waves-effect waves-light"
                onClick={() => {
                  setDataTable(dataTable.filter((item) => item.id !== row.id));
                  setCheckAll(false);
                }}
                disabled={disabled}
              >
                <i className="ri-delete-bin-5-line"></i>
              </ButtonTheme>
            </div>
          );
        },
      },
    ];
    if (typeTable === "products" || typeTable === "category") {
      newColumns.unshift({
        title: t(typeTable === "products" ? "SKU" : "Category Id"),
        dataIndex: "sku",
        key: "code_",
      });
    }
    return newColumns;
  }, [typeTable, dataTable]);

  useEffect(() => {
    if (disabled) {
      const listKey = Object.keys(data);
      setTypeTable(listKey?.[0] ? listKey?.[0] : "condition");
      setDataTable(data[listKey[0]]);
      if (data[listKey?.[0]]?.[0]?.id === "all") {
        setCheckAll(true);
      }
    }
  }, [data, disabled]);

  useImperativeHandle(tableRef, () => ({ [typeTable]: dataTable }));
  return (
    <>
      <div className="d-flex align-items-baseline">
        <span style={{ width: "220px" }}>{t("Order has")}</span>
        <div style={{ width: "250px" }}>
          <SelectTheme
            isForm={false}
            value={typeTable}
            name={"is_condition_contains_order"}
            options={[
              { value: "condition", label: "Select product condition" },
              { value: "products", label: "Product" },
              { value: "category", label: "Category" },
              { value: "brand", label: "Brand" },
            ]}
            disabled={disabled}
            onChange={(option) => {
              setTypeTable(option.value);
              setDataTable([]);
            }}
          />
        </div>
      </div>
      {typeTable !== "condition" && (
        <>
          <SearchDropdown
            onClickItem={(data) => {
              const mapData = {
                id: data?.id,
                sku: data?.sku,
                name: data?.name,
              };
              setDataTable([...dataTable, mapData]);
            }}
            isOpen={showDropDown}
            toggleOff={() => {
              setShowDropDown(false);
            }}
            toggleOn={() => {
              setShowDropDown(true);
            }}
            onCallApi={(data) => {
              variantsAPI.list(data);
            }}
            typeSearch={typeTable}
            isShowDefault={true}
            disabled={checkAll || disabled}
            customSubSearch={
              <div
                style={{ width: "400px" }}
                className="d-flex flex-row-reverse justify-content-end auth-pass-inputgroup form-check-primary"
              >
                <FormGroup check={true} className="mx-3">
                  <Label for="check-all" className="form-check-label">
                    {"All brands"}
                  </Label>
                  <Input
                    type="checkbox"
                    id="check-all"
                    onChange={(e) => {
                      const check = e.target.checked;
                      setCheckAll(check);
                      if (check) {
                        let dataALL;
                        if (typeTable === "products") {
                          dataALL = [
                            {
                              name: "All products",
                              id: "all",
                            },
                          ];
                        } else if (typeTable === "category") {
                          dataALL = [
                            {
                              name: "All categories",
                              id: "all",
                            },
                          ];
                        } else {
                          dataALL = [
                            {
                              name: "All brands",
                              id: "all",
                            },
                          ];
                        }
                        setDataTable(dataALL);
                      } else {
                        setDataTable([]);
                      }
                    }}
                    autoComplete="off"
                    checked={checkAll}
                    disabled={disabled}
                  />
                </FormGroup>
              </div>
            }
          />
          <Table
            dataSource={dataTable}
            columns={columns}
            rowClassName="editable-row"
            pagination={false}
            rowKey={(row) => row?.id}
            className=" mt-4"
          />
        </>
      )}
    </>
  );
};

export default CreateVoucher;
