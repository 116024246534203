import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";

// Redux States
import {
  LIST_PRODUCT,
  DELETE_PRODUCT,
  ADD_NEW_PRODUCT,
  UPDATE_PRODUCT,
  GET_PRODUCT,
  IMPORT_PRODUCTS,
} from "./actionType";

import {
  deleteProductSuccess,
  deleteProductFail,
  addProductSuccess,
  addProductFail,
  updateProductSuccess,
  updateProductFail,
  listProductSuccess,
  listProductFail,
  getProductSuccess,
  getProductFail,
} from "./action";

import { productsAPI } from "../../../../helpers/resource_helper";

function* listProduct({ payload: { ...rest } }) {
  try {
    const response = yield call(productsAPI.list, { ...rest });
    yield put(listProductSuccess(response));
  } catch (error) {
    yield put(listProductFail(error));
  }
}

function* getProduct({ payload: id }) {
  try {
    const response = yield call(productsAPI.get, { id });
    yield put(getProductSuccess(response));
  } catch (error) {
    yield put(getProductFail(error));
  }
}

function* deleteProducts({ payload: { product, handleAfterSubmit } }) {
  try {
    const response = yield call(productsAPI.delete, { id: product.id });
    yield put(deleteProductSuccess(product));
    yield call(handleAfterSubmit, response);
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteProductFail(error));
    toast.error(error.toString(), { autoClose: 3000 });
  }
}

function* onAddNewProduct({ payload: { product, handleAfterSubmit } }) {
  try {
    const response = yield call(productsAPI.create, product);
    yield put(addProductSuccess(response));
    yield call(handleAfterSubmit, response);
    toast.success("Product Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addProductFail(error));
    toast.error(error.toString(), { autoClose: 3000 });
  }
}

function* onUpdateProduct({ payload: { product, handleAfterSubmit } }) {
  try {
    const response = yield call(productsAPI.put, product);
    yield call(handleAfterSubmit, response);
    yield put(updateProductSuccess(response));
    toast.success("Product Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateProductFail(error));
    toast.error(error.toString(), { autoClose: 3000 });
  }
}

export function* watchGetProducts() {
  yield takeEvery(LIST_PRODUCT, listProduct);
}

export function* watchDeleteProducts() {
  yield takeEvery(DELETE_PRODUCT, deleteProducts);
}

export function* watchGetProduct() {
  yield takeEvery(GET_PRODUCT, getProduct);
}

export function* watchUpdateProduct() {
  yield takeEvery(UPDATE_PRODUCT, onUpdateProduct);
}

export function* watchAddNewProduct() {
  yield takeEvery(ADD_NEW_PRODUCT, onAddNewProduct);
}

function* productsSaga() {
  yield all([
    fork(watchGetProduct),
    fork(watchGetProducts),
    fork(watchUpdateProduct),
    fork(watchAddNewProduct),
    fork(watchDeleteProducts),
  ]);
}

export default productsSaga;
