import {
  LIST_BRAND,
  DELETE_BRAND,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_FAIL,
  ADD_NEW_BRAND,
  ADD_BRAND_SUCCESS,
  ADD_BRAND_FAIL,
  UPDATE_BRAND,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_FAIL,
  LIST_BRAND_FAIL,
  LIST_BRAND_SUCCESS,
  GET_BRAND,
  GET_BRAND_FAIL,
  GET_BRAND_SUCCESS,
} from "./actionType";

export const listBrand = (options) => ({
  type: LIST_BRAND,
  payload: options,
});

export const listBrandFail = (error) => ({
  type: LIST_BRAND_FAIL,
  payload: error,
});

export const listBrandSuccess = (brands) => ({
  type: LIST_BRAND_SUCCESS,
  payload: brands,
});

export const getBrand = () => ({
  type: GET_BRAND,
});

export const getBrandFail = (error) => ({
  type: GET_BRAND_FAIL,
  payload: error,
});

export const getBrandSuccess = (brand) => ({
  type: GET_BRAND_SUCCESS,
  payload: brand,
});

export const deleteBrand = (brand) => ({
  type: DELETE_BRAND,
  payload: brand,
});

export const deleteBrandSuccess = (brand) => ({
  type: DELETE_BRAND_SUCCESS,
  payload: brand,
});

export const deleteBrandFail = (error) => ({
  type: DELETE_BRAND_FAIL,
  payload: error,
});

export const updateBrand = (brand, handleAfterSubmit = () => {}) => ({
  type: UPDATE_BRAND,
  payload: { brand, handleAfterSubmit },
});

export const updateBrandSuccess = (brand) => ({
  type: UPDATE_BRAND_SUCCESS,
  payload: brand,
});

export const updateBrandFail = (error) => ({
  type: UPDATE_BRAND_FAIL,
  payload: error,
});

export const addNewBrand = (brand, handleAfterSubmit = () => {}) => ({
  type: ADD_NEW_BRAND,
  payload: { brand, handleAfterSubmit },
});

export const addBrandSuccess = (brand) => ({
  type: ADD_BRAND_SUCCESS,
  payload: brand,
});

export const addBrandFail = (error) => ({
  type: ADD_BRAND_FAIL,
  payload: error,
});
