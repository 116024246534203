import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import { isEmpty } from "lodash";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { deleteVoucher, listVoucher } from "../../../../store/actions";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import withRouter from "../../../../Components/Common/withRouter";
import { Link } from "react-router-dom";
import ModalContainer from "../../../../Components/Common/ModalContainer";
import { vouchersAPI } from "../../../../helpers/resource_helper";
import Loader from "../../../../Components/Common/Loader";
import BadgeCustom from "../../../../Components/Common/BadgeCustom";
import {
  formatCurrency,
  formatDate,
  formatDateTime,
  formatViewCurrency,
  getDateTimeRemaining,
} from "../../../../helpers/format_helper";
import { toast } from "react-toastify";
import { Table } from "antd";
import { changeStatusVoucher } from "../../../../helpers/service_helper";

const VouchersPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Voucher List")} | OptiWarehouse`;
  const dispatch = useDispatch();
  const [isModal, setIsModal] = useState(false);
  const [idVoucher, setIdVoucher] = useState("");
  const [titleModal, setTitleModal] = useState("");
  const {
    vouchers,
    vouchersLoading,
    vouchersAction,
    vouchersMessage,
    vouchersSuccess,
    vouchersError,
    vouchersLimit,
    vouchersPage,
    vouchersTotal,
  } = useMemoizedSelector((state) => ({
    vouchers: state.Vouchers.vouchers,
    vouchersLoading: state.Vouchers.loading,
    vouchersAction: state.Vouchers.action,
    vouchersSuccess: state.Vouchers.success,
    vouchersError: state.Vouchers.error,
    vouchersMessage: state.Vouchers.message,
    vouchersLimit: state.Vouchers.limit,
    vouchersTotal: state.Vouchers.total,
    vouchersPage: state.Vouchers.page,
  }));

  const headers = [
    "code",
    "name",
    "number_code",
    "value_voucher",
    "max_value_coupon",
    "start_at",
    "expire_at",
    "status",
    "action",
  ];

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">{t("Voucher List")}</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex gap-3 flex-wrap">
                        <ButtonTheme
                          type="button"
                          className="btn btn-light"
                          icon="ri-file-text-line"
                          onClick={() => {}}
                        >
                          {t("Export")}
                        </ButtonTheme>
                        <ButtonTheme
                          className="btn btn-primary"
                          id="create-btn"
                          icon="ri-add-line text-white"
                          onClick={() => {
                            navigate("/vouchers/create");
                          }}
                        >
                          {t("Add Voucher")}
                        </ButtonTheme>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <TableContainerCustom
                    filterType={"vouchers"}
                    searchPlaceHolder="Search vouchers..."
                    listFilter={[
                      {
                        id: "status",
                        type: "selectBox",
                        title: "Status",
                        dataOption: [
                          { id: "APPLY", name: "Apply" },
                          { id: "STOP_APPLY", name: "Stop applying" },
                          { id: "NOT_ACTIVE", name: "Not active" },
                          { id: "CANCELLED", name: "Cancelled" },
                        ],
                      },
                    ]}
                    headers={headers}
                    contents={vouchers}
                    limitPage={Number(vouchersLimit)}
                    currentPage={Number(vouchersPage) + 1}
                    totalItems={vouchersTotal}
                    loading={vouchersLoading}
                    showPagination={true}
                    scrollHeight="50vh"
                    onCallData={(dataPayload) => {
                      // const { sort_created_at, ...res } = dataPayload;
                      dispatch(listVoucher(dataPayload));
                    }}
                    isCheckbox={true}
                    isSettings={true}
                    listAction={[
                      {
                        title: "",
                        type: "boxSelect",
                        listAction: [
                          {
                            title: "Active",
                            subTitle: "Active voucher",
                            onClick: async (data) => {
                              await changeStatusVoucher({
                                status: "APPLY",
                                id: data,
                              });
                            },
                          },
                          {
                            title: "Pause",
                            subTitle: "Pause voucher",
                            onClick: async (data) => {
                              await changeStatusVoucher({
                                status: "STOP_APPLY",
                                id: data,
                              });
                            },
                          },
                          {
                            title: "Cancel",
                            subTitle: "Cancel voucher",
                            onClick: async (data) => {
                              await changeStatusVoucher({
                                status: "CANCELLED",
                                id: data,
                              });
                            },
                          },
                        ],
                      },
                    ]}
                    listHeader={[
                      {
                        title: t("Voucher details"),
                        key: "detail-voucher",
                        children: [
                          {
                            title: t("Voucher code"),
                            dataIndex: "code",
                            key: "code",
                            sorter: true,
                            render: (value, row, index) => {
                              return (
                                <Link to={`/vouchers/details/${row.id}`} className="link-primary text-truncate d-block">
                                  {value}
                                </Link>
                              );
                            },
                          },
                          {
                            title: t("Name"),
                            dataIndex: "name",
                            key: "name",
                            sorter: true,
                          },
                          {
                            title: t("Quantity"),
                            dataIndex: "number_code",
                            key: "number_code",
                            sorter: true,
                          },
                          {
                            title: t("Discount"),
                            dataIndex: "value_voucher",
                            key: "value_voucher",
                            sorter: true,
                            render: (value, row, index) => {
                              return (
                                <>{`${row?.value_coupon?.amount} ${row?.value_coupon?.type === "PERCENT" && "%"}`}</>
                              );
                            },
                          },
                          {
                            title: t("Max coupon value"),
                            dataIndex: "max_value_coupon",
                            key: "max_value_coupon",
                            sorter: true,
                            render: (value, row, index) => {
                              return <>{formatViewCurrency(value)}</>;
                            },
                          },
                          {
                            title: t("Start at"),
                            dataIndex: "start_at",
                            key: "start_at",
                            sorter: true,
                            render: (value, row, index) => {
                              return <>{formatDateTime(value)}</>;
                            },
                          },
                          {
                            title: t("Expire at"),
                            dataIndex: "expire_at",
                            key: "expire_at",
                            sorter: true,
                            render: (value, row, index) => {
                              return <>{value ? formatDateTime(value) : "∞"}</>;
                            },
                          },
                          {
                            title: t("Status"),
                            dataIndex: "status",
                            key: "status",
                            sorter: true,
                            render: (value, row, index) => {
                              return (
                                <>
                                  {value === "NOT_ACTIVE" ? (
                                    <span className="badge border bg-primary mb-2 mx-3">{t("Not active")}</span>
                                  ) : value === "APPLY" ? (
                                    <span className="badge border bg-success mb-2 mx-3">{t("Apply")}</span>
                                  ) : value === "STOP_APPLY" ? (
                                    <span className="badge bg-dark-subtle border text-body mb-2 mx-3">
                                      {t("Stop applying")}
                                    </span>
                                  ) : (
                                    <span className="badge bg-danger mb-2 mx-3">{t("Cancelled")}</span>
                                  )}
                                </>
                              );
                            },
                          },
                          {
                            title: t("Action"),
                            dataIndex: "action",
                            key: "action",
                            render: (value, row, index) => {
                              return (
                                <UncontrolledDropdown>
                                  <DropdownToggle href="#" className="btn btn-soft-primary btn-sm" tag="button">
                                    <i className="ri-more-fill" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end" container="body">
                                    <DropdownItem
                                      onClick={() => {
                                        navigate(`/vouchers/${row.id}/edit`);
                                      }}
                                    >
                                      {t("Edit")}
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => {
                                        navigate(`/voucher-code/${row.id}`);
                                      }}
                                    >
                                      {t("Voucher code")}
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    {row.status === "NOT_ACTIVE" || row.status === "STOP_APPLY" ? (
                                      <DropdownItem
                                        onClick={async () => {
                                          await changeStatusVoucher({
                                            status: "APPLY",
                                            id: row.id,
                                          });
                                          toast.success(t("Active voucher successfully!"));
                                          setTimeout(() => {
                                            dispatch(listVoucher({}));
                                          }, 1000);
                                        }}
                                      >
                                        {t("Active")}
                                      </DropdownItem>
                                    ) : (
                                      <DropdownItem
                                        onClick={async () => {
                                          await changeStatusVoucher({
                                            status: "STOP_APPLY",
                                            id: row.id,
                                          });
                                          toast.success(t("Stop applying voucher successfully!"));
                                          setTimeout(() => {
                                            dispatch(listVoucher({}));
                                          }, 1000);
                                        }}
                                      >
                                        {t("Stop applying")}
                                      </DropdownItem>
                                    )}
                                    <DropdownItem
                                      onClick={async () => {
                                        await changeStatusVoucher({
                                          status: "CANCELLED",
                                          id: row.id,
                                        });
                                        toast.success(t("Cancel voucher successfully!"));
                                        setTimeout(() => {
                                          dispatch(listVoucher({}));
                                        }, 900);
                                      }}
                                    >
                                      {t("Cancel")}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              );
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};
export default withRouter(VouchersPage);
