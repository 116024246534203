import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { useTranslation } from "react-i18next";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import TransactionModal from "../../../Ecommerce/Finance/Transactions/TransactionModal";
import { useNavigate } from "react-router-dom";
import noChart from "../../../../assets/images/svg/no-chart.svg";
import { locationsAPI } from "../../../../helpers/resource_helper";
import { formatVNDCurrency } from "../../../../helpers/format_helper";
import OrderReportTable from "../ReportTables/OrderReportTable";
import ReturnReportTable from "../ReportTables/ReturnReportTable";
import ProductReportTable from "../ReportTables/ProductReportTable";
import ReceiptPaymentReportTable from "../ReportTables/ReceiptPaymentReportTable";
import { getListUserAPI, getReportOrder } from "../../../../helpers/service_helper";
import SelectTheme from "../../../../Components/Common/SelectTheme";
import { periodOptions } from "../../../../Components/constants/common";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { toast } from "react-toastify";
const { RangePicker } = DatePicker;

const ReportModal = ({ show, onCloseClick, branch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const viewTypeOptions = [
    { id: "order_and_return", name: "Thông tin đơn hàng và trả hàng" },
    { id: "product", name: "Thông tin sản phẩm" },
    { id: "receipt_and_payment", name: "Thông tin phiểu thu chi" },
  ];
  const staffTypes = [
    { id: "creating_staff", name: "Nhân viên tạo đơn" },
    { id: "assigned_staff", name: "Nhân viên gán đơn" },
  ];
  const sub = JSON.parse(localStorage.getItem("auth_user"))?.UserAttributes["sub"];
  const [loading, setLoading] = useState(false);
  const [branches, setBranches] = useState([]);
  const [users, setUsers] = useState([]);
  const [viewType, setViewType] = useState("order_and_return");
  const [staffType, setStaffType] = useState("creating_staff");
  const [selectedBranch, setSelectedBranch] = useState(branch);
  const [selectSource, setSelectedSource] = useState("");
  const [selectedUser, setSelectedUser] = useState(sub);
  const [selectedPeriod, setSelectedPeriod] = useState("today");
  const [reportData, setReportData] = useState({});

  const [dateFrom, setdateFrom] = useState(dayjs().format("YYYY-MM-DD"));
  const [dateTo, setDateTo] = useState(dayjs().format("YYYY-MM-DD"));

  const getListUser = async () => {
    try {
      const company_id = JSON.parse(localStorage.getItem("auth_user"))?.UserAttributes["custom:company_id"];
      const res = await getListUserAPI({ company_id });
      const mapRes = res.Users.map((item) => {
        return {
          id: item["Attributes"].find((u) => u["Name"] === "sub")["Value"],
          name: item["Username"],
        };
      });
      setUsers(mapRes);
    } catch (e) {
      console.error(e);
    }
  };
  const getData = async () => {
    try {
      const res = await locationsAPI.list({ limit: 20 });

      setBranches(res?.items || []);
    } catch (e) {
      console.error(String(e));
    }
  };
  const getReport = async () => {
    if (!dateFrom || !dateTo) {
      toast.error(t("Required date range!"));
    }
    if (!selectedBranch) {
      toast.error(t("Required branch!"));
    }
    if (!selectedUser) {
      toast.error(t("Required staff!"));
    }
    try {
      setLoading(true);
      const payload = {
        date_from: dateFrom,
        date_to: dateTo,
        period: "custom",
        // staff_id: selectedUser,
        // location_id: selectedBranch,
      };
      const reportOrder = await getReportOrder(payload);
      setReportData(reportOrder);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleViewReport = () => {
    selectedPeriod !== "custom" && getReport();
  };

  const onRangeChange = (dates, dateStrings) => {
    setdateFrom(dateStrings[0]);
    setDateTo(dateStrings[1]);
  };
  useEffect(() => {
    if (show) {
      getData();
      getReport();
      getListUser();
    }
  }, [show]);

  return (
    <Fragment>
      <Modal fade={true} isOpen={show} toggle={onCloseClick} centered={true} fullscreen scrollable>
        <ModalHeader className="border-bottom" toggle={onCloseClick}>
          {t("Daily report")}
        </ModalHeader>
        <ModalBody className="py-3 px-5">
          <Row className="gap-5 mb-3">
            <Col>
              <div className="hstack gap-3">
                {t("View")}
                <SelectTheme
                  isForm={false}
                  classWrapper="flex-1 mb-0"
                  options={viewTypeOptions}
                  onChange={(option) => {
                    setViewType(option.value);
                  }}
                  value={viewType}
                />
              </div>
            </Col>
            <Col>
              <div className="hstack gap-3 mb-3">
                {t("Branch")}
                <SelectTheme
                  isForm={false}
                  classWrapper="flex-1 mb-0"
                  options={branches}
                  onChange={(option) => {
                    setSelectedBranch(option.value);
                  }}
                  value={selectedBranch}
                />
              </div>
              <div className="hstack gap-3">
                {t("Source")}
                <SelectTheme
                  isForm={false}
                  classWrapper="flex-1 mb-0"
                  options={branches}
                  onChange={(option) => {
                    setSelectedSource(option.value);
                  }}
                  value={selectSource}
                />
              </div>
            </Col>
            <Col>
              <div className="hstack gap-3 mb-3">
                <Label>{t("Date")}</Label>
                <RangePicker
                  presets={[
                    {
                      label: t("Today"),
                      value: () => [dayjs(), dayjs().endOf("day")],
                    },
                    {
                      label: t("Yesterday"),
                      value: () => [dayjs().add(-1, "d"), dayjs()],
                    },
                    {
                      label: t("Last 7 days"),
                      value: [dayjs().add(-7, "d"), dayjs().endOf("day")],
                    },
                    {
                      label: t("Last 30 days"),
                      value: [dayjs().add(-30, "d"), dayjs().endOf("day")],
                    },
                    {
                      label: t("Last month"),
                      value: [dayjs().add(-1, "m").startOf("month"), dayjs().add(-1, "m").endOf("month")],
                    },
                  ]}
                  format={"YYYY-MM-DD"}
                  defaultValue={[dayjs(), dayjs()]}
                  onChange={onRangeChange}
                />
              </div>
              <div className="hstack gap-3">
                <SelectTheme
                  isForm={false}
                  classWrapper="flex-1 mb-0"
                  options={users}
                  onChange={(option) => {
                    setSelectedUser(option.value);
                  }}
                  value={selectedUser}
                />
                
                <SelectTheme
                  isForm={false}
                  classWrapper="flex-1 mb-0"
                  options={staffTypes}
                  onChange={(option) => {
                    setStaffType(option.value);
                  }}
                  value={staffType}
                />
              </div>
            </Col>
            <Col lg={2}>
              <div className="d-flex flex-column">
                <ButtonTheme className={"btn btn-primary mb-3"} onClick={handleViewReport} loading={loading}>
                  Xem báo cáo
                </ButtonTheme>
                <ButtonTheme className={"btn btn-primary"}>In phiếu tổng kết</ButtonTheme>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="d-flex justify-content-center">
                <img src={noChart} alt="" />
              </div>
              {viewType === "order_and_return" ? (
                <Fragment>
                  <OrderReportTable data={reportData} />
                  <ReturnReportTable data={{}} />
                </Fragment>
              ) : viewType === "product" ? (
                <ProductReportTable data={{}} />
              ) : (
                <ReceiptPaymentReportTable data={{}} />
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default ReportModal;
