import {
  LIST_CUSTOMER,
  LIST_CUSTOMER_FAIL,
  LIST_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAIL,
  ADD_NEW_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAIL,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAIL,
  GET_CUSTOMER,
  GET_CUSTOMER_FAIL,
  GET_CUSTOMER_SUCCESS,
  RESET_CUSTOMER_FLAG,
} from "./actionType";

export const listCustomer = (options) => ({
  type: LIST_CUSTOMER,
  payload: options,
});

export const listCustomerFail = (error) => ({
  type: LIST_CUSTOMER_FAIL,
  payload: error,
});

export const listCustomerSuccess = (customers) => ({
  type: LIST_CUSTOMER_SUCCESS,
  payload: customers,
});

export const getCustomer = (customerID) => ({
  type: GET_CUSTOMER,
  payload: customerID,
});

export const getCustomerFail = (error) => ({
  type: GET_CUSTOMER_FAIL,
  payload: error,
});

export const getCustomerSuccess = (customer) => ({
  type: GET_CUSTOMER_SUCCESS,
  payload: customer,
});

export const deleteCustomer = (customer, handleAfterSubmit = () => {}) => ({
  type: DELETE_CUSTOMER,
  payload: { customer, handleAfterSubmit },
});

export const deleteCustomerSuccess = (customer) => ({
  type: DELETE_CUSTOMER_SUCCESS,
  payload: customer,
});

export const deleteCustomerFail = (error) => ({
  type: DELETE_CUSTOMER_FAIL,
  payload: error,
});

export const updateCustomer = (customer, handleAfterSubmit = () => {}) => ({
  type: UPDATE_CUSTOMER,
  payload: { customer, handleAfterSubmit },
});

export const updateCustomerSuccess = (customer) => ({
  type: UPDATE_CUSTOMER_SUCCESS,
  payload: customer,
});

export const updateCustomerFail = (error) => ({
  type: UPDATE_CUSTOMER_FAIL,
  payload: error,
});

export const addNewCustomer = (customer, handleAfterSubmit = () => {}) => ({
  type: ADD_NEW_CUSTOMER,
  payload: { customer, handleAfterSubmit },
});

export const addCustomerSuccess = (customer) => ({
  type: ADD_CUSTOMER_SUCCESS,
  payload: customer,
});

export const addCustomerFail = (error) => ({
  type: ADD_CUSTOMER_FAIL,
  payload: error,
});

export const resetCustomer = () => ({
  type: RESET_CUSTOMER_FLAG,
});
