import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  RESET_REGISTER_FLAG,
  VERIFY_CONFIRMATION_CODE,
  VERIFY_CONFIRMATION_CODE_SUCCESSFUL,
  VERIFY_CONFIRMATION_CODE_FAILED,
  RESEND_CONFIRMATION_CODE,
  RESEND_CONFIRMATION_CODE_SUCCESSFUL,
  RESEND_CONFIRMATION_CODE_FAILED,
} from "./actionTypes";

export const registerUser = (user) => {
  return {
    type: REGISTER_USER,
    payload: { user },
  };
};

export const registerUserSuccessful = (user) => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  };
};

export const registerUserFailed = (user) => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  };
};

export const resetRegisterFlag = () => {
  return {
    type: RESET_REGISTER_FLAG,
  };
};

export const verifyConfirmationCode = (confirmationPayload) => {
  return {
    type: VERIFY_CONFIRMATION_CODE,
    payload: confirmationPayload,
  };
};

export const verifyConfirmationCodeSuccessful = (user) => {
  return {
    type: VERIFY_CONFIRMATION_CODE_SUCCESSFUL,
    payload: user,
  };
};

export const verifyConfirmationCodeFailed = (errorMessage) => {
  return {
    type: VERIFY_CONFIRMATION_CODE_FAILED,
    payload: errorMessage,
  };
};

export const resendConfirmationCode = (username) => {
  return {
    type: RESEND_CONFIRMATION_CODE,
    payload: { username },
  };
};

export const resendConfirmationCodeSuccess = (msg) => {
  return {
    type: RESEND_CONFIRMATION_CODE_SUCCESSFUL,
    payload: msg,
  };
};

export const resendConfirmationCodeFailed = (error) => {
  return {
    type: RESEND_CONFIRMATION_CODE_FAILED,
    payload: error,
  };
};
