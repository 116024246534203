import { Actions } from "../../../../Components/constants/common";
import {
  LIST_IMPORT,
  LIST_IMPORT_SUCCESS,
  LIST_IMPORT_FAIL,
} from "./actionType";

const INIT_STATE = {
  imports: [],
  import_details: [],
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const Imports = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_IMPORT:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_IMPORT_SUCCESS:
      return {
        ...state,
        message: "List imports success",
        loading: false,
        success: true,
        imports: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_IMPORT_FAIL:
      return {
        ...state,
        message: "List imports failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    default:
      return { ...state };
  }
};

export default Imports;
