// Actions
export const LIST_DISCOUNT = "LIST_DISCOUNT";
export const LIST_DISCOUNT_SUCCESS = "LIST_DISCOUNT_SUCCESS";
export const LIST_DISCOUNT_FAIL = "LIST_DISCOUNT_FAIL";

export const GET_DISCOUNT = "GET_DISCOUNT";
export const GET_DISCOUNT_SUCCESS = "GET_DISCOUNT_SUCCESS";
export const GET_DISCOUNT_FAIL = "GET_DISCOUNT_FAIL";

/**
 * Delete DISCOUNT
 */
export const DELETE_DISCOUNT = "DELETE_DISCOUNT";
export const DELETE_DISCOUNT_SUCCESS = "DELETE_DISCOUNT_SUCCESS";
export const DELETE_DISCOUNT_FAIL = "DELETE_DISCOUNT_FAIL";

/**
 * Add DISCOUNT
 */
export const ADD_NEW_DISCOUNT = "ADD_NEW_DISCOUNT";
export const ADD_DISCOUNT_SUCCESS = "ADD_DISCOUNT_SUCCESS";
export const ADD_DISCOUNT_FAIL = "ADD_DISCOUNT_FAIL";

/**
 * Edit DISCOUNT
 */
export const UPDATE_DISCOUNT = "UPDATE_DISCOUNT";
export const UPDATE_DISCOUNT_SUCCESS = "UPDATE_DISCOUNT_SUCCESS";
export const UPDATE_DISCOUNT_FAIL = "UPDATE_DISCOUNT_FAIL";

export const RESET_DISCOUNT_FLAG = "RESET_DISCOUNT_FLAG";

