import { useDispatch } from "react-redux";
import {
  ButtonGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  UncontrolledDropdown,
} from "reactstrap";
import { Fragment, useEffect, useState } from "react";
import { useDebounce } from "../Hooks/UseDebounce";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { Tooltip } from "antd";
import ButtonTheme from "./ButtonTheme";
import { isEmpty } from "lodash";

const ButtonDropdownCustom = ({
  text = "",
  data,
  options = null,
  isEdit = true,
  buttonClass,
  isObject = true,
  listRemoteData = async () => {},
  onChangeData = () => {},
  conditionFilter = {},
}) => {
  const { t } = useTranslation();
  const [listItems, setListItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const limit = 10;
  const [isOpen, setIsOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const debouncedInputValue = useDebounce(searchValue, 1000);

  const handleCheckList = (selectedItem) => {
    const hasChecked =
      data &&
      data.findIndex((item) => (isObject && item?.id == selectedItem.id) || (!isObject && item == selectedItem.id)) >
        -1;
    if (hasChecked) {
      const newData = data.filter(
        (item) => (isObject && item?.id != selectedItem.id) || (!isObject && item != selectedItem.id),
      );

      onChangeData(newData.length > 0 ? newData : null);
    } else {
      const payload = isObject
        ? options
          ? { id: selectedItem.id, name: selectedItem.name }
          : { ...selectedItem }
        : selectedItem.id;
      if (data) {
        onChangeData([...data, payload]);
      } else {
        onChangeData([payload]);
      }
    }
  };
  const handleCheckAll = (checked) => {
    if (checked) {
      const newData = listItems.map((item) => {
        return isObject ? (options ? { id: item.id, name: item.name } : item) : item.id;
      });
      onChangeData(newData);
    } else {
      onChangeData(null);
    }
  };
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const firstLoad = async () => {
    if (options) {
      return;
    }
    try {
      setLoading(true);
      const res = await listRemoteData.list({
        limit,
        ...(page > 0 && { page }),
        ...(debouncedInputValue && { query: debouncedInputValue }),
        ...conditionFilter,
        sort_created_at: "desc",
      });
      setListItems(res?.items || []);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getDataScroll = async () => {
    if (options) {
      return;
    }
    try {
      const res = await listRemoteData.list({
        limit,
        ...(page > 0 && { page }),
        ...(searchValue && { query: searchValue }),
      });
      setPage(Number(res.page));
      setListItems([...listItems, ...(res?.items || [])]);
      if (Number(res.limit) * (Number(res.page) + 1) < res.total) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (e) {
      console.error(String(e));
    }
  };

  useEffect(() => {
    if (options) {
      setListItems(options.filter((item) => item?.name.includes(debouncedInputValue)));
    }
  }, [debouncedInputValue]);

  useEffect(() => {
    firstLoad();
  }, []);

  return (
    <Fragment>
      <ButtonGroup className="d-block">
        <UncontrolledDropdown isOpen={isOpen} toggle={toggle} disabled={!isEdit}>
          <DropdownToggle tag="div" className={`${buttonClass} ${!isEdit && "bg-light"}`} style={{ minHeight: "40px" }}>
            <span className="me-auto">
              {data ? (
                data
                  .filter((item) => item.id !== "ALL")
                  .map((item, index) => {
                    const neededItem = isObject ? item : listItems.find((subItem) => subItem.id == item);
                    return (
                      <Tooltip title={neededItem?.name} key={index}>
                        <div className="d-inline-block p-1 bg-soft-primary rounded-4 w-auto">
                          <div className="d-inline-flex">
                            <div className="text-truncate" style={{ maxWidth: "100px" }}>
                              {t(neededItem?.name)}
                            </div>
                            {isEdit && (
                              <button
                                type="button"
                                className="btn btn-icon btn-ghost-primary rounded-circle ms-2"
                                style={{ height: "18px", width: "18px" }}
                                // onClick={() => onDelete(item.id)}
                              >
                                <i className="ri-close-line text-primary"></i>
                              </button>
                            )}
                          </div>
                        </div>
                      </Tooltip>
                    );
                  })
              ) : (
                <span className="text-muted align-self-center h-100">{t("Select " + text)}</span>
              )}
            </span>
          </DropdownToggle>
          <DropdownMenu id="infinity-scroll-dropdown-menu" style={{ width: "400px" }}>
            <div className="d-flex form-icon m-1">
              <input
                type="text"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                style={{ paddingRight: "2.7rem" }}
                className="form-control form-control-icon"
                id="iconInput"
                placeholder={t("Search...")}
              />
              <i className="mdi mdi-magnify search-widget-icon"></i>
            </div>
            <DropdownItem toggle={false} className="border-bottom" tag="span">
              <div className="form-check form-check-primary py-2">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  id={`check-all-${text}`}
                  value={""}
                  onChange={(e) => {
                    handleCheckAll(e.target.checked);
                  }}
                  checked={data && data.length == listItems.length}
                />
                <Label className="form-check-label w-100" for={`check-all-${text}`}>
                  {t("Check All")}
                </Label>
              </div>
            </DropdownItem>
            {listItems.length == 0 && loading ? (
              <Loader />
            ) : (
              <InfiniteScroll
                dataLength={listItems.length}
                next={() => {
                  getDataScroll();
                }}
                hasMore={hasMore}
                loader={<Loader />}
                scrollableTarget="infinity-scroll-dropdown-menu"
              >
                {listItems.map((option, key) => {
                  return (
                    <DropdownItem key={key} toggle={false} className="border-bottom" tag="span">
                      <FormGroup check={true} className={`form-check-primary hstack gap-2`}>
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          id={text + option.id}
                          value={option.id}
                          onChange={(e) => {
                            handleCheckList(option);
                          }}
                          checked={data && data.findIndex((item) => item?.id == option?.id || item == option?.id) > -1}
                        />
                        <Label className="form-check-label opacity-100 w-100" htmlFor={text + option.id}>
                          {t(option.name)}
                        </Label>
                      </FormGroup>
                    </DropdownItem>
                  );
                })}
              </InfiniteScroll>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </ButtonGroup>
      {isEdit && data && (
        <div className="text-end">
          <ButtonTheme
            className={"btn btn-ghost-danger"}
            onClick={() => {
              onChangeData(null);
            }}
          >
            {t("Clear All")}
          </ButtonTheme>
        </div>
      )}
    </Fragment>
  );
};

export default ButtonDropdownCustom;
