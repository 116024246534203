import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import BadgeCustom from "../../../../Components/Common/BadgeCustom";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { Link, useNavigate } from "react-router-dom";
import { getListUserAPI } from "../../../../helpers/service_helper";
import withRouter from "../../../../Components/Common/withRouter";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import { permissionAPI } from "../../../../helpers/resource_helper";
import ActionDropdown from "../../../../Components/Common/ActionDropdown";
import ChangePassModal from "./ChangePassModal";

function Accounts({ router: { t } }) {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [permissionLoading, setPermissionLoading] = useState(false);
  const headers = ["username", "email", "custom:role", "custom:is_init", "action"];
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState("");

  const [users, setUsers] = useState([]);
  // const [usersWithPermission, setUsersWithPermission] = useState([]);
  const toggle = () => {
    setIsOpen(false);
    setSelectedUsername("");
  };
  const handleChangePass = (data) => {
    setIsOpen(true);
    setSelectedUsername(data?.username || "");
  };
  const listUser = async () => {
    try {
      setLoading(true);
      const company_id = JSON.parse(localStorage.getItem("auth_user"))?.UserAttributes["custom:company_id"];
      const res = await getListUserAPI({ company_id });
      const mapRes = res.Users.map((item) => {
        const attributes = {};
        item["Attributes"].forEach((subItem) => (attributes[subItem["Name"]] = subItem["Value"]));
        return {
          ...attributes,
          username: item["Username"],
        };
      });
      setTotal(mapRes.length);
      setLimit(mapRes.length);
      setUsers(mapRes);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  // const listPermission = async (limit) => {
  //   try {
  //     setPermissionLoading(true);
  //     const res = await permissionAPI.list({ limit });
  //     console.log(res.items);
  //     console.log(users);
  //     setUsersWithPermission(res);
  //   } catch (e) {
  //     console.error(e);
  //   } finally {
  //     setPermissionLoading(false);
  //   }
  // };

  useEffect(() => {
    listUser();
  }, []);

  // useEffect(() => {
  //   if (users.length > 0) {
  //     listPermission(users.length);
  //   }
  // }, [users]);

  const location_id = JSON.parse(localStorage.getItem("location"))?.id;
  return (
    <div className="page-content">
      <Container fluid>
        <ChangePassModal show={isOpen} toggle={toggle} username={selectedUsername} />
        <BreadCrumb title={t("Employees and Permissions")} pageTitle="Settings" navigateTo="/settings" isBack={true} />
        <Card>
          <CardHeader>
            <Row className="g-4 align-items-center">
              <div className="col-sm">
                <div>
                  <h5 className="card-title mb-0">{t("Employees and Permissions")}</h5>
                </div>
              </div>
              <div className="col-sm-auto">
                <div className="d-flex gap-3 flex-wrap">
                  <ButtonTheme
                    type="button"
                    className="btn btn-primary"
                    id="create-btn"
                    icon="ri-add-line text-white"
                    onClick={() => {
                      navigate("create");
                    }}
                  >
                    {t("Add account")}
                  </ButtonTheme>
                  <ButtonTheme
                    type="button"
                    className="btn btn-info"
                    id="create-btn"
                    icon="ri-lock-unlock-fill text-white"
                    onClick={() => {
                      navigate("role");
                    }}
                  >
                    {t("Role-based permissions")}
                  </ButtonTheme>
                </div>
              </div>
            </Row>
          </CardHeader>
          <CardBody className="pt-0">
            <TableContainerCustom
              defaultSaveFilter={[]}
              headers={headers}
              contents={users}
              limitPage={Number(limit)}
              currentPage={Number(page) + 1}
              totalItems={total}
              loading={loading || permissionLoading}
              showPagination={true}
              scrollHeight="50vh"
              onCallData={(dataPayload) => {}}
              isSettings={true}
              listHeader={[
                {
                  title: "Thông tin nhân viên",
                  key: "detail-variant",
                  children: [
                    {
                      title: "Username",
                      dataIndex: "username",
                      key: "username",
                      render: (data, row) => {
                        return (
                          <Link to={`/settings/accounts/${row?.username}`} className="link-primary">
                            {row?.username || "---"}
                          </Link>
                        );
                      },
                    },
                    {
                      title: "Email",
                      dataIndex: "email",
                      key: "email",
                      render: (data, row) => {
                        return data || "---";
                      },
                    },
                    {
                      title: "Role",
                      dataIndex: "custom:role",
                      key: "custom:role",
                      render: (data, row) => {
                        return data || "---";
                      },
                    },
                    {
                      title: "Initial",
                      dataIndex: "custom:is_init",
                      key: "custom:is_init",
                      render: (data, row) => {
                        return data || "---";
                      },
                    },
                    {
                      title: "Action",
                      dataIndex: "action",
                      key: "action",
                      render: (value, row, index) => {
                        return (
                          <UncontrolledDropdown>
                            <DropdownToggle href="#" className="btn btn-soft-primary btn-sm" tag="button">
                              <i className="ri-more-fill" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end" container="body">
                              <DropdownItem
                                onClick={() => {
                                  navigate(`/settings/accounts/${row?.username}`);
                                }}
                              >
                                <i className="ri-eye-fill align-bottom me-2 text-primary"></i> {t("View")}
                              </DropdownItem>
                              <DropdownItem onClick={() => handleChangePass(row)}>
                                <i className="ri-pencil-fill align-bottom me-2 text-primary"></i> {t("Change password")}
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        );
                      },
                    },
                  ],
                },
              ]}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}

export default withRouter(Accounts);
