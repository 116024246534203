// Actions
export const LIST_TRANSACTION = "LIST_TRANSACTION";
export const LIST_TRANSACTION_SUCCESS = "LIST_TRANSACTION_SUCCESS";
export const LIST_TRANSACTION_FAIL = "LIST_TRANSACTION_FAIL";

export const GET_TRANSACTION = "GET_TRANSACTION";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_FAIL = "GET_TRANSACTION_FAIL";

export const ADD_NEW_TRANSACTION = "ADD_NEW_TRANSACTION";
export const ADD_TRANSACTION_SUCCESS = "ADD_TRANSACTION_SUCCESS";
export const ADD_TRANSACTION_FAIL = "ADD_TRANSACTION_FAIL";

export const CANCEL_TRANSACTION = "CANCEL_TRANSACTION";
export const CANCEL_TRANSACTION_SUCCESS = "CANCEL_TRANSACTION_SUCCESS";
export const CANCEL_TRANSACTION_FAIL = "CANCEL_TRANSACTION_FAIL";
