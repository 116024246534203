import {
  LIST_REWARD_PROGRAM,
  LIST_REWARD_PROGRAM_FAIL,
  LIST_REWARD_PROGRAM_SUCCESS,
  DELETE_REWARD_PROGRAM,
  DELETE_REWARD_PROGRAM_SUCCESS,
  DELETE_REWARD_PROGRAM_FAIL,
  ADD_NEW_REWARD_PROGRAM,
  ADD_REWARD_PROGRAM_SUCCESS,
  ADD_REWARD_PROGRAM_FAIL,
  UPDATE_REWARD_PROGRAM,
  UPDATE_REWARD_PROGRAM_SUCCESS,
  UPDATE_REWARD_PROGRAM_FAIL,
  GET_REWARD_PROGRAM,
  GET_REWARD_PROGRAM_FAIL,
  GET_REWARD_PROGRAM_SUCCESS,
  RESET_REWARD_PROGRAM_FLAG,
} from "./actionType";

export const listRewardProgram = (options) => ({
  type: LIST_REWARD_PROGRAM,
  payload: options,
});

export const listRewardProgramFail = (error) => ({
  type: LIST_REWARD_PROGRAM_FAIL,
  payload: error,
});

export const listRewardProgramSuccess = (rewardPrograms) => ({
  type: LIST_REWARD_PROGRAM_SUCCESS,
  payload: rewardPrograms,
});

export const getRewardProgram = (rewardProgramID) => ({
  type: GET_REWARD_PROGRAM,
  payload: rewardProgramID,
});

export const getRewardProgramFail = (error) => ({
  type: GET_REWARD_PROGRAM_FAIL,
  payload: error,
});

export const getRewardProgramSuccess = (rewardProgram) => ({
  type: GET_REWARD_PROGRAM_SUCCESS,
  payload: rewardProgram,
});

export const deleteRewardProgram = (rewardProgram, handleAfterSubmit = () => {}) => ({
  type: DELETE_REWARD_PROGRAM,
  payload: { rewardProgram, handleAfterSubmit },
});

export const deleteRewardProgramSuccess = (rewardProgram) => ({
  type: DELETE_REWARD_PROGRAM_SUCCESS,
  payload: rewardProgram,
});

export const deleteRewardProgramFail = (error) => ({
  type: DELETE_REWARD_PROGRAM_FAIL,
  payload: error,
});

export const updateRewardProgram = (rewardProgram, handleAfterSubmit = () => {}) => ({
  type: UPDATE_REWARD_PROGRAM,
  payload: { rewardProgram, handleAfterSubmit },
});

export const updateRewardProgramSuccess = (rewardProgram) => ({
  type: UPDATE_REWARD_PROGRAM_SUCCESS,
  payload: rewardProgram,
});

export const updateRewardProgramFail = (error) => ({
  type: UPDATE_REWARD_PROGRAM_FAIL,
  payload: error,
});

export const addNewRewardProgram = (rewardProgram, handleAfterSubmit = () => {}) => ({
  type: ADD_NEW_REWARD_PROGRAM,
  payload: { rewardProgram, handleAfterSubmit },
});

export const addRewardProgramSuccess = (rewardProgram) => ({
  type: ADD_REWARD_PROGRAM_SUCCESS,
  payload: rewardProgram,
});

export const addRewardProgramFail = (error) => ({
  type: ADD_REWARD_PROGRAM_FAIL,
  payload: error,
});

export const resetRewardProgram = () => ({
  type: RESET_REWARD_PROGRAM_FLAG,
});
