import { call, delay, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, initAccount, loginSuccess, logoutUserSuccess } from "./actions";

import { userInitial, userRole } from "../../../common/data/AuthUser/authUser";

//Include Both Helper File with needed methods
import { getInfoAPI, signInAPI } from "../../../helpers/AuthAPI";
import { setAuthorization } from "../../../helpers/api_helper";

function* loginUser({ payload: { user, navigate, historyPath } }) {
  try {
    const payload = { username: user.username, password: user.password };
    const tokenResponse = yield call(signInAPI, payload);
    if (tokenResponse) {
      const { AccessToken, RefreshToken, IdToken } = tokenResponse;
      setAuthorization(IdToken);
      const payload = {
        access_token: AccessToken,
        token: IdToken,
        username: user.username,
      };
      let userInfoResponse = yield call(getInfoAPI, payload);
      if (userInfoResponse) {
        let userAttributes = {};
        userInfoResponse.UserAttributes.forEach((item) => (userAttributes[item.Name] = item.Value));
        // Loop until the required conditions are met
        while (
          userAttributes["custom:role"] === userRole.ADMIN &&
          userAttributes["custom:is_init"] === userInitial.RUNNING
        ) {
          try {
            yield put(initAccount());
            // Delay for 5 second before making the next call
            yield delay(5000);
            userInfoResponse = yield call(getInfoAPI, payload);
            if (!userInfoResponse) break; // Break the loop if userInfoResponse is null
            userInfoResponse.UserAttributes.forEach((item) => (userAttributes[item.Name] = item.Value));
          } catch (error) {
            // Handle any errors that occur during the getInfoAPI call
            yield put(apiError(error));
            // You may want to break the loop or take other actions based on the error
            break;
          }
        }
        const auth_user = {
          ...userInfoResponse,
          UserAttributes: userAttributes,
          Token: tokenResponse,
        };
        yield put(loginSuccess(auth_user));
        localStorage.setItem("auth_user", JSON.stringify(auth_user));
        const path = historyPath ? (historyPath === "/login" ? "/dashboard" : historyPath) : "/dashboard";
        navigate(path);
      } else {
        yield put(apiError(userInfoResponse));
      }
    } else {
      yield put(apiError(tokenResponse));
    }
  } catch (error) {
    const errorString = String(error);
    if (errorString.includes("User is not confirmed")) {
      yield put(apiError(errorString));
      navigate("/verify-confirmation-code", { state: { username: user.username } });
    } else if (errorString.includes(":")) {
      yield put(apiError(errorString.split(":")?.at(1).trim()));
    } else {
      yield put(apiError(errorString));
    }
  }
}

function* logoutUser() {
  try {
    localStorage.clear();
    yield put(logoutUserSuccess(true));
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
