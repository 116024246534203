import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { listDiscount } from "../../../../store/actions";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import {
  Actions,
  DiscountAmountType,
  DiscountStatuses,
  DiscountTypeOptions,
  DiscountTypes,
  EffectiveProduct,
} from "../../../../Components/constants/common";
import Loader from "../../../../Components/Common/Loader";
import {
  deepCopy,
  formatVNDCurrency,
  handleReturnQuantityByItemType,
  returnDiscountInfoFieldByType,
} from "../../../../helpers/format_helper";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ImageCustom from "../../../../Components/Common/ImageCustom";
import ViewConditionBody from "./ViewConditionBody";
import PurchasedConditionBody from "./PurchasedConditionBody";
import OrderTotalConditionBody from "./OrderTotalConditionBody";

const PromotionModal = ({
  isOpen,
  toggle,
  onChangeDiscountId,
  selectedDiscountId,
  order,
  discountDetails,
  onChangeDiscountDetails,
  selectedLocation,
  selectedPriceGroup,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { discounts, discountsAction, discountsLoading, discountsMessage, discountsSuccess, discountsError } =
    useMemoizedSelector((state) => ({
      discounts: state.Discounts.discounts,
      discountsAction: state.Discounts.action,
      discountsLoading: state.Discounts.loading,
      discountsSuccess: state.Discounts.success,
      discountsError: state.Discounts.error,
      discountsMessage: state.Discounts.message,
    }));
  const [viewDetailsType, setViewDetailsType] = useState("");
  const [activeDiscounts, setActiveDiscounts] = useState([]);
  const [tempSelectedDiscount, setTempSelectedDiscount] = useState(null);
  const [tempDiscountDetails, setTempDiscountDetails] = useState(null);
  const [selectedPromotionId, setSelectedPromotionId] = useState(null);

  const ViewConditionsCase = [DiscountTypes.BY_PRODUCT, DiscountTypes.BY_QUANTITY];
  const ChooseDiscountCase = [DiscountTypes.BY_PURCHASE_PRODUCT, DiscountTypes.PRODUCT_BY_ORDER_TOTAL];
  const ChooseGiftCase = [DiscountTypes.GIFT_BY_ORDER_TOTAL, DiscountTypes.GIFT_BY_PURCHASE_PRODUCT];

  const handleChangeRadio = (data) => {
    setSelectedPromotionId(data);
  };

  const handleChangeTempDiscountDetails = (data) => {
    setTempDiscountDetails(data);
  };

  const handleChangePromotion = (item) => {
    if (selectedPromotionId === item.id) {
      handleChangeRadio(null);
      handleChangeTempDiscountDetails([]);
      setTempSelectedDiscount(null);
    } else {
      if (item.type === DiscountTypes.BY_ORDER_TOTAL) {
        handleChangeTempDiscountDetails([]);
      } else {
        const newDiscountDetails = returnDiscountDetailsData(item);
        handleChangeTempDiscountDetails(newDiscountDetails);
      }
      setTempSelectedDiscount(item);
      handleChangeRadio(item.id);
    }
  };

  const handleChangeViewDetailsType = (data) => {
    setTempSelectedDiscount(data ? data : null);
    setViewDetailsType(data?.type ? data.type : "");
  };

  const order_total = order?.sub_total || 0;

  const handleReturnFilteredOrderLineItems = (c, items) => {
    let filtered_order_items = [];
    const item_type = c["item_type"];
    const condition_line_key = `${item_type.toLowerCase()}`;
    filtered_order_items = items.filter((order_line_item) => {
      return c[condition_line_key].find((item) => {
        if (item_type === EffectiveProduct.PRODUCTS) {
          return item.id === order_line_item.product_id;
        }
        if (item_type === EffectiveProduct.VARIANTS) {
          return item.id === order_line_item.variant_id;
        }
        if (item_type === EffectiveProduct.CATEGORIES) {
          return item.id === order_line_item.category?.id;
        }
        if (item_type === EffectiveProduct.PRODUCTS) {
          return item.id === order_line_item.brand?.id;
        }
        return false;
      });
    });
    return filtered_order_items;
  };

  const returnDiscountDetailsData = (discount) => {
    const conditions = discount[returnDiscountInfoFieldByType(discount.type)];
    // check discount by product condition
    let filterOrderLineItems = [];
    const new_order_line_items = order.order_line_items.filter((item) => !item?.custom);
    if (discount.type === DiscountTypes.BY_PRODUCT) {
      const for_all_items = conditions?.[0]?.for_all_items;
      if (typeof for_all_items === "boolean") {
        conditions.forEach((c) => {
          if (for_all_items) {
            const total_quantity = new_order_line_items.reduce(
              (total, order_line_item) => total + Number(order_line_item.quantity),
              0,
            );
            if (c.min_quantity <= total_quantity && total_quantity <= c.discount_limit) {
              filterOrderLineItems = deepCopy(
                new_order_line_items.map((item) => {
                  item.sale_price =
                    item.unit_price -
                    (c.type === DiscountAmountType.VALUE ? c.amount : (c.rate * item.unit_price) / 100);
                  return item;
                }),
              );
            }
          } else {
            const filtered_order_items = handleReturnFilteredOrderLineItems(c, new_order_line_items);
            const total_quantity = filtered_order_items.reduce(
              (total, order_line_item) => total + Number(order_line_item.quantity),
              0,
            );
            if (c.min_quantity <= total_quantity && total_quantity <= c.discount_limit) {
              filterOrderLineItems = deepCopy([
                ...filterOrderLineItems,
                ...filtered_order_items.map((item) => {
                  item.sale_price =
                    item.unit_price -
                    (c.type === DiscountAmountType.VALUE ? c.amount : (c.rate * item.unit_price) / 100);
                  return item;
                }),
              ]);
            }
          }
        });
      }
    }
    // check discount by quantity condition
    if (discount.type === DiscountTypes.BY_QUANTITY) {
      const for_all_items = conditions?.[0]?.for_all_items;
      if (typeof for_all_items === "boolean") {
        conditions.forEach((c) => {
          if (for_all_items) {
            const total_quantity = new_order_line_items.reduce(
              (total, order_line_item) => total + Number(order_line_item.quantity),
              0,
            );
            const quantityCondition = c.conditions.find((cc) =>
              cc.quantity_to
                ? cc.quantity_from <= total_quantity && total_quantity <= cc.quantity_to
                : cc.quantity_from <= total_quantity,
            );
            if (quantityCondition) {
              filterOrderLineItems = deepCopy(
                new_order_line_items.map((item) => {
                  item.sale_price =
                    item.unit_price -
                    (quantityCondition.type === DiscountAmountType.VALUE
                      ? quantityCondition.amount
                      : (quantityCondition.rate * item.unit_price) / 100);
                  return item;
                }),
              );
            }
          } else {
            const filtered_order_items = handleReturnFilteredOrderLineItems(c, new_order_line_items);
            const total_quantity = filtered_order_items.reduce(
              (total, order_line_item) => total + Number(order_line_item.quantity),
              0,
            );
            const quantityCondition = c.conditions.find((cc) =>
              cc.quantity_to
                ? cc.quantity_from <= total_quantity && total_quantity <= cc.quantity_to
                : cc.quantity_from <= total_quantity,
            );
            if (quantityCondition) {
              filterOrderLineItems = [
                ...filterOrderLineItems,
                ...filtered_order_items.map((item) => {
                  item.sale_price =
                    item.unit_price -
                    (quantityCondition.type === DiscountAmountType.VALUE
                      ? quantityCondition.amount
                      : (quantityCondition.rate * item.unit_price) / 100);
                  return item;
                }),
              ];
            }
          }
        });
      }
    }
    return filterOrderLineItems;
  };

  const handleApply = () => {
    if (!selectedPromotionId) {
      toast.error(t("Please select a promotion"));
      return;
    }
    if (tempSelectedDiscount?.type && tempSelectedDiscount?.type !== DiscountTypes.BY_ORDER_TOTAL) {
      if (tempDiscountDetails.reduce((total, item) => total + +item.quantity, 0) === 0) {
        toast.error(t("Please add product"));
        return;
      }
    }
    const newData = tempDiscountDetails.filter((item) => item.quantity > 0);
    onChangeDiscountDetails(newData);
    onChangeDiscountId(selectedPromotionId);
    toggle();
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(listDiscount({ limit: 50, status: DiscountStatuses.RUNNING }));
      handleChangeRadio(selectedDiscountId);
      setViewDetailsType("");
      setTempDiscountDetails(discountDetails);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!discountsLoading) {
      const newActiveDiscounts = discounts.filter((d) => {
        const conditions = d[returnDiscountInfoFieldByType(d.type)];
        if (!conditions) {
          return false;
        }

        // check discount by order total
        if (d.type === DiscountTypes.BY_ORDER_TOTAL) {
          const effective_condition = conditions.some((c) =>
            c.amount_to ? c.amount_from <= order_total && order_total <= c.amount_to : c.amount_from <= order_total,
          );
          return !!effective_condition;
        }

        // check discount by product condition
        if (d.type === DiscountTypes.BY_PRODUCT) {
          const for_all_items = conditions?.[0]?.for_all_items;
          if (typeof for_all_items === "boolean") {
            return conditions.every((c) => {
              if (for_all_items) {
                const total_quantity = order.order_line_items.reduce(
                  (total, order_line_item) => total + Number(order_line_item.quantity),
                  0,
                );
                return c.min_quantity <= total_quantity && total_quantity <= c.discount_limit;
              }
              const total_quantity = handleReturnQuantityByItemType(c, order.order_line_items);
              return c.min_quantity <= total_quantity && total_quantity <= c.discount_limit;
            });
          }
          return false;
        }

        // check discount product by order total
        if (d.type === DiscountTypes.PRODUCT_BY_ORDER_TOTAL || d.type === DiscountTypes.GIFT_BY_ORDER_TOTAL) {
          const effective_condition = conditions.some((c) => c.amount_from <= order_total);
          return !!effective_condition;
        }

        // check discount by quantity condition
        if (d.type === DiscountTypes.BY_QUANTITY) {
          const for_all_items = conditions?.[0]?.for_all_items;
          if (typeof for_all_items === "boolean") {
            return conditions.some((c) => {
              if (for_all_items) {
                const total_quantity = order.order_line_items.reduce(
                  (total, order_line_item) => total + Number(order_line_item.quantity),
                  0,
                );
                return !!c.conditions.find((cc) =>
                  cc.quantity_to
                    ? cc.quantity_from <= total_quantity && total_quantity <= cc.quantity_to
                    : cc.quantity_from <= total_quantity,
                );
              }
              const total_quantity = handleReturnQuantityByItemType(c, order.order_line_items);
              return !!c.conditions.find((cc) =>
                cc.quantity_to
                  ? cc.quantity_from <= total_quantity && total_quantity <= cc.quantity_to
                  : cc.quantity_from <= total_quantity,
              );
            });
          }
          return false;
        }

        // check discount by purchased product
        if (d.type === DiscountTypes.BY_PURCHASE_PRODUCT || d.type === DiscountTypes.GIFT_BY_PURCHASE_PRODUCT) {
          const for_all_items = conditions?.[0]?.for_all_purchase_product;
          if (typeof for_all_items === "boolean") {
            return conditions.some((c) => {
              if (for_all_items) {
                const total_quantity = order.order_line_items.reduce(
                  (total, order_line_item) => total + Number(order_line_item.quantity),
                  0,
                );
                return c.purchase_product_quantity <= total_quantity;
              }
              const total_quantity = handleReturnQuantityByItemType(c, order.order_line_items, "purchase_");
              return c.purchase_product_quantity <= total_quantity;
            });
          }
          return false;
        }

        return false;
      });
      setActiveDiscounts(newActiveDiscounts);
    }
  }, [discounts, discountsLoading]);
  return (
    <Modal isOpen={isOpen} size="xl" scrollable>
      <ModalHeader toggle={toggle} className="hstack">
        {!viewDetailsType ? (
          t("Add promotion")
        ) : (
          <div className="hstack gap-2">
            <ButtonTheme
              className="btn btn-icon rounded-circle"
              onClick={() => {
                handleChangeViewDetailsType();
              }}
            >
              <i className="bx bx-arrow-back fs-22"></i>
            </ButtonTheme>
            <span>
              {t(
                ViewConditionsCase.includes(viewDetailsType)
                  ? "List effective conditions"
                  : ChooseGiftCase.includes(viewDetailsType)
                  ? "Choose gift item"
                  : "Choose discounted item",
              )}
            </span>
          </div>
        )}
      </ModalHeader>
      {!viewDetailsType ? (
        <ModalBody>
          {discountsAction === Actions.LIST ? (
            <Loader />
          ) : activeDiscounts.length === 0 ? (
            <p className="m-5 text-center">{t("No suitable promotion program found.")}</p>
          ) : (
            <div className="d-flex flex-column">
              {activeDiscounts.map((item, index) => {
                let discountValue = 0;
                if (item?.discount_by_order_total) {
                  const condition = item?.discount_by_order_total.find((subItem) => {
                    if (subItem.amount_to) {
                      return order.sub_total >= subItem.amount_from && order.sub_total <= subItem.amount_to;
                    } else {
                      return order.sub_total >= subItem.amount_from;
                    }
                  });
                  if (condition) {
                    discountValue =
                      condition.type === DiscountAmountType.VALUE ? condition.amount : condition.rate * order.sub_total;
                  }
                }
                return (
                  <div className="form-check hstack mb-2" key={index}>
                    <input
                      className="form-check-input"
                      type="radio"
                      value={""}
                      onClick={(e) => {
                        handleChangePromotion(item);
                      }}
                      onChange={(e) => {}}
                      id={`promotion-radio-${index}`}
                      checked={selectedPromotionId === item.id}
                    />
                    <Label className="form-check-label ms-3 w-100" for={`promotion-radio-${index}`}>
                      <div
                        className={`hstack gap-3 p-2 border rounded ${
                          selectedPromotionId === item.id && "bg-soft-primary border-primary"
                        }`}
                      >
                        <div className="flex-shrink-0">
                          <ImageCustom image={item?.image?.url} name={item?.name} avatarSize={"avatar-xs"} />
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="fw-semibold text-truncate-two-lines">{t(item.name)}</h5>
                          <div className="fs-13 text-muted">
                            <p className="mb-1">
                              {t(DiscountTypeOptions.find((option) => option.id === item?.type)?.label || "---")}
                            </p>
                          </div>
                        </div>
                        <div className="px-2 flex-shrink-0">
                          <div className="text-end">
                            {item.type === DiscountTypes.BY_ORDER_TOTAL ? (
                              <span>{formatVNDCurrency(discountValue)}</span>
                            ) : (
                              <span
                                className="text-primary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleChangeViewDetailsType(item);
                                }}
                              >
                                {t(
                                  ViewConditionsCase.includes(item.type)
                                    ? t("View conditions")
                                    : ChooseGiftCase.includes(item.type)
                                    ? t("Choose gift item")
                                    : t("Choose discounted item"),
                                )}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </Label>
                  </div>
                );
              })}
            </div>
          )}
        </ModalBody>
      ) : (
        <ModalBody>
          {ViewConditionsCase.includes(viewDetailsType) ? (
            <ViewConditionBody
              discount={tempSelectedDiscount}
              order_line_items={order.order_line_items.filter((item) => !item?.custom)}
              discountDetails={tempDiscountDetails}
              selectedPromotionId={selectedPromotionId}
              onChangeTempDiscountDetails={handleChangeTempDiscountDetails}
              onChangeSelectedPromotionId={handleChangeRadio}
            />
          ) : [DiscountTypes.BY_PURCHASE_PRODUCT, DiscountTypes.GIFT_BY_PURCHASE_PRODUCT].includes(viewDetailsType) ? (
            <PurchasedConditionBody
              discount={tempSelectedDiscount}
              order_total={order_total}
              order_line_items={order.order_line_items}
              discountDetails={tempDiscountDetails}
              selectedPromotionId={selectedPromotionId}
              onChangeTempDiscountDetails={handleChangeTempDiscountDetails}
              onChangeSelectedPromotionId={handleChangeRadio}
              selectedLocation={selectedLocation}
              selectedPriceGroup={selectedPriceGroup}
            />
          ) : (
            <OrderTotalConditionBody
              discount={tempSelectedDiscount}
              order_line_items={order.order_line_items}
              order_total={order_total}
              discountDetails={tempDiscountDetails}
              selectedPromotionId={selectedPromotionId}
              onChangeTempDiscountDetails={handleChangeTempDiscountDetails}
              onChangeSelectedPromotionId={handleChangeRadio}
              selectedLocation={selectedLocation}
              selectedPriceGroup={selectedPriceGroup}
            />
          )}
        </ModalBody>
      )}
      <ModalFooter>
        {viewDetailsType ? (
          <ButtonTheme
            className="btn btn-outline-primary w-md"
            onClick={() => {
              handleChangeViewDetailsType();
            }}
          >
            {t("Back")}
          </ButtonTheme>
        ) : (
          <ButtonTheme className="btn btn-outline-primary w-md" onClick={toggle}>
            {t("Close")}
          </ButtonTheme>
        )}
        <ButtonTheme className="btn btn-primary w-md" onClick={handleApply}>
          {t("Apply")}
        </ButtonTheme>
      </ModalFooter>
    </Modal>
  );
};
export default PromotionModal;
