import { Actions } from "../../../../Components/constants/common";
import {
  LIST_PACKAGE,
  DELETE_PACKAGE,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_FAIL,
  ADD_PACKAGE_SUCCESS,
  ADD_PACKAGE_FAIL,
  UPDATE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_FAIL,
  LIST_PACKAGE_SUCCESS,
  LIST_PACKAGE_FAIL,
  ADD_NEW_PACKAGE,
  UPDATE_PACKAGE,
  GET_PACKAGE,
  GET_PACKAGE_SUCCESS,
  GET_PACKAGE_FAIL,
  RESET_PACKAGE_FLAG,
} from "./actionType";

const INIT_STATE = {
  packages: [],
  package: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const Packages = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_PACKAGE:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_PACKAGE_SUCCESS:
      return {
        ...state,
        message: "Get packages success",
        loading: false,
        success: true,
        packages: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_PACKAGE_FAIL:
      return {
        ...state,
        message: "Get packages failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_PACKAGE:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_PACKAGE_SUCCESS:
      return {
        ...state,
        message: "Get package success",
        loading: false,
        success: true,
        package: action.payload,
        action: Actions.EMPTY,
      };

    case GET_PACKAGE_FAIL:
      return {
        ...state,
        message: "Get package failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_PACKAGE:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_PACKAGE_SUCCESS:
      return {
        ...state,
        message: "Delete package success",
        loading: false,
        success: true,
        packages: state.packages.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_PACKAGE_FAIL:
      return {
        ...state,
        message: "Delete package failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_NEW_PACKAGE:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_PACKAGE_SUCCESS:
      return {
        ...state,
        message: "Add package success",
        success: true,
        loading: false,
        package: action.payload,
        packages: [...state.packages, action.payload],
        action: Actions.EMPTY,
      };

    case ADD_PACKAGE_FAIL:
      return {
        ...state,
        message: "Add package failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_PACKAGE:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.UPDATE,
      };
    case UPDATE_PACKAGE_SUCCESS:
      return {
        ...state,
        message: "Update package success",
        loading: false,
        success: true,
        packages: state.packages.map((item) =>
          item.id.toString() === action.payload.id.toString() ? { ...item, ...action.payload } : item,
        ),
        action: Actions.EMPTY,
      };

    case UPDATE_PACKAGE_FAIL:
      return {
        ...state,
        message: "Update package failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case RESET_PACKAGE_FLAG:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Packages;
