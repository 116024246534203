import React, { Fragment, useEffect, useRef, useState } from "react";
import { Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { formatDateTime, formatVNDCurrency } from "../../../../helpers/format_helper";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import {
  addNewShiftPayLineItem,
  closeShift,
  deleteShiftPayLineItem,
  getShift,
  listShiftPayLineItem,
  moreShiftPayLineItem,
} from "../../../../store/actions";
import InputTheme from "../../../../Components/Common/InputTheme";
import SimpleBar from "simplebar-react";
import { Tooltip } from "antd";
import ShiftReportTemplate from "../../../../Components/Common/ShiftReportTemplate";
import ScrollToError from "../../../../Components/Common/ScrollToError";
const Type = {
  CLOSE_SHIFT: "CLOSE_SHIFT",
  CASH_MANAGEMENT: "CASH_MANAGEMENT",
  DEFAULT: "",
};
const ShiftModal = ({ show, onCloseClick }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { shift, shiftsLoading, shiftsSuccess } = useMemoizedSelector((state) => ({
    shift: state.Shifts.shift,
    shiftsLoading: state.Shifts.loading,
    shiftsAction: state.Shifts.action,
    shiftsError: state.Shifts.error,
  }));
  const [type, setType] = useState("");

  const handleChangeType = (data) => {
    setType(data);
  };

  const handleCloseShift = (data) => {
    if (shift?.id) {
      const payload = { id: shift.id, ...data };
      dispatch(
        closeShift(payload, () => {
          window.location.reload();
        }),
      );
    }
  };

  useEffect(() => {
    if (!show) {
      setType(Type.DEFAULT);
    } else {
      dispatch(getShift(shift?.id));
    }
  }, [show]);

  return (
    <Fragment>
      <Modal fade={true} isOpen={show} toggle={onCloseClick} size="lg" scrollable>
        <ModalHeader toggle={onCloseClick}>
          {type !== Type.DEFAULT && (
            <i
              className="ri-arrow-left-line cursor-pointer align-bottom me-3"
              onClick={() => {
                setType(Type.DEFAULT);
              }}
            />
          )}
          {t("Shift report")}
        </ModalHeader>
        <ModalBody>
          {type === Type.CASH_MANAGEMENT ? (
            <CashManagementBody shift={shift} />
          ) : type === Type.CLOSE_SHIFT ? (
            <CloseShiftBody shift={shift} onCloseShift={handleCloseShift} />
          ) : (
            <DefaultBody shift={shift} onChangeType={handleChangeType} />
          )}
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

const DefaultBody = ({ shift, onChangeType }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="d-flex gap-3 flex-col mb-3">
        <ButtonTheme
          className="btn btn-outline-primary w-100"
          onClick={() => {
            onChangeType(Type.CASH_MANAGEMENT);
          }}
        >
          {t("Cash management")}
        </ButtonTheme>
        <ButtonTheme
          className="btn btn-outline-primary w-100"
          onClick={() => {
            onChangeType(Type.CLOSE_SHIFT);
          }}
        >
          {t("Close shift")}
        </ButtonTheme>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <div>
          {t("Shift opened")}: {shift?.user?.name_staff || "---"}
        </div>
        <div>{formatDateTime(shift?.created_at)}</div>
      </div>
      <div className="pt-3 border-top text-primary fw-bolder mb-3">{t("Fund")}</div>
      <div className="d-flex justify-content-between mb-3">
        <div>{t("Starting fund")}</div>
        <div>{formatVNDCurrency(shift?.starting_fund || 0)}</div>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <div>{t("Paid in")}</div>
        <div>{formatVNDCurrency(shift?.paid_in || 0)}</div>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <div>{t("Paid out")}</div>
        <div>{formatVNDCurrency(shift?.paid_out || 0)}</div>
      </div>
      <div className="d-flex justify-content-between mb-3 fw-bolder">
        <div>{t("Expected fund")}</div>
        <div>{formatVNDCurrency(shift?.expected_fund || 0)}</div>
      </div>
      <div className="pt-3 border-top text-primary fw-bolder mb-3">{t("Cash drawer")}</div>
      <div className="d-flex justify-content-between mb-3">
        <div>{t("Starting cash")}</div>
        <div>{formatVNDCurrency(shift?.starting_cash || 0)}</div>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <div>{t("Cash payments")}</div>
        <div>{formatVNDCurrency(shift?.cash_payments || 0)}</div>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <div>{t("Cash refunds")}</div>
        <div>{formatVNDCurrency(shift?.cash_refunds || 0)}</div>
      </div>
      <div className="d-flex justify-content-between mb-3 fw-bolder">
        <div>{t("Expected cash amount")}</div>
        <div>{formatVNDCurrency(shift?.expected_cash || 0)}</div>
      </div>
      <div className="pt-3 border-top text-primary fw-bolder mb-3">{t("Sales summary")}</div>
      <div className="d-flex justify-content-between mb-3 fw-bolder">
        <div>{t("Gross sales")}</div>
        <div>{formatVNDCurrency(shift?.gross_sales || 0)}</div>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <div>{t("Refunds")}</div>
        <div>{formatVNDCurrency(shift?.refunds || 0)}</div>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <div>{t("Discounts")}</div>
        <div>{formatVNDCurrency(shift?.discounts || 0)}</div>
      </div>
      <div className="pt-3 border-top d-flex justify-content-between mb-3 fw-bolder">
        <div>{t("Net sales")}</div>
        <div>{formatVNDCurrency(shift?.net_sales || 0)}</div>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <div>{t("Cash")}</div>
        <div>{formatVNDCurrency(shift?.cash || 0)}</div>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <div>{t("Banking")}</div>
        <div>{formatVNDCurrency(shift?.banking || 0)}</div>
      </div>
    </Fragment>
  );
};
const CashManagementBody = ({ shift }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formRef = useRef();
  const {
    shiftPayLineItems,
    shiftPayLineItemsLoading,
    shiftPayLineItemsSuccess,
    shiftPayLineItemsError,
    shiftPayLineItemsLimit,
    shiftPayLineItemsPage,
    shiftPayLineItemsTotal,
  } = useMemoizedSelector((state) => ({
    shiftPayLineItems: state.Shifts.shiftPayLineItems,
    shiftPayLineItemsLoading: state.Shifts.loadingPayLineItem,
    shiftPayLineItemsSuccess: state.Shifts.successPayLineItem,
    shiftPayLineItemsError: state.Shifts.errorPayLineItem,
    shiftPayLineItemsLimit: state.Shifts.limitPayLineItem,
    shiftPayLineItemsPage: state.Shifts.pagePayLineItem,
    shiftPayLineItemsTotal: state.Shifts.totalPayLineItem,
  }));
  const validation = {
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      amount: 0,
      note: "",
    },
    validationSchema: Yup.object({
      amount: Yup.number().moreThan(0, t("Amount must be greater than 0")),
      note: Yup.string().required(t("Please enter note")),
    }),
  };
  const [hasMore, setHasMore] = useState(false);

  const handlePaid = (type, data) => {
    if (shift?.id) {
      const amount = Number(data.amount) || 0;
      const newPayload = { ...data, amount: amount, type };
      dispatch(
        addNewShiftPayLineItem(newPayload, shift?.id, () => {
          return formRef.current.resetForm;
        }),
      );
    }
  };

  const onClickViewMore = () => {
    if (shift?.id) {
      dispatch(
        moreShiftPayLineItem({
          sort_created_at: "desc",
          limit: shiftPayLineItemsLimit,
          page: Number(shiftPayLineItemsPage) + 1,
          shift_id: shift?.id,
        }),
      );
    }
  };

  useEffect(() => {
    if (!shiftPayLineItemsLoading) {
      setHasMore(shiftPayLineItemsLimit * (Number(shiftPayLineItemsPage) + 1) < shiftPayLineItemsTotal);
    }
  }, [shiftPayLineItemsLoading, shiftPayLineItemsLimit, shiftPayLineItemsPage, shiftPayLineItemsTotal]);

  useEffect(() => {
    if (shift?.id) {
      dispatch(listShiftPayLineItem({ sort_created_at: "desc", shift_id: shift?.id }));
    }
  }, []);
  return (
    <Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={validation.initialValues}
        validationSchema={validation.validationSchema}
        innerRef={formRef}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <ScrollToError />
            <div className="mb-3">
              {/* <InputTheme name="amount" type="number" disabled={shiftPayLineItemsLoading} placeholder="Enter amount" /> */}
              <InputTheme
                name="amount"
                className="form-control"
                format="price"
                placeholder="Enter amount"
                // value={actualCash}
                // onChange={(data) => {
                //   setActualCash(data);
                // }}
              />
            </div>
            <div className="mb-3">
              <InputTheme name="note" disabled={shiftPayLineItemsLoading} placeholder="Enter note" />
            </div>
            <div className="d-flex gap-3 flex-col mb-3">
              <ButtonTheme
                className="btn btn-outline-primary w-100 text-uppercase"
                onClick={() => {
                  handlePaid("PAID_IN", values);
                }}
              >
                {t("Pay in")}
              </ButtonTheme>
              <ButtonTheme
                className="btn btn-outline-primary w-100 text-uppercase"
                onClick={() => {
                  handlePaid("PAID_OUT", values);
                }}
              >
                {t("Pay out")}
              </ButtonTheme>
            </div>
          </Form>
        )}
      </Formik>
      <div className="pt-3 border-top text-primary fw-bolder mb-3">
        {t("Pay in")}/{t("Pay out")}
      </div>
      <SimpleBar style={{ maxHeight: "500px" }}>
        {shiftPayLineItems.length === 0
          ? t("No result")
          : shiftPayLineItems.map((item, index) => (
              <div key={index} className="d-flex justify-content-between mb-3">
                <div className="hstack gap-3">
                  <span className="fw-bolder">{formatDateTime(item.created_at)}</span>
                  <span className="text-muted">{item?.user?.name_staff || "---"}</span>
                  <Tooltip title={item?.note}>
                    <span className="text-truncate" style={{ maxWidth: "350px" }}>
                      {item?.note || "---"}
                    </span>
                  </Tooltip>
                </div>
                <div className="hstack gap-3">
                  <span>
                    {item?.type === "PAID_OUT" ? "-" : ""}
                    {formatVNDCurrency(item?.amount || 0)}
                  </span>
                  <ButtonTheme
                    type="button"
                    className="btn btn-outline-danger btn-icon waves-effect waves-light"
                    onClick={() => {
                      dispatch(deleteShiftPayLineItem(item, shift?.id));
                    }}
                  >
                    <i className="ri-delete-bin-5-line"></i>
                  </ButtonTheme>
                </div>
              </div>
            ))}
      </SimpleBar>
      {hasMore && (
        <div className="text-center">
          <ButtonTheme
            className="btn btn-ghost-primary"
            onClick={() => {
              onClickViewMore();
            }}
            disabled={shiftPayLineItemsLoading}
          >
            {t("View more")}
          </ButtonTheme>
        </div>
      )}
    </Fragment>
  );
};
const CloseShiftBody = ({ shift, onCloseShift }) => {
  const { t } = useTranslation();
  const [isPrint, setIsPrint] = useState(false);
  const [actualCash, setActualCash] = useState(0);
  const [actualFund, setActualFund] = useState(0);
  const [note, setNote] = useState("");
  const [fundNote, setFundNote] = useState("");
  const { shiftsLoading } = useMemoizedSelector((state) => ({
    shiftsLoading: state.Shifts.loading,
  }));

  const printContent = () => {
    var pri = document.getElementById("shift-report").contentWindow;
    pri.print();
  };
  const handleCloseShift = () => {
    isPrint && printContent();
    onCloseShift({ actual_cash: actualCash, note, actual_fund: actualFund, fund_note: fundNote });
  };
  useEffect(() => {
    setActualCash(shift?.expected_cash || 0);
    setActualFund(shift?.expected_fund || 0);
  }, [shift]);
  return (
    <Fragment>
      <ShiftReportTemplate shift={shift} />
      <div className="d-flex justify-content-between mb-3">
        <div>{t("Expected cash amount")}</div>
        <div>{formatVNDCurrency(shift?.expected_cash || 0)}</div>
      </div>
      <div className="hstack justify-content-between mb-3">
        <div>{t("Actual cash amount")}</div>
        <div>
          <InputTheme
            isForm={false}
            className="form-control text-end border-0 border-bottom px-0 rounded-0"
            format="price"
            value={actualCash}
            onChange={(data) => {
              setActualCash(data);
            }}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between fw-bolder mb-3">
        <div>{t("Difference")}</div>
        <div>{formatVNDCurrency(actualCash - shift?.expected_cash || 0)}</div>
      </div>
      <div className="hstack justify-content-between mb-3">
        <div>{t("Note")}</div>
        <div>
          <InputTheme
            isForm={false}
            placeholder="Enter note"
            value={note}
            onChange={(data) => {
              setNote(data);
            }}
          />
        </div>
      </div>
      <div className="pt-3 border-top d-flex justify-content-between mb-3">
        <div>{t("Expected fund amount")}</div>
        <div>{formatVNDCurrency(shift?.expected_fund || 0)}</div>
      </div>
      <div className="hstack justify-content-between mb-3">
        <div>{t("Actual fund amount")}</div>
        <div>
          <InputTheme
            isForm={false}
            className="form-control text-end border-0 border-bottom px-0 rounded-0"
            format="price"
            value={actualFund}
            onChange={(data) => {
              setActualFund(data);
            }}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between fw-bolder mb-3">
        <div>{t("Difference fund amount")}</div>
        <div>{formatVNDCurrency(actualFund - shift?.expected_fund || 0)}</div>
      </div>
      <div className="hstack justify-content-between mb-3">
        <div>{t("Fund Note")}</div>
        <div>
          <InputTheme
            isForm={false}
            placeholder="Enter note"
            value={fundNote}
            onChange={(data) => {
              setFundNote(data);
            }}
          />
        </div>
      </div>
      <div className="pt-3 border-top d-flex justify-content-between mb-3">
        <div>{t("Print report")}</div>
        <div className="form-check form-switch form-check-primary">
          <Input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckChecked"
            checked={isPrint}
            onChange={(e) => {
              setIsPrint(!isPrint);
            }}
          />
        </div>
      </div>
      <div className="d-flex gap-3 flex-col mb-3">
        <ButtonTheme
          className="btn btn-outline-primary w-100"
          onClick={handleCloseShift}
          disabled={shiftsLoading}
          loading={shiftsLoading}
        >
          {t("Close shift")}
        </ButtonTheme>
      </div>
    </Fragment>
  );
};

export default ShiftModal;
