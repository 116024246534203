import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { Link, useNavigate } from "react-router-dom";
import BreadCrumbCustom from "../../../../Components/Common/BreadCrumbCustom";
import withRouter from "../../../../Components/Common/withRouter";
import { useDispatch } from "react-redux";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { deleteRole, listRole } from "../../../../store/actions";

function Roles({ router: { t } }) {
  const dispatch = useDispatch();

  const { roles, rolesLoading, rolesSuccess, rolesError, rolesLimit, rolesTotal, rolesPage } = useMemoizedSelector(
    (state) => ({
      roles: state.Roles.roles,
      rolesLoading: state.Roles.loading,
      rolesSuccess: state.Roles.success,
      rolesError: state.Roles.error,
      rolesLimit: state.Roles.limit,
      rolesTotal: state.Roles.total,
      rolesPage: state.Roles.page,
    }),
  );

  const headers = ["role", "staff_work", "staff_quit", "created_at", "updated_at", "note", "action"];
  const navigate = useNavigate();
  const handleDelete = (data) => {
    dispatch(deleteRole(data));
  };

  return (
    <div className="page-content">
      <BreadCrumbCustom text="Back to accounts list" navigateTo="/settings/accounts"></BreadCrumbCustom>
      <Container fluid>
        <Card>
          <CardHeader>
            <Row className="g-4 align-items-center">
              <div className="col-sm">
                <div>
                  <h5 className="card-title mb-0">{t("Role list")}</h5>
                </div>
              </div>
              <div className="col-sm-auto">
                <div className="d-flex gap-3 flex-wrap">
                  <ButtonTheme
                    type="button"
                    className="btn btn-primary"
                    id="create-btn"
                    icon="ri-add-line text-white"
                    onClick={() => {
                      navigate("create-role");
                    }}
                  >
                    {t("Add role")}
                  </ButtonTheme>
                </div>
              </div>
            </Row>
          </CardHeader>
          <CardBody className="pt-0">
            <TableContainerCustom
              filterType={"roles"}
              searchPlaceHolder="Search roles..."
              listFilter={[]}
              headers={headers}
              contents={roles}
              limitPage={Number(rolesLimit)}
              currentPage={Number(rolesPage) + 1}
              totalItems={rolesTotal}
              loading={rolesLoading}
              showPagination={true}
              scrollHeight="50vh"
              onCallData={(dataPayload) => {
                dispatch(listRole(dataPayload));
              }}
              isSettings={true}
              listHeader={[
                {
                  title: "Thông tin nhân viên",
                  key: "detail-variant",
                  children: [
                    {
                      title: "Role",
                      dataIndex: "role",
                      key: "role",
                      render: (value, row, index) => {
                        return (
                          <Link to={`/settings/accounts/role/${row.id}`} className="d-block text-truncate link-primary">
                            {value || "---"}
                          </Link>
                        );
                      },
                    },
                    {
                      title: "Currently working employees",
                      dataIndex: "staff_work",
                      key: "staff_work",
                      render: (value, row, index) => {
                        return value || "---";
                      },
                    },
                    {
                      title: "Former employees",
                      dataIndex: "staff_quit",
                      key: "staff_quit",
                      render: (value, row, index) => {
                        return value || "---";
                      },
                    },
                    {
                      title: "Created at",
                      dataIndex: "created_at",
                      key: "created_at",
                      sorter: true,
                      defaultSortOrder: "desc",
                    },
                    {
                      title: "Updated at",
                      dataIndex: "updated_at",
                      key: "updated_at",
                      sorter: true,
                    },
                    {
                      title: "Note",
                      dataIndex: "note",
                      key: "note",
                      render: (value, row, index) => {
                        return value || "---";
                      },
                    },
                    {
                      title: "Action",
                      dataIndex: "action",
                      key: "action",
                      render: (value, row, index) => {
                        return (
                          <UncontrolledDropdown>
                            <DropdownToggle href="#" className="btn btn-soft-primary btn-sm" tag="button">
                              <i className="ri-more-fill" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end" container="body">
                              <DropdownItem
                                onClick={() => {
                                  navigate(`/settings/accounts/role/${row?.id}`);
                                }}
                              >
                                <i className="ri-eye-fill align-bottom me-2 text-primary"></i> {t("View")}
                              </DropdownItem>
                              <DropdownItem onClick={() => handleDelete(row)}>
                                <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i> {t("Delete")}
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        );
                      },
                    },
                  ],
                },
              ]}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}

export default withRouter(Roles);
