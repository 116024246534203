import { Actions } from "../../../../Components/constants/common";
import {
  LIST_LOYALTY_PROGRAM,
  DELETE_LOYALTY_PROGRAM,
  DELETE_LOYALTY_PROGRAM_SUCCESS,
  DELETE_LOYALTY_PROGRAM_FAIL,
  ADD_LOYALTY_PROGRAM_SUCCESS,
  ADD_LOYALTY_PROGRAM_FAIL,
  UPDATE_LOYALTY_PROGRAM_SUCCESS,
  UPDATE_LOYALTY_PROGRAM_FAIL,
  LIST_LOYALTY_PROGRAM_SUCCESS,
  LIST_LOYALTY_PROGRAM_FAIL,
  ADD_NEW_LOYALTY_PROGRAM,
  UPDATE_LOYALTY_PROGRAM,
  GET_LOYALTY_PROGRAM,
  GET_LOYALTY_PROGRAM_SUCCESS,
  GET_LOYALTY_PROGRAM_FAIL,
  RESET_LOYALTY_PROGRAM_FLAG,
} from "./actionType";

const INIT_STATE = {
  loyaltyPrograms: [],
  loyaltyProgram: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const LoyaltyPrograms = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_LOYALTY_PROGRAM:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_LOYALTY_PROGRAM_SUCCESS:
      return {
        ...state,
        message: "Get loyalty programs success",
        loading: false,
        success: true,
        loyaltyPrograms: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_LOYALTY_PROGRAM_FAIL:
      return {
        ...state,
        message: "Get loyalty programs failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_LOYALTY_PROGRAM:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_LOYALTY_PROGRAM_SUCCESS:
      return {
        ...state,
        message: "Get loyalty program success",
        loading: false,
        success: true,
        loyaltyProgram: action.payload,
        action: Actions.EMPTY,
      };

    case GET_LOYALTY_PROGRAM_FAIL:
      return {
        ...state,
        message: "Get loyalty program failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_LOYALTY_PROGRAM:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_LOYALTY_PROGRAM_SUCCESS:
      return {
        ...state,
        message: "Delete loyalty program success",
        loading: false,
        success: true,
        loyaltyPrograms: state.loyaltyPrograms.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_LOYALTY_PROGRAM_FAIL:
      return {
        ...state,
        message: "Delete loyalty program failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_NEW_LOYALTY_PROGRAM:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_LOYALTY_PROGRAM_SUCCESS:
      return {
        ...state,
        message: "Add loyalty program success",
        success: true,
        loading: false,
        loyaltyProgram: action.payload,
        loyaltyPrograms: [...state.loyaltyPrograms, action.payload],
        action: Actions.EMPTY,
      };

    case ADD_LOYALTY_PROGRAM_FAIL:
      return {
        ...state,
        message: "Add loyalty program failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_LOYALTY_PROGRAM:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.UPDATE,
      };
    case UPDATE_LOYALTY_PROGRAM_SUCCESS:
      return {
        ...state,
        message: "Update loyalty program success",
        loading: false,
        success: true,
        loyaltyProgram: action.payload,
        loyaltyPrograms: state.loyaltyPrograms.map((loyaltyProgram) =>
          loyaltyProgram.id.toString() === action.payload.id.toString() ? { ...loyaltyProgram, ...action.payload } : loyaltyProgram,
        ),
        action: Actions.EMPTY,
      };

    case UPDATE_LOYALTY_PROGRAM_FAIL:
      return {
        ...state,
        message: "Update loyalty program failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case RESET_LOYALTY_PROGRAM_FLAG:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default LoyaltyPrograms;
