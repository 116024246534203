import { Actions } from "../../../../Components/constants/common";
import {
  LIST_CUSTOMER_GROUP,
  DELETE_CUSTOMER_GROUP,
  DELETE_CUSTOMER_GROUP_SUCCESS,
  DELETE_CUSTOMER_GROUP_FAIL,
  ADD_CUSTOMER_GROUP_SUCCESS,
  ADD_CUSTOMER_GROUP_FAIL,
  UPDATE_CUSTOMER_GROUP_SUCCESS,
  UPDATE_CUSTOMER_GROUP_FAIL,
  LIST_CUSTOMER_GROUP_SUCCESS,
  LIST_CUSTOMER_GROUP_FAIL,
  ADD_NEW_CUSTOMER_GROUP,
  UPDATE_CUSTOMER_GROUP,
  GET_CUSTOMER_GROUP,
  GET_CUSTOMER_GROUP_SUCCESS,
  GET_CUSTOMER_GROUP_FAIL,
  RESET_CUSTOMER_GROUP_FLAG,
} from "./actionType";

const INIT_STATE = {
  customerGroups: [],
  customerGroup: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const CustomerGroups = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_CUSTOMER_GROUP:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_CUSTOMER_GROUP_SUCCESS:
      return {
        ...state,
        message: "Get customer groups success",
        loading: false,
        success: true,
        customerGroups: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_CUSTOMER_GROUP_FAIL:
      return {
        ...state,
        message: "Get customer groups failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_CUSTOMER_GROUP:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_CUSTOMER_GROUP_SUCCESS:
      return {
        ...state,
        message: "Get customer group success",
        loading: false,
        success: true,
        customerGroup: action.payload,
        action: Actions.EMPTY,
      };

    case GET_CUSTOMER_GROUP_FAIL:
      return {
        ...state,
        message: "Get customer group failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_CUSTOMER_GROUP:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_CUSTOMER_GROUP_SUCCESS:
      return {
        ...state,
        message: "Delete customer group success",
        loading: false,
        success: true,
        customerGroups: state.customerGroups.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_CUSTOMER_GROUP_FAIL:
      return {
        ...state,
        message: "Delete customer group failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_NEW_CUSTOMER_GROUP:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_CUSTOMER_GROUP_SUCCESS:
      return {
        ...state,
        message: "Add customer group success",
        success: true,
        loading: false,
        customerGroup: action.payload,
        customerGroups: [...state.customerGroups, action.payload],
        action: Actions.EMPTY,
      };

    case ADD_CUSTOMER_GROUP_FAIL:
      return {
        ...state,
        message: "Add customer group failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_CUSTOMER_GROUP:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.UPDATE,
      };
    case UPDATE_CUSTOMER_GROUP_SUCCESS:
      return {
        ...state,
        message: "Update customer group success",
        loading: false,
        success: true,
        customerGroup: action.payload,
        customerGroups: state.customerGroups.map((customerGroup) =>
          customerGroup.id.toString() === action.payload.id.toString()
            ? { ...customerGroup, ...action.payload }
            : customerGroup,
        ),
        action: Actions.EMPTY,
      };

    case UPDATE_CUSTOMER_GROUP_FAIL:
      return {
        ...state,
        message: "Update customer group failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case RESET_CUSTOMER_GROUP_FLAG:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default CustomerGroups;
