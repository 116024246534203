import { useEffect, useState } from "react";
import { Input } from "reactstrap";
import ButtonTheme from "./ButtonTheme";
import { useDebounce } from "../Hooks/UseDebounce";

const InputStep = ({ onChangeQuantity, quantity, max, index, product, id }) => {
  const [value, setValue] = useState(quantity);
  useEffect(() => {
    setValue(quantity);
  }, [quantity]);

  const debouncedValue = useDebounce(value, 1000);

  useEffect(() => {
    handleChangeQuantity(debouncedValue);
  }, [debouncedValue]);

  const handleChangeQuantity = (data) => {
    let number = 0;
    if (Number.isInteger(max) && data > max) {
      number = max;
    } else {
      if (data < 0) {
        number = 0;
      } else {
        number = data;
      }
    }
    onChangeQuantity(number, index, product);
  };
  return (
    <div className="input-step step-primary">
      <ButtonTheme
        type="button"
        className="minus"
        onClick={() => onChangeQuantity(value - 1, index, product)}
        disabled={value === 0}
      >
        –
      </ButtonTheme>
      <Input
        id={id}
        type="number"
        className="product-quantity"
        value={value}
        min="0"
        max={max}
        onChange={(e) => {
          setValue(Number(e.target.value));
        }}
      />
      <ButtonTheme
        type="button"
        className="plus"
        onClick={() => onChangeQuantity(value + 1, index, product)}
        disabled={value === max}
      >
        +
      </ButtonTheme>
    </div>
  );
};
export default InputStep;
