import {
  LIST_SALE_CHANNEL,
  LIST_SALE_CHANNEL_SUCCESS,
  LIST_SALE_CHANNEL_FAIL,
  DELETE_SALE_CHANNEL,
  DELETE_SALE_CHANNEL_SUCCESS,
  DELETE_SALE_CHANNEL_FAIL,
  ADD_SALE_CHANNEL_SUCCESS,
  ADD_SALE_CHANNEL_FAIL,
  UPDATE_SALE_CHANNEL_SUCCESS,
  UPDATE_SALE_CHANNEL_FAIL,
  ADD_NEW_SALE_CHANNEL,
  UPDATE_SALE_CHANNEL,
  GET_SALE_CHANNEL,
  GET_SALE_CHANNEL_SUCCESS,
  GET_SALE_CHANNEL_FAIL,
} from "./actionType";

const INIT_STATE = {
  saleChannels: [],
  saleChannel: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
};

const SaleChannels = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_SALE_CHANNEL:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
      };

    case LIST_SALE_CHANNEL_SUCCESS:
      return {
        ...state,
        message: "List sale channel success",
        loading: false,
        success: true,
        saleChannels: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
      };

    case LIST_SALE_CHANNEL_FAIL:
      return {
        ...state,
        message: "List sale channel failed",
        loading: false,
        success: false,
        error: action.payload,
      };

    case GET_SALE_CHANNEL:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
      };

    case GET_SALE_CHANNEL_SUCCESS:
      return {
        ...state,
        message: "Get sale channel success",
        loading: false,
        success: true,
        saleChannel: action.payload ?? null,
      };

    case GET_SALE_CHANNEL_FAIL:
      return {
        ...state,
        message: "Get sale channel failed",
        loading: false,
        success: false,
        error: action.payload,
      };

    case DELETE_SALE_CHANNEL:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
      };

    case DELETE_SALE_CHANNEL_SUCCESS:
      return {
        ...state,
        message: "Delete sale channel success",
        loading: false,
        success: true,
        saleChannels: state.saleChannels.filter((item) => item.id.toString() !== action.payload.id.toString()),
      };

    case DELETE_SALE_CHANNEL_FAIL:
      return {
        ...state,
        message: "Delete sale channel failed",
        loading: false,
        success: false,
        error: action.payload,
      };

    case ADD_NEW_SALE_CHANNEL:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
      };

    case ADD_SALE_CHANNEL_SUCCESS:
      return {
        ...state,
        message: "Add sale channel success",
        success: true,
        loading: false,
        saleChannels: [...state.saleChannels, action.payload],
      };

    case ADD_SALE_CHANNEL_FAIL:
      return {
        ...state,
        message: "Add sale channel failed",
        loading: false,
        success: false,
        error: action.payload,
      };

    case UPDATE_SALE_CHANNEL:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
      };
    case UPDATE_SALE_CHANNEL_SUCCESS:
      return {
        ...state,
        message: "Update sale channel success",
        loading: false,
        success: true,
        saleChannels: state.saleChannels.map((saleChannel) =>
          saleChannel.id.toString() === action.payload.id.toString()
            ? { ...saleChannel, ...action.payload }
            : saleChannel,
        ),
      };

    case UPDATE_SALE_CHANNEL_FAIL:
      return {
        ...state,
        message: "Update sale channel failed",
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default SaleChannels;
