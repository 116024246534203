import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import TransactionModal from "./TransactionVoucherModal";

import { useEffect } from "react";
import useMemoizedSelector from "../../../../../Components/Hooks/useMemoizedSelector";
import { convertValidDate, convertValidTime, formatVNDCurrency } from "../../../../../helpers/format_helper";
import ExportCSVModal from "../../../../../Components/Common/ExportCSVModal";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import TableContainerCustom from "../../../../../Components/Common/TableContainerCustom";
import { deleteTransactionVoucher, listTransactionVoucher } from "../../../../../store/actions";
import { Actions, TransactionVoucherTypeOptions } from "../../../../../Components/constants/common";
import withRouter from "../../../../../Components/Common/withRouter";
import DeleteModal from "../../../../../Components/Common/DeleteModal";
import BreadCrumbCustom from "../../../../../Components/Common/BreadCrumbCustom";
import ActionDropdown from "../../../../../Components/Common/ActionDropdown";

const TransactionVouchersPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Transaction Voucher List")} | OptiWarehouse`;
  const transactionVoucherID = location.state?.transactionVoucherID || "";
  const dispatch = useDispatch();

  const {
    transactionVouchers,
    transactionVouchersLoading,
    transactionVouchersAction,
    transactionVouchersMessage,
    transactionVouchersSuccess,
    transactionVouchersError,
    transactionVouchersLimit,
    transactionVouchersPage,
    transactionVouchersTotal,
  } = useMemoizedSelector((state) => ({
    transactionVouchers: state.TransactionVouchers.transactionVouchers,
    transactionVouchersLoading: state.TransactionVouchers.loading,
    transactionVouchersAction: state.TransactionVouchers.action,
    transactionVouchersSuccess: state.TransactionVouchers.success,
    transactionVouchersError: state.TransactionVouchers.error,
    transactionVouchersMessage: state.TransactionVouchers.message,
    transactionVouchersLimit: state.TransactionVouchers.limit,
    transactionVouchersTotal: state.TransactionVouchers.total,
    transactionVouchersPage: state.TransactionVouchers.page,
  }));

  const [isExportCSV, setIsExportCSV] = useState(false);
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedTransactionID, setSelectedTransactionID] = useState("");

  const onClickView = (data) => {
    setSelectedTransactionID(data);
    setShowTransactionModal(true);
  };
  const onClickDelete = (data) => {
    setSelectedTransactionID(data);
    setDeleteModal(true);
  };

  const onClose = () => {
    setSelectedTransactionID("");
    setDeleteModal(false);
  };

  const handleDeleteTransactionVoucher = () => {
    dispatch(deleteTransactionVoucher({ id: selectedTransactionID }, onClose));
  };

  const toggleTransactionModal = () => {
    setShowTransactionModal(false);
    setSelectedTransactionID("");
  };

  const headers = [
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: t("Type"),
      dataIndex: "type",
      key: "type",
      sorter: true,
      render: (_, data, index) => {
        return <>{t(_)}</>;
      },
    },
    {
      title: t("Updated at"),
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: true,
      render: (_, data, index) => {
        return (
          <>
            {convertValidDate(data.updated_at)},
            <small className="text-muted"> {convertValidTime(data.updated_at)}</small>
          </>
        );
      },
    },
    {
      title: t("Created at"),
      dataIndex: "created_at",
      key: "created_at",
      defaultSortOrder: "desc",
      sorter: true,
      render: (_, data, index) => {
        return (
          <>
            {convertValidDate(data.created_at)},
            <small className="text-muted"> {convertValidTime(data.created_at)}</small>
          </>
        );
      },
    },
    {
      title: t("Action"),
      key: "action",
      render: (_, row, index) => {
        return (
          <ActionDropdown
            actions={[
              {
                type: "view",
                onClick: () => {
                  onClickView(row.id);
                },
              },
              {
                type: "delete",
                onClick: () => {
                  onClickDelete(row.id);
                },
              },
            ]}
          />
        );
      },
    },
  ];

  useEffect(() => {
    transactionVoucherID && onClickView(transactionVoucherID);
  }, [transactionVoucherID]);

  return (
    <Fragment>
      <div className="page-content">
        <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={transactionVouchers} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteTransactionVoucher}
          loading={transactionVouchersLoading && transactionVouchersAction === Actions.DELETE}
          onCloseClick={onClose}
        />
        <TransactionModal
          isOpen={showTransactionModal}
          toggle={toggleTransactionModal}
          transactionVoucherID={selectedTransactionID}
        />
        <Container fluid>
          <BreadCrumbCustom text="Back to transaction list" navigateTo="/transactions" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">{t("Transaction Voucher List")}</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex gap-2">
                        <ButtonTheme
                          type="button"
                          className="btn btn-success add-btn"
                          id="create-btn"
                          onClick={() => {
                            setShowTransactionModal(true);
                          }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i>
                          {t("Add Voucher")}
                        </ButtonTheme>
                        <ButtonTheme type="button" className="btn btn-info" onClick={() => setIsExportCSV(true)}>
                          <i className="ri-file-download-line align-bottom me-1"></i>
                          {t("Export")}
                        </ButtonTheme>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <TableContainerCustom
                    filterType={"transactionVouchers"}
                    searchPlaceHolder="Search transaction vouchers..."
                    listFilter={[
                      {
                        id: "type",
                        type: "selectBox",
                        title: "Type",
                        dataOption: TransactionVoucherTypeOptions,
                      },
                    ]}
                    headers={headers}
                    contents={transactionVouchers}
                    limitPage={Number(transactionVouchersLimit)}
                    currentPage={Number(transactionVouchersPage) + 1}
                    totalItems={transactionVouchersTotal}
                    loading={transactionVouchersLoading && transactionVouchersAction === Actions.LIST}
                    showPagination={true}
                    scrollHeight="50vh"
                    onCallData={(dataPayload) => {
                      dispatch(listTransactionVoucher(dataPayload));
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};
export default withRouter(TransactionVouchersPage);
