import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";


// Redux States
import { LIST_SHIPPING_PROVIDER, DELETE_SHIPPING_PROVIDER, ADD_NEW_SHIPPING_PROVIDER, UPDATE_SHIPPING_PROVIDER, GET_SHIPPING_PROVIDER } from "./actionType";

import {
  deleteShippingProviderSuccess,
  deleteShippingProviderFail,
  addShippingProviderSuccess,
  addShippingProviderFail,
  updateShippingProviderSuccess,
  updateShippingProviderFail,
  listShippingProviderSuccess,
  listShippingProviderFail,
  getShippingProviderSuccess,
  getShippingProviderFail,
} from "./action";

import { shippingProvidersAPI } from "../../../../helpers/resource_helper";

function* listShippingProvider({ payload: { ...res } }) {
  try {
    const response = yield call(shippingProvidersAPI.list, { ...res });
    yield put(listShippingProviderSuccess(response));
  } catch (error) {
    yield put(listShippingProviderFail(error));
  }
}

function* getShippingProvider({ payload: id }) {
  try {
    const response = yield call(shippingProvidersAPI.get, { id });
    yield put(getShippingProviderSuccess(response));
  } catch (error) {
    yield put(getShippingProviderFail(error));
  }
}

function* deleteShippingProviders({ payload: shippingProvider }) {
  try {
    const response = yield call(shippingProvidersAPI.delete, shippingProvider);
    yield put(deleteShippingProviderSuccess(shippingProvider));
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteShippingProviderFail(error));
    toast.error("Shipping Provider Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewShippingProvider({ payload: shippingProvider }) {
  try {
    const response = yield call(shippingProvidersAPI.create, shippingProvider);
    yield put(addShippingProviderSuccess(response));
    toast.success("Shipping Provider Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addShippingProviderFail(error));
    toast.error("Shipping Provider Added Failed", { autoClose: 3000 });
  }
}

function* onUpdateShippingProvider({ payload: shippingProvider }) {
  try {
    const response = yield call(shippingProvidersAPI.put, shippingProvider);
    yield put(updateShippingProviderSuccess(response));
    toast.success("Shipping Provider Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateShippingProviderFail(error));
    toast.error("Shipping Provider Updated Failed", { autoClose: 3000 });
  }
}

export function* watchListShippingProvider() {
  yield takeEvery(LIST_SHIPPING_PROVIDER, listShippingProvider);
}

export function* watchDeleteShippingProviders() {
  yield takeEvery(DELETE_SHIPPING_PROVIDER, deleteShippingProviders);
}

export function* watchGetShippingProvider() {
  yield takeEvery(GET_SHIPPING_PROVIDER, getShippingProvider);
}


export function* watchUpdateShippingProvider() {
  yield takeEvery(UPDATE_SHIPPING_PROVIDER, onUpdateShippingProvider);
}

export function* watchAddNewShippingProvider() {
  yield takeEvery(ADD_NEW_SHIPPING_PROVIDER, onAddNewShippingProvider);
}

function* shippingProvidersSaga() {
  yield all([
    fork(watchGetShippingProvider),
    fork(watchListShippingProvider),
    fork(watchUpdateShippingProvider),
    fork(watchAddNewShippingProvider),
    fork(watchDeleteShippingProviders),
  ]);
}

export default shippingProvidersSaga;
