import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Input, ListGroup, ListGroupItem, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import SimpleBar from "simplebar-react";

import terminalImg from "../../assets/images/svg/terminal.svg";
import NoBranch from "../../assets/images/pos/no-branch.png";
import Loader from "../../Components/Common/Loader";
import ButtonTheme from "../../Components/Common/ButtonTheme";
import { useDebounce } from "../../Components/Hooks/UseDebounce";
import { addShift, listLocation } from "../../store/actions";
import SearchInput from "../../Components/Common/SearchInput";
import useMemoizedSelector from "../../Components/Hooks/useMemoizedSelector";

import { formatVNDCurrency } from "../../helpers/format_helper";
import { useNavigate } from "react-router-dom";
import InputTheme from "../../Components/Common/InputTheme";

const OpenShift = ({
  selectedLocation,
  selectedTerminal,
  onSelectTerminal,
  onSelectLocation,
  isShiftClosed,
  onOpenPOS,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { terminals, terminalsLoading } = useMemoizedSelector((state) => ({
    terminals: state.Terminals.terminals,
    terminalsLoading: state.Terminals.loading,
  }));
  const { locations, locationsLoading } = useMemoizedSelector((state) => ({
    locations: state.Locations.locations,
    locationsLoading: state.Locations.loading,
  }));
  const { checkLoading, shift, shiftsLoading } = useMemoizedSelector((state) => ({
    checkLoading: state.Shifts.checkLoading,
    shift: state.Shifts.shift,
    shiftsLoading: state.Shifts.loading,
  }));

  const [locationQuery, setLocationQuery] = useState("");
  const debouncedQuery = useDebounce(locationQuery, 1000);

  const [amount, setAmount] = useState(0);
  const [fund, setFund] = useState(0);

  const onAddShift = () => {
    if (selectedLocation?.value && selectedTerminal?.id) {
      const payload = {
        location_id: selectedLocation?.value,
        starting_cash: amount,
        starting_fund: fund,
        terminal_id: selectedTerminal?.id,
      };
      dispatch(addShift(payload, onOpenPOS));
    } else {
      toast.error("Required terminal");
    }
  };
  useEffect(() => {
    dispatch(listLocation({ query: debouncedQuery }));
  }, [debouncedQuery]);

  return (
    <div className="bg-white overflow-hidden">
      <div className="d-flex" style={{ width: "100vw", height: "100vh" }}>
        <div className="d-flex flex-column border-end" style={{ width: "25%" }}>
          <div className="p-4 border-bottom flex-shrink-0">
            <div className="hstack gap-2">
              <ButtonTheme
                className="btn btn-light btn-icon flex-shrink-0"
                onClick={() => {
                  navigate("/");
                }}
              >
                <i className="ri-arrow-left-line fs-24" />
              </ButtonTheme>
              <SearchInput
                onChangeValue={(value) => {
                  setLocationQuery(value);
                }}
                searchValue={locationQuery}
                onDeleteSearchValue={() => setLocationQuery("")}
                placeholder={"Search branches..."}
              />
            </div>
            <p className="text-muted mb-0 mt-1">{t("Select your branch")}</p>
          </div>
          <SimpleBar style={{ maxHeight: "calc(100vh - 114px)" }}>
            <ListGroup className="flex-column">
              {locationsLoading ? (
                <Loader />
              ) : locations.length === 0 ? (
                <div className="p-4">{t("No available branches")}</div>
              ) : (
                locations.map((location, index) => (
                  <ListGroupItem
                    key={index}
                    className={`p-4 rounded-0 border-0 border-bottom cursor-pointer ${
                      selectedLocation?.value === location.id
                        ? "bg-soft-primary text-primary"
                        : "list-group-item-action"
                    }`}
                    onClick={() => {
                      const data = { label: location?.name || null, value: location?.id || null };
                      if (selectedLocation?.value !== location.id) {
                        onSelectLocation(data);
                        onSelectTerminal(null);
                      }
                    }}
                  >
                    <div className="fw-bold text-truncate">{location?.name || "---"}</div>
                    <p className="mt-1 mb-0 text-dark">{location?.terminal_count || 0} máy POS</p>
                  </ListGroupItem>
                ))
              )}
            </ListGroup>
          </SimpleBar>
        </div>
        {!selectedLocation ? (
          <div
            className="align-items-center border-end d-flex flex-column justify-content-center gap-5"
            style={{ width: "50%" }}
          >
            <h3 className="text-muted">{t("Select your branch")}</h3>
            <img src={NoBranch} alt="" className="" style={{ height: "421px", width: "497px" }} />
          </div>
        ) : (
          <div className="d-flex flex-column border-end" style={{ width: "50%" }}>
            <div className="pt-5 px-4">
              <Row>
                <Col lg={12}>
                  <h4 className="mb-3">{t("Select terminal")}</h4>
                </Col>
              </Row>
            </div>
            <div className="flex-1 px-4">
              <SimpleBar style={{ maxHeight: "calc(100vh - 160px)" }}>
                <Row>
                  {terminalsLoading ? (
                    <Loader />
                  ) : terminals.length === 0 ? (
                    <Col lg={12}>{t("No available terminals")}</Col>
                  ) : (
                    terminals.map((item) => (
                      <Col lg={6} key={item.id}>
                        <Card
                          className={`border border-2 ${selectedTerminal?.id === item.id ? "border-primary " : ""}`}
                        >
                          <CardBody
                            style={{ cursor: "pointer" }}
                            className={`hstack gap-1 bg-light`}
                            onClick={() => {
                              onSelectTerminal(item);
                            }}
                          >
                            <i
                              className={`ri-checkbox-blank-circle-fill ${
                                item?.is_activate ? "text-success" : "text-danger"
                              }`}
                            />
                            <img src={terminalImg} alt="" className="avatar-sm" loading="lazy" />
                            {item?.name || "---"}{" "}
                          </CardBody>
                        </Card>
                      </Col>
                    ))
                  )}
                </Row>
              </SimpleBar>
            </div>
            <div className="flex-shrink-0 border-top hstack gap-5 p-4">
              <div className="hstack gap-2">
                <i className="ri-checkbox-blank-circle-fill align-bottom text-success" />
                Active
              </div>
              <div className="hstack gap-2">
                <i className="ri-checkbox-blank-circle-fill align-bottom text-danger" />
                Inactive
              </div>
              {/* <div className="hstack gap-2">
              <i className="ri-checkbox-blank-circle-fill align-bottom text-warning" />
              Pause
            </div> */}
            </div>
          </div>
        )}
        <div className="d-flex flex-column border-end" style={{ width: "25%" }}>
          <div className="p-4 border-bottom flex-shrink-0 hstack gap-2 justify-content-center fs-22">
            {!selectedTerminal ? (
              <span className="text-muted">{t("Select terminal")}</span>
            ) : (
              <Fragment>
                <i
                  className={`ri-checkbox-blank-circle-fill align-bottom ${
                    selectedTerminal?.is_activate ? "text-success" : "text-danger"
                  }`}
                />
                {selectedTerminal?.name || "---"}
              </Fragment>
            )}
          </div>
          <div className="d-flex flex-1 flex-column flex-1">
            <div className="border-bottom p-4">
              <div>{t("Current cash")}</div>
              <div className={`fs-36 fw-bold ${!selectedTerminal ? "opacity-0" : ""}`}>
                {formatVNDCurrency(selectedTerminal?.cash || 0)}
              </div>
            </div>
            {!selectedTerminal?.is_activate && (
              <div className="p-4">
                <div>{t("Enter starting cash of shift")}</div>
                <InputTheme
                  isForm={false}
                  format="price"
                  placeholder={t("Enter starting cash of shift")}
                  className="rounded-0 border-0 border-bottom"
                  value={amount}
                  disabled={!selectedTerminal?.id}
                  onChange={(data) => {
                    setAmount(Number(data));
                  }}
                ></InputTheme>
              </div>
            )}
            {!selectedTerminal?.is_activate && (
              <div className="p-4">
                <div>{t("Enter starting fund of shift")}</div>
                <InputTheme
                  isForm={false}
                  format="price"
                  placeholder={t("Enter starting fund of shift")}
                  className="rounded-0 border-0 border-bottom"
                  value={fund}
                  disabled={!selectedTerminal?.id}
                  onChange={(data) => {
                    setAmount(setFund(data));
                  }}
                ></InputTheme>
              </div>
            )}
          </div>
          <div className="p-4">
            {!selectedTerminal?.is_activate ? (
              <ButtonTheme
                className="btn btn-primary w-100 btn-lg rounded"
                onClick={onAddShift}
                loading={shiftsLoading}
                disabled={!selectedTerminal?.id || shiftsLoading}
                loadShowText={true}
              >
                {t("Select terminal")}
              </ButtonTheme>
            ) : (
              <ButtonTheme
                className="btn btn-primary w-100 btn-lg rounded"
                onClick={onOpenPOS}
                disabled={!selectedTerminal?.id || !shift?.id}
              >
                {t("Continue")}
              </ButtonTheme>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default OpenShift;
