export const GET_SHOP_INFO = "GET_SHOP_INFO";
export const GET_SHOP_INFO_SUCCESS = "GET_SHOP_INFO_SUCCESS";
export const GET_SHOP_INFO_FAIL = "GET_SHOP_INFO_FAIL";

export const UPDATE_SHOP_INFO = "UPDATE_SHOP_INFO";
export const UPDATE_SHOP_INFO_SUCCESS = "UPDATE_SHOP_INFO_SUCCESS";
export const UPDATE_SHOP_INFO_FAIL = "UPDATE_SHOP_INFO_FAIL";

export const GET_DEFAULT_PRINT = "GET_DEFAULT_PRINT";
export const GET_DEFAULT_PRINT_SUCCESS = "GET_DEFAULT_PRINT_SUCCESS";
export const GET_DEFAULT_PRINT_FAIL = "GET_DEFAULT_PRINT_FAIL";

export const UPDATE_DEFAULT_PRINT = "UPDATE_DEFAULT_PRINT";
export const UPDATE_DEFAULT_PRINT_SUCCESS = "UPDATE_DEFAULT_PRINT_SUCCESS";
export const UPDATE_DEFAULT_PRINT_FAIL = "UPDATE_DEFAULT_PRINT_FAIL";

export const UPDATE_LOCATION_ID = "UPDATE_LOCATION_ID";

export const GET_COLOR_SHOP_INFO = "GET_COLOR_SHOP_INFO";
export const GET_COLOR_SHOP_INFO_SUCCESS = "GET_COLOR_SHOP_INFO_SUCCESS";
export const GET_COLOR_SHOP_INFO_FAIL = "GET_COLOR_SHOP_INFO_FAIL";

export const UPDATE_COLOR_SHOP_INFO = "UPDATE_COLOR_SHOP_INFO";
export const UPDATE_COLOR_SHOP_INFO_SUCCESS = "UPDATE_COLOR_SHOP_INFO_SUCCESS";
export const UPDATE_COLOR_SHOP_INFO_FAIL = "UPDATE_COLOR_SHOP_INFO_FAIL";