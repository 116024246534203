import { DatePicker, Tooltip } from "antd";
import { ErrorMessage, useField } from "formik";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import dayjs from "dayjs";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";
import TagsInputCustom from "./TagsInputCustom";
import { formatVNDCurrency } from "../../helpers/format_helper";

function InputTheme({ isForm = true, ...props }) {
  if (isForm) return <InputThemeFormField {...props} />;
  return <InputThemeField {...props} />;
}

const InputThemeFormField = ({
  name,
  type = "text",
  label,
  placeholder = "",
  disabled,
  classWrapper = "",
  format,
  min,
  max,
  subLabel = null,
  customRender = null,
  customRenderEnd = null,
  onTrigger = () => {},
  formNoMargin = false,
}) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(name);
  const showError = meta.error && meta.touched;
  const [passwordShow, setPasswordShow] = useState(false);

  const handleChange = (e) => {
    if (format === "price") {
      helpers.setValue(e.target.value.replace(/[.\s₫]/g, "").replace(/[^0-9]/g, ""));
    } else {
      if (type === "date") {
        helpers.setValue(e.length > 0 ? e[0].toISOString() : null);
      } else if (type === "checkbox") {
        helpers.setValue(e.target.checked);
      } else if (type === "number") {
        let data = e.target.value;
        if (min) {
          if (data <= min) {
            data = min;
          }
        }
        if (max) {
          if (data > max) {
            data = max;
          }
        }
        helpers.setValue(Number(data));
      } else {
        helpers.setValue(e.target.value);
      }
    }

    onTrigger(e.target.value);
  };

  const onOk = (value) => {
    helpers.setValue(new Date(value).toISOString());
  };

  const onChangeEditorJson = (newValue) => {
    helpers.setValue(newValue);
  };
  return (
    <FormGroup
      noMargin={formNoMargin}
      // check={type === "checkbox" || type === "radio" ? true : false}
      className={`position-relative ${
        type === "checkbox" ? "d-flex flex-row-reverse justify-content-end" : ""
      } ${classWrapper}`}
    >
      {label && (
        <div className="d-flex">
          <Label for={name}>{typeof label === "string" ? t(label) : label}</Label>
          {subLabel && (
            <Tooltip title={t(subLabel)}>
              <i className="ri-information-line mx-1 text-secondary"></i>
            </Tooltip>
          )}
        </div>
      )}
      <div className={`${customRenderEnd ? "input-group" : ""} d-flex ${showError ? "is-invalid" : ""}`}>
        {customRender && <>{customRender}</>}
        {type === "timeDate" ? (
          <DatePicker
            showTime={{
              format: "HH:mm",
            }}
            onOk={onOk}
            onChange={(date, dateString) => {
              if (date) helpers.setValue(new Date(date).toISOString());
              else helpers.setValue(null);
            }}
            value={field.value ? dayjs(field.value) : ""}
            size="large"
            status={`${showError ? "error" : ""}`}
            placeholder={t(placeholder)}
            format={"DD-MM-YYYY HH:mm"}
            disabled={disabled}
            className={`${showError ? "is-invalid" : ""}`}
          />
        ) : type === "editJson" ? (
          <AceEditor
            placeholder={t("Placeholder Text")}
            mode="javascript"
            theme="github"
            onChange={onChangeEditorJson}
            fontSize={14}
            name={name}
            editorProps={{ $blockScrolling: true }}
            showPrintMargin={false}
            showGutter={true}
            highlightActiveLine={true}
            value={typeof field.value === "string" ? field.value : JSON.stringify(field.value, null, 2)}
            enableBasicAutocompletion={false}
            setOptions={{
              // enableLiveAutocompletion: false,
              // enableSnippets: true,
              showLineNumbers: true,
              tabSize: 2,
            }}
            style={{ width: "100%" }}
          />
        ) : type === "date" ? (
          <DatePicker
            format={"DD-MM-YYYY"}
            value={field.value ? dayjs(field.value) : ""}
            onChange={(date, dateString) => {
              if (date) helpers.setValue(new Date(date).toISOString());
              else helpers.setValue(null);
            }}
          />
        ) : type === "tags" ? (
          <>
            <TagsInputCustom
              classNames="w-100"
              value={field?.value ? (typeof field?.value === "string" ? field?.value.split(",") : field?.value) : []}
              onChangeTagInputs={(data) => {
                const newTags = data.map((item) => item.trim());
                helpers.setValue(newTags.join());
              }}
              name="tags"
              placeHolder={t(placeholder)}
            />
          </>
        ) : type === "checkbox" ? (
          <div className="form-check form-check-primary">
            <Input
              id={name}
              {...field}
              type={type}
              disabled={disabled}
              invalid={showError}
              onChange={(e) => {
                handleChange(e);
              }}
              autoComplete="off"
              checked={field.value}
            />
          </div>
        ) : (
          <Input
            id={name}
            {...field}
            type={passwordShow ? "text" : type}
            disabled={disabled}
            min={min}
            max={max}
            value={format === "price" ? formatVNDCurrency(field?.value ?? 0).toString() : field?.value ?? ""}
            placeholder={t(placeholder)}
            invalid={showError}
            onChange={(e) => {
              handleChange(e);
            }}
            autoComplete="off"
            checked={field.value}
          />
        )}
        {customRenderEnd && <>{customRenderEnd}</>}

        {showError && (
          <ErrorMessage
            name={name}
            component={FormFeedback}
            className="position-absolute fs-10 m-0"
            style={{ bottom: "-15px" }}
          />
        )}
        {type === "password" && (
          <button
            className={`btn btn-link position-absolute end-0 text-decoration-none text-muted ${showError && "me-4"}`}
            type="button"
            tabIndex="-1"
            id={`password-addon-${name}`}
            onClick={() => setPasswordShow(!passwordShow)}
          >
            <i className={`${passwordShow ? "ri-eye-off-fill" : "ri-eye-fill"} align-middle`}></i>
          </button>
        )}
      </div>
    </FormGroup>
  );
};

const InputThemeField = ({
  type = "text",
  label,
  subLabel,
  min,
  max,
  placeholder = "",
  disabled,
  classWrapper = "",
  className = "",
  format,
  value,
  onChange = () => {},
  onBlur = () => {},
  id = null,
  customRenderEnd = null,
  formNoMargin = false,
}) => {
  const { t } = useTranslation();
  const handleFormatValue = (format, value) => {
    switch (format) {
      case "price":
        return formatVNDCurrency(value || 0).toString();
      case "percent":
        return `${value || 0}%`;
      default:
        return value;
    }
  };
  const handleReFormatValue = (format, value) => {
    switch (format) {
      case "price":
        return value.replace(/[.\s₫]/g, "").replace(/[^0-9]/g, "");
      case "percent":
        return value.replace(/[.\s%]/g, "").replace(/[^0-9]/g, "");
      default:
        return value;
    }
  };
  const formattedValue = handleFormatValue(format, value);
  return (
    <FormGroup
      noMargin={formNoMargin}
      // check={type === "checkbox" || type === "radio" ? true : false}
      className={`position-relative ${
        type === "checkbox" ? "d-flex flex-row-reverse justify-content-end" : ""
      } ${classWrapper}`}
    >
      {label && (
        <div className="d-flex">
          <Label for={id}>{typeof label === "string" ? t(label) : label}</Label>
          {subLabel && (
            <Tooltip title={t(subLabel)}>
              <i className="ri-information-line mx-1 text-secondary"></i>
            </Tooltip>
          )}
        </div>
      )}
      <div className={`${customRenderEnd ? "input-group" : ""} d-flex`}>
        {type === "tags" ? (
          <TagsInputCustom
            id={id}
            className={className}
            value={
              formattedValue ? (typeof formattedValue === "string" ? formattedValue.split(",") : formattedValue) : []
            }
            onChangeTagInputs={(data) => {
              const newTags = data.map((item) => item.trim());
              onChange(newTags.join());
            }}
            name="tags"
            placeHolder={t(placeholder)}
            disabled={disabled}
          />
        ) : (
          <Input
            id={id}
            type={type}
            disabled={disabled}
            className={className}
            value={formattedValue}
            placeholder={t(placeholder)}
            onChange={(e) => {
              let data = handleReFormatValue(format, e.target.value);
              if (format === "price") {
                data = e.target.value.replace(/[.\s₫]/g, "").replace(/[^0-9]/g, "");
                data = Number(data);
              }
              if (type === "number" || ["price", "percent"].includes(format)) {
                data = Number(data);
                if (min) {
                  if (data < min) {
                    data = min;
                  }
                }
                if (max) {
                  if (data > max) {
                    data = max;
                  }
                }
              }
              onChange(data);
            }}
            onBlur={onBlur}
            autoComplete="off"
          />
        )}
        {customRenderEnd && <>{customRenderEnd}</>}
      </div>
    </FormGroup>
  );
};
export default InputTheme;
