import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import {
  loyaltyAppSetting,
  updateInfoSetting,
} from "../../../helpers/service_helper";
import {
  getLoyaltyAppError,
  getLoyaltyAppSuccess,
} from "./action";
import {
  GET_LOYALTY_APP,
  UPDATE_LOYALTY_APP,
} from "./actionType";
function* getLoyaltyApp() {
  try {
    const response = yield call(loyaltyAppSetting);
    yield put(getLoyaltyAppSuccess(response));
  } catch (error) {
    yield put(getLoyaltyAppError(error));
  }
}

function* updateLoyaltyApp({ payload: { data, handleAfterSubmit } }) {
  try {
    const response = yield call(updateInfoSetting, "loyalty_app", data);
    yield put(getLoyaltyAppSuccess(response));
    yield call(handleAfterSubmit);
  } catch (error) {
    yield put(getLoyaltyAppError(error));
  }
}

export function* watchGetLoyaltyApp() {
  yield takeEvery(GET_LOYALTY_APP, getLoyaltyApp);
}

export function* watchUpdateLoyaltyApp() {
  yield takeEvery(UPDATE_LOYALTY_APP, updateLoyaltyApp);
}

function* loyaltyAppSaga() {
  yield all([
    fork(watchGetLoyaltyApp),
    fork(watchUpdateLoyaltyApp),
  ]);
}

export default loyaltyAppSaga;
