import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";


// Redux States
import {
  LIST_PRICE_GROUP,
  DELETE_PRICE_GROUP,
  ADD_NEW_PRICE_GROUP,
  UPDATE_PRICE_GROUP,
  GET_PRICE_GROUP,
} from "./actionType";

import {
  deletePriceGroupSuccess,
  deletePriceGroupFail,
  addPriceGroupSuccess,
  addPriceGroupFail,
  updatePriceGroupSuccess,
  updatePriceGroupFail,
  listPriceGroupSuccess,
  listPriceGroupFail,
  getPriceGroupSuccess,
  getPriceGroupFail,
} from "./action";

import { priceGroupsAPI } from "../../../../helpers/resource_helper";

function* listPriceGroup({ payload: { ...res } }) {
  try {
    const response = yield call(priceGroupsAPI.list, { ...res });
    yield put(listPriceGroupSuccess(response));
  } catch (error) {
    yield put(listPriceGroupFail(error));
  }
}

function* getPriceGroup({ payload: id }) {
  try {
    const response = yield call(priceGroupsAPI.get, { id });
    yield put(getPriceGroupSuccess(response));
  } catch (error) {
    yield put(getPriceGroupFail(error));
  }
}

function* deletePriceGroups({ payload: priceGroup }) {
  try {
    const response = yield call(priceGroupsAPI.delete, priceGroup);
    yield put(deletePriceGroupSuccess(priceGroup));
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deletePriceGroupFail(error));
    toast.error("PriceGroup Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewPriceGroup({ payload: priceGroup }) {
  try {
    const response = yield call(priceGroupsAPI.create, priceGroup);
    yield put(addPriceGroupSuccess(response));
    toast.success("PriceGroup Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addPriceGroupFail(error));
    toast.error("PriceGroup Added Failed", { autoClose: 3000 });
  }
}

function* onUpdatePriceGroup({ payload: priceGroup }) {
  try {
    const response = yield call(priceGroupsAPI.put, priceGroup);
    yield put(updatePriceGroupSuccess(response));
    toast.success("PriceGroup Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updatePriceGroupFail(error));
    toast.error("PriceGroup Updated Failed", { autoClose: 3000 });
  }
}

export function* watchGetPriceGroups() {
  yield takeEvery(LIST_PRICE_GROUP, listPriceGroup);
}

export function* watchDeletePriceGroups() {
  yield takeEvery(DELETE_PRICE_GROUP, deletePriceGroups);
}

export function* watchGetPriceGroup() {
  yield takeEvery(GET_PRICE_GROUP, getPriceGroup);
}

export function* watchUpdatePriceGroup() {
  yield takeEvery(UPDATE_PRICE_GROUP, onUpdatePriceGroup);
}

export function* watchAddNewPriceGroup() {
  yield takeEvery(ADD_NEW_PRICE_GROUP, onAddNewPriceGroup);
}

function* priceGroupsSaga() {
  yield all([
    fork(watchGetPriceGroup),
    fork(watchGetPriceGroups),
    fork(watchUpdatePriceGroup),
    fork(watchAddNewPriceGroup),
    fork(watchDeletePriceGroups),
  ]);
}

export default priceGroupsSaga;
