// Actions
export const LIST_UNIT = "LIST_UNIT";
export const LIST_UNIT_SUCCESS = "LIST_UNIT_SUCCESS";
export const LIST_UNIT_FAIL = "LIST_UNIT_FAIL";

export const GET_UNIT = "GET_UNIT";
export const GET_UNIT_SUCCESS = "GET_UNIT_SUCCESS";
export const GET_UNIT_FAIL = "GET_UNIT_FAIL";

/**
 * Delete UNIT
 */
export const DELETE_UNIT = "DELETE_UNIT";
export const DELETE_UNIT_SUCCESS = "DELETE_UNIT_SUCCESS";
export const DELETE_UNIT_FAIL = "DELETE_UNIT_FAIL";

/**
 * Add UNIT
 */
export const ADD_NEW_UNIT = "ADD_NEW_UNIT";
export const ADD_UNIT_SUCCESS = "ADD_UNIT_SUCCESS";
export const ADD_UNIT_FAIL = "ADD_UNIT_FAIL";

/**
 * Edit UNIT
 */
export const UPDATE_UNIT = "UPDATE_UNIT";
export const UPDATE_UNIT_SUCCESS = "UPDATE_UNIT_SUCCESS";
export const UPDATE_UNIT_FAIL = "UPDATE_UNIT_FAIL";
