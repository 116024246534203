import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";


// Redux States
import { LIST_PACKAGE, DELETE_PACKAGE, ADD_NEW_PACKAGE, UPDATE_PACKAGE, GET_PACKAGE } from "./actionType";

import {
  deletePackageSuccess,
  deletePackageFail,
  addPackageSuccess,
  addPackageFail,
  updatePackageSuccess,
  updatePackageFail,
  listPackageSuccess,
  listPackageFail,
  getPackageSuccess,
  getPackageFail,
} from "./action";

import { packagesAPI } from "../../../../helpers/resource_helper";

function* listPackage({ payload: { limit, ...res } }) {
  try {
    const response = yield call(packagesAPI.list, { limit, ...res });
    yield put(listPackageSuccess(response));
  } catch (error) {
    yield put(listPackageFail(error));
  }
}

function* getPackage({ payload: id }) {
  try {
    const response = yield call(packagesAPI.get, { id });
    yield put(getPackageSuccess(response));
  } catch (error) {
    yield put(getPackageFail(error));
  }
}

function* deletePackages({ payload: data }) {
  try {
    const response = yield call(packagesAPI.delete, data);
    yield put(deletePackageSuccess(data));
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deletePackageFail(error));
    toast.error("Package Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewPackage({ payload: data }) {
  try {
    const response = yield call(packagesAPI.create, data);
    yield put(addPackageSuccess(response));
    toast.success("Package Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addPackageFail(error));
    toast.error("Package Added Failed", { autoClose: 3000 });
  }
}

function* onUpdatePackage({ payload: data }) {
  try {
    const response = yield call(packagesAPI.put, data);
    yield put(updatePackageSuccess(response));
    toast.success("Package Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updatePackageFail(error));
    toast.error("Package Updated Failed", { autoClose: 3000 });
  }
}

export function* watchGetPackages() {
  yield takeEvery(LIST_PACKAGE, listPackage);
}

export function* watchDeletePackages() {
  yield takeEvery(DELETE_PACKAGE, deletePackages);
}

export function* watchGetPackage() {
  yield takeEvery(GET_PACKAGE, getPackage);
}

export function* watchUpdatePackage() {
  yield takeEvery(UPDATE_PACKAGE, onUpdatePackage);
}

export function* watchAddNewPackage() {
  yield takeEvery(ADD_NEW_PACKAGE, onAddNewPackage);
}

function* packagesSaga() {
  yield all([
    fork(watchGetPackage),
    fork(watchGetPackages),
    fork(watchUpdatePackage),
    fork(watchAddNewPackage),
    fork(watchDeletePackages),
  ]);
}

export default packagesSaga;
