import React, { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import { Tooltip } from "antd";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

import { packagesAPI } from "../../../../../helpers/resource_helper";
import { formatVNDCurrency } from "../../../../../helpers/format_helper";

import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import ModalContainer from "../../../../../Components/Common/ModalContainer";
import CustomerDetails from "../../../../../Components/Common/CustomerDetails";
import BreadCrumbCustom from "../../../../../Components/Common/BreadCrumbCustom";
import SimpleTableContainer from "../../../../../Components/Common/SimpleTableContainer";
import StepProduct from "./StepProduct";
import withRouter from "../../../../../Components/Common/withRouter";
import LottieIcon from "../../../../../Components/Common/LottieIcon";

const PackageDetail = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Package Details")} | OptiWarehouse`;
  const { id } = useParams();
  const [dataDetailPackage, setDataDetailPackage] = useState({});
  const [isOpenWarring, setIsOpenWarring] = useState(false);
  const [loadingAction, setLoadingAction] = useState("");
  const [checkStatus, setCheckStatus] = useState();
  const getDetailPackage = async () => {
    const res = await packagesAPI.get({ id });
    setDataDetailPackage(res);
    setCheckStatus(res.status);
  };
  useEffect(() => {
    getDetailPackage();
  }, [id]);

  const columns = useMemo(
    () => [
      {
        Header: t("Product Details"),
        Cell: (cell) => {
          const product = cell.row.original;
          const index = cell.row.index;
          return (
            <div className="hstack">
              <div className="flex-shrink-0 avatar-md bg-light rounded p-1">
                <img src={product.image_url} alt="" className="img-fluid d-block" />
              </div>
              <div className="flex-grow-1 ms-3 d-flex flex-column justify-content-center">
                <h5 className="fs-15">
                  <Tooltip title={t(product.name)}>
                    <Link
                      id={`order-items-product-name-${index}`}
                      to={`/products/${product.product_id}/edit`}
                      className="d-inline-block text-truncate"
                      style={{ maxWidth: "400px" }}
                      target="_blank"
                    >
                      {t(product.name)}
                    </Link>
                  </Tooltip>
                </h5>
                <p className="text-muted fw-medium mb-1">
                  {t("Sku")}: {product.sku}
                </p>
                <p className="text-muted fw-medium mb-1 d-inline-block text-truncate" style={{ maxWidth: "400px" }}>
                  {t("Location Id")}: {product.location_id}
                </p>
                <p className="text-muted fw-medium mb-0">
                  {t("Note")}: {product?.note || "---"}
                </p>
              </div>
            </div>
          );
        },
      },
      {
        id: "quantity",
        Header: () => <div className="text-center">{t("Quantity")}</div>,
        Cell: (cell) => {
          let product = cell.row.original;
          return <div className="text-center">{product?.quantity ?? 0}</div>;
        },
      },
      {
        id: "price",
        Header: () => <div className="text-end">{t("Price")}</div>,
        Cell: (cell) => {
          let product = cell.row.original;
          return <div className="text-end">{formatVNDCurrency(product?.unit_price)}</div>;
        },
      },
      {
        id: "product_discount",
        Header: () => <div className="text-center">{t("Discount")}</div>,
        Cell: (cell) => {
          let product = cell.row.original;
          let index = cell.row.index;
          return (
            <div className="fw-medium">
              <div className="text-end">{formatVNDCurrency(product.unit_price - product.sale_price)}</div>
            </div>
          );
        },
      },
      {
        id: "total",
        Header: () => <div className="text-end">{t("Total Amount")}</div>,
        Cell: (cell) => {
          let product = cell.row.original;
          return <div className="fw-medium text-end">{formatVNDCurrency(product.quantity * product.sale_price)}</div>;
        },
      },
    ],
    [],
  );

  let amountTotal = (dataDetailPackage?.order_line_items || []).reduce(
    (total, item) => total + item.quantity * item.sale_price,
    0,
  );

  const handlePackageAction = async (action) => {
    try {
      setLoadingAction(action);
      await packagesAPI.createCustomPath(`${dataDetailPackage.id}/${action}`, {});
      getDetailPackage();
    } catch (error) {
      toast.error(error.toString());
    } finally {
      setLoadingAction("");
    }
  };

  return (
    <div className="page-content">
      <ModalContainer
        isOpen={isOpenWarring}
        toggle={() => {
          setIsOpenWarring(false);
        }}
        actionCustom={
          <div>
            <ButtonTheme
              type="button"
              className="btn btn-success w-md mx-2"
              onClick={() => {
                handlePackageAction("cancel");
              }}
              loading={loadingAction === "cancel"}
            >
              {t("Continue")}
            </ButtonTheme>
            <ButtonTheme
              type="button"
              className="btn btn-danger w-md"
              onClick={() => {
                setIsOpenWarring(false);
              }}
            >
              {t("Close")}
            </ButtonTheme>
          </div>
        }
      >
        <div className="d-flex flex-column align-items-center">
          <LottieIcon src="https://cdn.lordicon.com/tdrtiskw.json" style={{ width: "130px", height: "130px" }} />
          <div className="pt-4 text-center">
            <h4>{t("Uh oh, something went wrong!")}</h4>
            <p className="text-muted text-center">
              {t("The transfer was not successfully received by us. the email of the recipient wasn't correct.")}
            </p>
          </div>
        </div>
      </ModalContainer>

      <Container fluid>
        <BreadCrumbCustom text="Back to package list" navigateTo="/packages">
          <div className="hstack gap-2">
            {checkStatus === "PENDING" && (
              <>
                <ButtonTheme
                  type="button"
                  className="btn btn-outline-success w-md"
                  onClick={() => {
                    handlePackageAction("await_packing");
                  }}
                  loading={loadingAction === "await_packing"}
                  loadShowText={true}
                >
                  {t("Confirm")}
                </ButtonTheme>
                <ButtonTheme
                  type="button"
                  className="btn btn-outline-danger w-md"
                  onClick={() => {
                    setIsOpenWarring(true);
                  }}
                  loading={loadingAction === "cancel"}
                >
                  {t("Cancel")}
                </ButtonTheme>
              </>
            )}
            {checkStatus === "AWAIT_PACKING" && (
              <>
                <ButtonTheme
                  type="button"
                  className="btn btn-outline-success w-md"
                  onClick={() => {
                    handlePackageAction("pack");
                  }}
                  loading={loadingAction === "pack"}
                  loadShowText={true}
                >
                  {t("Packing")}
                </ButtonTheme>
                <ButtonTheme
                  type="button"
                  className="btn btn-outline-danger w-md"
                  onClick={() => {
                    setIsOpenWarring(true);
                  }}
                  loading={loadingAction === "cancel"}
                >
                  {t("Cancel")}
                </ButtonTheme>
              </>
            )}
            {checkStatus === "PACKING" && (
              <ButtonTheme
                type="button"
                className="btn btn-outline-success w-md"
                onClick={() => {
                  handlePackageAction("ready");
                }}
                loading={loadingAction === "ready"}
                loadShowText={true}
              >
                {t("Ready")}
              </ButtonTheme>
            )}
            {checkStatus === "READY" && (
              <ButtonTheme
                type="button"
                className="btn btn-outline-info w-md"
                onClick={() => {
                  handlePackageAction("ship");
                }}
                loading={loadingAction === "ship"}
                loadShowText={true}
              >
                {t("Ship")}
              </ButtonTheme>
            )}
            {checkStatus === "SHIPPING" && (
              <>
                <ButtonTheme
                  type="button"
                  className="btn btn-outline-secondary w-md"
                  onClick={() => {
                    handlePackageAction("delivered");
                  }}
                  loading={loadingAction === "delivered"}
                  loadShowText={true}
                >
                  {t("Delivered")}
                </ButtonTheme>
                <ButtonTheme
                  type="button"
                  className="btn btn-outline-danger w-md"
                  onClick={() => {
                    handlePackageAction("return");
                  }}
                  loading={loadingAction === "return"}
                  loadShowText={true}
                >
                  {t("Return")}
                </ButtonTheme>
              </>
            )}
            {checkStatus === "RETURNING" && (
              <>
                <ButtonTheme
                  type="button"
                  className="btn btn-outline-primary w-md"
                  onClick={() => {
                    handlePackageAction("delivered_return");
                  }}
                  loading={loadingAction === "delivered_return"}
                  loadShowText={true}
                >
                  {t("Delivered return")}
                </ButtonTheme>
              </>
            )}
          </div>
        </BreadCrumbCustom>

        <Row>
          <Col className="col-9">
            <CustomerDetails
              isEdit={false}
              selectedCustomer={{}}
              shipping_address={dataDetailPackage.shipping_address}
              billing_address={dataDetailPackage.billing_address}
            />
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center">
                  <h5 className="card-title flex-grow-1 mb-0">
                    {t("Order")}{" "}
                    <Link to={`/orders/${dataDetailPackage?.order_id}`}>#{dataDetailPackage?.order_number}</Link>
                  </h5>
                  <div className="flex-shrink-0">
                    <Link to="/apps-invoices-details" className="btn btn-success btn-sm">
                      <i className="ri-download-2-fill align-middle me-1"></i> {t("Invoice")}
                    </Link>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className="my-3 w-100">
                  <CardBody>
                    <SimpleTableContainer
                      columns={columns}
                      data={dataDetailPackage.order_line_items || []}
                      divClass="table-card table-responsive"
                      tableClass="table table-nowrap align-middle table-borderless mb-0"
                      theadClass="table-light text-muted"
                    />
                    <Row className="border-top border-top-dashed mt-3">
                      <Col lg={6}>
                        <table className="table table-borderless mb-0">
                          <tbody>
                            <tr>
                              <td>{t("Note")}</td>
                              <td>{dataDetailPackage.note || "---"}</td>
                            </tr>
                            <tr>
                              <td>{t("Tags")}</td>
                              <td>{dataDetailPackage.tags || "---"}</td>{" "}
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                      <Col lg={6}>
                        <table className="table table-borderless mb-0">
                          <tbody>
                            <tr>
                              <td>{t("Quantity")}</td>
                              <td className="text-end">
                                {(dataDetailPackage.order_line_items || []).reduce(
                                  (total, item) => total + Number(item.quantity),
                                  0,
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>{t("Total")}</td>
                              <td className="text-end">{formatVNDCurrency(amountTotal)}</td>
                            </tr>
                            <tr>
                              <td>
                                {t("Shipping Fee")}
                                <i className="ms-2 ri-information-line text-primary align-bottom"></i>
                              </td>
                              <td className="text-end">{formatVNDCurrency(dataDetailPackage?.shipping_fee ?? 0)}</td>
                            </tr>

                            <tr className="border-top border-top-dashed">
                              <th scope="row">{t("Amount still due")}</th>
                              <th className="text-end">
                                {formatVNDCurrency(amountTotal - dataDetailPackage?.shipping_fee)}
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </CardBody>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="col-3">
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">
                  <i className="ri-secure-payment-line align-bottom me-1 text-muted"></i> {t("Package Status")}
                </h5>
              </CardHeader>
              <CardBody>
                <StepProduct
                  defaultStep={[
                    { type: "PACKING", title: "Packing" },
                    { type: "READY", title: "Ready" },
                    { type: "SHIPPING", title: "Shipping" },
                    { type: "DELIVERED", title: "Delivered" },
                    { type: "Return", title: "Return" },
                  ]}
                  histories={dataDetailPackage.histories}
                />
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <div className="d-flex">
                  <h5 className="card-title flex-grow-1 mb-0">
                    <i className="mdi mdi-truck-fast-outline align-middle me-1 text-muted"></i>
                    {t("Logistics Details")}
                  </h5>
                  <div className="flex-shrink-0">
                    <Link to="#" className="badge badge-soft-primary fs-11">
                      {t("Track Order")}
                    </Link>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className="text-center">
                  <LottieIcon src="https://cdn.lordicon.com/uetqnvvg.json" style={{ width: "80px", height: "80px" }} />
                  <h5 className="fs-16 mt-2">{dataDetailPackage?.package_number || "---"}</h5>
                  <p className="text-muted mb-0">
                    {t("Shipping provider")}: {dataDetailPackage?.shipping_provider || "---"}
                  </p>
                  <p className="text-muted mb-0">
                    {t("Tracking number")}: {dataDetailPackage?.tracking_number || "---"}
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(PackageDetail);
