import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import ButtonTheme from "./ButtonTheme";

const AdminHomepage = () => {
  const { t } = useTranslation();
  return (
    <div className="ms-1 header-item d-sm-flex">
      <Tooltip title={t("Home page")}>
        <ButtonTheme
          type="button"
          onClick={() => {
            window.open("/dashboard", '_blank');
          }}
          className="btn btn-icon rounded-circle light-dark-mode"
        >
          <i className="ri-home-line fs-22"></i>
        </ButtonTheme>
      </Tooltip>
    </div>
  );
};
export default AdminHomepage;
