import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";


// Redux States
import { LIST_SUPPLIER, DELETE_SUPPLIER, ADD_NEW_SUPPLIER, UPDATE_SUPPLIER, GET_SUPPLIER } from "./actionType";

import {
  deleteSupplierSuccess,
  deleteSupplierFail,
  addSupplierSuccess,
  addSupplierFail,
  updateSupplierSuccess,
  updateSupplierFail,
  listSupplerSuccess,
  listSupplerFail,
  getSupplierSuccess,
  getSupplierFail,
} from "./action";

import { suppliersAPI } from "../../../../helpers/resource_helper";

function* listSupplier({ payload: { ...res } }) {
  try {
    const response = yield call(suppliersAPI.list, { ...res });
    yield put(listSupplerSuccess(response));
  } catch (error) {
    yield put(listSupplerFail(error));
  }
}

function* getSupplier({ payload: id }) {
  try {
    const response = yield call(suppliersAPI.get, { id });
    yield put(getSupplierSuccess(response));
  } catch (error) {
    yield put(getSupplierFail(error));
  }
}

function* deleteSupplier({ payload: supplier }) {
  try {
    const response = yield call(suppliersAPI.delete, supplier);
    yield put(deleteSupplierSuccess(supplier));
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteSupplierFail(error));
    toast.error("Supplier Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewSupplier({ payload: { supplier, handleAfterSubmit } }) {
  try {
    const response = yield call(suppliersAPI.create, supplier);
    yield put(addSupplierSuccess(response));
    yield call(handleAfterSubmit, response);
    toast.success(response?.message, { autoClose: 3000 });
    toast.success("Supplier Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addSupplierFail(error));
    toast.error("Supplier Added Failed", { autoClose: 3000 });
  }
}

function* onUpdateSupplier({ payload: { supplier, handleAfterSubmit } }) {
  try {
    const response = yield call(suppliersAPI.put, supplier);
    yield call(handleAfterSubmit, response);
    yield put(updateSupplierSuccess(response));
    toast.success("Supplier Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateSupplierFail(error));
    toast.error("Supplier Updated Failed", { autoClose: 3000 });
  }
}

export function* watchGetSuppliers() {
  yield takeEvery(LIST_SUPPLIER, listSupplier);
}

export function* watchDeleteSupplier() {
  yield takeEvery(DELETE_SUPPLIER, deleteSupplier);
}

export function* watchGetSupplier() {
  yield takeEvery(GET_SUPPLIER, getSupplier);
}

export function* watchUpdateSupplier() {
  yield takeEvery(UPDATE_SUPPLIER, onUpdateSupplier);
}

export function* watchAddNewSupplier() {
  yield takeEvery(ADD_NEW_SUPPLIER, onAddNewSupplier);
}

function* suppliersSaga() {
  yield all([
    fork(watchGetSupplier),
    fork(watchGetSuppliers),
    fork(watchUpdateSupplier),
    fork(watchAddNewSupplier),
    fork(watchDeleteSupplier),
  ]);
}

export default suppliersSaga;
