import { Fragment, useEffect, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label } from "reactstrap";
import { DiscountAmountType } from "../../../../../../Components/constants/common";
import { formatVNDCurrency } from "../../../../../../helpers/format_helper";
import { isEmpty } from "lodash";

const DiscountRateValue = ({
  data,
  index,
  subIndex = null,
  onChangeConditionLine = () => {},
  onChangeQuantityLine = () => {},
  disabled = false,
}) => {
  const [type, setType] = useState(DiscountAmountType.VALUE);
  const [amount, setAmount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    if (isOpen) {
      const discountLine = {
        type,
        amount: type === DiscountAmountType.VALUE ? amount : null,
        rate: type === DiscountAmountType.PERCENT ? amount : null,
      };
      subIndex || subIndex === 0
        ? onChangeQuantityLine(index, subIndex, discountLine)
        : onChangeConditionLine(index, discountLine);
    }
    setIsOpen(!isOpen);
  };
  const onChangeDiscountType = (data) => {
    setAmount(0);
    setType(data);
  };
  useEffect(() => {
    if (isOpen) {
      setType(data?.type || DiscountAmountType.VALUE);
      setAmount(data?.amount ? data?.amount : data?.rate ? data?.rate : 0);
    }
  }, [isOpen]);

  return (
    <Fragment>
      {disabled ? (
        <div className="text-end">
          {(data?.amount && formatVNDCurrency(data?.amount)) || (data?.rate && data?.rate + "%") || 0}
        </div>
      ) : (
        <Dropdown isOpen={isOpen} toggle={toggle}>
          <DropdownToggle tag="div" className={`form-control text-end ${disabled && "bg-light"}`} disabled={disabled}>
            {(data?.amount && formatVNDCurrency(data?.amount)) || (data?.rate && data?.rate + "%") || 0}
          </DropdownToggle>
          <DropdownMenu className="p-0" container="body">
            <DropdownItem className="hstack p-3" toggle={false}>
              <div className="flex-shrink-0 me-3">
                <Input
                  type="radio"
                  className="btn-check"
                  id={`value-discount-${index}${subIndex && "-" + subIndex}`}
                  name={`discount-options-${index}${subIndex && "-" + subIndex}`}
                  onChange={(e) => {
                    type !== DiscountAmountType.VALUE && onChangeDiscountType(DiscountAmountType.VALUE);
                  }}
                  checked={type === DiscountAmountType.VALUE}
                />
                <Label
                  className="btn btn-outline-primary m-0 rounded-0 rounded-start w-sm"
                  for={`value-discount-${index}${subIndex && "-" + subIndex}`}
                >
                  Value
                </Label>
                <Input
                  type="radio"
                  className="btn-check"
                  name={`discount-options-${index}${subIndex && "-" + subIndex}`}
                  id={`percent-discount-${index}${subIndex && "-" + subIndex}`}
                  onChange={(e) => {
                    type !== DiscountAmountType.PERCENT && onChangeDiscountType(DiscountAmountType.PERCENT);
                  }}
                  checked={type === DiscountAmountType.PERCENT}
                />
                <Label
                  className="btn btn-outline-primary m-0 rounded-0 rounded-end w-sm"
                  for={`percent-discount-${index}${subIndex && "-" + subIndex}`}
                >
                  %
                </Label>
              </div>
              <div className="flex-grow-1">
                <Input
                  type="number"
                  min={0}
                  className="form-control border-0 border-bottom text-end w-md"
                  onChange={(e) => {
                    let data = Number(e.target.value);
                    if (data < 0 || data === "") {
                      setAmount(0);
                    } else {
                      setAmount(data);
                    }
                  }}
                  value={amount}
                />
              </div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
    </Fragment>
  );
};
export default DiscountRateValue;
