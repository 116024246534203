import { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

import { isEmpty } from "lodash";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import withRouter from "../../../../Components/Common/withRouter";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { formatDate, formatFromNow } from "../../../../Components/constants/common";
import AddEditStoreModal from "./AddEditStoreModal";
import Loader from "../../../../Components/Common/Loader";
import ListContainerCustom from "../../../../Components/Common/ListContainerCustom";

import { deleteApp, deleteStore, listApp, listSaleChannel, listStore } from "../../../../store/actions";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { appStoresAPI } from "../../../../helpers/resource_helper";
import { getAuthLink, getAuthSchema } from "../../../../helpers/service_helper";
import { toast } from "react-toastify";
import ModalConfigSetting from "./AppStoreDetail/ModalConfigSetting";
import BadgeCustom from "../../../../Components/Common/BadgeCustom";
import ImageCustom from "../../../../Components/Common/ImageCustom";
import ActionDropdown from "../../../../Components/Common/ActionDropdown";

const StorePage = (props) => {
  const {
    router: { location, params, t },
  } = props;
  document.title = `${t("Store")} | OptiWarehouse`;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(listStore({}));
  }, [dispatch]);

  const { apps, loading, limit, page, total, loadingDelete } = useMemoizedSelector((state) => ({
    apps: state.Stores.apps,
    loading: state.Stores.loadingApp,
    storesSuccess: state.Stores.success,
    storesError: state.Stores.errorApp,
    limit: state.Stores.limitApp,
    page: state.Stores.pageApp,
    total: state.Stores.totalApp,
    loadingDelete: state.Stores.loadingDelete,
  }));

  const [showEditAddStoreModal, setShowEditAddStoreModal] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [selectedApp, setSelectedApp] = useState(null);
  const [listSchemaFixed, setListSchemaFixed] = useState([]);
  const [isModalConfig, setIsModalConfig] = useState(false);
  const [indexSelect, setIndexSelect] = useState(null);
  const [loadingSetting, setLoadingSetting] = useState(false);

  const headers = ["name", "created_at", "status", "action"];

  return (
    <div className="page-content">
      <ModalConfigSetting
        isOpen={isModalConfig}
        toggle={() => {
          setIsModalConfig(false);
        }}
        data={listSchemaFixed}
        isEdit={true}
        onSubmitConfig={() => {
          dispatch(listApp({ limit: 20, sort_created_at: "desc" }));
        }}
      />
      <AddEditStoreModal
        t={t}
        isOpen={showEditAddStoreModal}
        toggle={() => {
          setShowEditAddStoreModal(false);
        }}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => {
          dispatch(
            deleteApp(selectedApp, () => {
              dispatch(listApp({ limit: 20, sort_created_at: "desc" }));
              setDeleteModal(false);
            }),
          );
        }}
        onCloseClick={() => {
          setDeleteModal(false);
          setSelectedApp(null);
        }}
        loading={loadingDelete}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="purchase-store-list">
              <CardHeader className="card-header bstore-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("App List")}</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-3 flex-wrap">
                      <ButtonTheme
                        type="button"
                        className="btn btn-light"
                        icon="ri-file-text-line"
                        onClick={() => setIsExportCSV(true)}
                      >
                        {t("Export")}
                      </ButtonTheme>
                      <ButtonTheme
                        className="btn btn-primary"
                        id="create-btn"
                        icon="ri-add-line text-white"
                        onClick={() => {
                          setShowEditAddStoreModal(true);
                        }}
                      >
                        {t("Add App")}
                      </ButtonTheme>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <TableContainerCustom
                  filterType={"records"}
                  searchPlaceHolder="Search apps..."
                  onCallData={(dataPayload) => {
                    dispatch(listApp(dataPayload));
                  }}
                  tableView={true}
                  listFilter={[
                    {
                      id: "status",
                      type: "selectBox",
                      title: "Status",
                      dataOption: [
                        { id: "PENDING", name: "PENDING" },
                        { id: "ACTIVE", name: "ACTIVE" },
                      ],
                    },
                  ]}
                  headers={headers}
                  contents={apps}
                  limitPage={Number(limit)}
                  currentPage={Number(page) + 1}
                  totalItems={Number(total)}
                  loading={loading}
                  scrollHeight="50vh"
                  isSettings={true}
                  listHeader={[
                    {
                      title: t("App details"),
                      key: "detail-variant",
                      children: [
                        {
                          title: t("Name"),
                          dataIndex: "name",
                          key: "name",
                          render: (name, data) => {
                            return (
                              <div className="hstack gap-3">
                                <ImageCustom image={data.image} name={data?.name} avatarSize={"avatar-xs"} />
                                <span>{t(name)}</span>
                              </div>
                            );
                          },
                        },
                        {
                          title: t("Created At"),
                          dataIndex: "created_at",
                          key: "created_at",
                          sorter: true,
                          defaultSortOrder: "desc",
                        },
                        {
                          title: t("Status"),
                          dataIndex: "status",
                          key: "status",
                          render: (s) => <BadgeCustom status={s} />,
                          sorter: true,
                        },
                        {
                          title: t("Action"),
                          dataIndex: "action",
                          key: "action",
                          render: (_, data, index) => {
                            return (
                              <ActionDropdown
                                index={index}
                                actions={[
                                  { type: "settings", onClick: () => navigate(`/stores/settings/${data.id}`) },
                                  {
                                    type: "delete",
                                    onClick: () => {
                                      setSelectedApp(data);
                                      setDeleteModal(true);
                                    },
                                  },
                                ]}
                              />
                            );
                          },
                        },
                      ],
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(StorePage);
