import { Fragment, useEffect, useState } from "react";
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { deepCopy, formatVNDCurrency } from "../../../helpers/format_helper";
import ButtonTheme from "../ButtonTheme";
import { toast } from "react-toastify";
import InputTheme from "../InputTheme";

const PriceInput = ({ onChangeItem = () => {}, product, index }) => {
  const { t } = useTranslation();
  const [price, setPrice] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);
  const [type, setType] = useState("value");

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (isOpen) {
      setPrice(product?.unit_price || 0);
      setDiscountValue(product?.unit_price - product?.sale_price);
    }
  }, [isOpen]);

  const toggle = () => {
    setIsOpen(false);
  };

  const handleApply = () => {
    if (type === "value" && product.unit_price < discountValue) {
      toast.error(t("Discount price is more than total price!!!"));
      return;
    }
    const discount = type === "value" ? discountValue : (price * discountPercent) / 100;
    const sale_price = price - discount;
    const newProduct = deepCopy(product);
    onChangeItem(index, { ...newProduct, unit_price: price, sale_price, discount });
    toggle();
  };
  return (
    <Fragment>
      {/* <ButtonTheme
        style={{ color: "var(--vz-body-color)" }}
        className="btn btn-outline-light border-0 border-bottom w-100 p-0 py-1 text-end"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {formatVNDCurrency(product.unit_price)}
      </ButtonTheme> */}
      <div
        style={{ color: "var(--vz-body-color)" }}
        className="btn btn-outline-light border-0 border-bottom w-100 p-0 py-1 text-end cursor-pointer"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {formatVNDCurrency(product.unit_price)}
      </div>
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          {!product?.custom ? `${t("Product details")}: ${product.sku}` : t("Service details")}
        </ModalHeader>
        <ModalBody>
          <Row className="mb-3">
            <Col lg={12} className="hstack gap-2">
              <Label className="flex-grow-1">{t("Price")}</Label>
              <div>
                <InputTheme
                  className="form-control border-0 border-bottom text-end w-md"
                  value={price}
                  isForm={false}
                  format="price"
                  onChange={(data) => {
                    setPrice(data);
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="hstack" lg={12}>
              <Label className="me-3">{t("Discount")}</Label>
              <ButtonTheme
                className={`btn w-md waves-effect waves-light rounded-0 rounded-start ${
                  type === "value" ? "btn-primary" : "btn-outline-primary"
                }`}
                onClick={() => {
                  setType("value");
                }}
              >
                {t("Value")}
              </ButtonTheme>
              <ButtonTheme
                className={`btn w-md waves-effect waves-light rounded-0 rounded-end me-3 ${
                  type === "percent" ? "btn-primary" : "btn-outline-primary"
                }`}
                onClick={() => {
                  setType("percent");
                }}
              >
                %
              </ButtonTheme>
              {type === "value" ? (
                <InputTheme
                  isForm={false}
                  format="price"
                  min={0}
                  max={price}
                  className="form-control border-0 border-bottom text-end w-md"
                  onChange={(data) => {
                    if (data < 0 || data === "") {
                      setDiscountValue(0);
                    } else {
                      setDiscountValue(data);
                    }
                  }}
                  value={discountValue}
                />
              ) : (
                <InputTheme
                  isForm={false}
                  format="percent"
                  min={0}
                  max={100}
                  className="form-control border-0 border-bottom text-end w-md"
                  onChange={(data) => {
                    if (data < 0 || data === "") {
                      setDiscountPercent(0);
                    } else if (data > 100) {
                      setDiscountPercent(100);
                    } else {
                      setDiscountPercent(data);
                    }
                  }}
                  value={discountPercent}
                />
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-light w-sm" onClick={toggle}>
            {t("Close")}
          </Button>
          <ButtonTheme className="btn btn-primary w-sm" onClick={handleApply}>
            {t("Apply")}
          </ButtonTheme>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default PriceInput;
