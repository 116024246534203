import { Actions } from "../../../../Components/constants/common";
import {
  LIST_REWARD_PROGRAM,
  DELETE_REWARD_PROGRAM,
  DELETE_REWARD_PROGRAM_SUCCESS,
  DELETE_REWARD_PROGRAM_FAIL,
  ADD_REWARD_PROGRAM_SUCCESS,
  ADD_REWARD_PROGRAM_FAIL,
  UPDATE_REWARD_PROGRAM_SUCCESS,
  UPDATE_REWARD_PROGRAM_FAIL,
  LIST_REWARD_PROGRAM_SUCCESS,
  LIST_REWARD_PROGRAM_FAIL,
  ADD_NEW_REWARD_PROGRAM,
  UPDATE_REWARD_PROGRAM,
  GET_REWARD_PROGRAM,
  GET_REWARD_PROGRAM_SUCCESS,
  GET_REWARD_PROGRAM_FAIL,
  RESET_REWARD_PROGRAM_FLAG,
} from "./actionType";

const INIT_STATE = {
  rewardPrograms: [],
  rewardProgram: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const RewardPrograms = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_REWARD_PROGRAM:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_REWARD_PROGRAM_SUCCESS:
      return {
        ...state,
        message: "Get reward programs success",
        loading: false,
        success: true,
        rewardPrograms: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_REWARD_PROGRAM_FAIL:
      return {
        ...state,
        message: "Get reward programs failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_REWARD_PROGRAM:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_REWARD_PROGRAM_SUCCESS:
      return {
        ...state,
        message: "Get reward program success",
        loading: false,
        success: true,
        rewardProgram: action.payload,
        action: Actions.EMPTY,
      };

    case GET_REWARD_PROGRAM_FAIL:
      return {
        ...state,
        message: "Get reward program failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_REWARD_PROGRAM:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_REWARD_PROGRAM_SUCCESS:
      return {
        ...state,
        message: "Delete reward program success",
        loading: false,
        success: true,
        rewardPrograms: state.rewardPrograms.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_REWARD_PROGRAM_FAIL:
      return {
        ...state,
        message: "Delete reward program failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_NEW_REWARD_PROGRAM:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_REWARD_PROGRAM_SUCCESS:
      return {
        ...state,
        message: "Add reward program success",
        success: true,
        loading: false,
        rewardProgram: action.payload,
        rewardPrograms: [...state.rewardPrograms, action.payload],
        action: Actions.EMPTY,
      };

    case ADD_REWARD_PROGRAM_FAIL:
      return {
        ...state,
        message: "Add reward program failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_REWARD_PROGRAM:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.UPDATE,
      };
    case UPDATE_REWARD_PROGRAM_SUCCESS:
      return {
        ...state,
        message: "Update reward program success",
        loading: false,
        success: true,
        rewardProgram: action.payload,
        rewardPrograms: state.rewardPrograms.map((rewardProgram) =>
          rewardProgram.id.toString() === action.payload.id.toString() ? { ...rewardProgram, ...action.payload } : rewardProgram,
        ),
        action: Actions.EMPTY,
      };

    case UPDATE_REWARD_PROGRAM_FAIL:
      return {
        ...state,
        message: "Update reward program failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case RESET_REWARD_PROGRAM_FLAG:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default RewardPrograms;
