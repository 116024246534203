import { Actions } from "../../../../Components/constants/common";
import {
  LIST_BLOG_CATEGORY,
  DELETE_BLOG_CATEGORY,
  DELETE_BLOG_CATEGORY_SUCCESS,
  DELETE_BLOG_CATEGORY_FAIL,
  ADD_BLOG_CATEGORY_SUCCESS,
  ADD_BLOG_CATEGORY_FAIL,
  UPDATE_BLOG_CATEGORY_SUCCESS,
  UPDATE_BLOG_CATEGORY_FAIL,
  LIST_BLOG_CATEGORY_SUCCESS,
  LIST_BLOG_CATEGORY_FAIL,
  ADD_NEW_BLOG_CATEGORY,
  UPDATE_BLOG_CATEGORY,
  GET_BLOG_CATEGORY,
  GET_BLOG_CATEGORY_SUCCESS,
  GET_BLOG_CATEGORY_FAIL,
} from "./actionType";

const INIT_STATE = {
  blogCategories: [],
  blogCategory: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const BlogCategories = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_BLOG_CATEGORY:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_BLOG_CATEGORY_SUCCESS:
      return {
        ...state,
        message: "Get blogCategories success",
        loading: false,
        success: true,
        blogCategories: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_BLOG_CATEGORY_FAIL:
      return {
        ...state,
        message: "Get blogCategories failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_BLOG_CATEGORY:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_BLOG_CATEGORY_SUCCESS:
      return {
        ...state,
        message: "Get blogCategory success",
        loading: false,
        success: true,
        blogCategory: action.payload,
        action: Actions.EMPTY,
      };

    case GET_BLOG_CATEGORY_FAIL:
      return {
        ...state,
        message: "Get blogCategory failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_BLOG_CATEGORY:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_BLOG_CATEGORY_SUCCESS:
      return {
        ...state,
        message: "Delete blogCategory success",
        loading: false,
        success: true,
        blogCategories: state.blogCategories.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_BLOG_CATEGORY_FAIL:
      return {
        ...state,
        message: "Delete blogCategory failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_NEW_BLOG_CATEGORY:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_BLOG_CATEGORY_SUCCESS:
      return {
        ...state,
        message: "Add blogCategory success",
        success: true,
        loading: false,
        blogCategories: [...state.blogCategories, action.payload],
        action: Actions.EMPTY,
      };

    case ADD_BLOG_CATEGORY_FAIL:
      return {
        ...state,
        message: "Add blogCategory failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_BLOG_CATEGORY:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.UPDATE,
      };
    case UPDATE_BLOG_CATEGORY_SUCCESS:
      return {
        ...state,
        message: "Update blogCategory success",
        loading: false,
        success: true,
        blogCategories: state.blogCategories.map((blogCategory) =>
          blogCategory.id.toString() === action.payload.id.toString() ? { ...blogCategory, ...action.payload } : blogCategory,
        ),
        action: Actions.EMPTY,
      };

    case UPDATE_BLOG_CATEGORY_FAIL:
      return {
        ...state,
        message: "Update blogCategory failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    default:
      return { ...state };
  }
};

export default BlogCategories;
