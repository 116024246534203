import {
  LIST_VARIANT,
  DELETE_VARIANT,
  DELETE_VARIANT_SUCCESS,
  DELETE_VARIANT_FAIL,
  ADD_NEW_VARIANT,
  ADD_VARIANT_SUCCESS,
  ADD_VARIANT_FAIL,
  UPDATE_VARIANT,
  UPDATE_VARIANT_SUCCESS,
  UPDATE_VARIANT_FAIL,
  LIST_VARIANT_FAIL,
  LIST_VARIANT_SUCCESS,
  GET_VARIANT,
  GET_VARIANT_FAIL,
  GET_VARIANT_SUCCESS,
  RESET_VARIANT_FLAG,
} from "./actionType";

export const listVariant = (options) => ({
  type: LIST_VARIANT,
  payload: options,
});

export const listVariantFail = (error) => ({
  type: LIST_VARIANT_FAIL,
  payload: error,
});

export const listVariantSuccess = (variants) => ({
  type: LIST_VARIANT_SUCCESS,
  payload: variants,
});

export const getVariant = (variantID) => ({
  type: GET_VARIANT,
  payload: variantID,
});

export const getVariantFail = (error) => ({
  type: GET_VARIANT_FAIL,
  payload: error,
});

export const getVariantSuccess = (variant) => ({
  type: GET_VARIANT_SUCCESS,
  payload: variant,
});

export const deleteVariant = (variant) => ({
  type: DELETE_VARIANT,
  payload: variant,
});

export const deleteVariantSuccess = (variant) => ({
  type: DELETE_VARIANT_SUCCESS,
  payload: variant,
});

export const deleteVariantFail = (error) => ({
  type: DELETE_VARIANT_FAIL,
  payload: error,
});

export const updateVariant = (variant) => ({
  type: UPDATE_VARIANT,
  payload: variant,
});

export const updateVariantSuccess = (variant) => ({
  type: UPDATE_VARIANT_SUCCESS,
  payload: variant,
});

export const updateVariantFail = (error) => ({
  type: UPDATE_VARIANT_FAIL,
  payload: error,
});

export const addNewVariant = (variant) => ({
  type: ADD_NEW_VARIANT,
  payload: variant,
});

export const addVariantSuccess = (variant) => ({
  type: ADD_VARIANT_SUCCESS,
  payload: variant,
});

export const addVariantFail = (error) => ({
  type: ADD_VARIANT_FAIL,
  payload: error,
});

export const resetVariantFlag = () => ({
  type: RESET_VARIANT_FLAG,
});
