import {
  ADD_NEW_SHIFT_PAY_LINE_ITEM,
  ADD_SHIFT,
  ADD_SHIFT_FAIL,
  ADD_SHIFT_PAY_LINE_ITEM_FAIL,
  ADD_SHIFT_PAY_LINE_ITEM_SUCCESS,
  ADD_SHIFT_SUCCESS,
  CHECK_OPENED_SHIFT,
  CHECK_OPENED_SHIFT_FAIL,
  CHECK_OPENED_SHIFT_SUCCESS,
  CLOSE_SHIFT,
  CLOSE_SHIFT_FAIL,
  CLOSE_SHIFT_SUCCESS,
  DELETE_SHIFT_PAY_LINE_ITEM,
  DELETE_SHIFT_PAY_LINE_ITEM_FAIL,
  DELETE_SHIFT_PAY_LINE_ITEM_SUCCESS,
  GET_SHIFT,
  GET_SHIFT_FAIL,
  GET_SHIFT_PAY_LINE_ITEM,
  GET_SHIFT_PAY_LINE_ITEM_FAIL,
  GET_SHIFT_PAY_LINE_ITEM_SUCCESS,
  GET_SHIFT_SUCCESS,
  LIST_SHIFTS,
  LIST_SHIFTS_FAIL,
  LIST_SHIFTS_SUCCESS,
  LIST_SHIFT_PAY_LINE_ITEM,
  LIST_SHIFT_PAY_LINE_ITEM_FAIL,
  LIST_SHIFT_PAY_LINE_ITEM_SUCCESS,
  MORE_SHIFT_PAY_LINE_ITEM,
  MORE_SHIFT_PAY_LINE_ITEM_FAIL,
  MORE_SHIFT_PAY_LINE_ITEM_SUCCESS,
  UPDATE_SHIFT,
  UPDATE_SHIFT_FAIL,
  UPDATE_SHIFT_PAY_LINE_ITEM,
  UPDATE_SHIFT_PAY_LINE_ITEM_FAIL,
  UPDATE_SHIFT_PAY_LINE_ITEM_SUCCESS,
  UPDATE_SHIFT_SUCCESS,
} from "./actionType";

const INIT_STATE = {
  shifts: [],
  shift: {},
  loading: false,
  getLoading: false,
  checkLoading: false,
  limit: 0,
  page: 0,
  total: 0,

  shiftPayLineItems: [],
  shiftPayLineItem: {},
  loadingPayLineItem: false,
  checkLoadingPayLineItem: false,
  limitPayLineItem: 0,
  pagePayLineItem: 0,
  totalPayLineItem: 0,
};

const Shifts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_SHIFTS:
      return {
        ...state,
        loading: true,
      };

    case LIST_SHIFTS_SUCCESS:
      return {
        ...state,
        shifts: action.payload.items || [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        success: true,
        loading: false,
      };

    case LIST_SHIFTS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case GET_SHIFT:
      return {
        ...state,
        getLoading: true,
      };

    case GET_SHIFT_SUCCESS:
      return {
        ...state,
        shift: action.payload,
        success: true,
        getLoading: false,
      };

    case GET_SHIFT_FAIL:
      return {
        ...state,
        getLoading: false,
        success: false,
        error: action.payload,
      };

    case UPDATE_SHIFT:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_SHIFT_SUCCESS:
      return {
        ...state,
        shifts: [...state.shifts.filter((item) => item.id !== action.payload.id), action.payload],
        loading: false,
        success: true,
      };

    case UPDATE_SHIFT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case ADD_SHIFT:
      return {
        ...state,
        loading: true,
      };

    case ADD_SHIFT_SUCCESS:
      return {
        ...state,
        shifts: [action.payload, ...state.shifts],
        shift: action.payload,
        loading: false,
        success: true,
      };

    case ADD_SHIFT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case CHECK_OPENED_SHIFT:
      return {
        ...state,
        checkLoading: true,
      };

    case CHECK_OPENED_SHIFT_SUCCESS:
      return {
        ...state,
        shift: action.payload?.id ? action.payload : {},
        checkLoading: false,
        success: true,
      };

    case CHECK_OPENED_SHIFT_FAIL:
      return {
        ...state,
        checkLoading: false,
        success: false,
        error: action.payload,
      };
    case CLOSE_SHIFT:
      return {
        ...state,
        loading: true,
      };

    case CLOSE_SHIFT_SUCCESS:
      return {
        ...state,
        shift: action.payload,
        loading: false,
        success: true,
      };

    case CLOSE_SHIFT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case LIST_SHIFT_PAY_LINE_ITEM:
      return {
        ...state,
        errorPayLineItem: null,
        successPayLineItem: false,
        loadingPayLineItem: true,
      };

    case LIST_SHIFT_PAY_LINE_ITEM_SUCCESS:
      return {
        ...state,
        loadingPayLineItem: false,
        successPayLineItem: true,
        shiftPayLineItems: action.payload.items ?? [],
        limitPayLineItem: action.payload.limit ?? 0,
        pagePayLineItem: action.payload.page ?? 0,
        totalPayLineItem: action.payload.total ?? 0,
      };

    case LIST_SHIFT_PAY_LINE_ITEM_FAIL:
      return {
        ...state,
        loadingPayLineItem: false,
        successPayLineItem: false,
        errorPayLineItem: action.payload,
      };

    case MORE_SHIFT_PAY_LINE_ITEM:
      return {
        ...state,
        errorPayLineItem: null,
        successPayLineItem: false,
        loadingPayLineItem: true,
      };

    case MORE_SHIFT_PAY_LINE_ITEM_SUCCESS:
      return {
        ...state,
        loadingPayLineItem: false,
        successPayLineItem: true,
        shiftPayLineItems: [...state.shiftPayLineItems, ...action.payload.items],
        limitPayLineItem: action.payload.limit ?? 0,
        pagePayLineItem: action.payload.page ?? 0,
        totalPayLineItem: action.payload.total ?? 0,
      };

    case MORE_SHIFT_PAY_LINE_ITEM_FAIL:
      return {
        ...state,
        loadingPayLineItem: false,
        successPayLineItem: false,
        errorPayLineItem: action.payload,
      };

    case GET_SHIFT_PAY_LINE_ITEM:
      return {
        ...state,
        errorPayLineItem: null,
        successPayLineItem: false,
        loadingPayLineItem: true,
      };

    case GET_SHIFT_PAY_LINE_ITEM_SUCCESS:
      return {
        ...state,
        loadingPayLineItem: false,
        successPayLineItem: true,
        shiftPayLineItem: action.payload,
      };

    case GET_SHIFT_PAY_LINE_ITEM_FAIL:
      return {
        ...state,
        loadingPayLineItem: false,
        successPayLineItem: false,
        errorPayLineItem: action.payload,
      };

    case DELETE_SHIFT_PAY_LINE_ITEM:
      return {
        ...state,
        errorPayLineItem: null,
        successPayLineItem: false,
        loadingPayLineItem: true,
      };

    case DELETE_SHIFT_PAY_LINE_ITEM_SUCCESS:
      return {
        ...state,
        loadingPayLineItem: false,
        successPayLineItem: true,
        shift: action.payload.shift,
        shiftPayLineItems: state.shiftPayLineItems.filter(
          (item) => item.id.toString() !== action.payload.shiftPayLineItem.id.toString(),
        ),
      };

    case DELETE_SHIFT_PAY_LINE_ITEM_FAIL:
      return {
        ...state,
        loadingPayLineItem: false,
        successPayLineItem: false,
        errorPayLineItem: action.payload,
      };

    case ADD_NEW_SHIFT_PAY_LINE_ITEM:
      return {
        ...state,
        errorPayLineItem: null,
        successPayLineItem: false,
        loadingPayLineItem: true,
      };

    case ADD_SHIFT_PAY_LINE_ITEM_SUCCESS:
      return {
        ...state,
        successPayLineItem: true,
        loadingPayLineItem: false,
        shiftPayLineItems: [action.payload.shift_pay_line_item, ...state.shiftPayLineItems],
        shift: action.payload.shift,
        shiftPayLineItem: action.payload,
      };

    case ADD_SHIFT_PAY_LINE_ITEM_FAIL:
      return {
        ...state,
        loadingPayLineItem: false,
        successPayLineItem: false,
        errorPayLineItem: action.payload,
      };

    case UPDATE_SHIFT_PAY_LINE_ITEM:
      return {
        ...state,
        errorPayLineItem: null,
        successPayLineItem: false,
        loadingPayLineItem: true,
      };
    case UPDATE_SHIFT_PAY_LINE_ITEM_SUCCESS:
      return {
        ...state,
        loadingPayLineItem: false,
        successPayLineItem: true,
        shift: action.payload.shift,
        shiftPayLineItems: state.shiftPayLineItems.map((shiftPayLineItem) =>
          shiftPayLineItem.id.toString() === action.payload.shift_pay_line_item.id.toString()
            ? { ...shiftPayLineItem, ...action.payload }
            : shiftPayLineItem,
        ),
        shiftPayLineItem: action.payload.shift_pay_line_item,
      };

    case UPDATE_SHIFT_PAY_LINE_ITEM_FAIL:
      return {
        ...state,
        loadingPayLineItem: false,
        successPayLineItem: false,
        errorPayLineItem: action.payload,
      };

    default:
      return state;
  }
};

export default Shifts;
