import { Card } from "reactstrap";
import SimpleBar from "simplebar-react";
import classnames from "classnames";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { useTranslation } from "react-i18next";
import cart from "../../../../assets/images/svg/cart.svg";
import POSOrderLineItemsTable from "../../../../Components/Common/POSOrderLineItemsTable";
import { useImperativeHandle } from "react";

const Order = ({
  selectedOrder,
  removeProductFromCart,
  onChangeQuantity,
  onClickAddProductNow,
  discount,
  onChangeOrderLineItem,
  onChangeOrderLineItemUnit,
  refShortcutButtonOrder,
  selectedLocation,
  selectedPriceGroup,
}) => {
  const { t } = useTranslation();
  useImperativeHandle(refShortcutButtonOrder, () => ({
    onFocusProductSearch: () => onClickAddProductNow(),
  }));
  return (
    <Card
      className={classnames("mb-2 flex-grow-1 overflow-auto", {
        "justify-content-center": (selectedOrder?.order_line_items || []).length === 0,
      })}
    >
      {(selectedOrder?.order_line_items || []).length === 0 ? (
        <div className="text-center py-3">
          <img src={cart} className="img-fluid" alt="" />
          <div className="mb-2">{t("Your order currently has no products")}</div>
          <ButtonTheme
            id="add-product-now-button"
            type="button"
            className="btn btn-light waves-effect"
            onClick={onClickAddProductNow}
          >
            <div className="d-flex align-items-center gap-2">
              <i className="ri-add-line"></i>
              {t("Add product")}
            </div>
          </ButtonTheme>
        </div>
      ) : (
        <POSOrderLineItemsTable
          discount_id={selectedOrder.discount_id}
          products={selectedOrder.order_line_items}
          divClass="h-100 table-responsive"
          tableClass=""
          theadClass="bg-light"
          trClass=""
          discount={discount}
          removeProductFromCart={removeProductFromCart}
          onChangeQuantity={onChangeQuantity}
          discount_details={selectedOrder.discount_details}
          onChangeOrderLineItem={onChangeOrderLineItem}
          onChangeOrderLineItemUnit={onChangeOrderLineItemUnit}
          selectedLocation={selectedLocation}
          selectedPriceGroup={selectedPriceGroup}
        />
      )}
    </Card>
  );
};

export default Order;
