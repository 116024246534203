import ReactApexChart from "react-apexcharts";
import { getChartColorsArray } from "../../../../helpers/format_helper";
import { useEffect, useState } from "react";

const GradientCircleRadialbar = ({ dataColors, percent = 0, height = 70, width = 50, fontSize = "13px" }) => {
  const chartRadialbarGradientColors = getChartColorsArray(dataColors);
  const [series, setSeries] = useState([0]);
  useEffect(() => {
    setSeries([percent]);
  }, [percent]);
  const options = {
    chart: {
      height: 350,
      type: "radialBar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
        hollow: {
          margin: 0,
          size: "70%",
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
        },
        track: {
          strokeWidth: "67%",
          margin: 0, // margin is in pixels
        },

        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: false,
            color: "#888",
            fontSize: "17px",
          },
          value: {
            formatter: function (val) {
              return parseInt(val);
            },
            offsetY: 5,
            color: "#111",
            fontSize: "13px",
            show: true,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: chartRadialbarGradientColors,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: [""],
  };
  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="radialBar"
      height={height}
      width={width}
    />
  );
};

export default GradientCircleRadialbar;
