import { Actions } from "../../../../Components/constants/common";
import {
  LIST_DISCOUNT,
  DELETE_DISCOUNT,
  DELETE_DISCOUNT_SUCCESS,
  DELETE_DISCOUNT_FAIL,
  ADD_DISCOUNT_SUCCESS,
  ADD_DISCOUNT_FAIL,
  UPDATE_DISCOUNT_SUCCESS,
  UPDATE_DISCOUNT_FAIL,
  LIST_DISCOUNT_SUCCESS,
  LIST_DISCOUNT_FAIL,
  ADD_NEW_DISCOUNT,
  UPDATE_DISCOUNT,
  GET_DISCOUNT,
  GET_DISCOUNT_SUCCESS,
  GET_DISCOUNT_FAIL,
  RESET_DISCOUNT_FLAG,
} from "./actionType";

const INIT_STATE = {
  discounts: [],
  discount: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const Discounts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_DISCOUNT:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_DISCOUNT_SUCCESS:
      return {
        ...state,
        message: "Get discounts success",
        loading: false,
        success: true,
        discounts: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_DISCOUNT_FAIL:
      return {
        ...state,
        message: "Get discounts failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_DISCOUNT:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_DISCOUNT_SUCCESS:
      return {
        ...state,
        message: "Get discount success",
        loading: false,
        success: true,
        discount: action.payload,
        action: Actions.EMPTY,
      };

    case GET_DISCOUNT_FAIL:
      return {
        ...state,
        message: "Get discount failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_DISCOUNT:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_DISCOUNT_SUCCESS:
      return {
        ...state,
        message: "Delete discount success",
        loading: false,
        success: true,
        discounts: state.discounts.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_DISCOUNT_FAIL:
      return {
        ...state,
        message: "Delete discount failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_NEW_DISCOUNT:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_DISCOUNT_SUCCESS:
      return {
        ...state,
        message: "Add discount success",
        success: true,
        loading: false,
        discount: action.payload,
        discounts: [...state.discounts, action.payload],
        action: Actions.EMPTY,
      };

    case ADD_DISCOUNT_FAIL:
      return {
        ...state,
        message: "Add discount failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_DISCOUNT:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.UPDATE,
      };
    case UPDATE_DISCOUNT_SUCCESS:
      return {
        ...state,
        message: "Update discount success",
        loading: false,
        success: true,
        discount: action.payload,
        discounts: state.discounts.map((discount) =>
          discount.id.toString() === action.payload.id.toString() ? { ...discount, ...action.payload } : discount,
        ),
        action: Actions.EMPTY,
      };

    case UPDATE_DISCOUNT_FAIL:
      return {
        ...state,
        message: "Update discount failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case RESET_DISCOUNT_FLAG:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Discounts;
