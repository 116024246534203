import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";

// Redux States
import { LIST_ACTIVITY, GET_ACTIVITY } from "./actionType";

import { listActivitySuccess, listActivityFail, getActivitySuccess, getActivityFail } from "./action";

import { historyAPI } from "../../../helpers/resource_helper";

function* listActivity({ payload: { ...res } }) {
  try {
    const response = yield call(historyAPI.list, { ...res });
    yield put(listActivitySuccess(response));
  } catch (error) {
    yield put(listActivityFail(error));
  }
}

function* getActivity({ payload: id }) {
  try {
    const response = yield call(historyAPI.get, { id });
    yield put(getActivitySuccess(response));
  } catch (error) {
    yield put(getActivityFail(error));
  }
}

export function* watchGetActivities() {
  yield takeEvery(LIST_ACTIVITY, listActivity);
}

export function* watchGetActivity() {
  yield takeEvery(GET_ACTIVITY, getActivity);
}

function* activitiesSaga() {
  yield all([fork(watchGetActivity), fork(watchGetActivities)]);
}

export default activitiesSaga;
