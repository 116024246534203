import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import InputTheme from "../../../../../Components/Common/InputTheme";
import SelectTheme from "../../../../../Components/Common/SelectTheme";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import { useTranslation } from "react-i18next";
import { deepCopy } from "../../../../../helpers/format_helper";

const UnitOptions = ({ variants = [], units, onChangeUnits }) => {
  const { t } = useTranslation();
  const [listVariantName, setListVariantName] = useState([]);
  const handleAddOption = () => {
    onChangeUnits([...units, { variant: undefined, unit: undefined }]);
  };
  const handleRemoveOption = (index) => {
    onChangeUnits(units.filter((item, i) => i !== index));
  };
  useEffect(() => {
    setListVariantName([
      { id: "ALL", name: "All" },
      ...variants
        .filter((item) => !item?.unit?.id)
        .map((item) => {
          return { id: item.sku, name: item.name };
        }),
    ]);
  }, [variants]);
  return (
    <div className="mt-3">
      {units.map((option, i) => {
        return (
          <Row key={i} className="mb-3">
            <Col lg={6}>
              <SelectTheme
                isForm={false}
                label={"Variant"}
                classWrapper="flex-1"
                placeholder="Select variant"
                value={option?.variant}
                onChange={(data) => {
                  const newUnits = units.map((item, ii) => {
                    if (ii === i) {
                      item.variant = data?.value;
                    }
                    return item;
                  });
                  onChangeUnits(newUnits);
                }}
                options={listVariantName}
              />
            </Col>
            <Col lg={5}>
              <SelectTheme
                isForm={false}
                isObjectValueSelect={true}
                selectDataOrigin={true}
                value={option?.unit}
                onChange={(data) => {
                  const newUnits = units.map((item, ii) => {
                    if (ii === i) {
                      item.unit = data;
                    }
                    return item;
                  });
                  onChangeUnits(newUnits);
                }}
                label={"Unit"}
                placeholder={"Select an unit"}
                remote={true}
                path="product/units"
              />
            </Col>
            <Col lg={1} className="d-flex align-items-end">
              <ButtonTheme
                type="button"
                className="flex-shrink-0 btn btn-outline-danger btn-icon waves-effect waves-light"
                onClick={() => handleRemoveOption(i)}
              >
                <i className="ri-delete-bin-5-line"></i>
              </ButtonTheme>
            </Col>
          </Row>
        );
      })}
      <ButtonTheme type="button" className="btn btn-outline-primary" onClick={handleAddOption}>
        <i className="ri-add-line align-bottom me-1"></i> {t("Add option")}
      </ButtonTheme>
    </div>
  );
};
export default UnitOptions;
