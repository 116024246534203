import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";

// Redux States
import { LIST_BLOG, DELETE_BLOG, ADD_NEW_BLOG, UPDATE_BLOG, GET_BLOG } from "./actionType";

import {
  deleteBlogSuccess,
  deleteBlogFail,
  addBlogSuccess,
  addBlogFail,
  updateBlogSuccess,
  updateBlogFail,
  listBlogSuccess,
  listBlogFail,
  getBlogSuccess,
  getBlogFail,
} from "./action";

import { blogsAPI } from "../../../../helpers/resource_helper";

function* listBlog({ payload: { ...rest } }) {
  try {
    const response = yield call(blogsAPI.list, { ...rest });
    yield put(listBlogSuccess(response));
  } catch (error) {
    yield put(listBlogFail(error));
  }
}

function* getBlog({ payload: id }) {
  try {
    const response = yield call(blogsAPI.get, { id });
    yield put(getBlogSuccess(response));
  } catch (error) {
    yield put(getBlogFail(error));
  }
}

function* deleteBlogs({ payload: { blog, handleAfterSubmit } }) {
  try {
    const response = yield call(blogsAPI.delete, { id: blog.id });
    yield put(deleteBlogSuccess(blog));
    yield call(handleAfterSubmit);
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteBlogFail(error));
    toast.error(error.toString(), { autoClose: 3000 });
  }
}

function* onAddNewBlog({ payload: { blog, handleAfterSubmit } }) {
  try {
    const response = yield call(blogsAPI.create, blog);
    yield put(addBlogSuccess(response));
    yield call(handleAfterSubmit, response.id);
    toast.success("Blog Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addBlogFail(error));
    toast.error(error.toString(), { autoClose: 3000 });
  }
}

function* onUpdateBlog({ payload: { blog, handleAfterSubmit } }) {
  try {
    const response = yield call(blogsAPI.put, blog);
    yield put(updateBlogSuccess(response));
    yield call(handleAfterSubmit, response.id);
    toast.success("Blog Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateBlogFail(error));
    toast.error(error.toString(), { autoClose: 3000 });
  }
}

export function* watchGetBlogs() {
  yield takeEvery(LIST_BLOG, listBlog);
}

export function* watchDeleteBlogs() {
  yield takeEvery(DELETE_BLOG, deleteBlogs);
}

export function* watchGetBlog() {
  yield takeEvery(GET_BLOG, getBlog);
}

export function* watchUpdateBlog() {
  yield takeEvery(UPDATE_BLOG, onUpdateBlog);
}

export function* watchAddNewBlog() {
  yield takeEvery(ADD_NEW_BLOG, onAddNewBlog);
}

function* blogsSaga() {
  yield all([
    fork(watchGetBlog),
    fork(watchGetBlogs),
    fork(watchUpdateBlog),
    fork(watchAddNewBlog),
    fork(watchDeleteBlogs),
  ]);
}

export default blogsSaga;
