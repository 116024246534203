import {
  GET_LOYALTY_APP,
  GET_LOYALTY_APP_FAIL,
  GET_LOYALTY_APP_SUCCESS,
  UPDATE_LOYALTY_APP,
  UPDATE_LOYALTY_APP_FAIL,
  UPDATE_LOYALTY_APP_SUCCESS,
} from "./actionType";

export const getLoyaltyApp = () => ({
  type: GET_LOYALTY_APP,
});

export const getLoyaltyAppSuccess = (payload) => ({
  type: GET_LOYALTY_APP_SUCCESS,
  payload: payload,
});

export const getLoyaltyAppError = (error) => ({
  type: GET_LOYALTY_APP_FAIL,
  payload: error,
});

export const updateLoyaltyApp = (data, handleAfterSubmit = () => {}) => ({
  type: UPDATE_LOYALTY_APP,
  payload: { data, handleAfterSubmit },
});

export const updateLoyaltyAppSuccess = (data) => ({
  type: UPDATE_LOYALTY_APP_SUCCESS,
  payload: data,
});

export const updateLoyaltyAppFail = (error) => ({
  type: UPDATE_LOYALTY_APP_FAIL,
  payload: error,
});
