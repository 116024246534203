import React, { useMemo } from "react";
import Frame from "react-frame-component";
import { CONFIG_SIZE } from "../constants/TemplatePrint/EnumTemplate";
import { useTranslation } from "react-i18next";
import { convertValidDate, convertValidTime, formatVNDCurrency } from "../../helpers/format_helper";

export default function ShiftReportTemplate({ shift = {}, isShow = false }) {
  const { t } = useTranslation();
  const htmlContent = `
    <figure style="font-size: 10px">
      <p style="text-align:center;">&nbsp;</p>
      <p style="text-align:center;"><strong>${t("BÁO CÁO CHỐT CA")}</strong></p>
      <p style="text-align:center;"><strong>${shift?.location_name || shift?.location_id || "---"}</strong></p>
      <figure class="table" style="width:100%;">
        <table class="ck-table-resized">
          <colgroup>
            <col style="width:40%;">
            <col style="width:60%;">
          </colgroup>
          <tbody>
            <tr>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <strong>${t("Shift Id")}:</strong>
              </td>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <p style="text-align:right;">${shift?.id}</p>
              </td>
            </tr>
            <tr>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <strong>${t("Terminal Id")}:</strong>
              </td>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <p style="text-align:right;">${shift?.terminal_id}</p>
              </td>
            </tr>
            <tr>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <strong>${t("Opened time")}:</strong>
              </td>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <p style="text-align:right;">${convertValidDate(shift?.created_at)}, ${convertValidTime(
    shift?.created_at,
  )}</p>
              </td>
            </tr>
            <tr>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <strong>${t("Closed time")}:</strong>
              </td>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <p style="text-align:right;">${convertValidDate(new Date())}, ${convertValidTime(new Date())}</p>
              </td>
            </tr>
            <tr>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <strong>${t("Print time")}:</strong>
              </td>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <p style="text-align:right;">${convertValidDate(new Date())}, ${convertValidTime(new Date())}</p>
              </td>
            </tr>
            <tr>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <strong>${t("Staff")}:</strong>
              </td>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <p style="text-align:right;">${shift?.user?.name_staff || "---"}</p>
              </td>
            </tr>
            <tr>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <strong>${t("Staring cash")}:</strong>
              </td>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <p style="text-align:right;">${formatVNDCurrency(shift?.starting_cash || 0)}</p>
              </td>
            </tr>
            <tr>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <strong>${t("Gross sale")}:</strong>
              </td>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <p style="text-align:right;">${formatVNDCurrency(shift?.gross_sale || 0)}</p>
              </td>
            </tr>
            <tr>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <strong>${t("Discounts")}:</strong>
              </td>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <p style="text-align:right;">${formatVNDCurrency(shift?.discounts || 0)}</p>
              </td>
            </tr>
             <tr>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <strong>${t("Refunded")}:</strong>
              </td>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <p style="text-align:right;">${formatVNDCurrency(shift?.refunds || 0)}</p>
              </td>
            </tr>
            <tr>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <strong>${t("Paid in")}:</strong>
              </td>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <p style="text-align:right;">${formatVNDCurrency(shift?.paid_in || 0)}</p>
              </td>
            </tr>
            <tr>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <strong>${t("Paid out")}:</strong>
              </td>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <p style="text-align:right;">${formatVNDCurrency(shift?.paid_out || 0)}</p>
              </td>
            </tr>
            <tr>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <strong>${t("Expected fund")}:</strong>
              </td>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <p style="text-align:right;">${formatVNDCurrency(shift?.expected_fund || 0)}</p>
              </td>
            </tr>
            <tr>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <strong>${t("Actual fund")}:</strong>
              </td>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <p style="text-align:right;">${formatVNDCurrency(shift?.actual_fund || 0)}</p>
              </td>
            </tr>
            <tr>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <strong>${t("Net sale")}:</strong>
              </td>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <p style="text-align:right;">${formatVNDCurrency(shift?.net_sales || 0)}</p>
              </td>
            </tr>
            <tr>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <strong>${t("Expected cash")}:</strong>
              </td>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <p style="text-align:right;">${formatVNDCurrency(shift?.expected_cash || 0)}</p>
              </td>
            </tr>
            <tr>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <strong>${t("Actual cash")}:</strong>
              </td>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <p style="text-align:right;">${formatVNDCurrency(shift?.actual_cash || 0)}</p>
              </td>
            </tr>
             <tr>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <strong>${t("Difference")}:</strong>
              </td>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <p style="text-align:right;">${formatVNDCurrency(shift?.difference || 0)}</p>
              </td>
            </tr>
            <tr>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <strong>${t("Payment methods")}</strong>
              </td>
            </tr>
             <tr>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                - ${t("Cash")}:
              </td>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <p style="text-align:right;">${formatVNDCurrency(shift?.cash || 0)}</p>
              </td>
            </tr>
            <tr>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                - ${t("Banking")}:
              </td>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <p style="text-align:right;">${formatVNDCurrency(shift?.banking || 0)}</p>
              </td>
            </tr>
            <tr>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <strong>${t("Note")}:</strong>
              </td>
              <td style="border-color:rgb(255, 255, 255);border-style:solid;">
                <p style="text-align:right;">${shift?.note || "---"}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </figure>
      <p>&nbsp;</p>
      <p style="text-align:center;"><i>${t("Powered by optiwarehouse.com")}</i></p>
    </figure>`;
  return (
    <>
      <div className="item-content " style={!isShow ? { display: "none" } : {}}>
        <div className="view-print" style={{ textAlign: "center", overflow: "auto" }} id="view-print">
          <Frame
            width={`${CONFIG_SIZE.K57.width}`}
            height={500}
            id="shift-report"
            style={{ overflowY: "auto", overflowX: "hidden" }}
            contentDidMount={(e) => {
              var doc = document.getElementById("shift-report").contentWindow.document;
              doc.head.innerHTML = `<style> 
                    @media print {
                          @page {
                            size: auto;
                            margin: 0;
                          }
                          header, footer {
                            display: none !important;
                          }
                        }
                    body {
                        font-family: 'Arial', sans-serif; /* Thay đổi thành font chữ mong muốn */
                    }
                    figure {
                        margin:0
                    }
                    table {
                      width:100%;
                      border-collapse:collapse
                    }
                    .frame-content {
                       margin:20px
                    }
                    td {
                      padding:6px
                    }
                    p {
                      margin:0
                    }
                    .ck-content hr {
                        margin: 0;
                        height: 4px;
                        background: hsl(0, 0%, 87%);
                        border: 0;
                        width:100%
                    }
                    hr {
                        margin: 0;
                       
                    }
                    td, th {
                      border: 1px solid;
                    }
                    p + p {
                      padding-bottom:10px
                    }
                    .logo-shop {
                      width: 100px;
                      height: 80px;
                      object-fit:contain
                    }
                    .qr_code {
                      width: 260px;
                      height: 260px;
                    }
                    
                    </style>
                   
                    `;
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </Frame>
        </div>
      </div>
    </>
  );
}
