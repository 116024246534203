export const LIST_SHIFTS = "LIST_SHIFTS";
export const LIST_SHIFTS_SUCCESS = "LIST_SHIFTS_SUCCESS";
export const LIST_SHIFTS_FAIL = "LIST_SHIFTS_FAIL";

export const GET_SHIFT = "GET_SHIFT";
export const GET_SHIFT_SUCCESS = "GET_SHIFT_SUCCESS";
export const GET_SHIFT_FAIL = "GET_SHIFT_FAIL";

export const UPDATE_SHIFT = "UPDATE_SHIFT";
export const UPDATE_SHIFT_SUCCESS = "UPDATE_SHIFT_SUCCESS";
export const UPDATE_SHIFT_FAIL = "UPDATE_SHIFT_FAIL";

export const ADD_SHIFT = "ADD_SHIFT";
export const ADD_SHIFT_SUCCESS = "ADD_SHIFT_SUCCESS";
export const ADD_SHIFT_FAIL = "ADD_SHIFT_FAIL";

export const CHECK_OPENED_SHIFT = "CHECK_OPENED_SHIFT";
export const CHECK_OPENED_SHIFT_SUCCESS = "CHECK_OPENED_SHIFT_SUCCESS";
export const CHECK_OPENED_SHIFT_FAIL = "CHECK_OPENED_SHIFT_FAIL";

export const CLOSE_SHIFT = "CLOSE_SHIFT";
export const CLOSE_SHIFT_SUCCESS = "CLOSE_SHIFT_SUCCESS";
export const CLOSE_SHIFT_FAIL = "CLOSE_SHIFT_FAIL";

export const LIST_SHIFT_PAY_LINE_ITEM = "LIST_SHIFT_PAY_LINE_ITEM";
export const LIST_SHIFT_PAY_LINE_ITEM_SUCCESS = "LIST_SHIFT_PAY_LINE_ITEM_SUCCESS";
export const LIST_SHIFT_PAY_LINE_ITEM_FAIL = "LIST_SHIFT_PAY_LINE_ITEM_FAIL";

export const MORE_SHIFT_PAY_LINE_ITEM = "MORE_SHIFT_PAY_LINE_ITEM";
export const MORE_SHIFT_PAY_LINE_ITEM_SUCCESS = "MORE_SHIFT_PAY_LINE_ITEM_SUCCESS";
export const MORE_SHIFT_PAY_LINE_ITEM_FAIL = "MORE_SHIFT_PAY_LINE_ITEM_FAIL";

export const GET_SHIFT_PAY_LINE_ITEM = "GET_SHIFT_PAY_LINE_ITEM";
export const GET_SHIFT_PAY_LINE_ITEM_SUCCESS = "GET_SHIFT_PAY_LINE_ITEM_SUCCESS";
export const GET_SHIFT_PAY_LINE_ITEM_FAIL = "GET_SHIFT_PAY_LINE_ITEM_FAIL";

export const DELETE_SHIFT_PAY_LINE_ITEM = "DELETE_SHIFT_PAY_LINE_ITEM";
export const DELETE_SHIFT_PAY_LINE_ITEM_SUCCESS = "DELETE_SHIFT_PAY_LINE_ITEM_SUCCESS";
export const DELETE_SHIFT_PAY_LINE_ITEM_FAIL = "DELETE_SHIFT_PAY_LINE_ITEM_FAIL";

export const ADD_NEW_SHIFT_PAY_LINE_ITEM = "ADD_NEW_SHIFT_PAY_LINE_ITEM";
export const ADD_SHIFT_PAY_LINE_ITEM_SUCCESS = "ADD_SHIFT_PAY_LINE_ITEM_SUCCESS";
export const ADD_SHIFT_PAY_LINE_ITEM_FAIL = "ADD_SHIFT_PAY_LINE_ITEM_FAIL";

export const UPDATE_SHIFT_PAY_LINE_ITEM = "UPDATE_SHIFT_PAY_LINE_ITEM";
export const UPDATE_SHIFT_PAY_LINE_ITEM_SUCCESS = "UPDATE_SHIFT_PAY_LINE_ITEM_SUCCESS";
export const UPDATE_SHIFT_PAY_LINE_ITEM_FAIL = "UPDATE_SHIFT_PAY_LINE_ITEM_FAIL";
