import React, { useState } from "react";
import { Card, CardBody, CardHeader, Input, Label } from "reactstrap";
import ButtonDropdownCustom from "../../../../Components/Common/ButtonDropdownCustom";
import { useTranslation } from "react-i18next";

export default function BoxApply({
  title,
  disabled = false,
  titleRadioAll,
  onChange,
  data = null,
  callApi,
  text,
  conditionFilter,
}) {
  const { t } = useTranslation();
  const [check, setCheck] = useState(true);
  return (
    <Card>
      <CardHeader>
        <div className="d-sm-flex align-items-center">
          <h5 className="card-title flex-grow-1 mb-0">{t(title)}</h5>
        </div>
      </CardHeader>
      <CardBody>
        <div className="form-check mb-2">
          <Input
            className="form-check-input"
            type="checkbox"
            name="sources-radio"
            id={`${text}-radio`}
            checked={check}
            onChange={(e) => {
              const isCheck = e.target.checked;
              setCheck(isCheck);
              if (isCheck) {
                onChange([{ id: "ALL" }]);
              } else {
                onChange([]);
              }
            }}
            disabled={disabled}
          />
          <Label className="form-check-label" for={`${text}-radio`}>
            {t(titleRadioAll)}
          </Label>
        </div>
        <ButtonDropdownCustom
          isEdit={!check}
          text={text}
          data={data}
          buttonClass={"form-select"}
          listRemoteData={callApi}
          onChangeData={(data) => {
            const cloneData = check ? data : data.filter((item) => item.id !== "ALL");
            onChange(cloneData);
          }}
          conditionFilter={conditionFilter}
        />
      </CardBody>
    </Card>
  );
}
