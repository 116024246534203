import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import { deepCopy, formatVNDCurrency } from "../../helpers/format_helper";
import { useEffect, useState } from "react";
import config from "../../config";
import { useSearchParams } from "react-router-dom";

const PairDevice = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [socket, setSocket] = useState(null);
  const user_id = searchParams.get("user_id");
  const [data, setData] = useState(null);
  const [logo, setLogo] = useState(null);
  const [name, setName] = useState(null);
  const [address, setAddress] = useState(null);
  const [qrDetails, setQRDetails] = useState(null);
  let quantity = (data?.order_line_items || []).reduce((total, item) => total + +item.quantity, 0);

  useEffect(() => {
    if (!user_id) {
      return;
    }
    console.log(`${config.ws_url}?user_id=${user_id}`);
    const ws = new WebSocket(`${config.ws_url}?user_id=${user_id}`);

    ws.onopen = (event) => {
      console.log("Connection opened!");
      const pairingScreenMessage = {
        action: "sendMessage",
        data: { type: "PAIRING_SCREEN", user_id },
      };
      ws.send(JSON.stringify(pairingScreenMessage));
    };
    ws.onmessage = (event) => {
      const response = JSON.parse(event?.data);
      console.log(response?.message);
      if (response?.message?.pay_url) {
        // window.open(response?.message?.pay_url, "_self");
      }
      setData(response?.message?.active_order || null);
      setLogo(response?.message?.store_logo || null);
      setName(response?.message?.store_name || null);
      setAddress(response?.message?.store_address || null);
    };
    ws.onclose = () => {
      console.log("Connection Closed!");
    };
    setSocket(ws);

    return () => {
      ws.close();
    };
  }, [user_id]);
  window.onbeforeunload = () => {
    socket && socket.close();
  };

  return (
    <Card className="shadow-lg mb-0 overflow-hidden" style={{ height: "100vh", width: "100vw" }}>
      <CardHeader>
        <div className="hstack mb-5">
          <div className="flex-grow-1 border-end px-3">
            <img src={logo} alt="" height={60} width={200} />
          </div>
          <div className="flex-grow-1">
            <div className="px-3 text-truncate-two-lines">{address}</div>
          </div>
        </div>

        <h2 className="text-center">{t("Order details")}</h2>
      </CardHeader>
      <CardBody className="p-0" style={{ height: "calc(100vh - 176px)" }}>
        <div className="d-flex flex-column h-100">
          <div className="flex-grow-1 overflow-auto">
            <div className="h-100 table-responsive">
              <Table className="mb-0">
                <thead className="bg-light">
                  <tr>
                    <th>{t("Quantity")}</th>
                    <th className="text-end">{t("Quantity")}</th>
                    <th className="text-end">{t("Price")}</th>
                    <th className="text-end">{t("Discount")}</th>
                    <th className="text-end">{t("Total Amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {(data?.order_line_items || []).length === 0 ? (
                    <tr style={{ verticalAlign: "middle" }}>
                      <td colSpan={100}>
                        <div className="text-center text-muted py-5">{t("There are no any products")}</div>
                      </td>
                    </tr>
                  ) : (
                    (data?.order_line_items || []).map((product, index) => {
                      let total = product.quantity * product.sale_price;
                      // if (discount_id && ViewConditionsCase.includes(discount?.type)) {
                      //   const effectiveItem = discount_details.find((item) => item.sku === product.sku);
                      //   if (effectiveItem) {
                      //     total = effectiveItem.quantity * effectiveItem.sale_price;
                      //   }
                      // }
                      return (
                        <tr style={{ verticalAlign: "middle" }} key={index}>
                          <td>{product?.name || "---"}</td>
                          <td className="text-end">{product?.quantity || 0}</td>
                          <td className="text-end">{formatVNDCurrency(product.unit_price)}</td>
                          <td className="text-end">{formatVNDCurrency(product.unit_price - product.sale_price)}</td>
                          <td>
                            <div className="fw-medium text-end">{formatVNDCurrency(total)}</div>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </div>
          </div>
          <table className="table table-borderless mb-0 flex-shrink-0">
            <tbody>
              <tr className="border-top">
                <td>{`${t("Total")} (${quantity} ${quantity > 1 ? t("products") : t("product")})`}</td>
                <td className="text-end  fw-bold">{formatVNDCurrency(data?.total || 0)}</td>
              </tr>
              <tr>
                <td>{`${t("Discount")}`}</td>
                <td className="text-end  fw-bold">{formatVNDCurrency(data?.discount || 0)}</td>
              </tr>
              <tr>
                <td>
                  {t("Tax")}
                  <i className="ms-2 ri-information-line text-primary align-bottom"></i>
                </td>
                <td className="text-end fw-bold">{formatVNDCurrency(data?.tax || 0)}</td>
              </tr>
              <tr className="border-top">
                <td>{t("Amount to pay")}</td>
                <td className="text-end  fw-bold">{formatVNDCurrency(data?.sub_total || 0)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
};
export default PairDevice;
