import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import withRouter from "../../Components/Common/withRouter";
import ButtonTheme from "../../Components/Common/ButtonTheme";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { LogoLight } from "../../assets/images/svg";

// action
import { resetForgetPassword, userForgetPassword } from "../../store/actions";
import useMemoizedSelector from "../../Components/Hooks/useMemoizedSelector";
import { useProfile } from "../../Components/Hooks/useProfile";
import LottieIcon from "../../Components/Common/LottieIcon";

const ForgetPasswordPage = ({ t }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { dataShop } = useProfile();
  const [logo, setLogo] = useState(null);
  const [name, setName] = useState("");
  useEffect(() => {
    setLogo(dataShop?.setting_value?.store_logo_light?.url || LogoLight);
    setName(dataShop?.setting_value?.store_name || "OptiWarehouse");
  }, [dataShop]);
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(t("Please Enter Your Email")),
    }),
    onSubmit: (values) => {
      let payload = { username: values.email };
      dispatch(userForgetPassword(payload, history));
    },
  });

  const { forgetError, forgetSuccessMsg, loading } = useMemoizedSelector((state) => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
    loading: state.ForgetPassword.loading,
  }));

  useEffect(() => {
    if (!!forgetSuccessMsg) {
      setTimeout(() => {
        dispatch(resetForgetPassword());
        history("/reset-password", { state: { username: validation.values.email } });
      }, 3000);
    }
  }, [history, forgetSuccessMsg]);

  document.title = `${t("Forgot Password")} | OptiWarehouse`;
  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logo} alt="" height="100" />
                  </Link>
                </div>
                {/* <p className="mt-3 fs-15 fw-medium">
                  Premium Admin & Dashboard Template
                </p> */}
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">{t("Forgot Password?")}</h5>
                    <p className="text-muted">
                      {t("Reset password with")} {name}
                    </p>
                    <LottieIcon
                      src="https://cdn.lordicon.com/rhvddzym.json"
                      style={{ width: "120px", height: "120px" }}
                    />
                  </div>

                  <Alert className="alert-borderless alert-warning text-center mb-2 mx-2" role="alert">
                    {t("Enter your email and instructions will be sent to you!")}
                  </Alert>
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {t(forgetError)}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {t(forgetSuccessMsg)}
                      </Alert>
                    ) : null}
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-4">
                        <Label className="form-label">{t("Email")}</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder={t("Enter email")}
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={validation.touched.email && validation.errors.email ? true : false}
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.email}</div>
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="text-center mt-4">
                        <ButtonTheme
                          className="btn btn-primary w-100"
                          type="submit"
                          loading={loading}
                          disabled={loading}
                        >
                          {t("Send Reset Link")}
                        </ButtonTheme>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-4 text-center">
                <p className="mb-0">
                  {t("Wait, I remember my password")}...{" "}
                  <Link to="/login" className="fw-semibold text-primary text-decoration-underline">
                    {" "}
                    {t("Click here")}{" "}
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(withTranslation()(ForgetPasswordPage));
