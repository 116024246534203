import {
  ADD_TEMPLATE_PRINT,
  ADD_TEMPLATE_PRINT_FAIL,
  ADD_TEMPLATE_PRINT_SUCCESS,
  GET_TEMPLATE_PRINT,
  GET_TEMPLATE_PRINT_FAIL,
  GET_TEMPLATE_PRINT_SUCCESS,
  UPDATE_TEMPLATE_PRINT,
  UPDATE_TEMPLATE_PRINT_FAIL,
  UPDATE_TEMPLATE_PRINT_SUCCESS,
} from "./actionType";

export const getTemplatePrint = () => ({
  type: GET_TEMPLATE_PRINT,
});
export const getTemplatePrintSuccess = (payload) => ({
  type: GET_TEMPLATE_PRINT_SUCCESS,
  payload: payload,
});

export const getTemplatePrintError = (error) => ({
  type: GET_TEMPLATE_PRINT_FAIL,
  payload: error,
});

export const updatePrintTemplate = (data) => ({
  type: UPDATE_TEMPLATE_PRINT,
  payload: data,
});

export const updatePrintTemplateSuccess = (response) => ({
  type: UPDATE_TEMPLATE_PRINT_SUCCESS,
  payload: response,
});

export const updatePrintTemplateFail = (error) => ({
  type: UPDATE_TEMPLATE_PRINT_FAIL,
  payload: error,
});

export const addPrintTemplate = (data) => ({
  type: ADD_TEMPLATE_PRINT,
  payload: data,
});

export const addPrintTemplateSuccess = (response) => ({
  type: ADD_TEMPLATE_PRINT_SUCCESS,
  payload: response,
});

export const addPrintTemplateFail = (error) => ({
  type: ADD_TEMPLATE_PRINT_FAIL,
  payload: error,
});
