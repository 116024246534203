import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Card,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import Flatpickr from "react-flatpickr";

import { Form, Formik, useFormik } from "formik";
import * as Yup from "yup";

import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import Loader from "../../../../Components/Common/Loader";
import { Actions } from "../../../../Components/constants/common";

import { addNewTransaction, cancelTransaction, getTransaction } from "../../../../store/actions";
import InputTheme from "../../../../Components/Common/InputTheme";
import SelectTheme from "../../../../Components/Common/SelectTheme";
import ScrollToError from "../../../../Components/Common/ScrollToError";

const TransactionModal = ({ transactionID = "", isOpen, toggle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formRef = useRef();

  const { transaction, transactionsLoading, transactionsAction, transactionMessage, transactionsError } =
    useMemoizedSelector((state) => ({
      transaction: state.Transactions.transaction,
      transactionsLoading: state.Transactions.loading,
      transactionsAction: state.Transactions.action,
      transactionsError: state.Transactions.error,
      transactionMessage: state.Transactions.message,
    }));

  const currentTime = new Date().toISOString();
  const [type, setType] = useState("EXPENSE");
  const onChangeType = (data) => {
    setType(data);
    formRef.current.handleReset();
  };
  const validation = {
    enableReinitialize: true,

    initialValues: {
      amount: transactionID ? transaction?.amount || 0 : 0,
      payment_method: transactionID ? transaction?.payment_method || null : null,
      voucher: transactionID ? transaction?.voucher || null : null,
      effective_date: transactionID ? transaction?.effective_date || currentTime : currentTime,
      note: transactionID ? transaction?.note || "" : "",
      reference: transactionID ? transaction?.reference || "" : "",
      from_account: transactionID ? transaction?.from_account || null : null,
      to_account: transactionID ? transaction?.to_account || null : null,
    },
    validationSchema: Yup.object({
      amount: Yup.number().required(t("Please enter transaction amount")),
    }),
    onSubmit: (values) => {
      dispatch(addNewTransaction(values, toggle));
    },
  };

  const handleToggle = () => {
    if (!transactionsLoading) {
      toggle();
    }
  };

  useEffect(() => {
    if (transactionID && isOpen) {
      dispatch(getTransaction(transactionID));
    } else {
      setType("EXPENSE");
    }
  }, [isOpen]);

  useEffect(() => {
    if (transaction?.voucher?.type) {
      setType(transaction?.voucher?.type);
    }
  }, [transaction]);

  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={handleToggle} centered scrollable size="lg">
        <ModalHeader toggle={handleToggle}>{t(transactionID ? "View transaction" : "Add transaction")}</ModalHeader>
        <ModalBody>
          {transactionsAction === Actions.GET ? (
            <Loader />
          ) : (
            <Fragment>
              <div className="form-check form-check-primary mb-3">
                <Input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  value={"EXPENSE"}
                  onChange={(e) => {
                    onChangeType(e.target.value);
                  }}
                  disabled={transactionID}
                  checked={type === "EXPENSE"}
                />
                <Label className="form-check-label" for="flexRadioDefault1">
                  {t("Expense")}
                </Label>
              </div>
              <div className="form-check form-check-primary mb-3">
                <Input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value={"PAYMENT"}
                  onChange={(e) => {
                    onChangeType(e.target.value);
                  }}
                  disabled={transactionID}
                  checked={type === "PAYMENT"}
                />
                <Label className="form-check-label" for="flexRadioDefault2">
                  {t("Payment")}
                </Label>
              </div>
              <Formik
                enableReinitialize={true}
                initialValues={validation.initialValues}
                validationSchema={validation.validationSchema}
                onSubmit={validation.onSubmit}
                innerRef={formRef}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <ScrollToError />
                    <Row>
                      <Col lg={6}>
                        <InputTheme
                          label={"Effective date"}
                          type="timeDate"
                          name={"effective_date"}
                          placeholder={t("Select effective date")}
                          disabled={transactionID}
                        />
                      </Col>
                      <Col lg={6}>
                        {type === "EXPENSE" ? (
                          <SelectTheme
                            label={"Expense Voucher"}
                            name="voucher"
                            placeholder={t("Select expense voucher")}
                            remote={true}
                            isObjectValueSelect={true}
                            selectDataOrigin={true}
                            path="finance/vouchers"
                            optionSearch={{ type }}
                            disabled={transactionID}
                          />
                        ) : (
                          <SelectTheme
                            label={"Payment Voucher"}
                            name="voucher"
                            placeholder={t("Select payment voucher")}
                            remote={true}
                            isObjectValueSelect={true}
                            selectDataOrigin={true}
                            path="finance/vouchers"
                            optionSearch={{ type }}
                            disabled={transactionID}
                          />
                        )}
                      </Col>
                      <Col lg={6}>
                        <InputTheme
                          label={"Amount"}
                          name={"amount"}
                          format="price"
                          disabled={transactionID}
                          placeholder="Enter amount"
                        />
                      </Col>
                      <Col lg={6}>
                        <SelectTheme
                          label={"Payment method"}
                          name="payment_method"
                          placeholder={t("Select payment method")}
                          remote={true}
                          isObjectValueSelect={true}
                          selectDataOrigin={true}
                          path="finance/payment_methods"
                          disabled={transactionID}
                        />
                      </Col>
                      <Col lg={6}>
                        {type === "PAYMENT" ? (
                          <SelectTheme
                            label={"From account"}
                            name="from_account"
                            placeholder={t("Select account")}
                            remote={true}
                            isObjectValueSelect={true}
                            selectDataOrigin={true}
                            path="finance/accounts"
                            disabled={transactionID}
                          />
                        ) : (
                          <SelectTheme
                            label={"To account"}
                            name="to_account"
                            placeholder={t("Select account")}
                            remote={true}
                            isObjectValueSelect={true}
                            selectDataOrigin={true}
                            path="finance/accounts"
                            disabled={transactionID}
                          />
                        )}
                      </Col>
                      <Col lg={6}>
                        <InputTheme
                          label={"Note"}
                          name={"note"}
                          disabled={transactionID}
                          placeholder={transactionID ? "" : "Enter note"}
                        />
                      </Col>
                      <Col lg={6}>
                        <InputTheme
                          label={"Reference"}
                          name={"reference"}
                          disabled={transactionID}
                          placeholder={transactionID ? "" : "Enter reference"}
                        />
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Fragment>
          )}
        </ModalBody>
        <ModalFooter className="border-0">
          <ButtonTheme type="button" className="btn btn-light w-sm" onClick={handleToggle}>
            {t("Close")}
          </ButtonTheme>
          {!transactionID ? (
            <ButtonTheme
              type="button"
              className="btn btn-primary w-sm"
              onClick={() => formRef.current.handleSubmit()}
              loading={transactionsAction === Actions.CREATE && transactionsLoading}
              disabled={transactionsAction === Actions.CREATE && transactionsLoading}
            >
              {t("Create")}
            </ButtonTheme>
          ) : (
            <ButtonTheme
              type="button"
              className="btn btn-danger w-sm"
              onClick={() => {
                dispatch(cancelTransaction(transaction.id));
              }}
              loading={transactionsLoading}
              disabled={transactionsLoading}
            >
              {t("Cancel transaction")}
            </ButtonTheme>
          )}
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default TransactionModal;
