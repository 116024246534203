import {
  LIST_SHIPPING_PROVIDER,
  LIST_SHIPPING_PROVIDER_FAIL,
  LIST_SHIPPING_PROVIDER_SUCCESS,
  DELETE_SHIPPING_PROVIDER,
  DELETE_SHIPPING_PROVIDER_SUCCESS,
  DELETE_SHIPPING_PROVIDER_FAIL,
  ADD_NEW_SHIPPING_PROVIDER,
  ADD_SHIPPING_PROVIDER_SUCCESS,
  ADD_SHIPPING_PROVIDER_FAIL,
  UPDATE_SHIPPING_PROVIDER,
  UPDATE_SHIPPING_PROVIDER_SUCCESS,
  UPDATE_SHIPPING_PROVIDER_FAIL,
  GET_SHIPPING_PROVIDER,
  GET_SHIPPING_PROVIDER_FAIL,
  GET_SHIPPING_PROVIDER_SUCCESS,
} from "./actionType";

export const listShippingProvider = (options) => ({
  type: LIST_SHIPPING_PROVIDER,
  payload: options,
});

export const listShippingProviderFail = (error) => ({
  type: LIST_SHIPPING_PROVIDER_FAIL,
  payload: error,
});

export const listShippingProviderSuccess = (shippingProviders) => ({
  type: LIST_SHIPPING_PROVIDER_SUCCESS,
  payload: shippingProviders,
});

export const getShippingProvider = (shippingProviderID) => ({
  type: GET_SHIPPING_PROVIDER,
  payload: shippingProviderID,
});

export const getShippingProviderFail = (error) => ({
  type: GET_SHIPPING_PROVIDER_FAIL,
  payload: error,
});

export const getShippingProviderSuccess = (shippingProvider) => ({
  type: GET_SHIPPING_PROVIDER_SUCCESS,
  payload: shippingProvider,
});

export const deleteShippingProvider = (shippingProvider) => ({
  type: DELETE_SHIPPING_PROVIDER,
  payload: shippingProvider,
});

export const deleteShippingProviderSuccess = (shippingProvider) => ({
  type: DELETE_SHIPPING_PROVIDER_SUCCESS,
  payload: shippingProvider,
});

export const deleteShippingProviderFail = (error) => ({
  type: DELETE_SHIPPING_PROVIDER_FAIL,
  payload: error,
});

export const updateShippingProvider = (shippingProvider) => ({
  type: UPDATE_SHIPPING_PROVIDER,
  payload: shippingProvider,
});

export const updateShippingProviderSuccess = (shippingProvider) => ({
  type: UPDATE_SHIPPING_PROVIDER_SUCCESS,
  payload: shippingProvider,
});

export const updateShippingProviderFail = (error) => ({
  type: UPDATE_SHIPPING_PROVIDER_FAIL,
  payload: error,
});

export const addNewShippingProvider = (shippingProvider) => ({
  type: ADD_NEW_SHIPPING_PROVIDER,
  payload: shippingProvider,
});

export const addShippingProviderSuccess = (shippingProvider) => ({
  type: ADD_SHIPPING_PROVIDER_SUCCESS,
  payload: shippingProvider,
});

export const addShippingProviderFail = (error) => ({
  type: ADD_SHIPPING_PROVIDER_FAIL,
  payload: error,
});
