import {
  LIST_TERMINAL,
  DELETE_TERMINAL,
  DELETE_TERMINAL_SUCCESS,
  DELETE_TERMINAL_FAIL,
  ADD_TERMINAL_SUCCESS,
  ADD_TERMINAL_FAIL,
  UPDATE_TERMINAL_SUCCESS,
  UPDATE_TERMINAL_FAIL,
  LIST_TERMINAL_SUCCESS,
  LIST_TERMINAL_FAIL,
  ADD_NEW_TERMINAL,
  UPDATE_TERMINAL,
  GET_TERMINAL,
  GET_TERMINAL_SUCCESS,
  GET_TERMINAL_FAIL,
} from "./actionType";

const INIT_STATE = {
  terminals: [],
  terminal: {},
  error: null,
  success: false,
  loading: false,
  limit: 0,
  page: 0,
  total: 0,
};

const Terminals = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_TERMINAL:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };

    case LIST_TERMINAL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        terminals: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
      };

    case LIST_TERMINAL_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case GET_TERMINAL:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };

    case GET_TERMINAL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        terminal: action.payload,
      };

    case GET_TERMINAL_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case DELETE_TERMINAL:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };

    case DELETE_TERMINAL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        terminals: state.terminals.filter((item) => item.id.toString() !== action.payload.id.toString()),
      };

    case DELETE_TERMINAL_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case ADD_NEW_TERMINAL:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };

    case ADD_TERMINAL_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        terminals: [...state.terminals, action.payload],
        terminal: action.payload,
      };

    case ADD_TERMINAL_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case UPDATE_TERMINAL:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };
    case UPDATE_TERMINAL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        terminals: state.terminals.map((terminal) =>
          terminal.id.toString() === action.payload.id.toString() ? { ...terminal, ...action.payload } : terminal,
        ),
        terminal: action.payload,
      };

    case UPDATE_TERMINAL_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};

export default Terminals;
