import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { Tooltip } from "antd";

import { convertValidDate, convertValidTime, formatVNDCurrency } from "../../../../helpers/format_helper";

import withRouter from "../../../../Components/Common/withRouter";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";

import { listInventoryItem } from "../../../../store/actions";
import { Actions } from "../../../../Components/constants/common";

const InventoryItemsPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Inventory List")} | OptiWarehouse`;
  const dispatch = useDispatch();

  const {
    inventoryItems,
    inventoryItemsLoading,
    inventoryItemsAction,
    inventoryItemsSuccess,
    inventoryItemsError,
    inventoryItemsLimit,
    inventoryItemsPage,
    inventoryItemsTotal,
    inventoryItemsMessage,
  } = useMemoizedSelector((state) => ({
    inventoryItems: state.InventoryItems.inventoryItems,
    inventoryItemsAction: state.InventoryItems.action,
    inventoryItemsMessage: state.InventoryItems.message,
    inventoryItemsLoading: state.InventoryItems.loading,
    inventoryItemsSuccess: state.InventoryItems.success,
    inventoryItemsError: state.InventoryItems.error,
    inventoryItemsLimit: state.InventoryItems.limit,
    inventoryItemsPage: state.InventoryItems.page,
    inventoryItemsTotal: state.InventoryItems.total,
  }));

  const [isExportCSV, setIsExportCSV] = useState(false);

  const headers = [
    "location_id",
    "sku",
    "available",
    "incoming",
    "on_hand",
    "packing",
    "shipping",
    "min_value",
    "max_value",
    "cost",
    "updated_at",
  ];

  return (
    <div className="page-content">
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={inventoryItems} />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="purchase-order-list">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("Inventory List")}</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-3 flex-wrap">
                      <ButtonTheme
                        type="button"
                        className="btn btn-light"
                        icon="ri-file-text-line"
                        onClick={() => setIsExportCSV(true)}
                      >
                        {t("Export")}
                      </ButtonTheme>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <TableContainerCustom
                  filterType={"inventoryItems"}
                  searchPlaceHolder="Search inventory item..."
                  listFilter={[
                    {
                      id: "location_id",
                      type: "selectBox",
                      title: "Branch",
                      remote: true,
                      pathUrlLoad: "inventory/locations",
                    },
                    {
                      id: "variant_id",
                      type: "selectBox",
                      title: "Variant",
                      remote: true,
                      pathUrlLoad: "product/variants",
                    },
                  ]}
                  headers={headers}
                  contents={inventoryItems}
                  limitPage={Number(inventoryItemsLimit)}
                  currentPage={Number(inventoryItemsPage) + 1}
                  totalItems={inventoryItemsTotal}
                  loading={inventoryItemsLoading && inventoryItemsAction === Actions.LIST}
                  scrollHeight="50vh"
                  onCallData={(dataPayload) => {
                    dispatch(listInventoryItem(dataPayload));
                  }}
                  isSettings={true}
                  listHeader={[
                    {
                      title: "Inventory details",
                      key: "detail-variant",
                      children: [
                        {
                          title: t("Location"),
                          dataIndex: "location_id",
                          key: "location_id",
                          render: (value, row, index) => {
                            return (
                              <Tooltip title={value}>
                                <div className="d-block text-truncate">{row?.location_name || value}</div>
                              </Tooltip>
                            );
                          },
                        },
                        {
                          title: t("Sku"),
                          dataIndex: "sku",
                          key: "sku",
                          sorter: true,
                          render: (value, row, index) => {
                            return (
                              <Tooltip title={value}>
                                <Link to={`/products/${row.product_id}`} className="d-block text-truncate link-primary">
                                  {value || "---"}
                                </Link>
                              </Tooltip>
                            );
                          },
                        },
                        {
                          title: t("Available"),
                          dataIndex: "available",
                          key: "available",
                          sorter: true,
                          render: (value, row, index) => {
                            return <>{value || "---"}</>;
                          },
                        },
                        {
                          title: t("Incoming"),
                          dataIndex: "incoming",
                          key: "incoming",
                          sorter: true,
                          render: (value, row, index) => {
                            return <>{value || "---"}</>;
                          },
                        },
                        {
                          title: t("On hand"),
                          dataIndex: "on_hand",
                          key: "on_hand",
                          sorter: true,
                          render: (value, row, index) => {
                            return <>{value || "---"}</>;
                          },
                        },
                        {
                          title: t("Packing"),
                          dataIndex: "packing",
                          key: "packing",
                          sorter: true,
                          render: (value, row, index) => {
                            return <>{value || "---"}</>;
                          },
                        },
                        {
                          title: t("Shipping"),
                          dataIndex: "shipping",
                          key: "shipping",
                          sorter: true,
                          render: (value, row, index) => {
                            return <>{value || "---"}</>;
                          },
                        },
                        {
                          title: t("Min"),
                          dataIndex: "min_value",
                          key: "min_value",
                          sorter: true,
                          render: (value, row, index) => {
                            return <>{value || "---"}</>;
                          },
                        },
                        {
                          title: t("Max"),
                          dataIndex: "max_value",
                          key: "max_value",
                          sorter: true,
                          render: (value, row, index) => {
                            return <>{value || "---"}</>;
                          },
                        },
                        {
                          title: t("Cost"),
                          dataIndex: "cost",
                          key: "cost",
                          sorter: true,
                          render: (value, row, index) => {
                            return <>{formatVNDCurrency(value || 0)}</>;
                          },
                        },
                        {
                          title: t("Updated at"),
                          dataIndex: "updated_at",
                          key: "updated_at",
                          defaultSortOrder: "desc",
                          sorter: true,
                        },
                      ],
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(InventoryItemsPage);
