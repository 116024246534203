import { Fragment, useEffect, useMemo, useState } from "react";
import SimpleTableContainer from "../../../../Components/Common/SimpleTableContainer";
import { Col, Input, Label, Modal, ModalHeader, Row, Table } from "reactstrap";
import { Tooltip } from "antd";
import {
  deepCopy,
  formatVNDCurrency,
  handleReturnQuantityByItemType,
  returnDiscountInfoFieldByType,
} from "../../../../helpers/format_helper";
import { DiscountAmountType, DiscountTypes, EffectiveProduct } from "../../../../Components/constants/common";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../../Components/Common/Loader";
import { useDebounce } from "../../../../Components/Hooks/UseDebounce";
import { variantsAPI } from "../../../../helpers/resource_helper";
import { toast } from "react-toastify";
import InputStep from "../../../../Components/Common/InputStep";
import { useTranslation } from "react-i18next";

const OrderTotalConditionBody = ({
  discount,
  selectedPromotionId,
  order_total,
  order_line_items,
  discountDetails,
  onChangeTempDiscountDetails,
  onChangeSelectedPromotionId,
  selectedLocation,
  selectedPriceGroup,
}) => {
  const { t } = useTranslation();
  const [listVariants, setListVariants] = useState([]);
  const [payload, setPayload] = useState(null);
  const [effectiveCondition, setEffectiveCondition] = useState(null);
  const [tempDiscountDetails, setTempDiscountDetails] = useState([]);

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [valueSearch, setValueSearch] = useState("");
  const debouncedInputValue = useDebounce(valueSearch, 1000);

  const onCheck = (row) => {
    const newDiscountDetails = [...tempDiscountDetails];
    const discountDetailsIndex = newDiscountDetails.findIndex((item) => item.sku === row.sku);
    if (discountDetailsIndex > -1) {
      newDiscountDetails.splice(discountDetailsIndex, 1);
    } else {
      if (selectedQuantity === effectiveCondition.max_quantity) {
        toast.error(`${t("Max quantity is")} ${effectiveCondition.max_quantity}`);
        return;
      }
      const selectedProductPriceGroupIndex = row.prices.findIndex((item) => item.price_group.id === selectedPriceGroup);
      const selectedProductPrice =
        selectedProductPriceGroupIndex > -1
          ? row?.prices?.[selectedProductPriceGroupIndex]?.price
          : row?.prices?.[0]?.price;
      const sale_price = [DiscountTypes.GIFT_BY_ORDER_TOTAL, DiscountTypes.GIFT_BY_PURCHASE_PRODUCT].includes(
        discount.type,
      )
        ? 0
        : selectedProductPrice -
          (effectiveCondition.type === DiscountAmountType.VALUE
            ? effectiveCondition.amount
            : (effectiveCondition.rate * selectedProductPrice) / 100);
      const newItem = {
        unit_price: selectedProductPrice || 0,
        sale_price: sale_price || 0,
        sku: row?.sku || "",
        name: row?.name || "",
        variant_name: row?.name || "",
        location_id: selectedLocation.value || null,
        location_name: selectedLocation.label || null,
        location: selectedLocation || null,
        image_url: row.images?.[0]?.url || "",
        quantity: 1,
        product_id: row.product_id || null,
        variant_id: row.id || null,
        category: row?.category ? { id: row?.category?.id, name: row?.category?.name } : null,
        brand: row?.brand ? { id: row?.brand?.id, name: row?.brand?.name } : null,
      };
      newDiscountDetails.push(newItem);
    }

    onChangeSelectedPromotionId(newDiscountDetails.length > 0 ? discount.id : null);
    onChangeTempDiscountDetails(newDiscountDetails);
  };

  const selectedQuantity = useMemo(
    () => tempDiscountDetails.reduce((total, item) => total + +item.quantity, 0),
    [tempDiscountDetails],
  );

  const onChangeQuantity = (number, productIndex) => {
    const newDiscountDetails = deepCopy(tempDiscountDetails);
    const discountDetailsIndex = newDiscountDetails.findIndex((item, index) => index === productIndex);
    if (discountDetailsIndex > -1) {
      newDiscountDetails[discountDetailsIndex].quantity = number;
    }
    onChangeTempDiscountDetails(newDiscountDetails);
  };

  const getDataScroll = async (query, page) => {
    try {
      const res = await variantsAPI.list({
        limit: 20,
        ...payload,
        ...(page > 0 && { page }),
        ...(query && { query }),
      });
      setPage(Number(res.page));
      setListVariants([...listVariants, ...res.items]);

      if (Number(res.limit) * (Number(res.page) + 1) < res.total) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const conditions = discount[returnDiscountInfoFieldByType(discount.type)];
    const effective_condition = conditions
      .filter((c) => c.amount_from <= order_total)
      .reduce((prev, current) => (prev.amount_from > current.amount_from ? prev : current));
    setEffectiveCondition(effective_condition ? effective_condition : null);
  }, [order_line_items, discount]);

  useEffect(() => {
    if (selectedPromotionId !== discount?.id) {
      setTempDiscountDetails([]);
    } else {
      setTempDiscountDetails(discountDetails);
    }
  }, [selectedPromotionId, discountDetails]);

  useEffect(() => {
    if (typeof effectiveCondition?.for_all_items === "boolean") {
      if (effectiveCondition?.for_all_items) {
        setPayload({});
      } else {
        const listId = effectiveCondition[`${effectiveCondition.item_type.toLowerCase()}`]
          .map((item) => item.id)
          .join(",");
        if (effectiveCondition.item_type === EffectiveProduct.PRODUCTS) {
          const newPayload = {};
          newPayload["product_id"] = listId;
          setPayload(newPayload);
        }
        if (effectiveCondition.item_type === EffectiveProduct.VARIANTS) {
          setListVariants(effectiveCondition.variants);
        }
        if (effectiveCondition.item_type === EffectiveProduct.CATEGORIES) {
          const newPayload = {};
          newPayload["category.id"] = listId;
          setPayload(newPayload);
        }
        if (effectiveCondition.item_type === EffectiveProduct.BRANDS) {
          const newPayload = {};
          newPayload["brand.id"] = listId;
          setPayload(newPayload);
        }
      }
    }
  }, [effectiveCondition]);

  const getData = async (query) => {
    try {
      setLoading(true);
      const res = await variantsAPI.list({
        limit: 20,
        ...(query && { query }),
        ...payload,
      });
      setPage(Number(res.page));
      setListVariants(res.items);
      if (Number(res.limit) * (Number(res.page) + 1) < res.total) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (payload) {
      getData(valueSearch);
    }
  }, [payload]);

  useEffect(() => {
    if (typeof effectiveCondition?.for_all_items === "boolean") {
      if (!effectiveCondition?.for_all_items) {
        if (effectiveCondition.item_type === EffectiveProduct.VARIANTS) {
          const newList = effectiveCondition.variants.filter(
            (item) => item.sku.includes(debouncedInputValue) || item.name.includes(debouncedInputValue),
          );
          setListVariants(newList);
        }
      }
      if (payload) {
        getData(debouncedInputValue);
      }
    }
  }, [debouncedInputValue]);

  return (
    <Fragment>
      <Row>
        <Col lg={4}>
          <div className="bg-light p-2">{t("Product")}</div>
          <div>
            <div
              id="scrollableDivSearch"
              style={{
                height: "500px",
                overflow: "auto",
              }}
            >
              <div className="p-2 border">
                <Input
                  value={valueSearch}
                  onChange={(e) => {
                    setValueSearch(e.target.value);
                  }}
                  placeholder={t("Search...")}
                />
              </div>
              {loading ? (
                <Loader />
              ) : (
                <InfiniteScroll
                  dataLength={listVariants.length}
                  next={() => {
                    getDataScroll(valueSearch, page + 1);
                  }}
                  hasMore={hasMore}
                  scrollableTarget="scrollableDivSearch"
                >
                  {listVariants.length > 0 ? (
                    listVariants.map((item, index) => {
                      const selectedProductPriceGroupIndex = item.prices.findIndex(
                        (item) => item.price_group.id === selectedPriceGroup,
                      );
                      const price =
                        selectedProductPriceGroupIndex > -1
                          ? item?.prices?.[selectedProductPriceGroupIndex]?.price
                          : item?.prices?.[0]?.price;
                      const sale_price = [
                        DiscountTypes.PRODUCT_BY_ORDER_TOTAL,
                        DiscountTypes.BY_PURCHASE_PRODUCT,
                      ].includes(discount.type)
                        ? price -
                          (effectiveCondition.type === DiscountAmountType.VALUE
                            ? effectiveCondition.amount
                            : (effectiveCondition.rate * price) / 100)
                        : 0;
                      return (
                        <div key={index} className="d-flex border p-2">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            value={""}
                            onClick={(e) => {
                              onCheck(item);
                            }}
                            onChange={(e) => {}}
                            id={`sale-off-product-${index}`}
                            checked={tempDiscountDetails.findIndex((subItem) => subItem.sku === item.sku) > -1}
                          />
                          <Label className="form-check-label ms-2 w-100 hstack" for={`sale-off-product-${index}`}>
                            <div className="flex-grow-1">
                              <p>{t(item.name)}</p>
                              <p className="text-muted">{item.sku}</p>
                            </div>
                            {[DiscountTypes.PRODUCT_BY_ORDER_TOTAL, DiscountTypes.BY_PURCHASE_PRODUCT].includes(
                              discount.type,
                            ) && (
                              <div className="flex-shrink-0 text-end">
                                <p>{formatVNDCurrency(sale_price)}</p>
                                <p className="text-decoration-line-through text-muted">{formatVNDCurrency(price)}</p>
                              </div>
                            )}
                          </Label>
                        </div>
                      );
                    })
                  ) : (
                    <div className="border p-2">
                      <p>{t("Sorry! No Result Found")}</p>
                    </div>
                  )}
                </InfiniteScroll>
              )}
            </div>
          </div>
        </Col>
        <Col lg={8}>
          <div className="bg-light p-2 d-flex justify-content-between">
            <span>{t("Selected product")}</span>
            <span>
              {t("Selected quantity")}: {selectedQuantity}/{effectiveCondition?.max_quantity}
            </span>
          </div>
          <div>
            <div
              id="scrollableDivSearch"
              style={{
                height: "500px",
                overflow: "auto",
              }}
            >
              {tempDiscountDetails.map((item, index) => {
                return (
                  <div key={index} className="hstack gap-2 p-2 border">
                    <div className="flex-grow-1">
                      <p>{t(item.name)}</p>
                      <p className="text-muted">{item.sku}</p>
                    </div>
                    {[DiscountTypes.PRODUCT_BY_ORDER_TOTAL, DiscountTypes.BY_PURCHASE_PRODUCT].includes(
                      discount.type,
                    ) && (
                      <div className="flex-shrink-0 text-end me-2">
                        <p className="mb-0">{formatVNDCurrency(item.sale_price)}</p>
                        <p className="text-decoration-line-through text-muted mb-0">
                          {formatVNDCurrency(item.unit_price)}
                        </p>
                      </div>
                    )}
                    <InputStep
                      onChangeQuantity={onChangeQuantity}
                      index={index}
                      quantity={item.quantity}
                      max={
                        effectiveCondition?.max_quantity -
                        tempDiscountDetails
                          .filter((subItem) => subItem.sku !== item.sku)
                          .reduce((total, subItem) => total + +subItem.quantity, 0)
                      }
                    />
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        onCheck(item);
                      }}
                    >
                      <i className="ri-close-fill text-muted fs-22"></i>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};
export default OrderTotalConditionBody;
