import { GET_ROLE_PERMISSION, GET_ROLE_PERMISSION_FAIL, GET_ROLE_PERMISSION_SUCCESS } from "./actionTypes";

const initialState = {
  permission: {},
  loadingPermission: false,
};

const Permission = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLE_PERMISSION:
      state = {
        ...state,
        loadingPermission: true,
      };
      break;
    case GET_ROLE_PERMISSION_SUCCESS:
      state = {
        ...state,
        loadingPermission: false,
        permission: action.payload,
      };
      break;
    case GET_ROLE_PERMISSION_FAIL:
      state = { ...state, loadingPermission: false, error: action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Permission;
