import {
  LIST_ACCOUNT,
  LIST_ACCOUNT_FAIL,
  LIST_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAIL,
  ADD_NEW_ACCOUNT,
  ADD_ACCOUNT_SUCCESS,
  ADD_ACCOUNT_FAIL,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAIL,
  GET_ACCOUNT,
  GET_ACCOUNT_FAIL,
  GET_ACCOUNT_SUCCESS,
  RESET_ACCOUNT_FLAG,
} from "./actionType";

export const listAccount = (options) => ({
  type: LIST_ACCOUNT,
  payload: options,
});

export const listAccountFail = (error) => ({
  type: LIST_ACCOUNT_FAIL,
  payload: error,
});

export const listAccountSuccess = (accounts) => ({
  type: LIST_ACCOUNT_SUCCESS,
  payload: accounts,
});

export const getAccount = (accountID) => ({
  type: GET_ACCOUNT,
  payload: accountID,
});

export const getAccountFail = (error) => ({
  type: GET_ACCOUNT_FAIL,
  payload: error,
});

export const getAccountSuccess = (account) => ({
  type: GET_ACCOUNT_SUCCESS,
  payload: account,
});

export const deleteAccount = (account, handleAfterSubmit = () => {}) => ({
  type: DELETE_ACCOUNT,
  payload: { account, handleAfterSubmit },
});

export const deleteAccountSuccess = (account) => ({
  type: DELETE_ACCOUNT_SUCCESS,
  payload: account,
});

export const deleteAccountFail = (error) => ({
  type: DELETE_ACCOUNT_FAIL,
  payload: error,
});

export const updateAccount = (account, handleAfterSubmit = () => {}) => ({
  type: UPDATE_ACCOUNT,
  payload: { account, handleAfterSubmit },
});

export const updateAccountSuccess = (account) => ({
  type: UPDATE_ACCOUNT_SUCCESS,
  payload: account,
});

export const updateAccountFail = (error) => ({
  type: UPDATE_ACCOUNT_FAIL,
  payload: error,
});

export const addNewAccount = (account, handleAfterSubmit = () => {}) => ({
  type: ADD_NEW_ACCOUNT,
  payload: { account, handleAfterSubmit },
});

export const addAccountSuccess = (account) => ({
  type: ADD_ACCOUNT_SUCCESS,
  payload: account,
});

export const addAccountFail = (error) => ({
  type: ADD_ACCOUNT_FAIL,
  payload: error,
});

export const resetAccountFlag = () => ({
  type: RESET_ACCOUNT_FLAG,
});
