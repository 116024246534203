import { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

import { isEmpty } from "lodash";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import { formatDate, formatFromNow } from "../../../../helpers/format_helper";

import withRouter from "../../../../Components/Common/withRouter";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import AddEditSaleChannelModal from "./AddEditSaleChannelModal";
import Loader from "../../../../Components/Common/Loader";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import ListContainerCustom from "../../../../Components/Common/ListContainerCustom";

import { deleteSaleChannel, listSaleChannel } from "../../../../store/actions";
import ImageCustom from "../../../../Components/Common/ImageCustom";
import LottieIcon from "../../../../Components/Common/LottieIcon";

const SaleChannelPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  const dispatch = useDispatch();
  document.title = `${t("Sale Channel List")} | OptiWarehouse`;

  useEffect(() => {
    dispatch(listSaleChannel({ limit: 20 }));
  }, [dispatch]);

  const {
    saleChannels,
    saleChannelsLoading,
    saleChannelsSuccess,
    saleChannelsError,
    saleChannelsLimit,
    saleChannelsPage,
    saleChannelsTotal,
    saleChannelsMessage,
  } = useMemoizedSelector((state) => ({
    saleChannels: state.SaleChannels.saleChannels,
    saleChannelsMessage: state.SaleChannels.message,
    saleChannelsLoading: state.SaleChannels.loading,
    saleChannelsSuccess: state.SaleChannels.success,
    saleChannelsError: state.SaleChannels.error,
    saleChannelsLimit: state.SaleChannels.limit,
    saleChannelsPage: state.SaleChannels.page,
    saleChannelsTotal: state.SaleChannels.total,
  }));

  const [showEditAddSaleChannelModal, setShowEditAddSaleChannelModal] = useState(false);
  const toggleEditAddSaleChannelModal = () => {
    if (!saleChannelsLoading) {
      setShowEditAddSaleChannelModal(false);
      setSelectedSaleChannel(null);
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [selectedSaleChannel, setSelectedSaleChannel] = useState(null);

  const handleClickEdit = (data) => {
    setShowEditAddSaleChannelModal(true);
    setSelectedSaleChannel(data);
  };
  const handleClickDelete = (data) => {
    setSelectedSaleChannel(data);
    setDeleteModal(true);
  };
  const handleDeleteSaleChannel = () => {
    dispatch(deleteSaleChannel(selectedSaleChannel));
  };

  useEffect(() => {
    if (
      !saleChannelsLoading &&
      !isEmpty(selectedSaleChannel) &&
      deleteModal &&
      saleChannelsMessage === "Delete sale channel success"
    ) {
      setSelectedSaleChannel(null);
      setDeleteModal(false);
    }
  }, [saleChannelsLoading]);

  return (
    <div className="page-content">
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={saleChannels} />
      <AddEditSaleChannelModal
        t={t}
        isOpen={showEditAddSaleChannelModal}
        toggle={toggleEditAddSaleChannelModal}
        saleChannel={selectedSaleChannel}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteSaleChannel}
        onCloseClick={() => {
          setDeleteModal(false);
          setSelectedSaleChannel(null);
        }}
        loading={saleChannelsLoading}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="sale-channel-list">
              <CardHeader>
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("Sale Channel List")}</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-3 flex-wrap">
                      <ButtonTheme
                        type="button"
                        className="btn btn-success add-btn me-2"
                        id="create-btn"
                        onClick={() => {
                          setShowEditAddSaleChannelModal(true);
                        }}
                      >
                        <i className="ri-add-line align-bottom me-1"></i>
                        {t("Add Sale Channel")}
                      </ButtonTheme>
                      <ButtonTheme type="button" className="btn btn-info" onClick={() => setIsExportCSV(true)}>
                        <i className="ri-file-download-line align-bottom me-1"></i>
                        {t("Export")}
                      </ButtonTheme>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <ListContainerCustom
                  filterType={"saleChannels"}
                  searchPlaceHolder="Search sale channel..."
                  listFilter={[]}
                  limitPage={Number(saleChannelsLimit)}
                  currentPage={Number(saleChannelsPage) + 1}
                  totalItems={saleChannelsTotal}
                  loading={saleChannelsLoading}
                  showPagination={true}
                  scrollHeight="50vh"
                  onCallData={(dataPayload) => {
                    dispatch(listSaleChannel(dataPayload));
                  }}
                >
                  <Row className="mt-4">
                    {saleChannels.length === 0 ? (
                      !saleChannelsLoading ? (
                        <div className="py-4 mt-4 text-center">
                          <LottieIcon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            style={{ width: "72px", height: "72px" }}
                          />

                          <h5 className="mt-4">{t("Sorry! No Result Found")}</h5>
                        </div>
                      ) : (
                        <Loader />
                      )
                    ) : (
                      saleChannels.map((item, key) => (
                        <Fragment key={key}>
                          <Col xl={3} lg={6} className="project-card">
                            <Card className="card-animate card-height-100 bg-light">
                              <CardBody>
                                <div className="d-flex flex-column h-100">
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <p className="text-muted mb-4">{formatFromNow(item.updated_at)}</p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <div className="d-flex gap-1 align-items-center">
                                        <UncontrolledDropdown direction="start">
                                          <DropdownToggle
                                            tag="button"
                                            className="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15"
                                          >
                                            <FeatherIcon icon="more-horizontal" className="icon-sm" />
                                          </DropdownToggle>

                                          <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem
                                              onClick={() => {
                                                handleClickEdit(item);
                                              }}
                                            >
                                              <i className="ri-pencil-fill align-bottom me-2 text-primary"></i>{" "}
                                              {t("Edit")}
                                            </DropdownItem>
                                            <div className="dropdown-divider"></div>
                                            <DropdownItem
                                              onClick={() => {
                                                handleClickDelete(item);
                                              }}
                                            >
                                              <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>{" "}
                                              {t("Delete")}
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex mb-2">
                                    <div className="flex-shrink-0 me-3">
                                      <ImageCustom image={item.image?.url} name={item?.name} avatarSize={"avatar-sm"} />
                                    </div>
                                    <div className="flex-grow-1">
                                      <h5 className="mb-1 fs-15">
                                        <Link to="#" className="text-dark">
                                          {item.name}
                                        </Link>
                                      </h5>
                                      <p className="text-muted text-truncate-two-lines mb-3">{item.type}</p>
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                              <CardFooter className="bg-transparent bsaleChannel-top-dashed py-2">
                                <div className="d-flex align-items-center">
                                  <div className="flex-grow-1"></div>
                                  <div className="flex-shrink-0">
                                    <div className="text-muted">
                                      <i className="ri-calendar-event-fill me-1 align-bottom"></i>{" "}
                                      {formatDate(item.updated_at)}
                                    </div>
                                  </div>
                                </div>
                              </CardFooter>
                            </Card>
                          </Col>
                        </Fragment>
                      ))
                    )}
                  </Row>
                </ListContainerCustom>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(SaleChannelPage);
