import { Fragment } from "react";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";

import { DiscountTypeOptions } from "../../../../Components/constants/common";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";

const AddDiscountModal = ({ isOpen, toggle }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={toggle} scrollable centered size="lg">
        <ModalHeader toggle={toggle}>{t("Choose discount type")}</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-wrap justify-content-around gap-3">
            {DiscountTypeOptions.map((item, index) => (
              <Fragment key={index}>
                <Tooltip title={t(item.name)}>
                  <ButtonTheme
                    className="btn btn-outline-primary"
                    onClick={() => {
                      navigate(`/discounts/new?type=${item.id}`);
                    }}
                  >
                    <div className="hstack">
                      {/* <div className="fs-36">
                    <i className={`${icon}`}></i>
                  </div> */}
                      <div className="mx-2" style={{ width: "150px" }}>
                        <p className="text-truncate m-0">{t(item.name)}</p>
                      </div>
                    </div>
                  </ButtonTheme>
                </Tooltip>
              </Fragment>
            ))}
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AddDiscountModal;
