import { Actions } from "../../../../Components/constants/common";
import {
  LIST_TRANSACTION,
  ADD_TRANSACTION_SUCCESS,
  ADD_TRANSACTION_FAIL,
  CANCEL_TRANSACTION_SUCCESS,
  CANCEL_TRANSACTION_FAIL,
  LIST_TRANSACTION_SUCCESS,
  LIST_TRANSACTION_FAIL,
  ADD_NEW_TRANSACTION,
  CANCEL_TRANSACTION,
  GET_TRANSACTION,
  GET_TRANSACTION_SUCCESS,
  GET_TRANSACTION_FAIL,
} from "./actionType";

const INIT_STATE = {
  transactions: [],
  transaction: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const Transactions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_TRANSACTION:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_TRANSACTION_SUCCESS:
      return {
        ...state,
        message: "Get transactions success",
        loading: false,
        success: true,
        transactions: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_TRANSACTION_FAIL:
      return {
        ...state,
        message: "Get transactions failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_TRANSACTION:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_TRANSACTION_SUCCESS:
      return {
        ...state,
        message: "Get transaction success",
        loading: false,
        success: true,
        transaction: action.payload,
        action: Actions.EMPTY,
      };

    case GET_TRANSACTION_FAIL:
      return {
        ...state,
        message: "Get transaction failed",
        loading: false,
        success: false,
        transaction: {},
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_NEW_TRANSACTION:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_TRANSACTION_SUCCESS:
      return {
        ...state,
        message: "Add transaction success",
        success: true,
        loading: false,
        transactions: [action.payload, ...state.transactions],
        action: Actions.EMPTY,
      };

    case ADD_TRANSACTION_FAIL:
      return {
        ...state,
        message: "Add transaction failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case CANCEL_TRANSACTION:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.CANCEL,
      };
    case CANCEL_TRANSACTION_SUCCESS:
      return {
        ...state,
        message: "Cancel transaction success",
        loading: false,
        success: true,
        transactions: state.transactions.map((transaction) =>
          transaction.id.toString() === action.payload.toString()
            ? { ...transaction, status: "CANCELLED" }
            : transaction,
        ),
        action: Actions.EMPTY,
      };

    case CANCEL_TRANSACTION_FAIL:
      return {
        ...state,
        message: "Cancel transaction failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };
    default:
      return { ...state };
  }
};

export default Transactions;
