import {
  LIST_PACKAGE,
  DELETE_PACKAGE,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_FAIL,
  ADD_NEW_PACKAGE,
  ADD_PACKAGE_SUCCESS,
  ADD_PACKAGE_FAIL,
  UPDATE_PACKAGE,
  UPDATE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_FAIL,
  LIST_PACKAGE_FAIL,
  LIST_PACKAGE_SUCCESS,
  GET_PACKAGE,
  GET_PACKAGE_FAIL,
  GET_PACKAGE_SUCCESS,
  RESET_PACKAGE_FLAG,
} from "./actionType";

export const listPackage = (options) => ({
  type: LIST_PACKAGE,
  payload: options,
});

export const listPackageFail = (error) => ({
  type: LIST_PACKAGE_FAIL,
  payload: error,
});

export const listPackageSuccess = (packages) => ({
  type: LIST_PACKAGE_SUCCESS,
  payload: packages,
});

export const getPackage = () => ({
  type: GET_PACKAGE,
});

export const getPackageFail = (error) => ({
  type: GET_PACKAGE_FAIL,
  payload: error,
});

export const getPackageSuccess = (data) => ({
  type: GET_PACKAGE_SUCCESS,
  payload: data,
});

export const deletePackage = (data) => ({
  type: DELETE_PACKAGE,
  payload: data,
});

export const deletePackageSuccess = (data) => ({
  type: DELETE_PACKAGE_SUCCESS,
  payload: data,
});

export const deletePackageFail = (error) => ({
  type: DELETE_PACKAGE_FAIL,
  payload: error,
});

export const updatePackage = (data) => ({
  type: UPDATE_PACKAGE,
  payload: data,
});

export const updatePackageSuccess = (data) => ({
  type: UPDATE_PACKAGE_SUCCESS,
  payload: data,
});

export const updatePackageFail = (error) => ({
  type: UPDATE_PACKAGE_FAIL,
  payload: error,
});

export const addNewPackage = (data) => ({
  type: ADD_NEW_PACKAGE,
  payload: data,
});

export const addPackageSuccess = (data) => ({
  type: ADD_PACKAGE_SUCCESS,
  payload: data,
});

export const addPackageFail = (error) => ({
  type: ADD_PACKAGE_FAIL,
  payload: error,
});

export const resetPackageFlag = () => ({
  type: RESET_PACKAGE_FLAG,
});
