import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Col, Input, Row, Table } from "reactstrap";
import ImageCustom from "../../../../../../Components/Common/ImageCustom";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";
import InputStep from "../../../../../../Components/Common/InputStep";
import { deepCopy, formatVNDCurrency } from "../../../../../../helpers/format_helper";
import ProductsSearchDropDown from "../../../../../../Components/Common/ProductsSearchDropDown";
import NotFound from "../../../../../../Components/Common/NotFound";
import ButtonTheme from "../../../../../../Components/Common/ButtonTheme";
import { toast } from "react-toastify";

const StockItemsCard = ({
  values,
  stockLineItems = [],
  onChangeStockRelocate,
  isEdit = true,
  onDeleteFee,
  toggleOnFee,
}) => {
  const { t } = useTranslation();

  const total_quantity = values.stock_line_items.reduce(
    (total, item) => ((item?.quantity || 0) > 0 ? total + 1 : total),
    0,
  );

  const total_received_quantity = values.stock_line_items.reduce(
    (total, item) => ((item?.received_quantity || 0) > 0 ? total + 1 : total),
    0,
  );
  const total_return_quantity = values.stock_line_items.reduce(
    (total, item) => ((item?.return_quantity || 0) > 0 ? total + 1 : total),
    0,
  );

  const amountFeeTotal = (values?.other_fees || []).reduce((total, item) => total + +item.amount, 0);

  const [showDropDownProductMenu, setShowDropDownProductMenu] = useState(false);

  const handleChangeQuantity = (number, dataIndex) => {
    let newData = deepCopy(stockLineItems);
    let orderItemIndex = newData.findIndex((item, index) => index === dataIndex);
    newData[orderItemIndex].quantity = number;
    onChangeStockRelocate(newData);
  };

  const toggleOnDropDownProductMenu = () => {
    setShowDropDownProductMenu(true);
  };
  const toggleOffDropDownProductMenu = () => {
    setShowDropDownProductMenu(false);
  };

  const addProduct = (data) => {
    if (!values?.source) {
      toast.error(t("Please select delivered branch first!!!"));
      return;
    }
    const check = stockLineItems.find((item) => item.sku === data.sku);
    if (check) {
      alert(t("Product has already selected"));
      return;
    }
    let newData = deepCopy(stockLineItems);
    const unit_price = (data?.prices || [])?.[0]?.price || 0;
    const newItem = {
      quantity: 1,
      sku: data.sku,
      product_id: data?.product_id || null,
      inventories: data?.inventories || [],
      unit_price: unit_price,
      variant_id: data.id,
      variant_name: data.name,
      image_url: data.images?.[0]?.url,
      name: data.name,
    };
    newData.push(newItem);
    onChangeStockRelocate(newData);
  };

  const removeProduct = (index) => {
    const newData = stockLineItems.filter((item, i) => index !== i);
    onChangeStockRelocate(newData);
  };

  return (
    <Card>
      <CardHeader>
        <div className="d-flex align-items-center">
          <h5 className="card-title flex-grow-1 mb-0">{t("Product Info")}</h5>
        </div>
      </CardHeader>
      <CardBody>
        {isEdit && (
          <div className="mb-3">
            <ProductsSearchDropDown
              placeholder="Search by name, sku, barcode,..."
              onClickItem={addProduct}
              isOpen={showDropDownProductMenu}
              toggleOff={toggleOffDropDownProductMenu}
              toggleOn={toggleOnDropDownProductMenu}
            />
          </div>
        )}
        <div className={"table-responsive"} style={{ maxHeight: "600px" }}>
          <Table hover className={"table table-nowrap align-middle mb-3"}>
            <thead className={"table-light text-muted"}>
              <tr className={""}>
                <th>{t("Items No.")}</th>
                <th>{t("Image")}</th>
                <th>{t("Name")}</th>
                {isEdit && <th className="text-center">{t("Delivered branch inventory")}</th>}
                <th>{t("Delivered quantity")}</th>
                {!isEdit && <th>{t("Received quantity")}</th>}
                <th>{t("Price")}</th>
                <th>{t("Delivered total")}</th>
                {!isEdit && <th>{t("Received total")}</th>}
                {isEdit && <th></th>}
              </tr>
            </thead>
            <tbody>
              {isEdit ? (
                stockLineItems.length === 0 ? (
                  <tr>
                    <td colSpan={1000}>
                      <NotFound />
                    </td>
                  </tr>
                ) : (
                  stockLineItems.map((product, index) => {
                    const delivered_branch_inventory =
                      (product?.inventories || []).find((item) => item.location_id === values?.source?.id)?.available ||
                      0;
                    return (
                      <tr
                        style={{ verticalAlign: "middle" }}
                        key={index}
                        onClick={() => {
                          // onRowClick(row);
                        }}
                      >
                        <td>{stockLineItems.length - index}</td>
                        <td>
                          <ImageCustom image={product.image_url} name={product.name} avatarSize={"avatar-sm"} />
                        </td>
                        <td>
                          <div className="d-flex flex-column justify-content-center">
                            <h5 className="fs-15">
                              <Tooltip title={product.name}>
                                <Link
                                  id={`order-items-product-name-${index}`}
                                  to={{ pathname: `/products/${product.product_id}`, state: { sku: product.sku } }}
                                  className="d-inline-block text-truncate"
                                  style={{ maxWidth: "400px" }}
                                  target="_blank"
                                >
                                  {product.name}
                                </Link>
                              </Tooltip>
                            </h5>
                            <p className="text-muted fw-medium mb-1">Sku: {product.sku}</p>
                          </div>
                        </td>
                        <td className="text-center">{delivered_branch_inventory}</td>
                        <td className="text-center">
                          <InputStep
                            index={index}
                            onChangeQuantity={handleChangeQuantity}
                            quantity={product?.quantity || 0}
                          />
                        </td>
                        <td className="text-end">{formatVNDCurrency(product?.unit_price || 0)}</td>
                        <td className="text-end">
                          {formatVNDCurrency((product?.unit_price || 0) * (product?.quantity || 0))}
                        </td>
                        <td>
                          <ButtonTheme
                            type="button"
                            className="btn btn-outline-danger btn-icon waves-effect waves-light"
                            onClick={() => removeProduct(index)}
                          >
                            <i className="ri-delete-bin-5-line"></i>
                          </ButtonTheme>
                        </td>
                      </tr>
                    );
                  })
                )
              ) : (
                stockLineItems.map((product, index) => {
                  return (
                    <tr
                      style={{ verticalAlign: "middle" }}
                      key={index}
                      onClick={() => {
                        // onRowClick(row);
                      }}
                    >
                      <td>{stockLineItems.length - index}</td>

                      <td>
                        <ImageCustom image={product.image_url} name={product.name} avatarSize={"avatar-sm"} />
                      </td>
                      <td>
                        <div className="d-flex flex-column justify-content-center">
                          <h5 className="fs-15">
                            <Tooltip title={product.name}>
                              <Link
                                id={`order-items-product-name-${index}`}
                                to={{ pathname: `/products/${product.product_id}`, state: { sku: product.sku } }}
                                className="d-inline-block text-truncate"
                                style={{ maxWidth: "400px" }}
                                target="_blank"
                              >
                                {product.name}
                              </Link>
                            </Tooltip>
                          </h5>
                          <p className="text-muted fw-medium mb-1">Sku: {product.sku}</p>
                        </div>
                      </td>
                      <td className="text-center">{product?.quantity || 0}</td>
                      <td className="text-center">{product?.received_quantity || 0}</td>
                      <td className="text-end">{formatVNDCurrency(product?.unit_price || 0)}</td>
                      <td className="text-end">
                        {formatVNDCurrency(product?.unit_price || 0 * product?.quantity || 0)}
                      </td>
                      <td className="text-end">
                        {formatVNDCurrency(product?.received_quantity || 0 * product?.quantity || 0)}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
        <Row>
          <Col lg={6}></Col>
          <Col lg={6}>
            <table className="table table-borderless mb-0">
              <tbody>
                <tr>
                  <td>{`${t("Delivered quantity")} (${total_quantity} ${t(
                    `product${total_quantity > 1 ? "s" : ""}`,
                  )})`}</td>
                  <td className="text-end">{values.delivered_quantity}</td>
                </tr>
                <tr>
                  <td>{t("Delivered total")}</td>
                  <td className="text-end">{formatVNDCurrency(values.delivered_total)}</td>
                </tr>
                {values.received_quantity > 0 && (
                  <Fragment>
                    <tr className="border-top border-top-dashed">
                      <td>{`${t("Received quantity")} (${total_received_quantity} ${t(
                        `product${total_received_quantity > 1 ? "s" : ""}`,
                      )})`}</td>
                      <td className="text-end">{values.received_quantity}</td>
                    </tr>
                    <tr>
                      <td>{t("Received total")}</td>
                      <td className="text-end">{formatVNDCurrency(values.received_total)}</td>
                    </tr>
                  </Fragment>
                )}
                {values.return_quantity > 0 && (
                  <Fragment>
                    <tr className="border-top border-top-dashed">
                      <td>{`${t("Received quantity")} (${total_return_quantity} ${t(
                        `product${total_return_quantity > 1 ? "s" : ""}`,
                      )})`}</td>
                      <td className="text-end">{values.received_quantity}</td>
                    </tr>
                    <tr>
                      <td>{t("Received total")}</td>
                      <td className="text-end">{formatVNDCurrency(values.received_total)}</td>
                    </tr>
                  </Fragment>
                )}
                <tr className="border-top border-top-dashed">
                  <td>{t("Other fees")}</td>
                  {amountFeeTotal > 0 && <td className="text-end">{formatVNDCurrency(amountFeeTotal)}</td>}
                </tr>
                {(values?.other_fees || []).map((item, key) => (
                  <tr key={key}>
                    <td className="hstack gap-3">
                      {isEdit && (
                        <i
                          className="align-bottom text-danger ri-close-line cursor-pointer me-2 fs-16"
                          onClick={() => {
                            onDeleteFee(item);
                          }}
                        ></i>
                      )}
                      <span className={`text-muted ${isEdit ? "" : "ms-3"}`}>{item.name}</span>
                    </td>
                    <td className="text-end text-muted">{formatVNDCurrency(item.amount)}</td>
                  </tr>
                ))}
                {isEdit && (
                  <tr>
                    <td>
                      <ButtonTheme className="btn btn-ghost-primary p-0 border-0" onClick={toggleOnFee}>
                        <i className="ri-add-circle-line align-bottom me-2"></i>
                        {t("Add fee")}
                      </ButtonTheme>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default StockItemsCard;
