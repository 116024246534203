import { Fragment, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Col, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

import { Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import useMemoizedSelector from "../../../../../Components/Hooks/useMemoizedSelector";
import {
  addNewTransactionVoucher,
  getTransactionVoucher,
  updateTransactionVoucher,
} from "../../../../../store/actions";
import Loader from "../../../../../Components/Common/Loader";
import { Actions, TransactionVoucherTypeOptions } from "../../../../../Components/constants/common";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import InputTheme from "../../../../../Components/Common/InputTheme";
import SelectTheme from "../../../../../Components/Common/SelectTheme";
import ScrollToError from "../../../../../Components/Common/ScrollToError";

const TransactionVoucherModal = ({ transactionVoucherID = "", isOpen, toggle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formRef = useRef();

  const {
    transactionVoucher,
    transactionVouchersLoading,
    transactionVouchersAction,
    transactionVoucherMessage,
    transactionVouchersError,
  } = useMemoizedSelector((state) => ({
    transactionVoucher: state.TransactionVouchers.transactionVoucher,
    transactionVouchersLoading: state.TransactionVouchers.loading,
    transactionVouchersAction: state.TransactionVouchers.action,
    transactionVouchersError: state.TransactionVouchers.error,
    transactionVoucherMessage: state.TransactionVouchers.message,
  }));

  const validation = {
    enableReinitialize: true,
    initialValues: {
      name: transactionVoucher?.name || "",
      type: transactionVoucher?.type || "",
      description: transactionVoucher?.description || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Please enter transaction voucher name")),
      type: Yup.string().required(t("Please select transaction voucher type")),
    }),
    onSubmit: (values) => {
      if (transactionVoucherID) {
        dispatch(updateTransactionVoucher({ ...transactionVoucher, ...values }, toggle));
      } else {
        dispatch(addNewTransactionVoucher(values, toggle));
      }
    },
  };

  const handleToggle = () => {
    if (!transactionVouchersLoading) {
      toggle();
    }
  };

  useEffect(() => {
    transactionVoucherID && isOpen && dispatch(getTransactionVoucher(transactionVoucherID));
  }, [isOpen]);

  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={handleToggle} centered scrollable size="lg">
        <ModalHeader toggle={handleToggle}>
          {t(transactionVoucherID ? "View transaction voucher" : "Add transaction voucher")}
        </ModalHeader>
        <ModalBody>
          {transactionVouchersAction === Actions.GET ? (
            <Loader />
          ) : (
            <Formik
              enableReinitialize={true}
              initialValues={validation.initialValues}
              validationSchema={validation.validationSchema}
              onSubmit={validation.onSubmit}
              innerRef={formRef}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <ScrollToError />
                  <Row>
                    <Col lg={12}>
                      <InputTheme label={"Name"} name="name" placeholder={"Enter name"} />
                    </Col>
                    <Col lg={12}>
                      <SelectTheme
                        label={"Type"}
                        name="type"
                        placeholder={"Select type"}
                        options={TransactionVoucherTypeOptions}
                        value={values.type || ""}
                      />
                    </Col>
                    <Col lg={12}>
                      <InputTheme label={"Description"} name="description" placeholder={"Enter description"} />
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          )}
        </ModalBody>
        <ModalFooter className="border-0">
          <ButtonTheme type="button" className="btn btn-light w-sm" onClick={handleToggle}>
            {t("Close")}
          </ButtonTheme>
          <ButtonTheme
            type="button"
            className="btn btn-primary w-sm"
            onClick={() => formRef.current.handleSubmit()}
            loading={
              (transactionVouchersAction === Actions.UPDATE || transactionVouchersAction === Actions.CREATE) &&
              transactionVouchersLoading
            }
            disabled={
              (transactionVouchersAction === Actions.UPDATE || transactionVouchersAction === Actions.CREATE) &&
              transactionVouchersLoading
            }
          >
            {t(transactionVoucherID ? "Update" : "Create")}
          </ButtonTheme>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default TransactionVoucherModal;
