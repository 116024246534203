import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Container, Row, CardHeader, Col, CardBody } from "reactstrap";
import "nouislider/distribute/nouislider.css";

import { convertValidDate, convertValidTime } from "../../../../helpers/format_helper";

import withRouter from "../../../../Components/Common/withRouter";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import { Actions } from "../../../../Components/constants/common";

import { deleteVariant, listVariant, setConfigTour } from "../../../../store/actions";
import PopoverColumn from "./PopoverColumn";
import ImageCustom from "../../../../Components/Common/ImageCustom";
import ActionDropdown from "../../../../Components/Common/ActionDropdown";
import { Tooltip } from "antd";

const VariantsPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Variant List")} | OptiWarehouse`;
  const dispatch = useDispatch();

  const {
    variants,
    variantsLoading,
    variantsAction,
    variantsLimit,
    variantsSuccess,
    variantsPage,
    variantsTotal,
    variantsMessage,
  } = useMemoizedSelector((state) => ({
    variants: state.Variants.variants,
    variantsMessage: state.Variants.message,
    variantsAction: state.Variants.action,
    variantsLimit: state.Variants.limit,
    variantsPage: state.Variants.page,
    variantsTotal: state.Variants.total,
    variantsLoading: state.Variants.loading,
    variantsSuccess: state.Variants.success,
    variantsError: state.Variants.error,
  }));

  const [variant, setVariant] = useState(null);

  const [deleteModal, setDeleteModal] = useState(false);
  const [isExportCSV, setIsExportCSV] = useState(false);

  const onClickDelete = (variant) => {
    setVariant(variant);
    setDeleteModal(true);
  };

  const handleDeleteVariant = () => {
    if (variant) {
      dispatch(deleteVariant(variant));
    }
  };

  const handleCloseDeleteModal = () => {
    if (!variantsLoading) {
      setDeleteModal(false);
      setVariant(null);
    }
  };

  const headers = ["name", "images", "sku", "category", "brand", "available", "updated_at", "created_at", "action"];

  useEffect(() => {
    if (!variantsLoading && variantsSuccess && deleteModal && variantsMessage === "Delete variant success") {
      handleCloseDeleteModal();
    }
  }, [variantsLoading, variantsSuccess, variantsMessage]);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const steps = [
    {
      title: t("Upload File"),
      description: t("Put your files here."),
      target: () => ref1.current,
    },
  ];

  useEffect(() => {
    dispatch(
      setConfigTour({
        typeTour: "variant",
        steps,
      }),
    );
    return () => {
      dispatch(
        setConfigTour({
          typeTour: "variant",
          steps: [],
        }),
      );
    };
  }, [dispatch]);

  return (
    <div className="page-content">
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={variants} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteVariant}
        onCloseClick={handleCloseDeleteModal}
        loading={variantsLoading && variantsAction === Actions.DELETE}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div>
              <div className="card">
                <CardHeader className="border-0">
                  <Row className="align-items-center gy-3">
                    <div className="col-sm">
                      <h5 className="card-title mb-0">{t("Variant List")}</h5>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex gap-3 flex-wrap">
                        <ButtonTheme
                          type="button"
                          className="btn btn-light"
                          icon="ri-file-text-line"
                          onClick={() => setIsExportCSV(true)}
                        >
                          {t("Export")}
                        </ButtonTheme>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <TableContainerCustom
                    filterType={"variants"}
                    searchPlaceHolder="Search variant by name, sku, barcode..."
                    listFilter={[
                      {
                        id: "product_id",
                        type: "selectBox",
                        title: "Product",
                        remote: true,
                        pathUrlLoad: "product/products",
                      },
                      {
                        id: "category.id",
                        type: "selectBox",
                        title: "Category",
                        remote: true,
                        pathUrlLoad: "product/categories",
                      },
                      {
                        id: "brand.id",
                        type: "selectBox",
                        title: "Brand",
                        remote: true,
                        pathUrlLoad: "product/brands",
                      },
                    ]}
                    headers={headers}
                    contents={variants}
                    limitPage={Number(variantsLimit)}
                    currentPage={Number(variantsPage) + 1}
                    totalItems={variantsTotal}
                    loading={variantsLoading && variantsAction === Actions.LIST}
                    showPagination={true}
                    scrollHeight="50vh"
                    onCallData={(dataPayload) => {
                      dispatch(listVariant(dataPayload));
                    }}
                    isSettings={true}
                    listHeader={[
                      {
                        title: t("Variant details"),
                        key: "detail-variant",
                        children: [
                          {
                            title: t("Name"),
                            dataIndex: "name",
                            key: "name",
                            sorter: true,
                            render: (value, row, index) => {
                              return (
                                <Tooltip title={t(row.name)}>
                                  <Link
                                    to={`/variants/${row.product_id}`}
                                    state={{
                                      sku: row?.sku || "",
                                    }}
                                    className="d-block text-truncate link-primary h5 mb-0 fs-14"
                                  >
                                    {t(row.name)}
                                  </Link>
                                </Tooltip>
                              );
                            },
                          },
                          {
                            title: t("Image"),
                            dataIndex: "images",
                            key: "images",
                            width: "90px",
                            render: (value, row, index) => {
                              return (
                                <ImageCustom image={row.images?.[0]?.url} name={row.name} avatarSize={"avatar-xs"} />
                              );
                            },
                          },

                          {
                            title: t("Sku"),
                            dataIndex: "sku",
                            key: "sku",
                            sorter: true,
                          },
                          {
                            title: t("Category"),
                            dataIndex: "category",
                            key: "category",
                            sortKey: "category.name",
                            sorter: true,
                            render: (value, row, index) => {
                              return <div className="d-block text-truncate">{t(value?.name) || "---"}</div>;
                            },
                          },
                          {
                            title: t("Brand"),
                            dataIndex: "brand",
                            key: "brand",
                            sortKey: "brand.name",
                            sorter: true,
                            render: (value, row, index) => {
                              return <div className="d-block text-truncate">{t(value?.name) || "---"}</div>;
                            },
                          },
                          {
                            title: t("Available"),
                            titleAlign: "center",
                            dataIndex: "available",
                            key: "available",
                            render: (value, row, index) => {
                              return <PopoverColumn row={row} index={index} field="available" />;
                            },
                          },
                          {
                            title: t("Updated at"),
                            dataIndex: "updated_at",
                            key: "updated_at",
                            sorter: true,
                          },
                          {
                            title: t("Created at"),
                            dataIndex: "created_at",
                            key: "created_at",
                            sorter: true,
                            defaultSortOrder: "desc",
                          },
                          {
                            title: t("Action"),
                            dataIndex: "action",
                            key: "action",
                            render: (value, row, index) => {
                              return (
                                <ActionDropdown
                                  actions={[
                                    { type: "view", onClick: () => navigate(`/variants/${row.product_id}`) },
                                    {
                                      type: "edit",
                                      onClick: () => navigate(`/variants/${row.product_id}/edit`),
                                    },
                                    {
                                      type: "delete",
                                      onClick: () => {
                                        onClickDelete(row);
                                      },
                                    },
                                  ]}
                                />
                              );
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </CardBody>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(VariantsPage);
