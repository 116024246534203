import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import InputTheme from "../../../Components/Common/InputTheme";
import UploadTheme from "../../../Components/Common/UploadTheme";
import SelectTheme from "../../../Components/Common/SelectTheme";
import ButtonTheme from "../../../Components/Common/ButtonTheme";
import AddressTheme from "../../../Components/Common/AddressTheme";
import { useDispatch } from "react-redux";
import useMemoizedSelector from "../../../Components/Hooks/useMemoizedSelector";
import { getInfoShop, getVersion, updateInfoShop } from "../../../store/actions";
import locationVN from "../../../Components/constants/location";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ScrollToError from "../../../Components/Common/ScrollToError";

export default function ShopInfo() {
  const { t } = useTranslation();
  document.title = `${t("Store information")} | OptiWarehouse`;
  const [address, setAddress] = useState();
  const dispatch = useDispatch();
  const [optionDisTricts, setOptionDisTricts] = useState([]);
  const [valueDisTricts, setValueDisTricts] = useState({});
  const [optionWards, setOptionWards] = useState([]);
  const [valueWards, setValueWards] = useState({});

  const { dataShop, loading } = useMemoizedSelector((state) => ({
    dataShop: state.ShopInfo.dataShop,
    loading: state.ShopInfo.loading,
  }));

  useEffect(() => {
    dispatch(getInfoShop());
  }, [dispatch]);

  const validation = {
    enableReinitialize: true,
    initialValues: {
      ...dataShop.setting_value,
    },
    validationSchema: Yup.object({
      store_email: Yup.string().email().required(t("Please Enter Email")),
      store_phone: Yup.string()
        .required(t("Please Enter Phone Number"))
        .matches(/^[0-9]+$/, t("Phone number must contain only digits"))
        .min(10, t("Phone number is too short"))
        .max(15, t("Phone number is too long")),
    }),
    onSubmit: (values) => {
      const data = { ...address, ...values };
      dispatch(
        updateInfoShop(data, () => {
          toast.success(t("Update store information success!"));
          setTimeout(() => {
            dispatch(getVersion());
          }, 2000);
        }),
      );
    },
  };

  useEffect(() => {
    const findProvince = locationVN.find((item) => item.name === dataShop.setting_value?.store_provinces);
    if (findProvince) {
      setOptionDisTricts(
        findProvince.districts.map((item) => ({
          value: item.name,
          label: item.name,
          wards: item.wards,
        })),
      );
      const findDistrict = findProvince.districts.find((item) => item.name === dataShop.setting_value?.store_districts);
      if (findDistrict) {
        setValueDisTricts({ value: findDistrict.id, label: findDistrict.name, wards: findDistrict.wards });
        setOptionWards(findDistrict.wards.map((item) => ({ value: item.name, label: item.name })));
        const findWard = findDistrict.wards.find((item) => item.name === dataShop.setting_value?.store_wards);
        if (findWard) {
          setValueWards({ value: findWard.id, label: findWard.name });
        }
      }
    }
  }, [dataShop]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("Store information")} pageTitle="Settings" navigateTo="/settings" isBack={true} />
        <Formik
          enableReinitialize={true}
          initialValues={validation.initialValues}
          validationSchema={validation.validationSchema}
          onSubmit={validation.onSubmit}
          // innerRef={formRef}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <ScrollToError />
              <Card>
                <CardHeader>
                  <h4>{t("Contact information")}</h4>
                  <p>{t("Information used for customers to contact you")}</p>
                </CardHeader>
                <CardBody>
                  {/* <InputTheme type="image"/> */}
                  <Row>
                    <Col lg={6}>
                      <Row>
                        <Col lg={4} md={4} sm={4}>
                          <UploadTheme name="store_logo_light" label={"Light logo"} />
                        </Col>
                        <Col lg={4} md={4} sm={4}>
                          <UploadTheme name="store_logo_dark" label={"Dark logo"} />
                        </Col>
                        <Col lg={4} md={4} sm={4}>
                          <UploadTheme name="store_icon" label={"Icon"} />
                        </Col>
                      </Row>
                    </Col>

                    <Col lg={6}>
                      <InputTheme name="store_name" label={"Name store"} />
                      <InputTheme name="store_phone" label={"Phone"} />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <InputTheme name="store_email" label={"Email"} />
                    </Col>
                    <Col lg={6}>
                      <SelectTheme name="store_business_areas" label="Business sector" />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h4>{t("Store information")}</h4>
                  <p>
                    {t("The information used in store notifications, including the store address and contact details")}
                  </p>
                </CardHeader>
                <CardBody>
                  <Row>
                    <InputTheme name="store_url" label={"Store URL"} />
                  </Row>
                  <Row>
                    <SelectTheme
                      label={"Default price group"}
                      name="store_default_price_group"
                      placeholder={"Select Default Price"}
                      remote={true}
                      isObjectValueSelect={true}
                      selectDataOrigin={true}
                      path="price/price_groups"
                    />
                  </Row>
                  <Row>
                    <InputTheme name="store_address" label={"Store address"} />
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <SelectTheme
                        name="store_provinces"
                        label="Provinces"
                        options={locationVN.map((item) => ({
                          value: item.name,
                          label: item.name,
                          districts: item.districts,
                        }))}
                        onChange={(value) => {
                          const optionDistricts = value.districts.map((item) => ({
                            value: item.name,
                            label: item.name,
                            wards: item.wards,
                          }));
                          setOptionDisTricts(optionDistricts);
                          setFieldValue("store_districts", "");
                          setFieldValue("store_wards", "");
                          // setOptionWards([]);
                        }}
                      />
                    </Col>
                    <Col lg={4}>
                      <SelectTheme
                        name="store_districts"
                        label="Districts"
                        options={optionDisTricts}
                        onChange={(option) => {
                          const optionWards = option.wards.map((item) => ({
                            value: item.name,
                            label: item.name,
                          }));
                          setOptionWards(optionWards);
                          setFieldValue("store_wards", "");
                        }}
                      />
                    </Col>
                    <Col lg={4}>
                      <SelectTheme name="store_wards" label="Wards" options={optionWards} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <div className="text-end">
                <ButtonTheme className="btn btn-primary mx-3" type="submit" loading={loading}>
                  {t("Save")}
                </ButtonTheme>
                <ButtonTheme className="btn btn-outline-danger" onClick={() => {}}>
                  {t("Close")}
                </ButtonTheme>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  );
}
