import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";


// Redux States
import { LIST_ACCOUNT, DELETE_ACCOUNT, ADD_NEW_ACCOUNT, UPDATE_ACCOUNT, GET_ACCOUNT } from "./actionType";

import {
  deleteAccountSuccess,
  deleteAccountFail,
  addAccountSuccess,
  addAccountFail,
  updateAccountSuccess,
  updateAccountFail,
  listAccountSuccess,
  listAccountFail,
  getAccountSuccess,
  getAccountFail,
} from "./action";

import { accountsAPI } from "../../../../helpers/resource_helper";

function* listAccount({ payload: { ...rest } }) {
  try {
    const response = yield call(accountsAPI.list, { ...rest });
    yield put(listAccountSuccess(response));
  } catch (error) {
    yield put(listAccountFail(error));
  }
}

function* getAccount({ payload: id }) {
  try {
    const response = yield call(accountsAPI.get, { id });
    yield put(getAccountSuccess(response));
  } catch (error) {
    yield put(getAccountFail(error));
  }
}

function* deleteAccounts({ payload: { account, handleAfterSubmit } }) {
  try {
    const response = yield call(accountsAPI.delete, { id: account.id });
    yield put(deleteAccountSuccess(account));
    yield call(handleAfterSubmit);
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteAccountFail(error));
    toast.error("Account Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewAccount({ payload: { account, handleAfterSubmit } }) {
  try {
    const response = yield call(accountsAPI.create, account);
    yield put(addAccountSuccess(response));
    toast.success("Account Added Successfully", { autoClose: 3000 });
    yield call(handleAfterSubmit);
  } catch (error) {
    yield put(addAccountFail(error));
    toast.error("Account Added Failed", { autoClose: 3000 });
  }
}

function* onUpdateAccount({ payload: { account, handleAfterSubmit } }) {
  try {
    const response = yield call(accountsAPI.put, account);
    yield put(updateAccountSuccess(response));
    toast.success("Account Updated Successfully", { autoClose: 3000 });
    yield call(handleAfterSubmit);
  } catch (error) {
    yield put(updateAccountFail(error));
    toast.error("Account Updated Failed", { autoClose: 3000 });
  }
}

export function* watchListAccount() {
  yield takeEvery(LIST_ACCOUNT, listAccount);
}

export function* watchDeleteAccounts() {
  yield takeEvery(DELETE_ACCOUNT, deleteAccounts);
}

export function* watchGetAccount() {
  yield takeEvery(GET_ACCOUNT, getAccount);
}

export function* watchUpdateAccount() {
  yield takeEvery(UPDATE_ACCOUNT, onUpdateAccount);
}

export function* watchAddNewAccount() {
  yield takeEvery(ADD_NEW_ACCOUNT, onAddNewAccount);
}

function* accountsSaga() {
  yield all([
    fork(watchGetAccount),
    fork(watchListAccount),
    fork(watchUpdateAccount),
    fork(watchAddNewAccount),
    fork(watchDeleteAccounts),
  ]);
}

export default accountsSaga;
