import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import {
  getApiColorShopInfo,
  getDefaultPrintSettings,
  getInfoSetting,
  updateInfoSetting,
} from "../../helpers/service_helper";
import {
  geDefaultPrintSuccess,
  getColorInfoShopError,
  getColorInfoShopSuccess,
  getDefaultPrintError,
  getInfoShopError,
  getInfoShopSuccess,
  updateColorInfoShopError,
  updateColorInfoShopSuccess,
  updateDefaultPrintError,
  updateDefaultPrintSuccess,
} from "./action";
import {
  GET_COLOR_SHOP_INFO,
  GET_DEFAULT_PRINT,
  GET_SHOP_INFO,
  UPDATE_COLOR_SHOP_INFO,
  UPDATE_DEFAULT_PRINT,
  UPDATE_SHOP_INFO,
} from "./actionType";
import { settingsDefaultPrintAPI } from "../../helpers/resource_helper";
import { toast } from "react-toastify";

function* getShopInfo() {
  try {
    const response = yield call(getInfoSetting);
    yield put(getInfoShopSuccess(response));
  } catch (error) {
    yield put(getInfoShopError(error));
  }
}

function* getColorShopInfo() {
  try {
    const response = yield call(getApiColorShopInfo);
    yield put(getColorInfoShopSuccess(response?.setting_value));
  } catch (error) {
    yield put(getColorInfoShopError(error));
  }
}

function* updateColorShopInfo({ payload: data }) {
  try {
    const response = yield call(updateInfoSetting, "color", data);
    yield put(updateColorInfoShopSuccess(response?.setting_value));
    toast.success("Sava color success");
  } catch (error) {
    yield put(updateColorInfoShopError(error));
  }
}

function* getDefaultPrint() {
  try {
    const response = yield call(getDefaultPrintSettings);
    yield put(geDefaultPrintSuccess(response));
  } catch (error) {
    yield put(getDefaultPrintError(error));
  }
}

function* updateDefaultPrint({ payload: { data, handleAfterClick } }) {
  try {
    const response = yield call(settingsDefaultPrintAPI.create, data);
    yield put(updateDefaultPrintSuccess(response));
    yield call(handleAfterClick);
  } catch (error) {
    yield put(updateDefaultPrintError(error));
  }
}

function* updateShopInfo({ payload: { data, handleAfterSubmit } }) {
  try {
    const response = yield call(updateInfoSetting, "shop_info", data);
    yield put(getInfoShopSuccess(response));
    yield call(handleAfterSubmit);
  } catch (error) {
    yield put(getInfoShopError(error));
  }
}

export function* watchGetShopInfo() {
  yield takeEvery(GET_SHOP_INFO, getShopInfo);
}

export function* watchGetColorShopInfo() {
  yield takeEvery(GET_COLOR_SHOP_INFO, getColorShopInfo);
}

export function* watchUpdateColorShopInfo() {
  yield takeEvery(UPDATE_COLOR_SHOP_INFO, updateColorShopInfo);
}

export function* watchGetDefaultPrint() {
  yield takeEvery(GET_DEFAULT_PRINT, getDefaultPrint);
}

export function* watchGetCustomer() {
  yield takeEvery(UPDATE_SHOP_INFO, updateShopInfo);
}

export function* watchUpdateDefaultPrint() {
  yield takeEvery(UPDATE_DEFAULT_PRINT, updateDefaultPrint);
}

function* shopInfoSaga() {
  yield all([
    fork(watchGetShopInfo),
    fork(watchGetCustomer),
    fork(watchGetDefaultPrint),
    fork(watchUpdateDefaultPrint),
    fork(watchGetColorShopInfo),
    fork(watchUpdateColorShopInfo),
  ]);
}

export default shopInfoSaga;
