import React, { Fragment } from "react";
import ButtonTheme from "./ButtonTheme";
import { formatVNDCurrency } from "../../helpers/format_helper";
import { Col, Row } from "reactstrap";

const denominations = [1000, 2000, 5000, 10000, 20000, 50000, 100000, 200000, 500000];

const generatePriceSuggestions = (currentPrice) => {
  let suggestions = [currentPrice];
  const getNextPrice = (price) => {
    if (price < 10000) return price + 1000;
    if (price < 50000) return Math.floor(price / 10000) * 10000 + 10000;
    if (price < 100000) return Math.floor(price / 50000) * 50000 + 50000;
    if (price < 200000) return Math.floor(price / 100000) * 100000 + 100000;
    return Math.floor(price / 500000) * 500000 + 500000;
  };

  while (suggestions.length < 6) {
    let lastPrice = suggestions[suggestions.length - 1];
    let nextPrice = getNextPrice(lastPrice);
    suggestions.push(nextPrice);
  }
 
  return suggestions;
};

const PriceSuggestion = ({ currentPrice, givenCash, onSelectPrice }) => {
  if (currentPrice === 0) return <></>;

  const priceSuggestions = generatePriceSuggestions(currentPrice);

  return (
    <Row>
      {priceSuggestions.map((price, index) => (
        <Col key={index} className="mb-1 col-4">
          <ButtonTheme
            className={`btn ${price === Number(givenCash) ? "btn-primary" : "btn-outline-primary"} w-100`}
            onClick={() => {
              onSelectPrice(price);
            }}
          >
            {formatVNDCurrency(price)}
          </ButtonTheme>
        </Col>
      ))}
    </Row>
  );
};

export default PriceSuggestion;
