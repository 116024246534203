import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";

import { deepCopy } from "../../helpers/format_helper";

import InputStep from "./InputStep";
import { useTranslation } from "react-i18next";

const PackageComponent = ({ orderItems, selectedLocation, packagePayload, onChangePackagePayload, packages }) => {
  const { t } = useTranslation();
  const handleChangeQuantity = (number, productIndex, product) => {
    let newData = deepCopy(packagePayload);
    let packageIndex = newData.findIndex((item) => item.location_id === selectedLocation);
    if (packageIndex > -1) {
      let itemIndex = newData[packageIndex].order_line_items.findIndex((item) => item.sku === product.sku);
      if (itemIndex > -1) {
        newData[packageIndex].order_line_items[itemIndex].quantity = number;
        newData[packageIndex].order_line_items[itemIndex].quantity === 0 &&
          newData[packageIndex].order_line_items.splice(itemIndex, 1);
        newData[packageIndex].order_line_items.length === 0 && newData.splice(packageIndex, 1);
      } else {
        number !== 0 &&
          newData[packageIndex].order_line_items.push({
            quantity: number,
            sku: product.sku,
            note: "test",
          });
      }
    } else {
      number !== 0 &&
        newData.push({
          location_id: selectedLocation,
          order_line_items: [
            {
              quantity: number,
              sku: product.sku,
              note: "test",
            },
          ],
        });
    }
    onChangePackagePayload(newData);
  };

  return (
    <div className="table-responsive">
      <table className="table table-nowrap align-middle table-borderless mb-0">
        <thead className="table-light text-muted">
          <tr>
            <th scope="col">{t("Product Details")}</th>
            <th scope="col" className="text-center">
              {t("Total")}
            </th>
            <th scope="col" className="text-center">
              {t("Remaining")}
            </th>
            <th scope="col" className="text-center">
              {t("Quantity")}
            </th>
            <th scope="col" className="text-center">
              {t("Packed")}
            </th>
          </tr>
        </thead>
        <tbody>
          {orderItems.map((product, key) => {
            let totalPacked = packages.reduce(
              (total, item) =>
                total +
                item.order_line_items.reduce((subTotal, subItem) => {
                  if (subItem.sku === product.sku) {
                    return subTotal + Number(subItem.quantity);
                  } else {
                    return subTotal;
                  }
                }, 0),
              0,
            );

            let remainQuantity =
              product.quantity -
              totalPacked -
              packagePayload.reduce((total, item) => {
                let check = item.order_line_items.find((subItem) => subItem.sku === product.sku);
                if (check) {
                  return total + check.quantity;
                } else {
                  return total;
                }
              }, 0);

            let currentQuantity = 0;
            let packagePayloadIndex = packagePayload.findIndex((item) => item.location_id === selectedLocation);
            if (packagePayloadIndex > -1) {
              let check = packagePayload[packagePayloadIndex].order_line_items.find((item) => item.sku === product.sku);
              check && (currentQuantity = check.quantity);
            }

            let currentLocationPackages = packages.filter((item) => item.location_id === selectedLocation);
            let currentPacked = currentLocationPackages.reduce(
              (total, item) =>
                total +
                item.order_line_items.reduce((subTotal, subItem) => {
                  if (subItem.sku === product.sku) {
                    return subTotal + Number(subItem.quantity);
                  } else {
                    return subTotal;
                  }
                }, 0),
              0,
            );

            return (
              <tr key={key}>
                <td>
                  <div className="hstack">
                    <div className="flex-shrink-0 avatar-md bg-light rounded p-1">
                      <img src={product.image_url} alt="" className="img-fluid d-block" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h5 className="fs-15">
                        <Link
                          id={`order-package-product-name-${key}`}
                          to={`/products/${product.product_id}`}
                          className="d-inline-block text-truncate"
                          style={{ maxWidth: "400px" }}
                        >
                          {product.name ? t(product.name) : "---"}
                        </Link>
                        <UncontrolledTooltip target={`order-package-product-name-${key}`} fade>
                          {product.name ? t(product.name) : "---"}
                        </UncontrolledTooltip>
                      </h5>
                      <p className="text-muted mb-0">
                        {t("Sku")}: <span className="fw-medium">{product.sku}</span>
                      </p>
                    </div>
                  </div>
                </td>
                <td className="text-center">{product.quantity}</td>
                <td className="text-center">{remainQuantity}</td>
                <td className="fw-medium text-center">
                  <InputStep
                    index={key}
                    product={product}
                    onChangeQuantity={handleChangeQuantity}
                    quantity={currentQuantity}
                    max={remainQuantity + currentQuantity}
                  />
                </td>
                <td className="text-center">{currentPacked}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default PackageComponent;
