import { Fragment, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import Widget from "./Widgets";
import Revenue from "./Revenue";
import withRouter from "../../../../Components/Common/withRouter";

const CustomerDashboard = (props) => {
  const {
    router: { location, navigate, params, t },
  } = props;
  document.title = `${t("Customer Dashboard")} | OptiWarehouse`;
  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Row>
                  <Widget />
                </Row>
                <Row>
                  <Col xl={6}>
                    <Revenue />
                  </Col>
                  <Col xl={6}>
                    <Revenue />
                  </Col>
                </Row>
                <Row>
                  <Col xl={6}>
                    <Revenue />
                  </Col>
                  <Col xl={6}>
                    <Revenue />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default withRouter(CustomerDashboard);
