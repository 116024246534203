import {
  LIST_TRANSACTION_VOUCHER,
  LIST_TRANSACTION_VOUCHER_FAIL,
  LIST_TRANSACTION_VOUCHER_SUCCESS,
  DELETE_TRANSACTION_VOUCHER,
  DELETE_TRANSACTION_VOUCHER_SUCCESS,
  DELETE_TRANSACTION_VOUCHER_FAIL,
  ADD_NEW_TRANSACTION_VOUCHER,
  ADD_TRANSACTION_VOUCHER_SUCCESS,
  ADD_TRANSACTION_VOUCHER_FAIL,
  UPDATE_TRANSACTION_VOUCHER,
  UPDATE_TRANSACTION_VOUCHER_SUCCESS,
  UPDATE_TRANSACTION_VOUCHER_FAIL,
  GET_TRANSACTION_VOUCHER,
  GET_TRANSACTION_VOUCHER_FAIL,
  GET_TRANSACTION_VOUCHER_SUCCESS,
  RESET_TRANSACTION_VOUCHER_FLAG,
} from "./actionType";

export const listTransactionVoucher = (options) => ({
  type: LIST_TRANSACTION_VOUCHER,
  payload: options,
});

export const listTransactionVoucherFail = (error) => ({
  type: LIST_TRANSACTION_VOUCHER_FAIL,
  payload: error,
});

export const listTransactionVoucherSuccess = (transactionVouchers) => ({
  type: LIST_TRANSACTION_VOUCHER_SUCCESS,
  payload: transactionVouchers,
});

export const getTransactionVoucher = (transactionVoucherID) => ({
  type: GET_TRANSACTION_VOUCHER,
  payload: transactionVoucherID,
});

export const getTransactionVoucherFail = (error) => ({
  type: GET_TRANSACTION_VOUCHER_FAIL,
  payload: error,
});

export const getTransactionVoucherSuccess = (transactionVoucher) => ({
  type: GET_TRANSACTION_VOUCHER_SUCCESS,
  payload: transactionVoucher,
});

export const deleteTransactionVoucher = (transactionVoucher, handleAfterSubmit = () => {}) => ({
  type: DELETE_TRANSACTION_VOUCHER,
  payload: { transactionVoucher, handleAfterSubmit },
});

export const deleteTransactionVoucherSuccess = (transactionVoucher) => ({
  type: DELETE_TRANSACTION_VOUCHER_SUCCESS,
  payload: transactionVoucher,
});

export const deleteTransactionVoucherFail = (error) => ({
  type: DELETE_TRANSACTION_VOUCHER_FAIL,
  payload: error,
});

export const updateTransactionVoucher = (transactionVoucher, handleAfterSubmit = () => {}) => ({
  type: UPDATE_TRANSACTION_VOUCHER,
  payload: { transactionVoucher, handleAfterSubmit },
});

export const updateTransactionVoucherSuccess = (transactionVoucher) => ({
  type: UPDATE_TRANSACTION_VOUCHER_SUCCESS,
  payload: transactionVoucher,
});

export const updateTransactionVoucherFail = (error) => ({
  type: UPDATE_TRANSACTION_VOUCHER_FAIL,
  payload: error,
});

export const addNewTransactionVoucher = (transactionVoucher, handleAfterSubmit = () => {}) => ({
  type: ADD_NEW_TRANSACTION_VOUCHER,
  payload: { transactionVoucher, handleAfterSubmit },
});

export const addTransactionVoucherSuccess = (transactionVoucher) => ({
  type: ADD_TRANSACTION_VOUCHER_SUCCESS,
  payload: transactionVoucher,
});

export const addTransactionVoucherFail = (error) => ({
  type: ADD_TRANSACTION_VOUCHER_FAIL,
  payload: error,
});

export const resetTransactionVoucherFlag = () => ({
  type: RESET_TRANSACTION_VOUCHER_FLAG,
});
