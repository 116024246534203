// Actions
export const LIST_PACKAGE = "LIST_PACKAGE";
export const LIST_PACKAGE_SUCCESS = "LIST_PACKAGE_SUCCESS";
export const LIST_PACKAGE_FAIL = "LIST_PACKAGE_FAIL";

export const GET_PACKAGE = "GET_PACKAGE";
export const GET_PACKAGE_SUCCESS = "GET_PACKAGE_SUCCESS";
export const GET_PACKAGE_FAIL = "GET_PACKAGE_FAIL";

/**
 * Delete PACKAGE
 */
export const DELETE_PACKAGE = "DELETE_PACKAGE";
export const DELETE_PACKAGE_SUCCESS = "DELETE_PACKAGE_SUCCESS";
export const DELETE_PACKAGE_FAIL = "DELETE_PACKAGE_FAIL";

/**
 * Add PACKAGE
 */
export const ADD_NEW_PACKAGE = "ADD_NEW_PACKAGE";
export const ADD_PACKAGE_SUCCESS = "ADD_PACKAGE_SUCCESS";
export const ADD_PACKAGE_FAIL = "ADD_PACKAGE_FAIL";

/**
 * Edit PACKAGE
 */
export const UPDATE_PACKAGE = "UPDATE_PACKAGE";
export const UPDATE_PACKAGE_SUCCESS = "UPDATE_PACKAGE_SUCCESS";
export const UPDATE_PACKAGE_FAIL = "UPDATE_PACKAGE_FAIL";

export const RESET_PACKAGE_FLAG = "RESET_PACKAGE_FLAG";
