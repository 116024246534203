import { Actions } from "../../../../Components/constants/common";
import {
  LIST_CATEGORY,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAIL,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  LIST_CATEGORY_SUCCESS,
  LIST_CATEGORY_FAIL,
  ADD_NEW_CATEGORY,
  UPDATE_CATEGORY,
  GET_CATEGORY,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
} from "./actionType";

const INIT_STATE = {
  categories: [],
  category: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const Categories = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_CATEGORY:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_CATEGORY_SUCCESS:
      return {
        ...state,
        message: "Get categories success",
        loading: false,
        success: true,
        categories: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_CATEGORY_FAIL:
      return {
        ...state,
        message: "Get categories failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_CATEGORY:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        message: "Get category success",
        loading: false,
        success: true,
        category: action.payload,
        action: Actions.EMPTY,
      };

    case GET_CATEGORY_FAIL:
      return {
        ...state,
        message: "Get category failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_CATEGORY:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        message: "Delete category success",
        loading: false,
        success: true,
        categories: state.categories.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_CATEGORY_FAIL:
      return {
        ...state,
        message: "Delete category failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_NEW_CATEGORY:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        message: "Add category success",
        success: true,
        loading: false,
        categories: [...state.categories, action.payload],
        action: Actions.EMPTY,
      };

    case ADD_CATEGORY_FAIL:
      return {
        ...state,
        message: "Add category failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_CATEGORY:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.UPDATE,
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        message: "Update category success",
        loading: false,
        success: true,
        categories: state.categories.map((category) =>
          category.id.toString() === action.payload.id.toString() ? { ...category, ...action.payload } : category,
        ),
        action: Actions.EMPTY,
      };

    case UPDATE_CATEGORY_FAIL:
      return {
        ...state,
        message: "Update category failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    default:
      return { ...state };
  }
};

export default Categories;
