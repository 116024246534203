import { Fragment, useEffect } from "react";
import { useState } from "react";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import { useTranslation } from "react-i18next";
import { getListUserAPI } from "../../../../../helpers/service_helper";
import ReactSelect from "react-select";
import { Tooltip } from "antd";
import { useProfile } from "../../../../../Components/Hooks/useProfile";

const StaffSelectForm = ({ handleChangeStaff, selectedStaff }) => {
  const { userProfile } = useProfile();

  // const [isOpen, setIsOpen] = useState(false);
  // const [staff, setStaff] = useState(null);
  // const [users, setUsers] = useState([]);
  // const toggle = () => {
  //   setIsOpen(false);
  // };
  // const { t } = useTranslation();
  // const getListUser = async () => {
  //   const company_id = JSON.parse(localStorage.getItem("auth_user"))?.UserAttributes["custom:company_id"];
  //   const res = await getListUserAPI({ company_id });
  //   const mapRes = res.Users.map((item) => {
  //     return {
  //       value: item.Attributes.find((u) => u.Name === "sub").Value,
  //       label: item.Username,
  //     };
  //   });
  //   setUsers(mapRes);
  // };
  // useEffect(() => {
  //   getListUser();
  // }, []);
  return (
    <Fragment>
      {/* <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("Select staff")}</ModalHeader>
        <ModalBody>
          <ReactSelect
            id="user-options"
            classNamePrefix="select"
            value={staff}
            onChange={(value) => {
              setStaff(value);
            }}
            options={users}
          />
        </ModalBody>
        <ModalFooter>
          <ButtonTheme className="btn w-sm btn-light" onClick={toggle}>
            {t("Close")}
          </ButtonTheme>
          <ButtonTheme
            className="btn btn-primary w-sm"
            onClick={() => {
              handleChangeStaff(staff);
              toggle();
            }}
          >
            {t("Save")}
          </ButtonTheme>
        </ModalFooter>
      </Modal> */}
      {/* <ButtonTheme type="button" onClick={() => setIsOpen(true)} className="btn btn-icon h-50 w-100"> */}
      <Tooltip title={userProfile.Username}>
        <div className="hstack h-50 w-100 text-white">
          <span className="d-flex align-items-center justify-content-center">
            <i className="ri-user-3-line me-1"></i>
            <span
              className="fs-10 text-truncate d-block"
              style={{
                maxWidth: "80px",
              }}
            >
              {userProfile?.Username || "---"}
            </span>
          </span>
        </div>
      </Tooltip>

      {/* </ButtonTheme> */}
    </Fragment>
  );
};
export default StaffSelectForm;
