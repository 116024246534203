import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, Container } from "reactstrap";

import withRouter from "../../../../../Components/Common/withRouter";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import BreadCrumbCustom from "../../../../../Components/Common/BreadCrumbCustom";
import useMemoizedSelector from "../../../../../Components/Hooks/useMemoizedSelector";
import { Actions } from "../../../../../Components/constants/common";

import { deleteBlog, getBlog, resetBlogState } from "../../../../../store/actions";
import ImageCustom from "../../../../../Components/Common/ImageCustom";
import DeleteModal from "../../../../../Components/Common/DeleteModal";
import { toast } from "react-toastify";

const PreviewBlog = (props) => {
  const { router } = props;
  const { navigate, location, params, t } = router;
  const dispatch = useDispatch();
  const { id } = params;
  document.title = `${t(id ? "Edit Blog" : "Create Blog")} | OptiWarehouse`;

  const { blogState, blogsLoading, blogsSuccess, blogsAction, blogsMsg } = useMemoizedSelector((state) => ({
    blogState: state.Blogs.blog,
    blogsLoading: state.Blogs.loading,
    blogsAction: state.Blogs.action,
    blogsSuccess: state.Blogs.success,
    blogsError: state.Blogs.error,
    blogsMsg: state.Blogs.message,
  }));
  const { version } = useMemoizedSelector((state) => ({
    version: state.Version.version,
  }));


  const [blog, setBlog] = useState(null);
  const [deleteModal, setDeleteModal] = useState(null);
  const handleCloseDeleteModal = () => {
    if (!blogsLoading) {
      setDeleteModal(false);
    }
  };
  useEffect(() => {
    setBlog(blogState);
  }, [blogState, dispatch]);

  const handleClickDelete = () => {
    if (blog) {
      dispatch(
        deleteBlog(blog, () => {
          navigate(`/blogs`);
        }),
      );
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getBlog(id));
    } else {
      dispatch(resetBlogState());
    }
  }, [dispatch, id]);

  return (
    <div className="page-content">
      <Container fluid>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleClickDelete}
          onCloseClick={handleCloseDeleteModal}
          loading={blogsLoading && blogsAction === Actions.DELETE}
        />
        <BreadCrumbCustom text="Back to blog list" navigateTo="/blogs" t={t}>
          <div className="hstack gap-2">
            {id && (
              <ButtonTheme
                type="button"
                className="btn btn-danger w-sm"
                onClick={() => {
                  setDeleteModal(true);
                }}
                disabled={blogsAction === Actions.DELETE && blogsLoading}
              >
                {t("Delete")}
              </ButtonTheme>
            )}
            <ButtonTheme
              type="button"
              className="btn btn-outline-primary w-sm"
              onClick={() => navigate(`/blogs/${id}/edit`)}
              loading={(blogsAction === Actions.UPDATE || blogsAction === Actions.CREATE) && blogsLoading}
              disabled={(blogsAction === Actions.UPDATE || blogsAction === Actions.CREATE) && blogsLoading}
            >
              {t("Update")}
            </ButtonTheme>
            <ButtonTheme
              type="button"
              className="btn btn-outline-primary w-sm"
              onClick={() => {
                const link = version?.data?.dict?.shop_info?.setting_value?.store_url;
                if (link) {
                  window.open(`${link}/blogs/${blog.slug}`, "_blank");
                } else {
                  toast.error(t("Please config store URL before use this feature!"));
                }
              }}
            >
              {t("View on web")}
            </ButtonTheme>
          </div>
        </BreadCrumbCustom>
        <div className="d-flex gap-3">
          <div className="flex-grow-1">
            <Card>
              <CardBody>
                {/* <h5 className="card-title">{t("Blog")}</h5>
                <div className="mb-3">
                  <h5 className="fs-14 mb-3">{t("Image")}</h5>
                  <ImageCustom image={blog?.image?.url} name={blog?.title} />
                </div>
                <h5 className="fs-14 mb-3">{blog?.title || "---"}</h5> */}
                <div className="mb-3 w-100">
                  <h5 className="fs-14 mb-3">{t("Content")}</h5>
                  {blog?.content ? <div className="ck-content" dangerouslySetInnerHTML={{ __html: blog?.content }} /> : ""}
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(PreviewBlog);
