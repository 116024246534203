import { Table } from "reactstrap";
import { formatVNDCurrency } from "../../../../helpers/format_helper";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

const ReceiptPaymentReportTable = ({ data }) => {
  const { t } = useTranslation();
  const [receiptType, setReceiptType] = useState([
    { id: "cash", label: "Tiền mặt", isCollapsed: true },
    { id: "banking", label: "Chuyển khoản", isCollapsed: true },
    { id: "card_payment", label: "Quẹt thẻ", isCollapsed: true },
    { id: "pre_payment", label: "Thu tiền đối tác ứng trước", isCollapsed: true },
    { id: "other", label: "Thu khác", isCollapsed: true },
  ]);

  const [paymentType, setPaymentType] = useState([
    { id: "refund", label: "Hoàn tiền cho khách trả hàng", isCollapsed: true },
    { id: "shipping_debt", label: "Trả nợ đối tác vận chuyển", isCollapsed: true },
    { id: "other", label: "Chi khác", isCollapsed: true },
  ]);

  const [endOfDayInventoryCashType, setEndOfDayInventoryCashType] = useState([
    { id: "receipt", label: "Thu tiền mặt", isCollapsed: true },
    { id: "payment", label: "Chi tiền mặt", isCollapsed: true },
  ]);

  const [endOfDayInventoryBankingType, setEndOfDayInventoryBankingType] = useState([
    { id: "receipt", label: "Thu chuyển khoản", isCollapsed: true },
    { id: "payment", label: "Chi chuyển khoản", isCollapsed: true },
  ]);

  const toggleRow = (type, selectedRow) => {
    if (type === "receiptType") {
      const newData = receiptType.map((item) => {
        if (item.id === selectedRow.id) {
          item.isCollapsed = !item.isCollapsed;
        }
        return item;
      });
      setReceiptType(newData);
    } else if (type === "paymentType") {
      const newData = paymentType.map((item) => {
        if (item.id === selectedRow.id) {
          item.isCollapsed = !item.isCollapsed;
        }
        return item;
      });
      setPaymentType(newData);
    } else if (type === "endOfDayInventoryCashType") {
      const newData = endOfDayInventoryCashType.map((item) => {
        if (item.id === selectedRow.id) {
          item.isCollapsed = !item.isCollapsed;
        }
        return item;
      });
      setEndOfDayInventoryCashType(newData);
    } else {
      const newData = endOfDayInventoryBankingType.map((item) => {
        if (item.id === selectedRow.id) {
          item.isCollapsed = !item.isCollapsed;
        }
        return item;
      });
      setEndOfDayInventoryBankingType(newData);
    }
  };

  return (
    <Table bordered>
      <thead>
        <tr className="bg-light text-center">
          <th colSpan={1000}>{t("Thông tin thu chi")}</th>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <th className="text-center">{t("Giá trị")}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>{t("Tổng thu trong ngày")}</th>
          <td></td>
          <td className="text-end">{formatVNDCurrency(0)}</td>
        </tr>
        <tr>
          <td></td>
          <th>{t("Khách thanh toán cho đơn")}</th>
          <td className="text-end">{formatVNDCurrency(0)}</td>
        </tr>
        {receiptType.map((item, index) => (
          <Fragment key={index}>
            <tr>
              <td></td>
              <th
                className={`cursor-pointer${item.isCollapsed ? "" : " text-primary"}`}
                onClick={() => {
                  toggleRow("receiptType", item);
                }}
              >
                <i
                  className={`${
                    item.isCollapsed ? "ri-arrow-drop-right-line" : "ri-arrow-drop-down-line"
                  } fs-16 align-bottom`}
                ></i>{" "}
                {item.label}
              </th>
              <td className="text-end">{formatVNDCurrency(data?.[item.id]?.revenue || 0)}</td>
            </tr>
            {!item.isCollapsed && (
              <tr className="bg-light">
                <td colSpan={1000}>
                  <ChildTable title={"Danh sách thu"} data={data?.[item.id] || []} />
                </td>
              </tr>
            )}
          </Fragment>
        ))}
        <tr>
          <th>{t("Chi trong ngày")}</th>
          <td></td>
          <td className="text-end">{formatVNDCurrency(0)}</td>
        </tr>
        {paymentType.map((item, index) => (
          <Fragment key={index}>
            <tr>
              <td></td>
              <th
                className={`cursor-pointer${item.isCollapsed ? "" : " text-primary"}`}
                onClick={() => {
                  toggleRow("paymentType", item);
                }}
              >
                <i
                  className={`${
                    item.isCollapsed ? "ri-arrow-drop-right-line" : "ri-arrow-drop-down-line"
                  } fs-16 align-bottom`}
                ></i>{" "}
                {item.label}
              </th>
              <td className="text-end">{formatVNDCurrency(data?.[item.id]?.revenue || 0)}</td>
            </tr>
            {!item.isCollapsed && (
              <tr className="bg-light">
                <td colSpan={1000}>
                  <ChildTable title={"Danh sách chi"} data={data?.[item.id] || []} />
                </td>
              </tr>
            )}
          </Fragment>
        ))}
        <tr>
          <th>{t("Tồn cuối ngày")}</th>
          <td></td>
          <td className="text-end">{formatVNDCurrency(0)}</td>
        </tr>
        <tr>
          <td></td>
          <th>{t("Tồn cuối ngày tiền mặt")}</th>
          <td className="text-end">{formatVNDCurrency(0)}</td>
        </tr>
        {endOfDayInventoryCashType.map((item, index) => (
          <Fragment key={index}>
            <tr>
              <td></td>
              <th
                className={`cursor-pointer${item.isCollapsed ? "" : " text-primary"}`}
                onClick={() => {
                  toggleRow("endOfDayInventoryCashType", item);
                }}
              >
                <i
                  className={`${
                    item.isCollapsed ? "ri-arrow-drop-right-line" : "ri-arrow-drop-down-line"
                  } fs-16 align-bottom`}
                ></i>{" "}
                {item.label}
              </th>
              <td className="text-end">{formatVNDCurrency(data?.[item.id]?.revenue || 0)}</td>
            </tr>
            {!item.isCollapsed && (
              <tr className="bg-light">
                <td colSpan={1000}>
                  <ChildTable
                    title={item.id.includes("receipt") ? "Danh sách thu" : "Danh sách chi"}
                    data={data?.[item.id] || []}
                  />
                </td>
              </tr>
            )}
          </Fragment>
        ))}
        <tr>
          <td></td>
          <th>{t("Tồn cuối ngày chuyển khoản")}</th>
          <td className="text-end">{formatVNDCurrency(0)}</td>
        </tr>
        {endOfDayInventoryBankingType.map((item, index) => (
          <Fragment key={index}>
            <tr>
              <td></td>
              <th
                className={`cursor-pointer${item.isCollapsed ? "" : " text-primary"}`}
                onClick={() => {
                  toggleRow("endOfDayInventoryBankingType", item);
                }}
              >
                <i
                  className={`${
                    item.isCollapsed ? "ri-arrow-drop-right-line" : "ri-arrow-drop-down-line"
                  } fs-16 align-bottom`}
                ></i>{" "}
                {item.label}
              </th>
              <td className="text-end">{formatVNDCurrency(data?.[item.id]?.revenue || 0)}</td>
            </tr>
            {!item.isCollapsed && (
              <tr className="bg-light">
                <td colSpan={1000}>
                  <ChildTable
                    title={item.id.includes("receipt") ? "Danh sách thu" : "Danh sách chi"}
                    data={data?.[item.id] || []}
                  />
                </td>
              </tr>
            )}
          </Fragment>
        ))}
        <tr>
          <td></td>
          <th>{t("Tồn cuối ngày quẹt thẻ")}</th>
          <td className="text-end">{formatVNDCurrency(0)}</td>
        </tr>
      </tbody>
    </Table>
  );
};

const ChildTable = ({ title, data = [] }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Table bordered className="bg-white">
        <thead>
          <tr>
            <th colSpan={1000}>{t(title)}</th>
          </tr>
          <tr>
            <th className="text-center">{t("STT")}</th>
            <th>{t("Mã phiếu thu")}</th>
            <th>{t("Mã chứng từ")}</th>
            <th>{t("Phương thức thanh toán")}</th>
            <th className="text-center">{t("Giá trị")}</th>
            <th>{t("Người nộp")}</th>
            <th>{t("Người tạo")}</th>
            <th>{t("Chi nhánh")}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <Fragment key={index}>
              <tr>
                <td className="text-center">{t("STT")}</td>
                <td>{t("Mã phiếu thu")}</td>
                <td>{t("Mã chứng từ")}</td>
                <td>{t("Phương thức thanh toán")}</td>
                <td className="text-center">{t("Giá trị")}</td>
                <td>{t("Người nộp")}</td>
                <td>{t("Người tạo")}</td>
                <td>{t("Chi nhánh")}</td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </Table>
      {data.length === 0 && <div className="text-center">{t("Sorry! No Result Found")}</div>}
    </Fragment>
  );
};
export default ReceiptPaymentReportTable;
