// Actions
export const LIST_TRANSACTION_VOUCHER = "LIST_TRANSACTION_VOUCHER";
export const LIST_TRANSACTION_VOUCHER_SUCCESS = "LIST_TRANSACTION_VOUCHER_SUCCESS";
export const LIST_TRANSACTION_VOUCHER_FAIL = "LIST_TRANSACTION_VOUCHER_FAIL";

export const GET_TRANSACTION_VOUCHER = "GET_TRANSACTION_VOUCHER";
export const GET_TRANSACTION_VOUCHER_SUCCESS = "GET_TRANSACTION_VOUCHER_SUCCESS";
export const GET_TRANSACTION_VOUCHER_FAIL = "GET_TRANSACTION_VOUCHER_FAIL";

export const DELETE_TRANSACTION_VOUCHER = "DELETE_TRANSACTION_VOUCHER";
export const DELETE_TRANSACTION_VOUCHER_SUCCESS = "DELETE_TRANSACTION_VOUCHER_SUCCESS";
export const DELETE_TRANSACTION_VOUCHER_FAIL = "DELETE_TRANSACTION_VOUCHER_FAIL";

export const ADD_NEW_TRANSACTION_VOUCHER = "ADD_NEW_TRANSACTION_VOUCHER";
export const ADD_TRANSACTION_VOUCHER_SUCCESS = "ADD_TRANSACTION_VOUCHER_SUCCESS";
export const ADD_TRANSACTION_VOUCHER_FAIL = "ADD_TRANSACTION_VOUCHER_FAIL";

export const UPDATE_TRANSACTION_VOUCHER = "UPDATE_TRANSACTION_VOUCHER";
export const UPDATE_TRANSACTION_VOUCHER_SUCCESS = "UPDATE_TRANSACTION_VOUCHER_SUCCESS";
export const UPDATE_TRANSACTION_VOUCHER_FAIL = "UPDATE_TRANSACTION_VOUCHER_FAIL";

export const RESET_TRANSACTION_VOUCHER_FLAG = "RESET_TRANSACTION_VOUCHER_FLAG";
