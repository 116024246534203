import {
  LIST_PRODUCT,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  ADD_NEW_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAIL,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  LIST_PRODUCT_FAIL,
  LIST_PRODUCT_SUCCESS,
  GET_PRODUCT,
  GET_PRODUCT_FAIL,
  GET_PRODUCT_SUCCESS,
  RESET_PRODUCT_FLAG,
} from "./actionType";

export const listProduct = (options) => ({
  type: LIST_PRODUCT,
  payload: options,
});

export const listProductFail = (error) => ({
  type: LIST_PRODUCT_FAIL,
  payload: error,
});

export const listProductSuccess = (products) => ({
  type: LIST_PRODUCT_SUCCESS,
  payload: products,
});

export const getProduct = (productID) => ({
  type: GET_PRODUCT,
  payload: productID,
});

export const getProductFail = (error) => ({
  type: GET_PRODUCT_FAIL,
  payload: error,
});

export const getProductSuccess = (product) => ({
  type: GET_PRODUCT_SUCCESS,
  payload: product,
});

export const deleteProduct = (product, handleAfterSubmit = () => {}) => ({
  type: DELETE_PRODUCT,
  payload: { product, handleAfterSubmit },
});

export const deleteProductSuccess = (product) => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: product,
});

export const deleteProductFail = (error) => ({
  type: DELETE_PRODUCT_FAIL,
  payload: error,
});

export const updateProduct = (product, handleAfterSubmit = () => {}) => ({
  type: UPDATE_PRODUCT,
  payload: { product, handleAfterSubmit },
});

export const updateProductSuccess = (product) => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload: product,
});

export const updateProductFail = (error) => ({
  type: UPDATE_PRODUCT_FAIL,
  payload: error,
});

export const addNewProduct = (product, handleAfterSubmit = () => {}) => ({
  type: ADD_NEW_PRODUCT,
  payload: { product, handleAfterSubmit },
});

export const addProductSuccess = (product) => ({
  type: ADD_PRODUCT_SUCCESS,
  payload: product,
});

export const addProductFail = (error) => ({
  type: ADD_PRODUCT_FAIL,
  payload: error,
});

export const resetProductFlag = () => ({
  type: RESET_PRODUCT_FLAG,
});
