import { call, put, takeEvery } from "redux-saga/effects";

import { resetPasswordAPI } from "../../../helpers/AuthAPI";

import { RESET_PASSWORD } from "./actionTypes";

import { resetPassFail, resetPassSuccess } from "./actions";

function* resetPassword({ payload }) {
  try {
    const response = yield call(resetPasswordAPI, payload);
    yield put(resetPassSuccess(response?.message));
  } catch (error) {
    const errorString = String(error);
    if (errorString.includes(":")) {
      yield put(resetPassFail(errorString.split(":")?.at(1).trim()));
    } else {
      yield put(resetPassFail(errorString));
    }
  }
}
function* ResetPassSaga() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export default ResetPassSaga;
