import { Steps } from "antd";
import { useEffect, useState } from "react";
import { formatDate } from "../../../../../helpers/format_helper";

export default function StepProduct({ defaultStep = [], histories = [] }) {
  const [items, setItems] = useState([]);
  const [current, setCurrent] = useState(0);
  const [checkError, setCheckError] = useState(false);
  useEffect(() => {
    if ((histories || []).length > 0) {
      const isCheckCancel = histories.find((item) => item.status === "CANCELLED");
      if (isCheckCancel) {
        setItems([
          {
            title: "Cancelled",
            description: formatDate(isCheckCancel.updated_at),
          },
        ]);
        setCheckError(true);
      } else {
        const newData = defaultStep.map((item) => {
          const isCheck = histories?.find((h) => h.status === item.type);
          if (isCheck) {
            return {
              ...item,
              description: formatDate(isCheck.updated_at),
            };
          }
          return item;
        });
        setItems(newData);
        setCurrent(histories.length);
      }
    } else {
      setItems(defaultStep);
    }
  }, [defaultStep, histories]);

  return (
    <div>
      <Steps
        direction="vertical"
        // size="small"
        // percent={50}
        status={checkError ? "error" : ""}
        current={current}
        items={items}
      />
    </div>
  );
}
