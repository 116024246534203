import {
  LIST_ROLE,
  LIST_ROLE_FAIL,
  LIST_ROLE_SUCCESS,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
  ADD_NEW_ROLE,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAIL,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAIL,
  GET_ROLE,
  GET_ROLE_FAIL,
  GET_ROLE_SUCCESS,
  RESET_ROLE,
} from "./actionType";

export const listRole = (options) => ({
  type: LIST_ROLE,
  payload: options,
});

export const listRoleFail = (error) => ({
  type: LIST_ROLE_FAIL,
  payload: error,
});

export const listRoleSuccess = (roles) => ({
  type: LIST_ROLE_SUCCESS,
  payload: roles,
});

export const getRole = (roleID) => ({
  type: GET_ROLE,
  payload: roleID,
});

export const getRoleFail = (error) => ({
  type: GET_ROLE_FAIL,
  payload: error,
});

export const getRoleSuccess = (role) => ({
  type: GET_ROLE_SUCCESS,
  payload: role,
});

export const deleteRole = (role, handleAfterSubmit = () => {}) => ({
  type: DELETE_ROLE,
  payload: { role, handleAfterSubmit },
});

export const deleteRoleSuccess = (role) => ({
  type: DELETE_ROLE_SUCCESS,
  payload: role,
});

export const deleteRoleFail = (error) => ({
  type: DELETE_ROLE_FAIL,
  payload: error,
});

export const updateRole = (role, handleAfterSubmit = () => {}) => ({
  type: UPDATE_ROLE,
  payload: { role, handleAfterSubmit },
});

export const updateRoleSuccess = (role) => ({
  type: UPDATE_ROLE_SUCCESS,
  payload: role,
});

export const updateRoleFail = (error) => ({
  type: UPDATE_ROLE_FAIL,
  payload: error,
});

export const addNewRole = (role, handleAfterSubmit = () => {}) => ({
  type: ADD_NEW_ROLE,
  payload: { role, handleAfterSubmit },
});

export const addRoleSuccess = (role) => ({
  type: ADD_ROLE_SUCCESS,
  payload: role,
});

export const addRoleFail = (error) => ({
  type: ADD_ROLE_FAIL,
  payload: error,
});

export const resetRole = () => ({
  type: RESET_ROLE,
});
