import {
  LIST_ORDER,
  LIST_ORDER_FAIL,
  LIST_ORDER_SUCCESS,
  DELETE_ORDER,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAIL,
  ADD_NEW_ORDER,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FAIL,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  GET_ORDER,
  GET_ORDER_FAIL,
  GET_ORDER_SUCCESS,
  RESET_ORDER_FLAG,
} from "./actionType";

export const listOrder = (options) => ({
  type: LIST_ORDER,
  payload: options,
});

export const listOrderFail = (error) => ({
  type: LIST_ORDER_FAIL,
  payload: error,
});

export const listOrderSuccess = (orders) => ({
  type: LIST_ORDER_SUCCESS,
  payload: orders,
});

export const getOrder = (orderID) => ({
  type: GET_ORDER,
  payload: orderID,
});

export const getOrderFail = (error) => ({
  type: GET_ORDER_FAIL,
  payload: error,
});

export const getOrderSuccess = (order) => ({
  type: GET_ORDER_SUCCESS,
  payload: order,
});

export const deleteOrder = (order) => ({
  type: DELETE_ORDER,
  payload: order,
});

export const deleteOrderSuccess = (order) => ({
  type: DELETE_ORDER_SUCCESS,
  payload: order,
});

export const deleteOrderFail = (error) => ({
  type: DELETE_ORDER_FAIL,
  payload: error,
});

export const updateOrder = (order) => ({
  type: UPDATE_ORDER,
  payload: order,
});

export const updateOrderSuccess = (order) => ({
  type: UPDATE_ORDER_SUCCESS,
  payload: order,
});

export const updateOrderFail = (error) => ({
  type: UPDATE_ORDER_FAIL,
  payload: error,
});

export const addNewOrder = (order, handleAfterSubmit = () => {}) => ({
  type: ADD_NEW_ORDER,
  payload: { order, handleAfterSubmit },
});

export const addOrderSuccess = (order) => ({
  type: ADD_ORDER_SUCCESS,
  payload: order,
});

export const addOrderFail = (error) => ({
  type: ADD_ORDER_FAIL,
  payload: error,
});

export const resetOrderFlag = () => ({
  type: RESET_ORDER_FLAG,
});
