import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";


// Redux States
import {
  LIST_STORE,
  DELETE_STORE,
  ADD_NEW_STORE,
  UPDATE_STORE,
  GET_STORE,
  /////////////////////
  LIST_APP_STORE,
  GET_APP_STORE,
  LIST_APP,
  DELETE_APP,
} from "./actionType";

import {
  deleteStoreSuccess,
  deleteStoreFail,
  addStoreSuccess,
  addStoreFail,
  updateStoreSuccess,
  updateStoreFail,
  listStoreSuccess,
  listStoreFail,
  getStoreSuccess,
  getStoreFail,
  ////
  listAppStoreSuccess,
  listAppStoreFail,
  getAppStoreSuccess,
  getAppStoreFail,
  listAppSuccess,
  listAppFail,
  deleteAppSuccess,
  deleteAppFail,
} from "./action";

import { appStoresAPI, appsAPI, storesAPI } from "../../../../helpers/resource_helper";

// Redux States

function* listStore({ payload: { ...res } }) {
  try {
    const response = yield call(storesAPI.list, { ...res });
    yield put(listStoreSuccess(response));
  } catch (error) {
    yield put(listStoreFail(error));
  }
}

function* getStore({ payload: id }) {
  try {
    const response = yield call(storesAPI.get, { id });
    yield put(getStoreSuccess(response));
  } catch (error) {
    yield put(getStoreFail(error));
  }
}

function* deleteStores({ payload: store }) {
  try {
    const response = yield call(storesAPI.delete, store);
    yield put(deleteStoreSuccess(store));
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteStoreFail(error));
    toast.error("Store Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewStore({ payload: store }) {
  try {
    const response = yield call(storesAPI.create, store);
    yield put(addStoreSuccess(response));
    toast.success("Store Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addStoreFail(error));
    toast.error("Store Added Failed", { autoClose: 3000 });
  }
}

function* onUpdateStore({ payload: store }) {
  try {
    const response = yield call(storesAPI.put, store);
    yield put(updateStoreSuccess(response));
    toast.success("Store Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateStoreFail(error));
    toast.error("Store Updated Failed", { autoClose: 3000 });
  }
}

////////////////////////////
function* listAppStore({ payload: { ...res } }) {
  try {
    const response = yield call(appStoresAPI.list, { ...res });
    yield put(listAppStoreSuccess(response));
  } catch (error) {
    yield put(listAppStoreFail(error));
  }
}

function* getAppStore({ payload: id }) {
  try {
    const response = yield call(appStoresAPI.get, { id: `${id}/status` });
    yield put(getAppStoreSuccess(response));
  } catch (error) {
    yield put(getAppStoreFail(error));
  }
}

export function* watchListAppStore() {
  yield takeEvery(LIST_APP_STORE, listAppStore);
}
export function* watchGetAppStore() {
  yield takeEvery(GET_APP_STORE, getAppStore);
}

export function* watchListStore() {
  yield takeEvery(LIST_STORE, listStore);
}

export function* watchDeleteStores() {
  yield takeEvery(DELETE_STORE, deleteStores);
}

export function* watchGetStore() {
  yield takeEvery(GET_STORE, getStore);
}

////////////////////////////////

function* listApp({ payload: { ...res } }) {
  try {
    const response = yield call(appsAPI.list, { ...res });
    yield put(listAppSuccess(response));
  } catch (error) {
    yield put(listAppFail(error));
  }
}

function* deleteApp({ payload: { app, handleAfterSubmit } }) {
  try {
    const response = yield call(appsAPI.delete, app);
    yield put(deleteAppSuccess(app));
    handleAfterSubmit();
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteAppFail(error));
    toast.error("App Delete Failed", { autoClose: 3000 });
  }
}

export function* watchListApp() {
  yield takeEvery(LIST_APP, listApp);
}

export function* watchDeleteApp() {
  yield takeEvery(DELETE_APP, deleteApp);
}

////////////////////
export function* watchUpdateStore() {
  yield takeEvery(UPDATE_STORE, onUpdateStore);
}

export function* watchAddNewStore() {
  yield takeEvery(ADD_NEW_STORE, onAddNewStore);
}

function* storesSaga() {
  yield all([
    fork(watchGetStore),
    fork(watchListStore),
    fork(watchUpdateStore),
    fork(watchAddNewStore),
    fork(watchDeleteStores),
    /////
    fork(watchGetAppStore),
    fork(watchListAppStore),
    /////
    fork(watchListApp),
    fork(watchDeleteApp),
  ]);
}

export default storesSaga;
