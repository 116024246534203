import { Actions } from "../../../../Components/constants/common";
import {
  LIST_VOUCHER,
  DELETE_VOUCHER,
  DELETE_VOUCHER_SUCCESS,
  DELETE_VOUCHER_FAIL,
  ADD_VOUCHER_SUCCESS,
  ADD_VOUCHER_FAIL,
  UPDATE_VOUCHER_SUCCESS,
  UPDATE_VOUCHER_FAIL,
  LIST_VOUCHER_SUCCESS,
  LIST_VOUCHER_FAIL,
  ADD_NEW_VOUCHER,
  UPDATE_VOUCHER,
  GET_VOUCHER,
  GET_VOUCHER_SUCCESS,
  GET_VOUCHER_FAIL,
  RESET_VOUCHER_FLAG,
} from "./actionType";

const INIT_STATE = {
  vouchers: [],
  voucher: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const Vouchers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_VOUCHER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_VOUCHER_SUCCESS:
      return {
        ...state,
        message: "Get vouchers success",
        loading: false,
        success: true,
        vouchers: action.payload.items ?? [],
        limit: Number(action?.payload?.limit || 0),
        page: Number(action?.payload?.page || 0),
        total: Number(action?.payload?.total || 0),
        action: Actions.EMPTY,
      };

    case LIST_VOUCHER_FAIL:
      return {
        ...state,
        message: "Get vouchers failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_VOUCHER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_VOUCHER_SUCCESS:
      return {
        ...state,
        message: "Get voucher success",
        loading: false,
        success: true,
        voucher: action.payload,
        action: Actions.EMPTY,
      };

    case GET_VOUCHER_FAIL:
      return {
        ...state,
        message: "Get voucher failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_VOUCHER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_VOUCHER_SUCCESS:
      return {
        ...state,
        message: "Delete voucher success",
        loading: false,
        success: true,
        vouchers: state.vouchers.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_VOUCHER_FAIL:
      return {
        ...state,
        message: "Delete voucher failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_NEW_VOUCHER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_VOUCHER_SUCCESS:
      return {
        ...state,
        message: "Add voucher success",
        success: true,
        loading: false,
        vouchers: [...state.vouchers, action.payload],
        action: Actions.EMPTY,
      };

    case ADD_VOUCHER_FAIL:
      return {
        ...state,
        message: "Add voucher failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_VOUCHER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.UPDATE,
      };
    case UPDATE_VOUCHER_SUCCESS:
      return {
        ...state,
        message: "Update voucher success",
        loading: false,
        success: true,
        vouchers: state.vouchers.map((voucher) =>
          voucher.id.toString() === action.payload.id.toString() ? { ...voucher, ...action.payload } : voucher,
        ),
        action: Actions.EMPTY,
      };

    case UPDATE_VOUCHER_FAIL:
      return {
        ...state,
        message: "Update voucher failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case RESET_VOUCHER_FLAG:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Vouchers;
