import CopyToClipboardButton from "./CopyToClipboardButton ";

const CopyableText = ({ text, children }) => {
  return (
    <div className="hstack gap-2">
      {children}
      {text && <CopyToClipboardButton textToCopy={text} />}
    </div>
  );
};
export default CopyableText;
