import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";

// Redux States
import {
  LIST_LOYALTY_PROGRAM,
  DELETE_LOYALTY_PROGRAM,
  ADD_NEW_LOYALTY_PROGRAM,
  UPDATE_LOYALTY_PROGRAM,
  GET_LOYALTY_PROGRAM,
} from "./actionType";

import {
  deleteLoyaltyProgramSuccess,
  deleteLoyaltyProgramFail,
  addLoyaltyProgramSuccess,
  addLoyaltyProgramFail,
  updateLoyaltyProgramSuccess,
  updateLoyaltyProgramFail,
  listLoyaltyProgramSuccess,
  listLoyaltyProgramFail,
  getLoyaltyProgramSuccess,
  getLoyaltyProgramFail,
} from "./action";

import { loyaltyProgramsAPI } from "../../../../helpers/resource_helper";

function* listLoyaltyProgram({ payload: { ...res } }) {
  try {
    const response = yield call(loyaltyProgramsAPI.list, { ...res });
    yield put(listLoyaltyProgramSuccess(response));
  } catch (error) {
    yield put(listLoyaltyProgramFail(error));
  }
}

function* getLoyaltyProgram({ payload: id }) {
  try {
    const response = yield call(loyaltyProgramsAPI.get, { id });
    yield put(getLoyaltyProgramSuccess(response));
  } catch (error) {
    yield put(getLoyaltyProgramFail(error));
  }
}

function* deleteLoyaltyProgram({ payload: { loyaltyProgram, handleAfterSubmit } }) {
  try {
    const response = yield call(loyaltyProgramsAPI.delete, loyaltyProgram);
    yield put(deleteLoyaltyProgramSuccess(loyaltyProgram));
    toast.success(response?.message, { autoClose: 3000 });
    yield call(handleAfterSubmit);
  } catch (error) {
    yield put(deleteLoyaltyProgramFail(error));
    toast.error("Loyalty Program Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewLoyaltyProgram({ payload: { loyaltyProgram, handleAfterSubmit } }) {
  try {
    const response = yield call(loyaltyProgramsAPI.create, loyaltyProgram);
    yield put(addLoyaltyProgramSuccess(response));
    toast.success(response?.message, { autoClose: 3000 });
    toast.success("Loyalty Program Added Successfully", { autoClose: 3000 });
    yield call(handleAfterSubmit, response);
  } catch (error) {
    yield put(addLoyaltyProgramFail(error));
    toast.error(error, { autoClose: 3000 });
  }
}

function* onUpdateLoyaltyProgram({ payload: { loyaltyProgram, handleAfterSubmit } }) {
  try {
    const response = yield call(loyaltyProgramsAPI.put, loyaltyProgram);
    yield put(updateLoyaltyProgramSuccess(response));
    toast.success("Loyalty Program Updated Successfully", { autoClose: 3000 });
    yield call(handleAfterSubmit, response);
  } catch (error) {
    yield put(updateLoyaltyProgramFail(error));
    toast.error(error, { autoClose: 3000 });
  }
}

export function* watchGetLoyaltyPrograms() {
  yield takeEvery(LIST_LOYALTY_PROGRAM, listLoyaltyProgram);
}

export function* watchDeleteLoyaltyProgram() {
  yield takeEvery(DELETE_LOYALTY_PROGRAM, deleteLoyaltyProgram);
}

export function* watchGetLoyaltyProgram() {
  yield takeEvery(GET_LOYALTY_PROGRAM, getLoyaltyProgram);
}

export function* watchUpdateLoyaltyProgram() {
  yield takeEvery(UPDATE_LOYALTY_PROGRAM, onUpdateLoyaltyProgram);
}

export function* watchAddNewLoyaltyProgram() {
  yield takeEvery(ADD_NEW_LOYALTY_PROGRAM, onAddNewLoyaltyProgram);
}

function* loyaltyProgramsSaga() {
  yield all([
    fork(watchGetLoyaltyProgram),
    fork(watchGetLoyaltyPrograms),
    fork(watchUpdateLoyaltyProgram),
    fork(watchAddNewLoyaltyProgram),
    fork(watchDeleteLoyaltyProgram),
  ]);
}

export default loyaltyProgramsSaga;
