import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Dropdown, DropdownItem, DropdownMenu } from "reactstrap";

import { brandsAPI, categoriesAPI, productsAPI, variantsAPI } from "../../helpers/resource_helper";
import { formatVNDCurrency } from "../../helpers/format_helper";

import Loader from "./Loader";
import SearchInput from "./SearchInput";
import { withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useListData } from "../Hooks/useListData";
import LottieIcon from "./LottieIcon";

const SearchDropdown = ({
  onClickItem,
  isOpen,
  toggleOff,
  toggleOn,
  width = "100%",
  typeSearch = "variants",
  isShowDefault = false,
  customSubSearch,
  disabled,
  placeholder = "Search variant by name, sku, barcode,... (F3)",
  location = null,
  priceGroup = null,
  t,
}) => {
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const resource =
    typeSearch === "products"
      ? productsAPI
      : typeSearch === "variants"
      ? variantsAPI
      : typeSearch === "category"
      ? categoriesAPI
      : brandsAPI;

  const { page, loading, hasMore, items, query, debouncedQuery, onChangeQuery, onSearch, listData } = useListData({
    resource,
    isScroll: true,
  });

  const handleToggleOff = () => {
    onChangeQuery("");
    toggleOff();
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      handleToggleOff();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      onSearch();
    }
  }, [debouncedQuery, isOpen]);

  return (
    <div ref={dropdownRef}>
      <div className="d-flex align-items-center">
        <SearchInput
          toggleOn={toggleOn}
          searchValue={query}
          onChangeValue={onChangeQuery}
          placeholder={t(placeholder)}
          className="w-100"
          disabled={disabled}
        />
        {customSubSearch}
      </div>
      <Dropdown isOpen={isOpen} toggle={handleToggleOff}>
        <DropdownMenu className="p-0 mt-1" style={{ width }}>
          {!isShowDefault && (
            <DropdownItem
              className="border-bottom position-sticky top-0"
              onClick={() => {
                navigate("/products/new");
              }}
            >
              <div className="d-flex">
                <span className="me-3 text-center d-flex align-items-center" style={{ width: "2rem" }}>
                  <i className="bx bx-plus fs-22 text-primary"></i>
                </span>
                <h5 className="flex-grow-1 fw-semibold mb-0 d-flex align-items-center text-primary">
                  {t("Add new product")}
                </h5>
              </div>
            </DropdownItem>
          )}

          <div style={{ maxHeight: "400px", overflow: "auto" }} id="search-drop-down">
            {loading ? (
              <div className="p-3">
                <Loader />
              </div>
            ) : (
              <InfiniteScroll
                dataLength={items.length}
                next={() => {
                  listData(query, page + 1);
                }}
                hasMore={hasMore}
                scrollableTarget="search-drop-down"
              >
                {items.length > 0 ? (
                  items.map((item, index) => {
                    const available = item.inventories
                      ? item.inventories.reduce((total, inv) => {
                          if (!location) {
                            return total + inv.available;
                          } else {
                            return location.id === inv.location_id ? total + inv.available : total;
                          }
                        }, 0) || 0
                      : 0;
                    const on_hand = item.inventories
                      ? item.inventories.reduce((total, inv) => {
                          if (!location) {
                            return total + inv.on_hand;
                          } else {
                            return location.id === inv.location_id ? total + inv.on_hand : total;
                          }
                        }, 0) || 0
                      : 0;
                    const price = priceGroup
                      ? (item?.prices || []).find(
                          (priceItem) => priceItem.price_group.id === (priceGroup?.id || priceGroup?.value),
                        )?.price || item.prices?.[0]?.price
                      : item.prices?.[0]?.price;
                    return (
                      <DropdownItem key={index} onClick={() => onClickItem(item)} className="border-bottom">
                        <div className="hstack">
                          <div className="d-flex">
                            <img
                              src={Array.isArray(item.images) ? item.images?.[0]?.url : item.image?.url}
                              className="me-3 avatar-xs "
                              alt=""
                            />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="fw-semibold text-truncate-two-lines">{t(item.name)}</h5>
                            <div className="fs-13 text-muted">
                              <p className="mb-1">{item.sku}</p>
                            </div>
                          </div>
                          <div className="px-2 flex-shrink-0">
                            {!isShowDefault && (
                              <>
                                <h5 className="fw-semibold text-end">{formatVNDCurrency(price)}</h5>
                                <div>
                                  <span className="text-muted">{t("Available")}: </span>
                                  <span className={`fw-semibold ${available === 0 ? "text-danger" : "text-dark"}`}>
                                    {available}
                                  </span>{" "}
                                  <span className="text-muted">| {t("On hand")}: </span>
                                  <span className={`fw-semibold ${on_hand === 0 ? "text-danger" : "text-dark"}`}>
                                    {on_hand}
                                  </span>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </DropdownItem>
                    );
                  })
                ) : (
                  <div className="py-4 text-center">
                    <LottieIcon
                      style={{ width: "72px", height: "72px" }}
                      src="https://cdn.lordicon.com/msoeawqm.json"
                    />
                    <div className="mt-4">
                      <h5>{t("Sorry! No Result Found")}</h5>
                    </div>
                  </div>
                )}
              </InfiniteScroll>
            )}
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default withTranslation()(SearchDropdown);
