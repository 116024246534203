import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";

// Redux States
import {
  LIST_CUSTOMER_GROUP,
  DELETE_CUSTOMER_GROUP,
  ADD_NEW_CUSTOMER_GROUP,
  UPDATE_CUSTOMER_GROUP,
  GET_CUSTOMER_GROUP,
} from "./actionType";

import {
  deleteCustomerGroupSuccess,
  deleteCustomerGroupFail,
  addCustomerGroupSuccess,
  addCustomerGroupFail,
  updateCustomerGroupSuccess,
  updateCustomerGroupFail,
  listCustomerGroupSuccess,
  listCustomerGroupFail,
  getCustomerGroupSuccess,
  getCustomerGroupFail,
} from "./action";

import { customerGroupsAPI } from "../../../../helpers/resource_helper";

function* listCustomerGroup({ payload: { ...res } }) {
  try {
    const response = yield call(customerGroupsAPI.list, { ...res });
    yield put(listCustomerGroupSuccess(response));
  } catch (error) {
    yield put(listCustomerGroupFail(error));
  }
}

function* getCustomerGroup({ payload: id }) {
  try {
    const response = yield call(customerGroupsAPI.get, { id });
    yield put(getCustomerGroupSuccess(response));
  } catch (error) {
    yield put(getCustomerGroupFail(error));
  }
}

function* deleteCustomerGroup({ payload: { customerGroup, handleAfterSubmit } }) {
  try {
    const response = yield call(customerGroupsAPI.delete, customerGroup);
    yield put(deleteCustomerGroupSuccess(customerGroup));
    toast.success(response?.message, { autoClose: 3000 });
    yield call(handleAfterSubmit);
  } catch (error) {
    console.log(error)
    yield put(deleteCustomerGroupFail(error));
    toast.error("Customer Group Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewCustomerGroup({ payload: { customerGroup, handleAfterSubmit } }) {
  try {
    const response = yield call(customerGroupsAPI.create, customerGroup);
    yield put(addCustomerGroupSuccess(response));
    toast.success(response?.message, { autoClose: 3000 });
    toast.success("Customer Group Added Successfully", { autoClose: 3000 });
    yield call(handleAfterSubmit, response);
  } catch (error) {
    yield put(addCustomerGroupFail(error));
    toast.error("Customer Group Added Failed", { autoClose: 3000 });
  }
}

function* onUpdateCustomerGroup({ payload: { customerGroup, handleAfterSubmit } }) {
  try {
    const response = yield call(customerGroupsAPI.put, customerGroup);
    yield put(updateCustomerGroupSuccess(response));
    toast.success("Customer Group Updated Successfully", { autoClose: 3000 });
    yield call(handleAfterSubmit, response);
  } catch (error) {
    yield put(updateCustomerGroupFail(error));
    toast.error("Customer Group Updated Failed", { autoClose: 3000 });
  }
}

export function* watchGetCustomerGroups() {
  yield takeEvery(LIST_CUSTOMER_GROUP, listCustomerGroup);
}

export function* watchDeleteCustomerGroup() {
  yield takeEvery(DELETE_CUSTOMER_GROUP, deleteCustomerGroup);
}

export function* watchGetCustomerGroup() {
  yield takeEvery(GET_CUSTOMER_GROUP, getCustomerGroup);
}

export function* watchUpdateCustomerGroup() {
  yield takeEvery(UPDATE_CUSTOMER_GROUP, onUpdateCustomerGroup);
}

export function* watchAddNewCustomerGroup() {
  yield takeEvery(ADD_NEW_CUSTOMER_GROUP, onAddNewCustomerGroup);
}

function* customerGroupsSaga() {
  yield all([
    fork(watchGetCustomerGroup),
    fork(watchGetCustomerGroups),
    fork(watchUpdateCustomerGroup),
    fork(watchAddNewCustomerGroup),
    fork(watchDeleteCustomerGroup),
  ]);
}

export default customerGroupsSaga;
