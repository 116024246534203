import { Table } from "reactstrap";
import { deepCopy, formatVNDCurrency } from "../../../../helpers/format_helper";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReturnOrderOptions } from "../../../../Components/constants/common";

const ReturnReportTable = ({ data }) => {
  const { t } = useTranslation();
  const returnOrderStatusOptions = ReturnOrderOptions.map((item) => {
    item.isCollapsed = true;
    return item;
  });
  const [statuses, setStatuses] = useState(returnOrderStatusOptions);

  const toggleRow = (selectedRow) => {
    const newStatuses = deepCopy(statuses);
    const newData = newStatuses.map((item) => {
      if (item.id === selectedRow.id) {
        item.isCollapsed = !selectedRow.isCollapsed;
      }
      return item;
    });
    setStatuses(newData);
  };

  return (
    <Table bordered>
      <thead>
        <tr className="bg-light text-center">
          <th colSpan={1000}>{t("Thông tin trả hàng")}</th>
        </tr>
        <tr>
          <th></th>
          <th className="text-center">{t("Số lượng")} </th>
          <th className="text-center">{t("Tổng tiền trả hàng")}</th>
        </tr>
      </thead>
      <tbody>
        {statuses.map((item, index) => (
          <Fragment key={index}>
            <tr>
              <th
                className={`cursor-pointer${item.isCollapsed ? "" : " text-primary"}`}
                onClick={() => {
                  toggleRow(item);
                }}
              >
                <i
                  className={`${
                    item.isCollapsed ? "ri-arrow-drop-right-line" : "ri-arrow-drop-down-line"
                  } fs-16 align-bottom`}
                ></i>{" "}
                {t(item.name)}
              </th>
              <td className="text-center">{data?.[item.id]?.order_count || 0}</td>
              <td className="text-end">{formatVNDCurrency(data?.[item.id]?.total_revenue || 0)}</td>
            </tr>
            {!item.isCollapsed && (
              <tr className="bg-light">
                <td colSpan={1000}>
                  <ChildTable data={data?.[item.id]?.orders || []} />
                </td>
              </tr>
            )}
          </Fragment>
        ))}
      </tbody>
    </Table>
  );
};

const ChildTable = ({ data = [] }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Table bordered className="bg-white">
        <thead>
          <tr>
            <th colSpan={1000}>{t("Danh sách đơn trả hàng")}</th>
          </tr>
          <tr>
            <th className="text-center">{t("STT")}</th>
            <th>{t("Mã đơn trả")}</th>
            <th className="text-end">{t("Tổng tiền trả")}</th>
            <th>{t("Khách hàng")} </th>
            <th>{t("Chi nhánh")}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <Fragment key={index}>
              <tr>
                <td className="text-center">{t("STT")}</td>
                <td>{t("Mã đơn trả")}</td>
                <td className="text-end">{t("Tổng tiền trả")}</td>
                <td>{t("Khách hàng")} </td>
                <td>{t("Chi nhánh")}</td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </Table>
      {data.length === 0 && <div className="text-center">{t("Sorry! No Result Found")}</div>}
    </Fragment>
  );
};
export default ReturnReportTable;
