import React, { useEffect, useState } from "react";
import useMemoizedSelector from "../Hooks/useMemoizedSelector";
import { useDispatch } from "react-redux";
import { closeTour, handleOpenTour } from "../../store/tour/action";
import { Tour } from "antd";

export default function TourComponent() {
  const dispatch = useDispatch();
  const {
    openTour,
    typeTour,
    steps = [],
  } = useMemoizedSelector((state) => ({
    openTour: state.Tour.openTour,
    typeTour: state.Tour.typeTour,
    steps: state.Tour.steps,
  }));
  const [currentSteps, setCurrentSteps] = useState(0);

  const localStorageTour = () => {
    const localStorageTour = localStorage.getItem("tour");
    const tour = localStorageTour ? JSON.parse(localStorageTour) : null;
    return tour;
  };

  useEffect(() => {
    if (typeTour && steps.length > 0) {
      const local = localStorageTour();
      if (!local?.[typeTour]) {
        dispatch(handleOpenTour());
      } else {
        dispatch(closeTour());
      }
      setCurrentSteps(0);
    }
  }, [typeTour, steps, dispatch]);

  useEffect(() => {
    setCurrentSteps(0);
  }, [openTour]);

  return (
    <Tour
      onChange={(current) => {
        setCurrentSteps(current);
      }}
      open={typeTour && steps.length > 0 ? openTour : false}
      current={currentSteps}
      onClose={() => {
        dispatch(closeTour());
        const local = localStorageTour();
        const cloneLocal = local ? { ...local, [typeTour]: true } : { [typeTour]: true };
        localStorage.setItem("tour", JSON.stringify(cloneLocal));
      }}
      steps={steps}
      zIndex={1200}
    />
  );
}
