import { useEffect, useMemo, useState } from "react";
import SimpleTableContainer from "../../../../Components/Common/SimpleTableContainer";
import { Input } from "reactstrap";
import { Tooltip } from "antd";
import { deepCopy, formatVNDCurrency, returnDiscountInfoFieldByType } from "../../../../helpers/format_helper";
import { DiscountAmountType, DiscountTypes, EffectiveProduct } from "../../../../Components/constants/common";
import { useTranslation } from "react-i18next";

const ViewConditionBody = ({
  discount,
  selectedPromotionId,
  order_line_items,
  discountDetails,
  onChangeTempDiscountDetails,
  onChangeSelectedPromotionId,
}) => {
  const { t } = useTranslation();
  const [filteredOrderLineItems, setFilteredOrderLineItems] = useState([]);
  const [tempDiscountDetails, setTempDiscountDetails] = useState([]);
  const onCheck = (row) => {
    const newDiscountDetails = [...tempDiscountDetails];
    const discountDetailsIndex = newDiscountDetails.findIndex((item) => item.sku === row.sku);
    if (discountDetailsIndex > -1) {
      newDiscountDetails.splice(discountDetailsIndex, 1);
    } else {
      newDiscountDetails.push(row);
    }

    onChangeSelectedPromotionId(newDiscountDetails.length > 0 ? discount.id : null);
    onChangeTempDiscountDetails(newDiscountDetails);
  };
  const onRowClick = (row) => {
    const newDiscountDetails = [...tempDiscountDetails];
    const discountDetailsIndex = newDiscountDetails.findIndex((item) => item.sku === row.original.sku);
    if (discountDetailsIndex > -1) {
      newDiscountDetails.splice(discountDetailsIndex, 1);
    } else {
      newDiscountDetails.push(row.original);
    }

    onChangeSelectedPromotionId(newDiscountDetails.length > 0 ? discount.id : null);
    onChangeTempDiscountDetails(newDiscountDetails);
  };
  const columns = useMemo(
    () => [
      {
        id: "row",
        Header: "",
        Cell: (cell) => {
          let product = cell.row.original;
          return (
            <div>
              <Input
                type="checkbox"
                checked={tempDiscountDetails && tempDiscountDetails.some((item) => item.sku === product.sku)}
                onChange={(e) => {
                  e.preventDefault();
                  onCheck(product);
                }}
              />
            </div>
          );
        },
      },
      {
        id: "product",
        Header: t("Purchased product"),
        Cell: (cell) => {
          let product = cell.row.original;
          return (
            <div className="d-flex flex-column justify-content-center">
              <h5 className="fs-15">
                <Tooltip title={t(product?.name || "---")}>
                  <div className="d-inline-block link-primary text-truncate" style={{ maxWidth: "400px" }}>
                    {t(product?.name || "---")}
                  </div>
                </Tooltip>
              </h5>
              <p className="text-muted fw-medium mb-1">
                {t("Sku")}: {product.sku}
              </p>
            </div>
          );
        },
      },
      {
        id: "quantity",
        Header: () => <div className="text-center">{t("Quantity")}</div>,
        Cell: (cell) => {
          let product = cell.row.original;
          return <div className={`text-center`}>{product?.quantity}</div>;
        },
      },
      {
        id: "price",
        Header: () => <div className="text-end">{t("Price")}</div>,
        Cell: (cell) => {
          let product = cell.row.original;
          return <div className="text-end">{formatVNDCurrency(product?.unit_price)}</div>;
        },
      },
      {
        id: "product_discount",
        Header: () => <div className={"text-end"}>{t("Discount")}</div>,
        Cell: (cell) => {
          let product = cell.row.original;
          let index = cell.row.index;
          let discountValue = product.unit_price - product.sale_price;
          return <div className="text-end">{formatVNDCurrency(discountValue)}</div>;
        },
      },
      {
        id: "total",
        Header: () => <div className="text-end">{t("Total Amount")}</div>,
        Cell: (cell) => {
          let product = cell.row.original;
          let total = product.quantity * product.sale_price;
          return <div className="fw-medium text-end">{formatVNDCurrency(total)}</div>;
        },
      },
    ],
    [filteredOrderLineItems, tempDiscountDetails],
  );

  const handleReturnFilteredOrderLineItems = (c, items) => {
    let filtered_order_items = [];
    const item_type = c["item_type"];
    const condition_line_key = `${item_type.toLowerCase()}`;
    filtered_order_items = items.filter((order_line_item) => {
      return c[condition_line_key].find((item) => {
        if (item_type === EffectiveProduct.PRODUCTS) {
          return item.id === order_line_item.product_id;
        }
        if (item_type === EffectiveProduct.VARIANTS) {
          return item.id === order_line_item.variant_id;
        }
        if (item_type === EffectiveProduct.CATEGORIES) {
          return item.id === order_line_item.category?.id;
        }
        if (item_type === EffectiveProduct.PRODUCTS) {
          return item.id === order_line_item.brand?.id;
        }
        return false;
      });
    });
    return filtered_order_items;
  };

  useEffect(() => {
    const conditions = discount[returnDiscountInfoFieldByType(discount.type)];
    // check discount by product condition
    let newFilterOrderLineItems = [];
    const new_order_line_items = deepCopy(order_line_items);
    if (discount.type === DiscountTypes.BY_PRODUCT) {
      const for_all_items = conditions?.[0]?.for_all_items;
      if (typeof for_all_items === "boolean") {
        conditions.forEach((c) => {
          if (for_all_items) {
            const total_quantity = new_order_line_items.reduce(
              (total, order_line_item) => total + Number(order_line_item.quantity),
              0,
            );
            if (c.min_quantity <= total_quantity && total_quantity <= c.discount_limit) {
              newFilterOrderLineItems = new_order_line_items.map((item) => {
                item.sale_price =
                  item.unit_price - (c.type === DiscountAmountType.VALUE ? c.amount : (c.rate * item.unit_price) / 100);
                return item;
              });
            }
          } else {
            const filtered_order_items = handleReturnFilteredOrderLineItems(c, new_order_line_items);
            const total_quantity = filtered_order_items.reduce(
              (total, order_line_item) => total + Number(order_line_item.quantity),
              0,
            );
            if (c.min_quantity <= total_quantity && total_quantity <= c.discount_limit) {
              newFilterOrderLineItems = [
                ...newFilterOrderLineItems,
                ...filtered_order_items.map((item) => {
                  item.sale_price =
                    item.unit_price - (c.type === DiscountAmountType.VALUE ? c.amount : (c.rate * item.unit_price) / 100);
                  return item;
                }),
              ];
            }
          }
        });
      }
    }

    // check discount by quantity condition
    if (discount.type === DiscountTypes.BY_QUANTITY) {
      const for_all_items = conditions?.[0]?.for_all_items;
      if (typeof for_all_items === "boolean") {
        conditions.forEach((c) => {
          if (for_all_items) {
            const total_quantity = new_order_line_items.reduce(
              (total, order_line_item) => total + Number(order_line_item.quantity),
              0,
            );
            const quantityCondition = c.conditions.find((cc) =>
              cc.quantity_to
                ? cc.quantity_from <= total_quantity && total_quantity <= cc.quantity_to
                : cc.quantity_from <= total_quantity,
            );
            if (quantityCondition) {
              newFilterOrderLineItems = new_order_line_items.map((item) => {
                item.sale_price =
                  item.unit_price -
                  (quantityCondition.type === DiscountAmountType.VALUE
                    ? quantityCondition.amount
                    : (quantityCondition.rate * item.unit_price) / 100);
                return item;
              });
            }
          } else {
            const filtered_order_items = handleReturnFilteredOrderLineItems(c, new_order_line_items);
            const total_quantity = filtered_order_items.reduce(
              (total, order_line_item) => total + Number(order_line_item.quantity),
              0,
            );
            const quantityCondition = c.conditions.find((cc) =>
              cc.quantity_to
                ? cc.quantity_from <= total_quantity && total_quantity <= cc.quantity_to
                : cc.quantity_from <= total_quantity,
            );
            if (quantityCondition) {
              newFilterOrderLineItems = [
                ...newFilterOrderLineItems,
                ...filtered_order_items.map((item) => {
                  item.sale_price =
                    item.unit_price -
                    (quantityCondition.type === DiscountAmountType.VALUE
                      ? quantityCondition.amount
                      : (quantityCondition.rate * item.unit_price) / 100);
                  return item;
                }),
              ];
            }
          }
        });
      }
    }
    setFilteredOrderLineItems(newFilterOrderLineItems);
  }, [order_line_items, discount]);

  useEffect(() => {
    if (selectedPromotionId !== discount?.id) {
      setTempDiscountDetails([]);
    } else {
      setTempDiscountDetails(discountDetails);
    }
  }, [selectedPromotionId, discountDetails]);

  return (
    <SimpleTableContainer
      columns={columns}
      data={filteredOrderLineItems || []}
      onRowClick={onRowClick}
      divClass="table-responsive"
      tableClass="table table-nowrap align-middle mb-0"
      theadClass="table-light text-muted"
    />
  );
};
export default ViewConditionBody;
