// Actions
export const LIST_INVENTORY_ITEM = "LIST_INVENTORY_ITEM";
export const LIST_INVENTORY_ITEM_SUCCESS = "LIST_INVENTORY_ITEM_SUCCESS";
export const LIST_INVENTORY_ITEM_FAIL = "LIST_INVENTORY_ITEM_FAIL";

export const GET_INVENTORY_ITEM = "GET_INVENTORY_ITEM";
export const GET_INVENTORY_ITEM_SUCCESS = "GET_INVENTORY_ITEM_SUCCESS";
export const GET_INVENTORY_ITEM_FAIL = "GET_INVENTORY_ITEM_FAIL";

export const GET_INVENTORY_ITEM_TRANSACTION = "GET_INVENTORY_ITEM_TRANSACTION";
export const GET_INVENTORY_ITEM_TRANSACTION_SUCCESS = "GET_INVENTORY_ITEM_TRANSACTION_SUCCESS";
export const GET_INVENTORY_ITEM_TRANSACTION_FAIL = "GET_INVENTORY_ITEM_TRANSACTION_FAIL";


