import { Table } from "reactstrap";
import { formatVNDCurrency } from "../../../../helpers/format_helper";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

const ProductReportTable = ({ data }) => {
  const { t } = useTranslation();
  const [statuses, setStatuses] = useState([
    { id: "sell", label: "Hàng bán", isCollapsed: true },
    { id: "return", label: "Hàng trả", isCollapsed: true },
    { id: "cancel", label: "Hàng huỷ", isCollapsed: true },
  ]);

  const toggleRow = (selectedRow) => {
    const newData = statuses.map((item) => {
      if (item.id === selectedRow.id) {
        item.isCollapsed = !item.isCollapsed;
      }
      return item;
    });
    setStatuses(newData);
  };

  return (
    <Table bordered>
      <thead>
        <tr className="bg-light text-center">
          <th colSpan={1000}>{t("Thông tin sản phẩm")}</th>
        </tr>
        <tr>
          <th></th>
          <th className="text-center">{t("Số lượng hàng")} </th>
          <th className="text-center">{t("Giá trị hàng")}</th>
        </tr>
      </thead>
      <tbody>
        {statuses.map((item, index) => (
          <Fragment key={index}>
            <tr>
              <th
                className={`cursor-pointer${item.isCollapsed ? "" : " text-primary"}`}
                onClick={() => {
                  toggleRow(item);
                }}
              >
                <i
                  className={`${
                    item.isCollapsed ? "ri-arrow-drop-right-line" : "ri-arrow-drop-down-line"
                  } fs-16 align-bottom`}
                ></i>{" "}
                {item.label}
              </th>
              <td className="text-center">{data?.[item.id]?.quantity || 0}</td>
              <td className="text-end">{formatVNDCurrency(data?.[item.id]?.revenue || 0)}</td>
            </tr>
            {!item.isCollapsed && (
              <tr className="bg-light">
                <td colSpan={1000}>
                  <ChildTable data={data?.[item.id] || []} />
                </td>
              </tr>
            )}
          </Fragment>
        ))}
      </tbody>
    </Table>
  );
};

const ChildTable = ({ data = [] }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Table bordered className="bg-white">
        <thead>
          <tr>
            <th colSpan={1000}>{t("Danh sách sản phẩm")}</th>
          </tr>
          <tr>
            <th className="text-center">{t("STT")}</th>
            <th>{t("Mã sản phẩm")}</th>
            <th>{t("Tên sản phẩm")}</th>
            <th className="text-center">{t("Đơn vị tính")}</th>
            <th className="text-center">{t("Số lượng bán")}</th>
            <th className="text-end">{t("Giá trị bán")}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <Fragment key={index}>
              <tr>
                <td className="text-center">{t("STT")}</td>
                <td>{t("Mã sản phẩm")}</td>
                <td>{t("Tên sản phẩm")}</td>
                <td className="text-center">{t("Đơn vị tính")}</td>
                <td className="text-center">{t("Số lượng bán")}</td>
                <td className="text-end">{t("Giá trị bán")}</td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </Table>
      {data.length === 0 && <div className="text-center">{t("Sorry! No Result Found")}</div>}
    </Fragment>
  );
};
export default ProductReportTable;
