import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";


// Redux States
import { LIST_INVENTORY_ITEM, GET_INVENTORY_ITEM, GET_INVENTORY_ITEM_TRANSACTION } from "./actionType";

import {
  listInventoryItemSuccess,
  listInventoryItemFail,
  getInventoryItemSuccess,
  getInventoryItemFail,
  getInventoryItemTransactionSuccess,
  getInventoryItemTransactionFail,
} from "./action";

import { inventoryItemsAPI } from "../../../../helpers/resource_helper";
import { getInventoryItemTransactionAPI } from "../../../../helpers/service_helper";

function* listInventoryItem({ payload: { ...res } }) {
  try {
    const response = yield call(inventoryItemsAPI.list, { ...res });
    yield put(listInventoryItemSuccess(response));
  } catch (error) {
    yield put(listInventoryItemFail(error));
  }
}

function* getInventoryItem({ payload: { ...res } }) {
  try {
    const response = yield call(inventoryItemsAPI.list, { ...res });
    yield put(getInventoryItemSuccess(response));
  } catch (error) {
    yield put(getInventoryItemFail(error));
  }
}

function* getInventoryItemTraction({ payload: data }) {
  try {
    const response = yield call(getInventoryItemTransactionAPI, data);
    yield put(getInventoryItemTransactionSuccess(response));
  } catch (error) {
    yield put(getInventoryItemTransactionFail(error));
  }
}


export function* watchListInventoryItem() {
  yield takeEvery(LIST_INVENTORY_ITEM, listInventoryItem);
}

export function* watchGetInventoryItem() {
  yield takeEvery(GET_INVENTORY_ITEM, getInventoryItem);
}

export function* watchGetInventoryItemTransaction() {
  yield takeEvery(GET_INVENTORY_ITEM_TRANSACTION, getInventoryItemTraction);
}

function* inventoryItemsSaga() {
  yield all([
    fork(watchGetInventoryItem),
    fork(watchListInventoryItem),
    fork(watchGetInventoryItemTransaction),
  ]);
}

export default inventoryItemsSaga;
