import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";


// Redux States
import {
  LIST_VOUCHER,
  DELETE_VOUCHER,
  ADD_NEW_VOUCHER,
  UPDATE_VOUCHER,
  GET_VOUCHER,
} from "./actionType";

import {
  deleteVoucherSuccess,
  deleteVoucherFail,
  addVoucherSuccess,
  addVoucherFail,
  updateVoucherSuccess,
  updateVoucherFail,
  listVoucherSuccess,
  listVoucherFail,
  getVoucherSuccess,
  getVoucherFail,
} from "./action";

import { vouchersAPI } from "../../../../helpers/resource_helper";

function* listVoucher({ payload: { ...rest } }) {
  try {
    const response = yield call(vouchersAPI.list, { limit: 20, ...rest });
    yield put(listVoucherSuccess(response));
  } catch (error) {
    yield put(listVoucherFail(error));
  }
}

function* getVoucher({ payload: id }) {
  try {
    const response = yield call(vouchersAPI.get, { id });
    yield put(getVoucherSuccess(response));
  } catch (error) {
    yield put(getVoucherFail(error));
  }
}

function* deleteVouchers({ payload: voucher }) {
  try {
    const response = yield call(vouchersAPI.delete, { id: voucher.id });
    yield put(deleteVoucherSuccess(voucher));
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteVoucherFail(error));
    toast.error("Voucher Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewVoucher({ payload: voucher }) {
  try {
    const response = yield call(vouchersAPI.create, voucher);
    yield put(addVoucherSuccess(response));
    toast.success("Voucher Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addVoucherFail(error));
    toast.error("Voucher Added Failed", { autoClose: 3000 });
  }
}

function* onUpdateVoucher({ payload: voucher }) {
  try {
    const response = yield call(vouchersAPI.put, voucher);
    yield put(updateVoucherSuccess(response));
    toast.success("Voucher Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateVoucherFail(error));
    toast.error("Voucher Updated Failed", { autoClose: 3000 });
  }
}

export function* watchListVoucher() {
  yield takeEvery(LIST_VOUCHER, listVoucher);
}

export function* watchDeleteVouchers() {
  yield takeEvery(DELETE_VOUCHER, deleteVouchers);
}

export function* watchGetVoucher() {
  yield takeEvery(GET_VOUCHER, getVoucher);
}

export function* watchUpdateVoucher() {
  yield takeEvery(UPDATE_VOUCHER, onUpdateVoucher);
}

export function* watchAddNewVoucher() {
  yield takeEvery(ADD_NEW_VOUCHER, onAddNewVoucher);
}

function* vouchersSaga() {
  yield all([
    fork(watchGetVoucher),
    fork(watchListVoucher),
    fork(watchUpdateVoucher),
    fork(watchAddNewVoucher),
    fork(watchDeleteVouchers),
  ]);
}

export default vouchersSaga;
