import { useDispatch } from "react-redux";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import { listOrder } from "../../../../store/actions";
import { useEffect, useState } from "react";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import FullNameRender from "../../../../Components/Common/FullnameRender";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";
import { formatVNDCurrency } from "../../../../helpers/format_helper";
import { OrderStatuses } from "../../../../Components/constants/common";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const ReturnOrderModal = ({ isOpen, toggle }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { orders, ordersLoading, ordersAction, ordersLimit, ordersPage, ordersTotal, ordersMessage } =
    useMemoizedSelector((state) => ({
      orders: state.Orders.orders,
      ordersMessage: state.Orders.message,
      ordersAction: state.Orders.action,
      ordersLoading: state.Orders.loading,
      ordersSuccess: state.Orders.success,
      ordersError: state.Orders.error,
      ordersLimit: state.Orders.limit,
      ordersPage: state.Orders.page,
      ordersTotal: state.Orders.total,
    }));

  const headers = ["number", "created_at", "user", "customer", "total", "action"];

  useEffect(() => {
    isOpen && dispatch(listOrder({ status: OrderStatuses.COMPLETED }));
  }, [isOpen]);
  return (
    <Modal isOpen={isOpen} size="xl" centered toggle={toggle}>
      <ModalHeader toggle={toggle}>{t("Select order to return")}</ModalHeader>
      <ModalBody>
        <TableContainerCustom
          searchPlaceHolder="Search order..."
          listFilter={[]}
          headers={headers}
          contents={orders}
          limitPage={Number(ordersLimit)}
          currentPage={Number(ordersPage) + 1}
          totalItems={ordersTotal}
          loading={ordersLoading}
          showPagination={true}
          scrollHeight="50vh"
          onCallData={(dataPayload) => {
            dispatch(listOrder({ ...dataPayload, status: OrderStatuses.COMPLETED }));
            // dispatch(listOrder(dataPayload));
          }}
          listHeader={[
            {
              title: t("Order details"),
              key: "detail-order",
              children: [
                {
                  title: t("Order Number"),
                  dataIndex: "number",
                  key: "number",
                  render: (value, row, index) => {
                    return (
                      <Tooltip title={value}>
                        <div className="text-truncate-two-lines">{value}</div>
                      </Tooltip>
                    );
                  },
                },
                {
                  title: t("Created at"),
                  dataIndex: "created_at",
                  key: "created_at",
                  defaultSortOrder: "desc",
                },
                {
                  title: t("Staff"),
                  dataIndex: "user",
                  key: "user",
                  render: (value, row, index) => {
                    return (
                      <Tooltip title={value?.name_staff}>
                        <div className="text-truncate-two-lines">{value?.name_staff || "---"}</div>
                      </Tooltip>
                    );
                  },
                },
                {
                  title: t("Customer"),
                  dataIndex: "customer",
                  key: "customer",
                  render: (value, row, index) => {
                    return (
                      <div className="text-truncate d-block">
                        <FullNameRender first_name={value?.first_name} last_name={value?.last_name} />
                      </div>
                    );
                  },
                },

                {
                  title: t("Total"),
                  dataIndex: "total",
                  key: "total",
                  render: (value, row, index) => {
                    return <Tooltip title={formatVNDCurrency(value || 0)}>{formatVNDCurrency(value || 0)}</Tooltip>;
                  },
                },
                {
                  title: t("Action"),
                  dataIndex: "action",
                  key: "action",
                  render: (value, row, index) => {
                    return <Link to={`/return-orders/new?order_id=${row.id}`}>{t("Return and exchange")}</Link>;
                  },
                },
              ],
            },
          ]}
        />
      </ModalBody>
    </Modal>
  );
};
export default ReturnOrderModal;
