import { useState } from "react";
import { Input } from "reactstrap";
import { deepCopy } from "../../../helpers/format_helper";
import { useTranslation } from "react-i18next";

const ServiceNameInput = ({ onChangeItem = () => {}, product, index, className, placeholder, isEdit = true }) => {
  const { t } = useTranslation();
  const [name, setName] = useState(product.name);
  return (
    <Input
      type="text"
      className={className}
      value={name}
      placeholder={t(placeholder)}
      onChange={(e) => {
        setName(e.target.value);
      }}
      onBlur={(e) => {
        const newProduct = deepCopy(product);
        onChangeItem(index, { ...newProduct, name: e.target.value });
      }}
      disabled={!isEdit}
    />
  );
};

export default ServiceNameInput;
