export const DISCOUNT = {
  _list: "/discounts",
  get: "/discounts/details/:id",
  add: "/discounts/new",
  update: "/discounts/:id/edit",
  delete: true,
};

const ADD_DISCOUNT = "discount.add";
const UPDATE_DISCOUNT = "discount.update";
const DELETE_DISCOUNT = "discount.delete";
const GET_DISCOUNT = "discount.get";

export const VOUCHER = {
  _list: "/discounts",
  get: "/discounts/details/id",
  add: "/vouchers/create",
  update: "/vouchers/id/edit",
  delete: true,
};

const ADD_VOUCHER = "voucher.add";
const UPDATE_VOUCHER = "voucher.update";
const DELETE_VOUCHER = "voucher.delete";
const GET_VOUCHER = "voucher.get";
const UPDATE_VOUCHER_STATUS = "voucher.update_status";

export const VOUCHER_CODE = {
  _list: "/voucher-code/id",
};

const ADD_VOUCHER_CODE = "voucher_code.add";
const UPDATE_VOUCHER_CODE = "voucher_code.update";
const DELETE_VOUCHER_CODE = "voucher_code.delete";
const GET_VOUCHER_CODE = "voucher_code.get";
