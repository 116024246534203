import { Tooltip } from "antd";
import { useState } from "react";
import ButtonTheme from "./ButtonTheme";

const CopyToClipboardButton = ({ textToCopy }) => {
  const [isClick, setIsClick] = useState(false);
  return (
    <Tooltip title={isClick ? "Copied!" : "Copy"}>
      <ButtonTheme
        type="button"
        className="btn btn-icon btn-ghost-primary rounded-circle"
        onClick={(e) => {
          e.stopPropagation();
          navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
              setIsClick(true);
              setTimeout(() => {
                setIsClick(false);
              }, 1800);
            })
            .catch((error) => console.error("Failed to copy text: ", error));
        }}
      >
        <i className="bx bx-outline text-muted fs-22"></i>
      </ButtonTheme>
      {/* <span
        className="cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
              setIsClick(true);
              setTimeout(() => {
                setIsClick(false);
              }, 1800);
            })
            .catch((error) => console.error("Failed to copy text: ", error));
        }}
      >
        {isClick ? <i className="ri-file-copy-2-line"></i> : <i className="ri-file-copy-line"></i>}
      </span> */}
    </Tooltip>
  );
};
export default CopyToClipboardButton;
