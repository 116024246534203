import {
  LIST_UNIT,
  DELETE_UNIT,
  DELETE_UNIT_SUCCESS,
  DELETE_UNIT_FAIL,
  ADD_NEW_UNIT,
  ADD_UNIT_SUCCESS,
  ADD_UNIT_FAIL,
  UPDATE_UNIT,
  UPDATE_UNIT_SUCCESS,
  UPDATE_UNIT_FAIL,
  LIST_UNIT_FAIL,
  LIST_UNIT_SUCCESS,
  GET_UNIT,
  GET_UNIT_FAIL,
  GET_UNIT_SUCCESS,
} from "./actionType";

export const listUnit = (options) => ({
  type: LIST_UNIT,
  payload: options,
});

export const listUnitFail = (error) => ({
  type: LIST_UNIT_FAIL,
  payload: error,
});

export const listUnitSuccess = (units) => ({
  type: LIST_UNIT_SUCCESS,
  payload: units,
});

export const getUnit = () => ({
  type: GET_UNIT,
});

export const getUnitFail = (error) => ({
  type: GET_UNIT_FAIL,
  payload: error,
});

export const getUnitSuccess = (unit) => ({
  type: GET_UNIT_SUCCESS,
  payload: unit,
});

export const deleteUnit = (unit) => ({
  type: DELETE_UNIT,
  payload: unit,
});

export const deleteUnitSuccess = (unit) => ({
  type: DELETE_UNIT_SUCCESS,
  payload: unit,
});

export const deleteUnitFail = (error) => ({
  type: DELETE_UNIT_FAIL,
  payload: error,
});

export const updateUnit = (unit, handleAfterSubmit = () => {}) => ({
  type: UPDATE_UNIT,
  payload: { unit, handleAfterSubmit },
});

export const updateUnitSuccess = (unit) => ({
  type: UPDATE_UNIT_SUCCESS,
  payload: unit,
});

export const updateUnitFail = (error) => ({
  type: UPDATE_UNIT_FAIL,
  payload: error,
});

export const addNewUnit = (unit, handleAfterSubmit = () => {}) => ({
  type: ADD_NEW_UNIT,
  payload: { unit, handleAfterSubmit },
});

export const addUnitSuccess = (unit) => ({
  type: ADD_UNIT_SUCCESS,
  payload: unit,
});

export const addUnitFail = (error) => ({
  type: ADD_UNIT_FAIL,
  payload: error,
});
