// Actions
export const LIST_SHIPPING_PROVIDER = "LIST_SHIPPING_PROVIDER";
export const LIST_SHIPPING_PROVIDER_SUCCESS = "LIST_SHIPPING_PROVIDER_SUCCESS";
export const LIST_SHIPPING_PROVIDER_FAIL = "LIST_SHIPPING_PROVIDER_FAIL";

export const GET_SHIPPING_PROVIDER = "GET_SHIPPING_PROVIDER";
export const GET_SHIPPING_PROVIDER_SUCCESS = "GET_SHIPPING_PROVIDER_SUCCESS";
export const GET_SHIPPING_PROVIDER_FAIL = "GET_SHIPPING_PROVIDER_FAIL";

/**
 * Delete SHIPPING_PROVIDER
 */
export const DELETE_SHIPPING_PROVIDER = "DELETE_SHIPPING_PROVIDER";
export const DELETE_SHIPPING_PROVIDER_SUCCESS = "DELETE_SHIPPING_PROVIDER_SUCCESS";
export const DELETE_SHIPPING_PROVIDER_FAIL = "DELETE_SHIPPING_PROVIDER_FAIL";

/**
 * Add SHIPPING_PROVIDER
 */
export const ADD_NEW_SHIPPING_PROVIDER = "ADD_NEW_SHIPPING_PROVIDER";
export const ADD_SHIPPING_PROVIDER_SUCCESS = "ADD_SHIPPING_PROVIDER_SUCCESS";
export const ADD_SHIPPING_PROVIDER_FAIL = "ADD_SHIPPING_PROVIDER_FAIL";

/**
 * Edit SHIPPING_PROVIDER
 */
export const UPDATE_SHIPPING_PROVIDER = "UPDATE_SHIPPING_PROVIDER";
export const UPDATE_SHIPPING_PROVIDER_SUCCESS = "UPDATE_SHIPPING_PROVIDER_SUCCESS";
export const UPDATE_SHIPPING_PROVIDER_FAIL = "UPDATE_SHIPPING_PROVIDER_FAIL";
