import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import withRouter from "../../Components/Common/withRouter";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import ButtonTheme from "../../Components/Common/ButtonTheme";
import { Fragment } from "react";
import { getOrderPayQR } from "../../helpers/service_helper";
import { formatDateTime, formatVNDCurrency } from "../../helpers/format_helper";
import Loader from "../../Components/Common/Loader";
import BackButton from "../../Components/Common/BackButton";
import html2canvas from "html2canvas";
import SubmitModal from "../../Components/Common/SubmitModal";
import { useProfile } from "../../Components/Hooks/useProfile";
import onexapisLogo from "../../assets/images/onexapis-logo.png";
import LottieIcon from "../../Components/Common/LottieIcon";

const OrderPayQR = (props) => {
  const {
    router: { t, location, navigate, params },
  } = props;

  const { id } = params;

  const { dataShop } = useProfile();
  const [logo, setLogo] = useState(null);
  const [name, setName] = useState("");
  useEffect(() => {
    setLogo(dataShop?.setting_value?.store_logo_light?.url || onexapisLogo);
    setName(dataShop?.setting_value?.store_name || "OptiWarehouse");
  }, [dataShop]);

  const ref = useRef(null);
  const [error, setError] = useState("");
  const [data, setData] = useState(null);
  const [firstRun, setFirstRun] = useState(true);
  const [imageURI, setImageURI] = useState("");

  const [submitModal, setSubmitModal] = useState(false);
  const toggleSubmitModal = () => {
    setSubmitModal(false);
  };

  const screenshotPage = async () => {
    if (ref.current === null) {
      return;
    }
    html2canvas(ref.current).then((canvas) => {
      // Convert the canvas to a data URL
      const dataURL = canvas.toDataURL();

      // Create a link element to download the image
      const a = document.createElement("a");
      a.href = dataURL;
      a.download = "optiwarehouse-qr-code.png";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
    toggleSubmitModal();
  };

  const firstLoadData = async () => {
    try {
      const payload = {
        account_number: "0988979251",
        account_name: "Mai Khanh Linh",
        bank_id: 970422,
        transaction_id: id,
      };
      const res = await getOrderPayQR(payload);
      if (res) {
        setData(res);
      }
      if (res?.data?.qr_url) {
        // Fetch the image URL
        const response = await fetch(res?.data?.qr_url);
        const blob = await response.blob();
        // Convert the image blob to base64
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64Data = reader.result;
          setImageURI(base64Data);
        };
      }
    } catch (e) {
      setError(String(e));
    } finally {
      setFirstRun(false);
    }
  };

  useEffect(() => {
    firstLoadData();
  }, []);

  useEffect(() => {
    if (firstRun) {
      return;
    }
    const intervalId = setInterval(async () => {
      try {
        const payload = {
          account_number: "0988979251",
          account_name: "Mai Khanh Linh",
          bank_id: 970422,
          transaction_id: id,
        };
        const res = await getOrderPayQR(payload);
        if (res.paid) {
          setData(res);
          clearInterval(intervalId);
        }
      } catch (e) {
        clearInterval(intervalId);
      }
    }, 3000);
    return () => clearInterval(intervalId);
  }, [firstRun]);

  document.title = `${t("QR Code Details")} | OptiWarehouse`;
  return (
    <Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <SubmitModal
              show={submitModal}
              onSubmitClick={screenshotPage}
              onCloseClick={toggleSubmitModal}
              icon={
                <LottieIcon src="https://cdn.lordicon.com/rehjpyyh.json" style={{ width: "100px", height: "100px" }} />
              }
              text={"Do you want to save image to your device?"}
            />
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logo} alt="" height="100" />
                    </Link>
                  </div>
                  <h1
                    className="display-5 mt-2"
                    style={{
                      fontWeight: 600,
                      textTransform: "uppercase",
                      color: "#fff",
                      textShadow: "3px 4px #f7b84b",
                    }}
                  >
                    {name}
                  </h1>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              {firstRun ? (
                <Col md={8} lg={6} xl={5}>
                  <Card className="mt-4">
                    <CardBody className="p-4">
                      <div className="text-center mt-2">
                        <h5 className="text-primary">
                          {t(`Searching for order number`)} {id} ...
                        </h5>
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
                          <Loader />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ) : error ? (
                <Col md={8} lg={6} xl={5}>
                  <Card className="mt-4">
                    <CardBody className="p-4">
                      <div className="text-center mt-2">
                        <LottieIcon
                          src="https://cdn.lordicon.com/msoeawqm.json"
                          style={{ width: "42px", height: "42px" }}
                        />
                        <h4 className="text-uppercase">{t("Sorry, can not find order")} 😭</h4>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ) : data?.paid ? (
                <Col md={8} lg={6} xl={5}>
                  <Card className="mt-4">
                    <CardBody className="p-4">
                      <div className="text-center mt-2">
                        <h5 className="text-primary">
                          {t("Order number")}: {data?.order_number}
                        </h5>
                        <i className="ri-checkbox-circle-fill fs-48 text-success" />
                        <p className="text-muted">{t("Pay successfully!")}</p>
                      </div>
                      <div className="table-responsive mt-2">
                        <table className="table table-borderless align-middle mb-0">
                          <tbody>
                            <tr>
                              <td className="fw-bold">{t("Paid amount")}</td>
                              <td>{formatVNDCurrency(data?.paid_amount)}</td>
                            </tr>
                            <tr>
                              <td className="fw-bold">{t("Paid time")}</td>
                              <td>{formatDateTime(data?.paid_time)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ) : (
                <Col>
                  <Card className="mt-4">
                    <CardBody>
                      <div className="position-absolute">
                        <BackButton url={"/qr"} />
                      </div>
                      <div className="p-4 bg-white" ref={ref}>
                        <div className="text-center">
                          <h5 className="text-primary">
                            {t("Order number")}: {data?.order_number}
                          </h5>
                        </div>
                        <Row>
                          <Col lg={6}>
                            <img src={imageURI} alt="qr" className="w-100" />
                          </Col>
                          <Col lg={6} className="m-auto">
                            <div className="table-responsive">
                              <table className="table table-borderless align-middle mb-0">
                                <tbody>
                                  <tr>
                                    <td className="fw-bold">{t("Account name")}</td>
                                    <td>{data?.data?.account_name}</td>
                                  </tr>
                                  <tr>
                                    <td className="fw-bold">{t("Bank")}</td>
                                    <td>{t("VietinBank")}</td>
                                  </tr>
                                  <tr>
                                    <td className="fw-bold">{t("Bank account")}</td>
                                    <td>{data?.data?.account_number}</td>
                                  </tr>
                                  <tr>
                                    <td className="fw-bold">{t("Amount")}</td>
                                    <td>{formatVNDCurrency(data?.data?.amount)}</td>
                                  </tr>
                                  <tr>
                                    <td className="fw-bold">{t("QR note")}</td>
                                    <td>{data?.data?.qr_note}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="my-3 hstack justify-content-center gap-3">
                        <ButtonTheme
                          type="button"
                          className="btn btn-icon btn-topbar btn-primary rounded-circle light-dark-mode"
                          onClick={() => {
                            setSubmitModal(true);
                          }}
                        >
                          <i className="ri-screenshot-2-line fs-22"></i>
                        </ButtonTheme>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </Fragment>
  );
};

export default withRouter(OrderPayQR);
