import {
  ADD_NEW_SHIFT_PAY_LINE_ITEM,
  ADD_SHIFT,
  ADD_SHIFT_FAIL,
  ADD_SHIFT_PAY_LINE_ITEM_FAIL,
  ADD_SHIFT_PAY_LINE_ITEM_SUCCESS,
  ADD_SHIFT_SUCCESS,
  CHECK_OPENED_SHIFT,
  CHECK_OPENED_SHIFT_FAIL,
  CHECK_OPENED_SHIFT_SUCCESS,
  CLOSE_SHIFT,
  CLOSE_SHIFT_FAIL,
  CLOSE_SHIFT_SUCCESS,
  DELETE_SHIFT_PAY_LINE_ITEM,
  DELETE_SHIFT_PAY_LINE_ITEM_FAIL,
  DELETE_SHIFT_PAY_LINE_ITEM_SUCCESS,
  GET_SHIFT,
  GET_SHIFT_FAIL,
  GET_SHIFT_PAY_LINE_ITEM,
  GET_SHIFT_PAY_LINE_ITEM_FAIL,
  GET_SHIFT_PAY_LINE_ITEM_SUCCESS,
  GET_SHIFT_SUCCESS,
  LIST_SHIFTS,
  LIST_SHIFTS_FAIL,
  LIST_SHIFTS_SUCCESS,
  LIST_SHIFT_PAY_LINE_ITEM,
  LIST_SHIFT_PAY_LINE_ITEM_FAIL,
  LIST_SHIFT_PAY_LINE_ITEM_SUCCESS,
  MORE_SHIFT_PAY_LINE_ITEM,
  MORE_SHIFT_PAY_LINE_ITEM_FAIL,
  MORE_SHIFT_PAY_LINE_ITEM_SUCCESS,
  UPDATE_SHIFT,
  UPDATE_SHIFT_FAIL,
  UPDATE_SHIFT_PAY_LINE_ITEM,
  UPDATE_SHIFT_PAY_LINE_ITEM_FAIL,
  UPDATE_SHIFT_PAY_LINE_ITEM_SUCCESS,
  UPDATE_SHIFT_SUCCESS,
} from "./actionType";

export const listShifts = (options) => ({
  type: LIST_SHIFTS,
  payload: options,
});
export const listShiftsSuccess = (response) => ({
  type: LIST_SHIFTS_SUCCESS,
  payload: response,
});

export const listShiftsError = (error) => ({
  type: LIST_SHIFTS_FAIL,
  payload: error,
});

export const getShift = (shiftId) => ({
  type: GET_SHIFT,
  payload: shiftId,
});
export const getShiftSuccess = (payload) => ({
  type: GET_SHIFT_SUCCESS,
  payload: payload,
});

export const getShiftError = (error) => ({
  type: GET_SHIFT_FAIL,
  payload: error,
});

export const updateShift = (data) => ({
  type: UPDATE_SHIFT,
  payload: data,
});

export const updateShiftSuccess = (response) => ({
  type: UPDATE_SHIFT_SUCCESS,
  payload: response,
});

export const updateShiftFail = (error) => ({
  type: UPDATE_SHIFT_FAIL,
  payload: error,
});

export const addShift = (data, handleAfterSubmit = () => {}) => ({
  type: ADD_SHIFT,
  payload: { data, handleAfterSubmit },
});

export const addShiftSuccess = (response) => ({
  type: ADD_SHIFT_SUCCESS,
  payload: response,
});

export const addShiftFail = (error) => ({
  type: ADD_SHIFT_FAIL,
  payload: error,
});

export const checkOpenedShift = (data, handleAfterSubmit = () => {}) => ({
  type: CHECK_OPENED_SHIFT,
  payload: { data, handleAfterSubmit },
});

export const checkOpenedShiftSuccess = (response) => ({
  type: CHECK_OPENED_SHIFT_SUCCESS,
  payload: response,
});

export const checkOpenedShiftFail = (error) => ({
  type: CHECK_OPENED_SHIFT_FAIL,
  payload: error,
});

export const closeShift = (data, handleAfterSubmit = () => {}) => ({
  type: CLOSE_SHIFT,
  payload: { data, handleAfterSubmit },
});

export const closeShiftSuccess = (response) => ({
  type: CLOSE_SHIFT_SUCCESS,
  payload: response,
});

export const closeShiftFail = (error) => ({
  type: CLOSE_SHIFT_FAIL,
  payload: error,
});

export const listShiftPayLineItem = (options) => ({
  type: LIST_SHIFT_PAY_LINE_ITEM,
  payload: options,
});

export const listShiftPayLineItemFail = (error) => ({
  type: LIST_SHIFT_PAY_LINE_ITEM_FAIL,
  payload: error,
});

export const listShiftPayLineItemSuccess = (shiftPayLineItems) => ({
  type: LIST_SHIFT_PAY_LINE_ITEM_SUCCESS,
  payload: shiftPayLineItems,
});

export const moreShiftPayLineItem = (options) => ({
  type: MORE_SHIFT_PAY_LINE_ITEM,
  payload: options,
});

export const moreShiftPayLineItemFail = (error) => ({
  type: MORE_SHIFT_PAY_LINE_ITEM_FAIL,
  payload: error,
});

export const moreShiftPayLineItemSuccess = (shiftPayLineItems) => ({
  type: MORE_SHIFT_PAY_LINE_ITEM_SUCCESS,
  payload: shiftPayLineItems,
});

export const getShiftPayLineItem = (shiftPayLineItemID) => ({
  type: GET_SHIFT_PAY_LINE_ITEM,
  payload: shiftPayLineItemID,
});

export const getShiftPayLineItemFail = (error) => ({
  type: GET_SHIFT_PAY_LINE_ITEM_FAIL,
  payload: error,
});

export const getShiftPayLineItemSuccess = (shiftPayLineItem) => ({
  type: GET_SHIFT_PAY_LINE_ITEM_SUCCESS,
  payload: shiftPayLineItem,
});

export const deleteShiftPayLineItem = (shiftPayLineItem, shiftId) => ({
  type: DELETE_SHIFT_PAY_LINE_ITEM,
  payload: { shiftPayLineItem, shiftId },
});

export const deleteShiftPayLineItemSuccess = (shiftPayLineItem) => ({
  type: DELETE_SHIFT_PAY_LINE_ITEM_SUCCESS,
  payload: shiftPayLineItem,
});

export const deleteShiftPayLineItemFail = (error) => ({
  type: DELETE_SHIFT_PAY_LINE_ITEM_FAIL,
  payload: error,
});

export const updateShiftPayLineItem = (shiftPayLineItem, shiftId) => ({
  type: UPDATE_SHIFT_PAY_LINE_ITEM,
  payload: { shiftPayLineItem, shiftId },
});

export const updateShiftPayLineItemSuccess = (shiftPayLineItem) => ({
  type: UPDATE_SHIFT_PAY_LINE_ITEM_SUCCESS,
  payload: shiftPayLineItem,
});

export const updateShiftPayLineItemFail = (error) => ({
  type: UPDATE_SHIFT_PAY_LINE_ITEM_FAIL,
  payload: error,
});

export const addNewShiftPayLineItem = (shiftPayLineItem, shiftId, handleAfterSubmit = () => {}) => ({
  type: ADD_NEW_SHIFT_PAY_LINE_ITEM,
  payload: { shiftPayLineItem, shiftId, handleAfterSubmit },
});

export const addShiftPayLineItemSuccess = (shiftPayLineItem) => ({
  type: ADD_SHIFT_PAY_LINE_ITEM_SUCCESS,
  payload: shiftPayLineItem,
});

export const addShiftPayLineItemFail = (error) => ({
  type: ADD_SHIFT_PAY_LINE_ITEM_FAIL,
  payload: error,
});
