import { Fragment, useState, useEffect } from "react";
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { useTranslation } from "react-i18next";
import { checkVaidlVoucherCode } from "../../../../helpers/service_helper";
import { toast } from "react-toastify";
import InputTheme from "../../../../Components/Common/InputTheme";
import { calculateDiscountByCustomerGroup, deepCopy, formatVNDCurrency } from "../../../../helpers/format_helper";

const DiscountModal = ({
  toggle,
  isOpen,
  onChangeDiscountPrice = () => {},
  discountPrice = 0,
  maxDiscount = 0,
  currentOrder = {},
  onChangeVoucher = () => {},
}) => {
  const { t } = useTranslation();

  const [percent, setPercent] = useState(0);
  const [price, setPrice] = useState(0);
  const [type, setType] = useState("value");
  const [voucherCode, setVoucherCode] = useState("");
  const [loading, setLoading] = useState(false);

  const [applyDiscount, setApplyDiscount] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const newDiscountPercent =
        currentOrder?.percent_order_discount === null || currentOrder?.percent_order_discount === undefined
          ? 0
          : Number(currentOrder?.percent_order_discount);
      setPercent(newDiscountPercent);
      setPrice(discountPrice);
      const newType =
        currentOrder?.percent_order_discount === null || currentOrder?.percent_order_discount === undefined
          ? "value"
          : "percent";
      setType(newType);
      setApplyDiscount(!!currentOrder?.applying_loyal_discount);
    }
  }, [isOpen]);

  const handleApply = async () => {
    const newDiscount = type === "value" ? price : (maxDiscount * percent) / 100;

    const loyaltyDiscount = calculateDiscountByCustomerGroup(
      currentOrder?.customer?.customer_group,
      currentOrder?.customer,
      currentOrder?.sub_total,
      applyDiscount,
    );

    const redeemAmount =
      currentOrder?.redeem_point && currentOrder?.reward_program
        ? currentOrder.redeem_point * currentOrder.reward_program?.money
        : 0;
    const check = maxDiscount - newDiscount - loyaltyDiscount - redeemAmount;

    if (check < 0) {
      toast.error(t("Discount price is more than total price!!!"));
      return;
    }
    const newTotal = currentOrder.sub_total - newDiscount - loyaltyDiscount;

    let newPayments;
    if ((currentOrder.payments ?? []).length === 1) {
      newPayments = deepCopy(currentOrder.payments);
      newPayments[0].amount = newTotal - redeemAmount;
    } else {
      newPayments = null;
    }
    onChangeDiscountPrice({
      discount: newDiscount,
      total: newTotal,
      discount_by_customer_group: loyaltyDiscount,
      applying_loyal_discount: applyDiscount,
      payments: newPayments,
      percent_order_discount: type === "percent" ? percent : null,
    });
    try {
      setLoading(true);
      if (voucherCode) {
        const res = await checkVaidlVoucherCode(voucherCode, {
          ...currentOrder,
          total_order: currentOrder.total,
        });
        onChangeVoucher(res);
        setVoucherCode("");
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.toString());
    }
    setLoading(false);
    toggle();
  };
  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>{t("Discount order")}</ModalHeader>
        <ModalBody>
          <Row className="mb-3">
            <Col className="hstack gap-3">
              <div className="flex-grow-1">{t("Discount")}</div>
              <div className="d-flex">
                <ButtonTheme
                  className={`btn w-md waves-effect waves-light rounded-0 rounded-start ${
                    type === "value" ? "btn-primary" : "btn-outline-primary"
                  }`}
                  onClick={() => {
                    setType("value");
                  }}
                >
                  {t("Value")}
                </ButtonTheme>
                <ButtonTheme
                  className={`btn w-md waves-effect waves-light rounded-0 rounded-end ${
                    type === "percent" ? "btn-primary" : "btn-outline-primary"
                  }`}
                  onClick={() => {
                    setType("percent");
                  }}
                >
                  %
                </ButtonTheme>
              </div>
              <div>
                {type === "value" ? (
                  <InputTheme
                    isForm={false}
                    format="price"
                    min={0}
                    className="form-control border-0 border-bottom text-end w-md"
                    onChange={(value) => {
                      let data = Number(value);
                      if (data < 0 || data === "") {
                        setPrice(0);
                      } else {
                        setPrice(data);
                      }
                    }}
                    value={price}
                  />
                ) : (
                  <InputTheme
                    isForm={false}
                    format="percent"
                    min={0}
                    className="form-control border-0 border-bottom text-end w-md"
                    onChange={(value) => {
                      let data = Number(value);
                      if (data < 0 || data === "") {
                        setPercent(0);
                      } else if (data > 100) {
                        setPercent(100);
                      } else {
                        setPercent(data);
                      }
                    }}
                    value={percent}
                  />
                )}
              </div>
            </Col>
          </Row>
          {currentOrder?.customer?.customer_group && (
            <Row className="mb-3">
              <Col>
                <div className="form-check form-check-primary">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id={`applying-discount`}
                    onChange={(e) => {
                      setApplyDiscount(!applyDiscount);
                    }}
                    checked={applyDiscount}
                  />
                  <Label className="form-check-label" for={`applying-discount`}>
                    {`${t("Loyalty discount")} (${currentOrder?.customer?.customer_group?.discount_percent ?? 0}%)`}
                  </Label>
                </div>
              </Col>
              <Col>
                <div className="text-end">
                  {formatVNDCurrency(
                    !applyDiscount
                      ? 0
                      : ((currentOrder?.customer?.customer_group?.discount_percent ?? 0) * maxDiscount) / 100,
                  )}
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Label>{t("Voucher")}</Label>
              <Input
                type="text"
                className="form-control"
                value={voucherCode}
                onChange={(e) => {
                  setVoucherCode(e.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-light w-sm" onClick={toggle}>
            {t("Close")}
          </Button>
          <ButtonTheme className="btn btn-primary w-sm" onClick={handleApply} loading={loading} loadShowText={true}>
            {t("Apply")}
          </ButtonTheme>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default DiscountModal;
