import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { Tooltip } from "antd";

import { convertValidDate, convertValidTime } from "../../../../helpers/format_helper";

import withRouter from "../../../../Components/Common/withRouter";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import {
  Actions,
  RecordResponseCodeOptions,
  RecordResponseTypeOptions,
  RecordTypeOptions,
  RecordTypes,
} from "../../../../Components/constants/common";

import { listRecord } from "../../../../store/actions";
import BadgeCustom from "../../../../Components/Common/BadgeCustom";

const RecordsPage = (props) => {
  document.title = "Record List | OptiWarehouse";
  const {
    router: { navigate, location, params, t },
  } = props;
  const dispatch = useDispatch();

  const {
    records,
    recordsLoading,
    recordsAction,
    recordsSuccess,
    recordsError,
    recordsLimit,
    recordsPage,
    recordsTotal,
    recordsMessage,
  } = useMemoizedSelector((state) => ({
    records: state.Records.records,
    recordsAction: state.Records.action,
    recordsMessage: state.Records.message,
    recordsLoading: state.Records.loading,
    recordsSuccess: state.Records.success,
    recordsError: state.Records.error,
    recordsLimit: state.Records.limit,
    recordsPage: state.Records.page,
    recordsTotal: state.Records.total,
  }));

  const [isExportCSV, setIsExportCSV] = useState(false);

  const headers = [
    "id",
    "record_type",
    "app_id",
    "response_type",
    "response_code",
    "message",
    "updated_at",
    "created_at",
  ];

  return (
    <div className="page-content">
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={records} />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="purchase-order-list">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("Import Record List")}</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-3 flex-wrap">
                      <ButtonTheme
                        type="button"
                        className="btn btn-light"
                        icon="ri-file-text-line"
                        onClick={() => setIsExportCSV(true)}
                      >
                        {t("Export")}
                      </ButtonTheme>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <TableContainerCustom
                  filterType={"records"}
                  searchPlaceHolder="Search import record..."
                  listFilter={[
                    {
                      id: "record_type",
                      type: "selectBox",
                      title: "Record Type",
                      dataOption: RecordTypeOptions,
                    },
                    {
                      id: "app_id",
                      type: "selectBox",
                      title: "App ID",
                      remote: true,
                      pathUrlLoad: "https://api-staging.onexapis.com/apps",
                    },
                    {
                      id: "response_type",
                      type: "selectBox",
                      title: "Response Type",
                      dataOption: RecordResponseTypeOptions,
                    },
                    {
                      id: "response_code",
                      type: "selectBox",
                      title: "Response Code",
                      dataOption: RecordResponseCodeOptions,
                    },
                  ]}
                  headers={headers}
                  contents={records}
                  keyRow="created_at"
                  limitPage={Number(recordsLimit)}
                  currentPage={Number(recordsPage) + 1}
                  totalItems={recordsTotal}
                  loading={recordsLoading && recordsAction === Actions.LIST}
                  showPagination={true}
                  scrollHeight="50vh"
                  onCallData={(dataPayload) => {
                    dispatch(listRecord(dataPayload));
                  }}
                  isSettings={true}
                  listHeader={[
                    {
                      title: "Thông tin nhập",
                      key: "detail-variant",
                      children: [
                        {
                          title: "ID",
                          dataIndex: "id",
                          key: "id",
                          render: (value, row, index) => {
                            return (
                              <Tooltip title={value}>
                                <Link to={`/records/${value}`} className="fw-medium d-block text-truncate link-primary">
                                  {value || "---"}
                                </Link>
                              </Tooltip>
                            );
                          },
                        },
                        {
                          title: "Record Type",
                          dataIndex: "record_type",
                          key: "record_type",
                          sorter: true,
                          render: (value, row, index) => {
                            return (
                              <Tooltip title={RecordTypes?.[value] ? t(RecordTypes[value]) : "---"}>
                                <div className="d-block text-truncate">
                                  {RecordTypes?.[value] ? t(RecordTypes[value]) : "---"}
                                </div>
                              </Tooltip>
                            );
                          },
                        },
                        {
                          title: "App ID",
                          dataIndex: "app_id",
                          key: "app_id",
                          render: (value, row, index) => {
                            return (
                              <Tooltip title={value}>
                                <div className="d-block text-truncate">{value || "---"}</div>
                              </Tooltip>
                            );
                          },
                        },
                        {
                          title: "Response Type",
                          dataIndex: "response_type",
                          key: "response_type",
                          sorter: true,
                          render: (value, row, index) => {
                            return <BadgeCustom status={value} />;
                          },
                        },
                        {
                          title: "Response Code",
                          dataIndex: "response_code",
                          key: "response_code",
                          render: (value, row, index) => {
                            return <div className="d-block text-truncate">{value || "---"}</div>;
                          },
                        },
                        {
                          title: "Message",
                          dataIndex: "message",
                          key: "message",
                          sorter: true,
                          render: (value, row, index) => {
                            return (
                              <Tooltip title={value}>
                                <div className="d-block text-truncate">{value || "---"}</div>
                              </Tooltip>
                            );
                          },
                        },
                        {
                          title: "Updated at",
                          dataIndex: "updated_at",
                          key: "updated_at",
                          sorter: true,
                          render: (value, row, index) => {
                            return (
                              <>
                                {convertValidDate(value)},
                                <small className="text-muted"> {convertValidTime(value)}</small>
                              </>
                            );
                          },
                        },
                        {
                          title: "Created At",
                          dataIndex: "created_at",
                          key: "created_at",
                          defaultSortOrder: "desc",
                          sorter: true,
                          render: (value, row, index) => {
                            return (
                              <>
                                {convertValidDate(value)},
                                <small className="text-muted"> {convertValidTime(value)}</small>
                              </>
                            );
                          },
                        },
                      ],
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(RecordsPage);
