export const BLOG_CATEGORY = {
  _list: "/blog-categories",
  get: true,
  add: true,
  update: true,
  delete: true,
};

export const BLOG = {
  _list: "/blogs",
  get: "/blogs/:id",
  add: "/blogs/new",
  update: "/blogs/:id/edit",
};
