import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { isEmpty } from "lodash";

import withRouter from "../../../../Components/Common/withRouter";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { Actions } from "../../../../Components/constants/common";

import { deleteLoyaltyProgram, listLoyaltyProgram } from "../../../../store/actions";
import BadgeCustom from "../../../../Components/Common/BadgeCustom";
import { formatVNDCurrency } from "../../../../helpers/format_helper";
import { Link } from "react-router-dom";

const LoyaltyProgramPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Loyalty Program List")} | OptiWarehouse`;
  const dispatch = useDispatch();

  const {
    loyaltyPrograms,
    loyaltyProgramsAction,
    loyaltyProgramsLoading,
    loyaltyProgramsMessage,
    loyaltyProgramsSuccess,
    loyaltyProgramsError,
    loyaltyProgramsLimit,
    loyaltyProgramsPage,
    loyaltyProgramsTotal,
  } = useMemoizedSelector((state) => ({
    loyaltyPrograms: state.LoyaltyPrograms.loyaltyPrograms,
    loyaltyProgramsAction: state.LoyaltyPrograms.action,
    loyaltyProgramsLoading: state.LoyaltyPrograms.loading,
    loyaltyProgramsSuccess: state.LoyaltyPrograms.success,
    loyaltyProgramsError: state.LoyaltyPrograms.error,
    loyaltyProgramsMessage: state.LoyaltyPrograms.message,
    loyaltyProgramsLimit: state.LoyaltyPrograms.limit,
    loyaltyProgramsTotal: state.LoyaltyPrograms.total,
    loyaltyProgramsPage: state.LoyaltyPrograms.page,
  }));

  // modal state
  const [deleteModal, setDeleteModal] = useState(false);
  const [isExportCSV, setIsExportCSV] = useState(false);

  const [selectedLoyaltyProgram, setSelectedLoyaltyProgram] = useState(null);

  // table data
  const headers = ["name", "start_date", "expiry_date", "money", "number_used", "action"];

  const handleDeleteLoyaltyProgram = () => {
    dispatch(deleteLoyaltyProgram(selectedLoyaltyProgram));
  };
  const onClickDelete = (data) => {
    setSelectedLoyaltyProgram(data);
    setDeleteModal(true);
  };
  const onClose = () => {
    if (!loyaltyProgramsLoading) {
      setSelectedLoyaltyProgram(null);
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    // toggle off modal when delete success
    if (
      !loyaltyProgramsLoading &&
      !isEmpty(selectedLoyaltyProgram) &&
      deleteModal &&
      loyaltyProgramsMessage === "Delete loyalty program success"
    ) {
      setSelectedLoyaltyProgram(null);
      setDeleteModal(false);
    }
  }, [loyaltyProgramsLoading]);

  return (
    <Fragment>
      <div className="page-content">
        <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={loyaltyPrograms} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteLoyaltyProgram}
          loading={loyaltyProgramsLoading && loyaltyProgramsAction === Actions.DELETE}
          onCloseClick={onClose}
        />
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card id="loyaltyProgramList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">{t("Loyalty Program List")}</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex gap-3 flex-wrap">
                        <ButtonTheme
                          type="button"
                          className="btn btn-light"
                          icon="ri-file-text-line"
                          onClick={() => setIsExportCSV(true)}
                        >
                          {t("Export")}
                        </ButtonTheme>
                        <ButtonTheme
                          className="btn btn-primary"
                          id="create-btn"
                          icon="ri-add-line text-white"
                          onClick={() => {
                            navigate("/loyalty-programs/new");
                          }}
                        >
                          {t("Add Loyalty Program")}
                        </ButtonTheme>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <TableContainerCustom
                    filterType={"loyaltyPrograms"}
                    searchPlaceHolder="Search loyalty programs..."
                    listFilter={[]}
                    headers={headers}
                    contents={loyaltyPrograms}
                    limitPage={Number(loyaltyProgramsLimit)}
                    currentPage={Number(loyaltyProgramsPage) + 1}
                    totalItems={loyaltyProgramsTotal}
                    loading={loyaltyProgramsLoading && loyaltyProgramsAction === Actions.LIST}
                    showPagination={true}
                    scrollHeight="50vh"
                    onCallData={(dataPayload) => {
                      dispatch(listLoyaltyProgram(dataPayload));
                    }}
                    defaultPayload={{ limit: 20, sort_start_date: "desc" }}
                    isSettings={true}
                    listHeader={[
                      {
                        title: t("Point event details"),
                        key: "point-event-details",
                        children: [
                          {
                            title: t("Name"),
                            key: "name",
                            dataIndex: "name",
                            sorter: true,
                            render: (value, data, index) => {
                              return (
                                <Link
                                  to={`/loyalty-programs/${data.id}`}
                                  className="text-truncate d-block h5 fs-14 mb-0 link-primary"
                                >
                                  {value}
                                </Link>
                              );
                            },
                          },
                          { title: t("Points"), dataIndex: "points", key: "points", sorter: true },
                          { title: t("Collect"), dataIndex: "collect", key: "collect", sorter: true },
                          {
                            title: t("Start date"),
                            dataIndex: "start_date",
                            key: "start_date",
                            sorter: true,
                            defaultSortOrder: "desc",
                          },
                          { title: t("Expiry date"), dataIndex: "expiry_date", key: "expiry_date", sorter: true },
                          {
                            title: t("Point"),
                            dataIndex: "money",
                            key: "money",
                            sorter: true,
                            render: (value, data, index) => {
                              return (
                                <>
                                  {formatVNDCurrency(value)} = 1 {t("point")}
                                </>
                              );
                            },
                          },
                          { title: t("Number used"), dataIndex: "number_used", key: "number_used", sorter: true },
                          {
                            title: "Action",
                            dataIndex: "action",
                            key: "action",
                            render: (_, data, index) => {
                              return (
                                <UncontrolledDropdown>
                                  <DropdownToggle href="#" className="btn btn-soft-primary btn-sm" tag="button">
                                    <i className="ri-more-fill" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end" container="body">
                                    <DropdownItem
                                      onClick={() => {
                                        navigate(`/loyalty-programs/${data.id}`);
                                      }}
                                    >
                                      <i className="ri-pencil-fill align-bottom me-2 text-primary"></i> {t("Edit")}
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem
                                      onClick={() => {
                                        onClickDelete(data);
                                      }}
                                    >
                                      <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i> {t("Delete")}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              );
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};
export default withRouter(LoyaltyProgramPage);
