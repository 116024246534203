import { apiCli, defaultApiCli } from "../helpers/api_helper";
import { api } from "../config";
import axios from "axios";
import { orderSummaryAPI, settingsAPI } from "./resource_helper";

export const importByXlsxFileAPI = (path, data) => {
  return apiCli.create(`import/${path}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadImageAPI = (data) => {
  return apiCli.create("media/images/upload", data);
};

export const listStaticBanks = () => {
  return apiCli.get(`/static/banks`);
};

export const getInventoryItemTransactionAPI = ({ sku, location_id, ...res }) => {
  return apiCli.get(`/inventory/inventory_items/${sku}/inventory_transactions/${location_id}`, res);
};

export const getProvincesAll = () => {
  return apiCli.get("https://vn-public-apis.fpo.vn/provinces/getAll?limit=-1");
};

export const getDistrictsAll = (code = "") => {
  if (code) {
    return apiCli.get(`https://vn-public-apis.fpo.vn/districts/getByProvince?provinceCode=${code}&limit=-1`);
  }
  return apiCli.get(`https://vn-public-apis.fpo.vn/districts/getAll?limit=-1`);
};

export const getWards = (districtCode) => {
  return apiCli.get(`https://vn-public-apis.fpo.vn/wards/getByDistrict?districtCode=${districtCode}&limit=-1`);
};

export const updateOrderAPI = ({ id, action, data }) => {
  return apiCli.create(`/order/orders/${id}/${action}`, data);
};

export const updateReturnOrderAPI = ({ id, action, data }) => {
  return apiCli.create(`/order/return_orders/${id}/${action}`, data);
};

export const updatePurchaseOrderAPI = ({ id, action, data }) => {
  return apiCli.create(`/purchase_order/purchase_orders/${id}/${action}`, data);
};

export const getListDataDefault = (path, payload) => {
  return apiCli.get(path, { ...payload });
};

export const installAppStore = (app) => {
  return apiCli.create(`${api.API_URL_ONEX}/app_store/${app.id}/install`);
};

export const getAuthLink = ({ baseURL, connectionId }) => {
  return apiCli.get(`${baseURL}/auth/${connectionId}/auth_link`);
};
export const getAuthSchema = ({ baseURL }) => {
  return apiCli.get(`${baseURL}/auth/schema`);
};

export const testConnection = (baseURL, connectionId) => {
  return apiCli.get(`${baseURL}/auth/${connectionId}/test`, "");
};

export const getSchemaService = (baseURL) => {
  return apiCli.get(`${baseURL}/actions`);
};

export const postValidationAction = (baseURL, actionName, payload) => {
  return apiCli.create(`${baseURL}/actions/${actionName}/init`, payload);
};

export const listRecords = (data) => {
  return apiCli.get("/import/records", data);
};

export const getImportRecordAPI = ({ id }) => {
  return apiCli.get(`/import/records/${id}`);
};

export const listImportsAPI = (data) => {
  return apiCli.get("/import/imports", data);
};

export const getPackagesStatusCount = () => {
  return apiCli.get(`report/packages/status_count`);
};

export const changeStatusVoucher = (data) => {
  return apiCli.create(`promotion/voucher/status`, data);
};

export const getInfoSetting = () => {
  return settingsAPI.get({ id: "shop_info" });
};

export const loyaltyAppSetting = () => {
  return settingsAPI.get({ id: "loyalty_app" });
};

export const getApiColorShopInfo = () => {
  return settingsAPI.get({ id: "color" });
};

export const getDefaultPrintSettings = () => {
  return settingsAPI.get({ id: "print_template" });
};

export const updateInfoSetting = (KEY_SETTINGS, data) => {
  return settingsAPI.create({
    setting_name: KEY_SETTINGS,
    setting_value: data,
  });
};

export const getSapoQRCodeDetails = (id) => {
  return axios.get(`/qr/${id}`, { baseURL: "https://mnh8aegvie.execute-api.ap-southeast-1.amazonaws.com" });
};

export const checkVaidlVoucherCode = (voucherCode, data) => {
  return axios.post(`https://noliwms-staging.onexapis.com/promotion/check_voucher_code/${voucherCode}`, data);
};

export const getListUserAPI = (params) => {
  return apiCli.get(`https://admin.onexapis.com/admin/users`, params);
};

export const getDetailUserAPI = (id) => {
  return apiCli.get(`https://admin.onexapis.com/admin/users/${id}`);
};

export const postUserAPI = (data) => {
  return apiCli.create(`https://admin.onexapis.com/admin/users`, data);
};

export const updateUserAPI = (username, data) => {
  return apiCli.create(`https://admin.onexapis.com/admin/users/${username}`, data);
};

export const assignStaffPackage = (id, data) => {
  return apiCli.create(`order/assign_staff_package/${id}`, data);
};

export const updatePackageAPI = (id, action, data) => {
  return apiCli.create(`order/packages/${id}/${action}`, data);
};

export const getPermissionDefault = () => {
  return apiCli.get(`account/permission-default`);
};

export const cancelTransactionAPI = (id) => {
  return apiCli.create(`finance/transactions/${id}/cancel`);
};

export const getOrderSummary = (params) => {
  return apiCli.create(`report/dashboard/order_summary`, params);
};

export const getBestSellingProducts = (params) => {
  return apiCli.create(`report/dashboard/best_selling_products`, params);
};

export const getRevenueByLocation = (params) => {
  return apiCli.create(`report/dashboard/revenue_by_location`, params);
};

export const getRevenueBySource = (params) => {
  return apiCli.create(`report/dashboard/revenue_by_source`, params);
};

export const getReportProductSellingThrough = (productId) => {
  return apiCli.create(`report/products/selling_through?product_id=${productId}`, {});
};

export const getReportProductSellingOverview = (params) => {
  return apiCli.create(`report/products/selling_overview`, params);
};

export const listProvinces = (data) => {
  return defaultApiCli.get(`/static/get_provinces`, data);
};
export const listDistricts = (data) => {
  return defaultApiCli.get(`/static/get_districts`, data);
};
export const listWards = (data) => {
  return defaultApiCli.get(`/static/get_wards`, data);
};

export const getReportOrder = (params) => {
  return apiCli.create(`report/orders/report_order`, params);
};

export const getOrderPayQR = (data) => {
  return apiCli.create(`https://qr-staging.onexapis.com/qr/b319439b-92af-4ba7-957c-260a0cc05896`, data);
};

export const syncToHRV = (id) => {
  return apiCli.create(`/integration/haravan/sync_product/${id}`, {});
};

export const getVersionAPI = () => {
  return apiCli.get(`/version/get_version`);
};

export const listVariantsByOriginalSku = (sku) => {
  return apiCli.get(`/product/variants`, { limit: 1000, original_sku: sku });
};

export const getEffectiveRewardProgramAPI = () => {
  return apiCli.get(`/loyalty/reward_programs/get_effective_program`);
};
