import {
  LIST_DISCOUNT,
  LIST_DISCOUNT_FAIL,
  LIST_DISCOUNT_SUCCESS,
  DELETE_DISCOUNT,
  DELETE_DISCOUNT_SUCCESS,
  DELETE_DISCOUNT_FAIL,
  ADD_NEW_DISCOUNT,
  ADD_DISCOUNT_SUCCESS,
  ADD_DISCOUNT_FAIL,
  UPDATE_DISCOUNT,
  UPDATE_DISCOUNT_SUCCESS,
  UPDATE_DISCOUNT_FAIL,
  GET_DISCOUNT,
  GET_DISCOUNT_FAIL,
  GET_DISCOUNT_SUCCESS,
  RESET_DISCOUNT_FLAG,
} from "./actionType";

export const listDiscount = (options) => ({
  type: LIST_DISCOUNT,
  payload: options,
});

export const listDiscountFail = (error) => ({
  type: LIST_DISCOUNT_FAIL,
  payload: error,
});

export const listDiscountSuccess = (discounts) => ({
  type: LIST_DISCOUNT_SUCCESS,
  payload: discounts,
});

export const getDiscount = (discountID) => ({
  type: GET_DISCOUNT,
  payload: discountID,
});

export const getDiscountFail = (error) => ({
  type: GET_DISCOUNT_FAIL,
  payload: error,
});

export const getDiscountSuccess = (discount) => ({
  type: GET_DISCOUNT_SUCCESS,
  payload: discount,
});

export const deleteDiscount = (discount) => ({
  type: DELETE_DISCOUNT,
  payload: discount,
});

export const deleteDiscountSuccess = (discount) => ({
  type: DELETE_DISCOUNT_SUCCESS,
  payload: discount,
});

export const deleteDiscountFail = (error) => ({
  type: DELETE_DISCOUNT_FAIL,
  payload: error,
});

export const updateDiscount = (discount) => ({
  type: UPDATE_DISCOUNT,
  payload: discount,
});

export const updateDiscountSuccess = (discount) => ({
  type: UPDATE_DISCOUNT_SUCCESS,
  payload: discount,
});

export const updateDiscountFail = (error) => ({
  type: UPDATE_DISCOUNT_FAIL,
  payload: error,
});

export const addNewDiscount = (discount) => ({
  type: ADD_NEW_DISCOUNT,
  payload: discount,
});

export const addDiscountSuccess = (discount) => ({
  type: ADD_DISCOUNT_SUCCESS,
  payload: discount,
});

export const addDiscountFail = (error) => ({
  type: ADD_DISCOUNT_FAIL,
  payload: error,
});

export const resetDiscount = () => ({
  type: RESET_DISCOUNT_FLAG,
});
