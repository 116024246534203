import { Fragment, useImperativeHandle } from "react";
import { useEffect, useState } from "react";
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Row } from "reactstrap";
import { DiscountAmountType, DiscountTypes } from "../constants/common";
import { calculateDiscountByCustomerGroup, deepCopy, formatVNDCurrency } from "../../helpers/format_helper";
import { useTranslation } from "react-i18next";
import InputTheme from "./InputTheme";
import { toast } from "react-toastify";

const OrderDiscountDropDown = ({
  discount,
  amountDiscount = 0,
  maxDiscount,
  values,
  discountType,
  discountPercent,
  discountPrice,
  onChangeDiscountType,
  onChangeDiscountPercent,
  onChangeDiscountPrice,
  edit = true,
  refToggleDiscount = null,
  applyCustomerGroupDiscount,
  setApplyCustomerGroupDiscount,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [applyDiscount, setApplyDiscount] = useState(true);
  const [tempDiscountValue, setTempDiscountValue] = useState(0);
  const [tempDiscountPercent, setTempDiscountPercent] = useState(0);

  const handleToggle = () => {
    let totalSalePrice = values.order_line_items.reduce((total, item) => total + item.quantity * item.sale_price, 0);
    let totalFeePrice = values.fees ? values.fees.reduce((total, item) => total + Number(item.amount), 0) : 0;
    let newDiscount = 0;
    if (discountType === "value") {
      if (totalSalePrice + totalFeePrice >= tempDiscountValue) {
        newDiscount = tempDiscountValue;
      }
      onChangeDiscountPercent(0);
    } else {
      onChangeDiscountPercent(tempDiscountPercent);
      newDiscount = (tempDiscountPercent / 100) * (totalSalePrice + totalFeePrice);
    }

    const loyaltyDiscount = calculateDiscountByCustomerGroup(
      values?.customer?.customer_group,
      values?.customer,
      values?.sub_total,
      applyDiscount,
    );

    const redeemAmount =
      values?.redeem_point && values?.reward_program ? values.redeem_point * values.reward_program?.money : 0;
    const check = maxDiscount - newDiscount - loyaltyDiscount - redeemAmount;

    if (check < 0) {
      toast.error(t("Discount price is more than total price!!!"));
      return;
    }
    const newTotal = values.sub_total - newDiscount - loyaltyDiscount;

    let newPayments;
    if ((values.payments ?? []).length === 1) {
      newPayments = deepCopy(values.payments);
      newPayments[0].amount = newTotal - redeemAmount;
    } else {
      newPayments = null;
    }

    onChangeDiscountPrice({
      discount: newDiscount,
      total: newTotal,
      discount_by_customer_group: loyaltyDiscount,
      payments: newPayments,
    });
    setApplyCustomerGroupDiscount(applyDiscount);
    setIsOpen(!isOpen);
  };

  useImperativeHandle(refToggleDiscount, () => ({
    toggleDiscount: () => {
      handleToggle();
    },
  }));

  useEffect(() => {
    if (isOpen) {
      setApplyDiscount(applyCustomerGroupDiscount);
      setTempDiscountValue(discountPrice);
      setTempDiscountPercent(discountPercent);
    }
  }, [isOpen]);

  return (
    <Fragment>
      {edit ? (
        <Dropdown isOpen={isOpen} toggle={handleToggle}>
          <DropdownToggle tag="button" className="btn p-0">
            <span className="text-primary">{t("Discount")} (F6)</span>
          </DropdownToggle>
          <DropdownMenu className="p-0">
            <div className="p-3">
              {values.discount_id && discount && discount.type === DiscountTypes.BY_ORDER_TOTAL && (
                <div className="hstack mb-3">
                  <div className="flex-grow-1">{t(discount.name)}</div>
                  <div>{formatVNDCurrency(amountDiscount)}</div>
                </div>
              )}
              <div className="hstack">
                <div className="flex-shrink-0 me-3">
                  <Input
                    type="radio"
                    className="btn-check"
                    id="value-discount"
                    name="discount-options"
                    onChange={() => {
                      discountType !== "value" && onChangeDiscountType("value");
                    }}
                    checked={discountType === "value"}
                  />
                  <label className="btn btn-outline-primary m-0 rounded-0 rounded-start w-sm" htmlFor="value-discount">
                    {t("Value")}
                  </label>
                  <Input
                    type="radio"
                    className="btn-check"
                    name="discount-options"
                    id="percent-discount"
                    onChange={() => {
                      discountType !== "percent" && onChangeDiscountType("percent");
                    }}
                    checked={discountType === "percent"}
                  />
                  <label className="btn btn-outline-primary m-0 rounded-0 rounded-end w-sm" htmlFor="percent-discount">
                    %
                  </label>
                </div>
                <div className="flex-grow-1">
                  {discountType === "value" ? (
                    <InputTheme
                      isForm={false}
                      format="price"
                      min={0}
                      formNoMargin={true}
                      className="form-control border-0 border-bottom text-end w-md"
                      onChange={(data) => {
                        if (data < 0 || data === "") {
                          setTempDiscountValue(0);
                        } else {
                          setTempDiscountValue(data);
                        }
                      }}
                      value={tempDiscountValue}
                    />
                  ) : (
                    <InputTheme
                      isForm={false}
                      format="percent"
                      min={0}
                      max={100}
                      formNoMargin={true}
                      className="form-control border-0 border-bottom text-end w-md"
                      onChange={(data) => {
                        if (data < 0 || data === "") {
                          setTempDiscountPercent(0);
                        } else if (data > 100) {
                          setTempDiscountPercent(100);
                        } else {
                          setTempDiscountPercent(data);
                        }
                      }}
                      value={tempDiscountPercent}
                    />
                  )}
                </div>
              </div>
              {values?.customer?.customer_group && (
                <div className="mt-3 hstack justify-content-between">
                  <div className="form-check form-check-primary">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      id={`applying-discount`}
                      onChange={(e) => {
                        setApplyDiscount(!applyDiscount);
                      }}
                      checked={applyDiscount}
                    />
                    <Label className="form-check-label" for={`applying-discount`}>
                      {`${t("Loyalty discount")} (${values?.customer?.customer_group?.discount_percent ?? 0}%)`}
                    </Label>
                  </div>
                  <div className="text-end">
                    {formatVNDCurrency(
                      !applyDiscount
                        ? 0
                        : ((values?.customer?.customer_group?.discount_percent ?? 0) * maxDiscount) / 100,
                    )}
                  </div>
                </div>
              )}
            </div>
          </DropdownMenu>
        </Dropdown>
      ) : (
        <>{t("Discount")}</>
      )}
    </Fragment>
  );
};
export default OrderDiscountDropDown;
