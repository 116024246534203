import React, { useEffect, useState } from "react";
import Lottie from "react-lottie-player";

const LottieIcon = ({ src, loop = true, autoplay = true, style = { width: "42px", height: "42px" } }) => {
  const [animationData, setAnimationData] = useState(null);

  useEffect(() => {
    if (typeof src === "string") {
      // Fetch the JSON animation data if src is a URL
      fetch(src)
        .then((response) => response.json())
        .then((data) => setAnimationData(data))
        .catch((error) => console.error("Error loading animation:", error));
    } else {
      // If src is already a JSON object
      setAnimationData(src);
    }
  }, [src]);

  return (
    <div className="hstack justify-content-center">
      {animationData ? (
        <Lottie loop={loop} animationData={animationData} play={autoplay} style={style} />
      ) : (
        "Loading..."
      )}
    </div>
  );
};

export default LottieIcon;
