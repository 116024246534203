import {
  LIST_LOYALTY_PROGRAM,
  LIST_LOYALTY_PROGRAM_FAIL,
  LIST_LOYALTY_PROGRAM_SUCCESS,
  DELETE_LOYALTY_PROGRAM,
  DELETE_LOYALTY_PROGRAM_SUCCESS,
  DELETE_LOYALTY_PROGRAM_FAIL,
  ADD_NEW_LOYALTY_PROGRAM,
  ADD_LOYALTY_PROGRAM_SUCCESS,
  ADD_LOYALTY_PROGRAM_FAIL,
  UPDATE_LOYALTY_PROGRAM,
  UPDATE_LOYALTY_PROGRAM_SUCCESS,
  UPDATE_LOYALTY_PROGRAM_FAIL,
  GET_LOYALTY_PROGRAM,
  GET_LOYALTY_PROGRAM_FAIL,
  GET_LOYALTY_PROGRAM_SUCCESS,
  RESET_LOYALTY_PROGRAM_FLAG,
} from "./actionType";

export const listLoyaltyProgram = (options) => ({
  type: LIST_LOYALTY_PROGRAM,
  payload: options,
});

export const listLoyaltyProgramFail = (error) => ({
  type: LIST_LOYALTY_PROGRAM_FAIL,
  payload: error,
});

export const listLoyaltyProgramSuccess = (loyaltyPrograms) => ({
  type: LIST_LOYALTY_PROGRAM_SUCCESS,
  payload: loyaltyPrograms,
});

export const getLoyaltyProgram = (loyaltyProgramID) => ({
  type: GET_LOYALTY_PROGRAM,
  payload: loyaltyProgramID,
});

export const getLoyaltyProgramFail = (error) => ({
  type: GET_LOYALTY_PROGRAM_FAIL,
  payload: error,
});

export const getLoyaltyProgramSuccess = (loyaltyProgram) => ({
  type: GET_LOYALTY_PROGRAM_SUCCESS,
  payload: loyaltyProgram,
});

export const deleteLoyaltyProgram = (loyaltyProgram, handleAfterSubmit = () => {}) => ({
  type: DELETE_LOYALTY_PROGRAM,
  payload: { loyaltyProgram, handleAfterSubmit },
});

export const deleteLoyaltyProgramSuccess = (loyaltyProgram) => ({
  type: DELETE_LOYALTY_PROGRAM_SUCCESS,
  payload: loyaltyProgram,
});

export const deleteLoyaltyProgramFail = (error) => ({
  type: DELETE_LOYALTY_PROGRAM_FAIL,
  payload: error,
});

export const updateLoyaltyProgram = (loyaltyProgram) => ({
  type: UPDATE_LOYALTY_PROGRAM,
  payload: loyaltyProgram,
});

export const updateLoyaltyProgramSuccess = (loyaltyProgram, handleAfterSubmit = () => {}) => ({
  type: UPDATE_LOYALTY_PROGRAM_SUCCESS,
  payload: { loyaltyProgram, handleAfterSubmit },
});

export const updateLoyaltyProgramFail = (error) => ({
  type: UPDATE_LOYALTY_PROGRAM_FAIL,
  payload: error,
});

export const addNewLoyaltyProgram = (loyaltyProgram, handleAfterSubmit = () => {}) => ({
  type: ADD_NEW_LOYALTY_PROGRAM,
  payload: { loyaltyProgram, handleAfterSubmit },
});

export const addLoyaltyProgramSuccess = (loyaltyProgram) => ({
  type: ADD_LOYALTY_PROGRAM_SUCCESS,
  payload: loyaltyProgram,
});

export const addLoyaltyProgramFail = (error) => ({
  type: ADD_LOYALTY_PROGRAM_FAIL,
  payload: error,
});

export const resetLoyaltyProgram = () => ({
  type: RESET_LOYALTY_PROGRAM_FLAG,
});
