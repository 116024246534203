import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";

// Redux States
import { LIST_ROLE, DELETE_ROLE, ADD_NEW_ROLE, UPDATE_ROLE, GET_ROLE } from "./actionType";

import {
  deleteRoleSuccess,
  deleteRoleFail,
  addRoleSuccess,
  addRoleFail,
  updateRoleSuccess,
  updateRoleFail,
  listRoleSuccess,
  listRoleFail,
  getRoleSuccess,
  getRoleFail,
} from "./action";

import { roleAPI } from "../../../../helpers/resource_helper";

function* listRole({ payload: { ...res } }) {
  try {
    const response = yield call(roleAPI.list, { ...res });
    yield put(listRoleSuccess(response));
  } catch (error) {
    yield put(listRoleFail(error));
  }
}

function* getRole({ payload: id }) {
  try {
    const response = yield call(roleAPI.get, { id });
    yield put(getRoleSuccess(response));
  } catch (error) {
    yield put(getRoleFail(error));
  }
}

function* deleteRoles({ payload: { role, handleAfterSubmit } }) {
  try {
    const response = yield call(roleAPI.delete, role);
    yield put(deleteRoleSuccess(role));
    yield call(handleAfterSubmit);
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteRoleFail(error));
    toast.error("Role Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewRole({ payload: { role, handleAfterSubmit } }) {
  try {
    const response = yield call(roleAPI.create, role);
    yield put(addRoleSuccess(response));
    yield call(handleAfterSubmit);
    toast.success("Role Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addRoleFail(error));
    toast.error("Role Added Failed", { autoClose: 3000 });
  }
}

function* onUpdateRole({ payload: { role, handleAfterSubmit } }) {
  try {
    const response = yield call(roleAPI.put, role);
    yield put(updateRoleSuccess(response));
    yield call(handleAfterSubmit);
    toast.success("Role Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateRoleFail(error));
    toast.error("Role Updated Failed", { autoClose: 3000 });
  }
}

export function* watchListRole() {
  yield takeEvery(LIST_ROLE, listRole);
}

export function* watchDeleteRoles() {
  yield takeEvery(DELETE_ROLE, deleteRoles);
}

export function* watchGetRole() {
  yield takeEvery(GET_ROLE, getRole);
}

export function* watchUpdateRole() {
  yield takeEvery(UPDATE_ROLE, onUpdateRole);
}

export function* watchAddNewRole() {
  yield takeEvery(ADD_NEW_ROLE, onAddNewRole);
}

function* rolesSaga() {
  yield all([
    fork(watchGetRole),
    fork(watchListRole),
    fork(watchUpdateRole),
    fork(watchAddNewRole),
    fork(watchDeleteRoles),
  ]);
}

export default rolesSaga;
