import {
  LIST_INVENTORY_ITEM,
  LIST_INVENTORY_ITEM_FAIL,
  LIST_INVENTORY_ITEM_SUCCESS,
  GET_INVENTORY_ITEM,
  GET_INVENTORY_ITEM_FAIL,
  GET_INVENTORY_ITEM_SUCCESS,
  GET_INVENTORY_ITEM_TRANSACTION,
  GET_INVENTORY_ITEM_TRANSACTION_FAIL,
  GET_INVENTORY_ITEM_TRANSACTION_SUCCESS,
} from "./actionType";

export const listInventoryItem = (options) => ({
  type: LIST_INVENTORY_ITEM,
  payload: options,
});

export const listInventoryItemFail = (error) => ({
  type: LIST_INVENTORY_ITEM_FAIL,
  payload: error,
});

export const listInventoryItemSuccess = (inventoryItems) => ({
  type: LIST_INVENTORY_ITEM_SUCCESS,
  payload: inventoryItems,
});

export const getInventoryItem = (options) => ({
  type: GET_INVENTORY_ITEM,
  payload: options,
});

export const getInventoryItemFail = (error) => ({
  type: GET_INVENTORY_ITEM_FAIL,
  payload: error,
});

export const getInventoryItemSuccess = (inventoryItem) => ({
  type: GET_INVENTORY_ITEM_SUCCESS,
  payload: inventoryItem,
});

export const getInventoryItemTransaction = (data) => ({
  type: GET_INVENTORY_ITEM_TRANSACTION,
  payload: data,
});

export const getInventoryItemTransactionFail = (error) => ({
  type: GET_INVENTORY_ITEM_TRANSACTION_FAIL,
  payload: error,
});

export const getInventoryItemTransactionSuccess = (inventoryItem) => ({
  type: GET_INVENTORY_ITEM_TRANSACTION_SUCCESS,
  payload: inventoryItem,
});