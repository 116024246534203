import { useDispatch } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Modal, ModalBody, Progress, Row } from "reactstrap";
import { Tooltip } from "antd";
import SimpleBar from "simplebar-react";
import { toast } from "react-toastify";
import useMemoizedSelector from "../../../../../Components/Hooks/useMemoizedSelector";
import { getAppStore } from "../../../../../store/actions";
import BreadCrumb from "../../../../../Components/Common/BreadCrumb";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import withRouter from "../../../../../Components/Common/withRouter";
import { getAuthLink, getAuthSchema, installAppStore, testConnection } from "../../../../../helpers/service_helper";
import { appStoresAPI, channelsAPI, connectionsAPI } from "../../../../../helpers/resource_helper";
import ModalConfigSetting from "./ModalConfigSetting";
import { useTranslation } from "react-i18next";
import LottieIcon from "../../../../../Components/Common/LottieIcon";

const AppStoreDetail = (props) => {
  const {
    router: { location, navigate, params, t },
  } = props;
  document.title = `${t("App Details")} | OptiWarehouse`;

  const { id } = params;
  const dispatch = useDispatch();

  const { appStore, appStoresLoading, appStoresMessage, appStoresSuccess, appStoresError } = useMemoizedSelector(
    (state) => ({
      appStore: state.Stores.appStore,
      appStoresLoading: state.Stores.loading,
      appStoresSuccess: state.Stores.success,
      appStoresError: state.Stores.error,
      appStoresMessage: state.Stores.message,
    }),
  );

  const [installLoading, setInstallLoading] = useState(false);

  const [listSchemaFixed, setListSchemaFixed] = useState([]);
  const [isModalConfig, setIsModalConfig] = useState(false);
  const [modalWaring, setModalWaring] = useState(false);

  const [progress, setProgress] = useState(0);
  useEffect(() => {
    if (id) {
      dispatch(getAppStore(id));
    }
  }, [dispatch, id]);

  const onInstallApp = async (item) => {
    if (item) {
      try {
        setInstallLoading(true);
        const res = await installAppStore(item);
        // const detailChannel = await appStoresAPI.get({ id: `${id}/status` });
        // const resConnection = getAuthLink();
        // console.log("detailChannel", resConnection);

        // navigate(`/apps/${res.id}?view=true`);
        const checkListFixed = [];
        for (const connection of appStore.connections) {
          if (
            connection.channel.url !== "https://mws-staging.onexapis.com" &&
            connection.channel.url !== "https://mws.onexapis.com"
          ) {
            if (connection.channel.type === "OAUTH") {
              await connectionsAPI.put({ ...connection });
              const res1 = await getAuthLink({ baseURL: connection.channel.url, connectionId: connection.id });

              var a = document.createElement("a");
              a.title = "my title text";
              a.href = res1;
              // a.target = "_blank";
              document.body.appendChild(a);
              a.click();
              a.remove();
            } else if (connection.channel.type === "FIXED") {
              const schema = await getAuthSchema({ baseURL: connection.channel.url });
              checkListFixed.push({
                data: connection,
                schema,
              });
            }
          }
        }
        setListSchemaFixed(checkListFixed);
        if (checkListFixed.length > 0) {
          setModalWaring(true);
        }
      } catch (e) {
        toast.error(t(e.toString()));
      } finally {
        setInstallLoading(false);
      }
    }
  };

  const handleSubmitConfig = async (data) => {
    navigate("/stores?sort_created_at=desc");
  };

  return (
    <div className="page-content">
      <ModalWaringFixed
        isOpen={modalWaring}
        onClick={() => {
          setModalWaring(false);
          setIsModalConfig(true);
        }}
        onCloseClick={async () => {
          try {
            setInstallLoading(true);
            setModalWaring(false);
            const lengthList = listSchemaFixed.length;
            for (const data of listSchemaFixed) {
              const base = data.data;
              await connectionsAPI.put(
                { ...base },
                {
                  onUploadProgress: (progressEvent) => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    const totalMod = 100 / lengthList;
                    setProgress((p) => {
                      return p + Math.round((percentCompleted * totalMod) / 100);
                    });
                  },
                },
              );
              await testConnection(base.channel.url, base.id);
            }
            setInstallLoading(false);
            navigate("/stores");
          } catch (error) {
            toast.error(error.toString());
            setInstallLoading(false);
            // setProgress(0);
            console.log("error", error);
          }
        }}
      />
      <ModalConfigSetting
        isOpen={isModalConfig}
        toggle={() => {
          setIsModalConfig(false);
        }}
        data={listSchemaFixed}
        onSubmitConfig={handleSubmitConfig}
      />
      <Container fluid>
        <BreadCrumb title="App store details" pageTitle="App store" to="/app-store" />
        {appStoresLoading ? (
          <Card>
            <CardBody>
              <div className="hstack gap-5">
                <div className="flex-grow-1">
                  <h5>
                    <p className="mb-2 fs-24 text-primary skeleton-text skeleton-box"></p>
                  </h5>
                  <p className="mb-2 skeleton-text skeleton-box"></p>
                  <ButtonTheme className="btn btn-light w-xl disabled skeleton skeleton-box" type="button">
                    {t("Install")}
                  </ButtonTheme>
                </div>
                <div className="flex-shrink-0">
                  <div className="border rounded skeleton-box" style={{ height: "150px", width: "150px" }}></div>
                </div>
              </div>
            </CardBody>
          </Card>
        ) : (
          <Card>
            <CardBody className="border-error">
              <div className="hstack gap-5">
                <div className="flex-grow-1">
                  <h5 className="mb-2 fs-24 text-primary">{t(appStore?.name || "---")}</h5>
                  <div className="mb-2 text-muted">
                    <span>{t("Description")}: </span>
                    <span>{t(appStore?.description ?? "---")}</span>
                  </div>
                  <ButtonTheme
                    onClick={() => {
                      onInstallApp(appStore);
                    }}
                    className="btn btn-primary w-xl"
                    type="button"
                    loading={installLoading}
                    disabled={installLoading}
                  >
                    {t("Install")}
                  </ButtonTheme>
                </div>
                <div className="flex-shrink-0">
                  {appStore?.image != null ? (
                    <img
                      src={appStore?.image}
                      alt=""
                      className="explore-img rounded"
                      height="150px"
                      width="150px"
                      style={{ objectFit: "contain" }}
                    />
                  ) : (
                    <div
                      className="avatar-title text-uppercase border rounded bg-light text-primary"
                      style={{ height: "150px", width: "150px" }}
                    >
                      {appStore?.name?.charAt(0)}
                    </div>
                  )}
                </div>
              </div>
              {progress !== 0 && (
                <Progress value={progress} color="danger" className="animated-progess custom-progress progress-label">
                  <div className="label">{progress}%</div>{" "}
                </Progress>
              )}
            </CardBody>
          </Card>
        )}
        <Row>
          <div className="col-6">
            <h5 className="card-title fw-semibold fs-16 mb-2 hstack">
              <span className="flex-grow-1">{t("Flow List")}</span>
              <span className="badge bg-primary align-middle ms-1">
                {appStoresLoading ? 0 : (appStore?.flows || []).length} {t("flows")}
              </span>
            </h5>
            <Row className="team-list list-view-filter">
              {appStoresLoading ? (
                new Array(5).fill({}).map((item, index) => (
                  <Col key={index}>
                    <Card className="team-box">
                      <CardBody className="p-4">
                        <Row>
                          <div className="team-content">
                            <p className={`fs-16 mb-1 skeleton-text skeleton-box`}></p>
                          </div>
                        </Row>
                        <Row className="align-items-center team-row">
                          <Col lg={12} className="col">
                            <div className="team-profile-img">
                              <div className="text-muted me-2">
                                <span>{t("Actions")}: </span>
                              </div>
                              <div className={`avatar-group`}>
                                {new Array(5).fill({}).map((c, cKey) => (
                                  <Fragment key={cKey}>
                                    <Link to="#" className="avatar-group-item">
                                      <div className="avatar-xs">
                                        <div className="avatar-title fs-16 rounded-circle bg-light border-dashed border skeleton-box"></div>
                                      </div>
                                    </Link>
                                  </Fragment>
                                ))}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))
              ) : (appStore?.flows || []).length > 0 ? (
                <SimpleBar
                  style={{
                    maxHeight: "56vh",
                  }}
                >
                  {appStore.flows.map((item, key) => {
                    const actionsFlow = (item?.flow_definition?.nodes || [])
                      .filter((n) => n.data?.label?.imageChannel)
                      .map((n) => n.data.label);
                    return (
                      <Fragment key={key}>
                        <Col>
                          <Card className="team-box">
                            <CardBody className="p-4">
                              <Row>
                                <div className="team-content">
                                  <h5 className={`fs-16 mb-1`}>
                                    {key + 1}. {t(item.name)}
                                  </h5>
                                </div>
                              </Row>
                              <Row className="align-items-center team-row">
                                <Col lg={12} className="col">
                                  <div className="team-profile-img">
                                    <div className="text-muted me-2">
                                      <span>{t("Actions")}: </span>
                                    </div>
                                    {actionsFlow.length > 0 ? (
                                      <div className={`avatar-group`}>
                                        {actionsFlow.map((c, cKey) => (
                                          <Fragment key={cKey}>
                                            <Tooltip title={c.name}>
                                              {c?.imageChannel ? (
                                                <Link to="#" className="avatar-group-item">
                                                  <div className="avatar-xs">
                                                    <img
                                                      src={c.imageChannel}
                                                      alt=""
                                                      className="img-fluid d-block rounded-circle w-100 h-100 bg-light"
                                                      style={{ objectFit: "contain" }}
                                                    />
                                                  </div>
                                                </Link>
                                              ) : (
                                                <Link to="#" className="avatar-group-item">
                                                  <div className="avatar-xs">
                                                    <div className="avatar-title fs-16 rounded-circle bg-light border-dashed border text-primary">
                                                      {c.name.charAt(0)}
                                                    </div>
                                                  </div>
                                                </Link>
                                              )}
                                            </Tooltip>
                                          </Fragment>
                                        ))}
                                      </div>
                                    ) : (
                                      <div>
                                        <span>{t("This flow doesn't have any actions!")}</span>
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Fragment>
                    );
                  })}
                </SimpleBar>
              ) : (
                <div className="py-4 mt-4 text-center">
                  <LottieIcon src="https://cdn.lordicon.com/msoeawqm.json" style={{ width: "72px", height: "72px" }} />
                  <h5 className="mt-4">{t("This app hasn't have flows yet!")}</h5>
                </div>
              )}
            </Row>
          </div>
          <div className="col-6">
            <h4 className="card-title fw-semibold fs-16 mb-2 hstack">
              <span className="flex-grow-1">{t("Connection List")}</span>
              <span className="badge bg-primary align-middle ms-1">
                {appStoresLoading ? 0 : (appStore?.connections || []).length} {t("connections")}
              </span>
            </h4>
            <Row className="team-list list-view-filter">
              {appStoresLoading ? (
                new Array(5).fill({}).map((item, index) => (
                  <Col key={index}>
                    <Card className="team-box">
                      <CardBody className="p-4">
                        <Row className="align-items-center team-row">
                          <Col lg={12} className="col">
                            <div className="hstack gap-2">
                              <div className="team-profile-img">
                                <div className="avatar-lg img-thumbnail rounded-circle flex-shrink-0 skeleton-box">
                                  <div className="avatar-title border rounded-circle"></div>
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <p className="skeleton-text skeleton-box"></p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))
              ) : (appStore?.connections || []).length > 0 ? (
                <SimpleBar
                  style={{
                    maxHeight: "56vh",
                  }}
                >
                  {appStore.connections.map((item, key) => (
                    <Fragment key={key}>
                      <Col>
                        <Card className="team-box">
                          <CardBody className="p-4">
                            <Row className="align-items-center team-row">
                              <Col lg={12} className="col">
                                <div className="team-profile-img">
                                  <div className={`avatar-lg img-thumbnail rounded-circle flex-shrink-0`}>
                                    {item?.channel?.image != null ? (
                                      <img
                                        src={item.channel.image}
                                        alt=""
                                        className="img-fluid d-block rounded-circle w-100 h-100"
                                        style={{ objectFit: "contain" }}
                                      />
                                    ) : (
                                      <div className="avatar-title text-uppercase border rounded-circle bg-light text-primary">
                                        {item?.name?.charAt(0)}
                                      </div>
                                    )}
                                  </div>
                                  <div className="team-content">
                                    <h5 className={`fs-16 mb-1`}>{t(item?.name || "---")}</h5>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Fragment>
                  ))}
                </SimpleBar>
              ) : (
                <div className="py-4 mt-4 text-center">
                  <LottieIcon src="https://cdn.lordicon.com/msoeawqm.json" style={{ width: "72px", height: "72px" }} />
                  <h5 className="mt-4">{t("This app hasn't have connections yet!")}</h5>
                </div>
              )}
            </Row>
          </div>
        </Row>
      </Container>
    </div>
  );
};

const ModalWaringFixed = ({ isOpen, onClick, onCloseClick, loading }) => {
  const { t } = useTranslation();
  return (
    <Modal fade={true} isOpen={isOpen} toggle={onCloseClick} centered={true} /*  backdrop={false} */>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>{t("Are you sure?")}</h4>
            <p className="text-muted mx-4 mb-0">{t("Are you sure you want to default setting this app?")}</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <Button type="button" className="btn w-sm btn-light" data-bs-dismiss="modal" onClick={onCloseClick}>
            {t("No")}
          </Button>
          <ButtonTheme
            id="delete-record"
            type="button"
            className="btn w-sm btn-danger"
            onClick={onClick}
            loading={loading}
          >
            {t(`Yes, Delete It!`)}
          </ButtonTheme>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default withRouter(AppStoreDetail);
