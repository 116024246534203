import { useEffect, useState, Fragment, useImperativeHandle, useRef } from "react";

import { Card, CardBody, CardHeader, Col, Dropdown, DropdownItem, DropdownMenu, Input, Row } from "reactstrap";
import SimpleBar from "simplebar-react";
import { useTranslation } from "react-i18next";

import AddEditCustomerModal from "../../pages/Ecommerce/Customer/Customers/AddEditCustomerModal";
import ButtonTheme from "./ButtonTheme";
import { useDebounce } from "../Hooks/UseDebounce";
import Loader from "./Loader";

import { getCustomer, listCustomer } from "../../store/actions";
import FullNameRender from "./FullnameRender";
import { customersAPI } from "../../helpers/resource_helper";
import { useListData } from "../Hooks/useListData";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import LottieIcon from "./LottieIcon";

const CustomerDetails = ({
  selectedCustomer = null,
  onSelectCustomer = () => {},
  shipping_address,
  billing_address,
  addresses = [],
  onChangeCustomerBilling,
  onChangeCustomerShipping,
  isEdit,
  refCustomerSearch = null,
}) => {
  const { t } = useTranslation();
  const { page, loading, hasMore, items, query, debouncedQuery, onChangeQuery, onSearch, listData } = useListData({
    resource: customersAPI,
    isScroll: true,
  });

  const [showDeleteSearchValue, setShowDeleteSearchValue] = useState(false);

  const [showDropDownMenu, setShowDropDownMenu] = useState(false);
  const [showDropDownMenuBill, setShowDropDownMenuBill] = useState(false);
  const [showDropDownMenuShipping, setShowDropDownMenuShipping] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);

  const dropdownRef = useRef(null);

  const toggleOnCustomerModal = () => {
    isEdit && setShowCustomerModal(true);
  };

  const toggleOffCustomerModal = () => {
    setShowCustomerModal(false);
  };

  useEffect(() => {
    setShowDeleteSearchValue(!!debouncedQuery);
    showDropDownMenu && onSearch();
  }, [debouncedQuery, showDropDownMenu]);

  const toggleDropDownMenuShipping = () => {
    setShowDropDownMenuShipping(!showDropDownMenuShipping);
  };
  const toggleDropDownMenuBill = () => {
    setShowDropDownMenuBill(!showDropDownMenuBill);
  };
  const handleClickDeleteSearchValue = () => {
    setShowDeleteSearchValue(false);
    onChangeQuery("");
  };

  const handleClickItem = async (item) => {
    if (item?.id) {
      try {
        const res = await customersAPI.get({ id: item.id });
        onSelectCustomer(res);
      } catch (e) {
        toast.error(e);
      }
    }
  };

  useImperativeHandle(refCustomerSearch, () => ({
    onFocusCustomerSearch: () => {
      document.getElementById("search-customer-input").focus();
      setShowDropDownMenu(true);
    },
  }));

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropDownMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Fragment>
      <AddEditCustomerModal
        isOpen={showCustomerModal}
        toggle={toggleOffCustomerModal}
        customerId={selectedCustomer?.id}
        onAddUpdateCustomer={onSelectCustomer}
      />
      <Card>
        <CardHeader>
          <div className="d-flex">
            <h5 className="card-title flex-grow-1 mb-0">{t("Customer Details")}</h5>
          </div>
        </CardHeader>
        {selectedCustomer ? (
          <CardBody>
            <Row>
              <Col>
                <div className="mb-3">
                  <div className="fw-medium fs-14 text-uppercase">
                    {t("Customer")}
                    {isEdit && (
                      <ButtonTheme
                        type="button"
                        onClick={() => onSelectCustomer(null)}
                        className="btn btn-icon btn-ghost-danger waves-effect waves-light rounded-circle light-dark-mode ms-3"
                      >
                        <i className="ri-close-line fs-22"></i>
                      </ButtonTheme>
                    )}
                  </div>
                  <Row className="align-items-center">
                    <Col md={4}>
                      <h6
                        className={`fs-14 mb-0 ${isEdit && "text-primary cursor-pointer"}`}
                        onClick={toggleOnCustomerModal}
                      >
                        <FullNameRender
                          first_name={selectedCustomer?.first_name}
                          last_name={selectedCustomer?.last_name}
                        />
                      </h6>
                    </Col>
                    <Col md={4}>
                      <p className="mb-0">
                        <i className="ri-phone-line me-2 align-middle text-muted fs-16"></i>
                        {selectedCustomer?.phone ?? "---"}
                      </p>
                    </Col>
                    <Col md={4}>
                      <p className="mb-0">
                        <i className="ri-mail-line me-2 align-middle text-muted fs-16"></i>
                        {selectedCustomer?.email ?? "---"}
                      </p>
                    </Col>
                  </Row>
                </div>
                <div className="mb-3">
                  <div className="fs-14 hstack">
                    <i className="ri-map-pin-line align-middle me-1 text-muted"></i>
                    <span className="text-uppercase fw-medium  me-3">{t("Shipping Address")}</span>
                    {isEdit && (
                      <div>
                        <ButtonTheme
                          type="button"
                          className="btn btn-ghost-primary text-primary waves-effect"
                          onClick={toggleDropDownMenuShipping}
                        >
                          {t("Change")}
                        </ButtonTheme>
                        <DropDownAddress
                          isOpen={showDropDownMenuShipping}
                          toggle={toggleDropDownMenuShipping}
                          toggleCustomerModal={toggleOnCustomerModal}
                          addresses={addresses}
                          onChangeCustomerAddress={onChangeCustomerShipping}
                        />
                      </div>
                    )}
                  </div>
                  <p className="mb-0">
                    <FullNameRender first_name={shipping_address?.first_name} last_name={shipping_address?.last_name} />
                  </p>
                  <p className="mb-0">{shipping_address?.address1 || "---"}</p>
                </div>
                <div>
                  <div className="fs-14 hstack">
                    <i className="ri-map-pin-line align-middle text-muted me-1"></i>
                    <span className="fw-medium text-uppercase me-3">{t("Billing Address")}</span>
                    {isEdit && (
                      <div>
                        <ButtonTheme
                          type="button"
                          className="btn btn-ghost-primary text-primary waves-effect"
                          onClick={toggleDropDownMenuBill}
                        >
                          {t("Change")}
                        </ButtonTheme>
                        <DropDownAddress
                          isOpen={showDropDownMenuBill}
                          toggle={toggleDropDownMenuBill}
                          addresses={addresses}
                          toggleCustomerModal={toggleOnCustomerModal}
                          onChangeCustomerAddress={onChangeCustomerBilling}
                        />
                      </div>
                    )}
                  </div>
                  <p className="mb-0">
                    <FullNameRender first_name={billing_address?.first_name} last_name={billing_address?.last_name} />
                  </p>
                  <p className="mb-0">{billing_address?.address1 || "---"}</p>
                </div>
              </Col>
              {selectedCustomer.customer_group && (
                <Col lg={4}>
                  <div className="border-dashed border-1 rounded">
                    <div className="hstack p-2">
                      <span className="flex-grow-1 fw-semibold">{t("Accumulated points")}</span>
                      <span>{selectedCustomer?.loyal_customer?.point ?? 0}</span>
                    </div>
            
                    {selectedCustomer?.customer_group && (
                      <div className="hstack p-2">
                        <span className="flex-grow-1 fw-semibold">{t("Group name")}</span>
                        <span>{selectedCustomer?.customer_group?.name ?? "---"}</span>
                      </div>
                    )}
                  </div>
                </Col>
              )}
            </Row>
          </CardBody>
        ) : (
          <CardBody>
            <div ref={dropdownRef}>
              <form className="app-search d-md-block">
                <div className="position-relative">
                  <Input
                    id="search-customer-input"
                    type="text"
                    className="form-control border"
                    placeholder={t("Add customer to this order (F4)")}
                    value={query}
                    onChange={(e) => {
                      onChangeQuery(e.target.value);
                    }}
                    onClick={() => {
                      setShowDropDownMenu(true);
                    }}
                  />
                  <span className="mdi mdi-magnify search-widget-icon"></span>
                  {showDeleteSearchValue && (
                    <span
                      className="mdi mdi-close-circle search-widget-icon search-widget-icon-close cursor-pointer"
                      onClick={handleClickDeleteSearchValue}
                    ></span>
                  )}
                </div>
                <Dropdown
                  isOpen={showDropDownMenu}
                  toggle={() => {
                    setShowDropDownMenu(false);
                  }}
                  className="w-100"
                >
                  <DropdownMenu className="p-0 mt-1 w-100">
                    <DropdownItem className="border-bottom position-sticky top-0" onClick={toggleOnCustomerModal}>
                      <div className="d-flex">
                        <span className="me-3 text-center d-flex align-items-center" style={{ width: "2rem" }}>
                          <i className="bx bx-plus fs-22"></i>
                        </span>
                        <h5 className="flex-grow-1 fw-semibold mb-0 d-flex align-items-center">
                          {t("Add new customer")}
                        </h5>
                      </div>
                    </DropdownItem>
                    {loading ? (
                      <Loader />
                    ) : (
                      <div
                        id="customer-search-drop-down"
                        className="w-100"
                        style={{ maxHeight: "400px", overflow: "auto" }}
                      >
                        <InfiniteScroll
                          dataLength={items.length}
                          next={() => {
                            listData(query, page + 1);
                          }}
                          hasMore={hasMore}
                          loader={<Loader />}
                          scrollableTarget="customer-search-drop-down"
                        >
                          {items.length === 0 ? (
                            <div className="text-center">
                              <LottieIcon
                                style={{ width: "42px", height: "42px" }}
                                src="https://cdn.lordicon.com/msoeawqm.json"
                              />
                              <div>
                                <p>{t("Sorry! No Result Found")}</p>
                              </div>
                            </div>
                          ) : (
                            items.map((item, index) => (
                              <DropdownItem key={index} className="hstack" onClick={() => handleClickItem(item)}>
                                <div className="me-3 avatar-xs">
                                  <div className="avatar-title rounded-circle bg-light text-primary">
                                    {String(item.first_name).charAt(0).toUpperCase()}
                                  </div>
                                </div>
                                <h5 className="flex-grow-1 fw-semibold mb-0 d-flex align-items-center">
                                  <FullNameRender first_name={item?.first_name} last_name={item?.last_name} />
                                </h5>
                              </DropdownItem>
                            ))
                          )}
                        </InfiniteScroll>
                      </div>
                    )}
                  </DropdownMenu>
                </Dropdown>
              </form>
            </div>
            <div
              className="d-flex flex-column justify-content-center text-center text-muted "
              style={{ height: "200px" }}
            >
              {loading && !showDropDownMenu ? (
                <Loader />
              ) : (
                <Fragment>
                  <i className="bx bxs-user-detail fs-48"></i>
                  <span>{t("No customer info")}</span>
                </Fragment>
              )}
            </div>
          </CardBody>
        )}
      </Card>
    </Fragment>
  );
};

const DropDownAddress = ({ isOpen, toggle, onChangeCustomerAddress, addresses, toggleCustomerModal }) => {
  const { t } = useTranslation();
  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownMenu className="p-0" style={{ width: "600px", maxHeight: "600px" }}>
        <SimpleBar className="h-100">
          <div className="border-bottom p-3">
            <span className="fw-medium">{t("Change address")}</span>
          </div>

          {addresses.length > 0 ? (
            addresses.map((item, index) => (
              <DropdownItem key={index} onClick={() => onChangeCustomerAddress(item)}>
                <p className="mb-0 fw-medium">
                  <FullNameRender first_name={item?.first_name} last_name={item?.last_name} />
                </p>
                <div className="mb-0 text-wrap">{item?.address1 || "---"}</div>
              </DropdownItem>
            ))
          ) : (
            <div className="hstack p-3">
              <span className="me-auto">{t("This customer doesn't have any addresses!")}</span>
              <span className="text-primary text-decoration-underline cursor-pointer" onClick={toggleCustomerModal}>
                {t("Add address")}
              </span>
            </div>
          )}
        </SimpleBar>
      </DropdownMenu>
    </Dropdown>
  );
};
export default CustomerDetails;
