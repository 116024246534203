import React from 'react';
import { Link } from 'react-router-dom';

function CustomLink({ baseUrl, filterTime, linkText, className }) {
  const queryParams = [
    filterTime?.firstDate ? `created_at_from=${filterTime.firstDate}` : '',
    filterTime?.secondDate ? `created_at_to=${filterTime.secondDate}` : ''
  ].filter(Boolean).join('&');

  const url = `${baseUrl}${queryParams ? `?${queryParams}` : ''}`;

  return (
    <Link to={url} className={className}>
      {linkText}
    </Link>
  );
}

export default CustomLink;
