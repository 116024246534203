export const TERMINAL = {
  _list: "/terminals",
  get: true,
  add: true,
  update: true,
  delete: true,
};

export const SHIFT = {
  _list: "/shifts",
  get: "/shifts:id",
  add: true,
  update: true,
  delete: true,
};

export const POS = {
  pos: "/pos",
};