import { useState, useEffect, Fragment } from "react";
import { deepCopy } from "../../../helpers/format_helper";
import { Input } from "reactstrap";
import ButtonTheme from "../ButtonTheme";
import { useTranslation } from "react-i18next";

const NoteInput = ({ onChangeOrderLineItem, product, i, isEdit}) => {
  const [note, setNote] = useState("");
  const [isEditNote, setIsEditNote] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setNote(product.note || "");
  }, [product]);

  const handleChangeNote = (data, index) => {
    let newData = deepCopy(product);
    newData.note = data;
    onChangeOrderLineItem(index, newData);
  };

  return (
    <div
      onBlur={() => {
        setIsEditNote(false);
      }}
      className="hstack"
    >
      {t("Note")}:{" "}
      {isEditNote ? (
        <Input
          value={note}
          type="text"
          autoFocus={isEditNote}
          placeholder={"Enter note"}
          onChange={(e) => {
            let data = e.target.value;
            setNote(data);
          }}
          onBlur={() => {
            handleChangeNote(note, i);
          }}
        />
      ) : (
        <Fragment>
          {note}
          {isEdit && (
            <ButtonTheme
              className={"btn btn-ghost-primary waves-effect waves-light p-0"}
              onClick={() => {
                setIsEditNote(true);
              }}
            >
              <i className="ri-edit-2-line align-bottom"></i> Edit
            </ButtonTheme>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default NoteInput;
