import { useDispatch } from "react-redux";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import { formatVNDCurrency } from "../../../../helpers/format_helper";

import withRouter from "../../../../Components/Common/withRouter";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { listShifts } from "../../../../store/actions";

const ShiftsPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Shift List")} | OptiWarehouse`;

  const dispatch = useDispatch();

  const { shifts, shiftsLoading, shiftsSuccess, shiftsAction, shiftsError, shiftsLimit, shiftsTotal, shiftsPage } =
    useMemoizedSelector((state) => ({
      shifts: state.Shifts.shifts,
      shiftsLoading: state.Shifts.loading,
      shiftsAction: state.Shifts.action,
      shiftsSuccess: state.Shifts.success,
      shiftsError: state.Shifts.error,
      shiftsLimit: state.Shifts.limit,
      shiftsTotal: state.Shifts.total,
      shiftsPage: state.Shifts.page,
    }));

  const headers = ["name", "starting_cash", "actual_cash", "created_at", "updated_at", "close_time", "action"];

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="price-group-list">
              <CardHeader className="border-0">
                <Row className="g-4 align-items-center">
                  <div className="col-sm">
                    <div>
                      <h5 className="card-title mb-0">{t("Shift List")}</h5>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <TableContainerCustom
                  filterType={"shifts"}
                  searchPlaceHolder={t("Search shift...")}
                  listFilter={[
                    // {
                    //   id: "user_id",
                    //   type: "selectBox",
                    //   title: "User",
                    //   remote: true,
                    //   pathUrlLoad: "https://admin.onexapis.com/admin/users",
                    // },
                    {
                      id: "location_id",
                      type: "selectBox",
                      title: "Branch",
                      remote: true,
                      pathUrlLoad: "inventory/locations",
                    },
                    {
                      id: "terminal_id",
                      type: "selectBox",
                      title: "Terminal",
                      remote: true,
                      pathUrlLoad: "pos/terminals",
                    },
                  ]}
                  headers={headers}
                  isSettings={true}
                  listHeader={[
                    {
                      title: "Name",
                      dataIndex: "name",
                      key: "name",
                      render: (value, row, index) => {
                        return <>{row?.user?.name_staff || "---"}</>;
                      },
                    },
                    {
                      title: "Starting cash",
                      dataIndex: "starting_cash",
                      key: "starting_cash",
                      render: (value, row, index) => {
                        return <>{formatVNDCurrency(value)}</>;
                      },
                    },
                    {
                      title: "Actual cash",
                      dataIndex: "actual_cash",
                      key: "actual_cash",
                      render: (value, row, index) => {
                        return <>{formatVNDCurrency(value)}</>;
                      },
                    },
                    {
                      title: "Created at",
                      dataIndex: "created_at",
                      key: "created_at",
                      defaultSortOrder: "desc",
                      sorter: true,
                    },
                    {
                      title: "Updated at",
                      dataIndex: "updated_at",
                      key: "updated_at",
                      sorter: true,
                    },
                    {
                      title: "Closed at",
                      dataIndex: "close_time",
                      key: "close_time",
                      sorter: true,
                    },
                    {
                      title: "Action",
                      dataIndex: "action",
                      key: "action",
                      render: (value, row, index) => {
                        return (
                          <UncontrolledDropdown>
                            <DropdownToggle href="#" className="btn btn-soft-primary btn-sm" tag="button">
                              <i className="ri-more-fill" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end" container="body">
                              <DropdownItem
                                onClick={() => {
                                  navigate(`/shifts/${row.id}`);
                                }}
                              >
                                <i className="ri-eye-fill align-bottom me-2 text-primary"></i> {t("View shift details")}
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        );
                      },
                    },
                  ]}
                  contents={shifts}
                  limitPage={Number(shiftsLimit)}
                  currentPage={Number(shiftsPage) + 1}
                  totalItems={shiftsTotal}
                  loading={shiftsLoading}
                  showPagination={true}
                  scrollHeight="50vh"
                  onCallData={(dataPayload) => {
                    dispatch(listShifts(dataPayload));
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default withRouter(ShiftsPage);
