import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import { DiscountTypes } from "../../../../../Components/constants/common";

const InstructionsCondition = ({ type }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(false);
  };
  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={toggle} size="lg" centered scrollable>
        <ModalHeader toggle={toggle}>{t("Instructions for creating promotional conditions")}</ModalHeader>
        <ModalBody>
          {type === DiscountTypes.BY_ORDER_TOTAL && (
            <div>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 1:</span>{" "}
                {t("Enter the minimum and maximum order values to qualify for the discount under")}{" "}
                <span className="fw-semibold">{t("Amount from")}</span> {t("and")}{" "}
                <span className="fw-semibold">{t("Amount to")}</span>
              </p>
              <p>
                <span className="fw-semibold">{t("Step")} 2:</span>{" "}
                {t("Enter the discount percentage for the purchased products under")}{" "}
                <span className="fw-semibold">{t("Discount")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Example")}:</span>{" "}
                {t("20% discount for orders with a value between 1,000,000 and 2,000,000")}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 1:</span> {t("Enter")}{" "}
                <span className="fw-semibold">{t("Amount from as 1,000,000 and Amount to as 2,000,000")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 2:</span> {t("Enter the discount value as")}{" "}
                <span className="fw-semibold">20%</span>
              </p>
            </div>
          )}
          {type === DiscountTypes.BY_PRODUCT && (
            <div>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 1:</span> {t("Choose")}{" "}
                <span className="fw-semibold">{t("Effective product")}</span>{" "}
                {t("which can be Product/Product variant/Product category/Product brand")}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 2:</span> {t("In")}{" "}
                <span className="fw-semibold">{t("Purchase product")}</span>
                {", "}
                {t(
                  "enter the SKU code or the name/type/brand of the product, and check the selected products for the promotion",
                )}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 3:</span>{" "}
                {t("Enter the minimum quantity of products required in the order under")}{" "}
                <span className="fw-semibold">{t("Minimum quantity")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Note")}:</span>{" "}
                {t(
                  "The quantity of products in the order needed to satisfy the promotion condition can be either the quantity of a single product or the total quantity of all products in the order combined",
                )}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 4:</span>{" "}
                {t("Enter the maximum quantity of products eligible for the discount under")}{" "}
                <span className="fw-semibold">{t("Discount limit")}</span>
              </p>
              <p>
                <span className="fw-semibold">{t("Step")} 5:</span>{" "}
                {t("Enter the discount value for each selected product")}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Example")}:</span>{" "}
                {t(
                  "Buy any combination of Shirts, Pants, Hats, and Bags with a minimum quantity of 5 for a 20% discount on each item. The total number of products eligible for the discount",
                )}{" "}
                <span className="fw-semibold">{t("is limited to 100")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 1:</span> {t("Choose")}{" "}
                <span className="fw-semibold">{t("Effective product")}</span> {t("which can be Product variant")}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 2:</span> {t("In")}{" "}
                <span className="fw-semibold">{t("Purchase product")}</span> {t("find and select products such as")}{" "}
                <span className="fw-semibold">{t("Shirts, Pants, Hats, and Bags")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 3:</span> {t("Enter")}{" "}
                <span className="fw-semibold">{t("Minimum quantity")}</span> {t("as")} 5
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 4:</span> {t("Enter")}{" "}
                <span className="fw-semibold">{t("Discount limit")}</span> {t("as")} 100
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 5:</span> {t("Enter")}{" "}
                <span className="fw-semibold">{t("Discount")}</span> {t("as")} 20%
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Example scenarios for applying the promotion")}:</span>
              </p>
              <p className="mb-0">
                - {t("An order with 5 Shirt products will receive a 20% discount for all 5 shirts")}
              </p>
              <p className="mb-0">
                - {t("An order with 3 Pants + 2 Hats will receive a 20% discount for Pants and Hats")}
              </p>
            </div>
          )}
          {type === DiscountTypes.PRODUCT_BY_ORDER_TOTAL && (
            <div>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 1:</span> {t("Choose")}{" "}
                <span className="fw-semibold">{t("Effective product")}</span>{" "}
                {t("which can be Product/Product variant/Product category/Product brand")}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 2:</span>{" "}
                {t("Enter the minimum order value required to qualify for the promotion under")}{" "}
                <span className="fw-semibold">{t("Oder total from")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 3:</span> {t("In")}
                <span className="fw-semibold">{t("Sale offs product")}</span>
                {", "}
                {t(
                  "enter the SKU code or the name/type/brand of the product and check the selected products for the promotion",
                )}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 4:</span>{" "}
                {t("Enter the maximum quantity of sale offs product under")}{" "}
                <span className="fw-semibold">{t("Maximum quantity")}</span>
              </p>
              <p>
                <span className="fw-semibold">{t("Step")} 5:</span>{" "}
                {t("Enter the discount value for the selected products under")}{" "}
                <span className="fw-semibold">{t("Discount")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Example")}:</span>{" "}
                {t(
                  "An order with a value of 1,000,000 will be eligible to purchase up to 4 items of Shirts, Hats, Bags, and Pants with a 10% discount on each item",
                )}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 1:</span> {t("Choose")}{" "}
                <span className="fw-semibold">{t("Effective product")}</span> {t("which can be Product variant")}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 2:</span> {t("Enter")}{" "}
                <span className="fw-semibold">{t("Order total from as 1,000,000")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 3:</span> {t("In")}{" "}
                <span className="fw-semibold">{t("Sale offs product")}</span> {t("find and select such as")}{" "}
                <span className="fw-semibold">{t("Shirts, Pants, Hats, and Bags")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 4:</span> {t("Enter")}{" "}
                <span className="fw-semibold">{t("Maximum quantity as 4")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 5:</span> {t("Enter discount as")}{" "}
                <span className="fw-semibold">10%</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Example scenarios for applying the promotion")}:</span>
              </p>
              <p className="mb-0">
                -{" "}
                {t(
                  "An order with a value of 1,000,000 can purchase 4 Shirts or buy 3 Pants + 1 Shirt with a 10% discount on each item",
                )}
              </p>
            </div>
          )}
          {type === DiscountTypes.BY_QUANTITY && (
            <div>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 1:</span> {t("Choose")}{" "}
                <span className="fw-semibold">{t("Effective product")}</span>{" "}
                {t("which can be Product/Product variant/Product category/Product brand")}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 2:</span> {t("In")}
                <span className="fw-semibold">{t("Purchase product")}</span>
                {", "}
                {t(
                  "enter the SKU code or the name/type/brand of the product and check the selected products for the promotion",
                )}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 3:</span>{" "}
                {t("Enter the minimum quantity of products required in the order under")}{" "}
                <span className="fw-semibold">{t("Quantity from")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Note")}:</span>{" "}
                {t(
                  "The quantity of products in the order needed to satisfy the promotion condition can be either the quantity of a single product or the total quantity of all products in the order combined",
                )}
              </p>
              <p>
                <span className="fw-semibold">{t("Step")} 4:</span>{" "}
                {t("Enter the discount value for the selected products under")}{" "}
                <span className="fw-semibold">{t("Discount")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Example")}:</span>{" "}
                {t(
                  "Purchase any combination of Shirts, Pants, Hats, and Bags with a minimum quantity of 4 and a maximum quantity of 6, and receive a 10% discount on each item",
                )}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 1:</span> {t("Choose")}{" "}
                <span className="fw-semibold">{t("Effective product")}</span> {t("which can be Product variant")}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 2:</span> {t("In")}{" "}
                <span className="fw-semibold">{t("Purchase product")}</span> {t("find and select such as")}{" "}
                <span className="fw-semibold">{t("Shirts, Pants, Hats, and Bags")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 3:</span> {t("Enter")}{" "}
                <span className="fw-semibold">{t("Quantity from")}</span> {t("as")} 4 {t("and")}{" "}
                <span className="fw-semibold">{t("Quantity to")}</span> {t("as")} 6
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 4:</span> {t("Enter discount as")}{" "}
                <span className="fw-semibold">10%</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Example scenarios for applying the promotion")}:</span>
              </p>
              <p className="mb-0">
                - {t("An order with 4 Shirt products will receive a 10% discount for all 4 shirts")}
              </p>
              <p className="mb-0">
                - {t("An order with 3 Pants + 1 Shirt will receive a 10% discount on 3 Pants and 1 Shirt")}
              </p>
            </div>
          )}
          {type === DiscountTypes.BY_PURCHASE_PRODUCT && (
            <div>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 1:</span> {t("Choose")}{" "}
                <span className="fw-semibold">{t("Effective product")}</span>{" "}
                {t(
                  "which can be Product/Product variant/Product category/Product brand. This product type will apply to all conditions in the promotion",
                )}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 2:</span> {t("In")}
                <span className="fw-semibold">{t("Purchase product")}</span>
                {", "}
                {t(
                  "enter the SKU code or the name/type/brand of the product and check the selected products for the promotion",
                )}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 3:</span>{" "}
                {t("Enter the quantity of products needed to satisfy the condition under")}{" "}
                <span className="fw-semibold">{t("Quantity")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 4:</span> {t("Choose")}{" "}
                <span className="fw-semibold">{t("Effective sale offs product")}</span>{" "}
                {t(
                  "which can be Product/Product variant/Product category/Product brand. The promotional product is the product purchased with a discount",
                )}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 5:</span> {t("In")}{" "}
                <span className="fw-semibold">{t("Sale offs product")}</span>
                {", "}
                {t(
                  "enter the SKU code or the name/type/brand of the product and check the selected products for the discount",
                )}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 6:</span>{" "}
                {t("Enter the maximum quantity of promotional products eligible for purchase under")}{" "}
                <span className="fw-semibold">{t("Maximum quantity")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 7:</span>{" "}
                {t("Enter the discount value for the discounted products under")}{" "}
                <span className="fw-semibold">{t("Discount")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Example")}:</span>{" "}
                {t(
                  "Purchase any combination of 4 Shirts, Pants, Hats, and Bags and receive a 20% discount on up to 3 pairs of Sandals or any Shoes",
                )}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 1:</span> {t("Choose")}{" "}
                <span className="fw-semibold">{t("Effective product")}</span> {t("which can be Product variant")}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 2:</span> {t("In")}{" "}
                <span className="fw-semibold">{t("Purchase product")}</span> {t("find and select such as")}{" "}
                <span className="fw-semibold">{t("Shirts, Pants, Hats, and Bags")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 3:</span> {t("Enter")}{" "}
                <span className="fw-semibold">{t("Quantity")}</span> {t("as")} 4
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 4:</span> {t("Choose")}{" "}
                <span className="fw-semibold">{t("Effective sale offs product")}</span>{" "}
                {t("which can be Product variant")}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 5:</span> {t("In")}{" "}
                <span className="fw-semibold">{t("Sale offs product")}</span> {t("find and select such as")}{" "}
                <span className="fw-semibold">{t("Sandals, Shoes")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 6:</span> {t("Enter")}{" "}
                <span className="fw-semibold">{t("Maximum quantity")}</span> {t("as")} 3
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 7:</span> {t("Enter discount as")}{" "}
                <span className="fw-semibold">20%</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Example scenarios for applying the promotion")}:</span>
              </p>
              <p className="mb-0">
                - {t("An order with 4 Shirts can purchase 3 pairs of Shoes at a 20% discounted price")}
              </p>
              <p className="mb-0">
                -{" "}
                {t(
                  "An order with 3 Shirts + 1 pair of Pants can purchase 2 pairs of Shoes + 1 pair of Sandals at a 20% discounted price",
                )}
              </p>
            </div>
          )}
          {type === DiscountTypes.GIFT_BY_ORDER_TOTAL && (
            <div>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 1:</span> {t("Choose")}{" "}
                <span className="fw-semibold">{t("Effective product")}</span>{" "}
                {t("which can be Product/Product variant/Product category/Product brand")}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 2:</span>{" "}
                {t("Enter the minimum order value required to qualify for the promotion under")}{" "}
                <span className="fw-semibold">{t("Oder total from")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 3:</span> {t("In")}{" "}
                <span className="fw-semibold">{t("Gift product")}</span>
                {", "}
                {t(
                  "enter the SKU code or the name/type/brand of the product, and check the selected products for the gift",
                )}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 4:</span>{" "}
                {t("Enter the maximum quantity of gift products eligible for the order under")}{" "}
                <span className="fw-semibold">{t("Maximum quantity")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Example")}:</span>{" "}
                {t(
                  "An order with a value of 1,000,000 will receive up to 4 items of Shirts, Hats, Bags, and Pants as a gift",
                )}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 1:</span> {t("Choose")}{" "}
                <span className="fw-semibold">{t("Effective product")}</span> {t("which can be Product variant")}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 2:</span> {t("Enter")}{" "}
                <span className="fw-semibold">{t("Order total from as 1,000,000")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 3:</span> {t("In")}{" "}
                <span className="fw-semibold">{t("Gift product")}</span> {t("find and select such as")}{" "}
                <span className="fw-semibold">{t("Shirts, Hats, Bags, and Pants")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 4:</span> {t("Enter")}{" "}
                <span className="fw-semibold">{t("Max quantity as 4")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Example scenarios for applying the promotion")}:</span>
              </p>
              <p className="mb-0">
                -{" "}
                {t(
                  "An order with a value of 1,000,000 will receive 4 Shirts as a gift or receive 3 Pants + 1 Shirt as a gift",
                )}
              </p>
            </div>
          )}
          {type === DiscountTypes.GIFT_BY_PURCHASE_PRODUCT && (
            <div>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 1:</span> {t("Choose")}{" "}
                <span className="fw-semibold">{t("Effective product")}</span>{" "}
                {t(
                  "which can be Product/Product variant/Product category/Product brand. This product type will apply to all conditions in the promotion",
                )}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 2:</span> {t("In")}{" "}
                <span className="fw-semibold">{t("Purchase product")}</span>
                {", "}
                {t(
                  "enter the SKU code or the name/type/brand of the product and check the selected products for the promotion",
                )}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 3:</span>{" "}
                {t("Enter the quantity of products needed to satisfy the condition under")}{" "}
                <span className="fw-semibold">{t("Quantity")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 4:</span> {t("Choose")}{" "}
                <span className="fw-semibold">{t("Effective sale offs product")}</span>{" "}
                {t(
                  "which can be Product/Product variant/Product category/Product brand. The promotional product is the item given as a gift",
                )}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 5:</span> {t("In")}{" "}
                <span className="fw-semibold">{t("Sale offs product")}</span>
                {", "}
                {t(
                  "enter the SKU code or the name/type/brand of the product and check the selected products for the gift",
                )}
              </p>
              <p>
                <span className="fw-semibold">{t("Step")} 6:</span>{" "}
                {t("Enter the maximum quantity of promotional products eligible for purchase under")}{" "}
                <span className="fw-semibold">{t("Maximum quantity")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Example")}:</span>{" "}
                {t(
                  "An order purchasing any 2 items of Shirts, Pants, Hats, and Bags will receive up to 2 items of Shoes, Scarves as a gift",
                )}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 1:</span> {t("Choose")}{" "}
                <span className="fw-semibold">{t("Effective product")}</span> {t("which can be Product variant")}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 2:</span> {t("In")}{" "}
                <span className="fw-semibold">{t("Purchase product")}</span> {t("find and select such as")}{" "}
                <span className="fw-semibold">{t("Shirts, Pants, Hats, and Bags")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 3:</span> {t("Enter")}{" "}
                <span className="fw-semibold">{t("Quantity")}</span> {t("as")} 2
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 4:</span> {t("Choose")}{" "}
                <span className="fw-semibold">{t("Effective sale offs product")}</span>{" "}
                {t("which can be Product variant")}
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 5:</span> {t("In")}{" "}
                <span className="fw-semibold">{t("Sale offs product")}</span> {t("find and select such as")}{" "}
                <span className="fw-semibold">{t("Shoes, Scarves")}</span>
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Step")} 6:</span> {t("Enter")}{" "}
                <span className="fw-semibold">{t("Maximum quantity")}</span> {t("as")} 2
              </p>
              <p className="mb-0">
                <span className="fw-semibold">{t("Example scenarios for applying the promotion")}:</span>
              </p>
              <p className="mb-0">- {t("An order with 2 Shirts will receive 2 pairs of Shoes as a gift")}</p>
              <p className="mb-0">
                - {t("An order with 1 Shirt + 1 pair of Pants will receive 1 pair of Shoes + 1 Scarf as a gift")}
              </p>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <ButtonTheme className={"btn btn-outline-primary w-md"} onClick={toggle}>
            {t("Close")}
          </ButtonTheme>
        </ModalFooter>
      </Modal>
      <blockquote className="blockquote custom-blockquote blockquote-primary rounded mb-0 hstack gap-2">
        <i className="ri-lightbulb-line fs-22" />
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {t("Instructions for creating promotional conditions")}
        </div>
      </blockquote>
    </Fragment>
  );
};
export default InstructionsCondition;
