import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";

// Redux States
import { LIST_CUSTOMER, DELETE_CUSTOMER, ADD_NEW_CUSTOMER, UPDATE_CUSTOMER, GET_CUSTOMER } from "./actionType";

import {
  deleteCustomerSuccess,
  deleteCustomerFail,
  addCustomerSuccess,
  addCustomerFail,
  updateCustomerSuccess,
  updateCustomerFail,
  listCustomerSuccess,
  listCustomerFail,
  getCustomerSuccess,
  getCustomerFail,
} from "./action";

import { customersAPI } from "../../../../helpers/resource_helper";

function* listCustomer({ payload: { ...res } }) {
  try {
    const response = yield call(customersAPI.list, { ...res });
    yield put(listCustomerSuccess(response));
  } catch (error) {
    yield put(listCustomerFail(error));
  }
}

function* getCustomer({ payload: id }) {
  try {
    const response = yield call(customersAPI.get, { id });
    yield put(getCustomerSuccess(response));
  } catch (error) {
    yield put(getCustomerFail(error));
  }
}

function* deleteCustomer({ payload: { customer, handleAfterSubmit } }) {
  try {
    const response = yield call(customersAPI.delete, customer);
    yield put(deleteCustomerSuccess(customer));
    toast.success(response?.message, { autoClose: 3000 });
    yield call(handleAfterSubmit);
  } catch (error) {
    yield put(deleteCustomerFail(error));
    toast.error("Customer Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewCustomer({ payload: { customer, handleAfterSubmit } }) {
  try {
    const response = yield call(customersAPI.create, customer);
    yield put(addCustomerSuccess(response));
    yield call(handleAfterSubmit, response);
    toast.success(response?.message, { autoClose: 3000 });
    toast.success("Customer Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addCustomerFail(error));
    toast.error(error, { autoClose: 3000 });
  }
}

function* onUpdateCustomer({ payload: { customer, handleAfterSubmit } }) {
  try {
    const response = yield call(customersAPI.put, customer);
    yield put(updateCustomerSuccess(response));
    yield call(handleAfterSubmit, response);
    toast.success("Customer Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateCustomerFail(error));
    toast.error("Customer Updated Failed", { autoClose: 3000 });
  }
}

export function* watchGetCustomers() {
  yield takeEvery(LIST_CUSTOMER, listCustomer);
}

export function* watchDeleteCustomer() {
  yield takeEvery(DELETE_CUSTOMER, deleteCustomer);
}

export function* watchGetCustomer() {
  yield takeEvery(GET_CUSTOMER, getCustomer);
}

export function* watchUpdateCustomer() {
  yield takeEvery(UPDATE_CUSTOMER, onUpdateCustomer);
}

export function* watchAddNewCustomer() {
  yield takeEvery(ADD_NEW_CUSTOMER, onAddNewCustomer);
}

function* customersSaga() {
  yield all([
    fork(watchGetCustomer),
    fork(watchGetCustomers),
    fork(watchUpdateCustomer),
    fork(watchAddNewCustomer),
    fork(watchDeleteCustomer),
  ]);
}

export default customersSaga;
