import {
  LIST_ROLE,
  LIST_ROLE_SUCCESS,
  LIST_ROLE_FAIL,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAIL,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAIL,
  ADD_NEW_ROLE,
  UPDATE_ROLE,
  GET_ROLE,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAIL,
  RESET_ROLE,
} from "./actionType";

const INIT_STATE = {
  roles: [],
  role: {},
  error: null,
  success: false,
  loading: false,
  limit: 0,
  page: 0,
  total: 0,
};

const Roles = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_ROLE:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };

    case LIST_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        roles: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
      };

    case LIST_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case GET_ROLE:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };

    case GET_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        role: action.payload ?? {},
      };

    case GET_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        role: {},
        error: action.payload,
      };

    case DELETE_ROLE:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };

    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        roles: state.roles.filter((item) => item.id.toString() !== action.payload.id.toString()),
      };

    case DELETE_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case ADD_NEW_ROLE:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };

    case ADD_ROLE_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        roles: [...state.roles, action.payload],
      };

    case ADD_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case UPDATE_ROLE:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };
    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        roles: state.roles.map((role) =>
          role.id.toString() === action.payload.id.toString() ? { ...role, ...action.payload } : role,
        ),
      };

    case UPDATE_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case RESET_ROLE:
      return {
        ...state,
        role: {},
      };

    default:
      return { ...state };
  }
};

export default Roles;
