import { Fragment, useImperativeHandle } from "react";
import { useEffect, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from "reactstrap";
import { DiscountAmountType, DiscountTypes } from "../constants/common";
import { formatVNDCurrency } from "../../helpers/format_helper";
import { useTranslation } from "react-i18next";
import InputTheme from "./InputTheme";

const DiscountDropDown = ({
  discount,
  amountDiscount = 0,
  values,
  discountType,
  discountPercent,
  discountPrice,
  onChangeDiscountType,
  onChangeDiscountPercent,
  onChangeDiscountPrice,
  edit = true,
  refToggleDiscount = null,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => {
    let totalSalePrice = values.order_line_items.reduce((total, item) => total + item.quantity * item.sale_price, 0);
    let totalFeePrice = values.fees ? values.fees.reduce((total, item) => total + Number(item.amount), 0) : 0;
    let newDiscountPrice = 0;
    if (discountType === "value") {
      if (totalSalePrice + totalFeePrice >= discountPrice) {
        newDiscountPrice = discountPrice;
      }
      onChangeDiscountPercent(0);
    } else {
      newDiscountPrice = (discountPercent / 100) * (totalSalePrice + totalFeePrice);
    }
    onChangeDiscountPrice(newDiscountPrice);
    setIsOpen(!isOpen);
  };

  useImperativeHandle(refToggleDiscount, () => ({
    toggleDiscount: () => {
      handleToggle();
    },
  }));

  return (
    <Fragment>
      {edit ? (
        <Dropdown isOpen={isOpen} toggle={handleToggle}>
          <DropdownToggle tag="button" className="btn p-0">
            <span className="text-primary">{t("Discount")} (F6)</span>
          </DropdownToggle>
          <DropdownMenu className="p-0">
            <DropdownItem className="p-3" toggle={false}>
              {values.discount_id && discount && discount.type === DiscountTypes.BY_ORDER_TOTAL && (
                <div className="hstack mb-3">
                  <div className="flex-grow-1">{t(discount.name)}</div>
                  <div>{formatVNDCurrency(amountDiscount)}</div>
                </div>
              )}
              <div className="hstack">
                <div className="flex-shrink-0 me-3">
                  <Input
                    type="radio"
                    className="btn-check"
                    id="value-discount"
                    name="discount-options"
                    onChange={() => {
                      discountType !== "value" && onChangeDiscountType("value");
                    }}
                    checked={discountType === "value"}
                  />
                  <label className="btn btn-outline-primary m-0 rounded-0 rounded-start w-sm" htmlFor="value-discount">
                    {t("Value")}
                  </label>
                  <Input
                    type="radio"
                    className="btn-check"
                    name="discount-options"
                    id="percent-discount"
                    onChange={() => {
                      discountType !== "percent" && onChangeDiscountType("percent");
                    }}
                    checked={discountType === "percent"}
                  />
                  <label className="btn btn-outline-primary m-0 rounded-0 rounded-end w-sm" htmlFor="percent-discount">
                    %
                  </label>
                </div>
                <div className="flex-grow-1">
                  {discountType === "value" ? (
                    <InputTheme
                      isForm={false}
                      format="price"
                      min={0}
                      className="form-control border-0 border-bottom text-end w-md"
                      onChange={(data) => {
                        if (data < 0 || data === "") {
                          onChangeDiscountPrice(0);
                        } else {
                          onChangeDiscountPrice(data);
                        }
                      }}
                      value={discountPrice}
                    />
                  ) : (
                    <InputTheme
                      isForm={false}
                      format="percent"
                      min={0}
                      max={100}
                      className="form-control border-0 border-bottom text-end w-md"
                      onChange={(data) => {
                        if (data < 0 || data === "") {
                          onChangeDiscountPercent(0);
                        } else if (data > 100) {
                          onChangeDiscountPercent(100);
                        } else {
                          onChangeDiscountPercent(data);
                        }
                      }}
                      value={discountPercent}
                    />
                  )}
                </div>
              </div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ) : (
        <>{t("Discount")}</>
      )}
    </Fragment>
  );
};
export default DiscountDropDown;
