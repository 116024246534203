import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { isEmpty } from "lodash";

import {
  convertValidDate,
  convertValidTime,
  formatDateTime,
  formatVNDCurrency,
} from "../../../../helpers/format_helper";

import user1 from "../../../../assets/images/users/avatar-1.jpg";
import SimplePie from "./Components/SimplePie";
import BalanceColumn from "./Components/BalanceColumn";
import AddEditAccountModal from "./AddEditAccountModal";
import Loader from "../../../../Components/Common/Loader";
import withRouter from "../../../../Components/Common/withRouter";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { AccountTypeOptions, Actions, ReservedVoucher } from "../../../../Components/constants/common";
import ActionDropdown from "../../../../Components/Common/ActionDropdown";

import { deleteAccount, listAccount, listTransaction } from "../../../../store/actions";
import SimpleBar from "simplebar-react";
import NotFound from "../../../../Components/Common/NotFound";

const AccountsPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Account List")} | OptiWarehouse`;
  const dispatch = useDispatch();

  const {
    accounts,
    accountsLoading,
    accountsAction,
    accountsMessage,
    accountsSuccess,
    accountsError,
    accountsLimit,
    accountsPage,
    accountsTotal,
  } = useMemoizedSelector((state) => ({
    accounts: state.Accounts.accounts,
    accountsAction: state.Accounts.action,
    accountsLoading: state.Accounts.loading,
    accountsSuccess: state.Accounts.success,
    accountsError: state.Accounts.error,
    accountsMessage: state.Accounts.message,
    accountsLimit: state.Accounts.limit,
    accountsTotal: state.Accounts.total,
    accountsPage: state.Accounts.page,
  }));

  const {
    transactions,
    transactionsLoading,
    transactionsAction,
    transactionsMessage,
    transactionsSuccess,
    transactionsError,
    transactionsLimit,
    transactionsPage,
    transactionsTotal,
  } = useMemoizedSelector((state) => ({
    transactions: state.Transactions.transactions,
    transactionsAction: state.Transactions.action,
    transactionsLoading: state.Transactions.loading,
    transactionsSuccess: state.Transactions.success,
    transactionsError: state.Transactions.error,
    transactionsMessage: state.Transactions.message,
    transactionsLimit: state.Transactions.limit,
    transactionsTotal: state.Transactions.total,
    transactionsPage: state.Transactions.page,
  }));

  const [deleteModal, setDeleteModal] = useState(false);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [showEditAddAccountModal, setShowEditAddAccountModal] = useState(false);

  const [selectedAccount, setSelectedAccount] = useState(null);

  const handleDeleteAccount = () => {
    dispatch(
      deleteAccount(selectedAccount, () => {
        setSelectedAccount(null);
        setDeleteModal(false);
      }),
    );
  };
  const onClickDelete = (data) => {
    setSelectedAccount(data);
    setDeleteModal(true);
  };
  const onCloseDeleteModal = () => {
    if (!accountsLoading) {
      setSelectedAccount(null);
      setDeleteModal(false);
    }
  };

  const onClickEdit = (data) => {
    setSelectedAccount(data);
    setShowEditAddAccountModal(true);
  };

  const toggleEditAddAccountModal = () => {
    if (!accountsLoading) {
      setShowEditAddAccountModal(false);
      setSelectedAccount(null);
    }
  };

  const headers = [
    { title: t("Name"), dataIndex: "name", key: "name", sorter: true },
    { title: "Type", dataIndex: "type", key: "type", sorter: true },
    {
      title: t("Account Balance"),
      dataIndex: "balance",
      key: "balance",
      render: (_, data, index) => {
        return <BalanceColumn data={formatVNDCurrency(data.balance)} />;
      },
    },
    {
      title: t("Created At"),
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      defaultSortOrder: "desc",
    },
    {
      title: t("Updated at"),
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: true,
      // render: (_, data, index) => {
      //   return (
      //     <>
      //       {convertValidDate(data.updated_at)},
      //       <small className="text-muted"> {convertValidTime(data.updated_at)}</small>
      //     </>
      //   );
      // },
    },
    {
      title: t("Action"),
      dataIndex: "action",
      render: (_, data, index) => {
        return (
          <ActionDropdown
            index={index}
            actions={[
              {
                type: "edit",
                onClick: () => {
                  onClickEdit(data);
                },
              },
              {
                type: "delete",
                onClick: () => {
                  onClickDelete(data);
                },
              },
            ]}
          />
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(listTransaction({ limit: 20, sort_created_at: "desc" }));
  }, [dispatch]);

  return (
    <Fragment>
      <div className="page-content">
        <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={accounts} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteAccount}
          loading={accountsLoading && accountsAction === Actions.DELETE}
          onCloseClick={onCloseDeleteModal}
        />
        <AddEditAccountModal
          isOpen={showEditAddAccountModal}
          toggle={toggleEditAddAccountModal}
          account={selectedAccount}
        />
        <Container fluid>
          <Row>
            <Col lg={9}>
              {accountsLoading && accountsAction === Actions.LIST ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
                  <Loader />
                </div>
              ) : (
                <Row>
                  <Col md={4}>
                    <Card>
                      <CardHeader>
                        <h4 className="card-title mb-0">{t("Income Chart")}</h4>
                      </CardHeader>

                      <CardBody>
                        <SimplePie
                          dataColors='["--color-primary", "--color-success", "--color-warning", "--color-danger", "--color-info"]'
                          data={accounts}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card>
                      <CardHeader>
                        <h4 className="card-title mb-0">{t("Outcome Chart")}</h4>
                      </CardHeader>

                      <CardBody>
                        <SimplePie
                          dataColors='["--color-primary", "--color-success", "--color-warning", "--color-danger", "--color-info"]'
                          data={accounts}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card>
                      <CardHeader>
                        <h4 className="card-title mb-0">{t("Overall Chart")}</h4>
                      </CardHeader>

                      <CardBody>
                        <SimplePie
                          dataColors='["--color-primary", "--color-success", "--color-warning", "--color-danger", "--color-info"]'
                          data={accounts}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
              <Card>
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">{t("Account List")}</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex gap-3 flex-wrap">
                        <ButtonTheme
                          type="button"
                          className="btn btn-light"
                          icon="ri-file-text-line"
                          onClick={() => setIsExportCSV(true)}
                        >
                          {t("Export")}
                        </ButtonTheme>
                        <ButtonTheme
                          className="btn btn-primary"
                          id="create-btn"
                          icon="ri-add-line text-white"
                          onClick={() => {
                            setShowEditAddAccountModal(true);
                          }}
                        >
                          {t("Add Account")}
                        </ButtonTheme>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <TableContainerCustom
                    filterType={"accounts"}
                    searchPlaceHolder="Search accounts..."
                    listFilter={[
                      // {
                      //   id: "flow_id",
                      //   type: "selectBox",
                      //   title: "Flow",
                      //   remote: true,
                      //   pathUrlLoad: "flows",
                      // },
                      {
                        id: "type",
                        type: "selectBox",
                        title: `${t("Type")}`,
                        dataOption: AccountTypeOptions,
                      },
                    ]}
                    headers={headers}
                    contents={accounts}
                    limitPage={Number(accountsLimit)}
                    currentPage={Number(accountsPage) + 1}
                    totalItems={accountsTotal}
                    loading={accountsLoading && accountsAction === Actions.LIST}
                    showPagination={true}
                    scrollHeight="50vh"
                    onCallData={(dataPayload) => {
                      dispatch(listAccount(dataPayload));
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h5 className="fs-13 mb-3">{t("Income")}</h5>
                      <h2>
                        $0<small className="text-muted fs-14">.00</small>
                      </h2>
                      <p className="text-muted mb-0">
                        $0{" "}
                        <small className="badge badge-soft-success">
                          <i className="ri-arrow-right-up-line fs-13 align-bottom"></i>0%
                        </small>
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      <i className="mdi mdi-wallet-outline text-primary h1"></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h5 className="fs-13 mb-3">{t("Outcome")}</h5>
                      <h2>$0</h2>
                      <p className="text-muted mb-0">$0</p>
                    </div>
                    <div className="flex-shrink-0">
                      <i className="ri-hand-coin-line text-primary h1"></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h5 className="fs-13 mb-3">{t("Overall")}</h5>
                      <h2>$0</h2>
                      <p className="text-muted mb-0">
                        $0{" "}
                        <small className="badge badge-soft-success">
                          <i className="ri-arrow-right-up-line fs-13 align-bottom"></i>0%
                        </small>
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      <i className="ri-line-chart-line text-primary h1"></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">{t("Recent Transaction")}</h5>
                </CardHeader>
                <CardBody>
                  <SimpleBar style={{ maxHeight: "300px" }} className="mb-3">
                    {transactionsLoading && transactionsAction === Actions.LIST ? (
                      <Loader />
                    ) : transactions.length === 0 ? (
                      <NotFound />
                    ) : (
                      transactions.map((item, key) => (
                        <div key={key} className="d-flex mb-3">
                          <div className="flex-shrink-0">
                            <img src={user1} alt="" className="avatar-xxs" />
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h6 className="mb-1">{t("Transaction")}</h6>
                            <p className="text-muted mb-0">{formatDateTime(item?.effective_date)}</p>
                          </div>
                          <div className="flex-shrink-0">
                            {[ReservedVoucher.ORDER_PAYMENT_VOUCHER].includes(item.voucher.id) ? (
                              <h6 className="text-success mb-0">+ {formatVNDCurrency(item?.amount || 0)}</h6>
                            ) : (
                              <h6 className="text-danger mb-0">- {formatVNDCurrency(item?.amount || 0)}</h6>
                            )}
                          </div>
                        </div>
                      ))
                    )}
                  </SimpleBar>

                  <div>
                    <Link to="/transactions" className="btn btn-soft-info w-100">
                      {t("View All Transactions")} <i className="ri-arrow-right-line align-bottom"></i>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default withRouter(AccountsPage);
