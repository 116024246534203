import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";

// Redux States
import {
  LIST_REWARD_PROGRAM,
  DELETE_REWARD_PROGRAM,
  ADD_NEW_REWARD_PROGRAM,
  UPDATE_REWARD_PROGRAM,
  GET_REWARD_PROGRAM,
} from "./actionType";

import {
  deleteRewardProgramSuccess,
  deleteRewardProgramFail,
  addRewardProgramSuccess,
  addRewardProgramFail,
  updateRewardProgramSuccess,
  updateRewardProgramFail,
  listRewardProgramSuccess,
  listRewardProgramFail,
  getRewardProgramSuccess,
  getRewardProgramFail,
} from "./action";

import { rewardProgramsAPI } from "../../../../helpers/resource_helper";

function* listRewardProgram({ payload: { ...res } }) {
  try {
    const response = yield call(rewardProgramsAPI.list, { ...res });
    yield put(listRewardProgramSuccess(response));
  } catch (error) {
    yield put(listRewardProgramFail(error));
  }
}

function* getRewardProgram({ payload: id }) {
  try {
    const response = yield call(rewardProgramsAPI.get, { id });
    yield put(getRewardProgramSuccess(response));
  } catch (error) {
    yield put(getRewardProgramFail(error));
  }
}

function* deleteRewardProgram({ payload: { rewardProgram, handleAfterSubmit } }) {
  try {
    const response = yield call(rewardProgramsAPI.delete, rewardProgram);
    yield put(deleteRewardProgramSuccess(rewardProgram));
    toast.success(response?.message, { autoClose: 3000 });
    yield call(handleAfterSubmit);
  } catch (error) {
    yield put(deleteRewardProgramFail(error));
    toast.error("Reward Program Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewRewardProgram({ payload: { rewardProgram, handleAfterSubmit } }) {
  try {
    const response = yield call(rewardProgramsAPI.create, rewardProgram);
    yield put(addRewardProgramSuccess(response));
    toast.success(response?.message, { autoClose: 3000 });
    toast.success("Reward Program Added Successfully", { autoClose: 3000 });
    yield call(handleAfterSubmit, response);
  } catch (error) {
    yield put(addRewardProgramFail(error));
    toast.error("Reward Program Added Failed", { autoClose: 3000 });
  }
}

function* onUpdateRewardProgram({ payload: { rewardProgram, handleAfterSubmit } }) {
  try {
    const response = yield call(rewardProgramsAPI.put, rewardProgram);
    yield put(updateRewardProgramSuccess(response));
    toast.success("Reward Program Updated Successfully", { autoClose: 3000 });
    yield call(handleAfterSubmit, response);
  } catch (error) {
    yield put(updateRewardProgramFail(error));
    toast.error("Reward Program Updated Failed", { autoClose: 3000 });
  }
}

export function* watchGetRewardPrograms() {
  yield takeEvery(LIST_REWARD_PROGRAM, listRewardProgram);
}

export function* watchDeleteRewardProgram() {
  yield takeEvery(DELETE_REWARD_PROGRAM, deleteRewardProgram);
}

export function* watchGetRewardProgram() {
  yield takeEvery(GET_REWARD_PROGRAM, getRewardProgram);
}

export function* watchUpdateRewardProgram() {
  yield takeEvery(UPDATE_REWARD_PROGRAM, onUpdateRewardProgram);
}

export function* watchAddNewRewardProgram() {
  yield takeEvery(ADD_NEW_REWARD_PROGRAM, onAddNewRewardProgram);
}

function* rewardProgramsSaga() {
  yield all([
    fork(watchGetRewardProgram),
    fork(watchGetRewardPrograms),
    fork(watchUpdateRewardProgram),
    fork(watchAddNewRewardProgram),
    fork(watchDeleteRewardProgram),
  ]);
}

export default rewardProgramsSaga;
