import { Fragment, useRef } from "react";
import { useContext } from "react";
import { ScrollMenu, VisibilityContext, getItemsPos, slidingWindow } from "react-horizontal-scrolling-menu";
import { DragManager } from "./DragManager";
import ButtonTheme from "../ButtonTheme";

const HorizontalScrollingMenu = ({ children, className, scrollContainerRef }) => {
  const dragState = useRef(new DragManager());
  const handleDrag =
    ({ scrollContainer }) =>
    (ev) =>
      dragState.current.dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  return (
    <div onMouseLeave={dragState.current.dragStop} className={className}>
      <ScrollMenu
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
        onWheel={onWheel}
        // onMouseDown={() => dragState.current.dragStart}
        // onMouseUp={({ getItemById, scrollToItem, items }) =>
        //   () => {
        //     // NOTE: for center items
        //     dragState.current.dragStop();
        //     const { center } = getItemsPos(items.toItems());
        //     scrollToItem(getItemById(center), "smooth", "center");
        //   }}
        options={{ throttle: 0 }} // NOTE: for center items
        onMouseMove={handleDrag}
        apiRef={scrollContainerRef}
      >
        {children}
      </ScrollMenu>
    </div>
  );
};

function onWheel({ getItemById, items, scrollToItem }, ev) {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  const allItems = items.toItems();
  const visibleItems = items.getVisibleElements().map((el) => el[0]);

  if (ev.deltaY < 0) {
    // NOTE: for center items
    const nextGroupItems = slidingWindow(allItems, visibleItems).next();
    const { center } = getItemsPos(nextGroupItems);
    scrollToItem(getItemById(center), "smooth", "center");
  } else if (ev.deltaY > 0) {
    const prevGroupItems = slidingWindow(allItems, visibleItems).prev();
    const { center } = getItemsPos(prevGroupItems);
    scrollToItem(getItemById(center), "smooth", "center");
  }
}

function LeftArrow() {
  const visibility = useContext(VisibilityContext);
  const isFirstItemVisible = visibility.useIsVisible("first", true);

  return (
    <Fragment>
      {isFirstItemVisible ? (
        <></>
      ) : (
        <ButtonTheme
          className={"btn btn-icon btn-light"}
          disabled={isFirstItemVisible}
          onClick={() => visibility.scrollPrev()}
        >
          <i className="ri-arrow-left-s-line"></i>
        </ButtonTheme>
      )}
    </Fragment>
  );
}

function RightArrow() {
  const visibility = useContext(VisibilityContext);
  const isLastItemVisible = visibility.useIsVisible("last", false);

  return (
    <Fragment>
      {isLastItemVisible ? (
        <></>
      ) : (
        <ButtonTheme
          className={"btn btn-icon btn-light"}
          disabled={isLastItemVisible}
          onClick={() => visibility.scrollNext()}
        >
          <i className="ri-arrow-right-s-line"></i>
        </ButtonTheme>
      )}
    </Fragment>
  );
}

export default HorizontalScrollingMenu;
