import { useEffect, useState } from "react";
import InputTheme from "./InputTheme";

const InputPaymentField = ({ index, onChangePaymentPrice = () => {}, price = 0, isEdit = true }) => {
  const [value, setValue] = useState(0);
  useEffect(() => {
    setValue(price);
  }, [price]);
  return (
    <InputTheme
      className="form-control text-end border-0 border-bottom rounded-0 px-0"
      isForm={false}
      value={value}
      format="price"
      formNoMargin={true}
      onChange={(data) => {
        setValue(isNaN(data) ? 0 : data);
      }}
      onBlur={(e) => {
        onChangePaymentPrice(value, index);
      }}
      disabled={!isEdit}
    />
  );
};

export default InputPaymentField;
