import { Actions } from "../../../../Components/constants/common";
import {
  LIST_SHIPPING_PROVIDER,
  LIST_SHIPPING_PROVIDER_SUCCESS,
  LIST_SHIPPING_PROVIDER_FAIL,
  DELETE_SHIPPING_PROVIDER,
  DELETE_SHIPPING_PROVIDER_SUCCESS,
  DELETE_SHIPPING_PROVIDER_FAIL,
  ADD_SHIPPING_PROVIDER_SUCCESS,
  ADD_SHIPPING_PROVIDER_FAIL,
  UPDATE_SHIPPING_PROVIDER_SUCCESS,
  UPDATE_SHIPPING_PROVIDER_FAIL,
  ADD_NEW_SHIPPING_PROVIDER,
  UPDATE_SHIPPING_PROVIDER,
  GET_SHIPPING_PROVIDER,
  GET_SHIPPING_PROVIDER_SUCCESS,
  GET_SHIPPING_PROVIDER_FAIL,
} from "./actionType";

const INIT_STATE = {
  shippingProviders: [],
  shippingProvider: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const ShippingProviders = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_SHIPPING_PROVIDER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_SHIPPING_PROVIDER_SUCCESS:
      return {
        ...state,
        message: "List shipping provider success",
        loading: false,
        success: true,
        shippingProviders: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_SHIPPING_PROVIDER_FAIL:
      return {
        ...state,
        message: "List shipping provider failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_SHIPPING_PROVIDER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_SHIPPING_PROVIDER_SUCCESS:
      return {
        ...state,
        message: "Get shipping provider success",
        loading: false,
        success: true,
        shippingProvider: action.payload ?? null,
        action: Actions.EMPTY,
      };

    case GET_SHIPPING_PROVIDER_FAIL:
      return {
        ...state,
        message: "Get shipping provider failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_SHIPPING_PROVIDER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_SHIPPING_PROVIDER_SUCCESS:
      return {
        ...state,
        message: "Delete shipping provider success",
        loading: false,
        success: true,
        shippingProviders: state.shippingProviders.filter(
          (item) => item.id.toString() !== action.payload.id.toString(),
        ),
        action: Actions.EMPTY,
      };

    case DELETE_SHIPPING_PROVIDER_FAIL:
      return {
        ...state,
        message: "Delete shipping provider failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_NEW_SHIPPING_PROVIDER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_SHIPPING_PROVIDER_SUCCESS:
      return {
        ...state,
        message: "Add shipping provider success",
        success: true,
        loading: false,
        shippingProviders: [...state.shippingProviders, action.payload],
        action: Actions.EMPTY,
      };

    case ADD_SHIPPING_PROVIDER_FAIL:
      return {
        ...state,
        message: "Add shipping provider failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_SHIPPING_PROVIDER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.UPDATE,
      };
    case UPDATE_SHIPPING_PROVIDER_SUCCESS:
      return {
        ...state,
        message: "Update shipping provider success",
        loading: false,
        success: true,
        shippingProviders: state.shippingProviders.map((shippingProvider) =>
          shippingProvider.id.toString() === action.payload.id.toString()
            ? { ...shippingProvider, ...action.payload }
            : shippingProvider,
        ),
        action: Actions.EMPTY,
      };

    case UPDATE_SHIPPING_PROVIDER_FAIL:
      return {
        ...state,
        message: "Update shipping provider failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    default:
      return { ...state };
  }
};

export default ShippingProviders;
