import {
  LIST_STORE,
  LIST_STORE_SUCCESS,
  LIST_STORE_FAIL,
  DELETE_STORE,
  DELETE_STORE_SUCCESS,
  DELETE_STORE_FAIL,
  ADD_STORE_SUCCESS,
  ADD_STORE_FAIL,
  UPDATE_STORE_SUCCESS,
  UPDATE_STORE_FAIL,
  ADD_NEW_STORE,
  UPDATE_STORE,
  GET_STORE,
  GET_STORE_SUCCESS,
  GET_STORE_FAIL,

  ///////////////////
  LIST_APP_STORE,
  LIST_APP_STORE_SUCCESS,
  LIST_APP_STORE_FAIL,
  GET_APP_STORE,
  GET_APP_STORE_SUCCESS,
  GET_APP_STORE_FAIL,
  LIST_APP,
  LIST_APP_SUCCESS,
  LIST_APP_FAIL,
  DELETE_APP,
  DELETE_APP_SUCCESS,
  DELETE_APP_FAIL,
} from "./actionType";

const INIT_STATE = {
  stores: [],
  store: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  /////////////
  appStores: [],
  appStore: {},
  //////
  apps: [],
  loadingApp: false,
  limitApp: 0,
  pageApp: 0,
  totalApp: 0,
  loadingDelete: false,
};

const Stores = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_STORE:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
      };

    case LIST_STORE_SUCCESS:
      return {
        ...state,
        message: "List store success",
        loading: false,
        success: true,
        stores: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
      };

    case LIST_STORE_FAIL:
      return {
        ...state,
        message: "List store failed",
        loading: false,
        success: false,
        error: action.payload,
      };

    case GET_STORE:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
      };

    case GET_STORE_SUCCESS:
      return {
        ...state,
        message: "Get store success",
        loading: false,
        success: true,
        store: action.payload ?? null,
      };

    case GET_STORE_FAIL:
      return {
        ...state,
        message: "Get store failed",
        loading: false,
        success: false,
        error: action.payload,
      };

    case DELETE_STORE:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
      };

    case DELETE_STORE_SUCCESS:
      return {
        ...state,
        message: "Delete store success",
        loading: false,
        success: true,
        stores: state.stores.filter((item) => item.id.toString() !== action.payload.id.toString()),
      };

    case DELETE_STORE_FAIL:
      return {
        ...state,
        message: "Delete store failed",
        loading: false,
        success: false,
        error: action.payload,
      };

    case ADD_NEW_STORE:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
      };

    case ADD_STORE_SUCCESS:
      return {
        ...state,
        message: "Add store success",
        success: true,
        loading: false,
        stores: [...state.stores, action.payload],
      };

    case ADD_STORE_FAIL:
      return {
        ...state,
        message: "Add store failed",
        loading: false,
        success: false,
        error: action.payload,
      };

    case UPDATE_STORE:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
      };
    case UPDATE_STORE_SUCCESS:
      return {
        ...state,
        message: "Update store success",
        loading: false,
        success: true,
        stores: state.stores.map((store) =>
          store.id.toString() === action.payload.id.toString() ? { ...store, ...action.payload } : store,
        ),
      };

    case UPDATE_STORE_FAIL:
      return {
        ...state,
        message: "Update store failed",
        loading: false,
        success: false,
        error: action.payload,
      };

    /////////////

    case LIST_APP_STORE:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
      };

    case LIST_APP_STORE_SUCCESS:
      return {
        ...state,
        message: "List appStore success",
        loading: false,
        success: true,
        appStores: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
      };

    case LIST_APP_STORE_FAIL:
      return {
        ...state,
        message: "List appStore failed",
        loading: false,
        success: false,
        error: action.payload,
      };

    case GET_APP_STORE:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
      };

    case GET_APP_STORE_SUCCESS:
      return {
        ...state,
        message: "Get appStore success",
        loading: false,
        success: true,
        appStore: action.payload,
      };

    case GET_APP_STORE_FAIL:
      return {
        ...state,
        message: "Get appStore failed",
        loading: false,
        success: false,
        error: action.payload,
      };

    //////
    case LIST_APP:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loadingApp: true,
      };

    case LIST_APP_SUCCESS:
      return {
        ...state,
        message: "List app success",
        loadingApp: false,
        success: true,
        apps: action.payload.items ?? [],
        limitApp: action.payload.limit ?? 0,
        pageApp: action.payload.page ?? 0,
        totalApp: action.payload.total ?? 0,
      };

    case LIST_APP_FAIL:
      return {
        ...state,
        message: "List app failed",
        loadingApp: false,
        success: false,
        error: action.payload,
      };

    case DELETE_APP:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loadingDelete: true,
      };

    case DELETE_APP_SUCCESS:
      return {
        ...state,
        message: "Delete app success",
        loadingDelete: false,
        success: true,
      };

    case DELETE_APP_FAIL:
      return {
        ...state,
        message: "Delete app failed",
        loadingDelete: false,
        success: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default Stores;
