import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label } from "reactstrap";
//import images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { useNavigate } from "react-router-dom";
import useMemoizedSelector from "../Hooks/useMemoizedSelector";
import { useDispatch } from "react-redux";
import { updateLocationUser } from "../../store/actions";
import { useDebounce } from "../Hooks/UseDebounce";
import { locationsAPI } from "../../helpers/resource_helper";
import { toast } from "react-toastify";
import i18n, { resources } from "../../i18n";
import { filterByName } from "../../helpers/format_helper";
import LottieIcon from "./LottieIcon";

const ProfileDropdown = ({ t }) => {
  const userSession = JSON.parse(localStorage.getItem("auth_user"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { locationUser } = useMemoizedSelector((state) => ({
    locationUser: state.ShopInfo.locationUser,
  }));
  const { loadingVersion, version } = useMemoizedSelector((state) => ({
    loadingVersion: state.Version.loading,
    version: state.Version.version,
  }));

  const [firstRun, setFirstRun] = useState(true);
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);

  const [valueSearch, setValueSearch] = useState("");
  const debouncedInputValue = useDebounce(valueSearch, 1000);

  const [locations, setLocations] = useState([]);
  const [total, setTotal] = useState(0);

  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  const getLocations = async (query) => {
    // try {
    //   const res = await locationsAPI.list({ limit: 5, ...params });
    //   setLocations(res?.items || []);
    //   setTotal(res?.total || 0);
    // } catch (e) {
    //   console.error(String(e));
    // }
    const locations = version?.data?.list?.location || [];
    const filteredLocations = filterByName(locations, query);
    setLocations(filteredLocations);
  };

  useEffect(() => {
    setFirstRun(false);
  }, []);
  useEffect(() => {
    getLocations(debouncedInputValue);
  }, [version, debouncedInputValue]);

  useEffect(() => {
    const location = localStorage.getItem("location");
    if (!location) {
      if (locations[0]) {
        const newLocation = { id: location[0].id, name: location[0].name };
        dispatch(updateLocationUser(newLocation));
        localStorage.setItem("location", JSON.stringify(newLocation));
      }
    } else {
      dispatch(updateLocationUser(JSON.parse(location)));
    }
  }, [dispatch]);

  return (
    <React.Fragment>
      <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
        <DropdownToggle id={"page-header-user-dropdown"} tag="button" className="btn hstack gap-2" caret>
          <span className="d-flex align-items-center">
            {userSession?.UserAttributes?.["image"] != null ? (
              <img
                src={userSession.UserAttributes?.["image"]}
                alt={avatar1}
                className="rounded-circle header-profile-user"
              />
            ) : (
              <div className="header-profile-user">
                <div className="rounded-circle text-primary bg-light avatar-title text-uppercase border">
                  {userSession?.Username?.charAt(0)}
                </div>
              </div>
            )}
            <span className="text-start ms-xl-2" style={{ maxWidth: "150px" }}>
              <span className="d-none d-xl-inline-block ms-1 fw-semibold user-name-text text-truncate">
                {userSession?.Username}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted text-truncate">{t(locationUser?.name)}</span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {(version?.data?.list?.location || []).length >= 5 && (
            <div className="px-3 mb-1">
              <Input
                value={valueSearch}
                onChange={(e) => {
                  setValueSearch(e.target.value);
                }}
                placeholder={t("Search...")}
              />
              <p className="mt-1 fs-11 text-muted">Nhập tên để chọn những chi nhánh chưa được hiển thị bên dưới</p>
            </div>
          )}
          <DropdownItem
            onClick={() => {
              const data = { name: "All", id: "ALL" };
              dispatch(updateLocationUser(data));
              localStorage.setItem("location", JSON.stringify(data));
              window.location.reload();
            }}
          >
            <div className="form-check form-check-primary mb-0 hstack">
              <Input
                id={`location-user-all`}
                name="location-user"
                type="radio"
                className="mt-0"
                defaultChecked={locationUser.id === "ALL"}
              />
              <Label className="ms-1 mb-0" for={`location-user-all`}>
                {t("All")}
              </Label>
            </div>
          </DropdownItem>
          {locations.length > 0 ? (
            locations.map((location, index) => (
              <DropdownItem
                key={index}
                onClick={() => {
                  const newLocation = { id: location.id, name: location.name };
                  dispatch(updateLocationUser(newLocation));
                  localStorage.setItem("location", JSON.stringify(newLocation));
                  window.location.reload();
                }}
              >
                <div className="form-check form-check-primary mb-0 hstack">
                  <Input
                    id={`location-user-${index}`}
                    name="location-user"
                    type="radio"
                    className="mt-0"
                    defaultChecked={location.id === locationUser.id}
                  />
                  <Label className="ms-1 mb-0 text-truncate" for={`location-user-${index}`}>
                    {t(location.name)}
                  </Label>
                </div>
              </DropdownItem>
            ))
          ) : (
            <div className="px-3 text-center">
              <LottieIcon src="https://cdn.lordicon.com/msoeawqm.json" style={{ width: "42px", height: "42px" }} />
              <div>
                <p>{t("Sorry! No Result Found")}</p>
              </div>
            </div>
          )}
          <DropdownItem
            className="border-top"
            onClick={() => {
              navigate("/profile-settings");
            }}
          >
            <i className="ri-account-circle-fill text-muted fs-20 align-middle me-1"></i>
            <span className="align-middle text-dark">{t("Profile")}</span>
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              navigate("/logout");
            }}
          >
            <i className="ri-logout-box-r-line text-muted fs-20 align-middle me-1"></i>{" "}
            <span className="align-middle text-dark" data-key="t-logout">
              {t("Log out")}
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(ProfileDropdown);
