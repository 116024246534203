// Actions
export const LIST_LOCATION = "LIST_LOCATION";
export const LIST_LOCATION_SUCCESS = "LIST_LOCATION_SUCCESS";
export const LIST_LOCATION_FAIL = "LIST_LOCATION_FAIL";

export const GET_LOCATION = "GET_LOCATION";
export const GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS";
export const GET_LOCATION_FAIL = "GET_LOCATION_FAIL";

/**
 * Delete LOCATION
 */
export const DELETE_LOCATION = "DELETE_LOCATION";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS";
export const DELETE_LOCATION_FAIL = "DELETE_LOCATION_FAIL";

/**
 * Add LOCATION
 */
export const ADD_NEW_LOCATION = "ADD_NEW_LOCATION";
export const ADD_LOCATION_SUCCESS = "ADD_LOCATION_SUCCESS";
export const ADD_LOCATION_FAIL = "ADD_LOCATION_FAIL";

/**
 * Edit LOCATION
 */
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const UPDATE_LOCATION_FAIL = "UPDATE_LOCATION_FAIL";
