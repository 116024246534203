import React, { useEffect } from "react";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import { packagesAPI } from "../../../../helpers/resource_helper";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { PackageStatusOptions, PaymentStatusOptions } from "../../../../Components/constants/common";
import BadgeCustom from "../../../../Components/Common/BadgeCustom";
import withRouter from "../../../../Components/Common/withRouter";
import { Tooltip } from "antd";

const PackageList = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Package List")} | OptiWarehouse`;
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);

  const handleSetStatus = (id, status) => {
    setPackages((packages) => {
      const newPackages = packages.map((item) => {
        if (item.id === id)
          return {
            ...item,
            status: status,
          };
        return item;
      });
      return newPackages;
    });
  };

  const handlePackPackage = async (id) => {
    setLoading(true);
    try {
      await packagesAPI.createCustomPath(`${id}/pack`, {});
      handleSetStatus(id, "PACKING");
    } catch (error) {
      toast.error(error.toString());
    }
    setLoading(false);
  };

  const handleReadyPackage = async (id) => {
    setLoading(true);
    try {
      await packagesAPI.createCustomPath(`${id}/ready`, {});
      handleSetStatus(id, "READY");
    } catch (error) {
      toast.error(error.toString());
    }
    setLoading(false);
  };
  const handleShipPackage = async (id) => {
    setLoading(true);
    try {
      await packagesAPI.createCustomPath(`${id}/ship`, {});
      handleSetStatus(id, "SHIPPING");
    } catch (error) {
      toast.error(error.toString());
    }
    setLoading(false);
  };
  const handleDeliveredPackage = async (id) => {
    setLoading(true);
    try {
      await packagesAPI.createCustomPath(`${id}/delivered`, {});
      handleSetStatus(id, "DELIVERED");
    } catch (error) {
      toast.error(error.toString());
    }
    setLoading(false);
  };
  const handleReturnPackage = async (id) => {
    setLoading(true);
    try {
      await packagesAPI.createCustomPath(`${id}/return`, {});
      handleSetStatus(id, "RETURN");
    } catch (error) {
      toast.error(error.toString());
    }
    setLoading(false);
  };
  const handleDeliveredReturnPackage = async (id) => {
    setLoading(true);
    try {
      await packagesAPI.createCustomPath(`${id}/delivered_return`, {});
      handleSetStatus(id, "DELIVERED_RETURN");
    } catch (error) {
      toast.error(error.toString());
    }
    setLoading(false);
  };
  const handleCancelPackage = async (id) => {
    setLoading(true);
    try {
      await packagesAPI.put({ id: `${id}/cancel` });
      handleSetStatus(id, "CANCEL");
    } catch (error) {
      toast.error(error.toString());
    }
    setLoading(false);
  };

  const headers = ["package_number", "order_number", "status", "created_at", "updated_at", "action"];

  const getListPackage = async (dataPayload) => {
    try {
      setLoading(true);
      const res = await packagesAPI.list(dataPayload ? dataPayload : { limit: 20 });
      setPackages(res.items);
      setLimit(res.limit);
      setPage(res.page);
      setTotal(res.total);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getListPackage();
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="purchase-store-list">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("Package List")}</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-3 flex-wrap">
                      <ButtonTheme type="button" className="btn btn-light" icon="ri-file-text-line">
                        {t("Export")}
                      </ButtonTheme>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <TableContainerCustom
                  filterType={"packages"}
                  searchPlaceHolder="Search packages..."
                  onCallData={(dataPayload) => {
                    getListPackage(dataPayload);
                  }}
                  // tableView={true}
                  listFilter={[
                    {
                      id: "status",
                      type: "selectBox",
                      title: "Status",
                      dataOption: PackageStatusOptions,
                    },
                  ]}
                  headers={headers}
                  contents={packages}
                  limitPage={Number(limit)}
                  currentPage={Number(page) + 1}
                  totalItems={Number(total)}
                  loading={loading}
                  keyRow={"created_at"}
                  scrollHeight="50vh"
                  isSettings={true}
                  listHeader={[
                    {
                      title: t("Package details"),
                      key: "detail-variant",
                      children: [
                        {
                          title: t("Package Number"),
                          dataIndex: "package_number",
                          key: "package_number",
                          render: (data, row) => (
                            <Tooltip>
                              <Link to={`/packages/${row?.id}`} className="d-block text-truncate link-primary">
                                {data}
                              </Link>
                            </Tooltip>
                          ),
                        },
                        {
                          title: t("Order Number"),
                          dataIndex: "order_number",
                          key: "order_number",
                          render: (data, row) => (
                            <Link to={`/orders/${row.id}`} className="d-block text-truncate link-primary">
                              {data || "---"}
                            </Link>
                          ),
                        },
                        {
                          title: t("Status"),
                          dataIndex: "status",
                          key: "status",
                          render: (value, row, index) => <>{value ? <BadgeCustom status={value} /> : "---"}</>,
                        },
                        {
                          title: t("Created At"),
                          dataIndex: "created_at",
                          defaultSortOrder: "desc",
                          sorter: true,
                          key: "created_at",
                        },
                        {
                          title: t("Updated at"),
                          dataIndex: "updated_at",
                          sorter: true,
                          key: "updated_at",
                        },
                        {
                          title: t("Action"),
                          key: "action",
                          dataIndex: "action",
                          render: (_, data, index) => {
                            return (
                              <div>
                                <UncontrolledDropdown>
                                  <DropdownToggle href="#" className="btn btn-soft-primary btn-sm" tag="button">
                                    <i className="ri-more-fill" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end" container="body">
                                    {data.status === "PENDING" && (
                                      <>
                                        <DropdownItem onClick={() => handlePackPackage(data.id)}>
                                          {t("Packing")}
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() => {
                                            handleCancelPackage(data.id);
                                          }}
                                        >
                                          Cancel
                                        </DropdownItem>
                                      </>
                                    )}
                                    {data.status === "PACKING" && (
                                      <DropdownItem onClick={() => handleReadyPackage(data.id)}>
                                        {t("Ready")}
                                      </DropdownItem>
                                    )}
                                    {data.status === "READY" && (
                                      <DropdownItem onClick={() => handleShipPackage(data.id)}>
                                        {t("Ship")}
                                      </DropdownItem>
                                    )}
                                    {data.status === "SHIPPING" && (
                                      <DropdownItem onClick={() => handleDeliveredPackage(data.id)}>
                                        {t("Delivered")}
                                      </DropdownItem>
                                    )}
                                    {data.status === "DELIVERED" && (
                                      <>
                                        <DropdownItem onClick={() => handleReturnPackage(data.id)}>
                                          {t("Return")}
                                        </DropdownItem>
                                        <DropdownItem onClick={() => handleDeliveredReturnPackage(data.id)}>
                                          {t("Delivered return")}
                                        </DropdownItem>
                                      </>
                                    )}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            );
                          },
                        },
                      ],
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(PackageList);
