export const SALE_CHANNEL = {
  _list: "/sale-channels",
  //   get: "/products/id",
  //   add: "/products/new",
  //   update: "/products/id/edit",
  //   delete: true,
};

export const STORE = {
  _list: "/stores",
  update: "/stores/settings/:id",
};

const ADD_STORE = "store.add";
const UPDATE_STORE = "store.update";
const DELETE_STORE = "store.delete";
const GET_STORE = "store.get";
