import { useEffect, useMemo, useState } from "react";
import { Col, Input, Label, Nav, NavItem, NavLink, Row, Table } from "reactstrap";
import classnames from "classnames";

import { deepCopy } from "../../../../../../helpers/format_helper";

import ButtonTheme from "../../../../../../Components/Common/ButtonTheme";
import { useTranslation } from "react-i18next";
import InputTheme from "../../../../../../Components/Common/InputTheme";

const PricesTable = ({ prices, priceGroups, onChangePrice, sku, onApplyPrice = () => {} }) => {
  const { t } = useTranslation();
  const [activeTabPrice, setActiveTabPrice] = useState(1);
  const toggleTabPrice = (tab) => {
    if (activeTabPrice !== tab) {
      setActiveTabPrice(tab);
    }
  };

  const [priceAll, setPriceAll] = useState("");

  const handleApplyPrice = () => {
    onApplyPrice({ activeTabPrice, priceAll });
  };

  const handleChangePrice = (value, priceGroup) => {
    if (value !== "") {
      let newData = { price: value, price_group: { name: priceGroup.name, id: priceGroup.id } };
      let newPrices = deepCopy(prices);
      let priceIndex = newPrices.findIndex((price) => price?.price_group?.id === priceGroup.id);
      if (priceIndex === -1) {
        newPrices.push(newData);
      } else {
        newPrices[priceIndex] = newData;
      }
      onChangePrice(newPrices);
    }
  };

  const renderPriceGroup = () => {
    if (priceGroups.length === 0) {
      return [];
    }
    let priceGroup = priceGroups[activeTabPrice - 1];
    return [
      {
        Header: t(priceGroup.name),
        Cell: <PriceInput item={priceGroup} handleChangePrice={handleChangePrice} row={prices} />,
      },
    ];
  };

  const columns = useMemo(
    () => [
      {
        Header: t("Sku"),
        Cell: <div>{sku || "---"}</div>,
      },
      ...renderPriceGroup(),
    ],
    [sku, prices, activeTabPrice],
  );

  return (
    <Row>
      <div className="mb-3">
        <Nav tabs className="nav-tabs mb-3">
          {priceGroups.map((item, key) => (
            <NavItem key={key}>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: activeTabPrice === key + 1 })}
                onClick={() => {
                  toggleTabPrice(key + 1);
                }}
              >
                {t(item.name)}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <Row className="mb-3">
          <Col md="auto" className="align-self-end">
            <ButtonTheme type="button" className="btn btn-primary" onClick={handleApplyPrice}>
              {t("Apply for all")}
            </ButtonTheme>
          </Col>
          <Col>
            <Label className="form-label" for={"price-all-input"}>
              {t("Price")}
            </Label>
            <InputTheme
              id="price-all-input"
              className="form-control text-end"
              placeholder={t("Price")}
              value={priceAll}
              onChange={(value) => setPriceAll(value)}
              format="price"
              isForm={false}
            />
          </Col>
        </Row>
      </div>
      <div className="table-responsive">
        <Table className="mb-0 align-middle table-bordered rounded-1">
          <thead className="table-light text-muted text-center">
            <tr>
              {columns.map((item) => (
                <th key={item.Header}>{item.Header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {columns.map((item) => {
                return <td key={item.Header}>{item.Cell}</td>;
              })}
            </tr>
          </tbody>
        </Table>
      </div>
    </Row>
  );
};

const PriceInput = ({ item, handleChangePrice, row }) => {
  const { t } = useTranslation();
  let data = row.find((value) => value?.price_group.id === item.id)?.price ?? "";
  const [priceValue, setPriceValue] = useState(data);
  useEffect(() => {
    setPriceValue(row.find((value) => value?.price_group.id === item.id)?.price ?? "");
  }, [data]);
  return (
    <InputTheme
      className="form-control text-end"
      placeholder={t("Price")}
      format="price"
      isForm={false}
      id={item.id}
      value={priceValue}
      onChange={(data) => setPriceValue(data)}
      onBlur={(e) => handleChangePrice(priceValue, item)}
    />
  );
};

export default PricesTable;
