export const CUSTOMER = {
  dashboard: "/customer-dashboard",
  _list: "/customers",
  //   get: ,
  //   add: ,
  //   update: ,
  //   delete: ,
};

export const CUSTOMER_GROUP = {
  _list: "/customer-groups",
  //   get: ,
  //   add: ,
  //   update: ,
  //   delete: ,
};

export const REDEEM = {
  _list: "/redeem",
  //   get: ,
  //   add: ,
  //   update: ,
  //   delete: ,
};

export const REWARD = {
  _list: "/reward",
  //   get: ,
  //   add: ,
  //   update: ,
  //   delete: ,
};
