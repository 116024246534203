import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useMemoizedSelector from "../../../../../../Components/Hooks/useMemoizedSelector";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { DiscountAmountType, DiscountTypes } from "../../../../../../Components/constants/common";
import { Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty } from "lodash";

import {
  convertValidDate,
  convertValidTime,
  deepCopy,
  formatVNDCurrency,
} from "../../../../../../helpers/format_helper";
import { toast } from "react-toastify";
import { getDiscount } from "../../../../../../store/actions";
import AddFeesModal from "../../../../../../Components/Common/AddFeesModal";
import { Card, CardBody, CardHeader, Col, Input, Label, Row, Tooltip } from "reactstrap";
import ProductsSearchDropDown from "../../../../../../Components/Common/ProductsSearchDropDown";
import ReactSelect from "react-select";
import OrderLineItemsTable from "../../../../../../Components/Common/OrderLineItemsTable";
import ButtonTheme from "../../../../../../Components/Common/ButtonTheme";
import Loader from "../../../../../../Components/Common/Loader";
import ImageCustom from "../../../../../../Components/Common/ImageCustom";
import TagsInputCustom from "../../../../../../Components/Common/TagsInputCustom";
import DiscountDropDown from "../../../../../../Components/Common/DiscountDropDown";
import { checkVaidlVoucherCode } from "../../../../../../helpers/service_helper";
import { Popover } from "antd";
import InputTheme from "../../../../../../Components/Common/InputTheme";
import PromotionModal from "../../../Components/PromotionModal";
import ScrollToError from "../../../../../../Components/Common/ScrollToError";

const ExchangeOrderCard = ({ id, selectedLocation, order, returnOrder, onChangeExchangedOrder }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { locations, locationsLoading, locationsSuccess, locationsError } = useMemoizedSelector((state) => ({
    locations: state.Locations.locations,
    locationsLoading: state.Locations.loading,
    locationsSuccess: state.Locations.success,
    locationsError: state.Locations.error,
  }));

  const { paymentMethods, paymentMethodsLoading, paymentMethodsSuccess, paymentMethodsError } = useMemoizedSelector(
    (state) => ({
      paymentMethods: state.PaymentMethods.paymentMethods,
      paymentMethodsLoading: state.PaymentMethods.loading,
      paymentMethodsSuccess: state.PaymentMethods.success,
      paymentMethodsError: state.PaymentMethods.error,
    }),
  );

  const { priceGroups, priceGroupsLoading, priceGroupsSuccess, priceGroupsError } = useMemoizedSelector((state) => ({
    priceGroups: state.PriceGroups.priceGroups,
    priceGroupsLoading: state.PriceGroups.loading,
    priceGroupsSuccess: state.PriceGroups.success,
    priceGroupsError: state.PriceGroups.error,
  }));

  const { discount, discountsLoading, discountsSuccess, discountsError } = useMemoizedSelector((state) => ({
    discount: state.Discounts.discount,
    discountsLoading: state.Discounts.loading,
    discountsSuccess: state.Discounts.success,
    discountsError: state.Discounts.error,
  }));

  const [selectedPriceGroup, setSelectedPriceGroup] = useState(null);

  const [discountType, setDiscountType] = useState("value");
  const [discountPercent, setDiscountPercent] = useState(0);

  const [promotionModal, setPromotionModal] = useState(false);
  const [showDropDownProductMenu, setShowDropDownProductMenu] = useState(false);
  const [feesModal, setFeesModal] = useState(false);
  const [dataVoucher, setDataVoucher] = useState({});

  const [discountDetails, setDiscountDetails] = useState([]);

  const refToggleDiscount = useRef(null);

  const ViewConditionsCase = [DiscountTypes.BY_PRODUCT, DiscountTypes.BY_QUANTITY];
  const ChooseGiftCase = [DiscountTypes.GIFT_BY_ORDER_TOTAL, DiscountTypes.GIFT_BY_PURCHASE_PRODUCT];
  const ChooseDiscountCase = [DiscountTypes.BY_PURCHASE_PRODUCT, DiscountTypes.PRODUCT_BY_ORDER_TOTAL];

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      customer: returnOrder?.customer || null,
      billing_address: returnOrder?.billing_address || null,
      // location: order?.location || null,
      shipping_address: returnOrder?.shipping_address || null,
      order_line_items: order?.order_line_items || [],
      note: order?.note || "",
      tags: order?.tags || "",
      total: order?.total || 0,
      sub_total: order?.sub_total || 0,
      discount: order?.discount || 0,
      payments: order?.payments || null,
      source: returnOrder?.source || null,
      location: returnOrder?.location || null,
      staff: returnOrder?.staff || null,
      other_fees: order?.other_fees || null,
      shipping_fee: order?.shipping_fee || 0,
      tax: order?.tax || 0,
      discount_id: order?.discount_id || null,
    },
    onSubmit: (values) => {
      let payload = deepCopy(values);
      payload.order_line_items = payload.order_line_items
        .filter((item) => item.quantity > 0)
        .map((item) => {
          const { available, ...res } = item;
          return res;
        });
      if (!payload.customer) {
        toast.error(t("Required customer"), { autoClose: 3000 });
        return;
      }
      if (!selectedPriceGroup) {
        toast.error(t("Required price group"), { autoClose: 3000 });
        return;
      }
      if (!selectedLocation) {
        toast.error(t("Required branch"), { autoClose: 3000 });
        return;
      }
      payload["location"] = selectedLocation;
      if (payload.order_line_items.length === 0) {
        toast.error(t("This order has no items"), { autoClose: 3000 });
        return;
      }
      if (payload.discount_id) {
        payload.discount_details = discountDetails.map((item) => {
          const { available, ...res } = item;
          return res;
        });
      }
      // if (!id) {
      //   dispatch(
      //     addNewOrder({ ...payload, ...(Object.keys(dataVoucher).length > 0 && { ...dataVoucher }) }, (data) => {
      //       navigate(`/orders/${data?.id}`);
      //     }),
      //   );
      // } else {
      //   dispatch(updateOrder({ ...order, ...payload }));
      // }
    },
  });

  const handleChangePriceGroup = (data) => {
    setSelectedPriceGroup(data);
    validation.setFieldValue("order_line_items", []);
  };

  useEffect(() => {
    setDataVoucher({
      sub_voucher_total: order?.sub_voucher_total,
    });
  }, [order?.sub_voucher_total]);

  const handleChangeDiscountDetails = (data) => {
    setDiscountDetails(data);
  };

  const handleChangeDiscountId = (data) => {
    validation.setFieldValue("discount_id", data);
  };

  const amountSubTotal = useMemo(() => {
    if (id) {
      return validation.values.order_line_items.reduce((total, item) => total + item.quantity * item.sale_price, 0);
    }
    const defaultValue = validation.values.order_line_items.reduce((total, item, index) => {
      if (
        validation.values.discount_id &&
        discount &&
        discountDetails.length > 0 &&
        [DiscountTypes.BY_PRODUCT, DiscountTypes.BY_QUANTITY].includes(discount.type)
      ) {
        const itemInPromotion = discountDetails.find((subItem) => subItem.sku === item.sku);
        if (itemInPromotion) {
          return total + itemInPromotion.quantity * itemInPromotion.sale_price;
        }
      }
      return total + item.quantity * item.sale_price;
    }, 0);
    if (
      validation.values.discount_id &&
      discount &&
      discountDetails.length > 0 &&
      [...ChooseDiscountCase, ...ChooseGiftCase].includes(discount.type)
    ) {
      const extraProductValue = discountDetails.reduce(
        (total, item, index) => total + item.quantity * item.sale_price,
        0,
      );
      return defaultValue + extraProductValue;
    }
    return defaultValue;
  }, [validation.values.order_line_items, validation.values.discount_id, discount, discountDetails, id]);

  const orderQuantity = useMemo(() => {
    const defaultValue = validation.values.order_line_items.reduce((total, item, index) => {
      return total + +item.quantity;
    }, 0);
    if (!id) {
      const newQuantity = discountDetails.reduce((total, item, index) => {
        return total + +item.quantity;
      }, 0);
      return defaultValue + newQuantity;
    }
    return defaultValue;
  }, [validation.values.order_line_items, validation.values.discount_id, discount, discountDetails]);

  const amountShippingFee = useMemo(() => validation.values.shipping_fee, [validation.values.shipping_fee]);
  const amountFeeTotal = useMemo(
    () =>
      validation.values.other_fees ? validation.values.other_fees.reduce((total, item) => total + +item.amount, 0) : 0,
    [validation.values.other_fees],
  );
  const amountDiscount = useMemo(() => {
    let newAmountDiscount = validation.values.discount;
    if (!validation.values.discount_id) {
      return newAmountDiscount;
    }
    if (discount?.type === DiscountTypes.BY_ORDER_TOTAL) {
      const condition = discount?.discount_by_order_total.find((item) => {
        if (item?.amount_to) {
          return item.amount_to >= amountSubTotal && amountSubTotal >= item.amount_from;
        } else {
          return amountSubTotal >= item.amount_from;
        }
      });
      if (condition) {
        condition.type === DiscountAmountType.VALUE
          ? (newAmountDiscount = condition.amount)
          : (newAmountDiscount = (condition.rate * amountSubTotal) / 100);
        return newAmountDiscount;
      }
    }
    return newAmountDiscount;
  }, [amountSubTotal, validation.values.discount, validation.values.discount_id, discount]);

  const amountTotal = useMemo(
    () =>
      amountSubTotal +
      +amountShippingFee +
      +validation.values.tax -
      amountFeeTotal -
      amountDiscount -
      (dataVoucher?.sub_voucher_total || 0),
    [
      amountSubTotal,
      amountShippingFee,
      validation.values.tax,
      amountFeeTotal,
      amountDiscount,
      dataVoucher?.sub_voucher_total,
    ],
  );

  const amountPayments = useMemo(
    () =>
      validation.values.payments ? validation.values.payments.reduce((total, item) => total + +item.amount, 0) : 0,
    [validation.values.payments],
  );
  const amountStillDue = useMemo(() => amountTotal - amountPayments, [amountTotal, amountPayments]);
  const addProductToCart = async (selectedProduct) => {
    let newData = deepCopy(validation.values);
    if (selectedProduct) {
      const available = selectedProduct?.inventories?.[0].available || 0;
      if (!selectedProduct?.custom && available <= 0) {
        toast.error(t("This product is currently out of stock"));
        return;
      }
      let cartItemIndex = newData.order_line_items.findIndex((item) => item.sku === selectedProduct.sku);
      if (cartItemIndex > -1) {
        newData.order_line_items[cartItemIndex].quantity = Number(newData.order_line_items[cartItemIndex].quantity) + 1;
        newData.total = Number(newData.total) + Number(newData.order_line_items[cartItemIndex].sale_price);
        discountType === "percent" && (newData.discount = (newData.total * discountPercent) / 100);
        newData.sub_total = newData.total - newData.discount;
      } else {
        const selectedProductPriceGroupIndex = selectedProduct.prices.findIndex(
          (item) => item.price_group.id === selectedPriceGroup?.id,
        );
        const selectedProductPrice =
          selectedProductPriceGroupIndex > -1
            ? selectedProduct?.prices?.[selectedProductPriceGroupIndex]?.price
            : selectedProduct?.prices?.[0]?.price;
        const newItem = {
          unit_price: selectedProductPrice || 0,
          sale_price: selectedProductPrice || 0,
          sku: selectedProduct?.sku || "",
          name: selectedProduct?.name || "",
          variant_name: selectedProduct?.name || "",
          location: selectedLocation,
          image_url: selectedProduct.images?.[0]?.url || "",
          quantity: 1,
          product_id: selectedProduct.product_id || null,
          variant_id: selectedProduct.id || null,
          category: selectedProduct?.category
            ? { id: selectedProduct?.category?.id, name: selectedProduct?.category?.name }
            : null,
          brand: selectedProduct?.brand ? { id: selectedProduct?.brand?.id, name: selectedProduct?.brand?.name } : null,
          available,
        };
        newData.total += Number(newItem.sale_price);
        discountType === "percent" && (newData.discount = (newData.total * discountPercent) / 100);
        newData.sub_total = newData.total - newData.discount;
        newData.order_line_items.unshift(newItem);
      }
    } else {
      const newItem = {
        unit_price: 0,
        sale_price: 0,
        sku: crypto.randomUUID(),
        name: "",
        variant_name: "",
        image_url: null,
        location: selectedLocation,
        quantity: 1,
        custom: true,
      };
      newData.total += Number(newItem.sale_price);
      discountType === "percent" && (newData.discount = (newData.total * discountPercent) / 100);
      newData.sub_total = newData.total - newData.discount;
      newData.order_line_items.unshift(newItem);
    }
    validation.setValues(newData);
  };
  const handleChangeQuantity = (number, dataIndex) => {
    let newData = deepCopy(validation.values);
    const selectedProduct = newData?.order_line_items?.[dataIndex];
    const available = selectedProduct?.available || 0;
    if (!selectedProduct?.custom && available < number) {
      toast.error(t("This product is currently not enough stock"));
    }
    let orderItemIndex = newData.order_line_items.findIndex((item, index) => index === dataIndex);
    if (orderItemIndex > -1) {
      newData.order_line_items[orderItemIndex].quantity =
        !selectedProduct?.custom && available < number ? available : number;
      newData.total = newData.order_line_items.reduce((total, item) => total + item.sale_price * item.quantity, 0);
      discountType === "percent" && (newData.discount = (newData.total * discountPercent) / 100);
      newData.sub_total = newData.total - newData.discount;
    }
    validation.setValues(newData);
  };
  const removeProductFromCart = (selectedProductIndex) => {
    let newData = deepCopy(validation.values);
    newData.order_line_items = newData.order_line_items.filter((item, i) => i !== selectedProductIndex);
    newData.total = newData.order_line_items.reduce((total, item) => total + item.sale_price * item.quantity, 0);
    discountType === "percent" && (newData.discount = (newData.total * discountPercent) / 100);
    newData.sub_total = newData.total - newData.discount;
    validation.setValues(newData);
  };

  const handleChangeOrderLineItem = (index, data) => {
    let new_order_line_items = deepCopy(validation.values.order_line_items);
    new_order_line_items[index] = data;
    validation.setFieldValue("order_line_items", new_order_line_items);
  };

  const handleChangeFees = (data) => {
    let newData = deepCopy(validation.values);
    newData.other_fees = data;
    let totalFees = newData.other_fees ? newData.other_fees.reduce((total, item) => total + item.amount, 0) : 0;
    let totalSalePrice = newData.order_line_items.reduce((total, item) => total + item.sale_price * item.quantity, 0);
    newData.total = totalSalePrice + totalFees;
    discountType === "percent" && (newData.discount = (newData.total * discountPercent) / 100);
    newData.sub_total = newData.total - newData.discount;
    validation.setValues(newData);
  };

  const toggleFeesModal = () => {
    setFeesModal(false);
  };

  const handleChangeDiscountPrice = (data) => {
    validation.setFieldValue("discount", data);
  };
  const handleChangeDiscountPercent = (data) => {
    setDiscountPercent(data);
  };
  const handleChangeDiscountType = (data) => {
    setDiscountType(data);
  };

  const toggleOffDropDownProductMenu = () => {
    setShowDropDownProductMenu(false);
  };
  const toggleOnDropDownProductMenu = () => {
    setShowDropDownProductMenu(true);
  };

  const onChangeTagInputs = (e) => {
    let newTags = e.map((item) => item.trim());
    validation.setFieldValue("tags", newTags.join());
  };

  const handleAddPromotion = () => {
    if (validation.values.order_line_items.length === 0) {
      toast.error(t("At least 1 product to add promotion"));
      return;
    }
    setPromotionModal(true);
  };
  const handleRemovePromotion = () => {
    validation.setFieldValue("discount_id", null);
    handleChangeDiscountDetails([]);
  };
  const [dataQr, setDataQr] = useState(false);

  useEffect(() => {
    if (validation.values.discount_id) {
      dispatch(getDiscount(validation.values.discount_id));
    }
  }, [validation.values.discount_id]);

  useEffect(() => {
    if (!id) {
      validation.setFieldValue("discount_id", null);
      handleChangeDiscountDetails([]);
    }
  }, [validation.values.order_line_items]);

  useEffect(() => {
    if (!selectedPriceGroup) {
      const retailPrice = priceGroups.find((item) => item.name === "Giá bán lẻ");
      const newData = {
        label: retailPrice?.name || priceGroups?.[0]?.name || null,
        value: retailPrice?.id || priceGroups?.[0]?.id || null,
      };
      setSelectedPriceGroup(newData);
    }
  }, [priceGroupsLoading]);

  useEffect(() => {
    if (!id) {
      setDataVoucher({});
    }
  }, [validation.values.order_line_items, amountDiscount]);

  useEffect(() => {
    const newData = validation.values.order_line_items.length > 0 ? deepCopy(validation.values) : null;
    onChangeExchangedOrder(newData);
  }, [validation.values]);

  return (
    <Fragment>
      {feesModal && (
        <AddFeesModal
          toggle={toggleFeesModal}
          isOpen={feesModal}
          handleChangeFees={handleChangeFees}
          fees={validation.values.other_fees}
        />
      )}
      <PromotionModal
        isOpen={promotionModal}
        toggle={() => {
          setPromotionModal(false);
        }}
        selectedDiscountId={validation.values.discount_id}
        onChangeDiscountId={handleChangeDiscountId}
        order={validation.values}
        discountDetails={discountDetails}
        onChangeDiscountDetails={handleChangeDiscountDetails}
        selectedLocation={selectedLocation}
        selectedPriceGroup={selectedPriceGroup}
      />
      <Card>
        <CardHeader>
          <div className="d-flex align-items-center">
            <h5 className="card-title flex-grow-1 mb-0">{t("Exchange order")}</h5>
          </div>
        </CardHeader>
        <CardBody>
          <div className="hstack gap-3">
            <div className="flex-grow-1">
              <ProductsSearchDropDown
                placeholder="Search variant by name, sku, barcode,..."
                onClickItem={addProductToCart}
                isOpen={showDropDownProductMenu}
                toggleOff={toggleOffDropDownProductMenu}
                toggleOn={toggleOnDropDownProductMenu}
              />
            </div>
            <div className="flex-shrink-0" style={{ zIndex: "1001" }}>
              <ReactSelect
                id="price-group-options"
                classNamePrefix="select"
                value={selectedPriceGroup}
                onChange={(value) => {
                  handleChangePriceGroup(value);
                }}
                options={priceGroups.map((item) => {
                  return { label: item.name, value: item.id };
                })}
              />
            </div>
          </div>
          {validation.values.order_line_items.length > 0 && (
            <div className="my-3 w-100">
              <CardBody>
                <OrderLineItemsTable
                  isEdit={id === "new"}
                  discount_id={validation.values.discount_id}
                  products={validation.values.order_line_items || []}
                  divClass="table-card table-responsive"
                  tableClass="table table-nowrap align-middle mb-0"
                  theadClass="table-light text-muted"
                  trClass=""
                  discount={discount}
                  removeProductFromCart={removeProductFromCart}
                  onChangeQuantity={handleChangeQuantity}
                  discount_details={discountDetails}
                  onChangeOrderLineItem={handleChangeOrderLineItem}
                />
                <Row className="mt-3 border-top border-top-dashed">
                  <Col>
                    <ButtonTheme
                      className="btn btn-ghost-primary"
                      onClick={() => {
                        addProductToCart();
                      }}
                    >
                      <i className="ri-add-circle-line align-bottom me-2" />
                      {t("Add service (F9)")}
                    </ButtonTheme>
                  </Col>
                  {validation.values.discount_id ? (
                    discountsLoading ? (
                      <Loader />
                    ) : (
                      <Col className="py-3">
                        <h5>{t("Promotion details")}</h5>
                        <div className="hstack">
                          <div className="flex-shrink-0 p-1">
                            <ImageCustom image={discount?.image?.url} name={discount.name} avatarSize={"avatar-md"} />
                          </div>
                          <div className="flex-grow-1 ms-3 d-flex flex-column justify-content-center">
                            <h5 className="fs-15">
                              <Tooltip title={t(discount?.name)}>{t(discount?.name)}</Tooltip>
                            </h5>
                            <p className="text-muted fw-medium mb-1 text-truncate-two-lines">
                              {t("Description")}: {discount.description ? t(discount.description) : "---"}
                            </p>
                            <p className="text-muted fw-medium mb-1">
                              {t("Start at")}: {convertValidDate(discount.start_at)},{" "}
                              <small className="text-muted"> {convertValidTime(discount.start_at)}</small>
                            </p>
                            <p className="text-muted fw-medium mb-1">
                              {t("Expire at")}:{" "}
                              {discount.expire_at ? (
                                <>
                                  {convertValidDate(discount.expire_at)},{" "}
                                  <small className="text-muted"> {convertValidTime(discount.expire_at)}</small>
                                </>
                              ) : (
                                t("Limitless")
                              )}
                            </p>
                          </div>
                          {!id && (
                            <ButtonTheme className="btn btn-ghost-danger" onClick={handleRemovePromotion}>
                              {t("Remove promotion")}
                            </ButtonTheme>
                          )}
                        </div>
                      </Col>
                    )
                  ) : (
                    !id && (
                      <Col className="text-end">
                        <ButtonTheme className="btn btn-ghost-primary" onClick={handleAddPromotion}>
                          <i className="ri-gift-2-fill align-bottom me-2" />
                          {t("Add promotion")}
                        </ButtonTheme>
                      </Col>
                    )
                  )}
                </Row>
                <Row className="border-top border-top-dashed">
                  <Col lg={6}>
                    <div className="my-3">
                      <Label>{t("Note")}</Label>
                      <Input
                        type="text"
                        className="form-control"
                        name="note"
                        onChange={validation.handleChange}
                        value={validation.values.note}
                        placeholder={t("Enter note")}
                      />
                    </div>
                    <div className="mb-3">
                      <Label>{t("Tags")}</Label>
                      <TagsInputCustom
                        value={isEmpty(validation.values.tags) ? [] : validation.values.tags.split(",")}
                        onChangeTagInputs={onChangeTagInputs}
                        name="tags"
                        placeHolder={t("Enter tags")}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <table className="table table-borderless mb-0">
                      <tbody>
                        <tr>
                          <td>{`${t("Subtotal")} (${orderQuantity} ${t(
                            `product${orderQuantity > 1 ? "s" : ""}`,
                          )})`}</td>
                          <td className="text-end">{formatVNDCurrency(amountSubTotal)}</td>
                        </tr>
                        <tr>
                          <td>
                            <DiscountDropDown
                              refToggleDiscount={refToggleDiscount}
                              discount={discount}
                              amountDiscount={amountDiscount}
                              values={validation.values}
                              discountType={discountType}
                              discountPercent={discountPercent}
                              discountPrice={validation.values.discount}
                              onChangeDiscountType={handleChangeDiscountType}
                              onChangeDiscountPercent={handleChangeDiscountPercent}
                              onChangeDiscountPrice={handleChangeDiscountPrice}
                            />
                          </td>
                          <td className="text-end">{formatVNDCurrency(amountDiscount)}</td>
                        </tr>
                        <tr>
                          <td>
                            <PopoverVoucherCode
                              order={{
                                total_order: amountSubTotal,
                                order_line_items: validation.values.order_line_items,
                              }}
                              onAddVoucherToOrder={(data) => {
                                if (
                                  !data.apply_with_other_promotion &&
                                  (Number(amountDiscount) > 0 ||
                                    validation.values.order_line_items.every((order) => Number(order.discount) > 0))
                                ) {
                                  setDataVoucher({
                                    voucher_id: data.voucher_id,
                                    voucher_code: data.voucher_code,
                                    sub_voucher_total: 0,
                                  });
                                } else {
                                  setDataVoucher({
                                    voucher_id: data.voucher_id,
                                    voucher_code: data.voucher_code,
                                    sub_voucher_total: data.sub_voucher_total,
                                  });
                                }
                              }}
                            />

                            <>
                              {dataVoucher?.sub_voucher_total === 0 && dataVoucher?.voucher_code && (
                                <Tooltip
                                  title={t(
                                    "The applied code cannot be used to calculate customer discounts, point discounts, self-entered discounts for orders and promotional programs.",
                                  )}
                                >
                                  <i className="ms-2 ri-information-line text-danger align-bottom cursor-pointer"></i>
                                </Tooltip>
                              )}
                            </>
                          </td>
                          <td className="text-end">{formatVNDCurrency(dataVoucher?.sub_voucher_total)}</td>
                        </tr>
                        {id && (
                          <Fragment>
                            <tr>
                              <td>{t("Shipping fee")}</td>
                              <td className="text-end">
                                {order?.shipping_fee ? formatVNDCurrency(order?.shipping_fee) : "---"}
                              </td>
                            </tr>
                            <tr className="fw-semibold">
                              <td>{t("Other fees")}</td>
                              <td className="text-end">{formatVNDCurrency(amountFeeTotal)}</td>
                            </tr>
                            {validation.values.other_fees &&
                              validation.values.other_fees.map((item, key) => (
                                <tr key={key}>
                                  <td>
                                    <span className="ms-4">
                                      {key + 1}. {t(item.name)}
                                    </span>
                                  </td>
                                  <td className="text-end">{formatVNDCurrency(item.amount)}</td>
                                </tr>
                              ))}
                          </Fragment>
                        )}
                        <tr>
                          <td>
                            {t("Tax")}
                            <i className="ms-2 ri-information-line text-primary align-bottom"></i>
                          </td>
                          <td className="text-end">{formatVNDCurrency(validation.values?.tax || 0)}</td>
                        </tr>
                        <tr>
                          <td>{t("Amount to pay")}</td>
                          <td className="text-end">{formatVNDCurrency(amountTotal)}</td>
                        </tr>
                        <tr className="border-top border-top-dashed">
                          <th scope="row">{t("Amount still due")}</th>
                          <th className="text-end">{formatVNDCurrency(amountStillDue)}</th>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </CardBody>
            </div>
          )}
        </CardBody>
      </Card>
    </Fragment>
  );
};

const PopoverVoucherCode = ({ order, onAddVoucherToOrder }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const validation = {
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      voucher: "",
    },
    validationSchema: Yup.object({
      voucher: Yup.string().required(t("Please enter voucher code!")),
    }),
    onSubmit: async (values, actions) => {
      try {
        setLoading(true);
        const res = await checkVaidlVoucherCode(values.voucher, order);
        onAddVoucherToOrder({
          sub_voucher_total: res.total_apply_voucher,
          voucher_id: res.voucher_id,
          voucher_code: res.voucher_id,
          apply_with_other_promotion: res.apply_with_other_promotion,
        });
        actions.resetForm();
        setOpen(false);
        setLoading(false);
      } catch (error) {
        toast.error(error.toString());
        setLoading(false);
      }
    },
  };

  return (
    <Popover
      content={
        <Formik
          enableReinitialize={true}
          initialValues={validation.initialValues}
          validationSchema={validation.validationSchema}
          onSubmit={validation.onSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <ScrollToError />
              <div className="d-flex align-items-baseline">
                <InputTheme name={"voucher"} placeholder={t("Enter code")} />
                <ButtonTheme type="submit" className="btn btn-primary mx-2" loading={loading}>
                  {t("Apply")}
                </ButtonTheme>
              </div>
            </Form>
          )}
        </Formik>
      }
      title=""
      trigger="click"
      open={open}
      onOpenChange={() => {
        setOpen(!open);
      }}
    >
      <span className="btn text-primary p-0">{t("Voucher code")}</span>
    </Popover>
  );
};

export default ExchangeOrderCard;
