import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Input, Table } from "reactstrap";
import ImageCustom from "../../../../../../Components/Common/ImageCustom";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";
import InputStep from "../../../../../../Components/Common/InputStep";
import { deepCopy } from "../../../../../../helpers/format_helper";
import SelectTheme from "../../../../../../Components/Common/SelectTheme";
import { StockAdjustmentReasonOptions, StockAdjustmentReasons } from "../../../../../../Components/constants/common";
import ProductsSearchDropDown from "../../../../../../Components/Common/ProductsSearchDropDown";
import NotFound from "../../../../../../Components/Common/NotFound";
import { toast } from "react-toastify";
import ButtonTheme from "../../../../../../Components/Common/ButtonTheme";

const StockItemsCard = ({ stockLineItems = [], onChangeStockAdjustment, isEdit = true }) => {
  const { t } = useTranslation();

  const [showDropDownProductMenu, setShowDropDownProductMenu] = useState(false);

  const handleChangeQuantity = (number, dataIndex) => {
    let newData = deepCopy(stockLineItems);
    let orderItemIndex = newData.findIndex((item, index) => index === dataIndex);
    newData[orderItemIndex].quantity = number;
    onChangeStockAdjustment(newData);
  };

  const handleChangeLine = (data, dataIndex, field) => {
    let newData = deepCopy(stockLineItems);
    let orderItemIndex = newData.findIndex((item, index) => index === dataIndex);
    newData[orderItemIndex][field] = data;
    onChangeStockAdjustment(newData);
  };

  const toggleOnDropDownProductMenu = () => {
    setShowDropDownProductMenu(true);
  };
  const toggleOffDropDownProductMenu = () => {
    setShowDropDownProductMenu(false);
  };

  const addProduct = (data) => {
    const check = stockLineItems.find((item) => item.sku === data.sku);
    if (check) {
      alert(t("Product has already selected"));
      return;
    }
    let newData = deepCopy(stockLineItems);
    const newItem = {
      quantity: 0,
      sku: data.sku,
      product_id: data?.product_id || null,
      variant_id: data.id,
      variant_name: data.name,
      image_url: data.images?.[0]?.url,
      name: data.name,
      note: "",
      reason: StockAdjustmentReasons.OTHER,
    };
    newData.push(newItem);
    onChangeStockAdjustment(newData);
  };

  const removeProduct = (index) => {
    const newData = stockLineItems.filter((item, i) => index !== i);
    onChangeStockAdjustment(newData);
  };

  return (
    <Card>
      <CardHeader>
        <div className="d-flex align-items-center">
          <h5 className="card-title flex-grow-1 mb-0">{t("Product Info")}</h5>
        </div>
      </CardHeader>
      <CardBody>
        {isEdit && (
          <div className="mb-3">
            <ProductsSearchDropDown
              placeholder="Search by name, sku, barcode,..."
              onClickItem={addProduct}
              isOpen={showDropDownProductMenu}
              toggleOff={toggleOffDropDownProductMenu}
              toggleOn={toggleOnDropDownProductMenu}
              isShowDefault={true}
            />
          </div>
        )}
        <div className={"table-responsive"} style={{ maxHeight: "600px" }}>
          <Table hover className={"table table-nowrap align-middle mb-3"}>
            <thead className={"table-light text-muted"}>
              <tr className={""}>
                <th>{t("Items No.")}</th>
                <th>{t("Image")}</th>
                <th>{t("Name")}</th>
                <th className="text-center">{t("Actual Inventory")}</th>
                <th style={{ width: "200px" }}>{t("Reason")}</th>
                <th>{t("Note")}</th>
                {isEdit && <th></th>}
              </tr>
            </thead>
            <tbody>
              {isEdit ? (
                stockLineItems.length === 0 ? (
                  <tr>
                    <td colSpan={1000}>
                      <NotFound />
                    </td>
                  </tr>
                ) : (
                  stockLineItems.map((product, index) => {
                    return (
                      <tr
                        style={{ verticalAlign: "middle" }}
                        key={index}
                        onClick={() => {
                          // onRowClick(row);
                        }}
                      >
                        <td>{stockLineItems.length - index}</td>
                        <td>
                          <ImageCustom image={product.image_url} name={product.name} avatarSize={"avatar-sm"} />
                        </td>
                        <td>
                          <div className="d-flex flex-column justify-content-center">
                            <h5 className="fs-15">
                              <Tooltip title={product.name}>
                                <Link
                                  id={`order-items-product-name-${index}`}
                                  to={{ pathname: `/products/${product.product_id}`, state: { sku: product.sku } }}
                                  className="d-inline-block text-truncate"
                                  style={{ maxWidth: "400px" }}
                                  target="_blank"
                                >
                                  {product.name}
                                </Link>
                              </Tooltip>
                            </h5>
                            <p className="text-muted fw-medium mb-1">Sku: {product.sku}</p>
                          </div>
                        </td>
                        <td className="text-center">
                          <InputStep
                            index={index}
                            onChangeQuantity={handleChangeQuantity}
                            quantity={product?.quantity || 0}
                          />
                        </td>
                        <td>
                          <ReasonInput
                            index={index}
                            data={product?.reason || ""}
                            onChangeLine={handleChangeLine}
                            className={"mb-0"}
                          />
                        </td>
                        <td>
                          <NoteInput
                            index={index}
                            data={product?.note || ""}
                            onChangeLine={handleChangeLine}
                            placeholder={"Enter note"}
                            className={"border-0 border-bottom rounded-0"}
                          />
                        </td>
                        <td>
                          <ButtonTheme
                            type="button"
                            className="btn btn-outline-danger btn-icon waves-effect waves-light"
                            onClick={() => removeProduct(index)}
                          >
                            <i className="ri-delete-bin-5-line"></i>
                          </ButtonTheme>
                        </td>
                      </tr>
                    );
                  })
                )
              ) : (
                stockLineItems.map((product, index) => {
                  const total = product?.return_price || 0 * product?.quantity || 0;
                  return (
                    <tr
                      style={{ verticalAlign: "middle" }}
                      key={index}
                      onClick={() => {
                        // onRowClick(row);
                      }}
                    >
                      <td>{stockLineItems.length - index}</td>

                      <td>
                        <ImageCustom image={product.image_url} name={product.name} avatarSize={"avatar-sm"} />
                      </td>
                      <td>
                        <div className="d-flex flex-column justify-content-center">
                          <h5 className="fs-15">
                            <Tooltip title={product.name}>
                              <Link
                                id={`order-items-product-name-${index}`}
                                to={{ pathname: `/products/${product.product_id}`, state: { sku: product.sku } }}
                                className="d-inline-block text-truncate"
                                style={{ maxWidth: "400px" }}
                                target="_blank"
                              >
                                {product.name}
                              </Link>
                            </Tooltip>
                          </h5>
                          <p className="text-muted fw-medium mb-1">Sku: {product.sku}</p>
                        </div>
                      </td>
                      <td className="text-center">{product?.quantity || 0}</td>
                      <td>{product?.reason}</td>
                      <td>{product?.note || "---"}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

const NoteInput = ({ data = "", onChangeLine = () => {}, index, placeholder, className }) => {
  const [value, setValue] = useState(data);
  useEffect(() => {
    setValue(data);
  }, [data]);
  const handleBlur = () => {
    onChangeLine(value, index, "note");
  };
  return (
    <Input
      type="text"
      value={value}
      className={className}
      placeholder={placeholder}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onBlur={handleBlur}
    />
  );
};

const ReasonInput = ({ data = "", onChangeLine = () => {}, index, placeholder, className }) => {
  const [value, setValue] = useState(data);
  useEffect(() => {
    setValue(data);
  }, [data]);
  const handleChange = (option) => {
    onChangeLine(option, index, "reason");
  };
  return (
    <SelectTheme
      isForm={false}
      classWrapper={className}
      options={StockAdjustmentReasonOptions}
      onChange={(option) => {
        handleChange(option);
      }}
      selectDataOrigin={false}
      value={value}
    />
  );
};

export default StockItemsCard;
