import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Dropzone from "react-dropzone";
import { Checkbox } from "antd";
import cls from "classnames";
import styles from "../OptionList.module.scss";
import { useTranslation } from "react-i18next";
import ButtonTheme from "../../../../../../Components/Common/ButtonTheme";
import { formatBytes } from "../../../../../../helpers/format_helper";

const Index = ({ modal, toggleModal, onSaveImages, productImages, variantImages }) => {
  const { t } = useTranslation();

  const [tempImages, setTempImages] = useState([]);
  const [newImages, setNewImages] = useState([]);

  useEffect(() => {
    if (modal) {
      setTempImages(variantImages ?? []);
    }
  }, [modal]);

  const handleSave = () => {
    onSaveImages(tempImages);
    handleToggle();
  };

  const handleToggle = () => {
    setNewImages([]);
    toggleModal();
  };

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const content = event.target.result;
        file.content = content;
        file.preview = URL.createObjectURL(file);
        file.formattedSize = formatBytes(file.size);
        resolve(file);
      };

      reader.onerror = (event) => {
        reject(event.target.error);
      };
      reader.readAsDataURL(file);
    });
  };
  const handleAcceptedFiles = useCallback(
    (files) => {
      if (files.length > 0) {
        const promises = Array.from(files).map((file) => readFileContent(file));
        Promise.all(promises)
          .then((contents) => {
            setNewImages([...newImages, ...contents]);
          })
          .catch((error) => {
            console.error("Error reading files:", error);
          });
      }
    },
    [newImages],
  );

  return (
    <div>
      <Modal fade={true} isOpen={modal} toggle={handleToggle} centered={true} size="lg">
        <ModalHeader>{t("select_image")}</ModalHeader>
        <ModalBody>
          <div className="hstack gap-2">
            <Dropzone
              accept={{ "image/*": [".jpeg", ".jpg", ".png"] }}
              onDrop={(acceptedFiles) => {
                handleAcceptedFiles(acceptedFiles);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  className="text-center avatar-md col-auto p-0 m-2 border border-dashed border-2 cursor-pointer rounded-3"
                  onClick={handleToggle}
                  {...getRootProps()}
                >
                  <i className="display-4 text-muted ri-upload-cloud-2-fill rounded" />
                </div>
              )}
            </Dropzone>
            <Checkbox.Group
              className={cls(styles["modal-upload-image"])}
              style={{ width: "100%" }}
              onChange={(data) => {
                setTempImages(data);
              }}
            >
              {[...productImages, ...newImages].map((file, index) => {
                const checkedImage = tempImages.find((item) => item.id === file.id || item.preview === file.preview);
                return (
                  <Checkbox value={file} key={index} checked={!!checkedImage}>
                    <img
                      className="avatar-sm rounded bg-light"
                      key={index}
                      style={{ objectFit: "contain", aspectRatio: "9 / 9" }}
                      src={file.url || file.preview || file.image}
                      alt={`File preview ${index}`}
                    />
                  </Checkbox>
                );
              })}
            </Checkbox.Group>
          </div>
        </ModalBody>
        <ModalFooter>
          <ButtonTheme id="delete-record" type="button" className="btn btn-primary w-md" onClick={handleSave}>
            {t("Submit")}
          </ButtonTheme>
          <ButtonTheme type="button" className="btn w-md btn-light" onClick={handleToggle}>
            {t("Close")}
          </ButtonTheme>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Index;
