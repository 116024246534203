import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

import withRouter from "../../../../Components/Common/withRouter";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import { Actions } from "../../../../Components/constants/common";
import EditAddUnitModal from "./EditAddUnitModal";
import ActionDropdown from "../../../../Components/Common/ActionDropdown";

import { deleteUnit, listUnit } from "../../../../store/actions";

const UnitsPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Unit List")} | OptiWarehouse`;

  const dispatch = useDispatch();

  const { units, unitsLoading, unitsAction, unitsSuccess, unitsError, unitsLimit, unitsTotal, unitsPage } =
    useMemoizedSelector((state) => ({
      units: state.Units.units,
      unitsLoading: state.Units.loading,
      unitsSuccess: state.Units.success,
      unitsAction: state.Units.action,
      unitsError: state.Units.error,
      unitsLimit: state.Units.limit,
      unitsTotal: state.Units.total,
      unitsPage: state.Units.page,
    }));

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [showEditAddModal, setShowEditAddModal] = useState(false);

  const handleAdd = () => {
    setShowEditAddModal(true);
  };

  const handleEdit = (data) => {
    setShowEditAddModal(true);
    setSelectedUnit(data);
  };

  const handleCloseEditAddModal = () => {
    if (!unitsLoading) {
      setSelectedUnit(null);
      setShowEditAddModal(false);
    }
  };

  const handleDelete = () => {
    dispatch(deleteUnit(selectedUnit));
  };

  const handleCloseDeleteModal = () => {
    if (!unitsLoading) {
      setShowDeleteModal(false);
      setSelectedUnit(null);
    }
  };

  const headers = ["name", "ratio", "updated_at", "created_at", "action"];

  // toggle after loading
  useEffect(() => {
    if (!unitsLoading && unitsSuccess && showDeleteModal) {
      setShowDeleteModal(false);
      setSelectedUnit(null);
    }
  }, [unitsLoading, unitsSuccess]);

  return (
    <div className="page-content">
      <Container fluid>
        <DeleteModal
          show={showDeleteModal}
          onCloseClick={handleCloseDeleteModal}
          onDeleteClick={handleDelete}
          loading={unitsLoading && unitsAction === Actions.DELETE}
        />
        <EditAddUnitModal show={showEditAddModal} toggle={handleCloseEditAddModal} unit={selectedUnit} />
        <Row>
          <Col lg={12}>
            <Card id="unit-list">
              <CardHeader className="border-0">
                <Row className="g-4 align-items-center">
                  <div className="col-sm">
                    <div>
                      <h5 className="card-title mb-0">{t("Unit List")}</h5>
                    </div>
                  </div>
                  <div className="col-sm-auto">
                    <div>
                      <ButtonTheme
                        type="button"
                        className="btn btn-primary add-btn"
                        id="create-btn"
                        onClick={handleAdd}
                        icon="ri-add-line text-white"
                      >
                        {t("Add Unit")}
                      </ButtonTheme>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <TableContainerCustom
                    filterType={"units"}
                    searchPlaceHolder="Search unit..."
                    listFilter={[]}
                    headers={headers}
                    contents={units}
                    limitPage={Number(unitsLimit)}
                    currentPage={Number(unitsPage) + 1}
                    totalItems={unitsTotal}
                    loading={unitsLoading && unitsAction === Actions.LIST}
                    showPagination={true}
                    scrollHeight="50vh"
                    onCallData={(dataPayload) => {
                      dispatch(listUnit(dataPayload));
                    }}
                    isSettings={true}
                    listHeader={[
                      {
                        title: t("Unit details"),
                        key: "detail-variant",
                        children: [
                          {
                            title: t("Name"),
                            dataIndex: "name",
                            key: "name",
                            sorter: true,
                          },
                          {
                            title: t("Ratio"),
                            dataIndex: "ratio",
                            key: "ratio",
                            sorter: true,
                          },
                          {
                            title: t("Updated at"),
                            dataIndex: "updated_at",
                            key: "updated_at",
                            sorter: true,
                          },
                          {
                            title: t("Created at"),
                            dataIndex: "created_at",
                            key: "created_at",
                            defaultSortOrder: "desc",
                            sorter: true,
                          },
                          {
                            title: t("Action"),
                            dataIndex: "action",
                            key: "action",
                            render: (value, row, index) => {
                              return (
                                <ActionDropdown
                                  index={index}
                                  actions={[
                                    {
                                      type: "edit",
                                      onClick: () => {
                                        handleEdit(row);
                                      },
                                    },
                                    {
                                      type: "delete",
                                      onClick: () => {
                                        setSelectedUnit(row);
                                        setShowDeleteModal(true);
                                      },
                                    },
                                  ]}
                                />
                              );
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default withRouter(UnitsPage);
