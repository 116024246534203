// Actions
export const LIST_PRICE_GROUP = "LIST_PRICE_GROUP";
export const LIST_PRICE_GROUP_SUCCESS = "LIST_PRICE_GROUP_SUCCESS";
export const LIST_PRICE_GROUP_FAIL = "LIST_PRICE_GROUP_FAIL";

export const GET_PRICE_GROUP = "GET_PRICE_GROUP";
export const GET_PRICE_GROUP_SUCCESS = "GET_PRICE_GROUP_SUCCESS";
export const GET_PRICE_GROUP_FAIL = "GET_PRICE_GROUP_FAIL";

/**
 * Delete PRICE_GROUP
 */
export const DELETE_PRICE_GROUP = "DELETE_PRICE_GROUP";
export const DELETE_PRICE_GROUP_SUCCESS = "DELETE_PRICE_GROUP_SUCCESS";
export const DELETE_PRICE_GROUP_FAIL = "DELETE_PRICE_GROUP_FAIL";

/**
 * Add PRICE_GROUP
 */
export const ADD_NEW_PRICE_GROUP = "ADD_NEW_PRICE_GROUP";
export const ADD_PRICE_GROUP_SUCCESS = "ADD_PRICE_GROUP_SUCCESS";
export const ADD_PRICE_GROUP_FAIL = "ADD_PRICE_GROUP_FAIL";

/**
 * Edit PRICE_GROUP
 */
export const UPDATE_PRICE_GROUP = "UPDATE_PRICE_GROUP";
export const UPDATE_PRICE_GROUP_SUCCESS = "UPDATE_PRICE_GROUP_SUCCESS";
export const UPDATE_PRICE_GROUP_FAIL = "UPDATE_PRICE_GROUP_FAIL";
