import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";


// Redux States
import { LIST_ORDER, DELETE_ORDER, ADD_NEW_ORDER, UPDATE_ORDER, GET_ORDER } from "./actionType";

import {
  deleteOrderSuccess,
  deleteOrderFail,
  addOrderSuccess,
  addOrderFail,
  updateOrderSuccess,
  updateOrderFail,
  listOrderSuccess,
  listOrderFail,
  getOrderSuccess,
  getOrderFail,
} from "./action";

import { ordersAPI } from "../../../../helpers/resource_helper";

function* listOrder({ payload: { ...res } }) {
  try {
    const response = yield call(ordersAPI.list, { ...res });
    yield put(listOrderSuccess(response));
  } catch (error) {
    yield put(listOrderFail(error));
  }
}

function* getOrder({ payload: id }) {
  try {
    const response = yield call(ordersAPI.get, { id });
    yield put(getOrderSuccess(response));
  } catch (error) {
    yield put(getOrderFail(error));
  }
}

function* deleteOrder({ payload: order }) {
  try {
    const response = yield call(ordersAPI.delete, order);
    yield put(deleteOrderSuccess(order));
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteOrderFail(error));
    toast.error("Order Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewOrder({ payload: { order, handleAfterSubmit } }) {
  try {
    const response = yield call(ordersAPI.create, order);
    if (response) {
      yield call(handleAfterSubmit, response);
      yield put(addOrderSuccess(response));
      // toast.success("Order Added Successfully", { autoClose: 3000 });
    }
  } catch (error) {
    yield put(addOrderFail(error));
    toast.error("Order Added Failed", { autoClose: 3000 });
  }
}

function* onUpdateOrder({ payload: order }) {
  try {
    const response = yield call(ordersAPI.put, order);
    yield put(updateOrderSuccess(response));
    toast.success("Order Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateOrderFail(error));
    toast.error("Order Updated Failed", { autoClose: 3000 });
  }
}

export function* watchGetOrders() {
  yield takeEvery(LIST_ORDER, listOrder);
}

export function* watchDeleteOrder() {
  yield takeEvery(DELETE_ORDER, deleteOrder);
}

export function* watchGetOrder() {
  yield takeEvery(GET_ORDER, getOrder);
}

export function* watchUpdateOrder() {
  yield takeEvery(UPDATE_ORDER, onUpdateOrder);
}

export function* watchAddNewOrder() {
  yield takeEvery(ADD_NEW_ORDER, onAddNewOrder);
}

function* ordersSaga() {
  yield all([
    fork(watchGetOrder),
    fork(watchGetOrders),
    fork(watchUpdateOrder),
    fork(watchAddNewOrder),
    fork(watchDeleteOrder),
  ]);
}

export default ordersSaga;
