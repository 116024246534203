export const PRODUCT = {
  _list: "/products",
  get: "/products/id",
  add: "/products/new",
  update: "/products/id/edit",
  delete: true,
};
export const VARIANT = {
  _list: "/variants",
  get: true,
  add: true,
  update: true,
  delete: true,
};
export const BRAND = {
  _list: "/brands",
  get: true,
  add: true,
  update: true,
  delete: true,
};

export const CATEGORY = {
  _list: "/categories",
  get: true,
  add: true,
  update: true,
  delete: true,
};

export const ADD_PRODUCT = "product.add";
export const UPDATE_PRODUCT = "product.update";
export const DELETE_PRODUCT = "product.delete";
export const GET_PRODUCT = "product.get";

export const ADD_VARIANT = "variant.add";
export const UPDATE_VARIANT = "variant.update";
export const DELETE_VARIANT = "variant.delete";
export const GET_VARIANT = "variant.get";

export const ADD_BRAND = "brand.add";
export const UPDATE_BRAND = "brand.update";
export const DELETE_BRAND = "brand.delete";
export const GET_BRAND = "brand.get";

export const ADD_CATEGORY = "category.add";
export const UPDATE_CATEGORY = "category.update";
export const DELETE_CATEGORY = "category.delete";
export const GET_CATEGORY = "category.get";
