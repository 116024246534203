import { Link, useSearchParams } from "react-router-dom";
import { useState, useEffect, Fragment, useMemo } from "react";
import { useDispatch } from "react-redux";
import Flatpickr from "react-flatpickr";

import { Card, CardBody, CardHeader, Col, Container, FormFeedback, Input, Label, Row } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useProfile } from "../../../../../Components/Hooks/useProfile";
import useMemoizedSelector from "../../../../../Components/Hooks/useMemoizedSelector";
import { addNewDiscount, getDiscount, resetDiscount, updateDiscount } from "../../../../../store/actions";
import BreadCrumbCustom from "../../../../../Components/Common/BreadCrumbCustom";
import {
  Actions,
  DayOfWeekOptions,
  DiscountAmountType,
  DiscountStatusOptions,
  DiscountStatuses,
  DiscountTypeOptions,
  DiscountTypes,
  EffectiveProduct,
  MonthOptions,
} from "../../../../../Components/constants/common";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import Loader from "../../../../../Components/Common/Loader";
import withRouter from "../../../../../Components/Common/withRouter";
import { deepCopy, returnDiscountInfoFieldByType } from "../../../../../helpers/format_helper";
import DiscountByOrderTotal from "../DiscountInfo/DicountByOrderTotal";
import DiscountByProduct from "../DiscountInfo/DiscountByProduct";
import DiscountProductByOrderTotal from "../DiscountInfo/DiscountProductByOrderTotal";
import DiscountByPurchaseProduct from "../DiscountInfo/DiscountByPurchaseProduct";
import GiftByPurchaseProduct from "../DiscountInfo/GiftByPurchaseProduct";
import GiftByOrderTotal from "../DiscountInfo/GiftByOrderTotal";
import UploadImage from "../../../../../Components/Common/UploadImage";
import DiscountByQuantity from "../DiscountInfo/DiscountByQuantity";
import ButtonDropdownCustom from "../../../../../Components/Common/ButtonDropdownCustom";
import { locationsAPI } from "../../../../../helpers/resource_helper";
import { value } from "jsonpath";
import { toast } from "react-toastify";
import InstructionsCondition from "../Components/InstructionsCondition";

const EditAddDiscountPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  const { userProfile } = useProfile();
  const { id } = params;
  const [searchParams, setSearchParams] = useSearchParams();
  const discountType = searchParams.get("type");
  const isEdit = true;
  const dispatch = useDispatch();
  document.title = `${t(!id ? "Create new discount" : "Discount details")} | OptiWarehouse`;

  const { discount, discountsLoading, discountsAction, discountsSuccess, discountsError, discountsMessage } =
    useMemoizedSelector((state) => ({
      discount: state.Discounts.discount,
      discountsLoading: state.Discounts.loading,
      discountsAction: state.Discounts.action,
      discountsSuccess: state.Discounts.success,
      discountsError: state.Discounts.error,
      discountsMessage: state.Discounts.message,
    }));

  const [limitless, setLimitless] = useState(false);
  const [advancedDisplay, setAdvancedDisplay] = useState(false);
  const [disableExpire, setDisableExpire] = useState(false);
  const [locationRadio, setLocationRadio] = useState("all");
  const [sourceRadio, setSourceRadio] = useState("all");
  const [customerRadio, setCustomerRadio] = useState("all");

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: discount?.name || "",
      discount_code: discount?.discount_code || "",
      max_using_times: discount?.max_using_times || null,
      description: discount?.description || "",
      start_at: discount?.start_at || null,
      expire_at: discount?.expire_at || null,
      status: discount?.status || DiscountStatuses.DRAFT,
      image: discount?.image || null,
      publish: discount?.publish || false,
      type: discount?.type || discountType,
      company_id: discount?.company_id || userProfile.UserAttributes["custom:company_id"],
      locations: discount?.locations || null,
      sources: discount?.sources || null,
      customers: discount?.customers || null,
      effective_time_range: discount?.effective_time_range || null,
      effective_days_of_week: discount?.effective_days_of_week || null,
      effective_days_of_month: discount?.effective_days_of_month || null,
      effective_months: discount?.effective_months || null,
      discount_by_order_total: discount?.discount_by_order_total || null,
      discount_by_product: discount?.discount_by_product || null,
      discount_product_by_order_total: discount?.discount_product_by_order_total || null,
      discount_by_quantity: discount?.discount_by_quantity || null,
      discount_by_purchase_product: discount?.discount_by_purchase_product || null,
      gift_by_purchase_product: discount?.gift_by_purchase_product || null,
      gift_by_order_total: discount?.gift_by_order_total || null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Required discount name")),
      discount_code: Yup.string().required(t("Required discount code")),
      // max_using_times: Yup.number().required("Required discount max using times"),
      start_at: Yup.string().required(t("Required effective start date")),
    }),
    onSubmit: (values) => {
      let payload = deepCopy(values);
      limitless && (payload.max_using_times = null);
      disableExpire && (payload.expire_at = null);
      startTime && endTime && (payload.effective_time_range = startTime + "-" + endTime);
      const field = returnDiscountInfoFieldByType(validation.values.type);
      if (payload[field]) {
        if (!id) {
          dispatch(addNewDiscount(payload));
        } else {
          dispatch(updateDiscount({ ...discount, ...payload }));
        }
      } else {
        toast.error(t("Required at least 1 condition!"));
      }
    },
  });

  const addConditionDisable = useMemo(() => {
    if (validation.values.type === DiscountTypes.BY_PRODUCT) {
      return !!validation.values?.discount_by_product?.[0]?.for_all_items;
    } else if (validation.values.type === DiscountTypes.BY_QUANTITY) {
      return !!validation.values?.discount_by_quantity?.[0]?.for_all_items;
    } else if (validation.values.type === DiscountTypes.BY_PURCHASE_PRODUCT) {
      return !!validation.values?.discount_by_purchase_product?.[0]?.for_all_purchase_product;
    } else if (validation.values.type === DiscountTypes.GIFT_BY_PURCHASE_PRODUCT) {
      return !!validation.values?.gift_by_purchase_product?.[0]?.for_all_purchase_product;
    }
  }, [validation.values]);

  const handleChangeImage = (data) => {
    validation.setFieldValue("image", data);
  };

  const returnNewConditionLineData = () => {
    switch (validation.values.type) {
      case DiscountTypes.BY_ORDER_TOTAL:
        return { amount_from: 0, amount_to: null, amount: 0, rate: null, type: DiscountAmountType.VALUE };
      case DiscountTypes.BY_PRODUCT:
        return {
          min_quantity: 0,
          discount_limit: 0,
          item_type: EffectiveProduct.PRODUCTS,
          for_all_items: null,
          products: null,
          variants: null,
          brands: null,
          categories: null,
          amount: 0,
          rate: null,
          type: DiscountAmountType.VALUE,
        };
      case DiscountTypes.PRODUCT_BY_ORDER_TOTAL:
        return {
          amount_from: 0,
          max_quantity: 0,
          item_type: EffectiveProduct.PRODUCTS,
          for_all_items: null,
          products: null,
          variants: null,
          brands: null,
          categories: null,
          amount: 0,
          rate: null,
          type: DiscountAmountType.VALUE,
        };
      case DiscountTypes.BY_QUANTITY:
        return {
          item_type: EffectiveProduct.PRODUCTS,
          conditions: [{ quantity_from: 1, quantity_to: null, amount: 0, rate: null, type: DiscountAmountType.VALUE }],
          for_all_items: null,
          products: null,
          variants: null,
          brands: null,
          categories: null,
        };
      case DiscountTypes.BY_PURCHASE_PRODUCT:
        return {
          purchase_product_quantity: 0,
          sale_offs_product_max_quantity: 0,
          purchase_product_type: EffectiveProduct.PRODUCTS,
          sale_offs_product_type: EffectiveProduct.PRODUCTS,
          for_all_sale_offs_product: null,
          for_all_purchase_product: null,
          purchase_products: null,
          purchase_variants: null,
          purchase_brands: null,
          purchase_categories: null,
          sale_offs_products: null,
          sale_offs_variants: null,
          sale_offs_brands: null,
          sale_offs_categories: null,
          amount: 0,
          rate: null,
          type: DiscountAmountType.VALUE,
        };
      case DiscountTypes.GIFT_BY_PURCHASE_PRODUCT:
        return {
          purchase_product_quantity: 0,
          sale_offs_product_max_quantity: 0,
          purchase_product_type: EffectiveProduct.PRODUCTS,
          sale_offs_product_type: EffectiveProduct.PRODUCTS,
          for_all_sale_offs_product: null,
          for_all_purchase_product: null,
          purchase_products: null,
          purchase_variants: null,
          purchase_brands: null,
          purchase_categories: null,
          sale_offs_products: null,
          sale_offs_variants: null,
          sale_offs_brands: null,
          sale_offs_categories: null,
        };
      case DiscountTypes.GIFT_BY_ORDER_TOTAL:
        return {
          amount_from: 0,
          max_quantity: 0,
          item_type: EffectiveProduct.PRODUCTS,
          for_all_items: null,
          products: null,
          variants: null,
          brands: null,
          categories: null,
        };
      default:
        return null;
    }
  };

  const onChangeConditionInfo = (data) => {
    const infoField = returnDiscountInfoFieldByType(validation.values.type);
    if (infoField) {
      const newData = validation.values[infoField].map((item, idx) => {
        item.products = null;
        item.variants = null;
        item.brands = null;
        item.categories = null;
        if (item?.for_all_items) {
          item.for_all_items = false;
        }
        return { ...item, ...data };
      });
      validation.setFieldValue(infoField, newData);
    }
  };

  const onDeleteConditionLine = (index) => {
    const infoField = returnDiscountInfoFieldByType(validation.values.type);
    if (infoField) {
      const newData = validation.values[infoField].filter((item, idx) => idx !== index);
      validation.setFieldValue(infoField, newData);
    }
  };
  const onAddConditionLine = () => {
    const infoField = returnDiscountInfoFieldByType(validation.values.type);
    const newCondition = returnNewConditionLineData();
    const oldCondition = validation.values[infoField] || [];
    if (infoField && newCondition) {
      const newData = [...oldCondition, newCondition];
      validation.setFieldValue(infoField, newData);
    }
  };
  const onChangeConditionLine = (index, data) => {
    const infoField = returnDiscountInfoFieldByType(validation.values.type);
    if (infoField) {
      const newData = deepCopy(validation.values[infoField]);
      newData[index] = { ...newData[index], ...data };
      validation.setFieldValue(infoField, newData);
    }
  };

  const handleCheckAdvancedDisplay = (checked) => {
    if (!checked) {
      const newData = deepCopy(validation.values);
      const newData1 = {
        effective_time_range: null,
        effective_days_of_week: null,
        effective_days_of_month: null,
        effective_months: null,
      };
      validation.setValues({ ...newData, ...newData1 });
    }
    setAdvancedDisplay(checked);
  };

  const timeOptions = Array.from({ length: 48 }, (_, index) => {
    const hours = Math.floor(index / 2);
    const minutes = index % 2 === 0 ? "00" : "30";
    return `${hours.toString().padStart(2, "0")}:${minutes}`;
  });

  const dateOfMonthOptions = Array.from({ length: 31 }, (_, index) => {
    return { id: index, name: `${t("Day")} ${index + 1}` };
  });

  useEffect(() => {
    if (DiscountTypeOptions.findIndex((item) => item.id === discountType) === -1 && !id) {
      toast.error("That type is not available!");
      navigate("/discounts");
    }
  }, [discountType]);

  useEffect(() => {
    if (id) {
      dispatch(getDiscount(id));
    } else {
      dispatch(resetDiscount());
      onAddConditionLine();
    }
  }, [dispatch, id]);

  useEffect(() => {
    setLocationRadio(discount?.locations ? "some" : "all");
    setSourceRadio(discount?.sources ? "some" : "all");
    setCustomerRadio(discount?.Customers ? "some" : "all");
    (discount.effective_days_of_month ||
      discount.effective_days_of_week ||
      discount.effective_time_range ||
      discount.effective_months) &&
      setAdvancedDisplay(true);
    if (discount?.effective_time_range) {
      const timeRange = discount?.effective_time_range.split("-");
      setStartTime(timeRange[0] || null);
      setEndTime(timeRange[1] || null);
    }
  }, [discount]);

  // navigate when add discount success
  useEffect(() => {
    if (
      discountsSuccess &&
      !discountsLoading &&
      discount?.id &&
      (discountsMessage === "Add discount success" || discountsMessage === "Update discount success")
    ) {
      navigate(`/discounts/details/${discount.id}`);
    }
  }, [discountsSuccess, discountsLoading, discountsMessage, discount]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumbCustom text="Back to discount list" navigateTo="/discounts">
          {!id ? (
            <ButtonTheme
              type="button"
              className="btn btn-outline-primary w-md"
              onClick={validation.handleSubmit}
              loading={discountsLoading && discountsAction === Actions.CREATE}
              disabled={discountsLoading && discountsAction === Actions.CREATE}
            >
              {t("Create")}
            </ButtonTheme>
          ) : (
            <div className="hstack gap-2">
              <ButtonTheme
                type="button"
                className="btn btn-outline-primary w-md"
                onClick={validation.handleSubmit}
                loading={discountsLoading && discountsAction === Actions.UPDATE}
                disabled={discountsLoading || discountsAction === Actions.UPDATE}
              >
                {t("Update")}
              </ButtonTheme>
              <ButtonTheme
                type="button"
                className="btn btn-light w-md"
                onClick={() => {
                  navigate(`/discounts/details/${id}`);
                }}
                disabled={discountsLoading}
              >
                {t("Close")}
              </ButtonTheme>
            </div>
          )}
        </BreadCrumbCustom>

        {discountsLoading && discountsAction === Actions.GET ? (
          <Loader />
        ) : (
          <Row>
            <Col className="col-9">
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center">
                    <h5 className="card-title flex-grow-1 mb-0">{t("General Info")}</h5>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg={6}>
                      <Label className="form-label" for="project-thumbnail-img">
                        {t("Image")}
                      </Label>
                      <UploadImage
                        prefix="promotion"
                        image={validation.values.image}
                        onChangeImage={handleChangeImage}
                      />
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label>
                          {t("Name")} <span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="name-field"
                          type="text"
                          name="name"
                          placeholder={t("Enter discount name")}
                          value={validation.values.name || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={validation.touched.name && validation.errors.name ? true : false}
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <div className="form-check form-check-right form-switch">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            id="publish-field"
                            name="publish"
                            checked={validation.values.publish}
                            onChange={validation.handleChange}
                          />
                          <Label for="publish-field">{t("Publish")}</Label>
                        </div>
                      </div>
                    </Col>

                    <Col lg={6}>
                      <div className="mb-3">
                        <Label for="status-field">
                          {t("Status")} <span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="status-field"
                          type="select"
                          name="status"
                          value={validation.values.status}
                          onChange={validation.handleChange}
                        >
                          {DiscountStatusOptions.map((item) => (
                            <option key={item.id} value={item.id}>
                              {t(item.name)}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label>
                          {t("Discount code")} <span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="discount-code-field"
                          type="text"
                          name="discount_code"
                          placeholder={t("Enter discount code")}
                          value={validation.values.discount_code || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={validation.touched.discount_code && validation.errors.discount_code ? true : false}
                        />
                        {validation.touched.discount_code && validation.errors.discount_code ? (
                          <FormFeedback type="invalid">{validation.errors.discount_code}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label>
                          {t("Max using times")} <span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="using-amount-field"
                          type="number"
                          name="max_using_times"
                          placeholder={!limitless && t("Enter max using times")}
                          value={validation.values.max_using_times || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.max_using_times && validation.errors.max_using_times && !limitless
                              ? true
                              : false
                          }
                          disabled={limitless}
                        />
                        {!limitless && validation.touched.max_using_times && validation.errors.max_using_times ? (
                          <FormFeedback type="invalid">{validation.errors.max_using_times}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="form-check mb-3">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          id="limitless-input"
                          checked={limitless}
                          onChange={(e) => {
                            setLimitless(e.target.checked);
                            e.target.checked && validation.setFieldValue("max_using_times", null);
                          }}
                        />
                        <Label className="form-check-label" for="limitless-input">
                          {t("Limitless")}
                        </Label>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label>{t("Description")}</Label>
                        <Input
                          id="description-field"
                          type="text"
                          name="description"
                          placeholder={t("Enter description")}
                          value={validation.values.description || ""}
                          onChange={validation.handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center">
                    <h5 className="card-title flex-grow-1 mb-0">
                      {t("Discount info")} (
                      {t(DiscountTypeOptions.find((item) => item.id === validation.values.type)?.label || "---")})
                    </h5>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <InstructionsCondition type={validation.values.type} />
                  </div>
                  {validation.values.type === DiscountTypes.BY_ORDER_TOTAL && (
                    <DiscountByOrderTotal
                      onDeleteConditionLine={onDeleteConditionLine}
                      onChangeConditionLine={onChangeConditionLine}
                      data={validation.values.discount_by_order_total}
                      isEdit={isEdit}
                    />
                  )}
                  {validation.values.type === DiscountTypes.BY_PRODUCT && (
                    <DiscountByProduct
                      onDeleteConditionLine={onDeleteConditionLine}
                      onChangeConditionLine={onChangeConditionLine}
                      onChangeConditionInfo={onChangeConditionInfo}
                      data={validation.values.discount_by_product}
                      isEdit={isEdit}
                    />
                  )}
                  {validation.values.type === DiscountTypes.PRODUCT_BY_ORDER_TOTAL && (
                    <DiscountProductByOrderTotal
                      onDeleteConditionLine={onDeleteConditionLine}
                      onChangeConditionLine={onChangeConditionLine}
                      onChangeConditionInfo={onChangeConditionInfo}
                      data={validation.values.discount_product_by_order_total}
                      isEdit={isEdit}
                    />
                  )}
                  {validation.values.type === DiscountTypes.BY_QUANTITY && (
                    <DiscountByQuantity
                      onDeleteConditionLine={onDeleteConditionLine}
                      onChangeConditionLine={onChangeConditionLine}
                      onChangeConditionInfo={onChangeConditionInfo}
                      validation={validation}
                      data={validation.values.discount_by_quantity}
                      isEdit={isEdit}
                    />
                  )}
                  {validation.values.type === DiscountTypes.BY_PURCHASE_PRODUCT && (
                    <DiscountByPurchaseProduct
                      onDeleteConditionLine={onDeleteConditionLine}
                      onChangeConditionLine={onChangeConditionLine}
                      onChangeConditionInfo={onChangeConditionInfo}
                      validation={validation}
                      data={validation.values.discount_by_purchase_product}
                      isEdit={isEdit}
                    />
                  )}
                  {validation.values.type === DiscountTypes.GIFT_BY_ORDER_TOTAL && (
                    <GiftByOrderTotal
                      onDeleteConditionLine={onDeleteConditionLine}
                      onChangeConditionLine={onChangeConditionLine}
                      onChangeConditionInfo={onChangeConditionInfo}
                      data={validation.values.gift_by_order_total}
                      isEdit={isEdit}
                    />
                  )}
                  {validation.values.type === DiscountTypes.GIFT_BY_PURCHASE_PRODUCT && (
                    <GiftByPurchaseProduct
                      onDeleteConditionLine={onDeleteConditionLine}
                      onChangeConditionLine={onChangeConditionLine}
                      onChangeConditionInfo={onChangeConditionInfo}
                      validation={validation}
                      data={validation.values.gift_by_purchase_product}
                      isEdit={isEdit}
                    />
                  )}
                  <div className="mt-3">
                    <ButtonTheme
                      onClick={onAddConditionLine}
                      className={"btn btn-primary w-md"}
                      disabled={id ? true : false || addConditionDisable}
                    >
                      {t("Add condition")}
                    </ButtonTheme>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col className="col-3">
              <Card>
                <CardHeader>
                  <div className="d-sm-flex align-items-center">
                    <h5 className="card-title flex-grow-1 mb-0">{t("Effective time")}</h5>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Label>
                      {t("Start date")} <span className="text-danger">*</span>
                    </Label>
                    <Flatpickr
                      id="start-at-field"
                      className={`form-control ${!isEdit && "bg-light"}`}
                      placeholder={t("Select a date")}
                      type="date"
                      value={validation.values.start_at || ""}
                      onChange={(date) => {
                        validation.setFieldValue("start_at", date.length > 0 ? date[0].toISOString() : null);
                      }}
                      options={{
                        clickOpens: isEdit,
                        enableTime: true,
                        altInput: true,
                        time_24hr: true,
                        altFormat: "F j, Y H:i",
                        dateFormat: "d.m.y H:i",
                        disable: [
                          function (date) {
                            // return true to disable
                            if (validation.values.expire_at) {
                              return date.toISOString() >= validation.values.expire_at;
                            }
                          },
                        ],
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <Label>{t("Expire date")}</Label>
                    <Flatpickr
                      id="expire-at-field"
                      className="form-control"
                      placeholder={t("Select a date")}
                      type="date"
                      value={validation.values.expire_at || ""}
                      onChange={(date) => {
                        validation.setFieldValue("expire_at", date.length > 0 ? date[0].toISOString() : null);
                      }}
                      disabled={disableExpire}
                      options={{
                        clickOpens: !disableExpire,
                        enableTime: true,
                        altInput: true,
                        time_24hr: true,
                        altFormat: "F j, Y H:i",
                        dateFormat: "d.m.y H:i",
                        disable: [
                          function (date) {
                            // return true to disable
                            if (validation.values.start_at) {
                              return date.toISOString() < validation.values.start_at;
                            }
                          },
                        ],
                      }}
                    />
                  </div>
                  <div>
                    <Input
                      id="disable-expire-input"
                      type="checkbox"
                      checked={disableExpire}
                      onChange={(e) => {
                        setDisableExpire(e.target.checked);
                        e.target.checked && validation.setFieldValue("expire_at", null);
                      }}
                    />
                    <Label for="disable-expire-input" className="ms-2">
                      {t("Disable expire date")}
                    </Label>
                  </div>
                  <div>
                    <Input
                      id="advanced-input"
                      type="checkbox"
                      checked={advancedDisplay}
                      onChange={(e) => {
                        handleCheckAdvancedDisplay(e.target.checked);
                      }}
                      disabled={!isEdit}
                    />
                    <Label for="advanced-input" className="ms-2">
                      {t("Advanced display")}
                    </Label>
                  </div>
                  {advancedDisplay && (
                    <Fragment>
                      <div className="mb-3">
                        <Label>{t("Effective date time")}</Label>
                        <div className="hstack gap-2">
                          <select
                            className="form-select"
                            value={startTime}
                            onChange={(e) => {
                              setStartTime(e.target.value);
                            }}
                            disabled={!isEdit}
                          >
                            <option value={null}></option>
                            {timeOptions.map((time) => (
                              <option key={time} value={time}>
                                {time}
                              </option>
                            ))}
                          </select>
                          -
                          <select
                            className="form-select"
                            value={endTime}
                            onChange={(e) => {
                              setEndTime(e.target.value);
                            }}
                            disabled={!isEdit}
                          >
                            <option value={null}></option>
                            {timeOptions.map((time) => (
                              <option key={time} value={time}>
                                {time}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label>{t("Effective days of week")}</Label>
                        <ButtonDropdownCustom
                          isEdit={isEdit}
                          text="days of week"
                          data={validation.values.effective_days_of_week}
                          buttonClass={"form-select"}
                          options={DayOfWeekOptions}
                          isObject={false}
                          onChangeData={(data) => {
                            validation.setFieldValue("effective_days_of_week", data);
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <Label>{t("Effective months")}</Label>
                        <ButtonDropdownCustom
                          isEdit={isEdit}
                          text="months"
                          data={validation.values.effective_months}
                          buttonClass={"form-select"}
                          options={MonthOptions}
                          isObject={false}
                          onChangeData={(data) => {
                            validation.setFieldValue("effective_months", data);
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <Label>{t("Effective days of month")}</Label>
                        <ButtonDropdownCustom
                          isEdit={isEdit}
                          text="days"
                          data={validation.values.effective_days_of_month}
                          buttonClass={"form-select"}
                          options={dateOfMonthOptions}
                          isObject={false}
                          onChangeData={(data) => {
                            validation.setFieldValue("effective_days_of_month", data);
                          }}
                        />
                      </div>
                    </Fragment>
                  )}
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <div className="d-sm-flex align-items-center">
                    <h5 className="card-title flex-grow-1 mb-0">{t("Effective locations")}</h5>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="form-check mb-2">
                    <Input
                      className="form-check-input"
                      type="radio"
                      name="locations-radio"
                      id="locations-radio-1"
                      checked={locationRadio === "all"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setLocationRadio("all");
                          validation.setFieldValue("locations", null);
                        }
                      }}
                      disabled={!isEdit}
                    />
                    <Label className="form-check-label" for="locations-radio-1">
                      {t("All locations")}
                    </Label>
                  </div>
                  <div className="form-check">
                    <Input
                      className="form-check-input"
                      type="radio"
                      name="locations-radio"
                      id="locations-radio-2"
                      checked={locationRadio === "some"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setLocationRadio("some");
                        }
                      }}
                      disabled={!isEdit}
                    />
                    <Label className="form-check-label" for="locations-radio-2">
                      {t("Effective locations")}
                    </Label>
                  </div>
                  {locationRadio === "some" && (
                    <ButtonDropdownCustom
                      isEdit={isEdit}
                      text="locations"
                      data={validation.values.locations}
                      buttonClass={"form-select"}
                      listRemoteData={locationsAPI}
                      onChangeData={(data) => {
                        validation.setFieldValue("locations", data);
                      }}
                    />
                  )}
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <div className="d-sm-flex align-items-center">
                    <h5 className="card-title flex-grow-1 mb-0">{t("Effective sources")}</h5>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="form-check mb-2">
                    <Input
                      className="form-check-input"
                      type="radio"
                      name="sources-radio"
                      id="sources-radio-1"
                      checked={sourceRadio === "all"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSourceRadio("all");
                          validation.setFieldValue("sources", null);
                        }
                      }}
                      disabled={!isEdit}
                    />
                    <Label className="form-check-label" for="sources-radio-1">
                      {t("All sources")}
                    </Label>
                  </div>
                  <div className="form-check">
                    <Input
                      className="form-check-input"
                      type="radio"
                      name="sources-radio"
                      id="sources-radio-2"
                      checked={sourceRadio === "some"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSourceRadio("some");
                        }
                      }}
                      disabled={!isEdit}
                    />
                    <Label className="form-check-label" for="sources-radio-2">
                      {t("Effective sources")}
                    </Label>
                  </div>
                  {sourceRadio === "some" && (
                    <ButtonDropdownCustom
                      isEdit={isEdit}
                      text="locations"
                      data={validation.values.locations}
                      buttonClass={"form-select"}
                      listRemoteData={locationsAPI}
                      onChangeData={(data) => {
                        validation.setFieldValue("locations", data);
                      }}
                    />
                  )}
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <div className="d-sm-flex align-items-center">
                    <h5 className="card-title flex-grow-1 mb-0">{t("Effective customers")}</h5>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="form-check mb-2">
                    <Input
                      className="form-check-input"
                      type="radio"
                      name="customers-radio"
                      id="customers-radio-1"
                      checked={customerRadio === "all"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setCustomerRadio("all");
                          validation.setFieldValue("customers", null);
                        }
                      }}
                      disabled={!isEdit}
                    />
                    <Label className="form-check-label" for="customers-radio-1">
                      {t("All customers")}
                    </Label>
                  </div>
                  <div className="form-check">
                    <Input
                      className="form-check-input"
                      type="radio"
                      name="customers-radio"
                      id="customers-radio-2"
                      checked={customerRadio === "some"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setCustomerRadio("some");
                          validation.setFieldValue("customers", []);
                        }
                      }}
                      disabled={!isEdit}
                    />
                    <Label className="form-check-label" for="customers-radio-2">
                      {t("Effective customers")}
                    </Label>
                  </div>
                  {customerRadio === "some" && (
                    <ButtonDropdownCustom
                      isEdit={isEdit}
                      text="locations"
                      data={validation.values.locations}
                      buttonClass={"form-select"}
                      listRemoteData={locationsAPI}
                      onChangeData={(data) => {
                        validation.setFieldValue("locations", data);
                      }}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default withRouter(EditAddDiscountPage);
