import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { isEmpty } from "lodash";

import withRouter from "../../../../Components/Common/withRouter";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { Actions } from "../../../../Components/constants/common";

import { deleteCustomerGroup, listCustomerGroup } from "../../../../store/actions";
import ActionDropdown from "../../../../Components/Common/ActionDropdown";
import ImageCustom from "../../../../Components/Common/ImageCustom";
import { Link } from "react-router-dom";
import { formatVNDCurrency } from "../../../../helpers/format_helper";

const CustomerGroupPage = (props) => {
  const {
    router: { location, navigate, params, t },
  } = props;
  document.title = `${t("Customer Group List")} | OptiWarehouse`;
  const dispatch = useDispatch();

  const {
    customerGroups,
    customerGroupsAction,
    customerGroupsLoading,
    customerGroupsMessage,
    customerGroupsSuccess,
    customerGroupsError,
    customerGroupsLimit,
    customerGroupsPage,
    customerGroupsTotal,
  } = useMemoizedSelector((state) => ({
    customerGroups: state.CustomerGroups.customerGroups,
    customerGroupsAction: state.CustomerGroups.action,
    customerGroupsLoading: state.CustomerGroups.loading,
    customerGroupsSuccess: state.CustomerGroups.success,
    customerGroupsError: state.CustomerGroups.error,
    customerGroupsMessage: state.CustomerGroups.message,
    customerGroupsLimit: state.CustomerGroups.limit,
    customerGroupsTotal: state.CustomerGroups.total,
    customerGroupsPage: state.CustomerGroups.page,
  }));

  // modal state
  const [deleteModal, setDeleteModal] = useState(false);
  const [isExportCSV, setIsExportCSV] = useState(false);

  const [selectedCustomerGroup, setSelectedCustomerGroup] = useState(null);

  const handleDeleteCustomerGroup = () => {
    dispatch(deleteCustomerGroup(selectedCustomerGroup, handleAfterDelete));
  };
  const handleAfterDelete = () => {
    setSelectedCustomerGroup(null);
    setDeleteModal(false);
  };
  const onClickDelete = (data) => {
    setSelectedCustomerGroup(data);
    setDeleteModal(true);
  };
  const onClose = () => {
    if (!customerGroupsLoading) {
      setSelectedCustomerGroup(null);
      setDeleteModal(false);
    }
  };

  return (
    <Fragment>
      <div className="page-content">
        <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={customerGroups} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteCustomerGroup}
          loading={customerGroupsLoading && customerGroupsAction === Actions.DELETE}
          onCloseClick={onClose}
        />
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card id="customerGroupList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">{t("Customer Group List")}</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex gap-3 flex-wrap">
                        <ButtonTheme
                          type="button"
                          className="btn btn-light"
                          icon="ri-file-text-line"
                          onClick={() => setIsExportCSV(true)}
                        >
                          {t("Export")}
                        </ButtonTheme>
                        <ButtonTheme
                          className="btn btn-primary"
                          id="create-btn"
                          icon="ri-add-line text-white"
                          onClick={() => {
                            navigate("/customer-groups/new");
                          }}
                        >
                          {t("Add Customer Group")}
                        </ButtonTheme>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <TableContainerCustom
                    filterType={"customerGroups"}
                    searchPlaceHolder="Search customer groups..."
                    listFilter={[]}
                    headers={[
                      "name",
                      "image",
                      "min_purchased_amount",
                      "discount_percent",
                      "total_member",
                      "updated_at",
                      "created_at",
                      "action",
                    ]}
                    contents={customerGroups}
                    limitPage={Number(customerGroupsLimit)}
                    currentPage={Number(customerGroupsPage) + 1}
                    totalItems={customerGroupsTotal}
                    loading={customerGroupsLoading && customerGroupsAction === Actions.LIST}
                    showPagination={true}
                    scrollHeight="50vh"
                    onCallData={(dataPayload) => {
                      dispatch(listCustomerGroup(dataPayload));
                    }}
                    listHeader={[
                      {
                        title: t("Customer group details"),
                        key: "detail-customer-group",
                        children: [
                          {
                            title: t("Name"),
                            dataIndex: "name",
                            key: "name",
                            sorter: true,
                            render: (value, row, index) => {
                              return (
                                <Link
                                  to={`/customer-groups/${row.id}`}
                                  className="d-block text-truncate link-primary fs-14 h5 mb-0"
                                >
                                  {t(row.name)}
                                </Link>
                              );
                            },
                          },
                          {
                            title: t("Image"),
                            dataIndex: "image",
                            width: "90px",
                            key: "image",
                            render: (value, row, index) => {
                              return (
                                <ImageCustom image={row.image?.url} name={row.name} avatarSize={"avatar-xs"} />
                              );
                            },
                          },

                          {
                            title: "Spending condition",
                            dataIndex: "min_purchased_amount",
                            key: "min_purchased_amount",
                            sortKey: "min_purchased_amount",
                            sorter: true,
                            render: (value, row, index) => {
                              return <div className="d-block text-truncate">{formatVNDCurrency(value ?? 0)}</div>;
                            },
                          },
                          {
                            title: "Discount percent",
                            dataIndex: "discount_percent",
                            key: "discount_percent",
                            sortKey: "discount_percent",
                            sorter: true,
                            render: (value, row, index) => {
                              return <div className="d-block text-truncate">{value ?? 0}%</div>;
                            },
                          },
                          {
                            title: "Total member",
                            dataIndex: "total_member",
                            key: "total_member",
                          },
                          {
                            title: t("Updated at"),
                            dataIndex: "updated_at",
                            key: "updated_at",
                            sorter: true,
                          },
                          {
                            title: t("Created at"),
                            dataIndex: "created_at",
                            key: "created_at",
                            sorter: true,
                            defaultSortOrder: "desc",
                          },
                          {
                            title: t("Action"),
                            dataIndex: "action",
                            key: "action",
                            render: (value, row, index) => {
                              return (
                                <ActionDropdown
                                  index={index}
                                  actions={[
                                    {
                                      type: "view",
                                      title: "View list customers",
                                      onClick: () => {
                                        navigate(`/customers?customer_group.id=${row.id}`);
                                      },
                                    },
                                    {
                                      type: "edit",
                                      onClick: () => {
                                        navigate(`/customer-groups/${row.id}`);
                                      },
                                    },
                                    {
                                      type: "delete",
                                      onClick: () => {
                                        onClickDelete(row);
                                      },
                                    },
                                  ]}
                                />
                              );
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};
export default withRouter(CustomerGroupPage);
