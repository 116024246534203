import { Tooltip } from "antd";
import { Col, PopoverBody, Row, UncontrolledPopover } from "reactstrap";
import { productsAPI } from "../../../../../helpers/resource_helper";
import { Fragment, useEffect, useState } from "react";
import Loader from "../../../../../Components/Common/Loader";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { formatVNDCurrency } from "../../../../../helpers/format_helper";

const PopoverProductInfo = ({ type, index, row }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const getData = async () => {
    try {
      setLoading(true);
      const res = await productsAPI.get({ id: row.id });
      setData(res);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div id={`${type}-${index}`} className="text-center">
      <span className="cursor-pointer text-primary">{(data?.variants && data?.variants.length) || 0}</span>
      <UncontrolledPopover
        trigger="hover"
        target={`${type}-${index}`}
        placement="bottom"
        // innerClassName="custom-popover"
        popperClassName="custom-popover"
      >
        <PopoverBody>
          {loading ? (
            <Loader />
          ) : data ? (
            <SimpleBar style={{ maxHeight: "500px" }}>
              <div>
                <h5 className="text-center">{data?.name || "---"}</h5>
                {data?.variants && data?.variants.length > 0 ? (
                  data.variants.map((item, idx) => {
                    return (
                      <div key={idx} className="hstack p-2 gap-2 border">
                        <div className="d-flex align-items-center flex-shrink-0">
                          <img src={item?.images?.[0]?.url} className="me-3 avatar-xs " alt="" />
                        </div>
                        <div className="flex-grow-1">
                          <p className="mb-0">{item.sku}</p>
                          <Row>
                            <Col>Option 1: {item.option1 || "---"}</Col>
                            <Col>Option 2: {item.option2 || "---"}</Col>
                            <Col>Option 3: {item.option3 || "---"}</Col>
                          </Row>
                        </div>
                        <div className="px-2 flex-shrink-0">
                          <div className="fs-16 text-primary text-end">{formatVNDCurrency(item.prices?.[0]?.price)}</div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="hstack p-2 gap-2 border">
                    <div className="d-flex align-items-center flex-shrink-0">
                      <img src={data?.images?.[0]?.url} className="me-3 avatar-xs " alt="" />
                    </div>
                    <div className="flex-grow-1">
                      <p className="mb-0">{data.sku}</p>
                    </div>
                  </div>
                )}
              </div>
            </SimpleBar>
          ) : (
            "Product not found"
          )}
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
};
export default PopoverProductInfo;
