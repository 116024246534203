import { Fragment, useEffect, useState } from "react";
import { Input } from "reactstrap";
import { isEmpty } from "lodash";

const DiscountDecimal = ({
  data,
  index,
  subIndex = null,
  field,
  onChangeConditionLine = () => {},
  onChangeQuantityLine = () => {},
  className,
  disabled = false,
}) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(data?.[field]);
  }, [data]);

  return (
    <Fragment>
      <Input
        type="number"
        className={className}
        value={value}
        onChange={(e) => {
          setValue(isEmpty(e.target.value) ? null : Number(e.target.value));
        }}
        min={0}
        onBlur={(e) => {
          subIndex || subIndex === 0
            ? onChangeQuantityLine(index, subIndex, { [field]: value })
            : onChangeConditionLine(index, { [field]: value });
        }}
        disabled={disabled}
      />
    </Fragment>
  );
};
export default DiscountDecimal;
