import { Actions } from "../../../../Components/constants/common";
import {
  LIST_ORDER,
  DELETE_ORDER,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAIL,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FAIL,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  LIST_ORDER_SUCCESS,
  LIST_ORDER_FAIL,
  ADD_NEW_ORDER,
  UPDATE_ORDER,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAIL,
  RESET_ORDER_FLAG,
} from "./actionType";

const INIT_STATE = {
  orders: [],
  order: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const Orders = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_ORDER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        orders: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_ORDER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        order: action.payload,
        action: Actions.EMPTY,
      };

    case GET_ORDER_FAIL:
      return {
        ...state,
        order: {},
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_ORDER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_ORDER_SUCCESS:
      return {
        ...state,
        message: "Delete order success",
        loading: false,
        success: true,
        orders: state.orders.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_ORDER_FAIL:
      return {
        ...state,
        message: "Delete order failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_NEW_ORDER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_ORDER_SUCCESS:
      return {
        ...state,
        message: "Add order success",
        success: true,
        loading: false,
        order: action.payload,
        orders: [...state.orders, action.payload],
        action: Actions.EMPTY,
      };

    case ADD_ORDER_FAIL:
      return {
        ...state,
        message: "Add order failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_ORDER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.UPDATE,
      };
    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        message: "Update order success",
        loading: false,
        success: true,
        order: action.payload,
        orders: state.orders.map((item) => (item.id === action.payload.id ? { ...item, ...action.payload } : item)),
        action: Actions.EMPTY,
      };

    case UPDATE_ORDER_FAIL:
      return {
        ...state,
        message: "Update order failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case RESET_ORDER_FLAG:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Orders;
