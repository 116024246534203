// Actions
export const LIST_LOYALTY_PROGRAM = "LIST_LOYALTY_PROGRAM";
export const LIST_LOYALTY_PROGRAM_SUCCESS = "LIST_LOYALTY_PROGRAM_SUCCESS";
export const LIST_LOYALTY_PROGRAM_FAIL = "LIST_LOYALTY_PROGRAM_FAIL";

export const GET_LOYALTY_PROGRAM = "GET_LOYALTY_PROGRAM";
export const GET_LOYALTY_PROGRAM_SUCCESS = "GET_LOYALTY_PROGRAM_SUCCESS";
export const GET_LOYALTY_PROGRAM_FAIL = "GET_LOYALTY_PROGRAM_FAIL";

/**
 * Delete LOYALTY_PROGRAM
 */
export const DELETE_LOYALTY_PROGRAM = "DELETE_LOYALTY_PROGRAM";
export const DELETE_LOYALTY_PROGRAM_SUCCESS = "DELETE_LOYALTY_PROGRAM_SUCCESS";
export const DELETE_LOYALTY_PROGRAM_FAIL = "DELETE_LOYALTY_PROGRAM_FAIL";

/**
 * Add LOYALTY_PROGRAM
 */
export const ADD_NEW_LOYALTY_PROGRAM = "ADD_NEW_LOYALTY_PROGRAM";
export const ADD_LOYALTY_PROGRAM_SUCCESS = "ADD_LOYALTY_PROGRAM_SUCCESS";
export const ADD_LOYALTY_PROGRAM_FAIL = "ADD_LOYALTY_PROGRAM_FAIL";

/**
 * Edit LOYALTY_PROGRAM
 */
export const UPDATE_LOYALTY_PROGRAM = "UPDATE_LOYALTY_PROGRAM";
export const UPDATE_LOYALTY_PROGRAM_SUCCESS = "UPDATE_LOYALTY_PROGRAM_SUCCESS";
export const UPDATE_LOYALTY_PROGRAM_FAIL = "UPDATE_LOYALTY_PROGRAM_FAIL";

export const RESET_LOYALTY_PROGRAM_FLAG = "RESET_LOYALTY_PROGRAM_FLAG";

