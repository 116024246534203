import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";


// Redux States
import { LIST_DISCOUNT, DELETE_DISCOUNT, ADD_NEW_DISCOUNT, UPDATE_DISCOUNT, GET_DISCOUNT } from "./actionType";

import {
  deleteDiscountSuccess,
  deleteDiscountFail,
  addDiscountSuccess,
  addDiscountFail,
  updateDiscountSuccess,
  updateDiscountFail,
  listDiscountSuccess,
  listDiscountFail,
  getDiscountSuccess,
  getDiscountFail,
} from "./action";

import { discountsAPI } from "../../../../helpers/resource_helper";

function* listDiscount({ payload: { ...res } }) {
  try {
    const response = yield call(discountsAPI.list, { ...res });
    yield put(listDiscountSuccess(response));
  } catch (error) {
    yield put(listDiscountFail(error));
  }
}

function* getDiscount({ payload: id }) {
  try {
    const response = yield call(discountsAPI.get, { id });
    yield put(getDiscountSuccess(response));
  } catch (error) {
    yield put(getDiscountFail(error));
  }
}

function* deleteDiscount({ payload: discount }) {
  try {
    const response = yield call(discountsAPI.delete, discount);
    yield put(deleteDiscountSuccess(discount));
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteDiscountFail(error));
    toast.error("Discount Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewDiscount({ payload: discount }) {
  try {
    const response = yield call(discountsAPI.create, discount);
    yield put(addDiscountSuccess(response));
    toast.success(response?.message, { autoClose: 3000 });
    toast.success("Discount Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addDiscountFail(error));
    toast.error("Discount Added Failed", { autoClose: 3000 });
  }
}

function* onUpdateDiscount({ payload: discount }) {
  try {
    const response = yield call(discountsAPI.put, discount);
    yield put(updateDiscountSuccess(response));
    toast.success("Discount Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateDiscountFail(error));
    toast.error("Discount Updated Failed", { autoClose: 3000 });
  }
}

export function* watchGetDiscounts() {
  yield takeEvery(LIST_DISCOUNT, listDiscount);
}

export function* watchDeleteDiscount() {
  yield takeEvery(DELETE_DISCOUNT, deleteDiscount);
}

export function* watchGetDiscount() {
  yield takeEvery(GET_DISCOUNT, getDiscount);
}

export function* watchUpdateDiscount() {
  yield takeEvery(UPDATE_DISCOUNT, onUpdateDiscount);
}

export function* watchAddNewDiscount() {
  yield takeEvery(ADD_NEW_DISCOUNT, onAddNewDiscount);
}

function* discountsSaga() {
  yield all([
    fork(watchGetDiscount),
    fork(watchGetDiscounts),
    fork(watchUpdateDiscount),
    fork(watchAddNewDiscount),
    fork(watchDeleteDiscount),
  ]);
}

export default discountsSaga;
