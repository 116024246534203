import { Fragment } from "react";
import { Card, CardBody, Input, Label } from "reactstrap";
import { EffectiveProduct, EffectiveProductOptions } from "../../../../../Components/constants/common";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import DiscountDecimal from "./Columns/DiscountDecimal";
import DiscountInfoSearchProduct from "../../../../../Components/Common/DiscountInfoSearchProduct";
import SimpleBar from "simplebar-react";
import { useTranslation } from "react-i18next";

const GiftByPurchaseProduct = ({
  onDeleteConditionLine = () => {},
  onChangeConditionLine = () => {},
  onChangeConditionInfo = () => {},
  validation,
  data = null,
  isEdit = true,
}) => {
  const { t } = useTranslation();
  const handleChangePurchaseProductType = (data) => {
    const newData = (validation?.values?.gift_by_purchase_product || []).map((item, idx) => {
      item.purchase_products = null;
      item.purchase_variants = null;
      item.purchase_brands = null;
      item.purchase_categories = null;
      if (item?.for_all_purchase_product) {
        item.for_all_purchase_product = false;
      }
      return { ...item, ...data };
    });
    validation.setFieldValue("gift_by_purchase_product", newData);
  };

  return (
    <Fragment>
      {isEdit && (
        <Fragment>
          <div className="mb-3">
            <Label>{t("Effective product")}</Label>
            <select
              className="form-select mb-3"
              onChange={(e) => {
                handleChangePurchaseProductType({ purchase_product_type: e.target.value });
              }}
              value={data?.[0]?.purchase_product_type || EffectiveProduct.PRODUCTS}
            >
              {EffectiveProductOptions.map((item) => (
                <option key={item.id} value={item.id}>
                  {t(item.name)}
                </option>
              ))}
            </select>
          </div>
          <div className="form-check mb-3">
            <Input
              className="form-check-input"
              type="checkbox"
              id="stackable-input"
              checked={!!validation.values?.is_stackable}
              onChange={(e) => {
                validation.setFieldValue("is_stackable", e.target.checked);
              }}
            />
            <Label className="form-check-label" for="stackable-input">
              {t("Allow to apply use condition many times in 1 order")}
            </Label>
          </div>
        </Fragment>
      )}
      <SimpleBar style={{ maxHeight: "600px" }}>
        {data &&
          data.map((item, index) => (
            <Fragment key={index}>
              <Card className="bg-light mb-3">
                <CardBody>
                  <Card>
                    <CardBody>
                      <table className="table table-nowrap">
                        <thead>
                          <tr>
                            <th scope="col">{t("Purchased product")}</th>
                            <th scope="col" className="text-center col-2">
                              {t("Quantity")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <DiscountInfoSearchProduct
                                isEdit={isEdit}
                                for_all_items={item?.for_all_purchase_product}
                                type="purchase"
                                item_type={item?.purchase_product_type}
                                data={item}
                                index={index}
                                onChangeConditionLine={onChangeConditionLine}
                              />
                            </td>
                            <td>
                              <DiscountDecimal
                                data={item}
                                index={index}
                                field={"purchase_product_quantity"}
                                onChangeConditionLine={onChangeConditionLine}
                                className={"text-center"}
                                disabled={!isEdit}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      {isEdit && (
                        <div>
                          <Label>{t("Effective sale offs product")}</Label>
                          <select
                            className="form-select mb-3"
                            onChange={(e) => {
                              onChangeConditionLine(index, {
                                sale_offs_product_type: e.target.value,
                                sale_offs_products: null,
                                sale_offs_variants: null,
                                sale_offs_categories: null,
                                sale_offs_brands: null,
                                for_all_sale_offs_product: false,
                              });
                            }}
                            value={item?.sale_offs_product_type || EffectiveProduct.PRODUCTS}
                          >
                            {EffectiveProductOptions.map((subItem) => (
                              <option key={subItem.id} value={subItem.id}>
                                {t(subItem.name)}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      <table className="table table-nowrap">
                        <thead>
                          <tr>
                            <th scope="col">{t("Sale offs product")}</th>
                            <th scope="col" className="text-center col-2">
                              {t("Max quantity")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <DiscountInfoSearchProduct
                                for_all_items={item?.for_all_sale_offs_product}
                                type="sale_offs"
                                item_type={item?.sale_offs_product_type}
                                data={item}
                                index={index}
                                onChangeConditionLine={onChangeConditionLine}
                                isEdit={isEdit}
                              />
                            </td>
                            <td>
                              <DiscountDecimal
                                data={item}
                                index={index}
                                field={"sale_offs_product_max_quantity"}
                                onChangeConditionLine={onChangeConditionLine}
                                className={"text-center"}
                                disabled={!isEdit}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                  {isEdit && (
                    <ButtonTheme
                      className="btn btn-outline-danger float-end"
                      onClick={() => {
                        onDeleteConditionLine(index);
                      }}
                    >
                      {t("Remove condition")}
                    </ButtonTheme>
                  )}
                </CardBody>
              </Card>
            </Fragment>
          ))}
      </SimpleBar>
    </Fragment>
  );
};
export default GiftByPurchaseProduct;
