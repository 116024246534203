import { Fragment, useEffect, useMemo, useState } from "react";
import SimpleTableContainer from "../../../../Components/Common/SimpleTableContainer";
import { Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { Tooltip } from "antd";
import {
  deepCopy,
  formatVNDCurrency,
  handleReturnQuantityByItemType,
  returnDiscountInfoFieldByType,
} from "../../../../helpers/format_helper";
import { DiscountAmountType, DiscountTypes, EffectiveProduct } from "../../../../Components/constants/common";
import OrderTotalConditionBody from "./OrderTotalConditionBody";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../../Components/Common/Loader";
import { useDebounce } from "../../../../Components/Hooks/UseDebounce";
import InputStep from "../../../../Components/Common/InputStep";
import { toast } from "react-toastify";
import { variantsAPI } from "../../../../helpers/resource_helper";
import { useTranslation } from "react-i18next";

const PurchasedConditionBody = ({
  discount,
  selectedPromotionId,
  order_line_items,
  discountDetails,
  onChangeTempDiscountDetails,
  onChangeSelectedPromotionId,
  selectedLocation,
  selectedPriceGroup,
}) => {
  const { t } = useTranslation();
  const [effectiveConditions, setEffectiveConditions] = useState([]);
  const [tempDiscountDetails, setTempDiscountDetails] = useState([]);
  const [selectedLines, setSelectedLines] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setSelectedCondition(null);
    setIsOpen(false);
  };
  const onCheck = (condition_index, data) => {
    if (!selectedLines.includes(condition_index)) {
      setSelectedLines([...selectedLines, condition_index]);
      const newDiscountDetails = tempDiscountDetails.concat(data);
      onChangeTempDiscountDetails(newDiscountDetails);
    } else {
      setSelectedLines(selectedLines.filter((item) => item !== condition_index));
      const newDiscountDetails = tempDiscountDetails.filter((item) => item.condition_index === condition_index);
      onChangeTempDiscountDetails(newDiscountDetails);
    }
  };

  const handleChangeTempDiscountDetails = (index, data) => {
    const newData = tempDiscountDetails.filter((item) => item.condition_index !== index).concat(data);
    if (newData.length > 0) {
      if (!selectedLines.includes(index)) {
        setSelectedLines([...selectedLines, index]);
      }
    } else {
      setSelectedLines(selectedLines.filter((item) => item !== index));
    }
    setTempDiscountDetails(newData);
    onChangeTempDiscountDetails(newData);
  };

  useEffect(() => {
    const conditions = discount[returnDiscountInfoFieldByType(discount?.type)];
    // check discount by product condition
    let newEffectiveConditions = [];
    const new_order_line_items = deepCopy(order_line_items);

    const for_all_items = conditions?.[0]?.for_all_purchase_product;
    if (typeof for_all_items === "boolean") {
      conditions.forEach((c, index) => {
        if (for_all_items) {
          const total_quantity = new_order_line_items.reduce(
            (total, order_line_item) => total + Number(order_line_item.quantity),
            0,
          );
          if (c.purchase_product_quantity <= total_quantity) {
            newEffectiveConditions = deepCopy(conditions);
          }
        } else {
          const total_quantity = handleReturnQuantityByItemType(c, new_order_line_items, "purchase_");
          if (c.purchase_product_quantity <= total_quantity) {
            c.condition_index = index;
            newEffectiveConditions.push(c);
          }
        }
      });
    }
    setEffectiveConditions(newEffectiveConditions);
  }, [order_line_items, discount]);

  useEffect(() => {
    if (selectedPromotionId !== discount?.id) {
      setTempDiscountDetails([]);
      setSelectedLines([]);
    } else {
      const newSelectedLines = discountDetails.reduce((total, item) => {
        if (!total.includes(item.condition_index)) {
          total.push(item.condition_index);
        }
        return total;
      }, []);

      setTempDiscountDetails(discountDetails);
      setSelectedLines(newSelectedLines);
    }
  }, []);

  useEffect(() => {
    onChangeSelectedPromotionId(selectedLines.length > 0 ? discount.id : null);
  }, [selectedLines]);

  return (
    <Fragment>
      <ChildModal
        isOpen={isOpen}
        toggle={toggle}
        discount={discount}
        selectedLocation={selectedLocation}
        selectedPriceGroup={selectedPriceGroup}
        effectiveCondition={selectedCondition}
        onChangeTempDiscountDetails={handleChangeTempDiscountDetails}
        discountDetails={tempDiscountDetails.filter(
          (item) => selectedCondition && item?.condition_index === selectedCondition.condition_index,
        )}
        order_line_items={order_line_items}
      />
      <div className="table-responsive">
        <Table className="table mb-0">
          <thead className="table-light text-muted">
            <tr>
              <th></th>
              <th>{t("Purchased products")}</th>
              <th className="text-center border-end">{t("Quantity")}</th>
              <th>Sale off products</th>
              <th className="text-center">Sale off quantity</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {effectiveConditions.map((item, index) => {
              const purchasedProductsTag = item[`purchase_${item.purchase_product_type.toLowerCase()}`];
              const saleOffProductsTag = tempDiscountDetails.filter(
                (subItem) => subItem?.condition_index === item.condition_index,
              );
              return (
                <tr
                  key={index}
                  className="cursor-pointer"
                  onClick={(e) => {
                    onCheck(item.condition_index, saleOffProductsTag);
                  }}
                >
                  <td>
                    <Input
                      id={`condition-${index}`}
                      type="checkbox"
                      value={""}
                      checked={selectedLines.includes(item.condition_index)}
                      onChange={() => {}}
                      onClick={(e) => {
                        onCheck(item.condition_index, saleOffProductsTag);
                      }}
                    />
                  </td>
                  <td>
                    <div className="d-flex flex-wrap gap-2">
                      {purchasedProductsTag.map((subItem, subIndex) => {
                        return (
                          <span key={subIndex} className="bg-soft-primary rounded-4 px-2 py-1">
                            {subItem.name}
                          </span>
                        );
                      })}
                    </div>
                  </td>
                  <td className="text-center border-end">{item.purchase_product_quantity}</td>
                  {saleOffProductsTag.length === 0 ? (
                    <td colSpan={3}>
                      <div
                        className="cursor-pointer text-primary text-center"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSelectedCondition(item);
                          setIsOpen(true);
                        }}
                      >
                        {`Choose ${
                          discount?.type === DiscountTypes.GIFT_BY_PURCHASE_PRODUCT ? "gift" : "discounted"
                        } product`}
                      </div>
                    </td>
                  ) : (
                    <Fragment>
                      <td>
                        <div className="d-flex flex-wrap gap-2">
                          {saleOffProductsTag.map((subItem, subIndex) => {
                            return (
                              <span key={subIndex} className="bg-soft-primary rounded-4 px-2 py-1">
                                {subItem.name}
                              </span>
                            );
                          })}
                        </div>
                      </td>
                      <td className="text-center">
                        {saleOffProductsTag.reduce((total, subItem) => total + Number(subItem.quantity), 0)}
                      </td>
                      <td>
                        <span
                          className="cursor-pointer text-primary"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setSelectedCondition(item);
                            setIsOpen(true);
                          }}
                        >
                          Choose again
                        </span>
                      </td>
                    </Fragment>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
};
export default PurchasedConditionBody;

const ChildModal = ({
  effectiveCondition,
  discount,
  toggle,
  isOpen,
  selectedPriceGroup,
  selectedLocation,
  onChangeTempDiscountDetails,
  discountDetails,
  order_line_items,
}) => {
  const { t } = useTranslation();
  const [listVariants, setListVariants] = useState([]);
  const [payload, setPayload] = useState(null);
  const [tempDiscountDetails, setTempDiscountDetails] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [valueSearch, setValueSearch] = useState("");
  const debouncedInputValue = useDebounce(valueSearch, 1000);

  const onCheck = (row) => {
    const newDiscountDetails = [...tempDiscountDetails];
    const discountDetailsIndex = newDiscountDetails.findIndex((item) => item.sku === row.sku);
    if (discountDetailsIndex > -1) {
      newDiscountDetails.splice(discountDetailsIndex, 1);
    } else {
      if (selectedQuantity === maxSelectedQuantity) {
        toast.error(`${t("Max quantity is")} ${maxSelectedQuantity}`);
        return;
      }
      const selectedProductPriceGroupIndex = row.prices.findIndex((item) => item.price_group.id === selectedPriceGroup);
      const selectedProductPrice =
        selectedProductPriceGroupIndex > -1
          ? row?.prices?.[selectedProductPriceGroupIndex]?.price
          : row?.prices?.[0]?.price;
      const sale_price = [DiscountTypes.GIFT_BY_ORDER_TOTAL, DiscountTypes.GIFT_BY_PURCHASE_PRODUCT].includes(
        discount?.type,
      )
        ? 0
        : selectedProductPrice -
          (effectiveCondition?.type === DiscountAmountType.VALUE
            ? effectiveCondition?.amount
            : (effectiveCondition?.rate * selectedProductPrice) / 100);
      const newItem = {
        condition_index: effectiveCondition?.condition_index,
        unit_price: selectedProductPrice || 0,
        sale_price: sale_price || 0,
        sku: row?.sku || "",
        name: row?.name || "",
        variant_name: row?.name || "",
        location_id: selectedLocation.value || null,
        location_name: selectedLocation.label || null,
        location: selectedLocation || null,
        image_url: row.images?.[0]?.url || "",
        quantity: 1,
        product_id: row.product_id || null,
        variant_id: row.id || null,
        category: row?.category ? { id: row?.category?.id, name: row?.category?.name } : null,
        brand: row?.brand ? { id: row?.brand?.id, name: row?.brand?.name } : null,
      };
      newDiscountDetails.push(newItem);
    }
    setTempDiscountDetails(newDiscountDetails);
  };

  const selectedQuantity = useMemo(
    () => tempDiscountDetails.reduce((total, item) => total + +item.quantity, 0),
    [tempDiscountDetails],
  );

  const maxSelectedQuantity = useMemo(() => {
    if (discount.is_stackable && effectiveCondition) {
      const total_quantity = handleReturnQuantityByItemType(effectiveCondition, order_line_items, "purchase_");
      return (
        Math.floor(total_quantity / effectiveCondition?.purchase_product_quantity) *
        effectiveCondition?.sale_offs_product_max_quantity
      );
    }
    return effectiveCondition?.sale_offs_product_max_quantity;
  }, [effectiveCondition]);

  const onChangeQuantity = (number, productIndex) => {
    const newDiscountDetails = deepCopy(tempDiscountDetails);
    const discountDetailsIndex = newDiscountDetails.findIndex((item, index) => index === productIndex);
    if (discountDetailsIndex > -1) {
      newDiscountDetails[discountDetailsIndex].quantity = number;
    }
    setTempDiscountDetails(newDiscountDetails);
  };

  const handleApply = () => {
    if (tempDiscountDetails.length === 0) {
      toast.error(t("Please add product"));
      return;
    }
    const newData = tempDiscountDetails.filter((item) => item.quantity > 0);
    onChangeTempDiscountDetails(effectiveCondition?.condition_index, newData);
    toggle();
  };

  const getDataScroll = async (query, page) => {
    try {
      const res = await variantsAPI.list({
        limit: 20,
        ...payload,
        ...(page > 0 && { page }),
        ...(query && { query }),
      });
      setPage(Number(res.page));
      setListVariants([...listVariants, ...res.items]);

      if (Number(res.limit) * (Number(res.page) + 1) < res.total) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setTempDiscountDetails(discountDetails || []);
    } else {
      setTempDiscountDetails([]);
    }
  }, [isOpen, discountDetails]);

  useEffect(() => {
    if (typeof effectiveCondition?.for_all_sale_offs_product === "boolean") {
      if (effectiveCondition?.for_all_sale_offs_product) {
        setPayload({});
      } else {
        const listId = effectiveCondition[`sale_offs_${effectiveCondition?.sale_offs_product_type.toLowerCase()}`]
          .map((item) => item.id)
          .join(",");
        if (effectiveCondition?.sale_offs_product_type === EffectiveProduct.PRODUCTS) {
          const newPayload = {};
          newPayload["product_id"] = listId;
          setPayload(newPayload);
        }
        if (effectiveCondition?.sale_offs_product_type === EffectiveProduct.VARIANTS) {
          setListVariants(effectiveCondition?.variants);
        }
        if (effectiveCondition?.sale_offs_product_type === EffectiveProduct.CATEGORIES) {
          const newPayload = {};
          newPayload["category.id"] = listId;
          setPayload(newPayload);
        }
        if (effectiveCondition?.sale_offs_product_type === EffectiveProduct.BRANDS) {
          const newPayload = {};
          newPayload["brand.id"] = listId;
          setPayload(newPayload);
        }
      }
    }
  }, [effectiveCondition]);

  const getData = async (query) => {
    try {
      setLoading(true);
      const res = await variantsAPI.list({
        limit: 20,
        ...(query && { query }),
        ...payload,
      });
      setPage(Number(res.page));
      setListVariants(res.items);
      if (Number(res.limit) * (Number(res.page) + 1) < res.total) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (payload) {
      getData(valueSearch);
    }
  }, [payload]);

  useEffect(() => {
    if (typeof effectiveCondition?.for_all_items === "boolean") {
      if (!effectiveCondition?.for_all_items) {
        if (effectiveCondition?.item_type === EffectiveProduct.VARIANTS) {
          const newList = effectiveCondition?.variants.filter(
            (item) => item.sku.includes(debouncedInputValue) || item.name.includes(debouncedInputValue),
          );
          setListVariants(newList);
        }
      }
      if (payload) {
        getData(debouncedInputValue);
      }
    }
  }, [debouncedInputValue]);

  return (
    <Modal isOpen={isOpen} size="xl">
      <ModalHeader toggle={toggle}>
        {t(discount?.type === DiscountTypes.BY_PURCHASE_PRODUCT ? "Choose discounted product" : "Choose gift product")}
      </ModalHeader>
      <ModalBody>
        <Fragment>
          {discount.is_stackable && (
            <div className="mb-3">
              {t(
                "Promotions allow 1 condition to be applied multiple times in an order, the quantity awarded is multiplied by the quantity purchased",
              )}
            </div>
          )}
          <Row>
            <Col lg={4}>
              <div className="bg-light p-2">{t("Product")}</div>
              <div>
                <div
                  id="scrollableDivSearch"
                  style={{
                    height: "500px",
                    overflow: "auto",
                  }}
                >
                  <div className="p-2 border">
                    <Input
                      value={valueSearch}
                      onChange={(e) => {
                        setValueSearch(e.target.value);
                      }}
                      placeholder={t("Search...")}
                    />
                  </div>
                  {loading ? (
                    <Loader />
                  ) : (
                    <InfiniteScroll
                      dataLength={listVariants.length}
                      next={() => {
                        getDataScroll(valueSearch, page + 1);
                      }}
                      hasMore={hasMore}
                      scrollableTarget="scrollableDivSearch"
                    >
                      {listVariants.length > 0 ? (
                        listVariants.map((item, index) => {
                          const selectedProductPriceGroupIndex = item.prices.findIndex(
                            (item) => item.price_group.id === selectedPriceGroup,
                          );
                          const price =
                            selectedProductPriceGroupIndex > -1
                              ? item?.prices?.[selectedProductPriceGroupIndex]?.price
                              : item?.prices?.[0]?.price;
                          const sale_price = [
                            DiscountTypes.PRODUCT_BY_ORDER_TOTAL,
                            DiscountTypes.BY_PURCHASE_PRODUCT,
                          ].includes(discount?.type)
                            ? price -
                              (effectiveCondition?.type === DiscountAmountType.VALUE
                                ? effectiveCondition?.amount
                                : (effectiveCondition?.rate * price) / 100)
                            : 0;
                          return (
                            <div key={index} className="d-flex border p-2">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                value={""}
                                onClick={(e) => {
                                  onCheck(item);
                                }}
                                onChange={(e) => {}}
                                id={`sale-off-product-${index}`}
                                checked={tempDiscountDetails.findIndex((subItem) => subItem.sku === item.sku) > -1}
                              />
                              <Label className="form-check-label ms-2 w-100 hstack" for={`sale-off-product-${index}`}>
                                <div className="flex-grow-1">
                                  <p>{t(item.name)}</p>
                                  <p className="text-muted">{item.sku}</p>
                                </div>
                                {[DiscountTypes.PRODUCT_BY_ORDER_TOTAL, DiscountTypes.BY_PURCHASE_PRODUCT].includes(
                                  discount?.type,
                                ) && (
                                  <div className="flex-shrink-0 text-end">
                                    <p>{formatVNDCurrency(sale_price)}</p>
                                    <p className="text-decoration-line-through text-muted">
                                      {formatVNDCurrency(price)}
                                    </p>
                                  </div>
                                )}
                              </Label>
                            </div>
                          );
                        })
                      ) : (
                        <div className="border p-2">
                          <p>{t("Sorry! No Result Found")}</p>
                        </div>
                      )}
                    </InfiniteScroll>
                  )}
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="bg-light p-2 d-flex justify-content-between">
                <span>{t("Selected product")}</span>
                <span>
                  {t("Selected quantity")}: {selectedQuantity}/{maxSelectedQuantity}
                </span>
              </div>
              <div>
                <div
                  id="scrollableDivSearch"
                  style={{
                    height: "500px",
                    overflow: "auto",
                  }}
                >
                  {tempDiscountDetails.map((item, index) => {
                    return (
                      <div key={index} className="hstack gap-2 p-2 border">
                        <div className="flex-grow-1">
                          <p>{t(item.name)}</p>
                          <p className="text-muted">{item.sku}</p>
                        </div>
                        {[DiscountTypes.PRODUCT_BY_ORDER_TOTAL, DiscountTypes.BY_PURCHASE_PRODUCT].includes(
                          discount?.type,
                        ) && (
                          <div className="flex-shrink-0 text-end me-2">
                            <p className="mb-0">{formatVNDCurrency(item.sale_price)}</p>
                            <p className="text-decoration-line-through text-muted mb-0">
                              {formatVNDCurrency(item.unit_price)}
                            </p>
                          </div>
                        )}
                        <InputStep
                          onChangeQuantity={onChangeQuantity}
                          index={index}
                          quantity={item.quantity}
                          max={
                            maxSelectedQuantity -
                            tempDiscountDetails
                              .filter((subItem) => subItem.sku !== item.sku)
                              .reduce((total, subItem) => total + +subItem.quantity, 0)
                          }
                        />
                        <span
                          className="cursor-pointer"
                          onClick={() => {
                            onCheck(item);
                          }}
                        >
                          <i className="ri-close-fill text-muted fs-22"></i>
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </Fragment>
      </ModalBody>
      <ModalFooter>
        <ButtonTheme className="btn btn-outline-primary w-md" onClick={toggle}>
          {t("Close")}
        </ButtonTheme>
        <ButtonTheme className="btn btn-primary w-md" onClick={handleApply}>
          {t("Apply")}
        </ButtonTheme>
      </ModalFooter>
    </Modal>
  );
};
