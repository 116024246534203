import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { getOrderPayQR } from "../../../../helpers/service_helper";
import Loader from "../../../../Components/Common/Loader";
import { formatDateTime, formatVNDCurrency } from "../../../../helpers/format_helper";
import withRouter from "../../../../Components/Common/withRouter";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import LottieIcon from "../../../../Components/Common/LottieIcon";

const QRModal = ({ router, isOpen, toggle, order_id, deleteCurrentOrder }) => {
  const { t, location, navigate, params } = router;

  const ref = useRef(null);
  const [error, setError] = useState("");
  const [data, setData] = useState(null);
  const [firstRun, setFirstRun] = useState(true);
  const [imageURI, setImageURI] = useState("");

  const toggleModal = () => {
    toggle();
    data?.paid && deleteCurrentOrder();
  };

  const firstLoadData = async () => {
    try {
      const payload = {
        account_number: "0988979251",
        account_name: "Mai Khanh Linh",
        bank_id: 970422,
        transaction_id: order_id,
      };
      const res = await getOrderPayQR(payload);
      if (res) {
        setData(res);
      }
      if (res?.data?.qr_url) {
        // Fetch the image URL
        const response = await fetch(res?.data?.qr_url);
        const blob = await response.blob();
        // Convert the image blob to base64
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64Data = reader.result;
          setImageURI(base64Data);
        };
      }
    } catch (e) {
      setError(String(e));
    } finally {
      setFirstRun(false);
    }
  };

  useEffect(() => {
    isOpen && firstLoadData();
  }, [isOpen]);

  useEffect(() => {
    if (firstRun) {
      return;
    }
    const intervalId = setInterval(async () => {
      try {
        const payload = {
          account_number: "0988979251",
          account_name: "Mai Khanh Linh",
          bank_id: 970422,
          transaction_id: order_id,
        };
        const res = await getOrderPayQR(payload);
        if (res.paid) {
          setData(res);
          clearInterval(intervalId);
        }
      } catch (e) {
        clearInterval(intervalId);
      }
    }, 3000);
    return () => clearInterval(intervalId);
  }, [firstRun]);

  return (
    <Modal isOpen={isOpen} size="lg" centered>
      <ModalHeader toggle={toggleModal}>OR Code</ModalHeader>
      <ModalBody>
        <Row className="justify-content-center">
          {firstRun ? (
            <Col md={8} lg={6} xl={5}>
              <div className="text-center mt-2 p-4">
                <h5 className="text-primary">
                  {t(`Searching for order number`)} {order_id} ...
                </h5>
                <div className="d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
                  <Loader />
                </div>
              </div>
            </Col>
          ) : error ? (
            <Col md={8} lg={6} xl={5}>
              <div className="text-center p-4 mt-2">
                <LottieIcon src="https://cdn.lordicon.com/msoeawqm.json" style={{ width: "42px", height: "42px" }} />
                <h4 className="text-uppercase">{t("Sorry, can not find order")} 😭</h4>
              </div>
            </Col>
          ) : data?.paid ? (
            <Col md={8} lg={6} xl={5}>
              <div className="p-4">
                <div className="text-center mt-2">
                  <h5 className="text-primary">
                    {t("Order number")}: {data?.order_number}
                  </h5>
                  <i className="ri-checkbox-circle-fill fs-48 text-success" />
                  <p className="text-muted">{t("Pay successfully!")}</p>
                </div>
                <div className="table-responsive mt-2">
                  <table className="table table-borderless align-middle mb-0">
                    <tbody>
                      <tr>
                        <td className="fw-bold">{t("Paid amount")}</td>
                        <td>{formatVNDCurrency(data?.paid_amount)}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">{t("Paid time")}</td>
                        <td>{formatDateTime(data?.paid_time)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          ) : (
            <Col>
              <div className="p-4" ref={ref}>
                <div className="text-center">
                  <h5 className="text-primary">
                    {t("Order number")}: {data?.order_number}
                  </h5>
                </div>
                <Row>
                  <Col lg={6}>
                    <img src={imageURI} alt="qr" className="w-100" />
                  </Col>
                  <Col lg={6} className="m-auto">
                    <div className="table-responsive">
                      <table className="table table-borderless align-middle mb-0">
                        <tbody>
                          <tr>
                            <td className="fw-bold">{t("Account name")}</td>
                            <td>{data?.data?.account_name}</td>
                          </tr>
                          <tr>
                            <td className="fw-bold">{t("Bank")}</td>
                            <td>{t("VietinBank")}</td>
                          </tr>
                          <tr>
                            <td className="fw-bold">{t("Bank account")}</td>
                            <td>{data?.data?.account_number}</td>
                          </tr>
                          <tr>
                            <td className="fw-bold">{t("Amount")}</td>
                            <td>{formatVNDCurrency(data?.data?.amount)}</td>
                          </tr>
                          <tr>
                            <td className="fw-bold">{t("QR note")}</td>
                            <td>{data?.data?.qr_note}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <ButtonTheme type="button" className="btn btn-light w-md" onClick={toggleModal}>
          {t("Close")}
        </ButtonTheme>
      </ModalFooter>
    </Modal>
  );
};

export default withRouter(QRModal);
