export const ORDER = {
  _list: "/orders",
  add: "/orders/new",
  update: "/orders/id/edit",
  get: "/orders/id",
  order_process: "/order-process",
  delete: true,
  pay_api: true,
  confirm_api: true,
  cancel_api: true,
  request_cancel_api: true,
  pack_api: true,
};

export const PACKAGE = {
  _list: "/packages",
  get: "/packages/id",
  cancel_api: true,
  await_packing_api: true,
  pack_api: true,
  ready_api: true,
  ship_api: true,
  delivered_api: true,
  return_api: true,
  delivered_return_api: true,
  blocking_api: true,
};

export const RETURN_ORDER = {
  _list: "/return-orders",
  get: "/return-orders/id",
  pay_api: true,
  receive_api: true,
  cancel_api: true,
};

export const CANCEL_PACKAGE = "package.cancel_api";
export const AWAIT_PACKING_PACKAGE = "package.await_packing_api";
export const PACK_PACKAGE = "package.pack_api";
export const READY_PACKAGE = "package.ready_api";
export const SHIP_PACKAGE = "package.ship_api";
export const DELIVERED_PACKAGE = "package.delivered_api";
export const RETURN_PACKAGE = "package.return_api";
export const DELIVERED_RETURN_PACKAGE = "package.delivered_return_api";
export const BLOCKING_PACKAGE = "package.blocking_api";

export const ADD_ORDER = "order.add";
export const UPDATE_ORDER = "order.update";
export const DELETE_ORDER = "order.delete";
export const CONFIRM_ORDER = "order.CONFIRM";
export const PAY_ORDER = "order.pay_api";
export const CANCEL_ORDER = "order.cancel_api";
export const REQUEST_CANCEL_ORDER = "order.cancel_api";
export const PACK_ORDER = "order.pack_api";
