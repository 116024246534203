import React, { Fragment, useState } from "react";
import { withTranslation } from "react-i18next";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

function ActionDropdown({ actions, t, type = "light" }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleDropdownToggle = (e) => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <UncontrolledDropdown isOpen={isDropdownOpen} toggle={handleDropdownToggle}>
      <DropdownToggle className={`btn btn-ghost-${type} btn-sm`} tag="div">
        <i className="ri-more-fill text-dark" />
      </DropdownToggle>
      <DropdownMenu container={`body`}>
        {actions.map((action, index) => (
          <Fragment key={index}>
            {!action?.hide && (
              <Fragment>
                <DropdownItem onClick={() => action.onClick()} className="d-flex align-items-center">
                  {action.type === "view" ? (
                    <>
                      <i className="ri-eye-fill align-bottom me-2 color-sub-text-secondary fs-18"></i>
                      <span className="color-sub-text fw-medium">{action?.title ? t(action?.title) : t("View")}</span>
                    </>
                  ) : action.type === "delete" ? (
                    <>
                      <i className="ri-delete-bin-fill align-bottom me-2 text-danger color-sub-text-secondary fs-18"></i>
                      <span className="color-sub-text fw-medium">{action?.title ? t(action?.title) : t("Delete")}</span>
                    </>
                  ) : action.type === "edit" ? (
                    <>
                      <i className=" ri-edit-2-line align-bottom me-2 color-sub-text-secondary fs-18"></i>
                      <span className="color-sub-text fw-medium">{action?.title ? t(action?.title) : t("Edit")}</span>
                    </>
                  ) : action.type === "add" ? (
                    <>
                      <i className="ri-add-fill align-bottom me-2 fs-18"></i>
                      <span className="color-sub-text fw-medium">{action?.title ? t(action?.title) : t("Add")}</span>
                    </>
                  ) : action.type === "settings" ? (
                    <>
                      <i className="ri-settings-5-line align-bottom me-2 fs-18"></i>
                      <span className="color-sub-text fw-medium">
                        {action?.title ? t(action?.title) : t("Settings")}
                      </span>
                    </>
                  ) : (
                    <>
                      <i className="ri-close-circle-fill align-bottom me-2 text-danger fs-18"></i>
                      <span className="color-sub-text fw-medium"> {t("Cancel")}</span>
                    </>
                  )}
                </DropdownItem>
                {index !== actions.length - 1 && <DropdownItem divider />}
              </Fragment>
            )}
          </Fragment>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default withTranslation()(ActionDropdown);
