import { useTranslation } from "react-i18next";
import { images } from "../../assets/images";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="px-3 text-center">
      <div>
        <img
          src={images.NoDataFilter}
          alt="No Data Found"
        ></img>
      </div>

      <div>
        <p>{t("Sorry! No Result Found")}</p>
      </div>
    </div>
  );
};
export default NotFound;
