import { Form, Formik } from "formik";
import * as Yup from "yup";
import withRouter from "../../../../../Components/Common/withRouter";
import useMemoizedSelector from "../../../../../Components/Hooks/useMemoizedSelector";
import { toast } from "react-toastify";
import { convertValidDate, convertValidTime, deepCopy, formatVNDCurrency } from "../../../../../helpers/format_helper";
import { useDispatch } from "react-redux";
import {
  addNewReturnOrder,
  getOrder,
  getReturnOrder,
  listLocation,
  listPaymentMethod,
  listPriceGroup,
} from "../../../../../store/actions";
import { Fragment, useEffect, useRef, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from "reactstrap";
import BreadCrumbCustom from "../../../../../Components/Common/BreadCrumbCustom";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import { updateReturnOrderAPI } from "../../../../../helpers/service_helper";
import { Link, useSearchParams } from "react-router-dom";
import ExchangeOrderCard from "./Components/ExchangeOrderCard";
import FullNameRender from "../../../../../Components/Common/FullnameRender";
import SelectTheme from "../../../../../Components/Common/SelectTheme";
import InputTheme from "../../../../../Components/Common/InputTheme";
import {
  OrderStatuses,
  PaymentStatuses,
  ReturnOrderReasonOptions,
  ReturnOrderStatuses,
} from "../../../../../Components/constants/common";
import InputPaymentField from "../../../../../Components/Common/InputPaymentField";
import ReturnOrderCard from "./Components/ReturnOrderCard";
import AddPaymentsReturnOrderModal from "./Components/AddPaymentsModal";
import BadgeCustom from "../../../../../Components/Common/BadgeCustom";
import ScrollToError from "../../../../../Components/Common/ScrollToError";

const ReturnOrderDetails = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  const { id } = params;
  document.title = `${t(id === "new" ? "Create" : "View")} ${t("Return Order Details")} | OptiWarehouse`;
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("order_id");
  const formRef = useRef();
  const { order, ordersLoading, ordersAction, ordersSuccess, ordersError, ordersMessage } = useMemoizedSelector(
    (state) => ({
      order: state.Orders.order,
      ordersLoading: state.Orders.loading,
      ordersAction: state.Orders.action,
      ordersSuccess: state.Orders.success,
      ordersError: state.Orders.error,
      ordersMessage: state.Orders.message,
    }),
  );
  const {
    returnOrder,
    returnOrdersLoading,
    returnOrdersAction,
    returnOrdersSuccess,
    returnOrdersError,
    returnOrdersMessage,
  } = useMemoizedSelector((state) => ({
    returnOrder: state.ReturnOrders.returnOrder,
    returnOrdersLoading: state.ReturnOrders.loading,
    returnOrdersAction: state.ReturnOrders.action,
    returnOrdersSuccess: state.ReturnOrders.success,
    returnOrdersError: state.ReturnOrders.error,
    returnOrdersMessage: state.ReturnOrders.message,
  }));

  const { paymentMethods, paymentMethodsLoading, paymentMethodsSuccess, paymentMethodsError } = useMemoizedSelector(
    (state) => ({
      paymentMethods: state.PaymentMethods.paymentMethods,
      paymentMethodsLoading: state.PaymentMethods.loading,
      paymentMethodsSuccess: state.PaymentMethods.success,
      paymentMethodsError: state.PaymentMethods.error,
    }),
  );

  const { priceGroups, priceGroupsLoading, priceGroupsSuccess, priceGroupsError } = useMemoizedSelector((state) => ({
    priceGroups: state.PriceGroups.priceGroups,
    priceGroupsLoading: state.PriceGroups.loading,
    priceGroupsSuccess: state.PriceGroups.success,
    priceGroupsError: state.PriceGroups.error,
  }));

  const { discount, discountsLoading, discountsSuccess, discountsError } = useMemoizedSelector((state) => ({
    discount: state.Discounts.discount,
    discountsLoading: state.Discounts.loading,
    discountsSuccess: state.Discounts.success,
    discountsError: state.Discounts.error,
  }));

  const [paymentModal, setPaymentModal] = useState(false);
  const togglePaymentModal = () => {
    statusLoading !== "pay" && setPaymentModal(false);
  };

  const validation = {
    enableReinitialize: true,

    initialValues: {
      order_id: order?.id || returnOrder?.order_id || null,
      exchanged_order_id: returnOrder?.exchanged_order_id || null,
      order_number: returnOrder?.order_number || null,
      return_order_number: returnOrder?.return_order_number || null,
      exchanged_order_number: returnOrder?.exchanged_order_number || null,
      billing_address: order?.billing_address || returnOrder?.billing_address || null,
      shipping_address: order?.shipping_address || returnOrder?.shipping_address || null,
      customer: order?.customer || returnOrder?.customer || null,
      return_order_line_items: returnOrder?.return_order_line_items || [],
      total: returnOrder?.total || 0,
      total_exchanged_amount: returnOrder?.total_exchanged_amount || null,
      payments: returnOrder?.payments || null,
      payment_histories: returnOrder?.payment_histories || null,
      status: returnOrder?.status || ReturnOrderStatuses.RECEIVED,
      note: returnOrder?.note || "",
      tags: returnOrder?.tags || "",
      location: order?.location || returnOrder?.location || null,
      staff: order?.staff || returnOrder?.staff || null,
      reason: returnOrder?.reason || null,
      exchanged_order: returnOrder?.exchanged_order || null,
    },
    onSubmit: (values) => {
      let payload = deepCopy(values);

      if (payload.exchanged_order) {
        payload.exchanged_order.order_line_items = payload.exchanged_order.order_line_items
          .filter((item) => item.quantity > 0)
          .map((item) => {
            const { available, ...res } = item;
            return res;
          });
      }
      if (!payload.customer) {
        toast.error(t("Required customer"), { autoClose: 3000 });
        return;
      }
      if (id === "new") {
        dispatch(
          addNewReturnOrder(payload, (data) => {
            navigate(`/return-orders/${data.id}`);
          }),
        );
      }
    },
  };

  const handleChangeReturnOrderLines = (data) => {
    formRef.current.setFieldValue("return_order_line_items", data);
    const totalReturnOrder = data.reduce((total, item) => total + item.quantity * item.return_price, 0);
    formRef.current.setFieldValue("total", totalReturnOrder);
  };

  const handleChangeExchangedOrder = (data) => {
    formRef.current.setFieldValue("exchanged_order", data);
    formRef.current.setFieldValue("total_exchanged_amount", data?.total || null);
  };

  const handleAddPayment = () => {
    let amountItems = formRef.current.values.return_order_line_items.reduce(
      (total, item) => total + item.quantity * item.return_price,
      0,
    );
    let newPayments = formRef.current.values.payments ? deepCopy(formRef.current.values.payments) : [];
    let amountPayments = (formRef.current.values.payments || []).reduce((total, item) => total + +item.amount, 0);
    if (amountItems - amountPayments > 0) {
      newPayments.push({
        payment_method_id: "",
        amount: amountItems - amountPayments,
      });
    } else {
      newPayments.push({ payment_method_id: "", amount: 0 });
    }
    formRef.current.setFieldValue("payments", newPayments);
  };

  const [statusLoading, setStatusLoading] = useState("");
  const handleUpdateStatus = async (action, data) => {
    try {
      setStatusLoading(action);
      const res = await updateReturnOrderAPI({ id: returnOrder.id, action, data });
      dispatch(getReturnOrder(id));
      if (res.message) {
        toast.success(res.message);
      } else {
        toast.success(`${action} successfully!`);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setStatusLoading("");
    }
  };

  useEffect(() => {
    if (id === "new") {
      if (orderId) {
        dispatch(getOrder(orderId));
      } else {
        navigate("/return-orders");
      }
    } else {
      dispatch(getReturnOrder(id));
    }
    dispatch(listPaymentMethod({ limit: 20 }));
    dispatch(listPriceGroup({ limit: 20 }));
  }, [dispatch, id]);

  useEffect(() => {
    if (!order?.id && orderId && ordersError) {
      navigate("/return-orders");
    } else if (order?.id && orderId && !ordersError) {
      if (order?.status !== OrderStatuses.COMPLETED) {
        navigate("/return-orders");
      }
      const new_order_line_items = deepCopy(order.order_line_items || []);
      const newData = new_order_line_items.map((item) => {
        item.quantity = 0;
        item.return_price = item.sale_price;
        return item;
      });
      formRef.current.setFieldValue("return_order_line_items", newData);
    }
  }, [order, orderId]);

  return (
    <div className="page-content" tabIndex="0">
      <Container fluid>
        <AddPaymentsReturnOrderModal
          isOpen={paymentModal}
          toggle={togglePaymentModal}
          paymentMethods={paymentMethods}
          total={returnOrder?.total || 0}
          onAddPayment={handleUpdateStatus}
          payLoading={statusLoading === "pay"}
          payments={returnOrder?.payments || []}
        />
        <BreadCrumbCustom navigateTo="/return-orders">
          {id === "new" ? (
            <div className="hstack gap-2">
              <ButtonTheme
                type="button"
                className="btn btn-light w-md"
                onClick={() => {
                  navigate("/return-orders");
                }}
                disabled={ordersLoading || returnOrdersLoading}
              >
                {t("Close")}
              </ButtonTheme>
              <ButtonTheme
                type="button"
                className="btn btn-outline-primary w-md"
                onClick={() => formRef.current.handleSubmit()}
                loading={returnOrdersLoading}
                disabled={ordersLoading || returnOrdersLoading}
              >
                {t("Create")}
              </ButtonTheme>
            </div>
          ) : (
            <div className="hstack gap-2">
              <ButtonTheme
                type="button"
                className="btn btn-outline-danger w-md"
                onClick={() => {
                  handleUpdateStatus("cancel", {});
                }}
                loading={statusLoading === "cancel"}
                disabled={
                  statusLoading === "cancel" ||
                  returnOrder?.status === ReturnOrderStatuses.CANCELLED ||
                  returnOrder?.status === ReturnOrderStatuses.RECEIVED
                }
              >
                {t("Cancel")}
              </ButtonTheme>
              {returnOrder?.status !== ReturnOrderStatuses.RECEIVED && (
                <ButtonTheme
                  type="button"
                  className="btn btn-outline-primary w-md"
                  onClick={() => {
                    handleUpdateStatus("receive", {});
                  }}
                  loading={statusLoading === "receive"}
                  disabled={
                    statusLoading === "receive" ||
                    returnOrder?.status === ReturnOrderStatuses.CANCELLED ||
                    returnOrder?.status === ReturnOrderStatuses.RECEIVED
                  }
                >
                  {t("Receive")}
                </ButtonTheme>
              )}
            </div>
          )}
        </BreadCrumbCustom>
        <Formik
          enableReinitialize={true}
          initialValues={validation.initialValues}
          validationSchema={validation.validationSchema}
          onSubmit={validation.onSubmit}
          innerRef={formRef}
        >
          {({ values, setFieldValue, errors, touched }) => {
            const refundAmount = values.return_order_line_items.reduce(
              (total, item) => total + item.return_price * item.quantity,
              0,
            );
            const exchangeAmount = values?.total_exchanged_amount || 0;
            const paymentAmount = values.payments
              ? values.payments.reduce((total, item) => total + +item.amount, 0)
              : 0;
            const amountStillDue = refundAmount - paymentAmount;
            const handleDeletePayment = (index) => {
              let newData = values.payments.filter((payment, i) => index !== i);
              setFieldValue("payments", newData.length > 0 ? newData : null);
            };
            const handleChangePayment = (data, index) => {
              let newData = deepCopy(values.payments);
              newData[index].payment_method_id = data;
              setFieldValue("payments", newData);
            };
            const handleChangePaymentPrice = (data, index) => {
              let newData = deepCopy(values.payments);
              let newAmountStillDue =
                refundAmount -
                newData.reduce((total, item, i) => {
                  if (i !== index) {
                    return total + +item.amount;
                  } else {
                    return total;
                  }
                }, 0);
              newData[index].amount = newAmountStillDue - data < 0 ? newAmountStillDue : data;
              setFieldValue("payments", newData);
            };
            return (
              <Form>
                <ScrollToError />
                <Row>
                  <Col className="col-8">
                    <Card>
                      <CardHeader>
                        <div className="d-flex align-items-center">
                          <h5 className="card-title flex-grow-1 mb-0">{t("Voucher info")}</h5>
                        </div>
                      </CardHeader>
                      <CardBody>
                        {id === "new" ? (
                          <Row>
                            <Col className="col-6">
                              <div className="mb-3">
                                <div>{t("Customer")}</div>
                                <div>
                                  <Link>
                                    <FullNameRender
                                      first_name={order?.customer?.first_name}
                                      last_name={order?.customer?.last_name}
                                    />
                                  </Link>{" "}
                                  - {order?.customer?.phone || "---"}
                                </div>
                              </div>
                            </Col>
                            <Col className="col-6">
                              <div className="mb-3">
                                <div>{t("Order Id")}</div>
                                <Link to={`/orders/${order?.id}`}>{order?.id}</Link>{" "}
                              </div>
                            </Col>
                            <Col className="col-6">
                              <div className="mb-3">
                                <SelectTheme
                                  label={"Returning branch"}
                                  name="location"
                                  remote={true}
                                  disabled={true}
                                  path="inventory/locations"
                                  isObjectValueSelect={true}
                                />
                              </div>
                            </Col>
                            <Col className="col-6">
                              <div className="mb-3">
                                <InputTheme
                                  name="reference"
                                  label="Reference"
                                  disabled={returnOrdersLoading}
                                  placeholder="Enter reference"
                                />
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col lg={6}>
                              <Row>
                                <Col className="hstack justify-content-between col-4">
                                  <span>{t("Customer")}</span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">
                                  <FullNameRender
                                    first_name={values.customer?.first_name}
                                    last_name={values.customer?.last_name}
                                  />
                                </Col>
                                <Col className="hstack justify-content-between col-4">
                                  <span>{t("Order Id")}</span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">
                                  <Link className="link-primary text-truncate" to={`/orders/${values.order_id}`}>
                                    {values.order_number || "---"}
                                  </Link>
                                </Col>
                                <Col className="hstack justify-content-between col-4">
                                  <span>{t("Created at")}</span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">
                                  {convertValidDate(returnOrder?.created_at)},
                                  <small className="text-muted"> {convertValidTime(returnOrder?.created_at)}</small>
                                </Col>
                                <Col className="hstack justify-content-between col-4">
                                  <span>{t("Received at")}</span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">
                                  {returnOrder?.returned_on ? (
                                    <Fragment>
                                      {convertValidDate(returnOrder?.returned_on)},
                                      <small className="text-muted">
                                        {" "}
                                        {convertValidTime(returnOrder?.returned_on)}
                                      </small>
                                    </Fragment>
                                  ) : (
                                    "---"
                                  )}
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={6}>
                              <Row>
                                <Col className="hstack justify-content-between col-4">
                                  <span>{t("Returned branch")}</span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">
                                  <span className="text-truncate">{returnOrder?.location?.name}</span>
                                </Col>
                                <Col className="hstack justify-content-between col-4">
                                  <span>{t("Return Order ID")}</span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">
                                  {values.exchanged_order_id ? (
                                    <Link
                                      className="link-primary text-truncate"
                                      to={`/return-orders/${values.exchanged_order_id}`}
                                    >
                                      {values.exchanged_order_number || "---"}
                                    </Link>
                                  ) : (
                                    "---"
                                  )}
                                </Col>
                                <Col className="hstack justify-content-between col-4">
                                  <span>{t("Staff")}</span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">{values?.staff?.name || "---"}</Col>
                                <Col className="hstack justify-content-between col-4">
                                  <span>{t("Reference")}</span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">
                                  {returnOrder?.reference || "---"}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="col-4">
                    <Card>
                      <CardHeader>
                        <div className="d-flex align-items-center">
                          <h5 className="card-title flex-grow-1 mb-0">{t("Additional info")}</h5>
                        </div>
                      </CardHeader>
                      <CardBody>
                        {id === "new" ? (
                          <Row>
                            <Col className="col-12">
                              <div className="mb-3">
                                <SelectTheme label="Reason" name="reason" options={ReturnOrderReasonOptions} />
                              </div>
                            </Col>
                            <Col className="col-12">
                              <div className="mb-3">
                                <InputTheme
                                  name="note"
                                  label="Note"
                                  disabled={returnOrdersLoading}
                                  placeholder="Enter note"
                                />
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col lg={12}>
                              <div className="mb-3">
                                <div className="fw-bolder">{t("Reason")}</div>
                                <BadgeCustom status={values.reason || "OTHER"} />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="mb-3">
                                <div className="fw-bolder">{t("Note")}</div>
                                {values.note ? (
                                  <div>{t(values.note)}</div>
                                ) : (
                                  <div className="text-muted">{t("No note")}</div>
                                )}
                              </div>
                            </Col>
                          </Row>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="col-12">
                    <ReturnOrderCard
                      returnProducts={values.return_order_line_items}
                      products={order?.order_line_items || []}
                      onChangeReturnOrder={handleChangeReturnOrderLines}
                      isEdit={id === "new"}
                    />
                  </Col>
                  <Col className="col-12">
                    <Card>
                      <CardBody>
                        {id === "new" ? (
                          <div className="d-flex align-items-center justify-content-between">
                            <div>
                              <h5 className="card-title flex-grow-1 mb-0">{t("Receive returning products")}</h5>
                              <p className="mb-0">
                                {values.status === ReturnOrderStatuses.RECEIVED
                                  ? `${t("Returned products are returned to")} ${t(values.location?.name)}`
                                  : values.status === ReturnOrderStatuses.NOT_RECEIVED
                                  ? t("You need to update status after creating return order")
                                  : ""}
                              </p>
                            </div>
                            <div className="hstack gap-2">
                              <ButtonTheme
                                className={`btn btn-light ${
                                  values.status === ReturnOrderStatuses.RECEIVED ? "border-primary text-primary" : ""
                                }`}
                                onClick={() => {
                                  setFieldValue("status", ReturnOrderStatuses.RECEIVED);
                                }}
                              >
                                {t("Received")}
                              </ButtonTheme>
                              <ButtonTheme
                                className={`btn btn-light ${
                                  values.status === ReturnOrderStatuses.NOT_RECEIVED
                                    ? "border-primary text-primary"
                                    : ""
                                }`}
                                onClick={() => {
                                  setFieldValue("status", ReturnOrderStatuses.NOT_RECEIVED);
                                }}
                              >
                                {t("Not Received")}
                              </ButtonTheme>
                            </div>
                          </div>
                        ) : (
                          <div className="card-title">
                            {returnOrder?.status === ReturnOrderStatuses.RECEIVED ? (
                              <Fragment>
                                <span>
                                  <i className="ri-checkbox-circle-fill text-success align-bottom fs-16 me-3" />
                                </span>
                                {t("Received at")}{" "}
                                <span className="text-muted">
                                  ({convertValidDate(returnOrder?.created_at)},{" "}
                                  {convertValidTime(returnOrder?.created_at)})
                                </span>
                              </Fragment>
                            ) : (
                              <div className="hstack justify-content-between">
                                <div>
                                  <span>
                                    <i className="ri-truck-line align-bottom fs-16 me-3" />
                                  </span>
                                  {t(" Await receiving returned products")}{" "}
                                </div>
                                <ButtonTheme
                                  type="button"
                                  className="btn btn-outline-primary w-md"
                                  onClick={() => {
                                    handleUpdateStatus("receive", {});
                                  }}
                                  loading={statusLoading === "receive"}
                                  disabled={statusLoading === "receive"}
                                >
                                  {t("Receive")}
                                </ButtonTheme>
                              </div>
                            )}
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                  {id === "new" && (
                    <Col className="col-12">
                      <ExchangeOrderCard
                        id={id}
                        selectedLocation={order?.location}
                        order={values.exchanged_order}
                        returnOrder={values}
                        onChangeExchangedOrder={handleChangeExchangedOrder}
                      />
                    </Col>
                  )}
                  <Col className="col-12">
                    <Card>
                      <CardHeader>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="hstack">
                            {id !== "new" ? (
                              <span>
                                <i className="ri-refund-2-line align-bottom fs-16 me-3" />
                              </span>
                            ) : returnOrder?.payment_status !== PaymentStatuses.PAID ? (
                              <span>
                                <i className="ri-refund-2-line align-bottom fs-16 me-3" />
                              </span>
                            ) : (
                              <span>
                                <i className="ri-checkbox-circle-fill text-success align-bottom fs-16 me-3" />
                              </span>
                            )}
                            <h5 className="card-title flex-grow-1 mb-0">{t(id !== "new" ? "Refunded" : "Refund")}</h5>
                          </div>
                          {id !== "new" && returnOrder?.payment_status !== PaymentStatuses.PAID && (
                            <ButtonTheme
                              type="button"
                              className="btn btn-outline-primary w-md"
                              onClick={() => {
                                handleUpdateStatus("refund", {});
                              }}
                              loading={statusLoading === "refund"}
                              disabled={statusLoading === "refund"}
                            >
                              {t("Refund")}
                            </ButtonTheme>
                          )}
                        </div>
                      </CardHeader>
                      <CardBody>
                        {id === "new" ? (
                          <Row className="justify-content-end">
                            <Col className="col-6">
                              <table className="table table-borderless mb-0">
                                <tbody>
                                  <tr>
                                    <td>{t("Refund amount")}</td>
                                    <td className="text-end">{formatVNDCurrency(refundAmount)}</td>
                                  </tr>
                                  <tr>
                                    <td>{t("Exchanged order amount")}</td>
                                    <td className="text-end">{formatVNDCurrency(values.total_exchanged_amount)}</td>
                                  </tr>
                                  <tr className="fw-semibold">
                                    <td>{t("Total refund amount")}</td>
                                    <td className="text-end">
                                      {formatVNDCurrency(refundAmount - values.total_exchanged_amount)}
                                    </td>
                                  </tr>
                                  <tr className="border-top border-top-dashed">
                                    <th scope="row">{t("Refunded")}</th>
                                  </tr>
                                  {values.payments &&
                                    values.payments.map((item, key) => {
                                      return (
                                        <tr key={key}>
                                          {!item?.id ? (
                                            <td className="hstack">
                                              <i
                                                className="align-bottom text-danger ri-close-line cursor-pointer me-2 fs-16"
                                                onClick={() => {
                                                  handleDeletePayment(key);
                                                }}
                                              ></i>
                                              <Input
                                                type="select"
                                                className="form-select"
                                                onChange={(e) => {
                                                  handleChangePayment(e.target.value, key);
                                                }}
                                                value={item?.payment_method?.id}
                                              >
                                                <option value={""}>{t("Please select a payment")}</option>
                                                {paymentMethods.map((paymentMethodItem, key) => (
                                                  <option key={key} value={paymentMethodItem.id}>
                                                    {t(paymentMethodItem.name || "---")}
                                                  </option>
                                                ))}
                                              </Input>
                                            </td>
                                          ) : (
                                            <td>{t(item.payment_method.name || "---")}</td>
                                          )}
                                          {!item?.id ? (
                                            <td>
                                              <InputPaymentField
                                                index={key}
                                                price={item.amount}
                                                onChangePaymentPrice={handleChangePaymentPrice}
                                              />
                                            </td>
                                          ) : (
                                            <td className="text-end">{formatVNDCurrency(item.amount)}</td>
                                          )}
                                        </tr>
                                      );
                                    })}

                                  <tr>
                                    <td>
                                      <ButtonTheme
                                        className="btn btn-ghost-primary p-0 border-0"
                                        onClick={handleAddPayment}
                                        disabled={paymentAmount === refundAmount}
                                      >
                                        <i className="ri-add-circle-line align-bottom me-2"></i>
                                        {t("Add payment")}
                                      </ButtonTheme>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">{t("Amount still due")}</th>
                                    <th className="text-end">{formatVNDCurrency(amountStillDue)}</th>
                                  </tr>
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                        ) : (
                          <div className="hstack justify-content-between bg-soft-primary rounded p-3">
                            <div>
                              <span>{t("Must refund")}:</span>
                              <span className="fw-bold">{formatVNDCurrency(returnOrder?.total)}</span>
                            </div>
                            <div>
                              <span>{t("Refunded")}:</span>
                              <span className="fw-bold">
                                {formatVNDCurrency(
                                  (returnOrder?.payments || []).reduce((total, item) => total + +item.amount, 0),
                                )}
                              </span>
                            </div>
                            <div>
                              <span>{t("Amount still due")}:</span>
                              <span className="fw-bold text-danger">
                                {formatVNDCurrency(
                                  returnOrder?.total -
                                    (returnOrder?.payments || []).reduce((total, item) => total + +item.amount, 0),
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </div>
  );
};

export default withRouter(ReturnOrderDetails);
