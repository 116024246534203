import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { isEmpty } from "lodash";

import { convertValidDate, convertValidTime } from "../../../../helpers/format_helper";

import withRouter from "../../../../Components/Common/withRouter";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import AddEditPaymentMethodModal from "./AddEditPaymentMethodModal";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import { AccountTypeOptions, Actions } from "../../../../Components/constants/common";

import { deletePaymentMethod, listAccount, listPaymentMethod } from "../../../../store/actions";
import ActionDropdown from "../../../../Components/Common/ActionDropdown";

const PaymentMethodsPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Payment Method List")} | OptiWarehouse`;
  const dispatch = useDispatch();

  const {
    accounts,
    accountsLoading,
    accountsActions,
    accountsMessage,
    accountsSuccess,
    accountsError,
    accountsLimit,
    accountsPage,
    accountsTotal,
  } = useMemoizedSelector((state) => ({
    accounts: state.Accounts.accounts,
    accountsLoading: state.Accounts.loading,
    accountsActions: state.Accounts.action,
    accountsSuccess: state.Accounts.success,
    accountsError: state.Accounts.error,
    accountsMessage: state.Accounts.message,
    accountsLimit: state.Accounts.limit,
    accountsTotal: state.Accounts.total,
    accountsPage: state.Accounts.page,
  }));

  const {
    paymentMethods,
    paymentMethodsLoading,
    paymentMethodsAction,
    paymentMethodsMessage,
    paymentMethodsSuccess,
    paymentMethodsError,
    paymentMethodsLimit,
    paymentMethodsPage,
    paymentMethodsTotal,
  } = useMemoizedSelector((state) => ({
    paymentMethods: state.PaymentMethods.paymentMethods,
    paymentMethodsAction: state.PaymentMethods.action,
    paymentMethodsLoading: state.PaymentMethods.loading,
    paymentMethodsSuccess: state.PaymentMethods.success,
    paymentMethodsError: state.PaymentMethods.error,
    paymentMethodsMessage: state.PaymentMethods.message,
    paymentMethodsLimit: state.PaymentMethods.limit,
    paymentMethodsTotal: state.PaymentMethods.total,
    paymentMethodsPage: state.PaymentMethods.page,
  }));

  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [accountList, setAccountList] = useState([]);

  const [deleteModal, setDeleteModal] = useState(false);
  const [showEditAddPaymentMethodModal, setShowEditAddPaymentMethodModal] = useState(false);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const handleDeletePaymentMethod = () => {
    dispatch(deletePaymentMethod(selectedPaymentMethod));
  };
  const onClickDelete = (data) => {
    setSelectedPaymentMethod(data);
    setDeleteModal(true);
  };

  const onClickEdit = (data) => {
    setSelectedPaymentMethod(data);
    setShowEditAddPaymentMethodModal(true);
  };

  const onCloseDeleteModal = () => {
    if (!paymentMethodsLoading) {
      setSelectedPaymentMethod(null);
      setDeleteModal(false);
    }
  };

  const toggleEditAddPaymentMethodModal = () => {
    if (!paymentMethodsLoading) {
      setShowEditAddPaymentMethodModal(false);
      setSelectedPaymentMethod(null);
    }
  };

  const headers = [
    { title: t("Name"), dataIndex: "name", key: "name", sorter: true },
    {
      title: t("Account"),
      dataIndex: "account",
      key: "account",
      render: (data, row, index) => {
        return <>{data?.name || "---"}</>;
      },
    },
    {
      title: t("Type"),
      dataIndex: "type",
      key: "type",
      render: (data, row, index) => {
        return <>{row?.account?.type || "---"}</>;
      },
    },
    {
      title: t("Updated at"),
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: true,
    },
    {
      title: t("Created At"),
      dataIndex: "created_at",
      key: "created_at",
      defaultSortOrder: "desc",
      sorter: true,
    },
    {
      title: t("Action"),
      dataIndex: "action",
      render: (_, data, index) => {
        return (
          <ActionDropdown
            index={index}
            actions={[
              {
                type: "edit",
                onClick: () => {
                  onClickEdit(data);
                },
              },
              {
                type: "delete",
                onClick: () => {
                  onClickDelete(data);
                },
              },
            ]}
          />
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(listAccount({ limit: 20 }));
    // dispatch(listPaymentMethod({ limit: 20 }));
  }, [dispatch]);

  useEffect(() => {
    !accountsLoading && setAccountList(accounts);
  }, [accounts]);

  useEffect(() => {
    setPaymentMethodList(paymentMethods);
  }, [paymentMethods]);

  useEffect(() => {
    if (
      !paymentMethodsLoading &&
      !isEmpty(selectedPaymentMethod) &&
      deleteModal &&
      paymentMethodsMessage === "Delete payment method success"
    ) {
      setSelectedPaymentMethod(null);
      setDeleteModal(false);
    }
  }, [paymentMethodsLoading]);

  return (
    <Fragment>
      <div className="page-content">
        <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={paymentMethodList} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeletePaymentMethod}
          loading={paymentMethodsLoading && paymentMethodsAction === Actions.DELETE}
          onCloseClick={onCloseDeleteModal}
        />
        <AddEditPaymentMethodModal
          isOpen={showEditAddPaymentMethodModal}
          toggle={toggleEditAddPaymentMethodModal}
          paymentMethod={selectedPaymentMethod}
          accounts={accountList}
        />
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">{t("Payment Method List")}</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex gap-3 flex-wrap">
                        <ButtonTheme
                          type="button"
                          className="btn btn-light"
                          icon="ri-file-text-line"
                          onClick={() => setIsExportCSV(true)}
                        >
                          {t("Export")}
                        </ButtonTheme>
                        <ButtonTheme
                          className="btn btn-primary"
                          id="create-btn"
                          icon="ri-add-line text-white"
                          onClick={() => {
                            setShowEditAddPaymentMethodModal(true);
                          }}
                        >
                          {t("Add Payment Method")}
                        </ButtonTheme>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <TableContainerCustom
                    filterType={"paymentMethods"}
                    searchPlaceHolder="Search payment methods..."
                    listFilter={[
                      {
                        id: "account.type",
                        type: "selectBox",
                        title: `${t("Type")}`,
                        dataOption: AccountTypeOptions,
                      },
                    ]}
                    headers={headers}
                    contents={paymentMethodList}
                    limitPage={Number(paymentMethodsLimit)}
                    currentPage={Number(paymentMethodsPage) + 1}
                    totalItems={paymentMethodsTotal}
                    loading={paymentMethodsLoading && paymentMethodsAction === Actions.LIST}
                    showPagination={true}
                    scrollHeight="50vh"
                    onCallData={(dataPayload) => {
                      dispatch(listPaymentMethod(dataPayload));
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};
export default withRouter(PaymentMethodsPage);
