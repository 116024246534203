import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
} from "reactstrap";
import BreadCrumbCustom from "../../../../Components/Common/BreadCrumbCustom";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { vouchersAPI } from "../../../../helpers/resource_helper";
import { Link, useNavigate, useParams } from "react-router-dom";
import { formatDateTime, formatViewCurrency, getDateTimeRemaining } from "../../../../helpers/format_helper";
import Loader from "../../../../Components/Common/Loader";
import { toast } from "react-toastify";
import { Table } from "antd";
import { useTranslation } from "react-i18next";

export default function DetailVoucher() {
  const [detailVoucher, setDetailVoucher] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [loadingCannel, setLoadingCannel] = useState(false);
  const navigate = useNavigate();
  const { idVoucher } = useParams();
  const { t } = useTranslation();
  document.title = `${t("Voucher Details")} | OptiWarehouse`;

  useEffect(() => {
    const getDetailVoucher = async () => {
      setLoading(true);
      const res = await vouchersAPI.get({ id: idVoucher });
      setDetailVoucher(res);
      setLoading(false);
    };
    if (idVoucher) {
      getDetailVoucher();
    }
  }, [idVoucher]);
  const houseTime = getDateTimeRemaining(detailVoucher.expire_at);
  const typeTable = detailVoucher?.condition_contains_order?.["products"]
    ? "products"
    : detailVoucher?.condition_contains_order?.["category"]
    ? "category"
    : "brand";
  const dataConditionTable =
    detailVoucher?.condition_contains_order?.["products"] ||
    detailVoucher?.condition_contains_order?.["category"] ||
    detailVoucher?.condition_contains_order?.["brand"] ||
    [];
  const columns = useMemo(() => {
    const newColumns = [
      {
        title: t(typeTable === "products" ? "New product" : typeTable === "category" ? "New category" : "New brand"),
        key: "name",
        dataIndex: "name",
      },
      {
        title: t("Min product quantity in order"),
        key: "quantity",
        dataIndex: "quantity",
      },
    ];
    if (typeTable === "products" || typeTable === "category") {
      newColumns.unshift({
        title: t(typeTable === "products" ? "SKU" : "Category Id"),
        dataIndex: "sku",
        key: "code_",
      });
    }
    return newColumns;
  }, [typeTable]);
  return (
    <div className="page-content">
      <BreadCrumbCustom text={detailVoucher?.name} navigateTo="/vouchers">
        <>
          {detailVoucher.status === "NOT_ACTIVE" ? (
            <ButtonTheme
              type="button"
              loadShowText={true}
              loading={loadingButton}
              className="btn btn-primary w-sm"
              onClick={async () => {
                setLoadingButton(true);
                const res = await vouchersAPI.put({ ...detailVoucher, status: "APPLY" });
                setDetailVoucher(res);
                toast.success(t("Active voucher successfully!"));
                setLoadingButton(false);
              }}
            >
              {t("Active")}
            </ButtonTheme>
          ) : (
            <ButtonTheme
              type="button"
              loadShowText={true}
              loading={loadingButton}
              className="btn btn-primary w-sm"
              onClick={async () => {
                setLoadingButton(true);
                const res = await vouchersAPI.put({ ...detailVoucher, status: "STOP_APPLY" });
                setDetailVoucher(res);
                toast.success(t("Pause voucher successfully!"));
                setLoadingButton(false);
              }}
            >
              {t("Pause")}
            </ButtonTheme>
          )}
        </>
      </BreadCrumbCustom>
      <Container fluid>
        {loading ? (
          <Loader />
        ) : (
          <Row>
            <Col lg={8}>
              <Card>
                <CardBody>
                  <div className="align-items-center d-flex justify-content-between">
                    <div className="align-items-center d-flex mb-4">
                      <h5>{t("General info")}</h5>
                      {detailVoucher.status === "NOT_ACTIVE" ? (
                        <span className="badge badge-label bg-primary mb-2 mx-3">{t("Not active")}</span>
                      ) : detailVoucher.status === "APPLY" ? (
                        <span className="badge badge-label bg-success mb-2 mx-3">{t("Apply")}</span>
                      ) : detailVoucher.status === "STOP_APPLY" ? (
                        <span className="badge badge-label bg-light mb-2 mx-3">{t("Strop applying")}</span>
                      ) : (
                        <span className="badge badge-label bg-danger mb-2 mx-3">{t("Cancelled")}</span>
                      )}
                    </div>
                    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
                      <DropdownToggle caret data-toggle="dropdown" tag="button" className="btn btn-primary">
                        {t("Add action")}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            navigate(`/vouchers/${detailVoucher.id}`);
                          }}
                        >
                          {t("Edit")}
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem
                          className="d-flex"
                          onClick={async () => {
                            const isCheck = window.confirm("Bạn có muốn hủy không?");
                            if (isCheck) {
                              setLoadingCannel(true);
                              const res = await vouchersAPI.put({ ...detailVoucher, status: "CANCELLED" });
                              setDetailVoucher(res);
                              setLoadingCannel(false);
                            }
                          }}
                        >
                          {loadingCannel && <Spinner size="sm" className="mx-1" />}
                          {t("Close")}
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>

                  <Row>
                    <Col lg={6}>
                      <div className="d-flex">
                        <p className="flex-1">{t("Name")}</p>
                        <p className="flex-1">: {detailVoucher.name}</p>
                      </div>
                      <div className="d-flex">
                        <p className="flex-1">{t("Voucher code quantity")}</p>
                        <p className="flex-1">: 1</p>
                      </div>
                      <div className="d-flex">
                        <p className="flex-1">{t("Description")}</p>
                        <p className="flex-1">: {detailVoucher.description}</p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="d-flex">
                        <p className="flex-1">{t("Voucher code")}</p>
                        <p className="flex-1">: {detailVoucher.code}</p>
                      </div>
                      <div className="d-flex">
                        <p className="flex-1">{t("Discount total")}</p>
                        <p className="flex-1">: 2,168,500</p>
                      </div>
                      <div className="d-flex">
                        <p className="flex-1">{t("Voucher details")}</p>
                        <p className="flex-1">
                          : {t("Discount")}{" "}
                          {`${detailVoucher?.value_coupon?.amount}${
                            detailVoucher?.value_coupon?.type === "PERCENT" && "%"
                          } `}
                          {t("max")} {formatViewCurrency(detailVoucher?.max_value_coupon)}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  {detailVoucher.apply_with_other_promotion ? (
                    <div className="d-flex align-items-center">
                      <i className=" ri-check-line fs-20 mx-2 text-info"></i>{" "}
                      <span>{t("Stack with other promotions")}</span>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <i className=" ri-close-line fs-20 mx-2 text-danger"></i>{" "}
                      <span>{t("No stack with other promotions")}</span>
                    </div>
                  )}
                  <div className="d-flex align-items-center">
                    <i className="ri-user-3-line fs-20 mx-2"></i>
                    <span>
                      {t("Each customer uses the maximum")}{" "}
                      {detailVoucher?.use_voucher_customer
                        ? `${detailVoucher?.use_voucher_customer} ${t("times")}`
                        : t("many times")}
                    </span>
                  </div>
                </CardFooter>
              </Card>

              <Card>
                <CardBody>
                  <h5>{t("Voucher code")}</h5>
                  <Link to={`/voucher-code/${detailVoucher.id}`}>{t("View voucher code list of")}</Link>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h5>{t("Condition")}</h5>
                  <p>
                    {t("Order total from")} {formatViewCurrency(detailVoucher.condition_order)}.
                  </p>
                  {dataConditionTable.length > 0 && (
                    <Table
                      dataSource={dataConditionTable}
                      columns={columns}
                      rowClassName="editable-row"
                      pagination={false}
                      rowKey={(row) => row?.id}
                      className=" mt-4"
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col lg={4}>
              <Card>
                <CardBody>
                  <h5>{t("Start at")}</h5>
                  <p className="d-flex justify-content-between align-items-center">
                    <span className="flex-1">{t("From")}</span>
                    <span className="flex-1">:&nbsp; {formatDateTime(detailVoucher.start_at)}</span>
                  </p>
                  <p className="d-flex justify-content-between text-danger align-items-center">
                    <span className="flex-1">{t("To")}</span>
                    <span className="flex-1">
                      :&nbsp;{" "}
                      {detailVoucher.expire_at ? (
                        formatDateTime(detailVoucher.expire_at)
                      ) : (
                        <span className="fs-20">∞</span>
                      )}
                    </span>
                  </p>
                  <p className="d-flex justify-content-between align-items-center">
                    <span className="flex-1">{t("Remaining time")}</span>
                    <p className="flex-1 text-danger">
                      : &nbsp;
                      {detailVoucher.expire_at ? (
                        houseTime > 0 ? (
                          `${houseTime} h`
                        ) : (
                          <span> {t("Expiry")}</span>
                        )
                      ) : (
                        <span className="fs-20">∞</span>
                      )}
                    </p>
                  </p>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h5>{t("Effective locations")}</h5>
                  {detailVoucher?.locations?.find((item) => item.id === "ALL") ? (
                    <p>{t("All locations")}</p>
                  ) : (
                    detailVoucher?.locations?.map((item, key) => <p key={key}>{item.name}</p>)
                  )}
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h5>{t("Effective sources")}</h5>
                  {detailVoucher?.sources?.find((item) => item.id === "ALL") ? (
                    <p>{t("All sources")}</p>
                  ) : (
                    detailVoucher?.sources?.map((item, key) => <p key={key}>{item.name}</p>)
                  )}
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h5>{t("Effective customers")}</h5>
                  {detailVoucher?.customers?.find((item) => item.id === "ALL") ? (
                    <p>{t("All customers")}</p>
                  ) : (
                    detailVoucher?.customers?.map((item, key) => <p key={key}>{item.name}</p>)
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}
