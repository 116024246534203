import { Card, CardBody, Col, Row } from "reactstrap";
import { convertValidDate, convertValidTime, formatVNDCurrency } from "../../../../helpers/format_helper";
import SimpleTableContainer from "../../../../Components/Common/SimpleTableContainer";
import { Fragment, useMemo } from "react";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";
import FullNameRender from "../../../../Components/Common/FullnameRender";
import ImageCustom from "../../../../Components/Common/ImageCustom";
import { withTranslation } from "react-i18next";

const ExpandRowDetails = ({ data, t }) => {
  let quantity = (data?.order_line_items || []).reduce((total, item) => total + +item.quantity, 0);
  let amountTotal = (data?.order_line_items || []).reduce((total, item) => total + item.quantity * item.sale_price, 0);
  let amountFeeTotal = (data?.fees || []).reduce((total, item) => total + +item.amount, 0);
  let amountToPay = amountTotal + amountFeeTotal - data?.discount;

  const columns = useMemo(
    () => [
      {
        Header: t("Product Details"),
        Cell: (cell) => {
          const product = cell.row.original;
          const index = cell.row.index;
          return !product?.custom ? (
            <Fragment>
              <div className="d-flex">
                <div className="flex-shrink-0 p-1">
                  <ImageCustom image={product.image_url} name={product?.name} avatarSize={"avatar-md"} />
                </div>
                <div className="flex-grow-1 ms-3 d-flex flex-column justify-content-center">
                  <h5 className="fs-15">
                    {product?.name ? (
                      <Tooltip title={t(product.name)}>
                        <Link
                          id={`order-items-product-name-${index}`}
                          to={{ pathname: `/products/${product.product_id}`, state: { sku: product.sku } }}
                          className="d-inline-block text-truncate"
                          style={{ maxWidth: "400px" }}
                          target="_blank"
                        >
                          {t(product.name)}
                        </Link>
                      </Tooltip>
                    ) : (
                      "---"
                    )}
                  </h5>
                  <p className="text-muted fw-medium mb-1">
                    {t("Sku")}: {product.sku}
                  </p>
                  <p className="text-muted fw-medium mb-0 d-inline-block text-truncate" style={{ maxWidth: "400px" }}>
                    {t("Location")}: {product?.location?.name ? t(product?.location?.name) : "---"}
                  </p>
                </div>
              </div>
            </Fragment>
          ) : (
            <h5 className="fs-15">{t(product?.name)}</h5>
          );
        },
      },
      {
        id: "quantity",
        Header: () => <div className="text-center">{t("Quantity")}</div>,
        Cell: (cell) => {
          let product = cell.row.original;
          return <div className="text-center">{product?.quantity ?? 0}</div>;
        },
      },
      {
        id: "price",
        Header: () => <div className="text-end">{t("Price")}</div>,
        Cell: (cell) => {
          let product = cell.row.original;
          return <div className="text-end">{formatVNDCurrency(product?.unit_price)}</div>;
        },
      },
      {
        id: "product_discount",
        Header: () => <div className="text-end">{t("Discount")}</div>,
        Cell: (cell) => {
          let product = cell.row.original;
          let index = cell.row.index;
          return (
            <div className="fw-medium">
              <div className="text-end">{formatVNDCurrency(product.unit_price - product.sale_price)}</div>
            </div>
          );
        },
      },
      {
        id: "total",
        Header: () => <div className="text-end">{t("Total Amount")}</div>,
        Cell: (cell) => {
          let product = cell.row.original;
          return <div className="fw-medium text-end">{formatVNDCurrency(product.quantity * product.sale_price)}</div>;
        },
      },
    ],
    [],
  );
  return (
    <div>
      <Card>
        <CardBody className="d-flex gap-5">
          <div>
            <h6>{t("Order details")}</h6>
            <div className="hstack gap-2">
              <div>
                <p className="text-muted mb-1">{t("Order ID")}:</p>
                <p className="text-muted mb-1">{t("Created at")}:</p>
                <p className="text-muted mb-1">{t("Source")}:</p>
                <p className="text-muted mb-1">{t("Staff")}:</p>
              </div>
              <div>
                <p className="mb-1">{data?.id || "---"}</p>
                <p className="mb-1">
                  {convertValidDate(data?.created_at)}, <small>{convertValidTime(data?.created_at)}</small>
                </p>
                <p className="mb-1">{data?.order_type || "---"}</p>
                <p className="mb-1">{data?.staff?.name || "---"}</p>
              </div>
            </div>
          </div>
          <div>
            <h6>{t("Customer")}</h6>
            <p className="mb-1">
              <Link className="text-primary" to={`/customers/${data?.customer?.id}`}>
                <FullNameRender first_name={data?.customer?.first_name} last_name={data?.customer?.last_name} />
              </Link>
              {data?.customer?.phone && ` - ${data?.customer?.phone}`}
            </p>
          </div>
          <div className="border-start px-3">
            <div className="mb-2">
              <h6>{t("Order note")}</h6>
              <p className="mb-1">{data?.note ? t(data?.note) : "---"}</p>
            </div>
            <div>
              <h6>{t("Tags")}</h6>
              <p className="mb-1">{data?.tags ? data?.tags : "---"}</p>
            </div>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <SimpleTableContainer
            columns={columns}
            data={data.order_line_items || []}
            divClass="table-card table-responsive"
            tableClass="table table-sm table-nowrap align-middle mb-0"
            theadClass="table-light text-muted"
            noDataElement={
              <div className="d-flex justify-content-center align-items-center py-3">
                <div className="text-center">
                  <i className="bx bxs-basket text-muted fs-48 mt-3"></i>
                  <h6 className="fs-3 mb-3 text-muted">{t("Order has no items.")}</h6>
                </div>
              </div>
            }
          />
          <div className="border-top border-top-dashed mt-3 pt-3">
            <div className="hstack gap-5 float-end">
              <div>
                <p className="mb-1">{`${t("Total")} (${quantity} ${quantity > 1 ? t("products") : t("product")})`}</p>
                <p className="mb-1">{t("Discount")}</p>
                <p className="mb-1">{t("Customer group discount")}</p>
                <p className="mb-1">{t("Fees")}</p>
                <p className="mb-1 fw-bold">{t("Amount to pay")}</p>
              </div>
              <div>
                <p className="mb-1">{formatVNDCurrency(data?.sub_total)}</p>
                <p className="mb-1">{formatVNDCurrency(data?.discount)}</p>
                <p className="mb-1">{formatVNDCurrency(data?.discount_by_customer_group ?? 0)}</p>
                <p className="mb-1">{formatVNDCurrency(amountFeeTotal)}</p>
                <p className="mb-1 fw-bold">{formatVNDCurrency(data?.total)}</p>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
export default withTranslation()(ExpandRowDetails);
