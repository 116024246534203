import { Fragment, useEffect, useState } from "react";
import { Col, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

import { deepCopy, formatVNDCurrency } from "../../../../../../helpers/format_helper";
import ButtonTheme from "../../../../../../Components/Common/ButtonTheme";
import InputPaymentField from "../../../../../../Components/Common/InputPaymentField";

const AddPaymentsReturnOrderModal = ({
  isOpen,
  toggle,
  paymentMethods,
  total,
  onAddPayment,
  payLoading,
  payments = [],
}) => {
  const { t } = useTranslation();

  const [errorIndex, setErrorIndex] = useState([]);
  const [paymentList, setPaymentList] = useState([]);

  let amountPaid = payments.reduce((total, item) => total + +item.amount, 0);

  let amountTotal = total - amountPaid;

  let amountPayment = paymentList ? paymentList.reduce((total, item) => total + +item.amount, 0) : 0;
  let amountStillDue = amountTotal - amountPayment;

  const handleSubmit = () => {
    let newError = [...errorIndex];
    paymentList.forEach((item, index) => {
      if (!item?.id) {
        if (item.payment_method_id) {
          newError.includes(index) && (newError = newError.filter((e) => e !== index));
        } else {
          !newError.includes(index) && newError.push(index);
        }
      }
    });
    setErrorIndex(newError);
    if (paymentList.find((item) => item.payment_method_id === "")) {
      return;
    }
    onAddPayment("pay", { payments: paymentList });
  };

  const handleChangePaymentPrice = (data, index) => {
    let newData = deepCopy(paymentList);
    newData[index].amount = data;
    setPaymentList(newData);
  };
  const handleDeletePayment = (i) => {
    let newData =
      paymentList.length > 1
        ? paymentList.filter((item, index) => index !== i)
        : [{ payment_method_id: "", amount: 0 }];
    setPaymentList(newData);
    setErrorIndex(errorIndex.filter((item, index) => index !== i));
  };
  const handleAddPayment = () => {
    let newPayments = [...paymentList];
    if (amountTotal - amountPayment > 0) {
      newPayments.push({
        payment_method_id: "",
        amount: amountTotal - amountPayment,
      });
    } else {
      newPayments.push({ payment_method_id: "", amount: 0 });
    }
    setPaymentList(newPayments);
  };
  const handleChangePayment = (data, i) => {
    let newData = deepCopy(paymentList);
    if (data) {
      errorIndex.includes(i) && setErrorIndex(errorIndex.filter((item, index) => index !== i));
    } else {
      !errorIndex.includes(i) && setErrorIndex([...errorIndex, i]);
    }
    if (newData[i].id) {
      newData[i] = { payment_method_id: data, amount: newData[i].amount };
    } else {
      newData[i].payment_method_id = data;
    }
    setPaymentList(newData);
  };

  useEffect(() => {
    isOpen &&
      setPaymentList([
        {
          payment_method_id: "",
          amount: amountStillDue,
        },
      ]);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} scrollable>
      <ModalHeader toggle={toggle}>{t("Add payments")}</ModalHeader>
      <ModalBody>
        {paymentList.map((payment, key) => {
          return (
            <Fragment key={key}>
              <PaymentField
                payment={payment}
                paymentMethods={paymentMethods}
                index={key}
                handleChangePayment={handleChangePayment}
                handleChangePaymentPrice={handleChangePaymentPrice}
                handleDeletePayment={handleDeletePayment}
                error={errorIndex.includes(key)}
              />
            </Fragment>
          );
        })}
        <Row className="mb-3">
          <Col>
            <ButtonTheme className="btn btn-ghost-primary p-0 border-0" onClick={handleAddPayment}>
              <i className="ri-add-circle-line align-bottom me-2"></i>
              {t("Add payment")}
            </ButtonTheme>
          </Col>
        </Row>
        <Row className="border-0 border-dashed border-top border-bottom py-3">
          <Col></Col>
          <Col>
            <div className="hstack mb-2">
              <span className="flex-grow-1">{t("Amount to pay")}:</span>
              <span className="flex-shrink-0 text-end">{formatVNDCurrency(amountTotal)}</span>
            </div>
            <div className="hstack">
              <span className="flex-grow-1">{t("Amount still due")}:</span>
              <span className="flex-shrink-0 text-end">{formatVNDCurrency(amountStillDue)}</span>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <ButtonTheme type="button" className="btn btn-light w-md" onClick={toggle} disabled={payLoading}>
          {t("Close")}
        </ButtonTheme>
        <ButtonTheme
          type="button"
          className="btn btn-primary w-md"
          onClick={handleSubmit}
          loading={payLoading}
          disabled={payLoading}
        >
          {t("Submit")}
        </ButtonTheme>
      </ModalFooter>
    </Modal>
  );
};

const PaymentField = ({
  payment,
  paymentMethods,
  index,
  handleChangePayment,
  handleChangePaymentPrice,
  handleDeletePayment,
  error = false,
}) => {
  const { t } = useTranslation();
  return (
    <Row className="mb-3">
      <Col>
        <Label>
          {t("Payment method")} <span className="text-danger">*</span>
        </Label>{" "}
        <Input
          type="select"
          className="form-select"
          name="payment_method_id"
          onChange={(e) => {
            handleChangePayment(e.target.value, index);
          }}
          value={payment.id ? payment.payment_method.id : payment.payment_method_id}
          invalid={error}
        >
          <option value={""}>{t("Please select a payment")}</option>
          {paymentMethods.map((item, key) => (
            <option key={key} value={item.id}>
              {t(item.name)}
            </option>
          ))}
        </Input>
        {error ? (
          <FormFeedback type="invalid">
            <div>{t("Please select a payment")}</div>
          </FormFeedback>
        ) : null}
      </Col>
      <Col>
        <Label>{t("Amount")}</Label>
        <div className="hstack gap-2">
          <InputPaymentField index={index} price={payment.amount} onChangePaymentPrice={handleChangePaymentPrice} />
          <ButtonTheme
            type="button"
            onClick={() => handleDeletePayment(index)}
            className="btn btn-icon btn-ghost-danger waves-effect waves-light rounded-circle light-dark-mode align-self-end flex-shrink-0"
          >
            <i className="ri-close-line fs-22"></i>
          </ButtonTheme>
        </div>
      </Col>
    </Row>
  );
};
export default AddPaymentsReturnOrderModal;
