import { Actions } from "../../../../Components/constants/common";
import {
  LIST_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_FAIL,
  ADD_PAYMENT_METHOD_SUCCESS,
  ADD_PAYMENT_METHOD_FAIL,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPDATE_PAYMENT_METHOD_FAIL,
  LIST_PAYMENT_METHOD_SUCCESS,
  LIST_PAYMENT_METHOD_FAIL,
  ADD_NEW_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD,
  GET_PAYMENT_METHOD,
  GET_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHOD_FAIL,
  RESET_PAYMENT_METHOD_FLAG,
} from "./actionType";

const INIT_STATE = {
  paymentMethods: [],
  paymentMethod: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const PaymentMethods = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_PAYMENT_METHOD:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        message: "Get payment methods success",
        loading: false,
        success: true,
        paymentMethods: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        message: "Get payment methods failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_PAYMENT_METHOD:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        message: "Get payment method success",
        loading: false,
        success: true,
        paymentMethod: action.payload,
        action: Actions.EMPTY,
      };

    case GET_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        message: "Get payment method failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_PAYMENT_METHOD:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        message: "Delete payment method success",
        loading: false,
        success: true,
        paymentMethods: state.paymentMethods.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        message: "Delete payment method failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_NEW_PAYMENT_METHOD:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        message: "Add payment method success",
        success: true,
        loading: false,
        paymentMethods: [...state.paymentMethods, action.payload],
        action: Actions.EMPTY,
      };

    case ADD_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        message: "Add payment method failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_PAYMENT_METHOD:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.UPDATE,
      };
    case UPDATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        message: "Update payment method success",
        loading: false,
        success: true,
        paymentMethods: state.paymentMethods.map((paymentMethod) =>
          paymentMethod.id.toString() === action.payload.id.toString()
            ? { ...paymentMethod, ...action.payload }
            : paymentMethod,
        ),
        action: Actions.EMPTY,
      };

    case UPDATE_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        message: "Update payment method failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case RESET_PAYMENT_METHOD_FLAG:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default PaymentMethods;
