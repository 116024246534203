import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, Col, List, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

import { formatBytes } from "../../helpers/format_helper";
import { importByXlsxFileAPI } from "../../helpers/service_helper";

import documentImage from "../../assets/images/new-document.png";
import ButtonTheme from "./ButtonTheme";
import SelectTheme from "./SelectTheme";
import { Form, Formik } from "formik";
import ScrollToError from "./ScrollToError";

const ImportCSVModal = ({
  show,
  onCloseClick,
  title,
  pathUrl = "import_product_by_xlsx_file",
  onLoadData = () => {},
}) => {
  const { t } = useTranslation();

  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFile = (pathUrl) => {
    const result = {};
    if (pathUrl === "import_product_by_xlsx_file") {
      result["to"] = "/documents/import-products-sample.xlsx";
      result["name"] = "import-products-sample";
    } else if (pathUrl === "import_customer_by_xlsx") {
      result["to"] = "/documents/noli_customer.xlsx";
      result["name"] = "mau_file_khach_hang";
    } else {
      result["to"] = "/documents/mau_file_nhap_don_nhap_hang.xlsx";
      result["name"] = "mau_file_nhap_don_nhap_hang";
    }
    return result
  };

  const file = handleFile(pathUrl);

  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    setSelectedFile(files[0] || null);
  };
  const handleClose = () => {
    if (!loading) {
      selectedFile && setSelectedFile(null);
      onCloseClick();
    }
  };

  const validation = {
    enableReinitialize: true,
    initialValues: {},
    onSubmit: async (values) => {
      if (selectedFile) {
        try {
          setLoading(true);
          const formData = new FormData();
          formData.append("file", selectedFile);
          if (pathUrl === "import_purchase_order_by_xlsx_file_api") {
            formData.append("supplier", JSON.stringify(values.supplier));
            formData.append("location", JSON.stringify({ id: values.location.value, name: values.location.label }));
          }
          await importByXlsxFileAPI(pathUrl, formData);
          onLoadData();
          toast.success(t("Import successfully"));
        } catch (e) {
          toast.error(t("Import failed"));
          console.error(e);
        } finally {
          setSelectedFile(null);
          onCloseClick();
          setLoading(false);
        }
      }
    },
  };

  return (
    <Modal isOpen={show} toggle={handleClose} centered={true} size="lg" modalClassName="zoomIn">
      <ModalHeader toggle={handleClose}>{title ? title : t("Import product data")}</ModalHeader>
      <Formik
        enableReinitialize={true}
        initialValues={validation.initialValues}
        validationSchema={validation.validationSchema}
        onSubmit={validation.onSubmit}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <ScrollToError />
            <ModalBody className="py-3 px-5">
              {pathUrl === "import_purchase_order_by_xlsx_file_api" && (
                <Row>
                  <Col lg={6}>
                    <SelectTheme
                      label={"Location"}
                      name="location"
                      remote={true}
                      path="inventory/locations"
                      isObjectValueSelect={true}
                    />
                  </Col>
                  <Col lg={6}>
                    <SelectTheme
                      label={"Supplier"}
                      name="supplier"
                      remote={true}
                      path="actor/suppliers"
                      keyLabel={["first_name", "last_name"]}
                      selectDataOrigin={true}
                      isObjectValueSelect={true}
                    />
                  </Col>
                </Row>
              )}
              <div className="mb-3">
                <h6>{t("Notice")}:</h6>
                <blockquote className="blockquote custom-blockquote blockquote-danger rounded mb-2 hstack gap-3">
                  <i className="ri-alert-fill text-danger fs-3" />
                  <p className="text-body">
                    {t("Please import the file according to the template to avoid data discrepancies.")}
                  </p>
                </blockquote>
                <List className="mb-2">
                  <li>
                    {t("Download the import template")}{" "}
                    <Link
                      className="link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover"
                      download={file.name}
                      to={file.to}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("here")}
                    </Link>
                    .
                  </li>
                  <li>{t("The maximum file size is 3MB and it can have up to 5000 lines.")}</li>
                </List>
              </div>
              <div className="mb-3">
                <Dropzone
                  maxFiles={1}
                  onDrop={(acceptedFiles) => {
                    handleAcceptedFiles(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone dz-clickable" style={{ minHeight: "auto" }}>
                      <div className="dz-message needsclick" {...getRootProps()}>
                        <div className="mb-2">
                          <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                        </div>
                        <h4 className="text-muted">{t("Drop files here or click to upload.")}</h4>
                      </div>
                    </div>
                  )}
                </Dropzone>
                {selectedFile && (
                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                    <div className="hstack gap-2 p-2">
                      <div className="flex-shrink-0">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={selectedFile?.name}
                          src={documentImage}
                        />
                      </div>
                      <div className="flex-grow-1">
                        <Link to="#" className="text-muted font-weight-bold">
                          {selectedFile?.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{selectedFile?.formattedSize}</strong>
                        </p>
                      </div>
                      <div className="flex-shink-0">
                        <ButtonTheme
                          className="btn btn-sm btn-danger"
                          onClick={() => {
                            setSelectedFile(null);
                          }}
                        >
                          {t("Delete")}
                        </ButtonTheme>
                      </div>
                    </div>
                  </Card>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <ButtonTheme type="button" className="btn w-sm btn-light" onClick={handleClose} disabled={loading}>
                {t("Close")}
              </ButtonTheme>
              <ButtonTheme
                type="submit"
                className="btn w-sm btn-primary"
                // onClick={handleImportFile}
                disabled={loading}
                loading={loading}
              >
                {t("Import file")}
              </ButtonTheme>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

ImportCSVModal.propTypes = {
  onCloseClick: PropTypes.func,
  data: PropTypes.any,
  show: PropTypes.any,
};

export default ImportCSVModal;
