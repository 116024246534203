import { Fragment, useEffect, useMemo } from "react";
import SimpleTableContainer from "../../../../../Components/Common/SimpleTableContainer";
import { Card, CardBody, Input, Label } from "reactstrap";
import {
  DiscountAmountType,
  EffectiveProduct,
  EffectiveProductOptions,
} from "../../../../../Components/constants/common";
import DiscountRateValue from "./Columns/DiscountRateValue";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import DiscountDecimal from "./Columns/DiscountDecimal";
import DiscountInfoSearchProduct from "../../../../../Components/Common/DiscountInfoSearchProduct";
import SimpleBar from "simplebar-react";
import { deepCopy } from "../../../../../helpers/format_helper";
import { useTranslation } from "react-i18next";

const DiscountByQuantity = ({
  onDeleteConditionLine = () => {},
  onChangeConditionLine = () => {},
  onChangeConditionInfo = () => {},
  validation,
  data = null,
  isEdit = true,
}) => {
  const {t} =useTranslation()
  const handleAddQuantity = (index) => {
    const fakeData = deepCopy(data);
    fakeData[index].conditions.push({ quantity_from: 1, quantity_to: null, amount: null, rate: null, type: null });
    validation.setFieldValue("discount_by_quantity", fakeData);
  };

  const handleRemoveQuantity = (index, subIndex) => {
    const fakeData = deepCopy(data);
    fakeData[index].conditions.splice(subIndex, 1);
    if (fakeData[index].conditions.length === 0) {
      fakeData.splice(index, 1);
    }
    validation.setFieldValue("discount_by_quantity", fakeData);
  };

  const onChangeQuantityLine = (index, subIndex, newData) => {
    const fakeData = deepCopy(data);
    fakeData[index].conditions[subIndex] = { ...fakeData[index].conditions[subIndex], ...newData };
    validation.setFieldValue("discount_by_quantity", fakeData);
  };

  return (
    <Fragment>
      {isEdit && (
        <div>
          <Label>{t("Effective product")}</Label>
          <select
            className="form-select mb-3"
            onChange={(e) => {
              onChangeConditionInfo({ item_type: e.target.value });
            }}
            value={data?.[0]?.item_type || EffectiveProduct.PRODUCTS}
          >
            {EffectiveProductOptions.map((item) => (
              <option key={item.id} value={item.id}>
                {t(item.name)}
              </option>
            ))}
          </select>
        </div>
      )}
      <SimpleBar style={{ maxHeight: "600px" }}>
        {data &&
          data.map((item, index) => (
            <Fragment key={index}>
              <table className="table table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">{t("Purchased product")}</th>
                    <th scope="col" className="text-center col-2">
                      {t("Quantity from")}
                    </th>
                    <th scope="col" className="text-center col-2">
                      {t("Quantity to")}
                    </th>
                    <th scope="col" className="text-center col-2">
                      {t("Discount")}
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {item?.conditions &&
                    item?.conditions.map((subItem, subIndex) => (
                      <tr key={subIndex}>
                        {subIndex === 0 && (
                          <td rowSpan={item.conditions.length}>
                            <DiscountInfoSearchProduct
                              for_all_items={item?.for_all_items}
                              item_type={item?.item_type}
                              data={item}
                              index={index}
                              onChangeConditionLine={onChangeConditionLine}
                              isEdit={isEdit}
                            />
                          </td>
                        )}
                        <td>
                          <DiscountDecimal
                            data={subItem}
                            index={index}
                            subIndex={subIndex}
                            field={"quantity_from"}
                            onChangeConditionLine={onChangeConditionLine}
                            onChangeQuantityLine={onChangeQuantityLine}
                            className={"text-center"}
                            disabled={!isEdit}
                          />
                        </td>
                        <td>
                          <DiscountDecimal
                            data={subItem}
                            index={index}
                            subIndex={subIndex}
                            field={"quantity_to"}
                            onChangeConditionLine={onChangeConditionLine}
                            onChangeQuantityLine={onChangeQuantityLine}
                            className={"text-center"}
                            disabled={!isEdit}
                          />
                        </td>
                        <td>
                          <DiscountRateValue
                            data={subItem}
                            index={index}
                            subIndex={subIndex}
                            onChangeQuantityLine={onChangeQuantityLine}
                            onChangeConditionLine={onChangeConditionLine}
                            className={"text-end"}
                            disabled={!isEdit}
                          />
                        </td>
                        <td>
                          <div className="fw-medium text-end">
                            {isEdit ? (
                              <ButtonTheme
                                type="button"
                                className="btn btn-outline-danger btn-icon waves-effect waves-light"
                                onClick={() => {
                                  handleRemoveQuantity(index, subIndex);
                                }}
                              >
                                <i className="ri-delete-bin-5-line"></i>
                              </ButtonTheme>
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {isEdit && (
                <ButtonTheme
                  className="btn btn-ghost-primary float-end"
                  onClick={() => {
                    handleAddQuantity(index);
                  }}
                >
                  <i className="ri-add-circle-line align-bottom"></i> {t("Add quantity")}
                </ButtonTheme>
              )}
            </Fragment>
          ))}
      </SimpleBar>
    </Fragment>
  );
};
export default DiscountByQuantity;
