import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import ButtonTheme from "./ButtonTheme";
import { useDispatch } from "react-redux";
import { handleOpenTour } from "../../store/actions";

const GuidelineButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div className="ms-1 header-item d-sm-flex">
      <Tooltip title={t("Guideline")}>
        <ButtonTheme
          type="button"
          onClick={() => {
            dispatch(handleOpenTour());
          }}
          className="btn btn-icon rounded-circle light-dark-mode"
        >
          <i className="ri-question-fill fs-24"></i>
        </ButtonTheme>
      </Tooltip>
    </div>
  );
};
export default GuidelineButton;
