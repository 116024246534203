// Actions
export const LIST_SALE_CHANNEL = "LIST_SALE_CHANNEL";
export const LIST_SALE_CHANNEL_SUCCESS = "LIST_SALE_CHANNEL_SUCCESS";
export const LIST_SALE_CHANNEL_FAIL = "LIST_SALE_CHANNEL_FAIL";

export const GET_SALE_CHANNEL = "GET_SALE_CHANNEL";
export const GET_SALE_CHANNEL_SUCCESS = "GET_SALE_CHANNEL_SUCCESS";
export const GET_SALE_CHANNEL_FAIL = "GET_SALE_CHANNEL_FAIL";

/**
 * Delete SALE_CHANNEL
 */
export const DELETE_SALE_CHANNEL = "DELETE_SALE_CHANNEL";
export const DELETE_SALE_CHANNEL_SUCCESS = "DELETE_SALE_CHANNEL_SUCCESS";
export const DELETE_SALE_CHANNEL_FAIL = "DELETE_SALE_CHANNEL_FAIL";

/**
 * Add SALE_CHANNEL
 */
export const ADD_NEW_SALE_CHANNEL = "ADD_NEW_SALE_CHANNEL";
export const ADD_SALE_CHANNEL_SUCCESS = "ADD_SALE_CHANNEL_SUCCESS";
export const ADD_SALE_CHANNEL_FAIL = "ADD_SALE_CHANNEL_FAIL";

/**
 * Edit SALE_CHANNEL
 */
export const UPDATE_SALE_CHANNEL = "UPDATE_SALE_CHANNEL";
export const UPDATE_SALE_CHANNEL_SUCCESS = "UPDATE_SALE_CHANNEL_SUCCESS";
export const UPDATE_SALE_CHANNEL_FAIL = "UPDATE_SALE_CHANNEL_FAIL";
