import {
  LIST_CUSTOMER_GROUP,
  LIST_CUSTOMER_GROUP_FAIL,
  LIST_CUSTOMER_GROUP_SUCCESS,
  DELETE_CUSTOMER_GROUP,
  DELETE_CUSTOMER_GROUP_SUCCESS,
  DELETE_CUSTOMER_GROUP_FAIL,
  ADD_NEW_CUSTOMER_GROUP,
  ADD_CUSTOMER_GROUP_SUCCESS,
  ADD_CUSTOMER_GROUP_FAIL,
  UPDATE_CUSTOMER_GROUP,
  UPDATE_CUSTOMER_GROUP_SUCCESS,
  UPDATE_CUSTOMER_GROUP_FAIL,
  GET_CUSTOMER_GROUP,
  GET_CUSTOMER_GROUP_FAIL,
  GET_CUSTOMER_GROUP_SUCCESS,
  RESET_CUSTOMER_GROUP_FLAG,
} from "./actionType";

export const listCustomerGroup = (options) => ({
  type: LIST_CUSTOMER_GROUP,
  payload: options,
});

export const listCustomerGroupFail = (error) => ({
  type: LIST_CUSTOMER_GROUP_FAIL,
  payload: error,
});

export const listCustomerGroupSuccess = (customerGroups) => ({
  type: LIST_CUSTOMER_GROUP_SUCCESS,
  payload: customerGroups,
});

export const getCustomerGroup = (customerGroupID) => ({
  type: GET_CUSTOMER_GROUP,
  payload: customerGroupID,
});

export const getCustomerGroupFail = (error) => ({
  type: GET_CUSTOMER_GROUP_FAIL,
  payload: error,
});

export const getCustomerGroupSuccess = (customerGroup) => ({
  type: GET_CUSTOMER_GROUP_SUCCESS,
  payload: customerGroup,
});

export const deleteCustomerGroup = (customerGroup, handleAfterSubmit = () => {}) => ({
  type: DELETE_CUSTOMER_GROUP,
  payload: { customerGroup, handleAfterSubmit },
});

export const deleteCustomerGroupSuccess = (customerGroup) => ({
  type: DELETE_CUSTOMER_GROUP_SUCCESS,
  payload: customerGroup,
});

export const deleteCustomerGroupFail = (error) => ({
  type: DELETE_CUSTOMER_GROUP_FAIL,
  payload: error,
});

export const updateCustomerGroup = (customerGroup, handleAfterSubmit = () => {}) => ({
  type: UPDATE_CUSTOMER_GROUP,
  payload: { customerGroup, handleAfterSubmit },
});

export const updateCustomerGroupSuccess = (customerGroup) => ({
  type: UPDATE_CUSTOMER_GROUP_SUCCESS,
  payload: customerGroup,
});

export const updateCustomerGroupFail = (error) => ({
  type: UPDATE_CUSTOMER_GROUP_FAIL,
  payload: error,
});

export const addNewCustomerGroup = (customerGroup, handleAfterSubmit = () => {}) => ({
  type: ADD_NEW_CUSTOMER_GROUP,
  payload: { customerGroup, handleAfterSubmit },
});

export const addCustomerGroupSuccess = (customerGroup) => ({
  type: ADD_CUSTOMER_GROUP_SUCCESS,
  payload: customerGroup,
});

export const addCustomerGroupFail = (error) => ({
  type: ADD_CUSTOMER_GROUP_FAIL,
  payload: error,
});

export const resetCustomerGroup = () => ({
  type: RESET_CUSTOMER_GROUP_FLAG,
});
