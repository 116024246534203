import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { REGISTER_USER, RESEND_CONFIRMATION_CODE, VERIFY_CONFIRMATION_CODE } from "./actionTypes";
import {
  registerUserSuccessful,
  registerUserFailed,
  verifyConfirmationCodeSuccessful,
  verifyConfirmationCodeFailed,
  resendConfirmationCodeSuccess,
  resendConfirmationCodeFailed,
} from "./actions";

//Include Both Helper File with needed methods
import { confirmCodeAPI, resendCodeAPI, signUpAPI } from "../../../helpers/AuthAPI";

function* registerUser({ payload: { user } }) {
  try {
    const newUser = {
      username: user.user_name,
      password: user.password,
      email: user.email,
    };
    const response = yield call(signUpAPI, newUser);
    if (response) {
      yield put(registerUserSuccessful(response));
    } else {
      yield put(registerUserFailed(response));
    }
  } catch (error) {
    const errorString = String(error);
    if (errorString.includes(":")) {
      yield put(registerUserFailed(errorString.split(":")?.at(1).trim()));
    } else {
      yield put(registerUserFailed(errorString));
    }
  }
}

// send confirmation code
function* sendConfirmationCode({ payload }) {
  try {
    // console.debug("confirmationPayload:", username, code);
    const response = yield call(confirmCodeAPI, payload);
    yield put(verifyConfirmationCodeSuccessful(response));
  } catch (error) {
    // console.error("sendConfirmationCode error:", error);
    const errorString = String(error);
    if (errorString.includes(":")) {
      yield put(verifyConfirmationCodeFailed(errorString.split(":")?.at(1).trim()));
    } else {
      yield put(verifyConfirmationCodeFailed(errorString));
    }
  }
}

// resend confirmation code
function* resendConfirmationCode({ payload: { username } }) {
  try {
    const response = yield call(resendCodeAPI, { username });
    console.debug("response:", response);
    yield put(resendConfirmationCodeSuccess(response.message));
  } catch (error) {
    console.error("resendConfirmationCode error:", error);
    const errorStr = String(error);
    if (errorStr.includes(":")) {
      yield put(resendConfirmationCodeFailed(errorStr.split(":")?.at(1).trim()));
    } else {
      yield put(resendConfirmationCodeFailed(errorStr));
    }
    // yield put(apiError(error));
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser);
  yield takeEvery(VERIFY_CONFIRMATION_CODE, sendConfirmationCode);
  yield takeEvery(RESEND_CONFIRMATION_CODE, resendConfirmationCode);
}

function* accountSaga() {
  yield all([fork(watchUserRegister)]);
}

export default accountSaga;
