import { useState } from "react";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";

const CopyableTextTooltip = ({ text, children }) => {
  const [copy, setCopy] = useState("Nhấn để sao chép");
  return (
    <Tooltip
      onOpenChange={() => {
        setCopy("Nhấn để sao chép");
      }}
      title={copy}
    >
      <Link
        onClick={() => {
          setCopy("Đã sao chép");
          navigator.clipboard.writeText(text);
        }}
      >
        {children}
      </Link>
    </Tooltip>
  );
};
export default CopyableTextTooltip;
