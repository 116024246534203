import { Table } from "reactstrap";

const CustomTable = ({ headerGroups, getTableProps, getTableBodyProps, page, prepareRow, isPrice }) => {
  return (
    <div className="table-responsive">
      <Table {...getTableProps()} className="mb-0 align-middle table-bordered rounded-1">
        <thead className="table-light text-muted text-center">
          {headerGroups.map((headerGroup) => {
            const { key, ...rowProps } = headerGroup.getHeaderGroupProps(); // Extract key separately
            return (
              <tr key={`${key}-${isPrice ? "price" : "measurement"}`} {...rowProps}>
                {headerGroup.headers.map((column, index) => {
                  return (
                    <th {...column.getSortByToggleProps()} key={`${index}-${isPrice ? "price" : "measurement"}`} >
                      {column.render("Header")}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const { key, ...rowProps } = row.getRowProps(); // Extract key separately
            return (
              <tr key={`${key}-${isPrice ? "price" : "measurement"}`} {...rowProps}>
                {row.cells.map((cell) => {
                  const { key: cellKey, ...cellProps } = cell.getCellProps(); // Extract key separately
                  return (
                    <td key={`${cellKey}-${isPrice ? "price" : "measurement"}`} {...cellProps}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default CustomTable;
