import { useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { calculateDiscountByCustomerGroup, deepCopy, formatBytes } from "../../helpers/format_helper";
import { discountsAPI, variantsAPI } from "../../helpers/resource_helper";

import Order from "./Components/Order";
import POSHeader from "./Components/POSHeader";
import OptionsMenu from "./Components/OptionsMenu";
import RightSideBar from "./Components/RightSideBar";
import withRouter from "../../Components/Common/withRouter";
import { useProfile } from "../../Components/Hooks/useProfile";
import AllActionModal from "./Components/Modal/AllActionModal";
import useMemoizedSelector from "../../Components/Hooks/useMemoizedSelector";

import { getDraftOrder, listPaymentMethod, listPriceGroup, updateDraftOrder } from "../../store/actions";
import ReportModal from "./Components/Modal/ReportModal";
import CustomerInfoModal from "./Components/Modal/CustomerInfoModal";
import PriceSelectForm from "./Components/Modal/PriceSelectForm";
import config from "../../config";
import ShiftModal from "./Components/Modal/ShiftModal";
import { useDebounce, useDebounceFunction } from "../../Components/Hooks/UseDebounce";
import { OrderTypes } from "../../Components/constants/common";
import ReturnOrderModal from "../Ecommerce/Orders/Components/ReturnOrderModal";
import PromotionModal from "../Ecommerce/Orders/Components/PromotionModal";
import { getEffectiveRewardProgramAPI, listVariantsByOriginalSku } from "../../helpers/service_helper";
import { LogoLight } from "../../assets/images/svg";

const POS = (props) => {
  const {
    router: { location, navigate, params, t },
    selectedLocation,
    handleChangeLocation,
    cloneOrder,
  } = props;
  document.title = `${t("Point Of Sales")} | OptiWarehouse`;
  const { userProfile } = useProfile();
  const user_id = userProfile.UserAttributes["sub"];
  const username = userProfile.Username;
  const [socket, setSocket] = useState(null);
  const { dataShop } = useProfile();
  const [logo, setLogo] = useState(null);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [rewardProgram, setRewardProgram] = useState(null);

  useEffect(() => {
    const value = dataShop?.setting_value;
    setLogo(value?.store_logo_light?.url || LogoLight);
    setName(value?.store_name || "OptiWarehouse");
    setAddress(
      `${value?.store_address ? value?.store_address + ", " : ""}${
        value?.store_wards ? value?.store_wards + ", " : ""
      }${value?.store_districts ? value?.store_districts + ", " : ""}${
        value?.store_provinces ? value?.store_provinces : ""
      }` || "---",
    );
  }, [dataShop]);

  const store_info = {
    store_logo: logo,
    sore_name: name,
    store_address: address,
  };

  useEffect(() => {
    if (!user_id) {
      return;
    }
    const ws = new WebSocket(`${config.ws_url}?user_id=${user_id}`);
    ws.onopen = (event, context) => {
      console.log("Connection Opened!");
    };
    ws.onclose = () => {
      console.log("Connection Closed!");
    };
    ws.onmessage = (event) => {
      console.log("New message!");
    };

    ws.onerror = () => {
      console.log("WS Error");
    };

    window.addEventListener("beforeunload", () => {
      ws.close();
    });
    setSocket(ws);

    return () => {
      ws.close();
    };
  }, []);

  window.onbeforeunload = () => {
    socket && socket.close();
  };

  const dispatch = useDispatch();

  const getEffectiveRewardProgram = async () => {
    try {
      const res = await getEffectiveRewardProgramAPI();
      if (res) {
        const { created_at, updated_at, company_id, user, ...rest } = res;
        setRewardProgram(rest);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    dispatch(listPaymentMethod({ limit: 20 }));
    dispatch(listPriceGroup({ limit: 200 }));
    getEffectiveRewardProgram();
  }, [dispatch]);

  const { order, ordersLoading, ordersMessage, ordersSuccess, ordersError } = useMemoizedSelector((state) => ({
    order: state.Orders.order,
    ordersMessage: state.Orders.message,
    ordersLoading: state.Orders.loading,
    ordersSuccess: state.Orders.success,
    ordersError: state.Orders.error,
  }));

  const { paymentMethods, paymentMethodsLoading, paymentMethodsSuccess, paymentMethodsError } = useMemoizedSelector(
    (state) => ({
      paymentMethods: state.PaymentMethods.paymentMethods,
      paymentMethodsLoading: state.PaymentMethods.loading,
      paymentMethodsSuccess: state.PaymentMethods.success,
      paymentMethodsError: state.PaymentMethods.error,
    }),
  );

  const { priceGroups, priceGroupsLoading, priceGroupsSuccess, priceGroupsError } = useMemoizedSelector((state) => ({
    priceGroups: state.PriceGroups.priceGroups,
    priceGroupsLoading: state.PriceGroups.loading,
    priceGroupsSuccess: state.PriceGroups.success,
    priceGroupsError: state.PriceGroups.error,
  }));

  const { discount, discountsLoading, discountsSuccess, discountsError } = useMemoizedSelector((state) => ({
    discount: state.Discounts.discount,
    discountsLoading: state.Discounts.loading,
    discountsSuccess: state.Discounts.success,
    discountsError: state.Discounts.error,
  }));

  const { draftOrder, draftOrdersLoading, draftOrdersSuccess, draftOrdersError } = useMemoizedSelector((state) => ({
    draftOrder: state.DraftOrders.draftOrder,
    draftOrdersLoading: state.DraftOrders.loading,
    draftOrdersSuccess: state.DraftOrders.success,
    draftOrdersError: state.DraftOrders.error,
  }));

  const { loadingVersion, version } = useMemoizedSelector((state) => ({
    loadingVersion: state.Version.loading,
    version: state.Version.version,
  }));

  const scrollContainerRef = useRef({});
  const refShortcutButton = useRef(null);
  const refShortcutButtonRightMenu = useRef(null);
  const refShortcutButtonOrder = useRef(null);
  const refShortcutButtonHeader = useRef(null);

  const [selectedOrder, setSelectedOrder] = useState();
  const [currentOrders, setCurrentOrders] = useState([]);
  const debouncedCurrentOrders = useDebounce(currentOrders, 1000);

  const [selectedStaff, setSelectedStaff] = useState({ id: user_id, name: username });
  const [selectedPriceGroup, setSelectedPriceGroup] = useState(null);
  const [discountLoading, setDiscountLoading] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);
  const [showPromotionModal, setShowPromotionModal] = useState(false);
  const [showDropDownProductMenu, setShowDropDownProductMenu] = useState(false);
  const [showAllActionModal, setShowAllActionModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showShiftModal, setShowShiftModal] = useState(false);
  const [showCustomerInfoModal, setShowCustomerInfoModal] = useState(false);
  const [showReturnModal, setShowReturnModal] = useState(false);
  const [showPriceGroupModal, setShowPriceGroupModal] = useState(false);

  const toggleReturnModal = () => {
    setShowReturnModal(!showReturnModal);
  };
  const handleChangePriceGroup = (data) => {
    setSelectedPriceGroup(data);
    if (data.value !== selectedPriceGroup?.value) {
      const newOrders = currentOrders.map((item) => {
        const new_order_line_items = item.order_line_items.map((order_line_item) => {
          const new_price = (order_line_item?.prices ?? []).find(
            (item_price) => item_price.price_group.id === data?.value,
          )?.price;
          order_line_item.sale_price = new_price;
          order_line_item.unit_price = new_price;
          return order_line_item;
        });
        item.order_line_items = new_order_line_items;
        reCalculateOrderDetails(item);
        return item;
      });
      setCurrentOrders(newOrders);
    }
  };

  useImperativeHandle(refShortcutButton, () => ({
    onTogglePromotion: () => {
      togglePromotionModal();
    },
    onAddService: () => {
      addProductToCart();
    },
    onRemoveAllProducts: () => {
      handleDeleteAllProducts();
    },
    onToggleReport: () => {
      setShowReportModal(true);
    },
    onToggleAllAction: () => {
      toggleAllActionModal();
    },
    onToggleReturnModal: () => {
      toggleReturnModal();
    },
    onToggleCustomerInfo: () => {
      if (!currentOrders?.[selectedOrder]?.customer) {
        toast.error(t("Please select customer"));
        return;
      }
      setShowCustomerInfoModal(true);
    },
    onTogglePriceGroup: () => {
      setShowPriceGroupModal(true);
    },
    onToggleShift: () => {
      setShowShiftModal(true);
    },
  }));

  let keyDownFlag = false;
  const handleKeyUp = (e) => {
    keyDownFlag = false;
  };
  const handleKeyDown = (e) => {
    if (ordersLoading || draftOrdersLoading) {
      return;
    }

    if (keyDownFlag) {
      return;
    }
    keyDownFlag = true;

    const keyPressed = e.key;
    if (keyPressed === "F1") {
      e.preventDefault();
      refShortcutButtonRightMenu.current.onPay();
    }
    if (keyPressed === "F3") {
      e.preventDefault();
      refShortcutButtonOrder.current.onFocusProductSearch();
    }
    if (keyPressed === "F4") {
      e.preventDefault();
      refShortcutButtonRightMenu.current.onFocusCustomerSearch();
    }
    if (keyPressed === "F6") {
      e.preventDefault();
      refShortcutButtonRightMenu.current.toggleDiscount();
    }
    if (keyPressed === "F7") {
      e.preventDefault();

      refShortcutButtonRightMenu.current.togglePaymentMethod();
    }
    if (keyPressed === "F8") {
      e.preventDefault();

      refShortcutButton.current.onTogglePromotion();
    }
    if (keyPressed === "F9") {
      e.preventDefault();
      refShortcutButton.current.onAddService();
    }
    if (keyPressed === "F11") {
      e.preventDefault();
      refShortcutButtonHeader.current.onFullScreen();
    }
    if (e.altKey && keyPressed === "4") {
      e.preventDefault();
      refShortcutButton.current.onRemoveAllProducts();
    }
  };

  const togglePromotionModal = () => {
    if (currentOrders[selectedOrder].order_line_items.length === 0) {
      toast.error(t("Please select product for your order"));
      return;
    }
    setShowPromotionModal(!showPromotionModal);
  };

  const scrollToRightEnd = () => {
    scrollContainerRef.current?.scrollToItem?.(
      scrollContainerRef.current?.getItemElementById(currentOrders.slice(-1)?.[0]?.id),
    );
  };

  const newOrder = {
    order_line_items: [],
    customer: null,
    billing_address: null,
    shipping_address: null,
    location: { id: selectedLocation.value, name: selectedLocation.label },
    staff: selectedStaff,
    total: 0,
    sub_total: 0,
    payments: null,
    discount: 0,
    note: "",
    tags: null,
    given_cash: 0,
    excess_cash: 0,
    discount_by_customer_group: 0,
    applying_loyal_discount: true,
    percent_order_discount: null,
  };

  const addNewCurrentOrders = () => {
    const ids = currentOrders.map((item) => item.order_number);
    const max_id = ids.length > 0 ? Math.max(...ids) : 1;
    setCurrentOrders([...currentOrders, { ...newOrder, order_number: Number(max_id) + 1 }]);
    setSelectedOrder(currentOrders.length);
    scrollToRightEnd();
  };
  const deleteCurrentOrder = () => {
    if (selectedOrder > 1) {
      setSelectedOrder(selectedOrder - 1);
    } else {
      setSelectedOrder(0);
    }
    let newData = currentOrders.filter((item, i) => i !== selectedOrder);
    if (newData.length === 0) {
      newData.push({ ...newOrder, order_number: 1 });
    }
    setCurrentOrders(newData);
  };

  const deleteOrder = (index) => {
    if (index > 1) {
      setSelectedOrder(index - 1);
    } else {
      setSelectedOrder(0);
    }
    let newData = currentOrders.filter((item, i) => i !== index);
    if (newData.length === 0) {
      newData.push({ ...newOrder, order_number: 1 });
    }
    setCurrentOrders(newData);
  };

  const handleResetDiscount = () => {
    handleChangeDiscountId(null);
    handleChangeDiscountDetails([]);
  };

  const handleChangeCurrentOrder = (data) => {
    let newData = deepCopy(currentOrders);
    newData[selectedOrder] = data;
    setCurrentOrders(newData);
  };
  const handleChangeOrderLineItem = useCallback(
    (index, data) => {
      let newData = deepCopy(currentOrders);
      newData[selectedOrder].order_line_items[index] = data;
      reCalculateOrderDetails(newData[selectedOrder]);
      setCurrentOrders(newData);
    },
    [currentOrders],
  );

  const handleChangeOrderLineItemUnit = useCallback(
    (index, data) => {
      let newData = deepCopy(currentOrders);
      const check = newData[selectedOrder].order_line_items.find((item, i) => i !== index && item.sku === data.sku);
      if (check) {
        toast.error("Available product in cart!");
        newData[selectedOrder].order_line_items.splice(index, 1);
      } else {
        newData[selectedOrder].order_line_items[index] = data;
      }
      reCalculateOrderDetails(newData[selectedOrder]);
      setCurrentOrders(newData);
    },
    [currentOrders],
  );

  const toggleAllActionModal = () => {
    setShowAllActionModal(!showAllActionModal);
  };

  const handleDeleteAllProducts = () => {
    const newData = currentOrders.map((item, index) => {
      if (index === selectedOrder) {
        return {
          ...item,
          order_line_items: [],
          staff: selectedStaff,
          total: 0,
          sub_total: 0,
          payments: null,
          discount: 0,
          given_cash: 0,
          excess_cash: 0,
          redeem_point: null,
        };
      }
      return item;
    });
    setCurrentOrders(newData);
  };

  const getDiscountData = async (data) => {
    try {
      setDiscountLoading(true);
      if (data) {
        const res = await discountsAPI.get({ id: currentOrders[selectedOrder].discount_id });
        setCurrentOrders((pre) => {
          pre[selectedOrder].discount_object = res;
          return pre;
        });
      } else {
        setCurrentOrders((pre) => {
          delete pre[selectedOrder]["discount_object"];
          return pre;
        });
      }
    } catch (e) {
      toast.error(e.toString());
    } finally {
      setDiscountLoading(false);
    }
  };

  const handleChangeDiscountDetails = (data) => {
    setCurrentOrders((pre) => {
      if (data && data.length > 0) {
        pre[selectedOrder].discount_details = data;
      } else {
        delete pre[selectedOrder]["discount_details"];
      }
      return pre;
    });
  };

  const handleChangeDiscountId = (data) => {
    setCurrentOrders((pre) => {
      pre[selectedOrder].discount_id = data;
      return pre;
    });
    getDiscountData(data);
  };

  const toggleCollapse = () => {
    setIsCollapse(!isCollapse);
  };

  const toggleOffDropDownProductMenu = () => {
    setShowDropDownProductMenu(false);
  };
  const toggleOnDropDownProductMenu = () => {
    setShowDropDownProductMenu(true);
  };
  const handleClickAddProductNow = () => {
    toggleOnDropDownProductMenu();
    document.getElementById("search-products").focus();
  };

  const handleChangeSelectedOrder = (index) => {
    setSelectedOrder(index);
  };

  const handleChangeStaff = (data) => {
    setSelectedStaff(data);
  };

  // cart
  const addProductToCart = async (selectedProduct, handleAfterSelect = () => {}) => {
    const available =
      (selectedProduct?.inventories || []).find((item) => item.location_id === selectedLocation.value)?.available || 0;
    if (selectedProduct && available <= 0) {
      toast.error(t("This product is currently out of stock"));
      return;
    }
    const isGreaterThanInv =
      (currentOrders[selectedOrder]["order_line_items"].find((item) => item.sku === selectedProduct?.sku)?.quantity ||
        0) >= available;
    if (selectedProduct && isGreaterThanInv) {
      toast.error(t("This product is currently not enough stock"));
      return;
    }
    if (selectedProduct?.original_sku) {
      const hasSameOriginSku = currentOrders[selectedOrder]["order_line_items"].find(
        (item) => item?.original_sku === selectedProduct.original_sku && item?.sku !== selectedProduct.sku,
      );
      if (hasSameOriginSku) {
        toast.error(t("This product is already in the cart!"));
        return;
      }
    }

    let variants_with_same_original_sku;
    if (!selectedProduct?.custom && selectedProduct?.original_sku) {
      const variantWithOtherUnits = await listVariantsByOriginalSku(selectedProduct.original_sku);
      if (variantWithOtherUnits) {
        variants_with_same_original_sku = variantWithOtherUnits?.items ?? [];
      }
    }

    let number;
    let newData = currentOrders.map((item, i) => {
      if (i === selectedOrder) {
        if (selectedProduct) {
          let orderItemIndex = item.order_line_items.findIndex((orderItem) => orderItem.sku === selectedProduct.sku);
          if (orderItemIndex > -1) {
            const new_quantity = Number(item.order_line_items[orderItemIndex].quantity);
            item.order_line_items[orderItemIndex].prices = selectedProduct?.prices ?? [];
            item.order_line_items[orderItemIndex].quantity = new_quantity >= available ? available : new_quantity + 1;
            number = item.order_line_items.length - orderItemIndex;
            reCalculateOrderDetails(item);
          } else {
            const price = (selectedProduct?.prices || []).find(
              (priceItem) => priceItem.price_group.id === (selectedPriceGroup?.value || selectedPriceGroup?.id),
            )?.price;
            let newItem = {
              prices: selectedProduct.prices ?? [],
              unit_price: price || selectedProduct?.prices?.[0]?.price || 0,
              sale_price: price || selectedProduct?.prices?.[0]?.price || 0,
              sku: selectedProduct?.sku ?? "",
              name: selectedProduct?.name ?? "",
              variant_name: selectedProduct?.name ?? "",
              image_url: selectedProduct.images?.[0]?.url ?? "",
              location: selectedLocation?.value
                ? { id: selectedLocation?.value, name: selectedLocation?.label }
                : selectedLocation,
              category: selectedProduct?.category
                ? { id: selectedProduct?.category?.id, name: selectedProduct?.category?.name }
                : null,
              brand: selectedProduct?.brand
                ? { id: selectedProduct?.brand?.id, name: selectedProduct?.brand?.name }
                : null,
              quantity: 1,
              original_sku: selectedProduct?.original_sku ?? "",
              variants_with_same_original_sku: variants_with_same_original_sku ?? null,
              available,
            };
            newItem.product_id = selectedProduct.product_id;
            newItem.variant_id = selectedProduct.id;
            item.order_line_items.unshift(newItem);
            number = item.order_line_items.length;
            reCalculateOrderDetails(item);
          }
        } else {
          let newItem = {
            unit_price: 0,
            sale_price: 0,
            sku: crypto.randomUUID(),
            name: "",
            variant_name: "",
            image_url: null,
            location: selectedLocation?.value
              ? { id: selectedLocation?.value, name: selectedLocation?.label }
              : selectedLocation,
            quantity: 1,
            custom: true,
          };
          item.order_line_items.unshift(newItem);
          number = item.order_line_items.length;
        }
      }
      return item;
    });
    setCurrentOrders(newData);
    handleResetDiscount();
    setTimeout(() => {
      document.getElementById(`pos-item-${number}`).focus();
    }, 100);
    handleAfterSelect();
  };

  const handleChangeQuantity = (number, selectedProductIndex) => {
    const selectedProduct = currentOrders?.[selectedOrder]?.order_line_items?.[selectedProductIndex];
    const available = selectedProduct?.available || 0;
    if (!selectedProduct?.custom && available < number) {
      toast.error(t("This product is currently not enough stock"));
    }
    const newData = currentOrders.map((item, i) => {
      if (i === selectedOrder) {
        let orderItemIndex = item.order_line_items.findIndex((orderItem, index) => index === selectedProductIndex);
        if (orderItemIndex > -1) {
          item.order_line_items[orderItemIndex].quantity =
            !selectedProduct?.custom && available < number ? available : number;
          item.excess_cash = 0;
          item.given_cash = 0;
          reCalculateOrderDetails(item);
        }
      }
      return item;
    });
    setCurrentOrders(newData);
    handleResetDiscount();
  };
  const removeProductFromCart = (selectedProductIndex) => {
    const newData = currentOrders.map((item, i) => {
      if (i === selectedOrder) {
        let newOrderItems = item.order_line_items.filter((item, index) => index !== selectedProductIndex);
        item.order_line_items = newOrderItems;
        item.excess_cash = 0;
        item.given_cash = 0;
        reCalculateOrderDetails(item);
      }
      return item;
    });
    setCurrentOrders(newData);
    handleResetDiscount();
  };

  const reCalculateOrderDetails = (item) => {
    item.sub_total = item.order_line_items.reduce((total, item) => total + item.sale_price * item.quantity, 0);

    const loyaltyDiscount = calculateDiscountByCustomerGroup(
      item?.customer?.customer_group,
      item?.customer,
      item?.sub_total,
      item?.applying_loyal_discount,
    );
    item.discount_by_customer_group = loyaltyDiscount;

    if (item.discount > item.sub_total - item.discount_by_customer_group) {
      item.discount = 0;
    }
    item.total = item.sub_total - item.discount - item.discount_by_customer_group;
    if ((item?.payments ?? []).length === 1) {
      item.payments[0].amount = item.total;
      if (item.reward_program && item.redeem_point) {
        const discount_by_redeem_point = item.reward_program.money * item.redeem_point;
        if (discount_by_redeem_point > item.total) {
          item.reward_program = null;
          item.redeem_point = 0;
          toast.error("Redeem amount must less than or equal to order total!");
        } else {
          item.payments[0].amount -= discount_by_redeem_point;
        }
      }
    } else {
      item.payments = null;
    }
  };

  useEffect(() => {
    if (selectedLocation) {
      let newData = currentOrders.map((item) => {
        // item.location_id = selectedLocation;
        item.order_line_items = item.order_line_items.map((product) => {
          product.location = selectedLocation ? { id: selectedLocation.value, name: selectedLocation.label } : null;
          return product;
        });
        return item;
      });
      setCurrentOrders(newData);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (!priceGroupsLoading) {
      const retailPrice = priceGroups.find((item) => item.name === "Giá bán lẻ");
      const newData = {
        label:
          version?.data?.dict?.shop_info?.setting_value?.store_default_price_group?.name ||
          retailPrice?.name ||
          priceGroups?.[0]?.name ||
          null,
        value:
          version?.data?.dict?.shop_info?.setting_value?.store_default_price_group?.id ||
          retailPrice?.id ||
          priceGroups?.[0]?.id ||
          null,
      };
      setSelectedPriceGroup(newData);
    }
  }, [version, priceGroups, priceGroupsLoading]);

  const sendPayNotification = async (data) => {
    try {
      const url_link = `${window.location.origin}/order-qr/${data.id}`;
      if (socket && socket.readyState === WebSocket.OPEN) {
        const payload = {
          action: "sendMessage",
          data: {
            type: "PAY",
            pay_url: url_link,
            user_id: user_id,
            ...store_info,
          },
        };
        socket.send(JSON.stringify(payload));
      }
    } catch (e) {
      toast.error("Get qr fail");
    }
  };

  const updateOrderMessage = {
    action: "sendMessage",
    data: {
      type: "UPDATE_ORDER",
      active_order: currentOrders?.[selectedOrder] || null,
      user_id: user_id,
      ...store_info,
    },
  };
  useEffect(() => {
    localStorage.setItem("local_selected_order", JSON.stringify(selectedOrder));
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(updateOrderMessage));
    }
  }, [selectedOrder]);

  const saveDraftOrders = (data) => {
    if (draftOrder?.id) {
      const payload = { ...draftOrder, draft_orders: data };
      dispatch(updateDraftOrder(payload));
    }
  };

  useEffect(() => {
    if (currentOrders.length === 0) {
      addNewCurrentOrders();
    } else {
      saveDraftOrders(currentOrders);
    }

    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(updateOrderMessage));
    }
  }, [debouncedCurrentOrders]);

  useEffect(() => {
    dispatch(getDraftOrder());
  }, []);

  useEffect(() => {
    let newData = draftOrder?.draft_orders || [];
    if (!cloneOrder) {
      setCurrentOrders(newData);
      return;
    }
    const order_line_items = (cloneOrder?.order_line_items ?? []).map((item) => {
      let newItem = {
        prices: item.prices ?? [],
        unit_price: item?.unit_price ?? 0,
        sale_price: item?.sale_price ?? 0,
        sku: item?.sku ?? "",
        name: item?.name ?? "",
        variant_name: item?.variant_name ?? "",
        image_url: item.image_url ?? "",
        location: selectedLocation?.value
          ? { id: selectedLocation?.value, name: selectedLocation?.label }
          : selectedLocation,
        category: item?.category ?? null,
        brand: item?.brand ?? null,
        quantity: item?.quantity ?? 1,
        variants_with_same_original_sku: null,
      };
      return newItem;
    });
    const data = {
      ...newOrder,
      order_number: 1,
      order_line_items,
      customer: cloneOrder?.customer ?? null,
      billing_address: cloneOrder?.billing_address ?? null,
      shipping_address: cloneOrder?.shipping_address ?? null,
      total: cloneOrder?.total ?? 0,
      sub_total: cloneOrder?.sub_total ?? 0,
      discount: cloneOrder?.discount ?? 0,
      note: cloneOrder?.note ?? "",
      tags: cloneOrder?.tags ?? null,
      loyal_point: cloneOrder?.loyal_point ?? null,
      loyalty_program: cloneOrder?.loyalty_program ?? null,
      redeem_point: cloneOrder?.redeem_point ?? null,
      reward_program: cloneOrder?.reward_program ?? null,
    };
    setCurrentOrders([data]);
  }, [draftOrder]);

  return (
    <div
      className="overflow-hidden"
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      tabIndex="0"
      style={{ width: "100vw", height: "100vh" }}
    >
      <PromotionModal
        isOpen={showPromotionModal}
        toggle={togglePromotionModal}
        selectedDiscountId={currentOrders?.[selectedOrder]?.discount_id}
        onChangeDiscountId={handleChangeDiscountId}
        order={currentOrders?.[selectedOrder]}
        discountDetails={currentOrders?.[selectedOrder]?.discount_details}
        onChangeDiscountDetails={handleChangeDiscountDetails}
        selectedLocation={selectedLocation}
        selectedPriceGroup={selectedPriceGroup}
      />
      <ReportModal
        show={showReportModal}
        branch={selectedLocation?.value || selectedLocation?.id}
        onCloseClick={() => {
          setShowReportModal(false);
        }}
      />
      <ShiftModal
        show={showShiftModal}
        onCloseClick={() => {
          setShowShiftModal(false);
        }}
      />
      <AllActionModal
        show={showAllActionModal}
        onCloseClick={toggleAllActionModal}
        addProductToCart={addProductToCart}
        refShortcutButton={refShortcutButton}
        refShortcutButtonRightMenu={refShortcutButtonRightMenu}
        refShortcutButtonOrder={refShortcutButtonOrder}
        refShortcutButtonHeader={refShortcutButtonHeader}
      />
      <CustomerInfoModal
        customer={currentOrders?.[selectedOrder]?.customer}
        isOpen={showCustomerInfoModal}
        toggle={() => {
          setShowCustomerInfoModal(false);
        }}
      />
      <ReturnOrderModal isOpen={showReturnModal} toggle={toggleReturnModal} />
      <PriceSelectForm
        selectedPriceGroup={selectedPriceGroup}
        handleChangePriceGroup={handleChangePriceGroup}
        isOpen={showPriceGroupModal}
        toggle={() => {
          setShowPriceGroupModal(false);
        }}
      />
      <POSHeader
        selectedPriceGroup={selectedPriceGroup}
        refShortcutButtonHeader={refShortcutButtonHeader}
        selectedLocation={selectedLocation}
        handleChangeLocation={handleChangeLocation}
        handleChangeStaff={handleChangeStaff}
        selectedStaff={selectedStaff}
        currentOrders={currentOrders}
        selectedOrder={selectedOrder}
        handleChangeSelectedOrder={handleChangeSelectedOrder}
        deleteOrder={deleteOrder}
        addNewCurrentOrders={addNewCurrentOrders}
        addProductToCart={addProductToCart}
        showDropDownProductMenu={showDropDownProductMenu}
        toggleOffDropDownProductMenu={toggleOffDropDownProductMenu}
        toggleOnDropDownProductMenu={toggleOnDropDownProductMenu}
      />
      <div className="d-flex w-100" style={{ height: "calc(100vh - 50px)" }}>
        <div className="d-flex flex-column me-2 h-100" style={{ width: "calc(100% - 452px)" }}>
          <Order
            refShortcutButtonOrder={refShortcutButtonOrder}
            selectedOrder={currentOrders?.[selectedOrder]}
            discount={currentOrders?.[selectedOrder]?.discount_object}
            removeProductFromCart={removeProductFromCart}
            onChangeQuantity={handleChangeQuantity}
            onClickAddProductNow={handleClickAddProductNow}
            onChangeOrderLineItem={handleChangeOrderLineItem}
            onChangeOrderLineItemUnit={handleChangeOrderLineItemUnit}
            selectedLocation={selectedLocation}
            selectedPriceGroup={selectedPriceGroup}
          />
          <OptionsMenu
            isCollapse={isCollapse}
            toggleCollapse={toggleCollapse}
            addProductToCart={addProductToCart}
            refShortcutButtonRightMenu={refShortcutButtonRightMenu}
            refShortcutButton={refShortcutButton}
          />
        </div>

        <div className="h-100 flex-grow-1" style={{ width: "452px" }}>
          <RightSideBar
            refShortcutButtonRightMenu={refShortcutButtonRightMenu}
            togglePromotionModal={togglePromotionModal}
            discount={currentOrders[selectedOrder]?.discount_object}
            currentOrder={currentOrders[selectedOrder]}
            currentOrders={currentOrders}
            selectedOrder={selectedOrder}
            onChangeCurrentOrder={handleChangeCurrentOrder}
            deleteCurrentOrder={deleteCurrentOrder}
            sendPayNotification={sendPayNotification}
            selectedLocation={selectedLocation}
            cloneOrder={cloneOrder}
            rewardProgram={rewardProgram}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(POS);
