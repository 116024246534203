export const SIZE_TEMPLATE = [
  { id: "A4", name: "A4 paper size" },
  { id: "A5", name: "A5 paper size" },
  { id: "K57", name: "K57 paper size" },
  { id: "K80", name: "K80 paper size" },
];

export const TEMPLATE = [{ id: "ORDER", name: "Order" }];

export const CONFIG_SIZE = {
  A0: { width: 3179, height: 4494 },
  A1: { width: 2245, height: 3179 },
  A2: { width: 1587, height: 2245 },
  A3: { width: 1123, height: 1587 },
  A4: { width: 794, height: 1123 },
  A5: { width: 559, height: 794 },
  A6: { width: 397, height: 559 },
  A7: { width: 280, height: 397 },
  A8: { width: 197, height: 280 },
  A9: { width: 140, height: 197 },
  A10: { width: 98, height: 140 },
  K57: { width: 215, height: null }, // K57: 215 pixels width, height variable
  K80: { width: 302, height: null }, // K80: 302 pixels width, height variable
};
