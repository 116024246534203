import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import { Card, CardBody, Col, Container, Row } from "reactstrap";
import SimpleBar from "simplebar-react";

import SchemaForm from "../../../../Components/Common/SchemaForm";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import Loader from "../../../../Components/Common/Loader";

import { actionsAPI, appStoresAPI, connectionsAPI } from "../../../../helpers/resource_helper";
import { keyExists } from "../../../../helpers/format_helper";

import {
  getAuthLink,
  getAuthSchema,
  getSchemaService,
  postValidationAction,
  testConnection,
} from "../../../../helpers/service_helper";
import { useTranslation } from "react-i18next";

const resolveSchemaSettingRefs = (schema) => {
  if (typeof schema !== "object" || schema === null) {
    return null;
  }
  const refPath = schema.$ref;
  if (!refPath.startsWith("#/definitions/")) {
    throw new Error(`Invalid or unsupported $ref: ${refPath}`);
  }
  const definitionKey = refPath.replace("#/definitions/", "");
  let resolvedSchema = {};
  const objectSchema = schema.definitions[definitionKey]?.properties;
  console.log("objectSchema", objectSchema);

  const ref = keyExists(objectSchema.settings, "$ref", true);
  if (ref) {
    const refPath = ref.replace("#/definitions/", "");
    resolvedSchema = schema.definitions[refPath];
  }
  return resolvedSchema;
};

export default function DetailConfigApp() {
  const { t } = useTranslation();
  const refForm = useRef();
  const [loading, setLoading] = useState(false);
  const [dataSchema, setDataSchema] = useState({});
  const [formData, setFormData] = useState({});

  const [isCheckAuthLink, setIsCheckAuthLink] = useState(false);
  const [listConnection, setListConnection] = useState([{}, {}]);
  const [listAction, setListAction] = useState([{}, {}, {}]);
  const [name, setName] = useState("");
  const [selectBox, setSelectBox] = useState({});
  const { id } = useParams();
  const [loadingSetting, setLoadingSetting] = useState(true);
  const [payloadAction, setPayloadAction] = useState({});
  const [linkConnect, setLinkConnect] = useState("");
  const getSchema = async (data) => {
    setSelectBox(data);
    setLoading(true);
    try {
      if (data?.channel?.url) {
        const schema = await getAuthSchema({ baseURL: data.channel.url });
        let settingSchema =
          Object.entries(schema?.schema?.definitions || {}).find(([key, schema]) =>
            key.endsWith("SettingsSchema"),
          )?.[1] || {};
        setDataSchema(settingSchema);
        setFormData(schema.sample.settings);
      } else {
        const resSchema = await getSchemaService(data.data.label.urlChannel);
        const optionAction = Object.keys(resSchema).map((key) => ({
          label: key.charAt(0).toUpperCase() + key.slice(1),
          value: key,
          data: resSchema[key],
        }));
        let schemaAction = optionAction?.find((item) => item.value === data.data.label.valueAction);
        const res = await actionsAPI.get({ id: data.id });
        setPayloadAction(res);
        await handleChangeSchemaAction(schemaAction, data.data.label.channelId, res);
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error.toString());
    }
    setLoading(false);
  };

  const handleChangeSchemaAction = async (dataOption, channelId, detailAction = {}) => {
    const schemaGlobal = {
      $ref: "#/definitions/Config",
      $schema: "http://json-schema.org/draft-07/schema#",
      definitions: {
        Config: {
          additionalProperties: false,
          properties: {
            settings: {},
          },
          required: [],
          type: "object",
        },
      },
    };
    if (dataOption?.data?.schema) {
      const schemaSettings = resolveSchemaSettingRefs(dataOption.data.schema);
      schemaGlobal.definitions.Config.required.push("settings");
      if (Object.keys(schemaSettings?.properties || {}).length > 0) {
        schemaGlobal.definitions.Config.properties.settings = schemaSettings;
        const checkRefSetting = keyExists(schemaSettings, "$ref", true);
        if (checkRefSetting) {
          const refPath = checkRefSetting.replace("#/definitions/", "");
          schemaGlobal.definitions[refPath] = dataOption.data.schema.definitions[refPath];
        }
      } else {
        schemaGlobal.definitions.Config.properties.settings = {
          additionalProperties: {
            type: "string",
          },
          type: "object",
        };
      }
      setDataSchema(schemaGlobal);
      setFormData(detailAction);
    }
  };

  useEffect(() => {
    const getDetail = async (id) => {
      setLoadingSetting(true);
      const res = await appStoresAPI.get({ id: `${id}/status` });
      setName(res.name);
      try {
        const checkListFixed = [];
        const listAction = [];
        for (const connection of res.connections) {
          if (
            connection.channel.url !== "https://mws-staging.onexapis.com" &&
            connection.channel.url !== "https://mws.onexapis.com"
          ) {
            if (connection.channel.type === "OAUTH") {
              setIsCheckAuthLink(true);
              try {
                const res = await getAuthLink({
                  baseURL: connection.channel.url,
                  connectionId: connection.id,
                });
                setLinkConnect(res);
              } catch (error) {
                toast.error("No get link auth app!");
              }
            }

            checkListFixed.push(connection);
          }
        }
        for (const flow of res.flows) {
          flow.flow_definition.nodes.forEach((element) => {
            if (element.type === "actionNode" && element.data.label.type === "CONNECTION") {
              listAction.push(element);
            }
          });
        }
        setListAction(listAction);
        setListConnection(checkListFixed);

        getSchema(checkListFixed[0]);
      } catch (e) {
        toast.error(e.toString());
      }
      setLoadingSetting(false);
    };
    getDetail(id);
  }, [id]);

  const handleSubmitForm = async (data) => {
    refForm.current.showLoading();
    try {
      if (selectBox.channel?.url) {
        await testConnection(selectBox.channel?.url, selectBox?.id);
        await connectionsAPI.put({ ...selectBox, ...data.formData });
      } else {
        const payload = { ...payloadAction, ...data.formData };
        await postValidationAction(selectBox.data.label?.urlChannel, selectBox.data.label?.valueAction, payload);
        await actionsAPI.put(payload);
      }
      toast.success(t("Update settings success!"));
    } catch (error) {
      toast.error(error.toString());
    }

    refForm.current.hideLoading();
  };

  // const handleConnect = () => {
  //   setLoadingConnect(true);
  //   try {
  //   } catch (error) {
  //     toast.error(error.toString());
  //   }
  //   setLoadingConnect(false);
  // };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={
            <div>
              <span>{name}</span>
              {isCheckAuthLink && (
                <a className="btn btn-secondary mx-2" href={linkConnect}>
                  {t("Connect")}
                </a>
              )}
            </div>
          }
          pageTitle="Store"
          listDefault={["Detail Config", name]}
        />
        <Row>
          <Col xs={12}>
            <Row>
              <Col xl={3}>
                <div>
                  <h5 className="mb-1">{t("Connections")}</h5>
                  <p className="text-muted">{t("Don't miss scheduled events")}</p>
                  <SimpleBar className="pe-2 me-n1 mb-3" style={{ height: "20vh" }}>
                    {loadingSetting
                      ? new Array(2).fill({}).map((item, index) => (
                          <Col key={index} className="mb-3 w-100" lg={3}>
                            <Card className={`mb-3 cursor-pointer  `}>
                              <CardBody>
                                <div className="d-flex mb-3 align-items-center">
                                  <div className="me-3 avatar-xs skeleton">
                                    <img
                                      src={item?.image}
                                      alt=""
                                      className="img-fluid"
                                      style={{ objectFit: "contain", height: "30px" }}
                                    />
                                  </div>
                                  <p className="mt-2 fs-15 skeleton-box skeleton-text"></p>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        ))
                      : listConnection &&
                        listConnection.map((connection, key) => (
                          <React.Fragment key={key}>
                            <Card
                              className={`mb-3 cursor-pointer`}
                              color={connection.id === selectBox.id ? "primary" : ""}
                              onClick={() => {
                                getSchema(connection);
                              }}
                            >
                              <CardBody>
                                <div className="d-flex mb-3 align-items-center">
                                  <div className={`${loadingSetting ? "skeleton" : ""}`}>
                                    <img src={connection?.channel?.image} className="me-3 avatar-xs" alt="" />
                                  </div>
                                  <h6 className={`card-title fs-16 m-0 ${loadingSetting ? "skeleton-text" : ""}`}>
                                    {t(connection?.name || "---")}
                                  </h6>
                                </div>

                                <p className="text-muted text-truncate-two-lines mb-0">
                                  {t(connection?.channel?.description || "---")}
                                </p>
                              </CardBody>
                            </Card>
                          </React.Fragment>
                        ))}
                  </SimpleBar>
                </div>

                <div>
                  <h5 className="mb-1">{t("List Actions")}</h5>
                  <p className="text-muted">{t("Don't miss scheduled events")}</p>
                  <SimpleBar className="pe-2 me-n1 mb-3" style={{ height: "43vh" }}>
                    {loadingSetting
                      ? new Array(4).fill({}).map((item, index) => (
                          <Col key={index} className="mb-3 w-100" lg={3}>
                            <Card className={`mb-3 cursor-pointer  `}>
                              <CardBody>
                                <div className="d-flex mb-3 align-items-center">
                                  <div className="me-3 avatar-xs skeleton">
                                    <img
                                      src={item?.image}
                                      alt=""
                                      className="img-fluid"
                                      style={{ objectFit: "contain", height: "30px" }}
                                    />
                                  </div>
                                  <p className="mt-2 fs-15 skeleton-box skeleton-text"></p>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        ))
                      : listAction &&
                        listAction.map((action, key) => (
                          <React.Fragment key={key}>
                            <Card
                              className="mb-3 cursor-pointer"
                              color={action.id === selectBox.id ? "primary" : ""}
                              onClick={() => {
                                getSchema(action);
                              }}
                            >
                              <CardBody>
                                <div className="d-flex mb-3 align-items-center">
                                  <div>
                                    <img
                                      src={action?.data?.label?.imageChannel}
                                      className="me-3 avatar-xs"
                                      alt="user-pic"
                                    />
                                  </div>
                                  <h6 className="card-title fs-16 m-0">{t(action?.data?.label?.name || "---")}</h6>
                                </div>

                                <p className="text-muted text-truncate-two-lines mb-0">
                                  {t(action?.data?.label?.channelName || "---")}
                                </p>
                              </CardBody>
                            </Card>
                          </React.Fragment>
                        ))}
                  </SimpleBar>
                </div>
              </Col>
              <Col xl={9}>
                <Card className="card-h-100 h-100">
                  <CardBody>
                    {loading || loadingSetting ? (
                      <Loader />
                    ) : (
                      <div>
                        <p className="fs-24">{t("Settings")}</p>

                        <SchemaForm
                          schema={dataSchema}
                          onSubmit={handleSubmitForm}
                          formData={formData}
                          innerRef={refForm}
                        />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
