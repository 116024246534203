// Actions
export const LIST_CUSTOMER = "LIST_CUSTOMER";
export const LIST_CUSTOMER_SUCCESS = "LIST_CUSTOMER_SUCCESS";
export const LIST_CUSTOMER_FAIL = "LIST_CUSTOMER_FAIL";

export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_FAIL = "GET_CUSTOMER_FAIL";

/**
 * Delete CUSTOMER
 */
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_FAIL = "DELETE_CUSTOMER_FAIL";

/**
 * Add CUSTOMER
 */
export const ADD_NEW_CUSTOMER = "ADD_NEW_CUSTOMER";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_FAIL = "ADD_CUSTOMER_FAIL";

/**
 * Edit CUSTOMER
 */
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAIL = "UPDATE_CUSTOMER_FAIL";

export const RESET_CUSTOMER_FLAG = "RESET_CUSTOMER_FLAG";

