import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledDropdown,
} from "reactstrap";

import { deepCopy, formatVNDCurrency } from "../../../../helpers/format_helper";

import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import InputPaymentField from "../../../../Components/Common/InputPaymentField";
import { toast } from "react-toastify";
import InputTheme from "../../../../Components/Common/InputTheme";
import FullNameRender from "../../../../Components/Common/FullnameRender";
import { Tooltip } from "antd";

const PaymentsModal = ({
  isOpen,
  toggle,
  currentCustomer = null,
  payments = null,
  amountTotal,
  givenCash,
  excessCash,
  onSave = () => {},
  rewardProgram = null,
  redeemPoint = null,
}) => {
  const { t } = useTranslation();
  const { paymentMethods, paymentMethodsLoading, paymentMethodsSuccess, paymentMethodsError } = useMemoizedSelector(
    (state) => ({
      paymentMethods: state.PaymentMethods.paymentMethods,
      paymentMethodsLoading: state.PaymentMethods.loading,
      paymentMethodsSuccess: state.PaymentMethods.success,
      paymentMethodsError: state.PaymentMethods.error,
    }),
  );

  const [selectedButton, setSelectedButton] = useState([]);
  const [currentGivenCash, setCurrentGivenCash] = useState(0);
  const [currentRedeemPoint, setCurrentRedeemPoint] = useState(0);
  const [currentExcessCash, setCurrentExcessCash] = useState(0);

  const handleClickButton = (selectedItem) => {
    let pointExchanged = rewardProgram ? currentRedeemPoint * rewardProgram?.money : 0;
    if (selectedButton.find((item) => item.payment_method_id === selectedItem.id)) {
      setSelectedButton(selectedButton.filter((item) => item.payment_method_id !== selectedItem.id));
    } else {
      let newData = deepCopy(selectedButton);
      let totalPaymentPrice = newData.reduce((total, item) => total + item.amount, 0);
      if (totalPaymentPrice < amountTotal) {
        newData.push({
          payment_method_id: selectedItem.id,
          amount: amountTotal - totalPaymentPrice - pointExchanged,
          payment: selectedItem,
        });
      } else {
        newData.push({ payment_method_id: selectedItem.id, amount: 0, payment: selectedItem });
      }
      setSelectedButton(newData);
    }
  };

  const handleDeletePayment = (paymentId) => {
    setSelectedButton(selectedButton.filter((item) => item.payment_method_id !== paymentId));
  };

  const handleChangePaymentType = (data, index) => {
    let newData = deepCopy(selectedButton);
    newData[index].payment_method_id = data;
    setSelectedButton(newData);
  };

  const handleChangePaymentPrice = (data, index) => {
    let newData = deepCopy(selectedButton);
    newData[index].amount = data;
    setSelectedButton(newData);
  };

  const handleSave = () => {
    // if (selectedButton.length > 0) {
    //   const invalidAmount = selectedButton.some((item) => item.amount === 0);
    //   if (invalidAmount) {
    //     toast.error(t("Amount must be greater than 0"));
    //     return;
    //   }
    // }

    const data = {
      payments: selectedButton.length > 0 ? selectedButton : null,
      given_cash: currentGivenCash,
      excess_cash: currentExcessCash,
      reward_program: currentRedeemPoint > 0 ? rewardProgram : null,
      redeem_point: currentRedeemPoint > 0 ? currentRedeemPoint : null,
    };
    onSave(data);
    toggle();
  };

  useEffect(() => {
    isOpen && setCurrentGivenCash(givenCash);
  }, [isOpen, givenCash]);

  useEffect(() => {
    isOpen && rewardProgram && setCurrentRedeemPoint(redeemPoint ?? 0);
  }, [isOpen, redeemPoint, rewardProgram]);

  useEffect(() => {
    isOpen && setCurrentExcessCash(excessCash);
  }, [isOpen, excessCash]);

  useEffect(() => {
    isOpen && setSelectedButton(payments ? payments : []);
  }, [isOpen]);

  useEffect(() => {
    setCurrentExcessCash(
      currentGivenCash -
        selectedButton
          .filter((item) => item.payment.account.type === "CASH")
          .reduce((total, item) => total + item.amount, 0),
    );
  }, [selectedButton, currentGivenCash]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>{t("Multi payment method")}</ModalHeader>
      <ModalBody>
        <div className="hstack border-top border-bottom py-3">
          <div className="flex-grow-1">
            {currentCustomer && (
              <div className="hstack">
                <span className="flex-grow-1">
                  <span className="text-primary">
                    <FullNameRender first_name={currentCustomer?.first_name} last_name={currentCustomer?.last_name} />
                  </span>
                  {currentCustomer?.phone && <span>{" - " + currentCustomer?.phone}</span>}
                </span>
              </div>
            )}
            <div className="hstack">
              <span className="flex-grow-1">
                {t("Debt")}: {formatVNDCurrency(0)}
              </span>

              {currentCustomer?.loyal_customer ? (
                <UncontrolledDropdown>
                  <DropdownToggle href="#" className="btn" tag="button" caret>
                    {t("Current point")}: {currentCustomer?.loyal_customer?.point ?? 0}
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end dropdown-menu-lg p-0" toggle={false}>
                    <div className="hstack p-2">
                      <span className="flex-grow-1 fw-semibold">{t("Accumulated points")}</span>
                      <span>{currentCustomer?.loyal_customer?.point ?? 0}</span>
                    </div>
                    {rewardProgram && (
                      <div className="hstack p-2">
                        <span className="flex-grow-1 fw-semibold">{t("Exchange value")}</span>
                        <span>{(currentCustomer?.loyal_customer?.point ?? 0) * (rewardProgram?.money ?? 0)}</span>
                      </div>
                    )}
                    {currentCustomer?.customer_group && (
                      <div className="hstack p-2">
                        <span className="flex-grow-1 fw-semibold">{t("Group name")}</span>
                        <span>{currentCustomer?.customer_group?.name ?? "---"}</span>
                      </div>
                    )}
                    {/* <div className="hstack p-2">
                              <span className="flex-grow-1 fw-semibold">{t("Active card date")}</span>
                              <span>{new Date().toLocaleString()}</span>
                            </div>
                            <div className="hstack p-2">
                              <span className="flex-grow-1 fw-semibold">{t("Point to upgrade")}</span>
                              <span>0</span>
                            </div> */}
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : (
                <div>
                  <span className="me-3 hstack">
                    {t("Accumulated points")}:{" "}
                    <i className="text-danger ri-information-line fs-16 align-bottom ms-1"></i>
                  </span>
                  <ButtonTheme className="btn btn-ghost-primary text-primary hstack p-0">
                    {t("Activate")} <i className="ri-arrow-right-s-line align-bottom fs-16"></i>
                  </ButtonTheme>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="hstack fs-24 border-bottom py-3">
          <span className="flex-grow-1">{t("Amount to pay")}</span>
          <span className="text-primary">{formatVNDCurrency(amountTotal)}</span>
        </div>
        <div className="hstack gap-3 border-bottom py-3">
          {paymentMethods.map((item, index) => {
            let ids = selectedButton.map((sb) => sb.payment_method_id);
            return (
              <Fragment key={index}>
                <div>
                  <ButtonTheme
                    className={`btn btn-light w-md ${ids.includes(item.id) && "text-primary border-primary"}`}
                    onClick={() => {
                      handleClickButton(item);
                    }}
                  >
                    {t(item.name)}
                  </ButtonTheme>
                </div>
              </Fragment>
            );
          })}
        </div>
        <div className="border-bottom">
          {rewardProgram && currentCustomer?.loyal_customer && (
            <div className="hstack justify-content-between mb-1">
              <div className="hstack gap-2">
                {t("Pay")}
                <Tooltip title={`1 ${t("point")} = ${formatVNDCurrency(rewardProgram?.money)}`}>
                  <div style={{ width: "75px" }}>
                    <InputTheme
                      type="number"
                      isForm={false}
                      value={currentRedeemPoint}
                      max={currentCustomer?.loyal_customer?.point ?? 0}
                      min={0}
                      formNoMargin={true}
                      className="text-end border-0 border-bottom fw-medium px-0 rounded-0"
                      onChange={(data) => {
                        setCurrentRedeemPoint(data);
                      }}
                    />
                  </div>
                </Tooltip>
                {t("Point")}
              </div>
              <span className="flex-shrink-0 fw-bold">
                {formatVNDCurrency(currentRedeemPoint ?? 0 * rewardProgram?.money * 0)}
              </span>
            </div>
          )}
          {selectedButton.map((item, index) => (
            <div key={index} className="hstack gap-2 mb-3">
              <span className="flex-grow-1">{t(item?.payment?.name || "---")}</span>
              {/* <Input
                  type="select"
                  className="form-select"
                  value={item.payment_method_id}
                  onChange={(e) => {
                    handleChangePaymentType(e.target.value, index);
                  }}
                >
                  {paymentMethods
                    .filter((optionItem) => {
                      let ids = selectedButton.map((sb) => sb.payment_method_id);
                      return optionItem.id === item.payment_method_id || !ids.includes(optionItem.id);
                    })
                    .map((optionItem, key) => {
                      return (
                        <option key={key} value={optionItem.id}>
                          {optionItem.name}
                        </option>
                      );
                    })}
                </Input> */}
              <div className="flex-shrink-0">
                <InputPaymentField index={index} price={item.amount} onChangePaymentPrice={handleChangePaymentPrice} />
              </div>
              <div className="flex-shrink-0">
                <ButtonTheme
                  className={"btn btn-ghost-danger rounded-circle btn-icon waves-effect"}
                  onClick={() => {
                    handleDeletePayment(item.payment_method_id);
                  }}
                >
                  <i className="ri-close-line fs-16"></i>
                </ButtonTheme>
              </div>
            </div>
          ))}
        </div>
        <div className="py-3">
          <div className="hstack fs-24 mb-3">
            <div className="flex-grow-1">{t("Given cash")}</div>
            <div className="flex-shrink-0">
              <InputTheme
                className="text-end"
                format="price"
                isForm={false}
                value={currentGivenCash}
                onChange={(data) => {
                  setCurrentGivenCash(isNaN(data) ? 0 : data);
                }}
              />
            </div>
          </div>
          <div className="hstack">
            <span className="flex-grow-1">{t("Excess cash")}</span>
            <span>{formatVNDCurrency(currentExcessCash)}</span>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <ButtonTheme className={"btn btn-light w-md"} onClick={toggle}>
          {t("Close")}
        </ButtonTheme>
        <ButtonTheme className={"btn btn-outline-primary w-md"} onClick={handleSave}>
          {t("Save")}
        </ButtonTheme>
      </ModalFooter>
    </Modal>
  );
};
export default PaymentsModal;
