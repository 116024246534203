import {
  LIST_CATEGORY,
  LIST_CATEGORY_FAIL,
  LIST_CATEGORY_SUCCESS,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  ADD_NEW_CATEGORY,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAIL,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  GET_CATEGORY,
  GET_CATEGORY_FAIL,
  GET_CATEGORY_SUCCESS,
} from "./actionType";

export const listCategory = (options) => ({
  type: LIST_CATEGORY,
  payload: options,
});

export const listCategoryFail = (error) => ({
  type: LIST_CATEGORY_FAIL,
  payload: error,
});

export const listCategorySuccess = (categories) => ({
  type: LIST_CATEGORY_SUCCESS,
  payload: categories,
});

export const getCategory = (categoryID) => ({
  type: GET_CATEGORY,
  payload: categoryID,
});

export const getCategoryFail = (error) => ({
  type: GET_CATEGORY_FAIL,
  payload: error,
});

export const getCategorySuccess = (category) => ({
  type: GET_CATEGORY_SUCCESS,
  payload: category,
});

export const deleteCategory = (category) => ({
  type: DELETE_CATEGORY,
  payload: category,
});

export const deleteCategorySuccess = (category) => ({
  type: DELETE_CATEGORY_SUCCESS,
  payload: category,
});

export const deleteCategoryFail = (error) => ({
  type: DELETE_CATEGORY_FAIL,
  payload: error,
});

export const updateCategory = (category, handleAfterSubmit = () => {}) => ({
  type: UPDATE_CATEGORY,
  payload: { category, handleAfterSubmit },
});

export const updateCategorySuccess = (category) => ({
  type: UPDATE_CATEGORY_SUCCESS,
  payload: category,
});

export const updateCategoryFail = (error) => ({
  type: UPDATE_CATEGORY_FAIL,
  payload: error,
});

export const addNewCategory = (category, handleAfterSubmit = () => {}) => ({
  type: ADD_NEW_CATEGORY,
  payload: { category, handleAfterSubmit },
});

export const addCategorySuccess = (category) => ({
  type: ADD_CATEGORY_SUCCESS,
  payload: category,
});

export const addCategoryFail = (error) => ({
  type: ADD_CATEGORY_FAIL,
  payload: error,
});
