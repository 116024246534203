import { useEffect, useState } from "react";
import { getLoggedInUser } from "../../helpers/api_helper";
import { getLoggedInUserSettings } from "../../helpers/format_helper";

const useProfile = () => {
  const userProfileSession = getLoggedInUser();
  var token = userProfileSession && userProfileSession?.["Token"]?.["IdToken"];
  const [loading, setLoading] = useState(userProfileSession ? false : true);
  const [userProfile, setUserProfile] = useState(userProfileSession ? userProfileSession : null);

  useEffect(() => {
    const userProfileSession = getLoggedInUser();
    var token = userProfileSession && userProfileSession?.["Token"]?.["IdToken"];
    setUserProfile(userProfileSession ? userProfileSession : null);
    setLoading(token ? false : true);
  }, []);

  const userSettings = getLoggedInUserSettings();
  const [dataColor, setDataColor] = useState(userSettings?.dataColor ? userSettings?.dataColor : null);
  const [dataShop, setDataShop] = useState(userSettings?.dataShop ? userSettings?.dataShop : null);
  useEffect(() => {
    const userSettings = getLoggedInUserSettings();
    setDataColor(userSettings?.dataColor ? userSettings?.dataColor : null);
    setDataShop(userSettings?.dataShop ? userSettings?.dataShop : null);
  }, []);

  return { userProfile, loading, token, dataColor, dataShop };
};

export { useProfile };
