import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import { convertValidDate, convertValidTime } from "../../../../helpers/format_helper";

import EditAddPriceGroupModal from "./EditAddPriceGroupModal";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import withRouter from "../../../../Components/Common/withRouter";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { Actions } from "../../../../Components/constants/common";

import { deletePriceGroup, listPriceGroup } from "../../../../store/actions";

const PriceGroupsPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Price Groups")} | OptiWarehouse`;

  const dispatch = useDispatch();

  const {
    priceGroups,
    priceGroupsLoading,
    priceGroupsSuccess,
    priceGroupsAction,
    priceGroupsError,
    priceGroupsLimit,
    priceGroupsTotal,
    priceGroupsPage,
  } = useMemoizedSelector((state) => ({
    priceGroups: state.PriceGroups.priceGroups,
    priceGroupsLoading: state.PriceGroups.loading,
    priceGroupsAction: state.PriceGroups.action,
    priceGroupsSuccess: state.PriceGroups.success,
    priceGroupsError: state.PriceGroups.error,
    priceGroupsLimit: state.PriceGroups.limit,
    priceGroupsTotal: state.PriceGroups.total,
    priceGroupsPage: state.PriceGroups.page,
  }));

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPriceGroup, setSelectedPriceGroup] = useState(null);
  const [showEditAddModal, setShowEditAddModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const handleAdd = () => {
    setIsEdit(false);
    setShowEditAddModal(true);
  };

  const handleEdit = (data) => {
    setIsEdit(true);
    setShowEditAddModal(true);
    setSelectedPriceGroup(data);
  };

  const handleCloseEditAddModal = () => {
    if (!priceGroupsLoading) {
      setSelectedPriceGroup(null);
      setShowEditAddModal(false);
    }
  };

  const handleDelete = () => {
    dispatch(deletePriceGroup(selectedPriceGroup));
  };

  const handleCloseDeleteModal = () => {
    if (!priceGroupsLoading) {
      setShowDeleteModal(false);
      setSelectedPriceGroup(null);
    }
  };

  const headers = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Updated at",
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: true,
      render: (value, row, index) => {
        return (
          <>
            {convertValidDate(value)},<small className="text-muted"> {convertValidTime(value)}</small>
          </>
        );
      },
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      defaultSortOrder: "desc",
      sorter: true,
      render: (value, row, index) => {
        return (
          <>
            {convertValidDate(value)},<small className="text-muted"> {convertValidTime(value)}</small>
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (value, row, index) => {
        return (
          <UncontrolledDropdown>
            <DropdownToggle href="#" className="btn btn-soft-primary btn-sm" tag="button">
              <i className="ri-more-fill" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end" container="body">
              <DropdownItem onClick={() => handleEdit(row)}>
                <i className="ri-pencil-fill align-bottom me-2 text-primary"></i> {t("Edit")}
              </DropdownItem>

              <DropdownItem divider />
              <DropdownItem
                onClick={() => {
                  setSelectedPriceGroup(row);
                  setShowDeleteModal(true);
                }}
              >
                <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i> {t("Delete")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];

  // toggle after loading
  useEffect(() => {
    if (!priceGroupsLoading && priceGroupsSuccess && showDeleteModal) {
      setShowDeleteModal(false);
      setSelectedPriceGroup(null);
    }
  }, [priceGroupsLoading, priceGroupsSuccess]);

  return (
    <div className="page-content">
      <DeleteModal
        show={showDeleteModal}
        onCloseClick={handleCloseDeleteModal}
        onDeleteClick={handleDelete}
        loading={priceGroupsLoading && priceGroupsAction === Actions.DELETE}
      />
      <EditAddPriceGroupModal
        show={showEditAddModal}
        toggle={handleCloseEditAddModal}
        isEdit={isEdit}
        priceGroup={selectedPriceGroup}
      />
      <Container fluid>
        <BreadCrumb title={t("Price group list")} pageTitle="Settings" navigateTo="/settings" isBack={true} />
        <Row>
          <Col lg={12}>
            <Card id="price-group-list">
              <CardHeader className="border-0">
                <Row className="g-4 align-items-center">
                  <div className="col-sm">
                    <div>
                      <h5 className="card-title mb-0">{t("Price group list")}</h5>
                    </div>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-3 flex-wrap">
                      <ButtonTheme
                        type="button"
                        className="btn btn-primary"
                        id="create-btn"
                        icon="ri-add-line text-white"
                        onClick={handleAdd}
                      >
                        {t("Add Price Group")}
                      </ButtonTheme>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <TableContainerCustom
                  filterType={"priceGroups"}
                  searchPlaceHolder={t("Search price group...")}
                  listFilter={[]}
                  headers={headers}
                  contents={priceGroups}
                  limitPage={Number(priceGroupsLimit)}
                  currentPage={Number(priceGroupsPage) + 1}
                  totalItems={priceGroupsTotal}
                  loading={priceGroupsLoading && priceGroupsAction === Actions.LIST}
                  showPagination={true}
                  scrollHeight="50vh"
                  onCallData={(dataPayload) => {
                    dispatch(listPriceGroup(dataPayload));
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default withRouter(PriceGroupsPage);
