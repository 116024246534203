import { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { Card, CardBody, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import * as Yup from "yup";
import { FieldArray, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";

import Loader from "../../../../Components/Common/Loader";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { Actions, genderOptions } from "../../../../Components/constants/common";

import { addNewCustomer, getCustomer, updateCustomer } from "../../../../store/actions";
import InputTheme from "../../../../Components/Common/InputTheme";
import AddressTheme from "../../../../Components/Common/AddressTheme";
import SelectTheme from "../../../../Components/Common/SelectTheme";
import ScrollToError from "../../../../Components/Common/ScrollToError";

const AddEditCustomerModal = ({ customerId = "", isOpen, toggle, onAddUpdateCustomer = () => {}, isInPOS = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRef = useRef();
  const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const { customer, customersAction, customersLoading, customerMessage, customersSuccess, customersError } =
    useMemoizedSelector((state) => ({
      customer: state.Customers.customer,
      customersAction: state.Customers.action,
      customersLoading: state.Customers.loading,
      customersSuccess: state.Customers.success,
      customersError: state.Customers.error,
      customerMessage: state.Customers.message,
    }));

  const [addresses, setAddresses] = useState([]);

  const validation = {
    enableReinitialize: true,
    initialValues: {
      id: (customerId && customer?.id) || "",
      company_name: (customerId && customer?.company_name) || "",
      first_name: (customerId && customer?.first_name) || "",
      last_name: (customerId && customer?.last_name) || "",
      phone: (customerId && customer?.phone) || "",
      email: (customerId && customer?.email) || "",
      addresses: [],
      has_account: customer?.username && customer.password ? true : false,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required(t("Please Enter First Name")),
      // last_name: Yup.string().required(t("Please Enter Last Name")),
      // email: Yup.string()
      // .required(t("Please Enter Email"))
      // .matches(emailRegExp, t("Please Enter a Valid Email Address")),
      // phone: Yup.string()
      //   .required(t("Please Enter Phone Number"))
      //   .matches(/^[0-9]+$/, t("Phone number must contain only digits"))
      //   .min(10, t("Phone number is too short"))
      //   .max(15, t("Phone number is too long")),
    }),
    onSubmit: (values) => {
      values["is_customer"] = true;
      if (!values.id) {
        delete values.id;
      }
      if (!values.email) {
        values.email = null;
      }
      if (!values.phone) {
        values.phone = null;
      }
      if (addresses.length > 0) {
        let newAddresses = addresses.map((item) => {
          return { ...item, first_name: values.first_name, last_name: values.last_name, phone: values.phone };
        });
        values.billing_address = newAddresses[0];
        values.addresses = newAddresses;
      }
      const handleAfterSubmit = (newCustomer) => {
        onAddUpdateCustomer(newCustomer);
        handleToggle();
      };
      if (customerId) {
        let payload = { ...customer, ...values };
        dispatch(updateCustomer(payload, handleAfterSubmit));
      } else {
        let payload = { ...values };
        dispatch(addNewCustomer(payload, handleAfterSubmit));
      }
    },
  };

  const handleChangeAddresses = (index, newData) => {
    setAddresses(addresses.map((item, i) => (index === i ? newData : item)));
  };

  const handleClickMoreAddress = () => {
    let newAddress = { address1: "", city: "", province: "", country: "", country_code: "" };
    setAddresses([...addresses, newAddress]);
  };

  const handleClickDeleteAddress = (index) => {
    setAddresses(addresses.filter((item, i) => i !== index));
  };

  const handleToggle = () => {
    if (!customersLoading) {
      toggle();
      formRef.current.resetForm();
      setAddresses([]);
    }
  };

  useEffect(() => {
    setAddresses(customerId && customer.addresses ? customer?.addresses : []);
  }, [customer, customerId]);

  useEffect(() => {
    if (customerId && isOpen) {
      dispatch(getCustomer(customerId));
    }
  }, [customerId, isOpen]);

  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={handleToggle} scrollable centered size="lg">
        <ModalHeader toggle={handleToggle}>{t(customerId ? "Edit customer" : "Add new customer")}</ModalHeader>
        <ModalBody>
          {customersAction === Actions.GET ? (
            <Loader />
          ) : (
            <Formik
              enableReinitialize={true}
              initialValues={validation.initialValues}
              validationSchema={validation.validationSchema}
              onSubmit={validation.onSubmit}
              innerRef={formRef}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <ScrollToError />
                  {!isInPOS && !customerId && (
                    <Row>
                      <Col lg={8}>
                        <InputTheme
                          label={"Customer Id"}
                          name="id"
                          placeholder="Enter uuid4. Ex: 5c68367b-d972-49b0-a9bf-0dd9198b44f8"
                          disabled={customerId}
                        />
                      </Col>
                      <Col lg={4} className="d-flex align-items-center">
                        <InputTheme
                          label={"Create account"}
                          name="has_account"
                          type="checkbox"
                          classWrapper="mt-3"
                          disabled={customerId}
                        />
                      </Col>
                    </Row>
                  )}
                  {!isInPOS && values.has_account && (
                    <Row>
                      <Col lg={6}>
                        <InputTheme name="username" label="Username" placeholder="Enter username" />
                      </Col>
                      <Col lg={6}>
                        <InputTheme type="password" name="password" label="Password" placeholder="Enter password" />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col lg={4}>
                      <InputTheme label={"First name"} name="first_name" placeholder={"Enter First Name"} />
                    </Col>
                    <Col lg={4}>
                      <InputTheme label={"Last name"} name="last_name" placeholder={"Enter Last Name"} />
                    </Col>
                    <Col lg={4}>
                      <SelectTheme
                        label={"Gender"}
                        name="gender"
                        placeholder={t("Enter gender")}
                        options={genderOptions}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <InputTheme label={"Phone number"} name="phone" placeholder={t("Enter Phone Number")} />
                    </Col>
                    <Col lg={4}>
                      <InputTheme label={"Email"} name="email" placeholder={t("Enter Email")} type="email" />
                    </Col>

                    <Col lg={4}>
                      <InputTheme label={"Date of birth"} name="date_birth" type="date" />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <SelectTheme
                        label={"Customer group"}
                        name="customer_group"
                        placeholder={t("Select customer group")}
                        remote={true}
                        isObjectValueSelect={true}
                        selectDataOrigin={true}
                        onChange={(data) => {
                          if (data?.data) {
                            const { created_at, updated_at, company_id, user, total_member, ...rest } = data.data;
                            setFieldValue("customer_group", rest);
                          } else {
                            setFieldValue("customer_group", null);
                          }
                        }}
                        path="actor/customer_groups"
                      />
                    </Col>
                    <Col lg={12}>
                      <SelectTheme
                        label={"Company name"}
                        name="company_name"
                        placeholder={t("Enter Company Name")}
                        options={[]}
                      />
                    </Col>
                  </Row>
                  {addresses.map((item, index) => (
                    <Card className="border" key={index}>
                      <i
                        className="position-absolute top-0 start-100 translate-middle ri-close-circle-fill text-danger fs-24 cursor-pointer"
                        onClick={() => handleClickDeleteAddress(index)}
                      ></i>
                      <CardBody>
                        <AddressTheme address={item} index={index} handleChangeAddresses={handleChangeAddresses} />
                      </CardBody>
                    </Card>
                  ))}
                  <ButtonTheme
                    type="button"
                    className="btn btn-light"
                    onClick={() => {
                      handleClickMoreAddress();
                    }}
                  >
                    <i className="ri-add-line align-bottom me-1"></i>
                    {t("More address")}
                  </ButtonTheme>
                </Form>
              )}
            </Formik>
          )}
        </ModalBody>
        <ModalFooter className="border-0">
          <ButtonTheme type="button" className="btn btn-light w-sm" onClick={handleToggle}>
            {t("Close")}
          </ButtonTheme>
          <ButtonTheme
            type="button"
            className="btn btn-primary w-sm"
            onClick={() => formRef.current.handleSubmit()}
            loading={(customersAction === Actions.UPDATE || customersAction === Actions.CREATE) && customersLoading}
            disabled={(customersAction === Actions.UPDATE || customersAction === Actions.CREATE) && customersLoading}
          >
            {t(customerId ? "Update" : "Add")}
          </ButtonTheme>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default AddEditCustomerModal;
