import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { withTranslation } from "react-i18next";

import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";

import { changePass, resetChangePassFlag } from "../../../../store/auth/changepwd/actions";

const ChangePassword = ({ t }) => {
  const dispatch = useDispatch();
  const userSession = JSON.parse(localStorage.getItem("auth_user"))?.UserAttributes;

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required(t("Please Enter Your Password")),
      newPassword: Yup.string()
        .min(8, t("Password must be at least 8 characters"))
        .matches(RegExp("(.*[a-z].*)"), t("At least lowercase letter"))
        .matches(RegExp("(.*[A-Z].*)"), t("At least uppercase letter"))
        .matches(RegExp("(.*[0-9].*)"), t("At least one number"))
        .matches(/[/\W|_/g]/, t("At least one symbol"))
        .required(t("Please Enter Your New Password")),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], t("Passwords must match"))
        .required(t("Confirm password is required")),
    }),
    onSubmit: (values) => {
      const payload = { username: userSession.email, password: values.password, newPassword: values.newPassword };
      dispatch(changePass(payload));
      validation.resetForm();
    },
  });

  const { error, loading, changePassMsg } = useMemoizedSelector((state) => ({
    changePassMsg: state.ChangePassword.changePassMsg,
    error: state.ChangePassword.error,
    loading: state.ChangePassword.loading,
  }));

  // useEffect(() => {
  //   setTimeout(() => {
  //     dispatch(resetChangePassFlag());
  //   }, 3000);
  // }, [dispatch, error, loading]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {error && error ? (
        <Alert color="danger">
          <div>{t(error)}</div>
        </Alert>
      ) : null}
      {changePassMsg && changePassMsg ? (
        <Alert color="success">
          <div>{t(changePassMsg)}</div>
        </Alert>
      ) : null}
      <div className="row g-2">
        <div className="col-lg-4">
          <Label htmlFor="password" className="form-label">
            {t("Old Password")}*
          </Label>
          <Input
            name="password"
            className="form-control"
            placeholder={t("Enter current password")}
            type="password"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.password || ""}
            invalid={validation.touched.password && validation.errors.password ? true : false}
          />
          {validation.touched.password && validation.errors.password ? (
            <FormFeedback type="invalid">
              <div>{validation.errors.password}</div>
            </FormFeedback>
          ) : null}
        </div>
        <div className="col-lg-4">
          <Label htmlFor="new-password" className="form-label">
            {t("New Password")}*
          </Label>
          <Input
            name="newPassword"
            className="form-control"
            placeholder={t("Enter new password")}
            type="password"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.newPassword || ""}
            invalid={validation.touched.newPassword && validation.errors.newPassword ? true : false}
          />
          {validation.touched.newPassword && validation.errors.newPassword ? (
            <FormFeedback type="invalid">
              <div>{validation.errors.newPassword}</div>
            </FormFeedback>
          ) : null}
        </div>
        <div className="col-lg-4">
          <Label htmlFor="confirm-password" className="form-label">
            {t("Confirm Password")}*
          </Label>
          <Input
            name="confirmPassword"
            className="form-control"
            placeholder={t("Confirm password")}
            type="password"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.confirmPassword || ""}
            invalid={validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false}
          />
          {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
            <FormFeedback type="invalid">
              <div>{validation.errors.confirmPassword}</div>
            </FormFeedback>
          ) : null}
        </div>
        <div className="col-lg-12">
          <div className="mb-3">
            <Link to="/forgot-password" className="text-decoration-underline">
              {t("Forgot password?")}
            </Link>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="text-end">
            <ButtonTheme type="submit" className="btn btn-success" loading={loading} disabled={loading}>
              {t("Change Password")}
            </ButtonTheme>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default withTranslation()(ChangePassword);
