import { useDispatch } from "react-redux";
import { Fragment, useEffect, useState } from "react";

import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { isEmpty } from "lodash";

import AddEditSupplierModal from "./AddEditSupplierModal";
import withRouter from "../../../../Components/Common/withRouter";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { Actions } from "../../../../Components/constants/common";
import FullNameRender from "../../../../Components/Common/FullnameRender";
import ActionDropdown from "../../../../Components/Common/ActionDropdown";

import { deleteSupplier, listSupplier } from "../../../../store/actions";

const SuppliersPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Supplier List")} | OptiWarehouse`;
  const dispatch = useDispatch();

  // redux state
  const {
    suppliers,
    suppliersAction,
    suppliersLoading,
    supplierMessage,
    suppliersSuccess,
    suppliersError,
    suppliersLimit,
    suppliersTotal,
    suppliersPage,
  } = useMemoizedSelector((state) => ({
    suppliers: state.Suppliers.suppliers,
    suppliersAction: state.Suppliers.action,
    suppliersLoading: state.Suppliers.loading,
    suppliersSuccess: state.Suppliers.success,
    suppliersError: state.Suppliers.error,
    supplierMessage: state.Suppliers.message,
    suppliersLimit: state.Suppliers.limit,
    suppliersTotal: state.Suppliers.total,
    suppliersPage: state.Suppliers.page,
  }));

  const [deleteModal, setDeleteModal] = useState(false);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [showEditAddSupplierModal, setShowEditAddSupplierModal] = useState(false);

  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const handleDeleteSupplier = () => {
    dispatch(deleteSupplier(selectedSupplier));
  };
  const onClickDelete = (data) => {
    setSelectedSupplier(data);
    setDeleteModal(true);
  };

  const onToggleDelete = () => {
    if (!suppliersLoading) {
      setSelectedSupplier(null);
      setDeleteModal(false);
    }
  };

  const onClickEdit = (data) => {
    setSelectedSupplier(data);
    setShowEditAddSupplierModal(true);
  };

  const toggleEditAddSupplierModal = () => {
    if (!suppliersLoading) {
      setShowEditAddSupplierModal(false);
      setSelectedSupplier(null);
    }
  };

  const headers = ["supplier", "company_name", "email", "phone", "created_at", "updated_at", "action"];

  // useEffect(() => {
  //   dispatch(listSupplier({ limit: 20 }));
  // }, [dispatch]);

  useEffect(() => {
    if (
      !suppliersLoading &&
      !isEmpty(selectedSupplier) &&
      deleteModal &&
      supplierMessage === "Delete supplier success"
    ) {
      setSelectedSupplier(null);
      setDeleteModal(false);
      // handleListSupplierAfterDelete();
    }
  }, [suppliersLoading]);

  return (
    <Fragment>
      <div className="page-content">
        <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={suppliers} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteSupplier}
          loading={suppliersLoading && suppliersAction === Actions.DELETE}
          onCloseClick={onToggleDelete}
        />
        <AddEditSupplierModal
          isOpen={showEditAddSupplierModal}
          toggle={toggleEditAddSupplierModal}
          supplierId={selectedSupplier?.id}
        />
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card id="supplierList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">{t("Supplier List")}</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex gap-3 flex-wrap">
                        <ButtonTheme
                          type="button"
                          className="btn btn-light"
                          icon="ri-file-text-line"
                          onClick={() => setIsExportCSV(true)}
                        >
                          {t("Export")}
                        </ButtonTheme>
                        <ButtonTheme
                          className="btn btn-primary"
                          id="create-btn"
                          icon="ri-add-line text-white"
                          onClick={() => {
                            setShowEditAddSupplierModal(true);
                          }}
                        >
                          {t("Add Supplier")}
                        </ButtonTheme>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <TableContainerCustom
                    filterType="suppliers"
                    searchPlaceHolder="Search supplier..."
                    listFilter={[]}
                    headers={headers}
                    contents={suppliers}
                    limitPage={Number(suppliersLimit)}
                    currentPage={Number(suppliersPage) + 1}
                    totalItems={suppliersTotal}
                    loading={suppliersLoading && suppliersAction === Actions.LIST}
                    showPagination={true}
                    scrollHeight="50vh"
                    onCallData={(dataPayload) => {
                      dispatch(listSupplier(dataPayload));
                    }}
                    isSettings={true}
                    listHeader={[
                      {
                        title: t("Supplier details"),
                        key: "detail-variant",
                        children: [
                          {
                            title: t("Supplier"),
                            key: "supplier",
                            dataIndex: "first_name",
                            sorter: true,
                            render: (_, data, index) => {
                              return (
                                <div className="text-truncate d-block">
                                  <FullNameRender first_name={data.first_name} last_name={data.last_name} />
                                </div>
                              );
                            },
                          },
                          {
                            title: t("Company Name"),
                            dataIndex: "company_name",
                            key: "company_name",
                            sorter: true,
                            render: (_, data, index) => {
                              return <>{_ ?? "---"}</>;
                            },
                          },
                          { title: t("Email"), dataIndex: "email", key: "email", sorter: true },
                          { title: t("Phone"), dataIndex: "phone", key: "phone", sorter: true },
                          {
                            title: t("Created At"),
                            dataIndex: "created_at",
                            key: "created_at",
                            sorter: true,
                            defaultSortOrder: "desc",
                          },
                          { title: "Updated at", dataIndex: "updated_at", key: "updated_at", sorter: true },
                          {
                            title: t("Action"),
                            dataIndex: "action",
                            key: "action",
                            render: (_, data, index) => {
                              return (
                                <ActionDropdown
                                  index={index}
                                  actions={[
                                    {
                                      type: "edit",
                                      onClick: () => {
                                        onClickEdit(data);
                                      },
                                    },
                                    {
                                      type: "delete",
                                      onClick: () => {
                                        onClickDelete(data);
                                      },
                                    },
                                  ]}
                                />
                              );
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};
export default withRouter(SuppliersPage);
