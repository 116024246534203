// Actions
export const LIST_PAYMENT_METHOD = "LIST_PAYMENT_METHOD";
export const LIST_PAYMENT_METHOD_SUCCESS = "LIST_PAYMENT_METHOD_SUCCESS";
export const LIST_PAYMENT_METHOD_FAIL = "LIST_PAYMENT_METHOD_FAIL";

export const GET_PAYMENT_METHOD = "GET_PAYMENT_METHOD";
export const GET_PAYMENT_METHOD_SUCCESS = "GET_PAYMENT_METHOD_SUCCESS";
export const GET_PAYMENT_METHOD_FAIL = "GET_PAYMENT_METHOD_FAIL";

export const DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD";
export const DELETE_PAYMENT_METHOD_SUCCESS = "DELETE_PAYMENT_METHOD_SUCCESS";
export const DELETE_PAYMENT_METHOD_FAIL = "DELETE_PAYMENT_METHOD_FAIL";

export const ADD_NEW_PAYMENT_METHOD = "ADD_NEW_PAYMENT_METHOD";
export const ADD_PAYMENT_METHOD_SUCCESS = "ADD_PAYMENT_METHOD_SUCCESS";
export const ADD_PAYMENT_METHOD_FAIL = "ADD_PAYMENT_METHOD_FAIL";

export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const UPDATE_PAYMENT_METHOD_SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS";
export const UPDATE_PAYMENT_METHOD_FAIL = "UPDATE_PAYMENT_METHOD_FAIL";

export const RESET_PAYMENT_METHOD_FLAG = "RESET_PAYMENT_METHOD_FLAG";
