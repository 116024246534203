import { useImperativeHandle, useRef, useState } from "react";
import { Spinner } from "reactstrap";
import useMemoizedSelector from "../Hooks/useMemoizedSelector";

const ButtonTheme = ({
  id = "",
  name,
  type = "button",
  style,
  className,
  onClick = () => {},
  loading = false,
  disabled,
  refBtn,
  refTour = null,
  children,
  loadShowText = false,
  loadButtonRef = false,
  keyPermission = "",
  icon = "",
  color ="",
  ...field
}) => {
  const { permission } = useMemoizedSelector((state) => ({
    permission: state.Permission.permission,
  }));
  const [loadingRef, setLoadingRef] = useState(false);
  const refButton = useRef();
  useImperativeHandle(refBtn, () => ({
    showLoading: () => setLoadingRef(true),
    hideLoading: () => setLoadingRef(false),
    onTriggerClick: () => {
      refButton.current.click();
    },
  }));

  function getPermissionValue(key) {
    const keys = key.split(".");
    return permission?.[keys[0]]?.[keys[1]];
  }

  return (
    <>
      <button
        id={id}
        name={name}
        disabled={keyPermission ? !getPermissionValue(keyPermission) : disabled || loading}
        className={className}
        type={type}
        onClick={onClick}
        style={style}
        ref={refTour ? refTour : refButton}
        {...field}
      >
        {loadShowText ? (
          <div className="d-flex justify-content-center">
            {(loading || loadingRef) && <Spinner size="sm" className="mx-1" />}
            <div className="d-flex align-items-center">
              {icon && <i className={`${icon} fs-24 line-height-lg me-1`}></i>}
              {children}
            </div>
          </div>
        ) : (
          <>
            {loading || loadingRef ? (
              <Spinner size="sm" />
            ) : (
              <div className="d-flex align-items-center justify-content-center">
                {icon && <i className={`${icon} fs-24 line-height-lg me-1`}></i>}
                {children}
              </div>
            )}
          </>
        )}
      </button>
    </>
  );
};

export default ButtonTheme;
