import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { isEmpty } from "lodash";

import withRouter from "../../../../Components/Common/withRouter";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { Actions } from "../../../../Components/constants/common";

import { deleteRewardProgram, listRewardProgram } from "../../../../store/actions";
import FullNameRender from "../../../../Components/Common/FullnameRender";
import BadgeCustom from "../../../../Components/Common/BadgeCustom";
import AddRewardProgramModal from "./AddRewardProgramModal";
import { formatVNDCurrency } from "../../../../helpers/format_helper";
import { Link } from "react-router-dom";

const RewardProgramListPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Reward Program List")} | OptiWarehouse`;
  const dispatch = useDispatch();

  const {
    rewardPrograms,
    rewardProgramsAction,
    rewardProgramsLoading,
    rewardProgramsMessage,
    rewardProgramsSuccess,
    rewardProgramsError,
    rewardProgramsLimit,
    rewardProgramsPage,
    rewardProgramsTotal,
  } = useMemoizedSelector((state) => ({
    rewardPrograms: state.RewardPrograms.rewardPrograms,
    rewardProgramsAction: state.RewardPrograms.action,
    rewardProgramsLoading: state.RewardPrograms.loading,
    rewardProgramsSuccess: state.RewardPrograms.success,
    rewardProgramsError: state.RewardPrograms.error,
    rewardProgramsMessage: state.RewardPrograms.message,
    rewardProgramsLimit: state.RewardPrograms.limit,
    rewardProgramsTotal: state.RewardPrograms.total,
    rewardProgramsPage: state.RewardPrograms.page,
  }));

  // modal state
  const [deleteModal, setDeleteModal] = useState(false);
  const [isExportCSV, setIsExportCSV] = useState(false);

  const [selectedRewardProgram, setSelectedRewardProgram] = useState(null);

  // table data
  const headers = ["name", "type", "start_date", "expiry_date", "money", "number_used", "action"];

  const handleDeleteRewardProgram = () => {
    dispatch(deleteRewardProgram(selectedRewardProgram, handleAfterDelete));
  };

  const handleAfterDelete = () => {
    setSelectedRewardProgram(null);
    setDeleteModal(false);
  };
  const onClickDelete = (data) => {
    setSelectedRewardProgram(data);
    setDeleteModal(true);
  };
  const onClose = () => {
    if (!rewardProgramsLoading) {
      setSelectedRewardProgram(null);
      setDeleteModal(false);
    }
  };

  return (
    <Fragment>
      <div className="page-content">
        <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={rewardPrograms} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteRewardProgram}
          loading={rewardProgramsLoading && rewardProgramsAction === Actions.DELETE}
          onCloseClick={onClose}
        />
        {/* <AddRewardProgramModal isOpen={showAddModal} toggle={toggleAddModal} /> */}
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card id="rewardProgramList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">{t("Reward Program List")}</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex gap-3 flex-wrap">
                        <ButtonTheme
                          type="button"
                          className="btn btn-light"
                          icon="ri-file-text-line"
                          onClick={() => setIsExportCSV(true)}
                        >
                          {t("Export")}
                        </ButtonTheme>
                        <ButtonTheme
                          className="btn btn-primary"
                          id="create-btn"
                          icon="ri-add-line text-white"
                          onClick={() => {
                            navigate(`/reward-programs/new`);
                          }}
                        >
                          {t("Add Reward Program")}
                        </ButtonTheme>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <TableContainerCustom
                    filterType={"rewardPrograms"}
                    searchPlaceHolder="Search reward programs..."
                    listFilter={[]}
                    headers={headers}
                    contents={rewardPrograms}
                    limitPage={Number(rewardProgramsLimit)}
                    currentPage={Number(rewardProgramsPage) + 1}
                    totalItems={rewardProgramsTotal}
                    loading={rewardProgramsLoading && rewardProgramsAction === Actions.LIST}
                    showPagination={true}
                    scrollHeight="50vh"
                    onCallData={(dataPayload) => {
                      dispatch(listRewardProgram(dataPayload));
                    }}
                    defaultPayload={{ limit: 20, sort_start_date: "desc" }}
                    isSettings={true}
                    listHeader={[
                      {
                        title: t("Point event details"),
                        key: "point-event-details",
                        children: [
                          {
                            title: t("Name"),
                            key: "name",
                            dataIndex: "name",
                            sorter: true,
                            render: (value, data, index) => {
                              return (
                                <Link
                                  to={`/reward-programs/${data.id}`}
                                  className="text-truncate d-block h5 fs-14 mb-0 link-primary"
                                >
                                  {value}
                                </Link>
                              );
                            },
                          },
                          {
                            title: t("Type"),
                            dataIndex: "type",
                            key: "type",
                            sorter: true,
                          },
                          {
                            title: t("Start date"),
                            dataIndex: "start_date",
                            key: "start_date",
                            sorter: true,
                            defaultSortOrder: "desc",
                          },
                          { title: t("Expiry date"), dataIndex: "expiry_date", key: "expiry_date", sorter: true },
                          {
                            title: t("Point"),
                            dataIndex: "money",
                            key: "money",
                            sorter: true,
                            render: (value, data, index) => {
                              return (
                                <>
                                  1 {t("point")} = {formatVNDCurrency(value)}
                                </>
                              );
                            },
                          },
                          { title: t("Number used"), dataIndex: "number_used", key: "number_used", sorter: true },
                          {
                            title: t("Action"),
                            dataIndex: "action",
                            key: "action",
                            render: (_, data, index) => {
                              return (
                                <UncontrolledDropdown>
                                  <DropdownToggle href="#" className="btn btn-soft-primary btn-sm" tag="button">
                                    <i className="ri-more-fill" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end" container="body">
                                    <DropdownItem
                                      onClick={() => {
                                        navigate(`/reward-programs/${data.id}`);
                                      }}
                                    >
                                      <i className="ri-pencil-fill align-bottom me-2 text-primary"></i> {t("Edit")}
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem
                                      onClick={() => {
                                        onClickDelete(data);
                                      }}
                                    >
                                      <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i> {t("Delete")}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              );
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};
export default withRouter(RewardProgramListPage);
