import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";


// Redux States
import { LIST_CATEGORY, DELETE_CATEGORY, ADD_NEW_CATEGORY, UPDATE_CATEGORY, GET_CATEGORY } from "./actionType";

import {
  deleteCategorySuccess,
  deleteCategoryFail,
  addCategorySuccess,
  addCategoryFail,
  updateCategorySuccess,
  updateCategoryFail,
  listCategorySuccess,
  listCategoryFail,
  getCategorySuccess,
  getCategoryFail,
} from "./action";

import { categoriesAPI } from "../../../../helpers/resource_helper";

function* listCategory({ payload: { ...res } }) {
  try {
    const response = yield call(categoriesAPI.list, { ...res });
    yield put(listCategorySuccess(response));
  } catch (error) {
    yield put(listCategoryFail(error));
  }
}

function* getCategory({ payload: id }) {
  try {
    const response = yield call(categoriesAPI.get, { id });
    yield put(getCategorySuccess(response));
  } catch (error) {
    yield put(getCategoryFail(error));
  }
}

function* deleteCategory({ payload: category }) {
  try {
    const response = yield call(categoriesAPI.delete, category);
    yield put(deleteCategorySuccess(category));
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteCategoryFail(error));
    toast.error("Category Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewCategory({ payload: { category, handleAfterSubmit } }) {
  try {
    const response = yield call(categoriesAPI.create, category);
    yield put(addCategorySuccess(response));
    yield call(handleAfterSubmit, response);
    toast.success("Category Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addCategoryFail(error));
    toast.error("Category Added Failed", { autoClose: 3000 });
  }
}

function* onUpdateCategory({ payload: { category, handleAfterSubmit } }) {
  try {
    const response = yield call(categoriesAPI.put, category);
    yield put(updateCategorySuccess(response));
    yield call(handleAfterSubmit, response);
    toast.success("Category Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateCategoryFail(error));
    toast.error("Category Updated Failed", { autoClose: 3000 });
  }
}

export function* watchGetCategories() {
  yield takeEvery(LIST_CATEGORY, listCategory);
}

export function* watchDeleteCategory() {
  yield takeEvery(DELETE_CATEGORY, deleteCategory);
}

export function* watchGetCategory() {
  yield takeEvery(GET_CATEGORY, getCategory);
}

export function* watchUpdateCategory() {
  yield takeEvery(UPDATE_CATEGORY, onUpdateCategory);
}

export function* watchAddNewCategory() {
  yield takeEvery(ADD_NEW_CATEGORY, onAddNewCategory);
}

function* categoriesSaga() {
  yield all([
    fork(watchGetCategory),
    fork(watchGetCategories),
    fork(watchUpdateCategory),
    fork(watchAddNewCategory),
    fork(watchDeleteCategory),
  ]);
}

export default categoriesSaga;
