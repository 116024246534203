import React, { useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import SimpleBar from "simplebar-react";
import AvatarTheme from "../../../../Components/Common/Avatar";
import { UserOutlined, SearchOutlined } from "@ant-design/icons";
import { Avatar, Checkbox } from "antd";
import { useTranslation } from "react-i18next";

export default function GroupFilterStaff({ users = [], onChangeFilter = () => {} }) {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [listCheck, setListCheck] = useState([]);
  const toggle = (e) => {
    setDropdownOpen((prevState) => !prevState);
  };

  return (
    <div>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle data-toggle="dropdown" tag="div">
          <div className="btn-outline-primary btn cursor-pointer select-none">
            <UserOutlined />
            <span>{t("Assignees")}</span>
            <Avatar.Group>
              {listCheck.map((a, index) => (
                <AvatarTheme name={users.find((u) => u.id === a).name} key={index} />
              ))}
            </Avatar.Group>
          </div>
        </DropdownToggle>
        <DropdownMenu container="body">
          <div className="px-2 py-1 border-bottom">
            <SearchOutlined style={{ fontSize: "17px", padding: "0 8px" }} />
            <input className="border-0" placeholder={t("Search...")} />
          </div>
          <div className="py-2">
            <SimpleBar style={{ maxHeight: "400px" }}>
              {users.map((user, index) => (
                <div key={index}>
                  <Checkbox
                    checked={listCheck.find((item) => item === user.id)}
                    onChange={(e) => {
                      let listUserCheck = [];
                      if (e.target.checked) {
                        listUserCheck = [...listCheck, user.id];
                      } else {
                        listUserCheck = listCheck.filter((item) => item !== user.id);
                      }
                      setListCheck(listUserCheck);
                      onChangeFilter(listUserCheck);
                    }}
                  >
                    <div className="d-flex align-items-center" onClick={() => {}}>
                      <AvatarTheme name={user.name} />
                      <span className="px-2">{user.name}</span>
                    </div>
                  </Checkbox>
                </div>
              ))}
            </SimpleBar>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}
