// Actions
export const LIST_REWARD_PROGRAM = "LIST_REWARD_PROGRAM";
export const LIST_REWARD_PROGRAM_SUCCESS = "LIST_REWARD_PROGRAM_SUCCESS";
export const LIST_REWARD_PROGRAM_FAIL = "LIST_REWARD_PROGRAM_FAIL";

export const GET_REWARD_PROGRAM = "GET_REWARD_PROGRAM";
export const GET_REWARD_PROGRAM_SUCCESS = "GET_REWARD_PROGRAM_SUCCESS";
export const GET_REWARD_PROGRAM_FAIL = "GET_REWARD_PROGRAM_FAIL";

/**
 * Delete REWARD_PROGRAM
 */
export const DELETE_REWARD_PROGRAM = "DELETE_REWARD_PROGRAM";
export const DELETE_REWARD_PROGRAM_SUCCESS = "DELETE_REWARD_PROGRAM_SUCCESS";
export const DELETE_REWARD_PROGRAM_FAIL = "DELETE_REWARD_PROGRAM_FAIL";

/**
 * Add REWARD_PROGRAM
 */
export const ADD_NEW_REWARD_PROGRAM = "ADD_NEW_REWARD_PROGRAM";
export const ADD_REWARD_PROGRAM_SUCCESS = "ADD_REWARD_PROGRAM_SUCCESS";
export const ADD_REWARD_PROGRAM_FAIL = "ADD_REWARD_PROGRAM_FAIL";

/**
 * Edit REWARD_PROGRAM
 */
export const UPDATE_REWARD_PROGRAM = "UPDATE_REWARD_PROGRAM";
export const UPDATE_REWARD_PROGRAM_SUCCESS = "UPDATE_REWARD_PROGRAM_SUCCESS";
export const UPDATE_REWARD_PROGRAM_FAIL = "UPDATE_REWARD_PROGRAM_FAIL";

export const RESET_REWARD_PROGRAM_FLAG = "RESET_REWARD_PROGRAM_FLAG";

