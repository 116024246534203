import { Fragment, useEffect, useMemo, useState } from "react";
import { Input, Row, Table } from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTheme from "../../../../../../Components/Common/InputTheme";
import { useDebounce } from "../../../../../../Components/Hooks/UseDebounce";
import { deepCopy } from "../../../../../../helpers/format_helper";

const MeasurementTable = ({ sku, measurements, onChangeMeasurements = () => {} }) => {
  const { t } = useTranslation();
  const renderInventory = () => {
    return [
      {
        Header: t("Height"),
        Cell: (
          <MeasurementInput
            onChangeMeasurements={(value, unit) => {
              onChangeMeasurements({ ...measurements, height_value: value, height_unit: unit });
            }}
            value={measurements.height_value ?? 0}
            unit={measurements.height_unit}
          />
        ),
      },
      {
        Header: t(`Length`),
        Cell: (
          <MeasurementInput
            onChangeMeasurements={(value, unit) => {
              onChangeMeasurements({ ...measurements, length_value: value, length_unit: unit });
            }}
            value={measurements.length_value ?? 0}
            unit={measurements.length_unit}
          />
        ),
      },
      {
        Header: t(`Width`),
        Cell: (
          <MeasurementInput
            onChangeMeasurements={(value, unit) => {
              onChangeMeasurements({ ...measurements, width_value: value, width_unit: unit });
            }}
            value={measurements.width_value ?? 0}
            unit={measurements.width_unit}
          />
        ),
      },
      {
        Header: t(`Weight`),
        Cell: (
          <MeasurementInput
            onChangeMeasurements={(value, unit) => {
              onChangeMeasurements({ ...measurements, weight_value: value, weight_unit: unit });
            }}
            value={measurements.weight_value ?? 0}
            unit={measurements.weight_unit}
            isWeight={true}
          />
        ),
      },
    ];
  };
  const columns = useMemo(
    () => [
      {
        Header: "Sku",
        Cell: <div>{sku || "---"}</div>,
      },
      ...renderInventory(),
    ],
    [sku, measurements],
  );

  return (
    <Row>
      <div className="table-responsive">
        <Table className="mb-0 align-middle table-bordered rounded-1">
          <thead className="table-light text-muted text-center">
            <tr>
              {columns.map((item) => (
                <th key={item.Header}>{item.Header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {columns.map((item) => (
                <td key={item.Header}>{item.Cell}</td>
              ))}
            </tr>
          </tbody>
        </Table>
      </div>
    </Row>
  );
};

const MeasurementInput = ({ isWeight = false, unit, value, onChangeMeasurements = () => {} }) => {
  const [tempValue, setTempValue] = useState(0);
  const debouncedValue = useDebounce(tempValue, 1000);
  useEffect(() => {
    setTempValue(value ?? 0);
  }, [value]);

  useEffect(() => {
    onChangeMeasurements(tempValue, unit);
  }, [debouncedValue]);

  return (
    <InputTheme
      type="number"
      isForm={false}
      value={tempValue}
      onChange={(data) => setTempValue(Number(data) ?? 0)}
      customRenderEnd={
        <>
          <Input
            type="select"
            value={unit}
            className="input-group-text"
            onChange={(e) => {
              onChangeMeasurements(tempValue, e.target.value);
            }}
          >
            {isWeight ? (
              <Fragment>
                <option value="gr">gr</option>
                <option value="g">g</option>
                <option value="kg">kg</option>
              </Fragment>
            ) : (
              <Fragment>
                <option value="mm">mm</option>
                <option value="cm">cm</option>
                <option value="m">m</option>
              </Fragment>
            )}
          </Input>
        </>
      }
    />
  );
};

export default MeasurementTable;
