import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import validator from "@rjsf/validator-ajv8";
import Form from "@rjsf/core";
import { Input, Label } from "reactstrap";
import ButtonTheme from "./ButtonTheme";
import { useTranslation } from "react-i18next";
// import {
//   getTemplate,
//   getUiOptions,
//   titleId,
//   StrictRJSFSchema,
//   RJSFSchema,
//   FormContextType,
//   ObjectFieldTemplateProps,
//   ObjectFieldTemplatePropertyType,
// } from "@rjsf/utils";

export default function SchemaForm({ schema, onSubmit, formData, innerRef }) {
  const [newFormData, setNewFormData] = useState({});
  const [uiSchema, setUiSchema] = useState({});

  useEffect(() => {
    const newFormData = {};
    const newUiSchema = {};
    (Object.keys(schema?.definitions?.Config?.properties || {}) || []).forEach((element) => {
      newFormData[element] = formData[element];
      newUiSchema[element] = {
        "ui:title": convertName(schema?.definitions?.Config?.properties[element]?.title),
      };
    });

    setUiSchema(newUiSchema);
    setNewFormData(newFormData);
  }, [formData, schema?.definitions?.Config?.properties]);

  const widgets = {
    CheckboxWidget: CustomCheckbox,
  };

  return (
    <Form
      schema={schema}
      validator={validator}
      // onChange={log('changed')}
      onSubmit={onSubmit}
      showErrorList={false}
      // liveValidate={true}
      formData={newFormData}
      focusOnFirstError={true}
      uiSchema={uiSchema}
      widgets={widgets}
      isLoading={true}
      templates={{
        ButtonTemplates: { AddButton, RemoveButton, MoveDownButton, MoveUpButton },
        TitleFieldTemplate,
        FieldErrorTemplate,
        // WrapIfAdditionalTemplate,
        // FieldTemplate: CustomFieldTemplate,
        // ObjectFieldTemplate,
        // ArrayFieldItemTemplate,
        // ArrayFieldTemplate,
      }}
      // onError={log('errors')}
    >
      <CustomButtonSubmit ref={innerRef} />
    </Form>
  );
}

function convertName(text) {
  if (!text) return "";
  const arrName = text?.split("_");
  const mapArrName = arrName.map((item) => `${item.charAt(0).toUpperCase()}${item.substring(1, item.length)}`);
  return mapArrName.join(" ");
}

function AddButton(props) {
  const { icon, iconType, uiSchema, ...btnProps } = props;
  return (
    <button {...btnProps} className="btn btn-info btn-add my-3" style={{ padding: "5px 15px" }} type="button">
      {/*   {icon} <FormattedMessage defaultMessage="Add" /> */}
      <i className="bx bx-plus-medical fs-16" style={{ lineHeight: "22px" }}></i>
    </button>
  );
}
function RemoveButton(props) {
  const { icon, iconType, uiSchema, ...btnProps } = props;
  return (
    <button
      {...btnProps}
      className="btn btn-danger mb-3 mt-2 p-1"
      type="button"
      style={{ width: "55px", height: "34px" }}
    >
      <i className="bx bx-minus fs-22"></i>
    </button>
  );
}
function MoveUpButton(props) {
  const { icon, iconType, ...btnProps } = props;
  return (
    <button
      {...btnProps}
      className="btn btn-default mb-3 mt-2 p-1"
      style={{ width: "50px", height: "34px", border: "1px solid #ccc" }}
    >
      {icon} <i className="ri-arrow-up-fill"></i>
    </button>
  );
}

function MoveDownButton(props) {
  const { icon, iconType, ...btnProps } = props;
  return (
    <button
      {...btnProps}
      className="btn btn-default mb-3 mt-2 p-1"
      style={{ width: "55px", height: "34px", border: "1px solid #ccc" }}
    >
      {icon} <i className="ri-arrow-down-fill"></i>
    </button>
  );
}

const CustomCheckbox = function (props) {
  return (
    <div className="d-flex align-items-end mt-2 mb-2">
      <Input
        type="checkbox"
        className="form-check-primary"
        onChange={() => props.onChange(!props.value)}
        checked={props.value}
        style={{ height: "20px", width: "20px", marginRight: "6px" }}
      />
      <Label style={{ userSelect: "none", margin: 0 }} onClick={() => props.onChange(!props.value)}>
        {convertName(props.label)}
      </Label>
    </div>
  );
};

// function ArrayFieldItemTemplate(props) {
//   const { children, className } = props;
//   return <div className={`${className} dsdd`}>{children}</div>;
// }

function ArrayFieldTemplate(props) {
  return (
    <div>
      {props.items.map((element) => element.children)}
      {props.canAdd && (
        <button
          onClick={props.onAddClick}
          className="btn btn-info btn-add my-3"
          style={{ padding: "5px 20px" }}
          type="button"
        >
          {/*   {icon} <FormattedMessage defaultMessage="Add" /> */}
          <i className="bx bx-plus-medical fs-16" style={{ lineHeight: "22px" }}></i>
        </button>
      )}
    </div>
  );
}

function TitleFieldTemplate(props) {
  const { id, required, title } = props;
  return (
    <header id={id} style={{ fontSize: "17px" }}>
      {convertName(title)}
      {required && "*"}
    </header>
  );
}

function CustomFieldTemplate(props) {
  const {
    id,
    classNames,
    style,
    label,
    help,
    required,
    description,
    errors,
    children,
    displayLabel,
    onKeyChange,
    registry,
    fields,
  } = props;

  return (
    <div className={`${classNames} mb-3`} style={style}>
      {displayLabel && (
        <label htmlFor={id} style={{ margin: 0 }}>
          {convertName(label)}
          {required ? "*" : null}
        </label>
      )}

      {description}
      {children}
      {errors}
      {help}
    </div>
  );
}

// function ObjectFieldTemplate(props) {
//   return (
//     <div>
//       <span style={{ fontSize: "17px" }}>{props.title}</span>

//       {props.properties.map((element, index) => (
//         <div className="property-wrapper" key={index}>
//           {element.content}
//         </div>
//       ))}
//     </div>
//   );
// }

// function ObjectFieldTemplate(props) {
//   const { registry, properties, title, description, uiSchema, required, schema, idSchema } = props;
//   const options = getUiOptions(uiSchema);
//   const TitleFieldTemplate = getTemplate("TitleFieldTemplate", registry, options);
//   return (
//     <div>
//       {title && (
//         <TitleFieldTemplate
//           id={titleId(idSchema)}
//           title={title}
//           required={required}
//           schema={schema}
//           uiSchema={uiSchema}
//           registry={registry}
//         />
//       )}{" "}
//       {description}
//       <div className="row">
//         {properties.map((prop) => (
//           <div key={prop.content.key}>{prop.content}</div>
//         ))}
//       </div>
//     </div>
//   );
// }

function FieldErrorTemplate(props) {
  const { errors, errorSchema } = props;
  return (
    <>
      {errors?.map((error, i) => {
        return (
          <span
            key={i}
            style={{
              fontSize: "12px",
              marginLeft: "16px",
              color: "red",
            }}
          >
            {error}
          </span>
        );
      })}
    </>
  );
}

// function ButtonSubmit

const CustomButtonSubmit = forwardRef(function ButtonSubmit(props, ref) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const refButton = useRef();
  useImperativeHandle(ref, () => ({
    showLoading: () => setLoading(true),
    hideLoading: () => setLoading(false),
    onTriggerClick: () => {
      refButton.current.click();
    },
  }));
  return (
    <ButtonTheme
      type="submit"
      className="btn btn-primary w-sm mt-3"
      loading={loading}
      disabled={loading}
      refButton={refButton}
    >
      {t("Submit")}
    </ButtonTheme>
  );
});
