import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";

// Redux States
import { LIST_BLOG_CATEGORY, DELETE_BLOG_CATEGORY, ADD_NEW_BLOG_CATEGORY, UPDATE_BLOG_CATEGORY, GET_BLOG_CATEGORY } from "./actionType";

import {
  deleteBlogCategorySuccess,
  deleteBlogCategoryFail,
  addBlogCategorySuccess,
  addBlogCategoryFail,
  updateBlogCategorySuccess,
  updateBlogCategoryFail,
  listBlogCategorySuccess,
  listBlogCategoryFail,
  getBlogCategorySuccess,
  getBlogCategoryFail,
} from "./action";

import { blogCategoryAPI } from "../../../../helpers/resource_helper";

function* listBlogCategory({ payload: { ...res } }) {
  try {
    const response = yield call(blogCategoryAPI.list, { ...res });
    yield put(listBlogCategorySuccess(response));
  } catch (error) {
    yield put(listBlogCategoryFail(error));
  }
}

function* getBlogCategory({ payload: id }) {
  try {
    const response = yield call(blogCategoryAPI.get, { id });
    yield put(getBlogCategorySuccess(response));
  } catch (error) {
    yield put(getBlogCategoryFail(error));
  }
}

function* deleteBlogCategory({ payload: Blogcategory }) {
  try {
    const response = yield call(blogCategoryAPI.delete, Blogcategory);
    yield put(deleteBlogCategorySuccess(Blogcategory));
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteBlogCategoryFail(error));
    toast.error("BlogCategory Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewBlogCategory({ payload: { Blogcategory, handleAfterSubmit } }) {
  try {
    const response = yield call(blogCategoryAPI.create, Blogcategory);
    yield put(addBlogCategorySuccess(response));
    yield call(handleAfterSubmit, response);
    toast.success("BlogCategory Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addBlogCategoryFail(error));
    toast.error("BlogCategory Added Failed", { autoClose: 3000 });
  }
}

function* onUpdateBlogCategory({ payload: { Blogcategory, handleAfterSubmit } }) {
  try {
    const response = yield call(blogCategoryAPI.put, Blogcategory);
    yield put(updateBlogCategorySuccess(response));
    yield call(handleAfterSubmit, response);
    toast.success("BlogCategory Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateBlogCategoryFail(error));
    toast.error("BlogCategory Updated Failed", { autoClose: 3000 });
  }
}

export function* watchGetCategories() {
  yield takeEvery(LIST_BLOG_CATEGORY, listBlogCategory);
}

export function* watchDeleteBlogCategory() {
  yield takeEvery(DELETE_BLOG_CATEGORY, deleteBlogCategory);
}

export function* watchGetBlogCategory() {
  yield takeEvery(GET_BLOG_CATEGORY, getBlogCategory);
}

export function* watchUpdateBlogCategory() {
  yield takeEvery(UPDATE_BLOG_CATEGORY, onUpdateBlogCategory);
}

export function* watchAddNewBlogCategory() {
  yield takeEvery(ADD_NEW_BLOG_CATEGORY, onAddNewBlogCategory);
}

function* blogCategoriesSaga() {
  yield all([
    fork(watchGetBlogCategory),
    fork(watchGetCategories),
    fork(watchUpdateBlogCategory),
    fork(watchAddNewBlogCategory),
    fork(watchDeleteBlogCategory),
  ]);
}

export default blogCategoriesSaga;
