import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import { convertValidDate, convertValidTime } from "../../../../helpers/format_helper";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import withRouter from "../../../../Components/Common/withRouter";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { deleteTerminal, listTerminal } from "../../../../store/actions";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import EditAddTerminalModal from "./EditAddTerminalModal";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import ActionDropdown from "../../../../Components/Common/ActionDropdown";

const TerminalsPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Terminal List")} | OptiWarehouse`;

  const dispatch = useDispatch();

  const {
    terminals,
    terminalsLoading,
    terminalsSuccess,
    terminalsAction,
    terminalsError,
    terminalsLimit,
    terminalsTotal,
    terminalsPage,
  } = useMemoizedSelector((state) => ({
    terminals: state.Terminals.terminals,
    terminalsLoading: state.Terminals.loading,
    terminalsAction: state.Terminals.action,
    terminalsSuccess: state.Terminals.success,
    terminalsError: state.Terminals.error,
    terminalsLimit: state.Terminals.limit,
    terminalsTotal: state.Terminals.total,
    terminalsPage: state.Terminals.page,
  }));

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTerminal, setSelectedTerminal] = useState(null);
  const [showEditAddModal, setShowEditAddModal] = useState(false);

  const handleAdd = () => {
    setShowEditAddModal(true);
  };

  const handleEdit = (data) => {
    setShowEditAddModal(true);
    setSelectedTerminal(data);
  };

  const handleCloseEditAddModal = () => {
    if (!terminalsLoading) {
      setSelectedTerminal(null);
      setShowEditAddModal(false);
    }
  };

  const handleDelete = () => {
    dispatch(
      deleteTerminal(selectedTerminal, () => {
        setShowDeleteModal(false);
        setSelectedTerminal(null);
      }),
    );
  };

  const handleCloseDeleteModal = () => {
    if (!terminalsLoading) {
      setShowDeleteModal(false);
      setSelectedTerminal(null);
    }
  };

  const headers = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Activate",
      dataIndex: "is_activate",
      key: "is_activate",
      sorter: true,
      render: (value, row, index) => {
        return <i className={`ri-checkbox-blank-circle-fill align-bottom ${value ? "text-success" : "text-danger"}`} />;
      },
    },
    {
      title: "Updated at",
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: true,
      render: (value, row, index) => {
        return (
          <>
            {convertValidDate(value)},<small className="text-muted"> {convertValidTime(value)}</small>
          </>
        );
      },
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      defaultSortOrder: "desc",
      sorter: true,
      render: (value, row, index) => {
        return (
          <>
            {convertValidDate(value)},<small className="text-muted"> {convertValidTime(value)}</small>
          </>
        );
      },
    },
    {
      title: t("Action"),
      dataIndex: "action",
      key: "action",
      render: (value, row, index) => {
        return (
          <ActionDropdown
            index={index}
            actions={[
              {
                type: "edit",
                onClick: () => {
                  handleEdit(row);
                },
              },
              {
                type: "delete",
                onClick: () => {
                  setSelectedTerminal(row);
                  setShowDeleteModal(true);
                },
              },
            ]}
          />
        );
      },
    },
  ];

  return (
    <div className="page-content">
      <Container fluid>
        <DeleteModal
          show={showDeleteModal}
          onCloseClick={handleCloseDeleteModal}
          onDeleteClick={handleDelete}
          loading={terminalsLoading}
        />
        <EditAddTerminalModal show={showEditAddModal} toggle={handleCloseEditAddModal} terminal={selectedTerminal} />
        <Row>
          <Col lg={12}>
            <Card id="price-group-list">
              <CardHeader className="border-0">
                <Row className="g-4 align-items-center">
                  <div className="col-sm">
                    <div>
                      <h5 className="card-title mb-0">{t("Terminal List")}</h5>
                    </div>
                  </div>
                  <div className="col-sm-auto">
                    <div>
                      <ButtonTheme
                        type="button"
                        className="btn btn-primary add-btn"
                        id="create-btn"
                        onClick={handleAdd}
                        icon="ri-add-line text-white"
                      >
                        {t("Add Terminal")}
                      </ButtonTheme>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <TableContainerCustom
                  filterType={"terminals"}
                  searchPlaceHolder={t("Search terminal...")}
                  listFilter={[]}
                  headers={headers}
                  contents={terminals}
                  limitPage={Number(terminalsLimit)}
                  currentPage={Number(terminalsPage) + 1}
                  totalItems={terminalsTotal}
                  loading={terminalsLoading}
                  showPagination={true}
                  scrollHeight="50vh"
                  onCallData={(dataPayload) => {
                    dispatch(listTerminal(dataPayload));
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default withRouter(TerminalsPage);
