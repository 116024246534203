import { call, put, takeEvery } from "redux-saga/effects";

import { changePasswordAPI } from "../../../helpers/AuthAPI";

import { CHANGE_PASSWORD } from "./actionTypes";

import { changePassFail, changePassSuccess } from "./actions";

function* changePassword({ payload }) {
  try {
    const response = yield call(changePasswordAPI, payload);
    yield put(changePassSuccess(response.message));
  } catch (error) {
    yield put(changePassFail(error));
  }
}
function* ChangePassSaga() {
  yield takeEvery(CHANGE_PASSWORD, changePassword);
}

export default ChangePassSaga;
