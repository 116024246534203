import React, { useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import BreadCrumbCustom from "../../../../Components/Common/BreadCrumbCustom";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import ModalContainer from "../../../../Components/Common/ModalContainer";
import { FieldArray, Form, Formik } from "formik";
import InputTheme from "../../../../Components/Common/InputTheme";
import { voucherCodeAPI } from "../../../../helpers/resource_helper";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import ScrollToError from "../../../../Components/Common/ScrollToError";

export default function DetailVoucherCode() {
  const { t } = useTranslation();
  document.title = `${t("Voucher Code List")} | OptiWarehouse`;
  const headers = [
    {
      title: t("Voucher code"),
      dataIndex: "code",
      key: "code",
      sorter: true,
    },
    {
      title: t("Used times"),
      dataIndex: "used",
      key: "used",
      sorter: true,
    },
    {
      title: t("Max quantity"),
      dataIndex: "max_quantity",
      key: "max_quantity",
      sorter: true,
      render: (value, row, index) => {
        return <>{value ? value : <span className="fs-20">∞</span>}</>;
      },
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (value, row, index) => {
        return (
          <>
            {value === "NOT_ACTIVE" ? (
              <span className="badge  bg-primary mb-2 mx-3">{t("Not active")}</span>
            ) : value === "APPLY" ? (
              <span className="badge  bg-success mb-2 mx-3">{t("Applying")}</span>
            ) : (
              <span className="badge  bg-light mb-2 mx-3">{t("Stop applying")}</span>
            )}
          </>
        );
      },
    },
    {
      title: t("Create at"),
      dataIndex: "created_at",
      key: "created_at",
      defaultSortOrder: "desc",
      sorter: true,
      render: (value, row, index) => {
        return <>{value}</>;
      },
    },
    {
      title: t("Action"),
      dataIndex: "action",
      render: (value, row, index) => {
        return (
          <UncontrolledDropdown>
            <DropdownToggle href="#" className="btn btn-soft-primary btn-sm" tag="button">
              <i className="ri-more-fill" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end" container="body">
              {row.status === "NOT_ACTIVE" ? (
                <DropdownItem
                  onClick={async () => {
                    const res = await voucherCodeAPI.put({ ...row, id: row.code, status: "APPLY" });
                  }}
                >
                  <i className="ri-pencil-fill align-bottom me-2 text-primary"></i> {t("Active")}
                </DropdownItem>
              ) : (
                <DropdownItem onClick={() => {}}>
                  <i className="ri-pencil-fill align-bottom me-2 text-primary"></i> {t("Pause")}
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [isModal, setIsModal] = useState(false);
  const [isTypeModal, setIsTypeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [vouchersCode, setVouchersCode] = useState([]);
  const { id_voucher } = useParams();
  function randomNumericString(length) {
    let result = "";
    const characters = "0123456789";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  }

  const formRadomRef = useRef();
  const formHandmadeRef = useRef();
  const formRadom = {
    initialValues: {
      quantity: null,
      prefix: "",
      number_of_characters: null,
      suffixes: "",
    },
    validationSchema: Yup.object().shape({
      quantity: Yup.string().required(t("Required code quantity")),
      prefix: Yup.string().required(t("Required prefix code")),
      suffixes: Yup.string().required(t("Required suffixes code")),
      number_of_characters: Yup.number().required(t("Required number characters")),
    }),
    onSubmit: async (values) => {
      const arr = [];
      for (let index = 0; index < Number(values.quantity); index++) {
        const code = values.prefix + randomNumericString(Number(values.number_of_characters)) + values.suffixes;
        arr.push(code);
      }
      setLoading(true);
      await voucherCodeAPI.create({
        voucher_id: id_voucher,
        list_code: arr,
      });
      toast.success(t("Add code successfully!"));
      setLoading(false);
      setIsModal(false);
      getApiVoucherCode();
    },
  };
  const formHandmade = {
    initialValues: {
      code: [""],
    },
    validationSchema: Yup.object().shape({
      code: Yup.array()
        .of(
          Yup.string().required(t("Code is required")), // Quy tắc validation cho từng phần tử trong mảng
        )
        .min(1, t("At least one code is required")),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      await voucherCodeAPI.create({
        voucher_id: id_voucher,
        list_code: values.code,
      });
      toast.success(t("Add code successfully!"));
      setLoading(false);
      setIsModal(false);
      getApiVoucherCode();
    },
  };

  const getApiVoucherCode = async (params) => {
    setLoadingTable(true);
    try {
      const res = await voucherCodeAPI.list({
        voucher_id: id_voucher,
        limit: limit,
        ...(params && params),
      });
      setVouchersCode(res.items);
      setLimit(res.limit);
      setPage(res.page);
      setTotal(res.total);
    } catch (error) {
      toast.error(error.toString());
    }
    setLoadingTable(false);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <ModalContainer
          isOpen={isModal}
          toggle={() => {
            setIsModal(false);
          }}
          title={isTypeModal ? "Add random voucher code" : "Add voucher code"}
          actionCustom={
            <>
              <ButtonTheme
                type="button"
                className="btn btn-light w-sm"
                onClick={() => {
                  setIsModal(false);
                }}
              >
                {t("Close")}
              </ButtonTheme>
              <ButtonTheme
                type="button"
                loading={loading}
                loadShowText={true}
                className="btn btn-primary w-sm"
                onClick={() => {
                  if (isTypeModal) {
                    formRadomRef.current.handleSubmit();
                  } else {
                    formHandmadeRef.current.handleSubmit();
                  }
                }}
              >
                {t("Add")}
              </ButtonTheme>
            </>
          }
        >
          {isTypeModal ? (
            <Formik
              enableReinitialize={true}
              initialValues={formRadom.initialValues}
              validationSchema={formRadom.validationSchema}
              onSubmit={formRadom.onSubmit}
              innerRef={formRadomRef}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <ScrollToError />
                  <Row>
                    <InputTheme
                      name={"quantity"}
                      label={"Voucher code quantity"}
                      format="price"
                      placeholder="Max as 5,000"
                    />
                  </Row>
                  <div className="d-flex">
                    <InputTheme
                      name={"prefix"}
                      label={"Prefix"}
                      subLabel={"Enter only numbers and capital letters. Maximum 10 characters."}
                      placeholder="VD: ONEX"
                    />
                    <InputTheme
                      name={"number_of_characters"}
                      label={"Number of characters"}
                      subLabel={"Minimum 6, maximum 20"}
                      placeholder="Ex: 6"
                      type="number"
                    />
                    <InputTheme
                      name={"suffixes"}
                      label={"Suffixes"}
                      subLabel={"Enter only numbers and capital letters. Maximum 10 characters."}
                      placeholder="Ex: SALE"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <Formik
              enableReinitialize={true}
              initialValues={formHandmade.initialValues}
              validationSchema={formHandmade.validationSchema}
              onSubmit={formHandmade.onSubmit}
              innerRef={formHandmadeRef}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <ScrollToError />
                  <FieldArray name="code">
                    {({ push, remove }) => (
                      <div>
                        {values.code.map((item, index) => (
                          <div key={index} className="box-setting p-1">
                            <div className="align-items-center d-flex">
                              <InputTheme name={`code.${index}`} label={"Voucher code"} classWrapper="w-100" />
                              <button
                                className="btn btn-danger mx-3 p-1 hover-border-box mt-2"
                                type="button"
                                onClick={() => remove(index)}
                                style={{
                                  height: "30px",
                                }}
                              >
                                <i className="bx bx-minus fs-22"></i>
                              </button>
                            </div>
                          </div>
                        ))}
                        <button
                          className="btn btn-info btn-add mb-3 mt-2 p-1"
                          type="button"
                          onClick={() => push("")}
                          style={{
                            height: "32px",
                            width: "38px",
                          }}
                        >
                          <i className="bx bx-plus-medical fs-21"></i>
                        </button>
                      </div>
                    )}
                  </FieldArray>
                </Form>
              )}
            </Formik>
          )}
        </ModalContainer>
        <BreadCrumbCustom text="Back to voucher list" navigateTo="/vouchers"></BreadCrumbCustom>
        <Card>
          <CardHeader className="border-0">
            <div className="align-items-center d-flex justify-content-around">
              <div className="col-sm">
                <div>
                  <h5 className="card-title mb-0">{t("Voucher code list of")} CPM3</h5>
                </div>
              </div>
              <div className="align-items-center d-flex justify-content-around gap-3">
                <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
                  <DropdownToggle caret>{t("Add new voucher code")}</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => {
                        setIsModal(true);
                        setIsTypeModal(false);
                      }}
                    >
                      {t("Add manually")}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setIsModal(true);
                        setIsTypeModal(true);
                      }}
                    >
                      {t("Add randomly")}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>

                <ButtonTheme type="button" className="btn btn-info" onClick={() => {}}>
                  <i className="ri-file-download-line align-bottom me-1"></i>
                  {t("Export")}
                </ButtonTheme>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <TableContainerCustom
              filterType={"voucherCode"}
              searchPlaceHolder="Search voucher codes..."
              listFilter={[]}
              headers={headers}
              contents={vouchersCode}
              limitPage={Number(limit)}
              currentPage={Number(page) + 1}
              totalItems={total}
              loading={loadingTable}
              showPagination={true}
              scrollHeight="50vh"
              onCallData={(dataPayload) => {
                // const { sort_created_at, ...res } = dataPayload;
                getApiVoucherCode(dataPayload);
              }}
              isCheckbox={true}
              listAction={[
                {
                  title: "",
                  type: "boxSelect",
                  listAction: [
                    {
                      title: t("Active"),
                      onClick: async (data) => {
                        const mapApi = data.map((item) => {
                          const findItem = vouchersCode.find((i) => i.code === item);
                          return voucherCodeAPI.put({ ...findItem, id: findItem.code, status: "APPLY" });
                        });
                        await Promise.all(mapApi);
                      },
                    },
                    {
                      title: t("Pause"),
                      onClick: async (data) => {
                        const mapApi = data.map((item) => {
                          const findItem = vouchersCode.find((i) => i.code === item);
                          return voucherCodeAPI.put({ ...findItem, id: findItem.code, status: "STOP_APPLY" });
                        });
                        await Promise.all(mapApi);
                      },
                    },
                  ],
                },
              ]}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}
