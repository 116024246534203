import { Fragment, useEffect, useState } from "react";
import { formatVNDCurrency, getChartColorsArray } from "../../../../helpers/format_helper";
import ReactApexChart from "react-apexcharts";
import NotFound from "../../../../Components/Common/NotFound";

const AverageOverviewChart = ({ dataColors, sellingOverview, t }) => {
  var chartLineColumnColors = getChartColorsArray(dataColors);
  const initSeries = [
    {
      name: t("Average sale price"),
      type: "column",
      data: [],
    },
    {
      name: t("Average discount"),
      type: "line",
      data: [],
    },
  ];
  const [series, setSeries] = useState(initSeries);
  const [options, setOptions] = useState({
    chart: {
      toolbar: {
        show: !1,
      },
    },
    stroke: {
      width: [0, 4],
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
    },
    xaxis: {
      categories: [],
    },
    yaxis: [
      {
        title: {
          text: t("Average sale price"),
          style: {
            fontWeight: 600,
          },
        },
        labels: {
          formatter: function (val) {
            return formatVNDCurrency(val);
          },
        },
      },

      {
        opposite: true,
        title: {
          text: t("Average discount"),
          style: {
            fontWeight: 600,
          },
        },
        labels: {
          formatter: function (val) {
            return formatVNDCurrency(val);
          },
        },
      },
    ],
    tooltip: {
      y: [
        {
          formatter: function (val) {
            return formatVNDCurrency(val);
          },
        },
        {
          formatter: function (val) {
            return formatVNDCurrency(val);
          },
        },
      ],
    },
    legend: {
      position: "top",
      offsetX: 0,
      offsetY: 0,
    },
    colors: chartLineColumnColors,
  });

  useEffect(() => {
    const labelArray = sellingOverview.map((item) => item.period);
    const serries1 = sellingOverview.map((item) => (item?.average_discount || 0).toFixed(0));
    const serries2 = sellingOverview.map((item) => (item?.average_sale_price || 0).toFixed(0));
    const newSeries = [];
    if (serries1.length > 0 && serries2.length > 0) {
      newSeries.push(
        {
          name: t("Average sale price"),
          type: "column",
          data: serries2,
        },
        {
          name: t("Average discount"),
          type: "line",
          data: serries1,
        },
      );
    }
    setSeries(newSeries);
    setOptions((pre) => ({ ...pre, xaxis: { categories: labelArray } }));
  }, [sellingOverview]);

  if (series.length === 0) {
    return <NotFound />;
  }

  return (
    <Fragment>
      <ReactApexChart dir="ltr" className="apex-charts" options={options} series={series} type="line" height={350} />
    </Fragment>
  );
};

export default AverageOverviewChart;
