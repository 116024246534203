import { Tooltip } from "antd";
import React, { useState } from "react";
import { UserOutlined, SearchOutlined } from "@ant-design/icons";
import { Card, CardBody, CardHeader, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import SimpleBar from "simplebar-react";
import AvatarTheme from "../../../../Components/Common/Avatar";
import { assignStaffPackage } from "../../../../helpers/service_helper";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function AvatarAssign({ users, onChangeUser, userSelect = "", idPackage }) {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownOpen((prevState) => !prevState);
  };
  return (
    <div>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle data-toggle="dropdown" tag="div">
          {userSelect ? (
            <div className="position-relative warper-select-avatar">
              <div
                className="position-absolute close-avatar"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  try {
                    const dataClear = { id: null };
                    assignStaffPackage(idPackage, dataClear);
                    onChangeUser(dataClear);
                  } catch (error) {
                    toast.error(error.toString());
                  }
                }}
              >
                <i className="ri-close-line"></i>
              </div>
              <AvatarTheme name={userSelect.name} />
            </div>
          ) : (
            <Tooltip title="assign">
              <div className="warper-add-avatar">
                <UserOutlined />
                <div className="warper-add">
                  <i className=" ri-add-fill"></i>
                </div>
              </div>
            </Tooltip>
          )}
        </DropdownToggle>
        <DropdownMenu container="body">
          <div className="px-2 py-1 border-bottom">
            <SearchOutlined style={{ fontSize: "17px", padding: "0 8px" }} />
            <input className="border-0" placeholder={t("Search...")} />
          </div>
          <div className="py-2">
            <SimpleBar style={{ maxHeight: "400px" }}>
              {users.map((user, index) => (
                <DropdownItem
                  className="d-flex align-items-center"
                  key={index}
                  onClick={() => {
                    onChangeUser(user);
                    assignStaffPackage(idPackage, { id: user.id });
                  }}
                >
                  <AvatarTheme name={user.name} />
                  <span className="px-2">{user.name}</span>
                </DropdownItem>
              ))}
            </SimpleBar>
          </div>
        </DropdownMenu>
      </Dropdown>

      <div className="position-absolute" style={{ zIndex: 100 }}></div>
    </div>
  );
}
