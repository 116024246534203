import React, { Fragment, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";

import { Alert } from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { Form, Formik } from "formik";

import ButtonTheme from "../../Components/Common/ButtonTheme";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

// action
import { registerUser, apiError, resetRegisterFlag } from "../../store/actions";
import useMemoizedSelector from "../../Components/Hooks/useMemoizedSelector";
import { images } from "../../assets/images";
import { IconGoogle, LogoLight } from "../../assets/images/svg";
import InputTheme from "../../Components/Common/InputTheme";
import LanguageDropdown from "../../Components/Common/LanguageDropdown";
import ScrollToError from "../../Components/Common/ScrollToError";

const Register = ({ t }) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formRef = useRef();

  const validation = {
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      user_name: "",
      phone_number: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email(t("Please enter a valid email address")).required(t("Please enter your email")),
      user_name: Yup.string()
        .matches(/^[a-zA-Z0-9_]+$/, t("Username must contain only letters, numbers, or underscores"))
        .required(t("Please enter your username")),
      phone_number: Yup.string().required(t("Please enter your phone number")),
      password: Yup.string()
        .min(8, t("Password must be at least 8 characters"))
        .matches(RegExp("(.*[a-z].*)"), t("At least lowercase letter"))
        .matches(RegExp("(.*[A-Z].*)"), t("At least uppercase letter"))
        .matches(RegExp("(.*[0-9].*)"), t("At least one number"))
        .matches(/[/\W|_/g]/, t("At least one symbol"))
        .required(t("Password is required")),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), null], t("Passwords must match"))
        .required(t("Confirm password is required")),
    }),
    onSubmit: (values) => {
      dispatch(registerUser(values));
    },
  };

  const { error, registrationError, success, loading } = useMemoizedSelector((state) => ({
    registrationError: state.Account.registrationError,
    success: state.Account.success,
    error: state.Account.error,
    loading: state.Account.loading,
  }));

  useEffect(() => {
    dispatch(apiError(""));
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      dispatch(resetRegisterFlag());
      setTimeout(
        () => history("/verify-confirmation-code", { state: { username: formRef.current.values.user_name } }),
        3000,
      );
    }
    if (!loading && error) {
      let resetMsgTime = setTimeout(() => {
        dispatch(resetRegisterFlag());
      }, 3000);
      return () => clearTimeout(resetMsgTime);
    }
  }, [dispatch, success, error, history, loading]);

  document.title = `${t("Basic Sign Up")} | OneXAPIs`;

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="tw-bg-white">
          <div className="tw-flex tw-w-full mq850:tw-justify-center tw-h-screen">
            <div
              className={
                "tw-w-[60%] mq850:tw-w-[90%] tw-flex tw-flex-col tw-justify-center tw-mt-[-100px] mq850:tw-mt-[50px]"
              }
            >
              <div className={"tw-mx-[10%] mq850:tw-mx-[0px] tw-flex  tw-flex-col tw-justify-center "}>
                <div className={"tw-flex tw-justify-between tw-items-center"}>
                  <img src={LogoLight} className={"tw-h-[60px] tw-w-[200px]"} />
                  <div className={"tw-flex  tw-items-center"}>
                    <p className={"tw-font-normal tw-mt-2  tw-text-[14px]"}>{t("language")} : </p>
                    <LanguageDropdown />
                  </div>
                </div>
                <p className={"tw-text-[40px] tw-font-bold tw-text-[#151334] tw-mt-[60px] mq850:tw-mt-[20px]"}>
                  {t("Sign up")}
                </p>
                <p className={"tw-text-[14px] tw-font-normal"}>{t("register")}</p>
                {/*<button*/}
                {/*  className={"tw-p-3 tw-flex  tw-w-[calc(50%_-_16px)] tw-h-[48px] tw-bg-white tw-border-0 tw-gap-3 tw-shadow-md tw-border tw-rounded-md tw-min-w-[270px] tw-mt-4"}>*/}

                {/*  <img src={IconGoogle} alt={""} width={24} height={24} />*/}
                {/*  <p className={"tw-text-[16px] tw-font-medium tw-text-[#888888]"}>*/}
                {/*    {t("signInWithGoogle")}*/}
                {/*  </p>*/}
                {/*</button>*/}
                <div className="tw-mt-[32px]">
                  <Formik
                    enableReinitialize={true}
                    initialValues={validation.initialValues}
                    validationSchema={validation.validationSchema}
                    onSubmit={validation.onSubmit}
                    className="needs-validation"
                    innerRef={formRef}
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <ScrollToError />
                        {success && success ? (
                          <>
                            {toast(t("Your Redirect To Confirmation Page..."), {
                              position: "top-right",
                              hideProgressBar: false,
                              className: "bg-success text-white",
                              progress: undefined,
                              toastId: "",
                            })}
                            <ToastContainer autoClose={2000} limit={1} />
                            <Alert color="success">
                              {t("Register User Successfully and Your Redirect To Login.js Page")}...
                            </Alert>
                          </>
                        ) : null}

                        {error && error ? (
                          <Alert color="danger">
                            <div>{t(registrationError)}</div>
                          </Alert>
                        ) : null}

                        <div className="mb-3">
                          <InputTheme
                            name="email"
                            type="email"
                            label={
                              <Fragment>
                                {t("Email")} <span className="text-danger">*</span>
                              </Fragment>
                            }
                            placeholder="Enter email address"
                          />
                        </div>

                        <div className="mb-3">
                          <InputTheme
                            name="user_name"
                            label={
                              <Fragment>
                                {t("Username")} <span className="text-danger">*</span>
                              </Fragment>
                            }
                            placeholder="Username"
                          />
                        </div>
                        <div className="mb-3">
                          <InputTheme
                            name="phone_number"
                            label={
                              <Fragment>
                                {t("Phone Number")} <span className="text-danger">*</span>
                              </Fragment>
                            }
                            placeholder="Enter Phone Number"
                          />
                        </div>
                        <div className={"tw-grid tw-grid-cols-2 tw-gap-4"}>
                          <InputTheme
                            name="password"
                            type="password"
                            label={
                              <Fragment>
                                {t("Password")} <span className="text-danger">*</span>
                              </Fragment>
                            }
                            placeholder="Enter Password"
                          />
                          <InputTheme
                            name="confirm_password"
                            type="password"
                            label={
                              <Fragment>
                                {t("Confirm Password")} <span className="text-danger">*</span>
                              </Fragment>
                            }
                            placeholder="Confirm Password"
                          />
                        </div>

                        <div className="mt-4">
                          <ButtonTheme
                            className="btn btn-primary w-100"
                            type="submit"
                            loading={loading}
                            disabled={loading}
                          >
                            {t("Sign Up")}
                          </ButtonTheme>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
                <div className={"tw-flex tw-gap-3 tw-mt-2 mq850:tw-mt-[35px] "}>
                  <p className={"tw-text-[14px] tw-text-normal tw-text-[#737185]"}>{t("haveAccount")}</p>
                  <p
                    className={"tw-font-normal tw-text-[14px] tw-text-[#5252E1] hover:tw-cursor-pointer"}
                    onClick={() => navigate("/login")}
                  >
                    {t("signIn")}
                  </p>
                </div>

                <p className="tw-absolute mq850:tw-relative tw-bottom-[50px] mq850:tw-bottom-[0px] mq850:tw-mt-[50px] tw-text-[#737185] tw-text-center mq850:tw-text-[12px]">
                  &copy; {new Date().getFullYear()} OptiWarehouse. {t("Crafted with")}{" "}
                  <i className="mdi mdi-heart text-danger"></i> {t("by OneXAPIs team")}
                </p>
              </div>
              {/*<div className="mt-4 text-center">*/}
              {/*  <p className="mb-0">*/}
              {/*    {t("Already have an account?")}{" "}*/}
              {/*    <Link to="/login" className="fw-semibold text-primary text-decoration-underline">*/}
              {/*      {" "}*/}
              {/*      {t("Sign in")}{" "}*/}
              {/*    </Link>{" "}*/}
              {/*  </p>*/}
              {/*</div>*/}
            </div>
            <img
              src={images.ImageLogin}
              alt={""}
              className={"tw-w-[40%] tw-object-cover tw-h-screen mq850:tw-hidden"}
            />
          </div>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withTranslation()(Register);
