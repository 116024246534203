import { Pagination } from "antd";
import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";
import SelectTheme from "./SelectTheme";

const PaginationCustom = ({ currentPage, totalItems, limit = 10, payload = {}, onGetApi = () => {} }) => {
  const { t } = useTranslation();
  const limitOptions = [10, 20, 50, 100];

  const handleChangeLimit = (data) => {
    const dataPayload = { ...payload, limit: data };
    dataPayload.page && delete dataPayload.page;
    onGetApi(dataPayload);
  };

  const handleChangePage = (page) => {
    const data = { ...payload, page, limit: limit };
    onGetApi(data);
  };
  return (
    <div className="hstack flex-wrap justify-content-between gap-1">
      <div className="d-flex align-items-center text-muted">
        <span className="me-2">{t("Showing")}</span>
        <div className="me-2">
          {/* <SelectTheme
            options={limitOptions.map((item) => ({ id: item, name: item }))}
            isForm={false}
            classWrapper="flex-shrink-0"
            value={limit}
            onChange={(data) => {
              handleChangeLimit(data);
            }}
          /> */}
          <Input
            id="limit-field"
            className="form-select py-1"
            type="select"
            onChange={(e) => handleChangeLimit(e.target.value)}
            value={limit}
          >
            {limitOptions.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </Input>
        </div>
        <span className="me-2">{t("results")}.</span>
        <span>
          {t("From")} <b>{(currentPage - 1) * limit + 1}</b> {t("to")}{" "}
          <b>{currentPage * limit > totalItems ? totalItems : currentPage * limit}</b> {t("of a total of")}{" "}
          <b>{totalItems}</b>
        </span>
      </div>
      <div className="hstack">
        <Pagination
          current={currentPage}
          pageSize={limit}
          total={totalItems}
          onChange={(page) => {
            handleChangePage(page - 1);
          }}
          showQuickJumper
        />
      </div>
    </div>
  );
};

export default PaginationCustom;
