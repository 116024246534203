import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";


// Redux States
import { LIST_TERMINAL, DELETE_TERMINAL, ADD_NEW_TERMINAL, UPDATE_TERMINAL, GET_TERMINAL } from "./actionType";

import {
  deleteTerminalSuccess,
  deleteTerminalFail,
  addTerminalSuccess,
  addTerminalFail,
  updateTerminalSuccess,
  updateTerminalFail,
  listTerminalSuccess,
  listTerminalFail,
  getTerminalSuccess,
  getTerminalFail,
} from "./action";

import { terminalsAPI } from "../../../../helpers/resource_helper";

function* listTerminal({ payload: { ...rest } }) {
  try {
    const response = yield call(terminalsAPI.list, { ...rest });
    yield put(listTerminalSuccess(response));
  } catch (error) {
    yield put(listTerminalFail(error));
  }
}

function* getTerminal({ payload: id }) {
  try {
    const response = yield call(terminalsAPI.get, { id });
    yield put(getTerminalSuccess(response));
  } catch (error) {
    yield put(getTerminalFail(error));
  }
}

function* deleteTerminals({ payload: { terminal, handleAfterSubmit } }) {
  try {
    const response = yield call(terminalsAPI.delete, { id: terminal.id });
    yield put(deleteTerminalSuccess(terminal));
    yield call(handleAfterSubmit);
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteTerminalFail(error));
    toast.error(error.toString(), { autoClose: 3000 });
  }
}

function* onAddNewTerminal({ payload: { terminal, handleAfterSubmit } }) {
  try {
    const response = yield call(terminalsAPI.create, terminal);
    yield put(addTerminalSuccess(response));
    yield call(handleAfterSubmit, response.id);
    toast.success("Terminal Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addTerminalFail(error));
    toast.error(error.toString(), { autoClose: 3000 });
  }
}

function* onUpdateTerminal({ payload: { terminal, handleAfterSubmit } }) {
  try {
    const response = yield call(terminalsAPI.put, terminal);
    yield put(updateTerminalSuccess(response));
    yield call(handleAfterSubmit, response.id);
    toast.success("Terminal Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateTerminalFail(error));
    toast.error(error.toString(), { autoClose: 3000 });
  }
}

export function* watchGetTerminals() {
  yield takeEvery(LIST_TERMINAL, listTerminal);
}

export function* watchDeleteTerminals() {
  yield takeEvery(DELETE_TERMINAL, deleteTerminals);
}

export function* watchGetTerminal() {
  yield takeEvery(GET_TERMINAL, getTerminal);
}

export function* watchUpdateTerminal() {
  yield takeEvery(UPDATE_TERMINAL, onUpdateTerminal);
}

export function* watchAddNewTerminal() {
  yield takeEvery(ADD_NEW_TERMINAL, onAddNewTerminal);
}

function* terminalsSaga() {
  yield all([
    fork(watchGetTerminal),
    fork(watchGetTerminals),
    fork(watchUpdateTerminal),
    fork(watchAddNewTerminal),
    fork(watchDeleteTerminals),
  ]);
}

export default terminalsSaga;
