import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { getVersionAPI } from "../../helpers/service_helper";
import { getVersionFail, getVersionSuccess } from "./action";
import { GET_VERSION } from "./actionType";

function* getVersion() {
  try {
    const response = yield call(getVersionAPI);
    yield put(getVersionSuccess(response));
  } catch (error) {
    yield put(getVersionFail(error));
  }
}

export function* watchGetVersion() {
  yield takeEvery(GET_VERSION, getVersion);
}

function* versionSaga() {
  yield all([fork(watchGetVersion)]);
}

export default versionSaga;
