import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";


// Redux States
import { LIST_VARIANT, DELETE_VARIANT, ADD_NEW_VARIANT, UPDATE_VARIANT, GET_VARIANT, IMPORT_VARIANTS } from "./actionType";

import {
  deleteVariantSuccess,
  deleteVariantFail,
  addVariantSuccess,
  addVariantFail,
  updateVariantSuccess,
  updateVariantFail,
  listVariantSuccess,
  listVariantFail,
  getVariantSuccess,
  getVariantFail,
} from "./action";

import { variantsAPI } from "../../../../helpers/resource_helper";

function* listVariant({ payload: { ...rest } }) {
  try {
    const response = yield call(variantsAPI.list, { ...rest });
    yield put(listVariantSuccess(response));
  } catch (error) {
    yield put(listVariantFail(error));
  }
}

function* getVariant({ payload: id }) {
  try {
    const response = yield call(variantsAPI.get, { id });
    yield put(getVariantSuccess(response));
  } catch (error) {
    yield put(getVariantFail(error));
  }
}

function* deleteVariants({ payload: variant }) {
  try {
    const response = yield call(variantsAPI.delete, { id: variant.id });
    yield put(deleteVariantSuccess(variant));
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteVariantFail(error));
    toast.error("Variant Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewVariant({ payload: variant }) {
  try {
    const response = yield call(variantsAPI.create, variant);
    yield put(addVariantSuccess(response));
    toast.success("Variant Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addVariantFail(error));
    toast.error("Variant Added Failed", { autoClose: 3000 });
  }
}

function* onUpdateVariant({ payload: variant }) {
  try {
    const response = yield call(variantsAPI.put, variant);
    yield put(updateVariantSuccess(response));
    toast.success("Variant Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateVariantFail(error));
    toast.error("Variant Updated Failed", { autoClose: 3000 });
  }
}

export function* watchGetVariants() {
  yield takeEvery(LIST_VARIANT, listVariant);
}

export function* watchDeleteVariants() {
  yield takeEvery(DELETE_VARIANT, deleteVariants);
}

export function* watchGetVariant() {
  yield takeEvery(GET_VARIANT, getVariant);
}

export function* watchUpdateVariant() {
  yield takeEvery(UPDATE_VARIANT, onUpdateVariant);
}

export function* watchAddNewVariant() {
  yield takeEvery(ADD_NEW_VARIANT, onAddNewVariant);
}

function* variantsSaga() {
  yield all([
    fork(watchGetVariant),
    fork(watchGetVariants),
    fork(watchUpdateVariant),
    fork(watchAddNewVariant),
    fork(watchDeleteVariants),
  ]);
}

export default variantsSaga;
