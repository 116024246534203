import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import HistoryStatus from "../../pages/Ecommerce/Orders/Components/HistoryStatus";
import { Link } from "react-router-dom";
import { convertValidDate, convertValidTime } from "../../helpers/format_helper";
import classnames from "classnames";
import ButtonTheme from "./ButtonTheme";

const OrderHistoryModal = ({ isOpen, toggle, title, histories = [], defaultStep=[] }) => {
  const { t } = useTranslation();
  return (
    <Modal fade={true} isOpen={isOpen} toggle={toggle} centered={true} /*  backdrop={false} */>
      <ModalHeader>{t(title)}</ModalHeader>
      <ModalBody className="py-3 px-5">
        <div className="profile-timeline">
          {histories ? (
            <div className="accordion accordion-flush" id="accordionFlushExample">
              <HistoryStatus
                histories={histories}
                defaultStep={defaultStep}
              />
              {/* {histories.map((item, index) => (
                <div key={index} className="accordion-item border-0">
                  <div className="accordion-header" id="headingOne">
                    <Link to="#" className={classnames("accordion-button", "p-2", "shadow-none")}>
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 avatar-xs">
                          <div className="avatar-title bg-primary rounded-circle">
                            <i className="mdi mdi-clock-time-eight-outline"></i>
                          </div>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h6 className="fs-15 mb-0 fw-semibold">
                            {item?.status} -{" "}
                            <span className="fw-normal">
                              {convertValidDate(item?.updated_at)},
                              <small className="text-muted"> {convertValidTime(item?.updated_at)}</small>
                            </span>
                          </h6>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ))} */}
            </div>
          ) : (
            <span>{t("This order doesn't have any statuses!")}</span>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <ButtonTheme type="button" className="btn w-sm btn-light" onClick={toggle}>
          {t("Close")}
        </ButtonTheme>
      </ModalFooter>
    </Modal>
  );
};

OrderHistoryModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.any,
  title: PropTypes.string,
  histories: PropTypes.any,
  defaultStep: PropTypes.any,
};

export default OrderHistoryModal;
