import { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";

import { useFormik } from "formik";
import * as Yup from "yup";
import { Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";

import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import ModalContainer from "../../../../Components/Common/ModalContainer";
import UploadImage from "../../../../Components/Common/UploadImage";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";

import { addNewSaleChannel, updateSaleChannel } from "../../../../store/actions";

const AddEditSaleChannelModal = ({ saleChannel = null, isOpen, toggle, t }) => {
  const dispatch = useDispatch();

  const typeOptions = [
    { label: "Market place", value: "MARKETPLACE" },
    { label: "Ecommerce platform", value: "ECOMMERCE_PLATFORM" },
    { label: "Physical store", value: "PHYSICAL_STORE" },
    { label: "Social", value: "SOCIAL" },
  ];

  const { saleChannelsLoading, saleChannelMessage, saleChannelsSuccess, saleChannelsError } = useMemoizedSelector(
    (state) => ({
      saleChannelsLoading: state.SaleChannels.loading,
      saleChannelsSuccess: state.SaleChannels.success,
      saleChannelsError: state.SaleChannels.error,
      saleChannelMessage: state.SaleChannels.message,
    }),
  );

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (saleChannel && saleChannel.name) || "",
      type: (saleChannel && saleChannel.type) || "",
      image: (saleChannel && saleChannel.image) || null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Please Enter Name")),
      type: Yup.string().required(t("Please Select Type")),
    }),
    onSubmit: (values) => {
      if (saleChannel) {
        let payload = { ...saleChannel, ...values };
        dispatch(updateSaleChannel(payload));
      } else {
        let payload = { ...values };
        dispatch(addNewSaleChannel(payload));
      }
    },
  });

  const handleChangeImage = (data) => {
    validation.setFieldValue("image", data);
  };

  const handleToggle = () => {
    toggle();
    validation.resetForm();
  };

  useEffect(() => {
    if (
      !saleChannelsLoading &&
      (saleChannelMessage === "Add sale channel success" || saleChannelMessage === "Update sale channel success")
    ) {
      handleToggle();
    }
  }, [saleChannelsLoading, saleChannelMessage]);

  return (
    <Fragment>
      <ModalContainer
        isOpen={isOpen}
        toggle={handleToggle}
        title={saleChannel ? "Edit sale channel" : "Add new sale channel"}
        size="lg"
        actionCustom={
          <>
            <ButtonTheme
              type="button"
              className="btn btn-light w-sm"
              onClick={handleToggle}
              disabled={saleChannelsLoading}
            >
              {t("Close")}
            </ButtonTheme>
            <ButtonTheme
              type="button"
              className="btn btn-primary w-sm"
              loading={saleChannelsLoading}
              disabled={saleChannelsLoading}
              onClick={validation.handleSubmit}
            >
              {t(saleChannel ? "Update" : "Add")}
            </ButtonTheme>
          </>
        }
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label for="name-input" className="form-label">
                  {t("Name")}
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  name="name"
                  id="name-input"
                  className="form-control"
                  placeholder={t("Enter Name")}
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={validation.touched.name && validation.errors.name ? true : false}
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={12}>
              <div className="mb-3">
                <Label for="type-input" className="form-label">
                  {t("Type")}
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  name="type"
                  id="type-input"
                  className="form-select"
                  type="select"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.type || ""}
                  invalid={validation.touched.type && validation.errors.type ? true : false}
                >
                  <option value={""}>{t("Please select a type")}</option>
                  {typeOptions.map((item, key) => (
                    <option value={item.value} key={key}>
                      {t(item.label)}
                    </option>
                  ))}
                </Input>
                {validation.touched.type && validation.errors.type ? (
                  <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={12}>
              <Label className="form-label" for="project-thumbnail-img">
                {t("Image")}
              </Label>
              <UploadImage prefix="sale_channel" image={validation.values.image} onChangeImage={handleChangeImage} />
            </Col>
          </Row>
        </Form>
      </ModalContainer>
    </Fragment>
  );
};

export default AddEditSaleChannelModal;
