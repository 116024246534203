import React, { useEffect, useState } from "react";
import { Input, Label } from "reactstrap";
import { getDistrictsAll, getProvincesAll, getWards } from "../../helpers/service_helper";
import { deepCopy } from "../../helpers/format_helper";
import SelectTheme from "./SelectTheme";
import locationVN from "../constants/location";
import { useTranslation } from "react-i18next";

export default function AddressTheme({ address = {}, index, handleChangeAddresses = () => {} }) {
  ///api tinh https://vn-public-apis.fpo.vn/provinces/getAll?limit=-1
  //api huyen https://vn-public-apis.fpo.vn/districts/getAll?limit=-1
  // api xa https://vn-public-apis.fpo.vn/wards/getByDistrict?districtCode=712&limit=-1

  // chọn tỉnh search huyện https://vn-public-apis.fpo.vn/districts/getByProvince?provinceCode=72&limit=-1
  /// chọn xã search xã https://vn-public-apis.fpo.vn/wards/getByDistrict?districtCode=712&limit=-1
  const [optionProvinces, setOptionProvinces] = useState([]);
  const [valueProvinces, setValueProvinces] = useState({});

  const [optionDisTricts, setOptionDisTricts] = useState([]);
  const [valueDisTricts, setValueDisTricts] = useState({});

  const [optionWards, setOptionWards] = useState([]);
  const [valueWards, setValueWards] = useState({});
  const { t } = useTranslation();
  const [valueAddress, setValueAddress] = useState(address?.address1 || "");

  useEffect(() => {
    setValueAddress(address?.address1 || "");

    const findProvince = locationVN.find((item) => item.name === address?.province);
    if (findProvince) {
      setValueProvinces({ value: findProvince.id, label: findProvince.name, districts: findProvince.districts });
      setOptionDisTricts(
        findProvince.districts.map((item) => ({
          value: item.id,
          label: item.name,
          wards: item.wards,
        })),
      );
      const findDistrict = findProvince.districts.find((item) => item.name === address?.district);
      if (findDistrict) {
        setValueDisTricts({ value: findDistrict.id, label: findDistrict.name, wards: findDistrict.wards });
        setOptionWards(findDistrict.wards.map((item) => ({ value: item.id, label: item.name })));
        const findWard = findDistrict.wards.find((item) => item.name === address?.ward);
        if (findWard) {
          setValueWards({ value: findWard.id, label: findWard.name });
        }
      }
    }
    // setValueDisTricts( || "");

    // setValueWards(address?.ward || "");
  }, []);

  useEffect(() => {
    let newData = deepCopy(address);
    valueProvinces?.label && (newData.province = valueProvinces?.label);
    valueDisTricts?.label && (newData.district = valueDisTricts?.label);
    valueWards?.label && (newData.ward = valueWards?.label);
    newData.address1 = valueAddress;
    handleChangeAddresses(index, newData);
  }, [valueProvinces, valueDisTricts, valueWards, valueAddress]);

  return (
    <>
      <div className="hstack gap-2">
        <SelectTheme
          options={locationVN.map((item) => ({ id: item.id, name: item.name, districts: item.districts }))}
          label={"Provinces"}
          isForm={false}
          classWrapper="flex-1"
          value={valueProvinces}
          onChange={(option) => {
            setValueProvinces(option);
            const optionDistricts = option.districts.map((item) => ({
              value: item.id,
              label: item.name,
              wards: item.wards,
            }));
            setOptionDisTricts(optionDistricts);
            setValueDisTricts({});
            setValueWards({});
          }}
        />
        <SelectTheme
          isForm={false}
          classWrapper="flex-1"
          label="Districts"
          options={optionDisTricts}
          onChange={(option) => {
            setValueDisTricts(option);
            const optionWards = option.wards.map((item) => ({
              value: item.id,
              label: item.name,
            }));
            setOptionWards(optionWards);
            setValueWards({});
          }}
          value={valueDisTricts}
        />
        <SelectTheme
          isForm={false}
          classWrapper="flex-1"
          label="Wards"
          options={optionWards}
          onChange={(option) => {
            setValueWards(option);
          }}
          value={valueWards}
        />
      </div>
      <div>
        <Label>{t("Address")}</Label>
        <Input
          onChange={(e) => {
            setValueAddress(e.target.value);
          }}
          value={valueAddress}
        />
      </div>
    </>
  );
}
