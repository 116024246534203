import withRouter from "../../../../Components/Common/withRouter";
import { Form, Formik, useFormik } from "formik";
import { Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import BreadCrumbCustom from "../../../../Components/Common/BreadCrumbCustom";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { Actions } from "../../../../Components/constants/common";
import { Fragment, useEffect, useRef, useState } from "react";
import avatar1 from "../../../../assets/images/users/avatar-1.jpg";

import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import {
  addNewLoyaltyProgram,
  deleteLoyaltyProgram,
  getLoyaltyProgram,
  resetLoyaltyProgram,
  updateLoyaltyProgram,
} from "../../../../store/actions";
import { useDispatch } from "react-redux";
import ScrollToError from "../../../../Components/Common/ScrollToError";
import InputTheme from "../../../../Components/Common/InputTheme";
import UploadTheme from "../../../../Components/Common/UploadTheme";
import { formatDate, formatDateTime } from "../../../../helpers/format_helper";

const LoyaltyProgramDetails = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  const { id } = params;
  const dispatch = useDispatch();
  document.title = `${t(!id ? "New Loyalty Program" : "Loyalty Program Details")} | OptiWarehouse`;
  const {
    loyaltyProgram,
    loyaltyProgramsLoading,
    loyaltyProgramsAction,
    loyaltyProgramsSuccess,
    loyaltyProgramsError,
    loyaltyProgramsMessage,
  } = useMemoizedSelector((state) => ({
    loyaltyProgram: state.LoyaltyPrograms.loyaltyProgram,
    loyaltyProgramsLoading: state.LoyaltyPrograms.loading,
    loyaltyProgramsAction: state.LoyaltyPrograms.action,
    loyaltyProgramsSuccess: state.LoyaltyPrograms.success,
    loyaltyProgramsError: state.LoyaltyPrograms.error,
    loyaltyProgramsMessage: state.LoyaltyPrograms.message,
  }));
  const [showLimitAccrual, setShowLimitAccrual] = useState(false);
  const formRef = useRef();

  const now = new Date().toISOString();

  const validation = {
    enableReinitialize: true,
    initialValues: {
      name: loyaltyProgram?.name ?? "",
      money: loyaltyProgram?.money ?? null,
      start_date: loyaltyProgram?.start_date ?? now,
      expiry_date: loyaltyProgram?.expiry_date ?? null,
      image: loyaltyProgram?.image ?? null,
      diff_tier: loyaltyProgram?.diff_tier ?? false,
      diff_product: loyaltyProgram?.diff_product ?? false,
      customer_groups: loyaltyProgram?.customer_groups ?? null,
      products: loyaltyProgram?.products ?? null,
      accumulate_limit: loyaltyProgram?.accumulate_limit ?? false,
      duration_limit: loyaltyProgram?.duration_limit ?? null,
      number_limit: loyaltyProgram?.number_limit ?? null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Please Enter Loyalty Program Name")),
      money: Yup.number()
        .typeError("Must be a number")
        .positive("Number must be greater than 0")
        .required("This field is required"),
        start_date: Yup.string().required(t("Please Enter Start Date")),
    }),
    onSubmit: (values) => {
      console.log(id);
      if (!id) {
        dispatch(
          addNewLoyaltyProgram(values, (data) => {
            navigate(`/loyalty-programs/${data.id}`);
          }),
        );
      } else {
        dispatch(
          updateLoyaltyProgram({ ...loyaltyProgram, ...values }, (data) => {
            navigate(`/loyalty-programs/${data.id}`);
          }),
        );
      }
    },
  };

  useEffect(() => {
    if (id) {
      dispatch(getLoyaltyProgram(id));
    } else {
      dispatch(resetLoyaltyProgram());
    }
  }, [id]);

  useEffect(() => {});
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumbCustom text="Back to loyalty program list" navigateTo="/loyalty-programs">
          {!id ? (
            <ButtonTheme
              type="button"
              className="btn btn-outline-primary w-md"
              onClick={() => formRef.current.handleSubmit()}
              loading={loyaltyProgramsLoading && loyaltyProgramsAction === Actions.CREATE}
              disabled={loyaltyProgramsLoading && loyaltyProgramsAction === Actions.CREATE}
            >
              {t("Create")}
            </ButtonTheme>
          ) : (
            <div className="hstack gap-2">
              <ButtonTheme
                type="button"
                className="btn btn-outline-primary w-md"
                onClick={() => formRef.current.handleSubmit()}
                loading={loyaltyProgramsLoading && loyaltyProgramsAction === Actions.UPDATE}
                disabled={loyaltyProgramsLoading || loyaltyProgramsAction === Actions.UPDATE}
              >
                {t("Update")}
              </ButtonTheme>
              <ButtonTheme
                type="button"
                className="btn btn-outline-danger w-md"
                onClick={() => {
                  dispatch(
                    deleteLoyaltyProgram(loyaltyProgram, () => {
                      navigate("/loyalty-programs");
                    }),
                  );
                }}
                disabled={loyaltyProgramsLoading}
              >
                {t("Delete")}
              </ButtonTheme>
            </div>
          )}
        </BreadCrumbCustom>
        <Formik
          enableReinitialize={true}
          initialValues={validation.initialValues}
          validationSchema={validation.validationSchema}
          onSubmit={validation.onSubmit}
          innerRef={formRef}
        >
          {({ values, setFieldValue, errors, touched }) => {
            return (
              <Form>
                <ScrollToError />
                <Row>
                  <Col className={"col-9"}>
                    <Card>
                      <CardHeader>
                        <h5 className="card-title flex-grow-1 mb-0">{t("General")}</h5>
                      </CardHeader>
                      <CardBody>
                        <InputTheme
                          name={"name"}
                          placeholder="Enter loyalty program name"
                          label={
                            <>
                              {t("Name")}
                              <span className="text-danger">*</span>
                            </>
                          }
                        />
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader>
                        <h5 className="card-title flex-grow-1 mb-0">{t("Default loyalty points ratio")}</h5>
                      </CardHeader>
                      <CardBody>
                        <p>
                          {t(
                            "Apply to all products in the store, except for those with special point accrual rates set below.",
                          )}
                        </p>
                        <div className="d-flex justify-content-between gap-2 mb-3">
                          <div className="flex-1">
                            <InputTheme
                              name={"money"}
                              format="price"
                              placeholder="Enter exchange amount"
                              label={
                                <div className="hstack justify-content-between">
                                  {t("Exchange amount")}
                                  <span className="text-danger">*</span>
                                </div>
                              }
                            />
                          </div>
                          <div className="align-self-center pt-2">=</div>
                          <div className="flex-1">
                            <InputTheme isForm={false} value={`1 ${t("point")}`} disabled={true} label={"Point"} />
                          </div>
                        </div>
                        {/* <div>
                          <h5 className="card-title flex-grow-1 mb-0">{t("Advanced settings")}</h5>
                          <p>{t("You can only configure one of the two settings below.")}</p>
                          <div className="form-check form-switch">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="SwitchCheck1"
                              checked
                            />
                            <Label className="form-check-label" for="SwitchCheck1">
                              {t("Different point accruals for various membership tiers.")}
                            </Label>
                          </div>
                          <div className="form-check form-switch">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="SwitchCheck2"
                              checked
                            />
                            <Label className="form-check-label" for="SwitchCheck2">
                              {t("Different point accruals for various products.")}
                            </Label>
                          </div>
                        </div> */}
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader>
                        <h5 className="card-title flex-grow-1 mb-0">{t("Effective time")}</h5>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col lg={6} className="mb-3">
                            <InputTheme
                              name={"start_date"}
                              type="timeDate"
                              label={
                                <>
                                  {t("Effective date")}
                                  <span className="text-danger">*</span>
                                </>
                              }
                            />
                          </Col>
                          <Col lg={6} className="mb-3">
                            <InputTheme name={"expiry_date"} type="timeDate" label="Expiry date" />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    {/* <Card>
                      <CardHeader>
                        <h5 className="card-title flex-grow-1 mb-0">{t("Limit the number of point accruals")}</h5>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col lg={12} className="mb-3">
                            <div className="form-check form-check-primary mb-2">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="point-accruals"
                                checked={showLimitAccrual}
                                onChange={(e) => {
                                  setShowLimitAccrual(e.target.checked);
                                }}
                              />
                              <Label className="form-check-label" for="point-accruals">
                                {t("Limit the number of times a member can accrue points.")}
                              </Label>
                            </div>
                          </Col>
                          {showLimitAccrual && (
                            <Fragment>
                              <Col lg={6} className="mb-3">
                                <Label for="accruals" className="form-label">
                                  {t("Accruals")}
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="accruals"
                                  placeholder={t("Enter accruals")}
                                />
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Label for="effective-time" className="form-label">
                                  {t("Effective time")}
                                </Label>
                                <Input type="select" className="form-control" id="effective-time" />
                              </Col>
                            </Fragment>
                          )}
                        </Row>
                      </CardBody>
                    </Card> */}
                  </Col>
                  <Col className={"col-3"}>
                    <Card>
                      <CardBody>
                        <div className="text-center hstack justify-content-center">
                          <UploadTheme name="image" />
                        </div>
                        {values.name && <h5 className="mb-0 text-center">{values.name}</h5>}
                      </CardBody>
                      <CardBody className="border-top">
                        <div className="d-flex gap-2">
                          <i className="ri-calendar-check-line text-primary"></i>
                          {values.start_date ? (
                            <p className="mb-0">
                              <span>
                                {t("Effective time from")} {formatDateTime(values.start_date)}
                              </span>{" "}
                              {values.expiry_date && <span>{t("to")} {formatDateTime(values.expiry_date)}</span>}
                            </p>
                          ) : (
                            <span>{t("Required effective date")}</span>
                          )}
                        </div>
                      </CardBody>
                      {showLimitAccrual && (
                        <CardBody className="border-top">
                          <div className="d-flex gap-2">
                            <i className="ri-checkbox-circle-fill text-primary"></i>
                            <p className="mb-0">{t("Customers can receive points a maximum of 0 times per day.")}</p>
                          </div>
                        </CardBody>
                      )}
                    </Card>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </div>
  );
};

export default withRouter(LoyaltyProgramDetails);
