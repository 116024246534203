import { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";

import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import ModalContainer from "../../../../Components/Common/ModalContainer";
import UploadImage from "../../../../Components/Common/UploadImage";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { Actions } from "../../../../Components/constants/common";

import { addNewShippingProvider, updateShippingProvider } from "../../../../store/actions";

const AddEditShippingProviderModal = ({ shippingProvider = null, isOpen, toggle }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    shippingProvidersLoading,
    shippingProviderMessage,
    shippingProvidersAction,
    shippingProvidersSuccess,
    shippingProvidersError,
  } = useMemoizedSelector((state) => ({
    shippingProvidersLoading: state.ShippingProviders.loading,
    shippingProvidersAction: state.ShippingProviders.action,
    shippingProvidersSuccess: state.ShippingProviders.success,
    shippingProvidersError: state.ShippingProviders.error,
    shippingProviderMessage: state.ShippingProviders.message,
  }));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (shippingProvider && shippingProvider.name) || "",
      image: (shippingProvider && shippingProvider.image) || null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Please Enter Name")),
    }),
    onSubmit: (values) => {
      if (shippingProvider) {
        let payload = { ...shippingProvider, ...values };
        dispatch(updateShippingProvider(payload));
      } else {
        let payload = { ...values };
        dispatch(addNewShippingProvider(payload));
      }
    },
  });

  const handleChangeImage = (data) => {
    validation.setFieldValue("image", data);
  };

  const handleToggle = () => {
    if (!shippingProvidersLoading) {
      toggle();
      validation.resetForm();
    }
  };

  useEffect(() => {
    if (
      !shippingProvidersLoading &&
      (shippingProviderMessage === "Add shipping provider success" ||
        shippingProviderMessage === "Update shipping provider success")
    ) {
      handleToggle();
    }
  }, [shippingProvidersLoading, shippingProviderMessage]);

  return (
    <Fragment>
      <ModalContainer
        isOpen={isOpen}
        toggle={handleToggle}
        title={shippingProvider ? "Edit shipping provider" : "Add new shipping provider"}
        size="lg"
        actionCustom={
          <>
            <ButtonTheme type="button" className="btn btn-light w-sm" onClick={handleToggle}>
              {t("Close")}
            </ButtonTheme>
            <ButtonTheme
              type="button"
              className="btn btn-primary w-sm"
              loading={
                shippingProvidersLoading &&
                (shippingProvidersAction === Actions.UPDATE || shippingProvidersAction === Actions.CREATE)
              }
              disabled={
                shippingProvidersLoading &&
                (shippingProvidersAction === Actions.UPDATE || shippingProvidersAction === Actions.CREATE)
              }
              onClick={validation.handleSubmit}
            >
              {t(shippingProvider ? "Update" : "Add")}
            </ButtonTheme>
          </>
        }
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label for="name-input" className="form-label">
                  {t("Name")}
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  name="name"
                  id="name-input"
                  className="form-control"
                  placeholder={t("Enter Name")}
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={validation.touched.name && validation.errors.name ? true : false}
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={12}>
              <Label className="form-label" for="project-thumbnail-img">
                {t("Image")}
              </Label>
              <UploadImage prefix="logistic" image={validation.values.image} onChangeImage={handleChangeImage} />
            </Col>
          </Row>
        </Form>
      </ModalContainer>
    </Fragment>
  );
};

export default AddEditShippingProviderModal;
