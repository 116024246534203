import {
  LIST_RETURN_ORDER,
  LIST_RETURN_ORDER_FAIL,
  LIST_RETURN_ORDER_SUCCESS,
  ADD_NEW_RETURN_ORDER,
  ADD_RETURN_ORDER_SUCCESS,
  ADD_RETURN_ORDER_FAIL,
  GET_RETURN_ORDER,
  GET_RETURN_ORDER_FAIL,
  GET_RETURN_ORDER_SUCCESS,
} from "./actionType";

export const listReturnOrder = (options) => ({
  type: LIST_RETURN_ORDER,
  payload: options,
});

export const listReturnOrderFail = (error) => ({
  type: LIST_RETURN_ORDER_FAIL,
  payload: error,
});

export const listReturnOrderSuccess = (returnOrders) => ({
  type: LIST_RETURN_ORDER_SUCCESS,
  payload: returnOrders,
});

export const getReturnOrder = (returnOrderID) => ({
  type: GET_RETURN_ORDER,
  payload: returnOrderID,
});

export const getReturnOrderFail = (error) => ({
  type: GET_RETURN_ORDER_FAIL,
  payload: error,
});

export const getReturnOrderSuccess = (returnOrder) => ({
  type: GET_RETURN_ORDER_SUCCESS,
  payload: returnOrder,
});

export const addNewReturnOrder = (returnOrder, handleAfterSubmit = () => {}) => ({
  type: ADD_NEW_RETURN_ORDER,
  payload: { returnOrder, handleAfterSubmit },
});

export const addReturnOrderSuccess = (returnOrder) => ({
  type: ADD_RETURN_ORDER_SUCCESS,
  payload: returnOrder,
});

export const addReturnOrderFail = (error) => ({
  type: ADD_RETURN_ORDER_FAIL,
  payload: error,
});
