// Actions
export const LIST_PURCHASE_ORDER = "LIST_PURCHASE_ORDER";
export const LIST_PURCHASE_ORDER_SUCCESS = "LIST_PURCHASE_ORDER_SUCCESS";
export const LIST_PURCHASE_ORDER_FAIL = "LIST_PURCHASE_ORDER_FAIL";

export const GET_PURCHASE_ORDER = "GET_PURCHASE_ORDER";
export const GET_PURCHASE_ORDER_SUCCESS = "GET_PURCHASE_ORDER_SUCCESS";
export const GET_PURCHASE_ORDER_FAIL = "GET_PURCHASE_ORDER_FAIL";

/**
 * Delete PURCHASE_ORDER
 */
export const DELETE_PURCHASE_ORDER = "DELETE_PURCHASE_ORDER";
export const DELETE_PURCHASE_ORDER_SUCCESS = "DELETE_PURCHASE_ORDER_SUCCESS";
export const DELETE_PURCHASE_ORDER_FAIL = "DELETE_PURCHASE_ORDER_FAIL";

/**
 * Add PURCHASE_ORDER
 */
export const ADD_NEW_PURCHASE_ORDER = "ADD_NEW_PURCHASE_ORDER";
export const ADD_PURCHASE_ORDER_SUCCESS = "ADD_PURCHASE_ORDER_SUCCESS";
export const ADD_PURCHASE_ORDER_FAIL = "ADD_PURCHASE_ORDER_FAIL";

/**
 * Edit PURCHASE_ORDER
 */
export const UPDATE_PURCHASE_ORDER = "UPDATE_PURCHASE_ORDER";
export const UPDATE_PURCHASE_ORDER_SUCCESS = "UPDATE_PURCHASE_ORDER_SUCCESS";
export const UPDATE_PURCHASE_ORDER_FAIL = "UPDATE_PURCHASE_ORDER_FAIL";

export const RESET_PURCHASE_ORDER_FLAG = "RESET_PURCHASE_ORDER_FLAG";
