import { CUSTOMER, CUSTOMER_GROUP, REDEEM, REWARD } from "./customer";
import { DASHBOARD } from "./dashboard";
import { PAYMENT_METHOD, TRANSACTION } from "./finance";
import { IMPORT, RECORD } from "./import";
import { INVENTORY, LOCATION, STOCK_ADJUSTMENT, STOCK_RELOCATE } from "./invertory";
import { SHIPPING_PROVIDER } from "./logitic";
import { ORDER, PACKAGE, RETURN_ORDER } from "./order";
import { SHIFT, TERMINAL } from "./pos";
import { BRAND, CATEGORY, PRODUCT, VARIANT } from "./product";
import { DISCOUNT, VOUCHER, VOUCHER_CODE } from "./promotion";
import { PURCHASE_ORDER, SUPPLIER } from "./purchaseOrder";
import { REPORT } from "./report";
import { STORE } from "./saleChannel";
import { SETTINGS } from "./settings";
import { BLOG, BLOG_CATEGORY } from "./website";

export const PERMISSION = {
  // dashboard: DASHBOARD,
  order: ORDER,
  return_order: RETURN_ORDER,
  package: PACKAGE,
  variant: VARIANT,
  product: PRODUCT,
  brand: BRAND,
  category: CATEGORY,
  settings: SETTINGS,
  voucher_code: VOUCHER_CODE,
  voucher: VOUCHER,
  discount: DISCOUNT,
  store: STORE,
  shipping_provider: SHIPPING_PROVIDER,
  location: LOCATION,
  inventory: INVENTORY,
  stock_adjustment: STOCK_ADJUSTMENT,
  stock_relocate: STOCK_RELOCATE,
  purchase_orders: PURCHASE_ORDER,
  supplier: SUPPLIER,
  customer_group: CUSTOMER_GROUP,
  customer: CUSTOMER,
  import: IMPORT,
  record: RECORD,
  payment_methods: PAYMENT_METHOD,
  transaction: TRANSACTION,
  redeem: REDEEM,
  reward: REWARD,
  blog: BLOG,
  blog_category: BLOG_CATEGORY,
  terminal: TERMINAL,
  shift: SHIFT,
  report: REPORT,
};

export const PlanDetails = {
  POS: [
    "dashboards",
    "product",
    "order",
    "logistics",
    "purchase_orders",
    "customer",
    "finance",
    "inventory",
    "promotion",
    "import",
    "report",
    "pos",
    "setting",
  ],
  ECOMMERCE: [
    "dashboards",
    "product",
    "order",
    "sale-channel",
    "logistics",
    "purchase_orders",
    "customer",
    "finance",
    "inventory",
    "promotion",
    "import",
    "report",
    "website",
    "pos",
    "setting",
  ],
  OMNICHANNEL: [
    "dashboards",
    "product",
    "order",
    "sale-channel",
    "logistics",
    "purchase_orders",
    "customer",
    "finance",
    "inventory",
    "promotion",
    "import",
    "report",
    "website",
    "pos",
    "setting",
  ],
};

export const SubPlanDetails = {
  POS: [
    "order",
    "stock_adjustment",
    "stock_relocate",
    "product",
    "variant",
    "brand",
    "category",
    "location",
    "discount",
    "voucher",
    "voucher_code",
    "settings",
    "purchase_orders",
    "supplier",
    "customer",
    "customer_group",
    "import",
    "record",
    "payment_methods",
    "transaction",
    "account",
    "report",
    "inventory_item",
    "pos",
    "terminal",
    "shift",
    "reward",
    "redeem",
  ],
  ECOMMERCE: [
    "order",
    "return_order",
    "stock_adjustment",
    "stock_relocate",
    "package",
    "product",
    "variant",
    "brand",
    "category",
    "store",
    "shipping_provider",
    "location",
    "discount",
    "voucher",
    "voucher_code",
    "settings",
    "purchase_orders",
    "supplier",
    "customer",
    "customer_group",
    "import",
    "record",
    "payment_methods",
    "transaction",
    "account",
    "report",
    "inventory_item",
    "blog",
    "blog_category",
    "pos",
  ],
  OMNICHANNEL: [
    "order",
    "return_order",
    "stock_adjustment",
    "stock_relocate",
    "package",
    "product",
    "variant",
    "brand",
    "category",
    "store",
    "shipping_provider",
    "location",
    "discount",
    "voucher",
    "voucher_code",
    "settings",
    "purchase_orders",
    "supplier",
    "customer",
    "customer_group",
    "import",
    "record",
    "payment_methods",
    "transaction",
    "account",
    "report",
    "inventory_item",
    "blog",
    "blog_category",
    "terminal",
    "pos",
    "shift",
    "reward",
    "redeem",
  ],
};
