import React, { useEffect } from "react";
import withRouter from "../Components/Common/withRouter";

//redux
import useMemoizedSelector from "../Components/Hooks/useMemoizedSelector";
import { useProfile } from "../Components/Hooks/useProfile";
import { useNavigate } from "react-router";
import { getLoggedInUserSettings, handleSetColorCss, updateFavicon } from "../helpers/format_helper";

const NonAuthLayout = ({ children }) => {
  const { userProfile, loading, token, dataColor, dataShop } = useProfile();
  const navigate = useNavigate();

  const { layoutModeType } = useMemoizedSelector((state) => ({
    layoutModeType: state.Layout.layoutModeType,
  }));

  useEffect(() => {
    if (userProfile && token) {
      if (window.location.href.includes("/pos/pair-device") || window.location.href.includes("/qr")) {
        return;
      }
      navigate("/dashboard");
    }
  }, [userProfile, token]);

  useEffect(() => {
    updateFavicon(dataShop?.setting_value?.store_icon?.url);
  }, [dataShop]);

  useEffect(() => {
    if (dataColor) {
      const dataDark = dataColor?.["dark"];
      const dataLight = dataColor?.["light"];
      if (dataLight && dataDark) {
        const listKeyDark = Object.keys(dataDark);
        const listKeyLight = Object.keys(dataLight);
        handleSetColorCss("dark", listKeyDark, dataDark);
        handleSetColorCss("light", listKeyLight, dataLight);
      }
    }
  }, [dataColor]);

  // useEffect(() => {
  //   if (layoutModeType === "dark") {
  //     document.body.setAttribute("data-layout-mode", "dark");
  //   } else {
  //     document.body.setAttribute("data-layout-mode", "light");
  //   }
  //   return () => {
  //     document.body.removeAttribute("data-layout-mode");
  //   };
  // }, [layoutModeType]);
  return <div>{children}</div>;
};

export default withRouter(NonAuthLayout);
