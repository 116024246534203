import { useNavigate } from "react-router-dom";
import ButtonTheme from "./ButtonTheme";

const BackButton = ({ url }) => {
  const navigate = useNavigate();
  return (
    <div className="ms-1 header-item d-sm-flex">
      <ButtonTheme
        type="button"
        className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode"
        onClick={() => {
          navigate(url ? url : -1);
        }}
      >
        <i className="ri-arrow-left-line fs-22"></i>
      </ButtonTheme>
    </div>
  );
};
export default BackButton;
