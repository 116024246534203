import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";


// Redux States
import { GET_IMPORT_RECORD, LIST_RECORD } from "./actionType";

import { listRecordSuccess, listRecordFail, getImportRecordSuccess, getImportRecordFail } from "./action";

import { getImportRecordAPI, listRecords } from "../../../../helpers/service_helper";

function* listRecord({ payload: { ...res } }) {
  try {
    const response = yield call(listRecords, { ...res });
    yield put(listRecordSuccess(response));
  } catch (error) {
    yield put(listRecordFail(error));
  }
}

function* getImportRecord({ payload: id }) {
  try {
    const response = yield call(getImportRecordAPI, { id });
    yield put(getImportRecordSuccess(response));
  } catch (error) {
    yield put(getImportRecordFail(error));
  }
}

export function* watchGetImportRecord() {
  yield takeEvery(GET_IMPORT_RECORD, getImportRecord);
}

export function* watchGetRecords() {
  yield takeEvery(LIST_RECORD, listRecord);
}

function* recordsSaga() {
  yield all([fork(watchGetRecords), fork(watchGetImportRecord)]);
}

export default recordsSaga;
