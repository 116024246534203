import { Actions } from "../../../../Components/constants/common";
import {
  LIST_BRAND,
  DELETE_BRAND,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_FAIL,
  ADD_BRAND_SUCCESS,
  ADD_BRAND_FAIL,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_FAIL,
  LIST_BRAND_SUCCESS,
  LIST_BRAND_FAIL,
  ADD_NEW_BRAND,
  UPDATE_BRAND,
  GET_BRAND,
  GET_BRAND_SUCCESS,
  GET_BRAND_FAIL,
} from "./actionType";

const INIT_STATE = {
  brands: [],
  brand: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const Brands = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_BRAND:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_BRAND_SUCCESS:
      return {
        ...state,
        message: "Get brands success",
        loading: false,
        success: true,
        brands: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_BRAND_FAIL:
      return {
        ...state,
        message: "Get brands failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_BRAND:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_BRAND_SUCCESS:
      return {
        ...state,
        message: "Get brand success",
        loading: false,
        success: true,
        brand: action.payload,
        action: Actions.EMPTY,
      };

    case GET_BRAND_FAIL:
      return {
        ...state,
        message: "Get brand failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_BRAND:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_BRAND_SUCCESS:
      return {
        ...state,
        message: "Delete brand success",
        loading: false,
        success: true,
        brands: state.brands.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_BRAND_FAIL:
      return {
        ...state,
        message: "Delete brand failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_NEW_BRAND:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_BRAND_SUCCESS:
      return {
        ...state,
        message: "Add brand success",
        success: true,
        loading: false,
        brands: [ action.payload,...state.brands],
        action: Actions.EMPTY,
      };

    case ADD_BRAND_FAIL:
      return {
        ...state,
        message: "Add brand failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_BRAND:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.UPDATE,
      };
    case UPDATE_BRAND_SUCCESS:
      return {
        ...state,
        message: "Update brand success",
        loading: false,
        success: true,
        brands: state.brands.map((brand) =>
          brand.id.toString() === action.payload.id.toString() ? { ...brand, ...action.payload } : brand,
        ),
        action: Actions.EMPTY,
      };

    case UPDATE_BRAND_FAIL:
      return {
        ...state,
        message: "Update brand failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    default:
      return { ...state };
  }
};

export default Brands;
