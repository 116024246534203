import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { isEmpty } from "lodash";

import { convertValidDate, convertValidTime } from "../../../../helpers/format_helper";

import EditAddLocationModal from "./EditAddLocationModal";
import withRouter from "../../../../Components/Common/withRouter";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { Actions } from "../../../../Components/constants/common";

import { deleteLocation, listLocation } from "../../../../store/actions";
import ActionDropdown from "../../../../Components/Common/ActionDropdown";

const BrandsPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Location List")} | OptiWarehouse`;
  const dispatch = useDispatch();

  const {
    locations,
    locationsLoading,
    locationsAction,
    locationsSuccess,
    locationsMessage,
    locationsError,
    locationsLimit,
    locationsTotal,
    locationsPage,
  } = useMemoizedSelector((state) => ({
    locations: state.Locations.locations,
    locationsLoading: state.Locations.loading,
    locationsAction: state.Locations.action,
    locationsMessage: state.Locations.message,
    locationsSuccess: state.Locations.success,
    locationsError: state.Locations.error,
    locationsLimit: state.Locations.limit,
    locationsTotal: state.Locations.total,
    locationsPage: state.Locations.page,
  }));

  const [isExportCSV, setIsExportCSV] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditAddModal, setShowEditAddModal] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleAdd = () => {
    setShowEditAddModal(true);
  };

  const handleEdit = (data) => {
    setShowEditAddModal(true);
    setSelectedLocation(data);
  };

  const handleCloseEditAddModal = () => {
    if (!locationsLoading) {
      setShowEditAddModal(false);
      setSelectedLocation(null);
    }
  };

  const handleDelete = () => {
    dispatch(deleteLocation(selectedLocation));
  };

  const handleCloseDeleteModal = () => {
    if (!locationsLoading) {
      setShowDeleteModal(false);
      setSelectedLocation(null);
    }
  };

  const headers = [
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: t("Phone"),
      dataIndex: "phone",
      key: "phone",
      sorter: true,
    },
    {
      title: t("Updated at"),
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: true,
    },
    {
      title: t("Created at"),
      dataIndex: "created_at",
      key: "created_at",
      defaultSortOrder: "desc",
      sorter: true,
    },
    {
      title: t("Action"),
      key: "amount",
      render: (_, row, index) => {
        return (
          <ActionDropdown
            index={index}
            actions={[
              {
                type: "edit",
                onClick: () => {
                  handleEdit(row);
                },
              },
              {
                type: "delete",
                onClick: () => {
                  setSelectedLocation(row);
                  setShowDeleteModal(true);
                },
              },
            ]}
          />
        );
      },
    },
  ];

  // toggle after loading
  useEffect(() => {
    if (
      !locationsLoading &&
      !isEmpty(selectedLocation) &&
      showDeleteModal &&
      locationsMessage === "Delete location success"
    ) {
      setShowDeleteModal(false);
      setSelectedLocation(null);
    }
  }, [locationsLoading, locationsSuccess]);

  return (
    <div className="page-content">
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={locations} />
      <DeleteModal
        show={showDeleteModal}
        onCloseClick={handleCloseDeleteModal}
        onDeleteClick={handleDelete}
        loading={locationsLoading && locationsAction === Actions.DELETE}
      />
      <EditAddLocationModal show={showEditAddModal} toggle={handleCloseEditAddModal} location={selectedLocation} />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="location-list">
              <CardHeader className="border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("Location List")}</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-3 flex-wrap">
                      <ButtonTheme
                        type="button"
                        className="btn btn-light"
                        icon="ri-file-text-line"
                        onClick={() => setIsExportCSV(true)}
                      >
                        {t("Export")}
                      </ButtonTheme>
                      <ButtonTheme
                        className="btn btn-primary"
                        id="create-btn"
                        icon="ri-add-line text-white"
                        onClick={handleAdd}
                      >
                        {t("Add Location")}
                      </ButtonTheme>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <TableContainerCustom
                  filterType={"locations"}
                  searchPlaceHolder="Search locations..."
                  listFilter={[]}
                  headers={headers}
                  contents={locations}
                  limitPage={Number(locationsLimit)}
                  currentPage={Number(locationsPage) + 1}
                  totalItems={locationsTotal}
                  loading={locationsLoading && locationsAction === Actions.LIST}
                  showPagination={true}
                  scrollHeight="50vh"
                  onCallData={(dataPayload) => {
                    dispatch(listLocation(dataPayload));
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default withRouter(BrandsPage);
