import React, { useState } from "react";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import { Link } from "react-router-dom";
import BadgeCustom from "../../../../Components/Common/BadgeCustom";
import withRouter from "../../../../Components/Common/withRouter";
import { Tooltip } from "antd";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { listReturnOrder } from "../../../../store/actions";
import { useDispatch } from "react-redux";
import ReturnOrderModal from "../Components/ReturnOrderModal";
import FullNameRender from "../../../../Components/Common/FullnameRender";
import {
  PaymentStatusOptions,
  ReturnOrderOptions,
  ReturnOrderReasonOptions,
} from "../../../../Components/constants/common";

const ReturnOrders = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Return Order List")} | OptiWarehouse`;
  const dispatch = useDispatch();
  const { returnOrders, returnOrdersLoading, returnOrdersLimit, returnOrdersPage, returnOrdersTotal } =
    useMemoizedSelector((state) => ({
      returnOrders: state.ReturnOrders.returnOrders,
      returnOrdersLoading: state.ReturnOrders.loading,
      returnOrdersSuccess: state.ReturnOrders.success,
      returnOrdersError: state.ReturnOrders.error,
      returnOrdersLimit: state.ReturnOrders.limit,
      returnOrdersPage: state.ReturnOrders.page,
      returnOrdersTotal: state.ReturnOrders.total,
    }));
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(false);
  };

  const headers = [
    "return_order_number",
    "order_number",
    "exchanged_order_number",
    "customer",
    "status",
    "payment_status",
    "returned_at",
  ];

  return (
    <div className="page-content">
      <ReturnOrderModal isOpen={isOpen} toggle={toggle} />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="return-order-list">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("Return Order List")}</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-3 flex-wrap">
                      <ButtonTheme
                        className="btn btn-primary"
                        id="create-btn"
                        icon="ri-add-line text-white"
                        onClick={() => {
                          setIsOpen(true);
                        }}
                      >
                        {t("Add Return Order")}
                      </ButtonTheme>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <TableContainerCustom
                  filterType={"return_orders"}
                  searchPlaceHolder="Search return orders..."
                  onCallData={(dataPayload) => {
                    dispatch(listReturnOrder(dataPayload));
                  }}
                  listFilter={[
                    {
                      id: "status",
                      type: "selectBox",
                      title: `${t("Status")}`,
                      dataOption: ReturnOrderOptions,
                    },
                    {
                      id: "payment_status",
                      type: "selectBox",
                      title: `${t("Payment status")}`,
                      dataOption: PaymentStatusOptions,
                    },
                    {
                      id: "reason",
                      type: "selectBox",
                      title: `${t("Reason")}`,
                      dataOption: ReturnOrderReasonOptions,
                    },
                    {
                      id: "location.id",
                      type: "selectBox",
                      title: "Branch",
                      remote: true,
                      pathUrlLoad: "inventory/locations",
                    },
                    {
                      id: "returned_at",
                      type: "date",
                      title: "Returned at",
                    },
                    {
                      id: "cancelled_at",
                      type: "date",
                      title: "Cancelled at",
                    },
                  ]}
                  headers={headers}
                  contents={returnOrders}
                  limitPage={Number(returnOrdersLimit)}
                  currentPage={Number(returnOrdersPage) + 1}
                  totalItems={Number(returnOrdersTotal)}
                  loading={returnOrdersLoading}
                  scrollHeight="50vh"
                  isSettings={true}
                  listHeader={[
                    {
                      title: t("Return Order details"),
                      key: "detail-return-order",
                      children: [
                        {
                          title: t("Number"),
                          dataIndex: "return_order_number",
                          key: "return_order_number",
                          render: (data, row) => (
                            <Tooltip>
                              <Link
                                to={`/return-orders/${row.id}`}
                                className="d-block text-truncate h5 mb-0 fs-14 link-primary"
                              >
                                {data}
                              </Link>
                            </Tooltip>
                          ),
                        },
                        {
                          title: t("Order Number"),
                          dataIndex: "order_number",
                          key: "order_number",
                          render: (data, row) => (
                            <Link
                              to={`/orders/${row?.order_id}`}
                              className="d-block text-truncate h5 mb-0 fs-14 link-primary"
                            >
                              {data || "---"}
                            </Link>
                          ),
                        },
                        {
                          title: t("Exchanged Order Number"),
                          key: "exchanged_order_number",
                          dataIndex: "exchanged_order_number",
                          render: (data, row) =>
                            data ? (
                              <Link
                                to={`/orders/${row?.exchanged_order_id}`}
                                className="d-block text-truncate h5 mb-0 fs-14 link-primary"
                              >
                                {data || "---"}
                              </Link>
                            ) : (
                              "---"
                            ),
                        },
                        {
                          title: t("Customer"),
                          dataIndex: "customer",
                          key: "customer",
                          render: (value, row, index) => {
                            return (
                              <div className="text-truncate d-block">
                                <FullNameRender first_name={value?.first_name} last_name={value?.last_name} />
                              </div>
                            );
                          },
                        },
                        {
                          title: t("Status"),
                          dataIndex: "status",
                          key: "status",
                          render: (value, row, index) => <>{value ? <BadgeCustom status={value} /> : "---"}</>,
                        },
                        {
                          title: t("Payment status"),
                          dataIndex: "payment_status",
                          key: "payment_status",
                          render: (value, row, index) => <>{value ? <BadgeCustom status={value} /> : "---"}</>,
                        },
                        {
                          title: t("Received at"),
                          dataIndex: "returned_at",
                          sorter: true,
                          key: "returned_at",
                        },
                      ],
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(ReturnOrders);
