import { useEffect, useState } from "react";
import { getChartColorsArray } from "../../../../helpers/format_helper";
import ReactApexChart from "react-apexcharts";
import NotFound from "../../../../Components/Common/NotFound";

const SellThroughChart = ({ dataColors, metricsByMonth, initStock, t }) => {
  const chartColumnStacked100Colors = getChartColorsArray(dataColors);
  const initSeries = [
    {
      name: "Sold units",
      type: "column",
      data: [],
    },
    {
      name: t("Revenue retails cost rate"),
      type: "line",
      data: [],
    },
  ];
  const [series, setSeries] = useState(initSeries);

  const [options, setOptions] = useState({
    chart: {
      toolbar: {
        show: !1,
      },
    },
    stroke: {
      width: [0, 4],
    },

    xaxis: {
      categories: [],
    },
    yaxis: [
      {
        title: {
          text: t("Sold units"),
          style: {
            fontWeight: 600,
          },
        },
        min: 0,
        max: initStock,
      },
      {
        opposite: true,
        title: {
          text: t("Revenue retails cost rate"),
          style: {
            fontWeight: 600,
          },
        },
        labels: {
          formatter: function (val) {
            return val + "%";
          },
        },
      },
    ],
    dataLabels: {
      enabled: false,
      // formatter: function (val) {
      //   return ((val / initStock) * 100).toFixed(0) + "%";
      // },
    },
    tooltip: {
      y: [
        {
          formatter: function (val) {
            return `${val} (${((val / initStock) * 100).toFixed(0)}%)`;
          },
        },
        {
          formatter: function (val) {
            return val + "%";
          },
        },
      ],
    },
    // fill: {
    //   opacity: 1,
    // },
    legend: {
      position: "top",
      offsetX: 0,
      offsetY: 0,
    },
    colors: chartColumnStacked100Colors,
  });

  useEffect(() => {
    if (Object.keys(metricsByMonth)) {
      const categoryArray = Object.keys(metricsByMonth);
      const serries1 = Object.values(metricsByMonth).map((item) => (item?.sold_units || 0).toFixed(0));
      const serries2 = Object.values(metricsByMonth).map((item) =>
        ((item?.revenue_retails_cost_rate || 0) * 100).toFixed(0),
      );
      const newSeries = [];
      if (serries1.length > 0 && serries2.length > 0) {
        newSeries.push(
          {
            name: t("Sold units"),
            type: "column",
            data: serries1,
          },
          {
            name: t("Revenue retails cost rate"),
            type: "line",
            data: serries2,
          },
        );
      }
      setSeries(newSeries);
      setOptions((pre) => ({ ...pre, xaxis: { categories: categoryArray } }));
    }
  }, [metricsByMonth]);

  if (series.length === 0) {
    return <NotFound />;
  }

  return (
    <ReactApexChart dir="ltr" className="apex-charts" series={series} options={options} type="line" height={350} />
  );
};

export default SellThroughChart;
