import Dropzone, { useDropzone } from "react-dropzone";
import { Input, Label } from "reactstrap";
import { formatBytes } from "../../helpers/format_helper";
import { useCallback, useEffect, useState } from "react";
import ModalSelectionImage from "../../pages/Ecommerce/Product/Products/Components/ModalSelectionImage";

const ImageInput = ({ isShowModalSelection = false, productState, images, onChangeImages = () => {} }) => {
  const [modal, setModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    setSelectedFiles(images ?? []);
  }, [images]);

  useEffect(() => {}, []);
  const toggleModal = () => {
    setModal(!modal);
  };

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const content = event.target.result;
        file.content = content;
        file.preview = URL.createObjectURL(file);
        file.formattedSize = formatBytes(file.size);
        resolve(file);
      };

      reader.onerror = (event) => {
        reject(event.target.error);
      };
      reader.readAsDataURL(file);
    });
  };
  const handleAcceptedFiles = useCallback(
    (files) => {
      if (files.length > 0) {
        const promises = Array.from(files).map((file) => readFileContent(file));
        Promise.all(promises)
          .then((contents) => {
            onChangeImages([...selectedFiles, ...contents]);
          })
          .catch((error) => {
            console.error("Error reading files:", error);
          });
      }
    },
    [selectedFiles],
  );

  const handleChangeFiles = (files) => {
    onChangeImages(files);
  };

  const handleDeleteImage = (index) => {
    let newImages = selectedFiles.filter((item, i) => i !== index);
    onChangeImages(newImages);
  };

  return (
    <div className="dropzone">
      <div className="row justify-content-center dz-message">
        {selectedFiles &&
          selectedFiles.map((item, i) => (
            <div key={i + "-image"} className="col-auto p-0 m-2 ml">
              <img
                data-dz-thumbnail=""
                height="80"
                className="avatar-md rounded bg-light"
                alt={""}
                src={item?.url ?? item?.preview ?? item?.image}
                style={{ objectFit: "contain", aspectRatio: "9 / 9" }}
              />
              <i
                className="position-absolute top-0 start-100 translate-middle ri-close-circle-fill text-danger fs-19 cursor-pointer"
                onClick={() => handleDeleteImage(i)}
              ></i>
            </div>
          ))}
        {isShowModalSelection ? (
          <>
            <div
              className="avatar-md col-auto p-0 m-2 border border-dashed border-2 cursor-pointer rounded-3"
              onClick={toggleModal}
            >
              <i className="display-4 text-muted ri-upload-cloud-2-fill rounded" />
            </div>
            <ModalSelectionImage
              modal={modal}
              toggleModal={toggleModal}
              onSaveImages={handleChangeFiles}
              variantImages={selectedFiles}
              productImages={productState?.images}
            />
          </>
        ) : (
          <Dropzone
            accept={{ "image/*": [".jpeg", ".jpg", ".png"] }}
            onDrop={(acceptedFiles) => {
              handleAcceptedFiles(acceptedFiles);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                className="avatar-md col-auto p-0 m-2 border border-dashed border-2 cursor-pointer rounded-3"
                {...getRootProps()}
              >
                <i className="display-4 text-muted ri-upload-cloud-2-fill rounded" />
              </div>
            )}
          </Dropzone>
        )}
      </div>
    </div>
  );
};
export default ImageInput;
