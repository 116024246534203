import { Fragment, useEffect, useMemo } from "react";
import SimpleTableContainer from "../../../../../Components/Common/SimpleTableContainer";
import { Input, Label } from "reactstrap";
import {
  DiscountAmountType,
  EffectiveProduct,
  EffectiveProductOptions,
} from "../../../../../Components/constants/common";
import DiscountRateValue from "./Columns/DiscountRateValue";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import DiscountDecimal from "./Columns/DiscountDecimal";
import DiscountInfoSearchProduct from "../../../../../Components/Common/DiscountInfoSearchProduct";
import { useTranslation } from "react-i18next";
import { formatVNDCurrency } from "../../../../../helpers/format_helper";

const DiscountByProduct = ({
  onDeleteConditionLine = () => {},
  onChangeConditionLine = () => {},
  onChangeConditionInfo = () => {},
  data = null,
  isEdit = true,
}) => {
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        id: "purchase-product",
        Header: () => <div>{t("Purchased product")}</div>,
        Cell: (cell) => {
          const row = cell.row.original;
          const index = cell.row.index;
          return (
            <Fragment>
              <DiscountInfoSearchProduct
                isEdit={isEdit}
                for_all_items={row?.for_all_items}
                item_type={row?.item_type}
                data={row}
                index={index}
                onChangeConditionLine={onChangeConditionLine}
              />
            </Fragment>
          );
        },
      },
      {
        id: "min_quantity",
        Header: () => <div className="text-center">{t("Min quantity")}</div>,
        Cell: (cell) => {
          const row = cell.row.original;
          const index = cell.row.index;
          return (
            <Fragment>
              {isEdit ? (
                <DiscountDecimal
                  data={row}
                  index={index}
                  field={"min_quantity"}
                  onChangeConditionLine={onChangeConditionLine}
                  className={"text-center"}
                />
              ) : (
                <div className="text-center">{row?.min_quantity || 0}</div>
              )}
            </Fragment>
          );
        },
      },
      {
        id: "discount_limit",
        Header: () => <div className="text-center">{t("Discount limit")}</div>,
        Cell: (cell) => {
          const row = cell.row.original;
          const index = cell.row.index;
          return (
            <Fragment>
              {isEdit ? (
                <DiscountDecimal
                  data={row}
                  index={index}
                  field={"discount_limit"}
                  onChangeConditionLine={onChangeConditionLine}
                  className={"text-center"}
                />
              ) : (
                <div className="text-center">{row?.discount_limit || 0}</div>
              )}
            </Fragment>
          );
        },
      },
      {
        id: "discount",
        Header: () => <div className="text-end">{t("Discount")}</div>,
        Cell: (cell) => {
          const row = cell.row.original;
          const index = cell.row.index;
          return (
            <DiscountRateValue
              data={row}
              index={index}
              onChangeConditionLine={onChangeConditionLine}
              className={"text-end"}
              disabled={!isEdit}
            />
          );
        },
      },
      {
        id: "action",
        Header: () => <div></div>,
        Cell: (cell) => {
          let index = cell.row.index;
          return (
            <div className="fw-medium text-end">
              {isEdit ? (
                <ButtonTheme
                  type="button"
                  className="btn btn-outline-danger btn-icon waves-effect waves-light"
                  onClick={() => {
                    onDeleteConditionLine(index);
                  }}
                >
                  <i className="ri-delete-bin-5-line"></i>
                </ButtonTheme>
              ) : null}
            </div>
          );
        },
      },
    ],
    [data],
  );

  return (
    <Fragment>
      {isEdit && (
        <div>
          <Label>{t("Effective product")}</Label>
          <select
            className="form-select mb-3"
            onChange={(e) => {
              onChangeConditionInfo({ item_type: e.target.value });
            }}
            value={data?.[0]?.item_type || EffectiveProduct.PRODUCTS}
          >
            {EffectiveProductOptions.map((item) => (
              <option key={item.id} value={item.id}>
                {t(item.name)}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className="my-3 w-100">
        <SimpleTableContainer
          columns={columns}
          data={data || []}
          divClass="table-responsive"
          tableClass="table table-nowrap align-middle table-bdiscountless mb-0"
          theadClass="table-light text-muted"
        />
      </div>
    </Fragment>
  );
};
export default DiscountByProduct;
