import React, { useMemo } from "react";
import CountUp from "react-countup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { formatVNDCurrency } from "../../../../helpers/format_helper";
import CustomLink from "../../../../Components/Common/CustomLink";

const Widgets = ({ orderSummary,filterTime,setFilterTime }) => {
  const { t } = useTranslation();
  const revenue = useMemo(() => orderSummary?.revenue || 0, [orderSummary]);
  const numOrders = useMemo(() => orderSummary?.num_orders || 0, [orderSummary]);
  const uniqueCustomers = useMemo(() => orderSummary?.unique_customers || 0, [orderSummary]);
  const lastRevenue = useMemo(() => orderSummary?.comparison_report?.revenue || 0, [orderSummary]);
  const lastNumOrders = useMemo(() => orderSummary?.comparison_report?.num_orders || 0, [orderSummary]);
  const lastUniqueCustomers = useMemo(() => orderSummary?.comparison_report?.unique_customers || 0, [orderSummary]);
  const compareRevenue = useMemo(() => revenue - lastRevenue, [revenue, lastRevenue]);
  const compareNumOrders = useMemo(() => numOrders - lastNumOrders, [numOrders, lastNumOrders]);
  const compareUniqueCustomers = useMemo(
    () => uniqueCustomers - lastUniqueCustomers,
    [uniqueCustomers, lastUniqueCustomers],
  );
  const revenuePercent = useMemo(
    () => (lastRevenue > 0 ? ((revenue - lastRevenue) / lastRevenue) * 100 : 0),
    [lastRevenue, revenue],
  );
  const numOrdersPercent = useMemo(
    () => (lastNumOrders > 0 ? ((numOrders - lastNumOrders) / lastNumOrders) * 100 : 0),
    [lastNumOrders, numOrders],
  );
  const uniqueCustomersPercent = useMemo(
    () => (lastUniqueCustomers > 0 ? ((uniqueCustomers - lastUniqueCustomers) / lastUniqueCustomers) * 100 : 0),
    [uniqueCustomers, lastUniqueCustomers],
  );
  const baseUrl = '/orders';
  const queryParams = [
    filterTime?.firstDate ? `created_at_from=${filterTime.firstDate}` : '',
    filterTime?.secondDate ? `created_at_to=${filterTime.secondDate}` : ''
  ].filter(Boolean).join('&');  // Loại bỏ các chuỗi rỗng và nối chúng bằng '&'

  const url = `${baseUrl}${queryParams ? `?${queryParams}` : ''}`; // Thêm '?' chỉ khi có queryParams

  return (
    <React.Fragment>
      <Col xl={3} md={6}>
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="mb-0">{t("Total Earnings")}</p>
              </div>
              <div className="flex-shrink-0">
                <h5
                  className={`fs-16 mb-0 text-${
                    compareRevenue > 0 ? "success" : compareRevenue === 0 ? "muted" : "danger"
                  }`}
                >
                  {compareRevenue !== 0 && (
                    <i
                      className={`fs-24 align-middle ${
                        compareRevenue > 0 ? "bx bx-trending-up text-success" : "bx bx-trending-down text-danger"
                      } `}
                    ></i>
                  )}{" "}
                  {lastRevenue > 0 ? `${Math.abs(revenuePercent.toFixed(0))} %` : `${formatVNDCurrency(revenue)}`}
                </h5>
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                  <span className="counter-value">
                    <CountUp
                      start={0}
                      prefix={""}
                      suffix={"₫"}
                      separator={"."}
                      end={revenue}
                      decimals={0}
                      duration={4}
                    />
                  </span>
                </h4>
                <CustomLink
                  baseUrl="/transactions"
                  filterTime={filterTime}
                  linkText="View net earnings"
                  className="text-decoration-underline"
                />
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className={"avatar-title fs-3 bg-soft-warning summary-orders-icon"}>
                  <i className={`text-warning ri-money-dollar-circle-fill`}></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl={3} md={6}>
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="mb-0">{t("Orders")}</p>
              </div>
              <div className="flex-shrink-0">
                <h5
                  className={`fs-16 mb-0 text-${
                    compareNumOrders > 0 ? "success" : compareNumOrders === 0 ? "muted" : "danger"
                  }`}
                >
                  {compareNumOrders !== 0 && (
                    <i
                      className={`fs-24 align-middle ${
                        compareNumOrders > 0 ? "bx bx-trending-up text-success" : "bx bx-trending-down text-danger"
                      }`}
                    ></i>
                  )}{" "}
                  {lastNumOrders > 0 ? `${Math.abs(numOrdersPercent.toFixed(0))} %` : `${numOrders}`}
                </h5>
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                  <span className="counter-value">
                    <CountUp
                      start={0}
                      prefix={""}
                      suffix={""}
                      separator={","}
                      end={numOrders}
                      decimals={0}
                      duration={4}
                    />
                  </span>
                </h4>
                <CustomLink
                  baseUrl="/orders"
                  filterTime={filterTime}
                  linkText="View all orders"
                  className="text-decoration-underline"
                />

              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className={"avatar-title fs-3 bg-soft-success summary-orders-icon"}>
                  <i className={`text-success ri-handbag-fill`}></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl={3} md={6}>
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="mb-0">{t("Customers")}</p>
              </div>
              <div className="flex-shrink-0">
                <h5
                  className={`fs-16 mb-0 text-${
                    compareUniqueCustomers > 0 ? "success" : compareUniqueCustomers === 0 ? "muted" : "danger"
                  }`}
                >
                  {compareUniqueCustomers !== 0 && (
                    <i
                      className={`fs-24 align-middle ${
                        compareUniqueCustomers > 0
                          ? "bx bx-trending-up text-success"
                          : "bx bx-trending-down text-danger"
                      }`}
                    ></i>
                  )}{" "}
                  {lastUniqueCustomers > 0 ? `${Math.abs(uniqueCustomersPercent.toFixed(0))} %` : `${uniqueCustomers}`}
                </h5>
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                  <span className="counter-value">
                    <CountUp
                      start={0}
                      prefix={""}
                      suffix={""}
                      separator={","}
                      end={uniqueCustomers}
                      decimals={0}
                      duration={4}
                    />
                  </span>
                </h4>
                <Link to="#" className="text-decoration-underline">
                  {t("See details")}
                </Link>
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className={"avatar-title fs-3 bg-soft-info summary-orders-icon"}>
                  <i className={`text-info ri-user-3-fill`}></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl={3} md={6}>
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="mb-0">{t("My Balance")}</p>
              </div>
              <div className="flex-shrink-0">
                <h5
                  className={`fs-16 mb-0 text-${
                    compareRevenue > 0 ? "success" : compareRevenue === 0 ? "muted" : "danger"
                  }`}
                >
                  {compareRevenue !== 0 && (
                    <i
                      className={`fs-24 align-middle ${
                        compareRevenue > 0 ? "bx bx-trending-up text-success" : "bx bx-trending-down text-danger"
                      }`}
                    ></i>
                  )}{" "}
                  {lastRevenue > 0 ? `${Math.abs(revenuePercent.toFixed(0))} %` : `${formatVNDCurrency(revenue)}`}
                </h5>
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                  <span className="counter-value">
                    <CountUp
                      start={0}
                      prefix={""}
                      suffix={"₫"}
                      separator={"."}
                      end={revenue}
                      decimals={0}
                      duration={4}
                    />
                  </span>
                </h4>
                <CustomLink
                  filterTime={filterTime}
                  baseUrl="/accounts"
                  linkText="Withdraw money"
                  className="text-decoration-underline"
                />
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className={"avatar-title fs-3 bg-soft-danger summary-orders-icon"}>
                  <i className={`text-danger ri-wallet-fill`}></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Widgets;
