import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import useMemoizedSelector from "../../../Components/Hooks/useMemoizedSelector";
import ButtonTheme from "../../../Components/Common/ButtonTheme";
import { listActivity } from "../../../store/actions";
import TableContainerCustom from "../../../Components/Common/TableContainerCustom";
import { Actions } from "../../../Components/constants/common";
import withRouter from "../../../Components/Common/withRouter";
import dayjs from "dayjs";
import BadgeCustom from "../../../Components/Common/BadgeCustom";
import ModalContainer from "../../../Components/Common/ModalContainer";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";

const ActivitiesPage = (props) => {
  document.title = "Activity List | OptiWarehouse";
  const {
    router: { navigate, location, params, t },
  } = props;
  const dispatch = useDispatch();

  const { activities, activitiesLoading, activitiesAction, activitiesLimit, activitiesPage, activitiesTotal } =
    useMemoizedSelector((state) => ({
      activities: state.Activities.activities,
      activitiesAction: state.Activities.action,
      activitiesMessage: state.Activities.message,
      activitiesLoading: state.Activities.loading,
      activitiesSuccess: state.Activities.success,
      activitiesError: state.Activities.error,
      activitiesLimit: state.Activities.limit,
      activitiesPage: state.Activities.page,
      activitiesTotal: state.Activities.total,
    }));

  const [isExportCSV, setIsExportCSV] = useState(false);
  const [dataShow, setDataShow] = useState("");
  const [dataOldShow, setDataOldShow] = useState("");
  const headers = ["id", "table", "action", "note", "version", "on_hand"];
  const [isShowDetail, setIsShowDetail] = useState(false);

  return (
    <div className="page-content">
      <ModalContainer
        isOpen={isShowDetail}
        toggle={() => {
          setIsShowDetail(false);
        }}
        fullscreen={true}
        title={"Dữ liệu thay đổi"}
      >
        <ReactDiffViewer oldValue={dataOldShow} newValue={dataShow} compareMethod={DiffMethod.WORDS} splitView={true} />
      </ModalContainer>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="purchase-order-list">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("Activity log list")}</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-3 flex-wrap">
                      <ButtonTheme type="button" className="btn btn-info" onClick={() => setIsExportCSV(true)}>
                        <i className="ri-file-download-line align-bottom me-1"></i>
                        {t("Export")}
                      </ButtonTheme>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <TableContainerCustom
                  filterType={"activities"}
                  searchPlaceHolder="Search activities ..."
                  listFilter={[
                    {
                      id: "table",
                      type: "selectBox",
                      title: "Table",
                      dataOption: [{ id: "printTemplate", name: "Print Template" }],
                    },
                    {
                      id: "action",
                      type: "selectBox",
                      title: "Action",
                      dataOption: [
                        { id: "MODIFY", name: "MODIFY" },
                        { id: "INSERT", name: "INSERT" },
                        { id: "REMOVE", name: "REMOVE" },
                      ],
                    },
                    {
                      id: "version",
                      type: "date",
                      title: "Version",
                      formatData: "timestamp",
                    },
                  ]}
                  headers={headers}
                  contents={activities}
                  limitPage={Number(activitiesLimit)}
                  currentPage={Number(activitiesPage) + 1}
                  totalItems={activitiesTotal}
                  loading={activitiesLoading && activitiesAction === Actions.LIST}
                  showPagination={true}
                  scrollHeight="50vh"
                  onCallData={(dataPayload) => {
                    dispatch(listActivity(dataPayload));
                  }}
                  isSettings={true}
                  listHeader={[
                    {
                      title: "Thông tin lịch sử",
                      key: "detail-variant",
                      children: [
                        {
                          title: "ID",
                          dataIndex: "id",
                          key: "id",
                          render: (value, row, index) => {
                            return (
                              <>
                                {value === "None" ? (
                                  "---"
                                ) : (
                                  <Link
                                    to={`#`}
                                    className="fw-medium link-primary text-truncate-two-lines"
                                    onClick={() => {
                                      setIsShowDetail(true);
                                      setDataShow(JSON.stringify(row?.data, null, 2));
                                      setDataOldShow(JSON.stringify(row?.old_data, null, 2));
                                    }}
                                  >
                                    {value || "---"}
                                  </Link>
                                )}
                              </>
                            );
                          },
                        },
                        {
                          title: "Table",
                          dataIndex: "table",
                          key: "table",
                          sorter: true,
                          render: (value, row, index) => {
                            return <>{value || "---"}</>;
                          },
                        },
                        {
                          title: "Action",
                          dataIndex: "action",
                          key: "action",
                          sorter: true,
                          render: (value, row, index) => {
                            return <BadgeCustom status={value} />;
                          },
                        },
                        {
                          title: "Note",
                          dataIndex: "note",
                          key: "note",
                          sorter: true,
                          render: (value, row, index) => {
                            return <>{value || "---"}</>;
                          },
                        },
                        {
                          title: "Version",
                          dataIndex: "version",
                          key: "version",
                          sorter: true,
                          render: (value, row, index) => {
                            return <>{dayjs(value * 1000).format("DD/MM/YYYY, HH:mm:ss")}</>;
                          },
                        },
                        {
                          title: "User perform",
                          dataIndex: "user_perform",
                          key: "on_hand",
                          sorter: true,
                          render: (value, row, index) => {
                            return <>{row?.user?.name_staff || "---"}</>;
                          },
                        },
                      ],
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(ActivitiesPage);
