import { CLOSE_TOUR, OPEN_TOUR, SET_CONFIG_STEPS } from "./actionType";

const INIT_STATE = {
  openTour: false,
  typeTour: "",
  steps: [],
};

const Tour = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CONFIG_STEPS:
      return {
        ...state,
        steps: action.payload.steps,
        typeTour: action.payload.typeTour,
      };
    case OPEN_TOUR:
      return {
        ...state,
        openTour: true,
      };
    case CLOSE_TOUR:
      return {
        ...state,
        openTour: false,
      };
    default:
      return state;
  }
};

export default Tour;
