import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";


// Redux States
import { LIST_SALE_CHANNEL, DELETE_SALE_CHANNEL, ADD_NEW_SALE_CHANNEL, UPDATE_SALE_CHANNEL, GET_SALE_CHANNEL } from "./actionType";

import {
  deleteSaleChannelSuccess,
  deleteSaleChannelFail,
  addSaleChannelSuccess,
  addSaleChannelFail,
  updateSaleChannelSuccess,
  updateSaleChannelFail,
  listSaleChannelSuccess,
  listSaleChannelFail,
  getSaleChannelSuccess,
  getSaleChannelFail,
} from "./action";

import { saleChannelsAPI } from "../../../../helpers/resource_helper";

function* listSaleChannel({ payload: { ...res } }) {
  try {
    const response = yield call(saleChannelsAPI.list, { ...res });
    yield put(listSaleChannelSuccess(response));
  } catch (error) {
    yield put(listSaleChannelFail(error));
  }
}

function* getSaleChannel({ payload: id }) {
  try {
    const response = yield call(saleChannelsAPI.get, { id });
    yield put(getSaleChannelSuccess(response));
  } catch (error) {
    yield put(getSaleChannelFail(error));
  }
}

function* deleteSaleChannels({ payload: saleChannel }) {
  try {
    const response = yield call(saleChannelsAPI.delete, saleChannel);
    yield put(deleteSaleChannelSuccess(saleChannel));
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteSaleChannelFail(error));
    toast.error("SaleChannel Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewSaleChannel({ payload: saleChannel }) {
  try {
    const response = yield call(saleChannelsAPI.create, saleChannel);
    yield put(addSaleChannelSuccess(response));
    toast.success("SaleChannel Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addSaleChannelFail(error));
    toast.error("SaleChannel Added Failed", { autoClose: 3000 });
  }
}

function* onUpdateSaleChannel({ payload: saleChannel }) {
  try {
    const response = yield call(saleChannelsAPI.put, saleChannel);
    yield put(updateSaleChannelSuccess(response));
    toast.success("SaleChannel Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateSaleChannelFail(error));
    toast.error("SaleChannel Updated Failed", { autoClose: 3000 });
  }
}

export function* watchListSaleChannel() {
  yield takeEvery(LIST_SALE_CHANNEL, listSaleChannel);
}

export function* watchDeleteSaleChannels() {
  yield takeEvery(DELETE_SALE_CHANNEL, deleteSaleChannels);
}

export function* watchGetSaleChannel() {
  yield takeEvery(GET_SALE_CHANNEL, getSaleChannel);
}


export function* watchUpdateSaleChannel() {
  yield takeEvery(UPDATE_SALE_CHANNEL, onUpdateSaleChannel);
}

export function* watchAddNewSaleChannel() {
  yield takeEvery(ADD_NEW_SALE_CHANNEL, onAddNewSaleChannel);
}

function* saleChannelsSaga() {
  yield all([
    fork(watchGetSaleChannel),
    fork(watchListSaleChannel),
    fork(watchUpdateSaleChannel),
    fork(watchAddNewSaleChannel),
    fork(watchDeleteSaleChannels),
  ]);
}

export default saleChannelsSaga;
