import { useState } from "react";

const BalanceColumn = ({ data }) => {
  const [show, setShow] = useState(false);
  return (
    <div className="hstack">
      <span className="flex-grow-1">{show ? data : "*****"}</span>{" "}
      <button
        className="btn btn-link text-decoration-none text-muted"
        type="button"
        onClick={() => {
          setShow(!show);
        }}
      >
        {show ? <i className="ri-eye-off-fill align-middle"></i> : <i className="ri-eye-fill align-middle"></i>}
      </button>
    </div>
  );
};

export default BalanceColumn;
