import { Form, Formik } from "formik";
import * as Yup from "yup";
import withRouter from "../../../../../Components/Common/withRouter";
import useMemoizedSelector from "../../../../../Components/Hooks/useMemoizedSelector";
import { toast } from "react-toastify";
import { formatDateTime, deepCopy } from "../../../../../helpers/format_helper";
import { useDispatch } from "react-redux";
import {
  addNewStockRelocate,
  cancelStockRelocate,
  deliveryStockRelocate,
  getStockRelocate,
  receiveStockRelocate,
} from "../../../../../store/actions";
import { useEffect, useRef, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import BreadCrumbCustom from "../../../../../Components/Common/BreadCrumbCustom";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import SelectTheme from "../../../../../Components/Common/SelectTheme";
import InputTheme from "../../../../../Components/Common/InputTheme";
import { StockRelocateStatuses } from "../../../../../Components/constants/common";
import StockItemsCard from "./Components/StockItemsCard";
import AddFeesModal from "../../../../../Components/Common/AddFeesModal";
import BadgeCustom from "../../../../../Components/Common/BadgeCustom";
import ScrollToError from "../../../../../Components/Common/ScrollToError";
import { useSearchParams } from "react-router-dom";

const StockRelocateDetails = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  const { id } = params;
  document.title = `${t(!id ? "Create" : "View")} ${t("Stock Relocate Details")} | OptiWarehouse`;
  const dispatch = useDispatch();
  const formRef = useRef();
  const [searchParams] = useSearchParams();
  const clone_stock_relocate_id = searchParams.get("clone_stock_relocate_id");
  const {
    stockRelocate,
    stockRelocatesLoading,
    stockRelocatesCancelLoading,
    stockRelocatesReceiveLoading,
    stockRelocatesDeliveryLoading,
    stockRelocatesSuccess,
    stockRelocatesError,
  } = useMemoizedSelector((state) => ({
    stockRelocate: state.StockRelocates.stockRelocate,
    stockRelocatesLoading: state.StockRelocates.loading,
    stockRelocatesCancelLoading: state.StockRelocates.cancelLoading,
    stockRelocatesReceiveLoading: state.StockRelocates.receiveLoading,
    stockRelocatesDeliveryLoading: state.StockRelocates.deliveryLoading,
    stockRelocatesSuccess: state.StockRelocates.success,
    stockRelocatesError: state.StockRelocates.error,
  }));

  const validation = {
    enableReinitialize: true,

    initialValues: {
      source: stockRelocate?.source || null,
      destination: stockRelocate?.destination || null,
      stock_line_items: stockRelocate?.stock_line_items || [],
      other_fees: stockRelocate?.other_fees || null,
      note: stockRelocate?.note || "",
      tags: stockRelocate?.tags || null,
      reference: stockRelocate?.reference || null,
    },
    validationSchema: Yup.object({
      source: Yup.object().required(t("Please select delivered branch")),
      destination: Yup.object().required(t("Please select received branch")),
    }),
    onSubmit: (values) => {
      let payload = deepCopy(values);
      const source = { id: payload["source"]["id"], name: payload["source"]["name"] };
      const destination = { id: payload["destination"]["id"], name: payload["destination"]["name"] };
      if (source.id === destination.id) {
        toast.error(t("Delivered and received branch must be different!"));
        return;
      }
      payload["source"] = source;
      payload["destination"] = destination;

      const checkInventory = payload.stock_line_items.every((item) => {
        if (clone_stock_relocate_id) {
          return true;
        }
        const { inventories, ...rest } = item;
        const inventoryItem = inventories.find((subItem) => subItem.location_id === source.id);
        return inventoryItem?.available && inventoryItem?.available > item.quantity;
      });

      if (!checkInventory) {
        toast.error(t("Delivery quantity must be less than inventory quantity!"));
        return;
      }

      const checkQuantity = payload.stock_line_items.every((item) => {
        return item.quantity > 0;
      });

      if (!checkQuantity) {
        toast.error(t("Delivery quantity must be more than 0!"));
        return;
      }

      const stock_line_items = payload.stock_line_items.map((item) => {
        const { inventories, ...rest } = item;
        return rest;
      });
      payload["stock_line_items"] = stock_line_items;
      if (payload.stock_line_items.length === 0) {
        toast.error(t("Please add product"));
        return;
      }
      if (!id) {
        dispatch(
          addNewStockRelocate(payload, (data) => {
            navigate(`/stock-relocates/${data.id}`);
          }),
        );
      }
    },
  };
  const [feesModal, setFeesModal] = useState(false);

  const toggleFeesModal = () => {
    setFeesModal(false);
  };

  const handleChangeFees = (data) => {
    formRef.current.setFieldValue("other_fees", data);
  };

  const handleDeleteFee = (item) => {
    const data = deepCopy(formRef.current.values.other_fees);
    const newData = data.filter((fee) => fee.name !== item.name);
    formRef.current.setFieldValue("other_fees", newData);
  };

  const handleChangeStockRelocateLines = (data) => {
    formRef.current.setFieldValue("stock_line_items", data);
  };

  const handleSetValues = (data) => {
    formRef.current.setValues(data);
  };

  useEffect(() => {
    if (id) {
      dispatch(getStockRelocate(id));
    } else {
      if (clone_stock_relocate_id) {
        dispatch(getStockRelocate(clone_stock_relocate_id));
      }
    }
  }, [dispatch, id, clone_stock_relocate_id]);

  return (
    <div className="page-content" tabIndex="0">
      {feesModal && (
        <AddFeesModal
          toggle={toggleFeesModal}
          isOpen={feesModal}
          handleChangeFees={handleChangeFees}
          fees={formRef.current.values?.other_fees || []}
        />
      )}
      <Container fluid>
        <BreadCrumbCustom navigateTo="/stock-relocates" text={"Back to stock relocate list"}>
          {!id ? (
            <div className="hstack gap-2">
              <ButtonTheme
                type="button"
                className="btn btn-light w-md"
                onClick={() => {
                  navigate("/stock-relocates");
                }}
                disabled={stockRelocatesLoading}
              >
                {t("Close")}
              </ButtonTheme>
              <ButtonTheme
                type="button"
                className="btn btn-outline-primary w-md"
                onClick={() => formRef.current.handleSubmit()}
                loading={stockRelocatesLoading}
                disabled={stockRelocatesLoading}
              >
                {t("Create")}
              </ButtonTheme>
            </div>
          ) : (
            <div className="hstack gap-2">
              <ButtonTheme
                type="button"
                className="btn btn-outline-primary w-md"
                onClick={() => {
                  navigate(`/stock-relocates/new?clone_stock_relocate_id=${id}`);
                }}
                loading={stockRelocatesCancelLoading}
                disabled={stockRelocatesLoading}
              >
                <i class="ri-file-copy-line me-1"></i>
                {t("Copy")}
              </ButtonTheme>
              {stockRelocate?.status === StockRelocateStatuses.AWAIT_DELIVERY && (
                <ButtonTheme
                  type="button"
                  className="btn btn-outline-danger w-md"
                  onClick={() => {
                    dispatch(cancelStockRelocate(stockRelocate, handleSetValues));
                  }}
                  loading={stockRelocatesCancelLoading}
                  disabled={stockRelocatesLoading}
                >
                  {t("Cancel")}
                </ButtonTheme>
              )}
              {stockRelocate?.status === StockRelocateStatuses.AWAIT_DELIVERY && (
                <ButtonTheme
                  type="button"
                  className="btn btn-outline-primary w-md"
                  onClick={() => {
                    dispatch(deliveryStockRelocate(stockRelocate, handleSetValues));
                  }}
                  loading={stockRelocatesDeliveryLoading}
                  disabled={stockRelocatesLoading}
                >
                  {t("Delivery")}
                </ButtonTheme>
              )}
              {stockRelocate?.status === StockRelocateStatuses.DELIVERING && (
                <ButtonTheme
                  type="button"
                  className="btn btn-outline-primary w-md"
                  onClick={() => {
                    dispatch(receiveStockRelocate(stockRelocate, handleSetValues));
                  }}
                  loading={stockRelocatesReceiveLoading}
                  disabled={stockRelocatesLoading}
                >
                  {t("Receive")}
                </ButtonTheme>
              )}
            </div>
          )}
        </BreadCrumbCustom>
        <Formik
          enableReinitialize={true}
          initialValues={validation.initialValues}
          validationSchema={validation.validationSchema}
          onSubmit={validation.onSubmit}
          innerRef={formRef}
        >
          {({ values, setFieldValue, errors, touched }) => {
            return (
              <Form>
                <ScrollToError />
                <Row>
                  <Col className="col-8">
                    <Card>
                      <CardHeader>
                        {!id ? (
                          <div className="d-flex align-items-center">
                            <h5 className="card-title flex-grow-1 mb-0">{t("Voucher info")}</h5>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center">
                            <h5 className="card-title flex-grow-1 mb-0">
                              {t("Voucher info")} #{stockRelocate?.code}
                            </h5>
                            <h5 className="flex-shrink-0 mb-0">
                              <BadgeCustom status={stockRelocate?.status} />
                            </h5>
                          </div>
                        )}
                      </CardHeader>
                      <CardBody>
                        {!id ? (
                          <Row>
                            <Col className="col-6">
                              <div className="mb-3">
                                <SelectTheme
                                  label={"Delivered branch"}
                                  name="source"
                                  placeholder={"Select A Branch"}
                                  remote={true}
                                  isObjectValueSelect={true}
                                  selectDataOrigin={true}
                                  path="inventory/locations"
                                />
                              </div>
                            </Col>
                            <Col className="col-6">
                              <div className="mb-3">
                                <SelectTheme
                                  label={"Received branch"}
                                  name="destination"
                                  placeholder={"Select A Branch"}
                                  remote={true}
                                  isObjectValueSelect={true}
                                  selectDataOrigin={true}
                                  path="inventory/locations"
                                />
                              </div>
                            </Col>
                            <Col className="col-6">
                              <div className="mb-3">
                                <InputTheme
                                  name="reference"
                                  label="Reference"
                                  disabled={stockRelocatesLoading}
                                  placeholder="Enter reference"
                                />
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col lg={6}>
                              <Row>
                                <Col className="hstack justify-content-between col-4">
                                  <span>{t("Delivered branch")}</span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">
                                  <span className="text-truncate">{stockRelocate?.source?.name || "---"}</span>
                                </Col>
                                <Col className="hstack justify-content-between col-4">
                                  <span>{t("Created at")}</span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">
                                  {formatDateTime(stockRelocate?.created_at)}
                                </Col>
                                <Col className="hstack justify-content-between col-4">
                                  <span>{t("Delivered at")}</span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">
                                  {stockRelocate?.delivered_at ? formatDateTime(stockRelocate?.delivered_at) : "---"}
                                </Col>
                                <Col className="hstack justify-content-between col-4">
                                  <span>{t("Received at")}</span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">
                                  {stockRelocate?.received_at ? formatDateTime(stockRelocate?.received_at) : "---"}
                                </Col>
                                <Col className="hstack justify-content-between col-4">
                                  <span>
                                    {t("Received")} / {t("Delivered")}
                                  </span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">
                                  {stockRelocate?.received_quantity} / {stockRelocate?.quantity}
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={6}>
                              <Row>
                                <Col className="hstack justify-content-between col-4">
                                  <span>{t("Received branch")}</span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">
                                  <span className="text-truncate">{stockRelocate?.destination?.name || "---"}</span>
                                </Col>
                                <Col className="hstack justify-content-between col-4">
                                  <span>{t("Creating staff")}</span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">
                                  {stockRelocate?.creating_staff?.name || "---"}
                                </Col>
                                <Col className="hstack justify-content-between col-4">
                                  <span>{t("Delivering staff")}</span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">
                                  {stockRelocate?.delivering_staff?.name || "---"}
                                </Col>
                                <Col className="hstack justify-content-between col-4">
                                  <span>{t("Receiving staff")}</span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">
                                  {stockRelocate?.receiving_staff?.name || "---"}
                                </Col>
                                <Col className="hstack justify-content-between col-4">
                                  <span>{t("Reference")}</span>
                                  <span>:</span>
                                </Col>
                                <Col className="hstack justify-content-end col-8">
                                  {stockRelocate?.reference || "---"}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="col-4">
                    <Card>
                      <CardHeader>
                        <div className="d-flex align-items-center">
                          <h5 className="card-title flex-grow-1 mb-0">{t("Additional info")}</h5>
                        </div>
                      </CardHeader>
                      <CardBody>
                        {!id ? (
                          <Row>
                            <Col className="col-12">
                              <div className="mb-3">
                                <InputTheme
                                  name="note"
                                  label="Note"
                                  disabled={stockRelocatesLoading}
                                  placeholder="Enter note"
                                />
                              </div>
                            </Col>
                            <Col className="col-12">
                              <div className="mb-3">
                                <InputTheme
                                  name={"tags"}
                                  label={"Tags"}
                                  type="tags"
                                  placeholder={"Enter tags and press enter"}
                                  disabled={stockRelocatesLoading}
                                />
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col lg={12}>
                              <div className="mb-3">
                                <div className="fw-bolder">{t("Note")}</div>
                                <div>{stockRelocate?.note || "---"}</div>
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="mb-3">
                                <div className="fw-bolder">{t("Tags")}</div>
                                <div>{stockRelocate?.tags || "---"}</div>
                              </div>
                            </Col>
                          </Row>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="col-12">
                    <StockItemsCard
                      values={values}
                      stockLineItems={values.stock_line_items}
                      onChangeStockRelocate={handleChangeStockRelocateLines}
                      isEdit={!id}
                      onDeleteFee={handleDeleteFee}
                      toggleOnFee={() => {
                        setFeesModal(true);
                      }}
                    />
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </div>
  );
};

export default withRouter(StockRelocateDetails);
