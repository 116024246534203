import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";


// Redux States
import {
  LIST_PURCHASE_ORDER,
  DELETE_PURCHASE_ORDER,
  ADD_NEW_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER,
  GET_PURCHASE_ORDER,
} from "./actionType";

import {
  deletePurchaseOrderSuccess,
  deletePurchaseOrderFail,
  addPurchaseOrderSuccess,
  addPurchaseOrderFail,
  updatePurchaseOrderSuccess,
  updatePurchaseOrderFail,
  listPurchaseOrderSuccess,
  listPurchaseOrderFail,
  getPurchaseOrderSuccess,
  getPurchaseOrderFail,
} from "./action";

import { purchaseOrdersAPI } from "../../../../helpers/resource_helper";

function* listPurchaseOrder({ payload: { ...res } }) {
  try {
    const response = yield call(purchaseOrdersAPI.list, { ...res });
    yield put(listPurchaseOrderSuccess(response));
  } catch (error) {
    yield put(listPurchaseOrderFail(error));
  }
}

function* getPurchaseOrder({ payload: id }) {
  try {
    const response = yield call(purchaseOrdersAPI.get, { id });
    yield put(getPurchaseOrderSuccess(response));
  } catch (error) {
    yield put(getPurchaseOrderFail(error));
  }
}

function* deletePurchaseOrder({ payload: { purchase_order, handleAfterSubmit } }) {
  try {
    const response = yield call(purchaseOrdersAPI.delete, purchase_order);
    yield put(deletePurchaseOrderSuccess(purchase_order));
    yield call(handleAfterSubmit, purchase_order.id);
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deletePurchaseOrderFail(error));
    toast.error("Purchase Order Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewPurchaseOrder({ payload: { purchase_order, handleAfterSubmit } }) {
  try {
    const response = yield call(purchaseOrdersAPI.create, purchase_order);
    if (response) {
      yield put(addPurchaseOrderSuccess(response));
      yield call(handleAfterSubmit, response.id);
      toast.success("Purchase Order Added Successfully", { autoClose: 3000 });
    }
  } catch (error) {
    yield put(addPurchaseOrderFail(error));
    toast.error("Purchase Order Added Failed", { autoClose: 3000 });
  }
}

function* onUpdatePurchaseOrder({ payload: { purchase_order, handleAfterSubmit } }) {
  try {
    const response = yield call(purchaseOrdersAPI.put, purchase_order);
    yield put(updatePurchaseOrderSuccess(response));
    yield call(handleAfterSubmit, response.id);
    toast.success("Purchase Order Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updatePurchaseOrderFail(error));
    toast.error("Purchase Order Updated Failed", { autoClose: 3000 });
  }
}

export function* watchGetPurchaseOrders() {
  yield takeEvery(LIST_PURCHASE_ORDER, listPurchaseOrder);
}

export function* watchDeletePurchaseOrder() {
  yield takeEvery(DELETE_PURCHASE_ORDER, deletePurchaseOrder);
}

export function* watchGetPurchaseOrder() {
  yield takeEvery(GET_PURCHASE_ORDER, getPurchaseOrder);
}

export function* watchUpdatePurchaseOrder() {
  yield takeEvery(UPDATE_PURCHASE_ORDER, onUpdatePurchaseOrder);
}

export function* watchAddNewPurchaseOrder() {
  yield takeEvery(ADD_NEW_PURCHASE_ORDER, onAddNewPurchaseOrder);
}

function* purchaseOrdersSaga() {
  yield all([
    fork(watchGetPurchaseOrder),
    fork(watchGetPurchaseOrders),
    fork(watchUpdatePurchaseOrder),
    fork(watchAddNewPurchaseOrder),
    fork(watchDeletePurchaseOrder),
  ]);
}

export default purchaseOrdersSaga;
