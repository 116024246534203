import {
  GET_COLOR_SHOP_INFO,
  GET_COLOR_SHOP_INFO_FAIL,
  GET_COLOR_SHOP_INFO_SUCCESS,
  GET_DEFAULT_PRINT,
  GET_DEFAULT_PRINT_FAIL,
  GET_DEFAULT_PRINT_SUCCESS,
  GET_SHOP_INFO,
  GET_SHOP_INFO_FAIL,
  GET_SHOP_INFO_SUCCESS,
  UPDATE_COLOR_SHOP_INFO,
  UPDATE_COLOR_SHOP_INFO_FAIL,
  UPDATE_COLOR_SHOP_INFO_SUCCESS,
  UPDATE_DEFAULT_PRINT,
  UPDATE_DEFAULT_PRINT_FAIL,
  UPDATE_DEFAULT_PRINT_SUCCESS,
  UPDATE_LOCATION_ID,
  UPDATE_SHOP_INFO,
  UPDATE_SHOP_INFO_FAIL,
  UPDATE_SHOP_INFO_SUCCESS,
} from "./actionType";

const INIT_STATE = {
  dataShop: {},
  defaultPrint: {},
  loading: false,
  loadingDefaultTemplate: false,
  locationUser: {},
  dataColor: {},
  loadingColor: false,
};

const ShopInfo = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SHOP_INFO:
      return {
        ...state,
        loading: true,
      };

    case GET_SHOP_INFO_SUCCESS:
      return {
        ...state,
        dataShop: action.payload,
        loading: false,
      };

    case GET_SHOP_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_SHOP_INFO:
      return {
        ...state,
        message: "",
        error: null,
        loading: true,
      };
    case UPDATE_SHOP_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        dataShop: {
          ...action.payload,
        },
      };

    case UPDATE_SHOP_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_DEFAULT_PRINT:
      return {
        ...state,
        loading: true,
      };

    case GET_DEFAULT_PRINT_SUCCESS:
      return {
        ...state,
        defaultPrint: action.payload,
        loading: false,
      };

    case GET_DEFAULT_PRINT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_DEFAULT_PRINT:
      return {
        ...state,
        loadingDefaultTemplate: true,
      };
    case UPDATE_DEFAULT_PRINT_SUCCESS:
      return {
        ...state,
        defaultPrint: action.payload,
        loadingDefaultTemplate: false,
      };
    case UPDATE_DEFAULT_PRINT_FAIL:
      return {
        ...state,
        loadingDefaultTemplate: false,
        error: action.payload,
      };

    case UPDATE_LOCATION_ID:
      return {
        ...state,
        locationUser: action.payload,
      };

    case GET_COLOR_SHOP_INFO:
      return {
        ...state,
        loadingColor: true,
      };

    case GET_COLOR_SHOP_INFO_SUCCESS:
      return {
        ...state,
        dataColor: action.payload,
        loadingColor: false,
      };

    case GET_COLOR_SHOP_INFO_FAIL:
      return {
        ...state,
        loadingColor: false,
        error: action.payload,
      };

    case UPDATE_COLOR_SHOP_INFO:
      return {
        ...state,
        loadingColor: true,
      };

    case UPDATE_COLOR_SHOP_INFO_SUCCESS:
      return {
        ...state,
        dataColor: action.payload,
        loadingColor: false,
      };

    case UPDATE_COLOR_SHOP_INFO_FAIL:
      return {
        ...state,
        loadingColor: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default ShopInfo;
