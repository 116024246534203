import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";


// Redux States
import {
  LIST_TRANSACTION_VOUCHER,
  DELETE_TRANSACTION_VOUCHER,
  ADD_NEW_TRANSACTION_VOUCHER,
  UPDATE_TRANSACTION_VOUCHER,
  GET_TRANSACTION_VOUCHER,
} from "./actionType";

import {
  deleteTransactionVoucherSuccess,
  deleteTransactionVoucherFail,
  addTransactionVoucherSuccess,
  addTransactionVoucherFail,
  updateTransactionVoucherSuccess,
  updateTransactionVoucherFail,
  listTransactionVoucherSuccess,
  listTransactionVoucherFail,
  getTransactionVoucherSuccess,
  getTransactionVoucherFail,
} from "./action";

import { transactionVouchersAPI } from "../../../../helpers/resource_helper";

function* listTransactionVoucher({ payload: { ...rest } }) {
  try {
    const response = yield call(transactionVouchersAPI.list, { limit: 20, ...rest });
    yield put(listTransactionVoucherSuccess(response));
  } catch (error) {
    yield put(listTransactionVoucherFail(error));
  }
}

function* getTransactionVoucher({ payload: id }) {
  try {
    const response = yield call(transactionVouchersAPI.get, { id });
    yield put(getTransactionVoucherSuccess(response));
  } catch (error) {
    yield put(getTransactionVoucherFail(error));
  }
}

function* deleteTransactionVouchers({ payload: { transactionVoucher, handleAfterSubmit } }) {
  try {
    const response = yield call(transactionVouchersAPI.delete, { id: transactionVoucher.id });
    yield put(deleteTransactionVoucherSuccess(transactionVoucher));
    yield call(handleAfterSubmit);
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteTransactionVoucherFail(error));
    toast.error("Transaction Voucher Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewTransactionVoucher({ payload: { transactionVoucher, handleAfterSubmit } }) {
  try {
    const response = yield call(transactionVouchersAPI.create, transactionVoucher);
    yield put(addTransactionVoucherSuccess(response));
    yield call(handleAfterSubmit);
    toast.success("Transaction Voucher Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addTransactionVoucherFail(error));
    toast.error("Transaction Voucher Added Failed", { autoClose: 3000 });
  }
}

function* onUpdateTransactionVoucher({ payload: { transactionVoucher, handleAfterSubmit } }) {
  try {
    const response = yield call(transactionVouchersAPI.put, transactionVoucher);
    yield put(updateTransactionVoucherSuccess(response));
    toast.success("Transaction Voucher Updated Successfully", { autoClose: 3000 });
    yield call(handleAfterSubmit);
  } catch (error) {
    yield put(updateTransactionVoucherFail(error));
    toast.error("Transaction Voucher Updated Failed", { autoClose: 3000 });
  }
}

export function* watchListTransactionVoucher() {
  yield takeEvery(LIST_TRANSACTION_VOUCHER, listTransactionVoucher);
}

export function* watchDeleteTransactionVouchers() {
  yield takeEvery(DELETE_TRANSACTION_VOUCHER, deleteTransactionVouchers);
}

export function* watchGetTransactionVoucher() {
  yield takeEvery(GET_TRANSACTION_VOUCHER, getTransactionVoucher);
}

export function* watchUpdateTransactionVoucher() {
  yield takeEvery(UPDATE_TRANSACTION_VOUCHER, onUpdateTransactionVoucher);
}

export function* watchAddNewTransactionVoucher() {
  yield takeEvery(ADD_NEW_TRANSACTION_VOUCHER, onAddNewTransactionVoucher);
}

function* transactionVouchersSaga() {
  yield all([
    fork(watchGetTransactionVoucher),
    fork(watchListTransactionVoucher),
    fork(watchUpdateTransactionVoucher),
    fork(watchAddNewTransactionVoucher),
    fork(watchDeleteTransactionVouchers),
  ]);
}

export default transactionVouchersSaga;
