// Actions
export const LIST_ACTIVITY = "LIST_ACTIVITY";
export const LIST_ACTIVITY_SUCCESS = "LIST_ACTIVITY_SUCCESS";
export const LIST_ACTIVITY_FAIL = "LIST_ACTIVITY_FAIL";

export const GET_ACTIVITY = "GET_ACTIVITY";
export const GET_ACTIVITY_SUCCESS = "GET_ACTIVITY_SUCCESS";
export const GET_ACTIVITY_FAIL = "GET_ACTIVITY_FAIL";

export const RESET_ACTIVITY_FLAG = "RESET_ACTIVITY_FLAG";
