import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import InputTheme from "../../../../Components/Common/InputTheme";
import * as Yup from "yup";
import { Link, useParams, useSearchParams } from "react-router-dom";
import SelectTheme from "../../../../Components/Common/SelectTheme";
import BreadCrumbCustom from "../../../../Components/Common/BreadCrumbCustom";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { getDetailUserAPI, postUserAPI, updateUserAPI } from "../../../../helpers/service_helper";
import { permissionAPI } from "../../../../helpers/resource_helper";
import { toast } from "react-toastify";
import withRouter from "../../../../Components/Common/withRouter";
import Loader from "../../../../Components/Common/Loader";
import ScrollToError from "../../../../Components/Common/ScrollToError";

function DetailAccount({ router: { t, params } }) {
  const { username } = params;
  const [loading, setLoading] = useState(false);
  const [permissionLoading, setPermissionLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [permissionDetails, setPermissionDetails] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validateForm = {
    // name: Yup.string().required("Please Enter Name"),
    // phone: Yup.string().matches(phoneRegExp, "Phone number is not valid").required("Please Enter phone"),
    // email: Yup.string().email("Enter a valid email").required("Email is required"),

    // address: Yup.string().required("Address is required"),
    roles: Yup.array()
      .of(
        Yup.object().shape({
          role: Yup.object().required("role is required"),
          location: Yup.string().required("location is required"),
        }),
      )
      .required("Must have friends") // these constraints are shown if and only if inner constraints are satisfied
      .min(1, "Minimum of 3 friends"),
  };
  const form = {
    initialValues: {
      roles: permissionDetails?.roles || [{}],
      username: permissionDetails?.username || userDetails?.username || "",
      phone: permissionDetails?.phone || userDetails?.phone || "",
      birthdate: permissionDetails?.birthdate || userDetails?.birthdate || "",
      email: permissionDetails?.email || userDetails?.email || "",
      password: permissionDetails?.password || "",
      re_password: permissionDetails?.password || "",
      address: permissionDetails?.address || userDetails?.address || "",
      name: permissionDetails?.name || userDetails?.name || "",
    },
    validationSchema: Yup.object().shape(
      userDetails
        ? validateForm
        : {
            ...validateForm,
            ...{
              username: Yup.string().required("Please Enter username"),
              password: Yup.string()
                .required("This field is required")
                .min(8, "Pasword must be 8 or more characters")
                .matches(
                  /(?=.*[a-z])(?=.*[A-Z])\w+/,
                  "Password ahould contain at least one uppercase and lowercase character",
                )
                .matches(/\d/, "Password should contain at least one number")
                .matches(
                  /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
                  "Password should contain at least one special character",
                ),
              re_password: Yup.string()
                .oneOf([Yup.ref("password"), null], "Passwords must match")
                .required("Password is required"),
            },
          },
    ),
    onSubmit: async (values, actions) => {
      try {
        setLoading(true);
        const { re_password, roles, ...res } = values;
        const mapRole = roles.reduce((acc, current, index, array) => {
          acc[current.location] = current.role;
          return acc;
        }, {});
        let sub = "";
        if (userDetails) {
          delete res["password"];
          await updateUserAPI(userDetails.username, { ...userDetails, ...res });
          sub = userDetails.sub;
        } else {
          const resUser = await postUserAPI({ ...res });
          sub = resUser?.User?.Attributes?.find((item) => item.Name === "sub")?.Value;
        }
        await permissionAPI.create({
          user_id: sub,
          user_dict: res,
          role: mapRole,
        });
        if (permissionDetails) {
          toast.success("Edit user success!");
        } else {
          actions.resetForm();
          toast.success("Add user success!");
        }
      } catch (error) {
        toast.error(error.toString());
      } finally {
        setLoading(false);
      }
    },
  };
  const formRef = useRef();

  const getUserByUsername = async () => {
    if (!username) {
      return;
    }
    try {
      setUserLoading(true);
      const res = await getDetailUserAPI(username);
      const newUserDetails = { username };
      res.UserAttributes.forEach((item) => (newUserDetails[item["Name"]] = item["Value"]));
      setUserDetails(newUserDetails);
    } catch (e) {
      console.error(e);
    } finally {
      setUserLoading(false);
    }
  };
  const getPermissionBySub = async (data) => {
    if (!data?.sub) {
      return;
    }
    try {
      setPermissionLoading(true);
      const resPermission = await permissionAPI.get({ id: data?.sub });
      const convertRole = Object.keys(resPermission.role).map((item) => ({
        location: item,
        role: resPermission.role[item],
      }));
      const newPermission = { ...resPermission.user_dict, roles: convertRole, user_id: resPermission.user_id };
      setPermissionDetails(newPermission);
    } catch (e) {
      console.error(e);
    } finally {
      setPermissionLoading(false);
    }
  };

  useEffect(() => {
    getUserByUsername();
  }, [username]);
  useEffect(() => {
    if (userDetails) {
      getPermissionBySub(userDetails);
    }
  }, [userDetails]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumbCustom text="Back to accounts list" navigateTo="/settings/accounts">
          <ButtonTheme
            loading={loading}
            disabled={loading || userLoading || permissionLoading}
            loadShowText={true}
            className={"btn btn-primary"}
            onClick={() => {
              formRef.current.handleSubmit();
            }}
          >
            {t("Save")}
          </ButtonTheme>
        </BreadCrumbCustom>

        {userLoading || permissionLoading ? (
          <Loader />
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={form.initialValues}
            validationSchema={form.validationSchema}
            onSubmit={form.onSubmit}
            innerRef={formRef}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <ScrollToError />
                <Card>
                  <CardHeader>{t("Staff information")}</CardHeader>
                  <CardBody>
                    <Row>
                      <InputTheme name={"name"} label={"Staff name"} />
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <InputTheme name={"username"} label={"Username"} disabled={userDetails} />
                      </Col>
                      <Col lg={4}>
                        <InputTheme
                          name={"password"}
                          label={"Store login password"}
                          type="password"
                          disabled={userDetails}
                        />
                      </Col>
                      <Col lg={4}>
                        <InputTheme
                          name={"re_password"}
                          label={"Confirm password"}
                          type="password"
                          disabled={userDetails}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <InputTheme name={"email"} label={"Email"} />
                      </Col>
                      <Col lg={4}>
                        <InputTheme name={"phone"} label={"Phone"} />
                      </Col>
                      <Col lg={4}>
                        <InputTheme label={"Birth date"} name="birthdate" />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <InputTheme name={"address"} label={"Address"} />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader className="d-flex align-items-center justify-content-between">
                    <h5>{t("Staff role")}</h5>
                    <Link to={"/settings/accounts/role"}>{t("Role list")}</Link>
                  </CardHeader>
                  <CardBody>
                    <FieldArray name="roles">
                      {({ push, remove }) => (
                        <div>
                          {values.roles.map((role, index) => (
                            <Row key={index}>
                              <Col lg={5}>
                                <SelectTheme
                                  name={`roles.[${index}].role`}
                                  label="Role"
                                  remote={true}
                                  path={`account/role`}
                                  keyLabel={"role"}
                                  isObjectValueSelect={true}
                                />
                              </Col>
                              <Col lg={5}>
                                {/* <SelectTheme
                                  name={`roles.${index}.location`}
                                  label="Location"
                                  options={[
                                    ...locations.map((item) => ({ id: item.id, name: item.name })),
                                    { id: "All", name: "Tất cả" },
                                  ]}
                                /> */}
                                <SelectTheme
                                  label={"Branch"}
                                  name={`roles.${index}.location`}
                                  remote={true}
                                  path="inventory/locations"
                                  extraOptions={[{ id: "ALL", name: "All" }]}
                                />
                              </Col>
                              <Col lg={2} className="text-center mt-2 d-flex align-items-center">
                                <ButtonTheme
                                  className="btn btn-danger"
                                  onClick={() => {
                                    remove(index);
                                  }}
                                >
                                  {t("Delete")}
                                </ButtonTheme>
                              </Col>
                            </Row>
                          ))}
                          <ButtonTheme
                            className={"btn btn-primary"}
                            onClick={() => {
                              push({});
                            }}
                          >
                            {t("Assign additional roles")}
                          </ButtonTheme>
                        </div>
                      )}
                    </FieldArray>
                  </CardBody>
                </Card>

                {/* <Card>
                <CardHeader>Phân quyền dữ liệu</CardHeader>
                <CardBody></CardBody>
              </Card> */}
              </Form>
            )}
          </Formik>
        )}
      </Container>
    </div>
  );
}

export default withRouter(DetailAccount);
