// Actions
export const LIST_ORDER = "LIST_ORDER";
export const LIST_ORDER_SUCCESS = "LIST_ORDER_SUCCESS";
export const LIST_ORDER_FAIL = "LIST_ORDER_FAIL";

export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAIL = "GET_ORDER_FAIL";

/**
 * Delete ORDER
 */
export const DELETE_ORDER = "DELETE_ORDER";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_FAIL = "DELETE_ORDER_FAIL";

/**
 * Add ORDER
 */
export const ADD_NEW_ORDER = "ADD_NEW_ORDER";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const ADD_ORDER_FAIL = "ADD_ORDER_FAIL";

/**
 * Edit ORDER
 */
export const UPDATE_ORDER = "UPDATE_ORDER";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL";

export const RESET_ORDER_FLAG = "RESET_ORDER_FLAG";
