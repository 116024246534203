import {
  LIST_STOCK_ADJUSTMENT,
  DELETE_STOCK_ADJUSTMENT,
  DELETE_STOCK_ADJUSTMENT_SUCCESS,
  DELETE_STOCK_ADJUSTMENT_FAIL,
  ADD_STOCK_ADJUSTMENT_SUCCESS,
  ADD_STOCK_ADJUSTMENT_FAIL,
  UPDATE_STOCK_ADJUSTMENT_SUCCESS,
  UPDATE_STOCK_ADJUSTMENT_FAIL,
  LIST_STOCK_ADJUSTMENT_SUCCESS,
  LIST_STOCK_ADJUSTMENT_FAIL,
  ADD_NEW_STOCK_ADJUSTMENT,
  UPDATE_STOCK_ADJUSTMENT,
  GET_STOCK_ADJUSTMENT,
  GET_STOCK_ADJUSTMENT_SUCCESS,
  GET_STOCK_ADJUSTMENT_FAIL,
  BALANCE_STOCK_ADJUSTMENT,
  BALANCE_STOCK_ADJUSTMENT_SUCCESS,
  BALANCE_STOCK_ADJUSTMENT_FAIL,
} from "./actionType";

const INIT_STATE = {
  stockAdjustments: [],
  stockAdjustment: {},
  error: null,
  success: false,
  loading: false,
  limit: 0,
  page: 0,
  total: 0,
};

const StockAdjustments = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_STOCK_ADJUSTMENT:
      return {
        ...state,
        stockAdjustment: {},
        error: null,
        success: false,
        loading: true,
      };

    case LIST_STOCK_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        stockAdjustments: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
      };

    case LIST_STOCK_ADJUSTMENT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case GET_STOCK_ADJUSTMENT:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };

    case GET_STOCK_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        stockAdjustment: action.payload,
      };

    case GET_STOCK_ADJUSTMENT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case DELETE_STOCK_ADJUSTMENT:
      return {
        ...state,
        error: null,
        success: false,
        deleteLoading: true,
      };

    case DELETE_STOCK_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        success: true,
        stockAdjustment: action.payload,
        stockAdjustments: state.stockAdjustments.map((stockAdjustment) =>
          stockAdjustment.id.toString() === action.payload.id.toString()
            ? { ...stockAdjustment, ...action.payload }
            : stockAdjustment,
        ),
      };

    case DELETE_STOCK_ADJUSTMENT_FAIL:
      return {
        ...state,
        deleteLoading: false,
        success: false,
        error: action.payload,
      };
    case BALANCE_STOCK_ADJUSTMENT:
      return {
        ...state,
        error: null,
        success: false,
        balanceLoading: true,
      };

    case BALANCE_STOCK_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        balanceLoading: false,
        success: true,
        stockAdjustment: action.payload,
        stockAdjustments: state.stockAdjustments.map((stockAdjustment) =>
          stockAdjustment.id.toString() === action.payload.id.toString()
            ? { ...stockAdjustment, ...action.payload }
            : stockAdjustment,
        ),
      };

    case BALANCE_STOCK_ADJUSTMENT_FAIL:
      return {
        ...state,
        balanceLoading: false,
        success: false,
        error: action.payload,
      };

    case ADD_NEW_STOCK_ADJUSTMENT:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };

    case ADD_STOCK_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        stockAdjustments: [action.payload, ...state.stockAdjustments],
      };

    case ADD_STOCK_ADJUSTMENT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case UPDATE_STOCK_ADJUSTMENT:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };
    case UPDATE_STOCK_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        stockAdjustment: action.payload,
        stockAdjustments: state.stockAdjustments.map((stockAdjustment) =>
          stockAdjustment.id.toString() === action.payload.id.toString()
            ? { ...stockAdjustment, ...action.payload }
            : stockAdjustment,
        ),
      };

    case UPDATE_STOCK_ADJUSTMENT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default StockAdjustments;
