import {
  LIST_STOCK_ADJUSTMENT,
  DELETE_STOCK_ADJUSTMENT,
  DELETE_STOCK_ADJUSTMENT_SUCCESS,
  DELETE_STOCK_ADJUSTMENT_FAIL,
  ADD_NEW_STOCK_ADJUSTMENT,
  ADD_STOCK_ADJUSTMENT_SUCCESS,
  ADD_STOCK_ADJUSTMENT_FAIL,
  UPDATE_STOCK_ADJUSTMENT,
  UPDATE_STOCK_ADJUSTMENT_SUCCESS,
  UPDATE_STOCK_ADJUSTMENT_FAIL,
  LIST_STOCK_ADJUSTMENT_FAIL,
  LIST_STOCK_ADJUSTMENT_SUCCESS,
  GET_STOCK_ADJUSTMENT,
  GET_STOCK_ADJUSTMENT_FAIL,
  GET_STOCK_ADJUSTMENT_SUCCESS,
  BALANCE_STOCK_ADJUSTMENT,
  BALANCE_STOCK_ADJUSTMENT_SUCCESS,
  BALANCE_STOCK_ADJUSTMENT_FAIL,
} from "./actionType";

export const listStockAdjustment = (options) => ({
  type: LIST_STOCK_ADJUSTMENT,
  payload: options,
});

export const listStockAdjustmentFail = (error) => ({
  type: LIST_STOCK_ADJUSTMENT_FAIL,
  payload: error,
});

export const listStockAdjustmentSuccess = (stockAdjustments) => ({
  type: LIST_STOCK_ADJUSTMENT_SUCCESS,
  payload: stockAdjustments,
});

export const getStockAdjustment = (stockAdjustmentID) => ({
  type: GET_STOCK_ADJUSTMENT,
  payload: stockAdjustmentID,
});

export const getStockAdjustmentFail = (error) => ({
  type: GET_STOCK_ADJUSTMENT_FAIL,
  payload: error,
});

export const getStockAdjustmentSuccess = (stockAdjustment) => ({
  type: GET_STOCK_ADJUSTMENT_SUCCESS,
  payload: stockAdjustment,
});

export const deleteStockAdjustment = (stockAdjustment, handleAfterSubmit = () => {}) => ({
  type: DELETE_STOCK_ADJUSTMENT,
  payload: { stockAdjustment, handleAfterSubmit },
});

export const deleteStockAdjustmentSuccess = (stockAdjustment) => ({
  type: DELETE_STOCK_ADJUSTMENT_SUCCESS,
  payload: stockAdjustment,
});

export const deleteStockAdjustmentFail = (error) => ({
  type: DELETE_STOCK_ADJUSTMENT_FAIL,
  payload: error,
});

export const balanceStockAdjustment = (stockAdjustment, handleAfterSubmit = () => {}) => ({
  type: BALANCE_STOCK_ADJUSTMENT,
  payload: { stockAdjustment, handleAfterSubmit },
});

export const balanceStockAdjustmentSuccess = (stockAdjustment) => ({
  type: BALANCE_STOCK_ADJUSTMENT_SUCCESS,
  payload: stockAdjustment,
});

export const balanceStockAdjustmentFail = (error) => ({
  type: BALANCE_STOCK_ADJUSTMENT_FAIL,
  payload: error,
});

export const updateStockAdjustment = (stockAdjustment) => ({
  type: UPDATE_STOCK_ADJUSTMENT,
  payload: stockAdjustment,
});

export const updateStockAdjustmentSuccess = (stockAdjustment) => ({
  type: UPDATE_STOCK_ADJUSTMENT_SUCCESS,
  payload: stockAdjustment,
});

export const updateStockAdjustmentFail = (error) => ({
  type: UPDATE_STOCK_ADJUSTMENT_FAIL,
  payload: error,
});

export const addNewStockAdjustment = (stockAdjustment, handleAfterSubmit = () => {}) => ({
  type: ADD_NEW_STOCK_ADJUSTMENT,
  payload: { stockAdjustment, handleAfterSubmit },
});

export const addStockAdjustmentSuccess = (stockAdjustment) => ({
  type: ADD_STOCK_ADJUSTMENT_SUCCESS,
  payload: stockAdjustment,
});

export const addStockAdjustmentFail = (error) => ({
  type: ADD_STOCK_ADJUSTMENT_FAIL,
  payload: error,
});
