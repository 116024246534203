import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import ButtonTheme from "./ButtonTheme";

const SubmitModal = ({
  show,
  onSubmitClick,
  onCloseClick,
  loading = false,
  text,
  icon = null,
  description = null,
  children,
  title,
}) => {
  const { t } = useTranslation();
  return (
    <Modal fade={true} isOpen={show} toggle={onCloseClick} centered={true}>
      {title && <ModalHeader>{t(title)}</ModalHeader>}
      <ModalBody className="py-3 px-5">
        {children ? (
          children
        ) : (
          <div className="mt-2 text-center">
            {icon}
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              {text && <h4>{t(text)}</h4>}
              {description && <p className="text-muted mx-4 mb-0">{t(description)}</p>}
            </div>
          </div>
        )}
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <ButtonTheme
            id="delete-record"
            type="button"
            className="btn btn-primary w-md"
            onClick={onSubmitClick}
            loading={loading}
            disabled={loading}
          >
            {t("Submit")}
          </ButtonTheme>
          <ButtonTheme type="button" className="btn w-md btn-light" onClick={onCloseClick}>
            {t("Close")}
          </ButtonTheme>
        </div>
      </ModalBody>
    </Modal>
  );
};

SubmitModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};

export default withTranslation()(SubmitModal);
