import React, { useCallback, useRef, useState } from "react";
import { Card, CardBody, CardHeader, CardText, CardTitle, Container, FormGroup, Input, Label } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Col, Row } from "reactstrap";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import ModalContainer from "../../../Components/Common/ModalContainer";
import ButtonTheme from "../../../Components/Common/ButtonTheme";
import SelectTheme from "../../../Components/Common/SelectTheme";
import ViewTemplatePDF from "../../../Components/Common/ViewTemplatePDF";
import { CONFIG_SIZE, SIZE_TEMPLATE, TEMPLATE } from "../../../Components/constants/TemplatePrint/EnumTemplate";
import { useEffect } from "react";
import { historyAPI, locationsAPI } from "../../../helpers/resource_helper";
import { TEMPLATE_STRING } from "../../../Components/constants/TemplatePrint/TemplateString";
import useMemoizedSelector from "../../../Components/Hooks/useMemoizedSelector";
import { useDispatch } from "react-redux";
import { addPrintTemplate, getTemplatePrint, updatePrintTemplate } from "../../../store/actions";
import Loader from "../../../Components/Common/Loader";
import dayjs from "dayjs";
import SimpleBar from "simplebar-react";
import withRouter from "../../../Components/Common/withRouter";

const DATA_KEY_ADD_PRINT = {
  cua_hang: {
    title: "Thông tin cửa hàng",
    table: [
      [
        { description: "Logo cửa hàng", key: "store_logo_light" },
        { description: "Địa chỉ cửa hàng", key: "store_address" },
        { description: "Số điện thoại cửa hàng", key: "store_phone" },
      ],
      [
        { description: "bar code", key: "bar_code" },
        { description: "qr code", key: "qr_code" },
      ],
    ],
  },
  gio_hang: {
    title: "Thông tin giỏ hàng",
    table: [
      [
        { description: "STT", key: "order_line_items.line_stt" },
        { description: "Mã sản phẩm", key: "order_line_items.sku" },
        { description: "Tên sản phẩm", key: "order_line_items.variant_name" },
        { description: "Đơn vị sản phẩm", key: "order_line_items.line_unit" },
        { description: "Số lượng", key: "order_line_items.quantity" },
        { description: "Đơn giá", key: "order_line_items.unit_price" },
        { description: "Chiết khấu", key: "order_line_items.discount" },
        { description: "Thành tiền", key: "order_line_items.total" },
      ],
      [
        { description: "Thời gian tạo", key: "created_at" },
        { description: "Tổng số lượng", key: "order_total_quantity" },
        { description: "Tổng Tiền", key: "sub_total" },
        { description: "Tax", key: "tax" },
        { description: "Chiết khấu", key: "discount" },
        { description: "Phí giao hàng", key: "shipping_fee" },
        { description: "Khách phải trả", key: "sub_total" },
      ],
    ],
  },
  khach_hang: {
    title: "Thông tin khách hàng",
    table: [
      [
        { description: "Tên khách hàng", key: "customer.first_name" },
        { description: "Số điện thoại", key: "customer.phone" },
        { description: "Địa chỉ", key: "customer.billing_address.address1" },
      ],
    ],
  },
};

function FormPrint({ router: { t } }) {
  const [dataCKEditor, setDataCKEditor] = useState("");
  const [isOpenAddKey, setIsOpenAddKey] = useState(false);
  const [saveEditor, setSaveEditor] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [type, setType] = useState(TEMPLATE[[0]].id);
  const [size, setSize] = useState(SIZE_TEMPLATE[[0]].id);
  const [location, setLocation] = useState("");
  const [optionLocation, setOptionLocation] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [sizePrint, setSizePrint] = useState();
  const dispatch = useDispatch();
  const componentRef = useRef(null);
  const [isDefault, setIsDefault] = useState(false);
  const { templates, loading, dataShop, loadingAction } = useMemoizedSelector((state) => ({
    templates: state.TemplatePrint.templates,
    loading: state.TemplatePrint.loading,
    dataShop: state.ShopInfo.dataShop,
    loadingAction: state.ShopInfo.loadingAction,
  }));
  const [id, setId] = useState("");

  const handleFindTemplate = useCallback(
    (size, location, type) => {
      if (!size || !type) return "";
      const template = templates.find(
        (template) => template.size === size && template.location_id === location && template.template_type === type,
      );
      if (!template) {
        return { template: TEMPLATE_STRING[type][size] };
      }
      return template;
    },
    [templates],
  );
  const refEditor = useRef();

  const printIframe = () => {
    var pri = document.getElementById("edit").contentWindow;
    pri.print();
  };

  useEffect(() => {
    const getLocation = async () => {
      try {
        const res = await locationsAPI.list();
        const mapArrToOption = res.items.map((item) => ({ id: item.id, name: item.name }));
        setOptionLocation(mapArrToOption);
        setLocation(mapArrToOption[0].value);
      } catch (e) {
        console.error(e);
      }
    };
    getLocation();
  }, []);

  useEffect(() => {
    const getDataSelectPrint = async () => {
      const findTemplate = handleFindTemplate(size, location, type);
      if (findTemplate) {
        setDataCKEditor(findTemplate.template);
        setId(findTemplate.id);
      } else {
        setDataCKEditor("");
        setId("");
      }
      setSizePrint(CONFIG_SIZE[size]);
    };
    getDataSelectPrint();
  }, [templates, location, size, type, handleFindTemplate]);

  useEffect(() => {
    dispatch(getTemplatePrint());
  }, [dispatch]);

  const handleSaveTemplatePrint = async () => {
    const findTemplate = handleFindTemplate(size, location, type);
    const dataTemplatePrint = {
      ...findTemplate,
      template_type: type,
      location_id: location,
      size: size,
      template: dataCKEditor,
      is_default: isDefault,
    };
    if (findTemplate?.id) {
      dispatch(updatePrintTemplate(dataTemplatePrint));
    } else {
      dispatch(addPrintTemplate(dataTemplatePrint));
    }
    setIsDefault(false);
  };
  const KEY_DEFAULT = {
    ...dataShop.setting_value,
    returns: null,
    priority: null,
    shipping_fee: "0",
    other_fees: null,
    external_id: "8b12cac7-7e74-47c1-8db5-6d5776b77fca",
    number: "OO00000040",
    company_id: "9e61d187-426a-45ec-914d-7aea8ca7d42d",
    total: "62400",
    store: null,
    source: null,
    tax_rate: "0",
    voucher_id: "28845560-ee40-4818-aeb7-c07801f23179",
    order_line_items: [
      {
        variant_id: "4fb48978-75f3-4aba-9c43-d0276fd2dad2",
        variant_name: "Product test 2  - 2",
        sku: "PRODUCT-2-2",
        id: "b1831de6-6cb6-4756-9526-7dcf5fea8ed2",
        sale_price: "20000",
        price: "80000",
        brand: {
          id: "4cf87448-505a-42f8-819b-deb2fa8cccec",
          name: "Band test",
        },
        image_url:
          "https://noliwms.s3.amazonaws.com/9e61d187-426a-45ec-914d-7aea8ca7d42d/images/products/f29d9806-6f25-41c0-bcf8-4095317e3497.jpg",
        quantity: "4",
        name: "Product test 2  - 2",
        unit_price: "20000",
        note: null,
        product_id: "773bcdc1-653b-4a04-82ab-e34623afedcd",
        location: {
          id: "1c06a0e3-b292-4a4c-9068-1c75075c18cd",
          name: "Cửa hàng Hồ Ba Mẫu",
        },
        category: {
          id: "41289cd8-79ae-40a6-a82b-0447ae12dd98",
          name: "Cate test",
        },
        discount: "0",
      },
    ],
    sub_total: "80000",
    external_link: null,
    updated_at: "2024-01-21T10:33:07.880134+00:00",
    status: "AWAIT_PACKING",
    tax: "0",
    payment_histories: null,
    id: "8b12cac7-7e74-47c1-8db5-6d5776b77fca",
    tags: "",
    cancels: null,
    sub_voucher_total: "17600",
    staff_id: "906ed4d6-26f9-4056-8f3e-ae00a37c3edb",
    note: "",
    created_at: "2024-01-20T08:07:03.092401+00:00",
    shipping_address: {
      default_shipping: false,
      city: "Hà Nội",
      last_name: "Le",
      first_name: "Nguyen",
      default_billing: false,
      address1: "	Số 41 ngõ 63 Phố Ô Đồng Lầm, đường ven Hồ Ba Mẫu, Phương Liên, Đống Đa, Hà Nội",
      country_code: "",
      phone: "0988979251",
      district: "Đống Đa",
      address2: null,
      name: null,
      country: "",
      ward: "Phương Liên",
      province_code: null,
      zip: null,
      province: "",
    },
    customer: {
      last_name: "Le",
      id: "a4d36113-b816-4f7a-a888-1987608300a1",
      first_name: "Nguyen",
      tags: null,
      phone: "0988979251",
      account: null,
      addresses: [
        {
          default_shipping: false,
          city: "Hà Nội",
          last_name: "Le",
          first_name: "Nguyen",
          default_billing: false,
          address1: "	Số 41 ngõ 63 Phố Ô Đồng Lầm, đường ven Hồ Ba Mẫu, Phương Liên, Đống Đa, Hà Nội",
          country_code: "",
          phone: "0988979251",
          district: "Đống Đa",
          address2: null,
          name: null,
          country: "",
          ward: "Phương Liên",
          province_code: null,
          zip: null,
          province: "",
        },
      ],
      email: "nolihandmade@gmail.com",
      company_name: "",
      billing_address: {
        default_shipping: false,
        city: "Hà Nội",
        last_name: "Le",
        first_name: "Nguyen",
        default_billing: false,
        address1: "	Số 41 ngõ 63 Phố Ô Đồng Lầm, đường ven Hồ Ba Mẫu, Phương Liên, Đống Đa, Hà Nội",
        country_code: "",
        phone: "0988979251",
        district: "Đống Đa",
        address2: null,
        name: null,
        country: "",
        ward: "Phương Liên",
        province_code: null,
        zip: null,
        province: "",
      },
    },
    billing_address: {
      default_shipping: false,
      city: "Hà Nội",
      last_name: "Le",
      first_name: "Nguyen",
      default_billing: false,
      address1: "	Số 41 ngõ 63 Phố Ô Đồng Lầm, đường ven Hồ Ba Mẫu, Phương Liên, Đống Đa, Hà Nội",
      country_code: "",
      phone: "0988979251",
      district: "Đống Đa",
      address2: null,
      name: null,
      country: "",
      ward: "Phương Liên",
      province_code: null,
      zip: null,
      province: "",
    },
  };

  if (loading)
    return (
      <div className="page-content">
        <Container fluid>
          <Loader />
        </Container>
      </div>
    );

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("Print template")} pageTitle="Settings" navigateTo="/settings" isBack={true} />
        <ModalContainer
          isOpen={isOpenAddKey}
          toggle={() => {
            setIsOpenAddKey(false);
          }}
          scrollable={true}
          size="xl"
          title={
            <>
              <h4>{t("List keys")}</h4>
              <p
                style={{
                  fontStyle: "oblique",
                  fontSize: "13px",
                  margin: 0,
                }}
              >
                {t(
                  "The selected keywords will be added at the mouse cursor position on the Edit Print Template screen.",
                )}
              </p>
            </>
          }
        >
          {Object.keys(DATA_KEY_ADD_PRINT).map((item, index) => (
            <div key={index} className="mt-4">
              <h5 className="fs-19 text-center fw-medium">{DATA_KEY_ADD_PRINT[item].title}</h5>
              <Row>
                {DATA_KEY_ADD_PRINT[item].table.map((table, index) => (
                  <Col lg={6} key={index}>
                    <table className="table-key">
                      <thead>
                        <tr>
                          <th style={{ width: "50%" }}>{t("Interpretation")}</th>
                          <th style={{ width: "40%" }}>{t("Code")}</th>
                          <th style={{ width: "10%" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {table.map((k, i) => (
                          <tr key={i}>
                            <td>{k.description}</td>
                            <td>{`{${k.key}}`}</td>
                            <td>
                              <ButtonTheme
                                className="btn btn-outline-primary "
                                onClick={() => {
                                  const indexSelect = saveEditor.model.document.selection.getFirstPosition();
                                  if (indexSelect) {
                                    saveEditor.model.change((writer) => {
                                      writer.insertText(`{${k.key}}`, indexSelect);
                                    });
                                  }
                                  setIsOpenAddKey(false);
                                }}
                              >
                                {t("Select")}
                              </ButtonTheme>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                ))}
              </Row>
            </div>
          ))}
        </ModalContainer>

        <Card>
          <CardBody>
            <div className="align-items-center d-flex justify-content-between mb-2">
              <div
                className="d-flex"
                style={{
                  flex: 3,
                  gap: "20px",
                }}
              >
                <SelectTheme
                  isForm={false}
                  label={"Print template"}
                  classWrapper="flex-1"
                  options={TEMPLATE}
                  value={type}
                  onChange={(option) => {
                    setType(option.value);
                  }}
                />
                <SelectTheme
                  isForm={false}
                  label={"Branch"}
                  classWrapper="flex-1"
                  value={location}
                  onChange={(option) => {
                    setLocation(option.value);
                  }}
                  options={optionLocation}
                />
                <SelectTheme
                  isForm={false}
                  label={"Size"}
                  value={size}
                  classWrapper="flex-1"
                  options={SIZE_TEMPLATE}
                  onChange={(option) => {
                    setSize(option.value);
                  }}
                />
              </div>
              <div className="d-flex justify-content-end flex-1">
                {isEdit ? (
                  <FormGroup check className="fs-15 form-check-primary">
                    <Input
                      type="checkbox"
                      id="Check"
                      checked={isDefault}
                      onChange={(check) => {
                        setIsDefault(check.target.checked);
                      }}
                    />
                    <Label check for="Check">
                      {t("Set as default print size")}
                    </Label>
                  </FormGroup>
                ) : (
                  !showHistory && (
                    <ButtonTheme
                      className="btn btn-outline-info w-md"
                      onClick={() => {
                        setShowHistory(true);
                      }}
                    >
                      {t("View edit history")}
                    </ButtonTheme>
                  )
                )}
              </div>
            </div>
            <div className="d-flex">
              <Row className="flex-1">
                {isEdit && (
                  <Col lg={6}>
                    <div className="hstack gap-2 mb-2">
                      <ButtonTheme
                        className="btn btn-outline-success"
                        onClick={() => {
                          setIsOpenAddKey(true);
                        }}
                        style={{ padding: "4px 10px" }}
                      >
                        {t("Add keys")}
                      </ButtonTheme>
                      <ButtonTheme
                        className="btn btn-primary"
                        onClick={() => {
                          let text = window.confirm(
                            t(
                              "This action will update your print template to the system's default template. Are you sure?",
                            ),
                          );
                          if (text === true) {
                            const template = TEMPLATE_STRING[type][size];
                            setDataCKEditor(template);
                            // handleSaveTemplatePrint(template);
                          }
                        }}
                        style={{ padding: "4px 10px" }}
                      >
                        {t("Use existing template")}
                      </ButtonTheme>
                    </div>
                    <div style={{ border: "1px solid #d1d1d1", backgroundColor: "#fff" }}>
                      <CKEditor
                        ref={refEditor}
                        editor={Editor}
                        data={dataCKEditor}
                        onReady={(editor) => {
                          editor.ui
                            .getEditableElement()
                            .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
                        }}
                        onChange={(event, editor) => {
                          let data = editor?.getData();
                          setDataCKEditor(data);
                        }}
                        onBlur={(event, editor) => {
                          setSaveEditor(editor);
                        }}
                      />
                    </div>
                  </Col>
                )}
                <Col lg={isEdit ? 6 : 12} className={`${!isEdit ? "justify-content-end" : "justify-content-between"}`}>
                  <div
                    className={`d-flex ${
                      !isEdit ? "justify-content-end" : "justify-content-between"
                    }  align-items-center mb-2`}
                  >
                    {isEdit && <h5 className="title-view-print">{t("Preview")}</h5>}
                    <div>
                      <ButtonTheme className="btn btn-outline-danger" onClick={printIframe}>
                        {t("Print Preview")}
                      </ButtonTheme>

                      {!isEdit && (
                        <ButtonTheme
                          className="btn btn-outline-success mx-2"
                          onClick={() => {
                            setIsEdit(true);
                            setShowHistory(false);
                          }}
                        >
                          {t("Edit")}
                        </ButtonTheme>
                      )}
                    </div>
                  </div>
                  <ViewTemplatePDF
                    componentRef={componentRef}
                    dataTemplate={dataCKEditor}
                    dataForm={KEY_DEFAULT}
                    sizePrint={sizePrint}
                    isEdit={isEdit}
                  />
                </Col>
              </Row>
              {showHistory && (
                <HistoryPrintTemplate
                  toggle={() => {
                    setShowHistory(false);
                  }}
                  onChangeDataPrint={(dataString) => {
                    setDataCKEditor(dataString);
                  }}
                  id={id}
                  t={t}
                />
              )}
            </div>
            {isEdit && (
              <div className="text-end mt-3">
                <ButtonTheme
                  className="btn btn-outline-danger"
                  onClick={() => {
                    setIsEdit(false);
                  }}
                >
                  {t("Close")}
                </ButtonTheme>
                <ButtonTheme
                  className="btn btn-primary mx-3"
                  onClick={handleSaveTemplatePrint}
                  loading={loadingAction}
                  loadShowText={true}
                >
                  {t("Save")}
                </ButtonTheme>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}

const HistoryPrintTemplate = ({ toggle, id, onChangeDataPrint, t }) => {
  const [loading, setLoading] = useState(false);
  const [listHistory, setListHistory] = useState([]);
  const [active, setActive] = useState("");
  useEffect(() => {
    const getListPrintTemplate = async () => {
      setLoading(true);
      const list = await historyAPI.get({
        id,
      });
      setListHistory(list.Items.reverse());
      setLoading(false);
    };
    if (id) getListPrintTemplate();
  }, [id]);
  return (
    <div style={{ width: 270 }} className="history">
      <Card className="h-100">
        <CardHeader>
          <button type="button" className="btn-close float-end fs-11" aria-label="Close" onClick={toggle}></button>
          <div>
            <h6 className="card-title mb-0">{t("Print Template Edit History")}</h6>
            <p
              style={{
                fontSize: "12px",
                fontStyle: "oblique",
              }}
            >
              {t("The list displays the most recent 100 edit sessions.")}
            </p>
          </div>
        </CardHeader>
        <CardBody style={{ padding: 0, height: "200px" }}>
          {loading ? (
            <Loader />
          ) : (
            <>
              <SimpleBar id="scrollbar" className="h-100">
                {listHistory.map((history, index) => (
                  <Card
                    className="cursor-pointer m-0 border-button px-1"
                    color={`${active === index ? `primary` : ``} `}
                    key={index}
                    style={{ borderBottom: "1px solid #00000020" }}
                    onClick={() => {
                      onChangeDataPrint(history.data.template || "");
                      setActive(index);
                    }}
                  >
                    <CardBody>
                      <CardTitle tag="h6">{dayjs(history.version * 1000).format("DD/MM/YYYY, HH:mm:ss")}</CardTitle>
                      <CardText>{history.user.name_staff}</CardText>
                    </CardBody>
                  </Card>
                ))}
              </SimpleBar>
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default withRouter(FormPrint);
