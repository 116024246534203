const FullNameRender = ({ first_name, last_name }) => {
  let full_name = "";
  if (!!first_name) {
    full_name += first_name;
  }
  if (!!last_name) {
    if (!!first_name) {
      full_name = full_name + " ";
    }
    full_name = full_name + last_name;
  }
  return <span>{!!first_name || !!last_name ? full_name : "---"}</span>;
};
export default FullNameRender;
