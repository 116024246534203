import { useDispatch } from "react-redux";
import { Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import useMemoizedSelector from "../Hooks/useMemoizedSelector";
import { Fragment, useEffect, useState } from "react";
import { EffectiveProduct } from "../constants/common";
import { listBrand, listCategory, listProduct, listVariant } from "../../store/actions";
import SearchInput from "./SearchInput";
import { useDebounce } from "../Hooks/UseDebounce";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import Loader from "./Loader";
import ButtonTheme from "./ButtonTheme";
import { deepCopy, formatVNDCurrency } from "../../helpers/format_helper";
import TagName from "../../pages/Ecommerce/Promotion/Discounts/Components/TagName";
import InfiniteScroll from "react-infinite-scroll-component";

const DiscountInfoSearchProduct = ({
  for_all_items = false,
  type = "",
  item_type = EffectiveProduct.PRODUCTS,
  index,
  data,
  onChangeConditionLine,
  isEdit = true,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    products,
    productsLoading,
    productsAction,
    productsLimit,
    productsSuccess,
    productsPage,
    productsTotal,
    productsMessage,
  } = useMemoizedSelector((state) => ({
    products: state.Products.products,
    productsMessage: state.Products.message,
    productsAction: state.Products.action,
    productsLimit: state.Products.limit,
    productsPage: state.Products.page,
    productsTotal: state.Products.total,
    productsLoading: state.Products.loading,
    productsSuccess: state.Products.success,
    productsError: state.Products.error,
  }));

  const {
    variants,
    variantsLoading,
    variantsAction,
    variantsLimit,
    variantsSuccess,
    variantsPage,
    variantsTotal,
    variantsMessage,
  } = useMemoizedSelector((state) => ({
    variants: state.Variants.variants,
    variantsMessage: state.Variants.message,
    variantsAction: state.Variants.action,
    variantsLimit: state.Variants.limit,
    variantsPage: state.Variants.page,
    variantsTotal: state.Variants.total,
    variantsLoading: state.Variants.loading,
    variantsSuccess: state.Variants.success,
    variantsError: state.Variants.error,
  }));

  const {
    categories,
    categoriesLoading,
    categoriesAction,
    categoriesLimit,
    categoriesSuccess,
    categoriesPage,
    categoriesTotal,
    categoriesMessage,
  } = useMemoizedSelector((state) => ({
    categories: state.Categories.categories,
    categoriesMessage: state.Categories.message,
    categoriesAction: state.Categories.action,
    categoriesLimit: state.Categories.limit,
    categoriesPage: state.Categories.page,
    categoriesTotal: state.Categories.total,
    categoriesLoading: state.Categories.loading,
    categoriesSuccess: state.Categories.success,
    categoriesError: state.Categories.error,
  }));

  const { brands, brandsLoading, brandsAction, brandsLimit, brandsSuccess, brandsPage, brandsTotal, brandsMessage } =
    useMemoizedSelector((state) => ({
      brands: state.Brands.brands,
      brandsMessage: state.Brands.message,
      brandsAction: state.Brands.action,
      brandsLimit: state.Brands.limit,
      brandsPage: state.Brands.page,
      brandsTotal: state.Brands.total,
      brandsLoading: state.Brands.loading,
      brandsSuccess: state.Brands.success,
      brandsError: state.Brands.error,
    }));

  let dataKey =
    item_type === EffectiveProduct.PRODUCTS
      ? "products"
      : item_type === EffectiveProduct.VARIANTS
      ? "variants"
      : item_type === EffectiveProduct.CATEGORIES
      ? "categories"
      : item_type === EffectiveProduct.BRANDS
      ? "brands"
      : null;
  const [optionList, setOptionList] = useState([]);
  const [checkList, setCheckList] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [newQuery, setNewQuery] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const debouncedInputValue = useDebounce(searchValue, 1000);
  const handleChangeValue = (data) => {
    setSearchValue(data);
  };
  const resetState = () => {
    setOptionList([]);
    setCheckList([]);
    setIsCheckAll(false);
    setSearchValue("");
  };
  const handleCheckList = (selectedItem) => {
    const hasChecked = checkList.find((item) => item.id === selectedItem.id);
    if (hasChecked) {
      setCheckList(checkList.filter((item) => item.id !== selectedItem.id));
    } else {
      const { company_id, created_at, updated_at, ...payload } = selectedItem;
      setCheckList([...checkList, payload]);
    }
  };
  const handleCheckAll = (checked) => {
    setIsCheckAll(checked);
    setCheckList([]);
  };
  const toggle = () => {
    setIsOpen(false);
  };

  const toggleOn = () => {
    isEdit && setIsOpen(true);
  };

  const onLoadCheckList = () => {
    if (type === "purchase") {
      setCheckList(data?.["purchase_" + dataKey] || []);
    } else if (type === "sale_offs") {
      setCheckList(data?.["sale_offs_" + dataKey] || []);
    } else {
      setCheckList(data?.[dataKey] || []);
    }
  };

  const handleDelete = (idx) => {
    const newData = deepCopy(data);
    const key = (type === "purchase" ? "purchase_" : type === "sale_offs" ? "sale_offs_" : "") + dataKey;
    if (idx) {
      newData[key].splice(idx, 1);
    } else {
      newData[key] = [];
    }
    if (newData[key].length === 0) {
      newData[key] = null;
    }
    newData[
      "for_all_" + (type === "purchase" ? "purchase_product" : type === "sale_offs" ? "sale_offs_product" : "items")
    ] = false;
    onChangeConditionLine(index, newData);
  };

  const handleDeleteById = (item_id, product_id) => {
    const newData = deepCopy(data);
    const key = (type === "purchase" ? "purchase_" : type === "sale_offs" ? "sale_offs_" : "") + dataKey;
    if (product_id) {
      newData[key] = data[key].filter((item) => item.product_id !== product_id);
    } else {
      newData[key] = data[key].filter((item) => item.id !== item_id);
    }
    if (newData[key].length === 0) {
      newData[key] = null;
    }
    newData[
      "for_all_" + (type === "purchase" ? "purchase_product" : type === "sale_offs" ? "sale_offs_product" : "items")
    ] = false;
    onChangeConditionLine(index, newData);
  };

  const handleConfirm = () => {
    const payload = {};
    if (type === "purchase") {
      payload.for_all_purchase_product = isCheckAll;
      payload["purchase_" + dataKey] = checkList.length > 0 ? checkList : null;
    } else if (type === "sale_offs") {
      payload.for_all_sale_offs_product = isCheckAll;
      payload["sale_offs_" + dataKey] = checkList.length > 0 ? checkList : null;
    } else {
      payload.for_all_items = isCheckAll;
      payload[dataKey] = checkList.length > 0 ? checkList : null;
    }
    onChangeConditionLine(index, payload);
    toggle();
  };

  const getDataScroll = async () => {
    setNewQuery(false);
    const payload = { query: debouncedInputValue };
    if (item_type === EffectiveProduct.PRODUCTS) {
      payload.page = Number(productsPage) + 1;
      payload.limit = Number(productsLimit);
      dispatch(listProduct(payload));
    }
    if (item_type === EffectiveProduct.VARIANTS) {
      payload.page = Number(variantsPage) + 1;
      payload.limit = Number(variantsLimit);
      dispatch(listVariant(payload));
    }
    if (item_type === EffectiveProduct.CATEGORIES) {
      payload.page = Number(categoriesPage) + 1;
      payload.limit = Number(categoriesLimit);
      dispatch(listCategory(payload));
    }
    if (item_type === EffectiveProduct.BRANDS) {
      payload.page = Number(brandsPage) + 1;
      payload.limit = Number(brandsLimit);
      dispatch(listBrand(payload));
    }
  };

  useEffect(() => {
    if (!productsLoading || !variantsLoading || !categoriesLoading || !brandsLoading) {
      let newOptions = [];
      let newLimit = 0;
      let newPage = 0;
      let newTotal = 0;
      if (item_type === EffectiveProduct.PRODUCTS) {
        newOptions = products;
        newLimit = productsLimit;
        newPage = productsPage;
        newTotal = productsTotal;
      }
      if (item_type === EffectiveProduct.VARIANTS) {
        newOptions = variants;
        newLimit = variantsLimit;
        newPage = variantsPage;
        newTotal = variantsTotal;
      }
      if (item_type === EffectiveProduct.CATEGORIES) {
        newOptions = categories;
        newLimit = categoriesLimit;
        newPage = categoriesPage;
        newTotal = categoriesTotal;
      }
      if (item_type === EffectiveProduct.BRANDS) {
        newOptions = brands;
        newLimit = brandsLimit;
        newPage = brandsPage;
        newTotal = brandsTotal;
      }
      if (Number(newLimit) * (Number(newPage) + 1) < newTotal) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
      newQuery ? setOptionList(newOptions) : setOptionList([...optionList, ...newOptions]);
    }
  }, [newQuery, productsLoading, variantsLoading, categoriesLoading, brandsLoading]);

  useEffect(() => {
    if (isOpen) {
      setNewQuery(true);
      if (item_type === EffectiveProduct.PRODUCTS) {
        dispatch(listProduct({ query: debouncedInputValue }));
      }
      if (item_type === EffectiveProduct.VARIANTS) {
        dispatch(listVariant({ query: debouncedInputValue }));
      }
      if (item_type === EffectiveProduct.CATEGORIES) {
        dispatch(listCategory({ query: debouncedInputValue }));
      }
      if (item_type === EffectiveProduct.BRANDS) {
        dispatch(listBrand({ query: debouncedInputValue }));
      }
      onLoadCheckList();
      setIsCheckAll(!!for_all_items);
    } else {
      resetState();
    }
  }, [debouncedInputValue, isOpen]);

  return (
    <Fragment>
      <div className="d-md-block p-0">
        <div className="position-relative">
          <div className={`form-control border d-flex flex-wrap gap-2`}>
            {for_all_items ? (
              <TagName
                for_all_items={
                  type === "purchase"
                    ? data?.for_all_purchase_product
                    : type === "sale_offs"
                    ? data?.for_all_sale_offs_product
                    : data?.for_all_items
                }
                item_type={
                  type === "purchase"
                    ? data?.purchase_product_type
                    : type === "sale_offs"
                    ? data?.sale_offs_product_type
                    : data?.item_type
                }
                onDelete={handleDelete}
                onDeleteById={handleDeleteById}
                isEdit={isEdit}
              />
            ) : (
              <Fragment>
                <TagName
                  for_all_items={
                    type === "purchase"
                      ? data?.for_all_purchase_product
                      : type === "sale_offs"
                      ? data?.for_all_sale_offs_product
                      : data?.for_all_items
                  }
                  item_type={
                    type === "purchase"
                      ? data?.purchase_product_type
                      : type === "sale_offs"
                      ? data?.sale_offs_product_type
                      : data?.item_type
                  }
                  listItems={
                    type === "purchase"
                      ? data?.["purchase_" + dataKey]
                      : type === "sale_offs"
                      ? data?.["sale_offs_" + dataKey]
                      : data?.[dataKey]
                  }
                  onDeleteById={handleDeleteById}
                  onDelete={handleDelete}
                  isEdit={isEdit}
                />
              </Fragment>
            )}
            {isEdit && (
              <div className={`text-muted align-self-center w-100 cursor-pointer`} onClick={toggleOn}>
                {t(
                  item_type === EffectiveProduct.PRODUCTS
                    ? "Search product by name, sku"
                    : item_type === EffectiveProduct.VARIANTS
                    ? "Search variant by name, sku"
                    : item_type === EffectiveProduct.CATEGORIES
                    ? "Search by category name"
                    : item_type === EffectiveProduct.BRANDS
                    ? "Search by brand name"
                    : "",
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal isOpen={isOpen} toggle={toggle} size="lg" scrollable>
        <ModalHeader toggle={toggle}>Choose {item_type}</ModalHeader>
        <ModalBody id="infinity-scroll-modal">
          <SearchInput
            toggleOn={() => {
              setIsOpen(true);
            }}
            searchValue={searchValue}
            onChangeValue={handleChangeValue}
            placeholder={
              item_type === EffectiveProduct.PRODUCTS
                ? "Search product by name, sku"
                : item_type === EffectiveProduct.VARIANTS
                ? "Search variant by name, sku"
                : item_type === EffectiveProduct.CATEGORIES
                ? "Search by category name"
                : item_type === EffectiveProduct.BRANDS
                ? "Search by brand name"
                : ""
            }
          />
          <div className="border-bottom">
            <div className="form-check form-check-primary py-2">
              <Input
                className="form-check-input"
                type="checkbox"
                id={`check-all-${item_type}-${index}`}
                value={""}
                onChange={(e) => {
                  handleCheckAll(e.target.checked);
                }}
                checked={isCheckAll}
              />
              <Label className="form-check-label w-100" for={`check-all-${item_type}-${index}`}>
                {t("Check All")}
              </Label>
            </div>
          </div>
          {optionList.length === 0 && (variantsLoading || productsLoading || brandsLoading || categoriesLoading) ? (
            <Loader />
          ) : (
            <InfiniteScroll
              dataLength={optionList.length}
              next={() => {
                getDataScroll();
              }}
              hasMore={hasMore}
              loader={<Loader />}
              scrollableTarget="infinity-scroll-modal"
            >
              {optionList.map((item, key) => {
                const available = item.inventories
                  ? item.inventories.reduce((total, item) => total + item.available, 0) || 0
                  : 0;
                const on_hand = item.inventories
                  ? item.inventories.reduce((total, item) => total + item.on_hand, 0) || 0
                  : 0;
                const image = item?.images?.[0]?.url || item?.image?.url;
                return (
                  <div key={key} className="border-bottom">
                    <div className="form-check form-check-primary hstack gap-2">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id={item.id}
                        value={item.id}
                        onChange={(e) => {
                          handleCheckList(item);
                        }}
                        checked={checkList.findIndex((c) => c?.id === item?.id) > -1}
                        disabled={isCheckAll}
                      />
                      <Label className="form-check-label opacity-100 w-100" for={item.id}>
                        <div className="hstack py-2">
                          <div className="d-flex align-items-center">
                            <img src={image} className="me-3 avatar-xs " alt="" />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="fw-semibold">{item.name}</h5>
                            <div className="fs-13 text-muted">
                              <p className="mb-1">{item.sku}</p>
                            </div>
                          </div>
                          {(item_type === EffectiveProduct.PRODUCTS || item_type === EffectiveProduct.VARIANTS) && (
                            <div className="px-2 flex-shrink-0">
                              <div className="fs-16 text-primary text-end">
                                {formatVNDCurrency(item.prices?.[0]?.price)}
                              </div>
                              {item_type === EffectiveProduct.VARIANTS && (
                                <div>
                                  Available:{" "}
                                  <span className={available > 0 ? "text-primary" : "text-danger"}>{available}</span> |
                                  On hand:{" "}
                                  <span className={on_hand > 0 ? "text-primary" : "text-danger"}>{on_hand}</span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </Label>
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          )}
        </ModalBody>
        <ModalFooter>
          <ButtonTheme className={"btn btn-outline-primary w-md"} onClick={toggle}>
            Close
          </ButtonTheme>
          <ButtonTheme className={"btn btn-primary w-md"} onClick={handleConfirm}>
            Confirm
          </ButtonTheme>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default DiscountInfoSearchProduct;
