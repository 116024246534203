import { useEffect } from "react";
import { useDispatch } from "react-redux";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation, withTranslation } from "react-i18next";
import { Form, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { Actions } from "../../../../Components/constants/common";

import { addNewPriceGroup, updatePriceGroup } from "../../../../store/actions";

const EditAddPriceGroupModal = ({ show, toggle, isEdit, priceGroup }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { priceGroupsLoading, priceGroupsSuccess, priceGroupsAction, priceGroupsError } = useMemoizedSelector(
    (state) => ({
      priceGroupsLoading: state.PriceGroups.loading,
      priceGroupsSuccess: state.PriceGroups.success,
      priceGroupsAction: state.PriceGroups.action,
      priceGroupsError: state.PriceGroups.error,
    }),
  );

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (priceGroup && priceGroup.name) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Please Enter Price Group Name")),
    }),
    onSubmit: (values) => {
      const payload = {
        name: values.name,
        company_id: values.company_id,
      };
      if (isEdit) {
        payload.id = priceGroup.id;
        dispatch(updatePriceGroup(payload));
      } else {
        dispatch(addNewPriceGroup(payload));
      }
    },
  });
  const handleClose = () => {
    if (!priceGroupsLoading) {
      toggle();
      validation.resetForm();
    }
  };

  // toggle after loading
  useEffect(() => {
    !priceGroupsLoading && priceGroupsSuccess && show && handleClose();
  }, [priceGroupsLoading, priceGroupsSuccess]);

  return (
    <Modal fade={true} isOpen={show} toggle={handleClose} centered={true}>
      <ModalHeader className="bg-light p-3">{t(isEdit ? t("Edit Price Group") : t("New Price Group"))}</ModalHeader>
      <Form
        className="tablelist-form"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <ModalBody>
          <div className="mb-3">
            <Label htmlFor="name-field" className="form-label">
              {t("Price group name")}
            </Label>
            <Input
              name="name"
              id="name-field"
              className="form-control"
              placeholder={t("Enter Price Group Name")}
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.name || ""}
              invalid={validation.touched.name && validation.errors.name ? true : false}
            />
            {validation.touched.name && validation.errors.name ? (
              <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <ButtonTheme type="button" className="btn btn-light w-sm" onClick={handleClose}>
              {t("Close")}
            </ButtonTheme>
            <ButtonTheme
              type="submit"
              className="btn btn-primary w-sm"
              loading={
                priceGroupsLoading && (priceGroupsAction === Actions.UPDATE || priceGroupsAction === Actions.CREATE)
              }
              disabled={
                priceGroupsLoading && (priceGroupsAction === Actions.UPDATE || priceGroupsAction === Actions.CREATE)
              }
            >
              {t(isEdit ? "Update" : "Add")}
            </ButtonTheme>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withTranslation()(EditAddPriceGroupModal);
