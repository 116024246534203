import { Modal, Upload, Image, message } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { ErrorMessage, useField } from "formik";
import { FormFeedback, Label } from "reactstrap";
import { withTranslation } from "react-i18next";
import { uploadFile, uploadImageAPI } from "../../helpers/service_helper";
import ModalCommon from "./ModalCommon/ModalCommon";
import cls from "classnames";

function UploadTheme(props) {
  const { name, label, t } = props;
  const [field, meta, helpers] = useField(name);
  const showError = meta.error && meta.touched;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (field.value) {
      setFileList([
        {
          uid: "-1",
          status: "done",
          url: field.value.url,
        },
      ]);
    } else {
      setFileList([]);
    }
  }, [field.value]);

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });


  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList?.[0]?.response?.url) {
      helpers.setValue({
        url: newFileList?.[0]?.response?.url,
        id: newFileList?.[0]?.response?.id,
        name: newFileList?.[0]?.response?.name,
      });
    }
    if (newFileList.length === 0) {
      helpers.setValue(null);
    }
  };

  const beforeUpload = (file) => {
    const isPNG = file.type === 'image/png';
    if (!isPNG) {
      message.error(`${file.name} is not a png file`);
    }
    return isPNG || Upload.LIST_IGNORE;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const customRequest = async ({
    action,
    data,
    file,
    filename,
    headers,
    onError,
    onProgress,
    onSuccess,
    withCredentials,
  }) => {
    const payload = {
      name: file.name,
      image: await getBase64(file),
      prefix: "media",
    };
    try {
      const response = await uploadImageAPI(payload);
      onSuccess(response, file);
    } catch (error) {
      onError();
    }
    return {
      abort() {
        console.log("upload progress is aborted.");
      },
    };
  };

  return (
    <div>
      {label && <Label>{t(label)}</Label>}
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        // beforeUpload={beforeUpload}
        customRequest={customRequest}
        defaultFileList={field.value ? fileList : null}
      >
        {fileList.length === 1 ? null : uploadButton}
      </Upload>
      {showError && (
        <div className="position-absolute fs-10" style={{ color: "#f06548" }}>
          {meta.error}
        </div>
      )}
        <Image
          alt="example"
          style={{ width: "100%" }}
          src={previewImage}
          className={cls("tw-hidden")}
          preview={{
            visible: previewOpen,
            onVisibleChange: setPreviewOpen,
            zIndex: 10000
          }}
        />
    </div>
  );
}
export default withTranslation()(UploadTheme);
