import { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";

import { useFormik } from "formik";
import * as Yup from "yup";
import { Card, CardBody, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";

import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import ModalContainer from "../../../../Components/Common/ModalContainer";
import UploadImage from "../../../../Components/Common/UploadImage";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";

import { listAppStore } from "../../../../store/actions";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../../Components/constants/common";
import LottieIcon from "../../../../Components/Common/LottieIcon";

const AddEditStoreModal = ({ store = null, isOpen, toggle, t }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(listAppStore({ limit: 20 }));
  }, [dispatch]);

  const {
    appStores,
    appStoresLoading,
    appStoresMessage,
    appStoresSuccess,
    appStoresError,
    appStoresLimit,
    appStoresPage,
    appStoresTotal,
  } = useMemoizedSelector((state) => ({
    appStores: state.Stores.appStores,
    appStoresLoading: state.Stores.loading,
    appStoresSuccess: state.Stores.success,
    appStoresError: state.Stores.error,
    appStoresMessage: state.Stores.message,
    appStoresLimit: state.Stores.limit,
    appStoresTotal: state.Stores.total,
    appStoresPage: state.Stores.page,
  }));

  return (
    <Fragment>
      <ModalContainer
        isOpen={isOpen}
        toggle={toggle}
        title={t(store ? "Edit store" : "Add new store")}
        size="lg"
        centered={true}
        actionCustom={
          <>
            <ButtonTheme type="button" className="btn btn-light w-sm" onClick={toggle}>
              {t("Close")}
            </ButtonTheme>
          </>
        }
      >
        <Row>
          {appStores.length === 0 ? (
            !appStoresLoading ? (
              <div className="py-4 mt-4 text-center">
                <LottieIcon src="https://cdn.lordicon.com/msoeawqm.json" style={{ width: "72px", height: "72px" }} />
                <h5 className="mt-4">{t("Sorry! No Result Found")}</h5>
              </div>
            ) : (
              new Array(5).fill({}).map((item, index) => (
                <Col key={index} className="mb-3" lg={3}>
                  <Card className="explore-box">
                    <CardBody>
                      <div className="explore-place-bid-img overflow-hidden rounded text-center skeleton">
                        <img
                          src={item.image}
                          alt=""
                          className="img-fluid explore-img"
                          style={{ objectFit: "contain", height: "120px" }}
                        />
                      </div>
                      <div className="mt-3 fs-15 text-center " /* style={{ minHeight: "40px" }} */>
                        <p className="mb-1 mt-3 fs-15 skeleton-box skeleton-text"></p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))
            )
          ) : (
            appStores.map((item, key) => (
              <Col key={key} className="mb-3" lg={3}>
                <Card className="explore-box">
                  <CardBody>
                    <div className="explore-place-bid-img overflow-hidden rounded text-center">
                      <img
                        src={item.image}
                        alt=""
                        className="img-fluid explore-img"
                        style={{ objectFit: "contain", height: "120px" }}
                      />
                      <div className="bg-overlay"></div>
                      <div className="place-bid-btn">
                        <div className="hstack gap-2">
                          <ButtonTheme
                            onClick={() => {
                              navigate(`/stores/${item.id}`);
                            }}
                            className="btn btn-primary w-md"
                            type="button"
                          >
                            {t("View")}
                          </ButtonTheme>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 fs-15 text-center " /* style={{ minHeight: "40px" }} */>
                      <h6 className="text-primary">{t(item?.name || "---")}</h6>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))
          )}
        </Row>
      </ModalContainer>
    </Fragment>
  );
};

export default AddEditStoreModal;
