import React, { useMemo } from "react";
import Barcode from "react-barcode";
import parse from "html-react-parser";
import Frame from "react-frame-component";
import { getSapoQRCodeDetails } from "../../helpers/service_helper";
import { deepCopy, formatDateTime, formatVNDCurrency } from "../../helpers/format_helper";
import LogoTheme from "../../assets/images/logo-theme.png";
import QRCodeOptiwarehouse from "../../assets/images/qr_code_optiwarehouse.png";

export default function ViewTemplatePDF({
  componentRef,
  dataTemplate,
  dataForm = {},
  isShow = true,
  sizePrint,
  isEdit,
}) {
  function findItemByName(obj, nameToFind) {
    const element = nameToFind.replace("{", "").replace("}", "");
    const arrayElement = element.split(".");
    let data = obj;
    for (const key of arrayElement) {
      if (Array.isArray(data)) {
        data = data[0][key];
      } else {
        data = data?.[key];
      }
    }
    return data;
  }

  const dataView = useMemo(() => {
    let newString = dataTemplate || "";
    const listRegex = dataTemplate?.match(/\{([^}]+)\}/g);
    let parseString = dataTemplate;
    for (let index = 0; index < listRegex?.length; index++) {
      const element = listRegex[index].replace("{", "").replace("}", "");
      const arrayElement = element.split(".");
      const dataFindByKey = findItemByName(dataForm, element);
      if (element === "order_code") {
        const number = dataForm?.["number"] || "";
        newString = newString.replaceAll(`{${element}}`, number);
      }
      if (["tax", "sub_total", "discount", "shipping_fee", "total"].includes(element)) {
        const data = formatVNDCurrency(dataForm?.[element] || 0);
        newString = newString.replaceAll(`{${element}}`, data);
      }
      if (arrayElement.length === 1) {
        if (element === "bar_code") {
          newString = newString.replaceAll(`{${element}}`, `<div value=${dataForm[element]} id="barcode"></div>`);
        } else if (element === "store_logo_light") {
          const img = `<img src=${dataForm?.[element]?.url || LogoTheme} alt="logo shop" class="logo-shop" />`;
          newString = newString.replaceAll(`{${element}}`, img);
        } else if (element === "store_address") {
          const newStr = `${dataForm?.[element] || "---"}${
            dataForm["store_wards"] ? `, ${dataForm["store_wards"]}` : ""
          }${dataForm["store_districts"] ? `, ${dataForm["store_districts"]}` : ""}${
            dataForm["store_provinces"] ? `, ${dataForm["store_provinces"]}` : ""
          }`;
          newString = newString.replaceAll(`{${element}}`, newStr);
        } else if (element === "created_at") {
          newString = newString.replaceAll(`{${element}}`, formatDateTime(dataForm[element]));
        } else if (element === "total_quantity") {
          newString = newString.replaceAll(
            `{${element}}`,
            dataForm?.order_line_items?.reduce((total, item) => total + +item.quantity, 0) || 1,
          );
        } else if (element === "qr_code") {
          // const res = await getSapoQRCodeDetails(dataForm.id);
          const qr_code_image = QRCodeOptiwarehouse;
          const img = `<img src=${qr_code_image} alt="qr_code" className="logo-shop" />`;
          newString = newString.replaceAll(`{${element}}`, img);
        } else {
          newString = newString.replaceAll(`{${element}}`, dataForm[element] || "---");
        }
      } else {
        if (Array.isArray(dataForm[arrayElement[0]])) {
          const parser = new DOMParser();
          const document = parser.parseFromString(newString, "text/html");
          // Find all < tr> elements
          const trs = document.querySelectorAll("tr");
          const desiredContent = `{${element}}`;
          // Create a TreeWalker
          const walker = document.createTreeWalker(document, NodeFilter.SHOW_ELEMENT, null, false);
          // Iterate through the elements and check for the desired content
          let currentNode;
          while ((currentNode = walker.nextNode())) {
            if (currentNode.textContent.includes(desiredContent)) {
              // Check if the parent is a <tr> element
              if (currentNode.tagName === "TR") {
                const stringDomFind = currentNode.outerHTML;
                const keyParent = arrayElement[arrayElement.length - 2];
                const array = deepCopy(dataForm[keyParent]);

                if (Array.isArray(array)) {
                  const newStringMapArr = array.map((item, index) => {
                    let str = stringDomFind;
                    const line_stt = index + 1;
                    str = str.replaceAll(`{${keyParent}.line_stt}`, line_stt);
                    str = str.replaceAll(`{${keyParent}.line_unit}`, "---");
                    for (const key in item) {
                      if (Object.hasOwnProperty.call(item, key)) {
                        let element = "";
                        if (["unit_price", "discount", "price"].includes(key)) {
                          element = formatVNDCurrency(item[key]);
                        } else {
                          element = item[key];
                        }
                        str = str.replaceAll(`{${keyParent}.${key}}`, element);
                      }
                    }

                    return str;
                  });

                  newString = newString.replaceAll(stringDomFind, newStringMapArr.join(""));
                }
              }
            }
          }
        } else {
          newString = newString.replaceAll(`{${element}}`, dataFindByKey ?? "---");
        }
      }
    }
    parseString = parse(newString, {
      replace(domNode) {
        if (domNode.attribs && domNode.attribs.id === "barcode") {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Barcode value={domNode.attribs["value"]} format="CODE39" height={60} width={1.5} />
            </div>
          );
        }
        // if (domNode.attribs && domNode.attribs.id === "qr_code") {
        //   return (
        //     <div style={{ display: "flex", justifyContent: "center" }}>
        //       <QRCode value={domNode.attribs["value"]} size={120} style={{ marginTop: "15px" }} />
        //     </div>
        //   );
        // }
      },
    });
    return parseString;
  }, [dataTemplate, dataForm]);

  return (
    <>
      <div className="item-content " style={!isShow ? { display: "none" } : {}}>
        <div className="view-print" style={{ textAlign: "center", overflow: "auto" }} id="view-print">
          <div className="showContent" ref={(el) => (componentRef = el)}>
            <Frame
              width={`${sizePrint?.width}`}
              height={500}
              id="edit"
              style={{ overflowY: "auto", overflowX: "hidden" }}
              contentDidMount={(e) => {
                var doc = document.getElementById("edit").contentWindow.document;
                doc.head.innerHTML = `<style> 
                    @media print {
                          @page {
                            size: auto;
                            margin: 0;
                          }
                          header, footer {
                            display: none !important;
                          }
                        }
                    body {
                        font-family: 'Arial', sans-serif; /* Thay đổi thành font chữ mong muốn */
                    }
                    figure {
                        margin:0
                    }
                    table {
                      width:100%;
                      border-collapse:collapse
                    }
                    .frame-content {
                       margin:20px
                    }
                    td {
                      padding:6px
                    }
                    p {
                      margin:0
                    }
                    .ck-content hr {
                        margin: 0;
                        height: 4px;
                        background: hsl(0, 0%, 87%);
                        border: 0;
                        width:100%
                    }
                    hr {
                        margin: 0;
                       
                    }
                    td, th {
                      border: 1px solid;
                    }
                    p + p {
                      padding-bottom:10px
                    }
                    .logo-shop {
                      width: 100px;
                      height: 80px;
                      object-fit:contain
                    }
                    .qr_code {
                      width: 260px;
                      height: 260px;
                    }
                    
                    </style>
                   
                    `;
              }}
            >
              {dataView}
            </Frame>
          </div>
        </div>
      </div>
    </>
  );
}

const checkDataDom = (list = [], key) => {
  for (let index = 0; index < list.length; index++) {
    const element = list[index];
    if (element.name === "td" && element.children[0].data === key) {
      return list;
    } else {
      if (element?.children) {
        const result = checkDataDom(element.children, key);
        return result;
      }
    }
  }
  return false;
};
