export const GET_TEMPLATE_PRINT = "GET_TEMPLATE_PRINT";
export const GET_TEMPLATE_PRINT_SUCCESS = "GET_TEMPLATE_PRINT_SUCCESS";
export const GET_TEMPLATE_PRINT_FAIL = "GET_TEMPLATE_PRINT_FAIL";

export const UPDATE_TEMPLATE_PRINT = "UPDATE_TEMPLATE_PRINT";
export const UPDATE_TEMPLATE_PRINT_SUCCESS = "UPDATE_TEMPLATE_PRINT_SUCCESS";
export const UPDATE_TEMPLATE_PRINT_FAIL = "UPDATE_TEMPLATE_PRINT_FAIL";

export const ADD_TEMPLATE_PRINT = "ADD_TEMPLATE_PRINT";
export const ADD_TEMPLATE_PRINT_SUCCESS = "ADD_TEMPLATE_PRINT_SUCCESS";
export const ADD_TEMPLATE_PRINT_FAIL = "ADD_TEMPLATE_PRINT_FAIL";
