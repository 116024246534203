const locationVN = [
  {
    id: "1",
    code: "SG",
    name: "H\u1ed3 Ch\u00ed Minh",
    districts: [
      {
        id: "1",
        name: "B\u00ecnh Ch\u00e1nh",
        wards: [
          { id: "1", name: "An Ph\u00fa T\u00e2y", prefix: "X\u00e3" },
          { id: "2", name: "B\u00ecnh Ch\u00e1nh", prefix: "X\u00e3" },
          { id: "3", name: "B\u00ecnh H\u01b0ng", prefix: "X\u00e3" },
          { id: "4", name: "B\u00ecnh L\u1ee3i", prefix: "X\u00e3" },
          { id: "5", name: "\u0110a Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "6", name: "H\u01b0ng Long", prefix: "X\u00e3" },
          { id: "7", name: "L\u00ea Minh Xu\u00e2n", prefix: "X\u00e3" },
          { id: "8", name: "Ph\u1ea1m V\u0103n Hai", prefix: "X\u00e3" },
          { id: "9", name: "Phong Ph\u00fa", prefix: "X\u00e3" },
          { id: "10", name: "Quy \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "11", name: "T\u00e2n Ki\u00ean", prefix: "X\u00e3" },
          { id: "12", name: "T\u00e2n Nh\u1ef1t", prefix: "X\u00e3" },
          { id: "13", name: "T\u00e2n Qu\u00fd T\u00e2y", prefix: "X\u00e3" },
          { id: "14", name: "T\u00e2n T\u00fac", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "15", name: "V\u0129nh L\u1ed9c A", prefix: "X\u00e3" },
          { id: "16", name: "V\u0129nh L\u1ed9c B", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "1", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9", name: "1-2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3", name: "10A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4", name: "10B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6", name: "11D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7", name: "11E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11", name: "13B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17", name: "18A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18", name: "18B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19", name: "18D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21", name: "1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22", name: "1B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23", name: "1C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24", name: "1D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25", name: "1E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26", name: "1F", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29", name: "206", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "32", name: "234", prefix: "\u0110\u01b0\u1eddng" },
          { id: "33", name: "24", prefix: "Ph\u1ed1" },
          { id: "34", name: "24A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "35", name: "26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "36", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "37", name: "284", prefix: "\u0110\u01b0\u1eddng" },
          { id: "38", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "39", name: "2A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "40", name: "2B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "41", name: "2C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "42", name: "2F", prefix: "\u0110\u01b0\u1eddng" },
          { id: "43", name: "2G", prefix: "\u0110\u01b0\u1eddng" },
          { id: "44", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "45", name: "33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "46", name: "378", prefix: "\u0110\u01b0\u1eddng" },
          { id: "47", name: "3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "48", name: "3B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "49", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "50", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "51", name: "4A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "52", name: "4B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "53", name: "4C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "54", name: "4E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "55", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "56", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "57", name: "51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "58", name: "52", prefix: "Ph\u1ed1" },
          { id: "59", name: "53", prefix: "\u0110\u01b0\u1eddng" },
          { id: "60", name: "5A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "61", name: "5B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "62", name: "5C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "63", name: "5D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "64", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "65",
            name: "6 Th\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "66", name: "6A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "67", name: "6B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "68", name: "6C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "69", name: "6D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "70", name: "6F", prefix: "\u0110\u01b0\u1eddng" },
          { id: "71", name: "6T", prefix: "\u0110\u01b0\u1eddng" },
          { id: "72", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "73",
            name: "7 C\u00e1 8 Lu\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "74",
            name: "7 T\u00e2\u0301n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "75", name: "7A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "76", name: "7D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "77", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "78", name: "826", prefix: "\u0110\u01b0\u1eddng" },
          { id: "79", name: "835", prefix: "\u0110\u01b0\u1eddng" },
          { id: "80", name: "835A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "81", name: "835B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "82", name: "835C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "83", name: "8A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "84", name: "8B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "85", name: "8C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "86", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "87", name: "9A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "88", name: "A1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "89", name: "A10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "90", name: "A2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "91", name: "A3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "92", name: "A6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "93", name: "An H\u1ea1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "94",
            name: "An Ph\u00fa \u0110\u00f4ng 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "95",
            name: "An Ph\u00fa T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "96", name: "\u1ea4p 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "97", name: "\u1ea4p 1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "98", name: "\u1ea4p 1B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "99", name: "\u1ea4p 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "100", name: "\u1ea4p 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "101", name: "\u1ea4p 4B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "102", name: "\u1ea4p 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "103", name: "\u1ea4p 7A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "104", name: "B10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "105", name: "B2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "106", name: "B5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "107", name: "B6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "108", name: "Ba Be", prefix: "\u0110\u01b0\u1eddng" },
          { id: "109", name: "Ba B\u00ea", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "110",
            name: "B\u00e0 C\u1ea3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "111", name: "Ba Ch\u1ea3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "112",
            name: "B\u00e0 \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "113",
            name: "B\u00e0 H\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "114", name: "B\u00e0 Hom", prefix: "\u0110\u01b0\u1eddng" },
          { id: "115", name: "Ba L\u00e0ng", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "116",
            name: "Ba Si \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "117", name: "B\u00e0 Thau", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "118",
            name: "B\u00e0 T\u1ef5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "119",
            name: "B\u1ea7u G\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "120",
            name: "B\u00e0u H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "121",
            name: "B\u1ebfn L\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "122",
            name: "B\u00ecnh Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "123",
            name: "B\u00ecnh H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "124",
            name: "B\u00ecnh Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "125",
            name: "B\u00ecnh Ti\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "126",
            name: "B\u00ecnh Tr\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "127",
            name: "B\u1edd Bao X\u00f3m H\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "128",
            name: "B\u1edd Ch\u00f9a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "131",
            name: "B\u1edd D\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "129",
            name: "B\u1edd \u0110\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "130",
            name: "B\u1edd \u0110\u1ea5t M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "132",
            name: "B\u1ed9 \u0110\u1ed9i An \u0110i\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "133",
            name: "B\u1edd Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "134",
            name: "B\u1edd K\u00eanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "135",
            name: "B\u1edd K\u00eanh T14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "136", name: "B\u1edd Kinh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "137",
            name: "B\u1edd Kinh 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "138",
            name: "B\u1edd Nh\u00e0 Th\u1edd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "139",
            name: "B\u1edd T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "140", name: "B\u1edd Xe Lam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "141",
            name: "B\u00f4ng V\u0103n D\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "142",
            name: "B\u00f9i Thanh Khi\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "143",
            name: "B\u00f9i V\u0103n Ng\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "144",
            name: "B\u00f9i V\u0103n S\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "145", name: "C1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "146", name: "C11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "147", name: "C12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "148", name: "C2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "149", name: "C3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "150", name: "C4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "151", name: "C5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "152", name: "C6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "153", name: "C7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "154", name: "C7C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "155", name: "C8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "156", name: "C9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "157",
            name: "C\u1ea3 C\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "158", name: "C\u00e1i Trung", prefix: "\u0110\u01b0\u1eddng" },
          { id: "159", name: "Cao L\u1ed7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "160",
            name: "C\u1ea7u \u0110\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "161",
            name: "C\u1ea7u Su\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "162",
            name: "C\u1ea7u X\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "163",
            name: "C\u00e2y B\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "164",
            name: "C\u00e2y B\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "165",
            name: "C\u00e2y C\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "166",
            name: "C\u00e2y C\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "167",
            name: "C\u00e2y D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "168",
            name: "Ch\u00e1nh H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "169",
            name: "Chi\u1ebfn L\u01b0\u1ee3c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "170",
            name: "Ch\u00fa L\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "171", name: "Ch\u00f9a", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "172",
            name: "C\u00f4ng Ngh\u1ec7 M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "173", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "174", name: "D15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "175", name: "D17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "176", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "177", name: "D22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "178", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "179", name: "D4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "180", name: "D5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "181", name: "D6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "182", name: "D9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "184",
            name: "D\u00e2n C\u00f4ng H\u1ecfa Tuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "203",
            name: "D\u01b0\u01a1ng C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "204",
            name: "D\u01b0\u01a1ng C\u00f4ng Khi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "206",
            name: "D\u01b0\u01a1ng \u0110\u00ecnh C\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "183",
            name: "\u0110a Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "185",
            name: "\u0110\u1ea5t M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "186",
            name: "\u0110\u00ea Bao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "187",
            name: "\u0110\u00ea s\u1ed1 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "188",
            name: "\u0110inh \u0110\u1ee9c Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "189",
            name: "\u0110\u00ecnh Ph\u00fa L\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "190",
            name: "\u0110\u00ecnh Th\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "191",
            name: "\u0110o\u00e0n Nguy\u1ec5n Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "192",
            name: "\u0110o\u00e0n Nguy\u1ec5n Tu\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "193",
            name: "\u0110\u00f4ng D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "194",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "195", name: "\u0110T 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "196", name: "\u0110T 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "197", name: "\u0110T 824", prefix: "\u0110\u01b0\u1eddng" },
          { id: "198", name: "\u0110T 826", prefix: "\u0110\u01b0\u1eddng" },
          { id: "199", name: "\u0110T 833", prefix: "\u0110\u01b0\u1eddng" },
          { id: "200", name: "\u0110T 835", prefix: "\u0110\u01b0\u1eddng" },
          { id: "201", name: "\u0110T 835B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "202", name: "\u0110T 835C", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "205",
            name: "\u0110\u01b0\u1eddng \u0110\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "207", name: "E12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "208", name: "E14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "209", name: "E5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "210", name: "F11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "211", name: "G6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "212", name: "G7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "213",
            name: "Giao th\u00f4ng h\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "214",
            name: "H\u00e0 Duy Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "215", name: "H\u00e0 Thanh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "216",
            name: "H\u00e0ng C\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "217",
            name: "H\u00e0ng C\u1ecd 9A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "218",
            name: "H\u1eadu L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "219",
            name: "Hi\u1ec7p Th\u00e0nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "220",
            name: "Hi\u1ec7p Th\u00e0nh 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "221",
            name: "H\u1ed3 V\u0103n Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "222",
            name: "Ho\u00e0ng \u0110\u1ea1o Th\u00fay",
            prefix: "Ph\u1ed1",
          },
          {
            id: "223",
            name: "Ho\u00e0ng Phan Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "224",
            name: "H\u00f3c H\u01b0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "225",
            name: "H\u00f3c H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "226", name: "H\u01b0ng Long", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "227",
            name: "H\u01b0ng Nh\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "228",
            name: "H\u01b0ng Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "229",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "230",
            name: "H\u01b0\u01a1ng l\u1ed9  80",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "231",
            name: "H\u01b0\u01a1ng l\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "232",
            name: "H\u01b0\u01a1ng L\u00f4\u0323 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "233",
            name: "H\u01b0\u01a1ng L\u1ed9 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "234",
            name: "H\u01b0\u01a1ng l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "235",
            name: "H\u01b0\u01a1ng l\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "236",
            name: "H\u01b0\u01a1ng l\u00f4\u0323 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "237",
            name: "H\u01b0\u01a1ng L\u1ed9 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "238",
            name: "H\u01b0\u01a1ng L\u00f4\u0323 80B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "239",
            name: "Hu\u1ef3nh B\u00e1 Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "240",
            name: "Hu\u1ef3nh H\u1eefu Tr\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "241",
            name: "Hu\u1ef3nh Thanh Tu\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "242",
            name: "Hu\u1ef3nh V\u0103n Tr\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "243", name: "K\u00eanh", prefix: "\u0110\u01b0\u1eddng" },
          { id: "244", name: "K\u00eanh 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "245", name: "K\u00eanh 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "246", name: "K\u00eanh 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "247", name: "K\u00eanh 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "248", name: "K\u00eanh 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "249", name: "K\u00eanh 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "250", name: "K\u00eanh 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "251", name: "K\u00eanh 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "252", name: "K\u00eanh 9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "253", name: "K\u00eanh A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "254",
            name: "K\u00eanh An H\u1ea1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "255", name: "K\u00eanh B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "256", name: "K\u00eanh C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "257", name: "K\u00eanh C6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "258",
            name: "K\u00eanh \u0110\u1ed9c L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "259",
            name: "K\u00eanh Li\u00ean V\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "260", name: "K\u00eanh Ranh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "261",
            name: "K\u00eanh S\u00e1u O\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "262", name: "K\u00eanh T12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "263", name: "K\u00eanh T3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "264", name: "K\u00eanh T5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "265",
            name: "K\u00eanh Trung \u01af\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "266",
            name: "Khu\u1ea5t V\u0103n B\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "267", name: "Kinh 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "268", name: "Kinh 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "269", name: "Kinh 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "270", name: "Kinh A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "271", name: "Kinh B12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "272", name: "Kinh C", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "273",
            name: "Kinh Rau R\u0103m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "274", name: "Kinh T11", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "275",
            name: "Kinh Trung \u01af\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "276",
            name: "L\u1ea1i H\u00f9ng C\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "277",
            name: "La\u0301ng Le Ba\u0300u Co\u0300",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "278",
            name: "L\u00ea Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "279",
            name: "L\u00ea Ch\u00ednh \u0110ang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "280",
            name: "L\u00ea \u0110\u00ecnh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "281",
            name: "L\u00ea Minh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "282",
            name: "L\u00ea Qu\u00fd Ho\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "283",
            name: "L\u00ea Th\u1ecb Dung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "284",
            name: "Li\u00ean \u1ea4p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "285",
            name: "Li\u00ean \u1ea5p 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "286",
            name: "Li\u00ean \u1ea5p 1-2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "291",
            name: "Li\u00ean \u1ea5p 1-3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "287",
            name: "Li\u00ean \u1ea4p 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "288",
            name: "Li\u00ean \u1ea5p 123",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "289",
            name: "Li\u00ean \u1ea4p 1234",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "290",
            name: "Li\u00ean \u1ea5p 123A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "292",
            name: "Li\u00ean \u1ea4p 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "293",
            name: "Li\u00ean \u1ea5p 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "294",
            name: "Li\u00ean \u1ea5p 2-3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "296",
            name: "Li\u00ean \u1ea4p 2-3-4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "297",
            name: "Li\u00ean \u00e2\u0301p 2-6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "295",
            name: "Li\u00ean \u1ea5p 234",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "298",
            name: "Li\u00ean \u1ea4p 3-4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "299",
            name: "Li\u00ean \u1ea5p 3-4-5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "300",
            name: "Li\u00ean \u1ea4p 4 - 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "301",
            name: "Li\u00ean \u1ea5p 4-5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "302",
            name: "Li\u00ean \u1ea5p 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "303",
            name: "Li\u00ean \u1ea5p 5-6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "304",
            name: "Li\u00ean \u1ea4p 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "305",
            name: "Li\u00ean \u1ea5p 6-2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "306",
            name: "Li\u00ean Khu 123",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "307",
            name: "Li\u00ean Khu 2-6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "308",
            name: "Li\u00ean khu 4-5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "309",
            name: "Li\u00ean Khu 5 - 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "310",
            name: "Li\u00ean th\u00f4n 1-2-3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "311",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "312", name: "Linh H\u00f2a", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "313",
            name: "Linh H\u00f2a T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "314", name: "L\u00f4 2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "315",
            name: "L\u1ed9c H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "316",
            name: "L\u1eef V\u0103n C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "317",
            name: "L\u01b0\u01a1ng \u0110\u1ecbnh C\u1ee7a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "318",
            name: "L\u01b0\u01a1ng Ngang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "319",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "320", name: "M1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "321", name: "M16", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "322",
            name: "Mai B\u00e1 H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "323",
            name: "M\u01b0\u1eddi V\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "324",
            name: "M\u01b0\u01a1ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "325", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "326", name: "N3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "327", name: "N4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "328",
            name: "N\u0103m L\u1eeda 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "329",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "330",
            name: "Nguy\u1ec5n C\u1eedu Ch\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "331",
            name: "Nguy\u1ec5n C\u1eedu Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "332",
            name: "Nguy\u1ec5n \u0110\u00ecnh Ki\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "333",
            name: "Nguy\u1ec5n \u0110o\u00e0n Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "334",
            name: "Nguy\u1ec5n H\u1eefu Tr\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "335",
            name: "Nguy\u1ec5n Huy Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "336",
            name: "Nguy\u1ec5n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "337",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "338",
            name: "Nguy\u1ec5n Th\u1ecb Dung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "339",
            name: "Nguy\u1ec5n Th\u1ecb S\u01b0a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "340",
            name: "Nguy\u1ec5n Th\u1ecb Th\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "341",
            name: "Nguy\u1ec5n Th\u1ecb Ti\u1ebfp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "342",
            name: "Nguy\u1ec5n Th\u1ecb T\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "343",
            name: "Nguy\u1ec5n Th\u1ecb X\u01b0a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "344",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "345",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "346",
            name: "Nguy\u1ec5n V\u0103n B\u1ee9a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "347",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "348",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "349",
            name: "Nguy\u1ec5n V\u0103n Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "350",
            name: "Nguy\u1ec5n V\u0103n Th\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "351",
            name: "Nguy\u1ec5n V\u0103n Th\u1eddi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "352",
            name: "Nguy\u1ec5n V\u0103n Thu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "353",
            name: "Nguy\u1ec5n V\u0103n Thu\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "354",
            name: "Nguy\u1ec5n V\u0103n Tr\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "355",
            name: "Nguy\u1ec5n V\u0103n Tr\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "356",
            name: "Nh\u00e0 \u0110\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "357",
            name: "N\u1eef D\u00e2n C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "358",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "359",
            name: "Ph\u1ea1m T\u1ea5n M\u01b0\u1eddi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "360",
            name: "Ph\u1ea1m V\u0103n Hai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "361",
            name: "Ph\u1ea1m V\u0103n S\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "362",
            name: "Ph\u1ea1m V\u0103n Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "363",
            name: "Phan Th\u1ecb T\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "364",
            name: "Phan V\u0103n \u0110\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "365",
            name: "Phan V\u0103n M\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "366", name: "Phong Ph\u00fa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "367",
            name: "Ph\u01b0\u1edbc L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "368",
            name: "Qu\u00e1ch \u0110i\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "369",
            name: "Qu\u00e1ch \u0110i\u00eau 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "370",
            name: "Qu\u1ea3n Tr\u1ecdng Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "371",
            name: "Qu\u1ed1c L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "372",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "373",
            name: "Qu\u1ed1c L\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "374",
            name: "Qu\u1ed1c L\u1ed9 50",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "375",
            name: "Qu\u1ed1c L\u1ed9 51",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "376",
            name: "Qu\u1ed1c l\u1ed9 80",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "377",
            name: "Qu\u1ed1c l\u1ed9 8A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "378",
            name: "Quy \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "379", name: "Qu\u1ef3nh Anh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "380",
            name: "R\u1ea1ch B\u00e0 L\u1edbn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "381",
            name: "R\u1ea1ch B\u00e0u G\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "382",
            name: "R\u1ea1ch C\u1ea7u Su\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "383",
            name: "R\u1ea1ch \u00d4ng \u0110\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "384", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "385", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "386", name: "S\u1ed1 10A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "387", name: "S\u1ed1 10B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "388", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "389", name: "S\u1ed1 11A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "390", name: "S\u1ed1 11C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "391", name: "S\u1ed1 11D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "392", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "393", name: "S\u1ed1 12A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "394", name: "S\u1ed1 12B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "395", name: "S\u1ed1 12D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "396", name: "S\u1ed1 12E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "397", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "398", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "399", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "400", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "401", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "402", name: "S\u1ed1 18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "403", name: "S\u1ed1 18A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "404", name: "S\u1ed1 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "405", name: "S\u1ed1 1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "406", name: "S\u1ed1 1B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "407", name: "S\u1ed1 1C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "408", name: "S\u1ed1 1D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "409", name: "S\u1ed1 1E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "410", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "411", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "412", name: "S\u1ed1 21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "413", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "414", name: "S\u1ed1 23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "415", name: "S\u1ed1 24", prefix: "\u0110\u01b0\u1eddng" },
          { id: "416", name: "S\u1ed1 26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "417", name: "S\u1ed1 2A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "418", name: "S\u1ed1 2B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "419", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "420", name: "S\u1ed1 3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "421", name: "S\u1ed1 3B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "422", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "423", name: "S\u1ed1 49", prefix: "\u0110\u01b0\u1eddng" },
          { id: "424", name: "S\u1ed1 4A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "425", name: "S\u1ed1 4C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "426", name: "S\u1ed1 4E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "427", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "428", name: "S\u1ed1 5A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "429", name: "S\u1ed1 5C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "430", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "431", name: "S\u1ed1 60", prefix: "\u0110\u01b0\u1eddng" },
          { id: "432", name: "S\u1ed1 61", prefix: "\u0110\u01b0\u1eddng" },
          { id: "433", name: "S\u1ed1 6A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "434", name: "S\u1ed1 6B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "435", name: "S\u1ed1 6C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "436", name: "S\u1ed1 6D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "437", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "438", name: "S\u1ed1 7A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "439", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "440", name: "S\u1ed1 8B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "441", name: "S\u1ed1 8C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "442", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "443", name: "S\u1ed1 9A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "444",
            name: "S\u01a1n H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "445",
            name: "S\u01a1n H\u1ea3i 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "446", name: "Song H\u00e0nh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "447",
            name: "S\u01b0 \u0110o\u00e0n 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "448", name: "T1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "449", name: "T10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "450", name: "T11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "451", name: "T12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "452", name: "T14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "453", name: "T2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "454", name: "T3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "455", name: "T4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "456", name: "T5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "457", name: "T6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "458", name: "T8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "459", name: "T9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "460",
            name: "Tam B\u1eedu T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "461",
            name: "T\u00e1m Th\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "462",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "463",
            name: "T\u00e2n \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "464",
            name: "T\u00e2n Ki\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "465", name: "T\u00e2n Kim", prefix: "Ph\u1ed1" },
          {
            id: "466",
            name: "T\u00e2n Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "467",
            name: "T\u00e2n Li\u00ea\u0303u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "468", name: "T\u00e2n Long", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "469",
            name: "T\u00e2n Nhi\u1ec5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "470",
            name: "T\u00e2n Nh\u1ef1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "471",
            name: "T\u00e2n T\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "472",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "473",
            name: "T\u00e2n T\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "474",
            name: "T\u1eadp \u0110o\u00e0n 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "475",
            name: "T\u1eadp \u0110o\u00e0n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "476",
            name: "T\u1eadp \u0110o\u00e0n 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "477",
            name: "T\u1eadp \u0110o\u00e0n 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "478",
            name: "T\u1eadp \u0110o\u00e0n Li\u00ean Doanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "479",
            name: "T\u00e2y L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "480",
            name: "T\u00ean L\u1eeda 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "481",
            name: "Th\u1ea1nh L\u1ed9c 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "482",
            name: "Th\u00e0nh Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "483",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "484",
            name: "Th\u1ea1nh Xu\u00e2n 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "485",
            name: "Th\u1ebf L\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "486",
            name: "Th\u00edch Thi\u1ec7n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "487",
            name: "Thi\u00ean Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "488",
            name: "Th\u1edbi H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "489",
            name: "Th\u1ee7y L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "490",
            name: "T\u1ec9nh L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "491",
            name: "T\u1ec9nh l\u1ed9 10B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "492",
            name: "T\u1ec9nh L\u1ed9 10C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "493",
            name: "T\u1ec9nh l\u1ed9 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "494",
            name: "T\u1ec9nh L\u1ed9 50",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "495",
            name: "T\u1ec9nh l\u1ed9 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "496",
            name: "T\u1ec9nh l\u1ed9 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "497",
            name: "T\u1ec9nh L\u1ed9 80",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "498",
            name: "T\u1ec9nh l\u1ed9 824",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "499",
            name: "T\u1ec9nh l\u1ed9 825",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "500",
            name: "T\u1ec9nh l\u1ed9 826",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "501",
            name: "T\u1ec9nh L\u1ed9 830B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "502",
            name: "T\u1ec9nh L\u1ed9 833B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "503",
            name: "T\u1ec9nh l\u1ed9 835",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "504",
            name: "T\u1ec9nh l\u1ed9 835B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "505",
            name: "T\u1ec9nh L\u1ed9 835C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "506",
            name: "T\u1ec9nh L\u1ed9 835D",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "507",
            name: "T\u1ec9nh l\u1ed9 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "508", name: "TN1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "509", name: "TN13", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "510",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "511",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "512",
            name: "Tr\u1ea7n H\u1ea3i Ph\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "513",
            name: "Tr\u1ea7n V\u0103n Gi\u00e0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "514",
            name: "Trang V\u0103n H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "515",
            name: "Tr\u00ed Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "516",
            name: "Tr\u00edch S\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "517",
            name: "Tr\u1ecbnh Nh\u01b0 Khu\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "518",
            name: "Tr\u1ecbnh Quang Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "519",
            name: "Trung Ch\u00e1nh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "520", name: "Trung S\u01a1n", prefix: "Ph\u1ed1" },
          {
            id: "521",
            name: "Tr\u01b0\u01a1ng V\u0103n Bang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "522",
            name: "Tr\u01b0\u01a1ng V\u0103n \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "523",
            name: "T\u01b0 H\u1ef7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "524", name: "TX22", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "525",
            name: "V\u00e0nh \u0110ai 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "526",
            name: "V\u00e0nh \u0110ai 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "527",
            name: "V\u00e0nh \u0110ai 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "528",
            name: "V\u00e0nh \u0110ai Trong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "529",
            name: "V\u0129nh L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "530",
            name: "V\u0129nh L\u1ed9c A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "531",
            name: "V\u0129nh L\u1ed9c B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "532",
            name: "V\u0129nh L\u1ed9c H",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "533",
            name: "V\u00f5 H\u1eefu L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "534",
            name: "V\u00f5 T\u1ea7n Ch\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "535",
            name: "V\u00f5 T\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "536",
            name: "V\u00f5 Th\u1ecb T\u1ed1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "537",
            name: "V\u00f5 Tr\u1ea7n Ch\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "538",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "539",
            name: "V\u00f5 V\u0103n Thu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "540",
            name: "V\u00f5 V\u0103n V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "541",
            name: "V\u01b0\u1eddn L\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "542",
            name: "V\u01b0\u1eddn Th\u01a1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "543",
            name: "X\u00e3 \u0110\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "544",
            name: "X\u00f3m D\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "545",
            name: "X\u00f3m Gi\u1eefa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "546",
            name: "X\u01b0\u01a1ng C\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "547",
            name: "X\u01b0\u01a1ng C\u00e1 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "548",
            name: "X\u01b0\u01a1ng C\u00e1 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1",
            name: "13B Conic Phong Ph\u00fa",
            lat: "10.71240234375",
            lng: "106.64177703857",
          },
          {
            id: "2",
            name: "13D Asia Ph\u00fa M\u1ef9",
            lat: "10.705533027649",
            lng: "106.64806365967",
          },
          {
            id: "3",
            name: "13E Phong Ph\u00fa",
            lat: "10.704967498779",
            lng: "106.64633178711",
          },
          {
            id: "4",
            name: "584 T\u00e2n Ki\u00ean",
            lat: "10.708187103272",
            lng: "106.60097503662",
          },
          {
            id: "5",
            name: "6B Intresco",
            lat: "10.720191001892",
            lng: "106.68676757812",
          },
          {
            id: "6",
            name: "A.View",
            lat: "10.71292591095",
            lng: "106.64617919922",
          },
          {
            id: "7",
            name: "Amazing City",
            lat: "10.737606048584",
            lng: "106.55274963379",
          },
          {
            id: "8",
            name: "An H\u1ea1 Lotus",
            lat: "10.814573287964",
            lng: "106.51580047607",
          },
          {
            id: "9",
            name: "An L\u1ea1c Residence",
            lat: "10.74085521698",
            lng: "106.54239654541",
          },
          {
            id: "10",
            name: "An Ph\u00fa T\u00e2y",
            lat: "10.683333396912",
            lng: "106.59999847412",
          },
          {
            id: "11",
            name: "Calla Garden",
            lat: "10.716975212097",
            lng: "106.65301513672",
          },
          {
            id: "12",
            name: "Camellia Garden",
            lat: "10.719439506531",
            lng: "106.66181945801",
          },
          {
            id: "13",
            name: "Citizen Trung S\u01a1n",
            lat: "10.73055267334",
            lng: "106.68878936768",
          },
          {
            id: "14",
            name: "Conic \u0110\u00ecnh Khi\u00eam",
            lat: "10.709337234497",
            lng: "106.64176177978",
          },
          {
            id: "15",
            name: "Conic \u0110\u00f4ng Nam \u00c1",
            lat: "10.709321022034",
            lng: "106.64177703857",
          },
          {
            id: "16",
            name: "Conic Garden",
            lat: "10.710923194885",
            lng: "106.64198303223",
          },
          {
            id: "18",
            name: "D\u01b0\u01a1ng H\u1ed3ng Garden House",
            lat: "10.720993041992",
            lng: "106.66110992432",
          },
          {
            id: "17",
            name: "\u0110\u1ea1i Ph\u00fac Green Villas",
            lat: "10.717950820923",
            lng: "106.68319702148",
          },
          {
            id: "19",
            name: "Greenlife 13C",
            lat: "10.713145256043",
            lng: "106.64419555664",
          },
          {
            id: "20",
            name: "Happy City",
            lat: "10.713311195373",
            lng: "106.65032958984",
          },
          {
            id: "21",
            name: "Him Lam 6A",
            lat: "10.729124069214",
            lng: "106.68711853027",
          },
          {
            id: "22",
            name: "Ho\u00e0ng Th\u00e1p Plaza",
            lat: "10.731473922729",
            lng: "106.68936920166",
          },
          {
            id: "23",
            name: "H\u1ed3ng L\u0129nh Plaza",
            lat: "10.724812507629",
            lng: "106.66439056397",
          },
          {
            id: "24",
            name: "H\u1ed3ng Quang 13A Nam S\u00e0i G\u00f2n",
            lat: "10.711993217468",
            lng: "106.63779449463",
          },
          {
            id: "25",
            name: "HQC An Ph\u00fa T\u00e2y",
            lat: "10.688013076782",
            lng: "106.60849761963",
          },
          {
            id: "26",
            name: "HQC Plaza",
            lat: "10.69362449646",
            lng: "106.6093826294",
          },
          {
            id: "27",
            name: "Investco Green City",
            lat: "10.728575706482",
            lng: "106.68389129639",
          },
          {
            id: "28",
            name: "KCN Phong Ph\u00fa",
            lat: "10.698356628418",
            lng: "106.64340209961",
          },
          {
            id: "29",
            name: "KDC 6B T30",
            lat: "10.714260101318",
            lng: "106.68594360352",
          },
          {
            id: "30",
            name: "KDC \u1ea4p 5 Phong Ph\u00fa",
            lat: "10.725612640381",
            lng: "106.64627075195",
          },
          {
            id: "31",
            name: "KDC B\u00ecnh H\u01b0ng",
            lat: "10.722487449646",
            lng: "106.65581512451",
          },
          {
            id: "32",
            name: "KDC \u0110\u1ea1i Ph\u00fa",
            lat: "10.739863395691",
            lng: "106.54822540283",
          },
          {
            id: "33",
            name: "KDC Gia Ph\u00fa",
            lat: "10.770943641663",
            lng: "106.5527267456",
          },
          {
            id: "34",
            name: "KDC Happy Home One",
            lat: "10.828125953674",
            lng: "106.57223510742",
          },
          {
            id: "35",
            name: "KDC Ho\u00e0ng Th\u00e0nh",
            lat: "10.810021400452",
            lng: "106.5731048584",
          },
          {
            id: "36",
            name: "KDC R\u1ea1ng \u0110\u00f4ng",
            lat: "10.660576820373",
            lng: "106.5816116333",
          },
          {
            id: "37",
            name: "KDC Thanh B\u00ecnh",
            lat: "10.703538894653",
            lng: "106.66720581055",
          },
          {
            id: "38",
            name: "Khu Bi\u1ec7t Th\u1ef1 S\u00f4ng \u00d4ng L\u1edbn",
            lat: "10.726951599121",
            lng: "106.68867492676",
          },
          {
            id: "39",
            name: "Khu c\u0103n h\u1ed9 V\u0129nh L\u1ed9c",
            lat: "10.808732032776",
            lng: "106.57151794434",
          },
          {
            id: "40",
            name: "Khu d\u00e2n c\u01b0 Thu\u1ea7n Vi\u1ec7t",
            lat: "10.79236125946",
            lng: "106.55527496338",
          },
          {
            id: "41",
            name: "Khu \u0111\u00f4 th\u1ecb Ph\u00fa An Th\u1ecbnh",
            lat: "10.688013076782",
            lng: "106.60849761963",
          },
          {
            id: "42",
            name: "Lovera Park",
            lat: "10.698978424072",
            lng: "106.64194488525",
          },
          {
            id: "43",
            name: "Mansion",
            lat: "10.707159996033",
            lng: "106.64311981201",
          },
          {
            id: "44",
            name: "Mizuki Park",
            lat: "10.724812507629",
            lng: "106.66439056397",
          },
          {
            id: "45",
            name: "Nam Phong Eco Park",
            lat: "10.657523155212",
            lng: "106.58212280273",
          },
          {
            id: "46",
            name: "Newlife B\u00ecnh Ch\u00e1nh",
            lat: "10.790541648865",
            lng: "106.51029968262",
          },
          {
            id: "47",
            name: "Ng\u1ecdc Cung",
            lat: "10.724812507629",
            lng: "106.66439056397",
          },
          {
            id: "48",
            name: "Ng\u1ecdc \u0110\u00f4ng D\u01b0\u01a1ng 2",
            lat: "10.728695869446",
            lng: "106.58438873291",
          },
          {
            id: "49",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            lat: "10.730175971985",
            lng: "106.69509124756",
          },
          {
            id: "50",
            name: "Phi Long 5",
            lat: "10.721196174622",
            lng: "106.66104888916",
          },
          {
            id: "51",
            name: "S\u00e0i G\u00f2n Intela",
            lat: "10.709362030029",
            lng: "106.64224243164",
          },
          {
            id: "52",
            name: "S\u00e0i G\u00f2n Mia",
            lat: "10.732486724853",
            lng: "106.69004821777",
          },
          {
            id: "53",
            name: "S\u00e0i G\u00f2n West Garden",
            lat: "10.809598922729",
            lng: "106.57289123535",
          },
          {
            id: "54",
            name: "Saigonres",
            lat: "10.708714485168",
            lng: "106.62640380859",
          },
          {
            id: "55",
            name: "Skyway Residence",
            lat: "10.710056304932",
            lng: "106.64250183106",
          },
          {
            id: "56",
            name: "T\u00e2n T\u1ea1o Plaza 4-5-6",
            lat: "10.728891372681",
            lng: "106.5841293335",
          },
          {
            id: "57",
            name: "T\u0110V Phong Ph\u00fa",
            lat: "10.692061424255",
            lng: "106.65351104736",
          },
          {
            id: "58",
            name: "Terra Rosa",
            lat: "10.705985069275",
            lng: "106.64362335205",
          },
          {
            id: "59",
            name: "The Easter City",
            lat: "10.722816467285",
            lng: "106.68347930908",
          },
          {
            id: "60",
            name: "The Hollywood Tower",
            lat: "10.708862304688",
            lng: "106.60217285156",
          },
          {
            id: "61",
            name: "Trung S\u01a1n",
            lat: "10.730053901672",
            lng: "106.68901824951",
          },
          {
            id: "62",
            name: "Vi\u1ec7t Ph\u00fa Garden",
            lat: "10.694388389587",
            lng: "106.6399307251",
          },
          {
            id: "63",
            name: "Viloca Garden",
            lat: "10.814337730408",
            lng: "106.57969665527",
          },
          {
            id: "64",
            name: "V\u0129nh L\u1ed9c",
            lat: "10.776156425476",
            lng: "106.56327056885",
          },
          {
            id: "65",
            name: "V\u0129nh L\u1ed9c A",
            lat: "10.819152832031",
            lng: "106.5845413208",
          },
          {
            id: "66",
            name: "V\u0129nh L\u1ed9c Golden",
            lat: "10.806092262268",
            lng: "106.56735992432",
          },
        ],
      },
      {
        id: "2",
        name: "B\u00ecnh T\u00e2n",
        wards: [
          { id: "17", name: "An L\u1ea1c", prefix: "Ph\u01b0\u1eddng" },
          { id: "18", name: "An L\u1ea1c A", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "19",
            name: "B\u00ecnh H\u01b0ng H\u00f2a",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "20",
            name: "B\u00ecnh H\u01b0ng H\u00f2a A",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "21",
            name: "B\u00ecnh H\u01b0ng H\u00f2a B",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "22",
            name: "B\u00ecnh Tr\u1ecb \u0110\u00f4ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "23",
            name: "B\u00ecnh Tr\u1ecb \u0110\u00f4ng A",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "24",
            name: "B\u00ecnh Tr\u1ecb \u0110\u00f4ng B",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "25", name: "T\u00e2n T\u1ea1o", prefix: "Ph\u01b0\u1eddng" },
          { id: "26", name: "T\u00e2n T\u1ea1o A", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "549", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "550", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "551", name: "10A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "552", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "553", name: "111", prefix: "\u0110\u01b0\u1eddng" },
          { id: "554", name: "11A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "555", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "556", name: "12A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "557", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "558", name: "13A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "559", name: "13B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "560", name: "13C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "561", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "562", name: "144", prefix: "\u0110\u01b0\u1eddng" },
          { id: "563", name: "14A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "564", name: "14B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "565", name: "14E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "566", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "567", name: "15A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "568", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "569", name: "16A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "570", name: "16B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "571", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "572", name: "173", prefix: "\u0110\u01b0\u1eddng" },
          { id: "573", name: "17A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "574", name: "17B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "575", name: "17C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "576", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "577", name: "18A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "578", name: "18B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "579", name: "18C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "580", name: "18D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "581", name: "18E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "582", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "583", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "584", name: "19A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "585", name: "19B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "586", name: "19C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "587", name: "19D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "588", name: "19E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "589", name: "1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "590", name: "1B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "591", name: "1C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "592", name: "1D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "593", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "594", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "595", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "596", name: "21A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "597", name: "21B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "598", name: "21C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "599", name: "21D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "600", name: "21E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "601", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "602", name: "221", prefix: "\u0110\u01b0\u1eddng" },
          { id: "603", name: "22A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "604", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "605", name: "23A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "606", name: "23B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "607", name: "24", prefix: "Ph\u1ed1" },
          { id: "608", name: "24A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "609", name: "24B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "610", name: "25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "611", name: "25A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "612", name: "26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "613", name: "26/3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "614", name: "26/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "615", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "616", name: "27/3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "617", name: "27C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "618", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "619", name: "28A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "620", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "621", name: "2A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "622", name: "2B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "623", name: "2C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "624", name: "2D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "625", name: "2E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "626", name: "2F", prefix: "\u0110\u01b0\u1eddng" },
          { id: "627", name: "2G", prefix: "\u0110\u01b0\u1eddng" },
          { id: "628", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "629", name: "30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "630", name: "31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "631", name: "31A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "632", name: "32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "633", name: "32A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "634", name: "32B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "635", name: "33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "636", name: "33A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "637", name: "33B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "638", name: "34", prefix: "\u0110\u01b0\u1eddng" },
          { id: "639", name: "34A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "640", name: "34B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "641", name: "36", prefix: "Ph\u1ed1" },
          { id: "642", name: "38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "643", name: "38A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "644", name: "39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "645", name: "3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "646", name: "3B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "647", name: "3C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "648", name: "3D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "649", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "650", name: "40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "651", name: "40B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "652", name: "41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "653", name: "42", prefix: "\u0110\u01b0\u1eddng" },
          { id: "654", name: "43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "655", name: "44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "656", name: "46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "657", name: "46A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "658", name: "46B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "659", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "660", name: "48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "661", name: "48A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "662", name: "48B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "663", name: "49", prefix: "Ph\u1ed1" },
          { id: "664", name: "49A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "665", name: "49B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "666", name: "49C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "667", name: "4A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "668", name: "4B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "669", name: "4C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "670", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "671", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "672", name: "50A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "673", name: "50B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "674", name: "50C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "675", name: "50D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "676", name: "51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "677", name: "52", prefix: "Ph\u1ed1" },
          { id: "678", name: "52A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "679", name: "52B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "680", name: "53", prefix: "\u0110\u01b0\u1eddng" },
          { id: "681", name: "53A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "682", name: "53B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "683", name: "53C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "684", name: "54", prefix: "\u0110\u01b0\u1eddng" },
          { id: "685", name: "54A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "686", name: "55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "687", name: "55A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "688", name: "55B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "689", name: "57", prefix: "\u0110\u01b0\u1eddng" },
          { id: "690", name: "57A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "691", name: "57B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "692", name: "57C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "693", name: "59B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "694", name: "5A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "695", name: "5B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "696", name: "5C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "697", name: "5D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "698", name: "5E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "699", name: "5F", prefix: "\u0110\u01b0\u1eddng" },
          { id: "700", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "701", name: "61", prefix: "\u0110\u01b0\u1eddng" },
          { id: "702", name: "671", prefix: "\u0110\u01b0\u1eddng" },
          { id: "703", name: "688", prefix: "\u0110\u01b0\u1eddng" },
          { id: "704", name: "6A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "705", name: "6B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "706", name: "6C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "707", name: "6D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "708", name: "6E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "709", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "710", name: "7A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "711", name: "7B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "712", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "713", name: "822", prefix: "\u0110\u01b0\u1eddng" },
          { id: "714", name: "842", prefix: "\u0110\u01b0\u1eddng" },
          { id: "715", name: "8A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "716", name: "8B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "717", name: "8C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "718", name: "8D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "719", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "720", name: "933", prefix: "\u0110\u01b0\u1eddng" },
          { id: "721", name: "9A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "722", name: "A1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "723", name: "A2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "724", name: "A3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "725", name: "A4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "726", name: "A5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "727", name: "A6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "728", name: "A7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "729", name: "A8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "730", name: "A9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "731",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "732", name: "An L\u1ea1c", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "733",
            name: "Ao \u0110\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "734",
            name: "\u1ea4p Chi\u1ebfn L\u01b0\u1ee3c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "735",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "736", name: "B\u00e0 Hom", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "737",
            name: "B\u1ebfn L\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "738",
            name: "Bia Truy\u1ec1n Th\u1ed1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "739",
            name: "B\u00ecnh H\u01b0ng H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "740",
            name: "B\u00ecnh H\u01b0ng H\u00f2a A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "741", name: "B\u00ecnh Long", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "742",
            name: "B\u00ecnh Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "743",
            name: "B\u00ecnh Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "744",
            name: "B\u00ecnh Tr\u1ecb \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "745",
            name: "B\u1edd S\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "746",
            name: "B\u1edd Tuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "747",
            name: "B\u00f9i D\u01b0\u01a1ng L\u1ecbch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "748",
            name: "B\u00f9i H\u1eefu Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "749",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "750",
            name: "B\u00f9i T\u01b0 To\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "751", name: "C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "752", name: "C1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "753", name: "C2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "754", name: "C3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "755",
            name: "C\u1ea7u K\u00eanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "756", name: "C\u1ea7u Kinh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "757",
            name: "C\u00e2y C\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "758",
            name: "Chi\u1ebfn L\u01b0\u1ee3c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "759", name: "CN1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "760", name: "CN11", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "761",
            name: "C\u1ed1ng L\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "762",
            name: "C\u01b0 X\u00e1 Ph\u00fa L\u00e2m C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "763", name: "C\u1eedu Long", prefix: "\u0110\u01b0\u1eddng" },
          { id: "764", name: "D13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "765", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "766", name: "D7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "774",
            name: "D\u01b0\u01a1ng B\u00e1 Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "775",
            name: "D\u01b0\u01a1ng T\u1ef1 Qu\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "767",
            name: "\u0110\u1ea1i L\u1ed9 \u0110\u00f4ng T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "768",
            name: "\u0110\u1ea1i Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "769",
            name: "\u0110\u1ea5t M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "770",
            name: "\u0110\u00ecnh Nghi Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "771",
            name: "\u0110\u00ecnh T\u00e2n Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "772",
            name: "\u0110\u1ed7 N\u0103ng T\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "773",
            name: "\u0110o\u00e0n Ph\u00fa T\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "776", name: "E50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "777", name: "G1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "778", name: "G10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "779", name: "G11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "780", name: "G14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "781", name: "G3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "782", name: "G4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "783", name: "G5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "784", name: "G6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "785", name: "G7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "786", name: "G8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "787", name: "G9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "788", name: "Gia Ph\u00fa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "789",
            name: "G\u00f2 D\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "790",
            name: "G\u00f2 M\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "791",
            name: "G\u00f2 Xo\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "792", name: "H1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "793", name: "H10", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "794",
            name: "H\u00e0m V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "795",
            name: "H\u00e0ng D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "796",
            name: "H\u1ed3 H\u1ecdc L\u00e3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "797",
            name: "H\u1ed3 V\u0103n Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "798",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "799",
            name: "Ho\u00e0ng V\u0103n H\u1ee3p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "800",
            name: "H\u01b0\u01a1ng l\u1ed9  80",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "801",
            name: "H\u01b0\u01a1ng l\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "802",
            name: "H\u01b0\u01a1ng l\u1ed9 17A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "803",
            name: "H\u01b0\u01a1ng l\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "804",
            name: "H\u01b0\u01a1ng L\u1ed9 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "805",
            name: "H\u01b0\u01a1ng l\u1ed9 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "806",
            name: "Huy\u1ec7n l\u1ed9 24B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "807",
            name: "Huy\u1ec7n l\u1ed9 48B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "808",
            name: "Hu\u1ef3nh V\u0103n Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "809", name: "K\u00eanh 19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "810", name: "K\u00eanh 26/3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "811", name: "K\u00eanh C", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "812",
            name: "K\u00eanh N\u01b0\u1edbc \u0110en",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "813",
            name: "K\u00eanh s\u1ed1 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "814",
            name: "K\u00eanh Ti\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "815",
            name: "Khi\u1ebfu N\u0103ng T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "816", name: "Kinh B\u1eafc", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "817",
            name: "Kinh D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "818",
            name: "L\u00e2m Ho\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "819",
            name: "L\u00ea C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "820",
            name: "L\u00ea C\u00f4ng Ph\u00e9p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "821",
            name: "L\u00ea \u0110\u00ecnh C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "822",
            name: "L\u00ea \u0110\u00ecnh D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "823", name: "L\u00ea Ngung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "824",
            name: "L\u00ea T\u1ea5n B\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "825",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "826",
            name: "L\u00ea V\u0103n Qu\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "827",
            name: "L\u00ea V\u0103n Qu\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "828",
            name: "L\u00ea V\u0103n T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "829",
            name: "Li\u00ean \u1ea5p 123",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "830",
            name: "Li\u00ean \u00e2\u0301p 2-6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "831",
            name: "Li\u00ean \u1ea5p 4-5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "832",
            name: "Li\u00ean Doanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "836",
            name: "Li\u00ean Khu 1-6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "833",
            name: "Li\u00ean Khu 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "834",
            name: "Li\u00ean khu 10-11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "835",
            name: "Li\u00ean khu 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "837",
            name: "Li\u00ean Khu 16-18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "838",
            name: "Li\u00ean Khu 2-10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "839",
            name: "Li\u00ean Khu 2-5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "840",
            name: "Li\u00ean Khu 2-6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "841",
            name: "Li\u00ean Khu 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "842",
            name: "Li\u00ean khu 4-5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "843",
            name: "Li\u00ean Khu 45",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "844",
            name: "Li\u00ean Khu 5 - 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "845",
            name: "Li\u00ean Khu 5-10-11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "846",
            name: "Li\u00ean Khu 5-11-12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "847",
            name: "Li\u00ean Khu 7-13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "848",
            name: "Li\u00ean khu 8-9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "849",
            name: "Li\u00ean Khu 89",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "850",
            name: "Li\u00ean L\u1ed9 2-5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "851",
            name: "L\u1ed9 T\u1ebb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "852",
            name: "L\u00f4 T\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "853",
            name: "L\u0169y B\u00e1n B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "854",
            name: "L\u00fd Chi\u00eau Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "855",
            name: "L\u00fd Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "856", name: "M1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "857", name: "M10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "858", name: "M12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "859", name: "M3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "860", name: "M4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "861", name: "M5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "862", name: "M6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "863", name: "M7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "864", name: "M8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "865",
            name: "M\u00e3 L\u00f2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "866",
            name: "Mi\u1ebfu B\u00ecnh \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "867",
            name: "Mi\u1ebfu G\u00f2 \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "868",
            name: "Mi\u1ebfu G\u00f2 Xo\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "869", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "870", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "871", name: "N3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "872", name: "N6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "873",
            name: "Ng\u00f4 Y Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "874",
            name: "Nguy\u1ec5n C\u1eedu Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "875",
            name: "Nguy\u1ec5n \u0110\u00ecnh Ki\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "876",
            name: "Nguy\u1ec5n Hi\u1ebfn L\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "877",
            name: "Nguy\u1ec5n H\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "878",
            name: "Nguy\u1ec5n H\u1eefu Tr\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "879",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "880",
            name: "Nguy\u1ec5n Qu\u00fd Y\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "881",
            name: "Nguy\u1ec5n Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "882",
            name: "Nguy\u1ec5n Th\u1ecb Nh\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "883",
            name: "Nguy\u1ec5n Th\u1ecb T\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "884",
            name: "Nguy\u1ec5n Th\u1ee9c \u0110\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "885",
            name: "Nguy\u1ec5n Th\u1ee9c T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "886",
            name: "Nguy\u1ec5n Tri\u1ec7u Lu\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "887",
            name: "Nguy\u1ec5n Tri\u1ec7u L\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "888",
            name: "Nguy\u1ec5n Tr\u1ecdng Tr\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "889",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "890",
            name: "Nguy\u1ec5n V\u0103n C\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "891",
            name: "N\u01b0\u1edbc L\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "892", name: "P11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "893", name: "P2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "894",
            name: "Ph\u1ea1m B\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "895",
            name: "Ph\u1ea1m \u0110\u0103ng Gi\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "896",
            name: "Ph\u1ea1m \u0110\u0103ng Gi\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "897",
            name: "Ph\u1ea1m V\u0103n Hai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "898", name: "Phan Anh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "899",
            name: "Phan C\u00e1t T\u1ef1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "900",
            name: "Phan \u0110\u0103ng Gi\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "901",
            name: "Phan \u0110\u00ecnh Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "902",
            name: "Phan V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "903",
            name: "Ph\u00f9ng T\u00e1 Chu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "904",
            name: "Qu\u1ed1c L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "905",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "906",
            name: "Qu\u1ed1c l\u1ed9 1C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "907",
            name: "Qu\u1ed1c l\u1ed9 2B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "908",
            name: "Qu\u1ed1c l\u1ed9 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "909",
            name: "Qu\u1ed1c l\u1ed9 46",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "910",
            name: "Qu\u1ed1c l\u1ed9 80",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "911",
            name: "Qu\u1ed1c l\u1ed9 8A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "912", name: "Sinco", prefix: "\u0110\u01b0\u1eddng" },
          { id: "913", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "914", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "915", name: "S\u1ed1 10A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "916", name: "S\u1ed1 10B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "917", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "918", name: "S\u1ed1 11A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "919", name: "S\u1ed1 11B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "920", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "921", name: "S\u1ed1 12C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "922", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "923", name: "S\u1ed1 13A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "924", name: "S\u1ed1 13B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "925", name: "S\u1ed1 13C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "926", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "927", name: "S\u1ed1 14A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "928", name: "S\u1ed1 14B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "929", name: "S\u1ed1 14E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "930", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "931", name: "S\u1ed1 15A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "932", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "933", name: "S\u1ed1 16A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "934", name: "S\u1ed1 16B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "935", name: "S\u1ed1 16D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "936", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "937", name: "S\u1ed1 17A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "938", name: "S\u1ed1 17B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "939", name: "S\u1ed1 17C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "940", name: "S\u1ed1 18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "941", name: "S\u1ed1 18A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "942", name: "S\u1ed1 18B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "943", name: "S\u1ed1 18C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "944", name: "S\u1ed1 18D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "945", name: "S\u1ed1 18E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "946", name: "S\u1ed1 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "947", name: "S\u1ed1 19A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "948", name: "S\u1ed1 19B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "949", name: "S\u1ed1 19C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "950", name: "S\u1ed1 19D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "951", name: "S\u1ed1 19E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "952", name: "S\u1ed1 19F", prefix: "\u0110\u01b0\u1eddng" },
          { id: "953", name: "S\u1ed1 1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "954", name: "S\u1ed1 1B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "955", name: "S\u1ed1 1C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "956", name: "S\u1ed1 1D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "957", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "958", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "959", name: "S\u1ed1 21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "960", name: "S\u1ed1 21A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "961", name: "S\u1ed1 21B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "962", name: "S\u1ed1 21D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "963", name: "S\u1ed1 21E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "964", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "965", name: "S\u1ed1 23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "966", name: "S\u1ed1 23A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "967", name: "S\u1ed1 23B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "968", name: "S\u1ed1 24", prefix: "\u0110\u01b0\u1eddng" },
          { id: "969", name: "S\u1ed1 24A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "970", name: "S\u1ed1 24B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "971", name: "S\u1ed1 25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "972", name: "S\u1ed1 25A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "973", name: "S\u1ed1 26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "974", name: "S\u1ed1 27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "975", name: "S\u1ed1 28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "976", name: "S\u1ed1 28A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "977", name: "S\u1ed1 29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "978", name: "S\u1ed1 2A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "979", name: "S\u1ed1 2B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "980", name: "S\u1ed1 2C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "981", name: "S\u1ed1 2D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "982", name: "S\u1ed1 2E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "983", name: "S\u1ed1 2F", prefix: "\u0110\u01b0\u1eddng" },
          { id: "984", name: "S\u1ed1 2G", prefix: "\u0110\u01b0\u1eddng" },
          { id: "985", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "986", name: "S\u1ed1 30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "987", name: "S\u1ed1 31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "988", name: "S\u1ed1 31A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "989", name: "S\u1ed1 32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "990", name: "S\u1ed1 32A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "991", name: "S\u1ed1 32B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "992", name: "S\u1ed1 33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "993", name: "S\u1ed1 330", prefix: "\u0110\u01b0\u1eddng" },
          { id: "994", name: "S\u1ed1 33A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "995", name: "S\u1ed1 33B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "996", name: "S\u1ed1 34", prefix: "\u0110\u01b0\u1eddng" },
          { id: "997", name: "S\u1ed1 34A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "998", name: "S\u1ed1 36", prefix: "\u0110\u01b0\u1eddng" },
          { id: "999", name: "S\u1ed1 38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1000", name: "S\u1ed1 38A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1001", name: "S\u1ed1 39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1002", name: "S\u1ed1 3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1003", name: "S\u1ed1 3B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1004", name: "S\u1ed1 3C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1005", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1006", name: "S\u1ed1 40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1007", name: "S\u1ed1 41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1008", name: "S\u1ed1 42", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1009", name: "S\u1ed1 43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1010", name: "S\u1ed1 44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1011", name: "S\u1ed1 46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1012", name: "S\u1ed1 46A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1013", name: "S\u1ed1 47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1014", name: "S\u1ed1 48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1015", name: "S\u1ed1 48A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1016", name: "S\u1ed1 48B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1017", name: "S\u1ed1 49", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1018", name: "S\u1ed1 49B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1019", name: "S\u1ed1 49C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1020", name: "S\u1ed1 4A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1021", name: "S\u1ed1 4B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1022", name: "S\u1ed1 4C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1023", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1024", name: "S\u1ed1 50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1025", name: "S\u1ed1 50C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1026", name: "S\u1ed1 51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1027", name: "S\u1ed1 52", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1028", name: "S\u1ed1 53", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1029", name: "S\u1ed1 53B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1030", name: "S\u1ed1 53C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1031", name: "S\u1ed1 54A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1032", name: "S\u1ed1 55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1033", name: "S\u1ed1 55A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1034", name: "S\u1ed1 55B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1035", name: "S\u1ed1 57", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1036", name: "S\u1ed1 57A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1037", name: "S\u1ed1 57C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1038", name: "S\u1ed1 59", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1039", name: "S\u1ed1 59B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1040", name: "S\u1ed1 59C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1041", name: "S\u1ed1 5A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1042", name: "S\u1ed1 5B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1043", name: "S\u1ed1 5C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1044", name: "S\u1ed1 5D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1045", name: "S\u1ed1 5E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1046", name: "S\u1ed1 5F", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1047", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1048", name: "S\u1ed1 6A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1049", name: "S\u1ed1 6B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1050", name: "S\u1ed1 6D", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1051",
            name: "S\u00f4\u0301 6E",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1052", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1053", name: "S\u1ed1 7A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1054", name: "S\u1ed1 7B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1055", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1056", name: "S\u1ed1 8A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1057", name: "S\u1ed1 8B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1058", name: "S\u1ed1 8D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1059", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1060", name: "S\u1ed1 9A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1061", name: "S\u1ed1 9B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1062",
            name: "S\u01a1n K\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1063",
            name: "S\u00f4ng Su\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1064",
            name: "T\u1ea1 M\u1ef9 Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1065",
            name: "T\u00e1m Th\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1066",
            name: "T\u00e2n H\u00f2a \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1067",
            name: "T\u00e2n K\u1ef3 T\u00e2n Qu\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1068",
            name: "T\u00e2n T\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1069",
            name: "T\u00e2n Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1070",
            name: "T\u1eadp \u0110o\u00e0n 6B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1071",
            name: "T\u00e2y L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1072",
            name: "T\u00ean L\u1eeda",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1073",
            name: "T\u1ec9nh L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1074",
            name: "T\u1ec9nh l\u1ed9 10A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1075",
            name: "T\u1ec9nh l\u1ed9 10B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1076",
            name: "T\u1ec9nh l\u1ed9 10D",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1077",
            name: "T\u1ec9nh L\u1ed9 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1078",
            name: "T\u1ec9nh l\u1ed9 7A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1079",
            name: "T\u1ec9nh l\u1ed9 830",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1080",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "1081",
            name: "Tr\u00e2\u0300n Ha\u0309i Phu\u0323ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1082",
            name: "Tr\u1ea7n Thanh M\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1083",
            name: "Tr\u1ea7n Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1084",
            name: "Tr\u1ea7n V\u0103n Gi\u00e0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1085",
            name: "Tr\u01b0\u01a1ng Ph\u01b0\u1edbc Phan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1086",
            name: "Tr\u01b0\u01a1ng Qu\u1ed1c Phan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1087",
            name: "V\u00e0nh \u0110ai 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1088",
            name: "V\u00e0nh \u0110ai Trong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1089",
            name: "V\u0129nh L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1090",
            name: "V\u00f5 Tr\u1ea7n Ch\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1091",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1092",
            name: "V\u00f5 V\u0103n V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1093", name: "V\u0169 H\u1eefu", prefix: "Ph\u1ed1" },
          {
            id: "1094",
            name: "V\u01b0\u01a1ng V\u0103n Hu\u1ed1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1095",
            name: "Xa L\u1ed9 \u0110a\u0323i Ha\u0300n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "67",
            name: "8X Rainbow",
            lat: "10.778969764709",
            lng: "106.6198348999",
          },
          {
            id: "68",
            name: "Adi Lucky Home",
            lat: "10.730208396912",
            lng: "106.61042785644",
          },
          {
            id: "69",
            name: "An L\u1ea1c Plaza",
            lat: "10.751955032349",
            lng: "106.61994171143",
          },
          {
            id: "70",
            name: "Angia Star",
            lat: "10.763066291809",
            lng: "106.59183502197",
          },
          {
            id: "71",
            name: "B\u1eafc L\u01b0\u01a1ng B\u00e8o",
            lat: "10.750173568726",
            lng: "106.58804321289",
          },
          {
            id: "72",
            name: "Da S\u00e0",
            lat: "10.755867958069",
            lng: "106.61878967285",
          },
          {
            id: "73",
            name: "\u0110\u1ea5t Vi\u1ec7t",
            lat: "10.805675506592",
            lng: "106.60506439209",
          },
          {
            id: "74",
            name: "\u0110\u1ee9c Long Western Park",
            lat: "10.737811088562",
            lng: "106.61787414551",
          },
          {
            id: "75",
            name: "EHome 3",
            lat: "10.713743209839",
            lng: "106.60841369629",
          },
          {
            id: "76",
            name: "Full House",
            lat: "10.752190589905",
            lng: "106.60995483398",
          },
          {
            id: "77",
            name: "GoHome Dream Residence",
            lat: "10.807593345642",
            lng: "106.59832763672",
          },
          {
            id: "78",
            name: "Green Park B\u00ecnh T\u00e2n",
            lat: "10.807792663574",
            lng: "106.59342956543",
          },
          {
            id: "79",
            name: "Hai Th\u00e0nh T\u00ean L\u1eeda",
            lat: "10.747918128967",
            lng: "106.61193084717",
          },
          {
            id: "80",
            name: "Ho\u00e0ng Kim Th\u1ebf Gia",
            lat: "10.766724586487",
            lng: "106.61333465576",
          },
          {
            id: "81",
            name: "H\u1ed3ng T\u00e2n",
            lat: "10.765216827393",
            lng: "106.5927658081",
          },
          {
            id: "82",
            name: "HQC 35 H\u1ed3 H\u1ecdc L\u00e3m",
            lat: "10.727537155151",
            lng: "106.60922241211",
          },
          {
            id: "83",
            name: "Imperial Place",
            lat: "10.731081962585",
            lng: "106.61100006103",
          },
          {
            id: "84",
            name: "KCN T\u00e2n T\u1ea1o",
            lat: "10.737069129944",
            lng: "106.59568786621",
          },
          {
            id: "85",
            name: "Khang An Residence",
            lat: "10.723041534424",
            lng: "106.59915924072",
          },
          {
            id: "86",
            name: "Kingsway Tower",
            lat: "10.806018829346",
            lng: "106.60566711426",
          },
          {
            id: "87",
            name: "L\u00ea Th\u00e0nh",
            lat: "10.718202590942",
            lng: "106.61804199219",
          },
          {
            id: "88",
            name: "L\u00ea Th\u00e0nh T\u00e2n T\u1ea1o",
            lat: "10.763973236084",
            lng: "106.57930755615",
          },
          {
            id: "89",
            name: "L\u00ea Th\u00e0nh Twin Towers",
            lat: "10.766373634338",
            lng: "106.60376739502",
          },
          {
            id: "90",
            name: "Long Ph\u1ee5ng Apartment",
            lat: "10.753094673157",
            lng: "106.59164428711",
          },
          {
            id: "91",
            name: "Long Ph\u1ee5ng Residence",
            lat: "10.748139381409",
            lng: "106.62113189697",
          },
          {
            id: "92",
            name: "L\u00fd Chi\u00eau Ho\u00e0ng",
            lat: "10.736235618591",
            lng: "106.61863708496",
          },
          {
            id: "93",
            name: "Manhattan City",
            lat: "10.719304084778",
            lng: "106.60790252685",
          },
          {
            id: "94",
            name: "Moonlight Boulevard",
            lat: "10.741555213928",
            lng: "106.61870574951",
          },
          {
            id: "95",
            name: "Moonlight Park View",
            lat: "10.751832008362",
            lng: "106.62029266357",
          },
          {
            id: "96",
            name: "Nam An Apartment",
            lat: "10.808354377747",
            lng: "106.604637146",
          },
          {
            id: "97",
            name: "Nam H\u00f9ng V\u01b0\u01a1ng",
            lat: "10.723134994507",
            lng: "106.61112213135",
          },
          {
            id: "98",
            name: "Nguy\u1ec5n Quy\u1ec1n Plaza",
            lat: "10.772012710571",
            lng: "106.6217956543",
          },
          {
            id: "99",
            name: "Nh\u00e0 ph\u1ed1 L\u00ea Th\u00e0nh An L\u1ea1c",
            lat: "10.725152015686",
            lng: "106.60549163818",
          },
          {
            id: "100",
            name: "Nh\u1ea5t Lan",
            lat: "10.754416465759",
            lng: "106.59355926514",
          },
          {
            id: "101",
            name: "Nh\u1ea5t Lan 3",
            lat: "10.741172790527",
            lng: "106.59901428223",
          },
          {
            id: "102",
            name: "Saigon West Villas",
            lat: "10.743564605713",
            lng: "106.61312866211",
          },
          {
            id: "103",
            name: "Saigonhomes",
            lat: "10.76775932312",
            lng: "106.5962600708",
          },
          {
            id: "104",
            name: "Smile Home",
            lat: "10.824327468872",
            lng: "106.60120391846",
          },
          {
            id: "105",
            name: "Sonata Residences",
            lat: "10.740301132202",
            lng: "106.61271667481",
          },
          {
            id: "106",
            name: "T\u00e2n Mai",
            lat: "10.762338638306",
            lng: "106.59103393555",
          },
          {
            id: "107",
            name: "T\u00e2n T\u1ea1o Riverside",
            lat: "10.755507469177",
            lng: "106.60473632812",
          },
          {
            id: "108",
            name: "Tecco Town B\u00ecnh T\u00e2n",
            lat: "10.754988670349",
            lng: "106.58432006836",
          },
          {
            id: "109",
            name: "T\u00ean L\u1eeda Residence",
            lat: "10.744913101196",
            lng: "106.58417510986",
          },
          {
            id: "110",
            name: "Ti\u00ean Sa Villas",
            lat: "10.790324211121",
            lng: "106.5986251831",
          },
          {
            id: "111",
            name: "Tri\u1ec1u An Tower",
            lat: "10.731200218201",
            lng: "106.61310577393",
          },
          {
            id: "112",
            name: "V\u0129nh T\u01b0\u1eddng - T\u00e2n T\u1ea1o",
            lat: "10.750463485718",
            lng: "106.59058380127",
          },
          {
            id: "113",
            name: "Vision B\u00ecnh T\u00e2n",
            lat: "10.727779388428",
            lng: "106.59661102295",
          },
          {
            id: "114",
            name: "Welife City",
            lat: "10.809473991394",
            lng: "106.61466217041",
          },
        ],
      },
      {
        id: "3",
        name: "B\u00ecnh Th\u1ea1nh",
        wards: [
          { id: "27", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "28", name: "11", prefix: "Ph\u01b0\u1eddng" },
          { id: "29", name: "12", prefix: "Ph\u01b0\u1eddng" },
          { id: "30", name: "13", prefix: "Ph\u01b0\u1eddng" },
          { id: "31", name: "14", prefix: "Ph\u01b0\u1eddng" },
          { id: "32", name: "15", prefix: "Ph\u01b0\u1eddng" },
          { id: "33", name: "17", prefix: "Ph\u01b0\u1eddng" },
          { id: "34", name: "19", prefix: "Ph\u01b0\u1eddng" },
          { id: "35", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "36", name: "21", prefix: "Ph\u01b0\u1eddng" },
          { id: "37", name: "22", prefix: "Ph\u01b0\u1eddng" },
          { id: "38", name: "24", prefix: "Ph\u01b0\u1eddng" },
          { id: "39", name: "25", prefix: "Ph\u01b0\u1eddng" },
          { id: "40", name: "26", prefix: "Ph\u01b0\u1eddng" },
          { id: "41", name: "27", prefix: "Ph\u01b0\u1eddng" },
          { id: "42", name: "28", prefix: "Ph\u01b0\u1eddng" },
          { id: "43", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "44", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "45", name: "6", prefix: "Ph\u01b0\u1eddng" },
          { id: "46", name: "7", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "1096", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1097", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1098", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1099", name: "12AB", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1100", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1101", name: "135", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1102", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1103", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1104", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1105", name: "179", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1106", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1107", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1108", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1109", name: "233", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1110", name: "25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1111", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1112", name: "30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1113", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1114", name: "304", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1115", name: "37", prefix: "Ph\u1ed1" },
          { id: "1116", name: "3D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1117", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1118", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1119", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1120", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1121", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1122", name: "82", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1123",
            name: "An Th\u01b0\u1ee3ng 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1124",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1125",
            name: "B\u00ecnh H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1126",
            name: "B\u00ecnh L\u00e3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1127",
            name: "B\u00ecnh L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1128",
            name: "B\u00ecnh Qu\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1129",
            name: "B\u00f9i \u0110\u00ecnh T\u00fay",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1130",
            name: "B\u00f9i H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1131",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1132",
            name: "C\u1ea7u V\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1133",
            name: "Chu M\u1ea1nh Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1134",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1135",
            name: "C\u00f4ng Tr\u01b0\u1eddng Ho\u00e0 B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1136",
            name: "C\u00f4ng Tr\u01b0\u1eddng T\u1ef1 Do",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1137", name: "C\u1eedu Long", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1138", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1139", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1140", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1141", name: "D5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1142", name: "D8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1144",
            name: "Di\u00ean H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1150",
            name: "D\u01b0\u01a1ng V\u0169 T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1143",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1145",
            name: "\u0110inh B\u1ed9 L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1146",
            name: "\u0110\u00ecnh \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1147",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1148",
            name: "\u0110\u1ed1ng \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1149",
            name: "\u0110\u1ed1ng \u0110a 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1151",
            name: "H\u00e0ng Xanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1152",
            name: "H\u1ebbm D\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1153",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1154",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1155",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1156",
            name: "H\u1ed3ng B\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1157",
            name: "Hu\u1ef3nh \u0110\u00ecnh Hai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1158",
            name: "Hu\u1ef3nh M\u1eabn \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1159",
            name: "Hu\u1ef3nh T\u1ecbnh C\u1ee7a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1160", name: "Lam S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1161",
            name: "L\u00ea Quang \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1162",
            name: "L\u00ea Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1163",
            name: "L\u00ea V\u0103n S\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1164",
            name: "Long V\u00e2n T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1165",
            name: "L\u01b0\u01a1ng Ng\u1ecdc Quy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1166", name: "L\u01b0\u01a1ng S\u1eed C", prefix: "Ph\u1ed1" },
          {
            id: "1167",
            name: "Mai Xu\u00e2n Th\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1168", name: "M\u00ea Linh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1169",
            name: "Mi\u1ebfu N\u1ed5i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1170", name: "N4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1171",
            name: "Ng\u00f4 \u0110\u1ee9c K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1172",
            name: "Ng\u00f4 Nh\u00e2n T\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1173",
            name: "Ng\u00f4 T\u1ea5t T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1174",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1175",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1176",
            name: "Nguy\u1ec5n C\u00f4ng Hoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1177",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1178",
            name: "Nguy\u1ec5n C\u01b0 Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1179",
            name: "Nguy\u1ec5n C\u1eedu V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1180",
            name: "Nguy\u1ec5n Duy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1181",
            name: "Nguy\u1ec5n H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1182",
            name: "Nguy\u00ean H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1183",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1184",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1185",
            name: "Nguy\u1ec5n H\u1eefu Tho\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1186",
            name: "Nguy\u1ec5n Huy L\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1187",
            name: "Nguy\u1ec5n Huy T\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1188",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1189",
            name: "Nguy\u1ec5n L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1190",
            name: "Nguy\u1ec5n Ng\u1ecdc Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1191",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1192",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1193",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "1194",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1195",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1196",
            name: "Nguy\u1ec5n V\u0103n \u0110\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1197",
            name: "Nguy\u1ec5n V\u0103n L\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1198", name: "Nguy\u1ec5n X\u00ed", prefix: "Ph\u1ed1" },
          {
            id: "1199",
            name: "Nguy\u1ec5n Xu\u00e2n \u00d4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1200",
            name: "N\u01a1 Trang Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1201",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1202",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1203",
            name: "Ph\u1ea1m Vi\u1ebft Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1204", name: "Phan Anh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1205",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1206",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1207",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "1208",
            name: "Phan Huy \u00d4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1209",
            name: "Phan V\u0103n H\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1210",
            name: "Phan V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1211",
            name: "Phan X\u00edch Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1212",
            name: "Ph\u00f3 \u0110\u1ee9c Ch\u00ednh",
            prefix: "Ph\u1ed1",
          },
          { id: "1213", name: "Phong Lan", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1214",
            name: "Ph\u00fa M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1215",
            name: "Qu\u00e1ch V\u0103n Tu\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1216",
            name: "Qu\u1ed1c L\u1ed9 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1217", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1218", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1219", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1220", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1221", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1222", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1223", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1224", name: "S\u1ed1 19R", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1225", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1226", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1227", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1228", name: "S\u1ed1 36", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1229", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1230", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1231", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1232", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1233", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1234",
            name: "S\u1ed1 8 C\u01b0 x\u00e1 Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1235", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1236", name: "T30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1237", name: "T\u1ea7m Vu", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1238",
            name: "T\u00e2n C\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1239",
            name: "T\u0103ng B\u1ea1t H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1240", name: "Thanh \u0110a", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1241",
            name: "Th\u00edch Qu\u1ea3ng \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1242",
            name: "Ti\u1ec1n \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1243",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1244",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1245",
            name: "Tr\u1ea7n K\u1ebf X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1246",
            name: "Tr\u1ea7n Quang \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1247",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1248",
            name: "Tr\u1ea7n V\u0103n K\u1ef7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1249",
            name: "Tr\u1ecbnh Ho\u00e0i \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1250", name: "Tr\u1ee5c", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1251", name: "Tr\u1ee5c 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1252", name: "Tr\u1ee5c 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1253", name: "Tr\u1ee5c 30", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1254",
            name: "Tr\u01b0\u01a1ng \u0110\u0103ng Qu\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1255",
            name: "Tr\u01b0\u1eddng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1256",
            name: "Ung V\u0103n Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1257", name: "V\u1ea1n Ki\u1ebfp", prefix: "Ph\u1ed1" },
          {
            id: "1258",
            name: "V\u00f5 Duy Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1259",
            name: "V\u00f5 Tr\u01b0\u1eddng To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1260",
            name: "V\u0169 Huy T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1261", name: "V\u0169 Ng\u1ecdc Phan", prefix: "Ph\u1ed1" },
          {
            id: "1262",
            name: "V\u0169 T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1263",
            name: "X\u00f4 Vi\u1ebft Ngh\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1264",
            name: "Xu\u00e2n H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1265",
            name: "Y\u00ean \u0110\u1ed7",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "115",
            name: "283 L\u00ea Quang \u0110\u1ecbnh",
            lat: "10.810608863831",
            lng: "106.69203186035",
          },
          {
            id: "116",
            name: "41Bis \u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            lat: "10.801949501038",
            lng: "106.72135925293",
          },
          {
            id: "117",
            name: "B1 Tr\u01b0\u1eddng Sa",
            lat: "10.793082237244",
            lng: "106.70569610596",
          },
          {
            id: "118",
            name: "B\u1eafc B\u00ecnh",
            lat: "10.80127620697",
            lng: "106.72154998779",
          },
          {
            id: "119",
            name: "BCons Tower",
            lat: "10.802098274231",
            lng: "106.71923828125",
          },
          {
            id: "120",
            name: "B\u00ecnh H\u00f2a",
            lat: "10.833158493042",
            lng: "106.69987487793",
          },
          {
            id: "121",
            name: "Cantavil Ho\u00e0n C\u1ea7u",
            lat: "10.798961639404",
            lng: "106.71706390381",
          },
          {
            id: "122",
            name: "Cape Pearl - Petrosetco Tower",
            lat: "10.812224388123",
            lng: "106.72047424316",
          },
          {
            id: "123",
            name: "Chung c\u01b0 M\u1ef9 \u0110\u1ee9c",
            lat: "10.798401832581",
            lng: "106.71290588379",
          },
          {
            id: "124",
            name: "Chung c\u01b0 Nguy\u1ec5n Huy L\u01b0\u1ee3ng",
            lat: "10.805583000183",
            lng: "106.69604492188",
          },
          {
            id: "125",
            name: "Chung c\u01b0 Nguy\u1ec5n Ng\u1ecdc Ph\u01b0\u01a1ng",
            lat: "10.789197921753",
            lng: "106.70844268799",
          },
          {
            id: "126",
            name: "Chung c\u01b0 Ph\u1ea1m Vi\u1ebft Ch\u00e1nh",
            lat: "10.790844917297",
            lng: "106.71070098877",
          },
          {
            id: "127",
            name: "Chung c\u01b0 Ph\u00fa \u0110\u1ea1t",
            lat: "10.80731010437",
            lng: "106.71465301514",
          },
          {
            id: "128",
            name: "Chung c\u01b0 Th\u1ebf K\u1ef7 21",
            lat: "10.801628112793",
            lng: "106.72208404541",
          },
          {
            id: "129",
            name: "City Garden",
            lat: "10.793887138367",
            lng: "106.71131134033",
          },
          {
            id: "130",
            name: "Compa Tower",
            lat: "10.801268577576",
            lng: "106.70848083496",
          },
          {
            id: "131",
            name: "C\u1eedu Long",
            lat: "10.821617126465",
            lng: "106.70127868652",
          },
          {
            id: "132",
            name: "\u0110\u1ea1i An - Saigon Riverside",
            lat: "10.818114280701",
            lng: "106.72048187256",
          },
          {
            id: "133",
            name: "\u0110\u1ea1i Ph\u00fac River View",
            lat: "10.833653450012",
            lng: "106.70095062256",
          },
          {
            id: "134",
            name: "\u0110\u1ea5t Ph\u01b0\u01a1ng Nam",
            lat: "10.81060218811",
            lng: "106.70317077637",
          },
          {
            id: "135",
            name: "EBM Building",
            lat: "10.799454689026",
            lng: "106.72257232666",
          },
          {
            id: "136",
            name: "Elite Park",
            lat: "10.804427146912",
            lng: "106.72057342529",
          },
          {
            id: "137",
            name: "Gic Riverside",
            lat: "10.789640426636",
            lng: "106.7110824585",
          },
          {
            id: "138",
            name: "Gold Land B\u00ecnh Th\u1ea1nh",
            lat: "10.816730499268",
            lng: "106.70629119873",
          },
          {
            id: "139",
            name: "Green Field",
            lat: "10.812856674194",
            lng: "106.71607971191",
          },
          {
            id: "140",
            name: "HUD Building",
            lat: "10.797924041748",
            lng: "106.7038269043",
          },
          {
            id: "141",
            name: "Khu ph\u1ee9c h\u1ee3p 152 \u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            lat: "10.8015832901",
            lng: "106.7121887207",
          },
          {
            id: "142",
            name: "La Bonita",
            lat: "10.80731678009",
            lng: "106.71594238281",
          },
          {
            id: "143",
            name: "Melody Tower",
            lat: "10.798782348633",
            lng: "106.72125244141",
          },
          {
            id: "144",
            name: "Mi\u1ebfu N\u1ed5i",
            lat: "10.795630455017",
            lng: "106.69366455078",
          },
          {
            id: "145",
            name: "M\u1ef9 Ph\u01b0\u1edbc",
            lat: "10.798138618469",
            lng: "106.69905853272",
          },
          {
            id: "146",
            name: "Peace Building",
            lat: "10.800400733948",
            lng: "106.72100067139",
          },
          {
            id: "147",
            name: "Pearl Plaza",
            lat: "10.799614906311",
            lng: "106.71884155273",
          },
          {
            id: "148",
            name: "Qmobile Tower",
            lat: "10.803630828857",
            lng: "106.68909454346",
          },
          {
            id: "149",
            name: "Richmond City",
            lat: "10.81614112854",
            lng: "106.706199646",
          },
          {
            id: "150",
            name: "Rosena B\u00ecnh Th\u1ea1nh",
            lat: "10.805176734924",
            lng: "106.72064971924",
          },
          {
            id: "151",
            name: "Saigon Pearl",
            lat: "10.790362358093",
            lng: "106.71839141846",
          },
          {
            id: "152",
            name: "Saigonland Apartment",
            lat: "10.803030014038",
            lng: "106.71403503418",
          },
          {
            id: "153",
            name: "Saigonres Plaza",
            lat: "10.816153526306",
            lng: "106.70734405518",
          },
          {
            id: "154",
            name: "Samland Building",
            lat: "10.802707672119",
            lng: "106.72121429443",
          },
          {
            id: "155",
            name: "Samland Riverside",
            lat: "10.807012557983",
            lng: "106.71815490723",
          },
          {
            id: "156",
            name: "SGC Nguy\u1ec5n C\u1eedu V\u00e2n",
            lat: "10.797120094299",
            lng: "106.70642852783",
          },
          {
            id: "157",
            name: "Shophouse Vinhomes Central Park",
            lat: "10.788967132568",
            lng: "106.71472167969",
          },
          {
            id: "158",
            name: "Soho Premier",
            lat: "10.811855316162",
            lng: "106.71673583984",
          },
          {
            id: "159",
            name: "Soho Riverview",
            lat: "10.80979347229",
            lng: "106.71208190918",
          },
          {
            id: "160",
            name: "Sun Village Apartment",
            lat: "10.806095123291",
            lng: "106.68685150147",
          },
          {
            id: "161",
            name: "Sunwah Pearl",
            lat: "10.789627075195",
            lng: "106.71766662598",
          },
          {
            id: "162",
            name: "Tecco Central Home",
            lat: "10.805542945862",
            lng: "106.69916534424",
          },
          {
            id: "163",
            name: "Thanh \u0110a View",
            lat: "10.815892219543",
            lng: "106.72001647949",
          },
          {
            id: "164",
            name: "Thanh Ni\u00ean",
            lat: "10.79825592041",
            lng: "106.70669555664",
          },
          {
            id: "165",
            name: "Th\u1ea3o \u0110i\u1ec1n Building",
            lat: "10.805362701416",
            lng: "106.69104766846",
          },
          {
            id: "166",
            name: "The Hyco4 Tower",
            lat: "10.812481880188",
            lng: "106.71025848389",
          },
          {
            id: "167",
            name: "The Manor HCM",
            lat: "10.793809890747",
            lng: "106.71783447266",
          },
          {
            id: "168",
            name: "The Morning Star Plaza",
            lat: "10.813184738159",
            lng: "106.70872497559",
          },
          {
            id: "169",
            name: "TTTM H\u00e0ng Xanh",
            lat: "10.80011844635",
            lng: "106.71097564697",
          },
          {
            id: "170",
            name: "V Building",
            lat: "10.800415992737",
            lng: "106.72231292725",
          },
          {
            id: "171",
            name: "V_Center",
            lat: "10.819754600525",
            lng: "106.70332336426",
          },
          {
            id: "172",
            name: "V_ikon",
            lat: "10.800932884216",
            lng: "106.70845031738",
          },
          {
            id: "173",
            name: "Vin Office",
            lat: "10.804459571838",
            lng: "106.72058868408",
          },
          {
            id: "174",
            name: "Vinhomes Central Park",
            lat: "10.79492855072",
            lng: "106.72074127197",
          },
          {
            id: "175",
            name: "Wilton Tower",
            lat: "10.801501274109",
            lng: "106.71762084961",
          },
        ],
      },
      {
        id: "4",
        name: "C\u1ea7n Gi\u1edd",
        wards: [
          { id: "47", name: "An Th\u1edbi \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "48", name: "B\u00ecnh Kh\u00e1nh", prefix: "X\u00e3" },
          {
            id: "49",
            name: "C\u1ea7n Th\u1ea1nh ",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "50", name: "Long H\u00f2a", prefix: "X\u00e3" },
          { id: "51", name: "L\u00fd Nh\u01a1n", prefix: "X\u00e3" },
          { id: "52", name: "Tam Th\u00f4n Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "53", name: "Th\u1ea1nh An", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "1266", name: "23/10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1267", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1268", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1269", name: "An H\u00f2a", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1270",
            name: "An Ph\u00fa \u0110\u00f4ng 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1271",
            name: "An Th\u1edbi \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1272",
            name: "B\u00e0 X\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1273",
            name: "B\u00ecnh Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1274",
            name: "B\u00ecnh Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1275",
            name: "B\u00f9i L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1276",
            name: "C\u1ea7n Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1277",
            name: "C\u1eedu Vi\u1ec7t 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1282",
            name: "D\u01b0\u01a1ng V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1283",
            name: "Duy\u00ean H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1278",
            name: "\u0110\u1eb7ng V\u0103n Ki\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1279",
            name: "\u0110\u00e0o C\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1280",
            name: "\u0110\u00ea G\u1ed1c Tre",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1281",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1284", name: "EC", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1285", name: "Gi\u1ed1ng Ao", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1286", name: "G\u1ed1c Tre", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1287",
            name: "H\u00e0 Quang V\u00f3c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1288",
            name: "H\u1eadu L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1289",
            name: "Hi\u1ec7p Th\u00e0nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1290",
            name: "Khi\u00eau N\u0103ng T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1291",
            name: "Khi\u1ebfu N\u0103ng T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1292",
            name: "L\u00ea C\u00f4ng Mi\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1293",
            name: "L\u00ea H\u00f9ng Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1294",
            name: "L\u00ea Th\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1295",
            name: "L\u00ea Tr\u1ecdng M\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1296",
            name: "L\u1ed9c H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1297",
            name: "L\u01b0\u01a1ng V\u0103n Nho",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1298",
            name: "L\u00fd Nh\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1299",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1300",
            name: "M\u01b0\u01a1ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1301",
            name: "Ng\u1ecdc Ng\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1302",
            name: "Nguy\u1ec5n Huy Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1303",
            name: "Nguy\u1ec5n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1304",
            name: "Nguy\u1ec5n Phan Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1305",
            name: "Nguy\u1ec5n V\u0103n M\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1306",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1307",
            name: "Phan \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1308",
            name: "Phan Tr\u1ecdng Tu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1309",
            name: "Phong Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1310",
            name: "Ph\u01b0\u1edbc L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1311",
            name: "R\u1ea1ch L\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1312",
            name: "R\u1ea7n Ch\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1313",
            name: "R\u1eebng S\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1314",
            name: "T\u1eafc Xu\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1315",
            name: "Tam Th\u00f4n Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1316",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1317",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1318",
            name: "Th\u1ea1nh L\u1ed9c 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1319",
            name: "Th\u1ea1nh Th\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1320",
            name: "Th\u1ea1nh Xu\u00e2n 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1321",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1322",
            name: "Tr\u1ea7n Quang \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1323",
            name: "Tr\u1ea7n Quang Nh\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1324",
            name: "Tr\u1ea7n Quang Qu\u1eddn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1325",
            name: "Tr\u00edch S\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1326",
            name: "Tr\u01b0\u01a1ng T\u1ea5n Sang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1327",
            name: "Tr\u01b0\u01a1ng V\u0103n \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1328",
            name: "V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1329",
            name: "V\u00f5 \u0110\u00ecnh T\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1330",
            name: "V\u00f5 Trung Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1331", name: "V\u00f2ng Ao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1332",
            name: "V\u01b0\u1eddn Thu\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "176",
            name: "La Maison De C\u1ea7n Gi\u1edd",
            lat: "10.41042137146",
            lng: "106.9547958374",
          },
        ],
      },
      {
        id: "5",
        name: "C\u1ee7 Chi",
        wards: [
          { id: "54", name: "An Nh\u01a1n T\u00e2y", prefix: "X\u00e3" },
          { id: "55", name: "An Ph\u00fa", prefix: "X\u00e3" },
          { id: "56", name: "An Ph\u00fa Trung", prefix: "X\u00e3" },
          { id: "57", name: "B\u00ecnh M\u1ef9", prefix: "X\u00e3" },
          { id: "58", name: "C\u1ee7 Chi", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "59", name: "H\u00f2a Ph\u00fa", prefix: "X\u00e3" },
          { id: "60", name: "Nhu\u1eadn \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "61", name: "Ph\u1ea1m V\u0103n C\u1ed9i", prefix: "X\u00e3" },
          {
            id: "62",
            name: "Ph\u00fa H\u00f2a \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "63", name: "Ph\u00fa M\u1ef9 H\u01b0ng", prefix: "X\u00e3" },
          { id: "64", name: "Ph\u01b0\u1edbc Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "65", name: "Ph\u01b0\u1edbc Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "66", name: "Ph\u01b0\u1edbc V\u0129nh An", prefix: "X\u00e3" },
          { id: "67", name: "T\u00e2n An H\u1ed9i", prefix: "X\u00e3" },
          { id: "68", name: "T\u00e2n Ph\u00fa Trung", prefix: "X\u00e3" },
          {
            id: "69",
            name: "T\u00e2n Th\u1ea1nh \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "70", name: "T\u00e2n Th\u1ea1nh T\u00e2y", prefix: "X\u00e3" },
          { id: "71", name: "T\u00e2n Th\u00f4ng H\u1ed9i", prefix: "X\u00e3" },
          { id: "72", name: "Th\u00e1i M\u1ef9", prefix: "X\u00e3" },
          { id: "73", name: "Trung An", prefix: "X\u00e3" },
          { id: "74", name: "Trung L\u1eadp H\u1ea1", prefix: "X\u00e3" },
          { id: "75", name: "Trung L\u1eadp H\u1ea1", prefix: "X\u00e3" },
          {
            id: "76",
            name: "Trung L\u1eadp Th\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
        ],
        streets: [
          { id: "1333", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1334", name: "101", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1335", name: "103", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1336", name: "107", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1337", name: "108", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1338", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1339", name: "110", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1340", name: "111", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1341", name: "112", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1342", name: "114", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1343", name: "115", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1344", name: "116", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1345", name: "117", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1346", name: "118", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1347", name: "119", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1348", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1349", name: "122", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1350", name: "123", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1351", name: "129", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1352", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1353", name: "130", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1354", name: "131", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1355", name: "132", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1356", name: "141", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1357", name: "148", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1358", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1359", name: "153", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1360", name: "156", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1361", name: "157", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1362", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1363", name: "163", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1364", name: "164", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1365", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1366", name: "171", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1367", name: "177", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1368", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1369", name: "182", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1370", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1371", name: "191", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1372", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1373", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1374", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1375", name: "210", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1376", name: "212", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1377", name: "214", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1378", name: "217", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1379", name: "218", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1380", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1381", name: "220", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1382", name: "223", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1383", name: "225", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1384", name: "226", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1385", name: "22A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1386", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1387", name: "230", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1388", name: "233", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1389", name: "26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1390", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1391", name: "32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1392", name: "321", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1393", name: "33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1394", name: "339", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1395", name: "35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1396", name: "355", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1397", name: "36", prefix: "Ph\u1ed1" },
          { id: "1398", name: "363", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1399", name: "364", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1400", name: "365", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1401", name: "367", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1402", name: "368", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1403", name: "372", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1404", name: "374", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1405", name: "379", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1406", name: "39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1407", name: "397B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1408", name: "40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1409", name: "406", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1410", name: "41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1411", name: "411", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1412", name: "419", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1413", name: "423", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1414", name: "43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1415", name: "436", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1416", name: "441", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1417", name: "455", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1418", name: "456", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1419", name: "457", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1420", name: "458", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1421", name: "46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1422", name: "463", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1423", name: "464", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1424", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1425", name: "473", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1426", name: "489", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1427", name: "490", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1428", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1429", name: "500", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1430", name: "508", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1431", name: "51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1432", name: "517", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1433", name: "53", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1434", name: "530", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1435", name: "531", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1436", name: "538", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1437", name: "540", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1438", name: "556", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1439", name: "570", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1440", name: "572", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1441", name: "577", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1442", name: "58", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1443", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1444", name: "614", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1445", name: "618", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1446", name: "628", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1447", name: "63", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1448", name: "64", prefix: "Ph\u1ed1" },
          { id: "1449", name: "65", prefix: "Ph\u1ed1" },
          { id: "1450", name: "66", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1451", name: "68", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1452", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1453", name: "702", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1454", name: "715", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1455", name: "717", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1456", name: "74", prefix: "Ph\u1ed1" },
          { id: "1457", name: "791", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1458", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1459", name: "817A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1460", name: "84", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1461", name: "89", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1462", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1463", name: "92", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1464", name: "93", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1465", name: "94", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1466", name: "96", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1467", name: "97", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1468", name: "98", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1469", name: "9A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1470", name: "A61", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1471", name: "An H\u00f2a", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1472",
            name: "An Nh\u01a1n T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1473", name: "Ba L\u0103ng", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1474", name: "Ba Sa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1475",
            name: "B\u00e0 Thi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1476",
            name: "B\u00e0u Gi\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1477", name: "B\u00e0u L\u00e1ch", prefix: "Ph\u1ed1" },
          {
            id: "1478",
            name: "B\u1ea7u Tr\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1479", name: "B\u00e0u Tre", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1480",
            name: "B\u1ebfn \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1481",
            name: "B\u00ea\u0301n \u0110o\u0300 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1482",
            name: "B\u1ebfn \u0110\u00f2 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1483",
            name: "B\u1ebfn L\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1484",
            name: "B\u1ebfn S\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1485", name: "B\u1ebfn Than", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1486",
            name: "B\u00ecnh M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1487",
            name: "B\u1edd K\u00eanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1488",
            name: "B\u00f9i Th\u1ecb \u0110i\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1489",
            name: "B\u00f9i Th\u1ecb Me",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1490",
            name: "B\u00f9i Th\u1ecb Ng\u1ecdn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1491",
            name: "B\u00f9i V\u0103n Ba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1492",
            name: "B\u01b0ng Tr\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1493", name: "C2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1494",
            name: "C\u00e1 L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1495",
            name: "Can Tr\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1496",
            name: "C\u00e1nh \u0110\u1ed3ng D\u01b0\u1ee3c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1497",
            name: "Cao Thi \u0110\u00e1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1498",
            name: "C\u1ea7u D\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1499",
            name: "C\u00e2y B\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1500", name: "C\u00e2y Da", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1501",
            name: "C\u00e2y \u0110i\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1502",
            name: "C\u00e2y G\u00f5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1503",
            name: "C\u00e2y S\u1ed9p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1504",
            name: "C\u00e2y Tr\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1505",
            name: "C\u00e2y Tr\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1506",
            name: "C\u00e2y Tr\u00f4m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1507", name: "CN3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1508",
            name: "C\u1eedu Vi\u1ec7t 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1509", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1510", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1511", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1512", name: "D35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1513", name: "D4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1514", name: "D436", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1515", name: "D5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1531",
            name: "D\u01b0\u01a1ng Th\u1ecb T\u00e8n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1516",
            name: "\u0110\u1eb7ng Chi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1517",
            name: "\u0110\u1eb7ng Th\u00fac V\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1518",
            name: "\u0110\u00e0o Th\u1ecb Th\u01a1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1519",
            name: "\u0110\u00e0o V\u0103n Th\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1520",
            name: "\u0110\u00ecnh Th\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1521",
            name: "\u0110\u1ed7 \u0110\u0103ng Tuy\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1522",
            name: "\u0110\u1ed7 \u0110\u00ecnh Nh\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1523",
            name: "\u0110\u1ed7 Ng\u1ecdc Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1524",
            name: "\u0110\u1ed7 Quan C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1525",
            name: "\u0110\u1ed7 Th\u1ecb X\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1526",
            name: "\u0110o\u00e0n Minh Tri\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1527",
            name: "\u0110\u1ed3ng M\u00e3 V\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1528", name: "\u0110T 511", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1529", name: "\u0110T 826", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1530", name: "\u0110T 9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1532",
            name: "Gi\u00e1p H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1533",
            name: "Gi\u1ed3ng C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1534",
            name: "Gi\u1ed3ng Sao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1535",
            name: "G\u00f2 Ch\u00f9a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1536",
            name: "G\u00f3t Ch\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1537",
            name: "H\u00e0 Duy Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1538",
            name: "H\u00e0 V\u0103n Lao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1539",
            name: "H\u1ed1 B\u00f2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1540",
            name: "H\u1ed3 Th\u1ecb B\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1541",
            name: "H\u1ed3 V\u0103n \u01a0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1542",
            name: "H\u1ed3 V\u0103n T\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1543",
            name: "Ho\u00e0ng B\u00e1 Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1544",
            name: "Ho\u00e0ng \u0110\u00ecnh Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1545", name: "Hu Go", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1546",
            name: "H\u01b0\u01a1ng l\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1547",
            name: "H\u01b0\u01a1ng l\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1548",
            name: "H\u01b0\u01a1ng L\u1ed9 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1549",
            name: "Huy\u1ec7n \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1550",
            name: "Hu\u1ef3nh Minh M\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1551",
            name: "Hu\u1ef3nh Th\u1ecb B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1552",
            name: "Hu\u1ef3nh Th\u1ecb Tri\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1553",
            name: "Hu\u1ef3nh Th\u1ecb X\u01b0a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1554",
            name: "Hu\u1ef3nh V\u0103n C\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1555", name: "K\u00eanh 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1556", name: "K\u00eanh 8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1557",
            name: "K\u00eanh B\u00ecnh Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1558",
            name: "K\u00eanh \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1559",
            name: "Khi\u00eau N\u0103ng T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1560",
            name: "Khi\u1ebfu N\u0103ng T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1561",
            name: "Kim C\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1562", name: "L\u00e1ng The", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1563",
            name: "L\u00ea C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1564",
            name: "L\u00ea C\u00f4ng Mi\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1565",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1566",
            name: "L\u00ea Minh Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1567",
            name: "L\u00ea Minh Nh\u1ef1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1568",
            name: "L\u00ea Th\u1ecb Ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1569",
            name: "L\u00ea Th\u1ecb Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1570",
            name: "L\u00ea Th\u1ecb L\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1571",
            name: "L\u00ea Th\u1ecb Ng\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1572",
            name: "L\u00ea Th\u1ecb N\u1eeda",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1573",
            name: "L\u00ea Th\u1ecb Si\u00eang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1574",
            name: "L\u00ea Th\u1ecb Vui",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1575",
            name: "L\u00ea Th\u1ecd Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1576",
            name: "L\u00ea V\u0103n Kh\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1577",
            name: "L\u00ea V\u0129nh Huy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1578",
            name: "Li\u1ec5u B\u00ecnh H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1579",
            name: "Li\u00eau B\u00ecnh H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1580",
            name: "L\u01b0u Khai H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1581",
            name: "Mai Th\u1ecb Bu\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1582",
            name: "M\u0169i L\u1edbn 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1583",
            name: "M\u1ef9 Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1584", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1585", name: "N3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1586",
            name: "Ng\u00f4 Tri Ho\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1587",
            name: "Nguy\u1ec5n \u0110\u1ea1i N\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1588",
            name: "Nguy\u1ec5n \u0110\u00ecnh Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1589",
            name: "Nguy\u1ec5n Giao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1590",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1591",
            name: "Nguy\u1ec5n Kim C\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1592",
            name: "Nguy\u1ec5n Phong S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1593",
            name: "Nguy\u1ec5n Ph\u00fa Tr\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1594",
            name: "Nguy\u1ec5n Th\u1ecb Ch\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1595",
            name: "Nguy\u1ec5n Th\u1ecb D\u1ea1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1596",
            name: "Nguy\u1ec5n Th\u1ecb Hai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1597",
            name: "Nguy\u1ec5n Th\u1ecb H\u1eb9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1598",
            name: "Nguy\u1ec5n Th\u1ecb Ki\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1599",
            name: "Nguy\u1ec5n Th\u1ecb L\u1eafm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1600",
            name: "Nguy\u1ec5n Th\u1ecb L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1601",
            name: "Nguy\u1ec5n Th\u1ecb L\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1602",
            name: "Nguy\u1ec5n Th\u1ecb L\u1eeba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1603",
            name: "Nguy\u1ec5n Th\u1ecb Mi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1604",
            name: "Nguy\u1ec5n Th\u1ecb N\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1605",
            name: "Nguy\u1ec5n Th\u1ecb N\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1606",
            name: "Nguy\u1ec5n Th\u1ecb Qu\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1607",
            name: "Nguy\u1ec5n Th\u1ecb R\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1608",
            name: "Nguy\u1ec5n Th\u1ecb R\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1609",
            name: "Nguy\u1ec5n Th\u1ecb R\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1610",
            name: "Nguy\u1ec5n Th\u1ecb Se",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1611",
            name: "Nguy\u1ec5n Th\u1ecb Tri\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1612",
            name: "Nguy\u1ec5n V\u0103n Ho\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1613",
            name: "Nguy\u1ec5n V\u0103n Kh\u1ea1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1614",
            name: "Nguy\u1ec5n V\u0103n Ni",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1615",
            name: "Nguy\u1ec5n V\u0103n On",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1616",
            name: "Nguy\u1ec5n V\u0103n Ti\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1617",
            name: "Nguy\u1ec5n V\u0103n T\u1ef7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1618",
            name: "Nguy\u1ec5n V\u0103n X\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1619",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1620",
            name: "Nh\u1eef Ti\u1ebfn Hi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1621",
            name: "Nhu\u1eadn \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1622", name: "Ninh T\u1ed1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1623",
            name: "\u00d4ng \u00cdch \u0110\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1624",
            name: "\u00d4ng N\u0103m \u0110\u00e8n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1625",
            name: "\u00d4ng T\u01b0 R\u00f5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1626",
            name: "Ph\u1ea1m H\u1eefu T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1627",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1628",
            name: "Ph\u1ea1m Ph\u00fa Ti\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1629",
            name: "Ph\u1ea1m Th\u1ecb \u0110i\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1630",
            name: "Ph\u1ea1m Th\u1ecb Li\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1631",
            name: "Ph\u1ea1m Th\u1ecb R\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1632",
            name: "Ph\u1ea1m V\u0103n Ch\u00e8o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1633",
            name: "Ph\u1ea1m V\u0103n C\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1634",
            name: "Phan Th\u1ecb H\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1635",
            name: "Ph\u00fa Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1636",
            name: "Ph\u00fa M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1637",
            name: "Ph\u00fa Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1638",
            name: "Qu\u1ed1c l\u1ed9 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1639",
            name: "Qu\u1ed1c L\u1ed9 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1640",
            name: "R\u1ea1ch B\u00ecnh Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1641", name: "Samco", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1642", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1643", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1644", name: "S\u1ed1 102", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1645", name: "S\u1ed1 10A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1646", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1647", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1648", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1649", name: "S\u1ed1 13A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1650", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1651", name: "S\u1ed1 143", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1652", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1653", name: "S\u1ed1 153", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1654", name: "S\u1ed1 157", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1655", name: "S\u1ed1 164", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1656", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1657", name: "S\u1ed1 171", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1658", name: "S\u1ed1 173", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1659", name: "S\u1ed1 175", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1660", name: "S\u1ed1 177", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1661", name: "S\u1ed1 179", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1662", name: "S\u1ed1 18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1663", name: "S\u1ed1 180", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1664", name: "S\u1ed1 181", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1665", name: "S\u1ed1 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1666", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1667", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1668", name: "S\u1ed1 210", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1669", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1670", name: "S\u1ed1 23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1671", name: "S\u1ed1 231", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1672", name: "S\u1ed1 25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1673", name: "S\u1ed1 27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1674", name: "S\u1ed1 28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1675", name: "S\u1ed1 29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1676", name: "S\u1ed1 2D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1677", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1678", name: "S\u1ed1 30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1679", name: "S\u1ed1 31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1680", name: "S\u1ed1 33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1681", name: "S\u1ed1 35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1682", name: "S\u1ed1 36", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1683", name: "S\u1ed1 37", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1684", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1685", name: "S\u1ed1 409", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1686", name: "S\u1ed1 41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1687", name: "S\u1ed1 42", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1688", name: "S\u1ed1 43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1689", name: "S\u1ed1 44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1690", name: "S\u1ed1 46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1691", name: "S\u1ed1 49", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1692", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1693", name: "S\u1ed1 519", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1694", name: "S\u1ed1 55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1695", name: "S\u1ed1 597", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1696", name: "S\u1ed1 60", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1697", name: "S\u1ed1 626", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1698", name: "S\u1ed1 63", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1699", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1700", name: "S\u1ed1 71", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1701", name: "S\u1ed1 72", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1702", name: "S\u1ed1 74", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1703", name: "S\u1ed1 77", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1704", name: "S\u1ed1 787", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1705", name: "S\u1ed1 79", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1706", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1707", name: "S\u1ed1 87", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1708", name: "S\u1ed1 89", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1709", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1710", name: "S\u1ed1 94", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1711",
            name: "Song H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1712", name: "S\u00f4ng Lam", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1713", name: "S\u00f4ng Lu", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1714",
            name: "Su\u1ed1i L\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1715", name: "T8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1716", name: "Tam T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1717",
            name: "T\u00e2n An H\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1718",
            name: "T\u00e2n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1719",
            name: "T\u00e2n H\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1720", name: "T\u00e2n Quy", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1721", name: "Th\u1ea7y Cai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1722",
            name: "Thi\u1ebfu Ni\u00ean 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1723",
            name: "T\u1ec9nh L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1724",
            name: "T\u1ec9nh l\u1ed9 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1725",
            name: "T\u1ec9nh l\u1ed9 15B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1726",
            name: "T\u1ec9nh l\u1ed9 181",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1727",
            name: "T\u1ec9nh L\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1728",
            name: "T\u1ec9nh l\u1ed9 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1729",
            name: "T\u1ec9nh L\u1ed9 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1730",
            name: "T\u1ec9nh l\u1ed9 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1731",
            name: "T\u1ec9nh L\u1ed9 80",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1732",
            name: "T\u1ec9nh l\u1ed9 822",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1733",
            name: "T\u1ec9nh l\u1ed9 823",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1734",
            name: "T\u1ec9nh l\u1ed9 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1735",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1736",
            name: "Tr\u1ea7n B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1737",
            name: "Tr\u1ea7n Th\u1ecb Ki\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1738",
            name: "Tr\u1ea7n Th\u1ecb Ng\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1739",
            name: "Tr\u1ea7n Th\u1ecb Tr\u00f2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1740",
            name: "Tr\u1ea7n Th\u1ecb Xong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1741",
            name: "Tr\u1ea7n T\u1eed B\u00ecnh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "1742",
            name: "Tr\u1ea7n V\u0103n Ch\u1ea9m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1743", name: "Trung An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1744",
            name: "Trung L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1745",
            name: "Trung Vi\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1746",
            name: "Tr\u01b0\u01a1ng T\u1ea5n Sang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1747",
            name: "Tr\u01b0\u01a1ng Th\u1ecb Ki\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1748",
            name: "V\u00e0nh \u0110ai 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1749", name: "Vinh Long", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1750",
            name: "V\u00f5 \u0110\u00ecnh T\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1751",
            name: "V\u00f5 Th\u1ecb Chu\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1752",
            name: "V\u00f5 Th\u1ecb Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1753",
            name: "V\u00f5 Th\u1ecb H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1754",
            name: "V\u00f5 Th\u1ecb Ngh\u1ec9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1755",
            name: "V\u00f5 Trung Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1756",
            name: "V\u00f5 V\u0103n B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1757",
            name: "V\u00f5 V\u0103n D\u1eady",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1758",
            name: "V\u00f5 V\u0103n \u0110i\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1759",
            name: "V\u0169 Duy Ch\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1760",
            name: "V\u0169 T\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1761",
            name: "V\u01b0\u1eddn Thu\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1762",
            name: "Xuy\u00ean \u00c1",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "177",
            name: "Bella Vista City",
            lat: "10.938284873962",
            lng: "106.46308898926",
          },
          {
            id: "178",
            name: "Bi\u1ec7t th\u1ef1 C\u1ee7 Chi",
            lat: "11.074751853943",
            lng: "106.47609710693",
          },
          {
            id: "179",
            name: "B\u00ecnh M\u1ef9 Riverside",
            lat: "10.965720176697",
            lng: "106.63985443115",
          },
          {
            id: "180",
            name: "C.T Sphinx",
            lat: "10.926317214966",
            lng: "106.5037612915",
          },
          {
            id: "181",
            name: "KDC Little S\u00e0i G\u00f2n",
            lat: "10.965323448181",
            lng: "106.49444580078",
          },
          {
            id: "182",
            name: "Khu \u0111\u00f4 th\u1ecb B\u1ebfn Th\u00e0nh T\u00e2y B\u1eafc",
            lat: "10.965455055237",
            lng: "106.4937210083",
          },
          {
            id: "183",
            name: "Lucky Garden",
            lat: "10.94824886322",
            lng: "106.64617156982",
          },
          {
            id: "184",
            name: "Th\u00e0nh Nam Dragon",
            lat: "10.964915275574",
            lng: "106.49507141113",
          },
          {
            id: "185",
            name: "Thi\u00ean Ph\u00fac Garden",
            lat: "10.984469413757",
            lng: "106.5657119751",
          },
        ],
      },
      {
        id: "6",
        name: "G\u00f2 V\u1ea5p",
        wards: [
          { id: "77", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "78", name: "10", prefix: "Ph\u01b0\u1eddng" },
          { id: "79", name: "11", prefix: "Ph\u01b0\u1eddng" },
          { id: "80", name: "12", prefix: "Ph\u01b0\u1eddng" },
          { id: "81", name: "13", prefix: "Ph\u01b0\u1eddng" },
          { id: "82", name: "14", prefix: "Ph\u01b0\u1eddng" },
          { id: "83", name: "15", prefix: "Ph\u01b0\u1eddng" },
          { id: "84", name: "16", prefix: "Ph\u01b0\u1eddng" },
          { id: "85", name: "17", prefix: "Ph\u01b0\u1eddng" },
          { id: "86", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "87", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "88", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "89", name: "6", prefix: "Ph\u01b0\u1eddng" },
          { id: "90", name: "7", prefix: "Ph\u01b0\u1eddng" },
          { id: "91", name: "8", prefix: "Ph\u01b0\u1eddng" },
          { id: "92", name: "9", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "1763", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1764", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1765", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1766", name: "111", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1767", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1768", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1769", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1770", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1771", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1772", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1773", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1774", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1775", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1776", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1777", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1778", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1779", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1780", name: "24", prefix: "Ph\u1ed1" },
          { id: "1781", name: "24A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1782", name: "25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1783", name: "26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1784", name: "26/3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1785", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1786", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1787", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1788", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1789", name: "30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1790", name: "31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1791", name: "32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1792", name: "33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1793", name: "35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1794", name: "359", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1795", name: "36", prefix: "Ph\u1ed1" },
          { id: "1796", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1797", name: "40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1798", name: "41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1799", name: "43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1800", name: "44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1801", name: "440", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1802", name: "45", prefix: "Ph\u1ed1" },
          { id: "1803", name: "46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1804", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1805", name: "48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1806", name: "49", prefix: "Ph\u1ed1" },
          { id: "1807", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1808", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1809", name: "51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1810", name: "52", prefix: "Ph\u1ed1" },
          { id: "1811", name: "53", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1812", name: "55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1813", name: "56", prefix: "Ph\u1ed1" },
          { id: "1814", name: "57", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1815", name: "58", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1816", name: "59", prefix: "Ph\u1ed1" },
          { id: "1817", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1818", name: "60", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1819", name: "61", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1820", name: "66", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1821", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1822", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1823", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1824", name: "An H\u1ed9i", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1825", name: "An L\u1ed9c", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1826", name: "An Nh\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1827",
            name: "An Ph\u00fa \u0110\u00f4ng 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1828", name: "B2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1829", name: "B4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1830", name: "B5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1831",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1832",
            name: "B\u1ebfn \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1833",
            name: "B\u00f9i Quang L\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1834", name: "C2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1835",
            name: "C\u00e2y Th\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1836",
            name: "C\u00e2y Tr\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1837", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1838", name: "D11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1839", name: "D19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1840", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1841", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1842", name: "D4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1843", name: "D7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1848",
            name: "D\u01b0\u01a1ng Qu\u1ea3ng H\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1844",
            name: "\u0110\u1ed7 Ph\u00fac Th\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1845",
            name: "\u0110\u1ed7 Ph\u00fac T\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1846",
            name: "\u0110\u1ed7 Th\u00fac T\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1847",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1849",
            name: "H\u00e0 Duy Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1850",
            name: "H\u00e0n Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1851",
            name: "H\u1ea1nh Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1852",
            name: "H\u1ea1nh Th\u00f4ng T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1853",
            name: "H\u1eadu L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1854",
            name: "Hi\u1ec7p Th\u00e0nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1855",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1856",
            name: "Ho\u00e0ng Minh Gi\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1857",
            name: "Hu\u1ef3nh Kh\u01b0\u01a1ng An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1858",
            name: "Hu\u1ef3nh V\u0103n Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1859", name: "Lam S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1860",
            name: "L\u00ea \u0110\u1ee9c Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1861",
            name: "L\u00ea Ho\u00e0ng Ph\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1862", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1863",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1864",
            name: "L\u00ea Quang \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1865",
            name: "L\u00ea Th\u1ecb H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1866",
            name: "L\u00ea V\u0103n \u0110\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1867",
            name: "L\u00ea V\u0103n Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1868",
            name: "L\u00ea V\u0103n Th\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1869",
            name: "L\u00ea V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1870",
            name: "L\u1ed9c H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1871",
            name: "L\u01b0\u01a1ng Ng\u1ecdc Quy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1872",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1873",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "1874",
            name: "M\u01b0\u01a1ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1875",
            name: "Ng\u00f4 T\u1ea5t T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1876",
            name: "Nguy\u1ec5n B\u00e1 Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1877",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1878",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1879",
            name: "Nguy\u1ec5n Duy Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1880",
            name: "Nguy\u00ean H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1881",
            name: "Nguy\u1ec5n Huy Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1882",
            name: "Nguy\u1ec5n Huy \u0110i\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1883",
            name: "Nguy\u1ec5n Huy Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1884",
            name: "Nguy\u1ec5n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1885",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1886",
            name: "Nguy\u1ec5n Ki\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1887",
            name: "Nguy\u1ec5n Oanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1888",
            name: "Nguy\u1ec5n Th\u00e1i S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1889",
            name: "Nguy\u1ec5n Th\u1ecb H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1890",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1891",
            name: "Nguy\u1ec5n T\u01b0 Gi\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1892",
            name: "Nguy\u1ec5n Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1893",
            name: "Nguy\u1ec5n V\u0103n B\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1894",
            name: "Nguy\u1ec5n V\u0103n C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1896",
            name: "Nguy\u1ec5n V\u0103n Dung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1895",
            name: "Nguy\u1ec5n V\u0103n \u0110\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1897",
            name: "Nguy\u1ec5n V\u0103n \u0110\u1eebng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1898",
            name: "Nguy\u1ec5n V\u0103n Kh\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1899",
            name: "Nguy\u1ec5n V\u0103n Lu\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1900",
            name: "Nguy\u1ec5n V\u0103n L\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1901",
            name: "Nguy\u1ec5n V\u0103n Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1902",
            name: "N\u00fai M\u1ed9t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1903", name: "Ph\u1ea1m Huy Th\u00f4ng", prefix: "Ph\u1ed1" },
          {
            id: "1904",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1905",
            name: "Ph\u1ea1m Ph\u00fa Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1906",
            name: "Ph\u1ea1m V\u0103n B\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1907",
            name: "Ph\u1ea1m V\u0103n Chi\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1908",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1909",
            name: "Phan Huy \u00cdch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1910",
            name: "Phan V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1911",
            name: "Ph\u00fa Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1912",
            name: "Qu\u00e2n S\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1913",
            name: "Qu\u1ea3ng H\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1914", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1915",
            name: "Qu\u1ed1c l\u1ed9 54",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1916",
            name: "R\u1ea1ch B\u00e0 Mi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "1917", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1918", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1919", name: "S\u1ed1 102", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1920", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1921", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1922", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1923", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1924", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1925", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1926", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1927", name: "S\u1ed1 18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1928", name: "S\u1ed1 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1929", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1930", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1931", name: "S\u1ed1 21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1932", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1933", name: "S\u1ed1 23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1934", name: "S\u1ed1 24", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1935", name: "S\u1ed1 25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1936", name: "S\u1ed1 26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1937", name: "S\u1ed1 27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1938", name: "S\u1ed1 28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1939", name: "S\u1ed1 29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1940", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1941", name: "S\u1ed1 30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1942", name: "S\u1ed1 31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1943", name: "S\u1ed1 32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1944", name: "S\u1ed1 34", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1945", name: "S\u1ed1 35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1946", name: "S\u1ed1 36", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1947", name: "S\u1ed1 38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1948", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1949", name: "S\u1ed1 40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1950", name: "S\u1ed1 43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1951", name: "S\u1ed1 44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1952", name: "S\u1ed1 45", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1953", name: "S\u1ed1 46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1954", name: "S\u1ed1 48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1955", name: "S\u1ed1 49", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1956", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1957", name: "S\u1ed1 50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1958", name: "S\u1ed1 51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1959", name: "S\u1ed1 52", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1960", name: "S\u1ed1 53", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1961", name: "S\u1ed1 54", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1962", name: "S\u1ed1 55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1963", name: "S\u1ed1 56", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1964", name: "S\u1ed1 57", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1965", name: "S\u1ed1 59", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1966", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1967", name: "S\u1ed1 61", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1968", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1969", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1970", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "1971", name: "S\u1ed1 N1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "1972",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1973",
            name: "T\u00e2n S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1974",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1975",
            name: "Th\u00e1i An 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1976",
            name: "Th\u1ea1nh L\u1ed9c 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1977",
            name: "Th\u1ea1nh Xu\u00e2n 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1978",
            name: "Th\u1ea1nh Xu\u00e2n 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1979",
            name: "Th\u00edch B\u1eedu \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1980",
            name: "Th\u00edch B\u1eedu Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1981",
            name: "Th\u00edch B\u1eedu Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1982",
            name: "Thi\u00ean H\u1ed9 D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1983",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1984",
            name: "Th\u00f4ng T\u00e2y H\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1985",
            name: "T\u00f4 Ng\u1ecdc V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1986",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1987",
            name: "Tr\u1ea7n B\u00e1 Giao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1988",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1989",
            name: "Tr\u1ea7n Ph\u00fa C\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1990",
            name: "Tr\u1ea7n Quang \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1991",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1992",
            name: "Tr\u1ea7n Qu\u1ed1c Tu\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1993",
            name: "Tr\u1ea7n Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1994",
            name: "Tr\u1ea7n Th\u1ecb Ngh\u0129",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1995",
            name: "Tr\u00edch S\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1996",
            name: "Tr\u01b0ng N\u1eef V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1997",
            name: "Tr\u01b0\u01a1ng \u0110\u0103ng Qu\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1998",
            name: "Tr\u01b0\u01a1ng Minh Gi\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "1999",
            name: "Tr\u01b0\u1eddng Minh K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2000",
            name: "Tr\u01b0\u01a1ng V\u0103n \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2001",
            name: "T\u00fa M\u1ee1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2002",
            name: "V\u0169 Thanh T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2003", name: "Xa La", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2004", name: "Y\u00ean Hoa", prefix: "Ph\u1ed1" },
        ],
        projects: [
          {
            id: "186",
            name: "26 Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            lat: "10.814686775207",
            lng: "106.6872253418",
          },
          {
            id: "187",
            name: "8X Th\u00e1i An",
            lat: "10.834434509277",
            lng: "106.63457489014",
          },
          {
            id: "188",
            name: "An L\u1ed9c",
            lat: "10.846782684326",
            lng: "106.6767578125",
          },
          {
            id: "189",
            name: "Bee Home 2",
            lat: "10.853917121887",
            lng: "106.66494750977",
          },
          {
            id: "190",
            name: "Cityland Garden Hills",
            lat: "10.824227333069",
            lng: "106.69188690185",
          },
          {
            id: "191",
            name: "Cityland Park Hills",
            lat: "10.834345817566",
            lng: "106.66839599609",
          },
          {
            id: "192",
            name: "Cityland Z751",
            lat: "10.828784942627",
            lng: "106.67973327637",
          },
          {
            id: "193",
            name: "C\u00f4ng Vi\u00ean V\u0103n H\u00f3a G\u00f2 V\u1ea5p",
            lat: "10.838552474976",
            lng: "106.67033386231",
          },
          {
            id: "194",
            name: "Dream Home",
            lat: "10.854777336121",
            lng: "106.64982604981",
          },
          {
            id: "195",
            name: "Dream Home Residence",
            lat: "10.855331420898",
            lng: "106.65269470215",
          },
          {
            id: "196",
            name: "Easy Home T\u00e2n Mai",
            lat: "10.851682662964",
            lng: "106.668800354",
          },
          {
            id: "197",
            name: "Felix Homes",
            lat: "10.84929561615",
            lng: "106.68047332764",
          },
          {
            id: "198",
            name: "Gia Ph\u00e1t Apartment",
            lat: "10.843927383423",
            lng: "106.67369842529",
          },
          {
            id: "199",
            name: "Gia Tu\u1ec7 Central Square",
            lat: "10.839688301086",
            lng: "106.68199920654",
          },
          {
            id: "200",
            name: "Green Home Residence",
            lat: "10.847910881043",
            lng: "106.67025756836",
          },
          {
            id: "201",
            name: "Green Park Residences",
            lat: "10.819437980652",
            lng: "106.6728439331",
          },
          {
            id: "202",
            name: "H\u00e0 \u0110\u00f4 Nguy\u1ec5n V\u0103n C\u00f4ng",
            lat: "10.82470703125",
            lng: "106.67847442627",
          },
          {
            id: "203",
            name: "H\u00e0 Ki\u1ec1u",
            lat: "10.83584022522",
            lng: "106.68850708008",
          },
          {
            id: "204",
            name: "I-Home 1",
            lat: "10.850742340088",
            lng: "106.65377044678",
          },
          {
            id: "205",
            name: "Khang Gia G\u00f2 V\u1ea5p",
            lat: "10.84365272522",
            lng: "106.63561248779",
          },
          {
            id: "206",
            name: "Masteri M-One G\u00f2 V\u1ea5p",
            lat: "10.817939758301",
            lng: "106.68627929688",
          },
          {
            id: "207",
            name: "Osimi Tower",
            lat: "10.841645240784",
            lng: "106.67705535889",
          },
          {
            id: "208",
            name: "Ph\u00fa Gia H\u01b0ng Apartment",
            lat: "10.850607872009",
            lng: "106.67350006103",
          },
          {
            id: "209",
            name: "Plaza Nguy\u00ean H\u1ed3ng",
            lat: "10.818247795105",
            lng: "106.69104003906",
          },
          {
            id: "210",
            name: "Samland Airport",
            lat: "10.818264961243",
            lng: "106.69192504883",
          },
          {
            id: "211",
            name: "Sunny Plaza",
            lat: "10.814759254456",
            lng: "106.68216705322",
          },
          {
            id: "212",
            name: "Sunview 3",
            lat: "10.848922729492",
            lng: "106.64950561523",
          },
          {
            id: "213",
            name: "The Splendor",
            lat: "10.849657058716",
            lng: "106.68095397949",
          },
          {
            id: "214",
            name: "Thi\u00ean L\u1ed9c Tower",
            lat: "10.851188659668",
            lng: "106.65518188477",
          },
          {
            id: "215",
            name: "Unik Home",
            lat: "10.853189468384",
            lng: "106.65170288086",
          },
          {
            id: "216",
            name: "V\u0103n Lang Residence",
            lat: "10.827880859375",
            lng: "106.69004821777",
          },
          {
            id: "217",
            name: "Z751 H\u00e0 \u0110\u00f4",
            lat: "10.836288452148",
            lng: "106.66637420654",
          },
          {
            id: "218",
            name: "Z751B H\u00e0 \u0110\u00f4",
            lat: "10.830677032471",
            lng: "106.6784362793",
          },
        ],
      },
      {
        id: "7",
        name: "H\u00f3c M\u00f4n",
        wards: [
          { id: "93", name: " \u0110\u00f4ng Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "94", name: " H\u00f3c M\u00f4n", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "95",
            name: "B\u00e0 \u0110i\u1ec3m",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "96", name: "Nh\u1ecb B\u00ecnh", prefix: "X\u00e3" },
          { id: "97", name: "T\u00e2n Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "98", name: "T\u00e2n Th\u1edbi Nh\u00ec", prefix: "X\u00e3" },
          { id: "99", name: "T\u00e2n Xu\u00e2n", prefix: "X\u00e3" },
          { id: "100", name: "Th\u1edbi Tam Th\u00f4n", prefix: "X\u00e3" },
          { id: "101", name: "Trung Ch\u00e1nh", prefix: "X\u00e3" },
          {
            id: "102",
            name: "Xu\u00e2n Th\u1edbi \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "103",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n",
            prefix: "X\u00e3",
          },
          {
            id: "104",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
        ],
        streets: [
          { id: "2005", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2006", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2007", name: "10A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2008", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2009", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2010", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2011", name: "14F", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2012", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2013", name: "18A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2014", name: "19/1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2015", name: "1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2016", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2017", name: "2/9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2018", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2019", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2020", name: "23/9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2021", name: "24", prefix: "Ph\u1ed1" },
          { id: "2022", name: "25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2023", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2024", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2025", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2026", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2027", name: "39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2028", name: "3C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2029", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2030", name: "411", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2031", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2032", name: "5E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2033", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2034",
            name: "6 \u0110\u00f4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2035", name: "60", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2036", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2037", name: "719", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2038", name: "7B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2039", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2040", name: "80B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2041", name: "824", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2042", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2043", name: "A-3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2044", name: "A5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2045", name: "A7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2046", name: "An H\u1ea1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2047",
            name: "An S\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2048", name: "\u1ea4p 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2049", name: "\u1ea4p 4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2050",
            name: "\u1ea4p Ch\u00e1nh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2051",
            name: "\u1ea4p Ch\u00e1nh 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2052",
            name: "\u1ea4p Ch\u00e1nh 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2053",
            name: "\u1ea4p Ch\u00e1nh 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2054",
            name: "\u1ea4p Ch\u00e1nh 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2055",
            name: "\u1ea4p Ch\u00e1nh 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2056",
            name: "\u1ea4p Ch\u00e1nh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2057",
            name: "\u1ea4p \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2058",
            name: "\u1ea4p \u0110\u00ecnh 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2059",
            name: "\u1ea4p \u0110\u00ecnh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2060",
            name: "\u1ea4p \u0110\u00ecnh 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2061",
            name: "\u1ea4p \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2062",
            name: "\u1ea4p \u0110\u00f4ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2063",
            name: "\u1ea4p \u0110\u00f4ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2064",
            name: "\u1ea4p \u0110\u00f4ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2065",
            name: "\u1ea4p \u0110\u00f4ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2066",
            name: "\u1ea4p \u0110\u00f4ng 4-4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2067",
            name: "\u1ea4p \u0110\u00f4ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2068",
            name: "\u1ea4p \u0110\u00f4ng 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2069",
            name: "\u1ea4p M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2070",
            name: "\u1ea4p M\u1edbi 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2071",
            name: "\u1ea4p M\u1edbi 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2072",
            name: "\u1ea4p M\u1edbi 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2073",
            name: "\u1ea4p M\u1edbi 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2074",
            name: "\u1ea4p M\u1edbi 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2075", name: "B12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2076", name: "B2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2077", name: "B4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2078", name: "B5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2079",
            name: "B\u00e0 \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2080",
            name: "B\u00e0 \u0110i\u1ec3m 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2081",
            name: "B\u00e0 \u0110i\u1ec3m 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2082",
            name: "B\u00e0 \u0110i\u1ec3m 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2083",
            name: "B\u00e0 \u0110i\u1ec1m 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2084",
            name: "B\u00e0 \u0110i\u1ec3m 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2085",
            name: "B\u00e0 \u0110i\u1ec3m 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2086",
            name: "B\u00e0 \u0110i\u1ec3m 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2087",
            name: "B\u00e0 \u0110i\u1ec3m 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2088",
            name: "B\u00e0 \u0110i\u1ec3m 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2089",
            name: "B\u00e0 \u0110i\u1ec3m 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2090",
            name: "B\u00e0 \u0110i\u1ec3m 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2091",
            name: "B\u00e0 \u0110i\u1ec3m 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2092",
            name: "B\u00e0 \u0110i\u1ec3m 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2093",
            name: "B\u00e0 \u0110i\u1ec3m 8D",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2094",
            name: "B\u00e0 \u0110i\u1ec3m 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2095",
            name: "B\u00e0 H\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2096", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "2097",
            name: "B\u1eafc L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2098",
            name: "B\u1eafc L\u00e2n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2099",
            name: "B\u1eafc L\u00e2n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2100",
            name: "B\u1eafc L\u00e2n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2101",
            name: "B\u1eb1ng L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2102", name: "BN1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2103",
            name: "B\u1edd K\u00eanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2104", name: "B\u1edd Kinh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2105",
            name: "B\u1edd Kinh 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2106",
            name: "B\u00f9i C\u00f4ng Tr\u1eebng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2107",
            name: "B\u00f9i Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2108",
            name: "B\u00f9i Th\u1ecb L\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2109",
            name: "B\u00f9i V\u0103n Ng\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2110",
            name: "B\u00f9i V\u0103n Th\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2111", name: "C1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2112", name: "C3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2113",
            name: "Ch\u00f9a Hang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2114", name: "CN2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2115",
            name: "C\u1ed1ng H\u1ed9p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2116", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2117", name: "D11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2118", name: "D19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2119", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2120", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2121", name: "D4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2122", name: "D5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2123", name: "D7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2124",
            name: "D\u00e2n Th\u1eafng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2125",
            name: "D\u00e2n Th\u1eafng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2130", name: "DH5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2132", name: "DK4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2133", name: "DN 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2134", name: "DN2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2192",
            name: "D\u01b0\u01a1ng C\u00f4ng Khi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2193",
            name: "D\u01b0\u01a1ng Kh\u1ea3 Tr\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2194",
            name: "D\u01b0\u01a1ng Qu\u1ea3ng H\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2126",
            name: "\u0110\u1eb7ng C\u00f4ng B\u1ec9nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2127",
            name: "\u0110\u1eb7ng Qu\u1ed1c V\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2128",
            name: "\u0110\u1eb7ng Th\u00fac V\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2129",
            name: "\u0110\u1ea5t M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2131",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2135",
            name: "\u0110\u1ed7 V\u0103n D\u1eady",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2136",
            name: "\u0110\u1ed3ng B\u00e0 Canh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2137",
            name: "\u0110\u00f4ng L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2138",
            name: "\u0110\u00f4ng L\u00e2n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2139",
            name: "\u0110\u00f4ng L\u00e2n 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2140",
            name: "\u0110\u00f4ng L\u00e2n 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2141",
            name: "\u0110\u00f4ng L\u00e2n 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2142",
            name: "\u0110\u1ed3ng T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2143",
            name: "\u0110\u00f4ng Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2144",
            name: "\u0110\u00f4ng Th\u1ea1nh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2145",
            name: "\u0110\u00f4ng Th\u1ea1nh 1-1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2146",
            name: "\u0110\u00f4ng Th\u1ea1nh 1-2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2148",
            name: "\u0110\u00f4ng Th\u1ea1nh 1-3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2150",
            name: "\u0110\u00f4ng Th\u1ea1nh 1-4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2151",
            name: "\u0110\u00f4ng Th\u1ea1nh 1-5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2147",
            name: "\u0110\u00f4ng Th\u1ea1nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2149",
            name: "\u0110\u00f4ng Th\u1ea1nh 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2152",
            name: "\u0110\u00f4ng Th\u1ea1nh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2153",
            name: "\u0110\u00f4ng Th\u1ea1nh 2-1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2154",
            name: "\u0110\u00f4ng Th\u1ea1nh 2-2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2155",
            name: "\u0110\u00f4ng Th\u1ea1nh 2-3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2156",
            name: "\u0110\u00f4ng Th\u1ea1nh 2-3-1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2157",
            name: "\u0110\u00f4ng Th\u1ea1nh 2-4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2158",
            name: "\u0110\u00f4ng Th\u1ea1nh 2-5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2159",
            name: "\u0110\u00f4ng Th\u1ea1nh 2-6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2160",
            name: "\u0110\u00f4ng Th\u1ea1nh 2-7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2161",
            name: "\u0110\u00f4ng Th\u1ea1nh 2-8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2162",
            name: "\u0110\u00f4ng Th\u1ea1nh 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2163",
            name: "\u0110\u00f4ng Th\u1ea1nh 3 - 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2164",
            name: "\u0110\u00f4ng Th\u1ea1nh 3-1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2165",
            name: "\u0110\u00f4ng Th\u1ea1nh 3-2-1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2166",
            name: "\u0110\u00f4ng Th\u1ea1nh 3-3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2167",
            name: "\u0110\u00f4ng Th\u1ea1nh 3-4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2168",
            name: "\u0110\u00f4ng Th\u1ea1nh 3-4A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2169",
            name: "\u0110\u00f4ng Th\u1ea1nh 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2170",
            name: "\u0110\u00f4ng Th\u1ea1nh 4-1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2171",
            name: "\u0110\u00f4ng Th\u1ea1nh 4-2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2172",
            name: "\u0110\u00f4ng Th\u1ea1nh 4-2-1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2173",
            name: "\u0110\u00f4ng Th\u1ea1nh 4-3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2174",
            name: "\u0110\u00f4ng Th\u1ea1nh 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2175",
            name: "\u0110\u00f4ng Th\u1ea1nh 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2176",
            name: "\u0110\u00f4ng Th\u1ea1nh 6 - 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2177",
            name: "\u0110\u00f4ng Th\u1ea1nh 6-1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2178",
            name: "\u0110\u00f4ng Th\u1ea1nh 6-3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2179",
            name: "\u0110\u00f4ng Th\u1ea1nh 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2180",
            name: "\u0110\u00f4ng Th\u1ea1nh 7-1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2181",
            name: "\u0110\u00f4ng Th\u1ea1nh 7-3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2183",
            name: "\u0110\u00f4ng Th\u1ea1nh 7-4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2184",
            name: "\u0110\u00f4ng Th\u1ea1nh 7-5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2182",
            name: "\u0110\u00f4ng Th\u1ea1nh 74",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2185",
            name: "\u0110\u00f4ng Th\u1ea1nh 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2186",
            name: "\u0110\u00f4ng Th\u1ea1nh 8-1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2187", name: "\u0110T 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2188", name: "\u0110T 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2189", name: "\u0110T 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2190", name: "\u0110T 7-5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2191", name: "\u0110T 824", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2195", name: "E32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2196", name: "F11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2197", name: "G1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2198", name: "G2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2199",
            name: "Gi\u00e1c \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2200",
            name: "G\u00f2 H\u01b0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2201", name: "H2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2202",
            name: "H\u00e0 Duy Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2203",
            name: "H\u00e0 N\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2204",
            name: "H\u00e0n Qu\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2205",
            name: "H\u1eadu L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2206", name: "HB2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2207",
            name: "Hi\u1ec7p Th\u00e0nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2208", name: "HN1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2209",
            name: "H\u1ed3 Ng\u1ecdc C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2210", name: "H\u00f2a An 8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2211",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2212",
            name: "Ho\u00e0ng C\u1ea3nh Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2213",
            name: "Ho\u00e0ng Duy Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2214",
            name: "Ho\u00e0ng Gia",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2215",
            name: "H\u01b0ng L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2216",
            name: "H\u01b0ng L\u00e2n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2217",
            name: "H\u01b0ng L\u00e2n 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2218",
            name: "H\u01b0\u01a1ng l\u1ed9  6A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2219",
            name: "H\u01b0\u01a1ng l\u1ed9  80",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2220",
            name: "H\u01b0\u01a1ng l\u1ed9 60",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2221",
            name: "H\u01b0\u01a1ng l\u1ed9 60B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2222",
            name: "H\u01b0\u01a1ng L\u1ed9 65",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2223",
            name: "H\u01b0\u01a1ng L\u00f4\u0323 80B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2224",
            name: "Hu\u1ef3nh Th\u1ecb M\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2225",
            name: "Hu\u1ef3nh Th\u1ecb Na",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2226", name: "K1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2227", name: "K3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2228", name: "K\u00eanh 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2229", name: "K\u00eanh 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2230", name: "K\u00eanh 5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2231",
            name: "K\u00eanh An H\u1ea1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2232", name: "Kim An", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2233", name: "KP 3-7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2234", name: "KT 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2235", name: "KT19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2236", name: "KT20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2237", name: "KT21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2238", name: "KT4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2239", name: "KT8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2240",
            name: "L\u00ea Ho\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2241", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2242",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2243", name: "L\u00ea Mai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2244",
            name: "L\u00ea Nh\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2245",
            name: "L\u00ea Th\u1ecb H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2246",
            name: "L\u00ea Th\u1ecb H\u00e0 T\u00e2n Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2247",
            name: "L\u00ea Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2248",
            name: "L\u00ea Th\u1ecb Kim",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2249",
            name: "L\u00ea Th\u1ecb Kim Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2250",
            name: "L\u00ea Th\u1ecb L\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2251",
            name: "L\u00ea Th\u1ecb Ri\u00eang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2252",
            name: "L\u00ea Th\u1ecb Th\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2253",
            name: "L\u00ea V\u0103n Kh\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2254",
            name: "L\u00ea V\u0103n S\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2255",
            name: "Li\u00ean \u1ea4p 2-6-7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2256",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2257",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2258",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2259",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          { id: "2260", name: "M4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2261",
            name: "M\u1ef9 H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2262",
            name: "M\u1ef9 H\u00f2a 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2263",
            name: "M\u1ef9 Ho\u00e0 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2264",
            name: "M\u1ef9 Ho\u00e0 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2265",
            name: "M\u1ef9 H\u00f2a 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2266",
            name: "M\u1ef9 Hu\u1ec1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2267",
            name: "M\u1ef9 Hu\u1ec1 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2268",
            name: "M\u1ef9 Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2269",
            name: "M\u1ef9 Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2270", name: "N18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2271", name: "N21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2272", name: "N3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2273", name: "N6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2274", name: "Nam Kinh", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2275", name: "Nam L\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2276",
            name: "Nam L\u00e2n 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2277",
            name: "Nam L\u00e2n 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2278",
            name: "Nam L\u00e2n 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2279", name: "Nam Th\u1edbi", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2280",
            name: "Nam Th\u1edbi 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2281",
            name: "Nam Th\u1edbi 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2282",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2283",
            name: "Nguy\u1ec5n \u1ea2nh Th\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2284",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2285",
            name: "Nguy\u1ec5n C\u00f4ng Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2286",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2287",
            name: "Nguy\u1ec5n H\u1eefu Tu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2288",
            name: "Nguy\u1ec5n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2289",
            name: "Nguy\u1ec5n Minh Tri\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2290",
            name: "Nguy\u1ec5n Ng\u1ecdc Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2291",
            name: "Nguy\u1ec5n Ng\u1ecdc Sanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2292",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2293",
            name: "Nguy\u1ec5n Th\u1ecb B\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2294",
            name: "Nguy\u1ec5n Th\u1ecb B\u00fap",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2295",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2296",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ea7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2297",
            name: "Nguy\u1ec5n Th\u1ecb \u0110i\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2298",
            name: "Nguy\u1ec5n Th\u1ecb H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2299",
            name: "Nguy\u1ec5n Th\u1ecb Hu\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2300",
            name: "Nguy\u1ec5n Th\u1ecb Kim",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2301",
            name: "Nguy\u1ec5n Th\u1ecb L\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2302",
            name: "Nguy\u1ec5n Th\u1ecb L\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2303",
            name: "Nguy\u1ec5n Th\u1ecb L\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2304",
            name: "Nguy\u1ec5n Th\u1ecb L\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2305",
            name: "Nguy\u1ec5n Th\u1ecb L\u1ee5a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2306",
            name: "Nguy\u1ec5n Th\u1ecb Ly",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2307",
            name: "Nguy\u1ec5n Th\u1ecb L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2308",
            name: "Nguy\u1ec5n Th\u1ecb M\u01b0\u1eddi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2309",
            name: "Nguy\u1ec5n Th\u1ecb Na",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2310",
            name: "Nguy\u1ec5n Th\u1ecb Ng\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2311",
            name: "Nguy\u1ec5n Th\u1ecb Nh\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2312",
            name: "Nguy\u1ec5n Th\u1ecb Nh\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2313",
            name: "Nguy\u1ec5n Th\u1ecb N\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2314",
            name: "Nguy\u1ec5n Th\u1ecb Nu\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2315",
            name: "Nguy\u1ec5n Th\u1ecb Pha",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2316",
            name: "Nguy\u1ec5n Th\u1ecb R\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2317",
            name: "Nguy\u1ec5n Th\u1ecb S\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2318",
            name: "Nguy\u1ec5n Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2319",
            name: "Nguy\u1ec5n Th\u1ecb S\u00f3c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2320",
            name: "Nguy\u1ec5n Th\u1ecb S\u01b0a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2321",
            name: "Nguy\u1ec5n Th\u1ecb Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2322",
            name: "Nguy\u1ec5n Th\u1ecb Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2323",
            name: "Nguy\u1ec5n Th\u1ecb Th\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2324",
            name: "Nguy\u1ec5n Th\u1ecb Th\u1ecfa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2325",
            name: "Nguy\u1ec5n Th\u1ecb Th\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2326",
            name: "Nguy\u1ec5n Th\u1ecb X\u00e9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2327",
            name: "Nguy\u1ec5n V\u0103n B\u1ee9a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2328",
            name: "Nguy\u1ec5n V\u0103n D\u1eady",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2329", name: "NH3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2330",
            name: "Nh\u00e0 Vu\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2331",
            name: "Nh\u1ecb B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2332",
            name: "Nh\u1ecb B\u00ecnh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2333",
            name: "Nh\u1ecb B\u00ecnh 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2334",
            name: "Nh\u1ecb B\u00ecnh 10A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2335",
            name: "Nh\u1ecb B\u00ecnh 10C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2336",
            name: "Nh\u1ecb B\u00ecnh 10D",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2337",
            name: "Nh\u1ecb B\u00ecnh 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2338",
            name: "Nh\u1ecb B\u00ecnh 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2339",
            name: "Nh\u1ecb B\u00ecnh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2340",
            name: "Nh\u1ecb B\u00ecnh 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2341",
            name: "Nh\u1ecb B\u00ecnh 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2342",
            name: "Nh\u1ecb B\u00ecnh 15A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2343",
            name: "Nh\u1ecb B\u00ecnh 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2344",
            name: "Nh\u1ecb B\u00ecnh 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2345",
            name: "Nh\u1ecb B\u00ecnh 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2346",
            name: "Nh\u1ecb B\u00ecnh 18C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2347",
            name: "Nh\u1ecb B\u00ecnh 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2348",
            name: "Nh\u1ecb B\u00ecnh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2349",
            name: "Nh\u1ecb B\u00ecnh 20A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2350",
            name: "Nh\u1ecb B\u00ecnh 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2351",
            name: "Nh\u1ecb B\u00ecnh 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2352",
            name: "Nh\u1ecb B\u00ecnh 2B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2353",
            name: "Nh\u1ecb B\u00ecnh 2D",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2354",
            name: "Nh\u1ecb B\u00ecnh 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2355",
            name: "Nh\u1ecb B\u00ecnh 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2356",
            name: "Nh\u1ecb B\u00ecnh 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2357",
            name: "Nh\u1ecb B\u00ecnh 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2358",
            name: "Nh\u1ecb B\u00ecnh 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2359",
            name: "Nh\u1ecb B\u00ecnh 7-5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2360",
            name: "Nh\u1ecb B\u00ecnh 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2361",
            name: "Nh\u1ecb B\u00ecnh 8C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2362",
            name: "Nh\u1ecb B\u00ecnh 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2363",
            name: "Nh\u1ecb B\u00ecnh 9A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2364",
            name: "Nh\u1ecb T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2365",
            name: "Nh\u1ecb T\u00e2n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2366",
            name: "Nh\u1ecb T\u00e2n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2367",
            name: "\u1ed0ng N\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2368",
            name: "Ph\u1ea1m Th\u1ecb Gi\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2369",
            name: "Ph\u1ea1m V\u0103n S\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2370",
            name: "Phan C\u00f4ng H\u1edbn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2371",
            name: "Ph\u1ea7n L\u0103ng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2372",
            name: "Phan V\u0103n \u0110\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2373",
            name: "Phan V\u0103n H\u1edbn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2374", name: "QH", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2375", name: "QH2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2376",
            name: "Qu\u00e1ch \u0110i\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2377", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2378",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2379",
            name: "Qu\u1ed1c L\u1ed9 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2380",
            name: "Qu\u1ed1c l\u1ed9 22A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2381",
            name: "Qu\u1ed1c l\u1ed9 22B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2382",
            name: "R\u1ea1ch B\u00e0 H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2383",
            name: "R\u1ea1ch B\u1edd \u0110\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2384",
            name: "R\u1ea1ch Nhum",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2385",
            name: "R\u1ed7ng B\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2386", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2387", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2388", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2389", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2390", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2391", name: "S\u1ed1 14A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2392", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2393", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2394", name: "S\u1ed1 210", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2395", name: "S\u1ed1 27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2396", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2397", name: "S\u1ed1 37", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2398", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2399", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2400", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2401", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2402", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2403", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2404",
            name: "Song H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2405",
            name: "Song H\u00e0nh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2406",
            name: "Song H\u00e0nh 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2407", name: "T10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2408", name: "T2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2409", name: "T3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2410", name: "T4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2411", name: "T7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2412",
            name: "Tam \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2413",
            name: "Tam \u0110\u00f4ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2414",
            name: "Tam \u0110\u00f4ng 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2415",
            name: "Tam \u0110\u00f4ng 11A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2416",
            name: "Tam \u0110\u00f4ng 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2417",
            name: "Tam \u0110\u00f4ng 12A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2418",
            name: "Tam \u0110\u00f4ng 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2419",
            name: "Tam \u0110\u00f4ng 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2420",
            name: "Tam \u0110\u00f4ng 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2421",
            name: "Tam \u0110\u00f4ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2422",
            name: "Tam \u0110\u00f4ng 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2423",
            name: "Tam \u0110\u00f4ng 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2424",
            name: "Tam \u0110\u00f4ng 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2425",
            name: "Tam \u0110\u00f4ng 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2426",
            name: "Tam \u0110\u00f4ng 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2427",
            name: "Tam \u0110\u00f4ng 28",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2428",
            name: "Tam \u0110\u00f4ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2429",
            name: "Tam \u0110\u00f4ng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2430", name: "Tam T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2431",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2432",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2433",
            name: "T\u00e2n Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2434",
            name: "T\u00e2n Hi\u1ec7p 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2445",
            name: "T\u00e2n Hi\u1ec7p 1-7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2435",
            name: "T\u00e2n Hi\u1ec7p 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2436",
            name: "T\u00e2n Hi\u1ec7p 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2437",
            name: "T\u00e2n Hi\u1ec7p 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2438",
            name: "T\u00e2n Hi\u1ec7p 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2439",
            name: "T\u00e2n Hi\u1ec7p 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2440",
            name: "T\u00e2n Hi\u1ec7p 14-1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2441",
            name: "T\u00e2n hi\u1ec7p 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2442",
            name: "T\u00e2n Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2443",
            name: "T\u00e2n Hi\u1ec7p 16-1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2444",
            name: "T\u00e2n Hi\u1ec7p 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2446",
            name: "T\u00e2n Hi\u1ec7p 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2447",
            name: "T\u00e2n Hi\u1ec7p 18A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2448",
            name: "T\u00e2n Hi\u1ec7p 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2449",
            name: "T\u00e2n Hi\u1ec7p 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2450",
            name: "T\u00e2n Hi\u1ec7p 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2451",
            name: "T\u00e2n Hi\u1ec7p 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2452",
            name: "T\u00e2n Hi\u1ec7p 28",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2453",
            name: "T\u00e2n Hi\u1ec7p 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2454",
            name: "T\u00e2n Hi\u1ec7p 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2455",
            name: "T\u00e2n Hi\u1ec7p 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2456",
            name: "T\u00e2n Hi\u1ec7p 30-1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2457",
            name: "T\u00e2n Hi\u1ec7p 31",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2458",
            name: "T\u00e2n Hi\u1ec7p 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2459",
            name: "T\u00e2n Hi\u1ec7p 34",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2460",
            name: "T\u00e2n Hi\u1ec7p 35",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2461",
            name: "T\u00e2n Hi\u1ec7p 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2462",
            name: "T\u00e2n Hi\u1ec7p 39",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2463",
            name: "T\u00e2n Hi\u1ec7p 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2466",
            name: "T\u00e2n Hi\u1ec7p 4-2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2468",
            name: "T\u00e2n Hi\u1ec7p 4-3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2471",
            name: "T\u00e2n Hi\u1ec7p 4-5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2464",
            name: "T\u00e2n Hi\u1ec7p 40",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2465",
            name: "T\u00e2n Hi\u1ec7p 41",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2467",
            name: "T\u00e2n Hi\u1ec7p 43",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2469",
            name: "T\u00e2n Hi\u1ec7p 44",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2470",
            name: "T\u00e2n Hi\u1ec7p 45",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2472",
            name: "T\u00e2n Hi\u1ec7p 46",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2473",
            name: "T\u00e2n Hi\u1ec7p 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2474",
            name: "T\u00e2n Hi\u1ec7p 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2475",
            name: "T\u00e2n Hi\u1ec7p 6B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2476",
            name: "T\u00e2n Hi\u1ec7p 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2477",
            name: "T\u00e2n Hi\u1ec7p 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2478",
            name: "T\u00e2n Hi\u1ec7p 8-1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2479",
            name: "T\u00e2n Hi\u1ec7p 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2480",
            name: "T\u00e2n Hi\u1ec7p 9-1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2481",
            name: "T\u00e2n Ki\u1ec3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2482",
            name: "T\u00e2n Ph\u00fa Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2483",
            name: "T\u00e2n S\u01a1n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2484",
            name: "T\u00e2n S\u01a1n 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2485",
            name: "T\u00e2n Th\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2486",
            name: "T\u00e2n Th\u1edbi 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2487",
            name: "T\u00e2n Th\u1edbi 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2488",
            name: "T\u00e2n Th\u1edbi 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2489",
            name: "T\u00e2n Th\u1edbi \u0110\u00f4ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2490",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2491",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2492",
            name: "T\u00e2n Th\u1edbi Nh\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2493",
            name: "T\u00e2n Th\u1edbi Nh\u00ec 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2494",
            name: "T\u00e2n Th\u1edbi Nh\u00ec 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2495",
            name: "T\u00e2n Th\u1edbi Nh\u00ec 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2496",
            name: "T\u00e2n Th\u1edbi Nh\u00ec 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2497",
            name: "T\u00e2n Th\u1edbi Nh\u00ec 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2498",
            name: "T\u00e2n Th\u1edbi Nh\u00ec 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2499",
            name: "T\u00e2n Th\u1edbi Nh\u00ec 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2500",
            name: "T\u00e2n Th\u1edbi Nh\u00ec 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2501",
            name: "T\u00e2n Th\u1edbi Nh\u00ec 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2502",
            name: "T\u00e2n Th\u1edbi Nh\u00ec 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2503",
            name: "T\u00e2n Th\u1edbi Nh\u00ec 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2504",
            name: "T\u00e2n Th\u1edbi Nh\u00ec 28",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2505",
            name: "T\u00e2n Th\u1edbi Nh\u00ec 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2506",
            name: "T\u00e2n Th\u1edbi Nh\u00ec 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2507",
            name: "T\u00e2n Th\u1edbi Nh\u00ec 33",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2508",
            name: "T\u00e2n Th\u1edbi Nh\u00ec 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2509",
            name: "T\u00e2n Th\u1edbi Nh\u00ec 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2510",
            name: "T\u00e2n Th\u1edbi Nh\u00ec 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2511",
            name: "T\u00e2n Th\u1edbi Nh\u00ec 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2512",
            name: "T\u00e2n Th\u1edbi Nh\u00ec 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2513",
            name: "T\u00e2n Th\u1edbi Nh\u00ec 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2514",
            name: "T\u00e2n Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2515",
            name: "T\u00e2n Ti\u1ebfn 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2516",
            name: "T\u00e2n Ti\u1ebfn 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2517",
            name: "T\u00e2n Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2518",
            name: "T\u00e2n Xu\u00e2n - Trung Ch\u00e1nh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2519",
            name: "T\u00e2n Xu\u00e2n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2520",
            name: "T\u00e2n Xu\u00e2n 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2521",
            name: "T\u00e2n Xu\u00e2n 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2522",
            name: "T\u00e2n Xu\u00e2n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2523",
            name: "T\u00e2n Xu\u00e2n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2524",
            name: "T\u00e2n Xu\u00e2n 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2525",
            name: "T\u00e2n Xu\u00e2n 4C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2526",
            name: "T\u00e2n Xu\u00e2n 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2527",
            name: "T\u00e2n Xu\u00e2n 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2528",
            name: "T\u00e2n Xu\u00e2n 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2529",
            name: "T\u00e2n Xu\u00e2n 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2530",
            name: "T\u00e2y B\u1eafc L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2531",
            name: "T\u00e2y H\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2532",
            name: "T\u00e2y L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2533",
            name: "T\u00e2y L\u00e2n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2534",
            name: "T\u00e2y L\u00e2n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2535",
            name: "T\u00e2y L\u00e2n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2536",
            name: "T\u00e2y T\u00e2n Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2537",
            name: "T\u00e2y Th\u1edbi Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2538", name: "TC12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2539", name: "TC2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2540",
            name: "Th\u00e1i Th\u1ecb Gi\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2541",
            name: "Th\u1ea1nh L\u1ed9c 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2542",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2543", name: "Th\u1ea3o Nhi", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2544",
            name: "Th\u1ecb Tr\u1ea5n T\u00e2n Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2545",
            name: "Thi\u00ean Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2546",
            name: "Th\u1edbi \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2547",
            name: "Th\u1edbi Nh\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2548",
            name: "Th\u1edbi S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2549",
            name: "Th\u1edbi Tam Th\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2550",
            name: "Th\u1edbi Tam Th\u00f4n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2551",
            name: "Th\u1edbi Tam Th\u00f4n 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2552",
            name: "Th\u1edbi Tam Th\u00f4n 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2553",
            name: "Th\u1edbi Tam Th\u00f4n 11A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2554",
            name: "Th\u1edbi Tam Th\u00f4n 11B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2555",
            name: "Th\u1edbi Tam Th\u00f4n 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2556",
            name: "Th\u1edbi Tam Th\u00f4n 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2557",
            name: "Th\u1edbi Tam Th\u00f4n 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2558",
            name: "Th\u1edbi Tam Th\u00f4n 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2559",
            name: "Th\u1edbi Tam Th\u00f4n 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2560",
            name: "Th\u1edbi Tam Th\u00f4n 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2561",
            name: "Th\u1edbi Tam Th\u00f4n 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2562",
            name: "Th\u1edbi Tam Th\u00f4n 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2563",
            name: "Th\u1edbi Tam Th\u00f4n 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2564",
            name: "Th\u1edbi Tam Th\u00f4n 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2565",
            name: "Th\u1edbi Tam Th\u00f4n 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2566",
            name: "Th\u1edbi Tam Th\u00f4n 5 - 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2567",
            name: "Th\u1edbi Tam Th\u00f4n 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2568",
            name: "Th\u1edbi Tam Th\u00f4n 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2569",
            name: "Th\u1edbi Tam Th\u00f4n 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2570",
            name: "Th\u1edbi Tam Th\u00f4n 8A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2571",
            name: "Th\u1edbi Tam Th\u00f4n 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2572",
            name: "Th\u1edbi T\u00e2y 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2573",
            name: "Th\u1edbi T\u00e2y 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2574",
            name: "Th\u1edbi T\u00e2y T\u00e2n Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2575",
            name: "Th\u1edbi T\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2576",
            name: "Th\u1edbi T\u1ee9 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2577",
            name: "Th\u01a1\u0301i T\u01b0\u0301 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2578",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2579",
            name: "Th\u1ed1ng Nh\u1ea5t 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2580",
            name: "Th\u1ed1ng Nh\u1ea5t 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2581",
            name: "Th\u01b0\u01a1ng M\u1ea1i 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2582",
            name: "Ti\u1ec1n L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2583",
            name: "Ti\u1ec1n L\u00e2n 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2584",
            name: "Ti\u1ec1n L\u00e2n 14A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2585",
            name: "Ti\u1ec1n L\u00e2n 14D",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2586",
            name: "Ti\u1ec1n L\u00e2n 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2587",
            name: "Ti\u1ec1n L\u00e2n 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2588",
            name: "Ti\u1ec1n L\u00e2n 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2589",
            name: "Ti\u1ec1n L\u00e2n 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2590",
            name: "T\u1ec9nh L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2591",
            name: "T\u1ec9nh l\u1ed9 640",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2592",
            name: "T\u1ec9nh L\u1ed9 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2593",
            name: "T\u1ec9nh l\u1ed9 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2594",
            name: "T\u1ec9nh l\u1ed9 824",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2595",
            name: "T\u1ec9nh l\u1ed9 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2596", name: "TK 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2597", name: "TK 18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2598", name: "TK 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2599", name: "TK1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2600", name: "TK10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2601", name: "TK11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2602", name: "TK12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2603", name: "TK13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2604", name: "TK14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2605", name: "TK16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2606", name: "TK2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2607", name: "TK20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2608", name: "TK21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2609", name: "TK27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2610", name: "TK4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2611", name: "TK7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2612", name: "TK8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2613", name: "TKA11", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2614",
            name: "T\u00f4 Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2615",
            name: "T\u00f4 K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2616",
            name: "T\u00f4 K\u00fd 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2617",
            name: "T\u00f4 K\u00fd 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2618",
            name: "T\u00f4 K\u00fd 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2619",
            name: "T\u00f4 K\u00fd 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2620",
            name: "T\u00f4 K\u00fd 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2621",
            name: "T\u00f4 K\u00fd 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2622",
            name: "T\u00f4 K\u00fd 6B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2623", name: "TR3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2624",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2625", name: "Tr\u1ea7n Anh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2626",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2627",
            name: "Tr\u1ea7n Kh\u1eafc Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2628",
            name: "Tr\u1ea7n L\u00e3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2629",
            name: "Tr\u1ea7n Th\u1ecb B\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2630",
            name: "Tr\u1ea7n Th\u1ecb Na",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2631",
            name: "Tr\u1ea7n V\u0103n M\u01b0\u1eddi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2632",
            name: "Tr\u1ecbnh Th\u1ecb \u0110\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2633",
            name: "Tr\u1ecbnh Th\u1ecb Gi\u1ea7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2634",
            name: "Tr\u1ecbnh Th\u1ecb Mi\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2635",
            name: "Tr\u1ecbnh Th\u1ecb Mi\u1ebfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2636",
            name: "Trung Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2637",
            name: "Trung Ch\u00e1nh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2638",
            name: "Trung Ch\u00e1nh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2639",
            name: "Trung Ch\u00e1nh T\u00e2n Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2640",
            name: "Trung \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2641",
            name: "Trung \u0110\u00f4ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2642",
            name: "Trung \u0110\u00f4ng 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2643",
            name: "Trung \u0110\u00f4ng 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2644",
            name: "Trung \u0110\u00f4ng 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2645",
            name: "Trung \u0110\u00f4ng 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2646",
            name: "Trung \u0110\u00f4ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2647",
            name: "Trung \u0110\u00f4ng 3A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2648",
            name: "Trung \u0110\u00f4ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2649",
            name: "Trung \u0110\u00f4ng 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2650",
            name: "Trung \u0110\u00f4ng 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2651",
            name: "Trung \u0110\u00f4ng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2652",
            name: "Trung L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2653",
            name: "Trung L\u00e2n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2654",
            name: "Trung L\u00e2n 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2655", name: "Trung M\u1ef9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2656",
            name: "Trung M\u1ef9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2657",
            name: "Trung M\u1ef9 T\u00e2n Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2658",
            name: "Trung M\u1ef9 T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2659",
            name: "Trung M\u1ef9 T\u00e2y 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2660",
            name: "Trung M\u1ef9 T\u00e2y 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2661",
            name: "Tr\u01b0ng N\u1eef V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2662",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2663",
            name: "Tr\u01b0\u01a1ng Th\u1ecb Nh\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2664",
            name: "V\u1ea1n H\u1ea1nh 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2665",
            name: "V\u1ea1n H\u1ea1nh 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2666",
            name: "V\u00e0nh \u0110ai 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2667",
            name: "V\u00e0nh \u0110ai 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2668",
            name: "V\u00f5 Mai Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2669",
            name: "V\u00f5 Th\u1ecb \u0110\u1ea7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2670",
            name: "V\u00f5 Th\u1ecb H\u1ed3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2671",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2672",
            name: "V\u01b0\u1eddn Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2673",
            name: "Xu\u00e2n H\u00f2a 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2674",
            name: "Xu\u00e2n Th\u00e1i 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2675",
            name: "Xu\u00e2n Th\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2676",
            name: "Xu\u00e2n Th\u1edbi 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2677",
            name: "Xu\u00e2n Th\u1edbi 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2678",
            name: "Xu\u00e2n Th\u1edbi 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2679",
            name: "Xu\u00e2n Th\u1edbi 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2680",
            name: "Xu\u00e2n Th\u1edbi 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2681",
            name: "Xu\u00e2n Th\u1edbi 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2682",
            name: "Xu\u00e2n Th\u1edbi 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2683",
            name: "Xu\u00e2n Th\u1edbi 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2684",
            name: "Xu\u00e2n Th\u1edbi 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2685",
            name: "Xu\u00e2n Th\u1edbi 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2686",
            name: "Xu\u00e2n Th\u1edbi 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2687",
            name: "Xu\u00e2n Th\u1edbi 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2688",
            name: "Xu\u00e2n Th\u1edbi 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2689",
            name: "Xu\u00e2n Th\u1edbi 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2690",
            name: "Xu\u00e2n Th\u1edbi 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2691",
            name: "Xu\u00e2n Th\u1edbi 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2692",
            name: "Xu\u00e2n Th\u1edbi 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2693",
            name: "Xu\u00e2n Th\u1edbi 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2694",
            name: "Xu\u00e2n Th\u1edbi \u0110\u00f4ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2695",
            name: "Xu\u00e2n Th\u1edbi \u0110\u00f4ng 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2696",
            name: "Xu\u00e2n Th\u1edbi \u0110\u00f4ng 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2697",
            name: "Xu\u00e2n Th\u1edbi \u0110\u00f4ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2698",
            name: "Xu\u00e2n Th\u1edbi \u0110\u00f4ng 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2699",
            name: "Xu\u00e2n Th\u1edbi \u0110\u00f4ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2700",
            name: "Xu\u00e2n Th\u1edbi \u0110\u00f4ng 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2701",
            name: "Xu\u00e2n Th\u1edbi \u0110\u00f4ng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2702",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2703",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2704",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2705",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2706",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2707",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2708",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2709",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2710",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 16B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2711",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2712",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2713",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2714",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2715",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2716",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2717",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2718",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2719",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2720",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 28",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2721",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2722",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 2B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2723",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2724",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 30C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2725",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 31",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2726",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 31A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2727",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2728",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 32A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2729",
            name: "Xu\u00e2n Th\u01a1i S\u01a1n 33",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2730",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 36",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2731",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2732",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2733",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2734",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2735",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2736",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2737",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2738",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2739",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2740",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2741",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2742",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2745",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 1-2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2743",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2744",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2746",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2747",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2748",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 14C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2749",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2750",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2751",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2752",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2753",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 1K",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2754",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2757",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 2-11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2759",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 2-2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2755",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2756",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2758",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2760",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2761",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2762",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2763",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2764",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 26-1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2765",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 26-1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2766",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2767",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 27-2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2768",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 28",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2769",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2770",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2771",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2772",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 31",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2773",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 31-4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2774",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2775",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 33",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2776",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2777",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 4-3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2778",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 44",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2779",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 47",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2780",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 48",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2781",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2782",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 5-2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2784",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 5-3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2783",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 53",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2785",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 53B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2786",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 53C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2787",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 58",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2788",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 58C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2789",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 59",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2790",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 59B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2791",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2792",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 63",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2793",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 64",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2794",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 66",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2795",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2796",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 7 - 3A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2797",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 7 - 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2799",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 7-1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2801",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 7-2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2803",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 7-3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2798",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 71",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2800",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 72",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2802",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 73",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2804",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2805",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 8 - 7A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2807",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 8-4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2806",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 84",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2808",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 86",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2809",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 87",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2810",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2811",
            name: "Xu\u00e2n Th\u1edbi Th\u01b0\u1ee3ng 9-1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2812",
            name: "Xuy\u00ean \u00c1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2813", name: "XXT 6-2-5", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "219",
            name: "An Th\u1ecbnh B\u00e0 \u0110i\u1ec3m",
            lat: "10.838904380798",
            lng: "106.58975982666",
          },
          {
            id: "220",
            name: "Cheery 3 Apartment",
            lat: "10.860601425171",
            lng: "106.6018447876",
          },
          {
            id: "221",
            name: "\u0110\u1ea1t Gia Garden",
            lat: "10.877826690674",
            lng: "106.59368133545",
          },
          {
            id: "222",
            name: "Eco Town",
            lat: "10.869356155396",
            lng: "106.55126953125",
          },
          {
            id: "223",
            name: "HQC H\u00f3c M\u00f4n",
            lat: "10.860527992248",
            lng: "106.60182952881",
          },
          {
            id: "224",
            name: "KDC Ho\u00e0ng H\u1ea3i",
            lat: "10.865822792053",
            lng: "106.614402771",
          },
          {
            id: "225",
            name: "Metro Garden",
            lat: "10.895685195923",
            lng: "106.57266998291",
          },
          {
            id: "226",
            name: "Sophia Garden",
            lat: "10.875514030457",
            lng: "106.56523895264",
          },
          {
            id: "227",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n",
            lat: "10.870985984802",
            lng: "106.57145690918",
          },
        ],
      },
      {
        id: "8",
        name: "Nh\u00e0 B\u00e8",
        wards: [
          {
            id: "105",
            name: "Hi\u1ec7p Ph\u01b0\u1edbc",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "106", name: "Long Th\u1edbi", prefix: "X\u00e3" },
          { id: "107", name: "Nh\u00e0 B\u00e8", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "108", name: "Nh\u01a1n \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "109", name: "Ph\u00fa Xu\u00e2n", prefix: "X\u00e3" },
          { id: "110", name: "Ph\u01b0\u1edbc Ki\u1ec3n", prefix: "X\u00e3" },
          { id: "111", name: "Ph\u01b0\u1edbc L\u1ed9c", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "2814", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2815", name: "126", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2816", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2817", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2818", name: "15B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2819", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2820", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2821", name: "1C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2822", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2823", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2824", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2825", name: "217", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2826", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2827", name: "247", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2828", name: "25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2829", name: "274", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2830", name: "275", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2831", name: "300", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2832", name: "435", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2833", name: "455", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2834", name: "456", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2835", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2836", name: "512", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2837", name: "824", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2838", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2839", name: "A2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2840", name: "A7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2841",
            name: "Ba\u0300 Ca\u0309",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2842",
            name: "B\u00e1c Ba Ni\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2843", name: "B\u1eafc Nam", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2844", name: "B\u00e0u Le", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2845", name: "Bi Bi", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2846",
            name: "B\u1edd T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2847", name: "C7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2848", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2849", name: "D10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2850", name: "D15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2851", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2852", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2853", name: "D4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2854", name: "D6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2855", name: "D7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2856", name: "D8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2857", name: "D9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2863",
            name: "D\u01b0\u01a1ng C\u00e1t L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2858",
            name: "\u0110\u1eb7ng Nh\u1eef L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2859",
            name: "\u0110\u00e0o S\u01b0 T\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2860",
            name: "\u0110\u00e0o T\u00f4ng Nguy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2861",
            name: "\u0110\u00f4ng Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2862", name: "\u0110T 826C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2864", name: "Gia Binh", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2865", name: "G\u00f2 Me", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2866",
            name: "H\u00e0ng D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2867",
            name: "H\u01b0\u01a1ng l\u1ed9 39",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2868",
            name: "Hu\u1ef3nh T\u1ea5n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2869",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2870",
            name: "L\u00ea V\u0103n L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2871",
            name: "Li\u00ean \u1ea4p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2872",
            name: "Li\u00ean \u1ea5p 2-3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2873",
            name: "Li\u00ean \u1ea4p 3-4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2874", name: "Long H\u1eadu", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2875",
            name: "Long Th\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2876",
            name: "M\u01b0\u1eddi Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2877", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2878", name: "N1 - 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2879", name: "N3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2880", name: "N5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2881", name: "N6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2882", name: "N7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2883", name: "N8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2884", name: "N9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2885", name: "N9 - 12", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2886",
            name: "Nguy\u1ec5n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2887",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2888",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2889",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2890",
            name: "Nguy\u1ec5n V\u0103n B\u1ea3y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2891",
            name: "Nguy\u1ec5n V\u0103n T\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2892",
            name: "Nh\u00e0 B\u00e8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2893",
            name: "Nh\u01a1n \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2894",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2895",
            name: "Ph\u1ea1m H\u1eefu L\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2896",
            name: "Phan V\u0103n B\u1ea3y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2897",
            name: "Ph\u01b0\u1edbc L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2898",
            name: "R\u1ea1ch Gi\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2899", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2900", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2901", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2902", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2903", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2904", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2905", name: "S\u1ed1 18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2906", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2907", name: "S\u1ed1 21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2908", name: "S\u1ed1 23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2909", name: "S\u1ed1 24", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2910", name: "S\u1ed1 25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2911", name: "S\u1ed1 27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2912", name: "S\u1ed1 29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2913", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2914", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2915", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2916", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2917", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2918", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2919", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2920", name: "s\u1ed1 915", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2921",
            name: "T\u00e2n Ki\u1ec3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2922",
            name: "Thanh Nh\u1ef1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2923",
            name: "Thanh Ni\u00ean Xung Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2924",
            name: "Tr\u01b0\u1eddng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2925",
            name: "V\u00f5 Ti\u00ean S\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2926",
            name: "X\u01b0\u01a1ng C\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2927",
            name: "X\u01b0\u01a1ng C\u00e1 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2928",
            name: "X\u01b0\u01a1ng C\u00e1 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "228",
            name: "Anh Tu\u1ea5n Apartment",
            lat: "10.679749488831",
            lng: "106.75086975098",
          },
          {
            id: "229",
            name: "Anh Tu\u1ea5n Garden",
            lat: "10.674652099609",
            lng: "106.69783782959",
          },
          {
            id: "230",
            name: "Bi\u1ec7t th\u1ef1 Galleria",
            lat: "10.70445728302",
            lng: "106.71314239502",
          },
          {
            id: "231",
            name: "Bi\u1ec7t th\u1ef1 Kim Long",
            lat: "10.722208976746",
            lng: "106.70191192627",
          },
          {
            id: "232",
            name: "Bi\u1ec7t th\u1ef1 Ng\u00e2n Long",
            lat: "10.719393730164",
            lng: "106.70273590088",
          },
          {
            id: "233",
            name: "Blue Diamond Tower",
            lat: "10.691372871399",
            lng: "106.70640563965",
          },
          {
            id: "234",
            name: "Blue Star",
            lat: "10.678610801697",
            lng: "106.75015258789",
          },
          {
            id: "235",
            name: "Dragon City",
            lat: "10.710577964783",
            lng: "106.70890808106",
          },
          {
            id: "236",
            name: "Dragon Hill Residence and Suites 2",
            lat: "10.71008682251",
            lng: "106.70885467529",
          },
          {
            id: "237",
            name: "Dragon Parc",
            lat: "10.702569007873",
            lng: "106.71464538574",
          },
          {
            id: "238",
            name: "Dragon Parc 2",
            lat: "10.694359779358",
            lng: "106.72041320801",
          },
          {
            id: "239",
            name: "Exim Pearl Tower",
            lat: "10.698514938354",
            lng: "106.70444488525",
          },
          {
            id: "240",
            name: "Garden Riverside Villas",
            lat: "10.718894958496",
            lng: "106.68061065674",
          },
          {
            id: "241",
            name: "Green Riverside",
            lat: "10.67823600769",
            lng: "106.75385284424",
          },
          {
            id: "242",
            name: "GS Metrocity Nh\u00e0 B\u00e8",
            lat: "10.682552337647",
            lng: "106.71489715576",
          },
          {
            id: "243",
            name: "Hi\u1ec7p Ph\u01b0\u1edbc",
            lat: "10.600989341736",
            lng: "106.75885009766",
          },
          {
            id: "244",
            name: "Ho\u00e0ng Anh An Ti\u1ebfn - Gold House",
            lat: "10.711324691773",
            lng: "106.70458984375",
          },
          {
            id: "245",
            name: "H\u01b0ng Ph\u00e1t",
            lat: "10.713678359985",
            lng: "106.70024871826",
          },
          {
            id: "246",
            name: "H\u01b0ng Ph\u00e1t Silver Star",
            lat: "10.717429161072",
            lng: "106.70176696777",
          },
          {
            id: "247",
            name: "KDC Nguy\u1ec5n B\u00ecnh",
            lat: "10.671059608459",
            lng: "106.72184753418",
          },
          {
            id: "248",
            name: "KDC Thanh Qu\u1ebf",
            lat: "10.671059608459",
            lng: "106.72184753418",
          },
          {
            id: "249",
            name: "Kenton Residences",
            lat: "10.722134590149",
            lng: "106.69951629639",
          },
          {
            id: "250",
            name: "Khu d\u00e2n c\u01b0 Nh\u01a1n \u0110\u1ee9c",
            lat: "10.678099632263",
            lng: "106.69930267334",
          },
          {
            id: "251",
            name: "L\u00e0ng \u0110\u1ea1i H\u1ecdc ABC",
            lat: "10.707189559937",
            lng: "106.7057723999",
          },
          {
            id: "252",
            name: "Lavila Ki\u1ebfn \u00c1 - Nh\u00e0 B\u00e8",
            lat: "10.691249847412",
            lng: "106.72121429443",
          },
          {
            id: "253",
            name: "Louis Tower",
            lat: "10.722640991211",
            lng: "106.70543670654",
          },
          {
            id: "254",
            name: "Nam S\u00e0i G\u00f2n Riverside",
            lat: "10.658900260925",
            lng: "106.69170379639",
          },
          {
            id: "255",
            name: "New Saigon Ho\u00e0ng Anh 3",
            lat: "10.719662666321",
            lng: "106.70148468018",
          },
          {
            id: "256",
            name: "Nine South Estates",
            lat: "10.690982818603",
            lng: "106.72204589844",
          },
          {
            id: "257",
            name: "Orchid Park",
            lat: "10.721109390259",
            lng: "106.72643280029",
          },
          {
            id: "258",
            name: "Park Vista",
            lat: "10.71111869812",
            lng: "106.70659637451",
          },
          {
            id: "259",
            name: "Ph\u00fa Gia Cotec",
            lat: "10.679718017578",
            lng: "106.73994445801",
          },
          {
            id: "260",
            name: "Ph\u00fa Ho\u00e0ng Anh",
            lat: "10.716768264771",
            lng: "106.70431518555",
          },
          {
            id: "261",
            name: "Ph\u00fa M\u1ef9 Thu\u1eadn",
            lat: "10.677675247192",
            lng: "106.75109100342",
          },
          {
            id: "262",
            name: "Ph\u00fa Xu\u00e2n 2",
            lat: "10.675395965576",
            lng: "106.73622894287",
          },
          {
            id: "263",
            name: "Ph\u00fa Xu\u00e2n 3",
            lat: "10.692239761352",
            lng: "106.74098968506",
          },
          {
            id: "264",
            name: "Ph\u00fa Xu\u00e2n 4",
            lat: "10.685405731201",
            lng: "106.74662017822",
          },
          {
            id: "265",
            name: "Ph\u00fa Xu\u00e2n 5",
            lat: "10.691005706787",
            lng: "106.74281311035",
          },
          {
            id: "266",
            name: "Ph\u00fa Xu\u00e2n C\u1ea3ng S\u00e0i G\u00f2n",
            lat: "10.67823600769",
            lng: "106.75385284424",
          },
          {
            id: "267",
            name: "Ph\u00fa Xu\u00e2n Cotec",
            lat: "10.676533699036",
            lng: "106.75074005127",
          },
          {
            id: "268",
            name: "Ph\u00fa Xu\u00e2n H\u1ed3ng L\u0129nh",
            lat: "10.67823600769",
            lng: "106.75385284424",
          },
          {
            id: "269",
            name: "Ph\u00fa Xu\u00e2n Minh Long",
            lat: "10.721109390259",
            lng: "106.72643280029",
          },
          {
            id: "270",
            name: "Ph\u00fa Xu\u00e2n V\u1ea1n H\u01b0ng Ph\u00fa",
            lat: "10.679995536804",
            lng: "106.73163604736",
          },
          {
            id: "271",
            name: "Ph\u00fa Xu\u00e2n V\u1ea1n Ph\u00e1t H\u01b0ng",
            lat: "10.685426712036",
            lng: "106.73825073242",
          },
          {
            id: "272",
            name: "Sadeco Ph\u01b0\u1edbc Ki\u1ec3n",
            lat: "10.72056388855",
            lng: "106.69620513916",
          },
          {
            id: "273",
            name: "S\u00e0i G\u00f2n M\u1edbi",
            lat: "10.684909820557",
            lng: "106.7467956543",
          },
          {
            id: "274",
            name: "Saigon South Residences",
            lat: "10.698759078979",
            lng: "106.71647644043",
          },
          {
            id: "275",
            name: "Sky Symphony",
            lat: "10.683977127075",
            lng: "106.70271301269",
          },
          {
            id: "276",
            name: "Sunrise Riverside",
            lat: "10.722357749939",
            lng: "106.70269012451",
          },
          {
            id: "277",
            name: "T\u00e2n An Huy - Tr\u1ea7n Th\u00e1i",
            lat: "10.722261428833",
            lng: "106.70400238037",
          },
          {
            id: "278",
            name: "Th\u00e1i S\u01a1n 1",
            lat: "10.708233833313",
            lng: "106.70797729492",
          },
          {
            id: "279",
            name: "The Headquarters",
            lat: "10.70512008667",
            lng: "106.712890625",
          },
          {
            id: "280",
            name: "The Park Residence",
            lat: "10.712343215942",
            lng: "106.70735931397",
          },
          {
            id: "281",
            name: "The Stars Village",
            lat: "10.66082572937",
            lng: "106.70754241943",
          },
          {
            id: "282",
            name: "The Sun City Ph\u01b0\u1edbc Ki\u1ec3n",
            lat: "10.692140579224",
            lng: "106.70416259766",
          },
        ],
      },
      {
        id: "9",
        name: "Ph\u00fa Nhu\u1eadn",
        wards: [
          { id: "112", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "113", name: "10", prefix: "Ph\u01b0\u1eddng" },
          { id: "114", name: "11", prefix: "Ph\u01b0\u1eddng" },
          { id: "115", name: "12", prefix: "Ph\u01b0\u1eddng" },
          { id: "116", name: "13", prefix: "Ph\u01b0\u1eddng" },
          { id: "117", name: "14", prefix: "Ph\u01b0\u1eddng" },
          { id: "118", name: "15", prefix: "Ph\u01b0\u1eddng" },
          { id: "119", name: "17", prefix: "Ph\u01b0\u1eddng" },
          { id: "120", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "121", name: "25", prefix: "Ph\u01b0\u1eddng" },
          { id: "122", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "123", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "124", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "125", name: "6", prefix: "Ph\u01b0\u1eddng" },
          { id: "126", name: "7", prefix: "Ph\u01b0\u1eddng" },
          { id: "127", name: "8", prefix: "Ph\u01b0\u1eddng" },
          { id: "128", name: "9", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "2929", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2930", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2931", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2932", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2933",
            name: "B\u00f9i V\u0103n Th\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2934",
            name: "C\u1ea7m B\u00e1 Th\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2935",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2936",
            name: "Chi\u1ebfn Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2937",
            name: "C\u00f4 B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2938", name: "C\u00f4 Giang", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2939", name: "C\u00f9 Lao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2940",
            name: "C\u01b0 x\u00e1 Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2949", name: "Duy T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2941",
            name: "\u0110\u1eb7ng Thai Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2942",
            name: "\u0110\u1eb7ng V\u0103n Ng\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2943",
            name: "\u0110\u1eb7ng V\u0103n S\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2944",
            name: "\u0110\u00e0o Duy Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2945",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2946",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2947",
            name: "\u0110\u1ed7 T\u1ea5n Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2948",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2950", name: "H\u1ea3i Nam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2951",
            name: "H\u1ed3 Bi\u1ec3u Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2952",
            name: "H\u1ed3 V\u0103n Hu\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2953", name: "Hoa Cau", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2954", name: "Hoa C\u00fac", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2955",
            name: "Hoa \u0110\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2956", name: "Hoa Gi\u1ea5y", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2957", name: "Hoa H\u1ed3ng", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2958", name: "Hoa Hu\u1ec7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2959", name: "Hoa L\u00e0i", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2960", name: "Hoa Lan", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2961", name: "Hoa Mai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2962",
            name: "Hoa Ph\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2963", name: "Hoa S\u1ee9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2964", name: "Hoa S\u1eefa", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2965", name: "Hoa Th\u1ecb", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2966", name: "Hoa Tr\u00e0", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2967",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2968",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2969",
            name: "Ho\u00e0ng Minh Gi\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2970", name: "Ho\u00e0ng Sa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2971",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2972",
            name: "H\u1ed3ng H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2973",
            name: "Hu\u1ef3nh V\u0103n B\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2974", name: "K\u00fd Con", prefix: "\u0110\u01b0\u1eddng" },
          { id: "2975", name: "Lam S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2976",
            name: "L\u00ea Quang \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2977",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2978",
            name: "L\u00ea T\u1ef1 T\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2979",
            name: "L\u00ea V\u0103n S\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2980",
            name: "Mai V\u0103n Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "2981", name: "Man Thu", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "2982",
            name: "Mi\u1ebfu N\u1ed5i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2983",
            name: "Ng\u00f4 Th\u1eddi Nhi\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2984",
            name: "Nguy\u1ec5n C\u00f4ng Hoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2985",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2986",
            name: "Nguy\u1ec5n \u0110\u00ecnh Ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2987",
            name: "Nguy\u1ec5n Hoa H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2988",
            name: "Nguy\u1ec5n Huy T\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2989",
            name: "Nguy\u1ec5n Ki\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2990",
            name: "Nguy\u1ec5n L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2991",
            name: "Nguy\u1ec5n Th\u1ecb Hu\u1ef3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2992",
            name: "Nguy\u1ec5n Th\u1ecb Qu\u1ef3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2993",
            name: "Nguy\u1ec5n Th\u1ecb Thu Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2994",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2995",
            name: "Nguy\u1ec5n Tr\u1ecdng Tuy\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2996",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2997",
            name: "Nguy\u1ec5n V\u0103n B\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2998",
            name: "Nguy\u1ec5n V\u0103n \u0110\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "2999",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3000",
            name: "Nhi\u00eau T\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3001",
            name: "Ph\u1ea1m V\u0103n Hai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3002",
            name: "Ph\u1ea1m Vi\u1ebft Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3003",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "3004",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3005",
            name: "Phan Huy \u00cdch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3006",
            name: "Phan T\u00e2y H\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3007",
            name: "Phan X\u00edch Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3008",
            name: "Ph\u1ed5 Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3009",
            name: "Ph\u00f9ng V\u0103n Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3010",
            name: "Ph\u00f9ng V\u0103n Khang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3011",
            name: "R\u1ea1ch Mi\u1ec5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3012", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3013", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3014", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3015", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3016", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3017", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3018", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3019", name: "T\u00e2n Canh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3020",
            name: "Th\u00edch Qu\u1ea3ng \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3021",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3022",
            name: "Tr\u1ea7n H\u1eefu Trang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3023",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3024",
            name: "Tr\u1ea7n K\u1ebf X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3025",
            name: "Tr\u1ea7n Kh\u1eafc Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3026",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3027",
            name: "Tr\u1ea7n Qu\u00fd \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3028",
            name: "Tr\u1ea7n T\u1ebf X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3029",
            name: "Tr\u01b0\u01a1ng \u0110\u0103ng Qu\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3030",
            name: "Tr\u01b0\u01a1ng Qu\u1ed1c Dung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3031",
            name: "Tr\u01b0\u1eddng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3032", name: "V\u1ea1n Ki\u1ebfp", prefix: "Ph\u1ed1" },
          {
            id: "3033",
            name: "V\u0169 Huy T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "283",
            name: "Arirang Building",
            lat: "10.798830986023",
            lng: "106.67757415772",
          },
          {
            id: "284",
            name: "BMC H\u01b0ng Long",
            lat: "10.715867042542",
            lng: "106.73725891113",
          },
          {
            id: "285",
            name: "Botanic Towers",
            lat: "10.805287361145",
            lng: "106.68518829346",
          },
          {
            id: "286",
            name: "Cao \u1ed1c Ph\u00fa Nhu\u1eadn",
            lat: "10.808441162109",
            lng: "106.67300415039",
          },
          {
            id: "287",
            name: "Centrepoint",
            lat: "10.797017097473",
            lng: "106.67342376709",
          },
          {
            id: "288",
            name: "Chung c\u01b0 Phan X\u00edch Long",
            lat: "10.797813415527",
            lng: "106.68964385986",
          },
          {
            id: "289",
            name: "Garden Gate",
            lat: "10.809511184692",
            lng: "106.67443847656",
          },
          {
            id: "290",
            name: "Gia Thy Building",
            lat: "10.806868553162",
            lng: "106.67586517334",
          },
          {
            id: "291",
            name: "Golden Mansion",
            lat: "10.808427810669",
            lng: "106.67012786865",
          },
          {
            id: "292",
            name: "Intresco Tower",
            lat: "10.799659729004",
            lng: "106.67012786865",
          },
          {
            id: "293",
            name: "Kingston Residence",
            lat: "10.798610687256",
            lng: "106.67055511475",
          },
          {
            id: "294",
            name: "Lucky House",
            lat: "10.791830062866",
            lng: "106.67470550537",
          },
          {
            id: "295",
            name: "Lutaco Tower",
            lat: "10.796021461487",
            lng: "106.67472839356",
          },
          {
            id: "296",
            name: "Newton Residence",
            lat: "10.798723220825",
            lng: "106.67306518555",
          },
          {
            id: "297",
            name: "Orchard Garden",
            lat: "10.809348106384",
            lng: "106.67386627197",
          },
          {
            id: "298",
            name: "Orchard Park View",
            lat: "10.809296607971",
            lng: "106.67260742188",
          },
          {
            id: "299",
            name: "PN-Techcons",
            lat: "10.797878265381",
            lng: "106.68849945068",
          },
          {
            id: "300",
            name: "Satra Citiland Plaza",
            lat: "10.800759315491",
            lng: "106.682472229",
          },
          {
            id: "301",
            name: "Satra Eximland",
            lat: "10.800753593445",
            lng: "106.6824798584",
          },
          {
            id: "302",
            name: "The Prince Residence",
            lat: "10.7919921875",
            lng: "106.68090820312",
          },
          {
            id: "303",
            name: "VMG Building",
            lat: "10.807344436646",
            lng: "106.67514038086",
          },
        ],
      },
      {
        id: "10",
        name: "Qu\u1eadn 1",
        wards: [
          { id: "129", name: "B\u1ebfn Ngh\u00e9", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "130",
            name: "B\u1ebfn Th\u00e0nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "131", name: "C\u1ea7u Kho", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "132",
            name: "C\u1ea7u \u00d4ng L\u00e3nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "133", name: "C\u00f4 Giang", prefix: "Ph\u01b0\u1eddng" },
          { id: "134", name: "\u0110a Kao", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "135",
            name: "Nguy\u1ec5n C\u01b0 Trinh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "136",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "137",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "138",
            name: "T\u00e2n \u0110\u1ecbnh",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "3034", name: "15B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3035", name: "3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3036", name: "Alexandre", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3037",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          {
            id: "3038",
            name: "B\u00e0 L\u00ea Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3039",
            name: "B\u1ebfn Ch\u01b0\u01a1ng D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3040",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3041",
            name: "B\u00f9i Vi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3042",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3043", name: "Calmette", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3044", name: "Camex", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3045",
            name: "Cao B\u00e1 Nh\u1ea1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3046",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3047",
            name: "C\u00e2y \u0110i\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3048",
            name: "Chu M\u1ea1nh Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3049",
            name: "C\u00f4 B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3050", name: "C\u00f4 Giang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3051",
            name: "C\u1ed1ng Qu\u1ef3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3052",
            name: "C\u00f4ng Tr\u01b0\u1eddng Lam S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3053",
            name: "C\u00f4ng Tr\u01b0\u1eddng M\u00ea Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3054",
            name: "C\u00f4ng Tr\u01b0\u1eddng Qu\u1ed1c T\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3055",
            name: "C\u00f4ng X\u00e3 Paris",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3056",
            name: "\u0110\u1ea1i L\u1ed9 \u0110\u00f4ng T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3057", name: "\u0110\u1eb7ng Dung", prefix: "Ph\u1ed1" },
          { id: "3058", name: "\u0110\u1eb7ng T\u1ea5t", prefix: "Ph\u1ed1" },
          {
            id: "3059",
            name: "\u0110\u1eb7ng Th\u1ecb Nhu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3060",
            name: "\u0110\u1eb7ng Tr\u1ea7n C\u00f4n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "3061",
            name: "\u0110\u1ec1 Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3062",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3063",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3064",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3065",
            name: "\u0110\u1ed7 Quang \u0110\u1ea9u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3066",
            name: "\u0110\u00f4ng Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3067",
            name: "\u0110\u1ed3ng Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3068", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "3069",
            name: "H\u1ea3i Tri\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3070", name: "H\u00e0m Nghi", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3071",
            name: "H\u00e0n Thuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3072",
            name: "H\u1ed3 H\u1ea3o H\u1edbn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3073",
            name: "H\u1ed3 Hu\u1ea5n Nghi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3074",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3075",
            name: "H\u00f2a H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3076",
            name: "H\u00f2a M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3077",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3078",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3079", name: "Ho\u00e0ng Sa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3080",
            name: "Huy\u1ec1n Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3081",
            name: "Huy\u1ec1n Tr\u00e2n C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3082",
            name: "Hu\u1ef3nh Kh\u01b0\u01a1ng An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3083",
            name: "Hu\u1ef3nh Kh\u01b0\u01a1ng Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3084",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3085", name: "K\u00fd Con", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3086",
            name: "L\u00e3nh Binh Th\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3087",
            name: "L\u00ea Anh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3088",
            name: "L\u00ea C\u00f4ng Ki\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3089",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3090", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3091",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3092",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3093",
            name: "L\u00ea Th\u00e1nh T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3094",
            name: "L\u00ea Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3095",
            name: "L\u00ea Th\u1ecb Ri\u00eang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3096", name: "L\u00ea V\u0103n H\u01b0u", prefix: "Ph\u1ed1" },
          {
            id: "3097",
            name: "L\u01b0\u01a1ng H\u1eefu Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3098",
            name: "L\u01b0u V\u0103n Lang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3099",
            name: "L\u00fd Ch\u00ednh Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3100",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3101",
            name: "L\u00fd V\u0103n Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3102",
            name: "L\u00fd V\u0103n Ph\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3103",
            name: "M\u00e3 L\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3104",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3105",
            name: "M\u1ea1c Th\u1ecb B\u01b0\u1edfi",
            prefix: "Ph\u1ed1",
          },
          {
            id: "3106",
            name: "Mai Th\u1ecb L\u1ef1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3107",
            name: "Nam K\u1ef3 Kh\u1edfi Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3108",
            name: "Nam Qu\u1ed1c Cang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3109",
            name: "Ng\u00f4 \u0110\u1ee9c K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3110",
            name: "Ng\u00f4 V\u0103n N\u0103m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3111",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3112",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3113",
            name: "Nguy\u1ec5n C\u1ea3nh Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3114",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3115",
            name: "Nguy\u1ec5n C\u01b0 Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3117",
            name: "Nguy\u1ec5n Do\u00e3n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3118",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3116",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3119",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3120",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3121",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3122",
            name: "Nguy\u1ec5n H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3123", name: "Nguy\u1ec5n Huy T\u1ef1", prefix: "Ph\u1ed1" },
          { id: "3124", name: "Nguy\u1ec5n Kh\u1eafc Nhu", prefix: "Ph\u1ed1" },
          {
            id: "3125",
            name: "Nguy\u1ec5n Phi Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3126", name: "Nguy\u1ec5n Si\u00eau", prefix: "Ph\u1ed1" },
          {
            id: "3127",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3128",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3129",
            name: "Nguy\u1ec5n Th\u00e0nh \u00dd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3130",
            name: "Nguy\u1ec5n Th\u1ecb Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3131",
            name: "Nguy\u1ec5n Th\u1ecb L\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3132",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3133",
            name: "Nguy\u1ec5n Th\u1ecb Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3134",
            name: "Nguy\u1ec5n Thi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3135",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3136",
            name: "Nguy\u1ec5n Trung Ng\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3137",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3138",
            name: "Nguy\u1ec5n V\u0103n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3139",
            name: "Nguy\u1ec5n V\u0103n Chi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3140",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3141",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3142",
            name: "Nguy\u1ec5n V\u0103n \u0110\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3143",
            name: "Nguy\u1ec5n V\u0103n Giai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3144",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3145",
            name: "Nguy\u1ec5n V\u0103n Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3146",
            name: "Nguy\u1ec5n V\u0103n Nguy\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3147",
            name: "Nguy\u1ec5n V\u0103n Th\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3148",
            name: "Nguy\u1ec5n V\u0103n Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3149",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3150",
            name: "Nguy\u1ec5n V\u0103n Tr\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3151", name: "Pasteur", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3152",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3153",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3154",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3155",
            name: "Ph\u1ea1m Vi\u1ebft Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3156",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3157",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3158",
            name: "Phan K\u1ebf B\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3159",
            name: "Phan Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3160", name: "Phan Ng\u1eef", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3161", name: "Phan T\u00f4n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3162",
            name: "Phan V\u0103n \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3163",
            name: "Phan V\u0103n Tr\u01b0\u1eddng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "3164",
            name: "Ph\u00f3 \u0110\u1ee9c Ch\u00ednh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "3165",
            name: "Ph\u00f9ng Kh\u1eafc Khoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3166", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3167",
            name: "S\u01b0\u01a1ng Nguy\u1ec7t \u00c1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3168",
            name: "Th\u1ea1ch Th\u1ecb Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3169",
            name: "Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3170",
            name: "Th\u00e1i V\u0103n Lung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3171", name: "Thi S\u00e1ch", prefix: "Ph\u1ed1" },
          {
            id: "3172",
            name: "Th\u1ee7 Khoa Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3173",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "3174",
            name: "T\u00f4n Th\u1ea5t \u0110\u1ea1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3175",
            name: "T\u00f4n Th\u1ea5t Thi\u1ec7p",
            prefix: "Ph\u1ed1",
          },
          {
            id: "3176",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3177",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3178",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3180",
            name: "Tr\u1ea7n Do\u00e3n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3179",
            name: "Tr\u1ea7n \u0110\u00ecnh Xu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3181",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3182",
            name: "Tr\u1ea7n Kh\u1eafc Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3183",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3184",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3185",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3186",
            name: "Tr\u1ea7n Qu\u1ed1c Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3187",
            name: "Tr\u1ea7n Qu\u00fd Kho\u00e1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3188",
            name: "Tr\u1ea7n Tu\u1ea5n Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3189",
            name: "Tr\u1ea7n V\u0103n K\u1ef7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3190",
            name: "Tr\u1ecbnh V\u0103n C\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3191",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3192",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3193",
            name: "Tr\u01b0\u1eddng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3194",
            name: "T\u00fa X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3195", name: "V\u1ea1n Ki\u1ebfp", prefix: "Ph\u1ed1" },
          {
            id: "3196",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3197",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3198",
            name: "X\u00f4 Vi\u1ebft Ngh\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3199", name: "Yersin", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "304",
            name: "10A Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            lat: "10.794728279114",
            lng: "106.6887588501",
          },
          {
            id: "305",
            name: "203 Nguy\u1ec5n Tr\u00e3i",
            lat: "10.766036987305",
            lng: "106.68797302246",
          },
          {
            id: "306",
            name: "Abacus Tower",
            lat: "10.789052963257",
            lng: "106.69992828369",
          },
          {
            id: "307",
            name: "Ascott Waterfront Saigon",
            lat: "10.782614707947",
            lng: "106.70549011231",
          },
          {
            id: "308",
            name: "Avalon Saigon Apartments",
            lat: "10.779725074768",
            lng: "106.69546508789",
          },
          {
            id: "309",
            name: "Bitexco Financial",
            lat: "10.771620750427",
            lng: "106.70449066162",
          },
          {
            id: "310",
            name: "BMC B\u1ebfn Ch\u01b0\u01a1ng D\u01b0\u01a1ng",
            lat: "10.759797096252",
            lng: "106.69319915772",
          },
          {
            id: "311",
            name: "C.T Plaza V\u00f5 V\u0103n Ki\u1ec7t",
            lat: "10.763944625854",
            lng: "106.69721984863",
          },
          {
            id: "312",
            name: "Central Garden",
            lat: "10.761813163757",
            lng: "106.69492340088",
          },
          {
            id: "313",
            name: "Chung c\u01b0 Th\u1ebf H\u1ec7 M\u1edbi",
            lat: "10.760112762451",
            lng: "106.69343566894",
          },
          {
            id: "314",
            name: "Citilight Tower",
            lat: "10.790254592896",
            lng: "106.69476318359",
          },
          {
            id: "315",
            name: "Compass Living Park View",
            lat: "10.772612571716",
            lng: "106.6932144165",
          },
          {
            id: "316",
            name: "C\u1ed1ng Qu\u1ef3nh Plaza",
            lat: "10.766673088074",
            lng: "106.68806457519",
          },
          {
            id: "317",
            name: "D1 Mension",
            lat: "10.75528717041",
            lng: "106.68809509277",
          },
          {
            id: "318",
            name: "Empire Tower",
            lat: "10.770987510681",
            lng: "106.70484161377",
          },
          {
            id: "319",
            name: "HMTC Building",
            lat: "10.784470558167",
            lng: "106.69650268555",
          },
          {
            id: "320",
            name: "Horizon",
            lat: "10.791884422302",
            lng: "106.68774414062",
          },
          {
            id: "321",
            name: "IBC Building",
            lat: "10.776081085205",
            lng: "106.7064743042",
          },
          {
            id: "322",
            name: "IMG Building",
            lat: "10.78786945343",
            lng: "106.69890594482",
          },
          {
            id: "323",
            name: "Indochina",
            lat: "10.791642189026",
            lng: "106.70240020752",
          },
          {
            id: "324",
            name: "International Plaza",
            lat: "10.767263412476",
            lng: "106.69010162353",
          },
          {
            id: "325",
            name: "Lancaster Nguy\u1ec5n Tr\u00e3i",
            lat: "10.764755249023",
            lng: "106.68629455566",
          },
          {
            id: "326",
            name: "Lim Tower",
            lat: "10.781789779663",
            lng: "106.70613861084",
          },
          {
            id: "327",
            name: "Madison 15 Thi S\u00e1ch",
            lat: "10.778396606445",
            lng: "106.7040939331",
          },
          {
            id: "328",
            name: "Mai H\u1ed3ng Qu\u1ebf Building",
            lat: "10.790431976318",
            lng: "106.69095611572",
          },
          {
            id: "329",
            name: "M\u00ea Linh Point Tower",
            lat: "10.774513244629",
            lng: "106.70600891113",
          },
          {
            id: "330",
            name: "Parksimex Building",
            lat: "10.776617050171",
            lng: "106.70511627197",
          },
          {
            id: "331",
            name: "Resco Building",
            lat: "10.777509689331",
            lng: "106.69822692871",
          },
          {
            id: "332",
            name: "Sacomreal Generalimex",
            lat: "10.769884109497",
            lng: "106.69982147217",
          },
          {
            id: "333",
            name: "S\u00e0i G\u00f2n M\u00ea Linh Tower",
            lat: "10.77651309967",
            lng: "106.70558929443",
          },
          {
            id: "334",
            name: "Saigon Centre",
            lat: "10.773184776306",
            lng: "106.70055389404",
          },
          {
            id: "335",
            name: "Saigon Land Building",
            lat: "10.782467842102",
            lng: "106.70519256592",
          },
          {
            id: "336",
            name: "Saigon One Tower",
            lat: "10.770014762878",
            lng: "106.70554351807",
          },
          {
            id: "337",
            name: "Saigon Trade Center",
            lat: "10.784090995789",
            lng: "106.70365905762",
          },
          {
            id: "338",
            name: "Sailing Tower",
            lat: "10.779865264893",
            lng: "106.6958694458",
          },
          {
            id: "339",
            name: "Smart View",
            lat: "10.766300201416",
            lng: "106.69400024414",
          },
          {
            id: "340",
            name: "Star Building",
            lat: "10.784742355347",
            lng: "106.69902038574",
          },
          {
            id: "341",
            name: "Sunwah Tower",
            lat: "10.773791313171",
            lng: "106.70344543457",
          },
          {
            id: "342",
            name: "Th\u00e1i S\u01a1n Building",
            lat: "10.762587547302",
            lng: "106.6873626709",
          },
          {
            id: "343",
            name: "The Lancaster HCM",
            lat: "10.78108215332",
            lng: "106.70478057861",
          },
          {
            id: "344",
            name: "The One S\u00e0i G\u00f2n",
            lat: "10.767124176025",
            lng: "106.6985244751",
          },
          {
            id: "345",
            name: "The ONne Ho Chi Minh City",
            lat: "10.770162582398",
            lng: "106.69828796387",
          },
          {
            id: "346",
            name: "TKT Office Building",
            lat: "10.756773948669",
            lng: "106.68589019775",
          },
          {
            id: "347",
            name: "V&D Building",
            lat: "10.761585235596",
            lng: "106.69002532959",
          },
          {
            id: "348",
            name: "Valentina Court",
            lat: "10.788854598999",
            lng: "106.69651794434",
          },
          {
            id: "349",
            name: "Viet Dragon Tower",
            lat: "10.77269077301",
            lng: "106.69367218018",
          },
          {
            id: "350",
            name: "Vietcombank Tower HCM",
            lat: "10.775577545166",
            lng: "106.70532989502",
          },
          {
            id: "351",
            name: "Vinaconex Building",
            lat: "10.790928840637",
            lng: "106.69839477539",
          },
          {
            id: "352",
            name: "Vincom Center TP HCM",
            lat: "10.778249740601",
            lng: "106.70202636719",
          },
          {
            id: "353",
            name: "Vincom Financial Tower",
            lat: "10.768757820129",
            lng: "106.70114898682",
          },
          {
            id: "354",
            name: "Vinhomes Golden River Ba Son",
            lat: "10.782538414002",
            lng: "106.70750427246",
          },
          {
            id: "355",
            name: "VP Tower",
            lat: "10.7706823349",
            lng: "106.70230865478",
          },
          {
            id: "356",
            name: "VRG River View",
            lat: "10.754638671875",
            lng: "106.68699645996",
          },
        ],
      },
      {
        id: "11",
        name: "Qu\u1eadn 10",
        wards: [
          { id: "139", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "140", name: "10", prefix: "Ph\u01b0\u1eddng" },
          { id: "141", name: "11", prefix: "Ph\u01b0\u1eddng" },
          { id: "142", name: "12", prefix: "Ph\u01b0\u1eddng" },
          { id: "143", name: "13", prefix: "Ph\u01b0\u1eddng" },
          { id: "144", name: "14", prefix: "Ph\u01b0\u1eddng" },
          { id: "145", name: "15", prefix: "Ph\u01b0\u1eddng" },
          { id: "146", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "147", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "148", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "149", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "150", name: "6", prefix: "Ph\u01b0\u1eddng" },
          { id: "151", name: "7", prefix: "Ph\u01b0\u1eddng" },
          { id: "152", name: "8", prefix: "Ph\u01b0\u1eddng" },
          { id: "153", name: "9", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "3200", name: "284", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3201", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3202", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3203", name: "506", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3204", name: "68", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3205", name: "7A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3206",
            name: "An Ph\u00fa \u0110\u00f4ng 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3207",
            name: "\u00c2u D\u01b0\u01a1ng L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3208",
            name: "Ba \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3209",
            name: "B\u00e0 H\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3210", name: "Ba V\u00ec", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3211",
            name: "B\u1eafc H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3212",
            name: "B\u1ea1ch M\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3213",
            name: "B\u00e0n C\u1edd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3214",
            name: "B\u1ebfn B\u00ecnh \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3215",
            name: "B\u1ebfn Nguy\u1ec5n Duy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3216",
            name: "B\u00ecnh Th\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3217",
            name: "B\u00f9i Huy B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3218", name: "B\u1eedu Long", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3219",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3220", name: "Cao L\u1ed7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3221",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3222",
            name: "Ch\u1ea5n H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3223",
            name: "Ch\u00e2u Th\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3224",
            name: "C\u01b0 X\u00e1 B\u1eafc H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3225",
            name: "C\u01b0 X\u00e1 \u0110\u1ed3ng Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3226",
            name: "C\u01b0 X\u00e1 Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3227", name: "C\u1eedu Long", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3228", name: "D\u1ea1 Nam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3229",
            name: "\u0110\u1eb7ng Ch\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3230",
            name: "\u0110\u00e0o Duy Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3231",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3232",
            name: "\u0110\u1ea5t Th\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3233",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3234",
            name: "\u0110inh H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3235",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3236",
            name: "\u0110\u00f4ng H\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3237",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3238",
            name: "\u0110\u1ed3ng Nai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3239",
            name: "\u0110\u1ed3ng Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3240", name: "Gia Ph\u00fa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3241",
            name: "H\u1eadu L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3242",
            name: "Hi\u1ec7p Th\u00e0nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3243",
            name: "H\u1ed3 B\u00e1 Ki\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3244",
            name: "H\u1ed3 H\u1ea3o H\u1edbn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3245",
            name: "H\u1ed3 Th\u1ecb K\u1ef7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3246",
            name: "H\u00f2a H\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3247",
            name: "H\u00f2a H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3248",
            name: "Ho\u00e0ng D\u01b0 Kh\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3249",
            name: "H\u1ed3ng B\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3250",
            name: "H\u1ed3ng L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3251",
            name: "H\u01b0ng Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3252",
            name: "H\u01b0ng Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3253",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3254",
            name: "H\u01b0\u01a1ng Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3255",
            name: "Hu\u1ef3nh Th\u1ecb Ph\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3256",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3257",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3258",
            name: "L\u00ea Quang Kim",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3259",
            name: "L\u00ea Th\u1ecb Ri\u00eang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3260",
            name: "L\u1ed9c H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3261",
            name: "L\u01b0\u01a1ng Ng\u1ecdc Quy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3262",
            name: "L\u01b0u Qu\u00fd K\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3263",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3264",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3265",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "3266",
            name: "M\u1ea1c V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3267",
            name: "M\u01b0\u01a1ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3268",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3269",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3270",
            name: "Nguy\u1ec5n Ch\u1ebf Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3271",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3272",
            name: "Nguy\u1ec5n Duy D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3273",
            name: "Nguy\u1ec5n Gi\u1ea3n Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3274",
            name: "Nguy\u1ec5n Huy Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3275",
            name: "Nguy\u1ec5n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3276",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3277",
            name: "Nguy\u1ec5n Kim",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3278",
            name: "Nguy\u1ec5n L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3279",
            name: "Nguy\u1ec5n Ng\u1ecdc L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3280",
            name: "Nguy\u1ec5n S\u01a1n H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3281",
            name: "Nguy\u1ec5n Th\u1ecb Nh\u1ecf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3282",
            name: "Nguy\u1ec5n Th\u1ecb T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3283",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "3284",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3285",
            name: "Nguy\u1ec5n Ti\u1ec3u La",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3286",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3287",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3288",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3289",
            name: "Nguy\u1ec5n V\u0103n C\u1ee7a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3290",
            name: "Nh\u1eadt T\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3291",
            name: "Ph\u1ea1m Th\u1ebf Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3292",
            name: "R\u1ea1ch \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3293", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3294", name: "S\u1ed1 17A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3295", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3296", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3297", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3298", name: "S\u1ed1 7A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3299", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3300",
            name: "S\u01b0 V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3301", name: "T\u1ea1 Quang B\u1eedu", prefix: "Ph\u1ed1" },
          {
            id: "3302",
            name: "Tam \u0110\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3303",
            name: "T\u1ea3n \u0110\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3304",
            name: "T\u00e2n Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3305",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3306",
            name: "Th\u00e1i Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3307",
            name: "Th\u1ea1nh L\u1ed9c 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3308",
            name: "Th\u00e0nh Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3309",
            name: "Th\u1ea1nh Xu\u00e2n 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3310",
            name: "Th\u1ea5t S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3311",
            name: "Thi\u00ean H\u1ed9 D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3312",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3313",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3314",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3315",
            name: "Tr\u1ea7n Minh Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3316",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "3317",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3318",
            name: "Tr\u1ea7n Thi\u1ec7n Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3319",
            name: "Tr\u1ea7n V\u0103n Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3320",
            name: "Tr\u00edch S\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3321",
            name: "Tr\u01b0\u1eddng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3322",
            name: "Tuy L\u00fd V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3323", name: "V\u0129nh Nam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3324",
            name: "V\u0129nh Vi\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3325",
            name: "V\u00f5 Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3326",
            name: "X\u00f3m C\u1ee7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "357",
            name: "\u1ea4n Quang",
            lat: "10.765694618225",
            lng: "106.67097473144",
          },
          {
            id: "358",
            name: "Charmington La Pointe",
            lat: "10.774741172791",
            lng: "106.67462921143",
          },
          {
            id: "359",
            name: "Chung c\u01b0 Ng\u00f4 Gia T\u1ef1",
            lat: "10.762373924255",
            lng: "106.67213439941",
          },
          {
            id: "360",
            name: "\u0110\u00e0o Duy T\u1eeb",
            lat: "10.770593643188",
            lng: "106.66315460205",
          },
          {
            id: "361",
            name: "H\u00e0 \u0110\u00f4 Villas",
            lat: "10.773082733154",
            lng: "106.66995239258",
          },
          {
            id: "362",
            name: "HaDo Centrosa Garden",
            lat: "10.776251792908",
            lng: "106.6792678833",
          },
          {
            id: "363",
            name: "Kingdom 101",
            lat: "10.782732963562",
            lng: "106.67167663574",
          },
          {
            id: "364",
            name: "Nguy\u1ec5n Kim",
            lat: "10.761591911316",
            lng: "106.66174316406",
          },
          {
            id: "365",
            name: "Rivera Park S\u00e0i G\u00f2n",
            lat: "10.769742012024",
            lng: "106.66360473633",
          },
          {
            id: "366",
            name: "Samco Building",
            lat: "10.759072303772",
            lng: "106.66465759277",
          },
          {
            id: "367",
            name: "Tenimex Office Building",
            lat: "10.76541519165",
            lng: "106.67314147949",
          },
          {
            id: "368",
            name: "Thi\u00ean Nam Apartment",
            lat: "10.773824691773",
            lng: "106.66159057617",
          },
          {
            id: "369",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            lat: "10.775464057922",
            lng: "106.66561126709",
          },
          {
            id: "370",
            name: "Toplife Tower",
            lat: "10.774305343628",
            lng: "106.68035888672",
          },
          {
            id: "371",
            name: "Tr\u1ea7n V\u0103n Ki\u1ec3u",
            lat: "10.766778945923",
            lng: "106.66036224365",
          },
          {
            id: "372",
            name: "Viettel Complex",
            lat: "10.778330802918",
            lng: "106.67916107178",
          },
          {
            id: "373",
            name: "Xi Grand Court",
            lat: "10.767601013184",
            lng: "106.65940856934",
          },
        ],
      },
      {
        id: "12",
        name: "Qu\u1eadn 11",
        wards: [
          { id: "154", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "155", name: "10", prefix: "Ph\u01b0\u1eddng" },
          { id: "156", name: "11", prefix: "Ph\u01b0\u1eddng" },
          { id: "157", name: "12", prefix: "Ph\u01b0\u1eddng" },
          { id: "158", name: "13", prefix: "Ph\u01b0\u1eddng" },
          { id: "159", name: "14", prefix: "Ph\u01b0\u1eddng" },
          { id: "160", name: "15", prefix: "Ph\u01b0\u1eddng" },
          { id: "161", name: "16", prefix: "Ph\u01b0\u1eddng" },
          { id: "162", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "163", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "164", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "165", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "166", name: "6", prefix: "Ph\u01b0\u1eddng" },
          { id: "167", name: "7", prefix: "Ph\u01b0\u1eddng" },
          { id: "168", name: "8", prefix: "Ph\u01b0\u1eddng" },
          { id: "169", name: "9", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "3327", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3328", name: "100", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3329", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3330", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3331", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3332", name: "281", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3333", name: "299", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3334", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3335", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3336", name: "34", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3337", name: "3A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3338",
            name: "3A C\u01b0 X\u00e1 B\u00ecnh Th\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3339", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3340", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3341", name: "52", prefix: "Ph\u1ed1" },
          { id: "3342", name: "5A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3343", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3344", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3345", name: "702", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3346", name: "708", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3347", name: "762", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3348", name: "7A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3349", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3350", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3351",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3352",
            name: "B\u00ecnh Th\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3353",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3354",
            name: "C\u00f4ng Ch\u00faa Ng\u1ecdc H\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3355",
            name: "C\u01b0 X\u00e1 B\u00ecnh Th\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3356",
            name: "C\u01b0 X\u00e1 L\u1eef Gia",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3357",
            name: "C\u01b0 X\u00e1 Ph\u00fa B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3358",
            name: "C\u01b0 X\u00e1 Ph\u00fa H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3359",
            name: "C\u01b0 x\u00e1 Ph\u00fa Th\u1ecd H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3364",
            name: "D\u01b0\u01a1ng \u0110\u00ecnh Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3365",
            name: "D\u01b0\u01a1ng T\u1eed Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3360",
            name: "\u0110\u1eb7ng Minh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3361",
            name: "\u0110\u00e0o Nguy\u00ean Ph\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3362",
            name: "\u0110\u1ed7 Ng\u1ecdc Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3363",
            name: "\u0110\u1ed9i Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3366",
            name: "H\u00e0 T\u00f4n Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3367",
            name: "H\u00e0n H\u1ea3i Nguy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3368",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3369",
            name: "H\u00f2a H\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3370",
            name: "Ho\u00e0ng \u0110\u1ee9c T\u01b0\u01a1ng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "3371",
            name: "H\u1ed3ng B\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3372",
            name: "Huy\u1ec7n To\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3373",
            name: "K\u00eanh T\u00e2n H\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3374",
            name: "Khu\u00f4ng Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3375",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3376",
            name: "L\u00e3nh Binh Th\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3377",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3378",
            name: "L\u00ea Ng\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3379",
            name: "L\u00ea Th\u1ecb B\u1ea1ch C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3380", name: "L\u00ea Tung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3381",
            name: "L\u00f2 Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3382", name: "L\u1eef Gia", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3383",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3384",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "3385",
            name: "Minh Ph\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3386",
            name: "Nguy\u1ec5n B\u00e1 H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3387",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3388",
            name: "Nguy\u1ec5n Th\u1ecb Nh\u1ecf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3389",
            name: "Nguy\u1ec5n V\u0103n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3390",
            name: "Nh\u1eadt T\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3391",
            name: "\u00d4ng B\u00edch Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3392",
            name: "\u00d4ng \u00cdch Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3393",
            name: "Phan X\u00edch Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3394",
            name: "Ph\u00f3 C\u01a1 \u0110i\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3395",
            name: "Ph\u00fa Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3396",
            name: "Qu\u00e2n S\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3397", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3398",
            name: "S\u1ed1 1 C\u01b0 x\u00e1 L\u1eef Gia",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3399", name: "S\u1ed1 100", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3400", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3401", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3402",
            name: "S\u1ed1 16 C\u01b0 X\u00e1 L\u1eef Gia",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3403", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3404", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3405",
            name: "S\u1ed1 2 C\u01b0 X\u00e1 B\u00ecnh Th\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3406",
            name: "S\u1ed1 2 C\u01b0 X\u00e1 L\u1eef Gia",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3407", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3408", name: "S\u1ed1 28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3409", name: "S\u1ed1 281", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3410", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3411",
            name: "S\u1ed1 3 C\u01b0 X\u00e1 B\u00ecnh Th\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3412",
            name: "S\u1ed1 3 C\u01b0 x\u00e1 L\u1eef Gia",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3413",
            name: "S\u1ed1 34 C\u01b0 X\u00e1 L\u1eef Gia",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3414", name: "S\u1ed1 3A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3415",
            name: "S\u1ed1 3A C\u01b0 X\u00e1 B\u00ecnh Th\u01a1\u0301i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3416", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3417",
            name: "S\u1ed1 4 C\u01b0 x\u00e1 B\u00ecnh Th\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3418", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3419",
            name: "S\u1ed1 5 C\u01b0 x\u00e1 B\u00ecnh Th\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3420", name: "S\u1ed1 52", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3421",
            name: "S\u1ed1 52 C\u01b0 X\u00e1 L\u1eef Gia",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3422", name: "S\u1ed1 55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3423", name: "S\u1ed1 5A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3424", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3425",
            name: "S\u1ed1 6 C\u01b0 X\u00e1 B\u00ecnh Th\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3426",
            name: "S\u1ed1 6 C\u01b0 X\u00e1 L\u1eef Gia",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3427", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3428",
            name: "S\u1ed1 7 C\u01b0 X\u00e1 B\u00ecnh Th\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3429",
            name: "S\u1ed1 7A C\u01b0 x\u00e1 B\u00ecnh Th\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3430",
            name: "S\u1ed1 85 C\u01b0 X\u00e1 L\u1eef Gia",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3431", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3432",
            name: "S\u1ed1 9 C\u01b0 x\u00e1 B\u00ecnh Th\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3433",
            name: "T\u1ea1 Uy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3434",
            name: "T\u00e2n H\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3435", name: "T\u00e2n Khai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3436",
            name: "T\u00e2n Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3437",
            name: "T\u00e2n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3438",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 07",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3439",
            name: "Th\u00e1i Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3440",
            name: "Thi\u00ean Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3441",
            name: "Th\u1edbi An 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3442",
            name: "Thu\u1eadn Ki\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3443",
            name: "Thu\u1eadn Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3444",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3445",
            name: "T\u00f4n Th\u1ea5t Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3446",
            name: "T\u1ed5ng Lung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3447",
            name: "T\u1ed1ng V\u0103n Tr\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3448",
            name: "Tr\u1ea7n Qu\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3449",
            name: "Tr\u1ea7n V\u0103n Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3450",
            name: "Tr\u1ecbnh \u0110\u00ecnh Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3451",
            name: "Tr\u1ecbnh \u0110\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3452",
            name: "Tr\u01b0\u1eddng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3453",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3454",
            name: "V\u0129nh Vi\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3455",
            name: "Xa L\u1ed9 \u0110a\u0323i Ha\u0300n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3456",
            name: "X\u00f3m \u0110\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3457",
            name: "Xuy\u00ean \u00c1",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "374",
            name: "Bigemco",
            lat: "10.775342941284",
            lng: "106.65589904785",
          },
          {
            id: "375",
            name: "C\u0103n h\u1ed9 Kh\u1ea3i Ho\u00e0n",
            lat: "10.77355670929",
            lng: "106.64756011963",
          },
          {
            id: "376",
            name: "C\u00e2y Mai",
            lat: "10.755464553833",
            lng: "106.65080261231",
          },
          {
            id: "377",
            name: "Chung c\u01b0 312 L\u1ea1c Long Qu\u00e2n",
            lat: "10.767893791199",
            lng: "106.64366149902",
          },
          {
            id: "378",
            name: "Chung c\u01b0 Ph\u00fa Th\u1ecd",
            lat: "10.771301269531",
            lng: "106.6533279419",
          },
          {
            id: "379",
            name: "\u0110\u1ea7m Sen",
            lat: "10.762916564941",
            lng: "106.6365814209",
          },
          {
            id: "380",
            name: "Lotus Apartment",
            lat: "10.766078948975",
            lng: "106.64296722412",
          },
          {
            id: "381",
            name: "L\u1eef Gia Plaza",
            lat: "10.771562576294",
            lng: "106.65316772461",
          },
          {
            id: "382",
            name: "Res 11",
            lat: "10.763312339783",
            lng: "106.64205932617",
          },
          {
            id: "383",
            name: "T\u00e2n Ph\u01b0\u1edbc Plaza",
            lat: "10.761169433594",
            lng: "106.65948486328",
          },
          {
            id: "384",
            name: "The EverRich I",
            lat: "10.763243675232",
            lng: "106.65711975098",
          },
          {
            id: "385",
            name: "The Flemington",
            lat: "10.764791488648",
            lng: "106.65550231934",
          },
          {
            id: "386",
            name: "The Park Avenue",
            lat: "21.0306224823",
            lng: "105.84886932373",
          },
          {
            id: "387",
            name: "Thu\u1eadn Vi\u1ec7t",
            lat: "10.77668762207",
            lng: "106.6545791626",
          },
          {
            id: "388",
            name: "Tu\u1ec7 T\u0129nh",
            lat: "10.762060165405",
            lng: "106.65449523926",
          },
        ],
      },
      {
        id: "13",
        name: "Qu\u1eadn 12",
        wards: [
          {
            id: "170",
            name: "An Ph\u00fa \u0110\u00f4ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "171",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "172",
            name: "Hi\u1ec7p Th\u00e0nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "173",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "174",
            name: "T\u00e2n H\u01b0ng Thu\u1eadn",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "175",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "176",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "177",
            name: "Th\u1ea1nh L\u1ed9c",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "178",
            name: "Th\u1ea1nh Xu\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "179", name: "Th\u1edbi An", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "180",
            name: "Trung M\u1ef9 T\u00e2y",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "3458", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3459", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3460", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3461", name: "122", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3462", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3463", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3464", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3465", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3466", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3467", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3468", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3469", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3470", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3471", name: "25B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3472", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3473", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3474", name: "30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3475", name: "32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3476", name: "33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3477", name: "35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3478", name: "36", prefix: "Ph\u1ed1" },
          { id: "3479", name: "38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3480", name: "39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3481", name: "40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3482", name: "43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3483", name: "45", prefix: "Ph\u1ed1" },
          { id: "3484", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3485", name: "48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3486", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3487", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3488", name: "51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3489", name: "595", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3490", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3491", name: "66", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3492", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3493", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3494", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3495", name: "9A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3496", name: "A2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3497", name: "A3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3498", name: "An L\u1ed9c", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3499",
            name: "An Ph\u00fa \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3500",
            name: "An Ph\u00fa \u0110\u00f4ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3501",
            name: "An Phu\u0301 \u0110\u00f4ng 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3502",
            name: "An Ph\u00fa \u0110\u00f4ng 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3503",
            name: "An Ph\u00fa \u0110\u00f4ng 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3504",
            name: "An Ph\u00fa \u0110\u00f4ng 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3505",
            name: "An Ph\u00fa \u0110\u00f4ng 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3506",
            name: "An Ph\u00fa \u0110\u00f4ng 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3507",
            name: "An Ph\u00fa \u0110\u00f4ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3508",
            name: "An Ph\u00fa \u0110\u00f4ng 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3509",
            name: "An Ph\u00fa \u0110\u00f4ng 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3510",
            name: "An Ph\u00fa \u0110\u00f4ng 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3511",
            name: "An Ph\u00fa \u0110\u00f4ng 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3512",
            name: "An Ph\u00fa \u0110\u00f4ng 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3513",
            name: "An Ph\u00fa \u0110\u00f4ng 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3514",
            name: "An Ph\u00fa \u0110\u00f4ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3515",
            name: "An Ph\u00fa \u0110\u00f4ng 31",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3516",
            name: "An Ph\u00fa \u0110\u00f4ng 35",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3517",
            name: "An Ph\u00fa \u0110\u00f4ng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3518",
            name: "An Ph\u00fa \u0110\u00f4ng 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3519", name: "Ba Ph\u1ee5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3520", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "3521",
            name: "B\u00f9i C\u00f4ng Tr\u1eebng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3522",
            name: "B\u00f9i V\u0103n Ng\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3523",
            name: "B\u00f9i V\u0103n Th\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3524", name: "C1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3525", name: "C3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3526", name: "C4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3527",
            name: "C\u1ea7u Ba Ph\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3528",
            name: "C\u1ea7u Ch\u1ee3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3529", name: "C\u00e2y Sao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3530",
            name: "Chi\u1ebfn Khu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3531",
            name: "C\u00f9 Lao Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3532", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3533", name: "D15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3534", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3535", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3536", name: "D6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3537", name: "DCT1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3538", name: "DCT5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3539", name: "DCT9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3540", name: "DD10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3541", name: "DD11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3542", name: "DD12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3543", name: "DD2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3544", name: "DD4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3545", name: "DD4-1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3546", name: "DD4-2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3547", name: "DD5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3548", name: "DD6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3549", name: "DD6-1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3550", name: "DD7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3551", name: "DD7-1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3552", name: "DD9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3555", name: "DN 7-1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3556", name: "DN10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3557", name: "DN11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3558", name: "DN12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3559", name: "DN13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3560", name: "DN4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3561", name: "DN5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3562", name: "DN6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3563", name: "DN7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3564", name: "DN8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3565", name: "DN9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3606",
            name: "Do\u0300ng S\u00f4ng Xanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3611", name: "DTC 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3612", name: "DTC2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3613",
            name: "D\u01b0\u01a1ng Th\u1ecb Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3614",
            name: "D\u01b0\u01a1ng Th\u1ecb M\u01b0\u1eddi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3553",
            name: "\u0110\u00ecnh Giao Kh\u1ea9u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3554",
            name: "\u0110\u00ecnh Th\u1ea1nh Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3566",
            name: "\u0110\u1ed7 H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3567",
            name: "\u0110\u1ed7 Quy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3568",
            name: "\u0110\u00f4ng B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3569",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3570",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3571",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 10B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3572",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3573",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3574",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3575",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3576",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 14B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3577",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3578",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3579",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3580",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3581",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3582",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3583",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3584",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3585",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3586",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3587",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3588",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3589",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3590",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3591",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 31",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3592",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3593",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 33",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3594",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 36",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3595",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 39",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3596",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 40",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3597",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 41",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3598",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 42",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3599",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 45",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3600",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 47",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3601",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3602",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3603",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3604",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3605",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3607",
            name: "\u0110\u1ed3ng T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3608",
            name: "\u0110\u1ed3ng Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3609", name: "\u0110T 741", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3610", name: "\u0110T 747", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3615",
            name: "Giang C\u1ef1 V\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3616", name: "Go\u0300 Sao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3617",
            name: "H\u00e0 Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3618", name: "H\u00e0 \u0110\u1eb7c", prefix: "Ph\u1ed1" },
          {
            id: "3619",
            name: "H\u00e0 Huy Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3620",
            name: "H\u00e0 Huy Gi\u00e1p 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3621",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3622",
            name: "H\u00e0 Th\u1ecb Kh\u00e9o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3623",
            name: "H\u00e0 Th\u1ecb Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3624",
            name: "H\u00e0n Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3625",
            name: "H\u1eadu L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3626",
            name: "Hi\u1ec7p Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3627",
            name: "Hi\u1ec7p Th\u00e0nh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3628",
            name: "Hi\u1ec7p Th\u00e0nh 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3629",
            name: "Hi\u1ec7p Th\u00e0nh 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3630",
            name: "Hi\u1ec7p Th\u00e0nh 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3631",
            name: "Hi\u1ec7p Th\u00e0nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3632",
            name: "Hi\u1ec7p Th\u00e0nh 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3633",
            name: "Hi\u1ec7p Th\u00e0nh 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3634",
            name: "Hi\u1ec7p Th\u00e0nh 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3635",
            name: "Hi\u1ec7p Th\u00e0nh 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3636",
            name: "Hi\u1ec7p Th\u00e0nh 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3637",
            name: "Hi\u1ec7p Th\u00e0nh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3638",
            name: "Hi\u1ec7p Th\u00e0nh 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3639",
            name: "Hi\u1ec7p Th\u00e0nh 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3640",
            name: "Hi\u1ec7p Th\u00e0nh 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3641",
            name: "Hi\u1ec7p Th\u00e0nh 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3642",
            name: "Hi\u1ec7p Th\u00e0nh 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3643",
            name: "Hi\u1ec7p Th\u00e0nh 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3644",
            name: "Hi\u1ec7p Th\u00e0nh 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3645",
            name: "Hi\u1ec7p Th\u00e0nh 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3646",
            name: "Hi\u1ec7p Th\u00e0nh 31",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3647",
            name: "Hi\u1ec7p Th\u00e0nh 33",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3648",
            name: "Hi\u1ec7p Th\u00e0nh 35",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3649",
            name: "Hi\u1ec7p Th\u00e0nh 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3650",
            name: "Hi\u1ec7p Th\u00e0nh 39",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3651",
            name: "Hi\u1ec7p Th\u00e0nh 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3652",
            name: "Hi\u1ec7p Th\u00e0nh 42",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3653",
            name: "Hi\u1ec7p Th\u00e0nh 43",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3654",
            name: "Hi\u1ec7p Th\u00e0nh 44",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3655",
            name: "Hi\u1ec7p Th\u00e0nh 45",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3656",
            name: "Hi\u1ec7p Th\u00e0nh 48",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3657", name: "Hi\u1ec7p Th\u00e0nh 49", prefix: "Ph\u1ed1" },
          {
            id: "3658",
            name: "Hi\u1ec7p Th\u00e0nh 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3659",
            name: "Hi\u1ec7p Th\u00e0nh 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3660",
            name: "Hi\u1ec7p Th\u00e0nh 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3661",
            name: "Hi\u1ec7p Th\u00e0nh 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3662",
            name: "Hi\u1ec7p Th\u00e0nh 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3663",
            name: "H\u1ed3 Bi\u1ec3u Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3664",
            name: "H\u1ed3 B\u01a1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3665", name: "H\u00f2a An 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3666", name: "H\u1ecda Mi", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3667",
            name: "Ho\u00e0ng T\u0103ng B\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3668",
            name: "H\u01b0ng Thu\u1eadn 44",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3669",
            name: "H\u01b0\u01a1ng L\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3670",
            name: "H\u01b0\u01a1ng l\u1ed9  80",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3671",
            name: "H\u01b0\u01a1ng l\u1ed9 17A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3672",
            name: "H\u01b0\u01a1ng L\u1ed9 18B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3673",
            name: "H\u01b0\u01a1ng L\u00f4\u0323 80B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3674",
            name: "H\u01b0\u01a1ng l\u1ed9 84B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3675",
            name: "Hu\u1ef3nh Th\u1ecb Hai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3676",
            name: "K\u00eanh Tham L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3677",
            name: "Kh\u1edfi Ngh\u0129a B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3678",
            name: "L\u00e2m Th\u1ecb H\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3679",
            name: "L\u00ea \u0110\u1ee9c Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3680",
            name: "L\u00ea Th\u1ecb Nho",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3681",
            name: "L\u00ea Th\u1ecb Ri\u00eang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3682",
            name: "L\u00ea V\u0103n Kh\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3683",
            name: "L\u1ed9c H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3684",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3685",
            name: "M\u01b0\u01a1ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3686",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3687",
            name: "Nguy\u1ec5n \u1ea2nh Th\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3688",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3689",
            name: "Nguy\u1ec5n Huy Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3690",
            name: "Nguy\u1ec5n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3691",
            name: "Nguy\u1ec5n Oanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3692",
            name: "Nguy\u1ec5n Th\u00e0nh V\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3693",
            name: "Nguy\u1ec5n Th\u1ecb B\u00fap",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3694",
            name: "Nguy\u1ec5n Th\u1ecb C\u0103n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3695",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1eb7ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3696",
            name: "Nguy\u1ec5n Th\u1ecb Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3697",
            name: "Nguy\u1ec5n Th\u1ecb Ki\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3698",
            name: "Nguy\u1ec5n Th\u1ecb S\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3699",
            name: "Nguy\u1ec5n Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3700",
            name: "Nguy\u1ec5n Th\u1ecb Th\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3701",
            name: "Nguy\u1ec5n Th\u1ecb Th\u01a1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3702",
            name: "Nguy\u1ec5n Th\u1ecb Tr\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3703",
            name: "Nguy\u1ec5n V\u0103n Qu\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3704",
            name: "Nguy\u1ec5n V\u0103n Th\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3705",
            name: "N\u00fai M\u1ed9t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3706",
            name: "Ph\u1ea7n L\u0103ng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3707",
            name: "Phan V\u0103n H\u1edbn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3708",
            name: "Ph\u00fa Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3709", name: "Qu\u00e1n Tre", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3710", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3711",
            name: "Qu\u1ed1c L\u1ed9 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3712",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3713",
            name: "Qu\u1ed1c L\u1ed9 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3714",
            name: "Qu\u1ed1c l\u1ed9 80",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3715",
            name: "R\u1ea1ch Giao Kh\u1ea9u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3716",
            name: "R\u1ea1ch \u00d4ng H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3717",
            name: "R\u1ea1ch Th\u1ea7y T\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3718", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3719", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3720", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3721", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3722", name: "S\u1ed1 21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3723", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3724", name: "S\u1ed1 23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3725", name: "S\u1ed1 27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3726", name: "S\u1ed1 29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3727", name: "S\u1ed1 2A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3728", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3729", name: "S\u1ed1 37", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3730", name: "S\u1ed1 41B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3731", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3732", name: "S\u1ed1 52", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3733", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3734", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3735", name: "S\u1ed1 8A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3736", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3737", name: "S\u01a1n Ca", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3738", name: "S\u01a1n Ca 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "3739", name: "S\u01a1n Cang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3740",
            name: "S\u01a1n H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3741",
            name: "Song H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3742", name: "T15", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3743",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3744",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3745",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3746",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3747",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3748",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3749",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3750",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3751",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3752",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3753",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3754",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3755",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3756",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3757",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3758",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3759",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 33",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3760",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 34",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3761",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 35",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3762",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 36",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3763",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3764",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3765",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3766",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3767",
            name: "T\u00e2n H\u01b0ng Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3768",
            name: "T\u00e2n H\u01b0ng Thu\u1eadn 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3769",
            name: "T\u00e2n H\u01b0ng Thu\u1eadn 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3770",
            name: "T\u00e2n H\u01b0ng Thu\u1eadn 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3771",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3772",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3773",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3774",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3775",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3776",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3777",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 14B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3778",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3779",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3780",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3781",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3782",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3783",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3784",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3785",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3786",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3787",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3788",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3789",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3790",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3791",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3792",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3793",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3794",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3795",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3796",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3797",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t 05",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3798",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3799",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3800",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3801",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3802",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3803",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3804",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3805",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3806",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3807",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t 1B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3808",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3809",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3810",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3811",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3812",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3813",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3814",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3815",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3816",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3817",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3818",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3819",
            name: "T\u00e2n Th\u1edbi Nh\u1ea5t 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3820",
            name: "T\u00e2n Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3821",
            name: "Th\u1ea1ch Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3822",
            name: "Th\u1ea1nh L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3823",
            name: "Th\u1ea1nh L\u1ed9c 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3824",
            name: "Th\u1ea1nh L\u1ed9c 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3825",
            name: "Th\u1ea1nh L\u1ed9c 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3826",
            name: "Th\u1ea1nh L\u1ed9c 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3827",
            name: "Th\u1ea1nh L\u1ed9c 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3828",
            name: "Th\u1ea1nh L\u1ed9c 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3829",
            name: "Th\u1ea1nh L\u1ed9c 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3830",
            name: "Th\u1ea1nh L\u1ed9c 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3831",
            name: "Th\u1ea1nh L\u1ed9c 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3832",
            name: "Th\u1ea1nh L\u1ed9c 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3833",
            name: "Th\u1ea1nh L\u1ed9c 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3834",
            name: "Th\u1ea1nh L\u1ed9c 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3835",
            name: "Th\u1ea1nh L\u1ed9c 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3836",
            name: "Th\u1ea1nh L\u1ed9c 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3837",
            name: "Th\u1ea1nh L\u1ed9c 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3838",
            name: "Th\u1ea1nh L\u1ed9c 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3839",
            name: "Th\u1ea1nh L\u1ed9c 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3840",
            name: "Th\u1ea1nh L\u1ed9c 28",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3841",
            name: "Th\u1ea1nh L\u1ed9c 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3842",
            name: "Th\u1ea1nh L\u1ed9c 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3843",
            name: "Th\u1ea1nh L\u1ed9c 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3844",
            name: "Th\u1ea1nh L\u1ed9c 31",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3845",
            name: "Th\u1ea1nh L\u1ed9c 33",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3846",
            name: "Th\u1ea1nh L\u1ed9c 35",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3847",
            name: "Th\u1ea1nh L\u1ed9c 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3848",
            name: "Th\u1ea1nh L\u1ed9c 38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3849",
            name: "Th\u1ea1nh L\u1ed9c 39",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3850",
            name: "Th\u1ea1nh L\u1ed9c 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3851",
            name: "Th\u1ea1nh L\u1ed9c 40",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3852",
            name: "Th\u1ea1nh L\u1ed9c 41",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3853",
            name: "Th\u1ea1nh L\u1ed9c 42",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3854",
            name: "Th\u1ea1nh L\u1ed9c 43",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3855",
            name: "Th\u1ea1nh L\u1ed9c 44",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3856",
            name: "Th\u1ea1nh L\u1ed9c 45",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3857",
            name: "Th\u1ea1nh L\u1ed9c 47",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3858",
            name: "Th\u1ea1nh L\u1ed9c 48",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3859",
            name: "Th\u1ea1nh L\u1ed9c 49",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3860",
            name: "Th\u1ea1nh L\u1ed9c 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3861",
            name: "Th\u1ea1nh L\u1ed9c 50",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3862",
            name: "Th\u1ea1nh L\u1ed9c 51",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3863",
            name: "Th\u1ea1nh L\u1ed9c 52",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3864",
            name: "Th\u1ea1nh L\u1ed9c 53",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3865",
            name: "Th\u1ea1nh L\u1ed9c 54",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3866",
            name: "Th\u1ea1nh L\u1ed9c 55",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3867",
            name: "Th\u1ea1nh L\u1ed9c 56",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3868",
            name: "Th\u1ea1nh L\u1ed9c 57",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3869",
            name: "Th\u1ea1nh L\u1ed9c 59",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3870",
            name: "Th\u1ea1nh L\u1ed9c 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3871",
            name: "Th\u1ea1nh L\u1ed9c 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3872",
            name: "Th\u1ea1nh L\u1ed9c 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3873",
            name: "Thanh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3874",
            name: "Th\u1ea1nh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3875",
            name: "Th\u1ea1nh Xu\u00e2n 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3876",
            name: "Th\u1ea1nh Xu\u00e2n 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3877",
            name: "Th\u1ea1nh Xu\u00e2n 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3878",
            name: "Th\u1ea1nh Xu\u00e2n 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3879",
            name: "Th\u1ea1nh Xu\u00e2n 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3880",
            name: "Th\u1ea1nh Xu\u00e2n 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3881",
            name: "Th\u1ea1nh Xu\u00e2n 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3882",
            name: "Th\u1ea1nh Xu\u00e2n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3883",
            name: "Th\u1ea1nh Xu\u00e2n 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3884",
            name: "Th\u1ea1nh Xu\u00e2n 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3885",
            name: "Th\u1ea1nh Xu\u00e2n 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3886",
            name: "Th\u1ea1nh Xu\u00e2n 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3887",
            name: "Th\u1ea1nh Xu\u00e2n 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3888",
            name: "Th\u1ea1nh Xu\u00e2n 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3889",
            name: "Th\u1ea1nh Xu\u00e2n 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3890",
            name: "Th\u1ea1nh Xu\u00e2n 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3891",
            name: "Th\u1ea1nh Xu\u00e2n 31",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3892",
            name: "Th\u1ea1nh Xu\u00e2n 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3893",
            name: "Th\u1ea1nh Xu\u00e2n 33",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3894",
            name: "Th\u1ea1nh Xu\u00e2n 34",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3895",
            name: "Th\u1ea1nh Xu\u00e2n 35",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3896",
            name: "Th\u1ea1nh Xu\u00e2n 36",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3897",
            name: "Th\u1ea1nh Xu\u00e2n 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3898",
            name: "Th\u1ea1nh Xu\u00e2n 38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3899",
            name: "Th\u1ea1nh Xu\u00e2n 39",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3900",
            name: "Th\u1ea1nh Xu\u00e2n 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3901",
            name: "Th\u1ea1nh Xu\u00e2n 40",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3902",
            name: "Th\u1ea1nh Xu\u00e2n 41",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3903",
            name: "Th\u1ea1nh Xu\u00e2n 42",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3904",
            name: "Th\u1ea1nh Xu\u00e2n 43",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3905",
            name: "Th\u1ea1nh Xu\u00e2n 44",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3906",
            name: "Th\u1ea1nh Xu\u00e2n 46",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3907",
            name: "Th\u1ea1nh Xu\u00e2n 47",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3908",
            name: "Th\u1ea1nh Xu\u00e2n 48",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3909",
            name: "Th\u1ea1nh Xu\u00e2n 51",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3910",
            name: "Th\u1ea1nh Xu\u00e2n 52",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3911",
            name: "Th\u1ea1nh Xu\u00e2n 56",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3912",
            name: "Th\u1ea1nh Xu\u00e2n 64",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3913",
            name: "Th\u00edch B\u1eedu \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3914", name: "Th\u1edbi An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3915",
            name: "Th\u1edbi An 06",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3916",
            name: "Th\u1edbi An 07",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3917",
            name: "Th\u1edbi An 09",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3918",
            name: "Th\u1edbi An 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3919",
            name: "Th\u1edbi An 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3920",
            name: "Th\u1edbi An 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3921",
            name: "Th\u1edbi An 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3922",
            name: "Th\u1edbi An 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3923",
            name: "Th\u1edbi An 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3924",
            name: "Th\u1edbi An 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3925",
            name: "Th\u1edbi An 17A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3926",
            name: "Th\u1edbi An 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3927",
            name: "Th\u1edbi An 18A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3928",
            name: "Th\u1edbi An 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3929",
            name: "Th\u1edbi An 19A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3930",
            name: "Th\u1edbi An 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3931",
            name: "Th\u1edbi An 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3932",
            name: "Th\u1edbi An 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3933",
            name: "Th\u1edbi An 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3934",
            name: "Th\u1edbi An 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3935",
            name: "Th\u1edbi An 28",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3936",
            name: "Th\u1edbi An 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3937",
            name: "Th\u1edbi An 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3938",
            name: "Th\u1edbi An 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3939",
            name: "Th\u1edbi An 35",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3940",
            name: "Th\u1edbi An 36",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3941",
            name: "Th\u1edbi An 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3942",
            name: "Th\u1edbi An 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3943",
            name: "Th\u1edbi An 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3944",
            name: "Th\u1edbi An 8A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3945",
            name: "Th\u1edbi An 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3946",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3947",
            name: "T\u1ec9nh L\u1ed9 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3948",
            name: "T\u1ec9nh L\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3949",
            name: "T\u1ec9nh l\u1ed9 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3950",
            name: "T\u1ec9nh l\u1ed9 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3951",
            name: "T\u1ec9nh L\u1ed9 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3952",
            name: "T\u1ec9nh L\u1ed9 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3953",
            name: "T\u1ec9nh L\u1ed9 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3954",
            name: "T\u1ec9nh L\u1ed9 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3955",
            name: "T\u1ec9nh L\u1ed9 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3956",
            name: "T\u1ec9nh L\u1ed9 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3957",
            name: "T\u1ec9nh L\u1ed9 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3958",
            name: "T\u1ec9nh L\u1ed9 40",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3959",
            name: "T\u1ec9nh L\u1ed9 41",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3960",
            name: "T\u1ec9nh l\u1ed9 43",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3961",
            name: "T\u1ec9nh l\u1ed9 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "3962", name: "TN02", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "3963",
            name: "T\u00f4 K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3964",
            name: "T\u00f4 Ng\u1ecdc V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3965",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3966",
            name: "Tr\u1ea7n Quang C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3967",
            name: "Tr\u1ea7n Quang \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3968",
            name: "Tr\u1ea7n Th\u1ecb B\u1ea3y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3969",
            name: "Tr\u1ea7n Th\u1ecb C\u00f3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3970",
            name: "Tr\u1ea7n Th\u1ecb C\u1edd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3971",
            name: "Tr\u1ea7n Th\u1ecb Do",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3972",
            name: "Tr\u1ea7n Th\u1ecb H\u00e8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3973",
            name: "Tr\u1ea7n Th\u1ecb H\u00f2e",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3974",
            name: "Tr\u1ea7n Th\u1ecb Ngh\u00e8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3975",
            name: "Tr\u00edch S\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3976",
            name: "Trung Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3977",
            name: "Trung M\u1ef9 T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3978",
            name: "Trung M\u1ef9 T\u00e2y 03",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3979",
            name: "Trung M\u1ef9 T\u00e2y 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3980",
            name: "Trung M\u1ef9 T\u00e2y 10A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3981",
            name: "Trung M\u1ef9 T\u00e2y 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3982",
            name: "Trung M\u1ef9 T\u00e2y 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3983",
            name: "Trung M\u1ef9 T\u00e2y 13A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3984",
            name: "Trung M\u1ef9 T\u00e2y 14A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3985",
            name: "Trung M\u1ef9 T\u00e2y 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3986",
            name: "Trung M\u1ef9 T\u00e2y 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3987",
            name: "Trung M\u1ef9 T\u00e2y 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3988",
            name: "Trung M\u1ef9 T\u00e2y 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3989",
            name: "Trung M\u1ef9 T\u00e2y 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3990",
            name: "Trung M\u1ef9 T\u00e2y 23A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3991",
            name: "Trung M\u1ef9 T\u00e2y 2A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3992",
            name: "Trung M\u1ef9 T\u00e2y 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3993",
            name: "Trung M\u1ef9 T\u00e2y 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3994",
            name: "Trung M\u1ef9 T\u00e2y 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3995",
            name: "Trung M\u1ef9 T\u00e2y 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3996",
            name: "Trung M\u1ef9 T\u00e2y 6A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3997",
            name: "Trung M\u1ef9 T\u00e2y 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3998",
            name: "Trung M\u1ef9 T\u00e2y 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "3999",
            name: "Trung M\u1ef9 T\u00e2y 9A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4000",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4001",
            name: "Tr\u01b0\u01a1ng Th\u1ecb Ng\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4002",
            name: "Tr\u01b0\u01a1ng V\u0103n \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4003",
            name: "V\u00f5 C\u00e2y D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4004",
            name: "V\u00f5 Qu\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4005",
            name: "V\u00f5 Th\u1ecb Li\u1ec5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4006",
            name: "V\u00f5 Th\u1ecb Ph\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4007",
            name: "V\u00f5 Th\u1ecb Th\u1eeba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4008",
            name: "V\u01b0\u1eddn L\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4009", name: "Xa La", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4010",
            name: "Xa L\u1ed9 \u0110a\u0323i Ha\u0300n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4011",
            name: "X\u00f4 Ng\u1ecdc Oanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4012",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4013",
            name: "Xu\u00e2n Th\u1edbi S\u01a1n 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4014",
            name: "Xuy\u00ean \u00c1",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "389",
            name: "12 View",
            lat: "10.824632644653",
            lng: "106.61088562012",
          },
          {
            id: "390",
            name: "8X Plus Tr\u01b0\u1eddng Chinh",
            lat: "10.831156730652",
            lng: "106.62126922607",
          },
          {
            id: "391",
            name: "An Ph\u00fa \u0110\u00f4ng Riverside",
            lat: "10.855405807495",
            lng: "106.71099853516",
          },
          {
            id: "392",
            name: "An S\u01b0\u01a1ng Besco",
            lat: "10.845525741577",
            lng: "106.61757659912",
          },
          {
            id: "393",
            name: "Cheery 2 Apartment",
            lat: "10.877117156982",
            lng: "106.64897918701",
          },
          {
            id: "394",
            name: "Chung c\u01b0 \u0110\u00f4ng H\u1ea3i",
            lat: "10.861311912537",
            lng: "106.61735534668",
          },
          {
            id: "395",
            name: "Chung c\u01b0 \u0110\u00f4ng H\u01b0ng",
            lat: "10.843232154846",
            lng: "106.6259689331",
          },
          {
            id: "397",
            name: "Depot Metro Tham L\u01b0\u01a1ng",
            lat: "10.82346534729",
            lng: "106.62450408935",
          },
          {
            id: "396",
            name: "\u0110\u1ea1t Gia Residence (CC V\u00f5 \u0110\u00ecnh)",
            lat: "10.877117156982",
            lng: "106.64897918701",
          },
          {
            id: "398",
            name: "\u0110i\u1ec1n Thu\u1eadn C\u1ea7u D\u1eeba",
            lat: "10.863104820252",
            lng: "106.6496963501",
          },
          {
            id: "399",
            name: "\u0110i\u1ec1n Thu\u1eadn Star Hills",
            lat: "10.86424446106",
            lng: "106.66596984863",
          },
          {
            id: "400",
            name: "First Home Th\u1ea1nh L\u1ed9c",
            lat: "10.867024421692",
            lng: "106.68698883057",
          },
          {
            id: "401",
            name: "First Land Hi\u1ec7p Th\u00e0nh",
            lat: "10.873598098755",
            lng: "106.64072418213",
          },
          {
            id: "402",
            name: "Gia \u0110\u1ecbnh Plaza",
            lat: "10.826372146606",
            lng: "106.62794494629",
          },
          {
            id: "403",
            name: "G\u00f2 Sao",
            lat: "10.884408950806",
            lng: "106.68391418457",
          },
          {
            id: "404",
            name: "H\u00e0 \u0110\u00f4 Th\u1edbi An",
            lat: "10.871948242188",
            lng: "106.65922546387",
          },
          {
            id: "405",
            name: "Happy Town Th\u1ea1nh L\u1ed9c",
            lat: "10.878442764282",
            lng: "106.67809295654",
          },
          {
            id: "406",
            name: "Happy Town Th\u1ea1nh Xu\u00e2n",
            lat: "10.880007743835",
            lng: "106.66546630859",
          },
          {
            id: "407",
            name: "Hi\u1ec7p Th\u00e0nh Building",
            lat: "10.882135391235",
            lng: "106.64862823486",
          },
          {
            id: "408",
            name: "Hi\u1ec7p Th\u00e0nh City",
            lat: "10.875553131103",
            lng: "106.62873840332",
          },
          {
            id: "409",
            name: "H\u01b0ng Ng\u00e2n Garden",
            lat: "10.858521461487",
            lng: "106.63079833984",
          },
          {
            id: "410",
            name: "KDC An S\u01b0\u01a1ng",
            lat: "10.842578887939",
            lng: "106.62628173828",
          },
          {
            id: "411",
            name: "Khang Th\u1ecbnh Town",
            lat: "10.898487091064",
            lng: "106.68820953369",
          },
          {
            id: "412",
            name: "Khu d\u00e2n c\u01b0 \u0110\u1ed3ng Ph\u01b0\u1ee3ng",
            lat: "10.839723587036",
            lng: "106.6298904419",
          },
          {
            id: "413",
            name: "Khu d\u00e2n c\u01b0 Th\u1ea1nh Xu\u00e2n",
            lat: "10.86573600769",
            lng: "106.67057800293",
          },
          {
            id: "414",
            name: "Kim T\u00e2m H\u1ea3i Apartment",
            lat: "10.830249786377",
            lng: "106.62292480469",
          },
          {
            id: "415",
            name: "Lori Plaza",
            lat: "10.856543540955",
            lng: "106.6143951416",
          },
          {
            id: "416",
            name: "Nh\u00e0 \u1edf qu\u00e2n khu 7",
            lat: "10.848454475403",
            lng: "106.61420440674",
          },
          {
            id: "417",
            name: "Nh\u00e0 \u1edf V\u1ea1n Xu\u00e2n B\u1eafc S\u00e0i G\u00f2n",
            lat: "10.880068778992",
            lng: "106.67883300781",
          },
          {
            id: "418",
            name: "Ph\u00fa An Center",
            lat: "10.865139007568",
            lng: "106.65650177002",
          },
          {
            id: "419",
            name: "Ph\u00fa Long Riverside",
            lat: "10.893929481506",
            lng: "106.67505645752",
          },
          {
            id: "420",
            name: "Prosper Plaza",
            lat: "10.828125",
            lng: "106.62316894531",
          },
          {
            id: "421",
            name: "Senturia V\u01b0\u1eddn L\u00e0i",
            lat: "10.85036277771",
            lng: "106.69371032715",
          },
          {
            id: "422",
            name: "Tecco Green Nest",
            lat: "10.829746246338",
            lng: "106.60997009277",
          },
          {
            id: "423",
            name: "Th\u00e1i An Apartment",
            lat: "10.842238426208",
            lng: "106.6307144165",
          },
          {
            id: "424",
            name: "Thi\u00ean Qu\u00fd",
            lat: "10.873269081116",
            lng: "106.65859985352",
          },
          {
            id: "425",
            name: "Th\u1ecd Nam Sang",
            lat: "10.833777427673",
            lng: "106.62088775635",
          },
          {
            id: "426",
            name: "Th\u1edbi An City",
            lat: "10.858932495117",
            lng: "106.66040802002",
          },
          {
            id: "427",
            name: "Tiny House",
            lat: "10.869569778442",
            lng: "106.67391204834",
          },
          {
            id: "428",
            name: "T\u00f4 K\u00fd Tower",
            lat: "10.856543540955",
            lng: "106.6143951416",
          },
          {
            id: "429",
            name: "Topaz Home",
            lat: "10.828960418701",
            lng: "106.62134552002",
          },
          {
            id: "430",
            name: "V\u01b0\u1eddn L\u00e0i Village",
            lat: "10.84930229187",
            lng: "106.69303131103",
          },
          {
            id: "431",
            name: "VXHome 25",
            lat: "10.879822731018",
            lng: "106.67363739014",
          },
          {
            id: "432",
            name: "Zen Tower",
            lat: "10.86176109314",
            lng: "106.64987945557",
          },
        ],
      },
      {
        id: "14",
        name: "Qu\u1eadn 2",
        wards: [
          {
            id: "181",
            name: " Th\u1ea1nh M\u1ef9 L\u1ee3i",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "182", name: "An Kh\u00e1nh", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "183",
            name: "An L\u1ee3i \u0110\u00f4ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "184", name: "An Ph\u00fa", prefix: "Ph\u01b0\u1eddng" },
          { id: "185", name: "B\u00ecnh An", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "186",
            name: "B\u00ecnh Kh\u00e1nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "187",
            name: "B\u00ecnh Tr\u01b0ng \u0110\u00f4ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "188",
            name: "B\u00ecnh Tr\u01b0ng T\u00e2y",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "189", name: "C\u00e1t L\u00e1i", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "190",
            name: "Th\u1ea3o \u0110i\u1ec1n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "191",
            name: "Th\u1ee7 Thi\u00eam",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "4015", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4016", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4017", name: "103", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4018", name: "104", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4019", name: "104A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4020", name: "10A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4021", name: "10E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4022", name: "10F", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4023", name: "10G", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4024", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4025", name: "112", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4026", name: "11A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4027", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4028", name: "12A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4029", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4030", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4031", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4032", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4033", name: "162", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4034", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4035", name: "17B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4036", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4037", name: "188", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4038", name: "18A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4039", name: "18B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4040", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4041", name: "19A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4042", name: "19B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4043", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4044", name: "2.5A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4045", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4046", name: "204", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4047", name: "204B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4048", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4049", name: "215", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4050", name: "21A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4051", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4052", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4053", name: "24", prefix: "Ph\u1ed1" },
          { id: "4054", name: "24A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4055", name: "24B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4056", name: "25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4057", name: "25B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4058", name: "26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4059", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4060", name: "27A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4061", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4062", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4063", name: "2D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4064", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4065", name: "30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4066", name: "30B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4067", name: "31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4068", name: "31A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4069", name: "31B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4070", name: "31C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4071", name: "31D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4072", name: "31E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4073", name: "31F", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4074", name: "32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4075", name: "33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4076", name: "34", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4077", name: "34A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4078", name: "34B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4079", name: "34C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4080", name: "35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4081", name: "35A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4082", name: "36", prefix: "Ph\u1ed1" },
          { id: "4083", name: "37", prefix: "Ph\u1ed1" },
          { id: "4084", name: "37A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4085", name: "38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4086", name: "39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4087", name: "3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4088", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4089", name: "40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4090", name: "41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4091", name: "41A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4092", name: "41B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4093", name: "42", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4094", name: "43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4095", name: "43B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4096", name: "44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4097", name: "45", prefix: "Ph\u1ed1" },
          { id: "4098", name: "46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4099", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4100", name: "48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4101", name: "49", prefix: "Ph\u1ed1" },
          { id: "4102", name: "49B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4103", name: "4A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4104", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4105", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4106", name: "51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4107", name: "52", prefix: "Ph\u1ed1" },
          { id: "4108", name: "53", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4109", name: "54", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4110", name: "55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4111", name: "56", prefix: "Ph\u1ed1" },
          { id: "4112", name: "57", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4113", name: "58", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4114", name: "59", prefix: "Ph\u1ed1" },
          { id: "4115", name: "5B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4116", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4117", name: "60", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4118", name: "61", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4119", name: "62", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4120", name: "63", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4121", name: "64", prefix: "Ph\u1ed1" },
          { id: "4122", name: "65", prefix: "Ph\u1ed1" },
          { id: "4123", name: "66", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4124", name: "68", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4125", name: "69", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4126", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4127", name: "711", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4128", name: "76", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4129", name: "78", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4130", name: "7A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4131", name: "7B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4132", name: "7C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4133", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4134", name: "80", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4135", name: "83", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4136", name: "86", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4137", name: "8A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4138", name: "8B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4139", name: "8G", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4140", name: "8K", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4141", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4142", name: "94", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4143", name: "95", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4144", name: "9A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4145", name: "9F", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4146", name: "A1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4147", name: "A2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4148", name: "A35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4149", name: "A4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4150", name: "A5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4151", name: "A6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4152",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4153", name: "An Ph\u00fa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4154",
            name: "An Ph\u00fa \u0110\u00f4ng 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4155", name: "An Trang", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4156", name: "B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4157", name: "B11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4158", name: "B37", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4159", name: "B7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4160",
            name: "B\u00e1t N\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4161", name: "B\u00ecnh An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4162",
            name: "B\u00ecnh Tr\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4163",
            name: "B\u00f9i T\u00e1 H\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4164", name: "C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4165", name: "C10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4166", name: "C2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4167", name: "C3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4168", name: "C4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4169",
            name: "Cao \u0110\u1ee9c L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4170",
            name: "Chu\u00f4ng V\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4171", name: "D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4172", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4173", name: "D10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4174", name: "D11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4175", name: "D15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4176", name: "D16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4177", name: "D18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4178", name: "D19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4179", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4180", name: "D20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4181", name: "D26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4182", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4183", name: "D4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4184", name: "D6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4185", name: "D64", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4186", name: "D7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4187", name: "D8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4194", name: "DCT7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4202",
            name: "D\u01b0 H\u00e0ng K\u00eanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4205",
            name: "D\u01b0\u01a1ng V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4206",
            name: "D\u01b0\u01a1ng V\u0103n D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4188",
            name: "\u0110\u1ea1i L\u1ed9 \u0110\u00f4ng T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4189",
            name: "\u0110\u00e0m V\u0103n L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4190",
            name: "\u0110\u1eb7ng H\u1eefu Ph\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4191",
            name: "\u0110\u1eb7ng Nh\u01b0 Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4192",
            name: "\u0110\u1eb7ng Thi Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4193",
            name: "\u0110\u1eb7ng Ti\u1ebfn \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4195",
            name: "\u0110\u1ed7 Ph\u00e1p Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4196", name: "\u0110\u1ed7 Quang", prefix: "Ph\u1ed1" },
          {
            id: "4197",
            name: "\u0110\u1ed7 Xu\u00e2n H\u1ee3p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4198",
            name: "\u0110o\u00e0n H\u1eefu Tr\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4199",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4200",
            name: "\u0110\u1ed3ng Qu\u1ed1c B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4201",
            name: "\u0110\u1ed3ng V\u0103n C\u1ed1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4203",
            name: "\u0110\u01b0\u1eddng A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4204",
            name: "\u0110\u01b0\u1eddng C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4207", name: "E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4208", name: "F", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4209", name: "F1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4210", name: "G1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4211", name: "G2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4212", name: "G4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4213",
            name: "Gi\u00e1ng H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4214",
            name: "Giang V\u0103n Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4215",
            name: "Gi\u00e1p V\u0103n Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4216",
            name: "G\u00f2 N\u1ed5i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4217", name: "H", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4218", name: "H\u00e0 Quang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4219",
            name: "H\u00e0n Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4220",
            name: "H\u00e2n V\u00e2n Nhi\u00ea\u0301p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4221",
            name: "H\u1eadu L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4222",
            name: "Hi\u1ec7p Th\u00e0nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4223",
            name: "H\u1ed3 Trung T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4224",
            name: "H\u01b0\u01a1ng l\u1ed9 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4225", name: "JD12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4226", name: "K", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4227", name: "K1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4228", name: "K3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4229", name: "KP3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4230", name: "KP5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4231", name: "L25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4232", name: "L6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4233",
            name: "L\u00e2m Quang Ky",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4234",
            name: "L\u00ea \u0110\u00ecnh Qu\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4235",
            name: "L\u00ea \u0110\u1ee9c Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4236",
            name: "L\u00ea Hi\u1ebfn Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4237",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4238",
            name: "L\u00ea H\u1eefu Ki\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4239",
            name: "L\u00ea Ph\u1ee5ng Hi\u1ec3u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "4240",
            name: "L\u00ea Th\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4241",
            name: "L\u00ea V\u0103n Hi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4242",
            name: "L\u00ea V\u0103n Mi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4243",
            name: "L\u00ea V\u0103n Th\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4244",
            name: "Li\u00ean Ph\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4245",
            name: "L\u1ed9c H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4246",
            name: "L\u01b0\u01a1ng \u0110\u1ecbnh C\u1ee7a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "4247",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4248", name: "M", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4249", name: "M34", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4250",
            name: "Mai Ch\u00ed Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4251",
            name: "M\u01b0\u01a1ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4252",
            name: "Mu\u1ed3ng T\u00edm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4253", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4254", name: "N11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4255", name: "N13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4256", name: "N17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4257", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4258", name: "NE", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4259",
            name: "Ng\u00f4 Quang Huy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4260",
            name: "Nguy\u1ec5n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4261",
            name: "Nguy\u1ec5n \u00c1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4262",
            name: "Nguy\u1ec5n B\u00e1 Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4263",
            name: "Nguy\u1ec5n B\u00e1 L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4264",
            name: "Nguy\u1ec5n C\u01a1 Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4265", name: "Nguy\u1ec5n C\u1eeb", prefix: "Ph\u1ed1" },
          {
            id: "4266",
            name: "Nguy\u1ec5n C\u01b0 D\u0129",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4271",
            name: "Nguy\u1ec5n Duy Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4272",
            name: "Nguy\u1ec5n Duy Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4267",
            name: "Nguy\u1ec5n \u0110\u0103ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4268",
            name: "Nguy\u1ec5n \u0110\u0103ng Giai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4269",
            name: "Nguy\u1ec5n \u0110\u1ecba L\u00f4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4270",
            name: "Nguy\u1ec5n \u0110\u00f4n Ti\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4273",
            name: "Nguy\u1ec5n Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4274",
            name: "Nguy\u1ec5n H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4275",
            name: "Nguy\u1ec5n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4276",
            name: "Nguy\u1ec5n Huy Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4277",
            name: "Nguy\u1ec5n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4278",
            name: "Nguy\u1ec5n Khoa \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4279",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng D\u0129",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4280",
            name: "Nguy\u1ec5n M\u1ed9ng Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4281",
            name: "Nguy\u1ec5n Quang B\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4282",
            name: "Nguy\u1ec5n Qu\u00fd C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4283",
            name: "Nguy\u1ec5n Qu\u00fd \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4284",
            name: "Nguy\u1ec5n Thanh S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4285",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4286",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4287",
            name: "Nguy\u1ec5n Tr\u1ecdng Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4288",
            name: "Nguy\u1ec5n Tr\u1ecdng Qu\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4289",
            name: "Nguy\u1ec5n Trung Nguy\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4290",
            name: "Nguy\u1ec5n T\u01b0 Nghi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4291",
            name: "Nguy\u1ec5n Tuy\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4292",
            name: "Nguy\u1ec5n \u01af D\u0129",
            prefix: "Ph\u1ed1",
          },
          {
            id: "4293",
            name: "Nguy\u1ec5n V\u0103n Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4294",
            name: "Nguy\u1ec5n V\u0103n H\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4295",
            name: "Nguy\u1ec5n V\u0103n K\u1ec9nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4296",
            name: "Ph\u1ea1m C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4297",
            name: "Ph\u1ea1m \u0110\u00f4n L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4298",
            name: "Ph\u1ea1m Hy L\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4299",
            name: "Ph\u1ea1m Th\u1eadn Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4300",
            name: "Phan B\u00e1 V\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4301",
            name: "Phan V\u0103n \u0110\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4302",
            name: "Ph\u01b0\u1ee3ng V\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4303",
            name: "Qu\u00e1ch Giai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4304",
            name: "Qu\u1ed1c H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4305",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4306", name: "R", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4307", name: "R2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4308",
            name: "Sen H\u1ed3ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4309", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4310", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4311", name: "S\u1ed1 104", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4312", name: "S\u1ed1 104A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4313", name: "S\u1ed1 10A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4314", name: "S\u1ed1 10G", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4315", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4316", name: "S\u1ed1 11A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4317", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4318", name: "S\u1ed1 128C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4319", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4320", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4321", name: "S\u1ed1 14A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4322", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4323", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4324", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4325", name: "S\u1ed1 18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4326", name: "S\u1ed1 187", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4327", name: "S\u1ed1 18A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4328", name: "S\u1ed1 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4329", name: "S\u1ed1 19B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4330", name: "S\u1ed1 19C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4331", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4332", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4333", name: "S\u1ed1 204", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4334", name: "S\u1ed1 21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4335", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4336", name: "S\u1ed1 22A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4337", name: "S\u1ed1 23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4338", name: "S\u1ed1 24", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4339", name: "S\u1ed1 24A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4340", name: "S\u1ed1 25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4341", name: "S\u1ed1 26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4342", name: "S\u1ed1 27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4343", name: "S\u1ed1 28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4344", name: "S\u1ed1 29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4345", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4346", name: "S\u1ed1 30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4347", name: "S\u1ed1 31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4348", name: "S\u1ed1 31A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4349", name: "S\u1ed1 31B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4350", name: "S\u1ed1 31C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4351", name: "S\u1ed1 31D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4352", name: "S\u1ed1 31E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4353", name: "S\u1ed1 31F", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4354", name: "S\u1ed1 32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4355", name: "S\u1ed1 33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4356", name: "S\u1ed1 33C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4357", name: "S\u1ed1 34", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4358", name: "S\u1ed1 34A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4359", name: "S\u1ed1 35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4360", name: "S\u1ed1 36", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4361", name: "S\u1ed1 37", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4362", name: "S\u1ed1 38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4363", name: "S\u1ed1 38B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4364", name: "S\u1ed1 39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4365", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4366", name: "S\u1ed1 40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4367", name: "S\u1ed1 41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4368", name: "S\u1ed1 42", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4369", name: "S\u1ed1 43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4370", name: "S\u1ed1 44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4371", name: "S\u1ed1 45", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4372", name: "S\u1ed1 46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4373", name: "S\u1ed1 47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4374", name: "S\u1ed1 48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4375", name: "S\u1ed1 49", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4376", name: "S\u1ed1 49B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4377", name: "S\u1ed1 4A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4378", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4379", name: "S\u1ed1 50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4380", name: "S\u1ed1 51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4381", name: "S\u1ed1 52", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4382", name: "S\u1ed1 52B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4383", name: "S\u1ed1 54", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4384", name: "S\u1ed1 55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4385", name: "S\u1ed1 56", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4386", name: "S\u1ed1 57", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4387", name: "S\u1ed1 58", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4388", name: "S\u1ed1 59", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4389", name: "S\u1ed1 5B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4390", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4391", name: "S\u1ed1 60", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4392", name: "S\u1ed1 61", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4393", name: "S\u1ed1 62", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4394", name: "S\u1ed1 63", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4395", name: "S\u1ed1 64", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4396", name: "S\u1ed1 65", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4397", name: "S\u1ed1 66", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4398", name: "S\u1ed1 69", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4399", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4400", name: "S\u1ed1 7A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4401", name: "S\u1ed1 7B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4402", name: "S\u1ed1 7C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4403", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4404", name: "S\u1ed1 8A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4405", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4406", name: "S\u1ed1 9A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4407",
            name: "Song H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4408",
            name: "S\u1eed Hy Nhan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4409", name: "T6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4410",
            name: "T\u1ea1 Hi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4411",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4412",
            name: "T\u00e2n L\u1eadp 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4413",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4414",
            name: "Th\u00e1i Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4415",
            name: "Th\u00e2n V\u0103n Nhi\u1ebfp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4416",
            name: "Th\u1ea1nh L\u1ed9c 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4417",
            name: "Th\u1ea1nh M\u1ef9 B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4418",
            name: "Th\u1ea1nh M\u1ef9 L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4419",
            name: "Th\u1ea1nh M\u1ef9 Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4420",
            name: "Th\u1ea1nh Xu\u00e2n 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4421",
            name: "Th\u1ea1nh Xu\u00e2n 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4422",
            name: "Th\u1ea3o \u0110i\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4423",
            name: "Th\u00edch M\u1eadt Th\u1ec3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4424",
            name: "T\u1ec9nh L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4425",
            name: "T\u1ec9nh l\u1ed9 25B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4426",
            name: "T\u1ed1ng H\u1eefu \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4427",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4428",
            name: "Tr\u1ea7n Kh\u1eafc Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4429",
            name: "Tr\u1ea7n L\u01b0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4430",
            name: "Tr\u1ea7n L\u1ef1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4431",
            name: "Tr\u1ea7n N\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4432",
            name: "Tr\u1ea7n Ng\u1ecdc Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4433",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4434",
            name: "Tr\u1ea7n Quang \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4435",
            name: "Tr\u00edch S\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4436",
            name: "Tr\u1ecbnh Kh\u1eafc L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4437",
            name: "Tr\u00fac \u0110\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4438",
            name: "Tr\u01b0\u01a1ng Gia M\u00f4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4439",
            name: "Tr\u01b0\u01a1ng V\u0103n Bang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4440",
            name: "Tr\u01b0\u01a1ng V\u0103n \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4441", name: "V\u1ea1n Ki\u1ebfp", prefix: "Ph\u1ed1" },
          {
            id: "4442",
            name: "V\u00e0nh \u0110ai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4443",
            name: "V\u00e0nh \u0110ai 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4444",
            name: "V\u00e0nh \u0110ai 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4445",
            name: "V\u00e0nh \u0110ai \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4446",
            name: "V\u00e0nh \u0110ai T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4447",
            name: "V\u00e0nh \u0110ai Trong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4448",
            name: "V\u00f5 Ch\u00ed C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4449",
            name: "V\u00f5 Tr\u01b0\u1eddng To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4450",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4451",
            name: "V\u0169 Ph\u01b0\u01a1ng \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4452", name: "V\u0169 T\u00f4ng Phan", prefix: "Ph\u1ed1" },
          {
            id: "4453",
            name: "Xa L\u1ed9 H\u00e0 N\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4454",
            name: "Xu\u00e2n Th\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4455",
            name: "Xu\u00e2n Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "433",
            name: "An C\u01b0",
            lat: "10.79797744751",
            lng: "106.73903656006",
          },
          {
            id: "434",
            name: "An H\u00f2a",
            lat: "10.793073654175",
            lng: "106.73958587647",
          },
          {
            id: "435",
            name: "An Khang",
            lat: "10.797328948975",
            lng: "106.73980712891",
          },
          {
            id: "436",
            name: "An L\u1ed9c  - An Ph\u00fac",
            lat: "10.796710968018",
            lng: "106.74494934082",
          },
          {
            id: "437",
            name: "An Ph\u00fa An Kh\u00e1nh",
            lat: "10.797435760498",
            lng: "106.73783111572",
          },
          {
            id: "438",
            name: "An Th\u1ecbnh",
            lat: "10.797847747803",
            lng: "106.73830413818",
          },
          {
            id: "439",
            name: "Bella Vida",
            lat: "10.770516395569",
            lng: "106.77297973633",
          },
          {
            id: "440",
            name: "Bi\u1ec7t th\u1ef1 H\u00e0 \u0110\u00f4",
            lat: "10.758361816406",
            lng: "106.76474761963",
          },
          {
            id: "441",
            name: "Bi\u0300nh An Pearl",
            lat: "10.789085388184",
            lng: "106.73013305664",
          },
          {
            id: "442",
            name: "B\u00ecnh An Riverside",
            lat: "10.795217514038",
            lng: "106.73085784912",
          },
          {
            id: "443",
            name: "B\u00ecnh Minh",
            lat: "10.789348602295",
            lng: "106.73516845703",
          },
          {
            id: "444",
            name: "C3 PARCSpring",
            lat: "10.788743972778",
            lng: "106.77073669434",
          },
          {
            id: "445",
            name: "Cantavil An Ph\u00fa",
            lat: "10.800490379333",
            lng: "106.74743652344",
          },
          {
            id: "446",
            name: "Cao \u1ed1c Th\u1ecbnh V\u01b0\u1ee3ng",
            lat: "10.788802146912",
            lng: "106.77110290527",
          },
          {
            id: "447",
            name: "C\u00e1t L\u00e1i Invesco",
            lat: "10.772298812866",
            lng: "106.78784179688",
          },
          {
            id: "448",
            name: "Centana Th\u1ee7 Thi\u00eam",
            lat: "10.791224479675",
            lng: "106.75289154053",
          },
          {
            id: "449",
            name: "Cheery Apartment",
            lat: "10.774020195007",
            lng: "106.76710510254",
          },
          {
            id: "450",
            name: "Chung c\u01b0 B\u1ed9 C\u00f4ng An",
            lat: "10.794454574585",
            lng: "106.73692321777",
          },
          {
            id: "451",
            name: "Citi Bella",
            lat: "10.766710281372",
            lng: "106.78633117676",
          },
          {
            id: "452",
            name: "Citi Bella 2",
            lat: "10.770320892334",
            lng: "106.77800750732",
          },
          {
            id: "453",
            name: "Citi Esto",
            lat: "10.773036956787",
            lng: "106.77127075195",
          },
          {
            id: "454",
            name: "Citi Home",
            lat: "10.767316818237",
            lng: "106.78410339356",
          },
          {
            id: "455",
            name: "Citi Soho",
            lat: "10.76784992218",
            lng: "106.78438568115",
          },
          {
            id: "456",
            name: "City Horse",
            lat: "10.797859191894",
            lng: "106.74847412109",
          },
          {
            id: "457",
            name: "Cityland B\u00ecnh Tr\u01b0ng \u0110\u00f4ng",
            lat: "10.776086807251",
            lng: "106.77824401856",
          },
          {
            id: "460",
            name: "D'Edge Th\u1ea3o \u0110i\u1ec1n",
            lat: "10.810338973999",
            lng: "106.73107147217",
          },
          {
            id: "459",
            name: "De Capella",
            lat: "10.787258148193",
            lng: "106.73500823975",
          },
          {
            id: "461",
            name: "Detesco",
            lat: "10.785972595215",
            lng: "106.73140716553",
          },
          {
            id: "462",
            name: "Diamond Island",
            lat: "10.778997421265",
            lng: "106.74719238281",
          },
          {
            id: "464",
            name: "D\u1ef1 \u00e1n Huy Ho\u00e0ng",
            lat: "10.778581619263",
            lng: "106.75447845459",
          },
          {
            id: "458",
            name: "\u0110\u1ea1i Quang Minh",
            lat: "10.774360656738",
            lng: "106.72677612305",
          },
          {
            id: "463",
            name: "\u0110\u00f4ng Th\u1ee7 Thi\u00eam",
            lat: "10.792063713074",
            lng: "106.77829742432",
          },
          {
            id: "465",
            name: "Empire City Th\u1ee7 Thi\u00eam",
            lat: "10.769825935364",
            lng: "106.71620941162",
          },
          {
            id: "466",
            name: "Era B\u00ecnh Kh\u00e1nh",
            lat: "10.787758827209",
            lng: "106.74254608154",
          },
          {
            id: "467",
            name: "Estella Heights",
            lat: "10.801277160644",
            lng: "106.74858856201",
          },
          {
            id: "468",
            name: "Feliz En Vista",
            lat: "10.778066635132",
            lng: "106.75643157959",
          },
          {
            id: "469",
            name: "Fideco Riverview",
            lat: "10.80290222168",
            lng: "106.73804473877",
          },
          {
            id: "470",
            name: "Galaxy B\u00ecnh An",
            lat: "10.770826339722",
            lng: "106.78539276123",
          },
          {
            id: "471",
            name: "Gateway Th\u1ea3o \u0110i\u1ec1n",
            lat: "10.803821563721",
            lng: "106.74672698975",
          },
          {
            id: "472",
            name: "Gem Riverside",
            lat: "10.79229927063",
            lng: "106.76621246338",
          },
          {
            id: "473",
            name: "Ho\u00e0ng Anh River View",
            lat: "10.804823875427",
            lng: "106.72715759277",
          },
          {
            id: "474",
            name: "Holm Villas Th\u1ea3o \u0110i\u1ec1n",
            lat: "10.810733795166",
            lng: "106.7244720459",
          },
          {
            id: "475",
            name: "Homyland",
            lat: "10.788062095642",
            lng: "106.76661682129",
          },
          {
            id: "476",
            name: "Homyland 2",
            lat: "21.0306224823",
            lng: "105.84886932373",
          },
          {
            id: "477",
            name: "Homyland 3",
            lat: "10.78768825531",
            lng: "106.76321411133",
          },
          {
            id: "478",
            name: "HQC B\u00ecnh Tr\u01b0ng \u0110\u00f4ng",
            lat: "10.791457176208",
            lng: "106.77599334717",
          },
          {
            id: "479",
            name: "Imperia An Ph\u00fa",
            lat: "10.800363540649",
            lng: "106.7502822876",
          },
          {
            id: "480",
            name: "KDC Ninh Giang",
            lat: "10.770826339722",
            lng: "106.78539276123",
          },
          {
            id: "481",
            name: "KDC Th\u1ebf k\u1ec9 21",
            lat: "10.781676292419",
            lng: "106.7520904541",
          },
          {
            id: "482",
            name: "KDC V\u0103n Minh",
            lat: "10.783132553101",
            lng: "106.74753570557",
          },
          {
            id: "483",
            name: "Khu \u0111\u00f4 th\u1ecb C\u00e1t L\u00e1i",
            lat: "10.772915840149",
            lng: "106.76694488525",
          },
          {
            id: "484",
            name: "La Astoria",
            lat: "10.78791809082",
            lng: "106.76310729981",
          },
          {
            id: "485",
            name: "Lakeview City",
            lat: "10.795413970947",
            lng: "106.77575683594",
          },
          {
            id: "486",
            name: "Lan Anh Village",
            lat: "10.785685539246",
            lng: "106.72257232666",
          },
          {
            id: "487",
            name: "Lexington Residence",
            lat: "10.798354148865",
            lng: "106.75190734863",
          },
          {
            id: "488",
            name: "Marina Bay Th\u1ee7 Thi\u00eam",
            lat: "10.77458190918",
            lng: "106.72696685791",
          },
          {
            id: "489",
            name: "Masteri An Ph\u00fa",
            lat: "10.801708221436",
            lng: "106.74262237549",
          },
          {
            id: "490",
            name: "Masteri Th\u1ea3o \u0110i\u1ec1n",
            lat: "10.802689552307",
            lng: "106.74181365967",
          },
          {
            id: "491",
            name: "May Apartments",
            lat: "10.810273170471",
            lng: "106.73084259033",
          },
          {
            id: "492",
            name: "Midpoint Villas Th\u1ea3o \u0110i\u1ec1n",
            lat: "10.808676719666",
            lng: "106.73278808594",
          },
          {
            id: "493",
            name: "Nam R\u1ea1ch Chi\u1ebfc",
            lat: "10.794406890869",
            lng: "106.76432800293",
          },
          {
            id: "494",
            name: "New City Th\u1ee7 Thi\u00eam",
            lat: "10.782060623169",
            lng: "106.74008178711",
          },
          {
            id: "495",
            name: "One Verandah Mapletree",
            lat: "10.776951789856",
            lng: "106.75189971924",
          },
          {
            id: "496",
            name: "Palm City",
            lat: "10.795161247253",
            lng: "106.76573944092",
          },
          {
            id: "497",
            name: "Palm Heights",
            lat: "10.793773651123",
            lng: "106.76152801514",
          },
          {
            id: "498",
            name: "PARCSpring",
            lat: "10.788648605347",
            lng: "106.77069091797",
          },
          {
            id: "499",
            name: "Parkland Apartments",
            lat: "10.804164886475",
            lng: "106.74938201904",
          },
          {
            id: "500",
            name: "Petroland Qu\u1eadn 2",
            lat: "10.791753768921",
            lng: "106.77695465088",
          },
          {
            id: "501",
            name: "PetroVietnam Landmark",
            lat: "10.796918869019",
            lng: "106.75061798096",
          },
          {
            id: "502",
            name: "Ph\u1ed1 \u0110\u00f4ng Village",
            lat: "10.769062042236",
            lng: "106.7808303833",
          },
          {
            id: "503",
            name: "Preche",
            lat: "10.803859710693",
            lng: "106.74678039551",
          },
          {
            id: "504",
            name: "Q2 Th\u1ea3o \u0110i\u1ec1n",
            lat: "10.800563812256",
            lng: "106.73700714111",
          },
          {
            id: "505",
            name: "Regency Park",
            lat: "10.793998718262",
            lng: "106.75092315674",
          },
          {
            id: "506",
            name: "River Garden",
            lat: "10.815117835998",
            lng: "106.72711181641",
          },
          {
            id: "507",
            name: "Safari",
            lat: "10.77764415741",
            lng: "106.75764465332",
          },
          {
            id: "508",
            name: "Saigon Mia",
            lat: "10.794303894043",
            lng: "106.73815917969",
          },
          {
            id: "509",
            name: "Saigon Mystery Villas",
            lat: "10.778272628784",
            lng: "106.74682617188",
          },
          {
            id: "510",
            name: "Sarah Th\u1ea3o \u0110i\u1ec1n",
            lat: "10.816192626953",
            lng: "106.73178863525",
          },
          {
            id: "511",
            name: "Sarimi Sala",
            lat: "10.770347595215",
            lng: "106.72473907471",
          },
          {
            id: "512",
            name: "Screc II Tower",
            lat: "10.791568756103",
            lng: "106.7405090332",
          },
          {
            id: "513",
            name: "Spring Life",
            lat: "10.78980922699",
            lng: "106.77127075195",
          },
          {
            id: "514",
            name: "Sunny Th\u1ee7 Thi\u00eam",
            lat: "10.774942398071",
            lng: "106.72224426269",
          },
          {
            id: "515",
            name: "T\u00e1i \u0111\u1ecbnh c\u01b0 B\u00ecnh Kh\u00e1nh",
            lat: "10.783045768738",
            lng: "106.73673248291",
          },
          {
            id: "516",
            name: "Th\u1ea1nh M\u1ef9 L\u1ee3i",
            lat: "10.769866943359",
            lng: "106.76713562012",
          },
          {
            id: "517",
            name: "Th\u1ea1nh M\u1ef9 L\u1ee3i - Ph\u00fa Nhu\u1eadn 1,2",
            lat: "10.768471717834",
            lng: "106.7608795166",
          },
          {
            id: "518",
            name: "Th\u1ea3o \u0110i\u1ec1n Pearl",
            lat: "10.801422119141",
            lng: "106.73316955566",
          },
          {
            id: "519",
            name: "The Ascent",
            lat: "10.80522441864",
            lng: "106.73136901856",
          },
          {
            id: "520",
            name: "The CBD Premium Home",
            lat: "10.774380683899",
            lng: "106.76149749756",
          },
          {
            id: "521",
            name: "The Estella",
            lat: "10.800268173218",
            lng: "106.74884796143",
          },
          {
            id: "522",
            name: "The Krista",
            lat: "10.79044342041",
            lng: "106.77030944824",
          },
          {
            id: "523",
            name: "The Nassim",
            lat: "10.804647445679",
            lng: "106.74440765381",
          },
          {
            id: "524",
            name: "The Sun Avenue",
            lat: "10.78844833374",
            lng: "106.75196838379",
          },
          {
            id: "525",
            name: "The Vista An Ph\u00fa",
            lat: "10.805335998535",
            lng: "106.75173187256",
          },
          {
            id: "526",
            name: "Th\u1ee7 Thi\u00eam Dragon",
            lat: "10.763142585754",
            lng: "106.75983428955",
          },
          {
            id: "527",
            name: "Th\u1ee7 Thi\u00eam Lakeview",
            lat: "10.772672653198",
            lng: "106.72301483154",
          },
          {
            id: "528",
            name: "Th\u1ee7 Thi\u00eam Sky",
            lat: "10.814663887024",
            lng: "106.73025512695",
          },
          {
            id: "529",
            name: "Th\u1ee7 Thi\u00eam Star",
            lat: "10.792109489441",
            lng: "106.77737426758",
          },
          {
            id: "530",
            name: "Th\u1ee7 Thi\u00eam Villa",
            lat: "10.768555641174",
            lng: "106.75621032715",
          },
          {
            id: "531",
            name: "Th\u1ee7 Thi\u00eam Xanh",
            lat: "10.789509773254",
            lng: "106.77629852295",
          },
          {
            id: "532",
            name: "Tropic Garden",
            lat: "10.813972473144",
            lng: "106.72784423828",
          },
          {
            id: "533",
            name: "Ventura",
            lat: "10.773036956787",
            lng: "106.77127075195",
          },
          {
            id: "534",
            name: "Veronica Apartments",
            lat: "10.813142776489",
            lng: "106.73081970215",
          },
          {
            id: "535",
            name: "Victoria Village",
            lat: "10.776183128357",
            lng: "106.7597732544",
          },
          {
            id: "536",
            name: "Villa E6 Qu\u1eadn 2",
            lat: "10.792886734009",
            lng: "106.73840332031",
          },
          {
            id: "537",
            name: "Villa Riviera",
            lat: "10.807569503784",
            lng: "106.74929046631",
          },
          {
            id: "538",
            name: "Vinaconex Th\u1ea3o \u0110i\u1ec1n",
            lat: "10.807326316833",
            lng: "106.73126983643",
          },
          {
            id: "539",
            name: "Vinhomes Th\u1ee7 Thi\u00eam",
            lat: "10.763198852539",
            lng: "106.72640991211",
          },
          {
            id: "540",
            name: "Vista Verde",
            lat: "10.777677536011",
            lng: "106.75783538818",
          },
          {
            id: "541",
            name: "Water Bay Novaland",
            lat: "10.782060623169",
            lng: "106.74008178711",
          },
          {
            id: "542",
            name: "Waterfront Residences",
            lat: "10.804924011231",
            lng: "106.72632598877",
          },
          {
            id: "543",
            name: "Waterina Suites",
            lat: "10.758361816406",
            lng: "106.76474761963",
          },
          {
            id: "544",
            name: "Xi Riverview Palace",
            lat: "10.816993713379",
            lng: "106.73073577881",
          },
        ],
      },
      {
        id: "15",
        name: "Qu\u1eadn 3",
        wards: [
          { id: "192", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "193", name: "10", prefix: "Ph\u01b0\u1eddng" },
          { id: "194", name: "11", prefix: "Ph\u01b0\u1eddng" },
          { id: "195", name: "12", prefix: "Ph\u01b0\u1eddng" },
          { id: "196", name: "13", prefix: "Ph\u01b0\u1eddng" },
          { id: "197", name: "14", prefix: "Ph\u01b0\u1eddng" },
          { id: "198", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "199", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "200", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "201", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "202", name: "6", prefix: "Ph\u01b0\u1eddng" },
          { id: "203", name: "7", prefix: "Ph\u01b0\u1eddng" },
          { id: "204", name: "8", prefix: "Ph\u01b0\u1eddng" },
          { id: "205", name: "9", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "4456", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4457", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4458", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4459",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          {
            id: "4460",
            name: "B\u00e0 L\u00ea Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4461",
            name: "B\u1eafc H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4462",
            name: "B\u00e0n C\u1edd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4463",
            name: "B\u1edd B\u1eafc K\u00eanh Nhi\u00eau L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4464",
            name: "B\u1edd Nam K\u00eanh Nhi\u00eau L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4465",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4466",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4467",
            name: "C\u1ed1ng H\u1ed9p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4468",
            name: "CT. Chi\u1ebfn s\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4469",
            name: "CT. D\u00e2n Ch\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4470",
            name: "C\u01b0 x\u00e1 \u0110\u00f4 Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4471",
            name: "C\u01b0 x\u00e1 Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4476",
            name: "D\u01b0 Kh\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4472",
            name: "\u0110\u1eb7ng V\u0103n Ng\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4473",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4474",
            name: "\u0110\u1ed7 T\u1ea5n Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4475",
            name: "\u0110o\u00e0n C\u00f4ng B\u1eedu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4477", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "4478",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4479",
            name: "H\u00f2a H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4480",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4481", name: "Ho\u00e0ng Sa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4482",
            name: "Hu\u1ef3nh T\u1ecbnh C\u1ee7a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4483",
            name: "Hu\u1ef3nh V\u0103n B\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4484",
            name: "K\u00eanh Nhi\u00eau L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4485",
            name: "K\u1ef3 Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4486", name: "K\u1ef3 \u0110\u1ed3ng", prefix: "Ph\u1ed1" },
          {
            id: "4487",
            name: "L\u00ea Ng\u00f4 C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4488",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4489",
            name: "L\u00ea Th\u1ecb Ri\u00eang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4490",
            name: "L\u00ea V\u0103n S\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4491",
            name: "Li\u00ean Ph\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4492",
            name: "L\u00fd Ch\u00ednh Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4493",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4494",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "4495",
            name: "Nam K\u1ef3 Kh\u1edfi Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4496",
            name: "Ng\u00f4 Th\u1eddi Nhi\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4497",
            name: "Nguy\u1ec5n Bi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4499",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4498",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4500",
            name: "Nguy\u1ec5n Gia Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4501",
            name: "Nguy\u1ec5n Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4502",
            name: "Nguy\u1ec5n Ng\u1ecdc Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4503",
            name: "Nguy\u1ec5n Ph\u00fac Nguy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4504",
            name: "Nguy\u1ec5n S\u01a1n H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4505",
            name: "Nguy\u1ec5n Th\u1ecb Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4506",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4507",
            name: "Nguy\u1ec5n Th\u1ecb Nhi\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4508",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "4509",
            name: "Nguy\u1ec5n Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4510",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4511",
            name: "Nguy\u1ec5n V\u0103n Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4512",
            name: "Nguy\u1ec5n V\u0103n Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4513",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4514", name: "Pasteur", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4515",
            name: "Ph\u1ea1m \u0110\u00ecnh To\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4516",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4517",
            name: "R\u1ea1ch B\u00f9ng Binh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4518", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4519",
            name: "S\u1ed1 1 C\u01b0 x\u00e1 \u0110\u00f4 Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4520", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4521",
            name: "S\u1ed1 2 C\u01b0 x\u00e1 \u0110\u00f4 Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4522", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4523",
            name: "S\u1ed1 3 C\u01b0 x\u00e1 \u0110\u00f4 Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4524", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4525",
            name: "S\u1ed1 4 C\u01b0 x\u00e1 \u0110\u00f4 Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4526",
            name: "S\u1ed1 5 C\u01b0 X\u00e1 \u0110\u00f4 Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4527",
            name: "S\u1ed1 6 C\u01b0 X\u00e1 \u0110\u00f4 Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4528",
            name: "S\u1ed1 7 C\u01b0 x\u00e1 \u0110\u00f4 Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4529",
            name: "S\u1ed1 8 C\u01b0 X\u00e1 \u0110\u00f4 Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4530",
            name: "S\u01b0 Thi\u1ec7n Chi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4531",
            name: "S\u01b0 V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4532",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4533",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4534",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4535",
            name: "Tr\u1ea7n Minh Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4536",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4537",
            name: "Tr\u1ea7n Qu\u1ed1c Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4538",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4539",
            name: "Tr\u1ea7n V\u0103n \u0110ang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4540",
            name: "Tr\u1ecbnh V\u0103n C\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4541",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4542",
            name: "Tr\u01b0\u01a1ng Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4543",
            name: "Tr\u01b0\u1eddng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4544",
            name: "T\u00fa X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4545",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4546",
            name: "V\u00f5 V\u0103n T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4547",
            name: "V\u01b0\u1eddn Chu\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "545",
            name: "107 Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            lat: "10.778290748596",
            lng: "106.68842315674",
          },
          {
            id: "546",
            name: "Alpha Tower",
            lat: "10.775826454163",
            lng: "106.68811035156",
          },
          {
            id: "547",
            name: "An Ph\u00fa Plaza",
            lat: "10.787470817566",
            lng: "106.68486022949",
          },
          {
            id: "548",
            name: "Bitexco Nam Long",
            lat: "10.777503013611",
            lng: "106.69115447998",
          },
          {
            id: "549",
            name: "C.T Plaza Minh Ch\u00e2u",
            lat: "10.788401603699",
            lng: "106.67618560791",
          },
          {
            id: "550",
            name: "Cao \u1ed1c Pax Sky",
            lat: "10.777493476868",
            lng: "106.68930053711",
          },
          {
            id: "551",
            name: "Crec Building Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            lat: "10.778257369995",
            lng: "106.68919372559",
          },
          {
            id: "552",
            name: "DB Court",
            lat: "10.782584190369",
            lng: "106.68971252441",
          },
          {
            id: "553",
            name: "DC Tower",
            lat: "10.78807258606",
            lng: "106.68532562256",
          },
          {
            id: "554",
            name: "Grand Central",
            lat: "10.783152580261",
            lng: "106.69194030762",
          },
          {
            id: "555",
            name: "Green Building",
            lat: "10.786206245422",
            lng: "106.66705322266",
          },
          {
            id: "556",
            name: "IDC Building",
            lat: "10.784394264221",
            lng: "106.69625854492",
          },
          {
            id: "557",
            name: "Intresco Plaza",
            lat: "10.790990829468",
            lng: "106.68557739258",
          },
          {
            id: "558",
            name: "Jabes 2 Building",
            lat: "10.776410102844",
            lng: "106.68479919434",
          },
          {
            id: "559",
            name: "L\u00e9man Luxury Apartments",
            lat: "10.778017044067",
            lng: "106.68978118897",
          },
          {
            id: "560",
            name: "Lien Hoa Building",
            lat: "10.772247314453",
            lng: "106.69233703613",
          },
          {
            id: "561",
            name: "MB Bank Tower",
            lat: "10.785419464111",
            lng: "106.66730499268",
          },
          {
            id: "562",
            name: "Minh Long Tower",
            lat: "10.775221824646",
            lng: "106.6901550293",
          },
          {
            id: "563",
            name: "New Pearl - Vi\u00ean Ng\u1ecdc M\u1edbi",
            lat: "10.782331466675",
            lng: "106.69258880615",
          },
          {
            id: "564",
            name: "New Pearl Residence",
            lat: "10.782257080078",
            lng: "106.69229888916",
          },
          {
            id: "565",
            name: "Ng\u1ecdc \u0110\u00f4ng D\u01b0\u01a1ng",
            lat: "10.775370597839",
            lng: "106.68601989746",
          },
          {
            id: "566",
            name: "Nguy\u1ec5n Ph\u00fac Nguy\u00ean",
            lat: "10.77960395813",
            lng: "106.68041992188",
          },
          {
            id: "567",
            name: "Pasteur Tower",
            lat: "10.783495903015",
            lng: "106.69197845459",
          },
          {
            id: "568",
            name: "Pjico Building",
            lat: "10.783911705017",
            lng: "106.6912689209",
          },
          {
            id: "569",
            name: "PMC Building",
            lat: "10.781931877136",
            lng: "106.69428253174",
          },
          {
            id: "570",
            name: "PNT Court",
            lat: "10.785945892334",
            lng: "106.6923751831",
          },
          {
            id: "571",
            name: "PVC Building",
            lat: "10.779047012329",
            lng: "106.68955993652",
          },
          {
            id: "572",
            name: "Saigon Mansion",
            lat: "10.780792236328",
            lng: "106.6946182251",
          },
          {
            id: "573",
            name: "Saigon Pavillon",
            lat: "10.776280403137",
            lng: "106.68864440918",
          },
          {
            id: "574",
            name: "Saigon Software Park",
            lat: "10.780604362488",
            lng: "106.68585968018",
          },
          {
            id: "575",
            name: "Screc Tower",
            lat: "10.786576271057",
            lng: "106.67573547363",
          },
          {
            id: "576",
            name: "Serenity Sky Villas",
            lat: "10.800720214844",
            lng: "106.71531677246",
          },
          {
            id: "577",
            name: "Sherwood Residence",
            lat: "10.782806396484",
            lng: "106.69316101074",
          },
          {
            id: "578",
            name: "S\u00f4ng \u0110\u00e0 Tower",
            lat: "10.783598899841",
            lng: "106.68277740478",
          },
          {
            id: "579",
            name: "T\u00e0i L\u1ed9c Office Building",
            lat: "10.786842346191",
            lng: "106.69309234619",
          },
          {
            id: "580",
            name: "Thi\u00ean H\u1ed3ng Building",
            lat: "10.768797874451",
            lng: "106.68324279785",
          },
          {
            id: "581",
            name: "T\u00edn ngh\u0129a Building",
            lat: "10.786697387695",
            lng: "106.67978668213",
          },
        ],
      },
      {
        id: "16",
        name: "Qu\u1eadn 4",
        wards: [
          { id: "206", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "207", name: "10", prefix: "Ph\u01b0\u1eddng" },
          { id: "208", name: "12", prefix: "Ph\u01b0\u1eddng" },
          { id: "209", name: "13", prefix: "Ph\u01b0\u1eddng" },
          { id: "210", name: "14", prefix: "Ph\u01b0\u1eddng" },
          { id: "211", name: "15", prefix: "Ph\u01b0\u1eddng" },
          { id: "212", name: "16", prefix: "Ph\u01b0\u1eddng" },
          { id: "213", name: "18", prefix: "Ph\u01b0\u1eddng" },
          { id: "214", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "215", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "216", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "217", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "218", name: "6", prefix: "Ph\u01b0\u1eddng" },
          { id: "219", name: "8", prefix: "Ph\u01b0\u1eddng" },
          { id: "220", name: "9", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "4548", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4549", name: "10A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4550", name: "10C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4551", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4552", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4553", name: "12A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4554", name: "12B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4555", name: "12C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4556", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4557", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4558", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4559", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4560", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4561", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4562", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4563", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4564", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4565", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4566", name: "24", prefix: "Ph\u1ed1" },
          { id: "4567", name: "25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4568", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4569", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4570", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4571", name: "30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4572", name: "33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4573", name: "34", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4574", name: "36", prefix: "Ph\u1ed1" },
          { id: "4575", name: "38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4576", name: "39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4577", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4578", name: "40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4579", name: "41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4580", name: "42", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4581", name: "43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4582", name: "44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4583", name: "45", prefix: "Ph\u1ed1" },
          { id: "4584", name: "46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4585", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4586", name: "48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4587", name: "49", prefix: "Ph\u1ed1" },
          { id: "4588", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4589", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4590", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4591", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4592", name: "A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4593",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          {
            id: "4594",
            name: "B\u1ebfn V\u00e2n \u0110\u1ed3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4595",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4596", name: "Cao L\u1ed7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4597",
            name: "C\u01b0 X\u00e1 V\u0129nh H\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4602",
            name: "D\u01b0\u01a1ng Kh\u00e1nh H\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4598",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4599",
            name: "\u0110inh L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4600",
            name: "\u0110o\u00e0n Nh\u1eef H\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4601",
            name: "\u0110o\u00e0n V\u0103n B\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4603",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4604", name: "Ho\u00e0ng Sa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4605",
            name: "Kh\u00e1nh H\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4606",
            name: "L\u00ea Ng\u00f4 C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4607",
            name: "L\u00ea Qu\u1ed1c H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4608",
            name: "L\u00ea Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4609",
            name: "L\u00ea V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4610",
            name: "L\u00fd Ch\u00ednh Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4611",
            name: "Nam K\u1ef3 Kh\u1edfi Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4612",
            name: "Ng\u00f4 Th\u1eddi Nhi\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4613",
            name: "Ng\u00f4 V\u0103n S\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4614",
            name: "Nguy\u1ec5n H\u1eefu H\u00e3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4615",
            name: "Nguy\u1ec5n H\u1eefu H\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4616",
            name: "Nguy\u1ec5n Kho\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4617",
            name: "Nguy\u1ec5n Ki\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4618",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4619",
            name: "Nguy\u1ec5n Th\u1ea7n Hi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4620",
            name: "Nguy\u1ec5n Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4621",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4622",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4623",
            name: "Phan V\u0103n H\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4624",
            name: "Qu\u1ed1c l\u1ed9 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4625", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4626", name: "S\u1ed1 10C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4627", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4628", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4629", name: "S\u1ed1 12A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4630", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4631", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4632", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4633", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4634", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4635", name: "S\u1ed1 18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4636", name: "S\u1ed1 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4637", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4638", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4639", name: "S\u1ed1 21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4640", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4641", name: "S\u1ed1 23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4642", name: "S\u1ed1 24", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4643", name: "S\u1ed1 25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4644", name: "S\u1ed1 28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4645", name: "S\u1ed1 29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4646", name: "S\u1ed1 30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4647", name: "S\u1ed1 32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4648", name: "S\u1ed1 34", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4649", name: "S\u1ed1 38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4650", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4651", name: "S\u1ed1 40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4652", name: "S\u1ed1 41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4653", name: "S\u1ed1 42", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4654", name: "S\u1ed1 43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4655", name: "S\u1ed1 44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4656", name: "S\u1ed1 45", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4657", name: "S\u1ed1 47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4658", name: "S\u1ed1 48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4659", name: "S\u1ed1 49", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4660", name: "S\u1ed1 50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4661", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4662", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4663", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4664",
            name: "T\u00e2n V\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4665",
            name: "T\u00f4n \u0110\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4666",
            name: "T\u00f4n Th\u1ea5t Thuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4667",
            name: "Tr\u1ea7n Minh Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4668",
            name: "Tr\u01b0\u01a1ng \u0110\u00ecnh H\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4669",
            name: "T\u00fa X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4670",
            name: "V\u00e2n \u0110\u1ed3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4671",
            name: "V\u1ea1n Qu\u1ed1c T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4672",
            name: "V\u0129nh H\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4673",
            name: "V\u0129nh Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4674",
            name: "X\u00f3m Chi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "582",
            name: "Charmington Iris",
            lat: "10.753022193909",
            lng: "106.69145965576",
          },
          {
            id: "583",
            name: "Constrexim Copac Square",
            lat: "10.762310981751",
            lng: "106.7077255249",
          },
          {
            id: "584",
            name: "Four Square",
            lat: "10.75749874115",
            lng: "106.69372558594",
          },
          {
            id: "585",
            name: "Galaxy 9",
            lat: "10.757175445557",
            lng: "106.6939163208",
          },
          {
            id: "586",
            name: "Grand Riverside",
            lat: "10.758068084717",
            lng: "106.69443511963",
          },
          {
            id: "587",
            name: "H2 Ho\u00e0ng Di\u1ec7u",
            lat: "10.762415885925",
            lng: "106.70224761963",
          },
          {
            id: "588",
            name: "H3 Ho\u00e0ng Di\u1ec7u",
            lat: "10.760313034058",
            lng: "106.6989364624",
          },
          {
            id: "589",
            name: "ICON 56",
            lat: "10.766123771668",
            lng: "106.70275115967",
          },
          {
            id: "590",
            name: "Khahomex Savico",
            lat: "10.766205787659",
            lng: "106.70265960693",
          },
          {
            id: "591",
            name: "Kh\u00e1nh H\u1ed9i 1",
            lat: "10.754400253296",
            lng: "106.69049072266",
          },
          {
            id: "592",
            name: "Kh\u00e1nh H\u1ed9i 2",
            lat: "10.755631446838",
            lng: "106.69100952148",
          },
          {
            id: "593",
            name: "Kh\u00e1nh H\u1ed9i 3",
            lat: "10.75511264801",
            lng: "106.68975067139",
          },
          {
            id: "594",
            name: "Lancaster Lincoln",
            lat: "10.760307312012",
            lng: "106.71221160889",
          },
          {
            id: "595",
            name: "Lancaster Residences D4",
            lat: "10.753288269043",
            lng: "106.70529937744",
          },
          {
            id: "596",
            name: "Masteri Millennium",
            lat: "10.762699127197",
            lng: "106.69958496094",
          },
          {
            id: "597",
            name: "Orient Apartment",
            lat: "10.757141113281",
            lng: "106.69234466553",
          },
          {
            id: "598",
            name: "Riva Park",
            lat: "10.755148887634",
            lng: "106.71866607666",
          },
          {
            id: "599",
            name: "River Gate",
            lat: "10.762795448303",
            lng: "106.69860076904",
          },
          {
            id: "600",
            name: "Saigon Royal Residence",
            lat: "10.767429351807",
            lng: "106.70413208008",
          },
          {
            id: "601",
            name: "The Gold View",
            lat: "10.756248474121",
            lng: "106.69184875488",
          },
          {
            id: "602",
            name: "The Tresor",
            lat: "10.766646385193",
            lng: "106.70347595215",
          },
          {
            id: "603",
            name: "T\u00f4n Th\u1ea5t Thuy\u1ebft",
            lat: "10.754854202271",
            lng: "106.69194793701",
          },
          {
            id: "604",
            name: "V\u1ea1n \u0110\u00f4",
            lat: "10.755442619324",
            lng: "106.6897277832",
          },
          {
            id: "605",
            name: "VietCas Tower",
            lat: "10.755802154541",
            lng: "106.71842193603",
          },
        ],
      },
      {
        id: "17",
        name: "Qu\u1eadn 5",
        wards: [
          { id: "221", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "222", name: "10", prefix: "Ph\u01b0\u1eddng" },
          { id: "223", name: "11", prefix: "Ph\u01b0\u1eddng" },
          { id: "224", name: "12", prefix: "Ph\u01b0\u1eddng" },
          { id: "225", name: "13", prefix: "Ph\u01b0\u1eddng" },
          { id: "226", name: "14", prefix: "Ph\u01b0\u1eddng" },
          { id: "227", name: "15", prefix: "Ph\u01b0\u1eddng" },
          { id: "228", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "229", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "230", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "231", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "232", name: "6", prefix: "Ph\u01b0\u1eddng" },
          { id: "233", name: "7", prefix: "Ph\u01b0\u1eddng" },
          { id: "234", name: "8", prefix: "Ph\u01b0\u1eddng" },
          { id: "235", name: "9", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "4675", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4676", name: "An B\u00ecnh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4678",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4677",
            name: "An \u0110i\u1ec1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4679", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "4680",
            name: "B\u1ea1ch V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4681",
            name: "B\u00e3i S\u1eady",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4682",
            name: "B\u00f9i H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4683",
            name: "Cao \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4684",
            name: "Ch\u00e2u V\u0103n Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4685",
            name: "Chi\u00eau Anh C\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4686",
            name: "C\u00f4ng Tr\u01b0\u1eddng An \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4691",
            name: "D\u01b0\u01a1ng B\u00e1 Tr\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4692",
            name: "D\u01b0\u01a1ng T\u1eed Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4687",
            name: "\u0110\u1eb7ng Th\u00e1i Th\u00e2n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "4688",
            name: "\u0110\u00e0o T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4689",
            name: "\u0110\u1ed7 Ng\u1ecdc Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4690",
            name: "\u0110\u1ed7 V\u0103n S\u1eedu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4693", name: "Gia Ph\u00fa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4694",
            name: "G\u00f2 C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4695",
            name: "H\u00e0 T\u00f4n Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4696",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4697",
            name: "H\u00e0m T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4698",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4699",
            name: "H\u1ecdc L\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4700",
            name: "H\u1ed3ng B\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4701",
            name: "H\u01b0ng Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4702",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4703",
            name: "Hu\u1ef3nh M\u1eabn \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4704", name: "Kim Bi\u00ean", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4705",
            name: "K\u00fd H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4706",
            name: "L\u00e3o T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4707",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4708",
            name: "L\u00ea Quang \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4709",
            name: "L\u01b0\u01a1ng Nh\u1eef H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4710",
            name: "L\u01b0u Xu\u00e2n T\u00edn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4711",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "4712",
            name: "M\u1ea1c C\u1eedu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4713",
            name: "M\u1ea1c Thi\u00ean T\u00edch",
            prefix: "Ph\u1ed1",
          },
          {
            id: "4714",
            name: "Ngh\u0129a Th\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4715",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4716",
            name: "Ng\u00f4 Nh\u00e2n T\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4717",
            name: "Ng\u00f4 Nh\u00e2n T\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4718",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4719",
            name: "Nguy\u1ec5n \u00c1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4720",
            name: "Nguy\u1ec5n An Kh\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4721",
            name: "Nguy\u1ec5n Bi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4722",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4723",
            name: "Nguy\u1ec5n Duy D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4724",
            name: "Nguy\u1ec5n Kim",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4725",
            name: "Nguy\u1ec5n Phi Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4726",
            name: "Nguy\u1ec5n Thi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4727",
            name: "Nguy\u1ec5n Th\u1ecb Nh\u1ecf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4728",
            name: "Nguy\u1ec5n Th\u1eddi Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4729",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4730",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4731",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4732",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4733",
            name: "Nguy\u1ec5n V\u0103n \u0110\u1eebng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4734",
            name: "Nhi\u00eau T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4735",
            name: "Ph\u1ea1m B\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4736",
            name: "Ph\u1ea1m \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4737",
            name: "Ph\u1ea1m H\u1eefu Ch\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4738",
            name: "Phan Huy Ch\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4739",
            name: "Phan Ph\u00fa Ti\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4740",
            name: "Phan V\u0103n Kh\u1ecfe",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4741",
            name: "Phan V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4742",
            name: "Ph\u00f3 C\u01a1 \u0110i\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4743",
            name: "Ph\u00fa \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4744",
            name: "Ph\u00f9 \u0110\u1ed5ng Thi\u00ean V\u01b0\u01a1ng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "4745",
            name: "Ph\u00fa Gi\u00e1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4746",
            name: "Ph\u00fa H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4747", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "4748",
            name: "Ph\u01b0\u1edbc H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4749",
            name: "S\u01b0 V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4750",
            name: "T\u1ea1 Uy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4751",
            name: "T\u1ea3n \u0110\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4752",
            name: "T\u00e2n H\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4753",
            name: "T\u00e2n H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4754",
            name: "T\u00e2n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4755",
            name: "T\u0103ng B\u1ea1t H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4756",
            name: "Thu\u1eadn Ki\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4757",
            name: "T\u1ed1ng Duy T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4758",
            name: "T\u1ed1ng V\u0103n H\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4759",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4760",
            name: "Tr\u1ea7n Ch\u00e1nh Chi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4761", name: "Tr\u1ea7n \u0110i\u1ec1n", prefix: "Ph\u1ed1" },
          {
            id: "4762",
            name: "Tr\u1ea7n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4763",
            name: "Tr\u1ea7n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4764",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4765",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4766",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4767",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4768",
            name: "Tr\u1ea7n Tu\u1ea5n Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4769",
            name: "Tr\u1ea7n T\u01b0\u1edbng C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4770",
            name: "Tr\u1ea7n V\u0103n Ki\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4771",
            name: "Tr\u1ea7n V\u0103n T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4772",
            name: "Tr\u1ea7n Xu\u00e2n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4773", name: "Trang T\u1eed", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4774",
            name: "Tri\u1ec7u Quang Ph\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4775",
            name: "Tr\u1ecbnh Ho\u00e0i \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4776", name: "V\u1ea1n Ki\u1ebfp", prefix: "Ph\u1ed1" },
          {
            id: "4777",
            name: "V\u1ea1n T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4778",
            name: "V\u00f5 Tr\u01b0\u1eddng To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4779",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4780",
            name: "V\u0169 Ch\u00ed Hi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4781",
            name: "V\u0169 Ch\u00ed Hi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4782",
            name: "X\u00f3m Ch\u1ec9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4783",
            name: "X\u00f3m V\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4784",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "606",
            name: "155 Nguy\u1ec5n Ch\u00ed Thanh",
            lat: "10.75901889801",
            lng: "106.66495513916",
          },
          {
            id: "607",
            name: "Cao \u1ed1c Cinco",
            lat: "10.752439498901",
            lng: "106.65442657471",
          },
          {
            id: "608",
            name: "Charmington",
            lat: "10.750589370727",
            lng: "106.66368103027",
          },
          {
            id: "609",
            name: "Dragon Riverside City",
            lat: "21.029581069946",
            lng: "105.85041046143",
          },
          {
            id: "610",
            name: "Golden Plaza",
            lat: "10.753300666809",
            lng: "106.65481567383",
          },
          {
            id: "611",
            name: "H\u00e0 Phan",
            lat: "10.753090858459",
            lng: "106.67324829102",
          },
          {
            id: "612",
            name: "HCMC Lottery Tower",
            lat: "10.760750770569",
            lng: "106.67484283447",
          },
          {
            id: "613",
            name: "H\u00f9ng V\u01b0\u01a1ng Plaza",
            lat: "10.755990028381",
            lng: "106.66268920898",
          },
          {
            id: "614",
            name: "Lakai",
            lat: "10.754042625427",
            lng: "106.66982269287",
          },
          {
            id: "615",
            name: "Ng\u1ecdc Kh\u00e1nh Tower",
            lat: "10.753630638123",
            lng: "106.68415832519",
          },
          {
            id: "616",
            name: "Ph\u00fac Th\u1ecbnh",
            lat: "10.75341129303",
            lng: "106.68025970459",
          },
          {
            id: "617",
            name: "Sao Mai",
            lat: "10.752693176269",
            lng: "106.65977478027",
          },
          {
            id: "618",
            name: "T\u1ea3n \u0110\u00e0 Court",
            lat: "10.753204345703",
            lng: "106.66454315185",
          },
          {
            id: "619",
            name: "The Everrich Infinity",
            lat: "10.760364532471",
            lng: "106.68082427978",
          },
          {
            id: "620",
            name: "Thu\u1eadn Ki\u1ec1u Plaza",
            lat: "10.754392623901",
            lng: "106.65578460693",
          },
          {
            id: "621",
            name: "Ventosa",
            lat: "10.756464004517",
            lng: "106.65641784668",
          },
        ],
      },
      {
        id: "18",
        name: "Qu\u1eadn 6",
        wards: [
          { id: "236", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "237", name: "10", prefix: "Ph\u01b0\u1eddng" },
          { id: "238", name: "11", prefix: "Ph\u01b0\u1eddng" },
          { id: "239", name: "12", prefix: "Ph\u01b0\u1eddng" },
          { id: "240", name: "13", prefix: "Ph\u01b0\u1eddng" },
          { id: "241", name: "14", prefix: "Ph\u01b0\u1eddng" },
          { id: "242", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "243", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "244", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "245", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "246", name: "6", prefix: "Ph\u01b0\u1eddng" },
          { id: "247", name: "7", prefix: "Ph\u01b0\u1eddng" },
          { id: "248", name: "8", prefix: "Ph\u01b0\u1eddng" },
          { id: "249", name: "9", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "4785", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4786", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4787", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4788", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4789", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4790", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4791", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4792", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4793", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4794", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4795", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4796", name: "1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4797", name: "1B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4798", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4799", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4800", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4801", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4802", name: "22A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4803", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4804", name: "24", prefix: "Ph\u1ed1" },
          { id: "4805", name: "25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4806", name: "26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4807", name: "26A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4808", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4809", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4810", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4811", name: "30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4812", name: "31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4813", name: "32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4814", name: "32A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4815", name: "32B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4816", name: "32C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4817", name: "33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4818", name: "34", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4819", name: "35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4820", name: "36", prefix: "Ph\u1ed1" },
          { id: "4821", name: "37", prefix: "Ph\u1ed1" },
          { id: "4822", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4823", name: "40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4824", name: "41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4825", name: "42", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4826", name: "43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4827", name: "44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4828", name: "45", prefix: "Ph\u1ed1" },
          { id: "4829", name: "46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4830", name: "49", prefix: "Ph\u1ed1" },
          { id: "4831", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4832", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4833", name: "51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4834", name: "53", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4835", name: "54", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4836", name: "55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4837", name: "57", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4838", name: "58", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4839", name: "59", prefix: "Ph\u1ed1" },
          { id: "4840", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4841", name: "60", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4842", name: "61", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4843", name: "62", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4844", name: "64", prefix: "Ph\u1ed1" },
          { id: "4845", name: "68", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4846", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4847", name: "70", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4848", name: "72", prefix: "Ph\u1ed1" },
          { id: "4849", name: "74", prefix: "Ph\u1ed1" },
          { id: "4850", name: "76", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4851", name: "78", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4852", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4853", name: "82", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4854", name: "84", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4855", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4856",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4857", name: "B\u00e0 Hom", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4858",
            name: "B\u00e0 K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4859",
            name: "B\u00e0 L\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4860",
            name: "B\u00e3i S\u1eady",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4861",
            name: "B\u1ebfn Ph\u00fa L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4862",
            name: "B\u00ecnh Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4863",
            name: "B\u00ecnh Ph\u00fa 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4864",
            name: "B\u00ecnh Ph\u00fa 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4865",
            name: "B\u00ecnh Ph\u00fa 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4866",
            name: "B\u00ecnh T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4867",
            name: "B\u00ecnh Th\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4868",
            name: "B\u00ecnh Ti\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4869",
            name: "B\u1eedu \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4870",
            name: "Cao V\u0103n L\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4871",
            name: "Ch\u1ee3 L\u1edbn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4872",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4873",
            name: "C\u01b0 X\u00e1 Ph\u00fa L\u00e2m A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4874",
            name: "C\u01b0 X\u00e1 Ph\u00fa L\u00e2m B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4875",
            name: "C\u01b0 X\u00e1 Ph\u00fa L\u00e2m D",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4876",
            name: "C\u01b0 X\u00e1 Ra \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4877",
            name: "\u0110\u1ea1i L\u1ed9 \u0110\u00f4ng T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4878",
            name: "\u0110\u1eb7ng Nguy\u00ean C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4879", name: "Gia Ph\u00fa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4880",
            name: "H\u1eadu Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4881",
            name: "H\u1eadu Giang A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4882",
            name: "H\u1eadu Giang B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4883",
            name: "Ho\u00e0ng L\u00ea Kha",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4884",
            name: "H\u1ed3ng B\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4885",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4886",
            name: "K\u00eanh T\u00e2n H\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4887",
            name: "Khi\u00eau N\u0103ng T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4888",
            name: "Khi\u1ebfu N\u0103ng T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4889",
            name: "Kinh D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4890",
            name: "L\u00ea C\u00f4ng Mi\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4891",
            name: "L\u00ea Quang Sung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4892",
            name: "L\u00ea T\u1ea5n K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4893",
            name: "L\u00ea Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4894",
            name: "L\u00ea Tu\u1ea5n M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4895",
            name: "L\u00f2 G\u1ed1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4896",
            name: "L\u00fd Chi\u00eau Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4897",
            name: "Mai Xu\u00e2n Th\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4898",
            name: "Minh Ph\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4899", name: "N77", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4900",
            name: "Ng\u00f4 Nh\u00e2n T\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4901",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4902",
            name: "Nguy\u1ec5n H\u1eefu Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4903",
            name: "Nguy\u1ec5n Ph\u1ea1m Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4904",
            name: "Nguy\u1ec5n Th\u1ecb Nh\u1ecf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4905",
            name: "Nguy\u1ec5n V\u0103n Lu\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4906",
            name: "Nguy\u1ec5n Xu\u00e2n Ph\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4907",
            name: "Ph\u1ea1m \u0110\u00ecnh H\u1ed5",
            prefix: "Ph\u1ed1",
          },
          {
            id: "4908",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4909",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4910",
            name: "Ph\u1ea1m Ph\u00fa Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4911",
            name: "Ph\u1ea1m V\u0103n Ch\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4912", name: "Phan Anh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4913",
            name: "Phan V\u0103n Kh\u1ecfe",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4914",
            name: "Ph\u00fa \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4915",
            name: "Qu\u1ed1c l\u1ed9 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4916", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4917", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4918",
            name: "S\u1ed1 10 C\u01b0 X\u00e1 Ra \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4919", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4920",
            name: "S\u1ed1 11 C\u01b0 x\u00e1 Ra \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4921", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4922", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4923", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4924", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4925", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4926", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4927", name: "S\u1ed1 21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4928", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4929", name: "S\u1ed1 23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4930", name: "S\u1ed1 24", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4931", name: "S\u1ed1 24A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4932", name: "S\u1ed1 25A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4933", name: "S\u1ed1 26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4934", name: "S\u1ed1 27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4935", name: "S\u1ed1 28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4936", name: "S\u1ed1 29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4937", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4938", name: "S\u1ed1 30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4939", name: "S\u1ed1 30A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4940", name: "S\u1ed1 31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4941", name: "S\u1ed1 32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4942", name: "S\u1ed1 33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4943", name: "S\u1ed1 35A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4944", name: "S\u1ed1 36", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4945", name: "S\u1ed1 39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4946", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4947", name: "S\u1ed1 40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4948", name: "S\u1ed1 41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4949", name: "S\u1ed1 42", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4950", name: "S\u1ed1 43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4951", name: "S\u1ed1 44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4952", name: "S\u1ed1 46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4953", name: "S\u1ed1 47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4954", name: "S\u1ed1 48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4955", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4956", name: "S\u1ed1 50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4957", name: "S\u1ed1 51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4958", name: "S\u1ed1 52", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4959", name: "S\u1ed1 53", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4960", name: "S\u1ed1 54", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4961", name: "S\u1ed1 55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4962", name: "S\u1ed1 57", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4963", name: "S\u1ed1 58", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4964", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4965", name: "S\u1ed1 61", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4966", name: "S\u1ed1 64", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4967", name: "S\u1ed1 66", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4968", name: "S\u1ed1 68", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4969", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4970", name: "S\u1ed1 70", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4971", name: "S\u1ed1 74", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4972", name: "S\u1ed1 78", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4973", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4974", name: "S\u1ed1 82", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4975", name: "S\u1ed1 84", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4976", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4977",
            name: "S\u1ed1 9 C\u01b0 x\u00e1 \u0111\u00e0i Rada",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4978",
            name: "S\u1ed1 9 C\u01b0 X\u00e1 Ra\u0111a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4979",
            name: "Song H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4980",
            name: "T\u00e2n H\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4981",
            name: "T\u00e2n H\u00f2a \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4982",
            name: "Th\u00e1i Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4983",
            name: "Th\u00e1p M\u01b0\u1eddi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4984",
            name: "T\u1ec9nh L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4985", name: "Tr\u1ea7n B\u00ecnh", prefix: "Ph\u1ed1" },
          {
            id: "4986",
            name: "Tr\u1ea7n Trung L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4987",
            name: "Tr\u1ea7n V\u0103n Ki\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "4988", name: "Trang T\u1eed", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "4989",
            name: "Tr\u01b0\u01a1ng T\u1ea5n Sang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4990",
            name: "V\u0103n Th\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4991",
            name: "V\u00e0nh \u0110ai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4992",
            name: "V\u1ec7 \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4993",
            name: "V\u00f5 \u0110\u00ecnh T\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4994",
            name: "V\u00f5 Trung Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4995",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "4996",
            name: "V\u00f5 V\u0103n T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "622",
            name: "An Ph\u00fa Apartment",
            lat: "10.745414733887",
            lng: "106.62638854981",
          },
          {
            id: "623",
            name: "Blue Sapphire B\u00ecnh Ph\u00fa",
            lat: "10.739830970764",
            lng: "106.62884521484",
          },
          {
            id: "624",
            name: "Goldland Plaza",
            lat: "10.736006736755",
            lng: "106.63619995117",
          },
          {
            id: "625",
            name: "Him Lam Ch\u1ee3 L\u1edbn",
            lat: "10.746722221374",
            lng: "106.63714599609",
          },
          {
            id: "626",
            name: "Khu c\u0103n h\u1ed9 B\u00ecnh Ph\u00fa",
            lat: "10.745272636414",
            lng: "106.62660980225",
          },
          {
            id: "627",
            name: "Lucky Palace",
            lat: "10.74892616272",
            lng: "106.65283966065",
          },
          {
            id: "628",
            name: "OPC Eximland",
            lat: "10.753125190735",
            lng: "106.63592529297",
          },
          {
            id: "629",
            name: "Remax Plaza",
            lat: "10.745091438293",
            lng: "106.64931488037",
          },
          {
            id: "630",
            name: "Sacomreal H\u00f9ng V\u01b0\u01a1ng",
            lat: "10.746000289917",
            lng: "106.62400054932",
          },
          {
            id: "631",
            name: "StarLight Riverside",
            lat: "10.749584197998",
            lng: "106.63497161865",
          },
          {
            id: "632",
            name: "Summer Square",
            lat: "10.759433746338",
            lng: "106.62526702881",
          },
          {
            id: "633",
            name: "T\u00e2n Th\u1ecbnh L\u1ee3i",
            lat: "10.752339363098",
            lng: "106.62880706787",
          },
          {
            id: "634",
            name: "The Western Capital",
            lat: "10.740673065186",
            lng: "106.63024139404",
          },
          {
            id: "635",
            name: "Viva Riverside",
            lat: "10.742552757263",
            lng: "106.64744567871",
          },
        ],
      },
      {
        id: "19",
        name: "Qu\u1eadn 7",
        wards: [
          {
            id: "250",
            name: "B\u00ecnh Thu\u1eadn",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "251", name: "Ph\u00fa M\u1ef9", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "252",
            name: "Ph\u00fa Thu\u1eadn",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "253", name: "T\u00e2n H\u01b0ng", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "254",
            name: "T\u00e2n Ki\u1ec3ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "255", name: "T\u00e2n Phong", prefix: "Ph\u01b0\u1eddng" },
          { id: "256", name: "T\u00e2n Ph\u00fa", prefix: "Ph\u01b0\u1eddng" },
          { id: "257", name: "T\u00e2n Quy", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "258",
            name: "T\u00e2n Thu\u1eadn \u0110\u00f4ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "259",
            name: "T\u00e2n Thu\u1eadn T\u00e2y",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "4997", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4998", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "4999", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5000", name: "11A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5001", name: "11N", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5002", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5003", name: "12B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5004", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5005", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5006", name: "14A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5007", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5008", name: "15B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5009", name: "15N", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5010", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5011", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5012", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5013", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5014", name: "1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5015", name: "1B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5016", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5017", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5018", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5019", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5020", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5021", name: "24", prefix: "Ph\u1ed1" },
          { id: "5022", name: "25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5023", name: "25A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5024", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5025", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5026", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5027", name: "2A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5028", name: "2B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5029", name: "2C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5030", name: "2K", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5031", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5032", name: "30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5033", name: "31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5034", name: "32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5035", name: "33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5036", name: "34", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5037", name: "35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5038", name: "36", prefix: "Ph\u1ed1" },
          { id: "5039", name: "37", prefix: "Ph\u1ed1" },
          { id: "5040", name: "38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5041", name: "39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5042", name: "3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5043", name: "3B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5044", name: "3J", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5045", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5046", name: "40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5047", name: "41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5048", name: "42", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5049", name: "43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5050", name: "44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5051", name: "45", prefix: "Ph\u1ed1" },
          { id: "5052", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5053", name: "48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5054", name: "49", prefix: "Ph\u1ed1" },
          { id: "5055", name: "4A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5056", name: "4C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5057", name: "4F", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5058", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5059", name: "51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5060", name: "52", prefix: "Ph\u1ed1" },
          { id: "5061", name: "53", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5062", name: "54", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5063", name: "55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5064", name: "56", prefix: "Ph\u1ed1" },
          { id: "5065", name: "57", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5066", name: "59", prefix: "Ph\u1ed1" },
          { id: "5067", name: "5C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5068", name: "5K", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5069", name: "5N", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5070", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5071", name: "60", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5072", name: "61", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5073", name: "62", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5074", name: "63", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5075", name: "64", prefix: "Ph\u1ed1" },
          { id: "5076", name: "65", prefix: "Ph\u1ed1" },
          { id: "5077", name: "66", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5078", name: "67", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5079", name: "68", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5080", name: "69", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5081", name: "6A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5082", name: "6D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5083", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5084", name: "70", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5085", name: "71", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5086", name: "719", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5087", name: "73", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5088", name: "75", prefix: "Ph\u1ed1" },
          { id: "5089", name: "76", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5090", name: "77", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5091", name: "78", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5092", name: "79", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5093", name: "7L", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5094", name: "7P", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5095", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5096", name: "81", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5097", name: "83", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5098", name: "85", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5099", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5100", name: "9M", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5101", name: "A4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5102", name: "B4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5103",
            name: "B\u1ebf V\u0103n C\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5104",
            name: "B\u1ebfn Ngh\u00e9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5105",
            name: "B\u00ecnh Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5106",
            name: "B\u00f9i B\u1eb1ng \u0110o\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5107",
            name: "B\u00f9i V\u0103n Ba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5108", name: "C", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5109",
            name: "Cao Tri\u1ec1u Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5110",
            name: "Chuy\u00ean D\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5111",
            name: "Chuy\u00ean D\u00f9ng 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5112",
            name: "Chuy\u00ean D\u00f9ng Ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5113", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5114", name: "D14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5115", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5116", name: "D24", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5117", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5118", name: "D35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5119", name: "D4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5120", name: "D44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5121", name: "D5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5122", name: "D6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5123", name: "D7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5124",
            name: "\u0110a Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5125",
            name: "\u0110a Thi\u1ec7n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5126",
            name: "\u0110\u1eb7ng \u0110\u1ea1i \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5127",
            name: "\u0110\u1eb7ng \u0110\u1ee9c Thu\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5128",
            name: "\u0110\u00e0o Chi\u1ebfn Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5129",
            name: "\u0110\u00e0o Ch\u00ednh Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5130",
            name: "\u0110\u00e0o T\u00f4ng Nguy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5131",
            name: "\u0110\u00e0o Tr\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5132",
            name: "\u0110\u00f4 \u0110\u1ed1c Tuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5133", name: "G", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5134",
            name: "Gi\u1ea3i Ph\u00f3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5135", name: "G\u00f2 Lu", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5136",
            name: "G\u00f2 \u00d4 M\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5137",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5138", name: "Him Lam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5139",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5140",
            name: "Ho\u00e0ng V\u0103n Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5141",
            name: "H\u01b0ng Gia 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5142",
            name: "H\u01b0ng Gia 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5143",
            name: "H\u01b0ng Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5144",
            name: "H\u01b0ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5145",
            name: "Hu\u1ef3nh T\u1ea5n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5146",
            name: "Ki\u1ec1u \u0110\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5147",
            name: "L\u00e2m V\u0103n B\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5148",
            name: "L\u00e3nh Binh Th\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5149",
            name: "L\u00ea \u0110\u0103ng L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5150",
            name: "L\u00ea V\u0103n L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5151",
            name: "L\u00ea V\u0103n Th\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5152",
            name: "L\u00ea V\u0103n Thi\u00eam",
            prefix: "Ph\u1ed1",
          },
          {
            id: "5153",
            name: "L\u01b0u Tr\u1ecdng L\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5154",
            name: "L\u00fd Long T\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5155",
            name: "L\u00fd Ph\u1ee5c Man",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5156",
            name: "Mai Ch\u00ed Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5157",
            name: "Mai V\u0103n V\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5158", name: "M\u1ef9 Giang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5159",
            name: "M\u1ef9 H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5160",
            name: "M\u1ef9 Ph\u00fa 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5161",
            name: "M\u1ef9 T\u00fa C\u1ea3nh Quan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5162", name: "N", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5163", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5164", name: "N3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5165", name: "N8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5166", name: "Nam Long", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5167",
            name: "Nguy\u1ec5n B\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5168",
            name: "Nguy\u1ec5n Cao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5169",
            name: "Nguy\u1ec5n Cao Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5170",
            name: "Nguy\u1ec5n \u0110\u1ed5ng Chi",
            prefix: "Ph\u1ed1",
          },
          {
            id: "5171",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5172",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5173",
            name: "Nguy\u1ec5n Kh\u1eafc Vi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5174",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5175",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5176",
            name: "Nguy\u1ec5n Th\u1ecb Th\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5177",
            name: "Nguy\u1ec5n V\u0103n B\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5178",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5179",
            name: "Nguy\u1ec5n V\u0103n Lu\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5180",
            name: "Nguy\u1ec5n V\u0103n Qu\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5181",
            name: "Nguy\u1ec5n V\u0103n T\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5182", name: "O", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5183", name: "P", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5184",
            name: "Ph\u1ea1m H\u1eefu L\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5185",
            name: "Ph\u1ea1m Th\u00e1i B\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5186",
            name: "Ph\u1ea1m V\u0103n Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5187",
            name: "Phan Huy Th\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5188",
            name: "Phan Khi\u00eam \u00cdch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5189",
            name: "Phan V\u0103n V\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5190",
            name: "Ph\u00fa Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5191",
            name: "Qu\u1ed1c l\u1ed9 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5192",
            name: "Qu\u1ed1c l\u1ed9 71",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5193", name: "Raymondienne", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5194",
            name: "S\u00e1ng T\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5195", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5196", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5197", name: "S\u1ed1 1041", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5198", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5199", name: "S\u1ed1 11L", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5200", name: "S\u1ed1 11N", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5201", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5202", name: "S\u1ed1 12A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5203", name: "S\u1ed1 12B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5204", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5205", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5206", name: "S\u1ed1 14A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5207", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5208", name: "S\u1ed1 15B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5209", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5210", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5211", name: "S\u1ed1 18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5212", name: "S\u1ed1 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5213", name: "S\u1ed1 1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5214", name: "S\u1ed1 1B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5215", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5216", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5217", name: "S\u1ed1 21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5218", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5219", name: "S\u1ed1 23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5220", name: "S\u1ed1 25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5221", name: "S\u1ed1 25A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5222", name: "S\u1ed1 27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5223", name: "S\u1ed1 28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5224", name: "S\u1ed1 29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5225", name: "S\u1ed1 2A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5226", name: "S\u1ed1 2B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5227", name: "S\u1ed1 2C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5228", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5229", name: "S\u1ed1 30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5230", name: "S\u1ed1 31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5231", name: "S\u1ed1 32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5232", name: "S\u1ed1 33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5233", name: "S\u1ed1 34", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5234", name: "S\u1ed1 35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5235", name: "S\u1ed1 36", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5236", name: "S\u1ed1 37", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5237", name: "S\u1ed1 38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5238", name: "S\u1ed1 39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5239", name: "S\u1ed1 3B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5240", name: "S\u1ed1 3J", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5241", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5242", name: "S\u1ed1 40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5243", name: "S\u1ed1 41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5244", name: "S\u1ed1 42", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5245", name: "S\u1ed1 43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5246", name: "S\u1ed1 44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5247", name: "S\u1ed1 45", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5248", name: "S\u1ed1 46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5249", name: "S\u1ed1 47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5250", name: "S\u1ed1 48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5251", name: "S\u1ed1 487", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5252", name: "S\u1ed1 49", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5253", name: "S\u1ed1 4A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5254", name: "S\u1ed1 4D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5255", name: "S\u1ed1 4F", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5256", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5257", name: "S\u1ed1 50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5258", name: "S\u1ed1 51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5259", name: "S\u1ed1 52", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5260", name: "S\u1ed1 53", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5261", name: "S\u1ed1 54", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5262", name: "S\u1ed1 55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5263", name: "S\u1ed1 57", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5264", name: "S\u1ed1 5A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5265", name: "S\u1ed1 5K", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5266", name: "S\u1ed1 5X", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5267", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5268", name: "S\u1ed1 60", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5269", name: "S\u1ed1 61", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5270", name: "S\u1ed1 62", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5271", name: "S\u1ed1 63", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5272", name: "S\u1ed1 64", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5273", name: "S\u1ed1 65", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5274", name: "S\u1ed1 66", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5275", name: "S\u1ed1 67", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5276", name: "S\u1ed1 69", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5277", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5278", name: "S\u1ed1 70", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5279", name: "S\u1ed1 71", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5280", name: "S\u1ed1 719", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5281", name: "S\u1ed1 73", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5282", name: "S\u1ed1 75", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5283", name: "S\u1ed1 77", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5284", name: "S\u1ed1 79", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5285", name: "S\u1ed1 793", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5286", name: "S\u1ed1 7E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5287", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5288", name: "S\u1ed1 80", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5289", name: "S\u1ed1 81", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5290", name: "S\u1ed1 83", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5291", name: "S\u1ed1 85", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5292", name: "S\u1ed1 8A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5293", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5294", name: "S\u1ed1 95", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5295", name: "S\u1ed1 9M", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5296", name: "S\u1ed1 C19", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5297",
            name: "T\u00e2n An Huy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5298",
            name: "T\u00e2n M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5299",
            name: "T\u00e2n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5300", name: "T\u00e2n Quy", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5301",
            name: "T\u00e2n Qu\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5302",
            name: "T\u00e2n Quy \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5303",
            name: "T\u00e2n Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5304",
            name: "T\u00e2n Thu\u1eadn Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5305",
            name: "T\u00e2n Thu\u1eadn T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5306",
            name: "T\u00e2n Tr\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5307",
            name: "T\u00e2n V\u0103n Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5308",
            name: "T\u00e2y B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5309",
            name: "Th\u00e1i V\u0103n B\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5310",
            name: "Th\u1edbi An 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5311", name: "Ti\u1ec3u Nam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5312",
            name: "T\u1ec9nh l\u1ed9 15B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5313",
            name: "T\u1ec9nh l\u1ed9 73",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5314",
            name: "T\u00f4n D\u1eadt Ti\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5315",
            name: "Tr\u1ea7n \u0110\u1ee9c L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5316",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5317",
            name: "Tr\u1ea7n Tr\u1ecdng Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5318",
            name: "Tr\u1ea7n V\u0103n Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5319",
            name: "Tr\u1ea7n V\u0103n Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5320",
            name: "Tr\u1ea7n Xu\u00e2n So\u1ea1n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "5321",
            name: "V\u01b0\u1eddn \u0110i\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "636",
            name: "An Gia Riverside",
            lat: "10.71448135376",
            lng: "106.74025726318",
          },
          {
            id: "637",
            name: "An Gia Skyline",
            lat: "10.715517997742",
            lng: "106.74086761475",
          },
          {
            id: "638",
            name: "An H\u00f2a - Nam Long",
            lat: "10.74100112915",
            lng: "106.73278045654",
          },
          {
            id: "639",
            name: "An Vi\u00ean",
            lat: "10.741514205933",
            lng: "106.733253479",
          },
          {
            id: "640",
            name: "Ascent Lakeside",
            lat: "10.752409934998",
            lng: "106.72602081299",
          },
          {
            id: "641",
            name: "Belleza Apartment",
            lat: "10.70658493042",
            lng: "106.727684021",
          },
          {
            id: "642",
            name: "Bi\u1ec7t th\u1ef1 Chateau",
            lat: "10.714929580688",
            lng: "106.72568511963",
          },
          {
            id: "643",
            name: "B\u00ecnh An Apartment",
            lat: "10.741367340088",
            lng: "106.70882415772",
          },
          {
            id: "644",
            name: "C\u0103n h\u1ed9 Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            lat: "10.712491989136",
            lng: "106.74208831787",
          },
          {
            id: "645",
            name: "C\u0103n h\u1ed9 Ph\u00fa M\u1ef9 V\u1ea1n Ph\u00e1t H\u01b0ng",
            lat: "10.713397979736",
            lng: "106.73195648193",
          },
          {
            id: "646",
            name: "C\u1ea3nh Vi\u00ean 1",
            lat: "10.72093296051",
            lng: "106.72979736328",
          },
          {
            id: "647",
            name: "C\u1ea3nh Vi\u00ean 2",
            lat: "10.722710609436",
            lng: "106.72817993164",
          },
          {
            id: "648",
            name: "C\u1ea3nh Vi\u00ean 3",
            lat: "10.721760749817",
            lng: "106.72884368897",
          },
          {
            id: "649",
            name: "Citadel Residence",
            lat: "10.726306915283",
            lng: "106.72380828857",
          },
          {
            id: "650",
            name: "CityLand Riverside",
            lat: "10.737704277039",
            lng: "106.72602844238",
          },
          {
            id: "651",
            name: "Cosmo City",
            lat: "10.738699913025",
            lng: "106.71119689941",
          },
          {
            id: "655",
            name: "D-Vela",
            lat: "10.727342605591",
            lng: "106.7334060669",
          },
          {
            id: "652",
            name: "Diamond City",
            lat: "10.751041412353",
            lng: "106.72482299805",
          },
          {
            id: "653",
            name: "Docklands S\u00e0i G\u00f2n",
            lat: "10.735711097717",
            lng: "106.72557067871",
          },
          {
            id: "654",
            name: "\u0110\u1ee9c Long Golden Land",
            lat: "10.753331184387",
            lng: "106.72330474853",
          },
          {
            id: "656",
            name: "Ehome 5-The Bridgeview",
            lat: "10.743570327759",
            lng: "106.73300170898",
          },
          {
            id: "657",
            name: "Elysium Tower",
            lat: "10.73012638092",
            lng: "106.73799133301",
          },
          {
            id: "658",
            name: "Evergreen",
            lat: "10.697972297668",
            lng: "106.74341583252",
          },
          {
            id: "659",
            name: "Florita \u0110\u1ee9c Kh\u1ea3i",
            lat: "10.744828224182",
            lng: "106.69515228272",
          },
          {
            id: "660",
            name: "Garden Court 1",
            lat: "10.734034538269",
            lng: "106.72158050537",
          },
          {
            id: "661",
            name: "Garden Court 2",
            lat: "10.723184585571",
            lng: "106.71578979492",
          },
          {
            id: "662",
            name: "Garden Plaza 1",
            lat: "10.722661972046",
            lng: "106.71318054199",
          },
          {
            id: "663",
            name: "Garden Plaza 2",
            lat: "10.723395347595",
            lng: "106.71466064453",
          },
          {
            id: "664",
            name: "Golden King",
            lat: "10.726008415222",
            lng: "106.72389221191",
          },
          {
            id: "665",
            name: "Grand View",
            lat: "10.721883773804",
            lng: "106.71209716797",
          },
          {
            id: "666",
            name: "Green Valley",
            lat: "10.733027458191",
            lng: "106.71590423584",
          },
          {
            id: "667",
            name: "Green View",
            lat: "10.7220287323",
            lng: "106.72663116455",
          },
          {
            id: "668",
            name: "Happy Residence",
            lat: "21.0306224823",
            lng: "105.84886932373",
          },
          {
            id: "669",
            name: "Happy Valley",
            lat: "10.728944778442",
            lng: "106.70188140869",
          },
          {
            id: "670",
            name: "Happy Valley Premier",
            lat: "10.731839179993",
            lng: "106.70282745361",
          },
          {
            id: "671",
            name: "Hemera Building",
            lat: "10.730772972107",
            lng: "106.73199462891",
          },
          {
            id: "672",
            name: "Him Lam K\u00eanh T\u1ebb",
            lat: "10.741340637207",
            lng: "106.69855499268",
          },
          {
            id: "673",
            name: "Him Lam Riverside",
            lat: "10.743911743164",
            lng: "106.6953048706",
          },
          {
            id: "674",
            name: "Ho\u00e0ng Anh Gia Lai 1",
            lat: "10.740623474121",
            lng: "106.70416259766",
          },
          {
            id: "675",
            name: "Ho\u00e0ng Anh Gia Lai 2",
            lat: "10.75084400177",
            lng: "106.69967651367",
          },
          {
            id: "676",
            name: "Ho\u00e0ng Anh Thanh B\u00ecnh",
            lat: "10.745690345764",
            lng: "106.69889831543",
          },
          {
            id: "677",
            name: "Ho\u00e0ng Kim",
            lat: "10.741436004639",
            lng: "106.72992706299",
          },
          {
            id: "678",
            name: "H\u01b0ng Gia",
            lat: "10.732464790344",
            lng: "106.70937347412",
          },
          {
            id: "679",
            name: "H\u01b0ng Gia 3",
            lat: "10.732464790344",
            lng: "106.7095413208",
          },
          {
            id: "680",
            name: "H\u01b0ng Ph\u00e1t Green Star",
            lat: "10.704976081848",
            lng: "106.73589324951",
          },
          {
            id: "681",
            name: "H\u01b0ng Ph\u01b0\u1edbc",
            lat: "10.729906082153",
            lng: "106.70790100098",
          },
          {
            id: "682",
            name: "H\u01b0ng Th\u00e1i 1 & 2",
            lat: "10.734663963318",
            lng: "106.70854949951",
          },
          {
            id: "683",
            name: "H\u01b0ng V\u01b0\u1ee3ng 1",
            lat: "10.729572296143",
            lng: "106.70986938477",
          },
          {
            id: "684",
            name: "H\u01b0ng V\u01b0\u1ee3ng 2",
            lat: "10.730611801148",
            lng: "106.70964050293",
          },
          {
            id: "685",
            name: "H\u01b0ng V\u01b0\u1ee3ng 3",
            lat: "10.729234695435",
            lng: "106.70845794678",
          },
          {
            id: "686",
            name: "Incomex",
            lat: "10.751443862915",
            lng: "106.70908355713",
          },
          {
            id: "687",
            name: "Jamona City",
            lat: "10.736345291138",
            lng: "106.73973846435",
          },
          {
            id: "688",
            name: "Jamona Golden Silk",
            lat: "10.750555992127",
            lng: "106.73568725586",
          },
          {
            id: "689",
            name: "Jamona Heights",
            lat: "10.750555992127",
            lng: "106.73568725586",
          },
          {
            id: "690",
            name: "KDC Ph\u00fa M\u1ef9",
            lat: "10.713545799255",
            lng: "106.73371887207",
          },
          {
            id: "691",
            name: "Kim S\u01a1n",
            lat: "10.731839179993",
            lng: "106.70282745361",
          },
          {
            id: "692",
            name: "La Casa",
            lat: "10.7143907547",
            lng: "106.7421798706",
          },
          {
            id: "693",
            name: "Lam Thi\u00ean L\u1ee5c \u0110\u1ecba",
            lat: "10.722814559937",
            lng: "106.71273803711",
          },
          {
            id: "694",
            name: "Lavida Plus",
            lat: "10.727764129639",
            lng: "106.7014541626",
          },
          {
            id: "695",
            name: "Lotus Residence",
            lat: "10.716377258301",
            lng: "106.7407913208",
          },
          {
            id: "696",
            name: "Luxcity",
            lat: "10.738822937012",
            lng: "106.72969818115",
          },
          {
            id: "697",
            name: "LuxGarden",
            lat: "10.740355491638",
            lng: "106.7389831543",
          },
          {
            id: "698",
            name: "Luxury Home",
            lat: "10.735774040222",
            lng: "106.73860931397",
          },
          {
            id: "701",
            name: "M-One Nam S\u00e0i G\u00f2n",
            lat: "10.750825881958",
            lng: "106.70957946777",
          },
          {
            id: "699",
            name: "Midtown Ph\u00fa M\u1ef9 H\u01b0ng",
            lat: "10.724321365356",
            lng: "106.72822570801",
          },
          {
            id: "700",
            name: "Minh Th\u00e0nh",
            lat: "10.74475479126",
            lng: "106.70429992676",
          },
          {
            id: "702",
            name: "M\u1ef9 C\u1ea3nh",
            lat: "10.726870536804",
            lng: "106.71382141113",
          },
          {
            id: "703",
            name: "M\u1ef9 \u0110\u1ee9c",
            lat: "10.725026130676",
            lng: "106.70864868164",
          },
          {
            id: "704",
            name: "M\u1ef9 Gia 1",
            lat: "10.720639228821",
            lng: "106.73181915283",
          },
          {
            id: "705",
            name: "M\u1ef9 Gia 2",
            lat: "10.718254089356",
            lng: "106.72666168213",
          },
          {
            id: "706",
            name: "M\u1ef9 Giang 1&2",
            lat: "10.722883224487",
            lng: "106.71720886231",
          },
          {
            id: "707",
            name: "M\u1ef9 H\u00e0o",
            lat: "10.726888656616",
            lng: "106.71505737305",
          },
          {
            id: "708",
            name: "M\u1ef9 Ho\u00e0ng",
            lat: "10.727392196655",
            lng: "106.70978546143",
          },
          {
            id: "709",
            name: "M\u1ef9 H\u01b0ng",
            lat: "10.728044509888",
            lng: "106.71294403076",
          },
          {
            id: "710",
            name: "M\u1ef9 Khang",
            lat: "10.715998649597",
            lng: "106.73012542725",
          },
          {
            id: "711",
            name: "M\u1ef9 Kh\u00e1nh 1",
            lat: "10.721447944641",
            lng: "106.71221923828",
          },
          {
            id: "712",
            name: "M\u1ef9 Kh\u00e1nh 2",
            lat: "10.727892875671",
            lng: "106.70837402344",
          },
          {
            id: "713",
            name: "M\u1ef9 Kh\u00e1nh 3",
            lat: "10.726886749268",
            lng: "106.70827484131",
          },
          {
            id: "714",
            name: "M\u1ef9 Kh\u00e1nh 4",
            lat: "10.726899147034",
            lng: "106.70903778076",
          },
          {
            id: "715",
            name: "M\u1ef9 Kim 1,2",
            lat: "10.72483253479",
            lng: "106.70964813232",
          },
          {
            id: "716",
            name: "M\u1ef9 Kim 3",
            lat: "10.723878860474",
            lng: "106.71535491943",
          },
          {
            id: "717",
            name: "M\u1ef9 Ph\u00e1t",
            lat: "10.724047660828",
            lng: "106.70913696289",
          },
          {
            id: "718",
            name: "M\u1ef9 Ph\u00fa 1",
            lat: "10.717904090881",
            lng: "106.72719573975",
          },
          {
            id: "719",
            name: "M\u1ef9 Ph\u00fa 2",
            lat: "10.717672348023",
            lng: "106.72721862793",
          },
          {
            id: "720",
            name: "M\u1ef9 Ph\u00fa 3",
            lat: "10.71577835083",
            lng: "106.72807312012",
          },
          {
            id: "721",
            name: "M\u1ef9 Ph\u00fa Apartment Petroland",
            lat: "10.748022079468",
            lng: "106.71352386475",
          },
          {
            id: "722",
            name: "M\u1ef9 Ph\u00fac",
            lat: "10.723380088806",
            lng: "106.70973205566",
          },
          {
            id: "723",
            name: "M\u1ef9 Ph\u01b0\u1edbc",
            lat: "10.732802391052",
            lng: "106.70741271973",
          },
          {
            id: "724",
            name: "M\u1ef9 Quang",
            lat: "10.723925590515",
            lng: "106.71131896973",
          },
          {
            id: "725",
            name: "M\u1ef9 Th\u00e1i 1",
            lat: "10.718861579895",
            lng: "106.73143005371",
          },
          {
            id: "726",
            name: "M\u1ef9 Th\u00e1i 2",
            lat: "10.721493721008",
            lng: "106.72817230225",
          },
          {
            id: "727",
            name: "M\u1ef9 Th\u00e1i 3",
            lat: "10.714923858643",
            lng: "106.72922515869",
          },
          {
            id: "728",
            name: "M\u1ef9 To\u00e0n 1",
            lat: "10.727749824524",
            lng: "106.70594787598",
          },
          {
            id: "729",
            name: "M\u1ef9 To\u00e0n 2",
            lat: "10.727668762207",
            lng: "106.70748901367",
          },
          {
            id: "730",
            name: "M\u1ef9 To\u00e0n 3",
            lat: "10.72940826416",
            lng: "106.70780944824",
          },
          {
            id: "731",
            name: "M\u1ef9 T\u00fa 1",
            lat: "10.723573684692",
            lng: "106.71393585205",
          },
          {
            id: "732",
            name: "M\u1ef9 T\u00fa 2",
            lat: "10.723214149475",
            lng: "106.71332550049",
          },
          {
            id: "733",
            name: "M\u1ef9 T\u00fa 3",
            lat: "10.722677230835",
            lng: "106.71216583252",
          },
          {
            id: "734",
            name: "M\u1ef9 V\u0103n 1&2",
            lat: "10.722471237183",
            lng: "106.72734832764",
          },
          {
            id: "735",
            name: "M\u1ef9 Vi\u00ean",
            lat: "10.717802047729",
            lng: "106.72941589356",
          },
          {
            id: "736",
            name: "Nam \u0110\u00f4 Ph\u00fa M\u1ef9 H\u01b0ng",
            lat: "10.721673965454",
            lng: "106.73124694824",
          },
          {
            id: "737",
            name: "Nam Khang",
            lat: "10.715161323547",
            lng: "106.72978210449",
          },
          {
            id: "738",
            name: "Nam Long 1",
            lat: "10.726933479309",
            lng: "106.70944976807",
          },
          {
            id: "739",
            name: "Nam Long 2",
            lat: "10.725917816162",
            lng: "106.70980072022",
          },
          {
            id: "740",
            name: "Nam Long 3",
            lat: "10.726530075073",
            lng: "106.70967102051",
          },
          {
            id: "741",
            name: "Nam Long Ph\u00fa Thu\u1eadn",
            lat: "10.730423927307",
            lng: "106.73532104492",
          },
          {
            id: "742",
            name: "Nam Long Tr\u1ea7n Tr\u1ecdng Cung",
            lat: "10.743570327759",
            lng: "106.73300170898",
          },
          {
            id: "743",
            name: "Nam Phu Villas",
            lat: "10.744510650635",
            lng: "106.73680114746",
          },
          {
            id: "744",
            name: "Nam Ph\u00fac - Le Jardin",
            lat: "10.723278045654",
            lng: "106.73001861572",
          },
          {
            id: "745",
            name: "Nam Quang",
            lat: "10.723917007446",
            lng: "106.70973205566",
          },
          {
            id: "746",
            name: "Nam Quang 2",
            lat: "10.723628997803",
            lng: "106.71239471435",
          },
          {
            id: "747",
            name: "Nam Thi\u00ean 1,2,3",
            lat: "10.726922988892",
            lng: "106.71109771728",
          },
          {
            id: "748",
            name: "Nam Th\u00f4ng 1 - Ph\u00fa M\u1ef9 H\u01b0ng",
            lat: "10.719794273377",
            lng: "106.73275756836",
          },
          {
            id: "749",
            name: "Nam Th\u00f4ng 2",
            lat: "10.716694831848",
            lng: "106.73054504394",
          },
          {
            id: "750",
            name: "Nam Th\u00f4ng 3",
            lat: "10.719520568848",
            lng: "106.73251342773",
          },
          {
            id: "751",
            name: "Nam Th\u00f4ng-KDC Nam Long",
            lat: "10.743593215942",
            lng: "106.73544311523",
          },
          {
            id: "752",
            name: "Nam Vi\u00ean",
            lat: "10.723279953003",
            lng: "106.72918701172",
          },
          {
            id: "753",
            name: "Ng\u1ecdc Lan Savimex",
            lat: "10.732104301453",
            lng: "106.73751068115",
          },
          {
            id: "754",
            name: "Park View",
            lat: "10.721869468689",
            lng: "106.71747589111",
          },
          {
            id: "755",
            name: "PetroLand Tower",
            lat: "10.729439735413",
            lng: "106.72329711914",
          },
          {
            id: "756",
            name: "Ph\u00fa Gia",
            lat: "10.725512504578",
            lng: "106.71377563477",
          },
          {
            id: "757",
            name: "Ph\u00fa M\u1ef9 2",
            lat: "10.708225250244",
            lng: "106.73336791992",
          },
          {
            id: "758",
            name: "Ph\u00fa M\u1ef9 An",
            lat: "10.7389793396",
            lng: "106.72814178467",
          },
          {
            id: "759",
            name: "Ph\u00fa M\u1ef9 Ch\u1ee3 L\u1edbn",
            lat: "10.706418991089",
            lng: "106.72917175293",
          },
          {
            id: "760",
            name: "Ph\u00fa M\u1ef9 H\u01b0ng",
            lat: "10.727806091309",
            lng: "106.71489715576",
          },
          {
            id: "761",
            name: "Q7 Saigon Riverside",
            lat: "10.718033790588",
            lng: "106.74239349365",
          },
          {
            id: "762",
            name: "Qu\u1ed1c C\u01b0\u1eddng Gia Lai 1",
            lat: "10.751481056213",
            lng: "106.7091293335",
          },
          {
            id: "763",
            name: "Res III",
            lat: "10.734942436218",
            lng: "106.71835327148",
          },
          {
            id: "764",
            name: "Richlane Residence",
            lat: "10.728944778442",
            lng: "106.70188140869",
          },
          {
            id: "765",
            name: "River City",
            lat: "10.7371301651",
            lng: "106.74011230469",
          },
          {
            id: "766",
            name: "River Panorama",
            lat: "10.714189529419",
            lng: "106.73777770996",
          },
          {
            id: "767",
            name: "Riverpark Residence",
            lat: "10.726121902466",
            lng: "106.70742034912",
          },
          {
            id: "768",
            name: "Riverside Residence",
            lat: "10.719802856445",
            lng: "106.72736358643",
          },
          {
            id: "769",
            name: "Riviera Point",
            lat: "10.732268333435",
            lng: "106.72880554199",
          },
          {
            id: "770",
            name: "Sadeco ven s\u00f4ng",
            lat: "10.726566314697",
            lng: "106.69413757324",
          },
          {
            id: "771",
            name: "S\u00e0i G\u00f2n Paragon",
            lat: "10.729515075684",
            lng: "106.72190093994",
          },
          {
            id: "772",
            name: "Saigon Plaza Tower",
            lat: "10.714839935303",
            lng: "106.73709869385",
          },
          {
            id: "773",
            name: "Saigon South Plaza",
            lat: "10.713933944702",
            lng: "106.73093414307",
          },
          {
            id: "774",
            name: "Savimex Ph\u00fa Thu\u1eadn",
            lat: "10.728085517883",
            lng: "106.73552703857",
          },
          {
            id: "775",
            name: "SC VivoCity",
            lat: "10.730116844177",
            lng: "106.70349121094",
          },
          {
            id: "776",
            name: "Scenic Valley",
            lat: "10.731781959534",
            lng: "106.71672821045",
          },
          {
            id: "777",
            name: "Scenic Valley 2",
            lat: "10.731369018555",
            lng: "106.71810150147",
          },
          {
            id: "778",
            name: "Seven Diamond",
            lat: "10.741134643555",
            lng: "106.70881652832",
          },
          {
            id: "779",
            name: "Sky Garden 3",
            lat: "10.732933998108",
            lng: "106.70638275147",
          },
          {
            id: "780",
            name: "Sky Garden I",
            lat: "10.730993270874",
            lng: "106.70673370361",
          },
          {
            id: "781",
            name: "Sky Garden II",
            lat: "10.729200363159",
            lng: "106.70616912842",
          },
          {
            id: "782",
            name: "Southern Palace",
            lat: "10.734457015991",
            lng: "106.72267913818",
          },
          {
            id: "783",
            name: "Star Hill",
            lat: "10.73091506958",
            lng: "106.7262802124",
          },
          {
            id: "784",
            name: "Sunrise City",
            lat: "10.73862361908",
            lng: "106.70059967041",
          },
          {
            id: "785",
            name: "Sunrise City View",
            lat: "10.745357513428",
            lng: "106.70084381103",
          },
          {
            id: "786",
            name: "Sunriver",
            lat: "10.741206169128",
            lng: "106.70581054688",
          },
          {
            id: "787",
            name: "T\u00e2n An Huy",
            lat: "10.735085487366",
            lng: "106.70343780518",
          },
          {
            id: "788",
            name: "T\u00e2n M\u1ef9",
            lat: "10.735304832458",
            lng: "106.71876525879",
          },
          {
            id: "789",
            name: "T\u00e2n Th\u00e0nh l\u1eadp",
            lat: "10.703401565552",
            lng: "106.72997283935",
          },
          {
            id: "790",
            name: "T\u1ea5n Tr\u01b0\u1eddng",
            lat: "10.732469558716",
            lng: "106.73853302002",
          },
          {
            id: "791",
            name: "The Era Town",
            lat: "10.699416160583",
            lng: "106.73012542725",
          },
          {
            id: "792",
            name: "The EverRich III",
            lat: "10.728132247925",
            lng: "106.72913360596",
          },
          {
            id: "793",
            name: "The Golden Star",
            lat: "10.737909317017",
            lng: "106.72645568848",
          },
          {
            id: "794",
            name: "The Grande",
            lat: "10.723898887634",
            lng: "106.72782897949",
          },
          {
            id: "795",
            name: "The Mark",
            lat: "10.735365867615",
            lng: "106.71587371826",
          },
          {
            id: "796",
            name: "The Panorama",
            lat: "10.721817970276",
            lng: "106.71401977539",
          },
          {
            id: "797",
            name: "The Point qu\u1eadn 7",
            lat: "10.726363182068",
            lng: "106.7336730957",
          },
          {
            id: "798",
            name: "The Signature",
            lat: "10.730867385864",
            lng: "106.72051239014",
          },
          {
            id: "799",
            name: "Thi\u00ean Ph\u00fa Gia",
            lat: "10.75178527832",
            lng: "106.70503234863",
          },
          {
            id: "800",
            name: "Thi\u00ean S\u01a1n Plaza",
            lat: "10.731790542602",
            lng: "106.71922302246",
          },
          {
            id: "801",
            name: "Tulip Tower",
            lat: "10.7143907547",
            lng: "106.73749542236",
          },
          {
            id: "802",
            name: "USilk Apartment",
            lat: "10.741176605225",
            lng: "106.70559692383",
          },
          {
            id: "804",
            name: "V-Star",
            lat: "10.732625007629",
            lng: "106.73900604248",
          },
          {
            id: "803",
            name: "Vincity qu\u1eadn 7",
            lat: "10.734034538269",
            lng: "106.72158050537",
          },
        ],
      },
      {
        id: "20",
        name: "Qu\u1eadn 8",
        wards: [
          { id: "260", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "261", name: "10", prefix: "Ph\u01b0\u1eddng" },
          { id: "262", name: "11", prefix: "Ph\u01b0\u1eddng" },
          { id: "263", name: "12", prefix: "Ph\u01b0\u1eddng" },
          { id: "264", name: "13", prefix: "Ph\u01b0\u1eddng" },
          { id: "265", name: "14", prefix: "Ph\u01b0\u1eddng" },
          { id: "266", name: "15", prefix: "Ph\u01b0\u1eddng" },
          { id: "267", name: "16", prefix: "Ph\u01b0\u1eddng" },
          { id: "268", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "269", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "270", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "271", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "272", name: "6", prefix: "Ph\u01b0\u1eddng" },
          { id: "273", name: "7", prefix: "Ph\u01b0\u1eddng" },
          { id: "274", name: "8", prefix: "Ph\u01b0\u1eddng" },
          { id: "275", name: "9", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "5322", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5323", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5324", name: "1011", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5325", name: "102", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5326", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5327", name: "1107", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5328", name: "111", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5329", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5330", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5331", name: "130", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5332", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5333", name: "141", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5334", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5335", name: "152", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5336", name: "152A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5337", name: "152B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5338", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5339", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5340", name: "172", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5341", name: "19/8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5342", name: "192", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5343", name: "198", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5344", name: "19C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5345", name: "1D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5346", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5347", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5348", name: "204", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5349", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5350", name: "218", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5351", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5352", name: "232", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5353", name: "233", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5354", name: "239", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5355", name: "24", prefix: "Ph\u1ed1" },
          { id: "5356", name: "25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5357", name: "26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5358", name: "266", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5359", name: "267", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5360", name: "267A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5361", name: "267B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5362", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5363", name: "270", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5364", name: "284", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5365", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5366", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5367", name: "3052A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5368", name: "31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5369", name: "3158B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5370", name: "318", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5371", name: "320", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5372", name: "320A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5373", name: "330", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5374", name: "332", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5375", name: "3643C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5376", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5377", name: "41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5378", name: "42", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5379", name: "44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5380", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5381", name: "48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5382", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5383", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5384", name: "54", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5385", name: "56", prefix: "Ph\u1ed1" },
          { id: "5386", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5387", name: "60", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5388", name: "643", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5389", name: "66", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5390", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5391", name: "715", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5392", name: "74", prefix: "Ph\u1ed1" },
          { id: "5393", name: "783", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5394", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5395", name: "817", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5396", name: "817A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5397", name: "843", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5398", name: "892", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5399", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5400", name: "902", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5401", name: "909", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5402", name: "92", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5403", name: "9A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5404", name: "A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5405", name: "A1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5406",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5407", name: "An H\u1ea3i 4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5408",
            name: "An Ph\u00fa \u0110\u00f4ng 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5409",
            name: "An Ph\u00fa T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5410",
            name: "\u00c2u D\u01b0\u01a1ng L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5411",
            name: "Ba \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5412", name: "Ba T\u01a1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5413",
            name: "B\u00e0u H\u1ea1t 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5414",
            name: "B\u00e0u Tr\u1ea3ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5415",
            name: "B\u1ebfn Ba \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5416",
            name: "B\u1ebfn B\u00ecnh \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5417",
            name: "B\u1ebfn B\u00ecnh \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5418",
            name: "B\u1ebfn M\u1ec5 C\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5419",
            name: "B\u1ebfn Nguy\u1ec5n Duy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5420",
            name: "B\u1ebfn Ph\u00fa \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5421",
            name: "B\u00ecnh \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5422",
            name: "B\u00ecnh \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5423",
            name: "B\u00ecnh \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5424",
            name: "B\u00ecnh H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5425", name: "B\u00f4ng Sao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5426",
            name: "B\u00f9i Huy B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5427",
            name: "B\u00f9i Minh Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5428", name: "C1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5429", name: "C2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5430", name: "C3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5431", name: "C4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5432", name: "C5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5433", name: "C6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5434", name: "C7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5435", name: "C7B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5436", name: "C7C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5437", name: "C8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5438",
            name: "C\u1ea7n Giu\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5439", name: "Cao L\u1ed7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5440",
            name: "Cao Xu\u00e2n D\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5441", name: "C\u00e2y Sung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5442",
            name: "Ch\u00e1nh H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5443",
            name: "C\u00f9 Lao Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5444", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5445", name: "D15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5446", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5447", name: "D4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5448", name: "D9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5449", name: "D\u1ea1 Nam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5450",
            name: "D\u00e3 T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5462",
            name: "D\u01b0\u01a1ng B\u00e1 Tr\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5463",
            name: "D\u01b0\u01a1ng \u0110\u00ecnh H\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5464",
            name: "D\u01b0\u01a1ng Kh\u1ea3 Tr\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5465",
            name: "D\u01b0\u01a1ng Quang \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5451",
            name: "\u0110\u1ea1i L\u1ed9 \u0110\u00f4ng T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5452",
            name: "\u0110\u1eb7ng Ch\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5453",
            name: "\u0110\u1eb7ng Th\u00f9y Tr\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5454",
            name: "\u0110\u00e0o Cam M\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5455",
            name: "\u0110\u00ecnh An T\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5456",
            name: "\u0110inh H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5457",
            name: "\u0110\u1ed3ng Di\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5458",
            name: "\u0110\u00f4ng H\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5459",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5460", name: "\u0110T 741", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5461", name: "\u0110T 747", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5466", name: "E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5467", name: "E2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5468", name: "F", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5469",
            name: "H\u00e0n Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5470",
            name: "H\u1eadu L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5471",
            name: "Hi\u1ec7p Th\u00e0nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5472",
            name: "H\u1ed3 Bi\u1ec3u Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5473",
            name: "H\u1ed3 H\u1ecdc L\u00e3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5474",
            name: "H\u1ed3 Ng\u1ecdc L\u00e3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5475",
            name: "Ho\u00e0i Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5476",
            name: "Ho\u00e0ng \u0110\u1ea1o Th\u00fay",
            prefix: "Ph\u1ed1",
          },
          {
            id: "5477",
            name: "Ho\u00e0ng Kim Giao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5478",
            name: "Ho\u00e0ng Minh \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5479",
            name: "Ho\u00e0ng S\u0129 Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5480",
            name: "H\u01b0ng Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5481",
            name: "H\u01b0ng Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5482",
            name: "Hu\u1ef3nh B\u00e1 Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5483",
            name: "Hu\u1ef3nh Th\u1ecb Ph\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5484",
            name: "Kh\u1edfi Ngh\u0129a B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5485",
            name: "L\u00ea Quang Kim",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5486",
            name: "L\u00ea Th\u00e0nh Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5487",
            name: "Li\u00ean T\u1ec9nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5488",
            name: "Li\u00ean T\u1ec9nh 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5489",
            name: "L\u1ed9c H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5490",
            name: "L\u01b0\u01a1ng Ng\u1ecdc Quy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5491",
            name: "L\u01b0\u01a1ng V\u0103n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5492",
            name: "L\u01b0u H\u1eefu Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5493",
            name: "L\u01b0u Qu\u00fd K\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5494",
            name: "L\u00fd \u0110\u1ea1o Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5495",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5496",
            name: "M\u1ea1c V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5497", name: "Mai Am", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5498", name: "Mai H\u1eafc \u0110\u1ebf", prefix: "Ph\u1ed1" },
          {
            id: "5499",
            name: "M\u1ec5 C\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5500",
            name: "M\u01b0\u01a1ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5501",
            name: "Ng\u00f4 S\u1ef9 Li\u00ean",
            prefix: "Ph\u1ed1",
          },
          {
            id: "5502",
            name: "Nguy\u1ec5n Ch\u1ebf Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5503",
            name: "Nguy\u1ec5n Duy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5504",
            name: "Nguy\u1ec5n Huy Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5505",
            name: "Nguy\u1ec5n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5506",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5507",
            name: "Nguy\u1ec5n Ng\u1ecdc Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5508",
            name: "Nguy\u1ec5n Nh\u01b0\u1ee3c Thi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5509",
            name: "Nguy\u1ec5n Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5510",
            name: "Nguy\u1ec5n S\u1ef9 C\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5511",
            name: "Nguy\u1ec5n Th\u1ecb T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5512",
            name: "Nguy\u1ec5n Th\u1ecb Th\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5513",
            name: "Nguy\u1ec5n Trung Ng\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5514",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5515",
            name: "Nguy\u1ec5n V\u0103n C\u1ee7a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5516",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5517",
            name: "N\u00fai M\u1ed9t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5518", name: "P4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5519",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5520",
            name: "Ph\u1ea1m Th\u1ebf Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5521",
            name: "Phong Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5522",
            name: "Ph\u00fa \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5523",
            name: "Ph\u00fa Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5524",
            name: "Qu\u1ed1c L\u1ed9 50",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5525",
            name: "Quy \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5526",
            name: "R\u1ea1ch C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5527",
            name: "R\u1ea1ch C\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5528",
            name: "R\u1ea1ch N\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5529",
            name: "R\u1ea1ch \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5530", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5531",
            name: "S\u1ed1 1 KDC Hi\u1ec7p \u00c2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5532", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5533", name: "S\u1ed1 1011", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5534", name: "S\u1ed1 102", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5535", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5536", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5537", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5538", name: "S\u1ed1 130", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5539", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5540", name: "S\u1ed1 142", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5541", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5542", name: "S\u1ed1 152", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5543", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5544", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5545", name: "S\u1ed1 192", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5546", name: "S\u1ed1 198", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5547", name: "S\u1ed1 1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5548", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5549", name: "S\u1ed1 21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5550", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5551", name: "S\u1ed1 23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5552", name: "S\u1ed1 232", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5553", name: "S\u1ed1 239", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5554", name: "S\u1ed1 25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5555", name: "S\u1ed1 26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5556", name: "S\u1ed1 270", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5557", name: "S\u1ed1 284", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5558", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5559", name: "S\u1ed1 31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5560", name: "S\u1ed1 318", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5561", name: "s\u1ed1 332", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5562", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5563", name: "S\u1ed1 41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5564", name: "S\u1ed1 42", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5565", name: "S\u1ed1 44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5566", name: "S\u1ed1 48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5567", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5568", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5569", name: "S\u1ed1 635", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5570", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5571", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5572", name: "S\u1ed1 84", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5573", name: "S\u1ed1 8A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5574", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5575", name: "S\u1ed1 911", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5576", name: "S\u1ed1 P4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5577", name: "T\u1ea1 Quang B\u1eedu", prefix: "Ph\u1ed1" },
          { id: "5578", name: "T\u00e1m Danh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5579",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5580",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5581",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5582",
            name: "Th\u1ea1nh L\u1ed9c 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5583",
            name: "Th\u1ea1nh Xu\u00e2n 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5584",
            name: "Th\u1ea1nh Xu\u00e2n 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5585",
            name: "Th\u00edch B\u1eedu \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5586",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5587",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "5588",
            name: "Tr\u1ea7n Quang \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5589",
            name: "Tr\u1ea7n V\u0103n Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5590",
            name: "Tr\u1ea7n V\u0103n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5591",
            name: "Tr\u00edch S\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5592",
            name: "Tr\u1ecbnh Quang Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5593",
            name: "Tr\u01b0\u01a1ng \u0110\u00ecnh H\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5594",
            name: "Tr\u01b0\u01a1ng V\u0103n \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5595",
            name: "T\u00f9ng Thi\u1ec7n V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5596",
            name: "Tuy L\u00fd V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5597",
            name: "\u1ee4 C\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5598", name: "\u01afu Long", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5599",
            name: "V\u00e0nh \u0110ai 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5600", name: "V\u0129nh Nam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5601",
            name: "V\u00f5 Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5602",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5603", name: "Xa La", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5604",
            name: "X\u00f3m C\u1ee7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "805",
            name: "51 Cha\u0301nh H\u01b0ng",
            lat: "10.738349914551",
            lng: "106.66819763184",
          },
          {
            id: "806",
            name: "Asa Light",
            lat: "10.740220069885",
            lng: "106.67190551758",
          },
          {
            id: "807",
            name: "Aurora Residences",
            lat: "10.73425579071",
            lng: "106.63607025147",
          },
          {
            id: "808",
            name: "Bella Vista",
            lat: "10.744780540466",
            lng: "106.66519165039",
          },
          {
            id: "809",
            name: "B\u00ecnh \u0110\u0103ng",
            lat: "10.733424186707",
            lng: "106.65528869629",
          },
          {
            id: "810",
            name: "B\u00ecnh \u0110\u00f4ng Xanh",
            lat: "10.745994567871",
            lng: "106.65669250488",
          },
          {
            id: "811",
            name: "B\u00f4ng Sao",
            lat: "10.73680305481",
            lng: "106.66087341309",
          },
          {
            id: "812",
            name: "Cara Riverview",
            lat: "10.720841407776",
            lng: "106.61875152588",
          },
          {
            id: "813",
            name: "Carina Plaza",
            lat: "10.729211807251",
            lng: "106.62342071533",
          },
          {
            id: "814",
            name: "Central Premium",
            lat: "10.737226486206",
            lng: "106.67050933838",
          },
          {
            id: "815",
            name: "Ch\u00e1nh H\u01b0ng Apartment",
            lat: "10.734752655029",
            lng: "106.66300201416",
          },
          {
            id: "816",
            name: "Ch\u00e1nh H\u01b0ng Giai Vi\u1ec7t",
            lat: "10.736810684204",
            lng: "106.66925811768",
          },
          {
            id: "817",
            name: "Chung c\u01b0 M\u1ef9 Thu\u1eadn",
            lat: "10.731193542481",
            lng: "106.62295532227",
          },
          {
            id: "818",
            name: "Chung c\u01b0 Thanh Nh\u1ef1t",
            lat: "10.714577674866",
            lng: "106.63067626953",
          },
          {
            id: "819",
            name: "City Gate Towers",
            lat: "10.727749824524",
            lng: "106.62387084961",
          },
          {
            id: "820",
            name: "City Gate Towers 2",
            lat: "10.729842185974",
            lng: "106.62393188477",
          },
          {
            id: "821",
            name: "City Mall",
            lat: "10.697508811951",
            lng: "106.6183013916",
          },
          {
            id: "822",
            name: "Conic L\u1ed9c Ph\u00e1t",
            lat: "10.734662055969",
            lng: "106.66490936279",
          },
          {
            id: "823",
            name: "Diamond Lotus",
            lat: "10.747282981873",
            lng: "106.67645263672",
          },
          {
            id: "825",
            name: "Dream Home Palace",
            lat: "10.707181930542",
            lng: "106.62365722656",
          },
          {
            id: "826",
            name: "Dream Home Riverside",
            lat: "10.719096183777",
            lng: "106.65570068359",
          },
          {
            id: "824",
            name: "\u0110\u1ed3ng Di\u1ec1u",
            lat: "10.739930152893",
            lng: "106.67802429199",
          },
          {
            id: "827",
            name: "\u0110\u1ee9c Long Newland",
            lat: "10.733022689819",
            lng: "106.65194702148",
          },
          {
            id: "828",
            name: "Felisa Riverside",
            lat: "10.734300613403",
            lng: "106.6361618042",
          },
          {
            id: "829",
            name: "Good House Apartment",
            lat: "10.718138694763",
            lng: "106.62168121338",
          },
          {
            id: "830",
            name: "Green River qu\u1eadn 8",
            lat: "10.732636451721",
            lng: "106.64310455322",
          },
          {
            id: "831",
            name: "Harbor City",
            lat: "10.710288047791",
            lng: "106.61108398438",
          },
          {
            id: "832",
            name: "Heaven Riverview",
            lat: "10.718474388123",
            lng: "106.62302398682",
          },
          {
            id: "833",
            name: "High Intela",
            lat: "10.727546691894",
            lng: "106.62245941162",
          },
          {
            id: "834",
            name: "Him Lam Nam Kh\u00e1nh",
            lat: "10.734689712524",
            lng: "106.66825866699",
          },
          {
            id: "835",
            name: "KDC An Ph\u01b0\u1edbc",
            lat: "10.718281745911",
            lng: "106.62174987793",
          },
          {
            id: "836",
            name: "KDC Ch\u1ee3 B\u00ecnh \u0110i\u1ec1n",
            lat: "10.697911262512",
            lng: "106.61169433594",
          },
          {
            id: "837",
            name: "Khang Gia",
            lat: "10.74072933197",
            lng: "106.66923522949",
          },
          {
            id: "838",
            name: "Nam Ph\u00e1t Riverside",
            lat: "10.699279785156",
            lng: "106.62261199951",
          },
          {
            id: "839",
            name: "NBB Garden III",
            lat: "10.715403556824",
            lng: "106.62250518799",
          },
          {
            id: "840",
            name: "Ng\u1ecdc Ph\u01b0\u01a1ng Nam",
            lat: "10.743633270264",
            lng: "106.68511199951",
          },
          {
            id: "841",
            name: "Osaka Garden",
            lat: "10.699507713318",
            lng: "106.62257385254",
          },
          {
            id: "842",
            name: "Peridot Building",
            lat: "10.731771469116",
            lng: "106.62280273438",
          },
          {
            id: "843",
            name: "Ph\u00fa L\u1ee3i",
            lat: "10.702758789062",
            lng: "106.62162780762",
          },
          {
            id: "844",
            name: "Ph\u00fa Th\u1ecbnh Riverside",
            lat: "10.702631950378",
            lng: "106.61897277832",
          },
          {
            id: "845",
            name: "R\u1ea1ch L\u00e0o",
            lat: "10.731200218201",
            lng: "106.63472747803",
          },
          {
            id: "846",
            name: "RCL B\u00ecnh \u0110\u00f4ng",
            lat: "10.749927520752",
            lng: "106.6655960083",
          },
          {
            id: "847",
            name: "RCL T\u1ea1 Quang B\u1eedu",
            lat: "10.734662055969",
            lng: "106.66490936279",
          },
          {
            id: "848",
            name: "Saigon Skyview",
            lat: "10.733350753784",
            lng: "106.65539550781",
          },
          {
            id: "849",
            name: "Samland Giai Vi\u1ec7t",
            lat: "10.736555099487",
            lng: "106.66951751709",
          },
          {
            id: "850",
            name: "Sao Mai",
            lat: "10.725149154663",
            lng: "106.62618255615",
          },
          {
            id: "851",
            name: "Sovrano Plaza",
            lat: "10.698369026184",
            lng: "106.62442016602",
          },
          {
            id: "852",
            name: "Sunshine Avenue",
            lat: "10.72548866272",
            lng: "106.61959075928",
          },
          {
            id: "853",
            name: "Tara Residence",
            lat: "10.734134674072",
            lng: "106.65579223633",
          },
          {
            id: "854",
            name: "The Avila",
            lat: "10.721154212952",
            lng: "106.61985778809",
          },
          {
            id: "855",
            name: "The Pega Suite",
            lat: "10.734172821045",
            lng: "106.6535949707",
          },
          {
            id: "856",
            name: "The PegaSuite 2",
            lat: "10.744507789612",
            lng: "106.68532562256",
          },
          {
            id: "857",
            name: "The Sun City Ba T\u01a1",
            lat: "10.704745292664",
            lng: "106.6269454956",
          },
          {
            id: "858",
            name: "Topaz City",
            lat: "10.739453315735",
            lng: "106.67981719971",
          },
          {
            id: "859",
            name: "Topaz Elite",
            lat: "10.744084358215",
            lng: "106.67591094971",
          },
          {
            id: "860",
            name: "Tr\u01b0\u01a1ng \u0110\u00ecnh H\u1ed9i 2",
            lat: "10.721086502075",
            lng: "106.62126922607",
          },
          {
            id: "861",
            name: "Tr\u01b0\u01a1ng \u0110\u00ecnh H\u1ed9i 3",
            lat: "10.720627784729",
            lng: "106.62493896484",
          },
          {
            id: "862",
            name: "VHome",
            lat: "10.720517158508",
            lng: "106.62300872803",
          },
          {
            id: "863",
            name: "West Intela",
            lat: "10.73291015625",
            lng: "106.62268829346",
          },
          {
            id: "864",
            name: "Zen Home",
            lat: "10.70880317688",
            lng: "106.62692260742",
          },
        ],
      },
      {
        id: "21",
        name: "Qu\u1eadn 9",
        wards: [
          { id: "276", name: "Hi\u1ec7p Ph\u00fa", prefix: "Ph\u01b0\u1eddng" },
          { id: "277", name: "Long B\u00ecnh", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "278",
            name: "Long Ph\u01b0\u1edbc",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "279",
            name: "Long Th\u1ea1nh M\u1ef9",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "280",
            name: "Long Tr\u01b0\u1eddng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "281", name: "Ph\u00fa H\u1eefu", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "282",
            name: "Ph\u01b0\u1edbc B\u00ecnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "283",
            name: "Ph\u01b0\u1edbc Long A",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "284",
            name: "Ph\u01b0\u1edbc Long B",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "285", name: "T\u00e2n Ph\u00fa", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "286",
            name: "T\u0103ng Nh\u01a1n Ph\u00fa A",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "287",
            name: "T\u0103ng Nh\u01a1n Ph\u00fa B",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "288",
            name: "Tr\u01b0\u1eddng Th\u1ea1nh",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "5605", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5606", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5607", name: "100", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5608", name: "100A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5609", name: "102", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5610", name: "1021", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5611", name: "1022", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5612", name: "1025", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5613", name: "103", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5614", name: "104", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5615", name: "106", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5616", name: "108", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5617", name: "1088", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5618", name: "109", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5619", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5620", name: "110", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5621", name: "1106", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5622", name: "112", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5623", name: "114", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5624", name: "119", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5625", name: "11A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5626", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5627", name: "120", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5628", name: "121", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5629", name: "123", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5630", name: "127D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5631", name: "128", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5632", name: "129", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5633", name: "12A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5634", name: "12B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5635", name: "12C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5636", name: "12E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5637", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5638", name: "1315", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5639", name: "132", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5640", name: "135", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5641", name: "137", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5642", name: "138", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5643", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5644", name: "140", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5645", name: "141", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5646", name: "144", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5647", name: "145", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5648", name: "146", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5649", name: "147", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5650", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5651", name: "150", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5652", name: "154", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5653", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5654", name: "160", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5655", name: "161", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5656", name: "167", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5657", name: "168", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5658", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5659", name: "175", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5660", name: "176", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5661", name: "179", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5662", name: "17D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5663", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5664", name: "182", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5665", name: "185", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5666", name: "187", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5667", name: "189", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5668", name: "18A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5669", name: "18B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5670", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5671", name: "192", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5672", name: "197", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5673", name: "198", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5674", name: "1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5675", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5676", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5677", name: "200", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5678", name: "201", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5679", name: "202", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5680", name: "205A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5681", name: "206", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5682", name: "207", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5683", name: "208", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5684", name: "20A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5685", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5686", name: "210", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5687", name: "210A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5688", name: "211", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5689", name: "212", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5690", name: "215", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5691", name: "218", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5692", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5693", name: "220", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5694", name: "222", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5695", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5696", name: "231", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5697", name: "236", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5698", name: "238", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5699", name: "239", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5700", name: "24", prefix: "Ph\u1ed1" },
          { id: "5701", name: "244", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5702", name: "245", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5703", name: "248/1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5704", name: "25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5705", name: "251", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5706", name: "26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5707", name: "26/3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5708", name: "261", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5709", name: "263", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5710", name: "265", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5711", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5712", name: "270", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5713", name: "270A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5714", name: "270B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5715", name: "275", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5716", name: "278", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5717", name: "279", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5718", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5719", name: "288", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5720", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5721", name: "290", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5722", name: "295", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5723", name: "297", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5724", name: "2A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5725", name: "2B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5726", name: "2C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5727", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5728", name: "30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5729", name: "300", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5730", name: "31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5731", name: "311", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5732", name: "316", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5733", name: "319", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5734", name: "32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5735", name: "32D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5736", name: "33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5737", name: "339", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5738", name: "35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5739", name: "359", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5740", name: "36", prefix: "Ph\u1ed1" },
          { id: "5741", name: "360", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5742", name: "37", prefix: "Ph\u1ed1" },
          { id: "5743", name: "379", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5744", name: "38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5745", name: "385", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5746", name: "387", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5747", name: "39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5748", name: "397", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5749", name: "3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5750", name: "3B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5751", name: "3C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5752", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5753", name: "40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5754", name: "400", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5755", name: "409", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5756", name: "41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5757", name: "420", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5758", name: "426", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5759", name: "429", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5760", name: "43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5761", name: "44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5762", name: "440", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5763", name: "441", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5764", name: "442", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5765", name: "447", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5766", name: "447B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5767", name: "448", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5768", name: "449", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5769", name: "454", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5770", name: "455", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5771", name: "46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5772", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5773", name: "475", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5774", name: "48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5775", name: "49", prefix: "Ph\u1ed1" },
          { id: "5776", name: "491", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5777", name: "494", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5778", name: "495", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5779", name: "499", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5780", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5781", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5782", name: "500", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5783", name: "51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5784", name: "52", prefix: "Ph\u1ed1" },
          { id: "5785", name: "539", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5786", name: "545", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5787", name: "546", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5788", name: "55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5789", name: "567", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5790", name: "568", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5791", name: "58", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5792", name: "588", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5793", name: "591", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5794", name: "5A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5795", name: "5B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5796", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5797", name: "60", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5798", name: "61", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5799", name: "622", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5800", name: "623", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5801", name: "624", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5802", name: "63", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5803", name: "65", prefix: "Ph\u1ed1" },
          { id: "5804", name: "659", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5805", name: "66", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5806", name: "671", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5807", name: "672", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5808", name: "68", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5809", name: "680", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5810", name: "6A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5811", name: "6B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5812", name: "6C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5813", name: "6D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5814", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5815", name: "71", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5816", name: "711", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5817", name: "72", prefix: "Ph\u1ed1" },
          { id: "5818", name: "73", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5819", name: "734", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5820", name: "74", prefix: "Ph\u1ed1" },
          { id: "5821", name: "76", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5822", name: "767", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5823", name: "77", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5824", name: "78", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5825", name: "783", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5826", name: "785", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5827", name: "79", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5828", name: "7A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5829", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5830", name: "80", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5831", name: "811", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5832", name: "816", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5833", name: "817", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5834", name: "822", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5835", name: "826", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5836", name: "827", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5837", name: "835", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5838", name: "836", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5839", name: "84", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5840", name: "85", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5841", name: "86", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5842", name: "882", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5843", name: "89", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5844", name: "898", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5845", name: "8A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5846", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5847", name: "904", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5848", name: "94", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5849", name: "96", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5850", name: "963", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5851", name: "970", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5852", name: "976", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5853", name: "98", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5854", name: "980", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5855", name: "99", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5856", name: "990", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5857", name: "9A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5858", name: "9B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5859", name: "A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5860", name: "A1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5861", name: "A2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5862", name: "A3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5863", name: "A4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5864", name: "A6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5865",
            name: "An Ph\u00fa \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5866",
            name: "An Ph\u00fa \u0110\u00f4ng 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5867", name: "B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5868", name: "B1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5869", name: "B2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5870",
            name: "B\u1eafc R\u1ea1ch Chi\u1ebfc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5871",
            name: "B\u00e0u C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5872",
            name: "B\u00f9i Qu\u1ed1c Kh\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5873",
            name: "B\u00f9i X\u01b0\u01a1ng Tr\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5874",
            name: "B\u01b0ng \u00d4ng Tho\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5875", name: "C1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5876", name: "C18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5877", name: "C2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5878", name: "C3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5879",
            name: "C\u1ea7u \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5880",
            name: "C\u1ea7u X\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5881",
            name: "C\u1ea7u X\u00e2y 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5882",
            name: "C\u1ea7u X\u00e2y 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5883",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5884",
            name: "C\u00f4 B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5885", name: "C\u00f4 Giang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5886",
            name: "C\u00f4ng Tr\u01b0\u1eddng An \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5887", name: "CT01", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5888",
            name: "C\u00f9 Lao Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5889", name: "D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5890", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5891", name: "D10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5892", name: "D100", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5893", name: "D11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5894", name: "D11B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5895", name: "D12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5896", name: "D13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5897", name: "D14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5898", name: "D15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5899", name: "D16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5900", name: "D17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5901", name: "D19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5902", name: "D1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5903", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5904", name: "D21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5905", name: "D22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5906", name: "D2A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5907", name: "D2D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5908", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5909", name: "D3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5910", name: "D3B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5911", name: "D4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5912", name: "D400", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5913", name: "D4A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5914", name: "D5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5915", name: "D5A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5916", name: "D6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5917", name: "D6A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5918", name: "D7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5919", name: "D8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5920", name: "D9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5921", name: "D990", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5922", name: "D9A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5929",
            name: "D\u00e2n Ch\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5931",
            name: "Di\u1ec7p Minh Tuy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5941",
            name: "D\u01b0\u01a1ng \u0110\u00ecnh H\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5923",
            name: "\u0110\u1ea1i Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5924",
            name: "\u0110\u1ea1i L\u1ed9 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5925",
            name: "\u0110\u1ea1i l\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5926",
            name: "\u0110\u1ea1i l\u1ed9 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5927",
            name: "\u0110\u1ea1i l\u1ed9 61",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5928",
            name: "\u0110\u1ea1i L\u1ed9 \u0110\u00f4ng T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5930",
            name: "\u0110\u1eb7ng Nghi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5932",
            name: "\u0110inh C\u1ee7ng Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5933",
            name: "\u0110\u00ecnh Phong Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5934",
            name: "\u0110\u1ed7 Th\u1ebf Di\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5935",
            name: "\u0110\u1ed7 Xu\u00e2n H\u1ee3p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5936",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5937", name: "\u0110T 741", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5938", name: "\u0110T 747", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5939", name: "\u0110T 769", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5940", name: "\u0110T 816", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5942", name: "F", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5943",
            name: "G\u00f2 C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5944",
            name: "G\u00f2 C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5945",
            name: "G\u00f2 \u0110\u1ed3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5946", name: "G\u00f2 Lu", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5947",
            name: "G\u00f2 N\u1ed5i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5948", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "5949",
            name: "H\u00e0n Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5950", name: "H\u00e0ng Tre", prefix: "Ph\u1ed1" },
          {
            id: "5951",
            name: "H\u1eadu L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5952",
            name: "Hi - Tech Park",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5953",
            name: "Hi\u1ec1n V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5954",
            name: "Hi\u1ec7p Th\u00e0nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5955",
            name: "Hi\u1ec7p Th\u00e0nh 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5956",
            name: "H\u1ed3 B\u00e1 Ph\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5957",
            name: "H\u1ed3 Bi\u1ec3u Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5958",
            name: "H\u1ed3 Ng\u1ecdc L\u00e3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5959",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5960",
            name: "Ho\u00e0ng H\u1eefu Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5961",
            name: "H\u1ed3ng S\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5962",
            name: "Huy C\u00e2\u0323n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5963",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5964",
            name: "\u00cdch Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5965",
            name: "\u00cdch Th\u1ea1nh 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5966", name: "K22", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5967",
            name: "Kh\u1edfi Ngh\u0129a B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5968",
            name: "Kh\u1ed5ng T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5969",
            name: "Kh\u00f4\u0309ng T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5970", name: "K\u00fd Con", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5971",
            name: "L\u00e3 Xu\u00e2n Oai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5972",
            name: "L\u1ea1c Thi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5973",
            name: "L\u00e2m Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5974",
            name: "L\u00e0ng T\u0103ng Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5975",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5976",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5977",
            name: "L\u00ea Th\u00e1nh T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5978",
            name: "L\u00ea V\u0103n Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5979",
            name: "L\u00ea V\u0103n Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5980",
            name: "L\u00ea Xu\u00e2n Oai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5981",
            name: "Li\u00ean Ph\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5982", name: "L\u00f2 Lu", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5983",
            name: "L\u1ed9c H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5984", name: "Long H\u00f2a", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5985",
            name: "Long Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5986", name: "Long S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5987",
            name: "Long Th\u00e0nh - D\u1ea7u Gi\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5988",
            name: "Long Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5989",
            name: "L\u01b0\u01a1ng L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5990",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5991",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5992", name: "M1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5993", name: "M4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "5994", name: "M50", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "5995",
            name: "M\u00e3 L\u00f2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5996",
            name: "M\u1ea1c Hi\u1ec1n T\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5997",
            name: "Man Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "5998",
            name: "M\u01b0\u01a1ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "5999", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6000", name: "N10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6001", name: "N11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6002", name: "N12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6003", name: "N13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6004", name: "N17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6005", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6006", name: "N3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6007", name: "N4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6008", name: "N5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6009", name: "N6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6010", name: "N7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6011", name: "N8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6012", name: "N9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6013", name: "Nam Cao", prefix: "Ph\u1ed1" },
          { id: "6014", name: "Nam H\u00f2a", prefix: "Ph\u1ed1" },
          { id: "6015", name: "NB3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6016",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6017",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6019",
            name: "Nguy\u1ec5n Duy Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6018",
            name: "Nguy\u1ec5n \u0110\u00ecnh Thi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6020",
            name: "Nguy\u1ec5n Huy Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6021",
            name: "Nguy\u1ec5n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6022",
            name: "Nguy\u1ec5n Kho\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6023",
            name: "Nguy\u1ec5n Th\u1ecb S\u00f3c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6024",
            name: "Nguy\u1ec5n Th\u1ecb T\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6025",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6026",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6027",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6028",
            name: "Nguy\u1ec5n V\u0103n T\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6029",
            name: "Nguy\u1ec5n V\u0103n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6030",
            name: "Nguy\u1ec5n V\u0103n Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6031",
            name: "Nguy\u1ec5n Xi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6032",
            name: "Nguy\u1ec5n Xi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6033",
            name: "N\u00fai M\u1ed9t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6034",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6035",
            name: "Ph\u1ea1m Tr\u1ecdng C\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6036",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6037",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6038",
            name: "Phan \u0110\u1eb7ng \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6039",
            name: "Phan \u0110\u1ea1t \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6040",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6041",
            name: "Phong Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6042",
            name: "Ph\u00fa H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6043",
            name: "Ph\u00fa Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6044", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "6045",
            name: "Ph\u01b0\u1edbc B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6046",
            name: "Ph\u01b0\u1edbc H\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6047",
            name: "Ph\u01b0\u1edbc H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6048",
            name: "Ph\u01b0\u1edbc Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6049", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6050",
            name: "Qu\u1ed1c L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6051",
            name: "Qu\u1ed1c l\u1ed9 106",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6052",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6053",
            name: "Qu\u1ed1c L\u1ed9 52",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6054", name: "S\u1ed1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6055", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6056", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6057", name: "S\u1ed1 100", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6058", name: "S\u1ed1 102", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6059", name: "S\u1ed1 109", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6060", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6061", name: "S\u1ed1 110", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6062", name: "S\u1ed1 111", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6063", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6064", name: "S\u1ed1 123", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6065", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6066", name: "S\u1ed1 138", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6067", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6068", name: "S\u1ed1 147", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6069", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6070", name: "S\u1ed1 154", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6071", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6072", name: "S\u1ed1 160", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6073", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6074", name: "S\u1ed1 176", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6075", name: "S\u1ed1 179", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6076", name: "S\u1ed1 18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6077", name: "S\u1ed1 182", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6078", name: "S\u1ed1 185", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6079", name: "S\u1ed1 18A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6080", name: "S\u1ed1 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6081", name: "S\u1ed1 19A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6082", name: "S\u1ed1 1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6083", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6084", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6085", name: "S\u1ed1 200", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6086", name: "S\u1ed1 206", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6087", name: "S\u1ed1 21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6088", name: "S\u1ed1 215", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6089", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6090", name: "S\u1ed1 23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6091", name: "S\u1ed1 24", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6092", name: "S\u1ed1 25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6093", name: "S\u1ed1 26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6094", name: "S\u1ed1 27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6095", name: "S\u1ed1 28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6096", name: "S\u1ed1 29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6097", name: "S\u1ed1 290", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6098", name: "S\u1ed1 295", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6099", name: "S\u1ed1 297", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6100", name: "S\u1ed1 2A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6101", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6102", name: "S\u1ed1 30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6103", name: "S\u1ed1 31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6104", name: "S\u1ed1 32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6105", name: "S\u1ed1 33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6106", name: "S\u1ed1 339", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6107", name: "S\u1ed1 35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6108", name: "S\u1ed1 359", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6109", name: "S\u1ed1 38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6110", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6111", name: "S\u1ed1 41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6112", name: "S\u1ed1 44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6113", name: "S\u1ed1 442", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6114", name: "S\u1ed1 448", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6115", name: "S\u1ed1 449", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6116", name: "S\u1ed1 46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6117", name: "S\u1ed1 48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6118", name: "S\u1ed1 494", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6119", name: "S\u1ed1 4A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6120", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6121", name: "S\u1ed1 50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6122", name: "S\u1ed1 52", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6123", name: "S\u1ed1 5A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6124", name: "S\u1ed1 5B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6125", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6126", name: "S\u1ed1 61", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6127", name: "S\u1ed1 61B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6128", name: "S\u1ed1 68", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6129", name: "S\u1ed1 6A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6130", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6131", name: "S\u1ed1 73", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6132", name: "S\u1ed1 74", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6133", name: "S\u1ed1 79", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6134", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6135", name: "S\u1ed1 816", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6136", name: "S\u1ed1 817", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6137", name: "S\u1ed1 827", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6138", name: "S\u1ed1 83", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6139", name: "S\u1ed1 882", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6140", name: "S\u1ed1 898", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6141", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6142", name: "S\u1ed1 91", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6143", name: "S\u1ed1 970", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6144", name: "S\u1ed1 98", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6145", name: "S\u1ed1 990", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6146", name: "S\u1ed1 9B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6147", name: "S\u1ed1 A1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6148",
            name: "Song H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6149",
            name: "T\u1ea1 Thu Th\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6150", name: "Tam \u0110a", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6151",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6152",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6153",
            name: "T\u00e2n H\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6154",
            name: "T\u00e2n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6155",
            name: "T\u00e2n H\u00f2a 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6156",
            name: "T\u00e2n L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6157",
            name: "T\u00e2n L\u1eadp 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6158",
            name: "T\u00e2n L\u1eadp 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6159",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6160",
            name: "T\u0103ng Nh\u01a1n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6161",
            name: "T\u0103ng Nh\u01a1n Ph\u00fa A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6162",
            name: "T\u0103ng Nh\u01a1n Ph\u00fa B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6163",
            name: "T\u00e2y H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6164",
            name: "T\u00e2y H\u00f2a 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6165",
            name: "Th\u1ea1nh L\u1ed9c 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6166", name: "Thanh Nga", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6167",
            name: "Th\u1ea1nh Xu\u00e2n 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6168",
            name: "Th\u1ea1nh Xu\u00e2n 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6169",
            name: "Th\u00edch B\u1eedu \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6170",
            name: "Th\u1ee7y L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6171",
            name: "T\u1ec9nh l\u1ed9 179",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6172",
            name: "T\u1ec9nh l\u1ed9 359",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6173",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6174",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6175",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6176",
            name: "Tr\u1ea7n K\u1ebf X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6177",
            name: "Tr\u1ea7n L\u01b0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6178",
            name: "Tr\u1ea7n Quang \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6179",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6180",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6181",
            name: "Tr\u1ea7n Th\u1ecb \u0110i\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6182",
            name: "Tr\u1ea7n Tr\u1ecdng Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6183",
            name: "Tr\u00edch S\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6184",
            name: "Tr\u1ecbnh C\u00f4ng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6185",
            name: "Tr\u1ecbnh Ho\u00e0i \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6186",
            name: "Tr\u01b0\u1eddng Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6187",
            name: "Tr\u01b0\u1eddng L\u01b0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6188",
            name: "Tr\u01b0\u1eddng L\u01b0u 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6189",
            name: "Tr\u01b0\u01a1ng Minh Gi\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6190",
            name: "Tr\u01b0\u1eddng Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6191",
            name: "Tr\u01b0\u01a1ng V\u0103n \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6192",
            name: "Tr\u01b0\u01a1ng V\u0103n H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6193",
            name: "Tr\u01b0\u01a1ng V\u0103n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6194", name: "T\u1ef1 Do", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6195",
            name: "T\u00fa X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6196",
            name: "\u00dat Tr\u00e0 \u00d4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6197",
            name: "V\u00e0nh \u0110ai 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6198",
            name: "V\u00e0nh \u0110ai 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6199",
            name: "V\u00e0nh \u0110ai \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6200",
            name: "V\u00e0nh \u0110ai Trong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6201",
            name: "V\u00f5 Ch\u00ed C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6202",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6203",
            name: "V\u00f5 Qu\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6204",
            name: "V\u00f5 V\u0103n H\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6205", name: "Xa La", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6206",
            name: "Xa L\u1ed9 H\u00e0 N\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6207",
            name: "Xu\u00e2n Qu\u1ef3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "865",
            name: "9 View Apartment",
            lat: "10.829195976257",
            lng: "106.77359008789",
          },
          {
            id: "866",
            name: "B\u1eafc R\u1ea1ch Chi\u1ebfc",
            lat: "10.813199996948",
            lng: "106.76558685303",
          },
          {
            id: "867",
            name: "B\u00e1ch Khoa",
            lat: "10.793263435364",
            lng: "106.78644561768",
          },
          {
            id: "868",
            name: "Bi\u1ec7t th\u1ef1 Cielo Island",
            lat: "10.813611030579",
            lng: "106.77536010742",
          },
          {
            id: "869",
            name: "B\u00ecnh Minh",
            lat: "10.848183631897",
            lng: "106.77548217773",
          },
          {
            id: "870",
            name: "Blue Diamond riverside",
            lat: "10.741564750671",
            lng: "106.66596221924",
          },
          {
            id: "871",
            name: "Camland Garden",
            lat: "10.788503646851",
            lng: "106.77067565918",
          },
          {
            id: "872",
            name: "C\u0103n h\u1ed9 Hausneo",
            lat: "10.807682991028",
            lng: "106.78701019287",
          },
          {
            id: "873",
            name: "Centana \u0110i\u1ec1n Ph\u00fac Th\u00e0nh",
            lat: "10.798804283142",
            lng: "106.82768249512",
          },
          {
            id: "874",
            name: "Centermark",
            lat: "10.789060592651",
            lng: "106.80014038086",
          },
          {
            id: "876",
            name: "Diamond Island qu\u1eadn 9",
            lat: "10.814771652222",
            lng: "106.83441162109",
          },
          {
            id: "875",
            name: "\u0110\u1ea1i Ph\u00fac Green Stone",
            lat: "10.811502456665",
            lng: "106.85189819336",
          },
          {
            id: "877",
            name: "\u0110i\u1ec1n Ph\u00fac Th\u00e0nh",
            lat: "10.808594703674",
            lng: "106.78546905518",
          },
          {
            id: "878",
            name: "\u0110\u00f4ng T\u0103ng Long",
            lat: "10.809152603149",
            lng: "106.82122802734",
          },
          {
            id: "879",
            name: "Ehome 2",
            lat: "10.810459136963",
            lng: "106.78625488281",
          },
          {
            id: "880",
            name: "EHome \u0110\u00f4ng S\u00e0i G\u00f2n 1",
            lat: "10.820073127747",
            lng: "106.77940368652",
          },
          {
            id: "881",
            name: "Ehome S",
            lat: "10.796906471252",
            lng: "106.78079986572",
          },
          {
            id: "882",
            name: "Exim Garden",
            lat: "10.809194564819",
            lng: "106.80206298828",
          },
          {
            id: "883",
            name: "Flora Anh \u0110\u00e0o",
            lat: "10.809295654297",
            lng: "106.77587890625",
          },
          {
            id: "884",
            name: "Fuji Residence",
            lat: "10.81330871582",
            lng: "106.78468322754",
          },
          {
            id: "885",
            name: "Gia H\u00f2a",
            lat: "10.817047119141",
            lng: "106.77870941162",
          },
          {
            id: "886",
            name: "Ginza Tam \u0110a",
            lat: "10.796999931335",
            lng: "106.84168243408",
          },
          {
            id: "887",
            name: "Golf Park",
            lat: "10.868057250977",
            lng: "106.81405639648",
          },
          {
            id: "888",
            name: "Green Building",
            lat: "10.82789516449",
            lng: "106.76384735107",
          },
          {
            id: "889",
            name: "Green Home Riverside",
            lat: "10.795251846313",
            lng: "106.8133392334",
          },
          {
            id: "890",
            name: "Green Town Qu\u1eadn 9",
            lat: "10.790739059448",
            lng: "106.79454803467",
          },
          {
            id: "891",
            name: "Harbor View T\u00e2n C\u1ea3ng",
            lat: "10.799381256103",
            lng: "106.8108291626",
          },
          {
            id: "892",
            name: "Him Lam Ph\u00fa An",
            lat: "10.819493293762",
            lng: "106.76263427734",
          },
          {
            id: "893",
            name: "Ho\u00e0ng Anh Minh Tu\u1ea5n",
            lat: "10.816940307617",
            lng: "106.77410125732",
          },
          {
            id: "894",
            name: "Hoja Villa",
            lat: "10.817252159119",
            lng: "106.78575134277",
          },
          {
            id: "895",
            name: "HTReal 898",
            lat: "10.787086486816",
            lng: "106.79949951172",
          },
          {
            id: "896",
            name: "H\u01b0ng Ph\u00fa 1",
            lat: "10.810185432434",
            lng: "106.78964996338",
          },
          {
            id: "897",
            name: "H\u01b0ng Ph\u00fa 2",
            lat: "10.810669898987",
            lng: "106.78900146484",
          },
          {
            id: "898",
            name: "H\u00f9ng Vi\u1ec7t",
            lat: "10.797760009766",
            lng: "106.79106903076",
          },
          {
            id: "899",
            name: "I-Home Xa L\u1ed9 H\u00e0 N\u1ed9i",
            lat: "10.832926750183",
            lng: "106.76522064209",
          },
          {
            id: "900",
            name: "Jamila Khang \u0110i\u1ec1n",
            lat: "10.801998138428",
            lng: "106.74769592285",
          },
          {
            id: "901",
            name: "KDC C.T.C",
            lat: "10.797227859497",
            lng: "106.82037353516",
          },
          {
            id: "902",
            name: "KDC Ch\u1ee3 Long Tr\u01b0\u1eddng M\u1edbi",
            lat: "10.80619430542",
            lng: "106.82228088379",
          },
          {
            id: "903",
            name: "KDC Ki\u1ebfn \u00c1",
            lat: "10.806091308594",
            lng: "106.78285217285",
          },
          {
            id: "904",
            name: "KDC Long Thu\u1eadn",
            lat: "10.811502456665",
            lng: "106.85189819336",
          },
          {
            id: "905",
            name: "KDC M\u1ef9 Long",
            lat: "10.84193611145",
            lng: "106.83081054688",
          },
          {
            id: "906",
            name: "KDC Nam Long",
            lat: "10.809241294861",
            lng: "106.78060150147",
          },
          {
            id: "907",
            name: "KDC Ph\u00fa Nhu\u1eadn - Ph\u01b0\u1edbc Long B",
            lat: "10.804096221924",
            lng: "106.78578948975",
          },
          {
            id: "908",
            name: "KDC Ph\u01b0\u1edbc Thi\u1ec7n",
            lat: "10.848958969116",
            lng: "106.83767700195",
          },
          {
            id: "909",
            name: "KDC Thanh Tr\u00fac",
            lat: "10.819617271423",
            lng: "106.80724334717",
          },
          {
            id: "910",
            name: "KDC Tr\u01b0\u1eddng L\u01b0u",
            lat: "10.804048538208",
            lng: "106.82344055176",
          },
          {
            id: "911",
            name: "Khang An",
            lat: "10.789060592651",
            lng: "106.80014038086",
          },
          {
            id: "912",
            name: "Khang \u0110i\u1ec1n Intresco",
            lat: "10.818890571594",
            lng: "106.78099060059",
          },
          {
            id: "913",
            name: "Khang \u0110i\u1ec1n Long Tr\u01b0\u1eddng",
            lat: "10.793491363525",
            lng: "106.82695770264",
          },
          {
            id: "914",
            name: "Khu d\u00e2n c\u01b0 Long Th\u1ea1nh M\u1ef9",
            lat: "10.835906028748",
            lng: "106.83002471924",
          },
          {
            id: "915",
            name: "Kikyo Residence",
            lat: "10.798589706421",
            lng: "106.78565216065",
          },
          {
            id: "916",
            name: "Li\u00ean Ph\u01b0\u1eddng Star",
            lat: "10.807675361633",
            lng: "106.78702545166",
          },
          {
            id: "917",
            name: "Long Ph\u01b0\u1edbc \u0110i\u1ec1n - Long Tr\u01b0\u1eddng",
            lat: "10.793239593506",
            lng: "106.82614135742",
          },
          {
            id: "918",
            name: "Lucasta Villa",
            lat: "10.803651809692",
            lng: "106.79628753662",
          },
          {
            id: "919",
            name: "Lucky Dragon",
            lat: "10.821514129639",
            lng: "106.77268218994",
          },
          {
            id: "920",
            name: "Man Thi\u1ec7n",
            lat: "10.853242874146",
            lng: "106.78842163086",
          },
          {
            id: "921",
            name: "Mega Residence",
            lat: "10.799418449402",
            lng: "106.79365539551",
          },
          {
            id: "922",
            name: "Mega Ruby",
            lat: "10.799203872681",
            lng: "106.79740905762",
          },
          {
            id: "923",
            name: "Mega Sapphire",
            lat: "10.789060592651",
            lng: "106.80014038086",
          },
          {
            id: "924",
            name: "Mega Village",
            lat: "10.78470993042",
            lng: "106.79044342041",
          },
          {
            id: "925",
            name: "Melosa Garden",
            lat: "21.0306224823",
            lng: "105.84886932373",
          },
          {
            id: "926",
            name: "Monada Khang \u0110i\u1ec1n",
            lat: "10.786940574646",
            lng: "106.80638885498",
          },
          {
            id: "927",
            name: "Nam Khang Residence",
            lat: "10.805932998657",
            lng: "106.82167816162",
          },
          {
            id: "928",
            name: "Nh\u00e2n Ph\u00fa",
            lat: "10.833818435669",
            lng: "106.78170776367",
          },
          {
            id: "929",
            name: "Park Riverside T\u00e2n C\u1ea3ng",
            lat: "10.80708694458",
            lng: "106.79985809326",
          },
          {
            id: "930",
            name: "Peach Garden",
            lat: "10.802832603455",
            lng: "106.79010009766",
          },
          {
            id: "931",
            name: "Ph\u1ed1 \u0110\u00f4ng - Hoa Sen",
            lat: "10.809042930603",
            lng: "106.78527069092",
          },
          {
            id: "932",
            name: "Ph\u1ed1 \u0110\u00f4ng Residences",
            lat: "10.797355651856",
            lng: "106.82217407227",
          },
          {
            id: "933",
            name: "Ph\u00fac An Vi\u00ean",
            lat: "10.854552268982",
            lng: "106.83196258545",
          },
          {
            id: "934",
            name: "Phuoc Long Spring Town",
            lat: "10.828809738159",
            lng: "106.77368927002",
          },
          {
            id: "935",
            name: "Quang Trung Diamond",
            lat: "10.839282989502",
            lng: "106.77328491211",
          },
          {
            id: "936",
            name: "Rio Casa",
            lat: "10.806343078613",
            lng: "106.8210067749",
          },
          {
            id: "937",
            name: "Rio Centro G\u00f2 C\u00e1t",
            lat: "10.784770011902",
            lng: "106.80068206787",
          },
          {
            id: "938",
            name: "Rio Porto",
            lat: "10.800979614258",
            lng: "106.79637145996",
          },
          {
            id: "939",
            name: "Rio Vista",
            lat: "10.820757865906",
            lng: "106.7773361206",
          },
          {
            id: "940",
            name: "River Park",
            lat: "10.814707756043",
            lng: "106.77949523926",
          },
          {
            id: "941",
            name: "River Valley",
            lat: "10.814158439636",
            lng: "106.7847366333",
          },
          {
            id: "942",
            name: "Riviera Cove",
            lat: "10.81303691864",
            lng: "106.78732299805",
          },
          {
            id: "943",
            name: "S\u00e0i G\u00f2n Gateway",
            lat: "10.847274780273",
            lng: "106.77352905273",
          },
          {
            id: "944",
            name: "Samsung Village",
            lat: "10.81973361969",
            lng: "106.79968261719",
          },
          {
            id: "945",
            name: "Sim City",
            lat: "10.826538085938",
            lng: "106.81995391846",
          },
          {
            id: "946",
            name: "Singa City",
            lat: "10.797060966492",
            lng: "106.82346343994",
          },
          {
            id: "947",
            name: "Sky 9",
            lat: "10.804578781128",
            lng: "106.79141235352",
          },
          {
            id: "948",
            name: "Sun City Minh S\u01a1n",
            lat: "10.804204940796",
            lng: "106.7938079834",
          },
          {
            id: "949",
            name: "Tam \u0110a BCR",
            lat: "10.799301147461",
            lng: "106.8402633667",
          },
          {
            id: "950",
            name: "T\u0103ng Long Garden",
            lat: "10.805561065674",
            lng: "106.8166885376",
          },
          {
            id: "951",
            name: "T\u0103ng Long River View",
            lat: "10.816813468933",
            lng: "106.81936645508",
          },
          {
            id: "952",
            name: "T\u0103ng Ph\u00fa House",
            lat: "10.825181007385",
            lng: "106.77071380615",
          },
          {
            id: "953",
            name: "TDH - Ph\u00fac Th\u1ecbnh \u0110\u1ee9c",
            lat: "10.826097488403",
            lng: "106.76342773438",
          },
          {
            id: "955",
            name: "TDH - Ph\u01b0\u1edbc Long",
            lat: "10.838738441467",
            lng: "106.76923370361",
          },
          {
            id: "954",
            name: "TDH \u2013 Ph\u01b0\u1edbc B\u00ecnh",
            lat: "10.815683364868",
            lng: "106.76960754394",
          },
          {
            id: "956",
            name: "TDH Hi\u1ec7p Ph\u00fa",
            lat: "10.843758583069",
            lng: "106.77165985107",
          },
          {
            id: "957",
            name: "TDL Residence",
            lat: "10.799463272095",
            lng: "106.82539367676",
          },
          {
            id: "958",
            name: "Th\u00e1i D\u01b0\u01a1ng Luxury",
            lat: "10.802091598511",
            lng: "106.81466674805",
          },
          {
            id: "959",
            name: "Tha\u0301i D\u01b0\u01a1ng Xanh",
            lat: "10.858216285706",
            lng: "106.83666229248",
          },
          {
            id: "960",
            name: "Th\u1ea3o Nguy\u00ean S\u00e0i G\u00f2n",
            lat: "10.866487503052",
            lng: "106.81713104248",
          },
          {
            id: "961",
            name: "Th\u00e1p M\u01b0\u1eddi Merita",
            lat: "10.804538726807",
            lng: "106.79082489014",
          },
          {
            id: "962",
            name: "The Art",
            lat: "10.813985824585",
            lng: "106.77523040772",
          },
          {
            id: "963",
            name: "The Boat Club Residences",
            lat: "10.803527832031",
            lng: "106.83853149414",
          },
          {
            id: "964",
            name: "The Eastern",
            lat: "10.805453300476",
            lng: "106.79660797119",
          },
          {
            id: "965",
            name: "The Garland",
            lat: "10.8159532547",
            lng: "106.7858505249",
          },
          {
            id: "966",
            name: "The Venica",
            lat: "10.813611030579",
            lng: "106.77536010742",
          },
          {
            id: "967",
            name: "Thi\u00ean L\u00fd",
            lat: "10.821120262146",
            lng: "106.7830657959",
          },
          {
            id: "968",
            name: "Th\u1ee7 Thi\u00eam Garden",
            lat: "10.80698299408",
            lng: "106.78387451172",
          },
          {
            id: "969",
            name: "Topaz Home 2",
            lat: "10.867607116699",
            lng: "106.80640411377",
          },
          {
            id: "970",
            name: "Topia Garden",
            lat: "10.774962425232",
            lng: "106.92531585693",
          },
          {
            id: "971",
            name: "Tr\u00ed Ki\u1ec7t Long Tr\u01b0\u1eddng",
            lat: "10.818901062012",
            lng: "106.80438995361",
          },
          {
            id: "972",
            name: "Tr\u00ed Minh",
            lat: "10.785165786743",
            lng: "106.79210662842",
          },
          {
            id: "973",
            name: "Tr\u01b0\u1eddng Th\u1ea1nh 1",
            lat: "10.80472946167",
            lng: "106.84111022949",
          },
          {
            id: "974",
            name: "Valencia Riverside",
            lat: "10.79123210907",
            lng: "106.79698181152",
          },
          {
            id: "975",
            name: "Valora Fuji",
            lat: "10.813254356384",
            lng: "106.78452301025",
          },
          {
            id: "976",
            name: "Vi\u1ec7t Nh\u00e2n Villa Riverside",
            lat: "10.817142486572",
            lng: "106.82978820801",
          },
          {
            id: "977",
            name: "Villa Park",
            lat: "10.803483009338",
            lng: "106.80074310303",
          },
          {
            id: "978",
            name: "Vincity qu\u1eadn 9",
            lat: "10.84201335907",
            lng: "106.83285522461",
          },
          {
            id: "979",
            name: "V\u01b0\u1eddn Lan",
            lat: "10.801112174988",
            lng: "106.81434631348",
          },
        ],
      },
      {
        id: "22",
        name: "T\u00e2n B\u00ecnh",
        wards: [
          { id: "289", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "290", name: "10", prefix: "Ph\u01b0\u1eddng" },
          { id: "291", name: "11", prefix: "Ph\u01b0\u1eddng" },
          { id: "292", name: "12", prefix: "Ph\u01b0\u1eddng" },
          { id: "293", name: "13", prefix: "Ph\u01b0\u1eddng" },
          { id: "294", name: "14", prefix: "Ph\u01b0\u1eddng" },
          { id: "295", name: "15", prefix: "Ph\u01b0\u1eddng" },
          { id: "296", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "297", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "298", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "299", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "300", name: "6", prefix: "Ph\u01b0\u1eddng" },
          { id: "301", name: "7", prefix: "Ph\u01b0\u1eddng" },
          { id: "302", name: "8", prefix: "Ph\u01b0\u1eddng" },
          { id: "303", name: "9", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "6208", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6209", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6210", name: "11B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6211", name: "124", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6212", name: "149/33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6213", name: "17B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6214", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6215", name: "25A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6216", name: "27/3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6217", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6218", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6219", name: "45", prefix: "Ph\u1ed1" },
          { id: "6220", name: "49", prefix: "Ph\u1ed1" },
          { id: "6221", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6222", name: "52", prefix: "Ph\u1ed1" },
          { id: "6223", name: "57", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6224", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6225", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6226", name: "A4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6227", name: "An T\u00f4n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6228",
            name: "\u1ea4p B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6229",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6230", name: "B1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6231", name: "B3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6232", name: "B6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6233", name: "Ba Gia", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6234", name: "Ba V\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6235", name: "Ba V\u00ec", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6236",
            name: "B\u1eafc H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6237",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6238",
            name: "B\u1ea1ch \u0110\u1eb1ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6239",
            name: "B\u00e0nh V\u0103n Tr\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6240",
            name: "B\u00e0u B\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6241",
            name: "B\u00e0u C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6242",
            name: "B\u00e0u C\u00e1t 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6243",
            name: "B\u00e0u C\u00e1t 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6244",
            name: "B\u00e0u C\u00e1t 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6245",
            name: "B\u00e0u C\u00e1t 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6246",
            name: "B\u00e0u C\u00e1t 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6247",
            name: "B\u00e0u C\u00e1t 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6248",
            name: "B\u00e0u C\u00e1t 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6249",
            name: "B\u00e0u C\u00e1t 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6250",
            name: "B\u00e0u C\u00e1t 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6251",
            name: "B\u00e0u C\u00e1t \u0110\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6252",
            name: "B\u1ea3y Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6253",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6254",
            name: "B\u1ebfn C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6255",
            name: "B\u00edch Ph\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6256",
            name: "B\u00ecnh Gi\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6257",
            name: "B\u00f9i Th\u1ebf M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6258",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6259", name: "C1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6260", name: "C12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6261", name: "C18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6262", name: "C2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6263", name: "C22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6264", name: "C27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6265", name: "C3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6266", name: "C4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6267", name: "C65", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6268", name: "C9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6269",
            name: "Ca V\u0103n Th\u1ec9nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6270",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6271",
            name: "Ch\u1ea5n H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6272",
            name: "Ch\u00e2u V\u0129nh T\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6273", name: "Ch\u00ed Linh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6274",
            name: "Chi\u1ebfn Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6275",
            name: "Ch\u1eed \u0110\u1ed3ng T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6276",
            name: "Ch\u01b0\u01a1ng Ho\u00e0ng Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6277",
            name: "C\u1ed9ng H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6278",
            name: "C\u1ed1ng L\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6279",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6280",
            name: "C\u01b0 X\u00e1 T\u1ef1 Do",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6281", name: "C\u1eedu Long", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6282", name: "D23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6283", name: "D52", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6286",
            name: "D\u00e2n Tr\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6302",
            name: "D\u01b0\u01a1ng V\u00e2n Nga",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6303", name: "Duy T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6284",
            name: "\u0110\u1ea1i l\u1ed9 Th\u0103ng Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6285",
            name: "\u0110\u1ea1i Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6287",
            name: "\u0110\u1eb7ng L\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6288",
            name: "\u0110\u1eb7ng Minh Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6289",
            name: "\u0110\u1eb7ng V\u0103n Chung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6290",
            name: "\u0110\u1eb7ng V\u0103n S\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6291",
            name: "\u0110\u1ea5t Th\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6292",
            name: "\u0110inh \u0110i\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6293",
            name: "\u0110\u1ed3 S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6294",
            name: "\u0110\u1ed1ng \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6295",
            name: "\u0110\u1ed3ng \u0110en",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6296",
            name: "\u0110\u00f4ng H\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6297",
            name: "\u0110\u1ed3ng Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6298",
            name: "\u0110\u1ed3ng Nai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6299",
            name: "\u0110\u00f4ng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6300",
            name: "\u0110\u1ed3ng Xo\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6301",
            name: "\u0110\u01b0\u1eddng H\u1ed3ng Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6304",
            name: "Gi\u1ea3i Ph\u00f3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6305",
            name: "G\u00f2 C\u1ea9m \u0110\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6306",
            name: "H\u00e0 B\u00e1 T\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6307",
            name: "H\u00e1t Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6308",
            name: "H\u1eadu Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6309",
            name: "Hi\u1ec7p Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6310",
            name: "H\u1ed3 \u0110\u1eafc Di",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6311",
            name: "H\u1ed3 Ng\u1ecdc C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6312",
            name: "H\u1ed3 Ng\u1ecdc L\u00e3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6313",
            name: "H\u1ed3 V\u0103n T\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6314",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6315",
            name: "Ho\u00e0 Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6316",
            name: "H\u00f2a H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6317",
            name: "Ho\u00e0ng B\u1eadt \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6318",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6319",
            name: "Ho\u00e0ng K\u1ebf Vi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6320",
            name: "Ho\u00e0ng K\u1ebf Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6321", name: "Ho\u00e0ng Sa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6322",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6323",
            name: "Ho\u00e0ng Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6324",
            name: "Ho\u00e0ng Xu\u00e2n Nh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6325",
            name: "H\u1ed3ng H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6326",
            name: "H\u1ed3ng H\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6327",
            name: "H\u1ed3ng L\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6328",
            name: "H\u01b0ng H\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6329",
            name: "H\u01b0\u01a1ng L\u1ed9 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6330",
            name: "Hu\u1ef3nh Lan Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6331",
            name: "Hu\u1ef3nh T\u1ecbnh C\u1ee7a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6332",
            name: "Hu\u1ef3nh V\u0103n Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6333", name: "K22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6334", name: "K300", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6335", name: "K4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6336", name: "Khai Tr\u00ed", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6337",
            name: "Khi\u00eau N\u0103ng T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6338",
            name: "Khi\u1ebfu N\u0103ng T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6339",
            name: "Khu\u00f4ng Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6340", name: "Kim H\u1ea3i", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6341",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6342", name: "Lam S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6343",
            name: "L\u00ea B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6344",
            name: "L\u00ea C\u00f4ng Mi\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6346",
            name: "L\u00ea Duy Nhu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6345",
            name: "L\u00ea \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6347", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6348",
            name: "L\u00ea Minh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6349",
            name: "L\u00ea Ng\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6350",
            name: "L\u00ea Ph\u00e1t \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6351",
            name: "L\u00ea T\u1ea5n Qu\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6352",
            name: "L\u00ea Trung Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6353",
            name: "L\u00ea V\u0103n Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6354",
            name: "L\u00ea V\u0103n S\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6355", name: "L\u00f4 D", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6356",
            name: "L\u1ed9c H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6357", name: "L\u1ed9c Vinh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6358",
            name: "Long H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6359",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6360",
            name: "L\u01b0u Nh\u00e2n Ch\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6361",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "6362",
            name: "Mai L\u00e3o B\u1ea1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6363",
            name: "N\u0103m Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6364", name: "Nam H\u00f2a", prefix: "Ph\u1ed1" },
          { id: "6365", name: "Nam S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6366",
            name: "Ngh\u0129a H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6367",
            name: "Ngh\u0129a H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6368",
            name: "Ngh\u0129a Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6369",
            name: "Ng\u00f4 B\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6370",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6371",
            name: "Ng\u00f4 Th\u1ecb Thu Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6372",
            name: "Ng\u1ecdc Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6373",
            name: "Nguy\u1ec5n B\u00e1 T\u00f2ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6374",
            name: "Nguy\u1ec5n B\u00e1 Tuy\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6375",
            name: "Nguy\u1ec5n B\u1eadc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6376",
            name: "Nguy\u1ec5n B\u1eb7c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6377",
            name: "Nguy\u1ec5n Bi\u1ec3u B2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6378",
            name: "Nguy\u1ec5n C\u1ea3nh D\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6379",
            name: "Nguy\u1ec5n Ch\u00e1nh S\u1eaft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6380",
            name: "Nguy\u1ec5n \u0110\u00ecnh Kh\u01a1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6381",
            name: "Nguy\u1ec5n \u0110\u1ee9c Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6382",
            name: "Nguy\u1ec5n Hi\u1ebfn L\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6383",
            name: "Nguy\u1ec5n H\u1ed3ng \u0110\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6384",
            name: "Nguy\u1ec5n Minh Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6385",
            name: "Nguy\u1ec5n Minh Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6386",
            name: "Nguy\u1ec5n Ph\u00fac Chu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6387",
            name: "Nguy\u1ec5n Quang B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6388",
            name: "Nguy\u1ec5n S\u1ef9 S\u00e1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6389",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6390",
            name: "Nguy\u1ec5n Thanh Tuy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6391",
            name: "Nguy\u1ec5n Th\u1ebf L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6392",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1eb7ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6393",
            name: "Nguy\u1ec5n Th\u1ecb Nh\u1ecf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6394",
            name: "Nguy\u1ec5n Th\u1ecb Thu Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6395",
            name: "Nguy\u1ec5n Tr\u1ecdng L\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6396",
            name: "Nguy\u1ec5n Tr\u1ecdng Tuy\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6397",
            name: "Nguy\u1ec5n T\u01b0 Gi\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6398",
            name: "Nguy\u1ec5n T\u1eed Nha",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6400",
            name: "Nguy\u1ec5n V\u0103n M\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6399",
            name: "Nguy\u1ec5n V\u0103n Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6401",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6402",
            name: "Nguy\u1ec5n V\u0103n V\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6403",
            name: "Nguy\u1ec5n V\u0103n V\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6404",
            name: "Nguy\u1ec5n V\u0103n V\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6405",
            name: "Nh\u1ea5t Chi Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6406",
            name: "Ni S\u01b0 Hu\u1ef3nh Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6407",
            name: "N\u00fai Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6408",
            name: "Ph\u1ea1m C\u1ef1 L\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6409",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6410",
            name: "Ph\u1ea1m Ph\u00fa Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6411",
            name: "Ph\u1ea1m V\u0103n B\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6412",
            name: "Ph\u1ea1m V\u0103n Hai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6413",
            name: "Ph\u1ea1m V\u0103n S\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6414",
            name: "Phan B\u00e1 Phi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6415",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6416",
            name: "Phan Huy \u00cdch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6417",
            name: "Phan S\u00e0o Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6418",
            name: "Phan Th\u0103ng Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6419",
            name: "Phan Th\u00fac Duy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6420",
            name: "Phan V\u0103n S\u1eedu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6421",
            name: "Ph\u1ed5 Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6422", name: "Ph\u00f9 Dung", prefix: "Ph\u1ed1" },
          {
            id: "6423",
            name: "Ph\u00fa H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6424",
            name: "Ph\u00fa L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6425", name: "Ph\u00fac Chu", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6426",
            name: "Qu\u00e1ch V\u0103n Tu\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6427",
            name: "Qu\u1ea3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6428",
            name: "S\u1ea7m S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6429", name: "Sao Mai", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6430", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6431", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6432", name: "S\u1ed1 18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6433", name: "S\u1ed1 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6434", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6435", name: "S\u1ed1 25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6436", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6437", name: "S\u1ed1 34B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6438", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6439", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6440", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6441", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6442", name: "S\u01a1n Cang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6443",
            name: "S\u01a1n H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6444",
            name: "S\u01a1n H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6445",
            name: "S\u00f4ng \u0110\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6446",
            name: "S\u00f4ng \u0110\u00e1y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6447",
            name: "S\u00f4ng Nhu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6448",
            name: "S\u00f4ng Thao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6449",
            name: "S\u00f4ng Th\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6450", name: "T4A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6451",
            name: "T\u00e1i Thi\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6452", name: "T\u00e2n Canh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6453",
            name: "T\u00e2n Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6454",
            name: "T\u00e2n H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6455", name: "T\u00e2n Khai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6456",
            name: "T\u00e2n K\u1ef3 T\u00e2n Qu\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6457",
            name: "T\u00e2n L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6458",
            name: "T\u00e2n Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6459",
            name: "T\u00e2n S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6460",
            name: "T\u00e2n S\u01a1n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6461",
            name: "T\u00e2n S\u01a1n Nh\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6462",
            name: "T\u00e2n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6463",
            name: "T\u00e2n Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6464",
            name: "T\u00e2n Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6465",
            name: "T\u00e2n Trang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6466",
            name: "T\u00e2n Tr\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6467",
            name: "T\u1ea3n Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6468",
            name: "T\u00e2n Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6469",
            name: "Th\u00e1i Th\u1ecb Nh\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6470",
            name: "Th\u00e2n Nh\u00e2n Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6471",
            name: "Th\u0103ng  Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6472",
            name: "Th\u00e0nh M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6473",
            name: "Th\u00e9p M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6474",
            name: "Th\u00edch Minh Nguy\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6475",
            name: "Thi\u00ean Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6476",
            name: "Th\u1ee7 Khoa Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6477",
            name: "Th\u00fay L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6478",
            name: "Ti\u1ec1n Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6479",
            name: "T\u1ed1ng V\u0103n H\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6480",
            name: "Tr\u00e0 Kh\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6481",
            name: "Tr\u1ea7n Mai Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6482",
            name: "Tr\u1ea7n Minh Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6483",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6484",
            name: "Tr\u1ea7n Qu\u1ed1c Ho\u00e0n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "6485",
            name: "Tr\u1ea7n T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6486",
            name: "Tr\u1ea7n Th\u00e1i T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6487",
            name: "Tr\u1ea7n Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6488",
            name: "Tr\u1ea7n Tri\u1ec7u Lu\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6489",
            name: "Tr\u00e2\u0300n V\u0103n Danh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6490",
            name: "Tr\u1ea7n V\u0103n D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6491",
            name: "Tr\u1ea7n V\u0103n Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6492",
            name: "Tr\u1ea7n V\u0103n Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6493",
            name: "Tr\u1ecbnh \u0110\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6494", name: "Trung Lang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6495",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6496",
            name: "Tr\u01b0\u01a1ng C\u00f4ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6497",
            name: "Tr\u01b0\u01a1ng Ho\u00e0ng Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6498",
            name: "Tr\u01b0\u01a1ng Qu\u1ed1c Dung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6499",
            name: "Tr\u01b0\u01a1ng Qu\u1ed1c Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6500",
            name: "Tr\u01b0\u1eddng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6501",
            name: "Tr\u01b0\u1eddng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6502",
            name: "Tr\u01b0\u1eddng S\u01a1n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6503",
            name: "Tr\u01b0\u01a1ng T\u1ea5n Sang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6504",
            name: "T\u1ef1 C\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6505",
            name: "T\u1ee9 H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6506",
            name: "T\u1ef1 L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6507",
            name: "\u00dat T\u1ecbch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6508",
            name: "V\u0103n Chung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6509",
            name: "V\u00e2n C\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6510",
            name: "V\u00f5 \u0110\u00ecnh T\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6511",
            name: "V\u00f5 Th\u00e0nh Trang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6512",
            name: "V\u00f5 Trung Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6513",
            name: "V\u01b0\u1eddn Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6514",
            name: "Xu\u00e2n Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6515",
            name: "Xu\u00e2n H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6516",
            name: "Y\u00ean Th\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "980",
            name: "ACBR Office Building",
            lat: "10.802206039429",
            lng: "106.64459991455",
          },
          {
            id: "981",
            name: "B\u00e0u C\u00e1t II",
            lat: "10.786147117615",
            lng: "106.64504241943",
          },
          {
            id: "982",
            name: "B\u1ea3y Hi\u1ec1n Tower",
            lat: "10.788576126099",
            lng: "106.65021514893",
          },
          {
            id: "983",
            name: "Bee Home",
            lat: "10.806868553162",
            lng: "106.64110565185",
          },
          {
            id: "984",
            name: "Botanica Premier",
            lat: "10.812040328979",
            lng: "106.66993713379",
          },
          {
            id: "985",
            name: "C.T Plaza",
            lat: "10.813024520874",
            lng: "106.66564178467",
          },
          {
            id: "986",
            name: "Carillon 3",
            lat: "10.806468009949",
            lng: "106.64686584473",
          },
          {
            id: "987",
            name: "Carillon Apartment",
            lat: "10.804841041565",
            lng: "106.64766693115",
          },
          {
            id: "988",
            name: "Central Plaza",
            lat: "10.794235229492",
            lng: "106.66318511963",
          },
          {
            id: "989",
            name: "Chung c\u01b0 Ph\u00fac Y\u00ean",
            lat: "10.825569152832",
            lng: "106.62985229492",
          },
          {
            id: "990",
            name: "C\u1ed9ng H\u00f2a Garden",
            lat: "10.802412986755",
            lng: "106.65201568603",
          },
          {
            id: "991",
            name: "C\u1ed9ng H\u00f2a Plaza",
            lat: "10.801239967346",
            lng: "106.65090942383",
          },
          {
            id: "992",
            name: "Era L\u1ea1c Long Qu\u00e2n",
            lat: "10.775128364563",
            lng: "106.649269104",
          },
          {
            id: "993",
            name: "Etown Building",
            lat: "10.801940917969",
            lng: "106.64748382568",
          },
          {
            id: "994",
            name: "Golf View Tower",
            lat: "10.835982322693",
            lng: "106.64627075195",
          },
          {
            id: "995",
            name: "H\u00e0 \u0110\u00f4 Airport Building",
            lat: "10.813484191894",
            lng: "106.66551971435",
          },
          {
            id: "996",
            name: "Nam Minh Long",
            lat: "10.825534820557",
            lng: "106.62957000732",
          },
          {
            id: "997",
            name: "Newberry Residence",
            lat: "10.789093017578",
            lng: "106.6480178833",
          },
          {
            id: "998",
            name: "Palacio Garden",
            lat: "10.786957740784",
            lng: "106.64205169678",
          },
          {
            id: "999",
            name: "Republic Plaza",
            lat: "10.801020622253",
            lng: "106.66078948975",
          },
          {
            id: "1000",
            name: "Ruby Garden",
            lat: "10.817695617676",
            lng: "106.63272094727",
          },
          {
            id: "1001",
            name: "S\u00e0i G\u00f2n Airport",
            lat: "10.81510925293",
            lng: "106.66687774658",
          },
          {
            id: "1002",
            name: "Scetpa Building",
            lat: "10.800856590271",
            lng: "106.65029144287",
          },
          {
            id: "1003",
            name: "Sky Center",
            lat: "10.806015968323",
            lng: "106.66645812988",
          },
          {
            id: "1004",
            name: "T\u00e2n B\u00ecnh Apartment",
            lat: "10.824196815491",
            lng: "106.63576507568",
          },
          {
            id: "1005",
            name: "Th\u00e1i S\u01a1n Apartment",
            lat: "10.803124427795",
            lng: "106.6520690918",
          },
          {
            id: "1006",
            name: "Th\u00e0nh C\u00f4ng Tower 2",
            lat: "10.819122314453",
            lng: "106.62982940674",
          },
          {
            id: "1007",
            name: "The Botanica",
            lat: "10.808710098267",
            lng: "106.66935729981",
          },
          {
            id: "1008",
            name: "The Harmona",
            lat: "10.79693031311",
            lng: "106.64209747315",
          },
          {
            id: "1009",
            name: "TPA Building",
            lat: "10.808499336243",
            lng: "106.66484069824",
          },
          {
            id: "1010",
            name: "Waseco Building",
            lat: "10.80532169342",
            lng: "106.66578674316",
          },
        ],
      },
      {
        id: "23",
        name: "T\u00e2n Ph\u00fa",
        wards: [
          { id: "304", name: "Hi\u1ec7p T\u00e2n", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "305",
            name: "H\u00f2a Th\u1ea1nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "306",
            name: "Ph\u00fa Th\u1ea1nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "307",
            name: "Ph\u00fa Th\u1ecd H\u00f2a",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "308", name: "Ph\u00fa Trung", prefix: "Ph\u01b0\u1eddng" },
          { id: "309", name: "S\u01a1n K\u1ef3", prefix: "Ph\u01b0\u1eddng" },
          { id: "310", name: "T\u00e2n Qu\u00fd", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "311",
            name: "T\u00e2n S\u01a1n Nh\u00ec",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "312",
            name: "T\u00e2n Th\u00e0nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "313",
            name: "T\u00e2n Th\u1edbi H\u00f2a",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "314",
            name: "T\u00e2y Th\u1ea1nh",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "6517", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6518", name: "11N", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6519", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6520", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6521", name: "14B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6522", name: "15A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6523", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6524", name: "16A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6525", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6526", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6527", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6528", name: "19/5B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6529", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6530", name: "26-3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6531", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6532", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6533", name: "30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6534", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6535", name: "33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6536", name: "3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6537", name: "41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6538", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6539", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6540", name: "5A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6541", name: "66", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6542", name: "79", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6543", name: "9A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6544", name: "A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6545",
            name: "An Ph\u00fa \u0110\u00f4ng 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6546",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6547", name: "B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6548", name: "B1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6549", name: "B14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6550", name: "B2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6551", name: "B3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6552", name: "B4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6553",
            name: "B\u00e1c \u00c1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6554",
            name: "B\u1eafc \u00c1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6555",
            name: "B\u00e0u C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6556",
            name: "B\u00e0u C\u00e1t 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6557",
            name: "B\u00ecnh Gi\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6558",
            name: "B\u00ecnh Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6559",
            name: "B\u1edd Bao T\u00e2n Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6560",
            name: "B\u00f9i C\u1ea9m H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6561",
            name: "B\u00f9i Th\u1ebf M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6562",
            name: "B\u00f9i Xu\u00e2n Ph\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6563", name: "C13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6564", name: "C2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6565", name: "C4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6566", name: "C5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6567", name: "C6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6568", name: "C8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6569",
            name: "C\u00e1ch M\u1ea1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6570",
            name: "Cao V\u0103n Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6571",
            name: "C\u1ea7u Ni\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6572",
            name: "C\u1ea7u X\u00e2y 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6573",
            name: "C\u1ea7u X\u00e9o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6574", name: "C\u00e2y Keo", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6575", name: "CC2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6576", name: "CC3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6577", name: "CC5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6578",
            name: "Ch\u00e2n L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6579",
            name: "Ch\u1ebf Lan Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6580",
            name: "Chu Thi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6581",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6582", name: "CN1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6583", name: "CN10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6584", name: "CN11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6585", name: "CN12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6586", name: "CN13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6587", name: "CN3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6588", name: "CN4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6589", name: "CN5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6590", name: "CN6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6591", name: "CN8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6592", name: "CN9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6593",
            name: "C\u1ed9ng H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6594",
            name: "C\u1ed9ng H\u00f2a 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6595", name: "D10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6596", name: "D11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6597", name: "D12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6598", name: "D13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6599", name: "D14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6600", name: "D14A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6601", name: "D14B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6602", name: "D15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6603", name: "D16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6604", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6605", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6606", name: "D4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6607", name: "D9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6610",
            name: "D\u00e2n Ch\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6611",
            name: "D\u00e2n T\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6616", name: "DC1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6617", name: "DC11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6618", name: "DC13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6619", name: "DC2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6620", name: "DC3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6621", name: "DC4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6622", name: "DC5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6623", name: "DC6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6624", name: "DC7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6625", name: "DC8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6626", name: "DC9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6629",
            name: "Di\u1ec7p Minh Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6631", name: "DN6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6652",
            name: "D\u01b0\u01a1ng \u0110\u00ecnh C\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6653",
            name: "D\u01b0\u01a1ng \u0110\u1ee9c Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6654",
            name: "D\u01b0\u01a1ng Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6655",
            name: "D\u01b0\u01a1ng Khu\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6657",
            name: "D\u01b0\u01a1ng Thi\u1ec7u T\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6658",
            name: "D\u01b0\u01a1ng V\u0103n D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6608",
            name: "\u0110\u1ea1i Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6609",
            name: "\u0110\u00e0m Th\u1eadn Huy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6612",
            name: "\u0110\u1eb7ng Th\u1ebf Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6613",
            name: "\u0110\u00e0o Nguy\u00ean C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6614",
            name: "\u0110\u00e0o Tr\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6615",
            name: "\u0110\u1ea9u V\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6627",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6628",
            name: "\u0110i\u1ec7n Cao Th\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6630", name: "\u0110inh Li\u1ec7t", prefix: "Ph\u1ed1" },
          {
            id: "6632",
            name: "\u0110\u1ed7 B\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6633",
            name: "\u0110\u1ed7 C\u00f4ng T\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6634",
            name: "\u0110\u00f4 \u0110\u1ed1c Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6635",
            name: "\u0110\u00f4 \u0110\u1ed1c L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6636",
            name: "\u0110\u00f4 \u0110\u1ed1c Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6637",
            name: "\u0110\u00f4 \u0110\u1ed1c Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6638",
            name: "\u0110\u00f4 \u0110\u1ed1c Th\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6639",
            name: "\u0110\u1ed7 \u0110\u1ee9c D\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6640", name: "\u0110\u1ed7 Nhu\u1eadn", prefix: "Ph\u1ed1" },
          {
            id: "6641",
            name: "\u0110\u1ed7 Th\u1ecb T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6642",
            name: "\u0110\u1ed7 Th\u1ecb T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6643",
            name: "\u0110\u1ed7 Th\u1eeba Lu\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6644",
            name: "\u0110\u1ed7 Th\u1eeba T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6645",
            name: "\u0110\u1ed7 V\u0103n Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6646",
            name: "\u0110o\u00e0n Gi\u1ecfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6647",
            name: "\u0110o\u00e0n H\u1ed3ng Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6648",
            name: "\u0110o\u00e0n H\u1ed3ng Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6649",
            name: "\u0110o\u00e0n K\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6650",
            name: "\u0110\u1ed9c L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6651",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6656",
            name: "\u0110\u01b0\u1eddng S3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6659", name: "E38", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6660",
            name: "G\u00f2 D\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6661",
            name: "G\u00f2 Xo\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6662",
            name: "H\u00e0n Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6663",
            name: "H\u00e0n M\u1eb7c T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6664",
            name: "H\u1eadu L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6665",
            name: "Hi\u1ec1n V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6666",
            name: "Hi\u1ec7p Th\u00e0nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6667",
            name: "H\u1ed3 \u0110\u1eafc Di",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6668",
            name: "H\u1ed3 Ng\u1ecdc C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6669", name: "Hoa B\u1eb1ng", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6670",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6671",
            name: "Ho\u00e0ng Ng\u1ecdc Ph\u00e1ch",
            prefix: "Ph\u1ed1",
          },
          {
            id: "6672",
            name: "Ho\u00e0ng Thi\u1ec1u Hoa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6673",
            name: "Ho\u00e0ng V\u0103n H\u00f2e",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6674",
            name: "Ho\u00e0ng Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6675",
            name: "Ho\u00e0ng Xu\u00e2n Ho\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6676",
            name: "Ho\u00e0ng Xu\u00e2n Nh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6677",
            name: "H\u01b0\u01a1ng l\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6678",
            name: "H\u01b0\u01a1ng L\u1ed9 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6679",
            name: "Hu\u1ef3nh Thi\u1ec7n L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6680",
            name: "Hu\u1ef3nh Thi\u1ec7u Hoa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6681",
            name: "Hu\u1ef3nh V\u0103n Ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6682",
            name: "Hu\u1ef3nh V\u0103n G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6683",
            name: "Hu\u1ef3nh V\u0103n M\u1ed9t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6684",
            name: "Hu\u1ef3nh V\u0103n Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6685",
            name: "I\u0301ch Thi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6686", name: "\u00cdch Thi\u1ec7n", prefix: "Ph\u1ed1" },
          {
            id: "6687",
            name: "K\u00eanh 19/5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6688",
            name: "K\u00eanh 19/5A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6689",
            name: "K\u00eanh 19/5B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6690",
            name: "K\u00eanh N\u01b0\u1edbc \u0110en",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6691",
            name: "K\u00eanh T\u00e2n H\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6692",
            name: "Kh\u1edfi Ngh\u0129a B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6693",
            name: "Khu\u00f4ng Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6694",
            name: "Ki\u1ec1u S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6695",
            name: "L\u00ea C\u1ea3nh Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6696",
            name: "L\u00ea Cao L\u00e3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6697",
            name: "L\u00ea \u0110\u00ecnh Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6698",
            name: "L\u00ea \u0110\u00ecnh Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6699",
            name: "L\u00ea Kh\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6700",
            name: "L\u00ea L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6701",
            name: "L\u00ea L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6702",
            name: "L\u00ea Li\u1ec5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6703",
            name: "L\u00ea L\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6704",
            name: "L\u00ea L\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6705",
            name: "L\u00ea Ng\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6706",
            name: "L\u00ea Ni\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6707",
            name: "L\u00ea Quang Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6708",
            name: "L\u00ea Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6709",
            name: "L\u00ea Qu\u1ed1c Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6710", name: "L\u00ea Sao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6711",
            name: "L\u00ea S\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6712",
            name: "L\u00ea Th\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6713",
            name: "L\u00ea Thi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6714",
            name: "L\u00ea Th\u00fac Ho\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6715",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "6716",
            name: "L\u00ea Trung \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6717", name: "L\u00ea V\u0103n Phan", prefix: "Ph\u1ed1" },
          {
            id: "6718",
            name: "L\u00ea V\u0103n Ph\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6719",
            name: "L\u00ea V\u0103n Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6720",
            name: "L\u00ea V\u0129nh H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6721",
            name: "L\u1ed9c H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6722",
            name: "L\u01b0\u01a1ng \u0110\u1eafc B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6723",
            name: "L\u01b0\u01a1ng Minh Nguy\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6724",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6725",
            name: "L\u01b0\u01a1ng Tr\u00fac \u0110\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6726",
            name: "L\u01b0u Ch\u00ed Hi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6727",
            name: "L\u0169y B\u00e1n B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6728",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6729",
            name: "L\u00fd Th\u00e1i T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6730",
            name: "L\u00fd Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6731",
            name: "L\u00fd Tu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6732",
            name: "M\u00e3 L\u00f2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6733",
            name: "M\u01b0\u01a1ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6734", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6735", name: "N3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6736",
            name: "Nghi\u00eam To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6737",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6738",
            name: "Ng\u1ee5y Nh\u01b0 Kon Tum",
            prefix: "Ph\u1ed1",
          },
          {
            id: "6739",
            name: "Ng\u1ee5y Nh\u01b0 KonTum",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6740",
            name: "Nguy\u1ec5n B\u00e1 T\u00f2ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6741",
            name: "Nguy\u1ec5n C\u1ea3nh Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6742",
            name: "Nguy\u1ec5n Ch\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6743",
            name: "Nguy\u1ec5n C\u1eedu \u0110\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6745",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6746",
            name: "Nguy\u1ec5n D\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6744",
            name: "Nguy\u1ec5n \u0110\u1ed7 Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6747",
            name: "Nguy\u1ec5n H\u00e1o V\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6748",
            name: "Nguy\u1ec5n H\u1ea3o V\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6749",
            name: "Nguy\u1ec5n H\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6750",
            name: "Nguy\u1ec5n H\u1eefu D\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6751",
            name: "Nguy\u1ec5n H\u1eefu Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6752",
            name: "Nguy\u1ec5n Huy Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6753",
            name: "Nguy\u1ec5n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6754",
            name: "Nguy\u1ec5n L\u1ed9 Tr\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6755",
            name: "Nguy\u1ec5n L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6756",
            name: "Nguy\u1ec5n Minh Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6757",
            name: "Nguy\u1ec5n M\u1ef9 Ca",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6758",
            name: "Nguy\u1ec5n Nghi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6759",
            name: "Nguy\u1ec5n Ng\u1ecdc Nh\u1ef1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6760",
            name: "Nguy\u1ec5n Nh\u1eef L\u00e3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6761",
            name: "Nguy\u1ec5n Quang Di\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6762",
            name: "Nguy\u1ec5n Qu\u1ebf Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6763",
            name: "Nguy\u1ec5n Qu\u00fd Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6764",
            name: "Nguy\u1ec5n S\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6765",
            name: "Nguy\u1ec5n S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6766",
            name: "Nguy\u1ec5n S\u00fay",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6767",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6768",
            name: "Nguy\u1ec5n Th\u1ebf Truy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6769",
            name: "Nguy\u1ec5n Thi\u1ec7u L\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6770",
            name: "Nguy\u1ec5n Tr\u1ecdng Ph\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6771",
            name: "Nguy\u1ec5n Tr\u1ecdng Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6772",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6773",
            name: "Nguy\u1ec5n V\u0103n D\u01b0\u1ee1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6774",
            name: "Nguy\u1ec5n V\u0103n Huy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6775",
            name: "Nguy\u1ec5n V\u0103n Ng\u1ecdc",
            prefix: "Ph\u1ed1",
          },
          {
            id: "6776",
            name: "Nguy\u1ec5n V\u0103n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6777",
            name: "Nguy\u1ec5n V\u0103n S\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6778",
            name: "Nguy\u1ec5n V\u0103n T\u1ed1",
            prefix: "Ph\u1ed1",
          },
          {
            id: "6779",
            name: "Nguy\u1ec5n V\u0103n V\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6780",
            name: "Nguy\u1ec5n V\u0103n X\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6781",
            name: "Nguy\u1ec5n V\u0103n Y\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6782",
            name: "Nguy\u1ec5n Xu\u00e2n Kho\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6783",
            name: "N\u00fai M\u1ed9t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6784",
            name: "\u00d4ng \u00cdch Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6785",
            name: "Ph\u1ea1m Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6786",
            name: "Ph\u1ea1m Ng\u1ecdc Nh\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6787",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6788",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6789",
            name: "Ph\u1ea1m Q\u00fay Th\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6790",
            name: "Ph\u1ea1m V\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6791",
            name: "Ph\u1ea1m V\u0103n Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6792",
            name: "Ph\u1ea1m V\u0103n X\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6793", name: "Phan Anh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6794",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6795",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6796",
            name: "Phan V\u0103n N\u0103m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6797",
            name: "Ph\u1ed1 Ch\u1ee3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6798",
            name: "Ph\u1ed5 Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6799",
            name: "Ph\u00fa Th\u1ecd H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6800",
            name: "Ph\u00fa Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6801",
            name: "Ph\u00f9ng Ch\u00ed Ki\u00ean",
            prefix: "Ph\u1ed1",
          },
          {
            id: "6802",
            name: "Qu\u00e1ch \u0110\u00ecnh B\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6803",
            name: "Qu\u00e1ch H\u1eefu Nghi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6804",
            name: "Qu\u00e1ch V\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6805",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6806", name: "S", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6807", name: "S1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6808", name: "S11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6809", name: "S2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6810", name: "S3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6811", name: "S4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6812", name: "S5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6813", name: "S7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6814", name: "S8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6815", name: "S9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6816", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6817", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6818", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6819", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6820", name: "S\u1ed1 18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6821", name: "S\u1ed1 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6822", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6823", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6824", name: "S\u1ed1 27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6825", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6826", name: "S\u1ed1 30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6827", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6828", name: "S\u1ed1 41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6829", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6830", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6831", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6832", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6833",
            name: "S\u01a1n K\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6834",
            name: "S\u01a1n K\u1ef3 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6835", name: "T1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6836", name: "T2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6837", name: "T3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6838", name: "T4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6839", name: "T4A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6840", name: "T4B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6841", name: "T5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6842", name: "T6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6843", name: "T8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6844",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6845",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6846",
            name: "T\u00e2n H\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6847",
            name: "T\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6848",
            name: "T\u00e2n K\u1ef3 T\u00e2n Qu\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6849",
            name: "T\u00e2n Qu\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6850",
            name: "T\u00e2n S\u01a1n Nh\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6851",
            name: "T\u00e2n Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6852",
            name: "T\u00e2n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6853",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6854",
            name: "T\u00e2y S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6855",
            name: "T\u00e2y Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6856", name: "Th\u1ea1ch Lam", prefix: "Ph\u1ed1" },
          {
            id: "6857",
            name: "Th\u1ea9m M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6858",
            name: "Th\u00e0nh C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6859",
            name: "Th\u1ea1nh L\u1ed9c 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6860",
            name: "Th\u1ea1nh Xu\u00e2n 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6861",
            name: "Th\u1ea1nh Xu\u00e2n 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6862",
            name: "Th\u00edch B\u1eedu \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6863",
            name: "Tho\u1ea1i Ng\u1ecdc H\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6864",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6865",
            name: "Th\u01b0\u01a1ng M\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6866",
            name: "T\u1ec9nh L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6867",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6868",
            name: "T\u1ed1ng V\u0103n Tr\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6869",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6870",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6871",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6872",
            name: "Tr\u1ea7n Huy Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6873",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6874",
            name: "Tr\u1ea7n Quang C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6875",
            name: "Tr\u1ea7n Quang \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6876",
            name: "Tr\u1ea7n Quang Qu\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6877",
            name: "Tr\u1ea7n T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6878",
            name: "Tr\u1ea7n Th\u1ee7 \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6879",
            name: "Tr\u1ea7n V\u0103n C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6880",
            name: "Tr\u1ea7n V\u0103n Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6881",
            name: "Tr\u1ea7n V\u0103n Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6882",
            name: "Tr\u1ea7n V\u0103n \u01a0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6883",
            name: "Tr\u00edch S\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6884",
            name: "Tr\u1ecbnh \u0110\u00ecnh Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6885",
            name: "Tr\u1ecbnh \u0110\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6886",
            name: "Tr\u1ecbnh L\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6887",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6888",
            name: "Tr\u01b0\u01a1ng H\u1ea3o V\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6889",
            name: "Tr\u01b0\u01a1ng V\u0103n \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6890",
            name: "Tr\u01b0\u01a1ng V\u00e2n L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6891",
            name: "Tr\u01b0\u01a1ng V\u0103n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6892",
            name: "Tr\u01b0\u01a1ng V\u0129nh K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6893", name: "T\u1ef1 Do", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6894",
            name: "T\u1ef1 Quy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6895", name: "V\u0103n Cao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6896",
            name: "V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6897",
            name: "V\u00f5 C\u00f4ng T\u1ed3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6898",
            name: "V\u00f5 Ho\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6899",
            name: "V\u00f5 V\u0103n D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6900",
            name: "V\u0169 Tr\u1ecdng Ph\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6901",
            name: "V\u01b0\u1eddn L\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6902", name: "Xa La", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6903", name: "\u1ef6 Lan", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6904",
            name: "Y\u00ean \u0110\u1ed7",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1011",
            name: "584 Lilama SHB plaza",
            lat: "10.776358604431",
            lng: "106.6357498169",
          },
          {
            id: "1012",
            name: "8X \u0110\u1ea7m Sen",
            lat: "10.769884109497",
            lng: "106.62624359131",
          },
          {
            id: "1013",
            name: "An B\u00ecnh",
            lat: "10.779189109802",
            lng: "106.63515472412",
          },
          {
            id: "1014",
            name: "An Gia Garden",
            lat: "10.801912307739",
            lng: "106.62579345703",
          },
          {
            id: "1015",
            name: "\u00c2u C\u01a1 Tower",
            lat: "10.787341117859",
            lng: "106.64099884033",
          },
          {
            id: "1016",
            name: "Carillon 2",
            lat: "10.775685310364",
            lng: "106.63444519043",
          },
          {
            id: "1017",
            name: "Carillon 5",
            lat: "10.772256851196",
            lng: "106.63240814209",
          },
          {
            id: "1018",
            name: "Carillon 6",
            lat: "10.770365715027",
            lng: "106.63214111328",
          },
          {
            id: "1019",
            name: "Carillon 7",
            lat: "10.761142730713",
            lng: "106.63013458252",
          },
          {
            id: "1020",
            name: "Celadon City",
            lat: "10.803905487061",
            lng: "106.61668395996",
          },
          {
            id: "1021",
            name: "Chung c\u01b0 Khu\u00f4ng Vi\u1ec7t",
            lat: "10.770582199097",
            lng: "106.63861083984",
          },
          {
            id: "1023",
            name: "Diamond Lotus Lake View",
            lat: "10.768801689148",
            lng: "106.63234710693",
          },
          {
            id: "1022",
            name: "\u0110\u1ea1i Th\u00e0nh",
            lat: "10.775797843933",
            lng: "106.64209747315",
          },
          {
            id: "1024",
            name: "Fortuna - V\u01b0\u1eddn L\u00e0i",
            lat: "10.788031578064",
            lng: "106.62883758545",
          },
          {
            id: "1025",
            name: "Green Lotus Resident",
            lat: "10.756546974182",
            lng: "106.63570404053",
          },
          {
            id: "1026",
            name: "Heart Land \u0110\u1ea7m Sen",
            lat: "10.765084266663",
            lng: "106.63535308838",
          },
          {
            id: "1027",
            name: "Hi\u1ec7p T\u00e2n Apartment",
            lat: "10.76655292511",
            lng: "106.62686157227",
          },
          {
            id: "1028",
            name: "IDICO T\u00e2n Ph\u00fa",
            lat: "10.772834777832",
            lng: "106.63404083252",
          },
          {
            id: "1029",
            name: "Investco Babylon",
            lat: "10.788970947266",
            lng: "106.6404876709",
          },
          {
            id: "1030",
            name: "KCN T\u00e2n B\u00ecnh",
            lat: "10.813568115234",
            lng: "106.6229019165",
          },
          {
            id: "1031",
            name: "Khang Gia T\u00e2n H\u01b0\u01a1ng",
            lat: "10.789830207825",
            lng: "106.61887359619",
          },
          {
            id: "1032",
            name: "Khang Ph\u00fa",
            lat: "10.779891967773",
            lng: "106.63793182373",
          },
          {
            id: "1033",
            name: "Lotus Garden",
            lat: "10.774463653564",
            lng: "106.6357345581",
          },
          {
            id: "1034",
            name: "Melody Residences 16 \u00c2u C\u01a1",
            lat: "10.79825592041",
            lng: "106.63691711426",
          },
          {
            id: "1035",
            name: "Ph\u00fa Th\u1ea1nh Apartment",
            lat: "10.780529022217",
            lng: "106.63152313232",
          },
          {
            id: "1036",
            name: "Quang Th\u00e1i",
            lat: "10.766347885132",
            lng: "106.62797546387",
          },
          {
            id: "1037",
            name: "RichStar",
            lat: "10.772315979004",
            lng: "106.62551116943",
          },
          {
            id: "1038",
            name: "Ruby Land",
            lat: "10.762969017029",
            lng: "106.62898254394",
          },
          {
            id: "1039",
            name: "Sacomreal H\u00f2a B\u00ecnh",
            lat: "10.769156455994",
            lng: "106.63436889648",
          },
          {
            id: "1040",
            name: "Sacomreal-584",
            lat: "10.784090042114",
            lng: "106.63568115234",
          },
          {
            id: "1041",
            name: "S\u00e0i G\u00f2n Apartment",
            lat: "10.781603813171",
            lng: "106.63746643066",
          },
          {
            id: "1042",
            name: "Southern Dragon",
            lat: "10.78981590271",
            lng: "106.63986968994",
          },
          {
            id: "1043",
            name: "T\u00e2n H\u01b0\u01a1ng Tower",
            lat: "10.790072441101",
            lng: "106.62664031982",
          },
          {
            id: "1044",
            name: "TaniBuilding S\u01a1n K\u1ef3 1",
            lat: "10.805818557739",
            lng: "106.60969543457",
          },
          {
            id: "1045",
            name: "TaniBuilding S\u01a1n K\u1ef3 2",
            lat: "10.809303283691",
            lng: "106.60848999023",
          },
          {
            id: "1046",
            name: "Tecco \u0110\u1ea7m Sen Complex",
            lat: "10.766376495361",
            lng: "106.63423919678",
          },
          {
            id: "1047",
            name: "Th\u00e0nh C\u00f4ng Tower 1",
            lat: "10.814388275147",
            lng: "106.62377929688",
          },
          {
            id: "1048",
            name: "The Montana",
            lat: "10.775012016296",
            lng: "106.62358093262",
          },
          {
            id: "1049",
            name: "Topaz Garden",
            lat: "10.774995803833",
            lng: "106.63401031494",
          },
          {
            id: "1050",
            name: "Trung \u0110\u00f4ng Plaza",
            lat: "10.774488449097",
            lng: "106.63519287109",
          },
          {
            id: "1051",
            name: "Valeo \u0110\u1ea7m Sen",
            lat: "10.777625083923",
            lng: "106.6368637085",
          },
        ],
      },
      {
        id: "24",
        name: "Th\u1ee7 \u0110\u1ee9c",
        wards: [
          {
            id: "315",
            name: "B\u00ecnh Chi\u1ec3u",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "316", name: "B\u00ecnh Th\u1ecd", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "317",
            name: "Hi\u1ec7p B\u00ecnh Ch\u00e1nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "318",
            name: "Hi\u1ec7p B\u00ecnh Ph\u01b0\u1edbc",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "319", name: "Linh Chi\u1ec3u", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "320",
            name: "Linh \u0110\u00f4ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "321", name: "Linh T\u00e2y", prefix: "Ph\u01b0\u1eddng" },
          { id: "322", name: "Linh Trung", prefix: "Ph\u01b0\u1eddng" },
          { id: "323", name: "Linh Xu\u00e2n", prefix: "Ph\u01b0\u1eddng" },
          { id: "324", name: "Tam B\u00ecnh", prefix: "Ph\u01b0\u1eddng" },
          { id: "325", name: "Tam Ph\u00fa", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "326",
            name: "Tr\u01b0\u1eddng Th\u1ecd",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "6905", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6906", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6907", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6908", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6909", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6910", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6911", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6912", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6913", name: "163", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6914", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6915", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6916", name: "182", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6917", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6918", name: "1B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6919", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6920", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6921", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6922", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6923", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6924", name: "24", prefix: "Ph\u1ed1" },
          { id: "6925", name: "25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6926", name: "26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6927", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6928", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6929", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6930", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6931", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6932", name: "30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6933", name: "31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6934", name: "32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6935", name: "33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6936", name: "34", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6937", name: "35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6938", name: "36", prefix: "Ph\u1ed1" },
          { id: "6939", name: "37", prefix: "Ph\u1ed1" },
          { id: "6940", name: "38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6941", name: "389", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6942", name: "39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6943", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6944", name: "40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6945", name: "41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6946", name: "42", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6947", name: "43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6948", name: "44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6949", name: "45", prefix: "Ph\u1ed1" },
          { id: "6950", name: "46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6951", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6952", name: "479", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6953", name: "48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6954", name: "49", prefix: "Ph\u1ed1" },
          { id: "6955", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6956", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6957", name: "50/27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6958", name: "51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6959", name: "52", prefix: "Ph\u1ed1" },
          { id: "6960", name: "520", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6961", name: "53", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6962", name: "54", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6963", name: "57", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6964", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6965", name: "606", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6966", name: "63", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6967", name: "635", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6968", name: "647", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6969", name: "652", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6970", name: "66", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6971", name: "668", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6972", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6973", name: "70", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6974", name: "7L", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6975", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6976", name: "808", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6977", name: "833", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6978", name: "865", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6979", name: "866", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6980", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6981", name: "96", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6982", name: "A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6983", name: "An B\u00ecnh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6984",
            name: "An Ph\u00fa \u0110\u00f4ng 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6985",
            name: "\u1ea4p Xu\u00e2n Hi\u1ec7p 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "6986", name: "B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "6987", name: "B2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "6988",
            name: "B\u00e1c \u00c1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6989",
            name: "B\u1eafc \u00c1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6990",
            name: "B\u1ebfn \u0110\u00f2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6991",
            name: "B\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6992",
            name: "B\u00ecnh \u0110\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6993",
            name: "B\u00ecnh \u0110\u01b0\u1eddng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6994",
            name: "B\u00ecnh \u0110\u01b0\u1eddng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6995",
            name: "B\u00ecnh Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6996",
            name: "B\u00ecnh Tri\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6997",
            name: "B\u00ecnh Tr\u01b0ng Tr\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6998",
            name: "C\u00e2n Nh\u01a1n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "6999",
            name: "Cao t\u1ed1c B\u00ecnh L\u1ee3i - Linh T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7000", name: "C\u00e2y Keo", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7001",
            name: "Ch\u00e2n L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7002",
            name: "Chu M\u1ea1nh Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7003",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7004",
            name: "Ch\u01b0\u01a1ng D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7005",
            name: "C\u1ed1ng H\u1ed9p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7006",
            name: "C\u00f4ng L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7007",
            name: "C\u00f9 Lao Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7008", name: "D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7009", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7010", name: "D11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7011", name: "D12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7012", name: "D15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7013", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7014", name: "D23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7015", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7016", name: "D5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7017", name: "D52", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7018", name: "D5B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7019", name: "D6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7020", name: "D9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7021",
            name: "D\u00e2n Ch\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7035",
            name: "D\u01b0\u01a1ng V\u0103n Cam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7022",
            name: "\u0110\u1eb7ng Th\u1ecb R\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7023",
            name: "\u0110\u1eb7ng V\u0103n Bi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7024",
            name: "\u0110\u00e0o Trinh Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7025",
            name: "\u0110inh Th\u1ecb Thi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7026",
            name: "\u0110\u1ed7 Xu\u00e2n H\u1ee3p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7027",
            name: "\u0110o\u00e0n C\u00f4ng H\u1edbn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7028",
            name: "\u0110o\u00e0n K\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7029",
            name: "\u0110\u1ed9c L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7030",
            name: "\u0110\u1ed3ng B\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7031",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 42",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7032",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7033", name: "\u0110T 741", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7034", name: "\u0110T 747", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7036", name: "Einstein", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7037",
            name: "G\u00f2 D\u01b0a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7038",
            name: "H\u00e0n Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7039",
            name: "H\u00e0n Thuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7040",
            name: "H\u1eadu L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7041",
            name: "Hi\u1ec7p B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7042",
            name: "Hi\u1ec7p Th\u00e0nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7043",
            name: "Hi\u1ec7p Th\u00e0nh 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7044",
            name: "H\u1ed3 Bi\u1ec3u Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7045",
            name: "H\u1ed3 V\u0103n T\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7046",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7047",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7048",
            name: "Ho\u00e0ng Di\u1ec7u 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7049",
            name: "H\u1ed3ng \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7050",
            name: "H\u01b0\u01a1ng D\u1eeba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7051",
            name: "H\u1eefu Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7052",
            name: "K\u00eanh Ba B\u00f2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7053",
            name: "Kha V\u1ea1n C\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7054",
            name: "Kh\u1edfi Ngh\u0129a B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7055",
            name: "Kh\u1ed5ng T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7056", name: "Lam S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7057",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7058",
            name: "L\u00ea Th\u1ecb Hoa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7059",
            name: "L\u00ea V\u0103n Ch\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7060",
            name: "L\u00ea V\u0103n Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7061",
            name: "L\u00ea V\u0103n T\u00e1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7062",
            name: "Linh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7063",
            name: "Linh \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7064", name: "Linh T\u00e2y", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7065", name: "Linh Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7066",
            name: "Linh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7067",
            name: "L\u1ed9c H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7068",
            name: "L\u01b0\u01a1ng Kh\u1ea3i Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7069",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7070",
            name: "L\u00fd T\u1ebf Xuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7071",
            name: "M\u01b0\u01a1ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7072", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7073", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7074", name: "N3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7075", name: "N9-2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7076",
            name: "Nam Th\u1ecd 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7077",
            name: "Ng\u00f4 Ch\u00ed Qu\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7078", name: "Ng\u1ecdc Lan", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7079",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7080",
            name: "Nguy\u1ec5n B\u00e1 Lu\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7081",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7082",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7083",
            name: "Nguy\u1ec5n C\u1eedu \u0110\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7084",
            name: "Nguy\u1ec5n \u0110\u00ecnh Thi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7085",
            name: "Nguy\u1ec5n Huy Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7086",
            name: "Nguy\u1ec5n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7087",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7088",
            name: "Nguy\u1ec5n Th\u1ecb Nhung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7089",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7090",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7091",
            name: "Nguy\u1ec5n V\u0103n B\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7092",
            name: "Nguy\u1ec5n V\u0103n L\u1ecbch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7093",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7094",
            name: "N\u00fai M\u1ed9t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7095", name: "Pasteur", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7096",
            name: "Ph\u1ea1m V\u0103n B\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7097",
            name: "Ph\u1ea1m V\u0103n Chi\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7098",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7099",
            name: "Ph\u1ea1m V\u0103n S\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7100",
            name: "Phan Huy Ch\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7101",
            name: "Ph\u00fa Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7102",
            name: "Ph\u00fa \u0110\u00f4ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7103",
            name: "Ph\u00fa Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7104",
            name: "Qu\u1ed1c L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7105",
            name: "Qu\u1ed1c L\u1ed9 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7106",
            name: "Qu\u1ed1c L\u1ed9 13A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7107",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7108",
            name: "Qu\u1ed1c l\u1ed9 1K",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7109",
            name: "Qu\u1ed1c l\u1ed9 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7110",
            name: "R\u1ea1ch L\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7111", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7112", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7113", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7114", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7115", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7116", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7117", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7118", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7119", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7120", name: "S\u1ed1 18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7121", name: "S\u1ed1 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7122", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7123", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7124", name: "S\u1ed1 21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7125", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7126", name: "S\u1ed1 23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7127", name: "S\u1ed1 24", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7128", name: "S\u1ed1 25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7129", name: "S\u1ed1 26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7130", name: "S\u1ed1 27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7131", name: "S\u1ed1 28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7132", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7133", name: "S\u1ed1 30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7134", name: "S\u1ed1 31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7135", name: "S\u1ed1 32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7136", name: "S\u1ed1 34", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7137", name: "S\u1ed1 35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7138", name: "S\u1ed1 36", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7139", name: "S\u1ed1 37", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7140", name: "S\u1ed1 38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7141", name: "S\u1ed1 39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7142", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7143", name: "S\u1ed1 40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7144", name: "S\u1ed1 41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7145", name: "S\u1ed1 42", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7146", name: "S\u1ed1 43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7147", name: "S\u1ed1 44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7148", name: "S\u1ed1 45", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7149", name: "S\u1ed1 46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7150", name: "S\u1ed1 47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7151", name: "S\u1ed1 48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7152", name: "S\u1ed1 49", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7153", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7154", name: "S\u1ed1 50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7155", name: "S\u1ed1 51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7156", name: "S\u1ed1 52", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7157", name: "S\u1ed1 520", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7158", name: "S\u1ed1 53", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7159", name: "S\u1ed1 54", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7160", name: "S\u1ed1 58", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7161", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7162", name: "S\u1ed1 606", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7163", name: "S\u1ed1 647", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7164", name: "S\u1ed1 652", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7165", name: "S\u1ed1 668", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7166", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7167", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7168", name: "S\u1ed1 808", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7169", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7170", name: "S\u1ed1 907", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7171",
            name: "S\u00f4ng \u0110\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7172",
            name: "Song H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7173", name: "Tam B\u00ecnh", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7174", name: "Tam Ch\u00e2u", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7175", name: "Tam H\u00e0", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7176",
            name: "T\u00e2m T\u00e2m X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7177",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7178",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7179",
            name: "T\u00e2n L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7180",
            name: "T\u00e2n S\u01a1n Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7181",
            name: "T\u00e2n Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7182",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7183",
            name: "T\u00e2n V\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7184",
            name: "Th\u1ea1nh L\u1ed9c 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7185",
            name: "Th\u1ea1nh Xu\u00e2n 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7186",
            name: "Th\u1ea1nh Xu\u00e2n 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7187",
            name: "Th\u00edch B\u1eedu \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7188",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7189",
            name: "T\u1ec9nh l\u1ed9 43",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7190",
            name: "T\u00f4 Ng\u1ecdc V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7191",
            name: "T\u00f4 V\u0129nh Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7192",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7193",
            name: "Tr\u1ea7n Quang \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7194",
            name: "Tr\u1ea7n V\u0103n N\u1eefa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7195",
            name: "Tr\u00edch S\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7196",
            name: "Tr\u1ecbnh Phong \u0110\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7197",
            name: "Tr\u01b0ng Nh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7198",
            name: "Tr\u01b0ng Tr\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7199",
            name: "Tr\u01b0ng Tr\u1eafc B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7200",
            name: "Tr\u01b0\u1eddng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7201",
            name: "Tr\u01b0\u1eddng Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7202",
            name: "Tru\u00f4ng Tre",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7203",
            name: "Tr\u01b0\u01a1ng V\u0103n \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7204",
            name: "Tr\u01b0\u01a1ng V\u0103n Ng\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7205", name: "\u1ee4 Ghe", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7206",
            name: "V\u1eadn H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7207",
            name: "V\u00e0nh \u0110ai 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7208",
            name: "V\u00e0nh \u0110ai \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7209",
            name: "V\u00e0nh \u0110ai Trong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7210",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7211",
            name: "V\u00f5 Qu\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7212",
            name: "V\u00f5 Ti\u00ean S\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7213",
            name: "V\u00f5 V\u0103n Ng\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7214", name: "Xa La", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7215",
            name: "Xa L\u1ed9 \u0110a\u0323i Ha\u0300n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7216",
            name: "Xa L\u1ed9 H\u00e0 N\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7217",
            name: "X\u00f4 Vi\u1ebft Ngh\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7218",
            name: "Xuy\u00ean \u00c1",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1052",
            name: "4S Riverside Garden",
            lat: "10.822030067444",
            lng: "106.71848297119",
          },
          {
            id: "1053",
            name: "4S Riverside Linh \u0110\u00f4ng",
            lat: "10.844369888306",
            lng: "106.74047851562",
          },
          {
            id: "1054",
            name: "Aquila Plaza",
            lat: "10.844707489014",
            lng: "106.76338195801",
          },
          {
            id: "1055",
            name: "B\u00ecnh Chi\u1ec3u Riverside City",
            lat: "10.8804063797",
            lng: "106.73389434815",
          },
          {
            id: "1056",
            name: "C\u0103n h\u1ed9 S\u00e0i G\u00f2n Ho\u00e0ng Anh",
            lat: "21.0306224823",
            lng: "105.84886932373",
          },
          {
            id: "1057",
            name: "Cheery 4 Complex",
            lat: "10.861504554748",
            lng: "106.73915863037",
          },
          {
            id: "1058",
            name: "Ch\u01b0\u01a1ng D\u01b0\u01a1ng Golden Land",
            lat: "10.828922271728",
            lng: "106.75217437744",
          },
          {
            id: "1059",
            name: "Ch\u01b0\u01a1ng D\u01b0\u01a1ng Home",
            lat: "10.836952209473",
            lng: "106.75646209717",
          },
          {
            id: "1062",
            name: "Depot Metro Tower",
            lat: "10.855546951294",
            lng: "106.77429962158",
          },
          {
            id: "1064",
            name: "Dream Home 2",
            lat: "10.848203659058",
            lng: "106.72317504883",
          },
          {
            id: "1065",
            name: "D\u1ef1 \u00e1n S\u00f4ng \u0110\u00e0",
            lat: "10.827310562134",
            lng: "106.7159576416",
          },
          {
            id: "1060",
            name: "\u0110\u1ea1t Gia Centre Point",
            lat: "10.857501029968",
            lng: "106.73777770996",
          },
          {
            id: "1061",
            name: "\u0110\u1ea1t Gia Residence",
            lat: "10.859802246094",
            lng: "106.73918914795",
          },
          {
            id: "1063",
            name: "\u0110\u1ed3ng Kh\u1edfi Land",
            lat: "10.864570617676",
            lng: "106.73004150391",
          },
          {
            id: "1066",
            name: "\u0110\u1ee9c Linh 8",
            lat: "10.830169677734",
            lng: "106.76010131836",
          },
          {
            id: "1067",
            name: "Fortune Apartment",
            lat: "10.865657806397",
            lng: "106.75960540772",
          },
          {
            id: "1068",
            name: "Fresca Riverside",
            lat: "10.884717941284",
            lng: "106.73560333252",
          },
          {
            id: "1069",
            name: "Gia Ph\u00fa H\u01b0ng",
            lat: "10.864037513733",
            lng: "106.78116607666",
          },
          {
            id: "1070",
            name: "Green Home",
            lat: "10.855318069458",
            lng: "106.7470703125",
          },
          {
            id: "1071",
            name: "Green House",
            lat: "10.861849784851",
            lng: "106.75863647461",
          },
          {
            id: "1072",
            name: "Greenview Garden",
            lat: "10.844460487366",
            lng: "106.71942901611",
          },
          {
            id: "1073",
            name: "Heart Land Linh \u0110\u00f4ng",
            lat: "10.765069007873",
            lng: "106.63542938232",
          },
          {
            id: "1074",
            name: "HTReal Ho\u00e0n C\u1ea7u",
            lat: "10.883040428162",
            lng: "106.72640991211",
          },
          {
            id: "1075",
            name: "Jamona Home Resort",
            lat: "10.870589256287",
            lng: "106.71755981445",
          },
          {
            id: "1076",
            name: "KDC Bi\u0300nh Chi\u00ea\u0309u 2",
            lat: "10.876730918884",
            lng: "106.7261428833",
          },
          {
            id: "1077",
            name: "KDC H\u1ed3ng Long",
            lat: "10.848804473877",
            lng: "106.72303009033",
          },
          {
            id: "1078",
            name: "KDC H\u01b0ng Ph\u00fa",
            lat: "10.847543716431",
            lng: "106.73108673096",
          },
          {
            id: "1079",
            name: "KDC Nh\u00e0 Vi\u1ec7t",
            lat: "10.876829147339",
            lng: "106.72606658935",
          },
          {
            id: "1080",
            name: "K\u0110T B\u00ecnh Chi\u1ec3u",
            lat: "10.874020576477",
            lng: "106.72344207764",
          },
          {
            id: "1081",
            name: "Lan Ph\u01b0\u01a1ng MHBR",
            lat: "10.847046852112",
            lng: "106.75447082519",
          },
          {
            id: "1082",
            name: "Lavita Charm",
            lat: "10.82671546936",
            lng: "106.75691223144",
          },
          {
            id: "1083",
            name: "Lavita Garden",
            lat: "10.833784103394",
            lng: "106.76066589356",
          },
          {
            id: "1084",
            name: "Linh T\u00e2y",
            lat: "10.862103462219",
            lng: "106.75846862793",
          },
          {
            id: "1085",
            name: "Linh Trung",
            lat: "10.860907554627",
            lng: "106.78052520752",
          },
          {
            id: "1086",
            name: "Little Village",
            lat: "10.833019256592",
            lng: "106.72749328613",
          },
          {
            id: "1087",
            name: "Lotus Apartment",
            lat: "10.870831489563",
            lng: "106.74440765381",
          },
          {
            id: "1088",
            name: "Moonlight Residences",
            lat: "10.842835426331",
            lng: "106.76300811768",
          },
          {
            id: "1089",
            name: "M\u1ef9 An 3G",
            lat: "10.825702667236",
            lng: "106.72133636475",
          },
          {
            id: "1090",
            name: "M\u1ef9 Kim",
            lat: "10.825518608093",
            lng: "106.71983337402",
          },
          {
            id: "1091",
            name: "M\u1ef9 Long",
            lat: "10.825508117676",
            lng: "106.71920013428",
          },
          {
            id: "1092",
            name: "Newtown Apartment",
            lat: "10.824013710022",
            lng: "106.71964263916",
          },
          {
            id: "1093",
            name: "Opal Garden",
            lat: "10.827537536621",
            lng: "106.72274780273",
          },
          {
            id: "1094",
            name: "Opal Riverside",
            lat: "10.824551582336",
            lng: "106.72609710693",
          },
          {
            id: "1095",
            name: "Opal Skyview",
            lat: "10.833008766174",
            lng: "106.72750091553",
          },
          {
            id: "1096",
            name: "Opal Tower",
            lat: "10.833154678345",
            lng: "106.72740936279",
          },
          {
            id: "1097",
            name: "Ph\u00fa \u0110\u00f4ng 2",
            lat: "10.861868858337",
            lng: "106.74449157715",
          },
          {
            id: "1098",
            name: "S\u00e0i G\u00f2n Avenue",
            lat: "10.867641448975",
            lng: "106.73378753662",
          },
          {
            id: "1099",
            name: "S\u00e0i G\u00f2n Metro Park",
            lat: "10.838311195373",
            lng: "106.75314331055",
          },
          {
            id: "1100",
            name: "S\u00e0i G\u00f2n Riverside City",
            lat: "10.860200881958",
            lng: "106.72364807129",
          },
          {
            id: "1101",
            name: "Savico Hi\u1ec7p B\u00ecnh Ph\u01b0\u1edbc Tam B\u00ecnh",
            lat: "10.864169120789",
            lng: "106.72797393799",
          },
          {
            id: "1102",
            name: "S\u00f4ng \u0110\u00e0 Riverside",
            lat: "10.854322433472",
            lng: "106.72010040283",
          },
          {
            id: "1103",
            name: "Spring House",
            lat: "10.825255393982",
            lng: "106.71565246582",
          },
          {
            id: "1104",
            name: "Stown Th\u1ee7 \u0110\u1ee9c",
            lat: "10.883040428162",
            lng: "106.72640991211",
          },
          {
            id: "1105",
            name: "Sunview I, II",
            lat: "10.861505508423",
            lng: "106.73935699463",
          },
          {
            id: "1106",
            name: "Sunview Town",
            lat: "10.865708351135",
            lng: "106.72743225098",
          },
          {
            id: "1107",
            name: "T.N.T Building",
            lat: "10.857520103455",
            lng: "106.74083709717",
          },
          {
            id: "1108",
            name: "Tam B\u00ecnh",
            lat: "10.867647171021",
            lng: "106.73378753662",
          },
          {
            id: "1109",
            name: "TDH B\u00ecnh Chi\u1ec3u",
            lat: "10.876738548279",
            lng: "106.72492218018",
          },
          {
            id: "1110",
            name: "TDH Tr\u01b0\u1eddng Th\u1ecd",
            lat: "10.839807510376",
            lng: "106.76273345947",
          },
          {
            id: "1111",
            name: "T\u0110V Linh \u0110\u00f4ng",
            lat: "10.844245910644",
            lng: "106.7409286499",
          },
          {
            id: "1112",
            name: "T\u0110V Tam Ph\u00fa",
            lat: "10.861220359802",
            lng: "106.73960113525",
          },
          {
            id: "1113",
            name: "Tecco Linh \u0110\u00f4ng",
            lat: "10.849027633667",
            lng: "106.74716949463",
          },
          {
            id: "1114",
            name: "Th\u0103ng Long Home H\u01b0ng Ph\u00fa",
            lat: "10.861907958984",
            lng: "106.74443817139",
          },
          {
            id: "1115",
            name: "The Navita",
            lat: "10.86421585083",
            lng: "106.73346710205",
          },
          {
            id: "1116",
            name: "The Sun City Lan Ph\u01b0\u01a1ng",
            lat: "10.865387916565",
            lng: "106.72518157959",
          },
          {
            id: "1117",
            name: "The Sun City Riverview",
            lat: "10.849680900574",
            lng: "106.71417236328",
          },
          {
            id: "1118",
            name: "Thu Duc Garden Homes",
            lat: "10.838390350342",
            lng: "106.71282196045",
          },
          {
            id: "1119",
            name: "Th\u1ee7 \u0110\u1ee9c Riverside",
            lat: "10.879057884216",
            lng: "106.72396850586",
          },
          {
            id: "1120",
            name: "Van Phuc Riverside City",
            lat: "10.838145256043",
            lng: "106.71215057373",
          },
          {
            id: "1121",
            name: "V\u1ea1n Xu\u00e2n Tam B\u00ecnh",
            lat: "10.867095947266",
            lng: "106.74077606201",
          },
        ],
      },
    ],
  },
  {
    id: "2",
    code: "HN",
    name: "Ha\u0300 N\u00f4\u0323i",
    districts: [
      {
        id: "25",
        name: "Ba \u0110\u00ecnh",
        wards: [
          {
            id: "327",
            name: "C\u00f4\u0301ng Vi\u0323",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "328",
            name: "\u0110i\u00ea\u0323n Bi\u00ean",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "329",
            name: "\u0110\u00f4\u0323i C\u00e2\u0301n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "330",
            name: "Gia\u0309ng Vo\u0303",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "331", name: "Kim Ma\u0303", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "332",
            name: "Li\u00ea\u0303u Giai",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "333",
            name: "Ngo\u0323c Ha\u0300",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "334",
            name: "Ngo\u0323c Kha\u0301nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "335",
            name: "Nguy\u00ea\u0303n Trung Tr\u01b0\u0323c",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "336",
            name: "Phu\u0301c Xa\u0301",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "337",
            name: "Qua\u0301n Tha\u0301nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "338",
            name: "Tha\u0300nh C\u00f4ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "339",
            name: "Tru\u0301c Ba\u0323ch",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "340",
            name: "Vi\u0303nh Phu\u0301c",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "7219", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7220", name: "An X\u00e1", prefix: "Ph\u1ed1" },
          {
            id: "7221",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          {
            id: "7222",
            name: "B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7223",
            name: "B\u01b0\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7224",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7225",
            name: "C\u1ea7u Gi\u1ea5y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7226", name: "Ch\u00e2u Long", prefix: "Ph\u1ed1" },
          {
            id: "7227",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7228",
            name: "Ch\u00f9a M\u1ed9t C\u1ed9t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7229",
            name: "C\u01a1 X\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7230",
            name: "C\u1eeda B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7231", name: "C\u1eeda \u0110\u00f4ng", prefix: "Ph\u1ed1" },
          { id: "7232", name: "C\u1eeda Nam", prefix: "Ph\u1ed1" },
          {
            id: "7243",
            name: "D\u01b0\u01a1ng \u0110\u1ee9c Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7233", name: "\u0110\u1ea1i Y\u00ean", prefix: "Ph\u1ed1" },
          { id: "7234", name: "\u0110\u1eb7ng Dung", prefix: "Ph\u1ed1" },
          { id: "7235", name: "\u0110\u1eb7ng T\u1ea5t", prefix: "Ph\u1ed1" },
          {
            id: "7236",
            name: "\u0110\u00e0o T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7237",
            name: "\u0110\u00ea La Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7238",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7239",
            name: "\u0110\u1ed9c L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7240", name: "\u0110\u1ed1c Ng\u1eef", prefix: "Ph\u1ed1" },
          {
            id: "7241",
            name: "\u0110\u1ed9i C\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7242",
            name: "\u0110\u1ed9i Nh\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7244",
            name: "Giang V\u0103n Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7245", name: "Gi\u1ea3ng V\u00f5", prefix: "Ph\u1ed1" },
          { id: "7246", name: "H\u00e0ng B\u00fan", prefix: "Ph\u1ed1" },
          { id: "7247", name: "H\u00e0ng D\u1ea7u", prefix: "Ph\u1ed1" },
          {
            id: "7248",
            name: "H\u00e0ng Than",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7249",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7250",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7251",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7252", name: "H\u00f2e Nhai", prefix: "Ph\u1ed1" },
          {
            id: "7253",
            name: "H\u1ed3ng H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7254", name: "H\u1ed3ng Ph\u00fac", prefix: "Ph\u1ed1" },
          {
            id: "7255",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7256", name: "Huy\u1ec7n Thanh Quan", prefix: "Ph\u1ed1" },
          {
            id: "7257",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7258",
            name: "Khu t\u1eadp th\u1ec3 Th\u00e0nh C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7259",
            name: "Kh\u00fac H\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7260", name: "Kim M\u00e3", prefix: "Ph\u1ed1" },
          {
            id: "7261",
            name: "Kim M\u00e3 Th\u01b0\u1ee3ng",
            prefix: "Ph\u1ed1",
          },
          { id: "7262", name: "La Th\u00e0nh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7263",
            name: "L\u1ea1c Ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7264", name: "L\u00e1ng H\u1ea1", prefix: "Ph\u1ed1" },
          {
            id: "7265",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7266",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7267",
            name: "L\u00ea Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7268", name: "Li\u1ec5u Giai", prefix: "Ph\u1ed1" },
          { id: "7269", name: "Linh Lang", prefix: "Ph\u1ed1" },
          {
            id: "7270",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7271", name: "Mai Anh Tu\u1ea5n", prefix: "Ph\u1ed1" },
          { id: "7272", name: "Nam Cao", prefix: "Ph\u1ed1" },
          { id: "7273", name: "Nam Tr\u00e0ng", prefix: "Ph\u1ed1" },
          {
            id: "7274",
            name: "Ngh\u0129a D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7275",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7276",
            name: "Ng\u1ecdc H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7277", name: "Ng\u1ecdc Kh\u00e1nh", prefix: "Ph\u1ed1" },
          { id: "7278", name: "Ng\u0169 X\u00e3", prefix: "Ph\u1ed1" },
          {
            id: "7279",
            name: "Nguy\u1ec5n Bi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7280",
            name: "Nguy\u1ec5n C\u1ea3nh Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7281",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7282",
            name: "Nguy\u1ec5n C\u00f4ng Hoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7283",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7284",
            name: "Nguy\u00ean H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7285",
            name: "Nguy\u1ec5n Huy Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7286",
            name: "Nguy\u1ec5n Kh\u1eafc Hi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7287", name: "Nguy\u1ec5n Kh\u1eafc Nhu", prefix: "Ph\u1ed1" },
          {
            id: "7288",
            name: "Nguy\u1ec5n Ph\u1ea1m Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7289",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7290",
            name: "Nguy\u1ec5n Thi\u1ebfp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7291",
            name: "Nguy\u1ec5n Thi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7292",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7293",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7294",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7295",
            name: "Nguy\u1ec5n V\u0103n Ng\u1ecdc",
            prefix: "Ph\u1ed1",
          },
          {
            id: "7296",
            name: "N\u00fai Tr\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7297",
            name: "\u00d4ng \u00cdch Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7298",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7299", name: "Ph\u1ea1m Huy Th\u00f4ng", prefix: "Ph\u1ed1" },
          {
            id: "7300",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7301",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7302",
            name: "Phan Huy \u00cdch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7303",
            name: "Phan K\u1ebf B\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7304",
            name: "Ph\u00f3 \u0110\u1ee9c Ch\u00ednh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "7305",
            name: "Ph\u00fac X\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7306",
            name: "Qu\u1ea7n Ng\u1ef1a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7307",
            name: "Qu\u00e1n Th\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7308", name: "S\u01a1n T\u00e2y", prefix: "Ph\u1ed1" },
          { id: "7309", name: "Tam \u0110a", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7310",
            name: "T\u00e2n \u1ea4p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7311", name: "Thanh B\u1ea3o", prefix: "Ph\u1ed1" },
          {
            id: "7312",
            name: "Th\u00e0nh C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7313",
            name: "Th\u00e0nh C\u00f4ng B\u1eafc",
            prefix: "Ph\u1ed1",
          },
          {
            id: "7314",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7315",
            name: "Th\u1ee5y Khu\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7316",
            name: "T\u00f4n Th\u1ea5t \u0110\u1ea1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7317",
            name: "T\u00f4n Th\u1ea5t Thi\u1ec7p",
            prefix: "Ph\u1ed1",
          },
          {
            id: "7318",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7319",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7320",
            name: "Tr\u1ea7n T\u1ebf X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7321", name: "Tr\u1ea5n V\u0169", prefix: "Ph\u1ed1" },
          {
            id: "7322",
            name: "Tr\u1ecbnh Ho\u00e0i \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7323",
            name: "Tr\u00fac B\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7324",
            name: "Tr\u00fac L\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7325",
            name: "V\u1ea1n B\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7326", name: "V\u0103n Cao", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7327", name: "V\u1ea1n Ph\u00fac", prefix: "Ph\u1ed1" },
          {
            id: "7328",
            name: "V\u0129nh Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7329",
            name: "V\u0129nh Ph\u00fac 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7330",
            name: "Vu\u0303 Tha\u0323nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7331", name: "Y\u00ean Ninh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7332",
            name: "Y\u00ean Ph\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7333",
            name: "Y\u00ean Th\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1122",
            name: "134 Qu\u00e1n Th\u00e1nh",
            lat: "21.042512893677",
            lng: "105.84234619141",
          },
          {
            id: "1123",
            name: "15-17 Ng\u1ecdc Kh\u00e1nh",
            lat: "21.029512405396",
            lng: "105.81816864014",
          },
          {
            id: "1124",
            name: "16 Li\u1ec5u Giai",
            lat: "21.034103393555",
            lng: "105.81442260742",
          },
          {
            id: "1125",
            name: "222 \u0110\u1ed9i C\u1ea5n",
            lat: "21.035457611084",
            lng: "105.81873321533",
          },
          {
            id: "1126",
            name: "6 \u0110\u1ed9i Nh\u00e2n",
            lat: "21.041635513306",
            lng: "105.81187438965",
          },
          {
            id: "1127",
            name: "671 Ho\u00e0ng Hoa Th\u00e1m",
            lat: "21.04705619812",
            lng: "105.80774688721",
          },
          {
            id: "1128",
            name: "Artex Building 172 Ng\u1ecdc Kh\u00e1nh",
            lat: "21.024168014526",
            lng: "105.81959533691",
          },
          {
            id: "1129",
            name: "B1 Gi\u1ea3ng V\u00f5",
            lat: "21.028631210327",
            lng: "105.82160186768",
          },
          {
            id: "1130",
            name: "Bi\u1ec3n B\u1eafc",
            lat: "21.027513504028",
            lng: "105.80941772461",
          },
          {
            id: "1131",
            name: "C7 Gi\u1ea3ng V\u00f5",
            lat: "21.028581619263",
            lng: "105.82144927978",
          },
          {
            id: "1132",
            name: "Candeo Hotels H\u00e0 N\u1ed9i",
            lat: "21.035575866699",
            lng: "105.8183670044",
          },
          {
            id: "1133",
            name: "CC \u0110\u01b0\u1eddng S\u1eaft - 35 L\u00e1ng H\u1ea1",
            lat: "21.021657943726",
            lng: "105.81848144531",
          },
          {
            id: "1134",
            name: "Chung c\u01b0 31 L\u00e1ng H\u1ea1",
            lat: "21.020389556885",
            lng: "105.81776428223",
          },
          {
            id: "1135",
            name: "Chung c\u01b0 345 \u0110\u1ed9i C\u1ea5n",
            lat: "21.036424636841",
            lng: "105.81623077393",
          },
          {
            id: "1136",
            name: "Chung c\u01b0 379 \u0110\u1ed9i C\u1ea5n",
            lat: "21.039001464844",
            lng: "105.81282806397",
          },
          {
            id: "1137",
            name: "Chung c\u01b0 C1 Th\u00e0nh C\u00f4ng",
            lat: "21.020889282227",
            lng: "105.81581878662",
          },
          {
            id: "1138",
            name: "D2 Gi\u1ea3ng V\u00f5",
            lat: "21.025936126709",
            lng: "105.82218933106",
          },
          {
            id: "1139",
            name: "Discovery Complex 2",
            lat: "21.032028198242",
            lng: "105.83302307129",
          },
          {
            id: "1140",
            name: "DMC Tower",
            lat: "21.029407501221",
            lng: "105.81020355225",
          },
          {
            id: "1141",
            name: "Flamingo Tower",
            lat: "21.035278320312",
            lng: "105.82131195068",
          },
          {
            id: "1142",
            name: "H\u00e0 N\u1ed9i Aqua Central",
            lat: "21.044471740723",
            lng: "105.84562683106",
          },
          {
            id: "1143",
            name: "Hanoi Daewoo Hotel",
            lat: "21.030689239502",
            lng: "105.81179046631",
          },
          {
            id: "1144",
            name: "HAREC Building",
            lat: "21.02206993103",
            lng: "105.81874084473",
          },
          {
            id: "1145",
            name: "HITTC Building",
            lat: "21.025812149048",
            lng: "105.82264709473",
          },
          {
            id: "1146",
            name: "H\u00f2a B\u00ecnh Green",
            lat: "21.041240692139",
            lng: "105.80784606934",
          },
          {
            id: "1147",
            name: "ITT Building",
            lat: "21.036609649658",
            lng: "105.81565856934",
          },
          {
            id: "1148",
            name: "Lake View Building",
            lat: "21.027061462402",
            lng: "105.81948852539",
          },
          {
            id: "1149",
            name: "Lotte Center H\u00e0 N\u1ed9i",
            lat: "21.03165435791",
            lng: "105.81290435791",
          },
          {
            id: "1150",
            name: "Newtatco 21 L\u00e1ng H\u1ea1",
            lat: "21.021005630493",
            lng: "105.81826782227",
          },
          {
            id: "1151",
            name: "Newtatco V\u0129nh Ph\u00fac",
            lat: "21.042627334595",
            lng: "105.80751800537",
          },
          {
            id: "1152",
            name: "Ng\u1ecdc Kh\u00e1nh Plaza",
            lat: "21.026321411133",
            lng: "105.81111907959",
          },
          {
            id: "1153",
            name: "NIKKO",
            lat: "21.041913986206",
            lng: "105.84680175781",
          },
          {
            id: "1154",
            name: "N\u00fai Tr\u00fac Square",
            lat: "21.03021812439",
            lng: "105.8229675293",
          },
          {
            id: "1155",
            name: "Platinum Residences",
            lat: "21.027067184448",
            lng: "105.81745147705",
          },
          {
            id: "1156",
            name: "Resco Tower",
            lat: "21.029546737671",
            lng: "105.81145477295",
          },
          {
            id: "1157",
            name: "Rose Garden",
            lat: "21.024799346924",
            lng: "105.81883239746",
          },
          {
            id: "1158",
            name: "Skyline Tower",
            lat: "21.043102264404",
            lng: "105.84161376953",
          },
          {
            id: "1159",
            name: "Sun Grand City V\u0103n Cao",
            lat: "21.03851890564",
            lng: "105.81559753418",
          },
          {
            id: "1160",
            name: "Th\u00e0nh C\u00f4ng Tower 57 L\u00e1ng H\u1ea1",
            lat: "21.019260406494",
            lng: "105.81726837158",
          },
          {
            id: "1161",
            name: "Th\u00e0nh \u0110\u00f4ng",
            lat: "21.031934738159",
            lng: "105.82517242432",
          },
          {
            id: "1162",
            name: "Thanh Ni\u00ean Plaza",
            lat: "21.037805557251",
            lng: "105.81545257568",
          },
          {
            id: "1163",
            name: "The Golden Armor",
            lat: "21.029693603516",
            lng: "105.82151031494",
          },
          {
            id: "1164",
            name: "The Lancaster H\u00e0 N\u1ed9i",
            lat: "21.028713226318",
            lng: "105.82358551025",
          },
          {
            id: "1165",
            name: "TID Tower",
            lat: "21.036985397339",
            lng: "105.81475830078",
          },
          {
            id: "1166",
            name: "T\u1ed5 h\u1ee3p s\u1ed1 5 Th\u00e0nh C\u00f4ng",
            lat: "21.020088195801",
            lng: "105.81594848633",
          },
          {
            id: "1167",
            name: "Toserco Building",
            lat: "21.03081703186",
            lng: "105.82013702393",
          },
          {
            id: "1168",
            name: "Tr\u00fac B\u1ea1ch",
            lat: "21.040447235107",
            lng: "105.82469177246",
          },
          {
            id: "1169",
            name: "Vinhomes Gi\u1ea3ng V\u00f5",
            lat: "21.02575302124",
            lng: "105.81820678711",
          },
          {
            id: "1170",
            name: "Vinhomes Metropolis - Li\u1ec5u Giai",
            lat: "21.031799316406",
            lng: "105.81419372559",
          },
          {
            id: "1171",
            name: "VIT Tower",
            lat: "21.029567718506",
            lng: "105.8117980957",
          },
          {
            id: "1172",
            name: "X1-26 Li\u1ec5u Giai",
            lat: "21.035438537598",
            lng: "105.81414031982",
          },
        ],
      },
      {
        id: "26",
        name: "Ba V\u00ec",
        wards: [
          { id: "341", name: "Ba Tr\u1ea1i", prefix: "X\u00e3" },
          { id: "342", name: "Ba V\u00ec", prefix: "X\u00e3" },
          { id: "343", name: "C\u1ea9m L\u0129nh", prefix: "X\u00e3" },
          { id: "344", name: "Cam Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "345", name: "Ch\u00e2u S\u01a1n", prefix: "X\u00e3" },
          { id: "346", name: "Chu Minh", prefix: "X\u00e3" },
          { id: "347", name: "C\u1ed5 \u0110\u00f4", prefix: "X\u00e3" },
          { id: "348", name: "\u0110\u00f4ng Quang", prefix: "X\u00e3" },
          { id: "349", name: "\u0110\u1ed3ng Th\u00e1i", prefix: "X\u00e3" },
          { id: "350", name: "Kh\u00e1nh Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "351", name: "Minh Ch\u00e2u", prefix: "X\u00e3" },
          { id: "352", name: "Minh Quang", prefix: "X\u00e3" },
          { id: "353", name: "Phong V\u00e2n", prefix: "X\u00e3" },
          { id: "354", name: "Ph\u00fa Ch\u00e2u", prefix: "X\u00e3" },
          { id: "355", name: "Ph\u00fa C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "356", name: "Ph\u00fa \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "357", name: "Ph\u00fa Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "358", name: "Ph\u00fa S\u01a1n", prefix: "X\u00e3" },
          { id: "359", name: "S\u01a1n \u0110\u00e0", prefix: "X\u00e3" },
          { id: "360", name: "T\u1ea3n H\u1ed3ng", prefix: "X\u00e3" },
          { id: "361", name: "T\u1ea3n L\u0129nh", prefix: "X\u00e3" },
          {
            id: "362",
            name: "T\u00e2y \u0110\u1eb1ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "363", name: "Th\u00e1i H\u00f2a", prefix: "X\u00e3" },
          { id: "364", name: "Thu\u1ea7n M\u1ef9", prefix: "X\u00e3" },
          { id: "365", name: "Th\u1ee5y An", prefix: "X\u00e3" },
          { id: "366", name: "Ti\u00ean Phong", prefix: "X\u00e3" },
          { id: "367", name: "T\u00f2ng B\u1ea1t", prefix: "X\u00e3" },
          { id: "368", name: "V\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "369", name: "V\u1ea1n Th\u1eafng", prefix: "X\u00e3" },
          { id: "370", name: "V\u1eadt L\u1ea1i", prefix: "X\u00e3" },
          { id: "371", name: "Y\u00ean B\u00e0i", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "7334", name: "Chu Minh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7335",
            name: "\u0110\u1ea1i l\u1ed9 Th\u0103ng Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7336", name: "\u0110T 91", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7337",
            name: "Ph\u00f4\u0301 Na\u0309",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7338",
            name: "Qu\u1ed1c l\u1ed9 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7339",
            name: "Qu\u1ed1c L\u1ed9 414",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7340",
            name: "T\u1ea3n L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7341",
            name: "T\u1ec9nh L\u1ed9 411C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7342",
            name: "T\u1ec9nh l\u1ed9 414",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7343",
            name: "T\u1ec9nh l\u1ed9 416",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7344",
            name: "T\u1ec9nh l\u1ed9 84",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7345",
            name: "T\u1ec9nh L\u1ed9 87A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7346",
            name: "T\u1ec9nh l\u1ed9 88",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7347",
            name: "V\u0129nh H\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1173",
            name: "Country House",
            lat: "21.199230194092",
            lng: "105.42321014404",
          },
          {
            id: "1174",
            name: "Green Villas 4",
            lat: "21.034170150757",
            lng: "105.44195556641",
          },
          {
            id: "1175",
            name: "Ng\u1ecdc Vi\u00ean Islands",
            lat: "21.073888778686",
            lng: "105.45497131348",
          },
          {
            id: "1176",
            name: "Nine Ivory Eco",
            lat: "21.034170150757",
            lng: "105.44195556641",
          },
          {
            id: "1177",
            name: "Royal Garden",
            lat: "21.034170150757",
            lng: "105.44195556641",
          },
          {
            id: "1178",
            name: "Su\u1ed1i Hai Villa",
            lat: "21.125679016113",
            lng: "105.34677886963",
          },
          {
            id: "1179",
            name: "T\u1ea3n Vi\u00ean Villas",
            lat: "21.066980361939",
            lng: "105.4009475708",
          },
          {
            id: "1180",
            name: "The Grand Arena Hill",
            lat: "21.056848526001",
            lng: "105.32900238037",
          },
          {
            id: "1181",
            name: "The Queen Villas",
            lat: "21.034278869629",
            lng: "105.434715271",
          },
          {
            id: "1182",
            name: "Y\u00ean B\u00e0i Top Hills",
            lat: "21.034170150757",
            lng: "105.44195556641",
          },
          {
            id: "1183",
            name: "Y\u00ean B\u00e0i Villas 2",
            lat: "21.036722183227",
            lng: "105.43798065185",
          },
        ],
      },
      {
        id: "27",
        name: "B\u1eafc T\u1eeb Li\u00eam",
        wards: [
          {
            id: "372",
            name: "C\u1ed5 Nhu\u1ebf 1",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "373",
            name: "C\u1ed5 Nhu\u1ebf 2",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "374", name: "\u0110\u00f4ng Ng\u1ea1c", prefix: "X\u00e3" },
          {
            id: "375",
            name: "\u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "376", name: "Li\u00ean M\u1ea1c", prefix: "X\u00e3" },
          { id: "377", name: "Minh Khai", prefix: "X\u00e3" },
          { id: "378", name: "Ph\u00fa Di\u1ec5n", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "379",
            name: "Ph\u00fac Di\u1ec5n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "380", name: "T\u00e2y T\u1ef1u", prefix: "X\u00e3" },
          { id: "381", name: "Th\u01b0\u1ee3ng C\u00e1t", prefix: "X\u00e3" },
          { id: "382", name: "Th\u1ee5y Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          {
            id: "383",
            name: "Xu\u00e2n \u0110\u1ec9nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "384", name: "Xu\u00e2n T\u1ea3o", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "7348", name: "104", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7349", name: "32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7350", name: "69", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7351", name: "70", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7352",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7353",
            name: "C\u1ea7u Di\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7354", name: "C\u1ea7u Noi", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7355",
            name: "C\u1ea7u V\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7356",
            name: "Ch\u00e2u \u0110\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7357", name: "CN5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7358",
            name: "C\u1ed5 Nhu\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7359",
            name: "\u0110\u1ea1i C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7360",
            name: "\u0110\u1ea1i C\u00e1t 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7361",
            name: "\u0110\u1eb7ng Th\u00f9y Tr\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7362",
            name: "\u0110i\u1ec7n C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7363",
            name: "\u0110\u00ecnh Qu\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7364", name: "\u0110\u1ed7 Nhu\u1eadn", prefix: "Ph\u1ed1" },
          {
            id: "7365",
            name: "\u0110\u00f4ng Ng\u1ea1c",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "7366",
            name: "\u0110\u1ee9c Di\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7367",
            name: "\u0110\u1ee9c Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7368",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7369",
            name: "Ho\u00e0ng C\u00f4ng Ch\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7370",
            name: "Ho\u00e0ng Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7371",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7372",
            name: "Ho\u00e0ng T\u0103ng B\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7373",
            name: "Ho\u00e0ng X\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7374", name: "K1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7375", name: "K1B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7376", name: "K2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7377", name: "K4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7378", name: "K\u1ebb V\u1ebd", prefix: "Ph\u1ed1" },
          { id: "7379", name: "Ki\u1ec1u Mai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7380",
            name: "L\u00ea V\u0103n Hi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7381",
            name: "L\u00ea V\u0103n L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7382",
            name: "Li\u00ean M\u1ea1c",
            prefix: "\u0111\u01b0\u1eddng",
          },
          { id: "7383", name: "M\u1ea1c X\u00e1", prefix: "Ph\u1ed1" },
          {
            id: "7384",
            name: "Ng\u1ecda Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7385",
            name: "Nguy\u1ec5n Ho\u00e0ng T\u00f4n",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "7386",
            name: "Nguy\u1ec5n V\u0103n Huy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7387",
            name: "Nguy\u00ean X\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7388",
            name: "Nh\u1eadt T\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7389", name: "Nh\u1ed5n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7390",
            name: "Ph\u1ea1m Tu\u1ea5n T\u00e0i",
            prefix: "Ph\u1ed1",
          },
          {
            id: "7391",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7392",
            name: "Phan B\u00e1 V\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7393",
            name: "Ph\u1ed1 \u0110\u0103m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7394",
            name: "Ph\u1ed1 Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7395",
            name: "Ph\u00fa Di\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7396",
            name: "Ph\u00fa Ki\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7397", name: "Ph\u00fa Minh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7398",
            name: "Ph\u00fac Di\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7399",
            name: "Ph\u00fac L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7400",
            name: "Ph\u00fac Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7401", name: "Ph\u00fac Vinh", prefix: "Ph\u1ed1" },
          {
            id: "7402",
            name: "Qu\u1ed1c l\u1ed9 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7403",
            name: "Qu\u1ed1c l\u1ed9 70",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7404", name: "S\u1ed1 32", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7405",
            name: "S\u00f4ng Nhu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7406",
            name: "T\u00e2n \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7407",
            name: "T\u00e2n Nhu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7408",
            name: "T\u00e2n Nhu\u1ec7 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7409",
            name: "T\u00e2n Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7410",
            name: "T\u00e2n Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7411",
            name: "T\u00e2n Xu\u00e2n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7412",
            name: "T\u0103ng Thi\u1ebft Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7413",
            name: "T\u00e2y T\u1ef1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7414", name: "Th\u01b0\u1ee3ng C\u00e1t", prefix: "Ph\u1ed1" },
          {
            id: "7415",
            name: "Th\u1ee5y Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7416",
            name: "T\u1ec9nh l\u1ed9 70A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7417",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7418", name: "Tr\u1ea7n Cung", prefix: "Ph\u1ed1" },
          {
            id: "7419",
            name: "Trung T\u1ef1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7420",
            name: "V\u0103n Ti\u1ebfn D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7421",
            name: "V\u00e2n Tr\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7422",
            name: "V\u0103n Tr\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7423",
            name: "V\u0103n Tr\u00ec 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7424",
            name: "V\u00f5 Q\u00fay Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7425",
            name: "Xu\u00e2n \u0110\u1ec9nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7426", name: "Xu\u00e2n La", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7427",
            name: "Y\u00ean N\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1184",
            name: "120 Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t BQP",
            lat: "21.046228408814",
            lng: "105.79166412353",
          },
          {
            id: "1185",
            name: "An B\u00ecnh City",
            lat: "21.049566268921",
            lng: "105.7785949707",
          },
          {
            id: "1186",
            name: "An B\u00ecnh Tower",
            lat: "21.069675445557",
            lng: "105.77705383301",
          },
          {
            id: "1187",
            name: "B5 C\u1ea7u Di\u1ec5n",
            lat: "21.049613952637",
            lng: "105.77079772949",
          },
          {
            id: "1188",
            name: "C1 C2 Xu\u00e2n \u0110\u1ec9nh",
            lat: "21.070245742798",
            lng: "105.79147338867",
          },
          {
            id: "1189",
            name: "Chung c\u01b0 199 H\u1ed3 T\u00f9ng M\u1eadu",
            lat: "21.040212631226",
            lng: "105.76447296143",
          },
          {
            id: "1190",
            name: "Chung c\u01b0 789 Xu\u00e2n \u0110\u1ec9nh",
            lat: "21.068014144898",
            lng: "105.79708099365",
          },
          {
            id: "1191",
            name: "Chung c\u01b0 T\u00e2y T\u1ef1u BQP",
            lat: "21.044973373413",
            lng: "105.75218200684",
          },
          {
            id: "1192",
            name: "CT2A Ngh\u0129a \u0110\u00f4",
            lat: "21.051164627075",
            lng: "105.79280090332",
          },
          {
            id: "1193",
            name: "CT2B Ngh\u0129a \u0110\u00f4",
            lat: "21.051193237305",
            lng: "105.79364013672",
          },
          {
            id: "1194",
            name: "CT3 C\u1ed5 Nhu\u1ebf",
            lat: "21.050870895386",
            lng: "105.7861251831",
          },
          {
            id: "1195",
            name: "Ecohome 1",
            lat: "21.086324691773",
            lng: "105.78231811523",
          },
          {
            id: "1196",
            name: "Ecohome 2",
            lat: "21.083793640137",
            lng: "105.78446960449",
          },
          {
            id: "1197",
            name: "Goldmark City",
            lat: "21.040336608887",
            lng: "105.7675857544",
          },
          {
            id: "1198",
            name: "Green Planet",
            lat: "21.040382385254",
            lng: "105.76299285889",
          },
          {
            id: "1199",
            name: "Green Stars",
            lat: "21.052398681641",
            lng: "105.78018188477",
          },
          {
            id: "1200",
            name: "Habico Tower",
            lat: "21.053640365601",
            lng: "105.78202056885",
          },
          {
            id: "1201",
            name: "Hanhud Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            lat: "21.049615859985",
            lng: "105.78385925293",
          },
          {
            id: "1202",
            name: "IA20 Ciputra",
            lat: "21.091917037964",
            lng: "105.78500366211",
          },
          {
            id: "1203",
            name: "Intracom 2 C\u1ea7u Di\u1ec5n",
            lat: "21.041986465454",
            lng: "105.75904083252",
          },
          {
            id: "1204",
            name: "Intracom 3",
            lat: "21.065158843994",
            lng: "105.768409729",
          },
          {
            id: "1205",
            name: "K\u0110T C\u1ed5 Nhu\u1ebf",
            lat: "21.050373077393",
            lng: "105.78484344482",
          },
          {
            id: "1206",
            name: "Khu Ngo\u1ea1i Giao \u0110o\u00e0n",
            lat: "21.065839767456",
            lng: "105.79814910889",
          },
          {
            id: "1207",
            name: "Kim C\u01b0\u01a1ng Xu\u00e2n \u0110\u1ec9nh",
            lat: "21.071130752564",
            lng: "105.79109191894",
          },
          {
            id: "1208",
            name: "Kosmo T\u00e2y H\u1ed3",
            lat: "21.064056396484",
            lng: "105.80647277832",
          },
          {
            id: "1209",
            name: "Lakeside Residences - Goldmark City",
            lat: "21.040225982666",
            lng: "105.76752471924",
          },
          {
            id: "1210",
            name: "Long Giang Tower",
            lat: "21.071636199951",
            lng: "105.78974151611",
          },
          {
            id: "1211",
            name: "Megastar T\u00e2y H\u1ed3",
            lat: "21.068765640259",
            lng: "105.78651428223",
          },
          {
            id: "1212",
            name: "N01-T1 Ngo\u1ea1i Giao \u0110o\u00e0n",
            lat: "21.06575012207",
            lng: "105.79821014404",
          },
          {
            id: "1213",
            name: "N01-T2 Ngo\u1ea1i Giao \u0110o\u00e0n",
            lat: "21.06348991394",
            lng: "105.7949295044",
          },
          {
            id: "1214",
            name: "N01-T3 Ngo\u1ea1i Giao \u0110o\u00e0n",
            lat: "21.06308555603",
            lng: "105.79482269287",
          },
          {
            id: "1215",
            name: "N01-T4 Ngo\u1ea1i Giao \u0110o\u00e0n",
            lat: "21.063035964966",
            lng: "105.79541778565",
          },
          {
            id: "1216",
            name: "N01-T5 Ngo\u1ea1i Giao \u0110o\u00e0n",
            lat: "21.063035964966",
            lng: "105.79597473144",
          },
          {
            id: "1217",
            name: "N01-T6 Ngo\u1ea1i Giao \u0110o\u00e0n",
            lat: "21.063737869263",
            lng: "105.79704284668",
          },
          {
            id: "1218",
            name: "N01-T7 Ngo\u1ea1i Giao \u0110o\u00e0n",
            lat: "21.063575744629",
            lng: "105.79531097412",
          },
          {
            id: "1219",
            name: "N01-T8 Ngo\u1ea1i Giao \u0110o\u00e0n",
            lat: "21.063655853271",
            lng: "105.79613494873",
          },
          {
            id: "1220",
            name: "N02-T1 Ngo\u1ea1i Giao \u0110o\u00e0n",
            lat: "21.066165924072",
            lng: "105.79811859131",
          },
          {
            id: "1221",
            name: "N02-T2 Ngo\u1ea1i Giao \u0110o\u00e0n",
            lat: "21.066650390625",
            lng: "105.79803466797",
          },
          {
            id: "1222",
            name: "N02-T3 Ngo\u1ea1i Giao \u0110o\u00e0n",
            lat: "21.066110610962",
            lng: "105.79805755615",
          },
          {
            id: "1223",
            name: "N03-T1 Ngo\u1ea1i Giao \u0110o\u00e0n",
            lat: "21.065765380859",
            lng: "105.79808044434",
          },
          {
            id: "1224",
            name: "N03-T2 Ngo\u1ea1i Giao \u0110o\u00e0n",
            lat: "21.065017700195",
            lng: "105.79893493652",
          },
          {
            id: "1225",
            name: "N03-T3&T4 Ngo\u1ea1i Giao \u0110o\u00e0n",
            lat: "21.064430236816",
            lng: "105.79842376709",
          },
          {
            id: "1226",
            name: "N03-T5 Ngo\u1ea1i Giao \u0110o\u00e0n",
            lat: "21.064184188843",
            lng: "105.79916381836",
          },
          {
            id: "1227",
            name: "N03-T6 Ngo\u1ea1i Giao \u0110o\u00e0n",
            lat: "21.064977645874",
            lng: "105.79773712158",
          },
          {
            id: "1228",
            name: "N03-T7 Ngo\u1ea1i Giao \u0110o\u00e0n",
            lat: "21.063575744629",
            lng: "105.79537200928",
          },
          {
            id: "1229",
            name: "N03-T8 Ngo\u1ea1i Giao \u0110o\u00e0n",
            lat: "21.064998626709",
            lng: "105.80028533935",
          },
          {
            id: "1230",
            name: "N04A Ngo\u1ea1i Giao \u0110o\u00e0n",
            lat: "21.066186904907",
            lng: "105.80116271973",
          },
          {
            id: "1231",
            name: "N04B Ngo\u1ea1i Giao \u0110o\u00e0n",
            lat: "21.065879821777",
            lng: "105.80138397217",
          },
          {
            id: "1232",
            name: "Ngh\u0129a \u0110\u00f4",
            lat: "21.050580978394",
            lng: "105.79224395752",
          },
          {
            id: "1233",
            name: "Nh\u00e0 \u1edf c\u00e1n b\u1ed9 nh\u00e2n vi\u00ean TP. H\u00e0 N\u1ed9i",
            lat: "21.073970794678",
            lng: "105.7908706665",
          },
          {
            id: "1234",
            name: "Nh\u00e0 \u1edf cho CBCS B\u1ed9 C\u00f4ng an",
            lat: "21.066087722778",
            lng: "105.76396942139",
          },
          {
            id: "1235",
            name: "Resco C\u1ed5 Nhu\u1ebf",
            lat: "21.068120956421",
            lng: "105.78330230713",
          },
          {
            id: "1236",
            name: "Scitech Tower",
            lat: "21.041013717651",
            lng: "105.76511383057",
          },
          {
            id: "1237",
            name: "Sunshine City",
            lat: "21.091917037964",
            lng: "105.78500366211",
          },
          {
            id: "1238",
            name: "Th\u00e0nh Ph\u1ed1 Giao L\u01b0u",
            lat: "21.050914764404",
            lng: "105.77820587158",
          },
          {
            id: "1239",
            name: "The Link 345-CT1",
            lat: "21.078651428223",
            lng: "105.80261230469",
          },
          {
            id: "1240",
            name: "T\u1ed5ng c\u1ee5c 5 B\u1ed9 C\u00f4ng An",
            lat: "21.050596237183",
            lng: "105.7829208374",
          },
          {
            id: "1241",
            name: "VC7 Housing Complex - 136 H\u1ed3 T\u00f9ng M\u1eadu",
            lat: "21.045133590698",
            lng: "105.76779174805",
          },
          {
            id: "1242",
            name: "Vibex T\u1eeb Li\u00eam",
            lat: "21.088706970215",
            lng: "105.77491760254",
          },
        ],
      },
      {
        id: "28",
        name: "C\u1ea7u Gi\u1ea5y",
        wards: [
          {
            id: "385",
            name: "D\u1ecbch V\u1ecdng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "386",
            name: "D\u1ecbch V\u1ecdng H\u1eadu",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "387", name: "Mai D\u1ecbch", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "388",
            name: "Ngh\u0129a \u0110\u00f4",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "389",
            name: "Ngh\u0129a T\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "390", name: "Quan Hoa", prefix: "Ph\u01b0\u1eddng" },
          { id: "391", name: "Trung H\u00f2a", prefix: "Ph\u01b0\u1eddng" },
          { id: "392", name: "Y\u00ean H\u00f2a", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "7428", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7429", name: "32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7430", name: "800A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7431",
            name: "B\u1ea3o Th\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7432",
            name: "B\u1edd S\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7433",
            name: "B\u01b0\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7434", name: "C4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7435",
            name: "C\u1ea7u Gi\u1ea5y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7436",
            name: "C\u1ea7u v\u01b0\u1ee3t Mai D\u1ecbch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7437", name: "CD2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7438", name: "CD3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7439", name: "Ch\u00f9a H\u00e0", prefix: "Ph\u1ed1" },
          {
            id: "7440",
            name: "C\u1ed1m V\u00f2ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7443",
            name: "D\u1ecbch V\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7444",
            name: "D\u1ecbch V\u1ecdng H\u1eadu",
            prefix: "Ph\u1ed1",
          },
          {
            id: "7447",
            name: "Do\u00e3n K\u1ebf Thi\u1ec7n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "7452",
            name: "D\u01b0\u01a1ng \u0110\u00ecnh Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7453",
            name: "D\u01b0\u01a1ng Khu\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7454",
            name: "D\u01b0\u01a1ng Qu\u1ea3ng H\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7455", name: "Duy T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7441",
            name: "\u0110\u1ea7m B\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7442",
            name: "\u0110\u1eb7ng Th\u00f9y Tr\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7445",
            name: "\u0110i\u1ec7n Bi\u00ean 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7446", name: "\u0110\u1ed7 Quang", prefix: "Ph\u1ed1" },
          {
            id: "7448",
            name: "\u0110\u1ed3ng B\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7449", name: "\u0110\u1ed3ng B\u00e1t A", prefix: "Ph\u1ed1" },
          {
            id: "7450",
            name: "\u0110\u1ed3ng B\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7451",
            name: "\u0110\u00f4ng Quan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7456",
            name: "H\u1ea1 Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7457", name: "H\u00e0m Nghi", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7458",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7459", name: "Hoa B\u1eb1ng", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7460",
            name: "Ho\u00e0ng \u0110\u1ea1o Th\u00fay",
            prefix: "Ph\u1ed1",
          },
          {
            id: "7461",
            name: "Ho\u00e0ng Minh Gi\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7462",
            name: "Ho\u00e0ng Ng\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7463",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7464", name: "Ho\u00e0ng S\u00e2m", prefix: "Ph\u1ed1" },
          {
            id: "7465",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7466", name: "K800A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7467",
            name: "Khu\u1ea5t Duy Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7468",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7469", name: "L\u00e1ng", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7470",
            name: "L\u00e1ng H\u00f2a L\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7471",
            name: "L\u00ea \u0110\u1ee9c Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7472",
            name: "L\u00ea V\u0103n L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7473",
            name: "M\u1ea1c Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7474",
            name: "M\u1ea1c Th\u00e1i T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7475", name: "Mai D\u1ecbch", prefix: "Ph\u1ed1" },
          {
            id: "7476",
            name: "Nam Trung Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7477", name: "Ngh\u0129a T\u00e2n", prefix: "Ph\u1ed1" },
          {
            id: "7478",
            name: "Nguy\u1ec5n Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7479",
            name: "Nguy\u1ec5n C\u01a1 Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7480",
            name: "Nguy\u1ec5n \u0110\u00ecnh Ho\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7481",
            name: "Nguy\u1ec5n \u0110\u1ed5ng Chi",
            prefix: "Ph\u1ed1",
          },
          {
            id: "7482",
            name: "Nguy\u1ec5n Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7483",
            name: "Nguy\u1ec5n Kh\u1ea3 Tr\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7484",
            name: "Nguy\u1ec5n Khang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7485",
            name: "Nguy\u1ec5n Kh\u00e1nh To\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7486",
            name: "Nguy\u1ec5n Ng\u1ecdc V\u0169",
            prefix: "Ph\u1ed1",
          },
          {
            id: "7487",
            name: "Nguy\u1ec5n Phong S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7488",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7489",
            name: "Nguy\u1ec5n Th\u1ecb Th\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7490",
            name: "Nguy\u1ec5n Tri\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7491",
            name: "Nguy\u1ec5n V\u0103n Huy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7492", name: "Nh\u1eadt T\u00e2n", prefix: "Ph\u1ed1" },
          {
            id: "7493",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7494",
            name: "Ph\u1ea1m Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7495",
            name: "Ph\u1ea1m Th\u1eadn Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7496",
            name: "Ph\u1ea1m Tu\u1ea5n T\u00e0i",
            prefix: "Ph\u1ed1",
          },
          {
            id: "7497",
            name: "Ph\u1ea1m V\u0103n B\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7498",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7499",
            name: "Phan V\u0103n Tr\u01b0\u1eddng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "7500",
            name: "Ph\u00f9ng Ch\u00ed Ki\u00ean",
            prefix: "Ph\u1ed1",
          },
          { id: "7501", name: "Quan Hoa", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7502", name: "Quan Nh\u00e2n", prefix: "Ph\u1ed1" },
          {
            id: "7503",
            name: "Th\u00e0nh Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7504",
            name: "Th\u1ecd Th\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7505",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7506",
            name: "T\u00f4n Th\u1ea5t Thuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7507", name: "Tr\u1ea7n B\u00ecnh", prefix: "Ph\u1ed1" },
          { id: "7508", name: "Tr\u1ea7n Cung", prefix: "Ph\u1ed1" },
          {
            id: "7510",
            name: "Tr\u1ea7n Duy H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7509",
            name: "Tr\u1ea7n \u0110\u0103ng Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7511",
            name: "Tr\u1ea7n Kim Xuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7512",
            name: "Tr\u1ea7n Qu\u1ed1c Ho\u00e0n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "7513",
            name: "Tr\u1ea7n Qu\u1ed1c V\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7514",
            name: "Tr\u1ea7n Qu\u00fd Ki\u00ean",
            prefix: "Ph\u1ed1",
          },
          {
            id: "7515",
            name: "Tr\u1ea7n Th\u00e1i T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7516",
            name: "Tr\u1ea7n T\u1eed B\u00ecnh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "7517",
            name: "Tr\u1ea7n V\u0129",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7518", name: "Tr\u1ea7n V\u1ef9", prefix: "Ph\u1ed1" },
          {
            id: "7519",
            name: "Tr\u00e0ng H\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7520",
            name: "Tr\u00e0ng H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7521", name: "Trung H\u00f2a", prefix: "Ph\u1ed1" },
          { id: "7522", name: "Trung K\u00ednh", prefix: "Ph\u1ed1" },
          {
            id: "7523",
            name: "Trung Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7524",
            name: "Trung Y\u00ean 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7525",
            name: "Trung Y\u00ean 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7526",
            name: "Trung Y\u00ean 10A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7527",
            name: "Trung Y\u00ean 10B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7528",
            name: "Trung Y\u00ean 10D",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7529",
            name: "Trung Y\u00ean 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7530", name: "Trung Y\u00ean 11A", prefix: "Ph\u1ed1" },
          {
            id: "7531",
            name: "Trung Y\u00ean 11B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7532",
            name: "Trung Y\u00ean 11C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7533",
            name: "Trung Y\u00ean 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7534",
            name: "Trung Y\u00ean 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7535",
            name: "Trung Y\u00ean 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7536",
            name: "Trung Y\u00ean 14A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7537",
            name: "Trung Y\u00ean 14B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7538",
            name: "Trung Y\u00ean 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7539",
            name: "Trung Y\u00ean 15A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7540",
            name: "Trung Y\u00ean 15B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7541",
            name: "Trung Y\u00ean 15C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7542",
            name: "Trung Y\u00ean 15D",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7543",
            name: "Trung Y\u00ean 15E",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7544",
            name: "Trung Y\u00ean 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7545",
            name: "Trung Y\u00ean 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7546",
            name: "Trung Y\u00ean 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7547",
            name: "Trung Y\u00ean 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7548",
            name: "Trung Y\u00ean 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7549",
            name: "Trung Y\u00ean 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7550",
            name: "Trung Y\u00ean 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7551",
            name: "Trung Y\u00ean 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7552",
            name: "Trung Y\u00ean 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7553",
            name: "Trung Y\u00ean 9A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7554",
            name: "Trung Y\u00ean 9B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7555",
            name: "Tr\u01b0\u01a1ng C\u00f4ng Giai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7556",
            name: "Tu\u1ed5i Tr\u1ebb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7557",
            name: "V\u00f5 Ch\u00ed C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7558",
            name: "V\u00f5 Q\u00fay Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7559",
            name: "V\u0169 Ph\u1ea1m H\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7560", name: "X4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7561",
            name: "Xu\u00e2n Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7562",
            name: "Y\u00ean H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1243",
            name: "113 Trung K\u00ednh",
            lat: "21.020910263061",
            lng: "105.79001617432",
          },
          {
            id: "1244",
            name: "151 Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            lat: "21.041961669922",
            lng: "105.79928588867",
          },
          {
            id: "1245",
            name: "169 Nguy\u1ec5n Ng\u1ecdc V\u0169",
            lat: "21.012062072754",
            lng: "105.80797576904",
          },
          {
            id: "1246",
            name: "173 Xu\u00e2n Th\u1ee7y",
            lat: "21.036239624023",
            lng: "105.78537750244",
          },
          {
            id: "1247",
            name: "2T Corporation",
            lat: "21.038515090942",
            lng: "105.78017425537",
          },
          {
            id: "1248",
            name: "30 Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            lat: "21.043857574463",
            lng: "105.78136444092",
          },
          {
            id: "1249",
            name: "335 C\u1ea7u Gi\u1ea5y",
            lat: "21.035289764404",
            lng: "105.7918548584",
          },
          {
            id: "1250",
            name: "789 B\u1ed9 Qu\u1ed1c Ph\u00f2ng - 147 Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            lat: "21.045589447021",
            lng: "105.79972076416",
          },
          {
            id: "1251",
            name: "95 C\u1ea7u Gi\u1ea5y",
            lat: "21.034652709961",
            lng: "105.79549407959",
          },
          {
            id: "1252",
            name: "A10-A14 Nam Trung Y\u00ean",
            lat: "21.01905632019",
            lng: "105.78797912598",
          },
          {
            id: "1253",
            name: "AC Building",
            lat: "21.03094291687",
            lng: "105.78391265869",
          },
          {
            id: "1254",
            name: "An L\u1ea1c \u2013 Ho\u00e0ng Ng\u00e2n",
            lat: "21.007579803467",
            lng: "105.8105392456",
          },
          {
            id: "1255",
            name: "An Sinh",
            lat: "21.050689697266",
            lng: "105.79093170166",
          },
          {
            id: "1256",
            name: "ATS",
            lat: "21.046337127686",
            lng: "105.78240203857",
          },
          {
            id: "1257",
            name: "\u00c2u Vi\u1ec7t Building",
            lat: "21.036817550659",
            lng: "105.77175140381",
          },
          {
            id: "1258",
            name: "AZ L\u00e2m Vi\u00ean Complex",
            lat: "21.040990829468",
            lng: "105.79005432129",
          },
          {
            id: "1259",
            name: "B\u1ea3o Anh Building",
            lat: "21.031976699829",
            lng: "105.78861236572",
          },
          {
            id: "1260",
            name: "Belleville H\u00e0 N\u1ed9i",
            lat: "21.02180480957",
            lng: "105.7908706665",
          },
          {
            id: "1261",
            name: "C\u1ea7u Gi\u1ea5y Center Point",
            lat: "21.032211303711",
            lng: "105.79969787598",
          },
          {
            id: "1262",
            name: "CC1 H\u00e0 \u0110\u00f4 Parkside",
            lat: "21.034706115723",
            lng: "105.79234313965",
          },
          {
            id: "1263",
            name: "Central Field Trung K\u00ednh",
            lat: "21.020978927612",
            lng: "105.79106140137",
          },
          {
            id: "1264",
            name: "Chelsea Park",
            lat: "21.019016265869",
            lng: "105.79320526123",
          },
          {
            id: "1265",
            name: "Chelsea Residences",
            lat: "21.018060684204",
            lng: "105.79606628418",
          },
          {
            id: "1266",
            name: "Chung c\u01b0 60 Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            lat: "21.046581268311",
            lng: "105.79739379883",
          },
          {
            id: "1267",
            name: "CMC Tower",
            lat: "21.030727386475",
            lng: "105.78433227539",
          },
          {
            id: "1268",
            name: "Constrexim Complex",
            lat: "21.035444259644",
            lng: "105.78131103516",
          },
          {
            id: "1269",
            name: "CT4 Vimeco II",
            lat: "21.008962631226",
            lng: "105.79312896728",
          },
          {
            id: "1270",
            name: "CT6 Constrexim Y\u00ean H\u00f2a",
            lat: "21.02357673645",
            lng: "105.78856658935",
          },
          {
            id: "1271",
            name: "CTM 299 C\u1ea7u Gi\u1ea5y",
            lat: "21.034620285034",
            lng: "105.79354095459",
          },
          {
            id: "1272",
            name: "CTM Building 139 C\u1ea7u Gi\u1ea5y",
            lat: "21.031925201416",
            lng: "105.79920196533",
          },
          {
            id: "1273",
            name: "D\u2019. Palais De Louis - Nguy\u1ec5n V\u0103n Huy\u00ean",
            lat: "21.036661148071",
            lng: "105.79804992676",
          },
          {
            id: "1274",
            name: "D11 Sunrise Building",
            lat: "21.030992507935",
            lng: "105.78829193115",
          },
          {
            id: "1275",
            name: "D22 B\u1ed9 T\u01b0 L\u1ec7nh Bi\u00ean Ph\u00f2ng",
            lat: "21.032440185547",
            lng: "105.7785949707",
          },
          {
            id: "1277",
            name: "Diamond Building",
            lat: "21.031093597412",
            lng: "105.78078460693",
          },
          {
            id: "1278",
            name: "Discovery Complex",
            lat: "21.035051345825",
            lng: "105.79497528076",
          },
          {
            id: "1281",
            name: "Dream Land Bonanza",
            lat: "21.031360626221",
            lng: "105.78009796143",
          },
          {
            id: "1276",
            name: "\u0110\u1ea1i Ph\u00e1t Building",
            lat: "21.03175163269",
            lng: "105.78253936768",
          },
          {
            id: "1279",
            name: "\u0110\u00f4ng \u0110\u00f4",
            lat: "21.049934387207",
            lng: "105.79525756836",
          },
          {
            id: "1280",
            name: "\u0110\u00f4ng Nam Tr\u1ea7n Duy H\u01b0ng",
            lat: "21.005596160889",
            lng: "105.79886627197",
          },
          {
            id: "1282",
            name: "\u0110TM D\u1ecbch V\u1ecdng",
            lat: "21.029237747192",
            lng: "105.79385375977",
          },
          {
            id: "1283",
            name: "Elinco",
            lat: "21.030088424683",
            lng: "105.78333282471",
          },
          {
            id: "1284",
            name: "Eurowindow Multi Complex",
            lat: "21.011089324951",
            lng: "105.79990386963",
          },
          {
            id: "1285",
            name: "Five Star Resident",
            lat: "21.030506134033",
            lng: "105.78851318359",
          },
          {
            id: "1286",
            name: "FLC Twin Towers",
            lat: "21.034498214722",
            lng: "105.79551696777",
          },
          {
            id: "1287",
            name: "FPT C\u1ea7u Gi\u1ea5y",
            lat: "21.03074836731",
            lng: "105.78260803223",
          },
          {
            id: "1288",
            name: "Golden Park Tower",
            lat: "21.024080276489",
            lng: "105.78963470459",
          },
          {
            id: "1289",
            name: "Grand Plaza",
            lat: "21.007749557495",
            lng: "105.79705810547",
          },
          {
            id: "1290",
            name: "Green Park Tower",
            lat: "21.022399902344",
            lng: "105.78666687012",
          },
          {
            id: "1291",
            name: "Ha Do Park View",
            lat: "21.027410507202",
            lng: "105.79351043701",
          },
          {
            id: "1292",
            name: "HDI Homes V\u0169 Ph\u1ea1m H\u00e0m",
            lat: "21.014122009277",
            lng: "105.78865814209",
          },
          {
            id: "1293",
            name: "Hoa Binh International",
            lat: "21.046566009521",
            lng: "105.79515838623",
          },
          {
            id: "1294",
            name: "Ho\u00e0ng Linh Building",
            lat: "21.031942367554",
            lng: "105.78289031982",
          },
          {
            id: "1295",
            name: "Ho\u00e0ng Ng\u00e2n Plaza",
            lat: "21.009384155273",
            lng: "105.80545806885",
          },
          {
            id: "1296",
            name: "Home City - Trung K\u00ednh Complex",
            lat: "21.016098022461",
            lng: "105.79248809815",
          },
          {
            id: "1297",
            name: "Housing",
            lat: "21.021160125732",
            lng: "105.79067993164",
          },
          {
            id: "1298",
            name: "Indochina Plaza",
            lat: "21.036001205444",
            lng: "105.78246307373",
          },
          {
            id: "1299",
            name: "Intracom",
            lat: "21.029634475708",
            lng: "105.78565216065",
          },
          {
            id: "1300",
            name: "K\u0110T m\u1edbi C\u1ea7u Gi\u1ea5y",
            lat: "21.024765014648",
            lng: "105.78576660156",
          },
          {
            id: "1301",
            name: "K\u0110T Trung Y\u00ean",
            lat: "21.015531539917",
            lng: "105.79941558838",
          },
          {
            id: "1302",
            name: "Khu nh\u00e0 \u1edf HV T\u01b0 ph\u00e1p",
            lat: "21.008228302002",
            lng: "105.80918884277",
          },
          {
            id: "1303",
            name: "Khu Nh\u00e0 \u1edf KD D\u1ecbch V\u1ecdng",
            lat: "21.033617019653",
            lng: "105.79091644287",
          },
          {
            id: "1304",
            name: "L\u00e0ng Qu\u1ed1c t\u1ebf Th\u0103ng Long",
            lat: "21.040250778198",
            lng: "105.79108428955",
          },
          {
            id: "1305",
            name: "Li\u1ec1n k\u1ec1 Ph\u1ed1 Wall",
            lat: "21.033756256104",
            lng: "105.78927612305",
          },
          {
            id: "1306",
            name: "LOD Building",
            lat: "21.031229019165",
            lng: "105.78784179688",
          },
          {
            id: "1307",
            name: "Mandarin Garden",
            lat: "21.004484176636",
            lng: "105.79862976074",
          },
          {
            id: "1308",
            name: "Maple Tower",
            lat: "21.030138015747",
            lng: "105.78422546387",
          },
          {
            id: "1309",
            name: "MB Grand Tower",
            lat: "21.008136749268",
            lng: "105.80793762207",
          },
          {
            id: "1310",
            name: "Mitec Tower",
            lat: "21.020034790039",
            lng: "105.78391265869",
          },
          {
            id: "1311",
            name: "N01-D17 Duy T\u00e2n",
            lat: "21.030416488648",
            lng: "105.78785705566",
          },
          {
            id: "1312",
            name: "N04 Tr\u1ea7n Duy H\u01b0ng",
            lat: "21.008878707886",
            lng: "105.80025482178",
          },
          {
            id: "1313",
            name: "N05 Tr\u1ea7n Duy H\u01b0ng",
            lat: "21.006958007812",
            lng: "105.80107879639",
          },
          {
            id: "1314",
            name: "N105 Nguy\u1ec5n Phong S\u1eafc",
            lat: "21.038961410523",
            lng: "105.79041290283",
          },
          {
            id: "1315",
            name: "Nam Trung Y\u00ean",
            lat: "21.014148712158",
            lng: "105.78872680664",
          },
          {
            id: "1316",
            name: "Paragon Tower",
            lat: "21.031177520752",
            lng: "105.78939819336",
          },
          {
            id: "1317",
            name: "Park View City",
            lat: "21.01584815979",
            lng: "105.79760742188",
          },
          {
            id: "1318",
            name: "PV Oil 148 Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            lat: "21.046089172363",
            lng: "105.805519104",
          },
          {
            id: "1319",
            name: "PVI Tower",
            lat: "21.02400970459",
            lng: "105.78898620606",
          },
          {
            id: "1320",
            name: "Richland Southern",
            lat: "21.036092758179",
            lng: "105.78330230713",
          },
          {
            id: "1321",
            name: "Royal Lotus Complex",
            lat: "21.033952713013",
            lng: "105.78500366211",
          },
          {
            id: "1322",
            name: "San Nam",
            lat: "21.031383514404",
            lng: "105.78311157227",
          },
          {
            id: "1323",
            name: "Sky Park Residence",
            lat: "21.027719497681",
            lng: "105.78589630127",
          },
          {
            id: "1324",
            name: "SkyView Tr\u1ea7n Th\u00e1i T\u00f4ng",
            lat: "21.033311843872",
            lng: "105.78974914551",
          },
          {
            id: "1325",
            name: "Somerset H\u00f2a B\u00ecnh",
            lat: "21.046663284302",
            lng: "105.79512023926",
          },
          {
            id: "1326",
            name: "S\u00f4ng \u0110\u00e0 7",
            lat: "21.011241912842",
            lng: "105.7953414917",
          },
          {
            id: "1327",
            name: "Star Tower",
            lat: "21.024976730347",
            lng: "105.79122924805",
          },
          {
            id: "1328",
            name: "T\u00e2y Nam \u0110\u1ea1i h\u1ecdc Th\u01b0\u01a1ng M\u1ea1i",
            lat: "21.035680770874",
            lng: "105.7712020874",
          },
          {
            id: "1329",
            name: "The Premier H\u00e0 N\u1ed9i",
            lat: "21.029111862183",
            lng: "105.78462982178",
          },
          {
            id: "1330",
            name: "TIG Tower (ThangLong Royal Plaza)",
            lat: "21.028127670288",
            lng: "105.77920532227",
          },
          {
            id: "1331",
            name: "T\u00f2a nh\u00e0 3A",
            lat: "21.032144546509",
            lng: "105.78260040283",
          },
          {
            id: "1332",
            name: "T\u00f2a nh\u00e0 Reeco",
            lat: "21.009834289551",
            lng: "105.8056716919",
          },
          {
            id: "1333",
            name: "Tr\u00e0ng An GP - Complex",
            lat: "21.044372558594",
            lng: "105.80323028565",
          },
          {
            id: "1334",
            name: "Trung H\u00f2a Nh\u00e2n Ch\u00ednh",
            lat: "21.008365631104",
            lng: "105.80290985107",
          },
          {
            id: "1335",
            name: "Trung Y\u00ean I",
            lat: "21.014348983765",
            lng: "105.79640960693",
          },
          {
            id: "1336",
            name: "Trung Y\u00ean Plaza",
            lat: "21.014102935791",
            lng: "105.80178833008",
          },
          {
            id: "1337",
            name: "TTC Tower",
            lat: "21.030759811401",
            lng: "105.78192901611",
          },
          {
            id: "1338",
            name: "Vicem Tower",
            lat: "21.015066146851",
            lng: "105.78534698486",
          },
          {
            id: "1339",
            name: "Vi\u1ec7t \u00c1 Tower",
            lat: "21.030353546143",
            lng: "105.78502655029",
          },
          {
            id: "1340",
            name: "Vietracimex Tower",
            lat: "21.03094291687",
            lng: "105.78391265869",
          },
          {
            id: "1341",
            name: "Viglacera - Exim Building",
            lat: "21.046535491943",
            lng: "105.80443572998",
          },
          {
            id: "1342",
            name: "Vimeco I - Ph\u1ea1m H\u00f9ng",
            lat: "21.00873374939",
            lng: "105.79022979736",
          },
          {
            id: "1343",
            name: "Vimeco II - Nguy\u1ec5n Ch\u00e1nh",
            lat: "21.009666442871",
            lng: "105.79374694824",
          },
          {
            id: "1344",
            name: "Vinaconex 1",
            lat: "21.001682281494",
            lng: "105.79240417481",
          },
          {
            id: "1345",
            name: "Vinahud",
            lat: "21.01646232605",
            lng: "105.80170440674",
          },
          {
            id: "1346",
            name: "Vinata Tower",
            lat: "20.996593475342",
            lng: "105.79893493652",
          },
          {
            id: "1347",
            name: "Vinhomes D'Capitale",
            lat: "21.005805969238",
            lng: "105.79437255859",
          },
          {
            id: "1348",
            name: "VMT Building",
            lat: "21.031944274902",
            lng: "105.78181457519",
          },
          {
            id: "1349",
            name: "Watermark T\u00e2y H\u1ed3",
            lat: "21.055547714233",
            lng: "105.80859375",
          },
          {
            id: "1350",
            name: "Y\u00ean H\u00f2a",
            lat: "21.018758773804",
            lng: "105.7947769165",
          },
          {
            id: "1351",
            name: "Y\u00ean H\u00f2a Condominium",
            lat: "21.02140045166",
            lng: "105.79205322266",
          },
          {
            id: "1352",
            name: "Y\u00ean H\u00f2a Th\u0103ng Long",
            lat: "21.017549514771",
            lng: "105.79067230225",
          },
          {
            id: "1353",
            name: "Zodiac Building",
            lat: "21.030832290649",
            lng: "105.78211212158",
          },
        ],
      },
      {
        id: "29",
        name: "Ch\u01b0\u01a1ng M\u1ef9",
        wards: [
          {
            id: "393",
            name: "Ch\u00fac S\u01a1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "394", name: "\u0110\u1ea1i Y\u00ean", prefix: "X\u00e3" },
          { id: "395", name: "\u0110\u1ed3ng L\u1ea1c", prefix: "X\u00e3" },
          { id: "396", name: "\u0110\u1ed3ng Ph\u00fa", prefix: "X\u00e3" },
          {
            id: "397",
            name: "\u0110\u00f4ng Ph\u01b0\u01a1ng Y\u00ean",
            prefix: "X\u00e3",
          },
          { id: "398", name: "\u0110\u00f4ng S\u01a1n", prefix: "X\u00e3" },
          { id: "399", name: "Ho\u00e0 Ch\u00ednh", prefix: "X\u00e3" },
          { id: "400", name: "Ho\u00e0ng Di\u1ec7u", prefix: "X\u00e3" },
          {
            id: "401",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "X\u00e3",
          },
          { id: "402", name: "H\u1ed3ng Phong", prefix: "X\u00e3" },
          { id: "403", name: "H\u1ee3p \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "404", name: "H\u1eefu V\u0103n", prefix: "X\u00e3" },
          { id: "405", name: "Lam \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "406", name: "M\u1ef9 L\u01b0\u01a1ng", prefix: "X\u00e3" },
          {
            id: "407",
            name: "Nam Ph\u01b0\u01a1ng Ti\u1ebfn",
            prefix: "X\u00e3",
          },
          { id: "408", name: "Ng\u1ecdc H\u00f2a", prefix: "X\u00e3" },
          { id: "409", name: "Ph\u00fa Nam An", prefix: "X\u00e3" },
          { id: "410", name: "Ph\u00fa Ngh\u0129a", prefix: "X\u00e3" },
          { id: "411", name: "Ph\u1ee5ng Ch\u00e2u", prefix: "X\u00e3" },
          { id: "412", name: "Qu\u1ea3ng B\u1ecb", prefix: "X\u00e3" },
          { id: "413", name: "T\u00e2n Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "414", name: "Thanh B\u00ecnh", prefix: "X\u00e3" },
          { id: "415", name: "Th\u01b0\u1ee3ng V\u1ef1c", prefix: "X\u00e3" },
          { id: "416", name: "Th\u1ee5y H\u01b0\u01a1ng", prefix: "X\u00e3" },
          {
            id: "417",
            name: "Thu\u1ef7 Xu\u00e2n Ti\u00ean",
            prefix: "X\u00e3",
          },
          { id: "418", name: "Ti\u00ean Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "419", name: "T\u1ed1t \u0110\u1ed9ng", prefix: "X\u00e3" },
          { id: "420", name: "Tr\u1ea7n Ph\u00fa", prefix: "X\u00e3" },
          { id: "421", name: "Trung H\u00f2a", prefix: "X\u00e3" },
          { id: "422", name: "Tr\u01b0\u1eddng Y\u00ean", prefix: "X\u00e3" },
          { id: "423", name: "V\u0103n V\u00f5", prefix: "X\u00e3" },
          { id: "424", name: "Xu\u00e2n Mai", prefix: "Th\u1ecb tr\u1ea5n" },
        ],
        streets: [
          { id: "7563", name: "201", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7564", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7565", name: "479", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7566", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7567",
            name: "B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7568",
            name: "B\u00ecnh S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7569",
            name: "Chi\u1ebfn Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7570", name: "Cu Ba", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7571", name: "\u0110T 419", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7572",
            name: "H\u1ed3 Ch\u00ed Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7573",
            name: "Nguy\u1ec5n Anh Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7574",
            name: "Qu\u1ed1c l\u1ed9 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7575",
            name: "Qu\u1ed1c l\u1ed9 21A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7576",
            name: "Qu\u1ed1c l\u1ed9 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7577",
            name: "Qu\u1ed1c L\u1ed9 6A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7578",
            name: "Ti\u00ean Tr\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7579",
            name: "T\u1ec9nh l\u1ed9 419",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7580",
            name: "T\u1ec9nh l\u1ed9 424",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7581",
            name: "T\u1ec9nh L\u1ed9 80",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1354",
            name: "KCN Ph\u00fa Ngh\u0129a",
            lat: "20.927444458008",
            lng: "105.71241760254",
          },
          {
            id: "1355",
            name: "L\u00e0ng Th\u1eddi \u0110\u1ea1i",
            lat: "20.978729248047",
            lng: "105.85665893555",
          },
          {
            id: "1356",
            name: "L\u1ed9c Ninh Singashine",
            lat: "20.926460266113",
            lng: "105.71282958984",
          },
          {
            id: "1357",
            name: "Ph\u00fa Ngh\u0129a",
            lat: "20.933313369751",
            lng: "105.67115783691",
          },
        ],
      },
      {
        id: "30",
        name: "\u0110an Ph\u01b0\u1ee3ng",
        wards: [
          { id: "425", name: "\u0110an Ph\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "426", name: "\u0110\u1ed3ng Th\u00e1p", prefix: "X\u00e3" },
          { id: "427", name: "H\u1ea1 M\u1ed7", prefix: "X\u00e3" },
          { id: "428", name: "H\u1ed3ng H\u00e0", prefix: "X\u00e3" },
          { id: "429", name: "Li\u00ean H\u00e0", prefix: "X\u00e3" },
          { id: "430", name: "Li\u00ean H\u1ed3ng", prefix: "X\u00e3" },
          { id: "431", name: "Li\u00ean Trung", prefix: "X\u00e3" },
          { id: "432", name: "Ph\u00f9ng", prefix: "Th\u1ecb tr\u1ea5n" },
          {
            id: "433",
            name: "Ph\u01b0\u01a1ng \u0110\u00ecnh",
            prefix: "X\u00e3",
          },
          { id: "434", name: "Song Ph\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "435", name: "T\u00e2n H\u1ed9i", prefix: "X\u00e3" },
          { id: "436", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "437", name: "Th\u1ecd An", prefix: "X\u00e3" },
          { id: "438", name: "Th\u1ecd Xu\u00e2n", prefix: "X\u00e3" },
          { id: "439", name: "Th\u01b0\u1ee3ng M\u1ed7", prefix: "X\u00e3" },
          { id: "440", name: "Trung Ch\u00e2u", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "7582", name: "32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7583", name: "79", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7584",
            name: "\u0110\u1ed7 \u0110\u1ee9c D\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7585",
            name: "\u0110\u1ed3ng \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7586",
            name: "\u0110\u1ed3ng S\u1eady",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7587",
            name: "G\u00f2 M\u00e8o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7588",
            name: "H\u1ea1 H\u1ed3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7589",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7590", name: "Minh Khai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7591",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7592",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7593", name: "Phan Long", prefix: "Ph\u1ed1" },
          { id: "7594", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "7595",
            name: "Ph\u01b0\u01a1ng M\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7596",
            name: "Ph\u01b0\u1ee3ng Tr\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7597",
            name: "Qu\u1ed1c l\u1ed9 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7598",
            name: "S\u00f4ng Nhu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7599",
            name: "T\u00e2y S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7600",
            name: "Th\u1ee5y \u1ee8ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7601",
            name: "T\u1ec9nh L\u1ed9 79",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1358",
            name: "Ph\u00fac Th\u1ecbnh Tower",
            lat: "21.074918746948",
            lng: "105.69923400879",
          },
          {
            id: "1359",
            name: "T\u00e2n l\u00e2\u0323p",
            lat: "21.073427200317",
            lng: "105.7127456665",
          },
          {
            id: "1360",
            name: "T\u00e2n L\u1eadp Cienco 5",
            lat: "21.073583602905",
            lng: "105.71309661865",
          },
          {
            id: "1361",
            name: "T\u00e2n T\u00e2y \u0110\u00f4",
            lat: "21.074520111084",
            lng: "105.70022583008",
          },
          {
            id: "1362",
            name: "The Phoenix Garden",
            lat: "21.092809677124",
            lng: "105.68113708496",
          },
          {
            id: "1363",
            name: "Vincity \u0110an Ph\u01b0\u1ee3ng",
            lat: "21.093896865845",
            lng: "105.7028427124",
          },
          {
            id: "1364",
            name: "xpHOMES",
            lat: "21.074464797974",
            lng: "105.70079803467",
          },
        ],
      },
      {
        id: "31",
        name: "\u0110\u00f4ng Anh",
        wards: [
          { id: "441", name: "B\u1eafc H\u1ed3ng", prefix: "X\u00e3" },
          { id: "442", name: "C\u1ed5 Loa", prefix: "X\u00e3" },
          { id: "446", name: "D\u1ee5c T\u00fa", prefix: "X\u00e3" },
          { id: "443", name: "\u0110\u1ea1i M\u1ea1ch", prefix: "X\u00e3" },
          {
            id: "444",
            name: "\u0110\u00f4ng Anh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "445", name: "\u0110\u00f4ng H\u1ed9i", prefix: "X\u00e3" },
          { id: "447", name: "H\u1ea3i B\u1ed1i", prefix: "X\u00e3" },
          { id: "448", name: "Kim Chung", prefix: "X\u00e3" },
          { id: "449", name: "Kim N\u1ed7", prefix: "X\u00e3" },
          { id: "450", name: "Li\u00ean H\u00e0", prefix: "X\u00e3" },
          { id: "451", name: "Mai L\u00e2m", prefix: "X\u00e3" },
          { id: "452", name: "Nam H\u1ed3ng", prefix: "X\u00e3" },
          { id: "453", name: "Nguy\u00ean Kh\u00ea", prefix: "X\u00e3" },
          { id: "454", name: "T\u00e0m X\u00e1", prefix: "X\u00e3" },
          { id: "455", name: "T\u1ea7m X\u00e1", prefix: "X\u00e3" },
          { id: "456", name: "Th\u1ee5y L\u00e2m", prefix: "X\u00e3" },
          { id: "457", name: "Ti\u00ean D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "458", name: "Uy N\u1ed7", prefix: "X\u00e3" },
          { id: "459", name: "V\u00e2n H\u00e0", prefix: "X\u00e3" },
          { id: "460", name: "V\u00e2n N\u1ed9i", prefix: "X\u00e3" },
          { id: "461", name: "Vi\u1ec7t H\u00f9ng", prefix: "X\u00e3" },
          { id: "462", name: "V\u0129nh Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "463", name: "V\u00f5ng La", prefix: "X\u00e3" },
          { id: "464", name: "Xu\u00e2n Canh", prefix: "X\u00e3" },
          { id: "465", name: "Xu\u00e2n N\u1ed9n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "7602", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7603", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7604", name: "23B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7605", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7606", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7607", name: "72", prefix: "Ph\u1ed1" },
          {
            id: "7608",
            name: "\u1ea4p T\u00f3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7609",
            name: "B\u1eafc H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7610",
            name: "B\u1eafc H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7611",
            name: "B\u1eafc Th\u0103ng Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7612",
            name: "B\u00e3i Ch\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7613",
            name: "B\u00e3i K\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7614",
            name: "B\u00edch Ph\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7615", name: "Cao L\u1ed7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7616",
            name: "Cao t\u1ed1c Nh\u1eadt T\u00e2n N\u1ed9i B\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7617",
            name: "Cao t\u1ed1c Th\u0103ng Long N\u1ed9i B\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7618",
            name: "C\u1ea7u L\u1edbn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7620",
            name: "C\u1ed5 D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7619",
            name: "C\u1ed5 \u0110i\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7621", name: "C\u1ed5 Loa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7622",
            name: "C\u1ed5 Nhu\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7623",
            name: "C\u1eeda H\u00e0ng M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7635", name: "Du Ngo\u1ea1i", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7636", name: "Du N\u1ed9i", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7637",
            name: "D\u1ee5c N\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7624",
            name: "\u0110a L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7625",
            name: "\u0110\u00e0i Bi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7626",
            name: "\u0110\u1ea1i \u0110\u1ed3ng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "7627",
            name: "\u0110\u1ea3n D\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7628",
            name: "\u0110\u1ea3n M\u1ed7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7629",
            name: "\u0110\u00e0o Cam M\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7630",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7631",
            name: "\u0110\u00e0o Duy T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7632",
            name: "\u0110\u00f4ng H\u1ed9i",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "7633",
            name: "\u0110\u00f4ng Ng\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7634",
            name: "\u0110\u1ed3ng Qu\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7638",
            name: "Ga \u0110\u00f4ng Anh",
            prefix: "\u0111\u01b0\u1eddng",
          },
          { id: "7639", name: "Gi\u1ea7y Da", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7640",
            name: "H\u1ea3i B\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7641",
            name: "H\u1eadu D\u01b0\u1ee1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7642",
            name: "H\u1ed9i Ph\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7643", name: "Kim Chung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7644",
            name: "Kinh B\u1eafc 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7645",
            name: "L\u1ea1i \u0110\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7646",
            name: "L\u00e2m Ti\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7647",
            name: "L\u00ea H\u1eefu T\u1ef1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7648",
            name: "L\u00ea X\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7649",
            name: "Li\u00ean H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7650",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7651",
            name: "L\u00f2 V\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7652",
            name: "L\u1ed9c H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7653",
            name: "L\u01b0\u01a1ng Quy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7654",
            name: "L\u00fd Nh\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7655", name: "Mai Ch\u00e2u", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7656", name: "Ng\u1ecdc Chi", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7657",
            name: "Ng\u1ecdc Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7658",
            name: "Nguy\u00ean Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7659", name: "Nh\u1ea1n", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7660", name: "Phan X\u00e1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7661",
            name: "Ph\u00e1p V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7662", name: "Ph\u00f9 Dung", prefix: "Ph\u1ed1" },
          { id: "7663", name: "Ph\u00fac L\u1ed9c", prefix: "Ph\u1ed1" },
          {
            id: "7664",
            name: "Ph\u00fac Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7665",
            name: "Ph\u01b0\u01a1ng Tr\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7666",
            name: "Qu\u00e1n T\u1ec9nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7667",
            name: "Qu\u1ed1c l\u1ed9 23B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7668",
            name: "Qu\u1ed1c l\u1ed9 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7669", name: "S\u00e1p Mai", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7670", name: "S\u01a1n Du", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7671",
            name: "T\u00e0m X\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7672",
            name: "T\u00e2n Trang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7673",
            name: "Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7674",
            name: "Thi\u00ean Bi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7675",
            name: "Thi\u00ean Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7676",
            name: "Thi\u1ebft B\u1ecb \u0110i\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7677",
            name: "Th\u00f4n \u0110\u00eca-Nam H\u1ed3ng",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "7678",
            name: "Th\u1ee5y H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7679",
            name: "Th\u1ee5y L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7680",
            name: "Ti\u00ean H\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7681", name: "Ti\u00ean Kha", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7682",
            name: "Tr\u01b0\u1eddng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7683", name: "Uy N\u1ed7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7684",
            name: "V\u0103n N\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7685",
            name: "V\u0103n Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7686",
            name: "V\u00e2n Tr\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7687",
            name: "Vi\u1ec7t H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7688",
            name: "V\u00f5 Ch\u00ed C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7689",
            name: "V\u1ecf L\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7690",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7691",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7692",
            name: "V\u01b0\u1eddn \u0110\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7693",
            name: "Xu\u00e2n Canh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7694",
            name: "Y\u00ean H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7695", name: "Z153", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "1365",
            name: "\u0110\u00f4ng Anh",
            lat: "21.1236743927",
            lng: "105.77121734619",
          },
          {
            id: "1366",
            name: "Eurowindow River Park",
            lat: "21.082349777222",
            lng: "105.87175750732",
          },
          {
            id: "1367",
            name: "Intracom Riverside",
            lat: "21.125017166138",
            lng: "105.83688354492",
          },
          {
            id: "1368",
            name: "Nam H\u1ed3ng",
            lat: "21.157918930054",
            lng: "105.78820800781",
          },
        ],
      },
      {
        id: "32",
        name: "\u0110\u1ed1ng \u0110a",
        wards: [
          { id: "466", name: "Ca\u0301t Linh", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "467",
            name: "Ha\u0300ng B\u00f4\u0323t",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "468",
            name: "Kh\u00e2m Thi\u00ean",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "469",
            name: "Kh\u01b0\u01a1ng Th\u01b0\u01a1\u0323ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "470", name: "Kim Li\u00ean", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "471",
            name: "La\u0301ng Ha\u0323",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "472",
            name: "La\u0301ng Th\u01b0\u01a1\u0323ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "473",
            name: "Nam \u0110\u00f4\u0300ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "474",
            name: "Nga\u0303 T\u01b0 S\u01a1\u0309",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "475",
            name: "\u00d4 Ch\u01a1\u0323 D\u01b0\u0300a",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "476",
            name: "Ph\u01b0\u01a1ng Li\u00ean",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "477",
            name: "Ph\u01b0\u01a1ng Mai",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "478", name: "Quang Trung", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "479",
            name: "Qu\u00f4\u0301c T\u01b0\u0309 Gia\u0301m",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "480", name: "Thi\u0323nh Quang", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "481",
            name: "Th\u00f4\u0309 Quan",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "482",
            name: "Trung Li\u00ea\u0323t",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "483", name: "Trung Phu\u0323ng", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "484",
            name: "Trung T\u01b0\u0323",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "485",
            name: "V\u0103n Ch\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "486",
            name: "V\u0103n Mi\u00ea\u0301u",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "7696", name: "An Tr\u1ea1ch", prefix: "Ph\u1ed1" },
          {
            id: "7697",
            name: "An Tr\u1ea1ch 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7698", name: "B\u00edch C\u00e2u", prefix: "Ph\u1ed1" },
          {
            id: "7699",
            name: "C\u1ea9m V\u0103n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7700", name: "C\u1ea3nh V\u1ec7", prefix: "Ph\u1ed1" },
          { id: "7701", name: "C\u00e1t Linh", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7702", name: "C\u1ea7u M\u1edbi", prefix: "Ph\u1ed1" },
          {
            id: "7703",
            name: "C\u00e2y B\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7704",
            name: "Chi\u1ebfn Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7705",
            name: "Ch\u1ee3 Kh\u00e2m Thi\u00ean",
            prefix: "Ph\u1ed1",
          },
          { id: "7706", name: "Ch\u00f9a B\u1ed9c", prefix: "Ph\u1ed1" },
          { id: "7707", name: "Ch\u00f9a L\u00e1ng", prefix: "Ph\u1ed1" },
          {
            id: "7708",
            name: "Ch\u00f9a N\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7709", name: "\u0110\u1ea1i La", prefix: "Ph\u1ed1" },
          {
            id: "7710",
            name: "\u0110\u1eb7ng Ti\u1ebfn \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7711",
            name: "\u0110\u1eb7ng Tr\u1ea7n C\u00f4n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "7712",
            name: "\u0110\u1eb7ng V\u0103n Ng\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7713",
            name: "\u0110\u00e0o Duy Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7714",
            name: "\u0110\u00ea La Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7715",
            name: "\u0110o\u00e0n K\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7716",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7717", name: "\u0110\u00f4ng C\u00e1c", prefix: "Ph\u1ed1" },
          {
            id: "7718",
            name: "\u0110\u00f4ng T\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7719",
            name: "\u0110\u01b0\u1eddng L\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7720",
            name: "Gi\u1ea3i Ph\u00f3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7721", name: "Gi\u1ea3ng V\u00f5", prefix: "Ph\u1ed1" },
          {
            id: "7722",
            name: "Ha\u0300ng B\u00f4\u0323t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7723", name: "H\u00e0ng Ch\u00e1o", prefix: "Ph\u1ed1" },
          {
            id: "7724",
            name: "H\u00e0ng \u0110\u1eaby",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7725", name: "H\u00e0o Nam", prefix: "Ph\u1ed1" },
          {
            id: "7726",
            name: "H\u1ed3 Ba M\u1eabu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7727",
            name: "H\u1ed3 \u0110\u1eafc Di",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7728", name: "H\u1ed3 Gi\u00e1m", prefix: "Ph\u1ed1" },
          {
            id: "7729",
            name: "H\u1ed3 V\u0103n Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7730",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7731", name: "Ho\u00e0ng An", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7732", name: "Ho\u00e0ng C\u1ea7u", prefix: "Ph\u1ed1" },
          {
            id: "7733",
            name: "Ho\u00e0ng Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7734",
            name: "Ho\u00e0ng Ng\u1ecdc Ph\u00e1ch",
            prefix: "Ph\u1ed1",
          },
          {
            id: "7735",
            name: "Ho\u00e0ng T\u00edch Tr\u00ed",
            prefix: "Ph\u1ed1",
          },
          { id: "7736", name: "Huy V\u0103n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7737",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7738", name: "Kh\u00e2m Thi\u00ean", prefix: "Ph\u1ed1" },
          {
            id: "7739",
            name: "Kh\u01b0\u01a1ng Th\u01b0\u1ee3ng",
            prefix: "Ph\u1ed1",
          },
          { id: "7740", name: "Kim Hoa", prefix: "Ph\u1ed1" },
          { id: "7741", name: "Kim Li\u00ean", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7742",
            name: "Kim Li\u00ean M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7743", name: "La Th\u00e0nh", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7744", name: "Lan B\u00e1", prefix: "Ph\u1ed1" },
          { id: "7745", name: "L\u00e1ng", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7746", name: "L\u00e1ng H\u1ea1", prefix: "Ph\u1ed1" },
          {
            id: "7747",
            name: "L\u00e1ng Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7748",
            name: "L\u00e1ng Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7749",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7750",
            name: "L\u00ea Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7751",
            name: "L\u00ea V\u0103n L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7752",
            name: "L\u00ea\u0323nh C\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7753", name: "Linh Quang", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7754", name: "Linh Quang A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7755", name: "Linh Quang B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7756",
            name: "L\u01b0\u01a1ng \u0110\u1ecbnh C\u1ee7a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "7757",
            name: "L\u01b0\u01a1ng S\u1eed A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7758",
            name: "L\u01b0\u01a1ng S\u1eed B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7759", name: "L\u01b0\u01a1ng S\u1eed C", prefix: "Ph\u1ed1" },
          {
            id: "7760",
            name: "L\u00fd V\u0103n Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7761", name: "Mai Anh Tu\u1ea5n", prefix: "Ph\u1ed1" },
          {
            id: "7762",
            name: "Nam \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7763",
            name: "Ng\u00f4 S\u0129 Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7764",
            name: "Ng\u00f4 T\u1ea5t T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7765",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7766",
            name: "Nguy\u1ec5n C\u00f4ng Hoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7767",
            name: "Nguy\u00ean H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7768",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7769",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7770",
            name: "Nguy\u1ec5n Ng\u1ecdc N\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7771",
            name: "Nguy\u1ec5n Nh\u01b0 \u0110\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7772", name: "Nguy\u1ec5n Ph\u00fac Lai", prefix: "Ph\u1ed1" },
          {
            id: "7773",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7774",
            name: "\u00d4 Ch\u1ee3 D\u1eeba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7775",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7776",
            name: "Phan Huy Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7777",
            name: "Phan Ph\u00fa Ti\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7778", name: "Phan Ph\u00f9 Ti\u00ean", prefix: "Ph\u1ed1" },
          {
            id: "7779",
            name: "Phan V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7780",
            name: "Ph\u00e1o \u0110\u00e0i L\u00e1ng",
            prefix: "Ph\u1ed1",
          },
          { id: "7781", name: "Ph\u1ed1 224", prefix: "Ph\u1ed1" },
          { id: "7782", name: "Ph\u1ed1 V\u1ecdng", prefix: "Ph\u1ed1" },
          { id: "7783", name: "Ph\u01b0\u01a1ng Mai", prefix: "Ph\u1ed1" },
          {
            id: "7784",
            name: "Quan Th\u1ed5 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7785",
            name: "Quan Th\u1ed5 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7786",
            name: "Qu\u1ed1c T\u1eed Gi\u00e1m",
            prefix: "Ph\u1ed1",
          },
          {
            id: "7787",
            name: "T\u00e2y S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7788",
            name: "T\u00e2y S\u01a1n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7789", name: "Th\u00e1i H\u00e0", prefix: "Ph\u1ed1" },
          { id: "7790", name: "Th\u00e1i Th\u1ecbnh", prefix: "Ph\u1ed1" },
          { id: "7791", name: "Th\u00e1i Th\u1ecbnh 1", prefix: "Ph\u1ed1" },
          { id: "7792", name: "Th\u00e1i Th\u1ecbnh 2", prefix: "Ph\u1ed1" },
          {
            id: "7793",
            name: "Th\u00e0nh C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7794",
            name: "Thanh Mi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7795",
            name: "Thanh Mi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7796",
            name: "Th\u1ecbnh H\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7797",
            name: "Th\u1ecbnh H\u00e0o 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7798",
            name: "Th\u1ecbnh H\u00e0o 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7799",
            name: "Th\u1ecbnh Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7800", name: "Th\u1ed5 Quan", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7801", name: "Th\u00f4ng Phong", prefix: "Ph\u1ed1" },
          {
            id: "7802",
            name: "Th\u01b0\u1ee3ng \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7803",
            name: "Th\u1ee7y L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7804",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "7805",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7806",
            name: "Tr\u1ea7n H\u1eefu T\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7807",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7808",
            name: "Tr\u1ea7n Qu\u00ed C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7809",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7810",
            name: "Tr\u1ecbnh Ho\u00e0i \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7811", name: "Tr\u00fac Kh\u00ea", prefix: "Ph\u1ed1" },
          { id: "7812", name: "Trung Li\u1ec7t", prefix: "Ph\u1ed1" },
          {
            id: "7813",
            name: "Trung Ph\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7814", name: "Trung T\u1ea3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7815",
            name: "Trung Ti\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7816",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7817",
            name: "V\u0103n Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7818",
            name: "V\u0103n Ch\u01b0\u01a1ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7819",
            name: "V\u0103n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7820", name: "V\u0103n Mi\u1ebfu", prefix: "Ph\u1ed1" },
          {
            id: "7821",
            name: "V\u00e0nh \u0110ai 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7822",
            name: "V\u0129nh H\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7823",
            name: "V\u00f5 V\u0103n D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7824", name: "V\u0169 Ng\u1ecdc Phan", prefix: "Ph\u1ed1" },
          {
            id: "7825",
            name: "Vu\u0303 Tha\u0323nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7826", name: "X\u00e3 \u0110\u00e0n", prefix: "Ph\u1ed1" },
          {
            id: "7827",
            name: "X\u00e3 \u0110\u00e0n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7828", name: "X\u00e3 \u0110\u00e0n 3", prefix: "Ph\u1ed1" },
          { id: "7829", name: "Y Mi\u1ebfu", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7830",
            name: "Y\u00ean La\u0303ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7831",
            name: "Y\u00ean Th\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1369",
            name: "101 L\u00e1ng H\u1ea1",
            lat: "21.013721466064",
            lng: "105.8134765625",
          },
          {
            id: "1370",
            name: "101A Nguy\u1ec5n Khuy\u1ebfn",
            lat: "21.028150558472",
            lng: "105.83915710449",
          },
          {
            id: "1371",
            name: "102 Tr\u01b0\u1eddng Chinh Meco",
            lat: "20.999938964844",
            lng: "105.83637237549",
          },
          {
            id: "1372",
            name: "131 ph\u1ed1 Th\u00e1i H\u00e0",
            lat: "21.013097763061",
            lng: "105.81949615478",
          },
          {
            id: "1373",
            name: "170 \u0110\u00ea La Th\u00e0nh - GP Building",
            lat: "21.021224975586",
            lng: "105.82806396484",
          },
          {
            id: "1374",
            name: "183 Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            lat: "21.014120101929",
            lng: "105.82740020752",
          },
          {
            id: "1375",
            name: "187 T\u00e2y S\u01a1n",
            lat: "21.013336181641",
            lng: "105.82765197754",
          },
          {
            id: "1376",
            name: "25 V\u0169 Ng\u1ecdc Phan",
            lat: "21.013818740845",
            lng: "105.81130218506",
          },
          {
            id: "1377",
            name: "257 Gi\u1ea3i Ph\u00f3ng",
            lat: "20.998628616333",
            lng: "105.84197998047",
          },
          {
            id: "1378",
            name: "27 Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            lat: "21.018184661865",
            lng: "105.81126403809",
          },
          {
            id: "1379",
            name: "360 T\u00e2y S\u01a1n",
            lat: "21.004644393921",
            lng: "105.82197570801",
          },
          {
            id: "1380",
            name: "59-63 Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            lat: "21.019290924072",
            lng: "105.80796051025",
          },
          {
            id: "1381",
            name: "B4 v\u00e0 B14 Kim Li\u00ean",
            lat: "21.010158538818",
            lng: "105.83633422852",
          },
          {
            id: "1382",
            name: "B7 - B10 Kim Li\u00ean",
            lat: "21.009407043457",
            lng: "105.83549499512",
          },
          {
            id: "1384",
            name: "C'land Tower",
            lat: "21.017286300659",
            lng: "105.83079528809",
          },
          {
            id: "1383",
            name: "Capital Garden 102 Tr\u01b0\u1eddng Chinh Kinh \u0110\u00f4",
            lat: "21.00057220459",
            lng: "105.83708190918",
          },
          {
            id: "1385",
            name: "D\u2019. Le Pont D\u2019or - Ho\u00e0ng C\u1ea7u",
            lat: "21.019493103027",
            lng: "105.82234191894",
          },
          {
            id: "1387",
            name: "DSD Building",
            lat: "21.018976211548",
            lng: "105.81674194336",
          },
          {
            id: "1386",
            name: "\u0110\u00e0o Duy Anh Tower - 9 \u0110\u00e0o Duy Anh",
            lat: "21.008693695068",
            lng: "105.83807373047",
          },
          {
            id: "1388",
            name: "Eurowindow T\u00f4n Th\u1ea5t T\u00f9ng",
            lat: "21.003387451172",
            lng: "105.83012390137",
          },
          {
            id: "1389",
            name: "Ford Th\u0103ng Long",
            lat: "21.013093948364",
            lng: "105.81308746338",
          },
          {
            id: "1390",
            name: "Geleximco 36 Ho\u00e0ng C\u1ea7u",
            lat: "21.01708984375",
            lng: "105.82386016846",
          },
          {
            id: "1391",
            name: "Gi\u1ea3ng V\u00f5 Complex",
            lat: "21.025989532471",
            lng: "105.82312011719",
          },
          {
            id: "1392",
            name: "H\u00e0 Th\u00e0nh Plaza",
            lat: "21.008867263794",
            lng: "105.82034301758",
          },
          {
            id: "1393",
            name: "HACO Complex Tower",
            lat: "21.006830215454",
            lng: "105.83361816406",
          },
          {
            id: "1394",
            name: "H\u1ea3i \u0110\u0103ng Tower",
            lat: "21.020587921143",
            lng: "105.81804656982",
          },
          {
            id: "1395",
            name: "Hapro Building",
            lat: "21.028144836426",
            lng: "105.83322906494",
          },
          {
            id: "1396",
            name: "Hateco Plaza",
            lat: "21.019966125488",
            lng: "105.80923461914",
          },
          {
            id: "1397",
            name: "Ho\u00e0ng C\u1ea7u Skyline",
            lat: "21.018220901489",
            lng: "105.82409667969",
          },
          {
            id: "1398",
            name: "Hong Kong Tower",
            lat: "21.027200698852",
            lng: "105.80382537842",
          },
          {
            id: "1399",
            name: "H\u1ee3p Nh\u1ea5t Building",
            lat: "21.015050888061",
            lng: "105.83484649658",
          },
          {
            id: "1400",
            name: "ICC Commercial Center",
            lat: "21.021810531616",
            lng: "105.80918121338",
          },
          {
            id: "1401",
            name: "Icon4 Tower",
            lat: "21.028160095215",
            lng: "105.80431365967",
          },
          {
            id: "1402",
            name: "Kh\u00e2m Thi\u00ean Building",
            lat: "21.018865585327",
            lng: "105.83438873291",
          },
          {
            id: "1403",
            name: "Kinh Do Building",
            lat: "21.006963729858",
            lng: "105.82285308838",
          },
          {
            id: "1404",
            name: "La Casa Villa",
            lat: "21.01411819458",
            lng: "105.81095886231",
          },
          {
            id: "1405",
            name: "M3 - M4 Nguy\u1ec5n Ch\u00ed Thanh",
            lat: "21.02020072937",
            lng: "105.8088760376",
          },
          {
            id: "1406",
            name: "M5 Nguy\u1ec5n Ch\u00ed Thanh",
            lat: "21.021142959595",
            lng: "105.80934906006",
          },
          {
            id: "1407",
            name: "MBLand Tower",
            lat: "21.006483078003",
            lng: "105.8307800293",
          },
          {
            id: "1408",
            name: "Mecanimex",
            lat: "21.01411819458",
            lng: "105.81095886231",
          },
          {
            id: "1409",
            name: "MIPEC Towers",
            lat: "21.005880355835",
            lng: "105.82328033447",
          },
          {
            id: "1410",
            name: "Ocean Park",
            lat: "21.007434844971",
            lng: "105.84014892578",
          },
          {
            id: "1411",
            name: "Oriental Plaza",
            lat: "21.018495559692",
            lng: "105.81601715088",
          },
          {
            id: "1412",
            name: "Oriental Tower - 324 T\u00e2y S\u01a1n",
            lat: "21.005504608154",
            lng: "105.82211303711",
          },
          {
            id: "1413",
            name: "Petrowaco 97 L\u00e1ng H\u1ea1",
            lat: "21.014455795288",
            lng: "105.81383514404",
          },
          {
            id: "1414",
            name: "Porte De Ville",
            lat: "21.016185760498",
            lng: "105.80822753906",
          },
          {
            id: "1415",
            name: "Sapphire Tower",
            lat: "21.024168014526",
            lng: "105.82690429688",
          },
          {
            id: "1416",
            name: "Sky City Towers-88 L\u00e1ng H\u1ea1",
            lat: "21.012491226196",
            lng: "105.81131744385",
          },
          {
            id: "1417",
            name: "S\u00f4ng H\u1ed3ng Park View",
            lat: "21.014835357666",
            lng: "105.81726837158",
          },
          {
            id: "1418",
            name: "T\u00e2n \u00c1 \u0110\u1ea1i Th\u00e0nh",
            lat: "21.026477813721",
            lng: "105.83326721191",
          },
          {
            id: "1419",
            name: "T\u1eadp th\u1ec3 H\u00e0o Nam",
            lat: "21.026437759399",
            lng: "105.82570648193",
          },
          {
            id: "1420",
            name: "T\u0110C Ho\u00e0ng C\u1ea7u",
            lat: "21.01944732666",
            lng: "105.82165527344",
          },
          {
            id: "1421",
            name: "T\u0110L Building",
            lat: "21.015607833862",
            lng: "105.81392669678",
          },
          {
            id: "1422",
            name: "Twin Towers",
            lat: "21.025880813599",
            lng: "105.79917144775",
          },
          {
            id: "1423",
            name: "VCCI Tower - s\u1ed1 9 \u0110\u00e0o Duy Anh",
            lat: "21.008779525757",
            lng: "105.8385848999",
          },
          {
            id: "1424",
            name: "Vi\u1ec5n \u0110\u00f4ng Building 36 Ho\u00e0ng C\u1ea7u",
            lat: "21.019750595093",
            lng: "105.82315826416",
          },
          {
            id: "1425",
            name: "Viet Tower",
            lat: "21.009660720825",
            lng: "105.82355499268",
          },
          {
            id: "1426",
            name: "Vinaconex Tower",
            lat: "21.014762878418",
            lng: "105.81320953369",
          },
          {
            id: "1427",
            name: "Vincom Center Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            lat: "21.006196975708",
            lng: "105.83108520508",
          },
          {
            id: "1428",
            name: "Vinhomes Nguy\u1ec5n Ch\u00ed Thanh",
            lat: "21.022953033447",
            lng: "105.80981445312",
          },
          {
            id: "1429",
            name: "V\u01b0\u1eddn Xu\u00e2n - 71 Nguy\u1ec5n Ch\u00ed Thanh",
            lat: "21.024124145508",
            lng: "105.81119537353",
          },
        ],
      },
      {
        id: "33",
        name: "Gia L\u00e2m",
        wards: [
          { id: "487", name: "B\u00e1t Tr\u00e0ng", prefix: "X\u00e3" },
          { id: "488", name: "C\u1ed5 Bi", prefix: "X\u00e3" },
          { id: "493", name: "D\u01b0\u01a1ng H\u00e0", prefix: "X\u00e3" },
          { id: "494", name: "D\u01b0\u01a1ng Quang", prefix: "X\u00e3" },
          { id: "495", name: "D\u01b0\u01a1ng X\u00e1", prefix: "X\u00e3" },
          { id: "489", name: "\u0110a T\u1ed1n", prefix: "X\u00e3" },
          { id: "490", name: "\u0110\u1eb7ng X\u00e1", prefix: "X\u00e3" },
          { id: "491", name: "\u0110\u00ecnh Xuy\u00ean", prefix: "X\u00e3" },
          { id: "492", name: "\u0110\u00f4ng D\u01b0", prefix: "X\u00e3" },
          { id: "496", name: "Ki\u00eau K\u1ef5", prefix: "X\u00e3" },
          { id: "497", name: "Kim Lan", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "498", name: "Kim S\u01a1n", prefix: "X\u00e3" },
          { id: "499", name: "L\u1ec7 Chi", prefix: "X\u00e3" },
          { id: "500", name: "Ninh Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "501", name: "Ph\u00f9 \u0110\u1ed5ng", prefix: "X\u00e3" },
          { id: "502", name: "Ph\u00fa Th\u1ecb", prefix: "X\u00e3" },
          {
            id: "503",
            name: "Tr\u00e2u Qu\u1ef3",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "504", name: "Trung M\u1ea7u", prefix: "X\u00e3" },
          { id: "505", name: "V\u0103n \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "506", name: "Y\u00ean Th\u01b0\u1eddng", prefix: "X\u00e3" },
          {
            id: "507",
            name: "Y\u00ean Vi\u00ean",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "508", name: "Y\u00ean Vi\u00ean", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "7832", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7833", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7834", name: "39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7835", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7836", name: "A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7837",
            name: "\u00c1i M\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7838",
            name: "An \u0110\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7839",
            name: "An \u0110\u00e0o A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7840",
            name: "An \u0110\u00e0o B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7841",
            name: "An \u0110\u00e0o C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7842",
            name: "An \u0110\u00e0o D",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7843", name: "An L\u1ea1c", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7844", name: "B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7845",
            name: "B\u00e1t Tr\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7846",
            name: "B\u00ecnh Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7847", name: "C", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7848",
            name: "C\u1ea7u \u0110u\u1ed1ng",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "7849",
            name: "C\u1ea7u V\u01b0\u1ee3t Ph\u00fa Th\u1ecb",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "7850",
            name: "Ch\u00ednh Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7851", name: "C\u1ed5 Bi", prefix: "\u0111\u01b0\u1eddng" },
          {
            id: "7852",
            name: "C\u1eedu Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7853",
            name: "C\u1eedu Vi\u1ec7t 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7854",
            name: "C\u1eedu Vi\u1ec7t 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7855", name: "D", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7862",
            name: "D\u1ed1c L\u00e3 Ninh Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7865",
            name: "D\u01b0\u01a1ng \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7866", name: "D\u01b0\u01a1ng Quang", prefix: "Ph\u1ed1" },
          { id: "7868", name: "DX 03", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7869", name: "DX 04", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7870", name: "DX 06", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7856",
            name: "\u0110a T\u1ed1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7857",
            name: "\u0110\u1eb7ng Ph\u00fac Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7858",
            name: "\u0110\u00e0o Nguy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7859",
            name: "\u0110\u00e0o Nguy\u00ean A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7860",
            name: "\u0110\u00e0o Xuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7861",
            name: "\u0110\u00ecnh Xuy\u00ean",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "7863",
            name: "\u0110\u00f4ng D\u01b0 Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7864",
            name: "\u0110\u1ed5ng Xuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7867",
            name: "\u0110\u01b0\u1eddng Y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7871", name: "E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7872", name: "G", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7873", name: "Giang Cao", prefix: "\u0111\u01b0\u1eddng" },
          {
            id: "7874",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7875", name: "K", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7876",
            name: "Khi\u00eau N\u0103ng T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7877",
            name: "Khi\u1ebfu N\u0103ng T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7878", name: "Khoan T\u1ebf", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7879",
            name: "Ki\u00ean Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7880",
            name: "Ki\u00eau K\u1ef5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7881", name: "Kim \u00c2u", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7882", name: "Kim H\u1ed3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7883", name: "Kim Quan", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7884",
            name: "L\u00e2m Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7885",
            name: "L\u00ea C\u00f4ng Mi\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7886", name: "L\u1ec7 M\u1eadt", prefix: "Ph\u1ed1" },
          {
            id: "7887",
            name: "Li\u00ean t\u1ec9nh 179",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7888", name: "M", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7889", name: "Mai Ph\u00fac", prefix: "Ph\u1ed1" },
          { id: "7890", name: "N", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7891",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7892",
            name: "Ng\u00f4 Xu\u00e2n Qu\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7893",
            name: "Ng\u1ecdc \u0110\u1ed9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7894",
            name: "Ng\u1ecdc L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7895",
            name: "Ng\u1ecdc Th\u1ee5y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7896",
            name: "Nguy\u1ec5n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7897",
            name: "Nguy\u1ec5n \u0110\u1ee9c Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7898",
            name: "Nguy\u1ec5n S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7899",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7900",
            name: "Ninh Hi\u1ec7p",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "7901",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7902",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          { id: "7903", name: "Ph\u1ed1 Nha", prefix: "Ph\u1ed1" },
          { id: "7904", name: "Ph\u00fa Gia", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7905",
            name: "Qu\u1ed1c L\u1ed9 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7906",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7907",
            name: "Qu\u1ed1c l\u1ed9 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7908",
            name: "Qu\u1ed1c l\u1ed9 39B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7909",
            name: "Qu\u1ed1c L\u1ed9 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7910", name: "R", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7911", name: "S", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7912", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7913", name: "T", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7914",
            name: "Thi\u00ean \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7915",
            name: "Th\u01b0\u1ee3ng Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7916",
            name: "T\u1ec9nh l\u1ed9 179",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7917",
            name: "T\u1ec9nh l\u1ed9 181",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7918",
            name: "T\u1ec9nh l\u1ed9 270",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7919",
            name: "T\u1ec9nh l\u1ed9 424",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7920",
            name: "T\u1ec9nh l\u1ed9 84",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7921",
            name: "Tr\u00e2u Qu\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7922",
            name: "Tr\u00f9ng Qu\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7923",
            name: "Tr\u01b0\u01a1ng T\u1ea5n Sang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7924", name: "U", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7925", name: "V", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7926",
            name: "V\u00f5 \u0110\u00ecnh T\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7927",
            name: "V\u00f5 Trung Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7928", name: "Voi Ph\u1ee5c", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7929", name: "Y", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7930", name: "\u1ef6 Lan", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7931",
            name: "Y\u00ean Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7932",
            name: "Y\u00ean T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7933",
            name: "Y\u00ean Th\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7934",
            name: "Y\u00ean Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1430",
            name: "\u0110\u1eb7ng X\u00e1 1",
            lat: "21.016773223877",
            lng: "105.9501876831",
          },
          {
            id: "1431",
            name: "\u0110\u1eb7ng X\u00e1 2",
            lat: "21.015619277954",
            lng: "105.94860839844",
          },
          {
            id: "1432",
            name: "Hoa Vi\u00ean Villas",
            lat: "21.016941070557",
            lng: "105.95265960693",
          },
          {
            id: "1433",
            name: "L\u00e2m Vi\u00ean Villas",
            lat: "21.019399642944",
            lng: "105.94985198975",
          },
          {
            id: "1434",
            name: "Vincity Gia L\u00e2m",
            lat: "21.010520935059",
            lng: "105.95294189453",
          },
        ],
      },
      {
        id: "34",
        name: "H\u00e0 \u0110\u00f4ng",
        wards: [
          { id: "509", name: "Bi\u00ean Giang", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "511",
            name: "D\u01b0\u01a1ng N\u1ed9i",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "510", name: "\u0110\u1ed3ng Mai", prefix: "Ph\u01b0\u1eddng" },
          { id: "512", name: "H\u00e0 C\u1ea7u", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "513",
            name: "Ki\u1ebfn H\u01b0ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "514", name: "La Kh\u00ea", prefix: "Ph\u01b0\u1eddng" },
          { id: "515", name: "M\u1ed7 Lao", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "516",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "517", name: "Ph\u00fa La", prefix: "Ph\u01b0\u1eddng" },
          { id: "518", name: "Ph\u00fa L\u00e3m", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "519",
            name: "Ph\u00fa L\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "520", name: "Ph\u00fac La", prefix: "Ph\u01b0\u1eddng" },
          { id: "521", name: "Quang Trung", prefix: "Ph\u01b0\u1eddng" },
          { id: "522", name: "V\u1ea1n Ph\u00fac", prefix: "Ph\u01b0\u1eddng" },
          { id: "523", name: "V\u0103n Qu\u00e1n", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "524",
            name: "Y\u00ean Ngh\u0129a",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "525", name: "Y\u1ebft Ki\u00eau", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "7935", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7936", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7937", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7938", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7939", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7940", name: "21B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7941", name: "25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7942", name: "36", prefix: "Ph\u1ed1" },
          { id: "7943", name: "430", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7944", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7945", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7946", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7947", name: "66", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7948", name: "70", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7949", name: "72", prefix: "Ph\u1ed1" },
          { id: "7950", name: "An H\u00f2a", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7951", name: "An Khang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7952",
            name: "An Ph\u00fa \u0110\u00f4ng 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7953", name: "An Th\u1eafng", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7954", name: "Ao Sen", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7955", name: "Ba La", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7956", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "7957",
            name: "B\u1eafc L\u00e3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7958",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7959",
            name: "B\u1ea1ch Th\u00e1i B\u01b0\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7960",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7961", name: "Bia B\u00e0", prefix: "\u0110\u01b0\u1eddng" },
          { id: "7962", name: "B\u00edch C\u00e2u", prefix: "Ph\u1ed1" },
          {
            id: "7963",
            name: "Bi\u00ean Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7964",
            name: "B\u00f9i B\u1eb1ng \u0110o\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7965",
            name: "B\u00f9i Th\u1ecb C\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7966",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7967", name: "C\u1ea7u Am", prefix: "Ph\u1ed1" },
          {
            id: "7968",
            name: "C\u1ea7u B\u01b0\u01a1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7969",
            name: "C\u1ea7u \u0110\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7970",
            name: "C\u1ea7u \u0110\u01a1 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7971",
            name: "C\u1ea7u \u0110\u01a1 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7972",
            name: "C\u1ea7u \u0110\u01a1 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7973",
            name: "C\u1ea7u \u0110\u01a1 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7974",
            name: "Chi\u1ebfn Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7975",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7976",
            name: "Ch\u00f9a V\u00f5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7977",
            name: "C\u1ed5 B\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7978",
            name: "C\u1ed1ng \u0110\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7979",
            name: "C\u1eeda Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "7984", name: "Do L\u1ed9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "7980",
            name: "\u0110a S\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7981",
            name: "\u0110\u1ea1i An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7982",
            name: "\u0110\u1ea1i M\u1ed7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7983",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7985",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7986",
            name: "\u0110\u1ed9ng L\u00e3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7987",
            name: "Giang Ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7988",
            name: "Gi\u1ebfng Sen",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7989",
            name: "H\u00e0 C\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7990",
            name: "H\u00e0 Tr\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7991",
            name: "H\u00e0 Tr\u00ec 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7992",
            name: "H\u00e0 Tr\u00ec 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7993",
            name: "H\u00e0 Tr\u00ec 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7994",
            name: "Ha\u0300 Tri\u0300 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7995",
            name: "H\u00e0 Tr\u00ec 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7996",
            name: "H\u00e0n Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7997",
            name: "H\u1ea1nh Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7998",
            name: "H\u1eadu L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "7999",
            name: "Hi\u1ec7p Th\u00e0nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8000",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8001",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8002",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8003",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8004",
            name: "H\u1eefu H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8005",
            name: "Huy\u00ea\u0300n Ky\u0300",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8006",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8007",
            name: "Ki\u1ebfn H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8008", name: "La C\u1ea3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8009",
            name: "La D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8010", name: "La N\u1ed9i", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8011",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8012",
            name: "L\u00ea H\u1eefu Tr\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8013", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8014",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8015",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8016",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8017",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "8018",
            name: "L\u00ea V\u0103n L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8019",
            name: "L\u00ea Xu\u00e2n \u0110i\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8020",
            name: "L\u1ed9c H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8021",
            name: "L\u01b0\u01a1ng Ng\u1ecdc Quy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8022",
            name: "L\u01b0\u01a1ng V\u0103n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8023",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8024",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "8025",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8026",
            name: "M\u1ea1c Th\u1ecb B\u01b0\u1edfi",
            prefix: "Ph\u1ed1",
          },
          {
            id: "8027",
            name: "M\u1eadu L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8028", name: "Minh Khai", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8029", name: "M\u1ed7 Lao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8030",
            name: "M\u01b0\u01a1ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8031",
            name: "Nam C\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8032",
            name: "Ngh\u0129a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8033",
            name: "Ngh\u0129a L\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8034",
            name: "Ng\u00f4 Gia Kh\u1ea3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8035",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8036",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8037",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8038",
            name: "Ng\u00f4 Th\u00ec S\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8039",
            name: "Nguy\u1ec5n Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8040",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8041",
            name: "Nguy\u1ec5n H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8042",
            name: "Nguy\u1ec5n Huy Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8043",
            name: "Nguy\u1ec5n Kh\u1eafc H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8044",
            name: "Nguy\u1ec5n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8045",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8046",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8047",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8048",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8049",
            name: "Nguy\u1ec5n Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8050",
            name: "Nguy\u1ec5n V\u0103n L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8051",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8052",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8053",
            name: "Nh\u00e2n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8054",
            name: "Nh\u00e2n Tr\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8055",
            name: "Nh\u01a1n Tr\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8056",
            name: "Nhu\u1ec7 Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8057",
            name: "N\u00fai M\u1ed9t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8058",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8059",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8060",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8061",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8062",
            name: "Phan Huy Ch\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8063",
            name: "Phan Tr\u1ecdng Tu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8064",
            name: "Ph\u1ed1 L\u1ee5a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8065",
            name: "Ph\u00fa L\u00e3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8066", name: "Ph\u00fa L\u01b0\u01a1ng", prefix: "Ph\u1ed1" },
          {
            id: "8067",
            name: "Ph\u00fa M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8068",
            name: "Ph\u00fa Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8069", name: "Ph\u00fac La", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8070",
            name: "Ph\u00fac Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8071",
            name: "Ph\u00fac Th\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8072", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "8073",
            name: "Ph\u01b0\u1ee3ng B\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8074",
            name: "Quan Th\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8075",
            name: "Quang L\u00e3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8076", name: "Quang Minh", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8077", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8078",
            name: "Qu\u1ed1c l\u1ed9 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8079",
            name: "Qu\u1ed1c l\u1ed9 21B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8080",
            name: "Qu\u1ed1c l\u1ed9 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8081",
            name: "Qu\u1ed1c l\u1ed9 70",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8082",
            name: "Quy\u1ebft Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8083",
            name: "Quy\u1ebft Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8084",
            name: "R\u1ea1ng \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8085",
            name: "S\u00f4ng Nhu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8086",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8087",
            name: "T\u1ea3n \u0110\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8088",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8089", name: "T\u00e2n Xa", prefix: "Ph\u1ed1" },
          {
            id: "8090",
            name: "T\u00e2y S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8091",
            name: "Thanh B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8092",
            name: "Th\u00e0nh C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8093",
            name: "Thanh L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8094",
            name: "Thanh L\u00e3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8095",
            name: "Th\u1ea1nh L\u1ed9c 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8096",
            name: "Th\u1ea1nh Xu\u00e2n 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8097",
            name: "Th\u1ea1nh Xu\u00e2n 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8098",
            name: "Ti\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8099",
            name: "T\u1ec9nh L\u1ed9 70",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8100",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8101",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8102",
            name: "T\u1ed1 H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8103",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8104",
            name: "Tr\u1ea7n \u0110\u0103ng Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8105",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8106",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8107",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8108",
            name: "Tr\u1ea7n Quang \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8109",
            name: "Tr\u1ea7n V\u0103n Chu\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8110",
            name: "Tr\u00edch S\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8111",
            name: "Trinh L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8112",
            name: "Trung Ki\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8113",
            name: "Tr\u01b0ng Nh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8114",
            name: "Tr\u01b0ng Tr\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8115",
            name: "Tr\u01b0\u01a1ng C\u00f4ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8116",
            name: "Tr\u01b0\u01a1ng V\u0103n \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8117",
            name: "V\u0103n Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8118", name: "V\u0103n La", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8119",
            name: "V\u0103n N\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8120",
            name: "V\u0103n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8121",
            name: "V\u0103n Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8122", name: "V\u1ea1n Ph\u00fac", prefix: "Ph\u1ed1" },
          {
            id: "8123",
            name: "V\u0103n Qu\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8124",
            name: "V\u0103n Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8125",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8126", name: "Xa La", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8127", name: "X\u1ed1m", prefix: "Ph\u1ed1" },
          { id: "8128", name: "\u1ef6 La", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8129", name: "Y S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8130",
            name: "Y\u00ean B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8131",
            name: "Y\u00ean H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8132",
            name: "Y\u00ean L\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8133",
            name: "Y\u00ean Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8134",
            name: "Y\u00ean Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8135",
            name: "Y\u00ean X\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8136",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1435",
            name: "16B Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            lat: "20.972915649414",
            lng: "105.77559661865",
          },
          {
            id: "1436",
            name: "7 Tr\u1ea7n Ph\u00fa",
            lat: "20.976259231567",
            lng: "105.78331756592",
          },
          {
            id: "1437",
            name: "89 Ph\u00f9ng H\u01b0ng",
            lat: "20.970031738281",
            lng: "105.78502655029",
          },
          {
            id: "1438",
            name: "An H\u01b0ng",
            lat: "20.974203109741",
            lng: "105.75543212891",
          },
          {
            id: "1439",
            name: "An Khang Villa",
            lat: "20.993957519531",
            lng: "105.78890991211",
          },
          {
            id: "1440",
            name: "An L\u1ea1c Tower",
            lat: "20.964876174927",
            lng: "105.76445007324",
          },
          {
            id: "1441",
            name: "An Ph\u00fa Shop Villa",
            lat: "20.993957519531",
            lng: "105.78890991211",
          },
          {
            id: "1442",
            name: "An V\u01b0\u1ee3ng Villas",
            lat: "20.972412109375",
            lng: "105.76152801514",
          },
          {
            id: "1443",
            name: "Anland Nam C\u01b0\u1eddng",
            lat: "20.976610183716",
            lng: "105.76152038574",
          },
          {
            id: "1444",
            name: "B\u1eafc H\u00e0 Fodacon",
            lat: "20.982532501221",
            lng: "105.78964233398",
          },
          {
            id: "1445",
            name: "B\u00ecnh V\u01b0\u1ee3ng 200 Quang Trung",
            lat: "20.966875076294",
            lng: "105.76963806152",
          },
          {
            id: "1446",
            name: "Booyoung",
            lat: "20.982448577881",
            lng: "105.78227996826",
          },
          {
            id: "1447",
            name: "CT2 Y\u00ean Ngh\u0129a",
            lat: "20.954057693481",
            lng: "105.74098205566",
          },
          {
            id: "1448",
            name: "CT3 Y\u00ean Ngh\u0129a",
            lat: "20.955513000488",
            lng: "105.74464416504",
          },
          {
            id: "1449",
            name: "CT5 V\u0103n Kh\u00ea",
            lat: "20.973022460938",
            lng: "105.76327514648",
          },
          {
            id: "1450",
            name: "Daewoo Cleve",
            lat: "20.958673477173",
            lng: "105.766746521",
          },
          {
            id: "1452",
            name: "D\u01b0\u01a1ng N\u1ed9i",
            lat: "20.971992492676",
            lng: "105.75634002685",
          },
          {
            id: "1451",
            name: "\u0110\u00f4 Ngh\u0129a",
            lat: "20.957902908325",
            lng: "105.73589324951",
          },
          {
            id: "1453",
            name: "Ellipse Tower",
            lat: "20.977453231811",
            lng: "105.78392028809",
          },
          {
            id: "1454",
            name: "Evelyne Gardens - ParkCity H\u00e0 N\u1ed9i",
            lat: "20.964075088501",
            lng: "105.75616455078",
          },
          {
            id: "1455",
            name: "FLC Star Tower",
            lat: "20.962554931641",
            lng: "105.76525115967",
          },
          {
            id: "1456",
            name: "Geleximco L\u00ea Tr\u1ecdng T\u1ea5n",
            lat: "21.006153106689",
            lng: "105.73284912109",
          },
          {
            id: "1457",
            name: "Golden Millennium",
            lat: "20.977905273438",
            lng: "105.78373718262",
          },
          {
            id: "1458",
            name: "GoldSilk Complex",
            lat: "20.978439331055",
            lng: "105.77264404297",
          },
          {
            id: "1459",
            name: "H\u00e0 \u0110\u00f4ng Park View",
            lat: "20.954797744751",
            lng: "105.77188873291",
          },
          {
            id: "1460",
            name: "H\u00e0 N\u1ed9i Time Towers",
            lat: "20.974182128906",
            lng: "105.77597808838",
          },
          {
            id: "1461",
            name: "Hemisco Xa La",
            lat: "20.961841583252",
            lng: "105.79552459717",
          },
          {
            id: "1462",
            name: "Hesco",
            lat: "20.978576660156",
            lng: "105.78734588623",
          },
          {
            id: "1463",
            name: "H\u1ed3 G\u01b0\u01a1m Plaza",
            lat: "20.979290008545",
            lng: "105.78546142578",
          },
          {
            id: "1464",
            name: "HP Landmark Tower",
            lat: "20.972354888916",
            lng: "105.75798034668",
          },
          {
            id: "1465",
            name: "HPC Landmark 105",
            lat: "20.973667144775",
            lng: "105.75871276856",
          },
          {
            id: "1466",
            name: "HUD3 Tower",
            lat: "20.966337203979",
            lng: "105.77752685547",
          },
          {
            id: "1467",
            name: "Hyundai Hillstate",
            lat: "20.963682174683",
            lng: "105.77370452881",
          },
          {
            id: "1468",
            name: "ICID Complex",
            lat: "20.981916427612",
            lng: "105.74249267578",
          },
          {
            id: "1469",
            name: "K\u0110T \u0110\u1ed3ng Mai",
            lat: "20.930871963501",
            lng: "105.74098205566",
          },
          {
            id: "1470",
            name: "K\u0110T La Kh\u00ea",
            lat: "20.965642929077",
            lng: "105.75942993164",
          },
          {
            id: "1471",
            name: "K\u0110T Ph\u00fa L\u01b0\u01a1ng",
            lat: "20.951971054077",
            lng: "105.78247070312",
          },
          {
            id: "1472",
            name: "K\u0110T V\u0103n Ph\u00fa",
            lat: "20.958282470703",
            lng: "105.76719665527",
          },
          {
            id: "1473",
            name: "K\u0110T V\u0103n Qu\u00e1n",
            lat: "20.973962783814",
            lng: "105.7890701294",
          },
          {
            id: "1474",
            name: "K\u0110T Xa La",
            lat: "20.962467193604",
            lng: "105.79236602783",
          },
          {
            id: "1475",
            name: "Khu nh\u00e0 \u1edf B\u1ed9 t\u01b0 l\u1ec7nh Th\u1ee7 \u0111\u00f4 H\u00e0 N\u1ed9i",
            lat: "20.954057693481",
            lng: "105.74098205566",
          },
          {
            id: "1476",
            name: "Khu nh\u00e0 \u1edf H\u01b0ng Th\u1ecbnh",
            lat: "20.952806472778",
            lng: "105.78500366211",
          },
          {
            id: "1477",
            name: "Khu nh\u00e0 \u1edf Ph\u00fa M\u1ef9",
            lat: "20.975999832153",
            lng: "105.7769241333",
          },
          {
            id: "1478",
            name: "Khu nh\u00e0 \u1edf V5 V6",
            lat: "20.962194442749",
            lng: "105.76448822022",
          },
          {
            id: "1479",
            name: "Khu nh\u00e0 ph\u1ed1 th\u01b0\u01a1ng m\u1ea1i 24h",
            lat: "20.983722686768",
            lng: "105.772605896",
          },
          {
            id: "1480",
            name: "Ki\u1ebfn H\u01b0ng",
            lat: "20.952150344849",
            lng: "105.78538513184",
          },
          {
            id: "1481",
            name: "La Casta V\u0103n Ph\u00fa",
            lat: "20.968681335449",
            lng: "105.75410461426",
          },
          {
            id: "1482",
            name: "L\u00e0ng Vi\u1ec7t Ki\u1ec1u Ch\u00e2u \u00c2u Euroland",
            lat: "20.983457565308",
            lng: "105.78564453125",
          },
          {
            id: "1483",
            name: "L\u00ea V\u0103n L\u01b0\u01a1ng Residentials",
            lat: "20.976511001587",
            lng: "105.75966644287",
          },
          {
            id: "1484",
            name: "Legend Park",
            lat: "20.978055953979",
            lng: "105.76538085938",
          },
          {
            id: "1485",
            name: "Mipec Highrise",
            lat: "20.952529907227",
            lng: "105.7903137207",
          },
          {
            id: "1486",
            name: "M\u1ed7 Lao",
            lat: "20.98268699646",
            lng: "105.78161621094",
          },
          {
            id: "1487",
            name: "Mulberry Lane",
            lat: "20.986518859863",
            lng: "105.78639984131",
          },
          {
            id: "1488",
            name: "Nacimex",
            lat: "20.993196487427",
            lng: "105.78787231445",
          },
          {
            id: "1489",
            name: "Nam C\u01b0\u1eddng Building",
            lat: "20.976673126221",
            lng: "105.7615814209",
          },
          {
            id: "1490",
            name: "Nam La Kh\u00ea",
            lat: "20.964221954346",
            lng: "105.76487731934",
          },
          {
            id: "1491",
            name: "Nam Xa la",
            lat: "20.960405349731",
            lng: "105.79469299316",
          },
          {
            id: "1492",
            name: "N\u00e0ng H\u01b0\u01a1ng",
            lat: "20.98567199707",
            lng: "105.7952041626",
          },
          {
            id: "1493",
            name: "New Skyline",
            lat: "20.979644775391",
            lng: "105.79234313965",
          },
          {
            id: "1494",
            name: "Newhouse Xa La",
            lat: "20.960222244263",
            lng: "105.79292297363",
          },
          {
            id: "1495",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            lat: "20.96933555603",
            lng: "105.76816558838",
          },
          {
            id: "1496",
            name: "Nguy\u1ec5n Ch\u00e1nh H\u00e0 \u0110\u00f4ng",
            lat: "20.970293045044",
            lng: "105.78443145752",
          },
          {
            id: "1497",
            name: "Park View Residence",
            lat: "20.964326858521",
            lng: "105.74954986572",
          },
          {
            id: "1498",
            name: "ParkCity H\u00e0 N\u1ed9i",
            lat: "20.964179992676",
            lng: "105.7560043335",
          },
          {
            id: "1499",
            name: "PCC1 Complex",
            lat: "20.952579498291",
            lng: "105.75757598877",
          },
          {
            id: "1500",
            name: "Ph\u00fa L\u00e3m",
            lat: "20.946918487549",
            lng: "105.75451660156",
          },
          {
            id: "1501",
            name: "Rainbow Building",
            lat: "20.974458694458",
            lng: "105.78774261475",
          },
          {
            id: "1502",
            name: "Sails Tower",
            lat: "20.959997177124",
            lng: "105.7985534668",
          },
          {
            id: "1503",
            name: "Samsora Premier",
            lat: "20.973217010498",
            lng: "105.77885437012",
          },
          {
            id: "1504",
            name: "SDU 143 Tr\u1ea7n Ph\u00fa",
            lat: "20.97731590271",
            lng: "105.78495788574",
          },
          {
            id: "1505",
            name: "Seasons Avenue",
            lat: "20.9870262146",
            lng: "105.7868347168",
          },
          {
            id: "1506",
            name: "SME Ho\u00e0ng Gia",
            lat: "20.969413757324",
            lng: "105.78094482422",
          },
          {
            id: "1507",
            name: "S\u00f4ng \u0110\u00e0 H\u00e0 \u0110\u00f4ng Tower",
            lat: "20.980344772339",
            lng: "105.78862762451",
          },
          {
            id: "1508",
            name: "Thanh B\u00ecnh Village",
            lat: "20.977252960205",
            lng: "105.78018188477",
          },
          {
            id: "1509",
            name: "Thanh H\u00e0 M\u01b0\u1eddng Thanh",
            lat: "20.939702987671",
            lng: "105.7872543335",
          },
          {
            id: "1510",
            name: "Th\u00e1p doanh nh\u00e2n Tower",
            lat: "20.975315093994",
            lng: "105.78067779541",
          },
          {
            id: "1511",
            name: "The Green Daisy",
            lat: "21.002592086792",
            lng: "105.73249816894",
          },
          {
            id: "1512",
            name: "The K Park",
            lat: "20.95990562439",
            lng: "105.79180145264",
          },
          {
            id: "1513",
            name: "The Pride",
            lat: "20.972398757935",
            lng: "105.75671386719",
          },
          {
            id: "1514",
            name: "The Sparks",
            lat: "20.979867935181",
            lng: "105.74391937256",
          },
          {
            id: "1515",
            name: "The Vesta",
            lat: "20.94633102417",
            lng: "105.75578308106",
          },
          {
            id: "1516",
            name: "Thi\u00ean Ni\u00ean K\u1ef7",
            lat: "20.974008560181",
            lng: "105.77889251709",
          },
          {
            id: "1517",
            name: "Th\u1ee7y L\u1ee3i Tower",
            lat: "20.963331222534",
            lng: "105.76391601562",
          },
          {
            id: "1518",
            name: "Tokyo Tower",
            lat: "20.981533050537",
            lng: "105.7707824707",
          },
          {
            id: "1519",
            name: "TSQ Galaxy",
            lat: "20.984521865845",
            lng: "105.7755355835",
          },
          {
            id: "1520",
            name: "TTTM TSQ",
            lat: "20.978330612183",
            lng: "105.7833404541",
          },
          {
            id: "1521",
            name: "Unimax Twin Tower",
            lat: "20.967233657837",
            lng: "105.76829528809",
          },
          {
            id: "1522",
            name: "Usilk City",
            lat: "20.97625541687",
            lng: "105.76264953613",
          },
          {
            id: "1523",
            name: "V\u0103n Kh\u00ea",
            lat: "20.974569320679",
            lng: "105.76432800293",
          },
          {
            id: "1524",
            name: "V\u0103n La - V\u0103n Kh\u00ea",
            lat: "20.965446472168",
            lng: "105.76873779297",
          },
          {
            id: "1525",
            name: "V\u0103n Ph\u00fa Victoria",
            lat: "20.959907531738",
            lng: "105.76819610596",
          },
          {
            id: "1526",
            name: "V\u1ea1n Ph\u00fac - Simco S\u00f4ng \u0110\u00e0",
            lat: "20.981897354126",
            lng: "105.76677703857",
          },
          {
            id: "1527",
            name: "Vinaconex 21",
            lat: "20.956735610962",
            lng: "105.75629425049",
          },
          {
            id: "1528",
            name: "Westa",
            lat: "20.978328704834",
            lng: "105.78472900391",
          },
          {
            id: "1529",
            name: "Xu\u00e2n Mai Complex",
            lat: "20.993957519531",
            lng: "105.78890991211",
          },
          {
            id: "1530",
            name: "Xu\u00e2n Mai Park State",
            lat: "20.962711334229",
            lng: "105.7734298706",
          },
          {
            id: "1531",
            name: "Xu\u00e2n Mai Riverside",
            lat: "20.983997344971",
            lng: "105.77888488769",
          },
          {
            id: "1532",
            name: "Xu\u00e2n Mai Sparks Tower",
            lat: "20.984642028809",
            lng: "105.77471923828",
          },
        ],
      },
      {
        id: "35",
        name: "Hai B\u00e0 Tr\u01b0ng",
        wards: [
          {
            id: "526",
            name: "Ba\u0323ch \u0110\u0103\u0300ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "527", name: "Ba\u0301ch Khoa", prefix: "Ph\u01b0\u1eddng" },
          { id: "528", name: "Ba\u0323ch Mai", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "529",
            name: "Bu\u0300i Thi\u0323 Xu\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "530",
            name: "C\u00e2\u0300u D\u00ea\u0300n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "531",
            name: "\u0110\u00f4\u0301ng Ma\u0301c",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "532",
            name: "\u0110\u00f4\u0300ng Nh\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "533",
            name: "\u0110\u00f4\u0300ng T\u00e2m",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "534",
            name: "L\u00ea \u0110a\u0323i Ha\u0300nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "535", name: "Minh Khai", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "536",
            name: "Ng\u00f4 Thi\u0300 Nh\u00e2\u0323m",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "537",
            name: "Nguy\u00ea\u0303n Du",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "538",
            name: "Pha\u0323m \u0110i\u0300nh H\u00f4\u0309",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "539",
            name: "Ph\u00f4\u0301 Hu\u00ea\u0301",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "540",
            name: "Quy\u0300nh L\u00f4i",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "541", name: "Quy\u0300nh Mai", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "542",
            name: "Thanh L\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "543", name: "Thanh Nha\u0300n", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "544",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "545", name: "Vi\u0303nh Tuy", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "8137", name: "8/3", prefix: "Ph\u1ed1" },
          { id: "8138", name: "Ao D\u00e0i", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8139", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "8140",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8141", name: "B\u1ea1ch Mai", prefix: "Ph\u1ed1" },
          {
            id: "8142",
            name: "B\u00f9i Ng\u1ecdc D\u01b0\u01a1ng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "8143",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8144", name: "C\u1ea3m H\u1ed9i", prefix: "Ph\u1ed1" },
          {
            id: "8145",
            name: "Cao \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8146",
            name: "C\u1ea7u L\u1ea1c Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8147",
            name: "C\u1ea7u V\u0129nh Tuy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8148",
            name: "Ch\u00f9a Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8149", name: "Ch\u00f9a Vua", prefix: "Ph\u1ed1" },
          {
            id: "8159",
            name: "D\u1ed1c Th\u1ecd L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8160",
            name: "D\u1ed1c V\u1ea1n Ki\u1ebfp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8164",
            name: "D\u01b0\u01a1ng V\u0103n B\u00e9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8150",
            name: "\u0110\u1ea1i C\u1ed3 Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8151", name: "\u0110\u1ea1i La", prefix: "Ph\u1ed1" },
          {
            id: "8152",
            name: "\u0110\u1ea7m Tr\u1ea5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8153",
            name: "\u0110\u00ea T\u00f4 Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8154",
            name: "\u0110\u00ea Tr\u1ea7n Kh\u00e1t Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8155",
            name: "\u0110\u1ed7 H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8156",
            name: "\u0110\u1ed7 Ng\u1ecdc Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8157",
            name: "\u0110\u1ed7 Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8158",
            name: "\u0110o\u00e0n Tr\u1ea7n Nghi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8161",
            name: "\u0110\u1ed9i Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8162", name: "\u0110\u00f4ng M\u00e1c", prefix: "Ph\u1ed1" },
          { id: "8163", name: "\u0110\u1ed3ng Nh\u00e2n", prefix: "Ph\u1ed1" },
          {
            id: "8165",
            name: "Gi\u1ea3i Ph\u00f3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8166",
            name: "Gi\u1ebfng M\u1ee9t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8167",
            name: "G\u1ed1c \u0110\u1ec1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8168", name: "H\u00e0m Long", prefix: "Ph\u1ed1" },
          {
            id: "8169",
            name: "H\u00e0n Thuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8170", name: "H\u00e0ng B\u00e0i", prefix: "Ph\u1ed1" },
          { id: "8171", name: "H\u00e0ng Chu\u1ed1i", prefix: "Ph\u1ed1" },
          {
            id: "8172",
            name: "H\u1ed3 Ba M\u1eabu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8173",
            name: "H\u1ed3 \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8174",
            name: "H\u1ed3 \u0110\u00ecnh B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8175",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8176",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8177",
            name: "H\u00f2a B\u00ecnh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8178", name: "Ho\u00e0 B\u00ecnh 4", prefix: "Ph\u1ed1" },
          { id: "8179", name: "Ho\u00e0 B\u00ecnh 6", prefix: "Ph\u1ed1" },
          {
            id: "8180",
            name: "Ho\u00e0 B\u00ecnh 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8181", name: "Hoa L\u01b0", prefix: "Ph\u1ed1" },
          { id: "8182", name: "H\u00f2a M\u00e3", prefix: "Ph\u1ed1" },
          {
            id: "8183",
            name: "Ho\u00e0ng Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8184", name: "H\u1ed3ng Mai", prefix: "Ph\u1ed1" },
          { id: "8185", name: "H\u01b0\u01a1ng Vi\u00ean", prefix: "Ph\u1ed1" },
          {
            id: "8186",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8187", name: "Kim Ng\u01b0u", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8188",
            name: "L\u1ea1c Nghi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8189", name: "L\u1ea1c Trung", prefix: "Ph\u1ed1" },
          {
            id: "8190",
            name: "L\u00e3ng Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8192",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8191",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8193",
            name: "L\u00ea Gia \u0110\u1ecbnh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "8194",
            name: "L\u00ea Ng\u1ecdc H\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8195",
            name: "L\u00ea Ph\u1ee5ng Hi\u1ec3u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "8196",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8197", name: "L\u00ea Thanh Ngh\u1ecb", prefix: "Ph\u1ed1" },
          { id: "8198", name: "L\u00ea V\u0103n H\u01b0u", prefix: "Ph\u1ed1" },
          { id: "8199", name: "L\u00f2 \u0110\u00fac", prefix: "Ph\u1ed1" },
          {
            id: "8200",
            name: "L\u01b0\u01a1ng Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8201",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "8202",
            name: "M\u1ea1c Th\u1ecb B\u01b0\u1edfi",
            prefix: "Ph\u1ed1",
          },
          {
            id: "8203",
            name: "Mai \u0110\u1ed9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8204", name: "Mai H\u1eafc \u0110\u1ebf", prefix: "Ph\u1ed1" },
          { id: "8205", name: "Mai H\u01b0\u01a1ng", prefix: "Ph\u1ed1" },
          { id: "8206", name: "Minh Khai", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8207", name: "Nam D\u01b0", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8208",
            name: "Ng\u00f5 Hu\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8209",
            name: "Ng\u00f5 Qu\u1ef3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8210",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8211",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8212",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8213",
            name: "Nguy\u1ec5n Cao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8214",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8216",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8215",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8217",
            name: "Nguy\u1ec5n Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8218",
            name: "Nguy\u1ec5n H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8219", name: "Nguy\u1ec5n Huy T\u1ef1", prefix: "Ph\u1ed1" },
          {
            id: "8220",
            name: "Nguy\u1ec5n Kho\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8221",
            name: "Nguy\u1ec5n Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8222",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8223",
            name: "Nguy\u1ec5n Trung Ng\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8224",
            name: "Ph\u1ea1m \u0110\u00ecnh H\u1ed5",
            prefix: "Ph\u1ed1",
          },
          {
            id: "8225",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8226",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8227",
            name: "Phan Huy Ch\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8228", name: "Ph\u1ed1 Hu\u1ebf", prefix: "Ph\u1ed1" },
          { id: "8229", name: "Ph\u1ed1 V\u1ecdng", prefix: "Ph\u1ed1" },
          {
            id: "8230",
            name: "Ph\u00f9 \u0110\u1ed5ng Thi\u00ean V\u01b0\u01a1ng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "8231",
            name: "Ph\u00f9ng Kh\u1eafc Khoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8232", name: "Ph\u01b0\u01a1ng Mai", prefix: "Ph\u1ed1" },
          { id: "8233", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8234",
            name: "Qu\u1ef3nh L\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8235", name: "Qu\u1ef3nh Mai", prefix: "Ph\u1ed1" },
          { id: "8236", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8237",
            name: "S\u00f4ng L\u1ea5p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8238", name: "T\u1ea1 Quang B\u1eedu", prefix: "Ph\u1ed1" },
          { id: "8239", name: "Tam Trinh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8240",
            name: "T\u00e2n L\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8241",
            name: "T\u00e2n L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8242",
            name: "T\u0103ng B\u1ea1t H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8243",
            name: "T\u00e2y K\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8244",
            name: "Th\u00e1i Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8245",
            name: "Thanh L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8246",
            name: "Thanh Nh\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8247", name: "Th\u1ec3 Giao", prefix: "Ph\u1ed1" },
          { id: "8248", name: "Thi S\u00e1ch", prefix: "Ph\u1ed1" },
          { id: "8249", name: "Thi\u1ec1n Quang", prefix: "Ph\u1ed1" },
          { id: "8250", name: "Th\u1ecbnh Y\u00ean", prefix: "Ph\u1ed1" },
          {
            id: "8251",
            name: "Th\u1ecd L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8252",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8253",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8254",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8255",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "8256",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8257",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8258",
            name: "Tr\u1ea7n Kh\u00e1t Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8259",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8260",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8261",
            name: "Tr\u1ea7n Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8262",
            name: "Tr\u1ea7n Xu\u00e2n So\u1ea1n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "8263",
            name: "Tri\u1ec7u Vi\u1ec7t V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8264",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8265",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8266", name: "T\u01b0\u01a1ng Mai", prefix: "Ph\u1ed1" },
          {
            id: "8267",
            name: "V\u00e2n \u0110\u1ed3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8268",
            name: "V\u00e2n H\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8269",
            name: "V\u00e2n H\u1ed3 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8270",
            name: "V\u00e2n H\u1ed3 I",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8271",
            name: "V\u00e2n H\u1ed3 III",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8272", name: "V\u1ea1n Ki\u1ebfp", prefix: "Ph\u1ed1" },
          { id: "8273", name: "V\u0129nh Tuy", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8274",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8275", name: "V\u0169 H\u1eefu L\u1ee3i", prefix: "Ph\u1ed1" },
          { id: "8276", name: "Y.E.C Xanh", prefix: "Ph\u1ed1" },
          {
            id: "8277",
            name: "Y\u00ean B\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8278", name: "Y\u00ean B\u00e1i 1", prefix: "Ph\u1ed1" },
          { id: "8279", name: "Y\u00ean B\u00e1i 2", prefix: "Ph\u1ed1" },
          { id: "8280", name: "Y\u00ean L\u1ea1c", prefix: "Ph\u1ed1" },
          {
            id: "8281",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1533",
            name: "10 Hoa L\u01b0",
            lat: "21.009401321411",
            lng: "105.84746551514",
          },
          {
            id: "1534",
            name: "141 Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            lat: "20.991165161133",
            lng: "105.84956359863",
          },
          {
            id: "1535",
            name: "229 Ph\u1ed1 V\u1ecdng",
            lat: "20.995378494263",
            lng: "105.84432220459",
          },
          {
            id: "1536",
            name: "25 L\u1ea1c Trung",
            lat: "21.002326965332",
            lng: "105.86479949951",
          },
          {
            id: "1537",
            name: "310 Minh Khai",
            lat: "20.993867874146",
            lng: "105.86184692383",
          },
          {
            id: "1538",
            name: "93 L\u00f2 \u0110\u00fac - Kinh \u0110\u00f4 Tower",
            lat: "21.015636444092",
            lng: "105.85718536377",
          },
          {
            id: "1539",
            name: "CDC Building",
            lat: "21.010469436646",
            lng: "105.84915924072",
          },
          {
            id: "1540",
            name: "Chung c\u01b0 122 V\u0129nh Tuy",
            lat: "20.999267578125",
            lng: "105.87572479248",
          },
          {
            id: "1541",
            name: "Chung c\u01b0 536A Minh Khai",
            lat: "20.998477935791",
            lng: "105.86943054199",
          },
          {
            id: "1542",
            name: "Chung c\u01b0 622 Minh Khai",
            lat: "20.998010635376",
            lng: "105.86836242676",
          },
          {
            id: "1543",
            name: "Coma Building",
            lat: "20.996467590332",
            lng: "105.85452270508",
          },
          {
            id: "1544",
            name: "Crystal Tower",
            lat: "21.01051902771",
            lng: "105.8508682251",
          },
          {
            id: "1545",
            name: "Gelex Tower",
            lat: "21.012348175049",
            lng: "105.84816741943",
          },
          {
            id: "1546",
            name: "Grand Building",
            lat: "21.015350341797",
            lng: "105.85402679443",
          },
          {
            id: "1547",
            name: "Green Park",
            lat: "21.014717102051",
            lng: "105.84711456299",
          },
          {
            id: "1548",
            name: "Green Pearl 378 Minh Khai",
            lat: "20.996538162231",
            lng: "105.86338806152",
          },
          {
            id: "1549",
            name: "H\u00e0n Vi\u1ec7t Tower",
            lat: "20.997060775757",
            lng: "105.86157226562",
          },
          {
            id: "1550",
            name: "HDI Tower",
            lat: "21.010473251343",
            lng: "105.84925842285",
          },
          {
            id: "1551",
            name: "Hinode City",
            lat: "20.995994567871",
            lng: "105.86126708984",
          },
          {
            id: "1552",
            name: "H\u00f2a B\u00ecnh Green City",
            lat: "21.00026512146",
            lng: "105.86993408203",
          },
          {
            id: "1553",
            name: "Ho\u00e0ng Th\u00e0nh Tower",
            lat: "21.011362075806",
            lng: "105.85061645508",
          },
          {
            id: "1554",
            name: "Imperia Sky Garden",
            lat: "20.99843788147",
            lng: "105.86673736572",
          },
          {
            id: "1555",
            name: "Li\u1ec1n k\u1ec1 622 Minh Khai",
            lat: "20.999074935913",
            lng: "105.87060546875",
          },
          {
            id: "1556",
            name: "Lilama 124 Minh Khai",
            lat: "20.99471282959",
            lng: "105.85306549072",
          },
          {
            id: "1557",
            name: "Nh\u00e0 ph\u1ed1 li\u1ec1n k\u1ec1 124 V\u0129nh Tuy",
            lat: "21.001955032349",
            lng: "105.87343597412",
          },
          {
            id: "1558",
            name: "Park 12 Park Hill - Times City",
            lat: "20.996578216553",
            lng: "105.86519622803",
          },
          {
            id: "1559",
            name: "Prime Centre",
            lat: "21.018707275391",
            lng: "105.84769439697",
          },
          {
            id: "1560",
            name: "Ruby Plaza",
            lat: "21.016345977783",
            lng: "105.85534667969",
          },
          {
            id: "1561",
            name: "Sky Light",
            lat: "20.996833801269",
            lng: "105.85549926758",
          },
          {
            id: "1562",
            name: "Sun Grand City Ancora Residence",
            lat: "21.011674880981",
            lng: "105.86267852783",
          },
          {
            id: "1563",
            name: "Sunshine Garden",
            lat: "20.992605209351",
            lng: "105.87033081055",
          },
          {
            id: "1564",
            name: "T\u00e0i T\u00e2m",
            lat: "21.013353347778",
            lng: "105.85785675049",
          },
          {
            id: "1565",
            name: "Th\u0103ng Long Garden 250 Minh Khai",
            lat: "20.993942260742",
            lng: "105.85710906982",
          },
          {
            id: "1566",
            name: "Times City",
            lat: "20.994791030884",
            lng: "105.86784362793",
          },
          {
            id: "1567",
            name: "Times City - Park Hill",
            lat: "20.994333267212",
            lng: "105.86756134033",
          },
          {
            id: "1568",
            name: "TMC Th\u0103ng Long - 01 L\u01b0\u01a1ng Y\u00ean",
            lat: "21.012016296387",
            lng: "105.86291503906",
          },
          {
            id: "1569",
            name: "T\u00f2a nh\u00e0 Cty CPXD s\u1ed1 1 HN",
            lat: "21.017902374268",
            lng: "105.84747314453",
          },
          {
            id: "1570",
            name: "T\u00f2a nh\u00e0 Th\u00e1i B\u00ecnh",
            lat: "21.009044647217",
            lng: "105.85118103027",
          },
          {
            id: "1571",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh Complex",
            lat: "20.992298126221",
            lng: "105.84944915772",
          },
          {
            id: "1572",
            name: "TTTM Ch\u1ee3 M\u01a1",
            lat: "20.996904373169",
            lng: "105.85028839111",
          },
          {
            id: "1573",
            name: "V Building",
            lat: "21.019178390503",
            lng: "105.8494644165",
          },
          {
            id: "1574",
            name: "Vinafor",
            lat: "21.013250350952",
            lng: "105.85842895508",
          },
          {
            id: "1575",
            name: "Vincom Center B\u00e0 Tri\u1ec7u",
            lat: "21.011552810669",
            lng: "105.84925842285",
          },
          {
            id: "1576",
            name: "VTC Online building",
            lat: "20.995391845703",
            lng: "105.86201477051",
          },
          {
            id: "1577",
            name: "Zodi Building",
            lat: "21.013252258301",
            lng: "105.85040283203",
          },
        ],
      },
      {
        id: "36",
        name: "Ho\u00e0i \u0110\u1ee9c",
        wards: [
          { id: "546", name: "An Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "547", name: "An Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "548", name: "C\u00e1t Qu\u1ebf", prefix: "X\u00e3" },
          { id: "550", name: "Di Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "554", name: "D\u01b0\u01a1ng Li\u1ec5u", prefix: "X\u00e3" },
          { id: "549", name: "\u0110\u1eafc S\u1edf", prefix: "X\u00e3" },
          { id: "551", name: "\u0110\u00f4ng La", prefix: "X\u00e3" },
          { id: "552", name: "\u0110\u1ee9c Giang", prefix: "X\u00e3" },
          {
            id: "553",
            name: "\u0110\u1ee9c Th\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
          { id: "555", name: "Kim Chung", prefix: "X\u00e3" },
          { id: "556", name: "La Ph\u00f9", prefix: "X\u00e3" },
          { id: "557", name: "L\u1ea1i Y\u00ean", prefix: "X\u00e3" },
          { id: "558", name: "Minh Khai", prefix: "X\u00e3" },
          { id: "559", name: "S\u01a1n \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "560", name: "Song Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "561", name: "Ti\u1ec1n Y\u00ean", prefix: "X\u00e3" },
          {
            id: "562",
            name: "Tr\u1ea1m Tr\u00f4i",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "563", name: "V\u00e2n Canh", prefix: "X\u00e3" },
          { id: "564", name: "V\u00e2n C\u00f4n", prefix: "X\u00e3" },
          { id: "565", name: "Y\u00ean S\u1edf", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "8282", name: "32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8283", name: "422", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8284", name: "422A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8285", name: "42A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8286", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8287", name: "70", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8288", name: "70A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8289", name: "72", prefix: "Ph\u1ed1" },
          { id: "8290", name: "79", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8291", name: "An Trai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8292",
            name: "\u0110\u1ea1i l\u1ed9 Th\u0103ng Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8293",
            name: "\u0110\u1ed3ng L\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8294", name: "\u0110\u1ed3ng Nh\u00e2n", prefix: "Ph\u1ed1" },
          { id: "8295", name: "La Tinh", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8296", name: "Lai X\u00e1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8297",
            name: "L\u00e1ng H\u00f2a L\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8298",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "8299",
            name: "L\u00ea V\u0103n L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8300",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8301",
            name: "Long C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8302",
            name: "Long C\u1ea3nh \u0110\u00f4ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8303",
            name: "Long C\u1ea3nh T\u00e2y 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8304",
            name: "Long H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8305",
            name: "Long H\u01b0ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8306",
            name: "Long Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8307",
            name: "Long Kh\u00e1nh 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8308",
            name: "Long Kh\u00e1nh 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8309",
            name: "Long Kh\u00e1nh 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8310", name: "Long Ph\u00fa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8311",
            name: "Long Ph\u00fa 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8312",
            name: "Qu\u1ed1c l\u1ed9 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8313",
            name: "Qu\u1ed1c l\u1ed9 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8314",
            name: "Th\u1ee5y \u1ee8ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8315",
            name: "T\u1ec9nh l\u1ed9 422",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8316",
            name: "T\u1ec9nh L\u1ed9 422B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8317",
            name: "T\u1ec9nh L\u1ed9 442",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8318",
            name: "T\u1ec9nh l\u1ed9 72",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8319",
            name: "Tr\u1ea7n H\u1eefu D\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8320", name: "V\u00e2n Canh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8321",
            name: "V\u00e2n L\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8322",
            name: "V\u00e0nh \u0110ai 3.5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8323",
            name: "V\u00e0nh \u0110ai 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8324",
            name: "Y\u00ean V\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1578",
            name: "An Th\u1ecbnh I",
            lat: "21.064477920532",
            lng: "105.68864440918",
          },
          {
            id: "1579",
            name: "B\u1ea3o S\u01a1n Paradise",
            lat: "21.001749038696",
            lng: "105.73071289062",
          },
          {
            id: "1580",
            name: "Bright City",
            lat: "21.062879562378",
            lng: "105.7222366333",
          },
          {
            id: "1581",
            name: "Ciri An Kh\u00e1nh",
            lat: "21.018384933472",
            lng: "105.70275878906",
          },
          {
            id: "1582",
            name: "CT Number One",
            lat: "21.042730331421",
            lng: "105.73021697998",
          },
          {
            id: "1583",
            name: "D\u1ea7u kh\u00ed \u0110\u1ee9c Giang",
            lat: "21.058673858643",
            lng: "105.70918273926",
          },
          {
            id: "1584",
            name: "Five Stars",
            lat: "21.017467498779",
            lng: "105.72721862793",
          },
          {
            id: "1585",
            name: "Gemek Premium",
            lat: "21.002981185913",
            lng: "105.73205566406",
          },
          {
            id: "1586",
            name: "Gemek Tower",
            lat: "21.008392333984",
            lng: "105.73024749756",
          },
          {
            id: "1587",
            name: "Hoa Ph\u01b0\u1ee3ng",
            lat: "20.998634338379",
            lng: "105.73297119141",
          },
          {
            id: "1588",
            name: "K\u0110T An Kh\u00e1nh - An Th\u01b0\u1ee3ng",
            lat: "20.983531951904",
            lng: "105.70503234863",
          },
          {
            id: "1589",
            name: "Khu \u0111\u00f4 th\u1ecb \u0110\u1ea1i h\u1ecdc V\u00e2n Canh",
            lat: "21.033149719238",
            lng: "105.73218536377",
          },
          {
            id: "1590",
            name: "Kim Chung Di Tr\u1ea1ch",
            lat: "21.056106567383",
            lng: "105.71633148193",
          },
          {
            id: "1591",
            name: "Lideco",
            lat: "21.068561553955",
            lng: "105.71018218994",
          },
          {
            id: "1592",
            name: "Nam An Kh\u00e1nh",
            lat: "21.002010345459",
            lng: "105.70750427246",
          },
          {
            id: "1593",
            name: "S\u01a1n \u0110\u1ed3ng",
            lat: "21.043411254883",
            lng: "105.70864868164",
          },
          {
            id: "1594",
            name: "Splendora An Kh\u00e1nh",
            lat: "21.015106201172",
            lng: "105.72484588623",
          },
          {
            id: "1595",
            name: "T\u00e2n Vi\u1ec7t Tower",
            lat: "21.077608108521",
            lng: "105.69110870361",
          },
          {
            id: "1596",
            name: "T\u00e2y \u0110\u00f4",
            lat: "21.039226531982",
            lng: "105.66764831543",
          },
          {
            id: "1597",
            name: "Th\u0103ng Long Victory",
            lat: "21.009687423706",
            lng: "105.72159576416",
          },
          {
            id: "1598",
            name: "The Golden An Kh\u00e1nh",
            lat: "21.001188278198",
            lng: "105.72555541992",
          },
          {
            id: "1599",
            name: "The Golden An Kh\u00e1nh 32T",
            lat: "21.004850387573",
            lng: "105.72810363769",
          },
          {
            id: "1600",
            name: "Tricon Towers",
            lat: "21.014390945435",
            lng: "105.72463226318",
          },
          {
            id: "1601",
            name: "V\u00e2n Canh",
            lat: "21.041305541992",
            lng: "105.73207092285",
          },
          {
            id: "1602",
            name: "Vinhomes Th\u0103ng Long",
            lat: "20.998750686646",
            lng: "105.71778869629",
          },
          {
            id: "1603",
            name: "V\u01b0\u1eddn Cam Vinapol",
            lat: "21.029722213745",
            lng: "105.72012329102",
          },
          {
            id: "1604",
            name: "Westpoint - Nam \u0111\u01b0\u1eddng 32",
            lat: "21.069169998169",
            lng: "105.70370483398",
          },
        ],
      },
      {
        id: "37",
        name: "Ho\u00e0n Ki\u1ebfm",
        wards: [
          {
            id: "566",
            name: "Ch\u01b0\u01a1ng D\u01b0\u01a1ng \u0110\u00f4\u0323",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "567",
            name: "C\u01b0\u0309a \u0110\u00f4ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "568", name: "C\u01b0\u0309a Nam", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "569",
            name: "\u0110\u00f4\u0300ng Xu\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "570",
            name: "Ha\u0300ng Ba\u0323c",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "571",
            name: "Ha\u0300ng Ba\u0300i",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "572",
            name: "Ha\u0300ng B\u00f4\u0300",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "573",
            name: "Ha\u0300ng B\u00f4ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "574",
            name: "Ha\u0300ng Bu\u00f4\u0300m",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "575",
            name: "Ha\u0300ng \u0110a\u0300o",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "576", name: "Ha\u0300ng Gai", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "577",
            name: "Ha\u0300ng Ma\u0303",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "578",
            name: "Ha\u0300ng Tr\u00f4\u0301ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "579",
            name: "Ly\u0301 Tha\u0301i T\u00f4\u0309",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "580", name: "Phan Chu Trinh", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "581",
            name: "Phu\u0301c T\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "582",
            name: "Tr\u00e2\u0300n H\u01b0ng \u0110a\u0323o",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "583",
            name: "Tra\u0300ng Ti\u00ea\u0300n",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "8325", name: "19/12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8326", name: "45", prefix: "Ph\u1ed1" },
          { id: "8327", name: "An X\u00e1", prefix: "Ph\u1ed1" },
          { id: "8328", name: "\u1ea4u Tri\u1ec7u", prefix: "Ph\u1ed1" },
          { id: "8329", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "8330",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8331", name: "B\u1ea3o Kh\u00e1nh", prefix: "Ph\u1ed1" },
          { id: "8332", name: "B\u1ea3o Linh", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8333", name: "B\u00e1t \u0110\u00e0n", prefix: "Ph\u1ed1" },
          { id: "8334", name: "B\u00e1t S\u1ee9", prefix: "Ph\u1ed1" },
          {
            id: "8335",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8336", name: "C\u1ea5m Ch\u1ec9", prefix: "Ph\u1ed1" },
          {
            id: "8337",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8338",
            name: "C\u1ea7u Ch\u01b0\u01a1ng D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8339",
            name: "C\u1ea7u \u0110\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8340", name: "C\u1ea7u \u0110\u00f4ng", prefix: "Ph\u1ed1" },
          { id: "8341", name: "C\u1ea7u G\u1ed7", prefix: "Ph\u1ed1" },
          {
            id: "8342",
            name: "C\u1ea7u Long Bi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8343",
            name: "C\u1ea7u Th\u00ea H\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8344", name: "Ch\u1ea3 C\u00e1", prefix: "Ph\u1ed1" },
          { id: "8345", name: "Ch\u00e2n C\u1ea7m", prefix: "Ph\u1ed1" },
          {
            id: "8346",
            name: "Chi\u1ebfn Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8347", name: "Ch\u1ee3 G\u1ea1o", prefix: "Ph\u1ed1" },
          {
            id: "8348",
            name: "Ch\u01b0\u01a1ng D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8349",
            name: "Ch\u01b0\u01a1ng D\u01b0\u01a1ng \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8350", name: "C\u1ed5 T\u00e2n", prefix: "Ph\u1ed1" },
          {
            id: "8351",
            name: "C\u1ed5ng \u0110\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8352",
            name: "C\u00f4ng Nghi\u1ec7p 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8353",
            name: "C\u1eeda B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8354", name: "C\u1eeda \u0110\u00f4ng", prefix: "Ph\u1ed1" },
          { id: "8355", name: "C\u1eeda Nam", prefix: "Ph\u1ed1" },
          {
            id: "8356",
            name: "D\u00e3 T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8357", name: "Dal", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8358", name: "Dal 22/12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8359", name: "Dal 27/7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8360",
            name: "\u0110\u1eb7ng Th\u00e1i Th\u00e2n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "8361",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8362",
            name: "\u0110i\u1ec7n Bi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8363",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8364",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8365",
            name: "\u0110inh L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8366", name: "\u0110inh Li\u1ec7t", prefix: "Ph\u1ed1" },
          { id: "8367", name: "\u0110\u00ecnh Ngang", prefix: "Ph\u1ed1" },
          {
            id: "8368",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8369",
            name: "\u0110o\u00e0n Nh\u1eef H\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8370",
            name: "\u0110\u00f4ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8371", name: "\u0110\u1ed3ng Xu\u00e2n", prefix: "Ph\u1ed1" },
          {
            id: "8372",
            name: "\u0110\u01b0\u1eddng Th\u00e0nh",
            prefix: "Ph\u1ed1",
          },
          { id: "8373", name: "G\u1ea7m C\u1ea7u", prefix: "Ph\u1ed1" },
          { id: "8374", name: "Gia Ng\u01b0", prefix: "Ph\u1ed1" },
          {
            id: "8375",
            name: "H\u1ea1 H\u1ed3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8376", name: "H\u00e0 Trung", prefix: "Ph\u1ed1" },
          { id: "8377", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          { id: "8378", name: "H\u00e0m Long", prefix: "Ph\u1ed1" },
          {
            id: "8379",
            name: "H\u00e0m T\u1eed Quan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8380", name: "H\u00e0ng B\u1ea1c", prefix: "Ph\u1ed1" },
          { id: "8381", name: "H\u00e0ng B\u00e0i", prefix: "Ph\u1ed1" },
          { id: "8382", name: "H\u00e0ng B\u00e8", prefix: "Ph\u1ed1" },
          { id: "8383", name: "H\u00e0ng B\u1ed3", prefix: "Ph\u1ed1" },
          { id: "8384", name: "H\u00e0ng B\u00f4ng", prefix: "Ph\u1ed1" },
          { id: "8385", name: "H\u00e0ng Bu\u1ed3m", prefix: "Ph\u1ed1" },
          { id: "8386", name: "H\u00e0ng B\u00fat", prefix: "Ph\u1ed1" },
          { id: "8387", name: "H\u00e0ng C\u00e1", prefix: "Ph\u1ed1" },
          { id: "8388", name: "H\u00e0ng C\u00e2n", prefix: "Ph\u1ed1" },
          { id: "8389", name: "H\u00e0ng Chai", prefix: "Ph\u1ed1" },
          {
            id: "8390",
            name: "H\u00e0ng Ch\u1ec9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8391", name: "H\u00e0ng Chi\u1ebfu", prefix: "Ph\u1ed1" },
          { id: "8392", name: "H\u00e0ng Ch\u0129nh", prefix: "Ph\u1ed1" },
          { id: "8393", name: "H\u00e0ng Chu\u1ed1i", prefix: "Ph\u1ed1" },
          { id: "8394", name: "H\u00e0ng C\u00f3t", prefix: "Ph\u1ed1" },
          { id: "8395", name: "H\u00e0ng Da", prefix: "Ph\u1ed1" },
          { id: "8397", name: "H\u00e0ng D\u1ea7u", prefix: "Ph\u1ed1" },
          { id: "8396", name: "H\u00e0ng \u0110\u00e0o", prefix: "Ph\u1ed1" },
          {
            id: "8398",
            name: "H\u00e0ng \u0110\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8399", name: "H\u00e0ng \u0110i\u1ebfu", prefix: "Ph\u1ed1" },
          { id: "8400", name: "H\u00e0ng \u0110\u1ed3ng", prefix: "Ph\u1ed1" },
          {
            id: "8401",
            name: "H\u00e0ng \u0110\u01b0\u1eddng",
            prefix: "Ph\u1ed1",
          },
          { id: "8402", name: "H\u00e0ng G\u00e0", prefix: "Ph\u1ed1" },
          { id: "8403", name: "H\u00e0ng Gai", prefix: "Ph\u1ed1" },
          { id: "8404", name: "H\u00e0ng Gi\u1ea5y", prefix: "Ph\u1ed1" },
          {
            id: "8405",
            name: "H\u00e0ng Gi\u1ea7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8406",
            name: "H\u00e0ng H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8407", name: "H\u00e0ng H\u00f2m", prefix: "Ph\u1ed1" },
          { id: "8408", name: "H\u00e0ng H\u01b0\u01a1ng", prefix: "Ph\u1ed1" },
          { id: "8409", name: "H\u00e0ng Khay", prefix: "Ph\u1ed1" },
          { id: "8410", name: "H\u00e0ng Khoai", prefix: "Ph\u1ed1" },
          { id: "8411", name: "H\u00e0ng L\u01b0\u1ee3c", prefix: "Ph\u1ed1" },
          { id: "8412", name: "H\u00e0ng M\u00e3", prefix: "Ph\u1ed1" },
          { id: "8413", name: "H\u00e0ng M\u1eafm", prefix: "Ph\u1ed1" },
          { id: "8414", name: "H\u00e0ng M\u00e0nh", prefix: "Ph\u1ed1" },
          { id: "8415", name: "H\u00e0ng Mu\u1ed1i", prefix: "Ph\u1ed1" },
          { id: "8416", name: "H\u00e0ng Ngang", prefix: "Ph\u1ed1" },
          { id: "8417", name: "H\u00e0ng N\u00f3n", prefix: "Ph\u1ed1" },
          { id: "8418", name: "H\u00e0ng Ph\u00e8n", prefix: "Ph\u1ed1" },
          { id: "8419", name: "H\u00e0ng Qu\u1ea1t", prefix: "Ph\u1ed1" },
          { id: "8420", name: "H\u00e0ng R\u01b0\u01a1i", prefix: "Ph\u1ed1" },
          {
            id: "8421",
            name: "H\u00e0ng Than",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8422", name: "H\u00e0ng Thi\u1ebfc", prefix: "Ph\u1ed1" },
          { id: "8423", name: "H\u00e0ng Th\u00f9ng", prefix: "Ph\u1ed1" },
          { id: "8424", name: "H\u00e0ng Tre", prefix: "Ph\u1ed1" },
          { id: "8425", name: "H\u00e0ng Tr\u1ed1ng", prefix: "Ph\u1ed1" },
          { id: "8426", name: "H\u00e0ng V\u1ea3i", prefix: "Ph\u1ed1" },
          { id: "8427", name: "H\u00e0ng V\u00f4i", prefix: "Ph\u1ed1" },
          {
            id: "8428",
            name: "H\u1ed3 Ho\u00e0n Ki\u1ebfm",
            prefix: "Ph\u1ed1",
          },
          { id: "8429", name: "H\u1ecfa L\u00f2", prefix: "Ph\u1ed1" },
          { id: "8430", name: "H\u1ed9i V\u0169", prefix: "Ph\u1ed1" },
          {
            id: "8431",
            name: "H\u1ed3ng H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8432", name: "H\u1ed3ng Ph\u00fac", prefix: "Ph\u1ed1" },
          { id: "8433", name: "L\u00e3n \u00d4ng", prefix: "Ph\u1ed1" },
          {
            id: "8434",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8435", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8436",
            name: "L\u00ea Ph\u1ee5ng Hi\u1ec3u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "8437",
            name: "L\u00ea Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8438",
            name: "L\u00ea Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8439",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8440", name: "L\u00ea V\u0103n H\u01b0u", prefix: "Ph\u1ed1" },
          {
            id: "8441",
            name: "L\u00ea V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8442", name: "Li\u00ean Tr\u00ec", prefix: "Ph\u1ed1" },
          { id: "8443", name: "L\u00f2 \u0110\u00fac", prefix: "Ph\u1ed1" },
          { id: "8444", name: "L\u00f2 R\u00e8n", prefix: "Ph\u1ed1" },
          { id: "8445", name: "L\u00f2 S\u0169", prefix: "Ph\u1ed1" },
          {
            id: "8446",
            name: "Long Bi\u00ean 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8447",
            name: "L\u01b0\u01a1ng Ng\u1ecdc Quy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8448",
            name: "L\u01b0\u01a1ng V\u0103n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8449",
            name: "L\u00fd \u0110\u1ea1o Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8450",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8451", name: "L\u00fd Qu\u1ed1c S\u01b0", prefix: "Ph\u1ed1" },
          {
            id: "8452",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8453",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          { id: "8454", name: "M\u00e3 M\u00e2y", prefix: "Ph\u1ed1" },
          { id: "8455", name: "Nam Ng\u01b0", prefix: "Ph\u1ed1" },
          {
            id: "8456",
            name: "Ng\u00f5 B\u1ea3o Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8457", name: "Ng\u00f5 G\u1ea1ch", prefix: "Ph\u1ed1" },
          {
            id: "8458",
            name: "Ng\u00f5 Huy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8459",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8460",
            name: "Ng\u00f5 Qu\u1ef3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8461",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8462", name: "Ng\u00f5 Tr\u1ea1m", prefix: "Ph\u1ed1" },
          {
            id: "8463",
            name: "Ng\u00f5 Tr\u1ea1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8464",
            name: "Ng\u00f4 V\u0103n S\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8465",
            name: "Nguy\u1ec5n Ch\u1ebf Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8466",
            name: "Nguy\u1ec5n C\u00f4ng Hoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8467",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8468",
            name: "Nguy\u1ec5n Gia Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8469",
            name: "Nguy\u1ec5n H\u1eefu Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8470",
            name: "Nguy\u1ec5n Kh\u1eafc C\u1ea7n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "8471",
            name: "Nguy\u00ean Khi\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8472",
            name: "Nguy\u1ec5n Quang B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8473",
            name: "Nguy\u1ec5n Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8474", name: "Nguy\u1ec5n Si\u00eau", prefix: "Ph\u1ed1" },
          {
            id: "8475",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8476",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "8477",
            name: "Nguy\u1ec5n Thi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8478",
            name: "Nguy\u1ec5n T\u01b0 Gi\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8479",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8480",
            name: "Nguy\u1ec5n V\u0103n T\u1ed1",
            prefix: "Ph\u1ed1",
          },
          { id: "8481", name: "Nguy\u1ec5n X\u00ed", prefix: "Ph\u1ed1" },
          { id: "8482", name: "Nh\u00e0 Chung", prefix: "Ph\u1ed1" },
          { id: "8483", name: "Nh\u00e0 H\u1ecfa", prefix: "Ph\u1ed1" },
          { id: "8484", name: "Nh\u00e0 Th\u1edd", prefix: "Ph\u1ed1" },
          {
            id: "8485",
            name: "\u00d4 Quan Ch\u01b0\u1edfng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "8486",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8487",
            name: "Ph\u1ea1m S\u01b0 M\u1ea1nh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "8488",
            name: "Ph\u1ea1m V\u0103n S\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8489",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8490",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8491",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8492",
            name: "Phan Huy Ch\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8493",
            name: "Ph\u00e1t L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8494", name: "Ph\u1ed1 Hu\u1ebf", prefix: "Ph\u1ed1" },
          { id: "8495", name: "Ph\u1ee7 Do\u00e3n", prefix: "Ph\u1ed1" },
          { id: "8496", name: "Ph\u00f9 Dung", prefix: "Ph\u1ed1" },
          {
            id: "8497",
            name: "Ph\u00fac Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8498",
            name: "Ph\u00fac T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8499", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          { id: "8500", name: "Qu\u00e1n S\u1ee9", prefix: "Ph\u1ed1" },
          { id: "8501", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8502",
            name: "T\u1ea1 Hi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8503", name: "T\u1ea1m Th\u01b0\u01a1ng", prefix: "Ph\u1ed1" },
          {
            id: "8504",
            name: "T\u00e2n Trang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8505", name: "Th\u00e0nh", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8506", name: "Thanh H\u00e0", prefix: "Ph\u1ed1" },
          {
            id: "8507",
            name: "Thanh Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8508",
            name: "Thi\u00ean Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8509", name: "Th\u1ee3 Nhu\u1ed9m", prefix: "Ph\u1ed1" },
          {
            id: "8510",
            name: "Th\u1ecd X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8511", name: "Thu\u1ed1c B\u1eafc", prefix: "Ph\u1ed1" },
          { id: "8512", name: "T\u00f4 T\u1ecbch", prefix: "Ph\u1ed1" },
          { id: "8513", name: "T\u1ed1 T\u1ecbch", prefix: "Ph\u1ed1" },
          {
            id: "8514",
            name: "T\u00f4n \u0110\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8516",
            name: "T\u1ed1ng Duy T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8515", name: "T\u00f4ng \u0110\u1ea3n", prefix: "Ph\u1ed1" },
          { id: "8517", name: "Tr\u1ea7n B\u00ecnh", prefix: "Ph\u1ed1" },
          {
            id: "8518",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8519",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8520",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8521",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "8522",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8523",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8524",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8525",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8526",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8527",
            name: "Tr\u1ea7n Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8528", name: "Tr\u00e0ng Thi", prefix: "Ph\u1ed1" },
          { id: "8529", name: "Tr\u00e0ng Ti\u1ec1n", prefix: "Ph\u1ed1" },
          { id: "8530", name: "Trang T\u1eed", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8531",
            name: "Tri\u1ec7u Qu\u1ed1c \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8532",
            name: "Tri\u1ec7u Vi\u1ec7t V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8533",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8534",
            name: "T\u1ee9c M\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8535", name: "V\u1ea1n Ki\u1ebfp", prefix: "Ph\u1ed1" },
          {
            id: "8536",
            name: "V\u00f5 V\u0103n D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8537", name: "V\u1ecdng", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8538",
            name: "V\u1ecdng \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8539",
            name: "V\u1ecdng H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8540",
            name: "Y\u00ean Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8541",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1605",
            name: "14 L\u00fd Nam \u0110\u1ebf",
            lat: "21.037593841553",
            lng: "105.84562683106",
          },
          {
            id: "1606",
            name: "33 \u0110\u01b0\u1eddng Th\u00e0nh",
            lat: "21.033081054688",
            lng: "105.84627532959",
          },
          {
            id: "1607",
            name: "84 Th\u1ee3 Nhu\u1ed9m - Hanoi Apartment Center",
            lat: "21.023067474365",
            lng: "105.84825897217",
          },
          {
            id: "1608",
            name: "ACB Office Building",
            lat: "21.022424697876",
            lng: "105.85618591309",
          },
          {
            id: "1609",
            name: "Artexport House",
            lat: "21.02237701416",
            lng: "105.85581970215",
          },
          {
            id: "1610",
            name: "BIDV Tower",
            lat: "21.029521942139",
            lng: "105.85745239258",
          },
          {
            id: "1611",
            name: "Capital Tower",
            lat: "21.023532867432",
            lng: "105.84195709228",
          },
          {
            id: "1612",
            name: "CornerStone Building",
            lat: "21.020496368408",
            lng: "105.85548400879",
          },
          {
            id: "1613",
            name: "D\u2019. San Raffles - H\u00e0ng B\u00e0i",
            lat: "21.024063110352",
            lng: "105.85250091553",
          },
          {
            id: "1614",
            name: "Ha Noi Tung Shing Square",
            lat: "21.029420852661",
            lng: "105.85683441162",
          },
          {
            id: "1615",
            name: "H\u00e0ng Da Galleria",
            lat: "21.031278610229",
            lng: "105.84628295898",
          },
          {
            id: "1616",
            name: "Hanoitourist Building",
            lat: "21.022365570068",
            lng: "105.85539245606",
          },
          {
            id: "1617",
            name: "International Centre",
            lat: "21.025062561035",
            lng: "105.8554763794",
          },
          {
            id: "1618",
            name: "Naforimex Building",
            lat: "21.024742126465",
            lng: "105.85140991211",
          },
          {
            id: "1619",
            name: "Opera Business Centre",
            lat: "21.025035858154",
            lng: "105.8591003418",
          },
          {
            id: "1620",
            name: "Pacific Place",
            lat: "21.023460388184",
            lng: "105.85004425049",
          },
          {
            id: "1621",
            name: "Ph\u00fa \u0110i\u1ec1n Building",
            lat: "21.025281906128",
            lng: "105.84375",
          },
          {
            id: "1622",
            name: "Press Club",
            lat: "21.025341033936",
            lng: "105.85683441162",
          },
          {
            id: "1623",
            name: "Red River Building",
            lat: "21.025024414062",
            lng: "105.86055755615",
          },
          {
            id: "1624",
            name: "STD Tower",
            lat: "21.021129608154",
            lng: "105.8477935791",
          },
          {
            id: "1625",
            name: "Sun City Building",
            lat: "21.02349281311",
            lng: "105.85536193848",
          },
          {
            id: "1626",
            name: "VCI Tower",
            lat: "21.021106719971",
            lng: "105.85187530518",
          },
          {
            id: "1627",
            name: "VID Tower 1",
            lat: "21.023994445801",
            lng: "105.84174346924",
          },
          {
            id: "1628",
            name: "Vietcombank Tower",
            lat: "21.028390884399",
            lng: "105.8576965332",
          },
          {
            id: "1629",
            name: "Vinaplast T\u00e0i T\u00e2m",
            lat: "21.021724700928",
            lng: "105.85418701172",
          },
        ],
      },
      {
        id: "38",
        name: "Ho\u00e0ng Mai",
        wards: [
          { id: "584", name: "\u0110\u1ea1i Kim", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "585",
            name: "\u0110\u1ecbnh C\u00f4ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "586", name: "Gi\u00e1p B\u00e1t", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "587",
            name: "Ho\u00e0ng Li\u1ec7t",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "588",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "589", name: "L\u0129nh Nam", prefix: "Ph\u01b0\u1eddng" },
          { id: "590", name: "Mai \u0110\u1ed9ng", prefix: "Ph\u01b0\u1eddng" },
          { id: "591", name: "T\u00e2n Mai", prefix: "Ph\u01b0\u1eddng" },
          { id: "592", name: "Thanh Tr\u00ec", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "593",
            name: "Th\u1ecbnh Li\u1ec7t",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "594", name: "Tr\u1ea7n Ph\u00fa", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "595",
            name: "T\u01b0\u01a1ng Mai",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "596",
            name: "V\u0129nh H\u01b0ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "597", name: "Y\u00ean S\u1edf", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "8542", name: "B\u1eb1ng A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8543", name: "B\u1eb1ng B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8544",
            name: "B\u1eb1ng Li\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8545",
            name: "B\u00f9i Huy B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8546",
            name: "B\u00f9i X\u01b0\u01a1ng Tr\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8547", name: "C\u1ea7u G\u1ed7", prefix: "Ph\u1ed1" },
          {
            id: "8548",
            name: "C\u1ea7u L\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8549",
            name: "C\u1ea7u Thanh Tr\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8550",
            name: "\u0110\u1ea1i \u0110\u1ed3ng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "8551",
            name: "\u0110\u1ea1i Kim",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8552",
            name: "\u0110\u1ea1i T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8553",
            name: "\u0110\u1eb7ng Xu\u00e2n B\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8554",
            name: "\u0110\u1ec1n L\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8555",
            name: "\u0110\u1ec1n L\u1eeb 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8556",
            name: "\u0110\u1ec1n L\u1eeb 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8557",
            name: "\u0110\u1ec1n L\u1eeb 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8558",
            name: "\u0110\u1ecbnh C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8559",
            name: "\u0110\u1ecbnh C\u00f4ng H\u1ea1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8560",
            name: "\u0110\u1ecbnh C\u00f4ng Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8561",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8562",
            name: "\u0110o\u00e0n K\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8563",
            name: "\u0110\u1ed9i C\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8564",
            name: "\u0110\u1ed3ng M\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8565",
            name: "\u0110\u00f4ng Thi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8566",
            name: "Gi\u1ea3i Ph\u00f3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8567",
            name: "Gi\u00e1p B\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8568", name: "Gi\u00e1p L\u1ee5c", prefix: "Ph\u1ed1" },
          {
            id: "8569",
            name: "Gi\u00e1p Nh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8570",
            name: "Gi\u00e1p T\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8571", name: "H\u1ecda Mi", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8572",
            name: "Hoa Th\u01b0\u1ee3c D\u01b0\u1ee3c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8573",
            name: "Ho\u00e0ng Li\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8574",
            name: "Ho\u00e0ng Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8575",
            name: "H\u1ed3ng Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8576", name: "H\u01b0ng Ph\u00fac", prefix: "Ph\u1ed1" },
          {
            id: "8577",
            name: "Khuy\u1ebfn L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8578",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8579", name: "Kim Giang", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8580", name: "Kim Ng\u01b0u", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8581",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "8582",
            name: "L\u00ea V\u0103n Ph\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8583",
            name: "Linh \u0110\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8584",
            name: "Linh \u0110\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8585", name: "L\u0129nh Nam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8586",
            name: "L\u01b0\u01a1ng Kh\u00e1nh Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8587",
            name: "Mai \u0110\u1ed9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8588", name: "Mai Khai", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8589", name: "Minh Khai", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8590", name: "Nam D\u01b0", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8591",
            name: "Nam D\u01b0 Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8592",
            name: "Nghi\u00eam Xu\u00e2n Y\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8593",
            name: "Ng\u00f5 G\u1ed1c \u0110\u1ec1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8594",
            name: "Ng\u1ecdc H\u1ed3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8595",
            name: "Ng\u0169 Nh\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8596",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8597",
            name: "Nguy\u1ec5n C\u1ea3nh D\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8598",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8599",
            name: "Nguy\u1ec5n Ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8600",
            name: "Nguy\u1ec5n C\u00f4ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8601",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8603",
            name: "Nguy\u1ec5n Duy Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8602",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8604",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8605",
            name: "Nguy\u1ec5n Kho\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8606",
            name: "Nguy\u1ec5n Ng\u1ecdc N\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8607",
            name: "Nguy\u1ec5n Tam Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8608",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8609",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8610",
            name: "Nguy\u1ec5n Xi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8611",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8612",
            name: "Ph\u00e1p V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8613",
            name: "Sen Ngo\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8614", name: "S\u1edf Th\u01b0\u1ee3ng", prefix: "Ph\u1ed1" },
          { id: "8615", name: "Tam Trinh", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8616", name: "T\u00e2n Khai", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8617", name: "T\u00e2n Mai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8618",
            name: "T\u00e2y Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8619", name: "Thanh \u0110\u00e0m", prefix: "Ph\u1ed1" },
          {
            id: "8620",
            name: "Thanh L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8621",
            name: "Thanh Li\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8622",
            name: "Thanh Ng\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8623",
            name: "Th\u1ecbnh Li\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8624",
            name: "Th\u00fay L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8625", name: "Tr\u1ea7n \u0110i\u1ec1n", prefix: "Ph\u1ed1" },
          {
            id: "8626",
            name: "Tr\u1ea7n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8627",
            name: "Tr\u1ea7n Nguy\u00ean \u0110\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8628",
            name: "Tr\u1ea7n Th\u1ee7 \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8629",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8630",
            name: "T\u1ee9 K\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8631", name: "T\u01b0\u01a1ng Mai", prefix: "Ph\u1ed1" },
          {
            id: "8632",
            name: "T\u1ef1u Li\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8633",
            name: "V\u00e0nh \u0110ai 2,5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8634",
            name: "V\u00e0nh \u0110ai 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8635",
            name: "V\u0129nh Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8636",
            name: "V\u0129nh H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8637", name: "V\u0129nh Tuy", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8638", name: "V\u0169 T\u00f4ng Phan", prefix: "Ph\u1ed1" },
          { id: "8639", name: "Y\u00ean Duy\u00ean", prefix: "Ph\u1ed1" },
          {
            id: "8640",
            name: "Y\u00ean L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8641",
            name: "Y\u00ean S\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1630",
            name: "130 Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            lat: "20.987161636352",
            lng: "105.8519821167",
          },
          {
            id: "1631",
            name: "25 T\u00e2n Mai",
            lat: "20.983491897583",
            lng: "105.85003662109",
          },
          {
            id: "1632",
            name: "282 L\u0129nh Nam",
            lat: "20.981918334961",
            lng: "105.87937164307",
          },
          {
            id: "1633",
            name: "409 L\u0129nh Nam",
            lat: "20.983386993408",
            lng: "105.87829589844",
          },
          {
            id: "1634",
            name: "An B\u00ecnh 1",
            lat: "20.983137130737",
            lng: "105.83196258545",
          },
          {
            id: "1635",
            name: "Ao S\u00e0o",
            lat: "20.975595474243",
            lng: "105.85230255127",
          },
          {
            id: "1636",
            name: "Athena Complex Ph\u00e1p V\u00e2n",
            lat: "20.956165313721",
            lng: "105.84352111816",
          },
          {
            id: "1637",
            name: "Athena Fulland",
            lat: "20.97882270813",
            lng: "105.81326293945",
          },
          {
            id: "1638",
            name: "B1-B2 T\u00e2y Nam Linh \u0110\u00e0m",
            lat: "20.964223861694",
            lng: "105.82318115234",
          },
          {
            id: "1639",
            name: "C\u0103n h\u1ed9 Th\u00f4ng t\u1ea5n x\u00e3",
            lat: "20.976398468018",
            lng: "105.81457519531",
          },
          {
            id: "1640",
            name: "C\u1ea7u Ti\u00ean",
            lat: "20.97117805481",
            lng: "105.84166717529",
          },
          {
            id: "1641",
            name: "Chung c\u01b0 83 Ng\u1ecdc H\u1ed3i",
            lat: "20.957498550415",
            lng: "105.84308624268",
          },
          {
            id: "1642",
            name: "CT3 T\u00e2y Nam Linh \u0110\u00e0m",
            lat: "20.964694976807",
            lng: "105.82510375977",
          },
          {
            id: "1643",
            name: "CT36 - Dream Home",
            lat: "20.978593826294",
            lng: "105.83534240723",
          },
          {
            id: "1644",
            name: "\u0110\u1ea1i Kim - \u0110\u1ecbnh C\u00f4ng M\u1edf R\u1ed9ng",
            lat: "20.977920532227",
            lng: "105.83632659912",
          },
          {
            id: "1645",
            name: "\u0110\u1ec1n L\u1eeb I",
            lat: "20.984666824341",
            lng: "105.85974884033",
          },
          {
            id: "1646",
            name: "\u0110\u1ec1n L\u1eeb II",
            lat: "20.985368728638",
            lng: "105.85875701904",
          },
          {
            id: "1647",
            name: "\u0110\u1ed3ng Ph\u00e1t Park View Tower",
            lat: "20.980098724365",
            lng: "105.87424468994",
          },
          {
            id: "1648",
            name: "Eco Green Tower",
            lat: "20.977571487427",
            lng: "105.84281921387",
          },
          {
            id: "1649",
            name: "Eco Lake View",
            lat: "20.973093032837",
            lng: "105.83837127685",
          },
          {
            id: "1650",
            name: "Gamuda City",
            lat: "20.969881057739",
            lng: "105.87168121338",
          },
          {
            id: "1651",
            name: "Gamuda City (Gamuda Gardens)",
            lat: "20.970283508301",
            lng: "105.87171173096",
          },
          {
            id: "1652",
            name: "Gamuda Lakes",
            lat: "20.969808578491",
            lng: "105.87211608887",
          },
          {
            id: "1653",
            name: "Gelexia Riverside",
            lat: "20.972938537598",
            lng: "105.866355896",
          },
          {
            id: "1654",
            name: "Golden Pearl",
            lat: "20.975248336792",
            lng: "105.8172454834",
          },
          {
            id: "1655",
            name: "Green Park Tr\u1ea7n Th\u1ee7 \u0110\u1ed9",
            lat: "20.960554122925",
            lng: "105.84596252441",
          },
          {
            id: "1656",
            name: "Green Park V\u0129nh H\u01b0ng",
            lat: "20.996925354004",
            lng: "105.8797454834",
          },
          {
            id: "1657",
            name: "H\u1ea3i Ph\u00e1t Complex",
            lat: "20.960554122925",
            lng: "105.84596252441",
          },
          {
            id: "1658",
            name: "Happy House 296 L\u0129nh Nam",
            lat: "20.981531143189",
            lng: "105.88021087647",
          },
          {
            id: "1659",
            name: "Hateco Ho\u00e0ng Mai",
            lat: "20.962734222412",
            lng: "105.86434173584",
          },
          {
            id: "1660",
            name: "Helios Tower 75 Tam Trinh",
            lat: "20.994585037231",
            lng: "105.86339569092",
          },
          {
            id: "1661",
            name: "HH1 Linh \u0110\u00e0m",
            lat: "20.964735031128",
            lng: "105.82636260986",
          },
          {
            id: "1662",
            name: "HH2 Linh \u0110\u00e0m",
            lat: "20.964334487915",
            lng: "105.827293396",
          },
          {
            id: "1663",
            name: "HH3 Linh \u0110\u00e0m",
            lat: "20.963598251343",
            lng: "105.82803344727",
          },
          {
            id: "1664",
            name: "HH4 Linh \u0110\u00e0m",
            lat: "20.963314056396",
            lng: "105.82673645019",
          },
          {
            id: "1665",
            name: "H\u00f2a Ph\u00e1t 70 NDC Tower",
            lat: "20.987060546875",
            lng: "105.85035705566",
          },
          {
            id: "1666",
            name: "H\u1ed3ng H\u00e0 Tower",
            lat: "20.972515106201",
            lng: "105.84674835205",
          },
          {
            id: "1667",
            name: "HUD3 Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            lat: "20.987325668335",
            lng: "105.84983825684",
          },
          {
            id: "1668",
            name: "Intracom 208",
            lat: "21.008220672607",
            lng: "105.83955383301",
          },
          {
            id: "1669",
            name: "K35 T\u00e2n Mai",
            lat: "20.983737945557",
            lng: "105.8515625",
          },
          {
            id: "1670",
            name: "K\u0110T \u0110\u1ea1i Kim",
            lat: "20.974800109863",
            lng: "105.83530426025",
          },
          {
            id: "1671",
            name: "K\u0110T \u0110\u1ecbnh C\u00f4ng",
            lat: "20.985563278198",
            lng: "105.83258056641",
          },
          {
            id: "1672",
            name: "K\u0110T Linh \u0110\u00e0m",
            lat: "20.97145652771",
            lng: "105.82817077637",
          },
          {
            id: "1673",
            name: "K\u0110T T\u00e2y Nam Kim Giang",
            lat: "20.98514175415",
            lng: "105.80736541748",
          },
          {
            id: "1674",
            name: "K\u0110T V\u0129nh Ho\u00e0ng",
            lat: "20.984697341919",
            lng: "105.86615753174",
          },
          {
            id: "1675",
            name: "Khu di d\u00e2n \u0110\u1ec1n L\u1eeb III",
            lat: "20.984836578369",
            lng: "105.85985565185",
          },
          {
            id: "1676",
            name: "Kim V\u0103n Kim L\u0169",
            lat: "20.973291397095",
            lng: "105.82000732422",
          },
          {
            id: "1677",
            name: "LePARC by Gamuda",
            lat: "20.969888687134",
            lng: "105.8726272583",
          },
          {
            id: "1678",
            name: "Licogi 12",
            lat: "20.971748352051",
            lng: "105.83906555176",
          },
          {
            id: "1679",
            name: "Lilama 52 L\u0129nh Nam",
            lat: "20.988988876343",
            lng: "105.86489868164",
          },
          {
            id: "1680",
            name: "Mandarin Garden 2",
            lat: "20.983860015869",
            lng: "105.84660339356",
          },
          {
            id: "1681",
            name: "Momota Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            lat: "20.987424850464",
            lng: "105.85154724121",
          },
          {
            id: "1682",
            name: "Nam \u0110\u00f4 Complex",
            lat: "20.979711532593",
            lng: "105.84483337402",
          },
          {
            id: "1683",
            name: "Nam H\u1ea3i Lakeview",
            lat: "20.983457565308",
            lng: "105.86720275879",
          },
          {
            id: "1684",
            name: "New Horizon City - 87 L\u0129nh Nam",
            lat: "20.989423751831",
            lng: "105.86656188965",
          },
          {
            id: "1685",
            name: "Nh\u00e0 \u1edf x\u00e3 h\u1ed9i @Home",
            lat: "20.979932785034",
            lng: "105.86463165283",
          },
          {
            id: "1686",
            name: "NOXH \u0110\u1ed3ng M\u00f4",
            lat: "20.973470687866",
            lng: "105.82582855225",
          },
          {
            id: "1687",
            name: "Osaka Complex",
            lat: "20.958904266357",
            lng: "105.83818054199",
          },
          {
            id: "1688",
            name: "Ph\u00e1p V\u00e2n T\u1ee9 Hi\u1ec7p",
            lat: "20.956270217896",
            lng: "105.84504699707",
          },
          {
            id: "1689",
            name: "Rainbow Linh \u0110\u00e0m",
            lat: "20.963884353638",
            lng: "105.82416534424",
          },
          {
            id: "1690",
            name: "Rice City Linh \u0110\u00e0m",
            lat: "20.963834762573",
            lng: "105.82245635986",
          },
          {
            id: "1691",
            name: "Riverside Tower 79 Thanh \u0110\u00e0m",
            lat: "20.993234634399",
            lng: "105.88736724853",
          },
          {
            id: "1692",
            name: "Rose Town",
            lat: "20.956588745117",
            lng: "105.84488677978",
          },
          {
            id: "1693",
            name: "Ruby Tower",
            lat: "20.989273071289",
            lng: "105.83251953125",
          },
          {
            id: "1694",
            name: "Sky Central",
            lat: "20.982782363892",
            lng: "105.83438110352",
          },
          {
            id: "1695",
            name: "Sky Garden Towers",
            lat: "20.982881546021",
            lng: "105.83829498291",
          },
          {
            id: "1696",
            name: "Smile Building",
            lat: "20.978338241577",
            lng: "105.83633422852",
          },
          {
            id: "1697",
            name: "South Building",
            lat: "20.948329925537",
            lng: "105.85318756103",
          },
          {
            id: "1698",
            name: "South Tower Ho\u00e0ng Li\u1ec7t",
            lat: "20.962495803833",
            lng: "105.84484100342",
          },
          {
            id: "1699",
            name: "Sunshine Palace",
            lat: "20.990182876587",
            lng: "105.86865997315",
          },
          {
            id: "1700",
            name: "T&T Riverview",
            lat: "20.998937606811",
            lng: "105.88019561768",
          },
          {
            id: "1701",
            name: "T&T Tower",
            lat: "20.983034133911",
            lng: "105.83511352539",
          },
          {
            id: "1702",
            name: "T\u00e2y Nam H\u1ed3 Linh \u0110\u00e0m",
            lat: "20.962661743164",
            lng: "105.82126617432",
          },
          {
            id: "1703",
            name: "The Manor Central Park",
            lat: "20.972890853882",
            lng: "105.81534576416",
          },
          {
            id: "1704",
            name: "The One Residence - Gamuda Garden",
            lat: "20.969203948975",
            lng: "105.87691497803",
          },
          {
            id: "1705",
            name: "The Two Residence - Gamuda Garden",
            lat: "20.970058441162",
            lng: "105.87777709961",
          },
          {
            id: "1706",
            name: "The Zen Residence",
            lat: "20.965379714966",
            lng: "105.85063934326",
          },
          {
            id: "1707",
            name: "Th\u1ecbnh Li\u1ec7t",
            lat: "20.979793548584",
            lng: "105.85580444336",
          },
          {
            id: "1708",
            name: "Ti\u1ebfn Ph\u00fa",
            lat: "20.984836578369",
            lng: "105.85985565185",
          },
          {
            id: "1709",
            name: "TTTM \u0110\u1ec1n L\u1eeb",
            lat: "20.984836578369",
            lng: "105.85985565185",
          },
          {
            id: "1710",
            name: "VP2 Linh \u0110\u00e0m",
            lat: "20.96418762207",
            lng: "105.83052825928",
          },
          {
            id: "1711",
            name: "VP3 Linh \u0110\u00e0m",
            lat: "20.964454650879",
            lng: "105.83135986328",
          },
          {
            id: "1712",
            name: "VP4 Linh \u0110\u00e0m",
            lat: "20.963829040527",
            lng: "105.83186340332",
          },
          {
            id: "1713",
            name: "VP5 Linh \u0110\u00e0m",
            lat: "20.963562011719",
            lng: "105.8316116333",
          },
          {
            id: "1714",
            name: "VP6 Linh \u0110\u00e0m",
            lat: "20.9671459198",
            lng: "105.83313751221",
          },
          {
            id: "1715",
            name: "VP7 Linh \u0110\u00e0m",
            lat: "20.965320587158",
            lng: "105.8381652832",
          },
        ],
      },
      {
        id: "39",
        name: "Long Bi\u00ean",
        wards: [
          {
            id: "598",
            name: "B\u1ed3 \u0110\u1ec1",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "599", name: "C\u1ef1 Kh\u1ed1i", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "600",
            name: "\u0110\u1ee9c Giang",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "601", name: "Gia Th\u1ee5y", prefix: "Ph\u01b0\u1eddng" },
          { id: "602", name: "Giang Bi\u00ean", prefix: "Ph\u01b0\u1eddng" },
          { id: "603", name: "Long Bi\u00ean", prefix: "Ph\u01b0\u1eddng" },
          { id: "604", name: "Ng\u1ecdc L\u00e2m", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "605",
            name: "Ng\u1ecdc Th\u1ee5y",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "606",
            name: "Ph\u00fac \u0110\u1ed3ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "607", name: "Ph\u00fac L\u1ee3i", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "608",
            name: "S\u00e0i \u0110\u1ed3ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "609",
            name: "Th\u1ea1ch B\u00e0n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "610",
            name: "Th\u01b0\u1ee3ng Thanh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "611",
            name: "Vi\u1ec7t H\u01b0ng",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "8642", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8643", name: "100", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8644", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8645", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8646",
            name: "\u00c1i M\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8647",
            name: "Anh \u0110\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8648",
            name: "Anh \u0110\u00e0o 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8651",
            name: "Anh \u0110\u00e0o 1-10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8654",
            name: "Anh \u0110\u00e0o 1-9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8649",
            name: "Anh \u0110\u00e0o 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8650",
            name: "Anh \u0110\u00e0o 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8652",
            name: "Anh \u0110\u00e0o 11-6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8653",
            name: "Anh \u0110\u00e0o 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8655",
            name: "Anh \u0110\u00e0o 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8656",
            name: "Anh \u0110\u00e0o 2-22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8657",
            name: "Anh \u0110\u00e0o 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8658",
            name: "Anh \u0110\u00e0o 3-19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8659",
            name: "Anh \u0110\u00e0o 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8660",
            name: "Anh \u0110\u00e0o 4-9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8661",
            name: "Anh \u0110\u00e0o 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8662",
            name: "Anh \u0110\u00e0o 5-12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8663",
            name: "Anh \u0110\u00e0o 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8664",
            name: "Anh \u0110\u00e0o 6 - 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8665",
            name: "Anh \u0110\u00e0o 6-23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8666",
            name: "Anh \u0110\u00e0o 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8667",
            name: "Anh \u0110\u00e0o 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8668",
            name: "Anh \u0110\u00e0o 8-38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8669",
            name: "Anh \u0110\u00e0o 8-4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8670",
            name: "Anh \u0110\u00e0o 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8671",
            name: "Anh \u0110\u00e0o 9 - 50",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8672",
            name: "Anh \u0110\u00e0o 9-34",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8673",
            name: "Anh \u0110\u00e0o 9-48",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8674",
            name: "Anh \u0110\u00e0o 9-5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8675",
            name: "Anh \u0110\u00e0o 9-55",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8676", name: "Ao L\u00e3o", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8677",
            name: "B\u1eafc Bi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8678",
            name: "B\u1eafc C\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8679",
            name: "B\u1eafc C\u1ea7u 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8680",
            name: "B\u1eafc C\u1ea7u 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8681",
            name: "B\u1eb1ng L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8682",
            name: "B\u1eb1ng L\u0103ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8683",
            name: "B\u1eb1ng L\u0103ng 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8684",
            name: "B\u1eb1ng L\u0103ng 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8685",
            name: "B\u1eb1ng L\u0103ng 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8686",
            name: "B\u1eb1ng L\u0103ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8687",
            name: "B\u1eb1ng L\u0103ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8688",
            name: "B\u1eb1ng L\u0103ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8689",
            name: "B\u1eb1ng L\u0103ng 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8690",
            name: "B\u1eb1ng L\u0103ng 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8691",
            name: "B\u1eb1ng L\u0103ng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8692",
            name: "B\u1eb1ng L\u0103ng 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8693",
            name: "B\u00e1t Kh\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8694",
            name: "B\u1ed3 \u0110\u1ec1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8695",
            name: "B\u01b0\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8696",
            name: "B\u01b0\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8697",
            name: "C\u1ea7u B\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8698",
            name: "Chu Huy M\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8699",
            name: "Chu Huy M\u1eabn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8700", name: "C\u1ed5 Linh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8701",
            name: "C\u1ed9ng H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8702",
            name: "C\u00f4ng Nghi\u1ec7p 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8703",
            name: "C\u00f4ng Nghi\u1ec7p 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8704",
            name: "C\u00f4ng Nghi\u1ec7p 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8705",
            name: "C\u00f4ng Nghi\u1ec7p 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8706",
            name: "C\u00f4ng Nghi\u1ec7p 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8707",
            name: "\u0110\u00e0m Quang Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8708",
            name: "\u0110\u1eb7ng V\u0169 H\u1ef7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8709",
            name: "\u0110o\u00e0n Khu\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8710",
            name: "\u0110\u1ed9c L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8711",
            name: "\u0110\u1ee9c Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8712", name: "Gia An", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8713", name: "Gia Qu\u1ea5t", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8714",
            name: "Gia Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8715", name: "Gia Th\u1ee5y", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8716",
            name: "Giang Bi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8717",
            name: "H\u00e0m R\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8718",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8719", name: "Hoa H\u1ed3ng", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8720", name: "Hoa L\u00e2m", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8721", name: "Hoa Lan", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8722", name: "Hoa Lan 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8723", name: "Hoa Lan 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8724", name: "Hoa Lan 2-11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8725", name: "Hoa Lan 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8726", name: "Hoa Lan 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8727", name: "Hoa Lan 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8728", name: "Hoa Lan 5-26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8729", name: "Hoa Lan 5-27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8730", name: "Hoa Lan 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8731", name: "Hoa Lan 6-27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8732", name: "Hoa Lan 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8733", name: "Hoa Lan 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8734", name: "Hoa Lan 8-11", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8735",
            name: "Hoa Ph\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8736",
            name: "Hoa Ph\u01b0\u1ee3ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8737",
            name: "Hoa Ph\u01b0\u1ee3ng 1-42",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8738",
            name: "Hoa Ph\u01b0\u1ee3ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8740",
            name: "Hoa Ph\u01b0\u1ee3ng 2-6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8739",
            name: "Hoa Ph\u01b0\u1ee3ng 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8741",
            name: "Hoa Ph\u01b0\u1ee3ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8742",
            name: "Hoa Ph\u01b0\u1ee3ng 3-10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8743",
            name: "Hoa Ph\u01b0\u1ee3ng 3-31",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8744",
            name: "Hoa Ph\u01b0\u1ee3ng 3-33",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8745",
            name: "Hoa Ph\u01b0\u1ee3ng 3-34",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8746",
            name: "Hoa Ph\u01b0\u1ee3ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8747",
            name: "Hoa Ph\u01b0\u1ee3ng 4-22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8748",
            name: "Hoa Ph\u01b0\u1ee3ng 4-24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8749",
            name: "Hoa Ph\u01b0\u1ee3ng 4-38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8750",
            name: "Hoa Ph\u01b0\u1ee3ng 4-6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8751",
            name: "Hoa Ph\u01b0\u1ee3ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8752",
            name: "Hoa Ph\u01b0\u1ee3ng 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8753",
            name: "Hoa Ph\u01b0\u1ee3ng 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8754",
            name: "Hoa Ph\u01b0\u1ee3ng 7- 31",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8755",
            name: "Hoa Ph\u01b0\u1ee3ng 7-3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8756",
            name: "Hoa Ph\u01b0\u1ee3ng 7-50",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8757",
            name: "Hoa Ph\u01b0\u1ee3ng 7-50B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8758",
            name: "Hoa Ph\u01b0\u1ee3ng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8759",
            name: "Hoa Ph\u01b0\u1ee3ng 8-18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8760",
            name: "Hoa Ph\u01b0\u1ee3ng 8-22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8761",
            name: "Hoa Ph\u01b0\u1ee3ng 8-9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8762", name: "Hoa S\u1eefa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8763",
            name: "Hoa S\u1eefa 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8764",
            name: "Hoa S\u1eefa 1 - 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8769",
            name: "Hoa S\u1eefa 1-22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8765",
            name: "Hoa S\u1eefa 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8766",
            name: "Hoa S\u1eefa 10 - 44",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8767",
            name: "Hoa S\u1eefa 10-14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8768",
            name: "Hoa S\u1eefa 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8770",
            name: "Hoa S\u1eefa 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8771",
            name: "Hoa S\u1eefa 2-10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8772",
            name: "Hoa S\u1eefa 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8773",
            name: "Hoa S\u1eefa 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8774",
            name: "Hoa S\u1eefa 4-2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8775",
            name: "Hoa S\u1eefa 4-23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8776",
            name: "Hoa S\u1eefa 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8777",
            name: "Hoa S\u1eefa 5-8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8778",
            name: "Hoa S\u1eefa 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8779",
            name: "Hoa S\u1eefa 6-38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8780",
            name: "Hoa S\u1eefa 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8781",
            name: "Hoa S\u1eefa 7-17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8782",
            name: "Hoa S\u1eefa 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8783",
            name: "Hoa S\u1eefa 8-28",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8784",
            name: "Hoa S\u1eefa 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8785",
            name: "Hoa S\u1eefa 9-17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8786",
            name: "Hoa S\u1eefa 9-2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8787",
            name: "Ho\u00e0ng Nh\u01b0 Ti\u1ebfp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8788",
            name: "H\u1ed9i X\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8789",
            name: "H\u1ed3ng H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8790",
            name: "H\u01b0\u1edbng D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8791",
            name: "H\u01b0\u1edbng D\u01b0\u01a1ng 01-39",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8792",
            name: "H\u01b0\u1edbng D\u01b0\u01a1ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8793",
            name: "H\u01b0\u1edbng D\u01b0\u01a1ng 1 - 45",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8794",
            name: "H\u01b0\u1edbng D\u01b0\u01a1ng 1-12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8795",
            name: "H\u01b0\u01a1\u0301ng D\u01b0\u01a1ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8796",
            name: "H\u01b0\u1edbng D\u01b0\u01a1ng 3-26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8797",
            name: "H\u01b0\u1edbng D\u01b0\u01a1ng 3-3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8798",
            name: "H\u01b0\u1edbng D\u01b0\u01a1ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8799",
            name: "H\u01b0\u1edbng D\u01b0\u01a1ng 4-37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8800",
            name: "H\u01b0\u1edbng D\u01b0\u01a1ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8801",
            name: "H\u01b0\u1edbng D\u01b0\u01a1ng 5-1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8802",
            name: "Hu\u1ef3nh T\u1ea5n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8803",
            name: "Hu\u1ef3nh V\u0103n Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8804", name: "K\u1ebb T\u1ea1nh", prefix: "Ph\u1ed1" },
          { id: "8805", name: "Kim Quan", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8806",
            name: "Kim Quan Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8807", name: "L\u00e2m Du", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8808",
            name: "L\u00e2m H\u1ea1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8809", name: "L\u00e0ng Nha", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8810", name: "L\u1ec7 M\u1eadt", prefix: "Ph\u1ed1" },
          {
            id: "8811",
            name: "Li\u1ec5u giai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8812",
            name: "Long Bi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8813",
            name: "Long Bi\u00ean 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8814",
            name: "Long Bi\u00ean 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8815",
            name: "L\u01b0u Kh\u00e1nh \u0110\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8816",
            name: "L\u00fd S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8817",
            name: "Mai Ch\u00ed Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8818", name: "Mai Ph\u00fac", prefix: "Ph\u1ed1" },
          {
            id: "8819",
            name: "Nam \u0110u\u1ed1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8820",
            name: "Ng\u00f4 Gia Kh\u1ea3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8821",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8822", name: "Ng\u00f5 Tr\u1ea1m", prefix: "Ph\u1ed1" },
          {
            id: "8823",
            name: "Ng\u1ecdc L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8824",
            name: "Ng\u1ecdc Th\u1ee5y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8825", name: "Ng\u1ecdc Tr\u00ec", prefix: "Ph\u1ed1" },
          {
            id: "8826",
            name: "Nguy\u1ec5n Cao Luy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8827",
            name: "Nguy\u1ec5n \u0110\u1ee9c Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8828",
            name: "Nguy\u1ec5n Gia Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8829",
            name: "Nguy\u1ec5n S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8830",
            name: "Nguy\u00ean T\u1eed L\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8831",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8832",
            name: "Nguy\u1ec5n V\u0103n H\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8833",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8834",
            name: "Nguy\u1ec7t Qu\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8835",
            name: "Nguy\u1ec7t Qu\u1ebf 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8840",
            name: "Nguy\u1ec7t Qu\u1ebf 1-19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8836",
            name: "Nguy\u1ec7t Qu\u1ebf 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8837",
            name: "Nguy\u1ec7t Qu\u1ebf 10-22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8838",
            name: "Nguy\u1ec7t Qu\u1ebf 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8839",
            name: "Nguy\u1ec7t Qu\u1ebf 11-7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8841",
            name: "Nguy\u1ec7t Qu\u1ebf 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8842",
            name: "Nguy\u1ec7t Qu\u1ebf 12-52",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8843",
            name: "Nguy\u1ec7t Qu\u1ebf 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8844",
            name: "Nguy\u1ec7t Qu\u1ebf 15-23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8845",
            name: "Nguy\u1ec7t Qu\u1ebf 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8846",
            name: "Nguy\u1ec7t Qu\u1ebf 16-10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8847",
            name: "Nguy\u1ec7t Qu\u1ebf 16-21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8848",
            name: "Nguy\u1ec7t Qu\u1ebf 16-25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8849",
            name: "Nguy\u1ec7t Qu\u1ebf 16-37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8850",
            name: "Nguy\u1ec7t Qu\u1ebf 16-7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8851",
            name: "Nguy\u1ec7t Qu\u1ebf 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8852",
            name: "Nguy\u1ec7t Qu\u1ebf 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8853",
            name: "Nguy\u1ec7t Qu\u1ebf 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8854",
            name: "Nguy\u1ec7t Qu\u1ebf 19-10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8855",
            name: "Nguy\u1ec7t Qu\u1ebf 19-16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8856",
            name: "Nguy\u1ec7t Qu\u1ebf 19-32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8857",
            name: "Nguy\u1ec7t Qu\u1ebf 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8858",
            name: "Nguy\u1ec7t Qu\u1ebf 2 - 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8859",
            name: "Nguy\u1ec7t Qu\u1ebf 2 - 54",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8860",
            name: "Nguy\u1ec7t Qu\u1ebf 2 - 70",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8864",
            name: "Nguy\u1ec7t Qu\u1ebf 2-10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8871",
            name: "Nguy\u1ec7t Qu\u1ebf 2-46",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8873",
            name: "Nguy\u1ec7t Qu\u1ebf 2-64",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8861",
            name: "Nguy\u1ec7t Qu\u1ebf 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8862",
            name: "Nguy\u1ec7t Qu\u1ebf 20 - 50",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8863",
            name: "Nguy\u1ec7t Qu\u1ebf 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8865",
            name: "Nguy\u1ec7t Qu\u1ebf 21-19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8866",
            name: "Nguy\u1ec7t Qu\u1ebf 21-25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8867",
            name: "Nguy\u1ec7t Qu\u1ebf 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8868",
            name: "Nguy\u1ec7t Qu\u1ebf 22 - 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8869",
            name: "Nguy\u1ec7t Qu\u1ebf 22-3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8870",
            name: "Nguy\u1ec7t Qu\u1ebf 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8872",
            name: "Nguy\u1ec7t Qu\u1ebf 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8874",
            name: "Nguy\u1ec7t Qu\u1ebf 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8875",
            name: "Nguy\u1ec7t Qu\u1ebf 3 - 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8876",
            name: "Nguy\u1ec7t Qu\u1ebf 3-1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8877",
            name: "Nguy\u1ec7t Qu\u1ebf 3-36",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8878",
            name: "Nguy\u1ec7t Qu\u1ebf 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8879",
            name: "Nguy\u1ec7t Qu\u1ebf 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8880",
            name: "Nguy\u1ec7t Qu\u1ebf 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8881",
            name: "Nguy\u1ec7t Qu\u1ebf 6-17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8882",
            name: "Nguy\u1ec7t Qu\u1ebf 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8883",
            name: "Nguy\u1ec7t Qu\u1ebf 7-3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8884",
            name: "Nguy\u1ec7t Qu\u1ebf 7-34",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8885",
            name: "Nguy\u1ec7t Qu\u1ebf 7-4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8886",
            name: "Nguy\u1ec7t Qu\u1ebf 7-5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8887",
            name: "Nguy\u1ec7t Qu\u1ebf 7-6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8888",
            name: "Nguy\u1ec7t Qu\u1ebf 7-8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8889",
            name: "Nguy\u1ec7t Qu\u1ebf 7-9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8890",
            name: "Nguy\u1ec7t Qu\u1ebf 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8891",
            name: "Nguy\u1ec7t Qu\u1ebf 8-3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8892",
            name: "Nguy\u1ec7t Qu\u1ebf 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8893",
            name: "Nguy\u1ec7t Qu\u1ebf 9 - 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8894", name: "Nh\u1eadt", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8895",
            name: "\u00d4 C\u00e1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8896", name: "Ph\u1ed1 Nha", prefix: "Ph\u1ed1" },
          {
            id: "8897",
            name: "Ph\u1ed1 Tr\u1ea1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8898", name: "Phong Lan", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8899", name: "Phong Lan 1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8900",
            name: "Phong Lan 1-19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8901", name: "Phong Lan 1-8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8902", name: "Phong Lan 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8903", name: "Phong Lan 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8904", name: "Phong Lan 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8905", name: "Phong Lan 5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8906",
            name: "Phong Lan 5-31",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8907",
            name: "Ph\u00fa Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8908",
            name: "Ph\u00fac \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8909",
            name: "Ph\u00fac L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8910",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8911",
            name: "Qu\u1ed1c l\u1ed9 1B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8912",
            name: "Qu\u1ed1c L\u1ed9 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8913", name: "S\u00e0i \u0110\u1ed3ng", prefix: "Ph\u1ed1" },
          {
            id: "8914",
            name: "T\u00e2n Th\u1ee5y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8915",
            name: "Th\u1ea1ch B\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8916", name: "Th\u1ea1ch C\u1ea7u", prefix: "Ph\u1ed1" },
          { id: "8917", name: "Thanh Am", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8918",
            name: "Th\u00e9p M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8919",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8920", name: "Th\u01b0\u1ee3ng C\u00e1t", prefix: "Ph\u1ed1" },
          {
            id: "8921",
            name: "Th\u01b0\u1ee3ng \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8922",
            name: "Th\u01b0\u01a1\u0323ng H\u00f4\u0323i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8923",
            name: "Th\u01b0\u1ee3ng Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8924",
            name: "Th\u1ee5y Khuy\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8925",
            name: "T\u00ecnh Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8926", name: "Trung H\u00e0", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8927",
            name: "Tr\u01b0\u1eddng L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8928",
            name: "Tr\u01b0\u1eddng Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8929",
            name: "T\u01b0 \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8930", name: "Tulip", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8931", name: "Tulip 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8932", name: "Tulip 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8933", name: "Tulip 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8934", name: "Tulip 5-2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8935", name: "Tulip 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8936", name: "Tulip 6-22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8937", name: "Tulip 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8938", name: "Tulip 7-25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8939", name: "Tulip 9", prefix: "Ph\u1ed1" },
          {
            id: "8940",
            name: "V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8941", name: "Vi\u1ec7t H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "8942",
            name: "V\u0169 \u0110\u1ee9c Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8943",
            name: "V\u0169 Xu\u00e2n Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8944",
            name: "X\u00f3m L\u00f2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8945",
            name: "Xu\u00e2n \u0110\u1ed7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8946", name: "Z133", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "1716",
            name: "45 Nguy\u1ec5n S\u01a1n",
            lat: "21.047618865967",
            lng: "105.87711334228",
          },
          {
            id: "1717",
            name: "Aeon Mall Long Bi\u00ean",
            lat: "21.029922485352",
            lng: "105.88245391846",
          },
          {
            id: "1718",
            name: "Berriver Long Bi\u00ean",
            lat: "21.044317245483",
            lng: "105.87786102295",
          },
          {
            id: "1719",
            name: "C17 B\u1ed9 C\u00f4ng An",
            lat: "21.049911499023",
            lng: "105.86633300781",
          },
          {
            id: "1720",
            name: "C\u0103n h\u1ed9 d\u1ecbch v\u1ee5 cao c\u1ea5p Nh\u1eadt B\u1ea3n",
            lat: "21.055183410644",
            lng: "105.88604736328",
          },
          {
            id: "1721",
            name: "Canal Park",
            lat: "21.018646240234",
            lng: "105.92237091065",
          },
          {
            id: "1722",
            name: "Chung c\u01b0 NO-08 Giang Bi\u00ean",
            lat: "21.067457199097",
            lng: "105.92005157471",
          },
          {
            id: "1723",
            name: "C\u00f4ng vi\u00ean c\u00f4ng ngh\u1ec7 th\u00f4ng tin H\u00e0 N\u1ed9i",
            lat: "21.028049468994",
            lng: "105.90022277832",
          },
          {
            id: "1724",
            name: "CT1 Th\u1ea1ch B\u00e0n",
            lat: "21.019556045532",
            lng: "105.90727233887",
          },
          {
            id: "1725",
            name: "CT2A Th\u1ea1ch B\u00e0n",
            lat: "21.021669387817",
            lng: "105.91417694092",
          },
          {
            id: "1726",
            name: "Eco City Vi\u1ec7t H\u01b0ng",
            lat: "21.057735443115",
            lng: "105.91335296631",
          },
          {
            id: "1727",
            name: "Ecohome Ph\u00fac L\u1ee3i",
            lat: "21.042358398438",
            lng: "105.92729949951",
          },
          {
            id: "1728",
            name: "Garden City",
            lat: "21.019697189331",
            lng: "105.92053222656",
          },
          {
            id: "1729",
            name: "Green House",
            lat: "21.070169448852",
            lng: "105.90683746338",
          },
          {
            id: "1730",
            name: "Green Park CT15 Vi\u1ec7t H\u01b0ng",
            lat: "21.064611434936",
            lng: "105.91111755371",
          },
          {
            id: "1731",
            name: "Green Tower S\u00e0i \u0110\u1ed3ng",
            lat: "21.034845352173",
            lng: "105.91271209717",
          },
          {
            id: "1732",
            name: "H\u00e0 N\u1ed9i Garden Villa (H\u00e0 N\u1ed9i Garden City)",
            lat: "21.020210266113",
            lng: "105.9206161499",
          },
          {
            id: "1733",
            name: "H\u00e0 N\u1ed9i Homeland",
            lat: "21.065502166748",
            lng: "105.88831329346",
          },
          {
            id: "1734",
            name: "Happy Star Tower",
            lat: "21.060039520264",
            lng: "105.91365051269",
          },
          {
            id: "1735",
            name: "HC Golden City",
            lat: "21.037178039551",
            lng: "105.87306976318",
          },
          {
            id: "1736",
            name: "Him Lam Th\u1ea1ch B\u00e0n 2",
            lat: "21.024032592773",
            lng: "105.91035461426",
          },
          {
            id: "1737",
            name: "Ho\u00e0ng Quy",
            lat: "21.037178039551",
            lng: "105.87306976318",
          },
          {
            id: "1738",
            name: "IT Park",
            lat: "21.040733337402",
            lng: "105.89656066894",
          },
          {
            id: "1739",
            name: "Khai S\u01a1n Hill",
            lat: "21.069557189941",
            lng: "105.87926483154",
          },
          {
            id: "1740",
            name: "Khu \u0111\u00f4 th\u1ecb Th\u01b0\u1ee3ng Thanh",
            lat: "21.072340011597",
            lng: "105.89544677734",
          },
          {
            id: "1741",
            name: "Lotus Lake View",
            lat: "21.047859191894",
            lng: "105.88468170166",
          },
          {
            id: "1742",
            name: "Mipec Riverside",
            lat: "21.045745849609",
            lng: "105.86637878418",
          },
          {
            id: "1743",
            name: "New Space",
            lat: "21.060749053955",
            lng: "105.91368103027",
          },
          {
            id: "1744",
            name: "Nh\u00e0 \u1edf c\u00e1n b\u1ed9 chi\u1ebfn s\u1ef9 c\u00f4ng an Gia L\u00e2m",
            lat: "21.065502166748",
            lng: "105.88831329346",
          },
          {
            id: "1745",
            name: "Northern Diamond",
            lat: "21.031446456909",
            lng: "105.90082550049",
          },
          {
            id: "1746",
            name: "One 18 Ng\u1ecdc L\u00e2m",
            lat: "21.048288345337",
            lng: "105.87445068359",
          },
          {
            id: "1747",
            name: "Plaschem Plaza",
            lat: "21.050550460815",
            lng: "105.88479614258",
          },
          {
            id: "1748",
            name: "Rice City S\u00f4ng H\u1ed3ng",
            lat: "21.05432510376",
            lng: "105.87780761719",
          },
          {
            id: "1749",
            name: "Rice Home S\u00f4ng H\u1ed3ng",
            lat: "21.053829193115",
            lng: "105.87761688232",
          },
          {
            id: "1750",
            name: "Ruby City",
            lat: "21.058847427368",
            lng: "105.91470336914",
          },
          {
            id: "1751",
            name: "Ruby CT3 Ph\u00fac L\u1ee3i",
            lat: "21.036489486694",
            lng: "105.93190002441",
          },
          {
            id: "1752",
            name: "S\u00e0i \u0110\u1ed3ng",
            lat: "21.037466049194",
            lng: "105.9075088501",
          },
          {
            id: "1753",
            name: "S\u00e0i \u0110\u1ed3ng Lake View",
            lat: "21.036336898804",
            lng: "105.90642547607",
          },
          {
            id: "1754",
            name: "Savico MegaMall",
            lat: "21.036964416504",
            lng: "105.89985656738",
          },
          {
            id: "1755",
            name: "Silver Wings Building - Mekong",
            lat: "21.042753219604",
            lng: "105.87055206299",
          },
          {
            id: "1756",
            name: "Sunrise Building 3",
            lat: "21.039476394653",
            lng: "105.90586090088",
          },
          {
            id: "1757",
            name: "Th\u1ea1ch B\u00e0n",
            lat: "21.01905632019",
            lng: "105.90640258789",
          },
          {
            id: "1758",
            name: "Valencia Garden",
            lat: "21.05584526062",
            lng: "105.91175079346",
          },
          {
            id: "1759",
            name: "Vi\u1ec7t H\u01b0ng",
            lat: "21.062690734863",
            lng: "105.90833282471",
          },
          {
            id: "1760",
            name: "Vincom Long Bien",
            lat: "21.057071685791",
            lng: "105.8989944458",
          },
          {
            id: "1761",
            name: "Vinhomes Riverside",
            lat: "21.046094894409",
            lng: "105.91554260254",
          },
          {
            id: "1762",
            name: "Vinhomes The Harmony",
            lat: "21.04736328125",
            lng: "105.90567016602",
          },
        ],
      },
      {
        id: "40",
        name: "M\u00ea Linh",
        wards: [
          {
            id: "612",
            name: "Chi \u0110\u00f4ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "613", name: "Chu Phan", prefix: "X\u00e3" },
          { id: "614", name: "\u0110\u1ea1i Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "615", name: "Ho\u00e0ng Kim", prefix: "X\u00e3" },
          { id: "616", name: "Kim Hoa", prefix: "X\u00e3" },
          { id: "617", name: "Li\u00ean M\u1ea1c", prefix: "X\u00e3" },
          { id: "618", name: "M\u00ea Linh", prefix: "X\u00e3" },
          { id: "619", name: "Quang Minh", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "620", name: "Tam \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "621", name: "Th\u1ea1ch \u0110\u00e0", prefix: "X\u00e3" },
          { id: "622", name: "Thanh L\u00e2m", prefix: "X\u00e3" },
          { id: "623", name: "Ti\u1ec1n Phong", prefix: "X\u00e3" },
          { id: "624", name: "Ti\u1ebfn Th\u1eafng", prefix: "X\u00e3" },
          { id: "625", name: "Ti\u1ebfn Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "626", name: "Tr\u00e1ng Vi\u1ec7t", prefix: "X\u00e3" },
          { id: "627", name: "T\u1ef1 L\u1eadp", prefix: "X\u00e3" },
          { id: "628", name: "V\u0103n Kh\u00ea", prefix: "X\u00e3" },
          { id: "629", name: "V\u1ea1n Y\u00ean", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "8947", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8948", name: "23B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8949", name: "24", prefix: "Ph\u1ed1" },
          { id: "8950", name: "35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8951", name: "353", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8952",
            name: "B\u1eafc Th\u0103ng Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8953",
            name: "B\u1ea3o Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8954",
            name: "Cao t\u1ed1c Th\u0103ng Long N\u1ed9i B\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8955",
            name: "C\u00e2y Xo\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8956", name: "D\u00e2n Sinh", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8957", name: "H\u00e0 Phong", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8958",
            name: "H\u00e0 Phong 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8959",
            name: "Hoa B\u1eb1ng L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8960",
            name: "Hoa \u0110i\u1ec7p V\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8961",
            name: "Hoa Ng\u1ecdc Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8962",
            name: "Hoa Ph\u01b0\u1ee3ng V\u0129",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8963",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8964",
            name: "Long Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8965",
            name: "Ph\u1ed1 Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8966", name: "Quang Minh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8967",
            name: "Qu\u1ed1c l\u1ed9 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8968",
            name: "Qu\u1ed1c l\u1ed9 23B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8969",
            name: "Qu\u1ed1c l\u1ed9 308",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8970",
            name: "Qu\u1ed1c L\u1ed9 35",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8971",
            name: "T\u1ec9nh l\u1ed9 301",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8972",
            name: "T\u1ec9nh l\u1ed9 312",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8973",
            name: "Trung S\u01a1n Tr\u1ea7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8974",
            name: "T\u01b0\u1eddng Vi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8975",
            name: "V\u1ea1n Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8976",
            name: "V\u00e0nh \u0110ai 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8977",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8978",
            name: "Y\u00ean Nh\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1763",
            name: "AIC M\u00ea Linh",
            lat: "21.167276382446",
            lng: "105.75793457031",
          },
          {
            id: "1764",
            name: "Ba \u0110\u00ecnh M\u00ea Linh",
            lat: "21.155374526977",
            lng: "105.74903869629",
          },
          {
            id: "1765",
            name: "BIDV Scienco 5 M\u00ea Linh",
            lat: "21.165323257446",
            lng: "105.75479888916",
          },
          {
            id: "1766",
            name: "CEO M\u00ea Linh",
            lat: "21.170545578003",
            lng: "105.74478912353",
          },
          {
            id: "1767",
            name: "Chi \u0110\u00f4ng",
            lat: "21.220821380615",
            lng: "105.75598907471",
          },
          {
            id: "1768",
            name: "Chung c\u01b0 c\u00e1c KCN - M\u00ea Linh",
            lat: "21.162483215332",
            lng: "105.76239013672",
          },
          {
            id: "1769",
            name: "Cienco 5 M\u00ea Linh",
            lat: "21.165542602539",
            lng: "105.75314331055",
          },
          {
            id: "1770",
            name: "Diamond Park New",
            lat: "21.164947509766",
            lng: "105.76665496826",
          },
          {
            id: "1771",
            name: "H\u00e0 Phong",
            lat: "21.162040710449",
            lng: "105.76198577881",
          },
          {
            id: "1772",
            name: "Ho\u00e0ng V\u00e2n",
            lat: "21.150785446167",
            lng: "105.75688171387",
          },
          {
            id: "1773",
            name: "KCN Quang Minh",
            lat: "21.193775177002",
            lng: "105.76593780518",
          },
          {
            id: "1774",
            name: "K\u0110T Quang Minh",
            lat: "21.196413040161",
            lng: "105.78218078613",
          },
          {
            id: "1775",
            name: "Licogi 18 - M\u00ea Linh",
            lat: "21.190153121948",
            lng: "105.78215789795",
          },
          {
            id: "1776",
            name: "M\u00ea Linh - Thanh L\u00e2m - \u0110\u1ea1i Th\u1ecbnh",
            lat: "21.198097229004",
            lng: "105.71954345703",
          },
          {
            id: "1777",
            name: "Minh Giang \u0110\u1ea7m V\u00e0",
            lat: "21.160531997681",
            lng: "105.76445770264",
          },
          {
            id: "1778",
            name: "Ph\u00fac Vi\u1ec7t",
            lat: "21.160531997681",
            lng: "105.76445770264",
          },
          {
            id: "1779",
            name: "Rose Valley",
            lat: "21.160531997681",
            lng: "105.76445770264",
          },
          {
            id: "1780",
            name: "Ti\u1ec1n Phong",
            lat: "21.141590118408",
            lng: "105.76379394531",
          },
          {
            id: "1781",
            name: "T\u00f9ng Ph\u01b0\u01a1ng",
            lat: "21.189403533936",
            lng: "105.71950531006",
          },
        ],
      },
      {
        id: "41",
        name: "M\u1ef9 \u0110\u1ee9c",
        wards: [
          { id: "630", name: "An M\u1ef9", prefix: "X\u00e3" },
          { id: "631", name: "An Ph\u00fa", prefix: "X\u00e3" },
          { id: "632", name: "An Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "633", name: "B\u1ed9t Xuy\u00ean", prefix: "X\u00e3" },
          { id: "634", name: "\u0110\u1ea1i H\u01b0ng", prefix: "X\u00e3" },
          {
            id: "635",
            name: "\u0110\u1ea1i Ngh\u0129a",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "636", name: "\u0110\u1ed1c T\u00edn", prefix: "X\u00e3" },
          { id: "637", name: "\u0110\u1ed3ng T\u00e2m", prefix: "X\u00e3" },
          { id: "638", name: "H\u1ed3ng S\u01a1n", prefix: "X\u00e3" },
          { id: "639", name: "H\u1ee3p Thanh", prefix: "X\u00e3" },
          { id: "640", name: "H\u1ee3p Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "641", name: "H\u00f9ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "642", name: "H\u01b0\u01a1ng S\u01a1n", prefix: "X\u00e3" },
          { id: "643", name: "L\u00ea Thanh", prefix: "X\u00e3" },
          { id: "644", name: "M\u1ef9 Th\u00e0nh", prefix: "X\u00e3" },
          { id: "645", name: "Ph\u00f9 L\u01b0u T\u1ebf", prefix: "X\u00e3" },
          { id: "646", name: "Ph\u00fac L\u00e2m", prefix: "X\u00e3" },
          { id: "647", name: "Ph\u00f9ng X\u00e1", prefix: "X\u00e3" },
          { id: "648", name: "Th\u01b0\u1ee3ng L\u00e2m", prefix: "X\u00e3" },
          { id: "649", name: "Tuy Lai", prefix: "X\u00e3" },
          { id: "650", name: "V\u1ea1n Kim", prefix: "X\u00e3" },
          { id: "651", name: "Xuy X\u00e1", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "8979",
            name: "Qu\u1ed1c l\u1ed9 21B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8980",
            name: "T\u1ec9nh l\u1ed9 419",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "42",
        name: "Nam T\u1eeb Li\u00eam",
        wards: [
          {
            id: "652",
            name: "C\u1ea7u Di\u1ec5n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "653", name: "\u0110\u1ea1i M\u1ed7", prefix: "X\u00e3" },
          { id: "654", name: "M\u1ec5 Tr\u00ec", prefix: "X\u00e3" },
          {
            id: "655",
            name: "M\u1ef9 \u0110\u00ecnh 1",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "656",
            name: "M\u1ef9 \u0110\u00ecnh 2",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "657", name: "Ph\u00fa \u0110\u00f4", prefix: "X\u00e3" },
          {
            id: "658",
            name: "Ph\u01b0\u01a1ng Canh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "659", name: "T\u00e2y M\u1ed7", prefix: "X\u00e3" },
          { id: "660", name: "Trung V\u0103n", prefix: "X\u00e3" },
          {
            id: "661",
            name: "Xu\u00e2n Ph\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "8981", name: "32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8982", name: "41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8983", name: "70", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8984", name: "70A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8985", name: "An H\u1ea3i 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8986", name: "B6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8987",
            name: "B\u00ecnh H\u00f2a 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8988",
            name: "B\u00f9i Xu\u00e2n Ph\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8989", name: "C18", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "8990",
            name: "Cao Xu\u00e2n Huy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8991",
            name: "C\u1ea7u C\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8992",
            name: "C\u1ea7u Di\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "8993",
            name: "C\u1ea7u Th\u0103ng Long",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "8994",
            name: "Ch\u00e2u V\u0103n Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "8995", name: "CN1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8996", name: "CN2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8997", name: "CN3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8998", name: "CN4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "8999", name: "CN5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9000", name: "CN6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9001", name: "CN7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9002", name: "CN8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9003", name: "CN9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9004",
            name: "C\u01b0\u01a1ng Ki\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9013", name: "Do Nha", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9017",
            name: "D\u01b0\u01a1ng Khu\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9005",
            name: "\u0110\u1ea1i Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9006",
            name: "\u0110\u1ea1i l\u1ed9 Th\u0103ng Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9007",
            name: "\u0110\u1ea1i M\u1ed7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9008",
            name: "\u0110\u00ea \u0110\u00f4ng Ng\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9009",
            name: "\u0110\u00ecnh Qu\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9010",
            name: "\u0110\u00ecnh Th\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9011",
            name: "\u0110\u1ed7 \u0110\u00ecnh Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9012",
            name: "\u0110\u1ed7 \u0110\u1ee9c D\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9014",
            name: "\u0110\u1ed7 Xu\u00e2n H\u1ee3p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9015",
            name: "\u0110\u1ed3ng B\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9016",
            name: "\u0110\u1ed3ng Me",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9018", name: "Giao Quang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9019",
            name: "H\u00e0 B\u00e1 T\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9020", name: "H\u00e0m Nghi", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9021",
            name: "H\u1ed3 M\u1ec5 Tr\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9022",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9023", name: "H\u1ecfa L\u00f2", prefix: "Ph\u1ed1" },
          {
            id: "9024",
            name: "Ho\u00e0i Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9025",
            name: "Ho\u00e0ng C\u00f4ng Ch\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9026",
            name: "Ho\u00e0ng Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9027",
            name: "Ho\u00e0ng Lan 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9028",
            name: "Ho\u00e0ng Lan 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9029",
            name: "Ho\u00e0ng Lan 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9030",
            name: "H\u00f2e Th\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9031",
            name: "H\u1eefu H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9032", name: "K1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9033", name: "K1B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9034", name: "K2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9035", name: "K3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9036", name: "K32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9037", name: "K4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9038",
            name: "Khu\u1ea5t Duy Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9039",
            name: "L\u00e1ng H\u00f2a L\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9041",
            name: "L\u00ea Duy Hi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9040",
            name: "L\u00ea \u0110\u1ee9c Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9042",
            name: "L\u00ea Quang \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9043",
            name: "L\u00ea V\u0103n L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9044",
            name: "Li\u00ean C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9045",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9046",
            name: "L\u01b0u H\u1eefu Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9047",
            name: "L\u01b0u \u00dac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9048",
            name: "M\u1ec5 Tr\u00ec",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "9049",
            name: "M\u1ec5 Tr\u00ec H\u1ea1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9050",
            name: "M\u1ec5 Tr\u00ec Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9051",
            name: "Mi\u1ebfu \u0110\u1ea7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9052", name: "Mi\u00eau Nha", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9053", name: "M\u1ed9c Lan", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9054",
            name: "M\u1ed9c Lan 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9055",
            name: "M\u1ed9c Lan 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9056",
            name: "M\u1ed9c Lan 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9058",
            name: "M\u1ed9c Lan 2-3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9059",
            name: "M\u1ed9c Lan 2-8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9057",
            name: "M\u1ed9c Lan 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9060",
            name: "M\u1ed9c Lan 3-1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9061",
            name: "M\u1ed9c Lan 4-5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9062",
            name: "M\u1ed9c Lan 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9063",
            name: "M\u1ed9c Lan 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9064",
            name: "M\u1ef9 \u0110\u00ecnh",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "9065",
            name: "Ng\u00e2n H\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9066",
            name: "Ng\u1ecda Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9067",
            name: "Ng\u1ecdc \u0110\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9068",
            name: "Ng\u1ecdc M\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9069",
            name: "Ng\u1ecdc Tr\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9070",
            name: "Nguy\u1ec5n C\u1ea3nh H\u1ee3p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9071",
            name: "Nguy\u1ec5n C\u01a1 Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9072",
            name: "Nguy\u1ec5n \u0110\u1ed5ng Chi",
            prefix: "Ph\u1ed1",
          },
          {
            id: "9073",
            name: "Nguy\u1ec5n Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9074",
            name: "Nguy\u1ec5n Phong S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9075",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9076",
            name: "Nguy\u1ec5n V\u0103n X\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9077",
            name: "Nh\u00e2n M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9078", name: "Nh\u1ed5n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9079",
            name: "Nhu\u1ec7 Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9080",
            name: "N\u00f4ng L\u00e2m",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "9081",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9082",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9083",
            name: "Ph\u00fa \u0110\u00f4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9084", name: "Ph\u00fa H\u00e0", prefix: "Ph\u1ed1" },
          {
            id: "9085",
            name: "Ph\u00fa M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9086",
            name: "Ph\u00fa Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9087",
            name: "Ph\u00fac Di\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9088",
            name: "Ph\u00f9ng Khoang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9089",
            name: "Ph\u01b0\u01a1ng Canh",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "9090",
            name: "Quang Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9091",
            name: "Qu\u1ed1c l\u1ed9 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9092",
            name: "Qu\u1ed1c l\u1ed9 70",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9093",
            name: "Sa \u0110\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9094",
            name: "T\u00e2n M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9095",
            name: "T\u00e2n Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9096",
            name: "T\u0103ng Thi\u1ebft Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9097",
            name: "T\u00e2y M\u1ed7",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "9098",
            name: "Th\u1ea1ch Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9099",
            name: "Th\u1ea1ch Th\u1ea3o 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9100",
            name: "Th\u1ea1ch Th\u1ea3o 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9101",
            name: "Th\u1ea1ch Th\u1ea3o 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9102",
            name: "Th\u1ea1ch Th\u1ea3o 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9103",
            name: "Th\u1ea1ch Th\u1ea3o 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9104",
            name: "Th\u1ea1ch Th\u1ea3o 5-6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9105",
            name: "Th\u1ea1ch Th\u1ea3o 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9106",
            name: "Thanh B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9107",
            name: "Th\u1ecb C\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9108",
            name: "Thi\u00ean Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9109",
            name: "Thi\u1ec7n Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9110",
            name: "T\u1ec9nh l\u1ed9 72",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9111",
            name: "T\u1ed1 H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9112",
            name: "T\u00f4n Th\u1ea5t Thuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9113", name: "Tr\u1ea7n B\u00ecnh", prefix: "Ph\u1ed1" },
          {
            id: "9114",
            name: "Tr\u1ea7n H\u1eefu D\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9115",
            name: "Tr\u1ea7n Quang \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9116",
            name: "Tr\u1ea7n T\u1ea5n M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9117",
            name: "Tr\u1ea7n V\u0103n C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9118",
            name: "Tr\u1ea7n V\u0103n Lai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9119", name: "Tr\u1ea7n V\u1ef9", prefix: "Ph\u1ed1" },
          {
            id: "9120",
            name: "Trung V\u0103n",
            prefix: "\u0111\u01b0\u1eddng",
          },
          { id: "9121", name: "Tu Ho\u00e0ng", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9122",
            name: "V\u0103n Ti\u1ebfn D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9123", name: "V\u0169 H\u1eefu", prefix: "Ph\u1ed1" },
          { id: "9124", name: "V\u0169 H\u1eefu L\u1ee3i", prefix: "Ph\u1ed1" },
          {
            id: "9125",
            name: "V\u0169 Qu\u1ef3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9126",
            name: "V\u01b0\u1eddn Cam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9127",
            name: "X\u00f3m Ch\u00f9a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9128",
            name: "Xu\u00e2n Canh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9129", name: "Xu\u00e2n La", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9130",
            name: "Xu\u00e2n Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9131",
            name: "Y\u00ean H\u00f2a \u0110\u1ea1i M\u1ed7",
            prefix: "\u0111\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1782",
            name: "789 B\u1ed9 T\u1ed5ng Tham M\u01b0u - BQP",
            lat: "21.024967193604",
            lng: "105.77131652832",
          },
          {
            id: "1783",
            name: "An L\u1ea1c M\u1ef9 \u0110\u00ecnh",
            lat: "21.033037185669",
            lng: "105.76166534424",
          },
          {
            id: "1784",
            name: "An L\u1ea1c Ph\u00f9ng Khoang",
            lat: "20.986604690552",
            lng: "105.78857421875",
          },
          {
            id: "1785",
            name: "Apex Tower",
            lat: "21.014579772949",
            lng: "105.78428649902",
          },
          {
            id: "1786",
            name: "Athena Complex",
            lat: "21.041181564331",
            lng: "105.7353591919",
          },
          {
            id: "1787",
            name: "B.I.G Tower",
            lat: "21.031179428101",
            lng: "105.77904510498",
          },
          {
            id: "1788",
            name: "C14 - B\u1ed9 C\u00f4ng An",
            lat: "20.990812301636",
            lng: "105.78391265869",
          },
          {
            id: "1789",
            name: "C37 B\u1ed9 C\u00f4ng An - B\u1eafc H\u00e0 Tower",
            lat: "20.996829986572",
            lng: "105.79383850098",
          },
          {
            id: "1790",
            name: "CEO Tower",
            lat: "21.015817642212",
            lng: "105.78274536133",
          },
          {
            id: "1791",
            name: "Chung c\u01b0 B32 \u0110\u1ea1i M\u1ed7",
            lat: "20.985904693604",
            lng: "105.76788330078",
          },
          {
            id: "1792",
            name: "Crown Office",
            lat: "21.030523300171",
            lng: "105.76908111572",
          },
          {
            id: "1793",
            name: "CT1 Trung V\u0103n - Vinaconex 3",
            lat: "20.993669509888",
            lng: "105.77807617188",
          },
          {
            id: "1794",
            name: "CT2 Trung V\u0103n - Vinaconex 3",
            lat: "20.993726730347",
            lng: "105.77840423584",
          },
          {
            id: "1795",
            name: "CT2 Trung V\u0103n Viettel Hancic",
            lat: "20.99303817749",
            lng: "105.78835296631",
          },
          {
            id: "1796",
            name: "CT2 Xu\u00e2n Ph\u01b0\u01a1ng",
            lat: "21.031505584717",
            lng: "105.75537872315",
          },
          {
            id: "1797",
            name: "CT3 C\u2019Land L\u00ea \u0110\u1ee9c Th\u1ecd",
            lat: "21.030433654785",
            lng: "105.76927947998",
          },
          {
            id: "1799",
            name: "Detech Tower",
            lat: "21.028900146484",
            lng: "105.78173065185",
          },
          {
            id: "1800",
            name: "Dolphin Plaza",
            lat: "21.030044555664",
            lng: "105.77662658691",
          },
          {
            id: "1801",
            name: "Dream Town",
            lat: "21.016553878784",
            lng: "105.74628448486",
          },
          {
            id: "1798",
            name: "\u0110\u1ea1i M\u1ed7",
            lat: "20.992609024048",
            lng: "105.76223754883",
          },
          {
            id: "1802",
            name: "Ecolife Capitol",
            lat: "20.993562698364",
            lng: "105.78515625",
          },
          {
            id: "1803",
            name: "Five Star M\u1ef9 \u0110\u00ecnh",
            lat: "21.018629074097",
            lng: "105.77559661865",
          },
          {
            id: "1804",
            name: "FLC Complex Ph\u1ea1m H\u00f9ng",
            lat: "21.028190612793",
            lng: "105.77919769287",
          },
          {
            id: "1805",
            name: "FLC Garden City",
            lat: "20.991436004639",
            lng: "105.75606536865",
          },
          {
            id: "1806",
            name: "FLC Green Home 18 Ph\u1ea1m H\u00f9ng",
            lat: "21.030023574829",
            lng: "105.77881622315",
          },
          {
            id: "1807",
            name: "FLC Landmark Tower",
            lat: "21.034076690674",
            lng: "105.77035522461",
          },
          {
            id: "1808",
            name: "FLC Premier Park \u0110\u1ea1i M\u1ed7",
            lat: "20.998075485229",
            lng: "105.75171661377",
          },
          {
            id: "1809",
            name: "Florence M\u1ef9 \u0110\u00ecnh",
            lat: "21.030097961426",
            lng: "105.76187896728",
          },
          {
            id: "1810",
            name: "Golden Field M\u1ef9 \u0110\u00ecnh",
            lat: "21.035104751587",
            lng: "105.76554107666",
          },
          {
            id: "1811",
            name: "Golden Palace",
            lat: "21.011936187744",
            lng: "105.77499389648",
          },
          {
            id: "1812",
            name: "Handico Tower",
            lat: "21.01661491394",
            lng: "105.78160858154",
          },
          {
            id: "1813",
            name: "Hateco Xu\u00e2n Ph\u01b0\u01a1ng",
            lat: "21.055646896362",
            lng: "105.73091888428",
          },
          {
            id: "1814",
            name: "HH1 M\u1ec5 Tr\u00ec H\u1ea1",
            lat: "21.015453338623",
            lng: "105.779296875",
          },
          {
            id: "1815",
            name: "HH2 B\u1eafc H\u00e0",
            lat: "20.99751663208",
            lng: "105.79516601562",
          },
          {
            id: "1816",
            name: "HH3 Tower",
            lat: "21.016557693481",
            lng: "105.77941894531",
          },
          {
            id: "1817",
            name: "ICD",
            lat: "21.028305053711",
            lng: "105.7806930542",
          },
          {
            id: "1818",
            name: "Intracom 6",
            lat: "21.026660919189",
            lng: "105.73840332031",
          },
          {
            id: "1819",
            name: "Intracom1",
            lat: "20.990697860718",
            lng: "105.78777313232",
          },
          {
            id: "1820",
            name: "Iris Garden",
            lat: "21.029207229614",
            lng: "105.76445007324",
          },
          {
            id: "1821",
            name: "K\u0110T M\u1ec5 Tr\u00ec H\u1ea1",
            lat: "21.014993667602",
            lng: "105.78269958496",
          },
          {
            id: "1822",
            name: "K\u0110T Trung V\u0103n - Hancic",
            lat: "20.992214202881",
            lng: "105.78868103027",
          },
          {
            id: "1823",
            name: "K\u0110T Trung V\u0103n - Vinaconex 3",
            lat: "20.994457244873",
            lng: "105.77975463867",
          },
          {
            id: "1824",
            name: "Keangnam",
            lat: "21.016849517822",
            lng: "105.78397369385",
          },
          {
            id: "1825",
            name: "Khu \u0111\u00f4 th\u1ecb Xu\u00e2n Ph\u01b0\u01a1ng",
            lat: "21.025880813599",
            lng: "105.74070739746",
          },
          {
            id: "1826",
            name: "Lafontana",
            lat: "20.98980140686",
            lng: "105.76152801514",
          },
          {
            id: "1827",
            name: "Lilama 10",
            lat: "20.997804641724",
            lng: "105.79353332519",
          },
          {
            id: "1828",
            name: "Lotus Complex HH1",
            lat: "21.008852005005",
            lng: "105.74391937256",
          },
          {
            id: "1829",
            name: "Louis City",
            lat: "20.998075485229",
            lng: "105.75171661377",
          },
          {
            id: "1830",
            name: "MD Complex M\u1ef9 \u0110\u00ecnh",
            lat: "21.034229278564",
            lng: "105.76536560059",
          },
          {
            id: "1831",
            name: "M\u1ec5 Tr\u00ec Th\u01b0\u1ee3ng",
            lat: "21.004905700684",
            lng: "105.7772064209",
          },
          {
            id: "1832",
            name: "Mon City - H\u1ea3i \u0110\u0103ng City",
            lat: "21.035120010376",
            lng: "105.7674331665",
          },
          {
            id: "1833",
            name: "M\u1ef9 \u0110\u00ecnh",
            lat: "21.031929016113",
            lng: "105.76692199707",
          },
          {
            id: "1834",
            name: "M\u1ef9 \u0110\u00ecnh I",
            lat: "21.034135818481",
            lng: "105.76410675049",
          },
          {
            id: "1835",
            name: "M\u1ef9 \u0110\u00ecnh II",
            lat: "21.031585693359",
            lng: "105.76820373535",
          },
          {
            id: "1836",
            name: "M\u1ef9 \u0110\u00ecnh Pearl",
            lat: "21.00684928894",
            lng: "105.76931762695",
          },
          {
            id: "1837",
            name: "M\u1ef9 \u0110\u00ecnh Plaza",
            lat: "21.030839920044",
            lng: "105.77579498291",
          },
          {
            id: "1838",
            name: "M\u1ef9 \u0110\u00ecnh Plaza 2",
            lat: "21.031084060669",
            lng: "105.77456665039",
          },
          {
            id: "1839",
            name: "Newtatco Complex",
            lat: "21.02795791626",
            lng: "105.77179718018",
          },
          {
            id: "1840",
            name: "Nh\u00e0 \u1edf x\u00e3 h\u1ed9i B\u1ed9 T\u01b0 l\u1ec7nh T\u0103ng Thi\u1ebft Gi\u00e1p",
            lat: "21.022161483765",
            lng: "105.77179718018",
          },
          {
            id: "1841",
            name: "NOXH Trung V\u0103n",
            lat: "20.992307662964",
            lng: "105.78867340088",
          },
          {
            id: "1842",
            name: "Ph\u00fa M\u1ef9",
            lat: "21.035659790039",
            lng: "105.76690673828",
          },
          {
            id: "1843",
            name: "Ph\u00f9ng Khoang",
            lat: "20.995641708374",
            lng: "105.79232788086",
          },
          {
            id: "1844",
            name: "PVN Tower",
            lat: "21.010305404663",
            lng: "105.77405548096",
          },
          {
            id: "1845",
            name: "QMS Tower",
            lat: "20.986377716064",
            lng: "105.77867889404",
          },
          {
            id: "1846",
            name: "Roman Plaza",
            lat: "20.984340667725",
            lng: "105.77680969238",
          },
          {
            id: "1847",
            name: "Startup Tower",
            lat: "20.993900299072",
            lng: "105.76019287109",
          },
          {
            id: "1848",
            name: "Sudico M\u1ef9 \u0110\u00ecnh",
            lat: "21.017435073852",
            lng: "105.78039550781",
          },
          {
            id: "1849",
            name: "Sun Square",
            lat: "21.033765792847",
            lng: "105.77030181885",
          },
          {
            id: "1850",
            name: "Sunshine Center",
            lat: "21.029983520508",
            lng: "105.77864074707",
          },
          {
            id: "1851",
            name: "T\u00e2y H\u00e0 Tower",
            lat: "20.996280670166",
            lng: "105.7932510376",
          },
          {
            id: "1852",
            name: "T\u00e2y M\u1ed7",
            lat: "20.996740341186",
            lng: "105.74555206299",
          },
          {
            id: "1853",
            name: "Thang Long Number One",
            lat: "21.002698898315",
            lng: "105.79242706299",
          },
          {
            id: "1854",
            name: "The Emerald",
            lat: "21.022161483765",
            lng: "105.77179718018",
          },
          {
            id: "1855",
            name: "The Garden",
            lat: "21.015119552612",
            lng: "105.77783203125",
          },
          {
            id: "1856",
            name: "The Garden Hills - 99 Tr\u1ea7n B\u00ecnh",
            lat: "21.030405044556",
            lng: "105.77703094482",
          },
          {
            id: "1857",
            name: "The Manor",
            lat: "21.01417350769",
            lng: "105.77587127685",
          },
          {
            id: "1858",
            name: "TIG \u0110\u1ea1i M\u1ed7",
            lat: "20.994472503662",
            lng: "105.76130676269",
          },
          {
            id: "1859",
            name: "Times Square H\u00e0 N\u1ed9i",
            lat: "21.00345993042",
            lng: "105.77033233643",
          },
          {
            id: "1860",
            name: "Toyota M\u1ef9 \u0110\u00ecnh",
            lat: "21.029060363769",
            lng: "105.77982330322",
          },
          {
            id: "1861",
            name: "Viglacera Tower",
            lat: "21.003505706787",
            lng: "105.79152679443",
          },
          {
            id: "1862",
            name: "Vinaconex 7 - 34 C\u1ea7u Di\u1ec5n",
            lat: "21.033887863159",
            lng: "105.7596282959",
          },
          {
            id: "1863",
            name: "Vinhomes Gardenia",
            lat: "21.036567687988",
            lng: "105.76048278809",
          },
          {
            id: "1864",
            name: "Vinhomes Green Bay M\u1ec5 Tr\u00ec",
            lat: "20.998958587646",
            lng: "105.78803253174",
          },
          {
            id: "1865",
            name: "Vinhomes Melodia",
            lat: "21.019798278809",
            lng: "105.78070831299",
          },
          {
            id: "1866",
            name: "Vinhomes Sky Lake",
            lat: "21.019083023071",
            lng: "105.78173065185",
          },
          {
            id: "1867",
            name: "VOV M\u1ec5 Tr\u00ec",
            lat: "20.995250701904",
            lng: "105.78440856934",
          },
          {
            id: "1868",
            name: "X2 M\u1ef9 \u0110\u00ecnh",
            lat: "21.034578323364",
            lng: "105.76812744141",
          },
          {
            id: "1869",
            name: "Xu\u00e2n Ph\u01b0\u01a1ng Garden",
            lat: "21.029207229614",
            lng: "105.76445007324",
          },
          {
            id: "1870",
            name: "Xu\u00e2n Ph\u01b0\u01a1ng Residence",
            lat: "21.032726287842",
            lng: "105.75374603272",
          },
          {
            id: "1871",
            name: "Xu\u00e2n Ph\u01b0\u01a1ng Tasco",
            lat: "21.025352478027",
            lng: "105.74130249023",
          },
        ],
      },
      {
        id: "43",
        name: "Ph\u00fa Xuy\u00ean",
        wards: [
          { id: "662", name: "B\u1ea1ch H\u1ea1", prefix: "X\u00e3" },
          { id: "663", name: "Ch\u00e2u Can", prefix: "X\u00e3" },
          { id: "664", name: "Chuy\u00ean M\u1ef9", prefix: "X\u00e3" },
          { id: "665", name: "\u0110\u1ea1i Th\u1eafng", prefix: "X\u00e3" },
          { id: "666", name: "\u0110\u1ea1i Xuy\u00ean", prefix: "X\u00e3" },
          { id: "667", name: "Ho\u00e0ng Long", prefix: "X\u00e3" },
          { id: "668", name: "H\u1ed3ng Minh", prefix: "X\u00e3" },
          { id: "669", name: "H\u1ed3ng Th\u00e1i", prefix: "X\u00e3" },
          { id: "670", name: "Khai Th\u00e1i", prefix: "X\u00e3" },
          { id: "671", name: "Minh T\u00e2n", prefix: "X\u00e3" },
          { id: "672", name: "Nam Phong", prefix: "X\u00e3" },
          { id: "673", name: "Nam Tri\u1ec1u", prefix: "X\u00e3" },
          { id: "674", name: "Ph\u00fa Minh", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "675", name: "Ph\u00fa T\u00fac", prefix: "X\u00e3" },
          {
            id: "676",
            name: "Ph\u00fa Xuy\u00ean",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "677", name: "Ph\u00fa Y\u00ean", prefix: "X\u00e3" },
          { id: "678", name: "Ph\u00fac Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "679", name: "Ph\u01b0\u1ee3ng D\u1ef1c", prefix: "X\u00e3" },
          { id: "680", name: "Quang L\u00e3ng", prefix: "X\u00e3" },
          { id: "681", name: "Quang Trung", prefix: "X\u00e3" },
          { id: "682", name: "S\u01a1n H\u00e0", prefix: "X\u00e3" },
          { id: "683", name: "T\u00e2n D\u00e2n", prefix: "X\u00e3" },
          { id: "684", name: "Th\u1ee5y Ph\u00fa", prefix: "X\u00e3" },
          { id: "685", name: "Tri Th\u1ee7y", prefix: "X\u00e3" },
          { id: "686", name: "Tri Trung", prefix: "X\u00e3" },
          { id: "687", name: "V\u0103n Ho\u00e0ng", prefix: "X\u00e3" },
          { id: "688", name: "V\u0103n Nh\u00e2n", prefix: "X\u00e3" },
          { id: "689", name: "V\u00e2n T\u1eeb", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "9132", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9133", name: "429", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9134", name: "73", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9135",
            name: "Li\u00ean Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9136", name: "Ph\u1ed1 Gu\u1ed9t", prefix: "Ph\u1ed1" },
          {
            id: "9137",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9138",
            name: "S\u1ea3o Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9139",
            name: "Truy\u1ec1n Th\u1ed1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9140",
            name: "V\u00e2n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1872",
            name: "Hanssip",
            lat: "20.692998886108",
            lng: "105.92886352539",
          },
        ],
      },
      {
        id: "44",
        name: "Ph\u00fac Th\u1ecd",
        wards: [
          { id: "690", name: "C\u1ea9m \u0110\u00ecnh", prefix: "X\u00e3" },
          { id: "691", name: "G\u1ea1ch", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "692", name: "H\u00e1t M\u00f4n", prefix: "X\u00e3" },
          { id: "693", name: "Hi\u1ec7p Thu\u1eadn", prefix: "X\u00e3" },
          { id: "694", name: "Li\u00ean Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "695", name: "Long Xuy\u00ean", prefix: "X\u00e3" },
          { id: "696", name: "Ng\u1ecdc T\u1ea3o", prefix: "X\u00e3" },
          { id: "697", name: "Ph\u00fac H\u00f2a", prefix: "X\u00e3" },
          {
            id: "698",
            name: "Ph\u00fac Th\u1ecd",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "699", name: "Ph\u1ee5ng Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          {
            id: "700",
            name: "Ph\u01b0\u01a1ng \u0110\u1ed9",
            prefix: "X\u00e3",
          },
          { id: "701", name: "Sen Chi\u1ec3u", prefix: "X\u00e3" },
          { id: "702", name: "Tam Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "703", name: "Tam Thu\u1ea5n", prefix: "X\u00e3" },
          { id: "704", name: "Thanh \u0110a", prefix: "X\u00e3" },
          { id: "705", name: "Th\u1ecd L\u1ed9c", prefix: "X\u00e3" },
          { id: "706", name: "Th\u01b0\u1ee3ng C\u1ed1c", prefix: "X\u00e3" },
          { id: "707", name: "T\u00edch Giang", prefix: "X\u00e3" },
          { id: "708", name: "Tr\u1ea1ch M\u1ef9 L\u1ed9c", prefix: "X\u00e3" },
          { id: "709", name: "V\u00e2n H\u00e0", prefix: "X\u00e3" },
          { id: "710", name: "V\u00e2n Nam", prefix: "X\u00e3" },
          { id: "711", name: "V\u00e2n Ph\u00fac", prefix: "X\u00e3" },
          { id: "712", name: "V\u00f5ng Xuy\u00ean", prefix: "X\u00e3" },
          { id: "713", name: "Xu\u00e2n Ph\u00fa", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "9141",
            name: "L\u1ea1c Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9142",
            name: "Qu\u1ed1c l\u1ed9 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9143",
            name: "T\u1ec9nh l\u1ed9 419",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1873",
            name: "C\u1ea9m \u0110\u00ecnh",
            lat: "21.087303161621",
            lng: "105.62736511231",
          },
        ],
      },
      {
        id: "45",
        name: "Qu\u1ed1c Oai",
        wards: [
          { id: "714", name: "C\u1ea5n H\u1eefu", prefix: "X\u00e3" },
          { id: "715", name: "C\u1ed9ng H\u00f2a", prefix: "X\u00e3" },
          { id: "716", name: "\u0110\u1ea1i Th\u00e0nh", prefix: "X\u00e3" },
          { id: "717", name: "\u0110\u1ed3ng Quang", prefix: "X\u00e3" },
          { id: "718", name: "\u0110\u00f4ng Xu\u00e2n", prefix: "X\u00e3" },
          { id: "719", name: "\u0110\u00f4ng Y\u00ean", prefix: "X\u00e3" },
          { id: "720", name: "H\u00f2a Th\u1ea1ch", prefix: "X\u00e3" },
          { id: "721", name: "Li\u1ec7p Tuy\u1ebft", prefix: "X\u00e3" },
          { id: "722", name: "Ngh\u0129a H\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "723", name: "Ng\u1ecdc Li\u1ec7p", prefix: "X\u00e3" },
          { id: "724", name: "Ng\u1ecdc M\u1ef9", prefix: "X\u00e3" },
          { id: "725", name: "Ph\u00fa C\u00e1t", prefix: "X\u00e3" },
          { id: "726", name: "Ph\u00fa M\u00e3n", prefix: "X\u00e3" },
          { id: "727", name: "Ph\u01b0\u1ee3ng C\u00e1ch", prefix: "X\u00e3" },
          { id: "728", name: "Qu\u1ed1c Oai", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "729", name: "S\u00e0i S\u01a1n", prefix: "X\u00e3" },
          { id: "730", name: "T\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "731", name: "T\u00e2n Ph\u00fa", prefix: "X\u00e3" },
          { id: "732", name: "Th\u1ea1ch Th\u00e1n", prefix: "X\u00e3" },
          { id: "733", name: "Tuy\u1ebft Ngh\u0129a", prefix: "X\u00e3" },
          { id: "734", name: "Y\u00ean S\u01a1n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "9144", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9145", name: "21A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9146", name: "421B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9147",
            name: "\u0110\u1ea1i l\u1ed9 Th\u0103ng Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9148",
            name: "\u0110\u1ed3ng B\u1ee5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9149",
            name: "\u0110\u1ed3ng L\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9150",
            name: "\u0110\u1ed3ng V\u1ee1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9151",
            name: "H\u1ed3 Ch\u00ed Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9152",
            name: "H\u00f2a Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9153",
            name: "L\u00e1ng H\u00f2a L\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9154",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9155",
            name: "Qu\u1ed1c l\u1ed9 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9156",
            name: "Qu\u1ed1c l\u1ed9 21A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9157",
            name: "Qu\u1ed1c l\u1ed9 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9158",
            name: "Th\u1ea1ch Th\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9159",
            name: "T\u1ec9nh l\u1ed9 419",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9160",
            name: "T\u1ec9nh L\u1ed9 421B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9161",
            name: "Y\u00ean Qu\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1874",
            name: "New House City",
            lat: "20.996446609497",
            lng: "105.59937286377",
          },
          {
            id: "1875",
            name: "Sunny Garden City",
            lat: "21.012910842896",
            lng: "105.6416015625",
          },
          {
            id: "1876",
            name: "T\u00e2y Qu\u1ed1c Oai",
            lat: "21.027765274048",
            lng: "105.83415985107",
          },
          {
            id: "1877",
            name: "Tu\u1ea7n Ch\u00e2u",
            lat: "21.032455444336",
            lng: "105.64711761475",
          },
        ],
      },
      {
        id: "46",
        name: "S\u00f3c S\u01a1n",
        wards: [
          { id: "735", name: "B\u1eafc Ph\u00fa", prefix: "X\u00e3" },
          { id: "736", name: "B\u1eafc S\u01a1n", prefix: "X\u00e3" },
          { id: "737", name: "\u0110\u00f4ng Xu\u00e2n", prefix: "X\u00e3" },
          { id: "738", name: "\u0110\u1ee9c Ho\u00e0", prefix: "X\u00e3" },
          { id: "739", name: "Hi\u1ec1n Ninh", prefix: "X\u00e3" },
          { id: "740", name: "H\u1ed3ng K\u1ef3", prefix: "X\u00e3" },
          { id: "741", name: "Kim L\u0169", prefix: "X\u00e3" },
          { id: "742", name: "Mai \u0110\u00ecnh", prefix: "X\u00e3" },
          { id: "743", name: "Minh Ph\u00fa", prefix: "X\u00e3" },
          { id: "744", name: "Minh Tr\u00ed", prefix: "X\u00e3" },
          { id: "745", name: "Nam S\u01a1n", prefix: "X\u00e3" },
          { id: "746", name: "Ph\u00fa C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "747", name: "Ph\u00f9 Linh", prefix: "X\u00e3" },
          { id: "748", name: "Ph\u00f9 L\u1ed7", prefix: "X\u00e3" },
          { id: "749", name: "Ph\u00fa Minh", prefix: "X\u00e3" },
          { id: "750", name: "Quang Ti\u1ebfn", prefix: "X\u00e3" },
          {
            id: "751",
            name: "S\u00f3c S\u01a1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "752", name: "T\u00e2n D\u00e2n", prefix: "X\u00e3" },
          { id: "753", name: "T\u00e2n H\u01b0ng", prefix: "X\u00e3" },
          { id: "754", name: "T\u00e2n Minh", prefix: "X\u00e3" },
          { id: "755", name: "Thanh Xu\u00e2n", prefix: "X\u00e3" },
          { id: "756", name: "Ti\u00ean D\u01b0\u1ee3c", prefix: "X\u00e3" },
          { id: "757", name: "Trung Gi\u00e3", prefix: "X\u00e3" },
          { id: "758", name: "Vi\u1ec7t Long", prefix: "X\u00e3" },
          { id: "759", name: "Xu\u00e2n Giang", prefix: "X\u00e3" },
          { id: "760", name: "Xu\u00e2n Thu", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "9162", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9163", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9164", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9165", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9166", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9167", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9168", name: "35", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9169",
            name: "\u0110a Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9170",
            name: "\u0110\u1ec1n Gi\u00f3ng",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "9171",
            name: "\u0110\u1ed3ng Lai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9172",
            name: "\u0110\u00f4ng Quan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9173", name: "\u0110T 286", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9174", name: "Kim Anh", prefix: "\u0111\u01b0\u1eddng" },
          { id: "9175", name: "Mi\u1ebfu Th\u1edd", prefix: "ph\u1ed1" },
          {
            id: "9176",
            name: "Nam C\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9177",
            name: "N\u00fai \u0110\u00f4i",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "9178",
            name: "Ph\u00f9 M\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9179",
            name: "Ph\u00fa T\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9180",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9181",
            name: "Qu\u1ed1c l\u1ed9 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9182",
            name: "Qu\u1ed1c L\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9183",
            name: "Qu\u1ed1c l\u1ed9 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9184",
            name: "Qu\u1ed1c L\u1ed9 35",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9185",
            name: "Qu\u1ed1c l\u1ed9 71",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9186",
            name: "Qu\u1ed1c L\u1ed9 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9187",
            name: "T\u00e2n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9188",
            name: "T\u00e2n Tr\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9189",
            name: "Tha\u0323ch L\u00f4\u0303i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9190",
            name: "Th\u00e2n Nh\u00e2n Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9191",
            name: "Th\u1eafng Tr\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9192", name: "Thanh H\u00e0", prefix: "Ph\u1ed1" },
          {
            id: "9193",
            name: "T\u1ec9nh l\u1ed9 131",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9194",
            name: "T\u1ec9nh l\u1ed9 135",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9195",
            name: "T\u1ec9nh l\u1ed9 270",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9196",
            name: "T\u1ec9nh l\u1ed9 296",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9197",
            name: "T\u1ec9nh L\u1ed9 35",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9198",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9199",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1878",
            name: "KCN N\u1ed9i B\u00e0i",
            lat: "21.232711791992",
            lng: "105.81288909912",
          },
        ],
      },
      {
        id: "47",
        name: "S\u01a1n T\u00e2y",
        wards: [
          { id: "761", name: "C\u1ed5 \u0110\u00f4ng", prefix: "X\u00e3" },
          {
            id: "762",
            name: "\u0110\u01b0\u1eddng L\u00e2m",
            prefix: "X\u00e3",
          },
          { id: "763", name: "Kim S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "764", name: "L\u00ea L\u1ee3i", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "765",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "766",
            name: "Ph\u00fa Th\u1ecbnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "767", name: "Quang Trung", prefix: "Ph\u01b0\u1eddng" },
          { id: "768", name: "S\u01a1n \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "769", name: "S\u01a1n L\u1ed9c", prefix: "Ph\u01b0\u1eddng" },
          { id: "770", name: "Thanh M\u1ef9", prefix: "X\u00e3" },
          { id: "771", name: "Trung H\u01b0ng", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "772",
            name: "Trung S\u01a1n Tr\u1ea7m",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "773", name: "Vi\u00ean S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "774", name: "Xu\u00e2n Khanh", prefix: "Ph\u01b0\u1eddng" },
          { id: "775", name: "Xu\u00e2n S\u01a1n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "9200", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9201", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9202", name: "416", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9203", name: "418", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9204", name: "87", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9205", name: "88", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9206", name: "916", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9207",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9208",
            name: "C\u1ea7u Tr\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9209", name: "Ch\u00f9a Th\u00f4ng", prefix: "ph\u1ed1" },
          {
            id: "9210",
            name: "\u0110\u00e1 B\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9211",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9212", name: "\u0110\u1ed1c Ng\u1eef", prefix: "Ph\u1ed1" },
          {
            id: "9213",
            name: "\u0110\u1ed3i Ch\u00e8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9214",
            name: "\u0110\u1ed3i D\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9215",
            name: "\u0110\u01b0\u1eddng b\u00ea t\u00f4ng Cao Trung",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "9216",
            name: "\u0110\u01b0\u1eddng L\u00e2m",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "9217",
            name: "H\u1ed3 Ch\u00ed Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9218",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9219",
            name: "H\u1ed3ng H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9220",
            name: "H\u1eefu Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9221", name: "La Th\u00e0nh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9222",
            name: "L\u1ea1c S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9223",
            name: "L\u00e1ng H\u00f2a L\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9224",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9225",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "9226",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9227",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9228",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9229",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9230",
            name: "Ph\u00f3 \u0110\u1ee9c Ch\u00ednh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "9231",
            name: "Ph\u1ed1 H\u00e0ng",
            prefix: "\u0111\u01b0\u1eddng",
          },
          { id: "9232", name: "Ph\u00fa H\u00e0", prefix: "Ph\u1ed1" },
          { id: "9233", name: "Ph\u00fa Nhi", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9234", name: "Ph\u00f9 Sa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9235",
            name: "Ph\u00fa Th\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9236", name: "Ph\u00f9 Xa", prefix: "Ph\u1ed1" },
          {
            id: "9237",
            name: "Ph\u00f9ng Kh\u1eafc Khoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9238", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9239",
            name: "Qu\u1ed1c l\u1ed9 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9240",
            name: "Qu\u1ed1c l\u1ed9 21A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9241",
            name: "Qu\u1ed1c l\u1ed9 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9242",
            name: "Qu\u1ed1c l\u1ed9 71",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9243", name: "Thanh V\u1ecb", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9244", name: "Thu\u1ea7n Ngh\u1ec7", prefix: "Ph\u1ed1" },
          {
            id: "9245",
            name: "T\u1ec9nh l\u1ed9 414",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9246",
            name: "T\u1ec9nh l\u1ed9 416",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9247",
            name: "T\u1ec9nh l\u1ed9 73",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9248",
            name: "T\u1ec9nh l\u1ed9 84",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9249",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9250",
            name: "Trung S\u01a1n Tr\u1ea7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9251",
            name: "Tr\u01b0ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9252",
            name: "T\u00f9ng Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9253", name: "V\u00e2n Gia", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9254",
            name: "V\u1ecb Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9255",
            name: "V\u0129nh Th\u1ee5y",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1879",
            name: "Khu \u0111\u00f4 th\u1ecb m\u1edbi Ph\u00fa Th\u1ecbnh",
            lat: "21.136680603027",
            lng: "105.5133972168",
          },
          {
            id: "1880",
            name: "Mai Trai Ngh\u0129a Ph\u1ee7",
            lat: "21.136680603027",
            lng: "105.5133972168",
          },
          {
            id: "1881",
            name: "Thu\u1ea7n Ngh\u1ec7 Green City",
            lat: "21.1363697052",
            lng: "105.50961303711",
          },
        ],
      },
      {
        id: "48",
        name: "T\u00e2y H\u1ed3",
        wards: [
          { id: "776", name: "B\u01b0\u1edfi", prefix: "Ph\u01b0\u1eddng" },
          { id: "777", name: "Nh\u1eadt T\u00e2n", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "778",
            name: "Ph\u00fa Th\u01b0\u1ee3ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "779", name: "Qu\u1ea3ng An", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "780",
            name: "Th\u1ee5y Khu\u00ea",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "781", name: "T\u1ee9 Li\u00ean", prefix: "Ph\u01b0\u1eddng" },
          { id: "782", name: "Xu\u00e2n La", prefix: "Ph\u01b0\u1eddng" },
          { id: "783", name: "Y\u00ean Ph\u1ee5", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "9256", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9257",
            name: "An D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9258",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9259",
            name: "An Ph\u00fa \u0110\u00f4ng 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9260",
            name: "An Th\u00e0nh 2",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "9261",
            name: "Anh Th\u00e0nh 1",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "9262",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9263",
            name: "B\u01b0\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9266", name: "D\u1ed1c Tam \u0110a", prefix: "ph\u1ed1" },
          {
            id: "9264",
            name: "\u0110\u1eb7ng Thai Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9265",
            name: "\u0110\u00ea Quai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9267",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9268",
            name: "H\u1eadu L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9269",
            name: "Hi\u1ec7p Th\u00e0nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9270", name: "H\u1ed3 Sen", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9271",
            name: "H\u1ed3 T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9272",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9273",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9274",
            name: "H\u1ed3ng H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9275",
            name: "L\u1ea1c Ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9276",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9277",
            name: "L\u1ed9c H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9278",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9279",
            name: "Mai Xu\u00e2n Th\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9280",
            name: "Minh H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9281",
            name: "M\u01b0\u01a1ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9282", name: "Nghi T\u00e0m", prefix: "\u0111\u01b0\u1eddng" },
          {
            id: "9283",
            name: "Nguy\u1ec5n \u0110\u00ecnh Thi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9284",
            name: "Nguy\u1ec5n Ho\u00e0ng T\u00f4n",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "9285",
            name: "Nguy\u1ec5n Huy Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9286",
            name: "Nguy\u1ec5n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9287",
            name: "Nguy\u1ec5n L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9288",
            name: "Nguy\u1ec5n V\u0103n Huy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9289",
            name: "Nh\u1eadt Chi\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9290",
            name: "N\u01b0\u1edbc Ph\u1ea7n Lan",
            prefix: "\u0111\u01b0\u1eddng",
          },
          { id: "9291", name: "Ph\u00fa Gia", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9292",
            name: "Ph\u00fa X\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9293", name: "Ph\u00fac Hoa", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9294", name: "Qu\u1ea3ng An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9295",
            name: "Qu\u1ea3ng B\u00e1",
            prefix: "\u0111\u01b0\u1eddng",
          },
          { id: "9296", name: "Qu\u1ea3ng Kh\u00e1nh", prefix: "Ph\u1ed1" },
          {
            id: "9297",
            name: "T\u00e2n \u1ea4p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9298",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9299",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9300",
            name: "T\u00e2y H\u1ed3",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "9301",
            name: "Th\u1ea1nh L\u1ed9c 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9302",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9303",
            name: "Th\u1ea1nh Xu\u00e2n 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9304",
            name: "Th\u01b0\u1ee3ng Th\u1ee5y",
            prefix: "Ph\u1ed1",
          },
          {
            id: "9305",
            name: "Th\u1ee5y Khu\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9306",
            name: "T\u00f4 L\u1ecbch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9307",
            name: "T\u00f4 Ng\u1ecdc V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9308",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9309", name: "Tr\u1ea5n V\u0169", prefix: "Ph\u1ed1" },
          {
            id: "9310",
            name: "Tr\u00edch S\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9311",
            name: "Tr\u1ecbnh C\u00f4ng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9312", name: "T\u1eeb Hoa", prefix: "Ph\u1ed1" },
          {
            id: "9313",
            name: "T\u1ee9 Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9314", name: "V\u0103n Cao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9315",
            name: "V\u00e0nh \u0110ai 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9316",
            name: "V\u1ec7 H\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9317", name: "Ven H\u1ed3", prefix: "\u0111\u01b0\u1eddng" },
          {
            id: "9318",
            name: "V\u00f5 Ch\u00ed C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9319",
            name: "V\u00f5ng Th\u1ecb",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "9320",
            name: "X\u00f3m Ch\u00f9a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9321",
            name: "Xu\u00e2n Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9322",
            name: "Xu\u00e2n \u0110\u1ec9nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9323", name: "Xu\u00e2n La", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9324", name: "Y\u00ean Hoa", prefix: "Ph\u1ed1" },
          {
            id: "9325",
            name: "Y\u00ean Ph\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1882",
            name: "249A Th\u1ee5y Khu\u00ea",
            lat: "21.04335975647",
            lng: "105.81848907471",
          },
          {
            id: "1883",
            name: "6th Element",
            lat: "21.053024291992",
            lng: "105.79863739014",
          },
          {
            id: "1884",
            name: "Chung c\u01b0 Xu\u00e2n La",
            lat: "21.065666198731",
            lng: "105.80699920654",
          },
          {
            id: "1885",
            name: "Ciputra H\u00e0 N\u1ed9i",
            lat: "21.072135925293",
            lng: "105.81099700928",
          },
          {
            id: "1886",
            name: "D' EL Dorado",
            lat: "21.069709777832",
            lng: "105.81121063232",
          },
          {
            id: "1887",
            name: "D\u2019. Le Roi Soleil - Qu\u1ea3ng An",
            lat: "21.06489944458",
            lng: "105.82704925537",
          },
          {
            id: "1888",
            name: "Dream Land Xu\u00e2n La",
            lat: "21.063484191894",
            lng: "105.80693817139",
          },
          {
            id: "1889",
            name: "Ecolife T\u00e2y H\u1ed3",
            lat: "21.051111221314",
            lng: "105.80059051514",
          },
          {
            id: "1890",
            name: "Five Stars Westlake",
            lat: "21.043590545654",
            lng: "105.821144104",
          },
          {
            id: "1891",
            name: "Golden Westlake",
            lat: "21.043420791626",
            lng: "105.8215637207",
          },
          {
            id: "1892",
            name: "Hancom 603 L\u1ea1c Long Qu\u00e2n",
            lat: "21.06781578064",
            lng: "105.80813598633",
          },
          {
            id: "1893",
            name: "Hanoi Flower Villages",
            lat: "21.041961669922",
            lng: "105.83068084717",
          },
          {
            id: "1894",
            name: "Hanoi Lake View",
            lat: "21.047048568726",
            lng: "105.83693695068",
          },
          {
            id: "1895",
            name: "HIPT",
            lat: "21.042850494385",
            lng: "105.8240814209",
          },
          {
            id: "1896",
            name: "H\u1ecdc Vi\u1ec7n Qu\u1ed1c Ph\u00f2ng",
            lat: "21.046695709229",
            lng: "105.80083465576",
          },
          {
            id: "1897",
            name: "K\u0110T T\u00e2y H\u1ed3 T\u00e2y - Starlake H\u00e0 N\u1ed9i",
            lat: "21.055467605591",
            lng: "105.79588317871",
          },
          {
            id: "1898",
            name: "Khu t\u00e1i \u0111\u1ecbnh c\u01b0 Xu\u00e2n La",
            lat: "21.059675216675",
            lng: "105.80407714844",
          },
          {
            id: "1899",
            name: "L\u1ea1c H\u1ed3ng Westlake",
            lat: "21.083349227905",
            lng: "105.81072998047",
          },
          {
            id: "1900",
            name: "Machinco Building",
            lat: "21.043787002564",
            lng: "105.81356048584",
          },
          {
            id: "1901",
            name: "M\u00f9a Xu\u00e2n",
            lat: "21.061840057373",
            lng: "105.78378295898",
          },
          {
            id: "1902",
            name: "Ng\u1ecdc Linh Building",
            lat: "21.044387817383",
            lng: "105.81483459473",
          },
          {
            id: "1903",
            name: "Oriental Westlake",
            lat: "21.051906585693",
            lng: "105.80905914307",
          },
          {
            id: "1904",
            name: "Packexim",
            lat: "21.085641860962",
            lng: "105.81288909912",
          },
          {
            id: "1905",
            name: "Packexim 2 T\u00e2y H\u1ed3",
            lat: "21.087865829468",
            lng: "105.81491088867",
          },
          {
            id: "1906",
            name: "PentStudio",
            lat: "21.064092636108",
            lng: "105.80973815918",
          },
          {
            id: "1907",
            name: "Ph\u00fa Th\u01b0\u1ee3ng",
            lat: "21.088907241821",
            lng: "105.79418182373",
          },
          {
            id: "1908",
            name: "PVI T\u00e2y H\u1ed3 T\u00e2y",
            lat: "21.054058074951",
            lng: "105.79718017578",
          },
          {
            id: "1909",
            name: "Romantic Park T\u00e2y H\u1ed3 T\u00e2y",
            lat: "21.050905227661",
            lng: "105.79621124268",
          },
          {
            id: "1910",
            name: "Somerset West Lake",
            lat: "21.043357849121",
            lng: "105.81797790527",
          },
          {
            id: "1911",
            name: "Somerset West Point",
            lat: "21.058658599854",
            lng: "105.82552337647",
          },
          {
            id: "1912",
            name: "Sun Grand City",
            lat: "21.041172027588",
            lng: "105.82509613037",
          },
          {
            id: "1913",
            name: "Sun Grand City",
            lat: "21.064376831055",
            lng: "105.82263946533",
          },
          {
            id: "1914",
            name: "Sunshine Riverside",
            lat: "21.081007003784",
            lng: "105.81809234619",
          },
          {
            id: "1915",
            name: "Syrena Building",
            lat: "21.064247131348",
            lng: "105.8279800415",
          },
          {
            id: "1916",
            name: "T\u00e2y H\u1ed3 Residence",
            lat: "21.055543899536",
            lng: "105.80513000488",
          },
          {
            id: "1917",
            name: "T\u00e2y H\u1ed3 River View",
            lat: "21.088279724121",
            lng: "105.80040740967",
          },
          {
            id: "1918",
            name: "Udic Westlake",
            lat: "21.063966751099",
            lng: "105.80550384522",
          },
          {
            id: "1919",
            name: "Vinaconex No.2",
            lat: "21.073984146118",
            lng: "105.81289672852",
          },
          {
            id: "1920",
            name: "V\u01b0\u1eddn \u0110\u00e0o",
            lat: "21.079299926758",
            lng: "105.81423950195",
          },
        ],
      },
      {
        id: "49",
        name: "Th\u1ea1ch Th\u1ea5t",
        wards: [
          { id: "784", name: "B\u00ecnh Ph\u00fa", prefix: "X\u00e3" },
          { id: "785", name: "B\u00ecnh Y\u00ean", prefix: "X\u00e3" },
          { id: "786", name: "C\u1ea9m Y\u00ean", prefix: "X\u00e3" },
          { id: "787", name: "C\u1ea7n Ki\u1ec7m", prefix: "X\u00e3" },
          { id: "788", name: "Canh N\u1eadu", prefix: "X\u00e3" },
          { id: "789", name: "Ch\u00e0ng S\u01a1n", prefix: "X\u00e3" },
          { id: "791", name: "D\u1ecb N\u1eadu", prefix: "X\u00e3" },
          {
            id: "790",
            name: "\u0110\u1ea1i \u0110\u1ed3ng",
            prefix: "X\u00e3",
          },
          { id: "792", name: "\u0110\u1ed3ng Tr\u00fac", prefix: "X\u00e3" },
          { id: "793", name: "H\u1ea1 B\u1eb1ng", prefix: "X\u00e3" },
          { id: "794", name: "H\u01b0\u01a1ng Ng\u1ea3i", prefix: "X\u00e3" },
          { id: "795", name: "H\u1eefu B\u1eb1ng", prefix: "X\u00e3" },
          { id: "796", name: "Kim Quan", prefix: "X\u00e3" },
          { id: "797", name: "L\u1ea1i Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "798", name: "Li\u00ean Quan", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "799", name: "Ph\u00fa Kim", prefix: "X\u00e3" },
          { id: "800", name: "Ph\u00f9ng X\u00e1", prefix: "X\u00e3" },
          { id: "801", name: "T\u00e2n X\u00e3", prefix: "X\u00e3" },
          { id: "802", name: "Th\u1ea1ch H\u00f2a", prefix: "X\u00e3" },
          { id: "803", name: "Th\u1ea1ch X\u00e1", prefix: "X\u00e3" },
          { id: "804", name: "Ti\u1ebfn Xu\u00e2n", prefix: "X\u00e3" },
          { id: "805", name: "Y\u00ean B\u00ecnh", prefix: "X\u00e3" },
          { id: "806", name: "Y\u00ean Trung", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "9326", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9327", name: "419", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9328", name: "46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9329", name: "8/3", prefix: "Ph\u1ed1" },
          { id: "9330", name: "80", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9331", name: "84", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9332",
            name: "C\u1ea7n Th\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9333",
            name: "\u0110\u1ea1i l\u1ed9 Th\u0103ng Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9334",
            name: "\u0110\u1ed3ng Cam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9335",
            name: "\u0110\u1ed3ng V\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9336", name: "\u0110T 446", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9337",
            name: "Ph\u1ed1 C\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9338",
            name: "Ph\u00fac Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9339",
            name: "Qu\u1ed1c l\u1ed9 21A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9340",
            name: "Qu\u1ed1c l\u1ed9 21B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9341",
            name: "T\u1ec9nh l\u1ed9 419",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9342",
            name: "T\u1ec9nh l\u1ed9 420",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9343",
            name: "T\u1ec9nh L\u1ed9 446",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9344",
            name: "T\u1ec9nh l\u1ed9 84",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1921",
            name: "Asean Resort",
            lat: "21.032356262207",
            lng: "105.49690246582",
          },
          {
            id: "1922",
            name: "B\u1eafc Ph\u00fa C\u00e1t",
            lat: "20.990524291992",
            lng: "105.52519226074",
          },
          {
            id: "1923",
            name: "CNC Viettel",
            lat: "20.99728012085",
            lng: "105.53899383545",
          },
          {
            id: "1924",
            name: "K\u0110T Ph\u00fa C\u00e1t City",
            lat: "20.980834960938",
            lng: "105.53361511231",
          },
          {
            id: "1925",
            name: "Th\u1ea1ch Th\u1ea5t Qu\u1ed1c Oai",
            lat: "21.004442214966",
            lng: "105.63063049316",
          },
          {
            id: "1926",
            name: "Xanh Villas",
            lat: "20.990604400635",
            lng: "105.48683166504",
          },
        ],
      },
      {
        id: "50",
        name: "Thanh Oai",
        wards: [
          { id: "807", name: "B\u00edch H\u00f2a", prefix: "X\u00e3" },
          { id: "808", name: "B\u00ecnh Minh", prefix: "X\u00e3" },
          { id: "809", name: "Cao D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "810", name: "Cao Vi\u00ean", prefix: "X\u00e3" },
          { id: "811", name: "C\u1ef1 Kh\u00ea", prefix: "X\u00e3" },
          { id: "812", name: "D\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "813", name: "\u0110\u1ed7 \u0110\u1ed9ng", prefix: "X\u00e3" },
          { id: "814", name: "H\u1ed3ng D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "815", name: "Kim An", prefix: "X\u00e3" },
          { id: "816", name: "Kim B\u00e0i", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "817", name: "Kim Th\u01b0", prefix: "X\u00e3" },
          { id: "818", name: "Li\u00ean Ch\u00e2u", prefix: "X\u00e3" },
          { id: "819", name: "M\u1ef9 H\u01b0ng", prefix: "X\u00e3" },
          { id: "820", name: "Ph\u01b0\u01a1ng Trung", prefix: "X\u00e3" },
          { id: "821", name: "Tam H\u01b0ng", prefix: "X\u00e3" },
          { id: "822", name: "T\u00e2n \u01af\u1edbc", prefix: "X\u00e3" },
          { id: "823", name: "Thanh Cao", prefix: "X\u00e3" },
          { id: "824", name: "Thanh Mai", prefix: "X\u00e3" },
          { id: "825", name: "Thanh Th\u00f9y", prefix: "X\u00e3" },
          { id: "826", name: "Thanh V\u0103n", prefix: "X\u00e3" },
          { id: "827", name: "Xu\u00e2n D\u01b0\u01a1ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "9345", name: "21B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9346", name: "Kim Ch\u00e2u", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9347",
            name: "Qu\u1ed1c l\u1ed9 21B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9348",
            name: "Qu\u1ed1c l\u1ed9 71",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9349",
            name: "Th\u1ea1ch B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9350",
            name: "T\u1ec9nh l\u1ed9 427",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "51",
        name: "Thanh Tr\u00ec",
        wards: [
          { id: "830", name: "Duy\u00ean H\u00e0", prefix: "X\u00e3" },
          { id: "828", name: "\u0110\u1ea1i \u00c1ng", prefix: "X\u00e3" },
          { id: "829", name: "\u0110\u00f4ng M\u1ef9", prefix: "X\u00e3" },
          { id: "831", name: "H\u1eefu H\u00f2a", prefix: "X\u00e3" },
          { id: "832", name: "Li\u00ean Ninh", prefix: "X\u00e3" },
          { id: "833", name: "Ng\u1ecdc H\u1ed3i", prefix: "X\u00e3" },
          { id: "834", name: "Ng\u0169 Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "835", name: "T\u1ea3 Thanh Oai", prefix: "X\u00e3" },
          { id: "836", name: "Tam Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "837", name: "T\u00e2n Tri\u1ec1u", prefix: "X\u00e3" },
          { id: "838", name: "Thanh Li\u1ec7t", prefix: "X\u00e3" },
          { id: "839", name: "T\u1ee9 Hi\u1ec7p", prefix: "X\u00e3" },
          {
            id: "840",
            name: "V\u0103n \u0110i\u1ec3n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "841", name: "V\u1ea1n Ph\u00fac", prefix: "X\u00e3" },
          { id: "842", name: "V\u0129nh Qu\u1ef3nh", prefix: "X\u00e3" },
          { id: "843", name: "Y\u00ean M\u1ef9", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "9351", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9352", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9353", name: "70", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9354", name: "70A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9355", name: "70B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9356",
            name: "B\u1eb1ng Li\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9357",
            name: "C\u1ea7u B\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9358",
            name: "C\u1ea7u B\u01b0\u01a1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9359",
            name: "C\u1ea7u D\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9360",
            name: "C\u1ea7u T\u00f3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9361",
            name: "Chi\u1ebfn Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9362",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9363",
            name: "C\u1ed5 \u0110i\u1ec3n A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9364",
            name: "C\u1ed5 \u0110i\u1ec3n B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9365",
            name: "C\u1ed9ng H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9366",
            name: "C\u01b0\u01a1ng Ng\u00f4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9367",
            name: "\u0110\u1ea1i Khang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9368",
            name: "\u0110\u00ea Quai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9369",
            name: "\u0110\u00f4ng M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9370",
            name: "\u0110\u1ed3ng Tr\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9371",
            name: "H\u1eefu H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9372",
            name: "H\u1eefu L\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9373",
            name: "H\u1eefu Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9374",
            name: "Hu\u1ef3nh Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9375", name: "Kim Giang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9376",
            name: "L\u1ea1c Th\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9377",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9378",
            name: "L\u01b0u Ph\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9379",
            name: "Nghi\u00eam Xu\u00e2n Y\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9380",
            name: "Ng\u1ecdc H\u1ed3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9381",
            name: "Ng\u0169 Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9382",
            name: "Ng\u0169 Nh\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9383",
            name: "Nguy\u1ec5n Kho\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9384",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9385",
            name: "Nguy\u1ec5n Xi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9386",
            name: "Nh\u1ecb Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9387",
            name: "Phan Tr\u1ecdng Tu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9388",
            name: "Ph\u00e1p V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9389",
            name: "Qu\u1ed1c B\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9390",
            name: "Qu\u1ed1c L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9391",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9392",
            name: "Qu\u1ed1c l\u1ed9 1B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9393",
            name: "Qu\u1ed1c l\u1ed9 70",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9394",
            name: "Qu\u1ef3nh \u0110\u00f4",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "9395",
            name: "Si\u00eau Qu\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9396",
            name: "T\u1ea3 Thanh Oai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9397",
            name: "T\u00e2n Tri\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9398", name: "Thanh \u0110\u00e0m", prefix: "Ph\u1ed1" },
          {
            id: "9399",
            name: "Thanh L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9400",
            name: "Thanh Li\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9401", name: "Th\u1ecd Am", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9402",
            name: "Th\u01b0\u1ee3ng Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9403",
            name: "Tr\u1ea7n Th\u1ee7 \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9404",
            name: "Tranh Kh\u00fac",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "9405",
            name: "Tri\u1ec1u Kh\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9406",
            name: "T\u1ee9 Hi\u1ec7p",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "9407",
            name: "T\u1ef1 Kho\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9408",
            name: "T\u01b0\u01a1ng Chu\u0301c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9409",
            name: "T\u1ef1u Li\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9410",
            name: "V\u0103n \u0110i\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9411",
            name: "V\u00e0nh \u0110ai 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9412", name: "V\u0129nh Ninh", prefix: "Ph\u1ed1" },
          {
            id: "9413",
            name: "V\u0129nh Qu\u1ef3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9414",
            name: "V\u0129nh Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9415",
            name: "V\u00f2ng C\u1ea7u Ni\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9416",
            name: "Y\u00ean Ng\u01b0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9417", name: "Y\u00ean Ph\u00fa", prefix: "Ph\u1ed1" },
          {
            id: "9418",
            name: "Y\u00ean X\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1927",
            name: "C\u1ea7u B\u01b0\u01a1u",
            lat: "20.961641311646",
            lng: "105.80349731445",
          },
          {
            id: "1928",
            name: "Chung c\u01b0 \u0110\u1ea1i Thanh",
            lat: "20.95757484436",
            lng: "105.80690002441",
          },
          {
            id: "1929",
            name: "Chung c\u01b0 Vi\u1ec7n 103",
            lat: "20.968753814697",
            lng: "105.79210662842",
          },
          {
            id: "1930",
            name: "\u0110\u1ea1i Thanh",
            lat: "20.955715179443",
            lng: "105.80709075928",
          },
          {
            id: "1931",
            name: "\u0110\u1ed3ng Ph\u00e1t Resident",
            lat: "20.943445205689",
            lng: "105.84343719482",
          },
          {
            id: "1932",
            name: "Eco Dream",
            lat: "20.983062744141",
            lng: "105.80908203125",
          },
          {
            id: "1933",
            name: "Eco Green City",
            lat: "20.983098983765",
            lng: "105.80816650391",
          },
          {
            id: "1934",
            name: "Eco Spring",
            lat: "20.984567642212",
            lng: "105.80766296387",
          },
          {
            id: "1935",
            name: "Galaxy Tower",
            lat: "20.968086242676",
            lng: "105.82126617432",
          },
          {
            id: "1936",
            name: "HDB Plaza",
            lat: "20.967420578003",
            lng: "105.81435394287",
          },
          {
            id: "1937",
            name: "H\u1ed3ng H\u00e0 Eco City",
            lat: "20.943979263306",
            lng: "105.85543823242",
          },
          {
            id: "1938",
            name: "Housinco Grand Tower",
            lat: "20.97120475769",
            lng: "105.8010559082",
          },
          {
            id: "1939",
            name: "Minh Vi\u1ec7t Ng\u1ecdc H\u1ed3i",
            lat: "20.918281555176",
            lng: "105.84370422363",
          },
          {
            id: "1940",
            name: "Tabudec Plaza",
            lat: "20.958566665649",
            lng: "105.80219268799",
          },
          {
            id: "1941",
            name: "Tecco T\u1ee9 Hi\u1ec7p",
            lat: "20.929824829102",
            lng: "105.85433197022",
          },
          {
            id: "1942",
            name: "The Eden Rose",
            lat: "20.967420578003",
            lng: "105.81435394287",
          },
          {
            id: "1943",
            name: "Tincom Ph\u00e1p V\u00e2n",
            lat: "20.950983047485",
            lng: "105.84704589844",
          },
          {
            id: "1944",
            name: "T\u1ed5ng c\u1ee5c 5 T\u00e2n Tri\u00ea\u0300u",
            lat: "20.969627380371",
            lng: "105.79921722412",
          },
          {
            id: "1945",
            name: "T\u1ee9 Hi\u1ec7p Plaza",
            lat: "20.952589035034",
            lng: "105.85185241699",
          },
          {
            id: "1946",
            name: "Vi\u1ec7n b\u1ecfng L\u00ea H\u1eefu Tr\u00e1c",
            lat: "20.964401245117",
            lng: "105.79333496094",
          },
        ],
      },
      {
        id: "52",
        name: "Thanh Xu\u00e2n",
        wards: [
          {
            id: "844",
            name: "H\u1ea1 \u0110\u00ecnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "845",
            name: "Kh\u01b0\u01a1ng \u0110\u00ecnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "846",
            name: "Kh\u01b0\u01a1ng Mai",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "847",
            name: "Kh\u01b0\u01a1ng Trung",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "848", name: "Kim Giang", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "849",
            name: "Nh\u00e2n Ch\u00ednh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "850",
            name: "Ph\u01b0\u01a1ng Li\u1ec7t",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "851",
            name: "Thanh Xu\u00e2n B\u1eafc",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "852",
            name: "Thanh Xu\u00e2n Nam",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "853",
            name: "Thanh Xu\u00e2n Trung",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "854",
            name: "Th\u01b0\u1ee3ng \u0110\u00ecnh",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          {
            id: "9419",
            name: "B\u00f9i X\u01b0\u01a1ng Tr\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9420",
            name: "C\u1ea7u L\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9421", name: "Ch\u00ednh Kinh", prefix: "Ph\u1ed1" },
          {
            id: "9422",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9423", name: "C\u1ef1 L\u1ed9c", prefix: "Ph\u1ed1" },
          {
            id: "9424",
            name: "\u0110\u1ecbnh C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9425",
            name: "\u0110\u1ecbnh C\u00f4ng Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9426",
            name: "Gi\u1ea3i Ph\u00f3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9427",
            name: "Gi\u00e1p Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9428", name: "H\u1ea1 \u0110\u00ecnh", prefix: "Ph\u1ed1" },
          {
            id: "9429",
            name: "H\u1ed3 R\u00f9a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9430",
            name: "Ho\u00e0ng \u0110\u1ea1o Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9431",
            name: "Ho\u00e0ng \u0110\u1ea1o Th\u00fay",
            prefix: "Ph\u1ed1",
          },
          {
            id: "9432",
            name: "Ho\u00e0ng Minh Gi\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9433",
            name: "Ho\u00e0ng Ng\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9434",
            name: "Ho\u00e0ng V\u0103n Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9435",
            name: "H\u1ed3ng Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9436",
            name: "Khu\u1ea5t Duy Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9437",
            name: "Kh\u01b0\u01a1ng \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9438",
            name: "Kh\u01b0\u01a1ng \u0110\u00ecnh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9439", name: "Kh\u01b0\u01a1ng H\u1ea1", prefix: "Ph\u1ed1" },
          {
            id: "9440",
            name: "Kh\u01b0\u01a1ng H\u1ea1 M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9441",
            name: "Kh\u01b0\u01a1ng Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9442",
            name: "Kh\u01b0\u01a1ng Trung m\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9443", name: "Kim Giang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9444",
            name: "L\u1ea1c H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9445",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "9446",
            name: "L\u00ea V\u0103n L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9447",
            name: "L\u00ea V\u0103n Thi\u00eam",
            prefix: "Ph\u1ed1",
          },
          {
            id: "9448",
            name: "L\u01b0\u01a1ng Ng\u1ecdc Quy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9449",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9450",
            name: "Ng\u1ee5y Nh\u01b0 Kon Tum",
            prefix: "Ph\u1ed1",
          },
          {
            id: "9451",
            name: "Nguy\u1ec5n Huy T\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9452",
            name: "Nguy\u1ec5n L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9453",
            name: "Nguy\u1ec5n Ng\u1ecdc N\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9454",
            name: "Nguy\u1ec5n Ng\u1ecdc V\u0169",
            prefix: "Ph\u1ed1",
          },
          {
            id: "9455",
            name: "Nguy\u1ec5n Qu\u00fd \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9456",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9457",
            name: "Nguy\u1ec5n Th\u1ecb Th\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9458",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9459",
            name: "Nguy\u1ec5n Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9460",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9461",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9462",
            name: "Nguy\u1ec5n Xi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9463", name: "Nh\u00e2n H\u00f2a", prefix: "Ph\u1ed1" },
          {
            id: "9464",
            name: "Ph\u1ea1m \u0110\u00ecnh H\u1ed5",
            prefix: "Ph\u1ed1",
          },
          {
            id: "9465",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9466", name: "Ph\u1ed1 V\u1ecdng", prefix: "Ph\u1ed1" },
          {
            id: "9467",
            name: "Ph\u00f9ng Khoang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9468",
            name: "Ph\u01b0\u01a1ng Li\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          { id: "9469", name: "Quan Nh\u00e2n", prefix: "Ph\u1ed1" },
          {
            id: "9470",
            name: "T\u00e2n H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9471",
            name: "T\u00e2n Tri\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9472",
            name: "Thanh Nh\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9473",
            name: "Th\u01b0\u1ee3ng \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9474",
            name: "T\u1ed1 H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9475",
            name: "T\u00f4 V\u0129nh Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9476", name: "Tr\u1ea7n \u0110i\u1ec1n", prefix: "Ph\u1ed1" },
          {
            id: "9477",
            name: "Tri\u1ec1u Kh\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9478",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9479",
            name: "V\u00e0nh \u0110ai 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9480", name: "V\u0169 H\u1eefu", prefix: "Ph\u1ed1" },
          { id: "9481", name: "V\u0169 T\u00f4ng Phan", prefix: "Ph\u1ed1" },
          {
            id: "9482",
            name: "V\u0169 Tr\u1ecdng Ph\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9483",
            name: "V\u01b0\u01a1ng Th\u1eeba V\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "1947",
            name: "108 Nguy\u1ec5n Tr\u00e3i",
            lat: "21.000017166138",
            lng: "105.81490325928",
          },
          {
            id: "1948",
            name: "282 Nguy\u00ea\u0303n Huy T\u01b0\u01a1\u0309ng",
            lat: "20.996324539185",
            lng: "105.80266571045",
          },
          {
            id: "1949",
            name: "44 Tri\u1ec1u Kh\u00fac",
            lat: "20.9860496521",
            lng: "105.7981338501",
          },
          {
            id: "1950",
            name: "54 H\u1ea1 \u0110\u00ecnh",
            lat: "20.991142272949",
            lng: "105.81041717529",
          },
          {
            id: "1951",
            name: "57 V\u0169 Tr\u1ecdng Ph\u1ee5ng",
            lat: "20.997934341431",
            lng: "105.80813598633",
          },
          {
            id: "1952",
            name: "72 Quan Nh\u00e2n",
            lat: "21.006288528442",
            lng: "105.81175231934",
          },
          {
            id: "1953",
            name: "96 \u0110\u1ecbnh C\u00f4ng",
            lat: "20.984867095947",
            lng: "105.83864593506",
          },
          {
            id: "1954",
            name: "Apartment Building",
            lat: "21.000425338745",
            lng: "105.81442260742",
          },
          {
            id: "1955",
            name: "BRG Park Residence",
            lat: "21.005275726318",
            lng: "105.80550384522",
          },
          {
            id: "1956",
            name: "C3 L\u00ea V\u0103n L\u01b0\u01a1ng (Golden Palace)",
            lat: "21.004455566406",
            lng: "105.80252838135",
          },
          {
            id: "1957",
            name: "Cienco1 Ho\u00e0ng \u0110\u1ea1o Th\u00fay",
            lat: "21.004747390747",
            lng: "105.80617523193",
          },
          {
            id: "1958",
            name: "Cowaelmic 198 Nguy\u1ec5n Tu\u00e2n",
            lat: "21.000984191894",
            lng: "105.8014755249",
          },
          {
            id: "1959",
            name: "C\u1ef1  L\u1ed9c",
            lat: "21.003273010254",
            lng: "105.81109619141",
          },
          {
            id: "1960",
            name: "Diamond Blue 69 Tri\u1ec1u Kh\u00fac",
            lat: "20.984270095825",
            lng: "105.79975891113",
          },
          {
            id: "1961",
            name: "Diamond Flower Tower",
            lat: "21.005578994751",
            lng: "105.80374908447",
          },
          {
            id: "1962",
            name: "Fafilm - VNT Tower",
            lat: "21.002019882202",
            lng: "105.82038879394",
          },
          {
            id: "1963",
            name: "Five Star Kim Giang",
            lat: "20.986000061035",
            lng: "105.81218719482",
          },
          {
            id: "1964",
            name: "Gold Tower",
            lat: "20.993824005127",
            lng: "105.80697631836",
          },
          {
            id: "1965",
            name: "Golden Land",
            lat: "20.993486404419",
            lng: "105.80690765381",
          },
          {
            id: "1966",
            name: "Golden West",
            lat: "21.002038955689",
            lng: "105.80166625977",
          },
          {
            id: "1967",
            name: "GoldSeason",
            lat: "20.994771957398",
            lng: "105.80612945557",
          },
          {
            id: "1968",
            name: "H10 - Vinaconex 6",
            lat: "20.989418029785",
            lng: "105.80193328857",
          },
          {
            id: "1969",
            name: "H\u1ea1 \u0110\u00ecnh Tower",
            lat: "20.991706848144",
            lng: "105.80937957764",
          },
          {
            id: "1970",
            name: "H\u00e0 N\u1ed9i Center Point",
            lat: "21.004817962646",
            lng: "105.80467224121",
          },
          {
            id: "1971",
            name: "Handi Resco L\u00ea v\u0103n L\u01b0\u01a1ng",
            lat: "21.003467559814",
            lng: "105.80244445801",
          },
          {
            id: "1972",
            name: "Hapulico Complex",
            lat: "21.00048828125",
            lng: "105.80715942383",
          },
          {
            id: "1973",
            name: "HDI Homes Nguy\u1ec5n Tu\u00e2n",
            lat: "21.000587463379",
            lng: "105.80127716065",
          },
          {
            id: "1974",
            name: "Hei Tower \u0110i\u1ec7n L\u1ef1c",
            lat: "21.003246307373",
            lng: "105.80506134033",
          },
          {
            id: "1975",
            name: "HUD Tower",
            lat: "21.002099990845",
            lng: "105.80062103272",
          },
          {
            id: "1976",
            name: "Imperia Garden",
            lat: "20.997276306152",
            lng: "105.80298614502",
          },
          {
            id: "1977",
            name: "Imperial Plaza",
            lat: "20.985431671143",
            lng: "105.83934020996",
          },
          {
            id: "1978",
            name: "K\u0110T H\u1ea1 \u0110\u00ecnh",
            lat: "20.985956192017",
            lng: "105.80271911621",
          },
          {
            id: "1979",
            name: "Khang Th\u00f4ng Thanh Xu\u00e2n",
            lat: "20.98390007019",
            lng: "105.79361724853",
          },
          {
            id: "1980",
            name: "Khu nh\u00e0 \u1edf 183 Ho\u00e0ng V\u0103n Th\u00e1i",
            lat: "20.996061325073",
            lng: "105.82344818115",
          },
          {
            id: "1981",
            name: "Legend Tower 109 Nguy\u1ec5n Tu\u00e2n",
            lat: "20.998165130615",
            lng: "105.80150604248",
          },
          {
            id: "1982",
            name: "Licogi 13 Tower",
            lat: "20.998832702637",
            lng: "105.79779815674",
          },
          {
            id: "1983",
            name: "Li\u1ec1n k\u1ec1 96 Nguy\u1ec5n Huy T\u01b0\u1edfng",
            lat: "20.997404098511",
            lng: "105.80406188965",
          },
          {
            id: "1984",
            name: "M\u1ef9 S\u01a1n Tower",
            lat: "20.999387741089",
            lng: "105.80726623535",
          },
          {
            id: "1985",
            name: "Pandora 53 Tri\u1ec1u Kh\u00fac",
            lat: "20.985971450806",
            lng: "105.79833984375",
          },
          {
            id: "1986",
            name: "Pearl Ph\u01b0\u01a1ng Nam Towers",
            lat: "20.998714447021",
            lng: "105.83699798584",
          },
          {
            id: "1987",
            name: "Ph\u00fa Gia Residence",
            lat: "20.999738693237",
            lng: "105.80755615234",
          },
          {
            id: "1988",
            name: "PVV-Vinapharm 60B Nguy\u1ec5n Huy T\u01b0\u1edfng",
            lat: "20.998350143433",
            lng: "105.8063659668",
          },
          {
            id: "1989",
            name: "Rivera Park H\u00e0 N\u1ed9i",
            lat: "20.999118804932",
            lng: "105.80844116211",
          },
          {
            id: "1990",
            name: "Riverside Garden",
            lat: "20.990699768066",
            lng: "105.81427764893",
          },
          {
            id: "1991",
            name: "Royal City",
            lat: "21.002666473389",
            lng: "105.8154296875",
          },
          {
            id: "1992",
            name: "Sakura Tower",
            lat: "20.997611999512",
            lng: "105.80932617188",
          },
          {
            id: "1993",
            name: "Sapphire Palace",
            lat: "20.998561859131",
            lng: "105.81185150147",
          },
          {
            id: "1994",
            name: "Spring Home",
            lat: "20.987712860107",
            lng: "105.83530426025",
          },
          {
            id: "1995",
            name: "Star Tower 283 Kh\u01b0\u01a1ng Trung",
            lat: "20.997150421143",
            lng: "105.82169342041",
          },
          {
            id: "1996",
            name: "Starcity Center",
            lat: "21.005144119263",
            lng: "105.79467010498",
          },
          {
            id: "1997",
            name: "Starcity L\u00ea V\u0103n L\u01b0\u01a1ng",
            lat: "21.006181716919",
            lng: "105.80642700195",
          },
          {
            id: "1998",
            name: "Stellar Garden",
            lat: "21.002185821533",
            lng: "105.80279541016",
          },
          {
            id: "1999",
            name: "T\u00e2n H\u1ed3ng H\u00e0 Complex",
            lat: "21.002180099487",
            lng: "105.8225402832",
          },
          {
            id: "2000",
            name: "Th\u0103ng Long Tower",
            lat: "21.001281738281",
            lng: "105.80447387695",
          },
          {
            id: "2001",
            name: "Th\u00e0nh An Tower",
            lat: "21.007913589477",
            lng: "105.80716705322",
          },
          {
            id: "2002",
            name: "Thanh Xu\u00e2n Complex",
            lat: "20.999294281006",
            lng: "105.80495452881",
          },
          {
            id: "2003",
            name: "The Artemis",
            lat: "21.000082015991",
            lng: "105.82803344727",
          },
          {
            id: "2004",
            name: "The Golden Palm L\u00ea V\u0103n L\u01b0\u01a1ng",
            lat: "21.006311416626",
            lng: "105.8074798584",
          },
          {
            id: "2005",
            name: "Th\u1ed1ng Nh\u1ea5t Complex",
            lat: "20.998046875",
            lng: "105.80354309082",
          },
          {
            id: "2006",
            name: "Th\u01b0\u1ee3ng \u0110\u00ecnh Plaza",
            lat: "20.998079299927",
            lng: "105.81188964844",
          },
          {
            id: "2007",
            name: "Times Tower - HACC1 Complex Building",
            lat: "21.002452850342",
            lng: "105.80119323731",
          },
          {
            id: "2008",
            name: "Tincom City Point",
            lat: "20.995811462402",
            lng: "105.83812713623",
          },
          {
            id: "2009",
            name: "Transmeco",
            lat: "20.998092651367",
            lng: "105.79775238037",
          },
          {
            id: "2010",
            name: "Trung t\u00e2m T\u00e0i ch\u00ednh S\u00f4ng \u0110\u00e0",
            lat: "20.987970352173",
            lng: "105.80030059815",
          },
          {
            id: "2011",
            name: "TTTM H\u1ea1 \u0110\u00ecnh",
            lat: "21.001914978027",
            lng: "105.81887817383",
          },
          {
            id: "2012",
            name: "TTTM Kh\u01b0\u01a1ng \u0110\u00ecnh",
            lat: "20.992830276489",
            lng: "105.81769561768",
          },
          {
            id: "2013",
            name: "VG Building 235 Nguy\u1ec5n Tr\u00e3i",
            lat: "20.993465423584",
            lng: "105.80821990967",
          },
          {
            id: "2014",
            name: "Vicem Comatce Tower",
            lat: "20.999496459961",
            lng: "105.80065917969",
          },
          {
            id: "2015",
            name: "Vi\u1ec7t \u0110\u1ee9c Complex",
            lat: "20.999645233154",
            lng: "105.7993850708",
          },
          {
            id: "2016",
            name: "Vinhomes Smart City",
            lat: "20.996187210083",
            lng: "105.81022644043",
          },
          {
            id: "2017",
            name: "Viwaseen Tower",
            lat: "20.996795654297",
            lng: "105.79288482666",
          },
        ],
      },
      {
        id: "53",
        name: "Th\u01b0\u1eddng T\u00edn",
        wards: [
          {
            id: "855",
            name: "Ch\u01b0\u01a1ng D\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "856", name: "D\u0169ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "857", name: "Duy\u00ean Th\u00e1i", prefix: "X\u00e3" },
          { id: "858", name: "H\u00e0 H\u1ed3i", prefix: "X\u00e3" },
          { id: "859", name: "Hi\u1ec1n Giang", prefix: "X\u00e3" },
          { id: "860", name: "H\u00f2a B\u00ecnh", prefix: "X\u00e3" },
          { id: "861", name: "H\u1ed3ng V\u00e2n", prefix: "X\u00e3" },
          { id: "862", name: "Kh\u00e1nh H\u00e0", prefix: "X\u00e3" },
          { id: "863", name: "L\u00ea L\u1ee3i", prefix: "X\u00e3" },
          { id: "864", name: "Li\u00ean Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "865", name: "Minh C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "866", name: "Nghi\u00eam Xuy\u00ean", prefix: "X\u00e3" },
          { id: "867", name: "Nguy\u1ec5n Tr\u00e3i", prefix: "X\u00e3" },
          { id: "868", name: "Nh\u1ecb Kh\u00ea", prefix: "X\u00e3" },
          { id: "869", name: "Ninh S\u1edf", prefix: "X\u00e3" },
          { id: "870", name: "Qu\u1ea5t \u0110\u1ed9ng", prefix: "X\u00e3" },
          { id: "871", name: "T\u00e2n Minh", prefix: "X\u00e3" },
          { id: "872", name: "Th\u1eafng L\u1ee3i", prefix: "X\u00e3" },
          { id: "873", name: "Th\u1ed1ng Nh\u1ea5t", prefix: "X\u00e3" },
          { id: "874", name: "Th\u01b0 Ph\u00fa", prefix: "X\u00e3" },
          {
            id: "875",
            name: "Th\u01b0\u1eddng T\u00edn",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "876", name: "Ti\u1ec1n Phong", prefix: "X\u00e3" },
          { id: "877", name: "T\u00f4 Hi\u1ec7u", prefix: "X\u00e3" },
          { id: "878", name: "T\u1ef1 Nhi\u00ean", prefix: "X\u00e3" },
          { id: "879", name: "V\u0103n B\u00ecnh", prefix: "X\u00e3" },
          { id: "880", name: "V\u1ea1n \u0110i\u1ec3m", prefix: "X\u00e3" },
          { id: "881", name: "V\u0103n Ph\u00fa", prefix: "X\u00e3" },
          { id: "882", name: "V\u00e2n T\u1ea3o", prefix: "X\u00e3" },
          { id: "883", name: "V\u0103n T\u1ef1", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "9484", name: "71", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9485", name: "Ga", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9486",
            name: "Li\u00ean Th\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9487",
            name: "Li\u1ec5u Ngo\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9488",
            name: "Nguy\u1ec5n Tu\u1ea5n Tr\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9489",
            name: "Qu\u00e1n G\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9490", name: "Que H\u00e0n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9491",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9492",
            name: "Qu\u1ed1c l\u1ed9 1B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9493",
            name: "Qu\u1ed1c l\u1ed9 71",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9494",
            name: "Qu\u1ed1c L\u1ed9 71A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9495", name: "T\u00eda", prefix: "Ph\u1ed1" },
          {
            id: "9496",
            name: "T\u1ec9nh l\u1ed9 427",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9497",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9498", name: "V\u1ea1n Long", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9499",
            name: "V\u0129nh L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9500", name: "V\u00f4i", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "2018",
            name: "Khu \u0111\u00f4 th\u1ecb Duy\u00ean Th\u00e1i",
            lat: "20.898675918579",
            lng: "105.8671951294",
          },
        ],
      },
      {
        id: "54",
        name: "\u1ee8ng H\u00f2a",
        wards: [
          { id: "884", name: "Cao Th\u00e0nh", prefix: "X\u00e3" },
          {
            id: "885",
            name: "\u0110\u1ea1i C\u01b0\u1eddng",
            prefix: "X\u00e3",
          },
          { id: "886", name: "\u0110\u1ea1i H\u00f9ng", prefix: "X\u00e3" },
          { id: "887", name: "\u0110\u1ed9i B\u00ecnh", prefix: "X\u00e3" },
          { id: "888", name: "\u0110\u00f4ng L\u1ed7", prefix: "X\u00e3" },
          { id: "889", name: "\u0110\u1ed3ng T\u00e2n", prefix: "X\u00e3" },
          { id: "890", name: "\u0110\u1ed3ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "891", name: "H\u00f2a L\u00e2m", prefix: "X\u00e3" },
          { id: "892", name: "H\u00f2a Nam", prefix: "X\u00e3" },
          { id: "893", name: "H\u00f2a Ph\u00fa", prefix: "X\u00e3" },
          { id: "894", name: "Hoa S\u01a1n", prefix: "X\u00e3" },
          { id: "895", name: "H\u00f2a X\u00e1", prefix: "X\u00e3" },
          { id: "896", name: "H\u1ed3ng Quang", prefix: "X\u00e3" },
          { id: "897", name: "Kim \u0110\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "898", name: "Li\u00ean B\u1ea1t", prefix: "X\u00e3" },
          { id: "899", name: "L\u01b0u Ho\u00e0ng", prefix: "X\u00e3" },
          { id: "900", name: "Minh \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "901", name: "Ph\u00f9 L\u01b0u", prefix: "X\u00e3" },
          { id: "902", name: "Ph\u01b0\u01a1ng T\u00fa", prefix: "X\u00e3" },
          {
            id: "903",
            name: "Qu\u1ea3ng Ph\u00fa C\u1ea7u",
            prefix: "X\u00e3",
          },
          { id: "904", name: "S\u01a1n C\u00f4ng", prefix: "X\u00e3" },
          { id: "905", name: "T\u00e2n Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          {
            id: "906",
            name: "T\u1ea3o D\u01b0\u01a1ng V\u0103n",
            prefix: "X\u00e3",
          },
          { id: "907", name: "Tr\u1ea7m L\u1ed9ng", prefix: "X\u00e3" },
          { id: "908", name: "Trung T\u00fa", prefix: "X\u00e3" },
          { id: "909", name: "Tr\u01b0\u1eddng Th\u1ecbnh", prefix: "X\u00e3" },
          {
            id: "910",
            name: "V\u00e2n \u0110\u00ecnh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "911", name: "V\u1ea1n Th\u00e1i", prefix: "X\u00e3" },
          { id: "912", name: "Vi\u00ean An", prefix: "X\u00e3" },
          { id: "913", name: "Vi\u00ean N\u1ed9i", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "9501",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9502",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9503",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9504",
            name: "Phan V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9505", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9506",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9507",
            name: "Qu\u1ed1c l\u1ed9 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9508",
            name: "Qu\u1ed1c l\u1ed9 21B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9509",
            name: "Qu\u00f4\u0301c l\u00f4\u0323 75",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9510",
            name: "Th\u00f4n Thanh \u1ea4m",
            prefix: "\u0111\u01b0\u1eddng",
          },
          {
            id: "9511",
            name: "T\u1ec9nh l\u1ed9 424",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9512",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9513",
            name: "Tr\u1ea7n \u0110\u0103ng Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9514",
            name: "V\u0103n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "3",
    code: "DDN",
    name: "\u0110\u00e0 N\u1eb5ng",
    districts: [
      {
        id: "55",
        name: "C\u1ea9m L\u1ec7",
        wards: [
          { id: "914", name: "H\u00f2a An", prefix: "Ph\u01b0\u1eddng" },
          { id: "915", name: "H\u00f2a Ph\u00e1t", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "916",
            name: "H\u00f2a Th\u1ecd \u0110\u00f4ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "917",
            name: "H\u00f2a Th\u1ecd T\u00e2y",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "918", name: "H\u00f2a Xu\u00e2n", prefix: "Ph\u01b0\u1eddng" },
          { id: "919", name: "Khu\u00ea Trung", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "9515", name: "14B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9516", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9517", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9518", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9519", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9520", name: "66", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9521", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9522", name: "A22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9523", name: "An H\u00f2a", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9524", name: "An Ho\u00e0 1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9525",
            name: "An H\u00f2a 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9526",
            name: "An H\u00f2a 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9527",
            name: "An H\u00f2a 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9528", name: "An H\u00f2a 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9529", name: "An H\u00f2a 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9530", name: "An H\u00f2a 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9531", name: "An H\u00f2a 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9532", name: "An H\u00f2a 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9533", name: "An H\u00f2a 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9534", name: "An H\u00f2a 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9535", name: "An H\u00f2a 9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9536",
            name: "An Ph\u00fa \u0110\u00f4ng 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9537",
            name: "An Th\u01b0\u1ee3ng 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9538",
            name: "B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9539",
            name: "B\u00e3i S\u1eady",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9540",
            name: "B\u00e0u C\u1ea7u 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9541",
            name: "B\u00e0u C\u1ea7u 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9542",
            name: "B\u1ea7u C\u1ea7u 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9543",
            name: "B\u1ea7u C\u1ea7u 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9544",
            name: "B\u1ea7u C\u1ea7u 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9545",
            name: "B\u00e0u C\u1ea7u 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9546",
            name: "B\u1ea7u C\u1ea7u 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9547",
            name: "B\u00e0u Gia Th\u01b0\u1ee3ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9548",
            name: "B\u00e0u Gia Th\u01b0\u1ee3ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9549",
            name: "B\u00e0u Gia Th\u01b0\u1ee3ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9550",
            name: "B\u00e0u Gia Th\u01b0\u1ee3ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9551",
            name: "B\u00e0u Tr\u00e0m 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9552",
            name: "B\u00e0u Tr\u00e0m 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9553",
            name: "B\u00e0u Tr\u00e0m 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9554",
            name: "B\u00e0u Tr\u00e0m Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9555",
            name: "B\u00ecnh An 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9556",
            name: "B\u00ecnh H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9557",
            name: "B\u00ecnh H\u00f2a 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9558",
            name: "B\u00ecnh H\u00f2a 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9559",
            name: "B\u00ecnh H\u00f2a 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9560",
            name: "B\u00ecnh H\u00f2a 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9561",
            name: "B\u00ecnh H\u00f2a 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9562",
            name: "B\u00ecnh H\u00f2a 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9563",
            name: "B\u00ecnh H\u00f2a 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9564",
            name: "B\u00ecnh H\u00f2a 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9565",
            name: "B\u00ecnh H\u00f2a 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9566",
            name: "B\u00ecnh Ho\u00e0 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9567",
            name: "B\u00ecnh H\u00f2a 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9568",
            name: "B\u00ecnh H\u00f2a 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9569",
            name: "B\u00ecnh H\u00f2a 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9570",
            name: "B\u00ecnh H\u00f2a 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9571",
            name: "B\u00ecnh Th\u00e1i 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9572",
            name: "B\u00ecnh Th\u00e1i 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9573",
            name: "B\u00ecnh Th\u00e1i 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9574",
            name: "B\u00ecnh Th\u00e1i 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9575",
            name: "B\u00ecnh Th\u00e1i 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9576",
            name: "B\u00f9i Gi\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9577",
            name: "B\u00f9i H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9578",
            name: "B\u00f9i Huy \u0110\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9579",
            name: "B\u00f9i K\u1ef7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9580",
            name: "B\u00f9i T\u1ea5n Di\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9581",
            name: "B\u00f9i T\u1ea5n Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9582",
            name: "B\u00f9i V\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9583",
            name: "B\u00f9i Xu\u00e2n Tr\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9584",
            name: "B\u00f9i Xu\u00e2n T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9585",
            name: "B\u00f9i X\u01b0\u01a1ng Tr\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9586",
            name: "B\u00f9i X\u01b0\u01a1ng T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9587",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9588",
            name: "C\u1ea9m B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9589",
            name: "C\u1ea9m B\u1eafc 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9590",
            name: "C\u1ea9m B\u1eafc 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9591",
            name: "C\u1ea9m B\u1eafc 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9592",
            name: "C\u1ea9m B\u1eafc 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9593",
            name: "C\u1ea9m B\u1eafc 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9594",
            name: "C\u1ea9m B\u1eafc 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9595",
            name: "C\u1ea9m B\u1eafc 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9596",
            name: "C\u1ea9m B\u1eafc 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9597",
            name: "C\u1ea9m B\u1eafc 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9598",
            name: "C\u1ea9m B\u1eafc 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9599",
            name: "C\u1ea9m B\u1eafc 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9600",
            name: "C\u1ea9m B\u1eafc 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9601",
            name: "C\u1ea9m Ch\u00e1nh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9602",
            name: "C\u1ea9m Ch\u00e1nh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9603",
            name: "C\u1ea9m Ch\u00e1nh 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9604",
            name: "C\u1ea9m Ch\u00e1nh 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9605",
            name: "C\u1ea9m Ch\u00e1nh 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9606", name: "C\u1ea9m Nam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9607",
            name: "C\u1ea9m Nam 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9608",
            name: "C\u1ea9m Nam 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9609",
            name: "C\u1ea9m Nam 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9610",
            name: "C\u1ea9m Nam 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9611",
            name: "C\u1ea9m Nam 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9612",
            name: "C\u1ea9m Nam 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9613",
            name: "C\u1ea9m Nam 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9614",
            name: "C\u1ea9m Nam 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9615",
            name: "C\u1ea9m Nam 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9616",
            name: "Cao B\u00e1 \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9617",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9618",
            name: "Cao S\u01a1n Ph\u00e1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9619",
            name: "Cao Xu\u00e2n D\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9620",
            name: "Cao Xu\u00e2n Huy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9621",
            name: "C\u1ea7u \u0110\u1ecf T\u00fay Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9622",
            name: "Ch\u00e2u Th\u1ecb V\u0129nh T\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9623",
            name: "Chu M\u1ea1nh Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9624",
            name: "C\u1ed5 M\u00e2n C\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9625",
            name: "C\u1ed5 M\u00e2n C\u00fac 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9626",
            name: "C\u1ed5 M\u00e2n C\u00fac 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9627",
            name: "C\u1ed5 M\u00e2n C\u00fac 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9628",
            name: "C\u1ed5 M\u00e2n C\u00fac 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9629",
            name: "C\u1ed5 M\u00e2n Lan 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9630",
            name: "C\u1ed5 M\u00e2n Lan 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9631",
            name: "C\u1ed5 M\u00e2n Lan 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9632",
            name: "C\u1ed5 M\u00e2n Lan 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9633",
            name: "C\u1ed5 M\u00e2n Mai 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9634",
            name: "C\u1ed5 M\u00e2n Mai 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9635",
            name: "C\u1ed5 M\u00e2n Mai 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9636",
            name: "C\u1ed5 M\u00e2n Mai 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9637",
            name: "C\u1ed5 M\u00e2n Mai 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9638",
            name: "C\u1ed3n D\u1ea7u 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9639",
            name: "C\u1ed3n D\u1ea7u 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9640",
            name: "C\u1ed3n D\u1ea7u 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9641",
            name: "C\u1ed3n D\u1ea7u 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9642",
            name: "C\u1ed3n D\u1ea7u 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9643",
            name: "C\u1ed3n D\u1ea7u 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9644",
            name: "C\u1ed3n D\u1ea7u 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9645",
            name: "C\u1ed3n D\u1ea7u 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9646",
            name: "C\u1ed3n D\u1ea7u 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9647",
            name: "C\u1ed3n D\u1ea7u 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9648",
            name: "C\u1ed1ng Qu\u1ef3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9649",
            name: "C\u00f9 Lao Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9650", name: "D15", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9691",
            name: "D\u01b0\u01a1ng B\u00e1 Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9692",
            name: "D\u01b0\u01a1ng \u0110\u1ee9c Nhan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9693",
            name: "D\u01b0\u01a1ng Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9694",
            name: "D\u01b0\u01a1ng Qu\u1ea3ng H\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9651",
            name: "\u0110\u1ea1i l\u1ed9 Th\u0103ng Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9652",
            name: "\u0110\u1ea1i L\u1ed9 Trung L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9653",
            name: "\u0110\u00e0m V\u0103n L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9654",
            name: "\u0110\u1eb7ng \u0110\u1ee9c Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9655",
            name: "\u0110\u1eb7ng H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9656",
            name: "\u0110\u1eb7ng Nh\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9657",
            name: "\u0110\u1eb7ng Nh\u01b0 Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9658",
            name: "\u0110\u1eb7ng Thai Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9659",
            name: "\u0110\u1eb7ng V\u0103n Ng\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9660",
            name: "\u0110\u1eb7ng Xu\u00e2n B\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9661",
            name: "\u0110\u00e0o C\u00f4ng Ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9662",
            name: "\u0110\u00e0o Duy Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9663",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9664",
            name: "\u0110inh Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9665",
            name: "\u0110\u00ecnh \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9666", name: "\u0110inh Li\u1ec7t", prefix: "Ph\u1ed1" },
          {
            id: "9667",
            name: "\u0110inh Nh\u1eadt T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9668",
            name: "\u0110inh V\u0103n Ch\u1ea5p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9669",
            name: "\u0110\u1ed7 \u0110\u0103ng \u0110\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9670",
            name: "\u0110\u1ed7 \u0110\u0103ng Tuy\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9671",
            name: "\u0110\u00f4 \u0110\u1ed1c B\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9672",
            name: "\u0110\u00f4 \u0110\u1ed1c L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9673",
            name: "\u0110\u00f4 \u0110\u1ed1c L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9674",
            name: "\u0110\u00f4 \u0110\u1ed1c Tuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9675",
            name: "\u0110\u1ed7 Th\u00fac T\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9676",
            name: "\u0110\u1ed7 T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9677",
            name: "\u0110o\u00e0n H\u1eefu Tr\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9678",
            name: "\u0110o\u00e0n Ng\u1ecdc Nh\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9679",
            name: "\u0110o\u00e0n Nguy\u1ec5n Th\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9680",
            name: "\u0110o\u00e0n Nguy\u1ec5n Tu\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9681",
            name: "\u0110\u1ed9i C\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9682",
            name: "\u0110\u1ed9i Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9683",
            name: "\u0110\u1ed1ng C\u00f4ng Tr\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9684",
            name: "\u0110\u1ed1ng C\u00f4ng T\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9685",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9686",
            name: "\u0110\u00f4ng Th\u1ea1nh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9687",
            name: "\u0110\u00f4ng Th\u1ea1nh 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9688",
            name: "\u0110\u1ed3ng Tr\u00ed 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9689", name: "\u0110T 741", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9690", name: "\u0110T 747", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9695",
            name: "H\u00e0 Duy Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9696",
            name: "H\u00e0 Huy Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9697",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9698",
            name: "H\u00e0 M\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9699",
            name: "H\u00e0 T\u00f4ng Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9700",
            name: "H\u00e0 V\u0103n Tr\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9701",
            name: "H\u00e0n Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9702", name: "H\u00e0ng Cau", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9703",
            name: "H\u00e0ng D\u1eeba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9704",
            name: "H\u1eadu L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9705",
            name: "Hi\u1ec7p Th\u00e0nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9706",
            name: "H\u1ed3 Bi\u1ec3u Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9707",
            name: "H\u1ed3 \u0110\u1eafc Di",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9708",
            name: "H\u1ed3 Ng\u1ecdc L\u00e3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9709",
            name: "H\u1ed3 Nguy\u00ean Tr\u1eebng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9710",
            name: "H\u1ed3 Phi T\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9711",
            name: "H\u1ed3 Qu\u00fd Ly",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9712",
            name: "H\u1ed3 S\u1ef9 D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9713",
            name: "H\u1ed3 Trung L\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9714",
            name: "H\u1ed3 T\u1ef5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9715", name: "H\u00f2a An", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9716", name: "H\u00f2a An 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9717", name: "H\u00f2a An 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9718", name: "H\u00f2a An 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9719", name: "Ho\u00e0 An 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9720", name: "H\u00f2a An 6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9721",
            name: "Ho\u0300a An 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9722", name: "H\u00f2a An 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "9723", name: "H\u00f2a An 9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9724",
            name: "H\u00f3a M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9725",
            name: "H\u00f2a Nam 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9726",
            name: "H\u00f2a Nam 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9727",
            name: "H\u00f2a Nam 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9728",
            name: "H\u00f2a Nam 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9729",
            name: "H\u00f2a Nam 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9730",
            name: "H\u00f2a Nam 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9731",
            name: "H\u00f2a Th\u1ecd \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9732",
            name: "H\u00f2a Xu\u00e2n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9733",
            name: "Ho\u00e0ng B\u00ednh Ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9734",
            name: "Ho\u00e0ng Ch\u00e2u K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9737",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9739",
            name: "Ho\u00e0ng D\u01b0 Kh\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9735",
            name: "Ho\u00e0ng \u0110\u1ea1o Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9736",
            name: "Ho\u00e0ng \u0110\u1ea1o Th\u00fay",
            prefix: "Ph\u1ed1",
          },
          {
            id: "9738",
            name: "Ho\u00e0ng \u0110\u00ecnh \u00c1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9740",
            name: "Ho\u00e0ng K\u1ebf Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9741",
            name: "Ho\u00e0ng Minh Gi\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9742",
            name: "Ho\u00e0ng Ng\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9743",
            name: "Ho\u00e0ng Ng\u1ecdc Ph\u00e1ch",
            prefix: "Ph\u1ed1",
          },
          { id: "9744", name: "Ho\u00e0ng S\u00e2m", prefix: "Ph\u1ed1" },
          {
            id: "9745",
            name: "Ho\u00e0ng T\u0103ng B\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9746",
            name: "Ho\u00e0ng Th\u1ebf Thi\u1ec7n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "9747",
            name: "Ho\u00e0ng Th\u1ecb \u00c1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9748",
            name: "Ho\u00e0ng V\u0103n Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9749",
            name: "Ho\u00e0ng Xu\u00e2n H\u00e3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9750",
            name: "Huy C\u00e2\u0323n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9751",
            name: "Hu\u1ef3nh Ng\u1ecdc \u0110\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9752",
            name: "Hu\u1ef3nh Ng\u1ecdc Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9753",
            name: "Hu\u1ef3nh T\u1ea5n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9754",
            name: "Hu\u1ef3nh T\u1ecbnh C\u1ee7a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9755",
            name: "Hu\u1ef3nh Xu\u00e2n Nh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9756",
            name: "Kh\u1edfi Ngh\u0129a B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9757",
            name: "Kh\u00fac H\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9758",
            name: "Kh\u01b0\u01a1ng H\u1eefu D\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9759",
            name: "Ki\u1ec1u Ph\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9760",
            name: "L\u00e2m Nh\u0129",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9761",
            name: "L\u00e2m Quang Th\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9762",
            name: "L\u00e2m V\u0103n Th\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9763",
            name: "L\u00ea Cao L\u00e3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9769",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9770",
            name: "L\u00ea Duy L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9764",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9765",
            name: "L\u00ea \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9766",
            name: "L\u00ea \u0110\u1ec9nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9767",
            name: "L\u00ea \u0110\u00ecnh Di\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9768",
            name: "L\u00ea \u0110\u00ecnh K\u1ef5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9771",
            name: "L\u00ea Hi\u1ebfn Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9772", name: "L\u00ea Huy", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9773",
            name: "L\u00ea Kim L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9774", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9775",
            name: "L\u00ea Ng\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9776",
            name: "L\u00ea Qu\u1ea3ng Ba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9777",
            name: "L\u00ea Qu\u1ea3ng Ch\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9778",
            name: "L\u00ea Quang \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9779",
            name: "L\u00ea Quang H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9780",
            name: "L\u00ea Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9781",
            name: "L\u00ea Th\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9782", name: "L\u00ea Thanh Ngh\u1ecb", prefix: "Ph\u1ed1" },
          {
            id: "9783",
            name: "L\u00ea Thi\u1ebft H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9784",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "9785",
            name: "L\u00ea Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9786",
            name: "L\u00ea V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9787",
            name: "L\u00ea V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9788",
            name: "L\u00ea V\u0129nh Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9789",
            name: "Li\u00eam L\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9790",
            name: "Li\u00eam L\u1ea1c 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9791",
            name: "Li\u00eam L\u1ea1c 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9792",
            name: "Li\u00eam L\u1ea1c 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9793",
            name: "Li\u00eam L\u1ea1c 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9794",
            name: "Li\u00eam L\u1ea1c 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9795",
            name: "Li\u00eam L\u1ea1c 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9796",
            name: "Li\u00eam L\u1ea1c 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9797",
            name: "Li\u00eam L\u1ea1c 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9798",
            name: "Li\u00eam L\u1ea1c 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9799",
            name: "Li\u00eam L\u1ea1c 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9800",
            name: "Li\u00eam L\u1ea1c 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9801",
            name: "Li\u00eam L\u1ea1c 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9802",
            name: "Li\u00eam L\u1ea1c 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9803",
            name: "Li\u00eam L\u1ea1c 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9804",
            name: "Li\u00eam L\u1ea1c 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9805",
            name: "Li\u00eam L\u1ea1c 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9806",
            name: "Li\u00eam L\u1ea1c 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9807",
            name: "Li\u00ean L\u1ea1c 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9808",
            name: "Li\u00ean L\u1ea1c 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9809",
            name: "L\u1ed7 Gi\u00e1ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9810",
            name: "L\u1ed7 Gi\u00e1ng 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9811",
            name: "L\u1ed7 Gi\u00e1ng 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9812",
            name: "L\u1ed7 Gi\u00e1ng 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9813",
            name: "L\u1ed7 Gi\u00e1ng 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9814",
            name: "L\u1ed7 Gi\u00e1ng 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9815",
            name: "L\u1ed7 Gi\u00e1ng 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9816",
            name: "L\u1ed7 Gi\u00e1ng 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9817",
            name: "L\u1ed7 Gi\u00e1ng 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9818",
            name: "L\u1ed7 Gi\u00e1ng 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9819",
            name: "L\u1ed7 Gi\u00e1ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9820",
            name: "L\u1ed7 Gi\u00e1ng 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9821",
            name: "L\u1ed7 Gi\u00e1ng 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9822",
            name: "L\u1ed7 Gi\u00e1ng 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9823",
            name: "L\u00f4\u0303 Gia\u0301ng 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9824",
            name: "L\u1ed7 Gi\u00e1ng 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9825",
            name: "L\u1ed7 Gi\u00e1ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9826",
            name: "L\u1ed7 Gi\u00e1ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9827",
            name: "L\u1ed7 Gi\u00e1ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9828",
            name: "L\u1ed7 Gi\u00e1ng 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9829",
            name: "L\u1ed7 Gi\u00e1ng 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9830",
            name: "L\u1ed7 Gi\u00e1ng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9831",
            name: "L\u1ed7 Gi\u00e1ng 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9832",
            name: "L\u1ed9c H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9833", name: "L\u01b0 Giang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9834",
            name: "L\u01b0\u01a1ng \u0110\u1ecbnh C\u1ee7a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "9835",
            name: "L\u01b0\u01a1ng Nh\u1eef H\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9836",
            name: "L\u01b0\u01a1ng V\u0103n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9837",
            name: "L\u01b0u Nh\u00e2n Ch\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9838",
            name: "L\u00fd Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9839",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9840",
            name: "L\u00fd T\u1ebf Xuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9841",
            name: "L\u00fd Thi\u00ean B\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9842",
            name: "Mai Am Ti\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9843", name: "Mai Anh Tu\u1ea5n", prefix: "Ph\u1ed1" },
          {
            id: "9844",
            name: "M\u1eb9 Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9845",
            name: "Minh M\u1ea1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9846",
            name: "M\u01b0\u01a1ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9847",
            name: "Ng\u00f4 Chi Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9848",
            name: "Ng\u00f4 Huy Di\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9849",
            name: "Ng\u00f4 M\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9850",
            name: "Ng\u00f4 Nh\u00e2m T\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9851",
            name: "Ng\u00f4 Th\u00e1i L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9852",
            name: "Ng\u00f4 Th\u1ebf L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9853",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9854",
            name: "Nguy\u1ec5n B\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9855",
            name: "Nguy\u1ec5n C\u00f4ng Hoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9856",
            name: "Nguy\u1ec5n C\u00f4ng Tri\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9861",
            name: "Nguy\u1ec5n D\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9863",
            name: "Nguy\u1ec5n Duy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9857",
            name: "Nguy\u1ec5n \u0110\u0103ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9858",
            name: "Nguy\u1ec5n \u0110\u00ecnh T\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9859",
            name: "Nguy\u1ec5n \u0110\u1ed7 M\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9860",
            name: "Nguy\u1ec5n \u0110\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9862",
            name: "Nguy\u1ec5n \u0110\u1ee9c Thi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9864",
            name: "Nguy\u1ec5n H\u00e0m Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9865",
            name: "Nguy\u1ec5n H\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9866",
            name: "Nguy\u1ec5n H\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9867",
            name: "Nguy\u1ec5n H\u00e0ng Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9868",
            name: "Nguy\u1ec5n H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9869",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9870",
            name: "Nguy\u1ec5n H\u1eefu Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9871",
            name: "Nguy\u1ec5n Huy Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9872",
            name: "Nguy\u1ec5n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9873",
            name: "Nguy\u1ec5n Huy O\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9874",
            name: "Nguy\u1ec5n Huy T\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9875",
            name: "Nguy\u1ec5n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9876",
            name: "Nguy\u1ec5n Khoa Chi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9877",
            name: "Nguy\u1ec5n Kim",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9878",
            name: "Nguy\u1ec5n Lai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9879",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9880",
            name: "Nguy\u1ec5n L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9881",
            name: "Nguy\u1ec5n M\u1eadu T\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9882",
            name: "Nguy\u1ec5n M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9883",
            name: "Nguy\u1ec5n Nh\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9884",
            name: "Nguy\u1ec5n Nho T\u00fay",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9885",
            name: "Nguy\u1ec5n Nh\u01b0 \u0110\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9886",
            name: "Nguy\u1ec5n Nh\u01b0 \u0110\u1ecf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9887",
            name: "Nguy\u1ec5n Phong S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9888",
            name: "Nguy\u1ec5n Ph\u00fa H\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9889",
            name: "Nguy\u1ec5n Ph\u01b0\u1edbc Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9890",
            name: "Nguy\u1ec5n Ph\u01b0\u1edbc T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9891",
            name: "Nguy\u1ec5n Quang L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9892",
            name: "Nguy\u1ec5n Qu\u00fd \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9893",
            name: "Nguy\u1ec5n Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9894",
            name: "Nguy\u1ec5n S\u1eafc Kim",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9895",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9896",
            name: "Nguy\u1ec5n Thanh Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9897",
            name: "Nguy\u1ec5n Thanh N\u0103m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9898",
            name: "Nguy\u1ec5n Th\u1ebf L\u1ecbch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9899",
            name: "Nguy\u1ec5n Thi\u1ebfp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9900",
            name: "Nguy\u1ec5n Thu\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9901",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9902",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9903",
            name: "Nguy\u1ec5n Trung Ng\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9904",
            name: "Nguy\u1ec5n V\u0103n B\u1ed5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9905",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9906",
            name: "Nguy\u1ec5n V\u0103n \u0110\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9907",
            name: "Nguy\u1ec5n V\u0103n Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9908",
            name: "Nguy\u1ec5n V\u0103n Huy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9909",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9910",
            name: "Nguy\u1ec5n V\u0103n Ng\u1ecdc",
            prefix: "Ph\u1ed1",
          },
          {
            id: "9911",
            name: "Nguy\u1ec5n V\u0103n T\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9912",
            name: "Nguy\u1ec5n V\u0103n T\u1ef5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9913",
            name: "Nguy\u1ec5n V\u0103n Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9914",
            name: "Nguy\u1ec5n Xu\u00e2n H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9915",
            name: "Nh\u00e2n H\u00f2a 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9916",
            name: "Nh\u00e2n H\u00f2a 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9917",
            name: "Nh\u00e2n H\u00f2a 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9918",
            name: "Nh\u00e2n H\u00f2a 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9919",
            name: "Nh\u00e2n H\u00f2a 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9920",
            name: "Nh\u00e2n H\u00f2a 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9921",
            name: "Nh\u00e2n H\u00f2a 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9922",
            name: "Nh\u00e2n H\u00f2a 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9923",
            name: "Nh\u00e2n H\u00f2a 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9924",
            name: "Nh\u1ea5t Chi Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9925",
            name: "Nh\u01a1n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9926",
            name: "Nh\u01a1n H\u00f2a 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9927",
            name: "Nh\u01a1n H\u00f2a 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9928",
            name: "Nh\u01a1n H\u00f2a 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9929",
            name: "Nh\u01a1n H\u00f2a 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9930",
            name: "Nh\u01a1n Ho\u00e0 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9931",
            name: "Nh\u01a1n H\u00f2a 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9932",
            name: "Nh\u01a1n H\u00f2a 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9933",
            name: "Nh\u01a1n H\u00f2a 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9934",
            name: "Nh\u01a1n H\u00f2a 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9935",
            name: "Nh\u01a1n H\u00f2a 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9936",
            name: "Nh\u01a1n H\u00f2a 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9937",
            name: "Nh\u01a1n H\u00f2a 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9938",
            name: "Nh\u01a1n H\u00f2a 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9939",
            name: "Nh\u01a1n H\u00f2a 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9940",
            name: "Nh\u01a1n H\u00f2a 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9941",
            name: "Nh\u01a1n H\u00f2a 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9942",
            name: "Nh\u01a1n H\u00f2a 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9943",
            name: "Nh\u01a1n H\u00f2a 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9944",
            name: "Nh\u01a1n Ho\u00e0 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9945",
            name: "Nh\u01a1n H\u00f2a 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9946",
            name: "Nh\u01a1n H\u00f2a Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9947",
            name: "Nh\u01a1n H\u00f2a Ph\u01b0\u1edbc 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9948",
            name: "Nh\u01a1n H\u00f2a Ph\u01b0\u1edbc 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9949",
            name: "Nh\u01a1n H\u00f2a Ph\u01b0\u1edbc 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9950",
            name: "Nh\u01a1n H\u00f2a Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9951",
            name: "N\u00fai M\u1ed9t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9952",
            name: "\u00d4ng \u00cdch \u0110\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9953",
            name: "\u00d4ng \u00cdch Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9954", name: "Pasteur", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9955",
            name: "Ph\u1ea1m B\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9956",
            name: "Ph\u1ea1m C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9957",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9958",
            name: "Ph\u1ea1m H\u1eefu Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9959",
            name: "Ph\u1ea1m Ph\u00fa Ti\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9960",
            name: "Ph\u1ea1m S\u01b0 M\u1ea1nh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "9961",
            name: "Ph\u1ea1m Th\u1ebf Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9962",
            name: "Ph\u1ea1m T\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9963",
            name: "Ph\u1ea1m Vi\u1ebft Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9964",
            name: "Ph\u1ea1m Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9965", name: "Phan Anh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9966",
            name: "Phan Ch\u00e2u Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9967",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9968",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "9969",
            name: "Phan H\u00f2a 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9970", name: "Phan Khoang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9971",
            name: "Phan Kh\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9972",
            name: "Phan S\u1ef9 Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9973", name: "Phan Thao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9974",
            name: "Phan Th\u00fac Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9975",
            name: "Phan Tri\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "9976", name: "Phan T\u1ee9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "9977",
            name: "Phan V\u0103n \u0110\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9978",
            name: "Phan V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9979",
            name: "Phong B\u1eafc 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9980",
            name: "Phong B\u1eafc 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9981",
            name: "Phong B\u1eafc 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9982",
            name: "Phong B\u1eafc 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9983",
            name: "Phong B\u1eafc 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9984",
            name: "Phong B\u1eafc 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9985",
            name: "Phong B\u1eafc 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9986",
            name: "Phong B\u1eafc 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9987",
            name: "Phong B\u1eafc 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9988",
            name: "Phong B\u1eafc 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9989",
            name: "Phong B\u1eafc 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9990",
            name: "Phong B\u1eafc 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9991",
            name: "Phong B\u1eafc 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9992",
            name: "Phong B\u1eafc 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9993",
            name: "Phong B\u1eafc 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9994",
            name: "Phong B\u1eafc 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9995",
            name: "Phong B\u1eafc 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9996",
            name: "Ph\u00f9 \u0110\u1ed5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9997",
            name: "Ph\u00fa Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9998",
            name: "Ph\u01b0\u1edbc H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "9999",
            name: "Ph\u01b0\u1edbc Ho\u00e0 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10000",
            name: "Ph\u01b0\u1edbc Ho\u00e0 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10001",
            name: "Ph\u01b0\u1edbc Ho\u00e0 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10002",
            name: "Ph\u01b0\u1edbc Ho\u00e0 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10003",
            name: "Ph\u01b0\u1edbc H\u00f2a 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10004",
            name: "Ph\u01b0\u1edbc T\u01b0\u1eddng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10005",
            name: "Ph\u01b0\u1edbc T\u01b0\u1eddng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10006",
            name: "Ph\u01b0\u1edbc T\u01b0\u1eddng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10007",
            name: "Qu\u00e1ch X\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10008",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10009", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10010", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10011",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10012",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10013",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10014", name: "T\u1ebf Hanh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10015",
            name: "Th\u00e2n C\u00f4ng T\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10016",
            name: "Th\u0103ng  Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10017",
            name: "Thanh H\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10018",
            name: "Th\u1ea1nh L\u1ed9c 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10019",
            name: "Thanh L\u01b0\u01a1ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10020",
            name: "Thanh L\u01b0\u01a1ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10021",
            name: "Thanh L\u01b0\u01a1ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10022",
            name: "Thanh S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10023",
            name: "Th\u00e0nh Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10024",
            name: "Th\u1ea1nh Xu\u00e2n 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10025",
            name: "Th\u1ea1nh Xu\u00e2n 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10026",
            name: "Th\u00edch B\u1eedu \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10027",
            name: "Th\u00f4i Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10028", name: "Thu B\u1ed3n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10029",
            name: "Ti\u1ec1n \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10030",
            name: "Ti\u00ean S\u01a1n 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10031",
            name: "Ti\u00ean S\u01a1n 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10032",
            name: "T\u1ed1 H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10033",
            name: "T\u01a1 L\u1ee5a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10034",
            name: "T\u00f4 V\u0129nh Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10035",
            name: "T\u00f4n \u0110\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10036",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "10037",
            name: "T\u00f4n Th\u1ea5t Thuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10038",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10039",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10040",
            name: "Tr\u1ea7n \u0110\u00ecnh Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10041",
            name: "Tr\u1ea7n \u0110\u00ecnh Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10042",
            name: "Tr\u1ea7n Hu\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10043",
            name: "Tr\u1ea7n H\u1eefu Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10044",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10045",
            name: "Tr\u1ea7n Kim B\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10046",
            name: "Tr\u1ea7n L\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10047",
            name: "Tr\u1ea7n L\u01b0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10048",
            name: "Tr\u1ea7n L\u1ef1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10049",
            name: "Tr\u1ea7n Mai Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10050",
            name: "Tr\u1ea7n Nam Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10051",
            name: "Tr\u00e2\u0300n Ngo\u0323c S\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10052",
            name: "Tr\u1ea7n Ph\u01b0\u1edbc Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10053",
            name: "Tr\u1ea7n Quang \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10054",
            name: "Tr\u1ea7n Qu\u1ed1c Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10055",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10056",
            name: "Tr\u1ea7n Qu\u00fd Hai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10057",
            name: "Tr\u1ea7n T\u1ea5n M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10058",
            name: "Tr\u1ea7n Th\u1ee7 \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10059",
            name: "Tr\u1ea7n Th\u00fac Nh\u1eabn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10060",
            name: "Tr\u1ea7n T\u1eed B\u00ecnh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "10061",
            name: "Tr\u1ea7n V\u0103n C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10063",
            name: "Tr\u1ea7n V\u0103n D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10062",
            name: "Tr\u1ea7n V\u0103n \u0110ang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10064",
            name: "Tr\u1ea7n V\u0103n Gi\u00e0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10065",
            name: "Tr\u1ea7n V\u0103n \u01a0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10066",
            name: "Tr\u1ea7n V\u0103n Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10067",
            name: "Tr\u1ea7n Vi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10068",
            name: "Tr\u1ea7n Xu\u00e2n H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10069",
            name: "Tr\u1ea7n Xu\u00e2n So\u1ea1n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "10070",
            name: "Tr\u00edch S\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10071",
            name: "Tri\u1ec7u Qu\u1ed1c \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10072",
            name: "Tr\u1ecbnh \u0110\u00ecnh Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10073",
            name: "Tr\u1ecbnh Ho\u00e0i \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10074",
            name: "Tr\u1ecbnh Kh\u1ea3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10075",
            name: "Tr\u1eeb V\u0103n Th\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10076",
            name: "Trung L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10077",
            name: "Trung L\u01b0\u01a1ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10078",
            name: "Trung L\u01b0\u01a1ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10079",
            name: "Trung L\u01b0\u01a1ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10080",
            name: "Trung L\u01b0\u01a1ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10081",
            name: "Trung L\u01b0\u01a1ng 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10082",
            name: "Trung L\u01b0\u01a1ng 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10083",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10084",
            name: "Tr\u01b0\u01a1ng Quang Giao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10085",
            name: "Tr\u01b0\u1eddng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10086",
            name: "Tr\u01b0\u01a1ng Tr\u00ed C\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10087",
            name: "Tr\u01b0\u01a1ng V\u0103n \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10088",
            name: "Tr\u01b0\u01a1ng V\u0129nh K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10089",
            name: "T\u00fa M\u1ee1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10090",
            name: "T\u00f9ng L\u00e2m 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10091",
            name: "T\u00f9ng L\u00e2m 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10092",
            name: "T\u00f9ng L\u00e2m 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10093",
            name: "T\u00f9ng L\u00e2m 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10094",
            name: "T\u00f9ng L\u00e2m 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10095",
            name: "T\u00f9ng L\u00e2m 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10096",
            name: "T\u00f9ng L\u00e2m 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10097",
            name: "T\u00f9ng L\u00e2m 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10098",
            name: "T\u00f9ng L\u00e2m 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10099",
            name: "T\u00f9ng L\u00e2m 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10100",
            name: "T\u00fay Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10101",
            name: "V\u0103n C\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10102",
            name: "V\u0103n Ti\u1ebfn D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10103",
            name: "V\u00f5 An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10104",
            name: "V\u00f5 Ch\u00ed C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10105",
            name: "V\u00f5 Qu\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10106",
            name: "V\u00f5 S\u1ea1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10107",
            name: "V\u00f5 V\u0103n Ng\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10108",
            name: "V\u0169 Mi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10109",
            name: "V\u0169 Qu\u1ef3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10110",
            name: "Vu\u0303 Tha\u0323nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10111", name: "V\u0169 T\u00f4ng Phan", prefix: "Ph\u1ed1" },
          {
            id: "10112",
            name: "V\u0169 Tr\u1ecdng Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10113", name: "Xa La", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10114",
            name: "X\u00f4 Vi\u1ebft Ngh\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10115",
            name: "Xu\u00e2n Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10116",
            name: "Y\u00ean Th\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2019",
            name: "An Ph\u00e1t",
            lat: "16.053194046021",
            lng: "108.17738342285",
          },
          {
            id: "2020",
            name: "C.T Plaza \u0110\u00e0 N\u1eb5ng",
            lat: "16.042720794678",
            lng: "108.19351196289",
          },
          {
            id: "2022",
            name: "Dream Home",
            lat: "16.020612716675",
            lng: "108.22982788086",
          },
          {
            id: "2021",
            name: "\u0110\u00e2\u0300u tru\u0323c T\u00e2y B\u0103\u0301c",
            lat: "16.051267623901",
            lng: "108.17890167236",
          },
          {
            id: "2023",
            name: "Green Lake",
            lat: "16.018013000488",
            lng: "108.19845581055",
          },
          {
            id: "2024",
            name: "H\u00f2a An Residence",
            lat: "16.059719085693",
            lng: "108.17889404297",
          },
          {
            id: "2025",
            name: "K\u0110T H\u00f2a Xu\u00e2n m\u1edf r\u1ed9ng",
            lat: "16.018306732178",
            lng: "108.22174835205",
          },
          {
            id: "2026",
            name: "Khu qu\u00e2n nh\u00e2n 309",
            lat: "16.034847259521",
            lng: "108.17948913574",
          },
          {
            id: "2027",
            name: "Nam c\u1ea7u Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            lat: "16.008123397827",
            lng: "108.21940612793",
          },
          {
            id: "2028",
            name: "Ph\u01b0\u1edbc L\u00fd",
            lat: "16.043216705322",
            lng: "108.16985321045",
          },
          {
            id: "2029",
            name: "Th\u0103ng Long Riverside",
            lat: "16.006296157837",
            lng: "108.19923400879",
          },
          {
            id: "2030",
            name: "The Sun City Eco island",
            lat: "16.020698547363",
            lng: "108.22966003418",
          },
          {
            id: "2031",
            name: "Y\u00ean Th\u1ebf B\u1eafc S\u01a1n",
            lat: "16.049835205078",
            lng: "108.17946624756",
          },
        ],
      },
      {
        id: "56",
        name: "H\u1ea3i Ch\u00e2u",
        wards: [
          {
            id: "920",
            name: " H\u1ea3i Ch\u00e2u I",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "921",
            name: " H\u00f2a Thu\u1eadn \u0110\u00f4ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "922",
            name: "B\u00ecnh Hi\u00ean",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "923",
            name: "B\u00ecnh Thu\u1eadn",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "924",
            name: "H\u1ea3i Ch\u00e2u II",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "925",
            name: "H\u00f2a C\u01b0\u1eddng B\u1eafc",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "926",
            name: "H\u00f2a C\u01b0\u1eddng Nam",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "927",
            name: "H\u00f2a Thu\u1eadn T\u00e2y",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "928",
            name: "Nam D\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "929",
            name: "Ph\u01b0\u1edbc Ninh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "930", name: "Th\u1ea1ch Thang", prefix: "Ph\u01b0\u1eddng" },
          { id: "931", name: "Thanh B\u00ecnh", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "932",
            name: "Thu\u1eadn Ph\u01b0\u1edbc",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "10117", name: "2/9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10118", name: "21E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10119", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10120", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10121", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10122", name: "A4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10123",
            name: "Ba \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10124",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          {
            id: "10125",
            name: "B\u1eafc \u0110\u1ea9u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10126",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10127",
            name: "B\u00e0u H\u1ea1c 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10128",
            name: "B\u00e0u H\u1ea1c 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10129",
            name: "B\u1ea7u H\u1ea1c 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10130",
            name: "B\u1ea7u H\u1ea1t 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10131",
            name: "B\u00e0u Tr\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10132",
            name: "B\u00e0u Tr\u00e0m 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10133",
            name: "B\u00e0u Tr\u00e0m 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10134",
            name: "B\u00e0u Tr\u00e0m Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10135",
            name: "B\u1ea7u Tr\u1ea3ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10136", name: "B\u00ecnh An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10137",
            name: "B\u00ecnh An 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10138",
            name: "B\u00ecnh An 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10139",
            name: "B\u00ecnh An 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10140",
            name: "B\u00ecnh An 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10141",
            name: "B\u00ecnh An 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10142",
            name: "B\u00ecnh An 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10143",
            name: "B\u00ecnh Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10144",
            name: "B\u00ecnh Minh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10145",
            name: "B\u00ecnh Minh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10146",
            name: "B\u00ecnh Minh 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10147",
            name: "B\u00f9i K\u1ef7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10148",
            name: "B\u00f9i Vi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10149",
            name: "B\u00f9i Xu\u00e2n Ph\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10150",
            name: "Ca V\u0103n Th\u1ec9nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10151",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10152",
            name: "C\u1ea7m B\u00e1 Th\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10153",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10154",
            name: "Cao Xu\u00e2n D\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10155",
            name: "Cao Xu\u00e2n Huy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10156",
            name: "Ch\u00e2u Th\u1ecb V\u0129nh T\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10157",
            name: "Ch\u00e2u Th\u01b0\u1ee3ng V\u0103n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10158",
            name: "Ch\u00e2u V\u0103n Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10159",
            name: "Chi L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10160",
            name: "Chu M\u1ea1nh Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10161",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10162",
            name: "C\u00f4 B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10163",
            name: "C\u00f4 Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10188",
            name: "D\u01b0\u01a1ng B\u00e1 Tr\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10189",
            name: "D\u01b0\u01a1ng Qu\u1ea3ng H\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10190",
            name: "D\u01b0\u01a1ng Th\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10191",
            name: "D\u01b0\u01a1ng Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10192",
            name: "D\u01b0\u01a1ng Th\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10193", name: "Duy T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10164",
            name: "\u0110\u1ea1i l\u1ed9 Th\u0103ng Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10165",
            name: "\u0110\u1ea7m Rong 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10166",
            name: "\u0110\u1ea7m Rong 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10167",
            name: "\u0110\u1eb7ng Nguy\u00ean C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10168",
            name: "\u0110\u1eb7ng Thai Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10169",
            name: "\u0110\u1eb7ng Th\u00f9y Tr\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10170",
            name: "\u0110\u1eb7ng T\u1eed K\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10171",
            name: "\u0110\u00e0o Cam M\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10172",
            name: "\u0110\u00e0o Duy Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10173",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10174",
            name: "\u0110\u00e0o T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10175",
            name: "\u0110\u00e0o Tr\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10176",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10177",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10178",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10179", name: "\u0110\u1ed7 Quang", prefix: "Ph\u1ed1" },
          {
            id: "10180",
            name: "\u0110\u1ed7 Xu\u00e2n C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10181",
            name: "\u0110o\u00e0n Qu\u00fd Phi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10182",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10183", name: "\u0110\u1ed1c Ng\u1eef", prefix: "Ph\u1ed1" },
          {
            id: "10184",
            name: "\u0110\u1ed1ng \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10185",
            name: "\u0110\u1ee9c L\u1ee3i 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10186",
            name: "\u0110\u1ee9c L\u1ee3i 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10187",
            name: "\u0110\u1ee9c L\u1ee3i I",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10194",
            name: "Giang V\u0103n Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10195",
            name: "H\u00e0 B\u00e1 T\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10196",
            name: "H\u00e0 Huy Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10197",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10198",
            name: "H\u1ea3i H\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10199",
            name: "H\u1ea3i Ph\u00f2ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10200",
            name: "H\u1ea3i S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10201",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10202",
            name: "H\u00e0n M\u1eb7c T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10203",
            name: "H\u00e0n Thuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10204",
            name: "H\u1ed3 Bi\u1ec3u Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10205",
            name: "H\u1ed3 Nghinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10206",
            name: "H\u1ed3 Nguy\u00ean Tr\u1eebng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10207",
            name: "H\u1ed3 T\u00f4ng Th\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10208",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10209",
            name: "H\u00f3a S\u01a1n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10210",
            name: "H\u00f3a S\u01a1n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10211",
            name: "H\u00f3a S\u01a1n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10212",
            name: "H\u00f3a S\u01a1n 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10213",
            name: "H\u00f3a S\u01a1n 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10214",
            name: "Ho\u00e0n V\u0103n Th\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10215",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10216",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10217",
            name: "Ho\u00e0ng Th\u00fac Tr\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10218",
            name: "Ho\u00e0ng T\u00edch Tr\u00ed",
            prefix: "Ph\u1ed1",
          },
          {
            id: "10219",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10220",
            name: "Ho\u00e0ng Xu\u00e2n Nh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10221",
            name: "H\u01b0ng H\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10222",
            name: "H\u01b0ng Ho\u00e1 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10223",
            name: "H\u01b0ng Ho\u00e1 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10224",
            name: "H\u01b0ng H\u00f3a 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10225",
            name: "H\u01b0ng H\u00f3a 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10226",
            name: "H\u01b0ng Ho\u00e1 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10227",
            name: "H\u01b0ng H\u00f3a 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10228",
            name: "H\u01b0ng Ho\u00e1 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10229",
            name: "H\u01b0ng H\u00f3a 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10230",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10231",
            name: "Huy C\u00e2\u0323n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10232",
            name: "Hu\u1ef3nh L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10233",
            name: "Hu\u1ef3nh M\u1eabn \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10234",
            name: "Hu\u1ef3nh Ng\u1ecdc Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10235",
            name: "Hu\u1ef3nh T\u1ea5n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10236",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10237",
            name: "Ka V\u0103n Th\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10238",
            name: "L\u00ea Anh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10239",
            name: "L\u00ea B\u00e1 Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10240",
            name: "L\u00ea C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10247",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10248",
            name: "L\u00ea Duy L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10241",
            name: "L\u00ea \u0110\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10242",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10243",
            name: "L\u00ea \u0110\u00ecnh D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10244",
            name: "L\u00ea \u0110\u00ecnh Duy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10245",
            name: "L\u00ea \u0110\u00ecnh L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10246",
            name: "L\u00ea \u0110\u00ecnh Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10249",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10250",
            name: "L\u00ea kh\u1eafc C\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10251",
            name: "L\u00ea Kh\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10252", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10253",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10254",
            name: "L\u00ea Ng\u00f4 C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10255",
            name: "L\u00ea N\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10256",
            name: "L\u00ea Ph\u1ee5ng Hi\u1ec3u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "10257",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10258",
            name: "L\u00ea S\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10259", name: "L\u00ea Thanh Ngh\u1ecb", prefix: "Ph\u1ed1" },
          {
            id: "10260",
            name: "L\u00ea Th\u00e1nh T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10261",
            name: "L\u00ea Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10262",
            name: "L\u00ea V\u0103n \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10263",
            name: "L\u00ea V\u0103n Hi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10264",
            name: "L\u00ea V\u0103n Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10265",
            name: "L\u00ea V\u0129nh Huy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10266",
            name: "L\u01b0\u01a1ng Ng\u1ecdc Quy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10267",
            name: "L\u01b0\u01a1ng Nh\u1eef H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10268",
            name: "L\u01b0\u01a1ng Nh\u1eef H\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10269",
            name: "L\u01b0u Qu\u00fd K\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10270",
            name: "L\u01b0u Tr\u1ecdng L\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10271",
            name: "L\u00fd Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10272",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10273",
            name: "L\u00fd Th\u00e1i T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10274",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "10275",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10276",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10277",
            name: "M\u1ea1c Th\u1ecb B\u01b0\u1edfi",
            prefix: "Ph\u1ed1",
          },
          { id: "10278", name: "Mai Am", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10279", name: "Mai D\u1ecb", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10280",
            name: "Mai L\u00e3o B\u1ea1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10281",
            name: "Man Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10282", name: "M\u1ef9 An 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10283", name: "N\u1ea1i Nam", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10284", name: "Nam S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10285",
            name: "Nam S\u01a1n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10286",
            name: "Nam S\u01a1n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10287",
            name: "Nam S\u01a1n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10288",
            name: "Nam S\u01a1n 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10289",
            name: "Nam S\u01a1n 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10290",
            name: "Ng\u00f4 Chi Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10291",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10292",
            name: "Ng\u00f4 T\u1ea5t T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10293",
            name: "Ng\u00f4 Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10294",
            name: "Ng\u00f4 Th\u1ecb Li\u1ec5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10295",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10296",
            name: "Nguy\u1ec5n B\u00e1 H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10297",
            name: "Nguy\u1ec5n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10298",
            name: "Nguy\u1ec5n Cao Luy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10299",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10300",
            name: "Nguy\u1ec5n C\u01b0 Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10304",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10305",
            name: "Nguy\u1ec5n D\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10301",
            name: "Nguy\u1ec5n \u0110\u0103ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10302",
            name: "Nguy\u1ec5n \u0110\u00f4n Ti\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10303",
            name: "Nguy\u1ec5n \u0110\u1ed5ng Chi",
            prefix: "Ph\u1ed1",
          },
          {
            id: "10306",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10307",
            name: "Nguy\u1ec5n \u0110\u1ee9c Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10308",
            name: "Nguy\u1ec5n Gia Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10309",
            name: "Nguy\u1ec5n H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10310",
            name: "Nguy\u1ec5n Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10311",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10312",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10313",
            name: "Nguy\u1ec5n H\u1eefu D\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10314",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10315",
            name: "Nguy\u1ec5n Kh\u00e1nh To\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10316",
            name: "Nguy\u1ec5n Kho\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10317",
            name: "Nguy\u1ec5n L\u1ed9 Tr\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10318",
            name: "Nguy\u1ec5n Ph\u1ea9m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10319",
            name: "Nguy\u1ec5n Phi Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10320",
            name: "Nguy\u1ec5n Phong S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10321",
            name: "Nguy\u1ec5n Quang B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10322",
            name: "Nguy\u1ec5n S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10323",
            name: "Nguy\u1ec5n S\u01a1n H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10324",
            name: "Nguy\u1ec5n S\u01a1n Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10325",
            name: "Nguy\u1ec5n S\u00fay",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10326",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10327",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10328",
            name: "Nguy\u1ec5n Th\u00e0nh H\u00e3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10329",
            name: "Nguy\u1ec5n Th\u00e0nh \u00dd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10330",
            name: "Nguy\u1ec5n Thi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10331",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10332",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "10333",
            name: "Nguy\u1ec5n Tr\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10334",
            name: "Nguy\u1ec5n Tr\u00e1c Lu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10335",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10336",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10337",
            name: "Nguy\u1ec5n Tr\u1eebng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10338",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10339",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10340",
            name: "Nguy\u1ec5n V\u0103n Th\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10341",
            name: "Nguy\u1ec5n V\u0103n T\u1ed1",
            prefix: "Ph\u1ed1",
          },
          {
            id: "10342",
            name: "Nguy\u1ec5n Xu\u00e2n Nh\u0129",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10343",
            name: "Nguy\u1ec5n Xu\u00e2n \u00d4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10344",
            name: "Nh\u01b0 Nguy\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10345",
            name: "N\u01a1 Trang Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10346",
            name: "N\u00fai Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10347",
            name: "\u00d4ng B\u00edch Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10348",
            name: "\u00d4ng Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10349",
            name: "\u00d4ng \u00cdch Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10350",
            name: "\u00d4ng \u00cdch Khi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10351", name: "Pasteur", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10352",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10353",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10354",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10355",
            name: "Ph\u1ea1m Nh\u01b0 S\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10356",
            name: "Ph\u1ea1m Ph\u00fa Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10357",
            name: "Ph\u1ea1m Ph\u00fa Ti\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10358",
            name: "Ph\u1ea1m Th\u1ebf Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10359",
            name: "Ph\u1ea1m V\u0103n B\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10360",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10361",
            name: "Ph\u1ea1m V\u0103n Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10362", name: "Phan Anh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10363",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10364",
            name: "Phan C\u1ea3nh Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10365",
            name: "Phan Ch\u00e2u Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10366",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10367",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "10368",
            name: "Phan \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10369",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10370",
            name: "Phan Huy \u00d4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10371",
            name: "Phan K\u1ebf B\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10372",
            name: "Ph\u1ea7n L\u0103ng 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10373",
            name: "Ph\u1ea7n L\u0103ng 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10374",
            name: "Ph\u1ea7n L\u0103ng 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10375",
            name: "Ph\u1ea7n L\u0103ng 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10376",
            name: "Ph\u1ea7n L\u0103ng 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10377",
            name: "Ph\u1ea7n L\u0103ng 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10378",
            name: "Ph\u1ea7n L\u0103ng 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10379",
            name: "Ph\u1ea7n L\u0103ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10380",
            name: "Ph\u1ea7n L\u0103ng 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10381",
            name: "Ph\u1ea7n L\u0103ng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10382",
            name: "Ph\u1ea7n L\u0103ng 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10383", name: "Phan Thanh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10384",
            name: "Phan Th\u00e0nh T\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10385",
            name: "Phan Tr\u1ecdng Tu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10386", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10387", name: "Quy M\u1ef9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10388", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10389", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10390", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10391",
            name: "T\u1ea1 Hi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10392", name: "T\u00e2n An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10393",
            name: "T\u00e2n An 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10394",
            name: "T\u00e2n An 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10395",
            name: "T\u00e2n An 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10396",
            name: "T\u00e2n An 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10397",
            name: "T\u00e2n L\u1eadp 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10398",
            name: "T\u00e2n L\u1eadp 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10399",
            name: "T\u0103ng B\u1ea1t H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10400",
            name: "Th\u00e1i Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10401",
            name: "Th\u00e2n C\u1ea3nh Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10402",
            name: "Th\u0103ng  Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10403",
            name: "Thanh Duy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10404",
            name: "Thanh H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10405", name: "Thanh Huy 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10406", name: "Thanh Long", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10407",
            name: "Thanh S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10408",
            name: "Thanh Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10409", name: "Thi S\u00e1ch", prefix: "Ph\u1ed1" },
          {
            id: "10410",
            name: "Thu\u1eadn An 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10411",
            name: "Ti\u00ean S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10412",
            name: "Ti\u00ean S\u01a1n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10413",
            name: "Ti\u00ean S\u01a1n 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10414",
            name: "Ti\u00ean S\u01a1n 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10415",
            name: "Ti\u00ean S\u01a1n 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10416",
            name: "Ti\u00ean S\u01a1n 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10417",
            name: "Ti\u00ean S\u01a1n 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10418",
            name: "Ti\u00ean S\u01a1n 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10419",
            name: "Ti\u00ean S\u01a1n 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10420",
            name: "Ti\u00ean S\u01a1n 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10421",
            name: "Ti\u00ean S\u01a1n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10422",
            name: "Ti\u00ean S\u01a1n 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10423",
            name: "Ti\u00ean S\u01a1n 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10424",
            name: "Ti\u00ean S\u01a1n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10425",
            name: "Ti\u00ean S\u01a1n 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10426",
            name: "Ti\u00ean S\u01a1n 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10427",
            name: "Ti\u00ean S\u01a1n 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10428",
            name: "Ti\u00ean S\u01a1n 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10429",
            name: "Ti\u00ean S\u01a1n 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10430",
            name: "Ti\u00ean S\u01a1n 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10431", name: "Ti\u1ec3u La", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10432",
            name: "T\u1ed1 H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10433",
            name: "T\u00f4n Th\u1ea5t \u0110\u1ea1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10434",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10435",
            name: "T\u1ed1ng Ph\u01b0\u1edbc Ph\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10436",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10437",
            name: "Tr\u1ea7n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10438",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10439",
            name: "Tr\u1ea7n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10440", name: "Tr\u1ea7n Cung", prefix: "Ph\u1ed1" },
          {
            id: "10441",
            name: "Tr\u1ea7n \u0110\u0103ng Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10442",
            name: "Tr\u1ea7n \u0110\u1ee9c Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10443",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10444",
            name: "Tr\u1ea7n H\u1eefu Trang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10445",
            name: "Tr\u1ea7n K\u1ebf X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10446",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10447",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10448",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10449",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10450",
            name: "Tr\u1ea7n T\u1ea5n M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10451",
            name: "Tr\u1ea7n Th\u1ee7 \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10452",
            name: "Tr\u1ea7n T\u1ed1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10453",
            name: "Tr\u1ea7n V\u0103n Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10454",
            name: "Tr\u1ea7n Xu\u00e2n L\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10455",
            name: "Tri\u1ec7u N\u1eef V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10456",
            name: "Tr\u1ecbnh C\u00f4ng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10457",
            name: "Tr\u01b0ng Nh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10458",
            name: "Tr\u01b0ng N\u1eef V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10459",
            name: "Tr\u01b0\u01a1ng Chi\u0301 C\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10460",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10461",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10462",
            name: "V\u0103n C\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10463", name: "V\u0103n Cao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10464",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10465",
            name: "V\u00f5 V\u0103n T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10466", name: "V\u0169 H\u1eefu", prefix: "Ph\u1ed1" },
          {
            id: "10467",
            name: "V\u0169 Tr\u1ecdng Ph\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10468",
            name: "X\u00f4 Vi\u1ebft Ngh\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10469",
            name: "Xu\u00e2n Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10470",
            name: "Xu\u00e2n T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10471",
            name: "\u1ef6 Lan Nguy\u00ean Phi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10472",
            name: "Y\u00ean B\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10473",
            name: "Y\u00ean B\u00e1y",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2032",
            name: "4 Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            lat: "16.037105560303",
            lng: "108.21305084228",
          },
          {
            id: "2033",
            name: "Asia Park Residence",
            lat: "16.031089782715",
            lng: "108.23274993897",
          },
          {
            id: "2034",
            name: "Blooming Tower",
            lat: "16.090568542481",
            lng: "108.21631622315",
          },
          {
            id: "2035",
            name: "C\u0103n h\u1ed9 Quang Nguy\u1ec5n",
            lat: "16.04008102417",
            lng: "108.21060943603",
          },
          {
            id: "2036",
            name: "\u0110\u00e0 N\u1eb5ng Plaza",
            lat: "16.079374313354",
            lng: "108.2226486206",
          },
          {
            id: "2037",
            name: "Elysia Complex City",
            lat: "16.032602310181",
            lng: "108.2333908081",
          },
          {
            id: "2038",
            name: "F.Home",
            lat: "16.080213546753",
            lng: "108.22033691406",
          },
          {
            id: "2039",
            name: "Green Plaza \u0110\u00e0 N\u1eb5ng",
            lat: "16.063619613648",
            lng: "108.22369384766",
          },
          {
            id: "2040",
            name: "Halla Jade Residences",
            lat: "16.042387008667",
            lng: "108.22222900391",
          },
          {
            id: "2041",
            name: "Hilton B\u1ea1ch \u0110\u1eb1ng",
            lat: "16.072082519531",
            lng: "108.22496795654",
          },
          {
            id: "2042",
            name: "Indochina Riverside",
            lat: "16.069986343384",
            lng: "108.22454071045",
          },
          {
            id: "2043",
            name: "La Paz Tower",
            lat: "16.07643699646",
            lng: "108.22045135498",
          },
          {
            id: "2044",
            name: "Le Pavillon Luxury",
            lat: "16.033109664917",
            lng: "108.22423553467",
          },
          {
            id: "2045",
            name: "Nguy\u1ec1n \u0110\u00ecnh Chi\u1ec3u",
            lat: "16.028718948364",
            lng: "108.24558258057",
          },
          {
            id: "2046",
            name: "Risemount Apartment \u0110\u00e0 N\u1eb5ng",
            lat: "16.087999343872",
            lng: "108.22093200684",
          },
          {
            id: "2047",
            name: "The Sunrise Bay",
            lat: "16.086715698242",
            lng: "108.21911621094",
          },
          {
            id: "2048",
            name: "Vi\u1ec5n \u0110\u00f4ng Meridian",
            lat: "16.068960189819",
            lng: "108.22148132324",
          },
        ],
      },
      {
        id: "57",
        name: "H\u00f2a Vang",
        wards: [
          { id: "933", name: "H\u00f2a B\u1eafc", prefix: "Ph\u01b0\u1eddng" },
          { id: "934", name: "H\u00f2a Ch\u00e2u", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "935",
            name: "H\u00f2a Kh\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "936", name: "H\u00f2a Li\u00ean", prefix: "Ph\u01b0\u1eddng" },
          { id: "937", name: "H\u00f2a Nh\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "938", name: "H\u00f2a Ninh", prefix: "X\u00e3" },
          { id: "939", name: "H\u00f2a Phong", prefix: "X\u00e3" },
          { id: "940", name: "H\u00f2a Ph\u00fa", prefix: "X\u00e3" },
          { id: "941", name: "H\u00f2a Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "942", name: "H\u00f2a S\u01a1n", prefix: "X\u00e3" },
          { id: "943", name: "H\u00f2a Ti\u1ebfn", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "10474", name: "14B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10475", name: "409", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10476", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10477", name: "601", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10478", name: "602", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10479", name: "603", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10480", name: "605", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10481", name: "An C\u01b0 2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10482",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10483",
            name: "\u00c2u D\u01b0\u01a1ng L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10484",
            name: "B\u1ea1ch \u0110\u1eb1ng \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10485",
            name: "B\u00e0u C\u1ea7u 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10486",
            name: "Ba\u0300u C\u00e2\u0300u 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10487",
            name: "B\u00e0u C\u1ea7u 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10488",
            name: "B\u00e0u C\u1ea7u 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10489",
            name: "B\u1ea7u C\u1ea7u 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10490",
            name: "B\u1ea7u C\u1ea7u 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10491",
            name: "B\u1ea7u C\u1ea7u 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10492",
            name: "B\u1ea7u C\u1ea7u 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10493",
            name: "B\u1ea7u C\u1ea7u 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10494",
            name: "B\u00e0u C\u1ea7u 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10495",
            name: "B\u1ea7u C\u1ea7u 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10496",
            name: "B\u00e0u C\u1ea7u 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10497",
            name: "B\u1ea7u C\u1ea7u 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10498",
            name: "B\u1ea7u C\u1ea7u 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10499",
            name: "B\u00e0u Tr\u00e0m Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10500",
            name: "B\u00f9i C\u1ea9m H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10501",
            name: "B\u00f9i Huy \u0110\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10502",
            name: "C\u1ea9m B\u1eafc 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10503",
            name: "C\u1ea9m B\u1eafc 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10504",
            name: "C\u1ea9m Tho\u1ea1i T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10505",
            name: "C\u1ea9m To\u1ea1i Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10506",
            name: "Cao B\u00e1 \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10507",
            name: "C\u00f2n Mu\u1ed3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10510", name: "DH4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10511", name: "DH8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10519",
            name: "D\u01b0\u01a1ng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10508",
            name: "\u0110\u1eb7ng V\u0103n Ki\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10509",
            name: "\u0110\u00e0o Trinh Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10512",
            name: "\u0110\u1ed1ng C\u00f4ng Tr\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10513",
            name: "\u0110\u00f4ng Th\u1ea1nh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10514",
            name: "\u0110\u00f4ng Th\u1ea1nh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10515",
            name: "\u0110\u00f4ng Th\u1ea1nh 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10516", name: "\u0110T 601", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10517", name: "\u0110T 602", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10518", name: "\u0110T 605", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10520",
            name: "H\u00e0 Duy Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10521",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10522",
            name: "H\u1ed3 S\u0129 D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10523",
            name: "H\u00f2a Li\u00ean 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10524",
            name: "H\u00f2a Li\u00ean 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10525",
            name: "H\u00f2a Li\u00ean 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10526",
            name: "H\u00f2a Li\u00ean 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10527",
            name: "Ho\u00e0ng \u0110\u1ea1o Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10528", name: "Ho\u00e0ng S\u00e2m", prefix: "Ph\u1ed1" },
          {
            id: "10529",
            name: "Ho\u00e0ng Th\u1ecb \u00c1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10530",
            name: "Ho\u00e0ng V\u0103n Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10531",
            name: "Hu\u1ef3nh T\u1ecbnh C\u1ee7a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10532",
            name: "Kha V\u1ea1ng C\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10535",
            name: "L\u00ea Duy L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10533",
            name: "L\u00ea \u0110\u00ecnh Di\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10534",
            name: "L\u00ea \u0110\u1ee9c Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10536",
            name: "L\u00ea Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10537",
            name: "L\u00ea V\u0103n Hoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10538",
            name: "L\u1ed7 Gi\u00e1ng 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10539",
            name: "L\u00fd Thi\u00ean B\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10540",
            name: "Mai An Ti\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10541",
            name: "M\u1eb9 Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10542",
            name: "Ng\u0169 H\u00e0nh S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10543",
            name: "Nguy\u1ec5n B\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10544",
            name: "Nguy\u1ec5n \u0110\u0103ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10545",
            name: "Nguy\u1ec5n H\u00e0m Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10546",
            name: "Nguy\u1ec5n Huy O\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10547",
            name: "Nguy\u1ec5n Kh\u1ea3 Tr\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10548",
            name: "Nguy\u1ec5n Kim",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10549",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10550",
            name: "Nguy\u1ec5n Phan Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10551",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10552",
            name: "Nguy\u1ec5n Th\u1ebf L\u1ecbch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10553",
            name: "Nguy\u1ec5n Trung Ng\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10554",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10555",
            name: "Nguy\u1ec5n V\u0103n T\u1ef5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10556",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10557",
            name: "Ph\u1ea1m Ph\u00fa Ti\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10558",
            name: "Phan Th\u00fac Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10559",
            name: "Phan V\u0103n \u0110\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10560", name: "Phong Nam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10561",
            name: "Ph\u00fa H\u00f2a 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10562",
            name: "Quang Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10563",
            name: "Qu\u1ed1c L\u1ed9 14B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10564",
            name: "Qu\u1ed1c l\u1ed9 14G",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10565",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10566",
            name: "Qu\u1ed1c L\u1ed9 604",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10567", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10568", name: "Thanh Vinh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10569",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10570",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10571",
            name: "Tr\u1ea7n T\u1eed B\u00ecnh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "10572",
            name: "Tr\u1ea7n V\u0103n D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10573",
            name: "Tr\u1ea7n V\u0103n Gi\u00e0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10574",
            name: "Tr\u1ea7n V\u0103n Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10575",
            name: "Tr\u1ecbnh \u0110\u00ecnh Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10576",
            name: "Tr\u1ecbnh Quang Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10577",
            name: "Tr\u1eeb V\u0103n Th\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10578",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10579",
            name: "Tr\u01b0\u1eddng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10580",
            name: "Tr\u01b0\u01a1ng V\u0129nh K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10581",
            name: "T\u00f9ng L\u00e2m 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10582",
            name: "V\u0103n Ti\u1ebfn D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10583",
            name: "V\u0169 Mi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10584",
            name: "X\u00f4 Vi\u1ebft Ngh\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10585",
            name: "Xu\u00e2n Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      { id: "58", name: "Ho\u00e0ng Sa", wards: [], streets: [], projects: [] },
      {
        id: "59",
        name: "Li\u00ean Chi\u1ec3u",
        wards: [
          {
            id: "944",
            name: "H\u00f2a Hi\u1ec7p B\u1eafc",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "945",
            name: "H\u00f2a Hi\u1ec7p Nam",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "946",
            name: "H\u00f2a Kh\u00e1nh B\u1eafc",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "947",
            name: "H\u00f2a Kh\u00e1nh Nam",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "948", name: "H\u00f2a Minh", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "10586", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10587", name: "11B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10588", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10589", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10590", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10591", name: "A10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10592", name: "A2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10593", name: "A9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10594", name: "AC03", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10595",
            name: "An Xu\u00e2n 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10596",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10597",
            name: "B\u00e0 N\u00e0 - Su\u1ed1i M\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10598",
            name: "B\u1ea1ch Th\u00e1i B\u01b0\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10599",
            name: "B\u00e0u M\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10600",
            name: "B\u00e0u M\u1ea1c 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10601",
            name: "B\u00e0u M\u1ea1c 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10602",
            name: "B\u00e0u M\u1ea1c 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10603",
            name: "B\u00e0u M\u1ea1c 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10604",
            name: "B\u00e0u M\u1ea1c 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10605",
            name: "B\u00e0u M\u1ea1c 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10606",
            name: "B\u00e0u M\u1ea1c 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10607",
            name: "B\u00e0u M\u1ea1c 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10608",
            name: "B\u00e0u M\u1ea1c 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10609",
            name: "B\u00e0u M\u1ea1c 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10610",
            name: "B\u00e0u M\u1ea1c 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10611",
            name: "B\u00e0u M\u1ea1c 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10612",
            name: "B\u00e0u M\u1ea1c 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10613",
            name: "B\u00e0u M\u1ea1c 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10614",
            name: "B\u00e0u M\u1ea1c 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10615",
            name: "B\u00e0u N\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10616",
            name: "B\u00e0u N\u0103ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10617",
            name: "B\u1ea7u N\u0103ng 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10618",
            name: "B\u00e0u N\u0103ng 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10619",
            name: "B\u1ea7u N\u0103ng 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10620",
            name: "B\u00e0u N\u0103ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10621",
            name: "B\u00e0u N\u0103ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10622",
            name: "B\u00e0u N\u0103ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10623",
            name: "B\u00e0u N\u0103ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10624",
            name: "B\u00e0u N\u0103ng 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10625",
            name: "B\u00e0u N\u0103ng 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10626",
            name: "B\u00e0u N\u0103ng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10627",
            name: "B\u1ea7u N\u0103ng 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10628",
            name: "B\u00e0u V\u00e0ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10629",
            name: "B\u00e0u V\u00e0ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10630",
            name: "B\u00e0u V\u00e0ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10631",
            name: "B\u00e0u V\u00e0ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10632",
            name: "B\u00e0u V\u00e0ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10633",
            name: "B\u00e0u V\u00e0ng 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10634",
            name: "B\u00f9i Ch\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10635",
            name: "B\u00f9i T\u1ea5n Di\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10636", name: "C1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10637",
            name: "Cao B\u00e1 Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10638",
            name: "Ch\u01a1n T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10639",
            name: "Ch\u01a1n T\u00e2m 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10640",
            name: "Ch\u01a1n T\u00e2m 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10641",
            name: "Ch\u01a1n T\u00e2m 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10642",
            name: "Ch\u01a1n T\u00e2m 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10643",
            name: "Ch\u01a1n T\u00e2m 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10644",
            name: "Ch\u01a1n T\u00e2m 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10645",
            name: "Ch\u01a1n T\u00e2m 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10646",
            name: "Ch\u01a1n T\u00e2m 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10647",
            name: "Ch\u00fac \u0110\u1ed9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10698",
            name: "D\u0169ng S\u0129 Thanh Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10699",
            name: "D\u01b0\u01a1ng B\u00edch Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10700",
            name: "D\u01b0\u01a1ng C\u00e1t L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10701",
            name: "D\u01b0\u01a1ng \u0110\u1ee9c Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10648",
            name: "\u0110\u00e1 M\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10649",
            name: "\u0110\u00e1 M\u1ecdc 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10650",
            name: "\u0110\u00e1 M\u1ecdc 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10651",
            name: "\u0110\u00e1 M\u1ecdc 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10652",
            name: "\u0110\u00e1 M\u1ecdc 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10653",
            name: "\u0110\u00e1 M\u1ecdc 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10654",
            name: "\u0110\u00e1 M\u1ecdc 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10655",
            name: "\u0110\u00e1 M\u1ecdc 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10656",
            name: "\u0110a Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10657",
            name: "\u0110\u00e0 S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10658",
            name: "\u0110\u00e0m Quang Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10659",
            name: "\u0110\u00e0m Thanh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10660",
            name: "\u0110\u00e0m Thanh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10661",
            name: "\u0110\u00e0m Thanh 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10662",
            name: "\u0110\u00e0m Thanh 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10663",
            name: "\u0110\u00e0m Thanh 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10664",
            name: "\u0110\u00e0m Thanh 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10665",
            name: "\u0110\u00e0m Thanh 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10666",
            name: "\u0110\u00e0m V\u0103n L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10667",
            name: "\u0110\u1eb7ng Ch\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10668",
            name: "\u0110\u1eb7ng Chi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10669", name: "\u0110\u1eb7ng Dung", prefix: "Ph\u1ed1" },
          {
            id: "10670",
            name: "\u0110\u1eb7ng Huy T\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10671",
            name: "\u0110\u1eb7ng Huy Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10672",
            name: "\u0110\u1eb7ng Minh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10673", name: "\u0110\u1eb7ng T\u1ea5t", prefix: "Ph\u1ed1" },
          {
            id: "10674",
            name: "\u0110\u00e0o C\u00f4ng So\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10675",
            name: "\u0110\u00e0o Do\u00e3n \u0110\u1ecbch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10676",
            name: "\u0110\u00e0o Nghi\u1ec5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10677",
            name: "\u0110\u00e0o Nguy\u00ean Ph\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10678",
            name: "\u0110\u00e0o Quang Ph\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10679",
            name: "\u0110\u00e0o S\u01b0 T\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10680",
            name: "\u0110inh \u0110\u1ee9c Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10681",
            name: "\u0110\u1ed7 N\u0103ng T\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10682", name: "\u0110\u1ed7 Nhu\u1eadn", prefix: "Ph\u1ed1" },
          {
            id: "10683",
            name: "\u0110o\u00e0n Ph\u00fa T\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10684",
            name: "\u0110o\u00e0n Tr\u1ea7n Nghi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10685",
            name: "\u0110\u1ed3ng B\u00e0i 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10686",
            name: "\u0110\u1ed3ng B\u00e0i 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10687",
            name: "\u0110\u1ed3ng B\u00e0i 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10688",
            name: "\u0110\u1ed3ng K\u00e8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10689",
            name: "\u0110\u1ed3ng Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10690",
            name: "\u0110\u1ed3ng Tr\u00ed 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10691",
            name: "\u0110\u1ed3ng Tr\u00ed 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10692",
            name: "\u0110\u1ed3ng Tr\u00ed 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10693",
            name: "\u0110\u1ed3ng Tr\u00ed 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10694",
            name: "\u0110\u1ed3ng Tr\u00ed 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10695",
            name: "\u0110\u1ed3ng Tr\u00ed 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10696",
            name: "\u0110\u1ee9c L\u1ee3i 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10697",
            name: "\u0110\u1ee9c L\u1ee3i I",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10702",
            name: "Gi\u00e1p V\u0103n C\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10703",
            name: "H\u00e0 H\u1ed3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10704",
            name: "H\u00e0 V\u0103n T\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10705",
            name: "H\u00e0m Trung 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10706",
            name: "H\u00e0m Trung 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10707",
            name: "H\u00e0m Trung 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10708",
            name: "H\u00e0m Trung 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10709",
            name: "H\u00e0m Trung 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10710",
            name: "H\u00e0m Trung 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10711",
            name: "H\u00e0m Trung 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10712",
            name: "H\u00e0m Trung 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10713",
            name: "H\u1ed3 B\u00e1 \u00d4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10714",
            name: "H\u1ed3 Qu\u00fd Ly",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10715",
            name: "H\u1ed3 S\u1ef9 \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10716",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10717",
            name: "H\u00f2a Hi\u1ec7p 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10718",
            name: "H\u00f2a Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10719",
            name: "H\u00f2a Minh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10720",
            name: "Ho\u00e0 Minh 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10721",
            name: "H\u00f2a Minh 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10722",
            name: "H\u00f2a Minh 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10723",
            name: "H\u00f2a Minh 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10724",
            name: "H\u00f2a Minh 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10725",
            name: "Ho\u00e0 Minh 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10726",
            name: "H\u00f2a Minh 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10727",
            name: "H\u00f2a Minh 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10728",
            name: "H\u00f2a Minh 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10729",
            name: "H\u00f2a Minh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10730",
            name: "H\u00f2a Minh 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10731",
            name: "H\u00f2a Minh 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10732",
            name: "Ho\u00e0 Minh 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10733",
            name: "H\u00f2a Minh 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10734",
            name: "H\u00f2a Minh 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10735",
            name: "H\u00f2a Minh 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10736",
            name: "H\u00f2a Minh 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10737",
            name: "H\u00f2a Minh 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10738",
            name: "H\u00f2a Minh 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10739",
            name: "H\u00f2a Minh 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10740",
            name: "H\u00f3a M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10741",
            name: "H\u00f2a M\u1ef9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10742",
            name: "H\u00f2a M\u1ef9 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10743",
            name: "H\u00f2a M\u1ef9 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10744",
            name: "H\u00f2a M\u1ef9 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10745",
            name: "H\u00f2a M\u1ef9 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10746",
            name: "Ho\u00e0 Nam 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10747",
            name: "H\u00f2a Nam 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10748",
            name: "H\u00f2a Nam 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10749",
            name: "H\u00f2a Nam 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10750",
            name: "H\u00f2a Nam 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10751",
            name: "H\u00f2a Nam 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10752",
            name: "Ho\u00e0ng Minh Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10753",
            name: "Ho\u00e0ng Th\u1ecb Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10754",
            name: "Ho\u00e0ng V\u0103n Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10755",
            name: "H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10756",
            name: "Hu\u1ef3nh Th\u1ecb B\u1ea3o H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10757",
            name: "Ki\u1ec1u O\u00e1nh M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10758",
            name: "Kim C\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10759",
            name: "Kim Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10760",
            name: "Kim Li\u00ean 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10761",
            name: "Kim Li\u00ean 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10762",
            name: "Kim Li\u00ean 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10763",
            name: "Kinh D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10764",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10765",
            name: "L\u00e2m Nh\u0129",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10766",
            name: "L\u00e2m Quang Th\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10767", name: "L\u00ea A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10768",
            name: "L\u00ea C\u00f4ng Ki\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10769",
            name: "L\u00ea Do\u00e3n Nh\u1ea1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10770",
            name: "L\u00ea Hi\u1ebfn Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10771",
            name: "L\u00ea Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10772",
            name: "L\u00ea Thi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10773",
            name: "L\u00ea T\u1ef1 Nh\u1ea5t Th\u1ed1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10774",
            name: "L\u00ea T\u1ef1 Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10775",
            name: "L\u00ea V\u0103n M\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10776",
            name: "L\u00ea V\u0103n Mi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10777",
            name: "L\u00ea V\u0103n S\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10778",
            name: "L\u00ea V\u0103n Th\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10779",
            name: "L\u01b0\u01a1ng Kh\u00e1nh Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10780",
            name: "L\u01b0\u01a1ng Tr\u00fac \u0110\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10781",
            name: "L\u00fd Ch\u00ednh Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10782",
            name: "L\u00fd Th\u00e1i T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10783",
            name: "L\u00fd Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10784",
            name: "Mai V\u0103n Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10785", name: "M\u00ea Linh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10786",
            name: "M\u1ed9c B\u00e0i 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10787",
            name: "M\u1ed9c B\u00e0i 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10788",
            name: "M\u1ed9c B\u00e0i 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10789",
            name: "M\u1ed9c B\u00e0i 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10790",
            name: "M\u1ed9c B\u00e0i 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10791",
            name: "M\u1ed9c B\u00e0i 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10792",
            name: "M\u1ed9c B\u00e0i 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10793",
            name: "M\u1ed9c B\u00e0i 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10794",
            name: "M\u1ed9c B\u00e0i 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10795", name: "Nam Cao", prefix: "Ph\u1ed1" },
          { id: "10796", name: "Nam \u00d4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10797",
            name: "Nam Tr\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10798",
            name: "Ng\u00f4 Ch\u00e2n L\u01b0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10799",
            name: "Ng\u00f4 \u0110\u1ee9c K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10800",
            name: "Ng\u00f4 S\u0129 Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10801",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10802",
            name: "Ng\u00f4 V\u0103n S\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10803",
            name: "Ng\u00f4 Xu\u00e2n Thu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10804",
            name: "Ng\u1ecdc H\u1ed3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10805",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10806",
            name: "Nguy\u1ec5n B\u00e1 Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10807",
            name: "Nguy\u1ec5n C\u1ea3nh Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10808",
            name: "Nguy\u1ec5n C\u1ea3nh D\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10809",
            name: "Nguy\u1ec5n Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10810",
            name: "Nguy\u1ec5n Ch\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10811",
            name: "Nguy\u1ec5n Chu S\u0129",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10812",
            name: "Nguy\u1ec5n C\u00f4ng Hoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10813",
            name: "Nguy\u1ec5n \u0110\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10814",
            name: "Nguy\u1ec5n \u0110\u00ecnh T\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10815",
            name: "Nguy\u1ec5n \u0110\u1ed7 Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10816",
            name: "Nguy\u1ec5n H\u00e0ng Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10817", name: "Nguy\u1ec5n Huy T\u1ef1", prefix: "Ph\u1ed1" },
          {
            id: "10818",
            name: "Nguy\u1ec5n Huy T\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10819",
            name: "Nguy\u1ec5n Kh\u1eafc Nhu",
            prefix: "Ph\u1ed1",
          },
          {
            id: "10820",
            name: "Nguy\u1ec5n Khoa Chi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10821",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10822",
            name: "Nguy\u1ec5n Ki\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10823",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10824",
            name: "Nguy\u1ec5n Minh Ch\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10825",
            name: "Nguy\u1ec5n Minh Kh\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10826",
            name: "Nguy\u1ec5n Minh Kh\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10827",
            name: "Nguy\u1ec5n M\u1ed9ng Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10828",
            name: "Nguy\u1ec5n Nh\u01b0 H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10829",
            name: "Nguy\u1ec5n Ph\u01b0\u1edbc Chu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10830",
            name: "Nguy\u1ec5n Sinh S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10831",
            name: "Nguy\u1ec5n T\u1ea5n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10832",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10833",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10834",
            name: "Nguy\u1ec5n Th\u1ecb B\u1ea3y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10835",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10836",
            name: "Nguy\u1ec5n Th\u1ecb Th\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10837",
            name: "Nguy\u1ec5n Th\u00fay",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10838",
            name: "Nguy\u1ec5n T\u01b0\u1eddng Ph\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10839",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10840",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10841",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10842", name: "Nguy\u1ec5n X\u00ed", prefix: "Ph\u1ed1" },
          {
            id: "10843",
            name: "Nh\u01a1n H\u00f2a 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10844",
            name: "Nh\u01a1n Ho\u00e0 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10845",
            name: "Nh\u01a1n H\u00f2a 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10846",
            name: "Ninh T\u1ed1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10847",
            name: "Ph\u1ea1m \u0110\u00ecnh H\u1ed5",
            prefix: "Ph\u1ed1",
          },
          {
            id: "10848",
            name: "Ph\u1ea1m Nh\u01b0 X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10849",
            name: "Ph\u1ea1m Th\u1ecb Lam Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10850",
            name: "Ph\u1ea1m V\u0103n Ng\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10851",
            name: "Ph\u1ea1m V\u0103n Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10852",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10853",
            name: "Phan Th\u1ecb Lan Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10854",
            name: "Phan Th\u1ecb N\u1ec3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10855",
            name: "Phan V\u0103n \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10856",
            name: "Phan V\u0103n \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10857",
            name: "Phan V\u0103n Thu\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10858",
            name: "Phan V\u0103n Tr\u01b0\u01a1\u0300ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10859",
            name: "Ph\u00fa L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10860",
            name: "Ph\u00fa L\u1ed9c 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10861",
            name: "Ph\u00fa L\u1ed9c 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10862",
            name: "Ph\u00fa L\u1ed9c 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10863",
            name: "Ph\u00fa L\u1ed9c 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10864",
            name: "Ph\u00fa L\u1ed9c 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10865",
            name: "Ph\u00fa L\u1ed9c 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10866",
            name: "Ph\u00fa L\u1ed9c 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10867",
            name: "Ph\u00fa L\u1ed9c 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10868",
            name: "Ph\u00fa L\u1ed9c 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10869",
            name: "Ph\u00fa L\u1ed9c 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10870",
            name: "Ph\u00fa L\u1ed9c 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10871",
            name: "Ph\u00fa L\u1ed9c 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10872",
            name: "Ph\u00fa L\u1ed9c 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10873",
            name: "Phu\u0301 L\u00f4\u0323c 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10874",
            name: "Ph\u00fa L\u1ed9c 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10875",
            name: "Ph\u00fa L\u1ed9c 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10876",
            name: "Ph\u00fa L\u1ed9c 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10877",
            name: "Ph\u00fa Th\u1ea1nh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10878",
            name: "Ph\u00fa Th\u1ea1nh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10879",
            name: "Ph\u00fa Th\u1ea1nh 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10880",
            name: "Ph\u00fa Th\u1ea1nh 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10881",
            name: "Ph\u00fa Th\u1ea1nh 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10882",
            name: "Ph\u00fa Th\u1ea1nh 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10883",
            name: "Ph\u00fa Th\u1ea1nh 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10884",
            name: "Ph\u00fa Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10885",
            name: "Ph\u00fa Xu\u00e2n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10886",
            name: "Ph\u00fa Xu\u00e2n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10887",
            name: "Ph\u00fa Xu\u00e2n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10888",
            name: "Ph\u00fa Xu\u00e2n 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10889",
            name: "Ph\u00fa Xu\u00e2n 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10890",
            name: "Ph\u00fa Xu\u00e2n 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10891",
            name: "Ph\u00fa Xu\u00e2n 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10892",
            name: "Ph\u00fa Xu\u00e2n 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10893",
            name: "Ph\u00f9ng Ch\u00ed Ki\u00ean",
            prefix: "Ph\u1ed1",
          },
          { id: "10894", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "10895",
            name: "Quang Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10896",
            name: "Quang Th\u00e0nh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10897",
            name: "Quang Th\u00e0nh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10898",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10899", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10900", name: "S\u1ed1 11B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10901", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10902", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10903", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10904", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10905",
            name: "S\u1eed Hy Nhan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10906",
            name: "Su\u1ed1i L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10907", name: "T\u1ea1 Quang B\u1eedu", prefix: "Ph\u1ed1" },
          {
            id: "10908",
            name: "T\u00e2n Tr\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10909",
            name: "T\u00e2y B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10910",
            name: "T\u00e2y B\u1eafc 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10911",
            name: "T\u00e2y B\u1eafc 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10912",
            name: "Thanh T\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10913", name: "Thanh Vinh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10914",
            name: "Thanh Vinh 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10915",
            name: "Thanh Vinh 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10916",
            name: "Th\u00e0nh Vinh 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10917",
            name: "Thanh Vinh 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10918",
            name: "Thanh Vinh 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10919",
            name: "Thanh Vinh 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10920", name: "Thanh Vinh 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "10921", name: "Thanh Vinh 8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10922",
            name: "Th\u00edch Qu\u1ea3ng \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10923",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10924",
            name: "T\u00f4n \u0110\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10925",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "10926",
            name: "T\u1ed1ng Duy T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10927",
            name: "T\u1ed1t \u0110\u1ed9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10928",
            name: "Tr\u00e0 Na 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10929",
            name: "Tr\u00e0 Na 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10930",
            name: "Tr\u00e0 Na 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10931",
            name: "Tr\u1ea7n Anh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10932",
            name: "Tr\u1ea7n B\u00edch San",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10933",
            name: "Tr\u1ea7n \u0110\u00ecnh Tri",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10934",
            name: "Tr\u1ea7n Nguy\u00ean \u0110\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10935",
            name: "Tr\u1ea7n Ph\u01b0\u1edbc Chu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10936",
            name: "Tr\u1ea7n Qu\u00fd Kho\u00e1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10937",
            name: "Tr\u1ea7n T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10938",
            name: "Tr\u1ea7n V\u0103n \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10939",
            name: "Tr\u1ea7n V\u0103n K\u1ef7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10940",
            name: "Tr\u1ecbnh Kh\u1eafc L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10941",
            name: "Trung Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10942",
            name: "Trung Ngh\u0129a 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10943",
            name: "Trung Ngh\u0129a 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10944",
            name: "Trung Ngh\u0129a 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10945",
            name: "Trung Ngh\u0129a 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10946",
            name: "Trung Ngh\u0129a 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10947",
            name: "Trung Ngh\u0129a 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10948",
            name: "Trung Ngh\u0129a 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10949",
            name: "Trung Ngh\u0129a 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10950",
            name: "Trung Ngh\u0129a 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10951",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10952",
            name: "Tr\u01b0\u01a1ng V\u0103n \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10953",
            name: "Tr\u01b0\u01a1ng V\u00e2n L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10954",
            name: "T\u00fa M\u1ee1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10955",
            name: "T\u00fa Qu\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10956",
            name: "V\u0103n Th\u00e1nh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10957",
            name: "V\u0103n Th\u00e1nh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10958",
            name: "V\u0103n Th\u00e1nh 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10959",
            name: "V\u0103n Th\u00e1nh 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10960",
            name: "V\u00f5 Duy D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10961",
            name: "V\u0169 Huy T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10962",
            name: "V\u0169 L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10963", name: "V\u0169 Ng\u1ecdc Phan", prefix: "Ph\u1ed1" },
          {
            id: "10964",
            name: "V\u0169 Qu\u1ef3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10965",
            name: "Xu\u00e2n Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10966",
            name: "Xu\u00e2n Thi\u1ec1u 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10967",
            name: "Xu\u00e2n Thi\u1ec1u 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10968",
            name: "Xu\u00e2n Thi\u1ec1u 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10969",
            name: "Xu\u00e2n Thi\u1ec1u 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10970",
            name: "Xu\u00e2n Thi\u1ec1u 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10971",
            name: "Xu\u00e2n Thi\u1ec1u 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10972",
            name: "Xu\u00e2n Thi\u1ec1u 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10973",
            name: "Xu\u00e2n Thi\u1ec1u 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10974",
            name: "Xu\u00e2n Thi\u1ec1u 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10975",
            name: "Xu\u00e2n Thi\u1ec1u 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10976",
            name: "Xu\u00e2n Thi\u1ec1u 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10977",
            name: "Xu\u00e2n Thi\u1ec1u 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10978",
            name: "Xu\u00e2n Thi\u1ec1u 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10979",
            name: "Xu\u00e2n Thi\u1ec1u 28",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10980",
            name: "Xu\u00e2n Thi\u1ec1u 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10981",
            name: "Xu\u00e2n Thi\u1ec1u 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10982",
            name: "Xu\u00e2n Thi\u1ec1u 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10983",
            name: "Xu\u00e2n Thi\u1ec1u 33",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10984",
            name: "Xu\u00e2n Thi\u1ec1u 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10985",
            name: "Xu\u00e2n Thi\u1ec1u 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10986",
            name: "Xu\u00e2n Thi\u1ec1u 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10987",
            name: "Xu\u00e2n Thi\u1ec1u 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10988",
            name: "Xu\u00e2n Thi\u1ec1u 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10989",
            name: "Xu\u00e2n Thi\u1ec1u 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10990",
            name: "X\u01b0\u01a1ng C\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10991",
            name: "Y\u00ean Khu\u00ea 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10992",
            name: "Y\u00ean Th\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2049",
            name: "Aurora Da Nang City",
            lat: "16.052577972412",
            lng: "108.16662597656",
          },
          {
            id: "2050",
            name: "Aurora HouseforRent",
            lat: "16.052711486816",
            lng: "108.16683959961",
          },
          {
            id: "2051",
            name: "Ch\u0103m River Park",
            lat: "16.118709564209",
            lng: "108.1245803833",
          },
          {
            id: "2052",
            name: "Dragon City Park",
            lat: "16.098773956299",
            lng: "108.12654876709",
          },
          {
            id: "2053",
            name: "Ecorio",
            lat: "16.112564086914",
            lng: "108.12323760986",
          },
          {
            id: "2054",
            name: "Gold Beach - \u0110\u00e0 N\u1eb5ng City",
            lat: "16.065732955933",
            lng: "108.16954803467",
          },
          {
            id: "2055",
            name: "Golden Hills City",
            lat: "16.106407165527",
            lng: "108.10873413086",
          },
          {
            id: "2056",
            name: "H\u00f2a Kh\u00e1nh Residence",
            lat: "16.077058792114",
            lng: "108.13694000244",
          },
          {
            id: "2057",
            name: "KDC Ph\u00fa L\u1ed9c An",
            lat: "16.073684692383",
            lng: "108.16781616211",
          },
          {
            id: "2058",
            name: "K\u0110T An Ph\u01b0\u1edbc",
            lat: "16.064163208008",
            lng: "108.17938232422",
          },
          {
            id: "2059",
            name: "Khu d\u00e2n c\u01b0 H\u00f2a Minh 5",
            lat: "16.055025100708",
            lng: "108.16855621338",
          },
          {
            id: "2060",
            name: "Khu \u0111\u00f4 th\u1ecb Bulova",
            lat: "16.082162857056",
            lng: "108.1650314331",
          },
          {
            id: "2061",
            name: "Kim Long Nam City",
            lat: "16.073560714722",
            lng: "108.16074371338",
          },
          {
            id: "2062",
            name: "Lakeside Palace",
            lat: "16.093572616577",
            lng: "108.12629699707",
          },
          {
            id: "2063",
            name: "Luxury Beach",
            lat: "16.080743789673",
            lng: "108.16516876221",
          },
          {
            id: "2064",
            name: "Marina \u0110\u00e0 N\u1eb5ng Bay",
            lat: "16.074529647827",
            lng: "108.16146850586",
          },
          {
            id: "2065",
            name: "Nam Tr\u00e2n Central Park",
            lat: "16.061279296875",
            lng: "108.17114257812",
          },
          {
            id: "2066",
            name: "New \u0110\u00e0 N\u1eb5ng City",
            lat: "16.056694030762",
            lng: "108.1672668457",
          },
          {
            id: "2067",
            name: "Pandora City",
            lat: "16.087678909302",
            lng: "108.14781951904",
          },
          {
            id: "2068",
            name: "Ph\u1ed1 Garden Sea",
            lat: "16.081209182739",
            lng: "108.16575622559",
          },
          {
            id: "2069",
            name: "Phoenix Complex \u0110\u00e0 N\u1eb5ng",
            lat: "16.077058792114",
            lng: "108.13694000244",
          },
          {
            id: "2070",
            name: "Ph\u00fa Qu\u00fd",
            lat: "16.077058792114",
            lng: "108.13694000244",
          },
          {
            id: "2071",
            name: "Ph\u00f9ng H\u01b0ng",
            lat: "16.073793411255",
            lng: "108.16691589356",
          },
          {
            id: "2072",
            name: "Quang Th\u00e0nh 3B",
            lat: "16.085903167725",
            lng: "108.14337921143",
          },
          {
            id: "2073",
            name: "Sun Bay \u0110\u00e0 N\u1eb5ng",
            lat: "16.081251144409",
            lng: "108.16644287109",
          },
          {
            id: "2074",
            name: "Thien Park",
            lat: "16.123424530029",
            lng: "108.10635375977",
          },
        ],
      },
      {
        id: "60",
        name: "Ng\u0169 H\u00e0nh S\u01a1n",
        wards: [
          { id: "949", name: " Khu\u00ea M\u1ef9", prefix: "Ph\u01b0\u1eddng" },
          { id: "950", name: "H\u00f2a H\u1ea3i", prefix: "Ph\u01b0\u1eddng" },
          { id: "951", name: "H\u00f2a Qu\u00fd", prefix: "Ph\u01b0\u1eddng" },
          { id: "952", name: "M\u1ef9 An", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "10993", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "10994",
            name: "An B\u1eafc 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10995",
            name: "An B\u1eafc 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10996",
            name: "An B\u1eafc 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10997",
            name: "An B\u1eafc 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "10998",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "10999", name: "An N\u00f4ng", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11000",
            name: "An Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11001",
            name: "An Th\u01b0\u1ee3ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11002",
            name: "An Th\u01b0\u1ee3ng 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11003",
            name: "An Th\u01b0\u1ee3ng 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11004",
            name: "An Th\u01b0\u1ee3ng 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11005",
            name: "An Th\u01b0\u1ee3ng 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11006",
            name: "An Th\u01b0\u1ee3ng 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11007",
            name: "An Th\u01b0\u1ee3ng 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11008",
            name: "An Th\u01b0\u1ee3ng 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11009",
            name: "An Th\u01b0\u1ee3ng 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11010",
            name: "An Th\u01b0\u1ee3ng 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11011",
            name: "An Th\u01b0\u1ee3ng 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11012",
            name: "An Th\u01b0\u1ee3ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11013",
            name: "An Th\u01b0\u1ee3ng 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11014",
            name: "An Th\u01b0\u1ee3ng 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11015",
            name: "An Th\u01b0\u1ee3ng 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11016",
            name: "An Th\u01b0\u1ee3ng 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11017",
            name: "An Th\u01b0\u1ee3ng 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11018",
            name: "An Th\u01b0\u1ee3ng 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11019",
            name: "An Th\u01b0\u1ee3ng 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11020",
            name: "An Th\u01b0\u1ee3ng 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11021",
            name: "An Th\u01b0\u1ee3ng 28",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11022",
            name: "An Th\u01b0\u1ee3ng 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11023",
            name: "An Th\u01b0\u1ee3ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11024",
            name: "An Th\u01b0\u1ee3ng 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11025",
            name: "An Th\u01b0\u1ee3ng 31",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11026",
            name: "An Th\u01b0\u1ee3ng 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11027",
            name: "An Th\u01b0\u1ee3ng 33",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11028",
            name: "An Th\u01b0\u1ee3ng 34",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11029",
            name: "An Th\u01b0\u1ee3ng 35",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11030",
            name: "An Th\u01b0\u1ee3ng 36",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11031",
            name: "An Th\u01b0\u1ee3ng 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11032",
            name: "An Th\u01b0\u1ee3ng 38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11033",
            name: "An Th\u01b0\u1ee3ng 39",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11034",
            name: "An Th\u01b0\u1ee3ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11035",
            name: "An Th\u01b0\u1ee3ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11036",
            name: "An Th\u01b0\u1ee3ng 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11037",
            name: "An Th\u01b0\u1ee3ng 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11038",
            name: "An Th\u01b0\u1ee3ng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11039",
            name: "An Th\u01b0\u1ee3ng 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11040",
            name: "An T\u01b0 C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11041", name: "Anh Th\u01a1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11042",
            name: "\u1ea4p B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11043", name: "B1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11044", name: "B2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11045", name: "B3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11046", name: "B4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11047", name: "B5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11048",
            name: "B\u00e0 Bang Nh\u00e3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11049",
            name: "B\u00e1 Giang 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11050",
            name: "B\u00e1 Gi\u00e1ng 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11051",
            name: "B\u00e1 Gi\u00e1ng 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11052",
            name: "B\u00e1 Gi\u00e1ng 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11053",
            name: "B\u00e1 Gi\u00e1ng 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11054",
            name: "B\u00e1 Gi\u00e1ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11055",
            name: "B\u00e1 Gi\u00e1ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11056",
            name: "B\u00e1 Gi\u00e1ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11057",
            name: "B\u00e1 Gi\u00e1ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11058",
            name: "B\u00e1 Gi\u00e1ng 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11059",
            name: "B\u00e1 Gi\u00e1ng 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11060",
            name: "B\u00e1 Gi\u00e1ng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11061",
            name: "B\u00e1 Gi\u00e1ng 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11062",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          {
            id: "11063",
            name: "B\u00e1 T\u00f9ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11064",
            name: "B\u1eafc M\u1ef9 An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11065",
            name: "B\u00e1t N\u00e0n C\u00f4ng ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11066",
            name: "B\u00ecnh Gi\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11067",
            name: "B\u00ecnh K\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11068",
            name: "B\u00f9i B\u1ec9nh Uy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11069",
            name: "B\u00f9i T\u00e1 H\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11070",
            name: "B\u00f9i Th\u1ebf M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11071",
            name: "Cao H\u1ed3ng L\u00e3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11072",
            name: "Cao S\u01a1n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11073",
            name: "Cao S\u01a1n 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11074",
            name: "Cao S\u01a1n 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11075",
            name: "Cao S\u01a1n 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11076",
            name: "Cao S\u01a1n 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11077",
            name: "Cao S\u01a1n 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11078",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11079",
            name: "Ch\u00e2u Th\u1ecb V\u0129nh T\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11080",
            name: "Ch\u1ebf Lan Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11081",
            name: "Chu C\u1ea9m Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11082", name: "Chu Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11083",
            name: "Ch\u01b0\u01a1ng D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11084", name: "D", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11123",
            name: "Do\u00e3n K\u1ebf Thi\u1ec7n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "11126",
            name: "Do\u00e3n U\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11150",
            name: "D\u01b0\u01a1ng Khu\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11151",
            name: "D\u01b0\u01a1ng Th\u1ecb Xu\u00e2n Qu\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11152",
            name: "D\u01b0\u01a1ng Th\u01b0\u1ee3ng \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11153",
            name: "D\u01b0\u01a1ng T\u00f4n H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11154",
            name: "D\u01b0\u01a1ng T\u1eed Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11155",
            name: "D\u01b0\u01a1ng T\u1ef1 Qu\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11085",
            name: "\u0110a M\u1eb7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11086",
            name: "\u0110a M\u1eb7n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11087",
            name: "\u0110a M\u1eb7n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11088",
            name: "\u0110a M\u1eb7n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11089",
            name: "\u0110a M\u1eb7n 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11090",
            name: "\u0110a M\u1eb7n 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11091",
            name: "\u0110a M\u1eb7n 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11092",
            name: "\u0110a M\u1eb7n 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11093",
            name: "\u0110a M\u1eb7n 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11094",
            name: "\u0110a M\u1eb7n 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11095",
            name: "\u0110a M\u1eb7n \u0110\u00f4ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11096",
            name: "\u0110a M\u1eb7n \u0110\u00f4ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11097",
            name: "\u0110a M\u1eb7n \u0110\u00f4ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11098",
            name: "\u0110a M\u1ef9 T\u00e2y 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11099",
            name: "\u0110a Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11100",
            name: "\u0110a Ph\u01b0\u1edbc 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11101",
            name: "\u0110a Ph\u01b0\u1edbc 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11102",
            name: "\u0110a Ph\u01b0\u1edbc 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11103",
            name: "\u0110a Ph\u01b0\u1edbc 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11104",
            name: "\u0110a Ph\u01b0\u1edbc 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11105",
            name: "\u0110a Ph\u01b0\u1edbc 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11106",
            name: "\u0110a Ph\u01b0\u1edbc 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11107",
            name: "\u0110a Ph\u01b0\u1edbc 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11108",
            name: "\u0110a Ph\u01b0\u1edbc 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11109",
            name: "\u0110a Ph\u01b0\u1edbc 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11110",
            name: "\u0110\u1ea1i An 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11111",
            name: "\u0110\u1ea1i An 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11112",
            name: "\u0110\u1ea1i An 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11113",
            name: "\u0110\u1ea1i An 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11114",
            name: "\u0110\u1eb7ng Nguy\u00ean C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11115",
            name: "\u0110\u1eb7ng Th\u00e1i Th\u00e2n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "11116",
            name: "\u0110\u1eb7ng V\u0103n B\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11117",
            name: "\u0110\u1eb7ng Xu\u00e2n Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11118",
            name: "\u0110\u00e0o Duy T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11119",
            name: "\u0110\u00ecnh \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11120",
            name: "\u0110inh Gia Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11121",
            name: "\u0110\u1ed7 B\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11122",
            name: "\u0110\u1ed7 B\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11124",
            name: "\u0110o\u00e0n Khu\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11125",
            name: "\u0110o\u00e0n Khu\u00ea 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11127",
            name: "\u0110\u1ed3ng B\u00e0i 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11128",
            name: "\u0110\u00f4ng H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11129",
            name: "\u0110\u00f4ng H\u1ea3i 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11130",
            name: "\u0110\u00f4ng H\u1ea3i 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11131",
            name: "\u0110\u00f4ng H\u1ea3i 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11132",
            name: "\u0110\u00f4ng H\u1ea3i 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11133",
            name: "\u0110\u00f4ng H\u1ea3i 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11134",
            name: "\u0110\u00f4ng H\u1ea3i 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11135",
            name: "\u0110\u00f4ng H\u1ea3i 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11136",
            name: "\u0110\u00f4ng H\u1ea3i 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11137",
            name: "\u0110\u00f4ng H\u1ea3i 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11138",
            name: "\u0110\u00f4ng H\u1ea3i 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11139",
            name: "\u0110\u00f4ng H\u1ea3i 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11140",
            name: "\u0110\u00f4ng H\u1ea3i 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11141",
            name: "\u0110\u00f4ng H\u1ea3i 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11142",
            name: "\u0110\u00f4ng H\u1ea3i 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11143",
            name: "\u0110\u1ed3ng Ph\u01b0\u1edbc Huy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11144",
            name: "\u0110\u00f4ng Tr\u00e0 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11145",
            name: "\u0110\u00f4ng Tr\u00e0 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11146",
            name: "\u0110\u00f4ng Tr\u00e0 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11147",
            name: "\u0110\u00f4ng Tr\u00e0 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11148", name: "\u0110T 603", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11149", name: "\u0110T 607", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11156",
            name: "Giang Ch\u00e2u 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11157",
            name: "Giang Ch\u00e2u 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11158",
            name: "Giang Ch\u00e2u 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11159",
            name: "H\u1ea3i H\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11160",
            name: "H\u1ea3i Tri\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11161",
            name: "H\u00e0m T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11162",
            name: "H\u1eb1ng Ph\u01b0\u01a1ng N\u1eef S\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11163",
            name: "H\u1ed3 Hu\u00e2n Nghi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11164",
            name: "H\u1ed3 Th\u1ea5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11165",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11166", name: "H\u00f2a B\u00ecnh 1", prefix: "Ph\u1ed1" },
          {
            id: "11167",
            name: "H\u00f2a B\u00ecnh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11168",
            name: "Ho\u00e0 B\u00ecnh 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11169", name: "Ho\u00e0 B\u00ecnh 4", prefix: "Ph\u1ed1" },
          {
            id: "11170",
            name: "H\u00f2a B\u00ecnh 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11171", name: "Ho\u00e0 B\u00ecnh 6", prefix: "Ph\u1ed1" },
          {
            id: "11172",
            name: "Ho\u00e0 B\u00ecnh 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11173",
            name: "Ho\u00e0 C\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11174",
            name: "Ho\u00e0i Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11175",
            name: "Ho\u00e0ng B\u1eadt \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11176",
            name: "Ho\u00e0ng B\u00ednh Ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11177",
            name: "Ho\u00e0ng C\u01a1 Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11178",
            name: "Ho\u00e0ng C\u00f4ng Ch\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11179",
            name: "Ho\u00e0ng K\u1ebf Vi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11180",
            name: "Ho\u00e0ng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11181",
            name: "Ho\u00e0ng Thi\u1ec1u Hoa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11182",
            name: "Ho\u00e0ng Tr\u1ecdng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11183",
            name: "Ho\u00e0ng V\u0103n H\u00f2e",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11184",
            name: "Ho\u00e0ng V\u0103n Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11185",
            name: "Huy\u1ec1n Tr\u00e2n C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11186",
            name: "Hu\u1ef3nh B\u00e1 Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11187",
            name: "Hu\u1ef3nh L\u1eafm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11188",
            name: "Hu\u1ef3nh Th\u1ecb M\u1ed9t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11189",
            name: "Hu\u1ef3nh V\u0103n Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11190", name: "K20", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11191",
            name: "Kh\u00e1i \u0110\u00f4ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11192",
            name: "Kh\u1ea3i \u0110\u00f4ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11193",
            name: "Kh\u00e1i \u0110\u00f4ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11194",
            name: "Kh\u00e1i \u0110\u00f4ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11195",
            name: "Khu\u00ea B\u1eafc 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11196",
            name: "Khu\u00ea B\u1eafc 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11197",
            name: "Khu\u00ea B\u1eafc 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11198",
            name: "Khu\u00ea \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11199",
            name: "Khu\u00ea M\u1ef9 \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11200",
            name: "Khu\u00ea M\u1ef9 \u0110\u00f4ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11201",
            name: "Khu\u00ea M\u1ef9 \u0110\u00f4ng 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11202",
            name: "Khu\u00ea M\u1ef9 \u0110\u00f4ng 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11203",
            name: "Khu\u00ea M\u1ef9 \u0110\u00f4ng 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11204",
            name: "Khu\u00ea M\u1ef9 \u0110\u00f4ng 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11205",
            name: "Khu\u00ea M\u1ef9 \u0110\u00f4ng 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11206",
            name: "Khu\u00ea M\u1ef9 \u0110\u00f4ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11207",
            name: "Khu\u00ea M\u1ef9 \u0110\u00f4ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11208",
            name: "Khu\u00ea M\u1ef9 \u0110\u00f4ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11209",
            name: "Khu\u00ea M\u1ef9 \u0110\u00f4ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11210",
            name: "Khu\u00ea M\u1ef9 \u0110\u00f4ng 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11211",
            name: "Khu\u00ea M\u1ef9 \u0110\u00f4ng 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11212",
            name: "Khu\u00ea M\u1ef9 \u0110\u00f4ng 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11213",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11214",
            name: "L\u00e3 Xu\u00e2n Oai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11215",
            name: "L\u00ea \u0110\u00ecnh Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11216",
            name: "L\u00ea \u0110\u1ee9c Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11217",
            name: "L\u00ea H\u1eefu Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11218",
            name: "L\u00ea H\u1eefu Tr\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11219",
            name: "L\u00ea Huy C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11220",
            name: "L\u00ea Hy C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11221",
            name: "L\u00ea L\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11222",
            name: "L\u00ea Nh\u01b0 H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11223",
            name: "L\u00ea Quang \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11224",
            name: "L\u00ea Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11225",
            name: "L\u00ea Th\u1ecb Ri\u00eang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11226",
            name: "L\u00ea Thi\u1ec7n Tri",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11227",
            name: "L\u00ea Trung \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11228",
            name: "L\u00ea V\u0103n Hi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11229",
            name: "L\u00ea V\u0103n H\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "11230",
            name: "L\u00ea V\u0103n T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11231",
            name: "L\u00ea V\u0103n Th\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11232",
            name: "L\u1ed9c Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11233",
            name: "L\u01b0\u01a1ng Th\u1ebf K\u1ef7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11234",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11235",
            name: "L\u01b0\u01a1ng Th\u00fac K\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11236",
            name: "L\u01b0u \u0110\u00ecnh Ch\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11237",
            name: "L\u01b0u Quang Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11238",
            name: "L\u01b0u Quang V\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11239",
            name: "L\u01b0u V\u0103n Lang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11240",
            name: "L\u00fd V\u0103n Ph\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11241",
            name: "L\u00fd V\u0103n T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11242",
            name: "M\u1ea1c C\u1eedu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11243",
            name: "M\u1ea1c Thi\u00ean T\u00edch",
            prefix: "Ph\u1ed1",
          },
          {
            id: "11244",
            name: "Mai \u0110\u0103ng Ch\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11245",
            name: "Minh M\u1ea1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11246",
            name: "M\u1ed9c S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11247",
            name: "M\u1ed9c S\u01a1n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11248",
            name: "M\u1ed9c S\u01a1n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11249",
            name: "M\u1ed9c S\u01a1n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11250",
            name: "M\u1ed9c S\u01a1n 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11251", name: "M\u1ef9 An", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11252", name: "M\u1ef9 An 1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11253",
            name: "M\u1ef9 An 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11254",
            name: "M\u1ef9 An 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11255",
            name: "M\u1ef9 An 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11256",
            name: "M\u1ef9 An 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11257",
            name: "M\u1ef9 An 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11258",
            name: "M\u1ef9 An 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11259",
            name: "M\u1ef9 An 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11260",
            name: "M\u1ef9 An 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11261",
            name: "M\u1ef9 An 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11262", name: "M\u1ef9 An 2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11263",
            name: "M\u1ef9 An 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11264",
            name: "M\u1ef9 An 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11265",
            name: "M\u1ef9 An 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11266",
            name: "M\u1ef9 An 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11267",
            name: "M\u1ef9 An 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11268",
            name: "M\u1ef9 An 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11269", name: "M\u1ef9 An 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11270", name: "M\u1ef9 An 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11271", name: "M\u1ef9 An 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11272", name: "M\u1ef9 An 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11273", name: "M\u1ef9 An 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11274", name: "M\u1ef9 An 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11275", name: "M\u1ef9 An 9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11276",
            name: "M\u1ef9 \u0110a \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11277",
            name: "M\u1ef9 \u0110a \u0110\u00f4ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11278",
            name: "M\u1ef9 \u0110a \u0110\u00f4ng 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11279",
            name: "M\u1ef9 \u0110a \u0110\u00f4ng 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11280",
            name: "M\u1ef9 \u0110a \u0110\u00f4ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11281",
            name: "M\u1ef9 \u0110a \u0110\u00f4ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11282",
            name: "M\u1ef9 \u0110a \u0110\u00f4ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11283",
            name: "M\u1ef9 \u0110a \u0110\u00f4ng 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11284",
            name: "M\u1ef9 \u0110a \u0110\u00f4ng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11285",
            name: "M\u1ef9 \u0110a \u0110\u00f4ng 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11286",
            name: "M\u1ef9 \u0110a T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11287",
            name: "M\u1ef9 \u0110a T\u00e2y 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11288",
            name: "M\u1ef9 \u0110a T\u00e2y 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11289",
            name: "M\u1ef9 \u0110a T\u00e2y 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11290",
            name: "M\u1ef9 \u0110a T\u00e2y 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11291",
            name: "M\u1ef9 \u0110a T\u00e2y 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11292",
            name: "M\u1ef9 \u0110a T\u00e2y 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11293",
            name: "M\u1ef9 \u0110a T\u00e2y 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11294",
            name: "M\u1ef9 \u0110a T\u00e2y 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11295",
            name: "M\u1ef9 \u0110a T\u00e2y 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11296",
            name: "M\u1ef9 S\u01a1n 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11297",
            name: "Nam K\u1ef3 Kh\u1edfi Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11298",
            name: "Nam Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11299",
            name: "Nghi\u00eam Xu\u00e2n Y\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11300",
            name: "Nghi\u00eam Xu\u00e2n Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11301",
            name: "Ng\u00f4 Th\u00ec S\u0129",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11302",
            name: "Ng\u00f4 Vi\u1ebft H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11303",
            name: "Ng\u0169 H\u00e0nh S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11304",
            name: "Nguy\u1ec5n B\u00e1 L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11305",
            name: "Nguy\u1ec5n B\u00e1 Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11306",
            name: "Nguy\u1ec5n C\u01a1 Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11310",
            name: "Nguy\u1ec5n D\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11312",
            name: "Nguy\u1ec5n Duy Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11313",
            name: "Nguy\u1ec5n Duy Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11307",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11308",
            name: "Nguy\u1ec5n \u0110\u00ecnh Hi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11309",
            name: "Nguy\u1ec5n \u0110\u00ecnh Tr\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11311",
            name: "Nguy\u1ec5n \u0110\u1ee9c Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11314",
            name: "Nguy\u1ec5n Gia Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11315",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11316",
            name: "Nguy\u1ec5n H\u1eefu H\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11317",
            name: "Nguy\u1ec5n Kh\u1eafc Vi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11318",
            name: "Nguy\u1ec5n L\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11319",
            name: "Nguy\u1ec5n Minh Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11320",
            name: "Nguy\u1ec5n Nghi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11321",
            name: "Nguy\u1ec5n Nghi\u1ec5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11322",
            name: "Nguy\u1ec5n Ph\u1ea1m Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11323",
            name: "Nguy\u1ec5n Phan Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11324",
            name: "Nguy\u1ec5n Qu\u1ed1c Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11325",
            name: "Nguy\u1ec5n T\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11326",
            name: "Nguy\u1ec5n Th\u1ea7n Hi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11327",
            name: "Nguy\u1ec5n Th\u00e0nh \u00dd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11328",
            name: "Nguy\u1ec5n Th\u1ebf K\u1ef7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11329",
            name: "Nguy\u1ec5n Thi\u1ec7n K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11330",
            name: "Nguy\u1ec5n Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11331",
            name: "Nguy\u1ec5n Th\u1ee9c \u0110\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11332",
            name: "Nguy\u1ec5n Th\u1ee9c T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11333",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11334",
            name: "Nguy\u1ec5n Tr\u1ecdng H\u1ee3p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11335",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11336",
            name: "Nguy\u1ec5n T\u01b0 Gi\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11337",
            name: "Nguy\u1ec5n Tuy\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11338",
            name: "Nguy\u1ec5n V\u0103n B\u1ed5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11339",
            name: "Nguy\u1ec5n V\u0103n Nguy\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11340",
            name: "Nguy\u1ec5n V\u0103n Tho\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11341",
            name: "Nguy\u1ec5n Xi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11342",
            name: "Nguy\u1ec5n Xu\u00e2n Y\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11343",
            name: "Non N\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11344",
            name: "Non N\u01b0\u1edbc 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11345",
            name: "N\u01b0\u1edbc M\u1eb7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11346",
            name: "N\u01b0\u1edbc M\u1eb7n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11347",
            name: "N\u01b0\u1edbc M\u1eb7n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11348",
            name: "N\u01b0\u1edbc M\u1eb7n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11349",
            name: "N\u01b0\u1edbc M\u1eb7n 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11350",
            name: "N\u01b0\u1edbc M\u1eb7n 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11351",
            name: "Ph\u1ea1m \u0110\u1ee9c Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11352",
            name: "Ph\u1ea1m H\u1eefu K\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11353",
            name: "Ph\u1ea1m H\u1eefu Nh\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11354",
            name: "Ph\u1ea1m Khi\u00eam \u00cdch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11355",
            name: "Ph\u1ea1m Ki\u00eam \u00cdch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11356",
            name: "Ph\u1ea1m Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11357",
            name: "Ph\u1ea1m Nh\u01b0 Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11358",
            name: "Ph\u1ea1m N\u1ed5i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11359",
            name: "Ph\u1ea1m Th\u1eadn Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11360",
            name: "Ph\u1ea1m Tu\u1ea5n T\u00e0i",
            prefix: "Ph\u1ed1",
          },
          {
            id: "11361",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11362",
            name: "Ph\u1ea1m Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11363",
            name: "Phan \u0110\u00ecnh Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11364",
            name: "Phan H\u00e0nh S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11365",
            name: "Ph\u1ea7n L\u0103ng 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11366",
            name: "Ph\u1ea7n L\u0103ng 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11367",
            name: "Phan Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11368",
            name: "Phan Th\u00fac Duy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11369",
            name: "Phan T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11370",
            name: "Phan T\u00f2ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11371", name: "Phan T\u1ee9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11372", name: "Phan T\u1ee9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11373",
            name: "Phan V\u0103n \u0110\u1eb7ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11374",
            name: "Qu\u00e1n Kh\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11375",
            name: "Qu\u00e1n Kh\u00e1i 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11376",
            name: "Qu\u00e1n Kh\u00e1i 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11377",
            name: "Qu\u00e1n Kh\u00e1i 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11378",
            name: "Qu\u00e1n Kh\u00e1i 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11379",
            name: "Qu\u00e1n Kh\u00e1i 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11380",
            name: "Qu\u00e1n Kh\u00e1i 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11381",
            name: "Qu\u00e1n Kh\u00e1i 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11382",
            name: "Qu\u00e1n Kh\u00e1i 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11383",
            name: "S\u01a1n Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11384",
            name: "S\u01a1n Th\u1ee7y 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11385",
            name: "S\u01a1n Th\u1ee7y 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11386",
            name: "S\u01a1n Th\u1ee7y 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11387",
            name: "S\u01a1n Th\u1ee7y 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11388",
            name: "S\u01a1n Th\u1ee7y 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11389",
            name: "S\u01a1n Th\u1ee7y 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11390",
            name: "S\u01a1n Th\u1ee7y 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11391",
            name: "S\u01a1n Th\u1ee7y 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11392",
            name: "S\u01a1n Th\u1ee7y 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11393",
            name: "S\u01a1n Th\u1ee7y 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11394",
            name: "S\u01a1n Th\u1ee7y 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11395",
            name: "S\u01a1n Th\u1ee7y \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11396",
            name: "S\u01a1n Th\u1ee7y \u0110\u00f4ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11397",
            name: "S\u01a1n Th\u1ee7y \u0110\u00f4ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11398",
            name: "S\u01a1n Th\u1ee7y \u0110\u00f4ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11399",
            name: "S\u01a1n Th\u1ee7y \u0110\u00f4ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11400",
            name: "Song H\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11401",
            name: "S\u01b0 V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11402",
            name: "T\u00e2n L\u01b0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11403",
            name: "T\u00e2n Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11404",
            name: "T\u00e2y S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11405", name: "Th\u1ea1ch Lam", prefix: "Ph\u1ed1" },
          {
            id: "11406",
            name: "Th\u00e1i V\u0103n A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11407",
            name: "Th\u0103ng  Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11408",
            name: "Th\u00e9p M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11409",
            name: "Th\u00edch Ph\u01b0\u1edbc Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11410",
            name: "Th\u1ed5 S\u01a1n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11411",
            name: "Th\u01b0\u1ee3ng \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11412",
            name: "Th\u1ee7y S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11413",
            name: "Thu\u1ef7 S\u01a1n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11414",
            name: "Th\u1ee7y S\u01a1n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11415",
            name: "Th\u1ee7y S\u01a1n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11416",
            name: "Thu\u1ef7 S\u01a1n 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11417",
            name: "Thu\u1ef7 S\u01a1n 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11418",
            name: "Ti\u1ec1n \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11419",
            name: "T\u00f4n \u0110\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11420",
            name: "T\u00f4n Th\u1ea5t Thi\u1ec7p",
            prefix: "Ph\u1ed1",
          },
          {
            id: "11421",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11422",
            name: "Tr\u00e0 Kh\u00ea 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11423",
            name: "Tr\u00e0 Kh\u00ea 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11424",
            name: "Tr\u00e0 Kh\u00ea 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11425",
            name: "Tr\u00e0 Kh\u00ea 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11426",
            name: "Tr\u00e0 Kh\u00ea 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11427",
            name: "Tr\u00e0 Kh\u00ea 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11428",
            name: "Tr\u00e0 Kh\u00ea 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11429",
            name: "Tr\u00e0 Kh\u00ea 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11430",
            name: "Tr\u00e0 L\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11431",
            name: "Tr\u1ea7n B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11432",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "11433",
            name: "Tr\u1ea7n Ho\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11434",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11436",
            name: "Tr\u1ea7n H\u1eefu D\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11435",
            name: "Tr\u1ea7n H\u1eefu \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11437",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11438",
            name: "Tr\u1ea7n Qu\u1ed1c Ho\u00e0n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "11439",
            name: "Tr\u1ea7n Qu\u1ed1c V\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11440",
            name: "Tr\u1ea7n Th\u1ecb L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11441",
            name: "Tr\u1ea7n Tr\u1ecdng Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11443",
            name: "Tr\u1ea7n V\u0103n D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11442",
            name: "Tr\u1ea7n V\u0103n \u0110\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11444",
            name: "Tr\u1ea7n V\u0103n Gi\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11445",
            name: "Tr\u1ea7n V\u0103n Hai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11446",
            name: "Tr\u1ea7n V\u0103n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11447",
            name: "Tr\u1ea7n Xu\u00e2n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11448",
            name: "Tr\u1ecbnh L\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11449",
            name: "Trung H\u00f2a 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11450",
            name: "Trung H\u00f2a 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11451",
            name: "Trung H\u00f2a 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11452",
            name: "Trung H\u00f2a 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11453",
            name: "Trung H\u00f2a 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11454",
            name: "Tr\u01b0\u01a1ng C\u00f4ng Hy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11455",
            name: "Tr\u01b0\u01a1ng \u0110\u0103ng Qu\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11456",
            name: "Tr\u01b0\u01a1ng Minh Gi\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11457",
            name: "Tr\u01b0\u1eddng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11458",
            name: "Tr\u01b0\u01a1ng V\u0103n Hi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11459",
            name: "T\u00f9ng Thi\u1ec7n V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11460",
            name: "Tuy L\u00fd V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11461",
            name: "Ung V\u0103n Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11462",
            name: "V\u00e2n \u0110\u00e0i N\u1eef S\u0129",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11463",
            name: "V\u0103n T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11464",
            name: "V\u1ea1n T\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11465",
            name: "Vi\u1ec7t B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11466",
            name: "V\u00f5 Ch\u00ed C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11467",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11468",
            name: "V\u00f5 Nh\u01b0 H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11469",
            name: "V\u00f5 V\u0103n \u0110\u1eb7ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11470",
            name: "V\u00f5 V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11471",
            name: "V\u0169 Duy \u0110o\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11472",
            name: "V\u0169 H\u1eefu L\u1ee3i",
            prefix: "Ph\u1ed1",
          },
          {
            id: "11473",
            name: "V\u0169 M\u1ed9ng Nguy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11474",
            name: "V\u0169 V\u0103n C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11475",
            name: "V\u00f9ng Trung 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11476",
            name: "V\u00f9ng Trung 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11477",
            name: "V\u00f9ng Trung 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11478",
            name: "Vu\u0300ng Trung 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11479",
            name: "V\u00f9ng Trung 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11480",
            name: "V\u00f9ng Trung 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11481",
            name: "V\u00f9ng Trung 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11482",
            name: "V\u00f9ng Trung 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11483",
            name: "V\u00f9ng Trung 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11484",
            name: "V\u00f9ng Trung 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11485",
            name: "V\u00f9ng Trung 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11486",
            name: "V\u00f9ng Trung 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11487", name: "Yersin", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "2075",
            name: "Coco Wonderland Resort",
            lat: "15.980340957642",
            lng: "108.27596282959",
          },
          {
            id: "2076",
            name: "Cocobay",
            lat: "15.967425346374",
            lng: "108.28532409668",
          },
          {
            id: "2078",
            name: "Danang Beach Resort",
            lat: "15.974594116211",
            lng: "108.27829742432",
          },
          {
            id: "2077",
            name: "\u0110\u00e0 N\u1eb5ng Pearl",
            lat: "15.98800945282",
            lng: "108.24262237549",
          },
          {
            id: "2079",
            name: "FPT City \u0110\u00e0 N\u1eb5ng",
            lat: "15.97693157196",
            lng: "108.2629776001",
          },
          {
            id: "2080",
            name: "Furama Condotel \u0110\u00e0 N\u1eb5ng",
            lat: "16.039484024048",
            lng: "108.24968719482",
          },
          {
            id: "2081",
            name: "Furama Villas",
            lat: "16.039451599121",
            lng: "108.24980926514",
          },
          {
            id: "2082",
            name: "Fusion Maia Resort",
            lat: "16.031066894531",
            lng: "108.25498199463",
          },
          {
            id: "2083",
            name: "Han River Village",
            lat: "15.982957839966",
            lng: "108.23342132568",
          },
          {
            id: "2084",
            name: "H\u00f2a Qu\u00fd City",
            lat: "16.00044631958",
            lng: "108.23336029053",
          },
          {
            id: "2085",
            name: "Hyatt Regency",
            lat: "16.013021469116",
            lng: "108.26374053955",
          },
          {
            id: "2086",
            name: "Khu \u0111\u00f4 th\u1ecb Saphia",
            lat: "16.041341781616",
            lng: "108.24880218506",
          },
          {
            id: "2087",
            name: "Nam C\u1ea7u Tuy\u00ean S\u01a1n-Nam Vi\u1ec7t \u00c1",
            lat: "16.029903411865",
            lng: "108.24016571045",
          },
          {
            id: "2088",
            name: "Naman Residences",
            lat: "15.968838691711",
            lng: "108.2845993042",
          },
          {
            id: "2089",
            name: "Norman Estates",
            lat: "15.980513572693",
            lng: "108.27773284912",
          },
          {
            id: "2090",
            name: "Olalani Resort & Condotel",
            lat: "16.024803161621",
            lng: "108.24217224121",
          },
          {
            id: "2091",
            name: "One River",
            lat: "15.991830825806",
            lng: "108.26130676269",
          },
          {
            id: "2092",
            name: "Ph\u1ed1 S\u01a1n Th\u1ee7y",
            lat: "16.01095199585",
            lng: "108.25303649902",
          },
          {
            id: "2093",
            name: "Ph\u00fa M\u1ef9 An",
            lat: "15.991097450256",
            lng: "108.26021575928",
          },
          {
            id: "2094",
            name: "Premier Village",
            lat: "16.043506622314",
            lng: "108.24837493897",
          },
          {
            id: "2095",
            name: "Sky Han River",
            lat: "16.029554367065",
            lng: "108.24293518066",
          },
          {
            id: "2096",
            name: "Smart Center House",
            lat: "16.000804901123",
            lng: "108.23274993897",
          },
          {
            id: "2097",
            name: "Sun Premier Condotel \u0110\u00e0 N\u1eb5ng",
            lat: "16.041341781616",
            lng: "108.24880218506",
          },
          {
            id: "2098",
            name: "T18 Royal Era 1",
            lat: "16.053117752075",
            lng: "108.24723815918",
          },
          {
            id: "2099",
            name: "The Dune Residences",
            lat: "15.973139762878",
            lng: "108.278465271",
          },
          {
            id: "2100",
            name: "The Ocean Apartment",
            lat: "15.979566574097",
            lng: "108.27712249756",
          },
          {
            id: "2101",
            name: "The Ocean Estates",
            lat: "15.990924835205",
            lng: "108.2770690918",
          },
          {
            id: "2102",
            name: "The Ocean Suites",
            lat: "15.990429878235",
            lng: "108.27366638184",
          },
          {
            id: "2103",
            name: "The Ocean Villas",
            lat: "16.017023086548",
            lng: "108.26024627685",
          },
          {
            id: "2104",
            name: "The Point",
            lat: "15.978657722473",
            lng: "108.27596282959",
          },
          {
            id: "2105",
            name: "TMS Luxury \u0110\u00e0 N\u1eb5ng",
            lat: "16.048707962036",
            lng: "108.24911499023",
          },
          {
            id: "2106",
            name: "Vi\u00eam \u0110\u00f4ng Central Park",
            lat: "15.985242843628",
            lng: "108.2735824585",
          },
          {
            id: "2107",
            name: "Vinpearl Premium \u0110\u00e0 N\u1eb5ng",
            lat: "16.006965637207",
            lng: "108.2659072876",
          },
        ],
      },
      {
        id: "61",
        name: "S\u01a1n Tr\u00e0",
        wards: [
          {
            id: "953",
            name: " An H\u1ea3i B\u1eafc",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "954",
            name: "An H\u1ea3i \u0110\u00f4ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "955",
            name: "An H\u1ea3i T\u00e2y",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "956", name: "M\u00e2n Th\u00e1i", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "957",
            name: "N\u1ea1i Hi\u00ean \u0110\u00f4ng ",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "958",
            name: "Ph\u01b0\u1edbc M\u1ef9",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "959", name: "Th\u1ecd Quang", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "11488", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11489", name: "An C\u01b0", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11490", name: "An C\u01b0 1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11491",
            name: "An C\u01b0 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11492", name: "An C\u01b0 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11493", name: "An C\u01b0 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11494", name: "An C\u01b0 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11495", name: "An C\u01b0 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11496", name: "An C\u01b0 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11497", name: "An C\u01b0 7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11498",
            name: "An \u0110\u1ed3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11499",
            name: "An \u0110\u1ed3n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11500",
            name: "An \u0110\u1ed3n 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11501",
            name: "An \u0110\u1ed3n 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11502",
            name: "An H\u1ea3i 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11503",
            name: "An H\u1ea3i 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11504",
            name: "An H\u1ea3i 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11505",
            name: "An H\u1ea3i 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11506",
            name: "An H\u1ea3i 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11507",
            name: "An H\u1ea3i 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11508",
            name: "An H\u1ea3i 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11509",
            name: "An H\u1ea3i 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11510",
            name: "An H\u1ea3i 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11511",
            name: "An H\u1ea3i 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11512",
            name: "An H\u1ea3i 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11513",
            name: "An H\u1ea3i 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11514",
            name: "An H\u1ea3i 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11515",
            name: "An H\u1ea3i 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11516",
            name: "An H\u1ea3i 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11517",
            name: "An H\u1ea3i 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11518",
            name: "An H\u1ea3i 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11519",
            name: "An H\u1ea3i 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11520",
            name: "An H\u1ea3i 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11521",
            name: "An H\u1ea3i B\u1eafc 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11522",
            name: "An H\u1ea3i B\u1eafc 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11523",
            name: "An H\u1ea3i B\u1eafc 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11524",
            name: "An H\u1ea3i B\u1eafc 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11525",
            name: "An H\u1ea3i B\u1eafc 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11526",
            name: "An H\u1ea3i B\u1eafc 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11527",
            name: "An H\u1ea3i B\u1eafc 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11528",
            name: "An H\u1ea3i B\u1eafc 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11529",
            name: "An H\u00f2a 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11530", name: "An M\u1ef9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11531", name: "An Nh\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11532",
            name: "An Nh\u01a1n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11533",
            name: "An Nh\u01a1n 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11534",
            name: "An Nh\u01a1n 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11535",
            name: "An Nh\u01a1n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11536",
            name: "An Nh\u01a1n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11537",
            name: "An Nh\u01a1n 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11538",
            name: "An Nh\u01a1n 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11539",
            name: "An Nh\u01a1n 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11540",
            name: "An Nh\u01a1n 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11541",
            name: "An Nh\u01a1n 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11542",
            name: "An Nh\u01a1n 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11543",
            name: "An Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11544",
            name: "An Th\u01b0\u1ee3ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11545",
            name: "An Th\u01b0\u1ee3ng 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11546",
            name: "An Th\u01b0\u1ee3ng 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11547",
            name: "An Th\u01b0\u1ee3ng 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11548",
            name: "An Th\u01b0\u1ee3ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11549",
            name: "An Th\u01b0\u1ee3ng 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11550",
            name: "An Th\u01b0\u1ee3ng 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11551",
            name: "An Th\u01b0\u1ee3ng 28",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11552",
            name: "An Th\u01b0\u1ee3ng 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11553",
            name: "An Th\u01b0\u1ee3ng 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11554",
            name: "An Th\u01b0\u1ee3ng 31",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11555",
            name: "An Th\u01b0\u1ee3ng 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11556",
            name: "An Th\u01b0\u1ee3ng 33",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11557",
            name: "An Th\u01b0\u1ee3ng 34",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11558",
            name: "An Th\u01b0\u1ee3ng 36",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11559",
            name: "An Th\u01b0\u1ee3ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11560",
            name: "An Th\u01b0\u1ee3ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11561", name: "An Trung", prefix: "Ph\u1ed1" },
          { id: "11562", name: "An Trung 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11563", name: "An Trung 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11564", name: "An Trung 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11565", name: "An Trung 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11566", name: "An Trung 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11567", name: "An Trung 8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11568",
            name: "An Trung \u0110\u00f4ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11569",
            name: "An Trung \u0110\u00f4ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11570",
            name: "An Trung \u0110\u00f4ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11571",
            name: "An Trung \u0110\u00f4ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11572",
            name: "An Trung \u0110\u00f4ng 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11573", name: "An Xu\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11574",
            name: "B\u1ea1ch \u0110\u1eb1ng \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11575",
            name: "B\u1eb1ng L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11576",
            name: "B\u1eb1ng L\u0103ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11577",
            name: "B\u1eb1ng L\u0103ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11578",
            name: "B\u1eb1ng L\u0103ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11579",
            name: "B\u1eb1ng L\u0103ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11580",
            name: "B\u1eb1ng L\u0103ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11581",
            name: "B\u00ecnh Than",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11582",
            name: "B\u00f9i D\u01b0\u01a1ng L\u1ecbch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11583",
            name: "B\u00f9i H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11584",
            name: "B\u00f9i Huy B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11585",
            name: "B\u00f9i L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11586",
            name: "B\u00f9i Qu\u1ed1c H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11587",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11588",
            name: "C\u1ea9m B\u1eafc 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11589",
            name: "Cao B\u00e1 Nh\u1ea1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11590",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11591", name: "Cao L\u1ed7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11592",
            name: "Ch\u00e2u Th\u1ecb V\u0129nh T\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11593",
            name: "Ch\u1ebf Lan Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11594",
            name: "Chu Huy M\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11595",
            name: "Ch\u01b0\u01a1ng D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11596",
            name: "C\u1ed5 M\u00e2n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11597",
            name: "C\u1ed5 M\u00e2n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11598",
            name: "C\u1ed5 M\u00e2n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11599",
            name: "C\u1ed5 M\u00e2n 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11600",
            name: "C\u1ed5 M\u00e2n 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11601",
            name: "C\u1ed5 M\u00e2n 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11602",
            name: "C\u1ed5 M\u00e2n 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11603",
            name: "C\u1ed5 M\u00e2n 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11604",
            name: "C\u1ed5 M\u00e2n 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11605", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11606",
            name: "D\u00e3 T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11628",
            name: "D\u01b0\u01a1ng \u0110\u00ecnh Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11629",
            name: "D\u01b0\u01a1ng L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11630",
            name: "D\u01b0\u01a1ng Th\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11631",
            name: "D\u01b0\u01a1ng Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11632",
            name: "D\u01b0\u01a1ng Tr\u00ed Tr\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11633",
            name: "D\u01b0\u01a1ng T\u1ef1 Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11634",
            name: "D\u01b0\u01a1ng T\u1ef1 Qu\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11635",
            name: "D\u01b0\u01a1ng V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11636",
            name: "D\u01b0\u01a1ng V\u00e2n Nga",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11607",
            name: "\u0110\u1ea1i l\u1ed9 Th\u0103ng Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11608",
            name: "\u0110\u1eb7ng Nh\u1eef L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11609",
            name: "\u0110\u1eb7ng Tr\u1ea7n C\u00f4n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "11610",
            name: "\u0110\u00e0o Duy K\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11611",
            name: "\u0110inh C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11612",
            name: "\u0110inh \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11613",
            name: "\u0110inh \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11614",
            name: "\u0110inh L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11615",
            name: "\u0110inh Nh\u1eadt Th\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11616",
            name: "\u0110inh Th\u1ecb H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11617",
            name: "\u0110\u1ed7 Anh H\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11618",
            name: "\u0110\u1ed7 B\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11619",
            name: "\u0110\u1ed7 B\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11620",
            name: "\u0110\u1ed7 H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11621",
            name: "\u0110\u1ed7 Huy B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11622",
            name: "\u0110\u1ed7 Huy Uy\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11623",
            name: "\u0110\u1ed7 Th\u1ebf Ch\u1ea5p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11624",
            name: "\u0110\u1ed7 Xu\u00e2n H\u1ee3p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11625",
            name: "\u0110\u00f4ng Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11626",
            name: "\u0110\u00f4ng Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11627",
            name: "\u0110\u00f4ng Kinh Ngh\u0129a Th\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11637",
            name: "Gi\u00e1p H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11638",
            name: "H\u00e0 B\u1ed5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11639",
            name: "H\u00e0 Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11640", name: "H\u00e0 \u0110\u1eb7c", prefix: "Ph\u1ed1" },
          {
            id: "11641",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11642",
            name: "H\u00e0 K\u1ef3 Ng\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11643",
            name: "H\u00e0 Th\u1ecb Th\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11644",
            name: "H\u00e0 T\u00f4ng Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11645",
            name: "H\u1eadu L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11646",
            name: "Hi\u1ec7p Th\u00e0nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11647",
            name: "H\u1ed3 H\u00e1n Th\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11648",
            name: "H\u1ed3 H\u1ecdc L\u00e3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11649",
            name: "H\u1ed3 Nghinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11650",
            name: "H\u1ed3 S\u0129 Ph\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11651",
            name: "H\u1ed3 S\u0129 T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11652",
            name: "H\u1ed3 S\u1ef9 T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11653",
            name: "H\u1ed3 Th\u1ea5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11654",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11655",
            name: "Hoa H\u1ed3ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11656",
            name: "Hoa H\u1ed3ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11657",
            name: "Hoa H\u1ed3ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11658",
            name: "Hoa H\u1ed3ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11659", name: "Hoa L\u01b0", prefix: "Ph\u1ed1" },
          {
            id: "11660",
            name: "Hoa Ph\u01b0\u1ee3ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11661",
            name: "Hoa Ph\u01b0\u1ee3ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11662",
            name: "Ho\u00e0i Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11663",
            name: "Ho\u00e0ng B\u00edch S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11664",
            name: "Ho\u00e0ng \u0110\u1ee9c L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11665",
            name: "Ho\u00e0ng K\u1ebf Vi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11666",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11667",
            name: "Ho\u00e0ng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11668",
            name: "Ho\u00e0ng S\u0129 Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11669",
            name: "H\u01b0\u01a1ng H\u1ea3i Thi\u1ec1n S\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11670", name: "Huy Du", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11671",
            name: "Kh\u00fac H\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11672",
            name: "Kh\u00fac Th\u1eeba D\u1ee5",
            prefix: "Ph\u1ed1",
          },
          {
            id: "11673",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11674",
            name: "L\u00e2m Ho\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11675",
            name: "L\u00ea B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11676",
            name: "L\u00ea B\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11677",
            name: "L\u00ea C\u1ea3nh Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11678",
            name: "L\u00ea Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11679",
            name: "L\u00ea \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11680",
            name: "L\u00ea \u0110\u1ee9c Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11681",
            name: "L\u00ea H\u1eefu Ki\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11682",
            name: "L\u00ea H\u1eefu Tr\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11683",
            name: "L\u00ea L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11684",
            name: "L\u00ea L\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11685",
            name: "L\u00ea Ma\u0323nh Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11686",
            name: "L\u00ea Minh Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11687", name: "L\u00ea Ninh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11688",
            name: "L\u00ea Ph\u1ee5 Tr\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11689",
            name: "L\u00ea Ph\u1ee5ng Hi\u1ec3u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "11690",
            name: "L\u00ea Quang \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11691",
            name: "L\u00ea S\u0129 T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11692",
            name: "L\u00ea T\u1ea5n To\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11693",
            name: "L\u00ea T\u1ea5n Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11694",
            name: "L\u00ea Th\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11695",
            name: "L\u00ea V\u0103n Duy\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11696",
            name: "L\u00ea V\u0103n Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11697",
            name: "L\u00ea V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11698",
            name: "L\u00ea V\u0103n L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11699",
            name: "L\u00ea V\u0103n Qu\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11700",
            name: "L\u00ea V\u0103n Thi\u00eam",
            prefix: "Ph\u1ed1",
          },
          {
            id: "11701",
            name: "L\u00ea V\u0103n Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11702",
            name: "L\u00ea V\u0103n Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11703",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11704",
            name: "L\u1ed9c Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11705",
            name: "L\u1ed9c Ph\u01b0\u1edbc 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11706", name: "Loseby", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11707",
            name: "L\u01b0\u01a1ng H\u1eefu Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11708",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11709",
            name: "L\u01b0u H\u1eefu Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11710",
            name: "L\u00fd \u0110\u1ea1o Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11711",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11712",
            name: "L\u00fd Nh\u1eadt Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11713",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11714",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11715",
            name: "L\u00fd Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11716",
            name: "L\u00fd T\u1eed T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11717",
            name: "L\u00fd V\u0103n T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11718",
            name: "Mai H\u1eafc \u0110\u1ebf",
            prefix: "Ph\u1ed1",
          },
          {
            id: "11719",
            name: "M\u00e2n Quang 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11720",
            name: "M\u00e2n Quang 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11721",
            name: "M\u00e2n Quang 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11722",
            name: "M\u00e2n Quang 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11723",
            name: "M\u00e2n Quang 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11724",
            name: "M\u00e2n Quang 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11725",
            name: "M\u00e2n Quang 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11726",
            name: "M\u00e2n Quang 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11727",
            name: "M\u00e2n Quang 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11728",
            name: "M\u00e2n Quang 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11729",
            name: "M\u00e2n Quang 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11730",
            name: "M\u00e2n Quang 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11731",
            name: "M\u00e2n Quang 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11732",
            name: "M\u00e2n Quang 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11733", name: "Morrison", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11734",
            name: "M\u1ef9 An 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11735", name: "M\u1ef9 An 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11736", name: "M\u1ef9 An 8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11737",
            name: "M\u1ef9 \u0110a \u0110\u00f4ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11738",
            name: "M\u1ef9 Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11739",
            name: "M\u1ef9 Kh\u00ea 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11740",
            name: "M\u1ef9 Kh\u00ea 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11741",
            name: "M\u1ef9 Kh\u00ea 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11742",
            name: "M\u1ef9 Kh\u00ea 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11743",
            name: "M\u1ef9 Ph\u01b0\u1edbc 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11744",
            name: "N\u1ea1i Hi\u00ean \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11745",
            name: "N\u1ea1i Hi\u00ean \u0110\u00f4ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11746",
            name: "N\u1ea1i Hi\u00ean \u0110\u00f4ng 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11747",
            name: "N\u1ea1i Hi\u00ean \u0110\u00f4ng 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11748",
            name: "N\u1ea1i Hi\u00ean \u0110\u00f4ng 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11749",
            name: "N\u1ea1i Hi\u00ean \u0110\u00f4ng 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11750",
            name: "N\u1ea1i Hi\u00ean \u0110\u00f4ng 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11751",
            name: "N\u1ea1i Hi\u00ean \u0110\u00f4ng 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11752",
            name: "N\u1ea1i Hi\u00ean \u0110\u00f4ng 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11753",
            name: "N\u1ea1i Hi\u00ean \u0110\u00f4ng 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11754",
            name: "N\u1ea1i Hi\u00ean \u0110\u00f4ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11755",
            name: "N\u1ea1i Hi\u00ean \u0110\u00f4ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11756",
            name: "N\u1ea1i Hi\u00ean \u0110\u00f4ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11757",
            name: "N\u1ea1i Hi\u00ean \u0110\u00f4ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11758",
            name: "N\u1ea1i Hi\u00ean \u0110\u00f4ng 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11759",
            name: "N\u1ea1i Hi\u00ean \u0110\u00f4ng 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11760",
            name: "N\u1ea1i Hi\u00ean \u0110\u00f4ng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11761",
            name: "N\u1ea1i Hi\u00ean \u0110\u00f4ng 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11762",
            name: "N\u1ea1i H\u01b0ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11763",
            name: "N\u1ea1i H\u01b0ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11764",
            name: "N\u1ea1i Ngh\u0129a 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11765",
            name: "N\u1ea1i Ngh\u0129a 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11766",
            name: "N\u1ea1i Ngh\u0129a 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11767",
            name: "N\u1ea1i Ngh\u0129a 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11768",
            name: "N\u1ea1i Ngh\u0129a 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11769",
            name: "N\u1ea1i Ngh\u0129a 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11770",
            name: "N\u1ea1i Ngh\u0129a 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11771",
            name: "N\u1ea1i Th\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11772",
            name: "N\u1ea1i Th\u1ecbnh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11773",
            name: "N\u1ea1i Th\u1ecbnh 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11774",
            name: "N\u1ea1i Th\u1ecbnh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11775",
            name: "N\u1ea1i Th\u1ecbnh 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11776",
            name: "N\u1ea1i Th\u1ecbnh 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11777",
            name: "Nai Th\u1ecbnh 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11778",
            name: "Nai Th\u1ecbnh 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11779",
            name: "Nai Th\u1ecbnh 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11780",
            name: "N\u1ea1i Th\u1ecbnh 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11781",
            name: "N\u1ea1i T\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11782",
            name: "N\u1ea1i T\u00fa 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11783",
            name: "N\u1ea1i T\u00fa 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11784",
            name: "N\u1ea1i T\u00fa 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11785",
            name: "N\u1ea1i T\u00fa 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11786",
            name: "N\u1ea1i T\u00fa 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11787", name: "Nam Th\u1ecd", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11788",
            name: "Nam Th\u1ecd 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11789",
            name: "Nam Th\u1ecd 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11790",
            name: "Nam Th\u1ecd 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11791",
            name: "Nam Th\u1ecd 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11792",
            name: "Nam Th\u1ecd 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11793",
            name: "Nam Th\u1ecd 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11794",
            name: "Ng\u00f4 Cao L\u00e3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11795",
            name: "Ng\u00f4 Quang Huy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11796",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11797",
            name: "Ng\u00f4 Th\u00ec Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11798",
            name: "Ng\u00f4 Th\u00ec H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11799",
            name: "Ng\u00f4 Th\u00ec S\u0129",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11800",
            name: "Ng\u00f4 Th\u00ec Tr\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11801",
            name: "Ng\u00f4 Tr\u00ed H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11802",
            name: "Ng\u1ecdc H\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11803",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11804",
            name: "Nguy\u1ec5n Cao Luy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11805",
            name: "Nguy\u1ec5n Ch\u1ebf Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11806",
            name: "Nguy\u1ec5n Ch\u00ed Di\u1ec5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11807",
            name: "Nguy\u1ec5n C\u00f4ng S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11808",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11815",
            name: "Nguy\u1ec5n Duy Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11809",
            name: "Nguy\u1ec5n \u0110\u0103ng Giai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11810",
            name: "Nguy\u1ec5n \u0110\u0103ng Tuy\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11811",
            name: "Nguy\u1ec5n \u0110\u1ecba L\u00f4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11812",
            name: "Nguy\u1ec5n \u0110\u00ecnh Ho\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11813",
            name: "Nguy\u1ec5n \u0110\u1ee9c An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11814",
            name: "Nguy\u1ec5n \u0110\u1ee9c Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11816",
            name: "Nguy\u1ec5n Gia Tr\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11817",
            name: "Nguy\u1ec5n Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11818",
            name: "Nguy\u1ec5n H\u1eefu An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11819",
            name: "Nguy\u1ec5n H\u1eefu Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11820",
            name: "Nguy\u1ec5n Huy Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11821",
            name: "Nguy\u1ec5n Kh\u1eafc C\u1ea7n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "11822",
            name: "Nguy\u1ec5n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11823",
            name: "Nguy\u1ec5n L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11824",
            name: "Nguy\u1ec5n Phan Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11825",
            name: "Nguy\u1ec5n Ph\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11826",
            name: "Nguy\u1ec5n S\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11827",
            name: "Nguy\u1ec5n S\u1ef9 C\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11828",
            name: "Nguy\u1ec5n Th\u1ebf L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11829",
            name: "Nguy\u1ec5n Th\u1ecb Ba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11830",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11831",
            name: "Nguy\u1ec5n Th\u1ecb H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11832",
            name: "Nguy\u1ec5n Thi\u1ec7n K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11833",
            name: "Nguy\u1ec5n Thi\u1ebfp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11834",
            name: "Nguy\u1ec5n Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11835",
            name: "Nguy\u1ec5n Tr\u1ecdng Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11836",
            name: "Nguy\u1ec5n Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11837",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11838",
            name: "Nguy\u1ec5n Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11839",
            name: "Nguy\u1ec5n Tu\u1ea5n Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11840",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11841",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11842",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11843",
            name: "Nguy\u1ec5n V\u0103n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11844",
            name: "Nguy\u1ec5n V\u0103n Tho\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11845",
            name: "Nguy\u1ec5n Xu\u00e2n Kho\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11846",
            name: "Ph\u1ea1m C\u1ef1 L\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11847",
            name: "Ph\u1ea1m C\u1ef1c L\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11848",
            name: "Ph\u1ea1m H\u1eefu K\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11849", name: "Ph\u1ea1m Huy Th\u00f4ng", prefix: "Ph\u1ed1" },
          {
            id: "11850",
            name: "Ph\u1ea1m Ph\u00fa Ti\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11851",
            name: "Ph\u1ea1m Quang \u1ea2nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11852",
            name: "Ph\u1ea1m Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11853", name: "Ph\u1ea1m Tu", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11854",
            name: "Ph\u1ea1m V\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11855",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11856",
            name: "Ph\u1ea1m V\u0103n X\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11857",
            name: "Phan B\u00e1 Phi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11858",
            name: "Phan B\u00e1 V\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11859",
            name: "Phan B\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11860",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11861",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "11862",
            name: "Phan Huy Ch\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11863",
            name: "Phan Huy \u00cdch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11864",
            name: "Phan Huy Th\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11865", name: "Phan T\u1ee9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11866",
            name: "Phan V\u0103n H\u1edbn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11867",
            name: "Ph\u00f3 \u0110\u1ee9c Ch\u00ednh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "11868",
            name: "Ph\u00fa L\u1ed9c 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11869",
            name: "Ph\u00fac H\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11870",
            name: "Ph\u00f9ng Kh\u1eafc Khoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11871",
            name: "Ph\u01b0\u1edbc M\u1ef9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11872",
            name: "Ph\u01b0\u1edbc M\u1ef9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11873",
            name: "Ph\u01b0\u1edbc M\u1ef9 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11874",
            name: "Ph\u01b0\u1edbc M\u1ef9 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11875",
            name: "Ph\u01b0\u1edbc Tr\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11876",
            name: "Ph\u01b0\u1edbc Tr\u01b0\u1eddng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11877",
            name: "Ph\u01b0\u1edbc Tr\u01b0\u1eddng 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11878",
            name: "Ph\u01b0\u1edbc Tr\u01b0\u1eddng 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11879",
            name: "Ph\u01b0\u1edbc Tr\u01b0\u1eddng 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11880",
            name: "Ph\u01b0\u1edbc Tr\u01b0\u1eddng 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11881",
            name: "Ph\u01b0\u1edbc Tr\u01b0\u1eddng 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11882",
            name: "Ph\u01b0\u1edbc Tr\u01b0\u1eddng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11883",
            name: "Ph\u01b0\u1edbc Tr\u01b0\u1eddng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11884",
            name: "Ph\u01b0\u1edbc Tr\u01b0\u1eddng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11885",
            name: "Ph\u01b0\u1edbc Tr\u01b0\u1eddng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11886",
            name: "Ph\u01b0\u1edbc Tr\u01b0\u1eddng 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11887",
            name: "Ph\u01b0\u1edbc Tr\u01b0\u1eddng 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11888",
            name: "Ph\u01b0\u1edbc Tr\u01b0\u1eddng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11889",
            name: "Ph\u01b0\u1edbc Tr\u01b0\u1eddng 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11890",
            name: "Ph\u01b0\u1edbc T\u01b0\u1eddng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11891",
            name: "Ph\u01b0\u1edbc T\u01b0\u1eddng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11892", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11893", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11894",
            name: "Su\u1ed1i \u0110\u00e1 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11895",
            name: "Su\u1ed1i \u0110\u00e1 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11896",
            name: "Su\u1ed1i \u0110\u00e1 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11897",
            name: "Su\u1ed1i \u0110\u00e1 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11898",
            name: "S\u01b0\u01a1ng Nguy\u1ec7t \u00c1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11899",
            name: "T\u1ea1 M\u1ef9 Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11900",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11901",
            name: "T\u1ea3n \u0110\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11902",
            name: "T\u00e2n Ph\u00fa 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11903",
            name: "T\u00e2n Ph\u00fa 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11904",
            name: "T\u00e2n Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11905",
            name: "T\u00e2n Th\u00e1i 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11906",
            name: "T\u00e2n Th\u00e1i 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11907",
            name: "T\u00e2n Th\u00e1i 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11908",
            name: "T\u00e2n Th\u00e1i 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11909",
            name: "T\u00e2n Th\u00e1i 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11910",
            name: "T\u00e2n Th\u00e1i 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11911",
            name: "T\u00e2n Th\u00e1i 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11912",
            name: "T\u00e2n Th\u00e1i 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11913",
            name: "T\u00e2n Th\u00e1i 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11914",
            name: "T\u00e2n Th\u00e1i 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11915",
            name: "T\u00e2n Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11916", name: "Th\u1ea1ch Lam", prefix: "Ph\u1ed1" },
          {
            id: "11917",
            name: "Th\u00e1i Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11918",
            name: "Thanh B\u00ecnh Xanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11919",
            name: "Th\u1ea1nh L\u1ed9c 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11920",
            name: "Thanh Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11921", name: "Thanh Vinh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11922",
            name: "Th\u00e0nh Vinh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11923",
            name: "Th\u00e0nh Vinh 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11924",
            name: "Th\u00e0nh Vinh 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11925",
            name: "Th\u00e0nh Vinh 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11926",
            name: "Th\u1ebf L\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11927",
            name: "Th\u00edch Ph\u01b0\u1edbc Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11928",
            name: "Th\u00edch Thi\u1ec7n Chi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11929",
            name: "Th\u00f4i H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11930",
            name: "Th\u1ee7 Khoa Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11931",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11932",
            name: "T\u00f4n Quang Phi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11933",
            name: "T\u00f4n Th\u1ea5t Thi\u1ec7p",
            prefix: "Ph\u1ed1",
          },
          {
            id: "11934",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11935",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11936",
            name: "Tr\u1ea7n B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11937",
            name: "Tr\u1ea7n \u0110\u00ecnh \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11938",
            name: "Tr\u1ea7n \u0110\u1ee9c L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11939",
            name: "Tr\u1ea7n \u0110\u1ee9c Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11940",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11941",
            name: "Tr\u1ea7n H\u1eefu T\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11942",
            name: "Tr\u1ea7n Kh\u00e1t Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11943",
            name: "Tr\u1ea7n L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11944",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "11945",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11946",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11947",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11948",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11949",
            name: "Tr\u1ea7n S\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11950",
            name: "Tr\u1ea7n Thanh M\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11951",
            name: "Tr\u1ea7n Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11952",
            name: "Tr\u1ea7n Thuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11953",
            name: "Tr\u1ea7n V\u0103n D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11954",
            name: "Tri\u1ec7u Vi\u1ec7t V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11955",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11956",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11957",
            name: "Tr\u01b0\u01a1ng Qu\u1ed1c Dung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11958",
            name: "Tr\u01b0\u01a1ng Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11959",
            name: "Tr\u01b0\u1eddng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11960",
            name: "Tr\u01b0\u1eddng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11961",
            name: "V\u00e2n \u0110\u1ed3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11962",
            name: "V\u00f5 Duy Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11963",
            name: "V\u00f5 Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11964",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11965",
            name: "V\u00f5 Nh\u01b0 H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11966",
            name: "V\u00f5 Tr\u01b0\u1eddng To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11967",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11968",
            name: "V\u0169 \u0110\u00ecnh Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11969",
            name: "V\u0169 Ng\u1ecdc Nh\u1ea1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11970", name: "V\u0169 T\u00f4ng Phan", prefix: "Ph\u1ed1" },
          {
            id: "11971",
            name: "V\u0169 V\u0103n D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11972",
            name: "V\u0169ng Th\u00f9ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11973",
            name: "V\u0169ng Th\u00f9ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11974",
            name: "V\u0169ng Th\u00f9ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11975",
            name: "V\u0169ng Th\u00f9ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11976",
            name: "V\u0169ng Th\u00f9ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11977",
            name: "V\u0169ng Th\u00f9ng 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11978",
            name: "V\u0169ng Th\u00f9ng 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11979",
            name: "V\u01b0\u01a1ng Th\u1eeba V\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11980",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2108",
            name: "\u00c0 La Carte Hotel",
            lat: "16.068616867065",
            lng: "108.24445343018",
          },
          {
            id: "2109",
            name: "Alphanam Luxury Apartment",
            lat: "16.077888488769",
            lng: "108.24508666992",
          },
          {
            id: "2110",
            name: "An Vi\u00ean City",
            lat: "16.083999633789",
            lng: "108.23861694336",
          },
          {
            id: "2111",
            name: "Azura",
            lat: "16.071706771851",
            lng: "108.22905731201",
          },
          {
            id: "2112",
            name: "Bluehouse",
            lat: "16.092468261719",
            lng: "108.22873687744",
          },
          {
            id: "2113",
            name: "Central Coast \u0110\u00e0 N\u1eb5ng",
            lat: "16.077596664429",
            lng: "108.24550628662",
          },
          {
            id: "2114",
            name: "Condotel Times Square \u0110\u00e0 N\u1eb5ng",
            lat: "16.070013046265",
            lng: "108.24493408203",
          },
          {
            id: "2115",
            name: "Euro Village",
            lat: "16.055875778198",
            lng: "108.23052215576",
          },
          {
            id: "2116",
            name: "Fusion Suites Da Nang Beach",
            lat: "16.076030731201",
            lng: "108.24451446533",
          },
          {
            id: "2117",
            name: "Habour Ville",
            lat: "21.0306224823",
            lng: "105.84886932373",
          },
          {
            id: "2118",
            name: "Harmony Tower",
            lat: "16.070829391479",
            lng: "108.23638916016",
          },
          {
            id: "2119",
            name: "Hiyori Garden Tower",
            lat: "16.061910629273",
            lng: "108.23790740967",
          },
          {
            id: "2120",
            name: "H\u00f2a B\u00ecnh Green \u0110\u00e0 N\u1eb5ng",
            lat: "16.098104476929",
            lng: "108.22458648682",
          },
          {
            id: "2121",
            name: "InterContinental Da nang",
            lat: "16.12025642395",
            lng: "108.30657958984",
          },
          {
            id: "2122",
            name: "Marina Complex",
            lat: "16.092119216919",
            lng: "108.2255783081",
          },
          {
            id: "2123",
            name: "Mercure S\u01a1n Tr\u00e0",
            lat: "16.106418609619",
            lng: "108.29531097412",
          },
          {
            id: "2124",
            name: "M\u01b0\u1eddng Thanh S\u01a1n Tr\u00e0",
            lat: "16.053169250488",
            lng: "108.24815368652",
          },
          {
            id: "2125",
            name: "Nest Home",
            lat: "16.089340209961",
            lng: "108.24095916748",
          },
          {
            id: "2126",
            name: "Ng\u00f4 Quy\u1ec1n Shopping Street",
            lat: "16.101390838623",
            lng: "108.24749755859",
          },
          {
            id: "2127",
            name: "Olalani Riverside Towers",
            lat: "16.086826324463",
            lng: "108.22808074951",
          },
          {
            id: "2128",
            name: "Ph\u00fac L\u1ed9c Vi\u00ean",
            lat: "16.081996917725",
            lng: "108.23812866211",
          },
          {
            id: "2129",
            name: "S\u01a1n Tr\u00e0 Ocean View",
            lat: "16.095062255859",
            lng: "108.24802398682",
          },
          {
            id: "2130",
            name: "Star Villa S\u01a1n Tr\u00e0",
            lat: "21.0306224823",
            lng: "105.84886932373",
          },
          {
            id: "2131",
            name: "The Monarchy",
            lat: "16.056144714356",
            lng: "108.23332214356",
          },
          {
            id: "2132",
            name: "The Summit",
            lat: "16.096309661865",
            lng: "108.2455368042",
          },
          {
            id: "2133",
            name: "The Sun City Riverside",
            lat: "16.05576133728",
            lng: "108.23249816894",
          },
          {
            id: "2134",
            name: "Vinpearl Condotel \u0110\u00e0 N\u1eb5ng",
            lat: "16.071031570435",
            lng: "108.22988128662",
          },
          {
            id: "2135",
            name: "Wyndham Soleil \u0110\u00e0 N\u1eb5ng",
            lat: "16.070772171021",
            lng: "108.24475860596",
          },
        ],
      },
      {
        id: "62",
        name: "Thanh Kh\u00ea",
        wards: [
          { id: "960", name: "An Kh\u00ea", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "961",
            name: "Ch\u00ednh Gi\u00e1n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "962", name: "H\u00f2a Kh\u00ea", prefix: "Ph\u01b0\u1eddng" },
          { id: "963", name: "Tam Thu\u1eadn", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "964",
            name: "T\u00e2n Ch\u00ednh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "965",
            name: "Th\u1ea1c Gi\u00e1n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "966",
            name: "Thanh Kh\u00ea \u0110\u00f4ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "967",
            name: "Thanh Kh\u00ea T\u00e2y",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "968",
            name: "Thanh L\u1ed9c \u0110\u00e1n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "969", name: "V\u0129nh Trung", prefix: "Ph\u01b0\u1eddng" },
          { id: "970", name: "Xu\u00e2n H\u00e0", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "11981", name: "2/9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11982", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "11983", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11984",
            name: "An H\u00f2a 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "11985", name: "An Xu\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "11986",
            name: "An Xu\u00e2n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11987",
            name: "An Xu\u00e2n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11988",
            name: "B\u00e0u H\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11989",
            name: "B\u00e0u H\u1ea1c 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11990",
            name: "B\u00e0u H\u1ea1c 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11991",
            name: "B\u00e0u H\u1ea1c 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11992",
            name: "B\u00e0u H\u1ea1c 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11993",
            name: "B\u1ea7u H\u1ea1c 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11994",
            name: "B\u00e0u H\u1ea1c 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11995",
            name: "B\u00e0u H\u1ea1t 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11996",
            name: "B\u00e0u H\u1ea1t 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11997",
            name: "B\u00e0u L\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11998",
            name: "B\u00e0u Tr\u1ea3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "11999",
            name: "B\u00e0u Tr\u1ea3ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12000",
            name: "B\u1ea7u Tr\u1ea3ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12001",
            name: "B\u00e0u Tr\u1ea3ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12002",
            name: "B\u00e0u Tr\u1ea3ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12003",
            name: "B\u00e0u Tr\u1ea3ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12004",
            name: "B\u00e0u Tr\u1ea3ng 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12005",
            name: "B\u00e0u Tr\u1ea3ng 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12006",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12007",
            name: "B\u00ecnh Th\u00e1i 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12008",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12009",
            name: "C\u1ea7n Giu\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12010",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12035",
            name: "D\u0169ng S\u0129",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12036",
            name: "D\u0169ng S\u0129 Thanh Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12037",
            name: "D\u01b0\u01a1ng B\u00edch Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12011",
            name: "\u0110\u1eb7ng \u0110\u00ecnh V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12012",
            name: "\u0110\u1eb7ng Minh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12013",
            name: "\u0110\u1eb7ng Ph\u00fac Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12014",
            name: "\u0110\u1eb7ng Thai Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12015",
            name: "\u0110\u1eb7ng Th\u00f9y Tr\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12016",
            name: "\u0110\u00e0o Duy Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12017",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12018",
            name: "\u0110\u00e0o Nguy\u00ean Ph\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12019",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12020",
            name: "\u0110inh N\u00fap",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12021",
            name: "\u0110inh Th\u1ecb V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12022",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12023",
            name: "\u0110\u1ed7 \u0110\u1ee9c D\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12024",
            name: "\u0110\u1ed7 Ng\u1ecdc Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12025", name: "\u0110\u1ed7 Quang", prefix: "Ph\u1ed1" },
          {
            id: "12026",
            name: "\u0110o\u00e0n H\u1eefu Tr\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12027",
            name: "\u0110o\u00e0n Nh\u1eef H\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12028",
            name: "\u0110\u1ed1ng \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12029",
            name: "\u0110\u1ed3ng L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12030",
            name: "\u0110\u00f4ng L\u1ee3i 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12031",
            name: "\u0110\u00f4ng L\u1ee3i 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12032",
            name: "\u0110\u00f4ng L\u1ee3i 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12033",
            name: "\u0110\u00f4ng L\u1ee3i 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12034",
            name: "\u0110\u1ee9c L\u1ee3i I",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12038",
            name: "H\u00e0 \u0110\u00f4ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12039",
            name: "H\u00e0 \u0110\u00f4ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12040",
            name: "H\u00e0 \u0110\u00f4ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12041",
            name: "H\u00e0 \u0110\u00f4ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12042",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12043",
            name: "H\u00e0 Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12044",
            name: "H\u00e0 Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12045",
            name: "H\u00e0 Xu\u00e2n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12046",
            name: "H\u1ea3i H\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12047",
            name: "H\u1ea3i Ph\u00f2ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12048",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12049",
            name: "H\u00e0n M\u1eb7c T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12050",
            name: "H\u1ed3 Qu\u00fd Ly",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12051",
            name: "H\u1ed3 T\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12052",
            name: "H\u00f2a An 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12053",
            name: "H\u00f2a M\u1ef9 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12054",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12055",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12056",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12057",
            name: "Hu\u1ef3nh Ng\u1ecdc Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12058", name: "K19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12059", name: "Kim H\u1ea3i", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12060",
            name: "Kinh D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12061", name: "K\u1ef3 \u0110\u1ed3ng", prefix: "Ph\u1ed1" },
          {
            id: "12065",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12066",
            name: "L\u00ea Duy \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12067",
            name: "L\u00ea Duy T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12062",
            name: "L\u00ea \u0110\u00ecnh D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12063",
            name: "L\u00ea \u0110\u00ecnh L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12064",
            name: "L\u00ea \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12068",
            name: "L\u00ea Ng\u00f4 C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12069",
            name: "L\u00ea Quang Sung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12070",
            name: "L\u00ea Th\u1ecb T\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12071",
            name: "L\u00ea Th\u1ecb Xuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12072",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "12073",
            name: "L\u00ea V\u0103n Th\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12074",
            name: "L\u01b0\u01a1ng Nh\u1eef H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12075",
            name: "L\u01b0\u01a1ng Nh\u1eef H\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12076",
            name: "L\u00fd Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12077",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12078",
            name: "L\u00fd Th\u00e1i T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12079",
            name: "L\u00fd Tri\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12080",
            name: "Mai Xu\u00e2n Th\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12081",
            name: "Man Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12082",
            name: "M\u1eb9 Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12083", name: "M\u1eb9 Nhu", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12084",
            name: "Ng\u00f4 \u0110\u1ee9c K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12085",
            name: "Ng\u00f4 Gia Kh\u1ea3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12086",
            name: "Ng\u1ecdc H\u1ed3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12087",
            name: "Nguy\u1ec5n Bi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12088",
            name: "Nguy\u1ec5n C\u1ea3nh D\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12089",
            name: "Nguy\u1ec5n Cao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12090",
            name: "Nguy\u1ec5n Ch\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12091",
            name: "Nguy\u1ec5n C\u00f4ng H\u00e3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12092",
            name: "Nguy\u1ec5n \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12093",
            name: "Nguy\u1ec5n \u0110\u00ecnh T\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12094",
            name: "Nguy\u1ec5n \u0110\u00ecnh T\u1ef1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12095",
            name: "Nguy\u1ec5n \u0110\u1ee9c Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12096",
            name: "Nguy\u1ec5n Gia Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12097",
            name: "Nguy\u1ec5n Gi\u1ea3n Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12098",
            name: "Nguy\u1ec5n Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12099",
            name: "Nguy\u00ean H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12100",
            name: "Nguy\u1ec5n H\u1eefu Th\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12101",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12102",
            name: "Nguy\u1ec5n H\u1eefu Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12103",
            name: "Nguy\u1ec5n Huy L\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12104",
            name: "Nguy\u1ec5n Huy T\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12105",
            name: "Nguy\u1ec5n Khang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12106",
            name: "Nguy\u1ec5n Khoa Chi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12107",
            name: "Nguy\u1ec5n Nghi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12108",
            name: "Nguy\u1ec5n Nh\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12109",
            name: "Nguy\u1ec5n Ph\u01b0\u1edbc Nguy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12110",
            name: "Nguy\u1ec5n Ph\u01b0\u1edbc Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12111",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12112",
            name: "Nguy\u1ec5n Thanh N\u0103m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12113",
            name: "Nguy\u1ec5n Th\u1ecb B\u1ea3y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12114",
            name: "Nguy\u1ec5n Th\u1ecb Th\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12115",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12116",
            name: "Nguy\u1ec5n Trung \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12117",
            name: "Nguy\u1ec5n V\u0103n Hu\u1ec1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12118",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12119",
            name: "Nguy\u1ec5n V\u0103n Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12120", name: "Nguy\u1ec5n X\u00ed", prefix: "Ph\u1ed1" },
          {
            id: "12121",
            name: "\u00d4ng \u00cdch Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12122",
            name: "Ph\u1ea1m Ng\u1ecdc M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12123",
            name: "Ph\u1ea1m Nh\u1eef T\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12124",
            name: "Ph\u1ea1m V\u0103n Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12125", name: "Phan Anh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12126",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12127",
            name: "Ph\u1ea7n L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12128",
            name: "Ph\u1ea7n L\u0103ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12129",
            name: "Ph\u1ea7n L\u0103ng 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12130",
            name: "Ph\u1ea7n L\u0103ng 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12131",
            name: "Ph\u1ea7n L\u0103ng 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12132",
            name: "Ph\u1ea7n L\u0103ng 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12133",
            name: "Ph\u1ea7n L\u0103ng 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12134",
            name: "Ph\u1ea7n L\u0103ng 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12135",
            name: "Ph\u1ea7n L\u0103ng 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12136",
            name: "Ph\u1ea7n L\u0103ng 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12137",
            name: "Ph\u1ea7n L\u0103ng 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12138",
            name: "Ph\u1ea7n L\u0103ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12139",
            name: "Ph\u1ea7n L\u0103ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12140",
            name: "Ph\u1ea7n L\u0103ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12141",
            name: "Ph\u1ea7n L\u0103ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12142",
            name: "Ph\u1ea7n L\u0103ng 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12143",
            name: "Ph\u1ea7n L\u0103ng 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12144",
            name: "Ph\u1ea7n L\u0103ng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12145",
            name: "Ph\u1ea7n L\u0103ng 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12146", name: "Phan Nhu", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12147",
            name: "Phan Ph\u00fa Ti\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12148", name: "Phan Thanh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12149",
            name: "Phan Th\u00e0nh T\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12150",
            name: "Phan X\u00edch Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12151",
            name: "Ph\u00fa L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12152",
            name: "Ph\u00fa L\u1ed9c 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12153",
            name: "Ph\u00fa L\u1ed9c 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12154",
            name: "Ph\u00fa L\u1ed9c 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12155",
            name: "Ph\u00fa L\u1ed9c 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12156",
            name: "Ph\u00fa L\u1ed9c 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12157",
            name: "Ph\u00fa L\u1ed9c 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12158",
            name: "Ph\u00fa L\u1ed9c 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12159",
            name: "Ph\u00fa L\u1ed9c 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12160",
            name: "Phu\u0301 L\u00f4\u0323c 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12161",
            name: "Ph\u00fa L\u1ed9c 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12162",
            name: "Phu\u0323c \u0110a\u0301n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12163", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "12164",
            name: "Quang D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12165",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12166",
            name: "T\u1ea3n \u0110\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12167",
            name: "T\u00e2n H\u00f2a 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12168",
            name: "T\u00e2n H\u00f2a 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12169",
            name: "T\u00e2n H\u00f2a 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12170",
            name: "T\u00e2n H\u00f2a 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12171",
            name: "T\u00e2n H\u00f2a 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12172",
            name: "T\u00e2n H\u00f2a 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12173",
            name: "T\u00e2n H\u00f2a 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12174",
            name: "Th\u00e1i Th\u1ecb B\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12175",
            name: "Th\u00e2n Nh\u00e2n Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12176", name: "Thanh Huy", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12177", name: "Thanh Huy 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12178", name: "Thanh Huy 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12179", name: "Thanh Huy 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12180", name: "Thanh Huy 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12181", name: "Thanh Huy 5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12182",
            name: "Thanh Kh\u00ea 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12183",
            name: "Thu\u1eadn An 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12184",
            name: "Thu\u1eadn An 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12185",
            name: "Thu\u1eadn An 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12186",
            name: "Thu\u1eadn An 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12187",
            name: "Thu\u1eadn An 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12188",
            name: "Thu\u1eadn An 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12189",
            name: "Thu\u1eadn An 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12190",
            name: "Th\u00fac T\u1ec1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12191",
            name: "Ti\u00ean S\u01a1n 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12192",
            name: "Ti\u00ean S\u01a1n 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12193",
            name: "Ti\u00ean S\u01a1n 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12194",
            name: "Ti\u00ean S\u01a1n 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12195",
            name: "Ti\u00ean S\u01a1n 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12196",
            name: "Ti\u00ean S\u01a1n 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12197",
            name: "Ti\u00ean S\u01a1n 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12198",
            name: "Ti\u00ean S\u01a1n 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12199",
            name: "Ti\u00ean S\u01a1n 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12200",
            name: "Ti\u00ean S\u01a1n 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12201",
            name: "Ti\u00ean S\u01a1n 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12202",
            name: "Ti\u00ean S\u01a1n 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12203",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12204",
            name: "T\u00f4 Ng\u1ecdc V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12205",
            name: "T\u00f4n \u0110\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12206",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "12207",
            name: "T\u00f4n Th\u1ea5t \u0110\u1ea1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12208",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12209",
            name: "Tr\u1ea7n Anh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12210",
            name: "Tr\u1ea7n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12211",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12212",
            name: "Tr\u1ea7n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12213",
            name: "Tr\u1ea7n \u0110\u00ecnh Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12214",
            name: "Tr\u00e2\u0300n Ngo\u0323c S\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12215",
            name: "Tr\u1ea7n Nguy\u00ean \u0110\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12216",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12217",
            name: "Tr\u1ea7n Ph\u01b0\u1edbc Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12218",
            name: "Tr\u1ea7n Sang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12219",
            name: "Tr\u1ea7n Th\u00e1i T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12220",
            name: "Tr\u1ea7n Thanh Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12221",
            name: "Tr\u1ea7n Th\u1ecb L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12222",
            name: "Tr\u1ea7n T\u1ed1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12223",
            name: "Tr\u1ea7n Xu\u00e2n L\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12224",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12225", name: "V\u0103n Cao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12226",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12227",
            name: "V\u00f5 V\u0103n T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12228",
            name: "V\u0169 Hu\u1ef3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12229",
            name: "V\u0169 Qu\u1ef3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12230",
            name: "X\u00f4 Vi\u1ebft Ngh\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12231",
            name: "Xu\u00e2n \u0110\u00e1n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12232",
            name: "Xu\u00e2n \u0110\u00e1n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12233",
            name: "Xu\u00e2n \u0110\u00e1n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12234",
            name: "Xu\u00e2n H\u00f2a 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12235",
            name: "Xu\u00e2n H\u00f2a 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12236",
            name: "Y\u00ean Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12237",
            name: "Y\u00ean Kh\u00ea 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12238",
            name: "Y\u00ean Kh\u00ea 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2136",
            name: "Dragon V\u0129nh Trung",
            lat: "16.068254470825",
            lng: "108.21881103516",
          },
          {
            id: "2137",
            name: "Ho\u00e0ng Anh Lakeview Residence",
            lat: "16.062366485596",
            lng: "108.21044158935",
          },
          {
            id: "2138",
            name: "Ph\u00fa Gia Compound",
            lat: "16.07501411438",
            lng: "108.21254730225",
          },
          {
            id: "2139",
            name: "T\u00e2n An SuperMarket",
            lat: "16.04035949707",
            lng: "108.18895721435",
          },
          {
            id: "2140",
            name: "V\u0129nh Trung Plaza",
            lat: "16.066854476929",
            lng: "108.21329498291",
          },
        ],
      },
    ],
  },
  {
    id: "4",
    code: "BD",
    name: "B\u00ecnh D\u01b0\u01a1ng",
    districts: [
      {
        id: "63",
        name: "B\u00e0u B\u00e0ng",
        wards: [
          {
            id: "971",
            name: "C\u00e2y Tr\u01b0\u1eddng II",
            prefix: "X\u00e3",
          },
          { id: "972", name: "H\u01b0ng H\u00f2a", prefix: "X\u00e3" },
          { id: "973", name: "Lai H\u01b0ng", prefix: "X\u00e3" },
          { id: "974", name: "Lai Uy\u00ean", prefix: "X\u00e3" },
          { id: "975", name: "Lai Uy\u00ean", prefix: "X\u00e3" },
          { id: "976", name: "Long Nguy\u00ean", prefix: "X\u00e3" },
          { id: "977", name: "T\u00e2n H\u01b0ng", prefix: "X\u00e3" },
          { id: "978", name: "Tr\u1eeb V\u0103n Th\u1ed1", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "12239", name: "7B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12240", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12241",
            name: "B\u1ebfn V\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12242",
            name: "C\u1ef1u Chi\u1ebfn Binh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12243", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12244", name: "D13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12245", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12246", name: "D3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12247", name: "D5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12248", name: "D6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12249", name: "D7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12252", name: "DC713", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12253", name: "DH516", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12254", name: "DH601", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12255", name: "DH607", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12256", name: "DH610", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12257", name: "DH612", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12258", name: "DH613", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12259", name: "DH615", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12260", name: "DH618", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12261", name: "DH624", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12250",
            name: "\u0110\u1ea1i L\u1ed9 B\u00ecnh D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12251",
            name: "\u0110\u1ea1i L\u1ed9 H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12262", name: "\u0110T 741", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12263", name: "\u0110T 749", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12264", name: "\u0110T 749A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12265", name: "\u0110T 749C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12266", name: "\u0110T 75", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12267", name: "\u0110T 750", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12268", name: "HL 610", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12269", name: "HL612", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12270",
            name: "H\u1ed3 Ch\u00ed Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12271",
            name: "Long T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12272",
            name: "M\u1ef9 Ph\u01b0\u1edbc T\u00e2n V\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12273", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12274", name: "N16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12275", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12276", name: "N4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12277", name: "N6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12278", name: "N7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12279", name: "N8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12280",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12281",
            name: "Qu\u1ed1c L\u1ed9 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12282", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12283",
            name: "Song H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12284",
            name: "T\u1ea1o L\u1ef1c 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12285",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12286",
            name: "Thanh Ni\u00ean 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12287",
            name: "T\u1ec9nh l\u1ed9 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12288",
            name: "T\u1ec9nh l\u1ed9 750",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12289",
            name: "Tr\u1eeb V\u0103n Th\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12290",
            name: "V\u0103n B\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2141",
            name: "Garden Town",
            lat: "11.249523162842",
            lng: "106.63594818115",
          },
          {
            id: "2142",
            name: "Hu\u1ef3nh Gia Residence",
            lat: "11.272928237915",
            lng: "106.63529968262",
          },
          {
            id: "2143",
            name: "K\u0110T d\u1ecbch v\u1ee5 B\u00e0u B\u00e0ng",
            lat: "11.241799354553",
            lng: "106.62007141113",
          },
        ],
      },
      {
        id: "64",
        name: "B\u1ebfn C\u00e1t",
        wards: [
          { id: "979", name: "An \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "980", name: "An T\u00e2y", prefix: "X\u00e3" },
          { id: "981", name: "C\u00e2y Tr\u01b0\u1eddng", prefix: "X\u00e3" },
          {
            id: "982",
            name: "Ch\u00e1nh Ph\u00fa H\u00f2a",
            prefix: "X\u00e3",
          },
          { id: "983", name: "H\u00f2a L\u1ee3i", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "984",
            name: "M\u1ef9 Ph\u01b0\u1edbc",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "985", name: "Ph\u00fa An", prefix: "X\u00e3" },
          { id: "986", name: "T\u00e2n \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "987", name: "Th\u1edbi H\u00f2a", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "12291", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12292", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12293", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12294", name: "1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12295", name: "1C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12296", name: "1D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12297", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12298", name: "2/9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12299", name: "22A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12300", name: "30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12301", name: "3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12302", name: "4B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12303", name: "609", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12304", name: "741", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12305", name: "744", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12306", name: "747", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12307", name: "748", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12308", name: "7A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12309", name: "7B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12310",
            name: "An \u0110i\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12311", name: "An T\u00e2y", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12312",
            name: "An T\u00e2y 40",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12313",
            name: "An T\u00e2y 45",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12314",
            name: "An T\u00e2y 46",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12315",
            name: "An T\u00e2y 47",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12316",
            name: "An T\u00e2y 50",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12317",
            name: "An T\u00e2y 53",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12318",
            name: "An T\u00e2y 65",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12319",
            name: "An T\u00e2y 69",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12320",
            name: "An T\u00e2y 79",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12321",
            name: "An T\u00e2y 80",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12322",
            name: "An T\u00e2y 86",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12323", name: "Ba Lan Xi", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12324",
            name: "Ba M\u01b0\u01a1i Th\u00e1ng T\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12325",
            name: "B\u00e0u T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12326",
            name: "B\u1ebfn Ch\u00e0 Vi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12327",
            name: "B\u1ebfn \u0110\u1ed3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12328",
            name: "B\u1ebfn L\u1edbn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12329",
            name: "B\u1ebfn Tranh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12330",
            name: "B\u00ecnh L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12331",
            name: "B\u00f9i V\u0103n Ng\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12332", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12333", name: "D10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12334", name: "D11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12335", name: "D12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12336", name: "D13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12337", name: "D14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12338", name: "D14A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12339", name: "D15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12340", name: "D15B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12341", name: "D16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12342", name: "D16A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12343", name: "D17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12344", name: "D17A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12345", name: "D18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12346", name: "D18A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12347", name: "D19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12348", name: "D19A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12349", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12350", name: "D20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12351", name: "D20A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12352", name: "D21B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12353", name: "D22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12354", name: "D2A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12355", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12356", name: "D3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12357", name: "D4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12358", name: "D4B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12359", name: "D4C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12360", name: "D5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12361", name: "D5B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12362", name: "D6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12363", name: "D7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12364", name: "D8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12365", name: "D9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12366", name: "DA1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12367", name: "DA1-1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12368", name: "DA1-2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12369", name: "DA2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12370", name: "DA6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12371", name: "DA7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12373", name: "DB11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12374", name: "DB11A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12375", name: "DB13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12376", name: "DB3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12377", name: "DB4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12378", name: "DB5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12379", name: "DB6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12380", name: "DB8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12381", name: "DB9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12382", name: "DC3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12383", name: "DC5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12384", name: "DC6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12385", name: "DC7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12386", name: "DE1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12387", name: "DE4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12388", name: "DE7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12389", name: "DF1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12390", name: "DF2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12391", name: "DG1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12392", name: "DG2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12393", name: "DG3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12394", name: "DG4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12395", name: "DG5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12396", name: "DH1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12397", name: "DH15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12398", name: "DH2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12399", name: "DH3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12400", name: "DH424", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12401", name: "DH5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12402", name: "DH6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12403", name: "DH601", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12404", name: "DH604", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12405", name: "DH605", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12406", name: "DH607", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12407", name: "DH608", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12408", name: "DH609", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12409", name: "DH7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12410", name: "DH8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12411", name: "DHA1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12412", name: "DHA2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12413", name: "DHA3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12414", name: "DI1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12415", name: "DI15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12416", name: "DI2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12417", name: "DI3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12418", name: "DI4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12419", name: "DI4B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12420", name: "DI5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12421", name: "DI5A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12422", name: "DJ1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12423", name: "DJ10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12424", name: "DJ10A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12425", name: "DJ12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12426", name: "DJ15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12427", name: "DJ1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12428", name: "DJ2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12429", name: "DJ2A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12430", name: "DJ3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12431", name: "DJ34", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12432", name: "DJ3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12433", name: "DJ4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12434", name: "DJ5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12435", name: "DJ6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12436", name: "DJ7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12437", name: "DJ8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12438", name: "DJ8A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12439", name: "DJ9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12440", name: "DJ9A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12441", name: "DK10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12442", name: "DK11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12443", name: "DK12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12444", name: "DK4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12445", name: "DK5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12446", name: "DK5A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12447", name: "DK6A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12448", name: "DK8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12449", name: "DK9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12450", name: "DL", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12451", name: "DL10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12452", name: "DL11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12453", name: "DL12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12454", name: "DL13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12455", name: "DL14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12456", name: "DL15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12457", name: "DL16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12458", name: "DL17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12459", name: "DL3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12460", name: "DL4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12461", name: "DL5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12462", name: "DL6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12463", name: "DL7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12464", name: "DL8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12465", name: "DL9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12475", name: "DX", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12476", name: "DX 020", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12477", name: "DX 024", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12478", name: "DX 03", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12479", name: "DX 061", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12480", name: "DX 063", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12481", name: "DX 080", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12482", name: "DX 096", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12483", name: "DX 605", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12484", name: "DX 609", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12485", name: "DY7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12372",
            name: "\u0110\u1ea1i L\u1ed9 B\u00ecnh D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12466", name: "\u0110T 609", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12467", name: "\u0110T 741", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12468", name: "\u0110T 743", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12469", name: "\u0110T 744", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12470", name: "\u0110T 746", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12471", name: "\u0110T 748", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12472", name: "\u0110T 749", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12473", name: "\u0110T 750", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12474", name: "\u0110T 7A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12486", name: "F14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12487", name: "H1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12488", name: "H3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12489", name: "H4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12490", name: "H5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12491", name: "H6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12492",
            name: "Hai Th\u00e1ng Ch\u00edn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12493", name: "HL 607", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12494", name: "HL 609", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12495",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12496",
            name: "H\u01b0\u01a1ng L\u1ed9 608",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12497",
            name: "H\u01b0\u01a1ng l\u1ed9 740",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12498",
            name: "Huy\u1ec7n l\u1ed9 604",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12499", name: "KH1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12500", name: "KH2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12501", name: "KK14A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12502", name: "KK3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12503", name: "KK4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12504", name: "KK4A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12505", name: "KL1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12506", name: "KL12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12507", name: "KL13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12508", name: "KL1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12509", name: "KL2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12510", name: "KL3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12511", name: "L10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12512", name: "L52", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12513",
            name: "L\u00ea Ch\u00ed D\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12514", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12515",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12516",
            name: "Li\u00ean Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12517",
            name: "M\u1ef9 Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12518",
            name: "M\u1ef9 Ph\u01b0\u1edbc 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12519",
            name: "M\u1ef9 Ph\u01b0\u1edbc 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12520",
            name: "M\u1ef9 Ph\u01b0\u1edbc 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12521",
            name: "M\u1ef9 Ph\u01b0\u1edbc 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12522",
            name: "M\u1ef9 Ph\u01b0\u1edbc T\u00e2n V\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12523", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12524", name: "N10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12525", name: "N11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12526", name: "N12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12527", name: "N13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12528", name: "N14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12529", name: "N15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12530", name: "N16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12531", name: "N17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12532", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12534", name: "N2-D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12533", name: "N2B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12535", name: "N3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12536", name: "N3B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12537", name: "N4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12538", name: "N4-D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12539", name: "N5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12540", name: "N5A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12541", name: "N6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12542", name: "N6B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12543", name: "N7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12544", name: "N7A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12545", name: "N8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12546", name: "N9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12547", name: "NA-1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12548", name: "NA10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12549", name: "NA11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12550", name: "NA12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12551", name: "NA2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12552", name: "NA3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12553", name: "NA5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12554", name: "NA7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12555", name: "NA8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12556", name: "NA9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12557", name: "NB10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12558", name: "NB11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12559", name: "NB12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12560", name: "NB16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12561", name: "NB2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12562", name: "NB2A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12563", name: "NB2B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12564", name: "NB3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12565", name: "NB3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12566", name: "NB4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12567", name: "NB4A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12568", name: "NB5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12569", name: "NB6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12570", name: "NB7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12571", name: "NB7A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12572", name: "NB8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12573", name: "NB9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12574", name: "NC2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12575", name: "NC3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12576", name: "NE2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12577", name: "NE3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12578", name: "NE4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12579", name: "NE6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12580", name: "NE7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12581", name: "NE8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12582", name: "NF1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12583", name: "NF10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12584", name: "NF2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12585", name: "NF4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12586", name: "NF5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12587", name: "NF6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12588", name: "NG1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12589", name: "NG1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12590", name: "NG2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12591", name: "NG3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12592", name: "NG3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12593", name: "NG4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12594", name: "NG5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12595", name: "NG6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12596", name: "NG7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12597", name: "NG8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12598",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12599",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12600",
            name: "Nguy\u1ec5n Th\u00e0nh V\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12601",
            name: "Nguy\u1ec5n Th\u1ecb T\u1ed3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12602",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12603",
            name: "Nguy\u1ec5n V\u0103n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12604", name: "NH10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12605", name: "NH11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12606", name: "NH13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12607", name: "NH14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12608", name: "NH15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12609", name: "NH16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12610", name: "NH2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12611", name: "NH3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12612", name: "NH4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12613", name: "NH5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12614", name: "NH6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12615", name: "NH6A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12616", name: "NH7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12617", name: "NH7A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12618", name: "NH8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12619", name: "NH9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12620", name: "NHA1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12621", name: "NHA3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12622", name: "NHA4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12623", name: "NHA5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12624", name: "NHA6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12625", name: "NI1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12626", name: "NI10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12627", name: "NI11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12628", name: "NI12A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12629", name: "NI13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12630", name: "NI14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12631", name: "NI15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12632", name: "NI16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12633", name: "NI17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12634", name: "NI17A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12635", name: "NI18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12636", name: "NI19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12637", name: "NI1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12638", name: "NI2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12639", name: "NI20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12640", name: "NI3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12641", name: "NI4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12642", name: "NI4A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12643", name: "NI5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12644", name: "NI5A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12645", name: "NI6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12646", name: "NI7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12647", name: "NI8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12648", name: "NI9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12649", name: "NJ06", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12650", name: "NJ1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12651", name: "NJ10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12652", name: "NJ11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12653", name: "NJ12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12654", name: "NJ13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12655", name: "NJ14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12656", name: "NJ15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12657", name: "NJ16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12658", name: "NJ17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12659", name: "NJ18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12660", name: "NJ19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12661", name: "NJ2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12662", name: "NJ20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12663", name: "NJ21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12664", name: "NJ3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12665", name: "NJ4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12666", name: "NJ5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12667", name: "NJ9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12668", name: "NK1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12669", name: "NK10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12670", name: "NK11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12671", name: "NK12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12672", name: "NK13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12673", name: "NK14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12674", name: "NK14A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12675", name: "NK2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12676", name: "NK3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12677", name: "NK4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12678", name: "NK5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12679", name: "NK6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12680", name: "NK7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12681", name: "NK9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12682", name: "NL", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12683", name: "NL1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12684", name: "NL10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12685", name: "NL11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12686", name: "NL12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12687", name: "NL13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12688", name: "NL14A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12689", name: "NL15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12690", name: "NL16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12691", name: "NL16A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12692", name: "NL17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12693", name: "NL18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12694", name: "NL18A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12695", name: "NL19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12696", name: "NL19A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12697", name: "NL2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12698", name: "NL20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12699", name: "NL21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12700", name: "NL3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12701", name: "NL4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12702", name: "NL5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12703", name: "NL6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12704", name: "NL7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12705", name: "NL9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12706", name: "NP3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12707", name: "NP7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12708",
            name: "Ph\u1ea1m Ph\u00fa Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12709",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12710",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "12711",
            name: "Ph\u00fa S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12712",
            name: "Ph\u00fa Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12713",
            name: "Qu\u1ed1c L\u1ed9 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12714",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12715",
            name: "Qu\u1ed1c L\u1ed9 744",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12716",
            name: "Qu\u1ed1c l\u1ed9 7A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12717",
            name: "Qu\u1ed1c L\u1ed9 7B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12718",
            name: "Qu\u1ed1c L\u1ed9 \u0110T 7A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12719", name: "S1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12720", name: "SB1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12721", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12722", name: "S\u1ed1 28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12723", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12724", name: "S\u1ed1 80", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12725", name: "S\u1ed1 DK12", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12726",
            name: "T\u00e2n V\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12727",
            name: "T\u00e2n V\u0129nh Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12728",
            name: "T\u1ea1o L\u1ef1c 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12729",
            name: "T\u1ea1o L\u1ef1c 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12730",
            name: "T\u1ea1o L\u1ef1c 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12731", name: "TC 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12732", name: "TC1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12733", name: "TC1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12734", name: "TC2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12735", name: "TC3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12736",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12737",
            name: "T\u1ec9nh L\u1ed9 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12738",
            name: "T\u1ec9nh l\u1ed9 2/9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12739",
            name: "T\u1ec9nh l\u1ed9 240",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12740",
            name: "T\u1ec9nh L\u1ed9 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12741",
            name: "T\u1ec9nh l\u1ed9 744",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12742",
            name: "T\u1ec9nh l\u1ed9 748",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12743",
            name: "T\u1ec9nh l\u1ed9 7A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12744",
            name: "T\u1ec9nh L\u1ed9 7B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12745",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12746",
            name: "V\u00e0nh \u0110ai 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12747", name: "XB2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12748", name: "XB3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12749", name: "XB4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12750", name: "XC1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12751", name: "XC10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12752", name: "XC2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12753", name: "XC3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12754", name: "XC4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12755", name: "XC7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12756", name: "XC9A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12757", name: "XH3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12758", name: "XH4", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "2144",
            name: "An Ph\u00e1t Th\u1ecbnh - An T\u00e2y",
            lat: "11.101819038391",
            lng: "106.53615570068",
          },
          {
            id: "2145",
            name: "B\u1ebfn C\u00e1t Golden Land",
            lat: "11.129476547241",
            lng: "106.57025909424",
          },
          {
            id: "2146",
            name: "BenCat Center Point",
            lat: "11.095314025879",
            lng: "106.61041259766",
          },
          {
            id: "2147",
            name: "City Garden",
            lat: "11.109622001648",
            lng: "106.6067276001",
          },
          {
            id: "2148",
            name: "Cocoland",
            lat: "11.13676071167",
            lng: "106.65212249756",
          },
          {
            id: "2149",
            name: "College Town",
            lat: "11.095963478088",
            lng: "106.60496520996",
          },
          {
            id: "2150",
            name: "College Town II",
            lat: "11.094747543335",
            lng: "106.60389709473",
          },
          {
            id: "2151",
            name: "\u0110\u1ea1i Nam",
            lat: "11.054871559143",
            lng: "106.63165283203",
          },
          {
            id: "2152",
            name: "Eastern Land",
            lat: "11.124353408813",
            lng: "106.60224151611",
          },
          {
            id: "2153",
            name: "Ecolakes M\u1ef9 Ph\u01b0\u1edbc",
            lat: "11.117456436157",
            lng: "106.63137054443",
          },
          {
            id: "2154",
            name: "Golden Center City",
            lat: "11.145687103272",
            lng: "106.60007476807",
          },
          {
            id: "2155",
            name: "Golden Center City 2",
            lat: "11.145384788513",
            lng: "106.59993743897",
          },
          {
            id: "2156",
            name: "Golden City",
            lat: "11.104444503784",
            lng: "106.62323760986",
          },
          {
            id: "2157",
            name: "Golden Dragon (Kim Long)",
            lat: "11.095672607422",
            lng: "106.62242889404",
          },
          {
            id: "2158",
            name: "Goldland",
            lat: "11.06787109375",
            lng: "106.68737792969",
          },
          {
            id: "2159",
            name: "Happy Home",
            lat: "11.151206970215",
            lng: "106.62422943115",
          },
          {
            id: "2160",
            name: "Lucky Home",
            lat: "11.15207862854",
            lng: "106.61602783203",
          },
          {
            id: "2161",
            name: "Mega City",
            lat: "11.151037216187",
            lng: "106.58757781982",
          },
          {
            id: "2162",
            name: "M\u1ef9 Ph\u01b0\u1edbc 1",
            lat: "11.12863445282",
            lng: "106.59703826904",
          },
          {
            id: "2163",
            name: "M\u1ef9 Ph\u01b0\u1edbc 2",
            lat: "11.137392044067",
            lng: "106.62175750732",
          },
          {
            id: "2164",
            name: "M\u1ef9 Ph\u01b0\u1edbc 3",
            lat: "11.096517562866",
            lng: "106.63297271728",
          },
          {
            id: "2165",
            name: "M\u1ef9 Ph\u01b0\u1edbc 4",
            lat: "11.089554786682",
            lng: "106.60780334473",
          },
          {
            id: "2166",
            name: "New Era City",
            lat: "11.103433609009",
            lng: "106.62023162842",
          },
          {
            id: "2167",
            name: "New Life",
            lat: "11.090849876404",
            lng: "106.67039489746",
          },
          {
            id: "2168",
            name: "New Town",
            lat: "11.112710952759",
            lng: "106.67039489746",
          },
          {
            id: "2169",
            name: "Ng\u0169 T\u01b0\u1ee3ng Kh\u1ea3i Ho\u00e0n",
            lat: "11.108708381653",
            lng: "106.61153411865",
          },
          {
            id: "2170",
            name: "Ph\u00fa An",
            lat: "11.138757705688",
            lng: "106.62557220459",
          },
          {
            id: "2171",
            name: "Ph\u00fa M\u1ef9",
            lat: "11.120366096497",
            lng: "106.61232757568",
          },
          {
            id: "2172",
            name: "R\u1ea1ch B\u1eafp",
            lat: "11.143982887268",
            lng: "106.55697631836",
          },
          {
            id: "2173",
            name: "RichHome",
            lat: "11.16105556488",
            lng: "106.6734085083",
          },
          {
            id: "2174",
            name: "RichHome 2",
            lat: "11.117595672607",
            lng: "106.66717529297",
          },
          {
            id: "2175",
            name: "Spring City",
            lat: "11.113790512085",
            lng: "106.64128112793",
          },
          {
            id: "2176",
            name: "StarLight City",
            lat: "11.104907989502",
            lng: "106.60684204102",
          },
          {
            id: "2177",
            name: "Sunrise River",
            lat: "11.10915851593",
            lng: "106.59377288818",
          },
          {
            id: "2178",
            name: "The Green River",
            lat: "11.082178115845",
            lng: "106.61134338379",
          },
        ],
      },
      {
        id: "65",
        name: "D\u1ea7u Ti\u1ebfng",
        wards: [
          { id: "988", name: "An L\u1eadp", prefix: "X\u00e3" },
          {
            id: "989",
            name: "D\u1ea7u Ti\u1ebfng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "990", name: "\u0110\u1ecbnh  Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "991", name: "\u0110\u1ecbnh An", prefix: "X\u00e3" },
          { id: "992", name: "\u0110\u1ecbnh Th\u00e0nh", prefix: "X\u00e3" },
          { id: "993", name: "Long H\u00f2a", prefix: "X\u00e3" },
          { id: "994", name: "Long T\u00e2n", prefix: "X\u00e3" },
          { id: "995", name: "Minh H\u00f2a", prefix: "X\u00e3" },
          { id: "996", name: "Minh T\u00e2n", prefix: "X\u00e3" },
          { id: "997", name: "Minh Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "998", name: "Thanh An", prefix: "X\u00e3" },
          { id: "999", name: "Thanh Tuy\u1ec1n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "12759", name: "13/3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12760", name: "20/8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12761",
            name: "B\u00e0u Rong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12762", name: "B\u00e0u Sen", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12763",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12764", name: "DH703", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12765", name: "DH708", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12766", name: "DH716", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12767",
            name: "\u0110\u1ed9c L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12768", name: "\u0110T 744", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12769", name: "\u0110T 747", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12770", name: "\u0110T 748", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12771", name: "\u0110T 749", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12772", name: "\u0110T 749A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12773", name: "\u0110T 749B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12774", name: "\u0110T 749C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12775", name: "\u0110T 749D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12776", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "12777",
            name: "H\u1ed3 Ch\u00ed Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12778",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12779",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12780",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12781",
            name: "Ng\u00f4 V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12782",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12783",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12784",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12785",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12786",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12787",
            name: "Nguy\u1ec5n V\u0103n Ng\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12788",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12789",
            name: "Ph\u1ea1m Th\u1ecb Hoa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12790", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12791", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12792",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12793",
            name: "T\u1ec9nh l\u1ed9 239",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12794",
            name: "T\u1ec9nh l\u1ed9 240",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12795",
            name: "T\u1ec9nh l\u1ed9 744",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12796",
            name: "T\u1ec9nh l\u1ed9 748",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12797",
            name: "T\u1ec9nh l\u1ed9 749",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12798",
            name: "T\u1ec9nh l\u1ed9 750",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12799",
            name: "T\u1ec9nh l\u1ed9 751",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12800",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12801",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12802",
            name: "Tr\u1ea7n V\u0103n L\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12803",
            name: "Tr\u1ea7n V\u0103n Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12804", name: "T\u1ef1 Do", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12805",
            name: "V\u0103n C\u00f4ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12806",
            name: "V\u00e0nh \u0110ai",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2179",
            name: "The Garden Houses",
            lat: "11.354266166687",
            lng: "106.36992645264",
          },
        ],
      },
      {
        id: "66",
        name: "D\u0129 An",
        wards: [
          { id: "1000", name: "An B\u00ecnh", prefix: "Ph\u01b0\u1eddng" },
          { id: "1001", name: "B\u00ecnh An", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1002",
            name: "B\u00ecnh Th\u1eafng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1003", name: "D\u0129 An", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1004",
            name: "\u0110\u00f4ng H\u00f2a",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1005",
            name: "T\u00e2n B\u00ecnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1006",
            name: "T\u00e2n \u0110\u00f4ng Hi\u1ec7p",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "12807", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12808", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12809", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12810", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12811", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12812", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12813", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12814", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12815", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12816", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12817", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12818", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12819", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12820", name: "22/12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12821", name: "25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12822", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12823", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12824", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12825", name: "33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12826", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12827", name: "434", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12828", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12829", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12830", name: "60/09", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12831", name: "621", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12832", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12833", name: "743", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12834", name: "743A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12835", name: "743B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12836", name: "743C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12837", name: "7A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12838", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12839", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12840", name: "A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12841", name: "A2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12842", name: "A5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12843", name: "An B\u00ecnh", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12844", name: "An Nh\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12845", name: "An Ph\u00fa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12846",
            name: "An Ph\u00fa 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12847",
            name: "An Ph\u00fa 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12848",
            name: "An Ph\u00fa 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12849",
            name: "An Ph\u00fa 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12850",
            name: "An Ph\u00fa 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12851",
            name: "An Ph\u00fa 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12852",
            name: "An Ph\u00fa 34",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12853",
            name: "An Ph\u00fa 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12854",
            name: "An Ph\u00fa 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12855",
            name: "An Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12856", name: "B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12857", name: "Ba C\u00e2y", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12858",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          { id: "12859", name: "Ba Na", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12860", name: "B\u00e0 Sa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12861",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12862", name: "Becamex", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12863", name: "B\u00ecnh An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12864",
            name: "B\u00ecnh \u0110\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12865",
            name: "B\u00ecnh \u0110\u01b0\u1eddng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12866",
            name: "B\u00ecnh \u0110\u01b0\u1eddng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12867",
            name: "B\u00ecnh \u0110\u01b0\u1eddng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12868",
            name: "B\u00ecnh \u0110\u01b0\u1eddng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12869",
            name: "B\u00ecnh Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12870",
            name: "B\u00ecnh Minh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12871",
            name: "B\u00ecnh Minh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12872",
            name: "B\u00ecnh Thung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12873",
            name: "B\u00ecnh Thung 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12874",
            name: "B\u00ecnh Thung 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12875",
            name: "B\u00f9i H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12876",
            name: "B\u00f9i Th\u1ecb C\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12877",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12878", name: "C", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12879",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12880", name: "C\u00e2y Da", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12882",
            name: "C\u00e2y Da X\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12883",
            name: "C\u00e2y Da X\u1ec1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12881",
            name: "C\u00e2y \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12884",
            name: "C\u00e2y \u0110i\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12885",
            name: "C\u00e2y G\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12886",
            name: "C\u00e2y G\u0103ng - C\u00e2y Sao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12887", name: "C\u00e2y Keo", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12888",
            name: "C\u00e2y L\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12889",
            name: "C\u00e2y X\u1ec1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12890",
            name: "Chi\u00eau Li\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12891",
            name: "C\u00f4 B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12892",
            name: "C\u00f4 Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12893", name: "D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12894", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12895", name: "D11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12896", name: "D12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12897", name: "D13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12898", name: "D14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12899", name: "D16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12900", name: "D18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12901", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12902", name: "D2A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12903", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12904", name: "D4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12905", name: "D5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12906", name: "D6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12907", name: "D7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12908", name: "D8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12909", name: "D9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12917", name: "DL12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12918", name: "DN2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12938",
            name: "D\u01b0\u01a1ng \u0110\u00ecnh C\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12910",
            name: "\u0110\u1ea1i l\u1ed9 \u0110\u1ed9c l\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12911",
            name: "\u0110\u1ea1i L\u1ed9 \u0110\u00f4ng T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12912",
            name: "\u0110\u1ea1i Thi\u00ean L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12913",
            name: "\u0110\u1eb7ng V\u0103n M\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12914",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12915",
            name: "\u0110\u00e0o S\u01b0 T\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12916",
            name: "\u0110\u00e0o Trinh Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12919",
            name: "\u0110\u1ed7 T\u1ea5n Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12920",
            name: "\u0110o\u00e0n Th\u1ecb Kia",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12921",
            name: "\u0110\u1ed9c L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12922",
            name: "\u0110\u00f4ng A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12923",
            name: "\u0110\u00f4ng An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12924",
            name: "\u0110\u00f4ng B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12925",
            name: "\u0110\u00f4ng Chi\u00eau",
            prefix: "Ph\u1ed1",
          },
          {
            id: "12926",
            name: "\u0110\u00f4ng Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12927",
            name: "\u0110\u00f4ng T\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12928",
            name: "\u0110\u00f4ng T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12929",
            name: "\u0110\u00f4ng Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12930",
            name: "\u0110\u00f4ng Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12931", name: "\u0110T 743", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12932", name: "\u0110T 743A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12933", name: "\u0110T 743B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12934", name: "\u0110T 743C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12935", name: "\u0110T 747B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12936", name: "\u0110T 749", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12937", name: "\u0110T 843B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12939",
            name: "\u0110\u01b0\u1eddng L",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12940", name: "E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12941", name: "F", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12942", name: "G", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12943", name: "G3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12944", name: "GS08", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12945", name: "GS1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12946", name: "GS10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12947", name: "GS11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12948", name: "GS12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12949", name: "GS13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12950", name: "GS14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12951", name: "GS15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12952", name: "GS16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12953", name: "GS17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12954", name: "GS18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12955", name: "GS2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12956", name: "GS3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12957", name: "GS4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12958", name: "GS9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12959", name: "H", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12960",
            name: "H\u00e0 Huy Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12961", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "12962",
            name: "H\u1ea7m \u0110\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12963",
            name: "Hi\u1ec7p Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12964",
            name: "H\u1ed3 \u0110\u1eafc Di",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12965", name: "H\u1ed1 Lang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12966",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12967",
            name: "Ho\u00e0ng C\u1ea7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12968",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12969",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12970",
            name: "Hu\u1ef3nh T\u1ea5n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12971",
            name: "Hu\u1ef3nh Th\u1ecb T\u01b0\u01a1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12972", name: "K", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12973", name: "Khu 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12974", name: "L", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12975",
            name: "L\u00e1i Thi\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12976",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12977",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12978",
            name: "L\u00ea Th\u1ecb Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12979",
            name: "L\u00ea Th\u1ecb \u00dat",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12980",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "12981",
            name: "L\u00ea V\u0103n M\u1ea7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12982",
            name: "L\u00ea V\u0103n T\u00e1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12983",
            name: "L\u00ea V\u0103n Ti\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12984",
            name: "Li\u00ean Huy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12985",
            name: "L\u1ed3 \u00d4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12986",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          { id: "12987", name: "M", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "12988",
            name: "M\u1ea1ch C\u00e2y \u0110\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12989",
            name: "M\u1ea1ch Th\u1ecb Li\u1ec5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12990",
            name: "Mi H\u00f2a H\u1ee3p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12991",
            name: "M\u00f4\u0300i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12992",
            name: "M\u1ef3 H\u00f2a H\u1ee3p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "12993",
            name: "M\u1ef9 Ph\u01b0\u1edbc T\u00e2n V\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "12994", name: "N", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12995", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12996", name: "N10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12997", name: "N11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12998", name: "N14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "12999", name: "N15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13000", name: "N16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13001", name: "N17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13002", name: "N18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13003", name: "N19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13004", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13005", name: "N20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13006", name: "N21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13007", name: "N22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13008", name: "N23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13009", name: "N3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13010", name: "N4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13011", name: "N5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13012", name: "N7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13013", name: "N8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13014", name: "N9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13015",
            name: "N\u0103m L\u1eeda 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13016",
            name: "Nghi\u1ec7p V\u1ee5 \u0110\u01b0\u1eddng S\u1eaft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13017",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13018",
            name: "Ng\u00f4 V\u0103n S\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13019",
            name: "Ng\u00f4i Sao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13020",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13021",
            name: "Nguy\u1ec5n B\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13022",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13023",
            name: "Nguy\u1ec5n C\u00f4ng Chu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13024",
            name: "Nguy\u1ec5n C\u00f4ng Hoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13025",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13027",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13026",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13028",
            name: "Nguy\u1ec5n \u0110\u1ee9c Thi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13029",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13030",
            name: "Nguy\u1ec5n Phong S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13031",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13032",
            name: "Nguy\u1ec5n Th\u1ecb Ch\u1ea1y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13033",
            name: "Nguy\u1ec5n Th\u1ecb Ch\u1ed3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13034",
            name: "Nguy\u1ec5n Th\u1ecb Kh\u1eafp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13035",
            name: "Nguy\u1ec5n Th\u1ecb K\u00eca",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13036",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13037",
            name: "Nguy\u1ec5n Th\u1ecb Ph\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13038",
            name: "Nguy\u1ec5n Th\u1ecb T\u1ed3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13039",
            name: "Nguy\u1ec5n Th\u1ecb T\u01b0\u01a1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13040",
            name: "Nguy\u1ec5n Th\u1ecb \u00dat",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13041",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "13042",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13043",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13044",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13045",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13046",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13047",
            name: "Nguy\u1ec5n V\u0103n M\u1ea7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13048",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13049",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13050",
            name: "Nguy\u1ec5n Xi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13051",
            name: "Nh\u1ecb \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13052",
            name: "Nh\u1ecb \u0110\u1ed3ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13053",
            name: "Nh\u1ecb \u0110\u1ed3ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13054",
            name: "N\u1ed9i H\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13055",
            name: "N\u1ed9i H\u00f3a 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13056",
            name: "N\u1ed9i H\u00f3a II",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13057", name: "NP4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13058",
            name: "\u1ed0ng N\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13059",
            name: "Ph\u1ea1m H\u1eefu L\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13060",
            name: "Ph\u1ea1m H\u1eefu L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13061",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13062",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13063",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13064",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "13065",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13066",
            name: "Phan Huy \u00cdch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13067", name: "Q", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13068",
            name: "Qu\u1ed1c L\u1ed9 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13069",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13070",
            name: "Qu\u1ed1c l\u1ed9 1K",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13071",
            name: "Quy\u1ebft Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13072", name: "S", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13073", name: "S1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13074",
            name: "Si-li-c\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13075", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13076", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13077", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13078", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13079", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13080", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13081", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13082", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13083", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13084", name: "S\u1ed1 18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13085", name: "S\u1ed1 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13086", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13087", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13088", name: "S\u1ed1 21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13089", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13090", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13091", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13092", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13093", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13094", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13095", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13096",
            name: "Song H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13097",
            name: "Su\u1ed1i L\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13098",
            name: "Su\u1ed1i L\u1ed3 \u1ed2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13099",
            name: "S\u01b0\u01a1ng Nguy\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13100",
            name: "S\u01b0\u01a1ng Nguy\u1ec7t \u00c1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13101", name: "T", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13102", name: "T\u00e2n An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13103",
            name: "T\u00e2n Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13104",
            name: "T\u00e2n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13105",
            name: "T\u00e2n H\u00f2a \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13106",
            name: "T\u00e2n L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13107",
            name: "T\u00e2n Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13108",
            name: "T\u00e2n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13109",
            name: "T\u00e2n Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13110",
            name: "T\u00e2n Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13111",
            name: "T\u00e2n Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13112",
            name: "T\u00e2n V\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13113",
            name: "Th\u1eafng L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13114",
            name: "Th\u1eafng L\u1ee3i 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13115",
            name: "Thanh Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13116",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13117",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13118",
            name: "Th\u1ed1ng Nh\u1ea5t 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13119",
            name: "Th\u1ed1ng Nh\u1ea5t 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13120",
            name: "T\u1ec9nh l\u1ed9 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13121",
            name: "T\u1ec9nh L\u1ed9 638",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13122",
            name: "T\u1ec9nh l\u1ed9 743",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13123",
            name: "T\u1ec9nh l\u1ed9 743B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13124",
            name: "T\u1ec9nh l\u1ed9 743C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13125",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13126",
            name: "T\u00f4 V\u0129nh Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13127",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "13128",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13129",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "13130",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13131",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13132",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13133",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13134",
            name: "Tr\u1ea7n Th\u1ecb D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13135",
            name: "Tr\u1ea7n Th\u1ecb V\u1eefng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13136",
            name: "Tr\u1ea7n Th\u1ecb Xanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13137",
            name: "Tr\u1ea7n V\u0103n \u01a0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13138",
            name: "Tr\u1ecbnh L\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13139",
            name: "Tr\u1ecbnh Phong \u0110\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13140",
            name: "Trung Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13141",
            name: "Tr\u01b0\u01a1ng Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13142",
            name: "Tr\u01b0\u1eddng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13143",
            name: "Tru\u00f4ng Tre",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13144",
            name: "Tr\u01b0\u01a1ng V\u0103n V\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13145",
            name: "T\u00fa X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13146",
            name: "Tua G\u00f2 M\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13147", name: "V", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13148",
            name: "V\u00e0m Su\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13149",
            name: "V\u00e0nh \u0110ai 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13150",
            name: "V\u00e0nh \u0110ai 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13151",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13152",
            name: "V\u0169ng Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13153",
            name: "V\u0169ng Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13154", name: "X1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13155", name: "X5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13156",
            name: "Xa L\u1ed9 H\u00e0 N\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13157",
            name: "Xu\u00e2n Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2180",
            name: "An B\u00ecnh Sunshine",
            lat: "10.872192382812",
            lng: "106.75782775879",
          },
          {
            id: "2181",
            name: "An Th\u1ecbnh Ph\u00e1t 3",
            lat: "10.930732727051",
            lng: "106.76296234131",
          },
          {
            id: "2182",
            name: "Biconsi",
            lat: "10.931226730347",
            lng: "106.77545928955",
          },
          {
            id: "2183",
            name: "B\u00ecnh Nguy\u00ean",
            lat: "10.916078567505",
            lng: "106.81015777588",
          },
          {
            id: "2184",
            name: "C\u0103n h\u1ed9 Th\u1ea1nh T\u00e2n",
            lat: "10.913371086121",
            lng: "106.74765014648",
          },
          {
            id: "2185",
            name: "Champaca Garden",
            lat: "10.890683174133",
            lng: "106.78463745117",
          },
          {
            id: "2186",
            name: "Charm Plaza",
            lat: "10.907730102539",
            lng: "106.74210357666",
          },
          {
            id: "2187",
            name: "D\u0129 An Golden Land",
            lat: "10.92743396759",
            lng: "106.74822235107",
          },
          {
            id: "2188",
            name: "Dream House D\u0129 An",
            lat: "10.897820472717",
            lng: "106.81374359131",
          },
          {
            id: "2189",
            name: "Ecohomes Tri Th\u1ee9c Tr\u1ebb",
            lat: "10.894906997681",
            lng: "106.76184082031",
          },
          {
            id: "2190",
            name: "Green Square",
            lat: "10.889403343201",
            lng: "106.77593994141",
          },
          {
            id: "2191",
            name: "Him Lam Ph\u00fa \u0110\u00f4ng",
            lat: "21.0306224823",
            lng: "105.84886932373",
          },
          {
            id: "2192",
            name: "Khu d\u00e2n c\u01b0 T\u00e2n B\u00ecnh",
            lat: "10.936984062195",
            lng: "106.75185394287",
          },
          {
            id: "2193",
            name: "Khu \u0111\u00f4 th\u1ecb \u0110\u1ea1i Ph\u00fa",
            lat: "10.891921043396",
            lng: "106.7940826416",
          },
          {
            id: "2194",
            name: "Khu \u0111\u00f4 th\u1ecb \u0110\u00f4ng B\u00ecnh D\u01b0\u01a1ng",
            lat: "10.939131736755",
            lng: "106.76799011231",
          },
          {
            id: "2195",
            name: "Metro Tower",
            lat: "10.875980377197",
            lng: "106.80850982666",
          },
          {
            id: "2196",
            name: "Ph\u00fa \u0110\u00f4ng Premier",
            lat: "10.872254371643",
            lng: "106.75511932373",
          },
          {
            id: "2197",
            name: "Samsora Riverside",
            lat: "10.899457931519",
            lng: "106.83215332031",
          },
          {
            id: "2198",
            name: "The Mall City",
            lat: "10.904679298401",
            lng: "106.74153900147",
          },
        ],
      },
      {
        id: "67",
        name: "Ph\u00fa Gi\u00e1o",
        wards: [
          { id: "1007", name: "An B\u00ecnh", prefix: "X\u00e3" },
          { id: "1008", name: "An Linh", prefix: "X\u00e3" },
          { id: "1009", name: "An Long", prefix: "X\u00e3" },
          { id: "1010", name: "An Th\u00e1i", prefix: "X\u00e3" },
          { id: "1011", name: "Ph\u01b0\u1edbc H\u00f2a", prefix: "X\u00e3" },
          { id: "1012", name: "Ph\u01b0\u1edbc Sang", prefix: "X\u00e3" },
          {
            id: "1013",
            name: "Ph\u01b0\u1edbc V\u0129nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1014", name: "Tam L\u1eadp", prefix: "X\u00e3" },
          { id: "1015", name: "T\u00e2n Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "1016", name: "T\u00e2n Long", prefix: "X\u00e3" },
          { id: "1017", name: "V\u0129nh H\u00f2a", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "13158", name: "18/9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13159", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13160", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13161", name: "74", prefix: "Ph\u1ed1" },
          {
            id: "13162",
            name: "B\u1ebfn S\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13163", name: "B\u1ed1 Mua", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13164",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13165",
            name: "C\u00f4ng Ch\u00faa Ng\u1ecdc H\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13166", name: "DH501", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13167", name: "DH502", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13168", name: "DH503", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13169", name: "DH504", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13170", name: "DH505", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13171", name: "DH506", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13172", name: "DH507", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13173", name: "DH508", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13174", name: "DH510", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13175", name: "DH511", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13176", name: "DH512", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13177", name: "DH513", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13178", name: "DH515", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13179", name: "DH516", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13180",
            name: "\u0110\u1ed9c L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13181", name: "\u0110T 741", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13182", name: "\u0110T 750", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13183", name: "HL 502", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13184",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13185",
            name: "Nguy\u1ec5n V\u0103n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13186",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13187",
            name: "Phan Ch\u00e2u Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13188",
            name: "Ph\u01b0\u1edbc Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13189", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13190",
            name: "Qu\u1ed1c L\u1ed9 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13191",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13192", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13193",
            name: "T\u1ec9nh l\u1ed9 741",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13194",
            name: "T\u1ec9nh l\u1ed9 750",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2199",
            name: "Ph\u01b0\u1edbc H\u00f2a GoldenLand",
            lat: "11.238389968872",
            lng: "106.73932647705",
          },
        ],
      },
      {
        id: "68",
        name: "T\u00e2n Uy\u00ean",
        wards: [
          { id: "1018", name: "B\u1ea1ch \u0110\u1eb1ng", prefix: "X\u00e3" },
          { id: "1019", name: "B\u00ecnh M\u1ef9", prefix: "X\u00e3" },
          { id: "1020", name: "\u0110\u1ea5t Cu\u1ed1c", prefix: "X\u00e3" },
          { id: "1021", name: "Hi\u1ebfu Li\u00eam", prefix: "X\u00e3" },
          { id: "1022", name: "H\u1ed9i Ngh\u0129a", prefix: "X\u00e3" },
          { id: "1023", name: "Kh\u00e1nh B\u00ecnh", prefix: "X\u00e3" },
          { id: "1024", name: "L\u1ea1c An", prefix: "X\u00e3" },
          { id: "1025", name: "Ph\u00fa Ch\u00e1nh", prefix: "X\u00e3" },
          { id: "1026", name: "T\u00e2n B\u00ecnh", prefix: "X\u00e3" },
          { id: "1027", name: "T\u00e2n \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "1028", name: "T\u00e2n Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "1029", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "1030", name: "T\u00e2n M\u1ef9", prefix: "X\u00e3" },
          {
            id: "1031",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1032", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          {
            id: "1033",
            name: "T\u00e2n V\u0129nh Hi\u1ec7p",
            prefix: "X\u00e3",
          },
          {
            id: "1034",
            name: "Th\u00e1i H\u00f2a",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1035", name: "Th\u1ea1nh H\u1ed9i", prefix: "X\u00e3" },
          { id: "1036", name: "Th\u1ea1nh Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "1037", name: "Th\u01b0\u1eddng T\u00e2n", prefix: "X\u00e3" },
          {
            id: "1038",
            name: "Uy\u00ean H\u01b0ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1039", name: "V\u0129nh T\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "13195", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13196", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13197", name: "110B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13198", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13199", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13200", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13201", name: "26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13202", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13203", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13204", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13205", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13206", name: "31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13207", name: "35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13208", name: "36", prefix: "Ph\u1ed1" },
          { id: "13209", name: "37", prefix: "Ph\u1ed1" },
          { id: "13210", name: "38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13211", name: "39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13212", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13213", name: "40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13214", name: "410", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13215", name: "414", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13216", name: "418", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13217", name: "42", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13218", name: "43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13219", name: "45", prefix: "Ph\u1ed1" },
          { id: "13220", name: "46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13221", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13222", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13223", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13224", name: "51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13225", name: "53", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13226", name: "55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13227", name: "59", prefix: "Ph\u1ed1" },
          { id: "13228", name: "63", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13229", name: "65", prefix: "Ph\u1ed1" },
          { id: "13230", name: "66", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13231", name: "67", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13232", name: "68", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13233", name: "69", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13234", name: "71", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13235", name: "72", prefix: "Ph\u1ed1" },
          { id: "13236", name: "73", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13237", name: "742", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13238", name: "747", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13239", name: "747B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13240", name: "747C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13241", name: "76", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13242", name: "78", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13243", name: "80", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13244", name: "81", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13245", name: "86", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13246", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13247", name: "94", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13248",
            name: "An Ph\u00fa \u0110\u00f4ng 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13249",
            name: "B\u1ea1ch \u0110\u1eb1ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13250",
            name: "B\u1ea1ch \u0110\u1eb1ng 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13251",
            name: "B\u00ecnh Chu\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13252",
            name: "B\u00ecnh Chu\u1ea9n 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13253",
            name: "B\u00ecnh Chu\u1ea9n 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13254",
            name: "B\u00ecnh Chu\u1ea9n 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13255",
            name: "B\u00ecnh Chu\u1ea9n 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13256",
            name: "B\u00ecnh Chu\u1ea9n 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13257",
            name: "B\u00ecnh Chu\u1ea9n 31",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13258",
            name: "Bi\u0300nh Chu\u00e2\u0309n 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13259",
            name: "B\u00ecnh Chu\u1ea9n 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13260",
            name: "Ch\u00e2u Th\u1ecb Kim",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13261", name: "CN2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13262", name: "CN5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13263", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13264", name: "D10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13265", name: "D14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13266", name: "D15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13267", name: "D1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13268", name: "D1B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13269", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13270", name: "D28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13271", name: "D2B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13272", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13274", name: "D3-N6A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13273", name: "D3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13275", name: "D4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13276", name: "D4A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13277", name: "D4B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13278", name: "D5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13279", name: "D52", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13280", name: "D55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13281", name: "D5A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13282", name: "D6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13283", name: "D7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13284", name: "D8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13286",
            name: "D\u00e2n Ch\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13287", name: "DB1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13288", name: "DB4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13289", name: "DB5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13290", name: "DB7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13291", name: "DDX", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13292", name: "DH107", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13293", name: "DH401", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13294", name: "DH402", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13295", name: "DH403", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13296", name: "DH404", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13297", name: "DH405", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13298", name: "DH406", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13299", name: "DH407", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13300", name: "DH408", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13301", name: "DH409", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13302", name: "DH410", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13303", name: "DH411", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13304", name: "DH412", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13305", name: "DH417", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13306", name: "DH418", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13307", name: "DH420", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13308", name: "DH421", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13309", name: "DH422", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13310", name: "DH423", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13311", name: "DH436", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13312", name: "DH48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13313", name: "DH515", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13314", name: "DN2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13326", name: "DT 746A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13333", name: "DX", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13334", name: "DX 01", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13335", name: "DX 010", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13336", name: "DX 011", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13337", name: "DX 012", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13338", name: "DX 013", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13339", name: "DX 014", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13340", name: "DX 015", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13341", name: "DX 016", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13342", name: "DX 017", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13343", name: "DX 02", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13344", name: "DX 021", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13345", name: "DX 022", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13346", name: "DX 024", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13347", name: "DX 025", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13348", name: "DX 026", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13349", name: "DX 027", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13350", name: "DX 029", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13351", name: "DX 03", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13352", name: "DX 030", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13353", name: "DX 034", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13354", name: "DX 036", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13355", name: "DX 04", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13356", name: "DX 041", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13357", name: "DX 05", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13358", name: "DX 058", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13359", name: "DX 06", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13360", name: "DX 07", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13361", name: "DX 071", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13362", name: "DX 08", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13363", name: "DX 09", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13364", name: "DX 132", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13285",
            name: "\u0110\u1ea1i L\u1ed9 B\u00ecnh D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13315",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13316", name: "\u0110T 417", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13317", name: "\u0110T 602", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13318", name: "\u0110T 704", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13319", name: "\u0110T 741", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13320", name: "\u0110T 742", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13321", name: "\u0110T 743", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13322", name: "\u0110T 743B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13323", name: "\u0110T 743C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13324", name: "\u0110T 745", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13325", name: "\u0110T 746", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13327", name: "\u0110T 746B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13328", name: "\u0110T 747", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13329", name: "\u0110T 747A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13330", name: "\u0110T 747B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13331", name: "\u0110T 747C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13332", name: "\u0110T 749", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13365", name: "E11", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13366",
            name: "H\u00e0n Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13367",
            name: "H\u1eadu L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13368",
            name: "Hi\u1ec7p Th\u00e0nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13369", name: "HL 402", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13370", name: "HL 407", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13371", name: "HL 417", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13372", name: "HL405", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13373", name: "H\u1ed3 Nam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13374",
            name: "H\u1ed3 Trung Qu\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13375",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13376",
            name: "H\u1ed9i Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13377",
            name: "H\u01b0\u01a1ng l\u1ed9 406",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13378",
            name: "H\u01b0\u01a1ng l\u1ed9 412",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13379",
            name: "H\u01b0\u01a1ng l\u1ed9 418",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13380",
            name: "Hu\u1ef3nh V\u0103n L\u0169y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13381",
            name: "Kh\u00e1nh B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13382",
            name: "Kh\u00e1nh B\u00ecnh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13383",
            name: "Kh\u00e1nh B\u00ecnh 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13384",
            name: "Kh\u00e1nh B\u00ecnh 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13385",
            name: "Kh\u00e1nh B\u00ecnh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13386",
            name: "Kh\u00e1nh B\u00ecnh 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13387",
            name: "Kh\u00e1nh B\u00ecnh 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13388",
            name: "Kh\u00e1nh B\u00ecnh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13389",
            name: "Kh\u00e1nh B\u00ecnh 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13390",
            name: "Kh\u00e1nh B\u00ecnh 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13391",
            name: "Kh\u00e1nh B\u00ecnh 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13392",
            name: "Kh\u00e1nh B\u00ecnh 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13393",
            name: "Kh\u00e1nh B\u00ecnh 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13394",
            name: "Kh\u00e1nh B\u00ecnh 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13395",
            name: "Kh\u00e1nh B\u00ecnh 33",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13396",
            name: "Kh\u00e1nh B\u00ecnh 35",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13397",
            name: "Kh\u00e1nh B\u00ecnh 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13398",
            name: "Kh\u00e1nh B\u00ecnh 38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13399",
            name: "Kh\u00e1nh B\u00ecnh 40",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13400",
            name: "Kh\u00e1nh B\u00ecnh 41",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13401",
            name: "Kh\u00e1nh B\u00ecnh 44",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13402",
            name: "Kh\u00e1nh B\u00ecnh 45",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13403",
            name: "Kh\u00e1nh B\u00ecnh 46",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13404",
            name: "Kh\u00e1nh B\u00ecnh 48",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13405",
            name: "Kh\u00e1nh B\u00ecnh 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13406",
            name: "Kh\u00e1nh B\u00ecnh 50",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13407",
            name: "Kh\u00e1nh B\u00ecnh 51",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13408",
            name: "Kh\u00e1nh B\u00ecnh 53",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13409",
            name: "Kh\u00e1nh B\u00ecnh 56",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13410",
            name: "Kh\u00e1nh B\u00ecnh 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13411",
            name: "Kh\u00e1nh B\u00ecnh 61",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13412",
            name: "Kh\u00e1nh B\u00ecnh 63",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13413",
            name: "Kh\u00e1nh B\u00ecnh 64",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13414",
            name: "Kh\u00e1nh B\u00ecnh 65",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13415",
            name: "Kh\u00e1nh B\u00ecnh 67",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13416",
            name: "Kh\u00e1nh B\u00ecnh 68",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13417",
            name: "Kh\u00e1nh B\u00ecnh 69",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13418",
            name: "Kh\u00e1nh B\u00ecnh 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13419",
            name: "Kh\u00e1nh B\u00ecnh 70",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13420",
            name: "Kh\u00e1nh B\u00ecnh 71",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13421",
            name: "Kh\u00e1nh B\u00ecnh 72",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13422",
            name: "Kh\u00e1nh B\u00ecnh 73",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13423",
            name: "Kh\u00e1nh B\u00ecnh 74",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13424",
            name: "Kh\u00e1nh B\u00ecnh 75",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13425",
            name: "Kh\u00e1nh B\u00ecnh 76",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13426",
            name: "Kh\u00e1nh B\u00ecnh 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13427",
            name: "Kha\u0301nh H\u00f4\u0323i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13428",
            name: "Kh\u00e1nh L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13429",
            name: "Kh\u00e1nh L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13430",
            name: "Kh\u1edfi Ngh\u0129a B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13431",
            name: "Li\u00ean Huy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13432",
            name: "L\u1ed9c H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13433",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13434", name: "M5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13435", name: "M\u1ed9c Xum", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13436",
            name: "M\u01b0\u01a1ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13437", name: "M\u1ef9 Hi\u1ec7p", prefix: "Ph\u1ed1" },
          {
            id: "13438",
            name: "M\u1ef9 Ph\u01b0\u1edbc T\u00e2n V\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13439", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13440", name: "N10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13441", name: "N11B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13442", name: "N12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13443", name: "N12B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13444", name: "N13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13445", name: "N15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13446", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13447", name: "N3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13448", name: "N3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13449", name: "N3B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13450", name: "N4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13451", name: "N40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13452", name: "N5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13453", name: "N5A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13454", name: "N6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13455", name: "N6A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13456", name: "N6B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13457", name: "N7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13458", name: "N7A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13459", name: "N8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13460", name: "N9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13461", name: "NA5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13462", name: "NA6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13463", name: "NA7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13464", name: "NB10A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13465", name: "NB10B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13466", name: "NB2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13467", name: "NB3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13468", name: "NB4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13469", name: "NB5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13470", name: "NB6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13471", name: "NB6B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13472", name: "NB7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13473", name: "NB7A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13474", name: "NB9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13475", name: "NB9A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13476",
            name: "Nguy\u1ec5n Huy Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13477",
            name: "Nguy\u1ec5n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13478",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13479",
            name: "Nguy\u1ec5n V\u0103n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13480",
            name: "N\u00fai M\u1ed9t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13481",
            name: "\u00d4ng \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13482",
            name: "Ph\u00fa Ch\u00e1nh 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13483",
            name: "Ph\u00fa Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13484",
            name: "Ph\u01b0\u1edbc H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13485",
            name: "Ph\u01b0\u1edbc H\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13486",
            name: "Qu\u1ed1c L\u1ed9 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13487",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13488", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13489", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13490", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13491", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13492", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13493", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13494", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13495", name: "S\u1ed1 18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13496", name: "S\u1ed1 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13497", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13498", name: "S\u1ed1 21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13499", name: "S\u1ed1 23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13500", name: "S\u1ed1 28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13501", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13502", name: "S\u1ed1 30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13503", name: "S\u1ed1 32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13504", name: "S\u1ed1 33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13505", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13506", name: "S\u1ed1 43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13507", name: "S\u1ed1 44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13508", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13509", name: "S\u1ed1 55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13510", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13511", name: "S\u1ed1 65", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13512", name: "S\u1ed1 73", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13513", name: "S\u1ed1 77", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13514", name: "S\u1ed1 80", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13515", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13516",
            name: "Su\u1ed1i Tre",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13517", name: "T\u00e2n Ba", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13518",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13519",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13520",
            name: "T\u00e2n Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13521",
            name: "T\u00e2n Hi\u1ec7p 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13522",
            name: "T\u00e2n Hi\u1ec7p 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13523",
            name: "T\u00e2n Hi\u1ec7p 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13524",
            name: "T\u00e2n Hi\u1ec7p 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13525",
            name: "T\u00e2n hi\u1ec7p 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13526",
            name: "T\u00e2n Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13527",
            name: "T\u00e2n Hi\u1ec7p 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13528",
            name: "T\u00e2n Hi\u1ec7p 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13529",
            name: "T\u00e2n Hi\u1ec7p 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13530",
            name: "T\u00e2n Hi\u1ec7p 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13531",
            name: "T\u00e2n Hi\u1ec7p 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13532",
            name: "T\u00e2n Hi\u1ec7p 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13533",
            name: "T\u00e2n Hi\u1ec7p 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13534",
            name: "T\u00e2n Hi\u1ec7p 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13535",
            name: "T\u00e2n Hi\u1ec7p 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13536",
            name: "T\u00e2n Hi\u1ec7p 33",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13537",
            name: "T\u00e2n Hi\u1ec7p 34",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13538",
            name: "T\u00e2n Hi\u1ec7p 35",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13539",
            name: "T\u00e2n Hi\u1ec7p 36",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13540",
            name: "T\u00e2n Hi\u1ec7p 38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13541",
            name: "T\u00e2n Hi\u1ec7p 39",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13542",
            name: "T\u00e2n Hi\u1ec7p 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13543",
            name: "T\u00e2n Hi\u1ec7p 42",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13544",
            name: "T\u00e2n Hi\u1ec7p 43",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13545",
            name: "T\u00e2n Hi\u1ec7p 44",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13546",
            name: "T\u00e2n Hi\u1ec7p 46",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13547",
            name: "T\u00e2n Hi\u1ec7p 47",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13548",
            name: "T\u00e2n Hi\u1ec7p 49",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13549",
            name: "T\u00e2n Hi\u1ec7p 50",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13550",
            name: "T\u00e2n Hi\u1ec7p 53",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13551",
            name: "T\u00e2n Hi\u1ec7p 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13552",
            name: "T\u00e2n Hi\u1ec7p 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13553",
            name: "T\u00e2n Hi\u1ec7p 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13554",
            name: "T\u00e2n L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13555",
            name: "T\u00e2n L\u1eadp 35",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13556",
            name: "T\u00e2n Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13557",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13558",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13559",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13560",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13561",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13562",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13563",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13564",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13565",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13566",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13567",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13568",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13569",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13570",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 28",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13571",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13572",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 33",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13573",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 34",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13574",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 35",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13575",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 36",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13576",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13577",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13578",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 40",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13579",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 41",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13580",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 42",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13581",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 43",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13582",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13583",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13584",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13585",
            name: "T\u1ea1o L\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13586",
            name: "T\u1ea1o L\u1ef1c 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13587",
            name: "T\u1ea1o L\u1ef1c 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13588",
            name: "T\u1ea1o L\u1ef1c 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13589",
            name: "Th\u1ea1nh H\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13590",
            name: "Th\u1ea1nh H\u1ed9i 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13591",
            name: "Th\u1ea1nh H\u1ed9i 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13592",
            name: "Th\u1ea1nh H\u1ed9i 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13593",
            name: "Th\u1ea1nh L\u1ed9c 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13594",
            name: "Th\u1ea1nh Xu\u00e2n 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13595",
            name: "Th\u1ea1nh Xu\u00e2n 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13596",
            name: "Th\u1ea1nh Xu\u00e2n 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13597",
            name: "Th\u00edch B\u1eedu \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13598",
            name: "Th\u1ee7 Khoa Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13599",
            name: "T\u1ec9nh l\u1ed9 743",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13600",
            name: "T\u1ec9nh l\u1ed9 745",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13601",
            name: "T\u1ec9nh l\u1ed9 746",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13602",
            name: "T\u1ec9nh l\u1ed9 747",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13603",
            name: "T\u1ec9nh L\u1ed9 747B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13604",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13605",
            name: "Tr\u1ea7n Quang \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13606",
            name: "Tr\u00edch S\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13607",
            name: "Tr\u01b0\u01a1ng V\u0103n \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13608",
            name: "V\u00e0nh \u0110ai 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13609", name: "X2B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13610", name: "X4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13611", name: "Xa La", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13612", name: "XB1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13613", name: "XB2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13614", name: "XB4", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "2200",
            name: "Civilized City",
            lat: "11.127707481384",
            lng: "106.69435119629",
          },
          {
            id: "2201",
            name: "IJC@VSIP",
            lat: "11.127960205078",
            lng: "106.70578765869",
          },
          {
            id: "2202",
            name: "KDC Hill Land 19",
            lat: "11.040537834168",
            lng: "106.77300262451",
          },
          {
            id: "2203",
            name: "K\u0110T T\u00e2n Long",
            lat: "11.084159851074",
            lng: "106.78834533691",
          },
          {
            id: "2204",
            name: "New Central Park",
            lat: "11.123950958252",
            lng: "106.70551300049",
          },
          {
            id: "2205",
            name: "The Mall City II",
            lat: "11.128478050232",
            lng: "106.70683288574",
          },
          {
            id: "2206",
            name: "VSIP2 m\u1edf r\u1ed9ng",
            lat: "11.141220092773",
            lng: "106.70607757568",
          },
        ],
      },
      {
        id: "69",
        name: "Th\u1ee7 D\u1ea7u M\u1ed9t",
        wards: [
          { id: "1040", name: "Ch\u00e1nh M\u1ef9", prefix: "X\u00e3" },
          {
            id: "1041",
            name: "Ch\u00e1nh Ngh\u0129a",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1042",
            name: "\u0110\u1ecbnh H\u00f2a",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1043", name: "Hi\u1ec7p An", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1044",
            name: "Hi\u1ec7p Th\u00e0nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1045", name: "H\u00f2a Ph\u00fa", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1046",
            name: "Ph\u00fa C\u01b0\u1eddng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1047", name: "Ph\u00fa H\u00f2a", prefix: "Ph\u01b0\u1eddng" },
          { id: "1048", name: "Ph\u00fa L\u1ee3i", prefix: "Ph\u01b0\u1eddng" },
          { id: "1049", name: "Ph\u00fa M\u1ef9", prefix: "Ph\u01b0\u1eddng" },
          { id: "1050", name: "Ph\u00fa T\u00e2n", prefix: "Ph\u01b0\u1eddng" },
          { id: "1051", name: "Ph\u00fa Th\u1ecd", prefix: "Ph\u01b0\u1eddng" },
          { id: "1052", name: "T\u00e2n An", prefix: "X\u00e3" },
          {
            id: "1053",
            name: "T\u01b0\u01a1ng B\u00ecnh Hi\u1ec7p",
            prefix: "X\u00e3",
          },
        ],
        streets: [
          { id: "13615", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13616", name: "1/12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13617", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13618", name: "108", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13619", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13620", name: "110B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13621", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13622", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13623", name: "1369", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13624", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13625", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13626", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13627", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13628", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13629", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13630", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13631", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13632", name: "220", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13633", name: "221", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13634", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13635", name: "24", prefix: "Ph\u1ed1" },
          { id: "13636", name: "24A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13637", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13638", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13639", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13640", name: "31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13641", name: "32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13642", name: "322", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13643", name: "34", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13644", name: "35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13645", name: "36", prefix: "Ph\u1ed1" },
          { id: "13646", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13647", name: "40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13648", name: "44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13649", name: "45", prefix: "Ph\u1ed1" },
          { id: "13650", name: "48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13651", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13652", name: "51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13653", name: "53", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13654", name: "55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13655", name: "57", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13656", name: "59", prefix: "Ph\u1ed1" },
          { id: "13657", name: "5A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13658", name: "5B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13659", name: "5C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13660", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13661", name: "60", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13662", name: "61", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13663", name: "63", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13664", name: "64", prefix: "Ph\u1ed1" },
          { id: "13665", name: "65", prefix: "Ph\u1ed1" },
          { id: "13666", name: "66", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13667", name: "67", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13668", name: "68", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13669", name: "69", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13670", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13671", name: "70", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13672", name: "71", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13673", name: "72", prefix: "Ph\u1ed1" },
          { id: "13674", name: "73", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13675", name: "74", prefix: "Ph\u1ed1" },
          { id: "13676", name: "75", prefix: "Ph\u1ed1" },
          { id: "13677", name: "76", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13678", name: "78", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13679", name: "79", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13680", name: "7B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13681", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13682", name: "80", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13683", name: "81", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13684", name: "84", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13685", name: "85", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13686", name: "91", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13687", name: "93", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13688", name: "95", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13689", name: "97", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13690", name: "99", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13691",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13692", name: "An M\u1ef9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13693",
            name: "An Th\u1ea1nh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13694",
            name: "An Th\u1ea1nh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13695",
            name: "An Th\u1ea1nh 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13696", name: "AN2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13697",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13698",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13699",
            name: "B\u00e0u B\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13700",
            name: "B\u00f9i Ng\u1ecdc Thu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13701",
            name: "B\u00f9i Qu\u1ed1c Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13702",
            name: "B\u00f9i V\u0103n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13703",
            name: "B\u01b0ng C\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13704",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13705",
            name: "C\u00e2y \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13706", name: "C\u00e2y Me", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13707",
            name: "C\u00e2y Vi\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13708",
            name: "Ch\u00e1nh L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13709",
            name: "Ch\u00e1nh L\u1ed9c 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13710",
            name: "Ch\u00e1nh L\u1ed9c 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13711",
            name: "Ch\u00e1nh Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13712", name: "CN2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13713", name: "D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13763", name: "D-N7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13714", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13715", name: "D10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13716", name: "D11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13717", name: "D12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13718", name: "D13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13719", name: "D14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13720", name: "D15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13721", name: "D17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13722", name: "D19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13723", name: "D1B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13724", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13725", name: "D26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13726", name: "D27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13727", name: "D28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13728", name: "D2A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13729", name: "D2B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13730", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13731", name: "D3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13732", name: "D4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13733", name: "D410", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13734", name: "D4A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13735", name: "D5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13736", name: "D55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13737", name: "D6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13738", name: "D7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13739", name: "D7A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13740", name: "D8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13741", name: "D810", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13742", name: "D9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13743", name: "DA1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13744", name: "DA2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13745", name: "DA7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13748",
            name: "D\u00e2n Ch\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13749", name: "DB10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13750", name: "DB11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13751", name: "DB4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13752", name: "DB5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13753", name: "DB6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13754", name: "DB7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13755", name: "DB7A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13756", name: "DB7B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13757", name: "DB9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13758", name: "DH410", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13761", name: "DJ10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13762", name: "DJ9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13769", name: "DS13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13777", name: "DX", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13778", name: "DX 001", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13779", name: "DX 002", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13780", name: "DX 004", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13781", name: "DX 006", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13782", name: "DX 008", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13783", name: "DX 01", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13784", name: "DX 010", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13785", name: "DX 011", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13786", name: "DX 012", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13787", name: "DX 013", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13788", name: "DX 014", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13789", name: "DX 015", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13790", name: "DX 016", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13791", name: "DX 017", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13792", name: "DX 018", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13793", name: "DX 02", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13794", name: "DX 020", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13795", name: "DX 021", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13796", name: "DX 022", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13797", name: "DX 023", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13798", name: "DX 024", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13799", name: "DX 025", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13800", name: "DX 026", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13801", name: "DX 027", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13802", name: "DX 028", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13803", name: "DX 029", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13804", name: "DX 03", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13805", name: "DX 030", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13806", name: "DX 031", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13807", name: "DX 032", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13808", name: "DX 033", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13809", name: "DX 034", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13810", name: "DX 035", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13811", name: "DX 036", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13812", name: "DX 037", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13813", name: "DX 038", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13814", name: "DX 039", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13815", name: "DX 04", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13816", name: "DX 040", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13817", name: "DX 041", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13818", name: "DX 042", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13819", name: "DX 043", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13820", name: "DX 044", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13821", name: "DX 045", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13822", name: "DX 046", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13823", name: "DX 047", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13824", name: "DX 048", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13825", name: "DX 049", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13826", name: "DX 05", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13827", name: "DX 050", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13828", name: "DX 051", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13829", name: "DX 054", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13830", name: "DX 055", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13831", name: "DX 057", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13832", name: "DX 058", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13833", name: "DX 059", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13834", name: "DX 06", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13835", name: "DX 060", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13836", name: "DX 061", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13837", name: "DX 062", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13838", name: "DX 063", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13839", name: "DX 064", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13840", name: "DX 065", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13841", name: "DX 066", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13842", name: "DX 067", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13843", name: "DX 068", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13844", name: "DX 069", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13845", name: "DX 07", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13846", name: "DX 070", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13847", name: "DX 071", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13848", name: "DX 072", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13849", name: "DX 073", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13850", name: "DX 074", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13851", name: "DX 075", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13852", name: "DX 076", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13853", name: "DX 077", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13854", name: "DX 078", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13855", name: "DX 08", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13856", name: "DX 080", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13857", name: "DX 081", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13858", name: "DX 082", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13859", name: "DX 083", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13860", name: "DX 084", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13861", name: "DX 085", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13862", name: "DX 086", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13863", name: "DX 087", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13864", name: "DX 088", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13865", name: "DX 089", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13866", name: "DX 09", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13867", name: "DX 090", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13868", name: "DX 091", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13869", name: "DX 092", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13870", name: "DX 094", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13871", name: "DX 095", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13872", name: "DX 096", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13873", name: "DX 098", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13874", name: "DX 100", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13875", name: "DX 101", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13876", name: "DX 102", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13877", name: "DX 103", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13878", name: "DX 104", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13879", name: "DX 105", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13880", name: "DX 106", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13881", name: "DX 107", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13882", name: "DX 108", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13883", name: "DX 109", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13884", name: "DX 110", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13885", name: "DX 111", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13886", name: "DX 112", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13887", name: "DX 113", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13888", name: "DX 114", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13889", name: "DX 115", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13890", name: "DX 115/18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13891", name: "DX 116", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13892", name: "DX 117", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13893", name: "DX 118", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13894", name: "DX 119", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13895", name: "DX 120", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13896", name: "DX 121", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13897", name: "DX 122", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13898", name: "DX 123", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13899", name: "DX 124", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13900", name: "DX 125", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13901", name: "DX 126", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13902", name: "DX 127", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13903", name: "DX 128", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13904", name: "DX 129", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13905", name: "DX 131", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13906", name: "DX 132", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13907", name: "DX 133", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13908", name: "DX 134", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13909", name: "DX 136", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13910", name: "DX 140", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13911", name: "DX 141", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13912", name: "DX 142", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13913", name: "DX 143", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13914", name: "DX 144", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13915", name: "DX 145", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13916", name: "DX 146", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13917", name: "DX 148", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13918", name: "DX 149", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13919", name: "DX 150", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13920", name: "DX 177", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13921", name: "DX 288", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13922", name: "DX 301", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13923", name: "DX 322", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13746",
            name: "\u0110\u1ea1i L\u1ed9 B\u00ecnh D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13747",
            name: "\u0110\u1ea1i L\u1ed9 M\u1ef9 Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13759",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13760",
            name: "\u0110inh B\u1ed9 L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13764",
            name: "\u0110o\u00e0n \u0110\u1ee9c Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13765",
            name: "\u0110o\u00e0n Th\u1ecb Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13766",
            name: "\u0110o\u00e0n Tr\u1ea7n Nghi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13767",
            name: "\u0110\u1ed3ng C\u00e2y Vi\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13768",
            name: "\u0110\u1ed3ng Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13770", name: "\u0110T 741", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13771", name: "\u0110T 742", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13772", name: "\u0110T 743", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13773", name: "\u0110T 744", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13774", name: "\u0110T 745", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13775", name: "\u0110T 747", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13776", name: "\u0110T 747B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13924", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          { id: "13925", name: "Heo M\u1ecdi", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13926", name: "Hi\u1ec7p An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13927",
            name: "H\u1ed3 V\u0103n C\u1ed1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13928",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13929",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13930",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13931",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13932",
            name: "Hu\u1ef3nh Minh M\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13933",
            name: "Hu\u1ef3nh Th\u1ecb Ch\u1ea5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13934",
            name: "Hu\u1ef3nh Th\u1ecb Hi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13935",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13936",
            name: "Hu\u1ef3nh V\u0103n C\u00f9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13937",
            name: "Hu\u1ef3nh V\u0103n L\u0169y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13938",
            name: "Hu\u1ef3nh V\u0103n Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13939", name: "K48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13940", name: "K8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13941", name: "L3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13942", name: "L9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13943",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13944",
            name: "L\u00ea Ch\u00ed D\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13945",
            name: "L\u00ea Ho\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13946",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13947", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13948",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13949",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13950",
            name: "L\u00ea Th\u1ecb Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13951",
            name: "L\u00ea V\u0103n T\u00e1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13952",
            name: "Li\u00ean Ph\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13953",
            name: "L\u00f2 Ch\u00e9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13954", name: "L\u00f2 Lu", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13955",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13956",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "13957",
            name: "L\u00fd Th\u01b0\u1eddng Li\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13958",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13959", name: "M2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13960",
            name: "Mai B\u00e1 H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13961",
            name: "M\u1ef9 Ph\u01b0\u1edbc 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "13962",
            name: "M\u1ef9 Ph\u01b0\u1edbc T\u00e2n V\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13963", name: "N", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13964", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13965", name: "N10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13966", name: "N10A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13967", name: "N11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13968", name: "N12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13969", name: "N13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13970", name: "N14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13971", name: "N15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13972", name: "N16", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13973",
            name: "N16 B\u1eafc C\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13974", name: "N17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13975", name: "N18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13976", name: "N19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13977", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13978", name: "N20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13979", name: "N22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13980", name: "N23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13981", name: "N24A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13982", name: "N24B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13983", name: "N25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13984", name: "N3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13985", name: "N3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13986", name: "N4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13987", name: "N4A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13988", name: "N5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13989", name: "N6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13990", name: "N7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13991", name: "N8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13992", name: "N9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13993", name: "NA2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13994", name: "NA6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "13995",
            name: "Nam K\u1ef3 Kh\u1edfi Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "13996", name: "NB3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13997", name: "NB4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13998", name: "NB5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "13999", name: "NB9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14000", name: "NE3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14001",
            name: "Ng\u00f4 Ch\u00ed Qu\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14002",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14003",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14004",
            name: "Ng\u00f4 V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14005",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14006",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14008",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14007",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14009",
            name: "Nguy\u1ec5n \u0110\u1ee9c Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14010",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14011",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14012",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14013",
            name: "Nguy\u1ec5n Th\u00e0nh V\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14014",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14015",
            name: "Nguy\u1ec5n Th\u1ecb Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14016",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14017",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14018",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14019",
            name: "Nguy\u1ec5n V\u0103n L\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14020",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14021",
            name: "Nguy\u1ec5n V\u0103n L\u1ed9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14022",
            name: "Nguy\u1ec5n V\u0103n Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14023",
            name: "Nguy\u1ec5n V\u0103n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14024",
            name: "Nguy\u1ec5n V\u0103n Ti\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14025",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14026",
            name: "Nguy\u1ec7t Qu\u1ebf 9-16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14027", name: "NJ9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14028", name: "NK3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14029", name: "NT9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14030", name: "PCB36", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14031",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14032",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14033",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14034",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "14035",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14036",
            name: "Ph\u00fa Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14037",
            name: "Ph\u00fa Ch\u00e1nh A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14038",
            name: "Ph\u00fa Ch\u00e1nh B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14039",
            name: "Ph\u00fa Ch\u00e1nh C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14040",
            name: "Ph\u00fa Ch\u00e1nh D",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14041",
            name: "Ph\u00fa H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14042",
            name: "Ph\u00fa L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14043",
            name: "Ph\u00fa M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14044",
            name: "Ph\u00fa V\u0103n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14045",
            name: "Quanh Th\u00e0nh \u0110\u1ea1i Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14046",
            name: "Qu\u1ed1c L\u1ed9 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14047",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14048",
            name: "Qu\u1ed1c l\u1ed9 80",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14049", name: "Quy L\u01b0u", prefix: "Ph\u1ed1" },
          { id: "14050", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14051", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14052", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14053", name: "S\u1ed1 11B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14054", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14055", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14056", name: "S\u1ed1 13A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14057", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14058", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14059", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14060", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14061", name: "S\u1ed1 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14062", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14063", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14064", name: "S\u1ed1 21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14065", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14066", name: "S\u1ed1 23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14067", name: "S\u1ed1 24", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14068", name: "S\u1ed1 25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14069", name: "S\u1ed1 26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14070", name: "S\u1ed1 28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14071", name: "S\u1ed1 29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14072", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14073", name: "S\u1ed1 31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14074", name: "S\u1ed1 32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14075", name: "S\u1ed1 35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14076", name: "S\u1ed1 36", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14077", name: "S\u1ed1 37", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14078", name: "S\u1ed1 38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14079", name: "S\u1ed1 39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14080", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14081", name: "S\u1ed1 40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14082", name: "S\u1ed1 41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14083", name: "S\u1ed1 42", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14084", name: "S\u1ed1 45", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14085", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14086", name: "S\u1ed1 51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14087", name: "S\u1ed1 55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14088", name: "S\u1ed1 5B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14089", name: "S\u1ed1 5C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14090", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14091", name: "S\u1ed1 65", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14092", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14093", name: "S\u1ed1 70", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14094", name: "S\u1ed1 73", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14095", name: "S\u1ed1 79", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14096", name: "S\u1ed1 7B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14097", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14098", name: "S\u1ed1 80", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14099", name: "S\u1ed1 81", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14100", name: "S\u1ed1 86", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14101", name: "S\u1ed1 88", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14102", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14103", name: "S\u1ed1 9A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14104",
            name: "S\u00f3ng Th\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14105", name: "T\u00e2n An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14106",
            name: "T\u00e2n \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14107",
            name: "T\u00e2n V\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14108",
            name: "T\u1ea1o L\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14109",
            name: "T\u1ea1o L\u1ef1c 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14110",
            name: "T\u1ea1o L\u1ef1c 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14111",
            name: "T\u1ea1o L\u1ef1c 2B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14112",
            name: "T\u1ea1o L\u1ef1c 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14113",
            name: "T\u1ea1o L\u1ef1c 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14114",
            name: "T\u1ea1o L\u1ef1c 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14115",
            name: "T\u1ea1o L\u1ef1c 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14116",
            name: "T\u1ea1o L\u1ef1c 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14117",
            name: "Th\u00edch Qu\u1ea3ng \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14118",
            name: "T\u1ec9nh l\u1ed9 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14119",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "14120",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14121",
            name: "Tr\u1ea7n Ng\u1ecdc L\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14122",
            name: "Tr\u1ea7n Ng\u1ecdc Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14123",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14124",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14125",
            name: "Tr\u1ea7n T\u1eed B\u00ecnh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "14126",
            name: "Tr\u1ea7n V\u0103n Gi\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14127",
            name: "Tr\u1ea7n V\u0103n \u01a0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14128", name: "Tr\u00e1nh Ngh\u0129a", prefix: "Ph\u1ed1" },
          {
            id: "14129",
            name: "Tr\u1ecbnh Ho\u00e0i \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14130",
            name: "Tr\u1eeb V\u0103n Ph\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14131",
            name: "Tr\u1eeb V\u0103n Th\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14132",
            name: "Tru\u00f4ng B\u1ed3ng B\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14133",
            name: "T\u00fa X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14134",
            name: "V\u0103n C\u00f4ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14135",
            name: "V\u00e0nh \u0110ai 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14136",
            name: "V\u0129nh T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14137",
            name: "V\u00f5 Minh \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14138",
            name: "V\u00f5 Th\u00e0nh Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14139",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14140", name: "X1B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14141", name: "X65", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14142", name: "X95", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14143",
            name: "X\u00f3m Gu\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14144", name: "Yersin", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "2207",
            name: "Becamex City Center",
            lat: "10.975109100342",
            lng: "106.67076873779",
          },
          {
            id: "2208",
            name: "Becamex \u0110\u1ecbnh H\u00f2a",
            lat: "11.044321060181",
            lng: "106.65971374512",
          },
          {
            id: "2209",
            name: "Becamex H\u00f2a L\u1ee3i",
            lat: "11.086225509644",
            lng: "106.67230224609",
          },
          {
            id: "2210",
            name: "Detaco Ecovilas",
            lat: "11.006924629211",
            lng: "106.62910461426",
          },
          {
            id: "2211",
            name: "\u0110\u00f4ng \u0110\u00f4 \u0110\u1ea1i Ph\u1ed1",
            lat: "11.072381973267",
            lng: "106.68639373779",
          },
          {
            id: "2212",
            name: "Gold Star Tower",
            lat: "10.977697372437",
            lng: "106.65661621094",
          },
          {
            id: "2213",
            name: "Gold Town",
            lat: "11.055389404297",
            lng: "106.6876373291",
          },
          {
            id: "2214",
            name: "Green Pearl",
            lat: "11.069567680359",
            lng: "106.67484283447",
          },
          {
            id: "2215",
            name: "Hi\u1ec7p Th\u00e0nh III",
            lat: "11.002366065979",
            lng: "106.66919708252",
          },
          {
            id: "2216",
            name: "IJC Aroma B\u00ecnh D\u01b0\u01a1ng",
            lat: "11.057020187378",
            lng: "106.67530822754",
          },
          {
            id: "2217",
            name: "KDC Hi\u1ec7p Ph\u00e1t",
            lat: "11.005311012268",
            lng: "106.65684509277",
          },
          {
            id: "2218",
            name: "K\u0110T Ph\u00fac \u0110\u1ea1t",
            lat: "10.995797157288",
            lng: "106.68402099609",
          },
          {
            id: "2219",
            name: "Midori Park",
            lat: "11.061677932739",
            lng: "106.6823348999",
          },
          {
            id: "2220",
            name: "New Horizon",
            lat: "10.967465400696",
            lng: "106.6579284668",
          },
          {
            id: "2221",
            name: "Ph\u00fa C\u01b0\u1eddng (chung c\u01b0 Biconsi)",
            lat: "10.981477737427",
            lng: "106.65587615967",
          },
          {
            id: "2222",
            name: "Ph\u00fa H\u00f2a I",
            lat: "10.972611427307",
            lng: "106.68513488769",
          },
          {
            id: "2223",
            name: "Ph\u00fa Th\u1ecbnh",
            lat: "10.974110603332",
            lng: "106.66967010498",
          },
          {
            id: "2224",
            name: "Prince Town",
            lat: "11.058047294617",
            lng: "106.666015625",
          },
          {
            id: "2225",
            name: "Sora Gardens",
            lat: "11.046622276306",
            lng: "106.67892456055",
          },
          {
            id: "2226",
            name: "Sunflower",
            lat: "11.053737640381",
            lng: "106.67378997803",
          },
          {
            id: "2227",
            name: "Sunrise Apartment",
            lat: "10.995948791504",
            lng: "106.65089416504",
          },
          {
            id: "2228",
            name: "TDC Plaza",
            lat: "11.059379577637",
            lng: "106.67649841309",
          },
          {
            id: "2229",
            name: "Th\u00e0nh Ph\u1ed1 M\u1edbi B\u00ecnh D\u01b0\u01a1ng",
            lat: "11.056387901306",
            lng: "106.68144989014",
          },
          {
            id: "2230",
            name: "The Morning City",
            lat: "11.057543754578",
            lng: "106.70275878906",
          },
          {
            id: "2231",
            name: "T\u01b0\u01a1ng B\u00ecnh Hi\u1ec7p",
            lat: "11.012592315674",
            lng: "106.6269607544",
          },
          {
            id: "2232",
            name: "Uni-town",
            lat: "11.069066047668",
            lng: "106.68383026123",
          },
          {
            id: "2233",
            name: "Vsip II",
            lat: "11.045499801636",
            lng: "106.62501525879",
          },
          {
            id: "2234",
            name: "Wharfland",
            lat: "10.998602867127",
            lng: "106.65188598633",
          },
        ],
      },
      {
        id: "70",
        name: "Thu\u1eadn An",
        wards: [
          { id: "1054", name: "An Ph\u00fa", prefix: "Ph\u01b0\u1eddng" },
          { id: "1055", name: "An S\u01a1n", prefix: "X\u00e3" },
          { id: "1056", name: "An Th\u1ea1nh", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1057",
            name: "B\u00ecnh Chu\u1ea9n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1058",
            name: "B\u00ecnh H\u00f2a",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1059", name: "B\u00ecnh Nh\u00e2m", prefix: "X\u00e3" },
          { id: "1060", name: "H\u01b0ng \u0110\u1ecbnh", prefix: "X\u00e3" },
          {
            id: "1061",
            name: "L\u00e1i Thi\u00eau",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1062", name: "Thu\u1eadn Giao", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1063",
            name: "V\u0129nh Ph\u00fa",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "14145", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14146", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14147", name: "105", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14148", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14149", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14150", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14151", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14152", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14153", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14154", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14155", name: "17A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14156", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14157", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14158", name: "1B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14159", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14160", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14161", name: "20/12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14162", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14163", name: "21/7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14164", name: "22/12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14165", name: "22D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14166", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14167", name: "24", prefix: "Ph\u1ed1" },
          { id: "14168", name: "25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14169", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14170", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14171", name: "2A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14172", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14173", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14174", name: "30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14175", name: "32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14176", name: "33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14177", name: "34A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14178", name: "35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14179", name: "37", prefix: "Ph\u1ed1" },
          { id: "14180", name: "38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14181", name: "38A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14182", name: "39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14183", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14184", name: "40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14185", name: "41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14186", name: "43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14187", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14188", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14189", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14190", name: "550", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14191", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14192", name: "61", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14193", name: "61A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14194", name: "62", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14195", name: "66", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14196", name: "67", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14197", name: "69", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14198", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14199", name: "72", prefix: "Ph\u1ed1" },
          { id: "14200", name: "743", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14201", name: "743C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14202", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14203", name: "83", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14204", name: "88", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14205", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14206", name: "93", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14207", name: "A2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14208", name: "An M\u1ef9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14209", name: "An Ph\u00fa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14210",
            name: "An Ph\u00fa 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14211",
            name: "An Ph\u00fa 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14212",
            name: "An Ph\u00fa 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14213",
            name: "An Ph\u00fa 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14214",
            name: "An Ph\u00fa 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14215",
            name: "An Ph\u00fa 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14216",
            name: "An Ph\u00fa 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14217",
            name: "An Ph\u00fa 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14218",
            name: "An Ph\u00fa 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14219",
            name: "An ph\u00fa 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14220",
            name: "An Ph\u00fa 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14221",
            name: "An Ph\u00fa 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14222",
            name: "An Ph\u00fa 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14223",
            name: "An Ph\u00fa 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14224",
            name: "An Ph\u00fa 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14225",
            name: "An Ph\u00fa 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14226",
            name: "An Ph\u00fa 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14227",
            name: "An Ph\u00fa 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14228",
            name: "An Ph\u00fa 28",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14229",
            name: "An Ph\u00fa 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14230",
            name: "An Ph\u00fa 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14231",
            name: "An Ph\u00fa 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14232",
            name: "An Ph\u00fa 31",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14233",
            name: "An Ph\u00fa 31A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14234",
            name: "An Ph\u00fa 34",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14235",
            name: "An Ph\u00fa 35",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14236",
            name: "An Ph\u00fa 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14237",
            name: "An Ph\u00fa 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14238",
            name: "An Ph\u00fa 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14239",
            name: "An Ph\u00fa 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14240",
            name: "An Ph\u00fa 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14241",
            name: "An Ph\u00fa 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14242",
            name: "An Ph\u00fa 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14243",
            name: "An Ph\u00fa \u0110\u00f4ng 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14244", name: "An Qu\u1edbi", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14245", name: "An S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14246",
            name: "An S\u01a1n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14247",
            name: "An S\u01a1n 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14248",
            name: "An S\u01a1n 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14249",
            name: "An S\u01a1n 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14250",
            name: "An S\u01a1n 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14251",
            name: "An S\u01a1n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14252",
            name: "An S\u01a1n 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14253",
            name: "An S\u01a1n 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14254",
            name: "An S\u01a1n 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14255",
            name: "An S\u01a1n 31",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14256",
            name: "An S\u01a1n 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14257",
            name: "An S\u01a1n 33",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14258",
            name: "An S\u01a1n 35",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14259",
            name: "An S\u01a1n 36",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14260",
            name: "An S\u01a1n 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14261",
            name: "An S\u01a1n 38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14262",
            name: "An S\u01a1n 39",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14263",
            name: "An S\u01a1n 41",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14264",
            name: "An S\u01a1n 42",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14265",
            name: "An S\u01a1n 43",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14266",
            name: "An S\u01a1n 45",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14267",
            name: "An S\u01a1n 55",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14268",
            name: "An S\u01a1n 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14269",
            name: "An S\u01a1n 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14270",
            name: "An Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14271",
            name: "An Th\u1ea1nh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14272",
            name: "An Th\u1ea1nh 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14273",
            name: "An Th\u1ea1nh 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14274",
            name: "An Th\u1ea1nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14275",
            name: "An Th\u1ea1nh 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14276",
            name: "An Th\u1ea1nh 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14277",
            name: "An Th\u1ea1nh 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14278",
            name: "An Th\u1ea1nh 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14279",
            name: "An Th\u1ea1nh 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14280",
            name: "An Th\u1ea1nh 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14281",
            name: "An Th\u1ea1nh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14282",
            name: "An Th\u1ea1nh 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14283",
            name: "An Th\u1ea1nh 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14284",
            name: "An Th\u1ea1nh 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14285",
            name: "An Th\u1ea1nh 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14286",
            name: "An Th\u1ea1nh 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14287",
            name: "An Th\u1ea1nh 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14288",
            name: "An Th\u1ea1nh 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14289",
            name: "An Th\u1ea1nh 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14290",
            name: "An Th\u1ea1nh 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14291",
            name: "An Th\u1ea1nh 34",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14292",
            name: "An Th\u1ea1nh 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14293",
            name: "An Th\u1ea1nh 38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14294",
            name: "An Th\u1ea1nh 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14295",
            name: "An Th\u1ea1nh 42",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14296",
            name: "An Th\u1ea1nh 47",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14297",
            name: "An Th\u1ea1nh 50",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14298",
            name: "An Th\u1ea1nh 51",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14299",
            name: "An Th\u1ea1nh 55",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14300",
            name: "An Th\u1ea1nh 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14301",
            name: "An Th\u1ea1nh 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14302",
            name: "An Th\u1ea1nh 66",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14303",
            name: "An Th\u1ea1nh 67",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14304",
            name: "An Th\u1ea1nh 68",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14305",
            name: "An Th\u1ea1nh 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14306",
            name: "An Th\u1ea1nh 72",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14307",
            name: "An Th\u1ea1nh 73",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14308",
            name: "An Th\u1ea1nh 75",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14309",
            name: "An Th\u1ea1nh 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14310",
            name: "\u1ea4p B\u00ecnh H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14311",
            name: "\u1ea4p B\u00ecnh Thu\u1eadn 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14312",
            name: "\u1ea4p H\u00f2a L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14313", name: "B184", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14314", name: "B\u00e0 Bao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14315",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14316", name: "BH03", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14317", name: "BH22", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14318",
            name: "B\u00ecnh Chu\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14319",
            name: "B\u00ecnh Chu\u1ea9n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14320",
            name: "B\u00ecnh Chu\u1ea9n 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14321",
            name: "B\u00ecnh Chu\u1ea9n 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14322",
            name: "B\u00ecnh Chu\u1ea9n 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14323",
            name: "B\u00ecnh Chu\u1ea9n 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14324",
            name: "B\u00ecnh Chu\u1ea9n 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14325",
            name: "B\u00ecnh Chu\u1ea9n 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14326",
            name: "B\u00ecnh Chu\u1ea9n 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14327",
            name: "B\u00ecnh Chu\u1ea9n 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14328",
            name: "B\u00ecnh Chu\u1ea9n 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14329",
            name: "B\u00ecnh Chu\u1ea9n 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14330",
            name: "B\u00ecnh Chu\u1ea9n 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14331",
            name: "B\u00ecnh Chu\u1ea9n 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14332",
            name: "B\u00ecnh Chu\u1ea9n 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14333",
            name: "B\u00ecnh Chu\u1ea9n 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14334",
            name: "B\u00ecnh Chu\u1ea9n 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14335",
            name: "B\u00ecnh Chu\u1ea9n 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14336",
            name: "B\u00ecnh Chu\u1ea9n 28",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14337",
            name: "B\u00ecnh Chu\u1ea9n 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14338",
            name: "B\u00ecnh Chu\u1ea9n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14339",
            name: "B\u00ecnh Chu\u1ea9n 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14340",
            name: "B\u00ecnh Chu\u1ea9n 31",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14341",
            name: "B\u00ecnh Chu\u1ea9n 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14342",
            name: "B\u00ecnh Chu\u1ea9n 34",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14343",
            name: "B\u00ecnh Chu\u1ea9n 35",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14344",
            name: "B\u00ecnh Chu\u1ea9n 36",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14345",
            name: "B\u00ecnh Chu\u1ea9n 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14346",
            name: "B\u00ecnh Chu\u1ea9n 39",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14347",
            name: "B\u00ecnh Chu\u1ea9n 40",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14348",
            name: "B\u00ecnh Chu\u1ea9n 41",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14349",
            name: "B\u00ecnh Chu\u1ea9n 42",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14350",
            name: "B\u00ecnh Chu\u1ea9n 45",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14351",
            name: "B\u00ecnh Chu\u1ea9n 46",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14352",
            name: "B\u00ecnh Chu\u1ea9n 47",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14353",
            name: "B\u00ecnh Chu\u1ea9n 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14354",
            name: "B\u00ecnh Chu\u1ea9n 54",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14355",
            name: "B\u00ecnh Chu\u1ea9n 55",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14356",
            name: "B\u00ecnh Chu\u1ea9n 59",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14357",
            name: "B\u00ecnh Chu\u1ea9n 61",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14358",
            name: "Bi\u0300nh Chu\u00e2\u0309n 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14359",
            name: "B\u00ecnh Chu\u1ea9n 63",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14360",
            name: "B\u00ecnh Chu\u1ea9n 64",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14361",
            name: "B\u00ecnh Chu\u1ea9n 65",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14362",
            name: "B\u00ecnh Chu\u1ea9n 66",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14363",
            name: "B\u00ecnh Chu\u1ea9n 67",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14364",
            name: "B\u00ecnh Chu\u1ea9n 69",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14365",
            name: "B\u00ecnh Chu\u1ea9n 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14366",
            name: "B\u00ecnh Chu\u1ea9n 71",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14367",
            name: "B\u00ecnh Chu\u1ea9n 72",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14368",
            name: "B\u00ecnh Chu\u1ea9n 75",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14369",
            name: "B\u00ecnh Chu\u1ea9n 76",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14370",
            name: "B\u00ecnh Chu\u1ea9n 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14371",
            name: "B\u00ecnh Chu\u1ea9n 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14372",
            name: "B\u00ecnh \u0110\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14373",
            name: "B\u00ecnh \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14374",
            name: "B\u00ecnh \u0110\u1ee9c 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14375",
            name: "B\u00ecnh \u0110\u1ee9c 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14376",
            name: "B\u00ecnh Giao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14377",
            name: "B\u00ecnh H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14378",
            name: "B\u00ecnh H\u00f2a 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14379",
            name: "B\u00ecnh H\u00f2a 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14380",
            name: "B\u00ecnh H\u00f2a 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14381",
            name: "B\u00ecnh H\u00f2a 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14382",
            name: "B\u00ecnh H\u00f2a 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14383",
            name: "B\u00ecnh H\u00f2a 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14384",
            name: "B\u00ecnh H\u00f2a 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14385",
            name: "B\u00ecnh H\u00f2a 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14386",
            name: "B\u00ecnh H\u00f2a 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14387",
            name: "B\u00ecnh H\u00f2a 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14388",
            name: "B\u00ecnh H\u00f2a 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14389",
            name: "B\u00ecnh H\u00f2a 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14390",
            name: "B\u00ecnh Ho\u00e0 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14391",
            name: "B\u00ecnh H\u00f2a 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14392",
            name: "B\u00ecnh H\u00f2a 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14393",
            name: "B\u00ecnh H\u00f2a 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14394",
            name: "B\u00ecnh Ho\u00e0 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14395",
            name: "B\u00ecnh H\u00f2a 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14396",
            name: "B\u00ecnh H\u00f2a 74",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14397",
            name: "B\u00ecnh H\u00f2a 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14398",
            name: "B\u00ecnh H\u00f2a 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14399",
            name: "B\u00ecnh Nh\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14400",
            name: "B\u00ecnh Nh\u00e2m 01",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14401",
            name: "B\u00ecnh Nh\u00e2m 02",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14402",
            name: "B\u00ecnh Nh\u00e2m 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14403",
            name: "B\u00ecnh Nh\u00e2m 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14404",
            name: "B\u00ecnh Nh\u00e2m 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14405",
            name: "B\u00ecnh Nh\u00e2m 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14406",
            name: "B\u00ecnh Nh\u00e2m 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14407",
            name: "B\u00ecnh Nh\u00e2m 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14408",
            name: "B\u00ecnh Nh\u00e2m 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14409",
            name: "B\u00ecnh Nh\u00e2m 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14410",
            name: "B\u00ecnh Nh\u00e2m 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14411",
            name: "B\u00ecnh Nh\u00e2m 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14412",
            name: "B\u00ecnh Nh\u00e2m 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14413",
            name: "B\u00ecnh Nh\u00e2m 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14414",
            name: "B\u00ecnh Nh\u00e2m 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14415",
            name: "B\u00ecnh Nh\u00e2m 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14416",
            name: "B\u00ecnh Nh\u00e2m 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14417",
            name: "B\u00ecnh Nh\u00e2m 28",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14418",
            name: "B\u00ecnh Nh\u00e2m 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14419",
            name: "B\u00ecnh Nh\u00e2m 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14420",
            name: "B\u00ecnh Nh\u00e2m 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14421",
            name: "B\u00ecnh Nh\u00e2m 34",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14422",
            name: "B\u00ecnh Nh\u00e2m 35",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14423",
            name: "B\u00ecnh Nh\u00e2m 36",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14424",
            name: "B\u00ecnh Nh\u00e2m 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14425",
            name: "B\u00ecnh Nh\u00e2m 38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14426",
            name: "B\u00ecnh Nh\u00e2m 39",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14427",
            name: "B\u00ecnh Nh\u00e2m 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14428",
            name: "B\u00ecnh Nh\u00e2m 40",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14429",
            name: "B\u00ecnh Nh\u00e2m 41",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14430",
            name: "B\u00ecnh Nh\u00e2m 46",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14431",
            name: "B\u00ecnh Nh\u00e2m 47",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14432",
            name: "B\u00ecnh Nh\u00e2m 48",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14433",
            name: "B\u00ecnh Nh\u00e2m 49",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14434",
            name: "B\u00ecnh Nh\u00e2m 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14435",
            name: "B\u00ecnh Nh\u00e2m 56",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14436",
            name: "B\u00ecnh Nh\u00e2m 58",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14437",
            name: "B\u00ecnh Nh\u00e2m 60",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14438",
            name: "B\u00ecnh Nh\u00e2m 61",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14439",
            name: "B\u00ecnh Nh\u00e2m 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14440",
            name: "B\u00ecnh Nh\u00e2m 63",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14441",
            name: "B\u00ecnh Nh\u00e2m 64",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14442",
            name: "B\u00ecnh Nh\u00e2m 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14443",
            name: "B\u00ecnh Nh\u00e2m 74",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14444",
            name: "B\u00ecnh Nh\u00e2m 75",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14445",
            name: "B\u00ecnh Nh\u00e2m 78",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14446",
            name: "B\u00ecnh Nh\u00e2m 79",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14447",
            name: "B\u00ecnh Nh\u00e2m 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14448",
            name: "B\u00ecnh Nh\u00e2m 82",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14449",
            name: "B\u00ecnh Nh\u00e2m 83",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14450",
            name: "B\u00ecnh Nh\u00e2m 88",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14451",
            name: "B\u00ecnh Nh\u00e2m 89",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14452",
            name: "B\u00ecnh Nh\u00e2m 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14453",
            name: "B\u00ecnh Nh\u00e2m 90",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14454",
            name: "B\u00ecnh Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14455",
            name: "B\u00ecnh Ph\u00fa 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14456",
            name: "B\u00ecnh Ph\u01b0\u1edbc A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14457",
            name: "B\u00ecnh Ph\u01b0\u1edbc B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14458",
            name: "Bi\u0300nh Qu\u01a1\u0301i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14459",
            name: "B\u00ecnh Qu\u1edbi A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14460",
            name: "B\u00ecnh Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14461",
            name: "B\u00ecnh Thu\u1eadn 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14462", name: "BN83", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14463",
            name: "B\u1edd Bao S\u00f4ng S\u00e0i G\u00f2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14464",
            name: "B\u00f9i H\u1eefu Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14465",
            name: "B\u00f9i H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14466",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14467", name: "C11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14468", name: "C2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14469",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14470",
            name: "C\u1ea7u \u0110\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14471",
            name: "C\u1ea7u Ngang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14472",
            name: "C\u1ea7u T\u00e0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14473", name: "C\u00e2y Me", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14474",
            name: "Ch\u00e2u V\u0103n Ti\u1ebfp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14475",
            name: "Ch\u00f2m Sao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14476",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14477", name: "D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14478", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14479", name: "D11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14480", name: "D15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14481", name: "D16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14482", name: "D17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14483", name: "D17B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14484", name: "D18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14485", name: "D19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14486", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14487", name: "D20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14488", name: "D21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14489", name: "D22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14490", name: "D23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14491", name: "D24", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14492", name: "D25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14493", name: "D27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14494", name: "D28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14495", name: "D29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14496", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14497", name: "D30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14498", name: "D31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14499", name: "D32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14500", name: "D33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14501", name: "D35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14502", name: "D37", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14503", name: "D38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14504", name: "D39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14505", name: "D4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14506", name: "D40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14507", name: "D43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14508", name: "D5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14509", name: "D6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14510", name: "D7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14511", name: "D9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14512", name: "DA 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14513", name: "DA10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14514", name: "DA11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14515", name: "DA5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14516", name: "DA6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14517", name: "DA7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14521",
            name: "D\u00e2n Ch\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14523", name: "DC", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14524", name: "DC 82", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14525", name: "DC20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14526", name: "DC32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14527", name: "DC41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14528", name: "DC60", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14529", name: "DC61", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14530", name: "DC62", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14531", name: "DC63", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14532", name: "DC66", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14533", name: "DC70", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14534", name: "DC76", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14535", name: "DC83", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14536", name: "DC84", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14537", name: "DC85", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14538", name: "DD28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14539", name: "DDH47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14541", name: "DH101", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14542", name: "DH109", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14543", name: "DH151", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14544", name: "DH211", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14545", name: "DH218", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14546", name: "DH279", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14547", name: "DH401", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14548", name: "DH403", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14549", name: "DH404", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14550", name: "DH417", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14551", name: "DH8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14552", name: "DL12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14553", name: "DN1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14585", name: "DX", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14586", name: "DX 01", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14587", name: "DX 016", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14588", name: "DX 02", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14589", name: "DX 06", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14590", name: "DX 07", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14591", name: "DX 075", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14518",
            name: "\u0110\u1ea1i L\u1ed9 B\u00ecnh D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14519",
            name: "\u0110\u1ea1i l\u1ed9 \u0110\u1ed9c l\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14520",
            name: "\u0110\u1ea1i l\u1ed9 Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14522",
            name: "\u0110\u1ea5t Th\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14540",
            name: "\u0110\u00ea Bao S\u00f4ng S\u00e0i G\u00f2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14554",
            name: "\u0110\u1ed3 Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14555",
            name: "\u0110\u1ed7 Th\u00e0nh Nh\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14556",
            name: "\u0110\u1ed9c L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14557",
            name: "\u0110\u00f4ng An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14558",
            name: "\u0110\u1ed3ng An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14559",
            name: "\u0110\u1ed3ng An 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14560",
            name: "\u0110\u00f4ng An 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14561",
            name: "\u0110\u00f4ng An 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14562",
            name: "\u0110\u00f4ng Ba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14563",
            name: "\u0110\u00f4ng Cung C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14564",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14565",
            name: "\u0110\u00f4ng Nh\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14566",
            name: "\u0110\u00f4ng T\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14567", name: "\u0110T 37", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14568", name: "\u0110T 42", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14569", name: "\u0110T 47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14570", name: "\u0110T 734C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14571", name: "\u0110T 741", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14572", name: "\u0110T 742", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14573", name: "\u0110T 743", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14574", name: "\u0110T 743A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14575", name: "\u0110T 743B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14576", name: "\u0110T 743C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14577", name: "\u0110T 744", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14578", name: "\u0110T 745", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14579", name: "\u0110T 746", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14580", name: "\u0110T 747", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14581", name: "\u0110T 747B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14582", name: "\u0110T 747C", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14583",
            name: "\u0110\u1ee9c L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14584",
            name: "\u0110\u01b0\u1eddng AS02",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14592", name: "E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14593", name: "Gia Long", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14594", name: "H5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14595",
            name: "H\u00e0i M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14596",
            name: "H\u00e0n Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14597",
            name: "H\u1eadu L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14598",
            name: "Hi\u1ec7p Th\u00e0nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14599",
            name: "H\u1ed3 V\u0103n M\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14600",
            name: "H\u00f2a H\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14601",
            name: "H\u00f2a L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14602",
            name: "H\u00f2a L\u00e2n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14603",
            name: "H\u00f2a L\u00e2n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14604",
            name: "H\u00f2a Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14605",
            name: "H\u01b0ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14606",
            name: "H\u01b0ng \u0110\u1ecbnh 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14607",
            name: "H\u01b0ng \u0110\u1ecbnh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14608",
            name: "H\u01b0ng \u0110\u1ecbnh 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14609",
            name: "H\u01b0ng \u0110\u1ecbnh 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14610",
            name: "H\u01b0ng \u0110\u1ecbnh 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14611",
            name: "H\u01b0ng \u0110\u1ecbnh 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14612",
            name: "H\u01b0ng \u0110\u1ecbnh 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14613",
            name: "H\u01b0ng \u0110\u1ecbnh 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14614",
            name: "H\u01b0ng \u0110\u1ecbnh 31",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14615",
            name: "H\u01b0ng \u0110\u1ecbnh 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14616",
            name: "H\u01b0ng \u0110\u1ecbnh 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14617",
            name: "H\u01b0ng \u0110\u1ecbnh 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14618",
            name: "H\u01b0ng L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14619",
            name: "H\u01b0\u01a1ng L\u1ed9 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14620",
            name: "H\u1eefu Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14621",
            name: "K\u00eanh Ti\u00eau 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14622",
            name: "Kh\u00e1nh Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14623",
            name: "Kh\u1edfi Ngh\u0129a B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14624",
            name: "Khu d\u00e2n c\u01b0 V\u0129nh Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14625",
            name: "L\u00e1i Thi\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14626",
            name: "L\u00e1i Thi\u00eau 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14627",
            name: "L\u00e1i Thi\u00eau 101",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14628",
            name: "L\u00e1i Thi\u00eau 104",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14629",
            name: "L\u00e1i Thi\u00eau 105",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14630",
            name: "L\u00e1i Thi\u00eau 108",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14631",
            name: "L\u00e1i Thi\u00eau 109",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14632",
            name: "L\u00e1i Thi\u00eau 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14633",
            name: "L\u00e1i Thi\u00eau 110",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14634",
            name: "L\u00e1i Thi\u00eau 114",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14635",
            name: "L\u00e1i Thi\u00eau 115",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14636",
            name: "L\u00e1i Thi\u00eau 117",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14637",
            name: "L\u00e1i Thi\u00eau 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14638",
            name: "L\u00e1i Thi\u00eau 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14639",
            name: "L\u00e1i Thi\u00eau 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14640",
            name: "L\u00e1i Thi\u00eau 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14641",
            name: "L\u00e1i Thi\u00eau 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14642",
            name: "L\u00e1i Thi\u00eau 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14643",
            name: "L\u00e1i Thi\u00eau 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14644",
            name: "L\u00e1i Thi\u00eau 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14645",
            name: "L\u00e1i Thi\u00eau 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14646",
            name: "L\u00e1i Thi\u00eau 41",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14647",
            name: "L\u00e1i Thi\u00eau 42",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14648",
            name: "L\u00e1i Thi\u00eau 44",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14649",
            name: "L\u00e1i Thi\u00eau 45",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14650",
            name: "L\u00e1i Thi\u00eau 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14651",
            name: "L\u00e1i Thi\u00eau 50",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14652",
            name: "L\u00e1i Thi\u00eau 52",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14653",
            name: "L\u00e1i Thi\u00eau 53",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14654",
            name: "L\u00e1i Thi\u00eau 55",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14655",
            name: "L\u00e1i Thi\u00eau 58",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14656",
            name: "L\u00e1i Thi\u00eau 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14657",
            name: "L\u00e1i Thi\u00eau 60",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14658",
            name: "L\u00e1i Thi\u00eau 65",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14659",
            name: "L\u00e1i Thi\u00eau 66",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14660",
            name: "L\u00e1i Thi\u00eau 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14661",
            name: "L\u00e1i Thi\u00eau 70",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14662",
            name: "L\u00e1i Thi\u00eau 75",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14663",
            name: "L\u00e1i Thi\u00eau 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14664",
            name: "L\u00e1i Thi\u00eau 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14665",
            name: "L\u00e1i Thi\u00eau 91",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14666",
            name: "L\u00e1i Thi\u00eau 92",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14667",
            name: "L\u00e1i Thi\u00eau 93",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14668",
            name: "L\u00e1i Thi\u00eau 96",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14669",
            name: "L\u00e1i Thi\u00eau 98",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14670",
            name: "L\u00e1i Thi\u00eau 99",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14671",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14672",
            name: "L\u00ea Th\u1ecb Chung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14673",
            name: "L\u00ea Th\u1ecb Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14674",
            name: "L\u00ea V\u0103n Duy\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14675", name: "LH401", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14676",
            name: "Li\u00ean Huy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14677",
            name: "Li\u00ean Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14678",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14679",
            name: "L\u1ed9c H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14680",
            name: "Long Th\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14681",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14682",
            name: "M\u01b0\u01a1ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14683",
            name: "M\u1ef9 Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14684",
            name: "M\u1ef9 Ph\u01b0\u1edbc T\u00e2n V\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14685", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14686", name: "N13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14687", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14688", name: "N20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14689", name: "N3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14690", name: "N4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14691", name: "N5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14692", name: "N6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14693", name: "N7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14694", name: "NA1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14695", name: "NA10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14696", name: "NA11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14697", name: "NA12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14698", name: "NA2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14699", name: "NA3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14700", name: "NA4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14701", name: "NA5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14702", name: "NA6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14703", name: "NA7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14704", name: "NA8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14705", name: "NA9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14706", name: "NE8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14707",
            name: "Ng\u00f4 Ch\u00ed Qu\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14708",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14709",
            name: "Nguy\u1ec5n Ch\u00ed Qu\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14710",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14711",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14712",
            name: "Nguy\u1ec5n Duy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14713",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14714",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14715",
            name: "Nguy\u1ec5n Huy Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14716",
            name: "Nguy\u1ec5n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14717",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14718",
            name: "Nguy\u1ec5n Th\u1ecb T\u01b0\u01a1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14719",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14720",
            name: "Nguy\u1ec5n V\u0103n L\u1ed9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14721",
            name: "Nguy\u1ec5n V\u0103n Ti\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14722",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14723",
            name: "Nh\u00e0 Th\u1edd B\u00fang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14724", name: "NK9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14725",
            name: "N\u00fai M\u1ed9t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14726",
            name: "Phan Ch\u00e2u Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14727",
            name: "Phan \u0110\u00ecnh Ch\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14728",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14729",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14730",
            name: "Phan Thanh Gi\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14731",
            name: "Ph\u00f3 \u0110\u1ee9c Ch\u00ednh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "14732",
            name: "Ph\u00fa H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14733",
            name: "Ph\u00fa Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14734",
            name: "Ph\u00fa Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14735",
            name: "Ph\u00fa Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14736",
            name: "Qu\u1ed1c L\u1ed9 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14737",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14738",
            name: "Qu\u1ed1c L\u1ed9 43",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14739",
            name: "Qu\u1ed1c L\u1ed9 743",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14740", name: "R4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14741", name: "Rose Palace", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14742", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14743", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14744", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14745", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14746", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14747", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14748", name: "S\u1ed1 18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14749", name: "S\u1ed1 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14750", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14751", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14752", name: "S\u1ed1 25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14753", name: "S\u1ed1 25A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14754", name: "S\u1ed1 26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14755", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14756", name: "S\u1ed1 33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14757", name: "S\u1ed1 34A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14758", name: "S\u1ed1 38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14759", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14760", name: "S\u1ed1 40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14761", name: "S\u1ed1 4A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14762", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14763", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14764", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14765", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14766", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14767", name: "S\u1ed1 D33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14768", name: "S\u1ed1 D5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14769",
            name: "S\u00f3c B\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14770",
            name: "Song H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14771",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14772",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14773",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14774",
            name: "T\u00e2n Ph\u01b0\u1edbc Kh\u00e1nh 43",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14775",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14776",
            name: "Th\u00e1i H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14777",
            name: "Th\u1ea1nh B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14778",
            name: "Th\u1ea1nh L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14779",
            name: "Th\u1ea1nh L\u1ed9c 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14780",
            name: "Th\u1ea1nh L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14781",
            name: "Th\u1ea1nh Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14782",
            name: "Th\u1ea1nh Qu\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14783",
            name: "Th\u1ea1nh Xu\u00e2n 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14784",
            name: "Th\u1ea1nh Xu\u00e2n 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14785",
            name: "Th\u00edch B\u1eedu \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14786",
            name: "Th\u1ee7 Khoa Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14787",
            name: "Thu\u1eadn An H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14788",
            name: "Thu\u1eadn Giao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14789",
            name: "Thu\u1eadn Giao 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14790",
            name: "Thu\u1eadn Giao 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14791",
            name: "Thu\u1eadn Giao 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14792",
            name: "Thu\u1eadn Giao 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14793",
            name: "Thu\u1eadn Giao 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14794",
            name: "Thu\u1eadn Giao 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14795",
            name: "Thu\u1eadn Giao 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14796",
            name: "Thu\u1eadn Giao 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14797",
            name: "Thu\u1eadn Giao 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14798",
            name: "Thu\u1eadn Giao 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14799",
            name: "Thu\u1eadn Giao 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14800",
            name: "Thu\u1eadn Giao 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14801",
            name: "Thu\u1eadn Giao 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14802",
            name: "Thu\u1eadn Giao 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14803",
            name: "Thu\u1eadn Giao 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14804",
            name: "Thu\u1eadn Giao 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14805",
            name: "Thu\u1eadn Giao 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14806",
            name: "Thu\u1eadn Giao 31",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14807",
            name: "Thu\u1eadn Giao 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14808",
            name: "Thu\u1eadn Giao 33",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14809",
            name: "Thu\u1eadn Giao 43",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14810",
            name: "Thu\u1eadn Giao 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14811",
            name: "Thu\u1eadn Giao 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14812",
            name: "Thu\u1eadn Giao 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14813",
            name: "Thu\u1eadn Giao 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14814",
            name: "Thu\u1eadn Giao 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14815",
            name: "T\u1ec9nh l\u1ed9 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14816",
            name: "T\u1ec9nh l\u1ed9 43",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14817",
            name: "T\u1ec9nh l\u1ed9 743",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14818",
            name: "T\u1ec9nh l\u1ed9 745",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14819",
            name: "T\u1ec9nh l\u1ed9 7A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14820",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14822",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14821",
            name: "Tr\u1ea7n Quang \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14823",
            name: "Tr\u1ea7n Qu\u1ed1c Tu\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14824",
            name: "Tr\u00edch S\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14825",
            name: "Tr\u1ecbnh Ho\u00e0i \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14826",
            name: "Tr\u01b0\u1eddng S\u01a1n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14827",
            name: "Tr\u01b0\u01a1ng V\u0103n \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14828", name: "T\u1ef1 Do", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14829",
            name: "T\u1eeb V\u0103n Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14830",
            name: "V\u00e0nh \u0110ai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14831",
            name: "V\u00e0nh \u0110ai 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14832",
            name: "V\u00e0nh \u0110ai 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14833",
            name: "Vi\u1ec7t H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14834",
            name: "Viet Huong IP",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14835",
            name: "V\u0129nh Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14836",
            name: "V\u0129nh Ph\u00fa 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14837",
            name: "V\u0129nh Ph\u00fa 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14838",
            name: "V\u0129nh Ph\u00fa 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14839",
            name: "V\u0129nh Ph\u00fa 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14840",
            name: "V\u0129nh Ph\u00fa 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14841",
            name: "V\u0129nh Ph\u00fa 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14842",
            name: "V\u0129nh Ph\u00fa 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14843",
            name: "V\u0129nh Ph\u00fa 17A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14844",
            name: "V\u0129nh Ph\u00fa 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14845",
            name: "V\u0129nh Ph\u00fa 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14846",
            name: "V\u0129nh Ph\u00fa 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14847",
            name: "V\u0129nh Ph\u00fa 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14848",
            name: "V\u0129nh Ph\u00fa 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14849",
            name: "V\u0129nh Ph\u00fa 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14850",
            name: "V\u0129nh Ph\u00fa 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14851",
            name: "V\u0129nh Ph\u00fa 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14852",
            name: "V\u0129nh Ph\u00fa 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14853",
            name: "V\u0129nh Ph\u00fa 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14854",
            name: "V\u0129nh Ph\u00fa 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14855",
            name: "V\u0129nh Ph\u00fa 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14856",
            name: "V\u0129nh Ph\u00fa 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14857",
            name: "V\u0129nh Ph\u00fa 33",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14858",
            name: "V\u0129nh Ph\u00fa 34",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14859",
            name: "V\u0129nh Ph\u00fa 35",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14860",
            name: "V\u0129nh Ph\u00fa 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14861",
            name: "V\u0129nh Ph\u00fa 38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14862",
            name: "V\u0129nh Ph\u00fa 38A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14863",
            name: "V\u0129nh Ph\u00fa 39",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14864",
            name: "V\u0129nh Ph\u00fa 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14865",
            name: "Vinh Ph\u00fa 40",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14866",
            name: "V\u0129nh Ph\u00fa 41",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14867",
            name: "V\u0129nh Ph\u00fa 42",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14868",
            name: "V\u0129nh Ph\u00fa 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14869",
            name: "V\u0129nh Ph\u00fa 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14870",
            name: "V\u0129nh Ph\u00fa 66",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14871",
            name: "V\u0129nh Ph\u00fa 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14872",
            name: "V\u0129nh Ph\u00fa 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14873",
            name: "V\u0129nh Ph\u00fa 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14874",
            name: "V\u00f5 V\u0103n Ng\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14875", name: "X1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14876", name: "X3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14877", name: "X5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14878", name: "Xa La", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "2235",
            name: "An S\u01a1n Riverside",
            lat: "10.926562309265",
            lng: "106.66207122803",
          },
          {
            id: "2236",
            name: "An Th\u1ea1nh",
            lat: "10.954232215881",
            lng: "106.69259643555",
          },
          {
            id: "2237",
            name: "Bi\u1ec7t th\u1ef1 sinh th\u00e1i B\u00ecnh \u0110\u1ee9c Ti\u1ebfn",
            lat: "10.911832809448",
            lng: "106.69557189941",
          },
          {
            id: "2238",
            name: "B\u00ecnh Chu\u1ea9n",
            lat: "10.982069015503",
            lng: "106.72341156006",
          },
          {
            id: "2239",
            name: "Central Garden B\u00ecnh D\u01b0\u01a1ng",
            lat: "10.904032707214",
            lng: "106.69945526123",
          },
          {
            id: "2240",
            name: "Citadine B\u00ecnh D\u01b0\u01a1ng",
            lat: "10.933817863464",
            lng: "106.7099685669",
          },
          {
            id: "2241",
            name: "Contentment Plaza",
            lat: "10.870255470276",
            lng: "106.71208953857",
          },
          {
            id: "2242",
            name: "\u0110\u1ea1i Ng\u00e0n",
            lat: "10.94607925415",
            lng: "106.71956634522",
          },
          {
            id: "2243",
            name: "Eco Xu\u00e2n L\u00e1i Thi\u00eau",
            lat: "10.909506797791",
            lng: "106.71279144287",
          },
          {
            id: "2244",
            name: "EHome 4 - B\u1eafc S\u00e0i G\u00f2n",
            lat: "10.88461971283",
            lng: "106.70433807373",
          },
          {
            id: "2245",
            name: "First Home Premium",
            lat: "10.949599266052",
            lng: "106.70578765869",
          },
          {
            id: "2246",
            name: "KDC An Ph\u00fa H\u01b0ng",
            lat: "10.961121559143",
            lng: "106.73439788818",
          },
          {
            id: "2247",
            name: "KDC Qu\u1ed1c V\u01b0\u01a1ng",
            lat: "10.960496902466",
            lng: "106.74927520752",
          },
          {
            id: "2248",
            name: "KDC Thu\u1eadn Giao",
            lat: "10.964273452759",
            lng: "106.71302032471",
          },
          {
            id: "2249",
            name: "Khu \u0111\u00f4 th\u1ecb B\u00ecnh Ho\u00e0",
            lat: "10.911631584168",
            lng: "106.71356201172",
          },
          {
            id: "2250",
            name: "Marina Tower",
            lat: "10.877593994141",
            lng: "106.6997756958",
          },
          {
            id: "2251",
            name: "Newtown Center Land",
            lat: "10.911631584168",
            lng: "106.71356201172",
          },
          {
            id: "2252",
            name: "Oscar Riverside",
            lat: "10.899412155151",
            lng: "106.69631195068",
          },
          {
            id: "2253",
            name: "Roxana Plaza",
            lat: "10.877593994141",
            lng: "106.6997756958",
          },
          {
            id: "2254",
            name: "Terracotta Hill",
            lat: "10.904865264893",
            lng: "106.69995880127",
          },
          {
            id: "2255",
            name: "The Canary",
            lat: "10.932640075684",
            lng: "106.71234130859",
          },
          {
            id: "2256",
            name: "The Habitat B\u00ecnh D\u01b0\u01a1ng",
            lat: "10.924940109253",
            lng: "106.7145690918",
          },
          {
            id: "2257",
            name: "The Oasis",
            lat: "10.943667411804",
            lng: "106.72931671143",
          },
          {
            id: "2258",
            name: "The Seasons L\u00e1i Thi\u00eau",
            lat: "10.904865264893",
            lng: "106.69995880127",
          },
          {
            id: "2259",
            name: "V\u0129nh Ph\u00fa I",
            lat: "10.877020835877",
            lng: "106.71113586426",
          },
          {
            id: "2260",
            name: "V\u0129nh Ph\u00fa II",
            lat: "10.886666297913",
            lng: "106.70969390869",
          },
          {
            id: "2261",
            name: "VSIP 1",
            lat: "10.931837081909",
            lng: "106.72547149658",
          },
        ],
      },
    ],
  },
  {
    id: "5",
    code: "DNA",
    name: "\u0110\u1ed3ng Nai",
    districts: [
      {
        id: "71",
        name: "Bi\u00ean H\u00f2a",
        wards: [
          { id: "1064", name: "An B\u00ecnh", prefix: "Ph\u01b0\u1eddng" },
          { id: "1065", name: "An H\u00f2a", prefix: "X\u00e3" },
          { id: "1066", name: "B\u00e0u Hang", prefix: "Ph\u01b0\u1eddng" },
          { id: "1067", name: "B\u00ecnh \u0110a", prefix: "Ph\u01b0\u1eddng" },
          { id: "1068", name: "B\u1eedu H\u00f2a", prefix: "Ph\u01b0\u1eddng" },
          { id: "1069", name: "B\u1eedu Long", prefix: "Ph\u01b0\u1eddng" },
          { id: "1070", name: "Hi\u1ec7p H\u00f2a", prefix: "X\u00e3" },
          { id: "1071", name: "H\u1ed1 C\u1ea1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "1072", name: "H\u1ed1 Nai", prefix: "Ph\u01b0\u1eddng" },
          { id: "1073", name: "H\u00f3a An", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1074",
            name: "H\u00f2a B\u00ecnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1075", name: "Long B\u00ecnh", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1076",
            name: "Long B\u00ecnh T\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1077", name: "Long H\u01b0ng", prefix: "X\u00e3" },
          { id: "1078", name: "Ph\u01b0\u1edbc T\u00e2n", prefix: "X\u00e3" },
          { id: "1079", name: "Quang Vinh", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1080",
            name: "Quy\u1ebft Th\u1eafng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1081", name: "Tam Hi\u1ec7p", prefix: "Ph\u01b0\u1eddng" },
          { id: "1082", name: "Tam H\u00f2a", prefix: "Ph\u01b0\u1eddng" },
          { id: "1083", name: "Tam Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          {
            id: "1084",
            name: "T\u00e2n Bi\u00ean",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1085", name: "T\u00e2n H\u1ea1nh", prefix: "X\u00e3" },
          {
            id: "1086",
            name: "T\u00e2n Hi\u1ec7p",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1087", name: "T\u00e2n H\u00f2a", prefix: "Ph\u01b0\u1eddng" },
          { id: "1088", name: "T\u00e2n Long", prefix: "Ph\u01b0\u1eddng" },
          { id: "1089", name: "T\u00e2n Mai", prefix: "Ph\u01b0\u1eddng" },
          { id: "1090", name: "T\u00e2n Phong", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1091",
            name: "T\u00e2n Ti\u1ebfn",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1092", name: "T\u00e2n V\u1ea1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "1093", name: "Thanh B\u00ecnh", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1094",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1095",
            name: "Tr\u1ea3ng D\u00e0i",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1096", name: "Trung D\u0169ng", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "14879", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14880", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14881", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14882", name: "16A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14883", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14884", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14885", name: "2A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14886", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14887", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14888", name: "313", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14889", name: "3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14890", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14891", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14892", name: "51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14893", name: "518", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14894", name: "5B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14895", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14896", name: "768", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14897", name: "768B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14898", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14899", name: "A4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14900",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14901", name: "B4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14902", name: "B5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14903", name: "B752", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14904",
            name: "B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14905",
            name: "B\u1eafc S\u01a1n - Long Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14906",
            name: "B\u00ecnh D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14907",
            name: "B\u00f9i H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14908",
            name: "B\u00f9i Tr\u1ecdng Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14909",
            name: "B\u00f9i V\u0103n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14910",
            name: "B\u00f9i V\u0103n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14911",
            name: "B\u1eedu Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14912", name: "C1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14913",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14914",
            name: "C\u1ea7u V\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14915",
            name: "C\u00e2y Sung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14916",
            name: "Ch\u00e2u V\u0103n L\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14917",
            name: "Chu M\u1ea1nh Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14918",
            name: "Chuy\u00ean D\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14919",
            name: "C\u00f4 Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14920", name: "C\u00f9 Lao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14921",
            name: "C\u00f9 Lao Ph\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14922", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14923", name: "D11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14924", name: "D12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14925", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14926", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14927", name: "D4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14928", name: "D5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14929", name: "D55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14930", name: "D6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14931", name: "D7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14932", name: "D8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14933", name: "D9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14934",
            name: "D\u00e3 T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14947",
            name: "D\u01b0\u01a1ng B\u1ea1ch Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14948",
            name: "D\u01b0\u01a1ng Di\u00ean Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14949",
            name: "D\u01b0\u01a1ng \u0110\u00ecnh Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14950",
            name: "D\u01b0\u01a1ng T\u1eed Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14935",
            name: "\u0110\u1eb7ng \u0110\u1ea1i \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14936",
            name: "\u0110\u1eb7ng \u0110\u1ee9c Thu\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14937",
            name: "\u0110\u1eb7ng Nguy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14938",
            name: "\u0110\u1eb7ng V\u0103n Tr\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14939",
            name: "\u0110i\u1ec3u Xi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14940",
            name: "\u0110inh Quang \u00c2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14941",
            name: "\u0110inh Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14942",
            name: "\u0110\u1ed7 V\u0103n Thi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14943",
            name: "\u0110o\u00e0n V\u0103n C\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14944",
            name: "\u0110\u1ed3ng Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14945", name: "\u0110T 768", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14946", name: "\u0110T 768B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "14951", name: "H6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14952",
            name: "H\u00e0 Huy Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14953", name: "H\u00e0 Nam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14954",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14955",
            name: "H\u00e0n Thuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14956",
            name: "H\u1ed3 H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14957",
            name: "H\u1ed3 V\u0103n \u0110\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14958",
            name: "H\u1ed3 V\u0103n Hu\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14959",
            name: "H\u1ed3 V\u0103n Leo",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14960",
            name: "H\u1ed3 V\u0103n Th\u1ec3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14961",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14962",
            name: "Hoa H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14963", name: "Hoa Mai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14964",
            name: "Ho\u00e0ng B\u00e1 B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14965",
            name: "Ho\u00e0ng \u0110\u00ecnh C\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14966",
            name: "Ho\u00e0ng Minh Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14967",
            name: "Ho\u00e0ng Minh Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14968",
            name: "Ho\u00e0ng Tam K\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14969",
            name: "Ho\u00e0ng V\u0103n B\u1ed5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14970",
            name: "H\u01b0ng \u0110\u1ea1o V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14971",
            name: "H\u01b0\u01a1ng l\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14972",
            name: "H\u01b0\u01a1ng L\u1ed9 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14973",
            name: "H\u01b0\u01a1ng L\u1ed9 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14974",
            name: "Hu\u1ef3nh D\u00e2n Sanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14975",
            name: "Hu\u1ef3nh M\u1eabn \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14976",
            name: "Hu\u1ef3nh T\u1ea5n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14977",
            name: "Hu\u1ef3nh V\u0103n H\u1edbn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14978",
            name: "Hu\u1ef3nh V\u0103n L\u0169y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14979",
            name: "Hu\u1ef3nh V\u0103n Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14980",
            name: "Kim B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14981",
            name: "L\u00e0ng B\u01b0\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14982", name: "L\u00ea A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14983",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14984",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14985",
            name: "L\u00ea Nguy\u00ean \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14986",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14987",
            name: "L\u00ea Th\u1ecb V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14988", name: "L\u00ea Thoa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14989",
            name: "L\u00ea V\u0103n Duy\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14990",
            name: "Li\u00ean Ph\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14991",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "14992", name: "L\u00f2 Chao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "14993",
            name: "L\u1eef M\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14994",
            name: "L\u01b0\u01a1ng V\u0103n Nho",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14995",
            name: "L\u01b0u V\u0103n Vi\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14996",
            name: "L\u00fd Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14997",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "14998",
            name: "L\u00fd V\u0103n S\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "14999",
            name: "L\u00fd V\u0103n T\u1ecf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15000",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15001", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15002", name: "N10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15003", name: "N12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15004", name: "N14", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15005",
            name: "N16 B\u1eafc C\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15006", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15007", name: "N3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15008", name: "N4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15009", name: "N5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15010", name: "N6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15011", name: "N7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15012", name: "N8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15013", name: "N9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15014", name: "Nam Cao", prefix: "Ph\u1ed1" },
          {
            id: "15015",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15016",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15017",
            name: "Nguy\u1ec5n \u00c1i Qu\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15018",
            name: "Nguy\u1ec5n B\u00e1 H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15019",
            name: "Nguy\u1ec5n B\u1ea3o \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15020",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15021",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15022",
            name: "Nguy\u1ec5n Duy Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15023",
            name: "Nguy\u1ec5n Hi\u1ec1n V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15024",
            name: "Nguy\u1ec5n Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15025",
            name: "Nguy\u1ec5n H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15026",
            name: "Nguy\u1ec5n Kh\u1eafc Hi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15027",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15028",
            name: "Nguy\u1ec5n Ph\u00fac Chu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15029", name: "Nguy\u1ec5n Si\u00eau", prefix: "Ph\u1ed1" },
          {
            id: "15030",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15031",
            name: "Nguy\u1ec5n Th\u00e0nh \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15032",
            name: "Nguy\u1ec5n Th\u00e0nh Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15033",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15034",
            name: "Nguy\u1ec5n Th\u1ecb Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15035",
            name: "Nguy\u1ec5n Th\u1ecb Hoa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15036",
            name: "Nguy\u1ec5n Th\u1ecb T\u1ed3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15037",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "15038",
            name: "Nguy\u1ec5n Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15039",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15040",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15041",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15042",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15043",
            name: "Nguy\u1ec5n V\u0103n A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15044",
            name: "Nguy\u1ec5n V\u0103n Ch\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15045",
            name: "Nguy\u1ec5n V\u0103n Hoa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15046",
            name: "Nguy\u1ec5n V\u0103n Ho\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15047",
            name: "Nguy\u1ec5n V\u0103n K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15048",
            name: "Nguy\u1ec5n V\u0103n Lung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15049",
            name: "Nguy\u1ec5n V\u0103n Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15050",
            name: "Nguy\u1ec5n V\u0103n Ti\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15051",
            name: "Nguy\u1ec5n V\u0103n T\u1ed1",
            prefix: "Ph\u1ed1",
          },
          {
            id: "15052",
            name: "Nguy\u1ec5n V\u0103n T\u1ecf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15053",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15054",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15055",
            name: "Ph\u1ea1m Th\u1ecb Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15056",
            name: "Ph\u1ea1m V\u0103n Di\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15057",
            name: "Ph\u1ea1m V\u0103n Khoai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15058",
            name: "Ph\u1ea1m V\u0103n Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15059",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15060",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "15061",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15062", name: "Phan Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15063",
            name: "Phi Tr\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15064",
            name: "Ph\u00fa S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15065", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "15066",
            name: "Ph\u00f9ng Kh\u1eafc Khoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15067", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15068",
            name: "Qu\u1ed1c L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15069",
            name: "Qu\u1ed1c l\u1ed9 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15070",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15071",
            name: "Qu\u1ed1c l\u1ed9 1K",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15072",
            name: "Qu\u1ed1c L\u1ed9 51",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15073", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15074", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15075", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15076", name: "S\u1ed1 18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15077", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15078", name: "S\u1ed1 21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15079", name: "S\u1ed1 28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15080", name: "S\u1ed1 29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15081", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15082", name: "S\u1ed1 31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15083", name: "S\u1ed1 3B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15084", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15085", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15086", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15087", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15088", name: "S\u1ed1 9A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15089",
            name: "Song H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15090",
            name: "T\u00e2n B\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15091",
            name: "T\u00e2n Cang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15092",
            name: "T\u00e2n L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15093", name: "T\u00e2n Mai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15094",
            name: "T\u00e2n V\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15095",
            name: "Th\u00e2n Nh\u00e2n Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15096",
            name: "Th\u00e0nh Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15097",
            name: "T\u1ec9nh l\u1ed9 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15098",
            name: "T\u1ec9nh L\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15099",
            name: "T\u1ec9nh l\u1ed9 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15100",
            name: "T\u1ec9nh L\u1ed9 768",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15101",
            name: "T\u1ec9nh l\u1ed9 768B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15102",
            name: "Tr\u1ea7n Minh Tr\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15103",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15104",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15105",
            name: "Tr\u00e2\u0300n Thi\u0323 Hoa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15106",
            name: "Tr\u1ea7n V\u0103n \u01a0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15107",
            name: "Tr\u1ea7n V\u0103n X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15108",
            name: "Tr\u1ecbnh Ho\u00e0i \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15109",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15110",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15111",
            name: "Tr\u01b0\u01a1ng Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15112",
            name: "Tr\u01b0\u01a1ng V\u0103n Bang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15113",
            name: "Tr\u01b0\u01a1ng V\u0103n H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15114",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15115",
            name: "V\u00f5 T\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15116",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15117",
            name: "V\u00f5 Th\u1ecb T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15118",
            name: "V\u00f5 V\u0103n M\u00e9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15119",
            name: "V\u0169 H\u1ed3ng Ph\u00f4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15120",
            name: "V\u01b0\u1eddn Xo\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15121",
            name: "Xa L\u1ed9 H\u00e0 N\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15122",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2262",
            name: "1K INDICO",
            lat: "10.9303150177",
            lng: "106.80579376221",
          },
          {
            id: "2263",
            name: "Amber Court",
            lat: "10.950530052185",
            lng: "106.82797241211",
          },
          {
            id: "2264",
            name: "An H\u00f2a",
            lat: "10.893480300903",
            lng: "106.87321472168",
          },
          {
            id: "2265",
            name: "Bi\u00ean H\u00f2a Center Mall",
            lat: "10.933493614197",
            lng: "106.87889862061",
          },
          {
            id: "2266",
            name: "Bi\u00ean H\u00f2a City",
            lat: "10.895445823669",
            lng: "106.8946762085",
          },
          {
            id: "2267",
            name: "Bi\u00ean H\u00f2a Dragon City",
            lat: "10.860554695129",
            lng: "106.94606781006",
          },
          {
            id: "2268",
            name: "Bi\u00ean H\u00f2a Golden Town",
            lat: "10.857625961304",
            lng: "106.93368530273",
          },
          {
            id: "2269",
            name: "Bi\u00ean H\u00f2a Marina",
            lat: "10.883440971375",
            lng: "106.92555999756",
          },
          {
            id: "2270",
            name: "Bi\u00ean H\u00f2a New Town",
            lat: "10.935322761536",
            lng: "106.8000869751",
          },
          {
            id: "2271",
            name: "Bi\u00ean Ho\u00e0 Riverside",
            lat: "10.952171325684",
            lng: "106.78528594971",
          },
          {
            id: "2272",
            name: "Bi\u00ean H\u00f2a Square",
            lat: "10.950574874878",
            lng: "106.82775878906",
          },
          {
            id: "2273",
            name: "Bi\u00ean H\u00f2a Star",
            lat: "10.874729156494",
            lng: "106.91583251953",
          },
          {
            id: "2274",
            name: "Dona Green Pearl",
            lat: "10.852370262146",
            lng: "106.92401123047",
          },
          {
            id: "2275",
            name: "Dreamland City",
            lat: "10.876978874207",
            lng: "106.85750579834",
          },
          {
            id: "2276",
            name: "Elegance Town",
            lat: "10.863616943359",
            lng: "106.9241104126",
          },
          {
            id: "2277",
            name: "Golden Age Towers",
            lat: "10.900241851807",
            lng: "106.88782501221",
          },
          {
            id: "2278",
            name: "Golden Center Point",
            lat: "10.926023483276",
            lng: "106.94728088379",
          },
          {
            id: "2279",
            name: "Golden Residence",
            lat: "10.92672252655",
            lng: "106.80634307861",
          },
          {
            id: "2280",
            name: "H\u00f2a B\u00ecnh Town",
            lat: "10.960216522217",
            lng: "106.90592956543",
          },
          {
            id: "2281",
            name: "KDC An H\u00f2a 2",
            lat: "10.9321641922",
            lng: "106.85471343994",
          },
          {
            id: "2282",
            name: "KDC B\u1eedu H\u00f2a",
            lat: "10.93413066864",
            lng: "106.81085968018",
          },
          {
            id: "2283",
            name: "KDC \u0111\u01b0\u1eddng 5 n\u1ed1i d\u00e0i",
            lat: "10.9460105896",
            lng: "106.82956695557",
          },
          {
            id: "2284",
            name: "KDC Ph\u00fa Gia",
            lat: "10.988614082336",
            lng: "106.86541748047",
          },
          {
            id: "2285",
            name: "KDC Ph\u01b0\u1edbc T\u00e2n",
            lat: "10.874729156494",
            lng: "106.91583251953",
          },
          {
            id: "2286",
            name: "KDC th\u01b0\u01a1ng m\u1ea1i Ph\u01b0\u1edbc Th\u00e1i",
            lat: "10.863906860352",
            lng: "106.92353820801",
          },
          {
            id: "2287",
            name: "Ph\u00fa Th\u1ecbnh City",
            lat: "10.908442497253",
            lng: "106.85398101807",
          },
          {
            id: "2288",
            name: "Ph\u00fa Thu\u1eadn L\u1ee3i",
            lat: "10.923607826233",
            lng: "106.94709014893",
          },
          {
            id: "2289",
            name: "Ph\u00fa T\u00edn",
            lat: "10.859975814819",
            lng: "106.94240570068",
          },
          {
            id: "2290",
            name: "Quang Vinh",
            lat: "10.956379890442",
            lng: "106.81196594238",
          },
          {
            id: "2291",
            name: "T\u00e2n Bi\u00ean 1",
            lat: "10.966682434082",
            lng: "106.88917541504",
          },
          {
            id: "2292",
            name: "T\u00e2n Bi\u00ean 2",
            lat: "10.960022926331",
            lng: "106.90091705322",
          },
          {
            id: "2293",
            name: "Thanh B\u00ecnh Plaza",
            lat: "10.948246002197",
            lng: "106.81517791748",
          },
          {
            id: "2294",
            name: "The Pegasus Plaza",
            lat: "10.947186470032",
            lng: "106.82540130615",
          },
          {
            id: "2295",
            name: "The Pegasus Residence",
            lat: "10.887351989746",
            lng: "106.85705566406",
          },
          {
            id: "2296",
            name: "Topaz Twins",
            lat: "10.948684692383",
            lng: "106.83544921875",
          },
          {
            id: "2297",
            name: "V-CitiLight Bi\u00ean H\u00f2a",
            lat: "10.891657829285",
            lng: "106.82281494141",
          },
          {
            id: "2298",
            name: "Vincom Bi\u00ean H\u00f2a",
            lat: "10.953979492188",
            lng: "106.85260009766",
          },
        ],
      },
      {
        id: "72",
        name: "C\u1ea9m M\u1ef9",
        wards: [
          { id: "1097", name: "B\u1ea3o B\u00ecnh", prefix: "X\u00e3" },
          { id: "1098", name: "L\u00e2m San", prefix: "X\u00e3" },
          { id: "1099", name: "Long Giao", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "1100", name: "Nh\u00e2n Ngh\u0129a", prefix: "X\u00e3" },
          { id: "1101", name: "S\u00f4ng Nh\u1ea1n", prefix: "X\u00e3" },
          { id: "1102", name: "S\u00f4ng Ray", prefix: "X\u00e3" },
          { id: "1103", name: "Th\u1eeba \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "1104", name: "Xu\u00e2n B\u1ea3o", prefix: "X\u00e3" },
          { id: "1105", name: "Xu\u00e2n \u0110\u00f4ng", prefix: "X\u00e3" },
          {
            id: "1106",
            name: "Xu\u00e2n \u0110\u01b0\u1eddng",
            prefix: "X\u00e3",
          },
          { id: "1107", name: "Xu\u00e2n M\u1ef9", prefix: "X\u00e3" },
          { id: "1108", name: "Xu\u00e2n Qu\u1ebf", prefix: "X\u00e3" },
          { id: "1109", name: "Xu\u00e2n T\u00e2y", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "15123", name: "25B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15124", name: "56", prefix: "Ph\u1ed1" },
          { id: "15125", name: "765", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15126",
            name: "H\u01b0\u01a1ng l\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15127",
            name: "Qu\u1ed1c L\u1ed9 51",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15128",
            name: "Qu\u1ed1c l\u1ed9 56",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15129",
            name: "T\u1ec9nh L\u1ed9 764",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15130",
            name: "T\u1ec9nh L\u1ed9 765",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "73",
        name: "\u0110\u1ecbnh Qu\u00e1n",
        wards: [
          {
            id: "1110",
            name: "\u0110\u1ecbnh Qu\u00e1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "1111", name: "Gia Canh", prefix: "X\u00e3" },
          { id: "1112", name: "La Ng\u00e0", prefix: "X\u00e3" },
          { id: "1113", name: "Ng\u1ecdc \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "1114", name: "Ph\u00fa C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "1115", name: "Ph\u00fa Ho\u00e0", prefix: "X\u00e3" },
          { id: "1116", name: "Ph\u00fa L\u1ee3i", prefix: "X\u00e3" },
          { id: "1117", name: "Ph\u00fa Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "1118", name: "Ph\u00fa T\u00e2n", prefix: "X\u00e3" },
          { id: "1119", name: "Ph\u00fa T\u00fac", prefix: "X\u00e3" },
          { id: "1120", name: "Ph\u00fa Vinh", prefix: "X\u00e3" },
          { id: "1121", name: "Su\u1ed1i Nho", prefix: "X\u00e3" },
          { id: "1122", name: "Thanh S\u01a1n", prefix: "X\u00e3" },
          { id: "1123", name: "T\u00fac Tr\u01b0ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "15131", name: "101", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15132", name: "115", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15133", name: "\u0110T 763", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15134",
            name: "H\u01b0\u01a1ng l\u1ed9 105",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15135",
            name: "Qu\u1ed1c L\u1ed9 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "74",
        name: "Long Kh\u00e1nh",
        wards: [
          { id: "1124", name: "B\u1ea3o Ho\u00e0", prefix: "X\u00e3" },
          { id: "1125", name: "B\u1ea3o Quang", prefix: "X\u00e3" },
          { id: "1126", name: "B\u1ea3o Vinh", prefix: "X\u00e3" },
          { id: "1127", name: "B\u00e0u Sen", prefix: "X\u00e3" },
          { id: "1128", name: "B\u00e0u Tr\u00e2m", prefix: "X\u00e3" },
          { id: "1129", name: "B\u00ecnh L\u1ed9c", prefix: "X\u00e3" },
          { id: "1130", name: "H\u00e0ng G\u00f2n", prefix: "X\u00e3" },
          {
            id: "1131",
            name: "Ph\u00fa B\u00ecnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1132", name: "Su\u1ed1i Tre", prefix: "X\u00e3" },
          { id: "1133", name: "Xu\u00e2n An", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1134",
            name: "Xu\u00e2n B\u00ecnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1135",
            name: "Xu\u00e2n H\u00f2a",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1136", name: "Xu\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "1137", name: "Xu\u00e2n T\u00e2n", prefix: "X\u00e3" },
          { id: "1138", name: "Xu\u00e2n Thanh", prefix: "Ph\u01b0\u1eddng" },
          { id: "1139", name: "Xu\u00e2n Trung", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "15136", name: "21/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15137", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15138", name: "9/4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15139",
            name: "Anh H\u00f9ng Li\u1ec7t S\u1ef9 L\u00ea A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15140", name: "Ba S\u1eady", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15141",
            name: "B\u1ea7u Tr\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15142",
            name: "B\u00ecnh L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15143",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15144",
            name: "C\u1ea9m T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15145",
            name: "Ch\u00e2u V\u0103n Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15146",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15150", name: "Duy T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15147",
            name: "\u0110\u00e0o Tr\u00ed Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15148",
            name: "\u0110i\u1ec3u Xi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15149",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15151",
            name: "Gi\u1ea3i Ph\u00f3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15152",
            name: "H\u00e0 Huy Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15153", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "15154",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15155",
            name: "H\u1ed3 Th\u1ecb H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15156",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15157",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15158",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15159",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15160",
            name: "H\u1ed3ng Th\u1eadp T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15161",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15162",
            name: "Hu\u1ef3nh V\u0103n Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15163",
            name: "Kh\u1ed5ng T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15164",
            name: "K\u00fd Th\u1eeba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15165",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15166",
            name: "L\u00ea H\u1eefu Tr\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15167",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15168",
            name: "L\u00ea Nam Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15169",
            name: "L\u00ea Quang \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15170",
            name: "L\u00ea V\u0103n V\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15171",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15172",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15173",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "15174",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15175",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15176", name: "N3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15177",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15178",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15179",
            name: "Nguy\u1ec5n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15180",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15182",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15183",
            name: "Nguy\u1ec5n Duy D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15181",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15184",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15185",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15186",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15187",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15188",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15189",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15190",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15191",
            name: "Nguy\u1ec5n V\u0103n Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15192",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15193",
            name: "Nguy\u1ec5n V\u0103n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15194",
            name: "Nguy\u1ec5n V\u0103n L\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15195",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15196",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15197",
            name: "Ph\u1ea1m L\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15198",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15199",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15200",
            name: "Ph\u1ea1m Th\u00e1i B\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15201",
            name: "Ph\u1ea1m Th\u1ebf Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15202",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15203",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15204",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "15205",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15206",
            name: "Phan Huy Ch\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15207",
            name: "Phan V\u0103n \u0110\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15208", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15209",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15210",
            name: "Qu\u1ed1c l\u1ed9 56",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15211", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15212", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15213", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15214",
            name: "Su\u1ed1i Ch\u1ed3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15215",
            name: "T\u00e2n Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15216",
            name: "Th\u00e0nh Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15217",
            name: "Th\u00edch Qu\u1ea3ng \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15218",
            name: "T\u1ec9nh l\u1ed9 769",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15219",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15220",
            name: "Tr\u1ea7n \u0110\u1ea1i \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15221",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15222",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15223",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15224",
            name: "Tr\u1ea7n Th\u01b0\u1ee3ng Xuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15225",
            name: "Tr\u1ea7n V\u0103n Thi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15226",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15227",
            name: "Tr\u01b0\u01a1ng V\u0129nh K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15228",
            name: "V\u00f5 Duy D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15229",
            name: "V\u00f5 V\u0103n T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15230",
            name: "Xu\u00e2n L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "75",
        name: "Long Th\u00e0nh",
        wards: [
          { id: "1140", name: "An Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "1141", name: "B\u00e0u C\u1ea1n", prefix: "X\u00e3" },
          { id: "1142", name: "B\u00ecnh An", prefix: "X\u00e3" },
          { id: "1143", name: "B\u00ecnh S\u01a1n", prefix: "X\u00e3" },
          {
            id: "1144",
            name: "C\u1ea9m \u0110\u01b0\u1eddng",
            prefix: "X\u00e3",
          },
          { id: "1145", name: "L\u1ed9c An", prefix: "X\u00e3" },
          { id: "1146", name: "Long An", prefix: "X\u00e3" },
          { id: "1147", name: "Long \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "1148", name: "Long Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "1149", name: "Long Th\u00e0nh", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "1150", name: "Ph\u01b0\u1edbc B\u00ecnh", prefix: "X\u00e3" },
          { id: "1151", name: "Ph\u01b0\u1edbc T\u00e2n", prefix: "X\u00e3" },
          { id: "1152", name: "Ph\u01b0\u1edbc Th\u00e1i", prefix: "X\u00e3" },
          { id: "1153", name: "Su\u1ed1i Tr\u1ea7u", prefix: "X\u00e3" },
          { id: "1154", name: "Tam An", prefix: "X\u00e3" },
          { id: "1155", name: "T\u00e2n Hi\u1ec7p", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "15231", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15232", name: "240", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15233", name: "25B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15234", name: "25C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15235", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15236", name: "51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15237", name: "51A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15238", name: "51B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15239", name: "67", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15240", name: "679", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15241", name: "68", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15242", name: "769", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15243", name: "A1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15244",
            name: "An Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15245", name: "\u1ea4p 7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15246",
            name: "B\u00e0 K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15247",
            name: "B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15248",
            name: "B\u1eafc S\u01a1n - Long Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15249",
            name: "B\u00e0u C\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15250",
            name: "B\u00e0u C\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15251",
            name: "B\u1ea7u C\u0103n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15252",
            name: "B\u00ecnh S\u01a1n - Long B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15253",
            name: "B\u00ecnh S\u01a1n - Long Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15254",
            name: "B\u01b0ng C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15255",
            name: "B\u01b0ng M\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15256",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15257", name: "D25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15258", name: "D6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15259",
            name: "\u0110inh B\u1ed9 L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15260",
            name: "\u0110inh Quang \u00c2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15261",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15262", name: "\u0110T 769", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15263",
            name: "Gi\u1ea3i Ph\u00f3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15264",
            name: "G\u00f2 \u0110\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15265",
            name: "H\u00e0 Huy Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15266", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "15267",
            name: "Hi\u1ec1n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15268",
            name: "Ho\u00e0ng Minh Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15269",
            name: "Ho\u00e0ng Tam K\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15270",
            name: "H\u1ed3ng H\u00e0 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15271",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15272",
            name: "H\u01b0\u01a1ng l\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15273",
            name: "H\u01b0\u01a1ng L\u1ed9 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15274",
            name: "H\u01b0\u01a1ng l\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15275",
            name: "H\u01b0\u01a1ng L\u1ed9 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15276",
            name: "H\u01b0\u01a1ng L\u1ed9 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15278",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15277",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15279",
            name: "L\u00ea Quang \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15280",
            name: "Li\u00ean \u1ea5p 4-5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15281",
            name: "Li\u00ean C\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15282",
            name: "Li\u00ean Ph\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15283",
            name: "Li\u00ean Th\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15284",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15285",
            name: "L\u00f4i H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15286",
            name: "Long Th\u00e0nh - D\u1ea7u Gi\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15287",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15288",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15289",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "15290",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15291", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15292", name: "N6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15293",
            name: "Ng\u00f4 H\u00e0 Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15294",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15295",
            name: "Ng\u00f4 T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15296",
            name: "Nguy\u1ec5n \u00c1i Qu\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15297",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15298",
            name: "Nguy\u1ec5n H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15299",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15300",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15301",
            name: "Nguy\u1ec5n V\u0103n K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15302", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "15303",
            name: "Ph\u01b0\u1edbc B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15304",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15305",
            name: "Qu\u1ed1c L\u1ed9 51",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15306",
            name: "Qu\u1ed1c L\u1ed9 51A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15307",
            name: "Qu\u1ed1c l\u1ed9 51B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15308", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15309", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15310", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15311", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15312",
            name: "Song H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15313",
            name: "Su\u1ed1i Ph\u00e8n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15314",
            name: "T\u00e2n Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15315",
            name: "T\u1ec9nh L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15316",
            name: "T\u1ec9nh l\u1ed9 25B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15317",
            name: "T\u1ec9nh l\u1ed9 25C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15318",
            name: "T\u1ec9nh l\u1ed9 43",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15319",
            name: "T\u1ec9nh l\u1ed9 769",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15320",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "15321",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15322",
            name: "Tr\u1ea7n V\u0103n \u01a0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15323",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15324",
            name: "Tr\u01b0\u1eddng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15325",
            name: "Tr\u01b0\u1eddng S\u01a1n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15326",
            name: "V\u0103n H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15327",
            name: "V\u00e0nh \u0110ai 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15328",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15329",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15330",
            name: "V\u00f5 V\u0103n T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2299",
            name: "Airlink City",
            lat: "10.745066642761",
            lng: "106.97352600098",
          },
          {
            id: "2300",
            name: "Airlink City 3",
            lat: "10.781520843506",
            lng: "106.97439575195",
          },
          {
            id: "2301",
            name: "Airlink Residence",
            lat: "10.695094108582",
            lng: "107.01728820801",
          },
          {
            id: "2302",
            name: "Airlink Town",
            lat: "10.744335174561",
            lng: "106.9012298584",
          },
          {
            id: "2303",
            name: "Aquamarine Town",
            lat: "10.81115436554",
            lng: "106.98899078369",
          },
          {
            id: "2304",
            name: "B\u00ecnh S\u01a1n Riverside",
            lat: "10.802720069885",
            lng: "107.0421295166",
          },
          {
            id: "2305",
            name: "Blue Topaz",
            lat: "10.759828567505",
            lng: "106.98217010498",
          },
          {
            id: "2306",
            name: "C\u00f4ng vi\u00ean V\u0129nh H\u1eb1ng Long Th\u00e0nh",
            lat: "10.843803405762",
            lng: "106.98899078369",
          },
          {
            id: "2307",
            name: "Dragon Land",
            lat: "10.843803405762",
            lng: "106.98899078369",
          },
          {
            id: "2308",
            name: "Eco Town Long Th\u00e0nh",
            lat: "10.795548439026",
            lng: "106.94499969482",
          },
          {
            id: "2309",
            name: "Era City",
            lat: "10.796140670776",
            lng: "106.9898223877",
          },
          {
            id: "2310",
            name: "KDC Ph\u1ed1 Ch\u1ee3 Ph\u01b0\u1edbc Th\u00e1i",
            lat: "10.683708190918",
            lng: "107.02099609375",
          },
          {
            id: "2311",
            name: "KDC s\u00e2n bay Long Th\u00e0nh",
            lat: "10.835578918457",
            lng: "106.94176483154",
          },
          {
            id: "2312",
            name: "K\u0110T Nam Ph\u00e1t",
            lat: "10.744905471802",
            lng: "106.97383117676",
          },
          {
            id: "2313",
            name: "Khu d\u00e2n c\u01b0 H\u1ed3 L\u1ed9c An",
            lat: "10.940058708191",
            lng: "106.94934082031",
          },
          {
            id: "2314",
            name: "L\u1ed9c An",
            lat: "10.78214931488",
            lng: "106.95903778076",
          },
          {
            id: "2315",
            name: "Long \u0110\u1ee9c Richtown",
            lat: "10.813465118408",
            lng: "106.95210266113",
          },
          {
            id: "2316",
            name: "Long Th\u00e0nh - Happy Town",
            lat: "10.756766319275",
            lng: "106.98899078369",
          },
          {
            id: "2317",
            name: "Long Th\u00e0nh Center",
            lat: "10.784893035889",
            lng: "106.94970703125",
          },
          {
            id: "2318",
            name: "Long Th\u00e0nh Gateway",
            lat: "10.752526283264",
            lng: "106.97109985352",
          },
          {
            id: "2319",
            name: "Long Th\u00e0nh Pearl",
            lat: "10.791573524475",
            lng: "107.02291107178",
          },
          {
            id: "2320",
            name: "Long Th\u00e0nh Plaza",
            lat: "10.789709091187",
            lng: "106.94766235352",
          },
          {
            id: "2321",
            name: "Prosper Long Ph\u01b0\u1edbc",
            lat: "10.724585533142",
            lng: "106.9720916748",
          },
          {
            id: "2322",
            name: "Ruby Town",
            lat: "10.749203681946",
            lng: "106.98743438721",
          },
          {
            id: "2323",
            name: "Sala Garden",
            lat: "10.698452949524",
            lng: "107.0598526001",
          },
          {
            id: "2324",
            name: "Sala L\u1ed9c An Lakeview",
            lat: "10.81115436554",
            lng: "106.98899078369",
          },
          {
            id: "2325",
            name: "Sky Garden",
            lat: "10.721665382385",
            lng: "107.04640197754",
          },
          {
            id: "2326",
            name: "SunPark Residence",
            lat: "10.680058479309",
            lng: "107.02645111084",
          },
          {
            id: "2327",
            name: "Sunshine Residences",
            lat: "10.853300094604",
            lng: "106.93505859375",
          },
          {
            id: "2328",
            name: "Thung L\u0169ng Xanh",
            lat: "10.824370384216",
            lng: "106.93538665771",
          },
          {
            id: "2329",
            name: "Victoria City - An Thu\u1eadn",
            lat: "10.751986503601",
            lng: "106.96760559082",
          },
          {
            id: "2330",
            name: "Vincom Long Th\u00e0nh",
            lat: "10.722053527832",
            lng: "107.04683685303",
          },
        ],
      },
      {
        id: "76",
        name: "Nh\u01a1n Tr\u1ea1ch",
        wards: [
          {
            id: "1156",
            name: "\u0110\u1ea1i Ph\u01b0\u1edbc",
            prefix: "X\u00e3",
          },
          { id: "1157", name: "Hi\u1ec7p Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "1158", name: "Long T\u00e2n", prefix: "X\u00e3" },
          { id: "1159", name: "Long Th\u1ecd", prefix: "X\u00e3" },
          { id: "1160", name: "Ph\u00fa \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "1161", name: "Ph\u00fa H\u1ed9i", prefix: "X\u00e3" },
          { id: "1162", name: "Ph\u00fa H\u1eefu", prefix: "X\u00e3" },
          { id: "1163", name: "Ph\u00fa Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "1164", name: "Ph\u01b0\u1edbc An", prefix: "X\u00e3" },
          { id: "1165", name: "Ph\u01b0\u1edbc Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "1166", name: "Ph\u01b0\u1edbc Thi\u1ec1n", prefix: "X\u00e3" },
          { id: "1167", name: "V\u0129nh Thanh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "15331", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15332", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15333", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15334", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15335", name: "25A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15336", name: "25B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15337", name: "25C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15338", name: "319", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15339", name: "319B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15340", name: "339", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15341", name: "3B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15342", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15343", name: "769", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15344",
            name: "B\u1eafc Th\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15345",
            name: "B\u01b0ng \u00d4ng Tho\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15346",
            name: "C\u00e1t L\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15347",
            name: "C\u00e2y D\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15348",
            name: "Con G\u00e0 V\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15349",
            name: "C\u1ed9ng \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15350", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15351", name: "D10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15352", name: "D14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15353", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15354", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15355", name: "D4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15356", name: "D5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15357", name: "D6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15358", name: "D7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15359", name: "D8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15360", name: "D9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15365",
            name: "D\u01b0\u01a1ng V\u0103n Th\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15361",
            name: "\u0110\u1ea1i Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15362",
            name: "\u0110o\u00e0n Th\u1ecb Ph\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15363", name: "\u0110T 679", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15364", name: "\u0110T 769", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15366",
            name: "Gi\u1ea3i Ph\u00f3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15367",
            name: "Gi\u00e1o x\u1ee9 Ph\u00fac L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15368",
            name: "Gi\u1ed3ng \u00d4ng \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15369", name: "H", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15370",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15371",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15372",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15373",
            name: "H\u01b0\u01a1ng \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15374",
            name: "H\u01b0\u01a1ng L\u1ed9 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15375",
            name: "H\u01b0\u01a1ng l\u1ed9 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15376",
            name: "H\u01b0\u01a1ng L\u1ed9 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15377",
            name: "Hu\u1ef3nh V\u0103n L\u0169y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15378",
            name: "Hu\u1ef3nh V\u0103n Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15379",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15380",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15381",
            name: "L\u00ea H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15382",
            name: "Li\u00ean C\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15383",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15384",
            name: "Long \u0110i\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15385",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15386",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "15387",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15388", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15389", name: "N10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15390", name: "N11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15391", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15392", name: "N3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15393", name: "N4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15394", name: "N5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15395", name: "N6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15396", name: "N7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15397", name: "N9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15398",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15399",
            name: "Nguy\u1ec5n \u00c1i Qu\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15400",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15401",
            name: "Nguy\u1ec5n Kim Quy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15402",
            name: "Nguy\u1ec5n Th\u1ecb Ch\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15403",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15404",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15405",
            name: "Nguy\u1ec5n V\u0103n K\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15406",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15407",
            name: "\u00d4ng K\u00e8o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15408",
            name: "Ph\u1ea1m Th\u00e1i B\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15409",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15410",
            name: "Phan V\u0103n \u0110\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15411",
            name: "Phan V\u0103n H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15412",
            name: "Phan V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15413",
            name: "Ph\u01b0\u1edbc Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15414",
            name: "Qu\u00e1ch Th\u1ecb Trang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15415",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15416",
            name: "Qu\u1ed1c L\u1ed9 51",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15417", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15418", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15419", name: "S\u1ed1 3B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15420", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15421", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15422",
            name: "T\u00e2n V\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15423",
            name: "T\u1ec9nh L\u1ed9 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15424",
            name: "T\u1ec9nh l\u1ed9 25B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15425",
            name: "T\u1ec9nh l\u1ed9 25C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15426",
            name: "T\u1ec9nh l\u1ed9 769",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15427",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "15428",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "15429",
            name: "Tr\u1ea7n Nam Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15430",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15431",
            name: "Tr\u1ea7n Th\u1ecb Nh\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15432",
            name: "Tr\u1ea7n V\u0103n \u01a0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15433",
            name: "Tr\u1ea7n V\u0103n Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15434",
            name: "V\u00e0m \u00d4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15435",
            name: "V\u00e0nh \u0110ai 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15436",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15437",
            name: "V\u01b0\u1eddn Chu\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2334",
            name: "Detaco Nh\u01a1n Tr\u1ea1ch",
            lat: "10.674575805664",
            lng: "106.92852783203",
          },
          {
            id: "2337",
            name: "Dosa Riverside",
            lat: "10.744215965271",
            lng: "106.82373809814",
          },
          {
            id: "2331",
            name: "\u0110\u1ea1i Ph\u01b0\u1edbc Center City",
            lat: "10.735988616943",
            lng: "106.81610107422",
          },
          {
            id: "2332",
            name: "\u0110\u1ea1i Ph\u01b0\u1edbc Lotus",
            lat: "10.733075141907",
            lng: "106.8323059082",
          },
          {
            id: "2333",
            name: "\u0110\u1ea1i Ph\u01b0\u1edbc Riverside",
            lat: "10.744215965271",
            lng: "106.82373809814",
          },
          {
            id: "2335",
            name: "\u0110i\u1ec1n Ph\u01b0\u1edbc",
            lat: "10.7553358078",
            lng: "106.86343383789",
          },
          {
            id: "2336",
            name: "\u0110\u00f4ng S\u00e0i G\u00f2n",
            lat: "10.721966743469",
            lng: "106.86904144287",
          },
          {
            id: "2338",
            name: "Eco Sun",
            lat: "10.688333511353",
            lng: "106.92082977295",
          },
          {
            id: "2339",
            name: "KDC T\u00e2n Long",
            lat: "10.752477645874",
            lng: "106.87094116211",
          },
          {
            id: "2340",
            name: "K\u0110T Ph\u00fa H\u1ed9i",
            lat: "10.718647003174",
            lng: "106.88841247559",
          },
          {
            id: "2341",
            name: "Khu d\u00e2n c\u01b0 B\u00e1i T\u1eed Long \u0110\u1ea1i Ph\u01b0\u1edbc",
            lat: "10.735912322998",
            lng: "106.8158416748",
          },
          {
            id: "2342",
            name: "Khu \u0111\u00f4 th\u1ecb m\u1edbi Long Th\u1ecd",
            lat: "10.632780075073",
            lng: "106.97127532959",
          },
          {
            id: "2343",
            name: "King Bay",
            lat: "10.752477645874",
            lng: "106.87094116211",
          },
          {
            id: "2344",
            name: "Long Th\u1ecd Ph\u01b0\u1edbc An",
            lat: "10.674284934998",
            lng: "106.94481658936",
          },
          {
            id: "2345",
            name: "Nh\u01a1n Tr\u1ea1ch",
            lat: "10.688851356506",
            lng: "106.88109588623",
          },
          {
            id: "2346",
            name: "Nh\u01a1n Tr\u1ea1ch City Center",
            lat: "10.737144470215",
            lng: "106.94165039062",
          },
          {
            id: "2347",
            name: "Orchid City",
            lat: "10.672212600708",
            lng: "106.9224319458",
          },
          {
            id: "2348",
            name: "Ph\u00fa H\u1eefu Residence",
            lat: "10.730689048767",
            lng: "106.7734375",
          },
          {
            id: "2349",
            name: "Richland City",
            lat: "10.696884155273",
            lng: "106.8907699585",
          },
          {
            id: "2350",
            name: "S\u00f4ng \u0110\u00e0 IDC",
            lat: "10.670266151428",
            lng: "106.88851928711",
          },
          {
            id: "2351",
            name: "Sunflower City",
            lat: "10.679678916931",
            lng: "106.93093109131",
          },
          {
            id: "2352",
            name: "Swan Bay",
            lat: "10.744215965271",
            lng: "106.82373809814",
          },
          {
            id: "2353",
            name: "Sweet Home",
            lat: "10.726962089539",
            lng: "106.88854980469",
          },
          {
            id: "2354",
            name: "Th\u0103ng Long Home",
            lat: "10.681745529175",
            lng: "106.93119049072",
          },
          {
            id: "2355",
            name: "Th\u0103ng Long Home Hi\u1ec7p Ph\u01b0\u1edbc",
            lat: "10.73791217804",
            lng: "106.94606781006",
          },
        ],
      },
      {
        id: "77",
        name: "T\u00e2n Ph\u00fa",
        wards: [
          { id: "1168", name: "\u0110\u0103k Lua", prefix: "X\u00e3" },
          { id: "1169", name: "Nam C\u00e1t Ti\u00ean", prefix: "X\u00e3" },
          { id: "1170", name: "N\u00fai T\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "1171", name: "Ph\u00fa An", prefix: "X\u00e3" },
          { id: "1172", name: "Ph\u00fa B\u00ecnh", prefix: "X\u00e3" },
          { id: "1173", name: "Ph\u00fa \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "1174", name: "Ph\u00fa L\u00e2m", prefix: "X\u00e3" },
          { id: "1175", name: "Ph\u00fa L\u1eadp", prefix: "X\u00e3" },
          { id: "1176", name: "Ph\u00fa L\u1ed9c", prefix: "X\u00e3" },
          { id: "1177", name: "Ph\u00fa S\u01a1n", prefix: "X\u00e3" },
          { id: "1178", name: "Ph\u00fa Thanh", prefix: "X\u00e3" },
          { id: "1179", name: "Ph\u00fa Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "1180", name: "Ph\u00fa Trung", prefix: "X\u00e3" },
          { id: "1181", name: "Ph\u00fa Xu\u00e2n", prefix: "X\u00e3" },
          { id: "1182", name: "T\u00e0 L\u00e0i", prefix: "X\u00e3" },
          {
            id: "1183",
            name: "T\u00e2n Ph\u00fa",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "1184", name: "Thanh S\u01a1n", prefix: "X\u00e3" },
          { id: "1185", name: "Tr\u00e0 C\u1ed5", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "15438", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15439",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15440",
            name: "Qu\u1ed1c L\u1ed9 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15441",
            name: "T\u00e0 L\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15442",
            name: "Tr\u00e0 C\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "78",
        name: "Th\u1ed1ng Nh\u1ea5t",
        wards: [
          { id: "1186", name: "B\u00e0u H\u00e0m 2", prefix: "X\u00e3" },
          {
            id: "1187",
            name: "D\u1ea7u Gi\u00e2y",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "1188", name: "Gia Ki\u1ec7m", prefix: "X\u00e3" },
          { id: "1189", name: "Gia T\u00e2n 1", prefix: "X\u00e3" },
          { id: "1190", name: "Gia T\u00e2n 2", prefix: "X\u00e3" },
          { id: "1191", name: "Gia T\u00e2n 3", prefix: "X\u00e3" },
          { id: "1192", name: "H\u01b0ng L\u1ed9c", prefix: "X\u00e3" },
          { id: "1193", name: "L\u1ed9 25", prefix: "X\u00e3" },
          { id: "1194", name: "Quang Trung", prefix: "X\u00e3" },
          { id: "1195", name: "Xu\u00e2n Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "1196", name: "Xu\u00e2n Thi\u1ec7n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "15443", name: "769", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15444", name: "D15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15445", name: "D21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15446", name: "DH3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15447", name: "\u0110T 25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15448", name: "\u0110T 769", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15449",
            name: "\u0110\u1ee9c Huy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15450",
            name: "Long Th\u00e0nh - D\u1ea7u Gi\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15451", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15452", name: "N25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15453", name: "N31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15454", name: "N32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15455", name: "N33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15456", name: "N34", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15457",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15458",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15459",
            name: "Qu\u1ed1c L\u1ed9 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15460",
            name: "T\u1ec9nh l\u1ed9 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15461",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2356",
            name: "Dau Giay Center City",
            lat: "10.94312953949",
            lng: "107.1413269043",
          },
          {
            id: "2357",
            name: "D\u1ea7u Gi\u00e2y Center City 2",
            lat: "10.941209793091",
            lng: "107.14475250244",
          },
        ],
      },
      {
        id: "79",
        name: "Tr\u1ea3ng Bom",
        wards: [
          { id: "1197", name: "An Vi\u1ec5n", prefix: "X\u00e3" },
          { id: "1198", name: "B\u1eafc S\u01a1n", prefix: "X\u00e3" },
          { id: "1199", name: "B\u00e0u H\u00e0m", prefix: "X\u00e3" },
          { id: "1200", name: "B\u00ecnh Minh", prefix: "X\u00e3" },
          { id: "1201", name: "C\u00e2y G\u00e1o", prefix: "X\u00e3" },
          { id: "1202", name: "\u0110\u1ed3i 61", prefix: "X\u00e3" },
          { id: "1203", name: "\u0110\u00f4ng H\u00f2a", prefix: "X\u00e3" },
          { id: "1204", name: "Giang \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "1205", name: "H\u1ed1 Nai 3", prefix: "X\u00e3" },
          { id: "1206", name: "H\u00f2a Trung", prefix: "X\u00e3" },
          { id: "1207", name: "H\u01b0ng Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "1208", name: "Qu\u1ea3ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "1209", name: "S\u00f4ng Thao", prefix: "X\u00e3" },
          { id: "1210", name: "S\u00f4ng Tr\u1ea7u", prefix: "X\u00e3" },
          { id: "1211", name: "T\u00e2y H\u00f2a", prefix: "X\u00e3" },
          { id: "1212", name: "Thanh B\u00ecnh", prefix: "X\u00e3" },
          { id: "1213", name: "Tr\u1ea3ng Bom", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "1214", name: "Trung H\u00f2a", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "15462", name: "12A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15463", name: "2/9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15464", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15465", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15466", name: "22/12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15467", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15468", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15469", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15470", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15471", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15472", name: "51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15473", name: "53", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15474", name: "56", prefix: "Ph\u1ed1" },
          { id: "15475", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15476", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15477", name: "762", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15478", name: "767", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15479", name: "A1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15480",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15481", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "15482",
            name: "B\u1eafc H\u00f2a - Ph\u00fa S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15483",
            name: "B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15484",
            name: "B\u1eafc S\u01a1n - Long Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15485",
            name: "B\u00ecnh Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15486",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15487",
            name: "Ch\u1ee3 Chi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15488", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15489", name: "D20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15490", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15491", name: "D6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15500",
            name: "D\u01b0\u01a1ng \u0110\u1ed9c L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15492",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15493",
            name: "\u0110inh Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15494",
            name: "\u0110o\u00e0n K\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15495",
            name: "\u0110\u1ed3ng H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15496",
            name: "\u0110\u00f4ng H\u00f2a 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15497",
            name: "\u0110\u00f4ng H\u00f2a 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15498",
            name: "\u0110\u1ed3ng Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15499", name: "\u0110T 767", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15501", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "15502",
            name: "H\u01b0ng B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15503",
            name: "H\u01b0ng B\u00ecnh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15504",
            name: "H\u01b0ng \u0110\u1ea1o V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15505",
            name: "H\u01b0ng Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15506",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15507",
            name: "H\u01b0\u01a1ng L\u1ed9 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15508",
            name: "Hu\u1ef3nh V\u0103n Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15509", name: "K5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15510",
            name: "Kh\u00fac Th\u1eeba D\u1ee5",
            prefix: "Ph\u1ed1",
          },
          {
            id: "15511",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15512", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15513",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15514",
            name: "Li\u00ean \u1ea4p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15515",
            name: "Li\u00ean \u00e2\u0301p 2-6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15516",
            name: "Li\u00ean \u1ea5p 4-5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15517",
            name: "Li\u00ean khu 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15518",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15519",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15520",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          { id: "15521", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15522", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15523", name: "N4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15524", name: "N5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15525", name: "N7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15526",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15527",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15528",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15529",
            name: "Nguy\u1ec5n Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15530",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15531",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15532",
            name: "Nguy\u1ec5n S\u01a1n H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15533",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15534",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15535",
            name: "Nguy\u00ean V\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15536",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15537", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "15538",
            name: "Quang Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15539",
            name: "Qu\u1ea3ng Ti\u1ebfn 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15540",
            name: "Qu\u1ea3ng Ti\u1ebfn 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15541",
            name: "Qu\u1ea3ng Ti\u1ebfn 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15542", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15543",
            name: "Qu\u1ed1c Ch\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15544",
            name: "Qu\u1ed1c L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15545",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15546", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15547", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15548", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15549", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15550", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15551", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15552",
            name: "Su\u1ed1i Dinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15553",
            name: "Su\u1ed1i Tre 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15554",
            name: "T\u00e2y H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15555",
            name: "T\u00e2y H\u00f2a 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15556",
            name: "T\u00e2y H\u00f2a 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15557",
            name: "T\u00e2y H\u00f2a 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15558",
            name: "T\u00e2y H\u00f2a 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15559",
            name: "T\u00e2y H\u00f2a 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15560",
            name: "T\u00e2y H\u00f2a 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15561",
            name: "T\u00e2y L\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15562", name: "TH01", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15563",
            name: "Th\u00e1c \u0110\u00e1 H\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15564",
            name: "Th\u00e1i H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15565",
            name: "Th\u00e0nh Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15566",
            name: "T\u1ec9nh l\u1ed9 762",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15567",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15568",
            name: "Tr\u1ea3ng Bom",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15569", name: "Trung H\u00f2a", prefix: "Ph\u1ed1" },
          {
            id: "15570",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15571",
            name: "V\u00e0nh \u0110ai 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15572",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15573",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15574",
            name: "X\u00e3 \u0110\u1ed3i 61",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15575",
            name: "Y\u00ean Th\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2358",
            name: "Beryl Residences",
            lat: "10.860149383545",
            lng: "106.95659637451",
          },
          {
            id: "2359",
            name: "Boulevard City",
            lat: "10.952625274658",
            lng: "107.01282501221",
          },
          {
            id: "2360",
            name: "Dream Town \u0110\u1ed3ng Nai",
            lat: "10.857789039612",
            lng: "106.94664001465",
          },
          {
            id: "2361",
            name: "Giang \u0110i\u1ec1n",
            lat: "10.916097640991",
            lng: "106.99294281006",
          },
          {
            id: "2362",
            name: "Gold Hill",
            lat: "10.960885047913",
            lng: "106.9979019165",
          },
          {
            id: "2363",
            name: "H\u01b0ng Thu\u1eadn",
            lat: "10.95717048645",
            lng: "106.99352264404",
          },
          {
            id: "2364",
            name: "Khu d\u00e2n c\u01b0 An Ph\u00e1t",
            lat: "10.87579536438",
            lng: "107.00080108643",
          },
          {
            id: "2365",
            name: "La B\u00e0n Land",
            lat: "10.859353065491",
            lng: "106.95301818848",
          },
          {
            id: "2366",
            name: "Nature Village \u0110\u1ed3ng Nai",
            lat: "10.912027359009",
            lng: "106.98603820801",
          },
          {
            id: "2367",
            name: "Ngoc Bich Residence",
            lat: "10.924337387085",
            lng: "106.98205566406",
          },
          {
            id: "2368",
            name: "Palm Town",
            lat: "10.860149383545",
            lng: "106.95659637451",
          },
          {
            id: "2369",
            name: "Richland Hill",
            lat: "10.960425376892",
            lng: "106.99521636963",
          },
          {
            id: "2370",
            name: "Sakura Valley",
            lat: "10.912580490112",
            lng: "106.98961639404",
          },
          {
            id: "2371",
            name: "Sunrise Residences",
            lat: "10.95339012146",
            lng: "106.97718048096",
          },
          {
            id: "2372",
            name: "Su\u1ed1i Son",
            lat: "10.90904045105",
            lng: "106.97585296631",
          },
          {
            id: "2373",
            name: "The Viva City",
            lat: "10.904109954834",
            lng: "106.97818756104",
          },
          {
            id: "2374",
            name: "V\u0169 Ho\u00e0ng Anh",
            lat: "10.952963829041",
            lng: "106.99616241455",
          },
        ],
      },
      {
        id: "80",
        name: "V\u0129nh C\u1eedu",
        wards: [
          { id: "1215", name: "B\u00ecnh H\u00f2a", prefix: "X\u00e3" },
          { id: "1216", name: "B\u00ecnh L\u1ee3i", prefix: "X\u00e3" },
          { id: "1217", name: "Hi\u1ebfu Li\u00eam", prefix: "X\u00e3" },
          { id: "1218", name: "M\u00e3 \u0110\u00e0", prefix: "X\u00e3" },
          { id: "1219", name: "Ph\u00fa L\u00fd", prefix: "X\u00e3" },
          { id: "1220", name: "T\u00e2n An", prefix: "X\u00e3" },
          { id: "1221", name: "T\u00e2n B\u00ecnh", prefix: "X\u00e3" },
          { id: "1222", name: "Th\u1ea1nh Ph\u00fa", prefix: "X\u00e3" },
          { id: "1223", name: "Thi\u1ec7n T\u00e2n", prefix: "X\u00e3" },
          { id: "1224", name: "Tr\u1ecb An", prefix: "X\u00e3" },
          { id: "1225", name: "V\u0129nh An", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "1226", name: "V\u0129nh T\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "15576", name: "24", prefix: "Ph\u1ed1" },
          { id: "15577", name: "767", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15578", name: "768", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15579", name: "768B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15580",
            name: "B\u00ecnh L\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15581",
            name: "B\u1eedu Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15582",
            name: "C\u00e2y D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15583",
            name: "C\u00e2y Qu\u00e9o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15584", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15585",
            name: "\u0110a L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15586",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15587",
            name: "\u0110o\u00e0n V\u0103n C\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15588",
            name: "\u0110\u1ed3ng Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15589", name: "\u0110T 767", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15590", name: "\u0110T 768", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15591",
            name: "H\u01b0\u01a1ng L\u1ed9 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15592",
            name: "H\u01b0\u01a1ng L\u1ed9 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15593",
            name: "H\u01b0\u01a1ng l\u1ed9 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15594",
            name: "H\u01b0\u01a1ng L\u1ed9 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15595",
            name: "H\u01b0\u01a1ng L\u1ed9 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15596",
            name: "H\u01b0\u01a1ng L\u1ed9 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15597",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15598",
            name: "Long Chi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15599",
            name: "L\u01b0\u01a1ng Th\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15600",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15601", name: "M\u1ef9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15602", name: "N4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15603", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15604",
            name: "S\u1edfi Qu\u00fdt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15605",
            name: "Su\u1ed1i M\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15606",
            name: "T\u00e2n Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15607",
            name: "T\u1ec9nh l\u1ed9 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15608",
            name: "T\u1ec9nh l\u1ed9 761",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15609",
            name: "T\u1ec9nh l\u1ed9 762",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15610",
            name: "T\u1ec9nh L\u1ed9 768",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15611",
            name: "V\u0103n Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2375",
            name: "KDC Th\u1ea1nh Ph\u00fa",
            lat: "11.01661491394",
            lng: "106.84792327881",
          },
          {
            id: "2376",
            name: "Lavender City",
            lat: "11.003437995911",
            lng: "106.82098388672",
          },
        ],
      },
      {
        id: "81",
        name: "Xu\u00e2n L\u1ed9c",
        wards: [
          { id: "1227", name: "B\u1ea3o H\u00f2a", prefix: "X\u00e3" },
          { id: "1228", name: "Gia Ray", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "1229", name: "Lang Minh", prefix: "X\u00e3" },
          { id: "1230", name: "Su\u1ed1i Cao", prefix: "X\u00e3" },
          { id: "1231", name: "Su\u1ed1i C\u00e1t", prefix: "X\u00e3" },
          { id: "1232", name: "Xu\u00e2n B\u1eafc", prefix: "X\u00e3" },
          { id: "1233", name: "Xu\u00e2n \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "1234", name: "Xu\u00e2n Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "1235", name: "Xu\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "1236", name: "Xu\u00e2n H\u01b0ng", prefix: "X\u00e3" },
          { id: "1237", name: "Xu\u00e2n Ph\u00fa", prefix: "X\u00e3" },
          { id: "1238", name: "Xu\u00e2n T\u00e2m", prefix: "X\u00e3" },
          { id: "1239", name: "Xu\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "1240", name: "Xu\u00e2n Th\u1ecd", prefix: "X\u00e3" },
          { id: "1241", name: "Xu\u00e2n Tr\u01b0\u1eddng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "15612", name: "21/3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15613",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15614", name: "\u0110T 763", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15615",
            name: "Ho\u00e0ng \u0110\u00ecnh Th\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15616",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15617",
            name: "Hu\u1ef3nh V\u0103n Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15618",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15619",
            name: "L\u00ea Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15620",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15621",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15622",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15623",
            name: "Nguy\u1ec5n Thi\u1ebfp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15624",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15625",
            name: "T\u00e0 L\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15626",
            name: "T\u1ec9nh l\u1ed9 763",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15627",
            name: "T\u1ec9nh l\u1ed9 766",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15628",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15629",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "6",
    code: "KH",
    name: "Kh\u00e1nh H\u00f2a",
    districts: [
      {
        id: "82",
        name: "Cam L\u00e2m",
        wards: [
          { id: "1242", name: "Cam An B\u1eafc", prefix: "X\u00e3" },
          { id: "1243", name: "Cam An Nam", prefix: "X\u00e3" },
          {
            id: "1244",
            name: "Cam \u0110\u1ee9c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "1245",
            name: "Cam H\u1ea3i \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "1246", name: "Cam H\u1ea3i T\u00e2y", prefix: "X\u00e3" },
          { id: "1247", name: "Cam Hi\u1ec7p B\u1eafc", prefix: "X\u00e3" },
          { id: "1248", name: "Cam Hi\u1ec7p Nam", prefix: "X\u00e3" },
          { id: "1249", name: "Cam H\u00f2a", prefix: "X\u00e3" },
          {
            id: "1250",
            name: "Cam Ph\u01b0\u1edbc T\u00e2y",
            prefix: "X\u00e3",
          },
          { id: "1251", name: "Cam T\u00e2n", prefix: "X\u00e3" },
          { id: "1252", name: "Cam Th\u00e0nh B\u1eafc", prefix: "X\u00e3" },
          { id: "1253", name: "S\u01a1n T\u00e2n", prefix: "X\u00e3" },
          { id: "1254", name: "Su\u1ed1i C\u00e1t", prefix: "X\u00e3" },
          { id: "1255", name: "Su\u1ed1i T\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "15630", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15631", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15632",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          {
            id: "15633",
            name: "B\u00e3i Gi\u1ebfng - Trung Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15634",
            name: "C\u1eedu Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15635",
            name: "\u0110\u00e0o Duy Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15636",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15637",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15638",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15639",
            name: "H\u01b0\u01a1ng l\u1ed9 39",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15640", name: "Lam S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15641",
            name: "L\u1eadp \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15642",
            name: "L\u1eadp \u0110\u1ecbnh - Su\u1ed1i M\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15643",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15644",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15645",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15646", name: "N5A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15647",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15648",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15649",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15650",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15651",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15652",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15653",
            name: "Qu\u1ed1c L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15654",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15655",
            name: "Th\u1ee7y Tri\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15656",
            name: "T\u1ec9nh l\u1ed9 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15657",
            name: "T\u1ec9nh l\u1ed9 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15658", name: "V\u0103n Cao", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15659", name: "Yersin", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "2377",
            name: "Carava Resort Cam Ranh",
            lat: "12.060249328613",
            lng: "109.19989776611",
          },
          {
            id: "2378",
            name: "Manna Luxury Holiday",
            lat: "12.125744819641",
            lng: "109.19989776611",
          },
          {
            id: "2379",
            name: "Movenpick Cam Ranh Resort",
            lat: "12.043476104736",
            lng: "109.20838928223",
          },
          {
            id: "2380",
            name: "The Cam Ranh Flowers Resort",
            lat: "12.103421211243",
            lng: "109.19467926025",
          },
          {
            id: "2381",
            name: "The Residences Mia",
            lat: "12.060249328613",
            lng: "109.19989776611",
          },
          {
            id: "2382",
            name: "Vinpearl B\u00e3i D\u00e0i",
            lat: "12.081057548523",
            lng: "109.19302368164",
          },
          {
            id: "2383",
            name: "Vogue Resort Nha Trang",
            lat: "12.060249328613",
            lng: "109.19989776611",
          },
        ],
      },
      {
        id: "83",
        name: "Cam Ranh",
        wards: [
          { id: "1256", name: "Ba Ng\u00f2i", prefix: "Ph\u01b0\u1eddng" },
          { id: "1257", name: "Cam B\u00ecnh", prefix: "X\u00e3" },
          { id: "1258", name: "Cam L\u1eadp", prefix: "X\u00e3" },
          { id: "1259", name: "Cam Linh", prefix: "Ph\u01b0\u1eddng" },
          { id: "1260", name: "Cam L\u1ed9c", prefix: "Ph\u01b0\u1eddng" },
          { id: "1261", name: "Cam L\u1ee3i", prefix: "Ph\u01b0\u1eddng" },
          { id: "1262", name: "Cam Ngh\u0129a", prefix: "Ph\u01b0\u1eddng" },
          { id: "1263", name: "Cam Ph\u00fa", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1264",
            name: "Cam Ph\u00fac B\u1eafc",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1265", name: "Cam Ph\u00fac Nam", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1266",
            name: "Cam Ph\u01b0\u1edbc \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "1267", name: "Cam Th\u00e0nh Nam", prefix: "X\u00e3" },
          {
            id: "1268",
            name: "Cam Th\u1ecbnh \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "1269", name: "Cam Th\u1ecbnh T\u00e2y", prefix: "X\u00e3" },
          { id: "1270", name: "Cam Thu\u1eadn", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "15660", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15661", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15662", name: "22/8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15663", name: "3/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15664", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15665",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15666",
            name: "Cam H\u1ea3i \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15667",
            name: "Ch\u1ebf Lan Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15668",
            name: "Chi L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15669",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15670",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15671",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15672",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15673",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15674", name: "Lam S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15675",
            name: "L\u1eadp \u0110\u1ecbnh-Su\u1ed1i M\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15677",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15676",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15678",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15679",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15680",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15681",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15682",
            name: "Li\u00ean Th\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15683",
            name: "L\u01b0\u01a1ng V\u0103n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15684",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          { id: "15685", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15686",
            name: "Ngh\u0129a Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15687",
            name: "Ng\u00f4 M\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15688",
            name: "Nguy\u1ec5n B\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15689",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15690",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15691",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15692",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15693",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15694",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15695",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15696",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15697",
            name: "Nguy\u1ec5n Tr\u1ecdng K\u1ef7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15698",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15699",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15700",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15701",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15702",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15703",
            name: "Qu\u1ed1c l\u1ed9 27B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15704", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15705", name: "S\u1ed1 24", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15706", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15707",
            name: "T\u1ec9nh l\u1ed9 654",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15708",
            name: "T\u1ec9nh l\u1ed9 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15709",
            name: "T\u1ed1 H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15710",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15711",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "15712",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15713",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2384",
            name: "Cam Ranh City Gate",
            lat: "12.031831741333",
            lng: "109.21041107178",
          },
          {
            id: "2385",
            name: "Cam Ranh Mystery Villas",
            lat: "12.031831741333",
            lng: "109.21041107178",
          },
          {
            id: "2386",
            name: "Golden Bay",
            lat: "12.111289978027",
            lng: "109.18559265137",
          },
          {
            id: "2387",
            name: "Golden Bay 2",
            lat: "12.090082168579",
            lng: "109.18801116943",
          },
          {
            id: "2388",
            name: "Ocean Window Resort",
            lat: "11.927708625793",
            lng: "109.22368621826",
          },
          {
            id: "2389",
            name: "The Arena Cam Ranh",
            lat: "11.99972820282",
            lng: "109.20928955078",
          },
        ],
      },
      {
        id: "84",
        name: "Di\u00ean Kh\u00e1nh",
        wards: [
          { id: "1271", name: "Di\u00ean An", prefix: "X\u00e3" },
          { id: "1272", name: "Di\u00ean B\u00ecnh", prefix: "X\u00e3" },
          { id: "1273", name: "Di\u00ean \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "1274", name: "Di\u00ean \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "1275", name: "Di\u00ean H\u00f2a", prefix: "X\u00e3" },
          {
            id: "1276",
            name: "Di\u00ean Kh\u00e1nh ",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1277", name: "Di\u00ean L\u1ea1c", prefix: "X\u00e3" },
          { id: "1278", name: "Di\u00ean L\u00e2m", prefix: "X\u00e3" },
          { id: "1279", name: "Di\u00ean L\u1ed9c", prefix: "X\u00e3" },
          { id: "1280", name: "Di\u00ean Ph\u00fa", prefix: "X\u00e3" },
          { id: "1281", name: "Di\u00ean Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "1282", name: "Di\u00ean S\u01a1n", prefix: "X\u00e3" },
          { id: "1283", name: "Di\u00ean T\u00e2n", prefix: "X\u00e3" },
          { id: "1284", name: "Di\u00ean Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "1285", name: "Di\u00ean Th\u1ecd", prefix: "X\u00e3" },
          { id: "1286", name: "Di\u00ean To\u00e0n", prefix: "X\u00e3" },
          { id: "1287", name: "Di\u00ean Xu\u00e2n", prefix: "X\u00e3" },
          { id: "1288", name: "Su\u1ed1i Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "1289", name: "Su\u1ed1i Ti\u00ean", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "15714", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15715", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15716", name: "23/10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15717", name: "39", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15718",
            name: "An \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15719",
            name: "B\u00e0 \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15720",
            name: "B\u1ea7u \u0110\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15721",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15722",
            name: "C\u1ea7u Ch\u00e1y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15723", name: "C\u00e2y Da", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15724",
            name: "C\u00e2y Du\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15725",
            name: "Ch\u00edn Kh\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15726",
            name: "\u0110\u1ea7u C\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15727",
            name: "\u0110\u1ec1 Dai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15728",
            name: "\u0110\u1ed3ng Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15729",
            name: "\u0110\u1ed3ng Ng\u00f4n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15730",
            name: "G\u00f2 M\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15731",
            name: "G\u00f2 Th\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15732",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15733",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15734",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15735",
            name: "H\u01b0\u01a1ng l\u1ed9 39",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15736",
            name: "H\u01b0\u01a1ng l\u1ed9 45",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15737",
            name: "H\u01b0\u01a1ng l\u1ed9 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15738",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15739",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15740",
            name: "L\u0103ng \u00d4ng - Di\u00ean An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15741",
            name: "L\u1ea7u \u00d4ng Huy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15742",
            name: "Li\u00ean Th\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15743",
            name: "Li\u00ean T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15744",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15745",
            name: "L\u1ed7 L\u1ea7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15746",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15747",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15748",
            name: "Mi\u1ebfu Ti\u1ec1n Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15749", name: "N13", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15750",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15751",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15752",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15753",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15754",
            name: "Qu\u1ed1c L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15755",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15756",
            name: "Ru\u1ed9ng Th\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15757", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15758", name: "S\u1ed1 23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15759", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15760", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15761", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15762",
            name: "Th\u00e0nh H\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15763",
            name: "Th\u00f4ng Tin",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15764",
            name: "T\u1ec9nh L\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15765",
            name: "T\u1ec9nh l\u1ed9 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15766",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15767",
            name: "Tr\u01b0\u1eddng L\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15768",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15769",
            name: "X\u00f3m S\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2390",
            name: "K\u0110T Nam S\u00f4ng C\u00e1i",
            lat: "12.258790969849",
            lng: "109.09943389893",
          },
          {
            id: "2391",
            name: "Ph\u00fa \u00c2n Nam 2",
            lat: "12.243507385254",
            lng: "109.11962890625",
          },
        ],
      },
      {
        id: "85",
        name: "Kh\u00e1nh S\u01a1n",
        wards: [
          { id: "1290", name: "Ba C\u1ee5m B\u1eafc", prefix: "X\u00e3" },
          { id: "1291", name: "Ba C\u1ee5m Nam", prefix: "X\u00e3" },
          { id: "1292", name: "S\u01a1n B\u00ecnh", prefix: "X\u00e3" },
          { id: "1293", name: "S\u01a1n Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "1294", name: "S\u01a1n L\u00e2m", prefix: "X\u00e3" },
          { id: "1295", name: "S\u01a1n Trung", prefix: "X\u00e3" },
          { id: "1296", name: "Th\u00e0nh S\u01a1n", prefix: "X\u00e3" },
          {
            id: "1297",
            name: "T\u00f4 H\u1ea1p",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          { id: "15770", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15771",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15772",
            name: "T\u1ec9nh l\u1ed9 654",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "86",
        name: "Kh\u00e1nh V\u0129nh",
        wards: [
          { id: "1298", name: "C\u1ea7u B\u00e0", prefix: "X\u00e3" },
          { id: "1299", name: "Giang Ly", prefix: "X\u00e3" },
          { id: "1300", name: "Kh\u00e1nh B\u00ecnh", prefix: "X\u00e3" },
          { id: "1301", name: "Kh\u00e1nh \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "1302", name: "Kh\u00e1nh Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "1303", name: "Kh\u00e1nh Nam", prefix: "X\u00e3" },
          { id: "1304", name: "Kh\u00e1nh Ph\u00fa", prefix: "X\u00e3" },
          { id: "1305", name: "Kh\u00e1nh Th\u00e0nh", prefix: "X\u00e3" },
          {
            id: "1306",
            name: "Kh\u00e1nh Th\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
          { id: "1307", name: "Kh\u00e1nh Trung", prefix: "X\u00e3" },
          {
            id: "1308",
            name: "Kh\u00e1nh V\u0129nh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "1309", name: "Li\u00ean Sang", prefix: "X\u00e3" },
          { id: "1310", name: "S\u01a1n Th\u00e1i", prefix: "X\u00e3" },
          { id: "1311", name: "S\u00f4ng C\u1ea7u", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "15773", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15774", name: "2/8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15775", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15776",
            name: "\u0110\u00e0 L\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15777",
            name: "H\u01b0\u01a1ng l\u1ed9 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15778",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15779",
            name: "Qu\u1ed1c l\u1ed9 46",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15780",
            name: "T\u1ec9nh l\u1ed9 652",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15781",
            name: "T\u1ec9nh l\u1ed9 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15782",
            name: "T\u1ec9nh l\u1ed9 8B",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "87",
        name: "Nha Trang",
        wards: [
          { id: "1312", name: "L\u1ed9c Th\u1ecd", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1313",
            name: "Ng\u1ecdc Hi\u1ec7p",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1314",
            name: "Ph\u01b0\u1edbc \u0110\u1ed3ng",
            prefix: "X\u00e3",
          },
          {
            id: "1315",
            name: "Ph\u01b0\u1edbc H\u1ea3i",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1316",
            name: "Ph\u01b0\u1edbc H\u00f2a",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1317",
            name: "Ph\u01b0\u1edbc Long ",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1318",
            name: "Ph\u01b0\u1edbc T\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1319",
            name: "Ph\u01b0\u1edbc Ti\u1ebfn",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1320",
            name: "Ph\u01b0\u01a1ng S\u00e0i",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1321",
            name: "Ph\u01b0\u01a1ng S\u01a1n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1322", name: "T\u00e2n L\u1eadp", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1323",
            name: "V\u1ea1n Th\u1eafng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1324",
            name: "V\u1ea1n Th\u1ea1nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1325",
            name: "V\u0129nh H\u1ea3i",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1326", name: "V\u0129nh Hi\u1ec7p", prefix: "X\u00e3" },
          {
            id: "1327",
            name: "V\u0129nh H\u00f2a",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1328", name: "V\u0129nh L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "1329", name: "V\u0129nh Ng\u1ecdc", prefix: "X\u00e3" },
          {
            id: "1330",
            name: "V\u0129nh Nguy\u00ean",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1331",
            name: "V\u0129nh Ph\u01b0\u1edbc",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1332", name: "V\u0129nh Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "1333", name: "V\u0129nh Th\u00e1i", prefix: "X\u00e3" },
          { id: "1334", name: "V\u0129nh Th\u1ea1nh", prefix: "X\u00e3" },
          {
            id: "1335",
            name: "V\u0129nh Th\u1ecd",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1336", name: "V\u0129nh Trung", prefix: "X\u00e3" },
          {
            id: "1337",
            name: "V\u0129nh Tr\u01b0\u1eddng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1338",
            name: "X\u01b0\u01a1ng Hu\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "15783", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15784", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15785", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15786", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15787", name: "12A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15788", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15789", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15790", name: "14A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15791", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15792", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15793", name: "18A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15794", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15795", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15796", name: "1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15797", name: "1B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15798", name: "1C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15799", name: "1D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15800", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15801", name: "2/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15802", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15803", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15804", name: "21A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15805", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15806", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15807", name: "23/10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15808", name: "24", prefix: "Ph\u1ed1" },
          { id: "15809", name: "24A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15810", name: "26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15811", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15812", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15813", name: "28B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15814", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15815", name: "2A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15816", name: "2B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15817", name: "2C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15818", name: "2D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15819", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15820", name: "30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15821", name: "30A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15822", name: "31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15823", name: "32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15824", name: "33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15825", name: "34", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15826", name: "36", prefix: "Ph\u1ed1" },
          { id: "15827", name: "3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15828", name: "3B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15829", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15830", name: "40A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15831", name: "45", prefix: "Ph\u1ed1" },
          { id: "15832", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15833", name: "4B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15834", name: "4C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15835", name: "4D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15836", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15837", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15838", name: "5A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15839", name: "5B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15840", name: "5C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15841", name: "5D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15842", name: "5E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15843", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15844", name: "66", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15845", name: "6A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15846", name: "6B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15847", name: "6C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15848", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15849", name: "7B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15850", name: "7C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15851", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15852", name: "8A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15853", name: "8B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15854", name: "8C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15855", name: "8D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15856", name: "8E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15857", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15858", name: "9A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15859", name: "A1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15860", name: "A2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15861", name: "A3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15862", name: "A3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15863", name: "A4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15864", name: "A5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15865", name: "A6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15866",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15867",
            name: "An Ph\u00fa \u0110\u00f4ng 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15868", name: "An Vi\u00ean", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15869",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15870", name: "B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15871", name: "B1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15872", name: "B10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15873", name: "B2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15874", name: "B3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15875", name: "B4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15876", name: "B5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15877", name: "B5-22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15878", name: "B6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15879", name: "B7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15880", name: "B8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15881", name: "B9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15882", name: "Ba L\u00e0ng", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15883", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          { id: "15884", name: "B\u1eafc Nam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15885",
            name: "B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15886",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15887",
            name: "B\u1ea1ch Th\u00e1i B\u01b0\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15888",
            name: "B\u00e3i D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15889",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15890",
            name: "B\u1ebfn C\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15891",
            name: "B\u1ebfn Ch\u1ee3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15892",
            name: "Bi\u1ec7t Th\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15893",
            name: "Bi\u1ec3u \u0110\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15894",
            name: "B\u00ecnh Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15895",
            name: "B\u00ecnh H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15896",
            name: "B\u00ecnh Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15897",
            name: "B\u1edd \u0110\u1eafp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15898",
            name: "B\u1edd K\u00e8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15899",
            name: "B\u00f9i Huy B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15900",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15901",
            name: "B\u00f9i Xu\u00e2n Ph\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15902",
            name: "B\u00f9i Xu\u00e2n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15903",
            name: "B\u1eedu \u0110\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15904", name: "C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15905", name: "C1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15906", name: "C2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15907", name: "C3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15908", name: "C4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15909", name: "C5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15910", name: "C8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15911",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15912",
            name: "Cao \u0110\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15913", name: "Cao Minh Phi", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15914",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15915",
            name: "Cao V\u0103n B\u00e9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15916",
            name: "C\u00e1t L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15917",
            name: "C\u1ea7u B\u00e8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15918",
            name: "C\u1ea7u D\u1ee9a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15919",
            name: "C\u1ea7u D\u1ee9a - Ph\u00fa N\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15920",
            name: "C\u1ea7u H\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15921",
            name: "C\u1ea7u L\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15922",
            name: "C\u1ea7u X\u00f3m B\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15923",
            name: "C\u00e2y G\u00e1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15924",
            name: "Ch\u00e2u V\u0103n Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15925",
            name: "Ch\u1ebf Lan Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15926",
            name: "Chi L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15927",
            name: "Ch\u00ed Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15928", name: "CL 18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15929", name: "CL 28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15930", name: "CL13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15931", name: "CL23", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15932",
            name: "C\u00f4 B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15933", name: "C\u1ed5 Loa", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15934", name: "C\u1ee7 Chi", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15935",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15936",
            name: "C\u00f9 Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15937",
            name: "C\u00f9 Lao H\u1ea1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15938",
            name: "C\u00f9 Lao Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15939",
            name: "C\u00f9 Lao Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15940",
            name: "C\u1eedu Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15941",
            name: "C\u1eedu Vi\u1ec7t 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15942", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15943", name: "D15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15944", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15945", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15946", name: "D5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15947", name: "D7A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15948",
            name: "D\u00e3 T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15967",
            name: "Di\u00ean S\u01a1n B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15989",
            name: "D\u01b0\u01a1ng Ham",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15990",
            name: "D\u01b0\u01a1ng Hi\u1ebfn Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15991",
            name: "D\u01b0\u01a1ng Qu\u1ea3ng H\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15993",
            name: "D\u01b0\u01a1ng V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15994",
            name: "D\u01b0\u01a1ng V\u00e2n Nga",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15949",
            name: "\u0110\u1eafc L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15950",
            name: "\u0110\u1eafc Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15951", name: "\u0110\u1eb7ng Dung", prefix: "Ph\u1ed1" },
          {
            id: "15952",
            name: "\u0110\u1eb7ng Huy Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15953",
            name: "\u0110\u1eb7ng L\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15954",
            name: "\u0110\u1eb7ng Minh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15955",
            name: "\u0110\u1eb7ng Nguy\u00ean C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15956", name: "\u0110\u1eb7ng T\u1ea5t", prefix: "Ph\u1ed1" },
          {
            id: "15957",
            name: "\u0110\u1eb7ng Thai Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15958",
            name: "\u0110\u1eb7ng Th\u00e1i Th\u00e2n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "15959",
            name: "\u0110\u00e0o Duy Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15960",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15961",
            name: "\u0110\u00e0o V\u0103n Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15962",
            name: "\u0110\u1ea5t L\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15963", name: "\u0110\u1ec7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15964",
            name: "\u0110\u1ec1 P\u00f4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15965",
            name: "\u0110\u00e8o R\u00f9 R\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15966",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15968",
            name: "\u0110\u1ecbnh C\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15969",
            name: "\u0110inh L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15970", name: "\u0110inh Li\u1ec7t", prefix: "Ph\u1ed1" },
          {
            id: "15971",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15972",
            name: "\u0110\u00ecnh V\u0129nh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15973",
            name: "\u0110\u00f4 \u0110\u1ed1c Tuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15974",
            name: "\u0110\u00f4 L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15975",
            name: "\u0110o\u00e0n K\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15976",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15977",
            name: "\u0110o\u00e0n Tr\u1ea7n Nghi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15978",
            name: "\u0110\u1ed9i C\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15979",
            name: "\u0110\u1ed1ng \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15980",
            name: "\u0110\u00f4ng H\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15981",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15982",
            name: "\u0110\u00f4ng M\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15983",
            name: "\u0110\u1ed3ng Nai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15984",
            name: "\u0110\u00f4ng Ph\u01b0\u01a1\u0301c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15985", name: "\u0110T 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15986", name: "\u0110T 741", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15987", name: "\u0110T 747", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "15988",
            name: "\u0110\u01b0\u1eddng \u0110\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "15992",
            name: "\u0110\u01b0\u1eddng t\u1ec9nh 657K",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "15995", name: "F", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15996", name: "F1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15997", name: "F2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15998", name: "F3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "15999", name: "F4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16000", name: "F5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16001",
            name: "G\u00f2 C\u00e2y G\u00e1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16002",
            name: "G\u00f2 C\u00e2y Sung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16003",
            name: "G\u00f2 \u0110\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16004",
            name: "G\u00f2 G\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16005",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16006", name: "H\u00e0 Ra", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16007",
            name: "H\u00e0 Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16008", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "16009",
            name: "H\u1ea3i \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16010", name: "H\u1ea3i Nam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16011",
            name: "H\u00e0n Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16012",
            name: "H\u00e0n Thuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16013", name: "H\u00e0ng C\u00e1", prefix: "Ph\u1ed1" },
          {
            id: "16014",
            name: "H\u00e1t Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16015",
            name: "H\u1eadu Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16016",
            name: "H\u1eadu L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16017",
            name: "Hi\u1ec7p Th\u00e0nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16018",
            name: "H\u1ed3 S\u0129 D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16019",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16020",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16021", name: "Hoa L\u01b0", prefix: "Ph\u1ed1" },
          {
            id: "16022",
            name: "H\u00f2a Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16023",
            name: "Ho\u00e0n Ki\u1ebfm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16024",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16025",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16026",
            name: "Ho\u00e0ng Minh Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16027",
            name: "Ho\u00e0ng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16028",
            name: "Ho\u00e0ng T\u0103ng B\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16029",
            name: "Ho\u00e0ng V\u0103n Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16030",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16031",
            name: "H\u00f2n Ch\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16032",
            name: "H\u00f2n Dung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16033",
            name: "H\u00f2n Ngh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16034",
            name: "H\u00f2n Ngh\u00ea 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16035",
            name: "H\u00f2n Ngh\u00ea 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16036",
            name: "H\u00f2n R\u1edb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16037",
            name: "H\u00f2n R\u1edb 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16038",
            name: "H\u00f2n S\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16039",
            name: "H\u00f2n X\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16040",
            name: "H\u1ed3ng B\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16041",
            name: "H\u1ed3ng L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16042",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16043",
            name: "H\u01b0\u01a1ng \u0110i\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16044",
            name: "H\u01b0\u01a1ng Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16045",
            name: "H\u01b0\u01a1ng L\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16046",
            name: "H\u01b0\u01a1ng l\u1ed9 45",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16047",
            name: "H\u01b0\u01a1ng L\u1ed9 Ng\u1ecdc Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16048",
            name: "H\u01b0\u01a1ng L\u1ed9 Ng\u1ecdc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16049",
            name: "H\u01b0\u01a1ng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16050",
            name: "Huy\u1ec1n Tr\u00e2n C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16051",
            name: "Hu\u1ef3nh T\u1ea5n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16052",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16053",
            name: "Hu\u1ef3nh T\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16054",
            name: "Hu\u1ef3nh T\u1ecbnh C\u1ee7a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16055",
            name: "Hu\u1ef3nh V\u0103n Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16056", name: "K", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16057", name: "K10", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16058",
            name: "K\u00ea G\u00e1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16059", name: "Khe Sanh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16060",
            name: "Khi\u00eau N\u0103ng T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16061",
            name: "Khi\u1ebfu N\u0103ng T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16062",
            name: "Kh\u1edfi Ngh\u0129a B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16063",
            name: "Kh\u00fac Th\u1eeba D\u1ee5",
            prefix: "Ph\u1ed1",
          },
          {
            id: "16064",
            name: "Ki\u1ebfn Thi\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16065", name: "L\u1ea1c An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16066",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16067",
            name: "L\u1ea1c Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16068", name: "Lam S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16069", name: "L\u00e3n \u00d4ng", prefix: "Ph\u1ed1" },
          {
            id: "16070",
            name: "Lang Li\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16071",
            name: "L\u1ea7u \u00d4ng Huy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16072",
            name: "L\u00ea Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16073",
            name: "L\u00ea C\u00f4ng H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16074",
            name: "L\u00ea C\u00f4ng Mi\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16075",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16076",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16077", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16078",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16079",
            name: "L\u00ea Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16080",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16081", name: "L\u00ea Thanh Ngh\u1ecb", prefix: "Ph\u1ed1" },
          {
            id: "16082",
            name: "L\u00ea Th\u00e0nh Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16083",
            name: "L\u00ea Th\u00e1nh T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16084",
            name: "L\u00ea V\u0103n Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16085",
            name: "L\u00ea V\u0103n Mi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16086",
            name: "L\u00ea V\u0103n T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16087",
            name: "L\u00ea V\u0103n T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16088",
            name: "Li\u00ean Hoa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16089",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16090",
            name: "L\u1ed9c H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16091",
            name: "L\u01b0 C\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16092",
            name: "L\u01b0 Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16093",
            name: "L\u01b0\u01a1ng \u0110\u1eafc B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16094",
            name: "L\u01b0\u01a1ng \u0110\u1ecbnh C\u1ee7a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "16095",
            name: "L\u01b0\u01a1ng Ng\u1ecdc Quy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16096",
            name: "L\u01b0\u01a1ng Nh\u1eef H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16097",
            name: "L\u01b0\u01a1ng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16098",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16099",
            name: "L\u01b0\u01a1ng V\u0103n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16100",
            name: "L\u01b0u H\u1eefu Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16101",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16102",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16103",
            name: "L\u00fd Ph\u1ee5c Man",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16104",
            name: "L\u00fd Qu\u1ed1c S\u01b0",
            prefix: "Ph\u1ed1",
          },
          {
            id: "16105",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16106",
            name: "L\u00fd Th\u00e1nh T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16107",
            name: "L\u00fd Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16108",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "16109",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16110",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16111",
            name: "Mai An Ti\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16112",
            name: "Mai L\u00e3o B\u1ea1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16113",
            name: "Mai Th\u1ecb D\u00f5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16114",
            name: "Mai Xu\u00e2n Th\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16115",
            name: "M\u00e1y N\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16116", name: "M\u00ea Linh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16117",
            name: "Mi\u1ebfu B\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16118",
            name: "M\u01b0\u01a1ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16119", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16120", name: "N5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16121",
            name: "Ng\u00f4 \u0110\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16122",
            name: "Ng\u00f4 \u0110\u1ee9c K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16123",
            name: "Ng\u00f4 Gia Kh\u1ea3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16124",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16125",
            name: "Ng\u00f4 Lan Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16126",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16127",
            name: "Ng\u00f4 S\u0129 Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16128",
            name: "Ng\u00f4 T\u1ea5t T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16129",
            name: "Ng\u00f4 Th\u1eddi Nhi\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16130",
            name: "Ng\u00f4 V\u0103n S\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16131",
            name: "Ng\u1ecdc H\u00e2n C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16132",
            name: "Ng\u1ecdc H\u1ed3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16133",
            name: "Ng\u1ecdc H\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16134",
            name: "Ng\u1ecdc Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16135",
            name: "Nguy\u1ec5n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16136",
            name: "Nguy\u1ec5n B\u1eb7c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16137",
            name: "Nguy\u1ec5n Bi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16138",
            name: "Nguy\u1ec5n Bi\u1ec3u B1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16139",
            name: "Nguy\u1ec5n Bi\u1ec3u B2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16140",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16141",
            name: "Nguy\u1ec5n B\u1eedu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16142",
            name: "Nguy\u1ec5n C\u1ea3nh Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16143",
            name: "Nguy\u1ec5n Cao Luy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16144",
            name: "Nguy\u1ec5n Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16145",
            name: "Nguy\u1ec5n Ch\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16146",
            name: "Nguy\u1ec5n C\u01a1 Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16147",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16151",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16152",
            name: "Nguy\u1ec5n D\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16155",
            name: "Nguy\u1ec5n Duy Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16148",
            name: "Nguy\u1ec5n \u0110\u1ecba L\u00f4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16149",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16150",
            name: "Nguy\u1ec5n \u0110\u1ed7 Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16153",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16154",
            name: "Nguy\u1ec5n \u0110\u1ee9c Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16156",
            name: "Nguy\u1ec5n Gia Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16157",
            name: "Nguy\u1ec5n Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16158",
            name: "Nguy\u1ec5n Ho\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16159",
            name: "Nguy\u1ec5n H\u1ed3ng S\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16160",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16161",
            name: "Nguy\u1ec5n H\u1eefu Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16162",
            name: "Nguy\u1ec5n H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16163",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16164",
            name: "Nguy\u1ec5n Huy Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16165",
            name: "Nguy\u1ec5n Kh\u1eafc Vi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16166",
            name: "Nguy\u1ec5n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16167",
            name: "Nguy\u1ec5n Kh\u00e1nh To\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16168",
            name: "Nguy\u1ec5n Kho\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16169",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16170",
            name: "Nguy\u1ec5n L\u1ed9 Tr\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16171",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16172",
            name: "Nguy\u1ec5n Phan Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16173",
            name: "Nguy\u1ec5n Phi Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16174",
            name: "Nguy\u1ec5n Phong S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16175",
            name: "Nguy\u1ec5n Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16176",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16177",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16178",
            name: "Nguy\u1ec5n Th\u00e0nh Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16179",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16180",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16181",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "16182",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16183",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16184",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16185",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16186",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16187",
            name: "Nguy\u1ec5n V\u0103n B\u1ea3y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16188",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16189",
            name: "Nguy\u1ec5n V\u0103n \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16190",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16191",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16192",
            name: "Nguy\u1ec5n Xi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16193",
            name: "Nguy\u1ec5n Xu\u00e2n H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16194", name: "Nh\u00e0 Th\u1edd", prefix: "Ph\u1ed1" },
          {
            id: "16195",
            name: "Nh\u00e0 Vu\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16196",
            name: "Nh\u00e2n V\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16197",
            name: "Nh\u1eadt L\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16198",
            name: "Nh\u1eadt Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16199",
            name: "Nh\u1ecb H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16200",
            name: "N\u00fai C\u1ea5m 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16201",
            name: "N\u00fai M\u1ed9t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16202",
            name: "N\u00fai S\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16203", name: "NV05", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16204", name: "NV1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16205", name: "NV12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16206", name: "NV5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16207", name: "P1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16208", name: "P2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16209", name: "P3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16210", name: "Pasteur", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16211",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16212",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16213",
            name: "Ph\u1ea1m Ph\u00fa Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16214",
            name: "Ph\u1ea1m Th\u1ecb B\u1ea5t",
            prefix: "Ph\u1ed1",
          },
          { id: "16215", name: "Ph\u1ea1m Tu", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16216",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16217",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16218",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16219",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16220",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16221",
            name: "Phan Huy C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16222",
            name: "Phan Huy \u00cdch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16223",
            name: "Phan M\u1ea1nh Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16224",
            name: "Phan Nh\u01b0 C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16225",
            name: "Phan V\u0103n V\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16226", name: "Phan Vinh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16227",
            name: "Ph\u1eadt H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16228",
            name: "Ph\u00f3 \u0110\u1ee9c Ch\u00ednh",
            prefix: "Ph\u1ed1",
          },
          { id: "16229", name: "Phong Ch\u00e2u", prefix: "Ph\u1ed1" },
          {
            id: "16230",
            name: "Ph\u00f2ng Kh\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16231",
            name: "Ph\u00fa B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16232",
            name: "Ph\u00f9 \u0110\u1ed5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16233",
            name: "Ph\u00fa \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16234",
            name: "Ph\u00fa N\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16235",
            name: "Ph\u00fa N\u00f4ng B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16236", name: "Ph\u00f9 Sa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16237",
            name: "Ph\u00fa Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16238",
            name: "Ph\u00fa X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16239",
            name: "Ph\u00fac S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16240", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "16241",
            name: "Ph\u00f9ng Kh\u1eafc Khoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16242",
            name: "Ph\u01b0\u1edbc H\u1ea1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16243",
            name: "Ph\u01b0\u1edbc H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16244", name: "Ph\u01b0\u1edbc Hu\u1ec7", prefix: "Ph\u1ed1" },
          {
            id: "16245",
            name: "Ph\u01b0\u1edbc Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16246",
            name: "Ph\u01b0\u1edbc T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16247",
            name: "Ph\u01b0\u1edbc T\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16248",
            name: "Ph\u01b0\u01a1ng C\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16249",
            name: "Ph\u01b0\u01a1ng Ma",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16250", name: "Ph\u01b0\u01a1ng Mai", prefix: "Ph\u1ed1" },
          {
            id: "16251",
            name: "Ph\u01b0\u01a1ng S\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16252",
            name: "Qu\u00e2n Tr\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16253",
            name: "Quang D\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16254",
            name: "Qu\u1ea3ng \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16255", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16256",
            name: "Qu\u1ed1c L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16257",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16258",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16259",
            name: "Qu\u1ed1c l\u1ed9 1B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16260",
            name: "Qu\u1ed1c l\u1ed9 1C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16261", name: "Qu\u1ed1c Tu\u1ea5n", prefix: "Ph\u1ed1" },
          {
            id: "16262",
            name: "Sao Bi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16263", name: "S\u1eaft", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16264", name: "Sinh Trung", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16265", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16266", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16267", name: "S\u1ed1 10B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16268", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16269", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16270", name: "S\u1ed1 12A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16271", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16272", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16273", name: "S\u1ed1 14A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16274", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16275", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16276", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16277", name: "S\u1ed1 18A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16278", name: "S\u1ed1 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16279", name: "S\u1ed1 1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16280", name: "S\u1ed1 1B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16281", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16282", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16283", name: "S\u1ed1 21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16284", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16285", name: "S\u1ed1 23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16286", name: "S\u1ed1 24", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16287", name: "S\u1ed1 25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16288", name: "S\u1ed1 26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16289", name: "S\u1ed1 27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16290", name: "S\u1ed1 28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16291", name: "S\u1ed1 29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16292", name: "S\u1ed1 2A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16293", name: "S\u1ed1 2B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16294", name: "S\u1ed1 2C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16295", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16296", name: "S\u1ed1 31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16297", name: "S\u1ed1 33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16298", name: "S\u1ed1 34", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16299", name: "S\u1ed1 36", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16300", name: "S\u1ed1 3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16301", name: "S\u1ed1 3B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16302", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16303", name: "S\u1ed1 4A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16304", name: "S\u1ed1 4B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16305", name: "S\u1ed1 4C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16306", name: "S\u1ed1 4D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16307", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16308", name: "S\u1ed1 5A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16309", name: "S\u1ed1 5B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16310", name: "S\u1ed1 5C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16311", name: "S\u1ed1 5E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16312", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16313", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16314", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16315", name: "S\u1ed1 8A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16316", name: "S\u1ed1 8C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16317", name: "S\u1ed1 8E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16318", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16319", name: "S\u1ed1 A1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16320", name: "S\u1ed1 A3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16321", name: "S\u1ed1 A3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16322", name: "S\u1ed1 B5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16323", name: "S\u1ed1 T6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16324", name: "S\u01a1n Ca", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16325",
            name: "S\u01a1n Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16326",
            name: "S\u00f4ng C\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16327",
            name: "S\u1eed Hy Nhan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16328",
            name: "S\u01b0 V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16346", name: "T-3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16329", name: "T1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16330", name: "T11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16331", name: "T12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16332", name: "T13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16333", name: "T15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16334", name: "T16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16335", name: "T17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16336", name: "T18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16337", name: "T19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16338", name: "T2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16339", name: "T21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16340", name: "T22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16341", name: "T23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16342", name: "T27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16343", name: "T28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16344", name: "T3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16345", name: "T3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16347", name: "T4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16348", name: "T5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16349", name: "T6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16350", name: "T7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16351", name: "T8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16352", name: "T9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16353", name: "T\u00e2n An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16354",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16355",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16356",
            name: "T\u1ea3n \u0110\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16357",
            name: "T\u00e2n H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16358",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16359",
            name: "T\u00e2n Trang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16360",
            name: "T\u00e2n Tr\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16361",
            name: "T\u1ea3n Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16362",
            name: "T\u0103ng B\u1ea1t H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16363", name: "T\u00e2y Nam", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16364", name: "T\u1ebf Hanh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16365",
            name: "Th\u00e1i Khang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16366", name: "Th\u00e1i Nguy\u00ean", prefix: "Ph\u1ed1" },
          {
            id: "16367",
            name: "Th\u00e1i Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16368",
            name: "Th\u00e1i Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16369",
            name: "Th\u00e2n Nh\u00e2n Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16370",
            name: "Th\u1ea1nh L\u1ed9c 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16371", name: "Thanh Nam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16372",
            name: "Th\u1ea1nh Xu\u00e2n 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16373",
            name: "Th\u1ea1nh Xu\u00e2n 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16374",
            name: "Th\u00e1p B\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16375", name: "Thi S\u00e1ch", prefix: "Ph\u1ed1" },
          {
            id: "16376",
            name: "Th\u00edch B\u1eedu \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16377",
            name: "Th\u00edch Qu\u1ea3ng \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16378",
            name: "Tho\u1ea1i Ng\u1ecdc H\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16379",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16380",
            name: "Th\u1ee7y X\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16381",
            name: "Ti\u1ec1n Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16382",
            name: "T\u1ec9nh l\u1ed9 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16383",
            name: "T\u1ec9nh l\u1ed9 657",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16384",
            name: "T\u1ec9nh l\u1ed9 7A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16385",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16386",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16387",
            name: "T\u1ed1 H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16388",
            name: "T\u00f4 K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16389",
            name: "T\u00f4 V\u0129nh Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16390",
            name: "T\u00f4n \u0110\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16391",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "16392",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16393",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16394",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16395",
            name: "Tr\u1ea7n \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16396",
            name: "Tr\u1ea7n \u0110\u0103ng Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16397",
            name: "Tr\u1ea7n \u0110o\u00e0n Nghi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16398",
            name: "Tr\u1ea7n \u0110\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16399",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16400",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16401",
            name: "Tr\u1ea7n Kh\u00e1nh To\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16402",
            name: "Tr\u1ea7n Kh\u00e1t Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16403",
            name: "Tr\u1ea7n Kim H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16404",
            name: "Tr\u1ea7n L\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16405",
            name: "Tr\u1ea7n Mai Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16406",
            name: "Tr\u1ea7n Nam Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16407",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "16408",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16409",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16410",
            name: "Tr\u1ea7n Ph\u00fa B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16412",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16411",
            name: "Tr\u1ea7n Quang \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16413",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16414",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16415",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16416",
            name: "Tr\u1ea7n S\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16417",
            name: "Tr\u1ea7n Th\u1ecb T\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16418",
            name: "Tr\u1ea7n V\u0103n \u01a0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16419",
            name: "Tr\u00edch S\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16420",
            name: "Tri\u1ec7u Quang Ph\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16421",
            name: "Tri\u1ec7u Qu\u1ed1c \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16422",
            name: "Tr\u1ecbnh Ho\u00e0i \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16423",
            name: "Tr\u1ecbnh Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16424",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16425",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16426",
            name: "Tr\u01b0\u1eddng Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16427",
            name: "Tr\u01b0\u1eddng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16428",
            name: "Tr\u01b0\u1eddng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16429",
            name: "Tr\u01b0\u01a1ng T\u1ea5n Sang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16430",
            name: "Tr\u01b0\u01a1ng V\u0103n \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16431",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16432", name: "V1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16433", name: "V2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16434",
            name: "V\u00e2n \u0110\u1ed3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16435", name: "V\u1ea1n Hoa", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16436", name: "V\u1ea1n Ki\u1ebfp", prefix: "Ph\u1ed1" },
          { id: "16437", name: "V\u0103n Nga", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16438",
            name: "V\u1ea1n Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16439",
            name: "Vi\u1ec7t B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16440",
            name: "V\u0129nh \u0110i\u1ec1m Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16441",
            name: "V\u0129nh \u0110i\u1ec1m Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16442",
            name: "V\u0129nh H\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16443",
            name: "V\u0129nh Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16444",
            name: "V\u0129nh Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16445",
            name: "V\u0129nh Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16446",
            name: "V\u0129nh X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16447", name: "V\u00f5 Cang", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16448", name: "V\u00f5 Canh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16449",
            name: "V\u00f5 \u0110\u00ecnh T\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16450",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16451",
            name: "V\u00f5 Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16452",
            name: "V\u00f5 Trung Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16453",
            name: "V\u00f5 Tr\u01b0\u1eddng To\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16454",
            name: "V\u00f5 Tr\u01b0\u1eddng To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16455",
            name: "V\u00f5 V\u0103n K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16456",
            name: "V\u00f5 V\u0103n T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16457", name: "V\u0169 H\u1eefu", prefix: "Ph\u1ed1" },
          {
            id: "16458",
            name: "V\u0169 Xu\u00e2n Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16459",
            name: "V\u01b0\u1eddn D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16460",
            name: "V\u01b0\u1eddn Thu\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16461",
            name: "V\u01b0\u1eddn Xo\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16462", name: "Xa La", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16463",
            name: "X\u00f3m Chi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16464",
            name: "Xu\u00e2n L\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16465",
            name: "Xu\u00e2n L\u1ea1c 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16466",
            name: "Xu\u00e2n S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16467",
            name: "Xu\u00e2n Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16468",
            name: "X\u01b0\u01a1ng Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16469",
            name: "Y\u00ean Th\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16470", name: "Yersin", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16471",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2392",
            name: "AB Central Square",
            lat: "12.240833282471",
            lng: "109.19629669189",
          },
          {
            id: "2393",
            name: "Ariyana Smart Condotel Nha Trang",
            lat: "12.245812416077",
            lng: "109.19466400146",
          },
          {
            id: "2394",
            name: "B\u1eafc V\u0129nh H\u1ea3i",
            lat: "12.286805152893",
            lng: "109.19386291504",
          },
          {
            id: "2395",
            name: "Bavico Nha Trang",
            lat: "12.254682540894",
            lng: "109.19360351562",
          },
          {
            id: "2396",
            name: "Cham Oasis Nha Trang",
            lat: "12.265616416931",
            lng: "109.19041442871",
          },
          {
            id: "2397",
            name: "C\u1ed3n T\u00e2n L\u1eadp",
            lat: "12.253156661987",
            lng: "109.19659423828",
          },
          {
            id: "2398",
            name: "Diamond Bay City",
            lat: "12.17014503479",
            lng: "109.19689941406",
          },
          {
            id: "2400",
            name: "Dragon Fairy Hotel & Residence",
            lat: "12.210270881653",
            lng: "109.21360778809",
          },
          {
            id: "2401",
            name: "D\u1ef1 \u00e1n s\u00e2n bay Nha Trang",
            lat: "12.226748466492",
            lng: "109.20031738281",
          },
          {
            id: "2399",
            name: "\u0110\u00f4 th\u1ecb Ho\u00e0ng Long",
            lat: "12.224924087524",
            lng: "109.18409729004",
          },
          {
            id: "2402",
            name: "Garden Bay",
            lat: "12.188546180725",
            lng: "109.17203521729",
          },
          {
            id: "2403",
            name: "Gi\u00e1ng H\u01b0\u01a1ng",
            lat: "12.233981132507",
            lng: "109.17167663574",
          },
          {
            id: "2404",
            name: "Gold Coast Nha Trang",
            lat: "12.247921943665",
            lng: "109.19505310059",
          },
          {
            id: "2405",
            name: "Haborizon Nha Trang",
            lat: "12.200478553772",
            lng: "109.19213104248",
          },
          {
            id: "2406",
            name: "Havana Nha Trang Plaza",
            lat: "12.242969512939",
            lng: "109.19627380371",
          },
          {
            id: "2407",
            name: "Ho\u00e0ng Ph\u00fa Nha Trang",
            lat: "21.024118423462",
            lng: "105.8512878418",
          },
          {
            id: "2408",
            name: "H\u00f2n R\u1edb 1",
            lat: "12.204133033752",
            lng: "109.18695831299",
          },
          {
            id: "2409",
            name: "HQC Nha Trang",
            lat: "12.28576374054",
            lng: "109.19008636475",
          },
          {
            id: "2410",
            name: "HUD Building",
            lat: "12.233741760254",
            lng: "109.19487762451",
          },
          {
            id: "2411",
            name: "K\u0110T An B\u00ecnh T\u00e2n",
            lat: "12.215621948242",
            lng: "109.18027496338",
          },
          {
            id: "2412",
            name: "K\u0110T bi\u1ec3n An Vi\u00ean",
            lat: "12.197835922241",
            lng: "109.21228027344",
          },
          {
            id: "2413",
            name: "K\u0110T L\u00ea H\u1ed3ng Phong I",
            lat: "12.228963851929",
            lng: "109.17978668213",
          },
          {
            id: "2414",
            name: "K\u0110T L\u00ea H\u1ed3ng Phong II",
            lat: "12.249237060547",
            lng: "109.17469024658",
          },
          {
            id: "2415",
            name: "K\u0110T V\u0129nh H\u00f2a",
            lat: "12.294993400574",
            lng: "109.21566772461",
          },
          {
            id: "2416",
            name: "Khu \u0111\u00f4 th\u1ecb Nam V\u0129nh H\u1ea3i",
            lat: "12.279216766357",
            lng: "109.18641662598",
          },
          {
            id: "2417",
            name: "Khu \u0111\u00f4 th\u1ecb Ven S\u00f4ng T\u1eafc",
            lat: "12.232712745667",
            lng: "109.16863250732",
          },
          {
            id: "2418",
            name: "Lighthouse Complex Tower",
            lat: "12.242749214172",
            lng: "109.19421386719",
          },
          {
            id: "2419",
            name: "Lotus Island",
            lat: "12.206892967224",
            lng: "109.18560791016",
          },
          {
            id: "2420",
            name: "Maple Nha Trang",
            lat: "12.23766708374",
            lng: "109.19624328613",
          },
          {
            id: "2421",
            name: "Marina Hill Villa",
            lat: "12.2903175354",
            lng: "109.17964935303",
          },
          {
            id: "2422",
            name: "Marina Suites",
            lat: "12.252114295959",
            lng: "109.19264984131",
          },
          {
            id: "2423",
            name: "Mipeco Nha Trang",
            lat: "12.214150428772",
            lng: "109.20991516113",
          },
          {
            id: "2424",
            name: "M\u01b0\u1eddng Thanh 04 Tr\u1ea7n Ph\u00fa",
            lat: "12.258794784546",
            lng: "109.1974029541",
          },
          {
            id: "2425",
            name: "M\u01b0\u1eddng Thanh Nha Trang",
            lat: "12.290044784546",
            lng: "109.20583343506",
          },
          {
            id: "2426",
            name: "M\u01b0\u1eddng Thanh Qu\u00ea H\u01b0\u01a1ng",
            lat: "12.236655235291",
            lng: "109.19683074951",
          },
          {
            id: "2427",
            name: "M\u01b0\u1eddng Thanh Vi\u1ec5n Tri\u1ec1u",
            lat: "12.274140357971",
            lng: "109.20196533203",
          },
          {
            id: "2428",
            name: "M\u1ef9 Gia",
            lat: "12.239008903503",
            lng: "109.16455078125",
          },
          {
            id: "2429",
            name: "Napoleon Castle I",
            lat: "12.269981384277",
            lng: "109.20238494873",
          },
          {
            id: "2430",
            name: "Nh\u00e0 \u1edf x\u00e3 h\u1ed9i B\u00ecnh Ph\u00fa 2",
            lat: "12.29013633728",
            lng: "109.19446563721",
          },
          {
            id: "2431",
            name: "Nha Trang Center",
            lat: "12.248020172119",
            lng: "109.19596862793",
          },
          {
            id: "2432",
            name: "Nha Trang City Central",
            lat: "12.251400947571",
            lng: "109.1925201416",
          },
          {
            id: "2433",
            name: "Nha Trang Golden Peak",
            lat: "12.246380805969",
            lng: "109.19583129883",
          },
          {
            id: "2434",
            name: "Nha Trang Green Hill Villa",
            lat: "12.232712745667",
            lng: "109.16863250732",
          },
          {
            id: "2435",
            name: "Ocean Front Villas",
            lat: "12.213539123535",
            lng: "109.20929718018",
          },
          {
            id: "2436",
            name: "Ocean Gate Nha Trang",
            lat: "12.247122764587",
            lng: "109.19399261475",
          },
          {
            id: "2437",
            name: "Panorama City Nha Trang",
            lat: "12.243470191956",
            lng: "109.17720031738",
          },
          {
            id: "2438",
            name: "Panorama Nha Trang",
            lat: "12.238742828369",
            lng: "109.19528961182",
          },
          {
            id: "2439",
            name: "PH Nha Trang",
            lat: "12.209913253784",
            lng: "109.20170593262",
          },
          {
            id: "2440",
            name: "Ph\u01b0\u1edbc Long",
            lat: "12.213057518005",
            lng: "109.19040679932",
          },
          {
            id: "2441",
            name: "Royal Garden",
            lat: "12.236000061035",
            lng: "109.17604064941",
          },
          {
            id: "2442",
            name: "Scenia Bay Nha Trang",
            lat: "12.297069549561",
            lng: "109.21967315674",
          },
          {
            id: "2443",
            name: "Stellar Nha Trang",
            lat: "12.196161270142",
            lng: "109.20932006836",
          },
          {
            id: "2444",
            name: "Swisstouches La Luna Resort",
            lat: "12.295673370361",
            lng: "109.21011352539",
          },
          {
            id: "2445",
            name: "TD Plaza Nha Trang",
            lat: "12.243103027344",
            lng: "109.19555664062",
          },
          {
            id: "2446",
            name: "The Butterfly Nha Trang",
            lat: "12.2583360672",
            lng: "109.19583129883",
          },
          {
            id: "2447",
            name: "The Costa Nha Trang",
            lat: "12.244786262512",
            lng: "109.19631958008",
          },
          {
            id: "2448",
            name: "Tropicana Nha Trang",
            lat: "12.239227294922",
            lng: "109.19677734375",
          },
          {
            id: "2449",
            name: "UPlaza Nha Trang",
            lat: "12.275593757629",
            lng: "109.2014541626",
          },
          {
            id: "2450",
            name: "V\u00e2n Phong Tower",
            lat: "12.237535476685",
            lng: "109.19553375244",
          },
          {
            id: "2451",
            name: "VCN - Ph\u01b0\u1edbc Long",
            lat: "12.223214149475",
            lng: "109.18099212646",
          },
          {
            id: "2452",
            name: "VCN Ph\u01b0\u1edbc H\u1ea3i",
            lat: "12.23826789856",
            lng: "109.17714691162",
          },
          {
            id: "2453",
            name: "Vincity Nha Trang",
            lat: "12.238791465759",
            lng: "109.19674682617",
          },
          {
            id: "2454",
            name: "V\u0129nh \u0110i\u1ec1m Trung",
            lat: "12.256568908691",
            lng: "109.16729736328",
          },
          {
            id: "2455",
            name: "Vinpearl Beach Front Condotel",
            lat: "12.233153343201",
            lng: "109.19731140137",
          },
          {
            id: "2456",
            name: "Vinpearl Empire Condotel",
            lat: "12.243810653687",
            lng: "109.19281768799",
          },
          {
            id: "2457",
            name: "Vinpearl Luxury Nha Trang",
            lat: "12.211570739746",
            lng: "109.24368286133",
          },
          {
            id: "2458",
            name: "Vinpearl Nha Trang Bay Resort & Villas",
            lat: "12.207396507263",
            lng: "109.25561523438",
          },
          {
            id: "2459",
            name: "Vinpearl Premium Golf Land",
            lat: "12.205724716187",
            lng: "109.27576446533",
          },
          {
            id: "2460",
            name: "Vinpearl Resort Nha Trang",
            lat: "12.221298217773",
            lng: "109.2466583252",
          },
          {
            id: "2461",
            name: "Virgo Nha Trang",
            lat: "12.238322257996",
            lng: "109.19213867188",
          },
        ],
      },
      {
        id: "88",
        name: "Ninh H\u00f2a",
        wards: [
          { id: "1339", name: "Di\u00ean To\u00e0n", prefix: "X\u00e3" },
          { id: "1340", name: "Ninh An", prefix: "X\u00e3" },
          { id: "1341", name: "Ninh B\u00ecnh", prefix: "X\u00e3" },
          { id: "1343", name: "Ninh Di\u00eam", prefix: "Ph\u01b0\u1eddng" },
          { id: "1342", name: "Ninh \u0110a", prefix: "Ph\u01b0\u1eddng" },
          { id: "1344", name: "Ninh \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "1345", name: "Ninh Giang", prefix: "Ph\u01b0\u1eddng" },
          { id: "1346", name: "Ninh H\u00e0", prefix: "Ph\u01b0\u1eddng" },
          { id: "1347", name: "Ninh H\u1ea3i", prefix: "Ph\u01b0\u1eddng" },
          { id: "1348", name: "Ninh Hi\u1ec7p", prefix: "Ph\u01b0\u1eddng" },
          { id: "1349", name: "Ninh H\u01b0ng", prefix: "X\u00e3" },
          { id: "1350", name: "Ninh \u00cdch", prefix: "X\u00e3" },
          { id: "1351", name: "Ninh L\u1ed9c", prefix: "X\u00e3" },
          { id: "1352", name: "Ninh Ph\u00fa", prefix: "X\u00e3" },
          { id: "1353", name: "Ninh Ph\u1ee5ng", prefix: "X\u00e3" },
          { id: "1354", name: "Ninh Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "1355", name: "Ninh Quang", prefix: "X\u00e3" },
          { id: "1356", name: "Ninh Sim", prefix: "X\u00e3" },
          { id: "1357", name: "Ninh S\u01a1n", prefix: "X\u00e3" },
          { id: "1358", name: "Ninh T\u00e2n", prefix: "X\u00e3" },
          { id: "1359", name: "Ninh T\u00e2y", prefix: "X\u00e3" },
          { id: "1360", name: "Ninh Th\u00e2n", prefix: "X\u00e3" },
          { id: "1361", name: "Ninh Th\u1ecd", prefix: "X\u00e3" },
          { id: "1362", name: "Ninh Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "1363", name: "Ninh Th\u1ee7y", prefix: "Ph\u01b0\u1eddng" },
          { id: "1364", name: "Ninh Trung", prefix: "X\u00e3" },
          { id: "1365", name: "Ninh V\u00e2n", prefix: "X\u00e3" },
          { id: "1366", name: "Ninh Xu\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "16472", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16473", name: "16/7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16474", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16475", name: "2/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16476", name: "26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16477", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16478", name: "B1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16479",
            name: "B\u00e1 H\u00e0 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16480",
            name: "B\u00e1 H\u00e0 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16481", name: "B\u1eafc Nam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16482",
            name: "B\u1ebfn \u0110\u00f2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16483",
            name: "C\u00f4ng L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16484",
            name: "D\u00e3 T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16485",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16486",
            name: "\u0110\u1ed1ng \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16487", name: "\u0110T 652B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16488",
            name: "\u0110\u01b0\u1eddng s\u00e2n bay",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16489",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16490",
            name: "H\u1ed3 Th\u1ecb H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16491",
            name: "H\u00f2n Kh\u00f3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16492",
            name: "Hu\u1ef3nh \u0110ai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16493", name: "K10", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16494",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16495",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16496",
            name: "Minh M\u1ea1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16497",
            name: "M\u01b0\u1eddi S\u00e1u Th\u00e1ng B\u1ea3y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16498",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16499",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16500",
            name: "Nguy\u1ec5n Th\u1ecb Ng\u1ecdc Oanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16501",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16502",
            name: "Qu\u1ea3ng Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16503",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16504",
            name: "Qu\u1ed1c L\u1ed9 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16505",
            name: "Qu\u1ed1c L\u1ed9 26B-1B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16506", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16507", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16508",
            name: "S\u00f4ng C\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16509",
            name: "T\u00e2n \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16510",
            name: "T\u00e2n Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16511",
            name: "Th\u00edch Qu\u1ea3ng \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16512",
            name: "T\u1ec9nh L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16513",
            name: "T\u1ec9nh l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16514",
            name: "T\u1ec9nh L\u1ed9 26B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16515",
            name: "T\u1ec9nh L\u1ed9 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16516",
            name: "T\u1ec9nh l\u1ed9 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16517",
            name: "T\u1ec9nh l\u1ed9 652B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16518",
            name: "T\u1ec9nh l\u1ed9 652D",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16519",
            name: "Tr\u1ea7n Qu\u1ed1c Tu\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16520",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16521",
            name: "Tu\u00e2n Th\u1eeba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16522",
            name: "V\u00f5 T\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "89",
        name: "Tr\u01b0\u1eddng Sa",
        wards: [
          { id: "1367", name: "Sinh T\u1ed3n", prefix: "X\u00e3" },
          { id: "1368", name: "Song T\u1eed T\u00e2y", prefix: "X\u00e3" },
          {
            id: "1369",
            name: "Tr\u01b0\u1eddng Sa",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "90",
        name: "V\u1ea1n Ninh",
        wards: [
          { id: "1370", name: "\u0110\u1ea1i L\u00e3nh", prefix: "X\u00e3" },
          { id: "1371", name: "V\u1ea1n B\u00ecnh", prefix: "X\u00e3" },
          {
            id: "1372",
            name: "V\u1ea1n Gi\u00e3",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "1373", name: "V\u1ea1n H\u01b0ng", prefix: "X\u00e3" },
          { id: "1374", name: "V\u1ea1n Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "1375", name: "V\u1ea1n Long", prefix: "X\u00e3" },
          { id: "1376", name: "V\u1ea1n L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "1377", name: "V\u1ea1n Ph\u00fa", prefix: "X\u00e3" },
          { id: "1378", name: "V\u1ea1n Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "1379", name: "V\u1ea1n Th\u1eafng", prefix: "X\u00e3" },
          { id: "1380", name: "V\u1ea1n Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "1381", name: "V\u1ea1n Th\u1ecd", prefix: "X\u00e3" },
          { id: "1382", name: "Xu\u00e2n S\u01a1n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "16523", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16524", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16525", name: "358", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16526", name: "651", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16527", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16528", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "16529",
            name: "\u0110i\u1ec7p S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16530", name: "\u0110T 651", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16531", name: "\u0110T 651E", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16532",
            name: "\u0110\u01b0\u1eddng m\u00f2n \u0111i M\u0169i \u0110\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16533",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16534", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "16535",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16536",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16537",
            name: "Ho\u00e0nh H\u1eefu Ch\u1ea5p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16538",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16539",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16540",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16541",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16542",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          { id: "16543", name: "Ma T\u00e2y", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16544",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16545",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16546",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16547",
            name: "Nguy\u1ec5n H\u1ed3ng S\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16548",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16549",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16550",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16551",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16552",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16553",
            name: "Qu\u1ed1c L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16554",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16555", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16556",
            name: "T\u00e2n \u0110\u1ee9c \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16557",
            name: "T\u1ec9nh L\u1ed9 651",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16558",
            name: "T\u1ec9nh l\u1ed9 651E",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16559",
            name: "Tr\u1ea7n \u0110\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16560",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16561",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16562",
            name: "T\u00fa X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16563",
            name: "V\u0129nh Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "7",
    code: "HP",
    name: "H\u1ea3i Ph\u00f2ng",
    districts: [
      {
        id: "91",
        name: "An D\u01b0\u01a1ng",
        wards: [
          {
            id: "1384",
            name: "An D\u01b0\u01a1ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "1383", name: "An \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "1385", name: "An H\u1ea3i", prefix: "Ph\u01b0\u1eddng" },
          { id: "1386", name: "An H\u00f2a", prefix: "X\u00e3" },
          { id: "1387", name: "An H\u1ed3ng", prefix: "Ph\u01b0\u1eddng" },
          { id: "1388", name: "An H\u01b0ng", prefix: "X\u00e3" },
          { id: "1389", name: "B\u1eafc S\u01a1n", prefix: "X\u00e3" },
          { id: "1390", name: "\u0110\u1ea1i B\u1ea3n", prefix: "X\u00e3" },
          {
            id: "1391",
            name: "\u0110\u1eb7ng C\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "1392", name: "\u0110\u1ed3ng Th\u00e1i", prefix: "X\u00e3" },
          { id: "1393", name: "H\u1ed3ng phong", prefix: "X\u00e3" },
          { id: "1394", name: "H\u1ed3ng Th\u00e1i", prefix: "X\u00e3" },
          { id: "1395", name: "H\u1ed3ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "1396", name: "L\u00ea L\u1ee3i", prefix: "X\u00e3" },
          { id: "1397", name: "L\u00ea Thi\u00ean", prefix: "X\u00e3" },
          { id: "1398", name: "Nam S\u01a1n", prefix: "X\u00e3" },
          { id: "1399", name: "Qu\u1ed1c Tu\u1ea5n", prefix: "X\u00e3" },
          { id: "1400", name: "T\u00e2n Ti\u1ebfn", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "16564", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16565", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16566", name: "203", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16567", name: "208", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16568", name: "351", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16569", name: "353", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16570", name: "442", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16571", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16572", name: "52", prefix: "Ph\u1ed1" },
          { id: "16573", name: "91", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16574", name: "A2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16575", name: "An B\u00ecnh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16577",
            name: "An D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16578",
            name: "An D\u01b0\u01a1ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16579",
            name: "An D\u01b0\u01a1ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16576",
            name: "An \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16580",
            name: "An Kim H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16581", name: "An Ph\u00fa", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16582", name: "An Trang", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16583", name: "An Trang 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16584", name: "B\u1ea1ch Mai", prefix: "Ph\u1ed1" },
          { id: "16585", name: "B\u00ecnh An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16586",
            name: "C\u00e1ch H\u1ea1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16587",
            name: "C\u00e1ch Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16588",
            name: "C\u00e1i T\u1eaft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16589",
            name: "Ch\u00f9a Ngh\u00e8o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16590",
            name: "C\u1ed1ng \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16591", name: "Do Nha", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16592",
            name: "\u0110\u1ed3ng Di\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16593",
            name: "\u0110\u00f4ng \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16594",
            name: "\u0110\u1ed3ng Gi\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16595", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "16596",
            name: "Ho\u00e0ng Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16597",
            name: "Ki\u1ec1u Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16598",
            name: "Ki\u1ec1u Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16599",
            name: "L\u00ea L\u00e1c 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16600",
            name: "L\u00f2 Vi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16601",
            name: "L\u01b0\u01a1ng Qu\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16602",
            name: "M\u00e1ng N\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16603",
            name: "M\u00e1ng Th\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16604",
            name: "M\u00e1y N\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16605", name: "Minh Kha", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16606",
            name: "M\u1ef9 Tranh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16607",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16608",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16609",
            name: "Nh\u01b0 Ki\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16610",
            name: "Ph\u1ea1m D\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16611",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "16612",
            name: "Ph\u1ed1 R\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16613",
            name: "Qu\u1ed1c L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16614",
            name: "Qu\u1ed1c L\u1ed9 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16615",
            name: "Qu\u1ef3nh Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16616", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16617", name: "S\u1ed1 144", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16618", name: "S\u1ed1 145", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16619", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16620", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16621", name: "S\u1ed1 526", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16622", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16623", name: "S\u1ed1 70", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16624",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16625",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "16626",
            name: "Tr\u00e0ng Du\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16627", name: "Trang Quan", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16628", name: "Trang Quan 2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16629",
            name: "Trung Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16630", name: "V\u1ea1n Ki\u1ebfp", prefix: "Ph\u1ed1" },
          {
            id: "16631",
            name: "V\u0103n Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16632",
            name: "V\u0103n Qu\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16633", name: "V\u00e2n Tra", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16634",
            name: "V\u0129nh Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16635",
            name: "X\u00f3m M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2462",
            name: "Golden Land H\u1ea3i Ph\u00f2ng",
            lat: "20.857978820801",
            lng: "106.64386749268",
          },
          {
            id: "2463",
            name: "Ho\u00e0ng Huy Pruksa Town",
            lat: "20.856128692627",
            lng: "106.64496612549",
          },
          {
            id: "2464",
            name: "PG An \u0110\u1ed3ng",
            lat: "20.843961715698",
            lng: "106.64886474609",
          },
        ],
      },
      {
        id: "92",
        name: "An L\u00e3o",
        wards: [
          { id: "1401", name: "An L\u00e3o", prefix: "X\u00e3" },
          { id: "1402", name: "An Th\u00e1i", prefix: "X\u00e3" },
          { id: "1403", name: "An Th\u1eafng", prefix: "X\u00e3" },
          { id: "1404", name: "An Th\u1ecd", prefix: "X\u00e3" },
          { id: "1405", name: "An Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "1406", name: "B\u00e1t Trang", prefix: "X\u00e3" },
          { id: "1407", name: "Chi\u1ebfn Th\u1eafng", prefix: "X\u00e3" },
          { id: "1408", name: "M\u1ef9 \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "1409", name: "Quang H\u01b0ng", prefix: "X\u00e3" },
          { id: "1410", name: "Quang Trung", prefix: "X\u00e3" },
          { id: "1411", name: "Qu\u1ed1c Tu\u1ea5n", prefix: "X\u00e3" },
          { id: "1412", name: "T\u00e2n D\u00e2n", prefix: "X\u00e3" },
          { id: "1413", name: "T\u00e2n Vi\u00ean", prefix: "X\u00e3" },
          { id: "1414", name: "Th\u00e1i S\u01a1n", prefix: "X\u00e3" },
          { id: "1415", name: "Tr\u01b0\u1eddng S\u01a1n", prefix: "X\u00e3" },
          {
            id: "1416",
            name: "Tr\u01b0\u1eddng Th\u00e0nh",
            prefix: "X\u00e3",
          },
          { id: "1417", name: "Tr\u01b0\u1eddng Th\u1ecd", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "16636", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16637", name: "203", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16638", name: "212", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16639", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16640", name: "302", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16641", name: "304", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16642", name: "354", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16643", name: "An Lu\u1eadn", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16644", name: "An Trang", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16645", name: "Cao S\u01a1n", prefix: "Ph\u1ed1" },
          {
            id: "16646",
            name: "Ho\u00e0ng Thi\u1ebft T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16647",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16648",
            name: "L\u01b0\u01a1ng Kh\u00e1nh Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16649", name: "Minh Khai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16650",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16651",
            name: "Nguy\u1ec5n Chuy\u00ean M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16652",
            name: "Nguy\u1ec5n Tr\u1ecdng H\u00f2e",
            prefix: "Ph\u1ed1",
          },
          {
            id: "16653",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16654",
            name: "Qu\u1ed1c L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16655",
            name: "Ti\u1ebfn L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16656",
            name: "T\u1ec9nh l\u1ed9 360",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16657",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16658",
            name: "Tr\u00e2\u0300n Ta\u0309o",
            prefix: "Ph\u1ed1",
          },
          {
            id: "16659",
            name: "Tr\u1ea7n T\u1ea5t V\u0103n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16660", name: "Tr\u1ea7n Th\u1ecb Trinh", prefix: "Ph\u1ed1" },
          {
            id: "16661",
            name: "Tr\u01b0\u1eddng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16662", name: "V\u0103n Quang", prefix: "Ph\u1ed1" },
          { id: "16663", name: "V\u0103n Xu\u00e2n", prefix: "Ph\u1ed1" },
          {
            id: "16664",
            name: "V\u01b0\u01a1ng C\u00f4ng Hi\u1ec3n",
            prefix: "Ph\u1ed1",
          },
          { id: "16665", name: "Xu\u00e2n \u00c1ng", prefix: "Ph\u1ed1" },
        ],
        projects: [],
      },
      {
        id: "93",
        name: "B\u1ea1ch Long V\u0129",
        wards: [],
        streets: [],
        projects: [],
      },
      {
        id: "94",
        name: "C\u00e1t H\u1ea3i",
        wards: [
          { id: "1418", name: " \u0110\u1ed3ng B\u00e0i", prefix: "X\u00e3" },
          { id: "1419", name: "C\u00e1t B\u00e0", prefix: "X\u00e3" },
          { id: "1420", name: "C\u00e1t H\u1ea3i", prefix: "X\u00e3" },
          { id: "1421", name: "Gia Lu\u1eadn", prefix: "X\u00e3" },
          { id: "1422", name: "Hi\u1ec1n H\u00e0o", prefix: "X\u00e3" },
          { id: "1423", name: "Ho\u00e0ng Ch\u00e2u", prefix: "X\u00e3" },
          { id: "1424", name: "Ngh\u0129a L\u1ed9", prefix: "X\u00e3" },
          { id: "1425", name: "Ph\u00f9 Long", prefix: "X\u00e3" },
          { id: "1426", name: "Tr\u00e2n Ch\u00e2u", prefix: "X\u00e3" },
          { id: "1427", name: "V\u0103n Phong", prefix: "X\u00e3" },
          { id: "1428", name: "Vi\u1ec7t H\u1ea3i", prefix: "X\u00e3" },
          { id: "1429", name: "Xu\u00e2n \u0110\u00e1m", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "16666", name: "1/4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16667",
            name: "N\u00fai Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16668",
            name: "N\u00fai X\u1ebb",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2465",
            name: "Flamingo Cat Ba Beach Resort",
            lat: "20.719541549683",
            lng: "107.05558013916",
          },
          {
            id: "2466",
            name: "Venus C\u00e1t B\u00e0",
            lat: "20.792490005493",
            lng: "106.99221038818",
          },
        ],
      },
      {
        id: "96",
        name: "D\u01b0\u01a1ng Kinh",
        wards: [
          { id: "1437", name: "Anh D\u0169ng", prefix: "Ph\u01b0\u1eddng" },
          { id: "1438", name: "\u0110a Ph\u00fac", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1439",
            name: "H\u1ea3i Th\u00e0nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1440",
            name: "H\u00f2a Ngh\u0129a",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1441",
            name: "H\u01b0ng \u0110\u1ea1o",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1442",
            name: "T\u00e2n Th\u00e0nh",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "16712", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16713", name: "353", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16714", name: "355", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16715", name: "440", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16716", name: "8/3", prefix: "Ph\u1ed1" },
          { id: "16717", name: "An To\u00e0n", prefix: "Ph\u1ed1" },
          {
            id: "16718",
            name: "Anh D\u0169ng 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16719",
            name: "Anh D\u0169ng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16720", name: "B\u00f9i Ph\u1ed5", prefix: "Ph\u1ed1" },
          {
            id: "16721",
            name: "C\u1ea7u C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16722",
            name: "C\u1ea7u R\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16723", name: "Ch\u1ee3 H\u01b0\u01a1ng", prefix: "Ph\u1ed1" },
          {
            id: "16724",
            name: "\u0110a Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16725",
            name: "\u0110\u1ea1i Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16726",
            name: "\u0110\u00f4ng L\u00e3m 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16727",
            name: "H\u1ea3i Ph\u00f2ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16728", name: "H\u1ea3i Th\u00e0nh", prefix: "Ph\u1ed1" },
          {
            id: "16729",
            name: "H\u00f2a Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16730", name: "H\u1ee3p H\u00f2a", prefix: "Ph\u1ed1" },
          {
            id: "16731",
            name: "M\u1ea1c \u0110\u0103ng Doanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16732",
            name: "M\u1ea1c Quy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16733", name: "M\u00ea Linh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16734",
            name: "Nguy\u1ec5n Nh\u01b0 Qu\u1ebf",
            prefix: "Ph\u1ed1",
          },
          { id: "16735", name: "Ph\u1ea1m Gia M\u00f4", prefix: "Ph\u1ed1" },
          { id: "16736", name: "Ph\u1ea1m H\u1ea3i", prefix: "Ph\u1ed1" },
          {
            id: "16737",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16738", name: "Ph\u00fac H\u1ea3i", prefix: "Ph\u1ed1" },
          { id: "16739", name: "Ph\u00fac L\u1ed9c", prefix: "Ph\u1ed1" },
          { id: "16740", name: "Ph\u01b0\u01a1ng Lung", prefix: "Ph\u1ed1" },
          { id: "16741", name: "S\u00f4ng He", prefix: "Ph\u1ed1" },
          { id: "16742", name: "T\u00e2n H\u1ee3p", prefix: "Ph\u1ed1" },
          {
            id: "16743",
            name: "T\u00e2n L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16744",
            name: "T\u00e2n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16745",
            name: "Th\u1ebf Nh\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16746", name: "Ti\u1ec3u Tr\u00e0", prefix: "Ph\u1ed1" },
          { id: "16747", name: "T\u0129nh H\u1ea3i", prefix: "Ph\u1ed1" },
          {
            id: "16748",
            name: "To\u00e0n Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16749",
            name: "Tr\u00e0 Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16750",
            name: "Tr\u00e0 Kh\u00ea 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16751",
            name: "Tr\u00e0 Kh\u00ea 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16752", name: "Tr\u00e0 Nam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16753",
            name: "Tr\u1ea7n B\u00e1 L\u01b0\u01a1ng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "16754",
            name: "Tr\u1ea7n Minh Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "16755",
            name: "Tr\u01b0\u1eddng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16756",
            name: "T\u01b0 Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16757", name: "V\u00e2n Quan", prefix: "Ph\u1ed1" },
          { id: "16758", name: "V\u1ecdng H\u1ea3i", prefix: "Ph\u1ed1" },
          { id: "16759", name: "V\u0169 H\u1ed9", prefix: "Ph\u1ed1" },
          {
            id: "16760",
            name: "V\u0169 Th\u1ecb Ng\u1ecdc To\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2470",
            name: "Anh D\u0169ng II - Sao \u0110\u1ecf I",
            lat: "20.813419342041",
            lng: "106.70085144043",
          },
          {
            id: "2471",
            name: "K\u0110T Anh D\u0169ng 5",
            lat: "20.820482254028",
            lng: "106.6976776123",
          },
        ],
      },
      {
        id: "95",
        name: "\u0110\u1ed3 S\u01a1n",
        wards: [
          { id: "1430", name: "B\u00e0ng La", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1431",
            name: "H\u1ee3p \u0110\u1ee9c",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1432",
            name: "Minh \u0110\u1ee9c",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1433",
            name: "Ng\u1ecdc H\u1ea3i",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1434",
            name: "Ng\u1ecdc Xuy\u00ean",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1435",
            name: "V\u1ea1n H\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1436", name: "V\u1ea1n S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "16669", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16670", name: "353", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16671", name: "355", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16672", name: "403", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16673",
            name: "\u1ea4p B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16674",
            name: "B\u00e0 \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16675",
            name: "B\u00ecnh Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16676",
            name: "\u0110\u1ea1i Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16677",
            name: "\u0110\u1ea1i Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16678",
            name: "\u0110\u1ea7m ngh\u00e8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16679",
            name: "\u0110\u00ea bi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16680",
            name: "\u0110\u00ecnh \u0110o\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16681",
            name: "\u0110\u1ed3ng N\u1ebbo",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16682", name: "\u0110\u1ee9c H\u1eadu", prefix: "Ph\u1ed1" },
          {
            id: "16683",
            name: "\u0110\u1ee9c H\u1eadu 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16684",
            name: "\u0110\u1ee9c Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16685",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16686",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16687",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16688",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16689",
            name: "L\u00fd Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16690",
            name: "Minh Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16691",
            name: "Ngh\u0129a Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16692",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16693",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16694",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16695",
            name: "Nguy\u1ec5n V\u0103n Th\u1ee9c",
            prefix: "Ph\u1ed1",
          },
          {
            id: "16696",
            name: "Ph\u1ea1m Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16697",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16698", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16699", name: "Qu\u00fd Kim", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16700",
            name: "S\u01a1n H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16701",
            name: "Su\u1ed1i R\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16702",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16703",
            name: "Th\u01b0\u1ee3ng \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16704",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16705",
            name: "Tr\u1ea7n Minh Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "16706",
            name: "Trung Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16707",
            name: "Trung Ngh\u0129a 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16708", name: "V\u1ea1n Hoa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16709",
            name: "V\u1ea1n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16710",
            name: "V\u1ea1n L\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16711",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2467",
            name: "BRG Coastal City",
            lat: "20.729652404785",
            lng: "106.77946472168",
          },
          {
            id: "2468",
            name: "\u0110\u1ea3o Hoa Ph\u01b0\u1ee3ng",
            lat: "20.691289901733",
            lng: "106.78964233398",
          },
          {
            id: "2469",
            name: "H\u00f2n D\u1ea5u Resort",
            lat: "20.676984786987",
            lng: "106.80684661865",
          },
        ],
      },
      {
        id: "97",
        name: "H\u1ea3i An",
        wards: [
          {
            id: "1443",
            name: " \u0110\u1eb1ng H\u1ea3i",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1444", name: "An Kh\u00ea", prefix: "X\u00e3" },
          { id: "1445", name: "C\u00e1t Bi", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1446",
            name: "\u0110\u1eb1ng L\u00e2m",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1447",
            name: "\u0110\u00f4ng H\u1ea3i 1",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1448",
            name: "\u0110\u00f4ng H\u1ea3i 2",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1449", name: "Nam H\u1ea3i", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1450",
            name: "Th\u00e0nh T\u00f4",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1451",
            name: "Tr\u00e0ng C\u00e1t",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "16761", name: "32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16762", name: "356", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16763", name: "68", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16764", name: "7/3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16765", name: "An Kh\u00ea", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16766",
            name: "An Kh\u00ea 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16767", name: "An Trung", prefix: "Ph\u1ed1" },
          { id: "16768", name: "B2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16769", name: "B\u1eafc Trung H\u00e0nh", prefix: "Ph\u1ed1" },
          {
            id: "16770",
            name: "B\u00e3i C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16771",
            name: "B\u1ea3o Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16772",
            name: "B\u1ebfn L\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16773",
            name: "B\u00ecnh Ki\u1ec1u 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16774",
            name: "B\u00ecnh Ki\u1ec1u 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16775",
            name: "B\u00f9i Th\u1ecb T\u1ef1 Nhi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16776", name: "C\u00e1t Bi", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16777",
            name: "C\u00e1t D\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16778",
            name: "C\u00e1t Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16779",
            name: "C\u00e1t Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16780",
            name: "C\u00e1t V\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16781",
            name: "Ch\u00e8 H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16782",
            name: "Ch\u1ee3 L\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16783",
            name: "Ch\u00f9a V\u1ebd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16801", name: "D\u1ee9a", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16784", name: "\u0110\u00e0 N\u1eb5ng", prefix: "Ph\u1ed1" },
          {
            id: "16785",
            name: "\u0110\u1eb1ng H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16786",
            name: "\u0110\u1ec9nh V\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16787",
            name: "\u0110\u00ecnh V\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16788",
            name: "\u0110o\u00e0n K\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16789", name: "\u0110o\u1ea1n X\u00e1", prefix: "Ph\u1ed1" },
          {
            id: "16790",
            name: "\u0110o\u1ea1n X\u00e1 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16791",
            name: "\u0110\u00f4ng An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16792",
            name: "\u0110\u00f4ng H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16793",
            name: "\u0110\u00f4ng H\u1ea3i 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16794",
            name: "\u0110\u00f4ng H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16795",
            name: "\u0110\u00f4ng Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16796", name: "\u0110\u00f4ng Phong", prefix: "Ph\u1ed1" },
          {
            id: "16797",
            name: "\u0110\u00f4ng Trung H\u00e0nh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "16798",
            name: "\u0110\u1ed3ng X\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16799",
            name: "\u0110\u00f4ng X\u00e1 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16800",
            name: "\u0110\u1ed3ng X\u00e1 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16802",
            name: "G\u00e0 \u0110\u00f4ng H\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16803",
            name: "Ha\u0323 \u0110oa\u0323n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16804",
            name: "H\u1ea1 \u0110o\u1ea1n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16805",
            name: "H\u1ea1 \u0110o\u1ea1n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16806",
            name: "H\u1ea1 \u0110o\u1ea1n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16807",
            name: "H\u1ea1 \u0110o\u1ea1n 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16808", name: "H\u1ea1 L\u0169ng", prefix: "Ph\u1ed1" },
          {
            id: "16809",
            name: "H\u1ea1 L\u0169ng 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16810",
            name: "H\u00e0 N\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16811",
            name: "H\u1ea3i B\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16812",
            name: "H\u00e0ng T\u1ed5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16813",
            name: "H\u00e0o Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16814", name: "H\u1ed3 \u0110\u00f4ng", prefix: "Ph\u1ed1" },
          {
            id: "16815",
            name: "Ho\u00e0ng Minh Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16816",
            name: "Ho\u00e0ng Th\u1ebf Thi\u1ec7n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "16817",
            name: "H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16818",
            name: "Ki\u1ec1u H\u1ea1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16819",
            name: "Ki\u1ec1u S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16820",
            name: "Kinh B\u1eafc 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16821",
            name: "L\u1ea1ch Huy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16822", name: "L\u00e1ng", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16823",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16824",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16825",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16826", name: "Lim", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16827",
            name: "L\u1ef1c H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16828",
            name: "L\u0169ng B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16829",
            name: "L\u0169ng \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16830",
            name: "L\u0169ng \u0110\u00f4ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16831",
            name: "L\u0169ng \u0110\u00f4ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16832",
            name: "L\u01b0\u01a1ng Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16833",
            name: "L\u01b0\u01a1ng Kh\u00ea 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16834",
            name: "L\u01b0\u01a1ng Kh\u00ea 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16835",
            name: "L\u00fd H\u1ed3ng Nh\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16836",
            name: "M\u1ea1c \u0110\u0129nh Ph\u00fac",
            prefix: "Ph\u1ed1",
          },
          { id: "16837", name: "Mai Trung Th\u1ee9", prefix: "Ph\u1ed1" },
          {
            id: "16838",
            name: "N\u0103m C\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16839", name: "Nam C\u1ea7u", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16840", name: "Nam H\u1ea3i", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16841", name: "Nam H\u00f2a", prefix: "Ph\u1ed1" },
          {
            id: "16842",
            name: "Nam H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16843",
            name: "Nam H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16844", name: "Nam Phong", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16845",
            name: "Nam Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16846", name: "Nam Trung H\u00e0nh", prefix: "Ph\u1ed1" },
          {
            id: "16847",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16848",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16849",
            name: "Nguy\u1ec5n \u0110\u1ed3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16850",
            name: "Nguy\u1ec5n Khoa D\u1ee5c",
            prefix: "Ph\u1ed1",
          },
          {
            id: "16851",
            name: "Nguy\u1ec5n Th\u1ecb Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16852",
            name: "Nguy\u1ec5n V\u0103n H\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16853",
            name: "Nguy\u1ec5n V\u0103n Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16854",
            name: "Nh\u00e0 M\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16855",
            name: "Nh\u00e0 Th\u1edd X\u00e2m B\u1ed3",
            prefix: "Ph\u1ed1",
          },
          { id: "16856", name: "Ph\u00fa L\u01b0\u01a1ng", prefix: "Ph\u1ed1" },
          {
            id: "16857",
            name: "Ph\u1ee7 Th\u01b0\u1ee3ng \u0110o\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16858",
            name: "Ph\u00fa X\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16859",
            name: "Ph\u00fa X\u00e1 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16860",
            name: "Ph\u00fac \u0110\u00ecnh Qu\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16861",
            name: "Ph\u00fac L\u1ed9c 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16862",
            name: "Ph\u01b0\u01a1ng L\u01b0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16863",
            name: "Ph\u01b0\u01a1ng L\u01b0u 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16864",
            name: "Ph\u01b0\u01a1ng L\u01b0u 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16865",
            name: "Ph\u01b0\u01a1ng L\u01b0u 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16866",
            name: "Ph\u01b0\u01a1ng L\u01b0u 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16867",
            name: "Ph\u01b0\u01a1ng L\u01b0u 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16868",
            name: "Ph\u01b0\u01a1ng L\u01b0u 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16869",
            name: "Ph\u01b0\u01a1ng L\u01b0u 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16870",
            name: "T\u00e2n V\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16871", name: "T\u00e2y Trung H\u00e0nh", prefix: "Ph\u1ed1" },
          {
            id: "16872",
            name: "Th\u00e0nh T\u00f4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16873",
            name: "Th\u01b0 Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16874",
            name: "Th\u01b0 Trung 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16875",
            name: "Ti\u1ec1n Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16876",
            name: "T\u1ec9nh l\u1ed9 356",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16877",
            name: "Tr\u1ea7n Ho\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16878",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16879",
            name: "Tr\u1ea7n V\u0103n Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16880",
            name: "Tr\u00e0ng C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16881",
            name: "Tr\u1ea1ng Tr\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16882",
            name: "Tr\u1ef1c C\u00e1t 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16883",
            name: "Trung H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16884",
            name: "Trung H\u00e0nh 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16885",
            name: "Trung H\u00e0nh 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16886",
            name: "Trung H\u00e0nh 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16887",
            name: "Trung H\u00e0nh 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16888",
            name: "Trung H\u00e0nh 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16889", name: "Trung L\u1ef1c", prefix: "Ph\u1ed1" },
          {
            id: "16890",
            name: "Tr\u01b0ng Tr\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16891",
            name: "T\u1eeb L\u01b0\u01a1ng X\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16892", name: "V\u0103n Cao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16893",
            name: "V\u00e2n H\u1ed3 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16894", name: "V\u0129nh L\u01b0u", prefix: "Ph\u1ed1" },
          {
            id: "16895",
            name: "V\u0169 Ch\u00ed Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16896",
            name: "V\u01b0\u1eddn H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16897", name: "World Bank", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16898",
            name: "X\u00f3m \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2472",
            name: "Ph\u00fac L\u1ed9c New Horizon",
            lat: "20.830656051636",
            lng: "106.74410247803",
          },
        ],
      },
      {
        id: "98",
        name: "H\u1ed3ng B\u00e0ng",
        wards: [
          { id: "1452", name: "H\u1ea1 L\u00fd", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1453",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1454",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1455", name: "Minh Khai", prefix: "Ph\u01b0\u1eddng" },
          { id: "1456", name: "Nam S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1457",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1458",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1459", name: "Qu\u00e1n Toan", prefix: "Ph\u01b0\u1eddng" },
          { id: "1460", name: "Quang Trung", prefix: "Ph\u01b0\u1eddng" },
          { id: "1461", name: "S\u1edf D\u1ea7u", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1462",
            name: "Th\u01b0\u1ee3ng L\u00fd",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1463",
            name: "Tr\u1ea1i Chu\u1ed1i",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "16899", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16900", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16901", name: "351", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16902", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16903", name: "5/1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16904", name: "An Ch\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16905",
            name: "An L\u1ea1c 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16906",
            name: "An L\u1ea1c 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16907", name: "An Tr\u00ec", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16908",
            name: "An Tr\u00ec 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16909", name: "An Tr\u1ef1c", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16910",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16911",
            name: "B\u00e3i S\u1eady",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16912",
            name: "B\u1ebfn B\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16913", name: "Cam L\u1ed9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16914",
            name: "Cam L\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16915",
            name: "Cam L\u1ed9 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16916",
            name: "Cam L\u1ed9 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16917",
            name: "Cam L\u1ed9 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16918",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16919",
            name: "C\u1ea7u B\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16920",
            name: "Chi L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16921",
            name: "Ch\u01b0\u01a1ng D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16922",
            name: "C\u1ed1ng M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16923", name: "C\u1eed B\u00ecnh", prefix: "Ph\u1ed1" },
          {
            id: "16924",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16927",
            name: "D\u1ea7u L\u1eeda",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16930", name: "Do Nha 2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16931",
            name: "D\u1ed1c T\u00edt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16925",
            name: "\u0110\u00e0o \u0110\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16926",
            name: "\u0110\u00e0o \u0110\u00f4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16928",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16929",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16932",
            name: "\u0110\u1ed9i V\u0103n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16933", name: "\u0110\u1ee9c H\u1eadu", prefix: "Ph\u1ed1" },
          {
            id: "16934",
            name: "G\u00e0 \u0110\u00f4ng H\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16935",
            name: "H\u1ea1 L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16936",
            name: "H\u00e0 N\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16937", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "16938",
            name: "H\u1ea3i Tri\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16939",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16940",
            name: "H\u1ed3 \u0110\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16941",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16942",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16943",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16944",
            name: "H\u1ed3ng B\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16945",
            name: "H\u00f9ng  Du\u1ec7 V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16946",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16947", name: "K\u00ed Con", prefix: "Ph\u1ed1" },
          {
            id: "16948",
            name: "Ki\u1ebfn Thi\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16949", name: "K\u1ef3 \u0110\u1ed3ng", prefix: "Ph\u1ed1" },
          {
            id: "16950",
            name: "L\u00e2m S\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16951", name: "L\u00e3n \u00d4ng", prefix: "Ph\u1ed1" },
          {
            id: "16952",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16953",
            name: "Linh \u0110\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16954",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "16955",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16956",
            name: "M\u00ea Tr\u00f4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16957", name: "Minh Khai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16958",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16959",
            name: "Nguy\u1ec5n H\u1ed3ng Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16960",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16961",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16962",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16963",
            name: "Nguy\u1ec5n Trung Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16964",
            name: "Nh\u00e0 Tr\u1ebb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16965",
            name: "Ph\u1ea1m B\u00e1 Tr\u1ef1c",
            prefix: "Ph\u1ed1",
          },
          {
            id: "16966",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16967",
            name: "Ph\u1ea1m Ph\u00fa Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16968",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16969",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16970",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16971",
            name: "Ph\u1ed1 C\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16972",
            name: "Qu\u00e1n Toan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16973", name: "Quang \u0110\u00e0m", prefix: "Ph\u1ed1" },
          { id: "16974", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "16975",
            name: "Qu\u1ed1c L\u1ed9 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16976",
            name: "Qu\u1ef3nh C\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16977",
            name: "S\u1edf D\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16978", name: "Tam B\u1ea1c", prefix: "Ph\u1ed1" },
          {
            id: "16979",
            name: "T\u00e1n Thu\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16980",
            name: "T\u1ea3n Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16981",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16982", name: "Th\u1ea5t Kh\u00ea", prefix: "Ph\u1ed1" },
          {
            id: "16983",
            name: "Th\u1ebf L\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16985", name: "Ti\u00ean Dung", prefix: "Ph\u1ed1" },
          {
            id: "16984",
            name: "Ti\u1ec1n \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16986",
            name: "T\u00f4n \u0110\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16987",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "16988",
            name: "T\u00f4n Th\u1ea5t Thuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16989",
            name: "Tr\u1ea1i Chu\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16990",
            name: "Tr\u1ea1i L\u1ebb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16991",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16992",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16993",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16994",
            name: "Tr\u1ea1ng Tr\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "16995",
            name: "Tr\u01b0\u01a1ng V\u0103n L\u01b0\u0323c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "16996", name: "V\u1ea1n Ki\u1ebfp", prefix: "Ph\u1ed1" },
          {
            id: "16997",
            name: "V\u00f2ng C\u1ea7u Ni\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2473",
            name: "Central Tower",
            lat: "20.856660842896",
            lng: "106.68022918701",
          },
          {
            id: "2474",
            name: "Grand Pacific",
            lat: "20.864212036133",
            lng: "106.65229797363",
          },
          {
            id: "2475",
            name: "Vincity H\u1ea3i Ph\u00f2ng",
            lat: "20.859531402588",
            lng: "106.6669921875",
          },
          {
            id: "2476",
            name: "Vinhomes Imperia H\u1ea3i Ph\u00f2ng",
            lat: "20.865888595581",
            lng: "106.66597747803",
          },
        ],
      },
      {
        id: "99",
        name: "Ki\u1ebfn An",
        wards: [
          { id: "1464", name: "B\u1eafc S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1465",
            name: "\u0110\u1ed3ng H\u00f2a",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1466", name: "L\u00e3m H\u00e0", prefix: "Ph\u01b0\u1eddng" },
          { id: "1467", name: "L\u00e3m Kh\u00ea", prefix: "Ph\u01b0\u1eddng" },
          { id: "1468", name: "Nam S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1469",
            name: "Ng\u1ecdc S\u01a1n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1470",
            name: "Ph\u00f9 Li\u1ec5n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1471",
            name: "Qu\u00e1n Tr\u1eef",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1472",
            name: "Tr\u1ea7n Th\u00e0nh Ng\u1ecd",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1473", name: "Tr\u00e0ng Minh", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1474",
            name: "V\u0103n \u0110\u1ea9u",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "16998", name: "436", prefix: "\u0110\u01b0\u1eddng" },
          { id: "16999", name: "621", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17000",
            name: "B\u1eafc H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17001",
            name: "B\u00f9i M\u1ed9ng Hoa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17002",
            name: "C\u1eadn S\u01a1n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17003",
            name: "C\u00e1t \u0110\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17004",
            name: "C\u1ea7u Ni\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17005",
            name: "Chi\u00eau Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17006",
            name: "Ch\u1ee3 \u0110\u1ea7m Tri\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17007",
            name: "C\u1ed9ng H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17008",
            name: "C\u1ed9t C\u00f2i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17009",
            name: "C\u1ef1u Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17010",
            name: "\u0110\u1ea1i H\u1ed3ng M\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17011",
            name: "\u0110\u1ea5t \u0110\u1ecf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17012",
            name: "\u0110\u1ea9u Ph\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17013",
            name: "\u0110\u1ea9u Ph\u01b0\u1ee3ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17014",
            name: "\u0110\u1ea9u S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17015",
            name: "\u0110\u1ea9u V\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17016",
            name: "\u0110i\u1ec7n N\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17017",
            name: "\u0110o\u00e0n K\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17018",
            name: "\u0110\u00f4ng Ch\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17019",
            name: "\u0110\u1ed3ng H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17020",
            name: "\u0110\u00f4ng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17021",
            name: "\u0110\u1ed3ng T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17022",
            name: "\u0110\u1ed3ng T\u1eed 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17023",
            name: "Gi\u1ebfng N\u1ea7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17024",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17025", name: "Hoa Kh\u00ea", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17026",
            name: "Ho\u00e0ng C\u00f4ng Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17027",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17028",
            name: "Ho\u00e0ng Thi\u1ebft T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17029",
            name: "H\u01b0\u01a1ng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17030",
            name: "Kha L\u00e2m 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17031",
            name: "Kha L\u00e2m 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17032",
            name: "Kh\u00fac L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17033",
            name: "Kh\u00fac Tr\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17034",
            name: "Kh\u00fac Tr\u00ec 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17035",
            name: "Ki\u1ec1u S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17036",
            name: "La\u0303m Ha\u0300",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17037",
            name: "L\u00e3m Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17039",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17038",
            name: "L\u00ea \u0110\u1ea1i Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17040",
            name: "L\u00ea Qu\u1ed1c Uy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17041",
            name: "L\u1ec7 T\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17042",
            name: "L\u1ec7 T\u1ea3o 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17043",
            name: "Li\u00ean Ph\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17044",
            name: "L\u01b0u \u00dac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17045",
            name: "L\u00fd H\u1ed3ng Nh\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17046",
            name: "M\u1ea1c Thi\u00ean Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17047",
            name: "M\u1ef9 Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17048",
            name: "M\u1ef9 Kh\u00ea T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17049",
            name: "M\u1ef9 Th\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17050",
            name: "Nguy\u1ec5n C\u00f4ng M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17051",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17052",
            name: "Nguy\u1ec5n M\u1eabn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17053",
            name: "Nguy\u1ec5n Thi\u00ean L\u1ed9c",
            prefix: "Ph\u1ed1",
          },
          {
            id: "17054",
            name: "Nguy\u1ec5n Xi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17055",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "17056",
            name: "Phan Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17057", name: "Ph\u00f9 L\u01b0u", prefix: "Ph\u1ed1" },
          {
            id: "17058",
            name: "Ph\u00f9ng Th\u1ecb Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17059",
            name: "Ph\u01b0\u01a1ng Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17060",
            name: "Qu\u00e1n tr\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17061",
            name: "Qu\u00fd Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17062", name: "Quy T\u1ee9c", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17063",
            name: "Quy\u1ebft Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17064", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17065",
            name: "T\u00e2n H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17066",
            name: "T\u00e2y S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17067",
            name: "Th\u1ed1ng Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17068",
            name: "T\u1ec9nh l\u1ed9 355",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17069",
            name: "T\u00f4 Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17070",
            name: "Tr\u1ea7n B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17071",
            name: "Tr\u1ea7n Ki\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17072",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17073",
            name: "Tr\u1ea7n Nh\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17074",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17075",
            name: "Tr\u1ea7n Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17076",
            name: "Tr\u1ea7n T\u1ea5t V\u0103n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17077",
            name: "Tr\u1ea7n Th\u00e0nh Ng\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17078",
            name: "Trang \u0110\u1ed3ng T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17079",
            name: "Tr\u1eef Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17080",
            name: "Tr\u1eef Kh\u00ea 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17081",
            name: "Tr\u1eef Kh\u00ea 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17082",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17083",
            name: "Tr\u01b0\u1eddng Chinh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17084",
            name: "Vi\u1ec7t \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17085",
            name: "V\u01b0\u1eddn Chay",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17086",
            name: "Xu\u00e2n Bi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2477",
            name: "B\u1eafc S\u01a1n",
            lat: "20.809753417969",
            lng: "106.63502502441",
          },
          {
            id: "2478",
            name: "C\u1ef1u Vi\u00ean",
            lat: "20.821125030518",
            lng: "106.64009857178",
          },
          {
            id: "2479",
            name: "Tinh Th\u00e0nh Qu\u1ed1c t\u1ebf",
            lat: "20.820964813232",
            lng: "106.63996887207",
          },
        ],
      },
      {
        id: "100",
        name: "Ki\u1ebfn Th\u1ee5y",
        wards: [
          { id: "1481", name: "Du L\u1ec5", prefix: "X\u00e3" },
          { id: "1475", name: "\u0110a Ph\u00fac", prefix: "X\u00e3" },
          {
            id: "1476",
            name: "\u0110\u1ea1i \u0110\u1ed3ng",
            prefix: "X\u00e3",
          },
          { id: "1477", name: "\u0110\u1ea1i H\u00e0", prefix: "X\u00e3" },
          { id: "1478", name: "\u0110\u1ea1i H\u1ee3p", prefix: "X\u00e3" },
          { id: "1479", name: "\u0110o\u00e0n X\u00e1", prefix: "X\u00e3" },
          {
            id: "1480",
            name: "\u0110\u00f4ng Ph\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "1482", name: "H\u1eefu B\u1eb1ng", prefix: "X\u00e3" },
          { id: "1483", name: "Ki\u1ebfn Qu\u1ed1c", prefix: "X\u00e3" },
          { id: "1484", name: "Minh T\u00e2n", prefix: "X\u00e3" },
          { id: "1485", name: "Ng\u0169 \u0110oan", prefix: "X\u00e3" },
          { id: "1486", name: "Ng\u0169 Ph\u00fac", prefix: "X\u00e3" },
          {
            id: "1487",
            name: "N\u00fai \u0110\u1ed1i",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1488", name: "T\u00e2n Phong", prefix: "X\u00e3" },
          { id: "1489", name: "T\u00e2n Tr\u00e0o", prefix: "X\u00e3" },
          { id: "1490", name: "Thanh S\u01a1n", prefix: "X\u00e3" },
          { id: "1491", name: "Thu\u1eadn Thi\u00ean", prefix: "X\u00e3" },
          { id: "1492", name: "Th\u1ee5y H\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "1493", name: "T\u00fa S\u01a1n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "17087", name: "361", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17088", name: "362", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17089", name: "402", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17090", name: "C\u1ea9m La", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17091",
            name: "\u0110\u00ecnh \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17092",
            name: "Minh T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17093",
            name: "Ph\u1ea1m T\u1eed Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17094",
            name: "Ti\u1ec1n \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "101",
        name: "L\u00ea Ch\u00e2n",
        wards: [
          {
            id: "1494",
            name: " An D\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1495",
            name: " K\u00eanh D\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1496", name: "An Bi\u00ean", prefix: "Ph\u01b0\u1eddng" },
          { id: "1497", name: "C\u00e1t D\u00e0i", prefix: "Ph\u01b0\u1eddng" },
          { id: "1499", name: "D\u01b0 H\u00e0ng", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1500",
            name: "D\u01b0 H\u00e0ng K\u00eanh \t",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1498",
            name: "\u0110\u00f4ng H\u1ea3i",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1501",
            name: "H\u00e0ng K\u00eanh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1502", name: "H\u1ed3 Nam", prefix: "Ph\u01b0\u1eddng" },
          { id: "1503", name: "Lam S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1504",
            name: "Ngh\u0129a X\u00e1",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1505",
            name: "Ni\u1ec7m Ngh\u0129a",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1506", name: "Tr\u1ea1i Cau", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1507",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1508",
            name: "V\u0129nh Ni\u1ec7m",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "17095", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17096", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17097", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17098", name: "64", prefix: "Ph\u1ed1" },
          { id: "17099", name: "71", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17100", name: "77", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17101", name: "8/3", prefix: "Ph\u1ed1" },
          {
            id: "17103",
            name: "An D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17102",
            name: "An \u0110\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17104",
            name: "An Kim H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17105", name: "An Trang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17106",
            name: "C\u00e1t C\u1ee5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17108",
            name: "C\u00e1t D\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17107",
            name: "C\u00e1t \u0110\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17109",
            name: "C\u1ea7u C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17110",
            name: "C\u1ea7u \u0110\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17111",
            name: "C\u1ea7u Ni\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17112",
            name: "C\u1ea7u Ni\u1ec7m 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17113",
            name: "C\u1ea7u R\u00e0o 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17114",
            name: "Chi\u1ebfn L\u01b0\u1ee3c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17115", name: "Ch\u1ee3 Con", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17116",
            name: "Ch\u01a1\u0323 \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17117", name: "Ch\u1ee3 \u0110\u00f4n", prefix: "Ph\u1ed1" },
          {
            id: "17118",
            name: "Ch\u1ee3 H\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17119",
            name: "Ch\u1ee3 H\u00e0ng c\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17120",
            name: "Ch\u1ee3 H\u00e0ng M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17121",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17122",
            name: "Ch\u00f9a \u0110\u1ed3ng Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17123",
            name: "Ch\u00f9a H\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17124",
            name: "C\u1ed9ng H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17125", name: "C\u00f4ng Nh\u00e2n", prefix: "Ph\u1ed1" },
          {
            id: "17126",
            name: "C\u1ed9t \u0110\u00e8n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17128",
            name: "D\u00e2n L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17143",
            name: "D\u01b0 H\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17144",
            name: "D\u01b0 H\u00e0ng K\u00eanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17145",
            name: "D\u01b0\u01a1ng \u0110\u00ecnh Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17146",
            name: "D\u01b0\u01a1ng H\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17127",
            name: "\u0110\u1ea1i l\u1ed9 V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17129",
            name: "\u0110\u1eb7ng Kim N\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17130", name: "\u0110\u1eb7ng Ma La", prefix: "Ph\u1ed1" },
          { id: "17131", name: "\u0110\u00e0o Nhu\u1eadn", prefix: "Ph\u1ed1" },
          {
            id: "17132",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17133",
            name: "\u0110\u00ecnh \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17134",
            name: "\u0110inh Nhu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17135",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17136",
            name: "\u0110o\u00e0n K\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17137",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17138",
            name: "\u0110\u1ed3ng Qu\u1ed1c B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17139",
            name: "\u0110\u1ed3ng Thi\u1ec7n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "17140",
            name: "\u0110\u00f4ng Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17141",
            name: "\u0110\u00f4ng Tr\u00e0 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17142",
            name: "\u0110\u00f4ng Tr\u00e0 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17147", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          { id: "17148", name: "H\u00e0ng G\u00e0", prefix: "Ph\u1ed1" },
          {
            id: "17149",
            name: "H\u00e0ng K\u00eanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17150",
            name: "H\u00e0o Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17151",
            name: "H\u1ed3 \u00d4ng B\u00e1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17152", name: "H\u1ed3 Sen", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17153",
            name: "Ho\u00e0ng Minh Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17154",
            name: "Ho\u00e0ng Ng\u1ecdc Ph\u00e1ch",
            prefix: "Ph\u1ed1",
          },
          {
            id: "17155",
            name: "Ho\u00e0ng Qu\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17156",
            name: "Kh\u00fac Th\u1eeba D\u1ee5",
            prefix: "Ph\u1ed1",
          },
          {
            id: "17157",
            name: "Kh\u00fac Th\u1eeba D\u1ee5 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17158",
            name: "Ki\u1ec1u S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17159", name: "L\u1ea1ch Tray", prefix: "Ph\u1ed1" },
          { id: "17160", name: "Lam S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17161",
            name: "L\u00e2m T\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17162",
            name: "L\u00e1n B\u00e8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17163",
            name: "L\u00ea Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17164",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17165",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17166",
            name: "L\u00ea V\u0103n Thuy\u1ebft",
            prefix: "Ph\u1ed1",
          },
          {
            id: "17167",
            name: "L\u1eeda H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17168",
            name: "M\u00e1y T\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17169", name: "M\u00ea Linh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17170",
            name: "Mi\u1ebfu Hai X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17171",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17172",
            name: "Ng\u00f4 Kim T\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17173",
            name: "Nguy\u1ec5n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17174",
            name: "Nguy\u1ec5n C\u00f4ng H\u00f2a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "17175",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17176",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17177",
            name: "Nguy\u00ean H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17178",
            name: "Nguy\u1ec5n S\u01a1n H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17179",
            name: "Nguy\u1ec5n T\u1ea5t T\u1ed1",
            prefix: "Ph\u1ed1",
          },
          {
            id: "17180",
            name: "Nguy\u1ec5n T\u01b0\u1eddng Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17181",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17182",
            name: "Ph\u1ea1m H\u1eefu \u0110i\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17183", name: "Ph\u1ea1m Huy Th\u00f4ng", prefix: "Ph\u1ed1" },
          {
            id: "17184",
            name: "Ph\u1ea1m T\u1eed Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17185",
            name: "Qu\u00e1n N\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17186",
            name: "Qu\u00e1n Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17187",
            name: "Qu\u00e1n S\u1ecfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17188", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17189", name: "S\u1ed1 90", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17190",
            name: "S\u1edf D\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17191",
            name: "Thi\u00ean L\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17192",
            name: "Th\u00f4i Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17193", name: "Thuy\u1ebft", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17194",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17195",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "17196",
            name: "Tr\u1ea1i L\u1ebb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17197",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "17198",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17199", name: "Tr\u1ef1c C\u00e1t", prefix: "Ph\u1ed1" },
          { id: "17200", name: "V\u0103n Cao", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17201", name: "V\u1ea1n Ki\u1ebfp", prefix: "Ph\u1ed1" },
          { id: "17202", name: "V\u0129nh C\u00e1t", prefix: "Ph\u1ed1" },
          {
            id: "17203",
            name: "Vi\u0303nh Ni\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17204", name: "V\u0129nh Ti\u1ebfn", prefix: "Ph\u1ed1" },
          {
            id: "17205",
            name: "V\u0129nh Ti\u1ebfn 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17206",
            name: "V\u0129nh Ti\u1ebfn 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17207",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17208",
            name: "V\u00f2ng C\u1ea7u Ni\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17209",
            name: "V\u0169 Ch\u00ed Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17210", name: "World Bank", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "2480",
            name: "Waterfront City",
            lat: "20.823932647705",
            lng: "106.69306182861",
          },
        ],
      },
      {
        id: "102",
        name: "Ng\u00f4 Quy\u1ec1n",
        wards: [
          {
            id: "1509",
            name: " C\u1ea7u \u0110\u1ea5t",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1510", name: " Gia Vi\u00ean", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1511",
            name: " L\u1ea1c Vi\u00ean",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1512", name: " L\u00ea L\u1ee3i", prefix: "Ph\u01b0\u1eddng" },
          { id: "1513", name: "C\u1ea7u Tre", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1514",
            name: "\u0110\u1eb1ng Giang",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1515",
            name: "\u0110\u00f4ng Kh\u00ea",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1516",
            name: "\u0110\u1ed5ng Qu\u1ed1c B\u00ecnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1517", name: "L\u1ea1ch Tray", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1518",
            name: "L\u01b0\u01a1ng Kh\u00e1nh Thi\u1ec7n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1519", name: "M\u00e1y Chai", prefix: "Ph\u01b0\u1eddng" },
          { id: "1520", name: "M\u00e1y T\u01a1", prefix: "Ph\u01b0\u1eddng" },
          { id: "1521", name: "V\u1ea1n M\u1ef9", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "17211", name: "22A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17212", name: "349", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17213",
            name: "An \u0110\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17214",
            name: "An \u0110\u00e0 N\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17215", name: "An Trang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17216",
            name: "B\u00f9i Th\u1ecb T\u1ef1 Nhi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17217",
            name: "C\u1ea7u \u0110\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17218", name: "C\u1ea7u Tre", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17219",
            name: "C\u1ea7u Tre 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17220",
            name: "Ch\u00e8 H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17221",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17222",
            name: "Ch\u00f9a V\u1ebd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17223", name: "\u0110\u00e0 N\u1eb5ng", prefix: "Ph\u1ed1" },
          {
            id: "17224",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17225",
            name: "\u0110\u1ec9nh V\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17226", name: "\u0110o\u1ea1n X\u00e1", prefix: "Ph\u1ed1" },
          {
            id: "17227",
            name: "\u0110\u1ed9i C\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17228",
            name: "\u0110\u00f4ng Ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17229",
            name: "\u0110\u00f4ng Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17230",
            name: "\u0110\u1ed3ng Qu\u1ed1c B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17231", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "17232",
            name: "H\u00e0o Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17233",
            name: "Ho\u00e0ng Th\u1ebf Thi\u1ec7n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "17234",
            name: "Ki\u1ec1u S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17235", name: "K\u1ef3 \u0110\u1ed3ng", prefix: "Ph\u1ed1" },
          { id: "17236", name: "L\u1ea1ch Tray", prefix: "Ph\u1ed1" },
          {
            id: "17237",
            name: "Lam Pha\u0301t 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17238",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17239", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17240",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17241", name: "L\u00ea Qu\u00fdnh", prefix: "Ph\u1ed1" },
          {
            id: "17242",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17243",
            name: "L\u01b0\u01a1ng Kh\u00e1nh Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17244",
            name: "L\u01b0\u01a1ng V\u0103n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17245",
            name: "L\u00fd Ti\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17246",
            name: "M\u00e1y T\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17247",
            name: "Nam Ph\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17248",
            name: "Nam Ph\u00e1p 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17249",
            name: "Nam Ph\u00e1p I",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17250",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17251",
            name: "Nguy\u1ec5n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17252",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17253",
            name: "Nguy\u1ec5n H\u1eefu Tu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17254",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17255",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17256",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17257",
            name: "Ph\u1ea1m Minh \u0110\u1ee9c",
            prefix: "Ph\u1ed1",
          },
          {
            id: "17258",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17259",
            name: "Ph\u1ed1 C\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17260",
            name: "Ph\u00f3 \u0110\u1ee9c Ch\u00ednh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "17261",
            name: "Ph\u1ee7 Th\u01b0\u1ee3ng \u0110o\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17262",
            name: "Ph\u01b0\u01a1ng L\u01b0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17263",
            name: "Ph\u01b0\u01a1ng L\u01b0u II",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17264",
            name: "Qu\u00e1n Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17265", name: "Quy L\u01b0u", prefix: "Ph\u1ed1" },
          {
            id: "17266",
            name: "Th\u00e1i Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17267",
            name: "Thi\u00ean L\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17268",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17269",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17270",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17271",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17272", name: "V\u0103n Cao", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17273", name: "V\u1ea1n Ki\u1ebfp", prefix: "Ph\u1ed1" },
          {
            id: "17274",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17275",
            name: "V\u00f2ng V\u1ea1n M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17276",
            name: "V\u0169 Ch\u00ed Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2481",
            name: "Cat Bi Plaza",
            lat: "20.86082649231",
            lng: "106.6971282959",
          },
          {
            id: "2482",
            name: "SHP Plaza",
            lat: "20.841526031494",
            lng: "106.69241333008",
          },
          {
            id: "2483",
            name: "Sunlight Tower",
            lat: "20.854406356812",
            lng: "106.70046234131",
          },
          {
            id: "2484",
            name: "TD Lakeside",
            lat: "20.848432540894",
            lng: "106.70928955078",
          },
          {
            id: "2485",
            name: "TD Plaza H\u1ea3i Ph\u00f2ng",
            lat: "20.846530914307",
            lng: "106.70714569092",
          },
          {
            id: "2486",
            name: "Vincom Shophouse L\u00ea Th\u00e1nh T\u00f4ng",
            lat: "20.867036819458",
            lng: "106.69791412354",
          },
        ],
      },
      {
        id: "103",
        name: "Th\u1ee7y Nguy\u00ean",
        wards: [
          { id: "1522", name: " Cao Nh\u00e2n", prefix: "X\u00e3" },
          { id: "1523", name: " \u0110\u00f4ng S\u01a1n", prefix: "X\u00e3" },
          { id: "1524", name: " Gia Minh", prefix: "X\u00e3" },
          { id: "1525", name: " H\u00f2a B\u00ecnh", prefix: "X\u00e3" },
          { id: "1526", name: " K\u00eanh Giang", prefix: "X\u00e3" },
          { id: "1527", name: " Ki\u1ec1n B\u00e1i", prefix: "X\u00e3" },
          { id: "1528", name: " L\u00e2m \u0110\u1ed9ng", prefix: "X\u00e3" },
          { id: "1529", name: " Ph\u1ee5c L\u1ec5", prefix: "X\u00e3" },
          { id: "1530", name: " Tam H\u01b0ng", prefix: "X\u00e3" },
          {
            id: "1531",
            name: " Th\u1ee7y \u0110\u01b0\u1eddng",
            prefix: "X\u00e3",
          },
          { id: "1532", name: "An L\u01b0", prefix: "X\u00e3" },
          { id: "1533", name: "An S\u01a1n", prefix: "X\u00e3" },
          { id: "1534", name: "Ch\u00ednh M\u1ef9", prefix: "X\u00e3" },
          { id: "1535", name: "D\u01b0\u01a1ng Quan", prefix: "X\u00e3" },
          { id: "1536", name: "Gia \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "1537", name: "Hoa \u0110\u1ed9ng", prefix: "X\u00e3" },
          { id: "1538", name: "Ho\u00e0ng \u0110\u1ed9ng", prefix: "X\u00e3" },
          { id: "1539", name: "H\u1ee3p Th\u00e0nh", prefix: "X\u00e3" },
          { id: "1540", name: "K\u1ef3 S\u01a1n", prefix: "X\u00e3" },
          { id: "1541", name: "L\u1ea1i Xu\u00e2n", prefix: "X\u00e3" },
          { id: "1542", name: "L\u1eadp L\u1ec5", prefix: "X\u00e3" },
          { id: "1543", name: "Li\u00ean Kh\u00ea", prefix: "X\u00e3" },
          { id: "1544", name: "L\u01b0u Ki\u1ebfm", prefix: "X\u00e3" },
          { id: "1545", name: "L\u01b0u K\u1ef3", prefix: "X\u00e3" },
          { id: "1546", name: "Minh \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "1547", name: "Minh T\u00e2n", prefix: "X\u00e3" },
          { id: "1548", name: "M\u1ef9 \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "1549", name: "Ng\u0169 L\u00e3o", prefix: "X\u00e3" },
          { id: "1550", name: "N\u00fai \u0110\u00e8o", prefix: "X\u00e3" },
          { id: "1551", name: "Ph\u1ea3 L\u1ec5", prefix: "X\u00e3" },
          { id: "1552", name: "Ph\u00f9 Ninh", prefix: "X\u00e3" },
          { id: "1553", name: "Qu\u1ea3ng Thanh", prefix: "X\u00e3" },
          { id: "1554", name: "T\u00e2n D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "1555", name: "Thi\u00ean H\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "1556", name: "Th\u1ee7y S\u01a1n", prefix: "X\u00e3" },
          { id: "1557", name: "Th\u1ee7y Tri\u1ec1u", prefix: "X\u00e3" },
          { id: "1558", name: "Trung H\u00e0", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "17277", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17278", name: "25/10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17279", name: "351", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17280", name: "359", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17281",
            name: "\u00c1n Tr\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17282", name: "Ba Toa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17283",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17284",
            name: "B\u1ebfn B\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17285",
            name: "C\u1ea7u B\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17286",
            name: "C\u1ea7u \u0110en",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17287",
            name: "C\u1ed1ng C\u1edd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17288", name: "\u0110\u00e0 N\u1eb5ng", prefix: "Ph\u1ed1" },
          {
            id: "17289",
            name: "\u0110\u1ea7m Tr\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17290",
            name: "\u0110\u1ed3ng D\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17291",
            name: "\u0110\u1ed3ng X\u0103ng Trong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17292", name: "\u0110T 359", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17293",
            name: "H\u00e0 T\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17294", name: "H\u00e0m Long", prefix: "Ph\u1ed1" },
          {
            id: "17295",
            name: "Hoa \u0110\u1ed9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17296",
            name: "L\u00e2m \u0110\u1ed9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17297",
            name: "Li\u00ean Th\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17298",
            name: "Li\u00ean Th\u00f4n \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17299",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17300",
            name: "M\u1ef9 Li\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17301",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17302",
            name: "Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17303",
            name: "Nguy\u1ec5n Phong S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17304",
            name: "Ph\u1ea3 L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17305",
            name: "Ph\u1ed1 M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17306",
            name: "Qu\u1ea3ng C\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17307",
            name: "Qu\u1ed1c L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17308",
            name: "Qu\u1ed1c L\u1ed9 359",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17309",
            name: "T\u00e2n D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17310",
            name: "Th\u1ee7y Nguy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17311",
            name: "Th\u1ee7y S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17312",
            name: "T\u1ec9nh l\u1ed9 352",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17313",
            name: "T\u1ec9nh l\u1ed9 359",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17314",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17315",
            name: "Tr\u01b0\u1ee1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2487",
            name: "KDC G\u00f2 Gai",
            lat: "20.916660308838",
            lng: "106.67087554932",
          },
          {
            id: "2488",
            name: "Khu \u0111\u00f4 th\u1ecb B\u1eafc S\u00f4ng C\u1ea5m",
            lat: "20.894458770752",
            lng: "106.70503234863",
          },
          {
            id: "2489",
            name: "Quang Minh Green City",
            lat: "20.890880584717",
            lng: "106.6615524292",
          },
          {
            id: "2490",
            name: "S\u00f4ng Gi\u00e1 Resort",
            lat: "20.961519241333",
            lng: "106.66857910156",
          },
          {
            id: "2491",
            name: "VSIP H\u1ea3i Ph\u00f2ng",
            lat: "20.906261444092",
            lng: "106.68493652344",
          },
        ],
      },
      {
        id: "104",
        name: "Ti\u00ean L\u00e3ng",
        wards: [
          { id: "1559", name: " \u0110\u00f4ng H\u01b0ng", prefix: "X\u00e3" },
          { id: "1560", name: "B\u1eafc H\u01b0ng", prefix: "X\u00e3" },
          { id: "1561", name: "B\u1ea1ch \u0110\u1eb1ng", prefix: "X\u00e3" },
          { id: "1562", name: "C\u1ea5p Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "1563", name: "\u0110\u1ea1i Th\u1eafng", prefix: "X\u00e3" },
          { id: "1564", name: "\u0110o\u00e0n L\u1eadp", prefix: "X\u00e3" },
          { id: "1565", name: "H\u00f9ng Th\u1eafng", prefix: "X\u00e3" },
          { id: "1566", name: "Kh\u1edfi Ngh\u0129a", prefix: "X\u00e3" },
          { id: "1567", name: "Ki\u1ebfn Thi\u1ebft", prefix: "X\u00e3" },
          { id: "1568", name: "Nam H\u01b0ng", prefix: "X\u00e3" },
          { id: "1569", name: "Quang Ph\u1ee5c", prefix: "X\u00e3" },
          { id: "1570", name: "Quy\u1ebft Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "1571", name: "T\u00e2y H\u01b0ng", prefix: "X\u00e3" },
          { id: "1572", name: "Ti\u00ean C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "1573", name: "Ti\u00ean H\u01b0ng", prefix: "X\u00e3" },
          { id: "1574", name: "Ti\u00ean L\u00e3ng", prefix: "X\u00e3" },
          { id: "1575", name: "Ti\u00ean Minh", prefix: "X\u00e3" },
          { id: "1576", name: "Ti\u00ean Th\u1eafng", prefix: "X\u00e3" },
          { id: "1577", name: "Ti\u00ean Thanh", prefix: "X\u00e3" },
          { id: "1578", name: "Ti\u00ean Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "1579", name: "To\u00e0n Th\u1eafng", prefix: "X\u00e3" },
          { id: "1580", name: "T\u1ef1 C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "1581", name: "Vinh Quang", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "17316", name: "212", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17317", name: "354", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17318",
            name: "B\u1edd K\u00eanh Huy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17319",
            name: "\u0110\u00e0o Linh Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17320",
            name: "Minh \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17321",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17322",
            name: "Nguy\u1ec5n V\u0103n S\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "105",
        name: "V\u0129nh B\u1ea3o",
        wards: [
          { id: "1582", name: "An H\u00f2a", prefix: "X\u00e3" },
          { id: "1583", name: "Cao Minh", prefix: "X\u00e3" },
          { id: "1584", name: "C\u1ed5 Am", prefix: "X\u00e3" },
          { id: "1585", name: "C\u1ed9ng Hi\u1ec1n", prefix: "X\u00e3" },
          { id: "1587", name: "D\u0169ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "1586", name: "\u0110\u1ed3ng Minh", prefix: "X\u00e3" },
          { id: "1588", name: "Giang Bi\u00ean", prefix: "X\u00e3" },
          { id: "1589", name: "Hi\u1ec7p H\u00f2a", prefix: "X\u00e3" },
          { id: "1590", name: "H\u00f2a B\u00ecnh", prefix: "X\u00e3" },
          { id: "1591", name: "H\u01b0ng Nh\u00e2n", prefix: "X\u00e3" },
          { id: "1592", name: "H\u00f9ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "1593", name: "Li\u00ean Am", prefix: "X\u00e3" },
          { id: "1594", name: "L\u00fd H\u1ecdc", prefix: "X\u00e3" },
          { id: "1595", name: "Nh\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "1596", name: "Tam C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "1597", name: "Tam \u0110a", prefix: "X\u00e3" },
          { id: "1598", name: "T\u00e2n H\u01b0ng", prefix: "X\u00e3" },
          { id: "1599", name: "T\u00e2n Li\u00ean", prefix: "X\u00e3" },
          { id: "1600", name: "Th\u1eafng Th\u1ee7y", prefix: "X\u00e3" },
          { id: "1601", name: "Thanh L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "1602", name: "Ti\u1ec1n Phong", prefix: "X\u00e3" },
          { id: "1603", name: "Tr\u1ea5n D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "1604", name: "Trung L\u1eadp", prefix: "X\u00e3" },
          { id: "1605", name: "Vi\u1ec7t Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "1606", name: "V\u0129nh An", prefix: "X\u00e3" },
          { id: "1607", name: "V\u0129nh B\u1ea3o", prefix: "X\u00e3" },
          { id: "1608", name: "V\u0129nh Long", prefix: "X\u00e3" },
          { id: "1609", name: "V\u0129nh Phong", prefix: "X\u00e3" },
          { id: "1610", name: "Vinh Quang", prefix: "X\u00e3" },
          { id: "1611", name: "V\u0129nh Ti\u1ebfn", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "17323", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17324",
            name: "\u0110\u00f4ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17325",
            name: "Qu\u1ed1c l\u1ed9 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17326",
            name: "T\u00e2n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17327",
            name: "Ti\u1ec1n H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "8",
    code: "LA",
    name: "Long An",
    districts: [
      {
        id: "106",
        name: "B\u1ebfn L\u1ee9c",
        wards: [
          { id: "1612", name: "An Th\u1ea1nh", prefix: "X\u00e3" },
          {
            id: "1613",
            name: "B\u1ebfn L\u1ee9c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "1614", name: "B\u00ecnh \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "1615", name: "Long Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "1616", name: "L\u01b0\u01a1ng B\u00ecnh", prefix: "X\u00e3" },
          { id: "1617", name: "L\u01b0\u01a1ng H\u00f2a", prefix: "X\u00e3" },
          { id: "1618", name: "M\u1ef9 Y\u00ean", prefix: "X\u00e3" },
          { id: "1619", name: "Nh\u1ef1t Ch\u00e1nh", prefix: "X\u00e3" },
          { id: "1620", name: "Ph\u01b0\u1edbc L\u1ee3i", prefix: "X\u00e3" },
          { id: "1621", name: "T\u00e2n B\u1eedu", prefix: "X\u00e3" },
          { id: "1622", name: "T\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "1623", name: "Th\u1ea1nh \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "1624", name: "Th\u1ea1nh H\u00f2a", prefix: "X\u00e3" },
          { id: "1625", name: "Th\u1ea1nh L\u1ee3i", prefix: "X\u00e3" },
          { id: "1626", name: "Thanh Ph\u00fa", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "17328", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17329", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17330", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17331", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17332", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17333", name: "24A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17334", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17335", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17336", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17337", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17338", name: "7B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17339", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17340", name: "816", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17341", name: "830", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17342", name: "833B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17343", name: "835", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17344", name: "835C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17345", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17346", name: "A1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17347", name: "A2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17348",
            name: "An Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17349", name: "\u1ea4p 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17350", name: "\u1ea4p 5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17351",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17352",
            name: "B\u1ebfn L\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17353",
            name: "B\u1eddi \u0110ai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17354",
            name: "B\u00f9i Th\u1ecb \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17355",
            name: "C\u00e2y \u0110i\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17356",
            name: "C\u00f4ng V\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17367",
            name: "D\u01b0\u01a1ng Ph\u01b0\u1edbc T\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17357",
            name: "\u0110inh \u0110\u1ee9c Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17358", name: "\u0110T 816", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17359", name: "\u0110T 824", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17360", name: "\u0110T 830", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17361", name: "\u0110T 830C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17362", name: "\u0110T 832", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17363", name: "\u0110T 833B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17364", name: "\u0110T 835", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17365", name: "\u0110T 835C", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17366",
            name: "\u0110\u1ee9c L\u1eadp H\u1ea1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17368",
            name: "Gia Mi\u1ec7ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17369",
            name: "Go\u0300 \u0110en",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17370",
            name: "Ho\u00e0ng Phan Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17371",
            name: "H\u01b0\u01a1ng l\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17372",
            name: "H\u01b0\u01a1ng l\u1ed9 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17373",
            name: "Hu\u1ef3nh Ch\u00e2u S\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17374",
            name: "K\u00eanh 30/4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17375",
            name: "Li\u00ean \u1ea5p 1-2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17376",
            name: "Li\u00ean Ph\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17377",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17378",
            name: "Li\u00ean X\u00e3 2-3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17379",
            name: "L\u1ed9 B\u00e0 Cua",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17380",
            name: "Long B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17381",
            name: "Long Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17382",
            name: "Mai Th\u1ecb Non",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17383",
            name: "M\u1ef9 Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17384", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17385", name: "N3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17386", name: "N6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17387",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17388",
            name: "Nguy\u1ec5n H\u1eefu Tr\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17389",
            name: "Nguy\u1ec5n Minh Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17390",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17391",
            name: "Nguy\u1ec5n V\u0103n B\u1ee9a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17392",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17393",
            name: "Nguy\u1ec5n V\u0103n Linh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17394",
            name: "Nguy\u1ec5n V\u0103n Nh\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17395",
            name: "Nguy\u1ec5n V\u0103n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17396",
            name: "Nguy\u1ec5n V\u0103n Ti\u1ebfp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17397",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17398",
            name: "Nguy\u1ec5n V\u0103n Tu\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17399",
            name: "Nh\u1ef1t Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17400",
            name: "Phan V\u0103n L\u01b0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17401",
            name: "Phan V\u0103n M\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17402",
            name: "Phan V\u0103n T\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17403",
            name: "Ph\u01b0\u1edbc To\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17404",
            name: "Qu\u1ed1c L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17405",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17406",
            name: "Qu\u1ed1c L\u1ed9 50",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17407",
            name: "Qu\u1ed1c l\u1ed9 N2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17408",
            name: "R\u1ea1ch Tre",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17409", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17410", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17411", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17412", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17413", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17414", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17415", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17416", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17417", name: "S\u1ed1 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17418", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17419", name: "S\u1ed1 24", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17420", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17421", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17422", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17423", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17424", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17425", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17426", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17427",
            name: "Song H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17428",
            name: "T\u00e1m Th\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17429",
            name: "T\u00e2n B\u1eedu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17430",
            name: "T\u00e2n T\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17431",
            name: "Th\u1ea1nh \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17432",
            name: "Thanh Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17433",
            name: "Th\u1ea1nh H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17434",
            name: "T\u1ec9nh L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17435",
            name: "T\u1ec9nh l\u1ed9 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17436",
            name: "T\u1ec9nh l\u1ed9 16B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17437",
            name: "T\u1ec9nh l\u1ed9 635B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17438",
            name: "T\u1ec9nh l\u1ed9 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17439",
            name: "T\u1ec9nh l\u1ed9 816",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17440",
            name: "T\u1ec9nh l\u1ed9 824",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17441",
            name: "T\u1ec9nh l\u1ed9 826",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17442",
            name: "T\u1ec9nh L\u1ed9 826C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17443",
            name: "T\u1ec9nh l\u1ed9 830",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17444",
            name: "T\u1ec9nh L\u1ed9 830C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17445",
            name: "T\u1ec9nh L\u1ed9 830D",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17446",
            name: "T\u1ec9nh l\u1ed9 832",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17447",
            name: "T\u1ec9nh l\u1ed9 833",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17448",
            name: "T\u1ec9nh L\u1ed9 833B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17449",
            name: "T\u1ec9nh l\u1ed9 835",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17450",
            name: "T\u1ec9nh l\u1ed9 835B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17451",
            name: "T\u1ec9nh L\u1ed9 835C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17452",
            name: "T\u1ec9nh L\u1ed9 835D",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17453",
            name: "Tr\u1ea7n Th\u1ebf Sinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17454",
            name: "Tr\u1ea7n V\u0103n Gi\u00e0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17455",
            name: "Tr\u1ea7n V\u0103n Non",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17456",
            name: "V\u00e0m Th\u1ee7 \u0110o\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17457",
            name: "V\u00e0nh \u0110ai 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17458",
            name: "V\u00e0nh \u0110ai 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17459",
            name: "V\u0129nh L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17460",
            name: "V\u0129nh L\u1ed9c 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17461",
            name: "V\u00f5 C\u00f4ng T\u1ed3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2492",
            name: "Dragon Town",
            lat: "10.631801605225",
            lng: "106.49625396729",
          },
          {
            id: "2493",
            name: "Gia Long Countryside",
            lat: "10.645312309265",
            lng: "106.47257232666",
          },
          {
            id: "2494",
            name: "Happyland",
            lat: "10.643525123596",
            lng: "106.46138000488",
          },
          {
            id: "2495",
            name: "H\u01b0ng Gia Garden City",
            lat: "10.641548156738",
            lng: "106.4983291626",
          },
          {
            id: "2496",
            name: "KDC An Th\u1ea1nh",
            lat: "10.662935256958",
            lng: "106.46452331543",
          },
          {
            id: "2497",
            name: "Kh\u1ea3i Ho\u00e0n Chateau",
            lat: "10.671812057495",
            lng: "106.48683929443",
          },
          {
            id: "2498",
            name: "Khu \u0111\u00f4 th\u1ecb Ph\u00fac Giang",
            lat: "10.634335517883",
            lng: "106.49027252197",
          },
          {
            id: "2499",
            name: "Long H\u1ed9i City",
            lat: "10.633790969849",
            lng: "106.50080108643",
          },
          {
            id: "2500",
            name: "Long Kim II",
            lat: "10.631922721863",
            lng: "106.49533843994",
          },
          {
            id: "2501",
            name: "Moonlight Villas",
            lat: "10.701599121094",
            lng: "106.43022155762",
          },
          {
            id: "2502",
            name: "Newlife Long Kim",
            lat: "10.622996330261",
            lng: "106.5032119751",
          },
          {
            id: "2503",
            name: "Nh\u00e0 ph\u1ed1 Nam Khang Nam Long",
            lat: "10.665922164917",
            lng: "106.46180725098",
          },
          {
            id: "2504",
            name: "Nh\u1ef1t Ch\u00e1nh Residence",
            lat: "10.611143112183",
            lng: "106.47003936768",
          },
          {
            id: "2505",
            name: "Ph\u00fac Long Garden",
            lat: "10.634026527405",
            lng: "106.4958190918",
          },
          {
            id: "2506",
            name: "Sun City Long An",
            lat: "10.643023490906",
            lng: "106.4828414917",
          },
          {
            id: "2507",
            name: "Thanh Y\u1ebfn Residence",
            lat: "10.619495391846",
            lng: "106.47132110596",
          },
          {
            id: "2508",
            name: "Tr\u1ea7n Anh Riverside",
            lat: "10.644282341003",
            lng: "106.47901153564",
          },
          {
            id: "2509",
            name: "Waterpoint",
            lat: "10.663329124451",
            lng: "106.46577453613",
          },
          {
            id: "2510",
            name: "Western City",
            lat: "10.682163238525",
            lng: "106.45040130615",
          },
        ],
      },
      {
        id: "107",
        name: "C\u1ea7n \u0110\u01b0\u1edbc",
        wards: [
          {
            id: "1627",
            name: "C\u1ea7n \u0110\u01b0\u1edbc",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "1628", name: "Long Cang", prefix: "X\u00e3" },
          { id: "1629", name: "Long \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "1630", name: "Long H\u00f2a", prefix: "X\u00e3" },
          {
            id: "1631",
            name: "Long H\u1ef1u \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "1632", name: "Long H\u1ef1u T\u00e2y", prefix: "X\u00e3" },
          { id: "1633", name: "Long Kh\u00ea", prefix: "X\u00e3" },
          { id: "1634", name: "Long S\u01a1n", prefix: "X\u00e3" },
          { id: "1635", name: "Long Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "1636", name: "Long Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "1637", name: "M\u1ef9 L\u1ec7", prefix: "X\u00e3" },
          {
            id: "1638",
            name: "Ph\u01b0\u1edbc \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "1639", name: "Ph\u01b0\u1edbc Tuy", prefix: "X\u00e3" },
          { id: "1640", name: "Ph\u01b0\u1edbc V\u00e2n", prefix: "X\u00e3" },
          { id: "1641", name: "T\u00e2n \u00c2n", prefix: "X\u00e3" },
          { id: "1642", name: "T\u00e2n Ch\u00e1nh", prefix: "X\u00e3" },
          { id: "1643", name: "T\u00e2n L\u00e2n", prefix: "X\u00e3" },
          { id: "1644", name: "T\u00e2n Tr\u1ea1ch", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "17462", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17463", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17464", name: "216", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17465", name: "24", prefix: "Ph\u1ed1" },
          { id: "17466", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17467", name: "826", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17468", name: "830", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17469", name: "833", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17470", name: "833B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17471", name: "835A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17472", name: "835B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17473", name: "835C", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17474",
            name: "\u1ea4p Xo\u00e0i \u0110\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17475",
            name: "B\u1edd Ch\u00f9a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17476",
            name: "B\u1edd \u0110ai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17477",
            name: "B\u1edd M\u1ed3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17478",
            name: "C\u1ea7u X\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17479",
            name: "Ch\u1ee3 \u0110\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17480", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17481", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17482",
            name: "\u0110inh \u0110\u1ee9c Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17483",
            name: "\u0110\u1ed3ng T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17484", name: "\u0110T 826", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17485", name: "\u0110T 830", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17486", name: "\u0110T 833", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17487", name: "\u0110T 833B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17488", name: "\u0110T 835", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17489", name: "\u0110T 836", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17490",
            name: "Go\u0300 \u0110en",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17491",
            name: "H\u1ed3 V\u0103n Hu\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17492",
            name: "H\u01b0\u01a1ng l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17493",
            name: "H\u01b0\u01a1ng L\u1ed9 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17494",
            name: "H\u01b0\u01a1ng l\u1ed9 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17495",
            name: "H\u01b0\u01a1ng L\u1ed9 82",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17496",
            name: "Huy\u1ec7n L\u1ed9 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17497",
            name: "Huy\u1ec7n l\u1ed9 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17498",
            name: "Hu\u1ef3nh H\u1eefu Tr\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17499",
            name: "K\u00eanh Long Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17500",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17501",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17502", name: "Long Cang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17503",
            name: "Long Tr\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17504",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17505",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17506",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17507",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17508",
            name: "Nguy\u1ec5n V\u0103n Th\u1eddi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17509",
            name: "Nguy\u1ec5n V\u0103n Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17510",
            name: "Phan V\u0103n M\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17511",
            name: "Ph\u01b0\u1edbc V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17512",
            name: "Qu\u1ed1c L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17513",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17514",
            name: "Qu\u1ed1c L\u1ed9 50",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17515",
            name: "Qu\u1ed1c L\u1ed9 826",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17516", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17517", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17518", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17519",
            name: "T\u1ec9nh 826",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17520",
            name: "T\u1ec9nh L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17521",
            name: "T\u1ec9nh l\u1ed9 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17522",
            name: "T\u1ec9nh l\u1ed9 16B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17523",
            name: "T\u1ec9nh L\u1ed9 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17524",
            name: "T\u1ec9nh l\u1ed9 628",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17525",
            name: "T\u1ec9nh L\u1ed9 825A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17526",
            name: "T\u1ec9nh l\u1ed9 826",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17527",
            name: "T\u1ec9nh L\u1ed9 826B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17528",
            name: "T\u1ec9nh l\u1ed9 830",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17529",
            name: "T\u1ec9nh l\u1ed9 833",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17530",
            name: "T\u1ec9nh L\u1ed9 833B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17531",
            name: "T\u1ec9nh l\u1ed9 835",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17532",
            name: "T\u1ec9nh l\u1ed9 835B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17533",
            name: "T\u1ec9nh L\u1ed9 835C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17534",
            name: "T\u1ec9nh L\u1ed9 835D",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17535",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17536",
            name: "Tr\u1ecb Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17537",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17538",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2511",
            name: "An B\u00ecnh Green Home",
            lat: "10.531434059143",
            lng: "106.61510467529",
          },
          {
            id: "2512",
            name: "An Th\u1eafng Long Tr\u1ea1ch",
            lat: "10.601794242859",
            lng: "106.58715057373",
          },
          {
            id: "2513",
            name: "Eco Garden",
            lat: "10.633682250977",
            lng: "106.58207702637",
          },
          {
            id: "2514",
            name: "Gia Long Riverside",
            lat: "10.576993942261",
            lng: "106.59083557129",
          },
          {
            id: "2515",
            name: "Gia Ph\u00fa Vi\u00ean",
            lat: "10.620527267456",
            lng: "106.57849121094",
          },
          {
            id: "2516",
            name: "KDC Ch\u1ee3 R\u1ea1ch Ki\u1ebfn",
            lat: "10.575708389282",
            lng: "106.58737182617",
          },
          {
            id: "2517",
            name: "K\u0110T C\u1ea7u C\u1ea3ng Ph\u01b0\u1edbc \u0110\u00f4ng",
            lat: "10.493618011475",
            lng: "106.63502502441",
          },
          {
            id: "2518",
            name: "Nam Long",
            lat: "10.576993942261",
            lng: "106.59083557129",
          },
          {
            id: "2519",
            name: "Nam Phong Eco Town",
            lat: "10.47306728363",
            lng: "106.63009643555",
          },
          {
            id: "2520",
            name: "Ph\u1ed1 ch\u1ee3 \u0111a n\u0103ng C\u1ea7u Tr\u00e0m",
            lat: "10.622785568237",
            lng: "106.58676147461",
          },
          {
            id: "2521",
            name: "Th\u1eafng L\u1ee3i Riverside Market",
            lat: "10.579329490662",
            lng: "106.64307403564",
          },
          {
            id: "2522",
            name: "Th\u1eafng R\u1ea1ch Ki\u1ebfn",
            lat: "21.029581069946",
            lng: "105.85041046143",
          },
        ],
      },
      {
        id: "108",
        name: "C\u1ea7n Giu\u1ed9c",
        wards: [
          {
            id: "1645",
            name: "C\u1ea7n Giu\u1ed9c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "1646", name: "\u0110\u00f4ng Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "1647", name: "Long An", prefix: "X\u00e3" },
          { id: "1648", name: "Long H\u1eadu", prefix: "X\u00e3" },
          { id: "1649", name: "Long Ph\u1ee5ng", prefix: "X\u00e3" },
          { id: "1650", name: "Long Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "1651", name: "M\u1ef9 L\u1ed9c", prefix: "X\u00e3" },
          { id: "1652", name: "Ph\u01b0\u1edbc H\u1eadu", prefix: "X\u00e3" },
          { id: "1653", name: "Ph\u01b0\u1edbc L\u1ea1i", prefix: "X\u00e3" },
          { id: "1654", name: "Ph\u01b0\u1edbc L\u00e2m", prefix: "X\u00e3" },
          { id: "1655", name: "Ph\u01b0\u1edbc L\u00fd", prefix: "X\u00e3" },
          {
            id: "1656",
            name: "Ph\u01b0\u1edbc V\u0129nh \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "1657",
            name: "Ph\u01b0\u1edbc V\u0129nh T\u00e2y",
            prefix: "X\u00e3",
          },
          { id: "1658", name: "T\u00e2n Kim", prefix: "X\u00e3" },
          { id: "1659", name: "T\u00e2n T\u1eadp", prefix: "X\u00e3" },
          { id: "1660", name: "Thu\u1eadn Th\u00e0nh", prefix: "X\u00e3" },
          { id: "1661", name: "Tr\u01b0\u1eddng B\u00ecnh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "17539", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17540", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17541", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17542", name: "25A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17543", name: "34", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17544", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17545", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17546", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17547", name: "826", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17548", name: "826C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17549", name: "830", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17550", name: "835", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17551", name: "835A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17552", name: "835B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17553", name: "835C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17554", name: "9 Ch\u00edch", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17555",
            name: "\u1ea4p L\u1ed9 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17556",
            name: "\u1ea4p T\u00e2n \u0110\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17557",
            name: "\u1ea4p Thanh Ba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17558",
            name: "\u1ea4p Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17559", name: "Ba B\u00f4ng", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17560",
            name: "B\u00e0 Ki\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17561", name: "Ba L\u00e0ng", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17562", name: "Ba Nh\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17563",
            name: "B\u00e0 R\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17564",
            name: "Ba Si\u00eang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17565", name: "Ba Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17566",
            name: "B\u1ea3y Thu\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17567",
            name: "B\u1edd Ch\u00f9a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17568",
            name: "B\u1edd \u0110\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17569",
            name: "B\u1edd \u0110ai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17570",
            name: "B\u1edd \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17571",
            name: "B\u1edd L\u1ed9 H\u1ee7 T\u00edu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17572",
            name: "B\u1edd Mi\u1ec5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17573",
            name: "B\u1edd Nh\u00e0 Th\u1edd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17574",
            name: "Ch\u00edn \u0110\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17575",
            name: "C\u1ed9ng \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17576", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17577", name: "D10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17578", name: "D11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17579", name: "D13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17580", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17581", name: "D6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17582", name: "D8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17583", name: "D9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17597",
            name: "D\u01b0\u01a1ng Th\u1ecb Hai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17584",
            name: "\u0110\u1eb7ng V\u0129nh Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17585",
            name: "\u0110\u00ea L\u00f2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17586",
            name: "\u0110inh \u0110\u1ee9c Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17587",
            name: "\u0110o\u00e0n Nguy\u1ec5n Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17588", name: "\u0110T 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17589", name: "\u0110T 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17590", name: "\u0110T 35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17591", name: "\u0110T 826", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17592", name: "\u0110T 826C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17593", name: "\u0110T 830", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17594", name: "\u0110T 835", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17595", name: "\u0110T 835B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17596", name: "\u0110T 835C", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17598",
            name: "Gi\u1ebfng N\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17599",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17600",
            name: "H\u01b0\u01a1ng L\u00f4\u0323 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17601",
            name: "H\u01b0\u01a1ng l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17602",
            name: "H\u01b0\u01a1ng l\u1ed9 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17603",
            name: "H\u01b0\u01a1ng L\u1ed9 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17604",
            name: "H\u01b0\u01a1ng L\u1ed9 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17605",
            name: "K\u00eanh S\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17606",
            name: "Kh\u01b0\u01a1ng L\u1ed9 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17607",
            name: "Kim \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17608",
            name: "L\u00e3nh Binh Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17609",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17610",
            name: "L\u00ea Th\u1ecb L\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17611",
            name: "L\u00ea V\u0103n L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17612",
            name: "Li\u00ean \u1ea5p 1-3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17614",
            name: "Li\u00ean \u1ea5p 2-3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17613",
            name: "Li\u00ean \u1ea4p 2/6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17615",
            name: "Li\u00ean \u1ea5p 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17616",
            name: "Li\u00ean \u1ea4p 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17617",
            name: "L\u1ed9 \u1ea4p 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17618",
            name: "L\u1ed9 Long Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17619",
            name: "L\u1ed9 M\u01a1\u0301i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17620",
            name: "L\u1ed9c H\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17621",
            name: "L\u1ed9c Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17622",
            name: "Long H\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17623",
            name: "Long H\u1eadu 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17624",
            name: "Long Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17625",
            name: "Long Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17626",
            name: "Long Tr\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17627",
            name: "M\u01b0\u1eddi Hai Chim",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17628",
            name: "M\u01b0\u1eddi Ve",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17629",
            name: "M\u01b0\u1eddi V\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17630",
            name: "M\u1ef9 L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17631", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17632", name: "N13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17633", name: "N15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17634", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17635", name: "N3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17636", name: "N4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17637", name: "N5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17638", name: "N6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17639",
            name: "N\u0103m M\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17640",
            name: "N\u0103m Ng\u00f3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17641",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17642",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17643",
            name: "Nguy\u1ec5n H\u1eefu Tr\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17644",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17645",
            name: "Nguy\u1ec5n Th\u1ecb B\u1ea3y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17646",
            name: "Nguy\u1ec5n Th\u1ecb B\u1eb9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17647",
            name: "Nguy\u1ec5n Th\u1ecb S\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17648",
            name: "Nguy\u1ec5n V\u0103n T\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17649",
            name: "Nguy\u1ec5n V\u0103n Th\u1eddi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17650",
            name: "Nh\u00e0 \u0110\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17651", name: "NP21", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17652",
            name: "Ph\u1ea1m T\u1ea5n M\u01b0\u1eddi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17653",
            name: "Ph\u00fa Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17654",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17655",
            name: "Qu\u1ed1c L\u1ed9 50",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17656",
            name: "Qu\u1ed1c L\u1ed9 50B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17657", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17658", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17659", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17660", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17661", name: "S\u1ed1 21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17662", name: "S\u1ed1 24", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17663", name: "S\u1ed1 25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17664", name: "S\u1ed1 27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17665", name: "S\u1ed1 28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17666", name: "S\u1ed1 29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17667", name: "S\u1ed1 30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17668", name: "S\u1ed1 33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17669", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17670", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17671", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17672",
            name: "Song H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17673", name: "T\u00e2n Kim", prefix: "Ph\u1ed1" },
          {
            id: "17674",
            name: "T\u00e2n Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17675",
            name: "T\u00e2n Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17676",
            name: "T\u1eadp \u0110o\u00e0n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17677",
            name: "T\u1eadp \u0110o\u00e0n 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17678",
            name: "T\u00e2y B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17679", name: "Thanh H\u00e0", prefix: "Ph\u1ed1" },
          {
            id: "17680",
            name: "T\u1ec9nh L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17681",
            name: "T\u1ec9nh L\u1ed9 50",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17682",
            name: "T\u1ec9nh l\u1ed9 826",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17683",
            name: "T\u1ec9nh L\u1ed9 826B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17684",
            name: "T\u1ec9nh L\u1ed9 826C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17685",
            name: "Ti\u0309nh L\u1ed9 830",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17686",
            name: "T\u1ec9nh l\u1ed9 835",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17687",
            name: "T\u1ec9nh l\u1ed9 835A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17688",
            name: "T\u1ec9nh l\u1ed9 835B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17689",
            name: "T\u1ec9nh L\u1ed9 835C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17690",
            name: "Tr\u00ed Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17691",
            name: "Tr\u01b0\u1eddng B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17692",
            name: "Tr\u01b0\u01a1ng V\u0103n Bang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17693",
            name: "T\u01b0 G\u1ea5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17694",
            name: "T\u01b0 Nh\u1ef1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17695",
            name: "\u00dat T\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17696", name: "\u00dat Thu", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17697",
            name: "V\u0103n H\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17698",
            name: "V\u00e0nh \u0110ai 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17699",
            name: "V\u00f5 Th\u1ecb T\u1ed1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2523",
            name: "\u0110\u1ea1i L\u00e2m Ph\u00e1t Residential",
            lat: "10.645313262939",
            lng: "106.58763122559",
          },
          {
            id: "2524",
            name: "Five Star Eco City",
            lat: "10.643649101257",
            lng: "106.58578491211",
          },
          {
            id: "2525",
            name: "H\u01b0ng Ph\u00e1t Center",
            lat: "10.648676872253",
            lng: "106.58766937256",
          },
          {
            id: "2526",
            name: "KDC Long H\u1eadu",
            lat: "10.625426292419",
            lng: "106.72740936279",
          },
          {
            id: "2527",
            name: "KDC V\u0129nh Tr\u01b0\u1eddng",
            lat: "10.643004417419",
            lng: "106.69323730469",
          },
          {
            id: "2528",
            name: "Khu d\u00e2n c\u01b0 M\u1ef9 Dinh",
            lat: "10.604064941406",
            lng: "106.67737579346",
          },
          {
            id: "2529",
            name: "S\u00e0i G\u00f2n Village",
            lat: "10.644883155823",
            lng: "106.6919631958",
          },
          {
            id: "2530",
            name: "South Center",
            lat: "10.601962089539",
            lng: "106.67678833008",
          },
          {
            id: "2531",
            name: "T&T Long H\u1eadu",
            lat: "10.641052246094",
            lng: "106.6915435791",
          },
          {
            id: "2532",
            name: "Th\u00e0nh Hi\u1ebfu",
            lat: "10.638082504272",
            lng: "106.70099639893",
          },
        ],
      },
      {
        id: "109",
        name: "Ch\u00e2u Th\u00e0nh",
        wards: [
          { id: "1662", name: "An L\u1ee5c Long", prefix: "X\u00e3" },
          { id: "1663", name: "B\u00ecnh Qu\u1edbi", prefix: "X\u00e3" },
          { id: "1664", name: "B\u00ecnh Qu\u1edbi.", prefix: "X\u00e3" },
          {
            id: "1665",
            name: "D\u01b0\u01a1ng Xu\u00e2n H\u1ed9i",
            prefix: "X\u00e3",
          },
          { id: "1666", name: "Hi\u1ec7p Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "1667", name: "H\u00f2a Ph\u00fa", prefix: "X\u00e3" },
          { id: "1668", name: "Long Tr\u00ec", prefix: "X\u00e3" },
          {
            id: "1669",
            name: "Ph\u00fa Ng\u00e3i Tr\u1ecb",
            prefix: "X\u00e3",
          },
          {
            id: "1670",
            name: "Ph\u01b0\u1edbc T\u00e2n H\u01b0ng",
            prefix: "X\u00e3",
          },
          { id: "1671", name: "T\u1ea7m Vu", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "1672", name: "Thanh Ph\u00fa Long", prefix: "X\u00e3" },
          {
            id: "1673",
            name: "Thanh V\u0129nh \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "1674", name: "Thu\u1eadn M\u1ef9", prefix: "X\u00e3" },
          { id: "1675", name: "V\u0129nh C\u00f4ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "17700", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17701",
            name: "An Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17702",
            name: "Chi\u1ebfn L\u01b0\u1ee3c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17703", name: "\u0110T 852B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17704",
            name: "Nguy\u1ec5n Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17705",
            name: "Th\u00e2m Nhi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17706",
            name: "T\u1ec9nh 827B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17707",
            name: "T\u1ec9nh l\u1ed9 208",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17708",
            name: "T\u1ec9nh l\u1ed9 827",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17709",
            name: "T\u1ec9nh l\u1ed9 827A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17710",
            name: "T\u1ec9nh l\u1ed9 827B",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "110",
        name: "\u0110\u1ee9c H\u00f2a",
        wards: [
          { id: "1676", name: "An Ninh \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "1677", name: "An Ninh T\u00e2y", prefix: "X\u00e3" },
          {
            id: "1678",
            name: "\u0110\u1ee9c H\u00f2a",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "1679",
            name: "\u0110\u1ee9c Ho\u00e0 \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "1680",
            name: "\u0110\u1ee9c Ho\u00e0 H\u1ea1",
            prefix: "X\u00e3",
          },
          {
            id: "1681",
            name: "\u0110\u1ee9c Ho\u00e0 Th\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
          {
            id: "1682",
            name: "\u0110\u1ee9c L\u1eadp H\u1ea1",
            prefix: "X\u00e3",
          },
          {
            id: "1683",
            name: "\u0110\u1ee9c L\u1eadp Th\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
          {
            id: "1684",
            name: "H\u1eadu Ngh\u0129a",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "1685",
            name: "Hi\u1ec7p H\u00f2a",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "1686", name: "Hi\u1ec7p H\u00f2a", prefix: "X\u00e3" },
          {
            id: "1687",
            name: "Ho\u00e0 Kh\u00e1nh \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "1688", name: "Ho\u00e0 Kh\u00e1nh Nam", prefix: "X\u00e3" },
          {
            id: "1689",
            name: "H\u00f2a Kh\u00e1nh T\u00e2y",
            prefix: "X\u00e3",
          },
          { id: "1690", name: "H\u1ef1u Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "1691", name: "L\u1ed9c Giang", prefix: "X\u00e3" },
          { id: "1692", name: "M\u1ef9 H\u1ea1nh B\u1eafc", prefix: "X\u00e3" },
          { id: "1693", name: "M\u1ef9 H\u1ea1nh Nam", prefix: "X\u00e3" },
          { id: "1694", name: "T\u00e2n M\u1ef9", prefix: "X\u00e3" },
          { id: "1695", name: "T\u00e2n Ph\u00fa", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "17711", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17712", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17713", name: "10A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17714", name: "11A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17715", name: "11C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17716", name: "11D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17717", name: "11E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17718", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17719", name: "12A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17720", name: "12B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17721", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17722", name: "15A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17723", name: "16A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17724", name: "16A1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17725", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17726", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17727", name: "1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17728", name: "1C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17729", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17730", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17731", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17732", name: "23A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17733", name: "23A1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17734", name: "23A2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17735", name: "24A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17736", name: "24A4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17737", name: "25A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17738", name: "25A1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17739", name: "25A4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17740", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17741", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17742", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17743", name: "30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17744", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17745", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17746", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17747", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17748", name: "821", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17749", name: "823", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17750", name: "824", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17751", name: "825", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17752", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17753", name: "A2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17754", name: "A40A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17755", name: "An Ninh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17756",
            name: "An Ph\u00fa 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17757",
            name: "\u1eaep An Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17758",
            name: "\u1ea4p Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17759",
            name: "\u1ea4p M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17760",
            name: "\u1ea4p M\u1edbi 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17761",
            name: "\u1ea4p M\u1edbi 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17762",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17763", name: "B\u00e0 Hom", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17764", name: "Ba Ng\u1eeba", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17765", name: "Basa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17766",
            name: "B\u00e0u C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17767",
            name: "B\u00e0u Mu\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17768", name: "B\u00e0u Sen", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17769",
            name: "B\u1ebfn K\u00eanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17770",
            name: "B\u00ecnh H\u1eefu 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17771",
            name: "B\u00ecnh H\u1eefu 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17772",
            name: "B\u00ecnh L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17773",
            name: "B\u00ecnh T\u1ea3 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17774",
            name: "B\u00ecnh T\u1ea3 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17775",
            name: "B\u00ecnh Ti\u1ec1n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17776",
            name: "B\u00ecnh Ti\u1ec1n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17777", name: "BN16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17778", name: "BN6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17779", name: "BN7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17780",
            name: "B\u1edd K\u00eanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17781",
            name: "B\u00f9i V\u0103n Ng\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17782",
            name: "B\u00f9i V\u0103n Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17783",
            name: "C\u00e1t T\u01b0\u1eddng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17784",
            name: "C\u00e2y D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17785",
            name: "C\u1ea7y T\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17786",
            name: "Ch\u00e2u V\u0103n Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17787",
            name: "C\u1ed1ng B\u00e0 B\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17788",
            name: "C\u1ed1ng C\u1ea7u B\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17789",
            name: "C\u1eedu Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17790", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17791", name: "D12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17792", name: "D23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17793", name: "D40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17794", name: "D5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17796",
            name: "Di\u00eau H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17795",
            name: "\u0110\u00e1 Xanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17797",
            name: "\u0110\u1ed3n C\u00e2y D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17798", name: "\u0110T 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17799", name: "\u0110T 524", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17800", name: "\u0110T 823", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17801", name: "\u0110T 824", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17802", name: "\u0110T 825", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17803", name: "\u0110T 9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17804",
            name: "\u0110\u1ee9c H\u00f2a \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17805",
            name: "\u0110\u1ee9c H\u00f2a Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17806",
            name: "\u0110\u1ee9c Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17807",
            name: "G\u00f2 H\u01b0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17808", name: "Go\u0300 Sao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17809",
            name: "H\u00e0 Duy Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17810",
            name: "H\u00e0m Qu\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17811",
            name: "H\u00e0n Qu\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17812",
            name: "H\u1ed3 Ch\u00ed Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17813",
            name: "H\u00f2a Kh\u00e1nh \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17814", name: "Hoa Kh\u1ebf", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17815",
            name: "H\u1ed3ng B\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17816",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17817",
            name: "K\u00eanh Ph\u01b0\u1edbc H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17818",
            name: "K\u00eanh Tam Chi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17819",
            name: "K\u00eanh Th\u1ea7y Cai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17820",
            name: "Khi\u1ebfu N\u0103ng T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17821", name: "KT1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17822", name: "KT11", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17823",
            name: "L\u1eadp Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17824",
            name: "L\u00ea C\u00f4ng Mi\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17825",
            name: "L\u00ea V\u0103n C\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17826",
            name: "Li\u00ean Ph\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17827",
            name: "L\u1ed9c B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17828",
            name: "L\u1ee5c Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17829", name: "MA - D2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17830",
            name: "M\u01b0\u1eddi Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17831",
            name: "M\u1ef9 H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17832", name: "N10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17833", name: "N13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17834", name: "N17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17835", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17836", name: "N6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17837", name: "N9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17838",
            name: "Ng\u1ecdc Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17839",
            name: "Nguy\u1ec5n C\u00f4ng Th\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17840",
            name: "Nguy\u1ec5n Duy D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17841",
            name: "Nguy\u1ec5n Th\u1ecb H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17842",
            name: "Nguy\u1ec5n Th\u1ecb Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17843",
            name: "Nguy\u1ec5n Tr\u1ecdng Th\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17844",
            name: "Nguy\u1ec5n V\u0103n B\u1ee9a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17845",
            name: "Nguy\u1ec5n V\u0103n D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17846",
            name: "Nguy\u1ec5n V\u0103n D\u01b0\u1ee1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17847",
            name: "Nguy\u1ec5n V\u0103n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17848",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17849",
            name: "Ph\u1ea1m V\u0103n S\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17850",
            name: "Phan V\u0103n H\u1edbn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17851",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17852",
            name: "Qu\u1ed1c L\u1ed9 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17853",
            name: "Qu\u1ed1c l\u1ed9 22A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17854",
            name: "Qu\u1ed1c L\u1ed9 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17855",
            name: "Qu\u1ed1c l\u1ed9 N2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17856",
            name: "R\u1eebng S\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17857", name: "S1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17858", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17859", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17860", name: "S\u1ed1 10A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17861", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17862", name: "S\u1ed1 11C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17863", name: "S\u1ed1 11D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17864", name: "S\u1ed1 11E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17865", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17866", name: "S\u1ed1 12A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17867", name: "S\u1ed1 12B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17868", name: "S\u1ed1 12C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17869", name: "S\u1ed1 12D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17870", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17871", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17872", name: "S\u1ed1 14A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17873", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17874", name: "S\u1ed1 15C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17875", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17876", name: "S\u1ed1 18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17877", name: "S\u1ed1 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17878", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17879", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17880", name: "S\u1ed1 21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17881", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17882", name: "S\u1ed1 22C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17883", name: "S\u1ed1 23A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17884", name: "S\u1ed1 27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17885", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17886", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17887", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17888", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17889", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17890", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17891", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17892",
            name: "S\u00f2 \u0110o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17893", name: "T7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17894", name: "Tam T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17895",
            name: "T\u00e2n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17896",
            name: "T\u00e2n \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17897",
            name: "T\u00e2n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17898",
            name: "T\u00e2n H\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17899",
            name: "T\u00e2n M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17900", name: "TC1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17901", name: "TC3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17902", name: "TC4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17903", name: "TC5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17904",
            name: "Thi\u00ean Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17905",
            name: "T\u1ec9nh L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17906",
            name: "T\u1ec9nh l\u1ed9 10B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17907",
            name: "T\u1ec9nh l\u1ed9 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17908",
            name: "T\u1ec9nh L\u1ed9 814",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17909",
            name: "T\u1ec9nh l\u1ed9 822",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17910",
            name: "T\u1ec9nh l\u1ed9 823",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17911",
            name: "T\u1ec9nh l\u1ed9 824",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17912",
            name: "T\u1ec9nh l\u1ed9 825",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17913",
            name: "T\u1ec9nh l\u1ed9 827",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17914",
            name: "T\u1ec9nh l\u1ed9 830",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17915",
            name: "T\u1ec9nh l\u1ed9 833",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17916",
            name: "T\u1ec9nh l\u1ed9 834",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17917",
            name: "T\u1ec9nh l\u1ed9 835B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17918",
            name: "T\u1ec9nh L\u1ed9 835D",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17919", name: "T\u1ec9nh l\u1ed9 838", prefix: "Ph\u1ed1" },
          {
            id: "17920",
            name: "T\u1ec9nh l\u1ed9 839",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17921",
            name: "T\u1ec9nh l\u1ed9 840",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17922",
            name: "T\u1ec9nh l\u1ed9 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17923",
            name: "T\u1ec9nh L\u1ed9 Sa B\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17924", name: "TN3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17925",
            name: "Tr\u1ea7n Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17926",
            name: "Tr\u1ea7n Anh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17927",
            name: "Tr\u1ea7n V\u0103n Gi\u00e0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17928",
            name: "Tr\u1ea7n V\u0103n Li\u1ec5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17929",
            name: "Tr\u1ea7n V\u0103n Ng\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17930",
            name: "Tr\u01b0\u01a1ng T\u1ea5n Sang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17931",
            name: "Tr\u01b0\u01a1ng Th\u1ecb Giao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17932",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17933", name: "\u00dat An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17934",
            name: "V\u00e2n D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17935",
            name: "V\u00e0nh \u0110ai 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17936",
            name: "V\u00e0nh \u0110ai 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17937",
            name: "V\u00f5 \u0110\u00ecnh T\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17938",
            name: "V\u00f5 T\u1ea5n \u0110\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17939",
            name: "V\u00f5 Trung Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17940",
            name: "V\u00f5 V\u0103n Ng\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17941",
            name: "V\u00f5 V\u0103n T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17942",
            name: "X\u00f3m R\u1eebng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17943",
            name: "Xuy\u00ean \u00c1",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2533",
            name: "An H\u1ea1 Riverside",
            lat: "10.784456253052",
            lng: "106.49708557129",
          },
          {
            id: "2534",
            name: "An Khang 2",
            lat: "10.832867622375",
            lng: "106.49948883057",
          },
          {
            id: "2535",
            name: "An Ph\u00fac Center",
            lat: "10.790534973145",
            lng: "106.47592926025",
          },
          {
            id: "2536",
            name: "An Ph\u00fac Riverside",
            lat: "10.902338027954",
            lng: "106.4185333252",
          },
          {
            id: "2537",
            name: "B\u1ea3o Ng\u1ecdc Resident Long An",
            lat: "10.871070861816",
            lng: "106.48078155518",
          },
          {
            id: "2538",
            name: "Bella Villa",
            lat: "10.832471847534",
            lng: "106.46354675293",
          },
          {
            id: "2539",
            name: "C\u00e1t T\u01b0\u1eddng Golden River Residence",
            lat: "10.913774490356",
            lng: "106.31694793701",
          },
          {
            id: "2540",
            name: "C\u00e1t T\u01b0\u1eddng Ph\u00fa Nguy\u00ean Residence",
            lat: "10.870142936707",
            lng: "106.4973449707",
          },
          {
            id: "2541",
            name: "C\u00e1t T\u01b0\u1eddng Ph\u00fa Sinh Eco City",
            lat: "10.881859779358",
            lng: "106.4708480835",
          },
          {
            id: "2542",
            name: "C\u00e1t T\u01b0\u1eddng Ph\u00fa Th\u1ea1nh",
            lat: "10.769820213318",
            lng: "106.41851043701",
          },
          {
            id: "2543",
            name: "\u0110\u1ee9c H\u00f2a III Resco - Daresco Residence",
            lat: "10.939969062805",
            lng: "106.44950866699",
          },
          {
            id: "2544",
            name: "\u0110\u1ee9c H\u00f2a Residence",
            lat: "10.802939414978",
            lng: "106.46278381348",
          },
          {
            id: "2545",
            name: "E.City T\u00e2n \u0110\u1ee9c",
            lat: "10.80017375946",
            lng: "106.44091033936",
          },
          {
            id: "2546",
            name: "Eco Village",
            lat: "10.783395767212",
            lng: "106.49638366699",
          },
          {
            id: "2547",
            name: "Golden City Long An",
            lat: "10.821814537048",
            lng: "106.45708465576",
          },
          {
            id: "2548",
            name: "H\u1ea3i S\u01a1n",
            lat: "10.79536819458",
            lng: "106.48009490967",
          },
          {
            id: "2549",
            name: "HHome",
            lat: "10.902338027954",
            lng: "106.4185333252",
          },
          {
            id: "2550",
            name: "Hi\u1ec3n Vinh Garden",
            lat: "10.863038063049",
            lng: "106.44647979736",
          },
          {
            id: "2551",
            name: "KCN H\u1ea3i S\u01a1n",
            lat: "10.796590805054",
            lng: "106.47328948975",
          },
          {
            id: "2552",
            name: "KCN T\u00e2n \u0110\u00f4",
            lat: "10.778155326843",
            lng: "106.49176025391",
          },
          {
            id: "2553",
            name: "KDC Gia Th\u1ecbnh",
            lat: "10.863038063049",
            lng: "106.44647979736",
          },
          {
            id: "2554",
            name: "KDC T\u00e2n \u0110\u00f4",
            lat: "10.778424263",
            lng: "106.48916625977",
          },
          {
            id: "2555",
            name: "L\u00e0ng Sen Vi\u1ec7t Nam",
            lat: "10.811682701111",
            lng: "106.4969329834",
          },
          {
            id: "2556",
            name: "Lexington Garden",
            lat: "10.881937026978",
            lng: "106.47164916992",
          },
          {
            id: "2557",
            name: "Long An Areca Villa",
            lat: "10.902338027954",
            lng: "106.4185333252",
          },
          {
            id: "2558",
            name: "M\u1ef9 H\u1ea1nh Ho\u00e0ng Gia",
            lat: "10.85719871521",
            lng: "106.50051879883",
          },
          {
            id: "2559",
            name: "Natural Village",
            lat: "10.784142494202",
            lng: "106.49322509766",
          },
          {
            id: "2560",
            name: "New Moon",
            lat: "10.820436477661",
            lng: "106.45516204834",
          },
          {
            id: "2561",
            name: "Organic Garden",
            lat: "10.900596618652",
            lng: "106.4153137207",
          },
          {
            id: "2562",
            name: "Ph\u1ed1 chuy\u00ean gia C\u00e1t T\u01b0\u1eddng Ph\u00fa Nam",
            lat: "10.871440887451",
            lng: "106.51718139648",
          },
          {
            id: "2563",
            name: "Ph\u00fac An City",
            lat: "10.874680519104",
            lng: "106.51742553711",
          },
          {
            id: "2564",
            name: "Ph\u00fac Th\u1ecbnh Residence",
            lat: "10.823473930359",
            lng: "106.45776367188",
          },
          {
            id: "2565",
            name: "Royal Central",
            lat: "10.823914527893",
            lng: "106.4561920166",
          },
          {
            id: "2566",
            name: "Thi\u00ean Ph\u00fac Ho\u00e0ng Gia",
            lat: "10.87072467804",
            lng: "106.50537872314",
          },
          {
            id: "2567",
            name: "Vista Land",
            lat: "10.984469413757",
            lng: "106.5657119751",
          },
          {
            id: "2568",
            name: "Wintown",
            lat: "10.792115211487",
            lng: "106.43125152588",
          },
        ],
      },
      {
        id: "111",
        name: "\u0110\u1ee9c Hu\u1ec7",
        wards: [
          {
            id: "1696",
            name: "B\u00ecnh H\u00f2a B\u1eafc",
            prefix: "X\u00e3",
          },
          {
            id: "1697",
            name: "B\u00ecnh H\u00f2a H\u01b0ng",
            prefix: "X\u00e3",
          },
          { id: "1698", name: "B\u00ecnh H\u00f2a Nam", prefix: "X\u00e3" },
          { id: "1699", name: "B\u00ecnh Th\u00e0nh", prefix: "X\u00e3" },
          {
            id: "1700",
            name: "\u0110\u00f4ng Th\u00e0nh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "1701", name: "M\u1ef9 B\u00ecnh", prefix: "X\u00e3" },
          {
            id: "1702",
            name: "M\u1ef9 Qu\u00fd \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "1703", name: "M\u1ef9 Qu\u00fd T\u00e2y", prefix: "X\u00e3" },
          {
            id: "1704",
            name: "M\u1ef9 Th\u1ea1nh B\u1eafc",
            prefix: "X\u00e3",
          },
          {
            id: "1705",
            name: "M\u1ef9 Th\u1ea1nh \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "1706",
            name: "M\u1ef9 Th\u1ea1nh T\u00e2y",
            prefix: "X\u00e3",
          },
        ],
        streets: [
          { id: "17944", name: "\u0110T 816", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17945", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17946",
            name: "Qu\u1ed1c L\u1ed9 838",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17947",
            name: "T\u1ec9nh  L\u1ed9 818",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17948",
            name: "T\u1ec9nh l\u1ed9 786",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17949",
            name: "T\u1ec9nh l\u1ed9 816",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17950",
            name: "T\u1ec9nh l\u1ed9 822",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17951", name: "T\u1ec9nh l\u1ed9 838", prefix: "Ph\u1ed1" },
          {
            id: "17952",
            name: "T\u1ec9nh l\u1ed9 839",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "112",
        name: "Ki\u1ebfn T\u01b0\u1eddng",
        wards: [
          { id: "1707", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "1708", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "1709", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "1710", name: "B\u00ecnh Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "1711", name: "B\u00ecnh T\u00e2n", prefix: "X\u00e3" },
          { id: "1712", name: "Th\u1ea1nh H\u01b0ng", prefix: "X\u00e3" },
          { id: "1713", name: "Th\u1ea1nh Tr\u1ecb", prefix: "X\u00e3" },
          { id: "1714", name: "Tuy\u00ean Th\u1ea1nh", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "17953",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17954",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "17955",
            name: "Qu\u1ed1c L\u1ed9 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17956",
            name: "V\u00f5 V\u0103n \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "113",
        name: "M\u1ed9c H\u00f3a",
        wards: [
          {
            id: "1715",
            name: "B\u00ecnh H\u00f2a \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "1716",
            name: "B\u00ecnh H\u00f2a T\u00e2y",
            prefix: "X\u00e3",
          },
          { id: "1717", name: "B\u00ecnh H\u00f2a Trung", prefix: "X\u00e3" },
          { id: "1718", name: "B\u00ecnh Phong Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "1719", name: "B\u00ecnh Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "1720", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "1721", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "17957",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17958",
            name: "H\u01b0\u01a1ng l\u1ed9 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17959",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17960",
            name: "Nguy\u1ec5n Th\u00e0nh A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17961",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17962",
            name: "Qu\u1ed1c L\u1ed9 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17963",
            name: "Thi\u00ean H\u1ed9 D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17964",
            name: "T\u1ec9nh L\u1ed9 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "114",
        name: "T\u00e2n An",
        wards: [
          { id: "1722", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "1723", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "1724", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "1725", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "1726", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "1727", name: "6", prefix: "Ph\u01b0\u1eddng" },
          { id: "1728", name: "7", prefix: "Ph\u01b0\u1eddng" },
          { id: "1729", name: "An V\u0129nh Ng\u00e3i", prefix: "X\u00e3" },
          { id: "1730", name: "B\u00ecnh T\u00e2m", prefix: "X\u00e3" },
          {
            id: "1731",
            name: "H\u01b0\u1edbng Th\u1ecd Ph\u00fa",
            prefix: "X\u00e3",
          },
          {
            id: "1732",
            name: "Kh\u00e1nh H\u1eadu",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1733",
            name: "L\u1ee3i B\u00ecnh Nh\u01a1n",
            prefix: "X\u00e3",
          },
          { id: "1734", name: "Nh\u01a1n Th\u1ea1nh Trung", prefix: "X\u00e3" },
          {
            id: "1735",
            name: "T\u00e2n Kh\u00e1nh",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "17965", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17966", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17967", name: "24", prefix: "Ph\u1ed1" },
          { id: "17968", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17969", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17970", name: "68", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17971", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17972", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17973",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17974",
            name: "B\u1ea3o \u0110inh m\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17975",
            name: "B\u00f9i Ch\u00ed Nhu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17976",
            name: "Cao V\u0103n L\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17977",
            name: "Ch\u00e2u Th\u1ecb Kim",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17978",
            name: "Ch\u00e2u V\u0103n B\u1ea3y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17979",
            name: "Ch\u00e2u V\u0103n Gi\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17980",
            name: "C\u00f4ng V\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17981",
            name: "C\u1eed Luy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17982", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17983", name: "D5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "17984", name: "D8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17985",
            name: "\u0110\u1eb7ng C\u00f4ng Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17986",
            name: "\u0110\u1eb7ng V\u0103n Truy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17987",
            name: "\u0110inh Thi\u1ebfu S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17988",
            name: "\u0110inh Vi\u1ebft C\u1eebu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17989",
            name: "\u0110\u1ed7 Tr\u00ecnh Tho\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17990",
            name: "\u0110\u1ed7 T\u01b0\u1eddng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17991",
            name: "\u0110\u1ed7 T\u01b0\u1eddng T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "17992", name: "\u0110T 833", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "17993",
            name: "Gi\u1ed3ng Dinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17994",
            name: "H\u1ed3 V\u0103n Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17995",
            name: "H\u00f2a An 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17996",
            name: "Ho\u00e0ng Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17997",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17998",
            name: "H\u01b0\u01a1ng l\u1ed9 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "17999",
            name: "Hu\u1ef3nh Ch\u00e2u S\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18000",
            name: "Hu\u1ef3nh Ho\u00e0ng Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18001",
            name: "Hu\u1ef3nh H\u1eefu Th\u1ed1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18002",
            name: "Hu\u1ef3nh Th\u1ecb \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18003",
            name: "Hu\u1ef3nh V\u0103n \u0110\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18004",
            name: "Hu\u1ef3nh V\u0103n G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18005",
            name: "Hu\u1ef3nh V\u0103n Nh\u1ee9t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18006",
            name: "Hu\u1ef3nh V\u0103n T\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18007",
            name: "Hu\u1ef3nh Vi\u1ec7t Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18008",
            name: "Kh\u01b0\u01a1ng Minh Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18009",
            name: "L\u00ea Anh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18010",
            name: "L\u00ea H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18011",
            name: "L\u00ea Hu\u1ef3nh \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18012",
            name: "L\u00ea Th\u1ecb \u0110i\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18013",
            name: "L\u00ea V\u0103n C\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18014",
            name: "L\u00ea V\u0103n Ki\u00ea\u0323t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18015",
            name: "L\u00ea V\u0103n L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18016",
            name: "L\u00ea V\u0103n Tao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18017",
            name: "L\u00ea V\u0103n T\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18018",
            name: "L\u00ea V\u0103n T\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18019",
            name: "L\u01b0\u01a1ng V\u0103n Ch\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18020",
            name: "L\u01b0u V\u0103n T\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18021",
            name: "Mai B\u00e1 H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18022", name: "Mai Thu", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18023", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18024", name: "N4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18025", name: "N8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18026",
            name: "N\u0103m Gi\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18027",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18028",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18029",
            name: "Nguy\u1ec5n C\u00f4ng Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18030",
            name: "Nguy\u1ec5n C\u1eedu V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18033",
            name: "Nguy\u1ec5n Duy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18031",
            name: "Nguy\u1ec5n \u0110\u1ea1i Th\u1eddi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18032",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18034",
            name: "Nguy\u1ec5n H\u1ed3n S\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18035",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18036",
            name: "Nguy\u1ec5n H\u1eefu Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18037",
            name: "Nguy\u1ec5n Hu\u1ef3nh \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18038",
            name: "Nguy\u1ec5n Kim C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18039",
            name: "Nguy\u1ec5n Minh \u0110\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18040",
            name: "Nguy\u1ec5n Minh Tr\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18041",
            name: "Nguy\u1ec5n Ng\u1ecdc Sanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18042",
            name: "Nguy\u1ec5n Quang \u0110\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18043",
            name: "Nguy\u1ec5n T\u1ea5n Ch\u00edn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18044",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18045",
            name: "Nguy\u1ec5n Thanh C\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18046",
            name: "Nguy\u1ec5n Th\u1ecb B\u1ea3y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18047",
            name: "Nguy\u1ec5n Th\u1ecb H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18048",
            name: "Nguy\u1ec5n Th\u1ecb L\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18049",
            name: "Nguy\u1ec5n Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18050",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18051",
            name: "Nguy\u1ec5n V\u0103n C\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18052",
            name: "Nguy\u1ec5n V\u0103n Nh\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18053",
            name: "Nguy\u1ec5n V\u0103n Qu\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18054",
            name: "Nguy\u1ec5n V\u0103n R\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18055",
            name: "Nguy\u1ec5n V\u0103n T\u1ecbch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18056",
            name: "Nguy\u1ec5n V\u0103n Ti\u1ebfp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18057",
            name: "Nguy\u1ec5n V\u0103n Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18058",
            name: "Ph\u1ea1m V\u0103n Ng\u00f4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18059",
            name: "Ph\u1ea1m V\u0103n Tr\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18060",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18061",
            name: "Phan \u0110\u00f4ng S\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18062",
            name: "Ph\u1ea7n L\u0103ng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18063",
            name: "Phan V\u0103n L\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18064",
            name: "Phan V\u0103n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18065",
            name: "Phan V\u0103n Tu\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18066",
            name: "Qu\u00e1ch V\u0103n Tu\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18067",
            name: "Qu\u1ed1c L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18068",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18069",
            name: "Qu\u1ed1c L\u1ed9 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18070",
            name: "Quy\u1ebft Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18071", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18072", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18073", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18074", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18075", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18076", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18077", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18078", name: "S\u1ed1 24", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18079", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18080", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18081", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18082", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18083", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18084", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18085", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18086",
            name: "Song H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18087",
            name: "S\u01b0\u01a1ng Nguy\u1ec7t \u00c1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18088",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18089",
            name: "Thanh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18090",
            name: "Th\u1ee7 Khoa Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18091",
            name: "T\u1ec9nh L\u1ed9 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18092",
            name: "T\u1ec9nh l\u1ed9 827",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18093",
            name: "T\u1ec9nh l\u1ed9 827A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18094",
            name: "T\u1ec9nh l\u1ed9 833",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18095",
            name: "Tr\u00e0 Qu\u00fd B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18096",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18097",
            name: "Tr\u1ea7n Minh Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18098",
            name: "Tr\u1ea7n Phong S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18099",
            name: "Tr\u1ea7n Trung Tam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18100",
            name: "Tr\u1ea7n V\u0103n Ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18101",
            name: "Tr\u1ea7n V\u0103n \u0110\u1ea5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18102",
            name: "Tr\u1ea7n V\u0103n Gi\u00e0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18103",
            name: "Tr\u1ea7n V\u0103n H\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18104",
            name: "Tr\u1ea7n V\u0103n Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18105",
            name: "Tr\u1ea7n V\u0103n Ng\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18106",
            name: "Tr\u1ecbnh Quang Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18107",
            name: "Tr\u01b0\u01a1ng C\u00f4ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18108",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18109",
            name: "Tr\u01b0\u01a1ng Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18110",
            name: "Tr\u01b0\u01a1ng V\u0103n Bang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18111",
            name: "V\u00e0nh \u0110ai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18112",
            name: "V\u00f5 C\u00f4ng T\u1ed3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18113",
            name: "V\u00f5 Duy T\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18114",
            name: "V\u00f5 Ng\u1ecdc Qu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18115",
            name: "V\u00f5 Ph\u01b0\u1edbc C\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18116",
            name: "V\u00f5 T\u1ea5n \u0110\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18117",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18118",
            name: "V\u00f5 V\u0103n M\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18119",
            name: "V\u00f5 V\u0103n T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2569",
            name: "An Nhi\u00ean Garden",
            lat: "10.533009529114",
            lng: "106.40525054932",
          },
          {
            id: "2570",
            name: "Green City",
            lat: "10.549499511719",
            lng: "106.40700531006",
          },
          {
            id: "2571",
            name: "Idico T\u00e2n An",
            lat: "10.546810150146",
            lng: "106.39849853516",
          },
          {
            id: "2572",
            name: "Khu \u0111\u00f4 th\u1ecb ven s\u00f4ng V\u00e0m C\u1ecf",
            lat: "10.561027526855",
            lng: "106.39692687988",
          },
        ],
      },
      {
        id: "115",
        name: "T\u00e2n H\u01b0ng",
        wards: [
          {
            id: "1736",
            name: "B\u00ecnh Th\u1ea1nh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "1737", name: "H\u01b0ng \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "1738", name: "H\u01b0ng \u0110i\u1ec1n B", prefix: "X\u00e3" },
          { id: "1739", name: "H\u01b0ng H\u00e0", prefix: "X\u00e3" },
          { id: "1740", name: "H\u01b0ng Th\u1ea1nh", prefix: "X\u00e3" },
          {
            id: "1741",
            name: "T\u00e2n H\u01b0ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "1742", name: "Th\u1ea1nh H\u01b0ng", prefix: "X\u00e3" },
          { id: "1743", name: "V\u0129nh B\u1eefu", prefix: "X\u00e3" },
          { id: "1744", name: "V\u0129nh Ch\u00e2u A", prefix: "X\u00e3" },
          { id: "1745", name: "V\u0129nh Ch\u00e2u B", prefix: "X\u00e3" },
          { id: "1746", name: "V\u0129nh \u0110\u1ea1i", prefix: "X\u00e3" },
          { id: "1747", name: "V\u0129nh L\u1ee3i", prefix: "X\u00e3" },
          { id: "1748", name: "V\u0129nh Th\u1ea1nh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "18120", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18121", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18122",
            name: "\u0110\u01b0\u1eddng AA",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18123",
            name: "\u0110\u01b0\u1eddng GB",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18124", name: "G5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18125",
            name: "G\u00f2 Thuy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18126",
            name: "T\u1ec9nh l\u1ed9 831",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18127",
            name: "T\u1ec9nh l\u1ed9 842",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "116",
        name: "T\u00e2n Th\u1ea1nh",
        wards: [
          { id: "1749", name: "B\u1eafc Ho\u00e0", prefix: "X\u00e3" },
          {
            id: "1750",
            name: "H\u1eadu Th\u1ea1nh \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "1751",
            name: "H\u1eadu Th\u1ea1nh T\u00e2y",
            prefix: "X\u00e3",
          },
          { id: "1752", name: "Ki\u1ebfn B\u00ecnh", prefix: "X\u00e3" },
          { id: "1753", name: "Nh\u01a1n Ho\u00e0", prefix: "X\u00e3" },
          {
            id: "1754",
            name: "Nh\u01a1n Ho\u00e0 L\u1eadp",
            prefix: "X\u00e3",
          },
          { id: "1755", name: "Nh\u01a1n Ninh", prefix: "X\u00e3" },
          { id: "1756", name: "T\u00e2n B\u00ecnh", prefix: "X\u00e3" },
          { id: "1757", name: "T\u00e2n Ho\u00e0", prefix: "X\u00e3" },
          { id: "1758", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "1759", name: "T\u00e2n Ninh", prefix: "X\u00e3" },
          { id: "1760", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          {
            id: "1761",
            name: "T\u00e2n Th\u1ea1nh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          {
            id: "18128",
            name: "H\u01b0\u01a1ng l\u1ed9 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18129", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18130",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18131",
            name: "Qu\u1ed1c L\u1ed9 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18132",
            name: "Qu\u1ed1c l\u1ed9 N2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18133",
            name: "T\u1ec9nh l\u1ed9 829",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18134",
            name: "T\u1ec9nh L\u1ed9 837",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "117",
        name: "T\u00e2n Tr\u1ee5",
        wards: [
          { id: "1762", name: "An Nh\u1ef1t T\u00e2n", prefix: "X\u00e3" },
          { id: "1763", name: "B\u00ecnh L\u00e3ng", prefix: "X\u00e3" },
          { id: "1764", name: "B\u00ecnh T\u1ecbnh", prefix: "X\u00e3" },
          {
            id: "1765",
            name: "B\u00ecnh Trinh \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "1766", name: "\u0110\u1ee9c T\u00e2n", prefix: "X\u00e3" },
          { id: "1767", name: "L\u1ea1c T\u1ea5n", prefix: "X\u00e3" },
          { id: "1768", name: "M\u1ef9 B\u00ecnh", prefix: "X\u00e3" },
          { id: "1769", name: "Nh\u1ef1t Ninh", prefix: "X\u00e3" },
          {
            id: "1770",
            name: "Qu\u00ea M\u1ef9 Th\u1ea1nh",
            prefix: "X\u00e3",
          },
          {
            id: "1771",
            name: "T\u00e2n Ph\u01b0\u1edbc T\u00e2y",
            prefix: "X\u00e3",
          },
          {
            id: "1772",
            name: "T\u00e2n Tr\u1ee5",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          {
            id: "18135",
            name: "Cao Th\u1ecb Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18136", name: "\u0110an", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18137", name: "\u0110T 832", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18138", name: "\u0110T 833", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18139", name: "\u0110T 833B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18140",
            name: "H\u00f2a Nam 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18141",
            name: "Hu\u1ef3nh V\u0103n \u0110\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18142",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18143",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18144", name: "Thanh Phong", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18145",
            name: "T\u1ec9nh l\u1ed9 827",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18146",
            name: "T\u1ec9nh l\u1ed9 832",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18147",
            name: "T\u1ec9nh l\u1ed9 833",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18148",
            name: "T\u1ec9nh L\u1ed9 833B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18149",
            name: "T\u1ec9nh l\u1ed9 Cai T\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "118",
        name: "Th\u1ea1nh H\u00f3a",
        wards: [
          { id: "1773", name: "T\u00e2n \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "1774", name: "T\u00e2n Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "1775", name: "T\u00e2n T\u00e2y", prefix: "X\u00e3" },
          { id: "1776", name: "Th\u1ea1nh An", prefix: "X\u00e3" },
          {
            id: "1777",
            name: "Th\u1ea1nh H\u00f3a",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "1778", name: "Th\u1ea1nh Ph\u00fa", prefix: "X\u00e3" },
          { id: "1779", name: "Th\u1ea1nh Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "1780", name: "Thu\u1eadn B\u00ecnh", prefix: "X\u00e3" },
          {
            id: "1781",
            name: "Thu\u1eadn Ngh\u0129a H\u00f2a",
            prefix: "X\u00e3",
          },
          { id: "1782", name: "Th\u1ee7y \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "1783", name: "Th\u1ee7y T\u00e2y", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "18150", name: "836", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18151",
            name: "H\u01b0\u01a1ng l\u1ed9 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18152", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18153",
            name: "Qu\u1ed1c L\u1ed9 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18154",
            name: "T\u1ec9nh l\u1ed9 836",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "119",
        name: "Th\u1ee7 Th\u1eeba",
        wards: [
          { id: "1784", name: "B\u00ecnh An", prefix: "X\u00e3" },
          { id: "1785", name: "B\u00ecnh Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "1786", name: "Long Th\u00e0nh", prefix: "X\u00e3" },
          { id: "1787", name: "Long Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "1788", name: "Long Thu\u1eadn", prefix: "X\u00e3" },
          { id: "1789", name: "M\u1ef9 An", prefix: "X\u00e3" },
          { id: "1790", name: "M\u1ef9 L\u1ea1c", prefix: "X\u00e3" },
          { id: "1791", name: "M\u1ef9 Ph\u00fa", prefix: "X\u00e3" },
          { id: "1792", name: "M\u1ef9 Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "1793", name: "Nh\u1ecb Th\u00e0nh", prefix: "X\u00e3" },
          { id: "1794", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "1795", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "1796", name: "Th\u1ee7 Th\u1eeba", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "18155", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18156", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18157", name: "834", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18158", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18159",
            name: "An Ph\u00fa \u0110\u00f4ng 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18160",
            name: "B\u00ecnh L\u01b0\u01a1ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18161",
            name: "C\u00f4ng V\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18162",
            name: "\u0110\u1ed7 \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18163",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18164", name: "\u0110T 818", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18165", name: "\u0110T 834", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18166",
            name: "H\u1eadu L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18167",
            name: "Hi\u1ec7p Th\u00e0nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18168",
            name: "H\u01b0\u01a1ng l\u1ed9 28",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18169",
            name: "H\u01b0\u01a1ng L\u1ed9 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18170",
            name: "H\u01b0\u01a1ng l\u1ed9 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18171",
            name: "L\u1ed9 L\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18172",
            name: "L\u1ed9c H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18173",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18174",
            name: "M\u01b0\u01a1ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18175",
            name: "Nguy\u1ec5n Huy Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18176",
            name: "Nguy\u1ec5n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18177",
            name: "Nguy\u1ec5n V\u0103n Th\u1eddi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18178", name: "NT1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18179",
            name: "Phan V\u0103n T\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18180",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18181",
            name: "Qu\u1ed1c L\u1ed9 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18182",
            name: "Qu\u1ed1c l\u1ed9 N2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18183", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18184", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18185", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18186", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18187", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18188",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18189",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18190",
            name: "Th\u1ea1nh L\u1ed9c 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18191",
            name: "Th\u1ea1nh Xu\u00e2n 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18192",
            name: "Th\u1ee7 Khoa Th\u1eeba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18193",
            name: "T\u1ec9nh  L\u1ed9 818",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18194",
            name: "T\u1ec9nh l\u1ed9 834",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18195",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18196",
            name: "V\u00e0nh \u0110ai 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2573",
            name: "Everluck Residence",
            lat: "10.603224754333",
            lng: "106.41168212891",
          },
          {
            id: "2574",
            name: "Th\u1ee7 Th\u1eeba Ph\u00fa Thanh",
            lat: "10.600511550903",
            lng: "106.40624237061",
          },
        ],
      },
      {
        id: "120",
        name: "V\u0129nh H\u01b0ng",
        wards: [
          { id: "1797", name: "H\u01b0ng \u0110i\u1ec1n A", prefix: "X\u00e3" },
          { id: "1798", name: "Kh\u00e1nh H\u01b0ng", prefix: "X\u00e3" },
          { id: "1799", name: "Th\u00e1i B\u00ecnh Trung", prefix: "X\u00e3" },
          { id: "1800", name: "Th\u00e1i Tr\u1ecb", prefix: "X\u00e3" },
          { id: "1801", name: "Tuy\u00ean B\u00ecnh", prefix: "X\u00e3" },
          {
            id: "1802",
            name: "Tuy\u00ean B\u00ecnh T\u00e2y",
            prefix: "X\u00e3",
          },
          { id: "1803", name: "V\u0129nh B\u00ecnh", prefix: "X\u00e3" },
          {
            id: "1804",
            name: "V\u0129nh H\u01b0ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "1805", name: "V\u0129nh Thu\u1eadn", prefix: "X\u00e3" },
          { id: "1806", name: "V\u0129nh Tr\u1ecb", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "18197",
            name: "Hu\u1ef3nh V\u0103n T\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18198",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18199",
            name: "Qu\u1ed1c L\u1ed9 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18200",
            name: "V\u00f5 V\u0103n T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "9",
    code: "QNA",
    name: "Qu\u1ea3ng Nam",
    districts: [
      {
        id: "121",
        name: "B\u1eafc Tr\u00e0 My",
        wards: [
          { id: "1807", name: "Tr\u00e0 Bui", prefix: "X\u00e3" },
          { id: "1810", name: "Tr\u00e0 D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "1808", name: "Tr\u00e0 \u0110\u1ed1c", prefix: "X\u00e3" },
          { id: "1809", name: "Tr\u00e0 \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "1811", name: "Tr\u00e0 Gi\u00e1c", prefix: "X\u00e3" },
          { id: "1812", name: "Tr\u00e0 Giang", prefix: "X\u00e3" },
          { id: "1813", name: "Tr\u00e0 Gi\u00e1p", prefix: "X\u00e3" },
          { id: "1814", name: "Tr\u00e0 Ka", prefix: "X\u00e3" },
          { id: "1815", name: "Tr\u00e0 K\u00f3t", prefix: "X\u00e3" },
          { id: "1816", name: "Tr\u00e0 My", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "1817", name: "Tr\u00e0 N\u00fa", prefix: "X\u00e3" },
          { id: "1818", name: "Tr\u00e0 S\u01a1n", prefix: "X\u00e3" },
          { id: "1819", name: "Tr\u00e0 T\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "18201",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18202",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "125",
        name: "Duy Xuy\u00ean",
        wards: [
          { id: "1869", name: "Duy Ch\u00e2u", prefix: "X\u00e3" },
          { id: "1870", name: "Duy H\u1ea3i", prefix: "X\u00e3" },
          { id: "1871", name: "Duy H\u00f2a", prefix: "X\u00e3" },
          { id: "1872", name: "Duy Ngh\u0129a", prefix: "X\u00e3" },
          { id: "1873", name: "Duy Ph\u00fa", prefix: "X\u00e3" },
          { id: "1874", name: "Duy Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "1875", name: "Duy S\u01a1n", prefix: "X\u00e3" },
          { id: "1876", name: "Duy T\u00e2n", prefix: "X\u00e3" },
          { id: "1877", name: "Duy Th\u00e0nh", prefix: "X\u00e3" },
          { id: "1878", name: "Duy Thu", prefix: "X\u00e3" },
          { id: "1879", name: "Duy Trinh", prefix: "X\u00e3" },
          { id: "1880", name: "Duy Trung", prefix: "X\u00e3" },
          { id: "1881", name: "Duy Vinh", prefix: "X\u00e3" },
          {
            id: "1882",
            name: "Nam Ph\u01b0\u1edbc",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "1883",
            name: "Ph\u01b0\u1edbc Nam",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          { id: "18291", name: "28/3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18292", name: "610", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18293",
            name: "B\u00f9i T\u1ea5n Di\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18294", name: "\u0110T 610", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18295",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18296",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18297",
            name: "Nguy\u1ec5n Th\u00e0nh H\u00e3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18298",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18299",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18300",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "18301",
            name: "Tr\u01b0\u01a1ng Chi\u0301 C\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18302",
            name: "Tr\u01b0\u1eddng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "122",
        name: "\u0110\u1ea1i L\u1ed9c",
        wards: [
          {
            id: "1820",
            name: "\u00c1i Ngh\u0129a",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "1821", name: "\u0110\u1ea1i An", prefix: "X\u00e3" },
          { id: "1822", name: "\u0110\u1ea1i Ch\u00e1nh", prefix: "X\u00e3" },
          {
            id: "1823",
            name: "\u0110\u1ea1i C\u01b0\u1eddng",
            prefix: "X\u00e3",
          },
          {
            id: "1824",
            name: "\u0110\u1ea1i \u0110\u1ed3ng",
            prefix: "X\u00e3",
          },
          { id: "1825", name: "\u0110\u1ea1i Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "1826", name: "\u0110\u1ea1i H\u00f2a", prefix: "X\u00e3" },
          { id: "1827", name: "\u0110\u1ea1i H\u1ed3ng", prefix: "X\u00e3" },
          { id: "1828", name: "\u0110\u1ea1i H\u01b0ng", prefix: "X\u00e3" },
          { id: "1829", name: "\u0110\u1ea1i L\u00e3nh", prefix: "X\u00e3" },
          { id: "1830", name: "\u0110\u1ea1i Minh", prefix: "X\u00e3" },
          { id: "1831", name: "\u0110\u1ea1i Ngh\u0129a", prefix: "X\u00e3" },
          { id: "1832", name: "\u0110\u1ea1i Phong", prefix: "X\u00e3" },
          { id: "1833", name: "\u0110\u1ea1i Quang", prefix: "X\u00e3" },
          { id: "1834", name: "\u0110\u1ea1i S\u01a1n", prefix: "X\u00e3" },
          { id: "1835", name: "\u0110\u1ea1i T\u00e2n", prefix: "X\u00e3" },
          { id: "1836", name: "\u0110\u1ea1i Th\u1eafng", prefix: "X\u00e3" },
          { id: "1837", name: "\u0110\u1ea1i Th\u1ea1nh", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "18203",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18204", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18205",
            name: "Qu\u1ed1c L\u1ed9 14B",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "123",
        name: "\u0110i\u1ec7n B\u00e0n",
        wards: [
          { id: "1838", name: "\u0110i\u1ec7n An", prefix: "X\u00e3" },
          {
            id: "1839",
            name: "\u0110i\u1ec7n D\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "1840", name: "\u0110i\u1ec7n H\u00f2a", prefix: "X\u00e3" },
          { id: "1841", name: "\u0110i\u1ec7n H\u1ed3ng", prefix: "X\u00e3" },
          { id: "1842", name: "\u0110i\u1ec7n Minh", prefix: "X\u00e3" },
          {
            id: "1843",
            name: "\u0110i\u1ec7n Nam B\u1eafc",
            prefix: "X\u00e3",
          },
          {
            id: "1844",
            name: "\u0110i\u1ec7n Nam \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "1845", name: "\u0110i\u1ec7n Nam Trung", prefix: "X\u00e3" },
          { id: "1846", name: "\u0110i\u1ec7n Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "1847", name: "\u0110i\u1ec7n Phong", prefix: "X\u00e3" },
          {
            id: "1848",
            name: "\u0110i\u1ec7n Ph\u01b0\u1edbc",
            prefix: "X\u00e3",
          },
          {
            id: "1849",
            name: "\u0110i\u1ec7n Ph\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "1850", name: "\u0110i\u1ec7n Quang", prefix: "X\u00e3" },
          {
            id: "1851",
            name: "\u0110i\u1ec7n Th\u1eafng B\u1eafc",
            prefix: "X\u00e3",
          },
          {
            id: "1852",
            name: "\u0110i\u1ec7n Th\u1eafng Nam",
            prefix: "X\u00e3",
          },
          {
            id: "1853",
            name: "\u0110i\u1ec7n Th\u1eafng Trung",
            prefix: "X\u00e3",
          },
          { id: "1854", name: "\u0110i\u1ec7n Th\u1ecd", prefix: "X\u00e3" },
          { id: "1855", name: "\u0110i\u1ec7n Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "1856", name: "\u0110i\u1ec7n Trung", prefix: "X\u00e3" },
          {
            id: "1857",
            name: "V\u0129nh \u0110i\u1ec7n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          { id: "18206", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18207", name: "603", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18208", name: "607A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18209",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18210",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          {
            id: "18211",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18212",
            name: "Cao H\u1ed3ng L\u00e3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18213",
            name: "Cao S\u01a1n Ph\u00e1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18214",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18215",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18226",
            name: "D\u0169ng S\u0129",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18227",
            name: "D\u0169ng S\u0129 \u0110i\u1ec7n Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18228",
            name: "D\u01b0\u01a1ng C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18229", name: "Duy T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18216",
            name: "\u0110a M\u1eb7n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18217",
            name: "\u0110\u00e0o T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18218",
            name: "\u0110i\u1ec7n D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18219", name: "\u0110T 603", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18220", name: "\u0110T 605", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18221", name: "\u0110T 607", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18222", name: "\u0110T 607A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18223", name: "\u0110T 607B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18224", name: "\u0110T 608", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18225", name: "\u0110T 609", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18230",
            name: "H\u1ed3 Nghinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18231",
            name: "Hoa Anh \u0110\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18232",
            name: "H\u00f2a \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18233",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18234",
            name: "Ho\u00e0ng H\u1eefu Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18235",
            name: "Ho\u00e0ng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18236",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18237",
            name: "L\u00ea Anh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18238",
            name: "L\u00ea \u0110\u1ec9nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18239",
            name: "L\u00ea \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18240",
            name: "L\u00ea \u0110\u1ee9c Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18241",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18242",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18243",
            name: "L\u00ea Tr\u00ed Vi\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18244",
            name: "L\u00ea V\u0103n Hi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18245",
            name: "L\u01b0u Quang V\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18246",
            name: "Mai Th\u00fac Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18247",
            name: "Mai Xu\u00e2n Th\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18248",
            name: "M\u1eb9 Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18249", name: "Nam Cao", prefix: "Ph\u1ed1" },
          {
            id: "18250",
            name: "Nam K\u1ef3 Kh\u1edfi Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18251",
            name: "Nam Tr\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18252",
            name: "Ng\u00f4 M\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18253",
            name: "Ng\u00f4 T\u1ea5t T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18254",
            name: "Nguy\u1ec5n Cao Luy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18255",
            name: "Nguy\u1ec5n C\u01b0 Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18256",
            name: "Nguy\u1ec5n Gia Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18257",
            name: "Nguy\u1ec5n Nho T\u00fay",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18258",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18259",
            name: "Ph\u1ea1m Kh\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18260",
            name: "Ph\u1ea1m Nh\u01b0 X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18261",
            name: "Ph\u1ea1m Ph\u00fa Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18262",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18263",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18264", name: "Phan Thanh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18265",
            name: "Phan Thanh Gi\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18266",
            name: "Phan Th\u00fac Duy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18267",
            name: "Qu\u00e1ch Th\u1ecb Trang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18268",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18269", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18270",
            name: "S\u01a1n Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18271",
            name: "Th\u1ebf L\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18272", name: "Thi S\u00e1ch", prefix: "Ph\u1ed1" },
          {
            id: "18273",
            name: "T\u00f4 V\u0129nh Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18274",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18275",
            name: "T\u1ed1ng Duy T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18276",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "18277",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18278",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18279",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18280",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18281",
            name: "Tr\u1ea7n Th\u1ecb L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18282",
            name: "Tr\u1ea7n Th\u1ee7 \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18283",
            name: "Tr\u1ea7n V\u0103n Gi\u00e0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18284",
            name: "Tr\u01b0\u1eddng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18285",
            name: "T\u1ee9 Ng\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18286",
            name: "T\u00fa X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18287",
            name: "V\u0103n Ti\u1ebfn D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18288",
            name: "V\u00e0nh \u0110ai 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18289",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18290",
            name: "V\u00f5 Nh\u01b0 H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2575",
            name: "3 \u0110i\u1ec7n Nam \u0110i\u1ec7n Ng\u1ecdc",
            lat: "15.961597442627",
            lng: "108.25785064697",
          },
          {
            id: "2576",
            name: "7 \u0110i\u1ec7n Nam \u0110i\u1ec7n Ng\u1ecdc",
            lat: "15.953543663025",
            lng: "108.25691986084",
          },
          {
            id: "2577",
            name: "9 \u0110i\u1ec7n Nam \u0110i\u1ec7n Ng\u1ecdc",
            lat: "15.953708648682",
            lng: "108.25657653809",
          },
          {
            id: "2578",
            name: "Asia Village",
            lat: "15.914963722229",
            lng: "108.26877593994",
          },
          {
            id: "2579",
            name: "Central Gate",
            lat: "15.925643920898",
            lng: "108.22722625732",
          },
          {
            id: "2580",
            name: "Coco Center House",
            lat: "15.952430725098",
            lng: "108.27313232422",
          },
          {
            id: "2581",
            name: "Coco Garden City",
            lat: "15.926984786987",
            lng: "108.30300140381",
          },
          {
            id: "2582",
            name: "Coco Gardenia",
            lat: "15.891564369202",
            lng: "108.24778747559",
          },
          {
            id: "2583",
            name: "Coco River Garden",
            lat: "15.964643478394",
            lng: "108.2749786377",
          },
          {
            id: "2584",
            name: "Coco River Side City",
            lat: "15.975116729736",
            lng: "108.26361083984",
          },
          {
            id: "2585",
            name: "\u0110i\u1ec7n Nam Trung",
            lat: "21.0306224823",
            lng: "105.84886932373",
          },
          {
            id: "2586",
            name: "Eco Future Park",
            lat: "15.944807052612",
            lng: "108.25985717773",
          },
          {
            id: "2587",
            name: "Gaia City",
            lat: "15.953708648682",
            lng: "108.25554656982",
          },
          {
            id: "2588",
            name: "Golden Eleven",
            lat: "15.950820922852",
            lng: "108.27263641357",
          },
          {
            id: "2589",
            name: "Green City",
            lat: "15.955325126648",
            lng: "108.28190612793",
          },
          {
            id: "2590",
            name: "Green Park City",
            lat: "15.956659317017",
            lng: "108.26280212402",
          },
          {
            id: "2591",
            name: "Hera Complex Riverside",
            lat: "15.97575378418",
            lng: "108.26594543457",
          },
          {
            id: "2592",
            name: "H\u1ed9i An Green Village",
            lat: "15.898263931274",
            lng: "108.27630615234",
          },
          {
            id: "2593",
            name: "H\u1ed9i An Sky Garden",
            lat: "15.888102531433",
            lng: "108.29029083252",
          },
          {
            id: "2594",
            name: "K\u0110T An Ph\u00fa Qu\u00fd",
            lat: "15.947428703308",
            lng: "108.25895690918",
          },
          {
            id: "2595",
            name: "K\u0110T B\u00e1ch \u0110\u1ea1t",
            lat: "15.938106536865",
            lng: "108.26780700684",
          },
          {
            id: "2596",
            name: "Khu \u0111\u00f4 th\u1ecb An C\u01b0",
            lat: "15.953708648682",
            lng: "108.25554656982",
          },
          {
            id: "2597",
            name: "Khu \u0111\u00f4 th\u1ecb An Th\u1ecbnh",
            lat: "15.948410987854",
            lng: "108.25360870361",
          },
          {
            id: "2598",
            name: "Khu \u0111\u00f4 th\u1ecb D\u01b0\u01a1ng Ng\u1ecdc",
            lat: "15.954493522644",
            lng: "108.29042816162",
          },
          {
            id: "2599",
            name: "Khu \u0111\u00f4 th\u1ecb M\u1ef9 Gia Nam \u0110\u00e0 N\u1eb5ng",
            lat: "15.953708648682",
            lng: "108.25554656982",
          },
          {
            id: "2600",
            name: "Khu \u0111\u00f4 th\u1ecb s\u1ed1 4",
            lat: "15.953708648682",
            lng: "108.25531005859",
          },
          {
            id: "2601",
            name: "Khu ph\u1ed1 ch\u1ee3 \u0110i\u1ec7n Nam B\u1eafc",
            lat: "15.93689250946",
            lng: "108.25582885742",
          },
          {
            id: "2602",
            name: "New \u0110i\u1ec7n D\u01b0\u01a1ng City",
            lat: "15.926999092102",
            lng: "108.30235290527",
          },
          {
            id: "2603",
            name: "Ng\u00e2n C\u00e2u Ng\u00e2n Giang",
            lat: "15.953853607178",
            lng: "108.26086425781",
          },
          {
            id: "2604",
            name: "Ng\u1ecdc D\u01b0\u01a1ng Riverside",
            lat: "15.95567035675",
            lng: "108.2908782959",
          },
          {
            id: "2605",
            name: "Pan Pacific \u0110\u00e0 N\u1eb5ng Resort",
            lat: "15.960008621216",
            lng: "108.28965759277",
          },
          {
            id: "2606",
            name: "Ph\u1ed1 Ch\u1ee3 \u0110i\u1ec7n Ng\u1ecdc",
            lat: "15.954484939575",
            lng: "108.25489807129",
          },
          {
            id: "2607",
            name: "River View",
            lat: "15.926984786987",
            lng: "108.30300140381",
          },
          {
            id: "2608",
            name: "Sakura Central Park",
            lat: "15.952965736389",
            lng: "108.27477264404",
          },
          {
            id: "2609",
            name: "Sentosa Luxury",
            lat: "15.953708648682",
            lng: "108.25554656982",
          },
          {
            id: "2610",
            name: "Sentosa Riverside",
            lat: "15.949115753174",
            lng: "108.28495788574",
          },
          {
            id: "2611",
            name: "Sun Gate City",
            lat: "15.957670211792",
            lng: "108.26262664795",
          },
          {
            id: "2612",
            name: "Sun River City",
            lat: "15.891564369202",
            lng: "108.24778747559",
          },
          {
            id: "2613",
            name: "Th\u00e1i D\u01b0\u01a1ng 2",
            lat: "15.953708648682",
            lng: "108.25554656982",
          },
          {
            id: "2614",
            name: "The Vista",
            lat: "15.946506500244",
            lng: "108.25903320312",
          },
          {
            id: "2615",
            name: "Tr\u1ea3ng Nh\u1eadt",
            lat: "15.936782836914",
            lng: "108.22292327881",
          },
          {
            id: "2616",
            name: "Vanesea Field \u0110\u00e0 N\u1eb5ng Resort",
            lat: "15.991744041443",
            lng: "108.27398681641",
          },
          {
            id: "2617",
            name: "V\u0129nh \u0110i\u1ec7n Center House",
            lat: "15.888498306274",
            lng: "108.25132751465",
          },
          {
            id: "2618",
            name: "Vision City",
            lat: "15.953337669373",
            lng: "108.27448272705",
          },
        ],
      },
      {
        id: "124",
        name: "\u0110\u00f4ng Giang",
        wards: [
          { id: "1858", name: "A Rooi", prefix: "X\u00e3" },
          { id: "1859", name: "A Ting", prefix: "X\u00e3" },
          { id: "1860", name: "Ba", prefix: "X\u00e3" },
          { id: "1861", name: "J\u01a1 Ng\u00e2y", prefix: "X\u00e3" },
          { id: "1862", name: "Ka D\u0103ng", prefix: "X\u00e3" },
          { id: "1863", name: "M\u00e0 Cooi", prefix: "X\u00e3" },
          { id: "1864", name: "Prao", prefix: "X\u00e3" },
          { id: "1865", name: "S\u00f4ng K\u00f4n", prefix: "X\u00e3" },
          { id: "1866", name: "T\u00e0 Lu", prefix: "X\u00e3" },
          { id: "1867", name: "T\u01b0", prefix: "X\u00e3" },
          { id: "1868", name: "Za Hung", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "126",
        name: "Hi\u1ec7p \u0110\u1ee9c",
        wards: [
          { id: "1884", name: "B\u00ecnh L\u00e2m", prefix: "X\u00e3" },
          { id: "1885", name: "B\u00ecnh S\u01a1n", prefix: "X\u00e3" },
          { id: "1886", name: "Hi\u1ec7p H\u00f2a", prefix: "X\u00e3" },
          { id: "1887", name: "Hi\u1ec7p Thu\u1eadn", prefix: "X\u00e3" },
          { id: "1888", name: "Ph\u01b0\u1edbc Gia", prefix: "X\u00e3" },
          { id: "1889", name: "Ph\u01b0\u1edbc Tr\u00e0", prefix: "X\u00e3" },
          { id: "1890", name: "Qu\u1ebf B\u00ecnh", prefix: "X\u00e3" },
          { id: "1891", name: "Qu\u1ebf L\u01b0u", prefix: "X\u00e3" },
          { id: "1892", name: "Qu\u1ebf Th\u1ecd", prefix: "X\u00e3" },
          { id: "1893", name: "S\u00f4ng Tr\u00e0", prefix: "X\u00e3" },
          { id: "1894", name: "T\u00e2n An", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "1895", name: "Th\u0103ng Ph\u01b0\u1edbc", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "18303",
            name: "Qu\u1ed1c L\u1ed9 14E",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "127",
        name: "H\u1ed9i An",
        wards: [
          { id: "1896", name: "C\u1ea9m An", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1897",
            name: "C\u1ea9m Ch\u00e2u",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1898", name: "C\u1ea9m H\u00e0", prefix: "X\u00e3" },
          { id: "1899", name: "C\u1ea9m Kim", prefix: "X\u00e3" },
          { id: "1900", name: "C\u1ea9m Nam", prefix: "Ph\u01b0\u1eddng" },
          { id: "1901", name: "C\u1ea9m Ph\u00f4", prefix: "Ph\u01b0\u1eddng" },
          { id: "1902", name: "C\u1ea9m Thanh", prefix: "X\u00e3" },
          {
            id: "1903",
            name: "C\u1eeda \u0110\u1ea1i",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "1904", name: "Minh An", prefix: "Ph\u01b0\u1eddng" },
          { id: "1905", name: "S\u01a1n Phong", prefix: "Ph\u01b0\u1eddng" },
          { id: "1906", name: "T\u00e2n An", prefix: "Ph\u01b0\u1eddng" },
          { id: "1907", name: "T\u00e2n Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "1908", name: "Thanh H\u00e0", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "18304", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18305", name: "18/8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18306", name: "28/3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18307",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18308",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18309", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "18310",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18311",
            name: "B\u1ebfn Tr\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18312",
            name: "B\u00f9i Ch\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18313",
            name: "Cafe Chi\u1ec1u H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18314",
            name: "C\u1ea9m \u0110\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18315", name: "C\u1ea9m Nam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18316",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18317",
            name: "Ch\u1ebf Lan Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18318",
            name: "C\u00f4ng N\u1eef Ng\u1ecdc Hoa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18319",
            name: "C\u1eeda \u0110\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18326", name: "Duy T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18327", name: "DX 016", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18328", name: "DX 017", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18329", name: "DX 018", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18330", name: "DX 024", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18331", name: "DX 030", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18332", name: "DX 039", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18320",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18321",
            name: "\u0110\u00ea Pam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18322",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18323",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18324",
            name: "\u0110\u1ed7 \u0110\u0103ng Tuy\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18325",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18333", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "18334",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18335",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18336",
            name: "Ho\u00e0ng H\u1eefu Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18337",
            name: "Ho\u00e0ng Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18338",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18339",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18340",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18341",
            name: "Huy\u1ec1n Tr\u00e2n C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18342",
            name: "Hu\u1ef3nh L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18343",
            name: "Hu\u1ef3nh Ng\u1ecdc Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18344",
            name: "Hu\u1ef3nh Th\u1ecb L\u1ef1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18345",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18346", name: "La H\u1ed1i", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18347",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18348",
            name: "L\u00ea \u0110\u1ec9nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18349",
            name: "L\u00ea \u0110\u00ecnh D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18350",
            name: "L\u00ea \u0110\u00ecnh Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18351",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18352",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18353",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18354",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18355",
            name: "L\u00ea V\u0103n Hi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18356",
            name: "L\u00ea V\u0103n H\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "18357",
            name: "L\u01b0\u01a1ng Nh\u01b0 B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18358",
            name: "L\u01b0u Qu\u00fd K\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18359",
            name: "L\u01b0u Tr\u1ecdng L\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18360",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18361",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18362",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "18363",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18364",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18365",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18366",
            name: "Ng\u00f4 S\u0129 Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18367",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18368",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18369",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18371",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18373",
            name: "Nguy\u1ec5n Duy Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18370",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18372",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18374",
            name: "Nguy\u1ec5n Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18375",
            name: "Nguy\u1ec5n Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18376",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18377",
            name: "Nguy\u1ec5n Ng\u1ecdc Nh\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18378",
            name: "Nguy\u1ec5n Phan Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18379",
            name: "Nguy\u1ec5n Ph\u00fac Chu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18380",
            name: "Nguy\u1ec5n Ph\u00fac Nguy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18381",
            name: "Nguy\u1ec5n Ph\u00fac T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18382",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18383",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18384",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18385",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18386",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18387",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18388",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18389",
            name: "Nguy\u1ec5n Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18390",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18391",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18392",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18393",
            name: "Nh\u1ecb Tr\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18394",
            name: "N\u00fai Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18395",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18396",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18397",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18398",
            name: "Phan B\u00e1 Phi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18399",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18400",
            name: "Phan Ch\u00e2u Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18401",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18402",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "18403",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18404",
            name: "Phan Ng\u1ecdc Nh\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18405", name: "Phan Thanh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18406",
            name: "Ph\u00f9 \u0110\u1ed5ng Thi\u00ean V\u01b0\u01a1ng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "18407",
            name: "S\u01a1n Ph\u00f4 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18408",
            name: "S\u01a1n Ph\u00f4 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18409",
            name: "S\u01a1n Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18410",
            name: "Th\u00e1i Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18411",
            name: "Thanh H\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18412",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18413",
            name: "Th\u00edch Qu\u1ea3ng \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18414",
            name: "Tho\u1ea1i Ng\u1ecdc H\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18415", name: "Ti\u1ec3u La", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18416",
            name: "T\u00f4 V\u0129nh Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18417",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "18418",
            name: "T\u1ed1ng V\u0103n S\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18419",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18420",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18421",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18422",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18423",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18424",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18425",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18426",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18427",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18428",
            name: "Tr\u1ea7n V\u0103n D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18429",
            name: "Tr\u01b0ng N\u1eef V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18430",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18431",
            name: "Tr\u01b0\u01a1ng Minh H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18432",
            name: "Tr\u01b0\u01a1ng Minh L\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18433",
            name: "Tr\u01b0\u1eddng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18434",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18435",
            name: "V\u0103n Ti\u1ebfn D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18436",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18437",
            name: "X\u00f4 Vi\u1ebft Ngh\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18438",
            name: "Xu\u00e2n Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2619",
            name: "C\u1ea9m An - H\u1ed9i An",
            lat: "15.904230117798",
            lng: "108.34812927246",
          },
          {
            id: "2620",
            name: "Fusion Alya H\u1ed9i An",
            lat: "15.884652137756",
            lng: "108.38563537598",
          },
          {
            id: "2621",
            name: "H\u00f4\u0323i An Luxury",
            lat: "15.883943557739",
            lng: "108.38525390625",
          },
          {
            id: "2622",
            name: "Hoi An Royal Residence",
            lat: "15.88371181488",
            lng: "108.34749603271",
          },
          {
            id: "2623",
            name: "K\u0110T Tr\u1ea3ng K\u00e8o",
            lat: "15.896476745605",
            lng: "108.31837463379",
          },
          {
            id: "2624",
            name: "New H\u1ed9i An City",
            lat: "15.918086051941",
            lng: "108.33061218262",
          },
          {
            id: "2625",
            name: "Smart City H\u1ed9i An",
            lat: "15.890617370605",
            lng: "108.29850006104",
          },
          {
            id: "2626",
            name: "Time H\u1ed9i An",
            lat: "15.881932258606",
            lng: "108.33341217041",
          },
        ],
      },
      {
        id: "128",
        name: "Nam Giang",
        wards: [
          { id: "1909", name: "C\u00e0 Dy", prefix: "X\u00e3" },
          { id: "1910", name: "Ch\u00e0 V\u00e0l", prefix: "X\u00e3" },
          { id: "1911", name: "Ch\u01a1 Chun", prefix: "X\u00e3" },
          { id: "1912", name: "\u0110\u0103k Pree", prefix: "X\u00e3" },
          { id: "1913", name: "\u0110\u1eafk Pring", prefix: "X\u00e3" },
          { id: "1914", name: "\u0110\u1eafk T\u00f4i", prefix: "X\u00e3" },
          { id: "1915", name: "La D\u00ea\u00ea", prefix: "X\u00e3" },
          { id: "1916", name: "La \u00ca\u00ea", prefix: "X\u00e3" },
          { id: "1917", name: "T\u00e0 Bhing", prefix: "X\u00e3" },
          { id: "1918", name: "T\u00e0 P\u01a1\u01a1", prefix: "X\u00e3" },
          {
            id: "1919",
            name: "Th\u1ea1nh M\u1ef9",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "1920", name: "Zu\u00f4ih", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "129",
        name: "Nam Tr\u00e0 My",
        wards: [
          { id: "1921", name: "Tr\u00e0 Cang", prefix: "X\u00e3" },
          { id: "1922", name: "Tr\u00e0 Don", prefix: "X\u00e3" },
          { id: "1923", name: "Tr\u00e0 D\u01a1n", prefix: "X\u00e3" },
          { id: "1924", name: "Tr\u00e0 Leng", prefix: "X\u00e3" },
          { id: "1925", name: "Tr\u00e0 Linh", prefix: "X\u00e3" },
          { id: "1926", name: "Tr\u00e0 Mai", prefix: "X\u00e3" },
          { id: "1927", name: "Tr\u00e0 Nam", prefix: "X\u00e3" },
          { id: "1928", name: "Tr\u00e0 T\u1eadp", prefix: "X\u00e3" },
          { id: "1929", name: "Tr\u00e0 V\u00e2n", prefix: "X\u00e3" },
          { id: "1930", name: "Tr\u00e0 Vinh", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "130",
        name: "N\u00f4ng S\u01a1n",
        wards: [
          { id: "1931", name: "Ph\u01b0\u1edbc Ninh", prefix: "X\u00e3" },
          { id: "1932", name: "Qu\u1ebf L\u00e2m", prefix: "X\u00e3" },
          { id: "1933", name: "Qu\u1ebf L\u1ed9c", prefix: "X\u00e3" },
          { id: "1934", name: "Qu\u1ebf Ninh", prefix: "X\u00e3" },
          { id: "1935", name: "Qu\u1ebf Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "1936", name: "Qu\u1ebf Trung", prefix: "X\u00e3" },
          { id: "1937", name: "S\u01a1n Vi\u00ean", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "131",
        name: "N\u00fai Th\u00e0nh",
        wards: [
          {
            id: "1938",
            name: "N\u00fai Th\u00e0nh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "1939", name: "Tam Anh B\u1eafc", prefix: "X\u00e3" },
          { id: "1940", name: "Tam Anh Nam", prefix: "X\u00e3" },
          { id: "1941", name: "Tam Giang", prefix: "X\u00e3" },
          { id: "1942", name: "Tam H\u1ea3i", prefix: "X\u00e3" },
          { id: "1943", name: "Tam Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "1944", name: "Tam H\u00f2a", prefix: "X\u00e3" },
          { id: "1945", name: "Tam M\u1ef9 \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "1946", name: "Tam M\u1ef9 T\u00e2y", prefix: "X\u00e3" },
          { id: "1947", name: "Tam Ngh\u0129a", prefix: "X\u00e3" },
          { id: "1948", name: "Tam Quang", prefix: "X\u00e3" },
          { id: "1949", name: "Tam S\u01a1n", prefix: "X\u00e3" },
          { id: "1950", name: "Tam Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "1951", name: "Tam Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "1952", name: "Tam Tr\u00e0", prefix: "X\u00e3" },
          { id: "1953", name: "Tam Xu\u00e2n 1", prefix: "X\u00e3" },
          { id: "1954", name: "Tam Xu\u00e2n 2", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "18439", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18440", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18441", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18442",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18443",
            name: "L\u00ea V\u0103n Hi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18444",
            name: "Nguy\u1ec5n Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18445",
            name: "N\u00fai Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18446",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18447",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18448", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18449", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
      {
        id: "132",
        name: "Ph\u00fa Ninh",
        wards: [
          {
            id: "1955",
            name: "Ph\u00fa Th\u1ecbnh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "1956", name: "Tam An", prefix: "X\u00e3" },
          { id: "1958", name: "Tam D\u00e2n", prefix: "X\u00e3" },
          { id: "1957", name: "Tam \u0110\u1ea1i", prefix: "X\u00e3" },
          { id: "1959", name: "Tam \u0110\u00e0n", prefix: "X\u00e3" },
          { id: "1960", name: "Tam L\u00e3nh", prefix: "X\u00e3" },
          { id: "1961", name: "Tam L\u1ed9c", prefix: "X\u00e3" },
          { id: "1962", name: "Tam Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "1963", name: "Tam Th\u00e1i", prefix: "X\u00e3" },
          { id: "1964", name: "Tam Th\u00e0nh", prefix: "X\u00e3" },
          { id: "1965", name: "Tam Vinh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "18450", name: "\u0110T 615", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18451",
            name: "L\u00ea V\u0103n Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18452",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18453",
            name: "Qu\u1ed1c L\u1ed9 40B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18454", name: "Tam Vinh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18455",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "133",
        name: "Ph\u01b0\u1edbc S\u01a1n",
        wards: [
          {
            id: "1966",
            name: "Kh\u00e2m \u0110\u1ee9c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "1967", name: "Ph\u01b0\u1edbc Ch\u00e1nh", prefix: "X\u00e3" },
          { id: "1968", name: "Ph\u01b0\u1edbc C\u00f4ng", prefix: "X\u00e3" },
          {
            id: "1969",
            name: "Ph\u01b0\u1edbc \u0110\u1ee9c",
            prefix: "X\u00e3",
          },
          { id: "1970", name: "Ph\u01b0\u1edbc Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "1971", name: "Ph\u01b0\u1edbc H\u00f2a", prefix: "X\u00e3" },
          { id: "1972", name: "Ph\u01b0\u1edbc Kim", prefix: "X\u00e3" },
          { id: "1973", name: "Ph\u01b0\u1edbc L\u1ed9c", prefix: "X\u00e3" },
          { id: "1974", name: "Ph\u01b0\u1edbc M\u1ef9", prefix: "X\u00e3" },
          { id: "1975", name: "Ph\u01b0\u1edbc N\u0103ng", prefix: "X\u00e3" },
          { id: "1976", name: "Ph\u01b0\u1edbc Th\u00e0nh", prefix: "X\u00e3" },
          { id: "1977", name: "Ph\u01b0\u1edbc Xu\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "18456", name: "611", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18457",
            name: "Mai Th\u00fac Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18458",
            name: "Nguy\u1ec5n Duy Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18459",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "134",
        name: "Qu\u1ebf S\u01a1n",
        wards: [
          {
            id: "1978",
            name: "\u0110\u00f4ng Ph\u00fa",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "1979", name: "H\u01b0\u01a1ng An", prefix: "X\u00e3" },
          { id: "1980", name: "Ph\u00fa Th\u1ecd", prefix: "X\u00e3" },
          { id: "1981", name: "Qu\u1ebf An", prefix: "X\u00e3" },
          { id: "1982", name: "Qu\u1ebf Ch\u00e2u", prefix: "X\u00e3" },
          { id: "1983", name: "Qu\u1ebf C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "1984", name: "Qu\u1ebf Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "1985", name: "Qu\u1ebf Long", prefix: "X\u00e3" },
          { id: "1986", name: "Qu\u1ebf Minh", prefix: "X\u00e3" },
          { id: "1987", name: "Qu\u1ebf Phong", prefix: "X\u00e3" },
          { id: "1988", name: "Qu\u1ebf Ph\u00fa", prefix: "X\u00e3" },
          { id: "1989", name: "Qu\u1ebf Thu\u1eadn", prefix: "X\u00e3" },
          { id: "1990", name: "Qu\u1ebf Xu\u00e2n 1", prefix: "X\u00e3" },
          { id: "1991", name: "Qu\u1ebf Xu\u00e2n 2", prefix: "X\u00e3" },
          { id: "1992", name: "Qu\u00fd Thu\u1eadn", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "18460",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18461",
            name: "Phan Ch\u00e2u Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18462",
            name: "Qu\u1ed1c L\u1ed9 14B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18463",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18464",
            name: "Th\u1ecd Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18465",
            name: "Tr\u01b0ng N\u1eef V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18466",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "135",
        name: "Tam K\u1ef3",
        wards: [
          { id: "1993", name: "An M\u1ef9", prefix: "Ph\u01b0\u1eddng" },
          { id: "1994", name: "An Ph\u00fa", prefix: "Ph\u01b0\u1eddng" },
          { id: "1995", name: "An S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "1996", name: "An Xu\u00e2n", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "1997",
            name: "H\u00f2a H\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1998",
            name: "H\u00f2a Thu\u1eadn",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "1999",
            name: "Ph\u01b0\u1edbc H\u00f2a",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2000", name: "Tam Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "2001", name: "Tam Ph\u00fa", prefix: "X\u00e3" },
          { id: "2002", name: "Tam Th\u0103ng", prefix: "X\u00e3" },
          { id: "2003", name: "Tam Thanh", prefix: "X\u00e3" },
          {
            id: "2004",
            name: "T\u00e2n Th\u1ea1nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2005",
            name: "Tr\u01b0\u1eddng Xu\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "18467", name: "11/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18468", name: "24/3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18469", name: "An H\u00e0", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18470", name: "An H\u1ea1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18471", name: "An H\u00f2a", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18472", name: "An Th\u1ed5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18473",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          {
            id: "18474",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18475",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18476",
            name: "B\u00f9i Th\u1ebf M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18477",
            name: "C\u1ea7m B\u00e1 Th\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18478",
            name: "Cao H\u1ed3ng L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18479",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18480",
            name: "Ch\u00e2u Th\u01b0\u1ee3ng V\u0103n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18481",
            name: "Ch\u00e2u V\u0103n Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18482",
            name: "Ch\u1ebf Lan Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18483",
            name: "Chi\u1ebfn Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18484",
            name: "Ch\u01b0\u01a1ng D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18485",
            name: "D\u00e3 T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18497", name: "Duy T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18486", name: "\u0110\u1eb7ng Dung", prefix: "Ph\u1ed1" },
          {
            id: "18487",
            name: "\u0110\u1eb7ng Tr\u1ea7n C\u00f4n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "18488",
            name: "\u0110\u1eb7ng V\u0103n Ng\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18489",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18490",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18491", name: "\u0110inh Li\u1ec7t", prefix: "Ph\u1ed1" },
          {
            id: "18492",
            name: "\u0110inh N\u00fap",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18493",
            name: "\u0110\u1ed7 Th\u1ebf Ch\u1ea5p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18494",
            name: "\u0110o\u00e0n K\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18495",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18496",
            name: "\u0110\u1ed3ng Ph\u01b0\u1edbc Huy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18498",
            name: "H\u00e0 Huy Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18499",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18500",
            name: "H\u1ed3 Nghinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18501",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18502",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18503",
            name: "Ho\u00e0ng B\u00edch S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18504",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18505",
            name: "Ho\u00e0ng H\u1eefu Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18506",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18507",
            name: "H\u01b0\u01a1ng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18508",
            name: "Huy\u1ec1n Tr\u00e2n C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18509",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18510",
            name: "Kh\u01b0\u01a1ng H\u1eefu D\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18511",
            name: "L\u00e2m Quang Th\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18512", name: "Lam S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18513",
            name: "L\u00ea Anh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18514",
            name: "L\u00ea B\u00e1 Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18515",
            name: "L\u00ea C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18516",
            name: "L\u00ea \u0110\u1ec9nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18517",
            name: "L\u00ea \u0110\u00ecnh D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18518",
            name: "L\u00ea \u0110\u00ecnh Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18519",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18520",
            name: "L\u00ea Ng\u1ecdc H\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18521",
            name: "L\u00ea Ph\u1ee5ng Hi\u1ec3u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "18522",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18523",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18524",
            name: "L\u00ea Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18525",
            name: "L\u00ea Th\u1ecb Xuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18526",
            name: "L\u00ea Tr\u00ed Vi\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18527",
            name: "L\u00ea Trung \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18528",
            name: "L\u00ea V\u0103n Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18529",
            name: "L\u00ea V\u0129nh Huy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18530",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18531",
            name: "L\u01b0\u01a1ng V\u0103n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18532",
            name: "L\u01b0u Qu\u00fd K\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18533",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          { id: "18534", name: "N10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18535", name: "N24", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18536",
            name: "Nam Quang Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18537",
            name: "Nam Tr\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18538",
            name: "Ng\u00f4 \u0110\u1ee9c K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18539",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18540",
            name: "Ng\u00f4 S\u0129 Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18541",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18542",
            name: "Nguy\u1ec5n B\u00e1 Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18543",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18545",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18546",
            name: "Nguy\u1ec5n D\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18544",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18547",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18548",
            name: "Nguy\u1ec5n \u0110\u1ee9c Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18549",
            name: "Nguy\u1ec5n Gia Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18550",
            name: "Nguy\u1ec5n Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18551",
            name: "Nguy\u1ec5n Huy Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18552",
            name: "Nguy\u00ean Phi \u1ef6 Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18553",
            name: "Nguy\u1ec5n Phong S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18554",
            name: "Nguy\u1ec5n Ph\u00fac Chu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18555",
            name: "Nguy\u1ec5n Qu\u00fd \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18556",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18557",
            name: "Nguy\u1ec5n Th\u00e0nh H\u00e3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18558",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18559",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18560",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "18561",
            name: "Nguy\u1ec5n Thu\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18562",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18563",
            name: "Nguy\u1ec5n Tr\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18564",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18565",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18566",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18567",
            name: "Nguy\u1ec5n Xu\u00e2n Nh\u0129",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18568",
            name: "\u00d4ng \u00cdch \u0110\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18569",
            name: "\u00d4ng \u00cdch Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18570",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18571",
            name: "Ph\u1ea1m Kh\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18572",
            name: "Ph\u1ea1m Nh\u1eef T\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18573",
            name: "Ph\u1ea1m Nh\u01b0 X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18574",
            name: "Ph\u1ea1m Ph\u00fa Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18575",
            name: "Ph\u1ea1m V\u0103n X\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18576",
            name: "Phan B\u00e1 Phi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18577",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18578",
            name: "Phan Ch\u00e2u Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18579",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18580",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18581", name: "Phan Thanh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18582",
            name: "Phan Th\u00e0nh T\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18583",
            name: "Phan T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18584",
            name: "Phan V\u0103n \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18585",
            name: "Qu\u00e1ch Th\u1ecb Trang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18586", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18587",
            name: "T\u00e2n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18588",
            name: "Th\u00e1i Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18589",
            name: "Th\u00e2n Nh\u00e2n Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18590",
            name: "Thanh H\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18591",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18592", name: "Ti\u1ec3u La", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18593",
            name: "T\u1ec9nh l\u1ed9 616",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18594",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "18595",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18596",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18599",
            name: "Tr\u1ea7n D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18597",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "18598",
            name: "Tr\u1ea7n \u0110\u00ecnh Tri",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18600",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18601",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18602",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18603",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18604",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18605",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18606",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18607",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18608",
            name: "Tr\u1ea7n Th\u1ecb L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18609",
            name: "Tr\u1ea7n Th\u1ee7 \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18610",
            name: "Tr\u1ea7n Thuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18611",
            name: "Tr\u01b0ng N\u1eef V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18612",
            name: "Tr\u01b0\u01a1ng Chi\u0301 C\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18613",
            name: "Tr\u01b0\u01a1ng C\u00f4ng Huy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18614",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18615",
            name: "Tr\u01b0\u01a1ng Quang Giao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18616",
            name: "V\u0103n Th\u1ecb Th\u1eeba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18617",
            name: "V\u00f5 Th\u00e0nh N\u0103m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18618",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18619",
            name: "V\u01b0\u1eddn C\u1eeba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18620",
            name: "Xu\u00e2n Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18621",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2627",
            name: "Pointe91",
            lat: "15.442228317261",
            lng: "108.69857788086",
          },
        ],
      },
      {
        id: "136",
        name: "T\u00e2y Giang",
        wards: [
          { id: "2006", name: "A N\u00f4ng", prefix: "X\u00e3" },
          { id: "2007", name: "A Ti\u00eang", prefix: "X\u00e3" },
          { id: "2008", name: "A V\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "2009", name: "A Xan", prefix: "X\u00e3" },
          { id: "2010", name: "Bha L\u00ea", prefix: "X\u00e3" },
          { id: "2011", name: "Ch'\u01a1m", prefix: "X\u00e3" },
          { id: "2012", name: "Dang", prefix: "X\u00e3" },
          { id: "2013", name: "Ga Ri", prefix: "X\u00e3" },
          { id: "2014", name: "L\u0103ng", prefix: "X\u00e3" },
          { id: "2015", name: "Tr'hy", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "137",
        name: "Th\u0103ng B\u00ecnh",
        wards: [
          { id: "2016", name: "B\u00ecnh An", prefix: "X\u00e3" },
          { id: "2017", name: "B\u00ecnh Ch\u00e1nh", prefix: "X\u00e3" },
          { id: "2021", name: "B\u00ecnh D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "2018", name: "B\u00ecnh \u0110\u00e0o", prefix: "X\u00e3" },
          {
            id: "2019",
            name: "B\u00ecnh \u0110\u1ecbnh B\u1eafc",
            prefix: "X\u00e3",
          },
          {
            id: "2020",
            name: "B\u00ecnh \u0110\u1ecbnh Nam",
            prefix: "X\u00e3",
          },
          { id: "2022", name: "B\u00ecnh Giang", prefix: "X\u00e3" },
          { id: "2023", name: "B\u00ecnh H\u1ea3i", prefix: "X\u00e3" },
          { id: "2024", name: "B\u00ecnh L\u00e3nh", prefix: "X\u00e3" },
          { id: "2025", name: "B\u00ecnh Minh", prefix: "X\u00e3" },
          { id: "2026", name: "B\u00ecnh Nam", prefix: "X\u00e3" },
          { id: "2027", name: "B\u00ecnh Nguy\u00ean", prefix: "X\u00e3" },
          { id: "2028", name: "B\u00ecnh Ph\u00fa", prefix: "X\u00e3" },
          { id: "2029", name: "B\u00ecnh Ph\u1ee5c", prefix: "X\u00e3" },
          { id: "2030", name: "B\u00ecnh Qu\u1ebf", prefix: "X\u00e3" },
          { id: "2031", name: "B\u00ecnh Qu\u00fd", prefix: "X\u00e3" },
          { id: "2032", name: "B\u00ecnh Sa", prefix: "X\u00e3" },
          { id: "2033", name: "B\u00ecnh Tr\u1ecb", prefix: "X\u00e3" },
          { id: "2034", name: "B\u00ecnh Tri\u1ec1u", prefix: "X\u00e3" },
          { id: "2035", name: "B\u00ecnh Trung", prefix: "X\u00e3" },
          { id: "2036", name: "B\u00ecnh T\u00fa", prefix: "X\u00e3" },
          { id: "2037", name: "H\u00e0 Lam", prefix: "Th\u1ecb tr\u1ea5n" },
        ],
        streets: [
          { id: "18622", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18623",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18624",
            name: "Th\u00e1i Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18625",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18626", name: "Ti\u1ec3u La", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18627",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18628",
            name: "Tr\u1ea7n Th\u1ecb L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2628",
            name: "B\u00ecnh Nguy\u00ean Qu\u1ea3ng Nam",
            lat: "15.738080978394",
            lng: "108.36125946045",
          },
          {
            id: "2629",
            name: "K\u0110T An B\u00ecnh",
            lat: "15.738080978394",
            lng: "108.36125946045",
          },
          {
            id: "2630",
            name: "Vinpearl Nam H\u1ed9i An",
            lat: "15.806900024414",
            lng: "108.38606262207",
          },
        ],
      },
      {
        id: "138",
        name: "Ti\u00ean Ph\u01b0\u1edbc",
        wards: [
          { id: "2038", name: "Ti\u00ean An", prefix: "X\u00e3" },
          { id: "2039", name: "Ti\u00ean C\u1ea9m", prefix: "X\u00e3" },
          { id: "2040", name: "Ti\u00ean C\u1ea3nh", prefix: "X\u00e3" },
          { id: "2041", name: "Ti\u00ean Ch\u00e2u", prefix: "X\u00e3" },
          { id: "2042", name: "Ti\u00ean H\u00e0", prefix: "X\u00e3" },
          { id: "2043", name: "Ti\u00ean Hi\u1ec7p", prefix: "X\u00e3" },
          {
            id: "2044",
            name: "Ti\u00ean K\u1ef3",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2045", name: "Ti\u00ean L\u00e3nh", prefix: "X\u00e3" },
          { id: "2046", name: "Ti\u00ean L\u1eadp", prefix: "X\u00e3" },
          { id: "2047", name: "Ti\u00ean L\u1ed9c", prefix: "X\u00e3" },
          { id: "2048", name: "Ti\u00ean M\u1ef9", prefix: "X\u00e3" },
          { id: "2049", name: "Ti\u00ean Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "2050", name: "Ti\u00ean Phong", prefix: "X\u00e3" },
          { id: "2051", name: "Ti\u00ean S\u01a1n", prefix: "X\u00e3" },
          { id: "2052", name: "Ti\u00ean Th\u1ecd", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
    ],
  },
  {
    id: "10",
    code: "VT",
    name: "B\u00e0 R\u1ecba V\u0169ng T\u00e0u",
    districts: [
      {
        id: "139",
        name: "B\u00e0 R\u1ecba",
        wards: [
          { id: "2053", name: "H\u00f2a Long", prefix: "X\u00e3" },
          { id: "2054", name: "Kim Dinh", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2055",
            name: "Long H\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2056", name: "Long Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "2057", name: "Long T\u00e2m", prefix: "Ph\u01b0\u1eddng" },
          { id: "2058", name: "Long To\u00e0n", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2059",
            name: "Ph\u01b0\u1edbc Hi\u1ec7p",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2060",
            name: "Ph\u01b0\u1edbc H\u01b0ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2061",
            name: "Ph\u01b0\u1edbc Nguy\u00ean",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2062",
            name: "Ph\u01b0\u1edbc Trung",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2063", name: "T\u00e2n H\u01b0ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "18629", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18630", name: "11B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18631", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18632", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18633", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18634", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18635", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18636", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18637", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18638", name: "20A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18639", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18640", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18641", name: "24", prefix: "Ph\u1ed1" },
          { id: "18642", name: "27/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18643", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18644", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18645", name: "30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18646", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18647", name: "44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18648", name: "44A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18649", name: "45", prefix: "Ph\u1ed1" },
          { id: "18650", name: "46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18651", name: "46B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18652", name: "52", prefix: "Ph\u1ed1" },
          { id: "18653", name: "55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18654", name: "56", prefix: "Ph\u1ed1" },
          { id: "18655", name: "57", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18656", name: "79A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18657", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18658", name: "89", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18659", name: "90", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18660", name: "9C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18661", name: "A1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18662", name: "A2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18663", name: "A3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18664", name: "A4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18665", name: "A5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18666", name: "B1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18667", name: "B2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18668", name: "B3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18669", name: "B4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18670", name: "B5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18671", name: "B6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18672", name: "B7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18673", name: "B90", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18674",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18675",
            name: "B\u00f9i D\u01b0\u01a1ng L\u1ecbch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18676",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18677", name: "C1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18678", name: "C2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18679", name: "C3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18680",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18681",
            name: "Cao Tri\u1ec1u Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18682",
            name: "Chi L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18683",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18684", name: "D5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18685", name: "D7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18686", name: "D9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18687", name: "D911", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18698",
            name: "D\u01b0\u01a1ng B\u1ea1ch Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18688",
            name: "\u0110\u1eb7ng Nguy\u00ean C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18689",
            name: "\u0110\u1eb7ng Thai Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18690",
            name: "\u0110\u1eb7ng Xu\u00e2n B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18691",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18692",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18693", name: "\u0110\u1ed7 Nhu\u1eadn", prefix: "Ph\u1ed1" },
          {
            id: "18694",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18695",
            name: "\u0110\u1ed9c L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18696",
            name: "\u0110\u00f4ng H\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18697", name: "\u0110T 52", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18699", name: "GD1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18700", name: "GD2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18701", name: "GD3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18702", name: "GN2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18703", name: "GN3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18704",
            name: "G\u00f2 C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18705",
            name: "G\u00f2 C\u00e1t 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18706",
            name: "G\u00f2 C\u00e1t 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18707",
            name: "H\u00e0 Huy Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18708",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18709", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "18710",
            name: "H\u1ed3 Qu\u00fd Ly",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18711",
            name: "H\u1ed3 Tri T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18713",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18712",
            name: "Ho\u00e0ng \u0110\u1ea1o Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18714",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18715",
            name: "Ho\u00e0ng V\u0103n Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18716",
            name: "Ho\u00e0ng Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18717",
            name: "Hu\u1ec7 \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18718",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18719",
            name: "H\u01b0\u01a1ng l\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18720",
            name: "H\u01b0\u01a1ng l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18721",
            name: "H\u01b0\u01a1ng l\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18722",
            name: "H\u01b0\u01a1ng L\u1ed9 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18723",
            name: "H\u01b0\u01a1ng l\u1ed9 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18724",
            name: "Hu\u1ef3nh Kh\u01b0\u01a1ng An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18725",
            name: "Hu\u1ef3nh Ng\u1ecdc Hay",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18726",
            name: "Hu\u1ef3nh T\u1ea5n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18727",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18728",
            name: "Hu\u1ef3nh T\u1ecbnh C\u1ee7a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18729",
            name: "Kha V\u1ea1n C\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18730", name: "Kim H\u1ea3i", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18731", name: "K\u1ef3 \u0110\u1ed3ng", prefix: "Ph\u1ed1" },
          {
            id: "18732",
            name: "L\u00e2m Quang Ky",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18733",
            name: "L\u00ea B\u1ea3o T\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18734",
            name: "L\u00ea B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18735",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18736",
            name: "L\u00ea H\u1eefu Tr\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18737", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18738",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18739",
            name: "L\u00ea Long V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18740",
            name: "L\u00ea Qu\u00ed \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18741",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18742",
            name: "L\u00ea Th\u00e0nh Duy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18743",
            name: "L\u00ea Th\u1ecb B\u1ea1ch V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18744",
            name: "L\u00ea Th\u1ecb Ri\u00eang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18745",
            name: "L\u00ea Tr\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18746",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "18747",
            name: "L\u00ea V\u0103n Duy\u00ea\u0323t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18748",
            name: "L\u00ea V\u0103n H\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "18749",
            name: "L\u00ea V\u0103n L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18750", name: "L\u1ed9c An", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18751", name: "Long M\u1ef9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18752",
            name: "L\u1eeda H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18753",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18754",
            name: "L\u01b0u Ch\u00ed Hi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18755",
            name: "L\u01b0u H\u1eefu Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18756",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18757",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18758",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18759",
            name: "Mai H\u1eafc \u0110\u1ebf",
            prefix: "Ph\u1ed1",
          },
          {
            id: "18760",
            name: "M\u00f4 Xo\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18761",
            name: "M\u1ed9ng Hu\u00ea L\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18762",
            name: "M\u1ef9 Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18763",
            name: "Ng\u00f4 \u0110\u00ecnh Ch\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18764",
            name: "Ng\u00f4 \u0110\u1ee9c K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18765",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18766",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18767",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18768",
            name: "Nguy\u1ec5n B\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18769",
            name: "Nguy\u1ec5n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18770",
            name: "Nguy\u1ec5n Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18771",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18772",
            name: "Nguy\u1ec5n C\u01b0 Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18774",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18773",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18775",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18776",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18777",
            name: "Nguy\u1ec5n H\u1eefu Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18778",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18779",
            name: "Nguy\u1ec5n H\u1eefu Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18780", name: "Nguy\u1ec5n Huy T\u1ef1", prefix: "Ph\u1ed1" },
          {
            id: "18781",
            name: "Nguy\u1ec5n Hu\u1ef3nh \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18782",
            name: "Nguy\u1ec5n Kh\u00e1nh To\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18783",
            name: "Nguy\u1ec5n Khoa \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18784",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18785",
            name: "Nguy\u1ec5n M\u1ea1nh H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18786",
            name: "Nguy\u1ec5n M\u1ea1nh T\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18787",
            name: "Nguy\u1ec5n Ph\u00fac Chu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18788", name: "Nguy\u1ec5n Si\u00eau", prefix: "Ph\u1ed1" },
          {
            id: "18789",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18790",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18791",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18792",
            name: "Nguy\u1ec5n Th\u00e0nh Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18793",
            name: "Nguy\u1ec5n Thanh \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18794",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18795",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18796",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18797",
            name: "Nguy\u1ec5n Tr\u1ecdng Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18798",
            name: "Nguy\u1ec5n Tr\u1ecdng T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18799",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18800",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18801",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18802",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18803",
            name: "Nguy\u1ec5n V\u0103n H\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18804",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18805",
            name: "Nguy\u1ec5n V\u0103n Nguy\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18806",
            name: "Nguy\u1ec5n V\u0103n Tho\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18807",
            name: "Nguy\u1ec5n V\u0103n Tr\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18808",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18809",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18810",
            name: "\u00d4ng \u00cdch Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18811",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18812",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18813",
            name: "Ph\u1ea1m H\u1eefu Ch\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18814",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18815",
            name: "Ph\u1ea1m Ph\u00fa Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18816",
            name: "Ph\u1ea1m Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18817",
            name: "Ph\u1ea1m V\u0103n B\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18818",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18819", name: "Phan Anh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18820",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18821",
            name: "Phan Ch\u00e2u Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18822",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "18823",
            name: "Phan V\u0103n H\u1edbn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18824", name: "QH TP 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18825", name: "QH TP 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18826", name: "QH TP 3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18827",
            name: "Qu\u1ed1c L\u1ed9 44",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18828",
            name: "Qu\u1ed1c L\u1ed9 51",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18829",
            name: "Qu\u1ed1c L\u1ed9 51A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18830",
            name: "Qu\u1ed1c l\u1ed9 51B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18831",
            name: "Qu\u1ed1c l\u1ed9 51C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18832",
            name: "Qu\u1ed1c L\u1ed9 52",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18833",
            name: "Qu\u1ed1c L\u1ed9 55",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18834",
            name: "Qu\u1ed1c l\u1ed9 56",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18835", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18836", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18837", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18838", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18839", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18840", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18841", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18842", name: "S\u1ed1 18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18843", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18844", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18845", name: "S\u1ed1 31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18846", name: "S\u1ed1 36", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18847", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18848", name: "S\u1ed1 40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18849", name: "S\u1ed1 50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18850", name: "S\u1ed1 55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18851", name: "S\u1ed1 57", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18852", name: "S\u1ed1 62", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18853", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18854", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18855", name: "T\u1ea1 Quang B\u1eedu", prefix: "Ph\u1ed1" },
          {
            id: "18856",
            name: "T\u00e2n H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18857",
            name: "T\u0103ng B\u1ea1t H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18858",
            name: "Th\u00e1i V\u0103n Lung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18859",
            name: "Th\u00edch Thi\u1ec7n Chi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18860",
            name: "Thi\u1ec1u S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18861",
            name: "T\u1ec9nh L\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18862",
            name: "T\u1ec9nh l\u1ed9 44",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18863",
            name: "T\u1ec9nh l\u1ed9 44A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18864",
            name: "T\u1ec9nh l\u1ed9 52",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18865",
            name: "T\u1ec9nh L\u1ed9 81",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18866",
            name: "T\u00f4 Nguy\u1ec7t \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18867",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "18868",
            name: "T\u00f4n Th\u1ea5t Thuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18869",
            name: "Tr\u1ea7n Ch\u00e1nh Chi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18870",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18871",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18872",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18873",
            name: "Tr\u1ea7n Nguy\u00ean \u0110\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18874",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18875",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18876",
            name: "Tr\u1ea7n Xu\u00e2n \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18877",
            name: "Tr\u1ecbnh \u0110\u00ecnh Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18878",
            name: "Tr\u1ecbnh Ho\u00e0i \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18879",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18880",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18881",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18882",
            name: "Tr\u01b0\u01a1ng Ph\u00fac Phan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18883",
            name: "Tr\u01b0\u01a1ng T\u1ea5n B\u1eedu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18884",
            name: "Tr\u01b0\u01a1ng V\u0129nh K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18885",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18886",
            name: "Ung V\u0103n Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18887",
            name: "V\u0103n Ti\u1ebfn D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18888",
            name: "V\u00f5 Ng\u1ecdc Ch\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18889",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18890",
            name: "V\u00f5 Tr\u01b0\u1eddng To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18891",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18892",
            name: "V\u00f5 V\u0103n T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18893",
            name: "V\u0169 Tr\u1ecdng Ph\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18894",
            name: "Xu\u00e2n Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18895",
            name: "Xu\u00e2n H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2631",
            name: "Barya Citi",
            lat: "10.484992980957",
            lng: "107.19004821777",
          },
          {
            id: "2632",
            name: "Cinderella",
            lat: "10.50892829895",
            lng: "107.18162536621",
          },
          {
            id: "2633",
            name: "Eco Dragon",
            lat: "10.506100654602",
            lng: "107.1637878418",
          },
          {
            id: "2634",
            name: "Gia Long Residence",
            lat: "10.486676216125",
            lng: "107.19512176514",
          },
          {
            id: "2635",
            name: "Gia Long Villas Mini",
            lat: "10.523851394653",
            lng: "107.20101928711",
          },
          {
            id: "2636",
            name: "Green Village B\u00e0 R\u1ecba",
            lat: "10.496125221252",
            lng: "107.16017150879",
          },
          {
            id: "2637",
            name: "Khu th\u01b0\u01a1ng m\u1ea1i d\u1ecbch v\u1ee5 nh\u00e0 \u1edf Ho\u00e0n C\u1ea7u",
            lat: "10.515394210815",
            lng: "107.1809463501",
          },
          {
            id: "2638",
            name: "Kim Dinh",
            lat: "10.498332977295",
            lng: "107.14250183105",
          },
          {
            id: "2639",
            name: "Kim Dinh",
            lat: "10.498173713684",
            lng: "107.14252471924",
          },
          {
            id: "2640",
            name: "Lan Anh Residential",
            lat: "10.520184516907",
            lng: "107.19732666016",
          },
          {
            id: "2641",
            name: "The Unicity",
            lat: "10.519994735718",
            lng: "107.19776916504",
          },
        ],
      },
      {
        id: "140",
        name: "Ch\u00e2u \u0110\u1ee9c",
        wards: [
          { id: "2064", name: "B\u00e0u Chinh", prefix: "X\u00e3" },
          { id: "2065", name: "B\u00ecnh Ba", prefix: "X\u00e3" },
          { id: "2066", name: "B\u00ecnh Gi\u00e3", prefix: "X\u00e3" },
          { id: "2067", name: "B\u00ecnh Trung", prefix: "X\u00e3" },
          { id: "2068", name: "C\u00f9 B\u1ecb", prefix: "X\u00e3" },
          { id: "2069", name: "\u0110\u00e1 B\u1ea1c", prefix: "X\u00e3" },
          { id: "2070", name: "Kim Long", prefix: "X\u00e3" },
          { id: "2071", name: "L\u00e1ng L\u1edbn", prefix: "X\u00e3" },
          { id: "2072", name: "Ng\u00e3i Giao", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "2073", name: "Ngh\u0129a Th\u00e0nh", prefix: "X\u00e3" },
          { id: "2074", name: "Qu\u1ea3ng Th\u00e0nh", prefix: "X\u00e3" },
          { id: "2075", name: "S\u01a1n B\u00ecnh", prefix: "X\u00e3" },
          { id: "2076", name: "Su\u1ed1i Ngh\u1ec7", prefix: "X\u00e3" },
          { id: "2077", name: "Su\u1ed1i Rao", prefix: "X\u00e3" },
          { id: "2078", name: "X\u00e0 Bang", prefix: "X\u00e3" },
          { id: "2079", name: "Xu\u00e2n S\u01a1n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "18896", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18897", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18898", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18899", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18900", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18901", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18902", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18903", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18904", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18905", name: "31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18906", name: "35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18907", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18908", name: "49", prefix: "Ph\u1ed1" },
          { id: "18909", name: "56", prefix: "Ph\u1ed1" },
          { id: "18910", name: "765", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18911", name: "9B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18912", name: "9C", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18913",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18914",
            name: "B\u00ecnh Gi\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18915",
            name: "C\u1ea7u S\u1eaft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18916",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18917",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18918",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18919",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18920",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18921",
            name: "H\u1ed9i B\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18922",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18923",
            name: "Huy\u1ec7n \u0110\u1ecf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18924",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18925",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18926",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18927",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18928",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "18929",
            name: "M\u1ef9 Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18930",
            name: "M\u1ef9 Xu\u00e2n - Ng\u00e3i Giao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18931",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18932",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18933",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18934",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18935",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18936",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18937", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "18938",
            name: "Qu\u1ed1c l\u1ed9 56",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18939", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18940", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18941", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18942", name: "S\u1ed1 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18943", name: "S\u1ed1 21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18944", name: "S\u1ed1 29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18945", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18946", name: "S\u1ed1 45", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18947", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18948", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18949", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18950",
            name: "T\u1ec9nh L\u1ed9 765",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18951",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18952",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18953",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18954",
            name: "Xu\u00e2n S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "141",
        name: "C\u00f4n \u0110\u1ea3o",
        wards: [],
        streets: [
          {
            id: "18955",
            name: "B\u1ebfn \u0110\u1ea7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18956",
            name: "C\u1ecf \u1ed0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18957",
            name: "H\u00e0 Huy Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18958",
            name: "H\u1ed3 Thanh T\u00f2ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18959",
            name: "H\u1ed3 V\u0103n M\u1ecbch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18960",
            name: "Ho\u00e0ng Phi Y\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18961",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18962",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18963",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18964",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18965",
            name: "L\u00ea V\u0103n Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18966",
            name: "L\u01b0\u01a1ng Th\u1ebf Tr\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18967",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18968",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18970",
            name: "Nguy\u1ec5n Duy Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18969",
            name: "Nguy\u1ec5n \u0110\u1ee9c Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18971",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18972",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18973",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18974",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18975",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18976",
            name: "Ph\u1ea1m Qu\u1ed1c S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18977",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18978",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18979",
            name: "T\u1ea1 Uy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18980",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "18981",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18982",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18983",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18984",
            name: "V\u0169 V\u0103n Hi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "142",
        name: "\u0110\u1ea5t \u0110\u1ecf",
        wards: [
          {
            id: "2080",
            name: "\u0110\u1ea5t \u0110\u1ecf",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2081", name: "La\u0301ng Da\u0300i", prefix: "X\u00e3" },
          { id: "2082", name: "L\u1ed9c An", prefix: "X\u00e3" },
          { id: "2083", name: "Long M\u1ef9", prefix: "X\u00e3" },
          { id: "2084", name: "Long T\u00e2n", prefix: "X\u00e3" },
          {
            id: "2085",
            name: "Ph\u01b0\u1edbc H\u1ea3i",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2086", name: "Ph\u01b0\u1edbc H\u1ed9i", prefix: "X\u00e3" },
          {
            id: "2087",
            name: "Ph\u01b0\u1edbc Long Th\u1ecd",
            prefix: "X\u00e3",
          },
        ],
        streets: [
          { id: "18985", name: "44A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18986", name: "52", prefix: "Ph\u1ed1" },
          { id: "18987", name: "55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18988", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18989", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "18990", name: "D4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18991",
            name: "L\u00ea \u0110\u1ee9c Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18992", name: "Long M\u1ef9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18993",
            name: "Long Ph\u00f9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18994",
            name: "Long T\u00e2n-L\u00e1ng D\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18995",
            name: "Qu\u1ed1c L\u1ed9 55",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18996",
            name: "Qu\u1ed1c l\u1ed9 56",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "18997", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "18998",
            name: "T\u1ec9nh l\u1ed9 44",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "18999",
            name: "T\u1ec9nh l\u1ed9 44A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19000",
            name: "T\u1ec9nh l\u1ed9 52",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19001",
            name: "Tuy\u1ebfn D3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19002",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19003",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2642",
            name: "Long H\u1ea3i Villa & Hotel",
            lat: "10.389727592468",
            lng: "107.25862121582",
          },
          {
            id: "2643",
            name: "Oceanami Luxury Homes and Resort",
            lat: "10.387291908264",
            lng: "107.25846099854",
          },
        ],
      },
      {
        id: "143",
        name: "Long \u0110i\u1ec1n",
        wards: [
          { id: "2088", name: "An Ng\u00e3i", prefix: "X\u00e3" },
          { id: "2089", name: "An Nh\u1ee9t", prefix: "X\u00e3" },
          { id: "2090", name: "Long \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "2091", name: "Long H\u1ea3i", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "2092", name: "Ph\u01b0\u1edbc H\u01b0ng", prefix: "X\u00e3" },
          { id: "2093", name: "Ph\u01b0\u1edbc T\u1ec9nh", prefix: "X\u00e3" },
          { id: "2094", name: "Tam Ph\u01b0\u1edbc", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "19004", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19005", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19006", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19007", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19008", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19009", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19010", name: "36", prefix: "Ph\u1ed1" },
          { id: "19011", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19012", name: "40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19013", name: "44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19014", name: "44A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19015", name: "44B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19016", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19017", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19018", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19019", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19020", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19021",
            name: "B\u00f9i C\u00f4ng Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19022",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19023",
            name: "Cao V\u0103n Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19024",
            name: "Ch\u00e2n Ti\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19025",
            name: "Ch\u00e2u V\u0103n Bi\u00ea\u0301c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19027",
            name: "D\u01b0\u01a1ng B\u1ea1ch Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19026", name: "\u0110T 48", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19028",
            name: "H\u1ed3 Tri T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19029",
            name: "H\u01b0\u01a1ng l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19030",
            name: "H\u01b0\u01a1ng l\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19031",
            name: "H\u01b0\u01a1ng L\u1ed9 44",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19032",
            name: "H\u01b0\u01a1ng L\u1ed9 44A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19033",
            name: "H\u01b0\u01a1ng l\u1ed9 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19034",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19035",
            name: "Long B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19036",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19037",
            name: "M\u1ea1c Thanh \u0110\u1ea1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19038",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19039",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19040",
            name: "Nguy\u1ec5n Th\u1ecb Hoa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19041",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19042",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19043",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19044",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19045",
            name: "Ph\u1ea1m H\u1eefu Ch\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19046",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19047",
            name: "Qu\u1ed1c L\u1ed9 44",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19048",
            name: "Qu\u1ed1c L\u1ed9 44A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19049",
            name: "Qu\u1ed1c L\u1ed9 44B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19050",
            name: "Qu\u1ed1c L\u1ed9 55",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19051", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19052", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19053", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19054", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19055", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19056", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19057", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19058", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19059",
            name: "Thanh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19060",
            name: "T\u1ec9nh l\u1ed9 44",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19061",
            name: "T\u1ec9nh l\u1ed9 44A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19062",
            name: "T\u1ec9nh l\u1ed9 44B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19063",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19064",
            name: "Tr\u1ea7n Xu\u00e2n \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19065",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2644",
            name: "Dragon Beach Villa",
            lat: "10.408333778381",
            lng: "107.18939208984",
          },
          {
            id: "2645",
            name: "Gia Long Seaside",
            lat: "10.46638584137",
            lng: "107.24786376953",
          },
          {
            id: "2646",
            name: "KDC s\u1ed1 1 T\u00e2y Nam",
            lat: "10.48125743866",
            lng: "107.21049499512",
          },
          {
            id: "2647",
            name: "Khu ph\u1ed1 ch\u1ee3 Long \u0110i\u1ec1n",
            lat: "10.491209983826",
            lng: "107.22119903564",
          },
          {
            id: "2648",
            name: "Marine City",
            lat: "10.420283317566",
            lng: "107.188621521",
          },
          {
            id: "2649",
            name: "Ocean View Manor",
            lat: "10.407698631287",
            lng: "107.19397735596",
          },
          {
            id: "2650",
            name: "Saint Simeon",
            lat: "10.405012130737",
            lng: "107.19232940674",
          },
          {
            id: "2651",
            name: "Skypark Long \u0110i\u1ec1n",
            lat: "10.444763183594",
            lng: "107.23125457764",
          },
          {
            id: "2652",
            name: "Zenna Resort Villas",
            lat: "10.404230117798",
            lng: "107.19017791748",
          },
        ],
      },
      {
        id: "144",
        name: "T\u00e2n Th\u00e0nh",
        wards: [
          { id: "2095", name: "Ch\u00e2u Pha", prefix: "X\u00e3" },
          { id: "2096", name: "H\u1eafc D\u1ecbch", prefix: "X\u00e3" },
          { id: "2097", name: "M\u1ef9 Xu\u00e2n", prefix: "X\u00e3" },
          { id: "2098", name: "Ph\u00fa M\u1ef9", prefix: "X\u00e3" },
          { id: "2099", name: "Ph\u01b0\u1edbc H\u00f2a", prefix: "X\u00e3" },
          { id: "2100", name: "Ph\u01b0\u1edbc L\u1ed9c", prefix: "X\u00e3" },
          { id: "2101", name: "S\u00f4ng Xo\u00e0i", prefix: "X\u00e3" },
          { id: "2102", name: "T\u00e2n H\u1ea3i", prefix: "X\u00e3" },
          { id: "2103", name: "T\u00e2n H\u00f2a", prefix: "X\u00e3" },
          {
            id: "2104",
            name: "T\u00e2n Ph\u01b0\u1edbc",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2105", name: "T\u00f3c Ti\u00ean", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "19066", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19067", name: "32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19068", name: "51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19069", name: "80", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19070", name: "81", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19071", name: "911B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19072", name: "965", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19073", name: "991B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19074", name: "B\u1ea1ch Mai", prefix: "Ph\u1ed1" },
          {
            id: "19075",
            name: "B\u00e0u Ph\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19076",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19077",
            name: "Ch\u00e2u Pha",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19078",
            name: "Ch\u00e2u Pha - T\u00f3c Ti\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19079", name: "Chu H\u1ea3i", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19080",
            name: "H\u1eafc D\u1ecbch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19081",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19082",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19083",
            name: "Ho\u00e0ng Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19084",
            name: "H\u1ed9i B\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19085",
            name: "H\u1ed9i B\u00e0i - Ch\u00e2u Pha",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19086",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19087",
            name: "Hu\u1ef3nh T\u1ecbnh C\u1ee7a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19088", name: "Lam S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19089",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19090",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19091",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19092",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19093",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19094",
            name: "M\u1ef9 Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19095",
            name: "Ng\u00e3i Giao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19096",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19097",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19098",
            name: "Ng\u1ecdc H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19099",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19100",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19101",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19102",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19103",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19104",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1eb9p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19105",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19106",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19107",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19108",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19109",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19110",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19111",
            name: "Ph\u1ea1m H\u1eefu Ch\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19112",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19113",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19114",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19115",
            name: "Phan Ch\u00e2u Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19116",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19117",
            name: "Ph\u01b0\u1edbc T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19118",
            name: "Qu\u1ed1c L\u1ed9 51",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19119",
            name: "Qu\u1ed1c L\u1ed9 55",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19120",
            name: "Qu\u1ed1c L\u1ed9 81",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19121",
            name: "Qu\u1ed1c l\u1ed9 8A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19122", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19123", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19124", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19125", name: "S\u1ed1 38", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19126",
            name: "Song V\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19127",
            name: "Thanh T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19128",
            name: "T\u1ec9nh L\u1ed9 81",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19129",
            name: "T\u00f3c Ti\u00ean-Ch\u00e2u Pha",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19130",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19131",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19132",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19133",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19134",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19135", name: "V\u0103n Can", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19136",
            name: "V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2653",
            name: "Alibaba T\u00e2n Th\u00e0nh",
            lat: "10.587491989136",
            lng: "107.14504241943",
          },
          {
            id: "2654",
            name: "Ch\u00e2u Pha Ho\u00e0ng Qu\u00e2n",
            lat: "10.595998764038",
            lng: "107.13095092773",
          },
          {
            id: "2655",
            name: "Cinderella 2",
            lat: "10.60033416748",
            lng: "107.05373382568",
          },
          {
            id: "2656",
            name: "Cinderella 3",
            lat: "10.598689079285",
            lng: "107.10747528076",
          },
          {
            id: "2657",
            name: "Hodeco Ph\u00fa M\u1ef9",
            lat: "10.589314460754",
            lng: "107.05389404297",
          },
          {
            id: "2658",
            name: "Petro Town",
            lat: "10.579092979431",
            lng: "107.05310058594",
          },
          {
            id: "2659",
            name: "Ph\u00fa M\u1ef9 Center Point",
            lat: "10.593108177185",
            lng: "107.0630569458",
          },
          {
            id: "2660",
            name: "Ph\u00fa M\u1ef9 Town",
            lat: "10.595092773438",
            lng: "107.05449676514",
          },
        ],
      },
      {
        id: "145",
        name: "V\u0169ng T\u00e0u",
        wards: [
          { id: "2106", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "2107", name: "10", prefix: "Ph\u01b0\u1eddng" },
          { id: "2108", name: "11", prefix: "Ph\u01b0\u1eddng" },
          { id: "2109", name: "12", prefix: "Ph\u01b0\u1eddng" },
          { id: "2110", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "2111", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "2112", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "2113", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "2114", name: "6", prefix: "Ph\u01b0\u1eddng" },
          { id: "2115", name: "7", prefix: "Ph\u01b0\u1eddng" },
          { id: "2116", name: "8", prefix: "Ph\u01b0\u1eddng" },
          { id: "2117", name: "9", prefix: "Ph\u01b0\u1eddng" },
          { id: "2118", name: "Long S\u01a1n", prefix: "X\u00e3" },
          {
            id: "2119",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2120",
            name: "R\u1ea1ch D\u1eeba",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2121",
            name: "Th\u1eafng Nh\u1ea5t",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2122",
            name: "Th\u1eafng Nh\u00ec",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2123", name: "Th\u1eafng Tam", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "19137", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19138", name: "2/9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19139", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19140", name: "28/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19141", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19142", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19143", name: "51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19144", name: "51B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19145", name: "51C", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19146",
            name: "\u00c1 Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19147", name: "Ba Cu", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19148",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          { id: "19149", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "19150",
            name: "B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19151",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19152",
            name: "B\u00e0n C\u1edd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19153", name: "B\u00e0u Sen", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19154",
            name: "B\u00e0u Sen 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19155",
            name: "B\u00e0u Sen 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19156",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19157",
            name: "B\u1ebfn \u0110i\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19158",
            name: "B\u1ebfn \u0110\u00ecnh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19159",
            name: "B\u1ebfn \u0110\u00ecnh 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19160",
            name: "B\u1ebfn \u0110\u00ecnh 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19161",
            name: "B\u1ebfn \u0110\u00ecnh 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19162",
            name: "B\u1ebfn \u0110\u00ecnh 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19163",
            name: "B\u1ebfn \u0110\u00f2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19164",
            name: "B\u1ebfn N\u00f4m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19165",
            name: "B\u00ecnh Gi\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19166",
            name: "B\u00ecnh Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19167",
            name: "B\u1edd k\u00e8 R\u1ea1ch B\u1ebfn \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19168",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19169",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19170",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19171",
            name: "Ch\u00e2u V\u0103n Bi\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19172",
            name: "Chi L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19173",
            name: "Ch\u00ed Linh 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19174",
            name: "ch\u1ee3 R\u1ea1ch D\u1eeba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19175",
            name: "Chu M\u1ea1nh Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19176",
            name: "C\u00f4 B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19177",
            name: "C\u00f4 Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19178",
            name: "C\u1ed3n B\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19179",
            name: "C\u1ed1ng H\u1ed9p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19180", name: "D4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19181", name: "D5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19182",
            name: "D\u00e3 T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19185",
            name: "D\u1ea7u Kh\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19186",
            name: "Di\u1ec7p Minh Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19198",
            name: "D\u01b0\u01a1ng B\u1ea1ch Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19199",
            name: "D\u01b0\u01a1ng Ph\u1ea1m Huy Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19200",
            name: "D\u01b0\u01a1ng V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19201",
            name: "D\u01b0\u01a1ng V\u00e2n Nga",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19183",
            name: "\u0110\u1ea1i An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19184",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19187",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19188",
            name: "\u0110\u1ed3 Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19189",
            name: "\u0110\u00f4 L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19190",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19191",
            name: "\u0110o\u00e0n V\u0103n B\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19192",
            name: "\u0110\u1ed9i C\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19193",
            name: "\u0110\u1ed3i N\u1ecdc T\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19194",
            name: "\u0110\u1ed1ng \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19195",
            name: "\u0110\u00f4ng H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19196",
            name: "\u0110\u00f4ng H\u1ed3 Mang C\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19197",
            name: "\u0110\u1ed3ng Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19202",
            name: "G\u00f2 G\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19203", name: "H4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19204",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19205", name: "H\u1ea1 Long", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19206",
            name: "H\u1ea3i \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19207",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19208",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19209",
            name: "H\u00e0n M\u1eb7c T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19210",
            name: "H\u00e0n Thuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19211",
            name: "H\u00e0ng \u0110i\u1ec1u 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19212",
            name: "H\u1ed3 Bi\u1ec3u Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19213",
            name: "H\u1ed3 Qu\u00fd Ly",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19214",
            name: "H\u1ed3 Tri T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19215",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19216",
            name: "H\u00f3a D\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19217", name: "Hoa L\u01b0", prefix: "Ph\u1ed1" },
          {
            id: "19218",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19219",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19220",
            name: "Ho\u00e0ng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19221",
            name: "Ho\u00e0ng V\u0103n Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19222",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19223",
            name: "Ho\u00e0ng Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19224",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19225",
            name: "Huy\u1ec1n Kh\u01b0\u01a1ng An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19226",
            name: "Huy\u1ec1n Tr\u00e2n C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19227",
            name: "Hu\u1ef3nh Kh\u01b0\u01a1ng An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19228",
            name: "Hu\u1ef3nh Kh\u01b0\u01a1ng Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19229",
            name: "Hu\u1ef3nh V\u0103n H\u1edbn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19230",
            name: "Kha V\u1ea1n C\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19231",
            name: "Ki\u1ebfn T\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19232",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19233", name: "K\u00fd Con", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19234", name: "K\u1ef3 \u0110\u1ed3ng", prefix: "Ph\u1ed1" },
          {
            id: "19235",
            name: "La V\u0103n C\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19236",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19237", name: "L\u00e1ng", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19238",
            name: "L\u00e1ng C\u00e1t \u2013 Long S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19239",
            name: "L\u00ea B\u1ea3o T\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19240",
            name: "L\u00ea Ch\u00ed Hi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19241",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19242",
            name: "L\u00ea Ho\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19243",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19244", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19245",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19246",
            name: "L\u00ea Ng\u1ecdc H\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19247",
            name: "L\u00ea Ph\u1ee5ng Hi\u1ec3u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19248",
            name: "L\u00ea Quang \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19249",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19250",
            name: "L\u00ea Th\u00e0nh Duy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19251",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19252",
            name: "L\u00ea Th\u1ecb Ri\u00eang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19253",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19254",
            name: "L\u00ea V\u0103n L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19255",
            name: "L\u00ea V\u0103n T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19256",
            name: "L\u00ea V\u0103n T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19257",
            name: "Li\u00ean th\u00f4n 1- R\u1ea1ch L\u00f9a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19258",
            name: "Li\u00ean th\u00f4n 4-6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19259",
            name: "Li\u00ean th\u00f4n 5-8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19260",
            name: "Li\u00ean th\u00f4n B\u1ebfn \u0110i\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19261",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19262",
            name: "L\u01b0\u01a1ng V\u0103n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19263",
            name: "L\u01b0\u01a1ng V\u0103n Nho",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19264",
            name: "L\u01b0u Ch\u00ed Hi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19265",
            name: "L\u01b0u H\u1eefu Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19266",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19267",
            name: "L\u00fd Th\u00e1i T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19268",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19269",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19270",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19271",
            name: "M\u1ea1c Thanh \u0110\u1ea1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19272",
            name: "Mai Th\u00fac Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19273",
            name: "Mai Xu\u00e2n Th\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19274",
            name: "Nam K\u1ef3 Kh\u1edfi Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19275",
            name: "Ng\u00f4 \u0110\u1ee9c K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19276",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19277",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19278",
            name: "Ng\u00f4 T\u1ea5t T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19279",
            name: "Ng\u00f4 V\u0103n Huy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19280",
            name: "Ng\u01b0 Nghi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19281",
            name: "Ng\u01b0 Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19282",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19283",
            name: "Nguy\u1ec5n B\u00e1 L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19284",
            name: "Nguy\u1ec5n B\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19285",
            name: "Nguy\u1ec5n Bi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19286",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19287",
            name: "Nguy\u1ec5n B\u1eedu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19288",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19289",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19290",
            name: "Nguy\u1ec5n C\u01b0 Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19291",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19292",
            name: "Nguy\u1ec5n Gia Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19293",
            name: "Nguy\u1ec5n Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19294",
            name: "Nguy\u1ec5n H\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19295",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19296",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19297",
            name: "Nguy\u1ec5n H\u1eefu Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19298",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19299",
            name: "Nguy\u1ec5n Ki\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19300",
            name: "Nguy\u1ec5n Kim",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19301",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19302",
            name: "Nguy\u1ec5n Phi Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19303",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19304",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19305",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19306",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19307",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19308",
            name: "Nguy\u1ec5n Th\u1ecb Th\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19309",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19310",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19311",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19312",
            name: "Nguy\u1ec5n Tr\u1ecdng Qu\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19313",
            name: "Nguy\u1ec5n Tr\u1ecdng T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19314",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19315",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19316",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19317",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19318",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19319",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19320",
            name: "N\u01a1 Trang Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19321",
            name: "\u00d4ng H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19322",
            name: "\u00d4ng \u00cdch Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19323", name: "Pasteur", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19324",
            name: "Ph\u1ea1m C\u1ef1 L\u1ea1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19325",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19326", name: "Ph\u1ea1m Huy Th\u00f4ng", prefix: "Ph\u1ed1" },
          {
            id: "19327",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19328",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19329",
            name: "Ph\u1ea1m Th\u1ebf Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19330",
            name: "Ph\u1ea1m V\u0103n B\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19331",
            name: "Ph\u1ea1m V\u0103n Dinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19332",
            name: "Ph\u1ea1m V\u0103n Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19333",
            name: "Ph\u1ea1m Vi\u1ebft Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19334",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19335",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19336",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19337",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19338",
            name: "Phan Huy Ch\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19339",
            name: "Phan Huy \u00cdch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19340",
            name: "Phan K\u1ebf B\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19341",
            name: "Phan V\u0103n L\u01b0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19342",
            name: "Phan V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19343",
            name: "Ph\u00f3 \u0110\u1ee9c Ch\u00ednh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19344",
            name: "Ph\u00f9ng Ch\u00ed Ki\u00ean",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19345",
            name: "Ph\u00f9ng Kh\u1eafc Khoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19346",
            name: "Ph\u01b0\u1edbc Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19347", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19348",
            name: "Qu\u1ed1c L\u1ed9 51",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19349",
            name: "Qu\u1ed1c l\u1ed9 51B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19350",
            name: "Qu\u1ed1c l\u1ed9 51C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19351", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19352", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19353", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19354", name: "S\u1ed1 64", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19355",
            name: "S\u01b0\u01a1ng Nguy\u1ec7t \u00c1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19356",
            name: "T\u1ea1 Uy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19357",
            name: "T\u0103ng B\u1ea1t H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19358",
            name: "T\u00e2y H\u1ed3 Mang C\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19359",
            name: "Th\u00e1i V\u0103n Lung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19360",
            name: "Th\u1eafng Nh\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19361", name: "Thi S\u00e1ch", prefix: "Ph\u1ed1" },
          {
            id: "19362",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19363",
            name: "Th\u1ee7 Khoa Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19364",
            name: "Th\u00f9y V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19365",
            name: "Ti\u1ec1n C\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19366",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19367",
            name: "T\u00f4n \u0110\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19368",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19369",
            name: "T\u00f4n Th\u1ea5t Thuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19370",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19371",
            name: "T\u1ed1ng Duy T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19372",
            name: "Tr\u1ea7n Anh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19373",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19374",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19375",
            name: "Tr\u1ea7n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19376",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19377",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19378",
            name: "Tr\u1ea7n Kh\u1eafc Chung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19379",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19380",
            name: "Tr\u1ea7n Nguy\u00ean \u0110\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19381",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19382",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19383",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19384",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19385",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19386",
            name: "Tr\u1ea7n Qu\u00fd Tr\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19387",
            name: "Tr\u1ea7n Xu\u00e2n \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19388",
            name: "Tri\u1ec7u Vi\u1ec7t V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19389",
            name: "Tr\u1ecbnh Ho\u00e0i \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19390",
            name: "Tr\u1ee5c ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19391",
            name: "Tr\u01b0ng Nh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19392",
            name: "Tr\u01b0ng Tr\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19393",
            name: "Tr\u01b0\u01a1ng C\u00f4ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19394",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19395",
            name: "Tr\u01b0\u01a1ng Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19396",
            name: "Tr\u01b0\u1eddng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19397",
            name: "Tr\u01b0\u01a1ng V\u0103n Bang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19398",
            name: "Tr\u01b0\u01a1ng V\u0129nh K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19399",
            name: "T\u00fa X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19400",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19401", name: "V\u0103n Cao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19402",
            name: "Ven Bi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19403",
            name: "ven bi\u1ec3n H\u1ea3i \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19404", name: "Vi Ba", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19405",
            name: "V\u00f5 Ch\u00ed C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19406",
            name: "V\u00f5 \u0110\u00ecnh Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19407",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19408",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19409",
            name: "V\u00f5 Tr\u01b0\u1eddng To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19410",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19411",
            name: "V\u00f5 V\u0103n T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19412",
            name: "X\u00f4 Vi\u1ebft Ngh\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19413",
            name: "Y\u00ean B\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19414",
            name: "Y\u00ean \u0110\u1ed7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19415", name: "Yersin", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "2661",
            name: "Bi\u1ec7t th\u1ef1 Thanh B\u00ecnh",
            lat: "10.378735542297",
            lng: "107.12327575684",
          },
          {
            id: "2662",
            name: "B\u00ecnh Gi\u00e3 Resident",
            lat: "10.35510635376",
            lng: "107.09255218506",
          },
          {
            id: "2663",
            name: "Blue Sapphire",
            lat: "10.374457359314",
            lng: "107.1275177002",
          },
          {
            id: "2664",
            name: "Chung c\u01b0 B\u00ecnh An",
            lat: "10.392251014709",
            lng: "107.11837768555",
          },
          {
            id: "2665",
            name: "Chung c\u01b0 d\u1ea7u kh\u00ed PVC-IC",
            lat: "10.385354995728",
            lng: "107.10835266113",
          },
          {
            id: "2666",
            name: "Diamond PVC-IC",
            lat: "10.369275093079",
            lng: "107.08335113525",
          },
          {
            id: "2667",
            name: "Dic Phoenix",
            lat: "10.376780509949",
            lng: "107.11027526855",
          },
          {
            id: "2668",
            name: "DIC The LandMark Residence",
            lat: "10.348857879639",
            lng: "107.09421539307",
          },
          {
            id: "2669",
            name: "\u0110\u1ed3i Ng\u1ecdc T\u01b0\u1edbc II",
            lat: "10.351753234863",
            lng: "107.09481811523",
          },
          {
            id: "2670",
            name: "E-Sea Apartment",
            lat: "10.385938644409",
            lng: "107.12099456787",
          },
          {
            id: "2671",
            name: "Hodeco Plaza",
            lat: "10.366673469543",
            lng: "107.08555603027",
          },
          {
            id: "2672",
            name: "K\u0110T Ch\u00ed Linh",
            lat: "10.377519607544",
            lng: "107.12210845947",
          },
          {
            id: "2673",
            name: "Khu nh\u00e0 \u1edf s\u00f4ng C\u00e2y Kh\u1ebf",
            lat: "10.424558639526",
            lng: "107.15649414062",
          },
          {
            id: "2674",
            name: "Lakeside Apartment",
            lat: "10.376642227173",
            lng: "107.11066436768",
          },
          {
            id: "2675",
            name: "Mermaid Seaside",
            lat: "10.38390827179",
            lng: "107.06482696533",
          },
          {
            id: "2676",
            name: "Mermaid Seaview",
            lat: "10.34645652771",
            lng: "107.09586334229",
          },
          {
            id: "2677",
            name: "Metropolitan V\u0169ng T\u00e0u",
            lat: "10.398738861084",
            lng: "107.13329315186",
          },
          {
            id: "2678",
            name: "OSC Land",
            lat: "10.341053009033",
            lng: "107.08965301514",
          },
          {
            id: "2679",
            name: "Ruby Tower V\u0169ng T\u00e0u",
            lat: "10.351753234863",
            lng: "107.09481811523",
          },
          {
            id: "2680",
            name: "Saigonres Tower",
            lat: "10.351850509644",
            lng: "107.08822631836",
          },
          {
            id: "2681",
            name: "Sao Mai B\u1ebfn \u0110\u00ecnh",
            lat: "10.373888969421",
            lng: "107.09222412109",
          },
          {
            id: "2682",
            name: "Seaview Ch\u00ed Linh",
            lat: "10.377235412598",
            lng: "107.11193847656",
          },
          {
            id: "2683",
            name: "Silver Sea Tower 47 Bacu",
            lat: "10.350296974182",
            lng: "107.07693481445",
          },
          {
            id: "2684",
            name: "S\u01a1n Th\u1ecbnh",
            lat: "10.348903656006",
            lng: "107.10112762451",
          },
          {
            id: "2685",
            name: "The Imperial Complex",
            lat: "10.338810920715",
            lng: "107.09164428711",
          },
          {
            id: "2686",
            name: "Th\u1ee7y Ti\u00ean Resort",
            lat: "10.364238739014",
            lng: "107.06221008301",
          },
          {
            id: "2687",
            name: "Trung T\u00e2m Tri\u1ec3n l\u00e3m V\u0169ng T\u00e0u",
            lat: "21.0306224823",
            lng: "105.84886932373",
          },
          {
            id: "2688",
            name: "Veranda V\u0169ng T\u00e0u",
            lat: "10.353404045105",
            lng: "107.10005187988",
          },
          {
            id: "2689",
            name: "V\u0169ng T\u00e0u Center",
            lat: "10.361676216125",
            lng: "107.0750579834",
          },
          {
            id: "2690",
            name: "V\u0169ng T\u00e0u Melody",
            lat: "10.337511062622",
            lng: "107.08559417725",
          },
          {
            id: "2691",
            name: "V\u0169ng T\u00e0u Plaza",
            lat: "10.352159500122",
            lng: "107.08946990967",
          },
          {
            id: "2692",
            name: "V\u0169ng T\u00e0u Regency",
            lat: "10.351753234863",
            lng: "107.09481811523",
          },
        ],
      },
      {
        id: "146",
        name: "Xuy\u00ean M\u1ed9c",
        wards: [
          { id: "2124", name: "B\u00e0u L\u00e2m", prefix: "X\u00e3" },
          { id: "2125", name: "B\u00ecnh Ch\u00e2u", prefix: "X\u00e3" },
          { id: "2126", name: "B\u00f4ng Trang", prefix: "X\u00e3" },
          { id: "2127", name: "B\u01b0ng Ri\u1ec1ng", prefix: "X\u00e3" },
          { id: "2128", name: "H\u00f2a B\u00ecnh", prefix: "X\u00e3" },
          { id: "2129", name: "H\u00f2a Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "2130", name: "H\u00f2a H\u1ed9i", prefix: "X\u00e3" },
          { id: "2131", name: "H\u00f2a H\u01b0ng ", prefix: "X\u00e3" },
          { id: "2132", name: "Ph\u01b0\u1edbc B\u1eedu", prefix: "X\u00e3" },
          { id: "2133", name: "Ph\u01b0\u1edbc T\u00e2n", prefix: "X\u00e3" },
          { id: "2134", name: "Ph\u01b0\u1edbc Thu\u1eadn", prefix: "X\u00e3" },
          { id: "2135", name: "T\u00e2n L\u00e2m", prefix: "X\u00e3" },
          { id: "2136", name: "Xuy\u00ean M\u1ed9c", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "19416", name: "27/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19417", name: "328", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19418", name: "44B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19419", name: "55", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19420",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19421", name: "B\u1ea1ch Mai", prefix: "Ph\u1ed1" },
          {
            id: "19422",
            name: "B\u00ecnh Gi\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19423",
            name: "Chu\u00f4ng Qu\u00fdt G\u00f2 C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19424",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19425",
            name: "Ho\u00e0ng Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19426",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19427",
            name: "Hu\u1ef3nh Minh Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19428",
            name: "L\u00e1ng Sim",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19429",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19430", name: "L\u1ed9c An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19431",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19432",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19433",
            name: "Nguy\u1ec5n H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19434",
            name: "Nguy\u1ec5n Minh Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19435",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19436",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19437", name: "ODA", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19438",
            name: "\u00d4ng \u00cdch Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19439",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19440",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19441",
            name: "Qu\u1ed1c L\u1ed9 55",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19442",
            name: "T\u1ec9nh l\u1ed9 328",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19443",
            name: "T\u1ec9nh L\u1ed9 329",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19444",
            name: "T\u1ec9nh l\u1ed9 44",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19445",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19446",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19447",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19448",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19449",
            name: "Tr\u1ea7n V\u0103n Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19450",
            name: "Xuy\u00ean Ph\u01b0\u1edbc C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2693",
            name: "Coastar Estates",
            lat: "10.472871780396",
            lng: "107.39054870605",
          },
          {
            id: "2694",
            name: "Furama Resort H\u1ed3 C\u00f3c",
            lat: "10.499205589294",
            lng: "107.47956848145",
          },
          {
            id: "2695",
            name: "H\u1ed3 Tr\u00e0m Strip",
            lat: "10.469207763672",
            lng: "107.3983001709",
          },
          {
            id: "2696",
            name: "Princess Villas H\u1ed3 Tr\u00e0m",
            lat: "10.479000091553",
            lng: "107.39671325684",
          },
          {
            id: "2697",
            name: "The Hamptons H\u1ed3 Tr\u00e0m",
            lat: "10.471995353699",
            lng: "107.39533996582",
          },
        ],
      },
    ],
  },
  {
    id: "11",
    code: "DDL",
    name: "\u0110\u1eafk L\u1eafk",
    districts: [
      {
        id: "147",
        name: "Bu\u00f4n \u0110\u00f4n",
        wards: [
          { id: "2137", name: "Cu\u00f4r Knia", prefix: "X\u00e3" },
          { id: "2138", name: "Ea Bar", prefix: "X\u00e3" },
          { id: "2139", name: "Ea Huar", prefix: "X\u00e3" },
          { id: "2140", name: "Ea Nu\u00f4l", prefix: "X\u00e3" },
          { id: "2141", name: "Ea Wer", prefix: "X\u00e3" },
          { id: "2142", name: "Kr\u00f4ng Na", prefix: "X\u00e3" },
          { id: "2143", name: "T\u00e2n H\u00f2a", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "19451",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19452",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19453",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19454",
            name: "Qu\u1ed1c l\u1ed9 14C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19455",
            name: "T\u1ec9nh L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19456",
            name: "T\u1ec9nh L\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19457",
            name: "T\u1ec9nh L\u1ed9 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "148",
        name: "Bu\u00f4n H\u1ed3",
        wards: [
          { id: "2144", name: "An B\u00ecnh", prefix: "Ph\u01b0\u1eddng" },
          { id: "2145", name: "An L\u1ea1c", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2146",
            name: "B\u00ecnh T\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2147", name: "B\u00ecnh Thu\u1eadn", prefix: "X\u00e3" },
          { id: "2148", name: "C\u01b0 Bao", prefix: "X\u00e3" },
          {
            id: "2149",
            name: "\u0110\u1ea1t Hi\u1ebfu",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2150",
            name: "\u0110o\u00e0n K\u1ebft",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2151", name: "Ea Blang", prefix: "X\u00e3" },
          { id: "2152", name: "Ea Dr\u00f4ng", prefix: "X\u00e3" },
          { id: "2153", name: "Ea Si\u00ean", prefix: "X\u00e3" },
          { id: "2154", name: "Thi\u1ec7n An", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2155",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "19458", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19459", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19460",
            name: "B\u00f9i Xu\u00e2n Ph\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19461",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19462",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19463",
            name: "H\u1ea3i Tri\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19464",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19465",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19466",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19467",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19468",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19469",
            name: "L\u00ea Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19470",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19471",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19472",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19473",
            name: "L\u00ea V\u0103n H\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19474",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19475",
            name: "Ng\u00f4 \u0110\u1ee9c K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19476",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19477",
            name: "Nguy\u1ec5n Kim",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19478",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19479",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19480",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19481",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19482",
            name: "N\u01a1 Trang Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19483",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19484",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19485",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19486",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19487",
            name: "T\u1ec9nh l\u1ed9 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19488",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19489",
            name: "Tr\u1ea7n Qu\u1ed1c Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19490",
            name: "Tr\u01b0\u01a1ng V\u0129nh K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2698",
            name: "Bu\u00f4n H\u1ed3 Palama",
            lat: "12.918800354004",
            lng: "108.26849365234",
          },
        ],
      },
      {
        id: "149",
        name: "Bu\u00f4n Ma Thu\u1ed9t",
        wards: [
          { id: "2156", name: "C\u01b0 Ebur", prefix: "X\u00e3" },
          { id: "2157", name: "Ea Kao", prefix: "Ph\u01b0\u1eddng" },
          { id: "2158", name: "Ea Tam", prefix: "Ph\u01b0\u1eddng" },
          { id: "2159", name: "Ea Tu", prefix: "X\u00e3" },
          { id: "2160", name: "H\u00f2a Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "2161", name: "H\u00f2a Ph\u00fa", prefix: "X\u00e3" },
          { id: "2162", name: "H\u00f2a Th\u1eafng", prefix: "X\u00e3" },
          { id: "2163", name: "H\u00f2a Thu\u1eadn", prefix: "X\u00e3" },
          { id: "2164", name: "H\u00f2a Xu\u00e2n", prefix: "X\u00e3" },
          {
            id: "2165",
            name: "Kh\u00e1nh Xu\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2166", name: "T\u00e2n An", prefix: "X\u00e3" },
          { id: "2167", name: "T\u00e2n Ho\u00e0", prefix: "X\u00e3" },
          { id: "2168", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "2169", name: "T\u00e2n L\u1ee3i", prefix: "X\u00e3" },
          {
            id: "2170",
            name: "T\u00e2n Th\u00e0nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2171",
            name: "T\u00e2n Ti\u1ebfn",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2172",
            name: "Th\u1eafng L\u1ee3i",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2173",
            name: "Th\u00e0nh C\u00f4ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2174",
            name: "Th\u00e0nh Nh\u1ea5t",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2175",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2176", name: "T\u1ef1 An", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "19491", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19492", name: "10/3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19493", name: "10A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19494", name: "12A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19495", name: "13B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19496", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19497", name: "14A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19498", name: "16A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19499", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19500", name: "22B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19501", name: "26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19502", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19503", name: "2A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19504", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19505", name: "3B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19506", name: "54C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19507", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19508", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19509", name: "A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19510", name: "A D\u1eeba", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19511", name: "A Tranh", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19512", name: "A1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19513", name: "A10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19514", name: "A11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19515", name: "A12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19516", name: "A13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19517", name: "A14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19518", name: "A2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19519", name: "A3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19520", name: "A4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19521", name: "A5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19522", name: "A6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19523", name: "A7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19524", name: "A8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19525", name: "A9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19526", name: "Ama Jhao", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19527", name: "Ama Kh\u00ea", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19528", name: "Ama Pui", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19529", name: "Ama Sa", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19530", name: "AmaQuang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19531",
            name: "Ami \u0110oan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19532",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19533",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19534", name: "B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19535", name: "B1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19536", name: "B2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19537", name: "B3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19538", name: "B5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19539",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          { id: "19540", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "19541",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19542",
            name: "B\u00f9i H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19543",
            name: "B\u00f9i Huy B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19544",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19545",
            name: "Bu\u00f4n \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19546",
            name: "Bu\u00f4n K\u00f4 Tam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19547",
            name: "Bu\u00f4n Kr\u00f4ng A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19548",
            name: "Bu\u00f4n Kr\u00f4ng B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19549", name: "Bu\u00f4n Ky", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19550", name: "C", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19551",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19552",
            name: "Cao \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19553",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19554",
            name: "Ch\u00e2u S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19555",
            name: "Ch\u1ebf Lan Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19556",
            name: "Chu M\u1ea1nh Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19557",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19558",
            name: "C\u1ed1ng Qu\u1ef3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19559",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19560",
            name: "D\u00e3 T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19581",
            name: "D\u01b0\u01a1ng V\u00e2n Nga",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19561",
            name: "\u0110\u1ea1i L\u1ed9 \u0110\u00f4ng T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19562",
            name: "\u0110\u1ea1i l\u1ed9 Th\u0103ng Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19563", name: "\u0110\u1eb7ng Dung", prefix: "Ph\u1ed1" },
          {
            id: "19564",
            name: "\u0110\u1eb7ng Nguy\u00ean C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19565", name: "\u0110\u1eb7ng T\u1ea5t", prefix: "Ph\u1ed1" },
          {
            id: "19566",
            name: "\u0110\u1eb7ng Thai Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19567",
            name: "\u0110\u1eb7ng Th\u00e1i Th\u00e2n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19568",
            name: "\u0110\u1eb7ng Tr\u1ea7n C\u00f4n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19569",
            name: "\u0110\u1eb7ng V\u0103n Ng\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19570",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19571",
            name: "\u0110\u00e0o T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19572",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19573",
            name: "\u0110i\u1ec1u V\u0103n C\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19574",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19575",
            name: "\u0110inh N\u00fap",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19576",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19577",
            name: "\u0110inh V\u0103n Gi\u00f3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19578", name: "\u0110\u1ed7 Nhu\u1eadn", prefix: "Ph\u1ed1" },
          {
            id: "19579",
            name: "\u0110\u1ed7 Xu\u00e2n H\u1ee3p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19580",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19582", name: "E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19583", name: "Ea - Tam", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19584", name: "Eabar", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19585", name: "G", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19586",
            name: "Gi\u1ea3i Ph\u00f3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19587",
            name: "Gi\u00e1p H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19588", name: "H", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19589",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19590", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "19591",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19592",
            name: "H\u1ea3i Tri\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19593",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19594",
            name: "H\u00e0n M\u1eb7c T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19595",
            name: "H\u00e0n Thuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19596",
            name: "H\u1ed3 Ch\u00ed Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19597",
            name: "H\u1ed3 Gi\u00e1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19598",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19599",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19600",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19601",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19602",
            name: "Ho\u00e0ng Minh Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19603",
            name: "Ho\u00e0ng V\u0103n Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19604",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19605",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19606",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19607",
            name: "Kh\u00fac Th\u1eeba D\u1ee5",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19608",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19609",
            name: "Kpa Nguy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19610",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19611",
            name: "L\u00ea Anh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19612",
            name: "L\u00ea Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19613",
            name: "L\u00ea C\u00f4ng Ki\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19614",
            name: "L\u00ea C\u00f4ng Mi\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19617",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19615",
            name: "L\u00ea \u0110\u1ea1i Cang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19616",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19618",
            name: "L\u00ea \u0110\u1ee9c Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19619",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19620", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19621",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19622",
            name: "L\u00ea Minh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19623",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19624",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19625",
            name: "L\u00ea Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19626",
            name: "L\u00ea Th\u1ecb Ri\u00eang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19627",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19628",
            name: "L\u00ea V\u0103n H\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19629",
            name: "L\u00ea V\u0103n Nhi\u1ec5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19630",
            name: "L\u00ea V\u0103n S\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19631",
            name: "L\u00ea V\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19632",
            name: "Li\u00ean Kh\u1ed1i 3-4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19633",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19634",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19635",
            name: "L\u00fd Ch\u00ednh Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19636",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19637",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19638",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19639",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19640",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19641",
            name: "M\u1ea1c Th\u1ecb B\u01b0\u1edfi",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19642",
            name: "Mai H\u1eafc \u0110\u1ebf",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19643",
            name: "Mai Th\u1ecb L\u1ef1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19644",
            name: "Mai Xu\u00e2n Th\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19645",
            name: "Nam Qu\u1ed1c Cang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19646", name: "Nay Der", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19647", name: "Nay Phao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19648",
            name: "Nay Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19649",
            name: "Ng\u00f4 \u0110\u1ee9c K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19650",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19651",
            name: "Ng\u00f4 M\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19652",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19653",
            name: "Ng\u00f4 T\u1ea5t T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19654",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19655",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19656",
            name: "Nguy\u1ec5n Bi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19657",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19658",
            name: "Nguy\u1ec5n Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19659",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19660",
            name: "Nguy\u1ec5n C\u01a1 Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19661",
            name: "Nguy\u1ec5n C\u01a1 Tr\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19662",
            name: "Nguy\u1ec5n C\u00f4ng Hoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19663",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19664",
            name: "Nguy\u1ec5n C\u01b0 Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19666",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19668",
            name: "Nguy\u1ec5n Duy Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19665",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19667",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19669",
            name: "Nguy\u1ec5n Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19670",
            name: "Nguy\u00ean H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19671",
            name: "Nguy\u1ec5n H\u1ed3ng \u01afng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19672",
            name: "Nguy\u00ea\u0303n H\u01b0\u0303u H\u1ea5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19673",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ea5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19674",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19675", name: "Nguy\u1ec5n Huy T\u1ef1", prefix: "Ph\u1ed1" },
          {
            id: "19676",
            name: "Nguy\u1ec5n Huy T\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19677",
            name: "Nguy\u1ec5n Kh\u1eafc T\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19678",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19679",
            name: "Nguy\u1ec5n Kim",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19680",
            name: "Nguy\u1ec5n L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19681",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19682",
            name: "Nguy\u1ec5n Ph\u00fac Chu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19683", name: "Nguy\u1ec5n Si\u00eau", prefix: "Ph\u1ed1" },
          {
            id: "19684",
            name: "Nguy\u1ec5n Sinh S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19685",
            name: "Nguy\u1ec5n S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19686",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19687",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19688",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19689",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19690",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19691",
            name: "Nguy\u1ec5n Thi\u1ebfp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19692",
            name: "Nguy\u1ec5n Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19693",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19694",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19695",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19696",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19697",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19698",
            name: "Nguy\u1ec5n V\u0103n B\u00e9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19699",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19700",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19701",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19702",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19703",
            name: "Nguy\u1ec5n Xu\u00e2n Nghi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19704",
            name: "Nguy\u1ec5n Xu\u00e2n Nguy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19705",
            name: "N\u01a1 Trang G\u01b0h",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19706",
            name: "N\u01a1 Trang Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19707",
            name: "\u00d4ng \u00cdch Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19708",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19709",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19710",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19711",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19712",
            name: "Ph\u1ea1m Ph\u00fa Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19713",
            name: "Ph\u1ea1m V\u0103n B\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19714",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19715",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19716",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19717",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19718",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19719",
            name: "Phan Huy Ch\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19720",
            name: "Phan K\u1ebf B\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19721",
            name: "Phan Ki\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19722",
            name: "Phan V\u0103n Kh\u1ecfe",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19723",
            name: "Ph\u00f3 \u0110\u1ee9c Ch\u00ednh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19724",
            name: "Ph\u00f9 \u0110\u1ed5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19725",
            name: "Ph\u00f9ng Ch\u00ed Ki\u00ean",
            prefix: "Ph\u1ed1",
          },
          { id: "19726", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "19727",
            name: "Pi N\u0103ng T\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19728", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19729",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19730",
            name: "Qu\u1ed1c l\u1ed9 14C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19731",
            name: "Qu\u1ed1c L\u1ed9 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19732",
            name: "Qu\u1ed1c l\u1ed9 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19733",
            name: "Qu\u1ed1c l\u1ed9 687B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19734",
            name: "S\u0103m Br\u0103m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19735", name: "Siu Bieh", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19736", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19737", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19738", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19739",
            name: "S\u01a1n Khinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19740",
            name: "S\u01b0 V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19741",
            name: "S\u01b0\u01a1ng Nguy\u1ec7t \u00c1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19742", name: "T\u1ea1 Quang B\u1eedu", prefix: "Ph\u1ed1" },
          {
            id: "19743",
            name: "T\u1ea3n \u0110\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19744",
            name: "T\u00e2n Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19745",
            name: "T\u00e2y S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19746",
            name: "Th\u00e1i Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19747",
            name: "Th\u0103ng  Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19748", name: "Thi S\u00e1ch", prefix: "Ph\u1ed1" },
          {
            id: "19749",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19750",
            name: "Th\u1ee7 Khoa Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19751",
            name: "T\u1ec9nh L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19752",
            name: "T\u1ec9nh L\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19753",
            name: "T\u1ec9nh L\u1ed9 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19754",
            name: "T\u1ec9nh l\u1ed9 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19755",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19756",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19757",
            name: "T\u1ed1 H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19758",
            name: "T\u00f4 V\u0129nh Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19759",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19760",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19761",
            name: "T\u1ed1ng Duy T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19762",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19763",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19764",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19765",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19766",
            name: "Tr\u1ea7n H\u1eefu D\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19767",
            name: "Tr\u1ea7n H\u1eefu Trang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19768",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19769",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19770",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19771",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19772",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19773",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19774",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19775",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19776",
            name: "Tr\u1ea7n V\u0103n Phu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19777",
            name: "Tr\u1ecbnh V\u0103n C\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19778",
            name: "Tr\u01b0\u01a1ng C\u00f4ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19779",
            name: "Tr\u01b0\u01a1ng \u0110\u0103ng Qu\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19780",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19781",
            name: "Tr\u01b0\u01a1ng Quang Giao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19782",
            name: "Tr\u01b0\u01a1ng Quang Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19783", name: "T\u1ef1 An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19784",
            name: "T\u01b0 R\u01a1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19785",
            name: "T\u00fa X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19786",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19787", name: "V\u0103n Cao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19788",
            name: "V\u0103n Ti\u1ebfn D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19789",
            name: "V\u1ea1n Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19790",
            name: "V\u00e0nh \u0110ai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19792",
            name: "V\u00f5 Duy Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19791",
            name: "V\u00f5 \u0110\u00ecnh T\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19793",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19794",
            name: "V\u00f5 Trung Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19795",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19796",
            name: "X\u00f4 Vi\u1ebft Ngh\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19797",
            name: "Xu\u00e2n Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19798", name: "Y Bhin", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19799", name: "Y Bih Aleo", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19800", name: "Y Jut", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19801", name: "Y Khu", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19802", name: "Y Moan", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19803", name: "Y Mol", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19804", name: "Y Ng\u00f4ng", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19805", name: "Y Ni Ksok", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19806", name: "Y Ni Ksor", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19807", name: "Y Nu\u00ea", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19808", name: "Y \u01a0n", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19809", name: "Y Plo Eban", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19810", name: "Y Quang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19811",
            name: "Y Som Ni\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19812",
            name: "Y Thuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19813",
            name: "Y Thuy\u00ean Ksor",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19814",
            name: "Y \u00dat Ni\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19815", name: "Y Wang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19816",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19817",
            name: "Ypl\u00f4 \u00caban",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2699",
            name: "Ho\u00e0ng Anh BIDV",
            lat: "12.678952217102",
            lng: "108.04801177979",
          },
          {
            id: "2700",
            name: "Khu \u0111\u00f4 th\u1ecb B\u1eafc T\u00e2n L\u1ee3i",
            lat: "12.70120716095",
            lng: "108.0588760376",
          },
        ],
      },
      {
        id: "150",
        name: "C\u01b0 Kuin",
        wards: [
          { id: "2177", name: "C\u01b0 \u00ca Wi", prefix: "X\u00e3" },
          { id: "2178", name: "Dray Bh\u0103ng", prefix: "X\u00e3" },
          { id: "2179", name: "Ea Bh\u00f4k", prefix: "X\u00e3" },
          { id: "2180", name: "Ea Hu", prefix: "X\u00e3" },
          { id: "2181", name: "Ea Ktur", prefix: "X\u00e3" },
          { id: "2182", name: "Ea Ning", prefix: "X\u00e3" },
          { id: "2183", name: "Ea Ti\u00eau", prefix: "X\u00e3" },
          { id: "2184", name: "H\u00f2a Hi\u1ec7p", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "19818", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19819",
            name: "Qu\u1ed1c l\u1ed9 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19820",
            name: "T\u1ec9nh l\u1ed9 690",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19821", name: "Trung H\u00f2a", prefix: "Ph\u1ed1" },
        ],
        projects: [],
      },
      {
        id: "151",
        name: "C\u01b0 M'gar",
        wards: [
          {
            id: "2185",
            name: "C\u01b0 Dli\u00ea M'n\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "2186", name: "C\u01b0 M'gar", prefix: "X\u00e3" },
          { id: "2187", name: "C\u01b0 Su\u00ea", prefix: "X\u00e3" },
          { id: "2188", name: "Cuor \u0110\u0103ng", prefix: "X\u00e3" },
          { id: "2189", name: "Ea D'r\u01a1ng", prefix: "X\u00e3" },
          { id: "2190", name: "Ea H'\u0111ing", prefix: "X\u00e3" },
          { id: "2191", name: "Ea Ki\u1ebft", prefix: "X\u00e3" },
          { id: "2192", name: "Ea Kpam", prefix: "X\u00e3" },
          { id: "2193", name: "Ea Ku\u1ebfh", prefix: "X\u00e3" },
          { id: "2194", name: "Ea M'dr\u00f3h", prefix: "X\u00e3" },
          { id: "2195", name: "Ea M'nang", prefix: "X\u00e3" },
          { id: "2196", name: "Ea P\u1ed1k", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "2197", name: "Ea Tar", prefix: "X\u00e3" },
          { id: "2198", name: "Ea Tul", prefix: "X\u00e3" },
          { id: "2199", name: "Qu\u1ea3ng Hi\u1ec7p", prefix: "X\u00e3" },
          {
            id: "2200",
            name: "Qu\u1ea3ng Ph\u00fa",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2201", name: "Qu\u1ea3ng Ti\u1ebfn", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "19822", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19823", name: "26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19824", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19825", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "19826",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19827",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19828",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19829",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19830",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19831",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19832",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19833",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19834",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19835",
            name: "N\u01a1 Trang Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19836", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19837",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19838",
            name: "T\u1ec9nh l\u1ed9 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19839",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19840",
            name: "Tr\u1ea7n Ki\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19841",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19842", name: "Y Ng\u00f4ng", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
      {
        id: "152",
        name: "Ea H'Leo",
        wards: [
          { id: "2202", name: "C\u01b0 A Mung", prefix: "X\u00e3" },
          { id: "2203", name: "C\u01b0 M\u1ed1t", prefix: "X\u00e3" },
          { id: "2204", name: "Dl\u00ea Yang", prefix: "X\u00e3" },
          { id: "2205", name: "Ea Dr\u0103ng", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "2207", name: "Ea H'leo", prefix: "X\u00e3" },
          { id: "2206", name: "Ea Hiao", prefix: "X\u00e3" },
          { id: "2208", name: "Ea Kh\u0103l", prefix: "X\u00e3" },
          { id: "2209", name: "Ea Nam", prefix: "X\u00e3" },
          { id: "2210", name: "Ea Ral", prefix: "X\u00e3" },
          { id: "2211", name: "Ea Sol", prefix: "X\u00e3" },
          { id: "2212", name: "Ea Tir", prefix: "X\u00e3" },
          { id: "2213", name: "Ea Wy", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "19843", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19844",
            name: "Gi\u1ea3i Ph\u00f3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19845",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19846",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19847",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "153",
        name: "Ea Kar",
        wards: [
          { id: "2214", name: "C\u01b0 B\u00f4ng", prefix: "X\u00e3" },
          { id: "2215", name: "C\u01b0 Elang", prefix: "X\u00e3" },
          { id: "2216", name: "C\u01b0 Hu\u00ea", prefix: "X\u00e3" },
          { id: "2217", name: "C\u01b0 Ni", prefix: "X\u00e3" },
          { id: "2218", name: "C\u01b0 Pr\u00f4ng", prefix: "X\u00e3" },
          { id: "2219", name: "C\u01b0 Yang", prefix: "X\u00e3" },
          { id: "2220", name: "Ea \u0110ar", prefix: "X\u00e3" },
          { id: "2221", name: "Ea Kar", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "2222", name: "Ea Km\u00fat", prefix: "X\u00e3" },
          { id: "2223", name: "Ea Kn\u1ed1p", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "2224", name: "Ea \u00d4", prefix: "X\u00e3" },
          { id: "2225", name: "Ea P\u0103l", prefix: "X\u00e3" },
          { id: "2226", name: "Ea Sar", prefix: "X\u00e3" },
          { id: "2227", name: "Ea S\u00f4", prefix: "X\u00e3" },
          { id: "2228", name: "Ea T\u00edh", prefix: "X\u00e3" },
          { id: "2229", name: "Xu\u00e2n Ph\u00fa", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "19848", name: "26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19849", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19850",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19851",
            name: "L\u00ea Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19852",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19853",
            name: "M\u1ea1c Th\u1ecb B\u01b0\u1edfi",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19854",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19855",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19856",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19857", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19858",
            name: "Qu\u1ed1c L\u1ed9 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19859",
            name: "T\u1ec9nh L\u1ed9 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19860",
            name: "T\u1ec9nh L\u1ed9 683",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "154",
        name: "Ea S\u00fap",
        wards: [
          { id: "2230", name: "C\u01b0 Kbang", prefix: "X\u00e3" },
          { id: "2231", name: "C\u01b0 M'lan", prefix: "X\u00e3" },
          { id: "2232", name: "Ea Bung", prefix: "X\u00e3" },
          { id: "2233", name: "Ea L\u00ea", prefix: "X\u00e3" },
          { id: "2234", name: "Ea R\u1ed1k", prefix: "X\u00e3" },
          { id: "2235", name: "Ea S\u00fap", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "2236", name: "Ia Jl\u01a1i", prefix: "X\u00e3" },
          { id: "2237", name: "Ia L\u1ed1p", prefix: "X\u00e3" },
          { id: "2238", name: "Ia Rv\u00ea", prefix: "X\u00e3" },
          { id: "2239", name: "Ya T\u1edd M\u1ed1t", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "19861",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19862",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "155",
        name: "Kr\u00f4ng Ana",
        wards: [
          { id: "2240", name: "B\u0103ng A Dr\u00eanh", prefix: "X\u00e3" },
          { id: "2241", name: "B\u00ecnh H\u00f2a", prefix: "X\u00e3" },
          {
            id: "2242",
            name: "Bu\u00f4n Tr\u1ea5p",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2243", name: "Dray S\u00e1p", prefix: "X\u00e3" },
          { id: "2244", name: "Dur Km\u0103l", prefix: "X\u00e3" },
          { id: "2245", name: "Ea B\u00f4ng", prefix: "X\u00e3" },
          { id: "2246", name: "Ea Na", prefix: "X\u00e3" },
          { id: "2247", name: "Qu\u1ea3ng \u0110i\u1ec1n", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "19863",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19864", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "19865",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19866",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19867",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19868",
            name: "T\u1ec9nh L\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19869",
            name: "T\u1ec9nh l\u1ed9 682",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19870",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "156",
        name: "Kr\u00f4ng B\u00f4ng",
        wards: [
          { id: "2248", name: "C\u01b0 Dr\u0103m", prefix: "X\u00e3" },
          { id: "2249", name: "C\u01b0 Kty", prefix: "X\u00e3" },
          { id: "2250", name: "C\u01b0 Pui", prefix: "X\u00e3" },
          { id: "2251", name: "Dang Kang", prefix: "X\u00e3" },
          { id: "2252", name: "Ea Trul", prefix: "X\u00e3" },
          { id: "2253", name: "H\u00f2a L\u1ec5", prefix: "X\u00e3" },
          { id: "2254", name: "H\u00f2a Phong", prefix: "X\u00e3" },
          { id: "2255", name: "H\u00f2a S\u01a1n", prefix: "X\u00e3" },
          { id: "2256", name: "H\u00f2a T\u00e2n", prefix: "X\u00e3" },
          { id: "2257", name: "H\u00f2a Th\u00e0nh", prefix: "X\u00e3" },
          {
            id: "2258",
            name: "Khu\u00ea Ng\u1ecdc \u0110i\u1ec1n",
            prefix: "X\u00e3",
          },
          { id: "2259", name: "Kr\u00f4ng Kmar", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "2260", name: "Yang Mao", prefix: "X\u00e3" },
          { id: "2261", name: "Yang Reh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "19871", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19872", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19873", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19874", name: "9", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
      {
        id: "157",
        name: "Kr\u00f4ng Buk",
        wards: [
          { id: "2262", name: "Ch\u1ee9 Kb\u00f4", prefix: "X\u00e3" },
          { id: "2263", name: "C\u01b0 N\u00e9", prefix: "X\u00e3" },
          { id: "2264", name: "C\u01b0 P\u01a1ng", prefix: "X\u00e3" },
          { id: "2265", name: "Ea Ngai", prefix: "X\u00e3" },
          { id: "2266", name: "Ea Sin", prefix: "X\u00e3" },
          { id: "2267", name: "Ea Sin", prefix: "X\u00e3" },
          { id: "2268", name: "P\u01a1ng Drang", prefix: "X\u00e3" },
          { id: "2269", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "19875", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19876",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19877",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19878",
            name: "T\u1ec9nh l\u1ed9 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "158",
        name: "Kr\u00f4ng N\u0103ng",
        wards: [
          { id: "2270", name: "C\u01b0 Kl\u00f4ng", prefix: "X\u00e3" },
          { id: "2271", name: "Dli\u00ea Ya", prefix: "X\u00e3" },
          { id: "2272", name: "Ea D\u0103h", prefix: "X\u00e3" },
          { id: "2273", name: "Ea H\u1ed3", prefix: "X\u00e3" },
          { id: "2274", name: "Ea P\u00fak", prefix: "X\u00e3" },
          { id: "2275", name: "Ea Tam", prefix: "X\u00e3" },
          { id: "2276", name: "Ea T\u00e2n", prefix: "X\u00e3" },
          { id: "2277", name: "Ea T\u00f3h", prefix: "X\u00e3" },
          {
            id: "2278",
            name: "Kr\u00f4ng N\u0103ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2279", name: "Ph\u00fa L\u1ed9c", prefix: "X\u00e3" },
          { id: "2280", name: "Ph\u00fa Xu\u00e2n", prefix: "X\u00e3" },
          { id: "2281", name: "Tam Giang", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "19879", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19880",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19881",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19882",
            name: "Qu\u1ed1c L\u1ed9 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19883",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19884",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19885",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "159",
        name: "Kr\u00f4ng P\u1eafc",
        wards: [
          { id: "2282", name: "Ea Hiu", prefix: "X\u00e3" },
          { id: "2283", name: "Ea K\u00eanh", prefix: "X\u00e3" },
          { id: "2284", name: "Ea Kly", prefix: "X\u00e3" },
          { id: "2285", name: "Ea Knuec", prefix: "X\u00e3" },
          { id: "2286", name: "Ea Ku\u0103ng", prefix: "X\u00e3" },
          { id: "2287", name: "Ea Ph\u00ea", prefix: "X\u00e3" },
          { id: "2288", name: "Ea Uy", prefix: "X\u00e3" },
          { id: "2289", name: "Ea Yi\u00eang", prefix: "X\u00e3" },
          { id: "2290", name: "Ea Y\u00f4ng", prefix: "X\u00e3" },
          { id: "2291", name: "H\u00f2a An", prefix: "X\u00e3" },
          { id: "2292", name: "Ho\u00e0 \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "2293", name: "H\u00f2a Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "2294", name: "Kr\u00f4ng B\u00fak", prefix: "X\u00e3" },
          {
            id: "2295",
            name: "Ph\u01b0\u1edbc An",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2296", name: "T\u00e2n Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "2297", name: "V\u1ee5 B\u1ed5n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "19886", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19887", name: "26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19888", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19889",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19890",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19891",
            name: "Gi\u1ea3i Ph\u00f3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19892",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19893",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19894",
            name: "Ng\u00f4 \u0110\u1ee9c K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19895",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19896",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19897",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19898",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19899",
            name: "N\u01a1 Trang Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19900",
            name: "Qu\u1ed1c L\u1ed9 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19901",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19902",
            name: "X\u00f4 Vi\u1ebft Ngh\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "160",
        name: "L\u0103k",
        wards: [
          { id: "2298", name: "B\u00f4ng Krang", prefix: "X\u00e3" },
          { id: "2299", name: "Bu\u00f4n Tr\u00eda", prefix: "X\u00e3" },
          { id: "2300", name: "Bu\u00f4n Tri\u1ebft", prefix: "X\u00e3" },
          { id: "2301", name: "\u0110\u1eafk Li\u00eang", prefix: "X\u00e3" },
          { id: "2302", name: "\u0110\u1eafk Nu\u00ea", prefix: "X\u00e3" },
          { id: "2303", name: "\u0110\u1eafk Ph\u01a1i", prefix: "X\u00e3" },
          { id: "2304", name: "Ea R'bin", prefix: "X\u00e3" },
          { id: "2305", name: "Kr\u00f4ng N\u00f4", prefix: "X\u00e3" },
          {
            id: "2306",
            name: "Li\u00ean S\u01a1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2307", name: "Nam Ka", prefix: "X\u00e3" },
          { id: "2308", name: "Yang Tao", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "19903", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19904",
            name: "Qu\u1ed1c l\u1ed9 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "161",
        name: "M'\u0110r\u0103k",
        wards: [
          { id: "2309", name: "C\u01b0 Kr\u00f3a", prefix: "X\u00e3" },
          { id: "2310", name: "C\u01b0 M'ta", prefix: "X\u00e3" },
          { id: "2311", name: "C\u01b0 Prao", prefix: "X\u00e3" },
          { id: "2312", name: "C\u01b0 San", prefix: "X\u00e3" },
          { id: "2313", name: "Ea H'mlay", prefix: "X\u00e3" },
          { id: "2314", name: "Ea Lai", prefix: "X\u00e3" },
          { id: "2315", name: "Ea M' Doal", prefix: "X\u00e3" },
          { id: "2316", name: "Ea Pil", prefix: "X\u00e3" },
          { id: "2317", name: "Ea Ri\u00eang", prefix: "X\u00e3" },
          { id: "2318", name: "Ea Trang", prefix: "X\u00e3" },
          { id: "2319", name: "Kr\u00f4ng \u00c1", prefix: "X\u00e3" },
          { id: "2320", name: "Kr\u00f4ng Jing", prefix: "X\u00e3" },
          { id: "2321", name: "M'Dr\u1eafk", prefix: "Th\u1ecb tr\u1ea5n" },
        ],
        streets: [
          {
            id: "19905",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19906",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19907",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "19908",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19909",
            name: "Qu\u1ed1c L\u1ed9 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19910",
            name: "T\u1ec9nh l\u1ed9 693",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "12",
    code: "CT",
    name: "C\u1ea7n Th\u01a1",
    districts: [
      {
        id: "162",
        name: " Th\u1edbi Lai",
        wards: [
          { id: "2322", name: "\u0110\u1ecbnh M\u00f4n", prefix: "X\u00e3" },
          { id: "2323", name: "\u0110\u00f4ng B\u00ecnh", prefix: "X\u00e3" },
          { id: "2324", name: "\u0110\u00f4ng Thu\u1eadn", prefix: "X\u00e3" },
          { id: "2325", name: "T\u00e2n Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "2326", name: "Th\u1edbi Lai", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "2327", name: "Th\u1edbi T\u00e2n", prefix: "X\u00e3" },
          { id: "2328", name: "Th\u1edbi Th\u1ea1nh", prefix: "X\u00e3" },
          {
            id: "2329",
            name: "Tr\u01b0\u1eddng Th\u1eafng",
            prefix: "X\u00e3",
          },
          {
            id: "2330",
            name: "Tr\u01b0\u1eddng Th\u00e0nh",
            prefix: "X\u00e3",
          },
          { id: "2331", name: "Tr\u01b0\u1eddng Xu\u00e2n", prefix: "X\u00e3" },
          {
            id: "2332",
            name: "Tr\u01b0\u1eddng Xu\u00e2n A",
            prefix: "X\u00e3",
          },
          {
            id: "2333",
            name: "Tr\u01b0\u1eddng Xu\u00e2n B",
            prefix: "X\u00e3",
          },
          { id: "2334", name: "Xu\u00e2n Th\u1eafng", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "19911",
            name: "T\u1ec9nh l\u1ed9 922",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "163",
        name: "B\u00ecnh Th\u1ee7y",
        wards: [
          { id: "2335", name: "8", prefix: "Ph\u01b0\u1eddng" },
          { id: "2336", name: "An Th\u1edbi", prefix: "Ph\u01b0\u1eddng" },
          { id: "2337", name: "An Th\u1edbi", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2338",
            name: "B\u00ecnh Th\u1ee7y",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2339",
            name: "B\u00f9i H\u1eefu Ngh\u0129a",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2340", name: "Long H\u00f2a", prefix: "Ph\u01b0\u1eddng" },
          { id: "2341", name: "Long Tuy\u1ec1n", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2342",
            name: "Th\u1edbi An \u0110\u00f4ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2343", name: "Tr\u00e0 An", prefix: "Ph\u01b0\u1eddng" },
          { id: "2344", name: "Tr\u00e0 N\u00f3c", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "19912", name: "32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19913", name: "49", prefix: "Ph\u1ed1" },
          { id: "19914", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19915", name: "52", prefix: "Ph\u1ed1" },
          { id: "19916", name: "91B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19917",
            name: "B\u00ecnh Ph\u00f3 A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19918",
            name: "B\u00ecnh Ph\u00f3 B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19919",
            name: "B\u00ecnh Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19920",
            name: "B\u1edd K\u00e8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19921",
            name: "B\u00f9i H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19922",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19923",
            name: "C\u00f4ng Binh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19924", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19925",
            name: "\u0110inh C\u00f4ng Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19926",
            name: "\u0110\u1ed3ng Ng\u1ecdc S\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19927", name: "F10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19928", name: "F5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19929", name: "F7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19930",
            name: "H\u1ed3 Trung Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19931",
            name: "Hoa Ki\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19932",
            name: "H\u1ed3i L\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19933",
            name: "H\u01b0\u01a1ng l\u1ed9 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19934",
            name: "Hu\u1ef3nh Phan H\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19935",
            name: "L\u00e0ng Hoa Ki\u1ec3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19936",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19937",
            name: "L\u00ea Quang Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19938",
            name: "L\u00ea Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19939",
            name: "L\u00ea V\u0103n B\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19940",
            name: "L\u00ea V\u0103n S\u00f4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19941",
            name: "M\u1eadu Th\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19942",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19943",
            name: "Nguy\u1ec5n \u0110\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19944",
            name: "Nguy\u1ec5n Thanh S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19945",
            name: "Nguy\u1ec5n Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19946",
            name: "Nguy\u1ec5n Th\u1ecb T\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19947",
            name: "Nguy\u1ec5n Th\u1ecb T\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19948",
            name: "Nguy\u1ec5n Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19949",
            name: "Nguy\u1ec5n Truy\u1ec1n Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19950",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19951",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19952",
            name: "Nguy\u1ec5n V\u0103n Tr\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19953",
            name: "Nguy\u1ec5n Vi\u1ec7t D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19954",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19955",
            name: "Ph\u1ea1m H\u1eefu L\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19956",
            name: "Ph\u1ea1m Th\u1ecb Ban",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19957", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19958",
            name: "Qu\u1ed1c l\u1ed9 91B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19959",
            name: "R\u1ea1ch B\u00e0 B\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "19960", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19961", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19962", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19963", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19964", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19965", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19966", name: "S\u1ed1 31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19967", name: "S\u1ed1 32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19968", name: "S\u1ed1 36", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19969", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19970", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19971", name: "S\u1ed1 50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19972", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19973", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19974", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19975", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "19976",
            name: "T\u1ea1 Th\u1ecb Phi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19977",
            name: "Th\u00e1i Th\u1ecb Nh\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19978",
            name: "T\u1ec9nh l\u1ed9 918",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19979",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19980",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19981",
            name: "Tr\u01b0\u1eddng Ti\u1ec1n B\u00f4ng Vang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19982",
            name: "V\u00e0nh \u0110ai B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19983",
            name: "V\u00e0nh \u0111ai Phi Tr\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "19984",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2701",
            name: "Anthoi Riverside",
            lat: "10.071967124939",
            lng: "105.75787353516",
          },
        ],
      },
      {
        id: "164",
        name: "C\u00e1i R\u0103ng",
        wards: [
          { id: "2345", name: "Ba L\u00e1ng", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2346",
            name: "H\u01b0ng Ph\u00fa",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2347",
            name: "H\u01b0ng Th\u1ea1nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2348", name: "L\u00ea B\u00ecnh", prefix: "Ph\u01b0\u1eddng" },
          { id: "2349", name: "Ph\u00fa Th\u1ee9", prefix: "Ph\u01b0\u1eddng" },
          { id: "2350", name: "T\u00e2n Ph\u00fa", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2351",
            name: "Th\u01b0\u1eddng Th\u1ea1nh",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "19985", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19986", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19987", name: "10B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19988", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19989", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19990", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19991", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19992", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19993", name: "1B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19994", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19995", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19996", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19997", name: "22A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19998", name: "25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "19999", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20000", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20001", name: "2B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20002", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20003", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20004", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20005", name: "44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20006", name: "45", prefix: "Ph\u1ed1" },
          { id: "20007", name: "46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20008", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20009", name: "48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20010", name: "49", prefix: "Ph\u1ed1" },
          { id: "20011", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20012", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20013", name: "51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20014", name: "52", prefix: "Ph\u1ed1" },
          { id: "20015", name: "53", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20016", name: "54", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20017", name: "55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20018", name: "56", prefix: "Ph\u1ed1" },
          { id: "20019", name: "57", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20020", name: "58", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20021", name: "59", prefix: "Ph\u1ed1" },
          { id: "20022", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20023", name: "60", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20024", name: "61", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20025", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20026", name: "7B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20027", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20028", name: "8C2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20029", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20030", name: "91C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20031", name: "A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20032", name: "A1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20033", name: "A10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20034", name: "A11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20035", name: "A12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20036", name: "A15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20037", name: "A2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20038", name: "A3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20039", name: "A4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20040", name: "A6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20041", name: "A8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20042", name: "A9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20043", name: "B1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20044", name: "B10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20045", name: "B11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20046", name: "B12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20047", name: "B15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20048", name: "B17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20049", name: "B2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20050", name: "B23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20051", name: "B24", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20052", name: "B26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20053", name: "B27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20054", name: "B28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20055", name: "B3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20056", name: "B30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20057", name: "B4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20058", name: "B5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20059", name: "B6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20060", name: "B8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20061", name: "B9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20062", name: "B\u00e0 Cai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20063",
            name: "B\u00f9i Quang Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20064", name: "C6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20065", name: "C66", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20066",
            name: "Cao Minh L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20067",
            name: "C\u1ed3n Kh\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20068", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20069", name: "D10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20070", name: "D13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20071", name: "D14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20072", name: "D15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20073", name: "D17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20074", name: "D1B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20075", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20076", name: "D24", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20077", name: "D25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20078", name: "D26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20079", name: "D28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20080", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20081", name: "D33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20082", name: "D4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20083", name: "D6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20084", name: "D7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20085", name: "D8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20086", name: "Dal", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20087",
            name: "Di\u1ec7u Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20088",
            name: "H\u00e0ng Xo\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20089",
            name: "H\u1eadu T\u00e2n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20090",
            name: "H\u1eadu Th\u1ea1nh M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20091",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20092",
            name: "Hu\u1ef3nh Th\u1ecb N\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20093",
            name: "L\u00ea B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20094",
            name: "L\u00ea Nh\u1ef1t T\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20095",
            name: "L\u1ed9 V\u00f2ng Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20096",
            name: "Mai Ch\u00ed Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20097",
            name: "Nguy\u00ean H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20098",
            name: "Nguy\u1ec5n Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20099",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20100",
            name: "Nguy\u1ec5n V\u0103n Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20101",
            name: "Nguy\u1ec5n Vi\u1ec7t D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20102",
            name: "Nh\u1eadt T\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20103",
            name: "N\u01b0\u1edbc V\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20104",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20105",
            name: "Ph\u1ea1m V\u0103n Nh\u1edd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20106",
            name: "Phan Tr\u1ecdng Tu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20107", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20108",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20109",
            name: "Qu\u1ed1c L\u1ed9 61",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20110",
            name: "Qu\u1ed1c l\u1ed9 61B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20111",
            name: "Qu\u1ed1c L\u1ed9 91C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20112", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20113", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20114", name: "S\u1ed1 10A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20115", name: "S\u1ed1 10B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20116", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20117", name: "S\u1ed1 113", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20118", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20119", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20120", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20121", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20122", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20123", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20124", name: "S\u1ed1 18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20125", name: "S\u1ed1 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20126", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20127", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20128", name: "S\u1ed1 21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20129", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20130", name: "S\u1ed1 24", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20131", name: "S\u1ed1 25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20132", name: "S\u1ed1 26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20133", name: "S\u1ed1 27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20134", name: "S\u1ed1 28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20135", name: "S\u1ed1 29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20136", name: "S\u1ed1 2C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20137", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20138", name: "S\u1ed1 30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20139", name: "S\u1ed1 31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20140", name: "S\u1ed1 37", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20141", name: "S\u1ed1 39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20142", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20143", name: "S\u1ed1 44A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20144", name: "S\u1ed1 45", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20145", name: "S\u1ed1 46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20146", name: "S\u1ed1 47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20147", name: "S\u1ed1 48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20148", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20149", name: "S\u1ed1 50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20150", name: "S\u1ed1 51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20151", name: "S\u1ed1 54", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20152", name: "S\u1ed1 55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20153", name: "S\u1ed1 57", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20154", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20155", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20156", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20157", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20158", name: "S\u1ed1 D25", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20159",
            name: "Tr\u1ea7n Chi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20160",
            name: "Tr\u1ea7n Ho\u00e0ng Na",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20161",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20162",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20163",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20164",
            name: "Tr\u1ea7n V\u0103n S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20165",
            name: "Tr\u1ea7n V\u0103n Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20166",
            name: "Tr\u1ea7n V\u0103n Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20167",
            name: "Tr\u01b0\u01a1ng V\u0129nh Nguy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20168",
            name: "V\u00e0nh \u0110ai 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20169",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20170",
            name: "V\u00f5 T\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20171",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20172",
            name: "V\u0169 \u0110\u00ecnh Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20173",
            name: "Y\u00ean H\u1ea1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20174",
            name: "Y\u00ean Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20175",
            name: "Y\u00ean Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2702",
            name: "H\u01b0ng Ph\u00fa",
            lat: "10.01212978363",
            lng: "105.78941345215",
          },
          {
            id: "2703",
            name: "Milano Plaza",
            lat: "10.01118850708",
            lng: "105.79025268555",
          },
          {
            id: "2704",
            name: "Nam Long - H\u01b0ng Th\u1ea1nh",
            lat: "10.010577201843",
            lng: "105.78753662109",
          },
          {
            id: "2705",
            name: "T\u00e2y Nguy\u00ean Plaza",
            lat: "10.004126548767",
            lng: "105.7977142334",
          },
        ],
      },
      {
        id: "165",
        name: "C\u1edd \u0110\u1ecf",
        wards: [
          {
            id: "2352",
            name: "C\u1edd \u0110\u1ecf",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2353", name: "\u0110\u00f4ng Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "2354", name: "\u0110\u00f4ng Th\u1eafng", prefix: "X\u00e3" },
          { id: "2355", name: "Th\u1ea1nh Ph\u00fa", prefix: "X\u00e3" },
          { id: "2356", name: "Th\u1edbi \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "2357", name: "Th\u1edbi H\u01b0ng", prefix: "X\u00e3" },
          { id: "2358", name: "Th\u1edbi Xu\u00e2n", prefix: "X\u00e3" },
          { id: "2359", name: "Trung An", prefix: "X\u00e3" },
          { id: "2360", name: "Trung H\u01b0ng", prefix: "X\u00e3" },
          { id: "2361", name: "Trung Th\u1ea1nh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "20176", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20177", name: "56", prefix: "Ph\u1ed1" },
          {
            id: "20178",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20179",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20180",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20181",
            name: "M\u1eadu Th\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20182",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20183",
            name: "T\u1ec9nh l\u1ed9 922",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20184",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20185",
            name: "X\u00f4 Vi\u1ebft Ngh\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "166",
        name: "Ninh Ki\u1ec1u",
        wards: [
          { id: "2362", name: "An B\u00ecnh", prefix: "Ph\u01b0\u1eddng" },
          { id: "2363", name: "An C\u01b0", prefix: "Ph\u01b0\u1eddng" },
          { id: "2364", name: "An H\u00f2a", prefix: "Ph\u01b0\u1eddng" },
          { id: "2365", name: "An H\u1ed9i", prefix: "Ph\u01b0\u1eddng" },
          { id: "2366", name: "An Kh\u00e1nh", prefix: "Ph\u01b0\u1eddng" },
          { id: "2367", name: "An L\u1ea1c", prefix: "Ph\u01b0\u1eddng" },
          { id: "2368", name: "An Nghi\u1ec7p", prefix: "Ph\u01b0\u1eddng" },
          { id: "2369", name: "An Ph\u00fa", prefix: "Ph\u01b0\u1eddng" },
          { id: "2370", name: "An Th\u1ea1nh", prefix: "Ph\u01b0\u1eddng" },
          { id: "2371", name: "C\u00e1i Kh\u1ebf", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2372",
            name: "H\u01b0ng L\u1ee3i",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2373", name: "T\u00e2n An", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2374",
            name: "Th\u1edbi B\u00ecnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2375",
            name: "Xu\u00e2n Kh\u00e1nh",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "20186", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20187", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20188", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20189", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20190", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20191", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20192", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20193", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20194", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20195", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20196", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20197", name: "24", prefix: "Ph\u1ed1" },
          { id: "20198", name: "25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20199", name: "26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20200", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20201", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20202", name: "2F", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20203", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20204", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20205", name: "30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20206", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20207", name: "36", prefix: "Ph\u1ed1" },
          { id: "20208", name: "38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20209", name: "3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20210", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20211", name: "46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20212", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20213", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20214", name: "57", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20215", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20216", name: "6A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20217", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20218", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20219", name: "8A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20220", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20221", name: "91B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20222", name: "A1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20223", name: "A101", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20224", name: "A2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20225", name: "A3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20226", name: "A4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20227", name: "A5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20228", name: "A6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20229", name: "A7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20230", name: "AB12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20231", name: "B1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20232", name: "B10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20233", name: "B11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20234", name: "B12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20235", name: "B13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20236", name: "B14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20237", name: "B15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20238", name: "B16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20239", name: "B18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20240", name: "B184", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20241", name: "B19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20242", name: "B2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20243", name: "B21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20244", name: "B22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20245", name: "B23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20246", name: "B24", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20247", name: "B25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20248", name: "B26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20249", name: "B28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20250", name: "B29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20251", name: "B3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20252", name: "B30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20253", name: "B31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20254", name: "B32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20255", name: "B34", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20256", name: "B36", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20257", name: "B37", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20258", name: "B39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20259", name: "B4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20260", name: "B5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20261", name: "B7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20262", name: "B8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20263", name: "B9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20264",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          { id: "20265", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "20266",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20267",
            name: "B\u00f9i H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20268",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20269", name: "C1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20270", name: "C3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20271",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20272",
            name: "C\u00e1i S\u01a1n - H\u00e0ng B\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20273",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20274",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20275",
            name: "Ch\u00e2u V\u0103n Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20276",
            name: "C\u1ed3n Kh\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20277", name: "D14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20278", name: "D17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20279", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20280", name: "D6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20284",
            name: "Di\u00ean Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20281",
            name: "\u0110\u1ea1i l\u1ed9 H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20282",
            name: "\u0110\u1ec1 Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20283",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20285",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20286",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20287",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20288",
            name: "\u0110\u1ed3ng Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20289", name: "E1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20290", name: "E4-D4", prefix: "Ph\u1ed1" },
          { id: "20291", name: "F2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20292", name: "F5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20293", name: "F6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20294", name: "F7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20295", name: "G2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20296", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "20297",
            name: "H\u1ed3 B\u00fang S\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20298",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20299",
            name: "H\u1ed3 X\u00e1ng Th\u1ed5i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20300",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20301",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20302",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20303",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20304",
            name: "H\u01b0ng Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20305",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20306",
            name: "Hu\u1ef3nh C\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20307",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20308",
            name: "L\u00ea Anh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20309",
            name: "L\u00ea B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20310",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20311", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20312",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20313",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20314",
            name: "L\u00ea V\u0103n S\u00f4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20315",
            name: "L\u00f2 M\u1ed7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20316",
            name: "L\u1ed9 Ng\u00e2n H\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20317",
            name: "L\u00f2 Nh\u00f4m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20318",
            name: "L\u1ed9 V\u00f2ng Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20319",
            name: "L\u01b0\u01a1ng \u0110\u1ecbnh C\u1ee7a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20320",
            name: "L\u00fd Ch\u00ednh Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20321",
            name: "L\u00fd H\u1ed3ng Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20322",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20323",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20324",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20325",
            name: "M\u1ea1c Thi\u00ean T\u00edch",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20326",
            name: "M\u1eadu Th\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20327", name: "N1/3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20328",
            name: "Nam K\u1ef3 Kh\u1edfi Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20329",
            name: "Nam S\u00f4ng H\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20330",
            name: "Ng\u00e2n H\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20331",
            name: "Ng\u00f4 \u0110\u1ee9c K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20332",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20333",
            name: "Ng\u00f4 H\u1eefu H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20334",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20335",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20336",
            name: "Ng\u00f4 V\u0103n S\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20337",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20338",
            name: "Nguy\u1ec5n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20339",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20340",
            name: "Nguy\u1ec5n C\u01b0 Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20343",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20341",
            name: "Nguy\u1ec5n \u0110\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20342",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20344",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20345",
            name: "Nguy\u1ec5n Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20346",
            name: "Nguy\u1ec5n H\u1eefu Tr\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20347",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20348",
            name: "Nguy\u1ec5n Ng\u1ecdc Trai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20349",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20350",
            name: "Nguy\u1ec5n Th\u1ea7n Hi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20351",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20352",
            name: "Nguy\u1ec5n Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20353",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20354",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20355",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20356",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20357",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20358",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20359",
            name: "Nguy\u1ec5n V\u0103n Tr\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20360",
            name: "Nguy\u1ec5n Vi\u1ec7t D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20361",
            name: "Nguy\u1ec5n Vi\u1ec7t H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20362",
            name: "Ph\u1ea1m C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20363",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20364",
            name: "Ph\u1ea1m Ng\u1ecdc H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20365",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20366",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20367",
            name: "Ph\u1ea1m Th\u1ebf Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20368",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20369",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20370",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20371",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20372",
            name: "Phan Huy Ch\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20373",
            name: "Phan Th\u1ebf Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20374",
            name: "Phan V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20375",
            name: "Qu\u00e1n Tr\u1ecdng Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20376", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20377",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20378",
            name: "Qu\u1ed1c l\u1ed9 91B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20379", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20380", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20381", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20382", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20383", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20384", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20385", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20386", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20387", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20388", name: "S\u1ed1 18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20389", name: "S\u1ed1 19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20390", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20391", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20392", name: "S\u1ed1 21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20393", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20394", name: "S\u1ed1 23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20395", name: "S\u1ed1 24", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20396", name: "S\u1ed1 25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20397", name: "S\u1ed1 26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20398", name: "S\u1ed1 29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20399", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20400", name: "S\u1ed1 31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20401", name: "S\u1ed1 36", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20402", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20403", name: "S\u1ed1 45", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20404", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20405", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20406", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20407", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20408", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20409", name: "S\u1ed1 A1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20410",
            name: "S\u00f4ng H\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20411", name: "T\u1ea7m Vu", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20412",
            name: "T\u00e2n Tr\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20413",
            name: "Th\u1edbi Nh\u1ef1t 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20414",
            name: "Th\u1edbi Nh\u1ef1t 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20415",
            name: "Th\u1ee7 Khoa Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20416",
            name: "T\u1ec9nh L\u1ed9 923",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20417",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20418",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20419",
            name: "Tr\u1ea7n B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20420",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20421",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20422",
            name: "Tr\u1ea7n Ho\u00e0ng Na",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20423",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20424",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20425",
            name: "Tr\u1ea7n Ng\u1ecdc Qu\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20426",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20427",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20428",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20429",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20430",
            name: "Tr\u1ea7n V\u0103n Gi\u00e0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20431",
            name: "Tr\u1ea7n V\u0103n Ho\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20432",
            name: "Tr\u1ea7n V\u0103n Kh\u00e9o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20433",
            name: "Tr\u1ea7n V\u0103n \u01a0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20434",
            name: "Tr\u1ea7n Vi\u1ec7t Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20435",
            name: "Tr\u1ea7n V\u0129nh Ki\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20436",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20437",
            name: "T\u00fa X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20438",
            name: "Ung V\u0103n Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20439",
            name: "V\u00e0nh \u0110ai B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20440",
            name: "V\u00e0nh \u0111ai Phi Tr\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20441",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20442",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20443",
            name: "V\u00f5 Tr\u01b0\u1eddng To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20444",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20445",
            name: "V\u00f5 V\u0103n T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20446",
            name: "X\u00f4 Vi\u1ebft Ngh\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20447",
            name: "Xu\u00e2n Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2706",
            name: "KDC H\u1ed3ng Ph\u00e1t A",
            lat: "10.022591590881",
            lng: "105.74512481689",
          },
          {
            id: "2707",
            name: "KDC H\u1ed3ng Ph\u00e1t B",
            lat: "10.02419757843",
            lng: "105.74942016602",
          },
          {
            id: "2708",
            name: "TD Plaza C\u1ea7n Th\u01a1",
            lat: "10.049569129944",
            lng: "105.78687286377",
          },
          {
            id: "2709",
            name: "Vincom C\u1ea7n Th\u01a1",
            lat: "10.026397705078",
            lng: "105.77597045898",
          },
        ],
      },
      {
        id: "167",
        name: "\u00d4 M\u00f4n",
        wards: [
          {
            id: "2376",
            name: "Ch\u00e2u V\u0103n Li\u00eam",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2377", name: "Long H\u01b0ng", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2378",
            name: "Ph\u01b0\u1edbc Th\u1edbi",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2379", name: "Th\u1edbi An", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2380",
            name: "Th\u1edbi H\u00f2a",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2381", name: "Th\u1edbi Long", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2382",
            name: "Tr\u01b0\u1eddng L\u1ea1c",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "20448", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20449", name: "26/3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20450", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20451", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20452",
            name: "B\u00ecnh H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20453",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20454",
            name: "Ch\u00e2u V\u0103n Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20455",
            name: "Hu\u1ef3nh Th\u1ecb Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20456",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20457",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20458",
            name: "L\u1ed9 V\u00f2ng Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20459",
            name: "L\u01b0u H\u1eefu Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20460",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20461",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20462",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20463",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20464",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20465",
            name: "Qu\u1ed1c l\u1ed9 91",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20466",
            name: "Qu\u1ed1c L\u1ed9 91A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20467",
            name: "Quy\u1ebft Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20468",
            name: "T\u1ec9nh L\u1ed9 923",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20469",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20470",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20471",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20472",
            name: "Tr\u01b0\u01a1ng V\u0103n Di\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "168",
        name: "Phong \u0110i\u1ec1n",
        wards: [
          { id: "2383", name: "Giai Xu\u00e2n", prefix: "X\u00e3" },
          { id: "2384", name: "M\u1ef9 Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "2385", name: "Nh\u01a1n \u00c1i", prefix: "X\u00e3" },
          { id: "2386", name: "Nh\u01a1n Ngh\u0129a", prefix: "X\u00e3" },
          {
            id: "2387",
            name: "Phong \u0110i\u1ec1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2388", name: "T\u00e2n Th\u1edbi", prefix: "X\u00e3" },
          { id: "2389", name: "Tr\u01b0\u1eddng Long", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "20473", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20474", name: "26", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20475",
            name: "B\u00f4ng Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20476",
            name: "B\u00f4ng Vang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20477",
            name: "B\u00f9i H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20478",
            name: "M\u1ef9 Kh\u00e1nh-B\u00f4ng Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20479",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20480",
            name: "Phan V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20481",
            name: "Qu\u1ed1c l\u1ed9 61B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20482", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20483", name: "S\u1ed1 27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20484", name: "S\u1ed1 29", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20485",
            name: "T\u1ec9nh L\u1ed9 923",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20486",
            name: "T\u1ec9nh l\u1ed9 926",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20487",
            name: "T\u1ec9nh l\u1ed9 932",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20488",
            name: "Tr\u01b0\u1eddng Ti\u1ec1n B\u00f4ng Vang",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "169",
        name: "Th\u1ed1t N\u1ed1t",
        wards: [
          {
            id: "2390",
            name: "T\u00e2n H\u01b0ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2391", name: "T\u00e2n L\u1ed9c", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2392",
            name: "Th\u1ea1nh H\u00f2a",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2393", name: "Th\u1ea1nh L\u1ed9c", prefix: "X\u00e3" },
          {
            id: "2394",
            name: "Th\u1edbi Thu\u1eadn",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2395",
            name: "Th\u1ed1t N\u1ed1t",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2396", name: "Thu\u1eadn An", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2397",
            name: "Thu\u1eadn H\u01b0ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2398", name: "Trung Ki\u00ean", prefix: "Ph\u01b0\u1eddng" },
          { id: "2399", name: "Trung Nh\u1ee9t", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "20489", name: "91A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20490",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20491",
            name: "Nguy\u1ec5n V\u0103n Kim",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20492",
            name: "Qu\u1ed1c l\u1ed9 91",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20493",
            name: "Qu\u1ed1c l\u1ed9 91B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20494",
            name: "T\u1ec9nh l\u1ed9 91",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20495",
            name: "T\u1ec9nh l\u1ed9 921",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "170",
        name: "V\u0129nh Th\u1ea1nh",
        wards: [
          { id: "2400", name: "Th\u1ea1nh An", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "2401", name: "Th\u1ea1nh An", prefix: "X\u00e3" },
          { id: "2402", name: "Th\u1ea1nh L\u1ed9c", prefix: "X\u00e3" },
          { id: "2403", name: "Th\u1ea1nh L\u1ee3i", prefix: "X\u00e3" },
          { id: "2404", name: "Th\u1ea1nh M\u1ef9", prefix: "X\u00e3" },
          { id: "2405", name: "Th\u1ea1nh Qu\u1edbi", prefix: "X\u00e3" },
          { id: "2406", name: "Th\u1ea1nh Th\u1eafng", prefix: "X\u00e3" },
          { id: "2407", name: "Th\u1ea1nh Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "2408", name: "V\u0129nh B\u00ecnh", prefix: "X\u00e3" },
          {
            id: "2409",
            name: "V\u0129nh Th\u1ea1nh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2410", name: "V\u0129nh Trinh", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "20496",
            name: "Qu\u1ed1c l\u1ed9 80",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20497",
            name: "V\u0129nh L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "13",
    code: "BTH",
    name: "B\u00ecnh Thu\u1eadn  ",
    districts: [
      {
        id: "171",
        name: "B\u1eafc B\u00ecnh",
        wards: [
          { id: "2411", name: "B\u00ecnh An", prefix: "X\u00e3" },
          { id: "2412", name: "B\u00ecnh T\u00e2n", prefix: "X\u00e3" },
          {
            id: "2413",
            name: "Ch\u1ee3 L\u1ea7u",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2414", name: "H\u1ea3i Ninh", prefix: "X\u00e3" },
          { id: "2415", name: "Ho\u00e0 Th\u1eafng", prefix: "X\u00e3" },
          { id: "2416", name: "H\u1ed3ng Phong", prefix: "X\u00e3" },
          { id: "2417", name: "H\u1ed3ng Th\u00e1i", prefix: "X\u00e3" },
          {
            id: "2418",
            name: "L\u01b0\u01a1ng S\u01a1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2419", name: "Phan \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "2420", name: "Phan Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "2421", name: "Phan Ho\u00e0", prefix: "X\u00e3" },
          { id: "2422", name: "Phan L\u00e2m", prefix: "X\u00e3" },
          { id: "2423", name: "Phan R\u00ed Th\u00e0nh", prefix: "X\u00e3" },
          { id: "2424", name: "Phan S\u01a1n", prefix: "X\u00e3" },
          { id: "2425", name: "Phan Thanh", prefix: "X\u00e3" },
          { id: "2426", name: "Phan Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "2427", name: "S\u00f4ng B\u00ecnh", prefix: "X\u00e3" },
          { id: "2428", name: "S\u00f4ng L\u0169y", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "20498", name: "715", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20499", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20500",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20501",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20502",
            name: "Qu\u1ed1c L\u1ed9 28B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20503",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "172",
        name: "\u0110\u1ea3o Ph\u00fa Qu\u00fd",
        wards: [
          { id: "2429", name: "Long H\u1ea3i", prefix: "X\u00e3" },
          { id: "2430", name: "Ng\u0169 Ph\u1ee5ng", prefix: "X\u00e3" },
          { id: "2431", name: "Tam Thanh", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "173",
        name: "\u0110\u1ee9c Linh",
        wards: [
          { id: "2432", name: "\u0110a Kai", prefix: "X\u00e3" },
          { id: "2433", name: "\u0110\u00f4ng H\u00e0", prefix: "X\u00e3" },
          { id: "2434", name: "\u0110\u1ee9c Ch\u00ednh", prefix: "X\u00e3" },
          { id: "2435", name: "\u0110\u1ee9c H\u1ea1nh", prefix: "X\u00e3" },
          {
            id: "2436",
            name: "\u0110\u1ee9c T\u00e0i",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2437", name: "\u0110\u1ee9c T\u00edn", prefix: "X\u00e3" },
          { id: "2438", name: "M\u00ea Pu", prefix: "X\u00e3" },
          { id: "2439", name: "Nam Ch\u00ednh", prefix: "X\u00e3" },
          { id: "2440", name: "S\u00f9ng Nh\u01a1n", prefix: "X\u00e3" },
          { id: "2441", name: "T\u00e2n H\u00e0", prefix: "X\u00e3" },
          { id: "2442", name: "Tr\u00e0 T\u00e2n", prefix: "X\u00e3" },
          { id: "2443", name: "V\u00f5 Xu", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "2444", name: "V\u0169 H\u00f2a", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "20504", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20505", name: "\u0110T 713", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20506", name: "\u0110T 766", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20507", name: "S\u1ed1 45", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20508",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20509",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20510", name: "Z30A", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
      {
        id: "174",
        name: "H\u00e0m T\u00e2n",
        wards: [
          { id: "2445", name: "S\u01a1n M\u1ef9", prefix: "X\u00e3" },
          { id: "2446", name: "S\u00f4ng Phan", prefix: "X\u00e3" },
          { id: "2447", name: "T\u00e2n \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "2448", name: "T\u00e2n H\u00e0", prefix: "X\u00e3" },
          { id: "2449", name: "T\u00e2n Minh", prefix: "Th\u1ecb tr\u1ea5n" },
          {
            id: "2450",
            name: "T\u00e2n Ngh\u0129a",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2451", name: "T\u00e2n Ph\u00fac", prefix: "X\u00e3" },
          { id: "2452", name: "T\u00e2n Th\u1eafng", prefix: "X\u00e3" },
          { id: "2453", name: "T\u00e2n Xu\u00e2n", prefix: "X\u00e3" },
          { id: "2454", name: "Th\u1eafng H\u1ea3i", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "20511", name: "55", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20512",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20513",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20514",
            name: "Qu\u1ed1c L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20515",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20516",
            name: "Qu\u1ed1c L\u1ed9 55",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20517",
            name: "T\u1ec9nh L\u1ed9 719",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20518",
            name: "T\u1ec9nh L\u1ed9 720",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "175",
        name: "H\u00e0m Thu\u1eadn B\u1eafc",
        wards: [
          { id: "2455", name: "\u0110a Mi", prefix: "X\u00e3" },
          { id: "2456", name: "\u0110\u00f4ng Giang", prefix: "X\u00e3" },
          { id: "2457", name: "\u0110\u00f4ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "2458", name: "H\u00e0m Ch\u00ednh", prefix: "X\u00e3" },
          { id: "2459", name: "H\u00e0m \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "2460", name: "H\u00e0m Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "2461", name: "H\u00e0m Li\u00eam", prefix: "X\u00e3" },
          { id: "2462", name: "H\u00e0m Ph\u00fa", prefix: "X\u00e3" },
          { id: "2463", name: "H\u00e0m Th\u1eafng", prefix: "X\u00e3" },
          { id: "2464", name: "H\u00e0m Tr\u00ed", prefix: "X\u00e3" },
          { id: "2465", name: "H\u1ed3ng Li\u00eam", prefix: "X\u00e3" },
          { id: "2466", name: "H\u1ed3ng S\u01a1n", prefix: "X\u00e3" },
          { id: "2467", name: "La D\u1ea1", prefix: "X\u00e3" },
          { id: "2468", name: "Ma L\u00e2m", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "2469", name: "M\u1ef9 Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "2470", name: "Ph\u00fa Long", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "2471", name: "Thu\u1eadn H\u00f2a", prefix: "X\u00e3" },
          { id: "2472", name: "Thu\u1eadn Minh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "20519", name: "B\u00e0u Sen", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20520", name: "\u0110T 741", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20521",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20522",
            name: "H\u00e0m Nh\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20523",
            name: "H\u1ed3ng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20524",
            name: "Qu\u1ed1c L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20525",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20526",
            name: "Qu\u1ed1c l\u1ed9 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20527",
            name: "Qu\u1ed1c l\u1ed9 28",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20528",
            name: "Qu\u1ed1c L\u1ed9 55",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20529",
            name: "Ti\u0309nh L\u1ed9 711",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20530",
            name: "T\u1ec9nh l\u1ed9 718",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "176",
        name: "H\u00e0m Thu\u1eadn Nam",
        wards: [
          { id: "2473", name: "H\u00e0m C\u1ea7n", prefix: "X\u00e3" },
          { id: "2474", name: "H\u00e0m C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "2475", name: "H\u00e0m Ki\u1ec7m", prefix: "X\u00e3" },
          { id: "2476", name: "H\u00e0m Minh", prefix: "X\u00e3" },
          { id: "2477", name: "H\u00e0m M\u1ef9", prefix: "X\u00e3" },
          { id: "2478", name: "H\u00e0m Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "2479", name: "M\u01b0\u01a1ng M\u00e1n", prefix: "X\u00e3" },
          { id: "2480", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "2481", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "2482", name: "T\u00e2n Thu\u1eadn", prefix: "X\u00e3" },
          { id: "2483", name: "Thu\u1eadn Nam", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "2484", name: "Thu\u1eadn Qu\u00fd", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "20531", name: "709", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20532",
            name: "\u0110\u00e0i Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20533", name: "\u0110T 719", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20534",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20535",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20536",
            name: "Qu\u1ed1c L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20537",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20538",
            name: "Qu\u1ed1c L\u1ed9 55",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20539",
            name: "T\u1ec9nh l\u1ed9 718",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20540",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2710",
            name: "Aloha Beach Village",
            lat: "10.773213386536",
            lng: "107.99903106689",
          },
          {
            id: "2711",
            name: "Condotel Ocean View",
            lat: "10.747647285461",
            lng: "108.01451873779",
          },
          {
            id: "2712",
            name: "La Perla Villas Resort",
            lat: "10.705483436584",
            lng: "107.98947906494",
          },
          {
            id: "2713",
            name: "S\u00e0i G\u00f2n Su\u1ed1i Nhum",
            lat: "10.767616271973",
            lng: "108.01885223389",
          },
          {
            id: "2714",
            name: "The Pavillons Villa",
            lat: "10.75793170929",
            lng: "108.01860809326",
          },
        ],
      },
      {
        id: "177",
        name: "La Gi",
        wards: [
          {
            id: "2485",
            name: "B\u00ecnh T\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2486",
            name: "Ph\u01b0\u1edbc H\u1ed9i",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2487",
            name: "Ph\u01b0\u1edbc L\u1ed9c",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2488", name: "T\u00e2n An", prefix: "Ph\u01b0\u1eddng" },
          { id: "2489", name: "T\u00e2n B\u00ecnh", prefix: "X\u00e3" },
          { id: "2490", name: "T\u00e2n H\u1ea3i", prefix: "X\u00e3" },
          { id: "2491", name: "T\u00e2n Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          {
            id: "2492",
            name: "T\u00e2n Thi\u1ec7n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2493", name: "T\u00e2n Ti\u1ebfn", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "20541", name: "23/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20542", name: "55", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20543",
            name: "B\u1eafc \u00c1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20544",
            name: "B\u00ecnh Chu\u1ea9n 67",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20545",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20546",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20547",
            name: "C\u00f4 Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20548", name: "D", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20549",
            name: "\u0110inh B\u1ed9 L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20550",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20551",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20552",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20553",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20554",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20555", name: "La Gi", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20556",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20557",
            name: "L\u00ea Minh C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20558",
            name: "L\u00ea Th\u00e1nh T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20559",
            name: "L\u00ea Th\u1ecb Ri\u00eang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20560",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20561",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20562",
            name: "Mai Th\u00fac Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20563",
            name: "Ng\u00f4 \u0110\u1ee9c T\u1ed1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20564",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20565",
            name: "Nguy\u1ec5n C\u1ea3nh Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20566",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20567",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20569",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20568",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20570",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20571",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20572",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20573",
            name: "Nguy\u1ec5n Ng\u1ecdc K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20574",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20575",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20576",
            name: "Nguy\u1ec5n Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20577",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20578",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20579",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20580",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20581",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20582",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20583",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20584",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20585",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20586",
            name: "Qu\u1ed1c L\u1ed9 55",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20587",
            name: "Qu\u1ef3nh L\u01b0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20588",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20589",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20590",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20591",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20592",
            name: "Tri\u1ec7u Th\u1ecb Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20593",
            name: "Tr\u01b0ng Tr\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20594",
            name: "Tr\u01b0\u01a1ng V\u0129nh K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20595",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2715",
            name: "Fusion Alya La Gi",
            lat: "10.651034355164",
            lng: "107.77153015137",
          },
        ],
      },
      {
        id: "178",
        name: "Phan Thi\u1ebft",
        wards: [
          {
            id: "2494",
            name: "B\u00ecnh H\u01b0ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2495",
            name: "\u0110\u1ee9c Long",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2496",
            name: "\u0110\u1ee9c Ngh\u0129a",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2497",
            name: "\u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2498",
            name: "H\u00e0m Ti\u1ebfn",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2499", name: "H\u01b0ng Long", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2500",
            name: "L\u1ea1c \u0110\u1ea1o",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2501", name: "M\u0169i N\u00e9", prefix: "Ph\u01b0\u1eddng" },
          { id: "2502", name: "Phong N\u1eabm", prefix: "X\u00e3" },
          { id: "2503", name: "Phong N\u1eabm", prefix: "X\u00e3" },
          { id: "2504", name: "Ph\u00fa H\u00e0i", prefix: "Ph\u01b0\u1eddng" },
          { id: "2505", name: "Ph\u00fa T\u00e0i", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2506",
            name: "Ph\u00fa Th\u1ee7y",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2507", name: "Ph\u00fa Trinh", prefix: "Ph\u01b0\u1eddng" },
          { id: "2508", name: "Thanh H\u1ea3i", prefix: "Ph\u01b0\u1eddng" },
          { id: "2509", name: "Thi\u1ec7n Nghi\u1ec7p", prefix: "X\u00e3" },
          { id: "2510", name: "Ti\u1ebfn L\u1ee3i", prefix: "X\u00e3" },
          { id: "2511", name: "Ti\u1ebfn Th\u00e0nh", prefix: "X\u00e3" },
          { id: "2512", name: "Xu\u00e2n An", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "20596", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20597", name: "19/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20598", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20599", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20600", name: "706B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20601", name: "715", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20602", name: "716", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20603", name: "719", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20604",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20605", name: "B6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20606",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          { id: "20607", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "20608",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20609",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20610",
            name: "B\u00f9i Vi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20611",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20612",
            name: "Cao H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20613",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20614",
            name: "Ch\u00e2u Li\u00eam S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20615",
            name: "Ch\u00e2u V\u0103n Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20616",
            name: "Ch\u1ebf Lan Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20617",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20618",
            name: "C\u00f4ng H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20619",
            name: "C\u01b0\u1eddng \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20620",
            name: "D\u00e3 T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20635",
            name: "D\u01b0\u01a1ng \u0110\u00ecnh Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20621",
            name: "\u0110\u1ea1i L\u1ed9 H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20622",
            name: "\u0110\u1eb7ng Th\u1ecb Nhu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20623",
            name: "\u0110\u1eb7ng V\u0103n L\u00e3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20624",
            name: "\u0110\u1eb7ng V\u0103n Ng\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20625",
            name: "\u0110\u00e0o Duy Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20626",
            name: "\u0110\u00e0o T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20627",
            name: "\u0110inh B\u1ed9 L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20628",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20629",
            name: "\u0110\u1ed7 H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20630",
            name: "\u0110\u00f4ng Xu\u00e2n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20631", name: "\u0110T 706", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20632", name: "\u0110T 715", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20633", name: "\u0110T 716", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20634", name: "\u0110T 719", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20636",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20637",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20638",
            name: "H\u00e0m Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20639",
            name: "H\u00e0m Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20640",
            name: "H\u00e0n M\u1eb7c T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20641",
            name: "H\u00e0n Thuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20642",
            name: "Hi\u1ec1n V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20643",
            name: "H\u1ed3 Ng\u1ecdc L\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20644",
            name: "H\u1ed3 Quang C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20645",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20646",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20647",
            name: "Ho\u00e0ng B\u00edch S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20648",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20649",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20650",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20651",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20652",
            name: "Hu\u1ef3nh T\u1ea5n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20653",
            name: "Hu\u1ef3nh Th\u1ecb Kh\u1ea3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20654",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20655",
            name: "Kim Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20656", name: "K\u00fd Con", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20657",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20658",
            name: "L\u00e2m \u0110\u00ecnh Tr\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20660",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20659",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20661",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20662", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20663",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20664",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20665",
            name: "L\u00ea Th\u00e1nh T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20666",
            name: "L\u00ea Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20667",
            name: "L\u00ea V\u0103n H\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20668",
            name: "L\u00ea V\u0103n Ph\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20669",
            name: "L\u00f2 T\u1ec9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20670",
            name: "Long S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20671",
            name: "L\u01b0\u01a1ng \u0110\u1ecbnh C\u1ee7a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20672",
            name: "L\u01b0\u01a1ng Ng\u1ecdc Quy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20673",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20674",
            name: "L\u01b0\u01a1ng V\u0103n N\u0103m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20675",
            name: "L\u00fd \u0110\u1ea1o H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20676",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20677",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20678",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20679",
            name: "M\u1eadu Th\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20680",
            name: "M\u0169i N\u00e9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20681", name: "N4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20682", name: "N6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20683",
            name: "Ng\u00f4 S\u0129 Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20684",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20685",
            name: "Ng\u01b0 \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20686",
            name: "Nguy\u1ec5n Bi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20687",
            name: "Nguy\u1ec5n C\u01a1 Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20688",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20690",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20689",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20691",
            name: "Nguy\u1ec5n Gia T\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20692",
            name: "Nguy\u1ec5n H\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20693",
            name: "Nguy\u1ec5n H\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20694",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20695",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20696",
            name: "Nguy\u1ec5n H\u1eefu Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20697",
            name: "Nguy\u1ec5n Minh Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20698",
            name: "Nguy\u1ec5n Ph\u00fac Chu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20699",
            name: "Nguy\u1ec5n Ph\u00fac Nguy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20700",
            name: "Nguy\u1ec5n S\u1eafc Kim",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20701",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20702",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20703",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20704",
            name: "Nguy\u1ec5n Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20705",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20706",
            name: "Nguy\u1ec5n T\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20707",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20708",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20709",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20710",
            name: "Nguy\u1ec5n V\u0103n T\u1ed1",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20711",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20712",
            name: "Nguy\u1ec5n V\u0103n T\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20713",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20714",
            name: "\u00d4ng \u00cdch Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20715", name: "Pasteur", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20716",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20717",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20718",
            name: "Ph\u1ea1m Th\u1ecb Ng\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20719",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20720",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20721",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20722",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20723", name: "Phan Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20724",
            name: "Ph\u00fa Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20725", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          { id: "20726", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20727",
            name: "Qu\u1ea3ng Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20728",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20729",
            name: "Qu\u1ed1c l\u1ed9 28",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20730", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20731", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20732", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20733",
            name: "T\u0103ng B\u1ea1t H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20734",
            name: "Th\u00e1i Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20735",
            name: "Th\u1ee7 Khoa Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20736",
            name: "T\u1ec9nh L\u1ed9 28",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20737",
            name: "T\u1ec9nh L\u1ed9 706",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20738",
            name: "T\u1ec9nh l\u1ed9 706B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20739",
            name: "T\u1ec9nh L\u1ed9 715",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20740",
            name: "T\u1ec9nh L\u1ed9 716",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20741",
            name: "T\u1ec9nh L\u1ed9 719",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20742",
            name: "T\u1ec9nh L\u1ed9 76B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20743",
            name: "T\u00f4 V\u0129nh Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20744",
            name: "T\u00f4n \u0110\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20745",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20746",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20747",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20748",
            name: "Tr\u1ea7n L\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20749",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20750",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20751",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20752",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20753",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20754",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20755",
            name: "Tr\u01b0ng Nh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20756",
            name: "Tr\u01b0ng Tr\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20757",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20758",
            name: "Tr\u01b0\u01a1ng Gia N\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20759",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20760",
            name: "Tr\u01b0\u01a1ng V\u0103n Ly",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20761",
            name: "T\u00fa Lu\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20762",
            name: "T\u1eeb V\u0103n T\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20763",
            name: "Tuy\u00ean Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20764",
            name: "V\u0103n Th\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20765",
            name: "V\u0103n Th\u00e1nh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20766",
            name: "V\u0103n Th\u00e1nh 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20767",
            name: "V\u0103n Th\u00e1nh 3A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20768",
            name: "V\u1ea1n Th\u1ee7y T\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20769",
            name: "V\u00f5 H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20770",
            name: "V\u00f5 Li\u00eam S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20771",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20772",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20773",
            name: "V\u00f5 V\u0103n D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20774",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20775",
            name: "V\u00f5 V\u0103n T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20776",
            name: "Xu\u00e2n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20777", name: "Yersin", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "2716",
            name: "Acenza Villas",
            lat: "10.940414428711",
            lng: "108.16236877441",
          },
          {
            id: "2717",
            name: "Casa Lavanda",
            lat: "10.943014144897",
            lng: "108.17821502686",
          },
          {
            id: "2718",
            name: "HQC Ph\u00fa T\u00e0i",
            lat: "10.940999031067",
            lng: "108.0980758667",
          },
          {
            id: "2719",
            name: "KDC Ph\u00fa T\u00e0i - Ph\u00fa Trinh",
            lat: "10.937307357788",
            lng: "108.09008789062",
          },
          {
            id: "2720",
            name: "Mui Ne Cactus Resort",
            lat: "10.938767433167",
            lng: "108.17644500732",
          },
          {
            id: "2721",
            name: "Ocean Dunes",
            lat: "10.928998947144",
            lng: "108.11622619629",
          },
          {
            id: "2722",
            name: "Ocean Vista",
            lat: "10.93793964386",
            lng: "108.18341827393",
          },
          {
            id: "2723",
            name: "Queen Pearl",
            lat: "10.940061569214",
            lng: "108.15937805176",
          },
          {
            id: "2724",
            name: "Royal Hill",
            lat: "10.946674346924",
            lng: "108.19063568115",
          },
          {
            id: "2725",
            name: "Sea Links City",
            lat: "10.944976806641",
            lng: "108.18270111084",
          },
          {
            id: "2726",
            name: "Sentosa Villa",
            lat: "10.948039054871",
            lng: "108.27478790283",
          },
          {
            id: "2727",
            name: "Sunny Villa",
            lat: "10.960894584656",
            lng: "108.31075286865",
          },
          {
            id: "2728",
            name: "The Cliff Resort & Residences",
            lat: "10.936370849609",
            lng: "108.17650604248",
          },
          {
            id: "2729",
            name: "TTTM B\u1eafc Phan Thi\u1ebft",
            lat: "10.938756942749",
            lng: "108.10069274902",
          },
          {
            id: "2730",
            name: "Vietpearl City",
            lat: "10.922538757324",
            lng: "108.09481048584",
          },
        ],
      },
      {
        id: "179",
        name: "T\u00e1nh Linh",
        wards: [
          { id: "2513", name: "B\u1eafc Ru\u1ed9ng", prefix: "X\u00e3" },
          { id: "2514", name: "\u0110\u1ed3ng Kho", prefix: "X\u00e3" },
          { id: "2515", name: "\u0110\u1ee9c B\u00ecnh", prefix: "X\u00e3" },
          { id: "2516", name: "\u0110\u1ee9c Ph\u00fa", prefix: "X\u00e3" },
          { id: "2517", name: "\u0110\u1ee9c T\u00e2n", prefix: "X\u00e3" },
          { id: "2518", name: "\u0110\u1ee9c Thu\u1eadn", prefix: "X\u00e3" },
          { id: "2519", name: "Gia An", prefix: "X\u00e3" },
          { id: "2520", name: "Gia Huynh", prefix: "X\u00e3" },
          { id: "2521", name: "Huy Khi\u00eam", prefix: "X\u00e3" },
          { id: "2522", name: "La Ng\u00e2u", prefix: "X\u00e3" },
          {
            id: "2523",
            name: "L\u1ea1c T\u00e1nh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2524", name: "M\u0103ng T\u1ed1", prefix: "X\u00e3" },
          { id: "2525", name: "Ngh\u1ecb \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "2526", name: "Su\u1ed1i Ki\u1ebft", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "20778", name: "710", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20779",
            name: "Qu\u1ed1c L\u1ed9 55",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "180",
        name: "Tuy Phong",
        wards: [
          { id: "2527", name: "B\u00ecnh Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "2528", name: "Ch\u00ed C\u00f4ng", prefix: "X\u00e3" },
          { id: "2529", name: "Ho\u00e0 Minh", prefix: "X\u00e3" },
          { id: "2530", name: "Ho\u00e0 Ph\u00fa", prefix: "X\u00e3" },
          {
            id: "2531",
            name: "Li\u00ean H\u01b0\u01a1ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2532", name: "Phan D\u0169ng", prefix: "X\u00e3" },
          {
            id: "2533",
            name: "Phan R\u00ed C\u1eeda",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2534", name: "Phong Ph\u00fa", prefix: "X\u00e3" },
          { id: "2535", name: "Ph\u00fa L\u1ea1c", prefix: "X\u00e3" },
          { id: "2536", name: "Ph\u01b0\u1edbc Th\u1ec3", prefix: "X\u00e3" },
          { id: "2537", name: "V\u0129nh H\u1ea3o", prefix: "X\u00e3" },
          { id: "2538", name: "V\u0129nh T\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "20780", name: "17/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20781", name: "716", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20782",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20783",
            name: "Giang H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20784",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20785",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20786",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20787",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20788",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20789",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20790",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20791",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20792",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20793",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "14",
    code: "LDD",
    name: "L\u00e2m \u0110\u1ed3ng",
    districts: [
      {
        id: "181",
        name: "B\u1ea3o L\u00e2m",
        wards: [
          { id: "2539", name: "B'L\u00e1", prefix: "X\u00e3" },
          { id: "2540", name: "L\u1ed9c An", prefix: "X\u00e3" },
          { id: "2541", name: "L\u1ed9c B\u1eafc", prefix: "X\u00e3" },
          { id: "2542", name: "L\u1ed9c B\u1ea3o", prefix: "X\u00e3" },
          { id: "2543", name: "L\u1ed9c \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "2544", name: "L\u1ed9c L\u00e2m", prefix: "X\u00e3" },
          { id: "2545", name: "L\u1ed9c Nam", prefix: "X\u00e3" },
          { id: "2546", name: "L\u1ed9c Ng\u00e3i", prefix: "X\u00e3" },
          { id: "2547", name: "L\u1ed9c Ph\u00fa", prefix: "X\u00e3" },
          { id: "2548", name: "L\u1ed9c Qu\u1ea3ng", prefix: "X\u00e3" },
          { id: "2549", name: "L\u1ed9c T\u00e2n", prefix: "X\u00e3" },
          {
            id: "2550",
            name: "L\u1ed9c Th\u1eafng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2551", name: "L\u1ed9c Th\u00e0nh", prefix: "X\u00e3" },
          { id: "2552", name: "T\u00e2n H\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "2553", name: "T\u00e2n L\u1ea1c", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "20794", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20795", name: "55", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20796",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20797",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20798", name: "Lam S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20799",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20800",
            name: "L\u1ed9c S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20801",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20802",
            name: "Qu\u1ed1c L\u1ed9 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20803",
            name: "Qu\u1ed1c L\u1ed9 55",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20804", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20805",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20806",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2731",
            name: "B\u2019lao Charming",
            lat: "11.526161193848",
            lng: "107.83379364014",
          },
        ],
      },
      {
        id: "182",
        name: "B\u1ea3o L\u1ed9c",
        wards: [
          { id: "2554", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "2555", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "2556", name: "B'Lao", prefix: "Ph\u01b0\u1eddng" },
          { id: "2557", name: "\u0110\u1ea1i L\u00e0o", prefix: "X\u00e3" },
          { id: "2558", name: "\u0110am Bri", prefix: "X\u00e3" },
          { id: "2559", name: "L\u1ed9c Ch\u00e2u", prefix: "X\u00e3" },
          { id: "2560", name: "L\u1ed9c Nga", prefix: "X\u00e3" },
          {
            id: "2561",
            name: "L\u1ed9c Ph\u00e1t",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2562", name: "L\u1ed9c S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "2563", name: "L\u1ed9c Thanh", prefix: "X\u00e3" },
          {
            id: "2564",
            name: "L\u1ed9c Ti\u1ebfn",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "20807", name: "1/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20808", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20809", name: "28/3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20810", name: "55", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20811",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20812",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20813",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20814",
            name: "Chi L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20815",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20816",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20817", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20826", name: "Duy T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20818",
            name: "\u0110\u1ea7m Ri",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20819",
            name: "\u0110\u1eb7ng Tr\u1ea7n C\u00f4n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20820",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20821",
            name: "\u0110\u1ec1 Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20822",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20823",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20824",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20825",
            name: "\u0110\u1ed9i C\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20827",
            name: "H\u00e0 Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20828", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "20829",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20830",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20831",
            name: "Ho\u00e0i Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20832",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20833",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20834",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20835", name: "Lam S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20836",
            name: "L\u00ea C\u00f4ng Mi\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20837",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20838", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20839",
            name: "L\u00ea Ph\u1ee5ng Hi\u1ec3u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20840",
            name: "L\u00ea Th\u1ecb Ri\u00eang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20841",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20842",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20843",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20844",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20845",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20846",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20847",
            name: "M\u1ea1c Th\u1ecb B\u01b0\u1edfi",
            prefix: "Ph\u1ed1",
          },
          { id: "20848", name: "N5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20849",
            name: "Ng\u00f4 \u0110\u1ee9c K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20850",
            name: "Ng\u00f4 T\u1ea5t T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20851",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20852",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20853",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20854",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20855",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20856",
            name: "Nguy\u1ec5n H\u1eefu Ch\u1ec9nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20857",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20858",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20859",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20860",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20861",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20862",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20863",
            name: "Nguy\u1ec5n Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20864",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20865",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20866",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20867",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20868",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20869",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20870",
            name: "Ph\u1ea1m Ph\u00fa Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20871",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20872",
            name: "Phan Ch\u00e2u Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20873",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20874",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20875",
            name: "Phan Huy Ch\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20876",
            name: "Phan Huy \u00cdch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20877",
            name: "Phan Ng\u1ecdc Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20878",
            name: "Ph\u00f3 \u0110\u1ee9c Ch\u00ednh",
            prefix: "Ph\u1ed1",
          },
          { id: "20879", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "20880",
            name: "Ph\u00f9ng Kh\u1eafc Khoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20881",
            name: "Qu\u1ea3ng Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20882",
            name: "Qu\u1ed1c L\u1ed9 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20883",
            name: "Qu\u1ed1c L\u1ed9 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20884",
            name: "Qu\u1ed1c L\u1ed9 55",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20885",
            name: "T\u1ea3n \u0110\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20886",
            name: "T\u0103ng B\u1ea1t H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20887",
            name: "T\u00e2y S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20888",
            name: "Ti\u1ebfn L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20889",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20890",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20891",
            name: "T\u1ed1 H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20892",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20893",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20894",
            name: "Tr\u1ea7n Nguy\u00ean \u0110\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20895",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20896",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20897",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20898",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20899",
            name: "Tr\u1ea7n T\u1ebf X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20900",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20901",
            name: "V\u00f5 \u0110\u00ecnh T\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20902",
            name: "V\u00f5 Trung Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20903",
            name: "V\u00f5 V\u0103n T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20904",
            name: "Y\u00ean Th\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2732",
            name: "B\u1ea3o L\u1ed9c Capital",
            lat: "11.527601242065",
            lng: "107.81988525391",
          },
          {
            id: "2733",
            name: "Beacon Pass Residential",
            lat: "11.534272193909",
            lng: "107.78456878662",
          },
          {
            id: "2734",
            name: "Nam Ph\u01b0\u01a1ng City",
            lat: "11.559517860413",
            lng: "107.83467102051",
          },
          {
            id: "2735",
            name: "Ruby City B\u1ea3o L\u1ed9c",
            lat: "11.534266471863",
            lng: "107.82086181641",
          },
        ],
      },
      {
        id: "183",
        name: "C\u00e1t Ti\u00ean",
        wards: [
          {
            id: "2565",
            name: "\u0110\u1ed3ng Nai",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "2566",
            name: "\u0110\u1ed3ng Nai Th\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
          { id: "2567", name: "\u0110\u1ee9c Ph\u1ed5", prefix: "X\u00e3" },
          { id: "2568", name: "Gia Vi\u1ec5n", prefix: "X\u00e3" },
          { id: "2569", name: "M\u1ef9 L\u00e2m", prefix: "X\u00e3" },
          { id: "2570", name: "Nam Ninh", prefix: "X\u00e3" },
          { id: "2571", name: "Ph\u00f9 M\u1ef9", prefix: "X\u00e3" },
          { id: "2572", name: "Ph\u01b0\u1edbc C\u00e1t 1", prefix: "X\u00e3" },
          { id: "2573", name: "Ph\u01b0\u1edbc C\u00e1t 2", prefix: "X\u00e3" },
          { id: "2574", name: "Qu\u1ea3ng Ng\u00e3i", prefix: "X\u00e3" },
          { id: "2575", name: "Ti\u00ean Ho\u00e0ng", prefix: "X\u00e3" },
          { id: "2576", name: "T\u01b0 Ngh\u0129a", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "20905",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20906",
            name: "T\u1ec9nh l\u1ed9 721",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "188",
        name: "Di Linh",
        wards: [
          { id: "2622", name: "B\u1ea3o Thu\u1eadn", prefix: "X\u00e3" },
          { id: "2623", name: "Di Linh", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "2624", name: "\u0110inh L\u1ea1c", prefix: "X\u00e3" },
          { id: "2625", name: "\u0110inh Trang Ho\u00e0", prefix: "X\u00e3" },
          {
            id: "2626",
            name: "\u0110inh Trang Th\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
          { id: "2627", name: "Gia B\u1eafc", prefix: "X\u00e3" },
          { id: "2628", name: "Gia Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "2629", name: "Gung R\u00e9", prefix: "X\u00e3" },
          { id: "2630", name: "Ho\u00e0 B\u1eafc", prefix: "X\u00e3" },
          { id: "2631", name: "Ho\u00e0 Nam", prefix: "X\u00e3" },
          { id: "2632", name: "Ho\u00e0 Ninh", prefix: "X\u00e3" },
          { id: "2633", name: "Ho\u00e0 Trung", prefix: "X\u00e3" },
          { id: "2634", name: "Li\u00ean \u0110\u1ea7m", prefix: "X\u00e3" },
          { id: "2635", name: "S\u01a1n \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "2636", name: "Tam B\u1ed1", prefix: "X\u00e3" },
          { id: "2637", name: "T\u00e2n Ch\u00e2u", prefix: "X\u00e3" },
          { id: "2638", name: "T\u00e2n L\u00e2m", prefix: "X\u00e3" },
          { id: "2639", name: "T\u00e2n Ngh\u0129a", prefix: "X\u00e3" },
          { id: "2640", name: "T\u00e2n Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "21137", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21138", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21139",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21140",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21141",
            name: "\u0110\u1ed3ng L\u1ea1c 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21142",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21143",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21144",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21145",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21146",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21147",
            name: "L\u00ea V\u0103n T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21148",
            name: "M\u1ecd C\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21149",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21150",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21151",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21152",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21153",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21154",
            name: "Qu\u1ed1c L\u1ed9 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21155",
            name: "T\u00e2n L\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21156",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21157",
            name: "Tr\u1ecbnh Ho\u00e0i \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "184",
        name: "\u0110\u1ea1 Huoai",
        wards: [
          {
            id: "2577",
            name: "\u0110\u1ea1 M'Ri",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2578", name: "\u0110\u1ea1 M'Ri", prefix: "X\u00e3" },
          { id: "2579", name: "\u0110\u1ea1 Oai", prefix: "X\u00e3" },
          { id: "2580", name: "\u0110\u1ea1 Ploa", prefix: "X\u00e3" },
          { id: "2581", name: "\u0110\u1ea1 T\u1ed3n", prefix: "X\u00e3" },
          { id: "2582", name: "\u0110o\u00e0n K\u1ebft", prefix: "X\u00e3" },
          { id: "2583", name: "H\u00e0 L\u00e2m", prefix: "X\u00e3" },
          {
            id: "2584",
            name: "Ma \u0110a Gu\u00f4i",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2585", name: "Ma \u0110a Gu\u00f4i", prefix: "X\u00e3" },
          { id: "2586", name: "Ph\u01b0\u1edbc L\u1ed9c", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "20907", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20908",
            name: "Qu\u1ed1c L\u1ed9 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20909",
            name: "Qu\u1ed1c L\u1ed9 50",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20910",
            name: "T\u1ec9nh l\u1ed9 721",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "185",
        name: "\u0110\u00e0 L\u1ea1t",
        wards: [
          { id: "2587", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "2588", name: "10", prefix: "Ph\u01b0\u1eddng" },
          { id: "2589", name: "11", prefix: "Ph\u01b0\u1eddng" },
          { id: "2590", name: "12", prefix: "Ph\u01b0\u1eddng" },
          { id: "2591", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "2592", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "2593", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "2594", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "2595", name: "6", prefix: "Ph\u01b0\u1eddng" },
          { id: "2596", name: "7", prefix: "Ph\u01b0\u1eddng" },
          { id: "2597", name: "8", prefix: "Ph\u01b0\u1eddng" },
          { id: "2598", name: "9", prefix: "Ph\u01b0\u1eddng" },
          { id: "2599", name: "T\u00e0 Nung", prefix: "X\u00e3" },
          { id: "2600", name: "Tr\u1ea1m H\u00e0nh", prefix: "X\u00e3" },
          { id: "2601", name: "Xu\u00e2n Th\u1ecd", prefix: "X\u00e3" },
          { id: "2602", name: "Xu\u00e2n Tr\u01b0\u1eddng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "20911", name: "2/3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20912", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20913", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20914", name: "3/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20915", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20916", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20917", name: "66", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20918", name: "An B\u00ecnh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20919",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20920",
            name: "An Ph\u00fa \u0110\u00f4ng 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20921", name: "An S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20922", name: "An T\u00f4n", prefix: "\u0110\u01b0\u1eddng" },
          { id: "20923", name: "Ankroet", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20924",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          { id: "20925", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "20926",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20927",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20928",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20929",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20930",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20931",
            name: "Chi L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20932",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20933",
            name: "C\u00f4 B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20934",
            name: "C\u00f4 Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20935", name: "C\u1ed5 Loa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20936",
            name: "C\u00f4ng Ch\u00faa Ng\u1ecdc H\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20937",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20938",
            name: "C\u00fac Qu\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20942",
            name: "D\u00e3 T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20939", name: "\u0110a Minh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20940",
            name: "\u0110a Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20941",
            name: "\u0110a Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20943",
            name: "\u0110\u1eb7ng Th\u00e1i Th\u00e2n",
            prefix: "Ph\u1ed1",
          },
          { id: "20944", name: "\u0110ankia", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20945",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20946",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20947",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20948",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20949",
            name: "\u0110\u1ed3i C\u00f4ng \u0110o\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20950",
            name: "\u0110\u1ed3i Huy Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20951",
            name: "\u0110\u1ed3i Ph\u1eadt B\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20952",
            name: "\u0110\u1ed3i Th\u00f4ng Tin",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20953",
            name: "\u0110\u1ed1ng \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20954",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20955",
            name: "\u0110\u1ed3ng T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20956",
            name: "\u0110\u00f4ng T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20957", name: "Gio An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20958",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20959", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "20960",
            name: "H\u1ea3i Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20961",
            name: "H\u00e0n Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20962",
            name: "H\u00e0n Thuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20963",
            name: "H\u1eadu L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20964",
            name: "Hi\u1ec7p Th\u00e0nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20965",
            name: "H\u1ed3 Than Th\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20966",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20967",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20968",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20969",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20970",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20971",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20972",
            name: "Huy\u1ec1n Tr\u00e2n C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20973",
            name: "Hu\u1ef3nh T\u1ea5n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20974",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20975", name: "Khe Sanh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20976",
            name: "Kh\u1edfi Ngh\u0129a B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20977",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20978",
            name: "La S\u01a1n Phu T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20979",
            name: "L\u00e2m V\u0103n Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20980",
            name: "L\u00e3nh \u0110\u1ecba \u0110\u1ee9c B\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20981",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20982",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20983", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20984",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20985",
            name: "L\u00ea Th\u00e1nh T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20986",
            name: "L\u00ea Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20987",
            name: "L\u00ea V\u0103n Ho\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20988",
            name: "L\u00ea V\u0103n T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20989",
            name: "L\u00ea V\u0103n T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20990",
            name: "L\u1ed9 \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20991",
            name: "L\u1ed9c H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "20992", name: "L\u1eef Gia", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "20993",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20994",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20995",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20996",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "20997",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20998",
            name: "Ma Trang S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "20999",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21000",
            name: "Mai Anh \u0110\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21001",
            name: "Mai H\u1eafc \u0110\u1ebf",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21002",
            name: "Mai Hoa Th\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21003",
            name: "Mai Xu\u00e2n Th\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21004",
            name: "M\u0103ng Lin",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21005",
            name: "M\u0103ng Ling",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21006",
            name: "M\u1eabu T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21007", name: "M\u00ea Linh", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21008", name: "Mimosa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21009",
            name: "M\u01b0\u01a1ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21010", name: "Nam H\u1ed3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21011",
            name: "Nam K\u1ef3 Kh\u1edfi Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21012",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21013",
            name: "Ng\u00f4 Huy Di\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21014",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21015",
            name: "Ng\u00f4 T\u1ea5t T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21016",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21017",
            name: "Ng\u00f4 Th\u00ec S\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21018",
            name: "Ng\u00f4 V\u0103n S\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21019",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21020",
            name: "Nguy\u1ec5n Bi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21021",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21022",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21025",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21023",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21024",
            name: "Nguy\u1ec5n \u0110\u00ecnh Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21026",
            name: "Nguy\u1ec5n Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21027",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21028",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21029",
            name: "Nguy\u1ec5n Huy Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21030",
            name: "Nguy\u1ec5n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21031",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21032",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21033",
            name: "Nguy\u00ean Phi \u1ef6 Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21034", name: "Nguy\u1ec5n Si\u00eau", prefix: "Ph\u1ed1" },
          {
            id: "21035",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21036",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21037",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21038",
            name: "Nguy\u1ec5n Th\u1ecb Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21039",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21040",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21041",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21042",
            name: "Nguy\u00ean T\u1eed L\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21043",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21044",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21045",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21046", name: "Nh\u00e0 Chung", prefix: "Ph\u1ed1" },
          {
            id: "21047",
            name: "N\u00fai M\u1ed9t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21048", name: "Pasteur", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21049",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21050",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21051",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21052",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21053",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21054",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21055",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21056",
            name: "Phan Nh\u01b0 Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21057",
            name: "Ph\u00ea Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21058",
            name: "Ph\u00f3 \u0110\u1ee9c Ch\u00ednh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21059",
            name: "Ph\u00f9 \u0110\u1ed5ng Thi\u00ean V\u01b0\u01a1ng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21060",
            name: "Ph\u00fa Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21061",
            name: "Ph\u01b0\u1edbc Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21062", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21063",
            name: "S\u01b0 V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21064",
            name: "S\u01b0\u01a1ng Nguy\u1ec7t \u00c1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21065", name: "T21", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21066",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21067",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21068",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21069",
            name: "T\u0103ng B\u1ea1t H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21070",
            name: "Th\u00e1i L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21071",
            name: "Th\u00e1i Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21072",
            name: "Th\u1ea1nh L\u1ed9c 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21073",
            name: "Th\u00e1nh M\u1eabu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21074",
            name: "Thanh T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21075",
            name: "Th\u1ea1nh Xu\u00e2n 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21076",
            name: "Th\u1ea1nh Xu\u00e2n 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21077", name: "Thi S\u00e1ch", prefix: "Ph\u1ed1" },
          {
            id: "21078",
            name: "Th\u00edch B\u1eedu \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21079",
            name: "Thi\u1ec7n M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21080",
            name: "Thi\u00ean Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21081",
            name: "Thi\u1ec7n \u00dd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21082",
            name: "Th\u00f4ng Thi\u00ean H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21083",
            name: "Th\u1ee7 Khoa Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21084",
            name: "T\u1ec9nh l\u1ed9 722",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21085",
            name: "T\u1ec9nh l\u1ed9 723",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21086",
            name: "T\u1ec9nh l\u1ed9 725",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21087",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21088",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21089",
            name: "T\u00f4 Ng\u1ecdc V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21090",
            name: "T\u00f4 V\u0129nh Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21091",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21092",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21093",
            name: "Tr\u1ea1i M\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21094",
            name: "Tr\u1ea7n Anh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21095",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21096",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21097",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21098",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21099",
            name: "Tr\u1ea7n L\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21100",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21101",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21102",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21104",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21103",
            name: "Tr\u1ea7n Quang \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21105",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21106",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21107",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21108",
            name: "Tr\u1ea7n Th\u00e1i T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21109",
            name: "Tr\u1ea7n V\u0103n C\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21110",
            name: "Tr\u1ea1ng Tr\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21111",
            name: "Tr\u00edch S\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21112",
            name: "Tri\u1ec7u Vi\u1ec7t V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21113",
            name: "Tr\u1ecbnh Ho\u00e0i \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21114",
            name: "Tr\u01b0\u01a1ng C\u00f4ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21115",
            name: "Tr\u01b0\u01a1ng V\u0103n \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21116",
            name: "Tr\u01b0\u01a1ng V\u0103n Ho\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21117",
            name: "T\u1ef1 Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21118",
            name: "T\u00f9ng L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21119",
            name: "T\u01b0\u01a1ng Ph\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21120",
            name: "V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21121", name: "V\u1ea1n Ki\u1ebfp", prefix: "Ph\u1ed1" },
          {
            id: "21122",
            name: "V\u1ea1n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21123",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21124",
            name: "V\u00f5 Tr\u01b0\u1eddng To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21125",
            name: "V\u00f2ng L\u00e2m Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21126", name: "Xa La", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21127",
            name: "X\u00f4 Vi\u1ebft Ngh\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21128", name: "Xu\u00e2n An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21129",
            name: "Xu\u00e2n Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21130", name: "Y Dinh", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21131", name: "Yagout", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21132",
            name: "Y\u00ean Th\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21133", name: "Yersin", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21134",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2736",
            name: "Bavico Plaza Hotel \u0110\u00e0 L\u1ea1t",
            lat: "11.941957473755",
            lng: "108.43799591064",
          },
          {
            id: "2737",
            name: "C\u00f4ng vi\u00ean V\u0103n h\u00f3a \u0110\u00e0 L\u1ea1t",
            lat: "11.943179130554",
            lng: "108.44133758545",
          },
          {
            id: "2738",
            name: "\u0110\u00e0 L\u1ea1t Green",
            lat: "11.903877258301",
            lng: "108.43697357178",
          },
          {
            id: "2739",
            name: "\u0110\u00e0 L\u1ea1t Star Hill",
            lat: "11.941210746765",
            lng: "108.46051025391",
          },
          {
            id: "2740",
            name: "Fusion Maia \u0110\u00e0 L\u1ea1t",
            lat: "11.940419197083",
            lng: "108.45831298828",
          },
          {
            id: "2741",
            name: "Galaticos Living",
            lat: "11.885325431824",
            lng: "108.4275894165",
          },
          {
            id: "2742",
            name: "Golf Valley \u0110\u00e0 L\u1ea1t",
            lat: "11.945477485657",
            lng: "108.44163513184",
          },
          {
            id: "2743",
            name: "Ho\u00e0ng \u0110\u00ecnh Villa",
            lat: "11.940650939941",
            lng: "108.49535369873",
          },
          {
            id: "2744",
            name: "H\u01b0ng Th\u1ecbnh",
            lat: "11.951766014099",
            lng: "108.45587158203",
          },
          {
            id: "2745",
            name: "Mandaville Dalat",
            lat: "11.891489028931",
            lng: "108.44188690186",
          },
          {
            id: "2746",
            name: "Sacom Tuyen Lam",
            lat: "11.895761489868",
            lng: "108.44052124023",
          },
          {
            id: "2747",
            name: "The Panorama \u0110\u00e0 L\u1ea1t",
            lat: "11.939325332642",
            lng: "108.45622253418",
          },
          {
            id: "2748",
            name: "Vinpearl \u0110\u00e0 L\u1ea1t",
            lat: "11.94612121582",
            lng: "108.46178436279",
          },
        ],
      },
      {
        id: "186",
        name: "\u0110\u1ea1 T\u1ebbh",
        wards: [
          { id: "2603", name: "An Nh\u01a1n", prefix: "X\u00e3" },
          { id: "2604", name: "\u0110\u1ea1 Kho", prefix: "X\u00e3" },
          { id: "2605", name: "\u0110\u1ea1 L\u00e2y", prefix: "X\u00e3" },
          { id: "2606", name: "\u0110\u1ea1 Pal", prefix: "X\u00e3" },
          {
            id: "2607",
            name: "\u0110\u1ea1 T\u1ebbh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2608", name: "H\u00e0 \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "2609", name: "H\u01b0\u01a1ng L\u00e2m", prefix: "X\u00e3" },
          { id: "2610", name: "M\u1ef9 \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "2611", name: "Qu\u1ea3ng Tr\u1ecb", prefix: "X\u00e3" },
          { id: "2612", name: "Qu\u1ed1c Oai", prefix: "X\u00e3" },
          { id: "2613", name: "Tri\u1ec7u H\u1ea3i", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "187",
        name: "\u0110am R\u00f4ng",
        wards: [
          {
            id: "2614",
            name: "\u0110\u1ea1 K\u2019N\u00e0ng",
            prefix: "X\u00e3",
          },
          { id: "2615", name: "\u0110\u1ea1 Long", prefix: "X\u00e3" },
          { id: "2616", name: "\u0110\u1ea1 M'R\u00f4ng", prefix: "X\u00e3" },
          { id: "2617", name: "\u0110\u1ea1 Rsal", prefix: "X\u00e3" },
          { id: "2618", name: "\u0110\u1ea1 T\u00f4ng", prefix: "X\u00e3" },
          { id: "2619", name: "Li\u00eang S\u2019Roin", prefix: "X\u00e3" },
          { id: "2620", name: "Phi Li\u00eang", prefix: "X\u00e3" },
          { id: "2621", name: "R\u00f4 Men", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "21135", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21136",
            name: "Qu\u1ed1c l\u1ed9 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "189",
        name: "\u0110\u01a1n D\u01b0\u01a1ng",
        wards: [
          { id: "2642", name: "D'ran", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "2641", name: "\u0110\u1ea1 R\u00f2n", prefix: "X\u00e3" },
          { id: "2643", name: "Ka \u0110\u00f4", prefix: "X\u00e3" },
          { id: "2644", name: "Ka \u0110\u01a1n", prefix: "X\u00e3" },
          { id: "2645", name: "L\u1ea1c L\u00e2m", prefix: "X\u00e3" },
          { id: "2646", name: "L\u1ea1c Xu\u00e2n", prefix: "X\u00e3" },
          { id: "2647", name: "Pr\u00f3", prefix: "X\u00e3" },
          { id: "2648", name: "Qu\u1ea3ng L\u1eadp", prefix: "X\u00e3" },
          {
            id: "2649",
            name: "Th\u1ea1nh M\u1ef9",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2650", name: "Tu Tra", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "21158", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21159", name: "413", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21160",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21161",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21162",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21163",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21164",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21165",
            name: "Qu\u1ed1c l\u1ed9 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "190",
        name: "\u0110\u1ee9c Tr\u1ecdng",
        wards: [
          { id: "2651", name: "B\u00ecnh Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "2652", name: "\u0110\u00e0 Loan", prefix: "X\u00e3" },
          { id: "2653", name: "\u0110a Quyn", prefix: "X\u00e3" },
          { id: "2654", name: "Hi\u1ec7p An", prefix: "X\u00e3" },
          { id: "2655", name: "Hi\u1ec7p Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "2656", name: "Li\u00ean Hi\u1ec7p", prefix: "X\u00e3" },
          {
            id: "2657",
            name: "Li\u00ean Ngh\u0129a",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2660", name: "N'Thol H\u1ea1", prefix: "X\u00e3" },
          { id: "2658", name: "Ninh Gia", prefix: "X\u00e3" },
          { id: "2659", name: "Ninh Loan", prefix: "X\u00e3" },
          { id: "2661", name: "Ph\u00fa H\u1ed9i", prefix: "X\u00e3" },
          { id: "2662", name: "T\u00e0 Hine", prefix: "X\u00e3" },
          { id: "2663", name: "T\u00e0 N\u0103ng", prefix: "X\u00e3" },
          { id: "2664", name: "T\u00e2n H\u1ed9i", prefix: "X\u00e3" },
          { id: "2665", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "21166", name: "2/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21167", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21168", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21169",
            name: "B\u1ed3ng Lai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21170",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21171",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21172",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21173",
            name: "C\u00f4 B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21174", name: "Cospha", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21175",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21176",
            name: "D\u00e3 T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21177",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21178",
            name: "H\u00e0 Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21179",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21180", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "21181",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21182",
            name: "H\u00e0ng Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21183",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21184",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21185",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21186",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21187",
            name: "Huy\u1ec1n Tr\u00e2n C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21188",
            name: "Kh\u00fac Th\u1eeba D\u1ee5",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21189",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21190",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21191",
            name: "L\u00ea Anh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21192",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21193",
            name: "L\u00ea \u0110\u00ecnh Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21194",
            name: "L\u00ea \u0110\u1ee9c Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21195",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21196", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21197",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21198",
            name: "L\u00ea Th\u00e1nh T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21199",
            name: "L\u00ea Th\u1ecb H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21200",
            name: "L\u00ea Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21201",
            name: "L\u00ea Th\u1ecb Pha",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21202",
            name: "Li\u00ean Kh\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21203",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21204",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21205",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21206",
            name: "Nguy\u1ec5n B\u00e1 Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21207",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21208",
            name: "Nguy\u1ec5n C\u00f4ng Hoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21209",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21211",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21210",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21212",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21213",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21214",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21215",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21216",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21217",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21218",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21219",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21220",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21221",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21222",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21223",
            name: "Nguy\u1ec5n V\u0103n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21224", name: "Nguy\u1ec5n X\u00ed", prefix: "Ph\u1ed1" },
          {
            id: "21225",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21226",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21227",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21228",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21229",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21230",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21231", name: "Phi N\u00f4m", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21232",
            name: "Ph\u00f9 \u0110\u1ed5ng Thi\u00ean V\u01b0\u01a1ng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21233",
            name: "Qu\u1ed1c L\u1ed9 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21234",
            name: "Qu\u1ed1c l\u1ed9 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21235",
            name: "S\u01b0 V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21236",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21237",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21238",
            name: "T\u00f4 V\u0129nh Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21239",
            name: "T\u00f4n Th\u1ea5t Thuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21240",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21241",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21242",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21243",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21244",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21245",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21246",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21247",
            name: "V\u00f5 Ch\u00ed C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21248",
            name: "Xu\u00e2n Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21249",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "191",
        name: "L\u1ea1c D\u01b0\u01a1ng",
        wards: [
          { id: "2666", name: "\u0110\u1ea1 Chais", prefix: "X\u00e3" },
          { id: "2667", name: "\u0110\u1ea1 Nhim", prefix: "X\u00e3" },
          { id: "2668", name: "\u0110\u1ea1 Sar", prefix: "X\u00e3" },
          { id: "2669", name: "\u0110\u01b0ng KN\u1edb", prefix: "X\u00e3" },
          {
            id: "2670",
            name: "L\u1ea1c D\u01b0\u01a1ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2671", name: "L\u00e1t", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "21250", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21251", name: "723", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21252", name: "Bi-Doup", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21253", name: "\u0110am San", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21254",
            name: "\u0110\u0103ng Gia",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21255",
            name: "\u0110\u0103ng Gia R\u00edt B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21256",
            name: "Qu\u1ed1c L\u1ed9 723",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21257",
            name: "T\u1ec9nh l\u1ed9 723",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21258", name: "V\u0103n Cao", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
      {
        id: "192",
        name: "L\u00e2m H\u00e0",
        wards: [
          { id: "2672", name: "\u0110\u1ea1 \u0110\u1eddn", prefix: "X\u00e3" },
          { id: "2673", name: "\u0110an Ph\u01b0\u1ee3ng", prefix: "X\u00e3" },
          {
            id: "2674",
            name: "\u0110inh V\u0103n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2675", name: "\u0110\u00f4ng Thanh", prefix: "X\u00e3" },
          { id: "2676", name: "Gia L\u00e2m", prefix: "X\u00e3" },
          { id: "2677", name: "Ho\u00e0i \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "2678", name: "Li\u00ean H\u00e0", prefix: "X\u00e3" },
          { id: "2679", name: "M\u00ea Linh", prefix: "X\u00e3" },
          { id: "2680", name: "Nam Ban", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "2681", name: "Nam H\u00e0", prefix: "X\u00e3" },
          { id: "2682", name: "Phi T\u00f4", prefix: "X\u00e3" },
          { id: "2683", name: "Ph\u00fa S\u01a1n", prefix: "X\u00e3" },
          { id: "2684", name: "Ph\u00fac Th\u1ecd", prefix: "X\u00e3" },
          { id: "2685", name: "T\u00e2n H\u00e0", prefix: "X\u00e3" },
          { id: "2686", name: "T\u00e2n Thanh", prefix: "X\u00e3" },
          { id: "2687", name: "T\u00e2n V\u0103n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "21259", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21260", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21261",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21262",
            name: "Gia Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21263",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21264",
            name: "Qu\u1ed1c l\u1ed9 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21265",
            name: "T\u1ec9nh l\u1ed9 725",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "15",
    code: "TTH",
    name: "Th\u1eeba Thi\u00ean Hu\u1ebf",
    districts: [
      {
        id: "193",
        name: "A L\u01b0\u1edbi",
        wards: [
          { id: "2688", name: "A \u0110\u1edbt", prefix: "X\u00e3" },
          {
            id: "2689",
            name: "A L\u01b0\u1edbi",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2690", name: "A Ngo", prefix: "X\u00e3" },
          { id: "2691", name: "A Ro\u1eb1ng", prefix: "X\u00e3" },
          { id: "2692", name: "B\u1eafc S\u01a1n", prefix: "X\u00e3" },
          { id: "2693", name: "\u0110\u00f4ng S\u01a1n", prefix: "X\u00e3" },
          { id: "2694", name: "H\u1ed3ng B\u1eafc", prefix: "X\u00e3" },
          { id: "2695", name: "H\u1ed3ng H\u1ea1", prefix: "X\u00e3" },
          { id: "2696", name: "H\u1ed3ng Kim", prefix: "X\u00e3" },
          { id: "2697", name: "H\u1ed3ng Nam", prefix: "X\u00e3" },
          { id: "2698", name: "H\u1ed3ng Qu\u1ea3ng", prefix: "X\u00e3" },
          { id: "2699", name: "H\u1ed3ng Th\u00e1i", prefix: "X\u00e3" },
          { id: "2700", name: "H\u1ed3ng Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "2701", name: "H\u1ed3ng Th\u1ee7y", prefix: "X\u00e3" },
          { id: "2702", name: "H\u1ed3ng Trung", prefix: "X\u00e3" },
          { id: "2703", name: "H\u1ed3ng V\u00e2n", prefix: "X\u00e3" },
          { id: "2704", name: "H\u01b0\u01a1ng L\u00e2m", prefix: "X\u00e3" },
          {
            id: "2705",
            name: "H\u01b0\u01a1ng Nguy\u00ean",
            prefix: "X\u00e3",
          },
          { id: "2706", name: "H\u01b0\u01a1ng Phong", prefix: "X\u00e3" },
          { id: "2707", name: "Nh\u00e2m", prefix: "X\u00e3" },
          { id: "2708", name: "Ph\u00fa Vinh", prefix: "X\u00e3" },
          { id: "2709", name: "S\u01a1n Th\u1ee7y", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "21266", name: "A Biah", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21267", name: "A \u0110on", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21268", name: "A Ko", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21269", name: "A N\u00f4r", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21270", name: "A S\u00e1p", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21271", name: "A V\u1ea7u", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21272",
            name: "\u0102m M\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21273",
            name: "B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21274",
            name: "\u0110inh N\u00fap",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21275",
            name: "\u0110\u1ed9i C\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21276",
            name: "\u0110\u1ed9ng So",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21277",
            name: "Gi\u1ea3i ph\u00f3ng A So",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21278",
            name: "H\u1ed3 Ch\u00ed Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21279",
            name: "H\u1ed3 Hu\u1ea5n Nghi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21280",
            name: "H\u1ed3 V\u0103n H\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21281",
            name: "K\u0103n Tr\u00e9ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21282",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21283", name: "Konh H\u01b0", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21284", name: "Konh Khoai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21285",
            name: "L\u00ea Kh\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21286",
            name: "Nguy\u1ec5n Th\u1ee9c T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21287",
            name: "Nguy\u1ec5n V\u0103n Ho\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21288",
            name: "Nguy\u1ec5n V\u0103n Qu\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21289",
            name: "N\u01a1 Trang Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21290",
            name: "Qu\u1ef3nh Tr\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21291",
            name: "Tr\u01b0\u1eddng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21292",
            name: "V\u1ed7 \u00c1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21293",
            name: "V\u00f5 B\u1ea9m",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "194",
        name: "Hu\u1ebf",
        wards: [
          { id: "2710", name: "An C\u1ef1u", prefix: "Ph\u01b0\u1eddng" },
          { id: "2711", name: "An \u0110\u00f4ng", prefix: "Ph\u01b0\u1eddng" },
          { id: "2712", name: "An H\u00f2a", prefix: "Ph\u01b0\u1eddng" },
          { id: "2713", name: "An T\u00e2y", prefix: "Ph\u01b0\u1eddng" },
          { id: "2714", name: "\u0110\u00fac", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2715",
            name: "H\u01b0\u01a1ng Long",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2716",
            name: "H\u01b0\u01a1ng S\u01a1",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2717", name: "Kim Long", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2718",
            name: "Ph\u00fa B\u00ecnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2719", name: "Ph\u00fa C\u00e1t", prefix: "Ph\u01b0\u1eddng" },
          { id: "2720", name: "Ph\u00fa H\u1eadu", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2721",
            name: "Ph\u00fa Hi\u1ec7p",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2722", name: "Ph\u00fa H\u00f2a", prefix: "Ph\u01b0\u1eddng" },
          { id: "2723", name: "Ph\u00fa H\u1ed9i", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2724",
            name: "Ph\u00fa Nhu\u1eadn",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2725",
            name: "Ph\u00fa Thu\u1eadn",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2726",
            name: "Ph\u01b0\u1edbc V\u0129nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2727",
            name: "Ph\u01b0\u1eddng \u0110\u00fac",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2728", name: "T\u00e2y L\u1ed9c", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2729",
            name: "Thu\u1eadn H\u00f2a",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2730",
            name: "Thu\u1eadn L\u1ed9c",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2731",
            name: "Thu\u1eadn Th\u00e0nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2732", name: "Th\u1ee7y An", prefix: "X\u00e3" },
          {
            id: "2733",
            name: "Th\u1ee7y Bi\u1ec1u",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2734",
            name: "Th\u1ee7y Xu\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2735",
            name: "Tr\u01b0\u1eddng An",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2736", name: "V\u0129 D\u1ea1", prefix: "Ph\u01b0\u1eddng" },
          { id: "2737", name: "V\u0129nh Ninh", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2738",
            name: "Xu\u00e2n Ph\u00fa",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "21294", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21295", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21296", name: "23/8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21297", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21298", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21299", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21300",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21301", name: "An Ninh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21302",
            name: "An V\u00e2n D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21303",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21304", name: "\u1ea4u Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "21305",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21306",
            name: "B\u00e0 Nguy\u1ec5n \u0110\u00ecnh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21307", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "21308",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21309",
            name: "B\u1ea3o Qu\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21310",
            name: "B\u1ebfn Ngh\u00e9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21311",
            name: "B\u00f9i D\u01b0\u01a1ng L\u1ecbch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21312",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21313",
            name: "B\u00f9i Xu\u00e2n Ph\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21314",
            name: "B\u1eedu \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21315",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21316",
            name: "Cao \u0110\u00ecnh \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21317",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21318",
            name: "Cao V\u0103n Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21319",
            name: "Cao Xu\u00e2n D\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21320",
            name: "Cao Xu\u00e2n Huy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21321",
            name: "Ch\u1ebf Lan Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21322",
            name: "Chi L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21323",
            name: "Chi\u1ebft Bi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21324",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21325",
            name: "Ch\u00f9a \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21326",
            name: "Ch\u01b0\u01a1ng D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21327",
            name: "C\u1eeda Ng\u0103n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21328",
            name: "C\u1eeda Qu\u1ea3ng \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21329",
            name: "D\u00e3 T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21347",
            name: "Di\u1ec7u \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21357",
            name: "D\u1ee5c Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21358",
            name: "D\u01b0\u01a1ng H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21359",
            name: "D\u01b0\u01a1ng Thi\u1ec7u T\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21360",
            name: "D\u01b0\u01a1ng V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21361",
            name: "D\u01b0\u01a1ng Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21362", name: "Duy T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21330",
            name: "\u0110\u1ea7m An L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21331",
            name: "\u0110\u1ea1m Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21333", name: "\u0110\u1eb7ng Dung", prefix: "Ph\u1ed1" },
          {
            id: "21332",
            name: "\u0110\u1eb7ng \u0110\u1ee9c Tu\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21334",
            name: "\u0110\u1eb7ng Huy Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21335",
            name: "\u0110\u1eb7ng Nguy\u00ean C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21336", name: "\u0110\u1eb7ng T\u1ea5t", prefix: "Ph\u1ed1" },
          {
            id: "21337",
            name: "\u0110\u1eb7ng Thai Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21338",
            name: "\u0110\u1eb7ng Th\u00e1i Th\u00e2n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21339",
            name: "\u0110\u1eb7ng Th\u00f9y Tr\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21340",
            name: "\u0110\u1eb7ng Tr\u1ea7n C\u00f4n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21341",
            name: "\u0110\u1eb7ng V\u0103n Ng\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21342",
            name: "\u0110\u00e0o Duy Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21343",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21344",
            name: "\u0110\u00e0o T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21345",
            name: "\u0110\u00e0o Trinh Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21346",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21348",
            name: "\u0110inh B\u1ed9 L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21349",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21350",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21351",
            name: "\u0110o\u00e0n H\u1eefu Tr\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21352",
            name: "\u0110o\u00e0n Nguy\u1ec5n Tu\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21353",
            name: "\u0110o\u00e0n Nh\u1eef H\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21354",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21355",
            name: "\u0110\u1ed9i Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21356",
            name: "\u0110\u1ed1ng \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21363",
            name: "H\u00e0 Huy Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21364",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21365",
            name: "H\u00e0 Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21366",
            name: "H\u00e0 N\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21367", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "21368",
            name: "H\u1ea3i Tri\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21369",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21370",
            name: "H\u00e0n M\u1eb7c T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21371",
            name: "H\u00e0n Thuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21372",
            name: "H\u1ed3 \u0110\u1eafc Di",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21373",
            name: "H\u1ed3 Th\u1eeba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21374",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21375",
            name: "H\u1ed3 V\u0103n Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21376",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21377",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21378", name: "Hoa L\u01b0", prefix: "Ph\u1ed1" },
          {
            id: "21379",
            name: "H\u00f2a M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21380",
            name: "Ho\u00e0i Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21381",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21382",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21383",
            name: "Ho\u00e0ng Lanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21384",
            name: "Ho\u00e0ng Minh Gi\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21385",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21386",
            name: "Ho\u00e0ng Th\u1ecb Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21387",
            name: "Ho\u00e0ng Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21388",
            name: "Ho\u00e0ng V\u0103n L\u1ecbch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21389",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21390",
            name: "Ho\u00e0ng Xu\u00e2n H\u00e3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21391",
            name: "H\u1ed3ng Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21392",
            name: "H\u1ed3ng Thi\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21393",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21394",
            name: "Huy\u1ec1n Tr\u00e2n C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21395",
            name: "Hu\u1ef3nh T\u1ea5n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21396",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21397",
            name: "K\u1ebb Tr\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21398",
            name: "Kh\u1ea3i \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21399",
            name: "Ki\u1ec7t Mi\u1ebfu \u0110\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21400",
            name: "Kim Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21401", name: "Kim Long", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21402",
            name: "Kinh D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21403",
            name: "La S\u01a1n Phu T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21404",
            name: "L\u1ea1i Th\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21405",
            name: "L\u00e2m Ho\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21406",
            name: "L\u00e2m M\u1ed9ng Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21407",
            name: "L\u00ea C\u00f4ng H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21411",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21408",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21409",
            name: "L\u00ea \u0110\u00ecnh Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21410",
            name: "L\u00ea \u0110\u00ecnh Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21412",
            name: "L\u00ea Ho\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21413",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21414",
            name: "L\u00ea H\u1ed3ng S\u01a1n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21415",
            name: "L\u00ea Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21416",
            name: "L\u00ea H\u1eefu Tr\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21417",
            name: "L\u00ea Kh\u1eafc Quy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21418", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21419",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21420", name: "L\u00ea Minh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21421",
            name: "L\u00ea Minh C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21422",
            name: "L\u00ea Ng\u00f4 C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21423",
            name: "L\u00ea Ng\u1ecdc H\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21424",
            name: "L\u00ea Quang \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21425",
            name: "L\u00ea Quang Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21426",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21427",
            name: "L\u00ea Th\u00e1nh T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21428",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21429",
            name: "L\u00ea Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21430",
            name: "L\u00ea Trung \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21431",
            name: "L\u00ea T\u1ef1 Nhi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21432",
            name: "L\u00ea V\u0103n H\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21433",
            name: "L\u00ea V\u0103n Mi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21434",
            name: "L\u00ea Vi\u1ebft L\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21435",
            name: "L\u1ecbch \u0110\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21436",
            name: "Long Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21437",
            name: "L\u01b0\u01a1ng Ng\u1ecdc Quy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21438",
            name: "L\u01b0\u01a1ng Qu\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21439",
            name: "L\u01b0\u01a1ng V\u0103n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21440",
            name: "L\u01b0\u01a1ng Y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21441",
            name: "L\u01b0u H\u1eefu Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21442",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21443",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21444",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21445",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21446",
            name: "L\u00fd V\u0103n Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21447",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21448",
            name: "Mai An Ti\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21449",
            name: "Mai Kh\u1eafc \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21450",
            name: "Mai L\u00e3o B\u1ea1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21451",
            name: "Mai Th\u00fac Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21452", name: "Mang C\u00e1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21453",
            name: "Minh M\u1ea1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21454", name: "Nam Giao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21455",
            name: "Ng\u00f4 \u0110\u1ee9c K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21456",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21457",
            name: "Ng\u00f4 H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21458", name: "Ng\u00f4 Kha", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21459",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21460",
            name: "Ng\u00f4 S\u0129 Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21461",
            name: "Ng\u00f4 Th\u1ebf L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21462",
            name: "Ng\u00f4 Th\u1eddi Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21463",
            name: "Ng\u1ecdc Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21464",
            name: "Ng\u1ef1 B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21465",
            name: "Ng\u1ef1 Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21466",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21467",
            name: "Nguy\u1ec5n Bi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21468",
            name: "Nguy\u1ec5n B\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21469",
            name: "Nguy\u1ec5n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21470",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21471",
            name: "Nguy\u1ec5n Ch\u00ed Di\u1ec5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21472",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21473",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21474",
            name: "Nguy\u1ec5n C\u01b0 Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21479",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21482",
            name: "Nguy\u1ec5n Duy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21483",
            name: "Nguy\u1ec5n Duy Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21475",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21476",
            name: "Nguy\u1ec5n \u0110\u00ecnh T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21477",
            name: "Nguy\u1ec5n \u0110\u1ed7 Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21478",
            name: "Nguy\u1ec5n \u0110\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21480",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21481",
            name: "Nguy\u1ec5n \u0110\u1ee9c T\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21484",
            name: "Nguy\u1ec5n Gia Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21485",
            name: "Nguy\u1ec5n H\u00e0m Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21486",
            name: "Nguy\u1ec5n Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21487",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21488",
            name: "Nguy\u1ec5n H\u1eefu Ba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21489",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21490",
            name: "Nguy\u1ec5n H\u1eefu D\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21491",
            name: "Nguy\u1ec5n H\u1eefu Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21492",
            name: "Nguy\u1ec5n H\u1eefu Th\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21493",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21494",
            name: "Nguy\u1ec5n Huy L\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21495",
            name: "Nguy\u1ec5n Kh\u00e1nh To\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21496",
            name: "Nguy\u1ec5n Khoa Chi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21497",
            name: "Nguy\u1ec5n Khoa Vy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21498",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21499",
            name: "Nguy\u1ec5n L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21500",
            name: "Nguy\u1ec5n L\u1ed9 Tr\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21501",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21502",
            name: "Nguy\u1ec5n Minh V\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21503",
            name: "Nguy\u1ec5n Ph\u1ea1m Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21504",
            name: "Nguy\u1ec5n Phan Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21505",
            name: "Nguy\u1ec5n Phong S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21506",
            name: "Nguy\u1ec5n Ph\u00fac Chu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21507",
            name: "Nguy\u1ec5n Ph\u00fac Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21508",
            name: "Nguy\u1ec5n Ph\u00fac Nguy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21509",
            name: "Nguy\u1ec5n Ph\u00fac T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21510",
            name: "Nguy\u1ec5n Ph\u00fac Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21511",
            name: "Nguy\u1ec5n Ph\u00fac Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21512",
            name: "Nguy\u1ec5n Quang B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21513",
            name: "Nguy\u1ec5n Qu\u00fd Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21514",
            name: "Nguy\u1ec5n Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21515",
            name: "Nguy\u1ec5n Sinh Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21516",
            name: "Nguy\u1ec5n Sinh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21517",
            name: "Nguy\u1ec5n Sinh S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21518",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21519",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21520",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21521",
            name: "Nguy\u1ec5n Thi\u1ec7n K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21522",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21523",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21524",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21525",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21526",
            name: "Nguy\u1ec5n Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21527",
            name: "Nguy\u1ec5n Trung Ng\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21528",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21529",
            name: "Nguy\u1ec5n T\u01b0 Gi\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21530",
            name: "Nguy\u1ec5n Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21531",
            name: "Nguy\u1ec5n V\u0103n Ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21532",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21533",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21534",
            name: "Nguy\u1ec5n V\u0103n \u0110\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21535",
            name: "Nguy\u1ec5n V\u0103n Huy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21536",
            name: "Nguy\u1ec5n V\u0103n Kh\u1ea1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21537",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21538",
            name: "Nguy\u1ec5n V\u0103n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21539",
            name: "Nguy\u1ec5n V\u0103n Tho\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21540",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21541",
            name: "Nguy\u1ec5n Xu\u00e2n \u00d4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21542",
            name: "Nguy\u1ec7t Bi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21543",
            name: "Nh\u1ea5t \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21544",
            name: "Nh\u1eadt L\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21545",
            name: "\u00d4ng \u00cdch Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21546",
            name: "P\u00e1c B\u00f3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21547",
            name: "Ph\u1ea1m \u0110\u00ecnh H\u1ed5",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21548",
            name: "Ph\u1ea1m \u0110\u00ecnh To\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21549",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21550",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21551",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21552",
            name: "Ph\u1ea1m Th\u1ebf Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21553",
            name: "Ph\u1ea1m Th\u1ecb Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21554", name: "Ph\u1ea1m Tu", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21555",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21556", name: "Phan Anh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21557",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21558",
            name: "Phan C\u1ea3nh K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21559",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21560",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21561",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21562",
            name: "Phan H\u1eefu Th\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21563",
            name: "Phan Huy Ch\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21564",
            name: "Phan Huy \u00cdch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21565",
            name: "Phan Tr\u1ecdng T\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21566",
            name: "Phan V\u0103n Tr\u01b0\u1eddng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21567",
            name: "Ph\u00f3 \u0110\u1ee9c Ch\u00ednh",
            prefix: "Ph\u1ed1",
          },
          { id: "21568", name: "Phong Ch\u00e2u", prefix: "Ph\u1ed1" },
          {
            id: "21569",
            name: "Ph\u00fa M\u1ed9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21570",
            name: "Ph\u00f9ng Ch\u00ed Ki\u00ean",
            prefix: "Ph\u1ed1",
          },
          { id: "21571", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "21572",
            name: "Ph\u00f9ng Kh\u1eafc Khoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21573",
            name: "Ph\u00f9ng L\u01b0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21574",
            name: "Qu\u1ea3ng T\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21575",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21576",
            name: "Qu\u1ed1c l\u1ed9 49",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21577",
            name: "Qu\u1ed1c l\u1ed9 49B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21578",
            name: "Qu\u1ed1c S\u1eed Qu\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21579", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21580", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21581", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21582", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21583", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21584", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21585", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21586",
            name: "S\u01b0 Li\u1ec5u Qu\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21587",
            name: "S\u01b0 V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21588", name: "T\u1ea1 Quang B\u1eedu", prefix: "Ph\u1ed1" },
          { id: "21589", name: "Tam Thai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21590",
            name: "T\u1ea3n \u0110\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21591",
            name: "T\u00e2n S\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21592",
            name: "T\u00e2n Thi\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21593",
            name: "T\u0103ng B\u1ea1t H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21594",
            name: "T\u00e2y S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21595",
            name: "Th\u1ea1ch H\u00e3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21596",
            name: "Th\u00e1i Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21597",
            name: "Th\u00e2n Tr\u1ecdng M\u1ed9t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21598",
            name: "Th\u00e2n V\u0103n Nhi\u1ebfp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21599",
            name: "Th\u00e1nh Gi\u00f3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21600",
            name: "Thanh H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21601",
            name: "Thanh H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21602",
            name: "Thanh Lam B\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21603",
            name: "Thanh Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21604",
            name: "Thanh T\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21605",
            name: "Th\u1ebf L\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21606",
            name: "Th\u00edch N\u1eef Di\u1ec7u Kh\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21607",
            name: "Th\u00edch T\u1ecbnh Khi\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21608",
            name: "Thi\u00ean Thai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21609",
            name: "Th\u1ee7y D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21610",
            name: "T\u1ec9nh L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21611",
            name: "T\u1ec9nh l\u1ed9 10A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21612",
            name: "T\u1ecbnh T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21613",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21614",
            name: "T\u1ed1 H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21615",
            name: "T\u00f4 Ng\u1ecdc V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21616",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21617",
            name: "T\u00f4n Quang Phi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21618",
            name: "T\u00f4n Th\u1ea5t B\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21619",
            name: "T\u00f4n Th\u1ea5t C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21621",
            name: "T\u00f4n Th\u1ea5t D\u01b0\u01a1ng K\u1ef5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21620",
            name: "T\u00f4n Th\u1ea5t \u0110\u1ea1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21622",
            name: "T\u00f4n Th\u1ea5t Thi\u1ec7p",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21623",
            name: "T\u00f4n Th\u1ea5t Thuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21624",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21625",
            name: "T\u1ed1ng Duy T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21626",
            name: "Tr\u1ea7n Anh Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21627",
            name: "Tr\u1ea7n Anh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21628",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21629",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21630",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21631",
            name: "Tr\u1ea7n Ho\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21632",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21633",
            name: "Tr\u1ea7n H\u1eefu D\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21634",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21635",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21636",
            name: "Tr\u1ea7n L\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21637",
            name: "Tr\u1ea7n Nguy\u00ean \u0110\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21638",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21639",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21640",
            name: "Tr\u1ea7n Nh\u1ea5t B\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21641",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21642",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21643",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21644",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21645",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21646",
            name: "Tr\u1ea7n Qu\u00fd Kho\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21647",
            name: "Tr\u1ea7n Th\u00e1i T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21648",
            name: "Tr\u1ea7n Thanh M\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21649",
            name: "Tr\u1ea7n Th\u00fac Nh\u1eabn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21650",
            name: "Tr\u1ea7n V\u0103n K\u1ef7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21651",
            name: "Tr\u1ea7n V\u0103n \u01a0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21652",
            name: "Tr\u1ea7n Xu\u00e2n So\u1ea1n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21653",
            name: "Tri\u1ec7u Quang Ph\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21654",
            name: "Tr\u1ecbnh C\u00f4ng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21655",
            name: "Tr\u1ecbnh Ho\u00e0i \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21656",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21657",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21658",
            name: "Tr\u01b0\u01a1ng Gia M\u00f4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21659",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21660",
            name: "T\u1ef1 \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21661",
            name: "T\u00fa X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21662",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21663",
            name: "T\u00f9ng Thi\u1ec7n V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21664",
            name: "Tuy L\u00fd V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21665",
            name: "\u01afng B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21666",
            name: "\u01afng Tr\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21667", name: "V\u0103n Cao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21668",
            name: "V\u1ea1n Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21669",
            name: "Vi\u1ec7t B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21670",
            name: "V\u00f5 Li\u00eam S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21671",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21672",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21673",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21674",
            name: "V\u00f5 V\u0103n T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21675", name: "V\u0169 Ng\u1ecdc Phan", prefix: "Ph\u1ed1" },
          {
            id: "21676",
            name: "X\u00e3 T\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21677",
            name: "X\u00f3m Gi\u00f3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21678",
            name: "X\u00f3m H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21679", name: "Xu\u00e2n 68", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21680",
            name: "Xu\u00e2n Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21681",
            name: "Xu\u00e2n Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21682",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2749",
            name: "An C\u1ef1u City",
            lat: "16.457592010498",
            lng: "107.60492706299",
          },
          {
            id: "2750",
            name: "An \u0110\u00f4ng Villas",
            lat: "16.457887649536",
            lng: "107.612449646",
          },
          {
            id: "2751",
            name: "Chung c\u01b0 Xu\u00e2n Ph\u00fa",
            lat: "16.464120864868",
            lng: "107.60166168213",
          },
          {
            id: "2752",
            name: "EVN-Land Central Hu\u1ebf",
            lat: "16.456331253052",
            lng: "107.590675354",
          },
          {
            id: "2753",
            name: "Nam An Building",
            lat: "16.443000793457",
            lng: "107.60653686523",
          },
          {
            id: "2754",
            name: "The Manor",
            lat: "16.464460372925",
            lng: "107.61270904541",
          },
        ],
      },
      {
        id: "195",
        name: "H\u01b0\u01a1ng Th\u1ee7y",
        wards: [
          { id: "2739", name: "D\u01b0\u01a1ng H\u00f2a", prefix: "X\u00e3" },
          { id: "2740", name: "Ph\u00fa B\u00e0i", prefix: "Ph\u01b0\u1eddng" },
          { id: "2741", name: "Ph\u00fa S\u01a1n", prefix: "X\u00e3" },
          { id: "2742", name: "Th\u1ee7y B\u1eb1ng", prefix: "X\u00e3" },
          {
            id: "2743",
            name: "Th\u1ee7y Ch\u00e2u",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2744",
            name: "Thu\u1ef7 D\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2745",
            name: "Th\u1ee7y D\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2746",
            name: "Th\u1ee7y L\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2747", name: "Th\u1ee7y Ph\u00f9", prefix: "X\u00e3" },
          {
            id: "2748",
            name: "Th\u1ee7y Ph\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2749", name: "Th\u1ee7y T\u00e2n", prefix: "X\u00e3" },
          { id: "2750", name: "Th\u1ee7y Thanh", prefix: "X\u00e3" },
          { id: "2751", name: "Thu\u1ef7 V\u00e2n", prefix: "X\u00e3" },
          { id: "2752", name: "Th\u1ee7y V\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "21683", name: "2/9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21684",
            name: "An Th\u01b0\u1eddng C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21685",
            name: "B\u00f9i Huy B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21686",
            name: "B\u00f9i Xu\u00e2n Ph\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21687",
            name: "Cao B\u00e1 \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21688",
            name: "Ch\u00e1nh \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21689",
            name: "Ch\u00e2u S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21690",
            name: "Ch\u00e2u Th\u01b0\u1ee3ng V\u0103n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21691",
            name: "Ch\u00e2u V\u0103n Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21692",
            name: "C\u00f4ng L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21693",
            name: "C\u01b0 Ch\u00e1nh 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21694",
            name: "D\u1ea1 L\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21701",
            name: "D\u01b0\u01a1ng Khu\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21702",
            name: "D\u01b0\u01a1ng Nguy\u00ean Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21703",
            name: "D\u01b0\u01a1ng Thanh B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21704",
            name: "D\u01b0\u01a1ng Thi\u1ec7u T\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21695",
            name: "\u0110\u1ea1i Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21696",
            name: "\u0110\u1eb7ng Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21697",
            name: "\u0110\u1eb7ng Tr\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21698",
            name: "\u0110inh L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21699",
            name: "\u0110\u1ed7 Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21700",
            name: "\u0110\u1ed7 Xu\u00e2n H\u1ee3p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21705",
            name: "Gi\u00e1p H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21706",
            name: "H\u1ed3 Bi\u1ec3u Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21707",
            name: "Ho\u00e0ng H\u1eefu Th\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21708",
            name: "Ho\u00e0ng Minh Gi\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21709",
            name: "Ho\u00e0ng Phan Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21710",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21711",
            name: "Kh\u00fac Th\u1eeba D\u1ee5",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21712",
            name: "L\u00ea Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21713",
            name: "L\u00ea \u0110\u00ecnh M\u1ed9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21714", name: "L\u00ea Mai", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21715", name: "L\u00ea Thanh Ngh\u1ecb", prefix: "Ph\u1ed1" },
          {
            id: "21716",
            name: "L\u00ea Tr\u1ecdng B\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21717",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21718",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21719",
            name: "L\u1ee3i N\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21720",
            name: "L\u00fd \u0110\u1ea1o Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21721",
            name: "Mai Xu\u00e2n Th\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21722",
            name: "Minh M\u1ea1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21723",
            name: "M\u1ef9 Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21724", name: "Nam Cao", prefix: "Ph\u1ed1" },
          {
            id: "21725",
            name: "Ng\u00f4 Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21726",
            name: "Ng\u00f4 Th\u00ec S\u0129",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21727",
            name: "Nguy\u1ec5n C\u00f4ng Hoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21729",
            name: "Nguy\u1ec5n Duy Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21730",
            name: "Nguy\u1ec5n Duy Lu\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21728",
            name: "Nguy\u1ec5n \u0110\u00ecnh X\u01b0\u1edbng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21731",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21732",
            name: "Nguy\u1ec5n Huy T\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21733",
            name: "Nguy\u1ec5n Khoa V\u0103n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21734",
            name: "Nguy\u1ec5n Quang Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21735",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21736",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21737",
            name: "Nguy\u1ec5n Thanh \u00c1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21738",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21739",
            name: "Nguy\u1ec5n Tr\u1ecdng H\u1ee3p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21740",
            name: "Nguy\u1ec5n Tr\u1ecdng Thu\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21741",
            name: "Nguy\u1ec5n V\u0103n Ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21742",
            name: "Nguy\u1ec5n V\u0103n Ch\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21743",
            name: "Nguy\u1ec5n V\u0103n Th\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21744",
            name: "Nguy\u1ec5n V\u0103n Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21745",
            name: "Nguy\u1ec5n Vi\u1ebft Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21746",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21747",
            name: "Nguy\u1ec5n Xu\u00e2n Ng\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21748", name: "Ph\u1ea1m Huy Th\u00f4ng", prefix: "Ph\u1ed1" },
          {
            id: "21749",
            name: "Ph\u1ea1m Th\u1ebf Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21750",
            name: "Ph\u1ea1m V\u0103n Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21751",
            name: "Ph\u00f9ng L\u01b0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21752",
            name: "Ph\u00f9ng Qu\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21753", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21754",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21755",
            name: "S\u00f3ng H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21756",
            name: "T\u00e2n Tr\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21757",
            name: "Th\u00e1i Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21758",
            name: "Th\u00e1i V\u0129nh Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21759",
            name: "Th\u00e2n Nh\u00e2n Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21760", name: "Thanh Lam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21761",
            name: "Thu\u1eadn H\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21762",
            name: "T\u00f4n Th\u1ea5t S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21763",
            name: "Tr\u1ea7n Ho\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21764",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21765",
            name: "Tr\u1ea7n Thanh T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21766",
            name: "Tr\u1ecbnh C\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21767",
            name: "Tr\u01b0ng N\u1eef V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21768",
            name: "V\u00e2n D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21769",
            name: "V\u0103n Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21770",
            name: "V\u00f5 Duy Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21771", name: "V\u00f5 Khoa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21772",
            name: "V\u00f5 Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21773",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21774",
            name: "V\u00f5 Tr\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21775",
            name: "V\u00f5 Tr\u1ecdng B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21776",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21777",
            name: "V\u00f5 Xu\u00e2n L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21778",
            name: "V\u01b0\u01a1ng Th\u1eeba V\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2755",
            name: "\u0110\u00f4ng Nam Th\u1ee7y An",
            lat: "16.445426940918",
            lng: "107.61569213867",
          },
          {
            id: "2756",
            name: "Khu \u0111\u00f4 th\u1ecb An V\u00e2n D\u01b0\u01a1ng",
            lat: "16.480993270874",
            lng: "107.61829376221",
          },
        ],
      },
      {
        id: "196",
        name: "H\u01b0\u01a1ng Tr\u00e0",
        wards: [
          { id: "2753", name: "B\u00ecnh \u0110i\u1ec1n", prefix: "X\u00e3" },
          {
            id: "2754",
            name: "B\u00ecnh Th\u00e0nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2755", name: "H\u1ea3i D\u01b0\u01a1ng", prefix: "X\u00e3" },
          {
            id: "2756",
            name: "H\u1ed3ng Ti\u1ebfn",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2757",
            name: "H\u01b0\u01a1ng An",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2758", name: "H\u01b0\u01a1ng B\u00ecnh", prefix: "X\u00e3" },
          {
            id: "2759",
            name: "H\u01b0\u01a1ng Ch\u1eef",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2760",
            name: "H\u01b0\u01a1ng H\u1ed3",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2761", name: "H\u01b0\u01a1ng Phong", prefix: "X\u00e3" },
          {
            id: "2762",
            name: "H\u01b0\u01a1ng Th\u1ecd",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2763", name: "H\u01b0\u01a1ng To\u00e0n", prefix: "X\u00e3" },
          {
            id: "2764",
            name: "H\u01b0\u01a1ng V\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2765",
            name: "H\u01b0\u01a1ng V\u0103n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2766", name: "H\u01b0\u01a1ng Vinh", prefix: "X\u00e3" },
          {
            id: "2767",
            name: "H\u01b0\u01a1ng Xu\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2768", name: "T\u1ee9 H\u1ea1", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "21779", name: "49A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21780", name: "Bao Vinh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21781",
            name: "B\u00f9i C\u00f4ng Tr\u1eebng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21782",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21783",
            name: "\u0110inh B\u1ed9 L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21784",
            name: "\u0110o\u00e0n V\u0103n S\u00e1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21785",
            name: "\u0110\u1ed9c L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21786",
            name: "H\u1ed3 V\u0103n T\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21787",
            name: "Ho\u00e0ng Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21788",
            name: "H\u1ed3ng L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21789", name: "Kim Tr\u00e0", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21790",
            name: "L\u00e2m M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21791",
            name: "L\u00ea M\u1eadu L\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21792",
            name: "L\u00ea Quang Ho\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21793",
            name: "L\u00ea S\u0129 Th\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21794",
            name: "L\u00ea Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21795",
            name: "L\u00ea Thuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21796",
            name: "L\u00fd B\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21797",
            name: "L\u00fd Th\u00e1i T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21798",
            name: "Ng\u1ecdc H\u00e2n C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21799",
            name: "Nguy\u1ec5n Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21800",
            name: "Nguy\u1ec5n Khoa \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21801",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21802",
            name: "Phan S\u00e0o Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21803",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21804",
            name: "Qu\u1ed1c l\u1ed9 49",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21805", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21806",
            name: "S\u00f4ng B\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21807",
            name: "T\u0103ng B\u1ea1t H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21808",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21809",
            name: "T\u00f4n Th\u1ea5t B\u00e1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21810",
            name: "Tr\u1ea7n \u0110\u0103ng Khoa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21811",
            name: "Tr\u1ea7n Qu\u1ed1c Tu\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21812",
            name: "Tr\u1ea7n Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21813",
            name: "V\u0103n Th\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21814",
            name: "V\u00f5 V\u0103n D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "197",
        name: "Nam \u0110\u00f4ng",
        wards: [
          { id: "2769", name: "H\u01b0\u01a1ng Giang", prefix: "X\u00e3" },
          { id: "2770", name: "H\u01b0\u01a1ng Ho\u00e0", prefix: "X\u00e3" },
          { id: "2771", name: "H\u01b0\u01a1ng H\u1eefu", prefix: "X\u00e3" },
          { id: "2772", name: "H\u01b0\u01a1ng L\u1ed9c", prefix: "X\u00e3" },
          { id: "2773", name: "H\u01b0\u01a1ng Ph\u00fa", prefix: "X\u00e3" },
          { id: "2774", name: "H\u01b0\u01a1ng S\u01a1n", prefix: "X\u00e3" },
          { id: "2775", name: "Khe Tre", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "2776", name: "Th\u01b0\u1ee3ng L\u1ed9", prefix: "X\u00e3" },
          { id: "2777", name: "Th\u01b0\u1ee3ng Long", prefix: "X\u00e3" },
          { id: "2778", name: "Th\u01b0\u1ee3ng Nh\u1eadt", prefix: "X\u00e3" },
          {
            id: "2779",
            name: "Th\u01b0\u1ee3ng Qu\u1ea3ng",
            prefix: "X\u00e3",
          },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "198",
        name: "Phong \u0110i\u1ec1n",
        wards: [
          { id: "2780", name: "\u0110i\u1ec1n H\u1ea3i", prefix: "X\u00e3" },
          { id: "2781", name: "\u0110i\u1ec1n Ho\u00e0", prefix: "X\u00e3" },
          {
            id: "2782",
            name: "\u0110i\u1ec1n H\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "2783", name: "\u0110i\u1ec1n L\u1ed9c", prefix: "X\u00e3" },
          { id: "2784", name: "\u0110i\u1ec1n M\u00f4n", prefix: "X\u00e3" },
          { id: "2785", name: "Phong An", prefix: "X\u00e3" },
          { id: "2786", name: "Phong B\u00ecnh", prefix: "X\u00e3" },
          { id: "2787", name: "Phong Ch\u01b0\u01a1ng", prefix: "X\u00e3" },
          {
            id: "2788",
            name: "Phong \u0110i\u1ec1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2789", name: "Phong H\u1ea3i", prefix: "X\u00e3" },
          { id: "2790", name: "Phong Hi\u1ec1n", prefix: "X\u00e3" },
          { id: "2791", name: "Phong Ho\u00e0", prefix: "X\u00e3" },
          { id: "2792", name: "Phong M\u1ef9", prefix: "X\u00e3" },
          { id: "2793", name: "Phong S\u01a1n", prefix: "X\u00e3" },
          { id: "2794", name: "Phong Th", prefix: "X\u00e3" },
          { id: "2795", name: "Phong Thu", prefix: "X\u00e3" },
          { id: "2796", name: "Phong Xu\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "21815",
            name: "\u0110\u00f4ng Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21816",
            name: "L\u00ea Nh\u1eef L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21817",
            name: "Ph\u00f2 Tr\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21818",
            name: "Qu\u1ed1c L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21819",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21820",
            name: "V\u0103n Lang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21821",
            name: "V\u00f2ng Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "199",
        name: "Ph\u00fa L\u1ed9c",
        wards: [
          {
            id: "2797",
            name: "L\u0103ng C\u00f4",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2798", name: "L\u1ed9c An", prefix: "X\u00e3" },
          { id: "2799", name: "L\u1ed9c B\u00ecnh", prefix: "X\u00e3" },
          { id: "2800", name: "L\u1ed9c B\u1ed5n", prefix: "X\u00e3" },
          { id: "2801", name: "L\u1ed9c \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "2802", name: "L\u1ed9c H\u00f2a", prefix: "X\u00e3" },
          { id: "2803", name: "L\u1ed9c S\u01a1n", prefix: "X\u00e3" },
          { id: "2804", name: "L\u1ed9c Th\u1ee7y", prefix: "X\u00e3" },
          { id: "2805", name: "L\u1ed9c Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "2806", name: "L\u1ed9c Tr\u00ec", prefix: "X\u00e3" },
          { id: "2807", name: "L\u1ed9c V\u0129nh", prefix: "X\u00e3" },
          {
            id: "2808",
            name: "Ph\u00fa L\u1ed9c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2809", name: "Vinh Giang", prefix: "X\u00e3" },
          { id: "2810", name: "Vinh H\u1ea3i", prefix: "X\u00e3" },
          { id: "2811", name: "Vinh Hi\u1ec1n", prefix: "X\u00e3" },
          { id: "2812", name: "Vinh H\u01b0ng", prefix: "X\u00e3" },
          { id: "2813", name: "Vinh M\u1ef9", prefix: "X\u00e3" },
          { id: "2814", name: "Xu\u00e2n L\u1ed9c", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "21822", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21823", name: "24/3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21824", name: "A Biah", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21825", name: "A \u0110on", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21826", name: "A Ko", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21827", name: "A N\u00f4r", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21828", name: "A S\u00e1p", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21829", name: "A V\u1ea7u", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21830",
            name: "\u0102m M\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21831",
            name: "An C\u01b0 \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21832",
            name: "B\u1ea1ch M\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21833",
            name: "Ch\u00e2n M\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21834", name: "C\u1ed5 Loa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21835",
            name: "\u0110\u1eb7ng Do",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21836",
            name: "\u0110o\u00e0n Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21837",
            name: "\u0110\u1ed3ng S\u0129 B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21838",
            name: "\u0110\u1ed9ng So",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21839",
            name: "Gi\u1ea3i ph\u00f3ng A So",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21840",
            name: "H\u1ea3i V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21841",
            name: "H\u1ed3 V\u0103n \u0110\u1ed7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21842",
            name: "H\u1ed3 V\u0103n H\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21843",
            name: "Ho\u00e0ng \u0110\u1ee9c Tr\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21844",
            name: "Ho\u00e0ng Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21845",
            name: "K\u0103n Tr\u00e9ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21846", name: "Konh H\u01b0", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21847", name: "Konh Khoai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21848",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21849",
            name: "L\u00ea B\u00e1 D\u1ef5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21850",
            name: "L\u00ea Ch\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21851",
            name: "L\u00ea C\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21852",
            name: "L\u00ea \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21853",
            name: "L\u00ea Kh\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21854",
            name: "L\u01b0\u01a1ng \u0110\u1ecbnh C\u1ee7a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21855",
            name: "L\u00fd Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21856",
            name: "Nguy\u1ec5n C\u1ea3nh Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21857",
            name: "Nguy\u1ec5n C\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21858",
            name: "Nguy\u1ec5n \u0110\u00ecnh S\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21859",
            name: "Nguy\u1ec5n S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21860",
            name: "Nguy\u1ec5n Th\u00fac Nhu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21861",
            name: "Nguy\u1ec5n Th\u1ee9c T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21862",
            name: "Nguy\u1ec5n V\u0103n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21863",
            name: "Nguy\u1ec5n V\u0103n \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21864",
            name: "Nguy\u1ec5n V\u0103n Ho\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21865",
            name: "Nguy\u1ec5n V\u0103n Qu\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21866",
            name: "Nguy\u1ec5n V\u0103n Tuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21867",
            name: "N\u01a1 Trang Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21868", name: "Phan Sung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21869",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21870",
            name: "Qu\u1ef3nh Tr\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21871",
            name: "Thai D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21872",
            name: "Thanh Duy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21873",
            name: "Thu\u1ef7 T\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21874",
            name: "Tr\u1ea7n \u1ea4m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21875",
            name: "Tr\u1ea7n \u0110\u00ecnh T\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21876",
            name: "Tr\u1ea5n H\u1ea3i Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21877",
            name: "Tr\u1ea7n Ti\u1ebfn L\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21878",
            name: "Tr\u1ecbnh T\u1ed1 T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21879",
            name: "Tr\u01b0\u01a1ng Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21880",
            name: "T\u1eeb D\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21881", name: "T\u01b0 Vinh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21882",
            name: "V\u1ed7 \u00c1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21883",
            name: "V\u00f5 B\u1ea9m",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2757",
            name: "Angsana Properties L\u0103ng C\u00f4",
            lat: "16.334613800049",
            lng: "107.95581054688",
          },
          {
            id: "2758",
            name: "Banyan Tree L\u0103ng C\u00f4",
            lat: "16.337621688843",
            lng: "107.95332336426",
          },
          {
            id: "2759",
            name: "Ch\u00e2n M\u00e2y L\u0103ng C\u00f4",
            lat: "16.293020248413",
            lng: "108.04441833496",
          },
          {
            id: "2760",
            name: "Laguna L\u0103ng C\u00f4",
            lat: "16.327880859375",
            lng: "107.95648193359",
          },
          {
            id: "2761",
            name: "Laguna Park",
            lat: "16.325740814209",
            lng: "107.95626068115",
          },
        ],
      },
      {
        id: "200",
        name: "Ph\u00fa Vang",
        wards: [
          { id: "2815", name: "Ph\u00fa An", prefix: "X\u00e3" },
          { id: "2817", name: "Ph\u00fa Di\u00ean", prefix: "X\u00e3" },
          { id: "2818", name: "Ph\u00fa D\u01b0\u01a1ng", prefix: "X\u00e3" },
          {
            id: "2816",
            name: "Ph\u00fa \u0110a",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2819", name: "Ph\u00fa H\u1ea3i", prefix: "X\u00e3" },
          { id: "2820", name: "Ph\u00fa H\u1ed3", prefix: "X\u00e3" },
          { id: "2821", name: "Ph\u00fa L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "2822", name: "Ph\u00fa M\u1eadu", prefix: "X\u00e3" },
          { id: "2823", name: "Ph\u00fa M\u1ef9", prefix: "X\u00e3" },
          { id: "2824", name: "Ph\u00fa Thanh", prefix: "X\u00e3" },
          { id: "2825", name: "Ph\u00fa Thu\u1eadn", prefix: "X\u00e3" },
          { id: "2826", name: "Ph\u00fa Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "2827", name: "Ph\u00fa Xu\u00e2n", prefix: "X\u00e3" },
          { id: "2828", name: "Thu\u1eadn An", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "2829", name: "Vinh An", prefix: "X\u00e3" },
          { id: "2830", name: "Vinh H\u00e0", prefix: "X\u00e3" },
          { id: "2831", name: "Vinh Ph\u00fa", prefix: "X\u00e3" },
          { id: "2832", name: "Vinh Th\u00e1i", prefix: "X\u00e3" },
          { id: "2833", name: "Vinh Thanh", prefix: "X\u00e3" },
          { id: "2834", name: "Vinh Xu\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "21884", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21885",
            name: "\u0110o\u00e0n Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21886",
            name: "Ho\u00e0ng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21887",
            name: "Kinh D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21888",
            name: "L\u1ea1i Th\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21889",
            name: "Ng\u1ecdc Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21890",
            name: "Nguy\u1ec5n \u0110\u1ee9c Xuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21891",
            name: "Nguy\u1ec5n Sinh Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21892",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21893",
            name: "Qu\u1ed1c l\u1ed9 49",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21894",
            name: "T\u1ec9nh L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21895",
            name: "T\u1ec9nh l\u1ed9 10A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21896",
            name: "T\u1ef1 \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2762",
            name: "Hu\u1ebf Green City",
            lat: "16.494031906128",
            lng: "107.61267089844",
          },
        ],
      },
      {
        id: "201",
        name: "Qu\u1ea3ng \u0110i\u1ec1n",
        wards: [
          { id: "2835", name: "Qu\u1ea3ng An", prefix: "X\u00e3" },
          { id: "2836", name: "Qu\u1ea3ng C\u00f4ng", prefix: "X\u00e3" },
          { id: "2837", name: "Qu\u1ea3ng L\u1ee3i", prefix: "X\u00e3" },
          { id: "2838", name: "Qu\u1ea3ng Ng\u1ea1n", prefix: "X\u00e3" },
          { id: "2839", name: "Qu\u1ea3ng Ph\u00fa", prefix: "X\u00e3" },
          { id: "2840", name: "Qu\u1ea3ng Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "2841", name: "Qu\u1ea3ng Th\u00e1i", prefix: "X\u00e3" },
          { id: "2842", name: "Qu\u1ea3ng Th\u00e0nh", prefix: "X\u00e3" },
          { id: "2843", name: "Qu\u1ea3ng Th\u1ecd", prefix: "X\u00e3" },
          { id: "2844", name: "Qu\u1ea3ng Vinh", prefix: "X\u00e3" },
          { id: "2845", name: "S\u1ecba", prefix: "Th\u1ecb tr\u1ea5n" },
        ],
        streets: [
          {
            id: "21897",
            name: "Nguy\u1ec5n C\u1ea3nh D\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21898",
            name: "Nguy\u1ec5n V\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21899",
            name: "Ph\u1ea1m Quang \u00c1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21900",
            name: "T\u1ed1 H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "16",
    code: "KG",
    name: "Ki\u00ean Giang",
    districts: [
      {
        id: "202",
        name: "An Bi\u00ean",
        wards: [
          { id: "2846", name: "\u0110\u00f4ng Th\u00e1i", prefix: "X\u00e3" },
          { id: "2847", name: "\u0110\u00f4ng Y\u00ean", prefix: "X\u00e3" },
          { id: "2848", name: "H\u01b0ng Y\u00ean", prefix: "X\u00e3" },
          { id: "2849", name: "Nam Th\u00e1i", prefix: "X\u00e3" },
          { id: "2850", name: "Nam Th\u00e1i A", prefix: "X\u00e3" },
          { id: "2851", name: "Nam Y\u00ean", prefix: "X\u00e3" },
          { id: "2852", name: "T\u00e2y Y\u00ean", prefix: "X\u00e3" },
          { id: "2853", name: "T\u00e2y Y\u00ean A", prefix: "X\u00e3" },
          { id: "2854", name: "Th\u1ee9 Ba", prefix: "Th\u1ecb tr\u1ea5n" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "203",
        name: "An Minh",
        wards: [
          { id: "2855", name: "\u0110\u00f4ng Ho\u00e0", prefix: "X\u00e3" },
          { id: "2856", name: "\u0110\u00f4ng H\u01b0ng", prefix: "X\u00e3" },
          { id: "2857", name: "\u0110\u00f4ng H\u01b0ng A", prefix: "X\u00e3" },
          { id: "2858", name: "\u0110\u00f4ng H\u01b0ng B", prefix: "X\u00e3" },
          { id: "2859", name: "\u0110\u00f4ng Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "2860", name: "T\u00e2n Th\u1ea1nh", prefix: "X\u00e3" },
          {
            id: "2861",
            name: "Th\u1ee9 M\u01b0\u1eddi M\u1ed9t",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2862", name: "Thu\u1eadn Ho\u00e0", prefix: "X\u00e3" },
          { id: "2863", name: "V\u00e2n Kh\u00e1nh", prefix: "X\u00e3" },
          {
            id: "2864",
            name: "V\u00e2n Kh\u00e1nh \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "2865",
            name: "V\u00e2n Kh\u00e1nh T\u00e2y",
            prefix: "X\u00e3",
          },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "204",
        name: "Ch\u00e2u Th\u00e0nh",
        wards: [
          { id: "2866", name: "B\u00ecnh An", prefix: "X\u00e3" },
          { id: "2867", name: "Gi\u1ee5c T\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "2868", name: "Minh Ho\u00e0", prefix: "X\u00e3" },
          {
            id: "2869",
            name: "Minh L\u01b0\u01a1ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2870", name: "Mong Th\u1ecd", prefix: "X\u00e3" },
          { id: "2871", name: "Mong Th\u1ecd A", prefix: "X\u00e3" },
          { id: "2872", name: "Mong Th\u1ecd B", prefix: "X\u00e3" },
          { id: "2873", name: "Thanh L\u1ed9c", prefix: "X\u00e3" },
          {
            id: "2874",
            name: "V\u0129nh Ho\u00e0 Hi\u1ec7p",
            prefix: "X\u00e3",
          },
          {
            id: "2875",
            name: "V\u0129nh H\u00f2a Ph\u00fa",
            prefix: "X\u00e3",
          },
        ],
        streets: [
          { id: "21901", name: "61", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21902",
            name: "Mai Th\u1ecb H\u1ed3ng H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21903",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21904",
            name: "Qu\u1ed1c L\u1ed9 61",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21905",
            name: "Qu\u1ed1c l\u1ed9 63",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21906",
            name: "Qu\u1ed1c l\u1ed9 80",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21907",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "205",
        name: "Giang Th\u00e0nh",
        wards: [
          { id: "2876", name: "Ph\u00fa L\u1ee3i", prefix: "X\u00e3" },
          { id: "2877", name: "Ph\u00fa M\u1ef9", prefix: "X\u00e3" },
          {
            id: "2878",
            name: "T\u00e2n Kh\u00e1nh Ho\u00e0",
            prefix: "X\u00e3",
          },
          { id: "2879", name: "V\u0129nh \u0110i\u1ec1u", prefix: "X\u00e3" },
          { id: "2880", name: "V\u0129nh Ph\u00fa ", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "206",
        name: "Gi\u1ed3ng Ri\u1ec1ng",
        wards: [
          {
            id: "2881",
            name: "B\u00e0n T\u00e2n \u0110\u1ecbnh",
            prefix: "X\u00e3",
          },
          { id: "2882", name: "B\u00e0n Th\u1ea1ch", prefix: "X\u00e3" },
          {
            id: "2883",
            name: "Gi\u1ed3ng Ri\u1ec1ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2884", name: "Ho\u00e0 An", prefix: "X\u00e3" },
          { id: "2885", name: "Ho\u00e0 H\u01b0ng", prefix: "X\u00e3" },
          { id: "2886", name: "Ho\u00e0 L\u1ee3i", prefix: "X\u00e3" },
          { id: "2887", name: "Ho\u00e0 Thu\u1eadn", prefix: "X\u00e3" },
          { id: "2888", name: "Long Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "2889", name: "Ng\u1ecdc Ch\u00fac", prefix: "X\u00e3" },
          { id: "2890", name: "Ng\u1ecdc Ho\u00e0", prefix: "X\u00e3" },
          { id: "2891", name: "Ng\u1ecdc Th\u00e0nh", prefix: "X\u00e3" },
          { id: "2892", name: "Ng\u1ecdc Thu\u1eadn", prefix: "X\u00e3" },
          { id: "2893", name: "Th\u1ea1nh B\u00ecnh", prefix: "X\u00e3" },
          { id: "2894", name: "Th\u1ea1nh Ho\u00e0", prefix: "X\u00e3" },
          { id: "2895", name: "Th\u1ea1nh H\u01b0ng", prefix: "X\u00e3" },
          { id: "2896", name: "Th\u1ea1nh L\u1ed9c", prefix: "X\u00e3" },
          { id: "2897", name: "Th\u1ea1nh Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "2898", name: "V\u0129nh Ph\u00fa", prefix: "X\u00e3" },
          { id: "2899", name: "V\u0129nh Th\u1ea1nh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "21908", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21909",
            name: "T\u1ec9nh L\u1ed9 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21910",
            name: "T\u1ec9nh l\u1ed9 933",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21911",
            name: "T\u1ec9nh L\u1ed9 963",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "207",
        name: "G\u00f2 Quao",
        wards: [
          { id: "2900", name: "\u0110\u1ecbnh An", prefix: "X\u00e3" },
          { id: "2901", name: "\u0110\u1ecbnh H\u00f2a", prefix: "X\u00e3" },
          { id: "2902", name: "G\u00f2 Quao", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "2903", name: "Th\u1edbi Qu\u1ea3n", prefix: "X\u00e3" },
          { id: "2904", name: "Th\u1ee7y Li\u1ec3u", prefix: "X\u00e3" },
          {
            id: "2905",
            name: "V\u0129nh H\u00f2a H\u01b0ng B\u1eafc",
            prefix: "X\u00e3",
          },
          {
            id: "2906",
            name: "V\u0129nh H\u00f2a H\u01b0ng Nam",
            prefix: "X\u00e3",
          },
          {
            id: "2907",
            name: "V\u0129nh Ph\u01b0\u1edbc A",
            prefix: "X\u00e3",
          },
          {
            id: "2908",
            name: "V\u0129nh Ph\u01b0\u1edbc B",
            prefix: "X\u00e3",
          },
          { id: "2909", name: "V\u0129nh Th\u1eafng ", prefix: "X\u00e3" },
          { id: "2910", name: "V\u0129nh Tuy", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "21912", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21913", name: "61", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21914",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21915",
            name: "Qu\u1ed1c l\u1ed9 80",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "208",
        name: "H\u00e0 Ti\u00ean",
        wards: [
          { id: "2911", name: "B\u00ecnh San", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2912",
            name: "\u0110\u00f4ng H\u1ed3",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2913", name: "M\u1ef9 \u0110\u1ee9c", prefix: "X\u00e3" },
          {
            id: "2914",
            name: "Ph\u00e1o \u0110\u00e0i",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2915", name: "Thu\u1eadn Y\u00ean", prefix: "X\u00e3" },
          { id: "2916", name: "Ti\u00ean H\u1ea3i", prefix: "X\u00e3" },
          { id: "2917", name: "T\u00f4 Ch\u00e2u", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "21916", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21917", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21918", name: "25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21919", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21920", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21921", name: "33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21922", name: "38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21923", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21924", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21925", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21926",
            name: "B\u00e0 L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21927",
            name: "B\u00ecnh San",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21928",
            name: "Chi L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21929",
            name: "M\u1ea1c C\u00f4ng T\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21930",
            name: "M\u1ea1c C\u1eedu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21931",
            name: "M\u1ea1c Thi\u00ean T\u00edch",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21932",
            name: "Ph\u01b0\u01a1ng Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21933",
            name: "Qu\u1ed1c l\u1ed9 63",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21934",
            name: "Qu\u1ed1c l\u1ed9 80",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21935",
            name: "Tao \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21936",
            name: "T\u1ec9nh L\u1ed9 28",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21937",
            name: "T\u1ec9nh l\u1ed9 955A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21938",
            name: "Tr\u1ea7n H\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2763",
            name: "H\u00e0 Ti\u00ean",
            lat: "10.378645896912",
            lng: "104.47301483154",
          },
        ],
      },
      {
        id: "209",
        name: "H\u00f2n \u0110\u1ea5t",
        wards: [
          { id: "2918", name: "B\u00ecnh Giang", prefix: "X\u00e3" },
          { id: "2919", name: "B\u00ecnh S\u01a1n", prefix: "X\u00e3" },
          {
            id: "2920",
            name: "H\u00f2n \u0110\u1ea5t",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2921", name: "L\u00ecnh Hu\u1ef3nh", prefix: "X\u00e3" },
          { id: "2922", name: "M\u1ef9 Hi\u1ec7p S\u01a1n", prefix: "X\u00e3" },
          { id: "2923", name: "M\u1ef9 L\u00e2m", prefix: "X\u00e3" },
          { id: "2924", name: "M\u1ef9 Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "2925", name: "M\u1ef9 Th\u00e1i", prefix: "X\u00e3" },
          { id: "2926", name: "M\u1ef9 Thu\u1eadn", prefix: "X\u00e3" },
          { id: "2927", name: "Nam Th\u00e1i S\u01a1n", prefix: "X\u00e3" },
          {
            id: "2928",
            name: "S\u00f3c S\u01a1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2929", name: "S\u01a1n B\u00ecnh", prefix: "X\u00e3" },
          { id: "2930", name: "S\u01a1n Ki\u00ean", prefix: "X\u00e3" },
          { id: "2931", name: "Th\u1ed5 S\u01a1n", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "21939",
            name: "Qu\u1ed1c l\u1ed9 80",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "210",
        name: "Ki\u00ean H\u1ea3i",
        wards: [
          { id: "2932", name: "An S\u01a1n", prefix: "X\u00e3" },
          { id: "2933", name: "H\u00f2n Tre", prefix: "X\u00e3" },
          { id: "2934", name: "L\u1ea1i S\u01a1n", prefix: "X\u00e3" },
          { id: "2935", name: "Nam Du", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "21940", name: "46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21941", name: "47", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
      {
        id: "211",
        name: "Ki\u00ean L\u01b0\u01a1ng",
        wards: [
          { id: "2936", name: "B\u00ecnh An", prefix: "X\u00e3" },
          { id: "2937", name: "B\u00ecnh Tr\u1ecb", prefix: "X\u00e3" },
          { id: "2938", name: "D\u01b0\u01a1ng Ho\u00e0", prefix: "X\u00e3" },
          { id: "2939", name: "Ho\u00e0 \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "2940", name: "H\u00f2n Ngh\u1ec7 ", prefix: "X\u00e3" },
          { id: "2941", name: "Ki\u00ean B\u00ecnh", prefix: "X\u00e3" },
          {
            id: "2942",
            name: "Ki\u00ean L\u01b0\u01a1ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2943", name: "S\u01a1n H\u1ea3i", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "21942",
            name: "M\u1ea1c Thi\u00ean T\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21943",
            name: "Qu\u1ed1c l\u1ed9 80",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21944",
            name: "Tr\u01b0\u01a1ng C\u00f4ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "212",
        name: "Ph\u00fa Qu\u1ed1c",
        wards: [
          { id: "2944", name: "An Th\u1edbi", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "2945", name: "B\u00e3i Th\u01a1m", prefix: "X\u00e3" },
          { id: "2946", name: "C\u1eeda C\u1ea1n", prefix: "X\u00e3" },
          { id: "2947", name: "C\u1eeda D\u01b0\u01a1ng", prefix: "X\u00e3" },
          {
            id: "2948",
            name: "D\u01b0\u01a1ng \u0110\u00f4ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2949", name: "D\u01b0\u01a1ng T\u01a1", prefix: "X\u00e3" },
          { id: "2950", name: "G\u00e0nh D\u1ea7u", prefix: "X\u00e3" },
          { id: "2951", name: "H\u00e0m Ninh", prefix: "X\u00e3" },
          { id: "2952", name: "H\u00f2n Th\u01a1m", prefix: "X\u00e3" },
          { id: "2953", name: "Th\u1ed5 Ch\u00e2u", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "21945", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21946", name: "63", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21947",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21948", name: "Ba Tr\u1ea1i", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21949",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21950",
            name: "B\u00e3i Tr\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21951",
            name: "B\u00e3i X\u1ebfp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21952", name: "B\u00e0o", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21953",
            name: "B\u00e0o Huy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21954",
            name: "B\u1ebfn Tr\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21955",
            name: "B\u00fang G\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21956",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21957", name: "C\u00e2y Sao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21958",
            name: "C\u00e2y Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21959",
            name: "C\u00e2y Th\u00f4ng Ngo\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21960",
            name: "C\u00e2y Th\u00f4ng Trong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21961",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21962",
            name: "Ch\u00f9a \u00d4ng C\u1eeda L\u1ea5p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21963",
            name: "Ch\u00f9a \u00d4ng Su\u1ed1i \u0110\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21964",
            name: "C\u1eeda C\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21965",
            name: "C\u1eeda L\u1ea5p - Su\u1ed1i M\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21966", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21967", name: "Dinh B\u00e0", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21976",
            name: "D\u01b0\u01a1ng \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21977",
            name: "D\u01b0\u01a1ng \u0110\u00f4ng B\u1eafc \u0110\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21978",
            name: "D\u01b0\u01a1ng T\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21968",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21969",
            name: "\u0110\u00f4ng \u0110\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21970",
            name: "\u0110\u00f4ng Tranh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "21971", name: "\u0110T 45", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21972", name: "\u0110T 46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21973", name: "\u0110T 47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21974", name: "\u0110T 973", prefix: "\u0110\u01b0\u1eddng" },
          { id: "21975", name: "\u0110T 975", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "21979",
            name: "G\u00e0nh D\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21980",
            name: "G\u00e0nh Gi\u00f3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21981",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21982",
            name: "Hi\u1ec7p Tho\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21983",
            name: "H\u1ed3 Th\u1ecb Nghi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21984",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21985",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21986",
            name: "L\u00ea \u0110\u1ee9c To\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21987",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21988",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21989",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21990",
            name: "M\u1ea1c C\u1eedu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21991",
            name: "M\u1ea1c Thi\u00ean T\u00edch",
            prefix: "Ph\u1ed1",
          },
          {
            id: "21992",
            name: "M\u0169i D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21993",
            name: "Nam B\u1eafc \u0110\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21994",
            name: "Nam S\u00e2n Bay",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21995",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21996",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21997",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21998",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "21999",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22000",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22001",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22002",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22003",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22004",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22005",
            name: "\u00d4ng Lang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22006", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "22007",
            name: "Qu\u1ed1c l\u1ed9 47",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22008",
            name: "Su\u1ed1i C\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22009",
            name: "Su\u1ed1i C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22010",
            name: "Su\u1ed1i M\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22011",
            name: "Su\u1ed1i Ti\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22012",
            name: "T\u1ec9nh L\u1ed9 45",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22013",
            name: "T\u1ec9nh l\u1ed9 46",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22014",
            name: "T\u1ec9nh L\u1ed9 47",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22015",
            name: "T\u1ec9nh L\u1ed9 48",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22016",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22017",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22018",
            name: "Tr\u1ea7n Qu\u1ed1c Hi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22019",
            name: "Trung \u0110o\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22020",
            name: "Tuy\u1ebfn Tr\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22021",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22022",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2764",
            name: "Casa Garden Hill",
            lat: "10.147193908691",
            lng: "103.99562835693",
          },
          {
            id: "2765",
            name: "Donasea Villas",
            lat: "10.304374694824",
            lng: "103.90280151367",
          },
          {
            id: "2766",
            name: "Emeral Home Resort",
            lat: "10.223299980164",
            lng: "103.96521759033",
          },
          {
            id: "2767",
            name: "Grand World Ph\u00fa Qu\u1ed1c",
            lat: "10.3238697052",
            lng: "103.8528137207",
          },
          {
            id: "2768",
            name: "Intercon Ph\u00fa Qu\u1ed1c",
            lat: "10.125341415405",
            lng: "103.99562835693",
          },
          {
            id: "2769",
            name: "K\u0110T B\u1eafc D\u01b0\u01a1ng \u0110\u00f4ng",
            lat: "10.241543769836",
            lng: "103.95861053467",
          },
          {
            id: "2770",
            name: "Kem Beach Resort",
            lat: "10.020344734192",
            lng: "104.037109375",
          },
          {
            id: "2771",
            name: "Movenpick Resort Ph\u00fa Qu\u1ed1c",
            lat: "10.252152442932",
            lng: "103.94194030762",
          },
          {
            id: "2772",
            name: "Novotel Villas",
            lat: "21.0306224823",
            lng: "105.84886932373",
          },
          {
            id: "2773",
            name: "Ocean Land 7",
            lat: "10.238369941711",
            lng: "103.98289489746",
          },
          {
            id: "2774",
            name: "Ph\u00fa Qu\u1ed1c Marina",
            lat: "10.133566856384",
            lng: "103.98900604248",
          },
          {
            id: "2775",
            name: "Premier Residences Ph\u00fa Qu\u1ed1c",
            lat: "10.033840179443",
            lng: "104.0295791626",
          },
          {
            id: "2776",
            name: "Premier Village Ph\u00fa Qu\u1ed1c",
            lat: "10.351905822754",
            lng: "103.88632202148",
          },
          {
            id: "2777",
            name: "Sonasea Residences",
            lat: "10.125341415405",
            lng: "103.99562835693",
          },
          {
            id: "2778",
            name: "Sonasea Villas & Resort",
            lat: "10.129186630249",
            lng: "103.98262786865",
          },
          {
            id: "2779",
            name: "Sunset Sanato",
            lat: "10.154601097107",
            lng: "103.97591400146",
          },
          {
            id: "2780",
            name: "Vinpearl Ph\u00fa Qu\u1ed1c",
            lat: "10.334785461426",
            lng: "103.84999084473",
          },
          {
            id: "2781",
            name: "Wyndham Garden Ph\u00fa Qu\u1ed1c",
            lat: "10.056416511536",
            lng: "103.98934173584",
          },
        ],
      },
      {
        id: "213",
        name: "R\u1ea1ch Gi\u00e1",
        wards: [
          {
            id: "2954",
            name: " V\u0129nh Thanh V\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2955", name: "An B\u00ecnh", prefix: "Ph\u01b0\u1eddng" },
          { id: "2956", name: "An H\u00f2a", prefix: "Ph\u01b0\u1eddng" },
          { id: "2957", name: "Phi Th\u00f4ng", prefix: "X\u00e3" },
          {
            id: "2958",
            name: "R\u1ea1ch S\u1ecfi",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2959",
            name: "V\u0129nh B\u1ea3o",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2960",
            name: "V\u0129nh Hi\u1ec7p",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2961",
            name: "V\u0129nh L\u1ea1c",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2962",
            name: "V\u0129nh L\u1ee3i",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2963", name: "V\u0129nh Quang", prefix: "Ph\u01b0\u1eddng" },
          { id: "2964", name: "V\u0129nh Thanh", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "2965",
            name: "V\u0129nh Th\u00f4ng",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "22023", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22024", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22025", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22026",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          { id: "22027", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "22028",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22029",
            name: "B\u00f9i Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22030",
            name: "B\u00f9i Vi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22031",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22032",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22033", name: "Cao L\u1ed7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22034",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22035",
            name: "Ch\u00e2u V\u0103n Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22036",
            name: "Chi L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22037",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22038",
            name: "C\u00f4 B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22039",
            name: "D\u00e3 T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22053",
            name: "D\u01b0\u01a1ng B\u00e1 Tr\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22054",
            name: "D\u01b0\u01a1ng B\u1ea1ch Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22055",
            name: "D\u01b0\u01a1ng Minh Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22040", name: "\u0110\u1eb7ng Dung", prefix: "Ph\u1ed1" },
          {
            id: "22041",
            name: "\u0110\u1eb7ng Huy\u1ec1n Th\u00f4ng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22042",
            name: "\u0110\u1eb7ng Thai Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22043",
            name: "\u0110\u1eb7ng Tr\u1ea7n C\u00f4n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22044",
            name: "\u0110\u1eb7ng V\u0103n Ng\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22045",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22046",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22047",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22048", name: "\u0110inh Li\u1ec7t", prefix: "Ph\u1ed1" },
          {
            id: "22049",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22050",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22051",
            name: "\u0110\u1ed1ng \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22052",
            name: "\u0110\u00f4ng H\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22056",
            name: "H\u00e0 Huy Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22057", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "22058",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22059",
            name: "H\u00e0n Thuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22060",
            name: "H\u1ed3 Th\u1ecb K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22061",
            name: "H\u1ed3 Thi\u1ec7n Ph\u00f3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22062",
            name: "H\u1ed3 V\u0103n Hu\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22063",
            name: "Hoa Bi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22064",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22065",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22066",
            name: "Ho\u00e0ng Xu\u00e2n H\u00e3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22067",
            name: "H\u1ecdc L\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22068",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22069",
            name: "Huy\u1ec1n Tr\u00e2n C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22070",
            name: "Hu\u1ef3nh T\u1ea5n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22071",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22072",
            name: "Hu\u1ef3nh T\u1ecbnh C\u1ee7a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22073",
            name: "Ki\u00ean Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22074",
            name: "L\u1ea1c H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22075",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22076",
            name: "L\u00e2m Quang Ky",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22077",
            name: "L\u00ea Anh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22078",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22079",
            name: "L\u00ea Kh\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22080",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22081",
            name: "L\u00ea Minh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22082",
            name: "L\u00ea Quang \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22083",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22084",
            name: "L\u00ea Th\u00e1nh T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22085",
            name: "L\u00ea Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22086",
            name: "L\u00ea V\u0103n H\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22087",
            name: "L\u00ea V\u0103n Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22088",
            name: "L\u00ea V\u0129nh H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22089",
            name: "Li\u00ean H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22090",
            name: "L\u01b0\u01a1ng T\u1ecbnh C\u1ee7a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22091",
            name: "L\u01b0\u01a1ng V\u0103n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22092",
            name: "L\u01b0u H\u1eefu Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22093",
            name: "L\u01b0u Qu\u00fd K\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22094",
            name: "L\u00fd Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22095",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22096",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22097",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22098",
            name: "M\u1ea1c C\u1eedu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22099",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22100",
            name: "Mai Th\u1ecb H\u1ed3ng H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22101",
            name: "Mai Th\u00fac Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22102",
            name: "Mai V\u0103n B\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22103",
            name: "M\u1eadu Th\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22104",
            name: "Ng\u00f4 \u0110\u1ee9c K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22105",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22106",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22107",
            name: "Ng\u00f4 T\u1ea5t T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22108",
            name: "Ng\u00f4 V\u0103n S\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22109",
            name: "Ng\u1ecdc H\u00e2n C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22110",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22111",
            name: "Nguy\u1ec5n Bi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22112",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22113",
            name: "Nguy\u1ec5n C\u1ea3nh Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22114",
            name: "Nguy\u1ec5n C\u1ea3nh D\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22115",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22116",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22117",
            name: "Nguy\u1ec5n C\u01b0 Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22118",
            name: "Nguy\u1ec5n \u0110\u1ed5ng Chi",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22119",
            name: "Nguy\u1ec5n Gia Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22120",
            name: "Nguy\u1ec5n Hi\u1ec1n \u0110i\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22121",
            name: "Nguy\u1ec5n H\u00f9ng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22122",
            name: "Nguy\u1ec5n Phi Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22123",
            name: "Nguy\u1ec5n Quang B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22124",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22125",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22126",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22127",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22128",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22129",
            name: "Nguy\u1ec5n Tho\u1ea1i H\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22130",
            name: "Nguy\u1ec5n Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22131",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22132",
            name: "Nguy\u1ec5n Trung Ng\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22133",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22134",
            name: "Nguy\u1ec5n Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22135",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22136",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22137",
            name: "Nguy\u1ec5n V\u0103n Ki\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22138",
            name: "Nguy\u1ec5n V\u0103n T\u1ed1",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22139",
            name: "Nh\u1eadt T\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22140",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22141",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22142",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22143",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22144",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22145",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22146",
            name: "Phan Huy Ch\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22147",
            name: "Phan Th\u1ecb R\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22148",
            name: "Phan V\u0103n H\u1edbn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22149",
            name: "Ph\u00f3 \u0110\u1ee9c Ch\u00ednh",
            prefix: "Ph\u1ed1",
          },
          { id: "22150", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          { id: "22151", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22152",
            name: "Qu\u1ed1c l\u1ed9 80",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22153", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22154", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22155", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22156", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22157", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22158", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22159", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22160", name: "S\u1ed1 27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22161", name: "S\u1ed1 28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22162", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22163", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22164",
            name: "S\u01b0 Thi\u1ec7n \u00c2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22165",
            name: "S\u01b0 V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22166",
            name: "S\u01b0\u01a1ng Nguy\u1ec7t \u00c1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22167",
            name: "Thi\u00ean H\u1ed9 D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22168",
            name: "Th\u1ee7 Khoa Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22169",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22170",
            name: "T\u00f4 V\u0129nh Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22171",
            name: "T\u00f4 V\u0129nh Di\u1ec7p l\u1ea5n bi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22172",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22173",
            name: "T\u00f4n Quang Huy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22174",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22175",
            name: "Tr\u1ea7n B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22176",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22177",
            name: "Tr\u1ea7n C\u00f4ng An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22178",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22179",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22180",
            name: "Tr\u1ea7n H\u1eefu Trang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22181",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22182",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22183",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22184",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22185",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22186",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22187",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22188",
            name: "Tr\u1ea7n Th\u1ee7 \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22189",
            name: "Tr\u1ea7n V\u0103n Chu\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22190",
            name: "Tr\u1ea7n V\u0103n Gi\u00e0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22191",
            name: "Tr\u1ecbnh Ho\u00e0i \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22192",
            name: "Tr\u01b0\u01a1ng C\u00f4ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22193",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22194",
            name: "Tr\u01b0\u01a1ng V\u0129nh K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22195", name: "T\u1ef1 Do", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22196",
            name: "T\u00fa X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22197",
            name: "T\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22198",
            name: "U Minh M\u01b0\u1eddi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22199", name: "V\u0103n Cao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22200",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22201",
            name: "V\u00f5 Tr\u01b0\u1eddng To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22202",
            name: "V\u0169 \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22203",
            name: "V\u0169 Tr\u1ecdng Ph\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22204",
            name: "Xuy\u00ean \u00c1",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2782",
            name: "Ph\u00fa C\u01b0\u1eddng",
            lat: "9.9650249481201",
            lng: "105.10034179688",
          },
          {
            id: "2783",
            name: "Vincom R\u1ea1ch Gi\u00e1",
            lat: "10.003265380859",
            lng: "105.0818862915",
          },
        ],
      },
      {
        id: "214",
        name: "T\u00e2n Hi\u1ec7p",
        wards: [
          { id: "2966", name: "T\u00e2n An", prefix: "X\u00e3" },
          {
            id: "2967",
            name: "T\u00e2n Hi\u1ec7p",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2968", name: "T\u00e2n Hi\u1ec7p A", prefix: "X\u00e3" },
          { id: "2969", name: "T\u00e2n Hi\u1ec7p B", prefix: "X\u00e3" },
          { id: "2970", name: "T\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "2971", name: "T\u00e2n H\u1ed9i", prefix: "X\u00e3" },
          { id: "2972", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "2973", name: "Th\u1ea1nh \u0110\u00f4ng", prefix: "X\u00e3" },
          {
            id: "2974",
            name: "Th\u1ea1nh \u0110\u00f4ng A",
            prefix: "X\u00e3",
          },
          {
            id: "2975",
            name: "Th\u1ea1nh \u0110\u00f4ng B",
            prefix: "X\u00e3",
          },
          { id: "2976", name: "Th\u1ea1nh Tr\u1ecb", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "22205",
            name: "\u0110\u1ed7 V\u0103n D\u1eady",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22206",
            name: "\u0110\u1ed3ng Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22207",
            name: "\u0110\u01b0\u1eddng \u0110\u00f2n D\u00f4ng BC",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22208",
            name: "\u0110\u01b0\u1eddng Kinh B1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22209",
            name: "\u0110\u01b0\u1eddng Kinh C1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22210",
            name: "Qu\u1ed1c l\u1ed9 80",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "215",
        name: "U minh Th\u01b0\u1ee3ng",
        wards: [
          { id: "2977", name: "An Minh B\u1eafc", prefix: "X\u00e3" },
          { id: "2978", name: "H\u00f2a Ch\u00e1nh", prefix: "X\u00e3" },
          { id: "2979", name: "Minh Thu\u1eadn", prefix: "X\u00e3" },
          { id: "2980", name: "Th\u1ea1nh Y\u00ean ", prefix: "X\u00e3" },
          { id: "2981", name: "Th\u1ea1nh Y\u00ean A", prefix: "X\u00e3" },
          { id: "2982", name: "V\u0129nh H\u00f2a ", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "216",
        name: "V\u0129nh Thu\u1eadn",
        wards: [
          { id: "2983", name: "B\u00ecnh Minh", prefix: "X\u00e3" },
          { id: "2984", name: "Phong \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "2985", name: "T\u00e2n Thu\u1eadn", prefix: "X\u00e3" },
          {
            id: "2986",
            name: "V\u0129nh B\u00ecnh B\u1eafc",
            prefix: "X\u00e3",
          },
          { id: "2987", name: "V\u0129nh B\u00ecnh Nam", prefix: "X\u00e3" },
          { id: "2988", name: "V\u0129nh Phong", prefix: "X\u00e3" },
          {
            id: "2989",
            name: "V\u0129nh Thu\u1eadn",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "2990", name: "V\u0129nh Thu\u1eadn", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "22211",
            name: "Qu\u1ed1c l\u1ed9 63",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "17",
    code: "BN",
    name: "B\u1eafc Ninh",
    districts: [
      {
        id: "217",
        name: "B\u1eafc Ninh",
        wards: [
          {
            id: "2991",
            name: "\u0110\u1ea1i Ph\u00fac",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2992",
            name: "\u0110\u00e1p C\u1ea7u",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "2993",
            name: "H\u1ea1p L\u0129nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "2994", name: "H\u00f2a Long", prefix: "X\u00e3" },
          { id: "2995", name: "Kh\u1eafc Ni\u1ec7m", prefix: "X\u00e3" },
          { id: "2996", name: "Kh\u00fac Xuy\u00ean", prefix: "X\u00e3" },
          { id: "2997", name: "Kim Ch\u00e2n", prefix: "X\u00e3" },
          { id: "2998", name: "Kinh B\u1eafc", prefix: "Ph\u01b0\u1eddng" },
          { id: "2999", name: "Nam S\u01a1n", prefix: "X\u00e3" },
          { id: "3000", name: "Ninh X\u00e1", prefix: "Ph\u01b0\u1eddng" },
          { id: "3001", name: "Phong Kh\u00ea", prefix: "X\u00e3" },
          { id: "3002", name: "Su\u1ed1i Hoa", prefix: "Ph\u01b0\u1eddng" },
          { id: "3003", name: "Th\u1ecb C\u1ea7u", prefix: "X\u00e3" },
          { id: "3004", name: "Ti\u1ec1n An", prefix: "X\u00e3" },
          { id: "3005", name: "V\u1ea1n An", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "3006",
            name: "V\u00e2n D\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3007", name: "V\u1ec7 An", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "3008",
            name: "V\u00f5 C\u01b0\u1eddng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3009", name: "V\u0169 Ninh", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "22212", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22213", name: "1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22214", name: "1B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22215", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22216", name: "286", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22217", name: "36", prefix: "Ph\u1ed1" },
          { id: "22218", name: "38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22219", name: "53", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22220", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22221", name: "715", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22222",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22223",
            name: "Ba\u0300 Chu\u0301a Kho",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22224",
            name: "Ba Huy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22225",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22226",
            name: "B\u00ecnh Than",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22227",
            name: "B\u00f2 S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22228",
            name: "B\u1ed3 S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22229",
            name: "B\u1ed3 S\u01a1n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22230",
            name: "B\u00f2 S\u01a1n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22231",
            name: "B\u00f2 S\u01a1n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22232",
            name: "B\u1ed3 S\u01a1n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22233",
            name: "B\u00f2 S\u01a1n 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22234",
            name: "B\u00f9i Xu\u00e2n Ph\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22235",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22236",
            name: "Cao L\u1ed7 V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22237",
            name: "Ch\u1ebf Lan Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22238", name: "Chu M\u1eabu", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22239",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22240",
            name: "C\u1ed5 M\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22241",
            name: "C\u1ed5ng Ti\u1ec1n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22242",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22243", name: "D18", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22256",
            name: "D\u01b0\u01a1ng \u00d4\u0309",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22244",
            name: "\u0110a C\u1ea5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22245",
            name: "\u0110\u1ea1i Ph\u00fac 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22246",
            name: "\u0110\u1ea1i Ph\u00fac 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22247",
            name: "\u0110\u1ea1i Ph\u00fac 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22248",
            name: "\u0110\u1ea1i Ph\u00fac 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22249",
            name: "\u0110\u1ea1i Ph\u00fac 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22250",
            name: "\u0110\u1ea1i Ph\u00fac 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22251",
            name: "\u0110\u1ea1i Ph\u00fac 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22252",
            name: "\u0110\u1ea1i Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22253",
            name: "\u0110\u0103ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22254",
            name: "\u0110\u1ea5u M\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22255",
            name: "\u0110\u1ed7 Tr\u1ecdng V\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22257",
            name: "Giang V\u0103n Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22258", name: "H", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22259", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          { id: "22260", name: "Hai V\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22261",
            name: "H\u00e0n M\u1eb7c T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22262",
            name: "H\u00e0n Thuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22263", name: "H\u00e0ng M\u00e3", prefix: "Ph\u1ed1" },
          {
            id: "22264",
            name: "H\u1ed3 Ng\u1ecdc L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22265",
            name: "H\u1ed3 Ng\u1ecdc L\u00e2n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22266",
            name: "H\u1ed3 Ng\u1ecdc L\u00e2n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22267",
            name: "H\u1ed3 Ng\u1ecdc L\u00e2n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22268",
            name: "H\u1ed3 Ng\u1ecdc L\u00e2n 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22269",
            name: "H\u00f2a \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22270",
            name: "H\u00f2a \u0110\u00ecnh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22271",
            name: "Ho\u00e0i Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22272",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22273",
            name: "Ho\u00e0ng Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22274",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22275",
            name: "Ho\u00e0ng T\u00edch Ch\u00f9",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22276",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22277",
            name: "Huy\u1ec1n Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22278",
            name: "Kh\u1ea3 L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22279",
            name: "Kh\u1ea3 L\u1ec5 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22280",
            name: "Kh\u1ea3 L\u1ec5 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22281",
            name: "Kh\u1ea3 L\u1ec5 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22282",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22283", name: "Kim L\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22284",
            name: "Kinh  B\u1eafc 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22285",
            name: "Kinh B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22286",
            name: "Kinh B\u1eafc 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22287",
            name: "Kinh B\u1eafc 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22288",
            name: "Kinh B\u1eafc 20",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22289",
            name: "Kinh B\u1eafc 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22290",
            name: "Kinh B\u1eafc 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22291",
            name: "Kinh B\u1eafc 42",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22292",
            name: "Kinh B\u1eafc 44",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22293",
            name: "Kinh B\u1eafc 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22294",
            name: "Kinh B\u1eafc 54",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22295",
            name: "Kinh B\u1eafc 55",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22296",
            name: "Kinh B\u1eafc 56",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22297",
            name: "Kinh B\u1eafc 57",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22298",
            name: "Kinh B\u1eafc 58",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22299",
            name: "Kinh B\u1eafc 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22300",
            name: "Kinh B\u1eafc 74",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22301",
            name: "Kinh B\u1eafc 76",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22302",
            name: "Kinh B\u1eafc 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22303",
            name: "Kinh B\u1eafc 86",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22304",
            name: "Kinh B\u1eafc 87",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22305",
            name: "Kinh D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22306",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22307",
            name: "L\u00e3m L\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22308",
            name: "L\u00e3m Tr\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22309",
            name: "L\u00e0ng Kh\u1ea3 L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22310",
            name: "L\u00ea Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22311",
            name: "L\u00ea \u0110\u00ecnh T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22312",
            name: "L\u00ea \u0110\u1ee9c Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22313",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22314", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22315",
            name: "L\u00ea Ph\u1ee5ng Hi\u1ec3u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22316",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22317",
            name: "L\u00ea Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22318",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22319",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22320",
            name: "L\u00ea V\u0103n Duy\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22321",
            name: "L\u00ea V\u0103n H\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22322",
            name: "L\u00ea V\u0103n Th\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22323",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22324", name: "Luy L\u00e2u", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22325",
            name: "L\u00fd Anh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22326",
            name: "L\u00fd Cao T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22327",
            name: "L\u00fd Chi\u00eau Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22328",
            name: "L\u00fd \u0110\u1ea1o Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22329",
            name: "L\u00fd Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22330",
            name: "L\u00fd Qu\u1ed1c S\u01b0",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22331",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22332",
            name: "L\u00fd Th\u00e1i T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22333",
            name: "L\u00fd Th\u1ea7n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22334",
            name: "L\u00fd Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22335",
            name: "L\u00fd Th\u1ebf T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22336",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22337",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22338", name: "Mai Bang", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22339", name: "Minh Khai", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22340", name: "Nam Cao", prefix: "Ph\u1ed1" },
          {
            id: "22341",
            name: "Ng\u00f4 Gia Kh\u1ea3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22342",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22343",
            name: "Ng\u00f4 Mi\u1ec5n Thi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22344",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22345",
            name: "Ng\u00f4 S\u1ef9 Li\u00ean",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22346",
            name: "Ng\u00f4 T\u1ea5t T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22347",
            name: "Ng\u00f4 Xu\u00e2n Qu\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22348",
            name: "Ng\u1ecdc H\u00e2n C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22349",
            name: "Nguy\u1ec5n Cao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22350",
            name: "Nguy\u1ec5n Chi\u00eau Hu\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22351",
            name: "Nguy\u1ec5n C\u00f4ng H\u00e3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22352",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22356",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22353",
            name: "Nguy\u1ec5n \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22354",
            name: "Nguy\u1ec5n \u0110\u0103ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22355",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22357",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22358",
            name: "Nguy\u1ec5n Gia Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22359",
            name: "Nguy\u1ec5n Gi\u1ea3n Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22360",
            name: "Nguy\u1ec5n H\u1eefu Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22361",
            name: "Nguy\u1ec5n H\u1eefu Nghi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22362",
            name: "Nguy\u1ec5n Huy T\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22363",
            name: "Nguy\u1ec5n Nh\u00e2n K\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22364",
            name: "Nguy\u1ec5n Quang Ca",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22365",
            name: "Nguy\u1ec5n Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22366",
            name: "Nguy\u1ec5n Th\u1ecb L\u1ef1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22367",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22368",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22369",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22370",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22371",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22372",
            name: "Nguy\u1ec5n V\u0103n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22373",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22374",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22375",
            name: "Nguy\u1ec5n Xu\u00e2n Ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22376", name: "Nh\u00e0 Chung", prefix: "Ph\u1ed1" },
          {
            id: "22377",
            name: "Ni\u1ec1m X\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22378",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22379",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22380",
            name: "Phan Huy Ch\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22381",
            name: "Ph\u1ed1 V\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22382",
            name: "Ph\u00f9 \u0110\u1ed5ng Thi\u00ean V\u01b0\u01a1ng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22383",
            name: "Ph\u01b0\u01a1ng C\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22384",
            name: "Ph\u01b0\u01a1ng V\u1ef9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22385",
            name: "Qu\u1ed1c L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22386",
            name: "Qu\u1ed1c l\u1ed9 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22387",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22388",
            name: "Qu\u1ed1c l\u1ed9 1B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22389",
            name: "Qu\u1ed1c L\u1ed9 38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22390",
            name: "R\u1ea1p H\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22391", name: "Sao Mai", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22392", name: "T\u1ea1 Quang B\u1eedu", prefix: "Ph\u1ed1" },
          {
            id: "22393",
            name: "T\u1ea3n \u0110\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22394",
            name: "Th\u00e0nh B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22395",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22396",
            name: "Thanh Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22397",
            name: "Th\u00e0nh S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22398",
            name: "Th\u1ecb Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22399",
            name: "Thi\u00ean \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22400",
            name: "Th\u01b0\u1ee3ng \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22401", name: "Ti\u1ec1n An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22402",
            name: "T\u1ec9nh L\u1ed9 278",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22403",
            name: "T\u1ec9nh l\u1ed9 286",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22404",
            name: "T\u1ec9nh l\u1ed9 291",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22405",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22406",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22407",
            name: "T\u00f4 Ng\u1ecdc V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22408",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22409",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22410",
            name: "Tr\u1ea7n Huy Li\u1ec5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22411",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22412",
            name: "Tr\u1ea7n L\u01b0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22413",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22414",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22415",
            name: "Tri\u1ec7u Vi\u1ec7t V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22416",
            name: "Tr\u01b0\u01a1ng V\u0103n L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22417", name: "V\u0103n Cao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22418",
            name: "V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22419", name: "V\u0103n Mi\u1ebfu", prefix: "Ph\u1ed1" },
          {
            id: "22420",
            name: "V\u00e2n Tr\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22421",
            name: "Vi\u1ec7t Trang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22422",
            name: "V\u00f5 C\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22423",
            name: "V\u00f5 C\u01b0\u1eddng 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22424",
            name: "V\u00f5 C\u01b0\u1eddng 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22425",
            name: "V\u00f5 C\u01b0\u1eddng 110",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22426",
            name: "V\u00f5 C\u01b0\u1eddng 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22427",
            name: "V\u00f5 C\u01b0\u1eddng 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22428",
            name: "V\u00f5 C\u01b0\u1eddng 38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22429",
            name: "V\u00f5 C\u01b0\u1eddng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22430",
            name: "V\u00f5 C\u01b0\u1eddng 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22431",
            name: "V\u00f5 C\u01b0\u1eddng 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22432",
            name: "V\u00f5 C\u01b0\u1eddng 73",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22433",
            name: "V\u00f5 C\u01b0\u1eddng 87",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22434",
            name: "V\u0169 \u0110\u1ea1i Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22435",
            name: "V\u0169 Gi\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22436",
            name: "V\u0169 Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22437",
            name: "V\u0169 Ninh 33",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22438",
            name: "V\u01b0\u01a1ng V\u0103n Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22439",
            name: "Xu\u00e2n \u00c1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22440",
            name: "Xu\u00e2n \u1ed4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22441",
            name: "Xu\u00e2n \u1ed4 A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22442",
            name: "Xu\u00e2n \u1ed4 B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22443", name: "Y Na", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22444", name: "Yna", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "2784",
            name: "Chung c\u01b0 C\u00e1t T\u01b0\u1eddng",
            lat: "21.168426513672",
            lng: "106.0558013916",
          },
          {
            id: "2785",
            name: "Dabaco V\u1ea1n An",
            lat: "21.17474937439",
            lng: "106.06307220459",
          },
          {
            id: "2786",
            name: "Him Lam B\u1eafc Ninh",
            lat: "21.173801422119",
            lng: "106.07871246338",
          },
          {
            id: "2787",
            name: "Ho\u00e0n S\u01a1n",
            lat: "21.111297607422",
            lng: "105.99062347412",
          },
          {
            id: "2788",
            name: "H\u1ee3p Ph\u00fa Complex",
            lat: "21.174081802368",
            lng: "106.06451416016",
          },
          {
            id: "2789",
            name: "Huy\u1ec1n Quang 2",
            lat: "21.164499282837",
            lng: "106.05519866943",
          },
          {
            id: "2790",
            name: "K\u0110T \u0110\u1ea1i Ho\u00e0ng Long",
            lat: "21.165115356445",
            lng: "106.0588684082",
          },
          {
            id: "2791",
            name: "K\u0110T Ph\u00fac Ninh",
            lat: "21.162912368774",
            lng: "106.06908416748",
          },
          {
            id: "2792",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            lat: "21.171550750732",
            lng: "106.05689239502",
          },
          {
            id: "2793",
            name: "M\u01b0\u1eddng Thanh B\u1eafc Ninh",
            lat: "21.183477401733",
            lng: "106.06602478027",
          },
          {
            id: "2794",
            name: "Nguy\u1ec5n Quy\u1ec1n Luxury",
            lat: "21.168327331543",
            lng: "106.07415771484",
          },
          {
            id: "2795",
            name: "Phoenix Tower",
            lat: "21.174118041992",
            lng: "106.06456756592",
          },
          {
            id: "2796",
            name: "Qu\u1ebf V\u00f5 Hill View",
            lat: "21.153001785278",
            lng: "106.11399078369",
          },
          {
            id: "2797",
            name: "Royal Park B\u1eafc Ninh",
            lat: "21.188262939453",
            lng: "106.07670593262",
          },
          {
            id: "2798",
            name: "Tr\u1ea7u Cau Garden",
            lat: "21.167476654053",
            lng: "106.05455780029",
          },
          {
            id: "2799",
            name: "V-City",
            lat: "21.16827583313",
            lng: "106.0553894043",
          },
          {
            id: "2800",
            name: "Vinhomes B\u1eafc Ninh",
            lat: "21.183204650879",
            lng: "106.07537078857",
          },
        ],
      },
      {
        id: "218",
        name: "Gia B\u00ecnh",
        wards: [
          { id: "3010", name: "B\u00ecnh D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "3011", name: "Cao \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "3012", name: "\u0110\u1ea1i B\u00e1i", prefix: "X\u00e3" },
          { id: "3013", name: "\u0110\u1ea1i Lai", prefix: "X\u00e3" },
          { id: "3014", name: "\u0110\u00f4ng C\u1ee9u", prefix: "X\u00e3" },
          { id: "3015", name: "Gia B\u00ecnh", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "3016", name: "Giang S\u01a1n", prefix: "X\u00e3" },
          { id: "3017", name: "L\u00e3ng Ng\u00e2m", prefix: "X\u00e3" },
          { id: "3018", name: "Nh\u00e2n Th\u1eafng", prefix: "X\u00e3" },
          { id: "3019", name: "Qu\u1ef3nh Ph\u00fa", prefix: "X\u00e3" },
          { id: "3020", name: "Song Giang", prefix: "X\u00e3" },
          { id: "3021", name: "Th\u00e1i B\u1ea3o", prefix: "X\u00e3" },
          { id: "3022", name: "V\u1ea1n Ninh", prefix: "X\u00e3" },
          { id: "3023", name: "Xu\u00e2n Lai", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "22445", name: "282", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22446",
            name: "Qu\u1ed1c l\u1ed9 228",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22447",
            name: "Thi\u00ean Thai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22448",
            name: "T\u1ec9nh l\u1ed9 280",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22449",
            name: "T\u1ec9nh l\u1ed9 282",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "219",
        name: "L\u01b0\u01a1ng T\u00e0i",
        wards: [
          { id: "3024", name: "An Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "3025", name: "B\u00ecnh \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "3026", name: "Lai H\u1ea1", prefix: "X\u00e3" },
          { id: "3027", name: "L\u00e2m Thao", prefix: "X\u00e3" },
          { id: "3028", name: "Minh T\u00e2n", prefix: "X\u00e3" },
          { id: "3029", name: "M\u1ef9 H\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "3030", name: "Ph\u00fa H\u00f2a", prefix: "X\u00e3" },
          { id: "3031", name: "Ph\u00fa L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "3032", name: "Qu\u1ea3ng Ph\u00fa", prefix: "X\u00e3" },
          { id: "3033", name: "T\u00e2n L\u00e3ng", prefix: "X\u00e3" },
          { id: "3034", name: "Th\u1ee9a", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "3035", name: "Trung Ch\u00ednh", prefix: "X\u00e3" },
          { id: "3036", name: "Trung K\u00eanh", prefix: "X\u00e3" },
          { id: "3037", name: "Tr\u1eebng X\u00e1", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "22450", name: "280", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22451",
            name: "Gi\u00e1p V\u0103n C\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22452",
            name: "L\u01b0\u01a1ng T\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22453",
            name: "Ph\u01b0\u01a1ng X\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22454",
            name: "T\u1ec9nh l\u1ed9 280",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22455",
            name: "T\u1ec9nh l\u1ed9 281",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22456",
            name: "T\u1ec9nh l\u1ed9 284",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22457",
            name: "T\u1ec9nh l\u1ed9 285",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "220",
        name: "Qu\u1ebf V\u00f5",
        wards: [
          { id: "3038", name: "B\u1eb1ng An", prefix: "X\u00e3" },
          { id: "3039", name: "B\u1ed3ng Lai", prefix: "X\u00e3" },
          { id: "3040", name: "C\u00e1ch Bi", prefix: "X\u00e3" },
          { id: "3041", name: "Ch\u00e2u Phong", prefix: "X\u00e3" },
          { id: "3042", name: "Chi L\u0103ng", prefix: "X\u00e3" },
          { id: "3043", name: "\u0110\u1ea1i Xu\u00e2n", prefix: "X\u00e3" },
          { id: "3044", name: "\u0110\u00e0o Vi\u00ean", prefix: "X\u00e3" },
          { id: "3045", name: "\u0110\u1ee9c Long", prefix: "X\u00e3" },
          { id: "3046", name: "H\u00e1n Qu\u1ea3ng", prefix: "X\u00e3" },
          { id: "3047", name: "M\u1ed9 \u0110\u1ea1o", prefix: "X\u00e3" },
          { id: "3048", name: "Ng\u1ecdc X\u00e1", prefix: "X\u00e3" },
          { id: "3049", name: "Nh\u00e2n Ho\u00e0", prefix: "X\u00e3" },
          {
            id: "3050",
            name: "Ph\u1ed1 M\u1edbi",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3051", name: "Ph\u00f9 L\u00e3ng", prefix: "X\u00e3" },
          { id: "3052", name: "Ph\u00f9 L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "3053", name: "Ph\u01b0\u01a1ng Li\u1ec5u", prefix: "X\u00e3" },
          { id: "3054", name: "Ph\u01b0\u1ee3ng Mao", prefix: "X\u00e3" },
          { id: "3055", name: "Qu\u1ebf T\u00e2n", prefix: "X\u00e3" },
          { id: "3056", name: "Vi\u1ec7t H\u00f9ng", prefix: "X\u00e3" },
          { id: "3057", name: "Vi\u1ec7t Th\u1ed1ng", prefix: "X\u00e3" },
          { id: "3058", name: "Y\u00ean Gi\u1ea3", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "22458", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22459", name: "24", prefix: "Ph\u1ed1" },
          { id: "22460", name: "291", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22461", name: "36", prefix: "Ph\u1ed1" },
          {
            id: "22462",
            name: "H\u00e0 Li\u1ec5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22463",
            name: "Mai C\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22464",
            name: "Qu\u1ed1c l\u1ed9 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22465",
            name: "T\u1ec9nh l\u1ed9 291",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2801",
            name: "KCN Qu\u1ebf V\u00f5",
            lat: "21.155899047852",
            lng: "106.11800384521",
          },
          {
            id: "2802",
            name: "KCN Qu\u1ebf V\u00f5 II",
            lat: "21.126417160034",
            lng: "106.2214050293",
          },
          {
            id: "2803",
            name: "KCN Qu\u1ebf V\u00f5 III",
            lat: "21.139947891235",
            lng: "106.17748260498",
          },
          {
            id: "2804",
            name: "Khu \u0111\u00f4 th\u1ecb m\u1edbi Qu\u1ebf V\u00f5",
            lat: "21.137731552124",
            lng: "106.18161773682",
          },
          {
            id: "2805",
            name: "Nh\u00e0 \u1edf x\u00e3 h\u1ed9i Sao H\u1ed3ng",
            lat: "21.142127990723",
            lng: "106.17360687256",
          },
        ],
      },
      {
        id: "221",
        name: "Thu\u1eadn Th\u00e0nh",
        wards: [
          { id: "3059", name: "An B\u00ecnh", prefix: "X\u00e3" },
          {
            id: "3060",
            name: "\u0110\u1ea1i \u0110\u1ed3ng Th\u00e0nh",
            prefix: "X\u00e3",
          },
          { id: "3061", name: "\u0110\u00ecnh T\u1ed5", prefix: "X\u00e3" },
          { id: "3062", name: "Gia \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "3063", name: "H\u00e0 M\u00e3n", prefix: "X\u00e3" },
          { id: "3064", name: "H\u1ed3", prefix: "Th\u1ecb tr\u1ea5n" },
          {
            id: "3065",
            name: "H\u1ed3 Huy\u1ec7n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3066", name: "Ho\u00e0i Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "3067", name: "M\u00e3o \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "3068", name: "Ngh\u0129a \u0110\u1ea1o", prefix: "X\u00e3" },
          { id: "3069", name: "Ng\u0169 Th\u00e1i", prefix: "X\u00e3" },
          { id: "3070", name: "Nguy\u1ec7t \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "3071", name: "Ninh X\u00e1", prefix: "X\u00e3" },
          { id: "3072", name: "Song H\u1ed3", prefix: "X\u00e3" },
          { id: "3073", name: "Song Li\u1ec5u", prefix: "X\u00e3" },
          { id: "3074", name: "Thanh Kh\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "3075", name: "Tr\u1ea1m L\u1ed9", prefix: "X\u00e3" },
          { id: "3076", name: "Tr\u00ed Qu\u1ea3", prefix: "X\u00e3" },
          { id: "3077", name: "Xu\u00e2n L\u00e2m", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "22466", name: "38", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22467",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22468",
            name: "C\u00f4ng H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22469",
            name: "\u0110\u00f4ng C\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22470", name: "Kh\u00e1m", prefix: "Ph\u1ed1" },
          { id: "22471", name: "Luy L\u00e2u", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22472", name: "Mai Bang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22473",
            name: "Nguy\u1ec5n Ch\u00ed T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22474",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22475",
            name: "Qu\u1ed1c L\u1ed9 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22476",
            name: "Qu\u1ed1c l\u1ed9 281",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22477",
            name: "Qu\u1ed1c L\u1ed9 282",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22478",
            name: "Qu\u1ed1c L\u1ed9 38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22479",
            name: "Qu\u1ed1c l\u1ed9 38B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22480", name: "Tam \u00c1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22481",
            name: "Thu\u1eadn Th\u00e0nh 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22482",
            name: "Thu\u1eadn Th\u00e0nh 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22483",
            name: "T\u1ec9nh l\u1ed9 280",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22484",
            name: "T\u1ec9nh l\u1ed9 282",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22485",
            name: "T\u1ec9nh L\u1ed9 283",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2806",
            name: "\u0110\u1ee9c Vi\u1ec7t",
            lat: "21.040996551514",
            lng: "106.07376098633",
          },
          {
            id: "2807",
            name: "Khu \u0111\u00f4 th\u1ecb DMC Thu\u1eadn Th\u00e0nh",
            lat: "21.035778045654",
            lng: "106.07925415039",
          },
          {
            id: "2808",
            name: "Little SaiGon",
            lat: "21.040420532227",
            lng: "106.08826446533",
          },
        ],
      },
      {
        id: "222",
        name: "Ti\u00ean Du",
        wards: [
          { id: "3078", name: "C\u1ea3nh H\u01b0ng", prefix: "X\u00e3" },
          {
            id: "3079",
            name: "\u0110\u1ea1i \u0110\u1ed3ng",
            prefix: "X\u00e3",
          },
          { id: "3080", name: "Hi\u00ean V\u00e2n", prefix: "X\u00e3" },
          { id: "3081", name: "Ho\u00e0n S\u01a1n", prefix: "X\u00e3" },
          { id: "3082", name: "L\u1ea1c V\u1ec7", prefix: "X\u00e3" },
          { id: "3083", name: "Li\u00ean B\u00e3o", prefix: "X\u00e3" },
          { id: "3084", name: "Lim", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "3085", name: "Minh \u0110\u1ea1o", prefix: "X\u00e3" },
          { id: "3086", name: "N\u1ed9i Du\u1ec7", prefix: "X\u00e3" },
          { id: "3087", name: "Ph\u1eadt T\u00edch", prefix: "X\u00e3" },
          { id: "3088", name: "Ph\u00fa L\u00e2m", prefix: "X\u00e3" },
          { id: "3089", name: "T\u00e2n Chi", prefix: "X\u00e3" },
          { id: "3090", name: "Tri Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "3091", name: "Vi\u1ec7t \u0110o\u00e0n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "22486", name: "295", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22487", name: "295B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22489",
            name: "D\u01b0\u01a1ng Hu\u0301c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22488",
            name: "\u0110\u1ea1i Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22490",
            name: "Ho\u00e0n S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22491",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          { id: "22492", name: "P", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22493",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22494",
            name: "Qu\u1ed1c L\u1ed9 38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22495",
            name: "T\u1ec9nh l\u1ed9 270",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22496",
            name: "T\u1ec9nh l\u1ed9 287",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22497", name: "TS3", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "2809",
            name: "KCN \u0110\u1ea1i \u0110\u1ed3ng - Ho\u00e0n S\u01a1n",
            lat: "21.101810455322",
            lng: "105.99583435059",
          },
          {
            id: "2810",
            name: "Ti\u00ean S\u01a1n B\u1eafc Ninh",
            lat: "21.121913909912",
            lng: "106.00064086914",
          },
        ],
      },
      {
        id: "223",
        name: "T\u1eeb S\u01a1n",
        wards: [
          {
            id: "3092",
            name: "Ch\u00e2u Kh\u00ea",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "3093",
            name: "\u0110\u00ecnh B\u1ea3ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "3094",
            name: "\u0110\u1ed3ng K\u1ef5",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "3095",
            name: "\u0110\u00f4ng Ng\u00e0n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "3096",
            name: "\u0110\u1ed3ng Nguy\u00ean",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3097", name: "\u0110\u1ed3ng Nguy\u00ean", prefix: "X\u00e3" },
          { id: "3098", name: "H\u01b0\u01a1ng M\u1ea1c", prefix: "X\u00e3" },
          { id: "3099", name: "Ph\u00f9 Ch\u1ea9n", prefix: "X\u00e3" },
          { id: "3100", name: "Ph\u00f9 Kh\u00ea", prefix: "X\u00e3" },
          { id: "3101", name: "Tam S\u01a1n", prefix: "X\u00e3" },
          {
            id: "3102",
            name: "T\u00e2n H\u1ed3ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3103", name: "Trang H\u1ea1", prefix: "Ph\u01b0\u1eddng" },
          { id: "3104", name: "T\u01b0\u01a1ng Giang", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "22498", name: "1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22499", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22500", name: "B\u00ednh H\u1ea1", prefix: "Ph\u1ed1" },
          {
            id: "22501",
            name: "\u0110a H\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22502",
            name: "\u0110a\u0323i \u0110i\u0300nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22503", name: "HN2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22504",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22505",
            name: "H\u1eefu Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22506",
            name: "L\u00ea Quang \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22507", name: "Long V\u0129", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22508",
            name: "L\u00fd \u0110\u1ea1o Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22509",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22510",
            name: "L\u00fd Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22511",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          { id: "22512", name: "Minh Khai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22513",
            name: "Ngo\u1ea1i Th\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22514",
            name: "Nguy\u1ec5n C\u00f4ng H\u00e3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22515",
            name: "Nguy\u1ec5n Gi\u00e1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22516",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22517", name: "Ph\u00f9 L\u01b0u", prefix: "Ph\u1ed1" },
          {
            id: "22518",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22519", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22520", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22521",
            name: "T\u1ec9nh l\u1ed9 179",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22522",
            name: "T\u1ec9nh l\u1ed9 295",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22523",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22524",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22525", name: "Vi\u1ec1ng", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22526",
            name: "Y\u00ean L\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2811",
            name: "Ba Gia",
            lat: "21.085304260254",
            lng: "105.94174194336",
          },
          {
            id: "2812",
            name: "\u0110\u1ec1n \u0110\u00f4",
            lat: "21.101877212524",
            lng: "105.96195983887",
          },
          {
            id: "2813",
            name: "K\u0110T \u0110\u1ed3ng Nguy\u00ean",
            lat: "21.120679855347",
            lng: "105.9743270874",
          },
          {
            id: "2814",
            name: "K\u0110T T\u00e2n H\u1ed3ng - \u0110\u00f4ng Ng\u00e0n",
            lat: "21.116777420044",
            lng: "105.96204376221",
          },
          {
            id: "2815",
            name: "Sing Garden",
            lat: "21.075969696045",
            lng: "105.97605895996",
          },
          {
            id: "2816",
            name: "VSIP B\u1eafc Ninh",
            lat: "21.090768814087",
            lng: "105.96424102783",
          },
        ],
      },
      {
        id: "224",
        name: "Y\u00ean Phong",
        wards: [
          { id: "3105", name: "Ch\u1edd", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "3109", name: "D\u0169ng Li\u1ec7t", prefix: "X\u00e3" },
          { id: "3106", name: "\u0110\u00f4ng Phong", prefix: "X\u00e3" },
          { id: "3107", name: "\u0110\u00f4ng Th\u1ecd", prefix: "X\u00e3" },
          { id: "3108", name: "\u0110\u00f4ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "3110", name: "H\u00f2a Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "3111", name: "Long Ch\u00e2u", prefix: "X\u00e3" },
          { id: "3112", name: "Tam \u0110a", prefix: "X\u00e3" },
          { id: "3113", name: "Tam Giang", prefix: "X\u00e3" },
          { id: "3114", name: "Th\u1ee5y H\u00f2a", prefix: "X\u00e3" },
          { id: "3115", name: "Trung Ngh\u0129a", prefix: "X\u00e3" },
          { id: "3116", name: "V\u0103n M\u00f4n", prefix: "X\u00e3" },
          { id: "3117", name: "Y\u00ean Ph\u1ee5", prefix: "X\u00e3" },
          { id: "3118", name: "Y\u00ean Trung", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "22527", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22528", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22529", name: "286", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22530", name: "295", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22531",
            name: "Qu\u1ed1c l\u1ed9 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22532",
            name: "T\u1ec9nh L\u1ed9 277",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22533",
            name: "T\u1ec9nh l\u1ed9 286",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22534",
            name: "T\u1ec9nh l\u1ed9 295",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2817",
            name: "Nh\u00e0 \u1edf x\u00e3 h\u1ed9i B\u1eafc K\u1ef3",
            lat: "21.220272064209",
            lng: "105.98761749268",
          },
          {
            id: "2818",
            name: "Susan Y\u00ean Phong",
            lat: "21.200164794922",
            lng: "105.99954986572",
          },
          {
            id: "2819",
            name: "Y\u00ean Phong B\u1eafc Ninh",
            lat: "21.199089050293",
            lng: "105.99639129639",
          },
        ],
      },
    ],
  },
  {
    id: "18",
    code: "QNI",
    name: "Qu\u1ea3ng Ninh",
    districts: [
      {
        id: "225",
        name: "Ba Ch\u1ebd",
        wards: [
          { id: "3119", name: "Ba Ch\u1ebd", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "3120", name: "\u0110\u1ea1p Thanh", prefix: "X\u00e3" },
          {
            id: "3121",
            name: "\u0110\u1ed3n \u0110\u1ea1c",
            prefix: "X\u00e3",
          },
          { id: "3122", name: "L\u01b0\u01a1ng M\u00f4ng", prefix: "X\u00e3" },
          { id: "3123", name: "Minh C\u1ea7m", prefix: "X\u00e3" },
          { id: "3124", name: "Nam S\u01a1n", prefix: "X\u00e3" },
          { id: "3125", name: "Thanh L\u00e2m", prefix: "X\u00e3" },
          { id: "3126", name: "Thanh S\u01a1n", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "226",
        name: "B\u00ecnh Li\u00eau",
        wards: [
          {
            id: "3127",
            name: "B\u00ecnh Li\u00eau",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3128", name: "\u0110\u1ed3ng T\u00e2m", prefix: "X\u00e3" },
          { id: "3129", name: "\u0110\u1ed3ng V\u0103n", prefix: "X\u00e3" },
          { id: "3130", name: "Ho\u00e0nh M\u00f4", prefix: "X\u00e3" },
          { id: "3131", name: "H\u00fac \u0110\u1ed9ng", prefix: "X\u00e3" },
          { id: "3132", name: "L\u1ee5c H\u1ed3n", prefix: "X\u00e3" },
          { id: "3133", name: "T\u00ecnh H\u00fac", prefix: "X\u00e3" },
          { id: "3134", name: "V\u00f4 Ng\u1ea1i", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "227",
        name: "C\u1ea9m Ph\u1ea3",
        wards: [
          {
            id: "3135",
            name: "C\u1ea9m B\u00ecnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "3136",
            name: "C\u1ea9m \u0110\u00f4ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3137", name: "C\u1ea9m H\u1ea3i", prefix: "X\u00e3" },
          { id: "3138", name: "C\u1ea9m Ph\u00fa", prefix: "Ph\u01b0\u1eddng" },
          { id: "3139", name: "C\u1ea9m S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "3140", name: "C\u1ea9m T\u00e2y", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "3141",
            name: "C\u1ea9m Th\u1ea1ch",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "3142",
            name: "C\u1ea9m Th\u00e0nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "3143",
            name: "C\u1ea9m Th\u1ecbnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "3144",
            name: "C\u1ea9m Thu\u1ef7",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3145", name: "C\u1ea9m Trung", prefix: "Ph\u01b0\u1eddng" },
          { id: "3146", name: "C\u1ed9ng Ho\u00e0", prefix: "X\u00e3" },
          { id: "3147", name: "C\u1eeda \u00d4ng", prefix: "Ph\u01b0\u1eddng" },
          { id: "3148", name: "D\u01b0\u01a1ng Huy", prefix: "X\u00e3" },
          {
            id: "3149",
            name: "M\u00f4ng D\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3150", name: "Quang Hanh", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "22535", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22536", name: "18A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22537", name: "22/11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22538", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "22539",
            name: "B\u00e1i T\u1eed Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22540",
            name: "C\u1ea9m B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22541",
            name: "C\u1ea9m \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22542",
            name: "\u0110\u1eb7ng Ch\u00e2u Tu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22543",
            name: "\u0110\u1ea5t \u0110\u1ecf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22544",
            name: "\u0110o\u00e0n K\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22545",
            name: "\u0110\u1ed9c L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22546",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22547",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22548",
            name: "H\u1ed3ng Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22549",
            name: "L\u00ea L\u1ee3i A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22550", name: "L\u00ea Thanh Ngh\u1ecb", prefix: "Ph\u1ed1" },
          {
            id: "22551",
            name: "L\u00fd B\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22552",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22553",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22554",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22555",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22556",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22557",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22558",
            name: "Qu\u1ed1c l\u1ed9 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22559",
            name: "Qu\u1ed1c l\u1ed9 18A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22560",
            name: "Qu\u1ed1c L\u1ed9 18B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22561",
            name: "R\u1eb7ng D\u1eeba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22562",
            name: "T\u00e2n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22563",
            name: "Th\u1eafng L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22564",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22565",
            name: "Th\u1ecb \u0110\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22566",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22567",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22568",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22569",
            name: "Tr\u1ea7n Qu\u1ed1c T\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22570",
            name: "\u01af Huy T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22571",
            name: "V\u00f5 Huy T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22572",
            name: "V\u0169 V\u0103n Hi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22573",
            name: "V\u0169ng \u0110\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2820",
            name: "Khu \u0111\u00f4 th\u1ecb Qu\u1ea3ng H\u1ed3ng",
            lat: "21.00431060791",
            lng: "107.31060791016",
          },
        ],
      },
      {
        id: "228",
        name: "C\u00f4 T\u00f4",
        wards: [
          { id: "3151", name: "C\u00f4 T\u00f4", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "3152", name: "\u0110\u1ea3o Tr\u1ea7n", prefix: "X\u00e3" },
          { id: "3153", name: "\u0110\u1ed3ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "3154", name: "Thanh L\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [{ id: "22574", name: "H\u1ed3ng V\u00e2n", prefix: "Ph\u1ed1" }],
        projects: [],
      },
      {
        id: "229",
        name: "\u0110\u1ea7m H\u00e0",
        wards: [
          { id: "3158", name: "D\u1ef1c Y\u00ean", prefix: "X\u00e3" },
          { id: "3155", name: "\u0110\u1ea1i B\u00ecnh", prefix: "X\u00e3" },
          {
            id: "3156",
            name: "\u0110\u1ea7m H\u00e0",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3157", name: "\u0110\u1ea7m H\u00e0", prefix: "X\u00e3" },
          { id: "3159", name: "Qu\u1ea3ng An", prefix: "X\u00e3" },
          { id: "3160", name: "Qu\u1ea3ng L\u00e2m", prefix: "X\u00e3" },
          { id: "3161", name: "Qu\u1ea3ng L\u1ee3i", prefix: "X\u00e3" },
          { id: "3162", name: "Qu\u1ea3ng T\u00e2n", prefix: "X\u00e3" },
          { id: "3163", name: "T\u00e2n B\u00ecnh", prefix: "X\u00e3" },
          { id: "3164", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "22575",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "230",
        name: "\u0110\u00f4ng Tri\u1ec1u",
        wards: [
          { id: "3165", name: "An Sinh", prefix: "X\u00e3" },
          { id: "3166", name: "B\u00ecnh D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "3167", name: "B\u00ecnh Kh\u00ea", prefix: "X\u00e3" },
          {
            id: "3168",
            name: "\u0110\u00f4ng Tri\u1ec1u",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3169", name: "\u0110\u1ee9c Ch\u00ednh", prefix: "X\u00e3" },
          { id: "3170", name: "Ho\u00e0ng Qu\u1ebf", prefix: "X\u00e3" },
          { id: "3171", name: "H\u1ed3ng Phong", prefix: "X\u00e3" },
          {
            id: "3172",
            name: "H\u1ed3ng Th\u00e1i \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "3173",
            name: "H\u1ed3ng Th\u00e1i T\u00e2y",
            prefix: "X\u00e3",
          },
          { id: "3174", name: "H\u01b0ng \u0110\u1ea1o", prefix: "X\u00e3" },
          { id: "3175", name: "Kim S\u01a1n", prefix: "X\u00e3" },
          {
            id: "3176",
            name: "M\u1ea1o Kh\u00ea",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3177", name: "Nguy\u1ec5n Hu\u1ec7", prefix: "X\u00e3" },
          { id: "3178", name: "T\u00e2n Vi\u1ec7t", prefix: "X\u00e3" },
          { id: "3179", name: "Th\u1ee7y An", prefix: "X\u00e3" },
          { id: "3180", name: "Tr\u00e0ng An", prefix: "X\u00e3" },
          { id: "3181", name: "Tr\u00e0ng L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "3182", name: "Vi\u1ec7t D\u00e2n", prefix: "X\u00e3" },
          { id: "3183", name: "Xu\u00e2n S\u01a1n", prefix: "X\u00e3" },
          { id: "3184", name: "Y\u00ean \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "3185", name: "Y\u00ean Th\u1ecd", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "22576",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22577",
            name: "Ho\u00e0ng Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22578",
            name: "L\u00ean Mo\u0309",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22579",
            name: "Nguy\u1ec5n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22580",
            name: "Qu\u1ed1c l\u1ed9 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22581",
            name: "V\u01b0\u1eddn Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "231",
        name: "H\u1ea1 Long",
        wards: [
          {
            id: "3186",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "3187",
            name: "B\u00e3i Ch\u00e1y",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3188", name: "Cao Th\u1eafng", prefix: "Ph\u01b0\u1eddng" },
          { id: "3189", name: "Cao Xanh", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "3190",
            name: "\u0110\u1ea1i Y\u00ean",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "3191",
            name: "Gi\u1ebfng \u0110\u00e1y",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "3192",
            name: "H\u00e0 Kh\u00e1nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3193", name: "H\u00e0 Kh\u1ea9u", prefix: "Ph\u01b0\u1eddng" },
          { id: "3194", name: "H\u00e0 L\u1ea7m", prefix: "Ph\u01b0\u1eddng" },
          { id: "3195", name: "H\u00e0 Phong", prefix: "Ph\u01b0\u1eddng" },
          { id: "3196", name: "H\u00e0 Trung", prefix: "Ph\u01b0\u1eddng" },
          { id: "3197", name: "H\u00e0 Tu", prefix: "Ph\u01b0\u1eddng" },
          { id: "3198", name: "H\u1ed3ng Gai", prefix: "Ph\u01b0\u1eddng" },
          { id: "3199", name: "H\u1ed3ng H\u00e0", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "3200",
            name: "H\u1ed3ng H\u1ea3i",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "3201",
            name: "H\u00f9ng Th\u1eafng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "3202",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "3203",
            name: "Tu\u1ea7n Ch\u00e2u",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "3204",
            name: "Vi\u1ec7t H\u01b0ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "3205",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "22582", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22583", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22584", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22585", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22586", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22587", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22588", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22589", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22590", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22591", name: "18A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22592", name: "18B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22593", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22594", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22595", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22596", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22597", name: "227", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22598", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22599", name: "24", prefix: "Ph\u1ed1" },
          { id: "22600", name: "25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22601", name: "25/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22602", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22603", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22604", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22605", name: "33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22606", name: "336", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22607", name: "337", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22608", name: "34", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22609", name: "35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22610", name: "36", prefix: "Ph\u1ed1" },
          { id: "22611", name: "37", prefix: "Ph\u1ed1" },
          { id: "22612", name: "38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22613", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22614", name: "40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22615", name: "41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22616", name: "42", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22617", name: "43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22618", name: "44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22619", name: "45", prefix: "Ph\u1ed1" },
          { id: "22620", name: "46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22621", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22622", name: "48", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22623", name: "49", prefix: "Ph\u1ed1" },
          { id: "22624", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22625", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22626", name: "51", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22627", name: "52", prefix: "Ph\u1ed1" },
          { id: "22628", name: "53", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22629", name: "54", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22630", name: "55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22631", name: "56", prefix: "Ph\u1ed1" },
          { id: "22632", name: "57", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22633", name: "58", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22634", name: "59", prefix: "Ph\u1ed1" },
          { id: "22635", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22636", name: "60", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22637", name: "61", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22638", name: "64", prefix: "Ph\u1ed1" },
          { id: "22639", name: "65", prefix: "Ph\u1ed1" },
          { id: "22640", name: "66", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22641", name: "67", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22642", name: "68", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22643", name: "69", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22644", name: "70", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22645", name: "72", prefix: "Ph\u1ed1" },
          { id: "22646", name: "74", prefix: "Ph\u1ed1" },
          { id: "22647", name: "75", prefix: "Ph\u1ed1" },
          { id: "22648", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22649", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22650",
            name: "Anh \u0110\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22651",
            name: "\u00c2u L\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22652",
            name: "\u00c2u T\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22653",
            name: "Ba \u0110\u00e8o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22654", name: "Ba H\u1ea7m", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22655", name: "Ba Hang", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22656", name: "Ba Lan", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22657", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "22658",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22659",
            name: "B\u00e3i Ch\u00e1y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22660",
            name: "B\u00e3i Mu\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22661",
            name: "B\u00e0i Th\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22662",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22663",
            name: "B\u1ebfn \u0110oan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22664",
            name: "B\u00ecnh Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22665",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22666",
            name: "C\u00e1i B\u00e8o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22667",
            name: "C\u00e1i D\u0103m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22668",
            name: "C\u00e1i L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22669",
            name: "C\u00e1nh Bu\u1ed3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22670",
            name: "Cao \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22671", name: "Cao Mang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22672",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22673", name: "Cao Xanh", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22674", name: "C\u1ea7u G\u1ed7", prefix: "Ph\u1ed1" },
          { id: "22675", name: "Chu S\u0129", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22676",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22677", name: "Cienco", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22678", name: "C\u1ed9t 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22679", name: "C\u1ed9t 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22680", name: "C\u1ed9t 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22681", name: "C\u1ed9t 8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22682",
            name: "C\u1eeda L\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22683",
            name: "D\u00e2n Ch\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22684",
            name: "D\u00e2n Sinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22692", name: "D\u1ed1c H\u1ecdc", prefix: "Ph\u1ed1" },
          {
            id: "22699",
            name: "D\u01b0\u01a1ng \u0110\u00ecnh Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22700", name: "Duy T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22685",
            name: "\u0110\u1eb7ng B\u00e1 H\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22686",
            name: "\u0110\u1ea7u G\u1ed7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22687",
            name: "\u0110\u00ea Bao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22688", name: "\u0110inh Li\u1ec7t", prefix: "Ph\u1ed1" },
          {
            id: "22689",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22690",
            name: "\u0110o\u00e0n K\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22691",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22693",
            name: "\u0110\u1ed9i C\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22694",
            name: "\u0110\u1ed3i Ng\u00e2n H\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22695",
            name: "\u0110\u1ed3i V\u0103n Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22696",
            name: "\u0110\u00f4ng Ga",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22697",
            name: "\u0110\u00f4ng H\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22698",
            name: "\u0110\u1ed3ng Mang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22701",
            name: "Giang V\u0103n Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22702",
            name: "Gi\u1ebfng \u0110\u00e1y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22703",
            name: "Gi\u1ebfng \u0110\u1ed3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22704",
            name: "H\u00e0 Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22705",
            name: "H\u00e0 L\u1ea7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22706", name: "H\u1ea1 Long", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22707",
            name: "H\u1ea3i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22708",
            name: "H\u1ea3i \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22709",
            name: "H\u1ea3i H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22710",
            name: "H\u1ea3i L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22711",
            name: "H\u1ea3i Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22712",
            name: "H\u1ea3i Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22713",
            name: "H\u1ea3i Ph\u00f2ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22714",
            name: "H\u1ea3i Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22715",
            name: "H\u1ea3i Ph\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22716",
            name: "H\u1ea3i Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22717",
            name: "H\u1ea3i S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22718",
            name: "H\u1ea3i Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22719",
            name: "H\u1ea3i Th\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22720",
            name: "H\u1ea3i Tr\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22721",
            name: "H\u1ea3i V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22722",
            name: "H\u00e0ng N\u1ed3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22723",
            name: "H\u00e0ng Than",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22724",
            name: "H\u1eadu C\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22725",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22726",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22727",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22728",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22729",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22730",
            name: "Ho\u00e0ng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22731",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22732",
            name: "H\u1ed3ng Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22733",
            name: "H\u1ed3ng Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22734", name: "H\u1ed3ng V\u00e2n", prefix: "Ph\u1ed1" },
          {
            id: "22735",
            name: "H\u00f9ng Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22736",
            name: "H\u01b0\u1edbng D\u01b0\u01a1ng 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22737",
            name: "H\u01b0\u01a1ng l\u1ed9 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22738",
            name: "H\u1eefu Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22739",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22740",
            name: "K\u00eanh Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22741",
            name: "K\u00eanh Li\u00eam Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22742",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22743", name: "Kim Hoan", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22744",
            name: "L\u00e3 V\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22745",
            name: "L\u00ea Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22746", name: "L\u00ea Dung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22747",
            name: "L\u00ea Ho\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22748",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22749", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22750",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22751",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22752",
            name: "L\u00ea S\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22753", name: "L\u00ea Thanh Ngh\u1ecb", prefix: "Ph\u1ed1" },
          {
            id: "22754",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22755",
            name: "L\u00ea V\u0103n T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22756",
            name: "L\u00ea V\u0103n T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22757",
            name: "Long Ti\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22758",
            name: "L\u01b0 H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22759",
            name: "L\u1ee5c H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22760",
            name: "L\u1ee5c Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22761",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22762",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22763", name: "M2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22764", name: "M\u00ea Cung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22765",
            name: "Minh Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22766", name: "Minh H\u00e0", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22767", name: "Minh Khai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22768",
            name: "N\u0103m C\u1ed1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22769",
            name: "Nghi\u00eau Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22770",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22771",
            name: "Ng\u00f4 S\u1ef9 Li\u00ean",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22772",
            name: "Ng\u00f4 V\u0103n S\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22773",
            name: "Ng\u1ecdc Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22774",
            name: "Ng\u1ecdc V\u1eebng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22775",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22776",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22777",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22778",
            name: "Nguy\u1ec5n Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22779", name: "Nguy\u1ec5n Si\u00eau", prefix: "Ph\u1ed1" },
          {
            id: "22780",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22781",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22782",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22783",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22784",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22785",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22786",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22787", name: "Nguy\u1ec5n X\u00ed", prefix: "Ph\u1ed1" },
          {
            id: "22788",
            name: "N\u00fai H\u1ea1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22789",
            name: "N\u00fai X\u1ebb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22790",
            name: "\u00d4ng \u00cdch Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22791",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22792",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22793",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22794", name: "Ph\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22795",
            name: "Ph\u01b0\u1ee3ng Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22796",
            name: "Qu\u1ea3ng Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22797",
            name: "Qu\u1ed1c l\u1ed9 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22798",
            name: "Qu\u1ed1c l\u1ed9 18A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22799",
            name: "Qu\u1ed1c l\u1ed9 279",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22800",
            name: "Qu\u1ed1c l\u1ed9 63",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22801",
            name: "Qu\u1ed1c l\u1ed9 71",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22802", name: "Soi Nh\u1ee5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22803", name: "Soi Sim", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22804",
            name: "Su\u1ed1i M\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22805", name: "Tam Cung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22806",
            name: "T\u00e2n L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22807",
            name: "Th\u1eafng L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22808",
            name: "Th\u00e0nh C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22809",
            name: "Thi\u00ean Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22810",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22811",
            name: "Ti\u00eau Dao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22812",
            name: "T\u1ec9nh L\u1ed9 337",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22813",
            name: "T\u1ec9nh l\u1ed9 73",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22814",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22815",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22816",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22817",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22818",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22819",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22820",
            name: "Tr\u1ea7n Qu\u1ed1c Nghi\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22821",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22822",
            name: "Tr\u1ea7n Th\u00e1i T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22823",
            name: "Tri\u1ec7u Vi\u1ec7t V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22824",
            name: "Trinh N\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22825",
            name: "Tr\u1ed1ng M\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22826",
            name: "Tr\u01b0\u1eddng \u0110o\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22827",
            name: "Tuy\u1ec3n Than",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22828",
            name: "V\u0103n Lang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22829",
            name: "V\u1ea1n Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22830", name: "Vinh Quang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22831",
            name: "V\u0169 V\u0103n Hi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22832",
            name: "V\u1ef1ng \u0110\u00e2ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22833",
            name: "V\u0169ng T\u00e0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22834",
            name: "Vung Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22835",
            name: "V\u01b0\u1eddn \u0110\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22836",
            name: "V\u01b0\u1eddn V\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22837",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2821",
            name: "\u00c1nh D\u01b0\u01a1ng",
            lat: "20.958255767822",
            lng: "107.00687408447",
          },
          {
            id: "2822",
            name: "Beverly Hills H\u1ea1 Long",
            lat: "20.966913223267",
            lng: "107.04919433594",
          },
          {
            id: "2823",
            name: "Bi\u1ec7t th\u1ef1 \u0111\u1ed3i Th\u1ee7y S\u1ea3n",
            lat: "20.959184646606",
            lng: "107.04795074463",
          },
          {
            id: "2824",
            name: "Cao Xanh H\u00e0 Kh\u00e1nh",
            lat: "20.988622665405",
            lng: "107.09577941895",
          },
          {
            id: "2825",
            name: "Citadines Marina H\u1ea1 Long",
            lat: "20.955322265625",
            lng: "107.01278686523",
          },
          {
            id: "2828",
            name: "Dragon Hill City",
            lat: "20.9703540802",
            lng: "107.03309631348",
          },
          {
            id: "2826",
            name: "\u0110\u00f4ng B\u1eafc Goldland Plaza",
            lat: "20.95072555542",
            lng: "107.13251495361",
          },
          {
            id: "2827",
            name: "\u0110\u00f4ng H\u00f9ng Th\u1eafng",
            lat: "20.954540252686",
            lng: "107.01808166504",
          },
          {
            id: "2829",
            name: "FLC Grand Hotel H\u1ea1 Long",
            lat: "20.941778182983",
            lng: "107.12777709961",
          },
          {
            id: "2830",
            name: "FLC H\u1ea1 Long",
            lat: "20.95167350769",
            lng: "107.115234375",
          },
          {
            id: "2831",
            name: "Green Bay Garden",
            lat: "20.957677841187",
            lng: "107.00080108643",
          },
          {
            id: "2832",
            name: "Green Bay Premium",
            lat: "20.958995819092",
            lng: "107.00533294678",
          },
          {
            id: "2833",
            name: "Green Bay Towers",
            lat: "20.958494186401",
            lng: "107.00666809082",
          },
          {
            id: "2834",
            name: "Green bay village",
            lat: "20.958393096924",
            lng: "107.00932312012",
          },
          {
            id: "2835",
            name: "H\u1ea1 Long Central Plaza",
            lat: "20.949960708618",
            lng: "107.10028076172",
          },
          {
            id: "2836",
            name: "H\u1ea1 Long Marina",
            lat: "20.960851669312",
            lng: "107.00157928467",
          },
          {
            id: "2837",
            name: "KDC \u0110\u1ed3i Ng\u00e2n H\u00e0ng",
            lat: "20.953506469727",
            lng: "107.10205078125",
          },
          {
            id: "2838",
            name: "Khu \u0111\u00f4 th\u1ecb B\u00e3i Mu\u1ed1i",
            lat: "20.96643447876",
            lng: "107.09856414795",
          },
          {
            id: "2839",
            name: "L\u1ea1c H\u1ed3ng Lotus H\u1ea1 Long",
            lat: "20.958072662354",
            lng: "107.08098602295",
          },
          {
            id: "2840",
            name: "Licogi 18.1 Tower",
            lat: "20.937370300293",
            lng: "107.12561798096",
          },
          {
            id: "2841",
            name: "Lideco Qu\u1ea3ng Ninh",
            lat: "20.955533981323",
            lng: "107.08549499512",
          },
          {
            id: "2842",
            name: "Lotus Residences (V\u1ea1n Li\u00ean)",
            lat: "20.955488204956",
            lng: "107.00032043457",
          },
          {
            id: "2843",
            name: "Mandaville H\u1ea1 Long",
            lat: "20.961349487305",
            lng: "107.05864715576",
          },
          {
            id: "2844",
            name: "Mon Bay H\u1ea1 Long",
            lat: "20.949951171875",
            lng: "107.09691619873",
          },
          {
            id: "2845",
            name: "Monaco H\u1ea1 Long",
            lat: "20.971460342407",
            lng: "107.02354431152",
          },
          {
            id: "2846",
            name: "New Life Tower",
            lat: "20.952793121338",
            lng: "107.0239944458",
          },
          {
            id: "2847",
            name: "Premier Village H\u1ea1 Long",
            lat: "20.964384078979",
            lng: "107.04803466797",
          },
          {
            id: "2848",
            name: "San H\u00f4",
            lat: "20.956750869751",
            lng: "107.00625610352",
          },
          {
            id: "2849",
            name: "Sun World Ha Long Park",
            lat: "20.964384078979",
            lng: "107.04803466797",
          },
          {
            id: "2850",
            name: "T\u00e2y H\u00f9ng Th\u1eafng",
            lat: "20.960056304932",
            lng: "106.99323272705",
          },
          {
            id: "2851",
            name: "The Bay View Towers",
            lat: "20.937211990356",
            lng: "107.12758636475",
          },
          {
            id: "2852",
            name: "The Sapphire Residence",
            lat: "20.948621749878",
            lng: "107.08293914795",
          },
          {
            id: "2853",
            name: "Times Garden H\u1ea1 Long",
            lat: "20.952072143555",
            lng: "107.0842590332",
          },
          {
            id: "2854",
            name: "Tu\u1ea7n Ch\u00e2u Marina H\u1ea1 Long",
            lat: "20.915233612061",
            lng: "106.98114013672",
          },
          {
            id: "2855",
            name: "Vi\u1ec7t H\u01b0ng",
            lat: "20.995914459229",
            lng: "106.96537780762",
          },
          {
            id: "2856",
            name: "Vinhomes H\u1ea1 Long",
            lat: "20.949178695679",
            lng: "107.07238769531",
          },
        ],
      },
      {
        id: "232",
        name: "H\u1ea3i H\u00e0",
        wards: [
          { id: "3206", name: "C\u00e1i Chi\u00ean", prefix: "X\u00e3" },
          {
            id: "3207",
            name: "\u0110\u1ea3o C\u00e1i Chi\u00ean",
            prefix: "X\u00e3",
          },
          { id: "3208", name: "\u0110\u01b0\u1eddng Hoa", prefix: "X\u00e3" },
          { id: "3209", name: "Ph\u00fa H\u1ea3i", prefix: "X\u00e3" },
          { id: "3210", name: "Qu\u1ea3ng Ch\u00ednh", prefix: "X\u00e3" },
          { id: "3211", name: "Qu\u1ea3ng \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "3212", name: "Qu\u1ea3ng \u0110\u1ee9c", prefix: "X\u00e3" },
          {
            id: "3213",
            name: "Qu\u1ea3ng H\u00e0",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3214", name: "Qu\u1ea3ng Long", prefix: "X\u00e3" },
          { id: "3215", name: "Qu\u1ea3ng Minh", prefix: "X\u00e3" },
          { id: "3216", name: "Qu\u1ea3ng Phong", prefix: "X\u00e3" },
          { id: "3217", name: "Qu\u1ea3ng S\u01a1n", prefix: "X\u00e3" },
          { id: "3218", name: "Qu\u1ea3ng Th\u1eafng", prefix: "X\u00e3" },
          { id: "3219", name: "Qu\u1ea3ng Th\u00e0nh", prefix: "X\u00e3" },
          { id: "3220", name: "Qu\u1ea3ng Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "3221", name: "Qu\u1ea3ng Trung", prefix: "X\u00e3" },
          { id: "3222", name: "Ti\u1ebfn T\u1edbi", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "22838",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22839",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "233",
        name: "Ho\u00e0nh B\u1ed3",
        wards: [
          { id: "3223", name: "B\u1eb1ng C\u1ea3", prefix: "X\u00e3" },
          { id: "3224", name: "D\u00e2n Ch\u1ee7", prefix: "X\u00e3" },
          { id: "3225", name: "\u0110\u1ed3ng L\u00e2m", prefix: "X\u00e3" },
          { id: "3226", name: "\u0110\u1ed3ng S\u01a1n", prefix: "X\u00e3" },
          { id: "3227", name: "Ho\u00e0 B\u00ecnh", prefix: "X\u00e3" },
          { id: "3228", name: "K\u1ef3 Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "3229", name: "L\u00ea L\u1ee3i", prefix: "X\u00e3" },
          { id: "3230", name: "Qu\u1ea3ng La", prefix: "X\u00e3" },
          { id: "3231", name: "S\u01a1n D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "3232", name: "T\u00e2n D\u00e2n", prefix: "X\u00e3" },
          { id: "3233", name: "Th\u1ed1ng Nh\u1ea5t", prefix: "X\u00e3" },
          { id: "3234", name: "Tr\u1edbi", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "3235", name: "V\u0169 Oai", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "22840",
            name: "\u0110\u1ed3ng Ch\u00e8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22841", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22842",
            name: "Qu\u1ed1c l\u1ed9 279",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "234",
        name: "M\u00f3ng C\u00e1i",
        wards: [
          { id: "3236", name: "B\u1eafc S\u01a1n", prefix: "X\u00e3" },
          {
            id: "3237",
            name: "B\u00ecnh Ng\u1ecdc",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3238", name: "H\u1ea3i \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "3239", name: "H\u1ea3i H\u00f2a", prefix: "Ph\u01b0\u1eddng" },
          { id: "3240", name: "H\u1ea3i S\u01a1n", prefix: "X\u00e3" },
          { id: "3241", name: "H\u1ea3i Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "3242", name: "H\u1ea3i Xu\u00e2n", prefix: "X\u00e3" },
          { id: "3243", name: "H\u1ea3i Y\u00ean", prefix: "Ph\u01b0\u1eddng" },
          { id: "3244", name: "H\u00f2a L\u1ea1c", prefix: "Ph\u01b0\u1eddng" },
          { id: "3245", name: "Ka Long", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "3246",
            name: "Ninh D\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3247", name: "Qu\u1ea3ng Ngh\u0129a", prefix: "X\u00e3" },
          { id: "3248", name: "Tr\u00e0 C\u1ed5", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "3249",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3250", name: "V\u1ea1n Ninh", prefix: "X\u00e3" },
          { id: "3251", name: "V\u0129nh Th\u1ef1c", prefix: "X\u00e3" },
          { id: "3252", name: "V\u0129nh Trung", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "22843",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22844",
            name: "Ch\u00e2u \u0110\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22845",
            name: "D\u00e2n Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22846",
            name: "\u0110\u00e0o Ph\u00fac L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22847",
            name: "\u0110\u00f4ng Tr\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22848",
            name: "G\u1ed1c Kh\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22849",
            name: "H\u00e0 Ti\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22850",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22851",
            name: "H\u00f2a L\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22852",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22853",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22854",
            name: "H\u1ed3ng K\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22855",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22856",
            name: "H\u00f9ng V\u01b0\u01a1ng B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22857",
            name: "Kim Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22858",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22859",
            name: "L\u00ea H\u1eefu Tr\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22860",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22861",
            name: "L\u00f2 B\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22862",
            name: "Long Xuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22863",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22864",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22865",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22866",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22867",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22868",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22869",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22870",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22871",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22872",
            name: "Qu\u1ed1c l\u1ed9 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22873",
            name: "Th\u1eafng L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22874",
            name: "Th\u1ecd Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22875",
            name: "Th\u01b0\u01a1ng M\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22876",
            name: "T\u00f4 V\u0129nh Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22877",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22878",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22879",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22880",
            name: "V\u00e2n \u0110\u1ed3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22881",
            name: "V\u1ea1n Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22882", name: "V\u0129nh An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22883",
            name: "V\u01b0\u1eddn Tr\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22884",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2857",
            name: "Green Park M\u00f3ng C\u00e1i",
            lat: "21.498289108276",
            lng: "107.98281097412",
          },
          {
            id: "2858",
            name: "H\u1ed3ng V\u1eadn",
            lat: "21.530815124512",
            lng: "107.96434783936",
          },
        ],
      },
      {
        id: "235",
        name: "Qu\u1ea3ng Y\u00ean",
        wards: [
          { id: "3253", name: "C\u1ea9m La", prefix: "X\u00e3" },
          {
            id: "3254",
            name: "C\u1ed9ng H\u00f2a",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "3255",
            name: "\u0110\u00f4ng Mai",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3256", name: "H\u00e0 An", prefix: "Ph\u01b0\u1eddng" },
          { id: "3257", name: "Hi\u1ec7p H\u00f2a", prefix: "X\u00e3" },
          { id: "3258", name: "Ho\u00e0ng T\u00e2n", prefix: "X\u00e3" },
          { id: "3259", name: "Li\u00ean H\u00f2a", prefix: "X\u00e3" },
          { id: "3260", name: "Li\u00ean V\u1ecb", prefix: "X\u00e3" },
          { id: "3261", name: "Minh Th\u00e0nh", prefix: "Ph\u01b0\u1eddng" },
          { id: "3262", name: "Nam H\u00f2a", prefix: "Ph\u01b0\u1eddng" },
          { id: "3263", name: "Phong C\u1ed1c", prefix: "Ph\u01b0\u1eddng" },
          { id: "3264", name: "Phong H\u1ea3i", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "3265",
            name: "Qu\u1ea3ng Y\u00ean",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3266", name: "S\u00f4ng Khoai", prefix: "X\u00e3" },
          { id: "3267", name: "T\u00e2n An", prefix: "Ph\u01b0\u1eddng" },
          { id: "3268", name: "Ti\u1ec1n An", prefix: "X\u00e3" },
          { id: "3269", name: "Ti\u1ec1n Phong", prefix: "X\u00e3" },
          { id: "3270", name: "Y\u00ean Giang", prefix: "Ph\u01b0\u1eddng" },
          { id: "3271", name: "Y\u00ean H\u1ea3i", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "22885", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22886", name: "18A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22887",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22888",
            name: "\u0110\u01b0\u1eddng Ngang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22889",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22890",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22891", name: "Khe C\u00e1t", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22892",
            name: "Kim L\u00e3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22893",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22894",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22895",
            name: "Nguy\u1ec5n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22896",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22897", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22898",
            name: "Qu\u1ed1c L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22899",
            name: "Qu\u1ed1c l\u1ed9 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22900",
            name: "Qu\u1ed1c l\u1ed9 18A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22901",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22902",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22903",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22904",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22905",
            name: "y\u00ean giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "236",
        name: "Ti\u00ean Y\u00ean",
        wards: [
          { id: "3272", name: "\u0110\u1ea1i D\u1ef1c", prefix: "X\u00e3" },
          { id: "3273", name: "\u0110\u1ea1i Th\u00e0nh", prefix: "X\u00e3" },
          { id: "3274", name: "\u0110i\u1ec1n X\u00e1", prefix: "X\u00e3" },
          { id: "3275", name: "\u0110\u00f4ng H\u1ea3i", prefix: "X\u00e3" },
          { id: "3276", name: "\u0110\u00f4ng Ng\u0169", prefix: "X\u00e3" },
          { id: "3277", name: "\u0110\u1ed3ng Rui", prefix: "X\u00e3" },
          { id: "3278", name: "H\u00e0 L\u00e2u", prefix: "X\u00e3" },
          { id: "3279", name: "H\u1ea3i L\u1ea1ng", prefix: "X\u00e3" },
          { id: "3280", name: "Phong D\u1ee5", prefix: "X\u00e3" },
          { id: "3281", name: "Ti\u00ean L\u00e3ng", prefix: "X\u00e3" },
          {
            id: "3282",
            name: "Ti\u00ean Y\u00ean",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3283", name: "Y\u00ean Than", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "22906",
            name: "Qu\u1ed1c l\u1ed9 18A",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "237",
        name: "U\u00f4ng B\u00ed",
        wards: [
          { id: "3284", name: "B\u1eafc S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "3285", name: "\u0110i\u1ec1n C\u00f4ng", prefix: "X\u00e3" },
          { id: "3286", name: "Nam Kh\u00ea", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "3287",
            name: "Ph\u01b0\u01a1ng \u0110\u00f4ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "3288",
            name: "Ph\u01b0\u01a1ng Nam",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3289", name: "Quang Trung", prefix: "Ph\u01b0\u1eddng" },
          { id: "3290", name: "Thanh S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "3291",
            name: "Th\u01b0\u1ee3ng Y\u00ean C\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "3292",
            name: "Tr\u01b0ng V\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3293", name: "V\u00e0ng Danh", prefix: "Ph\u01b0\u1eddng" },
          { id: "3294", name: "Y\u00ean Thanh", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "22907", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22908", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22909", name: "18A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22910",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22911",
            name: "C\u1ea7u S\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22912",
            name: "Ch\u1ea1p Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22913",
            name: "\u0110\u1ed3ng M\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22914",
            name: "\u0110\u1ed3ng Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22915",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22916",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22917",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22918",
            name: "H\u1eefu Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22919",
            name: "L\u1ef1ng Xanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22920",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22921",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22922",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22923",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22924",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22925",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22926",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22927",
            name: "Ph\u00fa Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22928", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22929",
            name: "Qu\u1ed1c l\u1ed9 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22930",
            name: "Qu\u1ed1c l\u1ed9 18A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22931",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22932",
            name: "Thanh S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22933",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22934",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22935",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22936",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22937",
            name: "Tr\u1ea7n Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22938", name: "Tre Mai", prefix: "Ph\u1ed1" },
          {
            id: "22939",
            name: "Tr\u01b0ng Nh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22940",
            name: "Tr\u01b0ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22941",
            name: "Vi\u1ec7t X\u00f4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22942",
            name: "Y\u00ean Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22943",
            name: "Y\u00ean T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2859",
            name: "Vi\u1ec7t Long City",
            lat: "21.035367965698",
            lng: "106.7388381958",
          },
        ],
      },
      {
        id: "238",
        name: "V\u00e2n \u0110\u1ed3n",
        wards: [
          { id: "3295", name: "B\u1ea3n Sen", prefix: "X\u00e3" },
          { id: "3296", name: "B\u00ecnh D\u00e2n", prefix: "X\u00e3" },
          {
            id: "3297",
            name: "C\u00e1i R\u1ed3ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3298", name: "\u0110\u00e0i Xuy\u00ean", prefix: "X\u00e3" },
          { id: "3299", name: "\u0110o\u00e0n K\u1ebft", prefix: "X\u00e3" },
          { id: "3300", name: "\u0110\u00f4ng X\u00e1", prefix: "X\u00e3" },
          { id: "3301", name: "H\u1ea1 Long", prefix: "X\u00e3" },
          { id: "3302", name: "Minh Ch\u00e2u", prefix: "X\u00e3" },
          { id: "3303", name: "Ng\u1ecdc V\u1eebng", prefix: "X\u00e3" },
          { id: "3304", name: "Quan L\u1ea1n", prefix: "X\u00e3" },
          { id: "3305", name: "Th\u1eafng L\u1ee3i", prefix: "X\u00e3" },
          { id: "3306", name: "V\u1ea1n Y\u00ean", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "22944", name: "334", prefix: "\u0110\u01b0\u1eddng" },
          { id: "22945", name: "58", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22946",
            name: "\u0110\u00f4ng Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22947",
            name: "L\u00fd Anh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22948",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22949",
            name: "Vi\u1ec7t M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2860",
            name: "Resort Vi\u1ec7t M\u1ef9 V\u00e2n \u0110\u1ed3n",
            lat: "21.036842346191",
            lng: "107.37451934814",
          },
        ],
      },
    ],
  },
  {
    id: "19",
    code: "TH",
    name: "Thanh H\u00f3a",
    districts: [
      {
        id: "239",
        name: "B\u00e1 Th\u01b0\u1edbc",
        wards: [
          { id: "3307", name: "\u00c1i Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "3308", name: "Ban C\u00f4ng", prefix: "X\u00e3" },
          {
            id: "3309",
            name: "C\u00e0nh N\u00e0ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3310", name: "C\u1ed5 L\u0169ng", prefix: "X\u00e3" },
          { id: "3311", name: "\u0110i\u1ec1n H\u1ea1", prefix: "X\u00e3" },
          { id: "3312", name: "\u0110i\u1ec1n L\u01b0", prefix: "X\u00e3" },
          { id: "3313", name: "\u0110i\u1ec1n Quang", prefix: "X\u00e3" },
          {
            id: "3314",
            name: "\u0110i\u1ec1n Th\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
          { id: "3315", name: "\u0110i\u1ec1n Trung", prefix: "X\u00e3" },
          { id: "3316", name: "H\u1ea1 Trung", prefix: "X\u00e3" },
          { id: "3317", name: "K\u1ef3 T\u00e2n", prefix: "X\u00e3" },
          { id: "3318", name: "L\u00e2m Xa", prefix: "X\u00e3" },
          { id: "3319", name: "L\u0169ng Cao", prefix: "X\u00e3" },
          { id: "3320", name: "L\u0169ng Ni\u00eam", prefix: "X\u00e3" },
          { id: "3321", name: "L\u01b0\u01a1ng Ngo\u1ea1i", prefix: "X\u00e3" },
          { id: "3322", name: "L\u01b0\u01a1ng N\u1ed9i", prefix: "X\u00e3" },
          { id: "3323", name: "L\u01b0\u01a1ng Trung", prefix: "X\u00e3" },
          { id: "3324", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "3325", name: "Th\u00e0nh L\u00e2m", prefix: "X\u00e3" },
          { id: "3326", name: "Th\u00e1nh S\u01a1n", prefix: "X\u00e3" },
          { id: "3327", name: "Thi\u1ebft K\u1ebf", prefix: "X\u00e3" },
          { id: "3328", name: "Thi\u1ebft \u1ed0ng", prefix: "X\u00e3" },
          { id: "3329", name: "V\u0103n Nho", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "22950",
            name: "B\u00f9i V\u0103n Danh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22951",
            name: "C\u00e0nh N\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22952",
            name: "Qu\u1ed1c l\u1ed9 15A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22953",
            name: "Qu\u1ed1c l\u1ed9 15C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22954",
            name: "T\u1ec9nh l\u1ed9 217",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22955",
            name: "T\u1ec9nh l\u1ed9 943",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22956",
            name: "Tr\u00e0 \u00d4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "240",
        name: "B\u1ec9m S\u01a1n",
        wards: [
          { id: "3330", name: "Ba \u0110\u00ecnh", prefix: "Ph\u01b0\u1eddng" },
          { id: "3331", name: "B\u1eafc S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "3332",
            name: "\u0110\u00f4ng S\u01a1n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3333", name: "H\u00e0 Lan", prefix: "X\u00e3" },
          { id: "3334", name: "Lam S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "3335",
            name: "Ng\u1ecdc Tr\u1ea1o",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3336", name: "Ph\u00fa S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "3337", name: "Quang Trung", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "22957", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "22958",
            name: "B\u00f9i X\u01b0\u01a1ng Tr\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22959",
            name: "C\u1ea7m B\u00e1 Th\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22960",
            name: "Cao \u0110i\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22961",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22962",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22963",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22970",
            name: "D\u01b0\u01a1ng \u0110\u00ecnh Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22964", name: "\u0110\u1eb7ng Dung", prefix: "Ph\u1ed1" },
          {
            id: "22965",
            name: "\u0110\u1eb7ng Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22966",
            name: "\u0110\u00e0o Duy Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22967",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22968",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22969",
            name: "\u0110o\u00e0n K\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22971",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22972",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22973",
            name: "H\u00e0n Thuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22974",
            name: "H\u1ed3 Qu\u00fd Ly",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22975",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22976",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22977",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22978",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22979",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22980",
            name: "L\u00ea Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22981",
            name: "L\u00ea Ch\u00ed Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22982",
            name: "L\u00ea \u0110\u00ecnh Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22983",
            name: "L\u00ea H\u1eefu L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "22984", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "22985",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22986",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22987",
            name: "L\u00ea V\u0103n H\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22988",
            name: "L\u00ea V\u0103n T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22989",
            name: "L\u01b0\u01a1ng \u0110\u1eafc B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22990",
            name: "L\u01b0\u01a1ng \u0110\u1ecbnh C\u1ee7a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22991",
            name: "L\u01b0\u01a1ng Ng\u1ecdc Quy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22992",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22993",
            name: "L\u01b0\u01a1ng V\u0103n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22994",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22995",
            name: "L\u00fd Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22996",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22997",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "22998",
            name: "M\u1ea1c \u0110\u0103ng Dung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "22999",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23000",
            name: "Mai H\u1eafc \u0110\u1ebf",
            prefix: "Ph\u1ed1",
          },
          { id: "23001", name: "Nam Cao", prefix: "Ph\u1ed1" },
          {
            id: "23002",
            name: "Ng\u00f4 Gia Kh\u1ea3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23003",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23004",
            name: "Ng\u00f4 T\u1ea5t T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23005",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23006",
            name: "Ng\u00f4 Th\u00ec S\u0129",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23007",
            name: "Ng\u00f4 V\u0103n S\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23008",
            name: "Nguy\u1ec5n B\u00e1 Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23009",
            name: "Nguy\u1ec5n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23010",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23011",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23012",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23013",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23014",
            name: "Nguy\u1ec5n Ki\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23015",
            name: "Nguy\u1ec5n Qu\u1ed1c Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23016",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23017",
            name: "Nguy\u1ec5n Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23018",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23019",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23020",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23021",
            name: "Nguy\u1ec5n Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23022",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23023",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23024",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23025",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23026",
            name: "Ph\u1ea1m B\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23027",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23028",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23029",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23030",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23031",
            name: "Phan Huy Ch\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23032", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "23033",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23034", name: "T\u1ea1 Quang B\u1eedu", prefix: "Ph\u1ed1" },
          { id: "23035", name: "Thi S\u00e1ch", prefix: "Ph\u1ed1" },
          {
            id: "23036",
            name: "Thi\u00ean L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23037",
            name: "Thi\u1ec7u Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23038",
            name: "T\u00f4 V\u0129nh Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23039",
            name: "T\u00f4n Th\u1ea5t Thuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23040",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23041",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23042",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23043",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23044",
            name: "Tr\u1ea7n Nguy\u00ean \u0110\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23045",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23046",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23047",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23048",
            name: "Tr\u1ea7n T\u1ebf X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23049",
            name: "Tr\u1ea7n Th\u00e1i T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23050",
            name: "Tr\u1ea7n Xu\u00e2n So\u1ea1n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23051",
            name: "Tri\u1ec7u Qu\u1ed1c \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23052",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23053",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "241",
        name: "C\u1ea9m Th\u1ee7y",
        wards: [
          { id: "3338", name: "C\u1ea9m B\u00ecnh", prefix: "X\u00e3" },
          { id: "3339", name: "C\u1ea9m Ch\u00e2u", prefix: "X\u00e3" },
          { id: "3340", name: "C\u1ea9m Giang", prefix: "X\u00e3" },
          { id: "3341", name: "C\u1ea9m Li\u00ean", prefix: "X\u00e3" },
          { id: "3342", name: "C\u1ea9m Long", prefix: "X\u00e3" },
          { id: "3343", name: "C\u1ea9m L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "3344", name: "C\u1ea9m Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "3345", name: "C\u1ea9m Phong", prefix: "X\u00e3" },
          { id: "3346", name: "C\u1ea9m Ph\u00fa", prefix: "X\u00e3" },
          { id: "3347", name: "C\u1ea9m Qu\u00fd", prefix: "X\u00e3" },
          { id: "3348", name: "C\u1ea9m S\u01a1n", prefix: "X\u00e3" },
          { id: "3349", name: "C\u1ea9m T\u00e2m", prefix: "X\u00e3" },
          { id: "3350", name: "C\u1ea9m T\u00e2n", prefix: "X\u00e3" },
          { id: "3351", name: "C\u1ea9m Th\u1ea1ch", prefix: "X\u00e3" },
          { id: "3352", name: "C\u1ea9m Th\u00e0nh", prefix: "X\u00e3" },
          {
            id: "3353",
            name: "C\u1ea9m Th\u1ee7y",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3354", name: "C\u1ea9m T\u00fa", prefix: "X\u00e3" },
          { id: "3355", name: "C\u1ea9m V\u00e2n", prefix: "X\u00e3" },
          { id: "3356", name: "C\u1ea9m Y\u00ean", prefix: "X\u00e3" },
          { id: "3357", name: "Ph\u00fac Do", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "23054",
            name: "H\u1ed3 Ch\u00ed Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23055",
            name: "Ng\u1ecdc - Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23056",
            name: "T\u1ec9nh l\u1ed9 217",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23057",
            name: "T\u1ec9nh l\u1ed9 518",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23058",
            name: "T\u1ec9nh l\u1ed9 519",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23059",
            name: "V\u00e2n Y\u00ean S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "242",
        name: "\u0110\u00f4ng S\u01a1n",
        wards: [
          { id: "3358", name: "\u0110\u00f4ng Anh", prefix: "X\u00e3" },
          { id: "3359", name: "\u0110\u00f4ng H\u00f2a", prefix: "X\u00e3" },
          { id: "3360", name: "\u0110\u00f4ng Ho\u00e0ng", prefix: "X\u00e3" },
          { id: "3361", name: "\u0110\u00f4ng Kh\u00ea", prefix: "X\u00e3" },
          { id: "3362", name: "\u0110\u00f4ng Minh", prefix: "X\u00e3" },
          { id: "3363", name: "\u0110\u00f4ng Nam", prefix: "X\u00e3" },
          { id: "3364", name: "\u0110\u00f4ng Ninh", prefix: "X\u00e3" },
          { id: "3365", name: "\u0110\u00f4ng Ph\u00fa", prefix: "X\u00e3" },
          { id: "3366", name: "\u0110\u00f4ng Quang", prefix: "X\u00e3" },
          { id: "3367", name: "\u0110\u00f4ng Thanh", prefix: "X\u00e3" },
          { id: "3368", name: "\u0110\u00f4ng Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "3369", name: "\u0110\u00f4ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "3370", name: "\u0110\u00f4ng V\u0103n", prefix: "X\u00e3" },
          { id: "3371", name: "\u0110\u00f4ng Xu\u00e2n", prefix: "X\u00e3" },
          { id: "3372", name: "\u0110\u00f4ng Y\u00ean", prefix: "X\u00e3" },
          { id: "3373", name: "R\u1eebng Th\u00f4ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "23060", name: "45", prefix: "Ph\u1ed1" },
          { id: "23061", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23062",
            name: "L\u01b0\u01a1ng \u0110\u1eafc B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23063",
            name: "Qu\u1ed1c l\u1ed9 45",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23064",
            name: "Qu\u1ed1c l\u1ed9 47",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23065",
            name: "T\u1ec9nh l\u1ed9 521",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2861",
            name: "K\u0110T \u0110\u00f4ng S\u01a1n",
            lat: "19.804353713989",
            lng: "105.75685119629",
          },
        ],
      },
      {
        id: "243",
        name: "H\u00e0 Trung",
        wards: [
          { id: "3374", name: "H\u00e0 B\u1eafc", prefix: "X\u00e3" },
          { id: "3375", name: "H\u00e0 B\u00ecnh", prefix: "X\u00e3" },
          { id: "3376", name: "H\u00e0 Ch\u00e2u", prefix: "X\u00e3" },
          { id: "3378", name: "H\u00e0 D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "3377", name: "H\u00e0 \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "3379", name: "H\u00e0 Giang", prefix: "X\u00e3" },
          { id: "3380", name: "H\u00e0 H\u1ea3i", prefix: "X\u00e3" },
          { id: "3381", name: "H\u00e0 Lai", prefix: "X\u00e3" },
          { id: "3382", name: "H\u00e0 L\u00e2m", prefix: "X\u00e3" },
          { id: "3383", name: "H\u00e0 L\u0129nh", prefix: "X\u00e3" },
          { id: "3384", name: "H\u00e0 Long", prefix: "X\u00e3" },
          { id: "3385", name: "H\u00e0 Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "3386", name: "H\u00e0 Ninh", prefix: "X\u00e3" },
          { id: "3387", name: "H\u00e0 Phong", prefix: "X\u00e3" },
          { id: "3388", name: "H\u00e0 Ph\u00fa", prefix: "X\u00e3" },
          { id: "3389", name: "H\u00e0 S\u01a1n", prefix: "X\u00e3" },
          { id: "3390", name: "H\u00e0 T\u00e2n", prefix: "X\u00e3" },
          { id: "3391", name: "H\u00e0 Th\u00e1i", prefix: "X\u00e3" },
          { id: "3392", name: "H\u00e0 Thanh", prefix: "X\u00e3" },
          { id: "3393", name: "H\u00e0 Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "3394", name: "H\u00e0 To\u1ea1i", prefix: "X\u00e3" },
          { id: "3395", name: "H\u00e0 Trung", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "3396", name: "H\u00e0 V\u00e2n", prefix: "X\u00e3" },
          { id: "3397", name: "H\u00e0 Vinh", prefix: "X\u00e3" },
          { id: "3398", name: "H\u00e0 Y\u00ean", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "23066", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23068",
            name: "D\u01b0\u01a1ng -  V\u00e2n - Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23067",
            name: "\u0110\u00f4ng - S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23069",
            name: "\u0110\u01b0\u1eddng C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23070",
            name: "Kim T\u00e2n - H\u00e0 Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23071",
            name: "Long - S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23072",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23073",
            name: "T\u1ec9nh l\u1ed9 217",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23074",
            name: "T\u1ec9nh l\u1ed9 508",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23075",
            name: "T\u1ec9nh l\u1ed9 522",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "244",
        name: "H\u1eadu L\u1ed9c",
        wards: [
          { id: "3399", name: "C\u1ea7u L\u1ed9c", prefix: "X\u00e3" },
          { id: "3400", name: "Ch\u00e2u L\u1ed9c", prefix: "X\u00e3" },
          { id: "3401", name: "\u0110a L\u1ed9c", prefix: "X\u00e3" },
          { id: "3402", name: "\u0110\u1ea1i L\u1ed9c", prefix: "X\u00e3" },
          { id: "3403", name: "\u0110\u1ed3ng L\u1ed9c", prefix: "X\u00e3" },
          { id: "3404", name: "H\u1ea3i L\u1ed9c", prefix: "X\u00e3" },
          {
            id: "3405",
            name: "H\u1eadu L\u1ed9c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3406", name: "Hoa L\u1ed9c", prefix: "X\u00e3" },
          { id: "3407", name: "H\u00f2a L\u1ed9c", prefix: "X\u00e3" },
          { id: "3408", name: "H\u01b0ng L\u1ed9c", prefix: "X\u00e3" },
          { id: "3409", name: "Li\u00ean L\u1ed9c", prefix: "X\u00e3" },
          { id: "3410", name: "L\u1ed9c S\u01a1n", prefix: "X\u00e3" },
          { id: "3411", name: "L\u1ed9c T\u00e2n", prefix: "X\u00e3" },
          { id: "3412", name: "Minh L\u1ed9c", prefix: "X\u00e3" },
          { id: "3413", name: "M\u1ef9 L\u1ed9c", prefix: "X\u00e3" },
          { id: "3414", name: "Ng\u01b0 L\u1ed9c", prefix: "X\u00e3" },
          { id: "3415", name: "Phong L\u1ed9c", prefix: "X\u00e3" },
          { id: "3416", name: "Ph\u00fa L\u1ed9c", prefix: "X\u00e3" },
          { id: "3417", name: "Quang L\u1ed9c", prefix: "X\u00e3" },
          { id: "3418", name: "Th\u00e0nh L\u1ed9c", prefix: "X\u00e3" },
          { id: "3419", name: "Th\u1ecbnh L\u1ed9c", prefix: "X\u00e3" },
          { id: "3420", name: "Thu\u1ea7n L\u1ed9c", prefix: "X\u00e3" },
          { id: "3421", name: "Ti\u1ebfn L\u1ed9c", prefix: "X\u00e3" },
          { id: "3422", name: "Tri\u1ec7u L\u1ed9c", prefix: "X\u00e3" },
          { id: "3423", name: "Tuy L\u1ed9c", prefix: "X\u00e3" },
          { id: "3424", name: "V\u0103n L\u1ed9c", prefix: "X\u00e3" },
          { id: "3425", name: "Xu\u00e2n L\u1ed9c", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "23076", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23077",
            name: "\u0110\u1ea1i L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23078",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23079",
            name: "M\u1ef9 L\u1ed9c \u2013 Th\u00e0nh L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23080",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23081",
            name: "T\u00e2n \u2013 Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2862",
            name: "C\u1ee5m c\u00f4ng nghi\u1ec7p c\u1ea3ng c\u00e1 H\u00f2a L\u1ed9c",
            lat: "19.931541442871",
            lng: "105.93180084229",
          },
        ],
      },
      {
        id: "245",
        name: "Ho\u1eb1ng H\u00f3a",
        wards: [
          {
            id: "3426",
            name: "B\u00fat S\u01a1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3427", name: "Ho\u1eb1ng C\u00e1t", prefix: "X\u00e3" },
          { id: "3428", name: "Ho\u1eb1ng Ch\u00e2u", prefix: "X\u00e3" },
          { id: "3429", name: "Ho\u1eb1ng \u0110\u1ea1o", prefix: "X\u00e3" },
          { id: "3430", name: "Ho\u1eb1ng \u0110\u1ea1t", prefix: "X\u00e3" },
          { id: "3431", name: "Ho\u1eb1ng \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "3432", name: "Ho\u1eb1ng \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "3433", name: "Ho\u1eb1ng \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "3434", name: "Ho\u1eb1ng Giang", prefix: "X\u00e3" },
          { id: "3435", name: "Ho\u1eb1ng H\u00e0", prefix: "X\u00e3" },
          { id: "3436", name: "Ho\u1eb1ng H\u1ea3i", prefix: "X\u00e3" },
          { id: "3437", name: "Ho\u1eb1ng H\u1ee3p", prefix: "X\u00e3" },
          { id: "3438", name: "Ho\u1eb1ng Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "3439", name: "Ho\u1eb1ng Kh\u00ea", prefix: "X\u00e3" },
          { id: "3440", name: "Ho\u1eb1ng Kim", prefix: "X\u00e3" },
          { id: "3441", name: "Ho\u1eb1ng L\u1ed9c", prefix: "X\u00e3" },
          { id: "3442", name: "Ho\u1eb1ng L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "3443", name: "Ho\u1eb1ng L\u01b0u", prefix: "X\u00e3" },
          { id: "3444", name: "Ho\u1eb1ng Minh", prefix: "X\u00e3" },
          { id: "3445", name: "Ho\u1eb1ng Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "3446", name: "Ho\u1eb1ng Phong", prefix: "X\u00e3" },
          { id: "3447", name: "Ho\u1eb1ng Ph\u00fa", prefix: "X\u00e3" },
          { id: "3448", name: "Ho\u1eb1ng Ph\u1ee5", prefix: "X\u00e3" },
          { id: "3449", name: "Ho\u1eb1ng Ph\u00fac", prefix: "X\u00e3" },
          {
            id: "3450",
            name: "Ho\u1eb1ng Ph\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
          { id: "3451", name: "Ho\u1eb1ng Qu\u00fd", prefix: "X\u00e3" },
          { id: "3452", name: "Ho\u1eb1ng Qu\u1ef3", prefix: "X\u00e3" },
          { id: "3453", name: "Ho\u1eb1ng S\u01a1n", prefix: "X\u00e3" },
          { id: "3454", name: "Ho\u1eb1ng T\u00e2n", prefix: "X\u00e3" },
          { id: "3455", name: "Ho\u1eb1ng Th\u00e1i", prefix: "X\u00e3" },
          { id: "3456", name: "Ho\u1eb1ng Th\u1eafng", prefix: "X\u00e3" },
          { id: "3457", name: "Ho\u1eb1ng Thanh", prefix: "X\u00e3" },
          { id: "3458", name: "Ho\u1eb1ng Th\u00e0nh", prefix: "X\u00e3" },
          { id: "3459", name: "Ho\u1eb1ng Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "3460", name: "Ho\u1eb1ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "3461", name: "Ho\u1eb1ng Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "3462", name: "Ho\u1eb1ng Trinh", prefix: "X\u00e3" },
          { id: "3463", name: "Ho\u1eb1ng Trung", prefix: "X\u00e3" },
          {
            id: "3464",
            name: "Ho\u1eb1ng Tr\u01b0\u1eddng",
            prefix: "X\u00e3",
          },
          { id: "3465", name: "Ho\u1eb1ng Vinh", prefix: "X\u00e3" },
          { id: "3466", name: "Ho\u1eb1ng Xu\u00e2n", prefix: "X\u00e3" },
          { id: "3467", name: "Ho\u1eb1ng Xuy\u00ean", prefix: "X\u00e3" },
          { id: "3468", name: "Ho\u1eb1ng Y\u1ebfn", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "23082", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23083",
            name: "H\u01b0ng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23084",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23085",
            name: "T\u1ec9nh l\u1ed9 509",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23086",
            name: "T\u1ec9nh l\u1ed9 510",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23087",
            name: "T\u1ec9nh l\u1ed9 510B",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2863",
            name: "Eureka Linh Tr\u01b0\u1eddng",
            lat: "19.846412658691",
            lng: "105.93547058105",
          },
        ],
      },
      {
        id: "246",
        name: "Lang Ch\u00e1nh",
        wards: [
          {
            id: "3469",
            name: "\u0110\u1ed3ng L\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "3470", name: "Giao An", prefix: "X\u00e3" },
          { id: "3471", name: "Giao Thi\u1ec7n", prefix: "X\u00e3" },
          { id: "3472", name: "L\u00e2m Ph\u00fa", prefix: "X\u00e3" },
          { id: "3473", name: "Lang Ch\u00e1nh", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "3474", name: "Quang Hi\u1ebfn", prefix: "X\u00e3" },
          { id: "3475", name: "Tam V\u0103n", prefix: "X\u00e3" },
          { id: "3476", name: "T\u00e2n Ph\u00fac", prefix: "X\u00e3" },
          { id: "3477", name: "Tr\u00ed Nang", prefix: "X\u00e3" },
          { id: "3478", name: "Y\u00ean Kh\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "3479", name: "Y\u00ean Th\u1eafng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "23088", name: "135", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23089",
            name: "N\u00e0 \u0110ang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23090",
            name: "Quang T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23091",
            name: "Qu\u1ed1c l\u1ed9 15A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23092",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "247",
        name: "M\u01b0\u1eddng L\u00e1t",
        wards: [
          { id: "3480", name: "M\u01b0\u1eddng Chanh", prefix: "X\u00e3" },
          {
            id: "3481",
            name: "M\u01b0\u1eddng L\u00e1t",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3482", name: "M\u01b0\u1eddng L\u00fd", prefix: "X\u00e3" },
          { id: "3483", name: "Nhi S\u01a1n", prefix: "X\u00e3" },
          { id: "3484", name: "P\u00f9 Nhi", prefix: "X\u00e3" },
          { id: "3485", name: "Quang Chi\u1ec3u", prefix: "X\u00e3" },
          { id: "3486", name: "Tam Chung", prefix: "X\u00e3" },
          { id: "3487", name: "T\u00e9n T\u1eb1n", prefix: "X\u00e3" },
          { id: "3488", name: "Trung L\u00fd", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "23093",
            name: "T\u1ec9nh l\u1ed9 520",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "248",
        name: "Nga S\u01a1n",
        wards: [
          { id: "3489", name: "Ba \u0110\u00ecnh", prefix: "X\u00e3" },
          { id: "3490", name: "Nga An", prefix: "X\u00e3" },
          { id: "3491", name: "Nga B\u1ea1ch", prefix: "X\u00e3" },
          { id: "3492", name: "Nga \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "3493", name: "Nga Gi\u00e1p", prefix: "X\u00e3" },
          { id: "3494", name: "Nga H\u1ea3i", prefix: "X\u00e3" },
          { id: "3495", name: "Nga H\u01b0ng", prefix: "X\u00e3" },
          { id: "3496", name: "Nga Li\u00ean", prefix: "X\u00e3" },
          { id: "3497", name: "Nga L\u0129nh", prefix: "X\u00e3" },
          { id: "3498", name: "Nga M\u1ef9", prefix: "X\u00e3" },
          { id: "3499", name: "Nga Nh\u00e2n", prefix: "X\u00e3" },
          { id: "3500", name: "Nga Ph\u00fa", prefix: "X\u00e3" },
          { id: "3501", name: "Nga S\u01a1n", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "3502", name: "Nga T\u00e2n", prefix: "X\u00e3" },
          { id: "3503", name: "Nga Th\u1ea1ch", prefix: "X\u00e3" },
          { id: "3504", name: "Nga Th\u00e1i", prefix: "X\u00e3" },
          { id: "3505", name: "Nga Th\u1eafng", prefix: "X\u00e3" },
          { id: "3506", name: "Nga Thanh", prefix: "X\u00e3" },
          { id: "3507", name: "Nga Th\u00e0nh", prefix: "X\u00e3" },
          { id: "3508", name: "Nga Thi\u1ec7n", prefix: "X\u00e3" },
          { id: "3509", name: "Nga Thu\u1ef7", prefix: "X\u00e3" },
          { id: "3510", name: "Nga Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "3511", name: "Nga Trung", prefix: "X\u00e3" },
          { id: "3512", name: "Nga Tr\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "3513", name: "Nga V\u0103n", prefix: "X\u00e3" },
          { id: "3514", name: "Nga V\u1ecbnh", prefix: "X\u00e3" },
          { id: "3515", name: "Nga Y\u00ean", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "23094", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23095", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23096",
            name: "L\u00ea Th\u1ecb Hoa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23097",
            name: "T\u1ec9nh l\u1ed9 508",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "249",
        name: "Ng\u1ecdc L\u1eb7c",
        wards: [
          { id: "3516", name: "Cao Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "3517", name: "Cao Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "3518", name: "\u0110\u1ed3ng Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "3519", name: "Ki\u00ean Th\u1ecd", prefix: "X\u00e3" },
          { id: "3520", name: "Lam S\u01a1n", prefix: "X\u00e3" },
          { id: "3521", name: "L\u1ed9c Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "3522", name: "Minh S\u01a1n", prefix: "X\u00e3" },
          { id: "3523", name: "Minh Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "3524", name: "M\u1ef9 T\u00e2n", prefix: "X\u00e3" },
          { id: "3525", name: "Ng\u1ecdc Kh\u00ea", prefix: "X\u00e3" },
          {
            id: "3526",
            name: "Ng\u1ecdc L\u1eb7c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3527", name: "Ng\u1ecdc Li\u00ean", prefix: "X\u00e3" },
          { id: "3528", name: "Ng\u1ecdc S\u01a1n", prefix: "X\u00e3" },
          { id: "3529", name: "Ng\u1ecdc Trung", prefix: "X\u00e3" },
          { id: "3530", name: "Nguy\u1ec7t \u1ea4n", prefix: "X\u00e3" },
          { id: "3531", name: "Ph\u00fac Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "3532", name: "Ph\u00f9ng Gi\u00e1o", prefix: "X\u00e3" },
          { id: "3533", name: "Ph\u00f9ng Minh", prefix: "X\u00e3" },
          { id: "3534", name: "Quang Trung", prefix: "X\u00e3" },
          { id: "3535", name: "Th\u1ea1ch L\u1eadp", prefix: "X\u00e3" },
          { id: "3536", name: "Th\u00fay S\u01a1n", prefix: "X\u00e3" },
          { id: "3537", name: "V\u00e2n \u00c2m", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "23098",
            name: "\u0110\u01b0\u1eddng ATK",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23099",
            name: "H\u1ed3 Ch\u00ed Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23100",
            name: "L\u00ea \u0110\u00ecnh Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23101",
            name: "L\u00ea Ho\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23102",
            name: "Qu\u1ed1c l\u1ed9 15A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23103",
            name: "T\u1ec9nh l\u1ed9 516B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23104",
            name: "T\u1ec9nh l\u1ed9 519",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "250",
        name: "Nh\u01b0 Thanh",
        wards: [
          { id: "3538", name: "B\u1ebfn Sung", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "3539", name: "C\u00e1n Kh\u00ea", prefix: "X\u00e3" },
          { id: "3540", name: "H\u1ea3i Long", prefix: "X\u00e3" },
          { id: "3541", name: "H\u1ea3i V\u00e2n", prefix: "X\u00e3" },
          { id: "3542", name: "M\u1eadu L\u00e2m", prefix: "X\u00e3" },
          { id: "3543", name: "Ph\u00fa Nhu\u1eadn", prefix: "X\u00e3" },
          {
            id: "3544",
            name: "Ph\u00fac \u0110\u01b0\u1eddng",
            prefix: "X\u00e3",
          },
          { id: "3545", name: "Ph\u01b0\u1ee3ng Nghi", prefix: "X\u00e3" },
          { id: "3546", name: "Thanh K\u1ef3", prefix: "X\u00e3" },
          { id: "3547", name: "Thanh T\u00e2n", prefix: "X\u00e3" },
          { id: "3548", name: "Xu\u00e2n Du", prefix: "X\u00e3" },
          { id: "3549", name: "Xu\u00e2n Khang", prefix: "X\u00e3" },
          { id: "3550", name: "Xu\u00e2n Ph\u00fac", prefix: "X\u00e3" },
          { id: "3551", name: "Xu\u00e2n Th\u00e1i", prefix: "X\u00e3" },
          { id: "3552", name: "Xu\u00e2n Th\u1ecd", prefix: "X\u00e3" },
          { id: "3553", name: "Y\u00ean L\u1ea1c", prefix: "X\u00e3" },
          { id: "3554", name: "Y\u00ean Th\u1ecd", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "23105", name: "45", prefix: "Ph\u1ed1" },
          {
            id: "23106",
            name: "Qu\u1ed1c l\u1ed9 45",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23107",
            name: "T\u1ec9nh l\u1ed9 505",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23108",
            name: "T\u1ec9nh l\u1ed9 513",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23109",
            name: "T\u1ec9nh l\u1ed9 514",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "251",
        name: "Nh\u01b0 Xu\u00e2n",
        wards: [
          { id: "3555", name: "B\u00e3i Tr\u00e0nh", prefix: "X\u00e3" },
          { id: "3556", name: "B\u00ecnh L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "3557", name: "C\u00e1t T\u00e2n", prefix: "X\u00e3" },
          { id: "3558", name: "C\u00e1t V\u00e2n", prefix: "X\u00e3" },
          { id: "3559", name: "H\u00f3a Qu\u1ef3", prefix: "X\u00e3" },
          { id: "3560", name: "T\u00e2n B\u00ecnh", prefix: "X\u00e3" },
          { id: "3561", name: "Thanh H\u00f2a", prefix: "X\u00e3" },
          { id: "3562", name: "Thanh L\u00e2m", prefix: "X\u00e3" },
          { id: "3563", name: "Thanh Phong", prefix: "X\u00e3" },
          { id: "3564", name: "Thanh Qu\u00e2n", prefix: "X\u00e3" },
          { id: "3565", name: "Thanh S\u01a1n", prefix: "X\u00e3" },
          { id: "3566", name: "Thanh Xu\u00e2n", prefix: "X\u00e3" },
          { id: "3567", name: "Th\u01b0\u1ee3ng Ninh", prefix: "X\u00e3" },
          { id: "3568", name: "Xu\u00e2n B\u00ecnh", prefix: "X\u00e3" },
          { id: "3569", name: "Xu\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "3570", name: "Xu\u00e2n Qu\u1ef3", prefix: "X\u00e3" },
          {
            id: "3571",
            name: "Y\u00ean C\u00e1t",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3572", name: "Y\u00ean L\u1ec5", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "23110", name: "45", prefix: "Ph\u1ed1" },
          {
            id: "23111",
            name: "B\u00e3i Tr\u00e0nh - Nghi S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23112",
            name: "H\u1ed3 Ch\u00ed Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23113",
            name: "Qu\u1ed1c l\u1ed9 15A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23114",
            name: "T\u1ec9nh l\u1ed9 514",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "252",
        name: "N\u00f4ng C\u1ed1ng",
        wards: [
          { id: "3573", name: "C\u00f4ng B\u00ecnh", prefix: "X\u00e3" },
          { id: "3574", name: "C\u00f4ng Ch\u00ednh", prefix: "X\u00e3" },
          { id: "3575", name: "C\u00f4ng Li\u00eam", prefix: "X\u00e3" },
          { id: "3576", name: "Ho\u00e0ng Giang", prefix: "X\u00e3" },
          { id: "3577", name: "Ho\u00e0ng S\u01a1n", prefix: "X\u00e3" },
          { id: "3578", name: "Minh Kh\u00f4i", prefix: "X\u00e3" },
          { id: "3579", name: "Minh Ngh\u0129a", prefix: "X\u00e3" },
          { id: "3580", name: "Minh Th\u1ecd", prefix: "X\u00e3" },
          {
            id: "3581",
            name: "N\u00f4ng C\u1ed1ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3582", name: "T\u00e2n Khang", prefix: "X\u00e3" },
          { id: "3583", name: "T\u00e2n Ph\u00fac", prefix: "X\u00e3" },
          { id: "3584", name: "T\u00e2n Th\u1ecd", prefix: "X\u00e3" },
          { id: "3585", name: "T\u1ebf L\u1ee3i", prefix: "X\u00e3" },
          { id: "3586", name: "T\u1ebf N\u00f4ng", prefix: "X\u00e3" },
          { id: "3587", name: "T\u1ebf T\u00e2n", prefix: "X\u00e3" },
          { id: "3588", name: "T\u1ebf Th\u1eafng", prefix: "X\u00e3" },
          { id: "3589", name: "Th\u0103ng B\u00ecnh", prefix: "X\u00e3" },
          { id: "3590", name: "Th\u0103ng Long", prefix: "X\u00e3" },
          { id: "3591", name: "Th\u0103ng Th\u1ecd", prefix: "X\u00e3" },
          { id: "3592", name: "Trung Ch\u00ednh", prefix: "X\u00e3" },
          { id: "3593", name: "Trung Th\u00e0nh", prefix: "X\u00e3" },
          { id: "3594", name: "Trung \u00dd", prefix: "X\u00e3" },
          { id: "3595", name: "Tr\u01b0\u1eddng Giang", prefix: "X\u00e3" },
          { id: "3596", name: "Tr\u01b0\u1eddng Minh", prefix: "X\u00e3" },
          { id: "3597", name: "Tr\u01b0\u1eddng S\u01a1n", prefix: "X\u00e3" },
          { id: "3598", name: "Tr\u01b0\u1eddng Trung", prefix: "X\u00e3" },
          { id: "3599", name: "T\u01b0\u1ee3ng L\u0129nh", prefix: "X\u00e3" },
          { id: "3600", name: "T\u01b0\u1ee3ng S\u01a1n", prefix: "X\u00e3" },
          { id: "3601", name: "T\u01b0\u1ee3ng V\u0103n", prefix: "X\u00e3" },
          { id: "3602", name: "V\u1ea1n Ho\u00e0", prefix: "X\u00e3" },
          { id: "3603", name: "V\u1ea1n Th\u1eafng", prefix: "X\u00e3" },
          { id: "3604", name: "V\u1ea1n Thi\u1ec7n", prefix: "X\u00e3" },
          { id: "3605", name: "Y\u00ean M\u1ef9", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "23115", name: "45", prefix: "Ph\u1ed1" },
          {
            id: "23116",
            name: "Minh Ngh\u0129a  Ho\u00e0ng Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23117",
            name: "Nam Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23118",
            name: "Th\u0103ng Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23119",
            name: "T\u1ec9nh l\u1ed9 505",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23120",
            name: "T\u1ec9nh l\u1ed9 506",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23121",
            name: "T\u1ec9nh l\u1ed9 525",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23122",
            name: "V\u1ea1n Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "253",
        name: "Quan H\u00f3a",
        wards: [
          { id: "3606", name: "Hi\u1ec1n Chung", prefix: "X\u00e3" },
          { id: "3607", name: "Hi\u1ec1n Ki\u1ec7t", prefix: "X\u00e3" },
          { id: "3608", name: "H\u1ed3i Xu\u00e2n", prefix: "X\u00e3" },
          { id: "3609", name: "Nam \u0110\u1ed9ng", prefix: "X\u00e3" },
          { id: "3610", name: "Nam Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "3611", name: "Nam Xu\u00e2n", prefix: "X\u00e3" },
          { id: "3612", name: "Ph\u00fa L\u1ec7", prefix: "X\u00e3" },
          { id: "3613", name: "Ph\u00fa Nghi\u00eam", prefix: "X\u00e3" },
          { id: "3614", name: "Ph\u00fa S\u01a1n", prefix: "X\u00e3" },
          { id: "3615", name: "Ph\u00fa Thanh", prefix: "X\u00e3" },
          { id: "3616", name: "Ph\u00fa Xu\u00e2n", prefix: "X\u00e3" },
          { id: "3617", name: "Quan H\u00f3a", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "3618", name: "Th\u00e0nh S\u01a1n", prefix: "X\u00e3" },
          { id: "3619", name: "Thanh Xu\u00e2n", prefix: "X\u00e3" },
          { id: "3620", name: "Thi\u00ean Ph\u1ee7", prefix: "X\u00e3" },
          { id: "3621", name: "Trung S\u01a1n", prefix: "X\u00e3" },
          { id: "3622", name: "Trung Th\u00e0nh", prefix: "X\u00e3" },
          { id: "3623", name: "Xu\u00e2n Ph\u00fa", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "23123",
            name: "Qu\u1ed1c l\u1ed9 15A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23124",
            name: "Qu\u1ed1c l\u1ed9 15C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23125",
            name: "T\u1ec9nh l\u1ed9 520",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "254",
        name: "Quan S\u01a1n",
        wards: [
          { id: "3624", name: "M\u01b0\u1eddng M\u00ecn", prefix: "X\u00e3" },
          { id: "3625", name: "Na M\u00e8o", prefix: "X\u00e3" },
          { id: "3626", name: "Quan S\u01a1n", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "3627", name: "S\u01a1n \u0110i\u1ec7n", prefix: "X\u00e3" },
          { id: "3628", name: "S\u01a1n H\u00e0", prefix: "X\u00e3" },
          { id: "3629", name: "S\u01a1n L\u01b0", prefix: "X\u00e3" },
          { id: "3630", name: "S\u01a1n Th\u1ee7y", prefix: "X\u00e3" },
          { id: "3631", name: "Tam L\u01b0", prefix: "X\u00e3" },
          { id: "3632", name: "Tam Thanh", prefix: "X\u00e3" },
          { id: "3633", name: "Trung H\u1ea1", prefix: "X\u00e3" },
          { id: "3634", name: "Trung Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "3635", name: "Trung Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "3636", name: "Trung Xu\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "23126",
            name: "T\u1ec9nh l\u1ed9 217",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "255",
        name: "Qu\u1ea3ng X\u01b0\u01a1ng",
        wards: [
          { id: "3637", name: "Qu\u1ea3ng B\u00ecnh", prefix: "X\u00e3" },
          { id: "3638", name: "Qu\u1ea3ng Ch\u00ednh", prefix: "X\u00e3" },
          { id: "3639", name: "Qu\u1ea3ng \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "3640", name: "Qu\u1ea3ng \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "3641", name: "Qu\u1ea3ng Giao", prefix: "X\u00e3" },
          { id: "3642", name: "Qu\u1ea3ng H\u1ea3i", prefix: "X\u00e3" },
          { id: "3643", name: "Qu\u1ea3ng H\u00f2a", prefix: "X\u00e3" },
          { id: "3644", name: "Qu\u1ea3ng H\u1ee3p", prefix: "X\u00e3" },
          { id: "3645", name: "Qu\u1ea3ng Kh\u00ea", prefix: "X\u00e3" },
          { id: "3646", name: "Qu\u1ea3ng L\u0129nh", prefix: "X\u00e3" },
          { id: "3647", name: "Qu\u1ea3ng L\u1ed9c", prefix: "X\u00e3" },
          { id: "3648", name: "Qu\u1ea3ng L\u1ee3i", prefix: "X\u00e3" },
          { id: "3649", name: "Qu\u1ea3ng Long", prefix: "X\u00e3" },
          { id: "3650", name: "Qu\u1ea3ng L\u01b0u", prefix: "X\u00e3" },
          { id: "3651", name: "Qu\u1ea3ng Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "3652", name: "Qu\u1ea3ng Nham", prefix: "X\u00e3" },
          { id: "3653", name: "Qu\u1ea3ng Nh\u00e2n", prefix: "X\u00e3" },
          { id: "3654", name: "Qu\u1ea3ng Ninh", prefix: "X\u00e3" },
          { id: "3655", name: "Qu\u1ea3ng Phong", prefix: "X\u00e3" },
          { id: "3656", name: "Qu\u1ea3ng Ph\u00fac", prefix: "X\u00e3" },
          { id: "3657", name: "Qu\u1ea3ng T\u00e2n", prefix: "X\u00e3" },
          { id: "3658", name: "Qu\u1ea3ng Th\u1ea1ch", prefix: "X\u00e3" },
          { id: "3659", name: "Qu\u1ea3ng Th\u00e1i", prefix: "X\u00e3" },
          { id: "3660", name: "Qu\u1ea3ng Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "3661", name: "Qu\u1ea3ng Trung", prefix: "X\u00e3" },
          {
            id: "3662",
            name: "Qu\u1ea3ng Tr\u01b0\u1eddng",
            prefix: "X\u00e3",
          },
          { id: "3663", name: "Qu\u1ea3ng V\u0103n", prefix: "X\u00e3" },
          { id: "3664", name: "Qu\u1ea3ng V\u1ecdng", prefix: "X\u00e3" },
          {
            id: "3665",
            name: "Qu\u1ea3ng X\u01b0\u01a1ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3666", name: "Qu\u1ea3ng Y\u00ean", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "23127", name: "45", prefix: "Ph\u1ed1" },
          { id: "23128", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23129", name: "4A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23130", name: "4B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23131", name: "Ba Voi", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23132",
            name: "L\u01b0u - B\u00ecnh -Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23133",
            name: "Ph\u1ed1 M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23134",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23135",
            name: "Qu\u1ed1c l\u1ed9 45",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23136",
            name: "Qu\u1ed1c l\u1ed9 47",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23137",
            name: "Qu\u1ed1c l\u1ed9 4C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23138",
            name: "T\u00e2n Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23139",
            name: "T\u00e2n-Tr\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23140",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23141",
            name: "T\u1ec9nh l\u1ed9 4A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23142",
            name: "V\u0103n Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23143",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2864",
            name: "H\u00f9ng S\u01a1n Villa",
            lat: "19.719720840454",
            lng: "105.84370422363",
          },
        ],
      },
      {
        id: "256",
        name: "S\u1ea7m S\u01a1n",
        wards: [
          { id: "3667", name: "B\u1eafc S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "3668", name: "Qu\u1ea3ng Ch\u00e2u", prefix: "X\u00e3" },
          { id: "3669", name: "Qu\u1ea3ng C\u01b0", prefix: "X\u00e3" },
          { id: "3670", name: "Qu\u1ea3ng \u0110\u1ea1i", prefix: "X\u00e3" },
          { id: "3671", name: "Qu\u1ea3ng H\u00f9ng", prefix: "X\u00e3" },
          { id: "3672", name: "Qu\u1ea3ng Minh", prefix: "X\u00e3" },
          { id: "3673", name: "Qu\u1ea3ng Th\u1ecd", prefix: "X\u00e3" },
          {
            id: "3674",
            name: "Qu\u1ea3ng Ti\u1ebfn",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3675", name: "Qu\u1ea3ng Vinh", prefix: "X\u00e3" },
          { id: "3676", name: "Trung S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "3677",
            name: "Tr\u01b0\u1eddng S\u01a1n",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "23144", name: "3D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23145", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "23146",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23147",
            name: "B\u00ecnh T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23148",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23149",
            name: "C\u1ea7m B\u00e1 Th\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23150",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23151",
            name: "C\u01b0\u1eddng Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23152",
            name: "\u0110\u1eb7ng Huy Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23153",
            name: "\u0110\u1eb7ng Thai Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23154",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23155",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23156", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "23157",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23158",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23159",
            name: "Khang Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23160",
            name: "L\u00ea Ho\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23161",
            name: "L\u00ea H\u1eefu L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23162", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23163",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23164",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23165",
            name: "L\u00ea V\u0103n H\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23166",
            name: "L\u00ea V\u0103n T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23167",
            name: "L\u01b0\u01a1ng Ng\u1ecdc Quy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23168",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23169",
            name: "L\u01b0\u01a1ng V\u0103n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23170",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23171",
            name: "M\u1ea1c Th\u1ecb B\u01b0\u1edfi",
            prefix: "Ph\u1ed1",
          },
          { id: "23172", name: "Minh Khai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23173",
            name: "Nam S\u00f4ng M\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23174",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23175",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23176",
            name: "Ng\u00f4 V\u0103n S\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23177",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23178",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23179",
            name: "Nguy\u1ec5n H\u1ed3ng L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23180",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23181",
            name: "Nguy\u1ec5n S\u1ef9 D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23182",
            name: "Nguy\u1ec5n Th\u1ecb L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23183",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23184",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23185",
            name: "Nguy\u1ec5n Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23186",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23187",
            name: "Ph\u1ea1m B\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23188",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23189",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23190",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23191",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23192",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23193", name: "Quang Gi\u00e1p", prefix: "Ph\u1ed1" },
          {
            id: "23194",
            name: "Qu\u1ed1c l\u1ed9 47",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23195",
            name: "T\u00e2n \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23196",
            name: "T\u00e2y S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23197",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23198",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23199",
            name: "To\u00e0n Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23200",
            name: "T\u00f4n Th\u1ea5t Thuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23201",
            name: "T\u1ed1ng Duy T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23202",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23203",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23204",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23205",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23206",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23207",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23208",
            name: "Tr\u1ea7n T\u1ebf X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23209",
            name: "Tr\u1ea7n Xu\u00e2n So\u1ea1n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23210",
            name: "Trung K\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23211",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23212",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23213",
            name: "Xu\u00e2n Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2865",
            name: "FLC Lux City Samson",
            lat: "19.763648986816",
            lng: "105.91827392578",
          },
          {
            id: "2866",
            name: "Khu \u0111\u00f4 th\u1ecb sinh th\u00e1i S\u00f4ng \u0110\u01a1",
            lat: "19.732830047607",
            lng: "105.89068603516",
          },
        ],
      },
      {
        id: "257",
        name: "Th\u1ea1ch Th\u00e0nh",
        wards: [
          { id: "3678", name: "Kim T\u00e2n", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "3679", name: "Ng\u1ecdc Tr\u1ea1o", prefix: "X\u00e3" },
          { id: "3680", name: "Th\u1ea1ch B\u00ecnh", prefix: "X\u00e3" },
          { id: "3681", name: "Th\u1ea1ch C\u1ea9m", prefix: "X\u00e3" },
          { id: "3682", name: "Th\u1ea1ch \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "3683", name: "Th\u1ea1ch \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "3684", name: "Th\u1ea1ch L\u00e2m", prefix: "X\u00e3" },
          { id: "3685", name: "Th\u1ea1ch Long", prefix: "X\u00e3" },
          { id: "3686", name: "Th\u1ea1ch Qu\u1ea3ng", prefix: "X\u00e3" },
          { id: "3687", name: "Th\u1ea1ch S\u01a1n", prefix: "X\u00e3" },
          { id: "3688", name: "Th\u1ea1ch T\u00e2n", prefix: "X\u00e3" },
          { id: "3689", name: "Th\u1ea1ch T\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "3690", name: "Th\u00e0nh An", prefix: "X\u00e3" },
          { id: "3691", name: "Th\u00e0nh C\u00f4ng", prefix: "X\u00e3" },
          { id: "3692", name: "Th\u00e0nh H\u01b0ng", prefix: "X\u00e3" },
          { id: "3693", name: "Th\u00e0nh Kim", prefix: "X\u00e3" },
          { id: "3694", name: "Th\u00e0nh Long", prefix: "X\u00e3" },
          { id: "3695", name: "Th\u00e0nh Minh", prefix: "X\u00e3" },
          { id: "3696", name: "Th\u00e0nh M\u1ef9", prefix: "X\u00e3" },
          { id: "3697", name: "Th\u00e0nh T\u00e2m", prefix: "X\u00e3" },
          { id: "3698", name: "Th\u00e0nh T\u00e2n", prefix: "X\u00e3" },
          { id: "3699", name: "Th\u00e0nh Th\u1ecd", prefix: "X\u00e3" },
          { id: "3700", name: "Th\u00e0nh Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "3701", name: "Th\u00e0nh Tr\u1ef1c", prefix: "X\u00e3" },
          { id: "3702", name: "Th\u00e0nh V\u00e2n", prefix: "X\u00e3" },
          { id: "3703", name: "Th\u00e0nh Vinh", prefix: "X\u00e3" },
          { id: "3704", name: "Th\u00e0nh Y\u00ean", prefix: "X\u00e3" },
          { id: "3705", name: "V\u00e2n Du", prefix: "Th\u1ecb tr\u1ea5n" },
        ],
        streets: [
          { id: "23214", name: "45", prefix: "Ph\u1ed1" },
          {
            id: "23215",
            name: "H\u1ed3 Ch\u00ed Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23216",
            name: "Qu\u1ed1c l\u1ed9 45",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23217",
            name: "T\u1ec9nh l\u1ed9 516",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23218",
            name: "T\u1ec9nh l\u1ed9 516B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23219",
            name: "T\u1ec9nh l\u1ed9 522",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23220",
            name: "T\u1ec9nh l\u1ed9 523",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "258",
        name: "Thanh H\u00f3a",
        wards: [
          { id: "3706", name: "An Ho\u1ea1ch", prefix: "Ph\u01b0\u1eddng" },
          { id: "3707", name: "Ba \u0110\u00ecnh", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "3708",
            name: "\u0110i\u1ec7n Bi\u00ean",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "3709",
            name: "\u0110\u00f4ng C\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "3710", name: "\u0110\u00f4ng H\u1ea3i", prefix: "X\u00e3" },
          { id: "3711", name: "\u0110\u00f4ng H\u01b0ng", prefix: "X\u00e3" },
          {
            id: "3712",
            name: "\u0110\u00f4ng H\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3713", name: "\u0110\u00f4ng L\u0129nh", prefix: "X\u00e3" },
          {
            id: "3714",
            name: "\u0110\u00f4ng S\u01a1n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3715", name: "\u0110\u00f4ng T\u00e2n", prefix: "X\u00e3" },
          {
            id: "3716",
            name: "\u0110\u00f4ng Th\u1ecd",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "3717",
            name: "\u0110\u00f4ng V\u1ec7",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3718", name: "\u0110\u00f4ng Vinh", prefix: "X\u00e3" },
          {
            id: "3719",
            name: "H\u00e0m R\u1ed3ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3720", name: "Ho\u1eb1ng Anh", prefix: "X\u00e3" },
          { id: "3721", name: "Ho\u1eb1ng \u0110\u1ea1i", prefix: "X\u00e3" },
          { id: "3722", name: "Ho\u1eb1ng Long", prefix: "X\u00e3" },
          { id: "3723", name: "Ho\u1eb1ng L\u00fd", prefix: "X\u00e3" },
          { id: "3724", name: "Ho\u1eb1ng Quang", prefix: "X\u00e3" },
          { id: "3725", name: "Lam S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "3726", name: "Nam Ng\u1ea1n", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "3727",
            name: "Ng\u1ecdc Tr\u1ea1o",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3728", name: "Ph\u00fa S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "3729", name: "Qu\u1ea3ng C\u00e1t", prefix: "X\u00e3" },
          { id: "3730", name: "Qu\u1ea3ng \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "3731", name: "Qu\u1ea3ng H\u01b0ng", prefix: "X\u00e3" },
          { id: "3732", name: "Qu\u1ea3ng Ph\u00fa", prefix: "X\u00e3" },
          { id: "3733", name: "Qu\u1ea3ng T\u00e2m", prefix: "X\u00e3" },
          { id: "3734", name: "Qu\u1ea3ng Th\u1eafng", prefix: "X\u00e3" },
          { id: "3735", name: "Qu\u1ea3ng Th\u00e0nh", prefix: "X\u00e3" },
          { id: "3736", name: "Qu\u1ea3ng Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "3737", name: "T\u00e2n S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "3738",
            name: "T\u00e0o Xuy\u00ean",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3739", name: "Th\u00e0nh Tr\u1ecdng", prefix: "X\u00e3" },
          { id: "3740", name: "Thi\u1ec7u D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "3741", name: "Thi\u1ec7u Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "3742", name: "Thi\u1ec7u V\u00e2n", prefix: "X\u00e3" },
          {
            id: "3743",
            name: "Tr\u01b0\u1eddng Thi",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "23221", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23222", name: "395", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23223", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23224", name: "4A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23225", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23226", name: "66", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23227",
            name: "\u00c1i S\u01a1n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23228",
            name: "An Ph\u00fa \u0110\u00f4ng 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23229",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          { id: "23230", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "23231",
            name: "B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23232",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23233", name: "B\u00e0o Ngo\u1ea1i", prefix: "Ph\u1ed1" },
          {
            id: "23234",
            name: "B\u1ebfn Ng\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23235",
            name: "B\u1ebfn Than",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23236",
            name: "B\u00f9i S\u1ef9 L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23237",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23238",
            name: "C\u1ea7m B\u00e1 Th\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23239",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23240",
            name: "Cao \u0110i\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23241", name: "Cao S\u01a1n", prefix: "Ph\u1ed1" },
          {
            id: "23242",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23243",
            name: "Chi L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23244",
            name: "Chu Nguy\u00ean L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23245",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23246",
            name: "C\u1ed1ng T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23247", name: "C\u1ed9t C\u1edd", prefix: "Ph\u1ed1" },
          { id: "23248", name: "CSEDP", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23249",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23250",
            name: "C\u1eeda H\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23251",
            name: "C\u1eeda H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23252",
            name: "C\u1eeda T\u1ea3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23253",
            name: "C\u1eeda Ti\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23254",
            name: "D\u00e3 T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23273", name: "D\u1ed1c Ga", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23293",
            name: "D\u1ee5 T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23294",
            name: "D\u01b0\u01a1ng \u0110\u00ecnh Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23295",
            name: "D\u01b0\u01a1ng Ph\u00fa Chung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23296",
            name: "D\u01b0\u01a1ng V\u0103n Nga",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23297", name: "Duy T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23255",
            name: "\u0110\u1ea1i Kh\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23256",
            name: "\u0110\u1ea1i L\u1ed9 \u0110\u00f4ng T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23257",
            name: "\u0110\u1ea1i l\u1ed9 V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23258", name: "\u0110\u1eb7ng T\u1ea5t", prefix: "Ph\u1ed1" },
          {
            id: "23259",
            name: "\u0110\u1eb7ng Ti\u1ebfn \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23261",
            name: "\u0110\u00e0o Duy Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23262",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23260",
            name: "\u0110\u00e0o \u0110\u1ee9c Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23263",
            name: "\u0110\u00e0o T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23264",
            name: "\u0110inh Ch\u01b0\u01a1ng D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23265",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23266",
            name: "\u0110inh H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23267",
            name: "\u0110\u00ecnh H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23268",
            name: "\u0110inh L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23269", name: "\u0110inh Li\u1ec7t", prefix: "Ph\u1ed1" },
          {
            id: "23270",
            name: "\u0110\u1ed7 \u0110\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23271",
            name: "\u0110\u1ed7 H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23272",
            name: "\u0110oan H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23274",
            name: "\u0110\u1ed9i Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23275",
            name: "\u0110\u1ed9i Cung 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23276",
            name: "\u0110\u00f4ng \u00c1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23277",
            name: "\u0110\u1ed3ng Bam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23278",
            name: "\u0110\u1ed3ng C\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23279",
            name: "\u0110\u00f4ng H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23280",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23281",
            name: "\u0110\u00f4ng H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23282",
            name: "\u0110\u00f4ng H\u01b0\u01a1ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23283",
            name: "\u0110\u00f4ng H\u01b0\u01a1ng 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23284",
            name: "\u0110\u1ed3ng Khoai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23285",
            name: "\u0110\u1ed3ng Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23286",
            name: "\u0110\u00f4ng L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23287",
            name: "\u0110\u1ed3ng L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23288",
            name: "\u0110\u1ed3ng L\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23289",
            name: "\u0110\u00f4ng Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23290",
            name: "\u0110\u00f4ng Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23291",
            name: "\u0110\u00f4ng T\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23292",
            name: "\u0110\u00f4ng V\u1ec7 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23298",
            name: "H\u00e0 V\u0103n Mao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23299", name: "H\u1ea1c Oa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23300",
            name: "H\u1ea1c Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23301",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23302",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23303",
            name: "H\u00e0m T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23304",
            name: "H\u00e0n Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23305",
            name: "H\u00e0n M\u1eb7c T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23306",
            name: "H\u00e0n Thuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23307",
            name: "H\u00e0ng \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23308",
            name: "H\u00e0ng G\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23309",
            name: "H\u00e0ng Nan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23310",
            name: "H\u00e0ng S\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23311",
            name: "H\u00e0ng Than",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23312",
            name: "H\u1eadu L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23313", name: "H\u1eadu Th\u00e0nh", prefix: "Ph\u1ed1" },
          {
            id: "23314",
            name: "Hi\u1ec7p Th\u00e0nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23315",
            name: "H\u1ed3 \u0110\u1eafc Di",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23316",
            name: "H\u1ed3 Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23317",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23318",
            name: "Ho\u00e0ng B\u00e1 \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23319",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23320",
            name: "Ho\u00e0ng Xu\u00e2n Vi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23321",
            name: "H\u1ed3ng \u0110\u1ee9c 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23322",
            name: "H\u1ee3p Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23323",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23324",
            name: "K\u00eanh B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23325",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23326",
            name: "Lai Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23327",
            name: "L\u00ea B\u00e1 Gi\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23328",
            name: "L\u00ea C\u00f4ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23329",
            name: "L\u00ea \u0110\u00ecnh Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23330",
            name: "L\u00ea Ho\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23331",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23332",
            name: "L\u00ea H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23333",
            name: "L\u00ea H\u1eefu L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23334",
            name: "L\u00ea Kh\u1eafc Th\u00e1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23335",
            name: "L\u00ea Kh\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23336", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23337",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23338",
            name: "L\u00ea Ng\u1ecdc H\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23339",
            name: "L\u00ea Ni\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23340",
            name: "L\u00ea Ph\u1ee5ng Hi\u1ec3u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23341",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23342",
            name: "L\u00ea T\u1ea5t \u0110\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23343",
            name: "L\u00ea Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23344",
            name: "L\u00ea Th\u1ea7n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23345",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23346",
            name: "L\u00ea Th\u1ebf B\u00f9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23347",
            name: "L\u00ea Th\u1ebf Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23348",
            name: "L\u00ea Th\u1ecb Hoa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23349",
            name: "L\u00ea Th\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23350",
            name: "L\u00ea V\u00e3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23351",
            name: "L\u00ea V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23352",
            name: "L\u00ea V\u0103n H\u01b0u",
            prefix: "Ph\u1ed1",
          },
          { id: "23353", name: "L\u00f2 Chum", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23354",
            name: "L\u1ed9c H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23355",
            name: "L\u01b0\u01a1ng \u0110\u1eafc B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23356",
            name: "L\u01b0\u01a1ng \u0110\u1ecbnh C\u1ee7a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23357",
            name: "L\u01b0\u01a1ng H\u1eefu Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23358",
            name: "L\u01b0\u01a1ng Ng\u1ecdc Quy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23359",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23360",
            name: "L\u01b0u H\u1eefu Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23361",
            name: "L\u00fd \u0110\u1ea1o Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23362",
            name: "L\u00fd Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23363",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23364",
            name: "L\u00fd Th\u00e1i T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23365",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23366",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23367",
            name: "Mai An Ti\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23368",
            name: "Mai Xu\u00e2n D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23369",
            name: "M\u1eadt S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23370",
            name: "Minh C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23371", name: "Minh Khai", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23372", name: "M\u00f4i", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23373",
            name: "M\u1ed9ng Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23374",
            name: "M\u01b0\u01a1ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23375", name: "Nam Cao", prefix: "Ph\u1ed1" },
          { id: "23376", name: "Nam Ch\u1ee3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23377",
            name: "Nam Ng\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23378", name: "Nam S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23379",
            name: "Nam S\u01a1n 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23380",
            name: "Ng\u00e3 ba Voi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23381",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23382",
            name: "Ng\u00f4 S\u1ef9 Li\u00ean",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23383",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23384",
            name: "Ng\u00f4 T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23385",
            name: "Ng\u1ecdc Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23386",
            name: "Ng\u1ecdc N\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23387",
            name: "Nguy\u1ec5n B\u00e1 Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23388",
            name: "Nguy\u1ec5n B\u1eb7c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23389",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23390",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23391",
            name: "Nguy\u1ec5n Ch\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23392",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23393",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23394",
            name: "Nguy\u1ec5n \u0110\u1ee9c Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23395",
            name: "Nguy\u1ec5n Gia Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23396",
            name: "Nguy\u1ec5n Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23397",
            name: "Nguy\u1ec5n Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23398",
            name: "Nguy\u00ean H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23399",
            name: "Nguy\u1ec5n Huy Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23400", name: "Nguy\u1ec5n Huy T\u1ef1", prefix: "Ph\u1ed1" },
          {
            id: "23401",
            name: "Nguy\u1ec5n Kh\u1eafc Vi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23402",
            name: "Nguy\u1ec5n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23403",
            name: "Nguy\u1ec5n Kho\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23404",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23405",
            name: "Nguy\u1ec5n M\u1ed9ng Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23406",
            name: "Nguy\u1ec5n Nh\u1eef So\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23407",
            name: "Nguy\u1ec5n Ph\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23408",
            name: "Nguy\u1ec5n Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23409",
            name: "Nguy\u1ec5n Qu\u1ef3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23410",
            name: "Nguy\u1ec5n S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23411",
            name: "Nguy\u1ec5n T\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23412",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23413",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23414",
            name: "Nguy\u1ec5n Th\u1ecb Th\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23415",
            name: "Nguy\u1ec5n Thi\u1ebfp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23416",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23417",
            name: "Nguy\u1ec5n T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23418",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23419",
            name: "Nguy\u1ec5n Trinh Ti\u1ebfp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23420",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23421",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23422",
            name: "Nguy\u1ec5n Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23423",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23424",
            name: "Nh\u1eef B\u00e1 S\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23425",
            name: "Ph\u1ea1m B\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23426",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23427",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23428",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23429",
            name: "Ph\u1ea1m S\u01b0 M\u1ea1nh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23430",
            name: "Ph\u1ea1m V\u0103n Hinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23431",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23432",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23433",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23434",
            name: "Phan Huy Ch\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23435",
            name: "Phan Huy \u00cdch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23436",
            name: "Ph\u00f3 \u0110\u1ee9c Ch\u00ednh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23437",
            name: "Ph\u1ed1 T\u00e2y Ga",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23438",
            name: "Ph\u00fa Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23439",
            name: "Ph\u00fa C\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23440", name: "Ph\u00f9 L\u01b0u", prefix: "Ph\u1ed1" },
          {
            id: "23441",
            name: "Ph\u00fa Qu\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23442",
            name: "Ph\u00fa Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23443",
            name: "Ph\u00fa Th\u1ecd 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23444",
            name: "Ph\u00fa Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23445",
            name: "Ph\u00fa Th\u1ee9 \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23446",
            name: "Ph\u00fa Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23447",
            name: "Ph\u00fa Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23448",
            name: "Ph\u00f9ng Kh\u1eafc Khoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23449",
            name: "Ph\u01b0\u1ee3ng Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23450", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23451",
            name: "Quang Trung 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23452",
            name: "Quang Trung 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23453",
            name: "Qu\u1ea3ng X\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23454",
            name: "Qu\u1ea3ng Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23455",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23456",
            name: "Qu\u1ed1c l\u1ed9 45",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23457",
            name: "Qu\u1ed1c l\u1ed9 47",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23458", name: "T\u00e2n An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23459",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23460",
            name: "T\u1ea3n \u0110\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23461", name: "T\u00e2n Nam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23462",
            name: "T\u00e2n S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23463",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23464",
            name: "T\u00e0o M\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23465",
            name: "T\u00e2y Nam 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23466",
            name: "T\u00e2y S\u01a1n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23467",
            name: "T\u00e2y S\u01a1n 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23468",
            name: "Th\u1eafng L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23469",
            name: "Thanh Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23470",
            name: "Th\u1ea1nh L\u1ed9c 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23471",
            name: "Th\u1ea1nh L\u1ed9c 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23472",
            name: "Th\u00e0nh Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23473",
            name: "Th\u00e0nh Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23474",
            name: "Th\u1ea1nh Xu\u00e2n 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23475",
            name: "Th\u1ea1nh Xu\u00e2n 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23476",
            name: "Th\u1ebf L\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23477",
            name: "Th\u1ecd H\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23478",
            name: "Th\u00f4i H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23479",
            name: "Th\u1ed1ng Nh\u1ea5t 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23480",
            name: "Th\u1ee7 Ph\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23481",
            name: "T\u1ecbch \u0110i\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23482",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23483",
            name: "T\u00f4 V\u0129nh Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23484",
            name: "T\u00f4n Quang Phi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23485",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23486",
            name: "T\u1ed1ng Duy T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23487",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23488",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23489",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23490",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23491",
            name: "Tr\u1ea7n \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23492",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23493",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23494",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23495",
            name: "Tr\u1ea7n Kh\u00e1t Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23496",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23497",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23498",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23499",
            name: "Tr\u1ea7n Oanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23500",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23502",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23501",
            name: "Tr\u1ea7n Quang \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23503",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23504",
            name: "Tr\u1ea7n Th\u1ecb Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23505",
            name: "Tr\u1ea7n Th\u1ee7 \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23506",
            name: "Tr\u1ea7n V\u0103n \u01a0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23507",
            name: "Tr\u1ea7n Xu\u00e2n So\u1ea1n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23508",
            name: "Tr\u00edch S\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23509",
            name: "Tri\u1ec7u Quang Ph\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23510",
            name: "Tri\u1ec7u Qu\u1ed1c \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23511",
            name: "Tr\u1ecbnh Kh\u1ea3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23512",
            name: "Tr\u1ecbnh Ng\u00f5 Tr\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23513",
            name: "Tr\u1ecbnh Th\u1ebf L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23514",
            name: "Tr\u1ecbnh Th\u1ecb Ng\u1ecdc L\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23515",
            name: "Tr\u1ecbnh Th\u1ecb Ng\u1ecdc Tr\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23516", name: "Trung S\u01a1n", prefix: "Ph\u1ed1" },
          {
            id: "23517",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23518",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23519",
            name: "Tr\u01b0\u1eddng Thi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23520",
            name: "Tr\u01b0\u01a1ng V\u0103n \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23521",
            name: "T\u1eeb \u0110\u1ea1o H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23522",
            name: "T\u00fa M\u1ee1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23523",
            name: "T\u00fa X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23524",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23525",
            name: "V\u1ec7 \u0110\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23526",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23527",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23528", name: "Voi", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23529",
            name: "V\u0169 Tr\u1ecdng Ph\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23530",
            name: "Xu\u00e2n Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23531", name: "\u1ef6 Lan", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23532",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2867",
            name: "Eurowindow Park City",
            lat: "19.808101654053",
            lng: "105.80165100098",
          },
          {
            id: "2868",
            name: "FLC Complex Thanh H\u00f3a",
            lat: "19.782211303711",
            lng: "105.77973175049",
          },
          {
            id: "2869",
            name: "Khu \u0111\u00f4 th\u1ecb Xanh",
            lat: "19.806692123413",
            lng: "105.78517913818",
          },
          {
            id: "2870",
            name: "Ph\u00fa S\u01a1n",
            lat: "19.811485290527",
            lng: "105.75959777832",
          },
          {
            id: "2871",
            name: "T\u00e2n Th\u00e0nh Eco City",
            lat: "19.782636642456",
            lng: "105.77639770508",
          },
          {
            id: "2872",
            name: "Tecco Tower Thanh H\u00f3a",
            lat: "19.795038223267",
            lng: "105.78089141846",
          },
          {
            id: "2873",
            name: "Vincity Thanh H\u00f3a",
            lat: "19.806692123413",
            lng: "105.78517913818",
          },
          {
            id: "2874",
            name: "Vincom Thanh H\u00f3a",
            lat: "19.80823135376",
            lng: "105.77690124512",
          },
        ],
      },
      {
        id: "259",
        name: "Thi\u1ec7u H\u00f3a",
        wards: [
          { id: "3744", name: "Thi\u1ec7u Ch\u00e2u", prefix: "X\u00e3" },
          { id: "3745", name: "Thi\u1ec7u Ch\u00ednh", prefix: "X\u00e3" },
          { id: "3746", name: "Thi\u1ec7u C\u00f4ng", prefix: "X\u00e3" },
          { id: "3748", name: "Thi\u1ec7u Duy", prefix: "X\u00e3" },
          { id: "3747", name: "Thi\u1ec7u \u0110\u00f4", prefix: "X\u00e3" },
          { id: "3749", name: "Thi\u1ec7u Giang", prefix: "X\u00e3" },
          { id: "3750", name: "Thi\u1ec7u Giao", prefix: "X\u00e3" },
          { id: "3751", name: "Thi\u1ec7u H\u00f2a", prefix: "X\u00e3" },
          { id: "3752", name: "Thi\u1ec7u H\u1ee3p", prefix: "X\u00e3" },
          { id: "3753", name: "Thi\u1ec7u Long", prefix: "X\u00e3" },
          { id: "3754", name: "Thi\u1ec7u L\u00fd", prefix: "X\u00e3" },
          { id: "3755", name: "Thi\u1ec7u Minh", prefix: "X\u00e3" },
          { id: "3756", name: "Thi\u1ec7u Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "3757", name: "Thi\u1ec7u Nguy\u00ean", prefix: "X\u00e3" },
          { id: "3758", name: "Thi\u1ec7u Ph\u00fa", prefix: "X\u00e3" },
          { id: "3759", name: "Thi\u1ec7u Ph\u00fac", prefix: "X\u00e3" },
          { id: "3760", name: "Thi\u1ec7u Quang", prefix: "X\u00e3" },
          { id: "3761", name: "Thi\u1ec7u T\u00e2m", prefix: "X\u00e3" },
          { id: "3762", name: "Thi\u1ec7u T\u00e2n", prefix: "X\u00e3" },
          { id: "3763", name: "Thi\u1ec7u Th\u00e0nh", prefix: "X\u00e3" },
          { id: "3764", name: "Thi\u1ec7u Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "3765", name: "Thi\u1ec7u Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "3766", name: "Thi\u1ec7u To\u00e1n", prefix: "X\u00e3" },
          { id: "3767", name: "Thi\u1ec7u Trung", prefix: "X\u00e3" },
          { id: "3768", name: "Thi\u1ec7u V\u1eadn", prefix: "X\u00e3" },
          { id: "3769", name: "Thi\u1ec7u Vi\u00ean", prefix: "X\u00e3" },
          { id: "3770", name: "Thi\u1ec7u V\u0169", prefix: "X\u00e3" },
          {
            id: "3771",
            name: "V\u1ea1n H\u00e0",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          { id: "23533", name: "45", prefix: "Ph\u1ed1" },
          { id: "23534", name: "502", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23535", name: "515B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23538",
            name: "D\u01b0\u01a1ng H\u00f2a 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23536",
            name: "\u0110\u00ecnh H\u01b0\u01a1ng - Gi\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23537",
            name: "\u0110inh L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23539",
            name: "K\u00eanh Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23540",
            name: "Ki\u1ebfn H\u01b0ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23541",
            name: "Nguy\u1ec5n Qu\u00e1n Nho",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23542",
            name: "Nguy\u1ec5n Quang Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23543",
            name: "Thi\u1ec7u D\u01b0\u01a1ng - Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23544",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23545",
            name: "T\u1ec9nh l\u1ed9 515",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23546",
            name: "Tr\u00ed C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23547",
            name: "Tr\u00ed H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "260",
        name: "Th\u1ecd Xu\u00e2n",
        wards: [
          { id: "3772", name: "B\u1eafc L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "3773", name: "H\u1ea1nh Ph\u00fac", prefix: "X\u00e3" },
          { id: "3774", name: "Lam S\u01a1n", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "3775", name: "Nam Giang", prefix: "X\u00e3" },
          { id: "3776", name: "Ph\u00fa Y\u00ean", prefix: "X\u00e3" },
          { id: "3777", name: "Qu\u1ea3ng Ph\u00fa", prefix: "X\u00e3" },
          { id: "3778", name: "Sao V\u00e0ng", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "3779", name: "T\u00e2y H\u1ed3", prefix: "X\u00e3" },
          { id: "3780", name: "Th\u1ecd Di\u00ean", prefix: "X\u00e3" },
          { id: "3781", name: "Th\u1ecd H\u1ea3i", prefix: "X\u00e3" },
          { id: "3782", name: "Th\u1ecd L\u00e2m", prefix: "X\u00e3" },
          { id: "3783", name: "Th\u1ecd L\u1eadp", prefix: "X\u00e3" },
          { id: "3784", name: "Th\u1ecd L\u1ed9c", prefix: "X\u00e3" },
          { id: "3785", name: "Th\u1ecd Minh", prefix: "X\u00e3" },
          { id: "3786", name: "Th\u1ecd Nguy\u00ean", prefix: "X\u00e3" },
          { id: "3787", name: "Th\u1ecd Th\u1eafng", prefix: "X\u00e3" },
          { id: "3788", name: "Th\u1ecd Tr\u01b0\u1eddng", prefix: "X\u00e3" },
          {
            id: "3789",
            name: "Th\u1ecd Xu\u00e2n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3790", name: "Th\u1ecd X\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "3791", name: "Xu\u00e2n B\u00e1i", prefix: "X\u00e3" },
          { id: "3792", name: "Xu\u00e2n Ch\u00e2u", prefix: "X\u00e3" },
          { id: "3793", name: "Xu\u00e2n Giang", prefix: "X\u00e3" },
          { id: "3794", name: "Xu\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "3795", name: "Xu\u00e2n H\u01b0ng", prefix: "X\u00e3" },
          { id: "3796", name: "Xu\u00e2n Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "3797", name: "Xu\u00e2n Lai", prefix: "X\u00e3" },
          { id: "3798", name: "Xu\u00e2n Lam", prefix: "X\u00e3" },
          { id: "3799", name: "Xu\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "3800", name: "Xu\u00e2n Minh", prefix: "X\u00e3" },
          { id: "3801", name: "Xu\u00e2n Phong", prefix: "X\u00e3" },
          { id: "3802", name: "Xu\u00e2n Ph\u00fa", prefix: "X\u00e3" },
          { id: "3803", name: "Xu\u00e2n Quang", prefix: "X\u00e3" },
          { id: "3804", name: "Xu\u00e2n S\u01a1n", prefix: "X\u00e3" },
          { id: "3805", name: "Xu\u00e2n T\u00e2n", prefix: "X\u00e3" },
          { id: "3806", name: "Xu\u00e2n Th\u1eafng", prefix: "X\u00e3" },
          { id: "3807", name: "Xu\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "3808", name: "Xu\u00e2n Thi\u00ean", prefix: "X\u00e3" },
          { id: "3809", name: "Xu\u00e2n T\u00edn", prefix: "X\u00e3" },
          { id: "3810", name: "Xu\u00e2n Tr\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "3811", name: "Xu\u00e2n Vinh", prefix: "X\u00e3" },
          { id: "3812", name: "Xu\u00e2n Y\u00ean", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "23548", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23549",
            name: "H\u1ed3 Ch\u00ed Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23550",
            name: "Qu\u1ed1c l\u1ed9 15A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23551",
            name: "Qu\u1ed1c l\u1ed9 47",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23552",
            name: "T\u1ec9nh l\u1ed9 506",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "261",
        name: "Th\u01b0\u1eddng Xu\u00e2n",
        wards: [
          { id: "3813", name: "B\u00e1t M\u1ecdt", prefix: "X\u00e3" },
          { id: "3814", name: "Lu\u1eadn Kh\u00ea", prefix: "X\u00e3" },
          { id: "3815", name: "Lu\u1eadn Th\u00e0nh", prefix: "X\u00e3" },
          { id: "3816", name: "L\u01b0\u01a1ng S\u01a1n", prefix: "X\u00e3" },
          { id: "3817", name: "Ng\u1ecdc Ph\u1ee5ng", prefix: "X\u00e3" },
          { id: "3818", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "3819", name: "Th\u1ecd Thanh", prefix: "X\u00e3" },
          {
            id: "3820",
            name: "Th\u01b0\u1eddng Xu\u00e2n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3821", name: "V\u1ea1n Xu\u00e2n", prefix: "X\u00e3" },
          { id: "3822", name: "Xu\u00e2n C\u1ea9m", prefix: "X\u00e3" },
          { id: "3823", name: "Xu\u00e2n Cao", prefix: "X\u00e3" },
          { id: "3824", name: "Xu\u00e2n Chinh", prefix: "X\u00e3" },
          { id: "3825", name: "Xu\u00e2n D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "3826", name: "Xu\u00e2n L\u1eb9", prefix: "X\u00e3" },
          { id: "3827", name: "Xu\u00e2n L\u1ed9c", prefix: "X\u00e3" },
          { id: "3828", name: "Xu\u00e2n Th\u1eafng", prefix: "X\u00e3" },
          { id: "3829", name: "Y\u00ean Nh\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "23553",
            name: "H\u1ed3 Ch\u00ed Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23554",
            name: "M\u1ee5c S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23555",
            name: "T\u1ec9nh l\u1ed9 507",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23556",
            name: "Xu\u00e2n Cao",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "262",
        name: "T\u0129nh Gia",
        wards: [
          { id: "3830", name: "Anh S\u01a1n", prefix: "X\u00e3" },
          { id: "3831", name: "B\u00ecnh Minh", prefix: "X\u00e3" },
          { id: "3832", name: "C\u00e1c S\u01a1n", prefix: "X\u00e3" },
          { id: "3833", name: "\u0110\u1ecbnh H\u1ea3i", prefix: "X\u00e3" },
          { id: "3834", name: "H\u1ea3i An", prefix: "X\u00e3" },
          { id: "3835", name: "H\u1ea3i B\u00ecnh", prefix: "X\u00e3" },
          { id: "3836", name: "H\u1ea3i Ch\u00e2u", prefix: "X\u00e3" },
          { id: "3837", name: "H\u1ea3i H\u00e0", prefix: "X\u00e3" },
          { id: "3838", name: "H\u1ea3i H\u00f2a", prefix: "X\u00e3" },
          { id: "3839", name: "H\u1ea3i L\u0129nh", prefix: "X\u00e3" },
          { id: "3840", name: "H\u1ea3i Nh\u00e2n", prefix: "X\u00e3" },
          { id: "3841", name: "H\u1ea3i Ninh", prefix: "X\u00e3" },
          { id: "3842", name: "H\u1ea3i Thanh", prefix: "X\u00e3" },
          { id: "3843", name: "H\u1ea3i Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "3844", name: "H\u1ea3i Y\u1ebfn", prefix: "X\u00e3" },
          { id: "3845", name: "H\u00f9ng S\u01a1n", prefix: "X\u00e3" },
          { id: "3846", name: "Mai L\u00e2m", prefix: "X\u00e3" },
          { id: "3847", name: "Nghi S\u01a1n", prefix: "X\u00e3" },
          { id: "3848", name: "Ng\u1ecdc L\u0129nh", prefix: "X\u00e3" },
          { id: "3849", name: "Nguy\u00ean B\u00ecnh", prefix: "X\u00e3" },
          { id: "3850", name: "Ninh H\u1ea3i", prefix: "X\u00e3" },
          { id: "3851", name: "Ph\u00fa L\u00e2m", prefix: "X\u00e3" },
          { id: "3852", name: "Ph\u00fa S\u01a1n", prefix: "X\u00e3" },
          { id: "3853", name: "T\u00e2n D\u00e2n", prefix: "X\u00e3" },
          { id: "3854", name: "T\u00e2n Tr\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "3855", name: "Thanh S\u01a1n", prefix: "X\u00e3" },
          { id: "3856", name: "Thanh Th\u1ee7y", prefix: "X\u00e3" },
          { id: "3857", name: "T\u0129nh Gia", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "3858", name: "T\u0129nh H\u1ea3i", prefix: "X\u00e3" },
          { id: "3859", name: "Tri\u00eau D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "3860", name: "Tr\u00fac L\u00e2m", prefix: "X\u00e3" },
          { id: "3861", name: "Tr\u01b0\u1eddng L\u00e2m", prefix: "X\u00e3" },
          { id: "3862", name: "T\u00f9ng L\u00e2m", prefix: "X\u00e3" },
          { id: "3863", name: "Xu\u00e2n L\u00e2m", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "23557", name: "513", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23558", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23559",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23560",
            name: "Nghi S\u01a1n - B\u00e3i Tr\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23561", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23562",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23563",
            name: "Qu\u1ed1c l\u1ed9 2B",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "263",
        name: "Tri\u1ec7u S\u01a1n",
        wards: [
          { id: "3864", name: "An N\u00f4ng", prefix: "X\u00e3" },
          { id: "3865", name: "B\u00ecnh S\u01a1n", prefix: "X\u00e3" },
          { id: "3866", name: "D\u00e2n L\u1ef1c", prefix: "X\u00e3" },
          { id: "3867", name: "D\u00e2n L\u00fd", prefix: "X\u00e3" },
          { id: "3868", name: "D\u00e2n Quy\u1ec1n", prefix: "X\u00e3" },
          { id: "3869", name: "\u0110\u1ed3ng L\u1ee3i", prefix: "X\u00e3" },
          { id: "3870", name: "\u0110\u1ed3ng Th\u1eafng", prefix: "X\u00e3" },
          { id: "3871", name: "\u0110\u1ed3ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "3872", name: "H\u1ee3p l\u00fd", prefix: "X\u00e3" },
          { id: "3873", name: "H\u1ee3p Th\u1eafng", prefix: "X\u00e3" },
          { id: "3874", name: "H\u1ee3p Th\u00e0nh", prefix: "X\u00e3" },
          { id: "3875", name: "H\u1ee3p Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "3876", name: "Khuy\u1ebfn N\u00f4ng", prefix: "X\u00e3" },
          { id: "3877", name: "Minh Ch\u00e2u", prefix: "X\u00e3" },
          { id: "3878", name: "Minh D\u00e2n", prefix: "X\u00e3" },
          { id: "3879", name: "Minh S\u01a1n", prefix: "X\u00e3" },
          { id: "3880", name: "N\u00f4ng Tr\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "3881", name: "T\u00e2n Ninh", prefix: "X\u00e3" },
          { id: "3882", name: "Th\u00e1i Ho\u00e0", prefix: "X\u00e3" },
          { id: "3883", name: "Th\u1ecd B\u00ecnh", prefix: "X\u00e3" },
          { id: "3884", name: "Th\u1ecd C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "3885", name: "Th\u1ecd D\u00e2n", prefix: "X\u00e3" },
          { id: "3886", name: "Th\u1ecd Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "3887", name: "Th\u1ecd Ph\u00fa", prefix: "X\u00e3" },
          { id: "3888", name: "Th\u1ecd S\u01a1n", prefix: "X\u00e3" },
          { id: "3889", name: "Th\u1ecd T\u00e2n", prefix: "X\u00e3" },
          { id: "3890", name: "Th\u1ecd Th\u1ebf", prefix: "X\u00e3" },
          { id: "3891", name: "Th\u1ecd Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "3892", name: "Th\u1ecd V\u1ef1c", prefix: "X\u00e3" },
          { id: "3893", name: "Ti\u1ebfn N\u00f4ng", prefix: "X\u00e3" },
          {
            id: "3894",
            name: "Tri\u1ec7u S\u01a1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3895", name: "Tri\u1ec7u Th\u00e0nh", prefix: "X\u00e3" },
          { id: "3896", name: "V\u0103n S\u01a1n", prefix: "X\u00e3" },
          { id: "3897", name: "Xu\u00e2n L\u1ed9c", prefix: "X\u00e3" },
          { id: "3898", name: "Xu\u00e2n Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "3899", name: "Xu\u00e2n Th\u1ecd", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "23564", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23565", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          { id: "23566", name: "Gi\u1eaft", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23567",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23568",
            name: "Qu\u1ed1c l\u1ed9 47",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23569",
            name: "T\u00e2n Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23570",
            name: "T\u00e2n S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23571",
            name: "T\u00e2n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23572",
            name: "T\u1ec9nh l\u1ed9 506",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23573",
            name: "T\u1ec9nh l\u1ed9 514",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23574",
            name: "T\u1ec9nh l\u1ed9 517",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23575",
            name: "T\u00f4 V\u0129nh Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "264",
        name: "V\u0129nh L\u1ed9c",
        wards: [
          { id: "3900", name: "V\u0129nh An", prefix: "X\u00e3" },
          { id: "3901", name: "V\u0129nh H\u00f2a", prefix: "X\u00e3" },
          { id: "3902", name: "V\u0129nh H\u00f9ng", prefix: "X\u00e3" },
          { id: "3903", name: "V\u0129nh H\u01b0ng", prefix: "X\u00e3" },
          { id: "3904", name: "V\u0129nh Khang", prefix: "X\u00e3" },
          {
            id: "3905",
            name: "V\u0129nh L\u1ed9c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3906", name: "V\u0129nh Long", prefix: "X\u00e3" },
          { id: "3907", name: "V\u0129nh Minh", prefix: "X\u00e3" },
          { id: "3908", name: "V\u0129nh Ninh", prefix: "X\u00e3" },
          { id: "3909", name: "V\u0129nh Ph\u00fac", prefix: "X\u00e3" },
          { id: "3910", name: "V\u0129nh Quang", prefix: "X\u00e3" },
          { id: "3911", name: "V\u0129nh T\u00e2n", prefix: "X\u00e3" },
          { id: "3912", name: "V\u0129nh Th\u00e0nh", prefix: "X\u00e3" },
          { id: "3913", name: "V\u0129nh Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "3914", name: "V\u0129nh Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "3915", name: "V\u0129nh Y\u00ean", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "23576", name: "45", prefix: "Ph\u1ed1" },
          {
            id: "23577",
            name: "Qu\u1ed1c l\u1ed9 45",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23578",
            name: "T\u1ec9nh l\u1ed9 217",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "265",
        name: "Y\u00ean \u0110\u1ecbnh",
        wards: [
          { id: "3916", name: "\u0110\u1ecbnh B\u00ecnh", prefix: "X\u00e3" },
          { id: "3917", name: "\u0110\u1ecbnh C\u00f4ng", prefix: "X\u00e3" },
          { id: "3918", name: "\u0110\u1ecbnh H\u1ea3i", prefix: "X\u00e3" },
          { id: "3919", name: "\u0110\u1ecbnh H\u00f2a", prefix: "X\u00e3" },
          { id: "3920", name: "\u0110\u1ecbnh H\u01b0ng", prefix: "X\u00e3" },
          { id: "3921", name: "\u0110\u1ecbnh Li\u00ean", prefix: "X\u00e3" },
          { id: "3922", name: "\u0110\u1ecbnh Long", prefix: "X\u00e3" },
          { id: "3923", name: "\u0110\u1ecbnh T\u00e2n", prefix: "X\u00e3" },
          { id: "3924", name: "\u0110\u1ecbnh T\u0103ng", prefix: "X\u00e3" },
          { id: "3925", name: "\u0110\u1ecbnh Th\u00e0nh", prefix: "X\u00e3" },
          { id: "3926", name: "\u0110\u1ecbnh Ti\u1ebfn", prefix: "X\u00e3" },
          {
            id: "3927",
            name: "\u0110\u1ecbnh T\u01b0\u1eddng",
            prefix: "X\u00e3",
          },
          { id: "3928", name: "Ki\u1ec3u", prefix: "Th\u1ecb tr\u1ea5n" },
          {
            id: "3929",
            name: "Qu\u00e1n L\u00e0o",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3930", name: "Qu\u00fd L\u1ed9c", prefix: "X\u00e3" },
          {
            id: "3931",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3932", name: "Y\u00ean B\u00e1i", prefix: "X\u00e3" },
          { id: "3933", name: "Y\u00ean Giang", prefix: "X\u00e3" },
          { id: "3934", name: "Y\u00ean H\u00f9ng", prefix: "X\u00e3" },
          { id: "3935", name: "Y\u00ean L\u1ea1c", prefix: "X\u00e3" },
          { id: "3936", name: "Y\u00ean L\u00e2m", prefix: "X\u00e3" },
          { id: "3937", name: "Y\u00ean Ninh", prefix: "X\u00e3" },
          { id: "3938", name: "Y\u00ean Phong", prefix: "X\u00e3" },
          { id: "3939", name: "Y\u00ean Ph\u00fa", prefix: "X\u00e3" },
          { id: "3940", name: "Y\u00ean T\u00e2m", prefix: "X\u00e3" },
          { id: "3941", name: "Y\u00ean Th\u00e1i", prefix: "X\u00e3" },
          { id: "3942", name: "Y\u00ean Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "3943", name: "Y\u00ean Th\u1ecd", prefix: "X\u00e3" },
          { id: "3944", name: "Y\u00ean Trung", prefix: "X\u00e3" },
          { id: "3945", name: "Y\u00ean Tr\u01b0\u1eddng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "23579", name: "45", prefix: "Ph\u1ed1" },
          {
            id: "23580",
            name: "Qu\u1ed1c l\u1ed9 45",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "20",
    code: "NA",
    name: "Ngh\u1ec7 An",
    districts: [
      {
        id: "266",
        name: "Anh S\u01a1n",
        wards: [
          { id: "3946", name: " H\u00f9ng S\u01a1n", prefix: "X\u00e3" },
          { id: "3947", name: " Khai S\u01a1n", prefix: "X\u00e3" },
          { id: "3948", name: " Long S\u01a1n", prefix: "X\u00e3" },
          { id: "3949", name: " Th\u1ecd S\u01a1n ", prefix: "X\u00e3" },
          { id: "3950", name: "Anh S\u01a1n", prefix: "X\u00e3" },
          { id: "3951", name: "B\u00ecnh S\u01a1n ", prefix: "X\u00e3" },
          { id: "3952", name: "C\u1ea9m S\u01a1n ", prefix: "X\u00e3" },
          { id: "3953", name: "Cao S\u01a1n ", prefix: "X\u00e3" },
          { id: "3954", name: "\u0110\u1ec9nh S\u01a1n ", prefix: "X\u00e3" },
          { id: "3955", name: "\u0110\u1ee9c S\u01a1n ", prefix: "X\u00e3" },
          { id: "3956", name: "Hoa S\u01a1n", prefix: "X\u00e3" },
          { id: "3957", name: "H\u1ed9i S\u01a1n ", prefix: "X\u00e3" },
          { id: "3958", name: "L\u1ea1ng S\u01a1n", prefix: "X\u00e3" },
          { id: "3959", name: "L\u0129nh S\u01a1n ", prefix: "X\u00e3" },
          { id: "3960", name: "Ph\u00fac S\u01a1n", prefix: "X\u00e3" },
          { id: "3961", name: "Tam S\u01a1n", prefix: "X\u00e3" },
          { id: "3962", name: "T\u00e0o S\u01a1n ", prefix: "X\u00e3" },
          { id: "3963", name: "Th\u1ea1ch S\u01a1n", prefix: "X\u00e3" },
          { id: "3964", name: "Th\u00e0nh S\u01a1n ", prefix: "X\u00e3" },
          { id: "3965", name: "T\u01b0\u1eddng S\u01a1n ", prefix: "X\u00e3" },
          { id: "3966", name: "V\u0129nh S\u01a1n", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "23581",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23582",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23583",
            name: "Phan Th\u00e1i \u1ea4t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23584",
            name: "Qu\u1ed1c l\u1ed9 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "267",
        name: "Con Cu\u00f4ng",
        wards: [
          { id: "3967", name: " Chi Kh\u00ea", prefix: "X\u00e3" },
          { id: "3968", name: "B\u00ecnh Chu\u1ea9n", prefix: "X\u00e3" },
          { id: "3969", name: "B\u1ed3ng Kh\u00ea", prefix: "X\u00e3" },
          { id: "3970", name: "Cam L\u00e2m", prefix: "X\u00e3" },
          { id: "3971", name: "Ch\u00e2u Kh\u00ea", prefix: "X\u00e3" },
          { id: "3972", name: "Con Cu\u00f4ng", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "3973", name: "\u0110\u00f4n Ph\u1ee5c", prefix: "X\u00e3" },
          { id: "3974", name: "L\u1ea1ng Kh\u00ea", prefix: "X\u00e3" },
          { id: "3975", name: "L\u1ee5c D\u1ea1", prefix: "X\u00e3" },
          { id: "3976", name: "M\u1eadu \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "3977", name: "M\u00f4n S\u01a1n", prefix: "X\u00e3" },
          { id: "3978", name: "Th\u1ea1ch Ng\u00e0n", prefix: "X\u00e3" },
          { id: "3979", name: "Y\u00ean Kh\u00ea", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "23585",
            name: "Qu\u1ed1c l\u1ed9 7A",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "268",
        name: "C\u1eeda L\u00f2",
        wards: [
          { id: "3980", name: "Nghi H\u1ea3i", prefix: "Ph\u01b0\u1eddng" },
          { id: "3981", name: "Nghi H\u00f2a", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "3982",
            name: "Nghi H\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "3983", name: "Nghi T\u00e2n", prefix: "Ph\u01b0\u1eddng" },
          { id: "3984", name: "Nghi Thu", prefix: "Ph\u01b0\u1eddng" },
          { id: "3985", name: "Nghi Th\u1ee7y", prefix: "Ph\u01b0\u1eddng" },
          { id: "3986", name: "Thu Th\u1ee7y", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "23586", name: "72", prefix: "Ph\u1ed1" },
          {
            id: "23587",
            name: "B\u00ecnh Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23588",
            name: "B\u00ecnh Minh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23589",
            name: "Ho\u00e0ng \u0110an",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23590",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23591",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23592",
            name: "Nguy\u1ec5n Sinh Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23593",
            name: "Nguy\u1ec5n S\u01b0 H\u1ed3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23594",
            name: "Nguy\u1ec5n Th\u1ee9c T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23595", name: "Nguy\u1ec5n X\u00ed", prefix: "Ph\u1ed1" },
          {
            id: "23596",
            name: "Ph\u1ea1m Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23597",
            name: "Qu\u1ed1c l\u1ed9 46",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23598", name: "S\u00e0o Nam", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23599", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23600", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "2875",
            name: "C\u1eeda L\u00f2 Golf Resort",
            lat: "18.808782577515",
            lng: "105.7229385376",
          },
          {
            id: "2876",
            name: "PV C\u1eeda L\u00f2 Resort",
            lat: "18.792461395264",
            lng: "105.73391723633",
          },
          {
            id: "2877",
            name: "Villa Gold C\u1eeda L\u00f2",
            lat: "18.768602371216",
            lng: "105.75297546387",
          },
          {
            id: "2878",
            name: "Vinpearl C\u1eeda H\u1ed9i",
            lat: "18.760278701782",
            lng: "105.75814056396",
          },
        ],
      },
      {
        id: "269",
        name: "Di\u1ec5n Ch\u00e2u",
        wards: [
          { id: "3987", name: "Di\u1ec5n An", prefix: "X\u00e3" },
          { id: "3988", name: "Di\u1ec5n B\u00edch", prefix: "X\u00e3" },
          { id: "3989", name: "Di\u1ec5n B\u00ecnh", prefix: "X\u00e3" },
          { id: "3990", name: "Di\u1ec5n C\u00e1t", prefix: "X\u00e3" },
          {
            id: "3991",
            name: "Di\u1ec5n Ch\u00e2u",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "3992", name: "Di\u1ec5n \u0110o\u00e0i", prefix: "X\u00e3" },
          { id: "3993", name: "Di\u1ec5n \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "3994", name: "Di\u1ec5n H\u1ea3i", prefix: "X\u00e3" },
          { id: "3995", name: "Di\u1ec5n H\u1ea1nh", prefix: "X\u00e3" },
          { id: "3996", name: "Di\u1ec5n Hoa", prefix: "X\u00e3" },
          { id: "3997", name: "Di\u1ec5n Ho\u00e0ng", prefix: "X\u00e3" },
          { id: "3998", name: "Di\u1ec5n H\u1ed3ng", prefix: "X\u00e3" },
          { id: "3999", name: "Di\u1ec5n H\u00f9ng", prefix: "X\u00e3" },
          { id: "4000", name: "Di\u1ec5n Kim", prefix: "X\u00e3" },
          { id: "4001", name: "Di\u1ec5n K\u1ef7", prefix: "X\u00e3" },
          { id: "4002", name: "Di\u1ec5n L\u00e2m", prefix: "X\u00e3" },
          { id: "4003", name: "Di\u1ec5n Li\u00ean", prefix: "X\u00e3" },
          { id: "4004", name: "Di\u1ec5n L\u1ed9c", prefix: "X\u00e3" },
          { id: "4005", name: "Di\u1ec5n L\u1ee3i", prefix: "X\u00e3" },
          { id: "4006", name: "Di\u1ec5n Minh", prefix: "X\u00e3" },
          { id: "4007", name: "Di\u1ec5n M\u1ef9", prefix: "X\u00e3" },
          { id: "4008", name: "Di\u1ec5n Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "4009", name: "Di\u1ec5n Nguy\u00ean", prefix: "X\u00e3" },
          { id: "4010", name: "Di\u1ec5n Phong", prefix: "X\u00e3" },
          { id: "4011", name: "Di\u1ec5n Ph\u00fa", prefix: "X\u00e3" },
          { id: "4012", name: "Di\u1ec5n Ph\u00fac", prefix: "X\u00e3" },
          { id: "4013", name: "Di\u1ec5n Qu\u1ea3ng", prefix: "X\u00e3" },
          { id: "4014", name: "Di\u1ec5n T\u00e2n", prefix: "X\u00e3" },
          { id: "4015", name: "Di\u1ec5n Th\u00e1i", prefix: "X\u00e3" },
          { id: "4016", name: "Di\u1ec5n Th\u1eafng", prefix: "X\u00e3" },
          { id: "4017", name: "Di\u1ec5n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4018", name: "Di\u1ec5n Th\u00e1p", prefix: "X\u00e3" },
          { id: "4019", name: "Di\u1ec5n Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "4020", name: "Di\u1ec5n Th\u1ecd", prefix: "X\u00e3" },
          { id: "4021", name: "Di\u1ec5n Trung", prefix: "X\u00e3" },
          { id: "4022", name: "Di\u1ec5n Tr\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "4023", name: "Di\u1ec5n V\u1ea1n", prefix: "X\u00e3" },
          { id: "4024", name: "Di\u1ec5n Xu\u00e2n", prefix: "X\u00e3" },
          { id: "4025", name: "Di\u1ec5n Y\u00ean", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "23601",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23602",
            name: "Qu\u1ed1c L\u1ed9 48A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23603",
            name: "Qu\u1ed1c l\u1ed9 7A",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "270",
        name: "\u0110\u00f4 L\u01b0\u01a1ng",
        wards: [
          { id: "4026", name: " L\u1ea1c S\u01a1n", prefix: "X\u00e3" },
          { id: "4027", name: " Nh\u00e2n S\u01a1n", prefix: "X\u00e3" },
          { id: "4028", name: " Tr\u00f9 S\u01a1n", prefix: "X\u00e3" },
          { id: "4029", name: "B\u1eafc S\u01a1n", prefix: "X\u00e3" },
          { id: "4030", name: "B\u00e0i S\u01a1n", prefix: "X\u00e3" },
          { id: "4031", name: "B\u1ed3i S\u01a1n", prefix: "X\u00e3" },
          { id: "4032", name: "\u0110\u00e0 S\u01a1n", prefix: "X\u00e3" },
          { id: "4033", name: "\u0110\u1ea1i S\u01a1n", prefix: "X\u00e3" },
          { id: "4034", name: "\u0110\u1eb7ng S\u01a1n", prefix: "X\u00e3" },
          {
            id: "4035",
            name: "\u0110\u00f4 L\u01b0\u01a1ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4036", name: "\u0110\u00f4ng S\u01a1n", prefix: "X\u00e3" },
          {
            id: "4037",
            name: "Giang S\u01a1n \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "4038", name: "Giang S\u01a1n T\u00e2y", prefix: "X\u00e3" },
          { id: "4039", name: "Hi\u1ebfn S\u01a1n", prefix: "X\u00e3" },
          { id: "4040", name: "H\u00f2a S\u01a1n", prefix: "X\u00e3" },
          { id: "4041", name: "H\u1ed3ng S\u01a1n", prefix: "X\u00e3" },
          { id: "4042", name: "Lam S\u01a1n", prefix: "X\u00e3" },
          { id: "4043", name: "L\u01b0u S\u01a1n", prefix: "X\u00e3" },
          { id: "4044", name: "Minh S\u01a1n", prefix: "X\u00e3" },
          { id: "4045", name: "M\u1ef9 S\u01a1n", prefix: "X\u00e3" },
          { id: "4046", name: "Nam S\u01a1n", prefix: "X\u00e3" },
          { id: "4047", name: "Ng\u1ecdc S\u01a1n", prefix: "X\u00e3" },
          { id: "4048", name: "Quang S\u01a1n", prefix: "X\u00e3" },
          { id: "4049", name: "T\u00e2n S\u01a1n", prefix: "X\u00e3" },
          { id: "4050", name: "Th\u00e1i S\u01a1n", prefix: "X\u00e3" },
          { id: "4051", name: "Th\u1ecbnh S\u01a1n", prefix: "X\u00e3" },
          { id: "4052", name: "Thu\u1eadn S\u01a1n", prefix: "X\u00e3" },
          { id: "4053", name: "Th\u01b0\u1ee3ng S\u01a1n", prefix: "X\u00e3" },
          { id: "4054", name: "Tr\u00e0ng S\u01a1n", prefix: "X\u00e3" },
          { id: "4055", name: "Trung S\u01a1n", prefix: "X\u00e3" },
          { id: "4056", name: "V\u0103n S\u01a1n", prefix: "X\u00e3" },
          { id: "4057", name: "Xu\u00e2n S\u01a1n", prefix: "X\u00e3" },
          { id: "4058", name: "Y\u00ean S\u01a1n", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "23604",
            name: "Qu\u1ed1c l\u1ed9 15A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23605",
            name: "Qu\u1ed1c l\u1ed9 46",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23606",
            name: "Qu\u1ed1c l\u1ed9 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "271",
        name: "Ho\u00e0ng Mai",
        wards: [
          { id: "4059", name: "Mai H\u00f9ng", prefix: "X\u00e3" },
          { id: "4060", name: "Qu\u1ef3nh D\u1ecb", prefix: "X\u00e3" },
          { id: "4061", name: "Qu\u1ef3nh L\u1eadp", prefix: "X\u00e3" },
          { id: "4062", name: "Qu\u1ef3nh Li\u00ean", prefix: "X\u00e3" },
          { id: "4063", name: "Qu\u1ef3nh L\u1ed9c", prefix: "X\u00e3" },
          {
            id: "4064",
            name: "Qu\u1ef3nh Ph\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "4065", name: "Qu\u1ef3nh Thi\u1ec7n", prefix: "X\u00e3" },
          { id: "4066", name: "Qu\u1ef3nh Trang", prefix: "X\u00e3" },
          { id: "4067", name: "Qu\u1ef3nh Vinh", prefix: "X\u00e3" },
          { id: "4068", name: "Qu\u1ef3nh Xu\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "23607", name: "58", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23608",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23609",
            name: "Qu\u1ed1c L\u1ed9 48W",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "272",
        name: "H\u01b0ng Nguy\u00ean",
        wards: [
          { id: "4069", name: " H\u01b0ng L\u0129nh", prefix: "X\u00e3" },
          { id: "4070", name: " H\u01b0ng M\u1ef9", prefix: "X\u00e3" },
          { id: "4071", name: "H\u01b0ng Ch\u00e2u", prefix: "X\u00e3" },
          { id: "4072", name: "H\u01b0ng \u0110\u1ea1o", prefix: "X\u00e3" },
          { id: "4073", name: "H\u01b0ng Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "4074", name: "H\u01b0ng Lam", prefix: "X\u00e3" },
          { id: "4075", name: "H\u01b0ng L\u1ee3i", prefix: "X\u00e3" },
          { id: "4076", name: "H\u01b0ng Long", prefix: "X\u00e3" },
          {
            id: "4077",
            name: "H\u01b0ng Nguy\u00ean",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4078", name: "H\u01b0ng Nh\u00e2n", prefix: "X\u00e3" },
          { id: "4079", name: "H\u01b0ng Ph\u00fa", prefix: "X\u00e3" },
          { id: "4080", name: "H\u01b0ng Ph\u00fac", prefix: "X\u00e3" },
          { id: "4081", name: "H\u01b0ng T\u00e2n", prefix: "X\u00e3" },
          { id: "4082", name: "H\u01b0ng T\u00e2y", prefix: "X\u00e3" },
          { id: "4083", name: "H\u01b0ng Th\u1eafng", prefix: "X\u00e3" },
          { id: "4084", name: "H\u01b0ng Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "4085", name: "H\u01b0ng Th\u00f4ng", prefix: "X\u00e3" },
          { id: "4086", name: "H\u01b0ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "4087", name: "H\u01b0ng Trung", prefix: "X\u00e3" },
          { id: "4088", name: "H\u01b0ng X\u00e1", prefix: "X\u00e3" },
          { id: "4089", name: "H\u01b0ng Xu\u00e2n", prefix: "X\u00e3" },
          {
            id: "4090",
            name: "H\u01b0ng Y\u00ean B\u1eafc",
            prefix: "X\u00e3",
          },
          { id: "4091", name: "H\u01b0ng Y\u00ean Nam ", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "23610", name: "46", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23611",
            name: "L\u00ea Xu\u00e2n \u0110\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23612",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23613",
            name: "Qu\u1ed1c l\u1ed9 46",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23614",
            name: "T\u1ec9nh l\u1ed9 547",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23615",
            name: "T\u1ec9nh L\u1ed9 558",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "273",
        name: "K\u1ef3 S\u01a1n",
        wards: [
          { id: "4092", name: " M\u01b0\u1eddng T\u00edp", prefix: "X\u00e3" },
          { id: "4093", name: " Ph\u00e0 \u0110\u00e1nh", prefix: "X\u00e3" },
          { id: "4094", name: " T\u00e0 C\u1ea1", prefix: "X\u00e3" },
          { id: "4095", name: "B\u1eafc L\u00fd", prefix: "X\u00e3" },
          { id: "4096", name: "B\u1ea3o Nam", prefix: "X\u00e3" },
          { id: "4097", name: "B\u1ea3o Th\u1eafng", prefix: "X\u00e3" },
          { id: "4098", name: "Chi\u00eau L\u01b0u", prefix: "X\u00e3" },
          { id: "4099", name: "\u0110o\u1ecdc M\u1ea1y", prefix: "X\u00e3" },
          { id: "4100", name: "Hu\u1ed3i T\u1ee5", prefix: "X\u00e3" },
          { id: "4101", name: "H\u1eefu Ki\u1ec7m", prefix: "X\u00e3" },
          { id: "4102", name: "H\u1eefu L\u1eadp", prefix: "X\u00e3" },
          { id: "4103", name: "Keng \u0110u", prefix: "X\u00e3" },
          { id: "4104", name: "M\u01b0\u1eddng \u1ea2i", prefix: "X\u00e3" },
          { id: "4105", name: "M\u01b0\u1eddng L\u1ed1ng", prefix: "X\u00e3" },
          {
            id: "4106",
            name: "M\u01b0\u1eddng X\u00e9n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4107", name: "M\u1ef9 L\u00fd", prefix: "X\u00e3" },
          { id: "4108", name: "Na Loi", prefix: "X\u00e3" },
          { id: "4109", name: "Na Ngoi", prefix: "X\u00e3" },
          { id: "4110", name: "N\u1eadm C\u00e0n", prefix: "X\u00e3" },
          { id: "4111", name: "N\u1eadm C\u1eafn", prefix: "X\u00e3" },
          { id: "4112", name: "T\u00e2y S\u01a1n", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "23616",
            name: "Qu\u1ed1c l\u1ed9 7A",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "274",
        name: "Nam \u0110\u00e0n",
        wards: [
          { id: "4113", name: " Kim Li\u00ean", prefix: "X\u00e3" },
          { id: "4114", name: " Nam Anh", prefix: "X\u00e3" },
          { id: "4115", name: " Nam Trung", prefix: "X\u00e3" },
          { id: "4116", name: "H\u1ed3ng Long", prefix: "X\u00e3" },
          { id: "4117", name: "H\u00f9ng Ti\u1ebfn ", prefix: "X\u00e3" },
          { id: "4118", name: "Kh\u00e1nh S\u01a1n", prefix: "X\u00e3" },
          { id: "4119", name: "Nam C\u00e1t", prefix: "X\u00e3" },
          { id: "4120", name: "Nam C\u01b0\u1eddng", prefix: "X\u00e3" },
          {
            id: "4121",
            name: "Nam \u0110\u00e0n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4122", name: "Nam Giang", prefix: "X\u00e3" },
          { id: "4123", name: "Nam H\u01b0ng", prefix: "X\u00e3" },
          { id: "4124", name: "Nam Kim", prefix: "X\u00e3" },
          { id: "4125", name: "Nam L\u0129nh", prefix: "X\u00e3" },
          { id: "4126", name: "Nam L\u1ed9c", prefix: "X\u00e3" },
          { id: "4127", name: "Nam Ngh\u0129a", prefix: "X\u00e3" },
          { id: "4128", name: "Nam Ph\u00fac", prefix: "X\u00e3" },
          { id: "4129", name: "Nam T\u00e2n", prefix: "X\u00e3" },
          { id: "4130", name: "Nam Th\u00e1i", prefix: "X\u00e3" },
          { id: "4131", name: "Nam Thanh", prefix: "X\u00e3" },
          { id: "4132", name: "Nam Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "4133", name: "Nam Xu\u00e2n", prefix: "X\u00e3" },
          { id: "4134", name: "V\u00e2n Di\u00ean", prefix: "X\u00e3" },
          { id: "4135", name: "Xu\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "4136", name: "Xu\u00e2n L\u00e2m", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "23617", name: "46A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23618",
            name: "Qu\u1ed1c l\u1ed9 46",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "275",
        name: "Nghi L\u1ed9c",
        wards: [
          { id: "4137", name: " Nghi Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "4138", name: "Nghi C\u00f4ng b\u1eafc", prefix: "X\u00e3" },
          { id: "4139", name: "Nghi C\u00f4ng nam", prefix: "X\u00e3" },
          { id: "4140", name: "Nghi Di\u00ean", prefix: "X\u00e3" },
          { id: "4141", name: "Nghi \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "4142", name: "Nghi Hoa", prefix: "X\u00e3" },
          { id: "4143", name: "Nghi H\u1ee3p", prefix: "X\u00e3" },
          { id: "4144", name: "Nghi H\u01b0ng", prefix: "X\u00e3" },
          { id: "4145", name: "Nghi Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "4146", name: "Nghi Ki\u1ec1u", prefix: "X\u00e3" },
          { id: "4147", name: "Nghi L\u00e2m", prefix: "X\u00e3" },
          { id: "4148", name: "Nghi Li\u00ean", prefix: "X\u00e3" },
          { id: "4149", name: "Nghi Long", prefix: "X\u00e3" },
          { id: "4150", name: "Nghi M\u1ef9", prefix: "X\u00e3" },
          { id: "4151", name: "Nghi Phong", prefix: "X\u00e3" },
          { id: "4152", name: "Nghi Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "4153", name: "Nghi Quang", prefix: "X\u00e3" },
          { id: "4154", name: "Nghi Th\u1ea1ch", prefix: "X\u00e3" },
          { id: "4155", name: "Nghi Th\u00e1i", prefix: "X\u00e3" },
          { id: "4156", name: "Nghi Thi\u1ebft", prefix: "X\u00e3" },
          { id: "4157", name: "Nghi Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "4158", name: "Nghi Thu\u1eadn", prefix: "X\u00e3" },
          { id: "4159", name: "Nghi Trung", prefix: "X\u00e3" },
          { id: "4160", name: "Nghi Tr\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "4161", name: "Nghi V\u0103n", prefix: "X\u00e3" },
          { id: "4162", name: "Nghi V\u1ea1n", prefix: "X\u00e3" },
          { id: "4163", name: "Nghi X\u00e1", prefix: "X\u00e3" },
          { id: "4164", name: "Nghi Xu\u00e2n", prefix: "X\u00e3" },
          { id: "4165", name: "Nghi Y\u00ean", prefix: "X\u00e3" },
          { id: "4166", name: "Ph\u00fac Th\u1ecd", prefix: "X\u00e3" },
          {
            id: "4167",
            name: "Qu\u00e1n H\u00e0nh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          { id: "23619", name: "534", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23620", name: "535", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23621",
            name: "C\u1eeda H\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23622", name: "Nghi Kim", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23623",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23624",
            name: "Qu\u1ed1c l\u1ed9 46",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23625",
            name: "Qu\u1ed1c l\u1ed9 48E",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23626", name: "Vinh", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "2879",
            name: "FLC Ngh\u1ec7 An",
            lat: "18.873769760132",
            lng: "105.68775177002",
          },
        ],
      },
      {
        id: "276",
        name: "Ngh\u0129a \u0110\u00e0n",
        wards: [
          { id: "4168", name: "Ngh\u0129a An", prefix: "X\u00e3" },
          { id: "4169", name: "Ngh\u0129a B\u00ecnh", prefix: "X\u00e3" },
          {
            id: "4170",
            name: "Ngh\u0129a \u0110\u00e0n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4171", name: "Ngh\u0129a \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "4172", name: "Ngh\u0129a Hi\u1ebfu", prefix: "X\u00e3" },
          { id: "4173", name: "Ngh\u0129a H\u1ed9i", prefix: "X\u00e3" },
          { id: "4174", name: "Ngh\u0129a H\u1ed3ng", prefix: "X\u00e3" },
          { id: "4175", name: "Ngh\u0129a H\u01b0ng", prefix: "X\u00e3" },
          { id: "4176", name: "Ngh\u0129a Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "4177", name: "Ngh\u0129a L\u1ea1c", prefix: "X\u00e3" },
          { id: "4178", name: "Ngh\u0129a L\u00e2m", prefix: "X\u00e3" },
          { id: "4179", name: "Ngh\u0129a Li\u00ean", prefix: "X\u00e3" },
          { id: "4180", name: "Ngh\u0129a L\u1ed9c", prefix: "X\u00e3" },
          { id: "4181", name: "Ngh\u0129a L\u1ee3i", prefix: "X\u00e3" },
          { id: "4182", name: "Ngh\u0129a Long", prefix: "X\u00e3" },
          { id: "4183", name: "Ngh\u0129a Mai", prefix: "X\u00e3" },
          { id: "4184", name: "Ngh\u0129a Minh", prefix: "X\u00e3" },
          { id: "4185", name: "Ngh\u0129a Ph\u00fa", prefix: "X\u00e3" },
          { id: "4186", name: "Ngh\u0129a S\u01a1n", prefix: "X\u00e3" },
          { id: "4187", name: "Ngh\u0129a T\u00e2n", prefix: "X\u00e3" },
          { id: "4188", name: "Ngh\u0129a Th\u1eafng", prefix: "X\u00e3" },
          { id: "4189", name: "Ngh\u0129a Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "4190", name: "Ngh\u0129a Th\u1ecd", prefix: "X\u00e3" },
          { id: "4191", name: "Ngh\u0129a Trung", prefix: "X\u00e3" },
          { id: "4192", name: "Ngh\u0129a Y\u00ean", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "277",
        name: "Qu\u1ebf Phong",
        wards: [
          { id: "4193", name: "C\u1eafm Mu\u1ed9n", prefix: "X\u00e3" },
          { id: "4194", name: "Ch\u00e2u Kim", prefix: "X\u00e3" },
          { id: "4195", name: "Ch\u00e2u Th\u00f4n", prefix: "X\u00e3" },
          { id: "4196", name: "\u0110\u1ed3ng V\u0103n", prefix: "X\u00e3" },
          { id: "4197", name: "H\u1ea1nh D\u1ecbch", prefix: "X\u00e3" },
          { id: "4198", name: "Kim S\u01a1n", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "4199", name: "M\u01b0\u1eddng N\u1ecdc", prefix: "X\u00e3" },
          { id: "4200", name: "N\u1eadm Gi\u1ea3i", prefix: "X\u00e3" },
          { id: "4201", name: "N\u1eadm Nho\u00f3ng", prefix: "X\u00e3" },
          { id: "4202", name: "Quang Phong", prefix: "X\u00e3" },
          { id: "4203", name: "Qu\u1ebf S\u01a1n", prefix: "X\u00e3" },
          { id: "4204", name: "Th\u00f4ng Th\u1ee5", prefix: "X\u00e3" },
          { id: "4205", name: "Ti\u1ec1n Phong", prefix: "X\u00e3" },
          { id: "4206", name: "Tri L\u1ec5", prefix: "X\u00e3" },
        ],
        streets: [{ id: "23627", name: "48", prefix: "\u0110\u01b0\u1eddng" }],
        projects: [],
      },
      {
        id: "278",
        name: "Qu\u1ef3 Ch\u00e2u",
        wards: [
          { id: "4207", name: "Ch\u00e2u B\u00ednh", prefix: "X\u00e3" },
          { id: "4208", name: "Ch\u00e2u B\u00ecnh", prefix: "X\u00e3" },
          { id: "4209", name: "Ch\u00e2u H\u1ea1nh", prefix: "X\u00e3" },
          { id: "4210", name: "Ch\u00e2u Ho\u00e0n", prefix: "X\u00e3" },
          { id: "4211", name: "Ch\u00e2u H\u1ed9i", prefix: "X\u00e3" },
          { id: "4212", name: "Ch\u00e2u Nga", prefix: "X\u00e3" },
          { id: "4213", name: "Ch\u00e2u Phong", prefix: "X\u00e3" },
          { id: "4214", name: "Ch\u00e2u Th\u1eafng", prefix: "X\u00e3" },
          { id: "4215", name: "Ch\u00e2u Thu\u1eadn", prefix: "X\u00e3" },
          { id: "4216", name: "Ch\u00e2u Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "4217", name: "Di\u00ean L\u00e3m", prefix: "X\u00e3" },
          {
            id: "4218",
            name: "T\u00e2n L\u1ea1c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          {
            id: "23628",
            name: "Qu\u1ed1c l\u1ed9 48",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "279",
        name: "Qu\u1ef3 H\u1ee3p",
        wards: [
          {
            id: "4219",
            name: "B\u1eafc S\u01a1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "4220",
            name: "Ch\u00e2u C\u01b0\u1eddng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "4221",
            name: "Ch\u00e2u \u0110\u00ecnh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "4222",
            name: "Ch\u00e2u H\u1ed3ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "4223",
            name: "Ch\u00e2u L\u1ed9c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "4224",
            name: "Ch\u00e2u L\u00fd",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4225", name: "Ch\u00e2u Quang", prefix: "Th\u1ecb tr\u1ea5n" },
          {
            id: "4226",
            name: "Ch\u00e2u Th\u00e1i",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "4227",
            name: "Ch\u00e2u Th\u00e0nh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "4228",
            name: "Ch\u00e2u Ti\u1ebfn",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "4229",
            name: "\u0110\u1ed3ng H\u1ee3p",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "4230",
            name: "H\u1ea1 S\u01a1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "4231",
            name: "Li\u00ean H\u1ee3p",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4232", name: "Minh H\u1ee3p", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "4233", name: "Nam S\u01a1n", prefix: "Th\u1ecb tr\u1ea5n" },
          {
            id: "4234",
            name: "Ngh\u0129a Xu\u00e2n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "4235",
            name: "Qu\u1ef3 H\u1ee3p",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4236", name: "Tam H\u1ee3p", prefix: "Th\u1ecb tr\u1ea5n" },
          {
            id: "4237",
            name: "Th\u1ecd H\u1ee3p",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "4238",
            name: "V\u0103n L\u1ee3i",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "4239",
            name: "Y\u00ean H\u1ee3p",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "280",
        name: "Qu\u1ef3nh L\u01b0u",
        wards: [
          { id: "4240", name: "An H\u00f2a", prefix: "X\u00e3" },
          {
            id: "4241",
            name: "C\u1ea7u Gi\u00e1t",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4242", name: "Ng\u1ecdc S\u01a1n", prefix: "X\u00e3" },
          { id: "4243", name: "Qu\u1ef3nh B\u00e1", prefix: "X\u00e3" },
          { id: "4244", name: "Qu\u1ef3nh B\u1ea3ng", prefix: "X\u00e3" },
          { id: "4245", name: "Qu\u1ef3nh Ch\u00e2u", prefix: "X\u00e3" },
          { id: "4246", name: "Qu\u1ef3nh Di\u1ec7n", prefix: "X\u00e3" },
          { id: "4247", name: "Qu\u1ef3nh \u0110\u00f4i", prefix: "X\u00e3" },
          { id: "4248", name: "Qu\u1ef3nh Giang", prefix: "X\u00e3" },
          { id: "4249", name: "Qu\u1ef3nh H\u1eadu", prefix: "X\u00e3" },
          { id: "4250", name: "Qu\u1ef3nh Hoa", prefix: "X\u00e3" },
          { id: "4251", name: "Qu\u1ef3nh H\u1ed3ng", prefix: "X\u00e3" },
          { id: "4252", name: "Qu\u1ef3nh H\u01b0ng", prefix: "X\u00e3" },
          { id: "4253", name: "Qu\u1ef3nh L\u00e2m", prefix: "X\u00e3" },
          { id: "4254", name: "Qu\u1ef3nh Long", prefix: "X\u00e3" },
          { id: "4255", name: "Qu\u1ef3nh L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "4256", name: "Qu\u1ef3nh Minh", prefix: "X\u00e3" },
          { id: "4257", name: "Qu\u1ef3nh M\u1ef9", prefix: "X\u00e3" },
          { id: "4258", name: "Qu\u1ef3nh Ngh\u0129a", prefix: "X\u00e3" },
          { id: "4259", name: "Qu\u1ef3nh Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "4260", name: "Qu\u1ef3nh Tam", prefix: "X\u00e3" },
          { id: "4261", name: "Qu\u1ef3nh T\u00e2n", prefix: "X\u00e3" },
          { id: "4262", name: "Qu\u1ef3nh Th\u1ea1ch", prefix: "X\u00e3" },
          { id: "4263", name: "Qu\u1ef3nh Th\u1eafng", prefix: "X\u00e3" },
          { id: "4264", name: "Qu\u1ef3nh Thanh", prefix: "X\u00e3" },
          { id: "4265", name: "Qu\u1ef3nh Th\u1ecd", prefix: "X\u00e3" },
          { id: "4266", name: "Qu\u1ef3nh Thu\u1eadn", prefix: "X\u00e3" },
          { id: "4267", name: "Qu\u1ef3nh V\u0103n", prefix: "X\u00e3" },
          { id: "4268", name: "Qu\u1ef3nh Y\u00ean", prefix: "X\u00e3" },
          { id: "4269", name: "S\u01a1n H\u1ea3i", prefix: "X\u00e3" },
          { id: "4270", name: "T\u00e2n S\u01a1n", prefix: "X\u00e3" },
          { id: "4271", name: "T\u00e2n Th\u1eafng", prefix: "X\u00e3" },
          { id: "4272", name: "Ti\u1ebfn Th\u1ee7y", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "23629",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23630",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23631",
            name: "Qu\u1ed1c l\u1ed9 48B",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "281",
        name: "T\u00e2n K\u1ef3",
        wards: [
          { id: "4273", name: "\u0110\u1ed3ng V\u0103n", prefix: "X\u00e3" },
          { id: "4274", name: "Giai Xu\u00e2n", prefix: "X\u00e3" },
          { id: "4275", name: "H\u01b0\u01a1ng S\u01a1n", prefix: "X\u00e3" },
          { id: "4276", name: "K\u1ef3 S\u01a1n", prefix: "X\u00e3" },
          { id: "4277", name: "K\u1ef3 T\u00e2n", prefix: "X\u00e3" },
          { id: "4278", name: "Ngh\u0129a B\u00ecnh", prefix: "X\u00e3" },
          { id: "4280", name: "Ngh\u0129a D\u0169ng", prefix: "X\u00e3" },
          { id: "4279", name: "Ngh\u0129a \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "4281", name: "Ngh\u0129a H\u00e0nh", prefix: "X\u00e3" },
          { id: "4282", name: "Ngh\u0129a Ho\u00e0n", prefix: "X\u00e3" },
          { id: "4283", name: "Ngh\u0129a H\u1ee3p", prefix: "X\u00e3" },
          { id: "4284", name: "Ngh\u0129a Ph\u00fac", prefix: "X\u00e3" },
          { id: "4285", name: "Ngh\u0129a Th\u00e1i", prefix: "X\u00e3" },
          { id: "4286", name: "Ph\u00fa S\u01a1n", prefix: "X\u00e3" },
          { id: "4287", name: "T\u00e2n An", prefix: "X\u00e3" },
          { id: "4288", name: "T\u00e2n H\u1ee3p", prefix: "X\u00e3" },
          { id: "4289", name: "T\u00e2n H\u01b0\u01a1ng", prefix: "X\u00e3" },
          {
            id: "4290",
            name: "T\u00e2n K\u1ef3",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4291", name: "T\u00e2n Long", prefix: "X\u00e3" },
          { id: "4292", name: "T\u00e2n Ph\u00fa", prefix: "X\u00e3" },
          { id: "4293", name: "T\u00e2n Xu\u00e2n", prefix: "X\u00e3" },
          { id: "4294", name: "Ti\u00ean K\u1ef3", prefix: "X\u00e3" },
        ],
        streets: [{ id: "23632", name: "15A", prefix: "\u0110\u01b0\u1eddng" }],
        projects: [],
      },
      {
        id: "282",
        name: "Th\u00e1i H\u00f2a",
        wards: [
          { id: "4295", name: "\u0110\u00f4ng Hi\u1ebfu", prefix: "X\u00e3" },
          {
            id: "4296",
            name: "H\u00f2a Hi\u1ebfu",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4297", name: "Long S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "4298", name: "Ngh\u0129a H\u00f2a", prefix: "X\u00e3" },
          { id: "4299", name: "Ngh\u0129a M\u1ef9", prefix: "X\u00e3" },
          { id: "4300", name: "Ngh\u0129a Thu\u1eadn", prefix: "X\u00e3" },
          { id: "4301", name: "Ngh\u0129a Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "4302", name: "Quang Phong", prefix: "Ph\u01b0\u1eddng" },
          { id: "4303", name: "Quang Ti\u1ebfn", prefix: "Ph\u01b0\u1eddng" },
          { id: "4304", name: "T\u00e2y Hi\u1ebfu", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "23633", name: "48", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23634",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23635",
            name: "Qu\u1ed1c l\u1ed9 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23636", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23637",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "283",
        name: "Thanh Ch\u01b0\u01a1ng",
        wards: [
          { id: "4305", name: "C\u00e1t V\u0103n", prefix: "X\u00e3" },
          { id: "4307", name: "D\u00f9ng", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "4306", name: "\u0110\u1ed3ng V\u0103n", prefix: "X\u00e3" },
          { id: "4308", name: "H\u1ea1nh L\u00e2m", prefix: "X\u00e3" },
          { id: "4309", name: "Ng\u1ecdc L\u00e2m", prefix: "X\u00e3" },
          { id: "4310", name: "Ng\u1ecdc S\u01a1n", prefix: "X\u00e3" },
          { id: "4311", name: "Phong Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "4312", name: "Thanh An", prefix: "X\u00e3" },
          { id: "4313", name: "Thanh Chi", prefix: "X\u00e3" },
          {
            id: "4314",
            name: "Thanh Ch\u01b0\u01a1ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4317", name: "Thanh D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "4315", name: "Thanh \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "4316", name: "Thanh \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "4318", name: "Thanh Giang", prefix: "X\u00e3" },
          { id: "4319", name: "Thanh H\u00e0", prefix: "X\u00e3" },
          { id: "4320", name: "Thanh Ho\u00e0", prefix: "X\u00e3" },
          { id: "4321", name: "Thanh H\u01b0ng", prefix: "X\u00e3" },
          { id: "4322", name: "Thanh H\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "4323", name: "Thanh Khai", prefix: "X\u00e3" },
          { id: "4324", name: "Thanh Kh\u00ea", prefix: "X\u00e3" },
          { id: "4325", name: "Thanh L\u00e2m", prefix: "X\u00e3" },
          { id: "4326", name: "Thanh Li\u00ean", prefix: "X\u00e3" },
          { id: "4327", name: "Thanh L\u0129nh", prefix: "X\u00e3" },
          { id: "4328", name: "Thanh Long", prefix: "X\u00e3" },
          { id: "4329", name: "Thanh L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "4330", name: "Thanh Mai", prefix: "X\u00e3" },
          { id: "4331", name: "Thanh M\u1ef9", prefix: "X\u00e3" },
          { id: "4332", name: "Thanh Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "4333", name: "Thanh Nho", prefix: "X\u00e3" },
          { id: "4334", name: "Thanh Phong", prefix: "X\u00e3" },
          { id: "4335", name: "Thanh S\u01a1n", prefix: "X\u00e3" },
          { id: "4336", name: "Thanh Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "4337", name: "Thanh Thu\u1ef7", prefix: "X\u00e3" },
          { id: "4338", name: "Thanh Ti\u00ean", prefix: "X\u00e3" },
          { id: "4339", name: "Thanh T\u00f9ng", prefix: "X\u00e3" },
          { id: "4340", name: "Thanh T\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "4341", name: "Thanh V\u0103n", prefix: "X\u00e3" },
          { id: "4342", name: "Thanh Xu\u00e2n", prefix: "X\u00e3" },
          { id: "4343", name: "Thanh Y\u00ean", prefix: "X\u00e3" },
          { id: "4344", name: "V\u00f5 Li\u1ec7t", prefix: "X\u00e3" },
          { id: "4345", name: "Xu\u00e2n T\u01b0\u1eddng", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "23638",
            name: "Qu\u1ed1c l\u1ed9 46",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "284",
        name: "T\u01b0\u01a1ng D\u01b0\u01a1ng",
        wards: [
          {
            id: "4346",
            name: "H\u00f2a B\u00ecnh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "4347",
            name: "H\u1eefu Khu\u00f4ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "4348",
            name: "L\u01b0\u1ee3ng Minh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "4349",
            name: "L\u01b0u Ki\u1ec1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4350", name: "Mai S\u01a1n", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "4351", name: "Nga My", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "4352", name: "Nh\u00f4n Mai", prefix: "Th\u1ecb tr\u1ea5n" },
          {
            id: "4353",
            name: "Tam \u0110\u00ecnh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4354", name: "Tam H\u1ee3p", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "4355", name: "Tam Quang", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "4356", name: "Tam Th\u00e1i", prefix: "Th\u1ecb tr\u1ea5n" },
          {
            id: "4357",
            name: "Th\u1ea1ch Gi\u00e1m",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "4358",
            name: "X\u00e1 L\u01b0\u1ee3ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4359", name: "Xi\u00eang My", prefix: "Th\u1ecb tr\u1ea5n" },
          {
            id: "4360",
            name: "Y\u00ean H\u00f2a",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4361", name: "Y\u00ean Na", prefix: "Th\u1ecb tr\u1ea5n" },
          {
            id: "4362",
            name: "Y\u00ean Th\u1eafng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "4363",
            name: "Y\u00ean T\u0129nh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "285",
        name: "Vinh",
        wards: [
          {
            id: "4364",
            name: " B\u1ebfn Thu\u1ef7",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4365", name: "C\u1eeda Nam", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "4366",
            name: "\u0110\u1ed9i Cung",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "4367",
            name: "\u0110\u00f4ng V\u0129nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "4368",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "4369",
            name: "H\u1ed3ng S\u01a1n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "4370",
            name: "H\u01b0ng B\u00ecnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4371", name: "H\u01b0ng Ch\u00ednh", prefix: "X\u00e3" },
          {
            id: "4373",
            name: "H\u01b0ng D\u0169ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4372", name: "H\u01b0ng \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "4374", name: "H\u01b0ng H\u00f2a", prefix: "X\u00e3" },
          { id: "4375", name: "H\u01b0ng L\u1ed9c", prefix: "X\u00e3" },
          {
            id: "4376",
            name: "H\u01b0ng Ph\u00fac",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4377", name: "H\u01b0ng Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "4378", name: "L\u00ea L\u1ee3i", prefix: "Ph\u01b0\u1eddng" },
          { id: "4379", name: "L\u00ea Mao", prefix: "Ph\u01b0\u1eddng" },
          { id: "4380", name: "Nghi \u00c2n", prefix: "X\u00e3" },
          { id: "4381", name: "Nghi \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "4382", name: "Nghi Kim", prefix: "X\u00e3" },
          { id: "4383", name: "Nghi Li\u00ean", prefix: "X\u00e3" },
          { id: "4384", name: "Nghi Phong", prefix: "X\u00e3" },
          { id: "4385", name: "Nghi Ph\u00fa", prefix: "X\u00e3" },
          {
            id: "4386",
            name: "Qu\u00e1n B\u00e0u",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4387", name: "Quang Trung", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "4388",
            name: "Trung \u0110\u00f4",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "4389",
            name: "Tr\u01b0\u1eddng Thi",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4390", name: "Vinh T\u00e2n", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "23639", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23640", name: "32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23641", name: "46", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23642", name: "8B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23643",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23644", name: "B\u1eafc Nam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23645",
            name: "B\u1ea1ch Li\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23646",
            name: "B\u00f9i D\u01b0\u01a1ng L\u1ecbch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23647",
            name: "B\u00f9i Huy B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23648",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23649",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23650",
            name: "Cao Huy \u0110\u1ec9nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23651", name: "Cao L\u1ed7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23652",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23653",
            name: "Cao Xu\u00e2n D\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23654",
            name: "Cao Xu\u00e2n Huy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23655",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23656",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23657",
            name: "C\u1eeda H\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23680", name: "Duy T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23658",
            name: "\u0110\u1ea1i l\u1ed9 Th\u0103ng Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23659",
            name: "\u0110\u1eb7ng Nguy\u00ean C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23660",
            name: "\u0110\u1eb7ng Nh\u01b0 Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23661", name: "\u0110\u1eb7ng T\u1ea5t", prefix: "Ph\u1ed1" },
          {
            id: "23662",
            name: "\u0110\u1eb7ng Thai Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23663",
            name: "\u0110\u1eb7ng Th\u00e1i Th\u00e2n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23664",
            name: "\u0110\u1eb7ng Th\u00fac H\u1ee9a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23665",
            name: "\u0110\u1eb7ng V\u0103n Ng\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23666",
            name: "\u0110\u1eb7ng V\u0103n Th\u1ee5y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23667",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23668",
            name: "\u0110\u00e0o T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23669",
            name: "\u0110\u1eadu Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23670",
            name: "\u0110\u1ec1 Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23671",
            name: "\u0110inh B\u1ea1t T\u1ee5y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23672",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23673",
            name: "\u0110inh L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23674",
            name: "\u0110inh V\u0103n Ch\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23675",
            name: "\u0110o\u00e0n Nh\u1eef H\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23676",
            name: "\u0110\u1ed1c Thi\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23677",
            name: "\u0110\u1ed9i Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23678",
            name: "\u0110\u1ee9c Thi\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23679",
            name: "\u0110\u1ee9c Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23681",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23682",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23683",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23684",
            name: "H\u00e0n M\u1eb7c T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23685", name: "Herman", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23686",
            name: "H\u1ed3 B\u00e1 Ki\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23687",
            name: "H\u1ed3 B\u00e1 \u00d4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23688",
            name: "H\u1ed3 H\u00e1n Th\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23689",
            name: "H\u1ed3 H\u1eefu Nh\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23690",
            name: "H\u1ed3 Ng\u1ecdc L\u00e3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23691",
            name: "H\u1ed3 Phi T\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23692",
            name: "H\u1ed3 Qu\u00fd Ly",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23694",
            name: "H\u1ed3 S\u1ef9 D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23693",
            name: "H\u1ed3 S\u1ef9 \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23695",
            name: "H\u1ed3 T\u00f4ng Th\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23696",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23697",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23698",
            name: "H\u00f2a An 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23699",
            name: "Ho\u00e0ng Danh S\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23700",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23701",
            name: "Ho\u00e0ng Ngh\u0129a L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23702",
            name: "Ho\u00e0ng Nguy\u00ean C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23703",
            name: "Ho\u00e0ng Phan Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23704",
            name: "Ho\u00e0ng T\u1ea3 Th\u1ed1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23705",
            name: "Ho\u00e0ng Th\u1ecb Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23706",
            name: "Ho\u00e0ng Tr\u1ecdng Tr\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23707",
            name: "Ho\u00e0ng V\u0103n Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23708",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23709",
            name: "Ho\u00e0ng Xu\u00e2n H\u00e3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23710",
            name: "H\u1ed3ng B\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23711",
            name: "H\u1ed3ng Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23712",
            name: "H\u1ed3ng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23713",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23714",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23715", name: "Kim Y\u00ean", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23716",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23717",
            name: "L\u00ea Ho\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23718",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23719",
            name: "L\u00ea H\u1ed3ng S\u01a1n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23720",
            name: "L\u00ea Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23721",
            name: "L\u00ea Kh\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23722", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23723",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23724", name: "L\u00ea Mao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23725",
            name: "L\u00ea Ng\u1ecdc H\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23726", name: "L\u00ea Nin", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23727", name: "L\u00ea Ninh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23728",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23729",
            name: "L\u00ea V\u0103n H\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23730",
            name: "L\u00ea V\u0103n Mi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23731",
            name: "L\u00ea V\u0103n T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23732",
            name: "L\u00ea V\u0103n T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23733",
            name: "L\u00ea Vi\u1ebft L\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23734",
            name: "L\u00ea Vi\u1ebft Thu\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23735",
            name: "L\u00ea Xu\u00e2n \u0110\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23736", name: "L\u00f2 Cao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23737",
            name: "L\u1ee5c Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23738",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23739",
            name: "L\u00fd Nh\u1eadt Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23740",
            name: "L\u00fd Th\u00e1i T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23741",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23742",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23743",
            name: "Mai H\u1eafc \u0110\u1ebf",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23744",
            name: "Mai L\u00e3o Bang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23745", name: "Minh Khai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23746",
            name: "Ngh\u1ec7 An - Xi\u00eang Kho\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23747",
            name: "Nghi Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23748", name: "Nghi Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23749",
            name: "Ng\u00f4 \u0110\u1ee9c K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23750",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23751",
            name: "Ng\u00f4 Qu\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23752",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23753",
            name: "Ng\u00f4 T\u1ea5t T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23754",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23755",
            name: "Ng\u00f4 Tr\u00ed H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23756",
            name: "Ng\u00f4 Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23757",
            name: "Ng\u00f4 V\u0103n S\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23758",
            name: "Ng\u01b0 H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23759",
            name: "Nguy\u1ec5n Bi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23760",
            name: "Nguy\u1ec5n B\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23761",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23762",
            name: "Nguy\u1ec5n C\u1ea3nh Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23763",
            name: "Nguy\u1ec5n C\u1ea3nh D\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23764",
            name: "Nguy\u1ec5n C\u1ea3nh Hoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23765",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23766",
            name: "Nguy\u1ec5n Ch\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23767",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23768",
            name: "Nguy\u1ec5n C\u00f4ng Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23770",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23774",
            name: "Nguy\u1ec5n Duy Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23769",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23771",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23772",
            name: "Nguy\u1ec5n \u0110\u1ee9c \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23773",
            name: "Nguy\u1ec5n \u0110\u1ee9c M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23775",
            name: "Nguy\u1ec5n Gia Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23776",
            name: "Nguy\u1ec5n Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23777",
            name: "Nguy\u1ec5n H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23778",
            name: "Nguy\u1ec5n Huy O\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23779",
            name: "Nguy\u1ec5n Kh\u00e1nh To\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23780",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23781",
            name: "Nguy\u1ec5n Ki\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23782",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23783",
            name: "Nguy\u1ec5n N\u0103ng T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23784",
            name: "Nguy\u1ec5n Nghi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23785",
            name: "Nguy\u1ec5n Ng\u1ecdc Sanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23786",
            name: "Nguy\u1ec5n Phong S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23787",
            name: "Nguy\u1ec5n Qu\u1ed1c Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23788",
            name: "Nguy\u1ec5n Sinh S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23789",
            name: "Nguy\u1ec5n S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23790",
            name: "Nguy\u1ec5n S\u01b0 H\u1ed3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23791",
            name: "Nguy\u1ec5n S\u1ef9 Qu\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23792",
            name: "Nguy\u1ec5n S\u1ef9 S\u00e1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23793",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23794",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23795",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23796",
            name: "Nguy\u1ec5n Thi\u1ebfp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23797",
            name: "Nguy\u1ec5n Ti\u1ec1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23798",
            name: "Nguy\u1ec5n Ti\u1ebfn T\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23799",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23800",
            name: "Nguy\u1ec5n Trung Ng\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23801",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23802",
            name: "Nguy\u1ec5n Tu\u1ea5n Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23803",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23804",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23805",
            name: "Nguy\u1ec5n V\u0103n Giao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23806",
            name: "Nguy\u1ec5n V\u0103n T\u1ed1",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23807",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23808",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23809",
            name: "Nguy\u1ec5n V\u0129nh L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23810", name: "Nguy\u1ec5n X\u00ed", prefix: "Ph\u1ed1" },
          {
            id: "23811",
            name: "Nguy\u1ec5n Xi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23812",
            name: "Nguy\u1ec5n Xu\u00e2n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23813",
            name: "Nguy\u1ec5n Xu\u00e2n \u00d4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23814",
            name: "Ph\u1ea1m \u0110\u00ecnh Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23815",
            name: "Ph\u1ea1m \u0110\u00ecnh To\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23816",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23817",
            name: "Ph\u1ea1m Huy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23818",
            name: "Ph\u1ea1m Kinh V\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23819",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23820",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23821",
            name: "Ph\u1ea1m Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23822",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23823",
            name: "Phan C\u1ea3nh Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23824",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23825",
            name: "Phan C\u00f4ng T\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23826",
            name: "Phan \u0110\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23827",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23828",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23829",
            name: "Phan Huy Ch\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23830",
            name: "Phan Huy \u00cdch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23831",
            name: "Phan Huy Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23832",
            name: "Ph\u1ea7n L\u0103ng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23833",
            name: "Phan S\u1ef9 Th\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23834",
            name: "Phan T\u1ea5t Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23835",
            name: "Phan Th\u00e1i \u1ea4t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23836",
            name: "Phan Th\u00fac Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23837",
            name: "Phan V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23838",
            name: "Phong \u0110inh C\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23839",
            name: "Ph\u00fa Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23840",
            name: "Ph\u00f9ng Ch\u00ed Ki\u00ean",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23841",
            name: "Ph\u00f9ng Kh\u1eafc Khoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23842",
            name: "Ph\u00f9ng Ph\u00fac Ki\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23843",
            name: "Ph\u01b0\u1ee3ng Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23844",
            name: "Quang Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23845", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23846",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23847",
            name: "Qu\u1ed1c l\u1ed9 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23848",
            name: "Qu\u1ed1c l\u1ed9 46",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23849",
            name: "Si\u00eau H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23850",
            name: "T\u1ea1 c\u00f4ng luy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23851", name: "T\u1ea1 Quang B\u1eedu", prefix: "Ph\u1ed1" },
          {
            id: "23852",
            name: "T\u1ea3n \u0110\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23853",
            name: "T\u00e2n H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23854",
            name: "T\u00e2n H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23855",
            name: "T\u00e2n Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23856",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23857",
            name: "T\u00e2n Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23858",
            name: "T\u00e2n Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23859",
            name: "T\u00e2y H\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23860",
            name: "Th\u00e1i Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23861",
            name: "Th\u0103ng  Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23862",
            name: "Th\u00e0nh Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23863", name: "Thanh Vinh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23864",
            name: "T\u00f4 B\u00e1 Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23865",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23866",
            name: "T\u00f4 Ng\u1ecdc V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23867",
            name: "T\u00f4n Th\u1ea5t Thuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23868",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23869",
            name: "T\u1ed1ng Duy T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23870",
            name: "Tra\u0300 L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23871",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23872",
            name: "Tr\u1ea7n C\u1ea3nh B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23873",
            name: "Tr\u1ea7n \u0110\u00ecnh Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23874",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23875",
            name: "Tr\u1ea7n H\u1eefu Thung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23876",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23877",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23878",
            name: "Tr\u00e2\u0300n Minh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23879",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23880",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23881",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23882",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23883",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23884",
            name: "Tr\u1ea7n Qu\u1ed1c Ho\u00e0n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23885",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23886",
            name: "Tr\u1ea7n T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23887",
            name: "Tr\u1ea7n Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23888",
            name: "Tr\u1ea7n Th\u1ee7 \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23889",
            name: "Tr\u1ea7n Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23890",
            name: "Tr\u1ea7n Tr\u00f9ng Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23891",
            name: "Tr\u1ecbnh Ho\u00e0i \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23892",
            name: "Trung Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23893",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23894",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23895",
            name: "Tr\u01b0\u01a1ng Huy Li\u1ec5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23896",
            name: "Tr\u01b0\u1eddng Thi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23897",
            name: "Tr\u01b0\u1eddng Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23898",
            name: "Tr\u01b0\u01a1ng V\u0103n L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23899",
            name: "T\u00fa X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23900",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23901", name: "Va\u0323n An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23902",
            name: "V\u0103n \u0110\u1ee9c Giai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23903",
            name: "V\u0103n T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23904",
            name: "V\u1ec7 \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23905",
            name: "V\u0129nh M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23906",
            name: "V\u00f5 Nguy\u00ean Hi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23907",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23908",
            name: "V\u01b0\u01a1ng Th\u00fac M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23909",
            name: "X\u00f4 Vi\u1ebft Ngh\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23910",
            name: "Xu\u00e2n Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23911",
            name: "Xu\u00e2n Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23912",
            name: "Xu\u00e2n Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23913",
            name: "Y\u00ean B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23914",
            name: "Y\u00ean D\u0169ng Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23915",
            name: "Y\u00ean Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2880",
            name: "Arita Home",
            lat: "18.687421798706",
            lng: "105.66644287109",
          },
          {
            id: "2881",
            name: "B\u1ea3o S\u01a1n Complex",
            lat: "18.686487197876",
            lng: "105.68729400635",
          },
          {
            id: "2882",
            name: "B\u1ea3o S\u01a1n Green Pearl",
            lat: "18.681922912598",
            lng: "105.66914367676",
          },
          {
            id: "2883",
            name: "Chung c\u01b0 Huy H\u00f9ng",
            lat: "18.691265106201",
            lng: "105.68968200684",
          },
          {
            id: "2884",
            name: "Chung c\u01b0 Kim Tr\u01b0\u1eddng Thi",
            lat: "18.668657302856",
            lng: "105.69725036621",
          },
          {
            id: "2885",
            name: "Chung c\u01b0 Sao Ngh\u1ec7",
            lat: "18.686487197876",
            lng: "105.68729400635",
          },
          {
            id: "2886",
            name: "C\u1eeda Ti\u1ec1n Home",
            lat: "18.660758972168",
            lng: "105.66484069824",
          },
          {
            id: "2887",
            name: "Golden City 10",
            lat: "18.691408157349",
            lng: "105.67471313477",
          },
          {
            id: "2888",
            name: "Golden City 12",
            lat: "18.696298599243",
            lng: "105.68943023682",
          },
          {
            id: "2889",
            name: "Golden City 4",
            lat: "18.687097549438",
            lng: "105.67023468018",
          },
          {
            id: "2890",
            name: "Green View Tower",
            lat: "18.68078994751",
            lng: "105.66807556152",
          },
          {
            id: "2891",
            name: "Handico 30 Nghi Ph\u00fa",
            lat: "18.697618484497",
            lng: "105.68534851074",
          },
          {
            id: "2892",
            name: "K\u0110T \u0110\u1ea1i Th\u00e0nh - Nghi Kim",
            lat: "18.714305877686",
            lng: "105.66288757324",
          },
          {
            id: "2893",
            name: "Khu \u0111\u00f4 th\u1ecb Nam L\u00ea L\u1ee3i",
            lat: "18.681922912598",
            lng: "105.66914367676",
          },
          {
            id: "2894",
            name: "Khu \u0111\u00f4 th\u1ecb Nghi Ph\u00fa",
            lat: "18.706970214844",
            lng: "105.67958068848",
          },
          {
            id: "2895",
            name: "M\u01b0\u1eddng Thanh C\u1eeda \u0110\u00f4ng",
            lat: "18.685810089111",
            lng: "105.6993560791",
          },
          {
            id: "2896",
            name: "Petro Ngh\u1ec7 An Building",
            lat: "18.69920539856",
            lng: "105.67667388916",
          },
          {
            id: "2897",
            name: "T&T Victoria",
            lat: "18.673767089844",
            lng: "105.67427062988",
          },
          {
            id: "2898",
            name: "T\u00e2n Th\u1ecbnh",
            lat: "18.668912887573",
            lng: "105.68505096436",
          },
          {
            id: "2899",
            name: "T\u00e2y \u0111\u1ea1i l\u1ed9 V.L L\u00ea Nin",
            lat: "18.700046539307",
            lng: "105.68235015869",
          },
          {
            id: "2900",
            name: "Tecco \u0111\u01b0\u1eddng 72m",
            lat: "18.698509216309",
            lng: "105.67840576172",
          },
          {
            id: "2901",
            name: "Tecco Tower",
            lat: "18.673410415649",
            lng: "105.67465209961",
          },
          {
            id: "2902",
            name: "Tecco Tower B\u1ebfn Th\u1ee7y",
            lat: "18.65235710144",
            lng: "105.70107269287",
          },
          {
            id: "2903",
            name: "Tecco Tr\u01b0\u1eddng Th\u1ecbnh",
            lat: "18.668657302856",
            lng: "105.69725036621",
          },
          {
            id: "2904",
            name: "Trung \u0110\u1ee9c Tower",
            lat: "18.681978225708",
            lng: "105.67460632324",
          },
          {
            id: "2905",
            name: "Vicentra",
            lat: "18.665355682373",
            lng: "105.67427062988",
          },
          {
            id: "2906",
            name: "Vinh Plaza",
            lat: "18.69225692749",
            lng: "105.67459869385",
          },
          {
            id: "2907",
            name: "Vinh T\u00e2n",
            lat: "18.652421951294",
            lng: "105.69110870361",
          },
        ],
      },
      {
        id: "286",
        name: "Y\u00ean Th\u00e0nh",
        wards: [
          { id: "4391", name: "B\u1eafc Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4392", name: "B\u1ea3o Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4393", name: "C\u00f4ng Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4394", name: "\u0110\u1ea1i Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4395", name: "\u0110\u00f4 Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4396", name: "\u0110\u1ed3ng Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4397", name: "\u0110\u1ee9c Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4398", name: "H\u1eadu Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4399", name: "Hoa Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4400", name: "H\u1ed3ng Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4401", name: "H\u1ee3p Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4402", name: "H\u00f9ng Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4403", name: "Kh\u00e1nh D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "4404", name: "Kh\u00e1nh Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4405", name: "Kim Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4406", name: "L\u0103ng Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4407", name: "Li\u00ean Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4408", name: "Long Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4409", name: "L\u00fd Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4410", name: "M\u00e3 Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4411", name: "Minh Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4412", name: "M\u1ef9 Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4413", name: "Nam Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4414", name: "Nh\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4415", name: "Ph\u00fa Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4416", name: "Ph\u00fac Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4417", name: "Quang Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4418", name: "S\u01a1n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4419", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4420", name: "T\u0103ng Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4421", name: "T\u00e2y Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4422", name: "Th\u1ecbnh Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4423", name: "Th\u1ecd Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4424", name: "Ti\u1ebfn Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4425", name: "Trung Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4426", name: "V\u0103n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4427", name: "Vi\u00ean Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4428", name: "V\u0129nh Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4429", name: "Xu\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          {
            id: "4430",
            name: "Y\u00ean Th\u00e0nh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          {
            id: "23916",
            name: "Qu\u1ed1c l\u1ed9 7A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23917",
            name: "T\u1ec9nh L\u1ed9 538",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "21",
    code: "HD",
    name: "H\u1ea3i D\u01b0\u01a1ng",
    districts: [
      {
        id: "287",
        name: "B\u00ecnh Giang",
        wards: [
          { id: "4431", name: "B\u00ecnh Minh", prefix: "X\u00e3" },
          { id: "4432", name: "B\u00ecnh Xuy\u00ean", prefix: "X\u00e3" },
          { id: "4433", name: "C\u1ed5 Bi", prefix: "X\u00e3" },
          { id: "4434", name: "H\u1ed3ng Kh\u00ea", prefix: "X\u00e3" },
          { id: "4435", name: "H\u00f9ng Th\u1eafng", prefix: "X\u00e3" },
          { id: "4436", name: "H\u01b0ng Th\u1ecbnh", prefix: "X\u00e3" },
          {
            id: "4437",
            name: "K\u1ebb S\u1eb7t",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4438", name: "Long Xuy\u00ean", prefix: "X\u00e3" },
          { id: "4439", name: "Nh\u00e2n Quy\u1ec1n", prefix: "X\u00e3" },
          { id: "4440", name: "T\u00e2n H\u1ed3ng", prefix: "X\u00e3" },
          { id: "4441", name: "T\u00e2n Vi\u1ec7t", prefix: "X\u00e3" },
          { id: "4442", name: "Th\u00e1i D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "4443", name: "Th\u00e1i H\u00f2a", prefix: "X\u00e3" },
          { id: "4444", name: "Th\u00e1i H\u1ecdc", prefix: "X\u00e3" },
          { id: "4445", name: "Th\u00fac Kh\u00e1ng", prefix: "X\u00e3" },
          { id: "4446", name: "Tr\u00e1ng Li\u1ec7t", prefix: "X\u00e3" },
          { id: "4447", name: "V\u0129nh H\u1ed3ng", prefix: "X\u00e3" },
          { id: "4448", name: "V\u0129nh Tuy", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "23918", name: "20A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23919", name: "392", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23920",
            name: "N\u1eef V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23921", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23922",
            name: "Qu\u1ed1c L\u1ed9 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23923",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "288",
        name: "C\u1ea9m Gi\u00e0ng",
        wards: [
          { id: "4449", name: "C\u1ea9m \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "4450", name: "C\u1ea9m \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "4451", name: "C\u1ea9m \u0110o\u00e0i", prefix: "X\u00e3" },
          { id: "4452", name: "C\u1ea9m \u0110\u00f4ng", prefix: "X\u00e3" },
          {
            id: "4453",
            name: "C\u1ea9m Gi\u00e0ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4454", name: "C\u1ea9m Ho\u00e0ng", prefix: "X\u00e3" },
          { id: "4455", name: "C\u1ea9m H\u01b0ng", prefix: "X\u00e3" },
          { id: "4456", name: "C\u1ea9m Ph\u00fac", prefix: "X\u00e3" },
          { id: "4457", name: "C\u1ea9m S\u01a1n", prefix: "X\u00e3" },
          { id: "4458", name: "C\u1ea9m V\u0103n", prefix: "X\u00e3" },
          { id: "4459", name: "C\u1ea9m V\u0169", prefix: "X\u00e3" },
          { id: "4460", name: "Cao An", prefix: "X\u00e3" },
          { id: "4461", name: "\u0110\u1ee9c Ch\u00ednh", prefix: "X\u00e3" },
          { id: "4462", name: "Kim Giang", prefix: "X\u00e3" },
          { id: "4463", name: "Lai C\u00e1ch", prefix: "Th\u1ecb tr\u1ea5n" },
          {
            id: "4464",
            name: "L\u01b0\u01a1ng \u0110i\u1ec1n",
            prefix: "X\u00e3",
          },
          { id: "4465", name: "Ng\u1ecdc Li\u00ean", prefix: "X\u00e3" },
          { id: "4466", name: "T\u00e2n Tr\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "4467", name: "Th\u1ea1ch L\u1ed7i", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "23924", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23925",
            name: "\u00c1nh S\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23926",
            name: "Ho\u00e0ng Y\u1ebfn 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23927", name: "Mai Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23928",
            name: "Qu\u1ed1c L\u1ed9 38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23929",
            name: "Qu\u1ed1c L\u1ed9 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23930",
            name: "Qu\u1ed1c L\u1ed9 5A",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2908",
            name: "TNR Star T\u00e2n Tr\u01b0\u1eddng",
            lat: "20.935039520264",
            lng: "106.2232131958",
          },
        ],
      },
      {
        id: "289",
        name: "Ch\u00ed Linh",
        wards: [
          { id: "4468", name: "An L\u1ea1c", prefix: "X\u00e3" },
          { id: "4469", name: "B\u1eafc An", prefix: "Ph\u01b0\u1eddng" },
          { id: "4470", name: "B\u1ebfn T\u1eafm", prefix: "Ph\u01b0\u1eddng" },
          { id: "4471", name: "Ch\u00ed Minh", prefix: "Ph\u01b0\u1eddng" },
          { id: "4472", name: "C\u1ed5 Th\u00e0nh", prefix: "X\u00e3" },
          {
            id: "4473",
            name: "C\u1ed9ng H\u00f2a",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4474", name: "\u0110\u1ed3ng L\u1ea1c", prefix: "X\u00e3" },
          {
            id: "4475",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "4476",
            name: "Ho\u00e0ng T\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4477", name: "Ho\u00e0ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "4478", name: "H\u01b0ng \u0110\u1ea1o", prefix: "X\u00e3" },
          { id: "4479", name: "K\u00eanh Giang", prefix: "X\u00e3" },
          { id: "4480", name: "L\u00ea L\u1ee3i", prefix: "Ph\u01b0\u1eddng" },
          { id: "4481", name: "Nh\u00e2n Hu\u1ec7", prefix: "X\u00e3" },
          { id: "4482", name: "Ph\u1ea3 L\u1ea1i", prefix: "Ph\u01b0\u1eddng" },
          { id: "4483", name: "Sao \u0110\u1ecf", prefix: "Ph\u01b0\u1eddng" },
          { id: "4484", name: "T\u00e2n D\u00e2n", prefix: "X\u00e3" },
          {
            id: "4485",
            name: "Th\u00e1i H\u1ecdc",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4486", name: "V\u0103n An", prefix: "Ph\u01b0\u1eddng" },
          { id: "4487", name: "V\u0103n \u0110\u1ee9c", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "23931", name: "37", prefix: "Ph\u1ed1" },
          { id: "23932", name: "An Ninh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23933",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23934",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23935",
            name: "\u0110o\u00e0n K\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23936",
            name: "H\u1eefu Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23937",
            name: "M\u1ea1c Th\u1ecb B\u01b0\u1edfi",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23938",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23939",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23940",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23941",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23942",
            name: "Qu\u1ed1c l\u1ed9 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23943",
            name: "Qu\u1ed1c l\u1ed9 18A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23944",
            name: "Qu\u1ed1c l\u1ed9 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23945",
            name: "Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23946",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23947",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "23948",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23949",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23950",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "290",
        name: "Gia L\u1ed9c",
        wards: [
          {
            id: "4488",
            name: "\u0110o\u00e0n Th\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
          { id: "4489", name: "\u0110\u1ed3ng Quang", prefix: "X\u00e3" },
          {
            id: "4490",
            name: "\u0110\u1ee9c X\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "4491", name: "Gia H\u00f2a", prefix: "X\u00e3" },
          { id: "4492", name: "Gia Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "4493", name: "Gia L\u1ed9c", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "4494", name: "Gia L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "4495", name: "Gia T\u00e2n", prefix: "X\u00e3" },
          { id: "4496", name: "Gia Xuy\u00ean", prefix: "X\u00e3" },
          { id: "4497", name: "Ho\u00e0ng Di\u1ec7u", prefix: "X\u00e3" },
          { id: "4498", name: "H\u1ed3ng H\u01b0ng", prefix: "X\u00e3" },
          { id: "4499", name: "L\u00ea L\u1ee3i", prefix: "X\u00e3" },
          { id: "4500", name: "Li\u00ean H\u1ed3ng", prefix: "X\u00e3" },
          { id: "4501", name: "Nh\u1eadt T\u00e2n", prefix: "X\u00e3" },
          { id: "4502", name: "Ph\u1ea1m Tr\u1ea5n", prefix: "X\u00e3" },
          { id: "4503", name: "Ph\u01b0\u01a1ng H\u01b0ng", prefix: "X\u00e3" },
          { id: "4504", name: "Quang Minh", prefix: "X\u00e3" },
          { id: "4505", name: "T\u00e2n Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "4506", name: "Th\u1ed1ng K\u00eanh", prefix: "X\u00e3" },
          { id: "4507", name: "Th\u1ed1ng Nh\u1ea5t", prefix: "X\u00e3" },
          { id: "4508", name: "To\u00e0n Th\u1eafng", prefix: "X\u00e3" },
          { id: "4509", name: "Tr\u00f9ng Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "4510", name: "Y\u1ebft Ki\u00eau", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "23951",
            name: "\u0110o\u00e0n Nh\u1eef H\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23952",
            name: "Nguy\u1ec5n Ch\u1ebf Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23953",
            name: "Qu\u1ed1c l\u1ed9 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23954",
            name: "Qu\u1ed1c l\u1ed9 38B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23955",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "291",
        name: "H\u1ea3i D\u01b0\u01a1ng",
        wards: [
          { id: "4511", name: "\u00c1i Qu\u1ed1c", prefix: "X\u00e3" },
          { id: "4512", name: "An Ch\u00e2u", prefix: "X\u00e3" },
          {
            id: "4513",
            name: "B\u00ecnh H\u00e0n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "4514",
            name: "C\u1ea9m Th\u01b0\u1ee3ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4515", name: "H\u1ea3i T\u00e2n", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "4516",
            name: "L\u00ea Thanh Ngh\u1ecb",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4517", name: "Nam \u0110\u1ed3ng", prefix: "X\u00e3" },
          {
            id: "4518",
            name: "Ng\u1ecdc Ch\u00e2u",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "4519",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "4520",
            name: "Nh\u1ecb Ch\u00e2u",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "4521",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4522", name: "Quang Trung", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "4523",
            name: "T\u00e2n B\u00ecnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4524", name: "T\u00e2n H\u01b0ng", prefix: "X\u00e3" },
          { id: "4525", name: "Th\u1ea1ch Kh\u00f4i", prefix: "X\u00e3" },
          { id: "4526", name: "Thanh B\u00ecnh", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "4527",
            name: "Th\u01b0\u1ee3ng \u0110\u1ea1t",
            prefix: "X\u00e3",
          },
          {
            id: "4528",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "4529",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4530", name: "T\u1ee9 Minh", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "4531",
            name: "Vi\u1ec7t H\u00f2a",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "23956", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23957", name: "202", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23958", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23959", name: "351", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23960", name: "385", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23961", name: "38B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23962", name: "392B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23963", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23964", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "23965", name: "79", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23967",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23966",
            name: "An \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23968", name: "An Ninh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23969",
            name: "An Ph\u00fa \u0110\u00f4ng 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23970", name: "An Th\u00e1i", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23971",
            name: "B\u00e1 Li\u1ec5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23972", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "23973",
            name: "B\u1eafc Kinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23974",
            name: "B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23975",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23976",
            name: "B\u1ea1ch N\u0103ng Thi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23977",
            name: "B\u1ea3o Th\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23978",
            name: "B\u00ecnh L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23979",
            name: "B\u00ecnh Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23980",
            name: "B\u00f9i Th\u1ecb C\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23981",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23982",
            name: "Canh N\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23983",
            name: "Canh N\u00f4ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23984",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23985", name: "C\u1ea7u C\u1ed1n", prefix: "Ph\u1ed1" },
          { id: "23986", name: "Chi H\u00f2a", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23987",
            name: "Chi L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23988", name: "Ch\u1ee3 Con", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "23989",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23990",
            name: "Ch\u01b0\u01a1ng D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23991",
            name: "Ch\u01b0\u01a1ng M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23992",
            name: "C\u00f4 \u0110o\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23993",
            name: "C\u00f4 \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "23994", name: "C\u1ed1ng C\u00e2u", prefix: "Ph\u1ed1" },
          {
            id: "23995",
            name: "C\u1ef1u Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23996",
            name: "C\u1ef1u Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23997",
            name: "D\u00e3 T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24022", name: "D\u01b0\u01a1ng Quang", prefix: "Ph\u1ed1" },
          { id: "24023", name: "D\u01b0\u01a1ng T\u1ed1n", prefix: "Ph\u1ed1" },
          {
            id: "23998",
            name: "\u0110\u1ea1i An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "23999",
            name: "\u0110\u00e0m L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24000",
            name: "\u0110\u00e0n L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24001",
            name: "\u0110\u1eb7ng Huy\u1ec1n Th\u00f4ng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24002",
            name: "\u0110\u1eb7ng Qu\u1ed1c Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24003",
            name: "\u0110\u00e0o Duy Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24004",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24005",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24006",
            name: "\u0110inh \u0110\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24007",
            name: "\u0110inh L\u01b0u Kim",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24008",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24009",
            name: "\u0110inh V\u0103n T\u1ea3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24010",
            name: "\u0110\u1ed7 B\u00e1 Linh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24011",
            name: "\u0110\u1ed7 Ng\u1ecdc Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24012", name: "\u0110\u1ed7 Nhu\u1eadn", prefix: "Ph\u1ed1" },
          {
            id: "24013",
            name: "\u0110\u1ed7 Thi\u00ean Th\u01b0",
            prefix: "Ph\u1ed1",
          },
          { id: "24014", name: "\u0110\u1ed7 U\u00f4ng", prefix: "Ph\u1ed1" },
          { id: "24015", name: "\u0110\u1ed7 X\u00e1", prefix: "Ph\u1ed1" },
          {
            id: "24016",
            name: "\u0110o\u00e0n K\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24017",
            name: "\u0110o\u00e0n Nh\u1eef H\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24018",
            name: "\u0110\u1ed9i C\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24019",
            name: "\u0110\u00f4ng H\u01b0ng Thu\u1eadn 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24020", name: "\u0110\u1ed3ng Xu\u00e2n", prefix: "Ph\u1ed1" },
          {
            id: "24021",
            name: "\u0110\u1ee9c Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24024", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "24025",
            name: "H\u1ea3i \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24026",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24027",
            name: "H\u00e0n Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24028",
            name: "H\u00e0n Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24029",
            name: "H\u00e0n Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24030",
            name: "H\u00e0o Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24031",
            name: "H\u1eadu L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24032",
            name: "Hi\u1ec7p Th\u00e0nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24033",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24034",
            name: "H\u00f2a An 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24035",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24036",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24037", name: "Ho\u00e0ng L\u1ed9c", prefix: "Ph\u1ed1" },
          {
            id: "24038",
            name: "Ho\u00e0ng Ng\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24039",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24040",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24041",
            name: "H\u1ed3ng Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24042",
            name: "H\u1ed3ng Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24043",
            name: "H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24044",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24045",
            name: "H\u1eefu Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24046",
            name: "K\u00eanh Tre",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24047",
            name: "Kh\u00fac Th\u1eeba D\u1ee5",
            prefix: "Ph\u1ed1",
          },
          { id: "24048", name: "Kim S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24049",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24050",
            name: "L\u00ea Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24051",
            name: "L\u00ea \u0110\u00ecnh V\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24052",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24053",
            name: "L\u00ea Kh\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24054", name: "L\u00ea Quang B\u00ed", prefix: "Ph\u1ed1" },
          { id: "24055", name: "L\u00ea Thanh Ngh\u1ecb", prefix: "Ph\u1ed1" },
          {
            id: "24056",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24057",
            name: "L\u00ea V\u0103n H\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24058",
            name: "L\u00ea Vi\u1ebft H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24059",
            name: "L\u00ea Vi\u1ebft Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24060",
            name: "L\u1ed9 C\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24061",
            name: "L\u1ed9c H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24062",
            name: "L\u01b0\u01a1ng Nh\u01b0 H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24063",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24064",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24065",
            name: "L\u00fd Anh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24066",
            name: "L\u00fd C\u00f4ng U\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24067",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24068",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24069",
            name: "L\u00fd Qu\u1ed1c B\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24070",
            name: "L\u00fd Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24071",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          { id: "24072", name: "L\u00fd T\u1eed C\u1ea5u", prefix: "Ph\u1ed1" },
          {
            id: "24073",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24074",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24075",
            name: "M\u1ea1c \u0110\u0129nh Ph\u00fac",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24076",
            name: "M\u1ea1c Hi\u1ec1n T\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24077",
            name: "M\u1ea1c Th\u1ecb B\u01b0\u1edfi",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24078",
            name: "Mai H\u1eafc \u0110\u1ebf",
            prefix: "Ph\u1ed1",
          },
          { id: "24079", name: "Minh Khai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24080",
            name: "M\u01b0\u01a1ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24081",
            name: "Ng\u00f4 B\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24082",
            name: "Ng\u00f4 Ho\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24083",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24084",
            name: "Ng\u00f4 S\u0129 Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24085",
            name: "Ng\u1ecdc Tuy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24086",
            name: "Ng\u1ecdc Uy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24087",
            name: "Nguy\u1ec5n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24088",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24089",
            name: "Nguy\u1ec5n Cao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24090",
            name: "Nguy\u1ec5n Ch\u1ebf Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24091",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24092",
            name: "Nguy\u1ec5n C\u00f4ng H\u00f2a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24093",
            name: "Nguy\u1ec5n C\u00f4ng Hoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24094",
            name: "Nguy\u1ec5n C\u00f4ng Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24095", name: "Nguy\u1ec5n C\u1eeb", prefix: "Ph\u1ed1" },
          {
            id: "24096",
            name: "Nguy\u1ec5n \u0110\u1ea1i N\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24097",
            name: "Nguy\u1ec5n \u0110\u00ecnh B\u1ec3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24098",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24099",
            name: "Nguy\u1ec5n \u0110\u1ed5ng Chi",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24100",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24101",
            name: "Nguy\u1ec5n \u0110\u1ee9c Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24102",
            name: "Nguy\u1ec5n H\u1ea3i Thanh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24103",
            name: "Nguy\u1ec5n H\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24104",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24105",
            name: "Nguy\u1ec5n Huy Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24106",
            name: "Nguy\u1ec5n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24107",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24108",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24109",
            name: "Nguy\u1ec5n M\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24110",
            name: "Nguy\u1ec5n Ng\u1ecdc Sanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24111",
            name: "Nguy\u1ec5n Qu\u00fd T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24112",
            name: "Nguy\u1ec5n S\u1ef9 C\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24113",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24115",
            name: "Nguy\u1ec5n Th\u1ecb Du\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24114",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24116",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24117",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng M\u1eabn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24118",
            name: "Nguy\u1ec5n Tr\u00e1c Lu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24119",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24120",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24121",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24122",
            name: "Nguy\u1ec5n Tuy\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24123",
            name: "Nguy\u1ec5n \u01af D\u0129",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24124",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24125",
            name: "Nguy\u1ec5n V\u0103n Ng\u1ecdc",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24126",
            name: "Nguy\u1ec5n V\u0103n T\u1ed1",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24127",
            name: "Nguy\u1ec7t Qu\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24128", name: "Nh\u00e0 Th\u1edd", prefix: "Ph\u1ed1" },
          { id: "24129", name: "Nh\u1eadt T\u00e2n", prefix: "Ph\u1ed1" },
          {
            id: "24130",
            name: "Nh\u1ecb Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24131",
            name: "Nh\u1eef \u0110\u00ecnh Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24132",
            name: "Ph\u1ea1m C\u00f4ng B\u00e2n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24133",
            name: "Ph\u1ea1m Duy \u01af\u1edfng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24134",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24135",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24136", name: "Ph\u1ea1m Lu\u1eadn", prefix: "Ph\u1ed1" },
          {
            id: "24137",
            name: "Ph\u1ea1m Ng\u1ecdc Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24138",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24139",
            name: "Ph\u1ea1m Qu\u00fd\u200e Th\u00edch",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24140",
            name: "Ph\u1ea1m S\u01b0 M\u1ea1nh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24141",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24142",
            name: "Ph\u1ea1m Xu\u00e2n Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24143",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24144",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24145",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24146",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24147",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24148",
            name: "Ph\u1ea7n L\u0103ng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24149",
            name: "Ph\u1ed1 V\u0103n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24150", name: "Phong Lan", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24151",
            name: "Ph\u00fa Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24152",
            name: "Ph\u00fac Duy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24153",
            name: "Quan Th\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24154",
            name: "Qu\u00e1n Th\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24155",
            name: "Quang Li\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24156", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24157",
            name: "Qu\u1ed1c l\u1ed9 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24158",
            name: "Qu\u1ed1c L\u1ed9 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24159",
            name: "Quy\u1ebft Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24160",
            name: "S\u01a1n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24161", name: "Tam Giang", prefix: "Ph\u1ed1" },
          {
            id: "24162",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24163", name: "T\u00e2n Kim", prefix: "Ph\u1ed1" },
          {
            id: "24164",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24165",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24166",
            name: "T\u00e2n Tr\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24167",
            name: "T\u00e2y H\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24168", name: "Th\u1ea1ch Lam", prefix: "Ph\u1ed1" },
          {
            id: "24169",
            name: "Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24170",
            name: "Th\u00e1i H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24171",
            name: "Thanh B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24172",
            name: "Th\u1ea1nh L\u1ed9c 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24173",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24174",
            name: "Th\u1ea1nh Xu\u00e2n 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24175", name: "Thi S\u00e1ch", prefix: "Ph\u1ed1" },
          {
            id: "24176",
            name: "Thi\u1ec7n Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24177", name: "Thi\u1ec7n Nh\u00e2n", prefix: "Ph\u1ed1" },
          {
            id: "24178",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24179",
            name: "Th\u01b0\u1ee3ng \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24180",
            name: "Th\u1ee7y Ti\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24181",
            name: "Ti\u1ec1n Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24182",
            name: "T\u1ec9nh l\u1ed9 179",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24183",
            name: "T\u1ec9nh L\u1ed9 390",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24184",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24185",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24186",
            name: "T\u00f4 Ng\u1ecdc V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24187",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24188",
            name: "T\u1ed1ng Duy T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24189",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24190",
            name: "Tr\u1ea7n B\u00ecnhTr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24191",
            name: "Tr\u1ea7n C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24192",
            name: "Tr\u1ea7n C\u00f4ng Hi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24193",
            name: "Tr\u1ea7n \u0110\u0103ng Nguy\u00ean",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24194",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24195",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24196",
            name: "Tr\u1ea7n \u00cdch Ph\u00e1t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24197",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24198",
            name: "Tr\u1ea7n Nguy\u00ean \u0110\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24199",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24201",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24200",
            name: "Tr\u1ea7n Quang \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24202",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24203",
            name: "Tr\u1ea7n Qu\u1ed1c L\u1eb7c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24204",
            name: "Tr\u1ea7n S\u00f9ng D\u0129nh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24205",
            name: "Tr\u1ea7n Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24206",
            name: "Tr\u1ea7n Th\u1ee7 \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24207",
            name: "Tr\u1ea7n V\u0103n C\u1eadn",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24208",
            name: "Tr\u1ea7n V\u0103n Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24209",
            name: "Tr\u00edch S\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24210",
            name: "Tri\u1ec7u Quang Ph\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24211",
            name: "Tr\u1ecbnh Th\u1ecb Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24212",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24213",
            name: "Tr\u01b0\u01a1ng \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24214",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24215",
            name: "Tr\u01b0\u01a1ng M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24216",
            name: "Tr\u01b0\u01a1ng V\u0103n \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24217",
            name: "T\u1ef1 \u0110o\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24218", name: "T\u1ee9 Minh", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24219", name: "T\u1ee9 Th\u00f4ng", prefix: "Ph\u1ed1" },
          {
            id: "24220",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24221", name: "Tuy An", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24222", name: "Tuy H\u00f2a", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24223",
            name: "Vi\u1ec7t Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24224", name: "V\u0169 B\u1eb1ng", prefix: "Ph\u1ed1" },
          {
            id: "24225",
            name: "V\u0169 C\u00f4ng \u0110\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24227", name: "V\u0169 D\u1ef1", prefix: "Ph\u1ed1" },
          {
            id: "24228",
            name: "V\u0169 Duy Ch\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24226",
            name: "V\u0169 \u0110\u00ecnh Li\u00ean",
            prefix: "Ph\u1ed1",
          },
          { id: "24229", name: "V\u0169 H\u1eefu", prefix: "Ph\u1ed1" },
          {
            id: "24230",
            name: "V\u0169 H\u1ef1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24231",
            name: "V\u0169 M\u1ea1nh H\u00f9ng",
            prefix: "Ph\u1ed1",
          },
          { id: "24232", name: "V\u0169 N\u1ea1p", prefix: "Ph\u1ed1" },
          { id: "24233", name: "V\u0169 Nh\u01b0 T\u00f4", prefix: "Ph\u1ed1" },
          {
            id: "24234",
            name: "V\u0169 Ph\u1ea1m H\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24235",
            name: "V\u0169 Qu\u1ef3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24236",
            name: "Vu\u0303 Tha\u0323nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24237", name: "V\u0169 T\u00f4ng Phan", prefix: "Ph\u1ed1" },
          {
            id: "24238",
            name: "V\u0169 Tr\u1ecdng Ph\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24239",
            name: "V\u0169 T\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24240",
            name: "V\u0169 V\u0103n D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24241",
            name: "V\u0169 V\u0103n M\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24242",
            name: "V\u0169 V\u0103n Uy\u00ean",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24243",
            name: "Xu\u00e2n \u0110\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24244", name: "Xu\u00e2n Th\u1ecb", prefix: "Ph\u1ed1" },
          { id: "24245", name: "\u1ef6 Lan", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24246",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2909",
            name: "KDC Th\u1ea1ch Kh\u00f4i",
            lat: "20.907989501953",
            lng: "106.30840301514",
          },
          {
            id: "2910",
            name: "TNR Star Riverside Nam S\u00e1ch",
            lat: "20.962882995605",
            lng: "106.38175964355",
          },
        ],
      },
      {
        id: "292",
        name: "Kim Th\u00e0nh",
        wards: [
          { id: "4532", name: "B\u00ecnh D\u00e2n", prefix: "X\u00e3" },
          { id: "4533", name: "C\u1ea9m La", prefix: "X\u00e3" },
          { id: "4534", name: "C\u1ed5 D\u0169ng", prefix: "X\u00e3" },
          { id: "4535", name: "C\u1ed9ng H\u00f2a", prefix: "X\u00e3" },
          {
            id: "4536",
            name: "\u0110\u1ea1i \u0110\u1ee9c",
            prefix: "X\u00e3",
          },
          { id: "4537", name: "\u0110\u1ed3ng Gia", prefix: "X\u00e3" },
          { id: "4538", name: "Kim Anh", prefix: "X\u00e3" },
          { id: "4539", name: "Kim \u0110\u00ednh", prefix: "X\u00e3" },
          { id: "4540", name: "Kim Kh\u00ea", prefix: "X\u00e3" },
          { id: "4541", name: "Kim L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "4542", name: "Kim T\u00e2n", prefix: "X\u00e3" },
          { id: "4543", name: "Kim Xuy\u00ean", prefix: "X\u00e3" },
          { id: "4544", name: "Lai Vu", prefix: "X\u00e3" },
          { id: "4545", name: "Li\u00ean H\u00f2a", prefix: "X\u00e3" },
          { id: "4546", name: "Ng\u0169 Ph\u00fac", prefix: "X\u00e3" },
          {
            id: "4547",
            name: "Ph\u00fa Th\u00e1i",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4548", name: "Ph\u00fac Th\u00e0nh A", prefix: "X\u00e3" },
          { id: "4549", name: "Tam K\u1ef3", prefix: "X\u00e3" },
          { id: "4550", name: "Th\u01b0\u1ee3ng V\u0169", prefix: "X\u00e3" },
          { id: "4551", name: "Tu\u1ea5n H\u01b0ng", prefix: "X\u00e3" },
          { id: "4552", name: "Vi\u1ec7t H\u01b0ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24247", name: "188", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24248",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24249",
            name: "H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24250",
            name: "Qu\u1ed1c L\u1ed9 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24251",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "293",
        name: "Kinh M\u00f4n",
        wards: [
          { id: "4553", name: "An Ph\u1ee5", prefix: "X\u00e3" },
          { id: "4554", name: "An Sinh", prefix: "X\u00e3" },
          { id: "4555", name: "B\u1ea1ch \u0110\u1eb1ng", prefix: "X\u00e3" },
          { id: "4556", name: "Duy T\u00e2n", prefix: "X\u00e3" },
          { id: "4557", name: "Hi\u1ebfn Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4558", name: "Hi\u1ec7p An", prefix: "X\u00e3" },
          { id: "4559", name: "Hi\u1ec7p H\u00f2a", prefix: "X\u00e3" },
          { id: "4560", name: "Hi\u1ec7p S\u01a1n", prefix: "X\u00e3" },
          { id: "4561", name: "Ho\u00e0nh S\u01a1n", prefix: "X\u00e3" },
          { id: "4562", name: "H\u01b0ng \u0110\u1ea1o", prefix: "X\u00e3" },
          { id: "4563", name: "Kinh M\u00f4n", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "4564", name: "L\u1ea1c Long", prefix: "X\u00e3" },
          { id: "4565", name: "L\u00ea Ninh", prefix: "X\u00e3" },
          { id: "4566", name: "Long Xuy\u00ean", prefix: "X\u00e3" },
          { id: "4567", name: "Minh H\u00f2a", prefix: "X\u00e3" },
          { id: "4568", name: "Minh T\u00e2n", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "4569", name: "Ph\u1ea1m M\u1ec7nh", prefix: "X\u00e3" },
          {
            id: "4570",
            name: "Ph\u00fa Th\u1ee9",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4571", name: "Ph\u00fac Th\u00e0nh B", prefix: "X\u00e3" },
          { id: "4572", name: "Quang Trung", prefix: "X\u00e3" },
          { id: "4573", name: "T\u00e2n D\u00e2n", prefix: "X\u00e3" },
          { id: "4574", name: "Th\u00e1i H\u00f2a", prefix: "X\u00e3" },
          { id: "4575", name: "Th\u00e1i S\u01a1n", prefix: "X\u00e3" },
          { id: "4576", name: "Th\u00e1i Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "4577", name: "Th\u0103ng Long", prefix: "X\u00e3" },
          { id: "4578", name: "Th\u1ea5t H\u00f9ng", prefix: "X\u00e3" },
          { id: "4579", name: "Th\u01b0\u1ee3ng Qu\u1eadn", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24252", name: "C\u1ea7u Ba", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24253",
            name: "Hu\u1ebf Tr\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24254",
            name: "Ph\u00f9ng Kh\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24255",
            name: "T\u1ec9nh L\u1ed9 388",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "294",
        name: "Nam S\u00e1ch",
        wards: [
          { id: "4580", name: "An B\u00ecnh", prefix: "X\u00e3" },
          { id: "4581", name: "An L\u00e2m", prefix: "X\u00e3" },
          { id: "4582", name: "An S\u01a1n", prefix: "X\u00e3" },
          { id: "4583", name: "C\u1ed9ng H\u00f2a", prefix: "X\u00e3" },
          { id: "4584", name: "\u0110\u1ed3ng L\u1ea1c", prefix: "X\u00e3" },
          { id: "4585", name: "Hi\u1ec7p C\u00e1t", prefix: "X\u00e3" },
          { id: "4586", name: "H\u1ed3ng Phong", prefix: "X\u00e3" },
          { id: "4587", name: "H\u1ee3p Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "4588", name: "Minh T\u00e2n", prefix: "X\u00e3" },
          { id: "4589", name: "Nam Ch\u00ednh", prefix: "X\u00e3" },
          { id: "4590", name: "Nam H\u1ed3ng", prefix: "X\u00e3" },
          { id: "4591", name: "Nam H\u01b0ng", prefix: "X\u00e3" },
          { id: "4592", name: "Nam S\u00e1ch", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "4593", name: "Nam T\u00e2n", prefix: "X\u00e3" },
          { id: "4594", name: "Nam Trung", prefix: "X\u00e3" },
          { id: "4595", name: "Ph\u00fa \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "4596", name: "Qu\u1ed1c Tu\u1ea5n", prefix: "X\u00e3" },
          { id: "4597", name: "Th\u00e1i T\u00e2n", prefix: "X\u00e3" },
          { id: "4598", name: "Thanh Quang", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24256", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24257",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24258",
            name: "M\u1ea1c Th\u1ecb B\u01b0\u1edfi",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24259",
            name: "Nguy\u1ec5n \u0110\u0103ng L\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24260",
            name: "Qu\u1ed1c L\u1ed9 183",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24261",
            name: "Qu\u00f4\u0301c L\u00f4\u0323 5B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24262",
            name: "Thanh L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24263",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "295",
        name: "Ninh Giang",
        wards: [
          { id: "4599", name: "An \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "4600", name: "\u0110\u1ed3ng T\u00e2m", prefix: "X\u00e3" },
          { id: "4601", name: "\u0110\u00f4ng Xuy\u00ean", prefix: "X\u00e3" },
          { id: "4602", name: "Hi\u1ec7p L\u1ef1c", prefix: "X\u00e3" },
          { id: "4603", name: "Ho\u00e0ng Hanh", prefix: "X\u00e3" },
          { id: "4604", name: "H\u1ed3ng D\u1ee5", prefix: "X\u00e3" },
          { id: "4605", name: "H\u1ed3ng \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "4606", name: "H\u1ed3ng Phong", prefix: "X\u00e3" },
          { id: "4607", name: "H\u1ed3ng Ph\u00fac", prefix: "X\u00e3" },
          { id: "4608", name: "H\u1ed3ng Th\u00e1i", prefix: "X\u00e3" },
          { id: "4609", name: "H\u01b0ng Long", prefix: "X\u00e3" },
          { id: "4610", name: "H\u01b0ng Th\u00e1i", prefix: "X\u00e3" },
          { id: "4611", name: "Ki\u1ebfn Qu\u1ed1c", prefix: "X\u00e3" },
          { id: "4612", name: "Ngh\u0129a An", prefix: "X\u00e3" },
          { id: "4613", name: "Ninh Giang", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "4614", name: "Ninh H\u1ea3i", prefix: "X\u00e3" },
          { id: "4615", name: "Ninh Ho\u00e0", prefix: "X\u00e3" },
          { id: "4616", name: "Ninh Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4617", name: "Quang H\u01b0ng", prefix: "X\u00e3" },
          { id: "4618", name: "Quy\u1ebft Th\u1eafng", prefix: "X\u00e3" },
          { id: "4619", name: "T\u00e2n H\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "4620", name: "T\u00e2n Phong", prefix: "X\u00e3" },
          { id: "4621", name: "T\u00e2n Quang", prefix: "X\u00e3" },
          { id: "4622", name: "\u1ee8ng Ho\u00e8", prefix: "X\u00e3" },
          { id: "4623", name: "V\u0103n Giang", prefix: "X\u00e3" },
          { id: "4624", name: "V\u0103n H\u1ed9i", prefix: "X\u00e3" },
          { id: "4625", name: "V\u1ea1n Ph\u00fac", prefix: "X\u00e3" },
          { id: "4626", name: "V\u0129nh Ho\u00e0", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24264", name: "396", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24265",
            name: "Kh\u00fac Th\u1eeba V\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24266",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24267",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24268",
            name: "Qu\u1ed1c l\u1ed9 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24269",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24270", name: "V\u1ea1n Ph\u00fac", prefix: "Ph\u1ed1" },
        ],
        projects: [],
      },
      {
        id: "296",
        name: "Thanh H\u00e0",
        wards: [
          { id: "4627", name: "An L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "4628", name: "C\u1ea9m Ch\u1ebf", prefix: "X\u00e3" },
          { id: "4629", name: "H\u1ed3ng L\u1ea1c", prefix: "X\u00e3" },
          { id: "4630", name: "H\u1ee3p \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "4631", name: "Li\u00ean M\u1ea1c", prefix: "X\u00e3" },
          {
            id: "4632",
            name: "Ph\u01b0\u1ee3ng Ho\u00e0ng",
            prefix: "X\u00e3",
          },
          { id: "4633", name: "Quy\u1ebft Th\u1eafng", prefix: "X\u00e3" },
          { id: "4634", name: "T\u00e2n An", prefix: "X\u00e3" },
          { id: "4635", name: "T\u00e2n Vi\u1ec7t", prefix: "X\u00e3" },
          { id: "4636", name: "Thanh An", prefix: "X\u00e3" },
          { id: "4637", name: "Thanh B\u00ednh", prefix: "X\u00e3" },
          { id: "4638", name: "Thanh C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "4639", name: "Thanh H\u00e0", prefix: "X\u00e3" },
          { id: "4640", name: "Thanh H\u1ea3i", prefix: "X\u00e3" },
          { id: "4641", name: "Thanh H\u1ed3ng", prefix: "X\u00e3" },
          { id: "4642", name: "Thanh Kh\u00ea", prefix: "X\u00e3" },
          { id: "4643", name: "Thanh Lang", prefix: "X\u00e3" },
          { id: "4644", name: "Thanh S\u01a1n", prefix: "X\u00e3" },
          { id: "4645", name: "Thanh Thu\u1ef7", prefix: "X\u00e3" },
          { id: "4646", name: "Thanh X\u00e1", prefix: "X\u00e3" },
          { id: "4647", name: "Thanh Xu\u00e2n", prefix: "X\u00e3" },
          { id: "4648", name: "Ti\u1ec1n Ti\u1ebfn", prefix: "X\u00e3" },
          {
            id: "4649",
            name: "Tr\u01b0\u1eddng Th\u00e0nh",
            prefix: "X\u00e3",
          },
          { id: "4650", name: "Vi\u1ec7t H\u1ed3ng", prefix: "X\u00e3" },
          { id: "4651", name: "V\u0129nh L\u1eadp", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24271", name: "390B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24272",
            name: "An L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24273",
            name: "Nguy\u1ec5n H\u1ea3i Thanh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24274",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "297",
        name: "Thanh Mi\u1ec7n",
        wards: [
          { id: "4652", name: "Cao Th\u1eafng", prefix: "X\u00e3" },
          { id: "4653", name: "Chi L\u0103ng B\u1eafc", prefix: "X\u00e3" },
          { id: "4654", name: "Chi L\u0103ng Nam", prefix: "X\u00e3" },
          { id: "4655", name: "Di\u00ean H\u1ed3ng", prefix: "X\u00e3" },
          { id: "4656", name: "\u0110o\u00e0n K\u1ebft", prefix: "X\u00e3" },
          { id: "4657", name: "\u0110o\u00e0n T\u00f9ng", prefix: "X\u00e3" },
          { id: "4658", name: "H\u1ed3ng Quang", prefix: "X\u00e3" },
          { id: "4659", name: "H\u00f9ng S\u01a1n", prefix: "X\u00e3" },
          { id: "4660", name: "Lam S\u01a1n", prefix: "X\u00e3" },
          { id: "4661", name: "L\u00ea H\u1ed3ng", prefix: "X\u00e3" },
          { id: "4662", name: "Ng\u00f4 Quy\u1ec1n", prefix: "X\u00e3" },
          { id: "4663", name: "Ng\u0169 H\u00f9ng", prefix: "X\u00e3" },
          { id: "4664", name: "Ph\u1ea1m Kha", prefix: "X\u00e3" },
          { id: "4665", name: "T\u00e2n Tr\u00e0o", prefix: "X\u00e3" },
          { id: "4666", name: "Thanh Giang", prefix: "X\u00e3" },
          { id: "4667", name: "Thanh Mi\u1ec7n", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "4668", name: "Thanh T\u00f9ng", prefix: "X\u00e3" },
          { id: "4669", name: "Ti\u1ec1n Phong", prefix: "X\u00e3" },
          { id: "4670", name: "T\u1ee9 C\u01b0\u1eddng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24275", name: "18/8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24276",
            name: "Qu\u1ed1c L\u1ed9 39",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24277",
            name: "T\u1ec9nh l\u1ed9 39",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "298",
        name: "T\u1ee9 K\u1ef3",
        wards: [
          { id: "4671", name: "An Thanh", prefix: "X\u00e3" },
          { id: "4672", name: "B\u00ecnh L\u00e3ng", prefix: "X\u00e3" },
          { id: "4673", name: "C\u1ed9ng L\u1ea1c", prefix: "X\u00e3" },
          { id: "4676", name: "D\u00e2n Ch\u1ee7", prefix: "X\u00e3" },
          {
            id: "4674",
            name: "\u0110\u1ea1i \u0110\u1ed3ng",
            prefix: "X\u00e3",
          },
          { id: "4675", name: "\u0110\u1ea1i H\u1ee3p", prefix: "X\u00e3" },
          { id: "4677", name: "\u0110\u00f4ng K\u1ef3", prefix: "X\u00e3" },
          { id: "4678", name: "H\u00e0 K\u1ef3", prefix: "X\u00e3" },
          { id: "4679", name: "H\u00e0 Thanh", prefix: "X\u00e3" },
          { id: "4680", name: "H\u01b0ng \u0110\u1ea1o", prefix: "X\u00e3" },
          { id: "4681", name: "K\u1ef3 S\u01a1n", prefix: "X\u00e3" },
          { id: "4682", name: "Minh \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "4683", name: "Ng\u1ecdc K\u1ef3", prefix: "X\u00e3" },
          { id: "4684", name: "Ng\u1ecdc S\u01a1n", prefix: "X\u00e3" },
          { id: "4685", name: "Nguy\u00ean Gi\u00e1p", prefix: "X\u00e3" },
          { id: "4686", name: "Ph\u01b0\u1ee3ng K\u1ef3", prefix: "X\u00e3" },
          { id: "4687", name: "Quang Kh\u1ea3i", prefix: "X\u00e3" },
          { id: "4688", name: "Qu\u1ea3ng Nghi\u1ec7p", prefix: "X\u00e3" },
          { id: "4689", name: "Quang Ph\u1ee5c", prefix: "X\u00e3" },
          { id: "4690", name: "Quang Trung", prefix: "X\u00e3" },
          { id: "4691", name: "T\u00e1i S\u01a1n", prefix: "X\u00e3" },
          { id: "4692", name: "T\u00e2n K\u1ef3", prefix: "X\u00e3" },
          { id: "4693", name: "T\u00e2y K\u1ef3", prefix: "X\u00e3" },
          { id: "4694", name: "Ti\u00ean \u0110\u1ed9ng", prefix: "X\u00e3" },
          { id: "4695", name: "T\u1ee9 K\u1ef3", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "4696", name: "T\u1ee9 Xuy\u00ean", prefix: "X\u00e3" },
          { id: "4697", name: "V\u0103n T\u1ed1", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "24278",
            name: "Qu\u1ed1c l\u1ed9 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24279",
            name: "Qu\u1ed1c l\u1ed9 391",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "22",
    code: "GL",
    name: "Gia Lai",
    districts: [
      {
        id: "299",
        name: "An Kh\u00ea",
        wards: [
          { id: "4698", name: "An B\u00ecnh", prefix: "Ph\u01b0\u1eddng" },
          { id: "4699", name: "An Ph\u00fa", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "4700",
            name: "An Ph\u01b0\u1edbc",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4701", name: "An T\u00e2n", prefix: "Ph\u01b0\u1eddng" },
          { id: "4702", name: "C\u1eedu An", prefix: "X\u00e3" },
          { id: "4703", name: "Ng\u00f4 M\u00e2y", prefix: "Ph\u01b0\u1eddng" },
          { id: "4704", name: "Song An", prefix: "X\u00e3" },
          { id: "4705", name: "T\u00e2y S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "4706", name: "Th\u00e0nh An", prefix: "X\u00e3" },
          { id: "4707", name: "T\u00fa An", prefix: "X\u00e3" },
          { id: "4708", name: "Xu\u00e2n An", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24280", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24281",
            name: "\u0110\u1ed7 Tr\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24282",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24283",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24284",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24285",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24286",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24287",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24288",
            name: "Nguy\u1ec5n Thi\u1ebfp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24289",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24290",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24291",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24292", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24293",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24294",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "300",
        name: "AYun Pa",
        wards: [
          { id: "4709", name: "Cheo Reo", prefix: "Ph\u01b0\u1eddng" },
          { id: "4710", name: "Ch\u01b0 B\u0103h", prefix: "X\u00e3" },
          {
            id: "4711",
            name: "\u0110o\u00e0n K\u1ebft",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "4712",
            name: "H\u00f2a B\u00ecnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4713", name: "Ia Rbol", prefix: "X\u00e3" },
          { id: "4714", name: "Ia RT\u00f4", prefix: "X\u00e3" },
          { id: "4715", name: "Ia Sao", prefix: "X\u00e3" },
          { id: "4716", name: "S\u00f4ng B\u1edd", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          {
            id: "24295",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24296",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24297", name: "kpa klong", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24298",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24299",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          { id: "24300", name: "Nay Der", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24301",
            name: "Ng\u00f4 M\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24302",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24303",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24304",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24305",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24306",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24307",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24308",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24309",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24310",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24311",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24312",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24313",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24314",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24315",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24316",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24317",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24318",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "301",
        name: "Ch\u01b0 P\u0103h",
        wards: [
          { id: "4717", name: "Ch\u01b0 \u0110\u0103ng Ya", prefix: "X\u00e3" },
          { id: "4718", name: "Ch\u01b0 J\u00f4r", prefix: "X\u00e3" },
          { id: "4719", name: "\u0110\u0103k T\u01a1 Ver", prefix: "X\u00e3" },
          { id: "4720", name: "H\u00e0 T\u00e2y", prefix: "X\u00e3" },
          { id: "4721", name: "H\u00f2a Ph\u00fa", prefix: "X\u00e3" },
          { id: "4722", name: "Ia Ka", prefix: "X\u00e3" },
          { id: "4723", name: "Ia Kh\u01b0\u01a1l", prefix: "X\u00e3" },
          { id: "4724", name: "Ia Kreng", prefix: "X\u00e3" },
          { id: "4725", name: "Ia Ly", prefix: "X\u00e3" },
          { id: "4726", name: "Ia M\u01a1 N\u00f4ng", prefix: "X\u00e3" },
          { id: "4727", name: "Ia Nhin", prefix: "X\u00e3" },
          { id: "4728", name: "Ia Ph\u00ed", prefix: "X\u00e3" },
          { id: "4729", name: "Ngh\u0129a H\u00f2a", prefix: "X\u00e3" },
          { id: "4730", name: "Ngh\u0129a H\u01b0ng", prefix: "X\u00e3" },
          {
            id: "4731",
            name: "Ph\u00fa H\u00f2a",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          {
            id: "24319",
            name: "H\u1ed3 Ch\u00ed Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24320",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24321",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "302",
        name: "Ch\u01b0 P\u01b0h",
        wards: [
          { id: "4732", name: "Ch\u01b0 Don", prefix: "X\u00e3" },
          { id: "4733", name: "Ia BL\u1ee9", prefix: "X\u00e3" },
          { id: "4734", name: "Ia Dreng", prefix: "X\u00e3" },
          { id: "4735", name: "Ia Hla", prefix: "X\u00e3" },
          { id: "4736", name: "Ia Hr\u00fa", prefix: "X\u00e3" },
          { id: "4737", name: "Ia Le", prefix: "X\u00e3" },
          { id: "4738", name: "Ia Phang", prefix: "X\u00e3" },
          { id: "4739", name: "Ia Rong", prefix: "X\u00e3" },
          {
            id: "4740",
            name: "Nh\u01a1n H\u00f2a",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          {
            id: "24322",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24323",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24324",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "303",
        name: "Ch\u01b0 S\u00ea",
        wards: [
          { id: "4741", name: "Al B\u00e1", prefix: "X\u00e3" },
          { id: "4742", name: "AYun", prefix: "X\u00e3" },
          { id: "4743", name: "Bar M\u0103ih", prefix: "X\u00e3" },
          { id: "4744", name: "B\u1edd Ngoong", prefix: "X\u00e3" },
          { id: "4745", name: "Ch\u01b0 P\u01a1ng", prefix: "X\u00e3" },
          {
            id: "4746",
            name: "Ch\u01b0 S\u00ea",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4747", name: "Dun", prefix: "X\u00e3" },
          { id: "4748", name: "H B\u00f4ng", prefix: "X\u00e3" },
          { id: "4749", name: "Ia Blang", prefix: "X\u00e3" },
          { id: "4750", name: "Ia Dreng", prefix: "X\u00e3" },
          { id: "4751", name: "Ia Glai", prefix: "X\u00e3" },
          { id: "4752", name: "Ia Hl\u1ed1p", prefix: "X\u00e3" },
          { id: "4753", name: "Ia Ko", prefix: "X\u00e3" },
          { id: "4754", name: "Ia Pal", prefix: "X\u00e3" },
          { id: "4755", name: "Ia Ti\u00eam", prefix: "X\u00e3" },
          { id: "4756", name: "K\u00f4ng Htok", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24325", name: "17/3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24326",
            name: "C\u00e1ch M\u1ea1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24327",
            name: "Chu M\u1ea1nh Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24328",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24329",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24330",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24331", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24332",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24333",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24334",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24335",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24336",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24337",
            name: "Phan Th\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24338", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24339",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24340",
            name: "Qu\u1ed1c l\u1ed9 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24341",
            name: "T\u00f4 V\u0129nh Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24342",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24343",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24344",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "304",
        name: "Ch\u01b0PR\u00f4ng",
        wards: [
          { id: "4757", name: "B\u00e0u C\u1ea1n", prefix: "X\u00e3" },
          { id: "4758", name: "B\u00ecnh Gi\u00e1o", prefix: "X\u00e3" },
          {
            id: "4759",
            name: "Ch\u01b0 Pr\u00f4ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4760", name: "Ia Bang", prefix: "X\u00e3" },
          { id: "4761", name: "Ia B\u0103ng", prefix: "X\u00e3" },
          { id: "4762", name: "Ia Bo\u00f2ng", prefix: "X\u00e3" },
          { id: "4763", name: "Ia Drang", prefix: "X\u00e3" },
          { id: "4764", name: "Ia Ga", prefix: "X\u00e3" },
          { id: "4765", name: "Ia Kly", prefix: "X\u00e3" },
          { id: "4766", name: "Ia L\u00e2u", prefix: "X\u00e3" },
          { id: "4767", name: "Ia Me", prefix: "X\u00e3" },
          { id: "4768", name: "Ia M\u01a1", prefix: "X\u00e3" },
          { id: "4769", name: "Ia O", prefix: "X\u00e3" },
          { id: "4770", name: "Ia Ph\u00ecn", prefix: "X\u00e3" },
          { id: "4771", name: "Ia Pia", prefix: "X\u00e3" },
          { id: "4772", name: "Ia Pi\u01a1r", prefix: "X\u00e3" },
          { id: "4773", name: "Ia P\u00fach", prefix: "X\u00e3" },
          { id: "4774", name: "Ia T\u00f4r", prefix: "X\u00e3" },
          { id: "4775", name: "Ia V\u00ea", prefix: "X\u00e3" },
          { id: "4776", name: "Th\u0103ng H\u01b0ng", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "24345",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24346",
            name: "H\u01b0\u01a1ng l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24347",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24348",
            name: "Qu\u1ed1c l\u1ed9 14C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24349",
            name: "Qu\u1ed1c L\u1ed9 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24350",
            name: "T\u1ec9nh L\u1ed9 665",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "305",
        name: "\u0110\u0103k \u0110oa",
        wards: [
          { id: "4777", name: "A D\u01a1k", prefix: "X\u00e3" },
          {
            id: "4778",
            name: "\u0110ak \u0110oa",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4779", name: "\u0110\u0103k Krong", prefix: "X\u00e3" },
          { id: "4780", name: "\u0110\u0103k S\u01a1 Mei", prefix: "X\u00e3" },
          { id: "4781", name: "GLar", prefix: "X\u00e3" },
          { id: "4782", name: "H' Neng", prefix: "X\u00e3" },
          { id: "4783", name: "H\u00e0 B\u1ea7u", prefix: "X\u00e3" },
          { id: "4784", name: "H\u00e0 \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "4785", name: "H\u1ea3i Yang", prefix: "X\u00e3" },
          { id: "4786", name: "HNol", prefix: "X\u00e3" },
          { id: "4787", name: "Ia B\u0103ng", prefix: "X\u00e3" },
          { id: "4788", name: "Ia P\u1ebft", prefix: "X\u00e3" },
          { id: "4789", name: "K' Dang", prefix: "X\u00e3" },
          { id: "4790", name: "Kon Gang", prefix: "X\u00e3" },
          { id: "4791", name: "Nam Yang", prefix: "X\u00e3" },
          { id: "4792", name: "T\u00e2n B\u00ecnh", prefix: "X\u00e3" },
          { id: "4793", name: "Trang", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24351", name: "641", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24352",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24353",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24354",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24355",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24356",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24357",
            name: "S\u01b0 V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "306",
        name: "\u0110\u0103k P\u01a1",
        wards: [
          { id: "4794", name: "An Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4795", name: "C\u01b0 An", prefix: "X\u00e3" },
          { id: "4796", name: "\u0110ak P\u01a1", prefix: "X\u00e3" },
          { id: "4797", name: "H\u00e0 Tam", prefix: "X\u00e3" },
          { id: "4798", name: "Ph\u00fa An", prefix: "X\u00e3" },
          { id: "4799", name: "T\u00e2n An", prefix: "X\u00e3" },
          { id: "4800", name: "Ya H\u1ed9i", prefix: "X\u00e3" },
          { id: "4801", name: "Yang B\u1eafc", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "307",
        name: "\u0110\u1ee9c C\u01a1",
        wards: [
          { id: "4802", name: "Ch\u01b0 Ty", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "4803", name: "Ia  Din", prefix: "X\u00e3" },
          { id: "4804", name: "Ia D\u01a1k", prefix: "X\u00e3" },
          { id: "4805", name: "Ia Dom", prefix: "X\u00e3" },
          { id: "4806", name: "Ia Kla", prefix: "X\u00e3" },
          { id: "4807", name: "Ia Kr\u00eal", prefix: "X\u00e3" },
          { id: "4808", name: "Ia Kri\u00eang", prefix: "X\u00e3" },
          { id: "4809", name: "Ia Lang", prefix: "X\u00e3" },
          { id: "4810", name: "Ia Nan", prefix: "X\u00e3" },
          { id: "4811", name: "Ia Pn\u00f4n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24358", name: "Siubleh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24359",
            name: "T\u0103ng B\u1ea1t H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24360", name: "Tuy\u1ebfn 3", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
      {
        id: "308",
        name: "Ia Grai",
        wards: [
          { id: "4812", name: "Ia B\u00e1", prefix: "X\u00e3" },
          { id: "4813", name: "Ia Chia", prefix: "X\u00e3" },
          { id: "4814", name: "Ia D\u00ear", prefix: "X\u00e3" },
          { id: "4815", name: "Ia Gr\u0103ng", prefix: "X\u00e3" },
          { id: "4816", name: "Ia Hrung", prefix: "X\u00e3" },
          { id: "4817", name: "Ia Kha", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "4818", name: "Ia Khai", prefix: "X\u00e3" },
          { id: "4819", name: "Ia Krai", prefix: "X\u00e3" },
          { id: "4820", name: "Ia O", prefix: "X\u00e3" },
          { id: "4821", name: "Ia P\u1ebfch", prefix: "X\u00e3" },
          { id: "4822", name: "Ia Sao", prefix: "X\u00e3" },
          { id: "4823", name: "Ia T\u00f4", prefix: "X\u00e3" },
          { id: "4824", name: "Ia Yok", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "24361",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24362",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24363",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24364",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24365",
            name: "T\u1ec9nh l\u1ed9 664",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24366",
            name: "Tr\u01b0\u1eddng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "309",
        name: "Ia Pa",
        wards: [
          { id: "4825", name: "Ch\u01b0 M\u1ed1", prefix: "X\u00e3" },
          { id: "4826", name: "Ch\u01b0 R\u0103ng", prefix: "X\u00e3" },
          { id: "4827", name: "Ia Bro\u0103i", prefix: "X\u00e3" },
          { id: "4828", name: "Ia Kd\u0103m", prefix: "X\u00e3" },
          { id: "4829", name: "Ia Mr\u01a1n", prefix: "X\u00e3" },
          { id: "4830", name: "Ia Pa", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "4831", name: "Ia Tr\u00f4k", prefix: "X\u00e3" },
          { id: "4832", name: "Ia Tul", prefix: "X\u00e3" },
          { id: "4833", name: "Kim T\u00e2n", prefix: "X\u00e3" },
          { id: "4834", name: "P\u1edd t\u00f3", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "310",
        name: "KBang",
        wards: [
          { id: "4835", name: "\u0110\u1eafk HL\u01a1", prefix: "X\u00e3" },
          { id: "4836", name: "\u0110\u1eafk Rong", prefix: "X\u00e3" },
          { id: "4837", name: "\u0110\u1eafk Smar", prefix: "X\u00e3" },
          { id: "4838", name: "\u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "4839", name: "KBang", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "4840", name: "Kon PNe", prefix: "X\u00e3" },
          {
            id: "4841",
            name: "K\u00f4ng L\u01a1ng Kh\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "4842", name: "K\u00f4ng PLa", prefix: "X\u00e3" },
          { id: "4843", name: "KRong", prefix: "X\u00e3" },
          { id: "4844", name: "L\u01a1 Ku", prefix: "X\u00e3" },
          { id: "4845", name: "Ngh\u0129a An", prefix: "X\u00e3" },
          { id: "4846", name: "S\u01a1 Pai", prefix: "X\u00e3" },
          { id: "4847", name: "S\u01a1n Lang", prefix: "X\u00e3" },
          { id: "4848", name: "T\u01a1 Tung", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24367", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "24368",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24369", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
      {
        id: "311",
        name: "K\u00f4ng Chro",
        wards: [
          { id: "4849", name: "An Trung", prefix: "X\u00e3" },
          { id: "4850", name: "Ch\u01a1 Long", prefix: "X\u00e3" },
          { id: "4851", name: "Ch\u01b0 Kr\u00eay", prefix: "X\u00e3" },
          { id: "4852", name: "\u0110\u0103k K\u01a1 Ning", prefix: "X\u00e3" },
          { id: "4853", name: "\u0110\u1eafk Pling", prefix: "X\u00e3" },
          { id: "4854", name: "\u0110\u0103k P\u01a1 Pho", prefix: "X\u00e3" },
          { id: "4855", name: "\u0110\u1eafk S\u00f4ng", prefix: "X\u00e3" },
          { id: "4856", name: "\u0110\u1eafk T\u01a1 Pang", prefix: "X\u00e3" },
          { id: "4857", name: "K\u00f4ng Chro", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "4858", name: "K\u00f4ng Yang", prefix: "X\u00e3" },
          { id: "4859", name: "Sr\u00f3", prefix: "X\u00e3" },
          { id: "4860", name: "Ya Ma", prefix: "X\u00e3" },
          { id: "4861", name: "Yang Nam", prefix: "X\u00e3" },
          { id: "4862", name: "Yang Trung", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "24370",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "312",
        name: "Kr\u00f4ng Pa",
        wards: [
          { id: "4863", name: "Ch\u01b0 Dr\u0103ng", prefix: "X\u00e3" },
          { id: "4864", name: "Ch\u01b0 Gu", prefix: "X\u00e3" },
          { id: "4865", name: "Ch\u01b0 Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "4866", name: "Ch\u01b0 Rc\u0103m", prefix: "X\u00e3" },
          { id: "4867", name: "\u0110\u1ea5t B\u1eb1ng", prefix: "X\u00e3" },
          { id: "4868", name: "Ia Hdreh", prefix: "X\u00e3" },
          { id: "4869", name: "Ia Mlah", prefix: "X\u00e3" },
          { id: "4870", name: "Ia Rmok", prefix: "X\u00e3" },
          { id: "4871", name: "Ia Rsai", prefix: "X\u00e3" },
          { id: "4872", name: "Ia Rs\u01b0\u01a1m", prefix: "X\u00e3" },
          { id: "4873", name: "Kr\u00f4ng N\u0103ng", prefix: "X\u00e3" },
          { id: "4874", name: "Ph\u00fa C\u1ea7n", prefix: "X\u00e3" },
          {
            id: "4875",
            name: "Ph\u00fa T\u00fac",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4876", name: "Uar", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "24371",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "313",
        name: "Mang Yang",
        wards: [
          { id: "4877", name: "Ayun", prefix: "X\u00e3" },
          { id: "4878", name: "\u00d0\u0103k Drj\u0103ng", prefix: "X\u00e3" },
          { id: "4879", name: "\u00d0ak J\u01a1 Ta", prefix: "X\u00e3" },
          { id: "4880", name: "\u00d0ak Ta Ley", prefix: "X\u00e3" },
          { id: "4881", name: "\u00d0\u0103k Tr\u00f4i", prefix: "X\u00e3" },
          { id: "4882", name: "\u00d0\u0103k Y\u0103", prefix: "X\u00e3" },
          { id: "4883", name: "\u00d0\u00ea Ar", prefix: "X\u00e3" },
          { id: "4884", name: "Hra", prefix: "X\u00e3" },
          { id: "4885", name: "Kon Chi\u00eang", prefix: "X\u00e3" },
          { id: "4886", name: "Kon D\u01a1ng", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "4887", name: "Kon Th\u1ee5p", prefix: "X\u00e3" },
          { id: "4888", name: "Lo Pang", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "24372",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24373",
            name: "Qu\u1ed1c L\u1ed9 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24374",
            name: "Qu\u1ed1c L\u1ed9 19B",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "314",
        name: "Ph\u00fa Thi\u1ec7n",
        wards: [
          { id: "4889", name: "Ayun H\u1ea1", prefix: "X\u00e3" },
          { id: "4890", name: "Chr\u00f4h P\u01a1nan", prefix: "X\u00e3" },
          { id: "4891", name: "Ch\u01b0 A Thai", prefix: "X\u00e3" },
          { id: "4892", name: "Ia Ake", prefix: "X\u00e3" },
          { id: "4893", name: "Ia Hiao", prefix: "X\u00e3" },
          { id: "4894", name: "Ia Peng", prefix: "X\u00e3" },
          { id: "4895", name: "Ia Piar", prefix: "X\u00e3" },
          { id: "4896", name: "Ia Sol", prefix: "X\u00e3" },
          { id: "4897", name: "Ia Yeng", prefix: "X\u00e3" },
          {
            id: "4898",
            name: "Ph\u00fa Thi\u1ec7n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "315",
        name: "Plei Ku",
        wards: [
          { id: "4899", name: "An Ph\u00fa", prefix: "X\u00e3" },
          { id: "4900", name: "Bi\u1ec3n H\u1ed3", prefix: "X\u00e3" },
          { id: "4901", name: "Chi L\u0103ng", prefix: "Ph\u01b0\u1eddng" },
          { id: "4902", name: "Ch\u01b0 \u00c1", prefix: "X\u00e3" },
          { id: "4903", name: "Ch\u01b0 HDr\u00f4ng", prefix: "X\u00e3" },
          {
            id: "4904",
            name: "Di\u00ean H\u1ed3ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4905", name: "Di\u00ean Ph\u00fa", prefix: "X\u00e3" },
          {
            id: "4906",
            name: "\u0110\u1ed1ng \u0110a",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4907", name: "G\u00e0o", prefix: "X\u00e3" },
          { id: "4908", name: "Hoa L\u01b0", prefix: "Ph\u01b0\u1eddng" },
          { id: "4909", name: "H\u1ed9i Ph\u00fa", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "4910",
            name: "H\u1ed9i Th\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4911", name: "Ia K\u00eanh", prefix: "X\u00e3" },
          { id: "4912", name: "Ia Kring\u00ad", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "4913",
            name: "Ph\u00f9 \u0110\u1ed5ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4914", name: "T\u00e2n S\u01a1n", prefix: "X\u00e3" },
          { id: "4915", name: "T\u00e2y S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "4916",
            name: "Th\u1eafng L\u1ee3i",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "4917",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4918", name: "Tr\u00e0 B\u00e1", prefix: "Ph\u01b0\u1eddng" },
          { id: "4919", name: "Tr\u00e0 \u0110a", prefix: "X\u00e3" },
          {
            id: "4920",
            name: "Y\u00ean \u0110\u1ed7",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4921", name: "Y\u00ean Th\u1ebf", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "24375", name: "17/3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24376", name: "A Sanh", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24377", name: "AmaQuang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24378",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24379",
            name: "Anh H\u00f9ng \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24380",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24381",
            name: "\u00c2u D\u01b0\u01a1ng L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24382",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          { id: "24383", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "24384",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24385",
            name: "B\u00f9i D\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24386",
            name: "B\u00f9i Vi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24387",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24388",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24389",
            name: "Cao B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24390",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24391",
            name: "Chi L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24392",
            name: "Chu M\u1ea1nh Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24393",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24394",
            name: "C\u00f4 B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24395",
            name: "C\u00f4 Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24396",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24397",
            name: "D\u00e3 T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24410",
            name: "D\u01b0\u01a1ng Minh Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24411", name: "Duy T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24398",
            name: "\u0110\u1eb7ng Huy Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24399",
            name: "\u0110\u1eb7ng Nguy\u00ean C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24400",
            name: "\u0110\u1eb7ng Thai Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24401",
            name: "\u0110\u1eb7ng Th\u00e1i Th\u00e2n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24402",
            name: "\u0110\u1eb7ng Th\u00f9y Tr\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24403",
            name: "\u0110\u1eb7ng Tr\u1ea7n C\u00f4n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24404",
            name: "\u0110\u1eb7ng V\u0103n Ng\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24405",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24406",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24407",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24408",
            name: "\u0110\u1ed1ng \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24409",
            name: "\u0110\u1ed3ng Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24412",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24413", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "24414",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24415",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24416",
            name: "H\u00e0n M\u1eb7c T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24417",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24418",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24419",
            name: "Ho\u00e0ng \u0110\u1ea1o Th\u00fay",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24420",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24421",
            name: "Ho\u00e0ng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24422",
            name: "Ho\u00e0ng V\u0103n Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24423",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24424",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24425",
            name: "Huy\u1ec1n Tr\u00e2n C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24426",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24427",
            name: "Khu\u1ea5t Duy Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24428",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24429", name: "K\u00fd Con", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24430",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24431",
            name: "L\u00ea Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24434",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24432",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24433",
            name: "L\u00ea \u0110\u00ecnh Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24435",
            name: "L\u00ea \u0110\u1ee9c Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24436",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24437", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24438",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24439",
            name: "L\u00ea Ng\u1ecdc H\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24440",
            name: "L\u00ea Quang \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24441",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24442",
            name: "L\u00ea Th\u00e0nh Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24443",
            name: "L\u00ea Th\u00e1nh T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24444",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24445",
            name: "L\u00ea Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24446",
            name: "L\u00ea Th\u1ecb Ri\u00eang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24447",
            name: "L\u00ea V\u0103n H\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24448",
            name: "L\u00ea V\u0103n T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24449", name: "L\u1eef Gia", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24450",
            name: "L\u01b0\u01a1ng \u0110\u1ecbnh C\u1ee7a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24451",
            name: "L\u01b0\u01a1ng Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24452",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24453",
            name: "L\u00fd Ch\u00ednh Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24454",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24455",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24456",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24457",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24458",
            name: "M\u1ea1c \u0110\u0103ng Dung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24459",
            name: "M\u1ea1c Th\u1ecb B\u01b0\u1edfi",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24460",
            name: "Mai Th\u00fac Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24461",
            name: "Mai Xu\u00e2n Th\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24462", name: "Nay Der", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24463",
            name: "Ng\u00f4 \u0110\u1ee9c K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24464",
            name: "Ng\u00f4 Gia Kh\u1ea3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24465",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24466",
            name: "Ng\u00f4 M\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24467",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24468",
            name: "Ng\u00f4 T\u1ea5t T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24469",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24470",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24471",
            name: "Nguy\u1ec5n B\u00e1 Lai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24472",
            name: "Nguy\u1ec5n B\u00e1 Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24473",
            name: "Nguy\u1ec5n Bi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24474",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24475",
            name: "Nguy\u1ec5n C\u01b0 Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24477",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24476",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24478",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24479",
            name: "Nguy\u1ec5n \u0110\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24480",
            name: "Nguy\u1ec5n H\u1eefu Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24481",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24482",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24483",
            name: "Nguy\u1ec5n Nh\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24484",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24485",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24486",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24487",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24488",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24489",
            name: "Nguy\u1ec5n Thi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24490",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24491",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24492",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24493",
            name: "Nguy\u1ec5n Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24494",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24495",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24496",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24497",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24498", name: "Nguy\u1ec5n X\u00ed", prefix: "Ph\u1ed1" },
          {
            id: "24499",
            name: "N\u01a1 Trang Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24500",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24501",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24502",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24503",
            name: "Ph\u1ea1m Th\u1ebf Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24504",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24505",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24506",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24507",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24508",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24509",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24510",
            name: "Phan K\u1ebf B\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24511",
            name: "Ph\u00f9 \u0110\u1ed5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24512", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "24513",
            name: "Ph\u00f9ng Kh\u1eafc Khoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24514",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24515",
            name: "Quy\u1ebft Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24516", name: "Siu Bleh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24517",
            name: "S\u01b0 V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24518", name: "T\u1ea1 Quang B\u1eedu", prefix: "Ph\u1ed1" },
          {
            id: "24519",
            name: "T\u1ea3n \u0110\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24520",
            name: "T\u00e2n Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24521",
            name: "T\u0103ng B\u1ea1t H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24522", name: "Thi S\u00e1ch", prefix: "Ph\u1ed1" },
          {
            id: "24523",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24524",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24525",
            name: "T\u00f4 V\u0129nh Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24526",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24527",
            name: "T\u00f4n Th\u1ea5t Thuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24528",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24529",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24530",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24531",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24532",
            name: "Tr\u1ea7n Ki\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24533",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24534",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24535",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24536",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24537",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24538",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24539",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24540",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24541",
            name: "Tr\u1ea7n V\u0103n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24542",
            name: "Tri\u1ec7u Quang Ph\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24543",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24544",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24545",
            name: "Tr\u01b0\u1eddng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24546",
            name: "Tr\u01b0\u1eddng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24547",
            name: "T\u00fa X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24548",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24549",
            name: "Ung V\u0103n Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24550",
            name: "\u00dat T\u1ecbch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24551", name: "V\u1ea1n Ki\u1ebfp", prefix: "Ph\u1ed1" },
          {
            id: "24552",
            name: "V\u00f5 Duy D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24553",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24554",
            name: "V\u00f5 Trung Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24555",
            name: "V\u00f5 V\u0103n T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24556", name: "W\u1eebu", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24557",
            name: "Y\u00ean \u0110\u1ed7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24558",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "23",
    code: "BP",
    name: "B\u00ecnh Ph\u01b0\u1edbc",
    districts: [
      {
        id: "316",
        name: "B\u00ecnh Long",
        wards: [
          { id: "4922", name: "An L\u1ed9c", prefix: "Ph\u01b0\u1eddng" },
          { id: "4923", name: "An Ph\u00fa", prefix: "X\u00e3" },
          {
            id: "4924",
            name: "H\u01b0ng Chi\u1ebfn",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "4925",
            name: "Ph\u00fa \u0110\u1ee9c",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "4926",
            name: "Ph\u00fa Th\u1ecbnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4927", name: "Thanh L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "4928", name: "Thanh Ph\u00fa", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24559", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24560", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24561",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24562",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24563",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24564", name: "\u0110T 246", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24565", name: "\u0110T 757", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24566", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "24567",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24568",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24569",
            name: "H\u01b0ng Th\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24570",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24571",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24572",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24573",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24574",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24575",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24576",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24577",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24578",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24579",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24580",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24581",
            name: "Qu\u1ed1c L\u1ed9 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24582",
            name: "Th\u1ee7 Khoa Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24583",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24584",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24585",
            name: "Tr\u1eeb V\u0103n Th\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "317",
        name: "B\u00f9 \u0110\u0103ng",
        wards: [
          { id: "4929", name: "B\u00ecnh Minh", prefix: "X\u00e3" },
          { id: "4930", name: "Bom Bo", prefix: "X\u00e3" },
          { id: "4931", name: "\u0110\u1eafk Nhau", prefix: "X\u00e3" },
          { id: "4932", name: "\u0110\u0103ng H\u00e0", prefix: "X\u00e3" },
          { id: "4933", name: "\u0110o\u00e0n K\u1ebft", prefix: "X\u00e3" },
          { id: "4934", name: "\u0110\u1ed3ng Nai", prefix: "X\u00e3" },
          { id: "4935", name: "\u0110\u1ee9c Li\u1ec5u", prefix: "X\u00e3" },
          {
            id: "4936",
            name: "\u0110\u1ee9c Phong",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4937", name: "\u0110\u01b0\u1eddng 10", prefix: "X\u00e3" },
          { id: "4938", name: "Minh H\u01b0ng", prefix: "X\u00e3" },
          { id: "4939", name: "Ngh\u0129a B\u00ecnh", prefix: "X\u00e3" },
          { id: "4940", name: "Ngh\u0129a Trung", prefix: "X\u00e3" },
          { id: "4941", name: "Ph\u00fa S\u01a1n", prefix: "X\u00e3" },
          { id: "4942", name: "Ph\u01b0\u1edbc S\u01a1n", prefix: "X\u00e3" },
          { id: "4943", name: "Th\u1ecd S\u01a1n", prefix: "X\u00e3" },
          { id: "4944", name: "Th\u1ed1ng Nh\u1ea5t", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "24586",
            name: "\u0110o\u00e0n \u0110\u1ee9c Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24587", name: "\u0110T 759", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24588", name: "\u0110T 760", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24589", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "24590",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24591",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24592",
            name: "Qu\u1ed1c l\u1ed9 21B",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "318",
        name: "B\u00f9 \u0110\u1ed1p",
        wards: [
          { id: "4945", name: "H\u01b0ng Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "4946", name: "Ph\u01b0\u1edbc Thi\u1ec7n", prefix: "X\u00e3" },
          { id: "4947", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4948", name: "T\u00e2n Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "4949", name: "Thanh B\u00ecnh", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "4950", name: "Thanh Ho\u00e0", prefix: "X\u00e3" },
          { id: "4951", name: "Thi\u1ec7n H\u01b0ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24593", name: "\u0110T 759B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24594",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24595",
            name: "Qu\u1ed1c L\u1ed9 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "319",
        name: "B\u00f9 Gia M\u1eadp",
        wards: [
          { id: "4952", name: "B\u00ecnh Th\u1eafng", prefix: "X\u00e3" },
          { id: "4953", name: "B\u00f9 Gia M\u1eadp", prefix: "X\u00e3" },
          { id: "4954", name: "\u0110a Kia", prefix: "X\u00e3" },
          { id: "4955", name: "\u0110\u1eafk \u01a0", prefix: "X\u00e3" },
          { id: "4956", name: "\u0110\u1ee9c H\u1ea1nh", prefix: "X\u00e3" },
          {
            id: "4957",
            name: "\u0110\u1ee9c Ph\u01b0\u1edbc",
            prefix: "X\u00e3",
          },
          { id: "4958", name: "\u0110\u1ee9c V\u0103n", prefix: "X\u00e3" },
          { id: "4959", name: "Ph\u00fa Ngh\u0129a", prefix: "X\u00e3" },
          { id: "4960", name: "Ph\u00fa V\u0103n", prefix: "X\u00e3" },
          { id: "4961", name: "Ph\u01b0\u1edbc Minh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24596", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24597", name: "\u0110T 741", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24598",
            name: "Ph\u00fa Nguy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24599",
            name: "T\u00e2n Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "320",
        name: "Ch\u01a1n Th\u00e0nh",
        wards: [
          {
            id: "4962",
            name: "Ch\u01a1n Th\u00e0nh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4963", name: "Minh H\u01b0ng", prefix: "X\u00e3" },
          { id: "4964", name: "Minh L\u1eadp", prefix: "X\u00e3" },
          { id: "4965", name: "Minh Long", prefix: "X\u00e3" },
          { id: "4966", name: "Minh Th\u1eafng", prefix: "X\u00e3" },
          { id: "4967", name: "Minh Th\u00e0nh", prefix: "X\u00e3" },
          { id: "4968", name: "Nha B\u00edch", prefix: "X\u00e3" },
          { id: "4969", name: "Quang Minh", prefix: "X\u00e3" },
          { id: "4970", name: "Th\u00e0nh T\u00e2m", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24600", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24601", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24602", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24603", name: "9A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24604",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24605",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24606", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24607", name: "D6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24608", name: "D9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24609", name: "DL14", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24610",
            name: "\u0110\u1ed3ng H\u01b0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24611", name: "\u0110T 741", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24612", name: "\u0110T 751", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24613", name: "\u0110T 756", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24614",
            name: "H\u1ed3 Ch\u00ed Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24615",
            name: "Hu\u1ef3nh V\u0103n Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24616",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24617",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24618", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24619", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24620", name: "N8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24621", name: "N9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24622",
            name: "Ng\u00f4 \u0110\u1ee9c K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24623",
            name: "Ng\u00f4 T\u1ea5t T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24624",
            name: "Nguy\u1ec5n C\u00f4ng Hoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24625",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24626",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24627",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24628",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24629",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24630",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24631", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "24632",
            name: "Qu\u1ed1c L\u1ed9 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24633",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24634", name: "Rada", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24635", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24636", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24637", name: "S\u1ed1 32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24638", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24639", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24640", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24641", name: "T1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24642", name: "T2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24643", name: "T3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24644", name: "T4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24645", name: "T5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24646", name: "TB1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24647",
            name: "Thanh T\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24648",
            name: "Thi\u1ebfu Ni\u00ean 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24649",
            name: "T\u1ed1ng Duy T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24650",
            name: "Tr\u1ea7n Qu\u1ed1c Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24651",
            name: "Trung L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2911",
            name: "Ch\u01a1n Th\u00e0nh Golden Land",
            lat: "11.482642173767",
            lng: "106.61460876465",
          },
        ],
      },
      {
        id: "321",
        name: "\u0110\u1ed3ng Ph\u00fa",
        wards: [
          { id: "4971", name: "\u0110\u1ed3ng T\u00e2m", prefix: "X\u00e3" },
          { id: "4972", name: "\u0110\u1ed3ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "4973", name: "T\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "4974", name: "T\u00e2n H\u01b0ng", prefix: "X\u00e3" },
          { id: "4975", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "4976", name: "T\u00e2n L\u1ee3i", prefix: "X\u00e3" },
          {
            id: "4977",
            name: "T\u00e2n ph\u00fa",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "4978", name: "T\u00e2n Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "4979", name: "T\u00e2n Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "4980", name: "Thu\u1eadn L\u1ee3i", prefix: "X\u00e3" },
          { id: "4981", name: "Thu\u1eadn Ph\u00fa", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24652", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24653", name: "322", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24654", name: "753", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24655",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24656",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24657", name: "D11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24658", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24659", name: "\u0110T 741", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24660", name: "\u0110T753", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24661",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24662",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24663",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24664",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24665",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24666", name: "N10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24667", name: "N12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24668", name: "N7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24669", name: "N9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24670",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24671",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24672",
            name: "Ph\u00fa Ri\u1ec1ng \u0110\u1ecf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24673",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24674",
            name: "T\u1ec9nh L\u1ed9 753",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24675",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24676",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24677",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "322",
        name: "\u0110\u1ed3ng Xo\u00e0i",
        wards: [
          {
            id: "4982",
            name: "T\u00e2n B\u00ecnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "4983",
            name: "T\u00e2n \u0110\u1ed3ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4984", name: "T\u00e2n Ph\u00fa", prefix: "Ph\u01b0\u1eddng" },
          { id: "4985", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          {
            id: "4986",
            name: "T\u00e2n Thi\u1ec7n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "4987",
            name: "T\u00e2n Xu\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "4988", name: "Ti\u1ebfn H\u01b0ng", prefix: "X\u00e3" },
          { id: "4989", name: "Ti\u1ebfn Th\u00e0nh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24678", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24679", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24680", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24681", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24682", name: "246", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24683", name: "26/12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24684", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24685", name: "31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24686", name: "32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24687", name: "322", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24688", name: "37", prefix: "Ph\u1ed1" },
          { id: "24689", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24690", name: "5B5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24691", name: "9/6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24692", name: "B1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24693", name: "B2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24694", name: "B3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24695", name: "B5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24696", name: "B6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24697", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "24698",
            name: "B\u00f9i H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24699",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24700",
            name: "B\u00f9i Vi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24701", name: "C2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24702",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24703",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24704",
            name: "Ch\u00f9a Mi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24705", name: "D11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24706", name: "D18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24707", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24708", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24709", name: "D31", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24718",
            name: "D\u01b0\u01a1ng B\u00e1 Tr\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24710",
            name: "\u0110\u1eb7ng Thai Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24711",
            name: "\u0110\u1eb7ng Tr\u1ea7n C\u00f4n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24712",
            name: "\u0110i\u1ec3u \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24713",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24714",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24715", name: "\u0110T 507", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24716", name: "\u0110T 741", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24717", name: "\u0110T 753", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24719",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24720", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "24721",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24722",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24723", name: "HL 507", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24724",
            name: "H\u1ed3 Ch\u00ed Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24725",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24726",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24727",
            name: "Ho\u00e0ng V\u0103n Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24728",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24729",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24730",
            name: "H\u01b0\u01a1ng l\u1ed9 507",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24731",
            name: "Hu\u1ef3nh V\u0103n Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24732",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24733",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24734",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24735",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24736",
            name: "L\u00ea Th\u1ecb Ri\u00eang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24737",
            name: "L\u00ea V\u0103n S\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24738",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24739",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24740",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24741",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24742",
            name: "Mai Th\u00fac Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24743", name: "N11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24744", name: "N12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24745", name: "N13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24746", name: "N14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24747", name: "N15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24748", name: "N16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24749", name: "N21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24750", name: "N3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24751", name: "N4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24752", name: "N5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24753", name: "N6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24754", name: "N7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24755", name: "N8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24756", name: "Nam Cao", prefix: "Ph\u1ed1" },
          {
            id: "24757",
            name: "Ng\u00f4 \u0110\u1ee9c K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24758",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24759",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24760",
            name: "Nguy\u1ec5n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24761",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24762",
            name: "Nguy\u1ec5n Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24763",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24764",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24765",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24766",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24767",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24768",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24769",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24770",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24771",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24772",
            name: "Nguy\u1ec5n Tri",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24773",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24774",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24775",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24776",
            name: "Nguy\u1ec5n V\u0103n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24777", name: "Nguy\u1ec5n X\u00ed", prefix: "Ph\u1ed1" },
          {
            id: "24778",
            name: "N\u01a1 Trang Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24779",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24780",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24781",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24782",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24783",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24784",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24785",
            name: "Phan Huy \u00cdch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24786",
            name: "Phan V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24787",
            name: "Ph\u00fa C\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24788",
            name: "Ph\u00fa Ri\u1ec1ng \u0110\u1ecf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24789",
            name: "Qu\u1ed1c L\u1ed9 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24790",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24791", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24792", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24793", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24794", name: "S\u1ed1 26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24795", name: "S\u1ed1 27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24796", name: "S\u1ed1 29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24797", name: "S\u1ed1 2A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24798", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24799", name: "S\u1ed1 3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24800", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24801",
            name: "T\u1ec9nh l\u1ed9 741",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24802",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24803",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24804",
            name: "Tr\u1ea7n H\u1eefu \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24805",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24806",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24807",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24808",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24809",
            name: "Tr\u1ea7n V\u0103n Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24810",
            name: "Tr\u1ea7n Xu\u00e2n So\u1ea1n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24811",
            name: "Tri\u1ec7u Quang Ph\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24812",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24813",
            name: "Tr\u01b0\u01a1ng C\u00f4ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24814",
            name: "Tu\u1ea5n Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24815",
            name: "V\u00e0nh \u0110ai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24816",
            name: "V\u00f5 V\u0103n T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2912",
            name: "L\u00e0ng bi\u1ec7t th\u1ef1 Dovillas",
            lat: "11.53840637207",
            lng: "106.91412353516",
          },
          {
            id: "2913",
            name: "Nam Long Golden Land",
            lat: "11.533368110657",
            lng: "106.89854431152",
          },
          {
            id: "2914",
            name: "The Gold City",
            lat: "11.525585174561",
            lng: "106.86206817627",
          },
          {
            id: "2915",
            name: "Ti\u1ebfn Th\u00e0nh",
            lat: "11.524308204651",
            lng: "106.86881256104",
          },
        ],
      },
      {
        id: "323",
        name: "H\u1edbn Qu\u1ea3n",
        wards: [
          { id: "4990", name: "An Kh\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "4991", name: "An Ph\u00fa", prefix: "X\u00e3" },
          { id: "4992", name: "\u0110\u1ed3ng N\u01a1", prefix: "X\u00e3" },
          { id: "4993", name: "Minh \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "4994", name: "Minh T\u00e2m", prefix: "X\u00e3" },
          { id: "4995", name: "Ph\u01b0\u1edbc An", prefix: "X\u00e3" },
          { id: "4996", name: "T\u00e2n Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "4997", name: "T\u00e2n H\u01b0ng", prefix: "X\u00e3" },
          { id: "4998", name: "T\u00e2n Khai", prefix: "X\u00e3" },
          { id: "4999", name: "T\u00e2n L\u1ee3i", prefix: "X\u00e3" },
          { id: "5000", name: "T\u00e2n Quan", prefix: "X\u00e3" },
          { id: "5001", name: "Thanh An", prefix: "X\u00e3" },
          { id: "5002", name: "Thanh B\u00ecnh", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "24817",
            name: "B\u1eafc Nam 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24818", name: "\u0110T 304", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24819", name: "\u0110T 757", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24820",
            name: "Ho\u00e0i Nh\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24821",
            name: "Li\u00ean x\u00e3 Z68",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24822",
            name: "Qu\u1ed1c L\u1ed9 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24823",
            name: "T\u1ec9nh L\u1ed9 752",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24824",
            name: "V\u00e0nh \u0110ai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24825", name: "Z68", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
      {
        id: "324",
        name: "L\u1ed9c Ninh",
        wards: [
          { id: "5003", name: "L\u1ed9c An", prefix: "X\u00e3" },
          { id: "5004", name: "L\u1ed9c \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "5005", name: "L\u1ed9c Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "5006", name: "L\u1ed9c Ho\u00e0", prefix: "X\u00e3" },
          { id: "5007", name: "L\u1ed9c H\u01b0ng", prefix: "X\u00e3" },
          { id: "5008", name: "L\u1ed9c Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "5009", name: "L\u1ed9c Ninh", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "5010", name: "L\u1ed9c Ph\u00fa", prefix: "X\u00e3" },
          { id: "5011", name: "L\u1ed9c Quang", prefix: "X\u00e3" },
          { id: "5012", name: "L\u1ed9c T\u1ea5n", prefix: "X\u00e3" },
          { id: "5013", name: "L\u1ed9c Th\u00e1i", prefix: "X\u00e3" },
          { id: "5014", name: "L\u1ed9c Th\u00e0nh", prefix: "X\u00e3" },
          { id: "5015", name: "L\u1ed9c Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "5016", name: "L\u1ed9c Thi\u1ec7n", prefix: "X\u00e3" },
          { id: "5017", name: "L\u1ed9c Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "5018", name: "L\u1ed9c Thu\u1eadn", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24826", name: "\u0110T 756", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24827",
            name: "L\u1ed9c Quan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24828",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24829",
            name: "Qu\u1ed1c L\u1ed9 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "325",
        name: "Ph\u00fa Ri\u1ec1ng",
        wards: [
          { id: "5019", name: "B\u00ecnh S\u01a1n", prefix: "X\u00e3" },
          { id: "5020", name: "B\u00ecnh T\u00e2n", prefix: "X\u00e3" },
          { id: "5021", name: "B\u00f9 Nho", prefix: "X\u00e3" },
          { id: "5022", name: "Long B\u00ecnh", prefix: "X\u00e3" },
          { id: "5023", name: "Long H\u00e0", prefix: "X\u00e3" },
          { id: "5024", name: "Long H\u01b0ng", prefix: "X\u00e3" },
          { id: "5025", name: "Long T\u00e2n", prefix: "X\u00e3" },
          { id: "5026", name: "Ph\u00fa Ri\u1ec1ng", prefix: "X\u00e3" },
          { id: "5027", name: "Ph\u00fa Trung", prefix: "X\u00e3" },
          { id: "5028", name: "Ph\u01b0\u1edbc T\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24830", name: "757", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24831", name: "\u0110T 741", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24832", name: "\u0110T 757", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24833",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "326",
        name: "Ph\u01b0\u1edbc Long",
        wards: [
          { id: "5029", name: "Long Giang", prefix: "X\u00e3" },
          {
            id: "5030",
            name: "Long Ph\u01b0\u1edbc",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "5031", name: "Long Th\u1ee7y", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "5032",
            name: "Ph\u01b0\u1edbc B\u00ecnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "5033", name: "Ph\u01b0\u1edbc T\u00edn", prefix: "X\u00e3" },
          { id: "5034", name: "S\u01a1n Giang", prefix: "Ph\u01b0\u1eddng" },
          { id: "5035", name: "Th\u00e1c M\u01a1", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "24834", name: "6/1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24835", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "24836",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24837",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24838",
            name: "\u0110\u0103k S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24839",
            name: "\u0110\u1eafkT\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24840",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24841", name: "\u0110T 741", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24842", name: "\u0110T 759", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24843",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24844",
            name: "H\u1ed3 Th\u1ee7y Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24845",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24846",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24847",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24848",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24849",
            name: "L\u00ea V\u0103n A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24850",
            name: "L\u00ea V\u0103n Duy\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24851",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24852",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24853",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24854",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24855",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24856",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24857",
            name: "S\u01b0 V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24858",
            name: "T\u1eadp \u0110o\u00e0n 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24859",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24860",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24861",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24862", name: "T\u1ef1 Do", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "24",
    code: "HY",
    name: "H\u01b0ng Y\u00ean",
    districts: [
      {
        id: "327",
        name: "\u00c2n Thi",
        wards: [
          { id: "5036", name: " H\u1ed3ng Quang", prefix: "X\u00e3" },
          { id: "5037", name: "\u00c2n Thi", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "5038", name: "B\u1eafc S\u01a1n", prefix: "X\u00e3" },
          { id: "5039", name: "B\u00e3i S\u1eady", prefix: "X\u00e3" },
          { id: "5040", name: "C\u1ea9m Ninh", prefix: "X\u00e3" },
          { id: "5041", name: "\u0110a L\u1ed9c", prefix: "X\u00e3" },
          { id: "5042", name: "\u0110\u1eb7ng L\u1ec5", prefix: "X\u00e3" },
          {
            id: "5043",
            name: "\u0110\u00e0o D\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "5044", name: "H\u1ea1 L\u1ec5", prefix: "X\u00e3" },
          { id: "5045", name: "H\u1ed3 T\u00f9ng M\u1eadu", prefix: "X\u00e3" },
          { id: "5046", name: "Ho\u00e0ng Hoa Th\u00e1m", prefix: "X\u00e3" },
          { id: "5047", name: "H\u1ed3ng V\u00e2n", prefix: "X\u00e3" },
          { id: "5048", name: "Nguy\u1ec5n Tr\u00e3i", prefix: "X\u00e3" },
          { id: "5049", name: "Ph\u00f9 \u1ee6ng", prefix: "X\u00e3" },
          { id: "5050", name: "Qu\u1ea3ng L\u00e3ng", prefix: "X\u00e3" },
          { id: "5051", name: "Quang Vinh", prefix: "X\u00e3" },
          { id: "5052", name: "T\u00e2n Ph\u00fac", prefix: "X\u00e3" },
          { id: "5053", name: "Ti\u1ec1n Phong", prefix: "X\u00e3" },
          { id: "5054", name: "V\u00e2n Du", prefix: "X\u00e3" },
          { id: "5055", name: "V\u0103n Nhu\u1ec7", prefix: "X\u00e3" },
          { id: "5056", name: "Xu\u00e2n Tr\u00fac", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "24863",
            name: "Qu\u1ed1c L\u1ed9 38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24864",
            name: "T\u1ec9nh l\u1ed9 210",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "328",
        name: "H\u01b0ng Y\u00ean",
        wards: [
          { id: "5057", name: " Lam S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "5058", name: " L\u00ea L\u1ee3i", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "5059",
            name: " Li\u00ean Ph\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "5060", name: " Trung Ngh\u0129a", prefix: "X\u00e3" },
          { id: "5061", name: "An T\u1ea3o", prefix: "Ph\u01b0\u1eddng" },
          { id: "5062", name: "B\u1ea3o Kh\u00ea", prefix: "X\u00e3" },
          { id: "5063", name: "Hi\u1ebfn Nam", prefix: "Ph\u01b0\u1eddng" },
          { id: "5064", name: "Ho\u00e0ng Hanh", prefix: "X\u00e3" },
          {
            id: "5065",
            name: "H\u1ed3ng Ch\u00e2u",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "5066", name: "H\u1ed3ng Nam", prefix: "X\u00e3" },
          { id: "5067", name: "H\u00f9ng C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "5068", name: "Minh Khai", prefix: "Ph\u01b0\u1eddng" },
          { id: "5069", name: "Ph\u00fa C\u01b0\u1eddng", prefix: "X\u00e3" },
          {
            id: "5070",
            name: "Ph\u01b0\u01a1ng Chi\u1ec3u",
            prefix: "X\u00e3",
          },
          { id: "5071", name: "Qu\u1ea3ng Ch\u00e2u", prefix: "X\u00e3" },
          { id: "5072", name: "Quang Trung", prefix: "Ph\u01b0\u1eddng" },
          { id: "5073", name: "T\u00e2n H\u01b0ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24865", name: "39A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24866", name: "39B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24867", name: "An B\u00ecnh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24868",
            name: "An Chi\u1ec3u 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24869",
            name: "An D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24870",
            name: "B\u1eafc Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24871",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24872",
            name: "B\u00e3i S\u1eady",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24873",
            name: "B\u1edd S\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24874",
            name: "B\u00f9i Th\u1ecb C\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24875",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24876",
            name: "Chu M\u1ea1nh Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24877",
            name: "Ch\u00f9a Chu\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24878",
            name: "Ch\u00f9a \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24885",
            name: "Do\u00e3n L\u1ed7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24888", name: "D\u1ef1ng", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24889",
            name: "D\u01b0\u01a1ng H\u1eefu Mi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24879",
            name: "\u0110\u1eb7ng V\u0103n Ng\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24880",
            name: "\u0110i\u1ec7n Bi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24881",
            name: "\u0110i\u1ec7n Bi\u00ean 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24882",
            name: "\u0110inh \u0110i\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24883",
            name: "\u0110inh Gia Qu\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24884",
            name: "\u0110\u1ed7 Nh\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24886",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24887",
            name: "\u0110\u00f4ng Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24890",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24891",
            name: "H\u00e0n L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24892", name: "Hi\u1ebfn", prefix: "Ph\u1ed1" },
          {
            id: "24893",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24894",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24895",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24896",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24897",
            name: "H\u00f9ng C\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24898",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24899",
            name: "L\u00ea \u0110inh Ki\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24900", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24901", name: "L\u00ea Thanh Ngh\u1ecb", prefix: "Ph\u1ed1" },
          {
            id: "24902",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24903",
            name: "L\u00ea V\u0103n L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24904",
            name: "L\u01b0\u01a1ng \u0110\u1ecbnh C\u1ee7a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24905",
            name: "L\u01b0\u01a1ng Ng\u1ecdc Quy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24906",
            name: "L\u01b0\u01a1ng V\u0103n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24907",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24908",
            name: "M\u1ea1c Th\u1ecb B\u01b0\u1edfi",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24909",
            name: "Mai H\u1eafc \u0110\u1ebf",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24910",
            name: "M\u1eadu D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24911",
            name: "Nam Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24912",
            name: "Nam Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24913",
            name: "Nguy\u1ec5n Bi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24914",
            name: "Nguy\u1ec5n B\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24915",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24916",
            name: "Nguy\u1ec5n C\u00f4ng Hoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24918",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24917",
            name: "Nguy\u1ec5n \u0110\u00ecnh Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24919",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24920",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24921",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24922",
            name: "Nguy\u1ec5n Phong S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24923",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24924",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24925",
            name: "Nguy\u1ec5n Trung Ng\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24926",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24927",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24928",
            name: "Nh\u00e2n D\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24929", name: "Nh\u01b0 Qu\u1ef3nh", prefix: "Ph\u1ed1" },
          {
            id: "24930",
            name: "Ph\u1ea1m B\u1ea1ch H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24931",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24932",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24933",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24934",
            name: "Ph\u00f3 \u0110\u1ee9c Ch\u00ednh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24935",
            name: "Ph\u00f9ng Ch\u00ed Ki\u00ean",
            prefix: "Ph\u1ed1",
          },
          {
            id: "24936",
            name: "Ph\u01b0\u01a1ng \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24937", name: "T\u1ea1 Quang B\u1eedu", prefix: "Ph\u1ed1" },
          {
            id: "24938",
            name: "T\u00e2y Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24939",
            name: "T\u1ec9nh l\u1ed9 266",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24940",
            name: "T\u1ec9nh l\u1ed9 39B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24941",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24942",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24943",
            name: "T\u1ed1ng Tr\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24944",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24945",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24946",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24947",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24948",
            name: "Tri\u1ec7u Quang Ph\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24949",
            name: "Tr\u01b0ng Nh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24950",
            name: "Tr\u01b0ng Tr\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24951",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24952", name: "V\u0103n Mi\u1ebfu", prefix: "Ph\u1ed1" },
          { id: "24953", name: "V\u0129nh Ninh", prefix: "Ph\u1ed1" },
          {
            id: "24954",
            name: "V\u0169 Tr\u1ecdng Ph\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24955",
            name: "X\u00edch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24956", name: "X\u00f3m 2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24957",
            name: "X\u00f3m D\u01b0\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2916",
            name: "Ch\u1ee3 Ph\u1ed1 Hi\u1ebfn",
            lat: "20.649074554443",
            lng: "106.05812835693",
          },
          {
            id: "2917",
            name: "Ph\u00fac H\u01b0ng",
            lat: "20.664203643799",
            lng: "106.05841064453",
          },
          {
            id: "2918",
            name: "Xu\u00e2n Ph\u00fa H\u01b0ng",
            lat: "21.029581069946",
            lng: "105.85041046143",
          },
        ],
      },
      {
        id: "329",
        name: "Kho\u00e1i Ch\u00e2u",
        wards: [
          { id: "5074", name: " Ch\u00ed T\u00e2n", prefix: "X\u00e3" },
          { id: "5075", name: " \u0110\u1ea1i H\u01b0ng", prefix: "X\u00e3" },
          { id: "5076", name: " Nhu\u1ebf D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "5077", name: " Th\u00e0nh C\u00f4ng", prefix: "X\u00e3" },
          { id: "5078", name: " Vi\u1ec7t H\u00f2a", prefix: "X\u00e3" },
          { id: "5079", name: "An V\u0129", prefix: "X\u00e3" },
          { id: "5080", name: "B\u00ecnh Ki\u1ec1u", prefix: "X\u00e3" },
          { id: "5081", name: "B\u00ecnh Minh", prefix: "X\u00e3" },
          { id: "5082", name: "D\u1ea1 Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "5084", name: "D\u00e2n Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "5083", name: "\u0110\u1ea1i T\u1eadp", prefix: "X\u00e3" },
          { id: "5085", name: "\u0110\u00f4ng K\u1ebft", prefix: "X\u00e3" },
          { id: "5086", name: "\u0110\u00f4ng Ninh", prefix: "X\u00e3" },
          { id: "5087", name: "\u0110\u00f4ng T\u1ea3o", prefix: "X\u00e3" },
          { id: "5088", name: "\u0110\u1ed3ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "5089", name: "H\u00e0m T\u1eed", prefix: "X\u00e3" },
          { id: "5090", name: "H\u1ed3ng Ti\u1ebfn", prefix: "X\u00e3" },
          {
            id: "5091",
            name: "Kho\u00e1i Ch\u00e2u",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "5092", name: "Li\u00ean Kh\u00ea", prefix: "X\u00e3" },
          { id: "5093", name: "\u00d4ng \u0110\u00ecnh", prefix: "X\u00e3" },
          { id: "5094", name: "Ph\u00f9ng H\u01b0ng", prefix: "X\u00e3" },
          { id: "5095", name: "T\u00e2n Ch\u00e2u", prefix: "X\u00e3" },
          { id: "5096", name: "T\u00e2n D\u00e2n", prefix: "X\u00e3" },
          { id: "5097", name: "Thu\u1ea7n H\u01b0ng", prefix: "X\u00e3" },
          { id: "5098", name: "T\u1ee9 D\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24958", name: "205D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24959", name: "209", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24960", name: "377", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24961", name: "379", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24962", name: "An Th\u00e1i", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24963",
            name: "Ch\u1eed \u0110\u1ed3ng T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24964",
            name: "Nguy\u1ec5n Kho\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24965",
            name: "T\u1ec9nh l\u1ed9 199",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24966",
            name: "T\u1ec9nh l\u1ed9 204",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "330",
        name: "Kim \u0110\u1ed9ng",
        wards: [
          { id: "5099", name: "Ch\u00ednh Ngh\u0129a", prefix: "X\u00e3" },
          { id: "5100", name: "\u0110\u1ed3ng Thanh", prefix: "X\u00e3" },
          { id: "5101", name: "\u0110\u1ee9c H\u1ee3p", prefix: "X\u00e3" },
          { id: "5102", name: "Hi\u1ec7p C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "5103", name: "H\u00f9ng An", prefix: "X\u00e3" },
          {
            id: "5104",
            name: "L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "5105", name: "Mai \u0110\u1ed9ng", prefix: "X\u00e3" },
          { id: "5106", name: "Ngh\u0129a D\u00e2n", prefix: "X\u00e3" },
          { id: "5107", name: "Ng\u1ecdc Thanh", prefix: "X\u00e3" },
          { id: "5108", name: "Nh\u00e2n La", prefix: "X\u00e3" },
          {
            id: "5109",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "X\u00e3",
          },
          { id: "5110", name: "Ph\u00fa Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "5111", name: "Song Mai", prefix: "X\u00e3" },
          { id: "5112", name: "Th\u1ecd Vinh", prefix: "X\u00e3" },
          { id: "5113", name: "To\u00e0n Th\u1eafng", prefix: "X\u00e3" },
          { id: "5114", name: "V\u0129nh X\u00e1", prefix: "X\u00e3" },
          { id: "5115", name: "V\u0169 X\u00e1", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24967", name: "38B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24968", name: "39B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24969",
            name: "Qu\u1ed1c L\u1ed9 39",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24970",
            name: "Qu\u1ed1c L\u1ed9 39A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24971", name: "Tr\u01b0\u01a1ng X\u00e1", prefix: "Ph\u1ed1" },
        ],
        projects: [],
      },
      {
        id: "331",
        name: "M\u1ef9 H\u00e0o",
        wards: [
          { id: "5116", name: "B\u1ea1ch Sam", prefix: "X\u00e3" },
          {
            id: "5117",
            name: "B\u1ea7n Y\u00ean Nh\u00e2n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "5118", name: "C\u1ea9m X\u00e1", prefix: "X\u00e3" },
          { id: "5119", name: "D\u1ecb S\u1eed", prefix: "X\u00e3" },
          { id: "5120", name: "D\u01b0\u01a1ng Quang", prefix: "X\u00e3" },
          { id: "5121", name: "H\u00f2a Phong", prefix: "X\u00e3" },
          { id: "5122", name: "H\u01b0ng Long", prefix: "X\u00e3" },
          { id: "5123", name: "Minh \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "5124", name: "Ng\u1ecdc L\u00e2m", prefix: "X\u00e3" },
          { id: "5125", name: "Nh\u00e2n H\u00f2a", prefix: "X\u00e3" },
          {
            id: "5126",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "X\u00e3",
          },
          {
            id: "5127",
            name: "Ph\u00f9ng Ch\u00ed Ki\u00ean",
            prefix: "X\u00e3",
          },
          { id: "5128", name: "Xu\u00e2n D\u1ee5c", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24972", name: "196", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24973", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24974",
            name: "Li\u00ean Ph\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24975",
            name: "Li\u00ean T\u1ec9nh 210",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24976",
            name: "M\u1ef9 \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24977", name: "Ph\u1ed1 N\u1ed1i", prefix: "Ph\u1ed1" },
          {
            id: "24978",
            name: "Ph\u1ed1 N\u1ed1i A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24979",
            name: "Ph\u1ed1 N\u1ed1i B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24980",
            name: "Qu\u1ed1c L\u1ed9 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24981",
            name: "Qu\u1ed1c L\u1ed9 5A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24982",
            name: "T\u1ec9nh l\u1ed9 198",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2919",
            name: "Khu \u0111\u00f4 th\u1ecb L\u1ea1c H\u1ed3ng Ph\u00fac",
            lat: "20.925399780273",
            lng: "106.05745697021",
          },
          {
            id: "2920",
            name: "Ph\u1ed1 N\u1ed1i Sunshine",
            lat: "20.936790466309",
            lng: "106.06212615967",
          },
          {
            id: "2921",
            name: "TNR Ph\u1ed1 N\u1ed1i",
            lat: "20.925399780273",
            lng: "106.05745697021",
          },
        ],
      },
      {
        id: "332",
        name: "Ph\u00f9 C\u1eeb",
        wards: [
          { id: "5129", name: " T\u1ed1ng Tr\u00e2n", prefix: "X\u00e3" },
          { id: "5130", name: "\u0110\u00ecnh Cao", prefix: "X\u00e3" },
          {
            id: "5131",
            name: "\u0110o\u00e0n \u0110\u00e0o",
            prefix: "X\u00e3",
          },
          { id: "5132", name: "Minh Ho\u00e0ng", prefix: "X\u00e3" },
          { id: "5133", name: "Minh T\u00e2n", prefix: "X\u00e3" },
          { id: "5134", name: "Minh Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "5135", name: "Nguy\u00ean Ho\u00e0", prefix: "X\u00e3" },
          { id: "5136", name: "Nh\u1eadt Quang", prefix: "X\u00e3" },
          { id: "5137", name: "Phan S\u00e0o Nam", prefix: "X\u00e3" },
          { id: "5138", name: "Quang H\u01b0ng", prefix: "X\u00e3" },
          { id: "5139", name: "Tam \u0110a", prefix: "X\u00e3" },
          { id: "5140", name: "Ti\u00ean Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "5141", name: "T\u1ed1ng Phan", prefix: "X\u00e3" },
          { id: "5142", name: "Tr\u1ea7n Cao", prefix: "Th\u1ecb tr\u1ea5n" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "333",
        name: "Ti\u00ean L\u1eef",
        wards: [
          { id: "5143", name: "An Vi\u00ean", prefix: "X\u00e3" },
          { id: "5144", name: "C\u01b0\u01a1ng Ch\u00ednh", prefix: "X\u00e3" },
          { id: "5145", name: "D\u1ecb Ch\u1ebf", prefix: "X\u00e3" },
          { id: "5146", name: "\u0110\u1ee9c Th\u1eafng", prefix: "X\u00e3" },
          { id: "5147", name: "H\u1ea3i Tri\u1ec1u", prefix: "X\u00e3" },
          { id: "5148", name: "H\u01b0ng \u0110\u1ea1o", prefix: "X\u00e3" },
          { id: "5149", name: "L\u1ec7 X\u00e1", prefix: "X\u00e3" },
          { id: "5150", name: "Minh Ph\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "5151", name: "Ng\u00f4 Quy\u1ec1n", prefix: "X\u00e3" },
          { id: "5152", name: "Nh\u1eadt T\u00e2n", prefix: "X\u00e3" },
          { id: "5153", name: "Thi\u1ec7n Phi\u1ebfn", prefix: "X\u00e3" },
          { id: "5154", name: "Th\u1ee7 S\u1ef9", prefix: "X\u00e3" },
          { id: "5155", name: "Th\u1ee5y L\u00f4i", prefix: "X\u00e3" },
          { id: "5156", name: "Trung D\u0169ng", prefix: "X\u00e3" },
          { id: "5157", name: "V\u01b0\u01a1ng", prefix: "Th\u1ecb tr\u1ea5n" },
        ],
        streets: [
          { id: "24983", name: "39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24984", name: "39B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24985",
            name: "Qu\u1ed1c L\u1ed9 39",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24986", name: "Xu\u00f4i", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
      {
        id: "334",
        name: "V\u0103n Giang",
        wards: [
          { id: "5158", name: " M\u1ec5 S\u1edf", prefix: "X\u00e3" },
          { id: "5159", name: "C\u1eedu Cao", prefix: "X\u00e3" },
          { id: "5160", name: "Li\u00ean Ngh\u0129a", prefix: "X\u00e3" },
          { id: "5161", name: "Long H\u01b0ng", prefix: "X\u00e3" },
          { id: "5162", name: "Ngh\u0129a Tr\u1ee5", prefix: "X\u00e3" },
          { id: "5163", name: "Ph\u1ee5ng C\u00f4ng", prefix: "X\u00e3" },
          { id: "5164", name: "T\u00e2n Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "5165", name: "Th\u1eafng L\u1ee3i", prefix: "X\u00e3" },
          { id: "5166", name: "V\u0103n Giang", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "5167", name: "V\u0129nh Kh\u00fac", prefix: "X\u00e3" },
          { id: "5168", name: "Xu\u00e2n Quan", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24987", name: "179", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24988",
            name: "Th\u1ea3o Nguy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24989",
            name: "Th\u1ee7y Nguy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "24990",
            name: "T\u1ec9nh l\u1ed9 179",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24991", name: "Tr\u00fac", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24992",
            name: "V\u00e2n Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2922",
            name: "Aquabay Sky Residences",
            lat: "20.954893112183",
            lng: "105.93976593018",
          },
          {
            id: "2923",
            name: "Dragon Park V\u0103n Giang",
            lat: "20.961874008179",
            lng: "105.95423126221",
          },
          {
            id: "2924",
            name: "Ecopark",
            lat: "20.974222183228",
            lng: "105.92214202881",
          },
          {
            id: "2925",
            name: "Vincity H\u01b0ng Y\u00ean",
            lat: "20.941825866699",
            lng: "105.97059631348",
          },
        ],
      },
      {
        id: "335",
        name: "V\u0103n L\u00e2m",
        wards: [
          { id: "5169", name: " L\u01b0\u01a1ng T\u00e0i", prefix: "X\u00e3" },
          { id: "5170", name: " Vi\u1ec7t H\u01b0ng", prefix: "X\u00e3" },
          { id: "5171", name: "Ch\u1ec9 \u0110\u1ea1o", prefix: "X\u00e3" },
          {
            id: "5172",
            name: "\u0110\u1ea1i \u0110\u1ed3ng",
            prefix: "X\u00e3",
          },
          { id: "5173", name: "\u0110\u00ecnh D\u00f9", prefix: "X\u00e3" },
          { id: "5174", name: "L\u1ea1c \u0110\u1ea1o", prefix: "X\u00e3" },
          { id: "5175", name: "L\u1ea1c H\u1ed3ng", prefix: "X\u00e3" },
          { id: "5176", name: "Minh H\u1ea3i", prefix: "X\u00e3" },
          {
            id: "5177",
            name: "Nh\u01b0 Qu\u1ef3nh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "5178", name: "T\u00e2n Quang", prefix: "X\u00e3" },
          { id: "5179", name: "Tr\u01b0ng Tr\u1eafc", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "24993", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24994", name: "196", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24995", name: "206", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24996", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "24997", name: "5A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "24998",
            name: "\u0110\u1ea1i T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "24999", name: "Nh\u01b0 Qu\u1ef3nh", prefix: "Ph\u1ed1" },
          {
            id: "25000",
            name: "Qu\u1ed1c L\u1ed9 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2926",
            name: "K\u0110T Nh\u01b0 Qu\u1ef3nh",
            lat: "20.988578796387",
            lng: "105.98761749268",
          },
        ],
      },
      {
        id: "336",
        name: "Y\u00ean M\u1ef9",
        wards: [
          { id: "5180", name: " Y\u00ean H\u00f2a", prefix: "X\u00e3" },
          { id: "5181", name: "\u0110\u1ed3ng Than", prefix: "X\u00e3" },
          { id: "5182", name: "Giai Ph\u1ea1m", prefix: "X\u00e3" },
          { id: "5183", name: "Ho\u00e0n Long", prefix: "X\u00e3" },
          { id: "5184", name: "Li\u00eau X\u00e1", prefix: "X\u00e3" },
          {
            id: "5185",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "X\u00e3",
          },
          { id: "5186", name: "Minh Ch\u00e2u", prefix: "X\u00e3" },
          { id: "5187", name: "Ngh\u0129a Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "5188", name: "Ng\u1ecdc Long", prefix: "X\u00e3" },
          { id: "5189", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "5190", name: "T\u00e2n Vi\u1ec7t", prefix: "X\u00e3" },
          { id: "5191", name: "Thanh Long", prefix: "X\u00e3" },
          { id: "5192", name: "Trai Trang", prefix: "X\u00e3" },
          { id: "5193", name: "Trung H\u00f2a", prefix: "X\u00e3" },
          { id: "5194", name: "Trung H\u01b0ng", prefix: "X\u00e3" },
          { id: "5195", name: "Vi\u1ec7t C\u01b0\u1eddng", prefix: "X\u00e3" },
          {
            id: "5196",
            name: "Y\u00ean M\u1ef9",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "5197", name: "Y\u00ean Ph\u00fa", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "25001", name: "206", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25002", name: "206B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25003", name: "39A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25004", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25005", name: "5B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25006", name: "69", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25007",
            name: "Hoa Gi\u1ea5y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25008",
            name: "Qu\u1ed1c L\u1ed9 39",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25009",
            name: "Qu\u1ed1c L\u1ed9 39A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25010",
            name: "Qu\u1ed1c L\u1ed9 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25011",
            name: "Th\u01b0 Th\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25012",
            name: "T\u1ec9nh l\u1ed9 196",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25013",
            name: "T\u1ec9nh l\u1ed9 379",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2927",
            name: "V-Green City Ph\u1ed1 N\u1ed1i",
            lat: "20.909025192261",
            lng: "106.06056976318",
          },
        ],
      },
    ],
  },
  {
    id: "25",
    code: "BDD",
    name: "B\u00ecnh \u0110\u1ecbnh",
    districts: [
      {
        id: "337",
        name: "An L\u00e3o",
        wards: [
          { id: "5198", name: "An D\u0169ng", prefix: "X\u00e3" },
          { id: "5199", name: "An H\u00f2a", prefix: "X\u00e3" },
          { id: "5200", name: "An H\u01b0ng", prefix: "X\u00e3" },
          { id: "5201", name: "An L\u00e3o", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "5202", name: "An Ngh\u0129a", prefix: "X\u00e3" },
          { id: "5203", name: "An Quang", prefix: "X\u00e3" },
          { id: "5204", name: "An T\u00e2n", prefix: "X\u00e3" },
          { id: "5205", name: "An To\u00e0n", prefix: "X\u00e3" },
          { id: "5206", name: "An Trung", prefix: "X\u00e3" },
          { id: "5207", name: "An Vinh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "25014", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25015",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25016",
            name: "Qu\u1ed1c L\u1ed9 354",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25017",
            name: "T\u00e2y S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "338",
        name: "An Nh\u01a1n",
        wards: [
          {
            id: "5208",
            name: "B\u00ecnh \u0110\u1ecbnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "5209",
            name: "\u0110\u1eadp \u0110\u00e1",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "5210", name: "Nh\u01a1n An", prefix: "X\u00e3" },
          { id: "5211", name: "Nh\u01a1n H\u1ea1nh", prefix: "X\u00e3" },
          { id: "5212", name: "Nh\u01a1n H\u1eadu", prefix: "X\u00e3" },
          {
            id: "5213",
            name: "Nh\u01a1n H\u00f2a",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "5214",
            name: "Nh\u01a1n H\u01b0ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "5215", name: "Nh\u01a1n Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "5216", name: "Nh\u01a1n L\u1ed9c", prefix: "X\u00e3" },
          { id: "5217", name: "Nh\u01a1n M\u1ef9", prefix: "X\u00e3" },
          { id: "5218", name: "Nh\u01a1n Phong", prefix: "X\u00e3" },
          { id: "5219", name: "Nh\u01a1n Ph\u00fac", prefix: "X\u00e3" },
          { id: "5220", name: "Nh\u01a1n T\u00e2n", prefix: "X\u00e3" },
          {
            id: "5221",
            name: "Nh\u01a1n Th\u00e0nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "5222", name: "Nh\u01a1n Th\u1ecd", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "25018",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25019",
            name: "H\u1ed3ng L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25020",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25021",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25022",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25023",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25024",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25025", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25026",
            name: "Qu\u1ed1c L\u1ed9 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25027",
            name: "Qu\u1ed1c L\u1ed9 19B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25028",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25029",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25030",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25031",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25032", name: "Y\u1ebfn Lan", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "2928",
            name: "Khu d\u00e2n c\u01b0 B\u00e0n Th\u00e0nh",
            lat: "13.922771453857",
            lng: "109.09064483643",
          },
        ],
      },
      {
        id: "339",
        name: "Ho\u00e0i \u00c2n",
        wards: [
          { id: "5223", name: "\u00c2n \u0110\u1ee9c", prefix: "X\u00e3" },
          {
            id: "5224",
            name: "\u00c2n H\u1ea3o \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "5225", name: "\u00c2n H\u1ea3o T\u00e2y", prefix: "X\u00e3" },
          { id: "5226", name: "\u00c2n H\u1eefu", prefix: "X\u00e3" },
          { id: "5227", name: "\u00c2n M\u1ef9", prefix: "X\u00e3" },
          { id: "5228", name: "\u00c2n Ngh\u0129a", prefix: "X\u00e3" },
          { id: "5229", name: "\u00c2n Phong", prefix: "X\u00e3" },
          { id: "5230", name: "\u00c2n S\u01a1n", prefix: "X\u00e3" },
          { id: "5231", name: "\u00c2n Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "5232", name: "\u00c2n T\u00edn", prefix: "X\u00e3" },
          {
            id: "5233",
            name: "\u00c2n T\u01b0\u1eddng \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "5234",
            name: "\u00c2n T\u01b0\u1eddng T\u00e2y",
            prefix: "X\u00e3",
          },
          { id: "5235", name: "BokT\u01a1i", prefix: "X\u00e3" },
          { id: "5236", name: "\u0110akMang", prefix: "X\u00e3" },
          {
            id: "5237",
            name: "T\u0103ng B\u1ea1t H\u1ed5",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "340",
        name: "Ho\u00e0i Nh\u01a1n",
        wards: [
          {
            id: "5238",
            name: "B\u1ed3ng S\u01a1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "5239", name: "Ho\u00e0i Ch\u00e2u", prefix: "X\u00e3" },
          {
            id: "5240",
            name: "Ho\u00e0i Ch\u00e2u B\u1eafc",
            prefix: "X\u00e3",
          },
          { id: "5241", name: "Ho\u00e0i \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "5242", name: "Ho\u00e0i H\u1ea3i", prefix: "X\u00e3" },
          { id: "5243", name: "Ho\u00e0i H\u1ea3o", prefix: "X\u00e3" },
          { id: "5244", name: "Ho\u00e0i H\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "5245", name: "Ho\u00e0i M\u1ef9", prefix: "X\u00e3" },
          { id: "5246", name: "Ho\u00e0i Ph\u00fa", prefix: "X\u00e3" },
          { id: "5247", name: "Ho\u00e0i S\u01a1n", prefix: "X\u00e3" },
          { id: "5248", name: "Ho\u00e0i T\u00e2n", prefix: "X\u00e3" },
          { id: "5249", name: "Ho\u00e0i Thanh", prefix: "X\u00e3" },
          { id: "5250", name: "Ho\u00e0i Thanh T\u00e2y", prefix: "X\u00e3" },
          { id: "5251", name: "Ho\u00e0i Xu\u00e2n", prefix: "X\u00e3" },
          { id: "5252", name: "Tam Quan", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "5253", name: "Tam Quan B\u1eafc", prefix: "X\u00e3" },
          { id: "5254", name: "Tam Quan Nam", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "25033", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25034", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25035",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25036",
            name: "\u0110\u00e0o L\u1ed9 Di\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25037", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25038",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25039",
            name: "Thi\u1ebft \u0110\u00ednh B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25040",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25041",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "341",
        name: "Ph\u00f9 C\u00e1t",
        wards: [
          { id: "5255", name: "C\u00e1t Ch\u00e1nh", prefix: "X\u00e3" },
          { id: "5256", name: "C\u00e1t H\u1ea3i", prefix: "X\u00e3" },
          { id: "5257", name: "C\u00e1t Hanh", prefix: "X\u00e3" },
          { id: "5258", name: "C\u00e1t Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "5259", name: "C\u00e1t H\u01b0ng", prefix: "X\u00e3" },
          { id: "5260", name: "C\u00e1t Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "5261", name: "C\u00e1t L\u00e2m", prefix: "X\u00e3" },
          { id: "5262", name: "C\u00e1t Minh", prefix: "X\u00e3" },
          { id: "5263", name: "C\u00e1t Nh\u01a1n", prefix: "X\u00e3" },
          { id: "5264", name: "C\u00e1t T\u00e0i", prefix: "X\u00e3" },
          { id: "5265", name: "C\u00e1t Th\u1eafng", prefix: "X\u00e3" },
          { id: "5266", name: "C\u00e1t Th\u00e0nh", prefix: "X\u00e3" },
          { id: "5267", name: "C\u00e1t Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "5268", name: "C\u00e1t Trinh", prefix: "X\u00e3" },
          { id: "5269", name: "C\u00e1t T\u01b0\u1eddng", prefix: "X\u00e3" },
          {
            id: "5270",
            name: "Ng\u00f4 M\u00e2y",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          { id: "25042", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25043", name: "\u0110T 639", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25044",
            name: "Ng\u00f4 M\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25045",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25046", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25047",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25048",
            name: "T\u1ec9nh l\u1ed9 639",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25049",
            name: "T\u1ec9nh l\u1ed9 640",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "342",
        name: "Ph\u00f9 M\u1ef9",
        wards: [
          {
            id: "5271",
            name: "B\u00ecnh D\u01b0\u01a1ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "5272", name: "C\u00e1t Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "5273", name: "C\u00e1t S\u01a1n", prefix: "X\u00e3" },
          { id: "5274", name: "C\u00e1t T\u00e2n", prefix: "X\u00e3" },
          { id: "5275", name: "M\u1ef9 An", prefix: "X\u00e3" },
          { id: "5276", name: "M\u1ef9 C\u00e1t", prefix: "X\u00e3" },
          { id: "5277", name: "M\u1ef9 Ch\u00e1nh", prefix: "X\u00e3" },
          {
            id: "5278",
            name: "M\u1ef9 Ch\u00e1nh T\u00e2y",
            prefix: "X\u00e3",
          },
          { id: "5279", name: "M\u1ef9 Ch\u00e2u", prefix: "X\u00e3" },
          { id: "5280", name: "M\u1ef9 \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "5281", name: "M\u1ef9 Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "5282", name: "M\u1ef9 H\u00f2a", prefix: "X\u00e3" },
          { id: "5283", name: "M\u1ef9 L\u1ed9c", prefix: "X\u00e3" },
          { id: "5284", name: "M\u1ef9 L\u1ee3i", prefix: "X\u00e3" },
          { id: "5285", name: "M\u1ef9 Phong", prefix: "X\u00e3" },
          { id: "5286", name: "M\u1ef9 Quang", prefix: "X\u00e3" },
          { id: "5287", name: "M\u1ef9 T\u00e0i", prefix: "X\u00e3" },
          { id: "5288", name: "M\u1ef9 Th\u1eafng", prefix: "X\u00e3" },
          { id: "5289", name: "M\u1ef9 Th\u00e0nh", prefix: "X\u00e3" },
          { id: "5290", name: "M\u1ef9 Th\u1ecd", prefix: "X\u00e3" },
          { id: "5291", name: "M\u1ef9 Trinh", prefix: "X\u00e3" },
          {
            id: "5292",
            name: "Ph\u00f9 M\u1ef9",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          { id: "25050", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25051",
            name: "B\u00f9i \u0110i\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25052",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25053",
            name: "\u0110\u00e0o T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25054",
            name: "H\u00e0n M\u1eb7c T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25055",
            name: "Hu\u1ef3nh V\u0103n Th\u1ed1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25056",
            name: "L\u00e2m V\u0103n Th\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25057",
            name: "L\u00ea V\u0103n Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25058",
            name: "L\u00ea V\u0103n Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25059",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "25060",
            name: "Mai Xu\u00e2n Th\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25061",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25062",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25063",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25064",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25065",
            name: "Nguy\u1ec5n L\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25066",
            name: "Nguy\u1ec5n Nh\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25067",
            name: "Nguy\u1ec5n Ph\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25068",
            name: "Nguy\u1ec5n Qu\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25069",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25070",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25071",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25072",
            name: "T\u0103ng B\u1ea1t H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25073",
            name: "T\u1ec9nh l\u1ed9 632",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25074",
            name: "Tr\u01b0\u01a1ng V\u0103n C\u1ee7a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25075",
            name: "V\u00f5 Ng\u1ecdc H\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25076",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25077",
            name: "V\u00f5 Tr\u1ecdng Sanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25078",
            name: "V\u00f5 V\u0103n D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25079",
            name: "V\u0169 Th\u1ecb \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25080",
            name: "Xu\u00e2n Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "343",
        name: "Quy Nh\u01a1n",
        wards: [
          {
            id: "5293",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "5294",
            name: "\u0110\u1ed1ng \u0110a",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "5295",
            name: "Gh\u1ec1nh R\u00e1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "5296",
            name: "H\u1ea3i C\u1ea3ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "5297",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "5298", name: "L\u00ea L\u1ee3i", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "5299",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "5300", name: "Ng\u00f4 M\u00e2y", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "5301",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "5302", name: "Nh\u01a1n B\u00ecnh", prefix: "X\u00e3" },
          { id: "5303", name: "Nh\u01a1n Ch\u00e2u", prefix: "X\u00e3" },
          { id: "5304", name: "Nh\u01a1n H\u1ea3i", prefix: "X\u00e3" },
          { id: "5305", name: "Nh\u01a1n H\u1ed9i", prefix: "X\u00e3" },
          { id: "5306", name: "Nh\u01a1n L\u00fd", prefix: "X\u00e3" },
          {
            id: "5307",
            name: "Nh\u01a1n Ph\u00fa",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "5308", name: "Ph\u01b0\u1edbc M\u1ef9", prefix: "X\u00e3" },
          { id: "5309", name: "Quang Trung", prefix: "Ph\u01b0\u1eddng" },
          { id: "5310", name: "Th\u1ecb N\u1ea1i", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "5311",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "5312",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "5313",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "25081", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25082", name: "1/3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25083", name: "1/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25084", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25085", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25086", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25087", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25088", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25089", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25090", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25091", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25092", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25093", name: "31-3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25094", name: "5A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25095", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25096", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25097", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25098",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25099",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25100",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          { id: "25101", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "25102",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25103",
            name: "B\u00e3i D\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25104",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25105",
            name: "Bi\u00ean C\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25106",
            name: "B\u00ecnh H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25107",
            name: "B\u00ecnh H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25108",
            name: "B\u00f9i C\u1ea9m H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25109",
            name: "B\u00f9i \u0110i\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25110",
            name: "B\u00f9i \u0110\u1ee9c S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25111",
            name: "B\u00f9i Th\u1ecb Nh\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25112",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25113",
            name: "B\u00f9i Xu\u00e2n Ph\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25114",
            name: "C\u1ea7n V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25115",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25116",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25117",
            name: "C\u1ea7u \u0110en",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25118",
            name: "Ch\u00e0ng L\u00eda",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25119",
            name: "Ch\u1ebf Lan Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25120",
            name: "Chi L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25121",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25122",
            name: "Ch\u01b0\u01a1ng D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25138",
            name: "Di\u00ean H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25139",
            name: "Di\u00eau H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25147", name: "Duy T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25123",
            name: "\u0110\u1eb7ng L\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25124",
            name: "\u0110\u1eb7ng Thai Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25125",
            name: "\u0110\u1eb7ng Th\u00e1i Thuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25126",
            name: "\u0110\u1eb7ng Th\u00e0nh Ch\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25127",
            name: "\u0110\u1eb7ng Th\u00f9y Tr\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25128",
            name: "\u0110\u1eb7ng Ti\u1ebfn \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25129",
            name: "\u0110\u1eb7ng Tr\u1ea7n C\u00f4n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "25130",
            name: "\u0110\u1eb7ng V\u0103n Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25131",
            name: "\u0110\u1eb7ng V\u0103n Ng\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25132",
            name: "\u0110\u1eb7ng Xu\u00e2n Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25133",
            name: "\u0110\u00e0o Duy Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25134",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25135",
            name: "\u0110\u00e0o Phan Du\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25136",
            name: "\u0110\u00e0o T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25137",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25140",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25141", name: "\u0110inh Li\u1ec7t", prefix: "Ph\u1ed1" },
          {
            id: "25142",
            name: "\u0110inh Ru\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25143",
            name: "\u0110\u00f4 \u0110\u1ed1c B\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25144",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25145",
            name: "\u0110o\u00e0n V\u0103n \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25146",
            name: "\u0110\u1ed1ng \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25148",
            name: "H\u00e0 Huy Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25149",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25150", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "25151",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25152",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25153",
            name: "H\u00e0n M\u1eb7c T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25154",
            name: "H\u1ed3 Bi\u1ec3u Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25155",
            name: "H\u1ed3 \u0110\u1eafc Di",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25156", name: "Hoa L\u01b0", prefix: "Ph\u1ed1" },
          {
            id: "25157",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25158",
            name: "Ho\u00e0ng Minh Gi\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25159",
            name: "Ho\u00e0ng Minh Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25160",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25161",
            name: "Ho\u00e0ng V\u0103n Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25162",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25163",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25164",
            name: "Hu\u1ef3nh M\u1eabn \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25165",
            name: "Hu\u1ef3nh T\u1ea5n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25166",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25167",
            name: "Hu\u1ef3nh T\u1ecbnh C\u1ee7a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25168",
            name: "Hu\u1ef3nh V\u0103n Th\u1ed1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25169",
            name: "Kh\u00fac H\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25170",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25171",
            name: "La V\u0103n Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25172",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25173", name: "Lam S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25174",
            name: "L\u00e2m V\u0103n Th\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25175",
            name: "L\u00e2m V\u0103n T\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25176", name: "Lan Chi", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25177",
            name: "L\u00ea C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25178",
            name: "L\u00ea C\u00f4ng Mi\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25183",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25179",
            name: "L\u00ea \u0110\u1ea1i Cang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25180",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25181",
            name: "L\u00ea \u0110\u00ecnh Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25182",
            name: "L\u00ea \u0110\u00ecnh L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25184",
            name: "L\u00ea \u0110\u1ee9c Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25185",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25186",
            name: "L\u00ea H\u1eefu Ki\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25187", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25188",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25189",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25190",
            name: "L\u00ea Th\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25191", name: "L\u00ea Thanh Ngh\u1ecb", prefix: "Ph\u1ed1" },
          {
            id: "25192",
            name: "L\u00ea Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25193",
            name: "L\u00ea Th\u1ecb Khu\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25194",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "25195",
            name: "L\u00ea V\u0103n Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25196",
            name: "L\u00ea V\u0103n H\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "25197",
            name: "L\u00ea V\u0103n Th\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25198",
            name: "L\u00ea V\u0103n T\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25199", name: "L\u1eef Gia", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25200",
            name: "L\u01b0\u01a1ng \u0110\u1ecbnh C\u1ee7a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "25201",
            name: "L\u01b0\u01a1ng Nh\u1eef H\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25202",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25203",
            name: "L\u01b0u H\u1eefu Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25204",
            name: "L\u01b0u V\u0103n Lang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25205",
            name: "L\u00fd Ch\u00ednh Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25206",
            name: "L\u00fd T\u1ebf Xuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25207",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25208",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "25209",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25210",
            name: "L\u00fd V\u0103n B\u01b0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25211",
            name: "Mai An Ti\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25212",
            name: "Mai H\u1eafc \u0110\u1ebf",
            prefix: "Ph\u1ed1",
          },
          {
            id: "25213",
            name: "Mai Xu\u00e2n Th\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25214", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25215", name: "Nam Cao", prefix: "Ph\u1ed1" },
          {
            id: "25216",
            name: "Ng\u00f4 B\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25217",
            name: "Ng\u00f4 \u0110\u1ee9c \u0110\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25218",
            name: "Ng\u00f4 Gia Kh\u1ea3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25219",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25220",
            name: "Ng\u00f4 L\u00ea T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25221",
            name: "Ng\u00f4 M\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25222",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25223",
            name: "Ng\u00f4 T\u1ea5t T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25224",
            name: "Ng\u00f4 Th\u00ec S\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25225",
            name: "Ng\u00f4 Th\u1eddi Nhi\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25226",
            name: "Ng\u00f4 Tr\u1ecdng Thi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25227",
            name: "Ng\u00f4 V\u0103n S\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25228",
            name: "Ng\u1ecdc H\u00e2n C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25229",
            name: "Nguy\u1ec5n B\u00e1 Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25230",
            name: "Nguy\u1ec5n B\u00e1 Tuy\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25231",
            name: "Nguy\u1ec5n B\u00e8o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25232",
            name: "Nguy\u1ec5n B\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25233",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25234",
            name: "Nguy\u1ec5n C\u1ea3nh Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25235",
            name: "Nguy\u1ec5n Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25236",
            name: "Nguy\u1ec5n C\u01a1 Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25237",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25238",
            name: "Nguy\u1ec5n C\u01b0 Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25240",
            name: "Nguy\u1ec5n Di\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25243",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25244",
            name: "Nguy\u1ec5n D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25239",
            name: "Nguy\u1ec5n \u0110\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25241",
            name: "Nguy\u1ec5n \u0110\u00ecnh Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25242",
            name: "Nguy\u1ec5n \u0110\u00ecnh Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25245",
            name: "Nguy\u1ec5n Gia Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25246",
            name: "Nguy\u1ec5n Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25247",
            name: "Nguy\u1ec5n Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25248",
            name: "Nguy\u1ec5n H\u1ed3ng \u0110\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25249",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25250",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25251",
            name: "Nguy\u1ec5n H\u1eefu Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25252",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25253",
            name: "Nguy\u1ec5n Kho\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25254",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25255",
            name: "Nguy\u1ec5n L\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25256",
            name: "Nguy\u1ec5n L\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25257", name: "Nguy\u1ec5n M\u00e2n", prefix: "Ph\u1ed1" },
          {
            id: "25258",
            name: "Nguy\u1ec5n Nghi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25259",
            name: "Nguy\u1ec5n Nh\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25260",
            name: "Nguy\u1ec5n Phong S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25261",
            name: "Nguy\u1ec5n Qu\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25262",
            name: "Nguy\u1ec5n Qu\u00fd \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25263",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25264",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25265",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25266",
            name: "Nguy\u1ec5n Thanh Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25267",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25268",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25269",
            name: "Nguy\u1ec5n Th\u1ecb Y\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25270",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "25271",
            name: "Nguy\u1ec5n Thi\u1ebfp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25272",
            name: "Nguy\u1ec5n Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25273",
            name: "Nguy\u1ec5n Tr\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25274",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25275",
            name: "Nguy\u1ec5n Trung Ng\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25276",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25277",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25278",
            name: "Nguy\u1ec5n T\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25279",
            name: "Nguy\u1ec5n T\u1ef1 Nh\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25280",
            name: "Nguy\u1ec5n V\u0103n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25281",
            name: "Nguy\u1ec5n V\u0103n B\u00e9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25282",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25283",
            name: "Nguy\u1ec5n V\u0103n Huy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25284",
            name: "Nguy\u1ec5n V\u0103n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25285",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25286",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25287",
            name: "Nguy\u1ec5n Xu\u00e2n Nh\u0129",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25288",
            name: "Ph\u1ea1m C\u1ea7n Ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25289",
            name: "Ph\u1ea1m C\u1ef1 L\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25290",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25291",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25292", name: "Ph\u1ea1m Huy Th\u00f4ng", prefix: "Ph\u1ed1" },
          {
            id: "25293",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25294",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25295",
            name: "Ph\u1ea1m Ph\u00fa Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25296",
            name: "Ph\u1ea1m Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25297",
            name: "Ph\u1ea1m Th\u1ebf Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25298",
            name: "Ph\u1ea1m Th\u1ecb \u0110\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25299", name: "Ph\u1ea1m Tu", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25300",
            name: "Ph\u1ea1m V\u0103n L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25301",
            name: "Phan B\u00e1 V\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25302",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25303",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25304",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25305",
            name: "Phan Th\u00fac Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25306",
            name: "Phan Tr\u1ecdng Tu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25307",
            name: "Ph\u00f3 \u0110\u1ee9c Ch\u00ednh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "25308",
            name: "Ph\u00f9ng Kh\u1eafc Khoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25309",
            name: "Qu\u1ed1c L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25310",
            name: "Qu\u1ed1c L\u1ed9 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25311",
            name: "Qu\u1ed1c L\u1ed9 19B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25312",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25313",
            name: "Qu\u1ed1c l\u1ed9 1D",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25314", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25315", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25316", name: "S\u1ed1 15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25317", name: "S\u1ed1 19B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25318", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25319", name: "S\u1ed1 26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25320", name: "S\u1ed1 27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25321", name: "S\u1ed1 28", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25322",
            name: "S\u01b0 \u0111o\u00e0n 3 Sao V\u00e0ng",
            prefix: "Ph\u1ed1",
          },
          { id: "25323", name: "T\u1ea1 Quang B\u1eedu", prefix: "Ph\u1ed1" },
          {
            id: "25324",
            name: "T\u0103ng B\u1ea1t H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25325",
            name: "T\u00e2y S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25326",
            name: "Th\u00e1i V\u0103n Lung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25327",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25328",
            name: "Th\u00e0nh Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25329",
            name: "Th\u00e1p \u0110\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25330", name: "Thi S\u00e1ch", prefix: "Ph\u1ed1" },
          {
            id: "25331",
            name: "Tho\u1ea1i Ng\u1ecdc H\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25332",
            name: "Th\u00f4ng Tin",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25333",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25334",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25335",
            name: "T\u1ed1 H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25336",
            name: "T\u00f4 Ng\u1ecdc V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25337",
            name: "T\u00f4n \u0110\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25338",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "25339",
            name: "T\u00f4n Th\u1ea5t B\u00e1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25340",
            name: "T\u00f4n Th\u1ea5t \u0110\u1ea1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25341",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25342",
            name: "T\u1ed1ng Ph\u01b0\u1edbc Ph\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25343",
            name: "Tr\u1ea7n An T\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25344",
            name: "Tr\u1ea7n Anh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25345",
            name: "Tr\u1ea7n B\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25346",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25347",
            name: "Tr\u1ea7n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25348",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25349",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "25350",
            name: "Tr\u1ea7n \u0110\u0103ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25351",
            name: "Tr\u1ea7n \u0110\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25352",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25353",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25354",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25355",
            name: "Tr\u1ea7n Nguy\u00ean \u0110\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25356",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25357",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25358",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25359",
            name: "Tr\u1ea7n Qu\u00fd Kho\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25360",
            name: "Tr\u1ea7n Th\u1ecb K\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25361",
            name: "Tr\u1ea7n Th\u1ecb Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25362",
            name: "Tr\u1ea7n Th\u1ecb L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25363",
            name: "Tr\u1ea7n V\u0103n Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25364",
            name: "Tr\u1ea7n V\u0103n K\u1ef7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25365",
            name: "Tr\u1ea7n V\u0103n \u01a0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25366",
            name: "Tr\u1ea7n Xu\u00e2n So\u1ea1n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "25367",
            name: "Tr\u1ea1ng Qu\u1ef3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25368",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25369",
            name: "Tr\u01b0\u01a1ng V\u0129nh K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25370",
            name: "T\u00fa M\u1ee1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25372",
            name: "V\u00f5 Duy D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25371",
            name: "V\u00f5 \u0110\u00ecnh T\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25373", name: "V\u00f5 Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25374",
            name: "V\u00f5 M\u01b0\u1eddi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25375",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25376",
            name: "V\u00f5 S\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25377",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25378",
            name: "V\u00f5 Th\u1ecb Y\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25379",
            name: "V\u00f5 V\u0103n D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25380",
            name: "V\u0169 B\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25381",
            name: "V\u0169 \u0110\u00ecnh Hu\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25382",
            name: "Xu\u00e2n Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25383",
            name: "Xu\u00e2n Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25384", name: "\u1ef6 Lan", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "2929",
            name: "An Ph\u00fa Th\u1ecbnh",
            lat: "13.805950164795",
            lng: "109.21532440186",
          },
          {
            id: "2930",
            name: "FLC Quy Nh\u01a1n",
            lat: "13.883078575134",
            lng: "109.28057861328",
          },
          {
            id: "2931",
            name: "FLC SeaTower",
            lat: "13.753498077393",
            lng: "109.21636962891",
          },
          {
            id: "2932",
            name: "Green Home",
            lat: "13.779040336609",
            lng: "109.1487121582",
          },
          {
            id: "2933",
            name: "Ho\u00e0ng Anh Quy Nh\u01a1n",
            lat: "13.778714179993",
            lng: "109.23384094238",
          },
          {
            id: "2934",
            name: "TMS Luxury Hotel & Residences",
            lat: "13.767509460449",
            lng: "109.22742462158",
          },
        ],
      },
      {
        id: "344",
        name: "T\u00e2y S\u01a1n",
        wards: [
          { id: "5314", name: "B\u00ecnh H\u00f2a", prefix: "X\u00e3" },
          { id: "5315", name: "B\u00ecnh Nghi", prefix: "X\u00e3" },
          { id: "5316", name: "B\u00ecnh T\u00e2n", prefix: "X\u00e3" },
          { id: "5317", name: "B\u00ecnh Th\u00e0nh", prefix: "X\u00e3" },
          { id: "5318", name: "B\u00ecnh Thu\u1eadn", prefix: "X\u00e3" },
          { id: "5319", name: "B\u00ecnh T\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "5320", name: "Ph\u00fa Phong", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "5321", name: "T\u00e2y An", prefix: "X\u00e3" },
          { id: "5322", name: "T\u00e2y B\u00ecnh", prefix: "X\u00e3" },
          { id: "5323", name: "T\u00e2y Giang", prefix: "X\u00e3" },
          { id: "5324", name: "T\u00e2y Ph\u00fa", prefix: "X\u00e3" },
          { id: "5325", name: "T\u00e2y Thu\u1eadn", prefix: "X\u00e3" },
          { id: "5326", name: "T\u00e2y Vinh", prefix: "X\u00e3" },
          { id: "5327", name: "T\u00e2y Xu\u00e2n", prefix: "X\u00e3" },
          { id: "5328", name: "V\u0129nh An", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "25385", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25386",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25387",
            name: "\u0110\u1ed1ng \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25388",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25389",
            name: "Ph\u00fa Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25390",
            name: "Qu\u1ea3ng Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25391",
            name: "Qu\u1ed1c L\u1ed9 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25392",
            name: "T\u1ec9nh l\u1ed9 636",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "345",
        name: "Tuy Ph\u01b0\u1edbc",
        wards: [
          {
            id: "5329",
            name: "Di\u00eau Tr\u00ec",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "5330", name: "Ph\u01b0\u1edbc An", prefix: "X\u00e3" },
          { id: "5331", name: "Ph\u01b0\u1edbc Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "5332", name: "Ph\u01b0\u1edbc H\u00f2a", prefix: "X\u00e3" },
          { id: "5333", name: "Ph\u01b0\u1edbc H\u01b0ng", prefix: "X\u00e3" },
          { id: "5334", name: "Ph\u01b0\u1edbc L\u1ed9c", prefix: "X\u00e3" },
          { id: "5335", name: "Ph\u01b0\u1edbc Ngh\u0129a", prefix: "X\u00e3" },
          { id: "5336", name: "Ph\u01b0\u1edbc Quang", prefix: "X\u00e3" },
          { id: "5337", name: "Ph\u01b0\u1edbc S\u01a1n", prefix: "X\u00e3" },
          { id: "5338", name: "Ph\u01b0\u1edbc Th\u1eafng", prefix: "X\u00e3" },
          { id: "5339", name: "Ph\u01b0\u1edbc Th\u00e0nh", prefix: "X\u00e3" },
          { id: "5340", name: "Ph\u01b0\u1edbc Thu\u1eadn", prefix: "X\u00e3" },
          {
            id: "5341",
            name: "Tuy Ph\u01b0\u1edbc",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          { id: "25393", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25394", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25395", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25396",
            name: "Bi\u00ean C\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25397",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25398",
            name: "\u0110\u00e0o T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25399",
            name: "M\u1ef9 Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25400",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25401",
            name: "Qu\u1ed1c L\u1ed9 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25402",
            name: "Qu\u1ed1c l\u1ed9 19C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25403",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25404", name: "S\u1ed1 10B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25405",
            name: "T\u0103ng B\u1ea1t H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25406",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25407",
            name: "T\u1ec9nh l\u1ed9 19C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25408",
            name: "T\u1ec9nh L\u1ed9 638",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25409",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "346",
        name: "V\u00e2n Canh",
        wards: [
          { id: "5342", name: "Canh Hi\u1ec3n", prefix: "X\u00e3" },
          { id: "5343", name: "Canh Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "5344", name: "Canh H\u00f2a", prefix: "X\u00e3" },
          { id: "5345", name: "Canh Li\u00ean", prefix: "X\u00e3" },
          { id: "5346", name: "Canh Thu\u1eadn", prefix: "X\u00e3" },
          { id: "5347", name: "Canh Vinh", prefix: "X\u00e3" },
          { id: "5348", name: "V\u00e2n Canh", prefix: "Th\u1ecb tr\u1ea5n" },
        ],
        streets: [
          {
            id: "25410",
            name: "T\u1ec9nh L\u1ed9 638",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "347",
        name: "V\u0129nh Th\u1ea1nh",
        wards: [
          { id: "5349", name: "V\u0129nh H\u1ea3o", prefix: "X\u00e3" },
          { id: "5350", name: "V\u0129nh Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "5351", name: "V\u0129nh H\u00f2a", prefix: "X\u00e3" },
          { id: "5352", name: "V\u0129nh Kim", prefix: "X\u00e3" },
          { id: "5353", name: "V\u0129nh Quang", prefix: "X\u00e3" },
          { id: "5354", name: "V\u0129nh S\u01a1n", prefix: "X\u00e3" },
          {
            id: "5355",
            name: "V\u0129nh Th\u1ea1nh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "5356", name: "V\u0129nh Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "5357", name: "V\u0129nh Thu\u1eadn", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
    ],
  },
  {
    id: "26",
    code: "TG",
    name: "Ti\u1ec1n Giang",
    districts: [
      {
        id: "348",
        name: "C\u00e1i B\u00e8",
        wards: [
          { id: "5358", name: "An C\u01b0", prefix: "X\u00e3" },
          { id: "5359", name: "An H\u1eefu", prefix: "X\u00e3" },
          {
            id: "5360",
            name: "An Th\u00e1i \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "5361", name: "An Th\u00e1i Trung", prefix: "X\u00e3" },
          {
            id: "5362",
            name: "C\u00e1i B\u00e8",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "5363",
            name: "\u0110\u00f4ng H\u00f2a Hi\u1ec7p",
            prefix: "X\u00e3",
          },
          {
            id: "5364",
            name: "H\u1eadu M\u1ef9 B\u1eafc A",
            prefix: "X\u00e3",
          },
          {
            id: "5365",
            name: "H\u1eadu M\u1ef9 B\u1eafc B",
            prefix: "X\u00e3",
          },
          { id: "5366", name: "H\u1eadu M\u1ef9 Ph\u00fa", prefix: "X\u00e3" },
          { id: "5367", name: "H\u1eadu M\u1ef9 Trinh", prefix: "X\u00e3" },
          { id: "5368", name: "H\u1eadu Th\u00e0nh", prefix: "X\u00e3" },
          { id: "5369", name: "H\u00f2a H\u01b0ng", prefix: "X\u00e3" },
          { id: "5370", name: "H\u00f2a Kh\u00e1nh", prefix: "X\u00e3" },
          {
            id: "5371",
            name: "M\u1ef9 \u0110\u1ee9c \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "5372",
            name: "M\u1ef9 \u0110\u1ee9c T\u00e2y",
            prefix: "X\u00e3",
          },
          { id: "5373", name: "M\u1ef9 H\u1ed9i", prefix: "X\u00e3" },
          { id: "5374", name: "M\u1ef9 L\u1ee3i A", prefix: "X\u00e3" },
          { id: "5375", name: "M\u1ef9 L\u1ee3i B", prefix: "X\u00e3" },
          { id: "5376", name: "M\u1ef9 L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "5377", name: "M\u1ef9 T\u00e2n", prefix: "X\u00e3" },
          { id: "5378", name: "M\u1ef9 Trung", prefix: "X\u00e3" },
          { id: "5379", name: "T\u00e2n H\u01b0ng", prefix: "X\u00e3" },
          { id: "5380", name: "T\u00e2n Thanh", prefix: "X\u00e3" },
          { id: "5381", name: "Thi\u1ec7n Tr\u00ed", prefix: "X\u00e3" },
          { id: "5382", name: "Thi\u1ec7n Trung", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "25411", name: "23A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25412", name: "23B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25413", name: "30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25414", name: "75", prefix: "Ph\u1ed1" },
          { id: "25415", name: "77", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25416", name: "875", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25417",
            name: "B\u1ea3o \u0110\u1ecbnh Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25418",
            name: "C\u00f4 B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25419",
            name: "C\u1ed5 C\u00f2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25420",
            name: "C\u00f4 Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25421",
            name: "\u0110o\u00e0n Th\u1ecb Nghi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25422",
            name: "\u0110\u1ed1c Binh Ki\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25423",
            name: "Ho\u00e0ng Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25424",
            name: "K\u00eanh 6 B\u1eb1ng L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25425", name: "K\u00eanh 8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25426",
            name: "L\u00e3nh Binh C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25427",
            name: "L\u00ea Th\u1ecb Kim Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25428",
            name: "L\u00ea V\u0103n Duy\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25429",
            name: "L\u00ea V\u0103n Duy\u1ec7t A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25430",
            name: "L\u00ea V\u0103n Duy\u1ec7t B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25431",
            name: "M\u1ef9 L\u1ee3i A-B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25432",
            name: "M\u1ef9 T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25433",
            name: "Ng\u00f4 V\u0103n Nh\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25434",
            name: "Nguy\u1ec5n Ch\u00ed C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25435",
            name: "Nguy\u1ec5n Minh \u0110\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25436",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25437",
            name: "Nguy\u1ec5n V\u0103n B\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25438",
            name: "Nguy\u1ec5n V\u0103n Th\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25439",
            name: "Nguy\u1ec5n V\u0103n T\u1ed1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25440",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25441",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25442",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25443",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25444",
            name: "Phan V\u0103n Ba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25445",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25446",
            name: "T\u1ea1 Thu Th\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25447",
            name: "T\u00e2n H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25448",
            name: "Th\u00e1i V\u0103n T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25449",
            name: "Thi\u00ean H\u1ed9 D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25450",
            name: "T\u1ec9nh l\u1ed9 827",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25451",
            name: "T\u1ec9nh l\u1ed9 861",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25452",
            name: "T\u1ec9nh l\u1ed9 863",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25453",
            name: "T\u1ec9nh l\u1ed9 864",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25454",
            name: "T\u1ec9nh l\u1ed9 865",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25455",
            name: "T\u1ec9nh l\u1ed9 868",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25456",
            name: "T\u1ec9nh l\u1ed9 869",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25457",
            name: "Tr\u01b0ng N\u1eef V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25458",
            name: "V\u00f5 V\u0103n Gi\u00e1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25459",
            name: "X\u1ebbo M\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "349",
        name: "Cai L\u1eady",
        wards: [
          { id: "5383", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "5384", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "5385", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "5386", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "5387", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "5388", name: "Long Kh\u00e1nh", prefix: "X\u00e3" },
          {
            id: "5389",
            name: "M\u1ef9 H\u1ea1nh \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "5390", name: "M\u1ef9 H\u1ea1nh T\u00e2y", prefix: "X\u00e3" },
          {
            id: "5391",
            name: "M\u1ef9 Ph\u01b0\u1edbc T\u00e2y",
            prefix: "X\u00e3",
          },
          { id: "5392", name: "Nhi M\u1ef9", prefix: "Ph\u01b0\u1eddng" },
          { id: "5393", name: "Nh\u1ecb Qu\u00fd", prefix: "X\u00e3" },
          { id: "5394", name: "Ph\u00fa Qu\u00fd", prefix: "X\u00e3" },
          { id: "5395", name: "T\u00e2n B\u00ecnh", prefix: "X\u00e3" },
          { id: "5396", name: "T\u00e2n H\u1ed9i", prefix: "X\u00e3" },
          { id: "5397", name: "T\u00e2n Ph\u00fa", prefix: "X\u00e3" },
          { id: "5398", name: "Thanh H\u00f2a", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "25460",
            name: "B\u1ebfn C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25461",
            name: "Cao \u0110\u0103ng Chi\u1ebfm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25462",
            name: "\u0110\u1eb7ng V\u0103n B\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25463",
            name: "\u00d4ng Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25464",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25465",
            name: "Thanh T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25466",
            name: "T\u1ec9nh l\u1ed9 868",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25467",
            name: "Tr\u1ea7n Xu\u00e2n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25468",
            name: "T\u1ee9 Ki\u1ec7t B",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "350",
        name: "Ch\u00e2u Th\u00e0nh",
        wards: [
          { id: "5399", name: "B\u00e0n Long", prefix: "X\u00e3" },
          { id: "5400", name: "B\u00ecnh \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "5401", name: "B\u00ecnh Tr\u01b0ng", prefix: "X\u00e3" },
          {
            id: "5404",
            name: "D\u01b0\u1ee1ng \u0110i\u1ec1m",
            prefix: "X\u00e3",
          },
          { id: "5402", name: "\u0110i\u1ec1m Hy", prefix: "X\u00e3" },
          { id: "5403", name: "\u0110\u00f4ng H\u00f2a", prefix: "X\u00e3" },
          { id: "5405", name: "H\u1ef1u \u0110\u1ea1o", prefix: "X\u00e3" },
          { id: "5406", name: "Kim S\u01a1n", prefix: "X\u00e3" },
          { id: "5407", name: "Long An", prefix: "X\u00e3" },
          { id: "5408", name: "Long \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "5409", name: "Long H\u01b0ng", prefix: "X\u00e3" },
          { id: "5410", name: "Nh\u1ecb B\u00ecnh", prefix: "X\u00e3" },
          { id: "5411", name: "Ph\u00fa Phong", prefix: "X\u00e3" },
          { id: "5412", name: "Song Thu\u1eadn", prefix: "X\u00e3" },
          { id: "5413", name: "Tam Hi\u1ec7p", prefix: "X\u00e3" },
          {
            id: "5414",
            name: "T\u00e2n Hi\u1ec7p",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "5415",
            name: "T\u00e2n H\u1ed9i \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "5416", name: "T\u00e2n H\u01b0\u01a1ng", prefix: "X\u00e3" },
          {
            id: "5417",
            name: "T\u00e2n L\u00fd \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "5418", name: "T\u00e2n L\u00fd T\u00e2y", prefix: "X\u00e3" },
          {
            id: "5419",
            name: "Th\u00e2n C\u1eedu Ngh\u0129a",
            prefix: "X\u00e3",
          },
          { id: "5420", name: "Th\u1ea1nh Ph\u00fa", prefix: "X\u00e3" },
          { id: "5421", name: "V\u0129nh Kim", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "25469", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25470", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25471", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25472", name: "33", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25473", name: "34", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25474", name: "35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25475", name: "36", prefix: "Ph\u1ed1" },
          { id: "25476", name: "37", prefix: "Ph\u1ed1" },
          { id: "25477", name: "38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25478", name: "DH32", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25479", name: "\u0110T 876", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25480",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25481",
            name: "H\u01b0\u01a1ng l\u1ed9 18",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25482",
            name: "K\u00eanh L\u1ed9 D\u00e2y Th\u00e9p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25483",
            name: "L\u1ed9 C\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25484",
            name: "\u00d4ng Th\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25485",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25486",
            name: "Qu\u1ed1c l\u1ed9 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25487",
            name: "Song H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25488",
            name: "T\u00e2n L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25489",
            name: "T\u00e2n Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25490",
            name: "T\u1ec9nh l\u1ed9 864",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25491",
            name: "T\u1ec9nh l\u1ed9 866",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25492",
            name: "T\u1ec9nh l\u1ed9 866B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25493",
            name: "T\u1ec9nh l\u1ed9 867",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25494",
            name: "T\u1ec9nh l\u1ed9 868",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25495",
            name: "T\u1ec9nh l\u1ed9 870",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25496",
            name: "T\u1ec9nh l\u1ed9 874",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25497",
            name: "T\u1ec9nh l\u1ed9 876",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25498",
            name: "T\u1ec9nh l\u1ed9 878",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25499",
            name: "T\u1ec9nh l\u1ed9 878B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25500",
            name: "Tr\u1ea7n V\u0103n \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25501", name: "Wl1", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
      {
        id: "351",
        name: "Ch\u1ee3 G\u1ea1o",
        wards: [
          { id: "5422", name: "An Th\u1ea1nh Th\u1ee7y", prefix: "X\u00e3" },
          { id: "5423", name: "B\u00ecnh Ninh", prefix: "X\u00e3" },
          { id: "5424", name: "B\u00ecnh Phan", prefix: "X\u00e3" },
          {
            id: "5425",
            name: "B\u00ecnh Ph\u1ee5c Nh\u1ee9t",
            prefix: "X\u00e3",
          },
          {
            id: "5426",
            name: "Ch\u1ee3 G\u1ea1o",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "5427",
            name: "\u0110\u0103ng H\u01b0ng Ph\u01b0\u1edbc",
            prefix: "X\u00e3",
          },
          { id: "5428", name: "H\u00f2a \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "5429", name: "H\u00f2a T\u1ecbnh", prefix: "X\u00e3" },
          {
            id: "5430",
            name: "Long B\u00ecnh \u0110i\u1ec1n",
            prefix: "X\u00e3",
          },
          {
            id: "5431",
            name: "L\u01b0\u01a1ng H\u00f2a L\u1ea1c",
            prefix: "X\u00e3",
          },
          { id: "5432", name: "M\u1ef9 T\u1ecbnh An", prefix: "X\u00e3" },
          { id: "5433", name: "Ph\u00fa Ki\u1ebft", prefix: "X\u00e3" },
          { id: "5434", name: "Qu\u01a1n Long", prefix: "X\u00e3" },
          { id: "5435", name: "Song B\u00ecnh", prefix: "X\u00e3" },
          {
            id: "5436",
            name: "T\u00e2n B\u00ecnh Th\u1ea1nh",
            prefix: "X\u00e3",
          },
          {
            id: "5437",
            name: "T\u00e2n Thu\u1eadn B\u00ecnh",
            prefix: "X\u00e3",
          },
          { id: "5438", name: "Thanh B\u00ecnh", prefix: "X\u00e3" },
          { id: "5439", name: "Trung H\u00f2a", prefix: "X\u00e3" },
          { id: "5440", name: "Xu\u00e2n \u0110\u00f4ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "25502", name: "12A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25503", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25504", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25505", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25506", name: "24", prefix: "Ph\u1ed1" },
          { id: "25507", name: "25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25508", name: "26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25509", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25510", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25511", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25512", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25513", name: "8/3", prefix: "Ph\u1ed1" },
          {
            id: "25514",
            name: "An Kh\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25515",
            name: "C\u1ea3 Qu\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25517", name: "DH25", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25516",
            name: "\u0110\u00e1 Xanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25518",
            name: "\u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25519",
            name: "Huy\u1ec7n l\u1ed9 24B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25520",
            name: "Huy\u1ec7n l\u1ed9 24C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25521",
            name: "Huy\u1ec7n l\u1ed9 25C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25522",
            name: "Huy\u1ec7n l\u1ed9 28B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25523",
            name: "K\u00eanh Ngang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25524",
            name: "Nguy\u1ec5n \u0110\u1eafc Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25525",
            name: "\u00d42 Khu 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25526",
            name: "Qu\u1ed1c L\u1ed9 50",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25527", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25528", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25529",
            name: "Thanh H\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25530",
            name: "T\u1ecbnh H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25531",
            name: "T\u1ec9nh l\u1ed9 25B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25532",
            name: "T\u1ec9nh l\u1ed9 877",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25533",
            name: "T\u1ec9nh l\u1ed9 878B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25534",
            name: "T\u1ec9nh l\u1ed9 879",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25535",
            name: "T\u1ec9nh l\u1ed9 879B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25536",
            name: "T\u1ec9nh l\u1ed9 879C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25537", name: "V\u00e0m", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
      {
        id: "352",
        name: "G\u00f2 C\u00f4ng",
        wards: [
          { id: "5441", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "5442", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "5443", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "5444", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "5445", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "5446", name: "B\u00ecnh \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "5447", name: "B\u00ecnh Xu\u00e2n", prefix: "X\u00e3" },
          { id: "5448", name: "Long Ch\u00e1nh", prefix: "X\u00e3" },
          { id: "5449", name: "Long H\u00f2a", prefix: "X\u00e3" },
          { id: "5450", name: "Long H\u01b0ng", prefix: "X\u00e3" },
          { id: "5451", name: "Long Th\u00e0nh", prefix: "X\u00e3" },
          { id: "5452", name: "Long Thu\u1eadn", prefix: "X\u00e3" },
          { id: "5453", name: "T\u00e2n Trung", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "25538", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25539", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25540", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25541", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25542", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25543",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25544",
            name: "Ch\u1ee3 M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25545",
            name: "\u0110\u00ea Bao C\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25546",
            name: "\u0110\u1ed3ng Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25547", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "25548",
            name: "H\u1ed3 Bi\u1ec3u Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25549",
            name: "Ho\u00e0ng Tuy\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25550",
            name: "K\u00eanh N\u0103m C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25551",
            name: "L\u0103ng Ho\u00e0ng Gia",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25552",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25553",
            name: "L\u00ea Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25554",
            name: "Long Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25555",
            name: "L\u01b0u Th\u1ecb Dung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25556",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "25557",
            name: "M\u1ea1c V\u0103n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25558",
            name: "Ng\u00f4 T\u00f9ng Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25559",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25560",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25561",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25562",
            name: "Nguy\u1ec5n Th\u00ecn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25563",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25564",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25565",
            name: "Nguy\u1ec5n Tr\u1ecdng D\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25566",
            name: "Nguy\u1ec5n Tr\u1ecdng H\u1ee3p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25567",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25568",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25569",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25570",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25571",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25572",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25573",
            name: "Ph\u00f9ng Thanh V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25574",
            name: "Qu\u1ed1c L\u1ed9 50",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25575",
            name: "R\u1ea1ch G\u1ea7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25576",
            name: "T\u00e2n \u0110\u00f4ng C\u1ea7u B\u00e0 Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25577",
            name: "T\u1ebft M\u1eadu Th\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25578",
            name: "Th\u1ee7 Khoa Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25579",
            name: "T\u1ec9nh l\u1ed9 862",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25580",
            name: "T\u1ec9nh l\u1ed9 873",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25581",
            name: "T\u1ec9nh l\u1ed9 877",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25582",
            name: "Tr\u1ea7n C\u00f4ng T\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25583",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25584",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25585",
            name: "T\u1eeb D\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25586",
            name: "V\u00f5 Duy Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25587",
            name: "V\u00f5 Th\u1ecb L\u1edb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25588",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "353",
        name: "G\u00f2 C\u00f4ng \u0110\u00f4ng",
        wards: [
          { id: "5454", name: "B\u00ecnh \u00c2n", prefix: "X\u00e3" },
          { id: "5455", name: "B\u00ecnh Ngh\u1ecb", prefix: "X\u00e3" },
          { id: "5456", name: "Gia Thu\u1eadn", prefix: "X\u00e3" },
          { id: "5457", name: "Ki\u1ec3ng Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "5458", name: "Ph\u01b0\u1edbc Trung", prefix: "X\u00e3" },
          { id: "5459", name: "T\u00e2n \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "5460", name: "T\u00e2n \u0110\u00f4ng", prefix: "X\u00e3" },
          {
            id: "5461",
            name: "T\u00e2n H\u00f2a",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "5462", name: "T\u00e2n Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "5463", name: "T\u00e2n T\u00e2y", prefix: "X\u00e3" },
          { id: "5464", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "5465", name: "T\u0103ng H\u00f2a", prefix: "X\u00e3" },
          {
            id: "5466",
            name: "V\u00e0m L\u00e1ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          { id: "25589", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25590", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25591", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25592", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25593", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25594", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25595", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25596",
            name: "\u0110\u1ed9i Sai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25597",
            name: "M\u1ea1c V\u0103n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25598",
            name: "Nguy\u1ec5n Duy Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25599",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25600",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25601",
            name: "Qu\u1ed1c L\u1ed9 50",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25602",
            name: "Th\u1ee7 Khoa Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25603",
            name: "T\u1ec9nh l\u1ed9 862",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25604",
            name: "T\u1ec9nh l\u1ed9 871",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25605",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25606",
            name: "V\u00f5 Duy Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "354",
        name: "G\u00f2 C\u00f4ng T\u00e2y",
        wards: [
          { id: "5467", name: "B\u00ecnh Nh\u00ec", prefix: "X\u00e3" },
          { id: "5468", name: "B\u00ecnh Ph\u00fa", prefix: "X\u00e3" },
          { id: "5469", name: "B\u00ecnh T\u00e2n", prefix: "X\u00e3" },
          { id: "5470", name: "\u0110\u1ed3ng S\u01a1n", prefix: "X\u00e3" },
          { id: "5471", name: "\u0110\u1ed3ng Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "5472", name: "Long B\u00ecnh", prefix: "X\u00e3" },
          { id: "5473", name: "Long V\u0129nh", prefix: "X\u00e3" },
          { id: "5474", name: "Th\u00e0nh C\u00f4ng", prefix: "X\u00e3" },
          { id: "5475", name: "Th\u1ea1nh Nh\u1ef1t", prefix: "X\u00e3" },
          { id: "5476", name: "Th\u1ea1nh Tr\u1ecb", prefix: "X\u00e3" },
          {
            id: "5477",
            name: "V\u0129nh B\u00ecnh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "5478", name: "V\u0129nh H\u1eefu", prefix: "X\u00e3" },
          { id: "5479", name: "Y\u00ean Lu\u00f4ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "25607", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25608", name: "12A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25609", name: "12B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25610", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25611", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25612", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25613", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25614", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25615", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25616", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25617", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25618", name: "37", prefix: "Ph\u1ed1" },
          { id: "25619", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25620",
            name: "An Th\u1ea1nh Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25621",
            name: "C\u00f4 Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25622", name: "\u0110T 377A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25623",
            name: "Huy\u1ec7n l\u1ed9 13B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25624",
            name: "Huy\u1ec7n l\u1ed9 16B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25625",
            name: "Huy\u1ec7n l\u1ed9 16C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25626",
            name: "L\u00fd Th\u00e0nh B\u00f4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25627",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25628",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25629",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25630",
            name: "Ph\u1ea1m \u0110\u0103ng H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25631",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25632",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25633",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25634",
            name: "Phan Thanh Gi\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25635",
            name: "Qu\u1ed1c L\u1ed9 50",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25636",
            name: "Th\u1ea1nh L\u1ea1c \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25637",
            name: "Thi\u1ec7n Ch\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25638",
            name: "T\u1ec9nh l\u1ed9 15B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25639",
            name: "T\u1ec9nh l\u1ed9 872",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25640",
            name: "T\u1ec9nh l\u1ed9 873",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25641",
            name: "T\u1ec9nh l\u1ed9 877",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25642",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25643",
            name: "Tr\u1ea7n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25644",
            name: "Tr\u01b0\u01a1ng C\u00f4ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25645",
            name: "V\u00f5 T\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25646", name: "Xe Be", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
      {
        id: "355",
        name: "Huy\u1ec7n Cai L\u1eady",
        wards: [
          { id: "5480", name: "B\u00ecnh Ph\u00fa", prefix: "X\u00e3" },
          { id: "5481", name: "Cai L\u1eady", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "5482", name: "C\u1ea9m S\u01a1n", prefix: "X\u00e3" },
          { id: "5483", name: "Hi\u1ec7p \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "5484", name: "H\u1ed9i Xu\u00e2n", prefix: "X\u00e3" },
          { id: "5485", name: "Long Ti\u00ean", prefix: "X\u00e3" },
          { id: "5486", name: "Long Trung", prefix: "X\u00e3" },
          { id: "5487", name: "M\u1ef9 H\u1ea1nh Trung", prefix: "X\u00e3" },
          { id: "5488", name: "M\u1ef9 Long", prefix: "X\u00e3" },
          {
            id: "5489",
            name: "M\u1ef9 Th\u00e0nh B\u1eafc",
            prefix: "X\u00e3",
          },
          { id: "5490", name: "M\u1ef9 Th\u00e0nh Nam", prefix: "X\u00e3" },
          { id: "5491", name: "Ng\u0169 Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "5492", name: "Nh\u1ecb M\u1ef9", prefix: "X\u00e3" },
          { id: "5493", name: "Ph\u00fa An", prefix: "X\u00e3" },
          { id: "5494", name: "Ph\u00fa C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "5495", name: "Ph\u00fa Nhu\u1eadn", prefix: "X\u00e3" },
          { id: "5496", name: "Qu\u00fd Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "5497", name: "Tam B\u00ecnh", prefix: "X\u00e3" },
          { id: "5498", name: "T\u00e2n H\u1ed9i", prefix: "X\u00e3" },
          { id: "5499", name: "T\u00e2n Phong", prefix: "X\u00e3" },
          { id: "5500", name: "Th\u1ea1nh L\u1ed9c", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "25647", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25648", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25649", name: "868", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25650", name: "Ba D\u1eeba", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25651",
            name: "B\u1ebfn C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25652",
            name: "B\u1edd H\u1ed9i Khu 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25653",
            name: "Cao H\u1ea3i \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25654",
            name: "C\u00e2y Tr\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25656",
            name: "D\u00e2y Th\u00e9p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25655",
            name: "\u0110\u1eb7ng V\u0103n Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25657",
            name: "\u0110\u00eca Sen",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25658",
            name: "\u0110o\u00e0n Th\u1ecb Nghi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25659",
            name: "\u0110\u00f4ng Ba R\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25660",
            name: "\u0110\u00f4ng s\u00f4ng Ba R\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25661",
            name: "Gi\u1ed3ng Tre",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25662",
            name: "G\u00f2 G\u0103n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25663",
            name: "H\u00e0 T\u00f4n Hi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25664",
            name: "H\u1ed3 H\u1ea3i Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25665",
            name: "Hu\u1ef3nh V\u0103n S\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25666",
            name: "Kh\u00e1ng Chi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25667",
            name: "L\u00ea V\u0103n Ph\u1ea9m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25668",
            name: "L\u1ed9 Gi\u1eefa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25669",
            name: "Mai Th\u1ecb \u00dat",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25670",
            name: "M\u1ef9 Trang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25671",
            name: "Nguy\u1ec5n Ch\u00ed Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25672",
            name: "Nguy\u1ec5n Tu\u1ea5n Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25673",
            name: "Nguy\u1ec5n V\u0103n Ch\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25674",
            name: "Nguy\u1ec5n V\u0103n D\u00fat",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25675",
            name: "Nguy\u1ec5n V\u0103n Hi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25676",
            name: "Nguy\u1ec5n V\u0103n Lo",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25677",
            name: "Nguy\u1ec5n V\u0103n L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25678",
            name: "Nguy\u1ec5n V\u0103n M\u01b0\u1eddi T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25679",
            name: "Nh\u1ecb Qu\u00fd - Ph\u00fa Qu\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25680",
            name: "\u00d4ng Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25681",
            name: "\u00d4ng Huy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25682",
            name: "Phan V\u0103n Kh\u1ecfe",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25683",
            name: "Phan V\u0103n Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25684",
            name: "Phan Vi\u1ec7t Th\u1ed1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25685",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25686",
            name: "S\u00f4ng C\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25687",
            name: "T\u00e2n H\u1ed9i - M\u1ef9 H\u1ea1nh \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25688",
            name: "T\u00e2y Ba R\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25689",
            name: "T\u00e2y S\u00f4ng Ba R\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25690",
            name: "Th\u00e1i Th\u1ecb Ki\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25691",
            name: "Th\u00e1i Th\u1ecb Kim H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25692",
            name: "Thanh H\u00f2a - Ph\u00fa An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25693",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25694",
            name: "Thanh ni\u00ean Nh\u1ecb M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25695",
            name: "Thanh T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25696",
            name: "T\u1ec9nh l\u1ed9 847",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25697",
            name: "T\u1ec9nh l\u1ed9 864",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25698",
            name: "T\u1ec9nh l\u1ed9 865",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25699",
            name: "T\u1ec9nh l\u1ed9 868",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25700",
            name: "T\u1ec9nh L\u1ed9 875",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25701",
            name: "Tr\u1ea7n Xu\u00e2n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25702",
            name: "Tr\u1eeb V\u0103n Th\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25703",
            name: "Tr\u01b0\u01a1ng V\u0103n \u0110i\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25704",
            name: "Tr\u01b0\u01a1ng V\u0103n Sanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25705",
            name: "T\u1ee9 Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25706",
            name: "V\u00f5 Vi\u1ec7t T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25707",
            name: "X\u00e1ng Ngan",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "356",
        name: "M\u1ef9 Tho",
        wards: [
          { id: "5501", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "5502", name: "10", prefix: "Ph\u01b0\u1eddng" },
          { id: "5503", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "5504", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "5505", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "5506", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "5507", name: "6", prefix: "Ph\u01b0\u1eddng" },
          { id: "5508", name: "7", prefix: "Ph\u01b0\u1eddng" },
          { id: "5509", name: "8", prefix: "Ph\u01b0\u1eddng" },
          { id: "5510", name: "9", prefix: "Ph\u01b0\u1eddng" },
          { id: "5511", name: "\u0110\u1ea1o Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "5512", name: "M\u1ef9 Phong", prefix: "X\u00e3" },
          { id: "5513", name: "Ph\u01b0\u1edbc Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "5514", name: "T\u00e2n Long", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "5515",
            name: "T\u00e2n M\u1ef9 Ch\u00e1nh",
            prefix: "X\u00e3",
          },
          { id: "5516", name: "Th\u1edbi S\u01a1n", prefix: "X\u00e3" },
          { id: "5517", name: "Trung An", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "25708", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25709", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25710", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25711", name: "60", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25712", name: "789", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25713", name: "870B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25714", name: "879B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25715", name: "92A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25716", name: "92C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25717", name: "92D", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25718", name: "94B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25719",
            name: "\u1ea4p B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25720",
            name: "B\u00ecnh Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25721",
            name: "C\u1ea7u B\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25722",
            name: "C\u1ea7u Ch\u00f9a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25723",
            name: "C\u00f4 Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25726", name: "DH90", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25731",
            name: "D\u01b0\u01a1ng Khuy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25724",
            name: "\u0110\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25725",
            name: "\u0110\u1ea1o Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25727",
            name: "\u0110inh B\u1ed9 L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25728",
            name: "\u0110o\u00e0n Th\u1ecb Nghi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25729",
            name: "\u0110\u1ed1c Binh Ki\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25730",
            name: "\u0110\u1ed1ng \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25732",
            name: "Gi\u1ed3ng D\u1ee9a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25733",
            name: "G\u00f2 C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25734",
            name: "G\u00f2 \u00d4ng Gi\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25735",
            name: "H\u1ed3 V\u0103n Nh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25736",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25737",
            name: "Ho\u00e0ng Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25738",
            name: "H\u1ecdc L\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25739",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25740",
            name: "Huy\u1ec7n 92",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25741",
            name: "Huy\u1ec7n 92B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25742",
            name: "Huy\u1ec7n 93",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25743",
            name: "Huy\u1ec7n 94B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25744",
            name: "Huy\u1ec7n 95",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25745",
            name: "Huy\u1ec7n l\u1ed9 89",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25746",
            name: "Huy\u1ec7n l\u1ed9 91",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25747",
            name: "Huy\u1ec7n L\u1ed9 92",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25748",
            name: "Huy\u1ec7n l\u1ed9 92A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25749",
            name: "Huy\u1ec7n l\u1ed9 92C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25750",
            name: "Huy\u1ec7n L\u1ed9 93",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25751",
            name: "Huy\u1ec7n L\u1ed9 94B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25752",
            name: "Huy\u1ec1n Tho\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25753",
            name: "Hu\u1ef3nh T\u1ecbnh C\u1ee7a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25754", name: "K\u00fd Con", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25755",
            name: "L\u00e3nh Binh C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25756",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25757",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25758",
            name: "L\u00ea Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25759",
            name: "L\u00ea Th\u1ecb Ph\u1ec9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25760",
            name: "L\u00ea V\u0103n Duy\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25761",
            name: "L\u00ea V\u0103n Ngh\u1ec1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25762",
            name: "L\u00ea V\u0103n Ph\u1ea9m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25763",
            name: "L\u00ea V\u0103n Ph\u1ea1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25764",
            name: "L\u00ea V\u0103n Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25765",
            name: "L\u1ed9 C\u1ed3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25766",
            name: "L\u1ed9 \u0110\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25767", name: "L\u1ed9 Ma", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25768", name: "L\u1ed9 Me", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25769",
            name: "L\u1ed9 T\u1eadp \u0110o\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25770",
            name: "L\u00fd C\u00f4ng U\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25771",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "25772",
            name: "M\u1ef9 Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25773",
            name: "Nam K\u1ef3 Kh\u1edfi Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25774",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25775",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25776",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25777",
            name: "Nguy\u1ec5n C\u00f4ng B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25778",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25779",
            name: "Nguy\u1ec5n H\u1eefu Tr\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25780",
            name: "Nguy\u1ec5n Hu\u1ef3nh \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25781",
            name: "Nguy\u1ec5n Minh \u0110\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25782",
            name: "Nguy\u1ec5n Th\u1ecb Th\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25783",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25784",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25785",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25786",
            name: "Nguy\u1ec5n V\u0103n Gi\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25787",
            name: "Nguy\u1ec5n V\u0103n Nguy\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25788",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25789",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25790",
            name: "Phan Hi\u1ec3n \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25791",
            name: "Phan L\u01b0\u01a1ng Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25792",
            name: "Phan Thanh Gi\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25793",
            name: "Phan V\u0103n Kh\u1ecfe",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25794",
            name: "Ph\u01b0\u1edbc H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25795",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25796",
            name: "Qu\u1ed1c L\u1ed9 50",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25797",
            name: "Qu\u1ed1c l\u1ed9 60",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25798",
            name: "R\u1ea1ch G\u1ea7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25799", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25800",
            name: "T\u1ebft M\u1eadu Th\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25801",
            name: "Th\u00e1i Sanh H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25802",
            name: "Th\u00e1i V\u0103n \u0110\u1ea9u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25803",
            name: "Th\u1ea1nh H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25804",
            name: "Thi\u00ean H\u1ed9 D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25805",
            name: "Th\u1ee7 Khoa Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25806",
            name: "T\u1ec9nh l\u1ed9 864",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25807",
            name: "T\u1ec9nh l\u1ed9 868",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25808",
            name: "T\u1ec9nh L\u1ed9 870A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25809",
            name: "T\u1ec9nh l\u1ed9 870B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25810",
            name: "T\u1ec9nh l\u1ed9 879",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25811",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25812",
            name: "Tr\u1ea7n Ng\u1ecdc Gi\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25813",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "25814",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25815",
            name: "Tr\u1ea7n Th\u1ecb Th\u01a1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25816",
            name: "Tr\u1ea7n V\u0103n Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25817",
            name: "Tr\u1ecbnh Ho\u00e0i \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25818",
            name: "Tr\u1eeb V\u0103n Th\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25819",
            name: "Trung L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25820",
            name: "Tr\u01b0ng Tr\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25821",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25822",
            name: "Tr\u01b0\u1eddng V\u0103n H\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25823",
            name: "Tr\u01b0\u01a1ng V\u0129nh K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25824",
            name: "V\u00f5 T\u00e1nh B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25825",
            name: "V\u00f5 T\u00e1nh Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25826", name: "Yersin", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
      {
        id: "357",
        name: "T\u00e2n Ph\u00fa \u0110\u00f4ng",
        wards: [
          { id: "5518", name: "Ph\u00fa \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "5519", name: "Ph\u00fa T\u00e2n", prefix: "X\u00e3" },
          { id: "5520", name: "Ph\u00fa Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "5521", name: "T\u00e2n Ph\u00fa", prefix: "X\u00e3" },
          { id: "5522", name: "T\u00e2n Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "5523", name: "T\u00e2n Th\u1edbi", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "25827", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25828", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25829",
            name: "N\u00f4ng Tr\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25830",
            name: "Ph\u00e1o \u0110\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25831",
            name: "Ph\u00fa H\u1eefu - C\u1ed3n C\u1ed1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25832",
            name: "T\u1ec9nh l\u1ed9 15B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25833",
            name: "T\u1ec9nh l\u1ed9 877B",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "358",
        name: "T\u00e2n Ph\u01b0\u1edbc",
        wards: [
          { id: "5524", name: "H\u01b0ng Th\u1ea1nh", prefix: "X\u00e3" },
          {
            id: "5525",
            name: "M\u1ef9 Ph\u01b0\u1edbc",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "5526", name: "M\u1ef9 Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "5527", name: "Ph\u00fa M\u1ef9", prefix: "X\u00e3" },
          { id: "5528", name: "Ph\u01b0\u1edbc L\u1eadp", prefix: "X\u00e3" },
          {
            id: "5529",
            name: "T\u00e2n H\u00f2a \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "5530", name: "T\u00e2n H\u00f2a T\u00e2y", prefix: "X\u00e3" },
          {
            id: "5531",
            name: "T\u00e2n H\u00f2a Th\u00e0nh",
            prefix: "X\u00e3",
          },
          { id: "5532", name: "T\u00e2n L\u1eadp 1", prefix: "X\u00e3" },
          { id: "5533", name: "T\u00e2n L\u1eadp 2", prefix: "X\u00e3" },
          { id: "5534", name: "Th\u1ea1nh H\u00f2a", prefix: "X\u00e3" },
          { id: "5535", name: "Th\u1ea1nh M\u1ef9", prefix: "X\u00e3" },
          { id: "5536", name: "Th\u1ea1nh T\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "25834", name: "40", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25835", name: "41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25836", name: "42", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25837", name: "43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25838", name: "44", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25839", name: "45", prefix: "Ph\u1ed1" },
          { id: "25840", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25841", name: "50", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25842", name: "867", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25843",
            name: "B\u1eafc \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25844",
            name: "B\u1eafc K\u00eanh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25845", name: "\u0110T 867", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25846",
            name: "Huy\u1ec7n l\u1ed9 48B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25847",
            name: "Qu\u1ed1c L\u1ed9 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25848",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25849",
            name: "T\u1ec9nh l\u1ed9 865",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25850",
            name: "T\u1ec9nh l\u1ed9 866",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25851",
            name: "T\u1ec9nh l\u1ed9 866B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25852",
            name: "T\u1ec9nh l\u1ed9 867",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25853",
            name: "T\u1ec9nh l\u1ed9 874",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "27",
    code: "TB",
    name: "Th\u00e1i B\u00ecnh",
    districts: [
      {
        id: "359",
        name: "\u0110\u00f4ng H\u01b0ng",
        wards: [
          { id: "5537", name: "An Ch\u00e2u", prefix: "X\u00e3" },
          { id: "5538", name: "B\u1ea1ch \u0110\u1eb1ng", prefix: "X\u00e3" },
          {
            id: "5539",
            name: "Ch\u01b0\u01a1ng D\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          {
            id: "5540",
            name: "\u0110\u00f4 L\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "5541", name: "\u0110\u00f4ng \u00c1", prefix: "X\u00e3" },
          { id: "5542", name: "\u0110\u00f4ng C\u00e1c", prefix: "X\u00e3" },
          {
            id: "5543",
            name: "\u0110\u00f4ng C\u01b0\u1eddng",
            prefix: "X\u00e3",
          },
          {
            id: "5545",
            name: "\u0110\u00f4ng D\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          {
            id: "5544",
            name: "\u0110\u00f4ng \u0110\u1ed9ng",
            prefix: "X\u00e3",
          },
          { id: "5546", name: "\u0110\u00f4ng Giang", prefix: "X\u00e3" },
          { id: "5547", name: "\u0110\u00f4ng H\u00e0", prefix: "X\u00e3" },
          { id: "5548", name: "\u0110\u00f4ng Ho\u00e0ng", prefix: "X\u00e3" },
          { id: "5549", name: "\u0110\u00f4ng H\u1ee3p", prefix: "X\u00e3" },
          {
            id: "5550",
            name: "\u0110\u00f4ng H\u01b0ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "5551", name: "\u0110\u00f4ng Huy", prefix: "X\u00e3" },
          { id: "5552", name: "\u0110\u00f4ng Kinh", prefix: "X\u00e3" },
          { id: "5553", name: "\u0110\u00f4ng La", prefix: "X\u00e3" },
          { id: "5554", name: "\u0110\u00f4ng L\u0129nh", prefix: "X\u00e3" },
          { id: "5555", name: "\u0110\u00f4ng Phong", prefix: "X\u00e3" },
          { id: "5556", name: "\u0110\u1ed3ng Ph\u00fa", prefix: "X\u00e3" },
          {
            id: "5557",
            name: "\u0110\u00f4ng Ph\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "5558", name: "\u0110\u00f4ng Quang", prefix: "X\u00e3" },
          { id: "5559", name: "\u0110\u00f4ng S\u01a1n", prefix: "X\u00e3" },
          { id: "5560", name: "\u0110\u00f4ng T\u00e2n", prefix: "X\u00e3" },
          { id: "5561", name: "\u0110\u00f4ng Vinh", prefix: "X\u00e3" },
          { id: "5562", name: "\u0110\u00f4ng X\u00e1", prefix: "X\u00e3" },
          { id: "5563", name: "\u0110\u00f4ng Xu\u00e2n", prefix: "X\u00e3" },
          { id: "5564", name: "Hoa L\u01b0", prefix: "X\u00e3" },
          { id: "5565", name: "Hoa Nam", prefix: "X\u00e3" },
          { id: "5566", name: "H\u1ed3ng Ch\u00e2u", prefix: "X\u00e3" },
          { id: "5567", name: "H\u1ed3ng Giang", prefix: "X\u00e3" },
          { id: "5568", name: "H\u1ed3ng Vi\u1ec7t", prefix: "X\u00e3" },
          { id: "5569", name: "H\u1ee3p Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "5570", name: "Li\u00ean Giang", prefix: "X\u00e3" },
          { id: "5571", name: "L\u00f4 Giang", prefix: "X\u00e3" },
          { id: "5572", name: "M\u00ea Linh", prefix: "X\u00e3" },
          { id: "5573", name: "Minh Ch\u00e2u", prefix: "X\u00e3" },
          { id: "5574", name: "Minh T\u00e2n", prefix: "X\u00e3" },
          { id: "5575", name: "Nguy\u00ean X\u00e1", prefix: "X\u00e3" },
          { id: "5576", name: "Phong Ch\u00e2u", prefix: "X\u00e3" },
          { id: "5577", name: "Ph\u00fa Ch\u00e2u", prefix: "X\u00e3" },
          { id: "5578", name: "Ph\u00fa L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "5579", name: "Th\u0103ng Long", prefix: "X\u00e3" },
          { id: "5580", name: "Tr\u1ecdng Quan", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "25854", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25855", name: "39B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25856",
            name: "\u0110\u1ed1ng N\u0103m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25857",
            name: "Nguy\u1ec5n V\u0103n N\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25858",
            name: "Qu\u1ed1c L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25859",
            name: "T\u1ec9nh l\u1ed9 216",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25860",
            name: "T\u1ec9nh l\u1ed9 39B",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "360",
        name: "H\u01b0ng H\u00e0",
        wards: [
          { id: "5581", name: "B\u1eafc S\u01a1n", prefix: "X\u00e3" },
          { id: "5582", name: "Canh T\u00e2n", prefix: "X\u00e3" },
          { id: "5583", name: "Ch\u00ed H\u00f2a", prefix: "X\u00e3" },
          { id: "5584", name: "Chi L\u0103ng", prefix: "X\u00e3" },
          { id: "5585", name: "C\u1ed9ng H\u00f2a", prefix: "X\u00e3" },
          { id: "5586", name: "D\u00e2n Ch\u1ee7", prefix: "X\u00e3" },
          { id: "5591", name: "Duy\u00ean H\u1ea3i", prefix: "X\u00e3" },
          { id: "5587", name: "\u0110i\u1ec7p N\u00f4ng", prefix: "X\u00e3" },
          { id: "5588", name: "\u0110oan H\u00f9ng", prefix: "X\u00e3" },
          { id: "5589", name: "\u0110\u1ed9c L\u1eadp", prefix: "X\u00e3" },
          {
            id: "5590",
            name: "\u0110\u00f4ng \u0110\u00f4",
            prefix: "X\u00e3",
          },
          { id: "5592", name: "H\u00f2a B\u00ecnh", prefix: "X\u00e3" },
          { id: "5593", name: "H\u00f2a Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "5594", name: "H\u1ed3ng An", prefix: "X\u00e3" },
          { id: "5595", name: "H\u1ed3ng L\u0129nh", prefix: "X\u00e3" },
          { id: "5596", name: "H\u1ed3ng Minh", prefix: "X\u00e3" },
          { id: "5597", name: "H\u00f9ng D\u0169ng", prefix: "X\u00e3" },
          {
            id: "5598",
            name: "H\u01b0ng H\u00e0",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "5599",
            name: "H\u01b0ng Nh\u00e2n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "5600", name: "Kim Chung", prefix: "X\u00e3" },
          { id: "5601", name: "Li\u00ean Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "5602", name: "Minh H\u00f2a", prefix: "X\u00e3" },
          { id: "5603", name: "Minh Khai", prefix: "X\u00e3" },
          { id: "5604", name: "Minh T\u00e2n", prefix: "X\u00e3" },
          { id: "5605", name: "Ph\u00fac Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "5606", name: "T\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "5607", name: "T\u00e2n L\u1ec5", prefix: "X\u00e3" },
          { id: "5608", name: "T\u00e2n Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "5609", name: "T\u00e2y \u0110\u00f4", prefix: "X\u00e3" },
          { id: "5610", name: "Th\u00e1i H\u01b0ng", prefix: "X\u00e3" },
          { id: "5611", name: "Th\u00e1i Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "5612", name: "Th\u1ed1ng Nh\u1ea5t", prefix: "X\u00e3" },
          { id: "5613", name: "Ti\u1ebfn \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "5614", name: "V\u0103n C\u1ea9m", prefix: "X\u00e3" },
          { id: "5615", name: "V\u0103n Lang", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "25861", name: "39", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25862",
            name: "Khu \u0110\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25863",
            name: "Qu\u1ed1c L\u1ed9 39A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25864",
            name: "T\u1ec9nh l\u1ed9 216",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25865",
            name: "T\u1ec9nh l\u1ed9 224",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25866",
            name: "T\u1ec9nh l\u1ed9 226",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "361",
        name: "Ki\u1ebfn X\u01b0\u01a1ng",
        wards: [
          { id: "5616", name: "An B\u00ecnh", prefix: "X\u00e3" },
          { id: "5617", name: "An B\u1ed3i", prefix: "X\u00e3" },
          { id: "5618", name: "B\u00ecnh \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "5619", name: "B\u00ecnh Minh", prefix: "X\u00e3" },
          { id: "5620", name: "B\u00ecnh Nguy\u00ean", prefix: "X\u00e3" },
          { id: "5621", name: "B\u00ecnh Thanh", prefix: "X\u00e3" },
          { id: "5622", name: "\u0110\u00ecnh Ph\u00f9ng", prefix: "X\u00e3" },
          { id: "5623", name: "Ho\u00e0 B\u00ecnh", prefix: "X\u00e3" },
          { id: "5624", name: "H\u1ed3ng Th\u00e1i", prefix: "X\u00e3" },
          { id: "5625", name: "H\u1ed3ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "5626", name: "L\u00ea L\u1ee3i", prefix: "X\u00e3" },
          { id: "5627", name: "Minh H\u01b0ng", prefix: "X\u00e3" },
          { id: "5628", name: "Minh T\u00e2n", prefix: "X\u00e3" },
          { id: "5629", name: "Nam B\u00ecnh", prefix: "X\u00e3" },
          { id: "5630", name: "Nam Cao", prefix: "X\u00e3" },
          { id: "5631", name: "Quang B\u00ecnh", prefix: "X\u00e3" },
          { id: "5632", name: "Quang H\u01b0ng", prefix: "X\u00e3" },
          { id: "5633", name: "Quang L\u1ecbch", prefix: "X\u00e3" },
          { id: "5634", name: "Quang Minh", prefix: "X\u00e3" },
          { id: "5635", name: "Quang Trung", prefix: "X\u00e3" },
          { id: "5636", name: "Qu\u1ed1c Tu\u1ea5n", prefix: "X\u00e3" },
          { id: "5637", name: "Quy\u1ebft Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "5638", name: "Thanh N\u00ea", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "5639", name: "Thanh T\u00e2n", prefix: "X\u00e3" },
          { id: "5640", name: "Th\u01b0\u1ee3ng Hi\u1ec1n", prefix: "X\u00e3" },
          { id: "5641", name: "Tr\u00e0 Giang", prefix: "X\u00e3" },
          { id: "5642", name: "V\u0169 An", prefix: "X\u00e3" },
          { id: "5643", name: "V\u0169 B\u00ecnh", prefix: "X\u00e3" },
          { id: "5644", name: "V\u0169 C\u00f4ng", prefix: "X\u00e3" },
          { id: "5645", name: "V\u0169 Ho\u00e0", prefix: "X\u00e3" },
          { id: "5646", name: "V\u0169 L\u1ea1c", prefix: "X\u00e3" },
          { id: "5647", name: "V\u0169 L\u1ec5", prefix: "X\u00e3" },
          { id: "5648", name: "V\u0169 Ninh", prefix: "X\u00e3" },
          {
            id: "5649",
            name: "V\u0169 Qu\u00fd",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "5650", name: "V\u0169 S\u01a1n", prefix: "X\u00e3" },
          { id: "5651", name: "V\u0169 T\u00e2y", prefix: "X\u00e3" },
          { id: "5652", name: "V\u0169 Th\u1eafng", prefix: "X\u00e3" },
          { id: "5653", name: "V\u0169 Trung", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "25867", name: "39B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25868",
            name: "Qu\u1ed1c L\u1ed9 39",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25869",
            name: "T\u1ec9nh l\u1ed9 222",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25870",
            name: "T\u1ec9nh l\u1ed9 223",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25871",
            name: "T\u1ec9nh l\u1ed9 39B",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "362",
        name: "Qu\u1ef3nh Ph\u1ee5",
        wards: [
          { id: "5654", name: "An \u1ea4p", prefix: "X\u00e3" },
          { id: "5655", name: "An B\u00e0i", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "5656", name: "An C\u1ea7u", prefix: "X\u00e3" },
          { id: "5658", name: "An D\u1ee5c", prefix: "X\u00e3" },
          { id: "5657", name: "An \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "5659", name: "An Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "5660", name: "An Kh\u00ea", prefix: "X\u00e3" },
          { id: "5661", name: "An L\u1ec5", prefix: "X\u00e3" },
          { id: "5662", name: "An M\u1ef9", prefix: "X\u00e3" },
          { id: "5663", name: "An Ninh", prefix: "X\u00e3" },
          { id: "5664", name: "An Qu\u00fd", prefix: "X\u00e3" },
          { id: "5665", name: "An Th\u00e1i", prefix: "X\u00e3" },
          { id: "5666", name: "An Thanh", prefix: "X\u00e3" },
          { id: "5667", name: "An Tr\u00e0ng", prefix: "X\u00e3" },
          { id: "5668", name: "An Vinh", prefix: "X\u00e3" },
          { id: "5669", name: "An V\u0169", prefix: "X\u00e3" },
          { id: "5670", name: "\u0110\u00f4ng H\u1ea3i", prefix: "X\u00e3" },
          { id: "5671", name: "\u0110\u1ed3ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "5672", name: "Qu\u1ef3nh B\u1ea3o", prefix: "X\u00e3" },
          { id: "5673", name: "Qu\u1ef3nh Ch\u00e2u", prefix: "X\u00e3" },
          {
            id: "5674",
            name: "Qu\u1ef3nh C\u00f4i",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "5675", name: "Qu\u1ef3nh Giao", prefix: "X\u00e3" },
          { id: "5676", name: "Qu\u1ef3nh H\u1ea3i", prefix: "X\u00e3" },
          { id: "5677", name: "Qu\u1ef3nh Hoa", prefix: "X\u00e3" },
          { id: "5678", name: "Qu\u1ef3nh Ho\u00e0ng", prefix: "X\u00e3" },
          { id: "5679", name: "Qu\u1ef3nh H\u1ed9i", prefix: "X\u00e3" },
          { id: "5680", name: "Qu\u1ef3nh H\u1ed3ng", prefix: "X\u00e3" },
          { id: "5681", name: "Qu\u1ef3nh H\u01b0ng", prefix: "X\u00e3" },
          { id: "5682", name: "Qu\u1ef3nh Kh\u00ea", prefix: "X\u00e3" },
          { id: "5683", name: "Qu\u1ef3nh L\u00e2m", prefix: "X\u00e3" },
          { id: "5684", name: "Qu\u1ef3nh Minh", prefix: "X\u00e3" },
          { id: "5685", name: "Qu\u1ef3nh M\u1ef9", prefix: "X\u00e3" },
          { id: "5686", name: "Qu\u1ef3nh Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "5687", name: "Qu\u1ef3nh Nguy\u00ean", prefix: "X\u00e3" },
          { id: "5688", name: "Qu\u1ef3nh S\u01a1n", prefix: "X\u00e3" },
          { id: "5689", name: "Qu\u1ef3nh Th\u1ecd", prefix: "X\u00e3" },
          { id: "5690", name: "Qu\u1ef3nh Trang", prefix: "X\u00e3" },
          { id: "5691", name: "Qu\u1ef3nh X\u00e1", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "25872", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25873", name: "396B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25874",
            name: "Nguy\u1ec5n Th\u00e1i S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25875",
            name: "Qu\u1ed1c L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25876",
            name: "T\u1ec9nh l\u1ed9 216",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25877",
            name: "T\u1ec9nh l\u1ed9 217",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25878",
            name: "T\u1ec9nh l\u1ed9 224",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "363",
        name: "Th\u00e1i B\u00ecnh",
        wards: [
          {
            id: "5692",
            name: "B\u1ed3 Xuy\u00ean",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "5693",
            name: "\u0110\u1ec1 Th\u00e1m",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "5694", name: "\u0110\u00f4ng H\u00f2a", prefix: "X\u00e3" },
          { id: "5695", name: "\u0110\u00f4ng M\u1ef9", prefix: "X\u00e3" },
          { id: "5696", name: "\u0110\u00f4ng Th\u1ecd", prefix: "X\u00e3" },
          {
            id: "5697",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "5698", name: "K\u1ef3 B\u00e1", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "5699",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "5700",
            name: "Ph\u00fa Kh\u00e1nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "5701", name: "Ph\u00fa Xu\u00e2n", prefix: "X\u00e3" },
          { id: "5702", name: "Quang Trung", prefix: "Ph\u01b0\u1eddng" },
          { id: "5703", name: "T\u00e2n B\u00ecnh", prefix: "X\u00e3" },
          { id: "5704", name: "Ti\u1ec1n Phong", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "5705",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "5706",
            name: "Tr\u1ea7n L\u00e3m",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "5707", name: "V\u0169 Ch\u00ednh", prefix: "X\u00e3" },
          { id: "5708", name: "V\u0169 \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "5709", name: "V\u0169 L\u1ea1c", prefix: "X\u00e3" },
          { id: "5710", name: "V\u0169 Ph\u00fac", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "25879", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25880", name: "223", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25881",
            name: "B\u1ed3 Xuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25882",
            name: "B\u00f9i S\u1ef9 Ti\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25883",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25889",
            name: "Do\u00e3n Khu\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25884",
            name: "\u0110\u1eb7ng Nghi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25885",
            name: "\u0110\u1eb7ng Nhi\u1ec5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25886",
            name: "\u0110\u00ea Vu\u0300ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25887",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25888",
            name: "\u0110\u1ed7 L\u00fd Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25890",
            name: "\u0110o\u00e0n Nguy\u1ec5n Tu\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25891",
            name: "\u0110\u1ed1c \u0110en",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25892",
            name: "\u0110\u1ed1c Nh\u01b0\u1ee1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25893", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "25894",
            name: "H\u00f2a An 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25895",
            name: "Ho\u00e0ng C\u00f4ng Ch\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25896",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25897",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25898",
            name: "Ho\u00e0ng Minh Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25899",
            name: "Ho\u00e0ng V\u0103n Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25900",
            name: "H\u00f9ng Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25901",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25902", name: "K\u1ef3 \u0110\u1ed3ng", prefix: "Ph\u1ed1" },
          {
            id: "25903",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25904",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25905",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25906",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25907",
            name: "L\u00ea Tr\u1ecdng Th\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25908",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25909",
            name: "Long H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25910",
            name: "L\u00fd B\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25911",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25912",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          { id: "25913", name: "Minh Khai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25914",
            name: "Ng\u00f4 Quang B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25915",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25916",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25917",
            name: "Ng\u00f4 V\u0103n S\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25918",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25919",
            name: "Nguy\u1ec5n C\u00f4ng Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25920",
            name: "Nguy\u1ec5n Danh \u0110\u1edbi",
            prefix: "Ph\u1ed1",
          },
          {
            id: "25922",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25921",
            name: "Nguy\u1ec5n \u0110\u00ecnh Ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25923",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25924",
            name: "Nguy\u1ec5n Ng\u1ecdc Sanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25925",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25926",
            name: "Nguy\u1ec5n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25927",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25928",
            name: "Nguy\u1ec5n T\u00f4ng Quai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25929",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25930",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25931",
            name: "Nguy\u1ec5n V\u0103n N\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25932",
            name: "Ph\u1ea1m Huy Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25933",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25934",
            name: "Ph\u1ea1m Th\u1ebf Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25935",
            name: "Phan B\u00e1 V\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25936",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25937",
            name: "Ph\u1ea7n L\u0103ng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25938",
            name: "Qu\u00e1ch \u0110\u00ecnh B\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25939",
            name: "Qu\u00e1ch H\u1eefu Nghi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25940", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25941",
            name: "Qu\u1ed1c L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25942", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25943", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25944", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25945",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25946",
            name: "T\u00e2y H\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25947",
            name: "Ti\u1ec1n H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25948",
            name: "T\u1ec9nh l\u1ed9 39B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25949",
            name: "T\u1ed1ng V\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25950",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25951",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25952",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25953",
            name: "Tr\u1ea7n L\u00e3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25954",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25955",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25956",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25957",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25958",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25959",
            name: "Tr\u1ea7n Th\u00e1i T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25960",
            name: "Tr\u1ea7n Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25961",
            name: "Tr\u1ea7n Th\u1ecb Dung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25962",
            name: "Tr\u1ea7n Th\u1ee7 \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25963",
            name: "Tr\u01b0ng Tr\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25964",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25965",
            name: "V\u0169 Tr\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2935",
            name: "379 Phan B\u00e1 V\u00e0nh",
            lat: "20.435205459595",
            lng: "106.3318939209",
          },
          {
            id: "2936",
            name: "New City Th\u00e1i B\u00ecnh",
            lat: "20.438373565674",
            lng: "106.35600280762",
          },
          {
            id: "2937",
            name: "Ng\u00f4i Sao Ph\u01b0\u01a1ng \u0110\u00f4ng",
            lat: "20.433441162109",
            lng: "106.34332275391",
          },
          {
            id: "2938",
            name: "Petro Th\u0103ng Long",
            lat: "20.437883377075",
            lng: "106.325340271",
          },
          {
            id: "2939",
            name: "Ph\u00fa Xu\u00e2n Damsan",
            lat: "20.448833465576",
            lng: "106.33544921875",
          },
          {
            id: "2940",
            name: "Th\u00e1i B\u00ecnh Dragon City",
            lat: "20.441417694092",
            lng: "106.32701873779",
          },
          {
            id: "2941",
            name: "Vincom Plaza L\u00fd B\u00f4n",
            lat: "20.44455909729",
            lng: "106.33751678467",
          },
        ],
      },
      {
        id: "364",
        name: "Th\u00e1i Thu\u1ef5",
        wards: [
          {
            id: "5711",
            name: "Di\u00eam \u0110i\u1ec1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "5712", name: "H\u1ed3ng Qu\u1ef3nh", prefix: "X\u00e3" },
          { id: "5713", name: "M\u1ef9 L\u1ed9c", prefix: "X\u00e3" },
          { id: "5714", name: "Th\u00e1i An", prefix: "X\u00e3" },
          { id: "5716", name: "Th\u00e1i D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "5715", name: "Th\u00e1i \u0110\u00f4", prefix: "X\u00e3" },
          { id: "5717", name: "Th\u00e1i Giang", prefix: "X\u00e3" },
          { id: "5718", name: "Th\u00e1i H\u00e0", prefix: "X\u00e3" },
          { id: "5719", name: "Th\u00e1i H\u00f2a", prefix: "X\u00e3" },
          { id: "5720", name: "Th\u00e1i H\u1ecdc", prefix: "X\u00e3" },
          { id: "5721", name: "Th\u00e1i H\u1ed3ng", prefix: "X\u00e3" },
          { id: "5722", name: "Th\u00e1i H\u01b0ng", prefix: "X\u00e3" },
          { id: "5723", name: "Th\u00e1i Nguy\u00ean", prefix: "X\u00e3" },
          { id: "5724", name: "Th\u00e1i Ph\u00fac", prefix: "X\u00e3" },
          { id: "5725", name: "Th\u00e1i S\u01a1n", prefix: "X\u00e3" },
          { id: "5726", name: "Th\u00e1i T\u00e2n", prefix: "X\u00e3" },
          { id: "5727", name: "Th\u00e1i Th\u00e0nh", prefix: "X\u00e3" },
          { id: "5728", name: "Th\u00e1i Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "5729", name: "Th\u00e1i Th\u1ecd", prefix: "X\u00e3" },
          { id: "5730", name: "Th\u00e1i Thu\u1ea7n", prefix: "X\u00e3" },
          { id: "5731", name: "Th\u00e1i Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "5732", name: "Th\u00e1i Th\u1ee7y", prefix: "X\u00e3" },
          { id: "5733", name: "Th\u00e1i Xuy\u00ean", prefix: "X\u00e3" },
          { id: "5734", name: "Th\u1ee5y An", prefix: "X\u00e3" },
          { id: "5735", name: "Th\u1ee5y B\u00ecnh", prefix: "X\u00e3" },
          { id: "5736", name: "Th\u1ee5y Ch\u00ednh", prefix: "X\u00e3" },
          { id: "5737", name: "Th\u1ee5y D\u00e2n", prefix: "X\u00e3" },
          { id: "5738", name: "Th\u1ee5y D\u0169ng", prefix: "X\u00e3" },
          { id: "5739", name: "Th\u1ee5y D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "5740", name: "Th\u1ee5y Duy\u00ean", prefix: "X\u00e3" },
          { id: "5741", name: "Th\u1ee5y H\u00e0", prefix: "X\u00e3" },
          { id: "5742", name: "Th\u1ee5y H\u1ea3i", prefix: "X\u00e3" },
          { id: "5743", name: "Th\u1ee5y H\u1ed3ng", prefix: "X\u00e3" },
          { id: "5744", name: "Th\u1ee5y H\u01b0ng", prefix: "X\u00e3" },
          { id: "5745", name: "Th\u1ee5y Li\u00ean", prefix: "X\u00e3" },
          { id: "5746", name: "Th\u1ee5y L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "5747", name: "Th\u1ee5y Ninh", prefix: "X\u00e3" },
          { id: "5748", name: "Th\u1ee5y Phong", prefix: "X\u00e3" },
          { id: "5749", name: "Th\u1ee5y Ph\u00fac", prefix: "X\u00e3" },
          { id: "5750", name: "Th\u1ee5y Qu\u1ef3nh", prefix: "X\u00e3" },
          { id: "5751", name: "Th\u1ee5y S\u01a1n", prefix: "X\u00e3" },
          { id: "5752", name: "Th\u1ee5y T\u00e2n", prefix: "X\u00e3" },
          { id: "5753", name: "Th\u1ee5y Thanh", prefix: "X\u00e3" },
          { id: "5754", name: "Th\u1ee5y Tr\u00ecnh", prefix: "X\u00e3" },
          { id: "5755", name: "Th\u1ee5y Tr\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "5756", name: "Th\u1ee5y V\u0103n", prefix: "X\u00e3" },
          { id: "5757", name: "Th\u1ee5y Vi\u1ec7t", prefix: "X\u00e3" },
          { id: "5758", name: "Th\u1ee5y Xu\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "25966", name: "319", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25967", name: "37", prefix: "Ph\u1ed1" },
          { id: "25968", name: "39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25969", name: "47", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "25970",
            name: "T\u1ec9nh l\u1ed9 216",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25971",
            name: "T\u1ec9nh l\u1ed9 39B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25972",
            name: "T\u1ec9nh l\u1ed9 460",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "365",
        name: "Ti\u1ec1n H\u1ea3i",
        wards: [
          { id: "5759", name: "An Ninh", prefix: "X\u00e3" },
          { id: "5760", name: "B\u1eafc H\u1ea3i", prefix: "X\u00e3" },
          { id: "5761", name: "\u0110\u00f4ng C\u01a1", prefix: "X\u00e3" },
          { id: "5762", name: "\u0110\u00f4ng H\u1ea3i", prefix: "X\u00e3" },
          { id: "5763", name: "\u0110\u00f4ng Ho\u00e0ng", prefix: "X\u00e3" },
          { id: "5764", name: "\u0110\u00f4ng L\u00e2m", prefix: "X\u00e3" },
          { id: "5765", name: "\u0110\u00f4ng Long", prefix: "X\u00e3" },
          { id: "5766", name: "\u0110\u00f4ng Minh", prefix: "X\u00e3" },
          { id: "5767", name: "\u0110\u00f4ng Phong", prefix: "X\u00e3" },
          { id: "5768", name: "\u0110\u00f4ng Qu\u00fd", prefix: "X\u00e3" },
          { id: "5769", name: "\u0110\u00f4ng Tr\u00e0", prefix: "X\u00e3" },
          { id: "5770", name: "\u0110\u00f4ng Trung", prefix: "X\u00e3" },
          { id: "5771", name: "\u0110\u00f4ng Xuy\u00ean", prefix: "X\u00e3" },
          { id: "5772", name: "Nam Ch\u00ednh", prefix: "X\u00e3" },
          { id: "5773", name: "Nam C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "5774", name: "Nam H\u00e0", prefix: "X\u00e3" },
          { id: "5775", name: "Nam H\u1ea3i", prefix: "X\u00e3" },
          { id: "5776", name: "Nam H\u1ed3ng", prefix: "X\u00e3" },
          { id: "5777", name: "Nam H\u01b0ng", prefix: "X\u00e3" },
          { id: "5778", name: "Nam Ph\u00fa", prefix: "X\u00e3" },
          { id: "5779", name: "Nam Th\u1eafng", prefix: "X\u00e3" },
          { id: "5780", name: "Nam Thanh", prefix: "X\u00e3" },
          { id: "5781", name: "Nam Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "5782", name: "Nam Trung", prefix: "X\u00e3" },
          { id: "5783", name: "Ph\u01b0\u01a1ng C\u00f4ng", prefix: "X\u00e3" },
          { id: "5784", name: "T\u00e2y An", prefix: "X\u00e3" },
          { id: "5785", name: "T\u00e2y Giang", prefix: "X\u00e3" },
          { id: "5786", name: "T\u00e2y L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "5787", name: "T\u00e2y Ninh", prefix: "X\u00e3" },
          { id: "5788", name: "T\u00e2y Phong", prefix: "X\u00e3" },
          { id: "5789", name: "T\u00e2y S\u01a1n", prefix: "X\u00e3" },
          { id: "5790", name: "T\u00e2y Ti\u1ebfn", prefix: "X\u00e3" },
          {
            id: "5791",
            name: "Ti\u1ec1n H\u1ea3i",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "5792", name: "V\u00e2n Tr\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "5793", name: "V\u0169 L\u0103ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "25973", name: "14/10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25974", name: "39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25975", name: "B\u00f9i S\u00ednh", prefix: "Ph\u1ed1" },
          {
            id: "25976",
            name: "B\u00f9i Vi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25977",
            name: "Chu \u0110\u00ecnh Ng\u1ea1n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "25978",
            name: "Ho\u00e0ng V\u0103n Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25979", name: "Ho\u00e0ng Vinh", prefix: "Ph\u1ed1" },
          {
            id: "25980",
            name: "H\u00f9ng Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25981",
            name: "L\u01b0\u01a1ng V\u0103n S\u1ea3ng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "25982",
            name: "Ng\u00f4 Duy Ph\u1edbn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25983", name: "Ng\u00f4 Quang \u0110oan", prefix: "Ph\u1ed1" },
          {
            id: "25984",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25985",
            name: "Nguy\u1ec5n Quang B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25986",
            name: "Nguy\u1ec5n Trung Khuy\u1ebfn",
            prefix: "Ph\u1ed1",
          },
          { id: "25987", name: "Phan \u00c1i", prefix: "Ph\u1ed1" },
          {
            id: "25988",
            name: "Ph\u00f9ng Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25989",
            name: "T\u1ea1 Xu\u00e2n Thu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "25990", name: "Ti\u1ec3u Ho\u00e0ng", prefix: "Ph\u1ed1" },
          {
            id: "25991",
            name: "T\u1ec9nh l\u1ed9 219",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25992",
            name: "T\u1ec9nh l\u1ed9 39B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "25993",
            name: "Tr\u1ea7n \u0110\u1ee9c Th\u1ecbnh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "25994",
            name: "Tr\u1ea7n Xu\u00e2n S\u1eafc",
            prefix: "Ph\u1ed1",
          },
          { id: "25995", name: "V\u0169 Nhu", prefix: "Ph\u1ed1" },
          {
            id: "25996",
            name: "V\u0169 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "366",
        name: "V\u0169 Th\u01b0",
        wards: [
          { id: "5794", name: "B\u00e1ch Thu\u1eadn", prefix: "X\u00e3" },
          { id: "5796", name: "D\u0169ng Ngh\u0129a", prefix: "X\u00e3" },
          { id: "5797", name: "Duy Nh\u1ea5t", prefix: "X\u00e3" },
          { id: "5795", name: "\u0110\u1ed3ng Thanh", prefix: "X\u00e3" },
          { id: "5798", name: "Hi\u1ec7p H\u00f2a", prefix: "X\u00e3" },
          { id: "5799", name: "H\u00f2a B\u00ecnh", prefix: "X\u00e3" },
          { id: "5800", name: "H\u1ed3ng L\u00fd", prefix: "X\u00e3" },
          { id: "5801", name: "H\u1ed3ng Phong", prefix: "X\u00e3" },
          { id: "5802", name: "Minh Khai", prefix: "X\u00e3" },
          { id: "5803", name: "Minh L\u00e3ng", prefix: "X\u00e3" },
          { id: "5804", name: "Minh Quang", prefix: "X\u00e3" },
          { id: "5805", name: "Nguy\u00ean X\u00e1", prefix: "X\u00e3" },
          { id: "5806", name: "Ph\u00fac Th\u00e0nh", prefix: "X\u00e3" },
          { id: "5807", name: "Song An", prefix: "X\u00e3" },
          { id: "5808", name: "Song L\u00e3ng", prefix: "X\u00e3" },
          { id: "5809", name: "Tam Quang", prefix: "X\u00e3" },
          { id: "5810", name: "T\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "5811", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "5812", name: "T\u00e2n Phong", prefix: "X\u00e3" },
          { id: "5813", name: "Trung An", prefix: "X\u00e3" },
          { id: "5814", name: "T\u1ef1 T\u00e2n", prefix: "X\u00e3" },
          { id: "5815", name: "Vi\u1ec7t H\u00f9ng", prefix: "X\u00e3" },
          { id: "5816", name: "Vi\u1ec7t Thu\u1eadn", prefix: "X\u00e3" },
          { id: "5817", name: "V\u0169 \u0110o\u00e0i", prefix: "X\u00e3" },
          { id: "5818", name: "V\u0169 H\u1ed9i", prefix: "X\u00e3" },
          {
            id: "5819",
            name: "V\u0169 Th\u01b0",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "5820", name: "V\u0169 Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "5821", name: "V\u0169 V\u00e2n", prefix: "X\u00e3" },
          { id: "5822", name: "V\u0169 Vinh", prefix: "X\u00e3" },
          { id: "5823", name: "Xu\u00e2n H\u00f2a", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "25997", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25998", name: "10B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "25999", name: "39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26000", name: "LH 23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26001", name: "Minh Khai", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26002", name: "Minh Quang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26003",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26004",
            name: "Qu\u1ed1c L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26005",
            name: "Qu\u1ed1c L\u1ed9 10B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26006",
            name: "T\u1ec9nh l\u1ed9 223",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26007",
            name: "T\u1ec9nh l\u1ed9 454",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26008",
            name: "T\u1ec9nh l\u1ed9 463",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "28",
    code: "BG",
    name: "B\u1eafc Giang",
    districts: [
      {
        id: "367",
        name: "B\u1eafc Giang",
        wards: [
          { id: "5825", name: "D\u0129nh K\u1ebf", prefix: "X\u00e3" },
          { id: "5826", name: "D\u0129nh Tr\u00ec", prefix: "X\u00e3" },
          { id: "5824", name: "\u0110a Mai", prefix: "X\u00e3" },
          { id: "5827", name: "\u0110\u1ed3ng S\u01a1n", prefix: "X\u00e3" },
          {
            id: "5828",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "5829", name: "L\u00ea L\u1ee3i", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "5830",
            name: "M\u1ef9 \u0110\u1ed9",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "5831",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "5832", name: "Song Kh\u00ea", prefix: "X\u00e3" },
          { id: "5833", name: "Song Mai", prefix: "X\u00e3" },
          { id: "5834", name: "T\u00e2n M\u1ef9", prefix: "X\u00e3" },
          { id: "5835", name: "T\u00e2n Ti\u1ebfn", prefix: "X\u00e3" },
          {
            id: "5836",
            name: "Th\u1ecd X\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "5837",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "5838",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "5839",
            name: "X\u01b0\u01a1ng Giang",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "26009", name: "18A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26010", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26011", name: "295", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26012", name: "295B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26013", name: "298", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26014", name: "398", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26015",
            name: "\u00c1 L\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26016",
            name: "A L\u1eef Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26017",
            name: "B\u1ea3o Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26018",
            name: "C\u1ea3 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26019",
            name: "Cao K\u1ef3 V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26020",
            name: "Ch\u00e2u Xuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26021", name: "Chi Ly 1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26022",
            name: "Chu Danh T\u1ec3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26023", name: "C\u1ed1c", prefix: "Ph\u1ed1" },
          {
            id: "26024",
            name: "Cung Nh\u01b0\u1ee3ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26027",
            name: "Do\u00e3n D\u1ecbch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26025",
            name: "\u0110\u00e0o S\u01b0 T\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26026",
            name: "\u0110\u00e0o T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26028",
            name: "\u0110o\u00e0n K\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26029",
            name: "\u0110\u1ed3ng C\u1eeda",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26030",
            name: "\u0110\u1ed3ng C\u1eeda 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26031",
            name: "Gi\u00e1p H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26032",
            name: "Gi\u00e1p L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26033",
            name: "Gi\u00e1p Ngu\u1ed9t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26034",
            name: "Gi\u00e1p V\u0103n Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26035",
            name: "H\u1ed3 C\u00f4ng D\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26036",
            name: "H\u00f2a S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26037",
            name: "H\u00f2a Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26038",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26039",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26040",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26041",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26042",
            name: "Huy\u1ec1n Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26043", name: "L\u00ea An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26044",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26045", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26046",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26047", name: "L\u00ea L\u00fd", prefix: "Ph\u1ed1" },
          {
            id: "26048",
            name: "L\u00ea Tri\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26049",
            name: "L\u1ec1u V\u0103n Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26050",
            name: "L\u01b0\u01a1ng V\u0103n N\u0103m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26051",
            name: "L\u01b0u Nh\u00e2n Tr\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26052",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26053",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26054", name: "Minh Khai", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26055", name: "M\u1ef9 \u0110\u1ed9", prefix: "Ph\u1ed1" },
          {
            id: "26056",
            name: "Nam D\u0129nh K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26057",
            name: "Ngh\u0129a Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26058",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26059",
            name: "Ng\u00f4 Trang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26060",
            name: "Ng\u00f4 V\u0103n C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26061",
            name: "Nguy\u1ec5n Cao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26062",
            name: "Nguy\u1ec5n C\u00f4ng H\u00e3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26063",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26065",
            name: "Nguy\u1ec5n Do\u00e3n \u0110\u1ecbch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26066",
            name: "Nguy\u1ec5n Duy N\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26064",
            name: "Nguy\u1ec5n \u0110\u00ecnh Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26067",
            name: "Nguy\u00ean H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26068",
            name: "Nguy\u1ec5n Kh\u1eafc Nhu",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26069",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26070",
            name: "Nguy\u1ec5n Th\u1ecb L\u1ef1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26071",
            name: "Nguy\u1ec5n Th\u1ecb L\u01b0u 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26072",
            name: "Nguy\u1ec5n Th\u1ecb L\u01b0u 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26073",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26074",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26075",
            name: "Ph\u1ea1m Li\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26076",
            name: "Ph\u1ed3n X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26077",
            name: "Ph\u00f9ng Tr\u1ea1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26078",
            name: "Qu\u00e1ch Nh\u1eabn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26079",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26080",
            name: "Qu\u1ed1c l\u1ed9 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26081",
            name: "Song Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26082",
            name: "T\u00e2n Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26083",
            name: "Th\u00e1i \u0110\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26084",
            name: "Th\u00e2n C\u1ea3nh Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26085",
            name: "Th\u00e2n C\u1ea3nh V\u0103n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26086",
            name: "Th\u00e2n \u0110\u1ee9c Lu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26087",
            name: "Th\u00e2n Khu\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26088",
            name: "Th\u00e2n Nh\u00e2n T\u00edn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26089",
            name: "Th\u00e2n Nh\u00e2n Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26090",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26091",
            name: "Th\u00e1nh Thi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26092",
            name: "Ti\u1ec1n Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26093",
            name: "T\u1ec9nh l\u1ed9 284",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26094",
            name: "Tr\u1ea7n \u0110\u0103ng Tuy\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26095",
            name: "Tr\u1ea7n Kh\u00e1t Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26096",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26097",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26098",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26099",
            name: "Vi \u0110\u1ee9c L\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26100",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26101",
            name: "V\u01b0\u01a1ng V\u0103n Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26102",
            name: "X\u01b0\u01a1ng Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26103",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2942",
            name: "B\u00e1ch Vi\u1ec7t Lake Garden",
            lat: "21.282978057861",
            lng: "106.2190246582",
          },
          {
            id: "2943",
            name: "Dream Town B\u1eafc Giang",
            lat: "21.271017074585",
            lng: "106.19485473633",
          },
          {
            id: "2944",
            name: "Golden Avenue",
            lat: "21.275135040283",
            lng: "106.20174407959",
          },
          {
            id: "2945",
            name: "Royal Park B\u1eafc Giang",
            lat: "21.273805618286",
            lng: "106.19660186768",
          },
        ],
      },
      {
        id: "368",
        name: "Hi\u1ec7p H\u00f2a",
        wards: [
          { id: "5840", name: "B\u1eafc L\u00fd", prefix: "X\u00e3" },
          {
            id: "5841",
            name: "B\u00e1ch Nh\u1eabn",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "5842", name: "Ch\u00e2u Minh", prefix: "X\u00e3" },
          { id: "5844", name: "Danh Th\u1eafng", prefix: "X\u00e3" },
          { id: "5843", name: "\u0110\u1ea1i Th\u00e0nh", prefix: "X\u00e3" },
          { id: "5845", name: "\u0110oan B\u00e1i", prefix: "X\u00e3" },
          { id: "5846", name: "\u0110\u00f4ng L\u1ed7", prefix: "X\u00e3" },
          { id: "5847", name: "\u0110\u1ed3ng T\u00e2n", prefix: "X\u00e3" },
          { id: "5848", name: "\u0110\u1ee9c Th\u1eafng", prefix: "X\u00e3" },
          { id: "5849", name: "H\u00f2a S\u01a1n", prefix: "X\u00e3" },
          { id: "5850", name: "Ho\u00e0ng An", prefix: "X\u00e3" },
          { id: "5851", name: "Ho\u00e0ng L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "5852", name: "Ho\u00e0ng Thanh", prefix: "X\u00e3" },
          { id: "5853", name: "Ho\u00e0ng V\u00e2n", prefix: "X\u00e3" },
          { id: "5854", name: "H\u1ee3p Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "5855", name: "H\u00f9ng S\u01a1n", prefix: "X\u00e3" },
          { id: "5856", name: "H\u01b0\u01a1ng L\u00e2m", prefix: "X\u00e3" },
          { id: "5857", name: "L\u01b0\u01a1ng Phong", prefix: "X\u00e3" },
          { id: "5858", name: "Mai \u0110\u00ecnh", prefix: "X\u00e3" },
          { id: "5859", name: "Mai Trung", prefix: "X\u00e3" },
          { id: "5860", name: "Ng\u1ecdc S\u01a1n", prefix: "X\u00e3" },
          { id: "5861", name: "Quang Minh", prefix: "X\u00e3" },
          { id: "5862", name: "Th\u00e1i S\u01a1n", prefix: "X\u00e3" },
          { id: "5863", name: "Th\u1eafng", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "5864", name: "Thanh V\u00e2n", prefix: "X\u00e3" },
          {
            id: "5865",
            name: "Th\u01b0\u1eddng Th\u1eafng",
            prefix: "X\u00e3",
          },
          { id: "5866", name: "Xu\u00e2n C\u1ea9m", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "26104", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26105", name: "295", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26106", name: "296", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26107", name: "37", prefix: "Ph\u1ed1" },
          {
            id: "26108",
            name: "B\u1eafc L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26109",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26110",
            name: "Ho\u00e0ng L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26111",
            name: "Ho\u00e0ng V\u0103n Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26112",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26113",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26114",
            name: "Qu\u1ed1c L\u1ed9 296",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26115",
            name: "Qu\u1ed1c l\u1ed9 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26116",
            name: "T\u1ec9nh L\u1ed9 288",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26117",
            name: "T\u1ec9nh l\u1ed9 295",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26118",
            name: "T\u1ec9nh l\u1ed9 296",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26119",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26120",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2946",
            name: "KCN H\u00f2a Ph\u00fa",
            lat: "21.234251022339",
            lng: "105.97020721436",
          },
        ],
      },
      {
        id: "369",
        name: "L\u1ea1ng Giang",
        wards: [
          { id: "5867", name: "An H\u00e0", prefix: "X\u00e3" },
          {
            id: "5870",
            name: "D\u01b0\u01a1ng \u0110\u1ee9c",
            prefix: "X\u00e3",
          },
          { id: "5868", name: "\u0110\u1ea1i L\u00e2m", prefix: "X\u00e3" },
          { id: "5869", name: "\u0110\u00e0o M\u1ef9", prefix: "X\u00e3" },
          { id: "5871", name: "H\u01b0\u01a1ng L\u1ea1c", prefix: "X\u00e3" },
          { id: "5872", name: "H\u01b0\u01a1ng S\u01a1n", prefix: "X\u00e3" },
          { id: "5873", name: "K\u00e9p", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "5874", name: "M\u1ef9 H\u00e0", prefix: "X\u00e3" },
          { id: "5875", name: "M\u1ef9 Th\u00e1i", prefix: "X\u00e3" },
          { id: "5876", name: "Ngh\u0129a H\u00f2a", prefix: "X\u00e3" },
          { id: "5877", name: "Ngh\u0129a H\u01b0ng", prefix: "X\u00e3" },
          { id: "5878", name: "Phi M\u00f4", prefix: "X\u00e3" },
          { id: "5879", name: "Quang Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "5880", name: "T\u00e2n D\u0129nh", prefix: "X\u00e3" },
          { id: "5881", name: "T\u00e2n H\u01b0ng", prefix: "X\u00e3" },
          { id: "5882", name: "T\u00e2n Thanh", prefix: "X\u00e3" },
          { id: "5883", name: "T\u00e2n Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "5884", name: "Th\u00e1i \u0110\u00e0o", prefix: "X\u00e3" },
          { id: "5885", name: "Ti\u00ean L\u1ee5c", prefix: "X\u00e3" },
          { id: "5886", name: "V\u00f4i", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "5887", name: "Xu\u00e2n H\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "5888", name: "X\u01b0\u01a1ng L\u00e2m", prefix: "X\u00e3" },
          { id: "5889", name: "Y\u00ean M\u1ef9", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "26121", name: "31", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26122",
            name: "\u0110\u1ea1i L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26123",
            name: "M\u1ea3i H\u1ea1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26124",
            name: "Ph\u1ea1m V\u0103n Li\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26125",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26126",
            name: "Qu\u1ed1c l\u1ed9 31",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26127",
            name: "T\u1ec9nh l\u1ed9 265",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26128",
            name: "T\u1ec9nh L\u1ed9 292",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26129",
            name: "T\u1ec9nh l\u1ed9 295",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26130", name: "V\u00f4i", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26131",
            name: "Y\u00ean Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "370",
        name: "L\u1ee5c Nam",
        wards: [
          { id: "5890", name: "B\u1eafc L\u0169ng", prefix: "X\u00e3" },
          { id: "5891", name: "B\u1ea3o \u0110\u00e0i", prefix: "X\u00e3" },
          { id: "5892", name: "B\u1ea3o S\u01a1n", prefix: "X\u00e3" },
          { id: "5893", name: "B\u00ecnh S\u01a1n", prefix: "X\u00e3" },
          { id: "5894", name: "C\u1ea9m L\u00fd", prefix: "X\u00e3" },
          { id: "5895", name: "Chu \u0110i\u1ec7n", prefix: "X\u00e3" },
          { id: "5896", name: "C\u01b0\u01a1ng S\u01a1n", prefix: "X\u00e3" },
          { id: "5897", name: "\u0110an H\u1ed9i", prefix: "X\u00e3" },
          {
            id: "5898",
            name: "\u0110\u1ed3i Ng\u00f4",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "5899", name: "\u0110\u00f4ng H\u01b0ng", prefix: "X\u00e3" },
          { id: "5900", name: "\u0110\u00f4ng Ph\u00fa", prefix: "X\u00e3" },
          { id: "5901", name: "Huy\u1ec1n S\u01a1n", prefix: "X\u00e3" },
          { id: "5902", name: "Kh\u00e1m L\u1ea1ng", prefix: "X\u00e3" },
          { id: "5903", name: "Lan M\u1eabu", prefix: "X\u00e3" },
          { id: "5904", name: "L\u1ee5c Nam", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "5905", name: "L\u1ee5c S\u01a1n", prefix: "X\u00e3" },
          {
            id: "5906",
            name: "Ngh\u0129a Ph\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "5907", name: "Ph\u01b0\u01a1ng S\u01a1n", prefix: "X\u00e3" },
          { id: "5908", name: "Tam D\u1ecb", prefix: "X\u00e3" },
          { id: "5909", name: "Thanh L\u00e2m", prefix: "X\u00e3" },
          { id: "5910", name: "Ti\u00ean H\u01b0ng", prefix: "X\u00e3" },
          { id: "5911", name: "Ti\u00ean Nha", prefix: "X\u00e3" },
          { id: "5912", name: "Tr\u01b0\u1eddng Giang", prefix: "X\u00e3" },
          { id: "5913", name: "Tr\u01b0\u1eddng S\u01a1n", prefix: "X\u00e3" },
          { id: "5914", name: "V\u00f4 Tranh", prefix: "X\u00e3" },
          { id: "5915", name: "V\u0169 X\u00e1", prefix: "X\u00e3" },
          { id: "5916", name: "Y\u00ean S\u01a1n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "26132", name: "31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26133", name: "37", prefix: "Ph\u1ed1" },
          {
            id: "26134",
            name: "Qu\u1ed1c l\u1ed9 31",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26135",
            name: "Qu\u1ed1c l\u1ed9 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26136",
            name: "Thanh H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26137",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26138",
            name: "T\u1ec9nh l\u1ed9 293",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2947",
            name: "Khu \u0111\u00f4 th\u1ecb m\u1edbi \u0110\u1ed3ng C\u1eeda",
            lat: "21.304191589355",
            lng: "106.38647460938",
          },
        ],
      },
      {
        id: "371",
        name: "L\u1ee5c Ng\u1ea1n",
        wards: [
          { id: "5917", name: "Bi\u1ec3n \u0110\u1ed9ng", prefix: "X\u00e3" },
          { id: "5918", name: "Bi\u00ean S\u01a1n", prefix: "X\u00e3" },
          { id: "5919", name: "C\u1ea5m S\u01a1n", prefix: "X\u00e3" },
          { id: "5920", name: "Ch\u0169", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "5921", name: "\u0110\u00e8o Gia", prefix: "X\u00e3" },
          { id: "5922", name: "\u0110\u1ed3ng C\u1ed1c", prefix: "X\u00e3" },
          { id: "5923", name: "Gi\u00e1p S\u01a1n", prefix: "X\u00e3" },
          { id: "5924", name: "H\u1ed9 \u0110\u00e1p", prefix: "X\u00e3" },
          { id: "5925", name: "H\u1ed3ng Giang", prefix: "X\u00e3" },
          { id: "5926", name: "Ki\u00ean Lao", prefix: "X\u00e3" },
          { id: "5927", name: "Ki\u00ean Th\u00e0nh", prefix: "X\u00e3" },
          { id: "5928", name: "Kim S\u01a1n", prefix: "X\u00e3" },
          { id: "5929", name: "M\u1ef9 An", prefix: "X\u00e3" },
          { id: "5930", name: "Nam D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "5931", name: "Ngh\u0129a H\u1ed3", prefix: "X\u00e3" },
          { id: "5932", name: "Ph\u00ec \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "5933", name: "Phong Minh", prefix: "X\u00e3" },
          { id: "5934", name: "Phong V\u00e2n", prefix: "X\u00e3" },
          { id: "5935", name: "Ph\u00fa Nhu\u1eadn", prefix: "X\u00e3" },
          { id: "5936", name: "Ph\u01b0\u1ee3ng S\u01a1n", prefix: "X\u00e3" },
          { id: "5937", name: "Qu\u00fd S\u01a1n", prefix: "X\u00e3" },
          { id: "5938", name: "Sa L\u00fd", prefix: "X\u00e3" },
          { id: "5939", name: "S\u01a1n H\u1ea3i", prefix: "X\u00e3" },
          { id: "5940", name: "T\u00e2n Hoa", prefix: "X\u00e3" },
          { id: "5941", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "5942", name: "T\u00e2n M\u1ed9c", prefix: "X\u00e3" },
          { id: "5943", name: "T\u00e2n Quang", prefix: "X\u00e3" },
          { id: "5944", name: "T\u00e2n S\u01a1n", prefix: "X\u00e3" },
          { id: "5945", name: "Thanh H\u1ea3i", prefix: "X\u00e3" },
          { id: "5946", name: "Tr\u00f9 H\u1ef1u", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "26139", name: "31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26140", name: "Kim", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26141",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26142",
            name: "Qu\u1ed1c l\u1ed9 279",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26143",
            name: "Qu\u1ed1c l\u1ed9 31",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26144",
            name: "T\u1ec9nh l\u1ed9 285",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "372",
        name: "S\u01a1n \u0110\u1ed9ng",
        wards: [
          { id: "5947", name: "An B\u00e1", prefix: "X\u00e3" },
          { id: "5948", name: "An Ch\u00e2u", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "5949", name: "An Ch\u00e2u", prefix: "X\u00e3" },
          { id: "5950", name: "An L\u1ea1c", prefix: "X\u00e3" },
          { id: "5951", name: "An L\u1eadp", prefix: "X\u00e3" },
          { id: "5952", name: "B\u1ed3ng Am", prefix: "X\u00e3" },
          { id: "5953", name: "C\u1ea9m \u0110\u00e0n", prefix: "X\u00e3" },
          { id: "5954", name: "Chi\u00ean S\u01a1n", prefix: "X\u00e3" },
          { id: "5955", name: "D\u01b0\u01a1ng H\u01b0u", prefix: "X\u00e3" },
          { id: "5956", name: "Gi\u00e1o Li\u00eam", prefix: "X\u00e3" },
          { id: "5957", name: "H\u1eefu S\u1ea3n", prefix: "X\u00e3" },
          { id: "5958", name: "L\u1ec7 Vi\u1ec5n", prefix: "X\u00e3" },
          { id: "5959", name: "Long S\u01a1n", prefix: "X\u00e3" },
          { id: "5960", name: "Ng\u1ecdc Ch\u00e2u", prefix: "X\u00e3" },
          { id: "5961", name: "Ph\u00fac Th\u1eafng", prefix: "X\u00e3" },
          { id: "5962", name: "Qu\u1ebf S\u01a1n", prefix: "X\u00e3" },
          { id: "5963", name: "Th\u1ea1ch S\u01a1n", prefix: "X\u00e3" },
          { id: "5964", name: "Thanh Lu\u1eadn", prefix: "X\u00e3" },
          { id: "5965", name: "Thanh S\u01a1n", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "5966", name: "Tu\u1ea5n \u0110\u1ea1o", prefix: "X\u00e3" },
          { id: "5967", name: "Tu\u1ea5n M\u1eadu", prefix: "X\u00e3" },
          { id: "5968", name: "V\u00e2n S\u01a1n", prefix: "X\u00e3" },
          { id: "5969", name: "V\u0129nh Kh\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "5970", name: "Y\u00ean \u0110\u1ecbnh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "26145", name: "31", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26146",
            name: "Qu\u1ed1c l\u1ed9 279",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26147",
            name: "Qu\u1ed1c l\u1ed9 31",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26148",
            name: "T\u1ec9nh l\u1ed9 293",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26149",
            name: "T\u1ec9nh l\u1ed9 330",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "373",
        name: "T\u00e2n Y\u00ean",
        wards: [
          { id: "5971", name: "An D\u01b0\u01a1ng", prefix: "X\u00e3" },
          {
            id: "5972",
            name: "Cao Th\u01b0\u1ee3ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "5973", name: "Cao Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "5974", name: "Cao X\u00e1", prefix: "X\u00e3" },
          { id: "5975", name: "\u0110\u1ea1i H\u00f3a", prefix: "X\u00e3" },
          { id: "5976", name: "H\u1ee3p \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "5977", name: "Lam C\u1ed1t", prefix: "X\u00e3" },
          { id: "5978", name: "Lan Gi\u1edbi", prefix: "X\u00e3" },
          { id: "5979", name: "Li\u00ean Chung", prefix: "X\u00e3" },
          { id: "5980", name: "Li\u00ean S\u01a1n", prefix: "X\u00e3" },
          { id: "5981", name: "Ng\u1ecdc Ch\u00e2u", prefix: "X\u00e3" },
          { id: "5982", name: "Ng\u1ecdc L\u00fd", prefix: "X\u00e3" },
          { id: "5983", name: "Ng\u1ecdc Thi\u1ec7n", prefix: "X\u00e3" },
          { id: "5984", name: "Ng\u1ecdc V\u00e2n", prefix: "X\u00e3" },
          { id: "5985", name: "Nh\u00e3 Nam", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "5986", name: "Nh\u00e3 Nam", prefix: "X\u00e3" },
          { id: "5987", name: "Ph\u00fac H\u00f2a", prefix: "X\u00e3" },
          { id: "5988", name: "Ph\u00fac S\u01a1n", prefix: "X\u00e3" },
          { id: "5989", name: "Quang Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "5990", name: "Qu\u1ebf Nham", prefix: "X\u00e3" },
          { id: "5991", name: "Song V\u00e2n", prefix: "X\u00e3" },
          { id: "5992", name: "T\u00e2n Trung", prefix: "X\u00e3" },
          { id: "5993", name: "Vi\u1ec7t L\u1eadp", prefix: "X\u00e3" },
          { id: "5994", name: "Vi\u1ec7t Ng\u1ecdc", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "26150", name: "284", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26151", name: "398", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26152",
            name: "Cao Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26153",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26154",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26155",
            name: "Kim Tr\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26156",
            name: "Qu\u1ed1c L\u1ed9 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26157",
            name: "T\u00e2n Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26158",
            name: "T\u1ec9nh l\u1ed9 284",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26159",
            name: "T\u1ec9nh l\u1ed9 287",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26160",
            name: "T\u1ec9nh L\u1ed9 294",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26161",
            name: "T\u1ec9nh l\u1ed9 295",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2948",
            name: "An Huy Cao Th\u01b0\u1ee3ng",
            lat: "21.387355804443",
            lng: "106.12707519531",
          },
        ],
      },
      {
        id: "374",
        name: "Vi\u1ec7t Y\u00ean",
        wards: [
          {
            id: "5995",
            name: "B\u00edch \u0110\u1ed9ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "5996", name: "B\u00edch S\u01a1n", prefix: "X\u00e3" },
          { id: "5997", name: "Ho\u00e0ng Ninh", prefix: "X\u00e3" },
          { id: "5998", name: "H\u1ed3ng Th\u00e1i", prefix: "X\u00e3" },
          { id: "5999", name: "H\u01b0\u01a1ng Mai", prefix: "X\u00e3" },
          { id: "6000", name: "Minh \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "6001", name: "N\u1ebfnh", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6002", name: "N\u1ebfnh", prefix: "X\u00e3" },
          { id: "6003", name: "Ngh\u0129a Trung", prefix: "X\u00e3" },
          { id: "6004", name: "Ninh S\u01a1n", prefix: "X\u00e3" },
          { id: "6005", name: "Quang Ch\u00e2u", prefix: "X\u00e3" },
          { id: "6006", name: "Qu\u1ea3ng Minh", prefix: "X\u00e3" },
          { id: "6007", name: "T\u0103ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "6008", name: "Th\u01b0\u1ee3ng Lan", prefix: "X\u00e3" },
          { id: "6009", name: "Ti\u00ean S\u01a1n", prefix: "X\u00e3" },
          { id: "6010", name: "Trung S\u01a1n", prefix: "X\u00e3" },
          { id: "6011", name: "T\u1ef1 Lan", prefix: "X\u00e3" },
          { id: "6012", name: "V\u00e2n H\u00e0", prefix: "X\u00e3" },
          { id: "6013", name: "V\u00e2n Trung", prefix: "X\u00e3" },
          { id: "6014", name: "Vi\u1ec7t Ti\u1ebfn", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "26162", name: "34", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26163", name: "37", prefix: "Ph\u1ed1" },
          {
            id: "26164",
            name: "Nguy\u1ec5n Th\u1ebf Nho",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26165",
            name: "Nh\u01b0 Thi\u00ea\u0301t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26166",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26167",
            name: "Qu\u1ed1c l\u1ed9 34",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26168",
            name: "Qu\u1ed1c l\u1ed9 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26169",
            name: "Th\u00e2n Nh\u00e2n Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26170",
            name: "T\u1ec9nh l\u1ed9 284",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26171",
            name: "T\u1ec9nh L\u1ed9 295B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26172",
            name: "T\u1ec9nh L\u1ed9 298",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26173", name: "Tr\u00e0ng", prefix: "Ph\u1ed1" },
        ],
        projects: [
          {
            id: "2949",
            name: "Vi\u1ec7t Y\u00ean Central Park",
            lat: "21.27024269104",
            lng: "106.10186004639",
          },
        ],
      },
      {
        id: "375",
        name: "Y\u00ean D\u0169ng",
        wards: [
          { id: "6015", name: "C\u1ea3nh Th\u1ee5y", prefix: "X\u00e3" },
          { id: "6016", name: "\u0110\u1ed3ng Ph\u00fac", prefix: "X\u00e3" },
          { id: "6017", name: "\u0110\u1ed3ng T\u00e2m", prefix: "X\u00e3" },
          { id: "6018", name: "\u0110\u1ed3ng Vi\u1ec7t", prefix: "X\u00e3" },
          { id: "6019", name: "\u0110\u1ee9c Giang", prefix: "X\u00e3" },
          { id: "6020", name: "H\u1ed3ng K\u1ef3", prefix: "X\u00e3" },
          { id: "6021", name: "H\u01b0\u01a1ng Gi\u00e1n", prefix: "X\u00e3" },
          { id: "6022", name: "L\u00e3ng S\u01a1n", prefix: "X\u00e3" },
          { id: "6023", name: "L\u00e3o H\u1ed9", prefix: "X\u00e3" },
          { id: "6024", name: "Neo", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6025", name: "Nham S\u01a1n", prefix: "X\u00e3" },
          { id: "6026", name: "N\u1ed9i Ho\u00e0ng", prefix: "X\u00e3" },
          { id: "6027", name: "Qu\u1ef3nh S\u01a1n", prefix: "X\u00e3" },
          { id: "6028", name: "T\u00e2n An", prefix: "X\u00e3" },
          {
            id: "6029",
            name: "T\u00e2n D\u00e2n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6030", name: "T\u00e2n Li\u1ec5u", prefix: "X\u00e3" },
          { id: "6031", name: "Th\u1eafng C\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "6032", name: "Ti\u1ebfn D\u0169ng", prefix: "X\u00e3" },
          { id: "6033", name: "Ti\u1ec1n Phong", prefix: "X\u00e3" },
          { id: "6034", name: "Tr\u00ed Y\u00ean", prefix: "X\u00e3" },
          { id: "6035", name: "T\u01b0 M\u1ea1i", prefix: "X\u00e3" },
          { id: "6036", name: "Xu\u00e2n Ph\u00fa", prefix: "X\u00e3" },
          { id: "6037", name: "Y\u00ean L\u01b0", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "26174", name: "284", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26175", name: "398", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26176", name: "Ba T\u1ed5ng", prefix: "Ph\u1ed1" },
          {
            id: "26177",
            name: "\u0110\u00e0o S\u01b0 T\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26178",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26179", name: "L\u00e0n 2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26180",
            name: "L\u00ea \u0110\u1ee9c Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26181",
            name: "L\u01b0u Vi\u1ebft Tho\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26182",
            name: "Ng\u00f4 U\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26183",
            name: "Nguy\u1ec5n Vi\u1ebft Ch\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26184",
            name: "Ph\u1ea1m T\u00fac Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26185",
            name: "Ph\u00e1p Loa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26186",
            name: "T\u1ec9nh l\u1ed9 299",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26187",
            name: "T\u1ec9nh L\u1ed9 398",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26188",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26189",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2950",
            name: "Gwin Garden",
            lat: "21.204030990601",
            lng: "106.24338531494",
          },
          {
            id: "2951",
            name: "KCN Song Kh\u00ea - N\u1ed9i Ho\u00e0ng",
            lat: "21.235832214355",
            lng: "106.15515136719",
          },
          {
            id: "2952",
            name: "KDC L\u1ea1c Ph\u00fa",
            lat: "21.200159072876",
            lng: "106.22804260254",
          },
        ],
      },
      {
        id: "376",
        name: "Y\u00ean Th\u1ebf",
        wards: [
          { id: "6038", name: "An Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "6039", name: "B\u1ed1 H\u1ea1", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6040", name: "B\u1ed1 H\u1ea1", prefix: "X\u00e3" },
          { id: "6041", name: "Canh N\u1eadu", prefix: "X\u00e3" },
          {
            id: "6042",
            name: "C\u1ea7u G\u1ed3",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6043", name: "\u0110\u1ed3ng H\u01b0u", prefix: "X\u00e3" },
          { id: "6044", name: "\u0110\u1ed3ng K\u1ef3", prefix: "X\u00e3" },
          { id: "6045", name: "\u0110\u1ed3ng L\u1ea1c", prefix: "X\u00e3" },
          { id: "6046", name: "\u0110\u00f4ng S\u01a1n", prefix: "X\u00e3" },
          { id: "6047", name: "\u0110\u1ed3ng Ti\u1ebfn", prefix: "X\u00e3" },
          {
            id: "6048",
            name: "\u0110\u1ed3ng V\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          {
            id: "6049",
            name: "H\u01b0\u01a1ng V\u0129",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6050", name: "Ph\u1ed3n X\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "6051", name: "Tam Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "6052", name: "Tam Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "6053", name: "T\u00e2n Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "6054", name: "T\u00e2n S\u1ecfi", prefix: "X\u00e3" },
          { id: "6055", name: "Ti\u1ebfn Th\u1eafng", prefix: "X\u00e3" },
          { id: "6056", name: "Xu\u00e2n L\u01b0\u01a1ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "26190", name: "265", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26191",
            name: "C\u1ea7u G\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26192",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26193",
            name: "T\u1ec9nh l\u1ed9 242",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26194",
            name: "T\u1ec9nh l\u1ed9 265",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26195",
            name: "T\u1ec9nh l\u1ed9 287",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26196",
            name: "T\u1ec9nh L\u1ed9 294",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26197",
            name: "T\u1ec9nh l\u1ed9 295",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "29",
    code: "HB",
    name: "H\u00f2a B\u00ecnh",
    districts: [
      {
        id: "377",
        name: "Cao Phong",
        wards: [
          { id: "6057", name: "B\u1eafc Phong", prefix: "X\u00e3" },
          { id: "6058", name: "B\u00ecnh Thanh", prefix: "X\u00e3" },
          { id: "6059", name: "Cao Phong", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6061", name: "D\u0169ng Phong", prefix: "X\u00e3" },
          { id: "6060", name: "\u0110\u00f4ng Phong", prefix: "X\u00e3" },
          { id: "6062", name: "Nam Phong", prefix: "X\u00e3" },
          { id: "6063", name: "T\u00e2n Phong", prefix: "X\u00e3" },
          { id: "6064", name: "T\u00e2y Phong", prefix: "X\u00e3" },
          { id: "6065", name: "Thu Phong", prefix: "X\u00e3" },
          { id: "6066", name: "Thung Nai", prefix: "X\u00e3" },
          { id: "6067", name: "Xu\u00e2n Phong", prefix: "X\u00e3" },
          { id: "6068", name: "Y\u00ean L\u1eadp", prefix: "X\u00e3" },
          { id: "6069", name: "Y\u00ean Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "378",
        name: "\u0110\u00e0 B\u1eafc",
        wards: [
          { id: "6070", name: "Cao S\u01a1n", prefix: "X\u00e3" },
          {
            id: "6071",
            name: "\u0110\u00e0 B\u1eafc",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6072", name: "\u0110o\u00e0n K\u1ebft", prefix: "X\u00e3" },
          { id: "6073", name: "\u0110\u1ed3ng Chum", prefix: "X\u00e3" },
          { id: "6074", name: "\u0110\u1ed3ng Ngh\u00ea", prefix: "X\u00e3" },
          { id: "6075", name: "\u0110\u1ed3ng Ru\u1ed9ng", prefix: "X\u00e3" },
          { id: "6076", name: "Gi\u00e1p \u0110\u1eaft", prefix: "X\u00e3" },
          { id: "6077", name: "H\u00e0o L\u00fd", prefix: "X\u00e3" },
          { id: "6078", name: "Hi\u1ec1n L\u01b0\u01a1ng", prefix: "X\u00e3" },
          {
            id: "6079",
            name: "M\u01b0\u1eddng Chi\u1ec1ng",
            prefix: "X\u00e3",
          },
          { id: "6080", name: "M\u01b0\u1eddng Tu\u1ed5ng", prefix: "X\u00e3" },
          { id: "6081", name: "Su\u1ed1i N\u00e1nh", prefix: "X\u00e3" },
          { id: "6082", name: "T\u00e2n Minh", prefix: "X\u00e3" },
          { id: "6083", name: "T\u00e2n Pheo", prefix: "X\u00e3" },
          { id: "6084", name: "Ti\u1ec1n Phong", prefix: "X\u00e3" },
          { id: "6085", name: "To\u00e0n S\u01a1n", prefix: "X\u00e3" },
          { id: "6086", name: "Trung Th\u00e0nh", prefix: "X\u00e3" },
          { id: "6087", name: "Tu L\u00fd", prefix: "X\u00e3" },
          { id: "6088", name: "V\u1ea7y N\u01b0a", prefix: "X\u00e3" },
          { id: "6089", name: "Y\u00ean Ho\u00e0", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "379",
        name: "H\u00f2a B\u00ecnh",
        wards: [
          {
            id: "6090",
            name: "Ch\u0103m M\u00e1t",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "6091", name: "D\u00e2n Ch\u1ee7", prefix: "X\u00e3" },
          {
            id: "6092",
            name: "\u0110\u1ed3ng Ti\u1ebfn",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "6093", name: "H\u00f2a B\u00ecnh", prefix: "X\u00e3" },
          {
            id: "6094",
            name: "H\u1eefu Ngh\u1ecb",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "6095",
            name: "Ph\u01b0\u01a1ng L\u00e2m",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "6096", name: "S\u1ee7 Ng\u00f2i", prefix: "X\u00e3" },
          { id: "6097", name: "T\u00e2n H\u00f2a", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "6098",
            name: "T\u00e2n Th\u1ecbnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "6099",
            name: "Th\u00e1i B\u00ecnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "6100", name: "Th\u00e1i Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "6101", name: "Th\u1ecbnh Lang", prefix: "Ph\u01b0\u1eddng" },
          { id: "6102", name: "Th\u1ed1ng Nh\u1ea5t", prefix: "X\u00e3" },
          { id: "6103", name: "Trung Minh", prefix: "X\u00e3" },
          { id: "6104", name: "Y\u00ean M\u00f4ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "26198", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26199",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26200",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26201",
            name: "B\u00e0 \u0110\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26202", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "26203",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26204",
            name: "Chi L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26205",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26206",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26207",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26208",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26209", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "26210",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26211",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26212",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26213",
            name: "H\u1eefu Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26214",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26215",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26216",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26217",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26218",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26219",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26220",
            name: "Mai Th\u00fac Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26221", name: "Minh Khai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26222",
            name: "Nguy\u1ec5n Bi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26223",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26224",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26225",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26226",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26227",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26228",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26229",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26230", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "26231",
            name: "Qu\u1ed1c l\u1ed9 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26232",
            name: "Qu\u1ed1c l\u1ed9 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26233",
            name: "T\u00e2n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26234",
            name: "T\u0103ng B\u1ea1t H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26235",
            name: "Th\u1ecbnh Lang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26236",
            name: "Th\u1ecbnh Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26237",
            name: "Ti\u1ec3u Khu 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26238",
            name: "T\u1ec9nh L\u1ed9 437",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26239",
            name: "T\u00f4n Th\u1ea5t Thuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26240",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26241",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26242",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26243",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26244",
            name: "Tri\u1ec7u Ph\u00fac L\u1ecbch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26245",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2953",
            name: "KDC B\u1eafc \u0110\u01b0\u1eddng Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            lat: "20.823257446289",
            lng: "105.34366607666",
          },
        ],
      },
      {
        id: "380",
        name: "Kim B\u00f4i",
        wards: [
          { id: "6105", name: "B\u1eafc S\u01a1n", prefix: "X\u00e3" },
          { id: "6106", name: "B\u00ecnh S\u01a1n", prefix: "X\u00e3" },
          { id: "6107", name: "Bo", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6108", name: "Cu\u1ed1i H\u1ea1", prefix: "X\u00e3" },
          { id: "6109", name: "\u0110\u00f4ng B\u1eafc", prefix: "X\u00e3" },
          { id: "6110", name: "\u0110\u00fa S\u00e1ng", prefix: "X\u00e3" },
          { id: "6111", name: "H\u1ea1 B\u00ec", prefix: "X\u00e3" },
          { id: "6112", name: "H\u1ee3p \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "6113", name: "H\u1ee3p Kim", prefix: "X\u00e3" },
          { id: "6114", name: "H\u00f9ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "6115", name: "Kim B\u00ecnh", prefix: "X\u00e3" },
          { id: "6116", name: "Kim B\u00f4i", prefix: "X\u00e3" },
          { id: "6117", name: "Kim S\u01a1n", prefix: "X\u00e3" },
          { id: "6118", name: "Kim Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "6119", name: "Kim Truy", prefix: "X\u00e3" },
          { id: "6120", name: "L\u1eadp Chi\u1ec7ng", prefix: "X\u00e3" },
          { id: "6121", name: "M\u1ecb H\u00f2a", prefix: "X\u00e3" },
          { id: "6122", name: "Nam Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "6123", name: "N\u1eadt S\u01a1n", prefix: "X\u00e3" },
          { id: "6124", name: "Nu\u00f4ng D\u0103m", prefix: "X\u00e3" },
          { id: "6125", name: "S\u00e0o B\u00e1y", prefix: "X\u00e3" },
          { id: "6126", name: "S\u01a1n Th\u1ee7y", prefix: "X\u00e3" },
          { id: "6127", name: "Th\u01b0\u1ee3ng B\u00ec", prefix: "X\u00e3" },
          { id: "6128", name: "Th\u01b0\u1ee3ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "6129", name: "Trung B\u00ec", prefix: "X\u00e3" },
          { id: "6130", name: "T\u00fa S\u01a1n", prefix: "X\u00e3" },
          { id: "6131", name: "V\u0129nh \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "6132", name: "V\u0129nh Ti\u1ebfn", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "26246",
            name: "B\u01b0\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "381",
        name: "K\u1ef3 S\u01a1n",
        wards: [
          { id: "6133", name: "D\u00e2n H\u1ea1", prefix: "X\u00e3" },
          { id: "6134", name: "D\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "6135", name: "\u0110\u1ed9c L\u1eadp", prefix: "X\u00e3" },
          { id: "6136", name: "H\u1ee3p Th\u00e0nh", prefix: "X\u00e3" },
          { id: "6137", name: "H\u1ee3p Th\u1ecbnh", prefix: "X\u00e3" },
          {
            id: "6138",
            name: "K\u1ef3 S\u01a1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6139", name: "M\u00f4ng H\u00f3a", prefix: "X\u00e3" },
          { id: "6140", name: "Ph\u00fa Minh", prefix: "X\u00e3" },
          { id: "6141", name: "Ph\u00fac Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "6142", name: "Y\u00ean Quang", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "26247", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26248", name: "B\u00e3i Nai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26249",
            name: "Qu\u1ed1c l\u1ed9 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26250",
            name: "T\u1ec9nh L\u1ed9 446",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26251",
            name: "V\u0103n Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2954",
            name: "First Villa",
            lat: "20.903394699097",
            lng: "105.4126663208",
          },
          {
            id: "2955",
            name: "Kai Resort",
            lat: "20.903394699097",
            lng: "105.4126663208",
          },
          {
            id: "2956",
            name: "L\u1ea1c H\u1ed3ng Vi\u00ean",
            lat: "20.898113250732",
            lng: "105.42193603516",
          },
          {
            id: "2957",
            name: "Mercury Villas",
            lat: "20.908117294312",
            lng: "105.41471099854",
          },
          {
            id: "2958",
            name: "Ohara Villa & Resort",
            lat: "20.924451828003",
            lng: "105.43609619141",
          },
          {
            id: "2959",
            name: "The Melody Villas",
            lat: "20.901704788208",
            lng: "105.38931274414",
          },
        ],
      },
      {
        id: "382",
        name: "L\u1ea1c S\u01a1n",
        wards: [
          { id: "6143", name: "\u00c2n Ngh\u0129a", prefix: "X\u00e3" },
          { id: "6144", name: "B\u00ecnh C\u1ea3ng", prefix: "X\u00e3" },
          { id: "6145", name: "B\u00ecnh Ch\u00e2n", prefix: "X\u00e3" },
          { id: "6146", name: "B\u00ecnh H\u1ebbm", prefix: "X\u00e3" },
          { id: "6147", name: "Ch\u00ed \u0110\u1ea1o", prefix: "X\u00e3" },
          { id: "6148", name: "Ch\u00ed Thi\u1ec7n", prefix: "X\u00e3" },
          { id: "6149", name: "\u0110\u1ecbnh C\u01b0", prefix: "X\u00e3" },
          {
            id: "6150",
            name: "H\u01b0\u01a1ng Nh\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
          { id: "6151", name: "Li\u00ean V\u0169", prefix: "X\u00e3" },
          { id: "6152", name: "Mi\u1ec1n \u0110\u1ed3i", prefix: "X\u00e3" },
          { id: "6153", name: "M\u1ef9 Th\u00e0nh", prefix: "X\u00e3" },
          { id: "6154", name: "Ng\u1ecdc L\u00e2u", prefix: "X\u00e3" },
          { id: "6155", name: "Ng\u1ecdc S\u01a1n", prefix: "X\u00e3" },
          { id: "6156", name: "Nh\u00e2n Ngh\u0129a", prefix: "X\u00e3" },
          { id: "6157", name: "Ph\u00fa L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "6158", name: "Ph\u00fac Tuy", prefix: "X\u00e3" },
          { id: "6159", name: "Qu\u00fd Ho\u00e0", prefix: "X\u00e3" },
          { id: "6160", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "6161", name: "T\u00e2n M\u1ef9", prefix: "X\u00e3" },
          { id: "6162", name: "Th\u01b0\u1ee3ng C\u1ed1c", prefix: "X\u00e3" },
          { id: "6163", name: "T\u1ef1 Do", prefix: "X\u00e3" },
          { id: "6164", name: "Tu\u00e2n \u0110\u1ea1o", prefix: "X\u00e3" },
          { id: "6165", name: "V\u0103n Ngh\u0129a", prefix: "X\u00e3" },
          { id: "6166", name: "V\u0103n S\u01a1n", prefix: "X\u00e3" },
          {
            id: "6167",
            name: "V\u1ee5 B\u1ea3n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6168", name: "V\u0169 L\u00e2m", prefix: "X\u00e3" },
          { id: "6169", name: "Xu\u1ea5t H\u00f3a", prefix: "X\u00e3" },
          { id: "6170", name: "Y\u00ean Nghi\u1ec7p", prefix: "X\u00e3" },
          { id: "6171", name: "Y\u00ean Ph\u00fa", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "383",
        name: "L\u1ea1c Th\u1ee7y",
        wards: [
          { id: "6172", name: "An B\u00ecnh", prefix: "X\u00e3" },
          { id: "6173", name: "An L\u1ea1c", prefix: "X\u00e3" },
          { id: "6174", name: "Chi N\u00ea", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6175", name: "C\u1ed1 Ngh\u0129a", prefix: "X\u00e3" },
          { id: "6176", name: "\u0110\u1ed3ng M\u00f4n", prefix: "X\u00e3" },
          { id: "6177", name: "\u0110\u1ed3ng T\u00e2m", prefix: "X\u00e3" },
          { id: "6178", name: "H\u01b0ng Thi", prefix: "X\u00e3" },
          { id: "6179", name: "Khoan D\u1ee5", prefix: "X\u00e3" },
          { id: "6180", name: "L\u1ea1c Long", prefix: "X\u00e3" },
          { id: "6181", name: "Li\u00ean H\u00f2a", prefix: "X\u00e3" },
          { id: "6182", name: "Ph\u00fa L\u00e3o", prefix: "X\u00e3" },
          { id: "6183", name: "Ph\u00fa Th\u00e0nh", prefix: "X\u00e3" },
          { id: "6184", name: "Thanh H\u00e0", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6185", name: "Thanh N\u00f4ng", prefix: "X\u00e3" },
          { id: "6186", name: "Y\u00ean B\u1ed3ng", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "26252",
            name: "Qu\u1ed1c l\u1ed9 21B",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "384",
        name: "L\u01b0\u01a1ng S\u01a1n",
        wards: [
          { id: "6187", name: "Cao D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "6188", name: "Cao R\u0103m", prefix: "X\u00e3" },
          { id: "6189", name: "Cao Th\u1eafng", prefix: "X\u00e3" },
          { id: "6190", name: "C\u01b0 Y\u00ean", prefix: "X\u00e3" },
          { id: "6191", name: "Ho\u00e0 H\u1ee3p", prefix: "X\u00e3" },
          { id: "6192", name: "H\u00f2a S\u01a1n", prefix: "X\u00e3" },
          { id: "6193", name: "H\u1ee3p Ch\u00e2u", prefix: "X\u00e3" },
          { id: "6194", name: "H\u1ee3p H\u00f2a", prefix: "X\u00e3" },
          { id: "6195", name: "H\u1ee3p Thanh", prefix: "X\u00e3" },
          { id: "6196", name: "L\u00e2m S\u01a1n", prefix: "X\u00e3" },
          { id: "6197", name: "Li\u00ean S\u01a1n", prefix: "X\u00e3" },
          { id: "6198", name: "Long S\u01a1n", prefix: "X\u00e3" },
          {
            id: "6199",
            name: "L\u01b0\u01a1ng S\u01a1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6200", name: "Nhu\u1eadn Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "6201", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "6202", name: "T\u00e2n Vinh", prefix: "X\u00e3" },
          { id: "6203", name: "Th\u00e0nh L\u1eadp", prefix: "X\u00e3" },
          { id: "6204", name: "Thanh L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "6205", name: "Ti\u1ebfn S\u01a1n", prefix: "X\u00e3" },
          { id: "6206", name: "Trung S\u01a1n", prefix: "X\u00e3" },
          { id: "6207", name: "Tr\u01b0\u1eddng S\u01a1n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "26253", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26254",
            name: "\u0110\u1ed3ng Chanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26255",
            name: "H\u1ed3 Ch\u00ed Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26256",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26257",
            name: "Qu\u1ed1c l\u1ed9 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26258",
            name: "Qu\u1ed1c l\u1ed9 21A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26259",
            name: "Qu\u1ed1c l\u1ed9 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26260",
            name: "T\u00e2n H\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26261",
            name: "Tr\u01b0\u1eddng S\u01a1n A",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2960",
            name: "Beverly Hills",
            lat: "20.832410812378",
            lng: "105.5532989502",
          },
          {
            id: "2961",
            name: "\u0110\u1ed3i C\u1ecd Villas",
            lat: "20.853116989136",
            lng: "105.55305480957",
          },
          {
            id: "2962",
            name: "Eco Valley resort",
            lat: "20.869297027588",
            lng: "105.56209564209",
          },
          {
            id: "2963",
            name: "Green Oasis Villas",
            lat: "20.870454788208",
            lng: "105.55336761475",
          },
          {
            id: "2964",
            name: "Khu bi\u1ec7t th\u1ef1 V\u01b0\u1eddn \u0110\u00e0o",
            lat: "20.86128616333",
            lng: "105.5532989502",
          },
          {
            id: "2965",
            name: "Khu bi\u1ec7t th\u1ef1 V\u01b0\u1eddn Mai",
            lat: "20.854160308838",
            lng: "105.54200744629",
          },
          {
            id: "2966",
            name: "L\u00e2m S\u01a1n Resort",
            lat: "20.894899368286",
            lng: "105.45761108398",
          },
          {
            id: "2967",
            name: "Mountain Villas",
            lat: "20.836061477661",
            lng: "105.55600738525",
          },
          {
            id: "2968",
            name: "Mountain Villas II",
            lat: "20.842317581177",
            lng: "105.54329681396",
          },
          {
            id: "2969",
            name: "Mountain Villas III",
            lat: "20.833023071289",
            lng: "105.5532913208",
          },
          {
            id: "2970",
            name: "Resort Park Hill",
            lat: "20.832410812378",
            lng: "105.5532989502",
          },
          {
            id: "2971",
            name: "Sunset Villas & Resort",
            lat: "20.861028671265",
            lng: "105.49468994141",
          },
          {
            id: "2972",
            name: "Top Hill Villas",
            lat: "20.901033401489",
            lng: "105.56324768066",
          },
          {
            id: "2973",
            name: "Valley View",
            lat: "20.832410812378",
            lng: "105.5532989502",
          },
          {
            id: "2974",
            name: "Vi\u1ec7t \u00c2u",
            lat: "20.854761123657",
            lng: "105.52408599854",
          },
        ],
      },
      {
        id: "385",
        name: "Mai Ch\u00e2u",
        wards: [
          { id: "6208", name: "Ba Khan", prefix: "X\u00e3" },
          { id: "6209", name: "Bao La", prefix: "X\u00e3" },
          { id: "6210", name: "Chi\u1ec1ng Ch\u00e2u", prefix: "X\u00e3" },
          { id: "6211", name: "Cun Pheo", prefix: "X\u00e3" },
          { id: "6212", name: "\u0110\u1ed3ng B\u1ea3ng", prefix: "X\u00e3" },
          { id: "6213", name: "Hang Kia", prefix: "X\u00e3" },
          { id: "6214", name: "Mai Ch\u00e2u", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6215", name: "Mai H\u1ea1", prefix: "X\u00e3" },
          { id: "6216", name: "Mai H\u1ecbch", prefix: "X\u00e3" },
          { id: "6217", name: "N\u00e0 M\u00e8o", prefix: "X\u00e3" },
          { id: "6218", name: "N\u00e0 Ph\u00f2n", prefix: "X\u00e3" },
          { id: "6219", name: "Noong Lu\u00f4ng", prefix: "X\u00e3" },
          { id: "6220", name: "P\u00e0 C\u00f2", prefix: "X\u00e3" },
          { id: "6221", name: "Ph\u00fac S\u1ea1n", prefix: "X\u00e3" },
          { id: "6222", name: "Pi\u1ec1ng V\u1ebf", prefix: "X\u00e3" },
          { id: "6223", name: "P\u00f9 Pin", prefix: "X\u00e3" },
          { id: "6224", name: "T\u00e2n D\u00e2n", prefix: "X\u00e3" },
          { id: "6225", name: "T\u00e2n Mai", prefix: "X\u00e3" },
          { id: "6226", name: "T\u00e2n S\u01a1n", prefix: "X\u00e3" },
          { id: "6227", name: "Thung Khe", prefix: "X\u00e3" },
          { id: "6228", name: "T\u00f2ng \u0110\u1eadu", prefix: "X\u00e3" },
          { id: "6229", name: "V\u1ea1n Mai", prefix: "X\u00e3" },
          { id: "6230", name: "X\u0103m Kh\u00f2e", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "386",
        name: "T\u00e2n L\u1ea1c",
        wards: [
          { id: "6231", name: "B\u1eafc S\u01a1n", prefix: "X\u00e3" },
          { id: "6233", name: "Do Nh\u00e2n", prefix: "X\u00e3" },
          { id: "6232", name: "\u0110\u1ecbch Gi\u00e1o", prefix: "X\u00e3" },
          { id: "6234", name: "\u0110\u00f4ng Lai", prefix: "X\u00e3" },
          { id: "6235", name: "Gia M\u00f4", prefix: "X\u00e3" },
          { id: "6236", name: "L\u1ed7 S\u01a1n", prefix: "X\u00e3" },
          { id: "6237", name: "L\u0169ng V\u00e2n", prefix: "X\u00e3" },
          { id: "6238", name: "M\u00e3n \u0110\u1ee9c", prefix: "X\u00e3" },
          {
            id: "6239",
            name: "M\u01b0\u1eddng Kh\u1ebfn",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6240", name: "M\u1ef9 Ho\u00e0", prefix: "X\u00e3" },
          { id: "6241", name: "Nam S\u01a1n", prefix: "X\u00e3" },
          { id: "6242", name: "Ng\u1ed5 Lu\u00f4ng", prefix: "X\u00e3" },
          { id: "6243", name: "Ng\u1ecdc M\u1ef9", prefix: "X\u00e3" },
          { id: "6244", name: "Ng\u00f2i Hoa", prefix: "X\u00e3" },
          { id: "6245", name: "Phong Ph\u00fa", prefix: "X\u00e3" },
          { id: "6246", name: "Ph\u00fa C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "6247", name: "Ph\u00fa Vinh", prefix: "X\u00e3" },
          { id: "6248", name: "Quy H\u1eadu", prefix: "X\u00e3" },
          { id: "6249", name: "Quy M\u1ef9", prefix: "X\u00e3" },
          { id: "6250", name: "Quy\u1ebft Chi\u1ebfn", prefix: "X\u00e3" },
          { id: "6251", name: "Thanh H\u1ed1i", prefix: "X\u00e3" },
          { id: "6252", name: "Trung Ho\u00e0", prefix: "X\u00e3" },
          { id: "6253", name: "T\u1eed N\u00ea", prefix: "X\u00e3" },
          { id: "6254", name: "Tu\u00e2n L\u1ed9", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "26262",
            name: "Qu\u1ed1c L\u1ed9 12B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26263",
            name: "Qu\u1ed1c l\u1ed9 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "387",
        name: "Y\u00ean Th\u1ee7y",
        wards: [
          { id: "6255", name: "B\u1ea3o Hi\u1ec7u", prefix: "X\u00e3" },
          { id: "6256", name: "\u0110a Ph\u00fac", prefix: "X\u00e3" },
          { id: "6257", name: "\u0110o\u00e0n K\u1ebft", prefix: "X\u00e3" },
          {
            id: "6258",
            name: "H\u00e0ng Tr\u1ea1m",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6259", name: "H\u1eefu L\u1ee3i", prefix: "X\u00e3" },
          { id: "6260", name: "L\u1ea1c H\u01b0ng", prefix: "X\u00e3" },
          { id: "6261", name: "L\u1ea1c L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "6262", name: "L\u1ea1c S\u1ef9", prefix: "X\u00e3" },
          { id: "6263", name: "L\u1ea1c Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "6264", name: "Ng\u1ecdc L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "6265", name: "Ph\u00fa Lai", prefix: "X\u00e3" },
          { id: "6266", name: "Y\u00ean L\u1ea1c", prefix: "X\u00e3" },
          { id: "6267", name: "Y\u00ean Tr\u1ecb", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
    ],
  },
  {
    id: "30",
    code: "AG",
    name: "An Giang",
    districts: [
      {
        id: "388",
        name: "An Ph\u00fa",
        wards: [
          { id: "6268", name: "An Ph\u00fa", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6269", name: "\u0110a Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "6270", name: "Kh\u00e1nh An", prefix: "X\u00e3" },
          { id: "6271", name: "Kh\u00e1nh B\u00ecnh", prefix: "X\u00e3" },
          { id: "6272", name: "Long B\u00ecnh", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6273", name: "Nh\u01a1n H\u1ed9i", prefix: "X\u00e3" },
          { id: "6274", name: "Ph\u00fa H\u1ed9i", prefix: "X\u00e3" },
          { id: "6275", name: "Ph\u00fa H\u1eefu", prefix: "X\u00e3" },
          { id: "6276", name: "Ph\u01b0\u1edbc H\u01b0ng", prefix: "X\u00e3" },
          { id: "6277", name: "Qu\u1ed1c Th\u00e1i", prefix: "X\u00e3" },
          { id: "6278", name: "V\u0129nh H\u1eadu", prefix: "X\u00e3" },
          {
            id: "6279",
            name: "V\u0129nh H\u1ed9i \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "6280", name: "V\u0129nh L\u1ed9c", prefix: "X\u00e3" },
          { id: "6281", name: "V\u0129nh Tr\u01b0\u1eddng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "26264", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26265", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26266", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26267", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26268", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26269",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26270",
            name: "\u0110\u00f4ng Chi\u00eau",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26271",
            name: "H\u1eefu Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26272",
            name: "L\u00ea Th\u00e1nh T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26273",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26274",
            name: "Qu\u1ed1c l\u1ed9 91B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26275",
            name: "Tho\u1ea1i Ng\u1ecdc H\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26276",
            name: "T\u1ec9nh l\u1ed9 956",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26277",
            name: "T\u1ec9nh l\u1ed9 957",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26278",
            name: "Ung V\u0103n Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26279",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "389",
        name: "Ch\u00e2u \u0110\u1ed1c",
        wards: [
          {
            id: "6282",
            name: "Ch\u00e2u Ph\u00fa A",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "6283",
            name: "Ch\u00e2u Ph\u00fa B",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "6284", name: "N\u00fai Sam", prefix: "Ph\u01b0\u1eddng" },
          { id: "6285", name: "V\u0129nh Ch\u00e2u", prefix: "X\u00e3" },
          { id: "6286", name: "V\u0129nh M\u1ef9", prefix: "Ph\u01b0\u1eddng" },
          { id: "6287", name: "V\u0129nh Ng\u01b0\u01a1n", prefix: "X\u00e3" },
          { id: "6288", name: "V\u0129nh T\u1ebf", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "26280", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26281",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26282", name: "Ch\u00e2u Long", prefix: "Ph\u1ed1" },
          {
            id: "26283",
            name: "Ch\u00e2u Th\u1ecb T\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26284",
            name: "Chi L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26285",
            name: "C\u1eed Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26286", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26287",
            name: "\u0110\u1ed1ng \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26288",
            name: "\u0110\u01b0\u1eddng \u0110\u00ea H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26289",
            name: "\u0110\u01b0\u1eddng Tr\u01b0\u1eddng \u0110ua",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26290",
            name: "H\u01b0\u01a1ng L\u1ed9 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26291",
            name: "Kha Th\u1ecb L\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26292",
            name: "La Th\u00e0nh Th\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26293",
            name: "L\u00ea C\u00f4ng Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26294",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26295",
            name: "L\u00ea V\u0103n C\u01b0\u01a1\u0300ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26296", name: "Loui Pasteu", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26297",
            name: "Mai V\u0103n T\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26298",
            name: "M\u01b0\u01a1ng Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26299",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26300",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26301",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26302",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26303",
            name: "Nguy\u1ec5n V\u0103n Tho\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26304",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26305",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26306",
            name: "Phan V\u0103n V\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26307", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26308",
            name: "Qu\u1ed1c l\u1ed9 91",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26309", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26310", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26311", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26312",
            name: "S\u01b0\u01a1ng Nguy\u1ec7t \u00c1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26313",
            name: "Tho\u1ea1i Ng\u1ecdc H\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26314",
            name: "Th\u1ee7 Khoa Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26315",
            name: "Th\u1ee7 Khoa Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26316",
            name: "T\u1ec9nh l\u1ed9 955A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26317",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26318",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26319",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26320",
            name: "Tr\u1ea7n Th\u1ecb \u0110\u01b0\u1ee3c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26321",
            name: "Tr\u01b0ng N\u1eef V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26322",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2975",
            name: "Ch\u00e2u Qu\u1edbi 3",
            lat: "10.70073223114",
            lng: "105.1212310791",
          },
        ],
      },
      {
        id: "390",
        name: "Ch\u00e2u Ph\u00fa",
        wards: [
          { id: "6289", name: "B\u00ecnh Ch\u00e1nh", prefix: "X\u00e3" },
          { id: "6290", name: "B\u00ecnh Long", prefix: "X\u00e3" },
          { id: "6291", name: "B\u00ecnh M\u1ef9", prefix: "X\u00e3" },
          { id: "6292", name: "B\u00ecnh Ph\u00fa", prefix: "X\u00e3" },
          { id: "6293", name: "B\u00ecnh Th\u1ee7y", prefix: "X\u00e3" },
          {
            id: "6294",
            name: "C\u00e1i D\u1ea7u",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "6295",
            name: "\u0110\u1ea1o H\u1eefu C\u1ea3nh",
            prefix: "X\u00e3",
          },
          { id: "6296", name: "Kh\u00e1nh H\u00f2a", prefix: "X\u00e3" },
          { id: "6297", name: "M\u1ef9 \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "6298", name: "M\u1ef9 Ph\u00fa", prefix: "X\u00e3" },
          { id: "6299", name: "\u00d4 Long V\u0129", prefix: "X\u00e3" },
          {
            id: "6300",
            name: "Th\u1ea1nh M\u1ef9 T\u00e2y",
            prefix: "X\u00e3",
          },
          { id: "6301", name: "V\u0129nh Th\u1ea1nh Trung", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "26323", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26324", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26325", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26326", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26327", name: "30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26328", name: "31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26329", name: "3A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26330", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26331", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26332",
            name: "B\u00ecnh Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26333",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26334",
            name: "\u0110\u01b0\u1eddng Nam k\u00eanh 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26335",
            name: "Gi\u1ea3i Ph\u00f3ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26336",
            name: "Huy\u1ec1n Tr\u00e2n C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26337",
            name: "L\u00ea V\u0103n C\u01b0\u01a1\u0300ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26338",
            name: "L\u00fd Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26339",
            name: "Nguy\u1ec5n Kho\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26340",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26341",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26342",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26343",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26344",
            name: "Qu\u1ed1c l\u1ed9 1B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26345",
            name: "Qu\u1ed1c l\u1ed9 91",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26346",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26347",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26348",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26349",
            name: "Tr\u1ea7n V\u0103n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "391",
        name: "Ch\u00e2u Th\u00e0nh",
        wards: [
          { id: "6302", name: "An Ch\u00e2u", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6303", name: "An H\u00f2a", prefix: "X\u00e3" },
          { id: "6304", name: "B\u00ecnh H\u00f2a", prefix: "X\u00e3" },
          { id: "6305", name: "B\u00ecnh Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "6306", name: "C\u1ea7n \u0110\u0103ng", prefix: "X\u00e3" },
          {
            id: "6307",
            name: "H\u00f2a B\u00ecnh Th\u1ea1nh",
            prefix: "X\u00e3",
          },
          { id: "6308", name: "T\u00e2n Ph\u00fa", prefix: "X\u00e3" },
          { id: "6309", name: "V\u0129nh An", prefix: "X\u00e3" },
          { id: "6310", name: "V\u0129nh B\u00ecnh", prefix: "X\u00e3" },
          { id: "6311", name: "V\u0129nh Hanh", prefix: "X\u00e3" },
          { id: "6312", name: "V\u0129nh L\u1ee3i", prefix: "X\u00e3" },
          { id: "6313", name: "V\u0129nh Nhu\u1eadn", prefix: "X\u00e3" },
          { id: "6314", name: "V\u0129nh Th\u00e0nh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "26350", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26351", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26352", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26353", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26354", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26355", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26356", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26357", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26358", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26359", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26360", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26361", name: "941", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26362",
            name: "Ho\u00e0ng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26363",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26364",
            name: "L\u00e2m Thanh H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26365",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26366",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26367",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26368",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26369",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26370",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26371",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26372",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26373",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26374",
            name: "Phan Th\u1ecb R\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26375",
            name: "Qu\u1ed1c H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26376",
            name: "Qu\u1ed1c l\u1ed9 91",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26377",
            name: "T\u1ec9nh l\u1ed9 941",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26378",
            name: "T\u00f4n Th\u1ea5t \u0110\u1ea1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26379",
            name: "T\u00f4n Th\u1ea5t Thuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26380",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26381",
            name: "Tr\u01b0\u1eddng Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26382",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "392",
        name: "Ch\u1ee3 M\u1edbi",
        wards: [
          { id: "6315", name: "An Th\u1ea1nh Trung", prefix: "X\u00e3" },
          {
            id: "6316",
            name: "B\u00ecnh Ph\u01b0\u1edbc Xu\u00e2n",
            prefix: "X\u00e3",
          },
          {
            id: "6317",
            name: "Ch\u1ee3 M\u1edbi",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6318", name: "H\u00f2a An", prefix: "X\u00e3" },
          { id: "6319", name: "H\u00f2a B\u00ecnh", prefix: "X\u00e3" },
          { id: "6320", name: "H\u1ed9i An", prefix: "X\u00e3" },
          { id: "6321", name: "Ki\u1ebfn An", prefix: "X\u00e3" },
          { id: "6322", name: "Ki\u1ebfn Th\u00e0nh", prefix: "X\u00e3" },
          { id: "6323", name: "Long \u0110i\u1ec1n A", prefix: "X\u00e3" },
          { id: "6324", name: "Long \u0110i\u1ec1n B", prefix: "X\u00e3" },
          { id: "6325", name: "Long Giang", prefix: "X\u00e3" },
          { id: "6326", name: "Long Ki\u1ebfn", prefix: "X\u00e3" },
          { id: "6327", name: "M\u1ef9 An", prefix: "X\u00e3" },
          { id: "6328", name: "M\u1ef9 Hi\u1ec7p", prefix: "X\u00e3" },
          {
            id: "6329",
            name: "M\u1ef9 H\u1ed9i \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "6330",
            name: "M\u1ef9 Lu\u00f4ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6331", name: "Nh\u01a1n M\u1ef9", prefix: "X\u00e3" },
          { id: "6332", name: "T\u1ea5n M\u1ef9", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "26383", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26384",
            name: "Ch\u00e2u V\u0103n Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26385",
            name: "\u0110\u01b0\u1eddng B\u00e3i R\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26386",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26387",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26388",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26389",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26390",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26391",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26392",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26393",
            name: "Nguy\u1ec5n V\u0103n H\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26394",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26395",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26396",
            name: "Phan Thanh Gi\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26397",
            name: "Quy ho\u1ea1ch s\u1ed1 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26398",
            name: "Quy ho\u1ea1ch s\u1ed1 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26399",
            name: "Tho\u1ea1i Ng\u1ecdc H\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26400",
            name: "T\u1ec9nh l\u1ed9 942",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26401",
            name: "T\u1ec9nh l\u1ed9 944",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26402",
            name: "T\u1ec9nh L\u1ed9 946",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26403",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "393",
        name: "Long Xuy\u00ean",
        wards: [
          {
            id: "6333",
            name: "B\u00ecnh \u0110\u1ee9c",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "6334",
            name: "B\u00ecnh Kh\u00e1nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "6335",
            name: "\u0110\u00f4ng Xuy\u00ean",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "6336", name: "M\u1ef9 B\u00ecnh", prefix: "Ph\u01b0\u1eddng" },
          { id: "6337", name: "M\u1ef9 H\u00f2a", prefix: "Ph\u01b0\u1eddng" },
          { id: "6338", name: "M\u1ef9 H\u00f2a H\u01b0ng", prefix: "X\u00e3" },
          { id: "6339", name: "M\u1ef9 Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "6340", name: "M\u1ef9 Long", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "6341",
            name: "M\u1ef9 Ph\u01b0\u1edbc",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "6342", name: "M\u1ef9 Qu\u00fd", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "6343",
            name: "M\u1ef9 Th\u1ea1nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "6344", name: "M\u1ef9 Th\u1edbi", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "6345",
            name: "M\u1ef9 Xuy\u00ean",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "26404", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26405", name: "7A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26406",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26407",
            name: "\u00c2u D\u01b0\u01a1ng L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26408", name: "Ba Son", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26409",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26410",
            name: "B\u1ea3o \u0110\u1ecbnh Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26411",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26412",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26413",
            name: "B\u00f9i V\u0103n Danh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26414",
            name: "B\u00f9i Vi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26415", name: "C\u00e1i Sao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26416",
            name: "C\u1ea7m B\u00e1 Th\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26417",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26418",
            name: "Ch\u00e2u M\u1ea1nh Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26419",
            name: "Ch\u00e2u Th\u1ecb T\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26420",
            name: "Ch\u00e2u V\u0103n Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26421",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26422",
            name: "Ch\u00f9a \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26423",
            name: "Ch\u01b0\u1edfng Binh L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26424",
            name: "C\u00f4 B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26425",
            name: "C\u00f4 Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26426",
            name: "C\u00f4n \u0110\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26427",
            name: "C\u1ed1ng Qu\u1ef3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26428",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26429",
            name: "C\u01b0\u1eddng \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26430",
            name: "D\u00e3 T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26455",
            name: "D\u1ef1 \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26456",
            name: "D\u01b0\u01a1ng B\u00e1 Tr\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26457",
            name: "D\u01b0\u01a1ng B\u1ea1ch Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26458",
            name: "D\u01b0\u01a1ng Di\u00ean Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26431", name: "\u0110\u1eb7ng Dung", prefix: "Ph\u1ed1" },
          {
            id: "26432",
            name: "\u0110\u1eb7ng Thai Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26433",
            name: "\u0110\u1eb7ng Th\u00fac Li\u00eang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26434",
            name: "\u0110\u1eb7ng Tr\u1ea7n C\u00f4n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26435",
            name: "\u0110\u00e0o Duy Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26436",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26437",
            name: "\u0110\u00e0o T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26438",
            name: "\u0110\u1ec1 Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26439",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26440",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26441",
            name: "\u0110inh C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26442",
            name: "\u0110inh L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26443", name: "\u0110inh Li\u1ec7t", prefix: "Ph\u1ed1" },
          {
            id: "26444",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26445",
            name: "\u0110inh Tr\u01b0\u1eddng Sanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26446", name: "\u0110\u1ed7 Nhu\u1eadn", prefix: "Ph\u1ed1" },
          {
            id: "26447",
            name: "\u0110o\u00e0n Nh\u1eef H\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26448",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26449",
            name: "\u0110o\u00e0n V\u0103n Ph\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26450",
            name: "\u0110\u1ed1c Binh Ki\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26451",
            name: "\u0110\u1ed1c Binh L\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26452",
            name: "\u0110\u00f4ng H\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26453",
            name: "\u0110\u00f4ng Th\u1ecbnh 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26454",
            name: "\u0110\u00f4ng Th\u1ecbnh 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26459",
            name: "Giang V\u0103n Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26460",
            name: "H\u00e0 Ho\u00e0ng H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26461",
            name: "H\u00e0 Huy Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26462",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26463",
            name: "H\u1eafc H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26464", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "26465",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26466",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26467",
            name: "H\u00e0n M\u1eb7c T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26468",
            name: "H\u1ed3 B\u00e1 Ki\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26469",
            name: "H\u1ed3 B\u00e1 \u00d4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26470",
            name: "H\u1ed3 Bi\u1ec3u Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26471",
            name: "H\u1ed3 H\u1ea3o H\u1edbn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26472",
            name: "H\u1ed3 Hu\u1ea5n Nghi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26473",
            name: "H\u1ed3 Nguy\u00ean Tr\u1eebng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26474",
            name: "H\u1ed3 Qu\u00fd Ly",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26475",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26476",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26477",
            name: "Ho\u00e0ng L\u00ea Kha",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26478",
            name: "Ho\u00e0ng Ng\u1ecdc Ph\u00e1ch",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26479",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26480",
            name: "Ho\u00e0ng V\u0103n Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26481",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26482",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26483",
            name: "Hu\u1ef3nh T\u1ea5n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26484",
            name: "Hu\u1ef3nh Th\u1ecb H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26485",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26486",
            name: "Hu\u1ef3nh V\u0103n H\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26487",
            name: "Hu\u1ef3nh V\u0103n Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26488",
            name: "K\u00eanh \u0110\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26489",
            name: "Kh\u00fac Th\u1eeba D\u1ee5",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26490",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26491",
            name: "La S\u01a1n Phu T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26492",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26493",
            name: "L\u00ea Anh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26494",
            name: "L\u00ea Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26495",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26496",
            name: "L\u00ea Duy D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26497",
            name: "L\u00ea Ho\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26498",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26499", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26500",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26501",
            name: "L\u00ea Minh Ng\u01b0\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26502",
            name: "L\u00ea Ng\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26503",
            name: "L\u00ea Ph\u1ee5ng Hi\u1ec3u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26504",
            name: "L\u00ea Quang \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26505",
            name: "L\u00ea Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26506",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26507",
            name: "L\u00ea S\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26508",
            name: "L\u00ea Th\u00e1nh T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26509",
            name: "L\u00ea Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26510",
            name: "L\u00ea Th\u1ecb Nhi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26511",
            name: "L\u00ea Th\u1ecb Ri\u00eang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26512",
            name: "L\u00ea Thi\u1ec7n T\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26513",
            name: "L\u00ea Tri\u1ec7u Ki\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26514",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26515",
            name: "L\u00ea V\u0103n H\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26516",
            name: "L\u00ea V\u0103n L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26517",
            name: "L\u00ea V\u0103n Nhung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26518",
            name: "L\u00ea V\u0103n S\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26519",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26520",
            name: "L\u01b0\u01a1ng \u0110\u1ecbnh C\u1ee7a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26521",
            name: "L\u01b0\u01a1ng Ng\u1ecdc Quy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26522",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26523",
            name: "L\u01b0\u01a1ng V\u0103n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26524",
            name: "L\u01b0\u01a1ng V\u0103n C\u00f9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26525",
            name: "L\u01b0u H\u1eefu Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26526",
            name: "L\u00fd B\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26527",
            name: "L\u00fd C\u00f4ng U\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26528",
            name: "L\u00fd \u0110\u1ea1o Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26529",
            name: "L\u00fd Ph\u1eadt M\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26530",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26531",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26532",
            name: "L\u00fd Tr\u1ea7n Qu\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26533",
            name: "L\u00fd V\u0103n Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26534",
            name: "M\u1ea1c \u0110\u0103ng Dung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26535",
            name: "Mai H\u1eafc \u0110\u1ebf",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26536",
            name: "M\u1ef9 H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26537", name: "Nam Cao", prefix: "Ph\u1ed1" },
          {
            id: "26538",
            name: "Nam \u0110\u1eb7ng Dung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26539",
            name: "Ng\u00e3 C\u1ea1y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26540",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26541",
            name: "Ng\u00f4 L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26542",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26543",
            name: "Ng\u00f4 S\u0129 Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26544",
            name: "Ng\u00f4 T\u1ea5t T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26545",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26546",
            name: "Ng\u00f4 Th\u1eddi S\u0129",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26547",
            name: "Ng\u00f4 V\u0103n S\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26548",
            name: "Ng\u1ecdc H\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26549",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26550",
            name: "Nguy\u1ec5n Bi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26551",
            name: "Nguy\u1ec5n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26552",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26553",
            name: "Nguy\u1ec5n C\u1ea3nh D\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26554",
            name: "Nguy\u1ec5n Ch\u00e1nh Nh\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26555",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26556",
            name: "Nguy\u1ec5n Ch\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26557",
            name: "Nguy\u1ec5n C\u00f4ng Hoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26558",
            name: "Nguy\u1ec5n C\u01b0 Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26562",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26559",
            name: "Nguy\u1ec5n \u0110\u0103ng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26560",
            name: "Nguy\u1ec5n \u0110\u1ecba L\u00f4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26561",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26563",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26564",
            name: "Nguy\u1ec5n Gia Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26565",
            name: "Nguy\u1ec5n Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26566",
            name: "Nguy\u1ec5n Hi\u1ebfn L\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26567",
            name: "Nguy\u1ec5n Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26568",
            name: "Nguy\u00ean H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26569",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26570",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26571",
            name: "Nguy\u1ec5n H\u1eefu Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26572",
            name: "Nguy\u1ec5n Kh\u1eafc Nhu",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26573",
            name: "Nguy\u1ec5n Kh\u00e1nh To\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26574",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26575",
            name: "Nguy\u1ec5n L\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26576",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26577",
            name: "Nguy\u1ec5n Minh H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26578",
            name: "Nguy\u1ec5n Ng\u1ecdc Ba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26579",
            name: "Nguy\u1ec5n Nh\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26580",
            name: "Nguy\u1ec5n Phi Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26581",
            name: "Nguy\u1ec5n Phong S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26582",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26583",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26584",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26585",
            name: "Nguy\u1ec5n Thanh S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26586",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26587",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26588",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26589",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26590",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Kh\u00e1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26591",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26592",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26593",
            name: "Nguy\u1ec5n Tr\u1ecdng Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26594",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26595",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26596",
            name: "Nguy\u1ec5n V\u0103n C\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26597",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26598",
            name: "Nguy\u1ec5n V\u0103n S\u1eebng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26599",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26600", name: "Nguy\u1ec5n X\u00ed", prefix: "Ph\u1ed1" },
          {
            id: "26601",
            name: "Ph\u1ea1m C\u1ef1 L\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26602",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26603",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26604",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26605",
            name: "Ph\u1ea1m Th\u1ebf Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26606",
            name: "Ph\u1ea1m Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26607",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26608",
            name: "Ph\u1ea1m V\u0103n X\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26609",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26610",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26611",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26612",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26613",
            name: "Phan Huy \u00cdch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26614", name: "Phan K\u1ebf B\u00ecnh", prefix: "Ph\u1ed1" },
          {
            id: "26615",
            name: "Phan Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26616",
            name: "Phan Th\u00e0nh Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26617",
            name: "Phan T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26618",
            name: "Phan V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26619",
            name: "Phan X\u00edch Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26620",
            name: "Ph\u00f3 \u0110\u1ee9c Ch\u00ednh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26621",
            name: "Ph\u00f9ng Ch\u00ed Ki\u00ean",
            prefix: "Ph\u1ed1",
          },
          { id: "26622", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "26623",
            name: "Ph\u00f9ng Kh\u1eafc Khoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26624",
            name: "Qu\u00e1ch Th\u1ecb Trang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26625",
            name: "Qu\u1ea3n C\u01a1 Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26626",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26627",
            name: "Qu\u1ed1c l\u1ed9 91",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26628",
            name: "R\u1ea1ch M\u01b0\u01a1ng Khai L\u1edbn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26629",
            name: "R\u1ea1ch \u00d4ng M\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26630", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26631", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26632", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26633", name: "S\u1ed1 21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26634", name: "S\u1ed1 22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26635", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26636", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26637", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26638", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26639",
            name: "S\u01b0 V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26640",
            name: "T\u0103ng B\u1ea1t H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26641",
            name: "T\u00e2y Kh\u00e1nh 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26642",
            name: "Th\u00e1i Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26643",
            name: "Th\u00e0nh Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26644",
            name: "Th\u00e1nh Thi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26645", name: "Thi S\u00e1ch", prefix: "Ph\u1ed1" },
          {
            id: "26646",
            name: "Thi\u00ean H\u1ed9 D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26647",
            name: "Tho\u1ea1i Ng\u1ecdc H\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26648",
            name: "Th\u00f4ng L\u01b0u - T\u1ed5ng H\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26649",
            name: "Th\u1ee7 Khoa Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26650",
            name: "Th\u1ee7 Khoa Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26651",
            name: "Th\u1ee5c Ph\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26652",
            name: "T\u1ec9nh l\u1ed9 943",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26653",
            name: "T\u1ec9nh l\u1ed9 944",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26654",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26655",
            name: "T\u00f4 Ng\u1ecdc V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26656",
            name: "T\u00f4 V\u0129nh Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26657",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26658",
            name: "T\u00f4n Th\u1ea5t Thuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26659",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26660",
            name: "T\u1ed1ng Duy T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26661",
            name: "Tr\u00e0 M\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26662",
            name: "Tr\u00e0 \u00d4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26663",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26664",
            name: "Tr\u1ea7n C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26665",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26666",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26667",
            name: "Tr\u1ea7n H\u1eefu Trang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26668",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26669",
            name: "Tr\u1ea7n Kh\u1eafc Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26670",
            name: "Tr\u1ea7n Kh\u1eafc Chung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26671",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26672",
            name: "Tr\u1ea7n Nguy\u00ean \u0110\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26673",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26674",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26675",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26676",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26677",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26678",
            name: "Tr\u1ea7n Qu\u1ed1c T\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26679",
            name: "Tr\u1ea7n Qu\u1ed1c Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26680",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26681",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26682",
            name: "Tr\u1ea7n Qu\u00fd Kho\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26683",
            name: "Tr\u1ea7n Th\u1ee7 \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26684",
            name: "Tr\u1ea7n V\u0103n L\u1eabm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26685",
            name: "Tr\u1ea7n V\u0103n \u01a0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26686",
            name: "Tr\u1ea7n V\u0103n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26687",
            name: "Tr\u1ea7n V\u0103n Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26688",
            name: "Tri\u1ec7u Quang Ph\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26689",
            name: "Tri\u1ec7u Th\u1ecb Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26690",
            name: "Tr\u1ecbnh \u0110\u00ecnh Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26691",
            name: "Tr\u1ecbnh \u0110\u00ecnh Th\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26692",
            name: "Tr\u1ecbnh Ho\u00e0i \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26693",
            name: "Tr\u1ecbnh V\u0103n \u1ea4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26694",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26695",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26696",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26697",
            name: "Tr\u01b0\u01a1ng H\u1ed1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26698",
            name: "T\u00fa X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26699",
            name: "Ung V\u0103n Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26700",
            name: "Vi\u1ec7t B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26701",
            name: "V\u00f5 Duy D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26702",
            name: "V\u00f5 Ho\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26703",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26704",
            name: "V\u00f5 Tr\u01b0\u1eddng To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26705",
            name: "V\u00f5 V\u0103n Ho\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26706",
            name: "V\u00f5 V\u0103n T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26707", name: "V\u0169 Ng\u1ecdc Phan", prefix: "Ph\u1ed1" },
          {
            id: "26708",
            name: "V\u0169 Tr\u1ecdng Ph\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26709",
            name: "X\u1ebbo Ch\u00f4m 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26710",
            name: "Xu\u00e2n Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26711", name: "\u1ef6 Lan", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26712",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2976",
            name: "First Home An Giang",
            lat: "10.388477325439",
            lng: "105.43501281738",
          },
          {
            id: "2977",
            name: "Golden City An Giang",
            lat: "10.382612228394",
            lng: "105.42830657959",
          },
          {
            id: "2978",
            name: "Khu \u0111\u00f4 th\u1ecb T\u00e2y S\u00f4ng H\u1eadu",
            lat: "10.366510391235",
            lng: "105.44789123535",
          },
          {
            id: "2979",
            name: "Sao Mai B\u00ecnh Kh\u00e1nh 3",
            lat: "10.39527797699",
            lng: "105.41555786133",
          },
          {
            id: "2980",
            name: "Sao Mai B\u00ecnh Kh\u00e1nh 5",
            lat: "10.395571708679",
            lng: "105.41664886475",
          },
        ],
      },
      {
        id: "394",
        name: "Ph\u00fa T\u00e2n",
        wards: [
          {
            id: "6346",
            name: "B\u00ecnh Th\u1ea1nh \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "6347",
            name: "Ch\u1ee3 V\u00e0m",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6348", name: "Hi\u1ec7p X\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "6349", name: "H\u00f2a L\u1ea1c", prefix: "X\u00e3" },
          { id: "6350", name: "Long H\u00f2a", prefix: "X\u00e3" },
          { id: "6351", name: "Ph\u00fa An", prefix: "X\u00e3" },
          { id: "6352", name: "Ph\u00fa B\u00ecnh", prefix: "X\u00e3" },
          { id: "6353", name: "Ph\u00fa Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "6354", name: "Ph\u00fa H\u01b0ng", prefix: "X\u00e3" },
          { id: "6355", name: "Ph\u00fa L\u00e2m", prefix: "X\u00e3" },
          { id: "6356", name: "Ph\u00fa Long", prefix: "X\u00e3" },
          {
            id: "6357",
            name: "Ph\u00fa M\u1ef9",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6358", name: "Ph\u00fa Th\u00e0nh", prefix: "X\u00e3" },
          { id: "6359", name: "Ph\u00fa Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "6360", name: "Ph\u00fa Th\u1ecd", prefix: "X\u00e3" },
          { id: "6361", name: "Ph\u00fa Xu\u00e2n", prefix: "X\u00e3" },
          { id: "6362", name: "T\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "6363", name: "T\u00e2n Trung", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "26713", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26714", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26715",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26716", name: "C\u00f4ng An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26717",
            name: "\u0110\u01b0\u1eddng ng\u00e0nh r\u00e8n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26718",
            name: "\u0110\u01b0\u1eddng \u00f4ng Ba T\u1ee3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26719",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26720",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26721",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26722",
            name: "T\u1ec9nh l\u1ed9 954",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26723",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26724",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26725",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "395",
        name: "T\u00e2n Ch\u00e2u",
        wards: [
          { id: "6364", name: "Ch\u00e2u Phong", prefix: "X\u00e3" },
          { id: "6365", name: "L\u00ea Ch\u00e1nh", prefix: "X\u00e3" },
          { id: "6366", name: "Long An", prefix: "X\u00e3" },
          { id: "6367", name: "Long Ch\u00e2u", prefix: "Ph\u01b0\u1eddng" },
          { id: "6368", name: "Long H\u01b0ng", prefix: "Ph\u01b0\u1eddng" },
          { id: "6369", name: "Long Ph\u00fa", prefix: "Ph\u01b0\u1eddng" },
          { id: "6370", name: "Long S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "6371", name: "Long Th\u1ea1nh", prefix: "Ph\u01b0\u1eddng" },
          { id: "6372", name: "Ph\u00fa L\u1ed9c", prefix: "X\u00e3" },
          { id: "6373", name: "Ph\u00fa V\u0129nh", prefix: "X\u00e3" },
          { id: "6374", name: "T\u00e2n An", prefix: "X\u00e3" },
          { id: "6375", name: "T\u00e2n Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "6376", name: "V\u0129nh H\u00f2a", prefix: "X\u00e3" },
          { id: "6377", name: "V\u0129nh X\u01b0\u01a1ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "26726", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26727", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26728",
            name: "Ch\u00e2u V\u0103n Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26729",
            name: "Ch\u01b0\u1edfng Binh L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26730",
            name: "\u0110\u1ec1 Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26731",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26732",
            name: "H\u00e0 Ho\u00e0ng H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26733",
            name: "H\u00f9ng C\u1ea9m H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26734",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26735",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26736",
            name: "L\u00ea V\u0103n Duy\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26737",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26738",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26739",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26740",
            name: "Nguy\u1ec5n C\u00f4ng Nh\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26741",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26742",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26743",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26744",
            name: "Nguy\u1ec5n Quang Di\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26745",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26746",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26747",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26748",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26749",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26750",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26751",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26752",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26753",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26754",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26755",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26756", name: "Pasteur", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26757",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26758",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26759",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26760",
            name: "Phan Thanh Gi\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26761",
            name: "Tho\u1ea1i Ng\u1ecdc H\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26762",
            name: "Th\u1ee7 Khoa Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26763",
            name: "T\u1ec9nh l\u1ed9 841",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26764",
            name: "T\u1ec9nh l\u1ed9 952",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26765",
            name: "T\u1ec9nh l\u1ed9 953",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26766",
            name: "T\u1ec9nh l\u1ed9 954",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26767",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26768",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26769",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26770",
            name: "Tr\u01b0\u01a1ng C\u00f4ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26771",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "396",
        name: "Tho\u1ea1i S\u01a1n",
        wards: [
          { id: "6378", name: "An B\u00ecnh", prefix: "X\u00e3" },
          { id: "6379", name: "B\u00ecnh Th\u00e0nh", prefix: "X\u00e3" },
          { id: "6380", name: "\u0110\u1ecbnh M\u1ef9", prefix: "X\u00e3" },
          { id: "6381", name: "\u0110\u1ecbnh Th\u00e0nh", prefix: "X\u00e3" },
          {
            id: "6382",
            name: "M\u1ef9 Ph\u00fa \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "6383",
            name: "N\u00fai S\u1eadp",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6384", name: "\u00d3c Eo", prefix: "Th\u1ecb tr\u1ea5n" },
          {
            id: "6385",
            name: "Ph\u00fa H\u00f2a",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6386", name: "Ph\u00fa Thu\u1eadn", prefix: "X\u00e3" },
          { id: "6387", name: "T\u00e2y Ph\u00fa", prefix: "X\u00e3" },
          { id: "6388", name: "Tho\u1ea1i Giang", prefix: "X\u00e3" },
          { id: "6389", name: "V\u0129nh Ch\u00e1nh", prefix: "X\u00e3" },
          { id: "6390", name: "V\u0129nh Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "6391", name: "V\u0129nh Ph\u00fa", prefix: "X\u00e3" },
          { id: "6392", name: "V\u0129nh Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "6393", name: "V\u1ecdng \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "6394", name: "V\u1ecdng Th\u00ea", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "26772", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26773", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26774", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26775", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26776", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26777", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26778", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26779",
            name: "B\u1eafc k\u00eanh E",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26780",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26781",
            name: "C\u1ea7u \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26782",
            name: "C\u1ed1ng C\u00f4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26783",
            name: "\u0110\u1eb7ng Huy Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26784",
            name: "\u0110\u1eadp \u0110\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26785",
            name: "\u0110\u01b0\u1eddng Ch\u1ee3 c\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26786",
            name: "\u0110\u01b0\u1eddng V\u00e0nh \u0111ai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26787",
            name: "G\u00f2 C\u00e2y Th\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26788",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26789",
            name: "Huy\u1ec1n Tr\u00e2n C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26790",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26791",
            name: "L\u00e2m Thanh H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26792",
            name: "L\u00e0ng D\u00e2n T\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26793",
            name: "L\u00ea Anh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26794",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26795", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26796",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26797",
            name: "L\u00ea Th\u00e1nh T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26798",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26799",
            name: "L\u00ea Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26800",
            name: "L\u00ea V\u0103n T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26801",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26802",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26803",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26804",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26805",
            name: "Nguy\u1ec5n Duy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26806",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26807",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26808",
            name: "Nguy\u1ec5n Th\u1ecb B\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26809",
            name: "Nguy\u1ec5n Th\u1ecb H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26810",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26811",
            name: "Nguy\u1ec5n Th\u1ecb R\u1eaby",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26812",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26813",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26814",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26815",
            name: "Nguy\u1ec5n V\u0103n Mu\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26816",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26817",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26818",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26819",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26820",
            name: "Ph\u1ea1m Th\u1ecb Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26821",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26822",
            name: "Phan Thanh Gi\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26823",
            name: "Ph\u1ed1 Ch\u1ee3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26824",
            name: "Qu\u1ed1c L\u1ed9 943",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26825",
            name: "S\u00e2n Ti\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26826", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26827",
            name: "T\u1ea1 Uy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26828",
            name: "Tho\u1ea1i Ng\u1ecdc H\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26829",
            name: "T\u1ec9nh l\u1ed9 943",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26830",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26831",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26832",
            name: "Tr\u1ea7n Kh\u1eafc Chung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26833",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26834",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26835",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26836",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26837",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26838",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26839",
            name: "Tr\u1ea7n Th\u1ecb Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26840",
            name: "Tr\u01b0ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26841",
            name: "Tr\u01b0\u1eddng Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26842",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26843",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26844",
            name: "V\u00f5 V\u0103n T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "397",
        name: "T\u1ecbnh Bi\u00ean",
        wards: [
          { id: "6395", name: "An C\u01b0", prefix: "X\u00e3" },
          { id: "6396", name: "An H\u1ea3o", prefix: "X\u00e3" },
          { id: "6397", name: "An N\u00f4ng", prefix: "X\u00e3" },
          { id: "6398", name: "An Ph\u00fa", prefix: "X\u00e3" },
          { id: "6399", name: "Chi L\u0103ng", prefix: "Th\u1ecb tr\u1ea5n" },
          {
            id: "6400",
            name: "Nh\u00e0 B\u00e0ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6401", name: "Nh\u01a1n H\u01b0ng", prefix: "X\u00e3" },
          { id: "6402", name: "N\u00fai Voi", prefix: "X\u00e3" },
          { id: "6403", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "6404", name: "T\u00e2n L\u1ee3i", prefix: "X\u00e3" },
          { id: "6405", name: "Th\u1edbi S\u01a1n", prefix: "X\u00e3" },
          {
            id: "6406",
            name: "T\u1ecbnh Bi\u00ean",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6407", name: "V\u0103n Gi\u00e1o", prefix: "X\u00e3" },
          { id: "6408", name: "V\u0129nh Trung", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "26845", name: "1/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26846", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26847", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26848", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26849", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26850", name: "37", prefix: "Ph\u1ed1" },
          { id: "26851", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26852", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26853", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26854",
            name: "An Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26855",
            name: "Anh V\u0169 S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26856",
            name: "B\u00e0o M\u01b0\u1edbp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26857",
            name: "C\u00e0 Hom - M\u1eb1ng R\u00f2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26858",
            name: "Ch\u00e2u Th\u1ecb T\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26859",
            name: "Chi L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26860",
            name: "\u0110\u00ecnh Th\u1edbi S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26861",
            name: "\u0110o\u00e0n Minh Huy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26862",
            name: "\u0110\u01b0\u1eddng \u0111\u1eafp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26863",
            name: "\u0110\u01b0\u1eddng Voi I",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26864",
            name: "G\u00f2 C\u00e2y Sung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26865",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26866",
            name: "H\u00f2a H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26867",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26868",
            name: "H\u01b0\u01a1ng L\u00f4\u0323 11",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26869",
            name: "H\u01b0\u01a1ng l\u1ed9 17A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26870",
            name: "H\u01b0\u01a1ng l\u1ed9 17B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26871",
            name: "H\u01b0\u01a1ng l\u1ed9 62",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26872",
            name: "H\u01b0\u01a1ng l\u1ed9 Voi I",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26873",
            name: "H\u1eefu Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26874",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26875",
            name: "K\u00eanh 3/2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26876",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26877",
            name: "L\u00e1ng Ch\u00e1y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26878",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26879", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26880",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26881",
            name: "L\u00ea V\u0103n T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26882",
            name: "L\u00ecnh Qu\u1ef3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26883",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26884",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26885",
            name: "M\u1ef9 \u00c1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26886", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26887",
            name: "Nam An H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26888",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26889",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26890",
            name: "Nguy\u1ec5n Sinh S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26891",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26892",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26893",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26894",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26895",
            name: "N\u00fai Voi - T\u00e2n L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26896",
            name: "\u00d4 T\u01b0ksa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26897",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26898",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26899",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26900",
            name: "Ph\u00fa C\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26901",
            name: "Ph\u00fa H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26902",
            name: "Ph\u01b0\u1edbc \u0110i\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26903",
            name: "Qu\u1ed1c l\u1ed9 91",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26904",
            name: "S\u01a1n \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26905",
            name: "S\u01b0 V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26906",
            name: "T\u00e2n Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26907",
            name: "Tho\u1ea1i Ng\u1ecdc H\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26908",
            name: "T\u1ec9nh l\u1ed9 948",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26909",
            name: "T\u1ec9nh l\u1ed9 955A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26910",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26911",
            name: "T\u00fa T\u1ec1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26912",
            name: "Tuy\u1ebfn DC \u0110\u00ea L\u0169 n\u00fai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26913",
            name: "V\u0103n Lanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26914",
            name: "V\u0103n R\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26915",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26916",
            name: "V\u0169 H\u1ed3ng \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26917",
            name: "Xu\u00e2n T\u00f4",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "398",
        name: "Tri T\u00f4n",
        wards: [
          { id: "6409", name: "An T\u1ee9c", prefix: "X\u00e3" },
          { id: "6410", name: "Ba Ch\u00fac", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6411", name: "Ch\u00e2u L\u0103ng", prefix: "X\u00e3" },
          { id: "6412", name: "C\u00f4 T\u00f4", prefix: "X\u00e3" },
          { id: "6413", name: "L\u1ea1c Qu\u1edbi", prefix: "X\u00e3" },
          { id: "6414", name: "L\u00ea Tr\u00ec", prefix: "X\u00e3" },
          {
            id: "6415",
            name: "L\u01b0\u01a1ng An Tr\u00e0",
            prefix: "X\u00e3",
          },
          { id: "6416", name: "L\u01b0\u01a1ng Phi", prefix: "X\u00e3" },
          { id: "6417", name: "N\u00fai T\u00f4", prefix: "X\u00e3" },
          { id: "6418", name: "\u00d4 L\u00e2m", prefix: "X\u00e3" },
          { id: "6419", name: "T\u00e0 \u0110\u1ea3nh", prefix: "X\u00e3" },
          { id: "6420", name: "T\u00e2n Tuy\u1ebfn", prefix: "X\u00e3" },
          { id: "6421", name: "Tri T\u00f4n", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6422", name: "V\u0129nh Gia", prefix: "X\u00e3" },
          { id: "6423", name: "V\u0129nh Ph\u01b0\u1edbc", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "26918", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26919", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26920",
            name: "Ba Ch\u00fac S\u00f3c T\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26921",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26922",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26923",
            name: "\u0110\u01b0\u1eddng K\u00eanh M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26924",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26925",
            name: "H\u01b0\u01a1ng L\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26926", name: "K\u00eanh 13", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26927",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26928",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26929",
            name: "L\u00ea Th\u00e1nh T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26930",
            name: "L\u00ea V\u0103n T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26931",
            name: "L\u00f2 G\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26932",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26933", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26934",
            name: "Nam K\u1ef3 Kh\u1edfi Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26935",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26936",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26937",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26938",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26939",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26940",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26941",
            name: "T\u1ec9nh l\u1ed9 941",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26942",
            name: "T\u1ec9nh l\u1ed9 943",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26943",
            name: "T\u1ec9nh l\u1ed9 948",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26944",
            name: "T\u1ec9nh l\u1ed9 955B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26945",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26946",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "31",
    code: "VP",
    name: "V\u0129nh Ph\u00fac",
    districts: [
      {
        id: "399",
        name: "B\u00ecnh Xuy\u00ean",
        wards: [
          { id: "6424", name: "B\u00e1 Hi\u1ebfn", prefix: "X\u00e3" },
          {
            id: "6425",
            name: "\u0110\u1ea1o \u0110\u1ee9c",
            prefix: "X\u00e3",
          },
          { id: "6426", name: "Gia Kh\u00e1nh", prefix: "Th\u1ecb tr\u1ea5n" },
          {
            id: "6427",
            name: "H\u01b0\u01a1ng Canh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6428", name: "H\u01b0\u01a1ng S\u01a1n", prefix: "X\u00e3" },
          { id: "6429", name: "Ph\u00fa Xu\u00e2n", prefix: "X\u00e3" },
          { id: "6430", name: "Qu\u1ea5t L\u01b0u", prefix: "X\u00e3" },
          { id: "6431", name: "S\u01a1n L\u00f4i", prefix: "X\u00e3" },
          { id: "6432", name: "Tam H\u1ee3p", prefix: "X\u00e3" },
          { id: "6433", name: "T\u00e2n Phong", prefix: "X\u00e3" },
          { id: "6434", name: "Thanh L\u00e3ng", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6435", name: "Thi\u1ec7n K\u1ebf", prefix: "X\u00e3" },
          { id: "6436", name: "Trung M\u1ef9", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "26947", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26948", name: "302", prefix: "\u0110\u01b0\u1eddng" },
          { id: "26949", name: "310", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26950",
            name: "Ph\u1ed1 K\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26951",
            name: "Qu\u1ed1c L\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26952",
            name: "Qu\u1ed1c L\u1ed9 2A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26953",
            name: "Qu\u1ed1c L\u1ed9 303",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26954",
            name: "Tam L\u1ed9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26955",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
        ],
        projects: [],
      },
      {
        id: "400",
        name: "L\u1eadp Th\u1ea1ch",
        wards: [
          { id: "6437", name: "B\u1eafc B\u00ecnh", prefix: "X\u00e3" },
          { id: "6438", name: "B\u00e0n Gi\u1ea3n", prefix: "X\u00e3" },
          { id: "6439", name: "\u0110\u00ecnh Chu", prefix: "X\u00e3" },
          { id: "6440", name: "\u0110\u1ed3ng \u00cdch", prefix: "X\u00e3" },
          { id: "6441", name: "Hoa S\u01a1n", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6442", name: "H\u1ee3p L\u00fd", prefix: "X\u00e3" },
          {
            id: "6443",
            name: "L\u1eadp Th\u1ea1ch",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6444", name: "Li\u00ean H\u00f2a", prefix: "X\u00e3" },
          { id: "6445", name: "Li\u1ec5n S\u01a1n", prefix: "X\u00e3" },
          { id: "6446", name: "Ng\u1ecdc M\u1ef9", prefix: "X\u00e3" },
          { id: "6447", name: "Quang S\u01a1n", prefix: "X\u00e3" },
          { id: "6448", name: "S\u01a1n \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "6449", name: "Th\u00e1i H\u00f2a", prefix: "X\u00e3" },
          { id: "6450", name: "Ti\u00ean L\u1eef", prefix: "X\u00e3" },
          { id: "6451", name: "Tri\u1ec7u \u0110\u1ec1", prefix: "X\u00e3" },
          { id: "6452", name: "T\u1eed Du", prefix: "X\u00e3" },
          { id: "6453", name: "V\u0103n Qu\u00e1n", prefix: "X\u00e3" },
          { id: "6454", name: "V\u00e2n Tr\u1ee5c", prefix: "X\u00e3" },
          { id: "6455", name: "Xu\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "6456", name: "Xu\u00e2n L\u00f4i", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "26956",
            name: "Qu\u1ed1c l\u1ed9 2C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26957",
            name: "T\u1ec9nh l\u1ed9 305",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "401",
        name: "Ph\u00fac Y\u00ean",
        wards: [
          { id: "6457", name: "Cao Minh", prefix: "X\u00e3" },
          {
            id: "6458",
            name: "\u0110\u1ed3ng Xu\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "6459",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "6460", name: "Nam Vi\u00eam", prefix: "X\u00e3" },
          { id: "6461", name: "Ng\u1ecdc Thanh", prefix: "X\u00e3" },
          {
            id: "6462",
            name: "Ph\u00fac Th\u1eafng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "6463", name: "Ti\u1ec1n Ch\u00e2u", prefix: "X\u00e3" },
          {
            id: "6464",
            name: "Tr\u01b0ng Nh\u1ecb",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "6465",
            name: "Tr\u01b0ng Tr\u1eafc",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "6466",
            name: "Xu\u00e2n H\u00f2a",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "26958", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26959",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26960", name: "B2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26961",
            name: "\u0110\u1ed3ng T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26962", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "26963",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26964",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26965",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26966",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26967",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26968",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26969",
            name: "L\u00ea Quang \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "26970", name: "L\u00ea Xoay", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "26971",
            name: "L\u01b0u Qu\u00fd An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26972",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26973",
            name: "M\u1eadu  L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26974",
            name: "Ng\u00f4 Mi\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26975",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26976",
            name: "Ng\u1ecdc Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26977",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26978",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26979",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26980",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26981",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26982",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26983",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26984",
            name: "Ph\u00f9ng Ch\u00ed Ki\u00ean",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26985",
            name: "Qu\u1ed1c L\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26986",
            name: "Qu\u1ed1c l\u1ed9 23",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26987",
            name: "Qu\u1ed1c L\u1ed9 2A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26988",
            name: "S\u00f3c S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26989",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26990",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "26991",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26992",
            name: "Tri\u1ec7u Th\u1ecb Khoan H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26993",
            name: "Tr\u01b0ng Tr\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26994",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26995",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26996",
            name: "Xu\u00e2n Bi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26997",
            name: "Xu\u00e2n Giao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26998",
            name: "Xu\u00e2n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "26999",
            name: "Xu\u00e2n Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27000",
            name: "Xu\u00e2n Th\u01b0\u1ee3ng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27001",
            name: "Xu\u00e2n Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2981",
            name: "Flamingo Resort",
            lat: "21.340257644653",
            lng: "105.71897888184",
          },
          {
            id: "2982",
            name: "Paradise \u0110\u1ea1i L\u1ea3i Resort",
            lat: "21.318519592285",
            lng: "105.72652435303",
          },
          {
            id: "2983",
            name: "TMS Land H\u00f9ng V\u01b0\u01a1ng",
            lat: "21.241474151611",
            lng: "105.70218658447",
          },
        ],
      },
      {
        id: "402",
        name: "S\u00f4ng L\u00f4",
        wards: [
          { id: "6467", name: "B\u1ea1ch L\u01b0u", prefix: "X\u00e3" },
          { id: "6468", name: "Cao Phong", prefix: "X\u00e3" },
          { id: "6469", name: "\u0110\u00f4n Nh\u00e2n", prefix: "X\u00e3" },
          { id: "6470", name: "\u0110\u1ed3ng Qu\u1ebf", prefix: "X\u00e3" },
          { id: "6471", name: "\u0110\u1ed3ng Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "6472", name: "\u0110\u1ee9c B\u00e1c", prefix: "X\u00e3" },
          { id: "6473", name: "H\u1ea3i L\u1ef1u", prefix: "X\u00e3" },
          { id: "6474", name: "L\u00e3ng C\u00f4ng", prefix: "X\u00e3" },
          { id: "6475", name: "Nh\u00e2n \u0110\u1ea1o", prefix: "X\u00e3" },
          { id: "6476", name: "Nh\u1ea1o S\u01a1n", prefix: "X\u00e3" },
          { id: "6477", name: "Nh\u01b0 Th\u1ee5y", prefix: "X\u00e3" },
          { id: "6478", name: "Ph\u01b0\u01a1ng Khoan", prefix: "X\u00e3" },
          { id: "6479", name: "Quang Y\u00ean", prefix: "X\u00e3" },
          { id: "6480", name: "Tam S\u01a1n", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6481", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "6482", name: "T\u1ee9 Y\u00ean", prefix: "X\u00e3" },
          { id: "6483", name: "Y\u00ean Th\u1ea1ch", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "404",
        name: "Tam D\u01b0\u01a1ng",
        wards: [
          { id: "6493", name: "An H\u00f2a", prefix: "X\u00e3" },
          { id: "6496", name: "Duy Phi\u00ean", prefix: "X\u00e3" },
          { id: "6494", name: "\u0110\u1ea1o T\u00fa", prefix: "X\u00e3" },
          { id: "6495", name: "\u0110\u1ed3ng T\u0129nh", prefix: "X\u00e3" },
          { id: "6497", name: "Ho\u00e0ng \u0110an", prefix: "X\u00e3" },
          { id: "6498", name: "Ho\u00e0ng Hoa", prefix: "X\u00e3" },
          { id: "6499", name: "Ho\u00e0ng L\u00e2u", prefix: "X\u00e3" },
          {
            id: "6500",
            name: "H\u1ee3p H\u00f2a",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6501", name: "H\u1ee3p Th\u1ecbnh", prefix: "X\u00e3" },
          {
            id: "6502",
            name: "H\u01b0\u1edbng \u0110\u1ea1o",
            prefix: "X\u00e3",
          },
          { id: "6503", name: "Kim Long", prefix: "X\u00e3" },
          { id: "6504", name: "Thanh V\u00e2n", prefix: "X\u00e3" },
          { id: "6505", name: "V\u00e2n H\u1ed9i", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "27004", name: "306", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27005", name: "310", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27006", name: "336", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27007",
            name: "Qu\u1ed1c L\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27008",
            name: "Qu\u1ed1c l\u1ed9 2B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27009",
            name: "Qu\u1ed1c l\u1ed9 2C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27010",
            name: "T\u1ec9nh l\u1ed9 309",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2984",
            name: "H\u1ee3p Th\u1ecbnh",
            lat: "21.294019699097",
            lng: "105.57164001465",
          },
        ],
      },
      {
        id: "403",
        name: "Tam \u0110\u1ea3o",
        wards: [
          { id: "6484", name: "B\u1ed3 L\u00fd", prefix: "X\u00e3" },
          {
            id: "6485",
            name: "\u0110\u1ea1i \u0110\u00ecnh",
            prefix: "X\u00e3",
          },
          { id: "6486", name: "\u0110\u1ea1o Tr\u00f9", prefix: "X\u00e3" },
          { id: "6487", name: "H\u1ed3 S\u01a1n", prefix: "X\u00e3" },
          { id: "6488", name: "H\u1ee3p Ch\u00e2u", prefix: "X\u00e3" },
          { id: "6489", name: "Minh Quang", prefix: "X\u00e3" },
          {
            id: "6490",
            name: "Tam \u0110\u1ea3o",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6491", name: "Tam Quan", prefix: "X\u00e3" },
          { id: "6492", name: "Y\u00ean D\u01b0\u01a1ng", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "27002",
            name: "Qu\u1ed1c l\u1ed9 2B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27003",
            name: "T\u1ec9nh L\u1ed9 302",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "405",
        name: "V\u0129nh T\u01b0\u1eddng",
        wards: [
          { id: "6506", name: "An T\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "6507", name: "B\u00ecnh D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "6508", name: "B\u1ed3 Sao", prefix: "X\u00e3" },
          { id: "6509", name: "Cao \u0110\u1ea1i", prefix: "X\u00e3" },
          { id: "6510", name: "Ch\u1ea5n H\u01b0ng", prefix: "X\u00e3" },
          {
            id: "6511",
            name: "\u0110\u1ea1i \u0110\u1ed3ng",
            prefix: "X\u00e3",
          },
          { id: "6512", name: "Kim X\u00e1", prefix: "X\u00e3" },
          { id: "6513", name: "L\u0169ng Ho\u00e0", prefix: "X\u00e3" },
          { id: "6514", name: "L\u00fd Nh\u00e2n", prefix: "X\u00e3" },
          { id: "6515", name: "Ngh\u0129a H\u01b0ng", prefix: "X\u00e3" },
          { id: "6516", name: "Ng\u0169 Ki\u00ean", prefix: "X\u00e3" },
          { id: "6517", name: "Ph\u00fa \u0110a", prefix: "X\u00e3" },
          { id: "6518", name: "Ph\u00fa Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "6519", name: "Tam Ph\u00fac", prefix: "X\u00e3" },
          { id: "6520", name: "T\u00e2n C\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "6521", name: "T\u00e2n Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "6522", name: "Th\u1ed5 Tang", prefix: "Th\u1ecb tr\u1ea5n" },
          {
            id: "6523",
            name: "Th\u01b0\u1ee3ng Tr\u01b0ng",
            prefix: "X\u00e3",
          },
          {
            id: "6524",
            name: "T\u1ee9 Tr\u01b0ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6525", name: "Tu\u00e2n Ch\u00ednh", prefix: "X\u00e3" },
          { id: "6526", name: "V\u00e2n Xu\u00e2n", prefix: "X\u00e3" },
          { id: "6527", name: "Vi\u1ec7t Xu\u00e2n", prefix: "X\u00e3" },
          { id: "6528", name: "V\u0129nh Ninh", prefix: "X\u00e3" },
          { id: "6529", name: "V\u0129nh S\u01a1n", prefix: "X\u00e3" },
          { id: "6530", name: "V\u0129nh Th\u1ecbnh", prefix: "X\u00e3" },
          {
            id: "6531",
            name: "V\u0129nh T\u01b0\u1eddng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6532", name: "V\u0169 Di", prefix: "X\u00e3" },
          { id: "6533", name: "Y\u00ean B\u00ecnh", prefix: "X\u00e3" },
          { id: "6534", name: "Y\u00ean L\u1eadp", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "27011",
            name: "Qu\u1ed1c L\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27012",
            name: "Qu\u1ed1c L\u1ed9 2A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27013",
            name: "Qu\u1ed1c l\u1ed9 2C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27014",
            name: "T\u1ec9nh l\u1ed9 304",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "406",
        name: "V\u0129nh Y\u00ean",
        wards: [
          { id: "6535", name: "\u0110\u1ecbnh Trung", prefix: "X\u00e3" },
          {
            id: "6536",
            name: "\u0110\u1ed1ng \u0110a",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "6537",
            name: "\u0110\u1ed3ng T\u00e2m",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "6538", name: "H\u1ed9i H\u1ee3p", prefix: "Ph\u01b0\u1eddng" },
          { id: "6539", name: "Khai Quang", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "6540",
            name: "Li\u00ean B\u1ea3o",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "6541",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "6542", name: "Thanh Tr\u00f9", prefix: "X\u00e3" },
          {
            id: "6543",
            name: "T\u00edch S\u01a1n",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "27015", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27016", name: "An B\u00ecnh", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27017", name: "An S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27018", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "27019",
            name: "B\u1ea3o S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27020", name: "B\u00ecnh An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27021",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27022", name: "Ch\u00f9a H\u00e0", prefix: "Ph\u1ed1" },
          {
            id: "27023",
            name: "\u0110\u1ea7m V\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27024", name: "\u0110\u1eb7ng Dung", prefix: "Ph\u1ed1" },
          {
            id: "27025",
            name: "\u0110\u00e0o C\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27026",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27027",
            name: "\u0110\u00ecnh \u1ea4m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27028",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27029",
            name: "\u0110\u1ed9i C\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27030",
            name: "\u0110\u00f4n D\u01b0\u01a1ng C\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27031",
            name: "\u0110\u00f4n H\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27032",
            name: "\u0110\u00f4ng H\u00f2a 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27033",
            name: "\u0110\u1ed3ng H\u00f3c Th\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27034",
            name: "\u0110\u1ed3ng Kh\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27035",
            name: "\u0110\u00f4ng Qu\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27036",
            name: "\u0110\u1ed3ng R\u1eebng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27037",
            name: "H\u1ea1 C\u1ea3nh \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27038",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27039",
            name: "H\u00e0 V\u0103n Ch\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27040", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "27041",
            name: "H\u1ea3i L\u1ef1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27042",
            name: "H\u00e1n N\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27043",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27044",
            name: "Hoa H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27045",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27046",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27047",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27048",
            name: "Kim Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27049",
            name: "L\u1ea1c H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27050",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27051", name: "Lam S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27052",
            name: "L\u00ea Anh Tu\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27053",
            name: "L\u00ea Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27054",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27055",
            name: "L\u00ea Kh\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27056",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27057",
            name: "L\u00ea Ng\u1ecdc Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27058",
            name: "L\u00ea Qu\u1ea3ng Ba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27059",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27060",
            name: "L\u00ea T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27061", name: "L\u00ea Xoay", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27062",
            name: "L\u1ed7 \u0110\u00ecnh S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27063",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27064",
            name: "L\u01b0\u01a1ng V\u0103n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27065",
            name: "L\u01b0u T\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27066",
            name: "L\u00fd B\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27067",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27068",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27069",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27070",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27071",
            name: "M\u1eadu  L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27072",
            name: "M\u1eadu Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27073", name: "M\u00ea Linh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27074",
            name: "Minh Quy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27075",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27076",
            name: "Ng\u00f4 K\u00ednh Th\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27077",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27078",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27079",
            name: "Nguy\u1ec5n Bi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27080",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27081",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27082",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27083",
            name: "Nguy\u1ec5n Duy Th\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27084",
            name: "Nguy\u1ec5n Kho\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27085",
            name: "Nguy\u1ec5n Qu\u00fd T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27086",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27087",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27088",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27089",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27090",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27091",
            name: "Nguy\u1ec5n T\u01b0 Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27092",
            name: "Nguy\u1ec5n V\u0103n Ch\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27093",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27094",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27095",
            name: "N\u00fai \u0110inh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27096",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27097",
            name: "Ph\u1ea1m Phi Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27098",
            name: "Ph\u1ea1m Th\u1ecb Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27099",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27100",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27101",
            name: "Phan Ch\u00e2u Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27102",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27103",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27104",
            name: "Ph\u1ed1 Chi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27105",
            name: "Ph\u00f9ng Dong O\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27106",
            name: "Qu\u00e2n Ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27107",
            name: "Qu\u00e1n Ti\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27108", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27109",
            name: "Qu\u1ed1c L\u1ed9 2A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27110",
            name: "Qu\u1ed1c l\u1ed9 2B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27111",
            name: "Qu\u1ed1c l\u1ed9 2C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27112",
            name: "T\u00e2n Phong - V\u1ecb Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27113",
            name: "Thanh V\u00e2n - \u0110\u1ecbnh Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27114",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27115",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27116",
            name: "T\u00f4 V\u0129nh Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27117",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27118",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27119",
            name: "Tr\u1ea1i Giao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27120",
            name: "Tr\u1ea1i Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27121",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27122",
            name: "Tr\u1ea7n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27124",
            name: "Tr\u1ea7n Duy H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27123",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27125",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27126",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27127",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27128",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27129",
            name: "Tr\u1ea7n Qu\u1ed1c Ho\u00e0n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27130",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27131",
            name: "Tr\u1ea7n Qu\u1ed1c Tu\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27132",
            name: "Trung Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27133",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27134",
            name: "V\u00e2n Anh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27135",
            name: "V\u0129nh Th\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27136",
            name: "V\u0169 Duy C\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2985",
            name: "An Ph\u00fa Residence",
            lat: "21.316690444946",
            lng: "105.61731719971",
          },
          {
            id: "2986",
            name: "FLC Tower",
            lat: "21.314413070679",
            lng: "105.6079788208",
          },
          {
            id: "2987",
            name: "K\u0110T Nam V\u0129nh Y\u00ean",
            lat: "21.28589630127",
            lng: "105.6032409668",
          },
          {
            id: "2988",
            name: "Sky Garden V\u0129nh Y\u00ean",
            lat: "21.318546295166",
            lng: "105.59033203125",
          },
          {
            id: "2989",
            name: "Thi\u00ean Nga",
            lat: "21.313009262085",
            lng: "105.59224700928",
          },
          {
            id: "2990",
            name: "VCI Mountain View",
            lat: "21.331884384155",
            lng: "105.60073852539",
          },
          {
            id: "2991",
            name: "Vinaconex Xu\u00e2n Mai",
            lat: "21.319425582886",
            lng: "105.59545135498",
          },
        ],
      },
      {
        id: "407",
        name: "Y\u00ean L\u1ea1c",
        wards: [
          { id: "6544", name: "B\u00ecnh \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "6545", name: "\u0110\u1ea1i T\u1ef1", prefix: "X\u00e3" },
          {
            id: "6546",
            name: "\u0110\u1ed3ng C\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "6547", name: "\u0110\u1ed3ng V\u0103n", prefix: "X\u00e3" },
          { id: "6548", name: "H\u1ed3ng Ch\u00e2u", prefix: "X\u00e3" },
          { id: "6549", name: "H\u1ed3ng Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "6550", name: "Li\u00ean Ch\u00e2u", prefix: "X\u00e3" },
          { id: "6551", name: "Nguy\u1ec7t \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "6552", name: "Tam H\u1ed3ng", prefix: "X\u00e3" },
          { id: "6553", name: "T\u1ec1 L\u1ed7", prefix: "X\u00e3" },
          { id: "6554", name: "Trung H\u00e0", prefix: "X\u00e3" },
          { id: "6555", name: "Trung Ki\u00ean", prefix: "X\u00e3" },
          { id: "6556", name: "Trung Nguy\u00ean", prefix: "X\u00e3" },
          { id: "6557", name: "V\u0103n Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "6558", name: "Y\u00ean \u0110\u1ed3ng", prefix: "X\u00e3" },
          {
            id: "6559",
            name: "Y\u00ean L\u1ea1c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6560", name: "Y\u00ean Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "27137",
            name: "B\u1ea3n L\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27138",
            name: "Ph\u1ed1 L\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27139",
            name: "Qu\u1ed1c l\u1ed9 2C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27140",
            name: "T\u1ec9nh l\u1ed9 305",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "32",
    code: "TNI",
    name: "T\u00e2y Ninh",
    districts: [
      {
        id: "408",
        name: "B\u1ebfn C\u1ea7u",
        wards: [
          { id: "6561", name: "An Th\u1ea1nh", prefix: "X\u00e3" },
          {
            id: "6562",
            name: "B\u1ebfn C\u1ea7u",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6563", name: "L\u1ee3i Thu\u1eadn", prefix: "X\u00e3" },
          { id: "6564", name: "Long Ch\u1eef", prefix: "X\u00e3" },
          { id: "6565", name: "Long Giang", prefix: "X\u00e3" },
          { id: "6566", name: "Long Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "6567", name: "Long Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "6568", name: "Long Thu\u1eadn", prefix: "X\u00e3" },
          { id: "6569", name: "Ti\u00ean Thu\u1eadn", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "27141",
            name: "Bao Th\u1ecb Tr\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27142",
            name: "\u0110\u1eb7ng V\u0103n S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27143",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27144",
            name: "Nguy\u1ec5n V\u0103n \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27145",
            name: "Qu\u1ed1c L\u1ed9 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27146",
            name: "T\u1ec9nh l\u1ed9 784",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27147",
            name: "T\u1ec9nh l\u1ed9 786",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27148",
            name: "Xuy\u00ean \u00c1",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "409",
        name: "Ch\u00e2u Th\u00e0nh",
        wards: [
          { id: "6570", name: "An B\u00ecnh", prefix: "X\u00e3" },
          { id: "6571", name: "An C\u01a1", prefix: "X\u00e3" },
          { id: "6572", name: "Bi\u00ean Gi\u1edbi", prefix: "X\u00e3" },
          {
            id: "6573",
            name: "Ch\u00e2u Th\u00e0nh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6574", name: "\u0110\u1ed3ng Kh\u1edfi", prefix: "X\u00e3" },
          {
            id: "6575",
            name: "H\u1ea3o \u0110\u01b0\u1edbc",
            prefix: "X\u00e3",
          },
          { id: "6576", name: "H\u00f2a H\u1ed9i", prefix: "X\u00e3" },
          { id: "6577", name: "H\u00f2a Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "6578", name: "Long V\u0129nh", prefix: "X\u00e3" },
          { id: "6579", name: "Ninh \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "6580", name: "Ph\u01b0\u1edbc Vinh", prefix: "X\u00e3" },
          { id: "6581", name: "Th\u00e1i B\u00ecnh", prefix: "X\u00e3" },
          { id: "6582", name: "Thanh \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "6583", name: "Th\u00e0nh Long", prefix: "X\u00e3" },
          { id: "6584", name: "Tr\u00ed B\u00ecnh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "27149", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27150", name: "781", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27151", name: "786", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27152", name: "788", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27153",
            name: "C\u1ea7u G\u00f2 Chai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27154",
            name: "\u0110\u1ed3ng Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27155",
            name: "Ho\u00e0ng L\u00ea Kha",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27156",
            name: "L\u00ea Th\u1ecb M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27157",
            name: "Nguy\u1ec5n Minh Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27158",
            name: "Ph\u1ea1m Tung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27159",
            name: "Qu\u1ed1c L\u1ed9 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27160",
            name: "Qu\u1ed1c l\u1ed9 22B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27161",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "410",
        name: "D\u01b0\u01a1ng Minh Ch\u00e2u",
        wards: [
          { id: "6585", name: "B\u00e0u N\u0103ng", prefix: "X\u00e3" },
          { id: "6586", name: "B\u1ebfn C\u1ee7i", prefix: "X\u00e3" },
          { id: "6587", name: "C\u1ea7u Kh\u1edfi", prefix: "X\u00e3" },
          { id: "6588", name: "Ch\u00e0 L\u00e0", prefix: "X\u00e3" },
          {
            id: "6589",
            name: "D\u01b0\u01a1ng Minh Ch\u00e2u",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6590", name: "L\u1ed9c Ninh", prefix: "X\u00e3" },
          { id: "6591", name: "Phan", prefix: "X\u00e3" },
          { id: "6592", name: "Ph\u01b0\u1edbc Minh", prefix: "X\u00e3" },
          { id: "6593", name: "Ph\u01b0\u1edbc Ninh", prefix: "X\u00e3" },
          { id: "6594", name: "Su\u1ed1i \u0110\u00e1", prefix: "X\u00e3" },
          { id: "6595", name: "Tru\u00f4ng M\u00edt", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "27162", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27163", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27164", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27165", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27166", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27167", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27168", name: "37", prefix: "Ph\u1ed1" },
          { id: "27169", name: "39", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27170", name: "41", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27171", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27172", name: "781", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27173", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27174",
            name: "Ch\u00e2u V\u0103n Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27175",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27176",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27178",
            name: "D\u01b0\u01a1ng Minh Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27177", name: "\u0110T 784", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27179",
            name: "K\u00eanh \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27180",
            name: "Ng\u00f4 V\u0103n R\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27181",
            name: "Nguy\u1ec5n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27182",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27183",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27184",
            name: "Qu\u1ed1c L\u1ed9 784",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27185",
            name: "Su\u1ed1i B\u00e0u Vu\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27186",
            name: "Su\u1ed1i \u0110\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27187",
            name: "T\u1ec9nh L\u1ed9 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27188",
            name: "Tr\u1ecbnh \u0110\u00ecnh Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27189",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27190",
            name: "Ung V\u0103n Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "411",
        name: "G\u00f2 D\u1ea7u",
        wards: [
          { id: "6596", name: "B\u00e0u \u0110\u1ed3n", prefix: "X\u00e3" },
          { id: "6597", name: "C\u1ea9m Giang", prefix: "X\u00e3" },
          {
            id: "6598",
            name: "G\u00f2 D\u1ea7u",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6599", name: "Hi\u1ec7p Th\u1ea1nh", prefix: "X\u00e3" },
          {
            id: "6600",
            name: "Ph\u01b0\u1edbc \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "6601", name: "Ph\u01b0\u1edbc Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "6602", name: "Ph\u01b0\u1edbc Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "6603", name: "Th\u1ea1nh \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "6604", name: "Thanh Ph\u01b0\u1edbc", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "27191", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27192", name: "22B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27193", name: "782", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27194",
            name: "B\u1ebfn \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27195", name: "C\u00e2y Me", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27196",
            name: "Chi L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27197",
            name: "D\u01b0\u01a1ng V\u0103n N\u1ed1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27198",
            name: "D\u01b0\u01a1ng V\u0103n Th\u01b0a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27199", name: "Gi\u1ea7y Da", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27200",
            name: "H\u1ed3 V\u0103n Su\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27201",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27202",
            name: "H\u01b0\u01a1ng l\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27203",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27204", name: "Lam S\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27205",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27206",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27207",
            name: "L\u00ea V\u0103n Th\u1ea3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27208",
            name: "L\u00ea V\u0103n Th\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27209",
            name: "Minh Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27210",
            name: "Nam K\u1ef3 Kh\u1edfi Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27211",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27212",
            name: "N\u00f4ng Tr\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27213",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27214", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27215",
            name: "Qu\u1ed1c L\u1ed9 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27216",
            name: "Qu\u1ed1c L\u1ed9 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27217",
            name: "Qu\u1ed1c l\u1ed9 22B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27218", name: "S\u1ed1 17", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27219",
            name: "Tr\u1ea7n Qu\u1ed1c \u0110\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27220",
            name: "Tr\u1ea7n Th\u1ecb Sanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27221",
            name: "Tr\u1ea7n V\u0103n Th\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27222",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27223",
            name: "Xuy\u00ean \u00c1",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "412",
        name: "H\u00f2a Th\u00e0nh",
        wards: [
          { id: "6605", name: "Hi\u1ec7p T\u00e2n", prefix: "X\u00e3" },
          {
            id: "6606",
            name: "H\u00f2a Th\u00e0nh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6607", name: "Long Th\u00e0nh B\u1eafc", prefix: "X\u00e3" },
          { id: "6608", name: "Long Th\u00e0nh Nam", prefix: "X\u00e3" },
          { id: "6609", name: "Long Th\u00e0nh Trung", prefix: "X\u00e3" },
          {
            id: "6610",
            name: "Tr\u01b0\u1eddng \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "6611", name: "Tr\u01b0\u1eddng H\u00f2a", prefix: "X\u00e3" },
          { id: "6612", name: "Tr\u01b0\u1eddng T\u00e2y", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "27224", name: "22B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27225", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27226",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27227",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27228",
            name: "B\u00e0u \u1ebech",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27229",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27230",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27231",
            name: "C\u1ea7u B\u00e0 Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27232",
            name: "Ch\u00e2u V\u0103n Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27233",
            name: "Cua L\u00fd B\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27234",
            name: "\u0110\u1ed7 Th\u1ecb T\u1eb7ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27235", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "27236",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27237",
            name: "Hu\u1ef3nh Thanh M\u1eebng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27238",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27239",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27240",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27241",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27242",
            name: "Ng\u00f4 Th\u1eddi Nhi\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27243",
            name: "Ng\u00f4 Th\u1eddi Ni\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27244",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27245",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27246",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27247",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27248",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27249",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27250",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27251",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27252",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27253",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27254",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27255",
            name: "Phan V\u0103n \u0110\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27256",
            name: "Qu\u1ed1c l\u1ed9 22B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27257", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27258", name: "S\u1ed1 14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27259", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27260",
            name: "Th\u01b0\u1ee3ng Th\u00e2u Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27261",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27262",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27263",
            name: "Tr\u1ecbnh Phong \u0110\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27264",
            name: "Tr\u01b0\u01a1ng Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "413",
        name: "T\u00e2n Bi\u00ean",
        wards: [
          { id: "6613", name: "H\u00f2a Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "6614", name: "M\u1ecf C\u00f4ng", prefix: "X\u00e3" },
          {
            id: "6615",
            name: "T\u00e2n Bi\u00ean",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6616", name: "T\u00e2n B\u00ecnh", prefix: "X\u00e3" },
          { id: "6617", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "6618", name: "T\u00e2n Phong", prefix: "X\u00e3" },
          { id: "6619", name: "Th\u1ea1nh B\u1eafc", prefix: "X\u00e3" },
          { id: "6620", name: "Th\u1ea1nh B\u00ecnh", prefix: "X\u00e3" },
          { id: "6621", name: "Th\u1ea1nh T\u00e2y", prefix: "X\u00e3" },
          { id: "6622", name: "Tr\u00e0 Vong", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "27265", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27266",
            name: "C\u1ea7n \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27268",
            name: "D\u01b0\u01a1ng B\u1ea1ch Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27267", name: "\u0110T 783", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27269",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27270",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27271",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27272",
            name: "Hu\u1ef3nh C\u00f4ng Gi\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27273",
            name: "Hu\u1ef3nh T\u1ea5n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27274",
            name: "Hu\u1ef3nh V\u0103n Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27275",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27276",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27277",
            name: "L\u00ea V\u0103n S\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27278",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27279",
            name: "Nguy\u1ec5n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27280",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27281",
            name: "Nguy\u1ec5n Duy Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27282",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27283",
            name: "Nguy\u1ec5n Minh Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27284",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27285",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27286",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27287",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27288",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27289",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27290",
            name: "Ph\u1ea1m Th\u00e1i B\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27291",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27292",
            name: "Phan V\u0103n \u0110\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27293",
            name: "Qu\u1ed1c l\u1ed9 22B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27294",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27295",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27296",
            name: "Tr\u1ea7n V\u0103n Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27297",
            name: "Xu\u00e2n H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "414",
        name: "T\u00e2n Ch\u00e2u",
        wards: [
          { id: "6623", name: "Su\u1ed1i D\u00e2y", prefix: "X\u00e3" },
          { id: "6624", name: "Su\u1ed1i Ng\u00f4", prefix: "X\u00e3" },
          {
            id: "6625",
            name: "T\u00e2n Ch\u00e2u",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6626", name: "T\u00e2n \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "6627", name: "T\u00e2n H\u00e0", prefix: "X\u00e3" },
          { id: "6628", name: "T\u00e2n Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "6629", name: "T\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "6630", name: "T\u00e2n H\u1ed9i", prefix: "X\u00e3" },
          { id: "6631", name: "T\u00e2n H\u01b0ng", prefix: "X\u00e3" },
          { id: "6632", name: "T\u00e2n Ph\u00fa", prefix: "X\u00e3" },
          { id: "6633", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "6634", name: "Th\u1ea1nh \u0110\u00f4ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "27298", name: "785", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27299", name: "794", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27300", name: "795", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27301", name: "K\u00e0 Tum", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27302",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27303",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27304",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27305",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27306",
            name: "Tr\u1ea7n V\u0103n Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "415",
        name: "T\u00e2y Ninh",
        wards: [
          { id: "6635", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "6636", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "6637", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "6638", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "6639", name: "B\u00ecnh Minh", prefix: "X\u00e3" },
          { id: "6640", name: "Hi\u1ec7p Ninh", prefix: "Ph\u01b0\u1eddng" },
          { id: "6641", name: "Ninh S\u01a1n", prefix: "X\u00e3" },
          { id: "6642", name: "Ninh Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "6643", name: "T\u00e2n B\u00ecnh", prefix: "X\u00e3" },
          { id: "6644", name: "Th\u1ea1nh T\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "27307", name: "22B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27308", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27309", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27310", name: "784", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27311", name: "785", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27312", name: "786", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27313", name: "793", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27314", name: "798", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27315", name: "B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27316",
            name: "B\u1eddi L\u1eddi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27317",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27318",
            name: "Ch\u00e1nh M\u00f4n A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27319",
            name: "C\u01a1 Th\u00e1nh V\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27326",
            name: "D\u01b0\u01a1ng Minh Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27320",
            name: "\u0110\u1eb7ng Ng\u1ecdc Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27321",
            name: "\u0110\u1eb7ng V\u0103n L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27322",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27323", name: "\u0110T 787", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27324",
            name: "\u0110\u01b0\u1eddng G",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27325",
            name: "\u0110\u01b0\u1eddng M",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27327", name: "E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27328", name: "H", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27329",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27330",
            name: "H\u1ed3 V\u0103n L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27331",
            name: "Ho\u00e0ng L\u00ea Kha",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27332",
            name: "Hu\u1ef3nh C\u00f4ng Gi\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27333",
            name: "Hu\u1ef3nh C\u00f4ng Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27334",
            name: "Hu\u1ef3nh T\u1ea5n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27335",
            name: "Hu\u1ef3nh V\u0103n Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27336",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27337",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27338",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27339",
            name: "L\u00ea V\u0103n T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27340", name: "L\u1ed9 12A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27341", name: "L\u1ed9c An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27342",
            name: "Nam K\u1ef3 Kh\u1edfi Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27343",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27344",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27345",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27346",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27347",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27348",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27349",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27350",
            name: "Nguy\u1ec5n Tr\u1ecdng C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27351",
            name: "Nguy\u1ec5n V\u0103n B\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27352",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27353",
            name: "Nguy\u1ec5n V\u0103n R\u1ed1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27354",
            name: "Nguy\u1ec5n V\u0103n Th\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27355",
            name: "Nguy\u1ec5n V\u0103n Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27356",
            name: "Nguy\u1ec5n V\u0103n T\u1ed1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27357",
            name: "Nguy\u1ec5n V\u0103n Xi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27358", name: "Pasteur", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27359",
            name: "Ph\u1ea1m C\u00f4ng Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27360",
            name: "Ph\u1ea1m Tung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27361",
            name: "Ph\u1ea1m V\u0103n Chi\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27362",
            name: "Ph\u1ea1m V\u0103n Xuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27363",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27364", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27365",
            name: "Qu\u1ed1c l\u1ed9 22B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27366", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27367", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27368", name: "S\u1ed1 28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27369", name: "S\u1ed1 38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27370", name: "Thuy\u1ec1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27371",
            name: "T\u1ec9nh l\u1ed9 781",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27372",
            name: "T\u1ec9nh l\u1ed9 784",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27373",
            name: "T\u1ec9nh l\u1ed9 786",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27374",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27375",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27376",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27377",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27378",
            name: "Tr\u1ea7n V\u0103n Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27379",
            name: "Tr\u01b0ng N\u1eef V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27380",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27381",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27382",
            name: "Tr\u01b0\u01a1ng Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27383",
            name: "Tr\u01b0\u01a1ng T\u00f9ng Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27384", name: "Tua Hai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27385",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27386",
            name: "V\u00f5 V\u0103n Truy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27387",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2992",
            name: "HQC T\u00e2y Ninh",
            lat: "11.311698913574",
            lng: "106.09268951416",
          },
          {
            id: "2993",
            name: "Vincom T\u00e2y Ninh",
            lat: "21.0306224823",
            lng: "105.84886932373",
          },
        ],
      },
      {
        id: "416",
        name: "Tr\u1ea3ng B\u00e0ng",
        wards: [
          { id: "6645", name: "An H\u00f2a", prefix: "X\u00e3" },
          { id: "6646", name: "An T\u1ecbnh", prefix: "X\u00e3" },
          { id: "6647", name: "B\u00ecnh Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "6648", name: "\u0110\u00f4n Thu\u1eadn", prefix: "X\u00e3" },
          { id: "6649", name: "Gia B\u00ecnh", prefix: "X\u00e3" },
          { id: "6650", name: "Gia L\u1ed9c", prefix: "X\u00e3" },
          { id: "6651", name: "H\u01b0ng Thu\u1eadn", prefix: "X\u00e3" },
          { id: "6652", name: "L\u1ed9c H\u01b0ng", prefix: "X\u00e3" },
          { id: "6653", name: "Ph\u01b0\u1edbc Ch\u1ec9", prefix: "X\u00e3" },
          { id: "6654", name: "Ph\u01b0\u1edbc L\u01b0u", prefix: "X\u00e3" },
          {
            id: "6655",
            name: "Tr\u1ea3ng B\u00e0ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          { id: "27388", name: "22/12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27389", name: "782", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27390",
            name: "An Qu\u1edbi 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27391",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27392",
            name: "B\u1eddi L\u1eddi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27393",
            name: "B\u00f9i Thanh V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27398", name: "Duy T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27394",
            name: "\u0110\u1eb7ng V\u0103n Tr\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27395",
            name: "\u0110\u00ecnh Gia L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27396", name: "\u0110T 782", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27397", name: "\u0110T 787A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27399", name: "Gia Long", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27400",
            name: "H\u1ed3 B\u01a1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27401",
            name: "H\u1ed3 Ch\u00ed Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27402",
            name: "H\u00f2a H\u01b0ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27403",
            name: "H\u00f2a H\u01b0ng 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27404",
            name: "Ho\u00e0 H\u01b0ng 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27405",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27406",
            name: "H\u01b0\u01a1ng l\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27407",
            name: "Hu\u1ef3nh Th\u1ecb H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27408",
            name: "L\u00e3nh Binh T\u00f2ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27409",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27410",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27411",
            name: "Nguy\u1ec5n Tr\u1ecdng C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27412",
            name: "Nguy\u1ec5n V\u0103n Ch\u1ea5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27413",
            name: "Nguy\u1ec5n V\u0103n Ki\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27414",
            name: "Nguy\u1ec5n V\u0103n R\u1ed1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27415", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27416",
            name: "Qu\u1ed1c L\u1ed9 22",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27417",
            name: "Qu\u1ed1c l\u1ed9 22A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27418",
            name: "T\u1ec9nh L\u1ed9 19",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27419",
            name: "T\u1ec9nh l\u1ed9 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27420",
            name: "T\u1ec9nh L\u1ed9 64",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27421",
            name: "T\u1ec9nh l\u1ed9 786",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27422",
            name: "T\u1ec9nh l\u1ed9 787A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27423",
            name: "T\u1ec9nh l\u1ed9 787B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27424",
            name: "T\u1ec9nh L\u1ed9 789",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27425",
            name: "Tr\u1ea7n Th\u1ecb Nga",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27426",
            name: "Tr\u01b0ng Nh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27427",
            name: "Tr\u01b0ng Tr\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27428",
            name: "V\u00f5 T\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27429",
            name: "Xuy\u00ean \u00c1",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2994",
            name: "Mai Anh",
            lat: "11.043061256409",
            lng: "106.35929870605",
          },
        ],
      },
    ],
  },
  {
    id: "33",
    code: "TN",
    name: "Th\u00e1i Nguy\u00ean",
    districts: [
      {
        id: "417",
        name: "\u0110\u1ea1i T\u1eeb",
        wards: [
          { id: "6656", name: "An Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "6657", name: "B\u1ea3n Ngo\u1ea1i", prefix: "X\u00e3" },
          { id: "6658", name: "B\u00ecnh Thu\u1eadn", prefix: "X\u00e3" },
          { id: "6659", name: "C\u00e1t N\u00ea", prefix: "X\u00e3" },
          { id: "6660", name: "C\u00f9 V\u00e2n", prefix: "X\u00e3" },
          {
            id: "6661",
            name: "\u0110\u1ea1i T\u1eeb",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "6662",
            name: "\u0110\u1ee9c L\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "6663", name: "H\u00e0 Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "6664", name: "Ho\u00e0ng N\u00f4ng", prefix: "X\u00e3" },
          { id: "6665", name: "H\u00f9ng S\u01a1n", prefix: "X\u00e3" },
          { id: "6666", name: "Kh\u00f4i K\u1ef3", prefix: "X\u00e3" },
          { id: "6667", name: "K\u00fd Ph\u00fa", prefix: "X\u00e3" },
          { id: "6668", name: "La B\u1eb1ng", prefix: "X\u00e3" },
          { id: "6669", name: "L\u1ee5c Ba", prefix: "X\u00e3" },
          { id: "6670", name: "Minh Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "6671", name: "M\u1ef9 Y\u00ean", prefix: "X\u00e3" },
          { id: "6672", name: "Na Mao", prefix: "X\u00e3" },
          { id: "6673", name: "Ph\u00fa C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "6674", name: "Ph\u00fa L\u1ea1c", prefix: "X\u00e3" },
          { id: "6675", name: "Ph\u00fa Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "6676", name: "Ph\u00fa Xuy\u00ean", prefix: "X\u00e3" },
          { id: "6677", name: "Ph\u1ee5c Linh", prefix: "X\u00e3" },
          { id: "6678", name: "Ph\u00fac L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "6679", name: "Qu\u00e2n Chu", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6680", name: "Qu\u00e2n Chu", prefix: "X\u00e3" },
          { id: "6681", name: "T\u00e2n Linh", prefix: "X\u00e3" },
          { id: "6682", name: "T\u00e2n Th\u00e1i", prefix: "X\u00e3" },
          { id: "6683", name: "Ti\u00ean H\u1ed9i", prefix: "X\u00e3" },
          { id: "6684", name: "V\u1ea1n Th\u1ecd", prefix: "X\u00e3" },
          { id: "6685", name: "V\u0103n Y\u00ean", prefix: "X\u00e3" },
          { id: "6686", name: "Y\u00ean L\u00e3ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "27430", name: "261", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27431", name: "263", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27432", name: "37", prefix: "Ph\u1ed1" },
          {
            id: "27433",
            name: "Ph\u1ed1 \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27434",
            name: "Qu\u1ed1c l\u1ed9 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27435",
            name: "T\u1ec9nh l\u1ed9 264",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27436",
            name: "T\u1ec9nh l\u1ed9 270",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "418",
        name: "\u0110\u1ecbnh H\u00f3a",
        wards: [
          { id: "6687", name: "B\u1ea3o C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "6688", name: "B\u1ea3o Linh", prefix: "X\u00e3" },
          { id: "6689", name: "B\u00ecnh Th\u00e0nh", prefix: "X\u00e3" },
          { id: "6690", name: "B\u00ecnh Y\u00ean", prefix: "X\u00e3" },
          { id: "6691", name: "B\u1ed9c Nhi\u00eau", prefix: "X\u00e3" },
          { id: "6692", name: "Ch\u1ee3 Chu", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6693", name: "\u0110i\u1ec1m M\u1eb7c", prefix: "X\u00e3" },
          { id: "6694", name: "\u0110\u1ecbnh Bi\u00ean", prefix: "X\u00e3" },
          { id: "6695", name: "\u0110\u1ed3ng Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "6696", name: "Kim Ph\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "6697", name: "Kim S\u01a1n", prefix: "X\u00e3" },
          { id: "6698", name: "Lam V\u1ef9", prefix: "X\u00e3" },
          { id: "6699", name: "Linh Th\u00f4ng", prefix: "X\u00e3" },
          { id: "6700", name: "Ph\u00fa \u0110\u00ecnh", prefix: "X\u00e3" },
          { id: "6701", name: "Ph\u00fa Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "6702", name: "Ph\u00fac Chu", prefix: "X\u00e3" },
          { id: "6703", name: "Ph\u01b0\u1ee3ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "6704", name: "Quy K\u1ef3", prefix: "X\u00e3" },
          { id: "6705", name: "S\u01a1n Ph\u00fa", prefix: "X\u00e3" },
          { id: "6706", name: "T\u00e2n D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "6707", name: "T\u00e2n Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "6708", name: "Thanh \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "6709", name: "Trung H\u1ed9i", prefix: "X\u00e3" },
          { id: "6710", name: "Trung L\u01b0\u01a1ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "27437", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27438",
            name: "Qu\u00e1n Vu\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27439",
            name: "T\u1ec9nh l\u1ed9 254",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27440",
            name: "T\u1ec9nh l\u1ed9 264",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27441",
            name: "T\u1ec9nh l\u1ed9 264B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27442",
            name: "T\u1ec9nh l\u1ed9 268",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "419",
        name: "\u0110\u1ed3ng H\u1ef7",
        wards: [
          { id: "6711", name: "C\u00e2y Th\u1ecb", prefix: "X\u00e3" },
          { id: "6712", name: "Ch\u00f9a Hang", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6713", name: "H\u00f2a B\u00ecnh", prefix: "X\u00e3" },
          { id: "6714", name: "H\u00f3a Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "6715", name: "H\u00f3a Trung", prefix: "X\u00e3" },
          { id: "6716", name: "H\u1ee3p Ti\u1ebfn", prefix: "X\u00e3" },
          {
            id: "6717",
            name: "Hu\u1ed1ng Th\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
          { id: "6718", name: "Khe Mo", prefix: "X\u00e3" },
          { id: "6719", name: "Linh S\u01a1n", prefix: "X\u00e3" },
          { id: "6720", name: "Minh L\u1eadp", prefix: "X\u00e3" },
          { id: "6721", name: "Nam H\u00f2a", prefix: "X\u00e3" },
          { id: "6722", name: "Quang S\u01a1n", prefix: "X\u00e3" },
          {
            id: "6723",
            name: "S\u00f4ng C\u1ea7u",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6724", name: "T\u00e2n L\u1ee3i", prefix: "X\u00e3" },
          { id: "6725", name: "T\u00e2n Long", prefix: "X\u00e3" },
          { id: "6726", name: "Tr\u1ea1i Cau", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6727", name: "V\u0103n H\u00e1n", prefix: "X\u00e3" },
          { id: "6728", name: "V\u0103n L\u0103ng", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "27443",
            name: "Qu\u1ed1c L\u1ed9 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27444",
            name: "Qu\u1ed1c l\u1ed9 1B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27445",
            name: "Qu\u1ed1c l\u1ed9 217",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27446",
            name: "T\u1ec9nh l\u1ed9 265",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27447",
            name: "T\u1ec9nh l\u1ed9 269",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "420",
        name: "Ph\u1ed5 Y\u00ean",
        wards: [
          { id: "6729", name: "Ba H\u00e0ng", prefix: "Th\u1ecb tr\u1ea5n" },
          {
            id: "6730",
            name: "B\u1eafc S\u01a1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "6731",
            name: "B\u00e3i B\u00f4ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6732", name: "\u0110\u1eafc S\u01a1n", prefix: "X\u00e3" },
          { id: "6733", name: "\u0110\u00f4ng Cao", prefix: "X\u00e3" },
          { id: "6734", name: "\u0110\u1ed3ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "6735", name: "H\u1ed3ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "6736", name: "Minh \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "6737", name: "Nam Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "6738", name: "Ph\u00fac T\u00e2n", prefix: "X\u00e3" },
          { id: "6739", name: "Ph\u00fac Thu\u1eadn", prefix: "X\u00e3" },
          { id: "6740", name: "T\u00e2n H\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "6741", name: "T\u00e2n Ph\u00fa", prefix: "X\u00e3" },
          { id: "6742", name: "Th\u00e0nh C\u00f4ng", prefix: "X\u00e3" },
          { id: "6743", name: "Thu\u1eadn Th\u00e0nh", prefix: "X\u00e3" },
          { id: "6744", name: "Ti\u00ean Phong", prefix: "X\u00e3" },
          { id: "6745", name: "Trung Th\u00e0nh", prefix: "X\u00e3" },
          { id: "6746", name: "V\u1ea1n Ph\u00e1i", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "27448", name: "261", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27449", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27450",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27451",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27452",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27453",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27454",
            name: "Qu\u1ed1c l\u1ed9 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27455",
            name: "T\u1ec9nh l\u1ed9 301",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27456",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2995",
            name: "Khu d\u00e2n c\u01b0 \u0110\u00f4ng T\u00e2y",
            lat: "21.414775848389",
            lng: "105.89362335205",
          },
          {
            id: "2996",
            name: "Khu \u0111\u00f4 th\u1ecb Ph\u00fa \u0110\u1ea1i C\u00e1t",
            lat: "21.429388046265",
            lng: "105.87342834473",
          },
        ],
      },
      {
        id: "421",
        name: "Ph\u00fa B\u00ecnh",
        wards: [
          { id: "6747", name: "B\u00e0n \u0110\u1ea1t", prefix: "X\u00e3" },
          { id: "6748", name: "B\u1ea3o L\u00fd", prefix: "X\u00e3" },
          { id: "6752", name: "D\u01b0\u01a1ng Th\u00e0nh", prefix: "X\u00e3" },
          { id: "6749", name: "\u0110\u00e0o X\u00e1", prefix: "X\u00e3" },
          { id: "6750", name: "\u0110i\u1ec1m Th\u1ee5y", prefix: "X\u00e3" },
          { id: "6751", name: "\u0110\u1ed3ng Li\u00ean", prefix: "X\u00e3" },
          { id: "6753", name: "H\u00e0 Ch\u00e2u", prefix: "X\u00e3" },
          {
            id: "6754",
            name: "H\u01b0\u01a1ng S\u01a1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6755", name: "Kha S\u01a1n", prefix: "X\u00e3" },
          { id: "6756", name: "L\u01b0\u01a1ng Ph\u00fa", prefix: "X\u00e3" },
          { id: "6757", name: "Nga My", prefix: "X\u00e3" },
          { id: "6758", name: "Nh\u00e3 L\u1ed9ng", prefix: "X\u00e3" },
          { id: "6759", name: "T\u00e2n \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "6760", name: "T\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "6761", name: "T\u00e2n Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "6762", name: "T\u00e2n Kim", prefix: "X\u00e3" },
          { id: "6763", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "6764", name: "Thanh Ninh", prefix: "X\u00e3" },
          {
            id: "6765",
            name: "Th\u01b0\u1ee3ng \u0110\u00ecnh",
            prefix: "X\u00e3",
          },
          { id: "6766", name: "\u00dac K\u1ef3", prefix: "X\u00e3" },
          { id: "6767", name: "Xu\u00e2n Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "27457", name: "37", prefix: "Ph\u1ed1" },
          { id: "27458", name: "Ph\u00fa Gia", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27459",
            name: "Qu\u1ed1c l\u1ed9 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27460",
            name: "T\u1ec9nh l\u1ed9 261C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27461",
            name: "T\u1ec9nh l\u1ed9 266",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27462",
            name: "T\u1ec9nh l\u1ed9 269B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27463",
            name: "T\u1ec9nh l\u1ed9 287",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "422",
        name: "Ph\u00fa L\u01b0\u01a1ng",
        wards: [
          { id: "6768", name: "C\u1ed5 L\u0169ng", prefix: "X\u00e3" },
          {
            id: "6769",
            name: "\u0110\u1ed9ng \u0110\u1ea1t",
            prefix: "X\u00e3",
          },
          { id: "6770", name: "\u0110u", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6771", name: "Giang Ti\u00ean", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6772", name: "H\u1ee3p Th\u00e0nh", prefix: "X\u00e3" },
          { id: "6773", name: "\u00d4n L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "6774", name: "Ph\u1ea5n M\u1ec5", prefix: "X\u00e3" },
          { id: "6775", name: "Ph\u00fa \u0110\u00f4", prefix: "X\u00e3" },
          { id: "6776", name: "Ph\u1ee7 L\u00fd", prefix: "X\u00e3" },
          { id: "6777", name: "S\u01a1n C\u1ea9m", prefix: "X\u00e3" },
          { id: "6778", name: "T\u1ee9c Tranh", prefix: "X\u00e3" },
          { id: "6779", name: "V\u00f4 Tranh", prefix: "X\u00e3" },
          { id: "6780", name: "Y\u00ean \u0110\u1ed5", prefix: "X\u00e3" },
          { id: "6781", name: "Y\u00ean L\u1ea1c", prefix: "X\u00e3" },
          { id: "6782", name: "Y\u00ean Ninh", prefix: "X\u00e3" },
          { id: "6783", name: "Y\u00ean Tr\u1ea1ch", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "27464", name: "263", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27465", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27466", name: "37", prefix: "Ph\u1ed1" },
          {
            id: "27467",
            name: "Qu\u1ed1c l\u1ed9 1B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27468",
            name: "Qu\u1ed1c l\u1ed9 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27469",
            name: "T\u1ec9nh l\u1ed9 268",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "423",
        name: "S\u00f4ng C\u00f4ng",
        wards: [
          { id: "6784", name: "B\u00e1 Xuy\u00ean", prefix: "X\u00e3" },
          { id: "6785", name: "B\u00e1ch Quang", prefix: "Ph\u01b0\u1eddng" },
          { id: "6786", name: "B\u00ecnh S\u01a1n", prefix: "X\u00e3" },
          { id: "6787", name: "C\u1ea3i \u0110an", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "6788",
            name: "L\u01b0\u01a1ng Ch\u00e2u",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "6789", name: "M\u1ecf Ch\u00e8", prefix: "Ph\u01b0\u1eddng" },
          { id: "6790", name: "Ph\u1ed1 C\u00f2", prefix: "Ph\u01b0\u1eddng" },
          { id: "6791", name: "T\u00e2n Quang", prefix: "X\u00e3" },
          {
            id: "6792",
            name: "Th\u1eafng L\u1ee3i",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "6793", name: "Vinh S\u01a1n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "27470", name: "262", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27471", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27472", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27473", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27474", name: "An Ch\u00e2u", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27475",
            name: "B\u00ecnh S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27476",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng M\u01b0\u1eddi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27477",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27478",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27479",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27480",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27481",
            name: "Ng\u00f4 S\u1ef9 Li\u00ean",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27482",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27483",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27484",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27485",
            name: "Qu\u1ea3ng tr\u01b0\u1eddng 1/7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27486",
            name: "Qu\u1ed1c l\u1ed9 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27487",
            name: "Th\u1eafng L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27488",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27489",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27490",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27491",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27492",
            name: "Vinh S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27493",
            name: "V\u0169 Xu\u00e2n Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27494",
            name: "Xu\u00e2n Mi\u1ebfu 1-2",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "2997",
            name: "K\u0110T H\u1ed3ng V\u0169",
            lat: "21.463640213013",
            lng: "105.83783721924",
          },
          {
            id: "2998",
            name: "Khu \u0111\u00f4 th\u1ecb Thi\u00ean L\u1ed9c",
            lat: "21.455728530884",
            lng: "105.83528900146",
          },
          {
            id: "2999",
            name: "Kosy - S\u00f4ng C\u00f4ng",
            lat: "21.469970703125",
            lng: "105.8332824707",
          },
          {
            id: "3000",
            name: "V\u1ea1n Ph\u00fac City",
            lat: "21.457723617554",
            lng: "105.85213470459",
          },
        ],
      },
      {
        id: "424",
        name: "Th\u00e1i Nguy\u00ean",
        wards: [
          { id: "6794", name: "Cam Gi\u00e1", prefix: "Ph\u01b0\u1eddng" },
          { id: "6795", name: "Cao Ng\u1ea1n", prefix: "X\u00e3" },
          { id: "6796", name: "\u0110\u1ed3ng B\u1ea9m", prefix: "X\u00e3" },
          {
            id: "6797",
            name: "\u0110\u1ed3ng Quang",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "6798", name: "Gia S\u00e0ng", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "6799",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "6800",
            name: "H\u01b0\u01a1ng S\u01a1n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "6801", name: "L\u01b0\u01a1ng S\u01a1n", prefix: "X\u00e3" },
          {
            id: "6802",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "6803", name: "Ph\u00fa X\u00e1", prefix: "Ph\u01b0\u1eddng" },
          { id: "6804", name: "Ph\u00fac H\u00e0", prefix: "X\u00e3" },
          { id: "6805", name: "Ph\u00fac Tr\u00ecu", prefix: "X\u00e3" },
          { id: "6806", name: "Ph\u00fac Xu\u00e2n", prefix: "X\u00e3" },
          { id: "6807", name: "Quan Tri\u1ec1u", prefix: "Ph\u01b0\u1eddng" },
          { id: "6808", name: "Quang Trung", prefix: "Ph\u01b0\u1eddng" },
          { id: "6809", name: "Quang Vinh", prefix: "Ph\u01b0\u1eddng" },
          { id: "6810", name: "Quy\u1ebft Th\u1eafng", prefix: "X\u00e3" },
          { id: "6811", name: "T\u00e2n C\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "6812", name: "T\u00e2n L\u1eadp", prefix: "Ph\u01b0\u1eddng" },
          { id: "6813", name: "T\u00e2n Long", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "6814",
            name: "T\u00e2n Th\u00e0nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "6815",
            name: "T\u00e2n Th\u1ecbnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "6816",
            name: "Th\u1ecbnh \u0110\u00e1n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "6817", name: "Th\u1ecbnh \u0110\u1ee9c", prefix: "X\u00e3" },
          {
            id: "6818",
            name: "Th\u1ee7y \u0110\u01b0\u1eddng",
            prefix: "X\u00e3",
          },
          {
            id: "6819",
            name: "T\u00edch L\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "6820", name: "Trung Th\u00e0nh", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "6821",
            name: "Tr\u01b0ng V\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "6822",
            name: "T\u00fac Duy\u00ean",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "27495", name: "19/8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27496", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27497", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27498", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27499", name: "37", prefix: "Ph\u1ed1" },
          { id: "27500", name: "505", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27501",
            name: "B\u1eafc K\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27502", name: "B\u1eafc Nam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27503",
            name: "B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27504",
            name: "B\u1ebfn O\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27505",
            name: "B\u1ebfn T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27506",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27507",
            name: "Chi\u1ebfn Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27508",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27509", name: "C\u1ed9t C\u1edd", prefix: "Ph\u1ed1" },
          {
            id: "27518",
            name: "D\u01b0\u01a1ng T\u1ef1 Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27510",
            name: "\u0110\u1ea1i L\u1ed9 \u0110\u00f4ng T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27511", name: "\u0110\u1ea7m Xanh", prefix: "Ph\u1ed1" },
          {
            id: "27512",
            name: "\u0110\u00ea N\u00f4ng L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27513",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27514",
            name: "\u0110\u1ed9i C\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27515",
            name: "\u0110\u1ed3ng B\u1ea9m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27516",
            name: "\u0110\u1ed3ng H\u1ef7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27517", name: "\u0110\u1ed3ng Quang", prefix: "Ph\u1ed1" },
          {
            id: "27519",
            name: "Ga Th\u00e1i Nguy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27520",
            name: "Gang Th\u00e9p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27521",
            name: "Gia S\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27522",
            name: "Ho\u00e0ng Ng\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27523",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27524",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27525",
            name: "H\u01b0\u01a1ng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27526",
            name: "L\u00ea H\u1eefu Tr\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27527",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27528",
            name: "L\u01b0\u01a1ng Ng\u1ecdc Quy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27529",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27530",
            name: "L\u01b0u Nh\u00e2n Ch\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27531",
            name: "L\u01b0u Nh\u00e2n Tr\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27532",
            name: "Minh C\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27533",
            name: "M\u1ecf B\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27534",
            name: "Nam N\u00fai C\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27535",
            name: "Nguy\u1ec5n C\u00f4ng Hoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27536",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27537",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27538", name: "Nha Trang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27539",
            name: "N\u00fai C\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27540",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27541",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27542",
            name: "Ph\u1ed1 H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27543",
            name: "Ph\u1ed1 Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27544",
            name: "Ph\u1ed5 Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27545",
            name: "Ph\u1ee7 Li\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27546",
            name: "Ph\u00fa Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27547",
            name: "Ph\u00fa X\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27548",
            name: "Ph\u00fac Chu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27549",
            name: "Ph\u00fac H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27550",
            name: "Ph\u00fac Tr\u00ecu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27551",
            name: "Ph\u00fac Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27552",
            name: "Ph\u00f9ng Ch\u00ed Ki\u00ean",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27553",
            name: "Quan Tri\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27554", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27555", name: "Quang Vinh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27556",
            name: "Qu\u1ed1c l\u1ed9 1B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27557",
            name: "Qu\u1ed1c l\u1ed9 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27558",
            name: "Qu\u1ed1c l\u1ed9 922",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27559",
            name: "T\u00e2n C\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27560",
            name: "T\u00e2n Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27561",
            name: "T\u00e2n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27562",
            name: "T\u00e2n Th\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27563",
            name: "Thanh Ni\u00ean Xung Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27564",
            name: "Th\u1ecbnh \u0110\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27565",
            name: "Th\u1ecbnh \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27566",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27567",
            name: "T\u00edch L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27568",
            name: "T\u1ed1 H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27569", name: "Tr\u1ecbnh B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27570",
            name: "Tr\u1ecbnh B\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27571",
            name: "T\u00fac Duy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27572", name: "V\u0103n Cao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27573",
            name: "Vi\u1ec7t B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27574",
            name: "V\u00f3 Ng\u1ef1a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27575",
            name: "V\u01b0\u01a1ng Th\u1eeba V\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27576",
            name: "Xu\u00e2n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27577",
            name: "X\u01b0\u01a1ng R\u1ed3ng",
            prefix: "Ph\u1ed1",
          },
          { id: "27578", name: "Z115", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "3001",
            name: "\u0110\u1ed3ng B\u1ea9m - Riverside Villas",
            lat: "21.604902267456",
            lng: "105.83989715576",
          },
          {
            id: "3002",
            name: "M\u01b0\u1eddng Thanh Th\u00e1i Nguy\u00ean",
            lat: "21.597988128662",
            lng: "105.84223937988",
          },
          {
            id: "3003",
            name: "Picenza Plaza",
            lat: "21.601940155029",
            lng: "105.83879089355",
          },
          {
            id: "3004",
            name: "TBCO Riverside",
            lat: "21.607866287231",
            lng: "105.82514953613",
          },
          {
            id: "3005",
            name: "Tecco Th\u00e1i Nguy\u00ean",
            lat: "21.594150543213",
            lng: "105.83396911621",
          },
        ],
      },
      {
        id: "425",
        name: "V\u00f5 Nhai",
        wards: [
          { id: "6823", name: "B\u00ecnh Long", prefix: "X\u00e3" },
          {
            id: "6824",
            name: "C\u00fac \u0110\u01b0\u1eddng",
            prefix: "X\u00e3",
          },
          { id: "6825", name: "D\u00e2n Ti\u1ebfn", prefix: "X\u00e3" },
          {
            id: "6826",
            name: "\u0110\u00ecnh C\u1ea3",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6827", name: "La Hi\u00ean", prefix: "X\u00e3" },
          { id: "6828", name: "L\u00e2u Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "6829", name: "Li\u00ean Minh", prefix: "X\u00e3" },
          { id: "6830", name: "Nghinh T\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "6831", name: "Ph\u00fa Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "6832", name: "Ph\u01b0\u01a1ng Giao", prefix: "X\u00e3" },
          { id: "6833", name: "S\u1ea3ng M\u1ed9c", prefix: "X\u00e3" },
          { id: "6834", name: "Th\u1ea7n Sa", prefix: "X\u00e3" },
          { id: "6835", name: "Th\u01b0\u1ee3ng Nung", prefix: "X\u00e3" },
          { id: "6836", name: "Tr\u00e0ng X\u00e1", prefix: "X\u00e3" },
          { id: "6837", name: "V\u0169 Ch\u1ea5n", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "27579",
            name: "Qu\u1ed1c l\u1ed9 1B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27580",
            name: "Th\u00e1i Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27581",
            name: "T\u1ec9nh l\u1ed9 242",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27582",
            name: "T\u1ec9nh l\u1ed9 265",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "34",
    code: "LCA",
    name: "L\u00e0o Cai",
    districts: [
      {
        id: "426",
        name: "B\u1eafc H\u00e0",
        wards: [
          {
            id: "6838",
            name: "B\u1eafc H\u00e0",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6839", name: "B\u1ea3n C\u00e1i", prefix: "X\u00e3" },
          { id: "6840", name: "B\u1ea3n Gi\u00e0", prefix: "X\u00e3" },
          { id: "6841", name: "B\u1ea3n Li\u1ec1n", prefix: "X\u00e3" },
          { id: "6842", name: "B\u1ea3n Ph\u1ed1", prefix: "X\u00e3" },
          { id: "6843", name: "B\u1ea3o Nhai", prefix: "X\u00e3" },
          { id: "6844", name: "C\u1ed1c L\u1ea7u", prefix: "X\u00e3" },
          { id: "6845", name: "C\u1ed1c Ly", prefix: "X\u00e3" },
          { id: "6846", name: "Ho\u00e0ng Thu Ph\u1ed1", prefix: "X\u00e3" },
          {
            id: "6847",
            name: "L\u1ea7u Th\u00ed Ng\u00e0i",
            prefix: "X\u00e3",
          },
          { id: "6848", name: "L\u00f9ng C\u1ea3i", prefix: "X\u00e3" },
          { id: "6849", name: "L\u00f9ng Ph\u00ecnh", prefix: "X\u00e3" },
          { id: "6850", name: "Na H\u1ed1i", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6851", name: "N\u1eadm \u0110\u00e9t", prefix: "X\u00e3" },
          { id: "6852", name: "N\u1eadm Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "6853", name: "N\u1eadm L\u00fac", prefix: "X\u00e3" },
          { id: "6854", name: "N\u1eadm M\u00f2n", prefix: "X\u00e3" },
          { id: "6855", name: "T\u00e0 Ch\u1ea3i", prefix: "X\u00e3" },
          { id: "6856", name: "T\u1ea3 C\u1ee7 T\u1ef7", prefix: "X\u00e3" },
          { id: "6857", name: "T\u1ea3 Van Ch\u01b0", prefix: "X\u00e3" },
          {
            id: "6858",
            name: "Th\u1ea3i Gi\u00e0ng Ph\u1ed1",
            prefix: "X\u00e3",
          },
        ],
        streets: [
          { id: "27583", name: "Na H\u1ed3i", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27584",
            name: "T\u1ec9nh l\u1ed9 153",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "427",
        name: "B\u1ea3o Th\u1eafng",
        wards: [
          { id: "6859", name: "B\u1ea3n C\u1ea7m", prefix: "X\u00e3" },
          { id: "6860", name: "B\u1ea3n Phi\u1ec7t", prefix: "X\u00e3" },
          { id: "6861", name: "Gia Ph\u00fa", prefix: "X\u00e3" },
          {
            id: "6862",
            name: "N\u00f4ng tr\u01b0\u1eddng Phong H\u1ea3i",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6863", name: "Ph\u1ed1 Lu", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6864", name: "Ph\u1ed1 Lu", prefix: "X\u00e3" },
          { id: "6865", name: "Phong Ni\u00ean", prefix: "X\u00e3" },
          { id: "6866", name: "Ph\u00fa Nhu\u1eadn", prefix: "X\u00e3" },
          { id: "6867", name: "S\u01a1n H\u00e0", prefix: "X\u00e3" },
          { id: "6868", name: "S\u01a1n H\u1ea3i", prefix: "X\u00e3" },
          {
            id: "6869",
            name: "T\u1eb1ng Lo\u1ecfng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6870", name: "Th\u00e1i Ni\u00ean", prefix: "X\u00e3" },
          { id: "6871", name: "Tr\u00ec Quang", prefix: "X\u00e3" },
          { id: "6872", name: "Xu\u00e2n Giao", prefix: "X\u00e3" },
          { id: "6873", name: "Xu\u00e2n Quang", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "27585", name: "70", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27586",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27587",
            name: "Khu\u1ea5t Quang Chi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27588",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27589", name: "N13", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27590",
            name: "Qu\u1ed1c L\u1ed9 151",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27591",
            name: "Qu\u1ed1c l\u1ed9 4D",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27592",
            name: "Qu\u1ed1c l\u1ed9 4E",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "428",
        name: "B\u1ea3o Y\u00ean",
        wards: [
          { id: "6874", name: "B\u1ea3o H\u00e0", prefix: "X\u00e3" },
          { id: "6875", name: "Cam Con", prefix: "X\u00e3" },
          { id: "6876", name: "\u0110i\u1ec7n Quan", prefix: "X\u00e3" },
          { id: "6877", name: "Kim S\u01a1n", prefix: "X\u00e3" },
          { id: "6878", name: "Long Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "6879", name: "Long Ph\u00fac", prefix: "X\u00e3" },
          { id: "6880", name: "L\u01b0\u01a1ng S\u01a1n", prefix: "X\u00e3" },
          { id: "6881", name: "Minh T\u00e2n", prefix: "X\u00e3" },
          { id: "6882", name: "Ngh\u0129a \u0110\u00f4", prefix: "X\u00e3" },
          {
            id: "6883",
            name: "Ph\u1ed1 R\u00e0ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6884", name: "T\u00e2n D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "6885", name: "T\u00e2n Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "6886", name: "Th\u01b0\u1ee3ng H\u00e0", prefix: "X\u00e3" },
          { id: "6887", name: "Vi\u1ec7t Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "6888", name: "V\u0129nh Y\u00ean", prefix: "X\u00e3" },
          { id: "6889", name: "Xu\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "6890", name: "Xu\u00e2n Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "6891", name: "Y\u00ean S\u01a1n", prefix: "X\u00e3" },
        ],
        streets: [{ id: "27593", name: "70", prefix: "\u0110\u01b0\u1eddng" }],
        projects: [],
      },
      {
        id: "429",
        name: "B\u00e1t X\u00e1t",
        wards: [
          { id: "6892", name: "A L\u00f9", prefix: "X\u00e3" },
          { id: "6893", name: "A M\u00fa Sung", prefix: "X\u00e3" },
          { id: "6894", name: "B\u1ea3n Qua", prefix: "X\u00e3" },
          { id: "6895", name: "B\u1ea3n V\u01b0\u1ee3c", prefix: "X\u00e3" },
          { id: "6896", name: "B\u1ea3n X\u00e8o", prefix: "X\u00e3" },
          {
            id: "6897",
            name: "B\u00e1t X\u00e1t",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6898", name: "C\u1ed1c M\u1ef3", prefix: "X\u00e3" },
          { id: "6899", name: "C\u1ed1c San", prefix: "X\u00e3" },
          { id: "6900", name: "D\u1ec1n S\u00e1ng", prefix: "X\u00e3" },
          { id: "6901", name: "D\u1ec1n Th\u00e0ng", prefix: "X\u00e3" },
          { id: "6902", name: "M\u01b0\u1eddng Hum", prefix: "X\u00e3" },
          { id: "6903", name: "M\u01b0\u1eddng Vi", prefix: "X\u00e3" },
          { id: "6904", name: "N\u1eadm Ch\u1ea1c", prefix: "X\u00e3" },
          { id: "6905", name: "N\u1eadm Pung", prefix: "X\u00e3" },
          { id: "6906", name: "Ng\u1ea3i Th\u1ea7u", prefix: "X\u00e3" },
          { id: "6907", name: "Pa Cheo", prefix: "X\u00e3" },
          { id: "6908", name: "Ph\u00ecn Ngan", prefix: "X\u00e3" },
          { id: "6909", name: "Quang Kim", prefix: "X\u00e3" },
          { id: "6910", name: "S\u00e0ng Ma S\u00e1o", prefix: "X\u00e3" },
          { id: "6911", name: "T\u00f2ng Sanh", prefix: "X\u00e3" },
          { id: "6912", name: "Tr\u1ecbnh T\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "6913", name: "Trung L\u00e8ng H\u1ed3", prefix: "X\u00e3" },
          { id: "6914", name: "Y T\u00fd", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "27594",
            name: "\u0110\u00f4ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27595",
            name: "Ho\u00e0ng Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27596",
            name: "T\u1ec9nh l\u1ed9 155",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "430",
        name: "L\u00e0o Cai",
        wards: [
          {
            id: "6915",
            name: "B\u1eafc C\u01b0\u1eddng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "6916",
            name: "B\u1eafc L\u1ec7nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "6917", name: "B\u00ecnh Minh", prefix: "Ph\u01b0\u1eddng" },
          { id: "6918", name: "Cam \u0110\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "6919", name: "C\u1ed1c L\u1ebfu", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "6921",
            name: "Duy\u00ean H\u1ea3i",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "6920", name: "\u0110\u1ed3ng Tuy\u1ec3n", prefix: "X\u00e3" },
          { id: "6922", name: "H\u1ee3p Th\u00e0nh", prefix: "X\u00e3" },
          { id: "6923", name: "Kim T\u00e2n", prefix: "Ph\u01b0\u1eddng" },
          { id: "6924", name: "L\u00e0o Cai", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "6925",
            name: "Nam C\u01b0\u1eddng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "6926",
            name: "Nam C\u01b0\u01a1\u0300ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "6927", name: "Ph\u1ed1 M\u1edbi", prefix: "Ph\u01b0\u1eddng" },
          { id: "6928", name: "Pom H\u00e1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "6929", name: "T\u1ea3 Ph\u1eddi", prefix: "X\u00e3" },
          {
            id: "6930",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "6931", name: "V\u1ea1n H\u00f2a", prefix: "X\u00e3" },
          {
            id: "6932",
            name: "Xu\u00e2n T\u0103ng",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "27597", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27598", name: "19/8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27599", name: "22", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27600", name: "22/12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27601", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27602", name: "A3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27603", name: "A4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27604", name: "A5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27605", name: "A6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27606",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27607", name: "An L\u1ea1c", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27608",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27609", name: "B1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27610", name: "B14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27611", name: "B15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27612", name: "B3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27613", name: "B8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27614",
            name: "B\u1eb1ng Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27615",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27616",
            name: "B\u1ed5 \u00d4 Kh\u00e1nh Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27617",
            name: "B\u00f9i \u0110\u1ee9c Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27618",
            name: "B\u00f9i Nguy\u00ean Khi\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27619", name: "Cao L\u1ed7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27620",
            name: "Cao t\u1ed1c H\u00e0 N\u1ed9i - L\u00e0o Cai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27621",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27622",
            name: "C\u00f4 Ti\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27623", name: "D1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27624", name: "D10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27625", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27626", name: "D3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27627", name: "D4A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27628", name: "D6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27638", name: "DM1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27639", name: "DM3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27640", name: "DM4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27644",
            name: "Duy\u00ean H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27629",
            name: "\u0110an \u0110\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27630",
            name: "\u0110\u1eb7ng Huy Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27631",
            name: "\u0110\u1eb7ng Thai Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27632",
            name: "\u0110\u1eb7ng Th\u00f9y Tr\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27633",
            name: "\u0110\u1eb7ng Tr\u1ea7n C\u00f4n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27634",
            name: "\u0110\u00e0o Duy Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27635",
            name: "\u0110i\u1ec7n Bi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27636",
            name: "\u0110inh B\u1ed9 L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27637",
            name: "\u0110inh L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27641", name: "\u0110\u1ed7 Nhu\u1eadn", prefix: "Ph\u1ed1" },
          {
            id: "27642",
            name: "\u0110o\u00e0n K\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27643",
            name: "\u0110\u1ed3ng T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27645", name: "E11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27646", name: "E13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27647", name: "E16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27648", name: "E17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27649", name: "E18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27650", name: "E3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27651", name: "E9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27652", name: "F3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27653", name: "Fansipan", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27654",
            name: "H\u00e0 B\u1ed5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27655",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27656",
            name: "H\u1ed3 \u0110\u1eafc Di",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27657",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27658",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27659",
            name: "Hoa Qu\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27660",
            name: "Ho\u00e0ng C\u1ea7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27661",
            name: "Ho\u00e0ng C\u00f4ng Ch\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27662",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27663",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27664",
            name: "Ho\u00e0ng Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27665",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27666",
            name: "Ho\u00e0ng Quy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27667",
            name: "Ho\u00e0ng S\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27668",
            name: "Ho\u00e0ng Tr\u01b0\u1eddng Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27669",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27670",
            name: "Ho\u00e0ng Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27671",
            name: "H\u1ed3ng H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27672",
            name: "H\u01b0ng H\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27673",
            name: "H\u01b0\u01a1ng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27674",
            name: "Kh\u00e1nh Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27675",
            name: "Kh\u00fac Th\u1eeba D\u1ee5",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27676",
            name: "Kim Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27677", name: "L2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27678",
            name: "L\u1ea1c S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27679",
            name: "L\u00ea Anh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27680",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27681", name: "L\u00ea Hoa", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27682",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27683", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27684",
            name: "L\u00ea Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27685",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27686",
            name: "L\u00ea Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27687",
            name: "L\u00ea Thi\u1ebft H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27688",
            name: "L\u00ea V\u0103n Thi\u00eam",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27689",
            name: "L\u00ea V\u0103n Th\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27690",
            name: "L\u01b0\u01a1ng Kh\u00e1nh Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27691",
            name: "L\u01b0u B\u00e1ch Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27692",
            name: "L\u01b0u H\u1eefu Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27693",
            name: "L\u00fd C\u00f4ng U\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27694",
            name: "L\u00fd \u0110\u1ea1o Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27695",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27696",
            name: "L\u00fd Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27697",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27698",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          { id: "27699", name: "M21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27700", name: "M3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27701", name: "M8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27702",
            name: "M\u00e3 Y\u00ean S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27703",
            name: "Mai H\u1eafc \u0110\u1ebf",
            prefix: "Ph\u1ed1",
          },
          { id: "27704", name: "Mai V\u0103n Ty", prefix: "Ph\u1ed1" },
          { id: "27705", name: "Minh Khai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27706",
            name: "M\u01b0\u1eddng Hoa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27707",
            name: "M\u01b0\u1eddng Than",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27708", name: "N10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27709", name: "N11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27710", name: "N12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27711", name: "N13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27712", name: "N14", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27713",
            name: "N16 B\u1eafc C\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27714", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27715", name: "N5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27716", name: "N6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27717", name: "N7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27718", name: "N9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27719", name: "Nam Cao", prefix: "Ph\u1ed1" },
          {
            id: "27720",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27721",
            name: "Ng\u00f4 S\u0129 Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27722",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27723",
            name: "Ng\u00f4 V\u0103n S\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27724",
            name: "Ng\u1ecdc Uy\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27725",
            name: "Ng\u0169 Ch\u1ec9 S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27726",
            name: "Nguy\u1ec5n B\u00e1 L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27727",
            name: "Nguy\u1ec5n B\u1eb7c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27728",
            name: "Nguy\u1ec5n B\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27729",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27730",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27731",
            name: "Nguy\u1ec5n Ch\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27732",
            name: "Nguy\u1ec5n Danh Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27735",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27733",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27734",
            name: "Nguy\u1ec5n \u0110\u00ecnh Thi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27736",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27737",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27738",
            name: "Nguy\u1ec5n H\u1eefu An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27739",
            name: "Nguy\u1ec5n Kho\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27740",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27741",
            name: "Nguy\u1ec5n S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27742",
            name: "Nguy\u1ec5n Th\u0103ng B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27743",
            name: "Nguy\u1ec5n Th\u00e0nh Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27744",
            name: "Nguy\u1ec5n Th\u1ebf L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27745",
            name: "Nguy\u1ec5n Thi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27746",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27747",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27748",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27749",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27750",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27751",
            name: "Nguy\u1ec5n Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27752",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27753", name: "Nguy\u1ec5n X\u00ed", prefix: "Ph\u1ed1" },
          {
            id: "27754",
            name: "Nh\u1ea1c S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27755",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27756",
            name: "Ph\u1ea1m Th\u1eadn Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27757",
            name: "Ph\u1ea1m V\u0103n Kh\u1ea3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27758",
            name: "Ph\u1ea1m V\u0103n X\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27759",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27760",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27761",
            name: "Phan Huy \u00cdch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27762", name: "Phan Ph\u00f9 Ti\u00ean", prefix: "Ph\u1ed1" },
          {
            id: "27763",
            name: "Ph\u00f9ng Ch\u00ed Ki\u00ean",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27764",
            name: "Qu\u00e1ch V\u0103n R\u1ea1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27765",
            name: "Quang Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27766",
            name: "Qu\u1ed1c l\u1ed9 4D",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27767",
            name: "Qu\u1ed1c l\u1ed9 4E",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27768",
            name: "Qu\u1ed1c l\u1ed9 70",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27769",
            name: "Qu\u00fd H\u00f3a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27770",
            name: "Soi Chi\u1ec1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27771", name: "Soi L\u1ea7n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27772",
            name: "Soi Ti\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27773",
            name: "S\u01a1n \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27774",
            name: "S\u01a1n T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27775", name: "T1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27776", name: "T15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27777", name: "T2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27778", name: "T3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27779", name: "T5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27780",
            name: "T\u00e2n H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27781",
            name: "T\u00e2n L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27782",
            name: "T\u00e2n Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27783",
            name: "Th\u1ea1ch S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27784",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27785",
            name: "T\u1ec9nh l\u1ed9 155",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27786", name: "TN7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27787",
            name: "T\u00f4 Ng\u1ecdc V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27788",
            name: "T\u00f4n Th\u1ea5t Thuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27789",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27790",
            name: "Tr\u1ea7n \u0110\u0103ng Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27791",
            name: "Tr\u1ea7n Ho\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27792",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27793",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27794",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27795",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27796",
            name: "Tr\u1ea7n Th\u1ee7 \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27797",
            name: "Tr\u1ea7n Tung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27798",
            name: "Tr\u1ea7n V\u0103n N\u1ecf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27799",
            name: "Tri\u1ec7u Quang Ph\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27800",
            name: "Tri\u1ec7u Ti\u1ebfn Ti\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27801",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27802",
            name: "V\u00f5 \u0110\u1ea1i Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27803",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27804",
            name: "V\u00f5 V\u0103n T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27805",
            name: "V\u0169 V\u0103n M\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27806",
            name: "V\u01b0\u01a1ng Th\u1eeba V\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27807",
            name: "Xu\u00e2n Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27808",
            name: "Xuy\u00ean \u00c1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27809", name: "\u1ef6 Lan", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27810",
            name: "Y\u00ean L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27811",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3006",
            name: "Khu \u0111\u00f4 th\u1ecb Kosy",
            lat: "22.445882797241",
            lng: "104.00377655029",
          },
          {
            id: "3007",
            name: "The Manor Eco L\u00e0o Cai",
            lat: "22.464010238647",
            lng: "103.97821807861",
          },
        ],
      },
      {
        id: "431",
        name: "M\u01b0\u1eddng Kh\u01b0\u01a1ng",
        wards: [
          { id: "6933", name: "B\u1ea3n L\u1ea7u", prefix: "X\u00e3" },
          { id: "6934", name: "B\u1ea3n Sen", prefix: "X\u00e3" },
          { id: "6935", name: "Cao S\u01a1n", prefix: "X\u00e3" },
          { id: "6936", name: "D\u00ecn Chin", prefix: "X\u00e3" },
          { id: "6937", name: "La Pan T\u1ea9n", prefix: "X\u00e3" },
          { id: "6938", name: "L\u00f9ng Kh\u1ea5u Nhin", prefix: "X\u00e3" },
          { id: "6939", name: "L\u00f9ng Vai", prefix: "X\u00e3" },
          {
            id: "6940",
            name: "M\u01b0\u1eddng Kh\u01b0\u01a1ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "6941", name: "N\u1eadm Ch\u1ea3y", prefix: "X\u00e3" },
          { id: "6942", name: "N\u1ea5m L\u01b0", prefix: "X\u00e3" },
          { id: "6943", name: "Pha Long", prefix: "X\u00e3" },
          { id: "6944", name: "T\u1ea3 Gia Kh\u00e2u", prefix: "X\u00e3" },
          { id: "6945", name: "T\u1ea3 Ng\u1ea3i Ch\u1ed3", prefix: "X\u00e3" },
          { id: "6946", name: "T\u1ea3 Th\u00e0ng", prefix: "X\u00e3" },
          { id: "6947", name: "Thanh B\u00ecnh", prefix: "X\u00e3" },
          { id: "6948", name: "Tung Chung Ph\u1ed1", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "27812",
            name: "Qu\u1ed1c l\u1ed9 4D",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "432",
        name: "Sa Pa",
        wards: [
          { id: "6949", name: "B\u1ea3n H\u1ed3", prefix: "X\u00e3" },
          { id: "6950", name: "B\u1ea3n Khoang", prefix: "X\u00e3" },
          { id: "6951", name: "B\u1ea3n Ph\u00f9ng", prefix: "X\u00e3" },
          { id: "6952", name: "H\u1ea7u Th\u00e0o", prefix: "X\u00e3" },
          { id: "6953", name: "Lao Ch\u1ea3i", prefix: "X\u00e3" },
          { id: "6954", name: "N\u1eadm Cang", prefix: "X\u00e3" },
          { id: "6955", name: "N\u1eadm S\u00e0i", prefix: "X\u00e3" },
          { id: "6956", name: "Sa Pa", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "6957", name: "Sa P\u1ea3", prefix: "X\u00e3" },
          { id: "6958", name: "San S\u1ea3 H\u1ed3", prefix: "X\u00e3" },
          { id: "6959", name: "S\u1eed P\u00e1n", prefix: "X\u00e3" },
          { id: "6960", name: "Su\u1ed1i Th\u1ea7u", prefix: "X\u00e3" },
          {
            id: "6961",
            name: "T\u1ea3 Gi\u00e0ng Ph\u00ecnh",
            prefix: "X\u00e3",
          },
          { id: "6962", name: "T\u1ea3 Ph\u00ecn", prefix: "X\u00e3" },
          { id: "6963", name: "T\u1ea3 Van", prefix: "X\u00e3" },
          { id: "6964", name: "Thanh Kim", prefix: "X\u00e3" },
          { id: "6965", name: "Thanh Ph\u00fa", prefix: "X\u00e3" },
          { id: "6966", name: "Trung Ch\u1ea3i", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "27813",
            name: "C\u1ea7u M\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27814",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27815",
            name: "\u0110\u1ed3ng L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27816", name: "Fansipan", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27817",
            name: "Ho\u00e0ng Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27818",
            name: "H\u00f9ng H\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27819",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27820",
            name: "L\u00ea V\u0103n T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27821",
            name: "M\u01b0\u1eddng Hoa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27822",
            name: "Ng\u0169 Ch\u1ec9 S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27823",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27824",
            name: "Qu\u1ed1c l\u1ed9 4D",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27825", name: "Th\u00e1c B\u1ea1c", prefix: "Ph\u1ed1" },
          {
            id: "27826",
            name: "Th\u1ea1ch S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27827",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27828", name: "Violet", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27829",
            name: "Xu\u00e2n Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3008",
            name: "Mercure Sapa",
            lat: "22.325313568115",
            lng: "103.85493469238",
          },
          {
            id: "3009",
            name: "Sapa Jade Hill",
            lat: "22.325395584106",
            lng: "103.85414886475",
          },
        ],
      },
      {
        id: "433",
        name: "V\u0103n B\u00e0n",
        wards: [
          { id: "6967", name: "Chi\u1ec1ng Ken", prefix: "X\u00e3" },
          { id: "6968", name: "D\u1ea7n Th\u00e0ng", prefix: "X\u00e3" },
          { id: "6969", name: "D\u01b0\u01a1ng Q\u00f9y", prefix: "X\u00e3" },
          { id: "6970", name: "H\u00f2a M\u1ea1c", prefix: "X\u00e3" },
          {
            id: "6971",
            name: "Kh\u00e1nh Y\u00ean",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "6972",
            name: "Kh\u00e1nh Y\u00ean H\u1ea1",
            prefix: "X\u00e3",
          },
          {
            id: "6973",
            name: "Kh\u00e1nh Y\u00ean Th\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
          { id: "6974", name: "Kh\u00e1nh Y\u00ean Trung", prefix: "X\u00e3" },
          { id: "6975", name: "L\u00e0ng Gi\u00e0ng", prefix: "X\u00e3" },
          { id: "6976", name: "Li\u00eam Ph\u00fa", prefix: "X\u00e3" },
          { id: "6977", name: "Minh L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "6978", name: "N\u1eadm Ch\u00e0y", prefix: "X\u00e3" },
          { id: "6979", name: "N\u1eadm M\u1ea3", prefix: "X\u00e3" },
          { id: "6980", name: "N\u1eadm R\u1ea1ng", prefix: "X\u00e3" },
          { id: "6981", name: "N\u1eadm Tha", prefix: "X\u00e3" },
          { id: "6982", name: "N\u1eadm X\u00e2y", prefix: "X\u00e3" },
          { id: "6983", name: "N\u1eadm X\u00e9", prefix: "X\u00e3" },
          { id: "6984", name: "S\u01a1n Th\u1ee7y", prefix: "X\u00e3" },
          { id: "6985", name: "T\u00e2n An", prefix: "X\u00e3" },
          { id: "6986", name: "T\u00e2n Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "6987", name: "Th\u1eb3m D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "6988", name: "V\u0103n S\u01a1n", prefix: "X\u00e3" },
          { id: "6989", name: "V\u00f5 Lao", prefix: "X\u00e3" },
        ],
        streets: [{ id: "27830", name: "21", prefix: "\u0110\u01b0\u1eddng" }],
        projects: [],
      },
      {
        id: "434",
        name: "Xi Ma Cai",
        wards: [
          { id: "6990", name: "B\u1ea3n M\u1ebf", prefix: "X\u00e3" },
          { id: "6991", name: "C\u00e1n C\u1ea5u", prefix: "X\u00e3" },
          { id: "6992", name: "C\u00e1n H\u1ed3", prefix: "X\u00e3" },
          { id: "6993", name: "L\u1eed Th\u1ea9n", prefix: "X\u00e3" },
          { id: "6994", name: "L\u00f9ng Sui", prefix: "X\u00e3" },
          { id: "6995", name: "M\u1ea3n Th\u1ea9n", prefix: "X\u00e3" },
          { id: "6996", name: "N\u00e0n S\u00e1n", prefix: "X\u00e3" },
          { id: "6997", name: "N\u00e0n X\u00edn", prefix: "X\u00e3" },
          { id: "6998", name: "Quan Th\u1ea7n S\u00e1n", prefix: "X\u00e3" },
          { id: "6999", name: "S\u00e1n Ch\u1ea3i", prefix: "X\u00e3" },
          { id: "7000", name: "Si Ma Cai", prefix: "X\u00e3" },
          { id: "7001", name: "S\u00edn Ch\u00e9ng", prefix: "X\u00e3" },
          {
            id: "7002",
            name: "Th\u00e0o Ch\u01b0 Ph\u00ecn",
            prefix: "X\u00e3",
          },
        ],
        streets: [
          {
            id: "27831",
            name: "T\u1ec9nh l\u1ed9 153",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "35",
    code: "NDD",
    name: "Nam \u0110\u1ecbnh",
    districts: [
      {
        id: "435",
        name: "Giao Th\u1ee7y",
        wards: [
          { id: "7003", name: "B\u1ea1ch Long", prefix: "X\u00e3" },
          { id: "7004", name: "B\u00ecnh H\u00f2a", prefix: "X\u00e3" },
          { id: "7005", name: "Giao An", prefix: "X\u00e3" },
          { id: "7006", name: "Giao Ch\u00e2u", prefix: "X\u00e3" },
          { id: "7007", name: "Giao H\u00e0", prefix: "X\u00e3" },
          { id: "7008", name: "Giao H\u1ea3i", prefix: "X\u00e3" },
          { id: "7009", name: "Giao H\u01b0ng", prefix: "X\u00e3" },
          { id: "7010", name: "Giao H\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "7011", name: "Giao L\u1ea1c", prefix: "X\u00e3" },
          { id: "7012", name: "Giao Long", prefix: "X\u00e3" },
          { id: "7013", name: "Giao Nh\u00e2n", prefix: "X\u00e3" },
          { id: "7014", name: "Giao Phong", prefix: "X\u00e3" },
          { id: "7015", name: "Giao T\u00e2n", prefix: "X\u00e3" },
          { id: "7016", name: "Giao Thanh", prefix: "X\u00e3" },
          { id: "7017", name: "Giao Thi\u1ec7n", prefix: "X\u00e3" },
          { id: "7018", name: "Giao Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "7019", name: "Giao Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "7020", name: "Giao Xu\u00e2n", prefix: "X\u00e3" },
          { id: "7021", name: "Giao Y\u1ebfn", prefix: "X\u00e3" },
          { id: "7022", name: "Ho\u00e0nh S\u01a1n", prefix: "X\u00e3" },
          { id: "7023", name: "H\u1ed3ng Thu\u1eadn", prefix: "X\u00e3" },
          {
            id: "7024",
            name: "Ng\u00f4 \u0110\u1ed3ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "7025",
            name: "Qu\u1ea5t L\u00e2m",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          { id: "27832", name: "56", prefix: "Ph\u1ed1" },
          {
            id: "27833",
            name: "Qu\u1ed1c L\u1ed9 37B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27834",
            name: "T\u1ec9nh l\u1ed9 489",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "436",
        name: "H\u1ea3i H\u1eadu",
        wards: [
          { id: "7026", name: "C\u1ed3n", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "7027", name: "H\u1ea3i An", prefix: "X\u00e3" },
          { id: "7028", name: "H\u1ea3i Anh", prefix: "X\u00e3" },
          { id: "7029", name: "H\u1ea3i B\u1eafc", prefix: "X\u00e3" },
          { id: "7030", name: "H\u1ea3i Ch\u00e2u", prefix: "X\u00e3" },
          { id: "7031", name: "H\u1ea3i Ch\u00ednh", prefix: "X\u00e3" },
          { id: "7032", name: "H\u1ea3i C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "7033", name: "H\u1ea3i \u0110\u00f4ng", prefix: "X\u00e3" },
          {
            id: "7034",
            name: "H\u1ea3i \u0110\u01b0\u1eddng",
            prefix: "X\u00e3",
          },
          { id: "7035", name: "H\u1ea3i Giang", prefix: "X\u00e3" },
          { id: "7036", name: "H\u1ea3i H\u00e0", prefix: "X\u00e3" },
          { id: "7037", name: "H\u1ea3i H\u00f2a", prefix: "X\u00e3" },
          { id: "7038", name: "H\u1ea3i H\u01b0ng", prefix: "X\u00e3" },
          { id: "7039", name: "H\u1ea3i L\u1ed9c", prefix: "X\u00e3" },
          { id: "7040", name: "H\u1ea3i Long", prefix: "X\u00e3" },
          { id: "7041", name: "H\u1ea3i L\u00fd", prefix: "X\u00e3" },
          { id: "7042", name: "H\u1ea3i Minh", prefix: "X\u00e3" },
          { id: "7043", name: "H\u1ea3i Nam", prefix: "X\u00e3" },
          { id: "7044", name: "H\u1ea3i Ninh", prefix: "X\u00e3" },
          { id: "7045", name: "H\u1ea3i Phong", prefix: "X\u00e3" },
          { id: "7046", name: "H\u1ea3i Ph\u00fa", prefix: "X\u00e3" },
          { id: "7047", name: "H\u1ea3i Ph\u00fac", prefix: "X\u00e3" },
          { id: "7048", name: "H\u1ea3i Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "7049", name: "H\u1ea3i Quang", prefix: "X\u00e3" },
          { id: "7050", name: "H\u1ea3i S\u01a1n", prefix: "X\u00e3" },
          { id: "7051", name: "H\u1ea3i T\u00e2n", prefix: "X\u00e3" },
          { id: "7052", name: "H\u1ea3i T\u00e2y", prefix: "X\u00e3" },
          { id: "7053", name: "H\u1ea3i Thanh", prefix: "X\u00e3" },
          { id: "7054", name: "H\u1ea3i To\u00e0n", prefix: "X\u00e3" },
          { id: "7055", name: "H\u1ea3i Tri\u1ec1u", prefix: "X\u00e3" },
          { id: "7056", name: "H\u1ea3i Trung", prefix: "X\u00e3" },
          { id: "7057", name: "H\u1ea3i V\u00e2n", prefix: "X\u00e3" },
          { id: "7058", name: "H\u1ea3i Xu\u00e2n", prefix: "X\u00e3" },
          { id: "7059", name: "Th\u1ecbnh Long", prefix: "Th\u1ecb tr\u1ea5n" },
          {
            id: "7060",
            name: "Y\u00ean \u0110\u1ecbnh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          { id: "27835", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27836", name: "56", prefix: "Ph\u1ed1" },
          {
            id: "27837",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27838",
            name: "Ph\u1ed1 M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27839", name: "S\u1ed1 20", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
      {
        id: "437",
        name: "M\u1ef9 L\u1ed9c",
        wards: [
          { id: "7061", name: "M\u1ef9 H\u00e0", prefix: "X\u00e3" },
          { id: "7062", name: "M\u1ef9 H\u01b0ng", prefix: "X\u00e3" },
          {
            id: "7063",
            name: "M\u1ef9 L\u1ed9c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7064", name: "M\u1ef9 Ph\u00fac", prefix: "X\u00e3" },
          { id: "7065", name: "M\u1ef9 T\u00e2n", prefix: "X\u00e3" },
          { id: "7066", name: "M\u1ef9 Th\u1eafng", prefix: "X\u00e3" },
          { id: "7067", name: "M\u1ef9 Th\u00e0nh", prefix: "X\u00e3" },
          { id: "7068", name: "M\u1ef9 Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "7069", name: "M\u1ef9 Thu\u1eadn", prefix: "X\u00e3" },
          { id: "7070", name: "M\u1ef9 Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "7071", name: "M\u1ef9 Trung", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "27840", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27841", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27842",
            name: "Li\u1ec5u Nha",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27843",
            name: "Ph\u1ed1 B\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27844",
            name: "Qu\u1ed1c L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27845",
            name: "Vi\u0303nh Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "438",
        name: "Nam \u0110\u1ecbnh",
        wards: [
          {
            id: "7072",
            name: "B\u00e0 Tri\u1ec7u",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "7073", name: "C\u1eeda B\u1eafc", prefix: "Ph\u01b0\u1eddng" },
          { id: "7074", name: "C\u1eeda Nam", prefix: "Ph\u01b0\u1eddng" },
          { id: "7075", name: "H\u1ea1 Long", prefix: "Ph\u01b0\u1eddng" },
          { id: "7076", name: "L\u1ed9c An", prefix: "X\u00e3" },
          { id: "7077", name: "L\u1ed9c H\u1ea1", prefix: "Ph\u01b0\u1eddng" },
          { id: "7078", name: "L\u1ed9c H\u00f2a", prefix: "X\u00e3" },
          {
            id: "7079",
            name: "L\u1ed9c V\u01b0\u1ee3ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "7080", name: "M\u1ef9 X\u00e1", prefix: "X\u00e3" },
          { id: "7081", name: "Nam Phong", prefix: "X\u00e3" },
          { id: "7082", name: "Nam V\u00e2n", prefix: "X\u00e3" },
          {
            id: "7083",
            name: "N\u0103ng T\u0129nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "7084",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "7085", name: "Nguy\u1ec5n Du", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "7086",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "7087", name: "Quang Trung", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "7088",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "7089",
            name: "Tr\u1ea7n \u0110\u0103ng Ninh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "7090",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "7091",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "7092",
            name: "Tr\u1ea7n T\u1ebf X\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "7093",
            name: "Tr\u01b0\u1eddng Thi",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "7094",
            name: "V\u0103n Mi\u1ebfu",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "7095",
            name: "V\u1ecb Ho\u00e0ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "7096",
            name: "V\u1ecb Xuy\u00ean",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "27846", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27847", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27848", name: "55", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27849", name: "56", prefix: "Ph\u1ed1" },
          {
            id: "27850",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27851", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "27852",
            name: "B\u1eafc \u00c1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27853",
            name: "B\u1eafc Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27854",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27855", name: "B\u00e1i", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27856",
            name: "B\u00e3i Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27857",
            name: "B\u00e3i V\u01b0\u1ee3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27858",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27859",
            name: "B\u1ebfn Ng\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27860",
            name: "B\u1ebfn Th\u00f3c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27861",
            name: "B\u00f9i B\u1eb1ng \u0110o\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27862",
            name: "B\u00f9i \u0110\u00ecnh H\u00f2e",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27863",
            name: "B\u00f9i Huy \u0110\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27864",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27865",
            name: "B\u00f9i Xu\u00e2n M\u1eabn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27866",
            name: "B\u00f9i Xu\u00e2n Ph\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27867",
            name: "C\u1ea3i C\u00e1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27868",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27869", name: "C\u1ea7u \u0110\u00f4ng", prefix: "Ph\u1ed1" },
          { id: "27870", name: "Chu V\u0103n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27871",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27872",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27873",
            name: "C\u1eeda Dinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27874", name: "D4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27887",
            name: "D\u00e2y Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27890",
            name: "Di\u00ean H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27875",
            name: "\u0110\u1eb7ng \u0110o\u00e0n B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27876",
            name: "\u0110\u1eb7ng Thai Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27877",
            name: "\u0110\u1eb7ng Tr\u1ea7n C\u00f4n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27878",
            name: "\u0110\u1eb7ng V\u0103n Ng\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27879",
            name: "\u0110\u1eb7ng Vi\u1ec7t Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27880",
            name: "\u0110\u1eb7ng V\u0169 H\u1ef7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27881",
            name: "\u0110\u1eb7ng Xu\u00e2n B\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27882",
            name: "\u0110\u1eb7ng Xu\u00e2n Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27883",
            name: "\u0110\u1eb7ng Xu\u00e2n Vi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27884",
            name: "\u0110\u00e0o S\u01b0 T\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27885",
            name: "\u0110\u00e0o T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27886",
            name: "\u0110\u00e0o V\u0103n Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27888",
            name: "\u0110\u00ea\u0323 T\u01b0\u0301",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27889",
            name: "\u0110i\u1ec7n Bi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27891",
            name: "\u0110inh B\u1ed9 L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27892",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27893",
            name: "\u0110inh L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27894",
            name: "\u0110inh Th\u1ecb V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27895",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27896",
            name: "\u0110\u1ed7 Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27897",
            name: "\u0110\u1ed7 Huy R\u1eeba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27898",
            name: "\u0110\u1ed7 M\u1ea1nh \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27899",
            name: "\u0110\u00f2 Quan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27900",
            name: "\u0110o\u00e0n K\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27901",
            name: "\u0110o\u00e0n Khu\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27902",
            name: "\u0110o\u00e0n Nh\u1eef H\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27903",
            name: "\u0110o\u00e0n Tr\u1ea7n Nghi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27904",
            name: "\u0110\u00f4ng A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27905",
            name: "\u0110\u00f4ng Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27906",
            name: "\u0110\u00f4ng Kinh Ngh\u0129a Th\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27907",
            name: "\u0110\u00f4ng M\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27908",
            name: "\u0110\u00f4ng Y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27909",
            name: "Gi\u1ea3i Ph\u00f3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27910",
            name: "G\u1ed1c M\u00edt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27911", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "27912",
            name: "H\u00e0n Thuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27913",
            name: "H\u00e0ng C\u1ea5p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27914",
            name: "H\u00e0ng Cau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27915",
            name: "H\u00e0ng \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27916",
            name: "H\u00e0ng S\u1eaft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27917",
            name: "H\u00e0ng Thao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27918",
            name: "H\u00e0ng Ti\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27919",
            name: "H\u1eadu L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27920",
            name: "Hi\u1ec7p Th\u00e0nh 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27921",
            name: "H\u00f2a V\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27922",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27923",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27924",
            name: "Ho\u00e0ng H\u1eefu Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27925",
            name: "Ho\u00e0ng Ng\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27926",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27927",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27928",
            name: "H\u01b0ng Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27929",
            name: "Huy\u1ec1n Tr\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27930",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27931", name: "K\u00eanh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27932",
            name: "L\u00e3 Xu\u00e2n Oai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27933",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27934",
            name: "L\u00e1ng Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27935",
            name: "L\u00ea \u0110\u1ee9c Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27936",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27937",
            name: "L\u00ea H\u1ed3ng S\u01a1n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27938",
            name: "L\u00ea H\u1eefu Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27939",
            name: "L\u00ea Ng\u1ecdc D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27940",
            name: "L\u00ea Ng\u1ecdc H\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27941",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27942",
            name: "L\u00ea Ti\u1ebfn Ph\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27943",
            name: "L\u00ea V\u0103n H\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27944",
            name: "L\u00ea V\u0103n Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27945",
            name: "Li\u00ean C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27946",
            name: "Li\u00ean H\u00e0 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27947",
            name: "Li\u00ean H\u00e0 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27948",
            name: "L\u1ed9c H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27949",
            name: "L\u1ed9c V\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27950",
            name: "L\u01b0\u01a1ng Kh\u00e1nh Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27951",
            name: "L\u01b0\u01a1ng Ng\u1ecdc Quy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27952",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27953",
            name: "L\u01b0\u01a1ng V\u0103n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27954",
            name: "L\u01b0\u01a1ng X\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27955",
            name: "L\u01b0u Ch\u00ed Hi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27956",
            name: "L\u01b0u H\u1eefu Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27957",
            name: "L\u01b0u Tr\u1ecdng L\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27958",
            name: "L\u00fd \u00d4ng Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27959",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27960",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27961",
            name: "M\u1ea1c Th\u1ecb B\u01b0\u1edfi",
            prefix: "Ph\u1ed1",
          },
          { id: "27962", name: "Mai X\u00e1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27963",
            name: "M\u00e1y Chai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27964", name: "Minh Khai", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27965", name: "M\u1ef9 Tho", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "27966",
            name: "M\u1ef9 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27967",
            name: "M\u1ef9 X\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "27968", name: "N3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27969", name: "N4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27970", name: "N6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27971", name: "N8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "27972", name: "Nam Cao", prefix: "Ph\u1ed1" },
          {
            id: "27973",
            name: "Ng\u00f4 Gia Kh\u1ea3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27974",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27975",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27976",
            name: "Ng\u00f4 S\u1ef9 Li\u00ean",
            prefix: "Ph\u1ed1",
          },
          {
            id: "27977",
            name: "Ng\u00f4 T\u1ea5t T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27978",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27979",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27980",
            name: "Nguy\u1ec5n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27981",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27982",
            name: "Nguy\u1ec5n Cao Luy\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27983",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27984",
            name: "Nguy\u1ec5n C\u01a1 Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27985",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27986",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27987",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27988",
            name: "Nguy\u1ec5n \u0110\u1ee9c Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27989",
            name: "Nguy\u1ec5n Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27990",
            name: "Nguy\u1ec5n H\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27991",
            name: "Nguy\u00ean H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27992",
            name: "Nguy\u1ec5n Huy Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27993",
            name: "Nguy\u1ec5n Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27994",
            name: "Nguy\u1ec5n Kh\u00e1nh To\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27995",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27996",
            name: "Nguy\u1ec5n Ng\u1ecdc \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27997",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27998",
            name: "Nguy\u1ec5n Th\u1ebf R\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "27999",
            name: "Nguy\u1ec5n Thi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28000",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "28001",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28002",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28003",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28004",
            name: "Nguy\u1ec5n Trung Ng\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28005",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28006",
            name: "Nguy\u1ec5n V\u0103n Hoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28007",
            name: "Nguy\u1ec5n V\u0103n Huy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28008",
            name: "Nguy\u1ec5n V\u0103n Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28009",
            name: "Nguy\u1ec5n V\u0103n T\u1ed1",
            prefix: "Ph\u1ed1",
          },
          {
            id: "28010",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28011",
            name: "Nguy\u1ec5n V\u0103n V\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28012",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28013",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28014",
            name: "Ph\u1ea1m Ng\u1ecdc H\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28015",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28016",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28017",
            name: "Ph\u1ea1m Th\u1ecb V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28018",
            name: "Ph\u1ea1m Tu\u1ea5n T\u00e0i",
            prefix: "Ph\u1ed1",
          },
          {
            id: "28019",
            name: "Ph\u1ea1m V\u0103n Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28020",
            name: "Ph\u1ea1m V\u0103n X\u00f4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28021", name: "Phan Anh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28022",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28023",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28024",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28025",
            name: "Phan Th\u1ebf Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28026",
            name: "Ph\u00f3 \u0110\u1ee9c Ch\u00ednh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "28027",
            name: "Ph\u1ed1 M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28028",
            name: "Phong L\u1ed9c T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28029",
            name: "Ph\u00f9 Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28030",
            name: "Ph\u00f9 Long A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28031",
            name: "Ph\u00f9 Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28032",
            name: "Ph\u00f9ng Ch\u00ed Ki\u00ean",
            prefix: "Ph\u1ed1",
          },
          {
            id: "28033",
            name: "Ph\u1ee5ng D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28034",
            name: "Ph\u00f9ng Kh\u1eafc Khoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28035", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28036",
            name: "Qu\u1ed1c L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28037",
            name: "Qu\u1ed1c l\u1ed9 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28038",
            name: "Qu\u1ed1c Ph\u00f2ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28039", name: "S2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28040", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28041", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28042",
            name: "Song H\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28043",
            name: "T\u00e2n Ch\u00e1nh Hi\u1ec7p 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28044",
            name: "Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28045",
            name: "Thanh B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28046",
            name: "Th\u00e0nh Chung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28047",
            name: "Th\u1ea1nh L\u1ed9c 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28048",
            name: "Th\u00e0nh Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28049",
            name: "Th\u00e0nh Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28050",
            name: "Th\u00edch Th\u1ebf Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28051",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28052",
            name: "Ti\u1ec1n Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28053",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28054",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28055",
            name: "T\u00f4 Ng\u1ecdc V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28056",
            name: "T\u1ed1ng V\u0103n Tr\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28057",
            name: "Tr\u1ea1i G\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28058",
            name: "Tr\u1ea7m C\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28059",
            name: "Tr\u1ea7m C\u00e1 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28060",
            name: "Tr\u1ea7n Anh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28061",
            name: "Tr\u1ea7n B\u00edch San",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28062",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28067",
            name: "Tr\u1ea7n Du\u1ec7 T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28068",
            name: "Tr\u1ea7n Duy H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28063",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "28064",
            name: "Tr\u1ea7n \u0110\u0103ng Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28065",
            name: "Tr\u1ea7n \u0110\u00ecnh Huy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28066",
            name: "Tr\u1ea7n \u0110\u00ecnh Th\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28069",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28070",
            name: "Tr\u1ea7n H\u1eefu T\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28071",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28072",
            name: "Tr\u1ea7n Kh\u1eafc Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28073",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28074",
            name: "Tr\u1ea7n K\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28075",
            name: "Tr\u1ea7n Ngh\u1ec7 T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28076",
            name: "Tr\u1ea7n Nguy\u00ean \u0110\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28077",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28078",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28079",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28080",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28081",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28082",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28083",
            name: "Tr\u1ea7n T\u1ebf X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28084",
            name: "Tr\u1ea7n Th\u00e1i T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28085",
            name: "Tr\u1ea7n Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28086",
            name: "Tr\u1ea7n Th\u1eeba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28087",
            name: "Tr\u1ea7n T\u1ef1 Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28088",
            name: "Tr\u1ea7n Tu\u1ea5n Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28089",
            name: "Tr\u1ea7n Tung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28090",
            name: "Tr\u1ea7n V\u0103n B\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28091",
            name: "Tr\u1ea7n V\u0103n Ch\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28092",
            name: "Tr\u1ea7n V\u0103n \u01a0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28093",
            name: "Tr\u1ea7n V\u0103n Tr\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28094", name: "Tr\u00e0ng Thi", prefix: "Ph\u1ed1" },
          {
            id: "28095",
            name: "Tr\u1ecbnh Ho\u00e0i \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28096",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28097",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28098",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28099",
            name: "T\u1ee9c M\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28100",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28101", name: "V\u0103n Cao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28102",
            name: "V\u1ecb Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28103",
            name: "V\u1ecb xuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28104",
            name: "Vi\u0303nh Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28105",
            name: "V\u0129nh M\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28106",
            name: "V\u0129nh Tr\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28107",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28108",
            name: "V\u0169 C\u00f4ng T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28109",
            name: "V\u0169 \u0110\u00ecnh Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28110",
            name: "V\u0169 \u0110\u00ecnh T\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28111",
            name: "V\u0169 H\u1eefu L\u1ee3i",
            prefix: "Ph\u1ed1",
          },
          {
            id: "28112",
            name: "V\u0169 Kh\u1ebf B\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28113", name: "V\u0169 Ng\u1ecdc Phan", prefix: "Ph\u1ed1" },
          {
            id: "28114",
            name: "V\u0169 Tr\u1ecdng Ph\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28115",
            name: "V\u0169 Tu\u1ea5n Chi\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28116",
            name: "V\u0169 V\u0103n V\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28117",
            name: "Xu\u00e2n H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28118",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3010",
            name: "H\u00f2a V\u01b0\u1ee3ng",
            lat: "20.438301086426",
            lng: "106.15951538086",
          },
          {
            id: "3011",
            name: "K\u0110T Th\u1ed1ng Nh\u1ea5t",
            lat: "20.440958023071",
            lng: "106.15701293945",
          },
          {
            id: "3012",
            name: "Khu \u0111\u00f4 th\u1ecb D\u1ec7t May",
            lat: "20.422769546509",
            lng: "106.17287445068",
          },
          {
            id: "3013",
            name: "Nam \u0110\u1ecbnh Tower",
            lat: "20.429702758789",
            lng: "106.16554260254",
          },
        ],
      },
      {
        id: "439",
        name: "Nam Tr\u1ef1c",
        wards: [
          { id: "7097", name: "B\u00ecnh Minh", prefix: "X\u00e3" },
          { id: "7098", name: "\u0110i\u1ec1n X\u00e1", prefix: "X\u00e3" },
          { id: "7099", name: "\u0110\u1ed3ng S\u01a1n", prefix: "X\u00e3" },
          { id: "7100", name: "H\u1ed3ng Quang", prefix: "X\u00e3" },
          { id: "7101", name: "Nam C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "7102", name: "Nam D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "7103", name: "Nam Giang", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "7104", name: "Nam H\u1ea3i", prefix: "X\u00e3" },
          { id: "7105", name: "Nam Hoa", prefix: "X\u00e3" },
          { id: "7106", name: "Nam H\u1ed3ng", prefix: "X\u00e3" },
          { id: "7107", name: "Nam H\u00f9ng", prefix: "X\u00e3" },
          { id: "7108", name: "Nam L\u1ee3i", prefix: "X\u00e3" },
          { id: "7109", name: "Nam M\u1ef9", prefix: "X\u00e3" },
          { id: "7110", name: "Nam Th\u00e1i", prefix: "X\u00e3" },
          { id: "7111", name: "Nam Th\u1eafng", prefix: "X\u00e3" },
          { id: "7112", name: "Nam Thanh", prefix: "X\u00e3" },
          { id: "7113", name: "Nam Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "7114", name: "Nam To\u00e0n", prefix: "X\u00e3" },
          { id: "7115", name: "Ngh\u0129a An", prefix: "X\u00e3" },
          { id: "7116", name: "T\u00e2n Th\u1ecbnh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28119", name: "52", prefix: "Ph\u1ed1" },
          {
            id: "28120",
            name: "Qu\u1ed1c l\u1ed9 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28121",
            name: "Qu\u1ed1c l\u1ed9 21B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28122",
            name: "Qu\u1ed1c L\u1ed9 55",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28123",
            name: "T\u1ec9nh l\u1ed9 490",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28124", name: "V\u00e0ng", prefix: "Ph\u1ed1" },
        ],
        projects: [],
      },
      {
        id: "440",
        name: "Ngh\u0129a H\u01b0ng",
        wards: [
          { id: "7117", name: "Ho\u00e0ng Nam", prefix: "X\u00e3" },
          {
            id: "7118",
            name: "Li\u1ec5u \u0110\u1ec1",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7119", name: "Nam \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "7120", name: "Ngh\u0129a B\u00ecnh", prefix: "X\u00e3" },
          { id: "7121", name: "Ngh\u0129a Ch\u00e2u", prefix: "X\u00e3" },
          { id: "7122", name: "Ngh\u0129a \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "7123", name: "Ngh\u0129a H\u1ea3i", prefix: "X\u00e3" },
          { id: "7124", name: "Ngh\u0129a H\u1ed3ng", prefix: "X\u00e3" },
          { id: "7125", name: "Ngh\u0129a H\u00f9ng", prefix: "X\u00e3" },
          { id: "7126", name: "Ngh\u0129a L\u1ea1c", prefix: "X\u00e3" },
          { id: "7127", name: "Ngh\u0129a L\u00e2m", prefix: "X\u00e3" },
          { id: "7128", name: "Ngh\u0129a L\u1ee3i", prefix: "X\u00e3" },
          { id: "7129", name: "Ngh\u0129a Minh", prefix: "X\u00e3" },
          { id: "7130", name: "Ngh\u0129a Phong", prefix: "X\u00e3" },
          { id: "7131", name: "Ngh\u0129a Ph\u00fa", prefix: "X\u00e3" },
          { id: "7132", name: "Ngh\u0129a Ph\u00fac", prefix: "X\u00e3" },
          { id: "7133", name: "Ngh\u0129a S\u01a1n", prefix: "X\u00e3" },
          { id: "7134", name: "Ngh\u0129a T\u00e2n", prefix: "X\u00e3" },
          { id: "7135", name: "Ngh\u0129a Th\u00e1i", prefix: "X\u00e3" },
          { id: "7136", name: "Ngh\u0129a Th\u1eafng", prefix: "X\u00e3" },
          { id: "7137", name: "Ngh\u0129a Th\u00e0nh", prefix: "X\u00e3" },
          { id: "7138", name: "Ngh\u0129a Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "7139", name: "Ngh\u0129a Trung", prefix: "X\u00e3" },
          {
            id: "7140",
            name: "Qu\u1ef9 Nh\u1ea5t",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "7141",
            name: "R\u1ea1ng \u0110\u00f4ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          {
            id: "28125",
            name: "T\u1ec9nh l\u1ed9 490",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28126",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "441",
        name: "Tr\u1ef1c Ninh",
        wards: [
          {
            id: "7142",
            name: "C\u00e1t Th\u00e0nh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7143", name: "C\u1ed5 L\u1ec5", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "7144", name: "Li\u00eam H\u1ea3i", prefix: "X\u00e3" },
          {
            id: "7145",
            name: "Ph\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "X\u00e3",
          },
          { id: "7146", name: "Tr\u1ef1c Ch\u00ednh", prefix: "X\u00e3" },
          { id: "7147", name: "Tr\u1ef1c C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "7148", name: "Tr\u1ef1c \u0110\u1ea1i", prefix: "X\u00e3" },
          { id: "7149", name: "Tr\u1ef1c \u0110\u1ea1o", prefix: "X\u00e3" },
          { id: "7150", name: "Tr\u1ef1c H\u00f9ng", prefix: "X\u00e3" },
          { id: "7151", name: "Tr\u1ef1c H\u01b0ng", prefix: "X\u00e3" },
          { id: "7152", name: "Tr\u1ef1c Khang", prefix: "X\u00e3" },
          { id: "7153", name: "Tr\u1ef1c M\u1ef9", prefix: "X\u00e3" },
          { id: "7154", name: "Tr\u1ef1c N\u1ed9i", prefix: "X\u00e3" },
          { id: "7155", name: "Tr\u1ef1c Ph\u00fa", prefix: "X\u00e3" },
          { id: "7156", name: "Tr\u1ef1c Th\u00e1i", prefix: "X\u00e3" },
          { id: "7157", name: "Tr\u1ef1c Th\u1eafng", prefix: "X\u00e3" },
          { id: "7158", name: "Tr\u1ef1c Thanh", prefix: "X\u00e3" },
          { id: "7159", name: "Tr\u1ef1c Thu\u1eadn", prefix: "X\u00e3" },
          { id: "7160", name: "Tr\u1ef1c Tu\u1ea5n", prefix: "X\u00e3" },
          { id: "7161", name: "Trung \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "7162", name: "Vi\u1ec7t H\u00f9ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28127", name: "21B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28128",
            name: "H\u1eefu Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28129",
            name: "Qu\u1ed1c l\u1ed9 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28130",
            name: "T\u1ec9nh l\u1ed9 51",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "442",
        name: "V\u1ee5 B\u1ea3n",
        wards: [
          { id: "7163", name: "C\u1ed9ng H\u00f2a", prefix: "X\u00e3" },
          { id: "7164", name: "\u0110\u1ea1i An", prefix: "X\u00e3" },
          { id: "7165", name: "\u0110\u1ea1i Th\u1eafng", prefix: "X\u00e3" },
          { id: "7166", name: "G\u00f4i", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "7167", name: "Hi\u1ec3n Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "7168", name: "H\u1ee3p H\u01b0ng", prefix: "X\u00e3" },
          { id: "7169", name: "Kim Th\u00e1i", prefix: "X\u00e3" },
          { id: "7170", name: "Li\u00ean B\u1ea3o", prefix: "X\u00e3" },
          { id: "7171", name: "Li\u00ean Minh", prefix: "X\u00e3" },
          { id: "7172", name: "Minh T\u00e2n", prefix: "X\u00e3" },
          { id: "7173", name: "Minh Thu\u1eadn", prefix: "X\u00e3" },
          { id: "7174", name: "Quang Trung", prefix: "X\u00e3" },
          { id: "7175", name: "Tam Thanh", prefix: "X\u00e3" },
          { id: "7176", name: "T\u00e2n Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "7177", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "7178", name: "Th\u00e0nh L\u1ee3i", prefix: "X\u00e3" },
          { id: "7179", name: "Trung Th\u00e0nh", prefix: "X\u00e3" },
          { id: "7180", name: "V\u0129nh H\u00e0o", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28131", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28132", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28133", name: "56B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28134", name: "\u0110T 56", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28135",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28136",
            name: "Qu\u1ed1c L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28137",
            name: "Qu\u1ed1c l\u1ed9 38B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28138",
            name: "T\u00e2y S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "443",
        name: "Xu\u00e2n Tr\u01b0\u1eddng",
        wards: [
          { id: "7181", name: "Th\u1ecd Nghi\u1ec7p", prefix: "X\u00e3" },
          { id: "7182", name: "Xu\u00e2n B\u1eafc", prefix: "X\u00e3" },
          { id: "7183", name: "Xu\u00e2n Ch\u00e2u", prefix: "X\u00e3" },
          { id: "7184", name: "Xu\u00e2n \u0110\u00e0i", prefix: "X\u00e3" },
          { id: "7185", name: "Xu\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "7186", name: "Xu\u00e2n H\u1ed3ng", prefix: "X\u00e3" },
          { id: "7187", name: "Xu\u00e2n Ki\u00ean", prefix: "X\u00e3" },
          { id: "7188", name: "Xu\u00e2n Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "7189", name: "Xu\u00e2n Ninh", prefix: "X\u00e3" },
          { id: "7190", name: "Xu\u00e2n Phong", prefix: "X\u00e3" },
          { id: "7191", name: "Xu\u00e2n Ph\u00fa", prefix: "X\u00e3" },
          { id: "7192", name: "Xu\u00e2n Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "7193", name: "Xu\u00e2n T\u00e2n", prefix: "X\u00e3" },
          { id: "7194", name: "Xu\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "7195", name: "Xu\u00e2n Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "7196", name: "Xu\u00e2n Th\u1ee7y", prefix: "X\u00e3" },
          { id: "7197", name: "Xu\u00e2n Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "7198", name: "Xu\u00e2n Trung", prefix: "X\u00e3" },
          {
            id: "7199",
            name: "Xu\u00e2n Tr\u01b0\u1eddng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7200", name: "Xu\u00e2n Vinh", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "28139",
            name: "Qu\u1ed1c l\u1ed9 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28140",
            name: "T\u1ec9nh l\u1ed9 489",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "444",
        name: "\u00dd Y\u00ean",
        wards: [
          { id: "7201", name: "L\u00e2m", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "7202", name: "Y\u00ean B\u1eb1ng", prefix: "X\u00e3" },
          { id: "7203", name: "Y\u00ean B\u00ecnh", prefix: "X\u00e3" },
          { id: "7204", name: "Y\u00ean Ch\u00ednh", prefix: "X\u00e3" },
          { id: "7205", name: "Y\u00ean C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "7207", name: "Y\u00ean D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "7206", name: "Y\u00ean \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "7208", name: "Y\u00ean H\u1ed3ng", prefix: "X\u00e3" },
          { id: "7209", name: "Y\u00ean H\u01b0ng", prefix: "X\u00e3" },
          { id: "7210", name: "Y\u00ean Khang", prefix: "X\u00e3" },
          { id: "7211", name: "Y\u00ean Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "7212", name: "Y\u00ean L\u1ed9c", prefix: "X\u00e3" },
          { id: "7213", name: "Y\u00ean L\u1ee3i", prefix: "X\u00e3" },
          { id: "7214", name: "Y\u00ean L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "7215", name: "Y\u00ean Minh", prefix: "X\u00e3" },
          { id: "7216", name: "Y\u00ean M\u1ef9", prefix: "X\u00e3" },
          { id: "7217", name: "Y\u00ean Ngh\u0129a", prefix: "X\u00e3" },
          { id: "7218", name: "Y\u00ean Nh\u00e2n", prefix: "X\u00e3" },
          { id: "7219", name: "Y\u00ean Ninh", prefix: "X\u00e3" },
          { id: "7220", name: "Y\u00ean Phong", prefix: "X\u00e3" },
          { id: "7221", name: "Y\u00ean Ph\u00fa", prefix: "X\u00e3" },
          { id: "7222", name: "Y\u00ean Ph\u00fac", prefix: "X\u00e3" },
          { id: "7223", name: "Y\u00ean Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "7224", name: "Y\u00ean Quang", prefix: "X\u00e3" },
          { id: "7225", name: "Y\u00ean T\u00e2n", prefix: "X\u00e3" },
          { id: "7226", name: "Y\u00ean Th\u1eafng", prefix: "X\u00e3" },
          { id: "7227", name: "Y\u00ean Th\u00e0nh", prefix: "X\u00e3" },
          { id: "7228", name: "Y\u00ean Th\u1ecd", prefix: "X\u00e3" },
          { id: "7229", name: "Y\u00ean Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "7230", name: "Y\u00ean Tr\u1ecb", prefix: "X\u00e3" },
          { id: "7231", name: "Y\u00ean Trung", prefix: "X\u00e3" },
          { id: "7232", name: "Y\u00ean X\u00e1", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28141", name: "485", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28142", name: "56", prefix: "Ph\u1ed1" },
          {
            id: "28143",
            name: "Qu\u1ed1c L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "36",
    code: "QNG",
    name: "Qu\u1ea3ng Ng\u00e3i",
    districts: [
      {
        id: "445",
        name: "Ba T\u01a1",
        wards: [
          { id: "7233", name: "Ba B\u00edch", prefix: "X\u00e3" },
          { id: "7234", name: "Ba Ch\u00f9a", prefix: "X\u00e3" },
          { id: "7235", name: "Ba Cung", prefix: "X\u00e3" },
          { id: "7237", name: "Ba Dinh", prefix: "X\u00e3" },
          { id: "7236", name: "Ba \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "7238", name: "Ba \u0110\u1ed9ng", prefix: "X\u00e3" },
          { id: "7239", name: "Ba Giang", prefix: "X\u00e3" },
          { id: "7240", name: "Ba Kh\u00e2m", prefix: "X\u00e3" },
          { id: "7241", name: "Ba L\u1ebf", prefix: "X\u00e3" },
          { id: "7242", name: "Ba Li\u00ean", prefix: "X\u00e3" },
          { id: "7243", name: "Ba Nam", prefix: "X\u00e3" },
          { id: "7244", name: "Ba Ng\u1ea1c", prefix: "X\u00e3" },
          { id: "7245", name: "Ba Th\u00e0nh", prefix: "X\u00e3" },
          { id: "7246", name: "Ba Ti\u00eau", prefix: "X\u00e3" },
          { id: "7247", name: "Ba T\u00f4", prefix: "X\u00e3" },
          { id: "7248", name: "Ba T\u01a1", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "7249", name: "Ba Trang", prefix: "X\u00e3" },
          { id: "7250", name: "Ba V\u00ec", prefix: "X\u00e3" },
          { id: "7251", name: "Ba Vinh", prefix: "X\u00e3" },
          { id: "7252", name: "Ba Xa", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "446",
        name: "B\u00ecnh S\u01a1n",
        wards: [
          { id: "7253", name: "B\u00ecnh An", prefix: "X\u00e3" },
          { id: "7254", name: "B\u00ecnh Ch\u00e1nh", prefix: "X\u00e3" },
          { id: "7255", name: "B\u00ecnh Ch\u00e2u", prefix: "X\u00e3" },
          { id: "7256", name: "B\u00ecnh Ch\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "7258", name: "B\u00ecnh D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "7257", name: "B\u00ecnh \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "7259", name: "B\u00ecnh H\u1ea3i", prefix: "X\u00e3" },
          { id: "7260", name: "B\u00ecnh Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "7261", name: "B\u00ecnh H\u00f2a", prefix: "X\u00e3" },
          { id: "7262", name: "B\u00ecnh Kh\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "7263", name: "B\u00ecnh Long", prefix: "X\u00e3" },
          { id: "7264", name: "B\u00ecnh Minh", prefix: "X\u00e3" },
          { id: "7265", name: "B\u00ecnh M\u1ef9", prefix: "X\u00e3" },
          { id: "7266", name: "B\u00ecnh Nguy\u00ean", prefix: "X\u00e3" },
          { id: "7267", name: "B\u00ecnh Ph\u00fa", prefix: "X\u00e3" },
          { id: "7268", name: "B\u00ecnh Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "7269", name: "B\u00ecnh T\u00e2n", prefix: "X\u00e3" },
          { id: "7270", name: "B\u00ecnh Th\u1ea1nh", prefix: "X\u00e3" },
          {
            id: "7271",
            name: "B\u00ecnh Thanh \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "7272", name: "B\u00ecnh Thanh T\u00e2y", prefix: "X\u00e3" },
          { id: "7273", name: "B\u00ecnh Th\u1edbi", prefix: "X\u00e3" },
          { id: "7274", name: "B\u00ecnh Thu\u1eadn", prefix: "X\u00e3" },
          { id: "7275", name: "B\u00ecnh Tr\u1ecb", prefix: "X\u00e3" },
          { id: "7276", name: "B\u00ecnh Trung", prefix: "X\u00e3" },
          {
            id: "7277",
            name: "Ch\u00e2u \u1ed4",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          {
            id: "28144",
            name: "D\u1ed1c S\u1ecfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28145",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28146",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28147", name: "T\u1ebf Hanh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28148",
            name: "T\u1ec9nh l\u1ed9 621",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28149",
            name: "T\u1ec9nh L\u1ed9 624",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28150",
            name: "Tr\u1ea7n K\u1ef3 Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28151",
            name: "V\u00f5 Th\u1ecb \u0110\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3014",
            name: "KDC \u0110\u00f4ng Nam",
            lat: "15.300809860229",
            lng: "108.75873565674",
          },
        ],
      },
      {
        id: "447",
        name: "\u0110\u1ee9c Ph\u1ed5",
        wards: [
          {
            id: "7278",
            name: "\u0110\u1ee9c Ph\u1ed5",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7279", name: "Ph\u1ed5 An", prefix: "X\u00e3" },
          { id: "7280", name: "Ph\u1ed5 Ch\u00e2u", prefix: "X\u00e3" },
          { id: "7281", name: "Ph\u1ed5 C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "7282", name: "Ph\u1ed5 H\u00f2a", prefix: "X\u00e3" },
          { id: "7283", name: "Ph\u1ed5 Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "7284", name: "Ph\u1ed5 Minh", prefix: "X\u00e3" },
          { id: "7285", name: "Ph\u1ed5 Nh\u01a1n", prefix: "X\u00e3" },
          { id: "7286", name: "Ph\u1ed5 Ninh", prefix: "X\u00e3" },
          { id: "7287", name: "Ph\u1ed5 Phong", prefix: "X\u00e3" },
          { id: "7288", name: "Ph\u1ed5 Quang", prefix: "X\u00e3" },
          { id: "7289", name: "Ph\u1ed5 Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "7290", name: "Ph\u1ed5 Thu\u1eadn", prefix: "X\u00e3" },
          { id: "7291", name: "Ph\u1ed5 V\u0103n", prefix: "X\u00e3" },
          { id: "7292", name: "Ph\u1ed5 Vinh", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "28152",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28153",
            name: "\u0110o\u00e0n Nh\u1eadt Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28154",
            name: "Hu\u1ef3nh C\u00f4ng Thi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28155",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28156",
            name: "Qu\u1ed1c L\u1ed9 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28157",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "448",
        name: "L\u00fd S\u01a1n",
        wards: [
          { id: "7293", name: "An B\u00ecnh", prefix: "X\u00e3" },
          { id: "7294", name: "An H\u1ea3i", prefix: "X\u00e3" },
          { id: "7295", name: "An V\u0129nh", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "449",
        name: "Minh Long",
        wards: [
          { id: "7296", name: "Long Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "7297", name: "Long Mai", prefix: "X\u00e3" },
          { id: "7298", name: "Long M\u00f4n", prefix: "X\u00e3" },
          { id: "7299", name: "Long S\u01a1n", prefix: "X\u00e3" },
          { id: "7300", name: "Thanh An", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "450",
        name: "M\u1ed9 \u0110\u1ee9c",
        wards: [
          { id: "7301", name: "\u0110\u1ee9c Ch\u00e1nh", prefix: "X\u00e3" },
          { id: "7302", name: "\u0110\u1ee9c Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "7303", name: "\u0110\u1ee9c H\u00f2a", prefix: "X\u00e3" },
          { id: "7304", name: "\u0110\u1ee9c L\u00e2n", prefix: "X\u00e3" },
          { id: "7305", name: "\u0110\u1ee9c L\u1ee3i", prefix: "X\u00e3" },
          { id: "7306", name: "\u0110\u1ee9c Minh", prefix: "X\u00e3" },
          { id: "7307", name: "\u0110\u1ee9c Nhu\u1eadn", prefix: "X\u00e3" },
          { id: "7308", name: "\u0110\u1ee9c Phong", prefix: "X\u00e3" },
          { id: "7309", name: "\u0110\u1ee9c Ph\u00fa", prefix: "X\u00e3" },
          { id: "7310", name: "\u0110\u1ee9c T\u00e2n", prefix: "X\u00e3" },
          { id: "7311", name: "\u0110\u1ee9c Th\u1eafng", prefix: "X\u00e3" },
          { id: "7312", name: "\u0110\u1ee9c Th\u1ea1nh", prefix: "X\u00e3" },
          {
            id: "7313",
            name: "M\u1ed9 \u0110\u1ee9c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          {
            id: "28158",
            name: "M\u1ef9 \u00c1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28159",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "451",
        name: "Ngh\u0129a H\u00e0nh",
        wards: [
          {
            id: "7314",
            name: "Ch\u1ee3 Ch\u00f9a",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7316", name: "H\u00e0nh D\u0169ng", prefix: "X\u00e3" },
          { id: "7315", name: "H\u00e0nh \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "7317", name: "H\u00e0nh Minh", prefix: "X\u00e3" },
          { id: "7318", name: "H\u00e0nh Nh\u00e2n", prefix: "X\u00e3" },
          { id: "7319", name: "H\u00e0nh Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "7320", name: "H\u00e0nh Thi\u1ec7n", prefix: "X\u00e3" },
          { id: "7321", name: "H\u00e0nh Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "7322", name: "H\u00e0nh Thu\u1eadn", prefix: "X\u00e3" },
          {
            id: "7323",
            name: "H\u00e0nh T\u00edn \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "7324",
            name: "H\u00e0nh T\u00edn T\u00e2y",
            prefix: "X\u00e3",
          },
          { id: "7325", name: "H\u00e0nh Trung", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "452",
        name: "Qu\u1ea3ng Ng\u00e3i",
        wards: [
          {
            id: "7326",
            name: "Ch\u00e1nh L\u1ed9",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "7327",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "7328",
            name: "Ngh\u0129a Ch\u00e1nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "7329", name: "Ngh\u0129a D\u00f5ng", prefix: "X\u00e3" },
          { id: "7330", name: "Ngh\u0129a D\u0169ng", prefix: "X\u00e3" },
          { id: "7331", name: "Ngh\u0129a H\u00e0", prefix: "X\u00e3" },
          {
            id: "7332",
            name: "Ngh\u0129a L\u1ed9",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "7333", name: "Ngh\u0129a Ph\u00fa", prefix: "X\u00e3" },
          {
            id: "7334",
            name: "Nguy\u1ec5n Nghi\u00eam",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "7335",
            name: "Qu\u1ea3ng Ph\u00fa",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "7336", name: "T\u1ecbnh \u1ea4n T\u00e2y", prefix: "X\u00e3" },
          {
            id: "7337",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "7338",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "7339",
            name: "Tr\u01b0\u01a1ng Quang Tr\u1ecdng",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "28160", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28161",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28162", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "28163",
            name: "B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28164",
            name: "B\u00edch Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28165",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28166",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28167",
            name: "C\u1ea9m Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28168",
            name: "Chu Huy M\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28169",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28180", name: "Duy T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28170",
            name: "\u0110\u1ea1i L\u1ed9 H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28171",
            name: "\u0110\u1eb7ng Th\u00f9y Tr\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28172",
            name: "\u0110\u1eb7ng V\u0103n Ng\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28173",
            name: "\u0110inh Duy T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28174",
            name: "\u0110inh Nha",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28175",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28176",
            name: "\u0110inh Tri\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28177",
            name: "\u0110o\u00e0n Kh\u1eafc Nh\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28178",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28179",
            name: "\u0110\u01b0\u1eddng Ng\u1ecdc C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28181",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28182", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "28183",
            name: "H\u1ed3 \u0110\u1eafc Di",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28184",
            name: "H\u1ed3 Qu\u00fd Ly",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28185",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28186",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28187",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28188",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28189",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28190",
            name: "Huy\u1ec1n Tr\u00e2n C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28191",
            name: "Hu\u1ef3nh C\u00f4ng Thi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28192",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28193",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28194",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28195",
            name: "L\u00ea \u0110\u00ecnh C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28196",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28197",
            name: "L\u00ea H\u1eefu Tr\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28198",
            name: "L\u00ea Khi\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28199",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28200",
            name: "L\u00ea Ngung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28201",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28202",
            name: "L\u00ea Th\u00e1nh T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28203",
            name: "L\u00ea Trung \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28204",
            name: "L\u00ea V\u0103n S\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28205",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28206",
            name: "L\u00fd Chi\u00eau Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28207",
            name: "L\u00fd Ch\u00ednh Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28208",
            name: "L\u00fd \u0110\u1ea1o Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28209",
            name: "L\u00fd Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28210",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "28211",
            name: "M\u1ea1c \u0110\u0103ng Dung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28212",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28213",
            name: "Mai \u0110\u00ecnh D\u00f5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28214",
            name: "Mai Xu\u00e2n Th\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28215",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28216",
            name: "Ng\u00f4 S\u1ef9 Li\u00ean",
            prefix: "Ph\u1ed1",
          },
          {
            id: "28217",
            name: "Nguy\u1ec5n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28218",
            name: "Nguy\u1ec5n B\u00e1 Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28219",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28220",
            name: "Nguy\u1ec5n Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28221",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28222",
            name: "Nguy\u1ec5n C\u00f4ng Hoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28223",
            name: "Nguy\u1ec5n C\u00f4ng Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28224",
            name: "Nguy\u1ec5n C\u01b0 Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28226",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28225",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28227",
            name: "Nguy\u1ec5n Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28228",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28229",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28230",
            name: "Nguy\u1ec5n N\u0103ng L\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28231",
            name: "Nguy\u1ec5n Nghi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28232",
            name: "Nguy\u1ec5n T\u1ea5n K\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28233",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28234",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28235",
            name: "Nguy\u1ec5n Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28236",
            name: "Nguy\u1ec5n Th\u1ee5y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28237",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28238",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28239",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28240",
            name: "Nguy\u1ec5n T\u1ef1 T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28241",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28242",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28243",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28244",
            name: "Nguy\u1ec5n V\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28245",
            name: "Ph\u1ea1m \u0110\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28246",
            name: "Ph\u1ea1m H\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28247",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28248",
            name: "Ph\u1ea1m Quang \u1ea2nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28249",
            name: "Ph\u1ea1m Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28250",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28251",
            name: "Ph\u1ea1m Xu\u00e2n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28252",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28253",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28254",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "28255",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28256",
            name: "Phan Huy \u00cdch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28257",
            name: "Phan Long B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28258",
            name: "Phan Th\u00e1i \u1ea4t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28259",
            name: "Phan V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28260", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28261",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28262",
            name: "Qu\u1ed1c L\u1ed9 24A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28263",
            name: "Qu\u1ed1c L\u1ed9 24B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28264", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28265", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28266", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28267", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28268", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28269", name: "T\u1ebf Hanh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28270",
            name: "Th\u00e1i Th\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28271",
            name: "Th\u00e0nh Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28272",
            name: "Thi\u00ean H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28273",
            name: "Thi\u00ean L\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28274",
            name: "Thi\u00ean M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28275",
            name: "T\u1ec9nh l\u1ed9 623",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28276",
            name: "T\u1ec9nh l\u1ed9 623C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28277",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28278",
            name: "T\u1ed1 H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28279",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "28280",
            name: "T\u1ed1ng V\u0103n Tr\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28281",
            name: "Tr\u00e0 B\u1ed3ng Kh\u1edfi Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28282",
            name: "Tr\u1ea7n Anh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28283",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28284",
            name: "Tr\u1ea7n C\u1ea7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28285",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28286",
            name: "Tr\u1ea7n C\u00f4ng Hi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28287",
            name: "Tr\u1ea7n D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28288",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28289",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28290",
            name: "Tr\u1ea7n Ki\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28291",
            name: "Tr\u1ea7n K\u1ef3 Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28292",
            name: "Tr\u1ea7n Nam Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28293",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28294",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28295",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28296",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28297",
            name: "Tr\u1ea7n T\u1ebf X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28298",
            name: "Tr\u1ea7n Th\u00e1i T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28299",
            name: "Tr\u1ea7n Th\u1ecb Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28300",
            name: "Tr\u1ea7n Th\u1ee7 \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28301",
            name: "Tri\u1ec7u Quang Ph\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28302",
            name: "Tr\u1ecbnh C\u00f4ng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28303",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28304",
            name: "Tr\u01b0\u01a1ng \u0110\u0103ng Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28305",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28306",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28307",
            name: "Tr\u01b0\u01a1ng Quang C\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28308",
            name: "Tr\u01b0\u01a1ng Quang Giao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28309",
            name: "Tr\u01b0\u01a1ng Quang Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28310",
            name: "Tr\u01b0\u01a1ng V\u0129nh K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28311",
            name: "Tr\u01b0\u1eddng Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28312",
            name: "V\u00e2n \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28313",
            name: "V\u1ea1n T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28314",
            name: "V\u00f5 H\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28315",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28316",
            name: "V\u00f5 Qu\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28317",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28318",
            name: "V\u00f5 T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28319",
            name: "Xu\u00e2n Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28320",
            name: "Xu\u00e2n Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3015",
            name: "An Ph\u00fa Sinh",
            lat: "15.118951797485",
            lng: "108.8205871582",
          },
          {
            id: "3016",
            name: "KDC Phan \u0110\u00ecnh Ph\u00f9ng-Nam L\u00ea L\u1ee3i",
            lat: "15.101881980896",
            lng: "108.80637359619",
          },
          {
            id: "3017",
            name: "K\u0110T Ngo\u0323c Ba\u0309o Vi\u00ean",
            lat: "15.113254547119",
            lng: "108.80112457275",
          },
          {
            id: "3018",
            name: "K\u0110T Thi\u00ean M\u1ef9 L\u1ed9c",
            lat: "15.15065574646",
            lng: "108.79703521729",
          },
          {
            id: "3019",
            name: "Khu d\u00e2n c\u01b0 B\u1eafc L\u00ea L\u1ee3i",
            lat: "15.114519119263",
            lng: "108.79066467285",
          },
          {
            id: "3020",
            name: "Ph\u00fa M\u1ef9 Qu\u1ea3ng Ng\u00e3i",
            lat: "15.119679450989",
            lng: "108.81003570557",
          },
        ],
      },
      {
        id: "453",
        name: "S\u01a1n H\u00e0",
        wards: [
          { id: "7340", name: "Di L\u0103ng", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "7341", name: "S\u01a1n Ba", prefix: "X\u00e3" },
          { id: "7342", name: "S\u01a1n Bao", prefix: "X\u00e3" },
          { id: "7343", name: "S\u01a1n Cao", prefix: "X\u00e3" },
          { id: "7344", name: "S\u01a1n Giang", prefix: "X\u00e3" },
          { id: "7345", name: "S\u01a1n H\u1ea1", prefix: "X\u00e3" },
          { id: "7346", name: "S\u01a1n H\u1ea3i", prefix: "X\u00e3" },
          { id: "7347", name: "S\u01a1n K\u1ef3", prefix: "X\u00e3" },
          { id: "7348", name: "S\u01a1n Linh", prefix: "X\u00e3" },
          { id: "7349", name: "S\u01a1n Nham", prefix: "X\u00e3" },
          { id: "7350", name: "S\u01a1n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "7351", name: "S\u01a1n Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "7352", name: "S\u01a1n Th\u1ee7y", prefix: "X\u00e3" },
          { id: "7353", name: "S\u01a1n Trung", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "454",
        name: "S\u01a1n T\u00e2y",
        wards: [
          { id: "7354", name: "S\u01a1n Bua", prefix: "X\u00e3" },
          { id: "7355", name: "S\u01a1n Dung", prefix: "X\u00e3" },
          { id: "7356", name: "S\u01a1n L\u1eadp", prefix: "X\u00e3" },
          { id: "7357", name: "S\u01a1n Li\u00ean", prefix: "X\u00e3" },
          { id: "7358", name: "S\u01a1n Long", prefix: "X\u00e3" },
          { id: "7359", name: "S\u01a1n M\u00e0u", prefix: "X\u00e3" },
          { id: "7360", name: "S\u01a1n M\u00f9a", prefix: "X\u00e3" },
          { id: "7361", name: "S\u01a1n T\u00e2n", prefix: "X\u00e3" },
          { id: "7362", name: "S\u01a1n Tinh", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "455",
        name: "S\u01a1n T\u1ecbnh",
        wards: [
          {
            id: "7363",
            name: "S\u01a1n T\u1ecbnh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7364", name: "T\u1ecbnh An", prefix: "X\u00e3" },
          {
            id: "7365",
            name: "T\u1ecbnh \u1ea4n \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "7366", name: "T\u1ecbnh \u1ea4n T\u00e2y", prefix: "X\u00e3" },
          { id: "7367", name: "T\u1ecbnh b\u1eafc", prefix: "X\u00e3" },
          { id: "7368", name: "T\u1ecbnh B\u00ecnh", prefix: "X\u00e3" },
          { id: "7369", name: "T\u1ecbnh Ch\u00e2u", prefix: "X\u00e3" },
          { id: "7370", name: "T\u1ecbnh \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "7371", name: "T\u1ecbnh Giang", prefix: "X\u00e3" },
          { id: "7372", name: "T\u1ecbnh H\u00e0", prefix: "X\u00e3" },
          { id: "7373", name: "T\u1ecbnh Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "7374", name: "T\u1ecbnh H\u00f2a", prefix: "X\u00e3" },
          { id: "7375", name: "T\u1ecbnh Kh\u00ea", prefix: "X\u00e3" },
          { id: "7376", name: "T\u1ecbnh K\u1ef3", prefix: "X\u00e3" },
          { id: "7377", name: "T\u1ecbnh Long", prefix: "X\u00e3" },
          { id: "7378", name: "T\u1ecbnh Minh", prefix: "X\u00e3" },
          { id: "7379", name: "T\u1ecbnh Phong", prefix: "X\u00e3" },
          { id: "7380", name: "T\u1ecbnh S\u01a1n", prefix: "X\u00e3" },
          { id: "7381", name: "T\u1ecbnh Thi\u1ec7n", prefix: "X\u00e3" },
          { id: "7382", name: "T\u1ecbnh Th\u1ecd", prefix: "X\u00e3" },
          { id: "7383", name: "T\u1ecbnh Tr\u00e0", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "28321",
            name: "B\u1edd B\u1eafc S\u00f4ng Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28322",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28323",
            name: "Qu\u1ed1c L\u1ed9 24B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28324",
            name: "T\u1ec9nh l\u1ed9 623",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3021",
            name: "S\u01a1n T\u1ecbnh",
            lat: "15.153493881226",
            lng: "108.8063659668",
          },
        ],
      },
      {
        id: "456",
        name: "T\u00e2y Tr\u00e0",
        wards: [
          { id: "7384", name: "Tr\u00e0 Kh\u00ea", prefix: "X\u00e3" },
          { id: "7385", name: "Tr\u00e0 L\u00e3nh", prefix: "X\u00e3" },
          { id: "7386", name: "Tr\u00e0 Nham", prefix: "X\u00e3" },
          { id: "7387", name: "Tr\u00e0 Phong", prefix: "X\u00e3" },
          { id: "7388", name: "Tr\u00e0 Qu\u00e2n", prefix: "X\u00e3" },
          { id: "7389", name: "Tr\u00e0 Thanh", prefix: "X\u00e3" },
          { id: "7390", name: "Tr\u00e0 Th\u1ecd", prefix: "X\u00e3" },
          { id: "7391", name: "Tr\u00e0 Trung", prefix: "X\u00e3" },
          { id: "7392", name: "Tr\u00e0 Xinh", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "457",
        name: "Tr\u00e0 B\u1ed3ng",
        wards: [
          { id: "7393", name: "Tr\u00e0 B\u00ecnh", prefix: "X\u00e3" },
          { id: "7394", name: "Tr\u00e0 B\u00f9i", prefix: "X\u00e3" },
          { id: "7395", name: "Tr\u00e0 Giang", prefix: "X\u00e3" },
          { id: "7396", name: "Tr\u00e0 Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "7397", name: "Tr\u00e0 L\u00e2m", prefix: "X\u00e3" },
          { id: "7398", name: "Tr\u00e0 Ph\u00fa", prefix: "X\u00e3" },
          { id: "7399", name: "Tr\u00e0 S\u01a1n", prefix: "X\u00e3" },
          { id: "7400", name: "Tr\u00e0 T\u00e2n", prefix: "X\u00e3" },
          { id: "7401", name: "Tr\u00e0 Th\u1ee7y", prefix: "X\u00e3" },
          {
            id: "7402",
            name: "Tr\u00e0 Xu\u00e2n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "458",
        name: "T\u01b0 Ngh\u0129a",
        wards: [
          { id: "7403", name: "La H\u00e0", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "7404", name: "Ngh\u0129a An", prefix: "X\u00e3" },
          { id: "7405", name: "Ngh\u0129a \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "7406", name: "Ngh\u0129a Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "7407", name: "Ngh\u0129a H\u00f2a", prefix: "X\u00e3" },
          { id: "7408", name: "Ngh\u0129a K\u1ef3", prefix: "X\u00e3" },
          { id: "7409", name: "Ngh\u0129a L\u00e2m", prefix: "X\u00e3" },
          { id: "7410", name: "Ngh\u0129a M\u1ef9", prefix: "X\u00e3" },
          { id: "7411", name: "Ngh\u0129a Ph\u00fa", prefix: "X\u00e3" },
          {
            id: "7412",
            name: "Ngh\u0129a Ph\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "7413", name: "Ngh\u0129a S\u01a1n", prefix: "X\u00e3" },
          { id: "7414", name: "Ngh\u0129a Th\u1eafng", prefix: "X\u00e3" },
          { id: "7415", name: "Ngh\u0129a Th\u1ecd", prefix: "X\u00e3" },
          { id: "7416", name: "Ngh\u0129a Thu\u1eadn", prefix: "X\u00e3" },
          {
            id: "7417",
            name: "Ngh\u0129a Th\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "7418", name: "Ngh\u0129a Trung", prefix: "X\u00e3" },
          {
            id: "7419",
            name: "S\u00f4ng V\u1ec7",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          {
            id: "28325",
            name: "Nguy\u1ec5n C\u00f4ng Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28326",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28327",
            name: "T\u1ec9nh l\u1ed9 623",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28328",
            name: "T\u1ec9nh l\u1ed9 623B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28329",
            name: "T\u1ec9nh l\u1ed9 623C",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3022",
            name: "K\u0110T Ph\u00fa An Khang",
            lat: "15.136629104614",
            lng: "108.88782501221",
          },
          {
            id: "3023",
            name: "Khu d\u00e2n c\u01b0 An L\u1ed9c Ph\u00e1t",
            lat: "15.113718032837",
            lng: "108.86109161377",
          },
        ],
      },
    ],
  },
  {
    id: "37",
    code: "BTR",
    name: "B\u1ebfn Tre",
    districts: [
      {
        id: "459",
        name: "Ba Tri",
        wards: [
          { id: "7420", name: "An B\u00ecnh T\u00e2y", prefix: "X\u00e3" },
          { id: "7421", name: "An \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "7422", name: "An Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "7423", name: "An H\u00f2a T\u00e2y", prefix: "X\u00e3" },
          { id: "7424", name: "An Ng\u00e3i T\u00e2y", prefix: "X\u00e3" },
          { id: "7425", name: "An Ng\u00e3i Trung", prefix: "X\u00e3" },
          { id: "7426", name: "An Ph\u00fa Trung", prefix: "X\u00e3" },
          { id: "7427", name: "An Th\u1ee7y", prefix: "X\u00e3" },
          { id: "7428", name: "Ba Tri", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "7429", name: "B\u1ea3o Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "7430", name: "B\u1ea3o Thu\u1eadn", prefix: "X\u00e3" },
          { id: "7431", name: "M\u1ef9 Ch\u00e1nh", prefix: "X\u00e3" },
          { id: "7432", name: "M\u1ef9 H\u00f2a", prefix: "X\u00e3" },
          { id: "7433", name: "M\u1ef9 Nh\u01a1n", prefix: "X\u00e3" },
          { id: "7434", name: "M\u1ef9 Th\u1ea1n", prefix: "X\u00e3" },
          { id: "7435", name: "M\u1ef9 Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "7436", name: "Ph\u00fa L\u1ec5", prefix: "X\u00e3" },
          { id: "7437", name: "Ph\u00fa Ng\u00e3i", prefix: "X\u00e3" },
          { id: "7438", name: "Ph\u01b0\u1edbc Tuy", prefix: "X\u00e3" },
          { id: "7439", name: "T\u00e2n H\u01b0ng", prefix: "X\u00e3" },
          { id: "7440", name: "T\u00e2n M\u1ef9", prefix: "X\u00e3" },
          { id: "7441", name: "T\u00e2n Th\u1ee7y", prefix: "X\u00e3" },
          { id: "7442", name: "T\u00e2n Xu\u00e2n", prefix: "X\u00e3" },
          { id: "7443", name: "V\u0129nh An", prefix: "X\u00e3" },
          { id: "7444", name: "V\u0129nh H\u00f2a", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "28330",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28331",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28332",
            name: "T\u1ec9nh l\u1ed9 885",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28333",
            name: "V\u0129nh Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "460",
        name: "B\u1ebfn Tre",
        wards: [
          { id: "7445", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "7446", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "7447", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "7448", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "7449", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "7450", name: "6", prefix: "Ph\u01b0\u1eddng" },
          { id: "7451", name: "7", prefix: "Ph\u01b0\u1eddng" },
          { id: "7452", name: "8", prefix: "Ph\u01b0\u1eddng" },
          { id: "7453", name: "B\u00ecnh Ph\u00fa", prefix: "X\u00e3" },
          { id: "7454", name: "M\u1ef9 Th\u00e0nh", prefix: "X\u00e3" },
          { id: "7455", name: "M\u1ef9 Th\u1ea1nh An", prefix: "X\u00e3" },
          { id: "7456", name: "Nh\u01a1n Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "7457", name: "Ph\u00fa H\u01b0ng", prefix: "X\u00e3" },
          {
            id: "7458",
            name: "Ph\u00fa Kh\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "7459", name: "Ph\u00fa Nhu\u1eadn", prefix: "X\u00e3" },
          { id: "7460", name: "Ph\u00fa T\u00e2n", prefix: "Ph\u01b0\u1eddng" },
          { id: "7461", name: "S\u01a1n \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "7462", name: "S\u01a1n \u0110\u00f4ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28334", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28335", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28336", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28337", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28338", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28339", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28340", name: "460B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28341", name: "60", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28342", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28343", name: "883", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28344", name: "884", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28345",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28346",
            name: "B\u00ecnh Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28347",
            name: "Ca V\u0103n Th\u1ec9nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28348",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28349", name: "\u0110an", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28350",
            name: "\u0110\u00ecnh Ph\u00fa T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28351",
            name: "\u0110o\u00e0n Ho\u00e0ng Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28352",
            name: "\u0110\u1ed3ng Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28353",
            name: "\u0110\u1ed3ng V\u0103n C\u1ed1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28354",
            name: "Ho\u00e0ng Lam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28355",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28356",
            name: "H\u01b0\u01a1ng L\u1ed9 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28357",
            name: "Hu\u1ef3nh T\u1ea5n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28358",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28359", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28360",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28361",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28362",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "28363",
            name: "Mi\u1ec5u C\u00e1i \u0110\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28364", name: "N2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28365",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28367",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28366",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28368",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28369",
            name: "Nguy\u1ec5n Ng\u1ecdc Nh\u1ef1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28370",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28371",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28372",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28373",
            name: "Nguy\u1ec5n V\u0103n Kh\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28374",
            name: "Nguy\u1ec5n V\u0103n Nguy\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28375",
            name: "Nguy\u1ec5n V\u0103n T\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28376",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28377",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28378",
            name: "Phan Ng\u1ecdc T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28379",
            name: "Ph\u00fa H\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28380",
            name: "Ph\u00fa H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28381",
            name: "Ph\u00fa Kh\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28382",
            name: "Ph\u00fa Ph\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28383",
            name: "Qu\u1ed1c l\u1ed9 60",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28384",
            name: "R\u1ea1ch C\u00e1i C\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28385", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28386", name: "S\u1ed1 4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28387",
            name: "T\u00e2n K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28388",
            name: "Th\u1ea7y Cai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28389",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28390",
            name: "Th\u1ee7 Khoa Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28391",
            name: "T\u1ec9nh l\u1ed9 883",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28392",
            name: "T\u1ec9nh l\u1ed9 884",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28393",
            name: "T\u1ec9nh L\u1ed9 887",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28394",
            name: "Tr\u1ea7n Qu\u1ed1c Tu\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28395",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28396",
            name: "Tr\u01b0\u01a1ng V\u0129nh K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28397",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28398",
            name: "V\u00f5 V\u0103n Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3024",
            name: "H\u01b0ng Ph\u00fa",
            lat: "10.253347396851",
            lng: "106.39443969727",
          },
          {
            id: "3025",
            name: "Sao Mai B\u1ebfn Tre",
            lat: "10.235722541809",
            lng: "106.35866546631",
          },
        ],
      },
      {
        id: "461",
        name: "B\u00ecnh \u0110\u1ea1i",
        wards: [
          {
            id: "7463",
            name: "B\u00ecnh \u0110\u1ea1i",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7464", name: "B\u00ecnh Th\u1eafng", prefix: "X\u00e3" },
          { id: "7465", name: "B\u00ecnh Th\u1edbi", prefix: "X\u00e3" },
          { id: "7466", name: "Ch\u00e2u H\u01b0ng", prefix: "X\u00e3" },
          {
            id: "7467",
            name: "\u0110\u1ea1i H\u00f2a L\u1ed9c",
            prefix: "X\u00e3",
          },
          { id: "7468", name: "\u0110\u1ecbnh Trung", prefix: "X\u00e3" },
          { id: "7469", name: "L\u1ed9c Thu\u1eadn", prefix: "X\u00e3" },
          { id: "7470", name: "Long \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "7471", name: "Long H\u00f2a", prefix: "X\u00e3" },
          { id: "7472", name: "Ph\u00fa Long", prefix: "X\u00e3" },
          { id: "7473", name: "Ph\u00fa Thu\u1eadn", prefix: "X\u00e3" },
          { id: "7474", name: "Ph\u00fa Vang", prefix: "X\u00e3" },
          { id: "7475", name: "Tam Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "7476", name: "Th\u1ea1nh Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "7477", name: "Th\u1ea1nh Tr\u1ecb", prefix: "X\u00e3" },
          { id: "7478", name: "Th\u1edbi Lai", prefix: "X\u00e3" },
          { id: "7479", name: "Th\u1edbi Thu\u1eadn", prefix: "X\u00e3" },
          { id: "7480", name: "Th\u1eeba \u0110\u1ee9c", prefix: "X\u00e3" },
          {
            id: "7481",
            name: "Vang Qu\u1edbi \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "7482", name: "Vang Qu\u1edbi T\u00e2y", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28399", name: "\u0110T 883", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28400",
            name: "H\u01b0\u01a1ng L\u1ed9 40",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28401",
            name: "L\u00ea Ph\u00e1t D\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28402",
            name: "M\u1eadu Th\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28403",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28404",
            name: "T\u1ec9nh l\u1ed9 883",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "462",
        name: "Ch\u00e2u Th\u00e0nh",
        wards: [
          { id: "7483", name: "An Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "7484", name: "An H\u00f3a", prefix: "X\u00e3" },
          { id: "7485", name: "An Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "7486", name: "An Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          {
            id: "7487",
            name: "Ch\u00e2u Th\u00e0nh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7488", name: "Giao H\u00f2a", prefix: "X\u00e3" },
          { id: "7489", name: "Giao Long", prefix: "X\u00e3" },
          { id: "7490", name: "H\u1eefu \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "7491", name: "Ph\u00fa An H\u00f2a", prefix: "X\u00e3" },
          { id: "7492", name: "Ph\u00fa \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "7493", name: "Ph\u00fa T\u00fac", prefix: "X\u00e3" },
          { id: "7494", name: "Ph\u01b0\u1edbc Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "7495", name: "Qu\u1edbi S\u01a1n", prefix: "X\u00e3" },
          { id: "7496", name: "Qu\u1edbi Th\u00e0nh", prefix: "X\u00e3" },
          { id: "7497", name: "S\u01a1n H\u00f2a", prefix: "X\u00e3" },
          { id: "7498", name: "Tam Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "7499", name: "T\u00e2n Ph\u00fa", prefix: "X\u00e3" },
          { id: "7500", name: "T\u00e2n Th\u1ea1ch", prefix: "X\u00e3" },
          { id: "7501", name: "Th\u00e0nh Tri\u1ec7u", prefix: "X\u00e3" },
          { id: "7502", name: "Ti\u00ean Long", prefix: "X\u00e3" },
          { id: "7503", name: "Ti\u00ean Th\u1ee7y", prefix: "X\u00e3" },
          { id: "7504", name: "T\u01b0\u1eddng \u0110a", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28405", name: "60", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28406", name: "An B\u00ecnh", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28407", name: "DH173", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28408",
            name: "Gi\u1ed3ng Da",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28409",
            name: "H\u01b0\u01a1ng l\u1ed9 178",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28410",
            name: "Hu\u1ef3nh T\u1ea5n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28411",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28412",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28413",
            name: "Nguy\u1ec5n V\u0103n C\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28414",
            name: "Qu\u1ed1c l\u1ed9 60",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28415",
            name: "T\u1ec9nh l\u1ed9 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28416",
            name: "T\u1ec9nh l\u1ed9 883",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28417",
            name: "T\u1ec9nh l\u1ed9 884",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28418",
            name: "T\u1ec9nh l\u1ed9 886",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "463",
        name: "Ch\u1ee3 L\u00e1ch",
        wards: [
          {
            id: "7505",
            name: "Ch\u1ee3 L\u00e1ch",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7506", name: "H\u00f2a Ngh\u0129a", prefix: "X\u00e3" },
          {
            id: "7507",
            name: "H\u01b0ng Kh\u00e1nh Trung B",
            prefix: "X\u00e3",
          },
          { id: "7508", name: "Long Th\u1edbi", prefix: "X\u00e3" },
          { id: "7509", name: "Ph\u00fa Ph\u1ee5ng", prefix: "X\u00e3" },
          { id: "7510", name: "Ph\u00fa S\u01a1n", prefix: "X\u00e3" },
          { id: "7511", name: "S\u01a1n \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "7512", name: "T\u00e2n Thi\u1ec1ng", prefix: "X\u00e3" },
          { id: "7513", name: "V\u0129nh B\u00ecnh", prefix: "X\u00e3" },
          { id: "7514", name: "V\u0129nh H\u00f2a", prefix: "X\u00e3" },
          { id: "7515", name: "V\u0129nh Th\u00e0nh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28419", name: "57", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28420",
            name: "Huy\u1ec7n l\u1ed9 172",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28421",
            name: "Qu\u1ed1c L\u1ed9 57",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28422",
            name: "T\u1ec9nh l\u1ed9 747",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28423",
            name: "T\u1ec9nh l\u1ed9 884",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "464",
        name: "Gi\u1ed3ng Tr\u00f4m",
        wards: [
          { id: "7516", name: "B\u00ecnh H\u00f2a", prefix: "X\u00e3" },
          { id: "7517", name: "B\u00ecnh Th\u00e0nh", prefix: "X\u00e3" },
          { id: "7518", name: "Ch\u00e2u B\u00ecnh", prefix: "X\u00e3" },
          { id: "7519", name: "Ch\u00e2u H\u00f2a", prefix: "X\u00e3" },
          {
            id: "7520",
            name: "Gi\u1ed3ng Tr\u00f4m",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7521", name: "H\u01b0ng L\u1ec5", prefix: "X\u00e3" },
          { id: "7522", name: "H\u01b0ng Nh\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "7523", name: "H\u01b0ng Phong", prefix: "X\u00e3" },
          { id: "7524", name: "Long M\u1ef9", prefix: "X\u00e3" },
          { id: "7525", name: "L\u01b0\u01a1ng H\u00f2a", prefix: "X\u00e3" },
          { id: "7526", name: "L\u01b0\u01a1ng Ph\u00fa", prefix: "X\u00e3" },
          { id: "7527", name: "L\u01b0\u01a1ng Qu\u1edbi", prefix: "X\u00e3" },
          { id: "7528", name: "M\u1ef9 Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "7529", name: "Phong M\u1ef9", prefix: "X\u00e3" },
          { id: "7530", name: "Phong N\u1eabm", prefix: "X\u00e3" },
          { id: "7531", name: "Ph\u01b0\u1edbc Long", prefix: "X\u00e3" },
          { id: "7532", name: "S\u01a1n Ph\u00fa", prefix: "X\u00e3" },
          { id: "7533", name: "T\u00e2n H\u00e0o", prefix: "X\u00e3" },
          { id: "7534", name: "T\u00e2n H\u00f2a", prefix: "X\u00e3" },
          {
            id: "7535",
            name: "T\u00e2n L\u1ee3i Th\u1ea1nh",
            prefix: "X\u00e3",
          },
          { id: "7536", name: "T\u00e2n Thanh", prefix: "X\u00e3" },
          {
            id: "7537",
            name: "Th\u1ea1nh Ph\u00fa \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "7538", name: "Thu\u1eadn \u0110i\u1ec1n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28424", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28425", name: "2/9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28426", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28427",
            name: "B\u1ebfn Mi\u1ec5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28428",
            name: "B\u00ecnh Ti\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28429", name: "DH173", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28430",
            name: "\u0110\u1ed3ng Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28431",
            name: "\u0110\u1ed3ng V\u0103n C\u1ed1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28432", name: "\u0110T 886", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28433",
            name: "Ho\u00e0ng Lam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28434",
            name: "L\u00e3nh Binh Th\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28435",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28436",
            name: "Phan V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28437",
            name: "T\u00e1n K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28438",
            name: "T\u1ec9nh l\u1ed9 885",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28439",
            name: "T\u1ec9nh l\u1ed9 886",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28440",
            name: "T\u1ec9nh L\u1ed9 887",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "465",
        name: "M\u1ecf C\u00e0y B\u1eafc",
        wards: [
          { id: "7539", name: "H\u00f2a L\u1ed9c", prefix: "X\u00e3" },
          {
            id: "7540",
            name: "H\u01b0ng Kh\u00e1nh Trung A",
            prefix: "X\u00e3",
          },
          {
            id: "7541",
            name: "Kh\u00e1nh Th\u1ea1nh T\u00e2n",
            prefix: "X\u00e3",
          },
          {
            id: "7542",
            name: "Nhu\u1eadn Ph\u00fa T\u00e2n",
            prefix: "X\u00e3",
          },
          { id: "7543", name: "Ph\u00fa M\u1ef9", prefix: "X\u00e3" },
          {
            id: "7544",
            name: "Ph\u01b0\u1edbc M\u1ef9 Trung",
            prefix: "X\u00e3",
          },
          { id: "7545", name: "T\u00e2n B\u00ecnh", prefix: "X\u00e3" },
          { id: "7546", name: "T\u00e2n Ph\u00fa T\u00e2y", prefix: "X\u00e3" },
          {
            id: "7547",
            name: "T\u00e2n Th\u00e0nh B\u00ecnh",
            prefix: "X\u00e3",
          },
          { id: "7548", name: "T\u00e2n Thanh T\u00e2y", prefix: "X\u00e3" },
          { id: "7549", name: "Th\u00e0nh An", prefix: "X\u00e3" },
          { id: "7550", name: "Th\u1ea1nh Ng\u00e3i", prefix: "X\u00e3" },
          { id: "7551", name: "Thanh T\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28441", name: "60", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28442",
            name: "Qu\u1ed1c L\u1ed9 57",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28443",
            name: "Qu\u1ed1c l\u1ed9 60",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28444",
            name: "T\u1ec9nh l\u1ed9 882",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "466",
        name: "M\u1ecf C\u00e0y Nam",
        wards: [
          { id: "7552", name: "An \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "7553", name: "An Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "7554", name: "An Th\u1edbi", prefix: "X\u00e3" },
          {
            id: "7555",
            name: "B\u00ecnh Kh\u00e1nh \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "7556",
            name: "B\u00ecnh Kh\u00e1nh T\u00e2y",
            prefix: "X\u00e3",
          },
          { id: "7557", name: "C\u1ea9m S\u01a1n", prefix: "X\u00e3" },
          {
            id: "7558",
            name: "\u0110a Ph\u01b0\u1edbc H\u1ed9i",
            prefix: "X\u00e3",
          },
          { id: "7559", name: "\u0110\u1ecbnh Th\u1ee7y", prefix: "X\u00e3" },
          { id: "7560", name: "H\u01b0\u01a1ng M\u1ef9", prefix: "X\u00e3" },
          { id: "7561", name: "Minh \u0110\u1ee9c", prefix: "X\u00e3" },
          {
            id: "7562",
            name: "M\u1ecf C\u00e0y",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7563", name: "Ng\u00e3i \u0110\u0103ng", prefix: "X\u00e3" },
          { id: "7564", name: "Ph\u01b0\u1edbc Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "7565", name: "T\u00e2n H\u1ed9i", prefix: "X\u00e3" },
          { id: "7566", name: "T\u00e2n Trung", prefix: "X\u00e3" },
          { id: "7567", name: "Th\u00e0nh Th\u1edbi A", prefix: "X\u00e3" },
          { id: "7568", name: "Th\u00e0nh Th\u1edbi B", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28445", name: "60", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28446",
            name: "Qu\u1ed1c L\u1ed9 57",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28447",
            name: "Qu\u1ed1c l\u1ed9 60",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28448",
            name: "T\u1ec9nh l\u1ed9 60",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "467",
        name: "Th\u1ea1nh Ph\u00fa",
        wards: [
          { id: "7569", name: "An \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "7570", name: "An Nh\u01a1n", prefix: "X\u00e3" },
          { id: "7571", name: "An Quy", prefix: "X\u00e3" },
          { id: "7572", name: "An Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "7573", name: "An Thu\u1eadn", prefix: "X\u00e3" },
          { id: "7574", name: "B\u00ecnh Th\u1ea1nh", prefix: "X\u00e3" },
          {
            id: "7575",
            name: "\u0110\u1ea1i \u0110i\u1ec1n",
            prefix: "X\u00e3",
          },
          { id: "7576", name: "Giao Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "7577", name: "H\u00f2a L\u1ee3i", prefix: "X\u00e3" },
          { id: "7578", name: "M\u1ef9 An", prefix: "X\u00e3" },
          { id: "7579", name: "M\u1ef9 H\u01b0ng", prefix: "X\u00e3" },
          { id: "7580", name: "Ph\u00fa Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "7581", name: "Qu\u1edbi \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "7582", name: "T\u00e2n Phong", prefix: "X\u00e3" },
          { id: "7583", name: "Th\u1ea1nh H\u1ea3i", prefix: "X\u00e3" },
          { id: "7584", name: "Th\u1ea1nh Phong", prefix: "X\u00e3" },
          {
            id: "7585",
            name: "Th\u1ea1nh Ph\u00fa",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7586", name: "Th\u1edbi Th\u1ea1nh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28449", name: "30/10", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28451",
            name: "D\u01b0\u01a1ng V\u0103n D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28450",
            name: "\u0110\u1ed3ng V\u0103n D\u1eabn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28452",
            name: "Gi\u1ed3ng D\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28453",
            name: "Hu\u1ef3nh Ng\u1ecdc Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28454",
            name: "L\u00ea V\u0103n V\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28455",
            name: "Nguy\u1ec5n Thanh T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28456",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28457",
            name: "Qu\u1ed1c L\u1ed9 57",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28458",
            name: "Tr\u1ea7n Th\u1ecb Ti\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28459",
            name: "Tr\u1ea7n V\u0103n T\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "38",
    code: "DNO",
    name: "\u0110\u1eafk N\u00f4ng",
    districts: [
      {
        id: "468",
        name: "C\u01b0 J\u00fat",
        wards: [
          { id: "7587", name: "C\u01b0 KNia", prefix: "X\u00e3" },
          {
            id: "7588",
            name: "\u0110\u0103k \u0110r\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "7589", name: "\u0110\u0103k Wil", prefix: "X\u00e3" },
          { id: "7590", name: "Ea P\u00f4", prefix: "X\u00e3" },
          { id: "7591", name: "Ea T'ling", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "7592", name: "Nam Dong", prefix: "X\u00e3" },
          { id: "7593", name: "T\u00e2m Th\u1eafng", prefix: "X\u00e3" },
          { id: "7594", name: "Tr\u00fac S\u01a1n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28460", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28461", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "28462",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28463",
            name: "Li\u00ean T\u1ec9nh 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28464",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28465",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28466",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28467", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28468",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28469",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28470", name: "Ybih Aleo", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
      {
        id: "469",
        name: "D\u0103k GLong",
        wards: [
          { id: "7595", name: "\u0110\u1eafk Ha", prefix: "X\u00e3" },
          { id: "7596", name: "\u0110\u1eafk PLao", prefix: "X\u00e3" },
          { id: "7597", name: "\u0110\u1eafk R'M\u0103ng", prefix: "X\u00e3" },
          { id: "7598", name: "\u0110\u1eafk Som", prefix: "X\u00e3" },
          { id: "7599", name: "Qu\u1ea3ng H\u00f2a", prefix: "X\u00e3" },
          {
            id: "7600",
            name: "Qu\u1ea3ng Kh\u00ea",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "7601",
            name: "Qu\u1ea3ng S\u01a1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          {
            id: "28471",
            name: "Bon R'B\u00fat",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28472",
            name: "Qu\u1ed1c l\u1ed9 28",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28473",
            name: "Qu\u1ed1c L\u1ed9 28B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28474",
            name: "T\u1ec9nh L\u1ed9 684",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28475",
            name: "T\u1ec9nh l\u1ed9 693",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "470",
        name: "D\u0103k Mil",
        wards: [
          { id: "7602", name: "\u0110\u1eafk G\u1eb1n", prefix: "X\u00e3" },
          { id: "7603", name: "\u0110\u1eafk Lao", prefix: "X\u00e3" },
          {
            id: "7604",
            name: "\u0110\u1eafk Mil",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7605", name: "\u0110\u1eafk N'Dr\u00f3t", prefix: "X\u00e3" },
          { id: "7606", name: "\u0110\u1eafk R'La", prefix: "X\u00e3" },
          { id: "7607", name: "\u0110\u1eafk S\u1eafk", prefix: "X\u00e3" },
          { id: "7608", name: "\u0110\u1ee9c M\u1ea1nh", prefix: "X\u00e3" },
          { id: "7609", name: "\u0110\u1ee9c Minh", prefix: "X\u00e3" },
          { id: "7610", name: "Long S\u01a1n", prefix: "X\u00e3" },
          { id: "7611", name: "Thu\u1eadn An", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28476", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28477",
            name: "\u0110\u1ee9c T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28478",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28479",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28480",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28481",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28482",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28483",
            name: "N\u01a1 Trang G\u01b0h",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28484", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28485",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28486",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "471",
        name: "D\u0103k R'L\u1ea5p",
        wards: [
          { id: "7612", name: "\u0110\u1eafk Ru", prefix: "X\u00e3" },
          { id: "7613", name: "\u0110\u1eafk Sin", prefix: "X\u00e3" },
          { id: "7614", name: "\u0110\u1eafk Wer", prefix: "X\u00e3" },
          { id: "7615", name: "\u0110\u1ea1o Ngh\u0129a", prefix: "X\u00e3" },
          { id: "7616", name: "H\u01b0ng B\u00ecnh", prefix: "X\u00e3" },
          {
            id: "7617",
            name: "Ki\u1ebfn \u0110\u1ee9c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7618", name: "Ki\u1ebfn Th\u00e0nh", prefix: "X\u00e3" },
          { id: "7619", name: "Ngh\u0129a Th\u1eafng", prefix: "X\u00e3" },
          { id: "7620", name: "Nh\u00e2n C\u01a1", prefix: "X\u00e3" },
          { id: "7621", name: "Nh\u00e2n \u0110\u1ea1o", prefix: "X\u00e3" },
          { id: "7622", name: "Qu\u1ea3ng T\u00edn", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28487", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28488",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28489",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28490",
            name: "T\u1ec9nh L\u1ed9 681",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28491",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "472",
        name: "D\u0103k Song",
        wards: [
          { id: "7623", name: "\u0110\u1eafk Ho\u00e0", prefix: "X\u00e3" },
          { id: "7624", name: "\u0110\u1eafk M\u00f4l", prefix: "X\u00e3" },
          { id: "7625", name: "\u0110\u1eafk N'Drung", prefix: "X\u00e3" },
          {
            id: "7626",
            name: "\u0110\u1ee9c An",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7627", name: "Nam B\u00ecnh", prefix: "X\u00e3" },
          { id: "7628", name: "N\u00e2m N'Jang", prefix: "X\u00e3" },
          { id: "7629", name: "Thu\u1eadn H\u00e0", prefix: "X\u00e3" },
          { id: "7630", name: "Thu\u1eadn H\u1ea1nh", prefix: "X\u00e3" },
          { id: "7631", name: "Tr\u01b0\u1eddng Xu\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "28492",
            name: "\u0110\u0103k Rung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28493",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28494",
            name: "Qu\u1ed1c l\u1ed9 14C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28495",
            name: "T\u1ec9nh l\u1ed9 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "473",
        name: "Gia Ngh\u0129a",
        wards: [
          { id: "7632", name: "\u0110\u1eafk Nia", prefix: "X\u00e3" },
          { id: "7633", name: "\u0110\u1eafk R\u2019Moan", prefix: "X\u00e3" },
          {
            id: "7634",
            name: "Ngh\u0129a \u0110\u1ee9c",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "7635",
            name: "Ngh\u0129a Ph\u00fa",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "7636",
            name: "Ngh\u0129a T\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "7637",
            name: "Ngh\u0129a Th\u00e0nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "7638", name: "Ngh\u0129a Trung", prefix: "Ph\u01b0\u1eddng" },
          { id: "7639", name: "Qu\u1ea3ng Th\u00e0nh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28496", name: "23/3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28497", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28498",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28499", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "28500",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28501", name: "D4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28502",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28503",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28504",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28505",
            name: "H\u1ed3 Ch\u00ed Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28506",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28507",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28508",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28509",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28510", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28511",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28512",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28513",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "28514",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28515",
            name: "M\u1ea1c Th\u1ecb B\u01b0\u1edfi",
            prefix: "Ph\u1ed1",
          },
          { id: "28516", name: "N1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28517", name: "N3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28518",
            name: "Ng\u00f4 M\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28519",
            name: "Ng\u00f4 T\u1ea5t T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28520",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28521",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28522",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28523",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28524",
            name: "Nh\u00e2n C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28525",
            name: "N\u01a1 Trang Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28526",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28527",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28528", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28529",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28530",
            name: "Qu\u1ed1c l\u1ed9 28",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28531",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "28532",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "28533",
            name: "V\u00e0nh \u0110ai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28534",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28535",
            name: "V\u0169 Anh Ba",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28536", name: "Yni\u00ea", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "3026",
            name: "Thi\u00ean Ph\u00fa Intresco",
            lat: "12.007342338562",
            lng: "107.68368530273",
          },
        ],
      },
      {
        id: "474",
        name: "Kr\u00f4ng N\u00f4",
        wards: [
          { id: "7640", name: "Bu\u00f4n Choah", prefix: "X\u00e3" },
          { id: "7641", name: "\u0110\u0103k Dr\u00f4", prefix: "X\u00e3" },
          {
            id: "7642",
            name: "\u0110\u0103k M\u00e2m",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7643", name: "\u0110\u0103k Nang", prefix: "X\u00e3" },
          { id: "7644", name: "\u0110\u0103k S\u00f4r", prefix: "X\u00e3" },
          { id: "7645", name: "\u0110\u1ee9c Xuy\u00ean", prefix: "X\u00e3" },
          { id: "7646", name: "Nam \u0110\u00e0", prefix: "X\u00e3" },
          { id: "7647", name: "N\u00e2m N'\u0110ir", prefix: "X\u00e3" },
          { id: "7648", name: "N\u00e2m Nung", prefix: "X\u00e3" },
          { id: "7649", name: "Nam Xu\u00e2n", prefix: "X\u00e3" },
          { id: "7650", name: "Qu\u1ea3ng Ph\u00fa", prefix: "X\u00e3" },
          { id: "7651", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "28537",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28538",
            name: "Qu\u1ed1c l\u1ed9 28",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28539",
            name: "T\u1ec9nh l\u1ed9 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "475",
        name: "Tuy \u0110\u1ee9c",
        wards: [
          {
            id: "7652",
            name: "\u0110\u1eafk B\u00fak So",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7653", name: "\u0110\u1eafk Ngo", prefix: "X\u00e3" },
          { id: "7654", name: "\u0110\u1eafk R'T\u00edh", prefix: "X\u00e3" },
          { id: "7655", name: "Qu\u1ea3ng T\u00e2m", prefix: "X\u00e3" },
          { id: "7656", name: "Qu\u1ea3ng T\u00e2n", prefix: "X\u00e3" },
          { id: "7657", name: "Qu\u1ea3ng Tr\u1ef1c", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "28540",
            name: "Qu\u1ed1c l\u1ed9 14C",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "39",
    code: "CM",
    name: "C\u00e0 Mau",
    districts: [
      {
        id: "476",
        name: "C\u00e0 Mau",
        wards: [
          { id: "7658", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "7659", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "7660", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "7661", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "7662", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "7663", name: "6", prefix: "Ph\u01b0\u1eddng" },
          { id: "7664", name: "7", prefix: "Ph\u01b0\u1eddng" },
          { id: "7665", name: "8", prefix: "Ph\u01b0\u1eddng" },
          { id: "7666", name: "9", prefix: "Ph\u01b0\u1eddng" },
          { id: "7667", name: "An Xuy\u00ean", prefix: "X\u00e3" },
          { id: "7668", name: "\u0110\u1ecbnh B\u00ecnh", prefix: "X\u00e3" },
          { id: "7669", name: "H\u00f2a T\u00e2n", prefix: "X\u00e3" },
          { id: "7670", name: "H\u00f2a Th\u00e0nh", prefix: "X\u00e3" },
          { id: "7671", name: "L\u00fd V\u0103n L\u00e2m", prefix: "X\u00e3" },
          { id: "7672", name: "T\u1eafc V\u00e2n", prefix: "X\u00e3" },
          {
            id: "7673",
            name: "T\u00e2n Th\u00e0nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "7674", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          {
            id: "7675",
            name: "T\u00e2n Xuy\u00ean",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "28541", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28542", name: "11B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28543", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28544", name: "26/3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28545", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28546", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28547", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28548", name: "9C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28549", name: "A8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28550",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28551",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28552", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "28553",
            name: "B\u00f9i Th\u1ecb Tr\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28554",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28555",
            name: "Ch\u00e2u V\u0103n \u0110\u1eb7ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28556",
            name: "Ch\u00e2u V\u0103n Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28557",
            name: "Chung Th\u00e0nh Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28558", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28559", name: "D7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28560", name: "D8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28561",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28562",
            name: "\u0110\u1ed7 Th\u1eeba Lu\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28563",
            name: "\u0110\u1ed7 Th\u1eeba T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28564", name: "H3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28565", name: "H7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28566",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28567",
            name: "H\u00f2a An 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28568", name: "Hoa L\u01b0", prefix: "Ph\u1ed1" },
          {
            id: "28569",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28570",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28571",
            name: "Hu\u1ef3nh Phi H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28572",
            name: "Hu\u1ef3nh T\u1ea5n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28573",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28574", name: "K2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28575",
            name: "K\u00eanh C\u1ed1ng \u0110\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28576",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28577",
            name: "Kinh M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28578",
            name: "Kinh R\u1ea1ch R\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28579",
            name: "L\u00e2m Th\u00e0nh M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28580",
            name: "L\u00ea Anh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28581",
            name: "L\u00ea \u0110\u1ee9c Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28582",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28583",
            name: "L\u00ea H\u1eefu Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28584",
            name: "L\u00ea Kh\u1eafc X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28585",
            name: "L\u00ea Th\u1ecb Ri\u00eang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28586",
            name: "L\u00ea V\u0129nh H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28587",
            name: "L\u1ed9 M\u01a1\u0301i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28588",
            name: "L\u00fd B\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28589",
            name: "L\u00fd Th\u00e1i T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28590",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "28591",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28592",
            name: "L\u00fd V\u0103n L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28593",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28594",
            name: "M\u1eadu Th\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28595", name: "N14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28596", name: "N18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28597", name: "Nam Cao", prefix: "Ph\u1ed1" },
          {
            id: "28598",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28599",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28600",
            name: "Nguy\u1ec5n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28601",
            name: "Nguy\u1ec5n Ch\u00e1nh T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28602",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28603",
            name: "Nguy\u1ec5n C\u01b0 Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28606",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28604",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28605",
            name: "Nguy\u1ec5n \u0110\u00ecnh Thi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28607",
            name: "Nguy\u1ec5n H\u1eefu L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28608",
            name: "Nguy\u1ec5n H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28609",
            name: "Nguy\u1ec5n Ng\u1ecdc Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28610",
            name: "Nguy\u1ec5n Ng\u1ecdc Sanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28611",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28612",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28613",
            name: "Nguy\u1ec5n Th\u1ecb Nho",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28614",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28615",
            name: "Nguy\u1ec5n Trung Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28616",
            name: "Nguy\u1ec5n V\u0103n B\u1ea3y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28617",
            name: "Nguy\u1ec5n V\u0103n Bi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28618",
            name: "Ph\u1ea1m Ch\u00ed Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28619",
            name: "Ph\u1ea1m H\u1ed3ng Th\u1eafm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28620",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28621",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28622",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28623",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28624",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28625",
            name: "Ph\u1ea7n L\u0103ng 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28626",
            name: "Phan Ng\u1ecdc Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28627",
            name: "Qu\u1ea3n l\u1ed9 Ph\u1ee5ng Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28628", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28629",
            name: "Qu\u1ea3ng Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28630",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28631",
            name: "Qu\u1ed1c l\u1ed9 63",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28632",
            name: "R\u1ea1ch Ch\u00f9a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28633", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28634", name: "S\u1ed1 10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28635", name: "S\u1ed1 11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28636", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28637", name: "S\u1ed1 13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28638", name: "S\u1ed1 16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28639", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28640", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28641", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28642", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28643", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28644", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28645",
            name: "T\u1ea1 Uy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28646",
            name: "T\u00e2n Th\u1edbi Hi\u1ec7p 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28647",
            name: "Th\u1ebf L\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28648",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28649",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "28650",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28651",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28652",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28653",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28654",
            name: "Tr\u1ea7n V\u0103n \u01a0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28655",
            name: "Tr\u1ea7n V\u0103n Th\u1eddi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28656",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28657",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28658",
            name: "V\u00e0nh \u0110ai 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28659",
            name: "V\u00e0nh \u0110ai 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28660",
            name: "V\u00e0nh \u0110ai 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28661",
            name: "V\u00f5 V\u0103n T\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28662",
            name: "V\u00f5 V\u0103n V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28663",
            name: "Xu\u00e2n Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "477",
        name: "C\u00e1i N\u01b0\u1edbc",
        wards: [
          {
            id: "7676",
            name: "C\u00e1i N\u01b0\u1edbc",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7677", name: "\u0110\u00f4ng H\u01b0ng", prefix: "X\u00e3" },
          { id: "7678", name: "\u0110\u00f4ng Th\u1edbi", prefix: "X\u00e3" },
          { id: "7679", name: "H\u00f2a M\u1ef9", prefix: "X\u00e3" },
          { id: "7680", name: "H\u01b0ng M\u1ef9", prefix: "X\u00e3" },
          {
            id: "7681",
            name: "L\u01b0\u01a1ng Th\u1ebf Tr\u00e2n",
            prefix: "X\u00e3",
          },
          { id: "7682", name: "Ph\u00fa H\u01b0ng", prefix: "X\u00e3" },
          { id: "7683", name: "T\u00e2n H\u01b0ng", prefix: "X\u00e3" },
          {
            id: "7684",
            name: "T\u00e2n H\u01b0ng \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "7685", name: "Th\u1ea1nh Ph\u00fa", prefix: "X\u00e3" },
          { id: "7686", name: "Tr\u1ea7n Th\u1edbi", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "28664",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "478",
        name: "\u0110\u1ea7m D\u01a1i",
        wards: [
          {
            id: "7687",
            name: "\u0110\u1ea7m D\u01a1i",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7688", name: "Ng\u1ecdc Ch\u00e1nh", prefix: "X\u00e3" },
          { id: "7689", name: "Nguy\u1ec5n Hu\u00e2n", prefix: "X\u00e3" },
          { id: "7690", name: "Qu\u00e1ch Ph\u1ea9m", prefix: "X\u00e3" },
          {
            id: "7691",
            name: "Qu\u00e1ch Ph\u1ea9m B\u1eafc",
            prefix: "X\u00e3",
          },
          {
            id: "7692",
            name: "T\u1ea1 An Kh\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          {
            id: "7693",
            name: "T\u1ea1 An Kh\u01b0\u01a1ng \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "7694",
            name: "T\u1ea1 An Kh\u01b0\u01a1ng Nam",
            prefix: "X\u00e3",
          },
          { id: "7695", name: "T\u00e2n D\u00e2n", prefix: "X\u00e3" },
          { id: "7697", name: "T\u00e2n Duy\u1ec7t", prefix: "X\u00e3" },
          { id: "7696", name: "T\u00e2n \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "7698", name: "T\u00e2n Thu\u1eadn", prefix: "X\u00e3" },
          { id: "7699", name: "T\u00e2n Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "7700", name: "T\u00e2n Trung", prefix: "X\u00e3" },
          { id: "7701", name: "Thanh T\u00f9ng", prefix: "X\u00e3" },
          { id: "7702", name: "Tr\u1ea7n Ph\u00e1n", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "479",
        name: "N\u0103m C\u0103n",
        wards: [
          { id: "7703", name: "\u0110\u1ea5t M\u1edbi", prefix: "X\u00e3" },
          { id: "7704", name: "H\u00e0m R\u1ed3ng", prefix: "X\u00e3" },
          { id: "7705", name: "H\u00e0ng V\u1ecbnh", prefix: "X\u00e3" },
          { id: "7706", name: "Hi\u1ec7p T\u00f9ng", prefix: "X\u00e3" },
          { id: "7707", name: "L\u00e2m H\u1ea3i", prefix: "X\u00e3" },
          {
            id: "7708",
            name: "N\u0103m C\u0103n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7709", name: "Tam Giang", prefix: "X\u00e3" },
          { id: "7710", name: "Tam Giang \u0110\u00f4ng", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "28665",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28666",
            name: "L\u00fd Nam \u0110\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28667",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "480",
        name: "Ng\u1ecdc Hi\u1ec3n",
        wards: [
          { id: "7711", name: "\u0110\u1ea5t M\u0169i", prefix: "X\u00e3" },
          {
            id: "7712",
            name: "R\u1ea1ch G\u1ed1c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7713", name: "Tam Giang T\u00e2y", prefix: "X\u00e3" },
          { id: "7714", name: "T\u00e2n \u00c2n", prefix: "X\u00e3" },
          { id: "7715", name: "T\u00e2n \u00c2n T\u00e2y", prefix: "X\u00e3" },
          { id: "7716", name: "Vi\u00ean An", prefix: "X\u00e3" },
          {
            id: "7717",
            name: "Vi\u00ean An \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
        ],
        streets: [
          { id: "28668", name: "13/12", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28669",
            name: "B\u00f4ng V\u0103n D\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28670",
            name: "Nguy\u1ec5n V\u0103n C\u1ee9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "481",
        name: "Ph\u00fa T\u00e2n",
        wards: [
          {
            id: "7718",
            name: "C\u00e1i \u0110\u00f4i V\u00e0m",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "7719",
            name: "Nguy\u1ec5n Vi\u1ec7t Kh\u00e1i",
            prefix: "X\u00e3",
          },
          { id: "7720", name: "Ph\u00fa M\u1ef9", prefix: "X\u00e3" },
          { id: "7721", name: "Ph\u00fa T\u00e2n", prefix: "X\u00e3" },
          { id: "7722", name: "Ph\u00fa Thu\u1eadn", prefix: "X\u00e3" },
          { id: "7723", name: "R\u1ea1ch Ch\u00e8o", prefix: "X\u00e3" },
          { id: "7724", name: "T\u00e2n H\u1ea3i", prefix: "X\u00e3" },
          {
            id: "7725",
            name: "T\u00e2n H\u01b0ng T\u00e2y",
            prefix: "X\u00e3",
          },
          { id: "7726", name: "Vi\u1ec7t Th\u1eafng", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "482",
        name: "Th\u1edbi B\u00ecnh",
        wards: [
          { id: "7727", name: "Bi\u1ec3n B\u1ea1ch", prefix: "X\u00e3" },
          {
            id: "7728",
            name: "Bi\u1ec3n B\u1ea1ch \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "7729", name: "H\u1ed3 Th\u1ecb K\u1ef7", prefix: "X\u00e3" },
          { id: "7730", name: "T\u00e2n B\u1eb1ng", prefix: "X\u00e3" },
          { id: "7731", name: "T\u00e2n L\u1ed9c", prefix: "X\u00e3" },
          { id: "7732", name: "T\u00e2n L\u1ed9c B\u1eafc", prefix: "X\u00e3" },
          {
            id: "7733",
            name: "T\u00e2n L\u1ed9c \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "7734", name: "T\u00e2n Ph\u00fa", prefix: "X\u00e3" },
          {
            id: "7735",
            name: "Th\u1edbi B\u00ecnh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7736", name: "Th\u1edbi B\u00ecnh", prefix: "X\u00e3" },
          { id: "7737", name: "Tr\u00ed L\u1ef1c", prefix: "X\u00e3" },
          { id: "7738", name: "Tr\u00ed Ph\u1ea3i", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28671", name: "13/12", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28672",
            name: "L\u00ea C\u00f4ng Nh\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28673",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28674",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28675",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28676",
            name: "Xuy\u00ean \u00c1",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "483",
        name: "Tr\u1ea7n V\u0103n Th\u1eddi",
        wards: [
          { id: "7739", name: "Kh\u00e1nh B\u00ecnh", prefix: "X\u00e3" },
          {
            id: "7740",
            name: "Kh\u00e1nh B\u00ecnh \u0110\u00f4ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "7741",
            name: "Kh\u00e1nh B\u00ecnh T\u00e2y",
            prefix: "X\u00e3",
          },
          {
            id: "7742",
            name: "Kh\u00e1nh B\u00ecnh T\u00e2y B\u1eafc",
            prefix: "X\u00e3",
          },
          { id: "7743", name: "Kh\u00e1nh H\u1ea3i", prefix: "X\u00e3" },
          { id: "7744", name: "Kh\u00e1nh H\u01b0ng", prefix: "X\u00e3" },
          { id: "7745", name: "Kh\u00e1nh L\u1ed9c", prefix: "X\u00e3" },
          { id: "7746", name: "L\u1ee3i An", prefix: "X\u00e3" },
          { id: "7747", name: "Phong \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "7748", name: "Phong L\u1ea1c", prefix: "X\u00e3" },
          {
            id: "7749",
            name: "S\u00f4ng \u0110\u1ed1c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7750", name: "Tr\u1ea7n H\u1ee3i", prefix: "X\u00e3" },
          {
            id: "7751",
            name: "Tr\u1ea7n V\u0103n Th\u1eddi",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          { id: "28677", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28678",
            name: "Nguy\u1ec5n Ng\u1ecdc Sanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "484",
        name: "U Minh",
        wards: [
          { id: "7752", name: "Kh\u00e1nh An", prefix: "X\u00e3" },
          { id: "7753", name: "Kh\u00e1nh H\u00f2a", prefix: "X\u00e3" },
          { id: "7754", name: "Kh\u00e1nh H\u1ed9i", prefix: "X\u00e3" },
          { id: "7755", name: "Kh\u00e1nh L\u00e2m", prefix: "X\u00e3" },
          { id: "7756", name: "Kh\u00e1nh Thu\u1eadn", prefix: "X\u00e3" },
          { id: "7757", name: "Kh\u00e1nh Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "7758", name: "Nguy\u1ec5n Ph\u00edch", prefix: "X\u00e3" },
          { id: "7759", name: "U Minh", prefix: "Th\u1ecb tr\u1ea5n" },
        ],
        streets: [
          {
            id: "28679",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28680",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "40",
    code: "VL",
    name: "V\u0129nh Long",
    districts: [
      {
        id: "485",
        name: "B\u00ecnh Minh",
        wards: [
          {
            id: "7760",
            name: "C\u00e1i V\u1ed3n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7761", name: "\u0110\u00f4ng B\u00ecnh", prefix: "X\u00e3" },
          { id: "7762", name: "\u0110\u00f4ng Th\u00e0nh", prefix: "X\u00e3" },
          { id: "7763", name: "\u0110\u00f4ng Th\u1ea1nh", prefix: "X\u00e3" },
          {
            id: "7764",
            name: "\u0110\u00f4ng Thu\u1eadn",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "7765", name: "M\u1ef9 H\u00f2a", prefix: "X\u00e3" },
          {
            id: "7766",
            name: "Th\u00e0nh Ph\u01b0\u1edbc",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "7767", name: "Thu\u1eadn An", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28681", name: "Bo Xoai", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28682", name: "D5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28683",
            name: "\u0110\u00f4ng B\u00ecnh - \u0110\u00f4ng Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28684",
            name: "Nguy\u1ec5n V\u0103n Th\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28685",
            name: "Phan V\u0103n N\u0103m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28686",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28687",
            name: "Qu\u1ed1c l\u1ed9 54",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28688", name: "Thu Loan", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "3027",
            name: "B\u00ecnh Minh",
            lat: "10.038204193115",
            lng: "105.82205200195",
          },
          {
            id: "3028",
            name: "HQC B\u00ecnh Minh",
            lat: "10.025405883789",
            lng: "105.84159851074",
          },
          {
            id: "3029",
            name: "K\u0110T B\u00ecnh Minh",
            lat: "10.013796806335",
            lng: "105.83489990234",
          },
          {
            id: "3030",
            name: "Mekong City",
            lat: "10.033302307129",
            lng: "105.82636260986",
          },
        ],
      },
      {
        id: "486",
        name: "B\u00ecnh T\u00e2n",
        wards: [
          { id: "7768", name: "M\u1ef9 Thu\u1eadn", prefix: "X\u00e3" },
          {
            id: "7769",
            name: "Nguy\u1ec5n V\u0103n Th\u1ea3nh",
            prefix: "X\u00e3",
          },
          { id: "7770", name: "T\u00e2n An Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "7771", name: "T\u00e2n B\u00ecnh", prefix: "X\u00e3" },
          { id: "7772", name: "T\u00e2n H\u01b0ng", prefix: "X\u00e3" },
          { id: "7773", name: "T\u00e2n L\u01b0\u1ee3c", prefix: "X\u00e3" },
          {
            id: "7774",
            name: "T\u00e2n Qu\u1edbi",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7775", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "7776", name: "Th\u00e0nh \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "7777", name: "Th\u00e0nh L\u1ee3i", prefix: "X\u00e3" },
          { id: "7778", name: "Th\u00e0nh Trung", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28689", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28690",
            name: "Khi\u1ebfu N\u0103ng T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28691",
            name: "L\u00ea C\u00f4ng Mi\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28692",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28693",
            name: "Qu\u1ed1c l\u1ed9 54",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28694",
            name: "V\u00f5 \u0110\u00ecnh T\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28695",
            name: "V\u00f5 Trung Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "487",
        name: "Long H\u1ed3",
        wards: [
          { id: "7779", name: "An B\u00ecnh", prefix: "X\u00e3" },
          {
            id: "7780",
            name: "B\u00ecnh Ho\u00e0 Ph\u01b0\u1edbc",
            prefix: "X\u00e3",
          },
          { id: "7781", name: "\u0110\u1ed3ng Ph\u00fa", prefix: "X\u00e3" },
          { id: "7782", name: "Ho\u00e0 Ninh", prefix: "X\u00e3" },
          { id: "7783", name: "Ho\u00e0 Ph\u00fa", prefix: "X\u00e3" },
          { id: "7784", name: "L\u1ed9c Ho\u00e0", prefix: "X\u00e3" },
          { id: "7785", name: "Long An", prefix: "X\u00e3" },
          { id: "7786", name: "Long H\u1ed3", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "7787", name: "Long Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "7788", name: "Ph\u00fa \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "7789", name: "Ph\u00fa Qu\u1edbi", prefix: "X\u00e3" },
          { id: "7790", name: "Ph\u01b0\u1edbc H\u1eadu", prefix: "X\u00e3" },
          { id: "7791", name: "T\u00e2n H\u1ea1nh", prefix: "X\u00e3" },
          { id: "7792", name: "Thanh \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "7793", name: "Th\u1ea1nh Qu\u1edbi", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28696", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28697", name: "Ba Lan", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28698",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28699",
            name: "Ph\u01b0\u1edbc H\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28700",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28701",
            name: "Qu\u1ed1c L\u1ed9 53",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28702",
            name: "Qu\u1ed1c L\u1ed9 57",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28703", name: "S\u1ed1 30A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28704",
            name: "T\u1ec9nh l\u1ed9 902",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28705",
            name: "T\u1ec9nh l\u1ed9 909",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "488",
        name: "Mang Th\u00edt",
        wards: [
          { id: "7794", name: "An Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "7795", name: "B\u00ecnh Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "7796", name: "C\u00e1i Nhum", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "7797", name: "Ch\u00e1nh An", prefix: "X\u00e3" },
          { id: "7798", name: "Ch\u00e1nh H\u1ed9i", prefix: "X\u00e3" },
          { id: "7799", name: "Ho\u00e0 T\u1ecbnh", prefix: "X\u00e3" },
          { id: "7800", name: "Long M\u1ef9", prefix: "X\u00e3" },
          { id: "7801", name: "M\u1ef9 An", prefix: "X\u00e3" },
          { id: "7802", name: "M\u1ef9 Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "7803", name: "Nh\u01a1n Ph\u00fa", prefix: "X\u00e3" },
          { id: "7804", name: "T\u00e2n An H\u1ed9i", prefix: "X\u00e3" },
          { id: "7805", name: "T\u00e2n Long", prefix: "X\u00e3" },
          { id: "7806", name: "T\u00e2n Long H\u1ed9i", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28706", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28707",
            name: "T\u1ec9nh l\u1ed9 902",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28708",
            name: "T\u1ec9nh l\u1ed9 903",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28709",
            name: "T\u1ec9nh l\u1ed9 904",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "489",
        name: "Tam B\u00ecnh",
        wards: [
          { id: "7807", name: "B\u00ecnh Ninh", prefix: "X\u00e3" },
          { id: "7808", name: "H\u1eadu L\u1ed9c", prefix: "X\u00e3" },
          { id: "7809", name: "H\u00f2a Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "7810", name: "H\u00f2a L\u1ed9c", prefix: "X\u00e3" },
          { id: "7811", name: "H\u00f2a Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "7812", name: "Loan M\u1ef9", prefix: "X\u00e3" },
          { id: "7813", name: "Long Ph\u00fa", prefix: "X\u00e3" },
          { id: "7814", name: "M\u1ef9 L\u1ed9c", prefix: "X\u00e3" },
          { id: "7815", name: "M\u1ef9 Th\u1ea1nh Trung", prefix: "X\u00e3" },
          { id: "7816", name: "Ng\u00e3i T\u1ee9", prefix: "X\u00e3" },
          { id: "7817", name: "Ph\u00fa L\u1ed9c", prefix: "X\u00e3" },
          { id: "7818", name: "Ph\u00fa Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "7819", name: "Song Ph\u00fa", prefix: "X\u00e3" },
          { id: "7820", name: "Tam B\u00ecnh", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "7821", name: "T\u00e2n L\u1ed9c", prefix: "X\u00e3" },
          { id: "7822", name: "T\u00e2n Ph\u00fa", prefix: "X\u00e3" },
          { id: "7823", name: "T\u01b0\u1eddng L\u1ed9c", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28710", name: "909", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28711",
            name: "H\u01b0\u01a1ng L\u1ed9 26/3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28712",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28713",
            name: "Qu\u1ed1c L\u1ed9 53",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28714",
            name: "Qu\u1ed1c l\u1ed9 54",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28715",
            name: "T\u1ec9nh l\u1ed9 904",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28716",
            name: "T\u1ec9nh L\u1ed9 905",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28717",
            name: "V\u00f5 T\u1ea5n \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "490",
        name: "Tr\u00e0 \u00d4n",
        wards: [
          { id: "7824", name: "Ho\u00e0 B\u00ecnh", prefix: "X\u00e3" },
          { id: "7825", name: "H\u1ef1u Th\u00e0nh", prefix: "X\u00e3" },
          {
            id: "7826",
            name: "L\u1ee5c S\u0129 Th\u00e0nh",
            prefix: "X\u00e3",
          },
          { id: "7827", name: "Nh\u01a1n B\u00ecnh", prefix: "X\u00e3" },
          { id: "7828", name: "Ph\u00fa Th\u00e0nh", prefix: "X\u00e3" },
          { id: "7829", name: "T\u00e2n M\u1ef9", prefix: "X\u00e3" },
          { id: "7830", name: "Thi\u1ec7n M\u1ef9", prefix: "X\u00e3" },
          { id: "7831", name: "Th\u1edbi Ho\u00e0", prefix: "X\u00e3" },
          { id: "7832", name: "Thu\u1eadn Th\u1edbi", prefix: "X\u00e3" },
          { id: "7833", name: "T\u00edch Thi\u1ec7n", prefix: "X\u00e3" },
          { id: "7834", name: "Tr\u00e0 C\u00f4n", prefix: "X\u00e3" },
          {
            id: "7835",
            name: "Tr\u00e0 \u00d4n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7836", name: "V\u0129nh Xu\u00e2n", prefix: "X\u00e3" },
          { id: "7837", name: "Xu\u00e2n Hi\u1ec7p", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "28718",
            name: "Qu\u1ed1c l\u1ed9 54",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28719",
            name: "T\u1ec9nh l\u1ed9 901",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28720",
            name: "T\u1ec9nh l\u1ed9 907",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "491",
        name: "V\u0129nh Long",
        wards: [
          { id: "7838", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "7839", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "7840", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "7841", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "7842", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "7843", name: "8", prefix: "Ph\u01b0\u1eddng" },
          { id: "7844", name: "9", prefix: "Ph\u01b0\u1eddng" },
          { id: "7845", name: "T\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "7846", name: "T\u00e2n H\u1ed9i", prefix: "X\u00e3" },
          { id: "7847", name: "T\u00e2n Ng\u00e3i", prefix: "X\u00e3" },
          { id: "7848", name: "Tr\u01b0\u1eddng An", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28721", name: "1/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28722", name: "14/9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28723", name: "2/9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28724", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28725", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28726", name: "520", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28727", name: "53", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28728", name: "57", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28729", name: "8/3", prefix: "Ph\u1ed1" },
          { id: "28730", name: "905", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28731",
            name: "B\u1ea1ch \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28732",
            name: "B\u1edd K\u00eanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28733",
            name: "C\u00e0 D\u0103m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28734",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28735",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28736",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28737",
            name: "Ho\u00e0ng Th\u00e1i Hi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28738",
            name: "H\u01b0ng \u0110\u1ea1o V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28739",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28740", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28741",
            name: "L\u00ea Minh H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28742",
            name: "L\u00ea Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28743",
            name: "L\u00ea V\u0103n T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28744",
            name: "L\u01b0u V\u0103n Li\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28745",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "28746",
            name: "M\u1eadu Th\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28747",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28748",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28750",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28749",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28751",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28752",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28753",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28754",
            name: "Nguy\u1ec5n V\u0103n L\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28755",
            name: "Nguy\u1ec5n V\u0103n Thi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28756", name: "\u00d4ng Phu", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28757",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28758",
            name: "Ph\u1ea1m Th\u00e1i B\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28759",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28760",
            name: "Phan V\u0103n \u0110\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28761",
            name: "Ph\u00f3 C\u01a1 \u0110i\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28762",
            name: "Ph\u01b0\u1edbc Ng\u01b0\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28763",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28764",
            name: "Qu\u1ed1c L\u1ed9 53",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28765",
            name: "Qu\u1ed1c l\u1ed9 80",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28766",
            name: "Qu\u1ed1c l\u1ed9 80B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28767", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28768",
            name: "Ti\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28769",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "28770",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28771",
            name: "Tr\u01b0ng N\u1eef V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28772",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28773",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28774",
            name: "V\u01b0\u1eddn \u1ed4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3031",
            name: "Vincom V\u0129nh Long",
            lat: "10.24715423584",
            lng: "105.97634124756",
          },
        ],
      },
      {
        id: "492",
        name: "V\u0169ng Li\u00eam",
        wards: [
          { id: "7849", name: "Hi\u1ebfu Ngh\u0129a", prefix: "X\u00e3" },
          { id: "7850", name: "Hi\u1ebfu Nh\u01a1n", prefix: "X\u00e3" },
          { id: "7851", name: "Hi\u1ebfu Ph\u1ee5ng", prefix: "X\u00e3" },
          { id: "7852", name: "Hi\u1ebfu Th\u00e0nh", prefix: "X\u00e3" },
          { id: "7853", name: "Hi\u1ebfu Thu\u1eadn", prefix: "X\u00e3" },
          { id: "7854", name: "Qu\u1edbi An", prefix: "X\u00e3" },
          { id: "7855", name: "Qu\u1edbi Thi\u1ec7n", prefix: "X\u00e3" },
          { id: "7856", name: "T\u00e2n An Lu\u00f4ng", prefix: "X\u00e3" },
          { id: "7857", name: "T\u00e2n Qu\u1edbi Trung", prefix: "X\u00e3" },
          { id: "7858", name: "Thanh B\u00ecnh", prefix: "X\u00e3" },
          { id: "7859", name: "Trung An", prefix: "X\u00e3" },
          { id: "7860", name: "Trung Ch\u00e1nh", prefix: "X\u00e3" },
          { id: "7861", name: "Trung Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "7862", name: "Trung Hi\u1ebfu", prefix: "X\u00e3" },
          { id: "7863", name: "Trung Ng\u00e3i", prefix: "X\u00e3" },
          { id: "7864", name: "Trung Ngh\u0129a", prefix: "X\u00e3" },
          { id: "7865", name: "Trung Th\u00e0nh", prefix: "X\u00e3" },
          {
            id: "7866",
            name: "Trung Th\u00e0nh \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "7867", name: "Trung Th\u00e0nh T\u00e2y", prefix: "X\u00e3" },
          {
            id: "7868",
            name: "V\u0169ng Li\u00eam",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          { id: "28775", name: "907", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28776",
            name: "Qu\u1ed1c L\u1ed9 53",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28777",
            name: "T\u1ec9nh l\u1ed9 907",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "41",
    code: "NB",
    name: "Ninh B\u00ecnh",
    districts: [
      {
        id: "493",
        name: "Gia Vi\u1ec5n",
        wards: [
          { id: "7869", name: "Gia Ho\u00e0", prefix: "X\u00e3" },
          { id: "7870", name: "Gia H\u01b0ng", prefix: "X\u00e3" },
          { id: "7871", name: "Gia L\u1ea1c", prefix: "X\u00e3" },
          { id: "7872", name: "Gia L\u1eadp", prefix: "X\u00e3" },
          { id: "7873", name: "Gia Minh", prefix: "X\u00e3" },
          { id: "7874", name: "Gia Phong", prefix: "X\u00e3" },
          { id: "7875", name: "Gia Ph\u00fa", prefix: "X\u00e3" },
          { id: "7876", name: "Gia Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "7877", name: "Gia Sinh", prefix: "X\u00e3" },
          { id: "7878", name: "Gia T\u00e2n", prefix: "X\u00e3" },
          { id: "7879", name: "Gia Th\u1eafng", prefix: "X\u00e3" },
          { id: "7880", name: "Gia Thanh", prefix: "X\u00e3" },
          { id: "7881", name: "Gia Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "7882", name: "Gia Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "7883", name: "Gia Tr\u1ea5n", prefix: "X\u00e3" },
          { id: "7884", name: "Gia Trung", prefix: "X\u00e3" },
          { id: "7885", name: "Gia V\u00e2n", prefix: "X\u00e3" },
          { id: "7886", name: "Gia V\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "7887", name: "Gia Xu\u00e2n", prefix: "X\u00e3" },
          { id: "7888", name: "Li\u00ean S\u01a1n", prefix: "X\u00e3" },
          { id: "7889", name: "Me", prefix: "Th\u1ecb tr\u1ea5n" },
        ],
        streets: [
          { id: "28778", name: "477", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28779", name: "\u0110T 477B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28780",
            name: "Ki\u1ebfn Y\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28781",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "494",
        name: "Hoa L\u01b0",
        wards: [
          { id: "7890", name: "Ninh An", prefix: "X\u00e3" },
          { id: "7891", name: "Ninh Giang", prefix: "X\u00e3" },
          { id: "7892", name: "Ninh H\u1ea3i", prefix: "X\u00e3" },
          { id: "7893", name: "Ninh H\u00f2a", prefix: "X\u00e3" },
          { id: "7894", name: "Ninh Khang", prefix: "X\u00e3" },
          { id: "7895", name: "Ninh M\u1ef9", prefix: "X\u00e3" },
          { id: "7896", name: "Ninh Th\u1eafng", prefix: "X\u00e3" },
          { id: "7897", name: "Ninh V\u00e2n", prefix: "X\u00e3" },
          { id: "7898", name: "Ninh Xu\u00e2n", prefix: "X\u00e3" },
          {
            id: "7899",
            name: "Thi\u00ean T\u00f4n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7900", name: "Tr\u01b0\u1eddng Y\u00ean", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28782", name: "12C", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28783", name: "1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28784", name: "AH1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28785",
            name: "Ph\u1ea1m C\u1ef1 L\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28786",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28787",
            name: "T\u00e2y B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28788", name: "T\u00e2y Nam", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28789",
            name: "Thi\u00ean S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28790",
            name: "Xu\u00e2n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "495",
        name: "Kim S\u01a1n",
        wards: [
          { id: "7901", name: "\u00c2n H\u00f2a", prefix: "X\u00e3" },
          { id: "7902", name: "B\u00ecnh Minh", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "7903", name: "Ch\u1ea5t B\u00ecnh", prefix: "X\u00e3" },
          { id: "7904", name: "Ch\u00ednh T\u00e2m", prefix: "X\u00e3" },
          { id: "7905", name: "Con Thoi", prefix: "X\u00e3" },
          { id: "7906", name: "\u0110\u1ecbnh Ho\u00e1", prefix: "X\u00e3" },
          {
            id: "7907",
            name: "\u0110\u1ed3ng H\u01b0\u1edbng",
            prefix: "X\u00e3",
          },
          { id: "7908", name: "H\u1ed3i Ninh", prefix: "X\u00e3" },
          { id: "7909", name: "H\u00f9ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "7910", name: "Kim Ch\u00ednh", prefix: "X\u00e3" },
          { id: "7911", name: "Kim \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "7912", name: "Kim \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "7913", name: "Kim H\u1ea3i", prefix: "X\u00e3" },
          { id: "7914", name: "Kim M\u1ef9", prefix: "X\u00e3" },
          { id: "7915", name: "Kim T\u00e2n", prefix: "X\u00e3" },
          { id: "7916", name: "Kim Trung", prefix: "X\u00e3" },
          { id: "7917", name: "Lai Th\u00e0nh", prefix: "X\u00e3" },
          { id: "7918", name: "L\u01b0u Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "7919", name: "Nh\u01b0 H\u00f2a", prefix: "X\u00e3" },
          {
            id: "7920",
            name: "Ph\u00e1t Di\u1ec7m",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "7921", name: "Quang Thi\u1ec7n", prefix: "X\u00e3" },
          { id: "7922", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "7923", name: "Th\u01b0\u1ee3ng Ki\u1ec7m", prefix: "X\u00e3" },
          { id: "7924", name: "V\u0103n H\u1ea3i", prefix: "X\u00e3" },
          { id: "7925", name: "Xu\u00e2n Thi\u1ec7n", prefix: "X\u00e3" },
          { id: "7926", name: "Y\u00ean L\u1ed9c", prefix: "X\u00e3" },
          { id: "7927", name: "Y\u00ean M\u1eadt", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28791", name: "481", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28792", name: "C\u00e0 Mau", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28793",
            name: "Ph\u00fa Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "496",
        name: "Nho Quan",
        wards: [
          { id: "7928", name: "C\u00fac Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "7929", name: "\u0110\u1ed3ng Phong", prefix: "X\u00e3" },
          { id: "7930", name: "\u0110\u1ee9c Long", prefix: "X\u00e3" },
          { id: "7931", name: "Gia L\u00e2m", prefix: "X\u00e3" },
          { id: "7932", name: "Gia S\u01a1n", prefix: "X\u00e3" },
          { id: "7933", name: "Gia Th\u1ee7y", prefix: "X\u00e3" },
          { id: "7934", name: "Gia T\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "7935", name: "K\u1ef3 Ph\u00fa", prefix: "X\u00e3" },
          { id: "7936", name: "L\u1ea1c V\u00e2n", prefix: "X\u00e3" },
          { id: "7937", name: "L\u1ea1ng Phong", prefix: "X\u00e3" },
          { id: "7938", name: "Nho Quan", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "7939", name: "Ph\u00fa L\u1ed9c", prefix: "X\u00e3" },
          { id: "7940", name: "Ph\u00fa Long", prefix: "X\u00e3" },
          { id: "7941", name: "Ph\u00fa S\u01a1n", prefix: "X\u00e3" },
          { id: "7942", name: "Qu\u1ea3ng L\u1ea1c", prefix: "X\u00e3" },
          { id: "7943", name: "Qu\u1ef3nh L\u01b0u", prefix: "X\u00e3" },
          { id: "7944", name: "S\u01a1n H\u00e0", prefix: "X\u00e3" },
          { id: "7945", name: "S\u01a1n Lai", prefix: "X\u00e3" },
          { id: "7946", name: "S\u01a1n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "7947", name: "Th\u1ea1ch B\u00ecnh", prefix: "X\u00e3" },
          { id: "7948", name: "Thanh L\u1ea1c", prefix: "X\u00e3" },
          { id: "7949", name: "Th\u01b0\u1ee3ng Ho\u00e0", prefix: "X\u00e3" },
          { id: "7950", name: "V\u0103n Phong", prefix: "X\u00e3" },
          { id: "7951", name: "V\u0103n Ph\u00fa", prefix: "X\u00e3" },
          { id: "7952", name: "V\u0103n Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "7953", name: "X\u00edch Th\u1ed5", prefix: "X\u00e3" },
          { id: "7954", name: "Y\u00ean Quang", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28794", name: "Li\u00eau Trung", prefix: "Ph\u1ed1" },
          {
            id: "28795",
            name: "Qu\u1ed1c L\u1ed9 12B",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3032",
            name: "CucPhuong Villas",
            lat: "20.296779632568",
            lng: "105.65041351318",
          },
        ],
      },
      {
        id: "497",
        name: "Ninh B\u00ecnh",
        wards: [
          {
            id: "7955",
            name: "B\u00edch \u0110\u00e0o",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "7956",
            name: "\u0110\u00f4ng Th\u00e0nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "7957", name: "Nam B\u00ecnh", prefix: "Ph\u01b0\u1eddng" },
          { id: "7958", name: "Nam Th\u00e0nh", prefix: "Ph\u01b0\u1eddng" },
          { id: "7959", name: "Ninh Kh\u00e1nh", prefix: "Ph\u01b0\u1eddng" },
          { id: "7960", name: "Ninh nh\u1ea5t", prefix: "X\u00e3" },
          { id: "7961", name: "Ninh Phong", prefix: "Ph\u01b0\u1eddng" },
          { id: "7962", name: "Ninh Ph\u00fac", prefix: "X\u00e3" },
          { id: "7963", name: "Ninh S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "7964", name: "Ninh Ti\u1ebfn", prefix: "X\u00e3" },
          {
            id: "7965",
            name: "Ph\u00fac Th\u00e0nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "7966",
            name: "T\u00e2n Th\u00e0nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "7967", name: "Thanh B\u00ecnh", prefix: "Ph\u01b0\u1eddng" },
          { id: "7968", name: "V\u00e2n Giang", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "28796", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28797", name: "1A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28798", name: "27/7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28799", name: "30/6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28800", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28801", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28802",
            name: "B\u1eafc Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28803",
            name: "B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28804",
            name: "B\u1eafc Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28805",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28806",
            name: "B\u00edch \u0110\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28807",
            name: "B\u00edch S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28808",
            name: "B\u00ecnh H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28809",
            name: "B\u00ecnh H\u00f2a 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28810",
            name: "B\u00ecnh Khang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28811",
            name: "B\u00ecnh Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28812",
            name: "B\u00ecnh Y\u00ean 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28813",
            name: "B\u00ecnh Y\u00ean T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28814",
            name: "C\u00e1t Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28815",
            name: "Chi\u1ebfn Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28816",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28817",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28827",
            name: "D\u01b0\u01a1ng V\u00e2n Nga",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28818",
            name: "\u0110\u1ea1i Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28819",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28820",
            name: "\u0110inh \u0110i\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28821",
            name: "\u0110inh T\u1ea5t Mi\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28822",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28823",
            name: "\u0110\u00f4ng H\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28824",
            name: "\u0110\u00f4ng Ph\u01b0\u01a1ng H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28825",
            name: "\u0110\u00f4ng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28826", name: "\u0110T 477", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28828",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28829",
            name: "H\u00e0n Thuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28830",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28831",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28832",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28833",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28834",
            name: "H\u1ee3p Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28835",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28836",
            name: "Kh\u00e1nh B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28837",
            name: "Kh\u00e1nh T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28838",
            name: "Ki\u1ebfn Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28839", name: "Kim \u0110a", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28840",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28841",
            name: "L\u00e3m Tr\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28842",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28843",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28844",
            name: "L\u00ea Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28845",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28846",
            name: "L\u01b0\u01a1ng V\u0103n Th\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28847",
            name: "L\u01b0\u01a1ng V\u0103n Tuy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28848",
            name: "L\u01b0u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28849",
            name: "L\u00fd Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28850",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28851",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28852",
            name: "Nam B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28853",
            name: "Nam Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28854",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28855",
            name: "Ng\u1ecdc H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28856",
            name: "Ng\u1ecdc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28857",
            name: "Ng\u1ecdc Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28858",
            name: "Nguy\u1ec5n B\u1eb7c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28859",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28860",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28861",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28862",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28863",
            name: "Nguy\u1ec5n H\u1eefu An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28864",
            name: "Nguy\u1ec5n T\u1ea5t Mi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28865",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28866",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28867",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28868",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28869",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28870",
            name: "Ninh Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28871",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28872",
            name: "Ph\u1ea1m Th\u1eadn Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28873",
            name: "Ph\u1ea1m V\u0103n Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28874",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28875",
            name: "Phong \u1ea4p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28876",
            name: "Phong \u0110\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28877",
            name: "Ph\u00fa S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28878",
            name: "Ph\u00fa Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28879",
            name: "Ph\u00fac Ch\u1ec9nh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28880",
            name: "Ph\u00fac H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28881",
            name: "Ph\u00fac Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28882", name: "Ph\u00fac L\u1ed9c", prefix: "Ph\u1ed1" },
          {
            id: "28883",
            name: "Ph\u00fac Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28884",
            name: "Ph\u00fac T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28885",
            name: "Ph\u00fac Th\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28886", name: "Ph\u00fac Tr\u00ec", prefix: "Ph\u1ed1" },
          { id: "28887", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "28888",
            name: "Ph\u01b0\u01a1ng \u0110\u00ecnh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "28889",
            name: "Qu\u1ea3ng Tr\u01b0\u1eddng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28890",
            name: "Qu\u1ed1c L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28891",
            name: "Qu\u1ed1c L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28892",
            name: "Qu\u1ed1c L\u1ed9 12B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28893",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28894",
            name: "Qu\u1ed1c l\u1ed9 1B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28895", name: "T25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28896", name: "T\u00e2n An", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28897",
            name: "T\u00e2n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28898",
            name: "T\u00e2n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28899",
            name: "T\u00e2n Th\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28900",
            name: "T\u00e2n Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28901",
            name: "T\u00e2y Th\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28902",
            name: "Th\u00e0nh C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28903",
            name: "Thi\u1ec7n T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28904",
            name: "Th\u01b0\u1ee3ng L\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28905",
            name: "Ti\u1ebfn Y\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28906",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "28907",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28908",
            name: "Tr\u1ea7n Ki\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28909",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28910",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28911",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28912",
            name: "Tr\u00e0ng An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28913",
            name: "Tri\u1ec7u Vi\u1ec7t V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28914",
            name: "Tr\u1ecbnh T\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28915", name: "Trung S\u01a1n", prefix: "Ph\u1ed1" },
          {
            id: "28916",
            name: "Trung Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28917",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28918",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28919",
            name: "V\u00e2n Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28920",
            name: "V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28921", name: "V\u0103n Mi\u1ebfu", prefix: "Ph\u1ed1" },
          {
            id: "28922",
            name: "V\u0103n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28923",
            name: "V\u1ea1n Th\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28924",
            name: "Vi\u1ec7t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28925",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28926",
            name: "V\u0169 Duy Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28927",
            name: "V\u0169 Ph\u1ea1m Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28928",
            name: "Xu\u00e2n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28929",
            name: "Xu\u00e2n Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28930",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "498",
        name: "Tam \u0110i\u1ec7p",
        wards: [
          { id: "7969", name: "B\u1eafc S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "7970", name: "\u0110\u00f4ng S\u01a1n", prefix: "X\u00e3" },
          { id: "7971", name: "Nam S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "7972", name: "Quang S\u01a1n", prefix: "X\u00e3" },
          {
            id: "7973",
            name: "T\u00e2n B\u00ecnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "7974", name: "T\u00e2y S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "7975", name: "Trung S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "7976", name: "Y\u00ean B\u00ecnh", prefix: "X\u00e3" },
          { id: "7977", name: "Y\u00ean S\u01a1n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28931", name: "12B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28932",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28933",
            name: "\u0110\u1ed3ng Giao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28934",
            name: "H\u00e0ng B\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28935",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28936",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28937",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28938",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "28939",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28940",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "28941",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28942",
            name: "Ng\u00f4 Th\u00ec S\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28943",
            name: "Ng\u00f4 V\u0103n S\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28944",
            name: "N\u00fai V\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28945",
            name: "Quang S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28946", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28947",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28948",
            name: "Quy\u1ebft Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28949",
            name: "Thi\u00ean Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28950",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28951",
            name: "V\u1ea1n Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28952",
            name: "Xu\u00e2n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28953", name: "Z879", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
      {
        id: "499",
        name: "Y\u00ean Kh\u00e1nh",
        wards: [
          { id: "7978", name: "Kh\u00e1nh An", prefix: "X\u00e3" },
          { id: "7979", name: "Kh\u00e1nh C\u00f4ng", prefix: "X\u00e3" },
          { id: "7980", name: "Kh\u00e1nh C\u01b0", prefix: "X\u00e3" },
          { id: "7981", name: "Kh\u00e1nh C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "7982", name: "Kh\u00e1nh H\u1ea3i", prefix: "X\u00e3" },
          { id: "7983", name: "Kh\u00e1nh H\u00f2a", prefix: "X\u00e3" },
          { id: "7984", name: "Kh\u00e1nh H\u1ed9i", prefix: "X\u00e3" },
          { id: "7985", name: "Kh\u00e1nh H\u1ed3ng", prefix: "X\u00e3" },
          { id: "7986", name: "Kh\u00e1nh L\u1ee3i", prefix: "X\u00e3" },
          { id: "7987", name: "Kh\u00e1nh M\u1eadu", prefix: "X\u00e3" },
          { id: "7988", name: "Kh\u00e1nh Nh\u1ea1c", prefix: "X\u00e3" },
          { id: "7989", name: "Kh\u00e1nh Ph\u00fa", prefix: "X\u00e3" },
          { id: "7990", name: "Kh\u00e1nh Th\u00e0nh", prefix: "X\u00e3" },
          { id: "7991", name: "Kh\u00e1nh Thi\u1ec7n", prefix: "X\u00e3" },
          { id: "7992", name: "Kh\u00e1nh Th\u1ee7y", prefix: "X\u00e3" },
          { id: "7993", name: "Kh\u00e1nh Ti\u00ean", prefix: "X\u00e3" },
          { id: "7994", name: "Kh\u00e1nh Trung", prefix: "X\u00e3" },
          { id: "7995", name: "Kh\u00e1nh V\u00e2n", prefix: "X\u00e3" },
          { id: "7996", name: "Y\u00ean Ninh", prefix: "Th\u1ecb tr\u1ea5n" },
        ],
        streets: [
          { id: "28954", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28955", name: "58", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28956",
            name: "Qu\u1ed1c L\u1ed9 10",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "500",
        name: "Y\u00ean M\u00f4",
        wards: [
          { id: "7997", name: "Kh\u00e1nh D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "7998", name: "Kh\u00e1nh Th\u1ecbnh", prefix: "X\u00e3" },
          {
            id: "7999",
            name: "Kh\u00e1nh Th\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
          { id: "8000", name: "Mai S\u01a1n", prefix: "X\u00e3" },
          { id: "8001", name: "Y\u00ean \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "8002", name: "Y\u00ean H\u00f2a", prefix: "X\u00e3" },
          { id: "8003", name: "Y\u00ean H\u01b0ng", prefix: "X\u00e3" },
          { id: "8004", name: "Y\u00ean L\u00e2m", prefix: "X\u00e3" },
          { id: "8005", name: "Y\u00ean M\u1ea1c", prefix: "X\u00e3" },
          { id: "8006", name: "Y\u00ean M\u1ef9", prefix: "X\u00e3" },
          { id: "8007", name: "Y\u00ean Nh\u00e2n", prefix: "X\u00e3" },
          { id: "8008", name: "Y\u00ean Phong", prefix: "X\u00e3" },
          { id: "8009", name: "Y\u00ean Ph\u00fa", prefix: "X\u00e3" },
          { id: "8010", name: "Y\u00ean Th\u00e1i", prefix: "X\u00e3" },
          { id: "8011", name: "Y\u00ean Th\u1eafng", prefix: "X\u00e3" },
          { id: "8012", name: "Y\u00ean Th\u00e0nh", prefix: "X\u00e3" },
          {
            id: "8013",
            name: "Y\u00ean Th\u1ecbnh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8014", name: "Y\u00ean T\u1eeb", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "28957",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28958",
            name: "T\u1ec9nh L\u1ed9 480",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "42",
    code: "PT",
    name: "Ph\u00fa Th\u1ecd",
    districts: [
      {
        id: "501",
        name: "C\u1ea9m Kh\u00ea",
        wards: [
          { id: "8015", name: "C\u1ea5p D\u1eabn", prefix: "X\u00e3" },
          { id: "8016", name: "C\u00e1t Tr\u00f9", prefix: "X\u00e3" },
          { id: "8017", name: "Ch\u01b0\u01a1ng X\u00e1", prefix: "X\u00e3" },
          {
            id: "8018",
            name: "\u0110i\u00eau L\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "8019", name: "\u0110\u1ed3ng Cam", prefix: "X\u00e3" },
          {
            id: "8020",
            name: "\u0110\u1ed3ng L\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "8021", name: "Hi\u1ec1n \u0110a", prefix: "X\u00e3" },
          { id: "8022", name: "H\u01b0\u01a1ng Lung", prefix: "X\u00e3" },
          { id: "8023", name: "Ng\u00f4 X\u00e1", prefix: "X\u00e3" },
          { id: "8024", name: "Ph\u00fa Kh\u00ea", prefix: "X\u00e3" },
          { id: "8025", name: "Ph\u00fa L\u1ea1c", prefix: "X\u00e3" },
          { id: "8026", name: "Ph\u00f9ng X\u00e1", prefix: "X\u00e3" },
          { id: "8027", name: "Ph\u01b0\u1ee3ng V\u0129", prefix: "X\u00e3" },
          { id: "8028", name: "Ph\u01b0\u01a1ng X\u00e1", prefix: "X\u00e3" },
          { id: "8029", name: "Sai Nga", prefix: "X\u00e3" },
          { id: "8030", name: "S\u01a1n Nga", prefix: "X\u00e3" },
          { id: "8031", name: "S\u01a1n T\u1ecbnh", prefix: "X\u00e3" },
          { id: "8032", name: "S\u00f4ng Thao", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "8033", name: "T\u1ea1 X\u00e1", prefix: "X\u00e3" },
          { id: "8034", name: "Tam S\u01a1n", prefix: "X\u00e3" },
          { id: "8035", name: "Thanh Nga", prefix: "X\u00e3" },
          { id: "8036", name: "Th\u1ee5y Li\u1ec5u", prefix: "X\u00e3" },
          { id: "8037", name: "Ti\u00ean L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8038", name: "T\u00ecnh C\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8039", name: "T\u00f9ng Kh\u00ea", prefix: "X\u00e3" },
          { id: "8040", name: "Tuy L\u1ed9c", prefix: "X\u00e3" },
          { id: "8041", name: "V\u0103n B\u00e1n", prefix: "X\u00e3" },
          { id: "8042", name: "V\u0103n Kh\u00fac", prefix: "X\u00e3" },
          { id: "8043", name: "X\u01b0\u01a1ng Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "8044", name: "Y\u00ean D\u01b0\u1ee1ng", prefix: "X\u00e3" },
          { id: "8045", name: "Y\u00ean T\u1eadp", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "28959",
            name: "Qu\u1ed1c L\u1ed9 32C",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "502",
        name: "\u0110oan H\u00f9ng",
        wards: [
          { id: "8046", name: "B\u1eb1ng Do\u00e3n", prefix: "X\u00e3" },
          { id: "8047", name: "B\u1eb1ng Lu\u00e2n", prefix: "X\u00e3" },
          { id: "8048", name: "Ca \u0110\u00ecnh", prefix: "X\u00e3" },
          { id: "8049", name: "Ch\u00e2n M\u1ed9ng", prefix: "X\u00e3" },
          { id: "8050", name: "Ch\u00ed \u0110\u00e1m", prefix: "X\u00e3" },
          { id: "8051", name: "\u0110\u1ea1i Ngh\u0129a", prefix: "X\u00e3" },
          {
            id: "8052",
            name: "\u0110oan H\u00f9ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8053", name: "\u0110\u00f4ng Kh\u00ea", prefix: "X\u00e3" },
          { id: "8054", name: "H\u00f9ng Long", prefix: "X\u00e3" },
          { id: "8055", name: "H\u00f9ng Quan", prefix: "X\u00e3" },
          { id: "8056", name: "H\u1eefu \u0110\u00f4", prefix: "X\u00e3" },
          { id: "8057", name: "Minh L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8058", name: "Minh Ph\u00fa", prefix: "X\u00e3" },
          { id: "8059", name: "Minh Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "8060", name: "Nghinh Xuy\u00ean", prefix: "X\u00e3" },
          { id: "8061", name: "Ng\u1ecdc Quan", prefix: "X\u00e3" },
          { id: "8062", name: "Phong Ph\u00fa", prefix: "X\u00e3" },
          { id: "8063", name: "Ph\u00fa Th\u1ee9", prefix: "X\u00e3" },
          { id: "8064", name: "Ph\u00fac Lai", prefix: "X\u00e3" },
          { id: "8065", name: "Ph\u01b0\u01a1ng Trung", prefix: "X\u00e3" },
          { id: "8066", name: "Qu\u1ebf L\u00e2m", prefix: "X\u00e3" },
          { id: "8067", name: "S\u00f3c \u0110\u0103ng", prefix: "X\u00e3" },
          { id: "8068", name: "T\u00e2y C\u1ed1c", prefix: "X\u00e3" },
          { id: "8069", name: "Ti\u00eau S\u01a1n", prefix: "X\u00e3" },
          { id: "8071", name: "V\u00e2n Du", prefix: "X\u00e3" },
          { id: "8070", name: "V\u00e2n \u0110\u1ed3n", prefix: "X\u00e3" },
          { id: "8072", name: "V\u1ee5 Quang", prefix: "X\u00e3" },
          { id: "8073", name: "Y\u00ean Ki\u1ec7n", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "28960",
            name: "Qu\u1ed1c L\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "503",
        name: "H\u1ea1 H\u00f2a",
        wards: [
          { id: "8074", name: "\u1ea4m H\u1ea1", prefix: "X\u00e3" },
          { id: "8075", name: "B\u1eb1ng Gi\u00e3", prefix: "X\u00e3" },
          { id: "8076", name: "C\u00e1o \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "8077", name: "Ch\u00ednh C\u00f4ng", prefix: "X\u00e3" },
          { id: "8078", name: "Chu\u1ebf L\u01b0u", prefix: "X\u00e3" },
          { id: "8079", name: "\u0110\u1ea1i Ph\u1ea1m", prefix: "X\u00e3" },
          { id: "8080", name: "\u0110an H\u00e0", prefix: "X\u00e3" },
          { id: "8081", name: "\u0110an Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "8082", name: "\u0110\u1ed9ng L\u00e2m", prefix: "X\u00e3" },
          { id: "8083", name: "Gia \u0110i\u1ec1n", prefix: "X\u00e3" },
          {
            id: "8084",
            name: "H\u1ea1 H\u00f2a",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8085", name: "H\u00e0 L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8086", name: "H\u1eadu B\u1ed5ng", prefix: "X\u00e3" },
          { id: "8087", name: "Hi\u1ec1n L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8088", name: "H\u01b0\u01a1ng X\u1ea1", prefix: "X\u00e3" },
          { id: "8089", name: "L\u00e2m L\u1ee3i", prefix: "X\u00e3" },
          { id: "8090", name: "Lang S\u01a1n", prefix: "X\u00e3" },
          { id: "8091", name: "L\u1ec7nh Khanh", prefix: "X\u00e3" },
          { id: "8092", name: "Li\u00ean Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8093", name: "Mai T\u00f9ng", prefix: "X\u00e3" },
          { id: "8094", name: "Minh C\u00f4i", prefix: "X\u00e3" },
          { id: "8095", name: "Minh H\u1ea1c", prefix: "X\u00e3" },
          { id: "8096", name: "Ph\u1ee5 Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "8097", name: "Ph\u01b0\u01a1ng Vi\u00ean", prefix: "X\u00e3" },
          { id: "8098", name: "Qu\u00e2n Kh\u00ea", prefix: "X\u00e3" },
          { id: "8099", name: "V\u0103n Lang", prefix: "X\u00e3" },
          { id: "8100", name: "V\u0129nh Ch\u00e2n", prefix: "X\u00e3" },
          { id: "8101", name: "V\u00f4 Tranh", prefix: "X\u00e3" },
          { id: "8102", name: "V\u1ee5 C\u1ea7u", prefix: "X\u00e3" },
          { id: "8103", name: "Xu\u00e2n \u00c1ng", prefix: "X\u00e3" },
          { id: "8104", name: "Y S\u01a1n", prefix: "X\u00e3" },
          { id: "8105", name: "Y\u00ean K\u1ef3", prefix: "X\u00e3" },
          { id: "8106", name: "Y\u00ean Lu\u1eadt", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "28961",
            name: "T\u1ec9nh l\u1ed9 320",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "504",
        name: "L\u00e2m Thao",
        wards: [
          { id: "8107", name: "B\u1ea3n Nguy\u00ean", prefix: "X\u00e3" },
          { id: "8108", name: "Cao X\u00e1", prefix: "X\u00e3" },
          { id: "8109", name: "H\u1ee3p H\u1ea3i", prefix: "X\u00e3" },
          {
            id: "8110",
            name: "H\u00f9ng S\u01a1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8111", name: "Kinh K\u1ec7", prefix: "X\u00e3" },
          { id: "8112", name: "L\u00e2m Thao", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "8113", name: "S\u01a1n D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8114", name: "S\u01a1n Vi", prefix: "X\u00e3" },
          { id: "8115", name: "Th\u1ea1ch S\u01a1n", prefix: "X\u00e3" },
          { id: "8116", name: "Ti\u00ean Ki\u00ean", prefix: "X\u00e3" },
          { id: "8117", name: "T\u1ee9 X\u00e3", prefix: "X\u00e3" },
          { id: "8118", name: "V\u0129nh L\u1ea1i", prefix: "X\u00e3" },
          { id: "8119", name: "Xu\u00e2n Huy", prefix: "X\u00e3" },
          { id: "8120", name: "Xu\u00e2n L\u0169ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28962", name: "32C", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28963",
            name: "Qu\u1ed1c L\u1ed9 32C",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "505",
        name: "Ph\u00f9 Ninh",
        wards: [
          { id: "8121", name: "An \u0110\u1ea1o", prefix: "X\u00e3" },
          { id: "8122", name: "B\u1ea3o Thanh", prefix: "X\u00e3" },
          { id: "8123", name: "B\u00ecnh B\u1ed9", prefix: "X\u00e3" },
          { id: "8124", name: "Gia Thanh", prefix: "X\u00e3" },
          { id: "8125", name: "H\u1ea1 Gi\u00e1p", prefix: "X\u00e3" },
          { id: "8126", name: "L\u1ec7 M\u1ef9", prefix: "X\u00e3" },
          { id: "8127", name: "Li\u00ean Hoa", prefix: "X\u00e3" },
          { id: "8128", name: "Phong Ch\u00e2u", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "8129", name: "Ph\u00fa L\u1ed9c", prefix: "X\u00e3" },
          { id: "8130", name: "Ph\u00fa M\u1ef9", prefix: "X\u00e3" },
          { id: "8131", name: "Ph\u00fa Nham", prefix: "X\u00e3" },
          { id: "8132", name: "Ph\u00f9 Ninh", prefix: "X\u00e3" },
          { id: "8133", name: "Ti\u00ean Du", prefix: "X\u00e3" },
          { id: "8134", name: "Ti\u00ean Ph\u00fa", prefix: "X\u00e3" },
          { id: "8135", name: "Tr\u1ea1m Th\u1ea3n", prefix: "X\u00e3" },
          { id: "8136", name: "Tr\u1ecb Qu\u1eadn", prefix: "X\u00e3" },
          { id: "8137", name: "Trung Gi\u00e1p", prefix: "X\u00e3" },
          { id: "8138", name: "T\u1eed \u0110\u00e0", prefix: "X\u00e3" },
          { id: "8139", name: "V\u0129nh Ph\u00fa", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28964", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28965",
            name: "Qu\u1ed1c L\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "506",
        name: "Ph\u00fa Th\u1ecd",
        wards: [
          { id: "8140", name: "\u00c2u C\u01a1", prefix: "Ph\u01b0\u1eddng" },
          { id: "8141", name: "H\u00e0 L\u1ed9c", prefix: "X\u00e3" },
          { id: "8142", name: "H\u00e0 Th\u1ea1ch", prefix: "X\u00e3" },
          {
            id: "8143",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "8144", name: "Phong Ch\u00e2u", prefix: "Ph\u01b0\u1eddng" },
          { id: "8145", name: "Ph\u00fa H\u1ed9", prefix: "X\u00e3" },
          { id: "8146", name: "Thanh Minh", prefix: "X\u00e3" },
          { id: "8147", name: "Thanh Vinh", prefix: "X\u00e3" },
          {
            id: "8148",
            name: "Tr\u01b0\u1eddng Th\u1ecbnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "8149", name: "V\u0103n Lung", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "28966",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28967",
            name: "H\u00f2a Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28968",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28969",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28970",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28971",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28972",
            name: "Qu\u1ed1c L\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28973", name: "T\u00e2n Minh", prefix: "Ph\u1ed1" },
          {
            id: "28974",
            name: "T\u00e2n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28975", name: "Ti\u00ean Dung", prefix: "Ph\u1ed1" },
          {
            id: "28976",
            name: "T\u1ec9nh l\u1ed9 320",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28977",
            name: "Tr\u01b0\u1eddng Th\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "507",
        name: "Tam N\u00f4ng",
        wards: [
          { id: "8150", name: "C\u1ed5 Ti\u1ebft", prefix: "X\u00e3" },
          { id: "8151", name: "D\u1eadu D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8152", name: "D\u1ecb N\u1eadu", prefix: "X\u00e3" },
          { id: "8153", name: "Hi\u1ec1n Quan", prefix: "X\u00e3" },
          { id: "8154", name: "H\u1ed3ng \u0110\u00e0", prefix: "X\u00e3" },
          { id: "8155", name: "H\u00f9ng \u0110\u00f4", prefix: "X\u00e3" },
          {
            id: "8156",
            name: "H\u01b0ng H\u00f3a",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8157", name: "H\u01b0\u01a1ng Nha", prefix: "X\u00e3" },
          { id: "8158", name: "H\u01b0\u01a1ng N\u1ed9n", prefix: "X\u00e3" },
          {
            id: "8159",
            name: "Ph\u01b0\u01a1ng Th\u1ecbnh",
            prefix: "X\u00e3",
          },
          { id: "8160", name: "Quang H\u00fac", prefix: "X\u00e3" },
          { id: "8161", name: "Tam C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "8162", name: "T\u1ec1 L\u1ec5", prefix: "X\u00e3" },
          { id: "8163", name: "Thanh Uy\u00ean", prefix: "X\u00e3" },
          { id: "8164", name: "Th\u1ecd V\u0103n", prefix: "X\u00e3" },
          { id: "8165", name: "Th\u01b0\u1ee3ng N\u00f4ng", prefix: "X\u00e3" },
          { id: "8166", name: "T\u1ee9 M\u1ef9", prefix: "X\u00e3" },
          { id: "8167", name: "V\u0103n L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8168", name: "V\u1ef1c Tr\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "8169", name: "Xu\u00e2n Quang", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "28978",
            name: "Qu\u1ed1c l\u1ed9 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28979",
            name: "T\u1ec9nh L\u1ed9 315",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3033",
            name: "Tam N\u00f4ng Dream City",
            lat: "21.376691818237",
            lng: "105.27349853516",
          },
        ],
      },
      {
        id: "508",
        name: "T\u00e2n S\u01a1n",
        wards: [
          { id: "8170", name: "\u0110\u1ed3ng S\u01a1n", prefix: "X\u00e3" },
          { id: "8171", name: "Ki\u1ec7t S\u01a1n", prefix: "X\u00e3" },
          { id: "8172", name: "Kim Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "8173", name: "Lai \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "8174", name: "Long C\u1ed1c", prefix: "X\u00e3" },
          { id: "8175", name: "Minh \u0110\u00e0i", prefix: "X\u00e3" },
          { id: "8176", name: "M\u1ef9 Thu\u1eadn", prefix: "X\u00e3" },
          { id: "8177", name: "Tam Thanh", prefix: "X\u00e3" },
          { id: "8178", name: "T\u00e2n Ph\u00fa", prefix: "X\u00e3" },
          { id: "8179", name: "T\u00e2n S\u01a1n", prefix: "X\u00e3" },
          { id: "8180", name: "Th\u1ea1ch Ki\u1ec7t", prefix: "X\u00e3" },
          { id: "8181", name: "Thu C\u00fac", prefix: "X\u00e3" },
          { id: "8182", name: "Thu Ng\u1ea1c", prefix: "X\u00e3" },
          { id: "8183", name: "V\u0103n Lu\u00f4ng", prefix: "X\u00e3" },
          { id: "8184", name: "Vinh Ti\u1ec1n", prefix: "X\u00e3" },
          { id: "8185", name: "Xu\u00e2n \u0110\u00e0i", prefix: "X\u00e3" },
          { id: "8186", name: "Xu\u00e2n S\u01a1n", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "509",
        name: "Thanh Ba",
        wards: [
          { id: "8187", name: "Ch\u00ed Ti\u00ean", prefix: "X\u00e3" },
          { id: "8188", name: "\u0110\u1ea1i An", prefix: "X\u00e3" },
          { id: "8189", name: "\u0110\u1ed7 S\u01a1n", prefix: "X\u00e3" },
          { id: "8190", name: "\u0110\u1ed7 Xuy\u00ean", prefix: "X\u00e3" },
          { id: "8191", name: "\u0110\u00f4ng L\u0129nh", prefix: "X\u00e3" },
          { id: "8192", name: "\u0110\u00f4ng Th\u00e0nh", prefix: "X\u00e3" },
          { id: "8193", name: "\u0110\u1ed3ng Xu\u00e2n", prefix: "X\u00e3" },
          { id: "8194", name: "Hanh C\u00f9", prefix: "X\u00e3" },
          { id: "8195", name: "Ho\u00e0ng C\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8196", name: "Kh\u1ea3i Xu\u00e2n", prefix: "X\u00e3" },
          { id: "8197", name: "L\u01b0\u01a1ng L\u1ed7", prefix: "X\u00e3" },
          { id: "8198", name: "M\u1ea1n L\u1ea1n", prefix: "X\u00e3" },
          { id: "8199", name: "N\u0103ng Y\u00ean", prefix: "X\u00e3" },
          { id: "8200", name: "Ninh D\u00e2n", prefix: "X\u00e3" },
          { id: "8201", name: "Ph\u01b0\u01a1ng L\u0129nh", prefix: "X\u00e3" },
          { id: "8202", name: "Qu\u1ea3ng N\u1ea1p", prefix: "X\u00e3" },
          { id: "8203", name: "S\u01a1n C\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8204", name: "Th\u00e1i Ninh", prefix: "X\u00e3" },
          { id: "8205", name: "Thanh Ba", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "8206", name: "Thanh H\u00e0", prefix: "X\u00e3" },
          { id: "8207", name: "Thanh V\u00e2n", prefix: "X\u00e3" },
          { id: "8208", name: "Thanh X\u00e1", prefix: "X\u00e3" },
          { id: "8209", name: "V\u00e2n L\u0129nh", prefix: "X\u00e3" },
          { id: "8210", name: "V\u00f5 Lao", prefix: "X\u00e3" },
          { id: "8211", name: "V\u0169 Y\u1ec3n", prefix: "X\u00e3" },
          { id: "8212", name: "Y\u1ec3n Kh\u00ea", prefix: "X\u00e3" },
          { id: "8213", name: "Y\u00ean N\u1ed9i", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28980", name: "Thanh Minh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28981",
            name: "T\u1ec9nh L\u1ed9 311",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "510",
        name: "Thanh S\u01a1n",
        wards: [
          { id: "8214", name: "C\u1ef1 \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "8215", name: "C\u1ef1 Th\u1eafng", prefix: "X\u00e3" },
          { id: "8216", name: "\u0110\u1ecbch Qu\u1ea3", prefix: "X\u00e3" },
          { id: "8217", name: "\u0110\u00f4ng C\u1eedu", prefix: "X\u00e3" },
          { id: "8218", name: "Gi\u00e1p Lai", prefix: "X\u00e3" },
          { id: "8219", name: "H\u01b0\u01a1ng C\u1ea7n", prefix: "X\u00e3" },
          { id: "8220", name: "Kh\u1ea3 C\u1eedu", prefix: "X\u00e3" },
          { id: "8221", name: "L\u01b0\u01a1ng Nha", prefix: "X\u00e3" },
          { id: "8222", name: "S\u01a1n H\u00f9ng", prefix: "X\u00e3" },
          { id: "8223", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "8224", name: "T\u00e2n Minh", prefix: "X\u00e3" },
          { id: "8225", name: "T\u1ea5t Th\u1eafng", prefix: "X\u00e3" },
          { id: "8226", name: "Th\u1ea1ch Kho\u00e1n", prefix: "X\u00e3" },
          { id: "8227", name: "Th\u1eafng S\u01a1n", prefix: "X\u00e3" },
          { id: "8228", name: "Thanh S\u01a1n", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "8229", name: "Th\u1ee5c Luy\u1ec7n", prefix: "X\u00e3" },
          { id: "8230", name: "Th\u01b0\u1ee3ng C\u1eedu", prefix: "X\u00e3" },
          { id: "8231", name: "Tinh Nhu\u1ec7", prefix: "X\u00e3" },
          { id: "8232", name: "V\u0103n Mi\u1ebfu", prefix: "X\u00e3" },
          { id: "8233", name: "V\u00f5 Mi\u1ebfu", prefix: "X\u00e3" },
          { id: "8234", name: "Y\u00ean L\u00e3ng", prefix: "X\u00e3" },
          { id: "8235", name: "Y\u00ean L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8236", name: "Y\u00ean S\u01a1n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28982", name: "316B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28983",
            name: "Qu\u1ed1c l\u1ed9 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28984",
            name: "T\u00e2n Th\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "28985", name: "V\u00e0ng", prefix: "Ph\u1ed1" },
        ],
        projects: [],
      },
      {
        id: "511",
        name: "Thanh Th\u1ee7y",
        wards: [
          { id: "8237", name: "B\u1ea3o Y\u00ean", prefix: "X\u00e3" },
          { id: "8238", name: "\u0110\u00e0o X\u00e1", prefix: "X\u00e3" },
          { id: "8239", name: "\u0110oan H\u1ea1", prefix: "X\u00e3" },
          { id: "8240", name: "\u0110\u1ed3ng Lu\u1eadn", prefix: "X\u00e3" },
          { id: "8241", name: "Ho\u00e0ng X\u00e1", prefix: "X\u00e3" },
          { id: "8242", name: "Ph\u01b0\u1ee3ng Mao", prefix: "X\u00e3" },
          { id: "8243", name: "S\u01a1n Th\u1ee7y", prefix: "X\u00e3" },
          { id: "8244", name: "T\u00e2n Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8245", name: "Th\u1ea1ch \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "8246", name: "Thanh Th\u1ee7y", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "8247", name: "Trung Ngh\u0129a", prefix: "X\u00e3" },
          { id: "8248", name: "Trung Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "8249", name: "Tu V\u0169", prefix: "X\u00e3" },
          { id: "8250", name: "Xu\u00e2n L\u1ed9c", prefix: "X\u00e3" },
          { id: "8251", name: "Y\u1ebfn Mao", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "28986", name: "316", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "28987",
            name: "T\u1ec9nh L\u1ed9 317",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3034",
            name: "V\u01b0\u1eddn Vua Resort & Villas",
            lat: "21.108890533447",
            lng: "105.29263305664",
          },
        ],
      },
      {
        id: "512",
        name: "Vi\u1ec7t Tr\u00ec",
        wards: [
          {
            id: "8252",
            name: "B\u1ea1ch H\u1ea1c",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "8253", name: "B\u1ebfn G\u00f3t", prefix: "Ph\u01b0\u1eddng" },
          { id: "8254", name: "Chu H\u00f3a", prefix: "X\u00e3" },
          { id: "8255", name: "D\u1eefu L\u00e2u", prefix: "Ph\u01b0\u1eddng" },
          { id: "8256", name: "Gia C\u1ea9m", prefix: "Ph\u01b0\u1eddng" },
          { id: "8257", name: "H\u00f9ng L\u00f4", prefix: "X\u00e3" },
          { id: "8258", name: "Hy C\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8259", name: "Kim \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "8260", name: "Minh N\u00f4ng", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "8261",
            name: "Minh Ph\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "8262", name: "N\u00f4ng Trang", prefix: "Ph\u01b0\u1eddng" },
          { id: "8263", name: "Ph\u01b0\u1ee3ng L\u00e2u", prefix: "X\u00e3" },
          { id: "8264", name: "S\u00f4ng L\u00f4", prefix: "X\u00e3" },
          { id: "8265", name: "T\u00e2n D\u00e2n", prefix: "Ph\u01b0\u1eddng" },
          { id: "8266", name: "T\u00e2n \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "8267", name: "Thanh \u0110\u00ecnh", prefix: "X\u00e3" },
          { id: "8268", name: "Thanh Mi\u1ebfu", prefix: "Ph\u01b0\u1eddng" },
          { id: "8269", name: "Th\u1ecd S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "8270", name: "Th\u1ee5y V\u00e2n", prefix: "X\u00e3" },
          {
            id: "8271",
            name: "Ti\u00ean C\u00e1t",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "8272", name: "Tr\u01b0ng V\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8273", name: "V\u00e2n C\u01a1", prefix: "Ph\u01b0\u1eddng" },
          { id: "8274", name: "V\u00e2n Ph\u00fa", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "28988", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28989", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "28990", name: "Anh D\u0169ng", prefix: "Ph\u1ed1" },
          {
            id: "28991",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28992",
            name: "\u00c2u C\u01a1 c\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28993",
            name: "B\u1ea1ch H\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28994",
            name: "B\u1ea3o H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28995",
            name: "B\u00ecnh H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28996",
            name: "Ch\u00e2u Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28997",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28998",
            name: "\u0110\u1ea1i N\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "28999",
            name: "\u0110\u1eb7ng Minh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29000",
            name: "\u0110inh C\u00f4ng Tu\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29001",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29002", name: "\u0110\u1ed7 Nhu\u1eadn", prefix: "Ph\u1ed1" },
          {
            id: "29003",
            name: "\u0110o\u00e0n K\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29004",
            name: "\u0110\u1ed3i Cam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29005",
            name: "\u0110\u1ed3i Ch\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29006",
            name: "\u0110\u00f4ng L\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29007",
            name: "\u0110\u00f4ng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29008", name: "G\u00e1t", prefix: "Ph\u1ed1" },
          { id: "29009", name: "G\u00f2 Mun", prefix: "Ph\u1ed1" },
          {
            id: "29010",
            name: "H\u00e0 Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29011",
            name: "H\u00e0 Li\u1ec5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29012", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "29013",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29014",
            name: "H\u00e0n Thuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29015",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29016", name: "H\u00f2a B\u00ecnh 1", prefix: "Ph\u1ed1" },
          {
            id: "29017",
            name: "H\u00f2a B\u00ecnh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29018",
            name: "H\u00f2a Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29019",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29020",
            name: "H\u1ed3ng H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29021",
            name: "H\u1ed3ng H\u00e0 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29022",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29023",
            name: "Ki\u1ebfn Thi\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29024",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29025",
            name: "L\u1ea1c H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29026",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29027",
            name: "L\u00e2m Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29028",
            name: "L\u0103ng C\u1ea9m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29029",
            name: "L\u00ea \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29030",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29031",
            name: "L\u00ea V\u0103n T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29032",
            name: "L\u00ed T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29033",
            name: "Long Ch\u00e2u Sa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29034",
            name: "Mai An Ti\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29035",
            name: "Mai S\u01a1n 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29036", name: "Mai S\u01a1n 2", prefix: "Ph\u1ed1" },
          {
            id: "29037",
            name: "M\u1ebb Qu\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29038", name: "Minh Lang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29039",
            name: "Ng\u00f4 T\u1ea5t T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29041",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29040",
            name: "Nguy\u1ec5n \u0110\u1ed1c B\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29042",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29043",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29044",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29045",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29046",
            name: "Nguy\u1ec7t C\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29047",
            name: "Nh\u1ecb H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29048",
            name: "Phan Ch\u00e2u Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29049",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29050", name: "Phong Ch\u00e2u", prefix: "Ph\u1ed1" },
          {
            id: "29051",
            name: "Ph\u00f9 \u0110\u1ed5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29052",
            name: "Qu\u1ea3ng Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29053",
            name: "Qu\u1ed1c L\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29054",
            name: "Qu\u1ed1c L\u1ed9 32C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29055",
            name: "S\u00f4ng Thao",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29056", name: "Tam Long", prefix: "Ph\u1ed1" },
          {
            id: "29057",
            name: "T\u00e2n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29058",
            name: "T\u1ea3n \u0110\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29059",
            name: "T\u00e2n \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29060",
            name: "T\u00e2n Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29061",
            name: "T\u1ea3n Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29062",
            name: "T\u00e2n Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29063",
            name: "T\u00e2n Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29064",
            name: "T\u00e2n X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29065",
            name: "Thanh B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29066",
            name: "Th\u00e0nh C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29067", name: "Thanh H\u00e0", prefix: "Ph\u1ed1" },
          {
            id: "29068",
            name: "Thanh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29069", name: "Thi \u0110ua", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29070",
            name: "Thi\u1ec1u Hoa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29071", name: "Th\u1ecd Mai", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29072", name: "Ti\u00ean Dung", prefix: "Ph\u1ed1" },
          {
            id: "29073",
            name: "Ti\u1ec1n Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29074", name: "Ti\u00ean Ph\u00fa", prefix: "Ph\u1ed1" },
          {
            id: "29075",
            name: "Ti\u00ean S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29076",
            name: "T\u00f4 V\u0129nh Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29077",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29078",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29079",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29080",
            name: "Tr\u1ea7n To\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29081",
            name: "Tr\u00e0ng Nam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29082",
            name: "Tr\u01b0ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29083", name: "V\u0103n Cao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29084",
            name: "V\u0103n Lang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29085", name: "Vi\u1ec7t H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "29086",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29087", name: "V\u0169 Du\u1ec7", prefix: "Ph\u1ed1" },
          {
            id: "29088",
            name: "V\u0169 Th\u00ea Lang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29089",
            name: "Xu\u00e2n N\u01b0\u01a1ng",
            prefix: "Ph\u1ed1",
          },
        ],
        projects: [
          {
            id: "3035",
            name: "H\u00f2a Phong",
            lat: "21.326585769653",
            lng: "105.38535308838",
          },
          {
            id: "3036",
            name: "Minh Ph\u01b0\u01a1ng",
            lat: "21.329950332642",
            lng: "105.37005615234",
          },
          {
            id: "3037",
            name: "Nam \u0110\u1ed3ng M\u1ea1",
            lat: "21.312515258789",
            lng: "105.4153137207",
          },
        ],
      },
      {
        id: "513",
        name: "Y\u00ean L\u1eadp",
        wards: [
          { id: "8275", name: "\u0110\u1ed3ng L\u1ea1c", prefix: "X\u00e3" },
          { id: "8276", name: "\u0110\u1ed3ng Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "8277", name: "H\u01b0ng Long", prefix: "X\u00e3" },
          { id: "8278", name: "L\u01b0\u01a1ng S\u01a1n", prefix: "X\u00e3" },
          { id: "8279", name: "Minh H\u00f2a", prefix: "X\u00e3" },
          { id: "8280", name: "M\u1ef9 Lung", prefix: "X\u00e3" },
          { id: "8281", name: "M\u1ef9 L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8282", name: "Nga Ho\u00e0ng", prefix: "X\u00e3" },
          { id: "8283", name: "Ng\u1ecdc \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "8284", name: "Ng\u1ecdc L\u1eadp", prefix: "X\u00e3" },
          { id: "8285", name: "Ph\u00fac Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "8286", name: "Th\u01b0\u1ee3ng Long", prefix: "X\u00e3" },
          { id: "8287", name: "Trung S\u01a1n", prefix: "X\u00e3" },
          { id: "8288", name: "Xu\u00e2n An", prefix: "X\u00e3" },
          { id: "8289", name: "Xu\u00e2n Th\u1ee7y", prefix: "X\u00e3" },
          { id: "8290", name: "Xu\u00e2n Vi\u00ean", prefix: "X\u00e3" },
          {
            id: "8291",
            name: "Y\u00ean L\u1eadp",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          {
            id: "29090",
            name: "Qu\u1ed1c L\u1ed9 70B",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "43",
    code: "NT",
    name: "Ninh Thu\u1eadn",
    districts: [
      {
        id: "514",
        name: "B\u00e1c \u00c1i",
        wards: [
          { id: "8292", name: "Ph\u01b0\u1edbc B\u00ecnh", prefix: "X\u00e3" },
          { id: "8293", name: "Ph\u01b0\u1edbc Ch\u00ednh", prefix: "X\u00e3" },
          {
            id: "8294",
            name: "Ph\u01b0\u1edbc \u0110\u1ea1i",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8295", name: "Ph\u01b0\u1edbc H\u00f2a", prefix: "X\u00e3" },
          { id: "8296", name: "Ph\u01b0\u1edbc T\u00e2n", prefix: "X\u00e3" },
          { id: "8297", name: "Ph\u01b0\u1edbc Th\u1eafng", prefix: "X\u00e3" },
          { id: "8298", name: "Ph\u01b0\u1edbc Th\u00e0nh", prefix: "X\u00e3" },
          { id: "8299", name: "Ph\u01b0\u1edbc Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "8300", name: "Ph\u01b0\u1edbc Trung", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "29091",
            name: "Qu\u1ed1c l\u1ed9 27B",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "515",
        name: "Ninh H\u1ea3i",
        wards: [
          { id: "8301", name: "H\u1ed9 H\u1ea3i", prefix: "X\u00e3" },
          {
            id: "8302",
            name: "Kh\u00e1nh H\u1ea3i",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8303", name: "Nh\u01a1n H\u1ea3i", prefix: "X\u00e3" },
          { id: "8304", name: "Ph\u01b0\u01a1ng H\u1ea3i", prefix: "X\u00e3" },
          { id: "8305", name: "T\u00e2n H\u1ea3i", prefix: "X\u00e3" },
          { id: "8306", name: "Thanh H\u1ea3i", prefix: "X\u00e3" },
          { id: "8307", name: "Tri H\u1ea3i", prefix: "X\u00e3" },
          { id: "8308", name: "V\u0129nh H\u1ea3i", prefix: "X\u00e3" },
          { id: "8309", name: "Xu\u00e2n H\u1ea3i", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "29092", name: "702", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29093",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29094",
            name: "N\u01a1 Trang Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29095",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29096",
            name: "S\u01b0\u01a1ng Nguy\u1ec7t \u00c1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29097",
            name: "T\u1ec9nh l\u1ed9 702",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29098",
            name: "Tr\u1ea7n Anh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29099",
            name: "Y\u00ean Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "516",
        name: "Ninh Ph\u01b0\u1edbc",
        wards: [
          { id: "8310", name: "An H\u1ea3i", prefix: "X\u00e3" },
          {
            id: "8311",
            name: "Ph\u01b0\u1edbc D\u00e2n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8312", name: "Ph\u01b0\u1edbc H\u1ea3i", prefix: "X\u00e3" },
          { id: "8313", name: "Ph\u01b0\u1edbc H\u1eadu", prefix: "X\u00e3" },
          { id: "8314", name: "Ph\u01b0\u1edbc H\u1eefu", prefix: "X\u00e3" },
          { id: "8315", name: "Ph\u01b0\u1edbc S\u01a1n", prefix: "X\u00e3" },
          { id: "8316", name: "Ph\u01b0\u1edbc Th\u00e1i", prefix: "X\u00e3" },
          { id: "8317", name: "Ph\u01b0\u1edbc Thu\u1eadn", prefix: "X\u00e3" },
          { id: "8318", name: "Ph\u01b0\u1edbc Vinh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "29100", name: "703", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29101",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29102",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29103",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29104",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "517",
        name: "Ninh S\u01a1n",
        wards: [
          { id: "8319", name: "H\u00f2a S\u01a1n", prefix: "X\u00e3" },
          { id: "8320", name: "L\u00e2m S\u01a1n", prefix: "X\u00e3" },
          { id: "8321", name: "L\u01b0\u01a1ng S\u01a1n", prefix: "X\u00e3" },
          { id: "8322", name: "Ma N\u1edbi", prefix: "X\u00e3" },
          { id: "8323", name: "M\u1ef9 S\u01a1n", prefix: "X\u00e3" },
          { id: "8324", name: "Nh\u01a1n S\u01a1n", prefix: "X\u00e3" },
          { id: "8325", name: "Qu\u1ea3ng S\u01a1n", prefix: "X\u00e3" },
          {
            id: "8326",
            name: "T\u00e2n S\u01a1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          { id: "29105", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29106", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "29107",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29108",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29109",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29110",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29111",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29112",
            name: "Nguy\u1ec5n Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29113",
            name: "Qu\u1ed1c l\u1ed9 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29114",
            name: "Qu\u1ed1c l\u1ed9 27B",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "518",
        name: "Phan Rang - Th\u00e1p Ch\u00e0m",
        wards: [
          { id: "8327", name: "B\u1ea3o An", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "8328",
            name: "\u0110\u00e0i S\u01a1n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "8329",
            name: "\u0110\u1ea1o Long",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "8330", name: "\u0110\u00f4 Vinh", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "8331",
            name: "\u0110\u00f4ng H\u1ea3i",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "8332", name: "Kinh Dinh", prefix: "Ph\u01b0\u1eddng" },
          { id: "8333", name: "M\u1ef9 B\u00ecnh", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "8334",
            name: "M\u1ef9 \u0110\u00f4ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "8335", name: "M\u1ef9 H\u1ea3i", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "8336",
            name: "M\u1ef9 H\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "8337", name: "Ph\u1ee7 H\u00e0", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "8338",
            name: "Ph\u01b0\u1edbc M\u1ef9",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "8339", name: "T\u1ea5n T\u00e0i", prefix: "Ph\u01b0\u1eddng" },
          { id: "8340", name: "Th\u00e0nh H\u1ea3i", prefix: "X\u00e3" },
          { id: "8341", name: "Thanh S\u01a1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "8342", name: "V\u0103n H\u1ea3i", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "29115", name: "16/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29116", name: "21/8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29117", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29118", name: "703", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29119",
            name: "B\u1eafc \u00c1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29120",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29121",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29122",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29123",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29124",
            name: "C\u00f4ng Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29129",
            name: "D\u01b0\u01a1ng Qu\u1ea3ng H\u00e0m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29130", name: "Duy T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29125",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29126",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29127",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29128",
            name: "\u0110\u1ed3ng D\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29131",
            name: "H\u00e0 Huy Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29132",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29133",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29134",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29135",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29136",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29137",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29138",
            name: "Hu\u1ef3nh T\u1ea5n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29139",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29141",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29140",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29142",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29143",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29144",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29145",
            name: "L\u01b0\u01a1ng Ng\u1ecdc Quy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29146",
            name: "L\u01b0\u01a1ng V\u0103n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29147",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29148",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29149",
            name: "M\u1ea1c Th\u1ecb B\u01b0\u1edfi",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29150",
            name: "Mai Xu\u00e2n Th\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29151",
            name: "Minh M\u1ea1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29152", name: "N5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29153",
            name: "Ng\u00f4 \u0110\u1ee9c K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29154",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29155",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29156",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29157",
            name: "Nguy\u1ec5n Bi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29158",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29159",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29160",
            name: "Nguy\u1ec5n C\u01b0 Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29161",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29162",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29163",
            name: "Nguy\u1ec5n Gia Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29164",
            name: "Nguy\u1ec5n Kho\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29165",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29166",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29167",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29168",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29169",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29170",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29171",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29172",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29173",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29174",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29175",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29176",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29177",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29178",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29179",
            name: "Phan  \u0110\u00ecnh H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29180",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29181",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29182",
            name: "Ph\u00f3 \u0110\u1ee9c Ch\u00ednh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29183",
            name: "Ph\u00f9 \u0110\u1ed5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29184",
            name: "Ph\u00f9ng Ch\u00ed Ki\u00ean",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29185",
            name: "Pi N\u0103ng T\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29186", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29187",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29188",
            name: "T\u1ea5n T\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29189",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29190",
            name: "T\u1ec9nh l\u1ed9 702",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29191",
            name: "T\u1ec9nh L\u1ed9 704",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29192",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29193",
            name: "T\u00f4n \u0110\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29194",
            name: "Tr\u1ea7n Anh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29195",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29196",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29197",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29198",
            name: "Tr\u1ea7n H\u1eefu Duy\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29199",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29200",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29201",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29202",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29203",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29204",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29205",
            name: "Tr\u1ea7n Qu\u1ed1c Th\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29206",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29207",
            name: "Tr\u1ea7n Thi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29208",
            name: "Tr\u1ecbnh Ho\u00e0i \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29209",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29210",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29211",
            name: "Tr\u01b0\u01a1ng V\u0103n Ly",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29212",
            name: "T\u1ef1 \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29213",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29214",
            name: "Y\u00ean Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29215",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3038",
            name: "Bi\u0300nh S\u01a1n Ocean Part",
            lat: "11.577960014343",
            lng: "109.02542114258",
          },
          {
            id: "3039",
            name: "Khu \u0111\u00f4 th\u1ecb m\u1edbi \u0110\u00f4ng B\u1eafc (Khu 1)",
            lat: "11.602774620056",
            lng: "108.94875335693",
          },
          {
            id: "3040",
            name: "Ph\u00fa Th\u1ecbnh Plaza",
            lat: "11.563881874084",
            lng: "109.01320648193",
          },
        ],
      },
      {
        id: "519",
        name: "Thu\u1eadn B\u1eafc",
        wards: [
          { id: "8343", name: "B\u1eafc Phong", prefix: "X\u00e3" },
          { id: "8344", name: "B\u1eafc S\u01a1n", prefix: "X\u00e3" },
          { id: "8345", name: "C\u00f4ng H\u1ea3i", prefix: "X\u00e3" },
          {
            id: "8346",
            name: "L\u1ee3i H\u1ea3i",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8347", name: "Ph\u01b0\u1edbc Chi\u1ebfn", prefix: "X\u00e3" },
          { id: "8348", name: "Ph\u01b0\u1edbc Kh\u00e1ng", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "29216",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "520",
        name: "Thu\u1eadn Nam",
        wards: [
          { id: "8349", name: "C\u00e0 N\u00e1", prefix: "X\u00e3" },
          { id: "8350", name: "Nh\u1ecb H\u00e0", prefix: "X\u00e3" },
          { id: "8351", name: "Ph\u01b0\u1edbc Di\u00eam", prefix: "X\u00e3" },
          { id: "8352", name: "Ph\u01b0\u1edbc Dinh", prefix: "X\u00e3" },
          { id: "8353", name: "Ph\u01b0\u1edbc H\u00e0", prefix: "X\u00e3" },
          { id: "8354", name: "Ph\u01b0\u1edbc Minh", prefix: "X\u00e3" },
          { id: "8355", name: "Ph\u01b0\u1edbc Nam", prefix: "X\u00e3" },
          { id: "8356", name: "Ph\u01b0\u1edbc Ninh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "29217", name: "709", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29218",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29219",
            name: "V\u0103n L\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "44",
    code: "PY",
    name: "Ph\u00fa Y\u00ean",
    districts: [
      {
        id: "521",
        name: "\u0110\u00f4ng H\u00f2a",
        wards: [
          {
            id: "8357",
            name: "H\u00f2a Hi\u1ec7p B\u1eafc",
            prefix: "X\u00e3",
          },
          { id: "8358", name: "H\u00f2a Hi\u1ec7p Nam", prefix: "X\u00e3" },
          { id: "8359", name: "H\u00f2a Hi\u1ec7p Trung", prefix: "X\u00e3" },
          { id: "8360", name: "H\u00f2a T\u00e2m", prefix: "X\u00e3" },
          {
            id: "8361",
            name: "H\u00f2a T\u00e2n \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "8362", name: "H\u00f2a Th\u00e0nh", prefix: "X\u00e3" },
          { id: "8363", name: "H\u00f2a Vinh", prefix: "X\u00e3" },
          {
            id: "8364",
            name: "H\u00f2a Xu\u00e2n \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "8365", name: "H\u00f2a Xu\u00e2n Nam", prefix: "X\u00e3" },
          {
            id: "8366",
            name: "H\u00f2a Xu\u00e2n T\u00e2y",
            prefix: "X\u00e3",
          },
        ],
        streets: [
          {
            id: "29220",
            name: "Ph\u00fa Th\u1ecd 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29221",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29222",
            name: "Qu\u1ed1c L\u1ed9 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "522",
        name: "\u0110\u1ed3ng Xu\u00e2n",
        wards: [
          { id: "8367", name: "\u0110a L\u1ed9c", prefix: "X\u00e3" },
          { id: "8368", name: "La Hai", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "8369", name: "Ph\u00fa M\u1ee1", prefix: "X\u00e3" },
          { id: "8370", name: "Xu\u00e2n L\u00e3nh", prefix: "X\u00e3" },
          { id: "8371", name: "Xu\u00e2n Long", prefix: "X\u00e3" },
          { id: "8372", name: "Xu\u00e2n Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "8373", name: "Xu\u00e2n Quang 1", prefix: "X\u00e3" },
          { id: "8374", name: "Xu\u00e2n Quang 2", prefix: "X\u00e3" },
          { id: "8375", name: "Xu\u00e2n Quang 3", prefix: "X\u00e3" },
          {
            id: "8376",
            name: "Xu\u00e2n S\u01a1n B\u1eafc",
            prefix: "X\u00e3",
          },
          { id: "8377", name: "Xu\u00e2n S\u01a1n Nam", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "29223",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "523",
        name: "Ph\u00fa H\u00f2a",
        wards: [
          { id: "8378", name: "H\u00f2a An", prefix: "X\u00e3" },
          {
            id: "8379",
            name: "H\u00f2a \u0110\u1ecbnh \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "8380",
            name: "H\u00f2a \u0110\u1ecbnh T\u00e2y",
            prefix: "X\u00e3",
          },
          { id: "8381", name: "H\u00f2a H\u1ed9i", prefix: "X\u00e3" },
          { id: "8382", name: "Ho\u00e0 Quang B\u1eafc", prefix: "X\u00e3" },
          { id: "8383", name: "Ho\u00e0 Quang Nam", prefix: "X\u00e3" },
          { id: "8384", name: "H\u00f2a Th\u1eafng", prefix: "X\u00e3" },
          { id: "8385", name: "H\u00f2a Tr\u1ecb", prefix: "X\u00e3" },
          {
            id: "8386",
            name: "Ph\u00fa H\u00f2a",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          {
            id: "29224",
            name: "C\u1ea7u \u00d4ng Ch\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29225",
            name: "Qu\u1ed1c l\u1ed9 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29226",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "524",
        name: "S\u01a1n H\u00f2a",
        wards: [
          { id: "8387", name: "C\u00e0 L\u00fai", prefix: "X\u00e3" },
          {
            id: "8388",
            name: "C\u1ee7ng S\u01a1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8389", name: "Each\u00e0 Rang", prefix: "X\u00e3" },
          { id: "8390", name: "Kr\u00f4ng Pa", prefix: "X\u00e3" },
          { id: "8391", name: "Ph\u01b0\u1edbc T\u00e2n", prefix: "X\u00e3" },
          { id: "8392", name: "S\u01a1n \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "8393", name: "S\u01a1n H\u00e0", prefix: "X\u00e3" },
          { id: "8394", name: "S\u01a1n H\u1ed9i", prefix: "X\u00e3" },
          { id: "8395", name: "S\u01a1n Long", prefix: "X\u00e3" },
          { id: "8396", name: "S\u01a1n Nguy\u00ean", prefix: "X\u00e3" },
          { id: "8397", name: "S\u01a1n Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "8398", name: "S\u01a1n Xu\u00e2n", prefix: "X\u00e3" },
          { id: "8399", name: "Su\u1ed1i B\u1ea1c", prefix: "X\u00e3" },
          { id: "8400", name: "Su\u1ed1i Trai", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "29227", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "29228",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29229",
            name: "Qu\u1ed1c l\u1ed9 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29230",
            name: "Su\u1ed1i B\u1ea1c 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29231",
            name: "Su\u1ed1i B\u1ea1c 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29232",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "525",
        name: "S\u00f4ng C\u1ea7u",
        wards: [
          { id: "8401", name: "Xu\u00e2n B\u00ecnh", prefix: "X\u00e3" },
          { id: "8402", name: "Xu\u00e2n C\u1ea3nh", prefix: "X\u00e3" },
          {
            id: "8403",
            name: "Xu\u00e2n \u0110\u00e0i",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "8404", name: "Xu\u00e2n H\u1ea3i", prefix: "X\u00e3" },
          { id: "8405", name: "Xu\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "8406", name: "Xu\u00e2n L\u00e2m", prefix: "X\u00e3" },
          { id: "8407", name: "Xu\u00e2n L\u1ed9c", prefix: "X\u00e3" },
          {
            id: "8408",
            name: "Xu\u00e2n Ph\u00fa",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "8409", name: "Xu\u00e2n Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          {
            id: "8410",
            name: "Xu\u00e2n Th\u00e0nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "8411", name: "Xu\u00e2n Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "8412", name: "Xu\u00e2n Th\u1ecd 1", prefix: "X\u00e3" },
          { id: "8413", name: "Xu\u00e2n Th\u1ecd 2", prefix: "X\u00e3" },
          {
            id: "8414",
            name: "Xu\u00e2n Y\u00ean",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          {
            id: "29233",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29234",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29235",
            name: "L\u00ea Th\u00e0nh Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29236",
            name: "L\u1ec7 Uy\u00ean \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29237",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29238",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29239",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29240",
            name: "Qu\u1ed1c l\u1ed9 1D",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3041",
            name: "V\u1ecbnh Xu\u00e2n \u0110\u00e0i",
            lat: "13.34123134613",
            lng: "109.25267028809",
          },
        ],
      },
      {
        id: "526",
        name: "S\u00f4ng Hinh",
        wards: [
          {
            id: "8415",
            name: "\u0110\u1ee9c B\u00ecnh \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "8416",
            name: "\u0110\u1ee9c B\u00ecnh T\u00e2y",
            prefix: "X\u00e3",
          },
          { id: "8417", name: "Ea B\u00e1", prefix: "X\u00e3" },
          { id: "8418", name: "Ea Bar", prefix: "X\u00e3" },
          { id: "8419", name: "Ea Bia", prefix: "X\u00e3" },
          { id: "8420", name: "Ea L\u00e2m", prefix: "X\u00e3" },
          { id: "8421", name: "Ea Ly", prefix: "X\u00e3" },
          { id: "8422", name: "Ea Trol", prefix: "X\u00e3" },
          { id: "8423", name: "Hai Ri\u00eang", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "8424", name: "S\u01a1n Giang", prefix: "X\u00e3" },
          { id: "8425", name: "S\u00f4ng Hinh", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "29241",
            name: "L\u01b0\u01a1ng V\u0103n Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29242",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29243",
            name: "Qu\u1ed1c l\u1ed9 19C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29244",
            name: "Qu\u1ed1c L\u1ed9 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29245",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29246",
            name: "Tr\u01b0\u1eddng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "527",
        name: "T\u00e2y H\u00f2a",
        wards: [
          { id: "8426", name: "H\u00f2a B\u00ecnh 1", prefix: "X\u00e3" },
          { id: "8427", name: "H\u00f2a \u0110\u1ed3ng", prefix: "X\u00e3" },
          {
            id: "8428",
            name: "H\u00f2a M\u1ef9 \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "8429", name: "H\u00f2a M\u1ef9 T\u00e2y", prefix: "X\u00e3" },
          { id: "8430", name: "H\u00f2a Phong", prefix: "X\u00e3" },
          { id: "8431", name: "H\u00f2a Ph\u00fa", prefix: "X\u00e3" },
          { id: "8432", name: "H\u00f2a T\u00e2n T\u00e2y", prefix: "X\u00e3" },
          { id: "8433", name: "H\u00f2a Th\u1ecbnh", prefix: "X\u00e3" },
          {
            id: "8434",
            name: "Ph\u00fa Th\u1ee9",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "8435",
            name: "S\u01a1n Th\u00e0nh \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "8436",
            name: "S\u01a1n Th\u00e0nh T\u00e2y",
            prefix: "X\u00e3",
          },
        ],
        streets: [
          { id: "29247", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29248", name: "\u0110T 643", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
      {
        id: "528",
        name: "Tuy An",
        wards: [
          { id: "8437", name: "An Ch\u1ea5n", prefix: "X\u00e3" },
          { id: "8438", name: "An C\u01b0", prefix: "X\u00e3" },
          { id: "8439", name: "An D\u00e2n", prefix: "X\u00e3" },
          { id: "8440", name: "An \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "8441", name: "An H\u1ea3i", prefix: "X\u00e3" },
          { id: "8442", name: "An Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "8443", name: "An Ho\u00e0", prefix: "X\u00e3" },
          { id: "8444", name: "An L\u0129nh", prefix: "X\u00e3" },
          { id: "8445", name: "An M\u1ef9", prefix: "X\u00e3" },
          { id: "8446", name: "An Nghi\u1ec7p", prefix: "X\u00e3" },
          { id: "8447", name: "An Ninh \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "8448", name: "An Ninh T\u00e2y", prefix: "X\u00e3" },
          { id: "8449", name: "An Th\u1ea1ch", prefix: "X\u00e3" },
          { id: "8450", name: "An Th\u1ecd", prefix: "X\u00e3" },
          { id: "8451", name: "An Xu\u00e2n", prefix: "X\u00e3" },
          {
            id: "8452",
            name: "Ch\u00ed Th\u1ea1nh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          {
            id: "29249",
            name: "Ch\u00ed \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29250",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29251",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29252",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "529",
        name: "Tuy H\u00f2a",
        wards: [
          { id: "8453", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "8454", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "8455", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "8456", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "8457", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "8458", name: "6", prefix: "Ph\u01b0\u1eddng" },
          { id: "8459", name: "7", prefix: "Ph\u01b0\u1eddng" },
          { id: "8460", name: "8", prefix: "Ph\u01b0\u1eddng" },
          { id: "8461", name: "9", prefix: "Ph\u01b0\u1eddng" },
          { id: "8462", name: "An Ph\u00fa", prefix: "X\u00e3" },
          { id: "8463", name: "B\u00ecnh Ki\u1ebfn", prefix: "X\u00e3" },
          { id: "8464", name: "B\u00ecnh Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "8465", name: "Ho\u00e0 Ki\u1ebfn", prefix: "X\u00e3" },
          {
            id: "8466",
            name: "Ph\u00fa \u0110\u00f4ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "8467", name: "Ph\u00fa L\u00e2m", prefix: "Ph\u01b0\u1eddng" },
          { id: "8468", name: "Ph\u00fa L\u00e2m", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "8469",
            name: "Ph\u00fa Th\u1ea1nh",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "29253", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29254", name: "22/12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29255", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29256", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29257", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29258",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29259", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "29260",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29261",
            name: "B\u1ebfn Ch\u01b0\u01a1ng D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29262",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29263",
            name: "C\u1ea7n V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29264",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29265",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29266",
            name: "Chi L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29267",
            name: "Chi\u1ebfn Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29268",
            name: "Chu M\u1ea1nh Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29269",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29270",
            name: "C\u00f4n S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29277", name: "Duy T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29271",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29272",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29273",
            name: "\u0110\u1ed9c L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29274",
            name: "\u0110\u1ed1ng \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29275",
            name: "\u0110\u1ed3ng Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29276",
            name: "\u0110\u01b0\u1eddng ph\u00eda T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29278",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29279", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "29280",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29281",
            name: "H\u1ed3ng B\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29282",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29283",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29284",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29285",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29286",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29287",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29288",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29289",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29290",
            name: "L\u00ea Th\u00e0nh Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29291",
            name: "L\u00ea Th\u00e1nh T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29292",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29293",
            name: "L\u00ea Trung Ki\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29294",
            name: "Li\u00ean Tr\u00ec 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29295",
            name: "Li\u00ean Tr\u00ec 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29296",
            name: "L\u01b0\u01a1ng T\u1ea5n Th\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29297",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29298",
            name: "L\u01b0\u01a1ng V\u0103n Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29299",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29300",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29301",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29302",
            name: "Mai Xu\u00e2n Th\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29303",
            name: "M\u1eadu Th\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29304",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29305",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29306",
            name: "Nguy\u1ec5n Anh H\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29307",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29308",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29309",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29311",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29310",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29312",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29313",
            name: "Nguy\u1ec5n H\u00e0o S\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29314",
            name: "Nguy\u1ec5n H\u1ed3ng S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29315",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29316",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29317",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29318",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29319",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29320",
            name: "Nguy\u1ec5n Th\u1ebf B\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29321",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29322",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29323",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29324",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29325",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29326",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29327",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29328",
            name: "Nguy\u1ec5n V\u0103n Huy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29329",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29330",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29331",
            name: "Ninh T\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29332",
            name: "Ninh T\u1ecbnh 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29333",
            name: "Ph\u1ea1m \u0110\u00ecnh Quy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29334",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29335",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29336",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29337",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29338",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29339",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29340",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29341",
            name: "Phan L\u01b0u Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29342",
            name: "Ph\u01b0\u1edbc H\u1eadu 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29343",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29344",
            name: "Qu\u1ed1c L\u1ed9 29",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29345", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29346",
            name: "Song H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29347",
            name: "T\u00e2n Tr\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29348",
            name: "Th\u0103ng  Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29349",
            name: "T\u1ed1 H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29350",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29351",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29352",
            name: "Tr\u1ea7n H\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29353",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29354",
            name: "Tr\u1ea7n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29355",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29356",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29357",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29358",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29359",
            name: "Tr\u1ea7n Xuy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29360",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29361", name: "V\u0103n Cao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29362",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29363", name: "Yersin", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29364",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3042",
            name: "Rosa Alba Resort Tuy H\u00f2a",
            lat: "13.114643096924",
            lng: "109.30702209473",
          },
          {
            id: "3043",
            name: "Vincom Shophouse Tuy H\u00f2a",
            lat: "13.103281974792",
            lng: "109.31218719482",
          },
        ],
      },
    ],
  },
  {
    id: "45",
    code: "HNA",
    name: "H\u00e0 Nam",
    districts: [
      {
        id: "530",
        name: "B\u00ecnh L\u1ee5c",
        wards: [
          { id: "8470", name: "An \u0110\u1ed5", prefix: "X\u00e3" },
          { id: "8471", name: "An L\u00e3o", prefix: "X\u00e3" },
          { id: "8472", name: "An M\u1ef9", prefix: "X\u00e3" },
          { id: "8473", name: "An Ninh", prefix: "X\u00e3" },
          { id: "8474", name: "An N\u1ed9i", prefix: "X\u00e3" },
          {
            id: "8475",
            name: "B\u00ecnh M\u1ef9",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8476", name: "B\u00ecnh Ngh\u0129a", prefix: "X\u00e3" },
          { id: "8477", name: "B\u1ed3 \u0110\u1ec1", prefix: "X\u00e3" },
          { id: "8478", name: "B\u1ed1i C\u1ea7u", prefix: "X\u00e3" },
          { id: "8479", name: "\u0110\u1ed3n X\u00e1", prefix: "X\u00e3" },
          { id: "8480", name: "\u0110\u1ed3ng Du", prefix: "X\u00e3" },
          { id: "8481", name: "H\u01b0ng C\u00f4ng", prefix: "X\u00e3" },
          { id: "8482", name: "La S\u01a1n", prefix: "X\u00e3" },
          { id: "8483", name: "M\u1ef9 Th\u1ecd", prefix: "X\u00e3" },
          { id: "8484", name: "Ng\u1ecdc L\u0169", prefix: "X\u00e3" },
          { id: "8485", name: "Ti\u00eau \u0110\u1ed9ng", prefix: "X\u00e3" },
          { id: "8486", name: "Tr\u00e0ng An", prefix: "X\u00e3" },
          { id: "8487", name: "Trung L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8488", name: "V\u0169 B\u1ea3n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "29365", name: "21", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29366", name: "21B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29367", name: "64B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29368",
            name: "Qu\u1ed1c l\u1ed9 21A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29369",
            name: "T\u1ec9nh l\u1ed9 971",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29370",
            name: "T\u1ec9nh l\u1ed9 974",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29371",
            name: "T\u1ec9nh l\u1ed9 975",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29372",
            name: "T\u1ec9nh l\u1ed9 976",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "531",
        name: "Duy Ti\u00ean",
        wards: [
          { id: "8489", name: "B\u1ea1ch Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "8490", name: "Ch\u00e2u Giang", prefix: "X\u00e3" },
          { id: "8491", name: "Ch\u00e2u S\u01a1n", prefix: "X\u00e3" },
          { id: "8492", name: "Chuy\u00ean Ngo\u1ea1i", prefix: "X\u00e3" },
          { id: "8495", name: "Duy H\u1ea3i", prefix: "X\u00e3" },
          { id: "8496", name: "Duy Minh", prefix: "X\u00e3" },
          { id: "8493", name: "\u0110\u1ecdi S\u01a1n", prefix: "X\u00e3" },
          {
            id: "8494",
            name: "\u0110\u1ed3ng V\u0103n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "8497",
            name: "H\u00f2a M\u1ea1c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8498", name: "Ho\u00e0ng \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "8499", name: "M\u1ed9c B\u1eafc", prefix: "X\u00e3" },
          { id: "8500", name: "M\u1ed9c Nam", prefix: "X\u00e3" },
          { id: "8501", name: "Ti\u00ean Ngo\u1ea1i", prefix: "X\u00e3" },
          { id: "8502", name: "Ti\u00ean N\u1ed9i", prefix: "X\u00e3" },
          { id: "8503", name: "Ti\u00ean Phong", prefix: "X\u00e3" },
          { id: "8504", name: "Tr\u00e1c V\u0103n", prefix: "X\u00e3" },
          { id: "8505", name: "Y\u00ean B\u1eafc", prefix: "X\u00e3" },
          { id: "8506", name: "Y\u00ean Nam", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "29373", name: "38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29374", name: "60", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29375",
            name: "\u00c1nh S\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29376",
            name: "B\u00ecnh Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29377",
            name: "\u0110\u1ed3ng H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29378",
            name: "L\u00ea C\u00f4ng Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29379",
            name: "Nguy\u1ec5n H\u1eefu Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29380",
            name: "Ph\u1ea1m Ng\u1ecdc Nh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29381",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29382",
            name: "Qu\u1ed1c L\u1ed9 38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29383",
            name: "Th\u00e1i H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29384", name: "Thanh Mai 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29385", name: "Thanh Mai 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29386", name: "Thanh Mai 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29387", name: "Thanh Mai 4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29388",
            name: "Th\u1eddi \u0110\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29389",
            name: "T\u1ec9nh l\u1ed9 9710",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29390",
            name: "T\u1ec9nh l\u1ed9 9711",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3044",
            name: "TNR Star \u0110\u1ed3ng V\u0103n",
            lat: "20.644411087036",
            lng: "105.92491149902",
          },
        ],
      },
      {
        id: "532",
        name: "Kim B\u1ea3ng",
        wards: [
          { id: "8507", name: "Ba Sao", prefix: "Th\u1ecb tr\u1ea5n" },
          {
            id: "8508",
            name: "\u0110\u1ea1i C\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "8509", name: "\u0110\u1ed3ng H\u00f3a", prefix: "X\u00e3" },
          { id: "8510", name: "Ho\u00e0ng T\u00e2y", prefix: "X\u00e3" },
          { id: "8511", name: "Kh\u1ea3 Phong", prefix: "X\u00e3" },
          { id: "8512", name: "L\u00ea H\u1ed3", prefix: "X\u00e3" },
          { id: "8513", name: "Li\u00ean S\u01a1n", prefix: "X\u00e3" },
          { id: "8514", name: "Ng\u1ecdc S\u01a1n", prefix: "X\u00e3" },
          { id: "8515", name: "Nguy\u1ec5n \u00day", prefix: "X\u00e3" },
          { id: "8516", name: "Nh\u1eadt T\u00e2n", prefix: "X\u00e3" },
          { id: "8517", name: "Nh\u1eadt T\u1ef1u", prefix: "X\u00e3" },
          { id: "8518", name: "Qu\u1ebf", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "8519", name: "T\u00e2n S\u01a1n", prefix: "X\u00e3" },
          { id: "8520", name: "Thanh S\u01a1n", prefix: "X\u00e3" },
          { id: "8521", name: "Thi S\u01a1n", prefix: "X\u00e3" },
          { id: "8522", name: "Th\u1ee5y L\u00f4i", prefix: "X\u00e3" },
          { id: "8523", name: "T\u01b0\u1ee3ng L\u0129nh", prefix: "X\u00e3" },
          { id: "8524", name: "V\u0103n X\u00e1", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "29391", name: "21B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29392", name: "38", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29393",
            name: "Ch\u00f9a Ba Gi\u00e1p - Ba \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29394", name: "Nam Cao", prefix: "Ph\u1ed1" },
          {
            id: "29395",
            name: "Qu\u1ed1c l\u1ed9 21A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29396",
            name: "Qu\u1ed1c l\u1ed9 21B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29397",
            name: "Qu\u1ed1c L\u1ed9 38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29398",
            name: "T\u1ec9nh l\u1ed9 711",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29399",
            name: "X\u00f3m Ao C\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "533",
        name: "L\u00fd Nh\u00e2n",
        wards: [
          { id: "8525", name: "B\u1eafc L\u00fd", prefix: "X\u00e3" },
          { id: "8526", name: "Ch\u00e2n L\u00fd", prefix: "X\u00e3" },
          { id: "8527", name: "Ch\u00ednh L\u00fd", prefix: "X\u00e3" },
          { id: "8528", name: "C\u00f4ng L\u00fd", prefix: "X\u00e3" },
          { id: "8529", name: "\u0110\u1ea1o L\u00fd", prefix: "X\u00e3" },
          { id: "8530", name: "\u0110\u1ed3ng L\u00fd", prefix: "X\u00e3" },
          { id: "8531", name: "\u0110\u1ee9c L\u00fd", prefix: "X\u00e3" },
          {
            id: "8532",
            name: "Ho\u00e0 H\u1eadu",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8533", name: "H\u1ee3p L\u00fd", prefix: "X\u00e3" },
          { id: "8534", name: "Nguy\u00ean L\u00fd", prefix: "X\u00e3" },
          { id: "8535", name: "Nh\u00e2n B\u00ecnh", prefix: "X\u00e3" },
          { id: "8536", name: "Nh\u00e2n Ch\u00ednh", prefix: "X\u00e3" },
          { id: "8537", name: "Nh\u00e2n \u0110\u1ea1o", prefix: "X\u00e3" },
          { id: "8538", name: "Nh\u00e2n H\u01b0ng", prefix: "X\u00e3" },
          { id: "8539", name: "Nh\u00e2n Khang", prefix: "X\u00e3" },
          { id: "8540", name: "Nh\u00e2n M\u1ef9", prefix: "X\u00e3" },
          { id: "8541", name: "Nh\u00e2n Ngh\u0129a", prefix: "X\u00e3" },
          { id: "8542", name: "Nh\u00e2n Th\u1ecbnh", prefix: "X\u00e3" },
          {
            id: "8543",
            name: "Ph\u00fa Ph\u00fac",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8544", name: "Ti\u1ebfn Th\u1eafng", prefix: "X\u00e3" },
          { id: "8545", name: "V\u0103n L\u00fd", prefix: "X\u00e3" },
          {
            id: "8546",
            name: "V\u0129nh Tr\u1ee5",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8547", name: "Xu\u00e2n Kh\u00ea", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "29400",
            name: "C\u00e1nh Di\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29401",
            name: "\u0110\u00ea H\u1eefu H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29402", name: "\u0110T 491", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29403",
            name: "\u0110\u01b0\u1eddng Th\u00f4n Ph\u00fa \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29404",
            name: "H\u00f9ng L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29405",
            name: "Li\u00ean X\u00e3 Ch\u00ednh L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29406",
            name: "Li\u00ean X\u00e3 C\u00f4ng L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29407",
            name: "Ph\u1ea1m T\u1ea5t \u0110\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29408",
            name: "T\u1ec9nh l\u1ed9 971",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29409",
            name: "T\u1ec9nh l\u1ed9 9716",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29410",
            name: "T\u1ec9nh l\u1ed9 9717",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29411",
            name: "T\u1ec9nh l\u1ed9 972",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29412",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "534",
        name: "Ph\u1ee7 L\u00fd",
        wards: [
          { id: "8548", name: "Ch\u00e2u S\u01a1n", prefix: "X\u00e3" },
          { id: "8549", name: "\u0110inh X\u00e1", prefix: "X\u00e3" },
          {
            id: "8550",
            name: "Hai B\u00e0 Tr\u01b0ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "8551", name: "Kim B\u00ecnh", prefix: "X\u00e3" },
          { id: "8552", name: "Lam H\u1ea1", prefix: "X\u00e3" },
          {
            id: "8553",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "8554", name: "Li\u00eam Ch\u00ednh", prefix: "X\u00e3" },
          { id: "8555", name: "Li\u00eam Chung", prefix: "X\u00e3" },
          { id: "8556", name: "Li\u00eam Ti\u1ebft", prefix: "X\u00e3" },
          { id: "8557", name: "Li\u00eam Tuy\u1ec1n", prefix: "X\u00e3" },
          {
            id: "8558",
            name: "L\u01b0\u01a1ng Kh\u00e1nh Thi\u1ec7n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "8559", name: "Minh Khai", prefix: "Ph\u01b0\u1eddng" },
          { id: "8560", name: "Ph\u00f9 V\u00e2n", prefix: "X\u00e3" },
          { id: "8561", name: "Quang Trung", prefix: "Ph\u01b0\u1eddng" },
          { id: "8562", name: "Thanh Ch\u00e2u", prefix: "X\u00e3" },
          { id: "8563", name: "Thanh Tuy\u1ec1n", prefix: "Ph\u01b0\u1eddng" },
          { id: "8564", name: "Ti\u00ean H\u1ea3i", prefix: "X\u00e3" },
          { id: "8565", name: "Ti\u00ean Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "8566", name: "Ti\u00ean T\u00e2n", prefix: "X\u00e3" },
          {
            id: "8567",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "8568", name: "Tr\u1ecbnh X\u00e1", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "29413", name: "12B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29414", name: "21A", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29415", name: "24/8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29416", name: "979", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29417", name: "\u1ea4m", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29418",
            name: "B\u00e0 L\u00ea Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29419",
            name: "Bi\u00ean H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29420",
            name: "B\u00f9i D\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29421",
            name: "Ch\u00e2u C\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29422",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29423", name: "D2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29424", name: "D4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29425", name: "D5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29426",
            name: "\u0110\u00ea M\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29427",
            name: "\u0110\u1ec1 Y\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29428",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29429",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29430",
            name: "H\u1ed3 V\u00e2n S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29431",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29432",
            name: "Ho\u00e0ng H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29433",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29434",
            name: "L\u00ea Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29435",
            name: "L\u00ea C\u00f4ng Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29436",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29437",
            name: "L\u00ea Ho\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29438",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29439",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29440",
            name: "L\u00ea Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29441",
            name: "Li\u00eam Ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29442",
            name: "L\u01b0\u01a1ng \u0110\u1ecbnh C\u1ee7a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29443",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29444",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29445",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29446",
            name: "M\u1ec5 N\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29447",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29448",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29449",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29450",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29451",
            name: "Nguy\u1ec5n H\u1eefu Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29452",
            name: "Nguy\u1ec5n Qu\u1ed1c Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29453",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29454",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29455",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29456",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29457",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29458",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29459",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29460",
            name: "Ph\u1ee7 L\u00fd - Ki\u1ec7n Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29461",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29462",
            name: "Qu\u1ed1c l\u1ed9 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29463",
            name: "Qu\u1ed1c l\u1ed9 21B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29464",
            name: "Qu\u1ed1c L\u1ed9 38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29465", name: "Quy L\u01b0u", prefix: "Ph\u1ed1" },
          {
            id: "29466",
            name: "Song Ch\u00e2u Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29467",
            name: "T\u00e2n Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29468",
            name: "T\u00f4 V\u0129nh Di\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29469",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29470",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29471",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29472",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29473",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29474",
            name: "Tr\u1ea7n Th\u1ecb Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29475",
            name: "Tr\u1ea7n V\u0103n Chu\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29476",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29477",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3045",
            name: "Ch\u00e2u S\u01a1n",
            lat: "20.524110794067",
            lng: "105.90461730957",
          },
          {
            id: "3046",
            name: "Khu \u0111\u00f4 th\u1ecb b\u1edd \u0111\u00f4ng s\u00f4ng \u0110\u00e1y",
            lat: "20.523591995239",
            lng: "105.91547393799",
          },
          {
            id: "3047",
            name: "M\u01b0\u1eddng Thanh H\u00e0 Nam",
            lat: "20.542093276978",
            lng: "105.9118270874",
          },
          {
            id: "3048",
            name: "River Silk City",
            lat: "20.541297912598",
            lng: "105.94664001465",
          },
          {
            id: "3049",
            name: "Ti\u1ebfn L\u1ed9c H\u00e0 Nam",
            lat: "20.532085418701",
            lng: "105.91393280029",
          },
          {
            id: "3050",
            name: "Ti\u1ebfn L\u1ed9c Residential",
            lat: "20.532173156738",
            lng: "105.91928863525",
          },
          {
            id: "3051",
            name: "Vincom Shophouse Ph\u1ee7 L\u00fd",
            lat: "20.543355941772",
            lng: "105.91816711426",
          },
        ],
      },
      {
        id: "535",
        name: "Thanh Li\u00eam",
        wards: [
          {
            id: "8569",
            name: "Ki\u1ec7n Kh\u00ea",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8570", name: "Li\u00eam C\u1ea7n", prefix: "X\u00e3" },
          { id: "8571", name: "Li\u00eam Phong", prefix: "X\u00e3" },
          { id: "8572", name: "Li\u00eam S\u01a1n", prefix: "X\u00e3" },
          { id: "8573", name: "Li\u00eam Thu\u1eadn", prefix: "X\u00e3" },
          { id: "8574", name: "Li\u00eam T\u00fac", prefix: "X\u00e3" },
          { id: "8575", name: "Thanh B\u00ecnh", prefix: "X\u00e3" },
          { id: "8576", name: "Thanh H\u00e0", prefix: "X\u00e3" },
          { id: "8577", name: "Thanh H\u1ea3i", prefix: "X\u00e3" },
          { id: "8578", name: "Thanh H\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8579", name: "Thanh L\u01b0u", prefix: "X\u00e3" },
          { id: "8580", name: "Thanh Ngh\u1ecb", prefix: "X\u00e3" },
          { id: "8581", name: "Thanh Nguy\u00ean", prefix: "X\u00e3" },
          { id: "8582", name: "Thanh Phong", prefix: "X\u00e3" },
          { id: "8583", name: "Thanh T\u00e2m", prefix: "X\u00e3" },
          { id: "8584", name: "Thanh T\u00e2n", prefix: "X\u00e3" },
          { id: "8585", name: "Thanh Th\u1ee7y", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "29478", name: "494", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29479", name: "9712", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29480", name: "9715", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29481",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29482",
            name: "Qu\u1ed1c l\u1ed9 21",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29483",
            name: "Qu\u1ed1c l\u1ed9 21A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29484",
            name: "Xu\u00e2n Tr\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "46",
    code: "HT",
    name: "H\u00e0 T\u0129nh",
    districts: [
      {
        id: "536",
        name: "C\u1ea9m Xuy\u00ean",
        wards: [
          { id: "8586", name: " C\u1ea9m L\u1ed9c", prefix: "X\u00e3" },
          { id: "8587", name: " C\u1ea9m M\u1ef9", prefix: "X\u00e3" },
          { id: "8588", name: " C\u1ea9m Nh\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "8589", name: "C\u1ea9m B\u00ecnh", prefix: "X\u00e3" },
          { id: "8590", name: "C\u1ea9m Du\u1ec7", prefix: "X\u00e3" },
          { id: "8591", name: "C\u1ea9m D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8592", name: "C\u1ea9m H\u00e0", prefix: "X\u00e3" },
          { id: "8593", name: "C\u1ea9m Ho\u00e0", prefix: "X\u00e3" },
          { id: "8594", name: "C\u1ea9m H\u01b0ng", prefix: "X\u00e3" },
          { id: "8595", name: "C\u1ea9m Huy", prefix: "X\u00e3" },
          { id: "8596", name: "C\u1ea9m L\u1ea1c", prefix: "X\u00e3" },
          { id: "8597", name: "C\u1ea9m L\u0129nh", prefix: "X\u00e3" },
          { id: "8598", name: "C\u1ea9m Minh", prefix: "X\u00e3" },
          { id: "8599", name: "C\u1ea9m Nam", prefix: "X\u00e3" },
          { id: "8600", name: "C\u1ea9m Ph\u00fac", prefix: "X\u00e3" },
          { id: "8601", name: "C\u1ea9m Quan", prefix: "X\u00e3" },
          { id: "8602", name: "C\u1ea9m Quang", prefix: "X\u00e3" },
          { id: "8603", name: "C\u1ea9m S\u01a1n", prefix: "X\u00e3" },
          { id: "8604", name: "C\u1ea9m th\u1ea1ch", prefix: "X\u00e3" },
          { id: "8605", name: "C\u1ea9m Th\u0103ng", prefix: "X\u00e3" },
          { id: "8606", name: "C\u1ea9m Th\u00e0nh", prefix: "X\u00e3" },
          { id: "8607", name: "C\u1ea9m Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "8608", name: "C\u1ea9m Trung", prefix: "X\u00e3" },
          { id: "8609", name: "C\u1ea9m V\u1ecbnh", prefix: "X\u00e3" },
          {
            id: "8610",
            name: "C\u1ea9m Xuy\u00ean",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8611", name: "C\u1ea9m Y\u00ean", prefix: "X\u00e3" },
          {
            id: "8612",
            name: "Thi\u00ean C\u1ea7m",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          { id: "29485", name: "26/3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29486",
            name: "Nguy\u1ec5n \u0110\u00ecnh Li\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29487",
            name: "Phan \u0110\u00ecnh G\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29488",
            name: "Quang H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29489",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "537",
        name: "Can L\u1ed9c",
        wards: [
          { id: "8613", name: " Xu\u00e2n L\u1ed9c", prefix: "X\u00e3" },
          { id: "8614", name: "\u0110\u1ed3ng L\u1ed9c", prefix: "X\u00e3" },
          { id: "8615", name: "Gia Hanh", prefix: "X\u00e3" },
          { id: "8616", name: "Kh\u00e1nh L\u1ed9c", prefix: "X\u00e3" },
          { id: "8617", name: "Kim L\u1ed9c", prefix: "X\u00e3" },
          { id: "8618", name: "M\u1ef9 L\u1ed9c", prefix: "X\u00e3" },
          { id: "8619", name: "Ngh\u00e8n", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "8620", name: "Ph\u00fa L\u1ed9c", prefix: "X\u00e3" },
          { id: "8621", name: "Quang L\u1ed9c", prefix: "X\u00e3" },
          { id: "8622", name: "S\u01a1n L\u1ed9c", prefix: "X\u00e3" },
          { id: "8623", name: "Song L\u1ed9c", prefix: "X\u00e3" },
          { id: "8624", name: "Thanh L\u1ed9c", prefix: "X\u00e3" },
          { id: "8625", name: "Thi\u00ean L\u1ed9c", prefix: "X\u00e3" },
          { id: "8626", name: "Thu\u1ea7n Thi\u1ec7n", prefix: "X\u00e3" },
          { id: "8627", name: "Th\u01b0\u1ee3ng L\u1ed9c", prefix: "X\u00e3" },
          { id: "8628", name: "Th\u01b0\u1eddng Nga", prefix: "X\u00e3" },
          { id: "8629", name: "Ti\u1ebfn L\u1ed9c", prefix: "X\u00e3" },
          { id: "8630", name: "Trung L\u1ed9c", prefix: "X\u00e3" },
          { id: "8631", name: "Tr\u01b0\u1eddng L\u1ed9c", prefix: "X\u00e3" },
          { id: "8632", name: "T\u00f9ng L\u1ed9c", prefix: "X\u00e3" },
          { id: "8633", name: "V\u0129nh L\u1ed9c", prefix: "X\u00e3" },
          { id: "8634", name: "V\u01b0\u1ee3ng L\u1ed9c", prefix: "X\u00e3" },
          { id: "8635", name: "Y\u00ean L\u1ed9c", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "29490",
            name: "X\u00f4 Vi\u1ebft Ngh\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "538",
        name: "\u0110\u1ee9c Th\u1ecd",
        wards: [
          { id: "8636", name: "B\u00f9i X\u00e1", prefix: "X\u00e3" },
          { id: "8637", name: "\u0110\u1ee9c An", prefix: "X\u00e3" },
          { id: "8638", name: "\u0110\u1ee9c Ch\u00e2u", prefix: "X\u00e3" },
          { id: "8640", name: "\u0110\u1ee9c D\u0169ng", prefix: "X\u00e3" },
          {
            id: "8639",
            name: "\u0110\u1ee9c \u0110\u1ed3ng",
            prefix: "X\u00e3",
          },
          { id: "8641", name: "\u0110\u1ee9c H\u00f2a", prefix: "X\u00e3" },
          { id: "8642", name: "\u0110\u1ee9c La", prefix: "X\u00e3" },
          { id: "8643", name: "\u0110\u1ee9c L\u1ea1c", prefix: "X\u00e3" },
          { id: "8644", name: "\u0110\u1ee9c L\u00e2m", prefix: "X\u00e3" },
          { id: "8645", name: "\u0110\u1ee9c L\u1ea1ng", prefix: "X\u00e3" },
          { id: "8646", name: "\u0110\u1ee9c L\u1eadp", prefix: "X\u00e3" },
          { id: "8647", name: "\u0110\u1ee9c Long", prefix: "X\u00e3" },
          { id: "8648", name: "\u0110\u1ee9c Nh\u00e2n", prefix: "X\u00e3" },
          { id: "8649", name: "\u0110\u1ee9c Quang", prefix: "X\u00e3" },
          { id: "8650", name: "\u0110\u1ee9c Thanh", prefix: "X\u00e3" },
          { id: "8651", name: "\u0110\u1ee9c Th\u1ecbnh", prefix: "X\u00e3" },
          {
            id: "8652",
            name: "\u0110\u1ee9c Th\u1ecd",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8653", name: "\u0110\u1ee9c Th\u1ee7y", prefix: "X\u00e3" },
          { id: "8654", name: "\u0110\u1ee9c T\u00f9ng", prefix: "X\u00e3" },
          { id: "8655", name: "\u0110\u1ee9c V\u1ecbnh", prefix: "X\u00e3" },
          { id: "8656", name: "\u0110\u1ee9c Y\u00ean", prefix: "X\u00e3" },
          { id: "8657", name: "Li\u00ean Minh", prefix: "X\u00e3" },
          { id: "8658", name: "T\u00e2n H\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8659", name: "Th\u00e1i Y\u00ean", prefix: "X\u00e3" },
          { id: "8660", name: "Trung L\u1ec5", prefix: "X\u00e3" },
          { id: "8661", name: "Tr\u01b0\u1eddng S\u01a1n", prefix: "X\u00e3" },
          { id: "8662", name: "T\u00f9ng \u1ea2nh", prefix: "X\u00e3" },
          { id: "8663", name: "Y\u00ean H\u1ed3", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "29491",
            name: "Qu\u1ed1c l\u1ed9 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29492",
            name: "T\u1ec9nh L\u1ed9 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29493",
            name: "Y\u00ean Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "539",
        name: "H\u00e0 T\u0129nh",
        wards: [
          { id: "8664", name: " Nam H\u00e0", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "8665",
            name: " Th\u1ea1ch Qu\u00fd",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "8666",
            name: " V\u0103n Y\u00ean",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "8667", name: "B\u1eafc H\u00e0", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "8668",
            name: "\u0110\u1ea1i N\u00e0i",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "8669",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "8670", name: "Nguy\u1ec5n Du", prefix: "Ph\u01b0\u1eddng" },
          { id: "8671", name: "T\u00e2n Giang", prefix: "Ph\u01b0\u1eddng" },
          { id: "8672", name: "Th\u1ea1ch B\u00ecnh", prefix: "X\u00e3" },
          { id: "8673", name: "Th\u1ea1ch \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "8674", name: "Th\u1ea1ch H\u1ea1", prefix: "X\u00e3" },
          { id: "8675", name: "Th\u1ea1ch H\u01b0ng", prefix: "X\u00e3" },
          { id: "8676", name: "Th\u1ea1ch Linh", prefix: "Ph\u01b0\u1eddng" },
          { id: "8677", name: "Th\u1ea1ch M\u00f4n", prefix: "X\u00e3" },
          { id: "8678", name: "Th\u1ea1ch Trung", prefix: "X\u00e3" },
          {
            id: "8679",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "29494", name: "26-3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29495",
            name: "B\u00f9i C\u1ea9m H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29500",
            name: "D\u01b0\u01a1ng Tr\u00ed Tr\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29496", name: "\u0110\u1eb7ng Dung", prefix: "Ph\u1ed1" },
          {
            id: "29497",
            name: "\u0110\u1eb7ng V\u0103n B\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29498",
            name: "\u0110\u1ed3ng Qu\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29499",
            name: "\u0110\u00f4ng Qu\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29501",
            name: "H\u00e0 Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29502",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29503",
            name: "H\u00e0 T\u00f4n M\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29504",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29505",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29506",
            name: "Huy C\u00e2\u0323n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29507",
            name: "L\u00ea B\u00e1 C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29508",
            name: "L\u00ea B\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29509",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29510",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29511",
            name: "L\u00ea Qu\u1ea3ng Ch\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29512",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29513",
            name: "Mai Th\u00fac Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29514",
            name: "Nam Ng\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29515",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29516",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29517",
            name: "Nguy\u1ec5n Bi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29518",
            name: "Nguy\u1ec5n Bi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29519",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29520",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29521",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29522",
            name: "Nguy\u1ec5n Ho\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29523",
            name: "Nguy\u1ec5n Ho\u00e0nh T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29524",
            name: "Nguy\u1ec5n H\u1eefu Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29525",
            name: "Nguy\u1ec5n Huy Lung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29526",
            name: "Nguy\u1ec5n Huy O\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29527", name: "Nguy\u1ec5n Huy T\u1ef1", prefix: "Ph\u1ed1" },
          {
            id: "29528",
            name: "Nguy\u1ec5n Kh\u1eafc Vi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29529",
            name: "Nguy\u1ec5n Phan Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29530",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29531",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29532",
            name: "Nguy\u1ec5n Thi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29533",
            name: "Nguy\u1ec5n Trung Thi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29534",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29535", name: "Nguy\u1ec5n X\u00ed", prefix: "Ph\u1ed1" },
          { id: "29536", name: "Nh\u1eadt T\u00e2n", prefix: "Ph\u1ed1" },
          {
            id: "29537",
            name: "Ph\u1ea1m S\u01b0 M\u1ea1nh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29538",
            name: "Phan \u0110\u00ecnh Gi\u00f3t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29539",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29540", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29541",
            name: "Qu\u1ed1c l\u1ed9 15",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29542",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29543",
            name: "T\u00e2n Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29544",
            name: "T\u1ec9nh L\u1ed9 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29545",
            name: "T\u00f4n Th\u1ea5t Thuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29546",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29547",
            name: "Tr\u1ea7n Th\u1ecb H\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29548",
            name: "V\u0103n Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29549",
            name: "V\u0169 Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29550",
            name: "X\u00f4 Vi\u1ebft Ngh\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29551",
            name: "Xu\u00e2n Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29552",
            name: "Y\u00ean \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3052",
            name: "K\u0110T B\u0103\u0301c Ha\u0300 Ti\u0303nh",
            lat: "18.35230255127",
            lng: "105.90319824219",
          },
          {
            id: "3053",
            name: "Vincity H\u00e0 T\u0129nh",
            lat: "18.355953216553",
            lng: "105.88774871826",
          },
          {
            id: "3054",
            name: "Vincom H\u00e0 T\u0129nh",
            lat: "18.290885925293",
            lng: "105.94515991211",
          },
        ],
      },
      {
        id: "540",
        name: "H\u1ed3ng L\u0129nh",
        wards: [
          {
            id: "8680",
            name: " Trung L\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "8681",
            name: "B\u1eafc H\u1ed3ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "8682",
            name: "\u0110\u1eadu Li\u00eau",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "8683",
            name: "\u0110\u1ee9c Thu\u1eadn",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "8684", name: "Nam H\u1ed3ng", prefix: "Ph\u01b0\u1eddng" },
          { id: "8685", name: "Thu\u1eadn L\u1ed9c", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "29553", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29554",
            name: "B\u00f9i C\u1ea9m H\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29555",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29556",
            name: "Ho\u00e0ng Xu\u00e2n H\u00e3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29557",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29558",
            name: "L\u00ea H\u1eefu Tr\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29559",
            name: "Mai Th\u00fac Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29560", name: "Minh Khai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29561",
            name: "Ng\u00f4 \u0110\u1ee9c K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29562",
            name: "Nguy\u1ec5n \u00c1i Qu\u1ed1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29563",
            name: "Nguy\u1ec5n Bi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29564",
            name: "Nguy\u1ec5n Nghi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29565",
            name: "Nguy\u1ec5n Sinh Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29566",
            name: "Nguy\u1ec5n Thi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29567", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29568",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29569",
            name: "Qu\u1ed1c l\u1ed9 1B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29570",
            name: "Qu\u1ed1c l\u1ed9 8A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29571",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29572",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "541",
        name: "H\u01b0\u01a1ng Kh\u00ea",
        wards: [
          { id: "8686", name: " Ph\u00fac \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "8687", name: "Gia Ph\u1ed1", prefix: "X\u00e3" },
          { id: "8688", name: "H\u00e0 Linh", prefix: "X\u00e3" },
          { id: "8689", name: "Ho\u00e0 H\u1ea3i", prefix: "X\u00e3" },
          { id: "8690", name: "H\u01b0\u01a1ng B\u00ecnh", prefix: "X\u00e3" },
          {
            id: "8691",
            name: "H\u01b0\u01a1ng \u0110\u00f4",
            prefix: "X\u00e3",
          },
          { id: "8692", name: "H\u01b0\u01a1ng Giang", prefix: "X\u00e3" },
          {
            id: "8693",
            name: "H\u01b0\u01a1ng Kh\u00ea",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8694", name: "H\u01b0\u01a1ng L\u00e2m", prefix: "X\u00e3" },
          { id: "8695", name: "H\u01b0\u01a1ng Li\u00ean", prefix: "X\u00e3" },
          { id: "8696", name: "H\u01b0\u01a1ng Long", prefix: "X\u00e3" },
          { id: "8697", name: "H\u01b0\u01a1ng Thu\u1ef7", prefix: "X\u00e3" },
          { id: "8698", name: "H\u01b0\u01a1ng Tr\u00e0", prefix: "X\u00e3" },
          { id: "8699", name: "H\u01b0\u01a1ng Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "8700", name: "H\u01b0\u01a1ng V\u0129nh", prefix: "X\u00e3" },
          { id: "8701", name: "H\u01b0\u01a1ng Xu\u00e2n", prefix: "X\u00e3" },
          { id: "8702", name: "L\u1ed9c Y\u00ean", prefix: "X\u00e3" },
          { id: "8703", name: "Ph\u00fa Gia", prefix: "X\u00e3" },
          { id: "8704", name: "Ph\u00fa Phong", prefix: "X\u00e3" },
          { id: "8705", name: "Ph\u00fac Tr\u1ea1ch", prefix: "X\u00e3" },
          {
            id: "8706",
            name: "Ph\u01b0\u01a1ng \u0110i\u1ec1n",
            prefix: "X\u00e3",
          },
          { id: "8707", name: "Ph\u01b0\u01a1ng M\u1ef9", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "29573",
            name: "H\u1ed3 Ch\u00ed Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29574",
            name: "Huy C\u00e2\u0323n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29575",
            name: "L\u00ea H\u1eefu Tr\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29576",
            name: "Long B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29577",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29578",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "542",
        name: "H\u01b0\u01a1ng S\u01a1n",
        wards: [
          { id: "8708", name: " S\u01a1n Ph\u00fac", prefix: "X\u00e3" },
          {
            id: "8709",
            name: "Ph\u1ed1 Ch\u00e2u",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8710", name: "S\u01a1n An", prefix: "X\u00e3" },
          { id: "8711", name: "S\u01a1n B\u1eb1ng", prefix: "X\u00e3" },
          { id: "8712", name: "S\u01a1n B\u00ecnh", prefix: "X\u00e3" },
          { id: "8713", name: "S\u01a1n Ch\u00e2u", prefix: "X\u00e3" },
          { id: "8714", name: "S\u01a1n Di\u1ec7m", prefix: "X\u00e3" },
          { id: "8715", name: "S\u01a1n Giang", prefix: "X\u00e3" },
          { id: "8716", name: "S\u01a1n H\u00e0", prefix: "X\u00e3" },
          { id: "8717", name: "S\u01a1n H\u00e0m", prefix: "X\u00e3" },
          { id: "8718", name: "S\u01a1n H\u00f2a", prefix: "X\u00e3" },
          { id: "8719", name: "S\u01a1n H\u1ed3ng", prefix: "X\u00e3" },
          { id: "8720", name: "S\u01a1n Kim 1", prefix: "X\u00e3" },
          { id: "8721", name: "S\u01a1n Kim 2", prefix: "X\u00e3" },
          { id: "8722", name: "S\u01a1n L\u00e2m", prefix: "X\u00e3" },
          { id: "8723", name: "S\u01a1n L\u1ec5", prefix: "X\u00e3" },
          { id: "8724", name: "S\u01a1n L\u0129nh", prefix: "X\u00e3" },
          { id: "8725", name: "S\u01a1n Long", prefix: "X\u00e3" },
          { id: "8726", name: "S\u01a1n Mai", prefix: "X\u00e3" },
          { id: "8727", name: "S\u01a1n M\u1ef9", prefix: "X\u00e3" },
          { id: "8728", name: "S\u01a1n Ninh", prefix: "X\u00e3" },
          { id: "8729", name: "S\u01a1n Ph\u00fa", prefix: "X\u00e3" },
          { id: "8730", name: "S\u01a1n Quang", prefix: "X\u00e3" },
          { id: "8731", name: "S\u01a1n T\u00e2n", prefix: "X\u00e3" },
          { id: "8732", name: "S\u01a1n T\u00e2y", prefix: "X\u00e3" },
          { id: "8733", name: "S\u01a1n Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "8734", name: "S\u01a1n Th\u1ee7y", prefix: "X\u00e3" },
          { id: "8735", name: "S\u01a1n Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "8736", name: "S\u01a1n Tr\u00e0", prefix: "X\u00e3" },
          { id: "8737", name: "S\u01a1n Trung", prefix: "X\u00e3" },
          { id: "8738", name: "S\u01a1n Tr\u01b0\u1eddng", prefix: "X\u00e3" },
          {
            id: "8739",
            name: "T\u00e2y S\u01a1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          {
            id: "29579",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29580",
            name: "Qu\u1ed1c l\u1ed9 8A",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "543",
        name: "K\u1ef3 Anh",
        wards: [
          { id: "8740", name: " K\u1ef3 L\u1ee3i", prefix: "X\u00e3" },
          { id: "8741", name: " K\u1ef3 Long", prefix: "X\u00e3" },
          { id: "8742", name: " K\u1ef3 Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8743", name: " K\u1ef3 Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "8744", name: "K\u1ef3 Anh", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "8745", name: "K\u1ef3 B\u1eafc", prefix: "X\u00e3" },
          { id: "8746", name: "K\u1ef3 Ch\u00e2u", prefix: "X\u00e3" },
          { id: "8747", name: "K\u1ef3 \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "8748", name: "K\u1ef3 Giang", prefix: "X\u00e3" },
          { id: "8749", name: "K\u1ef3 H\u00e0", prefix: "X\u00e3" },
          { id: "8750", name: "K\u1ef3 H\u1ea3i", prefix: "X\u00e3" },
          { id: "8751", name: "K\u1ef3 Hoa", prefix: "X\u00e3" },
          { id: "8752", name: "K\u1ef3 H\u1ee3p", prefix: "X\u00e3" },
          { id: "8753", name: "K\u1ef3 H\u01b0ng", prefix: "X\u00e3" },
          { id: "8754", name: "K\u1ef3 Khang", prefix: "X\u00e3" },
          { id: "8755", name: "K\u1ef3 L\u1ea1c", prefix: "X\u00e3" },
          { id: "8756", name: "K\u1ef3 L\u00e2m", prefix: "X\u00e3" },
          { id: "8757", name: "K\u1ef3 Li\u00ean", prefix: "X\u00e3" },
          { id: "8758", name: "K\u1ef3 Nam", prefix: "X\u00e3" },
          { id: "8759", name: "K\u1ef3 Ninh", prefix: "X\u00e3" },
          { id: "8760", name: "K\u1ef3 Phong", prefix: "X\u00e3" },
          { id: "8761", name: "K\u1ef3 Ph\u00fa", prefix: "X\u00e3" },
          { id: "8762", name: "K\u1ef3 S\u01a1n", prefix: "X\u00e3" },
          { id: "8763", name: "K\u1ef3 T\u00e2n", prefix: "X\u00e3" },
          { id: "8764", name: "K\u1ef3 T\u00e2y", prefix: "X\u00e3" },
          { id: "8765", name: "K\u1ef3 Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "8766", name: "K\u1ef3 Th\u1ecd", prefix: "X\u00e3" },
          { id: "8767", name: "K\u1ef3 Th\u01b0", prefix: "X\u00e3" },
          { id: "8768", name: "K\u1ef3 Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "8769", name: "K\u1ef3 Trinh", prefix: "X\u00e3" },
          { id: "8770", name: "K\u1ef3 Trung", prefix: "X\u00e3" },
          { id: "8771", name: "K\u1ef3 V\u0103n", prefix: "X\u00e3" },
          { id: "8772", name: "K\u1ef3 Xu\u00e2n", prefix: "X\u00e3" },
          {
            id: "8773",
            name: "S\u00f4ng Tr\u00ed",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          {
            id: "29581",
            name: "H\u01b0ng H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29582",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "544",
        name: "L\u1ed9c H\u00e0",
        wards: [
          { id: "8774", name: "An L\u1ed9c", prefix: "X\u00e3" },
          { id: "8775", name: "B\u00ecnh L\u1ed9c", prefix: "X\u00e3" },
          { id: "8776", name: "H\u1ed9 \u0110\u1ed9", prefix: "X\u00e3" },
          { id: "8777", name: "H\u1ed3ng L\u1ed9c", prefix: "X\u00e3" },
          { id: "8778", name: "\u00cdch H\u1eadu", prefix: "X\u00e3" },
          { id: "8779", name: "Mai Ph\u1ee5", prefix: "X\u00e3" },
          { id: "8780", name: "Ph\u00f9 L\u01b0u", prefix: "X\u00e3" },
          { id: "8781", name: "T\u00e2n L\u1ed9c", prefix: "X\u00e3" },
          { id: "8782", name: "Th\u1ea1ch B\u1eb1ng", prefix: "X\u00e3" },
          { id: "8783", name: "Th\u1ea1ch Ch\u00e2u", prefix: "X\u00e3" },
          { id: "8784", name: "Th\u1ea1ch Kim", prefix: "X\u00e3" },
          { id: "8785", name: "Th\u1ea1ch M\u1ef9", prefix: "X\u00e3" },
          { id: "8786", name: "Th\u1ecbnh L\u1ed9c", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "545",
        name: "Nghi Xu\u00e2n",
        wards: [
          { id: "8787", name: " Xu\u00e2n H\u1ea3i", prefix: "X\u00e3" },
          { id: "8788", name: " Xu\u00e2n Ph\u1ed5", prefix: "X\u00e3" },
          { id: "8789", name: "C\u1ed5 \u0110\u1ea1m", prefix: "X\u00e3" },
          { id: "8790", name: "C\u01b0\u01a1ng Gi\u00e1n", prefix: "X\u00e3" },
          { id: "8791", name: "Nghi Tr\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "8792", name: "Nghi Xu\u00e2n", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "8793", name: "Ti\u00ean \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "8794", name: "Xu\u00e2n An", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "8795", name: "Xu\u00e2n An", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "8796", name: "Xu\u00e2n \u0110an", prefix: "X\u00e3" },
          { id: "8797", name: "Xu\u00e2n Giang", prefix: "X\u00e3" },
          { id: "8798", name: "Xu\u00e2n H\u1ed9i", prefix: "X\u00e3" },
          { id: "8799", name: "Xu\u00e2n H\u1ed3ng", prefix: "X\u00e3" },
          { id: "8800", name: "Xu\u00e2n Lam", prefix: "X\u00e3" },
          { id: "8801", name: "Xu\u00e2n Li\u00ean", prefix: "X\u00e3" },
          { id: "8802", name: "Xu\u00e2n L\u0129nh", prefix: "X\u00e3" },
          { id: "8803", name: "Xu\u00e2n M\u1ef9", prefix: "X\u00e3" },
          { id: "8804", name: "Xu\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "8805", name: "Xu\u00e2n Tr\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "8806", name: "Xu\u00e2n Vi\u00ean", prefix: "X\u00e3" },
          { id: "8807", name: "Xu\u00e2n Y\u00ean", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "29583",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29584",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29585",
            name: "Qu\u1ed1c l\u1ed9 8B",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "546",
        name: "Th\u1ea1ch H\u00e0",
        wards: [
          { id: "8808", name: "B\u1eafc S\u01a1n", prefix: "X\u00e3" },
          { id: "8809", name: "Nam H\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8810", name: "Ng\u1ecdc S\u01a1n ", prefix: "X\u00e3" },
          { id: "8811", name: "Ph\u00f9 Vi\u1ec7t", prefix: "X\u00e3" },
          { id: "8812", name: "Th\u1ea1ch B\u00e0n", prefix: "X\u00e3" },
          { id: "8813", name: "Th\u1ea1ch \u0110\u00e0i", prefix: "X\u00e3" },
          { id: "8814", name: "Th\u1ea1ch \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "8815", name: "Th\u1ea1ch \u0110\u1ec9nh", prefix: "X\u00e3" },
          {
            id: "8816",
            name: "Th\u1ea1ch H\u00e0",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8817", name: "Th\u1ea1ch H\u1ea3i", prefix: "X\u00e3" },
          { id: "8818", name: "Th\u1ea1ch H\u1ed9i", prefix: "X\u00e3" },
          { id: "8819", name: "Th\u1ea1ch H\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8820", name: "Th\u1ea1ch K\u00eanh", prefix: "X\u00e3" },
          { id: "8821", name: "Th\u1ea1ch Kh\u00ea", prefix: "X\u00e3" },
          { id: "8822", name: "Th\u1ea1ch L\u1ea1c", prefix: "X\u00e3" },
          { id: "8823", name: "Th\u1ea1ch L\u00e2m", prefix: "X\u00e3" },
          { id: "8824", name: "Th\u1ea1ch Li\u00ean", prefix: "X\u00e3" },
          { id: "8825", name: "Th\u1ea1ch Long", prefix: "X\u00e3" },
          { id: "8826", name: "Th\u1ea1ch L\u01b0u", prefix: "X\u00e3" },
          { id: "8827", name: "Th\u1ea1ch Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "8828", name: "Th\u1ea1ch S\u01a1n", prefix: "X\u00e3" },
          { id: "8829", name: "Th\u1ea1ch T\u00e2n", prefix: "X\u00e3" },
          { id: "8830", name: "Th\u1ea1ch Th\u1eafng", prefix: "X\u00e3" },
          { id: "8831", name: "Th\u1ea1ch Thanh", prefix: "X\u00e3" },
          { id: "8832", name: "Th\u1ea1ch Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "8833", name: "Th\u1ea1ch Tr\u1ecb", prefix: "X\u00e3" },
          { id: "8834", name: "Th\u1ea1ch V\u0103n", prefix: "X\u00e3" },
          { id: "8835", name: "Th\u1ea1ch V\u0129nh", prefix: "X\u00e3" },
          { id: "8836", name: "Th\u1ea1ch Xu\u00e2n", prefix: "X\u00e3" },
          { id: "8837", name: "T\u01b0\u1ee3ng S\u01a1n", prefix: "X\u00e3" },
          { id: "8838", name: "Vi\u1ec7t Xuy\u00ean", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "29586", name: "1A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29587",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29588",
            name: "T\u1ec9nh L\u1ed9 17",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29589",
            name: "T\u1ec9nh L\u1ed9 26",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29590",
            name: "T\u1ec9nh L\u1ed9 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29591",
            name: "T\u1ec9nh l\u1ed9 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29592",
            name: "Trung V\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "547",
        name: "V\u0169 Quang",
        wards: [
          { id: "8839", name: "\u00c2n Ph\u00fa", prefix: "X\u00e3" },
          { id: "8840", name: "\u0110\u1ee9c B\u1ed3ng", prefix: "X\u00e3" },
          { id: "8841", name: "\u0110\u1ee9c Giang", prefix: "X\u00e3" },
          {
            id: "8842",
            name: "\u0110\u1ee9c H\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "8843", name: "\u0110\u1ee9c Li\u00ean", prefix: "X\u00e3" },
          { id: "8844", name: "\u0110\u1ee9c L\u0129nh", prefix: "X\u00e3" },
          {
            id: "8845",
            name: "H\u01b0\u01a1ng \u0110i\u1ec1n",
            prefix: "X\u00e3",
          },
          { id: "8846", name: "H\u01b0\u01a1ng Minh", prefix: "X\u00e3" },
          { id: "8847", name: "H\u01b0\u01a1ng Quang", prefix: "X\u00e3" },
          { id: "8848", name: "H\u01b0\u01a1ng Th\u1ecd", prefix: "X\u00e3" },
          { id: "8849", name: "S\u01a1n Th\u1ecd", prefix: "X\u00e3" },
          { id: "8850", name: "V\u0169 Quang", prefix: "Th\u1ecb tr\u1ea5n" },
        ],
        streets: [],
        projects: [],
      },
    ],
  },
  {
    id: "47",
    code: "DDT",
    name: "\u0110\u1ed3ng Th\u00e1p",
    districts: [
      {
        id: "548",
        name: "Cao L\u00e3nh",
        wards: [
          { id: "8851", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "8852", name: "11", prefix: "Ph\u01b0\u1eddng" },
          { id: "8853", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "8854", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "8855", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "8856", name: "6", prefix: "Ph\u01b0\u1eddng" },
          { id: "8857", name: "Ho\u00e0 An", prefix: "X\u00e3" },
          {
            id: "8858",
            name: "Ho\u00e0 Thu\u1eadn",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "8859", name: "M\u1ef9 Ng\u00e3i", prefix: "X\u00e3" },
          { id: "8860", name: "M\u1ef9 Ph\u00fa", prefix: "Ph\u01b0\u1eddng" },
          { id: "8861", name: "M\u1ef9 Ph\u00fa", prefix: "Ph\u01b0\u1eddng" },
          { id: "8862", name: "M\u1ef9 T\u00e2n", prefix: "X\u00e3" },
          { id: "8863", name: "M\u1ef9 Tr\u00e0", prefix: "X\u00e3" },
          {
            id: "8864",
            name: "T\u00e2n Thu\u1eadn \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "8865",
            name: "T\u00e2n Thu\u1eadn T\u00e2y",
            prefix: "X\u00e3",
          },
          { id: "8866", name: "T\u1ecbnh Th\u1edbi", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "29593", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29594", name: "An Nh\u01a1n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29595",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          { id: "29596", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "29597",
            name: "B\u00f9i H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29598",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29599", name: "C\u00e1i Sao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29600",
            name: "C\u00e1i T\u00f4m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29601",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29602",
            name: "Chi L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29603",
            name: "\u0110\u1eb7ng V\u0103n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29604",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29605",
            name: "\u0110inh B\u1ed9 L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29606",
            name: "\u0110\u1ed7 Th\u1ecb \u0110\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29607",
            name: "\u0110\u1ed7 V\u0103n Y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29608", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "29609",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29610",
            name: "H\u00f2a \u0110\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29611",
            name: "H\u00f2a T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29612",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29613",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29614",
            name: "Hu\u1ef3nh V\u0103n Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29615",
            name: "L\u00ea Anh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29617",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29616",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29618",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29619",
            name: "L\u00ea V\u0103n \u0110\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29620",
            name: "L\u00ea V\u0103n M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29621",
            name: "L\u00ea V\u0103n T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29622",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29623",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29624",
            name: "Mai V\u0103n Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29625",
            name: "Ng\u00f4 Th\u00ec Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29626",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29627",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29628",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29629",
            name: "Nguy\u1ec5n H\u1eefu Ki\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29630",
            name: "Nguy\u1ec5n Quang Di\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29631",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29632",
            name: "Nguy\u1ec5n Th\u1ecb L\u1ef1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29633",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29634",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29635",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29636",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29637",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29638",
            name: "\u00d4ng Th\u1ee3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29639",
            name: "Ph\u1ea1m H\u1eefu L\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29640",
            name: "Ph\u1ea1m Th\u1ecb U\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29641",
            name: "Phan Th\u1ecb Hu\u1ef3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29642",
            name: "Ph\u00f9ng Kh\u1eafc Khoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29643",
            name: "Qu\u1ed1c L\u1ed9 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29644", name: "S\u1ed1 9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29645",
            name: "T\u1eafc Th\u1ea7y Cai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29646",
            name: "T\u00e2n Vi\u1ec7t Ho\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29647",
            name: "Thi\u00ean H\u1ed9 D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29648",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29649",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29650",
            name: "Tr\u1ea7n H\u1eefu Trang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29651",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29652",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29653",
            name: "Tr\u1ea7n T\u1ebf X\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29654",
            name: "Tr\u1ea7n Th\u1ecb Nh\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29655",
            name: "Tr\u1ea7n V\u0103n Th\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29656",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29657",
            name: "V\u00f5 Tr\u01b0\u1eddng To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29658",
            name: "V\u00f5 V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "549",
        name: "Ch\u00e2u Th\u00e0nh",
        wards: [
          { id: "8867", name: "An Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "8868", name: "An Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "8869", name: "An Nh\u01a1n", prefix: "X\u00e3" },
          { id: "8870", name: "An Ph\u00fa Thu\u1eadn", prefix: "X\u00e3" },
          {
            id: "8871",
            name: "C\u00e1i T\u00e0u H\u1ea1",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8872", name: "H\u00f2a T\u00e2n", prefix: "X\u00e3" },
          { id: "8873", name: "Ph\u00fa H\u1ef1u", prefix: "X\u00e3" },
          { id: "8874", name: "Ph\u00fa Long", prefix: "X\u00e3" },
          { id: "8875", name: "T\u00e2n B\u00ecnh", prefix: "X\u00e3" },
          {
            id: "8876",
            name: "T\u00e2n Nhu\u1eadn \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "8877", name: "T\u00e2n Ph\u00fa", prefix: "X\u00e3" },
          { id: "8878", name: "T\u00e2n Ph\u00fa Trung", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "29659",
            name: "Nguy\u1ec5n V\u0103n Voi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29660",
            name: "Qu\u1ed1c l\u1ed9 80",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29661",
            name: "T\u1ec9nh L\u1ed9 853",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29662",
            name: "T\u1ec9nh l\u1ed9 854",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "550",
        name: "H\u1ed3ng Ng\u1ef1",
        wards: [
          { id: "8879", name: "An B\u00ecnh A", prefix: "X\u00e3" },
          { id: "8880", name: "An B\u00ecnh B", prefix: "X\u00e3" },
          { id: "8881", name: "An L\u1ea1c", prefix: "Ph\u01b0\u1eddng" },
          { id: "8882", name: "An L\u1ed9c", prefix: "Ph\u01b0\u1eddng" },
          { id: "8883", name: "An Th\u1ea1nh", prefix: "Ph\u01b0\u1eddng" },
          { id: "8884", name: "B\u00ecnh Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "8885", name: "T\u00e2n H\u1ed9i", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "29663",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29664", name: "\u0110T 841", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29665",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29666",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29667",
            name: "L\u00ea Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29668",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29669",
            name: "Nam K\u1ef3 Kh\u1edfi Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29670",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29671",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29672",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29673",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29674",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29675",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29676",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29677",
            name: "Ph\u1ea1m H\u00f9ng D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29678",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29679",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29680",
            name: "Qu\u1ed1c L\u1ed9 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29681",
            name: "T\u1ec9nh l\u1ed9 841",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29682",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29683",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29684",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29685",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "551",
        name: "Huy\u1ec7n Cao L\u00e3nh",
        wards: [
          { id: "8886", name: "An B\u00ecnh", prefix: "X\u00e3" },
          { id: "8887", name: "Ba Sao", prefix: "X\u00e3" },
          {
            id: "8888",
            name: "B\u00ecnh H\u00e0ng T\u00e2y",
            prefix: "X\u00e3",
          },
          { id: "8889", name: "B\u00ecnh H\u00e0ng Trung", prefix: "X\u00e3" },
          { id: "8890", name: "B\u00ecnh Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "8891", name: "G\u00e1o Gi\u1ed3ng", prefix: "X\u00e3" },
          { id: "8892", name: "M\u1ef9 Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "8893", name: "M\u1ef9 H\u1ed9i", prefix: "X\u00e3" },
          { id: "8894", name: "M\u1ef9 Long", prefix: "X\u00e3" },
          {
            id: "8895",
            name: "M\u1ef9 Th\u1ecd",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8896", name: "M\u1ef9 X\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8897", name: "Nh\u1ecb M\u1ef9", prefix: "X\u00e3" },
          { id: "8898", name: "Phong M\u1ef9", prefix: "X\u00e3" },
          {
            id: "8899",
            name: "Ph\u01b0\u01a1ng Th\u1ecbnh",
            prefix: "X\u00e3",
          },
          { id: "8900", name: "Ph\u01b0\u01a1ng Tr\u00e0", prefix: "X\u00e3" },
          { id: "8901", name: "T\u00e2n H\u1ed9i Trung", prefix: "X\u00e3" },
          { id: "8902", name: "T\u00e2n Ngh\u0129a", prefix: "X\u00e3" },
          { id: "8903", name: "X\u00e3 M\u1ef9 Th\u1ecd", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "29686", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29687", name: "307", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29688",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          { id: "29689", name: "Ba Sao", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29690", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "29691",
            name: "B\u00ecnh Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29692",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29693",
            name: "B\u00f9i V\u0103n K\u00e9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29694",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29695", name: "C\u00e1i Sao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29696",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29697",
            name: "C\u1ea7u \u0110\u00f4i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29698",
            name: "Chi L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29699", name: "Dal", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29700", name: "Dale", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29708", name: "Duy T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29701",
            name: "\u0110\u1eb7ng V\u0103n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29702",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29703",
            name: "\u0110inh B\u1ed9 L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29704",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29705",
            name: "\u0110\u1ed7 C\u00f4ng T\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29706",
            name: "\u0110\u1ed1c Binh Ki\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29707",
            name: "\u0110\u1ed1ng \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29709",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29710",
            name: "H\u00f2a T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29711",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29712",
            name: "L\u00ea Anh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29714",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29713",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29715",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29716",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29717",
            name: "L\u00ea V\u0103n C\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29718",
            name: "L\u00ea V\u0103n \u0110\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29719",
            name: "L\u00ea V\u0103n H\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29720",
            name: "L\u00ea V\u0103n M\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29721",
            name: "L\u1ed9 Ho\u00e0 T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29722",
            name: "L\u1ed9 M\u1edbi X\u00e3 T\u00e2n Thu\u1eadn T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29723",
            name: "L\u00fd C\u00f4ng U\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29724",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29725",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29726",
            name: "Mai V\u0103n Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29727",
            name: "M\u01b0\u01a1ng Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29728",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29729",
            name: "Ng\u00f4 S\u1ef9 Li\u00ean",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29730",
            name: "Ng\u00f4 Th\u1eddi Nh\u1eadm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29731",
            name: "Nguy\u1ec5n Ch\u00ed Tranh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29732",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29733",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29734",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29735",
            name: "Nguy\u1ec5n H\u1eefu Ki\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29736",
            name: "Nguy\u1ec5n Minh Tr\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29737",
            name: "Nguy\u1ec5n Quang Di\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29738",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29739",
            name: "Nguy\u1ec5n Th\u1ecb L\u1ef1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29740",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29741",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29742",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29743",
            name: "Nguy\u1ec5n V\u0103n B\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29744",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29745",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29746",
            name: "Nguy\u1ec5n V\u0103n Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29747",
            name: "Nguy\u1ec5n V\u0103n Ti\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29748",
            name: "Nguy\u1ec5n V\u0103n Tre",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29749",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29750",
            name: "\u00d4ng C\u1ea3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29751",
            name: "\u00d4ng Th\u1ee3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29752",
            name: "Ph\u1ea1m H\u1eefu L\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29753",
            name: "Ph\u1ea1m Th\u1ebf Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29754",
            name: "Ph\u1ea1m V\u0103n C\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29755",
            name: "Ph\u00f9 \u0110\u1ed5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29756", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "29757",
            name: "Ph\u00f9ng Kh\u1eafc Khoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29758",
            name: "Qu\u1ed1c L\u1ed9 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29759",
            name: "R\u1ea1ch X\u00e9p L\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29760", name: "S\u1ed1 48", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29761",
            name: "T\u1eafc Th\u1ea7y Cai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29762",
            name: "Th\u00e1p M\u01b0\u1eddi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29763",
            name: "Thi\u00ean H\u1ed9 D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29764",
            name: "Th\u1ed1ng Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29765",
            name: "Th\u1ee7 Khoa Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29766",
            name: "T\u1ec9nh L\u1ed9 650",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29767",
            name: "T\u1ec9nh l\u1ed9 850",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29768",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29769",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29770",
            name: "Tr\u1ea7n H\u1eefu Trang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29771",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29772",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29773",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29774",
            name: "Tr\u1ea7n Th\u1ecb Thu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29775",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29776",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29777",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29778",
            name: "V\u00f5 Tr\u01b0\u1eddng To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29779",
            name: "x\u00e3 T\u00e2n Thu\u1eadn T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "552",
        name: "Huy\u1ec7n H\u1ed3ng Ng\u1ef1",
        wards: [
          { id: "8904", name: "Long Kh\u00e1nh A", prefix: "X\u00e3" },
          { id: "8905", name: "Long Kh\u00e1nh B", prefix: "X\u00e3" },
          { id: "8906", name: "Long Thu\u1eadn", prefix: "X\u00e3" },
          { id: "8907", name: "Ph\u00fa Thu\u1eadn A", prefix: "X\u00e3" },
          { id: "8908", name: "Ph\u00fa Thu\u1eadn B", prefix: "X\u00e3" },
          { id: "8909", name: "Th\u01b0\u1eddng L\u1ea1c", prefix: "X\u00e3" },
          {
            id: "8910",
            name: "Th\u01b0\u1eddng Ph\u01b0\u1edbc 1",
            prefix: "X\u00e3",
          },
          {
            id: "8911",
            name: "Th\u01b0\u1eddng Ph\u01b0\u1edbc 2",
            prefix: "X\u00e3",
          },
          {
            id: "8912",
            name: "Th\u01b0\u1eddng Th\u1edbi H\u1eadu A",
            prefix: "X\u00e3",
          },
          {
            id: "8913",
            name: "Th\u01b0\u1eddng Th\u1edbi H\u1eadu B",
            prefix: "X\u00e3",
          },
          {
            id: "8914",
            name: "Th\u01b0\u1eddng Th\u1edbi Ti\u1ec1n",
            prefix: "X\u00e3",
          },
        ],
        streets: [
          {
            id: "29780",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "553",
        name: "Lai Vung",
        wards: [
          { id: "8915", name: "\u0110\u1ecbnh H\u00f2a", prefix: "X\u00e3" },
          { id: "8916", name: "H\u00f2a Long", prefix: "X\u00e3" },
          { id: "8917", name: "H\u00f2a Th\u00e0nh", prefix: "X\u00e3" },
          { id: "8918", name: "Lai Vung", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "8919", name: "Long H\u1eadu", prefix: "X\u00e3" },
          { id: "8920", name: "Long Th\u1eafng", prefix: "X\u00e3" },
          { id: "8921", name: "Phong H\u00f2a", prefix: "X\u00e3" },
          { id: "8922", name: "T\u00e2n D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "8923", name: "T\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "8924", name: "T\u00e2n Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "8925", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "8926", name: "V\u0129nh Th\u1edbi", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "29781", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29782", name: "\u0110T 851", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29783",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29784",
            name: "Qu\u1ed1c l\u1ed9 54",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29785",
            name: "Qu\u1ed1c l\u1ed9 80",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29786",
            name: "T\u1ec9nh L\u1ed9 851",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29787",
            name: "T\u1ec9nh l\u1ed9 852",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29788",
            name: "Xa L\u1ed9 Lai Vung",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "554",
        name: "L\u1ea5p V\u00f2",
        wards: [
          { id: "8927", name: "B\u00ecnh Th\u00e0nh", prefix: "X\u00e3" },
          { id: "8928", name: "B\u00ecnh Th\u1ea1nh Trung", prefix: "X\u00e3" },
          { id: "8929", name: "\u0110\u1ecbnh An", prefix: "X\u00e3" },
          { id: "8930", name: "\u0110\u1ecbnh Y\u00ean", prefix: "X\u00e3" },
          { id: "8931", name: "H\u1ed9i An \u0110\u00f4ng", prefix: "X\u00e3" },
          {
            id: "8932",
            name: "L\u1ea5p V\u00f2",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "8933", name: "Long H\u01b0ng A", prefix: "X\u00e3" },
          { id: "8934", name: "Long H\u01b0ng B", prefix: "X\u00e3" },
          { id: "8935", name: "M\u1ef9 An H\u01b0ng A", prefix: "X\u00e3" },
          { id: "8936", name: "M\u1ef9 An H\u01b0ng B", prefix: "X\u00e3" },
          { id: "8937", name: "T\u00e2n Kh\u00e1nh Trung", prefix: "X\u00e3" },
          { id: "8938", name: "T\u00e2n M\u1ef9", prefix: "X\u00e3" },
          { id: "8939", name: "V\u0129nh Th\u1ea1nh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "29789", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          { id: "29790", name: "\u0110T 848", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29791", name: "\u0110T 849", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29792",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29793",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29794",
            name: "Qu\u1ed1c l\u1ed9 54",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29795",
            name: "Qu\u1ed1c l\u1ed9 80",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29796",
            name: "T\u1ec9nh l\u1ed9 852",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29797",
            name: "T\u1ec9nh L\u1ed9 852B",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "555",
        name: "Sa \u0110\u00e9c",
        wards: [
          { id: "8940", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "8941", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "8942", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "8943", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "8944", name: "An H\u00f2a", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "8945",
            name: "T\u00e2n Kh\u00e1nh \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "8946",
            name: "T\u00e2n Ph\u00fa \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "8947",
            name: "T\u00e2n Quy \u0110\u00f4ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "8948", name: "T\u00e2n Quy T\u00e2y", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "29798", name: "853", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29799",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29800",
            name: "Dinh H\u1eefu Thu\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29801",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29802",
            name: "\u0110\u1ed3 Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29803", name: "\u0110T 848", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29804", name: "\u0110T 852", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29805", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "29806",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29807",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29808",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29809",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29810",
            name: "Ki\u1ec1u H\u1ea1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29811",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29812",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29813",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29814",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29815",
            name: "L\u00ea V\u0103n Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29816",
            name: "L\u01b0u V\u0103n Lang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29817",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29818",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29819",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29820",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29821",
            name: "Nguy\u1ec5n C\u01b0 Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29822",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29823",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29824",
            name: "Nguy\u1ec5n H\u1eefu Thu\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29825",
            name: "Nguy\u1ec5n Sinh S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29826",
            name: "Nguy\u1ec5n T\u1ea5n Ph\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29827",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29828",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29829",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29830",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29831",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29832",
            name: "Ph\u1ea1m H\u1eefu L\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29833",
            name: "Ph\u1ea1m V\u0103n \u00dat",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29834",
            name: "Ph\u1ea1m V\u0103n V\u1ebb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29835",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29836",
            name: "Qu\u1ed1c l\u1ed9 80",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29837",
            name: "T\u00e2n L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29838",
            name: "T\u1ec9nh L\u1ed9 848",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29839",
            name: "T\u1ec9nh l\u1ed9 852",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29840",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29841",
            name: "Tr\u1ea1m B\u01a1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29842",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29843",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29844",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29845",
            name: "Tr\u1ea7n Th\u1ecb Nh\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29846",
            name: "Tr\u1ea7n V\u0103n Voi",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "556",
        name: "Tam N\u00f4ng",
        wards: [
          { id: "8949", name: "An H\u00f2a", prefix: "X\u00e3" },
          { id: "8950", name: "An Long", prefix: "X\u00e3" },
          { id: "8951", name: "H\u00f2a B\u00ecnh", prefix: "X\u00e3" },
          { id: "8952", name: "Ph\u00fa C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "8953", name: "Ph\u00fa \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "8954", name: "Ph\u00fa Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "8955", name: "Ph\u00fa Ninh", prefix: "X\u00e3" },
          { id: "8956", name: "Ph\u00fa Th\u00e0nh A", prefix: "X\u00e3" },
          { id: "8957", name: "Ph\u00fa Th\u00e0nh B", prefix: "X\u00e3" },
          { id: "8958", name: "Ph\u00fa Th\u1ecd", prefix: "X\u00e3" },
          {
            id: "8959",
            name: "T\u00e2n C\u00f4ng S\u00ednh",
            prefix: "X\u00e3",
          },
          { id: "8960", name: "Tr\u00e0m Chim", prefix: "Th\u1ecb tr\u1ea5n" },
        ],
        streets: [
          { id: "29847", name: "\u0110T 844", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29848",
            name: "Qu\u1ed1c L\u1ed9 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29849",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "557",
        name: "T\u00e2n H\u1ed3ng",
        wards: [
          { id: "8961", name: "An Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "8962", name: "B\u00ecnh Ph\u00fa", prefix: "X\u00e3" },
          { id: "8963", name: "Sa R\u00e0i", prefix: "Th\u1ecb tr\u1ea5n" },
          {
            id: "8964",
            name: "T\u00e2n C\u00f4ng Ch\u00ed",
            prefix: "X\u00e3",
          },
          { id: "8965", name: "T\u00e2n H\u1ed9 C\u01a1", prefix: "X\u00e3" },
          { id: "8966", name: "T\u00e2n Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "8967", name: "T\u00e2n Th\u00e0nh A", prefix: "X\u00e3" },
          { id: "8968", name: "T\u00e2n Th\u00e0nh B", prefix: "X\u00e3" },
          { id: "8969", name: "Th\u00f4ng B\u00ecnh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "29850", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "29851",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "558",
        name: "Thanh B\u00ecnh",
        wards: [
          { id: "8970", name: "An Phong", prefix: "X\u00e3" },
          { id: "8971", name: "B\u00ecnh T\u1ea5n", prefix: "X\u00e3" },
          { id: "8972", name: "B\u00ecnh Th\u00e0nh", prefix: "X\u00e3" },
          { id: "8973", name: "Ph\u00fa L\u1ee3i", prefix: "X\u00e3" },
          { id: "8974", name: "T\u00e2n B\u00ecnh", prefix: "X\u00e3" },
          { id: "8975", name: "T\u00e2n Ho\u00e0", prefix: "X\u00e3" },
          { id: "8976", name: "T\u00e2n Hu\u1ec1", prefix: "X\u00e3" },
          { id: "8977", name: "T\u00e2n Long", prefix: "X\u00e3" },
          { id: "8978", name: "T\u00e2n M\u1ef9", prefix: "X\u00e3" },
          { id: "8979", name: "T\u00e2n Ph\u00fa", prefix: "X\u00e3" },
          { id: "8980", name: "T\u00e2n Qu\u1edbi", prefix: "X\u00e3" },
          { id: "8981", name: "T\u00e2n Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "8982", name: "Thanh B\u00ecnh", prefix: "Th\u1ecb tr\u1ea5n" },
        ],
        streets: [
          {
            id: "29852",
            name: "Qu\u1ed1c L\u1ed9 30",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "559",
        name: "Th\u00e1p M\u01b0\u1eddi",
        wards: [
          {
            id: "8983",
            name: "\u0110\u1ed1c Binh Ki\u1ec1u",
            prefix: "X\u00e3",
          },
          { id: "8984", name: "H\u01b0ng Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "8985", name: "L\u00e1ng Bi\u1ec3n", prefix: "X\u00e3" },
          { id: "8986", name: "M\u1ef9 An", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "8987", name: "M\u1ef9 An", prefix: "X\u00e3" },
          { id: "8988", name: "M\u1ef9 \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "8989", name: "M\u1ef9 H\u00f2a", prefix: "X\u00e3" },
          { id: "8990", name: "M\u1ef9 Qu\u00fd", prefix: "X\u00e3" },
          { id: "8991", name: "Ph\u00fa \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "8992", name: "T\u00e2n Ki\u1ec1u", prefix: "X\u00e3" },
          { id: "8993", name: "Th\u1ea1nh L\u1ee3i", prefix: "X\u00e3" },
          { id: "8994", name: "Thanh M\u1ef9", prefix: "X\u00e3" },
          { id: "8995", name: "Tr\u01b0\u1eddng Xu\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "29853",
            name: "G\u00f2 Th\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29854", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "29855",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "48",
    code: "ST",
    name: "S\u00f3c Tr\u0103ng",
    districts: [
      {
        id: "560",
        name: "Ch\u00e2u Th\u00e0nh",
        wards: [
          { id: "8996", name: "An Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "8997", name: "An Ninh", prefix: "X\u00e3" },
          {
            id: "8998",
            name: "Ch\u00e2u Th\u00e0nh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "8999",
            name: "H\u1ed3 \u0110\u1eafc Ki\u1ec7n",
            prefix: "X\u00e3",
          },
          { id: "9000", name: "Ph\u00fa T\u00e2m", prefix: "X\u00e3" },
          { id: "9001", name: "Ph\u00fa T\u00e2n", prefix: "X\u00e3" },
          { id: "9002", name: "Thi\u1ec7n M\u1ef9", prefix: "X\u00e3" },
          { id: "9003", name: "Thu\u1eadn H\u00f2a", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "29856", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29857", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29858",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29859",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "29860", name: "Dal", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29861",
            name: "\u0110\u01b0\u1eddng S\u00f3c V\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29862",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29863",
            name: "K\u00eanh H\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29864",
            name: "L\u01b0\u01a1ng \u0110\u1ecbnh C\u1ee7a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29865",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29866",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29867",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29868",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29869",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29870",
            name: "T\u1ec9nh L\u1ed9 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29871",
            name: "T\u1ec9nh l\u1ed9 932",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29872",
            name: "T\u1ec9nh l\u1ed9 938",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29873",
            name: "T\u1ec9nh l\u1ed9 939",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29874",
            name: "T\u1ec9nh l\u1ed9 939B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29875",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29876",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "561",
        name: "C\u00f9 Lao Dung",
        wards: [
          { id: "9004", name: "An Th\u1ea1nh 1", prefix: "X\u00e3" },
          { id: "9005", name: "An Th\u1ea1nh 2", prefix: "X\u00e3" },
          { id: "9006", name: "An Th\u1ea1nh 3", prefix: "X\u00e3" },
          {
            id: "9007",
            name: "An Th\u1ea1nh \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "9008", name: "An Th\u1ea1nh Nam", prefix: "X\u00e3" },
          { id: "9009", name: "An Th\u1ea1nh T\u00e2y", prefix: "X\u00e3" },
          {
            id: "9010",
            name: "C\u00f9 Lao Dung",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9011", name: "\u0110\u1ea1i \u00c2n 1", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "29877", name: "Dal", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29878",
            name: "\u0110o\u00e0n Th\u1ebf Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29879",
            name: "\u0110\u1ed3ng Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29880",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29881",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29882",
            name: "T\u1ec9nh l\u1ed9 933B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29883",
            name: "T\u1ec9nh l\u1ed9 934",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "562",
        name: "K\u1ebf S\u00e1ch",
        wards: [
          { id: "9012", name: "An L\u1ea1c T\u00e2y", prefix: "X\u00e3" },
          { id: "9013", name: "An L\u1ea1c Th\u00f4n", prefix: "X\u00e3" },
          { id: "9014", name: "An M\u1ef9", prefix: "X\u00e3" },
          { id: "9015", name: "Ba Trinh", prefix: "X\u00e3" },
          { id: "9016", name: "\u0110\u1ea1i H\u1ea3i", prefix: "X\u00e3" },
          { id: "9017", name: "K\u1ebf An", prefix: "X\u00e3" },
          {
            id: "9018",
            name: "K\u1ebf S\u00e1ch",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9019", name: "K\u1ebf Th\u00e0nh", prefix: "X\u00e3" },
          { id: "9020", name: "Nh\u01a1n M\u1ef9", prefix: "X\u00e3" },
          { id: "9021", name: "Phong N\u1eabm", prefix: "X\u00e3" },
          { id: "9022", name: "Th\u1edbi An H\u1ed9i", prefix: "X\u00e3" },
          { id: "9023", name: "Trinh Ph\u00fa", prefix: "X\u00e3" },
          { id: "9024", name: "Xu\u00e2n H\u00f2a", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "29884", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29885", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29886", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29887", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29888", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29889", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29890", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29891",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29892",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29893",
            name: "L\u00ea V\u0103n L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29894",
            name: "L\u00ea V\u0103n T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29895",
            name: "Nam S\u00f4ng H\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29896",
            name: "Nguy\u1ec5n Ho\u00e0ng Huy",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29897",
            name: "Nguy\u1ec5n Trung T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29898",
            name: "Nguy\u1ec5n V\u0103n Th\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29899",
            name: "Phan V\u0103n H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29900",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29901",
            name: "T\u1ec9nh l\u1ed9 932",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29902",
            name: "T\u1ec9nh l\u1ed9 932B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29903",
            name: "Ung C\u00f4ng U\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29904",
            name: "V\u00f2ng Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "563",
        name: "Long Ph\u00fa",
        wards: [
          { id: "9025", name: "Ch\u00e2u Kh\u00e1nh", prefix: "X\u00e3" },
          {
            id: "9026",
            name: "\u0110\u1ea1i Ng\u00e3i",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9027", name: "H\u1eadu Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "9028", name: "Long \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "9029", name: "Long Ph\u00fa", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "9030", name: "Long Ph\u00fa", prefix: "X\u00e3" },
          { id: "9031", name: "Ph\u00fa H\u1eefu", prefix: "X\u00e3" },
          { id: "9032", name: "Song Ph\u1ee5ng", prefix: "X\u00e3" },
          { id: "9033", name: "T\u00e2n H\u01b0ng", prefix: "X\u00e3" },
          { id: "9034", name: "T\u00e2n Th\u1ea1nh", prefix: "X\u00e3" },
          {
            id: "9035",
            name: "Tr\u01b0\u1eddng Kh\u00e1nh",
            prefix: "X\u00e3",
          },
        ],
        streets: [
          { id: "29905", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29906", name: "28", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29907", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29908", name: "60", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29909", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29911", name: "Di Long", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29910",
            name: "\u0110\u1eb7ng Minh Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29912",
            name: "\u0110o\u00e0n Th\u1ebf Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29913",
            name: "\u0110\u01b0\u1eddng \u0111al C\u00e1i Quanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29914",
            name: "L\u01b0\u01a1ng \u0110\u1ecbnh C\u1ee7a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29915",
            name: "Nam S\u00f4ng H\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29916",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29917",
            name: "T\u1ec9nh l\u1ed9 933",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29918",
            name: "T\u1ec9nh l\u1ed9 933",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "564",
        name: "M\u1ef9 T\u00fa",
        wards: [
          { id: "9036", name: "H\u01b0ng Ph\u00fa", prefix: "X\u00e3" },
          {
            id: "9037",
            name: "Hu\u1ef3nh H\u1eefu Ngh\u0129a",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9038", name: "Long H\u01b0ng", prefix: "X\u00e3" },
          { id: "9039", name: "M\u1ef9 H\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "9040", name: "M\u1ef9 Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "9041", name: "M\u1ef9 Thu\u1eadn", prefix: "X\u00e3" },
          { id: "9042", name: "M\u1ef9 T\u00fa", prefix: "X\u00e3" },
          { id: "9043", name: "Ph\u00fa M\u1ef9", prefix: "X\u00e3" },
          { id: "9044", name: "Thu\u1eadn H\u01b0ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "29919", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29920", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29921", name: "13", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29922", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29923", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29924", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29925", name: "25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29926", name: "26", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29927", name: "27", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29928", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29929", name: "30", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29930", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29931", name: "42", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29932", name: "60", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29933", name: "940", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29934", name: "Dal", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29935",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29936",
            name: "\u0110\u1ed3ng Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29937",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29938",
            name: "Hu\u1ef3nh V\u0103n Tri\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29939",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29940",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29941",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29942",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29943",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29944",
            name: "Qu\u1ea3n l\u1ed9 Ph\u1ee5ng Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29945",
            name: "Qu\u1ed1c l\u1ed9 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29946",
            name: "T\u1ec9nh l\u1ed9 938",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29947",
            name: "T\u1ec9nh l\u1ed9 939",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29948",
            name: "T\u1ec9nh l\u1ed9 940",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29949",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29950",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29951",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "565",
        name: "M\u1ef9 Xuy\u00ean",
        wards: [
          { id: "9045", name: "\u0110\u1ea1i T\u00e2m", prefix: "X\u00e3" },
          { id: "9046", name: "Gia H\u00f2a 1", prefix: "X\u00e3" },
          { id: "9047", name: "Gia H\u00f2a 2", prefix: "X\u00e3" },
          { id: "9048", name: "H\u00f2a T\u00fa 1", prefix: "X\u00e3" },
          { id: "9049", name: "H\u00f2a T\u00fa 2", prefix: "X\u00e3" },
          {
            id: "9050",
            name: "M\u1ef9 Xuy\u00ean",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9051", name: "Ng\u1ecdc \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "9052", name: "Ng\u1ecdc T\u1ed1", prefix: "X\u00e3" },
          { id: "9053", name: "T\u00e0i V\u0103n", prefix: "X\u00e3" },
          { id: "9054", name: "Tham \u0110\u00f4n", prefix: "X\u00e3" },
          { id: "9055", name: "Th\u1ea1nh Ph\u00fa", prefix: "X\u00e3" },
          { id: "9056", name: "Th\u1ea1nh Qu\u1edbi", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "29952", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29953", name: "15", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29954", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29955", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29956", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29957", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29958", name: "936", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29959", name: "939", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29960",
            name: "\u0110\u00ecnh Th\u1ea7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29961",
            name: "\u0110o\u00e0n Minh B\u1ea3y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29962",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29963",
            name: "Hu\u1ef3nh V\u0103n Ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29964",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29965",
            name: "L\u00ea V\u0103n Duy\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29966",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "29967",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29968",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29969",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29970",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29971",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29972",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29973",
            name: "Phan Thanh Gi\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29974",
            name: "Ph\u01b0\u1edbc Ki\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29975",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29976",
            name: "R\u1ea1ch B\u00e0 Chu\u1ed9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29977",
            name: "Th\u1ea7y C\u00f9i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29978",
            name: "T\u1ec9nh l\u1ed9 934",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29979",
            name: "T\u1ec9nh l\u1ed9 936B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29980",
            name: "T\u1ec9nh l\u1ed9 940",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29981",
            name: "Tri\u1ec7u N\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29982",
            name: "Tr\u01b0ng V\u01b0\u01a1ng 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29983",
            name: "Tr\u01b0ng V\u01b0\u01a1ng 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29984",
            name: "V\u0103n Ng\u1ecdc T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "566",
        name: "Ng\u00e3 N\u0103m",
        wards: [
          { id: "9057", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "9058", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "9059", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "9060", name: "Long B\u00ecnh", prefix: "X\u00e3" },
          { id: "9061", name: "M\u1ef9 B\u00ecnh", prefix: "X\u00e3" },
          { id: "9062", name: "M\u1ef9 Qu\u1edbi", prefix: "X\u00e3" },
          { id: "9063", name: "T\u00e2n Long", prefix: "X\u00e3" },
          { id: "9064", name: "V\u0129nh Qu\u1edbi", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "29985", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29986", name: "1/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "29987", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "29988",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29989",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29990",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29991",
            name: "Hu\u1ef3nh Th\u1ecb T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29992",
            name: "K\u00eanh X\u00e1ng ch\u00ecm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29993",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29994",
            name: "L\u00ea Ho\u00e0ng Chu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29995",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29996",
            name: "L\u00ea V\u0103n T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29997",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29998",
            name: "Mai Thanh Th\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "29999",
            name: "M\u1eadu Th\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30000",
            name: "M\u1ef9 Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30001",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30002",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30003",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30004",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30005",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30006",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30007",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30008",
            name: "Qu\u1ea3n l\u1ed9 Ph\u1ee5ng Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30009",
            name: "Qu\u1ed1c L\u1ed9 61",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30010",
            name: "T\u1ec9nh l\u1ed9 937",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30011",
            name: "Tr\u1ea7n V\u0103n B\u1ea3y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30012",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30013",
            name: "X\u1ebbo C\u1ea1y",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "567",
        name: "S\u00f3c Tr\u0103ng",
        wards: [
          { id: "9065", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "9066", name: "10", prefix: "Ph\u01b0\u1eddng" },
          { id: "9067", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "9068", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "9069", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "9070", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "9071", name: "6", prefix: "Ph\u01b0\u1eddng" },
          { id: "9072", name: "7", prefix: "Ph\u01b0\u1eddng" },
          { id: "9073", name: "8", prefix: "Ph\u01b0\u1eddng" },
          { id: "9074", name: "9", prefix: "Ph\u01b0\u1eddng" },
          { id: "9075", name: "Ph\u00fa T\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "30014", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30015", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30016",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30017", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "30018",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30019",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30020",
            name: "B\u00f9i Vi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30021",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30022", name: "Calmette", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30023",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30024",
            name: "Ch\u00e2u  Kh\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30025",
            name: "Ch\u00e2u V\u0103n Ti\u1ebfp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30026",
            name: "Ch\u00f4ng Ch\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30027",
            name: "D\u00e3 T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30035",
            name: "D\u01b0\u01a1ng K\u1ef3 Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30036",
            name: "D\u01b0\u01a1ng Minh Quan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30028",
            name: "\u0110\u1eb7ng V\u0103n Vi\u1ec5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30029",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30030",
            name: "\u0110\u1ec1 Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30031",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30032",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30033",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30034",
            name: "\u0110\u1ed3ng Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30037", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "30038",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30039",
            name: "H\u1ed3 Minh Lu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30040",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30041",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30042",
            name: "Hu\u1ef3nh Phan H\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30043",
            name: "K\u00eanh H\u1ed3 N\u01b0\u1edbc Ng\u1ecdt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30044",
            name: "K\u00eanh X\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30045",
            name: "Lai V\u0103n T\u1eedng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30047",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30046",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30048",
            name: "L\u00ea Ho\u00e0ng Chu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30049",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30050", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30051",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30052",
            name: "L\u00ea Th\u00ecn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30053",
            name: "L\u00ea V\u0103n T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30054",
            name: "L\u00ea V\u0103n T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30055",
            name: "L\u00ea V\u0129nh H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30056",
            name: "L\u01b0\u01a1ng \u0110\u1ecbnh C\u1ee7a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30057",
            name: "L\u00fd \u0110\u1ea1o Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30058",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30059",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30060",
            name: "Mai Thanh Th\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30061",
            name: "Nam K\u1ef3 Kh\u1edfi Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30062",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30063",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30064",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30066",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30065",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30067",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30068",
            name: "Nguy\u1ec5n H\u00f9ng  Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30069",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30070",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30071",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30072",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30073",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30074",
            name: "Nguy\u1ec5n V\u0103n H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30075",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30076",
            name: "Nguy\u1ec5n V\u0103n Th\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30077",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30078", name: "Pasteur", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30079",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30080",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30081",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30082",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30083",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30084",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30085",
            name: "Ph\u00fa L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30086",
            name: "Ph\u00fa T\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30087",
            name: "Qu\u1ea3n l\u1ed9 Ph\u1ee5ng Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30088",
            name: "Qu\u00e3ng Tr\u01b0\u1eddng B\u1ea1ch  \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30089",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30090",
            name: "Qu\u1ed1c l\u1ed9 60",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30091", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30092", name: "S\u1ed1 9A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30093",
            name: "S\u00f3c M\u1ed3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30094",
            name: "S\u01a1n \u0110\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30095",
            name: "S\u01b0\u01a1ng Nguy\u1ec7t \u00c1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30096",
            name: "Th\u1ee7 Khoa Hu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30097",
            name: "T\u1ec9nh L\u1ed9 13",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30098",
            name: "T\u1ec9nh l\u1ed9 934",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30099",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30100",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30101",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30102",
            name: "Tr\u1ea7n Minh Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30103",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30104",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30105",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30106",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30107",
            name: "Tr\u1ea7n Th\u1ee7 \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30108",
            name: "Tr\u1ea7n V\u0103n B\u1ea3y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30109",
            name: "Tr\u1ea7n V\u0103n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30110",
            name: "Tr\u1ea7n V\u0103n S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30111",
            name: "Tri\u1ec7u Quang Ph\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30112",
            name: "Tr\u01b0\u01a1ng C\u00f4ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30113",
            name: "Tr\u01b0\u01a1ng V\u0103n Qu\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30114",
            name: "Tr\u01b0\u01a1ng V\u0129nh K\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30115",
            name: "V\u0103n Ng\u1ecdc Ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30116",
            name: "V\u00e0nh \u0110ai II",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30117",
            name: "V\u00f5 \u0110\u00ecnh S\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30118",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30119", name: "X\u00e0 Lan", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30120",
            name: "X\u00f4 Vi\u1ebft Ngh\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30121",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "568",
        name: "Th\u1ea1nh Tr\u1ecb",
        wards: [
          { id: "9076", name: "Ch\u00e2u H\u01b0ng", prefix: "X\u00e3" },
          {
            id: "9077",
            name: "H\u01b0ng L\u1ee3i",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9078", name: "L\u00e2m Ki\u1ebft", prefix: "X\u00e3" },
          { id: "9079", name: "L\u00e2m T\u00e2n", prefix: "X\u00e3" },
          {
            id: "9080",
            name: "Ph\u00fa L\u1ed9c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9081", name: "Th\u1ea1nh T\u00e2n", prefix: "X\u00e3" },
          { id: "9082", name: "Th\u1ea1nh Tr\u1ecb", prefix: "X\u00e3" },
          { id: "9083", name: "Tu\u00e2n T\u1ee9c", prefix: "X\u00e3" },
          { id: "9084", name: "V\u0129nh L\u1ee3i", prefix: "X\u00e3" },
          { id: "9085", name: "V\u0129nh Th\u00e0nh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "30122", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30123", name: "1/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30124", name: "14/9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30125", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30126", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30127", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30128", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30129", name: "42", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30130", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30131",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30132",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30133",
            name: "Kinh Gi\u1ed3ng Ch\u00f9a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30134", name: "Kinh Ngay", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30135",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30136",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30137",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30138",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30139",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30140",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30141",
            name: "R\u1eaby M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30142",
            name: "T\u1ec9nh l\u1ed9 937",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30143",
            name: "T\u1ec9nh l\u1ed9 937B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30144",
            name: "T\u1ec9nh l\u1ed9 940",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30145",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30146",
            name: "Tr\u1ea7n V\u0103n B\u1ea3y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30147",
            name: "V\u0103n Ng\u1ecdc Ch\u00ednh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "569",
        name: "Tr\u1ea7n \u0110\u1ec1",
        wards: [
          { id: "9086", name: "\u0110\u1ea1i \u00c2n", prefix: "X\u00e3" },
          { id: "9087", name: "\u0110\u1ea1i \u00c2n 2", prefix: "X\u00e3" },
          {
            id: "9088",
            name: "L\u1ecbch H\u1ed9i Th\u01b0\u1ee3ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9089", name: "Li\u00eau T\u00fa", prefix: "X\u00e3" },
          { id: "9090", name: "T\u00e0i V\u0103n", prefix: "X\u00e3" },
          { id: "9091", name: "Th\u1ea1nh Th\u1edbi An", prefix: "X\u00e3" },
          {
            id: "9092",
            name: "Th\u1ea1nh Th\u1edbi Thu\u1eadn",
            prefix: "X\u00e3",
          },
          {
            id: "9093",
            name: "Tr\u1ea7n \u0110\u1ec1",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9094", name: "Trung B\u00ecnh", prefix: "X\u00e3" },
          { id: "9095", name: "Vi\u00ean An", prefix: "X\u00e3" },
          { id: "9096", name: "Vi\u00ean B\u00ecnh", prefix: "X\u00e3" },
          {
            id: "9097",
            name: "X\u00e3 L\u1ecbch H\u1ed9i Th\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
        ],
        streets: [
          { id: "30148", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30149", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30150", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30151", name: "936B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30152", name: "Dal", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30153", name: "Dal 22/12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30154", name: "Dal 27/7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30155", name: "\u0110T 935", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30156",
            name: "Huy\u1ec7n l\u1ed9 27",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30157",
            name: "Nam S\u00f4ng H\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30158", name: "Ngan R\u00f4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30159",
            name: "T\u1ec9nh l\u1ed9 934",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "570",
        name: "V\u0129nh Ch\u00e2u",
        wards: [
          { id: "9098", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "9099", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "9100", name: "H\u00f2a \u0110\u00f4ng", prefix: "X\u00e3" },
          {
            id: "9101",
            name: "Kh\u00e1nh H\u00f2a",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "9102", name: "L\u1ea1c H\u00f2a", prefix: "X\u00e3" },
          { id: "9103", name: "Lai H\u00f2a", prefix: "X\u00e3" },
          { id: "9104", name: "V\u0129nh H\u1ea3i", prefix: "X\u00e3" },
          { id: "9105", name: "V\u0129nh Hi\u1ec7p", prefix: "X\u00e3" },
          {
            id: "9106",
            name: "V\u0129nh Ph\u01b0\u1edbc",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "9107", name: "V\u0129nh T\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "30160", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30161", name: "11", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30162", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30163", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30164", name: "31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30165", name: "4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30166", name: "B\u00e0 Len", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30167",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30168",
            name: "Ca L\u1ea1c \u0110\u1ea1i B\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30175",
            name: "Dol Ch\u00eal",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30169",
            name: "\u0110ai Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30170",
            name: "\u0110al C\u1ea3ng Bu\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30171",
            name: "\u0110al T\u00e0 B\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30172",
            name: "\u0110al Xung Thum A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30173",
            name: "\u0110\u1ec1 Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30174",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30176",
            name: "\u0110\u1ed3ng Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30177",
            name: "Gi\u1ed3ng Gi\u1eefa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30178",
            name: "Huy\u1ec7n l\u1ed9 111",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30179",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30180", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30181",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30182",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30183",
            name: "M\u1eadu Th\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30184",
            name: "Nam S\u00f4ng H\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30185",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30186",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30187",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30188",
            name: "Phan Thanh Gi\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30189",
            name: "Ph\u01b0\u1edbc T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30190", name: "Prey Chop", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30191",
            name: "T\u00e0 L\u00e9t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30192",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30193",
            name: "T\u1ec9nh l\u1ed9 935",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30194",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30195",
            name: "Tr\u01b0ng Nh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30196",
            name: "Tr\u01b0ng Tr\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30197",
            name: "T\u1eebng D\u00f9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30198",
            name: "V\u0129nh B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30199",
            name: "V\u0129nh Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30200", name: "X\u1ebbo Me", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "49",
    code: "KT",
    name: "Kon Tum",
    districts: [
      {
        id: "571",
        name: "\u0110\u0103k Glei",
        wards: [
          { id: "9108", name: " \u0110\u0103k Glei", prefix: "X\u00e3" },
          { id: "9109", name: " \u0110\u0103k Kroong", prefix: "X\u00e3" },
          { id: "9110", name: " \u0110\u0103k M\u00f4n", prefix: "X\u00e3" },
          { id: "9111", name: "\u0110\u0103k Choong", prefix: "X\u00e3" },
          { id: "9112", name: "\u0110\u0103k Long", prefix: "X\u00e3" },
          { id: "9113", name: "\u0110\u0103k Man", prefix: "X\u00e3" },
          { id: "9114", name: "\u0110\u0103k Nhoong", prefix: "X\u00e3" },
          { id: "9115", name: "\u0110\u0103k P\u00e9k", prefix: "X\u00e3" },
          { id: "9116", name: "\u0110\u0103k Pl\u00f4", prefix: "X\u00e3" },
          { id: "9117", name: "M\u01b0\u1eddng Hoong", prefix: "X\u00e3" },
          { id: "9118", name: "Ng\u1ecdc Linh", prefix: "X\u00e3" },
          { id: "9119", name: "X\u1ed1p", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "30201",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "572",
        name: "\u0110\u0103k H\u00e0",
        wards: [
          {
            id: "9120",
            name: "\u0110\u1eafk H\u00e0",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9121", name: "\u0110\u0103k Hring", prefix: "X\u00e3" },
          { id: "9122", name: "\u0110\u0103k La", prefix: "X\u00e3" },
          { id: "9123", name: "\u0110\u1eafk Long", prefix: "X\u00e3" },
          { id: "9124", name: "\u0110\u0103k Mar", prefix: "X\u00e3" },
          { id: "9125", name: "\u0110\u1eafk Ng\u1ecdk", prefix: "X\u00e3" },
          { id: "9126", name: "\u0110\u0103k Pxi", prefix: "X\u00e3" },
          { id: "9127", name: "\u0110\u0103k Uy", prefix: "X\u00e3" },
          { id: "9128", name: "H\u00e0 M\u00f2n", prefix: "X\u00e3" },
          { id: "9129", name: "Ng\u1ecdk R\u00e9o", prefix: "X\u00e3" },
          { id: "9130", name: "Ng\u1ecdk Wang", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "30202",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30203",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30204",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30205",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30206",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "573",
        name: "\u0110\u0103k T\u00f4",
        wards: [
          { id: "9131", name: " \u0110\u1eafk R\u01a1 Nga", prefix: "X\u00e3" },
          { id: "9132", name: " V\u0103n Lem", prefix: "X\u00e3" },
          { id: "9135", name: "Di\u00ean B\u00ecnh", prefix: "X\u00e3" },
          {
            id: "9133",
            name: "\u0110\u1eafk T\u00f4",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9134", name: "\u0110\u1eafk Tr\u0103m", prefix: "X\u00e3" },
          { id: "9136", name: "Kon \u0110\u00e0o", prefix: "X\u00e3" },
          { id: "9137", name: "Ng\u1ecdk T\u1ee5", prefix: "X\u00e3" },
          { id: "9138", name: "P\u00f4 k\u00f4", prefix: "X\u00e3" },
          { id: "9139", name: "T\u00e2n C\u1ea3nh", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "30207",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30208",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "574",
        name: "Ia H'Drai",
        wards: [
          { id: "9141", name: "Ia Dom", prefix: "X\u00e3" },
          { id: "9140", name: "Ia \u0110al", prefix: "X\u00e3" },
          { id: "9142", name: "Ia T\u01a1i", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "575",
        name: "Kon Pl\u00f4ng",
        wards: [
          { id: "9143", name: "\u0110\u1eafk Long", prefix: "X\u00e3" },
          { id: "9144", name: "\u0110\u0103k N\u00ean", prefix: "X\u00e3" },
          { id: "9145", name: "\u0110\u0103k Ring", prefix: "X\u00e3" },
          { id: "9146", name: "\u0110\u0103k T\u0103ng", prefix: "X\u00e3" },
          { id: "9147", name: "Hi\u1ebfu", prefix: "X\u00e3" },
          { id: "9148", name: "M\u0103ng B\u00fat", prefix: "X\u00e3" },
          { id: "9149", name: "M\u0103ng C\u00e0nh", prefix: "X\u00e3" },
          { id: "9150", name: "Ng\u1ecdk Tem", prefix: "X\u00e3" },
          { id: "9151", name: "P\u1edd \u00ca", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "30209", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30210",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30211", name: "S\u1ed1 12", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
      {
        id: "576",
        name: "Kon R\u1eaby",
        wards: [
          { id: "9152", name: "\u0110\u0103k K\u00f4i", prefix: "X\u00e3" },
          { id: "9153", name: "\u0110\u0103k Pne", prefix: "X\u00e3" },
          { id: "9154", name: "\u0110\u0103k Ru\u1ed3ng", prefix: "X\u00e3" },
          {
            id: "9155",
            name: "\u0110\u0103k Rve",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "9156",
            name: "\u0110\u0103k T\u01a1 L\u00f9ng",
            prefix: "X\u00e3",
          },
          { id: "9157", name: "\u0110\u1eafk T\u1edd Re", prefix: "X\u00e3" },
          { id: "9158", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "577",
        name: "KonTum",
        wards: [
          { id: "9159", name: " Ia Chim", prefix: "X\u00e3" },
          { id: "9160", name: " Quang Trung", prefix: "Ph\u01b0\u1eddng" },
          { id: "9161", name: "Ch\u01b0 Hreng", prefix: "X\u00e3" },
          { id: "9167", name: "Duy T\u00e2n", prefix: "Ph\u01b0\u1eddng" },
          { id: "9162", name: "\u0110\u0103k Bl\u00e0", prefix: "X\u00e3" },
          { id: "9163", name: "\u0110\u0103k C\u1ea5m", prefix: "X\u00e3" },
          { id: "9164", name: "\u0110\u0103k N\u0103ng", prefix: "X\u00e3" },
          { id: "9165", name: "\u0110\u0103k R\u01a1 Wa", prefix: "X\u00e3" },
          { id: "9166", name: "\u0110o\u00e0n K\u1ebft", prefix: "X\u00e3" },
          { id: "9168", name: "Ho\u00e0 B\u00ecnh", prefix: "X\u00e3" },
          { id: "9169", name: "Kroong", prefix: "X\u00e3" },
          { id: "9170", name: "L\u00ea L\u1ee3i", prefix: "Ph\u01b0\u1eddng" },
          { id: "9171", name: "Ng\u00f4 M\u00e2y", prefix: "Ph\u01b0\u1eddng" },
          { id: "9172", name: "Ng\u1ecdk Bay", prefix: "X\u00e3" },
          {
            id: "9173",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "9174",
            name: "Quy\u1ebft Th\u1eafng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "9175",
            name: "Th\u1eafng L\u1ee3i",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "9176",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "9177",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "9178",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "9179", name: "Vinh Quang", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "30212", name: "14", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30213", name: "A D\u1eeba", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30214", name: "A Gi\u00f3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30215", name: "A Ninh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30216",
            name: "Ba \u0110\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30217", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "30218",
            name: "B\u1eafc C\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30219",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30220",
            name: "B\u00f9i \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30221",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30222",
            name: "B\u00f9i V\u0103n N\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30223",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30224",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30225",
            name: "Ch\u1ebf Lan Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30226",
            name: "C\u00f9 Ch\u00ednh Lan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30227",
            name: "C\u00f9 huy c\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30236", name: "Duy T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30228", name: "\u0110\u1eb7ng T\u1ea5t", prefix: "Ph\u1ed1" },
          {
            id: "30229",
            name: "\u0110\u1eb7ng Th\u00e1i Thuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30230",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30231",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30232",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30233",
            name: "\u0110o\u00e0n Khu\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30234",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30235",
            name: "\u0110\u1ed1ng \u0110a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30237", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "30238",
            name: "H\u1ed3 Tr\u1ecdng Hi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30239",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30240",
            name: "Ho\u00e0ng Th\u1ecb Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30241",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30242",
            name: "H\u1ed3ng B\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30243",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30244",
            name: "Hu\u1ef3nh \u0110\u0103ng Th\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30245",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30246",
            name: "Kapakal\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30247",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30248", name: "Kon Tum 2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30249",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30250",
            name: "L\u00ea \u0110\u00ecnh Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30251",
            name: "L\u00ea \u0110\u1ee9c Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30252",
            name: "L\u00ea Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30253",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30254",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30255",
            name: "L\u00ea Ng\u1ecdc H\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30256",
            name: "L\u00ea Ni\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30257",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30258", name: "L\u00ea Thanh Ngh\u1ecb", prefix: "Ph\u1ed1" },
          {
            id: "30259",
            name: "L\u00ea Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30260",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30261",
            name: "L\u00ea V\u0103n Hi\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30262",
            name: "L\u00ea V\u0103n T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30263",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30264",
            name: "L\u01b0\u01a1ng V\u0103n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30265",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30266",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30267",
            name: "Mai H\u1eafc \u0110\u1ebf",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30268",
            name: "Mai Xu\u00e2n Th\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30269", name: "Nam Cao", prefix: "Ph\u1ed1" },
          {
            id: "30270",
            name: "Ng\u00f4 \u0110\u1ee9c \u0110\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30271",
            name: "Ng\u00f4 \u0110\u1ee9c K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30272",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30273",
            name: "Ng\u00f4 S\u0129 Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30274",
            name: "Nguy\u1ec5n B\u00e1 Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30275",
            name: "Nguy\u1ec5n B\u1eb7c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30276",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30277",
            name: "Nguy\u1ec5n Gia Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30278",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30279",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30280",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30281",
            name: "Nguy\u1ec5n Huy Lung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30282",
            name: "Nguy\u1ec5n Kh\u1eafc Vi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30283",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30284",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30285",
            name: "Nguy\u1ec5n Nh\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30286",
            name: "Nguy\u1ec5n Sinh S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30287",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30288",
            name: "Nguy\u1ec5n Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30289",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30290",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30291",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30292",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30293",
            name: "Nguy\u1ec5n V\u0103n Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30294",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30295",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30296",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30297",
            name: "N\u01a1 Trang Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30298",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30299",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30300",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30301",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30302",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30303",
            name: "Phan Huy Ch\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30304",
            name: "Phan V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30305",
            name: "Phan V\u0103n Vi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30306",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30307",
            name: "Qu\u1ed1c L\u1ed9 24",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30308", name: "S\u1ed1 1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30309", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30310", name: "S\u1ed1 3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30311",
            name: "S\u01b0 V\u1ea1n H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30312", name: "Thi S\u00e1ch", prefix: "Ph\u1ed1" },
          { id: "30313", name: "Thu B\u1ed3n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30314",
            name: "T\u1ec9nh l\u1ed9 671",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30315",
            name: "T\u1ec9nh l\u1ed9 675",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30316",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30317",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30319",
            name: "Tr\u1ea7n D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30318",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30320",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30321",
            name: "Tr\u1ea7n H\u1eefu Trang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30322",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30323",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30324",
            name: "Tr\u1ea7n Ki\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30325",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30326",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30327",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30328",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30329",
            name: "Tr\u1ea7n Qu\u00fd C\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30330",
            name: "Tr\u1ea7n V\u0103n Hai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30331",
            name: "Tr\u1ea7n V\u0103n \u01a0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30332",
            name: "Tr\u1ea7n V\u0103n Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30333",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30334",
            name: "Tr\u01b0\u01a1ng \u0110\u0103ng Qu\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30335",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30336",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30337", name: "Ur\u00ea", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30338",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30339",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30340",
            name: "V\u00f5 V\u0103n D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30341", name: "Y Ch\u1edf", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30342",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "578",
        name: "Ng\u1ecdc H\u1ed3i",
        wards: [
          { id: "9180", name: " \u0110\u0103k X\u00fa", prefix: "X\u00e3" },
          { id: "9181", name: "\u0110\u0103k Ang", prefix: "X\u00e3" },
          { id: "9182", name: "\u0110\u0103k D\u1ee5c", prefix: "X\u00e3" },
          { id: "9183", name: "\u0110\u0103k Kan", prefix: "X\u00e3" },
          { id: "9184", name: "\u0110\u0103k N\u00f4ng", prefix: "X\u00e3" },
          { id: "9185", name: "Plei K\u1ea7n", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "9186", name: "P\u1edd Y", prefix: "X\u00e3" },
          { id: "9187", name: "Sa Loong", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "30343",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30344",
            name: "Nguy\u1ec5n Sinh S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30345",
            name: "Qu\u1ed1c l\u1ed9 14",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30346",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30347",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30348",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "579",
        name: "Sa Th\u1ea7y",
        wards: [
          { id: "9188", name: " Sa s\u01a1n", prefix: "X\u00e3" },
          { id: "9189", name: "H\u01a1 moong", prefix: "X\u00e3" },
          { id: "9190", name: "M\u00f4 Rai", prefix: "X\u00e3" },
          { id: "9191", name: "R\u1edd k\u01a1i", prefix: "X\u00e3" },
          { id: "9192", name: "Sa B\u00ecnh", prefix: "X\u00e3" },
          { id: "9193", name: "Sa Ngh\u0129a", prefix: "X\u00e3" },
          { id: "9194", name: "Sa nh\u01a1n", prefix: "X\u00e3" },
          { id: "9195", name: "Sa Th\u1ea7y", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "9196", name: "Ya Ly", prefix: "X\u00e3" },
          { id: "9197", name: "Ya t\u0103ng ", prefix: "X\u00e3" },
          { id: "9198", name: "Ya Xi\u00ear", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "30349",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30350",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30351",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30352",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30353",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30354",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "580",
        name: "Tu M\u01a1 R\u00f4ng",
        wards: [
          { id: "9199", name: "\u0110\u1eafk H\u00e0", prefix: "X\u00e3" },
          { id: "9200", name: "\u0110\u1eafk Na", prefix: "X\u00e3" },
          {
            id: "9201",
            name: "\u0110\u1eafk R\u01a1 \u00d4ng",
            prefix: "X\u00e3",
          },
          { id: "9202", name: "\u0110\u1eafk Sao", prefix: "X\u00e3" },
          { id: "9203", name: "\u0110\u1eafk T\u1edd Kan", prefix: "X\u00e3" },
          { id: "9204", name: "M\u0103ng Ri", prefix: "X\u00e3" },
          { id: "9205", name: "Ng\u1ecdk L\u00e2y", prefix: "X\u00e3" },
          { id: "9206", name: "Ng\u1ecdk Y\u00eau", prefix: "X\u00e3" },
          { id: "9207", name: "T\u00ea X\u0103ng", prefix: "X\u00e3" },
          { id: "9208", name: "Tu M\u01a1 R\u00f4ng", prefix: "X\u00e3" },
          { id: "9209", name: "V\u0103n Xu\u00f4i", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
    ],
  },
  {
    id: "50",
    code: "QB",
    name: "Qu\u1ea3ng B\u00ecnh",
    districts: [
      {
        id: "581",
        name: "Ba \u0110\u1ed3n",
        wards: [
          { id: "9210", name: "Qu\u1ea3ng H\u00f2a", prefix: "X\u00e3" },
          {
            id: "9211",
            name: "Qu\u1ea3ng Th\u1ecd",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "9212", name: "Qu\u1ea3ng Thu\u1eadn", prefix: "X\u00e3" },
          { id: "9213", name: "Qu\u1ea3ng Th\u1ee7y", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "30355",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30356",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30357",
            name: "Qu\u1ea3ng Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30358", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30359",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "582",
        name: "B\u1ed1 Tr\u1ea1ch",
        wards: [
          { id: "9214", name: "B\u1eafc Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "9215", name: "C\u1ef1 N\u1eabm", prefix: "X\u00e3" },
          { id: "9216", name: "\u0110\u1ea1i Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "9217", name: "\u0110\u1ed3ng Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "9218", name: "\u0110\u1ee9c Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "9219", name: "H\u1ea1 Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "9220", name: "H\u1ea3i Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "9221", name: "Ho\u00e0 Tr\u1ea1ch", prefix: "X\u00e3" },
          {
            id: "9222",
            name: "Ho\u00e0n L\u00e3o",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9223", name: "Ho\u00e0n Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "9224", name: "H\u01b0ng Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "9225", name: "L\u00e2m Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "9226", name: "Li\u00ean Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "9227", name: "L\u00fd Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "9228", name: "M\u1ef9 Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "9229", name: "Nam Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "9230", name: "Nh\u00e2n Tr\u1ea1ch", prefix: "X\u00e3" },
          {
            id: "9231",
            name: "N\u00f4ng tr\u01b0\u1eddng Vi\u1ec7t Trung",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9232", name: "Ph\u00fa \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "9233", name: "Ph\u00fa Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "9234", name: "Ph\u00fac Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "9235", name: "S\u01a1n L\u1ed9c", prefix: "X\u00e3" },
          { id: "9236", name: "S\u01a1n Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "9237", name: "T\u00e2n Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "9238", name: "T\u00e2y Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "9239", name: "Thanh Tr\u1ea1ch", prefix: "X\u00e3" },
          {
            id: "9240",
            name: "Th\u01b0\u1ee3ng Tr\u1ea1ch",
            prefix: "X\u00e3",
          },
          { id: "9241", name: "Trung Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "9242", name: "V\u1ea1n Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "9243", name: "Xu\u00e2n Tr\u1ea1ch", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "30360",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30361",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30362",
            name: "Qu\u00e1ch Xu\u00e2n K\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30363",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "583",
        name: "\u0110\u1ed3ng H\u1edbi",
        wards: [
          { id: "9244", name: "B\u1eafc L\u00fd", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "9245",
            name: "B\u1eafc Ngh\u0129a",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "9246", name: "B\u1ea3o Ninh", prefix: "X\u00e3" },
          {
            id: "9247",
            name: "\u0110\u1ed3ng M\u1ef9",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "9248",
            name: "\u0110\u1ed3ng Ph\u00fa",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "9249",
            name: "\u0110\u1ed3ng S\u01a1n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "9250", name: "\u0110\u1ee9c Ninh", prefix: "X\u00e3" },
          {
            id: "9251",
            name: "\u0110\u1ee9c Ninh \u0110\u00f4ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "9252",
            name: "H\u1ea3i \u0110\u00ecnh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "9253",
            name: "H\u1ea3i Th\u00e0nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "9254", name: "L\u1ed9c Ninh", prefix: "X\u00e3" },
          { id: "9255", name: "Nam L\u00fd", prefix: "Ph\u01b0\u1eddng" },
          { id: "9256", name: "Ngh\u0129a Ninh", prefix: "X\u00e3" },
          { id: "9257", name: "Ph\u00fa H\u1ea3i", prefix: "Ph\u01b0\u1eddng" },
          { id: "9258", name: "Quang Ph\u00fa", prefix: "X\u00e3" },
          { id: "9259", name: "Thu\u1eadn \u0110\u1ee9c", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "30364", name: "16-6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30365", name: "23/8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30366", name: "36", prefix: "Ph\u1ed1" },
          { id: "30367", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "30368",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30369",
            name: "B\u00ecnh H\u00f2a 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30370",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30371",
            name: "B\u00f9i Vi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30372",
            name: "Cao B\u00e1 \u0110\u1ea1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30373",
            name: "Cao B\u00e1 Qu\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30374",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30375",
            name: "Ch\u1ebf Lan Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30376",
            name: "Chi L\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30377",
            name: "Ch\u1eed \u0110\u1ed3ng T\u1eed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30378",
            name: "C\u00f4 T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30393",
            name: "D\u01b0\u01a1ng \u0110\u00ecnh Ngh\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30394",
            name: "D\u01b0\u01a1ng V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30379",
            name: "\u0110\u1eb7ng Huy Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30380",
            name: "\u0110\u1eb7ng Thai Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30381",
            name: "\u0110\u1eb7ng Tr\u1ea7n C\u00f4n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30382",
            name: "\u0110\u1eb7ng V\u0103n Ng\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30383",
            name: "\u0110\u1eb7ng Xu\u00e2n Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30384",
            name: "\u0110\u00e0o Duy Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30385",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30386", name: "\u0110\u1ed7 Nhu\u1eadn", prefix: "Ph\u1ed1" },
          {
            id: "30387",
            name: "\u0110o\u00e0n H\u1eefu Tr\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30388",
            name: "\u0110\u1ed9i C\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30389",
            name: "\u0110\u1ed9i Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30390",
            name: "\u0110\u00f4ng H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30391",
            name: "\u0110\u1ed3ng L\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30392",
            name: "\u0110\u1ee9c Ph\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30395", name: "F325", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30396", name: "Giao T\u1ebf", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30397",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30398", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "30399",
            name: "H\u00e0n Thuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30400",
            name: "H\u1ed3 Bi\u1ec3u Ch\u00e1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30401",
            name: "H\u1ed3 Ch\u00ed Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30402",
            name: "H\u1ed3 Nguy\u00ean Tr\u1eebng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30403",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30404",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30405",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30406",
            name: "Ho\u00e0ng K\u1ebf Vi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30407", name: "Ho\u00e0ng S\u00e2m", prefix: "Ph\u1ed1" },
          {
            id: "30408",
            name: "Ho\u00e0ng Th\u1ecb Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30409",
            name: "Ho\u00e0ng Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30410",
            name: "Ho\u00e0ng Xu\u00e2n H\u00e3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30411",
            name: "H\u1ed3ng Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30412",
            name: "H\u01b0ng Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30413",
            name: "H\u1eefu Ngh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30414",
            name: "Huy\u1ec1n Tr\u00e2n C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30415",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30416",
            name: "Kh\u00fac H\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30417",
            name: "L\u00ea Anh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30418",
            name: "L\u00ea Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30419",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30420",
            name: "L\u00ea M\u00f4 Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30421",
            name: "L\u00ea S\u0129",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30422",
            name: "L\u00ea Th\u00e0nh \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30423",
            name: "L\u00ea Th\u1ecb H\u1ed3ng G\u1ea5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30424",
            name: "L\u00ea V\u0103n H\u01b0u",
            prefix: "Ph\u1ed1",
          },
          { id: "30425", name: "Linh Giang", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30426",
            name: "L\u1ed9c \u0110\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30427",
            name: "Long \u0110\u1ea1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30428",
            name: "L\u01b0\u01a1ng Ng\u1ecdc Quy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30429",
            name: "L\u01b0\u01a1ng V\u0103n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30430",
            name: "L\u00fd Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30431",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30432",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30433",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30434",
            name: "M\u1ea1c \u0110\u0103ng Dung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30435",
            name: "M\u1ea1c Th\u1ecb B\u01b0\u1edfi",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30436",
            name: "Mai L\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30437",
            name: "Mai Th\u00fac Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30438",
            name: "M\u1ef9 C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30439", name: "Nam Cao", prefix: "Ph\u1ed1" },
          {
            id: "30440",
            name: "Ng\u00f4 \u0110\u1ee9c K\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30441",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30442",
            name: "Ng\u00f4 V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30443",
            name: "Nguy\u1ec5n B\u00e1 Ng\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30444",
            name: "Nguy\u1ec5n B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30445",
            name: "Nguy\u1ec5n Ch\u00ed Di\u1ec5u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30446",
            name: "Nguy\u1ec5n C\u00f4ng Hoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30447",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30448",
            name: "Nguy\u1ec5n C\u01b0 Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30451",
            name: "Nguy\u1ec5n D\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30452",
            name: "Nguy\u1ec5n Duy Thi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30449",
            name: "Nguy\u1ec5n \u0110\u0103ng Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30450",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30453",
            name: "Nguy\u1ec5n Gia Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30454",
            name: "Nguy\u1ec5n H\u1eefu C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30455",
            name: "Nguy\u1ec5n H\u1eefu D\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30456",
            name: "Nguy\u1ec5n H\u1eefu H\u00e3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30457",
            name: "Nguy\u1ec5n H\u1eefu Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30458",
            name: "Nguy\u1ec5n H\u1eefu Tr\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30459",
            name: "Nguy\u1ec5n Kim Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30460",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30461",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30462",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30463",
            name: "Nguy\u1ec5n Th\u1ecb \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30464",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30465",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30466",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30467",
            name: "Nguy\u1ec5n Tu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30468",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30469",
            name: "Nguy\u1ec5n V\u0103n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30470",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30471",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30472",
            name: "Nh\u1eadt L\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30473",
            name: "Ph\u1ea1m \u0110\u00ecnh H\u1ed5",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30474",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30475",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30476",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30477",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30478",
            name: "Ph\u1ea1m V\u0103n Hai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30479",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30480",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30481",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30482",
            name: "Phan Huy \u00cdch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30483",
            name: "Phan Ph\u00fa Ti\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30484",
            name: "Ph\u00f3 \u0110\u1ee9c Ch\u00ednh",
            prefix: "Ph\u1ed1",
          },
          { id: "30485", name: "Phong Nha", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30486",
            name: "Ph\u00f9ng Ch\u00ed Ki\u00ean",
            prefix: "Ph\u1ed1",
          },
          { id: "30487", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "30488",
            name: "Ph\u00f9ng Kh\u1eafc Hoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30489",
            name: "Qu\u00e1ch Xu\u00e2n K\u1ef3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30490", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30491",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30492", name: "T\u1ea1 Quang B\u1eedu", prefix: "Ph\u1ed1" },
          {
            id: "30493",
            name: "Th\u00e1i Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30494",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30495",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30496",
            name: "Th\u1ee5y Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30497",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30498",
            name: "T\u1ed1 H\u1eefu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30499",
            name: "T\u00f4 V\u0129nh Di\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30500",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30501",
            name: "T\u00f4n Th\u1ea5t Thuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30502",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30503",
            name: "Tr\u1ea7n Bang C\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30504",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30505",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30506",
            name: "Tr\u1ea7n Kinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30507",
            name: "Tr\u1ea7n M\u1ea1nh \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30508",
            name: "Tr\u1ea7n Nguy\u00ean \u0110\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30509",
            name: "Tr\u1ea7n Nh\u00e2n T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30510",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30511",
            name: "Tr\u1ea5n Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30512",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30513",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30514",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30515",
            name: "Tr\u1ea7n T\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30516",
            name: "Tr\u1ea7n T\u1ea5n M\u1edbi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30517",
            name: "Tr\u1ea7n Th\u1ee7 \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30518",
            name: "Tr\u1ea7n V\u0103n Chu\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30519",
            name: "Tr\u1ea7n V\u0103n Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30520",
            name: "Tri\u1ec7u Quang Ph\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30521",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30522",
            name: "Tr\u01b0\u01a1ng Ph\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30523",
            name: "Tr\u01b0\u01a1ng Ph\u00fac Ph\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30524",
            name: "Tr\u01b0\u01a1ng Qu\u1ed1c H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30525",
            name: "V\u00f5 Duy D\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30526",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30527",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30528",
            name: "V\u0169 Tr\u1ecdng Ph\u1ee5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30529",
            name: "V\u01b0\u01a1ng Th\u1eeba V\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30530",
            name: "Xu\u00e2n Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30531", name: "\u1ef6 Lan", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [
          {
            id: "3055",
            name: "Khu nh\u00e0 \u1edf B\u1eafc Tr\u1ea7n Quang Kh\u1ea3i",
            lat: "17.47766494751",
            lng: "106.61478424072",
          },
        ],
      },
      {
        id: "584",
        name: "L\u1ec7 Th\u1ee7y",
        wards: [
          { id: "9260", name: "An Thu\u1ef7", prefix: "X\u00e3" },
          { id: "9261", name: "Cam Th\u1ee7y", prefix: "X\u00e3" },
          { id: "9262", name: "D\u01b0\u01a1ng Th\u1ee7y", prefix: "X\u00e3" },
          { id: "9263", name: "Hoa Th\u1ee7y", prefix: "X\u00e3" },
          { id: "9264", name: "H\u1ed3ng Th\u1ee7y", prefix: "X\u00e3" },
          { id: "9265", name: "H\u01b0ng Th\u1ee7y", prefix: "X\u00e3" },
          { id: "9266", name: "Ki\u1ebfn Giang", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "9267", name: "Kim Th\u1ee7y", prefix: "X\u00e3" },
          { id: "9268", name: "L\u00e2m Th\u1ee7y", prefix: "X\u00e3" },
          { id: "9269", name: "Li\u00ean Th\u1ee7y", prefix: "X\u00e3" },
          { id: "9270", name: "L\u1ed9c Th\u1ee7y", prefix: "X\u00e3" },
          { id: "9271", name: "Mai Th\u1ee7y", prefix: "X\u00e3" },
          { id: "9272", name: "M\u1ef9 Th\u1ee7y", prefix: "X\u00e3" },
          { id: "9273", name: "Ng\u00e2n Th\u1ee7y", prefix: "X\u00e3" },
          {
            id: "9274",
            name: "Ng\u01b0 Th\u1ee7y B\u1eafc",
            prefix: "X\u00e3",
          },
          { id: "9275", name: "Ng\u01b0 Th\u1ee7y Nam", prefix: "X\u00e3" },
          { id: "9276", name: "Ng\u01b0 Th\u1ee7y Trung", prefix: "X\u00e3" },
          {
            id: "9277",
            name: "N\u00f4ng Tr\u01b0\u1eddng L\u1ec7 Ninh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9278", name: "Phong Th\u1ee7y", prefix: "X\u00e3" },
          { id: "9279", name: "Ph\u00fa Th\u1ee7y", prefix: "X\u00e3" },
          { id: "9280", name: "Sen Th\u1ee7y", prefix: "X\u00e3" },
          { id: "9281", name: "S\u01a1n Th\u1ee7y", prefix: "X\u00e3" },
          { id: "9282", name: "T\u00e2n Th\u1ee7y", prefix: "X\u00e3" },
          { id: "9283", name: "Th\u00e1i Th\u1ee7y", prefix: "X\u00e3" },
          { id: "9284", name: "Thanh Th\u1ee7y", prefix: "X\u00e3" },
          { id: "9285", name: "Tr\u01b0\u1eddng Th\u1ee7y", prefix: "X\u00e3" },
          { id: "9286", name: "V\u0103n Th\u1ee7y", prefix: "X\u00e3" },
          { id: "9287", name: "Xu\u00e2n Th\u1ee7y", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "30532",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30533",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "585",
        name: "Minh H\u00f3a",
        wards: [
          { id: "9288", name: "D\u00e2n H\u00f3a", prefix: "X\u00e3" },
          { id: "9289", name: "H\u00f3a H\u1ee3p", prefix: "X\u00e3" },
          { id: "9290", name: "H\u00f3a Ph\u00fac", prefix: "X\u00e3" },
          { id: "9291", name: "H\u00f3a S\u01a1n", prefix: "X\u00e3" },
          { id: "9292", name: "H\u00f3a Thanh", prefix: "X\u00e3" },
          { id: "9293", name: "H\u00f3a Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "9294", name: "H\u1ed3ng H\u00f3a", prefix: "X\u00e3" },
          { id: "9295", name: "Minh H\u00f3a", prefix: "X\u00e3" },
          {
            id: "9296",
            name: "Quy \u0110\u1ea1t",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9297", name: "Quy H\u00f3a", prefix: "X\u00e3" },
          { id: "9298", name: "T\u00e2n H\u00f3a", prefix: "X\u00e3" },
          { id: "9299", name: "Th\u01b0\u1ee3ng H\u00f3a", prefix: "X\u00e3" },
          { id: "9300", name: "Tr\u1ecdng H\u00f3a", prefix: "X\u00e3" },
          { id: "9301", name: "Trung H\u00f3a", prefix: "X\u00e3" },
          { id: "9302", name: "Xu\u00e2n H\u00f3a", prefix: "X\u00e3" },
          { id: "9303", name: "Y\u00ean H\u00f3a", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "30534",
            name: "Qu\u1ed1c L\u1ed9 12A",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "586",
        name: "Qu\u1ea3ng Ninh",
        wards: [
          { id: "9304", name: "An Ninh", prefix: "X\u00e3" },
          { id: "9305", name: "Duy Ninh", prefix: "X\u00e3" },
          { id: "9306", name: "Gia Ninh", prefix: "X\u00e3" },
          { id: "9307", name: "H\u1ea3i Ninh", prefix: "X\u00e3" },
          { id: "9308", name: "H\u00e0m Ninh", prefix: "X\u00e3" },
          { id: "9309", name: "Hi\u1ec1n Ninh", prefix: "X\u00e3" },
          { id: "9310", name: "L\u01b0\u01a1ng Ninh", prefix: "X\u00e3" },
          {
            id: "9311",
            name: "Qu\u00e1n H\u00e0u",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9312", name: "T\u00e2n Ninh", prefix: "X\u00e3" },
          { id: "9313", name: "Tr\u01b0\u1eddng S\u01a1n", prefix: "X\u00e3" },
          { id: "9314", name: "Tr\u01b0\u1eddng Xu\u00e2n", prefix: "X\u00e3" },
          { id: "9315", name: "V\u1ea1n Ninh", prefix: "X\u00e3" },
          { id: "9316", name: "V\u0129nh Ninh", prefix: "X\u00e3" },
          { id: "9317", name: "V\u00f5 Ninh", prefix: "X\u00e3" },
          { id: "9318", name: "Xu\u00e2n Ninh", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "30535",
            name: "H\u1ed3 Ch\u00ed Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30536",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30537",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "587",
        name: "Qu\u1ea3ng Tr\u1ea1ch",
        wards: [
          {
            id: "9319",
            name: "Ba \u0110\u1ed3n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9320", name: "C\u1ea3nh D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "9321", name: "C\u1ea3nh Ho\u00e1", prefix: "X\u00e3" },
          { id: "9322", name: "Ph\u00f9 H\u00f3a", prefix: "X\u00e3" },
          { id: "9323", name: "Qu\u1ea3ng Ch\u00e2u", prefix: "X\u00e3" },
          { id: "9324", name: "Qu\u1ea3ng \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "9325", name: "Qu\u1ea3ng H\u1ea3i", prefix: "X\u00e3" },
          { id: "9326", name: "Qu\u1ea3ng Ho\u00e0", prefix: "X\u00e3" },
          { id: "9327", name: "Qu\u1ea3ng H\u1ee3p", prefix: "X\u00e3" },
          { id: "9328", name: "Qu\u1ea3ng H\u01b0ng", prefix: "X\u00e3" },
          { id: "9329", name: "Qu\u1ea3ng Kim", prefix: "X\u00e3" },
          { id: "9330", name: "Qu\u1ea3ng Li\u00ean", prefix: "X\u00e3" },
          { id: "9331", name: "Qu\u1ea3ng L\u1ed9c", prefix: "X\u00e3" },
          { id: "9332", name: "Qu\u1ea3ng Long", prefix: "X\u00e3" },
          { id: "9333", name: "Qu\u1ea3ng L\u01b0u", prefix: "X\u00e3" },
          { id: "9334", name: "Qu\u1ea3ng Minh", prefix: "X\u00e3" },
          { id: "9335", name: "Qu\u1ea3ng Phong", prefix: "X\u00e3" },
          { id: "9336", name: "Qu\u1ea3ng Ph\u00fa", prefix: "X\u00e3" },
          { id: "9337", name: "Qu\u1ea3ng Ph\u00fac", prefix: "X\u00e3" },
          {
            id: "9338",
            name: "Qu\u1ea3ng Ph\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "9339", name: "Qu\u1ea3ng S\u01a1n", prefix: "X\u00e3" },
          { id: "9340", name: "Qu\u1ea3ng T\u00e2n", prefix: "X\u00e3" },
          { id: "9341", name: "Qu\u1ea3ng Th\u1ea1ch", prefix: "X\u00e3" },
          { id: "9342", name: "Qu\u1ea3ng Thanh", prefix: "X\u00e3" },
          { id: "9343", name: "Qu\u1ea3ng Th\u1ecd", prefix: "X\u00e3" },
          { id: "9344", name: "Qu\u1ea3ng Thu\u1ef7", prefix: "X\u00e3" },
          { id: "9345", name: "Qu\u1ea3ng Ti\u00ean", prefix: "X\u00e3" },
          { id: "9346", name: "Qu\u1ea3ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "9347", name: "Qu\u1ea3ng Trung", prefix: "X\u00e3" },
          {
            id: "9348",
            name: "Qu\u1ea3ng Tr\u01b0\u1eddng",
            prefix: "X\u00e3",
          },
          { id: "9349", name: "Qu\u1ea3ng T\u00f9ng", prefix: "X\u00e3" },
          { id: "9350", name: "Qu\u1ea3ng V\u0103n", prefix: "X\u00e3" },
          { id: "9351", name: "Qu\u1ea3ng Xu\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "30538",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "588",
        name: "Tuy\u00ean H\u00f3a",
        wards: [
          { id: "9352", name: "Cao Qu\u1ea3ng", prefix: "X\u00e3" },
          { id: "9353", name: "Ch\u00e2u H\u00f3a", prefix: "X\u00e3" },
          { id: "9354", name: "\u0110\u1ed3ng H\u00f3a", prefix: "X\u00e3" },
          {
            id: "9355",
            name: "\u0110\u1ed3ng L\u00ea",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9356", name: "\u0110\u1ee9c H\u00f3a", prefix: "X\u00e3" },
          { id: "9357", name: "H\u01b0\u01a1ng H\u00f3a", prefix: "X\u00e3" },
          { id: "9358", name: "Kim H\u00f3a", prefix: "X\u00e3" },
          { id: "9359", name: "L\u00e2m H\u00f3a", prefix: "X\u00e3" },
          { id: "9360", name: "L\u00ea H\u00f3a", prefix: "X\u00e3" },
          { id: "9361", name: "Mai H\u00f3a", prefix: "X\u00e3" },
          { id: "9362", name: "Nam H\u00f3a", prefix: "X\u00e3" },
          { id: "9363", name: "Ng\u01b0 H\u00f3a", prefix: "X\u00e3" },
          { id: "9364", name: "Phong H\u00f3a", prefix: "X\u00e3" },
          { id: "9365", name: "S\u01a1n H\u00f3a", prefix: "X\u00e3" },
          { id: "9366", name: "Th\u1ea1ch H\u00f3a", prefix: "X\u00e3" },
          { id: "9367", name: "Thanh H\u00f3a", prefix: "X\u00e3" },
          { id: "9368", name: "Thanh Th\u1ea1ch", prefix: "X\u00e3" },
          { id: "9369", name: "Thu\u1eadn H\u00f3a", prefix: "X\u00e3" },
          { id: "9370", name: "Ti\u1ebfn H\u00f3a", prefix: "X\u00e3" },
          { id: "9371", name: "V\u0103n H\u00f3a", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
    ],
  },
  {
    id: "51",
    code: "QT",
    name: "Qu\u1ea3ng Tr\u1ecb",
    districts: [
      {
        id: "589",
        name: "Cam L\u1ed9",
        wards: [
          { id: "9372", name: "Cam An", prefix: "X\u00e3" },
          { id: "9373", name: "Cam Ch\u00ednh", prefix: "X\u00e3" },
          { id: "9374", name: "Cam Hi\u1ebfu", prefix: "X\u00e3" },
          { id: "9375", name: "Cam L\u1ed9", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "9376", name: "Cam Ngh\u0129a", prefix: "X\u00e3" },
          { id: "9377", name: "Cam Thanh", prefix: "X\u00e3" },
          { id: "9378", name: "Cam Th\u00e0nh", prefix: "X\u00e3" },
          { id: "9379", name: "Cam Th\u1ee7y", prefix: "X\u00e3" },
          { id: "9380", name: "Cam Tuy\u1ec1n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "30539", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30540",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30541",
            name: "Qu\u1ed1c L\u1ed9 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "590",
        name: "\u0110a Kr\u00f4ng",
        wards: [
          { id: "9381", name: "A Bung", prefix: "X\u00e3" },
          { id: "9382", name: "A Ngo", prefix: "X\u00e3" },
          { id: "9383", name: "A Vao", prefix: "X\u00e3" },
          { id: "9384", name: "Ba L\u00f2ng", prefix: "X\u00e3" },
          { id: "9385", name: "Ba Nang", prefix: "X\u00e3" },
          { id: "9386", name: "\u0110a Kr\u00f4ng", prefix: "X\u00e3" },
          { id: "9387", name: "H\u1ea3i Ph\u00fac", prefix: "X\u00e3" },
          { id: "9388", name: "H\u00fac Ngh\u00ec", prefix: "X\u00e3" },
          { id: "9389", name: "H\u01b0\u1edbng Hi\u1ec7p", prefix: "X\u00e3" },
          {
            id: "9390",
            name: "Kr\u00f4ng Klang",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9391", name: "M\u00f2 \u00d3", prefix: "X\u00e3" },
          { id: "9392", name: "T\u00e0 Long", prefix: "X\u00e3" },
          { id: "9393", name: "T\u00e0 R\u1ee5t", prefix: "X\u00e3" },
          { id: "9394", name: "Tri\u1ec7u Nguy\u00ean", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "591",
        name: "\u0110\u1ea3o C\u1ed3n c\u1ecf",
        wards: [],
        streets: [],
        projects: [],
      },
      {
        id: "592",
        name: "\u0110\u00f4ng H\u00e0",
        wards: [
          { id: "9395", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "9396", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "9397", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "9398", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "9399", name: "5", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "9400",
            name: "\u0110\u00f4ng Giang",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "9401",
            name: "\u0110\u00f4ng L\u1ec5",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "9402",
            name: "\u0110\u00f4ng L\u01b0\u01a1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "9403",
            name: "\u0110\u00f4ng Thanh",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "30542", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30543", name: "9B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30544", name: "9D", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30545",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          { id: "30546", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "30547",
            name: "B\u1ebfn H\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30548",
            name: "B\u00f9i D\u1ee5c T\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30549",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30550",
            name: "Bu\u0300i Trung L\u00e2\u0323p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30551",
            name: "C\u1ea7n V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30552",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30553",
            name: "Ch\u1ebf Lan Vi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30554",
            name: "Chu M\u1ea1nh Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30555",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30556",
            name: "C\u1ed3n C\u1ecf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30566",
            name: "Dinh C\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30573",
            name: "D\u01b0\u01a1ng V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30557",
            name: "\u0110\u1ea1i C\u1ed3 Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30558", name: "\u0110\u1eb7ng Dung", prefix: "Ph\u1ed1" },
          { id: "30559", name: "\u0110\u1eb7ng T\u1ea5t", prefix: "Ph\u1ed1" },
          {
            id: "30560",
            name: "\u0110\u1eb7ng Thai Mai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30561",
            name: "\u0110\u1eb7ng Th\u00ed",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30562",
            name: "\u0110\u1eb7ng Tr\u1ea7n C\u00f4n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30563",
            name: "\u0110\u00e0o Duy Anh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30564",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30565",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30567",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30568",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30569",
            name: "\u0110o\u00e0n H\u1eefu Tr\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30570",
            name: "\u0110o\u00e0n Khu\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30571",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30572",
            name: "\u0110\u1ed9i Cung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30574", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "30575",
            name: "H\u1ea3i Tri\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30576",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30577",
            name: "H\u00e0n Thuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30578",
            name: "Hi\u1ec1n L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30579",
            name: "H\u1ed3 S\u1ef9 Th\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30580",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30581",
            name: "H\u1ed3 Xu\u00e2n L\u01b0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30582",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30583",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30584",
            name: "Ho\u00e0ng Th\u1ecb \u00c1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30585",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30586",
            name: "Huy\u1ec1n Tr\u00e2n C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30587",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30588",
            name: "Kh\u00f3a B\u1ea3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30589",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30590",
            name: "Lai Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30591", name: "L\u00e3n \u00d4ng", prefix: "Ph\u1ed1" },
          {
            id: "30592",
            name: "L\u00ea Ch\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30593",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30594",
            name: "L\u00ea H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30595",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30596",
            name: "L\u00ea H\u1eefu Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30597",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30598",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30599",
            name: "L\u00ea Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30600",
            name: "L\u00ea Th\u1ebf Hi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30601",
            name: "L\u00ea Th\u1ebf Ti\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30602",
            name: "L\u00ea Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30603",
            name: "L\u00ea V\u0103n H\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30604",
            name: "L\u01b0\u01a1ng \u0110\u1ecbnh C\u1ee7a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30605",
            name: "L\u01b0\u01a1ng Kh\u00e1nh Thi\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30606",
            name: "L\u01b0\u01a1ng Ng\u1ecdc Quy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30607",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30608",
            name: "L\u01b0\u01a1ng V\u0103n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30609",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30610",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30611",
            name: "Mai H\u1eafc \u0110\u1ebf",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30612",
            name: "Mai L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30613",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30614",
            name: "Ng\u00f4 S\u0129 Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30615",
            name: "Ng\u00f4 Th\u1eeba Nhi\u1ec7m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30616",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30617",
            name: "Nguy\u1ec5n Bi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30618",
            name: "Nguy\u1ec5n B\u1ec9nh Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30619",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30620",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30622",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30621",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30623",
            name: "Nguy\u1ec5n Gia Thi\u1ec1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30624",
            name: "Nguy\u1ec5n H\u00e0m Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30625",
            name: "Nguy\u1ec5n Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30626",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30627",
            name: "Nguy\u1ec5n H\u1eefu Khi\u1ebfu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30628",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30629",
            name: "Nguy\u1ec5n Quang X\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30630",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30631",
            name: "Nguy\u1ec5n Thi\u1ec7n Thu\u1eadt",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30632",
            name: "Nguy\u1ec5n Th\u01b0\u1ee3ng Hi\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30633",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30634",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30635",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30636",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30637",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30638",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30639",
            name: "Nguy\u1ec5n V\u0103n T\u01b0\u1edfng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30640",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30641",
            name: "\u00d4ng \u00cdch Khi\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30642",
            name: "Ph\u1ea1m \u0110\u00ecnh H\u1ed5",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30643",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30644",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30645",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30646",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30647",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30648",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30649",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30650",
            name: "Phan Huy Ch\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30651",
            name: "Phan V\u0103n Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30652",
            name: "Ph\u1ee5ng Hi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30653", name: "Ph\u00f9ng H\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "30654",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30655",
            name: "Qu\u1ed1c L\u1ed9 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30656",
            name: "Qu\u1ed1c L\u1ed9 9B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30657", name: "T\u1ea1 Quang B\u1eedu", prefix: "Ph\u1ed1" },
          {
            id: "30658",
            name: "T\u00e2n S\u1edf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30659",
            name: "Th\u1ea1ch H\u00e3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30660",
            name: "Th\u00e1i Phi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30661",
            name: "Th\u00e0nh C\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30662",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30663",
            name: "T\u00f4n Th\u1ea5t Thuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30664",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30665",
            name: "T\u1ed1ng Duy T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30666",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30667",
            name: "Tr\u1ea7n Cao V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30668",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30669",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30670",
            name: "Tr\u1ea7n H\u1eefu D\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30671",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30672",
            name: "Tr\u1ea7n Nh\u00e2n Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30673",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30674",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30675",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30676",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30677",
            name: "Tr\u1ecbnh Ho\u00e0i \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30678",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30679",
            name: "Tr\u01b0\u01a1ng \u0110\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30680",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30681", name: "V\u0103n Cao", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30682",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30683",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "593",
        name: "Gio Linh",
        wards: [
          {
            id: "9404",
            name: "C\u1eeda Vi\u1ec7t",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9405", name: "Gio An", prefix: "X\u00e3" },
          { id: "9406", name: "Gio B\u00ecnh", prefix: "X\u00e3" },
          { id: "9407", name: "Gio Ch\u00e2u", prefix: "X\u00e3" },
          { id: "9408", name: "Gio H\u1ea3i", prefix: "X\u00e3" },
          { id: "9409", name: "Gio H\u00f2a", prefix: "X\u00e3" },
          { id: "9410", name: "Gio Linh", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "9411", name: "Gio Mai", prefix: "X\u00e3" },
          { id: "9412", name: "Gio M\u1ef9", prefix: "X\u00e3" },
          { id: "9413", name: "Gio Phong", prefix: "X\u00e3" },
          { id: "9414", name: "Gio Quang", prefix: "X\u00e3" },
          { id: "9415", name: "Gio S\u01a1n", prefix: "X\u00e3" },
          { id: "9416", name: "Gio Th\u00e0nh", prefix: "X\u00e3" },
          { id: "9417", name: "Gio Vi\u1ec7t", prefix: "X\u00e3" },
          { id: "9418", name: "H\u1ea3i Th\u00e1i", prefix: "X\u00e3" },
          { id: "9419", name: "Linh H\u1ea3i", prefix: "X\u00e3" },
          { id: "9420", name: "Linh Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "9421", name: "Trung Giang", prefix: "X\u00e3" },
          { id: "9422", name: "Trung H\u1ea3i", prefix: "X\u00e3" },
          { id: "9423", name: "Trung S\u01a1n", prefix: "X\u00e3" },
          { id: "9424", name: "V\u0129nh Tr\u01b0\u1eddng", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "30684",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30685",
            name: "T\u00e2n Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "594",
        name: "H\u1ea3i L\u0103ng",
        wards: [
          { id: "9425", name: "H\u1ea3i An", prefix: "X\u00e3" },
          { id: "9426", name: "H\u1ea3i Ba", prefix: "X\u00e3" },
          { id: "9427", name: "H\u1ea3i Ch\u00e1nh", prefix: "X\u00e3" },
          { id: "9428", name: "H\u1ea3i D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "9429", name: "H\u1ea3i H\u00f2a", prefix: "X\u00e3" },
          { id: "9430", name: "H\u1ea3i Kh\u00ea", prefix: "X\u00e3" },
          { id: "9431", name: "H\u1ea3i L\u00e2m", prefix: "X\u00e3" },
          {
            id: "9432",
            name: "H\u1ea3i L\u0103ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9433", name: "H\u1ea3i Ph\u00fa", prefix: "X\u00e3" },
          { id: "9434", name: "H\u1ea3i Qu\u1ebf", prefix: "X\u00e3" },
          { id: "9435", name: "H\u1ea3i Quy", prefix: "X\u00e3" },
          { id: "9436", name: "H\u1ea3i S\u01a1n", prefix: "X\u00e3" },
          { id: "9437", name: "H\u1ea3i T\u00e2n", prefix: "X\u00e3" },
          { id: "9438", name: "H\u1ea3i Th\u00e0nh", prefix: "X\u00e3" },
          { id: "9439", name: "H\u1ea3i Thi\u1ec7n", prefix: "X\u00e3" },
          { id: "9440", name: "H\u1ea3i Th\u1ecd", prefix: "X\u00e3" },
          { id: "9441", name: "H\u1ea3i Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "9442", name: "H\u1ea3i Tr\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "9443", name: "H\u1ea3i V\u0129nh", prefix: "X\u00e3" },
          { id: "9444", name: "H\u1ea3i Xu\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "30686",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30687",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30688",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30689",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30690",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "595",
        name: "H\u01b0\u1edbng H\u00f3a",
        wards: [
          { id: "9445", name: "A D\u01a1i", prefix: "X\u00e3" },
          { id: "9446", name: "A T\u00fac", prefix: "X\u00e3" },
          { id: "9447", name: "A Xing", prefix: "X\u00e3" },
          { id: "9448", name: "Ba T\u1ea7ng", prefix: "X\u00e3" },
          { id: "9449", name: "H\u00fac", prefix: "X\u00e3" },
          { id: "9450", name: "H\u01b0\u1edbng L\u1eadp", prefix: "X\u00e3" },
          { id: "9451", name: "H\u01b0\u1edbng Linh", prefix: "X\u00e3" },
          { id: "9452", name: "H\u01b0\u1edbng L\u1ed9c", prefix: "X\u00e3" },
          { id: "9453", name: "H\u01b0\u1edbng Ph\u00f9ng", prefix: "X\u00e3" },
          { id: "9454", name: "H\u01b0\u1edbng S\u01a1n", prefix: "X\u00e3" },
          { id: "9455", name: "H\u01b0\u1edbng T\u00e2n", prefix: "X\u00e3" },
          { id: "9456", name: "H\u01b0\u1edbng Vi\u1ec7t", prefix: "X\u00e3" },
          { id: "9457", name: "Khe Sanh", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "9458", name: "Lao B\u1ea3o", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "9459", name: "T\u00e2n H\u1ee3p", prefix: "X\u00e3" },
          { id: "9460", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "9461", name: "T\u00e2n Li\u00ean", prefix: "X\u00e3" },
          { id: "9462", name: "T\u00e2n Long", prefix: "X\u00e3" },
          { id: "9463", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "9464", name: "Thanh", prefix: "X\u00e3" },
          { id: "9465", name: "Thu\u1eadn", prefix: "X\u00e3" },
          { id: "9466", name: "Xy", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "30691",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30692",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30693",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30694",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30695",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30696",
            name: "Qu\u1ed1c L\u1ed9 9",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "596",
        name: "Qu\u1ea3ng Tr\u1ecb",
        wards: [
          { id: "9467", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "9468", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "9469", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "9470", name: "An \u0110\u00f4n", prefix: "Ph\u01b0\u1eddng" },
          { id: "9471", name: "H\u1ea3i L\u1ec7", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "30697", name: "8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30698", name: "9", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30699",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30700",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30701",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30702",
            name: "\u0110o\u00e0n H\u1eefu Tr\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30703", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "30704",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30705",
            name: "Huy\u1ec1n Tr\u00e2n C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30706",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30707",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30708", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30709",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30710",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30711",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30712",
            name: "Mai H\u1eafc \u0110\u1ebf",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30713",
            name: "Minh M\u1ea1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30714",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30715",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30716",
            name: "Nguy\u1ec5n Th\u1ecb L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30717",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30718",
            name: "Nguy\u1ec5n Tr\u01b0\u1eddng T\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30719",
            name: "Nguy\u1ec5n T\u01b0 Gi\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30720",
            name: "Phan Chu Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30721",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30722",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30723",
            name: "Tr\u1ea7n H\u1eefu D\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "597",
        name: "Tri\u1ec7u Phong",
        wards: [
          { id: "9472", name: "\u00c1i T\u1eed", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "9473", name: "Tri\u1ec7u \u00c1i", prefix: "X\u00e3" },
          { id: "9474", name: "Tri\u1ec7u An", prefix: "X\u00e3" },
          { id: "9475", name: "Tri\u1ec7u \u0110\u1ea1i", prefix: "X\u00e3" },
          { id: "9476", name: "Tri\u1ec7u \u0110\u1ed9", prefix: "X\u00e3" },
          { id: "9477", name: "Tri\u1ec7u \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "9478", name: "Tri\u1ec7u Giang", prefix: "X\u00e3" },
          { id: "9479", name: "Tri\u1ec7u H\u00f2a", prefix: "X\u00e3" },
          { id: "9480", name: "Tri\u1ec7u L\u0103ng", prefix: "X\u00e3" },
          { id: "9481", name: "Tri\u1ec7u Long", prefix: "X\u00e3" },
          { id: "9482", name: "Tri\u1ec7u Ph\u01b0\u1edbc", prefix: "X\u00e3" },
          { id: "9483", name: "Tri\u1ec7u S\u01a1n", prefix: "X\u00e3" },
          { id: "9484", name: "Tri\u1ec7u T\u00e0i", prefix: "X\u00e3" },
          { id: "9485", name: "Tri\u1ec7u Th\u00e0nh", prefix: "X\u00e3" },
          { id: "9486", name: "Tri\u1ec7u Thu\u1eadn", prefix: "X\u00e3" },
          {
            id: "9487",
            name: "Tri\u1ec7u Th\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
          { id: "9488", name: "Tri\u1ec7u Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "9489", name: "Tri\u1ec7u Trung", prefix: "X\u00e3" },
          { id: "9490", name: "Tri\u1ec7u V\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "30724",
            name: "\u0110o\u00e0n Khu\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30725",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30726",
            name: "T\u1ec9nh L\u1ed9 64",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "598",
        name: "V\u0129nh Linh",
        wards: [
          { id: "9491", name: "B\u1ebfn Quan", prefix: "Th\u1ecb tr\u1ea5n" },
          {
            id: "9492",
            name: "C\u1eeda T\u00f9ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9493", name: "H\u1ed3 X\u00e1", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "9494", name: "V\u0129nh Ch\u1ea5p", prefix: "X\u00e3" },
          { id: "9495", name: "V\u0129nh Giang", prefix: "X\u00e3" },
          { id: "9496", name: "V\u0129nh H\u00e0", prefix: "X\u00e3" },
          { id: "9497", name: "V\u0129nh Hi\u1ec1n", prefix: "X\u00e3" },
          { id: "9498", name: "V\u0129nh H\u00f2a", prefix: "X\u00e3" },
          { id: "9499", name: "V\u0129nh Kh\u00ea", prefix: "X\u00e3" },
          { id: "9500", name: "V\u0129nh Kim", prefix: "X\u00e3" },
          { id: "9501", name: "V\u0129nh L\u00e2m", prefix: "X\u00e3" },
          { id: "9502", name: "V\u0129nh Long", prefix: "X\u00e3" },
          { id: "9503", name: "V\u0129nh Nam", prefix: "X\u00e3" },
          { id: "9504", name: "V\u0129nh \u00d4", prefix: "X\u00e3" },
          { id: "9505", name: "V\u0129nh S\u01a1n", prefix: "X\u00e3" },
          { id: "9506", name: "V\u0129nh T\u00e2n", prefix: "X\u00e3" },
          { id: "9507", name: "V\u0129nh Th\u1ea1ch", prefix: "X\u00e3" },
          { id: "9508", name: "V\u0129nh Th\u00e1i", prefix: "X\u00e3" },
          { id: "9509", name: "V\u0129nh Th\u00e0nh", prefix: "X\u00e3" },
          { id: "9510", name: "V\u0129nh Th\u1ee7y", prefix: "X\u00e3" },
          { id: "9511", name: "V\u0129nh Trung", prefix: "X\u00e3" },
          { id: "9512", name: "V\u0129nh T\u00fa", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "30727",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30728",
            name: "Huy\u1ec1n Tr\u00e2n C\u00f4ng Ch\u00faa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30729",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30730",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30731",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30732",
            name: "Phan Huy Ch\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30733",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30734",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "52",
    code: "TV",
    name: "Tr\u00e0 Vinh",
    districts: [
      {
        id: "599",
        name: "C\u00e0ng Long",
        wards: [
          { id: "9513", name: "An Tr\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "9514", name: "An Tr\u01b0\u1eddng A", prefix: "X\u00e3" },
          { id: "9515", name: "B\u00ecnh Ph\u00fa", prefix: "X\u00e3" },
          { id: "9516", name: "C\u00e0ng Long", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "9517", name: "\u0110\u1ea1i Ph\u00fac", prefix: "X\u00e3" },
          {
            id: "9518",
            name: "\u0110\u1ea1i Ph\u01b0\u1edbc",
            prefix: "X\u00e3",
          },
          { id: "9519", name: "\u0110\u1ee9c M\u1ef9", prefix: "X\u00e3" },
          { id: "9520", name: "Huy\u1ec1n H\u1ed9i", prefix: "X\u00e3" },
          { id: "9521", name: "M\u1ef9 C\u1ea9m", prefix: "X\u00e3" },
          { id: "9522", name: "Nh\u1ecb Long", prefix: "X\u00e3" },
          { id: "9523", name: "Nh\u1ecb Long Ph\u00fa", prefix: "X\u00e3" },
          {
            id: "9524",
            name: "Ph\u01b0\u01a1ng Th\u1ea1nh",
            prefix: "X\u00e3",
          },
          { id: "9525", name: "T\u00e2n An", prefix: "X\u00e3" },
          { id: "9526", name: "T\u00e2n B\u00ecnh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "30735", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30736", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30737", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30738", name: "31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30739", name: "53", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30740", name: "60", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30741", name: "7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30742",
            name: "B\u00f9i H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30743",
            name: "H\u01b0\u01a1ng l\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30744",
            name: "Qu\u1ed1c L\u1ed9 53",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30745",
            name: "Qu\u1ed1c l\u1ed9 54",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30746",
            name: "T\u1ec9nh l\u1ed9 911",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "600",
        name: "C\u1ea7u K\u00e8",
        wards: [
          { id: "9527", name: "An Ph\u00fa T\u00e2n", prefix: "X\u00e3" },
          {
            id: "9528",
            name: "C\u1ea7u K\u00e8",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9529", name: "Ch\u00e2u \u0110i\u1ec1n", prefix: "X\u00e3" },
          { id: "9530", name: "H\u00f2a \u00c2n", prefix: "X\u00e3" },
          { id: "9531", name: "H\u00f2a T\u00e2n", prefix: "X\u00e3" },
          { id: "9532", name: "Ninh Th\u1edbi", prefix: "X\u00e3" },
          { id: "9533", name: "Phong Ph\u00fa", prefix: "X\u00e3" },
          { id: "9534", name: "Phong Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "9535", name: "Tam Ng\u00e3i", prefix: "X\u00e3" },
          { id: "9536", name: "Th\u1ea1nh Ph\u00fa", prefix: "X\u00e3" },
          { id: "9537", name: "Th\u00f4ng H\u00f2a", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "30747",
            name: "H\u01b0\u01a1ng L\u1ed9 25",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30748",
            name: "Qu\u1ed1c l\u1ed9 54",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30749",
            name: "T\u1ec9nh l\u1ed9 901",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30750",
            name: "T\u1ec9nh l\u1ed9 911",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30751",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "601",
        name: "C\u1ea7u Ngang",
        wards: [
          { id: "9538", name: "C\u1ea7u Ngang", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "9539", name: "Hi\u1ec7p H\u00f2a", prefix: "X\u00e3" },
          {
            id: "9540",
            name: "Hi\u1ec7p M\u1ef9 \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "9541", name: "Hi\u1ec7p M\u1ef9 T\u00e2y", prefix: "X\u00e3" },
          { id: "9542", name: "Kim H\u00f2a", prefix: "X\u00e3" },
          { id: "9543", name: "Long S\u01a1n", prefix: "X\u00e3" },
          { id: "9544", name: "M\u1ef9 H\u00f2a", prefix: "X\u00e3" },
          { id: "9545", name: "M\u1ef9 Long", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "9546", name: "M\u1ef9 Long B\u1eafc", prefix: "X\u00e3" },
          { id: "9547", name: "M\u1ef9 Long Nam", prefix: "X\u00e3" },
          { id: "9548", name: "Nh\u1ecb Tr\u01b0\u1eddng", prefix: "X\u00e3" },
          {
            id: "9549",
            name: "Th\u1ea1nh H\u00f2a S\u01a1n",
            prefix: "X\u00e3",
          },
          { id: "9550", name: "Thu\u1eadn H\u00f2a", prefix: "X\u00e3" },
          { id: "9551", name: "Tr\u01b0\u1eddng Th\u1ecd", prefix: "X\u00e3" },
          { id: "9552", name: "Vinh Kim", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "30752", name: "17", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30753", name: "18", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30754", name: "20", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30755", name: "23", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30756", name: "35", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30757", name: "5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30758", name: "53", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30759", name: "M\u1ef9 Long", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30760",
            name: "Qu\u1ed1c L\u1ed9 53",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "602",
        name: "Ch\u00e2u Th\u00e0nh",
        wards: [
          {
            id: "9553",
            name: "Ch\u00e2u Th\u00e0nh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9554", name: "\u0110a L\u1ed9c", prefix: "X\u00e3" },
          { id: "9555", name: "H\u00f2a L\u1ee3i", prefix: "X\u00e3" },
          { id: "9556", name: "H\u00f2a Minh", prefix: "X\u00e3" },
          { id: "9557", name: "H\u00f2a Thu\u1eadn", prefix: "X\u00e3" },
          { id: "9558", name: "H\u01b0ng M\u1ef9", prefix: "X\u00e3" },
          { id: "9559", name: "Long H\u00f2a", prefix: "X\u00e3" },
          { id: "9560", name: "L\u01b0\u01a1ng H\u00f2a", prefix: "X\u00e3" },
          { id: "9561", name: "L\u01b0\u01a1ng H\u00f2a A", prefix: "X\u00e3" },
          { id: "9562", name: "M\u1ef9 Ch\u00e1nh", prefix: "X\u00e3" },
          { id: "9563", name: "Nguy\u1ec7t H\u00f3a", prefix: "X\u00e3" },
          { id: "9564", name: "Ph\u01b0\u1edbc H\u1ea3o", prefix: "X\u00e3" },
          { id: "9565", name: "Song L\u1ed9c", prefix: "X\u00e3" },
          { id: "9566", name: "Thanh M\u1ef9", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "30761", name: "10", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30762", name: "16", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30763", name: "2/9", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30764", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30765", name: "53", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30766",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30767",
            name: "H\u01b0\u01a1ng L\u1ed9 16",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30768",
            name: "L\u00f2 H\u1ed9t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30769",
            name: "Qu\u1ed1c L\u1ed9 53",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30770",
            name: "Qu\u1ed1c l\u1ed9 54",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30771",
            name: "Qu\u1ed1c l\u1ed9 60",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "603",
        name: "Duy\u00ean H\u1ea3i",
        wards: [
          { id: "9567", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "9568", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "9569", name: "D\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          {
            id: "9571",
            name: "Duy\u00ean H\u1ea3i",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9570", name: "\u0110\u00f4ng H\u1ea3i", prefix: "X\u00e3" },
          { id: "9572", name: "Hi\u1ec7p Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "9573", name: "Long H\u1eefu", prefix: "X\u00e3" },
          { id: "9574", name: "Long Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "9575", name: "Long Th\u00e0nh", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "9576", name: "Long To\u00e0n", prefix: "X\u00e3" },
          { id: "9577", name: "Long V\u0129nh", prefix: "X\u00e3" },
          { id: "9578", name: "Ng\u0169 L\u1ea1c", prefix: "X\u00e3" },
          {
            id: "9579",
            name: "Tr\u01b0\u1eddng Long H\u00f2a",
            prefix: "X\u00e3",
          },
        ],
        streets: [
          { id: "30772", name: "53", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30773", name: "81", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30774",
            name: "H\u01b0\u01a1ng l\u1ed9 81",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30775",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30776",
            name: "Qu\u1ed1c L\u1ed9 53",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30777",
            name: "T\u1ec9nh l\u1ed9 913",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30778",
            name: "T\u1ec9nh l\u1ed9 914",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "604",
        name: "Ti\u1ec3u C\u1ea7n",
        wards: [
          { id: "9580", name: "C\u1ea7u Quan", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "9581", name: "Hi\u1ebfu Trung", prefix: "X\u00e3" },
          { id: "9582", name: "Hi\u1ebfu T\u1eed", prefix: "X\u00e3" },
          { id: "9583", name: "H\u00f9ng Ho\u00e0", prefix: "X\u00e3" },
          { id: "9584", name: "Long Th\u1edbi", prefix: "X\u00e3" },
          { id: "9585", name: "Ng\u00e3i H\u00f9ng", prefix: "X\u00e3" },
          { id: "9586", name: "Ph\u00fa C\u1ea7n", prefix: "X\u00e3" },
          { id: "9587", name: "T\u00e2n Ho\u00e0", prefix: "X\u00e3" },
          { id: "9588", name: "T\u00e2n H\u00f9ng", prefix: "X\u00e3" },
          { id: "9589", name: "T\u1eadp Ng\u00e3i", prefix: "X\u00e3" },
          {
            id: "9590",
            name: "Ti\u1ec3u C\u1ea7n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          { id: "30779", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30780", name: "60", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30781", name: "Hai B\u00e0 Tr\u01b0ng", prefix: "Ph\u1ed1" },
          {
            id: "30782",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30783",
            name: "Qu\u1ed1c l\u1ed9 54",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30784",
            name: "Qu\u1ed1c l\u1ed9 60",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30785",
            name: "T\u1ec9nh l\u1ed9 912",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30786",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "605",
        name: "Tr\u00e0 C\u00fa",
        wards: [
          { id: "9591", name: "An Qu\u1ea3ng H\u1eefu", prefix: "X\u00e3" },
          { id: "9592", name: "\u0110\u1ea1i An", prefix: "X\u00e3" },
          {
            id: "9593",
            name: "\u0110\u1ecbnh An",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9594", name: "\u0110\u1ecbnh An", prefix: "X\u00e3" },
          { id: "9595", name: "\u0110\u00f4n Ch\u00e2u", prefix: "X\u00e3" },
          { id: "9596", name: "\u0110\u00f4n Xu\u00e2n", prefix: "X\u00e3" },
          { id: "9597", name: "H\u00e0m Giang", prefix: "X\u00e3" },
          { id: "9598", name: "H\u00e0m T\u00e2n", prefix: "X\u00e3" },
          { id: "9599", name: "Kim S\u01a1n", prefix: "X\u00e3" },
          { id: "9600", name: "Long Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "9601", name: "L\u01b0u Nghi\u1ec7p Anh", prefix: "X\u00e3" },
          { id: "9602", name: "Ng\u00e3i Xuy\u00ean", prefix: "X\u00e3" },
          { id: "9603", name: "Ng\u1ecdc Bi\u00ean", prefix: "X\u00e3" },
          { id: "9604", name: "Ph\u01b0\u1edbc H\u01b0ng", prefix: "X\u00e3" },
          { id: "9605", name: "T\u00e2n Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "9606", name: "T\u00e2n S\u01a1n", prefix: "X\u00e3" },
          { id: "9607", name: "T\u1eadp S\u01a1n", prefix: "X\u00e3" },
          { id: "9608", name: "Thanh S\u01a1n", prefix: "X\u00e3" },
          {
            id: "9609",
            name: "Tr\u00e0 C\u00fa",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          { id: "30787", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30788", name: "25", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30789", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30790", name: "53", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30791",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30792",
            name: "\u0110\u1ed9c L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30793",
            name: "\u0110\u1ed3ng Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30794",
            name: "Nam K\u1ef3 Kh\u1edfi Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30795",
            name: "Qu\u1ed1c l\u1ed9 54",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30796",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30797",
            name: "T\u1ec9nh l\u1ed9 914",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "606",
        name: "Tr\u00e0 Vinh",
        wards: [
          { id: "9610", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "9611", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "9612", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "9613", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "9614", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "9615", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "9616", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "9617", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "9618", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "9619", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "9620", name: "6", prefix: "Ph\u01b0\u1eddng" },
          { id: "9621", name: "6", prefix: "Ph\u01b0\u1eddng" },
          { id: "9622", name: "7", prefix: "Ph\u01b0\u1eddng" },
          { id: "9623", name: "8", prefix: "Ph\u01b0\u1eddng" },
          { id: "9624", name: "8", prefix: "Ph\u01b0\u1eddng" },
          { id: "9625", name: "9", prefix: "Ph\u01b0\u1eddng" },
          { id: "9626", name: "Long \u0110\u1ee9c", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "30798", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30799", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30800", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30801", name: "53", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30802",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30803",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30804", name: "D5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30805", name: "Dai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30806",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30807",
            name: "\u0110\u1ed9c L\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30808",
            name: "\u0110\u1ed3ng Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30809",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30810",
            name: "Hu\u1ef3nh Th\u00fac Kh\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30811", name: "Kho D\u1ea7u", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30812",
            name: "Ki\u00ean Th\u1ecb Nh\u1eabn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30813",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30814",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30815",
            name: "L\u00ea Th\u00e1nh T\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30816",
            name: "L\u00ea V\u0103n T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30817",
            name: "L\u00ea V\u0103n T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30818",
            name: "L\u00f2 H\u1ed9t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30819",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30820",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30821",
            name: "M\u1eadu Th\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30822",
            name: "Nam K\u1ef3 Kh\u1edfi Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30823",
            name: "Ng\u00f4 Qu\u1ed1c Tr\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30824",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30825",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30826",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30829",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30827",
            name: "Nguy\u1ec5n \u0110\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30828",
            name: "Nguy\u1ec5n \u0110\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30830",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30831",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30832",
            name: "Nguy\u1ec5n Th\u1ecb \u00dat",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30833",
            name: "Nguy\u1ec5n Thi\u1ec7n Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30834",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30835",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30836",
            name: "Ph\u1ea1m Th\u00e1i B\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30837",
            name: "Phan Ch\u00e2u Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30838",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30839",
            name: "Ph\u00fa H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30840", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30841",
            name: "Qu\u1ed1c L\u1ed9 53",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30842",
            name: "Qu\u1ed1c l\u1ed9 54",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30843",
            name: "Qu\u1ed1c l\u1ed9 60",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30844", name: "S\u1ed1 2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30845",
            name: "S\u01a1n Th\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30846",
            name: "S\u01a1n V\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30847",
            name: "Th\u1ea1ch Ng\u1ecdc Bi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30848",
            name: "T\u00f4 Th\u1ecb Hu\u1ef3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30849",
            name: "T\u1ed1ng Duy T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30850",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30851",
            name: "Tr\u1ea7n Qu\u1ed1c Tu\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30852",
            name: "Tr\u1ea7n V\u0103n \u00c2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30853",
            name: "V\u00e0nh \u0110ai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30854",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30855",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30856",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30857",
            name: "V\u0169 \u0110\u00ecnh Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3056",
            name: "HQC Tr\u00e0 Vinh",
            lat: "9.9579582214355",
            lng: "106.34377288818",
          },
          {
            id: "3057",
            name: "Vincom Shophouse Tr\u00e0 Vinh",
            lat: "9.9354877471924",
            lng: "106.33653259277",
          },
        ],
      },
    ],
  },
  {
    id: "53",
    code: "HGI",
    name: "H\u1eadu Giang",
    districts: [
      {
        id: "607",
        name: "Ch\u00e2u Th\u00e0nh",
        wards: [
          { id: "9627", name: "\u0110\u00f4ng Ph\u00fa", prefix: "X\u00e3" },
          {
            id: "9628",
            name: "\u0110\u00f4ng Ph\u01b0\u1edbc",
            prefix: "X\u00e3",
          },
          {
            id: "9629",
            name: "\u0110\u00f4ng Ph\u01b0\u1edbc A",
            prefix: "X\u00e3",
          },
          { id: "9630", name: "\u0110\u00f4ng Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "9631", name: "M\u00e1i D\u1ea7m", prefix: "X\u00e3" },
          {
            id: "9632",
            name: "Ng\u00e3 S\u00e1u",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9633", name: "Ph\u00fa An", prefix: "X\u00e3" },
          { id: "9634", name: "Ph\u00fa H\u1eefu", prefix: "X\u00e3" },
          { id: "9635", name: "Ph\u00fa T\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "30858", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30859", name: "8B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30860", name: "9A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30861",
            name: "C\u00e1i Chanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30862",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30863",
            name: "Nam S\u00f4ng H\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30864",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30865",
            name: "Qu\u1ed1c l\u1ed9 925",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30866", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30867", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30868", name: "S\u1ed1 7", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30869", name: "S\u1ed1 8", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30870",
            name: "T\u1ec9nh L\u1ed9 925",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "608",
        name: "Ch\u00e2u Th\u00e0nh A",
        wards: [
          {
            id: "9636",
            name: "B\u1ea3y Ng\u00e0n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "9637",
            name: "C\u00e1i T\u1eafc",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "9638",
            name: "M\u1ed9t Ng\u00e0n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9639", name: "Nh\u01a1n Ngh\u0129a A", prefix: "X\u00e3" },
          {
            id: "9640",
            name: "R\u1ea1ch G\u00f2i",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9641", name: "T\u00e2n H\u00f2a", prefix: "X\u00e3" },
          {
            id: "9642",
            name: "T\u00e2n Ph\u00fa Th\u1ea1nh",
            prefix: "X\u00e3",
          },
          { id: "9643", name: "Th\u1ea1nh Xu\u00e2n", prefix: "X\u00e3" },
          { id: "9644", name: "Tr\u01b0\u1eddng Long A", prefix: "X\u00e3" },
          {
            id: "9645",
            name: "Tr\u01b0\u1eddng Long T\u00e2y",
            prefix: "X\u00e3",
          },
        ],
        streets: [
          { id: "30871", name: "921", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30872",
            name: "B\u1ed1n T\u1ed5ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30873", name: "\u0110T 931B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30874",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30875",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30876",
            name: "Qu\u1ed1c L\u1ed9 61",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30877",
            name: "Qu\u1ed1c L\u1ed9 61C",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3058",
            name: "Khu d\u00e2n c\u01b0 th\u01b0\u01a1ng m\u1ea1i V\u1ea1n Ph\u00e1t",
            lat: "9.9226913452148",
            lng: "105.72142791748",
          },
        ],
      },
      {
        id: "609",
        name: "Long M\u1ef9",
        wards: [
          { id: "9646", name: "Long B\u00ecnh", prefix: "X\u00e3" },
          { id: "9647", name: "Long M\u1ef9", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "9648", name: "Long Ph\u00fa", prefix: "X\u00e3" },
          { id: "9649", name: "Long Tr\u1ecb", prefix: "X\u00e3" },
          { id: "9650", name: "Long Tr\u1ecb A", prefix: "X\u00e3" },
          { id: "9651", name: "L\u01b0\u01a1ng Ngh\u0129a", prefix: "X\u00e3" },
          { id: "9652", name: "L\u01b0\u01a1ng T\u00e2m", prefix: "X\u00e3" },
          { id: "9653", name: "T\u00e2n Ph\u00fa", prefix: "X\u00e3" },
          { id: "9654", name: "Thu\u1eadn H\u00f2a", prefix: "X\u00e3" },
          { id: "9655", name: "Thu\u1eadn H\u01b0ng", prefix: "X\u00e3" },
          {
            id: "9656",
            name: "Tr\u00e0 L\u1ed3ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "9657",
            name: "V\u0129nh Thu\u1eadn \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "9658", name: "V\u0129nh Vi\u1ec5n", prefix: "X\u00e3" },
          { id: "9659", name: "V\u0129nh Vi\u1ec5n A", prefix: "X\u00e3" },
          { id: "9660", name: "X\u00e0 Phi\u00ean", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "30878", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30879",
            name: "Qu\u1ed1c l\u1ed9 61B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30880",
            name: "T\u1ec9nh L\u1ed9 61B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30881",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30882",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "610",
        name: "Ng\u00e3 B\u1ea3y",
        wards: [
          { id: "9661", name: "\u0110\u1ea1i Th\u00e0nh", prefix: "X\u00e3" },
          { id: "9662", name: "Hi\u1ec7p L\u1ee3i", prefix: "X\u00e3" },
          { id: "9663", name: "Hi\u1ec7p Th\u00e0nh", prefix: "X\u00e3" },
          {
            id: "9664",
            name: "L\u00e1i Hi\u1ebfu",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "9665", name: "Ng\u00e3 B\u1ea3y", prefix: "Ph\u01b0\u1eddng" },
          { id: "9666", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "30883", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30884", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30885",
            name: "\u0110o\u00e0n V\u0103n Chia",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30886",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30887",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30888",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30889",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30890",
            name: "M\u1ea1c \u0110\u0129nh Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30891",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30892",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30893",
            name: "Nguy\u1ec5n Minh Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30894",
            name: "Nguy\u1ec5n Th\u1ecb Xem",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30895",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30896",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30897",
            name: "Nguy\u1ec5n V\u0103n N\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30898", name: "Nh\u00e0 Th\u1edd", prefix: "Ph\u1ed1" },
          {
            id: "30899",
            name: "Ph\u1ea1m H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30900",
            name: "Qu\u1ea3n l\u1ed9 Ph\u1ee5ng Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30901",
            name: "Qu\u1ed1c L\u1ed9 A1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30902",
            name: "T\u1ec9nh l\u1ed9 927",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30903",
            name: "Tr\u1ea7n Nam Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30904",
            name: "Tri\u1ec7u \u1ea8u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "611",
        name: "Ph\u1ee5ng Hi\u1ec7p",
        wards: [
          { id: "9667", name: "B\u00ecnh Th\u00e0nh", prefix: "X\u00e3" },
          {
            id: "9668",
            name: "B\u00fang T\u00e0u",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "9669",
            name: "C\u00e2y D\u01b0\u01a1ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9670", name: "Hi\u1ec7p H\u01b0ng", prefix: "X\u00e3" },
          { id: "9671", name: "H\u00f2a An", prefix: "X\u00e3" },
          { id: "9672", name: "H\u00f2a M\u1ef9", prefix: "X\u00e3" },
          { id: "9673", name: "Kinh C\u00f9ng", prefix: "X\u00e3" },
          { id: "9674", name: "Long Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "9675", name: "Ph\u1ee5ng Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "9676", name: "Ph\u01b0\u01a1ng B\u00ecnh", prefix: "X\u00e3" },
          { id: "9677", name: "Ph\u01b0\u01a1ng Ph\u00fa", prefix: "X\u00e3" },
          { id: "9678", name: "T\u00e2n B\u00ecnh", prefix: "X\u00e3" },
          { id: "9679", name: "T\u00e2n Long", prefix: "X\u00e3" },
          {
            id: "9680",
            name: "T\u00e2n Ph\u01b0\u1edbc H\u01b0ng",
            prefix: "X\u00e3",
          },
          { id: "9681", name: "Th\u1ea1nh H\u00f2a", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "30905", name: "61", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30906", name: "\u0110T 925B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30907",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30908",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30909",
            name: "Qu\u1ed1c L\u1ed9 61",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30910",
            name: "T\u1ec9nh l\u1ed9 927",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30911",
            name: "T\u1ec9nh l\u1ed9 928",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "612",
        name: "V\u1ecb Thanh",
        wards: [
          { id: "9682", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "9683", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "9684", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "9685", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "9686", name: "7", prefix: "Ph\u01b0\u1eddng" },
          { id: "9687", name: "Ho\u1ea3 L\u1ef1u", prefix: "X\u00e3" },
          { id: "9688", name: "Ho\u1ea3 Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "9689", name: "T\u00e2n Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "9690", name: "V\u1ecb T\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "30912", name: "1/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30913", name: "19", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30914", name: "19/8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30915", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30916", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30917", name: "61", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30918",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30919",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30920",
            name: "Ch\u00e2u V\u0103n Li\u00eam",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30921",
            name: "\u0110\u1ea1i l\u1ed9 V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30922",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30923",
            name: "\u0110\u1ed3 Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30924",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30925", name: "\u0110T 933", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30926",
            name: "H\u1ea3i Th\u01b0\u1ee3ng L\u00e3n \u00d4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30927",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30928",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30929",
            name: "K\u00eanh T\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30930",
            name: "L\u1ea1c Long Qu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30931",
            name: "L\u00ea Anh Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30932",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30933",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30934",
            name: "L\u01b0u H\u1eefu Ph\u01b0\u1edbc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30935",
            name: "Ng\u00f4 H\u1eefu H\u1ea1nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30936",
            name: "Ng\u00f4 T\u1ea5t T\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30937",
            name: "Nguy\u1ec5n An Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30938",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30939",
            name: "Nguy\u1ec5n C\u00f4ng Tr\u1ee9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30940",
            name: "Nguy\u00ean H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30941",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30942",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30943",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30944",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30945",
            name: "Nguy\u1ec5n V\u0103n C\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30946",
            name: "Nguy\u1ec5n V\u0103n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30947",
            name: "Nguy\u1ec5n V\u0103n Tr\u1ed7i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30948",
            name: "Nguy\u1ec5n Vi\u1ec7t H\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30949",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30950",
            name: "Phan B\u1ed9i Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30951",
            name: "Qu\u1ed1c L\u1ed9 61",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30952",
            name: "Qu\u1ed1c L\u1ed9 61C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30953", name: "S\u1ed1 6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30954",
            name: "T\u1ec9nh l\u1ed9 932",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30955",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30956",
            name: "Tr\u1ea7n Ho\u00e0ng Na",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30957",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30958",
            name: "Tr\u1ea7n Kh\u00e1nh D\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30959",
            name: "Tr\u1ea7n Ng\u1ecdc Qu\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30960",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30961",
            name: "Tr\u1ea7n Th\u1ee7 \u0110\u1ed9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30962",
            name: "Tr\u1ea7n V\u0103n Ho\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30963",
            name: "Tri\u1ec7u Th\u1ecb Trinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30964",
            name: "Tr\u1eeb V\u0103n Th\u1ed1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30965",
            name: "Tr\u01b0ng Nh\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30966",
            name: "Tr\u01b0ng Tr\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30967",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30968",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3059",
            name: "Vincom V\u1ecb Thanh",
            lat: "9.783522605896",
            lng: "105.46971130371",
          },
        ],
      },
      {
        id: "613",
        name: "V\u1ecb Th\u1ee7y",
        wards: [
          { id: "9691", name: "N\u00e0ng Mau", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "9692", name: "V\u1ecb B\u00ecnh", prefix: "X\u00e3" },
          { id: "9693", name: "V\u1ecb \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "9694", name: "V\u1ecb Th\u1eafng", prefix: "X\u00e3" },
          { id: "9695", name: "V\u1ecb Thanh", prefix: "X\u00e3" },
          { id: "9696", name: "V\u1ecb Th\u1ee7y", prefix: "X\u00e3" },
          { id: "9697", name: "V\u1ecb Trung", prefix: "X\u00e3" },
          {
            id: "9698",
            name: "V\u0129nh Thu\u1eadn T\u00e2y",
            prefix: "X\u00e3",
          },
          { id: "9699", name: "V\u0129nh Trung", prefix: "X\u00e3" },
          { id: "9700", name: "V\u0129nh T\u01b0\u1eddng", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "30969",
            name: "Qu\u1ed1c L\u1ed9 61A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30970",
            name: "Qu\u1ed1c l\u1ed9 61B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30971",
            name: "Qu\u1ed1c L\u1ed9 61C",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3060",
            name: "Khu d\u00e2n c\u01b0 V\u1ecb Thanh",
            lat: "9.7597579956055",
            lng: "105.42202758789",
          },
        ],
      },
    ],
  },
  {
    id: "54",
    code: "SL",
    name: "S\u01a1n La",
    districts: [
      {
        id: "614",
        name: "B\u1eafc Y\u00ean",
        wards: [
          {
            id: "9701",
            name: "B\u1eafc Y\u00ean",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9702", name: "Chi\u1ec1ng S\u1ea1i", prefix: "X\u00e3" },
          { id: "9703", name: "Chim V\u00e0n", prefix: "X\u00e3" },
          { id: "9704", name: "Hang Ch\u00fa", prefix: "X\u00e3" },
          { id: "9705", name: "H\u00e1ng \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "9706", name: "H\u1ed3ng Ng\u00e0i", prefix: "X\u00e3" },
          { id: "9707", name: "Hua Nh\u00e0n", prefix: "X\u00e3" },
          { id: "9708", name: "L\u00e0ng Ch\u1ebfu", prefix: "X\u00e3" },
          { id: "9709", name: "M\u01b0\u1eddng Khoa", prefix: "X\u00e3" },
          { id: "9710", name: "P\u1eafc Ng\u00e0", prefix: "X\u00e3" },
          { id: "9711", name: "Phi\u00eang Ban", prefix: "X\u00e3" },
          { id: "9712", name: "Phi\u00eang C\u00f4n", prefix: "X\u00e3" },
          { id: "9713", name: "Song Pe", prefix: "X\u00e3" },
          { id: "9714", name: "T\u1ea1 Khoa", prefix: "X\u00e3" },
          { id: "9715", name: "T\u00e0 X\u00f9a", prefix: "X\u00e3" },
          { id: "9716", name: "X\u00edm V\u00e0ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "30972", name: "112", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30973", name: "113363", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30974", name: "37", prefix: "Ph\u1ed1" },
          {
            id: "30975",
            name: "H\u00e0 Nh\u00e2n Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30976",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "615",
        name: "Mai S\u01a1n",
        wards: [
          { id: "9717", name: "Chi\u1ec1ng Ban", prefix: "X\u00e3" },
          { id: "9718", name: "Chi\u1ec1ng Ch\u0103n", prefix: "X\u00e3" },
          { id: "9719", name: "Chi\u1ec1ng Chung", prefix: "X\u00e3" },
          { id: "9720", name: "Chi\u1ec1ng Dong", prefix: "X\u00e3" },
          { id: "9721", name: "Chi\u1ec1ng Kheo", prefix: "X\u00e3" },
          {
            id: "9722",
            name: "Chi\u1ec1ng L\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "9723", name: "Chi\u1ec1ng Mai", prefix: "X\u00e3" },
          { id: "9724", name: "Chi\u1ec1ng Mung", prefix: "X\u00e3" },
          { id: "9725", name: "Chi\u1ec1ng N\u01a1i", prefix: "X\u00e3" },
          { id: "9726", name: "Chi\u1ec1ng Sung", prefix: "X\u00e3" },
          { id: "9727", name: "Chi\u1ec1ng Ve", prefix: "X\u00e3" },
          { id: "9728", name: "C\u00f2 N\u00f2i", prefix: "X\u00e3" },
          {
            id: "9729",
            name: "H\u00e1t L\u00f3t",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9730", name: "M\u01b0\u1eddng B\u1eb1ng", prefix: "X\u00e3" },
          { id: "9731", name: "M\u01b0\u1eddng Bon", prefix: "X\u00e3" },
          { id: "9732", name: "M\u01b0\u1eddng Tranh", prefix: "X\u00e3" },
          { id: "9733", name: "N\u00e0 B\u00f3", prefix: "X\u00e3" },
          { id: "9734", name: "N\u00e0 \u1edat", prefix: "X\u00e3" },
          { id: "9735", name: "Phi\u00eang C\u1eb1m", prefix: "X\u00e3" },
          { id: "9736", name: "Phi\u00eang P\u1eb1n", prefix: "X\u00e3" },
          { id: "9737", name: "T\u00e0 H\u1ed9c", prefix: "X\u00e3" },
          { id: "9738", name: "X\u00e3 H\u00e1t L\u00f3t", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "30977", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30978",
            name: "Chi\u1ec1ng Ng\u1ea7n - M\u01b0\u1eddng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30979",
            name: "Qu\u1ed1c l\u1ed9 4G",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30980",
            name: "T\u1ec9nh l\u1ed9 103",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30981",
            name: "T\u1ec9nh l\u1ed9 104",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "616",
        name: "M\u1ed9c Ch\u00e2u",
        wards: [
          { id: "9739", name: "Chi\u1ec1ng H\u1eafc", prefix: "X\u00e3" },
          { id: "9740", name: "Chi\u1ec1ng Kh\u1eeba", prefix: "X\u00e3" },
          { id: "9741", name: "Chi\u1ec1ng S\u01a1n", prefix: "X\u00e3" },
          { id: "9742", name: "\u0110\u00f4ng Sang", prefix: "X\u00e3" },
          { id: "9743", name: "Hua P\u0103ng", prefix: "X\u00e3" },
          { id: "9744", name: "L\u00f3ng S\u1eadp", prefix: "X\u00e3" },
          {
            id: "9745",
            name: "M\u1ed9c Ch\u00e2u",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9746", name: "M\u01b0\u1eddng Sang", prefix: "X\u00e3" },
          { id: "9747", name: "N\u00e0 M\u01b0\u1eddng", prefix: "X\u00e3" },
          {
            id: "9748",
            name: "N\u00f4ng tr\u01b0\u1eddng M\u1ed9c Ch\u00e2u",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9749", name: "Phi\u00eang Lu\u00f4ng", prefix: "X\u00e3" },
          { id: "9750", name: "Quy H\u01b0\u1edbng", prefix: "X\u00e3" },
          { id: "9751", name: "T\u00e0 L\u1ea1i", prefix: "X\u00e3" },
          { id: "9752", name: "T\u00e2n H\u1ee3p", prefix: "X\u00e3" },
          { id: "9753", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "30982", name: "37", prefix: "Ph\u1ed1" },
          { id: "30983", name: "43", prefix: "\u0110\u01b0\u1eddng" },
          { id: "30984", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30985",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30986",
            name: "Th\u1ea3o Nguy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "617",
        name: "M\u01b0\u1eddng La",
        wards: [
          { id: "9754", name: "Chi\u1ec1ng \u00c2n", prefix: "X\u00e3" },
          { id: "9755", name: "Chi\u1ec1ng C\u00f4ng", prefix: "X\u00e3" },
          { id: "9756", name: "Chi\u1ec1ng Hoa", prefix: "X\u00e3" },
          { id: "9757", name: "Chi\u1ec1ng Lao", prefix: "X\u00e3" },
          { id: "9758", name: "Chi\u1ec1ng Mu\u00f4n", prefix: "X\u00e3" },
          { id: "9759", name: "Chi\u1ec1ng San", prefix: "X\u00e3" },
          { id: "9760", name: "Hua Trai", prefix: "X\u00e3" },
          { id: "9761", name: "\u00cdt Ong", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "9762", name: "M\u01b0\u1eddng B\u00fa", prefix: "X\u00e3" },
          { id: "9763", name: "M\u01b0\u1eddng Ch\u00f9m", prefix: "X\u00e3" },
          { id: "9764", name: "M\u01b0\u1eddng Trai", prefix: "X\u00e3" },
          { id: "9765", name: "N\u1eadm Gi\u00f4n", prefix: "X\u00e3" },
          { id: "9766", name: "N\u1eadm P\u0103m", prefix: "X\u00e3" },
          { id: "9767", name: "Ng\u1ecdc Chi\u1ebfn", prefix: "X\u00e3" },
          { id: "9768", name: "Pi Toong", prefix: "X\u00e3" },
          { id: "9769", name: "T\u1ea1 B\u00fa", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "30987", name: "\u00cdt Ong", prefix: "Ph\u1ed1" },
          {
            id: "30988",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "30989", name: "L\u00ea Thanh Ngh\u1ecb", prefix: "Ph\u1ed1" },
          {
            id: "30990",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "30991",
            name: "L\u00f2 V\u0103n Gi\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30992",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30993",
            name: "Qu\u1ed1c l\u1ed9 106",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30994",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "618",
        name: "Ph\u00f9 Y\u00ean",
        wards: [
          { id: "9770", name: "B\u1eafc Phong", prefix: "X\u00e3" },
          { id: "9771", name: "\u0110\u00e1 \u0110\u1ecf", prefix: "X\u00e3" },
          { id: "9772", name: "Gia Ph\u00f9", prefix: "X\u00e3" },
          { id: "9773", name: "Huy B\u1eafc", prefix: "X\u00e3" },
          { id: "9774", name: "Huy H\u1ea1", prefix: "X\u00e3" },
          { id: "9775", name: "Huy T\u00e2n", prefix: "X\u00e3" },
          { id: "9776", name: "Huy Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "9777", name: "Huy T\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "9778", name: "Kim Bon", prefix: "X\u00e3" },
          { id: "9779", name: "M\u01b0\u1eddng Bang", prefix: "X\u00e3" },
          { id: "9780", name: "M\u01b0\u1eddng C\u01a1i", prefix: "X\u00e3" },
          { id: "9781", name: "M\u01b0\u1eddng Do", prefix: "X\u00e3" },
          { id: "9782", name: "M\u01b0\u1eddng Lang", prefix: "X\u00e3" },
          { id: "9783", name: "M\u01b0\u1eddng Th\u1ea3i", prefix: "X\u00e3" },
          { id: "9784", name: "Nam Phong", prefix: "X\u00e3" },
          {
            id: "9785",
            name: "Ph\u00f9 Y\u00ean",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9786", name: "Quang Huy", prefix: "X\u00e3" },
          { id: "9787", name: "S\u1eadp Sa", prefix: "X\u00e3" },
          { id: "9788", name: "Su\u1ed1i Bau", prefix: "X\u00e3" },
          { id: "9789", name: "Su\u1ed1i T\u1ecd", prefix: "X\u00e3" },
          { id: "9790", name: "T\u00e2n Lang", prefix: "X\u00e3" },
          { id: "9791", name: "T\u00e2n Phong", prefix: "X\u00e3" },
          { id: "9792", name: "T\u01b0\u1eddng H\u1ea1", prefix: "X\u00e3" },
          { id: "9793", name: "T\u01b0\u1eddng Phong", prefix: "X\u00e3" },
          { id: "9794", name: "T\u01b0\u1eddng Ph\u00f9", prefix: "X\u00e3" },
          {
            id: "9795",
            name: "T\u01b0\u1eddng Th\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
          { id: "9796", name: "T\u01b0\u1eddng Ti\u1ebfn", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "30995", name: "37", prefix: "Ph\u1ed1" },
          { id: "30996", name: "43", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "30997",
            name: "Qu\u1ed1c l\u1ed9 32B",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "619",
        name: "Qu\u1ef3nh Nhai",
        wards: [
          { id: "9797", name: "C\u00e0 N\u00e0ng", prefix: "X\u00e3" },
          { id: "9798", name: "Chi\u1ec1ng B\u1eb1ng", prefix: "X\u00e3" },
          { id: "9799", name: "Chi\u1ec1ng Khay", prefix: "X\u00e3" },
          { id: "9800", name: "Chi\u1ec1ng Khoang", prefix: "X\u00e3" },
          { id: "9801", name: "Chi\u1ec1ng \u01a0n", prefix: "X\u00e3" },
          { id: "9802", name: "M\u01b0\u1eddng Chi\u00ean", prefix: "X\u00e3" },
          { id: "9803", name: "M\u01b0\u1eddng Gi\u00e0ng", prefix: "X\u00e3" },
          { id: "9804", name: "M\u01b0\u1eddng Gi\u00f4n", prefix: "X\u00e3" },
          { id: "9805", name: "M\u01b0\u1eddng S\u1ea1i", prefix: "X\u00e3" },
          { id: "9806", name: "N\u1eadm \u00c9t", prefix: "X\u00e3" },
          { id: "9807", name: "P\u00e1 Ma Pha Khinh", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "30998",
            name: "Qu\u1ed1c l\u1ed9 279",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "30999",
            name: "T\u1ec9nh l\u1ed9 107",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "620",
        name: "S\u01a1n La",
        wards: [
          { id: "9808", name: "Chi\u1ec1ng An", prefix: "Ph\u01b0\u1eddng" },
          { id: "9809", name: "Chi\u1ec1ng C\u1ecd", prefix: "X\u00e3" },
          {
            id: "9810",
            name: "Chi\u1ec1ng C\u01a1i",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "9811", name: "Chi\u1ec1ng \u0110en", prefix: "X\u00e3" },
          {
            id: "9812",
            name: "Chi\u1ec1ng L\u1ec1",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "9813", name: "Chi\u1ec1ng Ng\u1ea7n", prefix: "X\u00e3" },
          { id: "9814", name: "Chi\u1ec1ng Sinh", prefix: "Ph\u01b0\u1eddng" },
          { id: "9815", name: "Chi\u1ec1ng X\u00f4m", prefix: "X\u00e3" },
          { id: "9816", name: "Hua La", prefix: "X\u00e3" },
          {
            id: "9817",
            name: "Quy\u1ebft T\u00e2m",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "9818",
            name: "Quy\u1ebft Th\u1eafng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "9819", name: "T\u00f4 Hi\u1ec7u", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "31000", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31001",
            name: "B\u00e0n C\u1edd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31002",
            name: "B\u1ea3n H\u1eb9o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31003",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31004",
            name: "Chi\u1ec1ng Sinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31005",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31006",
            name: "Chu V\u0103n Th\u1ecbnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31007",
            name: "\u0110i\u1ec7n Bi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31008",
            name: "Gi\u1ea3ng L\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31009",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31010",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31011",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31012",
            name: "Hu\u1ed5i Hin",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31013",
            name: "Kh\u1ea9u C\u1ea3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31014",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31015",
            name: "L\u00ea \u0110\u1ee9c Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31016",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31017",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31018",
            name: "L\u00ea Th\u00e1i T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31019", name: "L\u00ea Thanh Ngh\u1ecb", prefix: "Ph\u1ed1" },
          {
            id: "31020",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "31021",
            name: "L\u00f2 V\u0103n Gi\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31022",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31023",
            name: "Mai \u0110\u1eafc B\u00e2n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "31024",
            name: "Ng\u00f4 Gia Kh\u1ea3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31025",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31026",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31027",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31028",
            name: "Nguy\u1ec5n L\u01b0\u01a1ng B\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31029",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31030",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31031",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31032",
            name: "Qu\u1ed1c l\u1ed9 4G",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31033",
            name: "Qu\u1ed1c l\u1ed9 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31034", name: "S\u1ed1 18", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31035",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31036",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31037",
            name: "Tr\u1ea7n \u0110\u0103ng Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31038",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31039",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31040",
            name: "Tr\u01b0\u1eddng L\u1ec1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31041",
            name: "Tr\u01b0\u1eddng S\u01a1n T\u00e2y",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31042",
            name: "V\u0103n Ti\u1ebfn D\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31043",
            name: "Xu\u00e2n Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3061",
            name: "Vincom S\u01a1n La",
            lat: "21.322927474976",
            lng: "103.91725158691",
          },
        ],
      },
      {
        id: "621",
        name: "S\u00f4ng M\u00e3",
        wards: [
          { id: "9820", name: "B\u00f3 Sinh", prefix: "X\u00e3" },
          { id: "9821", name: "Chi\u1ec1ng Cang", prefix: "X\u00e3" },
          { id: "9822", name: "Chi\u1ec1ng En", prefix: "X\u00e3" },
          { id: "9823", name: "Chi\u1ec1ng Khoong", prefix: "X\u00e3" },
          {
            id: "9824",
            name: "Chi\u1ec1ng Kh\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "9825", name: "Chi\u1ec1ng Phung", prefix: "X\u00e3" },
          { id: "9826", name: "Chi\u1ec1ng S\u01a1", prefix: "X\u00e3" },
          { id: "9827", name: "\u0110\u1ee9a M\u00f2n", prefix: "X\u00e3" },
          { id: "9828", name: "Hu\u1ed5i M\u1ed9t", prefix: "X\u00e3" },
          { id: "9829", name: "M\u01b0\u1eddng Cai", prefix: "X\u00e3" },
          { id: "9830", name: "M\u01b0\u1eddng Hung", prefix: "X\u00e3" },
          { id: "9831", name: "M\u01b0\u1eddng L\u1ea7m", prefix: "X\u00e3" },
          { id: "9832", name: "M\u01b0\u1eddng Sai", prefix: "X\u00e3" },
          { id: "9833", name: "N\u00e0 Ngh\u1ecbu", prefix: "X\u00e3" },
          { id: "9834", name: "N\u1eadm M\u1eb1n", prefix: "X\u00e3" },
          { id: "9835", name: "N\u1eadm Ty", prefix: "X\u00e3" },
          { id: "9836", name: "P\u00fa P\u1ea9u", prefix: "X\u00e3" },
          {
            id: "9837",
            name: "S\u00f4ng M\u00e3",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9838", name: "Y\u00ean H\u01b0ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31044", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31045", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31046", name: "8/3", prefix: "Ph\u1ed1" },
          {
            id: "31047",
            name: "Bi\u00ean H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31048",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31049",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31050",
            name: "Ho\u00e0ng C\u00f4ng Ch\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31051",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31052",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31053",
            name: "L\u00ea V\u0103n T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31054",
            name: "L\u00f2 V\u0103n Gi\u00e1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31055",
            name: "Ngu\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31056",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31057",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31058",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "622",
        name: "S\u1ed1p C\u1ed9p",
        wards: [
          { id: "9839", name: "D\u1ed3m Cang", prefix: "X\u00e3" },
          { id: "9840", name: "M\u01b0\u1eddng L\u1ea1n", prefix: "X\u00e3" },
          { id: "9841", name: "M\u01b0\u1eddng L\u00e8o", prefix: "X\u00e3" },
          { id: "9842", name: "M\u01b0\u1eddng V\u00e0", prefix: "X\u00e3" },
          { id: "9843", name: "N\u1eadm L\u1ea1nh", prefix: "X\u00e3" },
          { id: "9844", name: "P\u00fang B\u00e1nh", prefix: "X\u00e3" },
          { id: "9845", name: "Sam Kha", prefix: "X\u00e3" },
          { id: "9846", name: "S\u1ed1p C\u1ed9p", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "31059",
            name: "T\u1ec9nh l\u1ed9 105",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "623",
        name: "Thu\u1eadn Ch\u00e2u",
        wards: [
          { id: "9847", name: "B\u1ea3n L\u1ea7m", prefix: "X\u00e3" },
          { id: "9848", name: "B\u00f3 M\u01b0\u1eddi", prefix: "X\u00e3" },
          { id: "9849", name: "Bon Ph\u1eb7ng", prefix: "X\u00e3" },
          { id: "9850", name: "Chi\u1ec1ng B\u00f4m", prefix: "X\u00e3" },
          { id: "9851", name: "Chi\u1ec1ng La", prefix: "X\u00e3" },
          { id: "9852", name: "Chi\u1ec1ng Ly", prefix: "X\u00e3" },
          { id: "9853", name: "Chi\u1ec1ng Ng\u00e0m", prefix: "X\u00e3" },
          { id: "9854", name: "Chi\u1ec1ng P\u1ea5c", prefix: "X\u00e3" },
          { id: "9855", name: "Chi\u1ec1ng Pha", prefix: "X\u00e3" },
          { id: "9856", name: "Co M\u1ea1", prefix: "X\u00e3" },
          { id: "9857", name: "Co T\u00f2ng", prefix: "X\u00e3" },
          { id: "9858", name: "\u00c9 T\u00f2ng", prefix: "X\u00e3" },
          { id: "9859", name: "Li\u1ec7p T\u00e8", prefix: "X\u00e3" },
          { id: "9860", name: "Long H\u1eb9", prefix: "X\u00e3" },
          { id: "9861", name: "Mu\u1ed5i N\u1ecdi", prefix: "X\u00e3" },
          { id: "9862", name: "M\u01b0\u1eddng B\u00e1m", prefix: "X\u00e3" },
          { id: "9863", name: "M\u01b0\u1eddng \u00c9", prefix: "X\u00e3" },
          {
            id: "9864",
            name: "M\u01b0\u1eddng Khi\u00eang",
            prefix: "X\u00e3",
          },
          { id: "9865", name: "N\u1eadm L\u1ea7u", prefix: "X\u00e3" },
          { id: "9866", name: "Nong Lay", prefix: "X\u00e3" },
          { id: "9867", name: "P\u00e1 L\u00f4ng", prefix: "X\u00e3" },
          { id: "9868", name: "Ph\u1ed5ng L\u00e1i", prefix: "X\u00e3" },
          { id: "9869", name: "Ph\u1ed5ng L\u1eb7ng", prefix: "X\u00e3" },
          { id: "9870", name: "Ph\u1ecfng L\u1eadp", prefix: "X\u00e3" },
          { id: "9871", name: "P\u00fang Tra", prefix: "X\u00e3" },
          { id: "9872", name: "Th\u00f4m M\u00f2n", prefix: "X\u00e3" },
          {
            id: "9873",
            name: "Thu\u1eadn Ch\u00e2u",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9874", name: "T\u00f4ng C\u1ecd", prefix: "X\u00e3" },
          { id: "9875", name: "T\u00f4ng L\u1ec7nh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31060", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31061",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31062",
            name: "T\u1ec9nh l\u1ed9 107",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "624",
        name: "V\u00e2n H\u1ed3",
        wards: [
          { id: "9876", name: "Chi\u1ec1ng Khoa", prefix: "X\u00e3" },
          { id: "9877", name: "Chi\u1ec1ng Xu\u00e2n", prefix: "X\u00e3" },
          { id: "9878", name: "Chi\u1ec1ng Y\u00ean", prefix: "X\u00e3" },
          { id: "9879", name: "Li\u00ean H\u00f2a", prefix: "X\u00e3" },
          { id: "9880", name: "L\u00f3ng Lu\u00f4ng", prefix: "X\u00e3" },
          { id: "9881", name: "M\u01b0\u1eddng Men", prefix: "X\u00e3" },
          { id: "9882", name: "M\u01b0\u1eddng T\u00e8", prefix: "X\u00e3" },
          { id: "9883", name: "Quang Minh", prefix: "X\u00e3" },
          { id: "9884", name: "Song Kh\u1ee7a", prefix: "X\u00e3" },
          { id: "9885", name: "Su\u1ed1i B\u1ea3ng", prefix: "X\u00e3" },
          { id: "9886", name: "T\u00e2n Xu\u00e2n", prefix: "X\u00e3" },
          { id: "9887", name: "T\u00f4 M\u00faa", prefix: "X\u00e3" },
          { id: "9888", name: "V\u00e2n H\u1ed3", prefix: "X\u00e3" },
          { id: "9889", name: "Xu\u00e2n Nha", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "625",
        name: "Y\u00ean Ch\u00e2u",
        wards: [
          { id: "9890", name: "Chi\u1ec1ng \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "9891", name: "Chi\u1ec1ng H\u1eb7c", prefix: "X\u00e3" },
          { id: "9892", name: "Chi\u1ec1ng Khoi", prefix: "X\u00e3" },
          { id: "9893", name: "Chi\u1ec1ng On", prefix: "X\u00e3" },
          { id: "9894", name: "Chi\u1ec1ng P\u1eb1n", prefix: "X\u00e3" },
          { id: "9895", name: "Chi\u1ec1ng S\u00e0ng", prefix: "X\u00e3" },
          {
            id: "9896",
            name: "Chi\u1ec1ng T\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "9897", name: "L\u00f3ng Phi\u00eang", prefix: "X\u00e3" },
          { id: "9898", name: "M\u01b0\u1eddng L\u1ef1m", prefix: "X\u00e3" },
          { id: "9899", name: "Phi\u00eang Kho\u00e0i", prefix: "X\u00e3" },
          { id: "9900", name: "S\u1eadp V\u1ea1t", prefix: "X\u00e3" },
          { id: "9901", name: "T\u00fa Nang", prefix: "X\u00e3" },
          { id: "9902", name: "Vi\u00eang L\u00e1n", prefix: "X\u00e3" },
          {
            id: "9903",
            name: "Y\u00ean Ch\u00e2u",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9904", name: "Y\u00ean S\u01a1n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31063", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31064",
            name: "T\u1ec9nh l\u1ed9 103",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31065",
            name: "T\u1ec9nh l\u1ed9 104",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "55",
    code: "BL",
    name: "B\u1ea1c Li\u00eau",
    districts: [
      {
        id: "626",
        name: "B\u1ea1c Li\u00eau",
        wards: [
          { id: "9905", name: "1", prefix: "Ph\u01b0\u1eddng" },
          { id: "9906", name: "2", prefix: "Ph\u01b0\u1eddng" },
          { id: "9907", name: "3", prefix: "Ph\u01b0\u1eddng" },
          { id: "9908", name: "4", prefix: "Ph\u01b0\u1eddng" },
          { id: "9909", name: "5", prefix: "Ph\u01b0\u1eddng" },
          { id: "9910", name: "7", prefix: "Ph\u01b0\u1eddng" },
          { id: "9911", name: "8", prefix: "Ph\u01b0\u1eddng" },
          { id: "9912", name: "Hi\u1ec7p Th\u00e0nh", prefix: "X\u00e3" },
          { id: "9913", name: "Nh\u00e0 M\u00e1t", prefix: "Ph\u01b0\u1eddng" },
          { id: "9914", name: "Nh\u00e0 M\u00e1t", prefix: "Ph\u01b0\u1eddng" },
          { id: "9915", name: "V\u0129nh Tr\u1ea1ch", prefix: "X\u00e3" },
          {
            id: "9916",
            name: "V\u0129nh Tr\u1ea1ch \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
        ],
        streets: [
          { id: "31066", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31067", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31068", name: "1E", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31069", name: "23/8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31070", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31071", name: "31", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31072", name: "38", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31073", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31074", name: "6A", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31075",
            name: "B\u00e0 Huy\u1ec7n Thanh Quan",
            prefix: "Ph\u1ed1",
          },
          { id: "31076", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "31077",
            name: "B\u1ea1ch \u0110\u1eb1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31078",
            name: "B\u00f4ng V\u0103n D\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31079",
            name: "B\u00f9i H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31080",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31081",
            name: "Cao V\u0103n L\u1ea7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31082",
            name: "C\u1ea7u X\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31083",
            name: "Ch\u00e2u V\u0103n \u0110\u1eb7ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31088",
            name: "D\u01b0\u01a1ng Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31089", name: "Duy T\u00e2n", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31084",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31085",
            name: "\u0110inh B\u1ed9 L\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31086",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31087",
            name: "\u0110\u1ed3ng Kh\u1edfi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31090",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31091",
            name: "H\u00e0m Nghi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31092",
            name: "H\u1ed3 Th\u1ecb K\u1ef7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31093",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31094",
            name: "Ho\u00e0ng Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31095",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31096",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31097",
            name: "H\u01b0\u01a1ng L\u1ed9 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31098",
            name: "Hu\u1ef3nh Qu\u1ea3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31099",
            name: "Hu\u1ef3nh V\u0103n X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31101",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31100",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31102",
            name: "L\u00ea H\u1ed3ng Nhi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31103",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31104",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31105",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31106",
            name: "L\u00ea Thi\u1ebft H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31107",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "31108",
            name: "L\u00ea V\u0103n Duy\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31109", name: "L\u00f2 R\u00e8n", prefix: "Ph\u1ed1" },
          {
            id: "31110",
            name: "L\u1ed9c Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31111",
            name: "Nam S\u00f4ng H\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31112",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31113",
            name: "Ng\u00f4 Quang Nh\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31114",
            name: "Nguy\u1ec5n C\u00f4ng T\u1ed9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31116",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31115",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31117",
            name: "Nguy\u1ec5n Hu\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31118",
            name: "Nguy\u1ec5n H\u1eefu Ngh\u0129a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31119",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31120",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31121",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31122",
            name: "Nguy\u1ec5n Th\u1ecb M\u01b0\u1eddi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31123",
            name: "Nguy\u1ec5n Th\u1ecb Nho",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31124",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31125",
            name: "Nguy\u1ec5n Trung Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31126",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31127",
            name: "Nguy\u1ec5n Vi\u1ebft Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31128",
            name: "Ninh B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31129",
            name: "N\u1ecdc N\u1ea1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31130",
            name: "Ph\u1ea1m H\u1ed3ng Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31131",
            name: "Ph\u1ea1m Ng\u1ecdc Hi\u1ec3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31132",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31133",
            name: "R\u1ea1ch C\u1ea7n Th\u0103ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31134", name: "S\u1ed1 29", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31135",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31136",
            name: "T\u1ec9nh l\u1ed9 38",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31137",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "31138",
            name: "Tr\u1ea7n Hu\u1ef3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31139",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31140",
            name: "Tr\u1ea7n Th\u1ecb Kh\u00e9o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31141",
            name: "Tr\u1ea7n V\u0103n S\u1edbm",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31142",
            name: "Tr\u1ea7n V\u0103n Th\u1eddi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31143",
            name: "Tr\u1ea7n V\u0103n Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31144",
            name: "Tr\u01b0\u01a1ng H\u00e1n Si\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31145",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31146",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3062",
            name: "Khu d\u00e2n c\u01b0 Tr\u00e0ng An",
            lat: "9.3121824264526",
            lng: "105.71965789795",
          },
        ],
      },
      {
        id: "627",
        name: "\u0110\u00f4ng H\u1ea3i",
        wards: [
          { id: "9917", name: "An Ph\u00fac", prefix: "X\u00e3" },
          { id: "9918", name: "An Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "9919", name: "An Tr\u1ea1ch A", prefix: "X\u00e3" },
          { id: "9920", name: "\u0110i\u1ec1n H\u1ea3i", prefix: "X\u00e3" },
          { id: "9921", name: "\u0110\u1ecbnh Th\u00e0nh", prefix: "X\u00e3" },
          {
            id: "9922",
            name: "\u0110\u1ecbnh Th\u00e0nh A",
            prefix: "X\u00e3",
          },
          {
            id: "9923",
            name: "G\u00e0nh H\u00e0o",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9924", name: "Long \u0110i\u1ec1n", prefix: "X\u00e3" },
          {
            id: "9925",
            name: "Long \u0110i\u1ec1n \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "9926",
            name: "Long \u0110i\u1ec1n \u0110\u00f4ng A",
            prefix: "X\u00e3",
          },
          {
            id: "9927",
            name: "Long \u0110i\u1ec1n T\u00e2y",
            prefix: "X\u00e3",
          },
        ],
        streets: [{ id: "31147", name: "1", prefix: "\u0110\u01b0\u1eddng" }],
        projects: [],
      },
      {
        id: "628",
        name: "Gi\u00e1 Rai",
        wards: [
          { id: "9928", name: "Gi\u00e1 Rai", prefix: "Th\u1ecb tr\u1ea5n" },
          {
            id: "9929",
            name: "H\u1ed9 Ph\u00f2ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "9930",
            name: "L\u00e1ng Tr\u00f2n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "9931", name: "Phong T\u00e2n", prefix: "X\u00e3" },
          { id: "9932", name: "Phong Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "9933", name: "Phong Th\u1ea1nh A", prefix: "X\u00e3" },
          {
            id: "9934",
            name: "Phong Th\u1ea1nh \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "9935",
            name: "Phong Th\u1ea1nh \u0110\u00f4ng A",
            prefix: "X\u00e3",
          },
          { id: "9936", name: "Phong Th\u1ea1nh T\u00e2y", prefix: "X\u00e3" },
          { id: "9937", name: "T\u00e2n Phong", prefix: "X\u00e3" },
          { id: "9938", name: "T\u00e2n Th\u1ea1nh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31148", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31149",
            name: "Qu\u1ea3n l\u1ed9 Ph\u1ee5ng Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31150",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "629",
        name: "H\u00f2a B\u00ecnh",
        wards: [
          {
            id: "9939",
            name: "Ho\u00e0 B\u00ecnh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9940", name: "Minh Di\u1ec7u", prefix: "X\u00e3" },
          { id: "9941", name: "V\u0129nh B\u00ecnh", prefix: "X\u00e3" },
          { id: "9942", name: "V\u0129nh H\u1eadu", prefix: "X\u00e3" },
          { id: "9943", name: "V\u0129nh H\u1eadu A", prefix: "X\u00e3" },
          { id: "9944", name: "V\u0129nh M\u1ef9 A", prefix: "X\u00e3" },
          { id: "9945", name: "V\u0129nh M\u1ef9 B", prefix: "X\u00e3" },
          { id: "9946", name: "V\u0129nh Th\u1ecbnh", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31151", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31152",
            name: "An D\u01b0\u01a1ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31153",
            name: "Phan Th\u1ecb T\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31154",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "630",
        name: "H\u1ed3ng D\u00e2n",
        wards: [
          { id: "9947", name: "L\u1ed9c Ninh", prefix: "X\u00e3" },
          { id: "9948", name: "Ngan D\u1eeba", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "9949", name: "Ninh H\u00f2a", prefix: "X\u00e3" },
          { id: "9950", name: "Ninh Qu\u1edbi", prefix: "X\u00e3" },
          { id: "9951", name: "Ninh Qu\u1edbi A", prefix: "X\u00e3" },
          { id: "9952", name: "Ninh Th\u1ea1nh L\u1ee3i", prefix: "X\u00e3" },
          { id: "9953", name: "Ninh Th\u1ea1nh L\u1ee3i A", prefix: "X\u00e3" },
          { id: "9954", name: "V\u0129nh L\u1ed9c", prefix: "X\u00e3" },
          { id: "9955", name: "V\u0129nh L\u1ed9c A", prefix: "X\u00e3" },
        ],
        streets: [{ id: "31155", name: "1", prefix: "\u0110\u01b0\u1eddng" }],
        projects: [],
      },
      {
        id: "631",
        name: "Ph\u01b0\u1edbc Long",
        wards: [
          { id: "9956", name: "H\u01b0ng Ph\u00fa", prefix: "X\u00e3" },
          {
            id: "9957",
            name: "Phong Th\u1ea1nh T\u00e2y A",
            prefix: "X\u00e3",
          },
          {
            id: "9958",
            name: "Phong Th\u1ea1nh T\u00e2y B",
            prefix: "X\u00e3",
          },
          {
            id: "9959",
            name: "Ph\u01b0\u1edbc Long",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9960", name: "Ph\u01b0\u1edbc Long", prefix: "X\u00e3" },
          {
            id: "9961",
            name: "V\u0129nh Ph\u00fa \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          {
            id: "9962",
            name: "V\u0129nh Ph\u00fa T\u00e2y",
            prefix: "X\u00e3",
          },
          { id: "9963", name: "V\u0129nh Thanh", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "31156",
            name: "Qu\u1ea3n l\u1ed9 Ph\u1ee5ng Hi\u1ec7p",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "632",
        name: "V\u0129nh L\u1ee3i",
        wards: [
          {
            id: "9964",
            name: "Ch\u00e2u H\u01b0ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "9965", name: "Ch\u00e2u H\u01b0ng A", prefix: "X\u00e3" },
          { id: "9966", name: "Ch\u00e2u Th\u1edbi", prefix: "X\u00e3" },
          { id: "9967", name: "H\u01b0ng H\u1ed9i", prefix: "X\u00e3" },
          { id: "9968", name: "H\u01b0ng Th\u00e0nh", prefix: "X\u00e3" },
          { id: "9969", name: "Long Th\u1ea1nh", prefix: "X\u00e3" },
          { id: "9970", name: "V\u0129nh H\u01b0ng", prefix: "X\u00e3" },
          { id: "9971", name: "V\u0129nh H\u01b0ng A", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31157", name: "1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31158", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31159",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "56",
    code: "YB",
    name: "Y\u00ean B\u00e1i",
    districts: [
      {
        id: "633",
        name: "L\u1ee5c Y\u00ean",
        wards: [
          { id: "9972", name: "An L\u1ea1c", prefix: "X\u00e3" },
          { id: "9973", name: "An Ph\u00fa", prefix: "X\u00e3" },
          { id: "9974", name: "\u0110\u1ed9ng Quan", prefix: "X\u00e3" },
          { id: "9975", name: "Khai Trung", prefix: "X\u00e3" },
          { id: "9976", name: "Kh\u00e1nh H\u00f2a", prefix: "X\u00e3" },
          { id: "9977", name: "Kh\u00e1nh Thi\u1ec7n", prefix: "X\u00e3" },
          { id: "9978", name: "L\u00e2m Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "9979", name: "Li\u1ec5u \u0110\u00f4", prefix: "X\u00e3" },
          { id: "9980", name: "Mai S\u01a1n", prefix: "X\u00e3" },
          { id: "9981", name: "Minh Chu\u1ea9n", prefix: "X\u00e3" },
          { id: "9982", name: "Minh Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "9983", name: "Minh Xu\u00e2n", prefix: "X\u00e3" },
          { id: "9984", name: "M\u01b0\u1eddng Lai", prefix: "X\u00e3" },
          { id: "9985", name: "Phan Thanh", prefix: "X\u00e3" },
          { id: "9986", name: "Ph\u00fac L\u1ee3i", prefix: "X\u00e3" },
          { id: "9987", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "9988", name: "T\u00e2n L\u0129nh", prefix: "X\u00e3" },
          { id: "9989", name: "T\u00e2n Ph\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "9990", name: "T\u00f4 M\u1eadu", prefix: "X\u00e3" },
          { id: "9991", name: "Tr\u00fac L\u00e2u", prefix: "X\u00e3" },
          { id: "9992", name: "Trung T\u00e2m", prefix: "X\u00e3" },
          { id: "9993", name: "V\u0129nh L\u1ea1c", prefix: "X\u00e3" },
          { id: "9994", name: "Y\u00ean Th\u1eafng", prefix: "X\u00e3" },
          {
            id: "9995",
            name: "Y\u00ean Th\u1ebf",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "634",
        name: "M\u00f9 Cang Ch\u1ea3i",
        wards: [
          { id: "9996", name: "Cao Ph\u1ea1", prefix: "X\u00e3" },
          { id: "9997", name: "Ch\u1ebf Cu Nha", prefix: "X\u00e3" },
          { id: "9998", name: "Ch\u1ebf T\u1ea1o", prefix: "X\u00e3" },
          { id: "9999", name: "D\u1ebf Xu Ph\u00ecnh", prefix: "X\u00e3" },
          { id: "10000", name: "H\u1ed3 B\u1ed1n", prefix: "X\u00e3" },
          { id: "10001", name: "Khao Mang", prefix: "X\u00e3" },
          { id: "10002", name: "Kim N\u1ecdi", prefix: "X\u00e3" },
          { id: "10003", name: "La P\u00e1n T\u1ea9n", prefix: "X\u00e3" },
          { id: "10004", name: "Lao Ch\u1ea3i", prefix: "X\u00e3" },
          { id: "10005", name: "M\u1ed3 D\u1ec1", prefix: "X\u00e3" },
          {
            id: "10006",
            name: "M\u00f9 Cang Ch\u1ea3i",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10007", name: "N\u1eadm C\u00f3", prefix: "X\u00e3" },
          { id: "10008", name: "N\u1eadm Kh\u1eaft", prefix: "X\u00e3" },
          { id: "10009", name: "P\u00fang Lu\u00f4ng", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "635",
        name: "Ngh\u0129a L\u1ed9",
        wards: [
          { id: "10010", name: "C\u1ea7u Thia", prefix: "Ph\u01b0\u1eddng" },
          { id: "10011", name: "Ngh\u0129a An", prefix: "X\u00e3" },
          { id: "10012", name: "Ngh\u0129a L\u1ee3i", prefix: "X\u00e3" },
          { id: "10013", name: "Ngh\u0129a Ph\u00fac", prefix: "X\u00e3" },
          {
            id: "10014",
            name: "P\u00fa Tr\u1ea1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "10015", name: "T\u00e2n An", prefix: "Ph\u01b0\u1eddng" },
          { id: "10016", name: "Trung T\u00e2m", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "31160", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31161", name: "29", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31162", name: "An H\u00f2a", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31163", name: "Ao Sen", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31164",
            name: "B\u1ea3n L\u00e8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31165",
            name: "B\u1ea3n V\u1ec7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31166", name: "Cang N\u00e0", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31167",
            name: "\u0110i\u1ec7n Bi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31168",
            name: "Ho\u00e0ng Li\u00ean S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31169",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31170",
            name: "N\u1eadm Thia",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31171",
            name: "Ngh\u0129a L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31172", name: "Ngh\u0129a T\u00e2n", prefix: "Ph\u1ed1" },
          {
            id: "31173",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31174",
            name: "Nguy\u1ec5n Quang B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31175",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31176",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31177",
            name: "P\u00e1 K\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31178",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31179",
            name: "Ph\u1ea1m Quang Th\u1ea9m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31180",
            name: "P\u00fa Ch\u1ea1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31181", name: "P\u00fa Lo", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31182",
            name: "Sang H\u00e1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31183",
            name: "T\u00e2n Th\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31184",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31185", name: "T\u00f4ng Co", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31186",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "636",
        name: "Tr\u1ea1m T\u1ea5u",
        wards: [
          { id: "10017", name: "B\u1ea3n C\u00f4ng", prefix: "X\u00e3" },
          { id: "10018", name: "B\u1ea3n M\u00f9", prefix: "X\u00e3" },
          { id: "10019", name: "H\u00e1t L\u1eebu", prefix: "X\u00e3" },
          { id: "10020", name: "L\u00e0ng Nh\u00ec", prefix: "X\u00e3" },
          { id: "10021", name: "P\u00e1 Hu", prefix: "X\u00e3" },
          { id: "10022", name: "P\u00e1 Lau", prefix: "X\u00e3" },
          { id: "10023", name: "Ph\u00ecnh H\u1ed3", prefix: "X\u00e3" },
          { id: "10024", name: "T\u00e0 Si L\u00e1ng", prefix: "X\u00e3" },
          {
            id: "10025",
            name: "Tr\u1ea1m T\u1ea5u",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10026", name: "Tr\u1ea1m T\u1ea5u", prefix: "X\u00e3" },
          { id: "10027", name: "T\u00fac \u0110\u00e1n", prefix: "X\u00e3" },
          { id: "10028", name: "X\u00e0 H\u1ed3", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "637",
        name: "Tr\u1ea5n Y\u00ean",
        wards: [
          { id: "10029", name: "B\u00e1o \u0110\u00e1p", prefix: "X\u00e3" },
          { id: "10030", name: "B\u1ea3o H\u01b0ng", prefix: "X\u00e3" },
          {
            id: "10031",
            name: "C\u1ed5 Ph\u00fac",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "10032",
            name: "C\u01b0\u1eddng Th\u1ecbnh",
            prefix: "X\u00e3",
          },
          { id: "10033", name: "\u0110\u00e0o Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "10034", name: "H\u00f2a Cu\u00f4ng", prefix: "X\u00e3" },
          { id: "10035", name: "H\u1ed3ng Ca", prefix: "X\u00e3" },
          { id: "10036", name: "H\u01b0ng Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "10037", name: "H\u01b0ng Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "10038", name: "Ki\u00ean Th\u00e0nh", prefix: "X\u00e3" },
          {
            id: "10039",
            name: "L\u01b0\u01a1ng Th\u1ecbnh",
            prefix: "X\u00e3",
          },
          { id: "10040", name: "Minh Qu\u00e1n", prefix: "X\u00e3" },
          { id: "10041", name: "Minh Qu\u00e2n", prefix: "X\u00e3" },
          { id: "10042", name: "Minh Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "10043", name: "Nga Qu\u00e1n", prefix: "X\u00e3" },
          { id: "10044", name: "Quy M\u00f4ng", prefix: "X\u00e3" },
          { id: "10045", name: "T\u00e2n \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "10046", name: "V\u00e2n H\u1ed9i", prefix: "X\u00e3" },
          { id: "10047", name: "Vi\u1ec7t C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "10048", name: "Vi\u1ec7t H\u1ed3ng", prefix: "X\u00e3" },
          { id: "10049", name: "Vi\u1ec7t Th\u00e0nh", prefix: "X\u00e3" },
          { id: "10050", name: "Y Can", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "31187",
            name: "Y\u00ean B\u00e1i-Khe Sang",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "638",
        name: "V\u0103n Ch\u1ea5n",
        wards: [
          { id: "10051", name: "An L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "10052", name: "B\u00ecnh Thu\u1eadn", prefix: "X\u00e3" },
          { id: "10053", name: "C\u00e1t Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "10054", name: "Ch\u1ea5n Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "10055", name: "\u0110\u1ea1i L\u1ecbch", prefix: "X\u00e3" },
          { id: "10056", name: "\u0110\u1ed3ng Kh\u00ea", prefix: "X\u00e3" },
          { id: "10057", name: "Gia H\u1ed9i", prefix: "X\u00e3" },
          { id: "10058", name: "H\u1ea1nh S\u01a1n", prefix: "X\u00e3" },
          { id: "10059", name: "Minh An", prefix: "X\u00e3" },
          { id: "10060", name: "N\u1eadm B\u00fang", prefix: "X\u00e3" },
          { id: "10061", name: "N\u1eadm L\u00e0nh", prefix: "X\u00e3" },
          { id: "10062", name: "N\u1eadm M\u01b0\u1eddi", prefix: "X\u00e3" },
          { id: "10063", name: "Ngh\u0129a S\u01a1n", prefix: "X\u00e3" },
          { id: "10064", name: "Ngh\u0129a T\u00e2m", prefix: "X\u00e3" },
          {
            id: "10065",
            name: "N\u00f4ng tr\u01b0\u1eddng Li\u00ean S\u01a1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "10066",
            name: "N\u00f4ng tr\u01b0\u1eddng Ngh\u0129a L\u1ed9",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "10067",
            name: "N\u00f4ng tr\u01b0\u1eddng Tr\u1ea7n Ph\u00fa",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10068", name: "Ph\u00f9 Nham", prefix: "X\u00e3" },
          { id: "10069", name: "Ph\u00fac S\u01a1n", prefix: "X\u00e3" },
          { id: "10070", name: "S\u01a1n A", prefix: "X\u00e3" },
          { id: "10071", name: "S\u01a1n L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "10072", name: "S\u01a1n Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "10073", name: "S\u00f9ng \u0110\u00f4", prefix: "X\u00e3" },
          { id: "10074", name: "Su\u1ed1i Bu", prefix: "X\u00e3" },
          { id: "10075", name: "Su\u1ed1i Gi\u00e0ng", prefix: "X\u00e3" },
          { id: "10076", name: "Su\u1ed1i Quy\u1ec1n", prefix: "X\u00e3" },
          { id: "10077", name: "T\u00e2n Th\u1ecbnh", prefix: "X\u00e3" },
          {
            id: "10078",
            name: "Th\u1ea1ch L\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "10079", name: "Thanh L\u01b0\u01a1ng", prefix: "X\u00e3" },
          {
            id: "10080",
            name: "Th\u01b0\u1ee3ng B\u1eb1ng La",
            prefix: "X\u00e3",
          },
          { id: "10081", name: "T\u00fa L\u1ec7", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "31188",
            name: "Qu\u1ed1c l\u1ed9 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "639",
        name: "V\u0103n Y\u00ean",
        wards: [
          { id: "10082", name: "An B\u00ecnh", prefix: "X\u00e3" },
          { id: "10083", name: "An Th\u1ecbnh", prefix: "X\u00e3" },
          {
            id: "10084",
            name: "Ch\u00e2u Qu\u1ebf H\u1ea1",
            prefix: "X\u00e3",
          },
          {
            id: "10085",
            name: "Ch\u00e2u Qu\u1ebf Th\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
          { id: "10086", name: "\u0110\u1ea1i Ph\u00e1c", prefix: "X\u00e3" },
          { id: "10087", name: "\u0110\u1ea1i S\u01a1n", prefix: "X\u00e3" },
          { id: "10088", name: "\u0110\u00f4ng An", prefix: "X\u00e3" },
          { id: "10089", name: "\u0110\u00f4ng Cu\u00f4ng", prefix: "X\u00e3" },
          { id: "10090", name: "Ho\u00e0ng Th\u1eafng", prefix: "X\u00e3" },
          { id: "10091", name: "L\u00e2m Giang", prefix: "X\u00e3" },
          { id: "10092", name: "Lang Th\u00edp", prefix: "X\u00e3" },
          { id: "10093", name: "M\u1eadu A", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "10094", name: "M\u1eadu \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "10095", name: "M\u1ecf V\u00e0ng", prefix: "X\u00e3" },
          { id: "10096", name: "N\u00e0 H\u1ea9u", prefix: "X\u00e3" },
          { id: "10097", name: "Ng\u00f2i A", prefix: "X\u00e3" },
          { id: "10098", name: "Phong D\u1ee5 H\u1ea1", prefix: "X\u00e3" },
          {
            id: "10099",
            name: "Phong D\u1ee5 Th\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
          { id: "10100", name: "Quang Minh", prefix: "X\u00e3" },
          { id: "10101", name: "T\u00e2n H\u1ee3p", prefix: "X\u00e3" },
          { id: "10102", name: "Vi\u1ec5n S\u01a1n", prefix: "X\u00e3" },
          { id: "10103", name: "Xu\u00e2n \u00c1i", prefix: "X\u00e3" },
          { id: "10104", name: "Xu\u00e2n T\u1ea7m", prefix: "X\u00e3" },
          { id: "10105", name: "Y\u00ean H\u1ee3p", prefix: "X\u00e3" },
          { id: "10106", name: "Y\u00ean H\u01b0ng", prefix: "X\u00e3" },
          { id: "10107", name: "Y\u00ean Ph\u00fa", prefix: "X\u00e3" },
          { id: "10108", name: "Y\u00ean Th\u00e1i", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31189", name: "3/2", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31190",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31191", name: "Ga Nh\u00e2m", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31192",
            name: "G\u1ed1c S\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31193",
            name: "H\u00e0 Ch\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31194", name: "Hoa Ban", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31195",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31196",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31197",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31198",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31199",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "31200",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31201",
            name: "Nguy\u1ec5n Khuy\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31202",
            name: "Ph\u1ea5n Ch\u00ec",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31203",
            name: "Quy\u1ebft Ti\u1ebfn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31204",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31205",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31206",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31207",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31208",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "640",
        name: "Y\u00ean B\u00e1i",
        wards: [
          { id: "10109", name: "\u00c2u L\u00e2u", prefix: "X\u00e3" },
          {
            id: "10110",
            name: "\u0110\u1ed3ng T\u00e2m",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "10111", name: "Gi\u1edbi Phi\u00ean", prefix: "X\u00e3" },
          {
            id: "10112",
            name: "H\u1ed3ng H\u00e0",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "10113", name: "H\u1ee3p Minh", prefix: "X\u00e3" },
          { id: "10114", name: "Minh B\u1ea3o", prefix: "X\u00e3" },
          { id: "10115", name: "Minh T\u00e2n", prefix: "Ph\u01b0\u1eddng" },
          { id: "10116", name: "Nam C\u01b0\u1eddng", prefix: "X\u00e3" },
          {
            id: "10117",
            name: "Nguy\u1ec5n Ph\u00fac",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "10118",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "10119", name: "Ph\u00fac L\u1ed9c", prefix: "X\u00e3" },
          { id: "10120", name: "T\u00e2n Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "10121", name: "Tuy L\u1ed9c", prefix: "X\u00e3" },
          { id: "10122", name: "V\u0103n Ph\u00fa", prefix: "X\u00e3" },
          { id: "10123", name: "V\u0103n Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "10124", name: "Y\u00ean Ninh", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "10125",
            name: "Y\u00ean Th\u1ecbnh",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "31209", name: "70", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31210",
            name: "\u00c2u C\u01a1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31211",
            name: "\u00c2u L\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31212",
            name: "B\u1ea3o L\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31213",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31214",
            name: "Cao Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31216",
            name: "D\u00e3 T\u01b0\u1ee3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31215",
            name: "\u0110\u00e1 Bia",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31217", name: "\u0110\u1eb7ng Dung", prefix: "Ph\u1ed1" },
          {
            id: "31218",
            name: "\u0110\u00e0o Duy T\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31219",
            name: "\u0110\u00e0o T\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31220",
            name: "\u0110i\u1ec7n Bi\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31221",
            name: "\u0110inh L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31222", name: "\u0110inh Li\u1ec7t", prefix: "Ph\u1ed1" },
          {
            id: "31223",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31224",
            name: "\u0110\u1ed7 V\u0103n \u0110\u1ee9c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31225",
            name: "\u0110o\u00e0n Th\u1ecb \u0110i\u1ec3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31226",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31227",
            name: "H\u1ed3 Xu\u00e2n H\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31228",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31229", name: "H\u00f2a C\u01b0\u1eddng", prefix: "Ph\u1ed1" },
          {
            id: "31230",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31231",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31232",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31233", name: "H\u1ed9i B\u00ecnh", prefix: "Ph\u1ed1" },
          {
            id: "31234",
            name: "H\u1ed3ng H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31235", name: "H\u1ed3ng T\u00e2n", prefix: "Ph\u1ed1" },
          { id: "31236", name: "Khe S\u1ebfn", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31237",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31238",
            name: "L\u00ea Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31239",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31240", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31241",
            name: "L\u00ea Qu\u00fd \u0110\u00f4n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31242",
            name: "L\u00ea Tr\u1ef1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31243",
            name: "L\u00ea V\u0103n T\u00e2m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31244",
            name: "L\u01b0\u01a1ng V\u0103n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31245",
            name: "L\u01b0\u01a1ng Y\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31246",
            name: "L\u00fd \u0110\u1ea1o Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31247",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "31248",
            name: "Mai H\u1eafc \u0110\u1ebf",
            prefix: "Ph\u1ed1",
          },
          { id: "31249", name: "Mai V\u0103n Ty", prefix: "Ph\u1ed1" },
          { id: "31250", name: "Minh Khai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31251",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31252",
            name: "Ng\u00f4 Minh Loan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31253",
            name: "Ng\u00f4 S\u1ef9 Li\u00ean",
            prefix: "Ph\u1ed1",
          },
          {
            id: "31254",
            name: "Nguy\u1ec5n C\u1ea3nh Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31255",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31256",
            name: "Nguy\u1ec5n \u0110\u1ee9c C\u1ea3nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31257",
            name: "Nguy\u1ec5n Kh\u1eafc Nhu",
            prefix: "Ph\u1ed1",
          },
          {
            id: "31258",
            name: "Nguy\u1ec5n Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31259",
            name: "Nguy\u1ec5n Quang B\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31260",
            name: "Nguy\u1ec5n T\u1ea5t Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31261",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31262",
            name: "Nguy\u1ec5n V\u0103n C\u1eeb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31263",
            name: "Ph\u1ea1m Ng\u0169 L\u00e3o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31264",
            name: "Phan \u0110\u0103ng L\u01b0u",
            prefix: "Ph\u1ed1",
          },
          {
            id: "31265",
            name: "Ph\u00f3 \u0110\u1ee9c Ch\u00ednh",
            prefix: "Ph\u1ed1",
          },
          {
            id: "31266",
            name: "Ph\u00fac T\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31267",
            name: "Ph\u00f9ng Kh\u1eafc Khoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31268", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31269",
            name: "Th\u00e0nh C\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31270",
            name: "Thanh H\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31271",
            name: "Thanh Li\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31272",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31273",
            name: "Th\u00e0nh Trung",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31274",
            name: "T\u00f4 Hi\u1ebfn Th\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31275",
            name: "T\u00f4 Ng\u1ecdc V\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31276",
            name: "Tr\u1ea7n B\u00ecnh Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31277",
            name: "Tr\u1ea7n \u0110\u1ee9c S\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31278",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31279",
            name: "Tr\u1ea7n Nguy\u00ean H\u00e3n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "31280",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31281",
            name: "Tr\u1ea7n Quang Kh\u1ea3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31282",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31283",
            name: "Tr\u01b0\u01a1ng Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31284",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31285",
            name: "V\u00f5 Th\u1ecb S\u00e1u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31286",
            name: "Y\u00ean H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31287", name: "Y\u00ean L\u1ea1c", prefix: "Ph\u1ed1" },
          {
            id: "31288",
            name: "Y\u00ean Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31289",
            name: "Y\u00ean Th\u1ebf",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31290",
            name: "Y\u1ebft Ki\u00eau",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3063",
            name: "Vincom Y\u00ean B\u00e1i",
            lat: "21.708532333374",
            lng: "104.88175201416",
          },
        ],
      },
      {
        id: "641",
        name: "Y\u00ean B\u00ecnh",
        wards: [
          { id: "10126", name: "B\u1ea1ch H\u00e0", prefix: "X\u00e3" },
          { id: "10127", name: "B\u1ea3o \u00c1i", prefix: "X\u00e3" },
          { id: "10128", name: "C\u1ea3m \u00c2n", prefix: "X\u00e3" },
          { id: "10129", name: "C\u1ea9m Nh\u00e2n", prefix: "X\u00e3" },
          {
            id: "10130",
            name: "\u0110\u1ea1i \u0110\u1ed3ng",
            prefix: "X\u00e3",
          },
          { id: "10131", name: "\u0110\u1ea1i Minh", prefix: "X\u00e3" },
          { id: "10132", name: "H\u00e1n \u0110\u00e0", prefix: "X\u00e3" },
          { id: "10133", name: "M\u00f4ng S\u01a1n", prefix: "X\u00e3" },
          { id: "10134", name: "M\u1ef9 Gia", prefix: "X\u00e3" },
          { id: "10135", name: "Ng\u1ecdc Ch\u1ea5n", prefix: "X\u00e3" },
          { id: "10136", name: "Ph\u00fa Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "10137", name: "Ph\u00fac An", prefix: "X\u00e3" },
          { id: "10138", name: "Ph\u00fac Ninh", prefix: "X\u00e3" },
          { id: "10139", name: "T\u00e2n H\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "10140", name: "T\u00e2n Nguy\u00ean", prefix: "X\u00e3" },
          {
            id: "10141",
            name: "Th\u00e1c B\u00e0",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10142", name: "Th\u1ecbnh H\u01b0ng", prefix: "X\u00e3" },
          { id: "10143", name: "T\u00edch C\u1ed1c", prefix: "X\u00e3" },
          { id: "10144", name: "V\u0103n L\u00e3ng", prefix: "X\u00e3" },
          { id: "10145", name: "V\u0129nh Ki\u00ean", prefix: "X\u00e3" },
          { id: "10146", name: "V\u0169 Linh", prefix: "X\u00e3" },
          { id: "10147", name: "Xu\u00e2n Lai", prefix: "X\u00e3" },
          { id: "10148", name: "Xu\u00e2n Long", prefix: "X\u00e3" },
          {
            id: "10149",
            name: "Y\u00ean B\u00ecnh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10150", name: "Y\u00ean B\u00ecnh", prefix: "X\u00e3" },
          { id: "10151", name: "Y\u00ean Th\u00e0nh", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
    ],
  },
  {
    id: "57",
    code: "TQ",
    name: "Tuy\u00ean Quang",
    districts: [
      {
        id: "642",
        name: "Chi\u00eam H\u00f3a",
        wards: [
          { id: "10152", name: "B\u00ecnh Nh\u00e2n", prefix: "X\u00e3" },
          { id: "10153", name: "B\u00ecnh Ph\u00fa", prefix: "X\u00e3" },
          { id: "10154", name: "H\u00e0 Lang", prefix: "X\u00e3" },
          { id: "10155", name: "H\u00f2a An", prefix: "X\u00e3" },
          { id: "10156", name: "H\u00f2a Ph\u00fa", prefix: "X\u00e3" },
          { id: "10157", name: "H\u00f9ng M\u1ef9", prefix: "X\u00e3" },
          { id: "10158", name: "Ki\u00ean \u0110\u00e0i", prefix: "X\u00e3" },
          { id: "10159", name: "Kim B\u00ecnh", prefix: "X\u00e3" },
          { id: "10160", name: "Linh Ph\u00fa", prefix: "X\u00e3" },
          { id: "10161", name: "Minh Quang", prefix: "X\u00e3" },
          { id: "10162", name: "Ng\u1ecdc H\u1ed9i", prefix: "X\u00e3" },
          { id: "10163", name: "Nh\u00e2n L\u00fd", prefix: "X\u00e3" },
          { id: "10164", name: "Ph\u00fa B\u00ecnh", prefix: "X\u00e3" },
          { id: "10165", name: "Ph\u00fac S\u01a1n", prefix: "X\u00e3" },
          { id: "10166", name: "Ph\u00fac Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "10167", name: "T\u00e2n An", prefix: "X\u00e3" },
          { id: "10168", name: "T\u00e2n M\u1ef9", prefix: "X\u00e3" },
          { id: "10169", name: "T\u00e2n Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "10170", name: "Tri Ph\u00fa", prefix: "X\u00e3" },
          { id: "10171", name: "Trung H\u00e0", prefix: "X\u00e3" },
          { id: "10172", name: "Trung H\u00f2a", prefix: "X\u00e3" },
          {
            id: "10173",
            name: "V\u0129nh L\u1ed9c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10174", name: "Vinh Quang", prefix: "X\u00e3" },
          { id: "10175", name: "Xu\u00e2n Quang", prefix: "X\u00e3" },
          { id: "10176", name: "Y\u00ean L\u1eadp", prefix: "X\u00e3" },
          { id: "10177", name: "Y\u00ean Nguy\u00ean", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31291", name: "176", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31292", name: "185", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31293", name: "188", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31294", name: "190", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31295",
            name: "Qu\u1ed1c l\u1ed9 279",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "643",
        name: "H\u00e0m Y\u00ean",
        wards: [
          { id: "10178", name: "B\u1ea1ch Xa", prefix: "X\u00e3" },
          { id: "10179", name: "B\u1eb1ng C\u1ed1c", prefix: "X\u00e3" },
          { id: "10180", name: "B\u00ecnh Xa", prefix: "X\u00e3" },
          { id: "10181", name: "\u0110\u1ee9c Ninh", prefix: "X\u00e3" },
          { id: "10182", name: "H\u00f9ng \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "10183", name: "Minh D\u00e2n", prefix: "X\u00e3" },
          { id: "10184", name: "Minh H\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "10185", name: "Minh Kh\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "10186", name: "Nh\u00e2n M\u1ee5c", prefix: "X\u00e3" },
          { id: "10187", name: "Ph\u00f9 L\u01b0u", prefix: "X\u00e3" },
          { id: "10188", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          {
            id: "10189",
            name: "T\u00e2n Y\u00ean",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10190", name: "Th\u00e1i H\u00f2a", prefix: "X\u00e3" },
          { id: "10191", name: "Th\u00e1i S\u01a1n", prefix: "X\u00e3" },
          { id: "10192", name: "Th\u00e0nh Long", prefix: "X\u00e3" },
          { id: "10193", name: "Y\u00ean L\u00e2m", prefix: "X\u00e3" },
          { id: "10194", name: "Y\u00ean Ph\u00fa", prefix: "X\u00e3" },
          { id: "10195", name: "Y\u00ean Thu\u1eadn", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31296", name: "189", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31297", name: "190", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31298", name: "2", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
      {
        id: "644",
        name: "L\u00e2m B\u00ecnh",
        wards: [
          { id: "10196", name: "B\u00ecnh An", prefix: "X\u00e3" },
          { id: "10197", name: "H\u1ed3ng Quang", prefix: "X\u00e3" },
          { id: "10198", name: "Khu\u00f4n H\u00e0", prefix: "X\u00e3" },
          { id: "10199", name: "L\u0103ng Can", prefix: "X\u00e3" },
          { id: "10200", name: "Ph\u00fac Y\u00ean", prefix: "X\u00e3" },
          { id: "10201", name: "Th\u1ed5 B\u00ecnh", prefix: "X\u00e3" },
          { id: "10202", name: "Th\u01b0\u1ee3ng L\u00e2m", prefix: "X\u00e3" },
          { id: "10203", name: "Xu\u00e2n L\u1eadp", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31299", name: "185", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31300", name: "188", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
      {
        id: "645",
        name: "Na Hang",
        wards: [
          { id: "10204", name: "C\u00f4n L\u00f4n", prefix: "X\u00e3" },
          { id: "10205", name: "\u0110\u00e0 V\u1ecb", prefix: "X\u00e3" },
          { id: "10206", name: "H\u1ed3ng Th\u00e1i", prefix: "X\u00e3" },
          { id: "10207", name: "Kh\u00e2u Tinh", prefix: "X\u00e3" },
          { id: "10208", name: "Na Hang", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "10209", name: "N\u0103ng Kh\u1ea3", prefix: "X\u00e3" },
          { id: "10210", name: "Sinh Long", prefix: "X\u00e3" },
          { id: "10211", name: "S\u01a1n Ph\u00fa", prefix: "X\u00e3" },
          { id: "10212", name: "Thanh T\u01b0\u01a1ng", prefix: "X\u00e3" },
          {
            id: "10213",
            name: "Th\u01b0\u1ee3ng Gi\u00e1p",
            prefix: "X\u00e3",
          },
          {
            id: "10214",
            name: "Th\u01b0\u1ee3ng N\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "10215", name: "Y\u00ean Hoa", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31301", name: "176", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31302", name: "185", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31303", name: "190", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31304",
            name: "Qu\u1ed1c l\u1ed9 279",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "646",
        name: "S\u01a1n D\u01b0\u01a1ng",
        wards: [
          { id: "10216", name: "B\u00ecnh Y\u00ean", prefix: "X\u00e3" },
          { id: "10217", name: "C\u1ea5p Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "10218", name: "Chi Thi\u1ebft", prefix: "X\u00e3" },
          { id: "10219", name: "\u0110\u1ea1i Ph\u00fa", prefix: "X\u00e3" },
          { id: "10220", name: "\u0110\u00f4ng L\u1ee3i", prefix: "X\u00e3" },
          { id: "10221", name: "\u0110\u1ed3ng Qu\u00fd", prefix: "X\u00e3" },
          { id: "10222", name: "\u0110\u00f4ng Th\u1ecd", prefix: "X\u00e3" },
          { id: "10223", name: "H\u00e0o Ph\u00fa", prefix: "X\u00e3" },
          { id: "10224", name: "H\u1ed3ng L\u1ea1c", prefix: "X\u00e3" },
          { id: "10225", name: "H\u1ee3p H\u00f2a", prefix: "X\u00e3" },
          { id: "10226", name: "H\u1ee3p Th\u00e0nh", prefix: "X\u00e3" },
          { id: "10227", name: "Kh\u00e1ng Nh\u1eadt", prefix: "X\u00e3" },
          { id: "10228", name: "L\u00e2m Xuy\u00ean", prefix: "X\u00e3" },
          {
            id: "10229",
            name: "L\u01b0\u01a1ng Thi\u1ec7n",
            prefix: "X\u00e3",
          },
          { id: "10230", name: "Minh Thanh", prefix: "X\u00e3" },
          { id: "10231", name: "Ninh Lai", prefix: "X\u00e3" },
          { id: "10232", name: "Ph\u00fa L\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "10233", name: "Ph\u00fac \u1ee8ng", prefix: "X\u00e3" },
          { id: "10234", name: "Quy\u1ebft Th\u1eafng", prefix: "X\u00e3" },
          { id: "10235", name: "S\u1ea7m D\u01b0\u01a1ng", prefix: "X\u00e3" },
          {
            id: "10236",
            name: "S\u01a1n D\u01b0\u01a1ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10237", name: "S\u01a1n Nam", prefix: "X\u00e3" },
          { id: "10238", name: "Tam \u0110a", prefix: "X\u00e3" },
          { id: "10239", name: "T\u00e2n Tr\u00e0o", prefix: "X\u00e3" },
          { id: "10240", name: "Thanh Ph\u00e1t", prefix: "X\u00e3" },
          { id: "10241", name: "Thi\u1ec7n K\u1ebf", prefix: "X\u00e3" },
          { id: "10242", name: "Th\u01b0\u01a1ng \u1ea4m", prefix: "X\u00e3" },
          { id: "10243", name: "Trung Y\u00ean", prefix: "X\u00e3" },
          { id: "10244", name: "T\u00fa Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "10245", name: "Tu\u00e2n L\u1ed9", prefix: "X\u00e3" },
          { id: "10246", name: "V\u0103n Ph\u00fa", prefix: "X\u00e3" },
          { id: "10247", name: "V\u00e2n S\u01a1n", prefix: "X\u00e3" },
          { id: "10248", name: "V\u0129nh L\u1ee3i", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31305", name: "37", prefix: "Ph\u1ed1" },
          {
            id: "31306",
            name: "Qu\u1ed1c l\u1ed9 2C",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "647",
        name: "Tuy\u00ean Quang",
        wards: [
          { id: "10249", name: "An Khang", prefix: "X\u00e3" },
          { id: "10250", name: "An T\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "10251", name: "\u0110\u1ed9i C\u1ea5n", prefix: "X\u00e3" },
          {
            id: "10252",
            name: "H\u01b0ng Th\u00e0nh",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "10253",
            name: "L\u01b0\u1ee1ng V\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
          { id: "10254", name: "Minh Xu\u00e2n", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "10255",
            name: "N\u00f4ng Ti\u1ebfn",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "10256", name: "Phan Thi\u1ebft", prefix: "Ph\u01b0\u1eddng" },
          { id: "10257", name: "T\u00e2n Binh", prefix: "X\u00e3" },
          { id: "10258", name: "T\u00e2n H\u00e0", prefix: "Ph\u01b0\u1eddng" },
          { id: "10259", name: "T\u00e2n Quang", prefix: "Ph\u01b0\u1eddng" },
          { id: "10260", name: "Th\u00e1i Long", prefix: "X\u00e3" },
          { id: "10261", name: "Tr\u00e0ng \u0110\u00e0", prefix: "X\u00e3" },
          { id: "10262", name: "\u1ef6 La", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "31307", name: "17/8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31308", name: "185", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31309", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31310", name: "An H\u00f2a", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31311", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "31312",
            name: "B\u00ecnh Thu\u1eadn",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31313",
            name: "H\u00e0 Huy T\u1eadp",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31314",
            name: "H\u00e0 Tuy\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31315",
            name: "Kim B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31317",
            name: "L\u00ea Du\u1ea9n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31316",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31318",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31319",
            name: "L\u00ea L\u1ee3i 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31320",
            name: "L\u01b0\u01a1ng S\u01a1n Tuy\u1ebft",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31321",
            name: "L\u00fd Th\u00e1i T\u1ed5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31322", name: "Minh Thanh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31323",
            name: "Ng\u00f4 Gia Kh\u1ea3m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31324",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31325",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31326",
            name: "Nguy\u1ec5n V\u0103n L\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31327",
            name: "Ph\u1ea1m V\u0103n \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31328",
            name: "Ph\u00fa H\u01b0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31329", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31330",
            name: "Qu\u1ed1c L\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31331",
            name: "Qu\u1ed1c l\u1ed9 2C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31332",
            name: "Qu\u1ed1c l\u1ed9 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31333",
            name: "Song H\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31334", name: "Tam C\u1edd", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31335",
            name: "T\u00e2n H\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31336",
            name: "T\u00e2n Tr\u00e0o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31337", name: "Thanh La", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31338",
            name: "Ti\u00ean L\u0169ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31339",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "31340",
            name: "T\u00f4n Th\u1ea5t T\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31341",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "31342",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31343",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31344",
            name: "Tr\u1ea7n Th\u00e1i T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31345",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31346",
            name: "Tu\u1ec7 T\u0129nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31347",
            name: "Vi\u00ean Ch\u00e2u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31348",
            name: "Xu\u00e2n H\u00f2a",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3064",
            name: "Vincom Tuy\u00ean Quang",
            lat: "21.818885803223",
            lng: "105.20527648926",
          },
        ],
      },
      {
        id: "648",
        name: "Y\u00ean S\u01a1n",
        wards: [
          { id: "10263", name: "Ch\u00e2n S\u01a1n", prefix: "X\u00e3" },
          { id: "10264", name: "Chi\u00eau Y\u00ean", prefix: "X\u00e3" },
          { id: "10265", name: "C\u00f4ng \u0110a", prefix: "X\u00e3" },
          { id: "10266", name: "\u0110\u1ea1o Vi\u1ec7n", prefix: "X\u00e3" },
          { id: "10267", name: "\u0110\u1ed9i B\u00ecnh", prefix: "X\u00e3" },
          { id: "10268", name: "Ho\u00e0ng Khai", prefix: "X\u00e3" },
          { id: "10269", name: "H\u00f9ng L\u1ee3i", prefix: "X\u00e3" },
          { id: "10270", name: "Ki\u1ebfn Thi\u1ebft", prefix: "X\u00e3" },
          { id: "10271", name: "Kim Ph\u00fa", prefix: "X\u00e3" },
          { id: "10272", name: "Kim Quan", prefix: "X\u00e3" },
          { id: "10273", name: "Lang Qu\u00e1n", prefix: "X\u00e3" },
          { id: "10274", name: "L\u1ef1c H\u00e0nh", prefix: "X\u00e3" },
          { id: "10275", name: "M\u1ef9 B\u1eb1ng", prefix: "X\u00e3" },
          { id: "10276", name: "Nh\u1eef H\u00e1n", prefix: "X\u00e3" },
          { id: "10277", name: "Nh\u1eef Kh\u00ea", prefix: "X\u00e3" },
          { id: "10278", name: "Ph\u00fa L\u00e2m", prefix: "X\u00e3" },
          { id: "10279", name: "Ph\u00fa Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "10280", name: "Ph\u00fac Ninh", prefix: "X\u00e3" },
          { id: "10281", name: "Qu\u00fd Qu\u00e2n", prefix: "X\u00e3" },
          {
            id: "10282",
            name: "T\u00e2n B\u00ecnh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10283", name: "T\u00e2n Long", prefix: "X\u00e3" },
          { id: "10284", name: "T\u00e2n Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "10285", name: "Th\u00e1i B\u00ecnh", prefix: "X\u00e3" },
          { id: "10286", name: "Th\u1eafng Qu\u00e2n", prefix: "X\u00e3" },
          { id: "10287", name: "Ti\u1ebfn B\u1ed9", prefix: "X\u00e3" },
          { id: "10288", name: "Trung Minh", prefix: "X\u00e3" },
          { id: "10289", name: "Trung M\u00f4n", prefix: "X\u00e3" },
          { id: "10290", name: "Trung S\u01a1n", prefix: "X\u00e3" },
          { id: "10291", name: "Trung Tr\u1ef1c", prefix: "X\u00e3" },
          { id: "10292", name: "T\u1ee9 Qu\u1eadn", prefix: "X\u00e3" },
          { id: "10293", name: "Xu\u00e2n V\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31349", name: "185", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31350", name: "186", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31351", name: "2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31352", name: "37", prefix: "Ph\u1ed1" },
          {
            id: "31353",
            name: "H\u01b0ng Ki\u1ec1u 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31354",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31355",
            name: "Qu\u1ed1c l\u1ed9 2B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31356",
            name: "Qu\u1ed1c l\u1ed9 2C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31357",
            name: "Qu\u1ed1c l\u1ed9 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31358",
            name: "Th\u00e1i Long",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31359",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "58",
    code: "DDB",
    name: "\u0110i\u1ec7n Bi\u00ean",
    districts: [
      {
        id: "649",
        name: "\u0110i\u1ec7n Bi\u00ean",
        wards: [
          { id: "10294", name: "H\u1eb9 Mu\u00f4ng", prefix: "X\u00e3" },
          { id: "10295", name: "Hua Thanh", prefix: "X\u00e3" },
          { id: "10296", name: "M\u01b0\u1eddng L\u00f3i", prefix: "X\u00e3" },
          { id: "10297", name: "M\u01b0\u1eddng Nh\u00e0", prefix: "X\u00e3" },
          {
            id: "10298",
            name: "M\u01b0\u1eddng Ph\u0103ng",
            prefix: "X\u00e3",
          },
          { id: "10299", name: "M\u01b0\u1eddng P\u1ed3n", prefix: "X\u00e3" },
          { id: "10300", name: "N\u00e0 Nh\u1ea1n", prefix: "X\u00e3" },
          { id: "10301", name: "N\u00e0 T\u1ea5u", prefix: "X\u00e3" },
          { id: "10302", name: "Na T\u00f4ng", prefix: "X\u00e3" },
          { id: "10303", name: "Na \u01af", prefix: "X\u00e3" },
          { id: "10304", name: "Noong Bua", prefix: "Ph\u01b0\u1eddng" },
          { id: "10305", name: "Noong H\u1eb9t", prefix: "X\u00e3" },
          { id: "10306", name: "Noong Lu\u1ed1ng", prefix: "X\u00e3" },
          { id: "10307", name: "N\u00faa Ngam", prefix: "X\u00e3" },
          { id: "10308", name: "P\u00e1 Khoang", prefix: "X\u00e3" },
          { id: "10309", name: "Pa Th\u01a1m", prefix: "X\u00e3" },
          { id: "10310", name: "Phu Lu\u00f4ng", prefix: "X\u00e3" },
          { id: "10311", name: "Pom L\u00f3t", prefix: "X\u00e3" },
          { id: "10312", name: "Sam M\u1ee9n", prefix: "X\u00e3" },
          { id: "10313", name: "Thanh An", prefix: "X\u00e3" },
          { id: "10314", name: "Thanh Ch\u0103n", prefix: "X\u00e3" },
          { id: "10315", name: "Thanh H\u01b0ng", prefix: "X\u00e3" },
          { id: "10316", name: "Thanh Lu\u00f4ng", prefix: "X\u00e3" },
          { id: "10317", name: "Thanh N\u01b0a", prefix: "X\u00e3" },
          { id: "10318", name: "Thanh X\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "10319", name: "Thanh Y\u00ean", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31360", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31361", name: "AH 13", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31362",
            name: "Ho\u00e0ng C\u00f4ng Ch\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31363",
            name: "Qu\u1ed1c L\u1ed9 12",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31364",
            name: "Qu\u1ed1c l\u1ed9 279",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31365",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "650",
        name: "\u0110i\u1ec7n Bi\u00ean \u0110\u00f4ng",
        wards: [
          { id: "10320", name: "Chi\u1ec1ng S\u01a1", prefix: "X\u00e3" },
          {
            id: "10321",
            name: "\u0110i\u1ec7n Bi\u00ean \u0110\u00f4ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10322", name: "H\u00e1ng L\u00eca", prefix: "X\u00e3" },
          { id: "10323", name: "Keo L\u00f4m", prefix: "X\u00e3" },
          { id: "10324", name: "Lu\u00e2n Gi\u1edbi", prefix: "X\u00e3" },
          { id: "10325", name: "M\u01b0\u1eddng Lu\u00e2n", prefix: "X\u00e3" },
          { id: "10326", name: "Na Son", prefix: "X\u00e3" },
          { id: "10327", name: "Nong U", prefix: "X\u00e3" },
          { id: "10328", name: "Ph\u00ec Nh\u1eeb", prefix: "X\u00e3" },
          { id: "10329", name: "Ph\u00ecnh Gi\u00e0ng", prefix: "X\u00e3" },
          { id: "10330", name: "P\u00fa H\u1ed3ng", prefix: "X\u00e3" },
          { id: "10331", name: "P\u00fa Nhi", prefix: "X\u00e3" },
          { id: "10332", name: "T\u00eca D\u00ecnh", prefix: "X\u00e3" },
          { id: "10333", name: "Xa Dung", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "651",
        name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
        wards: [
          { id: "10334", name: "Him Lam", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "10335",
            name: "M\u01b0\u1eddng Thanh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "10336", name: "Nam Thanh", prefix: "Ph\u01b0\u1eddng" },
          { id: "10337", name: "Noong Bua", prefix: "Ph\u01b0\u1eddng" },
          { id: "10338", name: "T\u00e0 L\u00e8ng", prefix: "X\u00e3" },
          { id: "10339", name: "T\u00e2n Thanh", prefix: "Ph\u01b0\u1eddng" },
          { id: "10340", name: "Thanh B\u00ecnh", prefix: "Ph\u01b0\u1eddng" },
          { id: "10341", name: "Thanh Minh", prefix: "X\u00e3" },
          {
            id: "10342",
            name: "Thanh Tr\u01b0\u1eddng",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "31366", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31367",
            name: "H\u00f2a B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31368",
            name: "Ho\u00e0ng C\u00f4ng Ch\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31369",
            name: "L\u00ea Tr\u1ecdng T\u1ea5n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "31370",
            name: "Nguy\u1ec5n H\u1eefu Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31371",
            name: "Qu\u1ed1c l\u1ed9 279",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31372",
            name: "S\u00f9ng Ph\u00e1i Sinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31373",
            name: "Tr\u1ea7n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31374",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31375",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "652",
        name: "M\u01b0\u1eddng \u1ea2ng",
        wards: [
          { id: "10343", name: "\u1eb2ng Cang", prefix: "X\u00e3" },
          { id: "10344", name: "\u1eb2ng N\u01b0a", prefix: "X\u00e3" },
          { id: "10345", name: "\u1eb2ng T\u1edf", prefix: "X\u00e3" },
          { id: "10346", name: "B\u00fang Lao", prefix: "X\u00e3" },
          {
            id: "10347",
            name: "M\u01b0\u1eddng \u1ea2ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "10348",
            name: "M\u01b0\u1eddng \u0110\u0103ng",
            prefix: "X\u00e3",
          },
          { id: "10349", name: "M\u01b0\u1eddng L\u1ea1n", prefix: "X\u00e3" },
          { id: "10350", name: "N\u1eb7m L\u1ecbch", prefix: "X\u00e3" },
          { id: "10351", name: "Ng\u1ed1i C\u00e1y", prefix: "X\u00e3" },
          { id: "10352", name: "Xu\u00e2n Lao", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "31376",
            name: "Qu\u1ed1c l\u1ed9 279",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "653",
        name: "M\u01b0\u1eddng Ch\u00e0",
        wards: [
          { id: "10353", name: "H\u1eeba Ng\u00e0i", prefix: "X\u00e3" },
          { id: "10354", name: "Hu\u1ed5i L\u00e8ng", prefix: "X\u00e3" },
          { id: "10355", name: "Hu\u1ed5i M\u00ed", prefix: "X\u00e3" },
          { id: "10356", name: "Ma Th\u00ec H\u1ed3", prefix: "X\u00e3" },
          {
            id: "10357",
            name: "M\u01b0\u1eddng Ch\u00e0",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "10358",
            name: "M\u01b0\u1eddng M\u01b0\u01a1n",
            prefix: "X\u00e3",
          },
          { id: "10359", name: "M\u01b0\u1eddng T\u00f9ng", prefix: "X\u00e3" },
          { id: "10360", name: "Na Sang", prefix: "X\u00e3" },
          { id: "10361", name: "N\u1eadm N\u00e8n", prefix: "X\u00e3" },
          { id: "10362", name: "Pa Ham", prefix: "X\u00e3" },
          { id: "10363", name: "Ph\u00ecn H\u1ed3", prefix: "X\u00e3" },
          { id: "10364", name: "Sa L\u00f4ng", prefix: "X\u00e3" },
          { id: "10365", name: "S\u00e1 T\u1ed5ng", prefix: "X\u00e3" },
          { id: "10366", name: "Si Pa Ph\u00ecn", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31377", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31378", name: "6", prefix: "\u0110\u01b0\u1eddng" },
        ],
        projects: [],
      },
      {
        id: "654",
        name: "M\u01b0\u1eddng Lay",
        wards: [
          { id: "10367", name: "Lay N\u01b0a", prefix: "X\u00e3" },
          { id: "10368", name: "Na Lay", prefix: "Ph\u01b0\u1eddng" },
          { id: "10369", name: "S\u00f4ng \u0110\u00e0", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31379", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31380", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31381",
            name: "T\u1ec9nh l\u1ed9 127",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "655",
        name: "M\u01b0\u1eddng Nh\u00e9",
        wards: [
          { id: "10370", name: "Chung Ch\u1ea3i", prefix: "X\u00e3" },
          { id: "10371", name: "Hu\u1ed5i L\u1ebfch", prefix: "X\u00e3" },
          { id: "10372", name: "Leng Su S\u00ecn", prefix: "X\u00e3" },
          { id: "10373", name: "M\u01b0\u1eddng Nh\u00e9", prefix: "X\u00e3" },
          { id: "10374", name: "M\u01b0\u1eddng Toong", prefix: "X\u00e3" },
          { id: "10375", name: "N\u1eadm K\u00e8", prefix: "X\u00e3" },
          { id: "10376", name: "N\u1eadm V\u00ec", prefix: "X\u00e3" },
          { id: "10377", name: "P\u00e1 M\u1ef3", prefix: "X\u00e3" },
          { id: "10378", name: "Qu\u1ea3ng L\u00e2m", prefix: "X\u00e3" },
          { id: "10379", name: "Sen Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "10380", name: "S\u00edn Th\u1ea7u", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "656",
        name: "N\u1eadm P\u1ed3",
        wards: [
          { id: "10381", name: "Ch\u00e0 Cang", prefix: "X\u00e3" },
          { id: "10382", name: "Ch\u00e0 N\u01b0a", prefix: "X\u00e3" },
          { id: "10383", name: "Ch\u00e0 T\u1edf", prefix: "X\u00e3" },
          { id: "10384", name: "N\u00e0 B\u1ee7ng", prefix: "X\u00e3" },
          { id: "10385", name: "Na C\u00f4 Sa", prefix: "X\u00e3" },
          { id: "10386", name: "N\u00e0 H\u1ef3", prefix: "X\u00e3" },
          { id: "10387", name: "N\u00e0 Khoa", prefix: "X\u00e3" },
          { id: "10388", name: "N\u1eadm Chua", prefix: "X\u00e3" },
          { id: "10389", name: "N\u1eadm Kh\u0103n", prefix: "X\u00e3" },
          { id: "10390", name: "N\u1eadm Nh\u1eeb", prefix: "X\u00e3" },
          { id: "10391", name: "N\u1eadm Tin", prefix: "X\u00e3" },
          { id: "10392", name: "Pa T\u1ea7n", prefix: "X\u00e3" },
          { id: "10393", name: "Ph\u00ecn H\u1ed3", prefix: "X\u00e3" },
          { id: "10394", name: "V\u00e0ng \u0110\u00e1n", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "657",
        name: "T\u1ee7a Ch\u00f9a",
        wards: [
          { id: "10395", name: "Hu\u1ed5i S\u00f3", prefix: "X\u00e3" },
          { id: "10396", name: "Lao X\u1ea3 Ph\u00ecnh", prefix: "X\u00e3" },
          { id: "10397", name: "M\u01b0\u1eddng B\u00e1ng", prefix: "X\u00e3" },
          { id: "10398", name: "M\u01b0\u1eddng \u0110un", prefix: "X\u00e3" },
          { id: "10399", name: "S\u00e1ng Nh\u00e8", prefix: "X\u00e3" },
          { id: "10400", name: "S\u00edn Ch\u1ea3i", prefix: "X\u00e3" },
          { id: "10401", name: "S\u00ednh Ph\u00ecnh", prefix: "X\u00e3" },
          { id: "10402", name: "T\u1ea3 Ph\u00ecn", prefix: "X\u00e3" },
          {
            id: "10403",
            name: "T\u1ea3 S\u00ecn Th\u00e0ng",
            prefix: "X\u00e3",
          },
          { id: "10404", name: "Trung Thu", prefix: "X\u00e3" },
          { id: "10405", name: "T\u1ee7a Ch\u00f9a", prefix: "X\u00e3" },
          { id: "10406", name: "T\u1ee7a Th\u00e0ng", prefix: "X\u00e3" },
        ],
        streets: [{ id: "31382", name: "6", prefix: "\u0110\u01b0\u1eddng" }],
        projects: [],
      },
      {
        id: "658",
        name: "Tu\u1ea7n Gi\u00e1o",
        wards: [
          {
            id: "10407",
            name: "Chi\u1ec1ng \u0110\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "10408", name: "Chi\u1ec1ng Sinh", prefix: "X\u00e3" },
          { id: "10409", name: "M\u00f9n Chung", prefix: "X\u00e3" },
          { id: "10410", name: "M\u01b0\u1eddng Khong", prefix: "X\u00e3" },
          { id: "10411", name: "M\u01b0\u1eddng M\u00f9n", prefix: "X\u00e3" },
          { id: "10412", name: "M\u01b0\u1eddng Th\u00edn", prefix: "X\u00e3" },
          { id: "10413", name: "N\u00e0 S\u00e1y", prefix: "X\u00e3" },
          { id: "10414", name: "N\u00e0 T\u00f2ng", prefix: "X\u00e3" },
          { id: "10415", name: "Ph\u00ecnh S\u00e1ng", prefix: "X\u00e3" },
          { id: "10416", name: "P\u00fa Nhung", prefix: "X\u00e3" },
          { id: "10417", name: "P\u00fa Xi", prefix: "X\u00e3" },
          { id: "10418", name: "Qu\u00e0i Cang", prefix: "X\u00e3" },
          { id: "10419", name: "Qu\u00e0i N\u01b0a", prefix: "X\u00e3" },
          { id: "10420", name: "Qu\u00e0i T\u1edf", prefix: "X\u00e3" },
          { id: "10421", name: "R\u1ea1ng \u0110\u00f4ng", prefix: "X\u00e3" },
          { id: "10422", name: "Ta Ma", prefix: "X\u00e3" },
          { id: "10423", name: "T\u00eanh Ph\u00f4ng", prefix: "X\u00e3" },
          { id: "10424", name: "T\u1ecfa T\u00ecnh", prefix: "X\u00e3" },
          {
            id: "10425",
            name: "Tu\u1ea7n Gi\u00e1o",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          { id: "31383", name: "6", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31384",
            name: "Qu\u1ed1c l\u1ed9 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "59",
    code: "LCH",
    name: "Lai Ch\u00e2u",
    districts: [
      {
        id: "659",
        name: "Lai Ch\u00e2u",
        wards: [
          {
            id: "10426",
            name: "\u0110o\u00e0n K\u1ebft",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "10427",
            name: "\u0110\u00f4ng Phong",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "10428", name: "N\u1eadm Lo\u1ecfng", prefix: "X\u00e3" },
          {
            id: "10429",
            name: "Quy\u1ebft Th\u1eafng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "10430",
            name: "Quy\u1ebft Ti\u1ebfn",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "10431", name: "San Th\u00e0ng", prefix: "X\u00e3" },
          { id: "10432", name: "T\u00e2n Phong", prefix: "Ph\u01b0\u1eddng" },
        ],
        streets: [
          { id: "31385", name: "2B2", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31386", name: "30/4", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31387", name: "58", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31388", name: "9C", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31389",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31390",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31391",
            name: "\u0110i\u1ec7n Bi\u00ean Ph\u1ee7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31392",
            name: "H\u1ed3 T\u00f9ng M\u1eadu",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31393",
            name: "Ho\u00e0ng Minh Gi\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31394",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31395",
            name: "Ho\u00e0ng V\u0103n Th\u00e1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31396",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31397",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31398",
            name: "Nguy\u1ec5n Ch\u00ed Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31399",
            name: "Nguy\u1ec5n Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31400",
            name: "Nguy\u1ec5n Tri Ph\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31401",
            name: "Ph\u1ea1m Ng\u1ecdc Th\u1ea1ch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31402", name: "Pusamcap", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31403",
            name: "Qu\u1ed1c l\u1ed9 4D",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31404",
            name: "Thanh Ni\u00ean",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31405",
            name: "T\u1ec9nh l\u1ed9 128",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31406",
            name: "T\u00f4n \u0110\u1ee9c Th\u1eafng",
            prefix: "Ph\u1ed1",
          },
          {
            id: "31407",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31408",
            name: "Tr\u1ea7n Huy Li\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31409",
            name: "Tr\u1ea7n Kh\u00e1t Ch\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31410",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31411",
            name: "Tr\u1ea7n Quang Di\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31412",
            name: "Tr\u1ea7n V\u0103n Th\u1ecd",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31413",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31414",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31415",
            name: "V\u00f5 V\u0103n Ki\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "660",
        name: "M\u01b0\u1eddng T\u00e8",
        wards: [
          { id: "10433", name: "Bum N\u01b0a", prefix: "X\u00e3" },
          { id: "10434", name: "Bum T\u1edf", prefix: "X\u00e3" },
          { id: "10435", name: "Ka L\u0103ng", prefix: "X\u00e3" },
          { id: "10436", name: "Kan H\u1ed3", prefix: "X\u00e3" },
          { id: "10437", name: "M\u00f9 C\u1ea3", prefix: "X\u00e3" },
          {
            id: "10438",
            name: "M\u01b0\u1eddng T\u00e8",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10439", name: "N\u1eadm Khao", prefix: "X\u00e3" },
          { id: "10440", name: "Pa \u1ee6", prefix: "X\u00e3" },
          { id: "10441", name: "Pa V\u1ec7 S\u1eed", prefix: "X\u00e3" },
          { id: "10442", name: "T\u00e1 B\u1ea1", prefix: "X\u00e3" },
          { id: "10443", name: "T\u00e0 T\u1ed5ng", prefix: "X\u00e3" },
          { id: "10444", name: "Thu L\u0169m", prefix: "X\u00e3" },
          { id: "10445", name: "V\u00e0ng San", prefix: "X\u00e3" },
          {
            id: "10446",
            name: "X\u00e3 M\u01b0\u1eddng T\u00e8",
            prefix: "X\u00e3",
          },
        ],
        streets: [
          {
            id: "31416",
            name: "T\u1ec9nh l\u1ed9 127",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "661",
        name: "N\u1eadm Nh\u00f9n",
        wards: [
          { id: "10447", name: "Hua Bum", prefix: "X\u00e3" },
          { id: "10448", name: "L\u00ea L\u1ee3i", prefix: "X\u00e3" },
          { id: "10449", name: "M\u01b0\u1eddng M\u00f4", prefix: "X\u00e3" },
          { id: "10450", name: "N\u1eadm Ban", prefix: "X\u00e3" },
          { id: "10451", name: "N\u1eadm Ch\u00e0", prefix: "X\u00e3" },
          { id: "10452", name: "N\u1eadm H\u00e0ng", prefix: "X\u00e3" },
          { id: "10453", name: "N\u1eadm Manh", prefix: "X\u00e3" },
          {
            id: "10454",
            name: "N\u1eadm Nh\u00f9n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10455", name: "N\u1eadm P\u00ec", prefix: "X\u00e3" },
          { id: "10456", name: "P\u00fa \u0110ao", prefix: "X\u00e3" },
          { id: "10457", name: "Trung Ch\u1ea3i", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "662",
        name: "Phong Th\u1ed5",
        wards: [
          { id: "10458", name: "B\u1ea3n Lang", prefix: "X\u00e3" },
          { id: "10459", name: "D\u00e0o San", prefix: "X\u00e3" },
          { id: "10460", name: "Hoang Th\u00e8n", prefix: "X\u00e3" },
          { id: "10461", name: "Hu\u1ed5i Lu\u00f4ng", prefix: "X\u00e3" },
          { id: "10462", name: "Kh\u1ed5ng L\u00e0o", prefix: "X\u00e3" },
          {
            id: "10463",
            name: "L\u1ea3n Nh\u00ec Th\u00e0ng",
            prefix: "X\u00e3",
          },
          { id: "10464", name: "Ma Ly Ch\u1ea3i", prefix: "X\u00e3" },
          { id: "10465", name: "Ma Ly Pho", prefix: "X\u00e3" },
          { id: "10466", name: "M\u1ed3 S\u00ec San", prefix: "X\u00e3" },
          { id: "10467", name: "M\u00f9 Sang", prefix: "X\u00e3" },
          { id: "10468", name: "M\u01b0\u1eddng So", prefix: "X\u00e3" },
          { id: "10469", name: "N\u1eadm Xe", prefix: "X\u00e3" },
          { id: "10470", name: "Pa V\u00e2y S\u1eed", prefix: "X\u00e3" },
          { id: "10471", name: "Phong Th\u1ed5", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "10472", name: "S\u00ec L\u1edf L\u1ea7u", prefix: "X\u00e3" },
          { id: "10473", name: "Sin S\u00fai H\u1ed3", prefix: "X\u00e3" },
          { id: "10474", name: "Tung Qua L\u00ecn", prefix: "X\u00e3" },
          { id: "10475", name: "V\u00e0ng Ma Ch\u1ea3i", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31417", name: "12", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31418",
            name: "Qu\u1ed1c l\u1ed9 100",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31419",
            name: "Qu\u1ed1c l\u1ed9 4D",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31420",
            name: "T\u1ec9nh l\u1ed9 128",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31421",
            name: "T\u1ec9nh l\u1ed9 132",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "663",
        name: "S\u00ecn H\u1ed3",
        wards: [
          { id: "10476", name: "C\u0103n Co", prefix: "X\u00e3" },
          { id: "10477", name: "Ch\u0103n N\u01b0a", prefix: "X\u00e3" },
          { id: "10478", name: "H\u1ed3ng Thu", prefix: "X\u00e3" },
          { id: "10479", name: "L\u00e0ng M\u00f4", prefix: "X\u00e3" },
          { id: "10480", name: "L\u00f9ng Th\u00e0ng", prefix: "X\u00e3" },
          { id: "10481", name: "Ma Quai", prefix: "X\u00e3" },
          { id: "10482", name: "Ma Quai,", prefix: "X\u00e3" },
          { id: "10483", name: "N\u1eadm Cha", prefix: "X\u00e3" },
          { id: "10484", name: "N\u1eadm Cu\u1ed5i", prefix: "X\u00e3" },
          { id: "10485", name: "N\u1eadm H\u0103n", prefix: "X\u00e3" },
          { id: "10486", name: "N\u1eadm M\u1ea1", prefix: "X\u00e3" },
          { id: "10487", name: "N\u1eadm T\u0103m", prefix: "X\u00e3" },
          { id: "10488", name: "Noong H\u1ebbo", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "10489", name: "Pa Kh\u00f3a", prefix: "X\u00e3" },
          { id: "10490", name: "Pa T\u1ea7n", prefix: "X\u00e3" },
          { id: "10491", name: "Ph\u0103ng S\u00f4 Lin", prefix: "X\u00e3" },
          { id: "10492", name: "Ph\u00ecn H\u1ed3", prefix: "X\u00e3" },
          { id: "10493", name: "Pu Sam C\u00e1p", prefix: "X\u00e3" },
          { id: "10494", name: "S\u00e0 D\u1ec1 Ph\u00ecn", prefix: "X\u00e3" },
          {
            id: "10495",
            name: "S\u00ecn H\u1ed3",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10496", name: "T\u1ea3 Ng\u1ea3o", prefix: "X\u00e3" },
          { id: "10497", name: "T\u1ea3 Ph\u00ecn", prefix: "X\u00e3" },
          {
            id: "10498",
            name: "T\u1ee7a S\u00edn Ch\u1ea3i",
            prefix: "X\u00e3",
          },
        ],
        streets: [
          {
            id: "31422",
            name: "T\u1ec9nh l\u1ed9 128",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "664",
        name: "Tam \u0110\u01b0\u1eddng",
        wards: [
          { id: "10499", name: "B\u1ea3n Bo", prefix: "X\u00e3" },
          { id: "10500", name: "B\u1ea3n Giang", prefix: "X\u00e3" },
          { id: "10501", name: "B\u1ea3n Hon", prefix: "X\u00e3" },
          { id: "10502", name: "B\u00ecnh L\u01b0", prefix: "X\u00e3" },
          { id: "10503", name: "Giang Ma", prefix: "X\u00e3" },
          { id: "10504", name: "H\u1ed3 Th\u1ea7u", prefix: "X\u00e3" },
          { id: "10505", name: "Khun H\u00e1", prefix: "X\u00e3" },
          { id: "10506", name: "N\u00e0 T\u0103m", prefix: "X\u00e3" },
          { id: "10507", name: "N\u00f9ng N\u00e0ng", prefix: "X\u00e3" },
          { id: "10508", name: "S\u01a1n B\u00ecnh", prefix: "X\u00e3" },
          { id: "10509", name: "S\u00f9ng Ph\u00e0i", prefix: "X\u00e3" },
          { id: "10510", name: "T\u1ea3 L\u00e8ng", prefix: "X\u00e3" },
          {
            id: "10511",
            name: "Tam \u0110\u01b0\u1eddng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10512", name: "Th\u00e8n Xin", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "31423",
            name: "Qu\u1ed1c l\u1ed9 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31424",
            name: "Qu\u1ed1c l\u1ed9 4D",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "665",
        name: "T\u00e2n Uy\u00ean",
        wards: [
          { id: "10513", name: "H\u1ed1 M\u00edt", prefix: "X\u00e3" },
          { id: "10514", name: "M\u01b0\u1eddng Khoa", prefix: "X\u00e3" },
          { id: "10515", name: "N\u1eadm C\u1ea7n", prefix: "X\u00e3" },
          { id: "10516", name: "N\u1eadm S\u1ecf", prefix: "X\u00e3" },
          { id: "10517", name: "P\u1eafc Ta", prefix: "X\u00e3" },
          { id: "10518", name: "Ph\u00fac Khoa", prefix: "X\u00e3" },
          { id: "10519", name: "T\u00e0 M\u00edt", prefix: "X\u00e3" },
          {
            id: "10520",
            name: "T\u00e2n Uy\u00ean",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10521", name: "Th\u00e2n Thu\u1ed9c", prefix: "X\u00e3" },
          { id: "10522", name: "Trung \u0110\u1ed3ng", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "31425",
            name: "Qu\u1ed1c l\u1ed9 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "666",
        name: "Than Uy\u00ean",
        wards: [
          { id: "10523", name: "Hua N\u00e0", prefix: "X\u00e3" },
          { id: "10524", name: "Khoen On", prefix: "X\u00e3" },
          { id: "10525", name: "M\u01b0\u1eddng Cang", prefix: "X\u00e3" },
          { id: "10526", name: "M\u01b0\u1eddng Kim", prefix: "X\u00e3" },
          { id: "10527", name: "M\u01b0\u1eddng M\u00edt", prefix: "X\u00e3" },
          { id: "10528", name: "M\u01b0\u1eddng Than", prefix: "X\u00e3" },
          { id: "10529", name: "Pha Mu", prefix: "X\u00e3" },
          { id: "10530", name: "Ph\u00fac Than", prefix: "X\u00e3" },
          { id: "10531", name: "Ta Gia", prefix: "X\u00e3" },
          { id: "10532", name: "T\u00e0 H\u1eeba", prefix: "X\u00e3" },
          { id: "10533", name: "T\u00e0 Mung", prefix: "X\u00e3" },
          { id: "10534", name: "Than Uy\u00ean", prefix: "Th\u1ecb tr\u1ea5n" },
        ],
        streets: [
          {
            id: "31426",
            name: "Qu\u1ed1c l\u1ed9 32",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "60",
    code: "LS",
    name: "L\u1ea1ng S\u01a1n",
    districts: [
      {
        id: "667",
        name: "B\u1eafc S\u01a1n",
        wards: [
          {
            id: "10535",
            name: "B\u1eafc S\u01a1n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10536", name: "B\u1eafc S\u01a1n", prefix: "X\u00e3" },
          { id: "10537", name: "Chi\u1ebfn Th\u1eafng", prefix: "X\u00e3" },
          { id: "10538", name: "Chi\u00eau V\u0169", prefix: "X\u00e3" },
          { id: "10539", name: "\u0110\u1ed3ng \u00dd", prefix: "X\u00e3" },
          { id: "10540", name: "H\u01b0ng V\u0169", prefix: "X\u00e3" },
          { id: "10541", name: "H\u1eefu V\u0129nh", prefix: "X\u00e3" },
          { id: "10542", name: "Long \u0110\u1ed1ng", prefix: "X\u00e3" },
          { id: "10543", name: "Nh\u1ea5t H\u00f2a", prefix: "X\u00e3" },
          { id: "10544", name: "Nh\u1ea5t Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "10545", name: "Qu\u1ef3nh S\u01a1n", prefix: "X\u00e3" },
          { id: "10546", name: "T\u00e2n H\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "10547", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "10548", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "10549", name: "T\u00e2n Tri", prefix: "X\u00e3" },
          { id: "10550", name: "Tr\u1ea5n Y\u00ean", prefix: "X\u00e3" },
          { id: "10551", name: "V\u1ea1n Th\u1ee7y", prefix: "X\u00e3" },
          { id: "10552", name: "V\u0169 L\u0103ng", prefix: "X\u00e3" },
          { id: "10553", name: "V\u0169 L\u1ec5", prefix: "X\u00e3" },
          { id: "10554", name: "V\u0169 S\u01a1n", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "31427",
            name: "Qu\u1ed1c l\u1ed9 1B",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "668",
        name: "B\u00ecnh Gia",
        wards: [
          { id: "10555", name: "B\u00ecnh Gia", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "10556", name: "B\u00ecnh La", prefix: "X\u00e3" },
          { id: "10557", name: "H\u00f2a B\u00ecnh", prefix: "X\u00e3" },
          { id: "10558", name: "Hoa Th\u00e1m", prefix: "X\u00e3" },
          {
            id: "10559",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "X\u00e3",
          },
          { id: "10560", name: "H\u1ed3ng Phong", prefix: "X\u00e3" },
          { id: "10561", name: "H\u1ed3ng Th\u00e1i", prefix: "X\u00e3" },
          { id: "10562", name: "H\u01b0ng \u0110\u1ea1o", prefix: "X\u00e3" },
          { id: "10563", name: "Minh Khai", prefix: "X\u00e3" },
          { id: "10564", name: "M\u00f4ng \u00c2n", prefix: "X\u00e3" },
          { id: "10565", name: "Quang Trung", prefix: "X\u00e3" },
          { id: "10566", name: "Qu\u00fd H\u00f2a", prefix: "X\u00e3" },
          { id: "10567", name: "T\u00e2n H\u00f2a", prefix: "X\u00e3" },
          { id: "10568", name: "T\u00e2n V\u0103n", prefix: "X\u00e3" },
          { id: "10569", name: "Thi\u1ec7n H\u00f2a", prefix: "X\u00e3" },
          { id: "10570", name: "Thi\u1ec7n Long", prefix: "X\u00e3" },
          { id: "10571", name: "Thi\u1ec7n Thu\u1eadt", prefix: "X\u00e3" },
          { id: "10572", name: "T\u00f4 Hi\u1ec7u", prefix: "X\u00e3" },
          { id: "10573", name: "V\u0129nh Y\u00ean", prefix: "X\u00e3" },
          { id: "10574", name: "Y\u00ean L\u1ed7", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "31428",
            name: "Qu\u1ed1c l\u1ed9 1B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31429",
            name: "T\u1ec9nh l\u1ed9 225",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "669",
        name: "Cao L\u1ed9c",
        wards: [
          { id: "10575", name: "B\u1ea3o L\u00e2m", prefix: "X\u00e3" },
          { id: "10576", name: "B\u00ecnh Trung", prefix: "X\u00e3" },
          { id: "10577", name: "Cao L\u00e2u", prefix: "X\u00e3" },
          { id: "10578", name: "Cao L\u1ed9c", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "10579", name: "C\u1ed1ng S\u01a1n", prefix: "X\u00e3" },
          {
            id: "10580",
            name: "\u0110\u1ed3ng \u0110\u0103ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10581", name: "Gia C\u00e1t", prefix: "X\u00e3" },
          { id: "10582", name: "H\u1ea3i Y\u1ebfn", prefix: "X\u00e3" },
          { id: "10583", name: "H\u00f2a C\u01b0", prefix: "X\u00e3" },
          { id: "10584", name: "H\u1ed3ng Phong", prefix: "X\u00e3" },
          { id: "10585", name: "H\u1ee3p Th\u00e0nh", prefix: "X\u00e3" },
          { id: "10586", name: "L\u1ed9c Y\u00ean", prefix: "X\u00e3" },
          { id: "10587", name: "M\u1eabu S\u01a1n", prefix: "X\u00e3" },
          { id: "10588", name: "Ph\u00fa X\u00e1", prefix: "X\u00e3" },
          { id: "10589", name: "Song Gi\u00e1p", prefix: "X\u00e3" },
          { id: "10590", name: "T\u00e2n Li\u00ean", prefix: "X\u00e3" },
          { id: "10591", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "10592", name: "Th\u1ea1ch \u0110\u1ea1n", prefix: "X\u00e3" },
          { id: "10593", name: "Thanh L\u00f2a", prefix: "X\u00e3" },
          { id: "10594", name: "Th\u1ee5y H\u00f9ng", prefix: "X\u00e3" },
          { id: "10595", name: "Xu\u00e2n Long", prefix: "X\u00e3" },
          { id: "10596", name: "Xu\u1ea5t L\u1ec5", prefix: "X\u00e3" },
          { id: "10597", name: "Y\u00ean Tr\u1ea1ch", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31430", name: "1B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31431",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "670",
        name: "Chi L\u0103ng",
        wards: [
          { id: "10598", name: "B\u1eafc Th\u1ee7y", prefix: "X\u00e3" },
          { id: "10599", name: "B\u1eb1ng H\u1eefu", prefix: "X\u00e3" },
          { id: "10600", name: "B\u1eb1ng M\u1ea1c", prefix: "X\u00e3" },
          { id: "10601", name: "Chi L\u0103ng", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "10602", name: "Chi L\u0103ng", prefix: "X\u00e3" },
          { id: "10603", name: "Chi\u1ebfn Th\u1eafng", prefix: "X\u00e3" },
          {
            id: "10604",
            name: "\u0110\u1ed3ng M\u1ecf",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10605", name: "Gia L\u1ed9c", prefix: "X\u00e3" },
          { id: "10606", name: "H\u00f2a B\u00ecnh", prefix: "X\u00e3" },
          { id: "10607", name: "H\u1ed3ng Phong", prefix: "X\u00e3" },
          { id: "10608", name: "H\u1eefu Ki\u00ean", prefix: "X\u00e3" },
          { id: "10609", name: "L\u00e2m S\u01a1n", prefix: "X\u00e3" },
          { id: "10610", name: "Li\u00ean S\u01a1n", prefix: "X\u00e3" },
          { id: "10611", name: "Mai Sao", prefix: "X\u00e3" },
          { id: "10612", name: "Nh\u00e2n L\u00fd", prefix: "X\u00e3" },
          { id: "10613", name: "Quan S\u01a1n", prefix: "X\u00e3" },
          { id: "10614", name: "Quang Lang", prefix: "X\u00e3" },
          {
            id: "10615",
            name: "Th\u01b0\u1ee3ng C\u01b0\u1eddng",
            prefix: "X\u00e3",
          },
          { id: "10616", name: "V\u00e2n An", prefix: "X\u00e3" },
          { id: "10617", name: "V\u1ea1n Linh", prefix: "X\u00e3" },
          { id: "10618", name: "V\u00e2n Th\u1ee7y", prefix: "X\u00e3" },
          { id: "10619", name: "Y T\u1ecbch", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31432", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          { id: "31433", name: "Cai Kinh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31434",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31435",
            name: "\u0110\u1ea1i Hu\u1ec1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31436",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31437",
            name: "Ho\u00e0ng Hoa Th\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31438",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31439", name: "Khu Ga", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31440",
            name: "L\u00ea L\u1ee3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31441",
            name: "Phai Lu\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31442",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31443",
            name: "Qu\u1ed1c l\u1ed9 279",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31444",
            name: "Th\u00e2n C\u1ea3nh Ph\u00fac",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31445",
            name: "Th\u00e2n C\u00f4ng T\u00e0i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31446",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31447",
            name: "Tr\u1ea7n L\u01b0u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31448",
            name: "Tr\u1ea7n Th\u00e1nh T\u00f4ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31449",
            name: "V\u0103n V\u1ec9",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "671",
        name: "\u0110\u00ecnh L\u1eadp",
        wards: [
          { id: "10620", name: "B\u1eafc L\u00e3ng", prefix: "X\u00e3" },
          { id: "10621", name: "B\u1eafc Xa", prefix: "X\u00e3" },
          { id: "10622", name: "B\u00ednh X\u00e1", prefix: "X\u00e3" },
          { id: "10623", name: "Ch\u00e2u S\u01a1n", prefix: "X\u00e3" },
          { id: "10624", name: "C\u01b0\u1eddng L\u1ee3i", prefix: "X\u00e3" },
          { id: "10625", name: "\u0110\u00ecnh L\u1eadp", prefix: "X\u00e3" },
          {
            id: "10626",
            name: "\u0110\u00ecnh L\u1eadp",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10627", name: "\u0110\u1ed3ng Th\u1eafng", prefix: "X\u00e3" },
          { id: "10628", name: "Ki\u00ean M\u1ed9c", prefix: "X\u00e3" },
          { id: "10629", name: "L\u00e2m Ca", prefix: "X\u00e3" },
          {
            id: "10630",
            name: "N\u00f4ng tr\u01b0\u1eddng Th\u00e1i B\u00ecnh",
            prefix: "X\u00e3",
          },
          { id: "10631", name: "Th\u00e1i B\u00ecnh", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "672",
        name: "H\u1eefu L\u0169ng",
        wards: [
          { id: "10632", name: "Cai Kinh", prefix: "X\u00e3" },
          {
            id: "10633",
            name: "\u0110\u00f4 L\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          { id: "10634", name: "\u0110\u1ed3ng T\u00e2n", prefix: "X\u00e3" },
          { id: "10635", name: "\u0110\u1ed3ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "10636", name: "H\u1ed3 S\u01a1n", prefix: "X\u00e3" },
          { id: "10637", name: "H\u00f2a B\u00ecnh", prefix: "X\u00e3" },
          { id: "10638", name: "H\u00f2a L\u1ea1c", prefix: "X\u00e3" },
          { id: "10639", name: "H\u00f2a S\u01a1n", prefix: "X\u00e3" },
          { id: "10640", name: "H\u00f2a Th\u1eafng", prefix: "X\u00e3" },
          { id: "10641", name: "H\u1eefu Li\u00ean", prefix: "X\u00e3" },
          {
            id: "10642",
            name: "H\u1eefu L\u0169ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10643", name: "Minh H\u00f2a", prefix: "X\u00e3" },
          { id: "10644", name: "Minh S\u01a1n", prefix: "X\u00e3" },
          { id: "10645", name: "Minh Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "10646", name: "Nh\u1eadt Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "10647", name: "Quy\u1ebft Th\u1eafng", prefix: "X\u00e3" },
          { id: "10648", name: "S\u01a1n H\u00e0", prefix: "X\u00e3" },
          { id: "10649", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "10650", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          { id: "10651", name: "Thanh S\u01a1n", prefix: "X\u00e3" },
          { id: "10652", name: "Thi\u1ec7n Ky", prefix: "X\u00e3" },
          { id: "10653", name: "V\u00e2n Nham", prefix: "X\u00e3" },
          { id: "10654", name: "Y\u00ean B\u00ecnh", prefix: "X\u00e3" },
          { id: "10655", name: "Y\u00ean S\u01a1n", prefix: "X\u00e3" },
          {
            id: "10656",
            name: "Y\u00ean Th\u1ecbnh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10657", name: "Y\u00ean V\u01b0\u1ee3ng", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "31450",
            name: "Li\u00ean X\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31451",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31452",
            name: "T\u1ec9nh l\u1ed9 242",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "673",
        name: "L\u1ea1ng S\u01a1n",
        wards: [
          { id: "10658", name: "Chi L\u0103ng", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "10659",
            name: "\u0110\u00f4ng Kinh",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "10660", name: "Ho\u00e0ng \u0110\u1ed3ng", prefix: "X\u00e3" },
          {
            id: "10661",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "10662", name: "Mai Pha", prefix: "X\u00e3" },
          { id: "10663", name: "N\u00e0 Poong", prefix: "X\u00e3" },
          { id: "10664", name: "N\u00e0 Th\u00f3", prefix: "X\u00e3" },
          { id: "10665", name: "P\u00f2 \u0110\u1ee9a", prefix: "X\u00e3" },
          { id: "10666", name: "Qu\u1ea3ng L\u1ea1c", prefix: "X\u00e3" },
          { id: "10667", name: "Tam Thanh", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "10668",
            name: "V\u0129nh Tr\u1ea1i",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "31453", name: "4B", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31454", name: "B\u00e0 Tri\u1ec7u", prefix: "Ph\u1ed1" },
          {
            id: "31455",
            name: "B\u1eafc S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31456",
            name: "B\u1ebfn B\u1eafc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31457",
            name: "B\u1ebfn B\u1ea1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31458",
            name: "B\u00f4ng Lau",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31459",
            name: "B\u00f9i Th\u1ecb Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31460",
            name: "Chu V\u0103n An",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31461", name: "\u0110\u1eb7ng Dung", prefix: "Ph\u1ed1" },
          {
            id: "31462",
            name: "\u0110\u1eb7ng V\u0103n Ng\u1eef",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31463",
            name: "\u0110\u00e8o Giang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31464",
            name: "\u0110inh C\u00f4ng Tr\u00e1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31465",
            name: "\u0110inh L\u1ec5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31466",
            name: "\u0110inh Ti\u00ean Ho\u00e0ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31467",
            name: "Ho\u00e0ng Qu\u1ed1c Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31468",
            name: "H\u00f9ng V\u01b0\u01a1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31469",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31470",
            name: "L\u00ea \u0110\u1ea1i H\u00e0nh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31471",
            name: "L\u00ea H\u1ed3ng Phong",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31472",
            name: "L\u00ea H\u1eefu Tr\u00e1c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31473", name: "L\u00ea Lai", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31474", name: "Linh Lang", prefix: "Ph\u1ed1" },
          {
            id: "31475",
            name: "L\u01b0\u01a1ng \u0110\u1ecbnh C\u1ee7a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "31476",
            name: "L\u01b0\u01a1ng Th\u1ebf Vinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31477",
            name: "L\u01b0\u01a1ng V\u0103n Can",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31478",
            name: "L\u01b0\u01a1ng V\u0103n Chi",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31479",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "31480",
            name: "Mai H\u1eafc \u0110\u1ebf",
            prefix: "Ph\u1ed1",
          },
          { id: "31481", name: "Minh Khai", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31482",
            name: "M\u1ef9 S\u01a1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31483",
            name: "Ng\u00f4 Gia T\u1ef1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31484",
            name: "Ng\u00f4 Quy\u1ec1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31485",
            name: "Ng\u00f4 S\u1ef9 Li\u00ean",
            prefix: "Ph\u1ed1",
          },
          {
            id: "31486",
            name: "Ng\u00f4 Th\u00ec S\u1ef9",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31488",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31487",
            name: "Nguy\u1ec5n \u0110\u00ecnh Chi\u1ec3u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31489",
            name: "Nguy\u1ec5n Kh\u1eafc C\u1ea7n",
            prefix: "Ph\u1ed1",
          },
          {
            id: "31490",
            name: "Nguy\u1ec5n Nghi\u1ec5m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31491",
            name: "Nguy\u1ec5n Phi Khanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31492",
            name: "Nguy\u1ec5n V\u0103n Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31493",
            name: "Nh\u1ecb Thanh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31494", name: "Phai V\u1ec7", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31495",
            name: "Phan \u0110\u00ecnh Ph\u00f9ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31496",
            name: "Phan Huy \u00cdch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31497",
            name: "Ph\u1ed1 mu\u1ed1i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31498",
            name: "Ph\u00f9ng Ch\u00ed Ki\u00ean",
            prefix: "Ph\u1ed1",
          },
          { id: "31499", name: "Quang Trung", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31500",
            name: "Qu\u1ed1c l\u1ed9 1A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31501", name: "S\u1ed1 5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31502", name: "Tam Thanh", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31503",
            name: "Th\u00e1c M\u1ea1 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31504",
            name: "Th\u00e1c M\u1ea1 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31505",
            name: "Th\u00e1c M\u1ea1 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31506",
            name: "Th\u00e1c M\u1ea1 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31507",
            name: "Th\u00e1c M\u1ea1 5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31508",
            name: "Th\u00e1c M\u1ea1 6",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31509",
            name: "Th\u00e1c M\u1ea1 7",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31510",
            name: "Th\u00e1c M\u1ea1 8",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31511",
            name: "Th\u00e1c Tr\u00e0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31512",
            name: "Th\u00e1i B\u00ecnh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31513",
            name: "Tinh D\u1ea7u 1",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31514",
            name: "Tinh D\u1ea7u 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31515",
            name: "Tinh D\u1ea7u 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31516",
            name: "Tinh D\u1ea7u 4",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31517",
            name: "T\u1ec9nh l\u1ed9 234",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31518",
            name: "T\u1ec9nh l\u1ed9 235C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31519",
            name: "T\u00f4 Hi\u1ec7u",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31520",
            name: "T\u00f4 Th\u1ecb",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31521",
            name: "Tr\u1ea7n \u0110\u1ea1i Ngh\u0129a",
            prefix: "Ph\u1ed1",
          },
          {
            id: "31522",
            name: "Tr\u1ea7n \u0110\u0103ng Ninh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31523",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31524",
            name: "Tr\u1ea7n Nh\u1eadt Du\u1eadt",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31525",
            name: "Tr\u1ea7n Qu\u1ed1c To\u1ea3n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31526",
            name: "V\u1ea1n L\u00fd",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [
          {
            id: "3065",
            name: "Nam Ho\u00e0ng \u0110\u1ed3ng I",
            lat: "21.877410888672",
            lng: "106.75325775146",
          },
          {
            id: "3066",
            name: "Ph\u00fa L\u1ed9c 4",
            lat: "21.860467910767",
            lng: "106.76401519775",
          },
          {
            id: "3067",
            name: "Ph\u00fa L\u1ed9c I",
            lat: "21.86568069458",
            lng: "106.759765625",
          },
          {
            id: "3068",
            name: "Vincom Shophouse L\u1ea1ng S\u01a1n",
            lat: "21.84824180603",
            lng: "106.75848388672",
          },
        ],
      },
      {
        id: "674",
        name: "L\u1ed9c B\u00ecnh",
        wards: [
          { id: "10669", name: "\u00c1i Qu\u1ed1c", prefix: "X\u00e3" },
          { id: "10670", name: "B\u1eb1ng Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "10671", name: "\u0110\u1ed3ng B\u1ee5c", prefix: "X\u00e3" },
          { id: "10672", name: "\u0110\u00f4ng Quan", prefix: "X\u00e3" },
          { id: "10673", name: "Hi\u1ec7p H\u1ea1", prefix: "X\u00e3" },
          { id: "10674", name: "H\u1eefu Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "10675", name: "H\u1eefu L\u00e2n", prefix: "X\u00e3" },
          { id: "10676", name: "Khu\u1ea5t X\u00e1", prefix: "X\u00e3" },
          {
            id: "10677",
            name: "L\u1ed9c B\u00ecnh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10678", name: "L\u1ee3i B\u00e1c", prefix: "X\u00e3" },
          { id: "10679", name: "L\u1ee5c Th\u00f4n", prefix: "X\u00e3" },
          { id: "10680", name: "M\u1eabu S\u01a1n", prefix: "X\u00e3" },
          { id: "10681", name: "Minh Ph\u00e1t", prefix: "X\u00e3" },
          {
            id: "10682",
            name: "Na D\u01b0\u01a1ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10683", name: "Nam Quan", prefix: "X\u00e3" },
          { id: "10684", name: "Nh\u01b0 Khu\u00ea", prefix: "X\u00e3" },
          { id: "10685", name: "Nh\u01b0\u1ee3ng B\u1ea1n", prefix: "X\u00e3" },
          { id: "10686", name: "Quan B\u1ea3n", prefix: "X\u00e3" },
          { id: "10687", name: "S\u00e0n Vi\u00ean", prefix: "X\u00e3" },
          { id: "10688", name: "Tam Gia", prefix: "X\u00e3" },
          { id: "10689", name: "T\u0129nh B\u1eafc", prefix: "X\u00e3" },
          { id: "10690", name: "T\u00fa \u0110o\u1ea1n", prefix: "X\u00e3" },
          { id: "10691", name: "T\u00fa M\u1ecbch", prefix: "X\u00e3" },
          { id: "10692", name: "V\u00e2n M\u1ed9ng", prefix: "X\u00e3" },
          { id: "10693", name: "Xu\u00e2n D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "10694", name: "Xu\u00e2n L\u1ec5", prefix: "X\u00e3" },
          { id: "10695", name: "Xu\u00e2n M\u00e3n", prefix: "X\u00e3" },
          { id: "10696", name: "Xu\u00e2n T\u00ecnh", prefix: "X\u00e3" },
          { id: "10697", name: "Y\u00ean Kho\u00e1i", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31527", name: "12/1", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31528", name: "19/10", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31529",
            name: "C\u00e1ch M\u1ea1ng Th\u00e1ng T\u00e1m",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31530",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31531",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31532",
            name: "L\u01b0\u01a1ng V\u0103n Tri",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31533",
            name: "Nhi\u1ec7t \u0110i\u1ec7n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31534",
            name: "P\u00f2 M\u1ee5c",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31535",
            name: "Th\u1ed1ng Nh\u1ea5t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31536",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31537",
            name: "Vi \u0110\u1ee9c Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "675",
        name: "Tr\u00e0ng \u0110\u1ecbnh",
        wards: [
          { id: "10698", name: "B\u1eafc \u00c1i", prefix: "X\u00e3" },
          { id: "10699", name: "Cao Minh", prefix: "X\u00e3" },
          { id: "10700", name: "Chi L\u0103ng", prefix: "X\u00e3" },
          { id: "10701", name: "Ch\u00ed Minh", prefix: "X\u00e3" },
          {
            id: "10702",
            name: "\u0110\u1ea1i \u0110\u1ed3ng",
            prefix: "X\u00e3",
          },
          { id: "10703", name: "\u0110\u00e0o Vi\u00ean", prefix: "X\u00e3" },
          { id: "10704", name: "\u0110\u1ec1 Th\u00e1m", prefix: "X\u00e3" },
          { id: "10705", name: "\u0110o\u00e0n K\u1ebft", prefix: "X\u00e3" },
          { id: "10706", name: "\u0110\u1ed9i C\u1ea5n", prefix: "X\u00e3" },
          { id: "10707", name: "H\u00f9ng S\u01a1n", prefix: "X\u00e3" },
          { id: "10708", name: "H\u00f9ng Vi\u1ec7t", prefix: "X\u00e3" },
          { id: "10709", name: "Kh\u00e1ng Chi\u1ebfn", prefix: "X\u00e3" },
          { id: "10710", name: "Kh\u00e1nh Long", prefix: "X\u00e3" },
          { id: "10711", name: "Kim \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "10712", name: "Qu\u1ed1c Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "10713", name: "Qu\u1ed1c Vi\u1ec7t", prefix: "X\u00e3" },
          { id: "10714", name: "T\u00e2n Minh", prefix: "X\u00e3" },
          { id: "10715", name: "T\u00e2n Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "10716", name: "T\u00e2n Y\u00ean", prefix: "X\u00e3" },
          {
            id: "10717",
            name: "Th\u1ea5t Kh\u00ea",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10718", name: "Tri Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "10719", name: "Trung Th\u00e0nh", prefix: "X\u00e3" },
          { id: "10720", name: "V\u0129nh Ti\u1ebfn", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31538", name: "4B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31539",
            name: "Qu\u1ed1c l\u1ed9 3B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31540",
            name: "Qu\u1ed1c l\u1ed9 4A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31541",
            name: "T\u1ec9nh l\u1ed9 226",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "676",
        name: "V\u0103n L\u00e3ng",
        wards: [
          { id: "10721", name: "An H\u00f9ng", prefix: "X\u00e3" },
          { id: "10722", name: "B\u1eafc La", prefix: "X\u00e3" },
          { id: "10723", name: "Gia Mi\u1ec5n", prefix: "X\u00e3" },
          {
            id: "10724",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "X\u00e3",
          },
          { id: "10725", name: "Ho\u00e0ng Vi\u1ec7t", prefix: "X\u00e3" },
          { id: "10726", name: "H\u1ed9i Hoan", prefix: "X\u00e3" },
          { id: "10727", name: "H\u1ed3ng Th\u00e1i", prefix: "X\u00e3" },
          { id: "10728", name: "Na S\u1ea7m", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "10729", name: "Nam La", prefix: "X\u00e3" },
          { id: "10730", name: "Nh\u1ea1c K\u1ef3", prefix: "X\u00e3" },
          { id: "10731", name: "T\u00e2n Lang", prefix: "X\u00e3" },
          { id: "10732", name: "T\u00e2n M\u1ef9", prefix: "X\u00e3" },
          { id: "10733", name: "T\u00e2n T\u00e1c", prefix: "X\u00e3" },
          { id: "10734", name: "T\u00e2n Thanh", prefix: "X\u00e3" },
          { id: "10735", name: "T\u00e2n Vi\u1ec7t", prefix: "X\u00e3" },
          { id: "10736", name: "Th\u00e0nh Ho\u00e0", prefix: "X\u00e3" },
          { id: "10737", name: "Thanh Long", prefix: "X\u00e3" },
          { id: "10738", name: "Thu\u1ef5 H\u00f9ng", prefix: "X\u00e3" },
          { id: "10739", name: "Tr\u00f9ng Kh\u00e1nh", prefix: "X\u00e3" },
          { id: "10740", name: "Tr\u00f9ng Qu\u00e1n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31542", name: "13/10", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31543",
            name: "B\u1ea3n T\u00edch",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31544",
            name: "H\u00e0ng D\u00e3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31545",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31546", name: "Khu Ga", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31547",
            name: "L\u01b0\u01a1ng V\u0103n Tri",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31548",
            name: "Qu\u1ed1c l\u1ed9 4A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31549",
            name: "T\u1ec9nh l\u1ed9 231",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "677",
        name: "V\u0103n Quan",
        wards: [
          { id: "10741", name: "B\u00ecnh Ph\u00fac", prefix: "X\u00e3" },
          { id: "10742", name: "Chu T\u00fac", prefix: "X\u00e3" },
          { id: "10743", name: "\u0110\u1ea1i An", prefix: "X\u00e3" },
          { id: "10744", name: "\u0110\u1ed3ng Gi\u00e1p", prefix: "X\u00e3" },
          { id: "10745", name: "Gia Mi\u1ec5n", prefix: "X\u00e3" },
          { id: "10746", name: "H\u00f2a B\u00ecnh", prefix: "X\u00e3" },
          {
            id: "10747",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "X\u00e3",
          },
          { id: "10748", name: "H\u1eefu L\u1ec5", prefix: "X\u00e3" },
          { id: "10749", name: "Kh\u00e1nh Kh\u00ea", prefix: "X\u00e3" },
          { id: "10750", name: "L\u01b0\u01a1ng N\u0103ng", prefix: "X\u00e3" },
          { id: "10751", name: "Ph\u00fa M\u1ef9", prefix: "X\u00e3" },
          { id: "10752", name: "Song Giang", prefix: "X\u00e3" },
          { id: "10753", name: "T\u00e2n \u0110o\u00e0n", prefix: "X\u00e3" },
          { id: "10754", name: "T\u00e2n T\u00e1c", prefix: "X\u00e3" },
          { id: "10755", name: "Tr\u1ea5n Ninh", prefix: "X\u00e3" },
          { id: "10756", name: "Tr\u00e0ng C\u00e1c", prefix: "X\u00e3" },
          { id: "10757", name: "Tr\u00e0ng Ph\u00e1i", prefix: "X\u00e3" },
          { id: "10758", name: "Tr\u00e0ng S\u01a1n", prefix: "X\u00e3" },
          { id: "10759", name: "Tri L\u1ec5", prefix: "X\u00e3" },
          { id: "10760", name: "T\u00fa Xuy\u00ean", prefix: "X\u00e3" },
          { id: "10761", name: "V\u0103n An", prefix: "X\u00e3" },
          { id: "10762", name: "V\u00e2n M\u1ed9ng", prefix: "X\u00e3" },
          { id: "10763", name: "V\u0103n Quan", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "10764", name: "Vi\u1ec7t Y\u00ean", prefix: "X\u00e3" },
          { id: "10765", name: "V\u0129nh Lai", prefix: "X\u00e3" },
          { id: "10766", name: "Xu\u00e2n Mai", prefix: "X\u00e3" },
          { id: "10767", name: "Y\u00ean Ph\u00fac", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "31550",
            name: "Qu\u1ed1c l\u1ed9 1B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31551",
            name: "T\u1ec9nh l\u1ed9 235C",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "61",
    code: "HG",
    name: "H\u00e0 Giang",
    districts: [
      {
        id: "678",
        name: "B\u1eafc M\u00ea",
        wards: [
          {
            id: "10768",
            name: "\u0110\u01b0\u1eddng \u00c2m",
            prefix: "X\u00e3",
          },
          {
            id: "10769",
            name: "\u0110\u01b0\u1eddng H\u1ed3ng",
            prefix: "X\u00e3",
          },
          { id: "10770", name: "Gi\u00e1p Trung", prefix: "X\u00e3" },
          { id: "10771", name: "L\u1ea1c N\u00f4ng", prefix: "X\u00e3" },
          { id: "10772", name: "Minh Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "10773", name: "Minh S\u01a1n", prefix: "X\u00e3" },
          { id: "10774", name: "Phi\u00eang Lu\u00f4ng", prefix: "X\u00e3" },
          { id: "10775", name: "Ph\u00fa Nam", prefix: "X\u00e3" },
          { id: "10776", name: "Th\u01b0\u1ee3ng T\u00e2n", prefix: "X\u00e3" },
          { id: "10777", name: "Y\u00ean C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "10778", name: "Y\u00ean \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "10779", name: "Y\u00ean Phong", prefix: "X\u00e3" },
          {
            id: "10780",
            name: "Y\u00ean Ph\u00fa",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [{ id: "31552", name: "34", prefix: "\u0110\u01b0\u1eddng" }],
        projects: [],
      },
      {
        id: "679",
        name: "B\u1eafc Quang",
        wards: [
          { id: "10781", name: "B\u1eb1ng H\u00e0nh", prefix: "X\u00e3" },
          { id: "10782", name: "\u0110\u1ed3ng T\u00e2m", prefix: "X\u00e3" },
          { id: "10783", name: "\u0110\u00f4ng Th\u00e0nh", prefix: "X\u00e3" },
          { id: "10784", name: "\u0110\u1ed3ng Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "10785", name: "\u0110\u1ed3ng Y\u00ean", prefix: "X\u00e3" },
          { id: "10786", name: "\u0110\u1ee9c Xu\u00e2n", prefix: "X\u00e3" },
          { id: "10787", name: "H\u00f9ng An", prefix: "X\u00e3" },
          { id: "10788", name: "H\u1eefu S\u1ea3n", prefix: "X\u00e3" },
          { id: "10789", name: "Kim Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "10790", name: "Li\u00ean Hi\u1ec7p", prefix: "X\u00e3" },
          { id: "10791", name: "Quang Minh", prefix: "X\u00e3" },
          { id: "10792", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "10793", name: "T\u00e2n Quang", prefix: "X\u00e3" },
          { id: "10794", name: "T\u00e2n Th\u00e0nh", prefix: "X\u00e3" },
          {
            id: "10795",
            name: "Th\u01b0\u1ee3ng B\u00ecnh",
            prefix: "X\u00e3",
          },
          { id: "10796", name: "Ti\u00ean Ki\u1ec1u", prefix: "X\u00e3" },
          { id: "10797", name: "Vi\u1ec7t H\u1ed3ng", prefix: "X\u00e3" },
          {
            id: "10798",
            name: "Vi\u1ec7t Quang",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10799", name: "Vi\u1ec7t Vinh", prefix: "X\u00e3" },
          { id: "10800", name: "V\u0129nh H\u1ea3o", prefix: "X\u00e3" },
          { id: "10801", name: "V\u0129nh Ph\u00fac", prefix: "X\u00e3" },
          { id: "10802", name: "V\u0129nh Tuy", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "10803", name: "V\u00f4 \u0110i\u1ebfm", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "31553",
            name: "Qu\u1ed1c l\u1ed9 279",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31554",
            name: "Qu\u1ed1c l\u1ed9 2C",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "680",
        name: "\u0110\u1ed3ng V\u0103n",
        wards: [
          {
            id: "10804",
            name: "\u0110\u1ed3ng V\u0103n",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          {
            id: "10805",
            name: "H\u1ed1 Qu\u00e1ng Ph\u00ecn",
            prefix: "X\u00e3",
          },
          { id: "10806", name: "L\u0169ng C\u00fa", prefix: "X\u00e3" },
          { id: "10807", name: "L\u0169ng Ph\u00ecn", prefix: "X\u00e3" },
          { id: "10808", name: "L\u0169ng T\u00e1o", prefix: "X\u00e3" },
          { id: "10809", name: "L\u0169ng Th\u1ea7u", prefix: "X\u00e3" },
          { id: "10810", name: "M\u00e1 L\u00e9", prefix: "X\u00e3" },
          {
            id: "10811",
            name: "Ph\u00f3 B\u1ea3ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10812", name: "Ph\u1ed1 C\u00e1o", prefix: "X\u00e3" },
          { id: "10813", name: "Ph\u1ed1 L\u00e0", prefix: "X\u00e3" },
          { id: "10814", name: "S\u00e0 Ph\u00ecn", prefix: "X\u00e3" },
          { id: "10815", name: "S\u1ea3ng T\u1ee7ng", prefix: "X\u00e3" },
          { id: "10816", name: "S\u00ednh L\u1ee7ng", prefix: "X\u00e3" },
          { id: "10817", name: "S\u1ee7ng L\u00e0", prefix: "X\u00e3" },
          { id: "10818", name: "S\u1ee7ng Tr\u00e1i", prefix: "X\u00e3" },
          { id: "10819", name: "T\u1ea3 L\u1ee7ng", prefix: "X\u00e3" },
          { id: "10820", name: "T\u1ea3 Ph\u00ecn", prefix: "X\u00e3" },
          {
            id: "10821",
            name: "Th\u00e0i Ph\u00ecn T\u1ee7ng",
            prefix: "X\u00e3",
          },
          { id: "10822", name: "V\u1ea7n Ch\u1ea3i", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31555", name: "182", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31556",
            name: "Qu\u1ed1c l\u1ed9 4C",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "681",
        name: "H\u00e0 Giang",
        wards: [
          { id: "10823", name: "Minh Khai", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "10824",
            name: "Ng\u1ecdc \u0110\u01b0\u1eddng",
            prefix: "X\u00e3",
          },
          {
            id: "10825",
            name: "Ng\u1ecdc H\u00e0",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "10826",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "10827",
            name: "Ph\u01b0\u01a1ng \u0110\u1ed9",
            prefix: "X\u00e3",
          },
          {
            id: "10828",
            name: "Ph\u01b0\u01a1ng Thi\u1ec7n",
            prefix: "X\u00e3",
          },
          { id: "10829", name: "Quang Trung", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "10830",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "Ph\u01b0\u1eddng",
          },
        ],
        streets: [
          { id: "31557", name: "19/5", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31558", name: "20/8", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31559", name: "34", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31560",
            name: "L\u00ea Ho\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31561",
            name: "L\u00fd Th\u01b0\u1eddng Ki\u1ec7t",
            prefix: "Ph\u1ed1",
          },
          {
            id: "31562",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31563",
            name: "Nguy\u1ec5n Du",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31564",
            name: "Nguy\u1ec5n Th\u00e1i H\u1ecdc",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31565",
            name: "Nguy\u1ec5n Tr\u00e3i",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31566",
            name: "Nguy\u1ec5n V\u0103n Linh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31567",
            name: "Qu\u1ed1c l\u1ed9 2C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31568",
            name: "Qu\u1ed1c l\u1ed9 4C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31569",
            name: "Quy\u1ebft Th\u1eafng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31570",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31571",
            name: "Tr\u1ea7n Ph\u00fa",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31572",
            name: "Xu\u00e2n Th\u1ee7y",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "682",
        name: "Ho\u00e0ng Su Ph\u00ec",
        wards: [
          { id: "10831", name: "B\u1ea3n Lu\u1ed1c", prefix: "X\u00e3" },
          { id: "10832", name: "B\u1ea3n M\u00e1y", prefix: "X\u00e3" },
          { id: "10833", name: "B\u1ea3n Nh\u00f9ng", prefix: "X\u00e3" },
          { id: "10834", name: "B\u1ea3n P\u00e9o", prefix: "X\u00e3" },
          { id: "10835", name: "B\u1ea3n Ph\u00f9ng", prefix: "X\u00e3" },
          { id: "10836", name: "Chi\u1ebfn Ph\u1ed1", prefix: "X\u00e3" },
          { id: "10837", name: "\u0110\u1ea3n V\u00e1n", prefix: "X\u00e3" },
          { id: "10838", name: "H\u1ed3 Th\u1ea7u", prefix: "X\u00e3" },
          { id: "10839", name: "N\u1eadm D\u1ecbch", prefix: "X\u00e3" },
          { id: "10840", name: "N\u1eadm Kh\u00f2a", prefix: "X\u00e3" },
          { id: "10841", name: "Nam S\u01a1n", prefix: "X\u00e3" },
          { id: "10842", name: "N\u1eadm Ty", prefix: "X\u00e3" },
          { id: "10843", name: "N\u00e0ng \u0110\u00f4n", prefix: "X\u00e3" },
          {
            id: "10844",
            name: "Ng\u00e0m \u0110\u0103ng V\u00e0i",
            prefix: "X\u00e3",
          },
          { id: "10845", name: "P\u1ed1 L\u1ed3", prefix: "X\u00e3" },
          { id: "10846", name: "P\u1edd Ly Ng\u00e0i", prefix: "X\u00e3" },
          { id: "10847", name: "S\u00e1n X\u1ea3 H\u1ed3", prefix: "X\u00e3" },
          {
            id: "10848",
            name: "T\u1ea3 S\u1eed Cho\u00f3ng",
            prefix: "X\u00e3",
          },
          { id: "10849", name: "T\u00e2n Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "10850", name: "Th\u00e0ng T\u00edn", prefix: "X\u00e3" },
          { id: "10851", name: "Th\u00e8n Chu Ph\u00ecn", prefix: "X\u00e3" },
          { id: "10852", name: "Th\u00f4ng Nguy\u00ean", prefix: "X\u00e3" },
          { id: "10853", name: "T\u1ee5 Nh\u00e2n", prefix: "X\u00e3" },
          { id: "10854", name: "T\u00fang S\u00e1n", prefix: "X\u00e3" },
          { id: "10855", name: "Vinh Quang", prefix: "Th\u1ecb tr\u1ea5n" },
        ],
        streets: [
          {
            id: "31573",
            name: "B\u1eafc Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31574",
            name: "T\u1ec9nh l\u1ed9 178",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "683",
        name: "M\u00e8o V\u1ea1c",
        wards: [
          { id: "10856", name: "C\u00e1n Chu Ph\u00ecn", prefix: "X\u00e3" },
          { id: "10857", name: "Gi\u00e0ng Chu Ph\u00ecn", prefix: "X\u00e3" },
          { id: "10858", name: "Kh\u00e2u Vai", prefix: "X\u00e3" },
          { id: "10859", name: "L\u0169ng Chinh", prefix: "X\u00e3" },
          { id: "10860", name: "L\u0169ng P\u00f9", prefix: "X\u00e3" },
          {
            id: "10861",
            name: "M\u00e8o V\u1ea1c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10862", name: "N\u1eadm Ban", prefix: "X\u00e3" },
          { id: "10863", name: "Ni\u00eam S\u01a1n", prefix: "X\u00e3" },
          { id: "10864", name: "Ni\u00eam T\u00f2ng", prefix: "X\u00e3" },
          { id: "10865", name: "P\u1ea3 Vi", prefix: "X\u00e3" },
          { id: "10866", name: "P\u1ea3i L\u1ee7ng", prefix: "X\u00e3" },
          { id: "10867", name: "S\u01a1n V\u0129", prefix: "X\u00e3" },
          { id: "10868", name: "S\u1ee7ng M\u00e1ng", prefix: "X\u00e3" },
          { id: "10869", name: "S\u1ee7ng Tr\u00e0", prefix: "X\u00e3" },
          { id: "10870", name: "T\u1ea3 L\u1ee7ng", prefix: "X\u00e3" },
          { id: "10871", name: "T\u00e1t Ng\u00e0", prefix: "X\u00e3" },
          {
            id: "10872",
            name: "Th\u01b0\u1ee3ng Ph\u00f9ng",
            prefix: "X\u00e3",
          },
          { id: "10873", name: "X\u00edn C\u00e1i", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31575", name: "182", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31576", name: "4B", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31577",
            name: "Qu\u1ed1c l\u1ed9 4C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31578",
            name: "T\u1ec9nh l\u1ed9 217",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "684",
        name: "Qu\u1ea3n B\u1ea1",
        wards: [
          {
            id: "10874",
            name: "B\u00e1t \u0110\u1ea1i S\u01a1n",
            prefix: "X\u00e3",
          },
          { id: "10875", name: "C\u00e1n T\u1ef7", prefix: "X\u00e3" },
          { id: "10876", name: "Cao M\u00e3 P\u1edd", prefix: "X\u00e3" },
          { id: "10877", name: "\u0110\u00f4ng H\u00e0", prefix: "X\u00e3" },
          { id: "10878", name: "L\u00f9ng T\u00e1m", prefix: "X\u00e3" },
          { id: "10879", name: "Ngh\u0129a Thu\u1eadn", prefix: "X\u00e3" },
          { id: "10880", name: "Qu\u1ea3n B\u1ea1", prefix: "X\u00e3" },
          { id: "10881", name: "Quy\u1ebft Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "10882", name: "T\u1ea3 V\u00e1n", prefix: "X\u00e3" },
          { id: "10883", name: "Tam S\u01a1n", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "10884", name: "Th\u00e1i An", prefix: "X\u00e3" },
          { id: "10885", name: "Thanh V\u00e2n", prefix: "X\u00e3" },
          { id: "10886", name: "T\u00f9ng V\u00e0i", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "31579",
            name: "Qu\u1ed1c l\u1ed9 4C",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "685",
        name: "Quang B\u00ecnh",
        wards: [
          { id: "10887", name: "B\u1ea3n R\u1ecba", prefix: "X\u00e3" },
          { id: "10888", name: "B\u1eb1ng Lang", prefix: "X\u00e3" },
          { id: "10889", name: "H\u01b0\u01a1ng S\u01a1n", prefix: "X\u00e3" },
          { id: "10890", name: "N\u00e0 Kh\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "10891", name: "T\u00e2n B\u1eafc", prefix: "X\u00e3" },
          { id: "10892", name: "T\u00e2n Nam", prefix: "X\u00e3" },
          { id: "10893", name: "T\u00e2n Tr\u1ecbnh", prefix: "X\u00e3" },
          { id: "10894", name: "Ti\u00ean Nguy\u00ean", prefix: "X\u00e3" },
          { id: "10895", name: "Ti\u00ean Y\u00ean", prefix: "X\u00e3" },
          { id: "10896", name: "V\u0129 Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "10897", name: "Xu\u00e2n Giang", prefix: "X\u00e3" },
          { id: "10898", name: "Xu\u00e2n Minh", prefix: "X\u00e3" },
          {
            id: "10899",
            name: "Y\u00ean B\u00ecnh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10900", name: "Y\u00ean H\u00e0", prefix: "X\u00e3" },
          { id: "10901", name: "Y\u00ean Th\u00e0nh", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
      {
        id: "686",
        name: "V\u1ecb Xuy\u00ean",
        wards: [
          { id: "10902", name: "B\u1ea1ch Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "10903", name: "Cao B\u1ed3", prefix: "X\u00e3" },
          {
            id: "10904",
            name: "\u0110\u1ea1o \u0110\u1ee9c",
            prefix: "X\u00e3",
          },
          { id: "10905", name: "Kim Linh", prefix: "X\u00e3" },
          { id: "10906", name: "Kim Th\u1ea1ch", prefix: "X\u00e3" },
          { id: "10907", name: "Lao Ch\u1ea3i", prefix: "X\u00e3" },
          { id: "10908", name: "Linh H\u1ed3", prefix: "X\u00e3" },
          { id: "10909", name: "Minh T\u00e2n", prefix: "X\u00e3" },
          { id: "10910", name: "Ng\u1ecdc Linh", prefix: "X\u00e3" },
          { id: "10911", name: "Ng\u1ecdc Minh", prefix: "X\u00e3" },
          {
            id: "10912",
            name: "N\u00f4ng tr\u01b0\u1eddng Vi\u1ec7t L\u00e2m",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10913", name: "Phong Quang", prefix: "X\u00e3" },
          { id: "10914", name: "Ph\u00fa Linh", prefix: "X\u00e3" },
          {
            id: "10915",
            name: "Ph\u01b0\u01a1ng Ti\u1ebfn",
            prefix: "X\u00e3",
          },
          { id: "10916", name: "Qu\u1ea3ng Ng\u1ea7n", prefix: "X\u00e3" },
          { id: "10917", name: "Thanh \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "10918", name: "Thanh Th\u1ee7y", prefix: "X\u00e3" },
          { id: "10919", name: "Thu\u1eadn H\u00f2a", prefix: "X\u00e3" },
          { id: "10920", name: "Th\u01b0\u1ee3ng S\u01a1n", prefix: "X\u00e3" },
          { id: "10921", name: "Trung Th\u00e0nh", prefix: "X\u00e3" },
          { id: "10922", name: "T\u00f9ng B\u00e1", prefix: "X\u00e3" },
          {
            id: "10923",
            name: "V\u1ecb Xuy\u00ean",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10924", name: "Vi\u1ec7t L\u00e2m", prefix: "X\u00e3" },
          { id: "10925", name: "X\u00edn Ch\u1ea3i", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "31580",
            name: "Qu\u1ed1c L\u1ed9 2",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "687",
        name: "X\u00edn M\u1ea7n",
        wards: [
          { id: "10926", name: "B\u1ea3n D\u00edu", prefix: "X\u00e3" },
          { id: "10927", name: "B\u1ea3n Ng\u00f2", prefix: "X\u00e3" },
          { id: "10928", name: "Ch\u1ebf L\u00e0", prefix: "X\u00e3" },
          { id: "10929", name: "Ch\u00ed C\u00e0", prefix: "X\u00e3" },
          {
            id: "10930",
            name: "C\u1ed1c P\u00e0i",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10931", name: "C\u1ed1c R\u1ebf", prefix: "X\u00e3" },
          { id: "10932", name: "Khu\u00f4n L\u00f9ng", prefix: "X\u00e3" },
          { id: "10933", name: "N\u00e0 Ch\u00ec", prefix: "X\u00e3" },
          { id: "10934", name: "N\u1ea5m D\u1ea9n", prefix: "X\u00e3" },
          { id: "10935", name: "N\u00e0n Ma", prefix: "X\u00e3" },
          { id: "10936", name: "N\u00e0n X\u1ec9n", prefix: "X\u00e3" },
          { id: "10937", name: "Ng\u00e1n Chi\u00ean", prefix: "X\u00e3" },
          { id: "10938", name: "P\u00e0 V\u1ea7y S\u1ee7", prefix: "X\u00e3" },
          { id: "10939", name: "Qu\u1ea3ng Nguy\u00ean", prefix: "X\u00e3" },
          { id: "10940", name: "T\u1ea3 Nh\u00ecu", prefix: "X\u00e3" },
          { id: "10941", name: "Th\u00e8n Ph\u00e0ng", prefix: "X\u00e3" },
          { id: "10942", name: "Thu T\u00e0", prefix: "X\u00e3" },
          { id: "10943", name: "Trung Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "10944", name: "X\u00edn M\u1ea7n", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "31581",
            name: "T\u1ec9nh l\u1ed9 178",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "688",
        name: "Y\u00ean Minh",
        wards: [
          { id: "10945", name: "B\u1ea1ch \u0110\u00edch", prefix: "X\u00e3" },
          { id: "10947", name: "Du Gi\u00e0", prefix: "X\u00e3" },
          { id: "10948", name: "Du Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "10946", name: "\u0110\u00f4ng Minh", prefix: "X\u00e3" },
          {
            id: "10949",
            name: "\u0110\u01b0\u1eddng Th\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
          { id: "10950", name: "H\u1eefu Vinh", prefix: "X\u00e3" },
          { id: "10951", name: "Lao V\u00e0 Ch\u1ea3i", prefix: "X\u00e3" },
          { id: "10952", name: "L\u0169ng H\u1ed3", prefix: "X\u00e3" },
          { id: "10953", name: "M\u1eadu Du\u1ec7", prefix: "X\u00e3" },
          { id: "10954", name: "M\u1eadu Long", prefix: "X\u00e3" },
          { id: "10955", name: "Na Kh\u00ea", prefix: "X\u00e3" },
          { id: "10956", name: "Ngam La", prefix: "X\u00e3" },
          { id: "10957", name: "Ng\u1ecdc Long", prefix: "X\u00e3" },
          { id: "10958", name: "Ph\u00fa L\u0169ng", prefix: "X\u00e3" },
          { id: "10959", name: "S\u1ee7ng Th\u00e0i", prefix: "X\u00e3" },
          { id: "10960", name: "S\u1ee7ng Tr\u00e1ng", prefix: "X\u00e3" },
          { id: "10961", name: "Th\u1eafng M\u1ed1", prefix: "X\u00e3" },
          { id: "10962", name: "Y\u00ean Minh", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "31582",
            name: "Qu\u1ed1c l\u1ed9 4C",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31583",
            name: "Tr\u1ea7n H\u01b0ng \u0110\u1ea1o",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
  {
    id: "62",
    code: "BK",
    name: "B\u1eafc K\u1ea1n",
    districts: [
      {
        id: "689",
        name: "Ba B\u1ec3",
        wards: [
          { id: "10963", name: "B\u00e0nh Tr\u1ea1ch", prefix: "X\u00e3" },
          { id: "10964", name: "Cao Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
          { id: "10965", name: "Cao Tr\u0129", prefix: "X\u00e3" },
          {
            id: "10966",
            name: "Ch\u1ee3 R\u00e3",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10967", name: "Chu H\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "10968", name: "\u0110\u1ecba Linh", prefix: "X\u00e3" },
          { id: "10969", name: "\u0110\u1ed3ng Ph\u00fac", prefix: "X\u00e3" },
          { id: "10970", name: "H\u00e0 Hi\u1ec7u", prefix: "X\u00e3" },
          { id: "10971", name: "Ho\u00e0ng Tr\u0129", prefix: "X\u00e3" },
          { id: "10972", name: "Khang Ninh", prefix: "X\u00e3" },
          { id: "10973", name: "M\u1ef9 Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "10974", name: "Nam M\u1eabu", prefix: "X\u00e3" },
          { id: "10975", name: "Ph\u00fac L\u1ed9c", prefix: "X\u00e3" },
          { id: "10976", name: "Qu\u1ea3ng Kh\u00ea", prefix: "X\u00e3" },
          {
            id: "10977",
            name: "Th\u01b0\u1ee3ng Gi\u00e1o",
            prefix: "X\u00e3",
          },
          { id: "10978", name: "Y\u1ebfn D\u01b0\u01a1ng", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "31584",
            name: "T\u1ec9nh l\u1ed9 254",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "690",
        name: "B\u1eafc K\u1ea1n",
        wards: [
          {
            id: "10979",
            name: "D\u01b0\u00ac\u01a1ng Quang",
            prefix: "X\u00e3",
          },
          {
            id: "10980",
            name: "\u0110\u1ee9c Xu\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "10981", name: "Huy\u1ec1n T\u1ee5ng", prefix: "X\u00e3" },
          {
            id: "10982",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "10983",
            name: "N\u00f4ng Th\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
          {
            id: "10984",
            name: "Ph\u00f9ng Ch\u00ed Ki\u00ean",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "10985",
            name: "S\u00f4ng C\u1ea7u",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "10986", name: "Xu\u1ea5t H\u00f3a", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31585", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31586",
            name: "B\u1ea3n \u00c1ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31587",
            name: "B\u00e0n V\u0103n Hoan",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31588",
            name: "\u0110\u1ed9i K\u1ef3 c\u0169",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31589",
            name: "Ho\u00e0ng Tr\u01b0\u1eddng Minh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          { id: "31590", name: "Kon Tum", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31591",
            name: "Nguy\u1ec5n Th\u1ecb Minh Khai",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31592",
            name: "Nguy\u1ec5n V\u0103n Tho\u00e1t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31593",
            name: "N\u00f4ng Qu\u1ed1c Ch\u1ea5n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31594",
            name: "N\u00f4ng V\u0103n Quang",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31595",
            name: "Ph\u00f9ng Ch\u00ed Ki\u00ean",
            prefix: "Ph\u1ed1",
          },
          {
            id: "31596",
            name: "Qu\u1ed1c l\u1ed9 3B",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31597",
            name: "T\u1ec9nh L\u1ed9 257",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31598",
            name: "T\u1ec9nh l\u1ed9 267",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31599",
            name: "Tr\u01b0\u1eddng Chinh",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31600",
            name: "V\u00f5 Nguy\u00ean Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "691",
        name: "B\u1ea1ch Th\u00f4ng",
        wards: [
          { id: "10987", name: "C\u1ea9m Gi\u00e0ng", prefix: "X\u00e3" },
          { id: "10988", name: "Cao S\u01a1n", prefix: "X\u00e3" },
          { id: "10990", name: "D\u01b0\u01a1ng Phong", prefix: "X\u00e3" },
          { id: "10989", name: "\u0110\u00f4n Phong", prefix: "X\u00e3" },
          { id: "10991", name: "H\u00e0 V\u1ecb", prefix: "X\u00e3" },
          { id: "10992", name: "L\u1ee5c B\u00ecnh", prefix: "X\u00e3" },
          { id: "10993", name: "M\u1ef9 Thanh", prefix: "X\u00e3" },
          { id: "10994", name: "Nguy\u00ean Ph\u00fac", prefix: "X\u00e3" },
          {
            id: "10995",
            name: "Ph\u1ee7 Th\u00f4ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "10996", name: "Ph\u01b0\u01a1ng Linh", prefix: "X\u00e3" },
          { id: "10997", name: "Qu\u00e2n B\u00ecnh", prefix: "X\u00e3" },
          { id: "10998", name: "Quang Thu\u1eadn", prefix: "X\u00e3" },
          { id: "10999", name: "S\u0129 B\u00ecnh", prefix: "X\u00e3" },
          { id: "11000", name: "T\u00e2n Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "11001", name: "T\u00fa Tr\u0129", prefix: "X\u00e3" },
          { id: "11002", name: "Vi H\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "11003", name: "V\u0169 Mu\u1ed9n", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31601", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31602",
            name: "Qu\u1ed1c l\u1ed9 3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31603",
            name: "T\u1ec9nh l\u1ed9 258",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "692",
        name: "Ch\u1ee3 \u0110\u1ed3n",
        wards: [
          { id: "11004", name: "B\u1ea3n Thi", prefix: "X\u00e3" },
          { id: "11005", name: "B\u1eb1ng L\u00e3ng", prefix: "X\u00e3" },
          {
            id: "11006",
            name: "B\u1eb1ng L\u0169ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "11007", name: "B\u1eb1ng Ph\u00fac", prefix: "X\u00e3" },
          { id: "11008", name: "B\u00ecnh Trung", prefix: "X\u00e3" },
          { id: "11009", name: "\u0110\u1ea1i S\u1ea3o", prefix: "X\u00e3" },
          { id: "11010", name: "\u0110\u1ed3ng L\u1ea1c", prefix: "X\u00e3" },
          { id: "11011", name: "\u0110\u00f4ng Vi\u00ean", prefix: "X\u00e3" },
          { id: "11012", name: "L\u01b0\u01a1ng B\u1eb1ng", prefix: "X\u00e3" },
          { id: "11013", name: "Nam C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "11014", name: "Ngh\u0129a T\u00e1", prefix: "X\u00e3" },
          { id: "11015", name: "Ng\u1ecdc Ph\u00e1i", prefix: "X\u00e3" },
          { id: "11016", name: "Phong Hu\u00e2n", prefix: "X\u00e3" },
          {
            id: "11017",
            name: "Ph\u01b0\u01a1ng Vi\u00ean",
            prefix: "X\u00e3",
          },
          { id: "11018", name: "Qu\u1ea3ng B\u1ea1ch", prefix: "X\u00e3" },
          { id: "11019", name: "R\u00e3 B\u1ea3n", prefix: "X\u00e3" },
          { id: "11020", name: "T\u00e2n L\u1eadp", prefix: "X\u00e3" },
          { id: "11021", name: "Xu\u00e2n L\u1ea1c", prefix: "X\u00e3" },
          { id: "11022", name: "Y\u00ean M\u1ef9", prefix: "X\u00e3" },
          { id: "11023", name: "Y\u00ean Nhu\u1eadn", prefix: "X\u00e3" },
          { id: "11024", name: "Y\u00ean Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "11025", name: "Y\u00ean Th\u01b0\u1ee3ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31604", name: "257", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31605",
            name: "T\u1ec9nh l\u1ed9 254",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "693",
        name: "Ch\u1ee3 M\u1edbi",
        wards: [
          { id: "11026", name: "B\u00ecnh V\u0103n", prefix: "X\u00e3" },
          { id: "11027", name: "Cao K\u1ef3", prefix: "X\u00e3" },
          {
            id: "11028",
            name: "Ch\u1ee3 M\u1edbi",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "11029", name: "H\u00f2a M\u1ee5c", prefix: "X\u00e3" },
          { id: "11030", name: "Mai L\u1ea1p", prefix: "X\u00e3" },
          { id: "11031", name: "Nh\u01b0 C\u1ed1", prefix: "X\u00e3" },
          { id: "11032", name: "N\u00f4ng H\u1ea1", prefix: "X\u00e3" },
          { id: "11033", name: "N\u00f4ng Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "11034", name: "Qu\u1ea3ng Chu", prefix: "X\u00e3" },
          { id: "11035", name: "T\u00e2n S\u01a1n", prefix: "X\u00e3" },
          { id: "11036", name: "Thanh B\u00ecnh", prefix: "X\u00e3" },
          { id: "11037", name: "Thanh Mai", prefix: "X\u00e3" },
          { id: "11038", name: "Thanh V\u1eadn", prefix: "X\u00e3" },
          { id: "11039", name: "Y\u00ean C\u01b0", prefix: "X\u00e3" },
          { id: "11040", name: "Y\u00ean \u0110\u0129nh", prefix: "X\u00e3" },
          { id: "11041", name: "Y\u00ean H\u00e2n", prefix: "X\u00e3" },
        ],
        streets: [{ id: "31606", name: "3", prefix: "\u0110\u01b0\u1eddng" }],
        projects: [],
      },
      {
        id: "694",
        name: "Na R\u00ec",
        wards: [
          { id: "11042", name: "\u00c2n T\u00ecnh", prefix: "X\u00e3" },
          { id: "11043", name: "C\u00f4n Minh", prefix: "X\u00e3" },
          { id: "11044", name: "C\u01b0 L\u1ec5", prefix: "X\u00e3" },
          { id: "11045", name: "C\u01b0\u1eddng L\u1ee3i", prefix: "X\u00e3" },
          { id: "11047", name: "D\u01b0\u01a1ng S\u01a1n", prefix: "X\u00e3" },
          { id: "11046", name: "\u0110\u1ed5ng X\u00e1", prefix: "X\u00e3" },
          { id: "11048", name: "H\u1ea3o Ngh\u0129a", prefix: "X\u00e3" },
          { id: "11049", name: "H\u1eefu Th\u00e1c", prefix: "X\u00e3" },
          { id: "11050", name: "Kim H\u1ef7", prefix: "X\u00e3" },
          { id: "11051", name: "Kim L\u01b0", prefix: "X\u00e3" },
          { id: "11052", name: "Lam S\u01a1n", prefix: "X\u00e3" },
          { id: "11053", name: "L\u1ea1ng San", prefix: "Th\u1ecb tr\u1ea5n" },
          { id: "11054", name: "Li\u00eam Th\u1ee7y", prefix: "X\u00e3" },
          { id: "11055", name: "L\u01b0\u01a1ng H\u1ea1", prefix: "X\u00e3" },
          {
            id: "11056",
            name: "L\u01b0\u01a1ng Th\u00e0nh",
            prefix: "X\u00e3",
          },
          {
            id: "11057",
            name: "L\u01b0\u01a1ng Th\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
          { id: "11058", name: "Quang Phong", prefix: "X\u00e3" },
          { id: "11059", name: "V\u0103n H\u1ecdc", prefix: "X\u00e3" },
          { id: "11060", name: "V\u0103n Minh", prefix: "X\u00e3" },
          { id: "11061", name: "V\u0169 Loan", prefix: "X\u00e3" },
          { id: "11062", name: "Xu\u00e2n D\u01b0\u01a1ng", prefix: "X\u00e3" },
          {
            id: "11063",
            name: "Y\u1ebfn L\u1ea1c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
        ],
        streets: [
          {
            id: "31607",
            name: "Qu\u1ed1c l\u1ed9 3B",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "695",
        name: "Ng\u00e2n S\u01a1n",
        wards: [
          { id: "11064", name: "B\u1eb1ng V\u00e2n", prefix: "X\u00e3" },
          { id: "11065", name: "C\u1ed1c \u0110\u00e1n", prefix: "X\u00e3" },
          { id: "11066", name: "\u0110\u1ee9c V\u00e2n", prefix: "X\u00e3" },
          { id: "11067", name: "H\u01b0\u01a1ng N\u00ea", prefix: "X\u00e3" },
          { id: "11068", name: "L\u00e3ng Ng\u00e2m", prefix: "X\u00e3" },
          {
            id: "11069",
            name: "N\u00e0 Ph\u1eb7c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "11070", name: "Thu\u1ea7n Mang", prefix: "X\u00e3" },
          { id: "11071", name: "Th\u01b0\u1ee3ng \u00c2n", prefix: "X\u00e3" },
          { id: "11072", name: "Th\u01b0\u1ee3ng Quan", prefix: "X\u00e3" },
          { id: "11073", name: "Trung H\u00f2a", prefix: "X\u00e3" },
          { id: "11074", name: "V\u00e2n T\u00f9ng", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31608", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31609",
            name: "Qu\u1ed1c l\u1ed9 279",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "696",
        name: "P\u00e1c N\u1eb7m",
        wards: [
          { id: "11075", name: "An Th\u1eafng", prefix: "X\u00e3" },
          { id: "11076", name: "B\u1eb1ng Th\u00e0nh", prefix: "X\u00e3" },
          { id: "11077", name: "B\u1ed9c B\u1ed1", prefix: "X\u00e3" },
          { id: "11078", name: "Cao T\u00e2n", prefix: "X\u00e3" },
          { id: "11079", name: "C\u1ed5 Linh", prefix: "X\u00e3" },
          { id: "11080", name: "C\u00f4ng B\u1eb1ng", prefix: "X\u00e3" },
          { id: "11081", name: "Gi\u00e1o Hi\u1ec7u", prefix: "X\u00e3" },
          { id: "11082", name: "Nghi\u00ean Loan", prefix: "X\u00e3" },
          { id: "11083", name: "Nh\u1ea1n M\u00f4n", prefix: "X\u00e3" },
          { id: "11084", name: "Xu\u00e2n La", prefix: "X\u00e3" },
        ],
        streets: [],
        projects: [],
      },
    ],
  },
  {
    id: "63",
    code: "CB",
    name: "Cao B\u1eb1ng",
    districts: [
      {
        id: "697",
        name: "B\u1ea3o L\u1ea1c",
        wards: [
          {
            id: "11085",
            name: "B\u1ea3o L\u1ea1c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "11086", name: "B\u1ea3o To\u00e0n", prefix: "X\u00e3" },
          { id: "11087", name: "C\u00f4 Ba", prefix: "X\u00e3" },
          { id: "11088", name: "C\u1ed1c P\u00e0ng", prefix: "X\u00e3" },
          { id: "11089", name: "\u0110\u00ecnh Ph\u00f9ng", prefix: "X\u00e3" },
          { id: "11090", name: "H\u1ed3ng An", prefix: "X\u00e3" },
          { id: "11091", name: "H\u1ed3ng Tr\u1ecb", prefix: "X\u00e3" },
          { id: "11092", name: "H\u01b0ng \u0110\u1ea1o", prefix: "X\u00e3" },
          { id: "11093", name: "H\u01b0ng Th\u1ecbnh", prefix: "X\u00e3" },
          { id: "11094", name: "Huy Gi\u00e1p", prefix: "X\u00e3" },
          { id: "11095", name: "Kh\u00e1nh Xu\u00e2n", prefix: "X\u00e3" },
          { id: "11096", name: "Kim C\u00fac", prefix: "X\u00e3" },
          { id: "11097", name: "Phan Thanh", prefix: "X\u00e3" },
          { id: "11098", name: "S\u01a1n L\u1eadp", prefix: "X\u00e3" },
          { id: "11099", name: "S\u01a1n L\u1ed9", prefix: "X\u00e3" },
          { id: "11100", name: "Th\u01b0\u1ee3ng H\u00e0", prefix: "X\u00e3" },
          {
            id: "11101",
            name: "Xu\u00e2n Tr\u01b0\u1eddng",
            prefix: "X\u00e3",
          },
        ],
        streets: [
          { id: "31610", name: "34", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31611",
            name: "T\u1ec9nh l\u1ed9 217",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "698",
        name: "B\u1ea3o L\u00e2m",
        wards: [
          { id: "11102", name: "\u0110\u1ee9c H\u1ea1nh", prefix: "X\u00e3" },
          { id: "11103", name: "L\u00fd B\u00f4n", prefix: "X\u00e3" },
          { id: "11104", name: "M\u00f4ng \u00c2n", prefix: "X\u00e3" },
          { id: "11105", name: "Nam Cao", prefix: "X\u00e3" },
          { id: "11106", name: "Nam Quang", prefix: "X\u00e3" },
          {
            id: "11107",
            name: "P\u00e1c Mi\u1ea7u",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "11108", name: "Qu\u1ea3ng L\u00e2m", prefix: "X\u00e3" },
          { id: "11109", name: "Th\u1ea1ch L\u00e2m", prefix: "X\u00e3" },
          { id: "11110", name: "Th\u00e1i H\u1ecdc", prefix: "X\u00e3" },
          { id: "11111", name: "Th\u00e1i S\u01a1n", prefix: "X\u00e3" },
          { id: "11112", name: "V\u0129nh Phong", prefix: "X\u00e3" },
          { id: "11113", name: "V\u0129nh Quang", prefix: "X\u00e3" },
          { id: "11114", name: "Y\u00ean Th\u1ed5", prefix: "X\u00e3" },
        ],
        streets: [{ id: "31612", name: "34", prefix: "\u0110\u01b0\u1eddng" }],
        projects: [],
      },
      {
        id: "699",
        name: "Cao B\u1eb1ng",
        wards: [
          { id: "11115", name: "Chu Trinh", prefix: "X\u00e3" },
          { id: "11117", name: "Duy\u1ec7t Trung", prefix: "Ph\u01b0\u1eddng" },
          {
            id: "11116",
            name: "\u0110\u1ec1 Th\u00e1m",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "11118", name: "H\u00f2a Chung", prefix: "Ph\u01b0\u1eddng" },
          { id: "11119", name: "H\u1ee3p Giang", prefix: "Ph\u01b0\u1eddng" },
          { id: "11120", name: "H\u01b0ng \u0110\u1ea1o", prefix: "X\u00e3" },
          {
            id: "11121",
            name: "Ng\u1ecdc Xu\u00e2n",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "11122",
            name: "S\u00f4ng B\u1eb1ng",
            prefix: "Ph\u01b0\u1eddng",
          },
          {
            id: "11123",
            name: "S\u00f4ng Hi\u1ebfn",
            prefix: "Ph\u01b0\u1eddng",
          },
          { id: "11124", name: "T\u00e2n Giang", prefix: "Ph\u01b0\u1eddng" },
          { id: "11125", name: "V\u0129nh Quang", prefix: "X\u00e3" },
        ],
        streets: [
          { id: "31613", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31614", name: "3/10", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31615",
            name: "B\u1ebf V\u0103n \u0110\u00e0n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31616",
            name: "\u0110\u00f4ng Kh\u00ea",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31617",
            name: "\u0110\u01b0\u1eddng H\u1ed3ng Vi\u1ec7t",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31618",
            name: "Ho\u00e0ng B\u00ecnh Gi\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31619",
            name: "Ho\u00e0ng Nh\u01b0",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31620",
            name: "Ho\u00e0ng V\u0103n Th\u1ee5",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31621",
            name: "Kim \u0110\u1ed3ng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31622",
            name: "L\u00fd T\u1ef1 Tr\u1ecdng",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31623",
            name: "Ng\u1ecdc Xu\u00e2n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31624",
            name: "N\u01b0\u1edbc Gi\u00e1p",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31625",
            name: "P\u00e1c P\u00f3",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31626",
            name: "Phay Kh\u1eaft N\u00e0 Ng\u1eb1n",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31627",
            name: "Qu\u1ed1c l\u1ed9 37",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31628",
            name: "Qu\u1ed1c l\u1ed9 4A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31629",
            name: "Xu\u00e2n Tr\u01b0\u1eddng",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "700",
        name: "H\u1ea1 Lang",
        wards: [
          { id: "11126", name: "An L\u1ea1c", prefix: "X\u00e3" },
          { id: "11127", name: "C\u00f4 Ng\u00e2n", prefix: "X\u00e3" },
          { id: "11128", name: "\u0110\u1ed3ng Loan", prefix: "X\u00e3" },
          { id: "11129", name: "\u0110\u1ee9c Quang", prefix: "X\u00e3" },
          { id: "11130", name: "Kim Loan", prefix: "X\u00e3" },
          { id: "11131", name: "L\u00fd Qu\u1ed1c", prefix: "X\u00e3" },
          { id: "11132", name: "Minh Long", prefix: "X\u00e3" },
          { id: "11133", name: "Quang Long", prefix: "X\u00e3" },
          { id: "11134", name: "T\u00e2n Vi\u1ec7t", prefix: "X\u00e3" },
          { id: "11135", name: "Th\u00e1i \u0110\u1ee9c", prefix: "X\u00e3" },
          { id: "11136", name: "Th\u1eafng L\u1ee3i", prefix: "X\u00e3" },
          {
            id: "11137",
            name: "Thanh Nh\u1eadt",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "11138", name: "Th\u1ecb Hoa", prefix: "X\u00e3" },
          { id: "11139", name: "Vi\u1ec7t Chu", prefix: "X\u00e3" },
          { id: "11140", name: "Vinh Qu\u00fd", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "31630",
            name: "T\u1ec9nh l\u1ed9 207",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "701",
        name: "H\u00e0 Qu\u1ea3ng",
        wards: [
          { id: "11141", name: "C\u1ea3i Vi\u00ean", prefix: "X\u00e3" },
          { id: "11142", name: "\u0110\u00e0o Ng\u1ea1n", prefix: "X\u00e3" },
          { id: "11143", name: "H\u1ea1 Th\u00f4n", prefix: "X\u00e3" },
          { id: "11144", name: "H\u1ed3ng S\u0129", prefix: "X\u00e3" },
          { id: "11145", name: "K\u00e9o Y\u00ean", prefix: "X\u00e3" },
          { id: "11146", name: "L\u0169ng N\u1eb7m", prefix: "X\u00e3" },
          { id: "11147", name: "M\u00e3 Ba", prefix: "X\u00e3" },
          { id: "11148", name: "N\u00e0 S\u00e1c", prefix: "X\u00e3" },
          { id: "11149", name: "N\u1ed9i Th\u00f4n", prefix: "X\u00e3" },
          { id: "11150", name: "Ph\u00f9 Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "11151", name: "Qu\u00fd Qu\u00e2n", prefix: "X\u00e3" },
          { id: "11152", name: "S\u0129 Hai", prefix: "X\u00e3" },
          { id: "11153", name: "S\u00f3c H\u00e0", prefix: "X\u00e3" },
          {
            id: "11154",
            name: "Th\u01b0\u1ee3ng Th\u00f4n",
            prefix: "X\u00e3",
          },
          { id: "11155", name: "T\u1ed5ng C\u1ecdt", prefix: "X\u00e3" },
          { id: "11156", name: "Tr\u01b0\u1eddng H\u00e0", prefix: "X\u00e3" },
          { id: "11157", name: "V\u00e2n An", prefix: "X\u00e3" },
          { id: "11158", name: "V\u1ea7n D\u00ednh", prefix: "X\u00e3" },
          { id: "11159", name: "Xu\u00e2n Ho\u00e0", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "31631",
            name: "T\u1ec9nh l\u1ed9 203",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31632",
            name: "T\u1ec9nh l\u1ed9 210",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "702",
        name: "H\u00f2a An",
        wards: [
          { id: "11160", name: "B\u1ea1ch \u0110\u1eb1ng", prefix: "X\u00e3" },
          { id: "11161", name: "B\u1ebf Tri\u1ec1u", prefix: "X\u00e3" },
          { id: "11162", name: "B\u00ecnh D\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "11163", name: "B\u00ecnh Long", prefix: "X\u00e3" },
          { id: "11164", name: "C\u00f4ng Tr\u1eebng", prefix: "X\u00e3" },
          { id: "11166", name: "D\u00e2n Ch\u1ee7", prefix: "X\u00e3" },
          { id: "11165", name: "\u0110\u1ea1i Ti\u1ebfn", prefix: "X\u00e3" },
          { id: "11167", name: "\u0110\u1ee9c Long", prefix: "X\u00e3" },
          { id: "11168", name: "\u0110\u1ee9c Xu\u00e2n", prefix: "X\u00e3" },
          { id: "11169", name: "H\u00e0 Tr\u00ec", prefix: "X\u00e3" },
          { id: "11170", name: "Ho\u00e0ng Tung", prefix: "X\u00e3" },
          { id: "11171", name: "H\u1ed3ng Nam", prefix: "X\u00e3" },
          { id: "11172", name: "H\u1ed3ng Vi\u1ec7t", prefix: "X\u00e3" },
          { id: "11173", name: "L\u00ea Chung", prefix: "X\u00e3" },
          { id: "11174", name: "Nam Tu\u1ea5n", prefix: "X\u00e3" },
          { id: "11175", name: "Ng\u0169 L\u00e3o", prefix: "X\u00e3" },
          { id: "11176", name: "Nguy\u1ec5n Hu\u1ec7", prefix: "X\u00e3" },
          {
            id: "11177",
            name: "N\u01b0\u1edbc Hai",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "11178", name: "Quang Trung", prefix: "X\u00e3" },
          {
            id: "11179",
            name: "Tr\u01b0ng V\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
          {
            id: "11180",
            name: "Tr\u01b0\u01a1ng L\u01b0\u01a1ng",
            prefix: "X\u00e3",
          },
        ],
        streets: [
          { id: "31633", name: "3", prefix: "\u0110\u01b0\u1eddng" },
          { id: "31634", name: "34", prefix: "\u0110\u01b0\u1eddng" },
          {
            id: "31635",
            name: "Qu\u1ed1c l\u1ed9 4A",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "703",
        name: "Nguy\u00ean B\u00ecnh",
        wards: [
          { id: "11181", name: "B\u1eafc H\u1ee3p", prefix: "X\u00e3" },
          { id: "11182", name: "Ca Th\u00e0nh", prefix: "X\u00e3" },
          { id: "11183", name: "Hoa Th\u00e1m", prefix: "X\u00e3" },
          { id: "11184", name: "H\u01b0ng \u0110\u1ea1o", prefix: "X\u00e3" },
          { id: "11185", name: "Lang M\u00f4n", prefix: "X\u00e3" },
          { id: "11186", name: "Mai Long", prefix: "X\u00e3" },
          { id: "11187", name: "Minh T\u00e2m", prefix: "X\u00e3" },
          { id: "11188", name: "Minh Thanh", prefix: "X\u00e3" },
          {
            id: "11189",
            name: "Nguy\u00ean B\u00ecnh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "11190", name: "Phan Thanh", prefix: "X\u00e3" },
          { id: "11191", name: "Quang Th\u00e0nh", prefix: "X\u00e3" },
          { id: "11192", name: "Tam Kim", prefix: "X\u00e3" },
          { id: "11193", name: "Th\u00e1i H\u1ecdc", prefix: "X\u00e3" },
          { id: "11194", name: "Th\u00e0nh C\u00f4ng", prefix: "X\u00e3" },
          { id: "11195", name: "Th\u1ec3 D\u1ee5c", prefix: "X\u00e3" },
          {
            id: "11196",
            name: "Th\u1ecbnh V\u01b0\u1ee3ng",
            prefix: "X\u00e3",
          },
          {
            id: "11197",
            name: "T\u0129nh T\u00fac",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "11198", name: "Tri\u1ec7u Nguy\u00ean", prefix: "X\u00e3" },
          { id: "11199", name: "V\u0169 N\u00f4ng", prefix: "X\u00e3" },
          { id: "11200", name: "Y\u00ean L\u1ea1c", prefix: "X\u00e3" },
        ],
        streets: [{ id: "31636", name: "34", prefix: "\u0110\u01b0\u1eddng" }],
        projects: [],
      },
      {
        id: "704",
        name: "Ph\u1ee5c H\u00f2a",
        wards: [
          { id: "11201", name: "C\u00e1ch Linh", prefix: "X\u00e3" },
          { id: "11202", name: "\u0110\u1ea1i S\u01a1n", prefix: "X\u00e3" },
          {
            id: "11203",
            name: "H\u00f2a Thu\u1eadn",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "11204", name: "H\u1ed3ng \u0110\u1ea1i", prefix: "X\u00e3" },
          {
            id: "11205",
            name: "L\u01b0\u01a1ng Thi\u1ec7n",
            prefix: "X\u00e3",
          },
          { id: "11206", name: "M\u1ef9 H\u01b0ng", prefix: "X\u00e3" },
          {
            id: "11207",
            name: "T\u00e0 L\u00f9ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "11208", name: "Ti\u00ean Th\u00e0nh", prefix: "X\u00e3" },
          { id: "11209", name: "Tri\u1ec7u \u1ea8u", prefix: "X\u00e3" },
        ],
        streets: [{ id: "31637", name: "3", prefix: "\u0110\u01b0\u1eddng" }],
        projects: [],
      },
      {
        id: "705",
        name: "Qu\u1ea3ng Uy\u00ean",
        wards: [
          { id: "11210", name: "B\u00ecnh L\u0103ng", prefix: "X\u00e3" },
          { id: "11211", name: "Cai B\u1ed9", prefix: "X\u00e3" },
          { id: "11212", name: "Ch\u00ed Th\u1ea3o", prefix: "X\u00e3" },
          { id: "11213", name: "\u0110o\u00e0i Kh\u00f4n", prefix: "X\u00e3" },
          { id: "11214", name: "\u0110\u1ed9c L\u1eadp", prefix: "X\u00e3" },
          { id: "11215", name: "H\u1ea1nh Ph\u00fac", prefix: "X\u00e3" },
          { id: "11216", name: "Ho\u00e0ng H\u1ea3i", prefix: "X\u00e3" },
          { id: "11217", name: "H\u1ed3ng \u0110\u1ecbnh", prefix: "X\u00e3" },
          { id: "11218", name: "H\u1ed3ng Quang", prefix: "X\u00e3" },
          { id: "11219", name: "Ng\u1ecdc \u0110\u1ed9ng", prefix: "X\u00e3" },
          { id: "11220", name: "Phi H\u1ea3i", prefix: "X\u00e3" },
          { id: "11221", name: "Ph\u00fac Sen", prefix: "X\u00e3" },
          { id: "11222", name: "Qu\u1ea3ng H\u01b0ng", prefix: "X\u00e3" },
          {
            id: "11223",
            name: "Qu\u1ea3ng Uy\u00ean",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "11224", name: "Qu\u1ed1c D\u00e2n", prefix: "X\u00e3" },
          { id: "11225", name: "Qu\u1ed1c Phong", prefix: "X\u00e3" },
          { id: "11226", name: "T\u1ef1 Do", prefix: "X\u00e3" },
        ],
        streets: [{ id: "31638", name: "3", prefix: "\u0110\u01b0\u1eddng" }],
        projects: [],
      },
      {
        id: "706",
        name: "Th\u1ea1ch An",
        wards: [
          { id: "11227", name: "Canh T\u00e2n", prefix: "X\u00e3" },
          { id: "11228", name: "Danh S\u1ef9", prefix: "X\u00e3" },
          {
            id: "11229",
            name: "\u0110\u00f4ng Kh\u00ea",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "11230", name: "\u0110\u1ee9c Long", prefix: "X\u00e3" },
          { id: "11231", name: "\u0110\u1ee9c Th\u00f4ng", prefix: "X\u00e3" },
          { id: "11232", name: "\u0110\u1ee9c Xu\u00e2n", prefix: "X\u00e3" },
          { id: "11233", name: "Kim \u0110\u1ed3ng", prefix: "X\u00e3" },
          { id: "11234", name: "L\u00ea Lai", prefix: "X\u00e3" },
          { id: "11235", name: "L\u00ea L\u1ee3i", prefix: "X\u00e3" },
          { id: "11236", name: "Minh Khai", prefix: "X\u00e3" },
          { id: "11237", name: "Quang Tr\u1ecdng", prefix: "X\u00e3" },
          { id: "11238", name: "Th\u00e1i C\u01b0\u1eddng", prefix: "X\u00e3" },
          { id: "11239", name: "Th\u1ecb Ng\u00e2n", prefix: "X\u00e3" },
          { id: "11240", name: "Th\u1ee5y H\u00f9ng", prefix: "X\u00e3" },
          { id: "11241", name: "Tr\u1ecdng Con", prefix: "X\u00e3" },
          { id: "11242", name: "V\u00e2n Tr\u00ecnh", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "31639",
            name: "Qu\u1ed1c l\u1ed9 4A",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31640",
            name: "T\u1ec9nh l\u1ed9 208",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "707",
        name: "Th\u00f4ng N\u00f4ng",
        wards: [
          { id: "11243", name: "B\u00ecnh L\u00e3ng", prefix: "X\u00e3" },
          { id: "11244", name: "C\u1ea7n N\u00f4ng", prefix: "X\u00e3" },
          { id: "11245", name: "C\u1ea7n Y\u00ean", prefix: "X\u00e3" },
          { id: "11246", name: "\u0110a Th\u00f4ng", prefix: "X\u00e3" },
          { id: "11247", name: "L\u01b0\u01a1ng Can", prefix: "X\u00e3" },
          {
            id: "11248",
            name: "L\u01b0\u01a1ng Th\u00f4ng",
            prefix: "X\u00e3",
          },
          { id: "11249", name: "Ng\u1ecdc \u0110\u1ed9ng", prefix: "X\u00e3" },
          { id: "11250", name: "Thanh Long", prefix: "X\u00e3" },
          {
            id: "11251",
            name: "Th\u00f4ng N\u00f4ng",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "11252", name: "V\u1ecb Quang", prefix: "X\u00e3" },
          { id: "11253", name: "Y\u00ean S\u01a1n", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "31641",
            name: "T\u1ec9nh l\u1ed9 204",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "708",
        name: "Tr\u00e0 L\u0129nh",
        wards: [
          { id: "11254", name: "Cao Ch\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "11255", name: "C\u00f4 M\u01b0\u1eddi", prefix: "X\u00e3" },
          {
            id: "11256",
            name: "H\u00f9ng Qu\u1ed1c",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "11257", name: "L\u01b0u Ng\u1ecdc", prefix: "X\u00e3" },
          { id: "11258", name: "Quang H\u00e1n", prefix: "X\u00e3" },
          { id: "11259", name: "Quang Trung", prefix: "X\u00e3" },
          { id: "11260", name: "Quang Vinh", prefix: "X\u00e3" },
          { id: "11261", name: "Qu\u1ed1c To\u1ea3n", prefix: "X\u00e3" },
          { id: "11262", name: "Tri Ph\u01b0\u01a1ng", prefix: "X\u00e3" },
          { id: "11263", name: "Xu\u00e2n N\u1ed9i", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "31642",
            name: "T\u1ec9nh l\u1ed9 205",
            prefix: "\u0110\u01b0\u1eddng",
          },
          {
            id: "31643",
            name: "T\u1ec9nh l\u1ed9 211",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
      {
        id: "709",
        name: "Tr\u00f9ng Kh\u00e1nh",
        wards: [
          { id: "11264", name: "C\u1ea3nh Ti\u00ean", prefix: "X\u00e3" },
          { id: "11265", name: "Cao Th\u0103ng", prefix: "X\u00e3" },
          { id: "11266", name: "Ch\u00ed Vi\u1ec5n", prefix: "X\u00e3" },
          { id: "11267", name: "\u0110\u00e0m Th\u1ee7y", prefix: "X\u00e3" },
          { id: "11268", name: "\u0110\u00ecnh Minh", prefix: "X\u00e3" },
          { id: "11269", name: "\u0110\u00ecnh Phong", prefix: "X\u00e3" },
          { id: "11270", name: "\u0110o\u00e0i C\u00f4n", prefix: "X\u00e3" },
          { id: "11271", name: "\u0110\u1ee9c H\u1ed3ng", prefix: "X\u00e3" },
          { id: "11272", name: "Kh\u00e2m Th\u00e0nh", prefix: "X\u00e3" },
          { id: "11273", name: "L\u0103ng Hi\u1ebfu", prefix: "X\u00e3" },
          { id: "11274", name: "L\u0103ng Y\u00ean", prefix: "X\u00e3" },
          { id: "11275", name: "Ng\u1ecdc Chung", prefix: "X\u00e3" },
          { id: "11276", name: "Ng\u1ecdc C\u00f4n", prefix: "X\u00e3" },
          { id: "11277", name: "Ng\u1ecdc Kh\u00ea", prefix: "X\u00e3" },
          { id: "11278", name: "Phong Ch\u00e2u", prefix: "X\u00e3" },
          { id: "11279", name: "Phong N\u1eadm", prefix: "X\u00e3" },
          { id: "11280", name: "Th\u00e2n Gi\u00e1p", prefix: "X\u00e3" },
          { id: "11281", name: "Th\u00f4ng Hu\u1ec1", prefix: "X\u00e3" },
          {
            id: "11282",
            name: "Tr\u00f9ng Kh\u00e1nh",
            prefix: "Th\u1ecb tr\u1ea5n",
          },
          { id: "11283", name: "Trung Ph\u00fac", prefix: "X\u00e3" },
        ],
        streets: [
          {
            id: "31644",
            name: "T\u1ec9nh l\u1ed9 206",
            prefix: "\u0110\u01b0\u1eddng",
          },
        ],
        projects: [],
      },
    ],
  },
];

export default locationVN;
