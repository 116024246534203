import { useTranslation } from "react-i18next";
import ButtonTheme from "./ButtonTheme";
import { Tooltip } from "antd";

const OfflineMode = () => {
  const { t } = useTranslation();
  return (
    <div className="ms-1 header-item d-sm-flex">
      <Tooltip title={t("Offline mode")}>
        <ButtonTheme type="button" className="btn btn-icon rounded-circle light-dark-mode">
          <i className="bx bx-wifi fs-22"></i>
        </ButtonTheme>
      </Tooltip>
    </div>
  );
};
export default OfflineMode;
