import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, UncontrolledCollapse, CardFooter, Button } from "reactstrap";
import SimpleBar from "simplebar-react";
import AvatarAssign from "./AvatarAssign";
import { formatDate } from "../../../../helpers/format_helper";
import { Draggable } from "react-beautiful-dnd";
import "simplebar-react/dist/simplebar.min.css";
import Loader from "../../../../Components/Common/Loader";
import { useTranslation } from "react-i18next";
const ProcessOrderColumn = ({ provided, column, users = [], onChangeUserOrderColumn = () => {}, getDataScroll }) => {
  const { t } = useTranslation();
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setHasMore(column?.hasMore);
  }, [column?.hasMore]);
  return (
    <div ref={provided.innerRef} {...provided.droppableProps}>
      <Card className="mb-2" style={{ minWidth: "250px" }}>
        <CardBody
          style={{
            borderTop: `2px solid #${
              column.title === "PENDING"
                ? "87909e"
                : column.title === "PACKING"
                ? "4466ff"
                : column.title === "READY"
                ? "ee5e99"
                : "008844"
            }`,
            borderRadius: "6px",
            boxShadow: "0 var(--2px) var(--8px) rgba(0, 0, 0, .08)",
          }}
        >
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <h6 className="fs-14 text-uppercase fw-semibold mb-0">
                {t(column.title)}{" "}
                <small className="badge bg-secondary align-bottom ms-1 totaltask-badge">{column.total}</small>
              </h6>
            </div>
            <div className="flex-shrink-0"></div>
          </div>
        </CardBody>
      </Card>
      <div
        className="process-scroll"
        // style={{ height: "calc(100vh - 420px)", overflowX: "hidden", overflowY: "scroll" }}
        id={`scroll-${column?.title}`}
      >
        <InfiniteScroll
          dataLength={column.items.length}
          next={() => {
            getDataScroll(Number(column.page) + 1, column.title);
          }}
          hasMore={hasMore}
          loader={<Loader />}
          scrollableTarget={`scroll-${column?.title}`}
        >
          {column.items.map((item, index) => (
            <ProcessOrderCard
              key={index}
              item={item}
              index={index}
              users={users}
              onChangeUser={(user) => {
                onChangeUserOrderColumn(user, item);
              }}
            />
          ))}
        </InfiniteScroll>
      </div>
      {provided.placeholder}
    </div>
  );
};

const ProcessOrderCard = ({ item, index, users, onChangeUser }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  if (!item) return <></>;
  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <div>
            <Card className={"mb-2"}>
              <CardBody>
                <Link to="#" className="d-flex align-items-center" id={"leadInnerDiscovered" + item.id}>
                  <AvatarAssign
                    idPackage={item.id}
                    users={users}
                    onChangeUser={onChangeUser}
                    userSelect={users.find((user) => user.id === item.packing_staff)}
                  />
                  <div className="flex-grow-1 ms-3">
                    <h6 className="fs-14 mb-1 d-flex align-items-baseline">
                      {t("Id")}:
                      <span
                        className="text-column"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          navigate(`/packages/${item.id}`);
                        }}
                      >
                        {item.id}
                      </span>
                    </h6>
                    <p className="text-muted mb-0">{formatDate(item.updated_at)}</p>
                  </div>
                </Link>
              </CardBody>
              <UncontrolledCollapse
                className="border-top border-top-dashed"
                toggler={"#leadInnerDiscovered" + item.id}
                defaultOpen={false}
              >
                <CardBody>
                  <h6 className="fs-14 mb-1">
                    {t("Tracking number")}:
                    <small className={"badge bg-success-subtle text-success"}>{item?.tracking_number}</small>
                  </h6>
                  <p>
                    {t("Order Id")}: {item.order_id}
                  </p>
                </CardBody>
              </UncontrolledCollapse>
            </Card>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default ProcessOrderColumn;
