import { Actions } from "../../../../Components/constants/common";
import {
  LIST_INVENTORY_ITEM,
  LIST_INVENTORY_ITEM_SUCCESS,
  LIST_INVENTORY_ITEM_FAIL,
  GET_INVENTORY_ITEM,
  GET_INVENTORY_ITEM_SUCCESS,
  GET_INVENTORY_ITEM_FAIL,
  GET_INVENTORY_ITEM_TRANSACTION,
  GET_INVENTORY_ITEM_TRANSACTION_SUCCESS,
  GET_INVENTORY_ITEM_TRANSACTION_FAIL,
} from "./actionType";

const INIT_STATE = {
  inventoryItems: [],
  inventoryItem: [],
  inventoryItemTransaction: [],
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  errorInventoryItem: null,
  successInventoryItem: false,
  loadingInventoryItem: false,
  limitInventoryItem: 0,
  pageInventoryItem: 0,
  totalInventoryItem: 0,
  errorInventoryItemTransaction: null,
  successInventoryItemTransaction: false,
  loadingInventoryItemTransaction: false,
  limitInventoryItemTransaction: 0,
  pageInventoryItemTransaction: 0,
  totalInventoryItemTransaction: 0,
  action: Actions.EMPTY,
};

const InventoryItems = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_INVENTORY_ITEM:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_INVENTORY_ITEM_SUCCESS:
      return {
        ...state,
        message: "List inventory item success",
        loading: false,
        success: true,
        inventoryItems: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_INVENTORY_ITEM_FAIL:
      return {
        ...state,
        message: "List inventory item failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_INVENTORY_ITEM:
      return {
        ...state,
        message: "",
        errorInventoryItem: null,
        successInventoryItem: false,
        loadingInventoryItem: true,
        action: Actions.GET,
      };

    case GET_INVENTORY_ITEM_SUCCESS:
      return {
        ...state,
        message: "Get inventory item success",
        loadingInventoryItem: false,
        successInventoryItem: true,
        inventoryItem: action.payload.items ?? [],
        limitInventoryItem: action.payload.limit ?? 0,
        pageInventoryItem: action.payload.page ?? 0,
        totalInventoryItem: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case GET_INVENTORY_ITEM_FAIL:
      return {
        ...state,
        message: "Get inventory item failed",
        loadingInventoryItem: false,
        successInventoryItem: false,
        errorInventoryItem: action.payload,
        action: Actions.EMPTY,
      };

    case GET_INVENTORY_ITEM_TRANSACTION:
      return {
        ...state,
        message: "",
        errorInventoryItemTransaction: null,
        successInventoryItemTransaction: false,
        loadingInventoryItemTransaction: true,
        action: Actions.EMPTY,
      };

    case GET_INVENTORY_ITEM_TRANSACTION_SUCCESS:
      return {
        ...state,
        message: "Get inventory item transaction success",
        loadingInventoryItemTransaction: false,
        successInventoryItemTransaction: true,
        inventoryItemTransaction: action.payload.items ?? [],
        limitInventoryItemTransaction: action.payload.limit ?? 0,
        pageInventoryItemTransaction: action.payload.page ?? 0,
        totalInventoryItemTransaction: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case GET_INVENTORY_ITEM_TRANSACTION_FAIL:
      return {
        ...state,
        message: "Get inventory item transaction failed",
        loadingInventoryItemTransaction: false,
        successInventoryItemTransaction: false,
        errorInventoryItemTransaction: action.payload,
        action: Actions.EMPTY,
      };

    default:
      return { ...state };
  }
};

export default InventoryItems;
