import {
  LIST_PRICE_GROUP,
  DELETE_PRICE_GROUP,
  DELETE_PRICE_GROUP_SUCCESS,
  DELETE_PRICE_GROUP_FAIL,
  ADD_NEW_PRICE_GROUP,
  ADD_PRICE_GROUP_SUCCESS,
  ADD_PRICE_GROUP_FAIL,
  UPDATE_PRICE_GROUP,
  UPDATE_PRICE_GROUP_SUCCESS,
  UPDATE_PRICE_GROUP_FAIL,
  LIST_PRICE_GROUP_FAIL,
  LIST_PRICE_GROUP_SUCCESS,
  GET_PRICE_GROUP,
  GET_PRICE_GROUP_FAIL,
  GET_PRICE_GROUP_SUCCESS,
} from "./actionType";

export const listPriceGroup = (options) => ({
  type: LIST_PRICE_GROUP,
  payload: options,
});

export const listPriceGroupFail = (error) => ({
  type: LIST_PRICE_GROUP_FAIL,
  payload: error,
});

export const listPriceGroupSuccess = (priceGroups) => ({
  type: LIST_PRICE_GROUP_SUCCESS,
  payload: priceGroups,
});

export const getPriceGroup = (priceGroupID) => ({
  type: GET_PRICE_GROUP,
  payload: priceGroupID,
});

export const getPriceGroupFail = (error) => ({
  type: GET_PRICE_GROUP_FAIL,
  payload: error,
});

export const getPriceGroupSuccess = (priceGroup) => ({
  type: GET_PRICE_GROUP_SUCCESS,
  payload: priceGroup,
});

export const deletePriceGroup = (priceGroup) => ({
  type: DELETE_PRICE_GROUP,
  payload: priceGroup,
});

export const deletePriceGroupSuccess = (priceGroup) => ({
  type: DELETE_PRICE_GROUP_SUCCESS,
  payload: priceGroup,
});

export const deletePriceGroupFail = (error) => ({
  type: DELETE_PRICE_GROUP_FAIL,
  payload: error,
});

export const updatePriceGroup = (priceGroup) => ({
  type: UPDATE_PRICE_GROUP,
  payload: priceGroup,
});

export const updatePriceGroupSuccess = (priceGroup) => ({
  type: UPDATE_PRICE_GROUP_SUCCESS,
  payload: priceGroup,
});

export const updatePriceGroupFail = (error) => ({
  type: UPDATE_PRICE_GROUP_FAIL,
  payload: error,
});

export const addNewPriceGroup = (priceGroup) => ({
  type: ADD_NEW_PRICE_GROUP,
  payload: priceGroup,
});

export const addPriceGroupSuccess = (priceGroup) => ({
  type: ADD_PRICE_GROUP_SUCCESS,
  payload: priceGroup,
});

export const addPriceGroupFail = (error) => ({
  type: ADD_PRICE_GROUP_FAIL,
  payload: error,
});
