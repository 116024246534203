import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";


// Redux States
import { LIST_LOCATION, DELETE_LOCATION, ADD_NEW_LOCATION, UPDATE_LOCATION, GET_LOCATION } from "./actionType";

import {
  deleteLocationSuccess,
  deleteLocationFail,
  addLocationSuccess,
  addLocationFail,
  updateLocationSuccess,
  updateLocationFail,
  listLocationSuccess,
  listLocationFail,
  getLocationSuccess,
  getLocationFail,
} from "./action";

import { locationsAPI } from "../../../../helpers/resource_helper";

function* listLocation({ payload: { ...res } }) {
  try {
    const response = yield call(locationsAPI.list, { ...res });
    yield put(listLocationSuccess(response));
  } catch (error) {
    yield put(listLocationFail(error));
  }
}

function* getLocation({ payload: id }) {
  try {
    const response = yield call(locationsAPI.get, { id });
    yield put(getLocationSuccess(response));
  } catch (error) {
    yield put(getLocationFail(error));
  }
}

function* deleteLocations({ payload: location }) {
  try {
    const response = yield call(locationsAPI.delete, location);
    yield put(deleteLocationSuccess(location));
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteLocationFail(error));
    toast.error("Location Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewLocation({ payload: location }) {
  try {
    const response = yield call(locationsAPI.create, location);
    yield put(addLocationSuccess(response));
    toast.success("Location Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addLocationFail(error));
    toast.error("Location Added Failed", { autoClose: 3000 });
  }
}

function* onUpdateLocation({ payload: location }) {
  try {
    const response = yield call(locationsAPI.put, location);
    yield put(updateLocationSuccess(response));
    toast.success("Location Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateLocationFail(error));
    toast.error("Location Updated Failed", { autoClose: 3000 });
  }
}

export function* watchGetLocations() {
  yield takeEvery(LIST_LOCATION, listLocation);
}

export function* watchDeleteLocations() {
  yield takeEvery(DELETE_LOCATION, deleteLocations);
}

export function* watchGetLocation() {
  yield takeEvery(GET_LOCATION, getLocation);
}

export function* watchUpdateLocation() {
  yield takeEvery(UPDATE_LOCATION, onUpdateLocation);
}

export function* watchAddNewLocation() {
  yield takeEvery(ADD_NEW_LOCATION, onAddNewLocation);
}

function* locationsSaga() {
  yield all([
    fork(watchGetLocation),
    fork(watchGetLocations),
    fork(watchUpdateLocation),
    fork(watchAddNewLocation),
    fork(watchDeleteLocations),
  ]);
}

export default locationsSaga;
