import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import { convertValidDate, convertValidTime, formatVNDCurrency } from "../../../../helpers/format_helper";

import TransactionModal from "./TransactionModal";
import withRouter from "../../../../Components/Common/withRouter";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import { Actions } from "../../../../Components/constants/common";

import { cancelTransaction, listTransaction } from "../../../../store/actions";
import { useEffect } from "react";
import ActionDropdown from "../../../../Components/Common/ActionDropdown";
import BadgeCustom from "../../../../Components/Common/BadgeCustom";

const TransactionsPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Transaction List")} | OptiWarehouse`;
  const transactionID = location.state?.transactionID || "";
  const dispatch = useDispatch();

  const {
    transactions,
    transactionsLoading,
    transactionsAction,
    transactionsMessage,
    transactionsSuccess,
    transactionsError,
    transactionsLimit,
    transactionsPage,
    transactionsTotal,
  } = useMemoizedSelector((state) => ({
    transactions: state.Transactions.transactions,
    transactionsLoading: state.Transactions.loading,
    transactionsAction: state.Transactions.action,
    transactionsSuccess: state.Transactions.success,
    transactionsError: state.Transactions.error,
    transactionsMessage: state.Transactions.message,
    transactionsLimit: state.Transactions.limit,
    transactionsTotal: state.Transactions.total,
    transactionsPage: state.Transactions.page,
  }));

  const [isExportCSV, setIsExportCSV] = useState(false);
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [selectedTransactionID, setSelectedTransactionID] = useState("");

  const onClickView = (data) => {
    setSelectedTransactionID(data);
    setShowTransactionModal(true);
  };

  const toggleTransactionModal = () => {
    if (!transactionsLoading) {
      setShowTransactionModal(false);
      setSelectedTransactionID("");
    }
  };

  const headers = [
    {
      title: t("User"),
      dataIndex: "user",
      key: "user",
      sortKey: "user.name_staff",
      sorter: true,
      render: (value, data, index) => {
        return <>{value?.name_staff || "---"}</>;
      },
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      sorter: true,
      render: (value, data, index) => {
        return <>{formatVNDCurrency(value)}</>;
      },
    },
    {
      title: t("From account"),
      dataIndex: "from_account",
      key: "from_account",
      render: (value, data, index) => {
        return <>{value?.name || "---"}</>;
      },
    },
    {
      title: t("To account"),
      dataIndex: "to_account",
      key: "to_account",
      render: (value, data, index) => {
        return <>{value?.name || "---"}</>;
      },
    },
    {
      title: t("Payment method"),
      dataIndex: "payment_method",
      key: "payment_method",
      sortKey: "payment_method.name",
      sorter: true,
      render: (value, data, index) => {
        return <>{value?.name || "---"}</>;
      },
    },
    {
      title: t("Voucher"),
      dataIndex: "voucher",
      key: "voucher",
      sortKey: "voucher.name",
      sorter: true,
      render: (value, data, index) => {
        return <>{value?.name || "---"}</>;
      },
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (value, data, index) => {
        return <BadgeCustom status={value} />;
      },
    },
    {
      title: t("Updated at"),
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: true,
    },
    {
      title: t("Created at"),
      dataIndex: "created_at",
      key: "created_at",
      defaultSortOrder: "desc",
      sorter: true,
    },
    {
      title: t("Action"),
      key: "action",
      render: (_, row, index) => {
        return (
          <ActionDropdown
            actions={[
              {
                type: "view",
                onClick: () => {
                  onClickView(row.id);
                },
              },
              {
                type: "cancel",
                onClick: () => {
                  dispatch(cancelTransaction(row.id));
                },
              },
            ]}
          />
        );
      },
    },
  ];

  useEffect(() => {
    transactionID && onClickView(transactionID);
  }, [transactionID]);

  return (
    <Fragment>
      <div className="page-content">
        <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={transactions} />
        <TransactionModal
          isOpen={showTransactionModal}
          toggle={toggleTransactionModal}
          transactionID={selectedTransactionID}
        />
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">{t("Transaction List")}</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex gap-3 flex-wrap">
                        <ButtonTheme
                          type="button"
                          className="btn btn-light"
                          icon="ri-file-text-line"
                          onClick={() => setIsExportCSV(true)}
                        >
                          {t("Export")}
                        </ButtonTheme>
                        <ButtonTheme
                          type="button"
                          className="btn btn-primary"
                          icon="ri-eye-fill text-white"
                          onClick={() => {
                            navigate("/transaction/vouchers");
                          }}
                        >
                          {t("View Voucher List")}
                        </ButtonTheme>
                        <ButtonTheme
                          className="btn btn-primary"
                          id="create-btn"
                          icon="ri-add-line text-white"
                          onClick={() => {
                            setShowTransactionModal(true);
                          }}
                        >
                          {t("Add Transaction")}
                        </ButtonTheme>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <TableContainerCustom
                    filterType={"transactions"}
                    searchPlaceHolder="Search transaction..."
                    listFilter={[
                      {
                        id: "status",
                        type: "selectBox",
                        title: `${t("Status")}`,
                        dataOption: [
                          { id: "COMPLETED", name: "Completed" },
                          { id: "CANCELLED", name: "Cancelled" },
                        ],
                      },
                      {
                        id: "voucher.id",
                        type: "selectBox",
                        title: "Voucher",
                        remote: true,
                        pathUrlLoad: "finance/vouchers",
                      },
                    ]}
                    headers={headers}
                    contents={transactions}
                    limitPage={Number(transactionsLimit)}
                    currentPage={Number(transactionsPage) + 1}
                    totalItems={transactionsTotal}
                    loading={transactionsLoading && transactionsAction === Actions.LIST}
                    showPagination={true}
                    scrollHeight="50vh"
                    onCallData={(dataPayload) => {
                      dispatch(listTransaction(dataPayload));
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};
export default withRouter(TransactionsPage);
