import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";

import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import useMemoizedSelector from "../../../../../Components/Hooks/useMemoizedSelector";
import { Actions } from "../../../../../Components/constants/common";

import { addNewCategory } from "../../../../../store/actions";

const CategoriesModal = ({ isOpen, toggle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { categories, categoriesLoading, categoriesAction, categoriesSuccess, categoriesError, categoriesMessage } =
    useMemoizedSelector((state) => ({
      categories: state.Categories.categories,
      categoriesLoading: state.Categories.loading,
      categoriesAction: state.Categories.action,
      categoriesSuccess: state.Categories.success,
      categoriesMessage: state.Categories.message,
      categoriesError: state.Categories.error,
    }));

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: "",
      parent_category_id: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Category Name"),
    }),
    onSubmit: (values) => {
      const newCate = {
        name: values.name,
        parent_category_id: values.parent_category_id,
      };
      dispatch(addNewCategory(newCate));
    },
  });

  const handleToggle = () => {
    if (!categoriesLoading) {
      toggle();
      validation.resetForm();
    }
  };

  useEffect(() => {
    if (!categoriesLoading && categoriesMessage === "Add category success") {
      handleToggle();
    }
  }, [categoriesLoading]);

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} modalClassName="zoomIn" centered>
      <ModalHeader toggle={handleToggle}>{t("Add New Category")}</ModalHeader>
      <ModalBody>
        <div>
          <div className="d-flex">
            <div className="flex-grow-1 me-3">
              <Label className="form-label">{t("Name")}</Label>
              <Input
                id="new-category"
                className="form-control me-auto"
                type="text"
                name="name"
                placeholder={t("Enter Category Name")}
                value={validation.values.name ?? ""}
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
                invalid={validation.errors.name && validation.touched.name ? true : false}
              />

              {validation.errors.name && validation.touched.name ? (
                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
              ) : null}
            </div>
            <div className="flex-grow-1 me-3">
              <Label className="form-label">{t("Parent")}</Label>
              <Input
                className="form-select me-auto"
                type="select"
                name="parent_category_id"
                value={validation.values.parent_category_id}
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
              >
                <option value="">{t("Select parent category")}</option>
                {categories.map((item, key) => (
                  <option key={key} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Input>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <ButtonTheme type="button" className="btn btn-light" onClick={handleToggle}>
          {t("Close")}
        </ButtonTheme>
        <ButtonTheme
          type="button"
          className="btn btn-primary"
          loading={categoriesLoading && categoriesAction === Actions.CREATE}
          disabled={categoriesLoading && categoriesAction === Actions.CREATE}
          onClick={() => validation.handleSubmit()}
        >
          {t("Add")}
        </ButtonTheme>
      </ModalFooter>
    </Modal>
  );
};

export default CategoriesModal;
