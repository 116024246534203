import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Card, CardBody, Col, Collapse, Row } from "reactstrap";
import SimpleBar from "simplebar-react";

import { formatVNDCurrency } from "../../../../helpers/format_helper";

import ButtonTheme from "../../../../Components/Common/ButtonTheme";

import ImageCustom from "../../../../Components/Common/ImageCustom";
import { useTranslation } from "react-i18next";
import { variantsAPI } from "../../../../helpers/resource_helper";
import { Tooltip } from "antd";
import Loader from "../../../../Components/Common/Loader";
import FilterOffcanvas from "../Modal/FilterOffcanvas";

const OptionsMenu = ({
  toggleCollapse,
  isCollapse,
  addProductToCart,
  refShortcutButtonRightMenu,
  refShortcutButton,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isQuickActionTab, setIsQuickActionTab] = useState(true);
  const [showLargeImage, setShowLargeImage] = useState(false);

  const [showFilterCanvas, setShowFilterCanvas] = useState(false);
  const [filter, setFilter] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [variants, setVariants] = useState([]);
  //large image product state
  const [page1, setPage1] = useState(0);
  const [total1, setTotal1] = useState(0);
  const [variants1, setVariants1] = useState([]);
  const handleChangePage = async (page) => {
    if (page >= 0) {
      try {
        setLoading(true);
        const res = await variantsAPI.list({ limit: 12, page, ...filter });
        setVariants(res.items);
        setPage(res.page);
        setTotal(res.total);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  };
  const handleChangePage1 = async (page) => {
    if (page >= 0) {
      try {
        setLoading(true);
        const res = await variantsAPI.list({ limit: 9, page, ...filter });
        setVariants1(res.items);
        setPage1(res.page);
        setTotal1(res.total);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const Menus = [
    {
      label: "Add service (F9)",
      onClick: () => {
        refShortcutButton.current.onAddService();
      },
    },
    {
      label: "Discount order (F6)",
      onClick: () => {
        refShortcutButtonRightMenu.current.toggleDiscount();
      },
    },
    {
      label: "Promotion (F8)",
      onClick: () => {
        refShortcutButton.current.onTogglePromotion();
      },
    },
    { label: "General setting", onClick: () => {} },
    {
      label: "Change price group",
      onClick: () => {
        refShortcutButton.current.onTogglePriceGroup();
      },
    },
    {
      label: "Customer info",
      onClick: () => {
        refShortcutButton.current.onToggleCustomerInfo();
      },
    },
    {
      label: "Delete all products",
      onClick: () => {
        refShortcutButton.current.onRemoveAllProducts();
      },
    },
    {
      label: "Exchange and return goods",
      onClick: () => {
        refShortcutButton.current.onToggleReturnModal();
      },
    },
    {
      label: "View order list",
      onClick: () => {
        window.open("/orders", "_blank");
      },
    },
    {
      label: "View report",
      onClick: () => {
        refShortcutButton.current.onToggleReport();
      },
    },
    {
      label: "Shift",
      onClick: () => {
        refShortcutButton.current.onToggleShift();
      },
    },
    {
      label: "All actions",
      onClick: () => {
        refShortcutButton.current.onToggleAllAction();
      },
    },
  ];

  const getVariants = async () => {
    try {
      setLoading(true);
      const res = await variantsAPI.list({ limit: 12, ...filter });
      setVariants(res.items);
      setPage(res.page);
      setTotal(res.total);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
      setPage1(0);
      setTotal1(0);
      setVariants1([]);
    }
  };
  const getVariantsWithLargeImage = async () => {
    try {
      setLoading(true);
      const res = await variantsAPI.list({ limit: 9, ...filter });
      setVariants1(res.items);
      setPage1(res.page);
      setTotal1(res.total);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
      setPage(0);
      setTotal(0);
      setVariants([]);
    }
  };

  useEffect(() => {
    if (!isCollapse && !isQuickActionTab) {
      showLargeImage ? getVariantsWithLargeImage() : getVariants();
    } else {
      setPage(0);
      setTotal(0);
      setVariants([]);
      setPage1(0);
      setTotal1(0);
      setVariants1([]);
    }
  }, [isCollapse, isQuickActionTab, showLargeImage, filter]);

  const toggle = (tab) => {
    setIsQuickActionTab(tab === "quick-action");
  };

  return (
    <Card className="flex-shrink-0 d-flex flex-column mb-0">
      <FilterOffcanvas
        open={showFilterCanvas}
        toggleDrawer={() => {
          setShowFilterCanvas(!showFilterCanvas);
        }}
        filter={filter}
        onChangeFilter={(data) => {
          setFilter(data);
        }}
        title={"Filter"}
        t={t}
      />
      <ButtonTheme
        type="button"
        onClick={toggleCollapse}
        style={{ backgroundColor: "var(--vz-card-bg)" }}
        className="position-absolute top-0 start-50 translate-middle btn btn-icon rounded-circle light-dark-mode border-light"
      >
        {!isCollapse ? <i className="mdi mdi-chevron-down fs-22"></i> : <i className="mdi mdi-chevron-up fs-22"></i>}
      </ButtonTheme>
      <div className="hstack justify-content-between p-3">
        <div className="hstack gap-3">
          <ButtonTheme
            className={`btn btn-light ${isQuickActionTab && "border-primary text-primary"}`}
            onClick={() => toggle("quick-action")}
          >
            {t("Quick action")}
          </ButtonTheme>
          <ButtonTheme
            className={`btn btn-light ${!isQuickActionTab && "border-primary text-primary"}`}
            onClick={() => toggle("product-list")}
          >
            {t("Product List")}
          </ButtonTheme>
        </div>
        {!isQuickActionTab && (
          <div className="hstack gap-2">
            <ButtonTheme
              className={`btn btn-icon border-0 ${Object.keys(filter).length > 0 ? "btn-light" : ""}`}
              onClick={() => {
                setShowFilterCanvas(true);
              }}
            >
              <i className="bx bx-filter-alt fs-24"></i>
            </ButtonTheme>
            <ButtonTheme
              className={`btn btn-icon border-0 ${showLargeImage ? "btn-light" : ""}`}
              onClick={() => {
                setShowLargeImage(!showLargeImage);
              }}
            >
              <i className="bx bx-image fs-24"></i>
            </ButtonTheme>
            <ButtonTheme
              className="btn btn-icon border-0"
              onClick={() => {
                if (showLargeImage) {
                  handleChangePage1(Number(page1) - 1);
                } else {
                  handleChangePage(Number(page) - 1);
                }
              }}
              disabled={Number(showLargeImage ? page1 : page) === 0}
            >
              <i className="ri-arrow-left-fill fs-24"></i>
            </ButtonTheme>
            <ButtonTheme
              className="btn btn-icon border-0"
              onClick={() => {
                if (showLargeImage) {
                  handleChangePage1(Number(page1) + 1);
                } else {
                  handleChangePage(Number(page) + 1);
                }
              }}
              disabled={showLargeImage ? 10 * (Number(page1) + 1) >= total1 : 10 * (Number(page) + 1) >= total}
            >
              <i className="ri-arrow-right-fill fs-24"></i>
            </ButtonTheme>
          </div>
        )}
      </div>
      <Collapse isOpen={!isCollapse} id="collapseWithicon">
        {isQuickActionTab ? (
          <div className="px-3" style={{ height: "150px" }}>
            <Row>
              {Menus.map((item, index) => (
                <Col md={2} key={index} className="mb-3" style={{ height: "57px" }}>
                  <ButtonTheme
                    className="btn btn-light w-100 h-100 px-1"
                    onClick={item.onClick}
                    disabled={!!item?.disabled}
                  >
                    <span className="text-truncate-two-lines">{t(item.label)}</span>
                  </ButtonTheme>
                </Col>
              ))}
            </Row>
          </div>
        ) : (
          <SimpleBar className="px-3 overflow-auto" style={{ height: "150px" }}>
            {loading ? (
              <Loader />
            ) : showLargeImage ? (
              <div className="hstack flex-wrap gap-2">
                {variants1.map((item, index) => {
                  const available = item.inventories
                    ? item.inventories.reduce((total, item) => total + item.available, 0) || 0
                    : 0;
                  const on_hand = item.inventories
                    ? item.inventories.reduce((total, item) => total + item.on_hand, 0) || 0
                    : 0;
                  return (
                    <div key={index} className="fs-11">
                      <Tooltip title={item.name}>
                        <CardLargeImageItem
                          item={item}
                          available={available}
                          addProductToCart={addProductToCart}
                          t={t}
                        />
                      </Tooltip>
                    </div>
                  );
                })}
              </div>
            ) : (
              <Row className="d-flex">
                {variants.map((item, index) => {
                  const available = item.inventories
                    ? item.inventories.reduce((total, item) => total + item.available, 0) || 0
                    : 0;
                  const on_hand = item.inventories
                    ? item.inventories.reduce((total, item) => total + item.on_hand, 0) || 0
                    : 0;
                  return (
                    <Col xxl={2} xl={4} sm={6} xs={12} key={index} className="fs-11 mb-2">
                      <CardItem item={item} available={available} addProductToCart={addProductToCart} t={t} />
                    </Col>
                  );
                })}
              </Row>
            )}
          </SimpleBar>
        )}
      </Collapse>
    </Card>
  );
};

const CardItem = ({ item, available, addProductToCart, t }) => {
  return (
    <Tooltip title={item.name}>
      <Card className="cursor-pointer mb-0 bg-light h-100" onClick={() => addProductToCart(item)}>
        <CardBody className="hstack gap-2 p-2">
          <div className="flex-shrink-0">
            <ImageCustom image={item?.images?.[0]?.url} name={item?.name} avatarSize={"avatar-xs"} isPreview={false} />
          </div>
          <div className="flex-grow-1">
            <p className="mb-0 text-truncate-two-lines">{item.name}</p>
            <div className="d-flex justify-content-between">
              <span>{formatVNDCurrency(item.prices?.[0]?.price)}</span>
              <span>
                {t("Quantity")}: {available}
              </span>
            </div>
          </div>
        </CardBody>
      </Card>
    </Tooltip>
  );
};
const CardLargeImageItem = ({ item, available, addProductToCart, t }) => {
  return (
    <Tooltip title={item.name}>
      <Card className="cursor-pointer border shadow-none mb-0" onClick={() => addProductToCart(item)}>
        <ImageCustom
          image={item?.images?.[0]?.url}
          name={item?.name}
          style={{ height: "148px", width: "148px", ojectFit: "contain" }}
          isPreview={false}
        />
        <div className="position-absolute bottom-0">
          <div className="bottom-0 end-0 opacity-50 position-absolute start-0 top-0 bg-primary"></div>
          <div className="row justify-content-center text-white px-2">
            <p className="mb-0 text-truncate-two-lines">{item.name}</p>
            <div className="d-flex justify-content-between">
              <span>{formatVNDCurrency(item.prices?.[0]?.price)}</span>
              <span>
                {t("Quantity")}: {available}
              </span>
            </div>
          </div>
        </div>
      </Card>
    </Tooltip>
  );
};
export default OptionsMenu;
