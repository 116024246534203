import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { ordersAPI } from "../../../../helpers/resource_helper";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ImageCustom from "../../../../Components/Common/ImageCustom";
import { formatVNDCurrency } from "../../../../helpers/format_helper";
import BadgeCustom from "../../../../Components/Common/BadgeCustom";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import NotFound from "../../../../Components/Common/NotFound";
import Loader from "../../../../Components/Common/Loader";

const RecentOrders = ({ loading, recentOrders }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">{t("Recent Orders")}</h4>
          <div className="flex-shrink-0">
            <ButtonTheme
              type="button"
              className="btn btn-soft-info btn-sm"
              onClick={() => {
                navigate("/orders");
              }}
              icon="ri-file-list-3-line"
            >
              {t("View All")}
            </ButtonTheme>
          </div>
        </CardHeader>

        <CardBody>
          <div className="table-responsive table-card">
            <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
              <thead className="text-muted table-light">
                <tr>
                  <th scope="col">{t("Order Number")}</th>
                  <th scope="col">{t("Location name")}</th>
                  <th scope="col">{t("Customer")}</th>
                  <th scope="col">{t("Amount")}</th>
                  <th scope="col">{t("Status")}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={1000}>
                      <Loader />
                    </td>
                  </tr>
                ) : recentOrders.length === 0 ? (
                  <tr>
                    <td colSpan={1000}>
                      <NotFound />
                    </td>
                  </tr>
                ) : (
                  recentOrders.map((item, key) => {
                    const customer_name = `${item.customer.first_name ? item.customer.first_name + " " : ""}${
                      item.customer.last_name ? item.customer.last_name : ""
                    }`;
                    return (
                      <tr key={key}>
                        <td>
                          {item?.number ? (
                            <Link to={`/orders/${item.id}`} className="fw-medium">
                              {item?.number}
                            </Link>
                          ) : (
                            "---"
                          )}
                        </td>
                        <td>{item?.order_line_items?.[0]?.location?.name || "---"}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0 me-2">
                              <ImageCustom
                                image={item.customer.image_url}
                                name={customer_name}
                                rounded={"rounded-circle"}
                                avatarSize={"avatar-xs "}
                              />
                            </div>
                            <div className="flex-grow-1">{customer_name || "---"}</div>
                          </div>
                        </td>
                        <td>
                          <span>{formatVNDCurrency(item?.total || 0)}</span>
                        </td>
                        <td>
                          <BadgeCustom status={item.status} />
                        </td>
                        <td>
                          <ButtonTheme
                            type="button"
                            className="btn btn-icon btn-soft-info"
                            onClick={() => {
                              navigate(`/orders/${item.id}`);
                            }}
                          >
                            <i className="ri-eye-fill"></i>
                          </ButtonTheme>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default RecentOrders;
