import { Fragment, useEffect, useImperativeHandle, useState } from "react";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import useMemoizedSelector from "../../../../../Components/Hooks/useMemoizedSelector";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import ReactSelect from "react-select";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";

const LocationSelectForm = ({ selectedLocation, handleChangeLocation, refBtn }) => {
  // const { locations, locationsLoading, locationsSuccess, locationsError } = useMemoizedSelector((state) => ({
  //   locations: state.Locations.locations,
  //   locationsLoading: state.Locations.loading,
  //   locationsSuccess: state.Locations.success,
  //   locationsError: state.Locations.error,
  // }));
  const { t } = useTranslation();
  // const [isOpen, setIsOpen] = useState(false);
  // const [location, setLocation] = useState(selectedLocation || null);

  // useImperativeHandle(refBtn, () => ({
  //   onToggleOnLocation: () => setIsOpen(true),
  // }));

  // useEffect(() => {
  //   isOpen && setLocation(selectedLocation);
  // }, [isOpen]);
  // const toggle = () => {
  //   setIsOpen(false);
  // };
  return (
    <Fragment>
      {/* <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t("Select location")}</ModalHeader>
        <ModalBody>
          <ReactSelect
            id="location-options"
            classNamePrefix="select"
            value={location}
            onChange={(e) => {
              setLocation(e);
            }}
            options={locations.map((item) => {
              return { label: item.name, value: item.id };
            })}
          />
        </ModalBody>
        <ModalFooter>
          <Button className="btn w-sm btn-light" onClick={toggle}>
            {t("Close")}
          </Button>
          <Button
            className="btn btn-primary w-sm"
            onClick={() => {
              handleChangeLocation(location);
              setIsOpen(false);
            }}
          >
            {t("Save")}
          </Button>
        </ModalFooter>
      </Modal> */}
      {/* <ButtonTheme type="button" onClick={() => setIsOpen(true)} className="btn btn-icon h-50 w-100"> */}
      <Tooltip title={selectedLocation?.label}>
        <div className="hstack h-50 w-100 text-white">
          <span className="d-flex align-items-center justify-content-center">
            <i className="ri-map-pin-line me-1"></i>
            <span
              className="fs-10 text-truncate d-block"
              style={{
                maxWidth: "80px",
              }}
            >
              {selectedLocation?.label || "---"}
            </span>
          </span>
        </div>
      </Tooltip>

      {/* </ButtonTheme> */}
    </Fragment>
  );
};
export default LocationSelectForm;
