// Actions
export const LIST_CUSTOMER_GROUP = "LIST_CUSTOMER_GROUP";
export const LIST_CUSTOMER_GROUP_SUCCESS = "LIST_CUSTOMER_GROUP_SUCCESS";
export const LIST_CUSTOMER_GROUP_FAIL = "LIST_CUSTOMER_GROUP_FAIL";

export const GET_CUSTOMER_GROUP = "GET_CUSTOMER_GROUP";
export const GET_CUSTOMER_GROUP_SUCCESS = "GET_CUSTOMER_GROUP_SUCCESS";
export const GET_CUSTOMER_GROUP_FAIL = "GET_CUSTOMER_GROUP_FAIL";

/**
 * Delete CUSTOMER_GROUP
 */
export const DELETE_CUSTOMER_GROUP = "DELETE_CUSTOMER_GROUP";
export const DELETE_CUSTOMER_GROUP_SUCCESS = "DELETE_CUSTOMER_GROUP_SUCCESS";
export const DELETE_CUSTOMER_GROUP_FAIL = "DELETE_CUSTOMER_GROUP_FAIL";

/**
 * Add CUSTOMER_GROUP
 */
export const ADD_NEW_CUSTOMER_GROUP = "ADD_NEW_CUSTOMER_GROUP";
export const ADD_CUSTOMER_GROUP_SUCCESS = "ADD_CUSTOMER_GROUP_SUCCESS";
export const ADD_CUSTOMER_GROUP_FAIL = "ADD_CUSTOMER_GROUP_FAIL";

/**
 * Edit CUSTOMER_GROUP
 */
export const UPDATE_CUSTOMER_GROUP = "UPDATE_CUSTOMER_GROUP";
export const UPDATE_CUSTOMER_GROUP_SUCCESS = "UPDATE_CUSTOMER_GROUP_SUCCESS";
export const UPDATE_CUSTOMER_GROUP_FAIL = "UPDATE_CUSTOMER_GROUP_FAIL";

export const RESET_CUSTOMER_GROUP_FLAG = "RESET_CUSTOMER_GROUP_FLAG";

