import { GET_ROLE_PERMISSION, GET_ROLE_PERMISSION_FAIL, GET_ROLE_PERMISSION_SUCCESS } from "./actionTypes";

export const getPermission = (data, onChangeLocation = () => {}) => {
  return {
    type: GET_ROLE_PERMISSION,
    payload: { data, onChangeLocation },
  };
};

export const getPermissionSuccess = (data) => {
  return {
    type: GET_ROLE_PERMISSION_SUCCESS,
    payload: data,
  };
};

export const getPermissionFail = (error) => {
  return {
    type: GET_ROLE_PERMISSION_FAIL,
    payload: error,
  };
};
