import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import LottieIcon from "../../../../Components/Common/LottieIcon";

const CopyOrderModal = ({ isOpen, toggle, orderId = "" }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{t("Copy order")}</ModalHeader>
      <ModalBody className="hstack gap-2">
        <div
          className="border cursor-pointer px-5 rounded text-center"
          onClick={() => {
            toggle();
            window.open(`/orders/new?clone_order_id=${orderId}`, "_blank");
          }}
        >
          <LottieIcon
            style={{ width: "128px", height: "128px" }}
            loop={false}
            src="https://cdn.lordicon.com/xirobkro.json"
          />
          <p className="mb-0 fw-bold">{t("Sell online")}</p>
        </div>

        <div
          className="border cursor-pointer px-5 rounded text-center"
          onClick={() => {
            toggle();
            window.open(`/pos?clone_order_id=${orderId}`, "_blank");
          }}
        >
          <LottieIcon
            style={{ width: "128px", height: "128px" }}
            loop={false}
            src="https://cdn.lordicon.com/taymdfsf.json"
          />
          <p className="mb-0 fw-bold">{t("Sell in store")}</p>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CopyOrderModal;
