import {
  LIST_SALE_CHANNEL,
  LIST_SALE_CHANNEL_FAIL,
  LIST_SALE_CHANNEL_SUCCESS,
  DELETE_SALE_CHANNEL,
  DELETE_SALE_CHANNEL_SUCCESS,
  DELETE_SALE_CHANNEL_FAIL,
  ADD_NEW_SALE_CHANNEL,
  ADD_SALE_CHANNEL_SUCCESS,
  ADD_SALE_CHANNEL_FAIL,
  UPDATE_SALE_CHANNEL,
  UPDATE_SALE_CHANNEL_SUCCESS,
  UPDATE_SALE_CHANNEL_FAIL,
  GET_SALE_CHANNEL,
  GET_SALE_CHANNEL_FAIL,
  GET_SALE_CHANNEL_SUCCESS,
} from "./actionType";

export const listSaleChannel = (options) => ({
  type: LIST_SALE_CHANNEL,
  payload: options,
});

export const listSaleChannelFail = (error) => ({
  type: LIST_SALE_CHANNEL_FAIL,
  payload: error,
});

export const listSaleChannelSuccess = (saleChannels) => ({
  type: LIST_SALE_CHANNEL_SUCCESS,
  payload: saleChannels,
});

export const getSaleChannel = (saleChannelID) => ({
  type: GET_SALE_CHANNEL,
  payload: saleChannelID,
});

export const getSaleChannelFail = (error) => ({
  type: GET_SALE_CHANNEL_FAIL,
  payload: error,
});

export const getSaleChannelSuccess = (saleChannel) => ({
  type: GET_SALE_CHANNEL_SUCCESS,
  payload: saleChannel,
});

export const deleteSaleChannel = (saleChannel) => ({
  type: DELETE_SALE_CHANNEL,
  payload: saleChannel,
});

export const deleteSaleChannelSuccess = (saleChannel) => ({
  type: DELETE_SALE_CHANNEL_SUCCESS,
  payload: saleChannel,
});

export const deleteSaleChannelFail = (error) => ({
  type: DELETE_SALE_CHANNEL_FAIL,
  payload: error,
});

export const updateSaleChannel = (saleChannel) => ({
  type: UPDATE_SALE_CHANNEL,
  payload: saleChannel,
});

export const updateSaleChannelSuccess = (saleChannel) => ({
  type: UPDATE_SALE_CHANNEL_SUCCESS,
  payload: saleChannel,
});

export const updateSaleChannelFail = (error) => ({
  type: UPDATE_SALE_CHANNEL_FAIL,
  payload: error,
});

export const addNewSaleChannel = (saleChannel) => ({
  type: ADD_NEW_SALE_CHANNEL,
  payload: saleChannel,
});

export const addSaleChannelSuccess = (saleChannel) => ({
  type: ADD_SALE_CHANNEL_SUCCESS,
  payload: saleChannel,
});

export const addSaleChannelFail = (error) => ({
  type: ADD_SALE_CHANNEL_FAIL,
  payload: error,
});
