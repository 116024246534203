import { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

import { isEmpty } from "lodash";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import Loader from "../../../../Components/Common/Loader";
import withRouter from "../../../../Components/Common/withRouter";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import AddEditShippingProviderModal from "./AddEditShippingProviderModal";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import ListContainerCustom from "../../../../Components/Common/ListContainerCustom";

import { deleteShippingProvider, listShippingProvider } from "../../../../store/actions";
import { Actions } from "../../../../Components/constants/common";
import { formatDate, formatFromNow } from "../../../../helpers/format_helper";
import ImageCustom from "../../../../Components/Common/ImageCustom";
import LottieIcon from "../../../../Components/Common/LottieIcon";

const ShippingProviderPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Shipping Provider List")} | OptiWarehouse`;
  const dispatch = useDispatch();

  const {
    shippingProviders,
    shippingProvidersLoading,
    shippingProvidersAction,
    shippingProvidersSuccess,
    shippingProvidersError,
    shippingProvidersLimit,
    shippingProvidersPage,
    shippingProvidersTotal,
    shippingProvidersMessage,
  } = useMemoizedSelector((state) => ({
    shippingProviders: state.ShippingProviders.shippingProviders,
    shippingProvidersMessage: state.ShippingProviders.message,
    shippingProvidersAction: state.ShippingProviders.action,
    shippingProvidersLoading: state.ShippingProviders.loading,
    shippingProvidersSuccess: state.ShippingProviders.success,
    shippingProvidersError: state.ShippingProviders.error,
    shippingProvidersLimit: state.ShippingProviders.limit,
    shippingProvidersPage: state.ShippingProviders.page,
    shippingProvidersTotal: state.ShippingProviders.total,
  }));

  const [selectedShippingProvider, setSelectedShippingProvider] = useState(null);

  const [deleteModal, setDeleteModal] = useState(false);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [showEditAddShippingProviderModal, setShowEditAddShippingProviderModal] = useState(false);

  const handleClickEdit = (data) => {
    setShowEditAddShippingProviderModal(true);
    setSelectedShippingProvider(data);
  };
  const handleClickDelete = (data) => {
    setSelectedShippingProvider(data);
    setDeleteModal(true);
  };
  const handleDeleteShippingProvider = () => {
    dispatch(deleteShippingProvider(selectedShippingProvider));
  };

  const toggleEditAddShippingProviderModal = () => {
    if (!shippingProvidersLoading) {
      setShowEditAddShippingProviderModal(false);
      setSelectedShippingProvider(null);
    }
  };

  const onCloseDeleteModal = () => {
    if (!shippingProvidersLoading) {
      setDeleteModal(false);
      setSelectedShippingProvider(null);
    }
  };

  useEffect(() => {
    if (
      !shippingProvidersLoading &&
      !isEmpty(selectedShippingProvider) &&
      deleteModal &&
      shippingProvidersMessage === "Delete shipping provider success"
    ) {
      setSelectedShippingProvider(null);
      setDeleteModal(false);
    }
  }, [shippingProvidersLoading]);

  return (
    <div className="page-content">
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={shippingProviders} />
      <AddEditShippingProviderModal
        isOpen={showEditAddShippingProviderModal}
        toggle={toggleEditAddShippingProviderModal}
        shippingProvider={selectedShippingProvider}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteShippingProvider}
        onCloseClick={onCloseDeleteModal}
        loading={shippingProvidersLoading && shippingProvidersAction === Actions.DELETE}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="purchase-shippingProvider-list">
              <CardHeader>
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("Shipping Provider List")}</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-3 flex-wrap">
                      <ButtonTheme
                        type="button"
                        className="btn btn-light"
                        icon="ri-file-text-line"
                        onClick={() => setIsExportCSV(true)}
                      >
                        {t("Export")}
                      </ButtonTheme>
                      <ButtonTheme
                        className="btn btn-primary"
                        id="create-btn"
                        icon="ri-add-line text-white"
                        onClick={() => {
                          setShowEditAddShippingProviderModal(true);
                        }}
                      >
                        {t("Add Shipping Provider")}
                      </ButtonTheme>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <ListContainerCustom
                  filterType={"shippingProviders"}
                  searchPlaceHolder="Search shipping provider..."
                  listFilter={[]}
                  limitPage={Number(shippingProvidersLimit)}
                  currentPage={Number(shippingProvidersPage) + 1}
                  totalItems={shippingProvidersTotal}
                  loading={shippingProvidersLoading && shippingProvidersAction === Actions.LIST}
                  showPagination={true}
                  scrollHeight="50vh"
                  onCallData={(dataPayload) => {
                    dispatch(listShippingProvider(dataPayload));
                  }}
                >
                  <Row className="mt-4">
                    {shippingProviders.length === 0 ? (
                      !shippingProvidersLoading ? (
                        <div className="py-4 mt-4 text-center">
                          <LottieIcon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            style={{ width: "72px", height: "72px" }}
                          />

                          <h5 className="mt-4">{t("Sorry! No Result Found")}</h5>
                        </div>
                      ) : (
                        <Loader />
                      )
                    ) : (
                      shippingProviders.map((item, key) => (
                        <Fragment key={key}>
                          <Col xl={3} lg={6} className="project-card">
                            <Card className="card-animate bg-light card-height-100">
                              <CardBody>
                                <div className="d-flex flex-column h-100">
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <p className="text-muted mb-4">{formatFromNow(item.updated_at)}</p>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <div className="d-flex gap-1 align-items-center">
                                        <UncontrolledDropdown direction="start">
                                          <DropdownToggle
                                            tag="button"
                                            className="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15"
                                          >
                                            <FeatherIcon icon="more-horizontal" className="icon-sm" />
                                          </DropdownToggle>

                                          <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem
                                              onClick={() => {
                                                handleClickEdit(item);
                                              }}
                                            >
                                              <i className="ri-pencil-fill align-bottom me-2 text-primary"></i>{" "}
                                              {t("Edit")}
                                            </DropdownItem>
                                            <div className="dropdown-divider"></div>
                                            <DropdownItem
                                              onClick={() => {
                                                handleClickDelete(item);
                                              }}
                                            >
                                              <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>{" "}
                                              {t("Delete")}
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex mb-2">
                                    <div className="flex-shrink-0 me-3">
                                      <ImageCustom image={item.image?.url} name={item?.name} avatarSize={"avatar-sm"} />
                                    </div>
                                    <div className="flex-grow-1 hstack">
                                      <h5 className="mb-1 fs-15">
                                        <Link to="#" className="text-dark">
                                          {t(item.name || "---")}
                                        </Link>
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                              <CardFooter className="bg-transparent bshippingProvider-top-dashed py-2">
                                <div className="d-flex align-items-center">
                                  <div className="flex-grow-1"></div>
                                  <div className="flex-shrink-0">
                                    <div className="text-muted">
                                      <i className="ri-calendar-event-fill me-1 align-bottom"></i>{" "}
                                      {formatDate(item.updated_at)}
                                    </div>
                                  </div>
                                </div>
                              </CardFooter>
                            </Card>
                          </Col>
                        </Fragment>
                      ))
                    )}
                  </Row>
                </ListContainerCustom>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(ShippingProviderPage);
