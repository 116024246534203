import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";


// Redux States
import {
  LIST_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  ADD_NEW_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD,
  GET_PAYMENT_METHOD,
} from "./actionType";

import {
  deletePaymentMethodSuccess,
  deletePaymentMethodFail,
  addPaymentMethodSuccess,
  addPaymentMethodFail,
  updatePaymentMethodSuccess,
  updatePaymentMethodFail,
  listPaymentMethodSuccess,
  listPaymentMethodFail,
  getPaymentMethodSuccess,
  getPaymentMethodFail,
} from "./action";

import { paymentMethodsAPI } from "../../../../helpers/resource_helper";

function* listPaymentMethod({ payload: { ...rest } }) {
  try {
    const response = yield call(paymentMethodsAPI.list, { ...rest });
    yield put(listPaymentMethodSuccess(response));
  } catch (error) {
    yield put(listPaymentMethodFail(error));
  }
}

function* getPaymentMethod({ payload: id }) {
  try {
    const response = yield call(paymentMethodsAPI.get, { id });
    yield put(getPaymentMethodSuccess(response));
  } catch (error) {
    yield put(getPaymentMethodFail(error));
  }
}

function* deletePaymentMethods({ payload: paymentMethod }) {
  try {
    const response = yield call(paymentMethodsAPI.delete, { id: paymentMethod.id });
    yield put(deletePaymentMethodSuccess(paymentMethod));
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deletePaymentMethodFail(error));
    toast.error("Payment Method Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewPaymentMethod({ payload: { paymentMethod, handleAfterSubmit } }) {
  try {
    const response = yield call(paymentMethodsAPI.create, paymentMethod);
    yield put(addPaymentMethodSuccess(response));
    toast.success("Payment Method Added Successfully", { autoClose: 3000 });
    yield call(handleAfterSubmit);
  } catch (error) {
    yield put(addPaymentMethodFail(error));
    toast.error("Payment Method Added Failed", { autoClose: 3000 });
  }
}

function* onUpdatePaymentMethod({ payload: { paymentMethod, handleAfterSubmit } }) {
  try {
    const response = yield call(paymentMethodsAPI.put, paymentMethod);
    yield put(updatePaymentMethodSuccess(response));
    toast.success("Payment Method Updated Successfully", { autoClose: 3000 });
    yield call(handleAfterSubmit);
  } catch (error) {
    yield put(updatePaymentMethodFail(error));
    toast.error("Payment Method Updated Failed", { autoClose: 3000 });
  }
}

export function* watchListPaymentMethod() {
  yield takeEvery(LIST_PAYMENT_METHOD, listPaymentMethod);
}

export function* watchDeletePaymentMethods() {
  yield takeEvery(DELETE_PAYMENT_METHOD, deletePaymentMethods);
}

export function* watchGetPaymentMethod() {
  yield takeEvery(GET_PAYMENT_METHOD, getPaymentMethod);
}

export function* watchUpdatePaymentMethod() {
  yield takeEvery(UPDATE_PAYMENT_METHOD, onUpdatePaymentMethod);
}

export function* watchAddNewPaymentMethod() {
  yield takeEvery(ADD_NEW_PAYMENT_METHOD, onAddNewPaymentMethod);
}

function* paymentMethodsSaga() {
  yield all([
    fork(watchGetPaymentMethod),
    fork(watchListPaymentMethod),
    fork(watchUpdatePaymentMethod),
    fork(watchAddNewPaymentMethod),
    fork(watchDeletePaymentMethods),
  ]);
}

export default paymentMethodsSaga;
