import { Fragment, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Col, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";

import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { Actions } from "../../../../Components/constants/common";

import { addNewPaymentMethod, updatePaymentMethod } from "../../../../store/actions";
import InputTheme from "../../../../Components/Common/InputTheme";
import SelectTheme from "../../../../Components/Common/SelectTheme";
import ScrollToError from "../../../../Components/Common/ScrollToError";

const AddEditPaymentMethodModal = ({ paymentMethod = null, isOpen, toggle, accounts = [] }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    paymentMethodState,
    paymentMethodsAction,
    paymentMethodsLoading,
    paymentMethodMessage,
    paymentMethodsSuccess,
    paymentMethodsError,
  } = useMemoizedSelector((state) => ({
    paymentMethodState: state.PaymentMethods.paymentMethod,
    paymentMethodsAction: state.PaymentMethods.action,
    paymentMethodsLoading: state.PaymentMethods.loading,
    paymentMethodsSuccess: state.PaymentMethods.success,
    paymentMethodsError: state.PaymentMethods.error,
    paymentMethodMessage: state.PaymentMethods.message,
  }));

  const formRef = useRef();
  const validation = {
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (paymentMethod && paymentMethod.name) || "",
      account: (paymentMethod && paymentMethod.account) || null,
      settings: (paymentMethod && paymentMethod.settings) || null,
      note: (paymentMethod && paymentMethod.note) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Please Enter Name")),
      account: Yup.object({
        type: Yup.string().required(t("Please Select Account Type")),
        id: Yup.string().required(t("Please Select Account Id")),
      }).required("Please Select Account"),
    }),
    onSubmit: (values) => {
      const payload = { ...values };
      if (paymentMethod?.id) {
        payload["id"] = paymentMethod.id;
        dispatch(updatePaymentMethod(payload, handleToggle));
      } else {
        dispatch(addNewPaymentMethod(payload, handleToggle));
      }
    },
  };

  const handleToggle = () => {
    if (!paymentMethodsLoading) {
      toggle();
      formRef.current.resetForm();
    }
  };

  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={handleToggle} centered>
        <ModalHeader toggle={handleToggle}>
          {t(paymentMethod ? "Edit payment method" : "Add new payment method")}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={validation.initialValues}
            validationSchema={validation.validationSchema}
            onSubmit={validation.onSubmit}
            innerRef={formRef}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <ScrollToError />
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <InputTheme name="name" label="Name" placeholder="Enter Name" />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3">
                      <SelectTheme
                        label={"Account"}
                        name="account"
                        placeholder={"Select An Account"}
                        remote={true}
                        isObjectValueSelect={true}
                        selectDataOrigin={true}
                        path="finance/accounts"
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3">
                      <InputTheme name="note" label="Note" placeholder="Enter note" />
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
        <ModalFooter className="border-0">
          <ButtonTheme type="button" className="btn btn-light w-sm" onClick={handleToggle}>
            {t("Close")}
          </ButtonTheme>
          <ButtonTheme
            type="button"
            className="btn btn-primary w-sm"
            loading={
              paymentMethodsLoading &&
              (paymentMethodsAction === Actions.UPDATE || paymentMethodsAction === Actions.CREATE)
            }
            disabled={
              paymentMethodsLoading &&
              (paymentMethodsAction === Actions.UPDATE || paymentMethodsAction === Actions.CREATE)
            }
            onClick={() => formRef.current.handleSubmit()}
          >
            {t(paymentMethod ? "Update" : "Add")}
          </ButtonTheme>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default AddEditPaymentMethodModal;
