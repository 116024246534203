import { Fragment, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

import { deepCopy, formatVNDCurrency } from "../../../../../helpers/format_helper";

import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import InputTheme from "../../../../../Components/Common/InputTheme";
const PriceDetails = ({
  variant,
  onChangeVariant = () => {},
  index,
  priceGroups,
  toggleOffSubmitPriceModal,
  isEdit = false,
}) => {
  const { t } = useTranslation();

  const handleChangePrice = (data) => {
    let priceIndex = (variant?.prices ?? []).findIndex((item) => item.price_group.id === data.price_group.id);
    const newData = deepCopy(variant?.prices ?? []);
    if (priceIndex > -1) {
      newData[priceIndex] = data;
    } else {
      newData.push(data);
    }
    onChangeVariant({ ...variant, prices: newData }, index);
  };

  return (
    <Card>
      <CardHeader>
        <Row className="g-4 align-items-center">
          <div className="col-sm">
            <h5 className="card-title mb-0 flex-grow-1 hstack justify-content-between">
              {isEmpty(index) ? "Prices" : "Variant Prices"}{" "}
              {isEdit && (
                <ButtonTheme
                  type="button"
                  className="btn btn-primary w-sm"
                  onClick={() => {
                    toggleOffSubmitPriceModal(variant?.prices ?? []);
                  }}
                >
                  {t("Apply for all")}
                </ButtonTheme>
              )}
            </h5>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        {isEdit ? (
          <Row>
            {priceGroups.map((item, key) => {
              let priceValue = (variant?.prices ?? []).find((p) => p.price_group.id === item.id)?.price || 0;
              return (
                <Col lg={6} key={key}>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="product-barcode-input">
                      {t(item.name || "---")}
                    </label>
                    <div className="mb-3">
                      <PriceEditField priceValue={priceValue} price={item} onChangePrice={handleChangePrice} />
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        ) : (
          <Row>
            {priceGroups.map((item, key) => {
              const priceGroup = (variant?.prices ?? []).find((subItem) => subItem.price_group.id === item.id);
              return (
                <Fragment key={key}>
                  <Col xs={3} className="hstack">
                    <span className="text-muted flex-grow-1">{t(item?.name || "---")}</span>
                  </Col>
                  <Col xs={3} className="text-end">
                    {formatVNDCurrency(priceGroup?.price ?? 0)}
                  </Col>
                </Fragment>
              );
            })}
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

const PriceEditField = ({ price, priceValue = 0, onChangePrice = () => {} }) => {
  const [value, setValue] = useState(priceValue);
  useEffect(() => {
    setValue(priceValue);
  }, [priceValue]);
  const handleChangePrice = () => {
    onChangePrice({ price: value, price_group: { id: price.id, name: price.name } });
  };
  return (
    <InputTheme
      isForm={false}
      format="price"
      min={0}
      value={value}
      onChange={(data) => {
        setValue(data < 0 ? 0 : data);
      }}
      onBlur={handleChangePrice}
    />
  );
};

export default PriceDetails;
