import React from "react";
import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
import useCheckPageLogin from "../../Components/Hooks/useCheckPageLogin";
import cls from "classnames";
const ParticlesAuth = ({ children, t }) => {
  const {isCheckPage} = useCheckPageLogin();
  return (
    <React.Fragment>
      <div className={cls("auth-page-wrapper",
        {"pt-0": isCheckPage,
        "pt-5": !isCheckPage,
        }
        )}>
        {!isCheckPage &&
          <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
            <div className="bg-overlay"></div>

            <div className="shape">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 1440 120"
              >
                <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
              </svg>
            </div>
          </div>
        }
        {/* pass the children */}
        {children}

{/*o day*/}
        {!isCheckPage &&
          <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center">
                  <p className="mb-0 text-muted">
                    &copy; {new Date().getFullYear()} OptiWarehouse. {t("Crafted with")}{" "}
                    <i className="mdi mdi-heart text-danger"></i> {t("by OneXAPIs team")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
        }
      </div>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(ParticlesAuth));
