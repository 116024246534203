import { Fragment, useEffect, useState } from "react";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ReactSelect from "react-select";
import { useTranslation } from "react-i18next";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";

const PriceSelectForm = ({ isOpen, toggle, selectedPriceGroup, handleChangePriceGroup }) => {
  const { priceGroups, priceGroupsLoading, priceGroupsSuccess, priceGroupsError } = useMemoizedSelector((state) => ({
    priceGroups: state.PriceGroups.priceGroups,
    priceGroupsLoading: state.PriceGroups.loading,
    priceGroupsSuccess: state.PriceGroups.success,
    priceGroupsError: state.PriceGroups.error,
  }));
  const { t } = useTranslation();
  const [priceGroup, setPriceGroup] = useState(selectedPriceGroup || null);

  useEffect(() => {
    isOpen && setPriceGroup(selectedPriceGroup);
  }, [isOpen]);
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{t("Select price group")}</ModalHeader>
      <ModalBody>
        <ReactSelect
          id="price-group-options"
          classNamePrefix="select"
          value={priceGroup}
          onChange={(e) => {
            setPriceGroup(e);
          }}
          options={priceGroups.map((item) => {
            return { label: item.name, value: item.id };
          })}
        />
      </ModalBody>
      <ModalFooter>
        <Button className="btn w-sm btn-light" onClick={toggle}>
          {t("Close")}
        </Button>
        <Button
          className="btn btn-primary w-sm"
          onClick={() => {
            handleChangePriceGroup(priceGroup);
            toggle();
          }}
        >
          {t("Save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default PriceSelectForm;
