import { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Col, Input, Row, Table } from "reactstrap";
import ImageCustom from "../../../../../../Components/Common/ImageCustom";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";
import ServiceNameInput from "../../../../../../Components/Common/Input/ServiceNameInput";
import InputStep from "../../../../../../Components/Common/InputStep";
import { deepCopy, formatVNDCurrency } from "../../../../../../helpers/format_helper";
import { toast } from "react-toastify";
import useMemoizedSelector from "../../../../../../Components/Hooks/useMemoizedSelector";

const ReturnOrderCard = ({ returnProducts = [], products, onChangeReturnOrder, isEdit = true }) => {
  const { t } = useTranslation();
  const { returnOrder } = useMemoizedSelector((state) => ({
    returnOrder: state.ReturnOrders.returnOrder,
  }));
  const returnQuantity = useMemo(
    () => (returnOrder?.return_order_line_items || []).reduce((total, item) => total + +item.quantity, 0),
    [returnProducts],
  );

  const handleChangeQuantity = (number, dataIndex) => {
    let newData = deepCopy(returnProducts);
    const maxQuantity = products.find((item, index) => item?.sku === newData?.[dataIndex]?.sku)?.quantity || 0;
    let orderItemIndex = newData.findIndex((item, index) => index === dataIndex);
    if (orderItemIndex > -1) {
      newData[orderItemIndex].quantity = maxQuantity < number ? maxQuantity : number;
    }
    onChangeReturnOrder(newData);
  };
  const handleChangeOrderLineItem = (index, data) => {
    let new_order_line_items = deepCopy(returnProducts);
    new_order_line_items[index]["return_price"] = data;
    onChangeReturnOrder(new_order_line_items);
  };
  return (
    <Card>
      <CardHeader>
        <div className="d-flex align-items-center">
          <h5 className="card-title flex-grow-1 mb-0">{t("Return product")}</h5>
        </div>
      </CardHeader>
      <CardBody>
        <div className={"table-card table-responsive"} style={{ maxHeight: "600px" }}>
          <Table hover className={"table table-nowrap align-middle mb-3"}>
            <thead className={"table-light text-muted"}>
              <tr className={""}>
                <th>{t("Items No.")}</th>
                <th>{t("Image")}</th>
                <th>{t("Name")}</th>
                <th className="text-center">{t("Quantity")}</th>
                <th className="text-end">{t("Price")}</th>
                <th className="text-end">{t("Return Price")}</th>
                <th className="text-end">{t("Total Amount")}</th>
              </tr>
            </thead>
            <tbody>
              {isEdit
                ? products.map((product, index) => {
                    const quantity = returnProducts.find((item) => item.sku === product.sku)?.quantity || 0;
                    const returnPrice = returnProducts.find((item) => item.sku === product.sku)?.return_price || 0;
                    const total = returnPrice * quantity;
                    return (
                      <tr
                        style={{ verticalAlign: "middle" }}
                        key={index}
                        onClick={() => {
                          // onRowClick(row);
                        }}
                      >
                        <td>{products.length - index}</td>
                        {!product?.custom ? (
                          <Fragment>
                            <td>
                              <ImageCustom image={product.image_url} name={product.name} avatarSize={"avatar-sm"} />
                            </td>
                            <td>
                              <div className="d-flex flex-column justify-content-center">
                                <h5 className="fs-15">
                                  <Tooltip title={product.name}>
                                    <Link
                                      id={`order-items-product-name-${index}`}
                                      to={{ pathname: `/products/${product.product_id}`, state: { sku: product.sku } }}
                                      className="d-inline-block text-truncate"
                                      style={{ maxWidth: "400px" }}
                                      target="_blank"
                                    >
                                      {product.name}
                                    </Link>
                                  </Tooltip>
                                </h5>
                                <p className="text-muted fw-medium mb-1">Sku: {product.sku}</p>
                              </div>
                            </td>
                          </Fragment>
                        ) : (
                          <td colSpan={2}>
                            <ServiceNameInput
                              className={"form-control border-0 border-bottom"}
                              placeholder={"Service name"}
                              onChangeItem={() => {}}
                              index={index}
                              product={product}
                              isEdit={false}
                            />
                          </td>
                        )}
                        <td className="text-center">
                          <InputStep index={index} onChangeQuantity={handleChangeQuantity} quantity={quantity} /> /{" "}
                          {product?.quantity || 0}
                        </td>
                        <td>
                          <div className="fw-medium text-end">{formatVNDCurrency(product?.sale_price || 0)}</div>
                        </td>
                        <td>
                          <ReturnPriceInput
                            index={index}
                            maxPrice={product?.sale_price || 0}
                            priceValue={returnPrice}
                            onChangePrice={handleChangeOrderLineItem}
                            placeholder={"Enter price"}
                            className={"border-0 border-bottom text-end"}
                          />
                        </td>
                        <td>
                          <div className="fw-medium text-end">{formatVNDCurrency(total)}</div>
                        </td>
                      </tr>
                    );
                  })
                : returnProducts.map((product, index) => {
                    const total = product?.return_price || 0 * product?.quantity || 0;
                    return (
                      <tr
                        style={{ verticalAlign: "middle" }}
                        key={index}
                        onClick={() => {
                          // onRowClick(row);
                        }}
                      >
                        <td>{products.length - index}</td>
                        {!product?.custom ? (
                          <Fragment>
                            <td>
                              <ImageCustom image={product.image_url} name={product.name} avatarSize={"avatar-sm"} />
                            </td>
                            <td>
                              <div className="d-flex flex-column justify-content-center">
                                <h5 className="fs-15">
                                  <Tooltip title={product.name}>
                                    <Link
                                      id={`order-items-product-name-${index}`}
                                      to={{ pathname: `/products/${product.product_id}`, state: { sku: product.sku } }}
                                      className="d-inline-block text-truncate"
                                      style={{ maxWidth: "400px" }}
                                      target="_blank"
                                    >
                                      {product.name}
                                    </Link>
                                  </Tooltip>
                                </h5>
                                <p className="text-muted fw-medium mb-1">Sku: {product.sku}</p>
                              </div>
                            </td>
                          </Fragment>
                        ) : (
                          <td colSpan={2}>
                            <ServiceNameInput
                              className={"form-control border-0 border-bottom"}
                              placeholder={"Service name"}
                              onChangeItem={() => {}}
                              index={index}
                              product={product}
                              isEdit={false}
                            />
                          </td>
                        )}
                        <td className="text-center">{product?.quantity || 0}</td>
                        <td>
                          <div className="fw-medium text-end">{formatVNDCurrency(product?.sale_price || 0)}</div>
                        </td>
                        <td>
                          <div className="fw-medium text-end">{formatVNDCurrency(product?.return_price || 0)}</div>
                        </td>
                        <td>
                          <div className="fw-medium text-end">{formatVNDCurrency(total)}</div>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
        </div>
        <Row className="justify-content-end">
          <Col className="col-6">
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td>
                    {t("Refund quantity")} ({returnQuantity} {t(`product${returnQuantity > 1 ? "s" : ""}`)})
                  </td>
                  <td className="text-end">{returnQuantity}</td>
                </tr>
                <tr>
                  <td>{t("Refund amount")}</td>
                  <td className="text-end">{formatVNDCurrency(returnOrder.total)}</td>
                </tr>
                {returnOrder?.exchanged_order && (
                  <tr>
                    <td>
                      {t("Exchanged amount")} (
                      <Link to={`/orders/${returnOrder.exchanged_order_id}`}>
                        {returnOrder?.exchanged_order_number || "---"}
                      </Link>
                      )
                    </td>
                    <td className="text-end">{formatVNDCurrency(returnOrder?.total_exchanged_amount || 0)}</td>
                  </tr>
                )}
                <tr className="fw-semibold border-top border-top-dashed">
                  <td>{t("Total refund amount")}</td>
                  <td className="text-end">
                    {formatVNDCurrency(returnOrder.total - returnOrder?.total_exchanged_amount || 0)}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const ReturnPriceInput = ({ maxPrice, priceValue = 0, onChangePrice = () => {}, index, placeholder, className }) => {
  const [value, setValue] = useState(priceValue);
  useEffect(() => {
    setValue(priceValue);
  }, [priceValue]);
  const handleChangePrice = () => {
    onChangePrice(index, value);
  };
  return (
    <Input
      type="number"
      min={0}
      value={value}
      className={className}
      placeholder={placeholder}
      onChange={(e) => {
        let data = Number(e.target.value);
        setValue(data > maxPrice ? maxPrice : data < 0 ? 0 : data);
      }}
      onBlur={handleChangePrice}
    />
  );
};

export default ReturnOrderCard;
