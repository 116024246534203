import { Actions } from "../../../../Components/constants/common";
import {
  LIST_SUPPLIER,
  DELETE_SUPPLIER,
  DELETE_SUPPLIER_SUCCESS,
  DELETE_SUPPLIER_FAIL,
  ADD_SUPPLIER_SUCCESS,
  ADD_SUPPLIER_FAIL,
  UPDATE_SUPPLIER_SUCCESS,
  UPDATE_SUPPLIER_FAIL,
  LIST_SUPPLIER_SUCCESS,
  LIST_SUPPLIER_FAIL,
  ADD_NEW_SUPPLIER,
  UPDATE_SUPPLIER,
  GET_SUPPLIER,
  GET_SUPPLIER_SUCCESS,
  GET_SUPPLIER_FAIL,
} from "./actionType";

const INIT_STATE = {
  suppliers: [],
  supplier: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const Suppliers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_SUPPLIER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_SUPPLIER_SUCCESS:
      return {
        ...state,
        message: "Get suppliers success",
        loading: false,
        success: true,
        suppliers: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_SUPPLIER_FAIL:
      return {
        ...state,
        message: "Get suppliers failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_SUPPLIER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_SUPPLIER_SUCCESS:
      return {
        ...state,
        message: "Get supplier success",
        loading: false,
        success: true,
        supplier: action.payload,
        action: Actions.EMPTY,
      };

    case GET_SUPPLIER_FAIL:
      return {
        ...state,
        message: "Get supplier failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_SUPPLIER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_SUPPLIER_SUCCESS:
      return {
        ...state,
        message: "Delete supplier success",
        loading: false,
        success: true,
        suppliers: state.suppliers.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_SUPPLIER_FAIL:
      return {
        ...state,
        message: "Delete supplier failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_NEW_SUPPLIER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_SUPPLIER_SUCCESS:
      return {
        ...state,
        message: "Add supplier success",
        success: true,
        loading: false,
        suppliers: [...state.suppliers, action.payload],
        action: Actions.EMPTY,
      };

    case ADD_SUPPLIER_FAIL:
      return {
        ...state,
        message: "Add supplier failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_SUPPLIER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.UPDATE,
      };
    case UPDATE_SUPPLIER_SUCCESS:
      return {
        ...state,
        message: "Update supplier success",
        loading: false,
        success: true,
        supplier: action.payload,
        suppliers: state.suppliers.map((supplier) =>
          supplier.id.toString() === action.payload.id.toString() ? { ...supplier, ...action.payload } : supplier,
        ),
        action: Actions.EMPTY,
      };

    case UPDATE_SUPPLIER_FAIL:
      return {
        ...state,
        message: "Update supplier failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    default:
      return { ...state };
  }
};

export default Suppliers;
