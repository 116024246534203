import {
  LIST_STOCK_RELOCATE,
  CANCEL_STOCK_RELOCATE,
  CANCEL_STOCK_RELOCATE_SUCCESS,
  CANCEL_STOCK_RELOCATE_FAIL,
  ADD_NEW_STOCK_RELOCATE,
  ADD_STOCK_RELOCATE_SUCCESS,
  ADD_STOCK_RELOCATE_FAIL,
  UPDATE_STOCK_RELOCATE,
  UPDATE_STOCK_RELOCATE_SUCCESS,
  UPDATE_STOCK_RELOCATE_FAIL,
  LIST_STOCK_RELOCATE_FAIL,
  LIST_STOCK_RELOCATE_SUCCESS,
  GET_STOCK_RELOCATE,
  GET_STOCK_RELOCATE_FAIL,
  GET_STOCK_RELOCATE_SUCCESS,
  DELIVERY_STOCK_RELOCATE,
  DELIVERY_STOCK_RELOCATE_SUCCESS,
  DELIVERY_STOCK_RELOCATE_FAIL,
  RECEIVE_STOCK_RELOCATE,
  RECEIVE_STOCK_RELOCATE_SUCCESS,
  RECEIVE_STOCK_RELOCATE_FAIL,
} from "./actionType";

export const listStockRelocate = (options) => ({
  type: LIST_STOCK_RELOCATE,
  payload: options,
});

export const listStockRelocateFail = (error) => ({
  type: LIST_STOCK_RELOCATE_FAIL,
  payload: error,
});

export const listStockRelocateSuccess = (stockRelocates) => ({
  type: LIST_STOCK_RELOCATE_SUCCESS,
  payload: stockRelocates,
});

export const getStockRelocate = (stockRelocateID) => ({
  type: GET_STOCK_RELOCATE,
  payload: stockRelocateID,
});

export const getStockRelocateFail = (error) => ({
  type: GET_STOCK_RELOCATE_FAIL,
  payload: error,
});

export const getStockRelocateSuccess = (stockRelocate) => ({
  type: GET_STOCK_RELOCATE_SUCCESS,
  payload: stockRelocate,
});

export const cancelStockRelocate = (stockRelocate, handleAfterSubmit = () => {}) => ({
  type: CANCEL_STOCK_RELOCATE,
  payload: { stockRelocate, handleAfterSubmit },
});

export const cancelStockRelocateSuccess = (stockRelocate) => ({
  type: CANCEL_STOCK_RELOCATE_SUCCESS,
  payload: stockRelocate,
});

export const cancelStockRelocateFail = (error) => ({
  type: CANCEL_STOCK_RELOCATE_FAIL,
  payload: error,
});

export const deliveryStockRelocate = (stockRelocate, handleAfterSubmit = () => {}) => ({
  type: DELIVERY_STOCK_RELOCATE,
  payload: { stockRelocate, handleAfterSubmit },
});

export const deliveryStockRelocateSuccess = (stockRelocate) => ({
  type: DELIVERY_STOCK_RELOCATE_SUCCESS,
  payload: stockRelocate,
});

export const deliveryStockRelocateFail = (error) => ({
  type: DELIVERY_STOCK_RELOCATE_FAIL,
  payload: error,
});

export const receiveStockRelocate = (stockRelocate, handleAfterSubmit = () => {}) => ({
  type: RECEIVE_STOCK_RELOCATE,
  payload: { stockRelocate, handleAfterSubmit },
});

export const receiveStockRelocateSuccess = (stockRelocate) => ({
  type: RECEIVE_STOCK_RELOCATE_SUCCESS,
  payload: stockRelocate,
});

export const receiveStockRelocateFail = (error) => ({
  type: RECEIVE_STOCK_RELOCATE_FAIL,
  payload: error,
});

export const updateStockRelocate = (stockRelocate) => ({
  type: UPDATE_STOCK_RELOCATE,
  payload: stockRelocate,
});

export const updateStockRelocateSuccess = (stockRelocate) => ({
  type: UPDATE_STOCK_RELOCATE_SUCCESS,
  payload: stockRelocate,
});

export const updateStockRelocateFail = (error) => ({
  type: UPDATE_STOCK_RELOCATE_FAIL,
  payload: error,
});

export const addNewStockRelocate = (stockRelocate, handleAfterSubmit = () => {}) => ({
  type: ADD_NEW_STOCK_RELOCATE,
  payload: { stockRelocate, handleAfterSubmit },
});

export const addStockRelocateSuccess = (stockRelocate) => ({
  type: ADD_STOCK_RELOCATE_SUCCESS,
  payload: stockRelocate,
});

export const addStockRelocateFail = (error) => ({
  type: ADD_STOCK_RELOCATE_FAIL,
  payload: error,
});
