// Actions
export const LIST_RETURN_ORDER = "LIST_RETURN_ORDER";
export const LIST_RETURN_ORDER_SUCCESS = "LIST_RETURN_ORDER_SUCCESS";
export const LIST_RETURN_ORDER_FAIL = "LIST_RETURN_ORDER_FAIL";

export const GET_RETURN_ORDER = "GET_RETURN_ORDER";
export const GET_RETURN_ORDER_SUCCESS = "GET_RETURN_ORDER_SUCCESS";
export const GET_RETURN_ORDER_FAIL = "GET_RETURN_ORDER_FAIL";

export const ADD_NEW_RETURN_ORDER = "ADD_NEW_RETURN_ORDER";
export const ADD_RETURN_ORDER_SUCCESS = "ADD_RETURN_ORDER_SUCCESS";
export const ADD_RETURN_ORDER_FAIL = "ADD_RETURN_ORDER_FAIL";

