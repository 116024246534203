import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { formatVNDCurrency, getChartColorsArray } from "../../../../helpers/format_helper";
import NotFound from "../../../../Components/Common/NotFound";

const BreakEventChart = ({ dataColors, metricsByMonth, cost, t }) => {
  const DashedLineColors = getChartColorsArray(dataColors);
  const initSeries = [
    {
      name: "Break Event",
      data: [],
    },
    {
      name: "Page Views",
      data: [],
    },
  ];
  const [series, setSeries] = useState(initSeries);
  const [options, setOptions] = useState({
    chart: {
      height: 380,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: DashedLineColors,
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
    },
    stroke: {
      width: [3, 4, 3],
      curve: "straight",
      dashArray: [0, 8, 5],
    },
    markers: {
      size: 0,

      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return formatVNDCurrency(val);
        },
      },
    },
    tooltip: {
      y: [
        {
          formatter: function (val) {
            return formatVNDCurrency(val);
          },
        },
        {
          formatter: function (val) {
            return formatVNDCurrency(val);
          },
        },
      ],
    },
    legend: {
      position: "top",
      offsetX: 0,
      offsetY: 0,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  });

  useEffect(() => {
    if (Object.keys(metricsByMonth)) {
      const categoryArray = Object.keys(metricsByMonth);
      const revenueArray = Object.values(metricsByMonth).map((item) => (item?.revenue || 0).toFixed(0));
      const costArray = Array.from(categoryArray, (x) => cost.toFixed(0));
      const newSeries = [];
      if (categoryArray.length > 0 && revenueArray.length > 0) {
        newSeries.push(
          {
            name: t("Break Event"),
            data: costArray,
          },
          {
            name: t("Revenue"),
            data: revenueArray,
          },
        );
      }
      setSeries(newSeries);
      setOptions((pre) => ({ ...pre, xaxis: { categories: categoryArray } }));
    }
  }, [metricsByMonth]);
  if (series.length === 0) {
    return <NotFound />;
  }
  return (
    <React.Fragment>
      <ReactApexChart dir="ltr" options={options} series={series} type="line" height="350" className="apex-charts" />
    </React.Fragment>
  );
};
export default BreakEventChart;
