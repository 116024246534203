import {
  LIST_TERMINAL,
  DELETE_TERMINAL,
  DELETE_TERMINAL_SUCCESS,
  DELETE_TERMINAL_FAIL,
  ADD_NEW_TERMINAL,
  ADD_TERMINAL_SUCCESS,
  ADD_TERMINAL_FAIL,
  UPDATE_TERMINAL,
  UPDATE_TERMINAL_SUCCESS,
  UPDATE_TERMINAL_FAIL,
  LIST_TERMINAL_FAIL,
  LIST_TERMINAL_SUCCESS,
  GET_TERMINAL,
  GET_TERMINAL_FAIL,
  GET_TERMINAL_SUCCESS,
} from "./actionType";

export const listTerminal = (options) => ({
  type: LIST_TERMINAL,
  payload: options,
});

export const listTerminalFail = (error) => ({
  type: LIST_TERMINAL_FAIL,
  payload: error,
});

export const listTerminalSuccess = (terminals) => ({
  type: LIST_TERMINAL_SUCCESS,
  payload: terminals,
});

export const getTerminal = (terminalID) => ({
  type: GET_TERMINAL,
  payload: terminalID,
});

export const getTerminalFail = (error) => ({
  type: GET_TERMINAL_FAIL,
  payload: error,
});

export const getTerminalSuccess = (terminal) => ({
  type: GET_TERMINAL_SUCCESS,
  payload: terminal,
});

export const deleteTerminal = (terminal, handleAfterSubmit = () => {}) => ({
  type: DELETE_TERMINAL,
  payload: { terminal, handleAfterSubmit },
});

export const deleteTerminalSuccess = (terminal) => ({
  type: DELETE_TERMINAL_SUCCESS,
  payload: terminal,
});

export const deleteTerminalFail = (error) => ({
  type: DELETE_TERMINAL_FAIL,
  payload: error,
});

export const updateTerminal = (terminal, handleAfterSubmit = () => {}) => ({
  type: UPDATE_TERMINAL,
  payload: { terminal, handleAfterSubmit },
});

export const updateTerminalSuccess = (terminal) => ({
  type: UPDATE_TERMINAL_SUCCESS,
  payload: terminal,
});

export const updateTerminalFail = (error) => ({
  type: UPDATE_TERMINAL_FAIL,
  payload: error,
});

export const addNewTerminal = (terminal, handleAfterSubmit = () => {}) => ({
  type: ADD_NEW_TERMINAL,
  payload: { terminal, handleAfterSubmit },
});

export const addTerminalSuccess = (terminal) => ({
  type: ADD_TERMINAL_SUCCESS,
  payload: terminal,
});

export const addTerminalFail = (error) => ({
  type: ADD_TERMINAL_FAIL,
  payload: error,
});
