import { useEffect, useRef, useState } from "react";
import { Card, CardBody, CardHeader, Col, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { Form, Formik, useFormik } from "formik";
import { useTranslation } from "react-i18next";

import ImageInput from "../../../../../Components/Common/ImageInput";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import LoadingButtonIcon from "../../../../../Components/Common/LoadingButtonIcon";
import useMemoizedSelector from "../../../../../Components/Hooks/useMemoizedSelector";
import { Actions } from "../../../../../Components/constants/common";
import InputTheme from "../../../../../Components/Common/InputTheme";
import ScrollToError from "../../../../../Components/Common/ScrollToError";

const VariantDetails = ({
  productState,
  variant,
  onChangeVariant = () => {},
  index,
  toggleOnSubmitMeasurementModal,
  isEdit = false,
}) => {
  const { t } = useTranslation();

  const formRef = useRef();

  const variantValidation = {
    enableReinitialize: true,
    initialValues: {
      barcode: variant?.barcode ?? null,
      images: variant?.images ?? null,
      name: variant?.name ?? "",
      sku: variant?.sku ?? "",
      measurements: variant?.measurements ?? {
        height_unit: variant?.measurements?.height_unit ?? "cm",
        height_value: variant?.measurements?.height_value ?? 0,
        length_unit: variant?.measurements?.length_unit ?? "cm",
        length_value: variant?.measurements?.length_value ?? 0,
        width_unit: variant?.measurements?.width_unit ?? "cm",
        width_value: variant?.measurements?.width_value ?? 0,
        weight_unit: variant?.measurements?.weight_unit ?? "g",
        weight_value: variant?.measurements?.weight_value ?? 0,
      },
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Please Enter Product Name")),
      sku: Yup.string().required(t("Please Enter Product Sku")),
    }),
  };

  const handleApplyForAll = () => {
    toggleOnSubmitMeasurementModal({
      height_unit: formRef?.current?.values?.measurements?.height_unit,
      height_value: formRef?.current?.values?.measurements?.height_value,
      length_unit: formRef?.current?.values?.measurements?.length_unit,
      length_value: formRef?.current?.values?.measurements?.length_value,
      width_unit: formRef?.current?.values?.measurements?.width_unit,
      width_value: formRef?.current?.values?.measurements?.width_value,
      weight_unit: formRef?.current?.values?.measurements?.weight_unit,
      weight_value: formRef?.current?.values?.measurements?.weight_value,
    });
  };

  useEffect(() => {
    onChangeVariant({ ...variant, ...formRef?.current?.values }, index);
  }, [formRef?.current?.values]);

  return (
    <Card>
      <CardHeader>
        <Row className="g-4 align-items-center">
          <div className="col-sm">
            <div className="hstack">
              <h5 className="card-title mb-0 flex-grow-1">{t("Variant Details")}</h5>
            </div>
          </div>
        </Row>
      </CardHeader>
      {isEdit ? (
        <CardBody>
          <Formik
            enableReinitialize={true}
            initialValues={variantValidation.initialValues}
            validationSchema={variantValidation.validationSchema}
            onSubmit={variantValidation.onSubmit}
            innerRef={formRef}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <ScrollToError />
                <div className="mb-3">
                  <InputTheme name={"name"} placeholder="Enter name" label={"Name"} />
                </div>
                <div className="mb-3">
                  <InputTheme name={"sku"} placeholder="Enter sku" label={"Sku"} />
                </div>
                <div className="mb-3">
                  <InputTheme name={"barcode"} placeholder="Enter barcode" label={"Barcode"} />
                </div>
                <div className="mb-3">
                  <h5 className="fs-14 mb-1">{t("Images")}</h5>
                  <ImageInput
                    images={values.images}
                    onChangeImages={(data) => {
                      setFieldValue("images", data);
                    }}
                    productState={productState}
                    isShowModalSelection={true}
                  />
                </div>
                <div className="mb-3">
                  <h5 className="fs-14 mb-1 hstack justify-content-between">
                    {t("Measurements")}{" "}
                    <ButtonTheme type="button" className="btn btn-primary w-sm" onClick={handleApplyForAll}>
                      {t("Apply for all")}
                    </ButtonTheme>
                  </h5>
                  <Row>
                    <Col sm={6}>
                      <div className="mb-3">
                        <InputTheme
                          label="Height"
                          type="number"
                          name="measurements.height_value"
                          customRenderEnd={
                            <>
                              <Input
                                type="select"
                                value={values.measurements?.height_unit}
                                className="input-group-text"
                                onChange={(e) => {
                                  setFieldValue("measurements.height_unit", e.target.value);
                                }}
                              >
                                <option value="mm">mm</option>
                                <option value="cm">cm</option>
                                <option value="m">m</option>
                              </Input>
                            </>
                          }
                        />
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="mb-3">
                        <InputTheme
                          label="Length"
                          type="number"
                          name="measurements.length_value"
                          customRenderEnd={
                            <>
                              <Input
                                type="select"
                                value={values.measurements?.length_unit}
                                className="input-group-text"
                                onChange={(e) => {
                                  setFieldValue("measurements.length_unit", e.target.value);
                                }}
                              >
                                <option value="mm">mm</option>
                                <option value="cm">cm</option>
                                <option value="m">m</option>
                              </Input>
                            </>
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <InputTheme
                        label="Width"
                        type="number"
                        name="measurements.width_value"
                        customRenderEnd={
                          <>
                            <Input
                              type="select"
                              value={values.measurements?.width_unit}
                              className="input-group-text"
                              onChange={(e) => {
                                setFieldValue("measurements.width_unit", e.target.value);
                              }}
                            >
                              <option value="mm">mm</option>
                              <option value="cm">cm</option>
                              <option value="m">m</option>
                            </Input>
                          </>
                        }
                      />
                    </Col>
                    <Col sm={6}>
                      <InputTheme
                        label="Weight"
                        type="number"
                        name="measurements.weight_value"
                        customRenderEnd={
                          <>
                            <Input
                              type="select"
                              value={values.measurements?.weight_unit}
                              className="input-group-text"
                              onChange={(e) => {
                                setFieldValue("measurements.weight_unit", e.target.value);
                              }}
                            >
                              <option value="gr">gr</option>
                              <option value="g">g</option>
                              <option value="kg">kg</option>
                            </Input>
                          </>
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </Form>
            )}
          </Formik>
        </CardBody>
      ) : (
        <CardBody>
          <div className="hstack">
            <div className="flex-grow-1">
              <Row>
                <Col xs={6}>
                  <span className="text-muted">{t("Name")}</span>
                </Col>
                <Col xs={6}>: {t(variant.name || "---")}</Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <span className="text-muted">{t("Sku")}</span>
                </Col>
                <Col xs={6}>: {variant.sku}</Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <span className="text-muted">{t("Barcode")}</span>
                </Col>
                <Col xs={6}>: {variant.barcode || "---"}</Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <span className="text-muted">{t("Option")} 1</span>
                </Col>
                <Col xs={6}>: {variant.option1}</Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <span className="text-muted">{t("Option")} 2</span>
                </Col>
                <Col xs={6}>: {variant.option2 || "---"}</Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <span className="text-muted">{t("Option")} 3</span>
                </Col>
                <Col xs={6}>: {variant.option3 || "---"}</Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <span className="text-muted">{t("Unit")}</span>
                </Col>
                <Col xs={6}>: {variant?.unit?.name ?? "---"}</Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <span className="text-muted">{t("Height")}</span>
                </Col>
                <Col xs={6}>
                  : {variant.measurements.height_value} {variant.measurements.height_unit}
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <span className="text-muted">{t("Length")}</span>
                </Col>
                <Col xs={6}>
                  : {variant.measurements.length_value} {variant.measurements.length_unit}
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <span className="text-muted">{t("Weight")}</span>
                </Col>
                <Col xs={6}>
                  : {variant.measurements.weight_value} {variant.measurements.weight_unit}
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <span className="text-muted">{t("Width")}</span>
                </Col>
                <Col xs={6}>
                  : {variant.measurements.width_value} {variant.measurements.width_unit}
                </Col>
              </Row>
            </div>
            <div className="flex-shrink-0 px-3">
              <img
                className="rounded border bg-light"
                alt=""
                src={variant.images?.[0]?.url}
                style={{ objectFit: "contain", height: "10rem", width: "10rem" }}
              />
            </div>
          </div>
        </CardBody>
      )}
    </Card>
  );
};
export default VariantDetails;
