import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Alert } from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { Form, Formik } from "formik";

import withRouter from "../../Components/Common/withRouter";
import InputTheme from "../../Components/Common/InputTheme";
import ButtonTheme from "../../Components/Common/ButtonTheme";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import useMemoizedSelector from "../../Components/Hooks/useMemoizedSelector";
import Loader from "../../Components/Common/Loader";
import { useProfile } from "../../Components/Hooks/useProfile";
// actions
import { loginUser, resetLoginFlag } from "../../store/actions";
import { IconGoogle, LogoLight } from "../../assets/images/svg";
import { images } from "../../assets/images";
import "../../specify-tailwind.css";
import LanguageDropdown from "../../Components/Common/LanguageDropdown";
import ScrollToError from "../../Components/Common/ScrollToError";

const Login = (props) => {
  const {
    router: { t, location, navigate, params },
  } = props;
  const historyPath = location?.state?.from;
  const dispatch = useDispatch();
  const { errorMsg, loading, initLoading, error } = useMemoizedSelector((state) => ({
    errorMsg: state.Login.errorMsg,
    loading: state.Login.loading,
    initLoading: state.Login.initLoading,
    error: state.Login.error,
  }));
  const { dataShop } = useProfile();
  const [logo, setLogo] = useState(null);
  const [name, setName] = useState("");
  useEffect(() => {
    setLogo(dataShop?.setting_value?.store_logo_light?.url || LogoLight);
    setName(dataShop?.setting_value?.store_name || "OptiWarehouse");
  }, [dataShop]);

  const validation = {
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required(t("Please Enter Your Username")),
      password: Yup.string().required(t("Please Enter Your Password")),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, navigate, historyPath));
    },
  };

  useEffect(() => {
    if (!loading && !!error) {
      let resetMsgTime = setTimeout(() => {
        dispatch(resetLoginFlag());
      }, 3000);
      return () => clearTimeout(resetMsgTime);
    }
  }, [dispatch, error, loading]);

  document.title = `${t("Sign In")} | ${name}`;
  return (
    <Fragment>
      {initLoading ? (
        <div className="hstack justify-content-center" style={{ height: "100vh" }}>
          <div>
            <Loader />
            <p className="mt-3">{t("Initialing account...")}</p>
          </div>
        </div>
      ) : (
        <ParticlesAuth>
          <div>
            <div className="tw-flex tw-w-full mq850:tw-justify-center tw-bg-white">
              <div
                className={
                  "tw-w-[60%] tw-flex tw-flex-col tw-justify-center mq850:tw-mt-[0] tw-relative mq850:tw-w-[90%] mq850:tw-h-screen"
                }
              >
                {errorMsg && errorMsg ? <Alert color="danger"> {t(errorMsg)} </Alert> : null}
                <div
                  className={"tw-mx-[10%] mq850:tw-mx-[0px] tw-flex  tw-flex-col mq850:tw-mb-[30%] tw-justify-center "}
                >
                  <div className={"tw-flex tw-justify-between tw-items-center"}>
                    <img src={logo} className={"tw-h-[60px] tw-w-[200px]"} />
                    <div className={"tw-flex  tw-items-center"}>
                      <p className={"tw-font-normal tw-mt-2  tw-text-[14px]"}>{t("language")} : </p>
                      <LanguageDropdown />
                    </div>
                  </div>
                  <p className={"tw-text-[40px] tw-font-bold tw-text-[#151334] tw-mt-[60px]"}>{t("Sign in")}</p>
                  <p className={"tw-text-[14px] tw-font-normal"}>{t("register")}</p>
                  {/*<button*/}
                  {/*  className={*/}
                  {/*    "tw-p-3 tw-flex  tw-h-[48px] tw-bg-white tw-border-0 tw-gap-3 tw-shadow-md tw-rounded-md tw-w-[50%]  mq850:tw-w-full  tw-mt-4"*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <img src={IconGoogle} alt={""} width={24} height={24} />*/}
                  {/*  <p>{t("signInWithGoogle")}</p>*/}
                  {/*</button>*/}
                  <div className="mt-4">
                    <Formik
                      enableReinitialize={true}
                      initialValues={validation.initialValues}
                      validationSchema={validation.validationSchema}
                      onSubmit={validation.onSubmit}
                    >
                      {({ values, setFieldValue }) => (
                        <Form>
                          <ScrollToError />
                          <div className="mb-3">
                            <InputTheme
                              name="username"
                              label="Username"
                              disabled={loading}
                              placeholder="Enter username"
                            />
                          </div>

                          <div className="mb-3">
                            <InputTheme
                              type="password"
                              name="password"
                              label="Password"
                              disabled={loading}
                              placeholder="Enter password"
                            />
                          </div>

                          <div className="hstack justify-content-end">
                            {/* <div className="form-check form-check-primary flex-grow-1">
                              <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                              <Label className="form-check-label" htmlFor="auth-remember-check">
                                {t("Remember me")}
                              </Label>
                            </div> */}
                            <Link to="/forgot-password" className="text-muted">
                              {t("Forgot password?")}
                            </Link>
                          </div>

                          <div className="mt-4">
                            <ButtonTheme
                              id="sign-in-button"
                              className="btn btn-primary w-100"
                              type="submit"
                              loading={loading}
                              disabled={loading}
                            >
                              {t("Sign In")}
                            </ButtonTheme>
                          </div>
                          <div className={"tw-flex tw-gap-3 tw-mt-3"}>
                            <p className={"tw-text-[14px] tw-text-normal tw-text-[#737185]"}>{t("noAccount")}</p>
                            <p
                              className={"tw-font-normal tw-text-[14px] tw-text-[#5252E1] hover:tw-cursor-pointer"}
                              onClick={() => navigate("/register")}
                            >
                              {t("signUp")}
                            </p>
                          </div>

                          <p className="tw-absolute tw-bottom-[50px] tw-text-[#737185] tw-text-center mq850:tw-text-[12px]">
                            &copy; {new Date().getFullYear()} OptiWarehouse. {t("Crafted with")}{" "}
                            <i className="mdi mdi-heart text-danger"></i> {t("by OneXAPIs team")}
                          </p>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>

                {/* <div className="mt-4 text-center">
                    <p className="mb-0">
                      {t("Don't have an account?")}{" "}
                      <Link to="/register" className="fw-semibold text-primary text-decoration-underline">
                        {" "}
                        {t("Sign up")}{" "}
                      </Link>{" "}
                    </p>
                  </div> */}
              </div>
              <img
                src={images.ImageLogin}
                alt={""}
                className={"tw-w-[40%] tw-object-cover tw-h-screen mq850:tw-hidden"}
              />
            </div>
          </div>
        </ParticlesAuth>
      )}
    </Fragment>
  );
};

export default withRouter(Login);
