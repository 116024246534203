import flagus from "../assets/images/flags/us.svg";
import flagvn from "../assets/images/flags/vn.svg";

const languages = {
  en: {
    label: "English",
    flag: flagus,
  },
  vi: {
    label: "Tiếng Việt",
    flag: flagvn,
  },
};

export default languages;
