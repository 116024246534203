import { GET_IMPORT_RECORD, GET_IMPORT_RECORD_FAIL, GET_IMPORT_RECORD_SUCCESS, LIST_RECORD, LIST_RECORD_FAIL, LIST_RECORD_SUCCESS } from "./actionType";

export const listRecord = (options) => ({
  type: LIST_RECORD,
  payload: options,
});

export const listRecordFail = (error) => ({
  type: LIST_RECORD_FAIL,
  payload: error,
});

export const listRecordSuccess = (records) => ({
  type: LIST_RECORD_SUCCESS,
  payload: records,
});

export const getImportRecord = (recordID) => ({
  type: GET_IMPORT_RECORD,
  payload: recordID,
});

export const getImportRecordFail = (error) => ({
  type: GET_IMPORT_RECORD_FAIL,
  payload: error,
});

export const getImportRecordSuccess = (importRecord) => ({
  type: GET_IMPORT_RECORD_SUCCESS,
  payload: importRecord,
});