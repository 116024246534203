import { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, Row, Table } from "reactstrap";

import { convertValidDate, convertValidTime } from "../../../../helpers/format_helper";

import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import Loader from "../../../../Components/Common/Loader";
import { Actions } from "../../../../Components/constants/common";

import { getImportRecord } from "../../../../store/actions";
import withRouter from "../../../../Components/Common/withRouter";
import BreadCrumbCustom from "../../../../Components/Common/BreadCrumbCustom";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";
import LottieIcon from "../../../../Components/Common/LottieIcon";

const RecordDetails = (props) => {
  document.title = "Record Details | OptiWarehouse";
  const {
    router: { navigate, location, params, t },
  } = props;
  const dispatch = useDispatch();
  const { id } = params;

  const { import_record, recordsLoading, recordsAction, recordMessage, recordsError } = useMemoizedSelector(
    (state) => ({
      import_record: state.Records.import_record,
      recordsLoading: state.Records.loading,
      recordsAction: state.Records.action,
      recordsError: state.Records.error,
      recordMessage: state.Records.message,
    }),
  );

  useEffect(() => {
    id && dispatch(getImportRecord(id));
  }, [id]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumbCustom text={"Back to record list"} navigateTo={"/records"} />
        <Row>
          <Col lg={12}>
            {recordsAction === Actions.GET ? (
              <Loader />
            ) : recordsError ? (
              <div className="text-center">
                <LottieIcon src="https://cdn.lordicon.com/msoeawqm.json" style={{ width: "130px", height: "130px" }} />
                <div>
                  <h4>{t(recordsError)}</h4>
                </div>
              </div>
            ) : (
              <Fragment>
                <h5 className="card-title mb-3">
                  Record Details
                  <span className="badge bg-primary rounded-pill align-middle ms-1 filter-badge">
                    {import_record ? import_record.length : 0}
                  </span>
                </h5>
                {import_record.map((item, index) => (
                  <Card key={index}>
                    <CardHeader>
                      <h5 className="card-title">
                        {index + 1}. {item?.record_id}
                      </h5>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg={6}>
                          <div className="table-responsive">
                            <Table className="table mb-0 table-borderless">
                              <tbody>
                                <tr>
                                  <th>
                                    <span className="fw-medium">Id:</span>
                                  </th>
                                  <td>{item?.id || "---"}</td>
                                </tr>
                                <tr>
                                  <th>
                                    <span className="fw-medium">Company id:</span>
                                  </th>
                                  <td>{item?.company_id || "---"}</td>
                                </tr>
                                <tr>
                                  <th>
                                    <span className="fw-medium">App id:</span>
                                  </th>
                                  <td>{item?.app_id || "---"}</td>
                                </tr>
                                <tr>
                                  <th>
                                    <span className="fw-medium">Record type:</span>
                                  </th>
                                  <td>{item?.record_type || "---"}</td>
                                </tr>
                                <tr>
                                  <th>
                                    <span className="fw-medium">Record type:</span>
                                  </th>
                                  <td>{item?.record ? <JsonView src={item.record} collapsed={true} /> : "---"}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="table-responsive">
                            <Table className="table mb-0 table-borderless">
                              <tbody>
                                <tr>
                                  <th>
                                    <span className="fw-medium">Response code:</span>
                                  </th>
                                  <td>{item?.response_code || "---"}</td>
                                </tr>
                                <tr>
                                  <th>
                                    <span className="fw-medium">Response type:</span>
                                  </th>
                                  <td>{item?.response_type || "---"}</td>
                                </tr>
                                <tr>
                                  <th>
                                    <span className="fw-medium">Updated at:</span>
                                  </th>
                                  <td>
                                    {convertValidDate(item?.updated_at)},{" "}
                                    <small>{convertValidTime(item?.updated_at)}</small>
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <span className="fw-medium">Created at:</span>
                                  </th>
                                  <td>
                                    {convertValidDate(item?.created_at)},{" "}
                                    <small>{convertValidTime(item?.created_at)}</small>
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <span className="fw-medium">Record type:</span>
                                  </th>
                                  <td>{item?.response ? <JsonView src={item.response} collapsed={true} /> : "---"}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                ))}
              </Fragment>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(RecordDetails);
