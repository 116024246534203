// Actions
export const LIST_STORE = "LIST_STORE";
export const LIST_STORE_SUCCESS = "LIST_STORE_SUCCESS";
export const LIST_STORE_FAIL = "LIST_STORE_FAIL";

export const GET_STORE = "GET_STORE";
export const GET_STORE_SUCCESS = "GET_STORE_SUCCESS";
export const GET_STORE_FAIL = "GET_STORE_FAIL";

/**
 * Delete STORE
 */
export const DELETE_STORE = "DELETE_STORE";
export const DELETE_STORE_SUCCESS = "DELETE_STORE_SUCCESS";
export const DELETE_STORE_FAIL = "DELETE_STORE_FAIL";

/**
 * Add STORE
 */
export const ADD_NEW_STORE = "ADD_NEW_STORE";
export const ADD_STORE_SUCCESS = "ADD_STORE_SUCCESS";
export const ADD_STORE_FAIL = "ADD_STORE_FAIL";

/**
 * Edit STORE
 */
export const UPDATE_STORE = "UPDATE_STORE";
export const UPDATE_STORE_SUCCESS = "UPDATE_STORE_SUCCESS";
export const UPDATE_STORE_FAIL = "UPDATE_STORE_FAIL";

// Actions
export const LIST_APP_STORE = "LIST_APP_STORE";
export const LIST_APP_STORE_SUCCESS = "LIST_APP_STORE_SUCCESS";
export const LIST_APP_STORE_FAIL = "LIST_APP_STORE_FAIL";

export const GET_APP_STORE = "GET_APP_STORE";
export const GET_APP_STORE_SUCCESS = "GET_APP_STORE_SUCCESS";
export const GET_APP_STORE_FAIL = "GET_APP_STORE_FAIL";

// Actions
export const LIST_APP = "LIST_APP";
export const LIST_APP_SUCCESS = "LIST_APP_SUCCESS";
export const LIST_APP_FAIL = "LIST_APP_FAIL";
/**
 * Delete APP
 */
export const DELETE_APP = "DELETE_APP";
export const DELETE_APP_SUCCESS = "DELETE_APP_SUCCESS";
export const DELETE_APP_FAIL = "DELETE_APP_FAIL";
