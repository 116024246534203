import { Fragment, useState } from "react";
import { EffectiveProduct } from "../../../../../Components/constants/common";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";
import PopoverProductInfo from "./PopoverProductInfo";
import PopoverVariantInfo from "./PopoverVariantInfo";

const TagName = ({
  item_type,
  for_all_items,
  listItems = [],
  isEdit = true,
  onDelete = () => {},
  onDeleteById = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {for_all_items ? (
        <div className="d-inline-block p-1 bg-soft-primary rounded-4 w-auto">
          <div className="d-inline-flex">
            <div className="text-truncate" style={{ maxWidth: "300px" }}>
              {t("All items")}
            </div>
            {isEdit && (
              <button
                type="button"
                className="btn btn-icon btn-ghost-primary rounded-circle ms-2"
                style={{ height: "18px", width: "18px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete();
                }}
              >
                <i className="ri-close-line text-primary"></i>
              </button>
            )}
          </div>
        </div>
      ) : (
        listItems && (
          <Fragment>
            {item_type !== EffectiveProduct.VARIANTS
              ? listItems.map((item, index) => {
                  const image = item?.images?.[0]?.url || item?.image?.url;
                  const product_count = item.product_count || 0;
                  return (
                    <Fragment key={index}>
                      <div className="border border-primary hstack p-2 rounded w-100">
                        <div className="d-flex align-items-center">
                          <img src={image} className="me-3 avatar-xs " alt="" />
                        </div>
                        <div className="flex-grow-1">
                          <div className="fs-13">
                            <p className="mb-0">{item.name}</p>
                            {item_type === EffectiveProduct.PRODUCTS ? (
                              <div className="mb-0 hstack gap-1">
                                Variants: <PopoverProductInfo type={item_type} index={index} row={item} />
                              </div>
                            ) : (
                              <div className="mb-0">
                                Products:{" "}
                                <Tooltip
                                  title={`View product list filtered by ${
                                    item_type === EffectiveProduct.CATEGORIES ? "category" : "brand"
                                  }`}
                                >
                                  <Link
                                    to={`/products?${
                                      item_type === EffectiveProduct.CATEGORIES ? "category.id" : "brand.id"
                                    }=${item.id}`}
                                    target="_blank"
                                  >
                                    {product_count}
                                  </Link>
                                </Tooltip>
                              </div>
                            )}
                          </div>
                        </div>
                        {isEdit && (
                          <button
                            type="button"
                            className="btn btn-icon btn-ghost-primary rounded-circle ms-2"
                            style={{ height: "18px", width: "18px" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              onDelete(item.id);
                            }}
                          >
                            <i className="ri-close-line text-danger"></i>
                          </button>
                        )}
                      </div>
                    </Fragment>
                  );
                })
              : listItems
                  .reduce((pre, item, subIndex) => {
                    if (item?.product_id) {
                      const productIndex = pre.findIndex((subItem) => subItem.product_id === item?.product_id);
                      if (productIndex > -1) {
                        pre[productIndex].variants.push(item);
                      } else {
                        pre.push({ product_id: item.product_id, variants: [item] });
                      }
                    } else {
                      pre.push({ product_id: item.product_id, variants: [item] });
                    }
                    return pre;
                  }, [])
                  .map((item, index) => {
                    return (
                      <Fragment key={index}>
                        <div className="border border-primary hstack p-2 rounded w-100">
                          <PopoverVariantInfo index={index} row={item} isEdit={isEdit} onDeleteById={onDeleteById} />
                        </div>
                      </Fragment>
                    );
                  })}
          </Fragment>
        )
      )}
    </Fragment>
  );
};
export default TagName;
