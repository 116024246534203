import {
  LIST_IMPORT,
  LIST_IMPORT_FAIL,
  LIST_IMPORT_SUCCESS,
} from "./actionType";

export const listImport = (options) => ({
  type: LIST_IMPORT,
  payload: options,
});

export const listImportFail = (error) => ({
  type: LIST_IMPORT_FAIL,
  payload: error,
});

export const listImportSuccess = (imports) => ({
  type: LIST_IMPORT_SUCCESS,
  payload: imports,
});
