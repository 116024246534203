import { Tooltip } from "antd";
import { Col, PopoverBody, Row, UncontrolledPopover } from "reactstrap";
import { productsAPI } from "../../../../../helpers/resource_helper";
import { Fragment, useEffect, useState } from "react";
import Loader from "../../../../../Components/Common/Loader";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { formatVNDCurrency } from "../../../../../helpers/format_helper";

const PopoverVariantInfo = ({ index, row, isEdit, onDeleteById }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const getData = async () => {
    if (row.product_id) {
      try {
        setLoading(true);
        const res = await productsAPI.get({ id: row.product_id });
        setData(res);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Fragment>
      <div className="d-flex align-items-center">
        <img src={data?.images?.[0]?.url || row.variants?.[0]?.images?.[0]?.url} className="me-3 avatar-xs " alt="" />
      </div>
      <div className="flex-grow-1">
        <div className="fs-13">
          <div className="mb-0">{row?.product_id ? data?.name || "---" : row?.variants?.[0]?.name}</div>
          <div className="mb-0 hstack gap-1">
            Variants:{" "}
            <div id={`variant-${index}`} className="text-center">
              <span className="cursor-pointer text-primary">{(row?.variants && row?.variants.length) || 0}</span>
              <UncontrolledPopover
                trigger="hover"
                target={`variant-${index}`}
                placement="bottom"
                // innerClassName="custom-popover"
                popperClassName="custom-popover"
              >
                <PopoverBody>
                  {loading && !data ? (
                    <Loader />
                  ) : (
                    <SimpleBar style={{ maxHeight: "500px" }}>
                      <div>
                        <h5 className="text-center">{row.product_id && data ? data.name : row.variants?.[0]?.name}</h5>
                        {row.variants.map((item, idx) => {
                          const v =
                            row.product_id && data ? data.variants.find((subItem) => subItem.id === item.id) : item;
                          return (
                            <div key={idx} className="hstack p-2 gap-2 border">
                              <div className="d-flex align-items-center flex-shrink-0">
                                <img src={v?.images?.[0]?.url} className="me-3 avatar-xs " alt="" />
                              </div>
                              <div className="flex-grow-1">
                                <p className="mb-0">{v.sku}</p>
                                <Row>
                                  <Col>Option 1: {v.option1 || "---"}</Col>
                                  <Col>Option 2: {v.option2 || "---"}</Col>
                                  <Col>Option 3: {v.option3 || "---"}</Col>
                                </Row>
                              </div>
                              <div className="px-2 flex-shrink-0">
                                <div className="fs-16 text-primary text-end">
                                  {formatVNDCurrency(v.prices?.[0]?.price)}
                                </div>
                              </div>
                              {isEdit && (
                                <button
                                  type="button"
                                  className="btn btn-icon btn-ghost-primary rounded-circle ms-2"
                                  style={{ height: "18px", width: "18px" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    onDeleteById(v.id);
                                  }}
                                >
                                  <i className="ri-close-line text-danger"></i>
                                </button>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </SimpleBar>
                  )}
                </PopoverBody>
              </UncontrolledPopover>
            </div>
          </div>
        </div>
      </div>
      {isEdit && (
        <button
          type="button"
          className="btn btn-icon btn-ghost-primary rounded-circle ms-2"
          style={{ height: "18px", width: "18px" }}
          onClick={(e) => {
            e.stopPropagation();
            onDeleteById(row.variants?.[0].id, row.product_id);
          }}
        >
          <i className="ri-close-line text-danger"></i>
        </button>
      )}
    </Fragment>
  );
};
export default PopoverVariantInfo;
