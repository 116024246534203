// Actions
export const LIST_PRODUCT = "LIST_PRODUCT";
export const LIST_PRODUCT_SUCCESS = "LIST_PRODUCT_SUCCESS";
export const LIST_PRODUCT_FAIL = "LIST_PRODUCT_FAIL";

export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAIL = "GET_PRODUCT_FAIL";

/**
 * Delete PRODUCT
 */
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";

/**
 * Add PRODUCT
 */
export const ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL";

/**
 * Edit PRODUCT
 */
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";

export const RESET_PRODUCT_FLAG = "RESET_PRODUCT_FLAG";
