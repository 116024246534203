import {
  ADD_TEMPLATE_PRINT,
  ADD_TEMPLATE_PRINT_FAIL,
  ADD_TEMPLATE_PRINT_SUCCESS,
  GET_TEMPLATE_PRINT,
  GET_TEMPLATE_PRINT_FAIL,
  GET_TEMPLATE_PRINT_SUCCESS,
  UPDATE_TEMPLATE_PRINT,
  UPDATE_TEMPLATE_PRINT_FAIL,
  UPDATE_TEMPLATE_PRINT_SUCCESS,
} from "./actionType";

const INIT_STATE = {
  templates: [],
  loading: false,
  loadingAction: false,
};

const TemplatePrint = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TEMPLATE_PRINT:
      return {
        ...state,
        loading: true,
      };

    case GET_TEMPLATE_PRINT_SUCCESS:
      return {
        templates: [...action.payload.Items],
        loading: false,
      };

    case GET_TEMPLATE_PRINT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_TEMPLATE_PRINT:
      return {
        ...state,
        loadingAction: true,
      };

    case UPDATE_TEMPLATE_PRINT_SUCCESS:
      return {
        templates: [...state.templates.filter((item) => item.id !== action.payload.id), action.payload],
        loadingAction: false,
      };

    case UPDATE_TEMPLATE_PRINT_FAIL:
      return {
        ...state,
        loadingAction: false,
        error: action.payload,
      };

    case ADD_TEMPLATE_PRINT:
      return {
        ...state,
        loadingAction: true,
      };

    case ADD_TEMPLATE_PRINT_SUCCESS:
      return {
        templates: [...state.templates, action.payload],
        loadingAction: false,
      };

    case ADD_TEMPLATE_PRINT_FAIL:
      return {
        ...state,
        loadingAction: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default TemplatePrint;
