// Actions
export const LIST_BLOG_CATEGORY = "LIST_BLOG_CATEGORY";
export const LIST_BLOG_CATEGORY_SUCCESS = "LIST_BLOG_CATEGORY_SUCCESS";
export const LIST_BLOG_CATEGORY_FAIL = "LIST_BLOG_CATEGORY_FAIL";

export const GET_BLOG_CATEGORY = "GET_BLOG_CATEGORY";
export const GET_BLOG_CATEGORY_SUCCESS = "GET_BLOG_CATEGORY_SUCCESS";
export const GET_BLOG_CATEGORY_FAIL = "GET_BLOG_CATEGORY_FAIL";

/**
 * Delete BLOG_CATEGORY
 */
export const DELETE_BLOG_CATEGORY = "DELETE_BLOG_CATEGORY";
export const DELETE_BLOG_CATEGORY_SUCCESS = "DELETE_BLOG_CATEGORY_SUCCESS";
export const DELETE_BLOG_CATEGORY_FAIL = "DELETE_BLOG_CATEGORY_FAIL";

/**
 * Add BLOG_CATEGORY
 */
export const ADD_NEW_BLOG_CATEGORY = "ADD_NEW_BLOG_CATEGORY";
export const ADD_BLOG_CATEGORY_SUCCESS = "ADD_BLOG_CATEGORY_SUCCESS";
export const ADD_BLOG_CATEGORY_FAIL = "ADD_BLOG_CATEGORY_FAIL";

/**
 * Edit BLOG_CATEGORY
 */
export const UPDATE_BLOG_CATEGORY = "UPDATE_BLOG_CATEGORY";
export const UPDATE_BLOG_CATEGORY_SUCCESS = "UPDATE_BLOG_CATEGORY_SUCCESS";
export const UPDATE_BLOG_CATEGORY_FAIL = "UPDATE_BLOG_CATEGORY_FAIL";
