import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import { formatVNDCurrency, getChartColorsArray } from "../../../../helpers/format_helper";
import ReactApexChart from "react-apexcharts";
import NotFound from "../../../../Components/Common/NotFound";
import Loader from "../../../../Components/Common/Loader";

const SalesByLocations = ({ revenueByLocation = [], loading }) => {
  const chartDonutBasicColors = getChartColorsArray(
    '["--color-primary", "--color-success", "--color-warning", "--color-danger", "--color-info", "--color-dark"]',
  );
  const { t } = useTranslation();
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    labels: [],
    chart: {
      height: 333,
      type: "donut",
    },
    legend: {
      position: "bottom",
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      dropShadow: {
        enabled: true,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return formatVNDCurrency(val);
        },
      },
    },
    colors: chartDonutBasicColors,
  });
  useEffect(() => {
    const newSeries = revenueByLocation.map((item) => item?.revenue || 0);
    const newLabels = revenueByLocation.map((item) => item?.location_name);
    setSeries(newSeries);
    setOptions((pre) => ({ ...pre, labels: newLabels }));
  }, [revenueByLocation]);
  return (
    <React.Fragment>
      <Card className="card-height-100">
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">{t("Revenue by location")}</h4>
        </CardHeader>

        <CardBody>
          {loading ? (
            <Loader />
          ) : series.length === 0 ? (
            <NotFound />
          ) : (
            <div dir="ltr">
              <ReactApexChart
                dir="ltr"
                options={options}
                series={series}
                type="donut"
                height="333"
                className="apex-charts"
              />
            </div>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SalesByLocations;
