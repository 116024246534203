import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { Col, PopoverBody, Row, UncontrolledPopover } from "reactstrap";
import { formatVNDCurrency } from "../../../../helpers/format_helper";
import Decimal from "decimal.js";

const PopoverColumn = ({ field, index, row }) => {
  const { t } = useTranslation();
  return (
    <div id={`${field}-${index}`} className="text-center">
      {(row.inventories || []).reduce((total, item) => total + item[field], 0)}
      <UncontrolledPopover
        trigger="hover"
        target={`${field}-${index}`}
        placement="bottom"
        popperClassName="custom-popover"
      >
        <PopoverBody>
          <Tooltip title={row.name}>
            <h4 className="text-center fw-bold text-truncate-two-lines mb-3">{row.name}</h4>
          </Tooltip>
          <Row>
            <Col lg={(row.inventories || []).length > 0 ? 6 : 12}>
              <h5>{t("Total")}</h5>
              <div className="hstack p-1 border-bottom border-dashed border-start-0 border-top-0 border-end-0">
                <span className="flex-grow-1">{t("On hand")}</span>
                <span>{(row?.inventories || []).reduce((total, item) => total + item.on_hand, 0)}</span>
              </div>
              <div className="hstack p-1 border-bottom border-dashed border-start-0 border-top-0 border-end-0">
                <span className="flex-grow-1">{t("Available")}</span>
                <span>{(row?.inventories || []).reduce((total, item) => total + item.available, 0)}</span>
              </div>
              <div className="hstack p-1 border-bottom border-dashed border-start-0 border-top-0 border-end-0">
                <span className="flex-grow-1">{t("Incoming")}</span>
                <span>{(row?.inventories || []).reduce((total, item) => total + item.incoming, 0)}</span>
              </div>
              <div className="hstack p-1 border-bottom border-dashed border-start-0 border-top-0 border-end-0">
                <span className="flex-grow-1">{t("Packing")}</span>
                <span>{(row?.inventories || []).reduce((total, item) => total + item.packing, 0)}</span>
              </div>
              <div className="hstack p-1 border-bottom border-dashed border-start-0 border-top-0 border-end-0">
                <span className="flex-grow-1">{t("Shipping")}</span>
                <span>{(row?.inventories || []).reduce((total, item) => total + item.shipping, 0)}</span>
              </div>
              <div className="hstack p-1">
                <span className="flex-grow-1">{t("Cost")}</span>
                <span>
                  {formatVNDCurrency(
                    (row?.inventories || []).reduce((total, item) => total + item.cost, 0) /
                      (row?.inventories || []).length,
                  )}
                </span>
              </div>
            </Col>
            {(row.inventories || []).map((item, index) => (
              <Col lg={6} key={index}>
                <h5>
                  {item?.location_name ? (
                    t(item?.location_name)
                  ) : (
                    <Tooltip title={item.location_id}>
                      {t("Branch")} {index + 1}
                    </Tooltip>
                  )}
                </h5>
                <div className="hstack p-1 border-bottom border-dashed border-start-0 border-top-0 border-end-0">
                  <span className="flex-grow-1">{t("On hand")}</span>
                  <span>{item?.on_hand || 0}</span>
                </div>
                <div className="hstack p-1 border-bottom border-dashed border-start-0 border-top-0 border-end-0">
                  <span className="flex-grow-1">{t("Available")}</span>
                  <span>{item?.available || 0}</span>
                </div>
                <div className="hstack p-1 border-bottom border-dashed border-start-0 border-top-0 border-end-0">
                  <span className="flex-grow-1">{t("Incoming")}</span>
                  <span>{item?.incoming || 0}</span>
                </div>
                <div className="hstack p-1 border-bottom border-dashed border-start-0 border-top-0 border-end-0">
                  <span className="flex-grow-1">{t("Packing")}</span>
                  <span>{item?.packing || 0}</span>
                </div>
                <div className="hstack p-1 border-bottom border-dashed border-start-0 border-top-0 border-end-0">
                  <span className="flex-grow-1">{t("Shipping")}</span>
                  <span>{item?.shipping || 0}</span>
                </div>
                <div className="hstack p-1">
                  <span className="flex-grow-1">{t("Cost")}</span>
                  <span>{formatVNDCurrency(item?.cost || 0)}</span>
                </div>
              </Col>
            ))}
          </Row>
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
};
export default PopoverColumn;
