import {
  LIST_LOCATION,
  DELETE_LOCATION,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAIL,
  ADD_NEW_LOCATION,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_FAIL,
  UPDATE_LOCATION,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAIL,
  LIST_LOCATION_FAIL,
  LIST_LOCATION_SUCCESS,
  GET_LOCATION,
  GET_LOCATION_FAIL,
  GET_LOCATION_SUCCESS,
} from "./actionType";

export const listLocation = (options) => ({
  type: LIST_LOCATION,
  payload: options,
});

export const listLocationFail = (error) => ({
  type: LIST_LOCATION_FAIL,
  payload: error,
});

export const listLocationSuccess = (locations) => ({
  type: LIST_LOCATION_SUCCESS,
  payload: locations,
});

export const getLocation = (locationID) => ({
  type: GET_LOCATION,
  payload: locationID,
});

export const getLocationFail = (error) => ({
  type: GET_LOCATION_FAIL,
  payload: error,
});

export const getLocationSuccess = (location) => ({
  type: GET_LOCATION_SUCCESS,
  payload: location,
});

export const deleteLocation = (location) => ({
  type: DELETE_LOCATION,
  payload: location,
});

export const deleteLocationSuccess = (location) => ({
  type: DELETE_LOCATION_SUCCESS,
  payload: location,
});

export const deleteLocationFail = (error) => ({
  type: DELETE_LOCATION_FAIL,
  payload: error,
});

export const updateLocation = (location) => ({
  type: UPDATE_LOCATION,
  payload: location,
});

export const updateLocationSuccess = (location) => ({
  type: UPDATE_LOCATION_SUCCESS,
  payload: location,
});

export const updateLocationFail = (error) => ({
  type: UPDATE_LOCATION_FAIL,
  payload: error,
});

export const addNewLocation = (location) => ({
  type: ADD_NEW_LOCATION,
  payload: location,
});

export const addLocationSuccess = (location) => ({
  type: ADD_LOCATION_SUCCESS,
  payload: location,
});

export const addLocationFail = (error) => ({
  type: ADD_LOCATION_FAIL,
  payload: error,
});
