import { Fragment, useEffect, useState } from "react";
import { Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { formatVNDCurrency } from "../../helpers/format_helper";
import ButtonTheme from "./ButtonTheme";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import InputTheme from "./InputTheme";

const AddFeesModal = ({ isOpen, toggle, isEdit, fees = null, handleChangeFees }) => {
  const { t } = useTranslation();
  const [feeList, setFeeList] = useState([{ name: "", amount: 0 }]);

  useEffect(() => {
    if (fees) {
      setFeeList(fees);
    }
  }, [fees]);

  const handleApply = () => {
    const hasDuplicateNames = feeList.some(
      (item, index) => item.name === "" || feeList.findIndex((f) => f.name === item.name) !== index,
    );

    if (!hasDuplicateNames) {
      handleChangeFees(feeList.length > 0 ? feeList : null);
      toggle();
    } else {
      toast.error(t("Required different fee name!"));
    }
  };

  const handleDeleteFee = (i) => {
    if (feeList.length > 1) {
      const newData = feeList.filter((_, index) => index !== i);
      setFeeList(newData);
    } else {
      setFeeList([{ name: "", amount: 0 }]);
    }
  };

  const handleAddFee = () => {
    if (feeList.find((item) => !item.name)) {
      toast.error(t("Required fee name!"));
    } else {
      setFeeList([...feeList, { name: "", amount: 0 }]);
    }
  };

  const handleChangeFee = (data, field, i) => {
    setFeeList(
      feeList.map((item, index) => {
        if (index === i) {
          item[field] = data;
        }
        return item;
      }),
    );
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{t(isEdit ? "Edit fees" : "Add fees")}</ModalHeader>
      <ModalBody>
        {feeList.map((fee, index) => (
          <Fragment key={index}>
            <FeeRow index={index} fee={fee} handleChangeFee={handleChangeFee} handleDeleteFee={handleDeleteFee} />
          </Fragment>
        ))}
        <Row className="mb-3">
          <Col>
            <ButtonTheme className="btn btn-ghost-primary p-0 border-0" onClick={handleAddFee}>
              <i className="ri-add-circle-line align-bottom me-2"></i>
              {t("Add fee")}
            </ButtonTheme>
          </Col>
        </Row>
        <Row className="border-0 border-dashed border-top border-bottom py-3">
          <Col></Col>
          <Col>
            <div className="hstack mb-2">
              <span className="flex-grow-1">{t("Total")}:</span>
              <span className="flex-shrink-0 text-end">
                {formatVNDCurrency(feeList.reduce((total, item) => total + Number(item.amount), 0))}
              </span>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <ButtonTheme className="btn btn-light w-md" onClick={toggle}>
          {t("Close")}
        </ButtonTheme>
        <ButtonTheme className="btn btn-primary w-md" onClick={handleApply}>
          {t("Apply")}
        </ButtonTheme>
      </ModalFooter>
    </Modal>
  );
};

const FeeRow = ({ index, fee, handleChangeFee, handleDeleteFee }) => {
  const { t } = useTranslation();
  return (
    <Row className="hstack mb-3" key={index}>
      <Col>
        {index === 0 && (
          <Label>
            {t("Fee name")} <span className="text-danger">*</span>
          </Label>
        )}
        <Input
          placeholder={t("Enter fee name")}
          value={fee.name}
          onChange={(e) => {
            handleChangeFee(e.target.value, "name", index);
          }}
        ></Input>
      </Col>
      <Col>
        {index === 0 && <Label>{t("Value")}</Label>}
        <InputTheme
          isForm={false}
          format="price"
          min={0}
          className="text-end"
          value={fee.amount}
          onChange={(value) => {
            let data = isNaN(value) ? 0 : value;
            handleChangeFee(data, "amount", index);
          }}
        />
      </Col>
      <ButtonTheme
        type="button"
        onClick={() => handleDeleteFee(index)}
        className="btn btn-icon btn-ghost-danger waves-effect waves-light rounded-circle light-dark-mode align-self-end"
      >
        <i className="ri-close-line fs-22"></i>
      </ButtonTheme>
    </Row>
  );
};

export default AddFeesModal;
