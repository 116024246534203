import { Label, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { DateDropDown, FilterItemDropDown } from "../../../../Components/Common/FilterComponent";
import { formatDate1 } from "../../../../helpers/format_helper";
import { useEffect, useState } from "react";

const FilterOffcanvas = ({ open, title, toggleDrawer, t, filter, onChangeFilter }) => {
  const listFilter = [
    {
      id: "category.id",
      type: "selectBox",
      title: "Category",
      remote: true,
      pathUrlLoad: "product/categories",
    },
    {
      id: "brand.id",
      type: "selectBox",
      title: "Brand",
      remote: true,
      pathUrlLoad: "product/brands",
    },
    {
      id: "created_at",
      type: "date",
      title: "Created date",
    },
    {
      id: "updated_at",
      type: "date",
      title: "Updated date",
    },
  ];
  const [payloadFilter, setPayloadFilter] = useState({});
  useEffect(() => {
    open && setPayloadFilter(filter);
  }, [open]);
  return (
    <Offcanvas isOpen={open} direction="end" className="offcanvas-end border-0" toggle={toggleDrawer}>
      <OffcanvasHeader
        className="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header-dark"
        toggle={toggleDrawer}
      >
        <span className="m-0 me-2 text-white">{t(title)}</span>
      </OffcanvasHeader>
      <OffcanvasBody>
        {listFilter.map((item, index) => {
          let startDateKey = item.id + "_from";
          let endDateKey = item.id + "_to";
          return (
            <div key={index} className="mb-3">
              <Label className="fw-semibold">{t(item.title)}</Label>
              {item?.type !== "date" ? (
                <FilterItemDropDown
                  t={t}
                  rounded={`rounded`}
                  dataOption={item?.dataOption || []}
                  title={item?.title}
                  dataCheck={payloadFilter?.[item.id]}
                  remote={item.remote}
                  pathUrlLoad={item.pathUrlLoad}
                  inOtherFilters={true}
                  type={item?.type}
                  onSaveTempFilters={(dataCheck) => {
                    setPayloadFilter((dataPayload) => {
                      const newData = { ...dataPayload };
                      if (dataCheck.length > 0) {
                        newData[item.id] = dataCheck.map((i) => i?.id).join();
                      } else {
                        delete newData[item.id];
                      }
                      return newData;
                    });
                  }}
                />
              ) : (
                <DateDropDown
                  t={t}
                  title={item?.title}
                  rounded={`rounded`}
                  inOtherFilters={true}
                  isOtherFiltersOpen={true}
                  onSaveTempFilters={(data) => {
                    setPayloadFilter((dataPayload) => {
                      const newData = { ...dataPayload };
                      if (data && data.length > 0) {
                        newData[startDateKey] = data?.[0] && data?.[0] ? formatDate1(data[0].toISOString()) : "";
                        newData[endDateKey] = data?.[1] && data?.[1] ? formatDate1(data[1].toISOString()) : "";
                      } else {
                        delete newData[startDateKey];
                        delete newData[endDateKey];
                      }
                      return newData;
                    });
                  }}
                  savedStartDate={payloadFilter?.[startDateKey] ? new Date(payloadFilter[startDateKey]) : null}
                  savedEndDate={payloadFilter?.[endDateKey] ? new Date(payloadFilter[endDateKey]) : null}
                  payload={payloadFilter}
                />
              )}
            </div>
          );
        })}
      </OffcanvasBody>
      <div className="offcanvas-footer border-top p-3 text-center">
        <div className="d-flex">
          <div style={{ flex: 1 }}>
            <ButtonTheme
              type="button"
              className="btn btn-outline-black "
              onClick={() => {
                setPayloadFilter({});
              }}
            >
              {t("Clear Filters")}
            </ButtonTheme>
          </div>
          <div style={{ flex: 2 }}>
            <ButtonTheme
              type="button"
              className="btn btn-primary w-100"
              onClick={() => {
                onChangeFilter(payloadFilter);
                toggleDrawer();
              }}
            >
              {t("Filter")}
            </ButtonTheme>
          </div>
        </div>
      </div>
    </Offcanvas>
  );
};
export default FilterOffcanvas;
