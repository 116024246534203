import { Avatar } from "antd";
import React from "react";

export default function AvatarTheme({ name = "" }) {
  let chart;
  const arrName = name.split(" ");
  if (arrName.length === 1) {
    chart = arrName[0].charAt(0);
  } else if (arrName.length === 2) {
    chart = arrName[0].charAt(0) + arrName[1].charAt(0);
  } else {
    chart = arrName[0].charAt(0) + arrName[arrName.length].charAt(0);
  }
  return (
    <div>
      <Avatar>{chart}</Avatar>
    </div>
  );
}
