import { Image, Popover } from "antd";
import { Fragment, useState } from "react";
const ImageCustom = ({ image, name, avatarSize, style, rounded = "rounded", images = [], isPreview = true }) => {
  const [imgError, setImgError] = useState(false);
  const handleImgError = () => {
    setImgError(true);
  };
  return (
    <Fragment>
      {image && !imgError ? (
        // <Image width={200} src={image} />
        <div className={`${avatarSize ? avatarSize : ""} ${rounded} position-relative bg-light`} style={style}>
          {isPreview ? (
            <Popover
              content={
                <img
                  className={`${rounded}`}
                  src={image}
                  alt=""
                  style={{ width: "400px", height: "400px", objectFit: "contain" }}
                />
              }
              title=""
              placement="right"
            >
              <img
                className={`${rounded}`}
                src={image}
                alt=""
                onError={handleImgError}
                style={{ width: "100%", height: "100%", objectFit: "contain", ...style }}
              />
            </Popover>
          ) : (
            <img
              className={`${rounded}`}
              src={image}
              alt=""
              onError={handleImgError}
              style={{ width: "100%", height: "100%", objectFit: "contain", ...style }}
            />
          )}
        </div>
      ) : (
        <div className={`${avatarSize ? avatarSize : ""}`} style={style}>
          <div className={`avatar-title ${rounded} bg-soft-primary text-primary`}>
            {name ? name.toUpperCase().charAt(0) : ""}
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default ImageCustom;
