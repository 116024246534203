import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

// Formik
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import useMemoizedSelector from "../../../../../Components/Hooks/useMemoizedSelector";
import { addNewProduct, listPriceGroup } from "../../../../../store/actions";
import InputTheme from "../../../../../Components/Common/InputTheme";
import UploadTheme from "../../../../../Components/Common/UploadTheme";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import { Actions } from "../../../../../Components/constants/common";
import ScrollToError from "../../../../../Components/Common/ScrollToError";
import SelectTheme from "../../../../../Components/Common/SelectTheme";

const QuickAddProductModal = ({ show, toggle }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { productsLoading, productsAction, productsSuccess } = useMemoizedSelector((state) => ({
    productsLoading: state.Products.loading,
    productsAction: state.Products.action,
    productsSuccess: state.Products.success,
  }));
  const {
    priceGroups,
    priceGroupsAction,
    priceGroupsLoading,
    priceGroupMessage,
    priceGroupsSuccess,
    priceGroupsError,
  } = useMemoizedSelector((state) => ({
    priceGroups: state.PriceGroups.priceGroups,
    priceGroupsAction: state.PriceGroups.action,
    priceGroupsLoading: state.PriceGroups.loading,
    priceGroupsSuccess: state.PriceGroups.success,
    priceGroupsError: state.PriceGroups.error,
    priceGroupMessage: state.PriceGroups.message,
  }));

  // validation
  const validation = {
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      sku: null,
      brand: null,
      category: null,
      publish: false,
      images: null,
      tags: null,
      prices: null,
      variants: [],
      measurements: {
        weight_value: 0,
        weight_unit: "g",
      },
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Please enter product name")),
      sku: Yup.string().required(t("Please enter product sku")),
    }),
    onSubmit: (values, actions) => {
      if (values.images) {
        values.images = [values.images];
      }
      const prices = priceGroups.map((item) => {
        return { price: values.prices, price_group: { name: item.name, id: item.id } };
      });
      values.prices = prices;
      dispatch(
        addNewProduct(
          {
            ...values,
          },
          (data) => {
            actions.resetForm();
            handleClose();
          },
        ),
      );
    },
  };

  const handleClose = () => {
    if (!productsLoading) {
      toggle();
    }
  };

  useEffect(() => {
    show && dispatch(listPriceGroup({}));
  }, [show]);
  const formRef = useRef();
  return (
    <Modal fade={true} isOpen={show} toggle={handleClose} centered={true}>
      <ModalHeader className="bg-light p-3" toggle={handleClose}>
        {t("Quickly add new product")}
      </ModalHeader>
      <ModalBody>
        <Formik
          enableReinitialize={true}
          initialValues={validation.initialValues}
          validationSchema={validation.validationSchema}
          onSubmit={validation.onSubmit}
          innerRef={formRef}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <ScrollToError />
              <InputTheme name={"name"} label={"Name"} placeholder={t("Enter product name")} />
              <InputTheme name={"sku"} label={"Sku"} placeholder={t("Enter product sku")} />
              <UploadTheme label="Image" name="images" />
              <InputTheme name={"prices"} label={"Price"} placeholder={t("Enter product price")} format="price" />
              <SelectTheme
                label={"Brand"}
                name="brand"
                placeholder={"Select product brand"}
                remote={true}
                isObjectValueSelect={true}
                selectDataOrigin={true}
                path="product/brands"
              />
              <SelectTheme
                label={"Category"}
                name="category"
                placeholder={"Select product category"}
                remote={true}
                isObjectValueSelect={true}
                selectDataOrigin={true}
                path="product/categories"
              />
              <InputTheme label={"Publish"} name="publish" type="checkbox" classWrapper="mt-3" />
              <InputTheme name={"tags"} label={"Tags"} type="tags" placeholder={"Enter tags and press enter"} />
            </Form>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>
        <div className="hstack gap-2 justify-content-end">
          <ButtonTheme type="button" className="btn btn-light w-sm" onClick={handleClose}>
            {t("Close")}
          </ButtonTheme>
          <ButtonTheme
            onClick={() => {
              formRef.current.handleSubmit();
            }}
            className="btn btn-primary w-sm"
            loading={productsLoading && productsAction === Actions.CREATE}
            disabled={productsLoading && productsAction === Actions.CREATE}
          >
            {t("Add")}
          </ButtonTheme>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default QuickAddProductModal;
