import {
  LIST_PURCHASE_ORDER,
  LIST_PURCHASE_ORDER_FAIL,
  LIST_PURCHASE_ORDER_SUCCESS,
  DELETE_PURCHASE_ORDER,
  DELETE_PURCHASE_ORDER_SUCCESS,
  DELETE_PURCHASE_ORDER_FAIL,
  ADD_NEW_PURCHASE_ORDER,
  ADD_PURCHASE_ORDER_SUCCESS,
  ADD_PURCHASE_ORDER_FAIL,
  UPDATE_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER_SUCCESS,
  UPDATE_PURCHASE_ORDER_FAIL,
  GET_PURCHASE_ORDER,
  GET_PURCHASE_ORDER_FAIL,
  GET_PURCHASE_ORDER_SUCCESS,
  RESET_PURCHASE_ORDER_FLAG,
} from "./actionType";

export const listPurchaseOrder = (options) => ({
  type: LIST_PURCHASE_ORDER,
  payload: options,
});

export const listPurchaseOrderFail = (error) => ({
  type: LIST_PURCHASE_ORDER_FAIL,
  payload: error,
});

export const listPurchaseOrderSuccess = (purchase_orders) => ({
  type: LIST_PURCHASE_ORDER_SUCCESS,
  payload: purchase_orders,
});

export const getPurchaseOrder = (purchase_orderID) => ({
  type: GET_PURCHASE_ORDER,
  payload: purchase_orderID,
});

export const getPurchaseOrderFail = (error) => ({
  type: GET_PURCHASE_ORDER_FAIL,
  payload: error,
});

export const getPurchaseOrderSuccess = (order) => ({
  type: GET_PURCHASE_ORDER_SUCCESS,
  payload: order,
});

export const deletePurchaseOrder = (purchase_order, handleAfterSubmit = () => {}) => ({
  type: DELETE_PURCHASE_ORDER,
  payload: { purchase_order, handleAfterSubmit },
});

export const deletePurchaseOrderSuccess = (purchase_order) => ({
  type: DELETE_PURCHASE_ORDER_SUCCESS,
  payload: purchase_order,
});

export const deletePurchaseOrderFail = (error) => ({
  type: DELETE_PURCHASE_ORDER_FAIL,
  payload: error,
});

export const updatePurchaseOrder = (purchase_order, handleAfterSubmit = () => {}) => ({
  type: UPDATE_PURCHASE_ORDER,
  payload: { purchase_order, handleAfterSubmit },
});

export const updatePurchaseOrderSuccess = (purchase_order) => ({
  type: UPDATE_PURCHASE_ORDER_SUCCESS,
  payload: purchase_order,
});

export const updatePurchaseOrderFail = (error) => ({
  type: UPDATE_PURCHASE_ORDER_FAIL,
  payload: error,
});

export const addNewPurchaseOrder = (purchase_order, handleAfterSubmit = () => {}) => ({
  type: ADD_NEW_PURCHASE_ORDER,
  payload: { purchase_order, handleAfterSubmit },
});

export const addPurchaseOrderSuccess = (purchase_order) => ({
  type: ADD_PURCHASE_ORDER_SUCCESS,
  payload: purchase_order,
});

export const addPurchaseOrderFail = (error) => ({
  type: ADD_PURCHASE_ORDER_FAIL,
  payload: error,
});

export const resetPurchaseOrderFlag = () => ({
  type: RESET_PURCHASE_ORDER_FLAG,
});
