// Actions
export const LIST_VARIANT = "LIST_VARIANT";
export const LIST_VARIANT_SUCCESS = "LIST_VARIANT_SUCCESS";
export const LIST_VARIANT_FAIL = "LIST_VARIANT_FAIL";

export const GET_VARIANT = "GET_VARIANT";
export const GET_VARIANT_SUCCESS = "GET_VARIANT_SUCCESS";
export const GET_VARIANT_FAIL = "GET_VARIANT_FAIL";

/**
 * Delete VARIANT
 */
export const DELETE_VARIANT = "DELETE_VARIANT";
export const DELETE_VARIANT_SUCCESS = "DELETE_VARIANT_SUCCESS";
export const DELETE_VARIANT_FAIL = "DELETE_VARIANT_FAIL";

/**
 * Add VARIANT
 */
export const ADD_NEW_VARIANT = "ADD_NEW_VARIANT";
export const ADD_VARIANT_SUCCESS = "ADD_VARIANT_SUCCESS";
export const ADD_VARIANT_FAIL = "ADD_VARIANT_FAIL";

/**
 * Edit VARIANT
 */
export const UPDATE_VARIANT = "UPDATE_VARIANT";
export const UPDATE_VARIANT_SUCCESS = "UPDATE_VARIANT_SUCCESS";
export const UPDATE_VARIANT_FAIL = "UPDATE_VARIANT_FAIL";

export const RESET_VARIANT_FLAG = "RESET_VARIANT_FLAG";
