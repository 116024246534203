import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// Formik
import * as Yup from "yup";
import { Form, Formik, useFormik } from "formik";
import { FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { addNewCategory, updateCategory } from "../../../../store/actions";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { Actions } from "../../../../Components/constants/common";
import UploadTheme from "../../../../Components/Common/UploadTheme";
import InputTheme from "../../../../Components/Common/InputTheme";
import SelectTheme from "../../../../Components/Common/SelectTheme";
import ScrollToError from "../../../../Components/Common/ScrollToError";

const EditAddCategoryModal = ({
  show,
  toggle,
  isEdit,
  category,
  nodeId,
  childrenNodeKeys,
  onChangeCategory = () => {},
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { categories, categoriesAction, categoriesLoading, categoriesSuccess, categoriesError } = useMemoizedSelector(
    (state) => ({
      categories: state.Categories.categories,
      categoriesLoading: state.Categories.loading,
      categoriesAction: state.Categories.action,
      categoriesSuccess: state.Categories.success,
      categoriesError: state.Categories.error,
    }),
  );

  const validation = {
    enableReinitialize: true,
    initialValues: {
      name: isEdit ? category?.name : "",
      parent_category_id: isEdit ? category?.parent_category_id : "",
      image: isEdit ? category?.image : null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Please Enter Category Name")),
    }),
    onSubmit: (values) => {
      const payload = {
        ...values,
      };
      if (isEdit) {
        payload.id = category.id;
        dispatch(
          updateCategory(payload, () => {
            toggle();
          }),
        );
      } else {
        if (nodeId) {
          payload.parent_category_id = nodeId;
        }
        dispatch(
          addNewCategory(payload, (data) => {
            onChangeCategory(data);
            toggle();
          }),
        );
      }
    },
  };
  const handleClose = () => {
    if (!categoriesLoading) {
      toggle();
    }
  };
  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(() => {
    if (isEdit && category) {
      setCategoryOptions(
        categories.filter((item) => item.id !== category.id).filter((item) => !childrenNodeKeys.includes(item.id)),
      );
    } else {
      setCategoryOptions(categories);
    }
  }, [categories, category, isEdit]);

  const formRef = useRef();
  return (
    <Modal fade={true} isOpen={show} toggle={handleClose} centered={true}>
      <ModalHeader className="bg-light p-3">
        {t(isEdit ? t("Edit category") : nodeId ? t("New child category") : t("New category"))}
      </ModalHeader>

      <ModalBody>
        <Formik
          enableReinitialize={true}
          initialValues={validation.initialValues}
          validationSchema={validation.validationSchema}
          onSubmit={validation.onSubmit}
          innerRef={formRef}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <ScrollToError />
              <InputTheme name={"name"} label={"Category Name"} placeholder={t("Enter Category Name")} />
              {nodeId ? (
                <>
                  <Label htmlFor="parent-category-id-field" className="form-label">
                    {t("Category Parent")}
                  </Label>
                  <Input
                    className="form-control"
                    readOnly
                    value={categoryOptions.find((item) => item.id === nodeId)?.name}
                  ></Input>
                </>
              ) : (
                <SelectTheme
                  name="parent_category_id"
                  label="Category Parent"
                  remote={true}
                  path="product/categories"
                />
              )}
              <UploadTheme label="Image" name="image" />
            </Form>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>
        <div className="hstack gap-2 justify-content-end">
          <ButtonTheme type="button" className="btn btn-light w-sm" onClick={handleClose}>
            {t("Close")}
          </ButtonTheme>
          <ButtonTheme
            className="btn btn-primary w-sm"
            loading={categoriesLoading && (categoriesAction === Actions.UPDATE || categoriesAction === Actions.CREATE)}
            disabled={categoriesLoading && (categoriesAction === Actions.UPDATE || categoriesAction === Actions.CREATE)}
            onClick={() => {
              formRef.current.handleSubmit();
            }}
          >
            {t(isEdit ? "Update" : "Add")}
          </ButtonTheme>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default EditAddCategoryModal;
