import { useState } from "react";
import { useDispatch } from "react-redux";

import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { Tooltip } from "antd";

import { convertValidDate, convertValidTime } from "../../../../helpers/format_helper";

import withRouter from "../../../../Components/Common/withRouter";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import BadgeCustom from "../../../../Components/Common/BadgeCustom";
import { Actions, ImportStatusOptions, ImportTypeOptions, ImportTypes } from "../../../../Components/constants/common";

import { listImport } from "../../../../store/actions";

const ImportsPage = (props) => {
  document.title = "Import List | OptiWarehouse";
  const {
    router: { navigate, location, params, t },
  } = props;
  const dispatch = useDispatch();

  const {
    imports,
    importsLoading,
    importsAction,
    importsSuccess,
    importsError,
    importsLimit,
    importsPage,
    importsTotal,
    importsMessage,
  } = useMemoizedSelector((state) => ({
    imports: state.Imports.imports,
    importsAction: state.Imports.action,
    importsMessage: state.Imports.message,
    importsLoading: state.Imports.loading,
    importsSuccess: state.Imports.success,
    importsError: state.Imports.error,
    importsLimit: state.Imports.limit,
    importsPage: state.Imports.page,
    importsTotal: state.Imports.total,
  }));

  const [isExportCSV, setIsExportCSV] = useState(false);

  const headers = [
    "id",
    "import_type",
    "failed",
    "success",
    "skipped",
    "total",
    "processed",
    "message",
    "imported_at",
    "finished_at",
    "status",
  ];

  const defaultSaveFilter = [
    {
      name: (
        <>
          <i className="ri-store-2-line me-1 align-bottom"></i> {t("All")}
        </>
      ),
      filter: {},
    },
    {
      name: (
        <>
          <i className="ri-store-2-line me-1 align-bottom"></i> {t("Product")}
        </>
      ),
      filter: { import_type: "product" },
    },
    {
      name: (
        <>
          <i className="ri-file-list-3-line me-1 align-bottom"></i> {t("Order")}
        </>
      ),
      filter: { import_type: "order" },
    },
    {
      name: (
        <>
          <i className="ri-article-line me-1 align-bottom"></i> {t("Purchase Order")}
        </>
      ),
      filter: { import_type: "purchase_order" },
    },
    {
      name: (
        <>
          <i className="ri-user-3-line me-1 align-bottom"></i> {t("Customer")}
        </>
      ),
      filter: { import_type: "customer" },
    },
    {
      name: (
        <>
          <i className="ri-user-3-line me-1 align-bottom"></i> {t("Supplier")}
        </>
      ),
      filter: { import_type: "supplier" },
    },
  ];

  return (
    <div className="page-content">
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={imports} />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="purchase-order-list">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("Import List")}</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-3 flex-wrap">
                      <ButtonTheme
                        type="button"
                        className="btn btn-light"
                        icon="ri-file-text-line"
                        onClick={() => setIsExportCSV(true)}
                      >
                        {t("Export")}
                      </ButtonTheme>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <TableContainerCustom
                  filterType={"imports"}
                  searchPlaceHolder="Search import..."
                  listFilter={[
                    {
                      id: "import_type",
                      type: "selectBox",
                      title: "Type",
                      dataOption: ImportTypeOptions,
                    },
                    {
                      id: "status",
                      type: "selectBox",
                      title: "Status",
                      dataOption: ImportStatusOptions,
                    },
                    {
                      id: "imported_at",
                      type: "date",
                      title: "Imported at",
                    },
                    {
                      id: "finished_at",
                      type: "date",
                      title: "Finished at",
                    },
                  ]}
                  defaultDateFilter={false}
                  defaultSaveFilter={defaultSaveFilter}
                  headers={headers}
                  contents={imports}
                  limitPage={Number(importsLimit)}
                  currentPage={Number(importsPage) + 1}
                  totalItems={importsTotal}
                  loading={importsLoading && importsAction === Actions.LIST}
                  showPagination={true}
                  scrollHeight="50vh"
                  onCallData={(dataPayload) => {
                    dispatch(listImport(dataPayload));
                  }}
                  defaultPayload={{ limit: 20, sort_imported_at: "desc" }}
                  isSettings={true}
                  listHeader={[
                    {
                      title: "Thông tin nhập",
                      key: "detail-variant",
                      children: [
                        {
                          title: "ID",
                          dataIndex: "id",
                          key: "id",
                          render: (value, row, index) => {
                            return (
                              <Tooltip title={value}>
                                <div className="fw-medium text-truncate d-block">{value}</div>
                              </Tooltip>
                            );
                          },
                        },
                        {
                          title: "Import Type",
                          dataIndex: "import_type",
                          key: "import_type",
                          render: (value, row, index) => {
                            const data = ImportTypes?.[value] || "---";
                            return <div className="d-block text-truncate">{t(data)}</div>;
                          },
                        },
                        {
                          title: "Success",
                          dataIndex: "success",
                          key: "success",
                          render: (value, row, index) => {
                            const success = row.processed - row.failed;
                            return <>{success || 0}</>;
                          },
                        },
                        {
                          title: "Skipped",
                          dataIndex: "skipped",
                          key: "skipped",
                          render: (value, row, index) => {
                            return <>{value || 0}</>;
                          },
                        },
                        {
                          title: "Failed",
                          dataIndex: "failed",
                          key: "failed",
                          render: (value, row, index) => {
                            return <>{value || 0}</>;
                          },
                        },
                        {
                          title: "Total",
                          dataIndex: "total",
                          key: "total",
                          render: (value, row, index) => {
                            return <>{value || 0}</>;
                          },
                        },
                        {
                          title: "Processed",
                          dataIndex: "processed",
                          key: "processed",
                          render: (value, row, index) => {
                            return <>{value || 0}</>;
                          },
                        },
                        {
                          title: "Message",
                          dataIndex: "message",
                          key: "message",
                          render: (value, row, index) => {
                            return (
                              <Tooltip title={value}>
                                <div className="fw-medium text-truncate d-block">{value || "---"}</div>
                              </Tooltip>
                            );
                          },
                        },
                        {
                          title: "Imported At",
                          dataIndex: "imported_at",
                          key: "imported_at",
                          defaultSortOrder: "desc",
                          sorter: true,
                          render: (value, row, index) => {
                            return (
                              <>
                                {convertValidDate(value)},
                                <small className="text-muted"> {convertValidTime(value)}</small>
                              </>
                            );
                          },
                        },
                        {
                          title: "Finished At",
                          dataIndex: "finished_at",
                          key: "finished_at",
                          sorter: true,
                          render: (value, row, index) => {
                            return (
                              <>
                                {value ? (
                                  <>
                                    {convertValidDate(value)},
                                    <small className="text-muted"> {convertValidTime(value)}</small>
                                  </>
                                ) : (
                                  "---"
                                )}
                              </>
                            );
                          },
                        },
                        {
                          title: "Status",
                          dataIndex: "status",
                          key: "status",
                          sorter: true,
                          render: (value, row, index) => (
                            <>
                              <BadgeCustom status={value} />
                            </>
                          ),
                        },
                      ],
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(ImportsPage);
