import { useState } from "react";
import { useDispatch } from "react-redux";

import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

import { convertValidDate, convertValidTime } from "../../../../helpers/format_helper";

import withRouter from "../../../../Components/Common/withRouter";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { StockAdjustmentStatusOptions } from "../../../../Components/constants/common";

import { listStockAdjustment } from "../../../../store/actions";
import { Link } from "react-router-dom";
import BadgeCustom from "../../../../Components/Common/BadgeCustom";

const StockAdjustmentsPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Stock Adjustment List")} | OptiWarehouse`;
  const dispatch = useDispatch();

  const {
    stockAdjustments,
    stockAdjustmentsLoading,
    stockAdjustmentsMessage,
    stockAdjustmentsError,
    stockAdjustmentsLimit,
    stockAdjustmentsTotal,
    stockAdjustmentsPage,
  } = useMemoizedSelector((state) => ({
    stockAdjustments: state.StockAdjustments.stockAdjustments,
    stockAdjustmentsLoading: state.StockAdjustments.loading,
    stockAdjustmentsSuccess: state.StockAdjustments.success,
    stockAdjustmentsError: state.StockAdjustments.error,
    stockAdjustmentsLimit: state.StockAdjustments.limit,
    stockAdjustmentsTotal: state.StockAdjustments.total,
    stockAdjustmentsPage: state.StockAdjustments.page,
  }));

  const [isExportCSV, setIsExportCSV] = useState(false);

  const headers = [
    {
      title: t("Code"),
      dataIndex: "code",
      key: "code",
      render: (value, row, index) => {
        return <Link to={`/stock-adjustments/${row.id}`}>{value}</Link>;
      },
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (value, row, index) => (
        <>
          <BadgeCustom status={value} />
        </>
      ),
    },
    {
      title: t("Created at"),
      dataIndex: "created_at",
      key: "created_at",
      defaultSortOrder: "desc",
      sorter: true,
    },
    {
      title: t("Balanced at"),
      dataIndex: "balanced_at",
      key: "updated_at",
      sorter: true,
    },
    {
      title: t("Creating staff"),
      dataIndex: "creating_staff",
      key: "creating_staff",
      render: (value, row, index) => {
        return <>{value?.name || "---"}</>;
      },
    },
    {
      title: t("Checking staff"),
      dataIndex: "checking_staff",
      key: "checking_staff",
      render: (value, row, index) => {
        return <>{value?.name || "---"}</>;
      },
    },
    {
      title: t("Balancing staff"),
      dataIndex: "balancing_staff",
      key: "balancing_staff",
      render: (value, row, index) => {
        return <>{value?.name || "---"}</>;
      },
    },
    {
      title: t("Note"),
      dataIndex: "note",
      key: "note",
      render: (value, row, index) => {
        return <div className="text-truncate">{value || "---"}</div>;
      },
    },
  ];

  return (
    <div className="page-content">
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={stockAdjustments} />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="stockAdjustment-list">
              <CardHeader className="border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("Stock Adjustment List")}</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-3 flex-wrap">
                      <ButtonTheme
                        type="button"
                        className="btn btn-light"
                        icon="ri-file-text-line"
                        onClick={() => setIsExportCSV(true)}
                      >
                        {t("Export")}
                      </ButtonTheme>
                      <ButtonTheme
                        className="btn btn-primary"
                        id="create-btn"
                        icon="ri-add-line text-white"
                        onClick={() => {
                          navigate("/stock-adjustments/new");
                        }}
                      >
                        {t("Add Stock Adjustment")}
                      </ButtonTheme>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <TableContainerCustom
                  filterType={"stockAdjustments"}
                  searchPlaceHolder="Search stock adjustments..."
                  listFilter={[
                    {
                      id: "status",
                      type: "selectBox",
                      title: "Status",
                      dataOption: StockAdjustmentStatusOptions,
                    },
                    {
                      id: "location.id",
                      type: "selectBox",
                      title: "Location",
                      remote: true,
                      pathUrlLoad: "inventory/locations",
                    },
                    {
                      id: "balanced_at",
                      type: "date",
                      title: "Balanced at",
                    },
                  ]}
                  headers={headers}
                  contents={stockAdjustments}
                  limitPage={Number(stockAdjustmentsLimit)}
                  currentPage={Number(stockAdjustmentsPage) + 1}
                  totalItems={stockAdjustmentsTotal}
                  loading={stockAdjustmentsLoading}
                  showPagination={true}
                  scrollHeight="50vh"
                  onCallData={(dataPayload) => {
                    dispatch(listStockAdjustment(dataPayload));
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default withRouter(StockAdjustmentsPage);
