import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Collapse, Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useTranslation } from "react-i18next";
import { ColorPicker } from "antd";
import ButtonTheme from "../../../Components/Common/ButtonTheme";
import classnames from "classnames";
import useMemoizedSelector from "../../../Components/Hooks/useMemoizedSelector";
import { useDispatch } from "react-redux";
import { updateColorInfoShop } from "../../../store/actions";
const DATA_DEFAULT = {
  dark: [
    {
      value: "#5252e1",
      description: "primary",
      key: "primary",
    },
    {
      value: "#3577f1",
      description: "secondary",
      key: "secondary",
    },
    {
      value: "#f3f6f9",
      description: "light",
      key: "light",
    },
    {
      value: "#212529",
      description: "dark",
      key: "dark",
    },
    {
      value: "#292e32",
      description: "navbar bg",
      key: "navbar-bg",
    },
    {
      value: "#f3f6f9",
      description: "body color",
      key: "body-color",
    },
    {
      value: "#292e32",
      description: "card system",
      key: "card-system",
    },
    {
      value: "#8886b06b",
      description: "border color",
      key: "border-color",
    },
    {
      value: "#737185",
      description: "sub text",
      key: "sub-text",
    },
    {
      value: "#151334",
      description: "color icon",
      key: "color-icon",
    },
  ],
  light: [
    {
      value: "#5252e1",
      description: "primary",
      key: "primary",
      subColor: [
        {
          value: "#5252e0cc",
          description: `primary sub 1 (màu hover button)`,
          key: `hover-primary`,
        },
        {
          description: `primary sub 2 (màu nền button icon)`,
          value: "#5252e01a",
          key: `bg-soft-primary`,
        },
        {
          description: `primary sub 3 (màu disable button)`,
          value: "#5252e033",
          key: `disabled-primary`,
        },
        {
          description: `primary sub 4 (màu chữ button)`,
          value: "#fff",
          key: `text-primary`,
        },
      ],
    },
    {
      value: "#3577f1",
      description: "secondary",
      key: "secondary",
      subColor: [
        {
          value: "#3577f1cc",
          description: `secondary sub 1 (màu hover button)`,
          key: `hover-secondary`,
        },
        {
          description: `secondary sub 2 (màu nền button icon)`,
          value: "#3577f11a",
          key: `bg-soft-secondary`,
        },
        {
          description: `secondary sub 3 (màu disable button)`,
          value: "#3577f133",
          key: `disabled-secondary`,
        },
        {
          description: `secondary sub 4 (màu chữ button)`,
          value: "#fff",
          key: `text-secondary`,
        },
      ],
    },
    {
      value: "#f3f6f9",
      description: "light",
      key: "light",
      subColor: [
        {
          value: "#a9a7d333",
          description: `light sub 1 (màu hover button)`,
          key: `hover-light`,
        },
        {
          description: `light sub 2 (màu nền button icon)`,
          value: "#a9a7d333",
          key: `bg-soft-light`,
        },
        {
          description: `light sub 3 (màu disable button)`,
          value: "#a9a7d333",
          key: `disabled-light`,
        },
        {
          description: `light sub 4 (màu chữ button)`,
          value: "#343a40",
          key: `text-light`,
        },
      ],
    },
    {
      value: "#212529",
      description: "dark",
      key: "dark",
      subColor: [
        {
          value: "#212529",
          description: `dark sub 1 (màu hover button)`,
          key: `hover-dark`,
        },
        {
          description: `dark sub 2 (màu nền button icon)`,
          value: "#212529",
          key: `bg-soft-dark`,
        },
        {
          description: `dark sub 3 (màu disable button)`,
          value: "#212529",
          key: `disabled-dark`,
        },
        {
          description: `dark sub 4 (màu chữ button)`,
          value: "#ffffff",
          key: `text-dark`,
        },
      ],
    },
    {
      value: "#ffffff",
      description: "navbar bg",
      key: "navbar-bg",
    },
    {
      value: "#151334",
      description: "body color",
      key: "body-color",
    },
    {
      value: "#fff",
      description: "card system",
      key: "card-system",
    },
    {
      value: "#8886b06b",
      description: "border color",
      key: "border-color",
    },
    {
      value: "#44425d",
      description: "sub text",
      key: "sub-text",
      subColor: [
        {
          value: "#737185",
          description: `sub text secondary (màu hover button)`,
          key: `sub-text-secondary`,
        },
      ],
    },
    {
      value: "#151334",
      description: "color icon",
      key: "color-icon",
    },
  ],
};

function calculateColors(hexColor) {
  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);

  const disableR = Math.min(255, Math.round(r + 0.9 * (255 - r)));
  const disableG = Math.min(255, Math.round(g + 0.9 * (255 - g)));
  const disableB = Math.min(255, Math.round(b + 0.9 * (255 - b)));
  const disableHex = "#" + toHex(disableR) + toHex(disableG) + toHex(disableB);

  const hoverR = Math.round(r + 0.1 * (0 - r));
  const hoverG = Math.round(g + 0.1 * (0 - g));
  const hoverB = Math.round(b + 0.1 * (0 - b));
  const hoverHex = "#" + toHex(hoverR) + toHex(hoverG) + toHex(hoverB);

  const softR = Math.min(255, Math.round(r + 0.2 * (230 - r)));
  const softG = Math.min(255, Math.round(g + 0.2 * (230 - g)));
  const softB = Math.min(255, Math.round(b + 0.2 * (230 - b)));
  const softHex = "#" + toHex(softR) + toHex(softG) + toHex(softB);

  return { disableHex, hoverHex, softHex };
}

function toHex(value) {
  const hex = value.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}
export default function ColorsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loadingColor, dataColorRemote } = useMemoizedSelector((state) => ({
    loadingColor: state.ShopInfo.loadingColor,
    dataColorRemote: state.ShopInfo.dataColor,
  }));
  const MODE_THEME = [
    { value: "light", label: "Light" },
    { value: "dark", label: "Dark" },
  ];
  const [dataColor, setDataColor] = useState({});
  const [dataColorSave, setDataColorSave] = useState(DATA_DEFAULT);

  const handleSubmitColor = () => {
    dispatch(updateColorInfoShop(dataColor));
  };

  useEffect(() => {
    if (!loadingColor) {
      if (dataColorRemote && Object.keys(dataColorRemote).length > 0) {
        const dataDark = DATA_DEFAULT?.["dark"];
        const dataLight = DATA_DEFAULT?.["light"];
        const newData = {
          dark: handleMapNewColor(dataDark, dataColorRemote["dark"]),
          light: handleMapNewColor(dataLight, dataColorRemote["light"]),
        };
        setDataColorSave(newData);
        setDataColor(dataColorRemote);
      }
    }
  }, [loadingColor, dataColorRemote]);
  const handleMapNewColor = (dataDefault, dataCallApi) => {
    const newData = dataDefault.map((item) => ({
      ...item,
      value: dataCallApi[item.key] || item.value,
      ...(item?.subColor && {
        subColor: item?.subColor.map((i) => ({ ...i, value: dataCallApi[i.key] || i.value })),
      }),
    }));
    return newData;
  };
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("Color store")} pageTitle="Settings" navigateTo="/settings" isBack={true} />
        <Row>
          {MODE_THEME.map((mode, index) => (
            <Col lg={6} key={index}>
              <Card>
                <CardHeader>{t(mode.label)}</CardHeader>
                <CardBody>
                  <Row>
                    {dataColorSave[mode.value].map((color, i) => (
                      <RenderColor
                        key={i}
                        keyColor={color?.key}
                        dataColor={color?.value}
                        title={color?.description}
                        setDataColor={setDataColor}
                        mode={mode.value}
                        onChangeDataColor={(keyColor, colorChange) => {
                          setDataColor((prevData) => {
                            const newData = { ...prevData };
                            // Ensure that the mode exists in the data object
                            if (!newData[mode.value]) {
                              newData[mode.value] = {};
                            }

                            // Update the color value for the specified mode and key
                            newData[mode.value][keyColor] = colorChange;
                            if (["primary", "secondary"].includes(keyColor)) {
                              const { hoverHex, disableHex, softHex } = calculateColors(colorChange);
                              newData[mode.value][`hover-${keyColor}`] = hoverHex;
                              newData[mode.value][`bg-soft-${keyColor}`] = softHex;
                              newData[mode.value][`disabled-${keyColor}`] = disableHex;
                            }
                            // Return the updated state
                            return newData;
                          });
                        }}
                        dataSubColor={color?.subColor ? color?.subColor : []}
                      />
                    ))}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
        <ButtonTheme className="btn btn-primary" onClick={handleSubmitColor} loading={loadingColor} loadShowText={true}>
          {t("Save")}
        </ButtonTheme>
      </Container>
    </div>
  );
}

const RenderColor = ({
  keyColor,
  dataColor,
  onChangeDataColor,
  dataSubColor = [],
  mode,
  title,
  setDataColor,
  isSub = false,
}) => {
  const [value, setValue] = useState(dataColor);
  const [isPriceCollapse, setIsPriceCollapse] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    onChangeDataColor(keyColor, value);
  }, [value]);

  useEffect(() => {
    setValue(dataColor);
  }, [dataColor]);

  return (
    <>
      <Col lg={6}>
        <div className="mb-3">
          <div className="hstack gap-2">
            {dataSubColor.length > 0 && (
              <div
                className={classnames("accordion-button", { collapsed: isPriceCollapse })}
                onClick={() => {
                  setIsPriceCollapse(!isPriceCollapse);
                }}
                style={{ cursor: "pointer", width: "30px" }}
              >
                <i className=" ri-arrow-right-s-line fs-20"></i>
              </div>
            )}
            <span>{title}</span>
            <ColorPicker
              // showText
              value={value}
              onChangeComplete={(color) => {
                const data = color.toHexString();
                setValue(data);
              }}
              disabled={isSub}
            />
            <span>{value}</span>
          </div>
        </div>
      </Col>
      {dataSubColor.length > 0 && (
        <Collapse isOpen={!isPriceCollapse}>
          {dataSubColor.map((item, index) => (
            <RenderColor
              key={index}
              keyColor={item.key}
              title={item.description}
              dataColor={item.value}
              onChangeDataColor={(keyColor, colorChange) => {
                setDataColor((prevData) => {
                  const newData = { ...prevData };
                  // Ensure that the mode exists in the data object
                  if (!newData[mode]) {
                    newData[mode] = {};
                  }

                  // Update the color value for the specified mode and key
                  newData[mode][keyColor] = colorChange;

                  // Return the updated state
                  return newData;
                });
              }}
              mode={mode}
              isSub={true}
            />
          ))}
        </Collapse>
      )}
    </>
  );
};
