import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

import withRouter from "../../../../../Components/Common/withRouter";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import BreadCrumbCustom from "../../../../../Components/Common/BreadCrumbCustom";
import useMemoizedSelector from "../../../../../Components/Hooks/useMemoizedSelector";

import { getShift, listOrder } from "../../../../../store/actions";
import InputTheme from "../../../../../Components/Common/InputTheme";
import SelectTheme from "../../../../../Components/Common/SelectTheme";
import ShiftReportTemplate from "../../../../../Components/Common/ShiftReportTemplate";
import BadgeCustom from "../../../../../Components/Common/BadgeCustom";
import FullNameRender from "../../../../../Components/Common/FullnameRender";
import { Link } from "react-router-dom";
import ExpandRowDetails from "../../../Orders/Components/ExpandRowDetails";
import TableContainerCustom from "../../../../../Components/Common/TableContainerCustom";
import { Actions } from "../../../../../Components/constants/common";
import { formatDateTime, formatVNDCurrency } from "../../../../../helpers/format_helper";

const ShiftDetailsPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  const { id } = params;

  document.title = `${t("Shift Details")} | OptiWarehouse`;

  const dispatch = useDispatch();

  const { shift, shiftsLoading, shiftsSuccess, shiftsError } = useMemoizedSelector((state) => ({
    shift: state.Shifts.shift,
    shiftsLoading: state.Shifts.loading,
    shiftsSuccess: state.Shifts.success,
    shiftsError: state.Shifts.error,
  }));

  const { orders, ordersLoading, ordersAction, ordersLimit, ordersPage, ordersTotal, ordersMessage } =
    useMemoizedSelector((state) => ({
      orders: state.Orders.orders,
      ordersMessage: state.Orders.message,
      ordersAction: state.Orders.action,
      ordersLoading: state.Orders.loading,
      ordersSuccess: state.Orders.success,
      ordersError: state.Orders.error,
      ordersLimit: state.Orders.limit,
      ordersPage: state.Orders.page,
      ordersTotal: state.Orders.total,
    }));

  const headers = ["number", "customer", "staff", "updated_at", "created_at", "status", "payment_status"];

  const [shiftData, setShiftData] = useState({});

  const printShiftReport = () => {
    var pri = document.getElementById("shift-report").contentWindow;
    pri.print();
  };

  useEffect(() => {
    if (id) {
      dispatch(getShift(id));
      dispatch(listOrder({ limit: 20, sort_created_at: "desc", shift_id: id }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    !shiftsLoading && setShiftData(shift);
  }, [shiftsLoading, shift]);

  const formRef = useRef();

  return (
    <div className="page-content">
      <Container fluid>
        <ShiftReportTemplate shift={shiftData} />
        <BreadCrumbCustom text="Back to shift list" navigateTo="/shifts" t={t}>
          <div className="hstack gap-2">
            <ButtonTheme
              type="button"
              className="btn btn-outline-primary w-sm"
              onClick={printShiftReport}
              loading={shiftsLoading}
              disabled={shiftsLoading}
            >
              {t("Print shift report")}
            </ButtonTheme>
          </div>
        </BreadCrumbCustom>
        <div className="d-flex gap-3">
          <div className="flex-grow-1">
            <Card id="basic-information">
              <CardHeader>
                <h5 className="card-title">{t("Shift")}</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Location")}:</span>
                      <span>{shiftData?.location_id ?? "---"}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Terminal")}:</span>
                      <span>{shiftData?.terminal_id ?? "---"}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Staff")}:</span>
                      <span>{shiftData?.user?.name_staff ?? "---"}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Created at")}:</span>
                      <span>{shiftData?.created_at ? formatDateTime(shiftData?.created_at) : "---"}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Updated at")}:</span>
                      <span>{shiftData?.updated_at ? formatDateTime(shiftData?.updated_at) : "---"}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Closed time")}:</span>
                      <span>{shiftData?.close_time ? formatDateTime(shiftData?.close_time) : "---"}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Note")}:</span>
                      <span>{shiftData?.note || "---"}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Starting cash")}:</span>
                      <span>{formatVNDCurrency(shiftData?.starting_cash ?? 0)}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Gross sale")}:</span>
                      <span>{formatVNDCurrency(shiftData?.gross_sale ?? 0)}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Cash payments")}:</span>
                      <span>{formatVNDCurrency(shiftData?.cash_payments ?? 0)}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Cash refund")}:</span>
                      <span>{formatVNDCurrency(shiftData?.cash_refund ?? 0)}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Discounts")}:</span>
                      <span>{formatVNDCurrency(shiftData?.discounts ?? 0)}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Refunds")}:</span>
                      <span>{formatVNDCurrency(shiftData?.refunds ?? 0)}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Paid in")}:</span>
                      <span>{formatVNDCurrency(shiftData?.paid_in ?? 0)}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Paid out")}:</span>
                      <span>{formatVNDCurrency(shiftData?.paid_out ?? 0)}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Starting fund")}:</span>
                      <span>{formatVNDCurrency(shiftData?.starting_fund ?? 0)}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Expected fund")}:</span>
                      <span>{formatVNDCurrency(shiftData?.expected_fund ?? 0)}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Actual fund")}:</span>
                      <span>{formatVNDCurrency(shiftData?.actual_fund ?? 0)}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Fund note")}:</span>
                      <span>{shiftData?.fund_note ?? "---"}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Net sale")}:</span>
                      <span>{formatVNDCurrency(shiftData?.net_sales ?? 0)}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Expected cash")}:</span>
                      <span>{formatVNDCurrency(shiftData?.expected_cash ?? 0)}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Actual cash")}:</span>
                      <span>{formatVNDCurrency(shiftData?.actual_cash ?? 0)}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Difference")}:</span>
                      <span>{formatVNDCurrency(shiftData?.difference ?? 0)}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Cash")}:</span>
                      <span>{formatVNDCurrency(shiftData?.cash ?? 0)}</span>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="hstack justify-content-between gap-2 mb-3">
                      <span className="fw-bold">{t("Banking")}:</span>
                      <span>{formatVNDCurrency(shiftData?.banking ?? 0)}</span>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("Order List")}</h5>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <TableContainerCustom
                  listFilter={[]}
                  headers={headers}
                  contents={orders}
                  limitPage={Number(ordersLimit)}
                  currentPage={Number(ordersPage) + 1}
                  totalItems={ordersTotal}
                  loading={ordersLoading && ordersAction === Actions.LIST}
                  showPagination={true}
                  scrollHeight="50vh"
                  onCallData={(dataPayload) => {
                    dispatch(listOrder({ ...dataPayload, shift_id: id }));
                  }}
                  renderExpandedComponent={(data) => <ExpandRowDetails data={data} />}
                  isSettings={true}
                  listHeader={[
                    {
                      title: t("Order details"),
                      key: "detail-variant",
                      children: [
                        {
                          title: t("Order Number"),
                          dataIndex: "number",
                          key: "number",
                          render: (value, row, index) => {
                            return (
                              <Link
                                to={`/orders/${row.id}`}
                                className="text-truncate d-block h5 fs-14 mb-0 link-primary"
                              >
                                {value}
                              </Link>
                            );
                          },
                        },
                        {
                          title: t("Staff"),
                          dataIndex: "staff",
                          key: "staff",
                          render: (value, row, index) => {
                            return <div className="text-truncate d-block">{value?.name ?? "---"}</div>;
                          },
                        },
                        {
                          title: t("Customer"),
                          dataIndex: "customer",
                          key: "customer",
                          render: (value, row, index) => {
                            return (
                              <div className="text-truncate d-block">
                                <FullNameRender first_name={value?.first_name} last_name={value?.last_name} />
                              </div>
                            );
                          },
                        },
                        {
                          title: t("Updated at"),
                          dataIndex: "updated_at",
                          key: "updated_at",
                          sorter: true,
                        },
                        {
                          title: t("Created at"),
                          dataIndex: "created_at",
                          key: "created_at",
                          sorter: true,
                          defaultSortOrder: "desc",
                        },
                        {
                          title: t("Order status"),
                          dataIndex: "status",
                          key: "status",
                          sorter: true,
                          render: (value, row, index) => (
                            <>
                              <BadgeCustom status={value} />
                            </>
                          ),
                        },
                        {
                          title: t("Payment status"),
                          dataIndex: "payment_status",
                          key: "payment_status",
                          sorter: true,
                          render: (value, row, index) => <>{value ? <BadgeCustom status={value} /> : "---"}</>,
                        },
                      ],
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(ShiftDetailsPage);
