import { Fragment } from "react";
import { Table } from "reactstrap";
import {
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  // useSortBy,
  useTable,
} from "react-table";

import withRouter from "./withRouter";
import { DefaultColumnFilter } from "./filters";

const SimpleTableContainer = ({
  divClass,
  tableClass,
  theadClass,
  trClass,
  thClass,
  columns,
  data,
  onRowClick = () => {},
  customPageSize = 1000,
  noDataElement = null,
  showHeader = true,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        selectedRowIds: 0,
      },
    },
    useGlobalFilter,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect,
  );

  return (
    <Fragment>
      <div className={divClass}>
        <Table hover {...getTableProps()} className={tableClass}>
          {showHeader && (
            <thead className={theadClass}>
              {headerGroups.map((headerGroup) => (
                <tr className={trClass} key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th key={column.id} className={thClass}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          )}

          <tbody {...getTableBodyProps()}>
            {page.length > 0
              ? page.map((row) => {
                  prepareRow(row);
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <tr
                        onClick={() => {
                          onRowClick(row);
                        }}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td key={cell.id} {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    </Fragment>
                  );
                })
              : noDataElement && (
                  <tr>
                    <td colSpan={"1000"}>{noDataElement}</td>
                  </tr>
                )}
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
};
export default withRouter(SimpleTableContainer);
