import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

// Formik
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import InputTheme from "../../../../Components/Common/InputTheme";
import { changePasswordAPI } from "../../../../helpers/AuthAPI";
import { toast } from "react-toastify";
import ScrollToError from "../../../../Components/Common/ScrollToError";

const ChangePassModal = ({ show, toggle, username }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const validation = {
    enableReinitialize: true,
    initialValues: {
      username: username,
      password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required(t("Please Enter Password")),
      new_password: Yup.string().required(t("Please Enter New Password")),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("new_password"), null], t("Passwords must match"))
        .required(t("Confirm password is required")),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const { confirm_password, ...payload } = values;
        await changePasswordAPI(payload);
        toggle();
      } catch (e) {
        toast.error(t(String(e)));
      } finally {
        setLoading(false);
      }
    },
  };
  const handleClose = () => {
    if (!loading) {
      toggle();
    }
  };

  const formRef = useRef();
  return (
    <Modal fade={true} isOpen={show} toggle={handleClose} centered={true}>
      <ModalHeader className="bg-light p-3">{t("Change password")}</ModalHeader>

      <ModalBody>
        <Formik
          enableReinitialize={true}
          initialValues={validation.initialValues}
          validationSchema={validation.validationSchema}
          onSubmit={validation.onSubmit}
          innerRef={formRef}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <ScrollToError />
              <InputTheme name={"password"} type="password" label="Password" placeholder={t("Enter Password")} />
              <InputTheme
                name={"new_password"}
                type="password"
                label="New password"
                placeholder={t("Enter new password")}
              />
              <InputTheme
                name={"confirm_password"}
                type="password"
                label="Confirm password"
                placeholder={t("Enter confirm password")}
              />
            </Form>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>
        <div className="hstack gap-2 justify-content-end">
          <ButtonTheme type="button" className="btn btn-light w-sm" onClick={handleClose}>
            {t("Close")}
          </ButtonTheme>
          <ButtonTheme
            className="btn btn-primary w-sm"
            loading={loading}
            disabled={loading}
            onClick={() => {
              formRef.current.handleSubmit();
            }}
          >
            {t("Change password")}
          </ButtonTheme>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ChangePassModal;
