import { Actions } from "../../../../Components/constants/common";
import {
  LIST_VARIANT,
  DELETE_VARIANT,
  DELETE_VARIANT_SUCCESS,
  DELETE_VARIANT_FAIL,
  ADD_VARIANT_SUCCESS,
  ADD_VARIANT_FAIL,
  UPDATE_VARIANT_SUCCESS,
  UPDATE_VARIANT_FAIL,
  LIST_VARIANT_SUCCESS,
  LIST_VARIANT_FAIL,
  ADD_NEW_VARIANT,
  UPDATE_VARIANT,
  GET_VARIANT,
  GET_VARIANT_SUCCESS,
  GET_VARIANT_FAIL,
  RESET_VARIANT_FLAG,
} from "./actionType";

const INIT_STATE = {
  variants: [],
  variant: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const Variants = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_VARIANT:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_VARIANT_SUCCESS:
      return {
        ...state,
        message: "Get variants success",
        loading: false,
        success: true,
        variants: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_VARIANT_FAIL:
      return {
        ...state,
        message: "Get variants failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_VARIANT:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_VARIANT_SUCCESS:
      return {
        ...state,
        message: "Get variant success",
        loading: false,
        success: true,
        variant: action.payload,
        action: Actions.EMPTY,
      };

    case GET_VARIANT_FAIL:
      return {
        ...state,
        message: "Get variant failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_VARIANT:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_VARIANT_SUCCESS:
      return {
        ...state,
        message: "Delete variant success",
        loading: false,
        success: true,
        variants: state.variants.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_VARIANT_FAIL:
      return {
        ...state,
        message: "Delete variant failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_NEW_VARIANT:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_VARIANT_SUCCESS:
      return {
        ...state,
        message: "Add variant success",
        success: true,
        loading: false,
        variants: [...state.variants, action.payload],
        action: Actions.EMPTY,
      };

    case ADD_VARIANT_FAIL:
      return {
        ...state,
        message: "Add variant failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_VARIANT:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.EMPTY,
      };
    case UPDATE_VARIANT_SUCCESS:
      return {
        ...state,
        message: "Update variant success",
        loading: false,
        success: true,
        variants: state.variants.map((variant) =>
          variant.id.toString() === action.payload.id.toString() ? { ...variant, ...action.payload } : variant,
        ),
        action: Actions.UPDATE,
      };

    case UPDATE_VARIANT_FAIL:
      return {
        ...state,
        message: "Update variant failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case RESET_VARIANT_FLAG:
      return {
        ...state,
        message: "",
        variant: {},
        success: false,
        action: Actions.EMPTY,
      };

    default:
      return { ...state };
  }
};

export default Variants;
