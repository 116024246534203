import {
  LIST_VOUCHER,
  LIST_VOUCHER_FAIL,
  LIST_VOUCHER_SUCCESS,
  DELETE_VOUCHER,
  DELETE_VOUCHER_SUCCESS,
  DELETE_VOUCHER_FAIL,
  ADD_NEW_VOUCHER,
  ADD_VOUCHER_SUCCESS,
  ADD_VOUCHER_FAIL,
  UPDATE_VOUCHER,
  UPDATE_VOUCHER_SUCCESS,
  UPDATE_VOUCHER_FAIL,
  GET_VOUCHER,
  GET_VOUCHER_FAIL,
  GET_VOUCHER_SUCCESS,
  RESET_VOUCHER_FLAG,
} from "./actionType";

export const listVoucher = (options) => ({
  type: LIST_VOUCHER,
  payload: options,
});

export const listVoucherFail = (error) => ({
  type: LIST_VOUCHER_FAIL,
  payload: error,
});

export const listVoucherSuccess = (vouchers) => ({
  type: LIST_VOUCHER_SUCCESS,
  payload: vouchers,
});

export const getVoucher = (voucherID) => ({
  type: GET_VOUCHER,
  payload: voucherID,
});

export const getVoucherFail = (error) => ({
  type: GET_VOUCHER_FAIL,
  payload: error,
});

export const getVoucherSuccess = (voucher) => ({
  type: GET_VOUCHER_SUCCESS,
  payload: voucher,
});

export const deleteVoucher = (voucher) => ({
  type: DELETE_VOUCHER,
  payload: voucher,
});

export const deleteVoucherSuccess = (voucher) => ({
  type: DELETE_VOUCHER_SUCCESS,
  payload: voucher,
});

export const deleteVoucherFail = (error) => ({
  type: DELETE_VOUCHER_FAIL,
  payload: error,
});

export const updateVoucher = (voucher) => ({
  type: UPDATE_VOUCHER,
  payload: voucher,
});

export const updateVoucherSuccess = (voucher) => ({
  type: UPDATE_VOUCHER_SUCCESS,
  payload: voucher,
});

export const updateVoucherFail = (error) => ({
  type: UPDATE_VOUCHER_FAIL,
  payload: error,
});

export const addNewVoucher = (voucher) => ({
  type: ADD_NEW_VOUCHER,
  payload: voucher,
});

export const addVoucherSuccess = (voucher) => ({
  type: ADD_VOUCHER_SUCCESS,
  payload: voucher,
});

export const addVoucherFail = (error) => ({
  type: ADD_VOUCHER_FAIL,
  payload: error,
});

export const resetVoucherFlag = () => ({
  type: RESET_VOUCHER_FLAG,
});
