import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import useMemoizedSelector from "../../../../../Components/Hooks/useMemoizedSelector";
import { Actions } from "../../../../../Components/constants/common";

import { addNewBrand } from "../../../../../store/actions";

const BrandsModal = ({ isOpen, toggle }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { brands, brandsLoading, brandsAction, brandsSuccess, brandsError, brandsMessage } = useMemoizedSelector(
    (state) => ({
      brands: state.Brands.brands,
      brandsLoading: state.Brands.loading,
      brandsAction: state.Brands.action,
      brandsSuccess: state.Brands.success,
      brandsMessage: state.Brands.message,
      brandsError: state.Brands.error,
    }),
  );

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Please Enter Brand Name")),
    }),
    onSubmit: (values) => {
      const newBrand = {
        name: values.name,
      };
      dispatch(addNewBrand(newBrand));
    },
  });

  const handleToggle = () => {
    if (!brandsLoading) {
      validation.resetForm();
      toggle();
    }
  };

  useEffect(() => {
    if (!brandsLoading && brandsSuccess) {
      handleToggle();
    }
  }, [brandsLoading]);

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} modalClassName="zoomIn" centered>
      <ModalHeader toggle={handleToggle}>{t("Add New Brand")}</ModalHeader>
      <ModalBody>
        <div>
          <Label className="form-label" htmlFor="new-brand">
            {t("Name")}
          </Label>
          <div className="d-flex">
            <div className="flex-grow-1 me-3">
              <Input
                id="new-brand"
                className="form-control me-auto"
                type="text"
                name="name"
                placeholder={t("Enter Brand Name")}
                value={validation.values.name ?? ""}
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
                invalid={validation.errors.name && validation.touched.name ? true : false}
              />
              {validation.errors.name && validation.touched.name ? (
                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
              ) : null}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <ButtonTheme className="btn btn-light" onClick={handleToggle}>
          {t("Close")}
        </ButtonTheme>
        <ButtonTheme
          type="button"
          className="btn btn-primary"
          loading={brandsLoading && brandsAction === Actions.CREATE}
          disabled={brandsLoading && brandsAction === Actions.CREATE}
          onClick={validation.handleSubmit}
        >
          {t("Add")}
        </ButtonTheme>
      </ModalFooter>
    </Modal>
  );
};

export default BrandsModal;
