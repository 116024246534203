import { Actions } from "../../../../Components/constants/common";
import {
  LIST_CUSTOMER,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAIL,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAIL,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAIL,
  LIST_CUSTOMER_SUCCESS,
  LIST_CUSTOMER_FAIL,
  ADD_NEW_CUSTOMER,
  UPDATE_CUSTOMER,
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAIL,
  RESET_CUSTOMER_FLAG,
} from "./actionType";

const INIT_STATE = {
  customers: [],
  customer: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const Customers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_CUSTOMER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_CUSTOMER_SUCCESS:
      return {
        ...state,
        message: "Get customers success",
        loading: false,
        success: true,
        customers: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_CUSTOMER_FAIL:
      return {
        ...state,
        message: "Get customers failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_CUSTOMER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        message: "Get customer success",
        loading: false,
        success: true,
        customer: action.payload,
        action: Actions.EMPTY,
      };

    case GET_CUSTOMER_FAIL:
      return {
        ...state,
        message: "Get customer failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_CUSTOMER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        message: "Delete customer success",
        loading: false,
        success: true,
        customers: state.customers.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_CUSTOMER_FAIL:
      return {
        ...state,
        message: "Delete customer failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_NEW_CUSTOMER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        message: "Add customer success",
        success: true,
        loading: false,
        customer: action.payload,
        customers: [...state.customers, action.payload],
        action: Actions.EMPTY,
      };

    case ADD_CUSTOMER_FAIL:
      return {
        ...state,
        message: "Add customer failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_CUSTOMER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.UPDATE,
      };
    case UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        message: "Update customer success",
        loading: false,
        success: true,
        customer: action.payload,
        customers: state.customers.map((customer) =>
          customer.id.toString() === action.payload.id.toString() ? { ...customer, ...action.payload } : customer,
        ),
        action: Actions.EMPTY,
      };

    case UPDATE_CUSTOMER_FAIL:
      return {
        ...state,
        message: "Update customer failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case RESET_CUSTOMER_FLAG:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Customers;
