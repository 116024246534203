import { useState } from "react";
import { useDebounce } from "./UseDebounce";

const useListData = ({ resource, isScroll = false }) => {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [dataList, setList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const debouncedInputValue = useDebounce(searchValue, 1000);
  const onChangeQuery = (data) => {
    setSearchValue(data);
  };

  const onSearch = async () => {
    try {
      setLoading(true);
      const res = await resource.list({ query: debouncedInputValue });
      setList(res?.items || []);
      setPage(Number(res.page));
      if (!isScroll) {
        return;
      }
      if (Number(res.limit) * (Number(res.page) + 1) < res.total) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (e) {
      console.error(e);
      setList([]);
      setPage(Number(0));
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  const listData = async (query, page) => {
    try {
      const res = await resource.list({
        limit: 20,
        ...(page > 0 && { page }),
        ...(query && { query: debouncedInputValue }),
      });
      setPage(Number(res.page));
      if (!isScroll) {
        setList(res.items);
        return;
      }
      setList([...dataList, ...res.items]);
      if (Number(res.limit) * (Number(res.page) + 1) < res.total) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (e) {
      console.error(e);
      setList([]);
      setPage(Number(0));
      setHasMore(false);
    }
  };

  return {
    page,
    loading,
    hasMore,
    items: dataList,
    query: searchValue,
    debouncedQuery: debouncedInputValue,
    onChangeQuery,
    onSearch,
    listData,
  };
};

export { useListData };
