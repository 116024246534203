import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { isEmpty } from "lodash";
import { Tooltip } from "antd";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

import { convertValidDate, convertValidTime } from "../../../../helpers/format_helper";

import AddDiscountModal from "./AddDiscountModal";
import withRouter from "../../../../Components/Common/withRouter";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import BadgeCustom from "../../../../Components/Common/BadgeCustom";
import ActionDropdown from "../../../../Components/Common/ActionDropdown";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import { Actions, DiscountStatusOptions, DiscountTypeOptions } from "../../../../Components/constants/common";

import { deleteDiscount, listDiscount } from "../../../../store/actions";

const DiscountListPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Discount List")} | OptiWarehouse`;
  const dispatch = useDispatch();

  const {
    discounts,
    discountsAction,
    discountsLoading,
    discountsMessage,
    discountsSuccess,
    discountsError,
    discountsLimit,
    discountsPage,
    discountsTotal,
  } = useMemoizedSelector((state) => ({
    discounts: state.Discounts.discounts,
    discountsAction: state.Discounts.action,
    discountsLoading: state.Discounts.loading,
    discountsSuccess: state.Discounts.success,
    discountsError: state.Discounts.error,
    discountsMessage: state.Discounts.message,
    discountsLimit: state.Discounts.limit,
    discountsTotal: state.Discounts.total,
    discountsPage: state.Discounts.page,
  }));

  // modal state
  const [deleteModal, setDeleteModal] = useState(false);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [showAddDiscountModal, setShowAddDiscountModal] = useState(false);

  const [selectedDiscount, setSelectedDiscount] = useState(null);

  const onClickDelete = (item) => {
    setSelectedDiscount(item);
    setDeleteModal(true);
  };

  const handleDeleteProduct = () => {
    if (selectedDiscount) {
      dispatch(deleteDiscount(selectedDiscount));
    }
  };

  const handleCloseDeleteModal = () => {
    if (!discountsLoading) {
      setDeleteModal(false);
      setSelectedDiscount(null);
    }
  };

  // table data
  const headers = [
    "discount_code",
    "name",
    "type",
    "max_using_times",
    "start_at",
    "expire_at",
    "created_at",
    "status",
    "action",
  ];

  const toggleAddDiscountModal = () => {
    if (!discountsLoading) {
      setShowAddDiscountModal(false);
      setSelectedDiscount(null);
    }
  };

  useEffect(() => {
    // toggle off modal when delete success
    if (
      !discountsLoading &&
      !isEmpty(selectedDiscount) &&
      deleteModal &&
      discountsMessage === "Delete discount success"
    ) {
      setSelectedDiscount(null);
      setDeleteModal(false);
    }
  }, [discountsLoading]);

  return (
    <Fragment>
      <div className="page-content">
        <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={discounts} />
        <AddDiscountModal isOpen={showAddDiscountModal} toggle={toggleAddDiscountModal} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteProduct}
          onCloseClick={handleCloseDeleteModal}
          loading={discountsLoading && discountsAction === Actions.DELETE}
        />
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card id="discount-list">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">{t("Discount List")}</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex gap-3 flex-wrap">
                        <ButtonTheme
                          type="button"
                          className="btn btn-light"
                          icon="ri-file-text-line"
                          onClick={() => setIsExportCSV(true)}
                        >
                          {t("Export")}
                        </ButtonTheme>
                        <ButtonTheme
                          className="btn btn-primary"
                          id="create-btn"
                          icon="ri-add-line text-white"
                          onClick={() => {
                            setShowAddDiscountModal(true);
                          }}
                        >
                          {t("Add Discount")}
                        </ButtonTheme>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <TableContainerCustom
                    filterType={"discounts"}
                    searchPlaceHolder={t("Search discounts...")}
                    listFilter={[
                      {
                        id: "type",
                        type: "selectBox",
                        title: "Type",
                        dataOption: DiscountTypeOptions,
                      },
                      {
                        id: "status",
                        type: "selectBox",
                        title: "Status",
                        dataOption: DiscountStatusOptions,
                      },
                      {
                        id: "start_at",
                        type: "date",
                        title: "Start at",
                      },
                      {
                        id: "expire_at",
                        type: "date",
                        title: "Expire at",
                      },
                    ]}
                    headers={headers}
                    contents={discounts}
                    limitPage={Number(discountsLimit)}
                    currentPage={Number(discountsPage) + 1}
                    totalItems={discountsTotal}
                    loading={discountsLoading && discountsAction === Actions.LIST}
                    showPagination={true}
                    scrollHeight="50vh"
                    onCallData={(dataPayload) => {
                      dispatch(listDiscount(dataPayload));
                    }}
                    isSettings={true}
                    listHeader={[
                      {
                        title: t("Discount details"),
                        key: "detail-variant",
                        children: [
                          {
                            title: t("Discount code"),
                            key: "discount_code",
                            dataIndex: "discount_code",
                            sorter: true,
                            render: (_, data, index) => {
                              return (
                                <Link
                                  to={`/discounts/details/${data.id}`}
                                  className="link-primary d-block text-truncate"
                                >
                                  {_ || "---"}
                                </Link>
                              );
                            },
                          },
                          {
                            title: t("Name"),
                            dataIndex: "name",
                            key: "name",
                            sorter: true,
                            render: (_, data, index) => {
                              return (
                                <Tooltip title={t(_ || "---")}>
                                  <div className="d-block text-truncate">{t(_ || "---")}</div>
                                </Tooltip>
                              );
                            },
                          },
                          {
                            title: t("Type"),
                            dataIndex: "type",
                            key: "type",
                            sorter: true,
                            render: (value, row, index) => {
                              const typeName = DiscountTypeOptions.find((item) => item.id === value)?.label;
                              return <>{t(typeName || "---")}</>;
                            },
                          },
                          {
                            title: t("Max using times"),
                            dataIndex: "max_using_times",
                            key: "max_using_times",
                            sorter: true,
                            render: (_, data, index) => {
                              return <>{_ || "∞"}</>;
                            },
                          },
                          {
                            title: t("Start at"),
                            dataIndex: "start_at",
                            key: "start_at",
                            sorter: true,
                          },
                          {
                            title: t("Expire at"),
                            dataIndex: "expire_at",
                            key: "expire_at",
                            sorter: true,
                          },
                          {
                            title: t("Created at"),
                            dataIndex: "created_at",
                            key: "created_at",
                            sorter: true,
                            defaultSortOrder: "desc",
                          },
                          {
                            title: t("Status"),
                            dataIndex: "status",
                            key: "status",
                            sorter: true,
                            render: (value, data, index) => {
                              return <BadgeCustom status={value} />;
                            },
                          },
                          {
                            title: t("Action"),
                            dataIndex: "action",
                            key: "action",
                            render: (value, row, index) => {
                              return (
                                <ActionDropdown
                                  index={index}
                                  actions={[
                                    {
                                      type: "view",
                                      onClick: () => {
                                        navigate(`/discounts/details/${row.id}`);
                                      },
                                    },
                                    {
                                      type: "edit",
                                      onClick: () => {
                                        navigate(`/discounts/${row.id}/edit`);
                                      },
                                    },
                                    {
                                      type: "delete",
                                      onClick: () => {
                                        onClickDelete(row);
                                      },
                                    },
                                  ]}
                                />
                              );
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};
export default withRouter(DiscountListPage);
