import React, { useEffect, useState, useRef, Fragment } from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

import withRouter from "../../../../Components/Common/withRouter";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import BreadCrumbCustom from "../../../../Components/Common/BreadCrumbCustom";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { Actions } from "../../../../Components/constants/common";

import {
  addNewCustomer,
  deleteCustomer,
  getCustomer,
  resetCustomer,
  listPointEvent,
  listTierEvent,
  listOrder,
} from "../../../../store/actions";

import DeleteModal from "../../../../Components/Common/DeleteModal";
import { Popover } from "antd";
import CopyableText from "../../../../Components/Common/CopyableText";
import BadgeCustom from "../../../../Components/Common/BadgeCustom";
import { convertValidDate, formatDateTime, formatVNDCurrency } from "../../../../helpers/format_helper";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import FullNameRender from "../../../../Components/Common/FullnameRender";
import { Link } from "react-router-dom";

const CustomerDetails = (props) => {
  const { router } = props;
  const { navigate, location, params, t } = router;
  const dispatch = useDispatch();
  const { id } = params;
  document.title = `${t("Customer Details")} | OptiWarehouse`;

  const { customer, customersLoading, customersSuccess, customersAction, customersMsg } = useMemoizedSelector(
    (state) => ({
      customer: state.Customers.customer,
      customersLoading: state.Customers.loading,
      customersAction: state.Customers.action,
      customersSuccess: state.Customers.success,
      customersError: state.Customers.error,
      customersMsg: state.Customers.message,
    }),
  );

  const {
    orders,
    ordersAction,
    ordersLoading,
    ordersMessage,
    ordersSuccess,
    ordersError,
    ordersLimit,
    ordersPage,
    ordersTotal,
  } = useMemoizedSelector((state) => ({
    orders: state.Orders.orders,
    ordersAction: state.Orders.action,
    ordersLoading: state.Orders.loading,
    ordersSuccess: state.Orders.success,
    ordersError: state.Orders.error,
    ordersMessage: state.Orders.message,
    ordersLimit: state.Orders.limit,
    ordersTotal: state.Orders.total,
    ordersPage: state.Orders.page,
  }));

  // table data
  const headers1 = ["full_name", "tier", "created_at", "type", "discount_percent"];

  const [customActiveTab, setcustomActiveTab] = useState("order-history");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const [deleteModal, setDeleteModal] = useState(null);
  const handleCloseDeleteModal = () => {
    if (!customersLoading) {
      setDeleteModal(false);
    }
  };

  const handleClickDelete = () => {
    if (customer?.id) {
      dispatch(
        deleteCustomer(customer, () => {
          navigate("/customers");
        }),
      );
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getCustomer(id));
    } else {
      dispatch(resetCustomer());
    }
  }, [dispatch, id]);

  const formRef = useRef();

  return (
    <div className="page-content">
      <Container fluid>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleClickDelete}
          onCloseClick={handleCloseDeleteModal}
          loading={customersLoading && customersAction === Actions.DELETE}
        />
        <BreadCrumbCustom text="Back to customer list" navigateTo="/customers">
          <div className="hstack gap-2">
            <ButtonTheme
              type="button"
              className="btn btn-danger w-sm"
              onClick={() => {
                setDeleteModal(true);
              }}
              disabled={customersAction === Actions.DELETE && customersLoading}
            >
              {t("Delete")}
            </ButtonTheme>
          </div>
        </BreadCrumbCustom>

        <h5 className="card-title">
          <FullNameRender first_name={customer?.first_name} last_name={customer?.last_name} />
        </h5>
        <Row>
          <Col lg={8}>
            <Card id="basic-information">
              <CardHeader className="border-bottom">
                <h5 className="card-title">{t("Personal information")}</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg={6}>
                    <Row>
                      <Col>
                        <p className="text-muted mb-0">{t("Birthday")}</p>
                      </Col>
                      <Col>
                        : <span className="mb-0">{customer?.birthday ?? "---"}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="text-muted mb-0">{t("Gender")}</p>
                      </Col>
                      <Col>
                        : <span className="mb-0">{customer?.gender ?? "---"}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="text-muted mb-0">{t("Phone")}</p>
                      </Col>
                      <Col>
                        : <span className="mb-0">{customer?.phone ?? "---"}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="text-muted mb-0">{t("Email")}</p>
                      </Col>
                      <Col>
                        : <span className="mb-0">{customer?.email ?? "---"}</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row>
                      <Col>
                        <p className="text-muted mb-0">{t("Customer group")}</p>
                      </Col>
                      <Col>
                        : <span className="mb-0">{customer?.customer_group?.name ?? "---"}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="text-muted mb-0">{t("Tags")}</p>
                      </Col>
                      <Col>
                        : <span className="mb-0">{customer?.tags ?? "---"}</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader className="border-bottom">
                <h5 className="card-title">{t("Purchase information")}</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg={6}>
                    <Row>
                      <Col>
                        <p className="text-muted mb-0">{t("Total spent")}</p>
                      </Col>
                      <Col>
                        : <span className="mb-0">{formatVNDCurrency(customer?.sale_order?.total_spent ?? 0)}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="text-muted mb-0">{t("Purchased order")}</p>
                      </Col>
                      <Col>
                        : <span className="mb-0">{customer?.sale_order?.purchased_order ?? 0}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="text-muted mb-0">{t("Last order at")}</p>
                      </Col>
                      <Col>
                        :{" "}
                        <span className="mb-0">
                          {customer?.sale_order?.last_order_on
                            ? formatDateTime(customer?.sale_order?.last_order_on)
                            : "---"}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row>
                      <Col>
                        <p className="text-muted mb-0">{t("Total products purchased")}</p>
                      </Col>
                      <Col>
                        : <span className="mb-0">{customer?.sale_order?.net_quantity ?? 0}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="text-muted mb-0">{t("Total products returned")}</p>
                      </Col>
                      <Col>
                        : <span className="mb-0">{customer?.sale_order?.returned_item_quantity ?? 0}</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card>
              <CardHeader className="border-bottom">
                <h5 className="card-title">{t("Sales suggestion information")}</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <p className="text-muted mb-0">{t("Default price group")}</p>
                  </Col>
                  <Col>
                    : <span className="mb-0">{customer?.customer_group?.default_price_group?.name ?? "---"}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="text-muted mb-0">{t("Default payment method")}</p>
                  </Col>
                  <Col>
                    : <span className="mb-0">{customer?.customer_group?.default_payment_method?.name ?? "---"}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="text-muted mb-0">{t("Discount percent")}</p>
                  </Col>
                  <Col>
                    : <span className="mb-0">{customer?.customer_group?.discount_percent ?? 0}%</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader className="border-bottom">
                <h5 className="card-title">{t("Points accumulation information")}</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <p className="text-muted mb-0">{t("Current point")}</p>
                  </Col>
                  <Col>
                    : <span className="mb-0">{customer?.loyal_customer?.point ?? 0}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="text-muted mb-0">{t("Used point")}</p>
                  </Col>
                  <Col>
                    : <span className="mb-0">{customer?.loyal_customer?.used_point ?? 0}</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Nav className="nav-tabs nav-tabs-custom nav-primary mb-3" role="tablist">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "order-history",
                      })}
                      onClick={() => {
                        toggleCustom("order-history");
                      }}
                    >
                      {t("Order history")}
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "tiers",
                      })}
                      onClick={() => {
                        toggleCustom("tiers");
                      }}
                    >
                      {t("Tier events")}
                    </NavLink>
                  </NavItem> */}
                </Nav>
                <TabContent activeTab={customActiveTab} className="text-muted">
                  <TabPane tabId="order-history" id="order-history">
                    <TableContainerCustom
                      headers={[
                        "number",
                        "status",
                        "payment_status",
                        "total",
                        "redeem_point",
                        "loyal_point",
                        "current_point",
                        "staff",
                        "created_at",
                      ]}
                      contents={orders}
                      limitPage={Number(ordersLimit)}
                      currentPage={Number(ordersPage) + 1}
                      totalItems={ordersTotal}
                      loading={ordersLoading}
                      showPagination={true}
                      scrollHeight="50vh"
                      onCallData={(dataPayload) => {
                        dispatch(listOrder({ ...dataPayload, "customer.id": id }));
                      }}
                      isSettings={true}
                      listHeader={[
                        {
                          title: t("Order details"),
                          key: "detail-order",
                          children: [
                            {
                              title: t("Order Number"),
                              dataIndex: "number",
                              key: "number",
                              render: (value, row, index) => {
                                return (
                                  <Link
                                    to={`/orders/${row.id}`}
                                    className="text-truncate d-block h5 fs-14 mb-0 link-primary"
                                  >
                                    {value}
                                  </Link>
                                );
                              },
                            },
                            {
                              title: t("Order status"),
                              dataIndex: "status",
                              key: "status",
                              sorter: true,
                              render: (value, row, index) => (
                                <>
                                  <BadgeCustom status={value} />
                                </>
                              ),
                            },
                            {
                              title: t("Payment status"),
                              dataIndex: "payment_status",
                              key: "payment_status",
                              sorter: true,
                              render: (value, row, index) => <>{value ? <BadgeCustom status={value} /> : "---"}</>,
                            },
                            {
                              title: t("Amount"),
                              dataIndex: "total",
                              key: "total",
                              render: (value, row, index) => {
                                return <div className="text-truncate d-block">{formatVNDCurrency(value)}</div>;
                              },
                            },
                            {
                              title: t("Redeem point"),
                              dataIndex: "redeem_point",
                              key: "redeem_point",
                              render: (value, row, index) => {
                                return <div className="text-truncate d-block">{value ?? "---"}</div>;
                              },
                            },
                            {
                              title: t("Loyal point"),
                              dataIndex: "loyal_point",
                              key: "loyal_point",
                              render: (value, row, index) => {
                                return <div className="text-truncate d-block">{value ?? "---"}</div>;
                              },
                            },
                            {
                              title: t("Last current point"),
                              dataIndex: "current_point",
                              key: "current_point",
                              render: (value, row, index) => {
                                return <div className="text-truncate d-block">{row?.customer?.loyal_customer?.point ?? "---"}</div>;
                              },
                            },
                            {
                              title: t("Staff"),
                              dataIndex: "staff",
                              key: "staff",
                              render: (value, row, index) => {
                                return <div className="text-truncate d-block">{value?.name ?? "---"}</div>;
                              },
                            },
                            {
                              title: t("Created at"),
                              dataIndex: "created_at",
                              key: "created_at",
                              sorter: true,
                              defaultSortOrder: "desc",
                            },
                          ],
                        },
                      ]}
                    />
                  </TabPane>
                  {/* <TabPane tabId="tiers" id="home1">
                    <TableContainerCustom
                      headers={headers1}
                      contents={tierEvents}
                      limitPage={Number(tierEventsLimit)}
                      currentPage={Number(tierEventsPage) + 1}
                      totalItems={tierEventsTotal}
                      loading={tierEventsLoading && tierEventsAction === Actions.LIST}
                      showPagination={true}
                      scrollHeight="50vh"
                      onCallData={(dataPayload) => {
                        dispatch(listTierEvent({ ...dataPayload, customer_id: id }));
                      }}
                      isSettings={true}
                      listHeader={[
                        {
                          title: t("Tier event details"),
                          key: "detail-variant",
                          children: [
                            {
                              title: t("Member name"),
                              key: "full_name",
                              dataIndex: "full_name",
                              sorter: true,
                              render: (_, data, index) => {
                                return (
                                  <>
                                    <div>{_ ?? "---"}</div>
                                    <div>{data?.phone ?? "---"}</div>
                                  </>
                                );
                              },
                            },
                            {
                              title: t("Tier"),
                              dataIndex: "tier",
                              key: "tier",
                              render: (_, data, index) => {
                                return <>{data?.tier.name ?? "---"}</>;
                              },
                            },
                            {
                              title: t("Created At"),
                              dataIndex: "created_at",
                              key: "created_at",
                              sorter: true,
                              defaultSortOrder: "desc",
                            },
                            {
                              title: t("Type"),
                              dataIndex: "type",
                              key: "type",
                            },
                            {
                              title: t("Percent"),
                              dataIndex: "discount_percent",
                              key: "discount_percent",
                              render: (_, data, index) => {
                                return <>{data?.tier?.discount_percent ?? 0}%</>;
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  </TabPane> */}
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(CustomerDetails);
