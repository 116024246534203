import { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Tooltip } from "antd";
import classnames from "classnames";
import SimpleBar from "simplebar-react";

import Loader from "../../../../../Components/Common/Loader";
import withRouter from "../../../../../Components/Common/withRouter";
import BreadCrumbCustom from "../../../../../Components/Common/BreadCrumbCustom";
import SearchDropdown from "../../../../../Components/Common/ProductsSearchDropDown";

import { getReportProductSellingThrough } from "../../../../../helpers/service_helper";
import { toast } from "react-toastify";
import ImageCustom from "../../../../../Components/Common/ImageCustom";
import SellThroughChart from "../../Chart/SellThroughChart";
import AverageChart from "../../Chart/AverageChart";
import { formatDateTime } from "../../../../../helpers/format_helper";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import GradientCircleRadialbar from "../../Components/GradientCirleRadialbar";
import BreakEventChart from "../../Chart/BreakEventChart";
import AveragePercentChart from "../../Chart/AveragePercentChart";
import LottieIcon from "../../../../../Components/Common/LottieIcon";

const ProductReportDetails = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Product Report")} | OptiWarehouse`;
  const { id } = params;
  const sku = location.state?.sku || "";

  const [showDropDownProductMenu, setShowDropDownProductMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({});
  const [percent, setPercent] = useState(0);
  const [verticalTab, setVerticalTab] = useState("");

  const toggleOffDropDownProductMenu = () => {
    setShowDropDownProductMenu(false);
  };
  const toggleOnDropDownProductMenu = () => {
    setShowDropDownProductMenu(true);
  };

  const toggleVertical = (tab) => {
    if (verticalTab !== tab) {
      setVerticalTab(tab);
    }
  };

  const getData = async (data) => {
    try {
      setLoading(true);
      const res = await getReportProductSellingThrough(data);
      setProduct(res);
      const keys = Object.keys(res?.metrics_by_month || {});
      const lastKey = keys?.[keys.length - 1];
      const lastValue = lastKey ? res?.metrics_by_month?.[lastKey] : {};
      const sold_units = lastValue?.sold_units || 0;
      const percent = res.initial_stock > 0 ? (sold_units / res.initial_stock) * 100 : 0;
      setPercent(percent.toFixed(0));
    } catch (e) {
      toast.error(String(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    id && getData(id);
  }, [id]);

  useEffect(() => {
    const data = sku ? sku : product.variants && product.variants.length > 0 ? product.variants[0].id : product.id;
    setVerticalTab(data);
  }, [product]);

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumbCustom text="Back" navigateTo={`/report/products`}></BreadCrumbCustom>
          <Row className="mb-3">
            <Col lg={4}>
              <SearchDropdown
                onClickItem={(data) => {
                  navigate(`/report/products/${data.id}`);
                }}
                isOpen={showDropDownProductMenu}
                toggleOff={toggleOffDropDownProductMenu}
                toggleOn={toggleOnDropDownProductMenu}
                typeSearch="products"
                placeholder="Search..."
                isShowDefault={true}
              />
            </Col>
            <Col lg={8} className="text-end">
              <ButtonTheme
                className="btn btn-icon btn-light flex-shrink-0 border"
                onClick={() => {
                  getData(id);
                }}
                disabled={!id || loading}
              >
                <i className="ri-refresh-line"></i>
              </ButtonTheme>
            </Col>
          </Row>
          {loading ? (
            <Loader />
          ) : (
            <Fragment>
              {id ? (
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col lg={12}>
                            <div className="d-flex gap-2 mb-3">
                              <div className="flex-shrink-0 px-3">
                                <ImageCustom
                                  image={product?.images?.[0]?.url}
                                  name={product.name}
                                  avatarSize={"avatar-xl"}
                                />
                              </div>
                              <div className="flex-grow-1">
                                <h5 className="card-title">{t(product?.name || "---")}</h5>
                                <div>
                                  <GradientCircleRadialbar
                                    dataColors='["--color-success"]'
                                    percent={percent}
                                    height={100}
                                    width={100}
                                    fontSize="17px"
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col lg={6} className="h-100 mb-3">
                            <SellThroughChart
                              dataColors='["--color-primary", "--color-success"]'
                              metricsByMonth={product?.metrics_by_month || {}}
                              initStock={product?.initial_stock || 0}
                              t={t}
                            />
                            <h5 className="card-title mb-0 text-truncate text-center">{t("Selling through")}</h5>
                          </Col>
                          <Col lg={6} className="h-100 mb-3">
                            <AverageChart
                              dataColors='["--color-primary", "--color-success"]'
                              metricsByMonth={product?.metrics_by_month || {}}
                              t={t}
                            />
                            <h5 className="card-title mb-0 text-truncate text-center">
                              {t("Average discount")}/{t("Average sale price")}
                            </h5>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={3}>
                    <Card>
                      <CardHeader>
                        <Row className="g-4 align-items-center">
                          <div className="col-sm">
                            <div>
                              <h5 className="card-title mb-0">{t("Variants")}</h5>
                            </div>
                          </div>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <SimpleBar style={{ maxHeight: "500px" }}>
                          <Nav pills className="flex-column nav-light" id="v-pills-tab">
                            {(product
                              ? product.variants && product.variants.length > 0
                                ? product.variants
                                : [product]
                              : []
                            ).map((variant, key) => {
                              const variantKeys = Object.keys(variant.metrics_by_month || {});
                              const variantLastKey = variantKeys?.[variantKeys.length - 1];
                              const variantLastValue = variantLastKey
                                ? variant?.metrics_by_month?.[variantLastKey]
                                : {};
                              const variantSoldUnits = variantLastValue?.sold_units || 0;
                              const variantPercent =
                                variant.initial_stock > 0 ? (variantSoldUnits / variant.initial_stock) * 100 : 0;
                              return (
                                <NavItem key={key} id={variant.id}>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      "mb-2": true,
                                      active: verticalTab === variant.id,
                                    })}
                                    onClick={() => {
                                      toggleVertical(variant.id);
                                    }}
                                  >
                                    <div className="hstack gap-2">
                                      <div className="flex-shrink-0">
                                        <ImageCustom
                                          image={variant.images?.[0]?.url}
                                          name={variant.name}
                                          avatarSize={"avatar-sm"}
                                        />
                                      </div>
                                      <div className="flex-grow-1">
                                        <Tooltip title={variant?.name}>
                                          <p className="p-0 text-truncate-two-lines">{t(variant?.name || "---")}</p>
                                        </Tooltip>
                                      </div>
                                      <div className="flex-shrink-0">
                                        <GradientCircleRadialbar
                                          dataColors='["--color-success"]'
                                          percent={variantPercent.toFixed(0)}
                                        />
                                      </div>
                                    </div>
                                  </NavLink>
                                </NavItem>
                              );
                            })}
                          </Nav>
                        </SimpleBar>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={9}>
                    <TabContent activeTab={verticalTab} className="mt-4 mt-md-0" id="v-pills-tabContent">
                      {(product.variants ? product.variants : []).map((variant, key) => {
                        const purchase_ids = variant.purchase_ids ? variant.purchase_ids.join(",") : "";
                        return (
                          <TabPane tabId={variant.id} key={key}>
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col lg={12}>
                                    <div className="d-flex gap-2 mb-3">
                                      <div className="flex-shrink-0">
                                        <ImageCustom
                                          image={variant?.images?.[0]?.url}
                                          name={variant.name}
                                          avatarSize={"avatar-xl"}
                                        />
                                      </div>
                                      <div className="flex-grow-1">
                                        <h5 className="card-title text-truncate-two-lines">
                                          {t(variant?.name || "---")}
                                        </h5>
                                        <p>
                                          <span>{t("Initial date")}:</span>{" "}
                                          <span>
                                            {variant?.initial_date ? formatDateTime(variant?.initial_date) : "---"}
                                          </span>
                                        </p>
                                      </div>
                                      <div className="flex-shrink-0">
                                        <ButtonTheme
                                          type="button"
                                          className="btn btn-soft-info btn-sm"
                                          onClick={() => {
                                            navigate(`/purchase-orders?id=${purchase_ids}`);
                                          }}
                                          disabled={!variant?.purchase_ids || variant?.purchase_ids.length === 0}
                                          icon="ri-article-line"
                                        >
                                          {t("View purchase order")}
                                        </ButtonTheme>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg={6} className="h-100 mb-3">
                                    <SellThroughChart
                                      dataColors='["--color-primary", "--color-success"]'
                                      metricsByMonth={variant?.metrics_by_month || {}}
                                      initStock={variant?.initial_stock || 0}
                                      t={t}
                                    />
                                    <h5 className="card-title m-auto text-truncate text-center">
                                      {t("Selling through")}
                                    </h5>
                                  </Col>
                                  <Col lg={6} className="h-100 mb-3">
                                    <BreakEventChart
                                      dataColors='["--color-danger", "--color-primary"]'
                                      metricsByMonth={variant?.metrics_by_month || {}}
                                      cost={variant?.cost || 0}
                                      t={t}
                                    />
                                    <h5 className="card-title m-auto text-truncate text-center">{t("Break Event")}</h5>
                                  </Col>
                                  <Col lg={6} className="h-100 mb-3">
                                    <AverageChart
                                      dataColors='["--color-primary", "--color-success"]'
                                      metricsByMonth={variant?.metrics_by_month || {}}
                                      t={t}
                                    />
                                    <h5 className="card-title m-auto text-truncate text-center">
                                      {t("Average sale price")}/{t("Average discount")}
                                    </h5>
                                  </Col>
                                  <Col lg={6} className="h-100 mb-3">
                                    <AveragePercentChart
                                      dataColors='["--color-primary", "--color-success"]'
                                      metricsByMonth={variant?.metrics_by_month || {}}
                                      t={t}
                                    />
                                    <h5 className="card-title m-auto text-truncate text-center">
                                      {t("Average sale price")}/{t("Average discount")} (%)
                                    </h5>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </TabPane>
                        );
                      })}
                    </TabContent>
                  </Col>
                </Row>
              ) : (
                <div className="py-4 text-center">
                  <LottieIcon src="https://cdn.lordicon.com/msoeawqm.json" style={{ width: "72px", height: "72px" }} />

                  <div className="mt-4">
                    <h5>{t("Select product to view report")}</h5>
                  </div>
                </div>
              )}
            </Fragment>
          )}
        </Container>
      </div>
    </Fragment>
  );
};

export default withRouter(ProductReportDetails);
