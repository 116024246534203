import {
  LIST_PAYMENT_METHOD,
  LIST_PAYMENT_METHOD_FAIL,
  LIST_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_FAIL,
  ADD_NEW_PAYMENT_METHOD,
  ADD_PAYMENT_METHOD_SUCCESS,
  ADD_PAYMENT_METHOD_FAIL,
  UPDATE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPDATE_PAYMENT_METHOD_FAIL,
  GET_PAYMENT_METHOD,
  GET_PAYMENT_METHOD_FAIL,
  GET_PAYMENT_METHOD_SUCCESS,
  RESET_PAYMENT_METHOD_FLAG,
} from "./actionType";

export const listPaymentMethod = (options) => ({
  type: LIST_PAYMENT_METHOD,
  payload: options,
});

export const listPaymentMethodFail = (error) => ({
  type: LIST_PAYMENT_METHOD_FAIL,
  payload: error,
});

export const listPaymentMethodSuccess = (paymentMethods) => ({
  type: LIST_PAYMENT_METHOD_SUCCESS,
  payload: paymentMethods,
});

export const getPaymentMethod = (paymentMethodID) => ({
  type: GET_PAYMENT_METHOD,
  payload: paymentMethodID,
});

export const getPaymentMethodFail = (error) => ({
  type: GET_PAYMENT_METHOD_FAIL,
  payload: error,
});

export const getPaymentMethodSuccess = (paymentMethod) => ({
  type: GET_PAYMENT_METHOD_SUCCESS,
  payload: paymentMethod,
});

export const deletePaymentMethod = (paymentMethod) => ({
  type: DELETE_PAYMENT_METHOD,
  payload: paymentMethod,
});

export const deletePaymentMethodSuccess = (paymentMethod) => ({
  type: DELETE_PAYMENT_METHOD_SUCCESS,
  payload: paymentMethod,
});

export const deletePaymentMethodFail = (error) => ({
  type: DELETE_PAYMENT_METHOD_FAIL,
  payload: error,
});

export const updatePaymentMethod = (paymentMethod, handleAfterSubmit = () => {}) => ({
  type: UPDATE_PAYMENT_METHOD,
  payload: { paymentMethod, handleAfterSubmit },
});

export const updatePaymentMethodSuccess = (paymentMethod) => ({
  type: UPDATE_PAYMENT_METHOD_SUCCESS,
  payload: paymentMethod,
});

export const updatePaymentMethodFail = (error) => ({
  type: UPDATE_PAYMENT_METHOD_FAIL,
  payload: error,
});

export const addNewPaymentMethod = (paymentMethod, handleAfterSubmit = () => {}) => ({
  type: ADD_NEW_PAYMENT_METHOD,
  payload: { paymentMethod, handleAfterSubmit },
});

export const addPaymentMethodSuccess = (paymentMethod) => ({
  type: ADD_PAYMENT_METHOD_SUCCESS,
  payload: paymentMethod,
});

export const addPaymentMethodFail = (error) => ({
  type: ADD_PAYMENT_METHOD_FAIL,
  payload: error,
});

export const resetPaymentMethodFlag = () => ({
  type: RESET_PAYMENT_METHOD_FLAG,
});
