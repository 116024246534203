import {
  LIST_ACTIVITY,
  LIST_ACTIVITY_FAIL,
  LIST_ACTIVITY_SUCCESS,
  GET_ACTIVITY,
  GET_ACTIVITY_FAIL,
  RESET_ACTIVITY_FLAG,
  GET_ACTIVITY_SUCCESS,
} from "./actionType";

export const listActivity = (options) => ({
  type: LIST_ACTIVITY,
  payload: options,
});

export const listActivityFail = (error) => ({
  type: LIST_ACTIVITY_FAIL,
  payload: error,
});

export const listActivitySuccess = (activities) => ({
  type: LIST_ACTIVITY_SUCCESS,
  payload: activities,
});

export const getActivity = (activityID) => ({
  type: GET_ACTIVITY,
  payload: activityID,
});

export const getActivityFail = (error) => ({
  type: GET_ACTIVITY_FAIL,
  payload: error,
});

export const getActivitySuccess = (error) => ({
  type: GET_ACTIVITY_SUCCESS,
  payload: error,
});

export const resetActivity = () => ({
  type: RESET_ACTIVITY_FLAG,
});
