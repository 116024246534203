import { Actions } from "../../../../Components/constants/common";
import {
  LIST_PRODUCT,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAIL,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  LIST_PRODUCT_SUCCESS,
  LIST_PRODUCT_FAIL,
  ADD_NEW_PRODUCT,
  UPDATE_PRODUCT,
  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAIL,
  RESET_PRODUCT_FLAG,
} from "./actionType";

const INIT_STATE = {
  products: [],
  product: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const Products = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_PRODUCT:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_PRODUCT_SUCCESS:
      return {
        ...state,
        message: "Get products success",
        loading: false,
        success: true,
        products: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_PRODUCT_FAIL:
      return {
        ...state,
        message: "Get products failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_PRODUCT:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        message: "Get product success",
        loading: false,
        success: true,
        product: action.payload,
        action: Actions.EMPTY,
      };

    case GET_PRODUCT_FAIL:
      return {
        ...state,
        message: "Get product failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_PRODUCT:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        message: "Delete product success",
        loading: false,
        success: true,
        products: state.products.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_PRODUCT_FAIL:
      return {
        ...state,
        message: "Delete product failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_NEW_PRODUCT:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        message: "Add product success",
        success: true,
        loading: false,
        products: [ action.payload,...state.products],
        product: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_PRODUCT_FAIL:
      return {
        ...state,
        message: "Add product failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_PRODUCT:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.UPDATE,
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        message: "Update product success",
        loading: false,
        success: true,
        products: state.products.map((product) =>
          product.id.toString() === action.payload.id.toString() ? { ...product, ...action.payload } : product,
        ),
        product: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_PRODUCT_FAIL:
      return {
        ...state,
        message: "Update product failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case RESET_PRODUCT_FLAG:
      return {
        ...state,
        message: "",
        product: {},
        success: false,
        action: Actions.EMPTY,
      };

    default:
      return { ...state };
  }
};

export default Products;
