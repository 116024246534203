// Actions
export const LIST_VOUCHER = "LIST_VOUCHER";
export const LIST_VOUCHER_SUCCESS = "LIST_VOUCHER_SUCCESS";
export const LIST_VOUCHER_FAIL = "LIST_VOUCHER_FAIL";

export const GET_VOUCHER = "GET_VOUCHER";
export const GET_VOUCHER_SUCCESS = "GET_VOUCHER_SUCCESS";
export const GET_VOUCHER_FAIL = "GET_VOUCHER_FAIL";

export const DELETE_VOUCHER = "DELETE_VOUCHER";
export const DELETE_VOUCHER_SUCCESS = "DELETE_VOUCHER_SUCCESS";
export const DELETE_VOUCHER_FAIL = "DELETE_VOUCHER_FAIL";

export const ADD_NEW_VOUCHER = "ADD_NEW_VOUCHER";
export const ADD_VOUCHER_SUCCESS = "ADD_VOUCHER_SUCCESS";
export const ADD_VOUCHER_FAIL = "ADD_VOUCHER_FAIL";

export const UPDATE_VOUCHER = "UPDATE_VOUCHER";
export const UPDATE_VOUCHER_SUCCESS = "UPDATE_VOUCHER_SUCCESS";
export const UPDATE_VOUCHER_FAIL = "UPDATE_VOUCHER_FAIL";

export const RESET_VOUCHER_FLAG = "RESET_VOUCHER_FLAG";
