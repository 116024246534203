import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";

const DeleteOrderModal = ({ show, onDeleteClick, onCloseClick, index }) => {
  const { t } = useTranslation();
  return (
    <Modal fade={true} isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader>
        {t("Close order")} {index + 1}
      </ModalHeader>
      <ModalBody className="py-3">
        <p className="text-muted">{t("System will not save the information of this order.")}</p>
        <p className="text-muted">{t("Are you sure about closing this order?")}</p>
      </ModalBody>
      <ModalFooter>
        <ButtonTheme type="button" className="btn w-sm btn-light" onClick={onCloseClick}>
          {t("Close")}
        </ButtonTheme>
        <ButtonTheme type="button" id="delete-record" className="btn w-sm btn-danger" onClick={onDeleteClick}>
          {t("Submit")}
        </ButtonTheme>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteOrderModal;
