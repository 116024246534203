import { Fragment, useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import { isEmpty } from "lodash";
import { Col, Input, Label, Nav, NavItem, NavLink, Row } from "reactstrap";
import {
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

import { deepCopy } from "../../../../../../helpers/format_helper";

import CustomTable from "./Table";
import ButtonTheme from "../../../../../../Components/Common/ButtonTheme";
import { DefaultColumnFilter } from "../../../../../../Components/Common/filters";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import InputTheme from "../../../../../../Components/Common/InputTheme";
import { useDebounce } from "../../../../../../Components/Hooks/UseDebounce";

const VariantTable = ({
  variants,
  onChangeVariants,
  options,
  isPrice = true,
  onApplyPrice,
  onApplyMeasurements,
  router,
  locations,
  priceGroups,
}) => {
  const { navigate, location, params, t } = router;
  const { id } = params;

  const [activeTabPrice, setActiveTabPrice] = useState(1);
  const toggleTabPrice = (tab) => {
    if (activeTabPrice !== tab) {
      setActiveTabPrice(tab);
    }
  };

  const [heightValueAll, setHeightValueAll] = useState(0);
  const [heightUnitAll, setHeightUnitAll] = useState("cm");
  const [widthValueAll, setWidthValueAll] = useState(0);
  const [widthUnitAll, setWidthUnitAll] = useState("cm");
  const [lengthValueAll, setLengthValueAll] = useState(0);
  const [lengthUnitAll, setLengthUnitAll] = useState("cm");
  const [weightValueAll, setWeightValueAll] = useState(0);
  const [weightUnitAll, setWeightUnitAll] = useState("g");
  const [priceAll, setPriceAll] = useState("");

  const handleApplyPrice = () => {
    onApplyPrice({ activeTabPrice, priceAll });
  };

  const handleApplyMeasurement = () => {
    onApplyMeasurements({
      height_value: heightValueAll,
      height_unit: heightUnitAll,
      width_value: widthValueAll,
      width_unit: widthUnitAll,
      length_value: lengthValueAll,
      length_unit: lengthUnitAll,
      weight_value: weightValueAll,
      weight_unit: weightUnitAll,
    });
  };

  const handleChangePrice = (value, priceGroup, row) => {
    if (value !== "") {
      let newData = { price: value, price_group: { name: priceGroup.name, id: priceGroup.id } };
      let priceIndex = row.prices.findIndex((price) => price?.price_group?.id === priceGroup.id);
      let variantIndex = variants.findIndex((variant) => variant.sku === row?.sku);
      let newVariants = deepCopy(variants);
      if (priceIndex === -1) {
        newVariants[variantIndex].prices.push(newData);
      } else {
        newVariants[variantIndex].prices[priceIndex] = newData;
      }
      onChangeVariants(newVariants);
    }
  };

  const handleChangeMeasurements = (data, row) => {
    console.log(row);
    let newVariants = variants.map((item) => {
      if (item.sku === row?.sku) {
        item.measurements = data;
      }
      return item;
    });
    console.log(newVariants);
    onChangeVariants(newVariants);
  };

  const handleChangeSku = (data) => {
    onChangeVariants(data);
  };

  const renderOptions = () => {
    return options
      .filter((item) => !isEmpty(item.name) && !isEmpty(item.values))
      .map((item, index) => {
        return {
          Header: item?.name,
          Cell: (cell) => {
            let data = cell.row.original;
            return (
              <div className="d-flex align-items-center">
                {index === 0 && (
                  <div className="hstack flex-shrink-0 me-3">
                    {data?.unit?.id && <i className="ri-corner-down-right-line me-3" />}
                    <div className="avatar-sm bg-light rounded p-1">
                      <img
                        src={data?.images?.[0]?.url ?? data?.images?.[0]?.image}
                        alt=""
                        className="img-fluid d-block"
                      />
                    </div>
                  </div>
                )}

                <div className="flex-grow-1">
                  {index === 0 && (
                    <h5 className="fs-14 mb-0">
                      {data?.option1} {data?.unit?.id && <span>({data.unit.name})</span>}
                    </h5>
                  )}
                  {index === 1 && <h5 className="fs-14 mb-0">{data?.option2}</h5>}
                  {index === 2 && <h5 className="fs-14 mb-0">{data?.option3}</h5>}
                </div>
              </div>
            );
          },
        };
      });
  };

  const renderPriceGroup = () => {
    if (!isPrice) {
      return [];
    }
    if (priceGroups.length === 0) {
      return [];
    }
    let priceGroup = priceGroups[activeTabPrice - 1];
    return [
      {
        Header: priceGroup.name,
        Cell: (cell) => {
          return <PriceInput item={priceGroup} handleChangePrice={handleChangePrice} row={cell.row.original} />;
        },
      },
    ];
  };
  const renderMeasurements = () => {
    if (isPrice) {
      return [];
    }
    return [
      {
        Header: t("Height"),
        Cell: (cell) => {
          let variant = cell.row.original;
          return (
            <MeasurementInput
              onChangeMeasurements={(value, unit) => {
                console.log({ ...variant?.measurements, height_value: value, height_unit: unit });
                handleChangeMeasurements({ ...variant?.measurements, height_value: value, height_unit: unit }, variant);
              }}
              value={variant?.measurements?.height_value ?? 0}
              unit={variant?.measurements?.height_unit ?? "cm"}
            />
          );
        },
      },
      {
        Header: t(`Length`),
        Cell: (cell) => {
          let variant = cell.row.original;
          return (
            <MeasurementInput
              onChangeMeasurements={(value, unit) => {
                handleChangeMeasurements({ ...variant?.measurements, length_value: value, length_unit: unit }, variant);
              }}
              value={variant?.measurements?.length_value ?? 0}
              unit={variant?.measurements?.length_unit ?? "cm"}
            />
          );
        },
      },
      {
        Header: t(`Width`),
        Cell: (cell) => {
          let variant = cell.row.original;
          return (
            <MeasurementInput
              onChangeMeasurements={(value, unit) => {
                handleChangeMeasurements({ ...variant?.measurements, width_value: value, width_unit: unit }, variant);
              }}
              value={variant?.measurements?.width_value ?? 0}
              unit={variant?.measurements?.width_unit ?? "cm"}
            />
          );
        },
      },
      {
        Header: t(`Weight`),
        Cell: (cell) => {
          let variant = cell.row.original;
          return (
            <MeasurementInput
              onChangeMeasurements={(value, unit) => {
                handleChangeMeasurements({ ...variant?.measurements, weight_value: value, weight_unit: unit }, variant);
              }}
              value={variant?.measurements?.weight_value ?? 0}
              unit={variant?.measurements?.weight_unit ?? "g"}
              isWeight={true}
            />
          );
        },
      },
    ];
  };
  const columns = useMemo(
    () => [
      ...renderOptions(),
      {
        Header: t("Sku"),
        Cell: (cell) => <SkuInput row={cell.row.original} onChangeSku={handleChangeSku} variants={variants} />,
      },
      ...renderPriceGroup(),
      ...renderMeasurements(),
    ],
    [variants, activeTabPrice],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: variants || [],
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: 100000,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
  );

  return (
    <Row>
      {isPrice ? (
        <div className="mb-3">
          <Nav tabs className="nav-tabs mb-3">
            {priceGroups.map((item, key) => (
              <NavItem key={key}>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: activeTabPrice === key + 1 })}
                  onClick={() => {
                    toggleTabPrice(key + 1);
                  }}
                >
                  {t(item.name)}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <Row className="mb-3">
            <Col md="auto" className="align-self-end">
              <ButtonTheme type="button" className="btn btn-primary" onClick={handleApplyPrice}>
                {t("Apply for all")}
              </ButtonTheme>
            </Col>
            <Col md={2}>
              <Label className="form-label" for="price-all-input">
                {t("Price")}
              </Label>
              <InputTheme
                id="price-all-input"
                isForm={false}
                format="price"
                min={0}
                className="form-control"
                placeholder={t("Price")}
                value={priceAll}
                onChange={(data) => setPriceAll(data)}
              />
            </Col>
          </Row>
        </div>
      ) : (
        <Row className="mb-3">
          <Col md="auto" className="align-self-end">
            <ButtonTheme type="button" className="btn btn-primary" onClick={handleApplyMeasurement}>
              {t("Apply for all")}
            </ButtonTheme>
          </Col>
          <Col md={2}>
            <InputTheme
              label="Height"
              id="height-all-input"
              type="number"
              isForm={false}
              value={heightValueAll}
              onChange={(data) => setHeightValueAll(Number(data) ?? 0)}
              customRenderEnd={
                <>
                  <Input
                    type="select"
                    value={heightUnitAll}
                    className="input-group-text"
                    onChange={(e) => {
                      setHeightUnitAll(e.target.value);
                    }}
                  >
                    <Fragment>
                      <option value="mm">mm</option>
                      <option value="cm">cm</option>
                      <option value="m">m</option>
                    </Fragment>
                  </Input>
                </>
              }
            />
          </Col>
          <Col md={2}>
            <InputTheme
              label="Length"
              type="number"
              id="length-all-input"
              isForm={false}
              value={lengthValueAll}
              onChange={(data) => {
                setLengthValueAll(Number(data) ?? 0);
              }}
              customRenderEnd={
                <>
                  <Input
                    type="select"
                    value={lengthUnitAll}
                    className="input-group-text"
                    onChange={(e) => {
                      setLengthUnitAll(e.target.value);
                    }}
                  >
                    <Fragment>
                      <option value="mm">mm</option>
                      <option value="cm">cm</option>
                      <option value="m">m</option>
                    </Fragment>
                  </Input>
                </>
              }
            />
          </Col>
          <Col md={2}>
            <InputTheme
              id="width-all-input"
              label="Width"
              type="number"
              isForm={false}
              value={widthValueAll}
              onChange={(data) => setWidthValueAll(Number(data) ?? 0)}
              customRenderEnd={
                <>
                  <Input
                    type="select"
                    value={widthUnitAll}
                    className="input-group-text"
                    onChange={(e) => {
                      setWidthUnitAll(e.target.value);
                    }}
                  >
                    <Fragment>
                      <option value="mm">mm</option>
                      <option value="cm">cm</option>
                      <option value="m">m</option>
                    </Fragment>
                  </Input>
                </>
              }
            />
          </Col>
          <Col md={2}>
            <InputTheme
              id="weight-all-input"
              label="Weight"
              type="number"
              isForm={false}
              value={weightValueAll}
              onChange={(data) => setWeightValueAll(Number(data) ?? 0)}
              customRenderEnd={
                <>
                  <Input
                    type="select"
                    value={weightUnitAll}
                    className="input-group-text"
                    onChange={(e) => {
                      setWeightUnitAll(e.target.value);
                    }}
                  >
                    <Fragment>
                      <option value="gr">gr</option>
                      <option value="g">g</option>
                      <option value="kg">kg</option>
                    </Fragment>
                  </Input>
                </>
              }
            />
          </Col>
        </Row>
      )}
      <CustomTable
        headerGroups={headerGroups}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        page={page}
        prepareRow={prepareRow}
        isPrice={isPrice}
      />
    </Row>
  );
};

const PriceInput = ({ item, handleChangePrice, row }) => {
  const { t } = useTranslation();
  let data = (row?.prices || []).find((value) => value?.price_group.id === item.id)?.price ?? "";
  const [priceValue, setPriceValue] = useState(data);
  return (
    <InputTheme
      isForm={false}
      format="price"
      className="form-control text-end"
      placeholder={t("Price")}
      value={priceValue}
      onChange={(data) => setPriceValue(data)}
      onBlur={(e) => handleChangePrice(priceValue, item, row)}
    />
  );
};

const SkuInput = ({ row, onChangeSku, variants }) => {
  const { t } = useTranslation();
  const [skuValue, setSkuValue] = useState(row.sku ?? "");
  const handleChangeSku = () => {
    if (skuValue === "") {
      toast.error(t("Sku must be filled"));
      setSkuValue(row.sku ?? "");
    } else {
      let check = variants.find((item) => item.sku === skuValue && item.sku !== row?.sku);
      if (check) {
        toast.error(t("Available sku"));
        setSkuValue(row.sku ?? "");
      } else {
        let variantIndex = variants.findIndex((variant) => variant.sku === row?.sku);
        let newVariants = deepCopy(variants);
        newVariants[variantIndex].sku = skuValue;
        onChangeSku(newVariants);
      }
    }
  };
  return (
    <Input
      type="text"
      className="form-control w-lg"
      value={skuValue}
      placeholder="Sku"
      onChange={(e) => {
        setSkuValue(e.target.value);
      }}
      onBlur={handleChangeSku}
    />
  );
};

const MeasurementInput = ({ isWeight = false, unit, value, onChangeMeasurements = () => {} }) => {
  const [tempValue, setTempValue] = useState(0);
  useEffect(() => {
    setTempValue(value ?? 0);
  }, [value]);

  return (
    <InputTheme
      type="number"
      isForm={false}
      value={tempValue}
      onChange={(data) => setTempValue(Number(data) ?? 0)}
      onBlur={() => {
        onChangeMeasurements(tempValue, unit);
      }}
      customRenderEnd={
        <>
          <Input
            type="select"
            value={unit}
            className="input-group-text"
            onChange={(e) => {
              onChangeMeasurements(tempValue, e.target.value);
            }}
          >
            {isWeight ? (
              <Fragment>
                <option value="gr">gr</option>
                <option value="g">g</option>
                <option value="kg">kg</option>
              </Fragment>
            ) : (
              <Fragment>
                <option value="mm">mm</option>
                <option value="cm">cm</option>
                <option value="m">m</option>
              </Fragment>
            )}
          </Input>
        </>
      }
    />
  );
};

export default VariantTable;
