import moment from "moment/moment";
import { DiscountTypes, EffectiveProduct } from "../Components/constants/common";
import i18n, { resources } from "../i18n";
export const formatCurrency = (x) => {
  x = Number(x ? x : "0");
  return x.toLocaleString("vn-VN", {
    style: "currency",
    currency: "VND",
  });
};

export const formatViewCurrency = (x = "") => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getDateTimeRemaining = (endDate = "") => {
  const a = moment(new Date(endDate), "DD-MM-YYYY HH:mm");
  const time = a.diff(new Date(), "hours");

  return time;
};

export const formatNumber = (x) => {
  x = x ? x : "0";
  return x.toLocaleString("vn-VN", {
    currency: "VND",
  });
};

export const formatDateTime = (x) => {
  try {
    return new Date(x).toLocaleString("vn-VN", { hour12: false });
  } catch (error) {
    return "";
  }
};

export const formatDate = (x) => {
  try {
    return moment(x).calendar();
  } catch (error) {
    return "";
  }
};
export const formatFromNow = (x) => {
  try {
    return moment(x).fromNow();
  } catch (error) {
    return "";
  }
};

export const convertValidDate = (date) => {
  const date1 = moment(new Date(date)).format("DD MMM Y");
  return date1;
};

export const convertValidTime = (time) => {
  const time1 = new Date(time);
  const getHour = time1.getHours();
  const getMin = time1.getMinutes();
  const getTime = `${getHour}:${getMin}`;
  var meridiem = "";
  if (getHour >= 12) {
    meridiem = "PM";
  } else {
    meridiem = "AM";
  }
  const updateTime = moment(getTime, "hh:mm").format("hh:mm") + " " + meridiem;
  return updateTime;
};

export const formatVNDCurrency = (x) => {
  const VND = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  });
  try {
    const price = Number(x);
    if (isNaN(price)) {
      return VND.format(0);
    } else {
      return VND.format(price);
    }
  } catch (e) {
    return VND.format(0);
  }
};

export const keyExists = (obj, key, isValue = false) => {
  if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
    return false;
  } else if (obj.hasOwnProperty.call(obj, key)) {
    if (isValue) {
      return obj[key];
    } else {
      return true;
    }
  } else if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      const result = keyExists(obj[i], key, isValue);
      if (result) {
        return result;
      }
    }
  } else {
    for (const k in obj) {
      const result = keyExists(obj[k], key, isValue);
      if (result) {
        return result;
      }
    }
  }
  return false;
};

export function deepCopy(src, keyFind, obj) {
  let target = Array.isArray(src) ? [] : {};
  for (let key in src) {
    let v = src[key];
    if (v) {
      if (typeof v === "object") {
        target[key] = deepCopy(v, keyFind, obj);
      } else {
        if (keyFind === key) {
          target[key] = { v, ...obj };
        } else {
          target[key] = v;
        }
      }
    } else {
      if (keyFind === key) {
        target[key] = { ...v, ...obj };
      } else {
        target[key] = v;
      }
    }
  }
  return target;
}

export function mapObjectToTree(obj) {
  const tree = [];

  function traverse(node, parent) {
    const keys = Object.keys(node);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = node[key];
      const newNode = {
        value: `${parent ? parent.value + "." : ""}${key}`,
        title: key,
      };
      if (typeof value === "object" && value !== null) {
        newNode.children = [];
        traverse(value, newNode);
      }
      if (parent) {
        parent.children = parent.children || [];
        parent.children.push(newNode);
      } else {
        tree.push(newNode);
      }
    }
  }
  traverse(obj, null);
  return tree;
}

export const convertNameFolder = (string) => {
  return string.replace("/", "");
};

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const dateFormat = () => {
  let d = new Date(),
    months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let h = d.getHours() % 12 || 12;
  let ampm = d.getHours() < 12 ? "AM" : "PM";
  return (
    d.getDate() +
    " " +
    months[d.getMonth()] +
    ", " +
    d.getFullYear() +
    ", " +
    h +
    ":" +
    d.getMinutes() +
    " " +
    ampm
  ).toString();
};

export function convertToTreeView(nodes, parent_category_id = null) {
  const tree = [];

  nodes.forEach((node) => {
    if (
      node.parent_category_id === parent_category_id ||
      (parent_category_id === null && !node.parent_category_id) ||
      (parent_category_id === null && nodes.findIndex((item) => item.id === node.parent_category_id) === -1)
    ) {
      const newNode = { key: node.id, title: node.name, data: node, children: convertToTreeView(nodes, node.id) };
      tree.push(newNode);
    }
  });

  return tree;
}

export function convertToNonAccentVietnamese(str) {
  str = str.replace(/A|Á|À|Ã|Ạ|Â|Ấ|Ầ|Ẫ|Ậ|Ă|Ắ|Ằ|Ẵ|Ặ/g, "A");
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/E|É|È|Ẽ|Ẹ|Ê|Ế|Ề|Ễ|Ệ/, "E");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/I|Í|Ì|Ĩ|Ị/g, "I");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/O|Ó|Ò|Õ|Ọ|Ô|Ố|Ồ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ỡ|Ợ/g, "O");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/U|Ú|Ù|Ũ|Ụ|Ư|Ứ|Ừ|Ữ|Ự/g, "U");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/Y|Ý|Ỳ|Ỹ|Ỵ/g, "Y");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/Đ/g, "D");
  str = str.replace(/đ/g, "d");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
  return str;
}

export const getChartColorsArray = (colors) => {
  colors = JSON.parse(colors);
  return colors.map(function (value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);

      if (color.indexOf("#") !== -1) color = color.replace(" ", "");
      if (color) return color;
      else return newValue;
    } else {
      var val = value.split(",");
      if (val.length === 2) {
        var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
};

export const returnDiscountInfoFieldByType = (type) => {
  switch (type) {
    case DiscountTypes.BY_ORDER_TOTAL:
      return "discount_by_order_total";
    case DiscountTypes.BY_PRODUCT:
      return "discount_by_product";
    case DiscountTypes.PRODUCT_BY_ORDER_TOTAL:
      return "discount_product_by_order_total";
    case DiscountTypes.BY_QUANTITY:
      return "discount_by_quantity";
    case DiscountTypes.BY_PURCHASE_PRODUCT:
      return "discount_by_purchase_product";
    case DiscountTypes.GIFT_BY_PURCHASE_PRODUCT:
      return "gift_by_purchase_product";
    case DiscountTypes.GIFT_BY_ORDER_TOTAL:
      return "gift_by_order_total";
    default:
      return "";
  }
};

export const handleReturnQuantityByItemType = (condition_line, order_line_items, type = "") => {
  let filtered_order_items = [];
  const item_type = condition_line[`${type ? `${type}product_type` : "item_type"}`];
  const condition_line_key = `${type}${item_type.toLowerCase()}`;
  filtered_order_items = order_line_items.filter((order_line_item) => {
    return condition_line[condition_line_key].find((item) => {
      if (item_type === EffectiveProduct.PRODUCTS) {
        return item.id === order_line_item.product_id;
      }
      if (item_type === EffectiveProduct.VARIANTS) {
        return item.id === order_line_item.variant_id;
      }
      if (item_type === EffectiveProduct.CATEGORIES) {
        return item.id === order_line_item.category?.id;
      }
      if (item_type === EffectiveProduct.PRODUCTS) {
        return item.id === order_line_item.brand?.id;
      }
      return false;
    });
  });
  const total_quantity = filtered_order_items.reduce(
    (total, order_line_item) => total + Number(order_line_item.quantity),
    0,
  );
  return total_quantity;
};

export const getLoggedInUserSettings = () => {
  try {
    const user = localStorage.getItem("settings");
    return user ? JSON.parse(user) : null;
  } catch (error) {
    console.error("Invalid settings", String(error));
    return null;
  }
};
export const setLoggedInUserSettings = (data) => {
  localStorage.setItem("settings", JSON.stringify(data));
};

export const handleSetColorCss = (mode, keys = [], color) => {
  keys.forEach((key) => {
    const modeElement = document.querySelector(`[data-layout-mode="${mode}"]`);
    if (modeElement) {
      if (key === "border-color" || key === "body-color") {
        modeElement.style.setProperty(`--vz-${key}`, color[key]);
      } else {
        modeElement.style.setProperty(`--color-${key}`, color[key]);
      }
    }
  });
};

export const updateFavicon = (state) => {
  const favicon = document.querySelector('link[rel="icon"]');
  favicon.href = state ? state : "/favicon.ico";
};

export const formatDate1 = (date) => {
  const currentDate = new Date(date);
  const year = currentDate.getFullYear();
  const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
  const day = ("0" + currentDate.getDate()).slice(-2);
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const removeUnnecessaryProps = (data, removeKeys) => {
  removeKeys.forEach((objKey) => {
    delete data[objKey];
  });
  return data;
};

export function formatDateFromISOString(isoString) {
  const date = new Date(isoString);

  // Extract the day, month, and year
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();

  // Format as dd-MM-yyyy
  return `${day}-${month}-${year}`;
}

export const filterByName = (data, query) => {
  return data.filter((item) => {
    const option = i18n.language === "vi" ? resources.vi.translation?.[item?.name] || item.name : item.name;
    return option.toLowerCase().includes(query.toLowerCase());
  });
};

export const calculateDiscountByCustomerGroup = (customerGroup, customer, subTotal, applyDiscount = false) => {
  if (!customerGroup || !applyDiscount) return 0;

  const discountPercent = customer.discount_percent || 0;
  const maxDiscountAmountPerOrder = customer.max_discount_amount_per_order;
  const minOrderAmount = customer.min_order_amount;

  const discountAmountByCustomerGroup = (discountPercent * subTotal) / 100;

  if (minOrderAmount === null || minOrderAmount === undefined) {
    if (maxDiscountAmountPerOrder !== null && maxDiscountAmountPerOrder !== undefined) {
      return Math.min(discountAmountByCustomerGroup, maxDiscountAmountPerOrder);
    } else {
      return discountAmountByCustomerGroup;
    }
  } else {
    if (minOrderAmount <= subTotal) {
      if (maxDiscountAmountPerOrder !== null && maxDiscountAmountPerOrder !== undefined) {
        return Math.min(discountAmountByCustomerGroup, maxDiscountAmountPerOrder);
      } else {
        return discountAmountByCustomerGroup;
      }
    } else {
      return 0;
    }
  }
};
