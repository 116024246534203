import { CLOSE_TOUR, OPEN_TOUR, SET_CONFIG_STEPS } from "./actionType";

export const setConfigTour = (response) => ({
  type: SET_CONFIG_STEPS,
  payload: response,
});

export const handleOpenTour = () => ({
  type: OPEN_TOUR,
});

export const closeTour = () => ({
  type: CLOSE_TOUR,
});
