import { Actions } from "../../../../Components/constants/common";
import {
  LIST_PRICE_GROUP,
  DELETE_PRICE_GROUP,
  DELETE_PRICE_GROUP_SUCCESS,
  DELETE_PRICE_GROUP_FAIL,
  ADD_PRICE_GROUP_SUCCESS,
  ADD_PRICE_GROUP_FAIL,
  UPDATE_PRICE_GROUP_SUCCESS,
  UPDATE_PRICE_GROUP_FAIL,
  LIST_PRICE_GROUP_SUCCESS,
  LIST_PRICE_GROUP_FAIL,
  ADD_NEW_PRICE_GROUP,
  UPDATE_PRICE_GROUP,
  GET_PRICE_GROUP,
  GET_PRICE_GROUP_SUCCESS,
  GET_PRICE_GROUP_FAIL,
} from "./actionType";

const INIT_STATE = {
  priceGroups: [],
  priceGroup: {},
  error: null,
  success: false,
  loading: false,
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const PriceGroups = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_PRICE_GROUP:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_PRICE_GROUP_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        priceGroups: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_PRICE_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_PRICE_GROUP:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_PRICE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        priceGroup: action.payload,
        action: Actions.EMPTY,
      };

    case GET_PRICE_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_PRICE_GROUP:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_PRICE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        priceGroups: state.priceGroups.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_PRICE_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_NEW_PRICE_GROUP:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_PRICE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        priceGroups: [...state.priceGroups, action.payload],
        action: Actions.EMPTY,
      };

    case ADD_PRICE_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_PRICE_GROUP:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
        action: Actions.UPDATE,
      };
    case UPDATE_PRICE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        priceGroups: state.priceGroups.map((priceGroup) =>
          priceGroup.id.toString() === action.payload.id.toString() ? { ...priceGroup, ...action.payload } : priceGroup,
        ),
        action: Actions.EMPTY,
      };

    case UPDATE_PRICE_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    default:
      return { ...state };
  }
};

export default PriceGroups;
