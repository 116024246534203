import React from "react";
import { useLocation } from "react-router-dom";

const useCheckPageLogin = () => {


  const location = useLocation();
  const { pathname } = location;

  // Create the variable to check for keywords in the pathname
  const isCheckPage = pathname.includes("login") || pathname.includes("contact") || pathname.includes("register") || pathname.includes("verify-confirmation-code");

return {isCheckPage}
};

export default useCheckPageLogin;