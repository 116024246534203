import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { useRef } from "react";
import ViewTemplatePDF from "./ViewTemplatePDF";
import { TEMPLATE_STRING } from "../constants/TemplatePrint/TemplateString";
import useMemoizedSelector from "../Hooks/useMemoizedSelector";
import ButtonTheme from "./ButtonTheme";
import ModalContainer from "./ModalContainer";
import { SIZE_TEMPLATE, TEMPLATE } from "../constants/TemplatePrint/EnumTemplate";
import { useDispatch } from "react-redux";
import { settingsDefaultPrintAPI } from "../../helpers/resource_helper";
import { getDefaultPrintShop, getTemplatePrint, updateDefaultPrintShop } from "../../store/actions";
import { toast } from "react-toastify";

const BreadCrumbCustom = ({ text, navigateTo = "#", t, children, isPrint, dataPrint, typePrint = "ORDER" }) => {
  const componentRef = useRef(null);
  const { templates, loading, dataShop, defaultPrint, locationUser, loadingDefaultTemplate } = useMemoizedSelector(
    (state) => ({
      templates: state.TemplatePrint.templates,
      loading: state.TemplatePrint.loading,
      loadingDefaultTemplate: state.ShopInfo.loadingDefaultTemplate,
      dataShop: state.ShopInfo.dataShop,
      defaultPrint: state.ShopInfo.defaultPrint,
      locationUser: state.ShopInfo.locationUser,
    }),
  );
  const dispatch = useDispatch();
  const [isEditPrint, setIsEditPrint] = useState();
  const [isDefault, setIsDefault] = useState(false);
  const [size, setSize] = useState("");
  const [type, setType] = useState(typePrint);
  const [dataPrintTemplate, setDataPrintTemplate] = useState("");
  const [firstRun, setFirstRun] = useState(true);
  const [checkPrint, setCheckPrint] = useState(true);
  useEffect(() => {
    if (isPrint) {
      setDataPrintTemplate(defaultPrint?.setting_value?.[typePrint]?.[locationUser.id]);
    }
  }, [isPrint, defaultPrint]);

  useEffect(() => {
    isPrint && dispatch(getDefaultPrintShop());
  }, [dispatch]);

  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);
      return;
    }
    var pri = document.getElementById("edit").contentWindow;
    pri.print();
  }, [checkPrint]);

  return (
    <React.Fragment>
      <ModalContainer
        isOpen={isEditPrint}
        toggle={() => {
          setIsEditPrint(false);
        }}
        size="lg"
        title={t("Choose info you want to print")}
        actionCustom={
          <div>
            <ButtonTheme
              type="button"
              className="btn btn-success w-md mx-2"
              onClick={async () => {
                const findTemplate = templates.find(
                  (template) =>
                    template.size === size &&
                    template.location_id === locationUser.id &&
                    template.template_type === type,
                );
                let template = "";
                if (findTemplate) {
                  template = findTemplate.template;
                } else {
                  template = TEMPLATE_STRING[type][size];
                }
                if (isDefault) {
                  dispatch(
                    updateDefaultPrintShop(
                      {
                        template_type: type,
                        location_id: locationUser.id,
                        size: size,
                        template: template,
                        is_default: true,
                      },
                      () => {
                        setIsEditPrint(false);
                        setDataPrintTemplate(template);
                        setCheckPrint(!checkPrint);
                        setIsDefault(false);
                        setSize(``);
                      },
                    ),
                  );
                }
              }}
              loading={loadingDefaultTemplate}
            >
              {t("Print")}
            </ButtonTheme>
            <ButtonTheme
              type="button"
              className="btn btn-danger w-md"
              onClick={() => {
                setIsEditPrint(false);
              }}
            >
              {t("Close")}
            </ButtonTheme>
          </div>
        }
      >
        {TEMPLATE.map((item, index) => (
          <FormGroup check key={index} className="fs-15 form-check-primary">
            <Input
              name="type"
              type="radio"
              checked={item.id === typePrint}
              onChange={() => {
                setType(item.id); ///
              }}
            />
            <Label check>{t(item.name)}</Label>
          </FormGroup>
        ))}
        <h5 className="mt-4">{t("Select printing template")}</h5>
        {SIZE_TEMPLATE.map((item, index) => (
          <ButtonTheme
            key={index}
            onClick={() => {
              setSize(item.id);
            }}
            className={`btn ${size === item.id ? "btn-primary" : "btn-outline-primary"} w-md mx-2`}
          >
            {t(item.name)}
          </ButtonTheme>
        ))}
        <FormGroup check className="fs-15 mt-4 form-check-primary">
          <Input
            type="checkbox"
            id="Check"
            checked={isDefault}
            onChange={(check) => {
              setIsDefault(check.target.checked);
            }}
          />
          <Label check for="Check">
            {t("Set as default page size")}
          </Label>
        </FormGroup>
      </ModalContainer>
      <Row className="position-sticky" style={{ top: "calc(70px + 1.5rem)", zIndex: 1002, height: "60px" }}>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between border-top">
            <Link to={navigateTo} className="hstack">
              <i className="bx bx-arrow-back cursor-pointer align-bottom fs-23 px-2" />
              <span className="align-center">{t(text)}</span>
            </Link>
            <div className="d-flex">
              {children}

              {isPrint && (
                <UncontrolledDropdown className="mx-2">
                  <div className="btn-group">
                    <ButtonTheme
                      type="button"
                      className="btn btn-outline-primary w-md d-flex"
                      onClick={() => {
                        if (defaultPrint?.setting_value?.[typePrint]?.[locationUser.id]) {
                          var pri = document.getElementById("edit").contentWindow;
                          pri.print();
                        } else {
                          toast.error("Please set default form!");
                        }
                      }}
                    >
                      <i className="ri-printer-line mx-1"></i> <span>{t("Print")}</span>
                    </ButtonTheme>
                    <DropdownToggle className="btn dropdown-toggle-split" color="primary" split></DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => {
                          setIsEditPrint(true);
                        }}
                      >
                        {t("Customize printing")}
                      </DropdownItem>
                    </DropdownMenu>
                  </div>
                </UncontrolledDropdown>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <ViewTemplatePDF
        componentRef={componentRef}
        dataForm={{ ...dataPrint, ...dataShop.setting_value }}
        dataTemplate={dataPrintTemplate}
        isShow={false}
      />
    </React.Fragment>
  );
};

export default withTranslation()(BreadCrumbCustom);
