import React from "react";
import { Modal, ModalBody } from "reactstrap";
import ButtonTheme from "./ButtonTheme";
import { withTranslation } from "react-i18next";
import LottieIcon from "./LottieIcon";

const DeleteModal = ({
  show,
  onDeleteClick,
  onCloseClick,
  loading = false,
  t,
  description = "Do you want to remove this record?",
}) => {
  return (
    <Modal fade={true} isOpen={show} toggle={onCloseClick} centered={true} /*  backdrop={false} */>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <LottieIcon style={{ width: "100px", height: "100px" }} src="https://cdn.lordicon.com/gsqxdxog.json" />
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>{t("Are you sure?")}</h4>
            <p className="text-muted mx-4 mb-0">{t(description)}</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <ButtonTheme type="button" className="btn w-sm btn-light" onClick={onCloseClick}>
            {t("Close")}
          </ButtonTheme>
          <ButtonTheme
            type="button"
            id="delete-record"
            className="btn w-sm btn-danger"
            onClick={onDeleteClick}
            loading={loading}
            disabled={loading}
          >
            {t("Yes, delete it!")}
          </ButtonTheme>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(DeleteModal);
