import { Steps } from "antd";
import { useEffect, useState } from "react";
import { formatDate } from "../../../../helpers/format_helper";

export default function HistoryStatus({ defaultStep = [], histories = [] }) {
  const PARTIAL = "PARTIAL_";

  const [items, setItems] = useState([]);
  const [current, setCurrent] = useState(0);
  const [checkError, setCheckError] = useState(false);
  const ErrorCase = ["CANCELLED", "IN_CANCEL", "SYNC_ERROR", "VOIDED"];
  useEffect(() => {
    const partialStatuses = histories.filter(
      (value, index, self) => value.status.includes(PARTIAL) && self.indexOf(value) === index,
    );
    const newData = defaultStep.map((item) => {
      const partialStatusesIndex = partialStatuses.findIndex((subItem) => subItem.status.includes(item.id));
      if (partialStatusesIndex > -1) {
        return { id: partialStatuses[partialStatusesIndex].status, name: "Partial " + item.name };
      } else {
        return item;
      }
    });
    if ((histories || []).length > 0) {
      const isCheckError = histories[histories.length - 1];
      if (ErrorCase.indexOf(isCheckError.status) > -1) {
        setItems([
          {
            title: isCheckError.status,
            description: formatDate(isCheckError.updated_at),
          },
        ]);
        setCheckError(true);
      } else {
        const newSteps = newData.map((item) => {
          const isCheck = histories?.find((h) => h.status === item.id);
          return {
            title: item.name,
            description: isCheck ? formatDate(isCheck.updated_at) : null,
          };
        });
        setItems(newSteps);
        setCurrent(histories.length);
      }
    } else {
      const newSteps = newData.map((item) => {
        return {
          title: item.name,
          description: null,
        };
      });
      setItems(newSteps);
    }
  }, [defaultStep, histories]);

  return (
    <div>
      <Steps
        direction="vertical"
        // size="small"
        status={checkError ? "error" : ""}
        current={current}
        items={items}
      />
    </div>
  );
}
