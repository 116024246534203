import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";

// Redux States
import { LIST_RETURN_ORDER, ADD_NEW_RETURN_ORDER, GET_RETURN_ORDER } from "./actionType";

import {
  addReturnOrderSuccess,
  addReturnOrderFail,
  listReturnOrderSuccess,
  listReturnOrderFail,
  getReturnOrderSuccess,
  getReturnOrderFail,
} from "./action";

import { returnOrdersAPI } from "../../../../helpers/resource_helper";

function* listReturnOrder({ payload: { ...res } }) {
  try {
    const response = yield call(returnOrdersAPI.list, { ...res });
    yield put(listReturnOrderSuccess(response));
  } catch (error) {
    yield put(listReturnOrderFail(error));
  }
}

function* getReturnOrder({ payload: id }) {
  try {
    const response = yield call(returnOrdersAPI.get, { id });
    yield put(getReturnOrderSuccess(response));
  } catch (error) {
    yield put(getReturnOrderFail(error));
  }
}

function* onAddNewReturnOrder({ payload: { returnOrder, handleAfterSubmit } }) {
  try {
    const response = yield call(returnOrdersAPI.create, returnOrder);
    if (response) {
      yield put(addReturnOrderSuccess(response));
      yield call(handleAfterSubmit, response);
      toast.success("ReturnOrder Added Successfully", { autoClose: 3000 });
    }
  } catch (error) {
    yield put(addReturnOrderFail(error));
    toast.error("ReturnOrder Added Failed", { autoClose: 3000 });
  }
}

export function* watchGetReturnOrders() {
  yield takeEvery(LIST_RETURN_ORDER, listReturnOrder);
}

export function* watchGetReturnOrder() {
  yield takeEvery(GET_RETURN_ORDER, getReturnOrder);
}

export function* watchAddNewReturnOrder() {
  yield takeEvery(ADD_NEW_RETURN_ORDER, onAddNewReturnOrder);
}

function* returnOrdersSaga() {
  yield all([fork(watchGetReturnOrder), fork(watchGetReturnOrders), fork(watchAddNewReturnOrder)]);
}

export default returnOrdersSaga;
