import withRouter from "../../../../Components/Common/withRouter";
import { Form, Formik, useFormik } from "formik";
import { Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import BreadCrumbCustom from "../../../../Components/Common/BreadCrumbCustom";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { Actions } from "../../../../Components/constants/common";
import { Fragment, useEffect, useRef, useState } from "react";
import avatar1 from "../../../../assets/images/users/avatar-1.jpg";

import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import {
  addNewCustomerGroup,
  deleteCustomerGroup,
  getCustomerGroup,
  resetCustomerGroup,
  updateCustomerGroup,
} from "../../../../store/actions";
import { useDispatch } from "react-redux";
import ScrollToError from "../../../../Components/Common/ScrollToError";
import InputTheme from "../../../../Components/Common/InputTheme";
import UploadTheme from "../../../../Components/Common/UploadTheme";
import { formatDate, formatDateTime, formatVNDCurrency } from "../../../../helpers/format_helper";
import SelectTheme from "../../../../Components/Common/SelectTheme";
import DeleteModal from "../../../../Components/Common/DeleteModal";

const CustomerGroupDetails = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  const { id } = params;
  const dispatch = useDispatch();
  document.title = `${t(!id ? "New Customer Group" : "Customer Group Details")} | OptiWarehouse`;
  const {
    customerGroup,
    customerGroupsLoading,
    customerGroupsAction,
    customerGroupsSuccess,
    customerGroupsError,
    customerGroupsMessage,
  } = useMemoizedSelector((state) => ({
    customerGroup: state.CustomerGroups.customerGroup,
    customerGroupsLoading: state.CustomerGroups.loading,
    customerGroupsAction: state.CustomerGroups.action,
    customerGroupsSuccess: state.CustomerGroups.success,
    customerGroupsError: state.CustomerGroups.error,
    customerGroupsMessage: state.CustomerGroups.message,
  }));
  const [showMinOrder, setShowMinOrder] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const formRef = useRef();

  const validation = {
    enableReinitialize: true,
    initialValues: {
      name: customerGroup?.name || "",
      min_purchased_amount: customerGroup?.min_purchased_amount || 0,
      image: customerGroup?.image ?? null,
      discount_percent: customerGroup?.discount_percent || 0,
      description: customerGroup?.description || "",
      default_price_group: customerGroup?.default_price_group || null,
      default_payment_method: customerGroup?.default_payment_method || null,
      max_discount_amount_per_order: customerGroup?.max_discount_amount_per_order || null,
      min_order_amount: customerGroup?.min_order_amount || null,
      next_group: customerGroup?.next_group || null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Please Enter Customer Group Name")),
      min_purchased_amount: Yup.number().typeError("Must be a number").required("This field is required"),
      discount_percent: Yup.number().typeError("Must be a number").required("This field is required"),
    }),
    onSubmit: (values) => {
      if (!id) {
        dispatch(
          addNewCustomerGroup(values, (data) => {
            navigate(`/customer-groups/${data.id}`);
          }),
        );
      } else {
        dispatch(
          updateCustomerGroup({ ...customerGroup, ...values }, (data) => {
            navigate(`/customer-groups/${data.id}`);
          }),
        );
      }
    },
  };

  const handleDeleteCustomerGroup = () => {
    dispatch(
      deleteCustomerGroup(customerGroup, () => {
        navigate("/customer-groups");
      }),
    );
  };

  useEffect(() => {
    if (customerGroupsLoading) {
      return;
    }
    if (customerGroup?.min_order_amount === null || customerGroup?.min_order_amount === undefined) {
      setShowMinOrder(false);
    } else {
      setShowMinOrder(true);
    }
  }, [customerGroup, customerGroupsLoading]);

  useEffect(() => {
    if (id) {
      dispatch(getCustomerGroup(id));
    } else {
      dispatch(resetCustomerGroup());
    }
  }, [id]);

  return (
    <div className="page-content">
      <Container fluid>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteCustomerGroup}
          loading={customerGroupsLoading && customerGroupsAction === Actions.DELETE}
          onCloseClick={() => {
            setDeleteModal(false);
          }}
        />
        <BreadCrumbCustom text="Back to customer group list" navigateTo="/customer-groups">
          {!id ? (
            <ButtonTheme
              type="button"
              className="btn btn-outline-primary w-md"
              onClick={() => formRef.current.handleSubmit()}
              loading={customerGroupsLoading && customerGroupsAction === Actions.CREATE}
              disabled={customerGroupsLoading && customerGroupsAction === Actions.CREATE}
            >
              {t("Create")}
            </ButtonTheme>
          ) : (
            <div className="hstack gap-2">
              <ButtonTheme
                type="button"
                className="btn btn-outline-primary w-md"
                onClick={() => formRef.current.handleSubmit()}
                loading={customerGroupsLoading && customerGroupsAction === Actions.UPDATE}
                disabled={customerGroupsLoading || customerGroupsAction === Actions.UPDATE}
              >
                {t("Update")}
              </ButtonTheme>
              <ButtonTheme
                type="button"
                className="btn btn-outline-danger w-md"
                onClick={() => {
                  setDeleteModal(true);
                }}
                disabled={customerGroupsLoading}
              >
                {t("Delete")}
              </ButtonTheme>
            </div>
          )}
        </BreadCrumbCustom>
        <Formik
          enableReinitialize={true}
          initialValues={validation.initialValues}
          validationSchema={validation.validationSchema}
          onSubmit={validation.onSubmit}
          innerRef={formRef}
        >
          {({ values, setFieldValue, errors, touched }) => {
            return (
              <Form>
                <ScrollToError />
                <Row>
                  <Col className={"col-9"}>
                    <Card>
                      <CardHeader>
                        <h5 className="card-title flex-grow-1 mb-0">{t("General")}</h5>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col lg={6}>
                            <InputTheme
                              name={"name"}
                              placeholder="Enter customerGroup name"
                              label={
                                <>
                                  {t("Name")}
                                  <span className="text-danger">*</span>
                                </>
                              }
                            />
                          </Col>
                          <Col lg={6}>
                            <InputTheme
                              name={"min_purchased_amount"}
                              placeholder="Enter min purchased amount"
                              format="price"
                              label={
                                <>
                                  {t("Min purchased amount")}
                                  <span className="text-danger">*</span>
                                </>
                              }
                            />
                          </Col>
                          <Col lg={6}>
                            <SelectTheme
                              label={"Default price group"}
                              name="default_price_group"
                              placeholder={t("Select Default Price")}
                              remote={true}
                              isObjectValueSelect={true}
                              selectDataOrigin={true}
                              onChange={(data) => {
                                setFieldValue("default_price_group", { id: data.data.id, name: data.data.name });
                              }}
                              path="price/price_groups"
                            />
                          </Col>
                          <Col lg={6}>
                            <SelectTheme
                              label={"Default payment method"}
                              name="default_payment_method"
                              placeholder={t("Select Payment Method")}
                              remote={true}
                              isObjectValueSelect={true}
                              selectDataOrigin={true}
                              onChange={(data) => {
                                setFieldValue("default_payment_method", { id: data.data.id, name: data.data.name });
                              }}
                              path="finance/payment_methods"
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader>
                        <h5 className="card-title flex-grow-1 mb-0">{t("Config offer")}</h5>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col lg={6}>
                            <InputTheme
                              name={"discount_percent"}
                              type="number"
                              min={0}
                              max={100}
                              placeholder="Enter discount percent"
                              label={"Discount percent"}
                              onTrigger={(data) => {
                                setFieldValue("discount_percent", Number(data));
                              }}
                            />
                          </Col>
                          <Col lg={6}>
                            <InputTheme
                              name={"max_discount_amount_per_order"}
                              format="price"
                              placeholder="Enter max discount amount per order"
                              label={"Max discount amount per order"}
                            />
                          </Col>
                          <Col lg={12} className="mb-3">
                            <div className="form-check form-check-primary mb-2">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="min-order-value"
                                checked={showMinOrder}
                                onChange={(e) => {
                                  if (!e.target.checked) {
                                    setFieldValue("min_order_amount", null);
                                  }
                                  setShowMinOrder(e.target.checked);
                                }}
                              />
                              <Label className="form-check-label" for="min-order-value">
                                {t("Minimum order value condition to receive tier benefits.")}
                              </Label>
                            </div>
                          </Col>
                          {showMinOrder && (
                            <Fragment>
                              <Col lg={6} className="mb-3">
                                <InputTheme
                                  name={"min_order_amount"}
                                  format="price"
                                  placeholder="Enter min order amount"
                                  label={"Min order amount"}
                                />
                              </Col>
                            </Fragment>
                          )}
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <SelectTheme
                              label={"Next group"}
                              name="next_group"
                              placeholder={t("Select next group")}
                              remote={true}
                              isObjectValueSelect={true}
                              selectDataOrigin={true}
                              onChange={(data) => {
                                setFieldValue(
                                  "next_group",
                                  data?.data ? { id: data.data.id, name: data.data.name } : null,
                                );
                              }}
                              path="actor/customer_groups"
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader>
                        <h5 className="card-title flex-grow-1 mb-0">{t("Benefit description")}</h5>
                      </CardHeader>
                      <CardBody>
                        <p>
                          {t(
                            "Provide customers with information about the offers they will receive when joining a tier. This information will be displayed to members when they log in to their account.",
                          )}
                        </p>
                        <InputTheme name={"description"} label={"Description"} placeholder="Enter description" />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className={"col-3"}>
                    <Card>
                      <CardBody className="text-center">
                        <div className="text-center hstack justify-content-center">
                          <UploadTheme name="image" />
                        </div>
                        {values.name && <h5 className="mb-0">{values.name}</h5>}
                      </CardBody>
                      <CardBody className="border-top d-flex text-center">
                        <div className="d-flex flex-1 flex-column gap-2 p-1 border-end">
                          <i className="ri-coupon-2-line text-primary fs-3"></i>
                          <p className="mb-0 fw-bold">{formatVNDCurrency(values.min_purchased_amount)}</p>
                          <p className="mb-0 text-muted">{t("Minimum spending condition")}</p>
                        </div>
                        <div className="d-flex flex-1 flex-column gap-2 p-1">
                          <i className="ri-discount-percent-fill text-primary fs-3"></i>
                          <p className="mb-0 fw-bold">{`${t("Discount")} ${values.discount_percent}%`}</p>
                          <p className="mb-0 text-muted">
                            {`${t("Max discount amount")} ${formatVNDCurrency(values.max_discount_amount_per_order)}`}
                            <br />
                            {showMinOrder && (
                              <span>{`${t("Orders from")} ${formatVNDCurrency(validation.min_order_amount)}`}</span>
                            )}
                          </p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </div>
  );
};

export default withRouter(CustomerGroupDetails);
