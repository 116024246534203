import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { Tooltip } from "antd";

import { convertValidDate, convertValidTime } from "../../../helpers/format_helper";

import withRouter from "../../../Components/Common/withRouter";
import ButtonTheme from "../../../Components/Common/ButtonTheme";
import ExportCSVModal from "../../../Components/Common/ExportCSVModal";
import useMemoizedSelector from "../../../Components/Hooks/useMemoizedSelector";
import TableContainerCustom from "../../../Components/Common/TableContainerCustom";
import BadgeCustom from "../../../Components/Common/BadgeCustom";
import { Actions, PaymentStatusOptions, PurchaseOrderStatusOptions } from "../../../Components/constants/common";

import { listPurchaseOrder } from "../../../store/actions";
import FullNameRender from "../../../Components/Common/FullnameRender";
import ImportCSVModal from "../../../Components/Common/ImportCSVModal";
import ActionDropdown from "../../../Components/Common/ActionDropdown";

const PurchaseOrders = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Purchase Order List")} | OptiWarehouse`;
  const dispatch = useDispatch();
  const [isImportCSV, setIsImportCSV] = useState(false);
  const {
    purchaseOrders,
    purchaseOrdersLoading,
    purchaseOrdersAction,
    purchaseOrdersSuccess,
    purchaseOrdersError,
    purchaseOrdersLimit,
    purchaseOrdersPage,
    purchaseOrdersTotal,
    purchaseOrdersMessage,
  } = useMemoizedSelector((state) => ({
    purchaseOrders: state.PurchaseOrders.purchase_orders,
    purchaseOrdersAction: state.PurchaseOrders.action,
    purchaseOrdersMessage: state.PurchaseOrders.message,
    purchaseOrdersLoading: state.PurchaseOrders.loading,
    purchaseOrdersSuccess: state.PurchaseOrders.success,
    purchaseOrdersError: state.PurchaseOrders.error,
    purchaseOrdersLimit: state.PurchaseOrders.limit,
    purchaseOrdersPage: state.PurchaseOrders.page,
    purchaseOrdersTotal: state.PurchaseOrders.total,
  }));

  const [isExportCSV, setIsExportCSV] = useState(false);

  const headers = [
    "purchase_order_number",
    "supplier",
    "updated_at",
    "created_at",
    "status",
    "payment_status",
    "action",
  ];

  return (
    <div className="page-content">
      <ImportCSVModal
        show={isImportCSV}
        onCloseClick={() => setIsImportCSV(false)}
        pathUrl="import_purchase_order_by_xlsx_file_api"
        title={t("Import purchase order data")}
        onLoadData={() => {
          dispatch(listPurchaseOrder({}));
        }}
      />
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={purchaseOrders} />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="purchase-order-list">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("Purchase Order List")}</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-3 flex-wrap">
                      <ButtonTheme
                        type="button"
                        className="btn btn-light"
                        icon="ri-external-link-fill"
                        onClick={() => {
                          setIsImportCSV(true);
                        }}
                      >
                        {t("Import")}
                      </ButtonTheme>
                      <ButtonTheme
                        type="button"
                        className="btn btn-light"
                        icon="ri-file-text-line"
                        onClick={() => setIsExportCSV(true)}
                      >
                        {t("Export")}
                      </ButtonTheme>
                      <ButtonTheme
                        type="button"
                        className="btn btn-primary add-btn me-2"
                        icon="ri-add-line text-white"
                        id="create-btn"
                        onClick={() => {
                          navigate("/purchase-orders/new");
                        }}
                      >
                        {t("Add Purchase Order")}
                      </ButtonTheme>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <TableContainerCustom
                  filterType={"purchaseOrders"}
                  searchPlaceHolder="Search purchase order..."
                  listFilter={[
                    {
                      id: "location_id",
                      type: "selectBox",
                      title: "Location",
                      remote: true,
                      pathUrlLoad: "inventory/locations",
                    },
                    {
                      id: "status",
                      type: "selectBox",
                      title: "Status",
                      dataOption: PurchaseOrderStatusOptions,
                    },
                    {
                      id: "payment_status",
                      type: "selectBox",
                      title: "Payment status",
                      dataOption: PaymentStatusOptions,
                    },
                  ]}
                  headers={headers}
                  contents={purchaseOrders}
                  limitPage={Number(purchaseOrdersLimit)}
                  currentPage={Number(purchaseOrdersPage) + 1}
                  totalItems={purchaseOrdersTotal}
                  loading={purchaseOrdersLoading && purchaseOrdersAction === Actions.LIST}
                  showPagination={true}
                  scrollHeight="50vh"
                  onCallData={(dataPayload) => {
                    dispatch(listPurchaseOrder(dataPayload));
                  }}
                  isSettings={true}
                  listHeader={[
                    {
                      title: t("Purchase order details"),
                      key: "detail-variant",
                      children: [
                        {
                          title: "Number",
                          dataIndex: "purchase_order_number",
                          key: "purchase_order_number",
                          render: (value, row, index) => {
                            return (
                              <Tooltip>
                                <Link to={`/purchase-orders/${row.id}`} className="d-block text-truncate link-primary">
                                  {value}
                                </Link>
                              </Tooltip>
                            );
                          },
                        },
                        {
                          title: t("Supplier"),
                          dataIndex: "supplier",
                          key: "supplier",
                          render: (value, row, index) => {
                            return (
                              <div className="text-truncate d-block">
                                <FullNameRender first_name={value?.first_name} last_name={value?.last_name} />
                              </div>
                            );
                          },
                        },
                        {
                          title: t("Updated at"),
                          dataIndex: "updated_at",
                          key: "updated_at",
                          sorter: true,
                          render: (value, row, index) => {
                            return (
                              <>
                                {convertValidDate(value)},
                                <small className="text-muted"> {convertValidTime(value)}</small>
                              </>
                            );
                          },
                        },
                        {
                          title: t("Created at"),
                          dataIndex: "created_at",
                          key: "created_at",
                          defaultSortOrder: "desc",
                          sorter: true,
                          render: (value, row, index) => {
                            return (
                              <>
                                {convertValidDate(value)},
                                <small className="text-muted"> {convertValidTime(value)}</small>
                              </>
                            );
                          },
                        },
                        {
                          title: t("Purchase order status"),
                          dataIndex: "status",
                          key: "status",
                          sorter: true,
                          render: (value, row, index) => (
                            <>
                              <BadgeCustom status={value} />
                            </>
                          ),
                        },
                        {
                          title: t("Payment status"),
                          dataIndex: "payment_status",
                          key: "payment_status",
                          sorter: true,
                          render: (value, row, index) => <>{value ? <BadgeCustom status={value} /> : "---"}</>,
                        },
                        {
                          title: t("Action"),
                          dataIndex: "action",
                          key: "action",
                          render: (value, row, index) => {
                            return (
                              <ActionDropdown
                                index={index}
                                actions={[
                                  {
                                    type: "view",
                                    onClick: () => {
                                      navigate(`/purchase-orders/${row.id}`);
                                    },
                                  },
                                  {
                                    hide: row.status === "COMPLETED",
                                    type: "edit",
                                    onClick: () => {
                                      navigate(`/purchase-orders/${row.id}/edit`);
                                    },
                                  },
                                ]}
                              />
                            );
                          },
                        },
                      ],
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(PurchaseOrders);
