import {
  LIST_STOCK_RELOCATE,
  CANCEL_STOCK_RELOCATE,
  CANCEL_STOCK_RELOCATE_SUCCESS,
  CANCEL_STOCK_RELOCATE_FAIL,
  ADD_STOCK_RELOCATE_SUCCESS,
  ADD_STOCK_RELOCATE_FAIL,
  UPDATE_STOCK_RELOCATE_SUCCESS,
  UPDATE_STOCK_RELOCATE_FAIL,
  LIST_STOCK_RELOCATE_SUCCESS,
  LIST_STOCK_RELOCATE_FAIL,
  ADD_NEW_STOCK_RELOCATE,
  UPDATE_STOCK_RELOCATE,
  GET_STOCK_RELOCATE,
  GET_STOCK_RELOCATE_SUCCESS,
  GET_STOCK_RELOCATE_FAIL,
  DELIVERY_STOCK_RELOCATE,
  DELIVERY_STOCK_RELOCATE_SUCCESS,
  DELIVERY_STOCK_RELOCATE_FAIL,
  RECEIVE_STOCK_RELOCATE,
  RECEIVE_STOCK_RELOCATE_SUCCESS,
  RECEIVE_STOCK_RELOCATE_FAIL,
} from "./actionType";

const INIT_STATE = {
  stockRelocates: [],
  stockRelocate: {},
  error: null,
  success: false,
  loading: false,
  limit: 0,
  page: 0,
  total: 0,
};

const StockRelocates = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_STOCK_RELOCATE:
      return {
        ...state,
        stockRelocate: {},
        error: null,
        success: false,
        loading: true,
      };

    case LIST_STOCK_RELOCATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        stockRelocates: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
      };

    case LIST_STOCK_RELOCATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case GET_STOCK_RELOCATE:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };

    case GET_STOCK_RELOCATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        stockRelocate: action.payload,
      };

    case GET_STOCK_RELOCATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case CANCEL_STOCK_RELOCATE:
      return {
        ...state,
        error: null,
        success: false,
        cancelLoading: true,
      };

    case CANCEL_STOCK_RELOCATE_SUCCESS:
      return {
        ...state,
        cancelLoading: false,
        success: true,
        stockRelocate: action.payload,
        stockRelocates: state.stockRelocates.map((stockRelocate) =>
          stockRelocate.id.toString() === action.payload.id.toString()
            ? { ...stockRelocate, ...action.payload }
            : stockRelocate,
        ),
      };

    case CANCEL_STOCK_RELOCATE_FAIL:
      return {
        ...state,
        cancelLoading: false,
        success: false,
        error: action.payload,
      };
    case DELIVERY_STOCK_RELOCATE:
      return {
        ...state,
        error: null,
        success: false,
        deliveryLoading: true,
      };

    case DELIVERY_STOCK_RELOCATE_SUCCESS:
      return {
        ...state,
        deliveryLoading: false,
        success: true,
        stockRelocate: action.payload,
        stockRelocates: state.stockRelocates.map((stockRelocate) =>
          stockRelocate.id.toString() === action.payload.id.toString()
            ? { ...stockRelocate, ...action.payload }
            : stockRelocate,
        ),
      };

    case DELIVERY_STOCK_RELOCATE_FAIL:
      return {
        ...state,
        deliveryLoading: false,
        success: false,
        error: action.payload,
      };

    case RECEIVE_STOCK_RELOCATE:
      return {
        ...state,
        error: null,
        success: false,
        receiveLoading: true,
      };

    case RECEIVE_STOCK_RELOCATE_SUCCESS:
      return {
        ...state,
        receiveLoading: false,
        success: true,
        stockRelocate: action.payload,
        stockRelocates: state.stockRelocates.map((stockRelocate) =>
          stockRelocate.id.toString() === action.payload.id.toString()
            ? { ...stockRelocate, ...action.payload }
            : stockRelocate,
        ),
      };

    case RECEIVE_STOCK_RELOCATE_FAIL:
      return {
        ...state,
        receiveLoading: false,
        success: false,
        error: action.payload,
      };

    case ADD_NEW_STOCK_RELOCATE:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };

    case ADD_STOCK_RELOCATE_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        stockRelocates: [action.payload, ...state.stockRelocates, action.payload],
      };

    case ADD_STOCK_RELOCATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case UPDATE_STOCK_RELOCATE:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };
    case UPDATE_STOCK_RELOCATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        stockRelocate: action.payload,
        stockRelocates: state.stockRelocates.map((stockRelocate) =>
          stockRelocate.id.toString() === action.payload.id.toString()
            ? { ...stockRelocate, ...action.payload }
            : stockRelocate,
        ),
      };

    case UPDATE_STOCK_RELOCATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default StockRelocates;
