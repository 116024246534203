import React, { useEffect, useState } from "react";
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { useTranslation } from "react-i18next";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { useNavigate } from "react-router-dom";
import { formatDate1 } from "../../../../helpers/format_helper";
import { periodOptions } from "../../../../Components/constants/common";
import { useProfile } from "../../../../Components/Hooks/useProfile";

const Section = ({ onChangeParams, loading, onRefresh,setFilterTime,onHandleGetFilterDate }) => {
  const { t } = useTranslation();
  const { userProfile } = useProfile();
  const navigate = useNavigate();
  const today = new Date();
  const [value, setValue] = useState([today]);
  const [period, setPeriod] = useState({ name: "This month", id: "this_month" });
  const handleChangePeriod = (data) => {
    setPeriod(data);
  };
  const handleSelectDate = (date) => {
    const newData = { period: "today" };
    if (date.length > 1) {
      setValue(date);
      const date_from = formatDate1(date[0]);
      const date_to = formatDate1(date[1]);
      newData.date_from = date_from;
      newData.date_to = date_to;
      newData.period = "custom";
    } else {
      setValue([today]);
    }
    onChangeParams(newData);
  };
  useEffect(() => {
    if (Array.isArray(value) && value.length === 2) {
      onHandleGetFilterDate(value);
    }
  }, [value]);

  useEffect(() => {
    if (period.id === "today") {
      setValue([today]);
      const newData = { period: "today" };
      onChangeParams(newData);
    }
    if (period.id === "this_week") {
      const dayOfWeek = today.getDay();
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - dayOfWeek + 1);
      startOfWeek.setHours(0, 0, 0, 0);
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
      setValue([startOfWeek, endOfWeek]);
      const newData = { period: "this_week" };
      onChangeParams(newData);
    }
    if (period.id === "this_month") {
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      setValue([startOfMonth, endOfMonth]);
      const newData = { period: "this_month" };
      onChangeParams(newData);
    }
    if (period.id === "this_year") {
      const startOfYear = new Date(today.getFullYear(), 0, 1);
      const endOfYear = new Date(today.getFullYear(), 11, 31);
      setValue([startOfYear, endOfYear]);
      const newData = { period: "this_year" };
      onChangeParams(newData);
    }
  }, [period]);

  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="flex-grow-1">
              <h4 className="fs-16 mb-1">
                {t("Good Morning")}, {userProfile?.Username}!
              </h4>
              <p className="text-muted mb-0">{t("Here's what's happening with your store today.")}</p>
            </div>
            <div className="mt-3 mt-lg-0">
              <form action="#">
                <Row className="g-3 mb-0 align-items-center">
                  <div className="col-sm-auto">
                    <UncontrolledDropdown className="card-header-dropdown" direction="start">
                      <DropdownToggle tag="a" className="text-reset dropdown-btn" role="button">
                        <span>
                          {t(period.name)}
                          <i className="mdi mdi-chevron-down ms-1"></i>
                        </span>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu dropdown-menu-end">
                        {periodOptions.map((item, index) => (
                          <DropdownItem
                            key={index}
                            onClick={() => {
                              handleChangePeriod(item);
                            }}
                          >
                            {t(item.name)}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <div className="col-sm-auto">
                    <div className={`input-group ${loading || period.id !== "custom" ? "disable-icon" : ""}`}>
                      <Flatpickr
                        value={value}
                        className="form-control border-0 dash-filter-picker shadow"
                        onChange={(date) => {
                          setValue(date);
                        }}
                        onClose={(date) => {
                          handleSelectDate(date);
                        }}
                        options={{
                          mode: "range",
                          dateFormat: "d M, Y",
                        }}
                        disabled={loading || period.id !== "custom"}
                      />
                      <div className="input-group-text bg-primary border-primary text-white">
                        <i className="ri-calendar-2-line"></i>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <ButtonTheme
                      type="button"
                      className="btn btn-primary"
                      icon="ri-add-line text-white"
                      onClick={() => {
                        navigate("/products/new");
                      }}
                    >
                      {t("Add Product")}
                    </ButtonTheme>
                  </div>
                  <div className="col-auto">
                    <ButtonTheme className="btn btn-soft-info btn-icon" loading={loading} onClick={onRefresh}>
                      <i className="ri-refresh-line"></i>
                    </ButtonTheme>
                  </div>
                </Row>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Section;
