import { call, put, takeEvery } from "redux-saga/effects";
import { getPermissionFail, getPermissionSuccess } from "./actions";
import { permissionAPI, roleAPI } from "../../../helpers/resource_helper";
import { GET_ROLE_PERMISSION } from "./actionTypes";

function* getPermission({ payload: { data, onChangeLocation } }) {
  try {
    const response = yield call(permissionAPI.get, { id: data.id });
    onChangeLocation(data.location);
    const resRole = yield call(roleAPI.get, { id: response.role[data.location.id]?.value });
    yield put(getPermissionSuccess(resRole.permission));
  } catch (error) {
    yield put(getPermissionFail(error));
  }
}
function* PermissionSaga() {
  yield takeEvery(GET_ROLE_PERMISSION, getPermission);
}

export default PermissionSaga;
