import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

// Formik
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { addNewUnit, updateUnit } from "../../../../store/actions";
import InputTheme from "../../../../Components/Common/InputTheme";
import UploadTheme from "../../../../Components/Common/UploadTheme";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { Actions } from "../../../../Components/constants/common";
import ScrollToError from "../../../../Components/Common/ScrollToError";

const EditAddUnitModal = ({ show, toggle, unit = null, onChangeUnit = () => {} }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { unitsLoading, unitsAction, unitsSuccess } = useMemoizedSelector((state) => ({
    unitsLoading: state.Units.loading,
    unitsAction: state.Units.action,
    unitsSuccess: state.Units.success,
  }));

  // validation
  const validation = {
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: unit?.name || "",
      ratio: unit?.ratio || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Please Enter Unit Name")),
      ratio: Yup.number().required(t("Please Enter Unit Ratio")).min(1, t("Ratio must be greater than or equal to 1")),
    }),
    onSubmit: (values, actions) => {
      if (unit?.id) {
        dispatch(
          updateUnit(
            {
              ...unit,
              ...values,
            },
            () => {
              actions.resetForm();
              handleClose();
            },
          ),
        );
      } else {
        dispatch(
          addNewUnit(
            {
              ...values,
            },
            (data) => {
              onChangeUnit(data);
              actions.resetForm();
              handleClose();
            },
          ),
        );
      }
    },
  };

  const handleClose = () => {
    if (!unitsLoading) {
      toggle();
    }
  };
  const formRef = useRef();
  return (
    <Modal fade={true} isOpen={show} toggle={handleClose} centered={true}>
      <ModalHeader className="bg-light p-3">{t(unit?.id ? t("Edit unit") : t("New unit"))}</ModalHeader>
      <ModalBody>
        <Formik
          enableReinitialize={true}
          initialValues={validation.initialValues}
          validationSchema={validation.validationSchema}
          onSubmit={validation.onSubmit}
          innerRef={formRef}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <ScrollToError />
              <InputTheme name={"name"} label={"Name"} placeholder={t("Enter Unit Name")} />
              <InputTheme type={"number"} min={1} name={"ratio"} label={"Ratio"} placeholder={t("Enter Ratio")} />
            </Form>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>
        <div className="hstack gap-2 justify-content-end">
          <ButtonTheme type="button" className="btn btn-light w-sm" onClick={handleClose}>
            {t("Close")}
          </ButtonTheme>
          <ButtonTheme
            onClick={() => {
              formRef.current.handleSubmit();
            }}
            className="btn btn-primary w-sm"
            loading={unitsLoading && (unitsAction === Actions.UPDATE || unitsAction === Actions.CREATE)}
            disabled={unitsLoading && (unitsAction === Actions.UPDATE || unitsAction === Actions.CREATE)}
          >
            {t(unit?.id ? "Update" : "Add")}
          </ButtonTheme>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default EditAddUnitModal;
