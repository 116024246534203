import {
  GET_VERSION,
  GET_VERSION_FAIL,
  GET_VERSION_SUCCESS,
} from "./actionType";

export const getVersion = () => ({
  type: GET_VERSION,
});
export const getVersionSuccess = (payload) => ({
  type: GET_VERSION_SUCCESS,
  payload: payload,
});
export const getVersionFail = (error) => ({
  type: GET_VERSION_FAIL,
  payload: error,
});