import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { isEmpty } from "lodash";
import useMemoizedSelector from "../../../../../Components/Hooks/useMemoizedSelector";
import withRouter from "../../../../../Components/Common/withRouter";
import { deleteDiscount, getDiscount } from "../../../../../store/actions";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import { Actions, DiscountTypeOptions, DiscountTypes } from "../../../../../Components/constants/common";
import BreadCrumbCustom from "../../../../../Components/Common/BreadCrumbCustom";
import BadgeCustom from "../../../../../Components/Common/BadgeCustom";
import DiscountByOrderTotal from "../DiscountInfo/DicountByOrderTotal";
import DiscountByProduct from "../DiscountInfo/DiscountByProduct";
import { convertValidDate, convertValidTime } from "../../../../../helpers/format_helper";
import DiscountProductByOrderTotal from "../DiscountInfo/DiscountProductByOrderTotal";
import GiftByOrderTotal from "../DiscountInfo/GiftByOrderTotal";
import DiscountByQuantity from "../DiscountInfo/DiscountByQuantity";
import DiscountByPurchaseProduct from "../DiscountInfo/DiscountByPurchaseProduct";
import GiftByPurchaseProduct from "../DiscountInfo/GiftByPurchaseProduct";
import Loader from "../../../../../Components/Common/Loader";
import Countdown from "react-countdown";

const DiscountDetailsPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Discount Details")} | OptiWarehouse`;
  const dispatch = useDispatch();
  const { id } = params;
  const [firstRun, setFirstRun] = useState(true);

  const { discount, discountsAction, discountsLoading, discountsMessage, discountsSuccess, discountsError } =
    useMemoizedSelector((state) => ({
      discount: state.Discounts.discount,
      discountsAction: state.Discounts.action,
      discountsLoading: state.Discounts.loading,
      discountsSuccess: state.Discounts.success,
      discountsError: state.Discounts.error,
      discountsMessage: state.Discounts.message,
    }));

  const handleDeleteDiscount = () => {
    dispatch(deleteDiscount(discount));
  };

  const rendererExpireIn = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span className="text-danger">{t("Expired")}</span>;
    } else {
      return <span>{`${days} day${days > 1 ? "s" : ""} ${hours}:${minutes}:${seconds}`}</span>;
    }
  };

  useEffect(() => {
    dispatch(getDiscount(id));
  }, [id]);

  useEffect(() => {
    setFirstRun(false);
  }, []);

  useEffect(() => {
    if (!discountsLoading && discountsSuccess && !firstRun) {
      if (discountsMessage === "Delete discount success") {
        navigate("/discounts");
      }
    }
  }, [discountsSuccess, discountsLoading, discountsMessage]);

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumbCustom text="Back to discount list" navigateTo="/discounts">
            <div className="hstack gap-2">
              <Fragment>
                <ButtonTheme
                  type="button"
                  className="btn btn-outline-danger w-md"
                  onClick={handleDeleteDiscount}
                  loading={discountsLoading && discountsAction === Actions.DELETE}
                  disabled={discountsLoading || discountsAction === Actions.DELETE}
                >
                  {t("Delete")}
                </ButtonTheme>
                <ButtonTheme
                  type="button"
                  className="btn btn-outline-primary w-md"
                  onClick={() => {
                    navigate(`/discounts/${id}`);
                  }}
                  loading={discountsLoading && discountsAction === Actions.UPDATE}
                  disabled={discountsLoading || discountsAction === Actions.UPDATE}
                >
                  {t("Edit")}
                </ButtonTheme>
              </Fragment>
            </div>
          </BreadCrumbCustom>
          {discountsLoading ? (
            <Loader />
          ) : (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader className="bdiscount-0">
                    <Row className="g-4 align-items-center">
                      <div className="col-sm">
                        <div className="hstack gap-2">
                          <h5 className="card-title mb-0">{t("General info")}</h5>
                          <BadgeCustom status={discount?.status} />
                        </div>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col lg={4} className="text-center">
                        <img
                          style={{ height: "10rem", width: "10rem" }}
                          className="rounded border bg-light"
                          alt=""
                          src={discount?.image?.url}
                        />
                      </Col>
                      <Col lg={4}>
                        <table className="table table-borderless mb-0">
                          <tbody>
                            <tr>
                              <td className="text-muted">{t("Name")} :</td>
                              <td>{discount?.name || "---"}</td>
                            </tr>
                            <tr>
                              <td className="text-muted">{t("Publish")} :</td>
                              <td>
                                {/* <i className={`ri-checkbox-circle-fill ${discount?.publish && "text-success"}`} /> */}
                                {t(discount?.publish ? "Active" : "Inactive")}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-muted">{t("Discount code")} :</td>
                              <td>{discount?.discount_code || "---"}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>

                      <Col lg={4}>
                        <table className="table table-borderless mb-0">
                          <tbody>
                            <tr>
                              <td className="text-muted">{t("Description")} :</td>
                              <td>{discount?.description || "---"}</td>
                            </tr>
                            <tr>
                              <td className="text-muted">{t("Expire in")} :</td>
                              <td>
                                {discount?.expire_at ? (
                                  <Countdown date={discount?.expire_at} renderer={rendererExpireIn} />
                                ) : (
                                  t("Limitless")
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-muted">{t("Used times")} :</td>
                              <td>
                                {discount?.used_times || 0}/{discount?.max_using_times || "∞"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  <CardHeader className="bdiscount-0">
                    <Row className="g-4 align-items-center">
                      <div className="col-sm">
                        <h5 className="card-title mb-0">{t("Effective info")}</h5>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col lg={4}>
                        <table className="table table-borderless mb-0">
                          <tbody>
                            <tr>
                              <td className="text-muted">{t("Start date")} :</td>
                              <td>
                                {discount?.start_at ? (
                                  <>
                                    {convertValidDate(discount?.start_at)},
                                    <small className="text-muted"> {convertValidTime(discount?.start_at)}</small>
                                  </>
                                ) : (
                                  "---"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-muted">{t("Expire date")} :</td>
                              <td>
                                {discount?.expire_at ? (
                                  <>
                                    {convertValidDate(discount?.expire_at)},
                                    <small className="text-muted"> {convertValidTime(discount?.expire_at)}</small>
                                  </>
                                ) : (
                                  t("Limitless")
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                      <Col lg={4}>
                        <table className="table table-borderless mb-0">
                          <tbody>
                            <tr>
                              <td className="text-muted">{t("Effective customers")} :</td>
                              <td>{discount?.customers || t("All customers")}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                      <Col lg={4}>
                        <table className="table table-borderless mb-0">
                          <tbody>
                            <tr>
                              <td className="text-muted">{t("Effective locations")} :</td>
                              <td>
                                {discount?.locations
                                  ? discount?.locations.map((item) => t(item.name)).join(", ")
                                  : t("All locations")}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-muted">{t("Effective sources")} :</td>
                              <td>{discount?.sources || t("All sources")}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  <CardHeader className="bdiscount-0">
                    <Row className="g-4 align-items-center">
                      <div className="col-sm">
                        <h5 className="card-title mb-0">
                          {t("Discount info")} (
                          {t(DiscountTypeOptions.find((item) => item.id === discount?.type)?.label || "---")})
                        </h5>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {discount.type === DiscountTypes.BY_ORDER_TOTAL && (
                      <DiscountByOrderTotal data={discount.discount_by_order_total} isEdit={false} />
                    )}
                    {discount.type === DiscountTypes.BY_PRODUCT && (
                      <DiscountByProduct data={discount.discount_by_product} isEdit={false} />
                    )}
                    {discount.type === DiscountTypes.PRODUCT_BY_ORDER_TOTAL && (
                      <DiscountProductByOrderTotal data={discount.discount_product_by_order_total} isEdit={false} />
                    )}
                    {discount.type === DiscountTypes.BY_QUANTITY && (
                      <DiscountByQuantity data={discount.discount_by_quantity} isEdit={false} />
                    )}
                    {discount.type === DiscountTypes.BY_PURCHASE_PRODUCT && (
                      <DiscountByPurchaseProduct data={discount.discount_by_purchase_product} isEdit={false} />
                    )}
                    {discount.type === DiscountTypes.GIFT_BY_ORDER_TOTAL && (
                      <GiftByOrderTotal data={discount.gift_by_order_total} isEdit={false} />
                    )}
                    {discount.type === DiscountTypes.GIFT_BY_PURCHASE_PRODUCT && (
                      <GiftByPurchaseProduct data={discount.gift_by_purchase_product} isEdit={false} />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </Fragment>
  );
};
export default withRouter(DiscountDetailsPage);
