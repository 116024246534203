import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Input, Label, Row, Spinner } from "reactstrap";
import { uploadImageAPI } from "../../helpers/service_helper";
import { toast } from "react-toastify";

const UploadImage = ({ prefix, image = null, onChangeImage = () => {} }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageContent, setImageContent] = useState("");
  const [loading, setLoading] = useState(false);

  const handleRemoveImage = () => {
    setSelectedImage(null);
    onChangeImage(null);
  };

  const handleAcceptedImage = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onabort = () => console.error("file reading was aborted");
      reader.onerror = () => console.error("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const content = reader.result;
        setImageContent(content);
      };
      reader.readAsDataURL(file);
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      });
      setSelectedImage(file);
    } else {
      setSelectedImage(null);
    }
  };
  /**
   * Formats the size
   */
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  useEffect(() => {
    if (selectedImage && imageContent) {
      const uploadImage = async () => {
        try {
          setLoading(true);
          const res = await uploadImageAPI({ prefix, name: selectedImage?.name, image: imageContent });
          onChangeImage(res);
        } catch (e) {
          toast.error(String(e), { autoClose: 3000 });
        } finally {
          setLoading(false);
          setImageContent("");
        }
      };
      uploadImage();
    }
  }, [selectedImage, imageContent]);
  return (
    <div>
      <Input
        disabled={loading}
        className="form-control mb-3"
        id="project-thumbnail-img"
        type="file"
        accept="image/png, image/gif, image/jpeg"
        onChange={(e) => {
          handleAcceptedImage(e.target.files[0]);
        }}
      />
      {loading ? (
        <div className="d-flex justify-content-center mx-2 mt-2">
          <Spinner color="primary" />
        </div>
      ) : (
        image &&
        image?.url && (
          <div className="text-center">
            <div className="position-relative d-inline-block">
              <img className="rounded avatar-md img-thumbnail" src={image?.url} alt="" />
              <div className="avatar-xs p-0 rounded-circle">
                <i
                  className="position-absolute top-0 start-100 translate-middle ri-close-circle-fill text-danger fs-19 cursor-pointer"
                  onClick={handleRemoveImage}
                ></i>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default UploadImage;
