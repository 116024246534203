import { uploadImageAPI } from "../../helpers/service_helper";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

const CKEditorCustom = ({ imagePrefix = "random", value, onChangeValue = () => {}, config = {} }) => {
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file
            .then(async (file) => {
              const payload = {
                prefix: imagePrefix,
                name: file.name,
                image: await toBase64(file),
              };
              const res = await uploadImageAPI(payload);
              resolve({ default: res.url });
            })
            .catch((err) => {
              console.error(err);
              reject(err);
            });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    if (editor?.plugins) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return uploadAdapter(loader);
      };
    }
  }
  return (
    <CKEditor
      config={{
        ...config,
        extraPlugins: [uploadPlugin],
      }}
      editor={Editor}
      watchdogConfig={false}
      onReady={(editor) => {
        editor.ui
          .getEditableElement()
          .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
      }}
      data={value}
      onChange={(event, editor) => {
        const data = editor?.getData();
        onChangeValue(data);
      }}
    />
  );
};
export default CKEditorCustom;
