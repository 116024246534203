import {
  GET_DRAFT_ORDER,
  GET_DRAFT_ORDER_SUCCESS,
  GET_DRAFT_ORDER_FAIL,
  UPDATE_DRAFT_ORDER_SUCCESS,
  UPDATE_DRAFT_ORDER_FAIL,
  UPDATE_DRAFT_ORDER,
} from "./actionType";

const INIT_STATE = {
  draftOrder: {},
  error: null,
  success: false,
  loading: false,
};

const DraftOrders = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_DRAFT_ORDER:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };

    case GET_DRAFT_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        draftOrder: action.payload,
      };

    case GET_DRAFT_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case UPDATE_DRAFT_ORDER:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };
    case UPDATE_DRAFT_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        draftOrders: action.payload,
      };

    case UPDATE_DRAFT_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};

export default DraftOrders;
