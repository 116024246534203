import React, { useEffect, useRef, useState } from "react";

import { Container, Card, CardBody, Nav, NavLink } from "reactstrap";
import classnames from "classnames";

import { packagesAPI } from "../../../../helpers/resource_helper";
import Loader from "../../../../Components/Common/Loader";
import { toast } from "react-toastify";
import SearchInput from "../../../../Components/Common/SearchInput";
import { getListUserAPI, getPackagesStatusCount, updatePackageAPI } from "../../../../helpers/service_helper";
import dayjs from "dayjs";

import { DragDropContext, Droppable } from "react-beautiful-dnd";
import ModalContainer from "../../../../Components/Common/ModalContainer";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { Form, Formik } from "formik";
import InputTheme from "../../../../Components/Common/InputTheme";
import * as Yup from "yup";
import GroupFilterStaff from "./GroupFilterStaff";
import ProcessOrderColumn from "./ProcessOrderColumn";
import withRouter from "../../../../Components/Common/withRouter";
import { useDebounce } from "../../../../Components/Hooks/UseDebounce";
import ScrollToError from "../../../../Components/Common/ScrollToError";

const ProcessOrderPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Process Order")} | OptiWarehouse`;
  const board = {
    columns: [],
  };
  const DATA_DEFAULT = ["PENDING", "BLOCKING", "AWAIT_PACKING", "PACKING", "READY", "SHIPPING"];
  const [loading, setLoading] = useState(false);
  const [controlledBoard, setBoard] = useState(board);
  const [searchValue, setSearchValue] = useState("");
  const debouncedInputValue = useDebounce(searchValue, 1000);

  // Border Top Nav Justified Tabs
  const [tabs, setTabs] = useState([]);
  const [topBorderjustifyTab, settopBorderjustifyTab] = useState([]);

  const [users, setUsers] = useState([]);
  const [payload, setPayload] = useState({});

  const [isModal, setIsModal] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const [saveSource, setSaveSource] = useState({});
  const [saveDestination, setSaveDestination] = useState({});
  const [saveIdPackage, setSaveIdPackage] = useState("");

  const listSort = [
    {
      name: "Today",
      value: [dayjs().subtract(1, "d").toISOString(), dayjs().toISOString()],
    },
    {
      name: "Yesterday",
      value: [dayjs().subtract(2, "d").toISOString(), dayjs().subtract(1, "d").toISOString()],
    },
    {
      name: "Last week",
      value: [dayjs().subtract(2, "w").toISOString(), dayjs().subtract(1, "w").toISOString()],
    },
    {
      name: "This week",
      value: [dayjs().subtract(1, "w").toISOString(), dayjs().toISOString()],
    },
    {
      name: "Last month",
      value: [dayjs().subtract(2, "M").toISOString(), dayjs().subtract(1, "M").toISOString()],
    },
    {
      name: "This month",
      value: [dayjs().subtract(1, "M").toISOString(), dayjs().toISOString()],
    },
  ];

  const [valueSortBy, setValueSortBy] = useState([]);

  function mergeArrays(array1 = [], array2 = []) {
    const mergedArray = [...array1];
    array2.forEach((item2) => {
      const existingItem = mergedArray.find((item1) => item1.key === item2.key);
      if (existingItem) {
        existingItem.doc_count += item2.doc_count;
      } else {
        mergedArray.push(item2);
      }
    });
    return mergedArray;
  }

  // const handleCardMove = async (_card, source, destination) => {
  //   if (_card.type !== "button" && _card.type !== "emty") {
  //     if (source.fromColumnId + 1 === destination.toColumnId) {
  //       const updatedBoard = moveCard(controlledBoard, source, destination);
  //       setBoard(updatedBoard);
  //       if (source.fromColumnId === 0) {
  //         await packagesAPI.createCustomPath(`${_card.id}/pack`, {});
  //       } else if (source.fromColumnId === 1) {
  //         await packagesAPI.createCustomPath(`${_card.id}/ready`, {});
  //       } else if (source.fromColumnId === 2) {
  //         await packagesAPI.createCustomPath(`${_card.id}/ship`, {});
  //       }
  //       toast.success(t("Change status success!"));
  //     } else {
  //       toast.error(t("No change status!"));
  //     }
  //   }
  // };

  const getApi = async () => {
    // setLoading(true);
    const mapApi = DATA_DEFAULT.map((item) => {
      return packagesAPI.list({
        sort_created_at: "desc",
        status: item,
        limit: 20,
        ...(payload && { ...payload }),
        ...(debouncedInputValue && { query: debouncedInputValue }),
        ...(valueSortBy.length > 0 && { created_at_from: valueSortBy[0], created_at_to: valueSortBy[1] }),
      });
    });
    const resCard = await Promise.all(mapApi);

    const cloneColumns = { ...columns };
    for (const key in cloneColumns) {
      const element = cloneColumns[key];
      const res_obj = resCard[key];
      element.items = res_obj.items;
      element.page = res_obj.page;
      element.hasMore = Number(res_obj.limit) * (Number(res_obj.page) + 1) < res_obj.total ? true : false;
      cloneColumns[key] = element;
    }
    setColumns(cloneColumns);
    // setLoading(false);
  };

  const getCountProcessOrder = async () => {
    const res = await getPackagesStatusCount();
    const listTab = [];
    let dataStatusCount = {};
    for (let index = 0; index < res.length; index++) {
      const element = res[index];
      if (DATA_DEFAULT.includes(element.key)) {
        if (Object.keys(dataStatusCount).length > 0) {
          for (const key in dataStatusCount) {
            if (typeof dataStatusCount[key] === "object") {
              const newBuckets = mergeArrays(dataStatusCount[key].buckets || [], element[key].buckets || []);
              dataStatusCount[key].buckets = newBuckets;
            }
          }
        } else {
          dataStatusCount = element;
        }
      }
    }

    for (const key in dataStatusCount) {
      if (Object.hasOwnProperty.call(dataStatusCount, key)) {
        const element = dataStatusCount[key];
        if (typeof element === "object") {
          listTab.push({
            name: key,
            keySearch: key === "provider_count" ? "shipping_provider" : "package_number",
            tabs: element.buckets,
          });
        }
      }
    }
    setTabs(listTab);
  };

  const getListUser = async () => {
    try {
      const company_id = JSON.parse(localStorage.getItem("auth_user"))?.UserAttributes["custom:company_id"];
      const res = await getListUserAPI({ company_id });
      const mapRes = res.Users.map((item) => {
        return {
          id: item.Attributes.find((u) => u.Name === "sub").Value,
          name: item.Username,
        };
      });
      setUsers(mapRes);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getCountProcessOrder();
    getListUser();
  }, []);

  const handleGetLoadMoreCard = async (typeCard, dataColumn, typeLoad) => {
    if (typeLoad === "search") {
      setBoard((data) => {
        const dataMap = data.columns.map((i, indexMap) => {
          if (i.title === typeCard) {
            const cloneData = [...i.cards];
            cloneData.pop();
            const newData = {
              id: indexMap,
              title: typeCard,
              total: dataColumn.total,
              cards:
                dataColumn.items.length === 0
                  ? [
                      {
                        type: "emty",
                      },
                    ]
                  : [...dataColumn.items],
              limit: Number(dataColumn.limit),
              page: Number(dataColumn.page),
            };
            return newData;
          }
          return i;
        });
        return {
          columns: dataMap,
        };
      });
    } else {
      try {
        const res = await packagesAPI.list({
          sort_created_at: "desc",
          status: typeCard,
          limit: 20,
          page: Number(dataColumn.page) + 1,
        });
        setBoard((data) => {
          const dataMap = data.columns.map((i, indexMap) => {
            if (i.title === typeCard) {
              const cloneData = [...i.cards];
              cloneData.pop();
              const newData = {
                id: indexMap,
                title: typeCard,
                total: res.total,
                cards: [...cloneData, ...res.items],
                limit: Number(res.limit),
                page: Number(res.page),
              };

              if (Number(res.limit) * (Number(res.page) + 1) < res.total) {
                newData.cards.push({
                  id: crypto.randomUUID(),
                  type: "button",
                  handleGetLoadMore: () => handleGetLoadMoreCard(typeCard, res),
                });
              }
              return newData;
            }
            return i;
          });
          return {
            columns: dataMap,
          };
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    getApi();
    // setCount(45);
  }, [payload, valueSortBy]);

  // const [count, setCount] = useState(45);
  // useEffect(() => {
  //   const refId = setInterval(() => {
  //     setCount((c) => {
  //       if (c === 0) {
  //         return 45;
  //       } else {
  //         return c - 1;
  //       }
  //     });
  //   }, 1000);
  //   return () => {
  //     clearInterval(refId);
  //   };
  // }, []);
  // useEffect(() => {
  //   if (count === 0) {
  //     getApi(searchValue);
  //   }
  // }, [count]);

  const obj = DATA_DEFAULT.reduce((acc, current, index, array) => {
    acc[index] = {
      title: current,
      items: [],
    };
    return acc;
  }, {});
  const [columns, setColumns] = useState(obj);

  const onDragEnd = async (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const sourceColumn = columns[source.droppableId];
    const sourceItems = [...sourceColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    if (DATA_DEFAULT[destination.droppableId] === "BLOCKING") {
      if (source.droppableId !== destination.droppableId) {
        setIsModal(true);
        setSaveDestination(destination);
        setSaveSource(source);
        setSaveIdPackage(removed.id);
      }
    } else {
      try {
        const status = destination.droppableId;
        handleDragEnd(source, destination, columns, setColumns);
        if (source.droppableId !== destination.droppableId) {
          await updatePackageAPI(
            removed.id,
            status === "3"
              ? "pack"
              : status === "4"
              ? "ready"
              : status === "5"
              ? "ship"
              : status === "2"
              ? "await_packing"
              : "",
            {},
          );
          toast.success(t("Change success"));
        }
      } catch (error) {
        handleDragEnd(destination, source);
        toast.error(error.toString());
      }
    }
  };

  const handleDragEnd = (source, destination) => {
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  const form = {
    initialValues: {
      note: "",
    },
    validationSchema: Yup.object().shape({
      note: Yup.string().required("Note is required"),
    }),
    onSubmit: async (values, actions) => {
      try {
        setLoadingButton(true);
        await updatePackageAPI(saveIdPackage, "blocking", values);
        handleDragEnd(saveSource, saveDestination);
        setLoadingButton(false);
        setIsModal(false);
        actions.resetForm();
      } catch (error) {
        toast.error(error.toString());
        setLoadingButton(false);
      }
    },
  };

  const formRef = useRef();

  return (
    <React.Fragment>
      <div className="page-content">
        <ModalContainer
          isOpen={isModal}
          toggle={() => {
            setIsModal(false);
          }}
          title={t("Process order")}
          actionCustom={
            <>
              <ButtonTheme
                type="button"
                className="btn btn-light w-sm"
                onClick={() => {
                  setIsModal(false);
                }}
              >
                {t("Close")}
              </ButtonTheme>
              <ButtonTheme
                type="button"
                loading={loadingButton}
                loadShowText={true}
                className="btn btn-primary w-sm"
                onClick={() => {
                  formRef.current.handleSubmit();
                }}
              >
                {t("Confirm")}
              </ButtonTheme>
            </>
          }
        >
          <Formik
            enableReinitialize={true}
            initialValues={form.initialValues}
            validationSchema={form.validationSchema}
            onSubmit={form.onSubmit}
            innerRef={formRef}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <ScrollToError />
                <p>
                  {t(
                    "Transfer the application to the pending process for other departments to check if there are problems with the application.",
                  )}
                </p>
                <p>{t("Please clearly state the details of the problem.")}</p>
                <InputTheme type="textarea" name={"note"} label={""} placeholder="Enter reason..." />
              </Form>
            )}
          </Formik>
        </ModalContainer>
        <Container fluid>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Card>
                <CardBody>
                  <ReloadProcessOrder
                    getApiReset={() => {
                      getApi();
                    }}
                  />

                  {tabs.map((item, key) => (
                    <div key={key}>
                      <h4>{t(item.name)}</h4>
                      <Nav tabs className="nav nav-tabs nav-border-top nav-border-top-primary mb-3">
                        {item.tabs.map((tab, index) => (
                          <NavLink
                            key={index}
                            style={{ cursor: "pointer" }}
                            className={classnames({ active: topBorderjustifyTab.includes(`${item.name}-${index}`) })}
                            onClick={() => {
                              const cloneData = [...topBorderjustifyTab];
                              const newKeySearch = payload[item.keySearch] ? payload[item.keySearch].split(",") : [];
                              if (topBorderjustifyTab.includes(`${item.name}-${index}`)) {
                                const newData = cloneData.filter((i) => i !== `${item.name}-${index}`);
                                settopBorderjustifyTab(newData);
                                const newSearch = newKeySearch.filter((item) => item !== tab.key);
                                setPayload({ ...payload, [item.keySearch]: newSearch.join(",") });
                              } else {
                                cloneData.push(`${item.name}-${index}`);
                                newKeySearch.push(tab.key);
                                settopBorderjustifyTab(cloneData);
                                setPayload({ ...payload, [item.keySearch]: newKeySearch.join(",") });
                              }
                            }}
                          >
                            <span key={index}>
                              <i className="ri-store-2-fill me-1 align-bottom"></i> {tab.key}
                              <small className="badge bg-secondary align-bottom ms-1 totaltask-badge">
                                {tab.doc_count}
                              </small>
                            </span>
                          </NavLink>
                        ))}
                      </Nav>
                    </div>
                  ))}
                  <div className="d-flex align-items-center">
                    <div style={{ width: "400px" }}>
                      <SearchInput
                        onChangeValue={async (value) => {
                          setSearchValue(value);
                        }}
                        searchValue={searchValue}
                        onDeleteSearchValue={() => setSearchValue("")}
                      />
                    </div>
                    <div className="mx-4 d-flex flex-wrap gap-3">
                      {listSort.map((item, index) => (
                        <span
                          key={index}
                          className={`btn cursor-pointer select-none ${
                            dayjs(valueSortBy[0]).format("MM-DD-YYYY") === dayjs(item.value[0]).format("MM-DD-YYYY") &&
                            dayjs(valueSortBy[1]).format("MM-DD-YYYY") === dayjs(item.value[1]).format("MM-DD-YYYY")
                              ? "btn-primary"
                              : "btn-outline-primary"
                          } `}
                          onClick={() => {
                            if (
                              dayjs(valueSortBy[0]).format("MM-DD-YYYY") ===
                                dayjs(item.value[0]).format("MM-DD-YYYY") &&
                              dayjs(valueSortBy[1]).format("MM-DD-YYYY") === dayjs(item.value[1]).format("MM-DD-YYYY")
                            ) {
                              setValueSortBy([]);
                            } else {
                              setValueSortBy(item.value);
                            }
                          }}
                        >
                          {t(item.name)}
                        </span>
                      ))}
                    </div>
                    <GroupFilterStaff
                      users={users}
                      onChangeFilter={(users) => {
                        setPayload({ ...payload, packing_staff: users.join(",") });
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
              <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
                <div>
                  <div className="d-flex gap-4">
                    {Object.entries(columns).map(([columnId, column], index) => {
                      return (
                        <Droppable key={columnId} droppableId={columnId}>
                          {(provided, snapshot) => (
                            <ProcessOrderColumn
                              provided={provided}
                              column={column}
                              users={users}
                              onChangeUserOrderColumn={(user, item) => {
                                const cloneItem = { ...item, packing_staff: user.id };
                                const mapItemsColumn = column.items.map((i) => {
                                  if (i.id === cloneItem.id) {
                                    return cloneItem;
                                  }
                                  return i;
                                });
                                const cloneColumns = { ...columns };
                                cloneColumns[columnId] = { ...column, items: mapItemsColumn };
                                setColumns(cloneColumns);
                              }}
                              getDataScroll={async (page, status) => {
                                const res = await packagesAPI.list({
                                  sort_created_at: "desc",
                                  status: status,
                                  limit: 20,
                                  page: page,
                                  ...(payload && { ...payload }),
                                  ...(searchValue && { query: searchValue }),
                                  ...(valueSortBy.length > 0 && {
                                    created_at_from: valueSortBy[0],
                                    created_at_to: valueSortBy[1],
                                  }),
                                });
                                const cloneColumns = { ...columns };
                                for (const key in cloneColumns) {
                                  const element = cloneColumns[key];
                                  if (element.title === status) {
                                    const res_obj = res;
                                    element.items = [...columns[key].items, ...res_obj.items];
                                    element.page = res_obj.page;
                                    element.hasMore =
                                      Number(res_obj.limit) * (Number(res_obj.page) + 1) < res_obj.total ? true : false;
                                  }
                                  cloneColumns[key] = element;
                                }
                                setColumns(cloneColumns);
                              }}
                            />
                          )}
                        </Droppable>
                      );
                    })}
                  </div>
                </div>
              </DragDropContext>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const ReloadProcessOrder = ({ getApiReset = () => {} }) => {
  const [count, setCount] = useState(45);
  useEffect(() => {
    const refId = setInterval(() => {
      setCount((c) => {
        if (c === 0) {
          return 45;
        } else {
          return c - 1;
        }
      });
    }, 1000);
    return () => {
      clearInterval(refId);
    };
  }, []);
  useEffect(() => {
    if (count === 0) {
      getApiReset();
    }
  }, [count]);

  return (
    <div className="reset-package">
      <i className="ri-restart-line mx-1"></i>
      <span>{count} s</span>
    </div>
  );
};

export default withRouter(ProcessOrderPage);
