import { Actions } from "../../../Components/constants/common";
import {
  LIST_ACTIVITY,
  LIST_ACTIVITY_SUCCESS,
  LIST_ACTIVITY_FAIL,
  GET_ACTIVITY,
  GET_ACTIVITY_SUCCESS,
  GET_ACTIVITY_FAIL,
  RESET_ACTIVITY_FLAG,
} from "./actionType";

const INIT_STATE = {
  activities: [],
  activity: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const Activities = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_ACTIVITY:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_ACTIVITY_SUCCESS:
      return {
        ...state,
        message: "Get activities success",
        loading: false,
        success: true,
        activities: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_ACTIVITY_FAIL:
      return {
        ...state,
        message: "Get activities failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_ACTIVITY:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_ACTIVITY_SUCCESS:
      return {
        ...state,
        message: "Get activity success",
        loading: false,
        success: true,
        activity: action.payload,
        action: Actions.EMPTY,
      };

    case GET_ACTIVITY_FAIL:
      return {
        ...state,
        message: "Get activity failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case RESET_ACTIVITY_FLAG:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Activities;
