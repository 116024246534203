import { Actions } from "../../../../Components/constants/common";
import {
  LIST_TRANSACTION_VOUCHER,
  DELETE_TRANSACTION_VOUCHER,
  DELETE_TRANSACTION_VOUCHER_SUCCESS,
  DELETE_TRANSACTION_VOUCHER_FAIL,
  ADD_TRANSACTION_VOUCHER_SUCCESS,
  ADD_TRANSACTION_VOUCHER_FAIL,
  UPDATE_TRANSACTION_VOUCHER_SUCCESS,
  UPDATE_TRANSACTION_VOUCHER_FAIL,
  LIST_TRANSACTION_VOUCHER_SUCCESS,
  LIST_TRANSACTION_VOUCHER_FAIL,
  ADD_NEW_TRANSACTION_VOUCHER,
  UPDATE_TRANSACTION_VOUCHER,
  GET_TRANSACTION_VOUCHER,
  GET_TRANSACTION_VOUCHER_SUCCESS,
  GET_TRANSACTION_VOUCHER_FAIL,
  RESET_TRANSACTION_VOUCHER_FLAG,
} from "./actionType";

const INIT_STATE = {
  transactionVouchers: [],
  transactionVoucher: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const TransactionVouchers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_TRANSACTION_VOUCHER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_TRANSACTION_VOUCHER_SUCCESS:
      return {
        ...state,
        message: "Get transaction vouchers success",
        loading: false,
        success: true,
        transactionVouchers: action.payload.items ?? [],
        limit: Number(action?.payload?.limit || 0),
        page: Number(action?.payload?.page || 0),
        total: Number(action?.payload?.total || 0),
        action: Actions.EMPTY,
      };

    case LIST_TRANSACTION_VOUCHER_FAIL:
      return {
        ...state,
        message: "Get transaction vouchers failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_TRANSACTION_VOUCHER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_TRANSACTION_VOUCHER_SUCCESS:
      return {
        ...state,
        message: "Get transaction voucher success",
        loading: false,
        success: true,
        transactionVoucher: action.payload,
        action: Actions.EMPTY,
      };

    case GET_TRANSACTION_VOUCHER_FAIL:
      return {
        ...state,
        message: "Get transaction voucher failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_TRANSACTION_VOUCHER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_TRANSACTION_VOUCHER_SUCCESS:
      return {
        ...state,
        message: "Delete transaction voucher success",
        loading: false,
        success: true,
        transactionVouchers: state.transactionVouchers.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_TRANSACTION_VOUCHER_FAIL:
      return {
        ...state,
        message: "Delete transaction voucher failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_NEW_TRANSACTION_VOUCHER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_TRANSACTION_VOUCHER_SUCCESS:
      return {
        ...state,
        message: "Add transaction voucher success",
        success: true,
        loading: false,
        transactionVouchers: [...state.transactionVouchers, action.payload],
        action: Actions.EMPTY,
      };

    case ADD_TRANSACTION_VOUCHER_FAIL:
      return {
        ...state,
        message: "Add transaction voucher failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_TRANSACTION_VOUCHER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.UPDATE,
      };
    case UPDATE_TRANSACTION_VOUCHER_SUCCESS:
      return {
        ...state,
        message: "Update transaction voucher success",
        loading: false,
        success: true,
        transactionVouchers: state.transactionVouchers.map((transactionVoucher) =>
          transactionVoucher.id.toString() === action.payload.id.toString() ? { ...transactionVoucher, ...action.payload } : transactionVoucher,
        ),
        action: Actions.EMPTY,
      };

    case UPDATE_TRANSACTION_VOUCHER_FAIL:
      return {
        ...state,
        message: "Update transaction voucher failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case RESET_TRANSACTION_VOUCHER_FLAG:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default TransactionVouchers;
