import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Collapse, Container, Form, Row } from "reactstrap";
import InputTheme from "../../../../Components/Common/InputTheme";
import * as Yup from "yup";
import { Checkbox } from "antd";
import BreadCrumbCustom from "../../../../Components/Common/BreadCrumbCustom";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { permissionAPI, roleAPI } from "../../../../helpers/resource_helper";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import { getPermissionDefault } from "../../../../helpers/service_helper";
import Loader from "../../../../Components/Common/Loader";
import { useTranslation } from "react-i18next";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { useDispatch } from "react-redux";
import { addNewRole, deleteRole, getRole, resetRole, updateRole } from "../../../../store/actions";
import { SubPlanDetails } from "../../../../common/data/Permission";
import { useProfile } from "../../../../Components/Hooks/useProfile";
import ScrollToError from "../../../../Components/Common/ScrollToError";

export default function DetailRole() {
  const { role, rolesLoading, rolesSuccess, rolesError, rolesLimit, rolesTotal, rolesPage } = useMemoizedSelector(
    (state) => ({
      role: state.Roles.role,
      rolesLoading: state.Roles.loading,
      rolesSuccess: state.Roles.success,
      rolesError: state.Roles.error,
      rolesLimit: state.Roles.limit,
      rolesTotal: state.Roles.total,
      rolesPage: state.Roles.page,
    }),
  );
  const { userProfile } = useProfile();
  const dispatch = useDispatch();
  const custom_plan = userProfile?.UserAttributes?.["custom:plan"];
  const subPlanDetails = custom_plan ? SubPlanDetails?.[custom_plan] || SubPlanDetails.POS : SubPlanDetails.POS;
  //state data
  const [dataPermission, setDataPermission] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const isEdit = id !== "create-role";
  const [deleteModal, setDeleteModal] = useState(false);
  const [permissionDefault, setPermissionDefault] = useState([]);
  const [loadingDefaultPermission, setLoadingDefaultPermission] = useState(false);
  const { t } = useTranslation();
  const handleAfterSubmit = () => {
    navigate("/settings/accounts/role");
  };
  const handleDelete = () => {
    dispatch(deleteRole(role, handleAfterSubmit));
  };

  const form = {
    initialValues: {
      role: role?.role || "",
      note: role?.note || "",
      full_permission: false,
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: (values) => {
      if (isEdit) {
        dispatch(updateRole({ ...role, ...values, permission: dataPermission }, handleAfterSubmit));
      } else {
        dispatch(addNewRole({ ...values, permission: dataPermission }, handleAfterSubmit));
      }
    },
  };

  const formRef = useRef();

  const getApiDefaultPermission = async () => {
    setLoadingDefaultPermission(true);
    const res = await getPermissionDefault();
    const mapRes = Object.keys(res).map((key) => ({
      key: key,
      label: key,
      children: Object.keys(res[key]).map((item) => ({
        key: item,
        label: res[key][item].description,
        defaultActive: res[key][item].active,
      })),
    }));

    setPermissionDefault(mapRes);
    setLoadingDefaultPermission(false);
  };

  useEffect(() => {
    if (id !== "create-role") {
      dispatch(getRole(id));
    } else {
      dispatch(resetRole());
    }
    getApiDefaultPermission();
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => {
            setDeleteModal(false);
          }}
          loading={rolesLoading}
        />
        <BreadCrumbCustom text="Back to role list" navigateTo="/settings/accounts/role">
          <ButtonTheme
            type="button"
            loadShowText={true}
            loading={rolesLoading}
            className="btn btn-primary w-sm mx-2"
            onClick={async () => {
              formRef.current.handleSubmit();
            }}
          >
            {t("Save")}
          </ButtonTheme>
          {isEdit && (
            <ButtonTheme
              type="button"
              className="btn btn-danger w-sm"
              onClick={async () => {
                setDeleteModal(true);
              }}
            >
              {t("Delete")}
            </ButtonTheme>
          )}
        </BreadCrumbCustom>
        <Card>
          <CardBody>
            <Formik
              enableReinitialize={true}
              initialValues={form.initialValues}
              validationSchema={form.validationSchema}
              onSubmit={form.onSubmit}
              innerRef={formRef}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <ScrollToError />
                  <Row>
                    <Col lg={6}>
                      <InputTheme label={"Role"} name="role" />
                    </Col>
                    <Col lg={6}>
                      <InputTheme label={"Note"} name="note" />
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>{t("Detailed permissions")}</CardHeader>
          <CardBody>
            {loadingDefaultPermission ? (
              <Loader />
            ) : (
              <>
                {permissionDefault.map((permission, index) => (
                  <CollapsePermission
                    key={index}
                    list={permission}
                    onChangeData={(data) => {
                      setDataPermission((dataPermission) => ({
                        ...dataPermission,
                        ...data,
                      }));
                    }}
                    subPlanDetails={subPlanDetails}
                    dataSelect={role?.permission}
                    lastKey={permissionDefault.length - 1 === index ? true : false}
                  />
                ))}
              </>
            )}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}

const CollapsePermission = ({ list, onChangeData = () => {}, lastKey, dataSelect = {}, subPlanDetails = [] }) => {
  const { t } = useTranslation();
  const [collapse, setCollapse] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectAllChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { checked } = e.target;
    const keys = checked ? list.children.map((k) => k.key) : [];
    setSelectedRowKeys(keys);
  };

  useEffect(() => {
    const obj = list.children.reduce((acc, current, index, array) => {
      acc[current.key] = selectedRowKeys?.find((item) => item === current.key) ? true : false;
      return acc;
    }, {});
    onChangeData({
      [list.key]: obj,
    });
  }, [selectedRowKeys]);

  useEffect(() => {
    if (Object.keys(dataSelect).length > 0 && dataSelect[list.key]) {
      const arrayKey = Object.keys(dataSelect[list.key]);
      const filterArrayKey = arrayKey.filter((item) => dataSelect[list.key][item]);
      setSelectedRowKeys(filterArrayKey);
    }
  }, [dataSelect]);

  const DATA_LABEL = {
    order: "Order",
    return_order: "Return order",
    stock_adjustment: "Stock adjustment",
    stock_relocate: "Stock relocate",
    purchase_orders: "Purchase orders",
    package: "Package",
    customer_group: "Customer group",
    import: "Import",
    record: "Record",
    payment_methods: "Payment methods",
    transaction: "Transaction",
    account: "Account",
    report: "Report",
    inventory_item: "Inventory item",
    blog: "Blog",
    blog_category: "Blog category",
    terminal: "Terminal",
    shift: "Shift",
    settings: "Settings",
    voucher_code: "Voucher code",
    voucher: "Voucher",
    store: "Store",
    shipping_provider: "Shipping provider",
    location: "Location",
    discount: "Discount",
    variant: "Variant",
    product: "Product",
    brand: "Brand",
    category: "Category",
    customer: "Customer",
    redeem: "Redeem",
    reward: "Reward",
    supplier: "Supplier",
  };

  return (
    <div className={`${lastKey ? "" : "border-bottom"} `}>
      <div className="d-flex gap-2 flex-wrap align-items-center px-2 py-3">
        <Checkbox
          indeterminate={selectedRowKeys?.length > 0 && selectedRowKeys?.length < list.children?.length}
          checked={selectedRowKeys?.length === list.children?.length && selectedRowKeys?.length > 0}
          onChange={onSelectAllChange}
          disabled={!subPlanDetails.includes(list.key)}
        />

        <div
          className="d-flex gap-2 flex-wrap align-items-center"
          onClick={() => {
            setCollapse(!collapse);
          }}
        >
          <span>{t(DATA_LABEL[list.label])}</span>

          <i
            className="ri-arrow-right-s-line fs-19"
            style={{
              transform: `${collapse ? "rotate(90deg)" : "rotate(0deg)"}`,
              transition: "all 0.3s ease",
            }}
          ></i>
        </div>
      </div>
      <Collapse isOpen={collapse}>
        <div className="mb-0">
          <CardBody>
            <Row>
              {list.children.map((permission, index) => (
                <Col lg={4} key={index}>
                  <Checkbox
                    disabled={!subPlanDetails.includes(list.key)}
                    checked={selectedRowKeys.includes(permission?.key)}
                    onChange={(e) => {
                      const check = e.target.checked;
                      const keyRow = permission?.key;
                      if (check) {
                        setSelectedRowKeys([...selectedRowKeys, keyRow]);
                      } else {
                        setSelectedRowKeys(selectedRowKeys.filter((item) => item !== keyRow));
                      }
                    }}
                    className="mb-3"
                  >
                    {t(permission?.label)}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </CardBody>
        </div>
      </Collapse>
    </div>
  );
};
