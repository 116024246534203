import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { formatVNDCurrency } from "../../../../../helpers/format_helper";

import DiscountDecimal from "./Columns/DiscountDecimal";
import DiscountRateValue from "./Columns/DiscountRateValue";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import SimpleTableContainer from "../../../../../Components/Common/SimpleTableContainer";

const DiscountByOrderTotal = ({
  onDeleteConditionLine = () => {},
  onChangeConditionLine = () => {},
  data = null,
  isEdit = true,
}) => {
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        id: "amount_from",
        Header: () => <div>{t("Amount from")}</div>,
        Cell: (cell) => {
          const row = cell.row.original;
          const index = cell.row.index;
          return (
            <Fragment>
              {isEdit ? (
                <DiscountDecimal
                  data={row}
                  index={index}
                  field={"amount_from"}
                  onChangeConditionLine={onChangeConditionLine}
                  className="text-end"
                />
              ) : row?.amount_from ? (
                formatVNDCurrency(row?.amount_from)
              ) : (
                "∞"
              )}
            </Fragment>
          );
        },
      },
      {
        id: "amount_to",
        Header: () => <div className={isEdit ? "text-end" : "text-center"}>{t("Amount to")}</div>,
        Cell: (cell) => {
          const row = cell.row.original;
          const index = cell.row.index;
          return (
            <Fragment>
              {isEdit ? (
                <DiscountDecimal
                  data={row}
                  index={index}
                  field={"amount_to"}
                  onChangeConditionLine={onChangeConditionLine}
                  className="text-end"
                  disabled={!isEdit}
                />
              ) : (
                <div className="text-center">{row?.amount_to ? formatVNDCurrency(row?.amount_to) : "∞"}</div>
              )}
            </Fragment>
          );
        },
      },
      {
        id: "discount",
        Header: () => <div className="text-end">{t("Discount")}</div>,
        Cell: (cell) => {
          const row = cell.row.original;
          const index = cell.row.index;
          return (
            <DiscountRateValue
              data={row}
              index={index}
              onChangeConditionLine={onChangeConditionLine}
              className="text-end"
              disabled={!isEdit}
            />
          );
        },
      },
      {
        id: "action",
        Header: () => <div></div>,
        Cell: (cell) => {
          let index = cell.row.index;
          return (
            <div className="fw-medium text-end">
              {isEdit ? (
                <ButtonTheme
                  type="button"
                  className="btn btn-outline-danger btn-icon waves-effect waves-light"
                  onClick={() => {
                    onDeleteConditionLine(index);
                  }}
                >
                  <i className="ri-delete-bin-5-line"></i>
                </ButtonTheme>
              ) : null}
            </div>
          );
        },
      },
    ],
    [data],
  );

  return (
    <Fragment>
      <div className="my-3 w-100">
        <SimpleTableContainer
          columns={columns}
          data={data || []}
          divClass="table-responsive"
          tableClass="table table-nowrap align-middle table-bdiscountless mb-0"
          theadClass="table-light text-muted"
        />
      </div>
    </Fragment>
  );
};

export default DiscountByOrderTotal;
