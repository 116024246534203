import { apiCli } from "../api_helper";

export const signInAPI = (data) => apiCli.create("auth/login", data);

export const signUpAPI = (data) => apiCli.create("auth/register", data);

export const getInfoAPI = (params) => {
  const queryString = params
    ? Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join("&")
    : "";
  return apiCli.get(`auth/user_info?${queryString}`);
};

export const changePasswordAPI = (data) => apiCli.create("auth/change_password", data);

export const confirmCodeAPI = (data) => apiCli.create("auth/confirm", data);

export const resendCodeAPI = (data) => apiCli.create("auth/resend_code", data);

export const forgotPasswordAPI = (data) => apiCli.create("auth/forgot", data);

export const resetPasswordAPI = (data) => apiCli.create("auth/new_password", data);

export const refreshTokenAPI = (data) => apiCli.create("auth/refresh", data);
