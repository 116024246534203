import { Actions } from "../../../../Components/constants/common";
import {
  LIST_PURCHASE_ORDER,
  DELETE_PURCHASE_ORDER,
  DELETE_PURCHASE_ORDER_SUCCESS,
  DELETE_PURCHASE_ORDER_FAIL,
  ADD_PURCHASE_ORDER_SUCCESS,
  ADD_PURCHASE_ORDER_FAIL,
  UPDATE_PURCHASE_ORDER_SUCCESS,
  UPDATE_PURCHASE_ORDER_FAIL,
  LIST_PURCHASE_ORDER_SUCCESS,
  LIST_PURCHASE_ORDER_FAIL,
  ADD_NEW_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER,
  GET_PURCHASE_ORDER,
  GET_PURCHASE_ORDER_SUCCESS,
  GET_PURCHASE_ORDER_FAIL,
  RESET_PURCHASE_ORDER_FLAG,
} from "./actionType";

const INIT_STATE = {
  purchase_orders: [],
  purchase_order: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const PurchaseOrders = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_PURCHASE_ORDER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        message: "Get purchase orders success",
        purchase_orders: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_PURCHASE_ORDER_FAIL:
      return {
        ...state,
        message: "Get purchase orders fail",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_PURCHASE_ORDER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        message: "Get purchase order success",
        loading: false,
        success: true,
        purchase_order: action.payload,
        action: Actions.EMPTY,
      };

    case GET_PURCHASE_ORDER_FAIL:
      return {
        ...state,
        message: "Get purchase order success",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_PURCHASE_ORDER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        message: "Delete purchase order success",
        loading: false,
        success: true,
        purchase_orders: state.purchase_orders.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_PURCHASE_ORDER_FAIL:
      return {
        ...state,
        message: "Delete purchase order failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_NEW_PURCHASE_ORDER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        message: "Add purchase order success",
        success: true,
        loading: false,
        purchase_orders: [...state.purchase_orders, action.payload],
        action: Actions.EMPTY,
      };

    case ADD_PURCHASE_ORDER_FAIL:
      return {
        ...state,
        message: "Add purchase order failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_PURCHASE_ORDER:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.UPDATE,
      };
    case UPDATE_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        message: "Update purchase order success",
        loading: false,
        success: true,
        purchase_order: action.payload,
        purchase_orders: state.purchase_orders.map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload } : item,
        ),
        action: Actions.EMPTY,
      };

    case UPDATE_PURCHASE_ORDER_FAIL:
      return {
        ...state,
        message: "Update purchase order failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case RESET_PURCHASE_ORDER_FLAG:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default PurchaseOrders;
