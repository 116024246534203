import { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Card, CardBody, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";

import Loader from "../../../../Components/Common/Loader";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import ModalContainer from "../../../../Components/Common/ModalContainer";
import AddressTheme from "../../../../Components/Common/AddressTheme";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { Actions } from "../../../../Components/constants/common";

import { addNewSupplier, getSupplier, updateSupplier } from "../../../../store/actions";

const AddEditSupplierModal = ({ supplierId = "", isOpen, toggle, onAddUpdateSupplier = () => {} }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { supplier, suppliersLoading, suppliersAction, supplierMessage, suppliersSuccess, suppliersError } =
    useMemoizedSelector((state) => ({
      supplier: state.Suppliers.supplier,
      suppliersAction: state.Suppliers.action,
      suppliersLoading: state.Suppliers.loading,
      suppliersSuccess: state.Suppliers.success,
      suppliersError: state.Suppliers.error,
      supplierMessage: state.Suppliers.message,
    }));

  const [addresses, setAddresses] = useState([]);

  const handleChangeAddresses = (index, newData) => {
    setAddresses(addresses.map((item, i) => (index === i ? newData : item)));
  };

  const handleClickMoreAddress = () => {
    let newAddress = {
      address1: "",
      city: "",
      province: "",
      country: "Việt Nam",
      country_code: "VN",
      district: "",
      ward: "",
    };
    setAddresses([...addresses, newAddress]);
  };

  const handleClickDeleteAddress = (index) => {
    setAddresses(addresses.filter((item, i) => i !== index));
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      company_name: (supplierId && supplier?.company_name) || "",
      first_name: (supplierId && supplier?.first_name) || "",
      last_name: (supplierId && supplier?.last_name) || "",
      phone: (supplierId && supplier?.phone) || "",
      email: (supplierId && supplier?.email) || "",
    },
    validationSchema: Yup.object({
      // company_name: Yup.string().required(t("Please Enter Company Name")),
      // first_name: Yup.string().required(t("Please Enter First Name")),
      // last_name: Yup.string().required(t("Please Enter Last Name")),
      // phone: Yup.string().required(t("Please Enter Phone Number")),
      // email: Yup.string().required(t("Please Enter Email")),
    }),
    onSubmit: (values) => {
      if (!values.email) {
        values.email = null;
      }
      if (!values.phone) {
        values.phone = null;
      }
      const newData = {
        company_name: values.company_name,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone: values.phone,
      };
      if (addresses.length > 0) {
        let newAddresses = addresses.map((item) => {
          return { ...item, first_name: newData.first_name, last_name: newData.last_name, phone: newData.phone };
        });
        newData.billing_address = newAddresses[0];
        newData.addresses = newAddresses;
      }
      const handleAfterSubmit = (newSupplier) => {
        onAddUpdateSupplier(newSupplier);
        handleToggle();
      };
      if (supplierId) {
        let payload = { ...supplier, ...newData };
        dispatch(updateSupplier(payload, handleAfterSubmit));
      } else {
        let payload = { ...newData };
        dispatch(addNewSupplier(payload, handleAfterSubmit));
      }
    },
  });

  useEffect(() => {
    setAddresses(supplierId && supplier?.addresses ? supplier?.addresses : []);
  }, [supplier, supplierId]);

  useEffect(() => {
    if (supplierId && isOpen) {
      dispatch(getSupplier(supplierId));
    }
  }, [supplierId, isOpen]);

  const handleToggle = () => {
    if (!suppliersLoading) {
      toggle();
      validation.resetForm();
      setAddresses([]);
    }
  };

  useEffect(() => {
    if (
      !suppliersLoading &&
      (supplierMessage === "Add supplier success" || supplierMessage === "Update supplier success")
    ) {
      handleToggle();
    }
  }, [suppliersLoading, supplierMessage]);

  return (
    <Fragment>
      <ModalContainer
        isOpen={isOpen}
        toggle={handleToggle}
        title={t(supplierId ? "Edit supplier" : "Add new supplier")}
        size="lg"
        centered={true}
        // scrollable={true}
        actionCustom={
          <>
            <ButtonTheme type="button" className="btn btn-light w-sm" onClick={handleToggle}>
              {t("Close")}
            </ButtonTheme>
            <ButtonTheme
              type="button"
              className="btn btn-primary w-sm"
              onClick={validation.handleSubmit}
              loading={suppliersLoading && (suppliersAction === Actions.UPDATE || suppliersAction === Actions.CREATE)}
              disabled={suppliersLoading && (suppliersAction === Actions.UPDATE || suppliersAction === Actions.CREATE)}
            >
              {t(supplier ? "Update" : "Add")}
            </ButtonTheme>
          </>
        }
      >
        {suppliersAction === Actions.GET ? (
          <Loader />
        ) : (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                  <Label for="company-name-input" className="form-label">
                    {t("Company name")}
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="company_name"
                    id="company-name-input"
                    className="form-control"
                    placeholder={t("Enter Company Name")}
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.company_name || ""}
                    invalid={validation.touched.company_name && validation.errors.company_name ? true : false}
                  />
                  {validation.touched.company_name && validation.errors.company_name ? (
                    <FormFeedback type="invalid">{validation.errors.company_name}</FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label for="first-name-input" className="form-label">
                    {t("First name")}
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="first_name"
                    id="first-name-input"
                    className="form-control"
                    placeholder={t("Enter First Name")}
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.first_name || ""}
                    invalid={validation.touched.first_name && validation.errors.first_name ? true : false}
                  />
                  {validation.touched.first_name && validation.errors.first_name ? (
                    <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label for="last-name-input" className="form-label">
                    {t("Last name")}
                  </Label>
                  <Input
                    name="last_name"
                    id="last-name-input"
                    className="form-control"
                    placeholder={t("Enter Last Name")}
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.last_name || ""}
                    invalid={validation.touched.last_name && validation.errors.last_name ? true : false}
                  />
                  {validation.touched.last_name && validation.errors.last_name ? (
                    <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label for="phone-number-input" className="form-label">
                    {t("Phone number")}
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="phone"
                    id="phone-number-input"
                    className="form-control"
                    placeholder={t("Enter Phone Number")}
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.phone || ""}
                    invalid={validation.touched.phone && validation.errors.phone ? true : false}
                  />
                  {validation.touched.phone && validation.errors.phone ? (
                    <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Label for="email-input" className="form-label">
                    {t("Email")}
                  </Label>
                  <Input
                    id="email-input"
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder={t("Enter Email")}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={validation.touched.email && validation.errors.email ? true : false}
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            {addresses.map((item, index) => (
              <Fragment key={index}>
                <Card className="border">
                  <i
                    className="position-absolute top-0 start-100 translate-middle ri-close-circle-fill text-danger fs-24 cursor-pointer"
                    onClick={() => handleClickDeleteAddress(index)}
                  ></i>
                  <CardBody>
                    <Row>
                      <AddressTheme address={item} index={index} handleChangeAddresses={handleChangeAddresses} />
                    </Row>
                  </CardBody>
                </Card>
              </Fragment>
            ))}
            <ButtonTheme
              type="button"
              className="btn btn-ghost-primary waves-effect waves-light"
              onClick={handleClickMoreAddress}
            >
              <i className="ri-add-line align-bottom me-1"></i>
              {t("More address")}
            </ButtonTheme>
          </Form>
        )}
      </ModalContainer>
    </Fragment>
  );
};

export default AddEditSupplierModal;
