import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

// Formik
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { addNewBrand, updateBrand } from "../../../../store/actions";
import InputTheme from "../../../../Components/Common/InputTheme";
import UploadTheme from "../../../../Components/Common/UploadTheme";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { Actions } from "../../../../Components/constants/common";
import ScrollToError from "../../../../Components/Common/ScrollToError";

const EditAddBrandModal = ({ show, toggle, brand = null, onChangeBrand = () => {} }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { brandsLoading, brandsAction, brandsSuccess } = useMemoizedSelector((state) => ({
    brandsLoading: state.Brands.loading,
    brandsAction: state.Brands.action,
    brandsSuccess: state.Brands.success,
  }));

  // validation
  const validation = {
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: brand?.name || "",
      image: brand?.image || null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Please Enter Brand Name")),
    }),
    onSubmit: (values, actions) => {
      if (brand?.id) {
        dispatch(
          updateBrand(
            {
              ...brand,
              ...values,
            },
            () => {
              actions.resetForm();
              handleClose();
            },
          ),
        );
      } else {
        dispatch(
          addNewBrand(
            {
              ...values,
            },
            (data) => {
              onChangeBrand(data);
              actions.resetForm();
              handleClose();
            },
          ),
        );
      }
    },
  };

  const handleClose = () => {
    if (!brandsLoading) {
      toggle();
    }
  };
  const formRef = useRef();
  return (
    <Modal fade={true} isOpen={show} toggle={handleClose} centered={true}>
      <ModalHeader className="bg-light p-3">{t(brand?.id ? t("Edit brand") : t("New brand"))}</ModalHeader>
      <ModalBody>
        <Formik
          enableReinitialize={true}
          initialValues={validation.initialValues}
          validationSchema={validation.validationSchema}
          onSubmit={validation.onSubmit}
          innerRef={formRef}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <ScrollToError />
              <InputTheme name={"name"} label={"Name"} placeholder={t("Enter Brand Name")} />
              <UploadTheme label="Image" name="image" />
            </Form>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>
        <div className="hstack gap-2 justify-content-end">
          <ButtonTheme type="button" className="btn btn-light w-sm" onClick={handleClose}>
            {t("Close")}
          </ButtonTheme>
          <ButtonTheme
            onClick={() => {
              formRef.current.handleSubmit();
            }}
            className="btn btn-primary w-sm"
            loading={brandsLoading && (brandsAction === Actions.UPDATE || brandsAction === Actions.CREATE)}
            disabled={brandsLoading && (brandsAction === Actions.UPDATE || brandsAction === Actions.CREATE)}
          >
            {t(brand?.id ? "Update" : "Add")}
          </ButtonTheme>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default EditAddBrandModal;
