import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import Widget from "./Component/Widgets";
import BestSellingProducts from "./Component/BestSellingProducts";
import RecentActivity from "./Component/RecentActivity";
import RecentOrders from "./Component/RecentOrders";
import Revenue from "./Component/Revenue";
import SalesByLocations from "./Component/SalesByLocations";
import Section from "./Component/Section";
import TopSellers from "./Component/TopSellers";
import {
  getBestSellingProducts,
  getOrderSummary,
  getRevenueByLocation,
  getRevenueBySource,
} from "../../../helpers/service_helper";
import { toast } from "react-toastify";
import withRouter from "../../../Components/Common/withRouter";
import SalesBySources from "./Component/SalesBySources";
import { ordersAPI } from "../../../helpers/resource_helper";

const DashboardEcommerce = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Dashboard")} | OptiWarehouse`;

  const [loading, setLoading] = useState(false);
  const [payloadParams, setPayloadParams] = useState(null);
  const [orderSummary, setOrderSummary] = useState(null);
  const [bestSellingProducts, setBestSellingProducts] = useState([]);
  const [revenueByLocation, setRevenueByLocation] = useState([]);
  const [revenueBySource, setRevenueBySource] = useState([]);
  const [recentOrders, setRecentOrders] = useState([]);
  const [filterTime, setFilterTime] = useState({
    firstDate: null,
    secondDate: null
  });
  const handleChangeParams = (data) => {
    setPayloadParams(data);
  };
  const getOrderSummaryData = async (data) => {
    try {
      setLoading(true);
      const res = await getOrderSummary(data);
      setOrderSummary(res);
    } catch (e) {
      toast.error(String(e));
    } finally {
      setLoading(false);
    }
  };
  const getBestSellingProductsData = async (data) => {
    try {
      setLoading(true);
      const res = await getBestSellingProducts(data);
      setBestSellingProducts(res);
    } catch (e) {
      toast.error(String(e));
    } finally {
      setLoading(false);
    }
  };

  const getRevenueByLocationData = async (data) => {
    try {
      setLoading(true);
      const res = await getRevenueByLocation(data);
      setRevenueByLocation(res);
    } catch (e) {
      toast.error(String(e));
    } finally {
      setLoading(false);
    }
  };
  const getRevenueBySourceData = async (data) => {
    try {
      setLoading(true);
      const res = await getRevenueBySource(data);
      setRevenueBySource(res);
    } catch (e) {
      toast.error(String(e));
    } finally {
      setLoading(false);
    }
  };
  const listRecentOrder = async () => {
    try {
      setLoading(true);
      const res = await ordersAPI.list({ limit: 5, sort_created_at: "desc" });
      setRecentOrders(res?.items || []);
    } catch (e) {
      toast.error(String(e));
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = () => {
    getData();
  };

  const getData = () => {
    getOrderSummaryData(payloadParams);
    getBestSellingProductsData(payloadParams);
    getRevenueByLocationData(payloadParams);
    getRevenueBySourceData(payloadParams);
    listRecentOrder();
  };

  useEffect(() => {
    if (payloadParams) {
      getData();
    }
  }, [payloadParams]);
  function formatDate(date) {
    const offset = 7; // Múi giờ GMT+07:00
    const localDate = new Date(date.getTime() + offset * 3600 * 1000);
    const year = localDate.getUTCFullYear();
    const month = ('0' + (localDate.getUTCMonth() + 1)).slice(-2);
    const day = ('0' + localDate.getUTCDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  const handleGetFilterDate = (datesArray) => {
    if (datesArray.length !== 2) {
      throw new Error('Input array must contain exactly 2 elements');
    }

    const firstDate = new Date(datesArray[0]);
    let secondDate = new Date(datesArray[1]);

    if (datesArray[0] === datesArray[1]) {
      secondDate.setDate(secondDate.getDate() + 1);
    }

    const formattedFirstDate = formatDate(firstDate);
    const formattedSecondDate = formatDate(secondDate);
    setFilterTime({
      firstDate: formattedFirstDate,
      secondDate: formattedSecondDate
    });
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Section onHandleGetFilterDate={handleGetFilterDate} setFilterTime={setFilterTime} onChangeParams={handleChangeParams} onRefresh={handleRefresh} loading={loading} />
                <Row>
                  <Widget filterTime={filterTime} setFilterTime={setFilterTime} orderSummary={orderSummary} />
                </Row>
                <Row>
                  <Col xl={8}>
                    <BestSellingProducts bestSellingProducts={bestSellingProducts} loading={loading} />
                  </Col>
                  <Col xl={4}>
                    <SalesByLocations revenueByLocation={revenueByLocation} loading={loading} />
                  </Col>
                </Row>
                <Row>
                  <Col xl={4}>
                    <SalesBySources revenueBySource={revenueBySource} loading={loading} />
                  </Col>
                  <Col xl={8}>
                    <RecentOrders recentOrders={recentOrders} loading={loading} />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(DashboardEcommerce);
