import {
  LIST_RETURN_ORDER,
  ADD_RETURN_ORDER_SUCCESS,
  ADD_RETURN_ORDER_FAIL,
  LIST_RETURN_ORDER_SUCCESS,
  LIST_RETURN_ORDER_FAIL,
  ADD_NEW_RETURN_ORDER,
  GET_RETURN_ORDER,
  GET_RETURN_ORDER_SUCCESS,
  GET_RETURN_ORDER_FAIL,
} from "./actionType";

const INIT_STATE = {
  returnOrders: [],
  returnOrder: {},
  error: null,
  success: false,
  loading: false,
  limit: 0,
  page: 0,
  total: 0,
};

const returnOrders = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_RETURN_ORDER:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };

    case LIST_RETURN_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        returnOrders: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
      };

    case LIST_RETURN_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case GET_RETURN_ORDER:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };

    case GET_RETURN_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        returnOrder: action.payload,
      };

    case GET_RETURN_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case ADD_NEW_RETURN_ORDER:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };

    case ADD_RETURN_ORDER_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        returnOrder: action.payload,
        returnOrders: [...state.returnOrders, action.payload],
      };

    case ADD_RETURN_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default returnOrders;
