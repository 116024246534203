import { Actions } from "../../../../Components/constants/common";
import {
  LIST_LOCATION,
  DELETE_LOCATION,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAIL,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_FAIL,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAIL,
  LIST_LOCATION_SUCCESS,
  LIST_LOCATION_FAIL,
  ADD_NEW_LOCATION,
  UPDATE_LOCATION,
  GET_LOCATION,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_FAIL,
} from "./actionType";

const INIT_STATE = {
  locations: [],
  location: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const Locations = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_LOCATION:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_LOCATION_SUCCESS:
      return {
        ...state,
        message: "Get locations success",
        loading: false,
        success: true,
        locations: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_LOCATION_FAIL:
      return {
        ...state,
        message: "Get locations failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_LOCATION:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_LOCATION_SUCCESS:
      return {
        ...state,
        message: "Get location success",
        loading: false,
        success: true,
        location: action.payload,
        action: Actions.EMPTY,
      };

    case GET_LOCATION_FAIL:
      return {
        ...state,
        message: "Get location failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_LOCATION:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        message: "Delete location success",
        loading: false,
        success: true,
        locations: state.locations.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_LOCATION_FAIL:
      return {
        ...state,
        message: "Delete location failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_NEW_LOCATION:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_LOCATION_SUCCESS:
      return {
        ...state,
        message: "Add location success",
        success: true,
        loading: false,
        locations: [...state.locations, action.payload],
        action: Actions.EMPTY,
      };

    case ADD_LOCATION_FAIL:
      return {
        ...state,
        message: "Add location failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_LOCATION:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.UPDATE,
      };
    case UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        message: "Update location success",
        loading: false,
        success: true,
        locations: state.locations.map((location) =>
          location.id.toString() === action.payload.id.toString() ? { ...location, ...action.payload } : location,
        ),
        action: Actions.EMPTY,
      };

    case UPDATE_LOCATION_FAIL:
      return {
        ...state,
        message: "Update location failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    default:
      return { ...state };
  }
};

export default Locations;
