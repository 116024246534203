import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";


// Redux States
import { LIST_TRANSACTION, ADD_NEW_TRANSACTION, CANCEL_TRANSACTION, GET_TRANSACTION } from "./actionType";

import {
  addTransactionSuccess,
  addTransactionFail,
  cancelTransactionSuccess,
  cancelTransactionFail,
  listTransactionSuccess,
  listTransactionFail,
  getTransactionSuccess,
  getTransactionFail,
} from "./action";

import { transactionsAPI } from "../../../../helpers/resource_helper";
import { cancelTransactionAPI } from "../../../../helpers/service_helper";

function* listTransaction({ payload: { ...rest } }) {
  try {
    const response = yield call(transactionsAPI.list, { ...rest });
    yield put(listTransactionSuccess(response));
  } catch (error) {
    yield put(listTransactionFail(error));
  }
}

function* getTransaction({ payload: id }) {
  try {
    const response = yield call(transactionsAPI.get, { id });
    yield put(getTransactionSuccess(response));
  } catch (error) {
    yield put(getTransactionFail(error));
  }
}

function* onAddNewTransaction({ payload: { transaction, handleAfterSubmit } }) {
  try {
    const response = yield call(transactionsAPI.create, transaction);
    yield put(addTransactionSuccess(response));
    yield call(handleAfterSubmit);
    toast.success("Transaction Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addTransactionFail(error));
    toast.error("Transaction Added Failed", { autoClose: 3000 });
  }
}

function* onCancelTransaction({ payload: transactionId }) {
  try {
    const response = yield call(cancelTransactionAPI, transactionId);
    yield put(cancelTransactionSuccess(transactionId));
    toast.success("Transaction Cancelled Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(cancelTransactionFail(error));
    toast.error("Transaction Cancelled Failed", { autoClose: 3000 });
  }
}

export function* watchListTransaction() {
  yield takeEvery(LIST_TRANSACTION, listTransaction);
}

export function* watchGetTransaction() {
  yield takeEvery(GET_TRANSACTION, getTransaction);
}

export function* watchCancelTransaction() {
  yield takeEvery(CANCEL_TRANSACTION, onCancelTransaction);
}

export function* watchAddNewTransaction() {
  yield takeEvery(ADD_NEW_TRANSACTION, onAddNewTransaction);
}

function* transactionsSaga() {
  yield all([
    fork(watchGetTransaction),
    fork(watchListTransaction),
    fork(watchCancelTransaction),
    fork(watchAddNewTransaction),
  ]);
}

export default transactionsSaga;
