// Actions
export const LIST_BLOG = "LIST_BLOG";
export const LIST_BLOG_SUCCESS = "LIST_BLOG_SUCCESS";
export const LIST_BLOG_FAIL = "LIST_BLOG_FAIL";

export const GET_BLOG = "GET_BLOG";
export const GET_BLOG_SUCCESS = "GET_BLOG_SUCCESS";
export const GET_BLOG_FAIL = "GET_BLOG_FAIL";

/**
 * Delete BLOG
 */
export const DELETE_BLOG = "DELETE_BLOG";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";
export const DELETE_BLOG_FAIL = "DELETE_BLOG_FAIL";

/**
 * Add BLOG
 */
export const ADD_NEW_BLOG = "ADD_NEW_BLOG";
export const ADD_BLOG_SUCCESS = "ADD_BLOG_SUCCESS";
export const ADD_BLOG_FAIL = "ADD_BLOG_FAIL";

/**
 * Edit BLOG
 */
export const UPDATE_BLOG = "UPDATE_BLOG";
export const UPDATE_BLOG_SUCCESS = "UPDATE_BLOG_SUCCESS";
export const UPDATE_BLOG_FAIL = "UPDATE_BLOG_FAIL";

export const RESET_BLOG_STATE = "RESET_BLOG_STATE";