import {
  GET_LOYALTY_APP,
  GET_LOYALTY_APP_FAIL,
  GET_LOYALTY_APP_SUCCESS,
  UPDATE_LOYALTY_APP,
  UPDATE_LOYALTY_APP_FAIL,
  UPDATE_LOYALTY_APP_SUCCESS,
} from "./actionType";

const INIT_STATE = {
  loyaltyApp: {},
  loading: false,
};

const LoyaltyApp = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LOYALTY_APP:
      return {
        ...state,
        loading: true,
      };

    case GET_LOYALTY_APP_SUCCESS:
      return {
        ...state,
        loyaltyApp: action.payload,
        loading: false,
      };

    case GET_LOYALTY_APP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_LOYALTY_APP:
      return {
        ...state,
        message: "",
        error: null,
        loading: true,
      };
    case UPDATE_LOYALTY_APP_SUCCESS:
      return {
        ...state,
        loading: false,
        loyaltyApp: {
          ...action.payload,
        },
      };

    case UPDATE_LOYALTY_APP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default LoyaltyApp;
