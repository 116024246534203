export const PURCHASE_ORDER = {
  _list: "/purchase-orders",
  get: "/purchase-orders:id",
  //   add: "/discounts/new",
  //   update: "/discounts/:id/edit",
};

export const SUPPLIER = {
  _list: "/suppliers",
  //   get: "/purchase-orders:id",
  //   add: "/discounts/new",
  //   update: "/discounts/:id/edit",
};
