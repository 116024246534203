import { useTranslation } from "react-i18next";
import ButtonTheme from "./ButtonTheme";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Fragment, useState } from "react";

const ShortcutGuide = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const onCloseClick = () => {
    setIsOpen(false);
  };

  const orderKeyboards = [
    {
      keyboard: "F1",
      description: "Thanh toán đơn hàng",
    },
    {
      keyboard: "F2",
      description: "Nhập tiền khách đưa",
    },
    {
      keyboard: "F3",
      description: "Tìm kiếm sản phẩm",
    },
    {
      keyboard: "F4",
      description: "Tìm kiếm khách hàng",
    },
    {
      keyboard: "F6",
      description: "Nhập chiết khấu đơn hàng",
    },
    {
      keyboard: "F7",
      description: "Chọn hình thức thanh toán",
    },
    {
      keyboard: "F8",
      description: "Thêm khuyến mãi đơn hàng",
    },
    {
      keyboard: "F9",
      description: "Thêm dịch vụ vào đơn hàng",
    },
    {
      keyboard: "F10",
      description: "Chuyển đổi các hình thức quét mã vạch",
    },
  ];

  const transitionKeyboards = [
    {
      keyboards: ["F11"],
      description: "Hiển thị chế độ toàn màn hình",
    },
    {
      keyboards: ["Alt", "1"],
      description: "In đơn hàng trước",
    },
    {
      keyboards: ["Alt", "x"],
      description: "Đổi phương thức thanh toán",
    },
    {
      keyboards: ["Alt", "2"],
      description: "Chuyển đổi qua lại giữa các đơn hàng",
    },
    {
      keyboards: ["Alt", "3"],
      description: "Đóng đơn hàng đang thao tác",
    },
    {
      keyboards: ["Alt", "4"],
      description: "Xóa toàn bộ sản phẩm trong đơn hàng đang thao tác",
    },
    {
      keyboards: ["Alt", "5"],
      description: "Hỗ trợ chọn lô tự động",
    },
  ];

  return (
    <div className="ms-1 header-item d-sm-flex">
      <Modal fade={true} isOpen={isOpen} toggle={onCloseClick} size="xl" scrollable>
        <ModalHeader toggle={onCloseClick}>{t("Keyboard shortcuts")}</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12} className="h6">
              Thao tác trên đơn hàng
            </Col>
            {orderKeyboards.map((item, index) => (
              <Col key={index} lg={4} className="d-flex gap-3 mb-3">
                <div
                  className="position-absolute border-bottom border-start rounded"
                  style={{ width: "30px", height: "30px", translate: "-2px 2px" }}
                ></div>
                <div
                  className="border rounded hstack justify-content-center"
                  style={{ minWidth: "30px", height: "30px" }}
                >
                  {item.keyboard}
                </div>
                <div>{item.description}</div>
              </Col>
            ))}
          </Row>
          <Row>
            <Col lg={12} className="h6">
              Thao tác chuyển đổi/ in đơn
            </Col>
            {transitionKeyboards.map((item, index) => (
              <Col key={index} lg={4} className="d-flex gap-3 mb-3">
                <div className="d-flex flex-row gap-1" style={{minWidth: "70px"}}>
                  {item.keyboards.map((subItem, subIndex) => (
                    <div key={subIndex}>
                      <div
                        className="position-absolute border-bottom border-start rounded"
                        style={{ width: "30px", height: "30px", translate: "-2px 2px" }}
                      ></div>
                      <div
                        className="border rounded hstack justify-content-center"
                        style={{ minWidth: "30px", height: "30px" }}
                      >
                        {subItem}
                      </div>
                    </div>
                  ))}
                </div>

                <div>{item.description}</div>
              </Col>
            ))}
          </Row>
        </ModalBody>
      </Modal>
      <ButtonTheme
        type="button"
        className="btn border btn-icon w-sm"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <i className="ri-question-line me-1"></i>
        {t("Shortcuts")}
      </ButtonTheme>
    </div>
  );
};
export default ShortcutGuide;
