import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";

import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import { listVariantsByOriginalSku, updatePurchaseOrderAPI } from "../../../../helpers/service_helper";
import { convertValidDate, convertValidTime, deepCopy, formatVNDCurrency } from "../../../../helpers/format_helper";

import withRouter from "../../../../Components/Common/withRouter";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { useProfile } from "../../../../Components/Hooks/useProfile";
import SearchDropdown from "../../../../Components/Common/ProductsSearchDropDown";
import BreadCrumbCustom from "../../../../Components/Common/BreadCrumbCustom";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import Loader from "../../../../Components/Common/Loader";
import AddFeesModal from "../../../../Components/Common/AddFeesModal";
import AddPaymentsModal from "../../../../Components/Common/AddPaymentsModal";
import DiscountDropDown from "../../../../Components/Common/DiscountDropDown";
import InputPaymentField from "../../../../Components/Common/InputPaymentField";
import SupplierInfo from "../../../../Components/Common/SupplierInfo";
import BadgeCustom from "../../../../Components/Common/BadgeCustom";
import OrderLineItemsTable from "../../../../Components/Common/OrderLineItemsTable";
import cart from "../../../../assets/images/svg/cart.svg";
import {
  Actions,
  PaymentStatuses,
  PurchaseOrderActions,
  PurchaseOrderStatuses,
} from "../../../../Components/constants/common";

import {
  addNewPurchaseOrder,
  getPurchaseOrder,
  listLocation,
  listPaymentMethod,
  resetPurchaseOrderFlag,
  updatePurchaseOrder,
} from "../../../../store/actions";
import InputTheme from "../../../../Components/Common/InputTheme";
import SelectTheme from "../../../../Components/Common/SelectTheme";

const PurchaseOrdersDetailsPage = (props) => {
  const {
    router: { location, navigate, params, t },
  } = props;
  document.title = `${t("Purchase Order Details")} | OptiWarehouse`;

  const { id } = params;
  const [searchParams] = useSearchParams();
  const clonePurchaseOrderId = searchParams.get("clone_purchase_order_id");
  const isEdit = location.pathname.split("/")?.[3] === "edit" || location.pathname.split("/")?.[2] === "new";
  const dispatch = useDispatch();
  const { userProfile } = useProfile();
  const refToggleDiscount = useRef(null);

  const {
    purchaseOrder,
    purchaseOrdersLoading,
    purchaseOrdersAction,
    purchaseOrdersSuccess,
    purchaseOrdersError,
    purchaseOrdersMessage,
  } = useMemoizedSelector((state) => ({
    purchaseOrder: state.PurchaseOrders.purchase_order,
    purchaseOrdersMessage: state.PurchaseOrders.message,
    purchaseOrdersAction: state.PurchaseOrders.action,
    purchaseOrdersLoading: state.PurchaseOrders.loading,
    purchaseOrdersSuccess: state.PurchaseOrders.success,
    purchaseOrdersError: state.PurchaseOrders.error,
  }));
  const { locations, locationsLoading, locationsSuccess, locationsError } = useMemoizedSelector((state) => ({
    locations: state.Locations.locations,
    locationsLoading: state.Locations.loading,
    locationsSuccess: state.Locations.success,
    locationsError: state.Locations.error,
  }));
  const { paymentMethods, paymentMethodsLoading, paymentMethodsSuccess, paymentMethodsError } = useMemoizedSelector(
    (state) => ({
      paymentMethods: state.PaymentMethods.paymentMethods,
      paymentMethodsLoading: state.PaymentMethods.loading,
      paymentMethodsSuccess: state.PaymentMethods.success,
      paymentMethodsError: state.PaymentMethods.error,
    }),
  );
  const { suppliers, suppliersLoading, supplierMessage, suppliersSuccess, suppliersError } = useMemoizedSelector(
    (state) => ({
      suppliers: state.Suppliers.suppliers,
      suppliersLoading: state.Suppliers.loading,
      suppliersSuccess: state.Suppliers.success,
      suppliersError: state.Suppliers.error,
      supplierMessage: state.Suppliers.message,
    }),
  );
  const [discountType, setDiscountType] = useState("value");
  const [discountPercent, setDiscountPercent] = useState(0);

  const [showDropDownProductMenu, setShowDropDownProductMenu] = useState(false);
  const [feesModal, setFeesModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    locations?.[0] && setSelectedLocation({ id: locations?.[0]?.id, name: locations?.[0]?.name });
  }, [locations]);

  const [updateLoading, setUpdateLoading] = useState("");

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      staff_id: purchaseOrder?.staff_id || userProfile.UserAttributes["sub"],
      supplier: purchaseOrder?.supplier || null,
      location_id: purchaseOrder?.location_id || locations?.[0]?.id || "",
      order_line_items: clonePurchaseOrderId
        ? (purchaseOrder?.order_line_items || []).map((item) => {
            const { id, ...rest } = item;
            return rest;
          })
        : purchaseOrder?.order_line_items || [],
      fees: purchaseOrder?.fees || null,
      payments: !clonePurchaseOrderId ? purchaseOrder?.payments || null : null,
      discount: purchaseOrder?.discount || 0,
      note: purchaseOrder?.note || "",
      tags: purchaseOrder?.tags || "",
      total: purchaseOrder?.total || 0,
      sub_total: purchaseOrder?.sub_total || 0,
    },
    onSubmit: (values) => {
      let payload = deepCopy(values);
      payload.order_line_items = payload.order_line_items
        .filter((item) => item.quantity > 0)
        .map((item) => {
          const { original_sku, available, variants_with_same_original_sku, ...rest } = item;
          return rest;
        });
      if (!payload.location_id) {
        toast.error(t("Please select branch"));
      }
      if (payload.order_line_items.length === 0) {
        toast.error(t("This purchase order has no products."));
      } else {
        const handleAfterSubmit = (data) => {
          navigate(`/purchase-orders/${data}`);
        };
        if (!id) {
          dispatch(addNewPurchaseOrder(payload, handleAfterSubmit));
        } else {
          dispatch(updatePurchaseOrder({ ...purchaseOrder, ...payload }, handleAfterSubmit));
        }
      }
    },
  });

  const handleKeyDown = (e) => {
    const keyPressed = e.key;

    if (keyPressed === "F3") {
      e.preventDefault();
      handleClickAddProductNow();
    }
    if (keyPressed === "F6") {
      e.preventDefault();
      refToggleDiscount.current.toggleDiscount();
    }
    if (keyPressed === "F7") {
      e.preventDefault();
      setFeesModal(true);
      return;
    }
  };

  // price state
  let amountTotal = validation.values?.order_line_items
    ? validation.values.order_line_items.reduce((total, item) => total + item.quantity * item.sale_price, 0)
    : 0;
  let amountFeeTotal = validation.values?.fees
    ? validation.values.fees.reduce((total, item) => total + +item.amount, 0)
    : 0;
  let amountToPay = amountTotal + amountFeeTotal - (validation.values?.discount || 0);
  let amountPayments = validation.values?.payments
    ? validation.values.payments.reduce((total, item) => total + +item.amount, 0)
    : 0;
  let amountStillDue = amountToPay - amountPayments;

  const reCalculateOrderDetails = (item) => {
    item.sub_total = (item?.order_line_items ?? []).reduce((total, item) => total + item.sale_price * item.quantity, 0);
    discountType === "percent" && (item.discount = (item.total * discountPercent) / 100);
    const totalFees = (item?.fees ?? []).reduce((total, item) => total + item.amount, 0);
    item.total = item.sub_total + totalFees - item.discount;
    if ((item?.payments ?? []).length === 1) {
      item.payments[0].amount = item.total;
    } else {
      item.payments = null;
    }
  };

  const addProductToCart = async (selectedProduct) => {
    if (selectedProduct) {
      let newData = deepCopy(validation.values);
      const available =
        (selectedProduct?.inventories || []).find(
          (item) => item.location_id === (selectedLocation?.id || selectedLocation?.value),
        )?.available || 0;
      if (selectedProduct?.original_sku) {
        const hasSameOriginSku = validation.values["order_line_items"].find(
          (item) => item?.original_sku === selectedProduct.original_sku && item?.sku !== selectedProduct.sku,
        );
        if (hasSameOriginSku) {
          toast.error(t("This product is already in the cart!"));
          return;
        }
      }
      let variants_with_same_original_sku;
      if (!selectedProduct?.custom && selectedProduct?.original_sku) {
        const variantWithOtherUnits = await listVariantsByOriginalSku(selectedProduct.original_sku);
        if (variantWithOtherUnits) {
          variants_with_same_original_sku = variantWithOtherUnits?.items ?? [];
        }
      }

      let cartItemIndex = newData.order_line_items.findIndex((item) => item.sku === selectedProduct.sku);
      if (cartItemIndex > -1) {
        newData.order_line_items[cartItemIndex].prices = selectedProduct?.prices ?? [];
        newData.order_line_items[cartItemIndex].quantity = Number(newData.order_line_items[cartItemIndex].quantity) + 1;
        reCalculateOrderDetails(newData);
      } else {
        let newItem = {
          unit_price: 0,
          sale_price: 0,
          sku: selectedProduct?.sku ?? "",
          name: selectedProduct?.name ?? "",
          variant_name: selectedProduct?.name ?? "",
          image_url: selectedProduct.images?.[0]?.url ?? "",
          quantity: 1,
          location: { id: selectedLocation?.id, name: selectedLocation?.name },
          original_sku: selectedProduct?.original_sku ?? "",
          variants_with_same_original_sku: variants_with_same_original_sku ?? null,
          available,
        };
        newItem.product_id = selectedProduct.product_id;
        newItem.variant_id = selectedProduct.id;
        newData.order_line_items.unshift(newItem);
        reCalculateOrderDetails(newData);
      }
      validation.setValues(newData);
    }
  };

  const handleChangeQuantity = (number, dataIndex) => {
    let newData = deepCopy(validation.values);
    let orderItemIndex = newData.order_line_items.findIndex((item, index) => index === dataIndex);
    if (orderItemIndex > -1) {
      newData.order_line_items[orderItemIndex].quantity = number;
      reCalculateOrderDetails(newData);
    }
    validation.setValues(newData);
  };
  const removeProductFromCart = (selectedProductIndex) => {
    let newData = deepCopy(validation.values);
    newData.order_line_items = newData.order_line_items.filter((item, index) => index !== selectedProductIndex);
    reCalculateOrderDetails(newData);
    validation.setValues(newData);
  };

  const handleChangeOrderLineItem = (index, data) => {
    let new_order_line_items = deepCopy(validation.values.order_line_items);
    new_order_line_items[index] = data;
    validation.setFieldValue("order_line_items", new_order_line_items);
  };

  const handleChangeOrderLineItemUnit = (index, data) => {
    let newData = deepCopy(validation.values);
    const check = newData.order_line_items.find((item, i) => i !== index && item.sku === data.sku);
    if (check) {
      toast.error("Available product in cart!");
      newData.order_line_items.splice(index, 1);
    } else {
      newData.order_line_items[index] = data;
    }
    reCalculateOrderDetails(newData);
    validation.setValues(newData);
  };

  const handleChangeDiscountPrice = (data) => {
    validation.setFieldValue("discount", data);
  };
  const handleChangeDiscountPercent = (data) => {
    setDiscountPercent(data);
  };
  const handleChangeDiscountType = (data) => {
    setDiscountType(data);
  };

  const toggleOffDropDownProductMenu = () => {
    setShowDropDownProductMenu(false);
  };
  const toggleOnDropDownProductMenu = () => {
    setShowDropDownProductMenu(true);
  };
  const handleClickAddProductNow = () => {
    toggleOnDropDownProductMenu();
    document.getElementById("search-products").focus();
  };

  const handleClickClose = () => {
    if (!purchaseOrdersLoading) {
      navigate(`/purchase-orders/${id}`);
      validation.resetForm();
    }
  };

  const handleClickEdit = () => {
    if (!purchaseOrdersLoading) {
      navigate(`/purchase-orders/${id}/edit`);
    }
  };

  const handleUpdateStatus = async (action, data) => {
    if (!purchaseOrdersLoading) {
      try {
        setUpdateLoading(action);
        const res = await updatePurchaseOrderAPI({ id: purchaseOrder.id, action, data });
        if (res.message) {
          toast.success(res.message);
        } else {
          toast.success(`${action} successfully!`);
        }
        paymentModal && togglePaymentModal();
        dispatch(getPurchaseOrder(purchaseOrder.id));
      } catch (e) {
        console.error(e);
      } finally {
        setUpdateLoading(PurchaseOrderActions.EMPTY);
      }
    }
  };

  const handleSelectSupplier = (data) => {
    if (data) {
      let { company_id, updated_at, created_at, ...supplierData } = data;
      validation.setFieldValue("supplier", supplierData);
    } else {
      validation.setFieldValue("supplier", null);
    }
  };

  const toggleFeesModal = () => {
    setFeesModal(false);
  };

  const togglePaymentModal = () => {
    updateLoading !== PurchaseOrderActions.PAY && setPaymentModal(false);
  };

  const handleChangeFees = (data) => {
    let newData = deepCopy(validation.values);
    newData.fees = data;
    reCalculateOrderDetails(newData);
    validation.setValues(newData);
  };
  const handleDeleteFee = (item) => {
    let newData = deepCopy(validation.values);
    newData.fees = newData.fees.filter((fee) => fee.name !== item.name);
    reCalculateOrderDetails(newData);
    validation.setValues(newData);
  };

  const handleDeletePayment = (index) => {
    let newData = validation.values.payments.filter((payment, i) => index !== i);
    validation.setFieldValue("payments", newData.length > 0 ? newData : null);
  };

  const handleChangePayment = (data, index) => {
    let newData = deepCopy(validation.values.payments);
    newData[index].payment_method_id = data;
    validation.setFieldValue("payments", newData);
  };

  const handleChangePaymentPrice = (data, index) => {
    let newData = deepCopy(validation.values.payments);
    let newAmountStillDue =
      amountToPay -
      newData.reduce((total, item, i) => {
        if (i !== index) {
          return total + +item.amount;
        } else {
          return total;
        }
      }, 0);
    newData[index].amount = newAmountStillDue - data < 0 ? newAmountStillDue : data;
    validation.setFieldValue("payments", newData);
  };

  const handleAddPayment = () => {
    let amountItems = validation.values.order_line_items.reduce(
      (total, item) => total + item.quantity * item.sale_price,
      0,
    );
    let amountFees = validation.values.fees
      ? validation.values.fees.reduce((total, item) => total + +item.amount, 0)
      : 0;
    let newPayments = validation.values.payments ? [...validation.values.payments] : [];

    if (validation.values.payments) {
      let amountPayments = validation.values.payments.reduce((total, item) => total + +item.amount, 0);
      if (amountItems + amountFees - amountPayments - validation.values.discount > 0) {
        newPayments.push({
          payment_method_id: "",
          amount: amountItems + amountFees - amountPayments - validation.values.discount,
        });
      } else {
        newPayments.push({ payment_method_id: "", amount: 0 });
      }
    } else {
      if (amountItems + amountFees - validation.values.discount > 0) {
        newPayments.push({ payment_method_id: "", amount: amountItems + amountFees - validation.values.discount });
      } else {
        newPayments.push({ payment_method_id: "", amount: 0 });
      }
    }
    validation.setFieldValue("payments", newPayments);
  };

  const handleChangeSupplierAddress = (data) => {
    const newData = deepCopy(validation.values.supplier);
    newData.billing_address = data;
    validation.setFieldValue("supplier", newData);
  };

  useEffect(() => {
    dispatch(resetPurchaseOrderFlag());
    dispatch(listLocation({ limit: 20 }));
    dispatch(listPaymentMethod({ limit: 20 }));
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getPurchaseOrder(id));
    } else {
      dispatch(resetPurchaseOrderFlag());
      if (clonePurchaseOrderId) {
        dispatch(getPurchaseOrder(clonePurchaseOrderId));
      }
    }
  }, [clonePurchaseOrderId, id]);

  return (
    <div className="page-content" onKeyDown={handleKeyDown} tabIndex="0">
      <Container fluid>
        {feesModal && (
          <AddFeesModal
            toggle={toggleFeesModal}
            isOpen={feesModal}
            handleChangeFees={handleChangeFees}
            fees={validation.values?.fees || null}
          />
        )}
        {paymentModal && (
          <AddPaymentsModal
            isOpen={paymentModal}
            toggle={togglePaymentModal}
            paymentMethods={paymentMethods}
            validation={validation}
            onAddPayment={handleUpdateStatus}
            payments={validation.values?.payments || []}
            payLoading={updateLoading === PurchaseOrderActions.PAY}
          />
        )}
        <BreadCrumbCustom text="Back to purchase order list" navigateTo="/purchase-orders">
          {!id ? (
            <div className="hstack gap-2">
              <UncontrolledDropdown>
                <ButtonGroup>
                  <ButtonTheme
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      return validation.handleSubmit();
                    }}
                    loading={purchaseOrdersLoading}
                    disabled={purchaseOrdersLoading}
                  >
                    {t("Create")}
                  </ButtonTheme>
                  <DropdownToggle
                    className="btn dropdown-toggle-split"
                    color="primary"
                    split
                    disabled={purchaseOrdersLoading}
                  ></DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>{t("Create & Import")}</DropdownItem>
                  </DropdownMenu>
                </ButtonGroup>
              </UncontrolledDropdown>
            </div>
          ) : (
            <div className="hstack gap-2">
              {!isEdit ? (
                <Fragment>
                  <ButtonTheme
                    type="button"
                    className="btn btn-outline-primary w-md"
                    onClick={() => {
                      navigate(`/purchase-orders/new?clone_purchase_order_id=${id}`);
                    }}
                    disabled={updateLoading !== PurchaseOrderActions.EMPTY || purchaseOrdersLoading}
                  >
                    <i class="ri-file-copy-line me-1"></i>
                    {t("Copy")}
                  </ButtonTheme>
                  {/* {purchaseOrder.status === PurchaseOrderStatuses.COMPLETED && (
                    <ButtonTheme
                      type="button"
                      className="btn btn-outline-danger w-sm"
                      onClick={() => {
                        handleUpdateStatus(PurchaseOrderActions.RETURN, {});
                      }}
                      loading={updateLoading === PurchaseOrderActions.RETURN}
                      disabled={updateLoading !== PurchaseOrderActions.EMPTY || purchaseOrdersLoading}
                    >
                      {t("Return")}
                    </ButtonTheme>
                  )} */}
                  {[PurchaseOrderStatuses.DRAFT, PurchaseOrderStatuses.PENDING].includes(purchaseOrder.status) && (
                    <ButtonTheme
                      type="button"
                      className="btn btn-outline-danger w-sm"
                      onClick={() => {
                        handleUpdateStatus(PurchaseOrderActions.CANCEL, {});
                      }}
                      loading={updateLoading === PurchaseOrderActions.CANCEL}
                      disabled={updateLoading !== PurchaseOrderActions.EMPTY || purchaseOrdersLoading}
                    >
                      {t("Cancel")}
                    </ButtonTheme>
                  )}
                  {purchaseOrder.payment_status !== PaymentStatuses.PAID && (
                    <ButtonTheme
                      type="button"
                      className="btn btn-outline-primary w-sm"
                      onClick={() => {
                        setPaymentModal(true);
                      }}
                      disabled={updateLoading !== PurchaseOrderActions.EMPTY || purchaseOrdersLoading}
                    >
                      {t("Pay")}
                    </ButtonTheme>
                  )}
                  {purchaseOrder.status === PurchaseOrderStatuses.DRAFT && (
                    <ButtonTheme
                      type="button"
                      className="btn btn-outline-primary w-sm"
                      onClick={() => {
                        handleUpdateStatus(PurchaseOrderActions.CONFIRM, {});
                      }}
                      loading={updateLoading === PurchaseOrderActions.CONFIRM}
                      disabled={updateLoading !== PurchaseOrderActions.EMPTY || purchaseOrdersLoading}
                    >
                      {t("Confirm")}
                    </ButtonTheme>
                  )}
                  {purchaseOrder.status === PurchaseOrderStatuses.PENDING && (
                    <ButtonTheme
                      type="button"
                      className="btn btn-outline-primary w-sm"
                      onClick={() => {
                        handleUpdateStatus(PurchaseOrderActions.COMPLETE, {});
                      }}
                      loading={updateLoading === PurchaseOrderActions.COMPLETE}
                      disabled={updateLoading !== PurchaseOrderActions.EMPTY || purchaseOrdersLoading}
                    >
                      {t("Import")}
                    </ButtonTheme>
                  )}
                  {purchaseOrder.status === PurchaseOrderStatuses.DRAFT &&
                    purchaseOrder.payment_status === PaymentStatuses.UNPAID && (
                      <UncontrolledDropdown>
                        <div className="btn-group">
                          <ButtonTheme
                            type="button"
                            className="btn btn-primary"
                            onClick={handleClickEdit}
                            disabled={updateLoading !== PurchaseOrderActions.EMPTY || purchaseOrdersLoading}
                          >
                            {t("Edit")}
                          </ButtonTheme>
                          <DropdownToggle
                            className="btn dropdown-toggle-split"
                            color="primary"
                            split
                            disabled={updateLoading !== PurchaseOrderActions.EMPTY || purchaseOrdersLoading}
                          ></DropdownToggle>
                          <DropdownMenu>
                            {!validation.values?.payments ? (
                              <DropdownItem
                                onClick={() => {
                                  setPaymentModal(true);
                                }}
                              >
                                {t("Add payment")}
                              </DropdownItem>
                            ) : (
                              <DropdownItem
                                onClick={() => {
                                  handleUpdateStatus(PurchaseOrderActions.COMPLETE, {});
                                }}
                              >
                                {t("Complete")}
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </div>
                      </UncontrolledDropdown>
                    )}
                </Fragment>
              ) : (
                <Fragment>
                  <ButtonTheme
                    type="button"
                    className="btn btn-outline-primary w-sm"
                    onClick={() => {
                      return validation.handleSubmit();
                    }}
                    loading={purchaseOrdersAction === Actions.UPDATE && purchaseOrdersLoading}
                    disabled={purchaseOrdersAction === Actions.UPDATE && purchaseOrdersLoading}
                  >
                    {t("Update")}
                  </ButtonTheme>
                  <ButtonTheme type="button" className="btn btn-light w-sm" onClick={handleClickClose} text={"Close"}>
                    {t("Close")}
                  </ButtonTheme>
                </Fragment>
              )}
            </div>
          )}
        </BreadCrumbCustom>
        {purchaseOrdersLoading && purchaseOrdersAction === Actions.GET ? (
          <Loader />
        ) : (
          <Fragment>
            <Row>
              <Col lg={9}>
                <SupplierInfo
                  isEdit={isEdit}
                  selectedSupplier={validation.values.supplier}
                  supplierList={suppliers}
                  onSelectSupplier={handleSelectSupplier}
                  addresses={validation.values.supplier?.addresses || []}
                  onChangeSupplierAddress={handleChangeSupplierAddress}
                />
              </Col>
              {!id ? (
                <Col lg={3}>
                  <Card>
                    <CardHeader>
                      <h5 className="card-title mb-0">{t("Additional info")}</h5>
                    </CardHeader>
                    <CardBody>
                      <div className="mb-3">
                        <SelectTheme
                          isForm={false}
                          isObjectValueSelect={true}
                          selectDataOrigin={true}
                          value={selectedLocation}
                          onChange={(data) => {
                            setSelectedLocation(data);
                            validation.setFieldValue("location_id", data?.id);
                            const new_order_line_items = validation.values.order_line_items.map((item) => {
                              item.location = { id: data?.id, name: data?.name };
                              return item;
                            });
                            validation.setFieldValue("order_line_items", new_order_line_items);
                          }}
                          label={"Branch"}
                          placeholder={"Select A Branch"}
                          remote={true}
                          path="inventory/locations"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ) : (
                <Col className="col-3">
                  <Card>
                    <CardHeader>
                      <div className="d-sm-flex align-items-center">
                        <h5 className="card-title flex-grow-1 mb-0">{t("Status")}</h5>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <div className="profile-timeline">
                        {purchaseOrder.histories ? (
                          <div className="accordion accordion-flush" id="accordionFlushExample">
                            {purchaseOrder.histories.map((item, index) => (
                              <div key={index} className="accordion-item border-0">
                                <div className="accordion-header" id="headingOne">
                                  <Link to="#" className={("accordion-button", "p-2", "shadow-none")}>
                                    <div className="d-flex align-items-center">
                                      <div className="flex-shrink-0 avatar-xs">
                                        <div className="avatar-title bg-success rounded-circle">
                                          <i className="mdi mdi-clock-time-eight-outline"></i>
                                        </div>
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-15 mb-0 fw-semibold">
                                          {item?.status} -{" "}
                                          <span className="fw-normal">
                                            {convertValidDate(item?.updated_at)},
                                            <small className="text-muted"> {convertValidTime(item?.updated_at)}</small>
                                          </span>
                                        </h6>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <span>{t("This purchase order doesn't have any statuses!")}</span>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    {!id ? (
                      <h5>{t("Product details")}</h5>
                    ) : (
                      <div className="d-flex align-items-center">
                        <h5 className="card-title flex-grow-1 mb-0">
                          {t("Purchase Order")} #{purchaseOrder?.purchase_order_number}
                        </h5>
                        <h5 className="flex-shrink-0 mb-0">
                          <BadgeCustom status={purchaseOrder.status} />
                        </h5>
                      </div>
                    )}
                  </CardHeader>
                  <CardBody>
                    {isEdit && (
                      <SearchDropdown
                        onClickItem={addProductToCart}
                        isOpen={showDropDownProductMenu}
                        toggleOff={toggleOffDropDownProductMenu}
                        toggleOn={toggleOnDropDownProductMenu}
                      />
                    )}
                    <div className="my-3 w-100">
                      <CardBody>
                        <OrderLineItemsTable
                          isEdit={isEdit}
                          products={validation.values.order_line_items || []}
                          divClass="table-card table-responsive"
                          tableClass="table table-nowrap align-middle mb-0"
                          theadClass="table-light text-muted"
                          trClass=""
                          removeProductFromCart={removeProductFromCart}
                          onChangeQuantity={handleChangeQuantity}
                          onChangeOrderLineItem={handleChangeOrderLineItem}
                          onChangeOrderLineItemUnit={handleChangeOrderLineItemUnit}
                          noDataElement={
                            <div className="d-flex justify-content-center align-items-center py-5">
                              <div className="text-center">
                                <img src={cart} className="img-fluid" alt="" />
                                <h6 className="fs-3 mb-3 text-muted">{t("Order has no products")}</h6>
                                <ButtonTheme
                                  id="add-product-now-button"
                                  type="button"
                                  className="btn btn-light waves-effect"
                                  onClick={handleClickAddProductNow}
                                >
                                  {t("Add product")}
                                </ButtonTheme>
                              </div>
                            </div>
                          }
                        />
                        <Row className="border-top border-top-dashed mt-3">
                          <Col lg={6}>
                            <div className="my-3">
                              <InputTheme
                                isForm={false}
                                value={validation.values.note}
                                onChange={(data) => {
                                  validation.setFieldValue("note", data);
                                }}
                                label="Note"
                                disabled={!isEdit}
                              />
                            </div>
                            <div className="mb-3">
                              <InputTheme
                                isForm={false}
                                value={validation.values.tags}
                                onChange={(data) => {
                                  validation.setFieldValue("tags", data);
                                }}
                                type="tags"
                                label="Tags"
                                disabled={!isEdit}
                                placeholder={!isEdit ? "" : t("Enter tags")}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <table className="table table-borderless mb-0">
                              <tbody>
                                <tr>
                                  <td>{t("Quantity")}</td>
                                  <td className="text-end">
                                    {validation.values.order_line_items.reduce(
                                      (total, item) => total + +item.quantity,
                                      0,
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t("Total")}</td>
                                  <td className="text-end">{formatVNDCurrency(amountTotal)}</td>
                                </tr>
                                <tr>
                                  <td>
                                    {isEdit ? (
                                      <DiscountDropDown
                                        refToggleDiscount={refToggleDiscount}
                                        values={validation.values}
                                        discountType={discountType}
                                        discountPercent={discountPercent}
                                        discountPrice={validation.values.discount}
                                        onChangeDiscountType={handleChangeDiscountType}
                                        onChangeDiscountPercent={handleChangeDiscountPercent}
                                        onChangeDiscountPrice={handleChangeDiscountPrice}
                                      />
                                    ) : (
                                      t("Discount")
                                    )}
                                  </td>
                                  <td className="text-end">{formatVNDCurrency(validation.values.discount)}</td>
                                </tr>
                                <tr>
                                  <td>{t("Import fees")}</td>
                                  <td className="text-end">{formatVNDCurrency(amountFeeTotal)}</td>
                                </tr>
                                {validation.values.fees &&
                                  validation.values.fees.map((item, key) => (
                                    <tr key={key}>
                                      <td className="hstack gap-3">
                                        {isEdit && (
                                          <i
                                            className="align-bottom text-danger ri-close-line cursor-pointer me-2 fs-16"
                                            onClick={() => {
                                              handleDeleteFee(item);
                                            }}
                                          ></i>
                                        )}
                                        <span className={`text-muted ${isEdit ? "" : "ms-3"}`}>{item.name}</span>
                                      </td>
                                      <td className="text-end text-muted">{formatVNDCurrency(item.amount)}</td>
                                    </tr>
                                  ))}
                                {isEdit && (
                                  <tr>
                                    <td>
                                      <ButtonTheme
                                        className="btn btn-ghost-primary p-0 border-0"
                                        onClick={() => {
                                          setFeesModal(true);
                                        }}
                                      >
                                        <i className="ri-add-circle-line align-bottom me-2"></i>
                                        {t("Add fee")} (F7)
                                      </ButtonTheme>
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td>
                                    {t("Tax")}
                                    <i className="ms-2 ri-information-line text-primary align-bottom"></i>
                                  </td>
                                  <td className="text-end">{formatVNDCurrency(validation.values?.tax ?? 0)}</td>
                                </tr>
                                <tr>
                                  <td>{t("Amount to pay")}</td>
                                  <td className="text-end">{formatVNDCurrency(amountToPay)}</td>
                                </tr>
                                <tr className="border-top border-top-dashed">
                                  <td>{t("Payment to the supplier")}</td>
                                </tr>
                                {validation.values.payments &&
                                  validation.values.payments.map((item, key) => {
                                    return (
                                      <tr key={key}>
                                        {!item?.id ? (
                                          <td className="hstack">
                                            <i
                                              className="align-bottom text-danger ri-close-line cursor-pointer me-2 fs-16"
                                              onClick={() => {
                                                handleDeletePayment(key);
                                              }}
                                            ></i>
                                            <Input
                                              type="select"
                                              className="form-select"
                                              onChange={(e) => {
                                                handleChangePayment(e.target.value, key);
                                              }}
                                              value={item?.payment_method?.id}
                                              disabled={!isEdit}
                                            >
                                              <option value={""}>{t("Please select a payment")}</option>
                                              {paymentMethods.map((paymentMethodItem, key) => (
                                                <option key={key} value={paymentMethodItem.id}>
                                                  {t(paymentMethodItem.name || "---")}
                                                </option>
                                              ))}
                                            </Input>
                                          </td>
                                        ) : (
                                          <td>{t(item.payment_method.name || "---")}</td>
                                        )}
                                        {!item?.id ? (
                                          <td>
                                            <InputPaymentField
                                              index={key}
                                              price={item.amount}
                                              onChangePaymentPrice={handleChangePaymentPrice}
                                              isEdit={isEdit}
                                            />
                                          </td>
                                        ) : (
                                          <td className="text-end">{formatVNDCurrency(item.amount)}</td>
                                        )}
                                      </tr>
                                    );
                                  })}
                                {isEdit && (
                                  <tr>
                                    <td>
                                      <ButtonTheme
                                        className="btn btn-ghost-primary p-0 border-0"
                                        onClick={handleAddPayment}
                                      >
                                        <i className="ri-add-circle-line align-bottom me-2"></i>
                                        {t("Add payment")}
                                      </ButtonTheme>
                                    </td>
                                  </tr>
                                )}
                                <tr className="border-top border-top-dashed">
                                  <th scope="row">{t("Amount still due")}</th>
                                  <th className="text-end">{formatVNDCurrency(amountStillDue)}</th>
                                </tr>
                              </tbody>
                            </table>
                          </Col>
                        </Row>
                      </CardBody>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Fragment>
        )}
      </Container>
    </div>
  );
};

export default withRouter(PurchaseOrdersDetailsPage);
