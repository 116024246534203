import { useState, useEffect } from "react";

import Dropzone, { useDropzone } from "react-dropzone";
import { isEmpty } from "lodash";
import { Card, CardBody, Form, Input, Label, Row } from "reactstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { deepCopy, formatBytes } from "../../../../../helpers/format_helper";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ModalSelectionImage from "./ModalSelectionImage";

const OptionList = ({
  options,
  optionData,
  optionIndex,
  variantImages,
  onChangeOptions,
  onDeleteOption,
  onChangeVariantImages,
  productImages,
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState(optionData?.name ?? "");
  const [values, setValues] = useState(optionData?.values ?? []);

  const handleDeleteValue = (data) => {
    let newVariantImages = variantImages.filter((item) => item?.key !== data);
    onChangeVariantImages(newVariantImages);
    const newValues = values.filter((item) => item !== data);
    setValues(newValues);
    onChangeOptions(optionIndex, { name, values: newValues });
  };

  const handleAddValue = (data) => {
    if (values.includes(data)) {
      toast.error(t("Available value"));
    } else {
      const newValues = [...values, data];
      setValues(newValues);
      onChangeOptions(optionIndex, { name, values: newValues });
    }
  };

  const handleChangeVariantImages = (data) => {
    let indexVariantImages = variantImages.findIndex((item) => item.key === data.key);
    if (indexVariantImages > -1) {
      onChangeVariantImages(variantImages.map((item, index) => (index === indexVariantImages ? data : item)));
    } else {
      onChangeVariantImages([...variantImages, data]);
    }
  };
  const handleDeleteOption = () => {
    onDeleteOption(optionIndex);
    optionIndex === 0 && onChangeVariantImages([]);
  };

  const handleChangeValue = (data, i) => {
    if (typeof i === "number") {
      let newOption = [...values];
      let newVariantImages = variantImages.map((item) => {
        if (item.key === newOption[i]) {
          item.key = data;
          return item;
        } else {
          return item;
        }
      });
      onChangeVariantImages(newVariantImages);
      newOption[i] = data;
      setValues(newOption);
      onChangeOptions(optionIndex, { name, values: newOption });
    }
  };

  const handleChangeOptionName = () => {
    let check = options.find((item, index) => index !== optionIndex && item.name === name);
    if (check) {
      toast.error(t("Option name available"));
      setName("");
    } else {
      onChangeOptions(optionIndex, { name, values });
    }
  };

  useEffect(() => {
    setName(optionData?.name ?? "");
    setValues(optionData?.values ?? []);
  }, [optionData]);

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = deepCopy(values);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setValues(items);
    onChangeOptions(optionIndex, { name, values: items });
  }

  return (
    <Card className="bg-light">
      <CardBody>
        <div className="d-flex mb-1">
          <h5 className="fs-14 flex-grow-1">
            {t("Option")} - {optionIndex + 1}
          </h5>
          <i className="ri-delete-bin-line fs-24 cursor-pointer flex-shrink-0" onClick={handleDeleteOption}></i>
        </div>
        <div className="mb-3">
          <Label htmlFor={`variant-name-input-${optionIndex}`}>{t("Option Name")}</Label>
          <Input
            type="text"
            className="form-control"
            id={`variant-name-input-${optionIndex}`}
            placeholder={t("Enter option name")}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            onBlur={handleChangeOptionName}
          />
        </div>
        <Label for="variant-values-input">{t("Option Values")}</Label>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="option-values-character">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {values.map((item, i) => (
                  <Draggable key={item} draggableId={item} index={i}>
                    {(provided) => {
                      let dragHandleProps = { ...provided.dragHandleProps };
                      return (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          <OptionValue
                            option={item}
                            values={values}
                            optionValueIndex={i}
                            variantImages={variantImages}
                            showInputImages={optionIndex === 0}
                            onChangeValue={handleChangeValue}
                            onDeleteValue={handleDeleteValue}
                            dragHandleProps={dragHandleProps}
                            onChangeVariantImages={handleChangeVariantImages}
                            productImages={productImages}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <OptionValue
          productImages={productImages}
          onAddValue={handleAddValue}
          isList={false}
          values={values}
          showInputImages={optionIndex === 0}
        />
      </CardBody>
    </Card>
  );
};

const OptionValue = ({
  isList = true,
  values,
  optionValueIndex,
  showInputImages,
  option,
  onChangeValue = () => {},
  onChangeVariantImages = () => {},
  variantImages = [],
  onAddValue,
  onDeleteValue,
  dragHandleProps = {},
  productImages,
}) => {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [modal, setModal] = useState(false);
  const [value, setValue] = useState(isList ? option : "");

  const toggleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    if (isList) {
      setValue(option);
      let check = variantImages.find((item) => item.key === option);
      if (check) {
        setSelectedFiles(check.data);
      } else {
        setSelectedFiles([]);
      }
    } else {
      setValue("");
    }
  }, [option]);

  const handleChangeValue = (e) => {
    if (!isEmpty(value)) {
      if (values.filter((item) => item !== option).includes(value)) {
        toast.error(t("Available value"));
        setValue(isList ? option : "");
      } else {
        isList ? onChangeValue(value, optionValueIndex) : onChangeValue(value, "");
      }
    } else {
      toast.error(t("Required value"));
      setValue(isList ? option : "");
    }
  };

  function handleDeleteFile(index) {
    setSelectedFiles(selectedFiles.filter((item, i) => i !== index));
  }

  useEffect(() => {
    if (isList && !isEmpty(value)) {
      let newData = {
        key: value,
        data: selectedFiles,
      };
      onChangeVariantImages(newData);
    }
  }, [selectedFiles]);

  const handleDeleteValue = () => {
    onDeleteValue(value);
  };

  const handleAddValue = () => {
    onAddValue(value);
    setSelectedFiles([]);
    setValue("");
  };

  return (
    <Card className="mb-3">
      <CardBody className={isList ? "" : "border border-dashed border-2 rounded"}>
        <div className="align-items-center d-flex text-center">
          <div className="flex-shrink-0 me-3">
            <Input
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  !isList && !isEmpty(value) && handleAddValue() && e.preventDefault();
                }
              }}
              // invalid={invalid}
              placeholder={t("Enter value")}
              onBlur={(e) => isList && handleChangeValue(e)}
            />
            {/* {invalid ? <FormFeedback type="invalid">Duplicated option name</FormFeedback> : null} */}
          </div>
          <div className="flex-grow-1 me-3">
            {showInputImages && isList ? (
              <Row className="align-items-center">
                {selectedFiles.map((f, i) => {
                  return (
                    <div key={i + "-file"} className="col-auto p-0 m-2">
                      <img
                        loading="lazy"
                        className="avatar-sm rounded bg-light "
                        alt={f.name}
                        src={f.url || f.preview || f.image}
                        style={{ objectFit: "contain", height: "50px", width: "50px" }}
                      />
                      <i
                        className="position-absolute top-0 start-100 translate-middle ri-close-circle-fill text-danger fs-19 cursor-pointer"
                        onClick={() => handleDeleteFile(i)}
                      ></i>
                    </div>
                  );
                })}
                <div
                  className="col-auto avatar-sm p-0 m-2 border border-dashed border-2 cursor-pointer rounded-3"
                  onClick={toggleModal}
                >
                  <i className="display-6 m-auto text-muted ri-upload-cloud-2-fill" />
                </div>
                <ModalSelectionImage
                  modal={modal}
                  toggleModal={toggleModal}
                  onSaveImages={(data) => {
                    setSelectedFiles(data);
                  }}
                  variantImages={selectedFiles}
                  productImages={productImages}
                />
              </Row>
            ) : (
              selectedFiles.map((f, i) => {
                return (
                  <div key={i + "-file"} className="col-auto p-0 m-2">
                    <img
                      loading="lazy"
                      className="avatar-sm rounded bg-light "
                      alt={f.name}
                      src={f?.url || f?.preview || f?.image}
                    />
                  </div>
                );
              })
            )}
          </div>
          {isList && (
            <div className="flex-shrink-0 ms-3">
              <i className="ri-delete-bin-line fs-24 cursor-pointer" onClick={handleDeleteValue}></i>
            </div>
          )}
          {isList && (
            <div className="flex-shrink-0 ms-3" {...dragHandleProps}>
              <i className="ri-menu-fill fs-24"></i>
            </div>
          )}
          {!isList && !isEmpty(value) && (
            <div className="flex-shrink-0 ms-3">
              <i className="ri-check-fill fs-24 cursor-pointer" onClick={handleAddValue}></i>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default OptionList;
