import { Fragment } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { formatVNDCurrency } from "../../../../helpers/format_helper";
import FullNameRender from "../../../../Components/Common/FullnameRender";

const CustomerInfoModal = ({ toggle, isOpen, customer }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={toggle} centered size="md">
        <ModalHeader toggle={toggle}>{t("Customer Info")}</ModalHeader>
        <ModalBody>
          <div className="mb-3 fw-semibold">{t("General")}</div>
          <Row className="ms-3 mb-3">
            <div className="col-6">{t("Name")}</div>
            <div className="col-6">
              <FullNameRender first_name={customer?.first_name} last_name={customer?.last_name} />
            </div>
            <div className="col-6">{t("Customer Group")}</div>
            <div className="col-6">{customer?.customer_group?.name || "---"}</div>
            <div className="col-6">{t("Email")}</div>
            <div className="col-6">{customer?.email || "---"}</div>
            <div className="col-6">{t("Phone")}</div>
            <div className="col-6">{customer?.phone || "---"}</div>
            <div className="col-6">{t("Debt")}</div>
            <div className="col-6">{formatVNDCurrency(customer?.debt || 0)}</div>
          </Row>
          <div className="mb-3 fw-semibold">{t("Loyalty points")}</div>
          <Row className="ms-3 mb-3">
            <div className="col-6">{t("Current point")}</div>
            <div className="col-6">{customer?.loyal_customer?.point || 0}</div>
            <div className="col-6">{t("Total spent")}</div>
            <div className="col-6">{formatVNDCurrency(customer?.sale_order?.total_spent || 0)}</div>
            {/* <div className="col-6">{t("Grade")}</div>
            <div className="col-6">{customer?.grade || "---"}</div> */}
            {/* <div className="col-6">{t("Remaining revenue for upgrade")}</div>
            <div className="col-6">{customer?.upgrade_point || "---"}</div> */}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-light w-sm" onClick={toggle}>
            {t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default CustomerInfoModal;
