import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";

// Redux States
import {
  LIST_STOCK_RELOCATE,
  ADD_NEW_STOCK_RELOCATE,
  UPDATE_STOCK_RELOCATE,
  GET_STOCK_RELOCATE,
  RECEIVE_STOCK_RELOCATE,
  CANCEL_STOCK_RELOCATE,
  DELIVERY_STOCK_RELOCATE,
} from "./actionType";

import {
  addStockRelocateSuccess,
  addStockRelocateFail,
  updateStockRelocateSuccess,
  updateStockRelocateFail,
  listStockRelocateSuccess,
  listStockRelocateFail,
  getStockRelocateSuccess,
  getStockRelocateFail,
  receiveStockRelocateSuccess,
  receiveStockRelocateFail,
  cancelStockRelocateSuccess,
  cancelStockRelocateFail,
  deliveryStockRelocateSuccess,
  deliveryStockRelocateFail,
} from "./action";

import { stockRelocatesAPI } from "../../../../helpers/resource_helper";

function* listStockRelocate({ payload: { ...res } }) {
  try {
    const response = yield call(stockRelocatesAPI.list, { ...res });
    yield put(listStockRelocateSuccess(response));
  } catch (error) {
    yield put(listStockRelocateFail(error));
  }
}

function* getStockRelocate({ payload: id }) {
  try {
    const response = yield call(stockRelocatesAPI.get, { id });
    yield put(getStockRelocateSuccess(response));
  } catch (error) {
    yield put(getStockRelocateFail(error));
  }
}

function* cancelStockRelocates({ payload: { stockRelocate, handleAfterSubmit } }) {
  try {
    const response = yield call(stockRelocatesAPI.customCreate, `${stockRelocate.id}/cancel`, {});
    yield put(cancelStockRelocateSuccess(response));
    toast.success(response?.message, { autoClose: 3000 });
    yield call(handleAfterSubmit, response);
  } catch (error) {
    yield put(cancelStockRelocateFail(error));
    toast.error("Stock Relocate Cancel Failed", { autoClose: 3000 });
  }
}

function* receiveStockRelocates({ payload: { stockRelocate, handleAfterSubmit } }) {
  try {
    const response = yield call(stockRelocatesAPI.customCreate, `${stockRelocate.id}/receive`, {});
    yield put(receiveStockRelocateSuccess(response));
    toast.success(response?.message, { autoClose: 3000 });
    yield call(handleAfterSubmit, response);
  } catch (error) {
    yield put(receiveStockRelocateFail(error));
    toast.error("Stock Relocate Receive Failed", { autoClose: 3000 });
  }
}

function* deliveryStockRelocates({ payload: { stockRelocate, handleAfterSubmit } }) {
  try {
    const response = yield call(stockRelocatesAPI.customCreate, `${stockRelocate.id}/delivery`, {});
    yield put(deliveryStockRelocateSuccess(response));
    toast.success(response?.message, { autoClose: 3000 });
    yield call(handleAfterSubmit, response);
  } catch (error) {
    yield put(deliveryStockRelocateFail(error));
    toast.error("Stock Relocate Delivery Failed", { autoClose: 3000 });
  }
}

function* onAddNewStockRelocate({ payload: { stockRelocate, handleAfterSubmit } }) {
  try {
    const response = yield call(stockRelocatesAPI.create, stockRelocate);
    yield put(addStockRelocateSuccess(response));
    toast.success("Stock Relocate Added Successfully", { autoClose: 3000 });
    yield call(handleAfterSubmit, response);
  } catch (error) {
    yield put(addStockRelocateFail(error));
    toast.error("Stock Relocate Added Failed", { autoClose: 3000 });
  }
}

function* onUpdateStockRelocate({ payload: stockRelocate }) {
  try {
    const response = yield call(stockRelocatesAPI.put, stockRelocate);
    yield put(updateStockRelocateSuccess(response));
    toast.success("Stock Relocate Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateStockRelocateFail(error));
    toast.error("Stock Relocate Updated Failed", { autoClose: 3000 });
  }
}

export function* watchGetStockRelocates() {
  yield takeEvery(LIST_STOCK_RELOCATE, listStockRelocate);
}

export function* watchCancelStockRelocates() {
  yield takeEvery(CANCEL_STOCK_RELOCATE, cancelStockRelocates);
}

export function* watchDeliveryStockRelocates() {
  yield takeEvery(DELIVERY_STOCK_RELOCATE, deliveryStockRelocates);
}

export function* watchReceiveStockRelocates() {
  yield takeEvery(RECEIVE_STOCK_RELOCATE, receiveStockRelocates);
}

export function* watchGetStockRelocate() {
  yield takeEvery(GET_STOCK_RELOCATE, getStockRelocate);
}

export function* watchUpdateStockRelocate() {
  yield takeEvery(UPDATE_STOCK_RELOCATE, onUpdateStockRelocate);
}

export function* watchAddNewStockRelocate() {
  yield takeEvery(ADD_NEW_STOCK_RELOCATE, onAddNewStockRelocate);
}

function* stockRelocatesSaga() {
  yield all([
    fork(watchGetStockRelocate),
    fork(watchGetStockRelocates),
    fork(watchUpdateStockRelocate),
    fork(watchAddNewStockRelocate),
    fork(watchCancelStockRelocates),
    fork(watchReceiveStockRelocates),
    fork(watchDeliveryStockRelocates),
  ]);
}

export default stockRelocatesSaga;
