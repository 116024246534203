import {
  UPDATE_DRAFT_ORDER,
  UPDATE_DRAFT_ORDER_SUCCESS,
  UPDATE_DRAFT_ORDER_FAIL,
  GET_DRAFT_ORDER_SUCCESS,
  GET_DRAFT_ORDER,
  GET_DRAFT_ORDER_FAIL,
} from "./actionType";

export const updateDraftOrder = (draftOrder, handleAfterSubmit = () => {}) => ({
  type: UPDATE_DRAFT_ORDER,
  payload: { draftOrder, handleAfterSubmit },
});

export const updateDraftOrderSuccess = (draftOrder) => ({
  type: UPDATE_DRAFT_ORDER_SUCCESS,
  payload: draftOrder,
});

export const updateDraftOrderFail = (error) => ({
  type: UPDATE_DRAFT_ORDER_FAIL,
  payload: error,
});

export const getDraftOrder = (handleAfterSubmit = () => {}) => ({
  type: GET_DRAFT_ORDER,
  payload: { handleAfterSubmit },
});

export const getDraftOrderFail = (error) => ({
  type: GET_DRAFT_ORDER_FAIL,
  payload: error,
});

export const getDraftOrderSuccess = (draftOrder) => ({
  type: GET_DRAFT_ORDER_SUCCESS,
  payload: draftOrder,
});
