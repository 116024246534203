export const LIST_STOCK_RELOCATE = "LIST_STOCK_RELOCATE";
export const LIST_STOCK_RELOCATE_SUCCESS = "LIST_STOCK_RELOCATE_SUCCESS";
export const LIST_STOCK_RELOCATE_FAIL = "LIST_STOCK_RELOCATE_FAIL";

export const GET_STOCK_RELOCATE = "GET_STOCK_RELOCATE";
export const GET_STOCK_RELOCATE_SUCCESS = "GET_STOCK_RELOCATE_SUCCESS";
export const GET_STOCK_RELOCATE_FAIL = "GET_STOCK_RELOCATE_FAIL";

export const ADD_NEW_STOCK_RELOCATE = "ADD_NEW_STOCK_RELOCATE";
export const ADD_STOCK_RELOCATE_SUCCESS = "ADD_STOCK_RELOCATE_SUCCESS";
export const ADD_STOCK_RELOCATE_FAIL = "ADD_STOCK_RELOCATE_FAIL";

export const UPDATE_STOCK_RELOCATE = "UPDATE_STOCK_RELOCATE";
export const UPDATE_STOCK_RELOCATE_SUCCESS = "UPDATE_STOCK_RELOCATE_SUCCESS";
export const UPDATE_STOCK_RELOCATE_FAIL = "UPDATE_STOCK_RELOCATE_FAIL";

export const DELIVERY_STOCK_RELOCATE = "DELIVERY_STOCK_RELOCATE";
export const DELIVERY_STOCK_RELOCATE_SUCCESS = "DELIVERY_STOCK_RELOCATE_SUCCESS";
export const DELIVERY_STOCK_RELOCATE_FAIL = "DELIVERY_STOCK_RELOCATE_FAIL";

export const RECEIVE_STOCK_RELOCATE = "RECEIVE_STOCK_RELOCATE";
export const RECEIVE_STOCK_RELOCATE_SUCCESS = "RECEIVE_STOCK_RELOCATE_SUCCESS";
export const RECEIVE_STOCK_RELOCATE_FAIL = "RECEIVE_STOCK_RELOCATE_FAIL";

export const CANCEL_STOCK_RELOCATE = "CANCEL_STOCK_RELOCATE";
export const CANCEL_STOCK_RELOCATE_SUCCESS = "CANCEL_STOCK_RELOCATE_SUCCESS";
export const CANCEL_STOCK_RELOCATE_FAIL = "CANCEL_STOCK_RELOCATE_FAIL";