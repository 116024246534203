// Actions
export const LIST_ACCOUNT = "LIST_ACCOUNT";
export const LIST_ACCOUNT_SUCCESS = "LIST_ACCOUNT_SUCCESS";
export const LIST_ACCOUNT_FAIL = "LIST_ACCOUNT_FAIL";

export const GET_ACCOUNT = "GET_ACCOUNT";
export const GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS";
export const GET_ACCOUNT_FAIL = "GET_ACCOUNT_FAIL";

export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAIL = "DELETE_ACCOUNT_FAIL";

export const ADD_NEW_ACCOUNT = "ADD_NEW_ACCOUNT";
export const ADD_ACCOUNT_SUCCESS = "ADD_ACCOUNT_SUCCESS";
export const ADD_ACCOUNT_FAIL = "ADD_ACCOUNT_FAIL";

export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_FAIL = "UPDATE_ACCOUNT_FAIL";

export const RESET_ACCOUNT_FLAG = "RESET_ACCOUNT_FLAG";
