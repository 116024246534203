import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { Tree } from "antd";
import { DragOutlined } from "@ant-design/icons";
import { convertToTreeView } from "../../../../helpers/format_helper";

import Loader from "../../../../Components/Common/Loader";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import withRouter from "../../../../Components/Common/withRouter";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import { Actions } from "../../../../Components/constants/common";

import { deleteCategory, listCategory, updateCategory } from "../../../../store/actions";
import ImageCustom from "../../../../Components/Common/ImageCustom";
import EditAddCategoryModal from "../Components/EditAddCategoryModal";
import ActionDropdown from "../../../../Components/Common/ActionDropdown";

const CategoriesPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Category List")} | OptiWarehouse`;

  const dispatch = useDispatch();

  const {
    categories,
    categoriesLoading,
    categoriesSuccess,
    categoriesAction,
    categoriesError,
    categoriesLimit,
    categoriesTotal,
    categoriesPage,
  } = useMemoizedSelector((state) => ({
    categories: state.Categories.categories,
    categoriesLoading: state.Categories.loading,
    categoriesAction: state.Categories.action,
    categoriesSuccess: state.Categories.success,
    categoriesError: state.Categories.error,
    categoriesLimit: state.Categories.limit,
    categoriesTotal: state.Categories.total,
    categoriesPage: state.Categories.page,
  }));

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showEditAddModal, setShowEditAddModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [nodeId, setNodeId] = useState("");
  const [childrenNodeKeys, setChildrenNodeKeys] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [convertedListCategories, setConvertedListCategories] = useState([]);

  const handleAdd = () => {
    setSelectedCategory(null);
    setChildrenNodeKeys([]);
    setIsEdit(false);
    setShowEditAddModal(true);
  };

  const handleAddChildren = (node) => {
    setSelectedCategory(node.data);
    setNodeId(node.data.id);
    setChildrenNodeKeys(getAllNodeKeys(node.children));
    setIsEdit(false);
    setShowEditAddModal(true);
  };

  const handleEdit = (node) => {
    setSelectedCategory(node.data);
    setChildrenNodeKeys(getAllNodeKeys(node.children));
    setIsEdit(true);
    setShowEditAddModal(true);
  };

  const handleCloseEditAddModal = () => {
    setShowEditAddModal(false);
    setNodeId("");
  };

  const handleDelete = () => {
    dispatch(deleteCategory(selectedCategory));
  };

  const handleCloseDeleteModal = () => {
    !categoriesLoading && setShowDeleteModal(false);
  };

  const treeData = useMemo(() => {
    const loop = (data) =>
      data.map((item) => {
        const strTitle = item.title;
        const nodeData = item.data;
        const title = (
          <div className="d-flex align-items-center position-relative hover-title-tree">
            <div className="flex-shrink-0 me-3 p-1">
              <ImageCustom image={item.data?.image?.url} name={item?.data?.name} avatarSize={"avatar-sm"} />
            </div>
            <div id={item.key} className="flex-grow-1 fs-16 fw-medium">
              {strTitle}
            </div>
            <div>
              <ActionDropdown
                actions={[
                  { type: "view", onClick: () => navigate(`/products?category.name=${nodeData.name}`) },
                  {
                    type: "add",
                    title: "Thêm danh mục",
                    onClick: () => handleEdit(item),
                  },
                  {
                    type: "edit",
                    onClick: () => handleEdit(item),
                  },
                  {
                    type: "delete",
                    onClick: () => {
                      setSelectedCategory(item.data);
                      setShowDeleteModal(true);
                    },
                  },
                ]}
              />
            </div>
          </div>
        );
        if (item.children) {
          return { ...item, title, key: item.key, children: loop(item.children) };
        }

        return {
          ...item,
          title,
          key: item.key,
        };
      });

    return loop(convertedListCategories);
  }, [convertedListCategories]);

  const getAllNodeKeys = (data) => {
    return data.reduce((keys, node) => {
      keys.push(node.key);

      if (node.children) {
        keys.push(...getAllNodeKeys(node.children));
      }

      return keys;
    }, []);
  };

  const onExpand = (newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys);
  };

  const onDrop = (info) => {
    if (info.dropToGap) {
      let newData = info.dragNode.data;
      if (newData.parent_category_id !== "") {
        newData.parent_category_id = "";
        dispatch(updateCategory(newData));
      }
    } else {
      let newData = info.dragNode.data;
      newData.parent_category_id = info.node.data.id;
      dispatch(updateCategory(newData));
    }
  };

  useEffect(() => {
    dispatch(listCategory({ limit: 2000 }));
  }, [dispatch]);

  useEffect(() => {
    let newData = convertToTreeView(categories);
    setConvertedListCategories(newData);
  }, [categories]);

  useEffect(() => {
    setExpandedKeys(getAllNodeKeys(treeData));
  }, [treeData]);

  // toggle after loading
  useEffect(() => {
    if (!categoriesLoading && categoriesSuccess && showDeleteModal) {
      setShowDeleteModal(false);
      setSelectedCategory(null);
    }
  }, [categoriesLoading, categoriesSuccess]);

  return (
    <div className="page-content">
      <DeleteModal
        show={showDeleteModal}
        onCloseClick={handleCloseDeleteModal}
        onDeleteClick={handleDelete}
        loading={categoriesLoading && categoriesAction === Actions.DELETE}
      />
      <EditAddCategoryModal
        nodeId={nodeId}
        childrenNodeKeys={childrenNodeKeys}
        show={showEditAddModal}
        toggle={handleCloseEditAddModal}
        isEdit={isEdit}
        category={selectedCategory}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="category-list">
              <CardHeader className="border-0">
                <Row className="g-4 align-items-center">
                  <div className="col-sm">
                    <div>
                      <h5 className="card-title mb-0">{t("Category List")}</h5>
                    </div>
                  </div>
                  <div className="col-sm-auto">
                    <div>
                      <ButtonTheme
                        type="button"
                        className="btn btn-primary add-btn"
                        id="create-btn"
                        onClick={handleAdd}
                        icon="ri-add-line text-white"
                      >
                        {t("Add Category")}
                      </ButtonTheme>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div className="mb-3">
                  {categoriesLoading && categoriesAction === Actions.LIST ? (
                    <Loader />
                  ) : categories.length === 0 ? (
                    <>{t("No data")}</>
                  ) : (
                    <Tree
                      style={{ backgroundColor: "var(--vz-card-cap-bg)", color: "var(--vz-heading-color)" }}
                      className="draggable-tree"
                      expandedKeys={expandedKeys}
                      onExpand={onExpand}
                      blockNode
                      onDrop={onDrop}
                      treeData={treeData}
                      draggable={{
                        icon: (
                          <div
                            style={{
                              cursor: "all-scroll",
                            }}
                          >
                            <i className="ri-menu-5-line fs-20" style={{ color: "#D1D1D1" }}></i>
                          </div>
                        ), // Sử dụng biểu tượng kéo tùy chỉnh
                        nodeDraggable: (node) => true, // Xác định xem node có thể kéo được hay không dựa trên thuộc tính 'draggable'
                        // onDragStart: onDragStart,
                      }}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default withRouter(CategoriesPage);
