import React, { useEffect, useState } from "react";
//redux
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Alert, FormFeedback, Input, Form } from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import ButtonTheme from "../../Components/Common/ButtonTheme";

import styles from "./verify.module.scss";
// action
import { resendConfirmationCode, resetRegisterFlag, verifyConfirmationCode } from "../../store/actions";
import useMemoizedSelector from "../../Components/Hooks/useMemoizedSelector";
import cls from "classnames";
import { LogoLight } from "../../assets/images/svg";

import { SyncOutlined } from "@ant-design/icons";
import CountdownTimer from "../../common/data/CountDownTimer";

const VerifyConfirmationCodePage = ({ t }) => {
  const [isSpinLoading, setIsSpinLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();

  const [action, setAction] = useState("");

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      confirmationCode: "",
    },
    validationSchema: Yup.object({
      confirmationCode: Yup.string().required(t("Please Enter Verification Code Sent To your Email")),
    }),
    onSubmit: (values) => {
      setAction("Verify");
      dispatch(verifyConfirmationCode({ username: location.state.username, code: values.confirmationCode }));
    },
  });

  const {
    error,
    registrationError,
    success,
    loading,
    resendLoading,
    resendMessage,
    resendError,
    resendSuccess,
    username,
  } = useMemoizedSelector((state) => ({
    registrationError: state.Account.registrationError,
    success: state.Account.success,
    error: state.Account.error,
    loading: state.Account.loading,
    resendLoading: state.Account.resendLoading,
    resendError: state.Account.resendError,
    resendSuccess: state.Account.resendSuccess,
    resendMessage: state.Account.resendMessage,
  }));

  useEffect(() => {
    if (success) {
      setTimeout(() => history("/login"), 3000);
    }
    if (!loading && (error || resendError)) {
      let resetErrorMsgTime = setTimeout(() => dispatch(resetRegisterFlag()), 3000);
      return () => clearTimeout(resetErrorMsgTime);
    }
  }, [dispatch, success, error, history, loading, resendError, resendLoading]);

  useEffect(() => {
    if (!location.state?.username) {
      history("/login");
    }
  }, []);

  document.title = `${t("Verify Confirmation Code")} | OptiWarehouse`;
  return (
    <ParticlesAuth>
      <div className={cls(styles["verify-otp-container"], "")}>
        <div className="p-2">
          {error && registrationError ? (
            <Alert color="danger" style={{ marginTop: "13px" }}>
              {t(registrationError)}
            </Alert>
          ) : null}
          {success ? (
            <Alert color="success" style={{ marginTop: "13px" }}>
              {/* {verifySuccessMsg} */}
              {t(`${action} Confirmation Code Successfully`)}
            </Alert>
          ) : null}
          {resendError && resendMessage ? (
            <Alert color="danger" style={{ marginTop: "13px" }}>
              {t(resendMessage)}
            </Alert>
          ) : null}
          {resendSuccess && resendMessage ? (
            <Alert color="success" style={{ marginTop: "13px" }}>
              {t(resendMessage)}
            </Alert>
          ) : null}
          <div className={"tw-flex tw-flex-col tw-items-center"}>
            <Link to="/login">
              <img src={LogoLight} className={"tw-h-[60px] tw-w-[200px]"} />
            </Link>
            <div className={"tw-flex tw-flex-col tw-gap-[13px] tw-mt-[30px] tw-mb-[34px]"}>
              <p className={" tw-text-[24px] tw-font-bold tw-text-[#151334]"}>{t("confirm_account")}</p>
              <p>{t("sent_code")}</p>
            </div>
          </div>

          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-4">
              <Input
                name="confirmationCode"
                className="form-control"
                placeholder={t("Enter confirmation code")}
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.confirmationCode || ""}
                invalid={validation.touched.confirmationCode && validation.errors.confirmationCode ? true : false}
              />
              {validation.touched.confirmationCode && validation.errors.confirmationCode ? (
                <FormFeedback type="invalid">
                  <div>{validation.errors.confirmationCode}</div>
                </FormFeedback>
              ) : null}
            </div>
            <div className="tw-flex tw-items-center ">
              <button
                className="tw-border-[0] tw-bg-inherit"
                type="button"
                onClick={() => {
                  setAction("Resend");
                  dispatch(resendConfirmationCode(location.state.username));
                  setIsSpinLoading(!isSpinLoading);
                }}
                disabled={isSpinLoading}
              >
                <SyncOutlined spin={isSpinLoading} />
              </button>
              <p className={`${!isSpinLoading ? "tw-mt-[8px]" : "tw-mt-[13px]"}`}>
                {t("send_new_code")}:{" "}
                <span className={"tw-text-[#44425D] tw-text-[14px] tw-font-extrabold"}>
                  <CountdownTimer checkActive={isSpinLoading} setIsSpinLoading={setIsSpinLoading} />
                </span>
              </p>
            </div>
            <div className="text-center mt-4">
              <ButtonTheme className="btn btn-primary w-100" type="submit" loading={loading} disabled={loading}>
                {t("Verify Confirmation Code")}
              </ButtonTheme>
            </div>
          </Form>
        </div>

        {/*<div className="mt-4 text-center">*/}
        {/*  <p className="mb-0">*/}
        {/*    {t("Already have an account?")}{" "}*/}
        {/*    <Link to="/login" className="fw-semibold text-primary text-decoration-underline">*/}
        {/*      {t("Sign in")}*/}
        {/*    </Link>*/}
        {/*  </p>*/}
        {/*</div>*/}
      </div>
    </ParticlesAuth>
  );
};

VerifyConfirmationCodePage.propTypes = {
  history: PropTypes.object,
  username: PropTypes.string,
};

export default withTranslation()(VerifyConfirmationCodePage);
