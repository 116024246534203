// Actions
export const LIST_BRAND = "LIST_BRAND";
export const LIST_BRAND_SUCCESS = "LIST_BRAND_SUCCESS";
export const LIST_BRAND_FAIL = "LIST_BRAND_FAIL";

export const GET_BRAND = "GET_BRAND";
export const GET_BRAND_SUCCESS = "GET_BRAND_SUCCESS";
export const GET_BRAND_FAIL = "GET_BRAND_FAIL";

/**
 * Delete BRAND
 */
export const DELETE_BRAND = "DELETE_BRAND";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS";
export const DELETE_BRAND_FAIL = "DELETE_BRAND_FAIL";

/**
 * Add BRAND
 */
export const ADD_NEW_BRAND = "ADD_NEW_BRAND";
export const ADD_BRAND_SUCCESS = "ADD_BRAND_SUCCESS";
export const ADD_BRAND_FAIL = "ADD_BRAND_FAIL";

/**
 * Edit BRAND
 */
export const UPDATE_BRAND = "UPDATE_BRAND";
export const UPDATE_BRAND_SUCCESS = "UPDATE_BRAND_SUCCESS";
export const UPDATE_BRAND_FAIL = "UPDATE_BRAND_FAIL";
