import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";
import { formatVNDCurrency, getChartColorsArray } from "../../../../../helpers/format_helper";
import { useTranslation } from "react-i18next";

const SimplePie = ({ dataColors, data }) => {
  const { t } = useTranslation();
  const [series, setSeries] = useState([]);
  const [labels, setLabel] = useState([]);
  useEffect(() => {
    setSeries(data.map((item) => Number(item?.balance ?? 10)));
    setLabel(data.map((item) => item.name));
  }, [data]);
  var chartPieBasicColors = getChartColorsArray(dataColors);
  var options = {
    chart: {
      height: 300,
      type: "pie",
    },
    labels,
    legend: {
      position: "bottom",
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
    },
    tooltip: {
      x: {
        formatter: function (val) {
          return t(val);
        },
      },
      y: {
        formatter: function (val) {
          return formatVNDCurrency(val);
        },
      },
    },
    colors: chartPieBasicColors,
  };
  return (
    <ReactApexChart dir="ltr" className="apex-charts" series={series} options={options} type="pie" height={267.7} />
  );
};

export default SimplePie;
