export const LIST_STOCK_ADJUSTMENT = "LIST_STOCK_ADJUSTMENT";
export const LIST_STOCK_ADJUSTMENT_SUCCESS = "LIST_STOCK_ADJUSTMENT_SUCCESS";
export const LIST_STOCK_ADJUSTMENT_FAIL = "LIST_STOCK_ADJUSTMENT_FAIL";

export const GET_STOCK_ADJUSTMENT = "GET_STOCK_ADJUSTMENT";
export const GET_STOCK_ADJUSTMENT_SUCCESS = "GET_STOCK_ADJUSTMENT_SUCCESS";
export const GET_STOCK_ADJUSTMENT_FAIL = "GET_STOCK_ADJUSTMENT_FAIL";

export const ADD_NEW_STOCK_ADJUSTMENT = "ADD_NEW_STOCK_ADJUSTMENT";
export const ADD_STOCK_ADJUSTMENT_SUCCESS = "ADD_STOCK_ADJUSTMENT_SUCCESS";
export const ADD_STOCK_ADJUSTMENT_FAIL = "ADD_STOCK_ADJUSTMENT_FAIL";

export const UPDATE_STOCK_ADJUSTMENT = "UPDATE_STOCK_ADJUSTMENT";
export const UPDATE_STOCK_ADJUSTMENT_SUCCESS = "UPDATE_STOCK_ADJUSTMENT_SUCCESS";
export const UPDATE_STOCK_ADJUSTMENT_FAIL = "UPDATE_STOCK_ADJUSTMENT_FAIL";

export const BALANCE_STOCK_ADJUSTMENT = "BALANCE_STOCK_ADJUSTMENT";
export const BALANCE_STOCK_ADJUSTMENT_SUCCESS = "BALANCE_STOCK_ADJUSTMENT_SUCCESS";
export const BALANCE_STOCK_ADJUSTMENT_FAIL = "BALANCE_STOCK_ADJUSTMENT_FAIL";

export const DELETE_STOCK_ADJUSTMENT = "DELETE_STOCK_ADJUSTMENT";
export const DELETE_STOCK_ADJUSTMENT_SUCCESS = "DELETE_STOCK_ADJUSTMENT_SUCCESS";
export const DELETE_STOCK_ADJUSTMENT_FAIL = "DELETE_STOCK_ADJUSTMENT_FAIL";