import {
  LIST_STORE,
  LIST_STORE_FAIL,
  LIST_STORE_SUCCESS,
  DELETE_STORE,
  DELETE_STORE_SUCCESS,
  DELETE_STORE_FAIL,
  ADD_NEW_STORE,
  ADD_STORE_SUCCESS,
  ADD_STORE_FAIL,
  UPDATE_STORE,
  UPDATE_STORE_SUCCESS,
  UPDATE_STORE_FAIL,
  GET_STORE,
  GET_STORE_FAIL,
  GET_STORE_SUCCESS,
  /////////////////////
  LIST_APP_STORE,
  LIST_APP_STORE_FAIL,
  LIST_APP_STORE_SUCCESS,
  GET_APP_STORE,
  GET_APP_STORE_FAIL,
  GET_APP_STORE_SUCCESS,
  ///////////
  LIST_APP,
  LIST_APP_FAIL,
  LIST_APP_SUCCESS,
  DELETE_APP,
  DELETE_APP_SUCCESS,
  DELETE_APP_FAIL,
} from "./actionType";

export const listStore = (options) => ({
  type: LIST_STORE,
  payload: options,
});

export const listStoreFail = (error) => ({
  type: LIST_STORE_FAIL,
  payload: error,
});

export const listStoreSuccess = (stores) => ({
  type: LIST_STORE_SUCCESS,
  payload: stores,
});

export const getStore = (storeID) => ({
  type: GET_STORE,
  payload: storeID,
});

export const getStoreFail = (error) => ({
  type: GET_STORE_FAIL,
  payload: error,
});

export const getStoreSuccess = (store) => ({
  type: GET_STORE_SUCCESS,
  payload: store,
});

export const deleteStore = (store) => ({
  type: DELETE_STORE,
  payload: store,
});

export const deleteStoreSuccess = (store) => ({
  type: DELETE_STORE_SUCCESS,
  payload: store,
});

export const deleteStoreFail = (error) => ({
  type: DELETE_STORE_FAIL,
  payload: error,
});

export const updateStore = (store) => ({
  type: UPDATE_STORE,
  payload: store,
});

export const updateStoreSuccess = (store) => ({
  type: UPDATE_STORE_SUCCESS,
  payload: store,
});

export const updateStoreFail = (error) => ({
  type: UPDATE_STORE_FAIL,
  payload: error,
});

export const addNewStore = (store) => ({
  type: ADD_NEW_STORE,
  payload: store,
});

export const addStoreSuccess = (store) => ({
  type: ADD_STORE_SUCCESS,
  payload: store,
});

export const addStoreFail = (error) => ({
  type: ADD_STORE_FAIL,
  payload: error,
});

export const listAppStore = (options) => ({
  type: LIST_APP_STORE,
  payload: options,
});

export const listAppStoreFail = (error) => ({
  type: LIST_APP_STORE_FAIL,
  payload: error,
});

export const listAppStoreSuccess = (appStores) => ({
  type: LIST_APP_STORE_SUCCESS,
  payload: appStores,
});

export const getAppStore = (appStoreID) => ({
  type: GET_APP_STORE,
  payload: appStoreID,
});

export const getAppStoreFail = (error) => ({
  type: GET_APP_STORE_FAIL,
  payload: error,
});

export const getAppStoreSuccess = (appStore) => ({
  type: GET_APP_STORE_SUCCESS,
  payload: appStore,
});

export const listApp = (options) => ({
  type: LIST_APP,
  payload: options,
});

export const listAppFail = (error) => ({
  type: LIST_APP_FAIL,
  payload: error,
});

export const listAppSuccess = (apps) => ({
  type: LIST_APP_SUCCESS,
  payload: apps,
});
export const deleteApp = (app, handleAfterSubmit = () => {}) => ({
  type: DELETE_APP,
  payload: { app, handleAfterSubmit },
});

export const deleteAppSuccess = (app) => ({
  type: DELETE_APP_SUCCESS,
  payload: app,
});

export const deleteAppFail = (error) => ({
  type: DELETE_APP_FAIL,
  payload: error,
});
