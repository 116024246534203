import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";


// Redux States
import { LIST_BRAND, DELETE_BRAND, ADD_NEW_BRAND, UPDATE_BRAND, GET_BRAND } from "./actionType";

import {
  deleteBrandSuccess,
  deleteBrandFail,
  addBrandSuccess,
  addBrandFail,
  updateBrandSuccess,
  updateBrandFail,
  listBrandSuccess,
  listBrandFail,
  getBrandSuccess,
  getBrandFail,
} from "./action";

import { brandsAPI } from "../../../../helpers/resource_helper";

function* listBrand({ payload: { ...res } }) {
  try {
    const response = yield call(brandsAPI.list, { ...res });
    yield put(listBrandSuccess(response));
  } catch (error) {
    yield put(listBrandFail(error));
  }
}

function* getBrand({ payload: id }) {
  try {
    const response = yield call(brandsAPI.get, { id });
    yield put(getBrandSuccess(response));
  } catch (error) {
    yield put(getBrandFail(error));
  }
}

function* deleteBrands({ payload: brand }) {
  try {
    const response = yield call(brandsAPI.delete, brand);
    yield put(deleteBrandSuccess(brand));
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteBrandFail(error));
    toast.error("Brand Delete Failed", { autoClose: 3000 });
  }
}

function* onAddNewBrand({ payload: { brand, handleAfterSubmit } }) {
  try {
    const response = yield call(brandsAPI.create, brand);
    yield put(addBrandSuccess(response));
    yield call(handleAfterSubmit, response);
    toast.success("Brand Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addBrandFail(error));
    toast.error("Brand Added Failed", { autoClose: 3000 });
  }
}

function* onUpdateBrand({ payload: { brand, handleAfterSubmit } }) {
  try {
    const response = yield call(brandsAPI.put, brand);
    yield put(updateBrandSuccess(response));
    yield call(handleAfterSubmit);
    toast.success("Brand Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateBrandFail(error));
    toast.error("Brand Updated Failed", { autoClose: 3000 });
  }
}

export function* watchGetBrands() {
  yield takeEvery(LIST_BRAND, listBrand);
}

export function* watchDeleteBrands() {
  yield takeEvery(DELETE_BRAND, deleteBrands);
}

export function* watchGetBrand() {
  yield takeEvery(GET_BRAND, getBrand);
}

export function* watchUpdateBrand() {
  yield takeEvery(UPDATE_BRAND, onUpdateBrand);
}

export function* watchAddNewBrand() {
  yield takeEvery(ADD_NEW_BRAND, onAddNewBrand);
}

function* brandsSaga() {
  yield all([
    fork(watchGetBrand),
    fork(watchGetBrands),
    fork(watchUpdateBrand),
    fork(watchAddNewBrand),
    fork(watchDeleteBrands),
  ]);
}

export default brandsSaga;
