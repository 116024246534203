import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types"; // Import PropTypes for type checking

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation();

    // Memoize the router props
    const routerProps = useMemo(() => ({ t, location, navigate, params }), [t, location, navigate, params]);

    return <Component {...props} router={routerProps} />;
  }

  return ComponentWithRouterProp;
}

// Add PropTypes for type checking
withRouter.propTypes = {
  Component: PropTypes.elementType.isRequired,
};

export default withRouter;
