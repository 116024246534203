import { Actions } from "../../../../Components/constants/common";
import {
  LIST_UNIT,
  DELETE_UNIT,
  DELETE_UNIT_SUCCESS,
  DELETE_UNIT_FAIL,
  ADD_UNIT_SUCCESS,
  ADD_UNIT_FAIL,
  UPDATE_UNIT_SUCCESS,
  UPDATE_UNIT_FAIL,
  LIST_UNIT_SUCCESS,
  LIST_UNIT_FAIL,
  ADD_NEW_UNIT,
  UPDATE_UNIT,
  GET_UNIT,
  GET_UNIT_SUCCESS,
  GET_UNIT_FAIL,
} from "./actionType";

const INIT_STATE = {
  units: [],
  unit: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const Units = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_UNIT:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_UNIT_SUCCESS:
      return {
        ...state,
        message: "Get units success",
        loading: false,
        success: true,
        units: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_UNIT_FAIL:
      return {
        ...state,
        message: "Get units failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_UNIT:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_UNIT_SUCCESS:
      return {
        ...state,
        message: "Get unit success",
        loading: false,
        success: true,
        unit: action.payload,
        action: Actions.EMPTY,
      };

    case GET_UNIT_FAIL:
      return {
        ...state,
        message: "Get unit failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_UNIT:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_UNIT_SUCCESS:
      return {
        ...state,
        message: "Delete unit success",
        loading: false,
        success: true,
        units: state.units.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_UNIT_FAIL:
      return {
        ...state,
        message: "Delete unit failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_NEW_UNIT:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_UNIT_SUCCESS:
      return {
        ...state,
        message: "Add unit success",
        success: true,
        loading: false,
        units: [ action.payload,...state.units],
        action: Actions.EMPTY,
      };

    case ADD_UNIT_FAIL:
      return {
        ...state,
        message: "Add unit failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_UNIT:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.UPDATE,
      };
    case UPDATE_UNIT_SUCCESS:
      return {
        ...state,
        message: "Update unit success",
        loading: false,
        success: true,
        units: state.units.map((unit) =>
          unit.id.toString() === action.payload.id.toString() ? { ...unit, ...action.payload } : unit,
        ),
        action: Actions.EMPTY,
      };

    case UPDATE_UNIT_FAIL:
      return {
        ...state,
        message: "Update unit failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    default:
      return { ...state };
  }
};

export default Units;
